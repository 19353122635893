import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../../assets/styles/Home.css';
import { Image, Principal_lista } from '../../assets/styles/Home.styled';
import {
  Container1,
  ContainerBottons,
  Subtitle,
  Title2,
} from '../../components/common/Home.styled';
import {
  Button,
  Container,
} from '../../components/common/common.styled';
import cuenta from '../../assets/img/lpVexi/logoVexi.svg';
import solicitud from '../../assets/img/lpVexi/solicitudVexi.svg';
import tarjeta from '../../assets/img/lpVexi/tarjetaVexi.svg';

import vexi1 from '../../assets/img/lpVexi/principalVexi.svg';
import vexi2 from '../../assets/img/lpVexi/beneficiosVexi.svg';
import WOlayout from '../../components/layouts/WOlayout';
// Modal

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const LpVexi = ({ location }) => {
  console.log('fijate aqui');
  console.log(process.env.REACT_APP_SERVERTYPE);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);
  const history = useHistory();
  function shareCoru(event) {
    event.preventDefault();
    history.push('/contact-vexi');
  }

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Container1>
        <Container
          width="85%"
          mt="70px"
          widthMobile="100%"
          mb="0%"
          mtMobile="75px"
        >
          <div className="m-0 w-100 row mb-5">
            <div
              className={`col-md-6 col-12  text-center d-flex
                            align-items-center d-md-none d-block justify-content-center`}
            >
              <ContainerBottons
                position=" "
                displayD="none"
                displayM="block"
                widthM="50%"
                margin="7% 0% 4% 0%"
              >
                <Image
                  src={vexi1}
                  heiM="100%"
                  widthmob="100%"
                  position="static"
                  left="0%"
                  top="0%"
                  hei="100%"
                  displayMobile="block"
                />
              </ContainerBottons>
            </div>
            <div className="col-lg-5 col-md-6 col-12 ">
              <Title2
                sizeD="50px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="3%"
                className="roboto"
                fSizeMd="30px"
                sizem="25px"
                top="0px"
                mleftm="0px"
                widthM="100%"
              >
                Vexi, la tarjeta de crédito que siempre quisiste
              </Title2>
              <Subtitle
                id="Subtitle1"
                className="roboto"
                widthM="100%"
                mtopM="0%"
                mbottomM="5%"
                width="100%"
                sizeMm992="15px"
                sizeM="14px"
                heightM=" "
                mleftM="0px"
              >
                Solicita tu tarjeta Vexi Carnet o American Express para obtener
                el mayor provecho posible y tengas acceso a una gran variedad de
                productos financieros formales que permitan potenciar tu
                desarrollo personal y empresarial.
              </Subtitle>
              <Button
                className="roboto"
                bg="#C518DD"
                mb="0"
                onClick={(e) => shareCoru(e)}
                mt="8.5%"
              >
                ¡Quiero mi tarjeta Vexi!
              </Button>
            </div>
            <div className={'col-1 d-lg-block d-none'} />
            <div
              className={`col-md-6 col-12   d-none mt-md-0 mt-4
                            align-items-center d-md-flex justify-content-start `}
            >
              <ContainerBottons
                position=" "
                displayD="block"
                displayM="none"
                widthD="82.2%"
                height="100%"
                heightMd="100%"
                widthMd="100%"
                margin="0px"
              >
                <Image
                  src={vexi1}
                  position="static"
                  left="0%"
                  top="0%"
                  hei="100%"
                  displayMobile="none"
                />
              </ContainerBottons>
            </div>
            <div
              className={`col-md-6 col-12  text-center d-flex
                            align-items-center d-md-none d-block justify-content-center`}
            >
              <ContainerBottons
                position=" "
                displayD="none"
                displayM="block"
                widthM="52%"
                margin="7% 0% 2% 0% "
              >
                <Image
                  src={vexi2}
                  heiM="100%"
                  widthmob="100%"
                  position="static"
                  left="0%"
                  top="0%"
                  hei="100%"
                  displayMobile="block"
                />
              </ContainerBottons>
            </div>
            <div className="col-md-6 d-md-block d-none" />
            <div className="col-md-6 col-12 mt-lg-5 mt-md-4 mt-2 mb-md-3 mb-1">
              <Title2
                sizeD="40px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="5%"
                className="roboto"
                fSizeMd="20px"
                sizem="20px"
                top="3%"
                mleftm="0px"
                widthM="100%"
              >
                Beneficios de tu tarjeta Vexi
              </Title2>
            </div>
            <div
              className={'col-lg-5 col-md-6 col-12 d-none d-md-flex justify-content-end'}
            >
              <ContainerBottons
                widthD="85%"
                height="100%"
                heightMd="100%"
                widthMd="100%"
                margin="0px"
                top="-1.8%"
              >
                <Image
                  src={vexi2}
                  position="static"
                  left="0%"
                  top="0%"
                  hei="100%"
                  displayMobile="none"
                />
              </ContainerBottons>
            </div>
            <div className={'col-1 d-lg-block d-none'} />
            <div className="col-md-6 col-12">
              <Subtitle
                id="Subtitle1"
                className="roboto"
                widthM="100%"
                mtopM="0%"
                mbottomM="5%"
                width="100%"
                sizeMm992="15px"
                sizeM="14px"
                heightM=" "
                mleftM="0px"
                marginTop="0%"
              >
                Vexi NO tiene anualidad de por vida, ni comisión por no uso,
                solo se requiere realizar un pago ÚNICO llamado comisión por
                apertura y listo, podrás disfrutar de todos los beneficios que
                tenemos para ti.
              </Subtitle>
              <Subtitle
                mbottomM="0"
                bottomMm640="0"
                sizeM="20px"
                sizeM640="20px"
                id="Subtitle"
                className="roboto"
                aling="left"
                weight="bold"
                size="18px"
                width="100%"
                weightM=" "
                mleftM="0px"
                mleftMm320="0px"
                mleftMm640="0px"
                mleftMm360="0px"
                widthMm320="300px"
              >
                Cuenta protegida
              </Subtitle>
              <Subtitle
                id="Subtitle"
                className="roboto"
                heightM=" "
                aling="left"
                size="16px"
                marginTop=" "
                weightM=" "
                mleftMm320="18px"
                widthMm320="280px"
                widthMm360="300px"
                bottomMm640="0px"
                mleftMm360="22px"
                mtopM="0px "
                width="100%"
              >
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Prende y apaga tus tarjetas a través de Vexi App.
                </Principal_lista>
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Tarjeta digital renovable para compras seguras por internet.
                </Principal_lista>
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Define un monto límite para tus compras.
                </Principal_lista>
              </Subtitle>
              <Subtitle
                mbottomM="0"
                bottomMm640="0"
                sizeM="20px"
                sizeM640="20px"
                id="Subtitle"
                className="roboto"
                aling="left"
                weight="bold"
                size="18px"
                width="100%"
                weightM=" "
                mleftM="0px"
                mleftMm320="0px"
                mleftMm640="0px"
                mleftMm360="0px"
                widthMm320="300px"
              >
                Controla tus gastos
              </Subtitle>
              <Subtitle
                id="Subtitle"
                className="roboto"
                heightM=" "
                aling="left"
                size="16px"
                marginTop=" "
                weightM=" "
                mleftMm320="18px"
                widthMm320="280px"
                widthMm360="300px"
                bottomMm640="0px"
                mleftMm360="22px"
                mtopM="0px "
                width="100%"
              >
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Parcializa tus compras en pagos fijos desde tu app.
                </Principal_lista>
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Administra tu presupuesto con nuestras categorías.
                </Principal_lista>
              </Subtitle>
              <Subtitle
                mbottomM="0"
                bottomMm640="0"
                sizeM="20px"
                sizeM640="20px"
                id="Subtitle"
                className="roboto"
                aling="left"
                weight="bold"
                size="18px"
                width="100%"
                weightM=" "
                mleftM="0px"
                mleftMm320="0px"
                mleftMm640="0px"
                mleftMm360="0px"
                widthMm320="300px"
              >
                Construye historial crediticio
              </Subtitle>
              <Subtitle
                id="Subtitle"
                className="roboto"
                heightM=" "
                aling="left"
                size="16px"
                marginTop=" "
                weightM=" "
                mleftMm320="18px"
                widthMm320="280px"
                widthMm360="300px"
                bottomMm640="0px"
                mleftMm360="22px"
                mtopM="0px "
                width="100%"
              >
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Incrementa tu línea de crédito al usar y pagar tu tarjeta.
                </Principal_lista>
                <Principal_lista
                  fsM="14px"
                  lhM="24px"
                  lh="24px"
                  fw="300"
                  fwM="300"
                  tiM="-1em"
                >
                  Baja tu tasa de interés al aumentar tu línea de crédito.
                </Principal_lista>
              </Subtitle>
            </div>
            <div className="col-12 text-center mt-lg-5 mt-0 mb-3">
              <Title2
                sizeD="40px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="10%"
                className="roboto"
                fSizeMd="20px"
                sizem="20px"
                top="5%"
                mleftm="0px"
                widthM="100%"
              >
                ¿Cómo solicito mi tarjeta Vexi?
              </Title2>
            </div>
            <div className="col-12  mt-md-0 mt-1">
              <div
                className={`row d-flex justify-content-center 
                                align-items-center mb-md-0 mb-5 justify-content-lg-between`}
              >
                <div
                  className={`col-lg-3 col-md-6 col-9 d-flex flex-column
                                 justify-content-center `}
                >
                  <div className="text-center">
                    <Image src={cuenta} width="120px" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <Subtitle
                      mbottomM="0"
                      bottomMm640="0"
                      sizeM="20px"
                      sizeM640="20px"
                      id="Subtitle"
                      className="roboto"
                      aling="center"
                      weight="bold"
                      size="18px"
                      width="100%"
                      weightM=" "
                      mleftMm320="10px"
                      widthMm320="300px"
                      mleftMm360="10px"
                    >
                      Escoge tu tarjeta Vexi
                    </Subtitle>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Subtitle
                      id="Subtitle"
                      className="roboto"
                      heightM=" "
                      aling="center"
                      size="16px"
                      marginTop=" "
                      weightM=" "
                      mleftMm320="18px"
                      widthMm320="280px"
                      widthMm360="300px"
                      alingMm640="center"
                      bottomMm640="0px"
                      mleftMm360="22px"
                      mtopM="0px "
                      width="100%"
                    >
                      Ingresa tus datos y solicita la tarjeta que más te
                      convenga
                    </Subtitle>
                  </div>
                </div>
                <div
                  className={`col-lg-3 col-md-6 col-9 d-flex flex-column
                                    justify-content-center  mt-md-0 mt-4`}
                >
                  <div className="text-center">
                    <Image src={solicitud} width="56px" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <Subtitle
                      mbottomM="0"
                      bottomMm640="0"
                      sizeM="20px"
                      sizeM640="20px"
                      id="Subtitle"
                      className="roboto"
                      aling="center"
                      weight="bold"
                      size="18px"
                      width="100%"
                      weightM=" "
                      mleftMm320="10px"
                      widthMm320="300px"
                      mleftMm360="10px"
                    >
                      Haz tu solicitud
                    </Subtitle>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Subtitle
                      id="Subtitle"
                      className="roboto"
                      aling="center"
                      size="16px"
                      width="100%"
                      marginTop=" "
                      weightM=" "
                      mleftMm320="18px"
                      widthMm320="280px"
                      widthMm360="300px"
                      alingMm640="center"
                      heightM=" "
                      mleftMm360="22px"
                      mtopM="0px "
                      bottomMm640="0px"
                    >
                      Haremos una consulta de tu historial crediticio para
                      determinar la línea de crédito que podemos ofrecerte
                    </Subtitle>
                  </div>
                </div>
                <div
                  className={`col-lg-3 col-md-6 col-9 d-flex flex-column
                                    justify-content-center  mt-lg-0 mt-md-3 
                                    mt-4`}
                >
                  <div className="text-center">
                    <Image src={tarjeta} width="76px" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <Subtitle
                      mbottomM="0"
                      bottomMm640="0"
                      sizeM="20px"
                      sizeM640="20px"
                      id="Subtitle"
                      className="roboto"
                      aling="center"
                      weight="bold"
                      size="18px"
                      width="100%"
                      weightM=" "
                      mleftMm320="10px"
                      widthMm320="300px"
                      mleftMm360="10px"
                    >
                      Obtén tu tarjeta
                    </Subtitle>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Subtitle
                      id="Subtitle"
                      className="roboto"
                      aling="center"
                      size="16px"
                      width="100%"
                      marginTop=" "
                      weightM=" "
                      mleftMm320="18px"
                      widthMm320="280px"
                      widthMm360="300px"
                      alingMm640="center"
                      heightM=" "
                      mleftMm360="22px"
                      mtopM="0px "
                      bottomMm640="0px"
                    >
                      En caso de ser aprobada, recibe tu tarjeta y disfruta de
                      todos sus beneficios
                    </Subtitle>
                  </div>
                </div>
                <div className="col-12 mt-4 mb-md-0 mb-5">
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-5 col-md-6">
                      <Button
                        className="roboto  mb-md-0 mb-5"
                        bg="#C518DD"
                        mb="0"
                        onClick={(e) => shareCoru(e)}
                        mt="0px"
                      >
                        Solicitar mi tarjeta Vexi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container1>
    </WOlayout>
  );
};

export default LpVexi;
