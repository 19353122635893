/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { CREATE_CLICK } from "../../mutations";
import { GET_DAY_OF_WEEK, GET_TRAINING_CATEGORIES } from "../../queries";
import { Rombo, VectorNum, Vector1 } from "./Home.styled";
import {
  Header_Home_Mob,
  Header_Home_Web,
  Image,
  Main,
  Header_Log,
  DivNotoficacion,
  Header_Log_Btn,
  Content_Options,
  Toggle_btn,
  Sidepannel,
  Header_Log_Mob,
  HeaderReturn,
  ContentFiguras,
  Header_Log_Vertical_Divisor,
} from "./common.styled";
import BurguerMenu from "../../assets/img/anacoach/menu.png";
import Login from "../../assets/img/anacoach/Login.png";
import ImageLogo from "../../assets/img/anacoach/CoruLogo.svg";
import ImageLogoC from "../../assets/img/anacoach/CoruLogoC.png";
import GeneralLoader from "./GeneralLoader";
import HeaderSelect from "./HeaderSelect";
import ClickButton from "../../Helpers/HookHelpers";
import ModalVirality2 from "./ModalVirality";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px",
    display: "flex",
    alignItems: "center",
    width: "15vw",
    height: "2.8vw",
    overflow: "hidden",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: "3%",
  },
  searchi: {
    fontSize: "2vw",
  },
}));

const Header = ({ pathname, background, backurl, setOptionSelected }) => {
  const history = useHistory();
  const { actions } = ClickButton();
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
  const timestamp = today;
  const [categories, setCategories] = useState([]);
  const [getCategories] = useLazyQuery(GET_TRAINING_CATEGORIES, {
    fetchPolicy: "cache-and-network",
    onCompleted({ getCategories }) {
      console.log("entrando a getCategories");
      setCategories(JSON.parse(getCategories.response));
      console.log("79 categories", JSON.parse(getCategories.response));
    },
    onError(err) {
      console.log("Error con categories");
      console.log(err);
    },
  });
  const handleOpenKanda = () => {
    const idUser = parseInt(sessionStorage.getItem("idUser") || 0);
    const idCont = sessionStorage.getItem("idCont");
    const urlKanda = sessionStorage.getItem("url_kanda");
    const pathnameP = window.location.pathname;
    const pathname = pathnameP.split("/")[1];
    if (urlKanda && urlKanda !== "") {
      if (idUser) {
        window.open(
          `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda#${idUser}#${idCont}`,
          "_blank"
        );
      } else {
        window.open(
          `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda`,
          "_blank"
        );
      }
    }
  };
  useEffect(() => {
    console.log("renderiza header");
    getCategories();
  }, []);
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
      localStorage.setItem("activityDate", new Date());
    },
    onError(err) {
      console.log(err);
    },
  });
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const [OpenModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
    actions({
      action: 'ClickButtonCollection',
      variables: `BannerRewards_BotónMenúComparteyGana`,
    });
  };
  const logout = () => {
    actions({
      action: "ClickButtonCoruHome",
      variables: "MenuCerrarSesión",
      logout: true,
    });
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem("id_session"),
          column: "clickLogoutHeader",
          collection: "sessions",
          timeStamp: timestamp,
        },
      },
    });
    sessionStorage.clear();
    localStorage.clear();
    setMostrarLoad(true);
    setTimeout(() => {
      history.push("/");
    }, 1200);
  };

  const HeaderTwo = ({ url, background, backurl, categories = [] }) => {
    const history = useHistory();
    let empiezahome = sessionStorage.getItem("clickEmpezarAhoraHome");
    const [ProfilePicture, setProfilePicture] = useState(
      sessionStorage.getItem("ProfilePicture") !== "null" &&
        sessionStorage.getItem("ProfilePicture") != null
        ? sessionStorage.getItem("ProfilePicture")
        : Login
    );
    empiezahome = empiezahome === "true";

    const handleClick = () => {
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "clickFeedHeader",
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      if (sessionStorage.getItem("idUser")) {
        actions({ action: "ClickButtonCoruHome", variables: "MenuLogoCoru" });
      }
      history.push("/the-training-feed/");
      sessionStorage.setItem("categorieActive", 1);
    };
    const initLogin = (e) => {
      e.preventDefault();
      e.stopPropagation();
      sessionStorage.setItem("clickIniciarSesion", true);
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "clickLogin",
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      history.push("/login");
    };
    const [viewMenu, setMenu] = useState(false);
    const handleOpen = (ruta, click) => {
      switch (ruta) {
        case "/profile":
          actions({ action: "ClickButtonCoruHome", variables: "MenuPerfil" });
          break;
        case "/achievements":
          actions({ action: "ClickButtonCoruHome", variables: "MenuLogros" });
          break;
        default:
          break;
      }
      const iduser = sessionStorage.getItem("idUser");
      if (
        iduser === "" ||
        iduser === undefined ||
        iduser === null ||
        iduser === "undefined"
      ) {
        history.push("/");
        return;
      }
      if (
        pathname === "/the-training-feed/" &&
        sessionStorage.getItem("idUser") === null
      ) {
        history.push("/login");
      } else {
        createClickFront({
          variables: {
            input: {
              id: sessionStorage.getItem("id_session"),
              column: click,
              collection: "sessions",
              timeStamp: timestamp,
            },
          },
        });
        setMenu(false);
        if (ruta === "/") {
          setMostrarLoad(true);
          sessionStorage.removeItem("clickIniciarSesion");
        }
        setMostrarLoad(true);
        if (ruta === "/achievements") {
          sessionStorage.setItem("originFeed", true);
          setTimeout(() => {
            setMostrarLoad(false);
          }, 1200);
        }
        setTimeout(() => {
          history.push(ruta);
        }, 1200);
      }
    };
    const actualizaProfilePicture = () => {
      setProfilePicture(
        sessionStorage.getItem("ProfilePicture") != "null" &&
          sessionStorage.getItem("ProfilePicture") != null
          ? sessionStorage.getItem("ProfilePicture")
          : Login
      );
      setTimeout(() => {
        actualizaProfilePicture();
      }, 1000);
    };

    useEffect(() => {
      actualizaProfilePicture();
    });

    let isViernes = sessionStorage.getItem("dayOfWeek") === "hola";

    const [getDayOfWeek] = useLazyQuery(GET_DAY_OF_WEEK, {
      onCompleted({ getDayOfWeek }) {
        const { today } = getDayOfWeek;
        sessionStorage.setItem("dayOfWeek", today);
        isViernes = today === "hola";
      },
      onError(err) {
        console.log(err);
      },
    });

    useEffect(() => {
      getDayOfWeek();
    }, []);

    return (
      <Main
        padding="1rem"
        overflow="none !important"
        urlActual={window.location.pathname}
        style={{ background: "transparent" }}
      >
        {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
        <div
          className={
            backurl !== undefined
              ? "BackHeader paddingLaterales home-header-mob"
              : "paddingLaterales home-header-mob"
          }
          style={{
            background: 'transparent',
            backgroundImage: backurl !== undefined ? `url(${backurl})` : " ",
          }}
        >
          <div
            className={
              backurl !== undefined
                ? "BackHeader d-flex justify-content-between align-items-center"
                : "d-flex justify-content-between align-items-center"
            }
            style={{
              height: "70px",
              background,
              backgroundImage: backurl !== undefined ? `url(${backurl})` : " ",
            }}
          >
            <div onClick={handleClick}>
              <Image
                cursor="pointer"
                src={ImageLogo}
                width="200px"
                mwidth="120px"
                heightMobile=""
                alt="logo-coru"
                onClick={handleClick}
              />
            </div>
            <Header_Home_Web width="160%">
              <Content_Options>
                <Fragment>
                  <HeaderSelect
                    options={categories}
                    setSelected={setOptionSelected}
                  />
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen("/achievements", "clickAttainmentHeader");
                    }}
                  >
                    {isViernes ? (
                      <DivNotoficacion className="notificacion">
                        1
                      </DivNotoficacion>
                    ) : null}
                    Logros
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    onClick={(e) => handleOpen("/profile")}
                  >
                    Perfil
                  </Header_Log>
                  <Header_Log_Vertical_Divisor />
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Cerrar sesión
                  </Header_Log>
                </Fragment>
              </Content_Options>
            </Header_Home_Web>
            <Header_Home_Mob>
              <Content_Options>
                <Toggle_btn
                  color="#07210A"
                  className="roboto"
                  onClick={() => setMenu(true)}
                  mfonts="28px"
                >
                  ☰
                </Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
            <Sidepannel
              className={
                viewMenu === true ? "sidepanel view" : "sidepanel no-view"
              }
              id="mySidepanel"
            >
              <div className="closebtn roboto" onClick={() => setMenu(false)}>
                ×
              </div>
              <Image
                cursor="pointer"
                position="absolute"
                marginTop="-47px"
                left=" "
                marginLeft="8%"
                bottom="5%"
                src={ImageLogo}
                width="25%"
                alt="logo-coru"
              />
              <Fragment>
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={() => {
                    handleOpen("/profile", "clickProfileHeader");
                  }}
                >
                  {sessionStorage.getItem("FirstName")}
                </Header_Log_Mob>
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={() => {
                    handleOpen("/the-training-feed/", "clickFeedHeader");
                  }}
                >
                  Inicio
                </Header_Log_Mob>
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={() => {
                    handleOpen("/achievements", "clickAttainmentHeader");
                  }}
                >
                  Logros
                </Header_Log_Mob>
                {window.screen.width <= 768 ? (
                  <Header_Log_Mob
                    className="text-session roboto"
                    onClick={() => {
                      handleOpen(
                        "/kanda/catalogo-de-productos",
                        "clickCatalogoHeader"
                      );
                    }}
                  >
                    Catálogo de Productos
                  </Header_Log_Mob>
                ) : null}
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={() => {
                    handleOpen("/profile", "clickProfileHeader");
                  }}
                >
                  Perfil
                </Header_Log_Mob>
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={handleOpenKanda}
                >
                  {/* Ir a mi perfil Kanda */}Tu Plan
                </Header_Log_Mob>
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={() => handleOpenModal()}
                >
                  Comparte y gana 🎁
                </Header_Log_Mob>
                <Header_Log_Btn
                  className="roboto"
                  width="80%"
                  left="8%"
                  padding="9px 15px!important"
                  position="absolute"
                  onClick={() => {
                    logout();
                  }}
                >
                  Cerrar sesión
                </Header_Log_Btn>

                <ContentFiguras style={{ zIndex: "-1" }}>
                  <Rombo
                    width="30.35px"
                    height="30.35px"
                    transform="rotate(46.41deg)"
                    background="#BFDD6A"
                  />
                  <VectorNum back="#6BD5AF" marginleft="20%" margintop="10%" />
                  <Vector1
                    width="56.12px"
                    height="56.12px"
                    background="#E3E660"
                    transform="rotate(-15.06deg)"
                    top="9%"
                    left="38%"
                  />
                  <Rombo
                    width="181.84px"
                    height="181.84px"
                    transform="rotate(-17.24deg)"
                    background="#91D770"
                    marginlef=""
                    margintop="9%"
                    index="-1"
                    position=" absolute"
                  />
                </ContentFiguras>
              </Fragment>
            </Sidepannel>
          </div>
        </div>
        {OpenModal ? (
          <ModalVirality2
            setShowModal={handleOpenModal}
            showModal={OpenModal}
            nameactions="BannerRewards_BotónMenúComparteyGana"
          />
        ) : null}
      </Main>
    );
  };

  const HeaderAdmin = () => (
    <div id="header">
      <div className="d-flex justify-content-between">
        <HeaderReturn width="5%" marginLeft="25px" className="roboto">
          {" "}
          atrás{" "}
        </HeaderReturn>
        <div style={{ margin: "8px", marginLeft: "110px" }}>
          <Image cursor="pointer" src={ImageLogo} width="52%" alt="logo-coru" />
        </div>
        <Image
          src={BurguerMenu}
          width="30px"
          marginRight="15px"
          alt="logo-coru"
        />
      </div>
    </div>
  );

  const HeaderLogin = () => {
    const history = useHistory();
    const handleClick = () => {
      if (sessionStorage.getItem("LPpageplanes") === "true") {
        history.push("/lp/page-planes");
      } else {
        history.push("/");
      }
    };

    return (
      <Main
        padding="1rem"
        overflow="none!important"
        urlActual={window.location.pathname}
        style={{ background }}
      >
        <div
          className="paddingLaterales home-header-mob"
          style={{ background }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ height: "70px", background }}
          >
            <div onClick={handleClick}>
              <Image
                cursor="pointer"
                src={ImageLogoC}
                width="27.5%"
                mwidth="97.92px"
                heightMobile="24px"
                alt="logo-coru"
                onClick={handleClick}
              />
            </div>
            <Header_Home_Web width="160%">
              <Content_Options>
                <Fragment>
                  <Header_Log
                    widthW=" "
                    width=" "
                    textAlign="right"
                    className="text-session roboto index-btns"
                  ></Header_Log>
                </Fragment>
              </Content_Options>
            </Header_Home_Web>
            <Header_Home_Mob>
              <Content_Options>
                <Toggle_btn
                  color="#07210A"
                  className="roboto"
                  mfonts="28px"
                ></Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
          </div>
        </div>
      </Main>
    );
  };

  const HeaderRegister = () => {
    const history = useHistory();
    const initLogin = (e) => {
      e.preventDefault();
      e.stopPropagation();
      sessionStorage.setItem("clickIniciarSesion", true);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "clickLogin",
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      history.push("/login");
    };

    const handleClick = () => {
      if (sessionStorage.getItem("LPpageplanes") === "true") {
        history.push("/lp/page-planes");
      } else {
        history.push("");
      }
    };
    return (
      <Main
        padding="1rem"
        overflow="none!important"
        urlActual={window.location.pathname}
        style={window.location.pathname === '/personal-Information-v2' ? {} : { background }}
      >
        <div
          className="paddingLaterales home-header-mob"
          style={{ background }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ height: "70px", background }}
          >
            <div onClick={handleClick}>
              <Image
                cursor="pointer"
                src={ImageLogo}
                width="200px"
                mwidth="120px"
                heightMobile=""
                alt="logo-coru"
                onClick={handleClick}
              />
            </div>
            <Header_Home_Web width="160%">
              <Content_Options>
                <Fragment>
                  <Header_Log
                    widthW=" "
                    width=" "
                    textAlign="right"
                    className="text-session roboto index-btns"
                  ></Header_Log>
                </Fragment>
              </Content_Options>
            </Header_Home_Web>
            <Header_Home_Mob>
              <Content_Options>
                <Toggle_btn
                  color="#07210A"
                  className="roboto"
                  mfonts="28px"
                ></Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
          </div>
        </div>
      </Main>
    );
  };

  const HeaderOne = ({ url, background }) => {
    const classes = useStyles();
    const [viewMenu, setMenu] = useState(false);
    const history = useHistory();
    const [ProfilePicture, setProfilePicture] = useState(
      sessionStorage.getItem("ProfilePicture") !== "null" &&
        sessionStorage.getItem("ProfilePicture") != null
        ? sessionStorage.getItem("ProfilePicture")
        : Login
    );
    const [sesion] = useState(sessionStorage.getItem("clickIniciarSesion"));
    let empiezahome = sessionStorage.getItem("clickEmpezarAhoraHome");
    empiezahome = empiezahome === "true";

    const initLogin = (e) => {
      e.preventDefault();
      e.stopPropagation();
      sessionStorage.setItem("clickIniciarSesion", true);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "clickLogin",
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      history.push("/login");
    };
    const initializeNow = (e) => {
      e.preventDefault();
      e.stopPropagation();
      sessionStorage.setItem("clickEmpezarAhoraHome", true);
      const tagManagerArgs = {
        dataLayer: {
          event: "clickEmpezarAhoraHome",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem("id_session"),
          column: "clickEmpezarAhoraHome",
          collection: "sessions",
          timeStamp: timestamp,
        },
      };
      createClickFront({ variables: createClickInput });
      history.push("/user-validation/");
    };
    const [mostrarLoad, setMostrarLoad] = useState(false);
    const handleOpen = (ruta) => {
      setMenu(false);
      // debugger;
      if (
        url === "/about-us" &&
        sesion == null &&
        ruta === "/the-training-feed/"
      ) {
        sessionStorage.removeItem("clickIniciarSesion");
        history.push("/");
      } else if (url === "/the-training-feed/") {
        history.push("/the-training-feed/");
      } else {
        setMostrarLoad(true);
        setTimeout(() => {
          history.push(ruta);
        }, 1200);
      }
    };

    const handleClickLogo = () => {
      if (sessionStorage.getItem("idUser")) {
        actions({ action: "ClickButtonCoruHome", variables: "MenuLogoCoru" });
      }

      setMenu(false);
      if (
        pathname === "/hook" ||
        pathname === "/answer" ||
        pathname === "/f1" ||
        pathname === "/f2" ||
        pathname === "/f3" ||
        pathname === "/f4" ||
        pathname === "/f5" ||
        pathname === "/c"
      ) {
        history.push("/hook");
      } else if (!empiezahome || !sesion) {
        //  history.push('/the-training-feed/');
      } else {
        //  history.push('/the-training-feed/');
      }
    };

    const actualizaProfilePicture = () => {
      setProfilePicture(
        sessionStorage.getItem("ProfilePicture") !== "null" &&
          sessionStorage.getItem("ProfilePicture") != null
          ? sessionStorage.getItem("ProfilePicture")
          : Login
      );
      setTimeout(() => {
        actualizaProfilePicture();
      }, 1000);
    };

    useEffect(() => {
      actualizaProfilePicture();
    });

    return (
      <Main padding="1rem" style={{ background }}>
        {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
        <div
          className="paddingLaterales home-header-mob"
          style={{ background }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ height: "70px", background }}
          >
            <div onClick={handleClickLogo}>
              <Image
                cursor="pointer"
                src={ImageLogo}
                width="200px"
                mwidth="120px"
                heightMobile=""
                alt="logo-coru"
                onClick={handleClickLogo}
              />
            </div>
            <Header_Home_Web width="160%">
              <Content_Options>
                <Fragment>
                  <Header_Log
                    widthW=" "
                    width=" "
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen("/the-training-feed/");
                    }}
                  >
                    Inicio
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen("/achievements");
                    }}
                  >
                    Logros
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen("/wo/card-funnel");
                    }}
                  >
                    Tarjeta de crédito
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen("/wo/fast-track");
                    }}
                  >
                    Préstamos a tu medida
                  </Header_Log>
                  <Header_Log widthW=" " width=" " marginW="auto 1%">
                    <Paper component="form" className={classes.root}>
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        className={(classes.input, "busqueda")}
                        placeholder="Buscar"
                        inputProps={{ "aria-label": "Buscar" }}
                      />
                    </Paper>
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Cerrar sesión
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    margin="0px 1%"
                    marginW="auto 1%"
                    leftW="1%"
                    rightW="2%"
                  >
                    <Header_Log
                      widthW=" "
                      width=" "
                      onClick={() => {
                        handleOpen("/profile");
                      }}
                    >
                      <Image
                        src={ProfilePicture}
                        border="1px solid #A6A6A6"
                        className="img-fluid"
                        alt=""
                        width="3.5vw"
                      />
                    </Header_Log>
                  </Header_Log>
                </Fragment>
              </Content_Options>
            </Header_Home_Web>
            <Header_Home_Mob>
              <Content_Options>
                <Toggle_btn
                  color="#07210A"
                  className=""
                  onClick={() => setMenu(true)}
                  mfonts="28px"
                >
                  ☰
                </Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
            <Sidepannel
              className={
                viewMenu === true ? "sidepanel view" : "sidepanel no-view"
              }
              id="mySidepanel"
            >
              <div className="closebtn roboto" onClick={() => setMenu(false)}>
                ×
              </div>
              <Image
                cursor="pointer"
                position="absolute"
                marginTop="-47px"
                left=" "
                marginLeft="8%"
                bottom="5%"
                src={ImageLogo}
                width="25%"
                alt="logo-coru"
              />

              {url === "/" || !empiezahome ? (
                <Fragment>
                  <Header_Log_Mob
                    fw="bold"
                    className="roboto"
                    onClick={() => {
                      handleOpen("/");
                    }}
                  >
                    Inicio
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    fw="bold"
                    className="roboto"
                    onClick={() => {
                      handleOpen("/achievements");
                    }}
                  >
                    Logros
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    fw="bold"
                    className="roboto"
                    onClick={() => {
                      handleOpen("/wo/card-funnel");
                    }}
                  >
                    Tarjeta de crédito
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    fw="bold"
                    className="roboto"
                    onClick={() => {
                      handleOpen("/wo/fast-track");
                    }}
                  >
                    Préstamos a tu medida
                  </Header_Log_Mob>
                  <Header_Log_Mob fw="bold" className="roboto">
                    Seguro de auto
                  </Header_Log_Mob>
                  <Header_Log_Mob fw="bold" className="roboto">
                    Seguro de hospitales
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    fw="bold"
                    className="roboto"
                    onClick={() => {
                      handleOpen("/about-us");
                    }}
                  >
                    Coru
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    fw="bold"
                    className="roboto"
                    onClick={() => {
                      handleOpen("/about-us");
                    }}
                  >
                    Contacto
                  </Header_Log_Mob>
                  <Header_Log_Btn
                    bottom="12%"
                    width="80%"
                    top="20%"
                    left="8%"
                    padding="9px 15px!important"
                    className="roboto index-btns"
                    position="absolute"
                    onClick={initializeNow}
                  >
                    Crear una cuenta
                  </Header_Log_Btn>
                  <Header_Log_Mob
                    position="absolute"
                    Pbottom="3%"
                    fw="bold"
                    aling="center"
                    onClick={initLogin}
                    className="roboto index-btns"
                  >
                    Iniciar sesión
                  </Header_Log_Mob>
                  <ContentFiguras>
                    <Rombo
                      width="30.35px"
                      height="30.35px"
                      transform="rotate(46.41deg)"
                      background="#BFDD6A"
                    />
                    <VectorNum
                      back="#6BD5AF"
                      marginleft="20%"
                      margintop="10%"
                    />
                    <Vector1
                      width="56.12px"
                      height="56.12px"
                      background="#E3E660"
                      transform="rotate(-15.06deg)"
                      top="39%"
                      left="38%"
                    />
                    <Rombo
                      width="181.84px"
                      height="181.84px"
                      transform="rotate(-17.24deg)"
                      background="#91D770"
                      marginlef=""
                      margintop="9%"
                      index="-1"
                      position=" absolute"
                    />
                  </ContentFiguras>
                </Fragment>
              ) : (
                <Fragment>
                  <Header_Log_Mob
                    className="text-session roboto"
                    onClick={() => {
                      handleOpen("/profile");
                    }}
                  >
                    {sessionStorage.getItem("FirstName")}
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    className="text-session roboto"
                    onClick={() => {
                      handleOpen("/the-training-feed/");
                    }}
                  >
                    Inicio
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    className="text-session roboto"
                    onClick={() => {
                      handleOpen("/achievements");
                    }}
                  >
                    Logros
                  </Header_Log_Mob>
                  <Header_Log_Mob
                    className="text-session roboto"
                    onClick={() => {
                      handleOpen("/profile");
                    }}
                  >
                    Perfil
                  </Header_Log_Mob>
                  <Header_Log_Btn
                    className="roboto"
                    width="80%"
                    left="8%"
                    padding="9px 15px!important"
                    position="absolute"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Cerrar sesión
                  </Header_Log_Btn>
                  <Header_Log_Mob
                    className="roboto"
                    fw="bold"
                    aling="center"
                    bottom="87px"
                    top="12%"
                    onClick={initLogin}
                  >
                    Buscar
                  </Header_Log_Mob>
                  <ContentFiguras>
                    <Rombo
                      width="30.35px"
                      height="30.35px"
                      transform="rotate(46.41deg)"
                      background="#BFDD6A"
                    />
                    <VectorNum
                      back="#6BD5AF"
                      marginleft="20%"
                      margintop="10%"
                    />
                    <Vector1
                      width="56.12px"
                      height="56.12px"
                      background="#E3E660"
                      transform="rotate(-15.06deg)"
                      top="9%"
                      left="38%"
                    />
                    <Rombo
                      width="181.84px"
                      height="181.84px"
                      transform="rotate(-17.24deg)"
                      background="#91D770"
                      marginlef=""
                      margintop="9%"
                      index="-1"
                      position=" absolute"
                    />
                  </ContentFiguras>
                </Fragment>
              )}
            </Sidepannel>
          </div>
        </div>
      </Main>
    );
  };

  const HeaderHome = (url, background) => {
    const [viewMenu, setMenu] = useState(false);
    const history = useHistory();
    const [ProfilePicture, setProfilePicture] = useState(
      sessionStorage.getItem("ProfilePicture") !== "null" &&
        sessionStorage.getItem("ProfilePicture") != null
        ? sessionStorage.getItem("ProfilePicture")
        : Login
    );
    const [sesion] = useState(sessionStorage.getItem("clickIniciarSesion"));
    let empiezahome = sessionStorage.getItem("clickEmpezarAhoraHome");
    empiezahome = empiezahome === "true";

    const initLogin = (e) => {
      e.preventDefault();
      e.stopPropagation();
      sessionStorage.setItem("clickIniciarSesion", true);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "clickLogin",
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      sessionStorage.setItem("focusheader", "");
      actions({
        action: "ClickButtonCoruHome",
        variables: "MenuLogin",
        redirection: "/login",
      });
      // history.push('/login');
    };
    const aboutUs = () => {
      actions({
        action: "ClickButtonCoruHome",
        variables: "MenuAboutUs",
        redirection: "/about-us",
      });
      // history.push('/about-us');
    };

    const initializeNow = (e) => {
      e.preventDefault();
      e.stopPropagation();
      sessionStorage.setItem("clickEmpezarAhoraHome", true);
      const tagManagerArgs = {
        dataLayer: {
          event: "clickEmpezarAhoraHome",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem("id_session"),
          column: "clickEmpezarAhoraHome",
          collection: "sessions",
          timeStamp: timestamp,
        },
      };
      createClickFront({ variables: createClickInput });
      sessionStorage.setItem("focusheader", "");
      actions({
        action: "ClickButtonCoruHome",
        variables: "MenuRegistro",
        redirection: "/user-validation/",
      });
      // history.push('/user-validation/');
    };
    const [mostrarLoad, setMostrarLoad] = useState(false);

    const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
    useEffect(() => {
      setheigthBrowser(window.outerHeight);
    }, [window.outerHeight]);

    const handleClickLogo = () => {
      if (sessionStorage.getItem("idUser")) {
        actions({ action: "ClickButtonCoruHome", variables: "MenuLogoCoru" });
      }

      setMenu(false);
      if (
        pathname === "/hook" ||
        pathname === "/answer" ||
        pathname === "/f1" ||
        pathname === "/f2" ||
        pathname === "/f3" ||
        pathname === "/f4" ||
        pathname === "/f5" ||
        pathname === "/c"
      ) {
        history.push("/hook");
      } else if (!empiezahome || !sesion) {
        history.push("/");
      } else {
        history.push("/the-training-feed/");
      }
      sessionStorage.setItem("focusheader", "");
    };
    const actualizaProfilePicture = () => {
      setProfilePicture(
        sessionStorage.getItem("ProfilePicture") !== "null" &&
          sessionStorage.getItem("ProfilePicture") != null
          ? sessionStorage.getItem("ProfilePicture")
          : Login
      );
      setTimeout(() => {
        actualizaProfilePicture();
      }, 1000);
    };

    useEffect(() => {
      actualizaProfilePicture();
    });

    const [focusHeader, setFocusHeader] = useState({
      Entrenamientos:
        sessionStorage.getItem("focusheader") === "Entrenamientos",
      Aliados: sessionStorage.getItem("focusheader") === "Aliados",
      Comparte: sessionStorage.getItem("focusheader") === "Comparte",
      AboutUs: sessionStorage.getItem("focusheader") === "AboutUs",
    });

    const Activate = (nav) => {
      //  debugger;
      switch (nav) {
        case "Entrenamientos":
          setFocusHeader({
            Entrenamientos: true,
            Aliados: false,
            Comparte: false,
            AboutUs: false,
          });
          sessionStorage.setItem("focusheader", "Entrenamientos");
          actions({
            action: "ClickButtonCoruHome",
            variables: "MenuEntrenamientos",
          });
          break;
        case "Aliados":
          setFocusHeader({
            Entrenamientos: false,
            Aliados: true,
            Comparte: false,
            AboutUs: false,
          });
          sessionStorage.setItem("focusheader", "Aliados");
          actions({ action: "ClickButtonCoruHome", variables: "MenuAliados" });
          break;
        case "Comparte":
          setFocusHeader({
            Entrenamientos: false,
            Aliados: false,
            Comparte: true,
            AboutUs: false,
          });
          sessionStorage.setItem("focusheader", "Comparte");
          actions({ action: "ClickButtonCoruHome", variables: "MenuComparte" });
          break;
        case "AboutUs":
          setFocusHeader({
            Entrenamientos: false,
            Aliados: false,
            Comparte: false,
            AboutUs: true,
          });
          sessionStorage.setItem("focusheader", "AboutUs");
          // actions({ action: 'ClickButtonCollection', variables: 'MenuAboutUs' });
          break;
        default:
          break;
      }
    };
    return (
      <Fragment>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="d-flex flex-row col-11 col-sm-12">
            <a
              className="navbar-brand col-11 col-md-2"
              href="#"
              style={{ alignSelf: "center", alignContent: "space-between" }}
            >
              <Image
                cursor="pointer"
                src={ImageLogo}
                width="70%"
                height="auto"
                maxwidth="130px"
                mheight="28px"
                mwidth="97.92px"
                heightMobile="20.8px"
                alt="logo-coru"
                onClick={handleClickLogo}
              />
            </a>
            <Header_Home_Mob>
              <Content_Options className="col-1 col-md-11">
                <Toggle_btn
                  color="#07210A"
                  className=""
                  onClick={() => setMenu(true)}
                  mfonts="28px"
                >
                  ☰
                </Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
            <div className="collapse navbar-collapse web" id="navHome">
              <ul
                className="navbar-nav mr-auto mt-2 mt-lg-0 px-5"
                id="navTextHome"
              >
                <li
                  className={
                    focusHeader.Entrenamientos ? "nav-item active" : "nav-item "
                  }
                >
                  <a
                    href="#Entrenamientos"
                    className="textHeader roboto nav-link"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      Activate("Entrenamientos");
                    }}
                  >
                    Entrenamientos<br></br>
                    {focusHeader.Entrenamientos ? (
                      <hr
                        style={{
                          width: "35%",
                          background: "#d31e4d",
                          margin: "0px",
                        }}
                      ></hr>
                    ) : null}
                  </a>
                </li>
                <li
                  className={
                    focusHeader.Aliados ? "nav-item active" : "nav-item "
                  }
                >
                  <a
                    href="#Aliados"
                    className="textHeader roboto nav-link"
                    onClick={() => {
                      Activate("Aliados");
                    }}
                  >
                    Aliados comerciales<br></br>
                    {focusHeader.Aliados ? (
                      <hr
                        style={{
                          width: "35%",
                          background: "#d31e4d",
                          margin: "0px",
                        }}
                      ></hr>
                    ) : null}
                  </a>
                </li>
                <li
                  className={
                    focusHeader.Comparte ? "nav-item active" : "nav-item "
                  }
                >
                  <a
                    href="#Comparte"
                    className="textHeader roboto nav-link"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      Activate("Comparte");
                    }}
                  >
                    Comparte Coru<br></br>
                    {focusHeader.Comparte ? (
                      <hr
                        style={{
                          width: "35%",
                          background: "#d31e4d",
                          margin: "0px",
                        }}
                      ></hr>
                    ) : null}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="textHeader roboto nav-link"
                    style={{
                      textAlign: "center",
                      paddingRight: "2rem",
                      cursor: "pointer",
                    }}
                    onClick={aboutUs}
                  >
                    ¿Qué es Coru?
                  </a>
                </li>
                <li
                  className="nav-item"
                  style={{ borderLeft: "1px solid #A6A6A6" }}
                >
                  <a
                    href="#"
                    className="textHeader text-session roboto nav-link text-hover"
                    onClick={initializeNow}
                    style={{
                      textAlign: "center",
                      paddingLeft: "2rem",
                      minWidth: "120px",
                    }}
                  >
                    Regístrate
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#"
                    className="textHeader text-session roboto nav-link text-hover"
                    onClick={initLogin}
                    style={{ textAlign: "right", minWidth: "122px" }}
                  >
                    Iniciar sesión
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Sidepannel
          className={viewMenu === true ? "sidepanel view" : "sidepanel no-view"}
          id="mySidepanel"
        >
          <div className="closebtn roboto" onClick={() => setMenu(false)}>
            ×
          </div>
          <Image
            cursor="pointer"
            position="absolute"
            marginTop="-47px"
            left=" "
            marginLeft="8%"
            bottom="5%"
            src={ImageLogo}
            width="25%"
            alt="logo-coru"
          />
          <Header_Log_Mob fw="bold" className="roboto">
            <a
              href="#Entrenamientos"
              className="textHeader text-session roboto"
            >
              Entrenamientos
            </a>
          </Header_Log_Mob>
          <Header_Log_Mob fw="bold" className="roboto">
            <a href="#Aliados" className="textHeader text-session roboto">
              Aliados comerciales
            </a>
          </Header_Log_Mob>
          <Header_Log_Mob fw="bold" className="roboto">
            <a href="#Comparte" className="textHeader text-session roboto">
              Comparte Coru
            </a>
          </Header_Log_Mob>
          <Header_Log_Mob fw="bold" className="roboto">
            <a onClick={aboutUs} className="textHeader text-session roboto">
              ¿Qué es Coru?
            </a>
          </Header_Log_Mob>
          <Header_Log_Mob fw="bold" className="roboto">
            <a
              onClick={handleOpenKanda}
              className="textHeader text-session roboto"
            >
              {/* Kanda */} Tu Plan
            </a>
          </Header_Log_Mob>
          <Header_Log_Btn
            bottom="12%"
            width="80%"
            top="20%"
            left="8%"
            padding="9px 15px!important"
            className="roboto index-btns"
            position="absolute"
            onClick={initializeNow}
          >
            Crear una cuenta
          </Header_Log_Btn>
          <Header_Log_Mob
            position="absolute"
            Pbottom="3%"
            fw="bold"
            aling="center"
            onClick={initLogin}
            className="roboto index-btns"
          >
            Iniciar sesión
          </Header_Log_Mob>
          <ContentFiguras>
            <Rombo
              width="30.35px"
              height="30.35px"
              transform="rotate(46.41deg)"
              background="#BFDD6A"
            />
            <VectorNum back="#6BD5AF" marginleft="20%" margintop="10%" />
            <Vector1
              width="56.12px"
              height="56.12px"
              background="#E3E660"
              transform="rotate(-15.06deg)"
              top="39%"
              left="38%"
            />
            <Rombo
              width="181.84px"
              height="181.84px"
              transform="rotate(-17.24deg)"
              background="#91D770"
              marginlef=""
              margintop="9%"
              index="-1"
              position=" absolute"
            />
          </ContentFiguras>
        </Sidepannel>
      </Fragment>
    );
  };

  const HeaderLP = ({ url, background, backurl }) => {
    const history = useHistory();
    let empiezahome = sessionStorage.getItem("clickEmpezarAhoraHome");
    const [ProfilePicture, setProfilePicture] = useState(
      sessionStorage.getItem("ProfilePicture") !== "null" &&
        sessionStorage.getItem("ProfilePicture") != null
        ? sessionStorage.getItem("ProfilePicture")
        : Login
    );
    empiezahome = empiezahome === "true";
    const handleClick = () => {
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "clickFeedHeader",
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      history.push("/the-training-feed/");
    };
    const [viewMenu, setMenu] = useState(false);
    const handleOpen = (ruta, click) => {
      createClickFront({
        variables: {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: click,
            collection: "sessions",
            timeStamp: timestamp,
          },
        },
      });
      setMenu(false);
      if (ruta === "/") {
        setMostrarLoad(true);
        sessionStorage.removeItem("clickIniciarSesion");
      }
      setMostrarLoad(true);
      if (ruta === "/achievements") {
        sessionStorage.setItem("originFeed", true);
        setTimeout(() => {
          setMostrarLoad(false);
        }, 1200);
      }
      setTimeout(() => {
        history.push(ruta);
      }, 1200);
    };
    const actualizaProfilePicture = () => {
      setProfilePicture(
        sessionStorage.getItem("ProfilePicture") !== "null" &&
          sessionStorage.getItem("ProfilePicture") != null
          ? sessionStorage.getItem("ProfilePicture")
          : Login
      );
      setTimeout(() => {
        actualizaProfilePicture();
      }, 1000);
    };

    useEffect(() => {
      actualizaProfilePicture();
    });

    let isViernes = sessionStorage.getItem("dayOfWeek") === "hola";

    const [getDayOfWeek] = useLazyQuery(GET_DAY_OF_WEEK, {
      onCompleted({ getDayOfWeek }) {
        const { today } = getDayOfWeek;
        sessionStorage.setItem("dayOfWeek", today);
        isViernes = today === "hola";
      },
      onError(err) {
        console.log(err);
      },
    });

    useEffect(() => {
      getDayOfWeek();
    }, []);

    const initializeNow = () => {
      history.push("/login");
    };

    return (
      <Main
        padding="1rem"
        overflow="none!important"
        urlActual={window.location.pathname}
        style={{ background }}
      >
        {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
        <div
          className={
            backurl !== undefined
              ? "BackHeader paddingLaterales home-header-mob"
              : "paddingLaterales home-header-mob"
          }
          style={{
            background,
            backgroundImage: backurl !== undefined ? `url(${backurl})` : " ",
          }}
        >
          <div
            className={
              backurl !== undefined
                ? "BackHeader d-flex justify-content-between align-items-center"
                : "d-flex justify-content-between align-items-center"
            }
            style={{
              height: "70px",
              background,
              backgroundImage: backurl !== undefined ? `url(${backurl})` : " ",
            }}
          >
            <div onClick={handleClick}>
              <Image
                cursor="pointer"
                src={ImageLogo}
                width="200px"
                mwidth="120px"
                heightMobile=""
                alt="logo-coru"
                onClick={handleClick}
              />
            </div>
            <Header_Home_Web width="160%">
              <Content_Options>
                <Fragment>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="auto"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      initializeNow();
                    }}
                  >
                    Iniciar sesión
                  </Header_Log>
                </Fragment>
              </Content_Options>
            </Header_Home_Web>
            <Header_Home_Mob>
              <Content_Options>
                <Toggle_btn
                  color="#07210A"
                  className="roboto"
                  onClick={() => {
                    initializeNow();
                  }}
                  mfonts="18px"
                >
                  Iniciar sesión
                </Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
            <Sidepannel
              className={
                viewMenu === true ? "sidepanel view" : "sidepanel no-view"
              }
              id="mySidepanel"
            >
              <div className="closebtn roboto" onClick={() => setMenu(false)}>
                ×
              </div>
              <Image
                cursor="pointer"
                position="absolute"
                marginTop="-47px"
                left=" "
                marginLeft="8%"
                bottom="5%"
                src={ImageLogo}
                width="25%"
                alt="logo-coru"
              />
              <Fragment>
                <Header_Log_Mob
                  className="text-session roboto"
                  onClick={() => {
                    initializeNow();
                  }}
                >
                  Iniciar sesión
                </Header_Log_Mob>
                <ContentFiguras>
                  <Rombo
                    width="30.35px"
                    height="30.35px"
                    transform="rotate(46.41deg)"
                    background="#BFDD6A"
                  />
                  <VectorNum back="#6BD5AF" marginleft="20%" margintop="10%" />
                  <Vector1
                    width="56.12px"
                    height="56.12px"
                    background="#E3E660"
                    transform="rotate(-15.06deg)"
                    top="9%"
                    left="38%"
                  />
                  <Rombo
                    width="181.84px"
                    height="181.84px"
                    transform="rotate(-17.24deg)"
                    background="#91D770"
                    marginlef=""
                    margintop="9%"
                    index="-1"
                    position=" absolute"
                  />
                </ContentFiguras>
              </Fragment>
            </Sidepannel>
          </div>
        </div>
      </Main>
    );
  };

  let HeaderComponent;
  if (
    pathname === "/hook" ||
    pathname === "/hook/" ||
    pathname === "/answer" ||
    pathname === "/f1" ||
    pathname === "/f2" ||
    pathname === "/f3" ||
    pathname === "/f4" ||
    pathname === "/f5" ||
    pathname === "/c" ||
    pathname === "/privacy-notice" ||
    pathname === "/wo/profiling"
  ) {
    HeaderComponent = (
      <HeaderOne
        url={pathname}
        background={background !== undefined ? background : "#FAFAFA"}
      />
    );
  } else if (pathname === "/") {
    HeaderComponent = (
      <HeaderHome
        url={pathname}
        background={background !== undefined ? background : "#FAFAFA"}
      />
    );
  } else if (pathname === "/admin") {
    HeaderComponent = <HeaderAdmin />;
  } else if (
    pathname === "/login" ||
    pathname === "/login-v2" ||
    pathname === "/forgot-password" ||
    pathname === "/reset-password" ||
    pathname === "/about-us"
  ) {
    HeaderComponent = <HeaderLogin />;
  } else if (
    pathname === "/user-validation" ||
    pathname === "/user-validation-otp" ||
    pathname === "/personal-Information" ||
    pathname === "/personal-Information-v2" ||
    pathname === "/new-password"
  ) {
    HeaderComponent = <HeaderRegister />;
  } else if (
    String(pathname).includes("/p/analytics/") ||
    pathname === "/admin-home/" ||
    pathname === "/admin-home" ||
    pathname === "/admin-home/pixel/" ||
    pathname === "/admin-home/shortlink/" ||
    pathname === "/admin-home/config-otp/" ||
    pathname === "/admin-home/trainings" ||
    pathname === "/admin-home/imageUpload/" ||
    pathname === "/admin-home/transactional" ||
    pathname === "/admin-home/template/" ||
    pathname === "/admin-home/campaign/" ||
    pathname === "/admin-home/dashboardSnc/" ||
    pathname === "/admin-home/blacklist/" ||
    pathname === "/admin-home/overview/" ||
    pathname === "/admin-home/opportunititesInfo/" ||
    pathname === "/admin-home/viewUserInfoDetail/"
  ) {
    HeaderComponent = <div></div>;
  } else if (
    pathname === "/coach/trainings/lp-first-investment" ||
    pathname === "/coach/Training/LpObtenerTarjeta"
  ) {
    HeaderComponent = (
      <HeaderLP
        url={pathname}
        background={background !== undefined ? background : "#FAFAFA"}
        backurl={backurl}
      />
    );
  } else if (
    pathname === "/wo/card-funnel/success" ||
    pathname === "/wo/personal-loans/success"
  ) {
    HeaderComponent = (
      <HeaderTwo
        url={pathname}
        background={background !== undefined ? background : "#FAFAFA"}
        backurl={backurl}
        categories={categories}
      />
    );
  } else {
    HeaderComponent = (
      <HeaderTwo
        url={pathname}
        background={background !== undefined ? background : "#FAFAFA"}
        backurl={backurl}
        categories={categories}
      />
    );
  }

  return <Fragment>{HeaderComponent}</Fragment>;
};

export default Header;
