import React, { useEffect, useState } from 'react';
import '../../assets/styles/Home.css';
import { useMutation } from '@apollo/client';
import enproceso from '../../assets/img/anacoach/faces-02.png';
import {
  DYNAMIC_GET,
  INSERT_FINISHED_WO_BY_XCOACH,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import SectionShare from '../../components/common/ShareSection';
import Recommendations from '../../components/Training/RecommendedTraining';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import { Container } from '../../assets/styles/Home.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingSectionFlex,
  TrainigTam,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';
import { SeparateSection, Feed_Section } from '../../assets/styles/Feed.styled';
import Resultados from '../../components/Training/Success_Result';
import Header from '../../components/common/Header';
import WOlayout from '../../components/layouts/WOlayout';
import ModalAmazon from '../../components/common/ModalAmazon';
import ModalVirality from '../../components/common/ModalVirality';
import InfoWoMonetizable from '../../components/common/InfoWoMonetizable';

function SuccessTarjeta() {
  let totalSol = '';
  let selectProduct = [];
  const resultadosItems = [];
  const oneResult = [];

  if (
    sessionStorage.getItem('CatProductos') != null
    && sessionStorage.getItem('datProducts') != null
  ) {
    const jsonProducts = JSON.parse(sessionStorage.getItem('CatProductos'));

    totalSol = sessionStorage.getItem('datOpportunity').split(',').length;
    const arrayIdProducts = sessionStorage.getItem('datProducts').split(',');
    for (let i = 0; i < arrayIdProducts.length; i++) {
      const productCard = jsonProducts.filter((element) => {
        if (parseInt(element.IDProduct) === parseInt(arrayIdProducts[i])) {
          console.log(element.IDProduct, arrayIdProducts[i]);
          selectProduct.push(element);
        }
      });
    }
  } else {
    selectProduct = [
      {
        IDProduct: 2,
        Name: 'The Gold Elite Credit Card American Express',
        ImageURL:
          'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/GoldEliteAMEX.png',
      },
    ];
  }

  const dateNow = new Date();
  if (selectProduct.length === 3) {
    for (let i = 0; i < selectProduct.length; i++) {
      resultadosItems.push({
        id: selectProduct[i].IDProduct,
        Name: selectProduct[i].Name || '',
        imgCard: selectProduct[i].ImageURL,
        titleCard: selectProduct[i].Name,
        ClickOut: selectProduct[i].ClickOut,
        hasClickOut: selectProduct[i].hasClickOut,
        ScrapperHabilitado: selectProduct[i].Enabled,
        imgresult: enproceso,
        resultado: 'En proceso',
        msgresultado: '¡Espera! Seguimos trabajando',
        info: `${'Fecha de solicitud  ' + ' '}${dateNow.getDate()}/${
          dateNow.getMonth() + 1
        }/${dateNow.getFullYear()}`,
        txtadicional:
          'Tu solicitud no pudo ser completada en línea. Te enviaremos el estatus a tu correo electrónico registrado.',
        documentos: '',
      });
    }
  } else {
    for (let i = 0; i < selectProduct.length; i++) {
      oneResult.push({
        id: selectProduct[i].IDProduct,
        Name: selectProduct[i].Name || '',
        imgCard: selectProduct[i].ImageURL,
        titleCard: selectProduct[i].Name,
        ClickOut: selectProduct[i].ClickOut,
        hasClickOut: selectProduct[i].hasClickOut,
        ScrapperHabilitado: selectProduct[i].Enabled,
        imgresult: enproceso,
        resultado: 'En proceso',
        msgresultado: '¡Espera! Seguimos trabajando',
        info: `${'Fecha de solicitud' + ' '}${dateNow.getDate()} / ${
          dateNow.getMonth() + 1
        } / ${dateNow.getFullYear()}`,
        txtadicional:
          'Tu solicitud no pudo ser completada en línea. Te enviaremos el estatus a tu correo electrónico registrado.',
        documentos: '',
      });
    }
  }

  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';

  firstname = sessionStorage.getItem('FirstName');
  nameClient = `${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem(
    'FathersLastName',
  )}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');

  const resultsProducts = [];

  useEffect(() => {
    if (sessionStorage.getItem('datOpportunity') !== null) {
      const opportunities = sessionStorage.getItem('datOpportunity').split(',');
      for (const i in opportunities) {
        const getProductsInput = {
          input: {
            table: 'DatOpportunity, CatProduct',
            columns: ['*'],
            conditions: [
              {
                valone: 'IDProduct',
                valtwo: 'CatProduct_IDProduct',
                condition: '=',
                validColumn: true,
              },
              {
                valone: 'IDDatOpportunity',
                valtwo: opportunities[i],
                condition: '=',
                logic: 'AND',
              },
            ],
          },
        };
        getOpportunity({ variables: getProductsInput });
      }
    } else {
      console.log('Sin data opportunity');
    }
  }, []);

  const [getOpportunity] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const productsDetails = JSON.parse(dynamicGet.response)[0];
        resultsProducts.push(productsDetails);
        console.log(productsDetails);
      }
    },
  });

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    const idTraining = sessionStorage.getItem('idTraining');
    const fromXCoach = sessionStorage.getItem('fromXCoach');
    const idAdviser = sessionStorage.getItem('idAdviser');
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === 'true' ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  const [top, setTop] = useState(false);
  useEffect(() => {
    if (top === false) {
      const scroll = document.getElementById('top');
      scroll.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      setTop(true);
    }
  }, []);

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${
              getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  return (
    <WOlayout
      backgroundColor={'#DE98FF'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <ModalVirality></ModalVirality>
      <TrainigTam>
        <Header background={'#DE98FF'} pathname={window.location.pathname} />
      </TrainigTam>
      <HeaderSuccess
        color1="#A370FF"
        color2="#8358C2"
        color3="#CBBFED"
        color4="#DE98FF"
        color5="#8892E3"
        color6="#BE7AE6"
        color7="#E2C2EE"
        color8="#A370FF"
        color9="#8892E3"
        color10="#6677E3"
      />
      <TrainingMain id="top">
        <ModalAmazon show={true} wo={'PrimeraTarjeta'}></ModalAmazon>
        <TrainingSectionGray back="#f4f6f6">
          <TrainingDivSection2 back="#f4f6f6">
            <br />
            <br />
            <TrainingSubtitles fw="600">
              ¡Gracias {firstname}!
            </TrainingSubtitles>
            <TrainingText className="dm-sans" align="center">
              Hemos procesado tu solicitud
              <br />y este es el resultado.
            </TrainingText>
            <br />
            <InfoWoMonetizable 
              nameClient={nameClient}
              telClient={telClient}
              emailClient={emailClient}
            />
          </TrainingDivSection2>
          <SeparateSection />
        </TrainingSectionGray>
        <div className="marginComentLgSuccess1">
          <TrainingDivSection
            wi="100%"
            back="transparent"
            backw="transparent"
            padding="0 5%"
          >
            <TrainingSectionFlex>
              <Resultados
                idTraining={sessionStorage.getItem('idTraining')}
                resultados={totalSol === 3 ? resultadosItems : oneResult}
              ></Resultados>
            </TrainingSectionFlex>
          </TrainingDivSection>
          {recomendaciones ? (
            <div className="marginComentLgSuccess1">
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </div>
          ) : null}
          <SectionShare WO="PrimeraTarjeta"> </SectionShare>
        </div>
      </TrainingMain>
    </WOlayout>
  );
}

export default SuccessTarjeta;
