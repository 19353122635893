import React, { Fragment } from 'react';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import SuperSeguros from '../Routines/SuperSeguros';

const RoutinesOrderLpSs = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
}) => {
  return (
    <Fragment>
      {orderRutine >= 1 ? (
        <DatBasic
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={1}
          nextOrderRoutine={2}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={2}
          nextOrderRoutine={3}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 3 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={3}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null}

      {orderRutine >= 4 ? (
        <SuperSeguros
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={4}
          nextOrderRoutine={5}
        />
      ) : null}
    </Fragment>
  );
};

export default RoutinesOrderLpSs;
