/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';

import {
  TrainingText,
  TrainingQuestion,
  QuestionsDivs,
  TrainingDivSection,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import Modal from "@material-ui/core/Modal";
import ModalContent from '../Training/ModalCrediteaPL';


const Referencias = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  crediteaVal,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const { sendMessage } = SendNotificationFTAndCC();
  const { sendEmail } = SendNotificationEmail();
  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRoutine('activa');
              setQuestions([]);
              if (currentRoutine === 12 && nextOrderRoutine === 13) {
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRoutine('inactiva');
    }
  }, []);

  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [Referencias, setReferencias] = useState(
    sessionStorage.getItem('Referencias') || '',
  );
  // Cambio de pantalla a resultados finales
  const history = useHistory();
  const hostcheck = window.location.pathname;
  useEffect(() => {
    if (Referencias !== '') {
      if (!(currentRoutine === 12 && nextOrderRoutine === 13)) {
        sendMessage({
          cellPhone: sessionStorage.getItem('CellPhone'),
          trigger: 'Notificación_FT_5_AUX',
        });
        sendMessage({
          cellPhone: sessionStorage.getItem('CellPhone'),
          trigger: 'Notificación_FT_5',
        });
        sendEmail({ WO: 'CC', trigger: 'alerta_cc_5_mail' });
        const timer = setTimeout(() => {
          if (hostcheck.includes('loans-funnel')) {
            history.push('/wo/personal-loans/success');
          } else if (hostcheck.includes('first-card')) {
            history.push('/wo/first-card/success');
          } else if (hostcheck.includes('compare-credit-card')) {
            history.push('/wo/card-compare/success');
          } else {
            history.push('/wo/card-funnel/success');
          }
        }, 5000);
        return () => clearTimeout(timer);
      }
    }
  }, [Referencias]);

  const [form, setForm] = useState({
    nameref1: '',
    nameref2: '',
    telref1: '',
    telref2: '',
  });
  // temporal
  const [texterror] = useState({
    nameref1: '',
    nameref2: '',
    telref1: '',
    telref2: '',
  });

  const [error] = useState({
    nameref1: false,
    nameref2: false,
    telref1: false,
    telref2: false,
  });

  const ValidPhone = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const path = window.location.pathname;
    let tel = '';
    if (path.includes('funnel-prestamos')) {
      tel = document.getElementById(id);
    } else if (path.includes('obtener-primer-tarjeta')) {
      tel = document.getElementsByName(name)[0];
    } else if (path.includes('comparar-tarjeta')) {
      tel = document.getElementsByName(name)[0];
    } else {
      tel = document.getElementById(id);
    }
    console.log(id);
    if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const elements = document.querySelectorAll(`[name=${nombre}`);
    const element = elements[elements.length - 1];
    if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
      console.log('Element ===>>>', element);
    } else if (nombre === 'secondname') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (
      type === 'Cat'
      || name === 'RFC'
      || name === 'digitoscard'
      || name === 'bank'
      || name === 'empresa'
    ) {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
  };
  const referencesAreValid = () => {
    let areValid = true;
    if (form.telref1.length < 10) {
      areValid = false;
    }
    if (form.telref2.length < 10) {
      areValid = false;
    }
    return areValid;
  };
  const handelChangeReferencias = () => {
    if (referencesAreValid()) {
      console.log('referencias');
      setReferencias('Referencias'); // succes
      createJsonInput(Rutine, questions);
      setQuestions([]);
      const click = `EnviarReferencias_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setShowCard(true);
      if (currentRoutine === 12 && nextOrderRoutine === 13) { // succes
        setOrderRutine(nextOrderRoutine);
      }
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const nombreCompletoRef = sessionStorage.getItem('Nombre_Completo_Referencia_Familiar') || '';
      const telRef = sessionStorage.getItem('Telefono_de_Referencia_Familiar') || '';
      const nombreCompletoNoFamRef = sessionStorage.getItem(
        'Nombre_Completo_de_una_Referencia_no_familiar',
      ) || '';
      const telNoFamRef = sessionStorage.getItem('Telefono_de_Referencia_no_Familiar') || '';
      setForm({
        ...form,
        nameref1: nombreCompletoRef,
        nameref2: nombreCompletoNoFamRef,
        telref1: telRef,
        telref2: telNoFamRef,
      });

      if (
        nombreCompletoRef !== ''
        || telRef !== ''
        || nombreCompletoNoFamRef !== ''
        || telNoFamRef !== ''
      ) {
        setReferencias('Referencias');
        if (currentRoutine === 12 && nextOrderRoutine === 13) {
          setOrderRutine(nextOrderRoutine);
        }
      }
    }
  }, [recoveryRoutine]);

  //Creditea mod
  const [creditea, setCreditea] = useState(false)
  const [continueCreditea,setContinueCreditea] = useState()
  const [open, setOpen] = useState(false);
  const [showCard, setShowCard] = useState(false)
  const [loadingCard, setLoadingCard] = useState(false)

  const acceptCreditea = () => {
    setCreditea(true);
    setTimeout(() => {
      setLoadingCard(!loadingCard)
    },4000)
  }

  const nextStepCreditea = () => {
    setContinueCreditea(true); setOpen(true)
  }

  const notConinueCreditea = () =>{
    setContinueCreditea(false); handelChangeReferencias()
  }

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#activeReferencias' });
    }, 4000);
  }, []);
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case 'telref1':
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      case 'telref2':
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {/* REFERENICIAS */}
      {!loaderWait ? (
        <Fragment>
          <div id="activeReferencias"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || Referencias !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            textbold="¡Estamos en la recta final! 😊"
            text="Indícame 2 referencias familiares o personales para continuar tu solicitud."
            text2="No te preocupes no le llamaremos a tus referencias."
            hora={hora}
          />
          <Container
            width="80%"
            mt="0"
            widthMobile="90%"
            mtMobile="0"
            displayweb={
              orderRutine === currentRoutine && Referencias === ''
                ? 'flex'
                : 'none'
            }
            displaymobile={
              orderRutine === currentRoutine && Referencias === ''
                ? 'flex'
                : 'none'
            }
          >
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
            <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
              <TrainingDivSection
                hew="auto"
                paddw="26px"
                mlw="0%"
                mtw="8%"
                wiw="100%"
                back="#F3F3F3"
                backw="#F3F3F3"
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"
                className="topOne1 topOne1Init"
              >
                <TrainingQuestion
                  width="100%"
                  wiw="100%"
                  mt="0"
                  className={
                    orderRutine === currentRoutine && Referencias === ''
                      ? ''
                      : 'hidden'
                  }
                >
                  <QuestionsDivs
                    padd="0"
                    txta="left"
                    width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
                    widthweb="100%"
                    hei="auto"
                    margin="0 auto auto"
                    bc="#F3F3F3"
                  >
                    <form>
                      <TrainingText
                        className="dm-sans"
                        align="left"
                        weight="600"
                      >
                        Referencia 1
                      </TrainingText>
                      {orderRutine === currentRoutine ? (
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="0-0"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                        />
                      ) : null}
                      {orderRutine === currentRoutine ? (
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="1-1"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                          activeTelefonoFijo={true}
                        />
                      ) : null}
                      <br />
                      <br />
                      <TrainingText
                        className="dm-sans"
                        align="left"
                        weight="600"
                      >
                        Referencia 2
                      </TrainingText>
                      {orderRutine === currentRoutine ? (
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="2-2"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                        />
                      ) : null}
                      {orderRutine === currentRoutine ? (
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="3-3"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                          activeTelefonoFijo={true}
                        />
                      ) : null}
                      {crediteaVal ? <CardsBtn
                        mtop="10px"
                        mtopw="10px"
                        mtopmd="10px"
                        type="button"
                        min_w="100%"
                        min_web="100%"
                        //Creditea ---------------------
                        onClick={() => {acceptCreditea()}}
                      >
                        Enviar
                      </CardsBtn>
                      :
                      <><CardsBtn
                      mtop="10px"
                      mtopw="10px"
                      mtopmd="10px"
                      type="button"
                      min_w="100%"
                      min_web="100%"
                      onClick={() => handelChangeReferencias()}
                    >
                      Enviar
                    </CardsBtn></>}
                    </form>
                  </QuestionsDivs>
                </TrainingQuestion>
              </TrainingDivSection>
            </Col7>
          </Container>
        </Fragment>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {creditea
      ?<>
      <CardTwo
        classCard={creditea ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={'Referencia 1: '}
        text2={`${form.nameref1} - ${form.telref1}`}
        text3={' Referencia 2: '}
        text4={`${form.nameref2} - ${form.telref2}`}
        colorTraining={trainingColor}
      />
      { !loadingCard ? <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
        : <Fragment>
        <div id="activeReferencias"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard={
            orderRutine === currentRoutine || Referencias !== ''
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          textbold="¿Necesitas dinero urgente? ¡Tienes un crédito pre-aprobado! 🤩"
          text="Todo indica que puedes aplicar a  unos préstamos personales acordes a tu perfil y obtener dinero en menos de 24 horas."
          text2="¿Te gustaría saber el monto de tus créditos pre-aprobados?"
          hora={hora}
          btn={true}
          btnText={"Sí, ver créditos pre-aprobados"}
          btn2={true}
          btnText2={"No busco préstamos por ahora"}
          funct={nextStepCreditea}
          funct2={notConinueCreditea}
        />
        <Container
          width="80%"
          mt="0"
          widthMobile="90%"
          mtMobile="0"
          displayweb={
            orderRutine === currentRoutine && Referencias === ''
              ? 'flex'
              : 'none'
          }
          displaymobile={
            orderRutine === currentRoutine && Referencias === ''
              ? 'flex'
              : 'none'
          }
        >
        </Container>
      </Fragment>

      }
      </>
      :<CardTwo
      classCard={ showCard ? 'cardTwoResp': 'hidden'}
      classTop="topTwo1"
      imgMan="hidden"
      text={'Referencia 1: '}
      text2={`${form.nameref1} - ${form.telref1}`}
      text3={' Referencia 2: '}
      text4={`${form.nameref2} - ${form.telref2}`}
      colorTraining={trainingColor}
    />
      }
      { continueCreditea ?<>
        {/* 
          -Lograr que cargue primero la pantalla de Referencias (parte de usuario) Antes que la card-opcion a modal
        */}
        <Modal
                        open={open}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{
                          backgroundColor: "rgba(7, 11, 17, 0.6)",
                          backdropFilter: " blur(8px)",
                          position: "fixed",
                          bottom: "0px",
                          top: "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ModalContent close={setOpen} setOrder={handelChangeReferencias} />
                      </Modal>
      </> :null
      }
      {!(currentRoutine === 12 && nextOrderRoutine === 13) ? (
        
        <>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={Referencias !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            textbold="¡Gracias por tus respuestas! 😉"
            text="Espera unos segundos  mientras procesamos tu solicitud."
            hora={hora}
          />
          <Container
            width="80%"
            mt="0"
            widthMobile="90%"
            mtMobile="0"
            displayweb={Referencias !== '' ? 'flex' : 'none'}
            displaymobile={Referencias !== '' ? 'flex' : 'none'}
          >
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
            <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        </>
      ) : null}
    </div>
  );
};

export default Referencias;
