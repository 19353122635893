import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_EMAIL_TELEFONO } from '../../../mutations'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { ContentHomeSection } from '../../common/Home.styled';
import { ParagrapTwo } from '../../common/common.styled';
import InputLabel from '@material-ui/core/InputLabel';
import { ErrorForm } from '../Styleds/Asesores.Styled';
import '../../../assets/styles/TextCustom.css';
import { ParrafoSuccess } from '../Styleds/Asesores.Styled';
import { CorrecionCorreo } from '../../common/common.styled';

const ModalDatosUpdate = ({
  setModalDatosCliente,
  datosupdate,
  basicInfo
}) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '500px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '600px',
    },
  }));

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      height: 'auto',
      padding: '0px 32px 24px',
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);
  const [errorcampo, setErrorCampo] = useState(false);
  const [correcto, setCorrecto] = useState(false);
  const [email, setemail] = useState(false);
  const [telefono, setTelefono] = useState(false);
  const { type, name, placeholder, titulo, value, idcont } = datosupdate;
  const [form, setForm] = useState({
    campo: '',
    verificar: '',
  });
  const [texterror] = useState({
    campo: '',
  });
  const [error] = useState({
    campo: false,
    celvalid: 0, 
    celvalid1: 1,    
  });

  const [TypeCorreo, SetTypeCorreo] = useState({
    text: '',
    display: '',
    text_correct: '',
  });

  const infoUser = {
    idcont: idcont,
    email: "",
    telefono:"",
  };

  useEffect(() => {
    if(email) basicInfo.email = form.campo;    
    if(telefono)basicInfo.cellphone = form.campo;

  }, [correcto])

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
    setModalDatosCliente(false);
  };
  const inputUser = {
    input: infoUser,
  };
  const [updateEmailTelefono] = useMutation(UPDATE_USER_EMAIL_TELEFONO, {
    onCompleted({ updateEmailTelefono }) {
      if (updateEmailTelefono.message === 'El usuario ya existe') {
        SetTypeCorreo({
          display: 'block',
          text: 'Usuario existente',
          text_correct: '',
        });
      } else{
        setCorrecto(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleChangeDate = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (name) {
      case 'email':
        ValidEmail(e.target.name, e.target.value);
        break;
      case 'telefono':
        ValidCel(e.target.name, e.target.value);
        break;
    }
  };

  const guardarDatos = e => {
    e.preventDefault();
    if( form.campo !=='' ) {
      if (name === 'telefono' ) {
        setErrorCampo(false);
        error.celvalid1 = 0;
        infoUser.telefono = form.campo.trim();
        setTelefono(true);
        updateEmailTelefono({
          variables: inputUser,
        });
        
      }
      if (name === 'email' ) {
        setErrorCampo(false);
        error.celvalid1 = 0;
        infoUser.email = form.campo.trim();
        setemail(true);
        updateEmailTelefono({
          variables: inputUser,
        });
      }
    } else {
      setErrorCampo(true);
      error.celvalid = 1;
    }
  }

  const ValidEmail = (name, value) => {
    let domain = value.split('@');
    const anexo = domain[0];
    domain = domain[1];

    if (domain !== '' && domain !== 'gmail' && domain !== 'hotmail') {
      if (domain === 'h') {
        console.log('entro');
        texterror.campo = '';
        error.campo = true;
        error.celvalid = 1;
      } else if (domain === 'g') {
        texterror.campo = '';
        error.campo = true;
        error.celvalid = 1;
      } else if (domain === 'hotmail.com' || domain === 'gmail.com') {
        texterror.campo = '';
        error.campo = false;
        error.celvalid = 0;
      }
    } else {
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      texterror.campo = 'Correo electrónico incorrecto';
      error.campo = true;
      error.celvalid = 1;
    } else {
      texterror.campo = '';
      error.campo = false;
      error.celvalid = 0;
    }
    if (value === '') {
      texterror.campo = 'Correo electrónico incorrecto';
      error.campo = true;
      error.celvalid = 1;
    }
  };
  const ValidCel = (name, value) => {
    if (name === 'campo') {
      setForm({
        ...form,
        campo: value.replace(/[^0-9]/g, ''),
      });
      if (
        (value.length < 11 && value.length !== 10)
        || (name && !/^[0-9]+$/i.test(value))
      ) {
        setForm({
          ...form,
          [name]: value.replace(/[^0-9]/g, ''),
        });
        texterror.campo = 'Por favor verifica tu número telefónico';
        error.campo = true;
        error.celvalid = 1;

        // (".MuiInput-underline").addClass("MuiInput-underline-error");
      } else if (value.length < 11 && value.length === 10) {
        setForm({
          ...form,
          [name]: value.replace(/[^0-9]/g, ''),
        });
        texterror.campo = '';
        error.campo = false;
        error.celvalid = 0;
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        className={'modal display-block'}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              zindex="1"
              onClick={handleClose}
            >
              X
            </ParagrapTwo>
            <ParagrapTwo
              texttalign="left"
              textalignMobile="left"
              className="mx-auto roboto"
            >
              <b>Actualizar Datos</b>
            </ParagrapTwo>

            <InputLabel
              className="roboto"
              id="demo-simple-select-outlined-label"
            >
              {correcto ? `${name}: ${form.campo}`  : `${titulo}: ${value}` }
            </InputLabel>
            {correcto ? <ParrafoSuccess color={'#22bb33'} > Exito! </ParrafoSuccess> : 
            (<form autoComplete="off" className="" onSubmit={guardarDatos}>
            <TextField
              type={type}
              name='campo'
              className="textInput registro"
              value={form.campo}
              placeholder={placeholder}
              fullWidth
              onChange={handleChangeDate}
              error={error.campo}
              helperText={texterror.campo}
              inputProps={{
                className:
                  error.celvalid === 1
                    ? 'invalidInput intermediateWidth'
                    : error.celvalid === 0
                      ? 'validInput intermediateWidth'
                      : 'intermediateWidth',
              }}

            />
            <CorrecionCorreo
                    display={TypeCorreo.display}
                    className="roboto"
                  >
                    {TypeCorreo.text}
                  </CorrecionCorreo>
            <br/>

          {errorcampo ? <ErrorForm> Campo obligatorio </ErrorForm> : null}
          <br/>
          <Button
            id="btnGenerar"
            name="btnGenerar"
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            Actualizar {name}
          </Button>
          </form>)
            }
           
          </ContentHomeSection>
        </div>
      </Modal>
    </>
  );
};

export default ModalDatosUpdate;
