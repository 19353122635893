/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation, useLazyQuery } from '@apollo/client';
import Dailymotion from 'react-dailymotion';
import moment from 'moment';
import WOlayout from '../../components/layouts/WOlayout';
import BarraScroll from '../BarraScroll';
import '../../assets/styles/Home.css';
import ana from '../../assets/img/anacoach/CoachImage.png';
import logoC from '../../assets/img/anacoach/logoCoach.png';
import check from '../../assets/img/anacoach/Vector.png';
import imgWo from '../../assets/img/anacoach/CCheader.svg';
import RoutinesOrderCC from './RoutinesOrderCCTestVideo';
import PlayYoutube from '../../components/common/PlayYoutube';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import {
  INSERT_ROUTINE,
  DYNAMIC_GET,
  DYNAMIC_INSERT,
  RECOVERY_TRAINING,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  BACKUP_WO_DATA,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GETURLVIDEO, GETPERCENTAGETRAINING, GET_PRODUCTS_OPORTUNITIES, CHECK_TRAINING_FINISHED, GET_TRAINING_STATUS,
} from '../../queries';

import {
  TrainingMain,
  TrainingSubtitles,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainingContainAna,
  TrainingLine,
  TrainingBenef,
  CoruCoach,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import WaitList from '../../components/Training/WaitList';
import Starttraining from '../../components/Training/Starttraining';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import SuccessWOValid from '../../components/Training/SuccessWOValid';
import { Helmet } from 'react-helmet';

const items2 = ['Solicita tu tarjeta sin tantos trámites.', '', ''];
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function CreditCard() {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 35);
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(35);
  let products;
  let productsDetails;
  const dataPersonInit = sessionStorage.getItem('DataPerson') || '';
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [isData, setisData] = useState(sessionStorage.getItem('DataPerson') === 'true');
  const [urlVideo, setUrlVideo] = useState('');
  const [idVideo, setIdVideo] = useState('');
  const [percentage, setPercentage] = useState('');
  const [recoveryState, setRecoveryState] = useState('');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [comenzar, setComenzar] = useState(false);
  const anchop = window.screen.width;

  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log('getTrainingStatus ----------------------- ', getTrainingStatus);
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });
  const [getIDTriggerNotification] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet) {
        const response = JSON.parse(dynamicGet.response);
        const { id } = response[0];
        insertCatNotificationTracking({
          variables: {
            input: {
              table: 'CatNotificationTracking',
              columns: ['DatSession_IDDatSession', 'CatTriggerNotification_Id'],
              detailValues: {
                values: [sessionStorage.getItem('idDatSession'), String(id)],
              },
            },
          },
        });
      }
    },
  });
  const [insertCatNotificationTracking] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert) {
        console.log(dynamicInsert);
      }
    },
  });
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const triggerName = params.get('trigger');
    if (triggerName && sessionStorage.getItem('idDatSession')) {
      console.log(triggerName);
      getIDTriggerNotification({
        variables: {
          input: {
            table: 'CatTriggerNotification',
            columns: ['*'],
            conditions: [
              {
                valone: 'triggerName',
                condition: '=',
                valtwo: triggerName,
              },
            ],
          },
        },
      });
    }
  }, [sessionStorage.getItem('idDatSession')]);
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  // End ValidWaitList

  // Valid Finished WO
  const [allowOverwrite, setAllowOverwrite] = useState(false);
  const [backupCC, setBackupCC] = useState();
  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const idTraining = sessionStorage.getItem('idTraining');
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const overwrite = sessionStorage.getItem(`overwrite_WO_${idTraining}`) || 'false';
        if (overwrite === 'true' && trainingInfo.avance === 100) {
          setAllowOverwrite(true);
          setOrderRutine(1);
          getTrainingData();
        } else if (trainingInfo.avance === 100) {
          setFinished(true);
          const firstName = sessionStorage.getItem('FirstName') || '';
          const middleName = sessionStorage.getItem('MiddleName') ? ` ${sessionStorage.getItem('MiddleName')}` : '';
          const fathersLastName = sessionStorage.getItem('FathersLastName') ? ` ${sessionStorage.getItem('FathersLastName')}` : '';
          const mothersLastName = sessionStorage.getItem('MothersLastName') ? ` ${sessionStorage.getItem('MothersLastName')}` : '';
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            fullName: `${firstName}${middleName}${fathersLastName}${mothersLastName}`,
            phone: sessionStorage.getItem('cellPhone'),
            email: sessionStorage.getItem('Email1'),
            meetsCondition: trainingInfo.threeMonthsPassed,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: 'Tu solicitud está en proceso',
            txt1: 'Para incrementar las posibilidades de obtener un crédito te recomendamos no solicitar más de uno en tres meses',
            txt2: `Realizaste tu solicitud el día ${moment(trainingInfo.fechaTermino).format('DD/MM/YYYY')} con los siguientes datos:`,
          });
        } else if (trainingInfo.avance < 100) {
          getTrainingData();
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      setactive3(true);
    }, 13000);
  }, [active2]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);
  const [stateDate, setStateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();


  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }

  const getTrainingData = () => {
    if (
      false
    ) {
      const idT = 35;
      recoveryTraining({
        variables: {
          idTraining: `${idT}` || '',
          idUser: sessionStorage.getItem('idUser') || '',
          idCont: sessionStorage.getItem('idCont') || '',
        },
      });
    } else {
      sessionStorage.setItem('activeRecovery', 'inactiva');
      setRecoveryState('inactiva');
      sessionStorage.setItem('Primervisita', true);
    }
  };

  const [getVideoTraining] = useLazyQuery(GETURLVIDEO, {
    onCompleted({ getVideoTraining }) {
      const video = JSON.parse(getVideoTraining.response);
      setUrlVideo(video.UrlVideo);
      setIdVideo(video.IdVideoURL);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getVideoTraining({ variables: { id: '1' } });
  }, []);

  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setactive3(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 35 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [backupWOData] = useMutation(BACKUP_WO_DATA, {
    onCompleted({ backupWOData }) {
      if (backupWOData.statusCode === 200) {
        console.log(backupWOData.response);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item, index) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(sessionStorage.getItem('idDatSession'));
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  const [recoveryTraining] = useMutation(RECOVERY_TRAINING, {
    onCompleted({ recoveryTraining }) {
      try {
        const json = JSON.parse(recoveryTraining.response);

        const indices = Object.keys(json);
        const bckData = [];
        for (const indice in indices) {
          const idRoutine = indices[indice];
          const {
            Name: name, RoutineType: routineType, questions, responses,
          } = json[idRoutine];

          const routine = {
            name,
            routineType,
            questionsAndresponses: [],
          };

          questions.map((question, index) => {
            routine.questionsAndresponses.push({
              questionName: question.QuestionName,
              responseValue: responses[index]?.responseValue || '',
            });
          });

          bckData.push(routine);
        }

        const idUser = sessionStorage.getItem('idUser');
        const idTraining = sessionStorage.getItem('idTraining');
        const idCont = sessionStorage.getItem('idCont');

        setBackupCC({
          routines: bckData, idUser, idTraining, idCont,
        });

        if (Object.keys(json).length === 0) {
          sessionStorage.setItem('activeRecovery', 'inactiva');
          setRecoveryState('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('recoveryState wos');
          console.log(routines);
          const indices = ['2', '3', '4', '5', '6', '7', '8', '9', '16', '10'];
          const currentRoutineRecovery = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
          let currentRoutine = 0;
          for (const indice in indices) {
            const idRoutine = indices[indice];
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              sessionStorage.removeItem(arraySessionsRutines[idTypeQuestion]);
            }
          }

          sessionStorage.removeItem('recoveryTarjetas');
          sessionStorage.removeItem('datOpportunity');
          sessionStorage.removeItem('datProducts');
          for (const indice in indices) {
            const idRoutine = indices[indice];
            console.log(idRoutine);
            console.log(currentRoutineRecovery[indice]);
            const arrayResponseRutine = routines[idRoutine].responses;
            let stopRoutine = 0;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              const { questionName } = arrayResponseRutine[i];

              if (responseUser !== '' && responseUser !== 'null' && responseUser != null) {
                if (questionType === 'Cat') {
                  let detailQuestion = null;
                  if (idTypeQuestion === 10) {
                    detailQuestion = routines[idRoutine].questions[9].detailQuestion;
                  } else if (idTypeQuestion === 5) {
                    detailQuestion = routines[idRoutine].questions[10].detailQuestion;
                  } else {
                    detailQuestion = routines[idRoutine].questions[i].detailQuestion;
                  }

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      if (idTypeQuestion === 20) {
                        console.log('ingresoMostrar');
                        setFlagIngHook(true);
                        sessionStorage.setItem('ingresoMostrar', detailQuestion[x].QuestionOption);
                      }
                      if (idTypeQuestion === 10) {
                        console.log(arraySessionsRutines[idTypeQuestion]);
                      }
                      sessionStorage.setItem(arraySessionsRutines[idTypeQuestion], `${responseUser}`);
                      break;
                    }
                  }
                } else if (questionName === 'Fecha de nacimiento') {
                  sessionStorage.setItem('recoveryDate', 'true');
                  let date = responseUser.substr(0, 10);
                  date += ''.split('-')[0];
                  sessionStorage.setItem('year', date.split('-')[0]);
                  sessionStorage.setItem('month', date.split('-')[1]);
                  sessionStorage.setItem('day', date.split('-')[2]);
                  setStateDate({
                    day: date.split('-')[2],
                    month: date.split('-')[1],
                    year: date.split('-')[0],
                  });

                  sessionStorage.setItem(arraySessionsRutines[idTypeQuestion], `${date}`);
                } else {
                  sessionStorage.setItem(arraySessionsRutines[idTypeQuestion], `${responseUser}`);
                }
              } else if (idTypeQuestion === 89 && (responseUser === 'null' || responseUser === null || responseUser === '')) {
                stopRoutine = currentRoutineRecovery[indice];
                break;
              }
              if (sessionStorage.getItem('recoveryUser') === 'true' && i === 6 && currentRoutineRecovery[indice] === 1) {
                console.log('recoveryUser', sessionStorage.getItem('recoveryUser'));
                break;
              }
            }
            if (stopRoutine !== 0) {
              currentRoutine = currentRoutineRecovery[indice];
              break;
            }
            if (parseInt(idRoutine) === 3) {
              const { datOpportunities } = routines[idRoutine];
              const { datProducts } = routines[idRoutine];
              if (datOpportunities !== '' && datOpportunities != null && datProducts !== '' && datProducts != null) {
                sessionStorage.setItem('datOpportunity', datOpportunities);
                sessionStorage.setItem('datProducts', datProducts);
              } else {
                break;
              }
            }
            // debugger;
            if (arrayResponseRutine.length === 11 && currentRoutineRecovery[indice] === 6) {
              console.log('direccion completa');
            } else if (arrayResponseRutine.length >= 0 && currentRoutineRecovery[indice] === 7) {
              if (arrayResponseRutine.length === 0) {
                sessionStorage.setItem('Tiene_tarjeta_de_credito_vigente', 'No');
              }
              console.log('autenticacion completa');
            } else if (arrayResponseRutine.length >= 0 && currentRoutineRecovery[indice] === 9) {
              console.log('ine bbva completa');
            } else if (arrayResponseRutine.length < routines[idRoutine].questions.length) {
              console.log('rutina actual: ', currentRoutineRecovery[indice]);
              console.log('questions: ', routines[idRoutine].questions.length, 'responses: ', arrayResponseRutine.length);
              currentRoutine = currentRoutineRecovery[indice];
              break;
            }

            switch (currentRoutineRecovery[indice]) {
              case 1:
                if (sessionStorage.getItem('recoveryUser') !== 'true') {
                  sessionStorage.setItem('dataPerson', 'datosPersonales');
                }
                questions = [];
                break;
              case 2:
                const arrayselectedCreditCards = routines[idRoutine].productsUser;
                let selectedCreditCards = '';
                selectedCreditCards = arrayselectedCreditCards
                  .map((element) => {
                    console.log(element.nameProduct);
                    return element.nameProduct;
                  })
                  .join();
                sessionStorage.setItem('recoveryTarjetas', selectedCreditCards);
                questions = [];
                break;
              case 10:
                sessionStorage.setItem('stateReferencias', 'Referencias');
                break;
              default:
                questions = [];
                break;
            }
            currentRoutine = currentRoutineRecovery[indice];
            if (sessionStorage.getItem('recoveryUser') === 'true' && currentRoutineRecovery[indice] === 1) {
              break;
            }
          }
          setOrderRutine(currentRoutine);
          sessionStorage.setItem('activeRecovery', 'activa');
          console.log('llega');
          setRecoveryState('activa');
        }
      } catch (error) {
        setRecoveryState('activa');
        console.log('exception recovery', error);
      }
    },
    onError(err) {
      setRecoveryState('inactiva');
      console.log('error recoveryTraining: ', err.message);
    },
  });
  const [flagIngHook, setFlagIngHook] = useState(false);
  const flagIng = sessionStorage.getItem('IngresoMensual');
  useEffect(() => {
    if (flagIng === true || flagIng === 'true' || flagIngHook) {
      console.log('obtenerProductos');

      if (sessionStorage.getItem('CatProductos') !== 'null' && sessionStorage.getItem('CatProductos')) {
        sessionStorage.setItem('CatProductosTemp', sessionStorage.getItem('CatProductos'));
      }
      sessionStorage.removeItem('FlagProducts');
      sessionStorage.removeItem('CatProductos');
      // setCardsProducts('');
      obtenerProductos();
    }
  }, [flagIngHook]);

  const obtenerProductos = () => {
    if (sessionStorage.getItem('FlagProducts') !== false && sessionStorage.getItem('FlagProducts') !== 'false') {
      const getProductsInput = {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idCont: parseInt(sessionStorage.getItem('idCont')),
        income: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
        vertical: 1,
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
      };
      console.log('realizo la peticion');

      getProductsOportunities({ variables: getProductsInput });
      sessionStorage.setItem('FlagProducts', false);
    } else {
      console.log('Petición enviada');
    }
  };

  const [getProductsDetails] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        productsDetails = JSON.parse(dynamicGet.response);
        const details = {};
        productsDetails.forEach((x) => {
          const id = `idProduct-${x.IDProduct}`;
          if (!details.hasOwnProperty(id)) {
            details[id] = {
              detail: [],
            };
          }
          details[id].detail.push(x);
        });
        sessionStorage.setItem('Detalles2', JSON.stringify(details));
        sessionStorage.setItem('DetallesProd', dynamicGet.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            addDatSession(jsonResponse.idSession);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            addDatSession(jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idDatSession', jsonResponse.idSession);
          addDatSession(jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem('currentOrderRoutine');
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  useEffect(() => {
    if (orderRutine >= 1 || orderRutine <= 11) {
      console.log('#### rutina > ', orderRutine);
      saveAdvance(parseInt(orderRutine));
    }
  }, [orderRutine]);
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      pageName,
      fromXCoach,
      idAdviser,
    } = getQueryVariableHashtagDeeplink('funnel-prestamos');
    const currentTraining = sessionStorage.getItem('idTraining');
    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    checkTrainingFinished({
      variables: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(currentTraining),
      },
    });

    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) { newSession({ variables: mongoInput }); }
  }, []);

  const [getProductsOportunities] = useLazyQuery(GET_PRODUCTS_OPORTUNITIES, {
    onCompleted({ getProductsOportunities }) {
      if (getProductsOportunities.statusCode === 200) {
        products = JSON.parse(getProductsOportunities.response);
        sessionStorage.setItem('CatProductos', getProductsOportunities.response);
        sessionStorage.setItem('CatProductosTemp', getProductsOportunities.response);
        const conditions = [];
        for (const i in products) {
          const conditionsTemp = {
            valone: 'IDProduct',
            condition: '=',
            valtwo: String(products[i].IDProduct),
            logic: 'OR',
          };
          conditions.push(conditionsTemp);
        }
        const productsDetailsInput = {
          input: {
            table: 'CatProductDetail',
            columns: ['*'],
            conditions,
          },
        };
        getProductsDetails({ variables: productsDetailsInput });
        // }, 3000);
      }
    },
  });

  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  const saveAdvance = (idRoutine) => {
    const idTraining = 35;
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    idRoutine = idRoutine;
    if (idRoutine < 10) {
      console.log('estatus de rutina ', idRoutine);
      const inputProgress = {
        input: {
          idRoutine: '',
          orderRoutine: String(idRoutine),
          idTraining: String(idTraining),
          idUser: String(idUser),
          idCont: String(idCont),
          isMaster: '1',
        },
      };
      if (idUser != null) {
        saveStatusTraining({ variables: inputProgress });
      }
    } else {
      for (let index = 1; index <= 12; index++) {
        const inputProgress = {
          input: {
            idRoutine: '',
            orderRoutine: String(index),
            idTraining: String(idTraining),
            idUser: String(idUser),
            idCont: String(idCont),
            isMaster: '1',
          },
        };
        if (idUser != null) {
          saveStatusTraining({ variables: inputProgress });
        }
      }
    }
  };

  const hora = `${date.getHours()}:${minutos}`;
  const [saludo] = useState('Si');
  const [beneficios] = useState('No');
  useEffect(() => {
    setRecoveryState(sessionStorage.getItem('activeRecovery'));
    console.log('recoveryState', recoveryState);
  });

  useEffect(() => {
    if (backupCC && backupCC.routines.length > 0 && allowOverwrite) {
      const mongoInput = {
        input: {
          collection: 'backup_wo_routines',
          data: JSON.stringify(backupCC),
        },
      };
      backupWOData({ variables: mongoInput });
    }
  }, [backupCC]);
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  if (
    recoveryState === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <div></div>
    );
  }
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: 'Entras a Coru y sales con una tarjeta',
    time: '5 minutos',
    imgWo: { imgWo },
  };
  return (
      <WOlayout backgroundColor={'#A370FF'} pathname={window.location.pathname} headerType="HeaderTwo" >
        <Helmet>
          <title>Solicita tu tarjeta de crédito en línea | CORU</title>
        </Helmet> 
        <TrainingMain>
          <TrainingAnimateFunnel
            bcolor="#A370FF"
            pmob="0 0 0 0"
            margin="0 0 24px 0"
            minh="309px"
            hweb="410px"
          >
            <BarraInfoWO propied={propied} />
            {scroll1 > 0 ? <BarraScroll/> : null }
            <TrainingLine id="line-percent-gray" mt=""></TrainingLine>
            {percentage !== ''
              ? <TrainingLine id="line-percent-pink" bcolor="#F64282" mt="" wweb={percentage} w={percentage} mr="auto" op="1" br="100px"></TrainingLine>
              : null
            }
          </TrainingAnimateFunnel>
          {WOStatus
            ? <Fragment>
              {!finished
                ? <Fragment>
                  {!allowOverwrite
                    ? <Fragment>
                      <ModalDataPersonalInitWO isData={isData} setData={setisData} />
                      <DataPersonalInitWO
                        Date={stateDate}
                        hora={hora}
                        orderRutine={orderRutine}
                        setOrderRutine={setOrderRutine}
                        createJsonInput={createJsonInput}
                        questions={questions}
                      />
                    </Fragment>
                    : null
                  }
                  { !comenzar
                    ? <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0" >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                            <div>
                              <Dailymotion
                                  video="k1QMCwKSZWxDR3wTrlD"
                                  width="100%"
                                  height="640px"
                                  autoplay="1"
                                  controls="false"
                                  mute="false"
                              />
                          </div>
                          {active1 ? (
                            <CardsBtn
                              type="button"
                              minmd="90%"
                              min_w="90%"
                              min_web="90%"
                              mtopmd="10px"
                              mtopw="10px"
                              windthmd="90%"
                              mleftmd="5%"
                              mleftweb="5%"
                              onClick={() => setComenzar(true)}
                            >
                              Comenzar
                            </CardsBtn>
                          ) : null}
                        </div>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    : null
                  }

                  <div className="shapes">
                    {beneficios === 'Si' ? (
                      <Fragment>
                        <TrainingDivSection mlw="10%" padding="0 20px" paddw="0" back="transparent" backw="transparent">
                          <Images
                            src={check}
                            top="94px"
                            topweb="70px"
                            left="29px"
                            leftw="2%"
                            width="18px"
                            position="absolute"
                            className="img-fluid"
                          ></Images>
                          <Images
                            src={check}
                            top="151px"
                            topweb="128px"
                            left="29px"
                            leftw="2%"
                            width="18px"
                            position="absolute"
                            className="img-fluid"
                          ></Images>
                          <Images
                            src={check}
                            top="210px"
                            topweb="186px"
                            left="29px"
                            leftw="2%"
                            width="18px"
                            position="absolute"
                            className="img-fluid"
                          ></Images>
                          <Images
                            src={check}
                            top="267px"
                            topweb="244px"
                            left="29px"
                            leftw="2%"
                            width="18px"
                            position="absolute"
                            className="img-fluid"
                          ></Images>
                          <TrainingSubtitles size="20px" fw="600" align="left" alignweb="left" className="cabin">
                            ¿Qué podré comparar de las diferentes opciones?
                                        </TrainingSubtitles>
                          <TrainingBenef
                            bbs="solid"
                            bbsw="none"
                            bbw="1px"
                            bbc="#CDCDCD"
                            pt="17px"
                            pb="17px"
                            he="58px"
                            pl="73px"
                            mt="26px"
                            className="roboto"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Beneficios
                                        </TrainingBenef>
                          <TrainingBenef
                            bbs="solid"
                            bbsw="none"
                            bbw="1px"
                            bbc="#CDCDCD"
                            pt="17px"
                            pb="17px"
                            he="58px"
                            pl="73px"
                            mt="0"
                            className="roboto"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Anualidad
                                        </TrainingBenef>
                          <TrainingBenef
                            bbs="solid"
                            bbsw="none"
                            bbw="1px"
                            bbc="#CDCDCD"
                            pt="17px"
                            pb="17px"
                            he="58px"
                            pl="73px"
                            mt="0"
                            className="roboto"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            CAT - Costo Anual Total
                                        </TrainingBenef>
                          <TrainingBenef
                            bbs="solid"
                            bbsw="none"
                            bbw="1px"
                            bbc="#CDCDCD"
                            pt="17px"
                            pb="17px"
                            he="58px"
                            pl="73px"
                            mt="0"
                            className="roboto"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Tasas de interés
                                        </TrainingBenef>
                        </TrainingDivSection>
                        <TrainingContainAna bottom="18%" back="#FAFAFA">
                          <Images
                            src={ana}
                            top="63px"
                            topweb="2.5%"
                            left="0"
                            leftw="-40px"
                            position="initial"
                            positionw="absolute"
                            border="2px solid #A370FF"
                            bs="border-box"
                            padd="5px"
                            br="45px"
                            className="img-fluid"
                          ></Images>
                          <CoruCoach
                            bcolor="#A370FF"
                            marginTop="-6%"
                            marginLeft="12%"
                            topD1="8%"
                            leftD1="9%"
                            topD2="6%"
                            leftD2="1.7%"
                            topD3="5%"
                            leftD3="1.7%"
                          >
                            <Images
                              src={logoC}
                              top="9px"
                              topweb="9px"
                              left="-8px"
                              leftw="-9px"
                              width="18px"
                              position="absolute"
                              className="img-fluid"
                            ></Images>
                          </CoruCoach>
                        </TrainingContainAna>
                      </Fragment>
                    ) : null}

                    {saludo === 'Si' && active2 ? (
                      <Fragment>
                        <TrainingDivSection
                          className="hidden"
                          bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                          mt="44px"
                          mtw="85px"
                          br="4px"
                          padding="21px 20px"
                          wi="335px"
                          wiw="670px"
                          he="auto"
                          hew="auto"
                          mb="0px"
                        >
                          <div className="triangulo-equilatero-bottom"></div>
                          {urlVideo !== '' ? (
                            <PlayYoutube idVideo={idVideo} width={'300'} height={'190'} close={false} />
                          ) : null}
                        </TrainingDivSection>
                        <TrainingDivSection
                          ml=" "
                          back=" "
                          backw=" "
                          paddw="4px"
                          mt="44px"
                          mtw="0"
                          br="4px"
                          padding="21px 20px"
                          wi="335px"
                          wiw="670px"
                          he="auto"
                          hew="auto"
                          mb="0px"
                          className="hidden"
                        >
                          <SendNotificationVideo urlYTDirect={urlVideo} />
                        </TrainingDivSection>
                      </Fragment>
                    ) : (
                      <PlayYoutube idVideo={idVideo} width={'300'} height={'190'} close={true} />
                    )}
                  </div>

                  {active3 && comenzar ? (
                    <Fragment>
                     <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0" >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                          {active3 ? (
                            <Fragment>
                              { dataPersonInit !== 'true'

                                ? <div className="marginComentLgSuccess1">
                                <Starttraining
                                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                                  title="Iniciemos esta aventura..."
                                  text="Primero necesito conocerte, ayúdame con tus datos personales." />
                              </div>
                                : null
                              }
                              <div className="marginComentLgSuccess1">
                                <RoutinesOrderCC
                                  Dates={stateDate}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  insertRoutine={insertRoutine}
                                  updateSession={updateSession}
                                  createSession={createSession}
                                />
                              </div>
                            </Fragment>
                          ) : null}
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    </Fragment>
                  ) : null}
                  {!active3 ? (
                    <Fragment>
                      <br></br>
                      <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
                        <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="0rem">
                          <ImageCoachInicio topMobile="35px" />
                          <Col7 padding=" " className="cardPrincipal1">
                            <LoadingWo />
                          </Col7>
                        </Container>
                      </TrainingSectionGray>
                    </Fragment>
                  ) : null}
                </Fragment>
                : <Fragment>
                  <SuccessWOValid {...trainingFinishedInfo} />
                </Fragment>
              }
            </Fragment>
            : <Fragment>
              <br />
              <br />
              <br />
              <br />
              {executeQuery ? <WaitList /> : null}
            </Fragment>
          }
        </TrainingMain>
      </WOlayout>
  );
}

export default CreditCard;
