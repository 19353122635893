/*Entrenamientos*/
import styled from "styled-components";
import { imageComponent } from "../../components/common/common";
import shapes from "../img/anacoach/ShapesFeed.png"

export const Feed_Main = styled.div`
	align-items: center;
	background-color:  ${props => props.background || '#fafafa'};  
	font-family: system-ui;
	height: 100%;
	margin: auto;
	width: 100%;
	display : ${props => props.display || ' '};
	position: ${props => props.position || ' '};
	@media (min-width: 768px) {
		background-color: ${(props) => props.backWeb || " "};
		margin-top:  ${props => props.mtop || ' '}; 
		margin-left:  ${props => props.mLeft|| ' '};  
	}
	@media (min-width: 1024px) {
		width: ${props => props.width || '50%'};
	}
	@media (max-width: 500px) {
		width: ${props => props.widthxs || '100%'};
	}
`;
export const Feed_Main_reco = styled.div`
	align-items: center;
	background-color:  ${props => props.background || '#fafafa'};  
	font-family: system-ui;
	height: 100%;
	margin: auto;
	width: 100%;
	display : ${props => props.display || ' '};
	@media (min-width: 770px) {
		background-color: ${(props) => props.backWeb || " "};
		margin-top:  ${props => props.mtop || ' '};  
		width: ${props => props.width || '100%'};
	}
`;
export const Feed_Sub_Main = styled.div`
	display: flex;
	flex-direction: row;
`;
export const Feed_Main_Left =  styled.div`
	max-width: 63%;
	width: 63%;
`;
export const Feed_Main_Right =  styled.div`
	max-width: 37%;
	width: 37%;
`;
export const Feed_Section = styled.div`
	position:  ${(props) => props.position || " "};
	background-color: ${(props) => props.back || " "};
	padding: ${props => props.paddingm || '5% 5% 5% 5%'};
	width: ${(props) => props.widthF || " "};
	@media (min-width: 768px) {
		background-color: ${(props) => props.backWeb || " "};
		padding: ${props => props.padding || '8% 5% 8% 5%'};
		margin-top: ${props => props.mTopWeb || ' '};
		border-radius: 3px;
		width: ${props => props.WidthLg|| '100% '};
		margin-left:  ${props => props.marginLeftLg|| ''};
	}
	@media (max-width: 500px) {
		background-color: ${(props) => props.backWeb || " "};
		padding: ${props => props.paddingxs || ''};
		margin-top: ${props => props.mTopWeb || ' '};
		border-radius: 3px;
		width: 100%;
	}
`;

export const Feed_Section_Init = styled.div`
	background-color: transparent;
	padding: 0% 0% 5% 0%;
	border:0px;
	@media (max-width: 768px) {
		padding: 0% 0% 15% 0%;
	}
`;

export const Feed_Span = styled.span`
	font-size: 1rem;
	font-family: ${props => props.fontFamily || 'Eina,"Helvetica Neue",Helvetica,sans-serif'};
	cursor: pointer;
	@media (min-width: 768px) {
		font-size: ${props => props.fontSize || '1rem'};
	}
`;
export const Feed_HeadPink = styled.div`
	background: #ec407a;
	color: #fff;
	height: 50px;
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-evenly;
	margin-top: 60px;
`;
export const Feed_HeadTop = styled.div `
	height: 55px;
	width: 100%;
	margin: auto;
	padding: 0px 16px;
	text-align: left;
	top: 0;
    position: fixed;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
		@media (min-width: 481px) {
			height: 75px;
			width: 100%;
			left: 0;
			padding: 20px 15px;
		}
	
`;
export const Feed_HeadTop_Center = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 1rem;
	@media (min-width: 481px) {
		height: 60px;
		width: 80%;
		padding: 0px;
	}
`;
export const ImageSticker = styled(imageComponent)`
	max-width: 100%;
	width: ${(props) => props.width || "40px"};
	height: ${(props) => props.hei || ""};
	float: ${(props) => props.float || ""};
	position: ${(props) => props.position || ""};
	margin-bottom: ${(props) => props.bottom || "auto"};
	background: ${(props) => props.back || ""};
	margin-top: ${(props) => props.top || "auto"};
	margin: ${(props) => props.marg || ""};
	border-radius: ${(props) => props.borderR || ""};
	@media (min-width: 481px) {
		width: ${(props) => props.widthw || props.width };
		height: ${(props) => props.heightw || props.hei};
	}
`;
export const Feed_HeadText = styled.div`
	margin-top: auto;
	margin-bottom: auto;
`;
export const Feed_Right_Box = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 20rem;
	width: 19rem;
	margin-left: 3rem;
	margin-top: 3rem;
	padding: 1rem;
	-webkit-box-shadow: 0px 5px 10px -2px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 5px 10px -2px rgba(0,0,0,0.75);
	box-shadow: 0px 5px 10px -2px rgba(0,0,0,0.75);
	border-radius: ${props => props.br || ''};
`;
export const Feed_Subtitles = styled.h2`
	color: #110e20;
	font-size: ${(props) => props.size || "1.7em"};
	text-align: ${(props) => props.align || "justify;"};
	font-weight: 600;
	@media (min-width: 481px) {
		font-size: ${(props) => props.size || "48px"};
		text-align: ${(props) => props.align || "left;"};
		letter-spacing: -0.05em;
		font-weight: 600;
	}
`;
export const Feed_Text = styled.p`
	color: #252525;
	font-family: Roboto;
	font-style: normal;
	letter-spacing: -0.2px;
	width: ${(props) => props.width || ""};
	margin: ${(props) => props.margin || ""};
	font-size: ${(props) => props.size || "18px"};
	font-weight: ${(props) => props.weight || "normal"};
	text-align: ${(props) => props.align || "left"};
	margin-bottom: ${(props) => props.bot || ""};
	line-height: ${(props) => props.lineMob || "150%"};
	margin-top : ${(props) => props.top || "25px"};
	margin-bottom: ${(props) => props.bottom || " "};
	margin-left: ${(props) => props.leftm || " "};
	max-width: ${(props) => props.maxwidth || " "};
	@media (min-width: 768px) {
		font-size: ${(props) => props.size || "18px"};
		line-height: ${(props) => props.line || "150%"};
	}
`;
export const SeparateSection = styled.hr`
	border-top: 2px solid rgb(250, 250, 250);
	margin-top: ${props => props.marginTop || '0.5rem'};
	margin-bottom: ${props => props.marginBottom || '0.5rem'};
`;
/*Video Section*/
export const VideoMain = styled.div`
	border-radius: 3px;
	box-shadow: rgba(0,0,0,0.15) 0px 2px 8px !important;
	width: 100%;
	height: 80px;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	@media (min-width: 768px) {
		padding: 5px 35px;
	}
`;
export const VideoText = styled.div`
	font-size: 14px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 8px;
	margin-right: 8px;
`;
export const Video_Details = styled.div`
	text-align: 	center;
`;

/*Products Section*/
export const Products_Main = styled.div`
	width: 100%;
	display: flex;
	overflow-y: hidden;
`;
export const Product = styled.div`
	width: 157px;
	height: 156px;
	left: 20px;
	top: 2538px;
	background: #FFFFFF;
	box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	margin-right: 40px;
	display: flex;
	@media (min-width: 768px) {
		width: 330px;
		padding: 5%;
		height: auto;
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.08);
	}
`;
export const ContainerProduct = styled.div`
	width: 157px;
	height: 218px;
`;
export const Product_Title = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 120%;
	text-align:  ${props => props.align || 'center' };
	letter-spacing: -0.3px;
	color: #07210A;
	margin-top: 15px;
	display: ${props => props.displayMobile || ' ' };
	@media (min-width: 768px) {
		display: ${props => props.displayWeb || ' ' };
	}

`;
export const Product_Question = styled.div`
	font-size: 12px;
	color: #a2a2a2;
`;

/*ScrollTop Section*/
export const Top_Main = styled.div`
	border-radius: 3px;
	box-shadow: rgba(0,0,0,0.15) 0px 2px 8px !important;
	width: 100%;
	height: 80px;
	padding: 5px 15%;
	display: flex;
	justify-content: space-between;
	color: #110e20;
	@media (min-width: 768px) {
		padding: 5px 40%;
	}
`;

export const Top_Main_Init = styled.div`
	
	width: 100%;
	display: flex;
	justify-content: space-between;
	color: #110e20;
	
`;

export const Top_Text = styled.div`
	margin: auto;
	font-weight: ${props => props.fontWeight || '600'};
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: ${props => props.fontSize || '16px'};
	line-height: 150%;
	text-align: center;
	letter-spacing: -0.2px;
	color: #252525;
	margin-bottom: 0px;
`;
/*Footer Feed*/
export const FeedFooterMain = styled.div`
	background: #fff;
	height: 75px;
	display: ${props => props.mb || 'flex'}; 
	margin-bottom: ${props => props.mb || ''};
	justify-content: space-around;
	bottom: ${(props) => props.bottmob || "0"};
	width: 100%;
	border-top: 1px solid #dddddd;
	left: ${props => props.left || '0px'};
	overflow: hidden;
	right: ${props => props.right || '0px'};
	z-index: 1;
	position: fixed;
	padding-bottom: 12px;
	-webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.1, 1), transform 0.2s cubic-bezier(0, 0, 0.1, 1), visibility;
	-moz-transition: transform 0.2s cubic-bezier(0, 0, 0.1, 1), visibility;
	transition: -ms-transform 0.2s cubic-bezier(0, 0, 0.1, 1), -webkit-transform 0.2s cubic-bezier(0, 0, 0.1, 1),
		transform 0.2s cubic-bezier(0, 0, 0.1, 1), visibility;
	display: ${props => props.displayM || ' '};
	@media (min-width: 768px) {
		bottom: ${(props) => props.bott || "0"};
		display: ${props => props.displayW || ' '};
	}
	width: 100%;
	left: 0; 
	bottom: 0; 
	position: fixed; 
	width: 100%;
    margin: auto;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);
`;

export const  FeedContainer = styled.div`
	width: 100%;
	display: flex;
	@media (min-width: 768px) {
		width: 40%;
	}
	
`;
export const FeedFooterMainBlue = styled.div`
	background: #fff;
	height: auto;
	display: flex;
	justify-content: space-around;
	bottom: 0;
	position: fixed;
	width: ${props => props.widthm || '100%'};
	left: ${props => props.left || '0px'};
	overflow: hidden;
	right: ${props => props.right || '0px'};
	z-index: 100;
	-webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.1, 1), transform 0.2s cubic-bezier(0, 0, 0.1, 1), visibility;
	-moz-transition: transform 0.2s cubic-bezier(0, 0, 0.1, 1), visibility;
	transition: -ms-transform 0.2s cubic-bezier(0, 0, 0.1, 1), -webkit-transform 0.2s cubic-bezier(0, 0, 0.1, 1),
		transform 0.2s cubic-bezier(0, 0, 0.1, 1), visibility;
	
	@media (min-width: 768px) {
		width: ${props => props.width || '50%'};
		margin: auto;
	}
`;
export const FeedFooterGrid = styled.div`
	display: grid;
	text-align: center;
	width: ${(props) => props.width || "33%"};
	padding-top: 12px;
	cursor: pointer;
`;
export const FeedFooterSpan = styled.span`
	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	font-weight: ${(props) => props.weight || ""};
	color: ${(props) => props.color || ""};
	display: ${(props) => props.display || "none"};
`;
export const SectionShareFeed = styled.div `
	width: ${props => props.wi || '100%'};
	padding: ${props => props.paddingm || '5% 5% 5% 5%'};
	background-color: ${props => props.bcolor || '#ffffff'};
	margin-top: ${props => props.mt || ""};
	filter:${props => props.filter || ""}; 
	margin-left: auto;
	margin-right: auto;
	@media (min-width: 768px) {
		padding: ${props => props.padding || '8% 5% 8% 5%'};
		width: ${props => props.wiw || '100%'};
	}
`;

export const ShapesFeed = styled.div `
	background-image: url(${shapes});
	background-repeat: no-repeat;
`;