/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn1,
  ErrorDiv1,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';

const TypesInstruments = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery, setCountRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {

                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('NuevaInversion') !== 'true'
      && sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [clickOntypeInstrument, setClickOnTypeInstrument] = useState(0);
  // States de preguntas
  const [nationalActions, setNationalActions] = useState([]);
  const [internationalActions, setInternationalActions] = useState([]);
  const [cetes, setCetes] = useState([]);
  const [Etf, setETF] = useState([]);
  const [fibras, setFibras] = useState([]);
  const [investmentFounds, setInvestmentFounds] = useState([]);
  const [metales, setMetales] = useState([]);
  const anchop = window.screen.width;

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [form, setForm] = useState({});

  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        switch (val.idField) {
          case 651: // pregunta 1
            if (nationalActions.indexOf(val.value) === -1) { setNationalActions(nationalActions.concat(val.value)); }
            setClickOnTypeInstrument(651);
            break;
          case 652:
            if (nationalActions.indexOf(val.value) === -1) { setNationalActions(nationalActions.concat(val.value)); }
            setClickOnTypeInstrument(652);
            break;
          case 653: // pregunta 2
            if (internationalActions.indexOf(val.value) === -1) { setInternationalActions(internationalActions.concat(val.value)); }
            setClickOnTypeInstrument(653);
            break;
          case 654:
            if (internationalActions.indexOf(val.value) === -1) { setInternationalActions(internationalActions.concat(val.value)); }
            setClickOnTypeInstrument(654);
            break;
          case 655: // pregunta 3
            if (cetes.indexOf(val.value) === -1) { setCetes(cetes.concat(val.value)); }
            setClickOnTypeInstrument(655);
            break;
          case 656: // pregunta 4
            if (Etf.indexOf(val.value) === -1) setETF(Etf.concat(val.value));
            setClickOnTypeInstrument(656);
            break;
          case 657:
            if (Etf.indexOf(val.value) === -1) setETF(Etf.concat(val.value));
            setClickOnTypeInstrument(657);
            break;
          case 658: // pregunta 5
            if (fibras.indexOf(val.value) === -1) { setFibras(fibras.concat(val.value)); }
            setClickOnTypeInstrument(658);
            break;
          case 659: // pregunta 6
            if (investmentFounds.indexOf(val.value) === -1) { setInvestmentFounds(investmentFounds.concat(val.value)); }
            setClickOnTypeInstrument(659);
            break;
          case 660: // pregunta 7
            if (metales.indexOf(val.value) === -1) { setMetales(metales.concat(val.value)); }
            setClickOnTypeInstrument(660);
            break;
          default:
            console.log('default break handleChangeCatOption case 0');
            break;
        }
        break;
      default:
        console.log(val);
    }
  };

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  // marcado de seleccion multiple
  useEffect(() => {
    console.log('Rutine: ', isEmptyObject(Rutine));
    let element;
    if (
      nationalActions.includes('Aeroméxico')
      && clickOntypeInstrument === 651
    ) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('Aeroméxico')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = nationalActions.indexOf('Aeroméxico');
          nationalActions.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    } else if (
      nationalActions.includes('Bimbo')
      && clickOntypeInstrument === 652
    ) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('Bimbo')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = nationalActions.indexOf('Bimbo');
          nationalActions.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }

    if (
      internationalActions.includes('Amazon')
      && clickOntypeInstrument === 653
    ) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('Amazon')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = internationalActions.indexOf('Amazon');
          internationalActions.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    } else if (
      internationalActions.includes('Facebook')
      && clickOntypeInstrument === 654
    ) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('Facebook')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = internationalActions.indexOf('Facebook');
          internationalActions.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }

    if (cetes.includes('Cetes a 30 días') && clickOntypeInstrument === 655) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('Cetes a 30 días')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = cetes.indexOf('Cetes a 30 días');
          cetes.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }

    if (Etf.includes('SPY') && clickOntypeInstrument === 656) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('SPY')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = Etf.indexOf('SPY');
          Etf.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    } else if (Etf.includes('USMV') && clickOntypeInstrument === 657) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('USMV')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = Etf.indexOf('USMV');
          Etf.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }

    if (fibras.includes('FUNO11.MX') && clickOntypeInstrument === 658) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('FUNO11.MX')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = fibras.indexOf('FUNO11.MX');
          fibras.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }
    if (investmentFounds.includes('CPODX') && clickOntypeInstrument === 659) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('CPODX')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = investmentFounds.indexOf('CPODX');
          investmentFounds.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }
    if (metales.includes('Oro') && clickOntypeInstrument === 660) {
      document.getElementById('error1').hidden = true;
      element = document.getElementsByName('Oro')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = metales.indexOf('Oro');
          metales.splice(deleted, 1);
        }
      }
      setClickOnTypeInstrument(0);
    }
  }, [
    nationalActions,
    internationalActions,
    cetes,
    Etf,
    fibras,
    investmentFounds,
    metales,
    clickOntypeInstrument,
    recoveryRoutine,
  ]);

  const handleTypeInstruments = (e) => {
    if (document.getElementsByClassName('selectedBen').length >= 1) {
      const click = `ContinuarInstrumentos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      e.preventDefault();
      if (!isEmptyObject(Rutine)) {
        questions.push(
          {
            id: 'nationalActions',
            question: 1,
            value: nationalActions.toString(),
          },
          {
            id: 'internationalActions',
            question: 2,
            value: internationalActions.toString(),
          },
          {
            id: 'cetes',
            question: 3,
            value: cetes.toString(),
          },
          {
            id: 'Etf',
            question: 4,
            value: Etf.toString(),
          },
          {
            id: 'fibras',
            question: 5,
            value: fibras.toString(),
          },
          {
            id: 'investmentFounds',
            question: 6,
            value: investmentFounds.toString(),
          },
          {
            id: 'metales',
            question: 7,
            value: metales.toString(),
          },
        );
        createJsonInput(Rutine, questions);
        setQuestions([]);
        setOrderRutine(nextOrderRoutine);
      }
      document.getElementById('error1').hidden = true;
    } else {
      document.getElementById('error1').hidden = false;
      console.log(document.getElementById('error1').hidden);
    }

    /*  */
  };

  useEffect(() => {
    if (
      recoveryRoutine === 'activa'
      && sessionStorage.getItem('NuevaInversion') !== 'true'
    ) {
      setNationalActions(
        sessionStorage.getItem('AccionesNacionales')
          ? sessionStorage.getItem('AccionesNacionales').split(',')
          : [],
      );
      setInternationalActions(
        sessionStorage.getItem('AccionesInternacionales')
          ? sessionStorage.getItem('AccionesInternacionales').split(',')
          : [],
      );
      setCetes(
        sessionStorage.getItem('Cetes')
          ? sessionStorage.getItem('Cetes').split(',')
          : [],
      );
      setETF(
        sessionStorage.getItem('ETFs')
          ? sessionStorage.getItem('ETFs').split(',')
          : [],
      );
      setFibras(
        sessionStorage.getItem('Fibras')
          ? sessionStorage.getItem('Fibras').split(',')
          : [],
      );
      setInvestmentFounds(
        sessionStorage.getItem('FondoInversion')
          ? sessionStorage.getItem('FondoInversion').split(',')
          : [],
      );
      setMetales(
        sessionStorage.getItem('Metales')
          ? sessionStorage.getItem('Metales').split(',')
          : [],
      );
    }
  }, [recoveryRoutine]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeTypesInstruments' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeTypesInstruments"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="Continuemos"
        />
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="¡Muy bien!"
          text2="Selecciona los instrumentos en los que deseas invertir:"
        />
        {orderRutine === 5 ? (
          <>
            <TrainingQuestion wiw="74%" mLg="11%">
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="0-0"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separationCat={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <ErrorDiv1 left="14%" top="20px" hidden id="error1">
              Selecciona los instrumentos en los que deseas invertir
            </ErrorDiv1>
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn1
                id="btnCandidadInvertir"
                onClick={(e) => handleTypeInstruments(e)}
              >
                Continuar
              </CardsBtn1>
            </div>
          </>
        ) : (
          <CardTwo
            classCard="cardTwoResp"
            classTop="topTwo1"
            imgMan="hidden"
            text={
              `${internationalActions
              } ${
                nationalActions
              } ${
                cetes
              } ${
                Etf
              } ${
                fibras
              } ${
                investmentFounds
              } ${
                metales}`
            }
            colorTraining={trainingColor}
          />
        )}
      </div>
  );
};

export default TypesInstruments;
