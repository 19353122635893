/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TRAININGS } from '../../queries';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  ContEntrenamientos,
} from '../../assets/styles/Home.styled';
import CardOne from '../../components/Home/Card1Animate';
import image1 from '../../assets/img/anacoach/mejor tarjeta.jpg';
import Clock from '../../assets/img/anacoach/clockTraining.png';

const RpMainGoalES = ({
  Date, hora, currentTraining, MainGoal,
}) => {
  let par = 1;
  const [stateInformativo, setInformativo] = useState(false);
  const [stateOutstanding, setOutstanding] = useState(false);
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  const validateTwin = [];
  const validateMedium = [];
  const validateBig = [];

  const trainingBig = [];
  const trainingVertical = []; // vertical cc
  const items4 = []; // entrenamiento destacado
  const itemsMore = []; // informativos
  const trainingTwin = []; // tiwn
  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    console.log('ItemsMore', arrValue);
    if (!(validateInformativo.indexOf(idTrainning) !== -1)) {
      const sectionInformativo = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: image1,
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      sectionInformativo.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
      if (itemsMore.length >= 1 && !stateInformativo) {
        console.log('activo set stateInformativo');
        setInformativo(true);
      }
    }
  };

  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateOutstanding.indexOf(idTrainning) !== -1)) {
      const sectionOutstanding = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: '',
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionOutstanding.Liked = arrValue.Liked;
      items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
      if (items4.length >= 1 && !stateOutstanding) {
        console.log('activo set stateOutstanding');
        setOutstanding(true);
      }
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        image: '',
        Liked: '',
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      sectionVertical.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
  };
  const idUser = sessionStorage.getItem('idUser') || 0;
  const vars = { variables: { idUser: Number(idUser) }, fetchPolicy: 'no-cache' };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining) {
    const trainingList = dataTraining.getTrainings;
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      switch (training.Section) {
        case 'Big':
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          break;
        case 'Medium':
          // items2.push(sectionMedium);
          break;
        case 'Twin':
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default: break;
      }
      if (training.TrainingType === 'Informativo') {
        pushInformativo(training);
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === 'Tarjetas de crédito') {
        pushCCVertical(training);
      }
    });
  }
  return (
    <Fragment>
      {MainGoal === 'Aumentar mi % de ahorro mensual'
      || sessionStorage.getItem('ObjetivoAhorro')
        === 'Aumentar mi % de ahorro mensual' ? (
        <Fragment>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="Crecer tu ahorro es posible si ajustas algunos detalles en tus gastos mensuales, ¡Antes de buscar más dinero, organiza mejor el que ya tienes y verás avances inmediatos!"
            text2="¡Inténtalo!"
            hora={hora}
          />
          {trainingBig.map(
            (post) => (
              (par = par === 0 ? 1 : 0),
              post.idTrainning === 25 ? (
                post.Show ? (
                  <ContEntrenamientos
                    id="ContEntrenamiento"
                    background="transparent"
                  >
                    <ContEntrenamiento
                      id={post.IDCatTraining}
                      background={
                        post.TrainingColor ? post.TrainingColor : '#917AFF'
                      }
                      widthM="100%"
                      widthD="100%"
                      cursor="pointer"
                    >
                      <a
                        href={
                          post.TrainingRedirectUrl
                            ? post.TrainingRedirectUrl
                            : '#'
                        }
                      >
                        <ContentTimer
                          height=" "
                          maxheightMobile="130px"
                          heightMobile="140px"
                          maxheight="107.250px"
                          display="flex"
                          top="0px"
                          width="100%"
                          position=" "
                        >
                          <TitleEntrenamiento
                            className="col-9 roboto"
                            left="0px"
                            margin="auto"
                            top=" "
                          >
                            {post.name}
                          </TitleEntrenamiento>
                          <Image
                            src={post.image}
                            width="25%"
                            widthmob="22%"
                            margin="auto"
                            top="5%"
                            bottom="1%"
                            right="0px"
                          />
                        </ContentTimer>
                        <ContentTimer className="container-bar">
                          <Image
                            src={Clock}
                            width="20px"
                            left="0px"
                            position=" "
                            top="1%"
                          />
                          <TextTime className="roboto">{post.time}</TextTime>
                        </ContentTimer>
                      </a>
                    </ContEntrenamiento>
                  </ContEntrenamientos>
                ) : (
                  <ContEntrenamientos
                    id="ContEntrenamiento"
                    background="transparent"
                  >
                    <ContEntrenamiento
                      id={post.IDCatTraining}
                      background={
                        post.TrainingColor ? post.TrainingColor : '#917AFF'
                      }
                      widthM="100%"
                      widthD="100%"
                      cursor="pointer"
                    >
                      <a
                        href={
                          post.TrainingRedirectUrl
                            ? post.TrainingRedirectUrl
                            : '#'
                        }
                      >
                        <ContentTimer
                          height=" "
                          maxheightMobile="130px"
                          heightMobile="140px"
                          maxheight="107.250px"
                          display="flex"
                          top="0px"
                          width="100%"
                          position=" "
                        >
                          <TitleEntrenamiento
                            className="col-9 roboto"
                            left="0px"
                            margin="auto"
                            top=" "
                          >
                            {post.name}
                          </TitleEntrenamiento>
                          <Image
                            src={post.image}
                            width="25%"
                            widthmob="22%"
                            margin="auto"
                            top="5%"
                            bottom="1%"
                            right="0px"
                          />
                        </ContentTimer>
                        <ContentTimer className="container-bar">
                          <Image
                            src={Clock}
                            width="20px"
                            left="0px"
                            position=" "
                            top="1%"
                          />
                          <TextTime className="roboto">{post.time}</TextTime>
                        </ContentTimer>
                      </a>
                    </ContEntrenamiento>
                  </ContEntrenamientos>
                )
              ) : null
            ),
          )}
        </Fragment>
        ) : null}
      {MainGoal === 'Aumentar mis ingresos'
      || sessionStorage.getItem('ObjetivoAhorro') === 'Aumentar mis ingresos' ? (
        <CardOne
          classCard="cardOne"
          classTop="topOne1"
          text="Ahorrar dinero rápido para cumplir metas específicas a veces requiere  buscar un ingreso extra."
          text2="¡Mira estas opciones!"
          hora={hora}
        />
        ) : null}
      {MainGoal === 'Reducir mis gastos'
      || sessionStorage.getItem('ObjetivoAhorro') === 'Reducir mis gastos' ? (
        <CardOne
          classCard="cardOne"
          classTop="topOne1"
          text="Ahorrar más dinero es un gran reto que puedes lograr si te decides a recortar algunos gastos."
          text2="¡Te diré cómo!"
          hora={hora}
        />
        ) : null}

      <Fragment>
        {(MainGoal === 'Aumentar mis ingresos'
          || sessionStorage.getItem('ObjetivoAhorro')
            === 'Aumentar mis ingresos')
          && trainingBig.map((post) => (post.idTrainning === 24 ? (
            post.Show ? (
                <ContEntrenamientos
                  id="ContEntrenamiento"
                  background="transparent"
                >
                  <ContEntrenamiento
                    id={post.IDCatTraining}
                    background={
                      post.TrainingColor ? post.TrainingColor : '#917AFF'
                    }
                    widthM="100%"
                    widthD="100%"
                    cursor="pointer"
                  >
                    <a
                      href={
                        post.TrainingRedirectUrl
                          ? post.TrainingRedirectUrl
                          : '#'
                      }
                    >
                      <ContentTimer
                        height=" "
                        maxheightMobile="130px"
                        heightMobile="140px"
                        maxheight="107.250px"
                        display="flex"
                        top="0px"
                        width="100%"
                        position=" "
                      >
                        <TitleEntrenamiento
                          className="col-9 roboto"
                          left="0px"
                          margin="auto"
                          top=" "
                        >
                          {post.name}
                        </TitleEntrenamiento>
                        <Image
                          src={post.image}
                          width="25%"
                          widthmob="22%"
                          margin="auto"
                          top="5%"
                          bottom="1%"
                          right="0px"
                        />
                      </ContentTimer>
                      <ContentTimer className="container-bar">
                        <Image
                          src={Clock}
                          width="20px"
                          left="0px"
                          position=" "
                          top="1%"
                        />
                        <TextTime className="roboto">{post.time}</TextTime>
                      </ContentTimer>
                    </a>
                  </ContEntrenamiento>
                </ContEntrenamientos>
            ) : (
                <ContEntrenamientos
                  id="ContEntrenamiento"
                  background="transparent"
                >
                  <ContEntrenamiento
                    id={post.IDCatTraining}
                    background={
                      post.TrainingColor ? post.TrainingColor : '#917AFF'
                    }
                    widthM="100%"
                    widthD="100%"
                    cursor="pointer"
                  >
                    <a
                      href={
                        post.TrainingRedirectUrl
                          ? post.TrainingRedirectUrl
                          : '#'
                      }
                    >
                      <ContentTimer
                        height=" "
                        maxheightMobile="130px"
                        heightMobile="140px"
                        maxheight="107.250px"
                        display="flex"
                        top="0px"
                        width="100%"
                        position=" "
                      >
                        <TitleEntrenamiento
                          className="col-9 roboto"
                          left="0px"
                          margin="auto"
                          top=" "
                        >
                          {post.name}
                        </TitleEntrenamiento>
                        <Image
                          src={post.image}
                          width="25%"
                          widthmob="22%"
                          margin="auto"
                          top="5%"
                          bottom="1%"
                          right="0px"
                        />
                      </ContentTimer>
                      <ContentTimer className="container-bar">
                        <Image
                          src={Clock}
                          width="20px"
                          left="0px"
                          position=" "
                          top="1%"
                        />
                        <TextTime className="roboto">{post.time}</TextTime>
                      </ContentTimer>
                    </a>
                  </ContEntrenamiento>
                </ContEntrenamientos>
            )
          ) : null))}
        {(MainGoal === 'Reducir mis gastos'
          || sessionStorage.getItem('ObjetivoAhorro') === 'Reducir mis gastos')
          && trainingBig.map(
            (post) => (
              (par = par === 0 ? 1 : 0),
              post.idTrainning === 2 ? (
                <ContEntrenamientos
                  id="ContEntrenamiento"
                  background="transparent"
                >
                  <ContEntrenamiento
                    id={post.IDCatTraining}
                    background={
                      post.TrainingColor ? post.TrainingColor : '#917AFF'
                    }
                    widthM="100%"
                    widthD="100%"
                    cursor="pointer"
                  >
                    <a
                      href={
                        post.TrainingRedirectUrl
                          ? post.TrainingRedirectUrl
                          : '#'
                      }
                    >
                      <ContentTimer
                        height=" "
                        maxheightMobile="130px"
                        heightMobile="140px"
                        maxheight="107.250px"
                        display="flex"
                        top="0px"
                        width="100%"
                        position=" "
                      >
                        <TitleEntrenamiento
                          className="col-9 roboto"
                          left="0px"
                          margin="auto"
                          top=" "
                        >
                          {post.name}
                        </TitleEntrenamiento>
                        <Image
                          src={post.image}
                          width="25%"
                          widthmob="22%"
                          margin="auto"
                          top="5%"
                          bottom="1%"
                          right="0px"
                        />
                      </ContentTimer>
                      <ContentTimer className="container-bar">
                        <Image
                          src={Clock}
                          width="20px"
                          left="0px"
                          position=" "
                          top="1%"
                        />
                        <TextTime className="roboto">{post.time}</TextTime>
                      </ContentTimer>
                    </a>
                  </ContEntrenamiento>
                </ContEntrenamientos>
              ) : null
            ),
          )}
      </Fragment>
    </Fragment>
  );
};
export default RpMainGoalES;
