import React, { Fragment, useEffect, useState } from 'react';
// rutinas
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import DomicileDebts from '../Routines/DomicileDebts';
import DetailRFC from '../Routines/DetailRFC';
import UserAuthentication from '../Routines/UserAuthentication';
import KnowScore from '../Routines/KnowScore';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderWoScore = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  backWO,
}) => {
  const [skipStep, setSkipStep] = useState(false);
  useEffect(() => {
    sessionStorage.setItem('datOpportunity', '0');
    const step = sessionStorage.getItem('stepScore') || '';
    if (step === 'true') {
      setSkipStep(true);
      setOrderRutine(6);
    }
  }, []);

  return (
    <Fragment>
      {orderRutine >= 1 && !skipStep ? (
        <ModalLogin
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={1}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
        // <DatBasic
        // Date={Date}
        // hora={hora}
        // orderRutine={orderRutine}
        // setOrderRutine={setOrderRutine}
        // currentTraining={currentTraining}
        // createJsonInput={createJsonInput}
        // questions={questions}
        // setQuestions={setQuestions}
        // trainingColor={trainingColor}
        // currentRoutine={1}
        // nextOrderRoutine={2}
        // recoveryTimeout={true}
        // />
      ) : null}
      {orderRutine >= 2 && !skipStep ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={2}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null}
      {/* {orderRutine >= 3 && !skipStep ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={3}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null} */}
      {orderRutine >= 4 && !skipStep ? (
        <DomicileDebts
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 5 && !skipStep ? (
        <DetailRFC
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={6}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 6 ? (
        <UserAuthentication
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          nextOrderRoutine={7}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 7 ? (
        <KnowScore
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={7}
          nextOrderRoutine="none"
          backWO={backWO}
        />        
      ) : null}
    </Fragment>
  );
};

export default RoutinesOrderWoScore;
