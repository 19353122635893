import React, { useState, Fragment, useEffect } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import BarraScroll from '../BarraScroll';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GET_TRAINING_COLOR,
  GETPERCENTAGETRAINING,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import RoutinesOrderWOValueOfMoney from './RoutinesOrderWOValueOfMoney';
import WaitList from '../../components/Training/WaitList';
import Starttraining from '../../components/Training/Starttraining';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import imgWo from '../../assets/img/anacoach/Children.svg';
import ClickButton from '../../Helpers/HookHelpers';
import WOlayout from '../../components/layouts/WOlayout';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import '../../assets/styles/kanda/KandaDesktop.css';

const items2 = ['Valor del dinero'];

const WOValueOfMoney = () => {
  const { actions } = GeneralHooks();
  // copia y pega estos states para crear un nuevo entrenamiento
  sessionStorage.setItem('idTraining', 31);
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(31);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [hasSuggestions] = useState(true);
  const [showExitHomeworkSchedule, setShowExitHomeworkSchedule] = useState(false);
  const [showDailyTaskLog, setShowDailyTaskLog] = useState(false);
  const [completedTasks, setCompletedTasks] = useState(false);
  const [showSummaryCompletedTasks, setShowSummaryCompletedTasks] = useState(false);
  const [showActiveRewards, setShowActiveRewards] = useState(false);
  const [showSavingAndHw, setShowSavingAndHw] = useState(false);
  const [showWindow2, setShowWindow2] = useState(false);
  const [showTaskLog, setShowTaskLog] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const anchop = window.screen.width;
  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  const { actions: actions2 } = ClickButton();

  useEffect(() => {
    if (showSavingAndHw) {
      const click = `VerAvance_${sessionStorage.getItem('idTraining')}`;
      actions2({ action: 'ClickButtonCollection', variables: click });
    }
  }, [showSavingAndHw]);

  // MUTATIONS AND QUERIES
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  const [firstRoutinesCheck, setFirstRoutinesCheck] = useState(true);

  useEffect(() => {
    if (orderRutine >= 5 && firstRoutinesCheck) {
      saveAdvance(1);
      saveAdvance(2);
      setFirstRoutinesCheck(false);
    }

    const orderRutineTemp = orderRutine;

    if (
      !Number.isInteger(orderRutineTemp)
      && orderRutineTemp === 'Lista de tareas'
    ) {
      saveAdvance(6);
    }

    console.log('rutina cx >>> ', orderRutine);
  }, [orderRutine]);

  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveStatusTraining }) {
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          const nameSession = arraySessionsRutines[questionRoutine.DatUserDetailStorage];
          sessionStorage.setItem(nameSession, item.value);

          switch (nameSession) {
            case 'Email1':
              localStorage.setItem('Email1', item.value);
              break;
            case 'CellPhone':
              localStorage.setItem('CellPhone', item.value);
              break;
            case 'idCont':
              localStorage.setItem('idCont', item.value);
              break;
            case 'idUser':
              localStorage.setItem('idUser', item.value);
              break;
            default:
              break;
          }

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  const saveAdvance = async (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    let userRoutines = '';
    if (
      currentOrderRoutine === 6
      || currentOrderRoutine === 'Lista de tareas'
    ) {
      userRoutines = '1,2,3,4,6';
    }
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines,
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      await saveStatusTraining({ variables: inputProgress });
    }
  };
  const createTempUser = (idUser, idCont) => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    idUser = idUser !== '' && idUser !== undefined && idUser !== null
      ? idUser
      : sessionStorage.getItem('idUser') || '';
    idCont = idCont !== '' && idCont !== undefined && idCont !== null
      ? idCont
      : sessionStorage.getItem('idCont') || '';
    console.log('idUser', idUser, 'idCont', idCont);
  };
  // END FUNCIONES

  // useeffect genericos para los entrenamientos
  // Valid WaitList
  // debugger
  console.log(currentTraining);
  const idTraining = String(currentTraining);
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining } });
  }, []);

  // obtener las utms
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'money-value');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: currentTraining },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);
  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser, idCont, hostName, pageName, fromXCoach, idAdviser,
    } = getQueryVariableHashtagDeeplink('money-value');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    createTempUser(idUser, idCont);
  }, []);

  useEffect(() => {
    getTrainingColor({ variables: { idTraining: String(currentTraining) } });
  }, []);

  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '10 minutos',
    imgWo: { imgWo },
    txtsubtitle: 'La importancia de aprender desde chiquitos',
  };
  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      pathnameSearch={window.location.search}
      headerType={
        window.location.search.includes('idUser') ? 'HeaderKids' : 'HeaderTwo'
      }
    >
      <Helmet>
        <title>Educación Financiera para Niños | Coru</title>
        <link rel="canonical" href="https://coru.com/wo/money-value" />
        <meta name="keywords" content="Educación Financiera para Niños" />
        <meta
          name="description"
          content="Ayuda a tus hijos a tener un mejor futuro. Entra a este entrenamiento y prepárate para preparalos en su futuro."
        />
      </Helmet>
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            {!showExitHomeworkSchedule ? (
              <TrainingAnimateFunnel
                bcolor={colorTraining}
                pmob="0 0 0 0"
                margin="0 0 24px 0"
                minh="309px"
                hweb="410px"
              >
                <BarraInfoWO propied={propied} />
                <ModalDataPersonalInitWO isData={isData} setData={setisData} />
                {scroll1 > 0 ? <BarraScroll /> : null}
                <TrainingLine id="line-percent-gray" mt=""></TrainingLine>
                {percentage !== '' ? (
                  <TrainingLine
                    id="line-percent-pink"
                    bcolor="#F64282"
                    mt=""
                    wweb={percentage}
                    w={percentage}
                    mr="auto"
                    op="1"
                    br="100px"
                  ></TrainingLine>
                ) : null}
              </TrainingAnimateFunnel>
            ) : null}
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ModalDataPersonalInitWO isData={isData} setData={setisData} />
                <DataPersonalInitWO
                  Date={Date}
                  hora={hora}
                  orderRutine={orderRutine}
                  setOrderRutine={setOrderRutine}
                  currentTraining={currentTraining}
                  createJsonInput={createJsonInput}
                  questions={questions}
                  setQuestions={setQuestions}
                />
                {orderRutine !== 'Lista de tareas' ? (
                  <TrainingSectionGray
                    wiw="100%"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      <div className="marginComentLgSuccess1">
                        <Starttraining
                          title="¡Hola! Soy Ana, tu coach financiero."
                          text="Este entrenamiento está pensado para que ayudes a tus hijos a descubrir el esfuerzo que existe detrás de cada moneda o billete."
                        />

                        <Starttraining
                          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                          text="Con tu guía, aprenderá a administrar sus gastos mediante actividades y recompensas."
                        />

                        <Starttraining
                          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                          title="Iniciemos esta aventura..."
                          text="Primero necesito conocerte, ayúdame con tus datos personales."
                        />

                        <Fragment>
                        </Fragment>
                      </div>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                ) : null}
                <TrainingSectionGray
                  wiw="100%"
                  mw="auto"
                  back="#FAFAFA"
                  mtop="0"
                >
                  <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                    {hasSuggestions ? (
                      <div className="marginComentLgSuccess1">
                        <RoutinesOrderWOValueOfMoney
                          Date={stateDate}
                          hora={hora}
                          orderRutine={orderRutine}
                          setOrderRutine={setOrderRutine}
                          currentTraining={currentTraining}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                          // states de pantallas externas al Wo
                          showExitHomeworkSchedule={showExitHomeworkSchedule}
                          setShowExitHomeworkSchedule={
                            setShowExitHomeworkSchedule
                          }
                          showDailyTaskLog={showDailyTaskLog}
                          setShowDailyTaskLog={setShowDailyTaskLog}
                          completedTasks={completedTasks}
                          setCompletedTasks={setCompletedTasks}
                          showSummaryCompletedTasks={showSummaryCompletedTasks}
                          setShowSummaryCompletedTasks={
                            setShowSummaryCompletedTasks
                          }
                          showActiveRewards={showActiveRewards}
                          setShowActiveRewards={setShowActiveRewards}
                          showSavingAndHw={showSavingAndHw}
                          setShowSavingAndHw={setShowSavingAndHw}
                          showWindow2={showWindow2}
                          setShowWindow2={setShowWindow2}
                          showTaskLog={showTaskLog}
                          setShowTaskLog={setShowTaskLog}
                        />
                      </div>
                    ) : null}
                  </TrainingDivSection2>
                </TrainingSectionGray>
                <br />
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
};

export default WOValueOfMoney;
