/* eslint-disable react/prop-types */
import React, { useState, useEffect, Suspense } from 'react';
import TagManager from 'react-gtm-module';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useLazyQuery } from '@apollo/client';
import ShowAvailableCardsV2 from '../Routines/ShowAvailableCardsV2';
import DetailRFC from '../Routines/DetailRFC';
import PersonalInformation from '../Routines/PersonalInformation';
import Domicile from '../Routines/Domicile';
import UserAuthentication from '../Routines/UserAuthentication';
import DatosLaborales from '../Routines/DatosLaborales';
import INE from '../Routines/INE';
import Referencias from '../Routines/Referencias';
import StepCreditea from '../Routines/StepCreditea';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import Curp from '../Routines/Curp';
import ClickButton from '../../Helpers/HookHelpers';
import { DivLoader } from '../../assets/styles/Training.styled';
import {
  CREATE_SESSION_MYSQL,
  CREATE_SESSION,
  DYNAMIC_GET,
  CREATE_OPPORTUNITIES,
  INSERT_FINISHED_WO_BY_XCOACH,
  GENERATE_SHORTENED_LINK,
  SEND_NOTIFICATION,
  APLICAR_TODAS_TARJETAS,
} from '../../mutations';
import { GET_TRAINING_COLOR } from '../../queries';

const RoutinesOrderCCTest = ({
  Dates,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  insertRoutine,
  updateSession,
  createSession,
  setactive4,
  active4,
}) => {
  console.log(`active4 ${active4}`);
  const [Rutine] = useState({});
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [colorTraining, setColorTraining] = useState('');
  const [Card, setTarjeta] = useState(
    sessionStorage.getItem('recoveryTarjetas') || '',
  );
  const [OneCard] = useState('');
  const [editCS, setEditCS] = useState(false);
  const [aplicarTodasTarjetas] = useMutation(APLICAR_TODAS_TARJETAS);
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getProductsName] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log(`dynamicGet ${dynamicGet}`);
      if (dynamicGet.statusCode === 200) {
        const namesProducts = JSON.parse(dynamicGet.response);
        sessionStorage.setItem('numProducts', namesProducts.length);
        for (const i in namesProducts) {
          const num = parseInt(i) + 1;
          sessionStorage.setItem(
            `product-${String(num)}`,
            namesProducts[i].Name,
          );
        }
      }
    },
  });

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        setactive4(false);
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const insertOpportunitiesUser = async (idProducts) => {
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1, // remplazar por el id del entrenamiento
        idProducts, // arreglo de numero ejemplo [10,1,3]
        idTraining: 46,
        idType: '989',
      },
    };
    const conditions = [];
    for (const i in idProducts) {
      const conditionsTemp = {
        valone: 'IDProduct',
        condition: '=',
        valtwo: String(idProducts[i]),
        logic: 'OR',
      };
      conditions.push(conditionsTemp);
    }
    const productsNameInput = {
      input: {
        table: 'CatProduct',
        columns: ['Name'],
        conditions,
      },
    };
    if (sessionStorage.getItem('datOpportunity')) {
      opportunityDetailInput.input.idOpportunities = sessionStorage
        .getItem('datOpportunity')
        .split(',')
        .map(Number);
    }
    await getProductsName({ variables: productsNameInput });

    createOpportunitiesUser({ variables: opportunityDetailInput });
  };

  const { actions } = ClickButton();
  const [openLoading, setopenLoading] = useState(false);
  const handleRoutineOrder = (step, prod, cantidad, tel) => {
    setEditCS(true);
    console.log(active4);
    if (cantidad === 'Todas') {
      const valor = document
        .getElementById('productos3')
        .textContent.split('-');
      setTarjeta(valor);
      setactive4(false);
      const divs = document.getElementsByClassName('productoMod');
      const apply = [];
      for (let d = 0; d < divs.length; d++) {
        const check = divs[d].id;
        apply.push(parseInt(check));
      }
      console.log(apply);
      sessionStorage.setItem('SelectIds', apply);
      insertOpportunitiesUser(apply);
      createJsonInput(
        Rutine,
        questions,
        insertRoutine,
        orderRutine,
        updateSession,
        createSession,
        TagManager,
      );
      setQuestions([]);
      if (sessionStorage.getItem('idTraining') === '35') {
        const clickEventCC = `Modal3Tarjetas_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
        actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
      }
      const click = `Modal3Tarjetas_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      //! Ingresar mutations oca
      const idUser = sessionStorage.getItem('idUser');
      console.log(`este es el valor de idUser: ${idUser}`);
      const isUserpar = parseInt(idUser, 10);
      aplicarTodasTarjetas({
        variables: { idUser: isUserpar },
      });
    } else {
      setTarjeta(cantidad);
      sessionStorage.setItem('SelectIds', prod);
      insertOpportunitiesUser(prod);
      createJsonInput(
        Rutine,
        questions,
        insertRoutine,
        orderRutine,
        updateSession,
        createSession,
        TagManager,
      );
      setQuestions([]);
    }
    setOrderRutine(5);
  };

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    fetchPolicy: 'no-cache',
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  const [newSession, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [sendNotification] = useMutation(SEND_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted({ sendNotification }) {
      if (sendNotification.statusCode === 200) {
        const send = sendNotification.response;
        if (send !== '') {
          console.log('Se envio correctamente el video!!', send);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted({ generateShortendLink }) {
      if (generateShortendLink.message === 'Exito') {
        const urlShortened = generateShortendLink.response;

        const cellPhone = sessionStorage.getItem('CellPhone');
        const firstName = sessionStorage.getItem('FirstName');
        const inputSendVideo = {
          input: {
            type: 'wamult',
            properties: {
              trigger: 'Rappi_TransaccionalV3',
              telephone: cellPhone,
              subject: '',
              template: '',
              variables: [
                {
                  setkey: 'Nombre',
                  setval: firstName,
                },
                {
                  setkey: 'Rappi_URL',
                  setval: urlShortened,
                },
              ],
              source: {},
              destination: {},
            },
          },
        };
        sendNotification({ variables: inputSendVideo });
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [generateShortendLinkForNu] = useMutation(GENERATE_SHORTENED_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted({ generateShortendLink }) {
      if (generateShortendLink.message === 'Exito') {
        const urlShortened = generateShortendLink.response;

        const cellPhone = sessionStorage.getItem('CellPhone');
        const firstName = sessionStorage.getItem('FirstName');
        const inputSendWaNu = {
          input: {
            type: 'wamult',
            properties: {
              trigger: 'Nu_Transaccional',
              telephone: cellPhone,
              subject: '',
              template: '',
              variables: [
                {
                  setkey: 'Nombre',
                  setval: firstName,
                },
                {
                  setkey: 'Nu_url',
                  setval: urlShortened,
                },
              ],
              source: {},
              destination: {},
            },
          },
        };
        sendNotification({ variables: inputSendWaNu });
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
      'aquiestoy',
    );
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      console.log("entro a linea 353 de routinesOrderCC");
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) { newSession({ variables: mongoInput }); }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    if (orderRutine === 0) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [orderRutine]);

  // useEffect(() => {
  //   if (orderRutine === 5) {
  //     let idUser = '';
  //     let cellPhone = '';
  //     let URL = '';
  //     idUser = sessionStorage.getItem('idUser');
  //     cellPhone = sessionStorage.getItem('CellPhone');
  //     URL = `https://rappi.onelink.me/ym92?af_adset=true&af_ad=${idUser}&pid=coru_int&c=Coru_Retargeting&is_retargeting=true&af_click_lookback=7d&af_dp=gbrappi%3A%2F%2Fcom.grability.rappi%3Fgoto%3Drappi_pay&af_reengagement_window=30d&af_inactivity_window=0d&clickID=true&android_ID=true&idfa=true&is_primary_attribution=true&af_force_deeplink=true`;
  //     const inputShortened = {
  //       input: {
  //         url: URL,
  //         telefono: cellPhone,
  //         mensaje: 'Rappi',
  //         idGen: 'ShareRappi',
  //       },
  //     };
  //     generateShortendLink({ variables: inputShortened });

  //     // Verificamos si Nu está seleccionada
  //     const walletStorage = sessionStorage.getItem('walletArr');
  //     if (walletStorage && walletStorage.includes('Tarjeta de Crédito Nu')) {
  //       idUser = sessionStorage.getItem('idUser');
  //       cellPhone = sessionStorage.getItem('CellPhone');
  //       URL = `https://nu.com.mx/?utm_source=coru&utm_medium=remkt&utm_campaign=test&utm_content=nofees&coru_id=${idUser}`;
  //       const inputShortenedNu = {
  //         input: {
  //           url: URL,
  //           telefono: cellPhone,
  //           mensaje: 'Nu',
  //           idGen: 'ShareNu',
  //         },
  //       };
  //       generateShortendLinkForNu({ variables: inputShortenedNu });
  //     }
  //   }
  // }, [orderRutine]);
  console.log('orderRutine: ', orderRutine);
  return (
    <div>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Dates}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={colorTraining}
            recoveryTimeout={true}
          />
        </Suspense>
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          mainDataRoutine={mainDataRoutine}
          date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          trainingColor={colorTraining}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 3 ? (
        <ContactInformation
          mainDataRoutine={mainDataRoutine}
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 4 ? (
        <ShowAvailableCardsV2
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={colorTraining}
          handleRoutineOrder={handleRoutineOrder}
          openLoading={openLoading}
          setopenLoading={setopenLoading}
          createOpportunities={insertOpportunitiesUser}
          editCS={editCS}
          Card={Card}
          OneCard={OneCard}
        />
      ) : null}
      {orderRutine == 5 ? (
        <DetailRFC
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={6}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 6 ? (
        <PersonalInformation
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          setactive4={setactive4}
          nextOrderRoutine={7}
          trainingColor={colorTraining}
        />
      ) : null}
      {/* aquie debera ir la rutina de curp 5 */}
      {orderRutine >= 7 ? (
        <Curp
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          idDatRelRoutines={16}
          currentRoutine={7}
          nextOrderRoutine={8}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 8 ? (
        <Domicile
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={8}
          nextOrderRoutine={9}
          trainingColor={colorTraining}
        />
      ) : null}
      {/* <Authenticate
                setOrderRutine={setOrderRutine}
                currentRoutine={9}
                nextOrdeRoutine={10}
                data={form}
                setForm={setForm}
                triedAut={triedAut}
                setTriedAut={setTriedAut}
                bancoActual={bancoActual}
                responseBuro={responseBuro}
                setResponseBuro={setResponseBuro}
            /> */}
      {orderRutine >= 9 ? (
        <UserAuthentication
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={9}
          nextOrderRoutine={10}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 10 ? (
        <DatosLaborales
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={10}
          nextOrderRoutine={13}
          trainingColor={colorTraining}
        />
      ) : null}
      {/* {orderRutine >= 11 ? (
        <INE
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={11}
          nextOrderRoutine={12}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina11"></div>
      {orderRutine >= 12 ? (
        <Referencias
          Date={Dates}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={12}
          nextOrderRoutine={13}
          trainingColor={colorTraining}
        />
      ) : null} */}
      {orderRutine >= 13 ? (
        <StepCreditea
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={13}
          nextOrderRoutine={0}
          trainingColor={colorTraining}
        />
      ) : null}
    </div>
  );
};

export default RoutinesOrderCCTest;
