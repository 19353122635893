import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Main, Container, ParagrapTwo, ContainerRequest, Image, ContainerFlexSolicitud, SolicitudText, TxtDetalle,
} from '../../components/common/common.styled';
import FooterFeed from '../../components/Feed/Footer_Feed';
import '../../assets/styles/perfil/perfiles.css';
import Header_Logros from '../../components/Perfiles/Header_Perfil';
import { DYNAMIC_GET } from '../../mutations';
import TestViralidad from '../../components/Logros/TestViralidad';
import RelacionadosRechazo from './RelacionadosRechazo';
import downArrow from '../../assets/img/anacoach/arrow-down.png';
import upArrow from '../../assets/img/anacoach/arrow.png';
import Cartera from '../../assets/img/logros/Cartera.svg';
import Flag from '../../assets/img/logros/Flag.svg';
import Share from '../../assets/img/logros/Share.svg';
import Clock from '../../assets/img/anacoach/clockTraining.png';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';

import {
  Image as ImageH,
  ContEntrenamiento,
  ContEntrenamientos,
  ContentTimer,
  TitleEntrenamiento,
  TextTime,
} from '../../assets/styles/Home.styled';

function Resquest({ Novisible, paddingInicio, isViernes }) {
  const [Oportunities, SetOportunities] = useState([]);
  const [progressTemp, setProgressTemp] = useState([]);
  const [aprobada] = useState(false);
  const [secondary, setSecondary] = useState(0);
  const [menor, setMenor] = useState(0);
  const [legend, setLegend] = useState({ estado: '', color: '' });
  const [showRelated, setShowRelated] = useState(false);
  const history = useHistory();
  const [verinfo, setVErinfo] = useState('');
  const [Prueba, setPrueba] = useState([]);

  const getOportunidad = (op) => {
    const testArray = [];
    const testArray2 = [];
    const testArray3 = [];
    const testArray4 = [];
    const itemsRepeated = {};
    const array = [];

    for (let i = 0; i < op.length; i++) {
      const item = op[i];
      if (!itemsRepeated[item.IDProduct]) {
        itemsRepeated[item.IDProduct] = item.IDProduct;
        array.push(item);
      }
    }

    if (array.length <= 3) {
      for (let i = 0; i < array.length; i++) {
      /*if (array.length >= 2) {
          testArray4.sort(function (a, b) {
            testArray4.push(array[i]);
            if (a.IDProvider > b.IDProvider) {
              return 1;
            }
            if (a.IDProvider < b.IDProvider) {
              return -1;
            }
            return 0;
          });  
        } else {*/
        testArray4.push(array[i]);
        //}
      }
      debugger
      setPrueba(testArray4);
    } else {
      for (let j = 0; j < array.length; j++) {
        if (
          array[j].IDProduct === 6 ||
          array[j].IDProduct === 311 ||
          array[j].IDProduct === 297 ||
          array[j].IDProduct === 291 ||
          array[j].IDProduct === 564
        ) {
          testArray4.push(array[j]);
          testArray4.sort(function (a, b) {
            if (a.IDProvider > b.IDProvider) {
              return 1;
            }
            if (a.IDProvider < b.IDProvider) {
              return -1;
            }
            return 0;
          });
          debugger
          setPrueba(testArray4);
        }
      }
    }

    if (testArray4.length > 3) {
      testArray4.reverse(testArray4);
      const aNuevo = testArray4.slice(testArray4.length - 3);
      aNuevo.reverse(aNuevo);
      debugger;
      setPrueba(aNuevo);
    }

    // Forjando el ultimo registro de la BD en funcion de la fecha y hora
    const fecha = op[op.length - 1].CreatedAt;
    testArray.push(op[op.length - 1]);
    for (let i = op.length - 2; i > -1; i--) {
      if (op[i].CreatedAt === fecha) {
        testArray.push(op[i]);
      } else {
        break;
      }
    }
    // Comprobando si hay AMEX:
    for (const j in testArray) {
      if (testArray[j].IDProduct === 6) {
        testArray2.push(testArray[j]);
      }
    }
    if (testArray2.length !== 0) {
      SetOportunities(testArray2);
      getAllTrainingFunction();
    } else {
      // SI no hay AMEX
      // Obteniendo el ultimo registro del conjunto de registros
      const idOp = testArray[0].DatOpportunity_IDDatOpportunity;
      for (let i = 0; i < testArray.length; i++) {
        if (testArray[i].DatOpportunity_IDDatOpportunity === idOp) {
          testArray3.push(testArray[i]);
        } else {
          break;
        }
      }
      // Verificando si es otro tipo como stori, vexi, creditea, banco azteca Ban coppel
      if (testArray3[0].IDProduct === 19 || testArray3[0].IDProduct === 290 || testArray3[0].IDProduct === 281 || testArray3[0].IDProduct === 38) {
        setSecondary(1);
      } else {
        setSecondary(0);
      }
      SetOportunities(testArray3);
      getAllTrainingFunction();
    }
  };

  const [getAllOportunities] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const OportunitiesTmp = JSON.parse(dynamicGet.response);
        debugger;
        // Obtiene las oportunidades del usuario
        // console.log(OportunitiesTmp);
        if (OportunitiesTmp.length > 0) {
          // Caso hay oportunidad y hay detalles de oportunidad
          getOportunidad(OportunitiesTmp);
        } else {
          // Caso de que halla Oportunity pero no detalles de la oportunity
          getOnliOpp();
        }
      }
    },
  });

  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const inputGetOportunities = {
      input: {
        table: 'CatContact, DatOpportunity, CatRelUsersOpportunity, CatProduct, CatProviders',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: String(sessionStorage.getItem('idUser')),
          },
          {
            logic: 'AND',
            valone: 'DatOpportunity.IDDatOpportunity',
            condition: '=',
            valtwo: 'CatRelUsersOpportunity.IDDatOpportunity',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'CatProduct_IDProduct',
            condition: '=',
            valtwo: 'IDProduct',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'CatProviders_IDProvider',
            condition: '=',
            valtwo: 'IDProvider',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'CatContact_IDCont',
            condition: '=',
            valtwo: 'IDCont',
            validColumn: true,
          },
        ],
      },
    };
    getAllOportunities({ variables: inputGetOportunities });
  }, []);
  let Training;
  let userDetail;

  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => {
            switch (element.IDType) {
              case '3':
                sessionStorage.setItem('level', element.Value);
                break;
              default:
                break;
            }
          },
        );
      }
    },

  });
  const ArrayP = [];
  const ArrrayS = [];

  const ConstruccionArray = (items2) => {
    const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
    for (let i = 0; i < items2.length; i += LONGITUD_PEDAZOS) {
      const pedazo = items2.slice(i, i + LONGITUD_PEDAZOS);
      ArrayP.push(pedazo);
    }
  };
  const [getAllTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        Training = JSON.parse(dynamicGet.response);
        for (let i = 0; i < Training.length; i++) {
          if (Training[i].Achievment < 100) { // En proceso
            ArrrayS.push(Training[i]);
          }
          if (Training[i].Achievment < 90 && Training[i].CatTraining_IDTraining === 35) { // Sin solicitar
            setProgressTemp(Training[i]);
            setMenor(1);
            setLegend({ estado: 'Sin solicitar', color: '#000' });
          } else {
            setMenor(2);
            if (Training[i].Achievment === 100) { 
              setLegend({ estado: 'En proceso', color: '#E18700' });
            } else {
              setLegend({ estado: 'En proceso', color: '#E18700' });
            }
          }
        }
        if (ArrrayS.length === 0) {
          return;
        }
        ConstruccionArray(ArrrayS);
      }
    },
  });

  const getAllTrainingFunction = () => {
    const getAllTrainingInput = {
      input: {
        table: 'CatRelUsersTraining, DatTraining, CatTraining',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
          {
            logic: 'AND',
            valone: 'CatRelUsersTraining.IDDatTraining',
            condition: '=',
            valtwo: 'DatTraining.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDCatTraining',
            condition: '=',
            valtwo: 'CatTraining_IDTraining',
            validColumn: true,
          },
        ],
      },
    };
    getAllTraining({ variables: getAllTrainingInput });
  };

  const [getOnliOppFunc] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const OportunitiesTmp = JSON.parse(dynamicGet.response);
        debugger;
        if (OportunitiesTmp.length > 0) {
          getOportunidad(OportunitiesTmp);
        } else {
          // No hay ni oportunidad ni detalles
          getAllTrainingFunction();
        }
      }
    },
  });

  const getOnliOpp = () => {
    const getOnliOpp = {
      input: {
        table: 'DatOpportunity, CatRelUsersOpportunity, CatProduct, CatProviders, CatContact',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: String(sessionStorage.getItem('idUser')),
          },
          {
            logic: 'AND',
            valone: 'DatOpportunity.IDDatOpportunity',
            condition: '=',
            valtwo: 'CatRelUsersOpportunity.IDDatOpportunity',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'CatProduct_IDProduct',
            condition: '=',
            valtwo: 'IDProduct',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'CatProviders_IDProvider',
            condition: '=',
            valtwo: 'IDProvider',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'CatContact_IDCont',
            condition: '=',
            valtwo: 'IDCont',
            validColumn: true,
          },
        ],
      },
    };
    getOnliOppFunc({ variables: getOnliOpp });
  };

  // Visual effects
  const scrollToTop = () => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const dynamicGetInput = {
      input: {
        table: 'CatUserDetails',
        columns: ['*'],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
        ],
      },
    };

    getUserDetail({ variables: dynamicGetInput });
    scrollToTop();
  }, []);

  const [goTrainingRazones, setGoTrainingRazones] = useState(false);
  useEffect(() => {
    if (goTrainingRazones) {
      history.push('/wo/rejection-reasons');
    }
  }, [goTrainingRazones]);

  const viewMore = (e, id, status) => {
    setVErinfo(id);
    setShowRelated(!showRelated);
    const elemento = document.getElementById(`InfoAdicional-${id}`);
    const btnV = document.getElementById(`BtnVer-${id}`);
    const btnRech = document.getElementById(`InfoRechazo-${id}`);
    if (status === 'Ver') {
      elemento.className = '';
      btnV.className = 'hidden';
      btnRech.className = '';
    } else if (status === 'Ocultar') {
      elemento.className = 'hidden';
      btnV.className = '';
      btnRech.className = 'hidden';
    }
  };

  const handleOpenFeed = () => {
    history.push('/wo/card-funnel');
  };

  const handleClickOut = url => {
    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();

  }

  return (
    <Fragment>
      {!Novisible ? (<Header_Logros name={sessionStorage.getItem('firstName')} />) : (<div></div>)}
      <Main minheigth="auto" background='#fafafa' mt={isViernes ? ' ' : '10%'} mtWeb={isViernes ? ' ' : '10%'} ptuno={!paddingInicio ? '70px' : ' '} pt={!paddingInicio ? '80px' : ' '}>
        {isViernes
          ? <Fragment>
            <TestViralidad />
            <br />
          </Fragment> : null
        }
        <Container displayweb="block" className="container" paddingMobile="0!important" width="94%" widthMobile="100%" mtMobile="0px">
          <ParagrapTwo
            mb="10%" fontSize="20px" fontSizeWeb="20px" mt="5%" mbweb="35px"
            textalignMobile="left"
            texttalign="left"
            className="mx-auto roboto" mtWeb="10%"
            fontWeight="bold">
            Mis solicitudes:
          </ParagrapTwo>
          {Oportunities.length === 0
            ? progressTemp.length === 0
              ? <div>

                <Fragment>
                  <SinSolicitud />
                </Fragment>
              </div>
              : <>
                <ContainerRequest>
                  <div style={{ padding: '21px', color: '#07210A' }}>
                    <p style={{ fontSize: '14px' }} className="mb-3">
                      {sessionStorage.getItem('FirstName')}, no dejes pendiente tu solicitud.
                      Puedes regresar y aplicar para las tarjetas que te interesan, nosotros te ayudaremos.
                    </p>
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      min_web="100%"
                      mtopw="10px"
                      mtop="0px"
                      mtopmd="0px"
                      onClick={handleOpenFeed}
                    >
                      <b>Terminar entrenamiento</b>
                    </CardsBtn>
                  </div>
                </ContainerRequest>
                <RelacionadosRechazo
                  idProd={-1}
                />
              </>
            : menor === 1
              ? 
              <>
              {Prueba.map((Oportunities) => (
              <ContainerRequest >
                <ContainerFlexSolicitud>
                  <Image src={Oportunities.Logo} width="70px" marginRight="0" marginRightM="0" />
                  <SolicitudText fc='#A6A6A6' fw='600' fs='18px'>N° {Oportunities.IDDatOpportunity}</SolicitudText>
                </ContainerFlexSolicitud>
                <ContainerFlexSolicitud jc="start" ai="center" fw="wrap">
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Image src={Oportunities.ImageURL} width="95px" marginRight="0" marginRightM="0" />
                    <SolicitudText ml='0' fs='20px' fc='#000000' fw='600'>
                      <div className="cabin text-center ml-2 "> {Oportunities.Name} </div>
                    </SolicitudText>
                  </div>
                </ContainerFlexSolicitud>
                <ContainerFlexSolicitud ai="baseline">
                  <SolicitudText fs='16px' fc={legend.color} fw='bold' fsM="13px">
                    {legend.estado}
                  </SolicitudText>
                  <SolicitudText fs='16px' fc='#07210A' fw='400' fsM="12px">
                    Solicitada el: {Oportunities.CreatedAt.substring(0,10)}
                  </SolicitudText>
                </ContainerFlexSolicitud>
                { 
                  Oportunities.BusinessAbr === 'Stori' || 
                  Oportunities.BusinessName ==='Vexi' ||
                  Oportunities.BusinessAbr === 'Nu' || 
                  Oportunities.BusinessName === 'Rappi ' ||
                  Oportunities.BusinessAbr === 'BBVA' ?
                    (
                      <div id={`InfoAdicional-${Oportunities.IDDatOpportunity}`} style={{ padding: '21px', color: '#07210A' }}>
                        <p style={{ fontSize: '14px' }} className="mb-3">
                          {sessionStorage.getItem('FirstName')}, no dejes pendiente tu solicitud. Continúa con tu proceso y obtén la tarjeta ideal para tí.
                        </p>
                        <CardsBtn
                          type="button"
                          min_w="100%"
                          min_web="100%"
                          mtopw="10px"
                          mtop="0px"
                          mtopmd="0px"
                          onClick={() => handleClickOut(
                            Oportunities.ClickOut,
                            )
                          }
                        >
                          <b>Terminar mi solicitud</b>
                        </CardsBtn>
                      </div>
                    ): (
                      <div style={{ padding: '21px', color: '#07210A' }}>
                        <p style={{ fontSize: '14px' }} className="mb-3">
                          {sessionStorage.getItem('FirstName')}, no dejes pendiente tu solicitud.
                          Puedes regresar y aplicar para las tarjetas que te interesan, nosotros te ayudaremos.
                        </p>
                      </div>
                    )}
              </ContainerRequest>
              ))}
                <div style={{ padding: '21px', color: '#07210A' }}>
                  <p style={{ fontSize: '14px' }} className="mb-3">
                    Tu entrenamiento sigue en progreso, te quedan 3 preguntas por responder. ¿Porqué no lo terminas? Te ayudaremos a aplicar a las tarjetas que te interesan.
                  </p>
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    border="1px solid #F64282"
                    back= "transparent"
                    color="#F64282"
                    mtopw="10px"
                    mtop="0px"
                    mtopmd="0px"
                    onClick={handleOpenFeed}
                  >
                    <b>Terminar entrenamiento</b>
                  </CardsBtn>
                </div>
              </>
              : menor === 2
                ? Prueba.map((oportunities) => (
                  <Fragment key={oportunities.IDDatOpportunity}>
                    <ContainerRequest >
                      <ContainerFlexSolicitud>
                        <Image src={oportunities.Logo} width="70px" marginRight="0" marginRightM="0" />
                        <SolicitudText fc='#A6A6A6' fw='600' fs='18px'>N° {oportunities.IDDatOpportunity}</SolicitudText>
                      </ContainerFlexSolicitud>
                      <ContainerFlexSolicitud jc={secondary === 1 ? 'space-between' : 'start'} ai="center" fw="wrap">
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                          <Image src={oportunities.ImageURL} width="95px" marginRight="0" marginRightM="0" />
                          <SolicitudText ml='0' fs='20px' fc='#000000' fw='600'>
                            <div className="cabin text-center ml-2 "> {oportunities.Name} </div>
                          </SolicitudText>
                        </div>
                        {secondary === 1
                          ? <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Image cursor='pointer' src={Cartera} width="20px" marginRight="0" marginRightM="0" />
                              <Image cursor='pointer' src={Flag} width="20px" marginRight="0" marginRightM="0" />
                              <Image cursor='pointer' src={Share} width="20px" marginRight="0" marginRightM="0" />
                            </div>
                            <SolicitudText fs='14px' fc="#07210A" fw='400'>
                              Anualidad
                            </SolicitudText>
                            <SolicitudText fc='#07210A' fw='600' fs='20px'>$0 MXN</SolicitudText>
                          </div>
                          : null
                        }
                      </ContainerFlexSolicitud>
                      <ContainerFlexSolicitud ai="baseline">
                        <SolicitudText fs='16px' fc={legend.color} fw='bold' fsM="13px">
                        {oportunities.IDProduct == 6 ? legend.estado : 'Pendiente'}
                        </SolicitudText>
                        <SolicitudText fs='16px' fc='#07210A' fw='400' fsM="12px">
                          Solicitada el: {moment(oportunities.CreatedAt).format('D/MM/YYYY')}
                        </SolicitudText>
                      </ContainerFlexSolicitud>
                      { 
                      oportunities.BusinessAbr === 'Stori' || 
                      oportunities.BusinessName ==='Vexi' ||
                      oportunities.BusinessAbr === 'Nu' || 
                      oportunities.BusinessName === 'Rappi ' ||
                      oportunities.BusinessAbr === 'BBVA' ?
                        (
                        <div style={{ padding: '21px', color: '#07210A' }}>
                          <div id={`InfoAdicional-${oportunities.IDDatOpportunity}`} style={{ padding: '21px', color: '#07210A' }}>
                              <p style={{ fontSize: '14px' }} className="mb-3">
                                Para continuar con tu proceso, {oportunities.BusinessAbr} requiere información adicional sobre ti. Haz click en "Terminar mi Solicitud".
                              </p>
                          </div>
                          <CardsBtn
                            type="button"
                            min_w="100%"
                            min_web="100%"
                            mtopw="10px"
                            mtop="0px"
                            mtopmd="0px"
                                onClick={() => handleClickOut(
                                  oportunities.ClickOut,
                                )
                              }
                              >
                                <b>Terminar mi solicitud</b>
                              </CardsBtn>
                          </div>
                        ) :  
                        <div id={`InfoAdicional-${oportunities.IDDatOpportunity}`} style={{ padding: '21px', color: '#07210A' }}>
                          <p style={{ fontSize: '14px' }} className="mb-3">
                          Tu información ha sido enviada y recibida. En menos de una semana, estarán contactándote directamente desde {oportunities.BusinessAbr} para finalizar el proceso.                          </p>
                        </div>
                      }
                      {/*<div id={`InfoAdicional-${oportunities.IDDatOpportunity}`} className='hidden' style={{ padding: '21px', color: '#07210A' }}>
                        <p style={{ fontSize: '14px' }} className="mb-3">
                          {secondary === 1
                            ? `Al momento de terminar tu solicitud ${oportunities.BusinessAbr} te enviará un mensaje de texto o un email para confirmar el estatus de tu aplicación. Mantente al pendiente`
                            : aprobada
                              ? null
                              : 'Las razones para que esto ocurriera pueden ser varias, pero no te desanimes, en Coru tenemos otras opciones para ti.'
                          }
                        </p>
                        {secondary === 0
                          ? aprobada ? null
                            : <CardsBtn
                              type="button"
                              min_w="100%"
                              min_web="100%"
                              mtopw="10px"
                              mtop="0px"
                              mtopmd="0px"
                              onClick={() => setGoTrainingRazones(true)}
                            >
                              <b> ¿Por qué no me dán una tarjeta?</b>
                            </CardsBtn>
                          : null
                        }
                        <div id={`BtnOcultar-${oportunities.IDDatOpportunity}`}>
                          <TxtDetalle onClick={(e) => viewMore(e, oportunities.IDDatOpportunity, 'Ocultar')}
                            cursor="pointer">
                            <Image src={upArrow} width="70px" marginRight="4px"
                              marginRightM="3px" height="9px" width="14px" />
                            Mostrar menos
                          </TxtDetalle>
                        </div>
                      </div>*/}
                      {aprobada
                        ? null
                        : <div id={`BtnVer-${oportunities.IDDatOpportunity}`}>
                          <TxtDetalle onClick={(e) => viewMore(e, oportunities.IDDatOpportunity, 'Ver')}
                            cursor="pointer">
                            <Image src={downArrow} marginRight="4px"
                              marginRightM="3px" height="9px" width="14px" />
                            Ver detalle
                          </TxtDetalle>
                        </div>
                      }
                    </ContainerRequest>

                    {oportunities.IDDatOpportunity === verinfo
                      ? showRelated
                        ? <RelacionadosRechazo
                          id={oportunities.DatOpportunity_IDDatOpportunity
                          }
                          idProd={oportunities.IDProduct}
                        />
                        : ''

                      : null
                    }

                  </Fragment>

                ))

                : <div>
                  <Fragment>
                    <SinSolicitud />
                  </Fragment>
                </div>

          }

        </Container>
      </Main>

      {!Novisible ? (<FooterFeed check='logros' />) : (<div></div>)}
    </Fragment>
  );
}

const SinSolicitud = () => (
    <ContEntrenamientos>
      <ContEntrenamiento
        id='101'
        background={'#A370FF'}
        widthM="100%"
        widthD="95%"
      >
        <Link
          to='/wo/card-funnel'
        >
          <ContentTimer
            height=' '
            maxheightMobile='130px'
            heightMobile='140px'
            maxheight='107.250px'
            display='flex'
            top='0px'
            width='100%'
            position=' '
          >
            <br />
            <TitleEntrenamiento
              className="roboto"
              margin="auto"
              top=" "
              widthD="230px"
            >
              Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.
            </TitleEntrenamiento>
            <ImageH src='https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CWO_CC%20%281%29.svg' width="25%" margin="auto" />
          </ContentTimer>
          <ContentTimer>
            <Image
              src={Clock}
              width='20px'
              left='0px'
              position=' '
              top='1%'
            />
            <TextTime className='roboto'>
              Tiempo de entrenamiento: 5 minutos
            </TextTime>
          </ContentTimer>
        </Link>
      </ContEntrenamiento>
    </ContEntrenamientos>
);

export default Resquest;
