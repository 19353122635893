import React from 'react';
import {
  ModalBackground,
  ModalBackCC,
  ModalHeader,
  ModalBody,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle24,
  Title1,
} from './Home.styled';
import {
  ImageSections,
  DivStyle,
  CardsBtn,
  CardsTxt,
} from '../../assets/styles/Training.styled';
import Cartera from '../../assets/img/logros/Cartera.svg';
import ImageCoachInicio from '../Training/ImageCoachInicio';

const ModalAgregarCartera = ({
  selected, modalAgregar, setModalAgregar, setSelectedComplete,
}) => {
  const card = JSON.parse(selected);
  const name = sessionStorage.getItem('FirstName');

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const eliminarProducto = (card, id) => {
    const walletStorage = sessionStorage.getItem('walletArr');
    if (walletStorage) {
      const walletStorageJson = JSON.parse(walletStorage);
      const newValues = walletStorageJson.filter((crd) => crd.IDProduct !== id);
      sessionStorage.setItem('walletArr', JSON.stringify(newValues));
      setSelectedComplete(newValues);
    }

    document.getElementsByClassName(`car-${id}`)[0].src = Cartera;

    setModalAgregar(false);
  };

  return (
    <>
      {modalAgregar ? (
        <ModalBackground display={'true'}>
          <ModalBackCC>
            <Rectangle23
              position="absolute"
              background="#91D770"
              Mleft="-30px"
              Mtop="-30px"
              width="60px"
              height="60px"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="90px"
              Mtop="-9px"
              width="28px"
              height="32px"
              transform="rotate(45deg)"
              left="58px"
              top="-27px"
            />
            <Rectangle23
              position="absolute"
              background="#6BD5AF"
              Mleft="190px"
              Mtop="-30px"
              width="62.36px"
              height="67px"
              transform="rotate(45deg)"
              left="171px"
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="280px"
              Mtop="40px"
              width="42.53px"
              height="45.22px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="-60px"
              Mtop="140px"
              width="60px"
              height="60px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle24
              positionD="absolute"
              radius="50px"
              width="145px"
              left="266px"
              top="118px"
              widthM="145px"
              heightM=""
              mLeftM=" "
              mTopM=" "
            />
            <div
              style={{
                position: 'absolute',
                right: '20px',
                top: '10px',
                fontSize: '18px',
                cursor: 'pointer',
              }}
              onClick={() => setModalAgregar(false)}
            >
              X
            </div>
            <ModalHeader>
              <ImageCoachInicio
                classAna={'trueAna'}
                topMobile="27px"
                modal={true}
              />
              <Title1
                size="23px"
                aling="center"
                mt=" "
                sizeM="18px"
                mleftM=" "
                mtopM=" "
                heightM=" "
              >
                Agregado a tu cartera
              </Title1>
            </ModalHeader>
            <ModalBody>
              <span style={{ padding: '12px 5px' }}>
                Hola {name}, hemos añadido a tu cartera:
              </span>
              <div
                key={card.IDProduct}
                className="d-flex flex-column"
                style={{
                  minWidth: '280px',
                  minHeight: '100px',
                  maxHeight: '115px',
                  height: 'auto',
                  padding: '0 15px',
                  margin: '15px 0 0 0',
                  boxShadow: '0px 4px 16px 0px rgb(0 0 0 / 20%)',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '60px',
                    }}
                  >
                    <ImageSections
                      pt={card.Name.includes('RappiCard') ? '0' : '12px'}
                      width="70px"
                      hmob="max-content"
                      mhw="60px"
                      mhm="60px"
                      ml="0"
                      heightweb="max-content"
                      src={card.ImageURL}
                      className="img-fluid"
                    />
                    <div style={{ textAlign: 'right', paddingTop: '12px' }}>
                      <CardsTxt className="roboto" size="12px">
                        Anualidad
                      </CardsTxt>
                      {JSON.parse(card.productDetail.response).map(
                        (detalle) => {
                          if (detalle.Name === 'Anualidad') {
                            return (
                              <CardsTxt
                                key={detalle.Description}
                                className="cabin"
                                weight="600"
                                size="16px"
                              >
                                {detalle.Description.includes('USD')
                                  ? detalle.Description
                                  : (detalle.Description.includes('MXN') ? `$${detalle.Description}`
                                    : `${formatter.format(detalle.Description)}MXN`)}
                              </CardsTxt>
                            );
                          }
                        },
                      )}
                    </div>
                  </div>
                  <DivStyle
                    className="cabin"
                    mh="60px"
                    mxh="60px"
                    weight="600"
                    align="left"
                    size="16px"
                    line="1.5em"
                    padd="5px 0"
                    color="#07210A"
                  >
                    {card.Name}
                  </DivStyle>
                </div>
              </div>
              <CardsBtn
                mtopmd="20px"
                mtop="20px"
                mtopw="20px"
                min_w="100%"
                min_web="100%"
                onClick={() => setModalAgregar(false)}
              >
                Seguir agregando tarjetas
              </CardsBtn>
              <CardsBtn
                color="#EC407A !important"
                back="#fff !important"
                border="solid 1px !important"
                mtopmd="20px"
                mtop="20px"
                mtopw="20px"
                min_w="100%"
                min_web="100%"
                onClick={() => eliminarProducto(card, card.IDProduct)}
              >
                Eliminar de mi cartera
              </CardsBtn>
            </ModalBody>
          </ModalBackCC>
        </ModalBackground>
      ) : null}
    </>
  );
};

export default ModalAgregarCartera;
