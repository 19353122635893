/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Carousel from 'react-elastic-carousel';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  TrainingSectionFlex,
  TrainingSubtitles,
  CardsTxt,
} from '../../assets/styles/Training.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import {
  Container, Col7, Col12, Image,
} from '../../components/common/common.styled';
import arrow from '../../assets/img/anacoach/Training/arr.svg';
import arrowUp from '../../assets/img/anacoach/Training/arrUp.svg';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER, GET_SUPER_SEGUROS } from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import RecommendedPlans from '../../components/Training/RecommendedPlans';

const SuperSeguros = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  currentRoutine,
  nextOrderRoutine,
}) => {
  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const [Rutine, setRutine] = useState({});
  const [countRecovery] = useState(0);

  // states respuestas
  const [gender, setGender] = useState('');
  const [insurangeLife, setInsurangeLife] = useState('');
  const [dependentPersons, setDependentPersons] = useState('');
  const [hasInsurance, setHasInsurance] = useState('');
  const [smoke, setSmoke] = useState('');
  const [cigarettesADay, setCigarettesADay] = useState('');
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const [timeWithoutSmoking, setTimeWithoutSmoking] = useState('');
  const [basicProducts, setBasicProducts] = useState([]);
  const [premiumProducts, setPremiumProducts] = useState([]);
  const [premiumProductsAux, setPremiumProductsAux] = useState([]);
  const [showProducts, setShowProducts] = useState(false);
  const [aniosSeguro, setAniosSeguro] = useState(5);
  const [filterAnios, setFilterAnios] = useState([]);
  const [filterSumAsegurada, setFilterSumAsegurada] = useState([]);
  const [showSeguro, setShowSeguro] = useState(false);
  const [sumaAseg, setSumaAseg] = useState(0);
  const [showSuma, setShowSuma] = useState(false);

  const [form, setForm] = useState({
    Cigarros_dia: '',
  });
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }
              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                break;
              } else {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getSuperSeguros] = useLazyQuery(GET_SUPER_SEGUROS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getSuperSeguros }) {
      if (getSuperSeguros) {
        const response = JSON.parse(getSuperSeguros.response);
        if (response) {
          const { data } = response;
          const { return_of_premium, term } = data.quotes;
          console.log('response APi', JSON.stringify(data));
          const validAniosPrem = [];
          const validSumAsPrem = [];
          setBasicProducts(term);
          setFilterSumAsegurada(
            return_of_premium.filter((item) => {
              if (!validSumAsPrem.includes(item.sum_insured)) {
                validSumAsPrem.push(item.sum_insured);
                return item;
              }
            }),
          );
          setFilterAnios(
            return_of_premium.filter((item) => {
              if (!validAniosPrem.includes(item.term_years)) {
                validAniosPrem.push(item.term_years);
                return item.term_years;
              }
            }),
          );
          console.log(monthlyIncome);
          if(monthlyIncome !== "Menos de $4,000"){
            setPremiumProducts(return_of_premium);
            setPremiumProductsAux(return_of_premium);  
          }
          setShowProducts(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (monthlyIncome !== '') {
      let validSmoke = '';
      if (smoke === 'Si') {
        validSmoke = parseInt(cigarettesADay) > 40 ? 'high' : 'low';
      } else {
        validSmoke = 'none';
      }
      const inputSS = {
        input: {
          date_of_birth: sessionStorage.getItem('Birthdate'),
          sex_at_birth: gender === 'Masculino' ? 'male' : 'female',
          smoking_frequency: validSmoke,
          salary: 0,
          monthly_income: monthlyIncome,
        },
      };
      console.log(inputSS);
      getSuperSeguros({
        variables: inputSS,
      });
    }
  }, [monthlyIncome]);

  useEffect(() => {
    if (aniosSeguro !== 0) {
      setPremiumProducts(
        premiumProductsAux.filter(
          ({ term_years }) => term_years === aniosSeguro,
        ),
      );
    }
    const validSumAsPrem = [];
    if (sumaAseg !== 0) {
      setFilterSumAsegurada(
        premiumProductsAux.filter((item) => {
          if (
            !validSumAsPrem.includes(item.sum_insured)
            && item.term_years === aniosSeguro
          ) {
            validSumAsPrem.push(item.sum_insured);
            return item;
          }
        }),
      );
    }
  }, [aniosSeguro]);

  useEffect(() => {
    if (sumaAseg !== 0 && aniosSeguro !== 0) {
      setPremiumProducts(
        premiumProductsAux.filter(
          ({ sum_insured, term_years }) => term_years === aniosSeguro && sum_insured === sumaAseg,
        ),
      );
    } else if (aniosSeguro === 0) {
      setPremiumProducts(
        premiumProductsAux.filter(({ sum_insured }) => sum_insured === sumaAseg),
      );
    }
  }, [sumaAseg]);

  const abrirNuevoTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setTimeout(() => setRecoveryRoutine('inactiva'), 11000);
    }
  }, []);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const handleChangeRutine = (data) => {
    setRutine(data);
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    if (e.target.name === 'Cigarros_dia') {
      validCigarros(e.target.name, e.target.value, e.target.id);
    }
  };
  const validCigarros = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const num = document.getElementById(id);
    if (num === null) {
      console.log('null');
    } else if (value.length === 0 || (name && !/^[0-9]+$/i.test(value))) {
      num.className = 'textInput inputs-bottom invalidInput';
    } else {
      num.className = 'textInput inputs-bottom validInput';
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setGender(val.value);
        break;
      case 1:
        setInsurangeLife(val.value);
        break;
      case 2:
        setDependentPersons(val.value);
        break;
      case 3:
        setHasInsurance(val.value);
        break;
      case 4:
        setSmoke(val.value);
        break;
      case 5:
        setCigarettesADay(val.value);
        break;
      case 6:
        setTimeWithoutSmoking(val.value);
        break;
      case 7:
        setMonthlyIncome(val.value);
        break;
      default:
        console.log(val);
    }
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && document.getElementById('activeExpenseCat') !== null
        && document.getElementById('activeExpenseCat') !== undefined
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeExpenseCat' });
        setReviewScroll(false);
      }
    }, 14000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      <div id="activeExpenseCat"></div>
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion className={gender === '' ? '' : 'hidden'}>
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="0-0"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <CardTwo
        classCard={gender !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={gender}
        colorTraining={trainingColor}
      />

      {/*-----*/}
      <QuestionsOrder
        classCard={gender !== '' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />

      <TrainingQuestion
        className={gender !== '' && insurangeLife === '' ? '' : 'hidden'}
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>


      <CardTwo
        classCard={insurangeLife !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={insurangeLife}
        colorTraining={trainingColor}
      />
      {/*-----*/}

      {/*-----*/}
      <QuestionsOrder
        classCard={insurangeLife !== '' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={2}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />

      <TrainingQuestion
        className={insurangeLife !== '' && dependentPersons === '' ? '' : 'hidden'}
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>


      <CardTwo
        classCard={dependentPersons !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={dependentPersons}
        colorTraining={trainingColor}
      />
      {/*-----*/}

      {/*-----*/}
      <QuestionsOrder
        classCard={dependentPersons !== '' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={3}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />

      <TrainingQuestion
        className={dependentPersons !== '' && hasInsurance === '' ? '' : 'hidden'}
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="3-3"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>


      <CardTwo
        classCard={hasInsurance !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={hasInsurance}
        colorTraining={trainingColor}
      />
      {/*-----*/}

      <QuestionsOrder
        classCard={hasInsurance !== '' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={4}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion
        className={hasInsurance !== '' && smoke === '' ? '' : 'hidden'}
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="4-4"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <CardTwo
        classCard={smoke !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={smoke}
        colorTraining={trainingColor}
      />

      <QuestionsOrder
        classCard={smoke === 'Si' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={5}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      {smoke === 'Si' && cigarettesADay === '' ? (
        <>
          <TrainingQuestion
            className={smoke === 'Si' && cigarettesADay === '' ? '' : 'hidden'}
          >
            <QuestionsFlex display="inline">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="5-5"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            </QuestionsFlex>
          </TrainingQuestion>
        </>
      ) : null}
      <CardTwo
        classCard={cigarettesADay !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={cigarettesADay}
        colorTraining={trainingColor}
      />
      {/* ################ */}
      <QuestionsOrder
        classCard={smoke === 'Dejé de fumar' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={6}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion
        className={
          smoke === 'Dejé de fumar' && timeWithoutSmoking === '' ? '' : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="6-6"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <CardTwo
        classCard={timeWithoutSmoking !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={timeWithoutSmoking}
        colorTraining={trainingColor}
      />
      {/* ################ */}

      <QuestionsOrder
        classCard={
          (cigarettesADay === '' && smoke === 'No')
          || (cigarettesADay !== '' && smoke === 'Si')
          || timeWithoutSmoking !== ''
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={7}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion
        className={
          ((cigarettesADay === '' && smoke === 'No')
            || (cigarettesADay !== '' && smoke === 'Si')
            || timeWithoutSmoking !== '')
          && monthlyIncome === ''
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="7-7"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <CardTwo
        classCard={monthlyIncome !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={monthlyIncome}
        colorTraining={trainingColor}
      />

      {showProducts && (
        <Fragment>
          <TrainingSectionFlex>
            <Col12
              id="CarruselEmpresasContentHome"
              pt="20px"
              flex="0 0 100%"
              maxwidth="100%"
              display="flex"
            >
              <div style={{ width: '60%', margin: 'auto' }}>
                <TrainingSubtitles
                  margin="20px 0 0 0"
                  alignweb="left"
                  sizew="24px"
                >
                  {' '}
                  Plan básico:
                </TrainingSubtitles>
              </div>
              <Carousel
                id="CarruselMetasLogros"
                styled={{ background: '#FAFAFA' }}
                transitionMs={1000}
              >
                {basicProducts.map(({ issuance_fee, premium, sum_insured }) => (
                  <RecommendedPlans
                    plan="basico"
                    issuance_fee={issuance_fee}
                    premium={premium}
                    sum_insured={sum_insured}
                    url="https://app.super.mx/vida/inicio?utm_source=coru&utm_medium=basico&utm_campaign=superseguro_0621"
                    abrirNuevoTab={abrirNuevoTab}
                  />
                ))}
              </Carousel>
            </Col12>
          </TrainingSectionFlex>
          {premiumProducts.length > 0 ? (
            <TrainingSectionFlex>
              <Col12
                id="CarruselEmpresasContentHome"
                pt="20px"
                flex="0 0 100%"
                maxwidth="100%"
                display="flex"
              >
                <div style={{ width: '60%', margin: 'auto' }}>
                  <TrainingSubtitles alignweb="left" sizew="24px">
                    {' '}
                    Plan premium:
                  </TrainingSubtitles>
                  <CardsTxt
                    className="roboto"
                    mt="20px"
                    size="14px"
                    style={{ display: 'flex' }}
                  >
                    Elige a cuantos años quieres tu seguro:{' '}
                    <div
                      style={{ marginLeft: '10px', fontSize: '14px' }}
                      onClick={() => setShowSeguro(!showSeguro)}
                    >
                      <span style={{ color: '#F64282', fontWeight: '600' }}>
                        {aniosSeguro} años
                        <Image
                          className="cardImage-1"
                          src={showSeguro ? arrowUp : arrow}
                          width="12px"
                          marginLeft="8px"
                          marginLeftM="8px"
                        ></Image>
                      </span>
                    </div>
                  </CardsTxt>
                  <CardsTxt
                    className="roboto"
                    mt="20px"
                    size="14px"
                    style={{ display: 'flex' }}
                  >
                    Suma Asegurada:{' '}
                    <div
                      style={{ marginLeft: '10px', fontSize: '14px' }}
                      onClick={() => setShowSuma(!showSuma)}
                    >
                      <span style={{ color: '#F64282', fontWeight: '600' }}>
                        {formatter.format(filterFloat(sumaAseg))}
                        <Image
                          className="cardImage-1"
                          src={showSuma ? arrowUp : arrow}
                          width="12px"
                          marginLeft="8px"
                          marginLeftM="8px"
                        ></Image>
                      </span>
                    </div>
                  </CardsTxt>
                </div>
                <div
                  onClick={() => setShowSeguro(!showSeguro)}
                  style={{ marginTop: '20px', width: '100%' }}
                  id="card-2"
                  className={showSeguro ? '' : 'hidden'}
                >
                  {filterAnios.map(({ term_years }, index) => (
                    <div
                      key={term_years}
                      style={{
                        color: '#F64282',
                        backgroundColor: '#FFFFFF',
                        fontSize: '14px',
                        cursor: 'pointer',
                        border: 'solid 1px #cdcdcd',
                        padding: '3px',
                        width: '30%',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                      onClick={() => setAniosSeguro(term_years)}
                    >
                      {term_years} años
                    </div>
                  ))}
                </div>
                <div
                  onClick={() => setShowSuma(!showSuma)}
                  style={{ marginTop: '20px', width: '100%' }}
                  id="card-2"
                  className={showSuma ? '' : 'hidden'}
                >
                  {filterSumAsegurada.map(({ sum_insured }, index) => (
                    <div
                      key={sum_insured}
                      style={{
                        color: '#F64282',
                        fontSize: '14px',
                        cursor: 'pointer',
                        border: 'solid 1px #cdcdcd',
                        padding: '3px',
                        width: '30%',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                      onClick={() => setSumaAseg(sum_insured)}
                    >
                      {formatter.format(filterFloat(sum_insured))}
                    </div>
                  ))}
                </div>
                <Carousel
                  id="CarruselMetasLogros"
                  styled={{ background: '#FAFAFA' }}
                  transitionMs={1000}
                >
                  {premiumProducts.map(
                    ({
                      issuance_fee, premium, sum_insured, refund,
                    }) => (
                      <RecommendedPlans
                        plan="premium"
                        issuance_fee={issuance_fee}
                        premium={premium}
                        sum_insured={sum_insured}
                        refund={refund}
                        url="https://app.super.mx/vida/inicio?utm_source=coru&utm_medium=premium&utm_campaign=superseguro_0621"
                        abrirNuevoTab={abrirNuevoTab}
                      />
                    ),
                  )}
                </Carousel>
              </Col12>
            </TrainingSectionFlex>
          ) : null}
        </Fragment>
      )}
    </div>
  );
};

export default SuperSeguros;
