import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';
import {
  Container, Col12, ParagrapTwo, Button,
} from '../common/common.styled';

function NoTraining({ tipo }) {
  const history = useHistory();
  const handleOpenFeed = () => {
    history.push('/the-training-feed/');
  };

  return (
        <Fragment>
            <Container width='80%' mt='1rem' widthMobile='100%' mtMobile='2rem'float="auto!important" >
                <ContainerPerfiles padding="4%" height="auto" minheight="122px" display="flex" mb="5%" background="#fff">
                    <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto">
                        <Col12 ta="center" pt="0px" >
                            <ParagrapTwo mt="0px" fontSize="40px" color="#F64282" fontSizeWeb="40px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>0</ParagrapTwo>
                        </Col12>
                        <Col12 ta="center" pt="0px" >
                            <ParagrapTwo mt="0px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>Entrenamientos</ParagrapTwo>
                        </Col12>
                    </Container>
                </ContainerPerfiles>
                <ContainerPerfiles padding="4%" height="auto" minheight="auto" display="flex" mb="5%" background="#A6A6A6">
                    <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto">
                        <Col12 ta="center" pt="0px" >
                            {tipo === 'concluidos'
                              ? <ParagrapTwo mt="0px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>Aún no has completado ningún entrenamiento. <br/>¡Comienza a entrenar!</ParagrapTwo>
                              : <ParagrapTwo mt="0px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>No tienes nungun entremamiento pendiente<br/>¡Comienza a entrenar!</ParagrapTwo>
                            }
                        </Col12>
                    </Container>
                </ContainerPerfiles>
                <Button className='mt-5 mt-md-4 mb-5 roboto' onClick={handleOpenFeed}>
                    Ir a Entrenamientos
                </Button>
            </Container>
        </Fragment>
  );
}
export default NoTraining;
