import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  weekDay,
  getCurrentDate,
} from '../../Helpers/general';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import SonData from '../Routines/SonData';
import SonHomework from '../Routines/SonHomework';
import {
  GET_RECOMMENDATION_TRAININGS,
  DYNAMIC_INSERT,
  DYNAMIC_GET,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GET_TRAINING_COLOR,
  RECOVERY_USERS_CHILDREN,
} from '../../queries';
import Homeworks from '../Routines/Homeworks';
import TaskLog from '../Routines/TaskLog';
import HomeworkSchedule from '../Routines/HomeworkSchedule';
import DailyTaskLog from '../../components/Training/RegistroDiarioTareas';
import WeeklySummary from '../../components/Training/ResumenSemanal';
import SummaryCompletedTasks from '../../components/Training/SummaryCompletedTasks';
import ActiveRewards from '../../components/Training/ActiveRewards';
import SavingAndHomeworks from '../../components/Training/SavingAndHomeworks';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationValueOfMoney from '../../components/common/SendNotificationValueOfMoney';
import ModalValorDinero from '../../components/common/ModalValorDinero';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';

let datos = [];
let totalReward = 0;
let name = '';

const RoutinesOrderWOValueOfMoney = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  showExitHomeworkSchedule,
  setShowExitHomeworkSchedule,
  showDailyTaskLog,
  setShowDailyTaskLog,
  completedTasks,
  setCompletedTasks,
  showSummaryCompletedTasks,
  setShowSummaryCompletedTasks,
  showActiveRewards,
  setShowActiveRewards,
  showSavingAndHw,
  setShowSavingAndHw,
  showWindow2,
  setShowWindow2,
  showTaskLog,
  setShowTaskLog,
}) => {
  const [datHwChildren, setDatHwChildren] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [saving, setSaving] = useState('');
  const [currentSaving, setCurrentSaving] = useState(0);
  const [comment, setComment] = useState({});
  const [newRewards, setNewRewards] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState({
    title: '',
    text: '',
  });

  const { actions } = ClickButton();
  const { sendMessage } = SendNotificationValueOfMoney();
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [recoveryUsersChildren] = useLazyQuery(RECOVERY_USERS_CHILDREN, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ recoveryUsersChildren }) {
      console.log('3 - >>> ', recoveryUsersChildren);
      if (recoveryUsersChildren !== null) {
        console.log('4 A - >>> ', recoveryUsersChildren);
        if (recoveryUsersChildren.message === 'success') {
          console.log('5 A - >>> ', recoveryUsersChildren.response);
          const resp = JSON.parse(recoveryUsersChildren.response);
          console.log('response == >>> ', resp);
          sessionStorage.setItem(
            'idUsersChildren',
            resp[0].childData.homeworks[0].idCatUsersChildren,
          );
          setDatHwChildren(resp);
          setOrderRutine('Lista de tareas');

          const currentDate = getCurrentDate();
          const fullDate = weekDay(currentDate);
          // let fullDate = "domingo"
          console.log('response == ', fullDate);

          const params = new URLSearchParams(window.location.search);
          const idUser = params.get('idUser') !== null ? params.get('idUser') : '';
          const rewards = params.get('rewards') !== null ? params.get('rewards') : '';

          if (idUser !== '') {
            name = resp[0].childData.name;
            if (rewards === 'active') {
              const task = resp[0].childData.homeworks;
              handleActiveRewards(task);
            } else if (fullDate !== 'domingo') {
              setShowTaskLog(true);
              const task = resp[0].childData.homeworks;
              let completeTasks = 0;
              let countTasks = 0;
              for (let i = 0; i < task.length; i++) {
                const { days } = task[i];
                for (let day = 0; day < days.length; day++) {
                  countTasks++;
                  if (task[i].Status[day] === true) {
                    completeTasks++;
                  }
                }
              }
              if (completeTasks > 0) setShowWindow2(true);
            } else if (fullDate === 'domingo') {
              const task = resp[0].childData.homeworks;
              handleTaskCompleteChidlren(task);
            }
          } else if (fullDate === 'domingo') {
            const task = resp[0].childData.homeworks;
            name = resp[0].childData.name;
            handleTaskCompleteParents(task);
          } else {
            setShowDailyTaskLog(true);
            const task = resp[0].childData.homeworks;
            name = resp[0].childData.name;
            handleDailyTaskLog(task);
          }
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('response == ', dynamicInsert.response);
        const requestR = sessionStorage.getItem('requestRewards')
          ? sessionStorage.getItem('requestRewards')
          : '';
        if (requestR !== '') {
          const idDatHomeworks = JSON.parse(
            sessionStorage.getItem('requestRewards_idDatHw'),
          );
          for (let i = 0; i < idDatHomeworks.length; i++) {
            dynamicUpdate({
              variables: {
                input: {
                  table: 'DatHomework',
                  columns: [
                    {
                      setkey: 'RewardStatus',
                      setval: 'recompensa solicitada',
                    },
                  ],
                  conditions: [
                    {
                      valone: 'idDatHomework',
                      valtwo: String(idDatHomeworks[i].split('_')[1]),
                      condition: '=',
                      logic: '',
                    },
                  ],
                },
              },
            });
          }
        }
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('response == ', dynamicUpdate);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getSavingChildren] = useMutation(DYNAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const respAmount = JSON.parse(dynamicGet.response);
        if (respAmount.length > 0) {
          let aux = 0;
          for (let amount = 0; amount < respAmount.length; amount++) {
            aux += parseFloat(respAmount[amount].amount);
          }
          setCurrentSaving(aux);
        } else {
          setCurrentSaving(0);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleTaskCompleteChidlren = (task) => {
    if (task.length > 0) {
      let completeTasks = 0;
      let countTasks = 0;
      for (let i = 0; i < task.length; i++) {
        const { days } = task[i];
        for (let day = 0; day < days.length; day++) {
          countTasks++;
          if (task[i].Status[day] === true) {
            completeTasks++;
          }
        }
        datos.push({
          taskName: `${i + 1}. ${task[i].name}`,
          taskMoney: `$${task[i].reward}`,
          taskInfo:
            completeTasks === countTasks
              ? 'Felicidades puedes pedir tu recompensa'
              : `Solo cumpliste con ${completeTasks} días de ${countTasks}`,
          taskStatus: completeTasks === countTasks ? 'Complete' : 'Incomplete',
          idDatHomework: task[i].idDatHomework,
          comment: task[i].comment !== '' ? task[i].comment : '',
        });
        if (completeTasks === countTasks) {
          totalReward += parseFloat(task[i].reward);
        }
        completeTasks = 0;
        countTasks = 0;
      }
      console.log(
        'response == Tareas completatadas:',
        `${completeTasks}/${countTasks}`,
      );
      console.log('response ==  handleTaskCompleteChidlren', datos);
      setCompletedTasks(true);
      setShowWindow2(true);
    }
  };

  const handleTaskCompleteParents = (task) => {
    if (task.length > 0) {
      let completeTasks = 0;
      let countTasks = 0;
      for (let i = 0; i < task.length; i++) {
        const { days } = task[i];
        for (let day = 0; day < days.length; day++) {
          countTasks++;
          if (task[i].Status[day] === true) {
            completeTasks++;
          }
        }
        const jsonStatus = {
          taskName: `${i + 1}. ${task[i].name}`,
          idDatHomework: task[i].idDatHomework,
        };

        if (completeTasks === countTasks) {
          jsonStatus.taskTime = 'Cumplió con esta tarea al 100%';
          jsonStatus.taskMoney = `$${task[i].reward}`;
        } else if (completeTasks === 0) {
          jsonStatus.taskTime = `Programada ${days} - ${task[i].time}`;
          jsonStatus.taskMoney = `$${task[i].reward}`;
        } else {
          jsonStatus.taskTime = `Se cumplió con ${completeTasks} de ${countTasks} dias asignados`;
          jsonStatus.taskMoney = '$0';
          jsonStatus.taskStatus = 'Incomplete';
          jsonStatus.comment = task[i].comment;
        }

        datos.push(jsonStatus);
        completeTasks = 0;
        countTasks = 0;
      }
      setShowSummaryCompletedTasks(true);
      console.log('Data == ', datos);
    }
    setShowExitHomeworkSchedule(true);
  };

  const handleDailyTaskLog = (task) => {
    if (task.length) {
      for (let i = 0; i < task.length; i++) {
        const { days } = task[i];
        for (let day = 0; day < days.length; day++) {
          datos.push({
            taskName: `${i + 1}. ${task[i].name}`,
            taskTime: `Programada ${days[day]} - ${task[i].time}`,
            taskInfo:
              task[i].Status[day] === true
                ? `Recompensa $${task[i].reward}`
                : 'No se registró avance al día de hoy',
            taskStatus:
              task[i].Status[day] === true ? 'Complete' : 'Incomplete',
          });
        }
      }
      console.log('response == Data == ', datos);
    }
    setShowExitHomeworkSchedule(true);
  };

  const handleActiveRewards = (task) => {
    let completeTasks = 0;
    let countTasks = 0;

    for (let i = 0; i < task.length; i++) {
      const { days } = task[i];
      for (let day = 0; day < days.length; day++) {
        countTasks++;
        if (task[i].Status[day] === true) {
          completeTasks++;
        }
      }
      if (completeTasks === countTasks) {
        datos.push({
          taskName: `${i + 1}. ${task[i].name}`,
          taskMoney: `$${task[i].reward}`,
        });
        totalReward += parseFloat(task[i].reward);
      }
    }
    setShowActiveRewards(true);
  };
  const registerSaving = (e, typeSaving, savings = '') => {
    e.preventDefault();

    const findClassElem = document.getElementById('quieroAhorrar').classList.value;

    if (typeSaving === 'Si' && !findClassElem.includes('disabledAhorro')) {
      document.getElementById('quieroAhorrar').className += ' disabledAhorro';
      const click = `Si_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      const save = parseFloat(savings) / 2;
      dynamicInsert({
        variables: {
          input: {
            table: 'DatChildrenSavings',
            columns: ['amount', 'CatUsersChildren_idCatUsersChildren'],
            detailValues: {
              values: [
                String(save),
                String(sessionStorage.getItem('idUsersChildren')),
              ],
            },
          },
        },
      });
    } else if (typeSaving === 'otra_cantidad') {
      const click = `GuardarAhorro_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      dynamicInsert({
        variables: {
          input: {
            table: 'DatChildrenSavings',
            columns: ['amount', 'CatUsersChildren_idCatUsersChildren'],
            detailValues: {
              values: [
                String(saving),
                String(sessionStorage.getItem('idUsersChildren')),
              ],
            },
          },
        },
      });
    }
  };

  const requestRewards = (e) => {
    e.preventDefault();
    const listComments = Object.values(comment);
    const keysComments = Object.keys(comment);
    sessionStorage.setItem('requestRewards', 'true');
    sessionStorage.setItem(
      'requestRewards_idDatHw',
      JSON.stringify(keysComments),
    );
    if (listComments.length > 0) {
      for (let i = 0; i < listComments.length; i++) {
        dynamicInsert({
          variables: {
            input: {
              table: 'DatHomeworkMessage',
              columns: ['DatHomework_idDatHomework', 'Message'],
              detailValues: {
                values: [keysComments[i].split('_')[1], listComments[i]],
              },
            },
          },
        });
        console.log(
          'response == comentarios DatHomework_idDatHomework: ',
          `${keysComments[i].split('_')[1]} comentario: ${listComments[i]}`,
        );
      }
    } else {
      const hw = datHwChildren[0].childData.homeworks;
      const idDatHomeworks = hw.map((homework) => homework.idDatHomework);
      console.log('response == ', idDatHomeworks);
      for (let i = 0; i < idDatHomeworks.length; i++) {
        dynamicUpdate({
          variables: {
            input: {
              table: 'DatHomework',
              columns: [
                {
                  setkey: 'RewardStatus',
                  setval: 'recompensa solicitada',
                },
              ],
              conditions: [
                {
                  valone: 'idDatHomework',
                  valtwo: String(idDatHomeworks[i]),
                  condition: '=',
                  logic: '',
                },
              ],
            },
          },
        });
      }
    }
    setShowModal(true);
    setTextModal({
      title: '¡El éxito depende del esfuerzo!',
      text: `Felicidades, ${name}, realizaste tus tareas semanales, ahora tus papás tienen que aprobar tu recompensa.`,
    });
  };

  const acceptTasks = () => {
    setShowModal(true);
    setTextModal({
      title: '¡Que nada te detenga!',
      text: `¡Perfecto, ${name}, recuerda que en el transcurso de la semana te recordaremos las tareas por cumplir!`,
    });
  };

  const confirmRewards = (e) => {
    e.preventDefault();
    const listRewards = Object.values(newRewards);
    const keysRewards = Object.keys(newRewards);

    if (listRewards.length > 0) {
      for (let i = 0; i < listRewards.length; i++) {
        dynamicUpdate({
          variables: {
            input: {
              table: 'DatHomework',
              columns: [
                {
                  setkey: 'Reward',
                  setval: listRewards[i],
                },
                {
                  setkey: 'RewardStatus',
                  setval: 'recompensa confirmada',
                },
              ],
              conditions: [
                {
                  valone: 'idDatHomework',
                  valtwo: String(keysRewards[i].split('_')[1]),
                  condition: '=',
                  logic: '',
                },
              ],
            },
          },
        });
      }
    } else {
      const hw = datHwChildren[0].childData.homeworks;
      const idDatHomeworks = hw.map((homework) => homework.idDatHomework);
      console.log('response == confirmRewards', idDatHomeworks);
      for (let i = 0; i < idDatHomeworks.length; i++) {
        dynamicUpdate({
          variables: {
            input: {
              table: 'DatHomework',
              columns: [
                {
                  setkey: 'RewardStatus',
                  setval: 'recompensa confirmada',
                },
              ],
              conditions: [
                {
                  valone: 'idDatHomework',
                  valtwo: String(idDatHomeworks[i]),
                  condition: '=',
                  logic: '',
                },
              ],
            },
          },
        });
      }
    }
    setShowModal(true);
    setTextModal({
      title: '¡La paciencia esta contigo!',
      text: `¡Listo, ${
        sessionStorage.getItem('FirstName')
          ? sessionStorage.getItem('FirstName')
          : 'Laura'
      }!
         Enviamos la confirmación a tu hijo.
         ¡Pronto aprenderá el valor del dinero!`,
    });
    let url = window.location.href;
    url += `?idUser=${sessionStorage.getItem('idUser')}&rewards=active`;
    sendMessage({
      url,
      cellPhone: sessionStorage.getItem('telefonoHijo'),
      trigger: 'recoleccion_recompesas_hijo',
      variables: {
        varNameChildren: 'Nombre_Hijo',
        varFatherName: '',
        varUrlWO: 'URL_WO',
      },
      nameFather: '',
      nameChildren: sessionStorage.getItem('nombreHijo'),
    });
  };

  const reminder = () => {
    setShowModal(true);
    setTextModal({
      title: 'La mejor manera de predecir el futuro es creándolo',
      text: `Perfecto ${
        sessionStorage.getItem('FirstName')
          ? sessionStorage.getItem('FirstName')
          : 'Laura'
      }, mañana recibirás un recordatorio para asignar nuevas tareas.`,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('response === handleChange', name, value);
    setSaving(value);
    if (String(name).includes('comment')) {
      setComment({
        ...comment,
        [name]: value,
      });
    }
    if (String(name).includes('newReward_')) {
      setNewRewards({
        ...newRewards,
        [name]: value,
      });
    }
  };
  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramIdUser = params.get('idUser') !== null ? params.get('idUser') : '';
    console.log('1 - >>> ', paramIdUser);
    const idUser = sessionStorage.getItem('idUser');
    if (paramIdUser !== '') {
      console.log('2 A - >>> ');
      recoveryUsersChildren({
        variables: {
          idUser: paramIdUser,
          idUsersChildren: '',
        },
      });
    } else if (idUser !== '') {
      console.log('2 B - >>> ');
      recoveryUsersChildren({
        variables: {
          idUser,
          idUsersChildren: '',
        },
      });
    }
  }, []);

  useEffect(() => {
    if (showSavingAndHw) {
      datos = [];
      setShowActiveRewards(false);
      const inputSaving = {
        input: {
          table: 'DatChildrenSavings',
          columns: ['amount'],
          conditions: [
            {
              valone: 'CatUsersChildren_idCatUsersChildren',
              valtwo: String(sessionStorage.getItem('idUsersChildren')),
              condition: '=',
            },
          ],
        },
      };
      getSavingChildren({ variables: inputSaving });
      totalReward = 0;
      const childData = datHwChildren[0].childData.homeworks;
      let completeTasks = 0;
      let countTasks = 0;
      let count = 0;
      for (let i = 0; i < childData.length; i++) {
        const { days } = childData[i];
        for (let day = 0; day < days.length; day++) {
          countTasks++;
          if (childData[i].Status[day] === true) {
            completeTasks++;
          }
        }
        if (completeTasks === countTasks) {
          count++;
          totalReward += parseFloat(childData[i].reward);
        }
      }
      datos.push(
        {
          taskName: 'Tareas realizadas',
          taskMoney: `${count} de ${childData.length}`,
        },
        {
          taskName: 'Recompensas obtenidas',
          taskMoney: `$${totalReward}`,
        },
      );
    }
  }, [showSavingAndHw]);

  return (
    <>
      <ModalValorDinero
        title={textModal.title}
        text={textModal.text}
        showed={showModal}
        setShowed={setShowModal}
      />
      {orderRutine >= 1 && datHwChildren.length === 0 ? (
        // <DatBasic
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   trainingColor={colorTraining}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        //   recoveryTimeout={true}
        // />
        <ModalLogin 
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 && datHwChildren.length === 0 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={2}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null}
      {/* {orderRutine >= 3 && datHwChildren.length === 0 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={3}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null} */}
      {orderRutine >= 4 && datHwChildren.length === 0 ? (
        <SonData
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={4}
          nextOrderRoutine={6}
        />
      ) : null}

      {orderRutine >= 6 && datHwChildren.length === 0 ? (
        <>
        
        <SonHomework
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={6}
          nextOrderRoutine={'Lista de tareas'}
        />
        </>
      ) : null}
      {orderRutine === 'Lista de tareas' && datHwChildren.length === 0 ? (
        <Homeworks
          createJsonInput={createJsonInput}
          trainingColor={colorTraining}
        />
      ) : null}

      {/* inicio se mostrara solo entre semana usuario hijo */}
      {showTaskLog && !showSchedule ? (
        <TaskLog
          infoChildren={datHwChildren}
          setShowSchedule={setShowSchedule}
          showWindow2={showWindow2}
          setShowWindow2={setShowWindow2}
        />
      ) : null}
      {showSchedule ? ( // datHwChildren.length > 0 &&
        <HomeworkSchedule
          infoChildren={datHwChildren}
          showExitHomeworkSchedule={showExitHomeworkSchedule}
          setShowExitHomeworkSchedule={setShowExitHomeworkSchedule}
          acceptTasks={acceptTasks}
        />
      ) : null}
      {/* fin se mostrara solo entre semana usuario hijo */}

      {/* inicio se mostrara solo entre semana usuario padre */}
      {showDailyTaskLog ? ( // datHwChildren.length > 0 &&
        <DailyTaskLog name={name} data={datos} />
      ) : null}
      {/* fin se mostrara solo entre semana usuario padre */}

      {/* inicio se mostrara solo domingos usuario hijo */}
      {completedTasks ? ( // datHwChildren.length > 0 &&
        <WeeklySummary
          data={datos}
          name={name}
          reward={totalReward}
          comment={comment}
          handleChange={handleChange}
          requestRewards={requestRewards}
        />
      ) : null}
      {/* fin se mostrara solo domingos usuario hijo */}

      {/* inicio se mostrara solo domingos usuario hijo */}
      {showSummaryCompletedTasks ? ( // datHwChildren.length > 0 &&
        <SummaryCompletedTasks
          name={name}
          data={datos}
          newRewards={newRewards}
          handleChange={handleChange}
          confirmRewards={confirmRewards}
          reminder={reminder}
        />
      ) : null}
      {showActiveRewards ? ( // datHwChildren.length > 0 &&
      <>
        <ActiveRewards
          data={datos}
          reward={totalReward}
          name={name}
          handleChange={handleChange}
          saving={saving}
          handleClick={registerSaving}
          setState={setShowSavingAndHw}
        />
        </>
      ) : null}
      {showSavingAndHw && datos.length > 0 ? ( // datHwChildren.length > 0 &&
        <SavingAndHomeworks
          data={datos}
          name={name}
          currentSaving={currentSaving}
        />
      ) : null}
    </>
  );
};
export default RoutinesOrderWOValueOfMoney;
