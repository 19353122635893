/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const Preocupaciones = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  activePossibleroutines = false,
  possibleRoutines = [],
  setRamaPreocupacion = '',
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editPreocup, setEditPreocup] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('caracteristicas', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [preocupaciones, setPreocupacion] = useState(
    sessionStorage.getItem('Preocupaciones') || '',
  );
  const [form] = useState({});
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 0:
        setEditPreocup(true);
        setPreocupacion(val.value);
        if (activePossibleroutines) {
          setRamaPreocupacion(val.value);
          if (val.value === 'Su salud') {
            setOrderRutine(possibleRoutines[0]);
          } else if (val.value === 'La inseguridad') {
            setOrderRutine(possibleRoutines[1]);
          } else if (val.value === '¿Qué pasaría con ellos si no estoy yo?') {
            setOrderRutine(possibleRoutines[2]);
          } else if (val.value === 'Su bienestar al salir de viaje') {
            setOrderRutine(possibleRoutines[3]);
          }
        } else {
          setOrderRutine(nextOrderRoutine);
        }

        break;
      default:
        console.log(val);
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setPreocupacion(sessionStorage.getItem('Preocupaciones') || '');
      if (activePossibleroutines) {
        setRamaPreocupacion(sessionStorage.getItem('Preocupaciones') || '');
        if (sessionStorage.getItem('Preocupaciones') === 'Su salud') {
          setOrderRutine(possibleRoutines[0]);
        } else if (
          sessionStorage.getItem('Preocupaciones') === 'La inseguridad'
        ) {
          setOrderRutine(possibleRoutines[1]);
        } else if (
          sessionStorage.getItem('Preocupaciones')
          === '¿Qué pasaría con ellos si no estoy yo?'
        ) {
          setOrderRutine(possibleRoutines[2]);
        } else if (
          sessionStorage.getItem('Preocupaciones')
          === 'Su bienestar al salir de viaje'
        ) {
          setOrderRutine(possibleRoutines[3]);
        }
      } else {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const handleChangeIcon = (json) => {
    setEditPreocup(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (preocupaciones === ''
          || (lapiz[0].routine === currentRoutine
            && lapiz[0].active === 1))
        && editPreocup !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activePreocupaciones' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activePreocupaciones"></div>
        <QuestionsOrder
          classCard="cardOne"
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (preocupaciones === ''
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1))
            && editPreocup !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            preocupaciones !== ''
            && (editPreocup === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={preocupaciones}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              preocupaciones !== ''
              && (orderRutine === 4
                || orderRutine === 7
                || orderRutine === 8
                || orderRutine === 9)
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
            }
          />
        </CardTwo>
      </div>
  );
};

export default Preocupaciones;
