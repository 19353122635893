import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Perfilamiento2_0 from '../Routines/Perfilamiento2_0';
import {
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';

const RoutinesOrderProfiling2_0 = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
  trainingColor,
}) => {
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [puntosDinero, setPuntosDinero] = useState(0);
  const [puntosRiesgo, setPuntosRiesgo] = useState(0);
  const [puntosFuturo, setPuntosFuturo] = useState(0);
  const history = useHistory();
  const handledPuntosDinero = (val) => {
    setPuntosDinero(puntosDinero + val);
  };

  const handledPuntosRiesgo = (val) => {
    setPuntosRiesgo(puntosRiesgo + val);
  };

  const handledPuntosFuturo = (val) => {
    setPuntosFuturo(puntosFuturo + val);
  };

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    if (puntosRiesgo >= 1 && puntosRiesgo <= 3) {
      /*
      setTitleLink_Riesgo('Poco riesgo');
      setTitleModalRiesgo('Nivel 1 de riesgo');
      setDescModalRiesgo(
        'No te gusta arriesgar nada y tus actividades comunes te permiten tomar el control de tus decisiones.',
      ); */
    } else if (puntosRiesgo >= 4 && puntosRiesgo <= 7) {
      /* setTitleLink_Riesgo('Riesgo');
      setTitleModalRiesgo('Nivel 2 de riesgo');
      setDescModalRiesgo(
        'Tomas decisiones que pueden ser arriesgadas pero sabes como resolverlo, recuerda que siempre es bueno tener un plan B.',
      ); */
    } else if (puntosRiesgo >= 8 && puntosRiesgo <= 12) {
      /* setTitleLink_Riesgo('Alto riesgo');
      setTitleModalRiesgo('Nivel 3 de riesgo');
      setDescModalRiesgo(
        'Tu vida se ve agitada, recuerda que es bueno tener protección para ti, tus seres queridos y sobretodo tener protegido tu dinero.',
      ); */
    }
  }, [puntosRiesgo]);

  useEffect(() => {
    if (puntosFuturo >= 1 && puntosFuturo <= 2) {
      /* setTitleLink_Futuro('Baja');
      setTitleModalFuturo('Nivel 1 de planeación');
      setDescModalFuturo(
        'Es importante pensar en tu futuro y que beneficios financieros necesitas',
      ); */
    } else if (puntosFuturo >= 3 && puntosFuturo <= 5) {
      /* setTitleLink_Futuro('Media');
      setTitleModalFuturo('Nivel 2 de planeación');
      setDescModalFuturo(
        'Recuerda que una prioridad debería ser tu futuro y como lo construyes poco a poco',
      ); */
    } else if (puntosFuturo >= 6 && puntosFuturo <= 9) {
      /* setTitleLink_Futuro('Poca planeación');
      setTitleModalFuturo('Nivel 3 de planeación');
      setDescModalFuturo(
        'Tu ambición por el futuro es increible, vas por un buen camino sigue prestando atención a esos detalles.',
      ); */
    }
  }, [puntosFuturo]);

  useEffect(() => {
    if (orderRutine === 'rp') {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [orderRutine]);

  return (
    <Fragment>
      <div className="form-web">
        {orderRutine >= 1 || orderRutine === 'rp' ? (
          <Perfilamiento2_0
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={'rp'}
            saveAdvance={saveAdvance}
            trainingColor={trainingColor}
            handledPuntosDinero={handledPuntosDinero}
            handledPuntosRiesgo={handledPuntosRiesgo}
            handledPuntosFuturo={handledPuntosFuturo}
          />
        ) : null}
      </div>

      {orderRutine === 'rp'
        ? (sessionStorage.setItem('recomendaciones', recomendaciones),
        sessionStorage.setItem('puntosDinero', puntosDinero),
        sessionStorage.setItem('puntosRiesgo', puntosRiesgo),
        sessionStorage.setItem('puntosFuturo', puntosFuturo),
        history.push('/wo/profiling-2/success'))
        : null}
    </Fragment>
  );
};
export default RoutinesOrderProfiling2_0;
