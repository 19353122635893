import React from 'react';
import Carousel from 'react-elastic-carousel';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import { CREATE_OPPORTUNITIES } from '../../mutations';
// import loader from '../../assets/img/anacoach/loading.svg';
import ClickButton from '../../Helpers/HookHelpers';
import {
  ImageSections,
  TrainingText,
  TrainingSectionFlex,
  Images,
  CardsBtn,
  DivStyle,
  DivGray,
  Resultado,
  DivLoader,
  CardsTxt,
} from '../../assets/styles/Training.styled';
import { Col12 } from './common.styled';

const RpProviders = ({ products = [] }) => {
  const { actions } = ClickButton();
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };

  const generateOpportunities = (product) => {
    const click = `ComenzarEntrenamiento_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2,
        idProducts: [product.IDProduct],
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '353',
        // value: product.Instrumento,
      },
    };
    console.log(opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
    sessionStorage.setItem('clickOutProduct', product.ClickOut);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    const win = window.open(product.ClickOut, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  return (
        <TrainingSectionFlex>
            <Col12
                id="CarruselEmpresasContentHome"
                pt="20px"
                flex="0 0 100%"
                maxwidth="100%"
                display="flex"
            >
                <Carousel
                    id="CarruselMetasHome"
                    styled={{ background: '#FAFAFA' }}
                    transitionMs={1000}
                >
                    {products.length !== 0 ? (
                      products.map((card) => (
                            <Resultado
                                key={card.IDProduct}
                                bcolor="#fff"
                                ta="left"
                                border="none"
                                hei="480px"
                                minh="453px"
                                minhweb="480px"
                                marginGene="5px auto 20px auto"
                                marginweb="5px auto 20px auto"
                                marri="auto"
                            >
                                <div style={{ height: '42px' }}>
                                    <ImageSections
                                        width={ card.Name === 'Monific' ? '42px' : '90px'}
                                        hmob="auto"
                                        ml="5%"
                                        heightweb="auto"
                                        src={card.ImageURL}
                                        className="img-fluid"
                                    />
                                </div>
                                <DivStyle
                                    className="cabin"
                                    mxh="30px"
                                    weight="600"
                                    align="left"
                                    size="16px"
                                    line="1.5em"
                                    padd="12px 5% 0 5%"
                                    color="#07210A"
                                >
                                    {card.Name}
                                </DivStyle>
                                <DivGray bcolor="#fff" padd="0 5% 0 5%">
                                    <div style={{ maxHeight: '545px' }}>
                                        <DivStyle align="left" mh="120px" size="20px">
                                            <CardsTxt color="#07210A" className="roboto" size="16px" weight="bold" mt="12px">
                                                Beneficios
                                            </CardsTxt>
                                            {card.benefits.map((detalle) => {
                                              if (detalle.Name.includes('Beneficio')) {
                                                return (
                                                        <li style={{ color: '#07210A' }} className="roboto styleBenef">
                                                            {detalle.Description}
                                                        </li>
                                                );
                                              }
                                            })}
                                        </DivStyle>
                                        <DivStyle align="left" mh="" size="14px">
                                            <CardsTxt color="#07210A" className="roboto" size="16px" weight="bold">
                                                Información extra
                                            </CardsTxt>
                                            {card.benefits.map((detalle) => {
                                              if (detalle.Name === 'MontoMinimo' && detalle.Description !== '0') {
                                                return (
                                                        <li style={{ color: '#07210A' }} className="roboto styleBenef">
                                                            Monto mínimo: ${formatterMx(detalle.Description)}
                                                        </li>
                                                );
                                              }
                                              if (detalle.Name === 'Tipo') {
                                                return (
                                                        <li style={{ color: '#07210A' }} className="roboto styleBenef">
                                                            Tipo: {detalle.Description}
                                                        </li>
                                                );
                                              }
                                              if (detalle.Name === 'Tasa') {
                                                return (
                                                        <li style={{ color: '#07210A' }} className="roboto styleBenef">
                                                            Tasa: {detalle.Description}%
                                                        </li>
                                                );
                                              }
                                              if (detalle.Name === 'Monto mínimo de inversión') {
                                                return (
                                                        <li style={{ color: '#07210A' }} className="roboto styleBenef">
                                                            Monto mínimo de inversión: ${formatterMx(detalle.Description)}
                                                        </li>
                                                );
                                              }
                                              if (detalle.Name === 'Información Extra Lista') {
                                                return (
                                                        <li style={{ color: '#07210A' }} className="roboto styleBenef">
                                                            {detalle.Description}
                                                        </li>
                                                );
                                              }
                                            })}
                                        </DivStyle>
                                    </div>

                                    <CardsBtn
                                        mtop="18px"
                                        min_w="100%"
                                        onClick={() => generateOpportunities(card)}
                                    >
                                        Abrir mi cuenta
                                    </CardsBtn>
                                </DivGray>
                            </Resultado>
                      ))
                    ) : (
                        <div>
                            <DivLoader
                                ta="left"
                                margin="0 20px 0 0"
                                ml="62%"
                                mlw="15%"
                                wi="110px"
                                he="32px"
                            >
                                <Images
                    src={''}
                    top="-32px"
                                    topweb=""
                                    left="2px"
                                    leftw="0"
                                    width="95px"
                                    position="absolute"
                                    className="img-fluid rotate180"
                                ></Images>
                            </DivLoader>
                            <DivLoader
                                bcolor="transparent"
                                ta="left"
                                margin="0 20px 0 0"
                                ml="62%"
                                mlw="15%"
                                wi="110px"
                                he="32px"
                            >
                                <TrainingText
                                    size="11px"
                                    sizew="12px"
                                    className="roboto"
                                    align="left"
                                    alignweb="left"
                                    ls="-0.2px"
                                    color="#A6A6A6"
                                >
                                    Escribiendo...
                                </TrainingText>
                            </DivLoader>
                        </div>
                    )}
                </Carousel>
            </Col12>
        </TrainingSectionFlex>
  );
};

export default RpProviders;
