/* eslint-disable max-len */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import Header from '../common/DefaultHeader';
import { TrainigTam } from '../../assets/styles/Training.styled';
import FooterTraining from '../Training/FooterTraining';
import BarraScroll from '../../anaCoach/BarraScroll';

const WOlayout = ({
  children, backgroundColor, pathname, headerType, backurl, pathnameSearch = '', showFooter = true
}) => {
  const scroll = document.documentElement.scrollTop;
  const [truescroll, settruescroll] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    // console.log(`SCROLLL DE wolayout${scroll}`);
    settruescroll(scroll);
  }, [scroll]);
  if (pathnameSearch !== '' && pathnameSearch.includes('idUser') && pathname.includes('WOValueOfMOney')) {
    return (
      <>
        {children}
      </>
    );
  }
  return (
    <div style={{ position: 'relative' }}>
        <Header background={backgroundColor} pathname={pathname} headerType={headerType} backurl={backurl} />
          {children}
     {/*  <>{showFooter && <>{(window.location.search).includes('idUser') ? null : <FooterTraining pathname={pathname} />}</>}</> */}
      {truescroll > 0 ? <BarraScroll /> : <BarraScroll />}

    </div>
  );
};
export default WOlayout;
