/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import confetis from '../../assets/img/anacoach/Hero.png';
import { Image, Container, Col7 } from '../../components/common/common.styled';
import { Title1 } from '../../components/common/Home.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';

import ClickButton from '../../Helpers/HookHelpers';

const styles = {
  paddingLeft: '10px',
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  letterSpacing: '-0.3px',
  textAlign: 'left',
};
const stylesResult = {
  marginTop: '10px',
  textAlign: 'left',
  fontSize: '16px',
  padding: '2% 2% 2% 3%',
  border: '1px solid #888',
  color: '#000000',
  borderRadius: '4px',
};

const BudgetSavings = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const anchop = window.screen.width;
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
            // setStateRecovery("inactiva");
          } else {
            const routines = json; // Object.values(json);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);
  const cantidadMD = convertirNum(sessionStorage.getItem('SumaIngresos'))
    - convertirNum(sessionStorage.getItem('SimaEgresos'));
  const [savingCapacityMoney, setSavingCapacity] = useState(
    sessionStorage.getItem('CantidadAhorro')
      || sessionStorage.getItem('CantidadInvertido'),
  );
  const [monthlyExpense, setMonthlyExpense] = useState(
    sessionStorage.getItem('GastosMinimos')
      || sessionStorage.getItem('SimaEgresos')
      || '',
  );
  const [currentCapacity, setCurrentCapacity] = useState(
    (sessionStorage.getItem('CapacidadAhorro') < 0
      ? '0'
      : sessionStorage.getItem('CapacidadAhorro'))
      || (cantidadMD < 0 ? '0' : cantidadMD.toString())
      || '0',
  );
  const [monthlyIncome] = useState(
    sessionStorage.getItem('SumaIngresos') || '',
  );

  const [form] = useState({
    initialSavings: 'Ahorro en stock',
    savingCapacity: 'Actualmente puedes ahorrar el % de tus ingresos',
    minimumExpenses: '$',
    monthlyIncome: '$',
  });
  // funcionalidad propia de la rutina

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const cantMD = convertirNum(sessionStorage.getItem('SumaIngresos'))
      - convertirNum(sessionStorage.getItem('SimaEgresos'));
    const capacidad = convertirNum(sessionStorage.getItem('CapacidadAhorro'));
    if (
      recoveryRoutine === 'activa'
      || (sessionStorage.getItem('idUser') === '0'
        && sessionStorage.getItem('idCont') === '0')
    ) {
      setSavingCapacity(
        sessionStorage.getItem('CantidadAhorro')
          || sessionStorage.getItem('CantidadInvertido'),
      );
      setCurrentCapacity(
        capacidad <= 0
          ? cantMD <= 0
            ? '0'
            : cantMD.toString()
          : sessionStorage.getItem('CapacidadAhorro')
            ? sessionStorage.getItem('CapacidadAhorro')
            : cantMD <= 0
              ? '0'
              : cantMD.toString(),
      );
      if (sessionStorage.getItem('GastosMinimos')) {
        setMonthlyExpense(sessionStorage.getItem('GastosMinimos') || '');
      } else if (sessionStorage.getItem('SimaEgresos')) {
        setMonthlyExpense(sessionStorage.getItem('SimaEgresos') || '');
      }
      if (
        sessionStorage.getItem('ModificacionDatos')
        === 'No, adelante con mi plan de ahorro'
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const [ocultarBtn, setOcultarBtn] = useState(false);

  const setContinuarActual = () => {
    gsap.to(window, { duration: 1, scrollTo: '#Resultados' });
    setOcultarBtn(true);
    const click = `ContinuarSituacionActual_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOrderRutine(nextOrderRoutine);
  };

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#SituacionActual' });
    }, 3000);
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {!loaderWait || orderRutine !== 3 ? (
        <>
          <Fragment></Fragment>
          <div id="SituacionActual"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuamos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Actualmente tu situación de ahorro se encuentra de la siguiente manera:"
            hora={hora}
          />
          <div className="marginComentLgSuccess1">
            <TrainingDivSection
              backw="#f8f9fa00"
              padding="0px"
              paddw="0px"
              mb="0"
              wi="90%"
              wiw="100%"
              he="auto"
              hew="auto"
              bsha="null"
            >
              <TrainingQuestion id="SituacionActual">
                <TrainingDivSection
                  back="transparent"
                  backw="transparent"
                  mlw="18%"
                  paddw="0px"
                  wiw="60%"
                  padding="0px"
                >
                  <div style={{ width: '100%' }}>
                    <Image
                      src={confetis}
                      height="238px"
                      marginLeftM="0px"
                      heightMobile="200px"
                      margin="0px"
                      top="110px"
                      left="0%"
                      mleft="0%"
                      marginLeft="0%"
                      mtop="75px"
                    />
                    <Title1
                      style={{ fontStyle: 'normal' }}
                      backgroundM="transparent"
                      pbottom="6%"
                      background="#FAFAFA"
                      position="sticky"
                      positionM="absolute"
                      mleft="auto"
                      mright="auto"
                      heightM="auto"
                      aling="center"
                      className="roboto cabin"
                      mb="30%"
                      mbM="0px"
                      left="0%"
                      size="35px"
                      mtopM="-20%"
                      mt="-147px"
                      mleftM="0px"
                      sizeM="20px"
                      widthM="100%"
                      width="68%"
                    >
                      ¡{sessionStorage.getItem('FirstName') || 'Laura'}, así es{' '}
                      <br /> tu situación actual de ahorro!
                    </Title1>
                  </div>
                  <TrainingDivSection
                    bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                    mt="0px"
                    mtw="-20%"
                    br="4px"
                    padding="15px"
                    wi="auto"
                    wiw="auto"
                    he="auto"
                    mb="0px"
                  >
                    <form
                      onSubmit={(e) => handleSubmit(e)}
                      className="wo-input"
                      style={{ marginTop: '0px', padding: '0px' }}
                    >
                      <h1 style={{ fontSize: '28px', fontFamily: 'arial' }}>
                        <b>Resultados</b>
                      </h1>
                      <br />
                      <label style={{ fontSize: '18px', fontFamily: 'arial' }}>
                        Esta información esta basada en el entrenamiento de
                        presupuesto que ya hiciste.
                      </label>
                      <br />
                      <label style={styles}>
                        <b>Dinero ahorrado actualmente:</b>
                      </label>

                      <p style={stylesResult}>
                        {!savingCapacityMoney ? '$' : savingCapacityMoney}
                      </p>
                      <br />
                      <label style={styles}>
                        <b>Ingreso mensual promedio:</b>
                      </label>
                      <p style={stylesResult}>
                        {monthlyIncome === '' ? '$' : monthlyIncome}
                      </p>

                      <br />
                      <label style={styles}>
                        <b>Gasto mensual promedio:</b>
                      </label>
                      <p style={stylesResult}>
                        {monthlyExpense === '' ? '$' : monthlyExpense}
                      </p>

                      <br />
                      <label style={styles}>
                        <b>Capacidad actual de ahorro mensual:</b>
                      </label>
                      <p style={stylesResult}>
                        {formatter.format(currentCapacity)}
                      </p>
                    </form>
                  </TrainingDivSection>
                  <CardsBtn
                    className={ocultarBtn ? 'hidden' : ''}
                    min_w="100%"
                    min_web="100%"
                    onClick={() => setContinuarActual()}
                  >
                    Continuar
                  </CardsBtn>
                </TrainingDivSection>
              </TrainingQuestion>
            </TrainingDivSection>
          </div>
          <div id="Resultados"></div>
          {orderRutine >= 4 ? (
            <Fragment>
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard="cardOne"
                classTop="topOne1"
                text="A continuación te presento una herramienta que te ayudará a hacer un plan de ahorro, según tus ingresos y gastos:"
                hora={hora}
              />
            </Fragment>
          ) : null}
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </div>
  );
};

export default BudgetSavings;
