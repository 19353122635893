import React, { useEffect} from 'react';
import { useMutation } from '@apollo/client';
import {
  GET_CURP,
} from '../../mutations';

const Curp = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  idDatRelRoutines,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  const [getCURP] = useMutation(GET_CURP, {
    onCompleted({ createCURP }) {
      if (createCURP.statusCode === 200) {
        const questionsTmp = [
          {
            id: 'Curp',
            question: 1,
            value: createCURP.response,
          },
        ];

        const rutineTmp = {
          CatTraining_IDCatTraining: currentTraining,
          ComponentName: null,
          IDCatRoutine: 6,
          Name: 'GeneraciondeCurp',
          RoutineProps: '{}',
          RoutineType: 'Tech',
          RoutinesOrder: currentRoutine,
          Status: true,
          idDatRelRoutines,
          questions: [
            {
              CatQuestions_IDCatQuestions: 341,
              CatRoutine_IDCatRoutine: 6,
              DatUserDetailStorage: 45,
              Description: null,
              IDCatQuestions: 341,
              QuestionName: 'Curp',
              QuestionType: 'OpenField',
              QuestionsOrder: 1,
              Show: true,
              Status: true,
            },
          ],
        };
        createJsonInput(rutineTmp, questionsTmp);
        setOrderRutine(nextOrderRoutine);
      } else {
        setOrderRutine(nextOrderRoutine);
      }
    },
    onError(e) {
      setOrderRutine('SALIO CON ERROR LA CONSULTA' ,nextOrderRoutine);
    },
  });

  useEffect(() => {
    let birthDatestr = '';
    if (sessionStorage.getItem('Birthdate')) {
      birthDatestr = sessionStorage.getItem('Birthdate').split('-');
    }

    const inputCURP = {
      input: {
        nombre:
          `${sessionStorage.getItem('FirstName')
          } ${
            sessionStorage.getItem('MiddleName')}`,
        apellido_paterno: sessionStorage.getItem('FathersLastName'),
        apellido_materno: sessionStorage.getItem('MothersLastName'),
        genero: sessionStorage.getItem('Gender'),
        estado: sessionStorage.getItem('BirthState'),
        fecha_nacimiento: sessionStorage.getItem('Birthdate')
          ? `${birthDatestr[2]}/${birthDatestr[1]}/${birthDatestr[0]}`
          : '',
      },
    };
    getCURP({ variables: inputCURP });
  }, []);
  return <></>;
};

export default Curp;
