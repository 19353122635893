import React, { Fragment, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

// Modal
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ClickButton from '../../Helpers/HookHelpers';
import { Vector1, Rectangle24, Triangle } from '../common/Home.styled';
import ana from '../../assets/img/anacoach/CoachImage.png';
import {
  TrainingSubtitles,
  TrainingText,
  TrainingContainAna,
  Images,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import {
  Container,
  Col6,
  Col12,
  Col11,
  SubtitleOne,
  ParagrapTwo,
  Button,
} from '../common/common.styled';
import { INSERT_WAIT_LIST } from '../../mutations';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

// END MODAL

function WaitList() {
  const { actions } = ClickButton();
  const session = sessionStorage.getItem('session') === 'true';
  const history = useHistory();
  const [form, setForm] = useState({
    datosGuardados: 'Si',
    fullName: '',
    email: '',
    phone: '',
  });

  const [error, setError] = useState({
    fullName: false,
    email: false,
    phone: false,
    fullNameValid: -1,
    emailValid: -1,
    phoneValid: -1,
  });

  const texterror = useState({
    fullName: '',
    email: '',
    phone: '',
  });

  const [insertWaitList] = useMutation(INSERT_WAIT_LIST, {
    onCompleted({ insertWaitList }) {
      if (insertWaitList.message === 'Exito') {
        setOpen(true);
      } else {
        console.log(insertWaitList.message);
      }
    },
  });

  const ValidEmail = (value) => {
    let domain = value.split('@');
    domain = domain[1];

    if (domain !== '' && domain !== 'gmail' && domain !== 'hotmail') {
      if (domain === 'h') {
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === 'g') {
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === 'hotmail.com' || domain === 'gmail.com') {
        texterror.email = '';
        error.email = false;
        error.emailvalid = 0;
      }
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailValid = 1;
    } else {
      texterror.email = '';
      error.email = false;
      error.emailValid = 0;
    }
    if (value === '') {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailvalid = 1;
    }
  };

  const ValidLetras = (nombre, valor) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    if (valor.length > 2) {
      error[`${nombre}Valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else {
      error[`${nombre}Valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
  };

  const ValidPhone = (name, value) => {
    if (
      (value.length < 11 && value.length !== 10)
      || (name && !/^[0-9]+$/i.test(value))
    ) {
      texterror[name] = 'Por favor verifica tu número telefónico';
      setError({
        ...error,
        [name]: true,
        [`${name}Valid`]: 1,
      });
    } else {
      texterror[name] = '';
      setError({
        ...error,
        [name]: false,
        [`${name}Valid`]: 0,
      });
    }
    console.log(error);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail(e.target.value);
        break;
      case 'phone':
        ValidPhone(e.target.name, e.target.value);
        break;
      default:
        console.log('Entro a letras');
        ValidLetras(e.target.name, e.target.value);
        break;
    }
  };

  const guardar = (e) => {
    e.preventDefault();
  };

  const ProgramarAviso = () => {
    const click = `ProgramarAviso_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    if (form.datosGuardados === 'No') {
      if (
        form.email !== ''
        && !error.email
        && form.fullName !== ''
        && !error.fullName
        && form.phone !== ''
        && !error.phone
      ) {
        saveWaitList();
      }
    } else {
      saveWaitList();
    }
  };

  const saveWaitList = () => {
    try {
      insertWaitList({
        variables: {
          input: {
            nombreCompleto: form.fullName,
            email: form.email,
            telefono: form.phone,
            idTraining:
              parseInt(sessionStorage.getItem('idTraining')) || parseInt('0'),
            idUser: String(sessionStorage.getItem('idUser')),
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // MODAL
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    history.push('/the-training-feed/');
  };
  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <SubtitleOne
          mt="3%"
          className="font-weight-bold roboto"
          sizeMobile="20px"
          size="20px"
        >
          ¡Excelente!
        </SubtitleOne>
        <ParagrapTwo className="mx-auto" mb="0px">
          Te has registrado exitosamente. Pronto podrás realizar este
          entrenamiento para seguir mejorando tu vida financiera.
        </ParagrapTwo>
        <br />
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <Container width="80%" mt="5%" widthMobile="90%" mtMobile="5%">
        <Col6 flex="0 0 100%" maxwidth="100%" pt="0px">
          <TrainingContainAna back="#FAFAFA" bottom="5%" displayM="none">
            <Images
              src={ana}
              top="63px"
              topweb="0%"
              left="0"
              leftw="-40px"
              position="initial"
              positionw=""
              border="2px solid #A7E8E5"
              bs="border-box"
              padd="5px"
              br="45px"
              className="img-fluid"
            ></Images>
          </TrainingContainAna>
          <br />
          <TrainingSubtitles
            size="20px"
            fw="600"
            align="left"
            alignweb="left"
            className="cabin"
          >
            Muy pronto podrás conocer este entrenamiento, estamos trabajando en
            el lanzamiento para que sigas mejorando tus finanzas.
          </TrainingSubtitles>
          <br />
          <TrainingText
            size="16px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            ¿Te gustaría que te avisemos cuando se encuentre disponible este
            entrenamiento?
          </TrainingText>
          <Vector1
            width="68px"
            height="68px"
            displayD={form.datosGuardados === 'Si' ? 'none' : 'block'}
            displayM="none"
            background="#A1D1C1"
            top="-20%"
            left="-33%"
          />
          <Rectangle24
            left="5%"
            top="20%"
            displayD={form.datosGuardados === 'Si' ? 'none' : 'block'}
            displayM="none"
            background="#A7E8E5"
            radius="30px"
            height="70px"
            transform="rotate(23.75deg);"
          />
        </Col6>
        <Col6
          flex="0 0 100%"
          maxwidth="100%"
          border="1px solid #D6D6D6"
          borderM="none"
          radius="4px"
          pt="2%"
          padding="1%"
        >
          <TrainingSubtitles
            size="16px"
            sizew="16px"
            fw="600"
            align="left"
            alignweb="left"
            className="cabin"
          >
            Enviar aviso a los datos registrados en tu cuenta
          </TrainingSubtitles>
          <div style={{ display: session ? 'block' : 'none' }}>
            <RadioGroup
              aria-label="datosRegistrados"
              name="datosGuardados"
              value={form.datosGuardados}
              onChange={handleChange}
            >
              <FormControlLabel value="Si" control={<Radio />} label="Si" />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No, ingresar otros datos"
              />
            </RadioGroup>
          </div>
          <Container
            width="100%"
            mt={form.datosGuardados !== 'Si' || !session ? '2%' : '15%'}
            widthMobile="100%"
            mtMobile="2%"
            displayweb={
              form.datosGuardados === 'No' || !session ? 'block' : 'none'
            }
          >
            <form autoComplete="off" className="" onSubmit={guardar}>
              <Container width="100%" widthMobile="100%" mtMobile="0px">
                <Col12
                  // pMobile="0px"
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    id="fullName"
                    name="fullName"
                    variant="outlined"
                    error={error.fullName}
                    label="Nombre Completo"
                    required
                    fullWidth
                    value={form.fullName}
                    onChange={handleChange}
                    helperText={texterror.fullName}
                    inputProps={{
                      className:
                        error.fullNameValid === 1
                          ? 'perfil invalidInput'
                          : error.fullNameValid === 0
                            ? ' perfil validInput'
                            : 'perfil',
                    }}
                  />
                </Col12>
                <Col12
                  // pMobile="0px"
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    id="email"
                    name="email"
                    error={error.email}
                    label="Correo electrónico"
                    variant="outlined"
                    inputProps={{
                      className:
                        error.emailValid === 1
                          ? 'perfil invalidInput'
                          : error.emailValid === 0
                            ? 'perfil validInput'
                            : 'perfil',
                    }}
                    fullWidth
                    value={form.email}
                    onChange={handleChange}
                    helperText={texterror.error}
                  />
                </Col12>
                <Col12
                  // pMobile="0px"
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    id="phone"
                    name="phone"
                    variant="outlined"
                    error={error.phone}
                    label="Teléfono"
                    required
                    value={form.phone}
                    fullWidth
                    onChange={handleChange}
                    inputProps={{
                      className:
                        error.phoneValid === 1
                          ? 'perfil invalidInput'
                          : error.phoneValid === 0
                            ? 'perfil validInput'
                            : 'perfil',
                    }}
                    helperText={texterror.phone}
                  />
                </Col12>
              </Container>
            </form>
          </Container>
          <Container
            width="100%"
            mt="15%"
            widthMobile="100%"
            mtMobile="10%"
            displaymobile="none"
          >
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={ProgramarAviso}
            >
              Programar aviso
            </CardsBtn>
            <TrainingText
              size="12px"
              sizew="12px"
              top="5%"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              Te enviaremos el aviso el día del pre-lanzamiento para que seas de
              los primeros en conocerlo. Por favor espera nuestro mensaje.
            </TrainingText>
          </Container>
        </Col6>
        <Col12 displayMobile="none">
          <Vector1
            width="68px"
            height="68px"
            background="#A1D1C1"
            top="-20%"
            left="-17%"
            displayD={form.datosGuardados === 'Si' ? 'block' : 'none'}
          />
          <Rectangle24
            left="5%"
            top="10%"
            background="#A7E8E5"
            radius="30px"
            height="70px"
            transform="rotate(23.75deg);"
            displayD={form.datosGuardados === 'Si' ? 'block' : 'none'}
          />
          <Triangle
            widthborder="50px solid transparent"
            widthborderColor="50px solid #A0D0DE"
            marginleft="-14%"
          />
          <Rectangle24
            left="70%"
            top="-10%"
            width="40px"
            height="40px"
            background="#A0D0DE"
            transform="rotate(23.75deg);"
          />
          <Rectangle24
            left="90%"
            top="0%"
            width="60px"
            height="60px"
            background="#A5BACF"
            transform="rotate(23.75deg);"
          />
          <Rectangle24
            left="110%"
            top="-12%"
            width="90px"
            height="90px"
            background="#A1D1C1"
            transform="rotate(23.75deg);"
          />
        </Col12>
      </Container>
      <Container
        width="100%"
        paddingTop="10%"
        bordertop="1px solid #dddddd"
        shadow="0px -4px 10px rgba(0, 0, 0, 0.12)"
        mt="0%"
        btopRradius="40px"
        btopLradius="40px"
        widthMobile="100%"
        mtMobile={form.datosGuardados === 'No' ? '10%' : '5%'}
        displayweb="none"
        displaymobile="block"
      >
        <Col11>
          <CardsBtn
            type="button"
            min_w="100%"
            min_web="100%"
            onClick={ProgramarAviso}
          >
            Programar aviso
          </CardsBtn>
          <TrainingText
            size="12px"
            sizew="12px"
            top="5%"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            Te enviaremos el aviso el día del pre-lanzamiento para que seas de
            los primeros en conocerlo. Por favor espera nuestro mensaje.
          </TrainingText>
        </Col11>
      </Container>
      <ModalMessage />
    </Fragment>
  );
}
export default WaitList;
