import React, { useEffect, useState } from "react";
import {
  Button,
  ContentButton,
  LinksFooter,
  HelpIcon,
  ContentGeneric,
  ModalFull,
  CheckBox,
} from "../../../../components/common/common.styled";
import Modal from "@material-ui/core/Modal";
import { TextMaxLine } from "../../../Routines/KnowScore.jsx";

export const ButtonsPassword = ({
  disabledbtn,
  handleClick,
  setispassword,
  setFlagOn,
  flagOn,
}) => {
  const marginModalw = `${(window.screen.availWidth - 374) / 2}px`;
  const marginModalh = `${(window.screen.availHeight - 400) / 2}px`;
  const [openInfo, setopenInfo] = useState(false);
  const nextInfo = () => {
    if (openInfo) {
      setopenInfo(false);
    } else {
      setopenInfo(true);
    }
  };

  const handleChecked = () => {
    setFlagOn(!flagOn);
  };

  return (
    <>
      <ContentButton mt="36px" mtMobile="24px">
        <Button
          id="btnIniciar"
          mt="0px"
          type="submit"
          borderRadius="16px"
          disabled={disabledbtn}
          className={
            disabledbtn ? "buttonInactive d-block  roboto" : "d-block  roboto"
          }
          bg={disabledbtn ? "#d1d1d1" : "#fb245c"}
          onClick={handleClick}
        >
          Ingresar
        </Button>
        <button
          className="link roboto"
          style={{
            fontSize: 12,
            border: "none",
            background: "none",
            fontFamily: "Roboto",
          }}
          onClick={() => setispassword(false)}
        >
          ¿Olvidaste tu contraseña?{" "}
          <span style={{ color: "#F64282", textDecoration: "revert" }}>
            Ingresa con enlace
          </span>
        </button>
        <br />
        <span className="roboto" style={{ fontSize: 12, marginTop: "12px" }}>
          Dentro de tu Perfil podrás cambiar tu contraseña
        </span>
        <br />
        <div style={{ textAlign: "left", marginTop: "36px", display: "flex" }}>
          <CheckBox
            type="checkbox"
            id="sActiva"
            name="sActiva"
            checked={flagOn}
            onClick={handleChecked}
          />
          <div
            className="roboto"
            style={{ fontSize: "12px", marginTop: "2px" }}
          >
            ¿Quieres mantener la sesión iniciada?
            <HelpIcon onClick={() => nextInfo()}>?</HelpIcon>
          </div>
        </div>
      </ContentButton>
      <Modal
        open={openInfo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          backgroundColor: "rgba(7, 11, 17, 0.05)",
          position: "fixed",
          top: "0px",
          overflowY: "auto",
        }}
      >
        <ModalFull
          top="75px"
          margin="0 7.5% 30px "
          padding="36px 21px"
          width="85%"
          widthMd="374px"
          marginMd={`${marginModalh} ${marginModalw}`}
          overflowY="auto"
          bottom="auto"
          borderR="16px"
          bottomxs="20px"
          className="d-flex flex-column"
        >
          <ContentGeneric
            textAlign="end"
            fontSize="20px"
            margin="0px"
            padding="0px"
            className="col-12"
            style={{ cursor: "pointer" }}
            onClick={() => setopenInfo(false)}
          ></ContentGeneric>
          <ContentGeneric
            dis="flex"
            flexDirection="column"
            gap="24px"
            position="relative"
            margin="0px !important"
            padding="0px !important"
            className="col-12"
          >
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              weight="400px"
              textAlign="left"
            >
              Mantén la sesión iniciada para no tener que volverla a iniciar la
              próxima vez.
            </TextMaxLine>
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              weight="400px"
              textAlign="left"
            >
              Para mantener tu cuenta segura, usa esta opción sólo en tus
              dispositivos personales.
            </TextMaxLine>
          </ContentGeneric>
          <ContentGeneric className="col-12" padding="0px" height="auto">
            <Button
              className="roboto"
              onClick={() => setopenInfo(false)}
              mb="0px"
            >
              Entendido
            </Button>
          </ContentGeneric>
        </ModalFull>
      </Modal>
    </>
  );
};

export default ButtonsPassword;
