import React, {
  useState, Fragment, useEffect, Suspense,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useLazyQuery, useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'react-elastic-carousel';
import FastTrack from '../Routines/FastTrack';
import { GET_PRODUCTS_BY_IDTRAINING, GET_TRAINING_COLOR } from '../../queries';
import { SEND_CREDITEA_WL, SEND_OTP_CREDITEA_WL, RE_SEND_OTP_CREDITEA_WL } from '../../mutations';
import { getDateStandart, getItemSessionStorage, handleOtpCreditea } from '../../Helpers/general';

import CardOne from '../../components/Home/Card1Animate';
import star2 from '../../assets/img/anacoach/star2.png';
import star from '../../assets/img/anacoach/star.png';
import stars from '../../assets/img/anacoach/5Stars.png';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import {
  SAVE_EVALUATE_TRAINING,
  INSERT_DAT_VIRALITY,
  GET_RECOMMENDATION_TRAININGS,
  UPDATE_HISTORY_COLLECTION,
  CREATE_SESSION,
  CREATE_OPPORTUNITIES,
  SET_TRAINING_COMPLETE,
} from '../../mutations';

import {
  Resultado,
  ImageSections,
  DivFlex,
  CardsTxt,
  DivGray,
  CardsBtn,
  DivStyle,
  Images,
  TrainingSectionGray,
  TrainingDivSection2,
  // DivBtn,
  RPFastTrack,
  TrainingSectionFlex,

  DivLoader,
} from '../../assets/styles/Training.styled';
import ProductsHooks from '../../Helpers/HookHelpersProducts';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import { shareCoru } from '../../Helpers/index';
import { viralityUTM } from '../../Helpers/HelperUTM';
import ClickButton from '../../Helpers/HookHelpers';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import ModalGeneral from '../../components/common/ModalGeneral';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';
import Checkbox from '@material-ui/core/Checkbox';
import { ParagrapTwo } from '../../components/common/common.styled';
import CrediteaModals from '../../components/common/CrediteaModals';

const DatBasic = React.lazy(() => import('../Routines/DatBasic'));
const RoutinesOrderFastTrack = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  percentage,
  getProgressAgain,
  setPercentage,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { sendApi } = ProductsHooks();
  const { actions } = ClickButton();
  const [fastTrackProducts, setFastTrackProducts] = useState([]);
  const [data, setData] = useState([]);
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [descIntro, setDescIntro] = useState('Estos son los créditos que te podrían interesar. Revisa las opciones que tenemos para tí y elige la que se adecúe a tus necesidades.');
  const [evaluacion, setEvaluacion] = useState(0);
  const [calificado, setCalificacion] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const { sendMessage } = SendNotificationFTAndCC();
  const { sendEmail } = SendNotificationEmail();
  const [modalActive, setModalActive] = useState(false);
  const [productForClickout, setProductForClickout] = useState();
  const [creditea, setCreditea] = useState({});
  const [kueski, setKueski] = useState({});
  const [setTrainingComplete] = useMutation(SET_TRAINING_COMPLETE);
  const [terminos, setterminos] = useState(true);
  const [crediteaappid, setCrediteaAppId] = useState('');
  const [crediteclickout, setCrediteClickOut] = useState('');
  const [resultcreditea, setResultCreditea] = useState({});
  const [completeft, setCompleteFT] = useState(false);

  useEffect(() => {
    if (fastTrackProducts.length > 0 && !completeft) {
      sendMessage({ cellPhone: sessionStorage.getItem('CellPhone'), trigger: 'Notificación_FT_3' });
      setTimeout(() => sendEmail({ WO: 'FastTrack', trigger: 'email_FT5' }), 2500);
      updateTrainingAchievement();
    }
  }, [fastTrackProducts, completeft]);

  const updateTrainingAchievement = async () => {
    const idUserM = parseInt(sessionStorage.getItem('idUser'));

    const result = await setTrainingComplete({
      variables: {
        idUser: idUserM,
        idTraining: 29,
      },
    });

    getProgressAgain();

    setPercentage('100%');
    setCompleteFT(true);
  };

  // const [sendCrediteaApplicationWL] = useMutation(SEND_CREDITEA_WL, {
  //   fetchPolicy: 'no-cache',
  //   onCompleted({ sendCrediteaApplicationWL }) {
  //     if (sendCrediteaApplicationWL.statusCode === 200) {
  //       setCrediteaAppId(sendCrediteaApplicationWL.response);
  //       setOpenSent(true);
  //     } else if (sendCrediteaApplicationWL.statusCode === 400) {
  //       setOpenSent(true);
  //       setSuccess(false);
  //       setResultOtp(true);
  //     }
  //   },
  //   onError(err) {
  //     console.log(err);
  //   },
  // });

  const [sendOTPCrediteaApplicationWL] = useMutation(SEND_OTP_CREDITEA_WL, {
    fetchPolicy: 'no-cache',
    onCompleted({ sendOTPCrediteaApplicationWL }) {
      if (sendOTPCrediteaApplicationWL.statusCode === 400) {
        const json = JSON.parse(sendOTPCrediteaApplicationWL.response);
        const data = JSON.parse(json.data);
        if (data.applicationId.length !== 0) {
          setCrediteClickOut(data.availableOffers[0].completionURL);
          setResultCreditea(data.availableOffers[0]);
          setSuccess(true);
          setResultOtp(true);
        } else {
          setSuccess(false);
          setResultOtp(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [reSendOTPCrediteaApplicationWL] = useMutation(RE_SEND_OTP_CREDITEA_WL, {
    fetchPolicy: 'no-cache',
    onCompleted({ reSendOTPCrediteaApplicationWL }) {
      console.log(reSendOTPCrediteaApplicationWL.response);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPersonalizeRecommendationTrainings] = useMutation(GET_RECOMMENDATION_TRAININGS, {
    onCompleted({ getPersonalizeRecommendationTrainings }) {
      if (getPersonalizeRecommendationTrainings.statusCode === 200) {
        setRecommendedTraining(getPersonalizeRecommendationTrainings.response);
        setRecomendaciones(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const Stars = [
    {
      IDStar: 's1',
      Image: star2,
    },
    {
      IDStar: 's2',
      Image: star2,
    },
    {
      IDStar: 's3',
      Image: star2,
    },
    {
      IDStar: 's4',
      Image: star2,
    },
    {
      IDStar: 's5',
      Image: star2,
    },
  ];
  function evalStar(id) {
    switch (id) {
      case 's1':
        cambiarImagen('s1', 0);
        break;
      case 's2':
        cambiarImagen('s2', 1);
        break;
      case 's3':
        cambiarImagen('s3', 2);
        break;
      case 's4':
        cambiarImagen('s4', 3);
        break;
      case 's5':
        cambiarImagen('s5', 4);
        break;
      default:
        break;
    }
  }
  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === 'success') {
        const { response } = insertDatVirality;
        const redirectLink = response.split(',')[0];
        const socialNetwork = response.split(',')[1];
        const announcement = sessionStorage.getItem('auxAnnouncement');
        shareCoru(socialNetwork, announcement, redirectLink);
      }
    },
  });
  const [updateHistoryCollection, { }] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode == 200) {
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickShareNetworkCollection, { }] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode == 200) {
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const overwrit = sessionStorage.getItem('overwrite_WO_29');
    if (overwrit === 'true' || overwrit === true) {
      setOrderRutine(4);
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setCreditea(data.filter((f) => f.IDProduct === 281));
      setKueski(data.filter((f) => f.IDProduct === 276))
    }
  }, [data]);

  const ClickShareMongo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickShareSN'.concat(action),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickSocialSN')) {
      const data_json = [
        {
          idSession: sessionStorage.getItem('id_session'),
          nameTraining: sessionStorage.getItem('trainingName'),
          medium: action,
          date: getDateStandart(),
        },
      ];
      const data_string = JSON.stringify(data_json);
      const filter_string = JSON.stringify({ _id: sessionStorage.getItem('idClickSocialSN') });
      const mongoInput = {
        input: {
          collection: 'LogShared',
          key: 'Shared',
          data: data_string,
          filter: filter_string,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const data_json = {
        idUser: sessionStorage.getItem('idUser'),
        Shared: [
          {
            idSession: sessionStorage.getItem('id_session'),
            nameTraining: sessionStorage.getItem('trainingName'),
            medium: action,
            date: getDateStandart(),
          },
        ],
      };
      const data_string = JSON.stringify(data_json);
      const mongoInput = {
        input: {
          collection: 'LogShared',
          data: data_string,
        },
      };
      createClickShareNetworkCollection({ variables: mongoInput });
    }
  };

  const shareTraining = (socialNetwork, announcement) => {
    ClickShareMongo(socialNetwork);
    sessionStorage.setItem('auxAnnouncement', announcement);
    viralityUTM(insertDatVirality, '/wo/fast-track', sessionStorage.getItem('trainingName'), socialNetwork);
  };
  function cambiarImagen(id, position) {
    const imgs = document.getElementsByClassName('evalua');
    for (let x = 0; x < 5; x++) {
      if (x <= position) {
        imgs[x].src = star;
      } else {
        imgs[x].src = star2;
      }
    }
  }
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        // console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const abrirNuevoTab = (product, byProduct = 0) => {
    setModalActive(false); debugger
    const click = `ComenzarEntrenamiento_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    let idProd = [];
    if (byProduct === 0) {
      if (product.IDProduct !== 276 && product.IDProduct !== 281) {
        idProd.push(product.IDProduct, 276, 281)
      } else if (product.IDProduct === 276) {
        idProd.push(product.IDProduct, 281)
      } else if (product.IDProduct === 281) {
        idProd.push(product.IDProduct, 276)
      }
    } else {
      idProd.push(byProduct)
    }
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2,
        idProducts: idProd,
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '353',
        // value: product.Instrumento,
      },
    };
    // console.log(opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
    sessionStorage.setItem('clickOutProduct', product.ClickOut);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    if (byProduct === 0) {
      if (product.IDProduct) {
        const url = product.ClickOut.replace('[IDUser]', sessionStorage.getItem('idUser') || '');
        window.open(url, '_blank').focus();
      }
      // window.open('https://kueski.com/prestamos-sin-buro?utm_source=wzogao&utm_medium=p_sms&utm_campaign=preaprobados&utm_term=ppg&utm_content=nh', '_blank').focus();
      // window.open('https://www.creditea.mx/?channel=coru_CPA', '_blank').focus();
    } else if (byProduct === 276) {
      if (kueski[0].ClickOut) {
        window.open(kueski[0].ClickOut, '_blank').focus();
      } else {
        window.open('https://kueski.com/prestamos-sin-buro?utm_source=wzogao&utm_medium=p_sms&utm_campaign=preaprobados&utm_term=ppg&utm_content=nh', '_blank').focus();
      }

    } else if (byProduct === 281) {
      const urlCreditea = creditea[0].ClickOut.replace('[IDUser]', sessionStorage.getItem('idUser') || '');
      if (urlCreditea) {
        window.open(urlCreditea, '_blank').focus();
      } else {
        window.open('https://www.creditea.mx/?channel=coru_CPA', '_blank').focus();
      }
    }
    sendMessage({ cellPhone: sessionStorage.getItem('CellPhone'), trigger: 'Notificación_FT_5', instrument: product.Name });
    setTimeout(() => sendEmail({ WO: 'FastTrack', trigger: 'email_FT_7' }), 2500);
    // Cambiar el foco al nuevo tab (punto opcional)
    // win.focus();
  };
  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    onCompleted({ getProductsByIdTraining }) {
      console.log('Productos', getProductsByIdTraining);
      const products = JSON.parse(getProductsByIdTraining.response);
      console.log(products);
      setData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });
  const scrollToRoutine = () => {
    const avance = document.body.scrollHeight;
    window.scroll({
      top: avance,
      behavior: 'smooth',
    });
  };
  const [saveEvaluateTraining] = useMutation(SAVE_EVALUATE_TRAINING, {
    onCompleted({ saveEvaluateTraining }) {
      if (saveEvaluateTraining.statusCode === 200) {
        console.log('saveEvaluateTraining', saveEvaluateTraining.response);
      }
    },
  });
  function envioEval() {
    const click = `EnviarEvaluacion_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setCalificacion(true);
    scrollToRoutine();
    console.log(evaluacion);
    if (sessionStorage.getItem('IdDatTraining') !== null && sessionStorage.getItem('IdDatTraining') !== '') { saveEvaluateTraining({ variables: { idDatTraining: String(sessionStorage.getItem('IdDatTraining')), stars: String(evaluacion) } }); }
  }
  useEffect(() => {
    getProductsByIdTraining({ variables: { idTraining: String(currentTraining) } });
  }, []);

  const [ActiveScrollProducts, setActiveScrollProducts] = useState(false);
  const [prevent2doScrollProducts, setprevent2doScrollProducts] = useState(true);
  useEffect(() => {
    if (ActiveScrollProducts && prevent2doScrollProducts) {
      setTimeout(() => {
        gsap.to(window, { duration: 1, scrollTo: '#ActivoProductosOpciones' });
        setprevent2doScrollProducts(false);
      }, 1500);
    }
  }, [ActiveScrollProducts]);

  const anchop = window.screen.width;
  const buttonR = document.getElementById('right-button');

  useEffect(() => {
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    if (buttonR !== null) {
      buttonR.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;
        const cw = content.scrollWidth;

        if (sl + scrollStep >= cw) {
          content.scrollTo(cw, 0);
        } else {
          content.scrollTo(sl + scrollStep, 0);
        }
      });
    }
  }, [buttonR]);
  const buttonL = document.getElementById('left-button');
  useEffect(() => {
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    if (buttonL !== null) {
      buttonL.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;

        if (sl - scrollStep <= 0) {
          content.scrollTo(0, 0);
        } else {
          content.scrollTo(sl - scrollStep, 0);
        }
      });
    }
  }, [buttonL]);

  // const [openSent, setOpenSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resultotp, setResultOtp] = useState(false);
  const [sendOTP, setSendOtp] = useState(true);
  // const verOtrasOpciones = () => {
  //   setOpenSent(true);
  //   setSuccess(false);
  //   setResultOtp(true);
  // };

  // const sendOtpCreditea = () => {
  //   if (crediteclickout.length === 0) {
  //     setSuccess(false);
  //     setResultOtp(false);
  //     setSendOtp(true);
  //     handleOtpCreditea({
  //       sendCrediteaApplicationWL,
  //       verOtrasOpciones,
  //     });
  //   } else if (crediteclickout.length !== 0) {
  //     window.open(crediteclickout, '_blank').focus();
  //   }
  // };

  const activeModal = (product) => {
    // if (product.IDProduct === 281) {
    //   sendOtpCreditea();
    // } else {
    //   setProductForClickout(product);
    //   setModalActive(true);
    // }
    setProductForClickout(product);
    setModalActive(true);
  }

  // const ValidationCrediteaOtp = (otp) => {
  //   if (sendOTP) {
  //     setSendOtp(false);
  //     sendOTPCrediteaApplicationWL({
  //       variables: {
  //         OTP: otp,
  //         id: crediteaappid,
  //         email: getItemSessionStorage('Email1'),
  //       },
  //     });
  //   }
  // };

  // const reSendOtpCreditea = () => {
  //   reSendOTPCrediteaApplicationWL({
  //     variables: {
  //       id: crediteaappid,
  //       email: getItemSessionStorage('Email1'),
  //     },
  //   });
  // };

  return (
    <Fragment>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={trainingColor}
            recoveryTimeout={true}
          />
        </Suspense>
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          mainDataRoutine={mainDataRoutine}
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 3 ? (
        <ContactInformation
          mainDataRoutine={mainDataRoutine}
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 4 ? (
        <FastTrack
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          fastTrackProducts={fastTrackProducts}
          setFastTrackProducts={setFastTrackProducts}
          setActiveScrollProducts={setActiveScrollProducts}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={trainingColor}
        />
      ) : null}
      {fastTrackProducts.length > 0 ? (
        <>
          <div id="ActivoProductosOpciones"></div>
          <ModalVirality />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <TrainingSectionGray back="#fafafa">
            <TrainingDivSection2 wiw="670px" paddi="0" paddiw="0" mw="auto" back="#fafafa">
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard="cardOne"
                classTop='topOne1'
                text={descIntro}
              />
              <div className="marginComentLgSuccess1">
                <TrainingSectionFlex className="d-flex flex-row" style={{ marginTop: '20px', marginBottom: '20px' }} id="CarruselEmpresasContentHomeFast">
                  <Carousel itemsToShow={1}>
                    {
                      fastTrackProducts?.map((product) => (<>
                        <Resultado key={product.IDProduct} bcolor="#fff" ta="left" mbot="20px" marri="auto" marriweb="auto" border="none" hei="635px" minh="453px" minhweb="600px">
                          <ImageSections
                            width="30%"
                            ml="5%"
                            heightweb="auto"
                            src={product.ImageURL}
                            className="img-fluid"
                          />
                          <br />
                          <DivStyle className="cabin" mxh="30px" weight="600" align="left" size="12px" line="1.5em" padd="12px 5% 0 5%" color="#07210A">
                            {product.Name}
                          </DivStyle>
                          <DivGray bcolor="#fff" padd="0 5% 0 5%">
                            <hr />
                            <div>
                              <DivStyle align="left" mh="100px" mxh="150px">
                                <DivFlex justify="space-between">
                                  {JSON.parse(product.productDetail.response).map(
                                    (detalle) => {
                                      if (detalle.Name === 'CAT') {
                                        return (
                                          <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                                            CAT {`${(detalle.Description * 100).toFixed(2)}%`}
                                          </CardsTxt>
                                        );
                                      } else if (detalle.Name === 'CAT Diario') {
                                        return (
                                          <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                                            CAT {`${detalle.Description}`}
                                          </CardsTxt>
                                        );
                                      } else if (detalle.Name === 'Información Extra') {
                                        return (
                                          <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                                            {detalle.Description}
                                          </CardsTxt>
                                        );
                                      }
                                    },
                                  )}
                                </DivFlex>
                                <DivFlex justify="space-between">
                                  {JSON.parse(product.productDetail.response).map(
                                    (detalle) => {
                                      if (detalle.Name === 'Interés anual') {
                                        return (
                                          <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                                            Tasas de interés {`${(detalle.Description * 100).toFixed(2)}%`}
                                          </CardsTxt>
                                        );
                                      }
                                    },
                                  )}
                                </DivFlex>
                              </DivStyle>
                              <Images
                                src={stars}
                                top="0"
                                topweb="0"
                                left="0"
                                leftw="0"
                                position="initial"
                                width="116px"
                                widthw="116px"
                                className="img-fluid"
                              ></Images>
                              <hr />
                              <DivStyle align="left" mh="180px" size="14px">
                                <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                                  Beneficios
                                </CardsTxt>
                                {JSON.parse(product.productDetail.response).map(
                                  (detalle) => {
                                    if (detalle.Name === 'Beneficio 1') {
                                      return <li className="roboto styleBenef listImagen">{detalle.Description}</li>;
                                    }
                                    if (detalle.Name === 'Beneficio 2') {
                                      return <li className="roboto styleBenef listImagen">{detalle.Description}</li>;
                                    }
                                    if (detalle.Name === 'Beneficio 3') {
                                      return <li className="roboto styleBenef listImagen">{detalle.Description}</li>;
                                    }
                                  },
                                )}
                              </DivStyle>
                            </div>
                            {/* {
                              product.Name === 'Creditea' &&
                              <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                                Para continuar con tu solicitud es necesario que apruebes los terminos y condiciones de Creditea.
                              </CardsTxt>
                            } */}
                            <CardsBtn
                              mtop="18px"
                              min_w="100%"
                              onClick={() => activeModal(product)}
                              disabled={!terminos ? 'disabled' : ''}
                            >
                              {/* {product.Name === 'Creditea' ? 'Empezar solicitud' : 'Solicitar préstamo'} */}
                              Solicitar préstamo
                            </CardsBtn>
                            {/* {product.Name === 'Creditea' &&
                              <div className='row'>
                                <div className="col-3 col-md-2 col-lg-2">
                                  <Checkbox checked={terminos} onChange={() => { setterminos(!terminos) }} name="terminos" />
                                </div>
                                <div className="col-9 col-md-10 col-lg-10">
                                  <ParagrapTwo fontSize="14px" fontSizeWeb="14px" className="mx-auto roboto" mb='10%' mbweb='10%' mt="10px" color="#07210A" fontWeight=" " mtWeb="10px">
                                    Si he leído y acepto los <a href='https://www.creditea.mx/terminos-y-condiciones' target="_blank" className='linkColor'>términos</a> y <a href='https://www.creditea.mx/terminos-y-condiciones' target="_blank" className='linkColor'>condiciones</a> de Creditea
                                  </ParagrapTwo>
                                </div>
                              </div>
                            } */}
                            <ModalGeneral
                              modalActive={modalActive}
                              setModalActive={setModalActive}
                              handleFunction={abrirNuevoTab}
                              params={productForClickout}
                              txtTitle="Consigue tu préstamo"
                              txtSubtitle="Estas a unos pasos de completar tu solicitud. Sí tu perfil se adecua a otro crédito te ayudaremos con la solicitud."
                              txtBtn="Continuar"
                              heiMod='68%'
                              modalKC={true}
                            />
                            {/* <CrediteaModals
                              setOpen={setOpenSent}
                              open={openSent}
                              ingreso={sessionStorage.getItem('Ingreso_Mensual_Fijo') || 'de_7000_a_15000'}
                              validation={ValidationCrediteaOtp}
                              sendOtpCreditea={sendOtpCreditea}
                              reenviarCodigo={reSendOtpCreditea}
                              success={success}
                              result={resultotp}
                              ClickOut={crediteclickout}
                              resultcreditea={resultcreditea}
                            /> */}
                          </DivGray>
                        </Resultado>
                      </>))
                    }
                  </Carousel>
                </TrainingSectionFlex>
              </div>
            </TrainingDivSection2>
          </TrainingSectionGray>

        </>
      ) : null}
      {fastTrackProducts.length > 0 ? (
        <>
          <ModalAmazon show={true} wo={'FastTrack'}></ModalAmazon>
          {/* Fremium Klar Prestamos a tu medida */}
          <TrainingSectionGray back="#fafafa">
            {sessionStorage.getItem('idTraining') === '29' ? (
              <ProductsFremium
                idT={parseInt(sessionStorage.getItem('idTraining'))}
              />
            ) : null}
          </TrainingSectionGray>
          <SectionShare WO="FastTrack"></SectionShare>
          {
            recomendaciones
              ? <Feed_Main width="100%" background=" " >
                <Feed_Main width="100%" display=" " mtop=" " background="transparent">
                  <CardOne
                    classCard="cardOne"
                    classTop="topOne1"
                    text="¿Aún quieres ir por más?"
                    text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                  />
                  <CardOne
                    classCard="cardOne"
                    classTop="topOne1"
                    text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                  />
                </Feed_Main>
              </Feed_Main>
              : null
          }
        </>) : null}
    </Fragment>
  );
};
export default RoutinesOrderFastTrack;
