import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { ContentHomeSection } from '../../common/Home.styled';
import { ParagrapTwo } from '../../common/common.styled';
import { ParrafoSuccess, ContenedorEmail } from '../Styleds/Asesores.Styled';
import { useMutation, gql } from '@apollo/client';

const ModalResetPassword = ({ setModalResetPassword, Email }) => {

    const useStyles = makeStyles((theme) => ({

        paper: {
            position: 'absolute',
            width: '500px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            textAlign: 'center',
            borderRadius: '5px',
            overflow: 'auto',
            overflowX: 'hidden',
            height: '600px',
        },
    }));

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            height: 'auto',
            padding: '0px 32px 24px',
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(true);
    const [mensaje, setMensaje] = useState(false);
    const [mensajeerror, setMensajeError] = useState(false);


    const [SendOtp] = useMutation(gql`
    mutation SendOtp($input: envioOtpInput) {
      sendOtp(input: $input)
    }
  `);

    const handleClose = () => {
        setOpen(false);
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        setModalResetPassword(false);
    };

    async function onSubmit() {
        let input;
        if (Email !== '') {
            input = {
                telefono: Email,
                medio: 'MAIL',
                tipo: 'recovery',
            };
        }
        try {
            await SendOtp({ variables: { input } });
            setMensaje(true);
        } catch (err) {
            setMensajeError(true);
            console.log(err);
        }
    }


    return (

        <>
            <Modal
                open={open}
                className={'modal display-block'}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                
                <div style={modalStyle} className={classes.paper}>
                    <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
                        <ParagrapTwo
                            fontWeight="700"
                            cursor="pointer"
                            texttalign="right"
                            textalignMobile="right"
                            className="mx-auto"
                            zindex="1"
                            onClick={handleClose}
                        >
                            X
                        </ParagrapTwo>
                        <ParagrapTwo
                            texttalign="left"
                            textalignMobile="left"
                            className="mx-auto roboto"
                        >
                            Reset Password
                        </ParagrapTwo>
                        <ContenedorEmail>
                            <InputLabel
                                className="roboto"
                                id="demo-simple-select-outlined-label"
                            >
                                {mensaje ? <ParrafoSuccess color={'#22bb33'} > Exito! </ParrafoSuccess> : Email }
                                {mensajeerror ? <ParrafoSuccess color={'#CC1E1E'} > Error! </ParrafoSuccess> : null}
                                
                            </InputLabel>
                        </ContenedorEmail>
                    </ContentHomeSection>
                    <div style={{ height: '4rem' }} ></div>
                    {mensaje ?
                        null :
                        (
                            <Button
                                id="btnGenerar"
                                name="btnGenerar"
                                type="button"
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={onSubmit}
                                fullWidth
                            >
                                Recuperar contraseña
                            </Button>
                        )}
                </div>
            </Modal>
        </>

    );
}

export default ModalResetPassword
