// // import { DataUsageOutlined } from '@material-ui/icons';

export function setSessionVariablesUserContact(response = '') {
  const payload = JSON.parse(response);
  const {
    BirthDate,
    BirthState,
    ExtNo,
    FathersLastName,
    FirstName,
    Gender,
    IntNo,
    MaritalStatus,
    MiddleName,
    MothersLastName,
    Municipality,
    RFC,
    Sector,
    State,
    Street,
    Suburb,
    ZipCode,
  } = payload;

  const date = new Date(BirthDate);
  const birthDate = `${date.getFullYear()}-${(`0${date.getMonth()}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

  sessionStorage.setItem('FirstName', FirstName);
  sessionStorage.setItem('MiddleName', MiddleName);
  sessionStorage.setItem('FathersLastName', FathersLastName);
  sessionStorage.setItem('MothersLastName', MothersLastName);
  sessionStorage.setItem('Birthdate', birthDate);
  sessionStorage.setItem('ZipCode', ZipCode);
  sessionStorage.setItem('BirthState', BirthState);
  sessionStorage.setItem('Gender', Gender);
  sessionStorage.setItem('RFC', RFC);
  sessionStorage.setItem('Street', Street);
  sessionStorage.setItem('ExtNo', ExtNo || '444');
  sessionStorage.setItem('IntNo', IntNo || '444');
  sessionStorage.setItem('Suburb', Suburb);
  sessionStorage.setItem('Municipality', Municipality);
  sessionStorage.setItem('State', State);
  sessionStorage.setItem('ZipCode', ZipCode);
  sessionStorage.setItem('Street', Street);
  sessionStorage.setItem('Tipo_de_Industria', Sector);
  sessionStorage.setItem('MaritalStatus', MaritalStatus);
}

export function setSessionVariablesUserContactCoopel(response = '') {
  const payload = JSON.parse(response);
  const {
    BirthDate,        
    FathersLastName,
    FirstName,
    Gender,    
    MaritalStatus,
    MiddleName,
    MothersLastName,    
    RFC    
  } = payload;

  const date = new Date(BirthDate);
  const birthDate = `${date.getFullYear()}-${(`0${date.getMonth()}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

  sessionStorage.setItem('FirstName', FirstName);
  sessionStorage.setItem('MiddleName', MiddleName);
  sessionStorage.setItem('FathersLastName', FathersLastName);
  sessionStorage.setItem('MothersLastName', MothersLastName);
  sessionStorage.setItem('Birthdate', birthDate);  
  sessionStorage.setItem('Gender', Gender);
  sessionStorage.setItem('RFC', RFC);
  
  sessionStorage.setItem('MaritalStatus', MaritalStatus);
}

export function setSessionVariablesUserDetails(response = '') {
  const payload = JSON.parse(response);
  const {
    CreditoAutomotrizBancarioEnLosUltimosDosAnios,
    HipotecaVigente,
    IngresoMensualFijo,
    NombreDeLaEmpresa,
    Ocupacion,
    TiempoDeLaborarEnLaEmpresaEnAnios,
    TiempoDeVivirEnDomicilio,  
    TipoDeVivienda,
    UltimoGradoDeEstudios,
    Ultimos4DigitosDeTarjeta,
    // NumeroINE,
    // TelefonoDeReferenciaFamiliar,
    // TelefonoDeReferenciaNoFamiliar,
    // NombreCompletoDeUnaReferenciaNoFamiliar,
    // NombreCompletoReferenciaFamiliar,
    // Edad,
    Curp,

  } = payload;

  sessionStorage.setItem('Ulltimo_grado_de_estudios', UltimoGradoDeEstudios);
  sessionStorage.setItem('Tipo_de_Vivienda', TipoDeVivienda);
  sessionStorage.setItem('Tiempo_de_vivir_en_domicilio', TiempoDeVivirEnDomicilio);
  sessionStorage.setItem('Ingreso_Mensual_Fijo', IngresoMensualFijo);
  sessionStorage.setItem('Nombre_de_la_empresa', NombreDeLaEmpresa);
  sessionStorage.setItem('Tiempo_de_laborar_en_la_empresa_en_años', TiempoDeLaborarEnLaEmpresaEnAnios);
  sessionStorage.setItem('Ocupacion', Ocupacion);
  
  sessionStorage.setItem('Ultimos_4_digitos_de_tarjeta', Ultimos4DigitosDeTarjeta);
  sessionStorage.setItem('Hipoteca_Vigente', HipotecaVigente);
  sessionStorage.setItem('Credito_automotriz_bancario_en_los_ultimos_do', CreditoAutomotrizBancarioEnLosUltimosDosAnios);
  sessionStorage.setItem('CURP', Curp);

  // Temporal data
  sessionStorage.setItem('HomePhone', '55522243678');
}

export function setSessionVariablesUserDetailsCoppel(response = '') {
  const payload = JSON.parse(response);
  const {
    IngresoMensualFijo,
    UltimoGradoDeEstudios,
    NumeroDeDependientes,
    TiempoDeVivirEnDomicilio,
    TipoDeVivienda,
    BancoTarjetaActualDelUsuario,
    Ultimos4DigitosDeTarjeta,
    TieneTarjetaDeCreditoVigente,
    CreditoAutomotrizBancarioEnLosUltimosDosAnios,
    HipotecaVigente,
    Ocupacion,
    TipoDeIndustria,
    NombreDeLaEmpresa,
    TiempoDeLaborarEnLaEmpresaEnAnios,
    NombreCompletoReferenciaFamiliar,
    TelefonoDeReferenciaFamiliar,


  } = payload;

  sessionStorage.setItem('Ingreso_Mensual_Fijo', IngresoMensualFijo);
  sessionStorage.setItem('Ulltimo_grado_de_estudios', UltimoGradoDeEstudios);
  sessionStorage.setItem('Numero_de_dependientes', NumeroDeDependientes);
  sessionStorage.setItem('Tiempo_de_vivir_en_domicilio', TiempoDeVivirEnDomicilio);
  sessionStorage.setItem('Tipo_de_Vivienda', TipoDeVivienda);
  sessionStorage.setItem('activeCard', TieneTarjetaDeCreditoVigente||"No");
  sessionStorage.setItem('Banco_tarjeta_actual_del_usuario', BancoTarjetaActualDelUsuario);
  sessionStorage.setItem('Ultimos_4_digitos_de_tarjeta', Ultimos4DigitosDeTarjeta);
  sessionStorage.setItem('Credito_automotriz_bancario_en_los_ultimos_do', CreditoAutomotrizBancarioEnLosUltimosDosAnios||"No");  
  sessionStorage.setItem('Hipoteca_Vigente', HipotecaVigente||"No");
  sessionStorage.setItem('Ocupacion', Ocupacion);
  sessionStorage.setItem('Tipo_de_Industria', TipoDeIndustria);
  sessionStorage.setItem('Nombre_de_la_empresa', NombreDeLaEmpresa);
  sessionStorage.setItem('Tiempo_de_laborar_en_la_empresa_en_años', TiempoDeLaborarEnLaEmpresaEnAnios);
  sessionStorage.setItem('Nombre_Completo_Referencia_Familiar', NombreCompletoReferenciaFamiliar);
  sessionStorage.setItem('Telefono_de_Referencia_Familiar', TelefonoDeReferenciaFamiliar);
}

export function setSessionVariablesuserMediumCoopel(response = '') {
  const payload = JSON.parse(response);
  const {
    CellPhone,
    HomePhone,
    Email1,
    
  } = payload;

  sessionStorage.setItem('CellPhone', CellPhone);
  sessionStorage.setItem('HomePhone', HomePhone||CellPhone);
  sessionStorage.setItem('Email1', Email1);
}
