import React from 'react';
import {
  TrainingSubtitles,
  TrainingText,
  CardsBtn,
  DivText,
} from '../../assets/styles/Training.styled';

const PymesCardList = ({
  title,
  text,
  subtitle,
  subtitle2,
  subtitle3,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  styleli,
  nextQuestion,
  onClick,
}) => (
    <>
      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {title}
      </TrainingSubtitles>
      <TrainingText
        size="16px"
        className="roboto"
        bot="30px"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        {text}
      </TrainingText>

      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {subtitle}
      </TrainingSubtitles>
      <DivText
        size="16px"
        className="roboto"
        bot="20px"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        <ul>
          <li>{text1} </li>
          <li>{text2} </li>
          <li style={{ listStyleType: styleli }}>{text3}</li>
        </ul>
      </DivText>

      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {subtitle2}
      </TrainingSubtitles>
      <DivText
        top="1px"
        size="16px"
        bot="20px"
        className="roboto"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        <ul>
          <li>{text4} </li>
          <li>{text5}</li>
          <li style={{ listStyleType: styleli }}>{text6} </li>
        </ul>
      </DivText>

      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {subtitle3}
      </TrainingSubtitles>
      <DivText
        top="1px"
        size="16px"
        bot="20px"
        className="roboto"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        <ul>
          <li>{text7} </li>
          <li>{text8} </li>
          <li>{text9} </li>
        </ul>
      </DivText>

      {nextQuestion === '' ? (
        <div style={{ display: 'flex' }}>
          <CardsBtn
            mtop="0"
            mtopw="0"
            mtopmd="0"
            windthLg="100%"
            onClick={onClick}
          >
            Continuar
          </CardsBtn>
        </div>
      ) : null}
    </>
);

export default PymesCardList;
