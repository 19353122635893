import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Rectangle13,
  Rectangle24,
  Vector2,
  Vector3,
  TextoCoach,
  ContentHomeSection,
  UiCard,
} from './Home.styled';
import { ParagrapTwo } from './common.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import ClickButton from '../../Helpers/HookHelpers';
import ImageCoachInicio from '../Training/ImageCoachInicio';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '550px',
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const ModalRecomendacionTraining = ({ textTraining, urlTraining, close }) => {
  const history = useHistory();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);
  const { actions } = ClickButton();

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  const goTrainingBudget = (event) => {
    const click = `IrAlEntrenamiento_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    event.preventDefault();
    sessionStorage.setItem('WO_came_from', actualPath);
    sessionStorage.setItem('Saving_Budget', 'true');
    history.push(urlTraining);
  };

  const actualPath = window.location.pathname;

  console.log('actual path > ', actualPath);

  return (
      <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
         <div style={modalStyle} className={classes.paper}>
             <ParagrapTwo
               fontWeight="700"
               cursor="pointer"
               texttalign="right"
               textalignMobile="right"
               className={close ? 'mx-auto' : 'hidden'}
               onClick={handleClose}
            >
               X
            </ParagrapTwo>
            <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
               <ContentHomeSection leftD="0%" floatD="left" width="48%;"></ContentHomeSection>
               <ImageCoachInicio />
               <ContentHomeSection leftD="0%" floatD="left" width="35%;"></ContentHomeSection>
            </ContentHomeSection>
            <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
               <Rectangle24
                  positionD="absolute"
                  Ptop="125px"
                  PTopM="-65px"
                  Pleft="-10%"
                  background="#6BD5AF"
                  displayM="block"
                  displayD=" "
                  left=" "
                  top=" "
                  width="70px"
                  height="70px"
                  transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
               />
               <Vector2
                  width="55px"
                  left="0"
                  PleftM="35%"
                  height="65px"
                  background="#91D770"
                  radius="40%"
                  transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                  positionD="absolute"
                  Pleft="17%"
                  Ptop="-5px"
                  displayM="block"
               />
               <Vector3
                  position="absolute"
                  left=" "
                  top=" "
                  Ptop="10px"
                  Pleft="44%"
                  background=" #E3E660;"
                  transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                  width="35px"
                  height="35px"
                  displayD=" "
                  displayM="none"
               />
               <Rectangle24
                  positionD="absolute"
                  Ptop="100px"
                  Pleft="74%"
                  PLeftM="-78%"
                  PTopM="-32%"
                  background="#BFDD6A"
                  displayM="block"
                  displayD=" "
                  left=" "
                  top=" "
                  width="30.94px"
                  height="30.94px"
                  transform="rotate(46.41deg)"
               />
               <Vector2
                  width="50px"
                  widthM="28.81px"
                  heightM="35.02px"
                  left=""
                  height="60px"
                  background="#6BD5AF"
                  radius="40%"
                  transform="rotate(-8.34deg)"
                  positionD="absolute"
                  Pleft="52%"
                  PleftM="-15%"
                  PTopM="-10%"
                  MtopM="-15%"
                  Ptop="135px"
                  displayM="block"
               />
               <Vector3
                  position="absolute"
                  left=" "
                  top=" "
                  Ptop="85px"
                  Pleft="14%"
                  PleftM="-3%"
                  background="#E3E660"
                  transform="rotate(-15.06deg)"
                  width="35px"
                  height="35px"
                  displayD=" "
                  displayM="block"
               />
               <Rectangle24
                  widthM="35px"
                  heightM="35px"
                  mLeftM="0"
                  mTopM="18%"
                  positionD="absolute"
                  Pleft="70%"
                  Ptop="0"
                  displayD=""
                  width="60px"
                  height="60px"
                  left=" "
                  top=" "
                  background="#91D770"
                  transform="rotate(-17.24deg)"
               />

               <UiCard positionM=" " heightM="150px" marginTop="120px" marginTopM="120px">
                  <Rectangle13
                     boxSha=" "
                     margin="auto"
                     bottomM="0px"
                     displayM="block"
                     position=" "
                     topM="0px"
                     height="156px"
                     width="100%"
                     widthm="100%"
                     padding="19px 25px 20px 21px"
                  >
                     <TextoCoach height="auto" className="roboto" width="auto">
                        <b>¡Hola! Soy Ana, tu coach financiero.</b>
                        <br />
                        <br />
                     </TextoCoach>
                     <TextoCoach height="auto" align="left" className="roboto" width="auto">
                        Para continuar con este entrenamiento, te recomendamos que realices primero el entrenamiento de {textTraining}.
                     </TextoCoach>
                  </Rectangle13>
               </UiCard>
            </ContentHomeSection>
            <CardsBtn min_w="100%" min_web="100%" onClick={(e) => goTrainingBudget(e)}>
               <b>Ir al entrenamiento</b>
            </CardsBtn>
            <br />
            <br />
            <Link to="/the-training-feed/">
               <CardsBtn back="#FFFFFF" color="#F64282" border="2px solid #F64282" min_w="100%" min_web="100%">
                  <b>Ir al inicio</b>
               </CardsBtn>
            </Link>
         </div>
      </Modal>
  );
};

export default ModalRecomendacionTraining;
