/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import {
  TrainingQuestion,
  TrainingDivSection,
  QuestionsDivs,
  CardsBtn,
  ErrorDiv,
  DivRespDP,
  TrainingSubtitles,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import { Container, Col7 } from '../../components/common/common.styled';
import LoadingWo from '../../components/Training/loadingWo';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import Starttraining from '../../components/Training/Starttraining';

const DomicileDebts = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  customAdvice,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const { sendMessage } = SendNotificationFTAndCC();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz] = useState([{ routine: '', active: '' }]);
  const [editDomicilio, setEditDomicilio] = useState('');
  const [letsEditDomicilio, setLetsEditDomicilio] = useState(false);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        const answers = {
          3: '',
          4: '',
          5: '',
          6: '',
          7: '',
          8: '',
        };

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log(routines);
          let contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            if (arrayResponseRutine.length === 11) {
              const insertQuestion = {
                idQuestion: 21,
                idType: 92,
                questionName: 'Ingresa Dirección',
                questionOrder: 1,
                questionType: 'OpenField',
                responseValue: '',
              };
              arrayResponseRutine.unshift(insertQuestion);
            }
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;

              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              const { questionName } = arrayResponseRutine[i];

              console.log('!!!### responseUser >>>', responseUser);
              console.log('!!!### questionOrder >>>', questionOrder);
              console.log('!!!### questionName >>>', questionName);

              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (parseInt(idTypeQuestion) === 89) {
                stopRoutine = true;
                console.log('telefono casa ', questionName, responseUser);
              }
            }

            if (
              answers[3]
              && answers[4]
              && answers[5]
              && answers[6]
              && answers[7]
              && answers[8]
            ) {
              setOrderRutine(nextOrderRoutine);
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [Domicilio, setDomicilio] = useState(
    sessionStorage.getItem('Suburb') || '',
  );
  const [TimeDom, setTimeDom] = useState(
    sessionStorage.getItem('Tiempo_de_vivir_en_domicilio') || '',
  );
  const [texterror] = useState({
    email: '',
    firstname: '',
    secondname: '',
    lastname: '',
    secondlastname: '',
    day: '',
    month: '',
    year: '',
  });

  const [error] = useState({
    genero: false,

    landline: false,
  });
  const [alertaDom, setAlerta] = useState(false);
  const [form, setForm] = useState({
    directionMaps: '',
    mapsGeo: '',
    calle: sessionStorage.getItem('Street') || '',
    numext: sessionStorage.getItem('ExtNo') || '',
    numint: sessionStorage.getItem('IntNo') || '',
    cp: sessionStorage.getItem('ZipCode') || '',
    colonia: sessionStorage.getItem('Suburb') || '',
    municipio: sessionStorage.getItem('Municipality') || '',
    estado: sessionStorage.getItem('State') || '',
    telcasa: sessionStorage.getItem('HomePhone') || '',
  });
  const ValidCP = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
  };
  const ValidPhone = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementById(id);
    if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'secondname') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.name);
    switch (e.target.name) {
      case 'telcasa':
        // setwriteTel('false');
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      case 'directionMaps':
        // // setwriteDom('false');
        console.log('Autocomplete');
        break;
      case 'calle':
        // setwriteDom('false');
        console.log('calle');
        break;
      case 'colonia':
        // setwriteDom('false');
        sessionStorage.setItem('Suburb', e.target.value);
        if (questions.length !== 0) {
          const arrtemp = [...questions];
          arrtemp[1] = { ...arrtemp[1], value: e.target.value };
          setQuestions(arrtemp);
        }
        break;
      case 'numext':
        // setwriteDom('false');
        console.log('numext');
        break;
      case 'numint':
        // setwriteDom('false');
        console.log('numint');
        break;
      case 'cp':
        // setwriteDom('false');
        ValidCP(e.target.name, e.target.value, e.target.id);
        console.log('cp');
        break;
      case 'municipio':
        console.log('municipio');
        break;
      case 'estado':
        console.log('estado');
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  // funcionalidad
  useEffect(() => {
    if (orderRutine === currentRoutine) {
      if (form.mapsGeo !== '') {
        handleChangeQuestionsSend(2, 'mapsGeo', form.mapsGeo);
      } else if (form.numint !== '') {
        handleChangeQuestionsSend(5, 'numint', form.numint);
      }
    }
  }, [form]);

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 9:
        setTimeDom(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeTipoVivienda' });
        break;
      case 10:
        gsap.to(window, { duration: 1, scrollTo: '#activeTelefonoCasa' });
        break;
      default:
        console.log('default break handleChangeCatOption case 2');
        break;
    }
  };

  const handleChangeAddress = (key, val) => {
    let numQuestion;
    switch (key) {
      case 'postal_code':
        key = 'cp';
        numQuestion = 6;
        break;
      case 'route':
        key = 'calle';
        numQuestion = 3;
        break;
      case 'street_number':
        key = 'numext';
        numQuestion = 4;
        break;
      case 'estado':
        key = 'estado';
        numQuestion = 9;
        break;
      case 'Municipality':
        key = 'municipio';
        numQuestion = 8;
        break;
      case 'Suburb':
        if (form.colonia === '') {
          sessionStorage.setItem('Suburb', val);
          form.colonia = val;
        }
        key = 'colonia';
        numQuestion = 7;
        break;
      default:
        break;
    }
    if (key !== undefined) {
      handleChangeQuestionsSend(numQuestion, key, val, 'OpenField');
    }
    setForm({
      ...form,
      [key]: val,
    });
  };

  const handleChangeDomicile = () => {
    setLetsEditDomicilio(false);
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone'),
      trigger: 'wa_cc_4',
    });
    const click = `GuardarDomicilio_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setEditDomicilio(true);
    setAlerta(false);
    setDomicilio('Continuar');
    createJsonInput(Rutine, questions);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#activeAniosEnDomicilio' });
    setOrderRutine(nextOrderRoutine);
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        ...form,
        directionMaps: '',
        mapsGeo: '',
        calle: sessionStorage.getItem('Street') || '',
        numext: sessionStorage.getItem('ExtNo') || '',
        numint: sessionStorage.getItem('IntNo') || '',
        cp: sessionStorage.getItem('ZipCode') || '',
        colonia: sessionStorage.getItem('Suburb') || '',
        municipio: sessionStorage.getItem('Municipality') || '',
        estado: sessionStorage.getItem('State') || '',
        telcasa: sessionStorage.getItem('HomePhone') || '',
      });
      setDomicilio(sessionStorage.getItem('Suburb') || '');
      setTimeDom(sessionStorage.getItem('Tiempo_de_vivir_en_domicilio') || '');
      const phone = sessionStorage.getItem('HomePhone') || '';
      if (
        phone !== ''
        || (sessionStorage.getItem('Street')
          && sessionStorage.getItem('ExtNo')
          && sessionStorage.getItem('ZipCode')
          && sessionStorage.getItem('Suburb'))
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    if (letsEditDomicilio) {
      setOrderRutine(4);
    }
  }, [letsEditDomicilio]);

  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [lapizTelfijo, setLapizTelfijo] = useState(
    sessionStorage.getItem('TipoCuentaDeposito') || '',
  );
  useEffect(() => {
    if (lapizTelfijo !== '') {
      setLapizTelfijo('Quitar');
    }
  }, [lapizTelfijo]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine && Domicilio === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeDomicilioForm' });
      }
    }, 4000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log(
      'mostrar loader >>>',
      recoveryRoutine,
      sessionStorage.getItem('idUser'),
      sessionStorage.getItem('idCont'),
    );
    return (
      <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        {!loaderWait ? (
          <>
            <div id="activeDomicilioForm"></div>

            {customAdvice && customAdvice.active ? (
              <Starttraining
                title={customAdvice.title}
                text={customAdvice.text}
                hiddeAna={true}
              />
            ) : (
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard="cardOne"
                classTop="topOne1"
                text="Continuemos"
                hora={hora}
              />
            )}
            <TrainingQuestion width="100%" wiw="100%" mt="0">
              <QuestionsDivs
                padd="0"
                txta="left"
                width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
                widthweb="100%"
                hei="auto"
                margin="0 auto auto"
                bc="#fafafa"
              >
                <form className="">
                  <Container
                    width="80%"
                    mt="0px"
                    widthMobile="90%"
                    mtMobile="1rem"
                    displayweb={
                      (orderRutine === currentRoutine && Domicilio === '')
                      || (lapiz[0].routine === 7
                        && lapiz[0].active === 1
                        && editDomicilio !== true)
                      || letsEditDomicilio
                        ? 'flex'
                        : 'none'
                    }
                    displaymobile={
                      (orderRutine === currentRoutine && Domicilio === '')
                      || (lapiz[0].routine === 7
                        && lapiz[0].active === 1
                        && editDomicilio !== true)
                      || letsEditDomicilio
                        ? 'flex'
                        : 'none'
                    }
                  >
                    <ImageCoachInicio
                      classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                      topMobile="35px"
                    />
                    <Col7
                      maxwidth="100%"
                      padding=" "
                      className="cardPrincipal1"
                    >
                      <TrainingDivSection
                        hew="auto"
                        className="topOne1"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                        className={
                          (orderRutine === currentRoutine
                            && Domicilio === '')
                          || (lapiz[0].routine === 7
                            && lapiz[0].active === 1
                            && editDomicilio !== true)
                          || letsEditDomicilio
                            ? ''
                            : 'hidden'
                        }
                      >
                        <QuestionsDivs
                          padd="0"
                          txta="left"
                          width="100%"
                          widthweb="100%"
                          hei="auto"
                          margin="0 auto auto"
                          bc="#F3F3F3"
                        >
                          <form>
                            <div
                              form={form}
                              handleChange={handleChange}
                              className="domicile-container"
                              id="domicileForm"
                            />
                            <TrainingSubtitles
                              size="20px"
                              sizew="20px"
                              fw="600"
                              align="left"
                              alignweb="left"
                              className="cabin"
                            >
                              Ingresa tu domicilio para continuar
                            </TrainingSubtitles>
                            <div>
                              {orderRutine === currentRoutine
                              || letsEditDomicilio ? (
                                <InputsOrder
                                  handleChangeAddress={handleChangeAddress}
                                  handleChangeQuestionsSend={
                                    handleChangeQuestionsSend
                                  }
                                  handleChangeRutine={handleChangeRutine}
                                  orderRutine={orderRutine}
                                  step="0-8"
                                  handleChangeCatOption={handleChangeCatOption}
                                  form={form}
                                  handleChange={handleChange}
                                  currentTraining={currentTraining}
                                />
                                ) : null}
                            </div>
                            <ErrorDiv
                              className={alertaDom === true ? '' : 'hidden'}
                            >
                              Parece que te falto llenar algún campo
                            </ErrorDiv>
                            <CardsBtn
                              type="button"
                              min_w="100%"
                              min_web="100%"
                              onClick={
                                form.calle === ''
                                || form.numext === ''
                                || form.cp === ''
                                || form.colonia === ''
                                  ? () => setAlerta(true)
                                  : () => handleChangeDomicile()
                              }
                            >
                              Guardar domicilio
                            </CardsBtn>
                          </form>
                        </QuestionsDivs>
                      </TrainingDivSection>
                    </Col7>
                  </Container>
                </form>
              </QuestionsDivs>
            </TrainingQuestion>
          </>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        )}
        <div id="activeAniosEnDomicilio"></div>
        {!letsEditDomicilio ? (
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                Domicilio !== '' && TimeDom === '' ? 'iconEdit1' : 'hidden'
              }
              src={editIcon}
              onClick={() => {
                setLetsEditDomicilio(true);
                setLoaderWait(false);
              }}
            />
            <CardTwo
              classCard={
                Domicilio !== ''
                && (editDomicilio === true
                  || !(lapiz[0].routine === 7 && lapiz[0].active === 1))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={`${form.calle} ${form.numext} ${form.numint}`}
              text2={`${form.cp} ${form.colonia}`}
              text3={form.municipio}
              text4={form.estado}
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        ) : null}
        <div id="domicileYears"></div>
        <div id="irDomicilio"></div>
        <div id="activeTipoVivienda"></div>
        <div id="irVivienda"></div>
        <div id="activeTelefonoCasa"></div>
        <div id="activeSigRoutine"></div>
      </div>
  );
};

export default DomicileDebts;
