import React, { Fragment, useLayoutEffect, useState } from "react";

function BarraScroll() {
  const [interval] = useState(15000);
  const [checkScroll, setCheckScroll] = useState(true);

  useLayoutEffect(() => {
    window.onscroll = function () {
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        const nuevoScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        const anchop = window.screen.width;
        if (nuevoScroll > 0 && checkScroll) {
          if (anchop <= 3000 && anchop >= 768) {
            const rests = 409 - nuevoScroll;
            if (nuevoScroll >= 334) {
              const grayline = document.getElementById("line-percent-gray");
              if (grayline !== undefined && grayline !== null) {
                grayline.style.marginTop = "45px";
              }
              const pinkline = document.getElementById("line-percent-pink");

              if (pinkline !== undefined && pinkline !== null) {
                pinkline.style.marginTop = "45px";
                setCheckScroll(false);
              }
            } else {
              const grayline = document.getElementById("line-percent-gray");
              if (grayline !== undefined && grayline !== null) {
                grayline.style.marginTop = `${rests}px`;
              }
              const pinkline = document.getElementById("line-percent-pink");

              if (pinkline !== undefined && pinkline !== null) {
                pinkline.style.marginTop = `${rests}px`;
                setCheckScroll(false);
              }
            }
          } else {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "40px";
            }
            const pinkline = document.getElementById("line-percent-pink");

            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "40px";
              setCheckScroll(false);
            }
          }
        } else {
          setCheckScroll(true);
          const tamScreen = window.screen.width;
          if (tamScreen > 1024) {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "404px";
            }
            const pinkline = document.getElementById("line-percent-pink");
            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "404px";
            }
          } else {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "303px";
            }
            const pinkline = document.getElementById("line-percent-pink");
            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "303px";
            }
          }
        }
      } else {
        setCheckScroll(true);
        const nuevoScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        const anchop = window.screen.width;
        if (nuevoScroll > 0 && checkScroll) {
          if (anchop <= 1468 && anchop >= 769) {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "4.8%";
            }
            const pinkline = document.getElementById("line-percent-pink");

            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "4.8%";
              setCheckScroll(false);
            }
          } else {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "40px";
            }
            const pinkline = document.getElementById("line-percent-pink");

            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "40px";
              setCheckScroll(false);
            }
          }
        } else {
          setCheckScroll(true);
          const tamScreen = window.screen.width;
          if (tamScreen >= 1026) {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "404px";
            }
            const pinkline = document.getElementById("line-percent-pink");
            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "404px";
            }
          } else if (tamScreen >= 768 && tamScreen <= 1025) {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "410px";
            }
            const pinkline = document.getElementById("line-percent-pink");
            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "410px";
            }
          } else {
            const grayline = document.getElementById("line-percent-gray");
            if (grayline !== undefined && grayline !== null) {
              grayline.style.marginTop = "303px";
            }
            const pinkline = document.getElementById("line-percent-pink");
            if (pinkline !== undefined && pinkline !== null) {
              pinkline.style.marginTop = "303px";
            }
          }
        }
      }
    };
  }, []);
  const inactivityTime = () => {
    window.setInterval(() => {}, interval);
  };
  window.onload = function () {
    inactivityTime();
  };

  return <Fragment></Fragment>;
}

export default BarraScroll;
