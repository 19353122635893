import React, { Fragment } from 'react';
import {
  Image,
} from '../common/common.styled';
import {
  Images,
  GraphicInvTest,
  GraphicInvTestColor,
  GraphicText,
  GraphicDiv,
  GraphicContainer,
  BaseTermometer,
} from '../../assets/styles/Training.styled';
import completed from '../../assets/img/anacoach/graphic/100%.png';
import medium from '../../assets/img/anacoach/graphic/50%.png';
import cero from '../../assets/img/anacoach/graphic/0%.png';
import user1 from '../../assets/img/anacoach/graphic/userPos.png';

function GraphicPercent({ datum }) {
  console.log(datum);
  return (
        <Fragment className='d-flex flex-column'>
            <GraphicContainer m='20% 20% 20% 20%'>
                <GraphicText
                    className="roboto"
                    color="#07210A"
                    align="center"
                    alignweb="center !important"
                    size="14px"
                    sizew="14px"
                    lh="120%"
                    ml="60px"
                    top="-25px"
                    weight="bold"
                >
                    {datum.key}
                </GraphicText>
                <Images
                    src={completed}
                    top="-20px"
                    topweb="-30px"
                    left="0"
                    leftw="0"
                    width="50.21px"
                    position="absolute"
                    className="img-fluid hidden"
                ></Images>
                <Images
                    src={medium}
                    top="105px"
                    topweb="105px"
                    left="0"
                    leftw="0"
                    width="50.21px"
                    position="absolute"
                    className="img-fluid hidden"
                ></Images>
                <Images
                    src={cero}
                    top="240px"
                    topweb="240px"
                    left="0"
                    leftw="0"
                    width="50.21px"
                    position="absolute"
                    className="img-fluid hidden"
                ></Images>
                <GraphicText
                    className="roboto"
                    color="#07210A"
                    align="left"
                    alignweb="left !important"
                    size="10px"
                    sizew="10px"
                    lh="120%"
                    ml="42px"
                    weight="normal"
                >
                    100%
                </GraphicText>
                <GraphicText
                    className="roboto"
                    color="#07210A"
                    align="left"
                    alignweb="left !important"
                    size="10px"
                    sizew="10px"
                    lh="120%"
                    ml="42px"
                    top="135px"
                    weight="normal"
                >
                    50%
                </GraphicText>
                <GraphicText
                    className="roboto"
                    color="#07210A"
                    align="left"
                    alignweb="left !important"
                    size="10px"
                    sizew="10px"
                    lh="120%"
                    ml="42px"
                    top="268px"
                    weight="normal"
                >
                    0%
                </GraphicText>
                <GraphicText
                    className="roboto"
                    color="#07210A"
                    align="center"
                    alignweb="center !important"
                    size="10px"
                    sizew="10px"
                    lh="120%"
                    ml="105px"
                    top="225px"
                    weight="bold"
                >
                    Tú estás <br />aquí
                </GraphicText>
                <GraphicText
                    className="roboto"
                    color="#07210A"
                    align="center"
                    alignweb="center !important"
                    size="14px"
                    sizew="14px"
                    lh="120%"
                    ml="112px"
                    top="160px"
                    weight="bold"
                >
                    {datum.y}%
                </GraphicText>
                <GraphicDiv>
                    <GraphicInvTest bcolor={`${datum.color}5c`} h={`${100 - datum.y}%`}></GraphicInvTest>
                    <GraphicInvTestColor bcolor={datum.color} h={`${datum.y}%`}></GraphicInvTestColor>
                </GraphicDiv>
                <Image src={user1} mwidth="27px" width="27px" margin="-180px auto auto 110px" top="110px" left="10%" mleft="0px" mtop="75px" />
                <BaseTermometer mt="-21px" ml="66px" bcolor={datum.color}></BaseTermometer>
            </GraphicContainer>
        </Fragment>
  );
}

export default GraphicPercent;
