/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  ModalBackground, ModalBackCC, ModalHeader, ModalBody,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Title1,
} from './Home.styled';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';
import Ana from '../../assets/img/anacoach/anavalordinero.png';
import { Image } from './common.styled';

const ModalStori = (props) => {
  const [modalState, setModalState] = useState('flex');
  const handleClickModal = () => {
    setModalState('none');
  };
  return (
    <>
      <ModalBackground display={ modalState }>
        <ModalBackCC height={'auto'} height768={'auto'}>
          <Rectangle23 position="absolute" background="#91D770" Mleft="-30px" Mtop="-30px" width="60px" height="60px" left=" " />
          <Rectangle23 position="absolute" background="#E3E660" Mleft="90px" Mtop="-9px" width="28px" height="32px" transform="rotate(45deg)" left="58px" top="-27px" />
          <Rectangle23 position="absolute" background="#6BD5AF" Mleft="190px" Mtop="-30px" width="30.36px" height="30px" transform="rotate(45deg)" left="171px" />
          <Rectangle23 position="absolute" background="#E3E660" Mleft="280px" Mtop="40px" width="42.53px" height="45.22px" transform="rotate(45deg)" left=" " />
          <div style={{
            position: 'absolute', right: '20px', top: '10px', fontSize: '18px', cursor: 'pointer', fontWeight: 'bold',
          }} onClick={props.openMod === undefined || props.openMod === null ? () => setModalState('none') : props.openMod}>
            <CloseIcon/>
          </div>
          <ModalHeader heightM={ '35%' } height={ '35%' } mtopM='2%'>
            <Image src={Ana} width='80px' mwidth='70px' marginTop='10px' marginTopM='5px' bottom='15px'/>
            <Title1 size="22px" aling="center" mt=" " sizeM="17px" mleftM=" " mtopM=" " heightM=" ">
                ¡Buena elección!
            </Title1>
          </ModalHeader>
          <ModalBody mtop="15%">
            <span style={{
              padding: '2px auto', fontSize: '14px', textAlign: 'center',
            }}>
              Continua tu solicitud con la App de Stori,
              revisa el mail que te fue enviado a tu correo electronico.
            </span>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CardsBtn
                font='14px'
                mtop='10px'
                mtopw='20px'
                mtopmd='15px'
                padd='10px 5px'
                onClick={() => handleClickModal() }
              >
                Entendido
              </CardsBtn>
            </div>
          </ModalBody>
        </ModalBackCC>
      </ModalBackground>
    </>
  );
};

export default ModalStori;
