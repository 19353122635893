const jsonVariableDias = {
  Vista: 365,
  '30 días': 12,
  '90 días': 4,
  '180 días': 2,
  '31 días': 12,
  '91 días': 4,
  '182 días': 2,
  '360 días': 1,
};
const jsonVariableYears = {
  '1 año': 1,
  '2 años': 2,
  '3 años': 3,
  '5 años': 5,
  '10 años': 10,
  '20 años': 20,
  '30 años': 30,
};

const getProductMeetCondition = (monto, plazo, product) => {
  monto = parseFloat(monto);
  if (monto >= product.montoMin) {
    let resultado = 0;
    if (jsonVariableDias[plazo] !== undefined) {
      resultado = (monto * (parseFloat(product.tasa) / 100))
          / jsonVariableDias[plazo]
        + monto;
    } else if (jsonVariableYears[plazo] !== undefined) {
      resultado = monto
          * (parseFloat(product.tasa) / 100)
          * jsonVariableYears[plazo]
        + monto;
    }
    return {
      IDProduct: product.IDProduct,
      Instrumento: product.Name,
      resultado,
      taza: `${product.tasa}%`,
      url: product.ClickOut || '',
      caracteristicas: product.benefits,
      ImageURL: product.ImageURL,
    };
  }
  return false;
};

const sortJSON = (data, key, orden) => data.sort((a, b) => {
  const x = a[key];
  const y = b[key];
  if (orden === 'asc') {
    return x < y ? -1 : x > y ? 1 : 0;
  }
  if (orden === 'desc') {
    return x > y ? -1 : x < y ? 1 : 0;
  }
});

export const getProductInversion = (monto, plazo) => {
  monto = monto.replace('$', '').replace(/,/g, '').trim();
  let productos = [];
  const products = JSON.parse(sessionStorage.getItem('productsFirstInvestment')) || '';
  for (const product of products) {
    const newProduct = getProductMeetCondition(monto, plazo, product);
    if (newProduct) {
      productos.push(newProduct);
    }
  }
  productos = sortJSON(productos, 'resultado', 'desc');
  // Ej. var oJSON = sortJSON(elJSON, 'nombre', 'asc');
  // Ej. var oJSON = sortJSON(elJSON, 'sexo', 'desc');
  if (productos.length > 0) return productos;
  return [
    {
      Instrumento: '',
      resultado: 'No disponible',
      taza: '',
      url: '',
    },
  ];
};
