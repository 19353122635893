import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { Image } from '../../assets/styles/Home.styled';

import {
  Container1,
  ContentHomeSection,
  Shapes,
  Title2,
  Subtitle2,
} from '../../components/common/Home.styled';
import {
  Button, Container, Col6,
} from '../../components/common/common.styled';
import vexi2 from '../../assets/img/lpVexi/tarjetaVexi2.svg';
import vexi3 from '../../assets/img/lpVexi/tarjetaVexi3.svg';
import vexi4 from '../../assets/img/lpVexi/tarjetaVexi4.svg';
import WOlayout from '../../components/layouts/WOlayout';
import { getItemSessionStorage } from '../../Helpers/general';

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function SuccessVexi({ location }) {
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);
  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  const url = new URL(window.location.href);
  const errorMessage = url.searchParams.get('error');
  const hasError = errorMessage !== null;
  const userName = `${getItemSessionStorage('FirstName')} ${getItemSessionStorage('MiddleName')}`;
  const errorMessages = {
    title: [
      'Lo sentimos, no podemos ofrecerte una Tarjeta Vexi',
      'Lo sentimos, hubo un error en tu trámite',
    ],
    description: [
      'Pero ya estás en nuestra lista y te contactaremos en cuanto podamos para ayudarte nuevamente en el trámite de tu tarjeta.',
      `No pudimos procesar tu solicitud porque [${errorMessage}], por favor vuelve a intentarlo más adelante.`,
    ],
  };
  const handleOk = () => window.location.replace('/the-training-feed/');
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);
  return (
      <WOlayout backgroundColor="#FAFAFA" pathname={window.location.pathname} headerType="HeaderLP" >
        {hasError
          ? <Container1>
            <Shapes id="Shapes2" key={2} top=" " left=" " heightD="800px" height="auto" heightD480="auto" width="100%" position=" " background="#FAFAFA">
                <ContentHomeSection topMm320="85px" topDW="85px" leftD=" " floatD=" " displayD="none" displayM="block">
                  <Title2
                    sizem="20px"
                    top={heigthBrowser >= 550 ? '30px' : '85px'}
                    topD="-5%" className="roboto"
                    color = "#C518DD"
                  >
                    {errorMessage.length ? errorMessages.title[1] : errorMessages.title[0]}
                  </Title2>
                  <Subtitle2
                    className="roboto"
                    bottom="17px"
                    top="10px"
                  >
                    {errorMessage.length ? errorMessages.description[1] : errorMessages.description[0]}
                  </Subtitle2>
                    <div style={{ textAlign: 'center' }} >
                      <Image src={vexi3} maxwidth="430px" width="80%" position="relative" left="0" top="5%" displayMobile="" />
                    </div>
                    <div style={{ margin: 'auto', width: '90%' }}>
                      <Button className="d-block roboto" width="90%" mb="120px" bg="#C518DD" fw = "400"
                        onClick = {handleOk}>
                          Entendido
                      </Button>
                    </div>
                </ContentHomeSection>
                <div className="web" style={{ width: '100%' }}>
                    <Container width="80%" mt="6%" widthMobile="100%" mb="0%" mtMobile="6%">
                        <Col6 className={heigthBrowser >= 550 ? '' : 'hidden'} pt="0px" flex ="0 0 100%" maxwidth="100%">
                            <Image src={vexi3} maxwidth="430px" hei = "270px" width="25%" position="absolute" left="0"
                            top="7%" displayMobile="none"/>
                        </Col6>
                        <Col6 pt="0px" display="flex" displayM="none">
                            <hr style={{
                              border: 'none', color: '#DADADA', borderLeft: '2px solid #DADADA', height: '500px', width: '2px', marginRight: '20px',
                            }}></hr>
                        <div style={{ margin: 'auto', width: '86.97%' }} className = "ml-5 pl-lg-5" >
                        <Title2
                          sizeD="35px"
                          top={heigthBrowser >= 550 ? '85px' : '45px'}
                          topD="-20%"
                          className="roboto"
                          color = "#C518DD"
                        >
                          {errorMessage.length ? errorMessages.title[1] : errorMessages.title[0]}
                        </Title2>
                        <Subtitle2
                          className="roboto"
                          bottom="17px"
                          top="10px"
                          width = "100%"
                        >
                          {errorMessage.length ? errorMessages.description[1] : errorMessages.description[0]}
                        </Subtitle2>
                        <Button
                          className="d-block roboto"
                          bg="#C518DD" mb="0" fw = "400"
                          onClick={handleOk}>
                          Entendido
                        </Button>
                        </div>
                        </Col6>
                    </Container>
                </div>
            </Shapes>
          </Container1>
          : <Container1>
            <Shapes id="Shapes2" key={2} top=" " left=" " heightD="800px" height="auto" heightD480="auto" width="100%" position=" " background="#FAFAFA">
                <ContentHomeSection topMm320="85px" topDW="85px" leftD=" " floatD=" " displayD="none" displayM="block">
                    <Title2 sizem="20px" top={heigthBrowser >= 550 ? '30px' : '85px'} topD="-5%" className="roboto"
                      color = "#C518DD">
                      <div className = "d-flex align-items-center justify-content-between">
                        ¡Felicidades {userName}!
                        <Image src={vexi4} maxwidth="430px" width="50px" position=" static"
                          mlM = "4pxs" left="0" top="5%" displayMobile="" />                        </div>
                    </Title2>
                    <Subtitle2 className="roboto" bottom="17px" top="10px">
                      Ya tienes una tarjeta preaprobada, solo completa la solicitud para recibirla.
                    </Subtitle2>
                    <div style={{ textAlign: 'center' }}>
                      <Image src={vexi2} maxwidth="430px" width="80%" position="relative"
                        mlM = "4pxs" left="0" top="5%" displayMobile="" />
                    </div>
                    <div style={{ margin: 'auto', width: '90%' }}>
                      <Button className="d-block roboto" width="90%" mb="120px" bg="#C518DD" fw = "400"
                        onClick = {handleOk}>
                        Continuar
                      </Button>
                    </div>
                </ContentHomeSection>
                <div className="web" style={{ width: '100%' }}>
                  <Container width="86%" mt="6%" widthMobile="100%" mb="0%" mtMobile="6%">
                    <Col6 className={heigthBrowser >= 550 ? '' : 'hidden'} pt="0px" flex ="0 0 100%"
                      maxwidth="100%" ptMd = "6%">
                      <Image src={vexi2} maxwidth="430px" hei = "270px" width="25%" position="absolute" left="0" top="8%" displayMobile="none" />
                    </Col6>
                    <Col6 pt="0px" display="flex" displayM="none">
                      <hr style={{
                        border: 'none', color: '#DADADA', borderLeft: '2px solid #DADADA', height: '500px', width: '2px', marginRight: '20px',
                      }}></hr>
                      <div style={{ margin: 'auto', width: '86.97%' }} className = "ml-5 pl-lg-5">
                        <Title2 sizeD="35px" top={heigthBrowser >= 550 ? '85px' : '45px'} topD="-20%" className="roboto"
                          color = "#C518DD">
                          <div className = "d-flex align-items-center justify-content-between">
                              ¡Felicidades {userName}!
                              <Image src={vexi4} maxwidth="430px" width="70px" hei = " "
                                position="static" left="2%" top="0%" displayMobile="none" />
                          </div>
                        </Title2>
                        <Subtitle2 className="roboto" bottom="17px" top="10px" width = "100%">
                          Ya tienes una tarjeta preaprobada, solo completa la solicitud para recibirla.
                        </Subtitle2>
                        <Button className="d-block roboto" bg="#C518DD" mb="0" fw = "400"
                          width = "0px" onClick = {handleOk}>
                          Continuar
                        </Button>
                      </div>
                    </Col6>
                  </Container>
                </div>
            </Shapes>
          </Container1>
        }
      </WOlayout>
  );
}

export default SuccessVexi;
