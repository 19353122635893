/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// assets
import { useLazyQuery } from '@apollo/client';
import bancomer from '../../assets/img/anacoach/bancomer.png';
import amex from '../../assets/img/anacoach/amex.png';
import santander from '../../assets/img/anacoach/santander.png';
import scotia from '../../assets/img/anacoach/scotia.png';
import citi from '../../assets/img/anacoach/citi.png';
import digitos from '../../assets/img/anacoach/4digitos.png';
import editIcon from '../../assets/img/anacoach/editar1.png';
import InputsOrder from '../../components/common/InputsOrder';
import Authenticate from '../Training/Authenticate';

import Banks from '../Training/Banks';

import {
  Images,
  TrainingQuestion,
  QuestionsDivs,
  QuestionsFlex,
  QuestionsText,
  QuestionsTextVideo,
  QuestionsDivsbarVideo,
  CardsBtn,
  CardsBtn1,
  ImageCard4D,
  // DivLoader,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import VideoQuestion from '../../components/common/VideoQuestion';

const UserAuthenticationVideo = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  checkBCReport = false,
  createSheet = false,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [Vivienda] = useState(
    sessionStorage.getItem('Tipo_de_Vivienda') || '',
  );
  const [TelCasa] = useState(
    sessionStorage.getItem('HomePhone') || '',
  );
  const [rfc] = useState(sessionStorage.getItem('RFC') || '');
  const [responseBuro, setResponseBuro] = useState('');
  const [despues, setDespues] = useState('');
  const [autenticoAhorro, setAutenticoAhorro] = useState('');
  const [TieneTarjeta, setTieneTarjeta] = useState('');
  const [bancoActual, setBancoActual] = useState(
    sessionStorage.getItem('Banco_tarjeta_actual_del_usuario') || '',
  );
  const [autenticado, setAutenticado] = useState('');
  const [digitosCard2, setdigitosCard2] = useState(
    sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
  );
  const [digitSetNext, setDigitSetNext] = useState(false);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editBanco, setEditBanco] = useState('');
  const [writeCard, setWriteCard] = useState('');
  const [triedAut, setTriedAut] = useState(1);

  const [digitosCard, setdigitosCard] = useState(
    sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
  );

  // modal banks
  const [modalBanks, setModalBanks] = useState(false);
  const [seeCards, setSeeCards] = useState(false);

  const [form, setForm] = useState({
    firstname: sessionStorage.getItem('FirstName') || '',
    lastname: sessionStorage.getItem('FathersLastName') || '',
    secondname: sessionStorage.getItem('MiddleName') || '',
    email: sessionStorage.getItem('Email1') || '',
    secondlastname: sessionStorage.getItem('MothersLastName') || '',
    day: sessionStorage.getItem('day') || Date.day,
    month: sessionStorage.getItem('month') || Date.month,
    year: sessionStorage.getItem('year') || Date.year,
    cel: sessionStorage.getItem('CellPhone') || '',
    directionMaps: '',
    mapsGeo: '',
    calle: sessionStorage.getItem('Street') || '',
    numext: sessionStorage.getItem('ExtNo') || '',
    numint: sessionStorage.getItem('IntNo') || '',
    cp: sessionStorage.getItem('ZipCode') || '',
    colonia: sessionStorage.getItem('Suburb') || '',
    municipio: sessionStorage.getItem('Municipality') || '',
    estado: sessionStorage.getItem('State') || '',
    telcasa: sessionStorage.getItem('HomePhone') || '',
    digitoscard: sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
  });
  // temporal
  const [texterror] = useState({
    email: '',
    firstname: '',
    secondname: '',
    lastname: '',
    secondlastname: '',
    day: '',
    month: '',
    year: '',
    digitoscard: '',
  });

  const [error] = useState({
    genero: false,
    digitoscard: false,
    landline: false,
  });
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      setQuestions([]);
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        console.log('recovery 1 >>>', json);
        console.log('recovery 2 >>>', Object.keys(json).length);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('rutina', routines);
          // setActiveRutine(true);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  if (
                    parseInt(idTypeQuestion) === 39
                    || parseInt(idTypeQuestion) === 9
                  ) {
                    contResponse++;
                  }
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }

            if (contResponse === 2) {
              console.log('tarjeta y banco');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );

              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      console.log('');
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem('intentoAuth') !== null
      && responseBuro !== null
      && !responseBuro.includes(
        'No Coincide con lo reportado en la Base de Datos',
      )
      && bancoActual !== ''
      && digitosCard !== ''
      && despues !== 'Si'
      && orderRutine === currentRoutine
    ) {
      setOrderRutine(nextOrderRoutine);
    }
  }, [responseBuro]);
  // end recovery
  useEffect(() => {
    if (autenticoAhorro === 'Si') {
      setOrderRutine(nextOrderRoutine);
    }
  }, [autenticoAhorro]);

  useEffect(() => {
    if (digitosCard.length === 4 || digitosCard2.length === 4) {
      const click = `ContinuarDigitos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [digitosCard2, digitosCard]);

  useEffect(() => {
    if (despues === 'Si') {
      const click = `NoTengoMiTarjetaALaMano_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [despues]);

  useEffect(() => {
    if (autenticado === 'Si') {
      setOrderRutine(nextOrderRoutine);
    }
  }, [autenticado]);
  useEffect(() => {
    form.firstname = sessionStorage.getItem('FirstName') || '';
    form.lastname = sessionStorage.getItem('FathersLastName') || '';
    form.secondname = sessionStorage.getItem('MiddleName') || '';
    form.email = sessionStorage.getItem('Email1') || '';
    form.secondlastname = sessionStorage.getItem('MothersLastName') || '';
    form.day = sessionStorage.getItem('day') || Date.day;
    form.month = sessionStorage.getItem('month') || Date.month;
    form.year = sessionStorage.getItem('year') || Date.year;
    form.cel = sessionStorage.getItem('CellPhone') || '';
    form.calle = sessionStorage.getItem('Street') || '';
    form.numext = sessionStorage.getItem('ExtNo') || '';
    form.numint = sessionStorage.getItem('IntNo') || '';
    form.cp = sessionStorage.getItem('ZipCode') || '';
    form.colonia = sessionStorage.getItem('Suburb') || '';
    form.municipio = sessionStorage.getItem('Municipality') || '';
    form.estado = sessionStorage.getItem('State') || '';
    form.telcasa = sessionStorage.getItem('HomePhone') || '';
    form.digitoscard = sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '';
  });

  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.name);
    switch (e.target.name) {
      case 'digitoscard':
        validDigitos(e.target.name, e.target.value, e.target.id);
        console.log('digitoscard');
        break;
      default:
        console.log('handleChange default case');
        break;
    }
  };
  const validDigitos = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (value.length !== 4 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu dígitos';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'digitoscard' || name === 'bank') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  // Scroll next routine
  const handleSubmit = (event) => {
    console.log('previniendo el enter');
    event.preventDefault();
  };
  const GoNextRoutine = (text) => {
    if (text === 'Si') {
      setAutenticado('Si');
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
  };

  const handleChangeCunrrentBank = (bank) => {
    setEditBanco(true);
    setQuestions([]);
    setBancoActual(bank);
    questions.push({
      id: bank,
      question: 1,
      value: bank,
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        ...form,
        digitoscard:
          sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
      });
      setBancoActual(
        sessionStorage.getItem('Banco_tarjeta_actual_del_usuario') || '',
      );
      setdigitosCard(
        sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
      );
    }
    if (bancoActual !== '' && digitosCard === '' && writeCard === '') {
      setWriteCard('NO');
    }
  }, [recoveryRoutine, bancoActual]);
  const handleChangeIcon = (json) => {
    setEditBanco(false);
    setLapiz([json]);
  };

  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  useEffect(() => {
    if (digitosCard2 !== '' && !digitSetNext) {
      setOrderRutine(nextOrderRoutine);
      setDigitSetNext(true);
    }
  }, [digitosCard2]);
  console.log('Veamos recovery >>>', recoveryRoutine);

  const [instScroll, setInstScroll] = useState(false);
  const setScroll1 = () => {
    if (!instScroll) {
      gsap.to(window, { duration: 1, scrollTo: '#activeInstitucionTarjeta' });
      setInstScroll(true);
    }
  };

  const [digitScroll, setDigitScroll] = useState(false);
  const [digitScroll2, setDigitScroll2] = useState(false);
  const [validDScroll, setValidDScroll] = useState(false);
  const setScroll2 = () => {
    if (!digitScroll) {
      gsap.to(window, { duration: 1, scrollTo: '#activeDigitosTarjeta' });
      setDigitScroll(true);
    }
  };

  const setScroll3 = () => {
    if (!digitScroll2) {
      gsap.to(window, { duration: 1, scrollTo: '#activeDigitosTarjeta2' });
      setDigitScroll2(true);
    }
  };

  const setScroll4 = () => {
    if (!validDScroll) {
      gsap.to(window, { duration: 1, scrollTo: '#activeValidaDigitos' });
      setValidDScroll(true);
    }
  };

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo /> {/* <h1>LOADING 1</h1> */}
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {(() => {
        console.log(
          'First validation parameters',
          Vivienda,
          triedAut,
          TelCasa,
          Vivienda !== '' && triedAut === 1 && TelCasa !== '',
        );
      })()}
      {triedAut === 1 && responseBuro === '' ? (
        <Authenticate
          setOrderRutine={setOrderRutine}
          data={form}
          setForm={setForm}
          rfc={rfc}
          triedAut={triedAut}
          setTriedAut={setTriedAut}
          bancoActual={bancoActual}
          responseBuro={responseBuro}
          setResponseBuro={setResponseBuro}
          nextOrdeRoutine={nextOrderRoutine}
          checkBCReport={checkBCReport}
          createSheet={createSheet}
        />
      ) : null}

      {/* {

************************************************************
					'Failed authentication path!!!!!!!!!'
************************************************************

				} */}
      {console.log('responseBuro >>> ', responseBuro)}
      {responseBuro !== ''
      && !responseBuro.includes('Tarjeta de Crédito Bancaria Existente')
      && !responseBuro.includes(
        'Tarjeta de Crédito Bancaria y Departamental Existente',
      )
      && !responseBuro.includes(
        'No Coincide con lo reportado en la Base de Datos',
      )
      && (triedAut === 2 || triedAut === 5) ? (
          responseBuro.includes('BC Score ') ? (
          // si en el primer intento regresa el bscore
          <div>
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              textbold="¡Muy bien! 👍"
              text="Ya hemos registrado tu historial crediticio."
              hora={hora}
            />

            {(() => {
              console.log('respondio con score en el primer intento');
              console.log(triedAut);
              if (triedAut === 2) {
                setTriedAut(5);
                setAutenticoAhorro('Si');
              }
            })()}
          </div>
          ) : responseBuro.includes('Exclusión ') ? (
          // entrar cuando en el primer intento responde exclusion
          <div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Sin cuentas para cálculo de BC Score"
              hora={hora}
            />
            {(() => {
              console.log('respondio con exclusion en el primer intento');
              if (triedAut === 2) {
                setTriedAut(5);
                setAutenticoAhorro('Si');
              }
            })()}
          </div>
          ) : (
          <div>
            {!responseBuro.includes('Tarjeta de Crédito Bancaria Existente')
            && !responseBuro.includes(
              'Tarjeta de Crédito Bancaria y Departamental Existente',
            )
            && !responseBuro.includes(
              'No Coincide con lo reportado en la Base de Datos',
            )
            && triedAut === 2 ? (
              <VideoQuestion idVideo="k2qKmDgDr2MzJGx19xp"></VideoQuestion>
              ) : null}
            <TrainingQuestion
              mt="-90px"
              mtw="-90px"
              index="10"
              pos="relative"
              className={
                !responseBuro.includes(
                  'Tarjeta de Crédito Bancaria Existente',
                )
                && !responseBuro.includes(
                  'Tarjeta de Crédito Bancaria y Departamental Existente',
                )
                && !responseBuro.includes(
                  'No Coincide con lo reportado en la Base de Datos',
                )
                && TieneTarjeta === ''
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsDivsbarVideo
                onClick={() => {
                  setTieneTarjeta('Si');
                  setSeeCards(true);
                }}
              >
                <QuestionsTextVideo size="14px" weight="400">
                  Si
                </QuestionsTextVideo>
              </QuestionsDivsbarVideo>
              <QuestionsDivsbarVideo
                onClick={() => {
                  setTieneTarjeta('No');
                  setOrderRutine(nextOrderRoutine);
                }}
              >
                <QuestionsTextVideo size="14px" weight="400">
                  No
                </QuestionsTextVideo>
              </QuestionsDivsbarVideo>
            </TrainingQuestion>
          </div>
          )
        ) : (
        <div>
          {(() => {
            if (responseBuro === '') {
              return (
                <Container
                  width="80%"
                  mt="1rem"
                  widthMobile="90%"
                  mtMobile="0rem"
                >
                  <ImageCoachInicio />
                  <Col7 padding=" " className="cardPrincipal1">
                    <LoadingWo /> {/* <h1>LOADING 2</h1> */}
                  </Col7>
                </Container>
              );
            }
          })()}
        </div>
        )}

      {/* {

************************************************************
					'Completed authentication path!!!!!!!!!'
************************************************************

				} */}
      {!responseBuro.includes('Exclusión')
      && responseBuro !== ''
      && (responseBuro.includes('Tarjeta de Crédito Bancaria Existente')
        || (TieneTarjeta === 'Si' && seeCards === true)
        || responseBuro.includes(
          'Tarjeta de Crédito Bancaria y Departamental Existente',
        ))
        ? setScroll1()
        : null}
      {!responseBuro.includes('Exclusión')
      && responseBuro !== ''
      && (responseBuro.includes('Tarjeta de Crédito Bancaria Existente')
        || (TieneTarjeta === 'Si' && seeCards === true)
        || responseBuro.includes(
          'Tarjeta de Crédito Bancaria y Departamental Existente',
        )) /* && seeCards */ ? (
        <div>
          <div id="activeInstitucionTarjeta"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            textbold="¡Sigamos, vas muy bien! 👍"
            text="Necesito información de tu tarjeta actual para consultar tu historial crediticio y continuar con la solicitud."
            hora={hora}
          />
          {/* {bancoActual === '' ? scrollToElem('aut1') : null} */}
          <div id="TarjetaScroll1"></div>
          <div id="irTarjeta"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="¿De qué institución es tu tarjeta de crédito actual? 💳"
            hora={hora}
          />
          <div className="d-none"></div>
          <TrainingQuestion
            wiw="64%"
            mLg="17%"
            className={
              (bancoActual === ''
                || (lapiz[0].routine === 8 && lapiz[0].active === 1))
              && editBanco !== true
                ? ''
                : 'hidden'
            }
          >
            <QuestionsFlex>
              <QuestionsDivs
                onClick={() => handleChangeCunrrentBank('Bancomer')}
              >
                <Images
                  position="relative"
                  width="90px"
                  left="0"
                  top="2px"
                  src={bancomer}
                ></Images>
              </QuestionsDivs>
              <QuestionsDivs
                onClick={() => handleChangeCunrrentBank('Citibanamex')}
              >
                <Images
                  position="relative"
                  width="100px"
                  left="0"
                  top="12px"
                  src={citi}
                ></Images>
              </QuestionsDivs>
            </QuestionsFlex>
            <QuestionsFlex>
              <QuestionsDivs
                onClick={() => handleChangeCunrrentBank('Santander')}
              >
                <Images
                  position="relative"
                  width="100px"
                  left="0"
                  top="12px"
                  src={santander}
                ></Images>
              </QuestionsDivs>
              <QuestionsDivs
                onClick={() => handleChangeCunrrentBank('Scotiabank')}
              >
                <Images
                  position="relative"
                  width="100px"
                  left="0"
                  top="12px"
                  src={scotia}
                ></Images>
              </QuestionsDivs>
            </QuestionsFlex>
            <QuestionsFlex>
              <QuestionsDivs
                onClick={() => handleChangeCunrrentBank('American Express')}
              >
                <Images
                  position="relative"
                  width="90px"
                  left="0"
                  top="2px"
                  src={amex}
                ></Images>
              </QuestionsDivs>
              <QuestionsDivs onClick={() => setModalBanks(true)}>
                <QuestionsText size="14px" weight="400" padd="5px">
                  Otro
                </QuestionsText>
              </QuestionsDivs>
            </QuestionsFlex>
            {modalBanks ? (
              <div>
                <CardOne
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  classCard={modalBanks ? 'cardOne' : 'hidden'}
                  classTop="topOne1"
                  text="Selecciona la institución:"
                  hora={hora}
                />
                <br />
                <Banks handleChangeBanks={handleChangeCunrrentBank} />
              </div>
            ) : null}
          </TrainingQuestion>
          <CardTwo
            classCard={
              bancoActual !== ''
              && (editBanco === true
                || !(lapiz[0].routine === 8 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={bancoActual}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={
                bancoActual !== '' && digitosCard === ''
                  ? 'iconEditCommon'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 8, active: 1 })}
            />
          </CardTwo>
        </div>
        ) : null}

      <div id="irBancosMore"></div>
      <div id="DigitosScroll"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          bancoActual !== ''
          && digitosCard === ''
          && despues !== 'Si'
          && (!(lapiz[0].routine === 8 && lapiz[0].active === 1)
            || editBanco === true)
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        textbold="¡Importante!"
        text="Esta información es de utilidad para consultar tu historial crediticio en el buró de crédito y así poder continuar con tu solicitud."
        hora={hora}
      />
      {sessionStorage.getItem('intentoAuth') !== null && responseBuro !== '' ? (
        parseInt(sessionStorage.getItem('intentoAuth')) > 3
        && !responseBuro.includes('BC Score') ? (
          <div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="No hemos obtenido tu score crediticio. Has excedido el número de intentos."
              hora={hora}
            />
            <br />
          </div>
          ) : (
          <div id="cardDigits">
            {setScroll2()}
            <div id="activeDigitosTarjeta"></div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={
                bancoActual !== ''
                && despues !== 'Si'
                && !(
                  lapiz[0].routine === 8
                  && lapiz[0].active === 1
                  && editBanco !== true
                )
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="¿Cuáles son los últimos 4 dígitos del número de tu tarjeta de crédito?"
              hora={hora}
            />
            <TrainingQuestion
              className={
                bancoActual !== ''
                && digitosCard === ''
                && despues !== 'Si'
                && !(
                  lapiz[0].routine === 8
                  && lapiz[0].active === 1
                  && editBanco !== true
                )
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsDivs
                padd="0"
                txta="left"
                widthxs="100%"
                widthweb="60%"
                marginleftLg="18%"
                hei="auto"
                margin="0 auto auto"
                bc="#fafafa"
              >
                <form onSubmit={(e) => handleSubmit(e)} className="wo-input">
                  {orderRutine === currentRoutine ? (
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="1-1"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                    />
                  ) : null}
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="19px"
                    mtop="19px"
                    onClick={() => {
                      setdigitosCard(form.digitoscard);
                    }}
                  >
                    Continuar
                  </CardsBtn>
                </form>
              </QuestionsDivs>
            </TrainingQuestion>
            {digitosCard !== '' && bancoActual !== '' ? (
              <Authenticate
                setOrderRutine={setOrderRutine}
                data={form}
                setForm={setForm}
                rfc={rfc}
                triedAut={2}
                setTriedAut={setTriedAut}
                bancoActual={bancoActual}
                responseBuro={responseBuro}
                setResponseBuro={setResponseBuro}
                nextOrdeRoutine={nextOrderRoutine}
                checkBCReport={checkBCReport}
                createSheet={createSheet}
              />
            ) : null}
            <CardTwo
              classCard={digitosCard !== '' ? 'cardTwoResp' : 'hidden'}
              classTop="topTwo1"
              imgMan="hidden"
              text={form.digitoscard}
              colorTraining={trainingColor}
            />
          </div>
          )
      ) : (
        <div>
          <div id="cardDigits2"></div>
          {setScroll3()}
          <div id="activeDigitosTarjeta2"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              bancoActual !== ''
              && despues !== 'Si'
              && !(
                lapiz[0].routine === 8
                && lapiz[0].active === 1
                && editBanco !== true
              )
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="¿Cuáles son los últimos 4 dígitos del número de tu tarjeta de crédito?"
            hora={hora}
          />
          <TrainingQuestion
            className={
              bancoActual !== ''
              && digitosCard === ''
              && despues !== 'Si'
              && !(
                lapiz[0].routine === 8
                && lapiz[0].active === 1
                && editBanco !== true
              )
                ? ''
                : 'hidden'
            }
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width="100%"
              widthweb="87%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form onSubmit={(e) => handleSubmit(e)} className="wo-input">
                {orderRutine === currentRoutine ? (
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="1-1"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                  />
                ) : null}
                <CardsBtn
                  type="button"
                  min_w="100%"
                  onClick={() => {
                    setdigitosCard(form.digitoscard);
                  }}
                >
                  Continuar
                </CardsBtn>
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
          {digitosCard !== '' && bancoActual !== '' ? (
            <Authenticate
              setOrderRutine={setOrderRutine}
              data={form}
              setForm={setForm}
              rfc={rfc}
              triedAut={2}
              setTriedAut={setTriedAut}
              bancoActual={bancoActual}
              responseBuro={responseBuro}
              setResponseBuro={setResponseBuro}
              nextOrdeRoutine={nextOrderRoutine}
              checkBCReport={checkBCReport}
              createSheet={createSheet}
            />
          ) : null}
          <CardTwo
            classCard={digitosCard !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={form.digitoscard}
            colorTraining={trainingColor}
          />
        </div>
      )}

      {sessionStorage.getItem('intentoAuth') !== null
      && responseBuro !== null ? (
          !responseBuro.includes(
            'No Coincide con lo reportado en la Base de Datos',
          ) ? (
          <div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={
                bancoActual !== ''
                && digitosCard !== ''
                && despues !== 'Si'
                && orderRutine === currentRoutine
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="Hemos validado tu información y ahora puedes continuar."
              hora={hora}
            />
            <br />
             {bancoActual !== ''
            && digitosCard !== ''
            && autenticado !== 'Si'
            && despues !== 'Si'
            && (parseInt(sessionStorage.getItem('intentoAuth')) >= 2
              || responseBuro.includes('BC Score'))
            && orderRutine === currentRoutine
              ? GoNextRoutine('Si')
              : null}
          </div>
            ) : parseInt(sessionStorage.getItem('intentoAuth')) <= 4
          && orderRutine === currentRoutine ? (
          <div>
            {setScroll4()}
            <div id="activeValidaDigitos"></div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              textbold="¡Lo sentimos!"
              text="Al parecer los dígitos que ingresaste no son correctos y no hemos podido consultar tu información."
              hora={hora}
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={
                bancoActual !== '' && despues !== 'Si' ? 'cardOne' : 'hidden'
              }
              classTop="topOne1"
              text="Por favor valida e ingresa los últimos 4 dígitos de tu tarjeta de crédito vigente"
              hora={hora}
            />
            <TrainingQuestion
              className={
                bancoActual !== '' && despues !== 'Si' && digitosCard2 === ''
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsDivs
                padd="0"
                txta="left"
                width="100%"
                widthweb="87%"
                hei="auto"
                margin="0 auto auto"
                bc="#fafafa"
              >
                <form onSubmit={(e) => handleSubmit(e)}>
                  {orderRutine === currentRoutine ? (
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="1-1"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                    />
                  ) : null}
                  <br />
                  <br />
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    onClick={() => setdigitosCard2(form.digitoscard)}
                  >
                    Continuar
                  </CardsBtn>
                  <br />
                  <br />
                </form>
              </QuestionsDivs>
            </TrainingQuestion>
            {digitosCard2 !== '' && bancoActual !== '' ? (
              <Authenticate
                setOrderRutine={setOrderRutine}
                data={form}
                setForm={setForm}
                rfc={rfc}
                triedAut={2}
                setTriedAut={setTriedAut}
                bancoActual={bancoActual}
                responseBuro={responseBuro}
                setResponseBuro={setResponseBuro}
                nextOrdeRoutine={nextOrderRoutine}
                checkBCReport={checkBCReport}
                createSheet={createSheet}
              />
            ) : null}
            <CardTwo
              classCard={digitosCard2 !== '' ? 'cardTwoResp' : 'hidden'}
              classTop="topTwo1"
              imgMan="hidden"
              text={form.digitoscard}
              colorTraining={trainingColor}
            />
            <div id="DigitosScroll2"></div>
          </div>
              ) : null
        ) : null}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          bancoActual !== ''
          && digitosCard === ''
          && despues !== 'Si'
          && digitosCard2 === ''
          && (!(lapiz[0].routine === 8 && lapiz[0].active === 1)
            || editBanco === true)
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="Si tienes problemas para identificar los 4 dígitos de tu tarjeta, aquí te decimos cómo hacerlo."
        hora={hora}
      />
      <ImageCard4D
        src={digitos}
        className={
          bancoActual !== ''
          && digitosCard === ''
          && despues !== 'Si'
          && digitosCard2 === ''
          && (!(lapiz[0].routine === 8 && lapiz[0].active === 1)
            || editBanco === true)
            ? 'img-fluid'
            : 'hidden'
        }
      ></ImageCard4D>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          bancoActual !== ''
          && digitosCard === ''
          && despues !== 'Si'
          && digitosCard2 === ''
          && (!(lapiz[0].routine === 8 && lapiz[0].active === 1)
            || editBanco === true)
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Tienes dudas de la seguridad de esta información?"
        text2="¡No te preocupes! Los 4 dígitos son información pública que puedes encontrar incluso en los tickets de tus compras."
        hora={hora}
      />
      <CardsBtn1
        onClick={() => setDespues('Si')}
        color="#EC407A !important"
        back="#fff !important"
        border="solid 1px !important"
        mleftmd="5%"
        className={
          bancoActual !== ''
          && digitosCard === ''
          && despues !== 'Si'
          && digitosCard2 === ''
          && (!(lapiz[0].routine === 8 && lapiz[0].active === 1)
            || editBanco === true)
            ? ''
            : 'hidden'
        }
      >
        No tengo mi tarjeta a la mano
      </CardsBtn1>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={despues === 'Si' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        textbold="¡Te esperamos!"
        text="Puedes regresar más tarde a concluir tu trámite."
        text2="Tu avance se ha quedado guardado y lo puedes retomar cuando tengas tus datos."
        hora={hora}
      />
    </div>
  );
};

export default UserAuthenticationVideo;
