/* eslint-disable no-tabs */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useHistory } from 'react-router-dom';
// Import visual components
import TextField from '@material-ui/core/TextField';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
  TrainingText,
  DivRespDP,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import { ImageSticker } from '../../assets/styles/Feed.styled';
import iconAgregarAmigo from '../../assets/img/anacoach/iconAgregarAmigo.svg';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import {
  SEND_NOTIFICATION,
  INSERT_DAT_GOAL_COLLABORATIVE,
  GENERATE_SHORTENED_LINK,
} from '../../mutations';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import { Container, Col7 } from '../../components/common/common.styled';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';

const AdventureCollaborative = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const styleInputNewService = {
    border: 'none',
    fontSize: '15px',
    width: '137px',
    backgroundColor: 'transparent',
  };
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [metasPorCumplir, setMetasPorCumplir] = useState(
    sessionStorage.getItem('MetasPorCumplir') || '',
  );
  const [propiaMetaColavorativa, setPropiaMetaColavorativa] = useState(
    sessionStorage.getItem('PropiaMetaColavorativa') || '',
  );
  const [dineroMetaColavorativa, setDineroMetaColavorativa] = useState(
    sessionStorage.getItem('DineroMetaColavorativa') || '',
  );
  const [tiempoMetaColavorativa, setTiempoMetaColavorativa] = useState(
    sessionStorage.getItem('TiempoMetaColavorativa') || '',
  );
  const [invitadosMetaColavorativa, setInvitadosMetaColavorativa] = useState(
    JSON.parse(sessionStorage.getItem('InvitadosMetaColavorativa')) || '',
  );
  const [partesIgualesMetaColavorativa, setPartesIgualesMetaColavorativa] = useState(sessionStorage.getItem('PartesIgualesMetaColavorativa') || '');
  const [
    porcentajeAhorroMetaColavorativa,
    setPorcentajeAhorroMetaColavorativa,
  ] = useState(
    sessionStorage.getItem('PorcentajeAhorroMetaColavorativa') || '',
  );

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({
    MetasPorCumplir: sessionStorage.getItem('MetasPorCumplir') || '',
    PropiaMetaColavorativa:
      sessionStorage.getItem('PropiaMetaColavorativa') || '',
    DineroMetaColavorativa:
      sessionStorage.getItem('DineroMetaColavorativa') || '',
    TiempoMetaColavorativa:
      sessionStorage.getItem('TiempoMetaColavorativa') || '',
    InvitadosMetaColavorativa:
      sessionStorage.getItem('InvitadosMetaColavorativa') || '',
    PartesIgualesMetaColavorativa:
      sessionStorage.getItem('PartesIgualesMetaColavorativa') || '',
    PorcentajeAhorroMetaColavorativa:
      sessionStorage.getItem('PorcentajeAhorroMetaColavorativa') || '',
  });

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const { actions } = ClickButton();

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    // setPreguntasIntroExtraIncome(val);
    switch (val.step) {
      case 0:
        setEditMetasPorCumplir(true);
        setMetasPorCumplir(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest2EI' });
        break;
      case 1:
        setEditDineroMetaColavorativa(true);
        setDineroMetaColavorativa(val.value);
        break;
      case 2:
        setEditTiempoMetaColavorativa(true);
        setTiempoMetaColavorativa(val.value);
        break;
      default:
        break;
    }
  };
  const handleChange = (e, question = '') => {
    let value = '';
    switch (e.target.name) {
      case 'DineroMetaColavorativa':
        value = e.target.value.replace('$', '').replace(/,/g, '').trim();
        value = filterFloat(value);

        if (value !== '') {
          setForm({
            ...form,
            [e.target.name]: formatter.format(filterFloat(value)),
          });
        } else if (e.target.value === '$') {
          setForm({
            ...form,
            [e.target.name]: '',
          });
        }
        break;
      case 'PropiaMetaColavorativa':
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
        break;
      case 'TiempoMetaColavorativa':
        value = filterFloat(e.target.value.trim());
        setForm({
          ...form,
          [e.target.name]: value,
        });
        break;
      default:
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
    }
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
    agrega();
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const invitados = JSON.parse(
        sessionStorage.getItem('InvitadosMetaColavorativa'),
      );
      setMetasPorCumplir(sessionStorage.getItem('MetasPorCumplir') || '');
      setPropiaMetaColavorativa(
        sessionStorage.getItem('PropiaMetaColavorativa') || '',
      );
      setDineroMetaColavorativa(
        sessionStorage.getItem('DineroMetaColavorativa') || '',
      );
      setTiempoMetaColavorativa(
        sessionStorage.getItem('TiempoMetaColavorativa') || '',
      );
      setInvitadosMetaColavorativa(invitados || '');
      setPartesIgualesMetaColavorativa(
        sessionStorage.getItem('PartesIgualesMetaColavorativa') || '',
      );
      setPorcentajeAhorroMetaColavorativa(
        sessionStorage.getItem('PorcentajeAhorroMetaColavorativa') || '',
      );
      if (invitados.length > 0) {
        setInputFields(invitados);
        setnrow(invitados.length);
        calcularPorcentajes(invitados, partesIgualesMetaColavorativa);
      }
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editMetasPorCumplir, setEditMetasPorCumplir] = useState('');
  const [editDineroMetaColavorativa, setEditDineroMetaColavorativa] = useState('');
  const [editTiempoMetaColavorativa, setEditTiempoMetaColavorativa] = useState('');
  const [editInvitadosMetaColavorativa, setEditInvitadosMetaColavorativa] = useState('');
  const [
    editPartesIgualesMetaColavorativa,
    setEditPartesIgualesMetaColavorativa,
  ] = useState('');
  const [
    editPorcentajeAhorroMetaColavorativa,
    setEditPorcentajeAhorroMetaColavorativa,
  ] = useState('');

  const handleChangeIcon = (json) => {
    setEditMetasPorCumplir(false);
    setEditDineroMetaColavorativa(false);
    setEditTiempoMetaColavorativa(false);
    setEditInvitadosMetaColavorativa(false);
    setEditPartesIgualesMetaColavorativa(false);
    setEditPorcentajeAhorroMetaColavorativa(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  useEffect(() => {
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: '#activeCollaborative' });
    }, 1000);
  }, []);
  useEffect(() => {
    sessionStorage.setItem(
      'InvitadosMetaColavorativa',
      JSON.stringify(invitadosMetaColavorativa),
    );
  }, [invitadosMetaColavorativa]);
  useEffect(() => {
    sessionStorage.setItem(
      'PartesIgualesMetaColavorativa',
      partesIgualesMetaColavorativa,
    );
  }, [partesIgualesMetaColavorativa]);

  const [sendNotification] = useMutation(SEND_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted({ sendNotification }) {},
    onError(err) {
      console.log(err);
    },
  });

  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted({ generateShortendLink }) {
      if (generateShortendLink.message === 'Exito') {
        return generateShortendLink.response;
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const history = useHistory();
  const [insertDatGoalCollaborative] = useMutation(
    INSERT_DAT_GOAL_COLLABORATIVE,
    {
      onCompleted({ insertDatGoalCollaborative }) {
        if (insertDatGoalCollaborative.message === 'success') {
          history.push('/wo/collaborative-success');
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const handleChangeMeta = () => {
    if (form.PropiaMetaColavorativa) {
      questions.push({
        id: 'PropiaMetaColavorativa',
        question: 2,
        value: form.PropiaMetaColavorativa,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setPropiaMetaColavorativa(form.PropiaMetaColavorativa);
      handleChangeCatOption({ step: 0, value: form.PropiaMetaColavorativa });
    }
  };
  const handleChangeMonto = () => {
    const click = `ContinuarMontoMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    questions.push({
      id: 'DineroMetaColavorativa',
      question: 3,
      value: form.DineroMetaColavorativa,
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
    setDineroMetaColavorativa(form.DineroMetaColavorativa);
    // setValidateStatusTraining(false);
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest3EI' });
  };
  const handleChangeTiempoMeta = () => {
    if (form.TiempoMetaColavorativa > 0) {
      questions.push({
        id: 'TiempoMetaColavorativa',
        question: 4,
        value: form.TiempoMetaColavorativa,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setTiempoMetaColavorativa(form.TiempoMetaColavorativa);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest4EI' });
  };

  const handleChangeInvitados = async () => {
    const invalidPhones = inputFields.filter((e) => e.phone.length < 10);
    if (invalidPhones.length === 0 && inputFields.length > 0) {
      setInvitadosMetaColavorativa(inputFields);

      let urlTraining = window.location.href;
      urlTraining = urlTraining.replace('/wo/collaborative', '/achievements');
      const cellPhone = sessionStorage.getItem('CellPhone');
      const firstname = sessionStorage.getItem('FirstName') || '';

      for (let i = 0; i < inputFields.length; i++) {
        const inputShortened = {
          input: {
            url: `${urlTraining}?phone=${inputFields[i].phone}`,
            telefono: cellPhone,
            mensaje: '',
            idGen: 'invitacion_colaborativa',
          },
        };
        const link = await generateShortendLink({ variables: inputShortened });
        const { response } = link.data.generateShortendLink;

        const inputSendNoti = {
          input: {
            type: 'wamult',
            properties: {
              trigger: 'invitacion_colaborativa',
              telephone: inputFields[i].phone,
              subject: '',
              template: '',
              variables: [
                {
                  setkey: 'primerNombre',
                  setval: firstname,
                },
                {
                  setkey: 'nombre_meta',
                  setval: metasPorCumplir || propiaMetaColavorativa,
                },
                {
                  setkey: 'URL_WO',
                  setval: response || '',
                },
              ],
              source: {},
              destination: {},
            },
          },
        };
        sendNotification({ variables: inputSendNoti });
      }
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest5EI' });
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let [nrow, setnrow] = useState(1);
  const [savingsPercentage, setSavingsPercentage] = useState([]);
  const [inputFields, setInputFields] = useState([]);

  // Funciones
  const agrega = () => {
    setnrow(nrow + 1);
    if (nrow < 11) setInputFields([...inputFields, { id: nrow, phone: '' }]);
  };

  const saveGoal = () => {
    const guestsData = [];
    savingsPercentage.map((e) => {
      guestsData.push({
        idUser: e.phone ? null : parseInt(sessionStorage.getItem('idUser')),
        phone: e.phone || sessionStorage.getItem('CellPhone') || '',
        percentage: parseFloat(e.percentage),
      });
    });
    const inputInsert = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        name: metasPorCumplir || propiaMetaColavorativa,
        goalDetails: [
          {
            idType: '480',
            value: dineroMetaColavorativa,
          },
          {
            idType: '481',
            value: tiempoMetaColavorativa,
          },
        ],
        guestsData,
      },
    };

    insertDatGoalCollaborative({
      variables: inputInsert,
    });
  };

  const calcularPorcentajes = (invitados, partesIguales) => {
    let percentage = '0';
    if (partesIguales === 'Si') {
      percentage = `${(100 / (invitados.length + 1)).toFixed(2)}`;
    }
    const savingsPercentageTmp = [{ name: 'Tú', phone: '', percentage }];
    invitados.map((e, index) => {
      savingsPercentageTmp.push({
        name: `Compañero ${index + 1}`,
        phone: e.phone,
        percentage,
      });
    });
    setSavingsPercentage(...savingsPercentage, savingsPercentageTmp);
  };

  const handleChangePhones = (event, index) => {
    event.preventDefault();
    const value = filterFloat(event.target.value.trim());
    ValidPhone(event.target.name, value, event.target.id, index);
  };
  const ValidPhone = (name, value, id, index) => {
    const tel = document.getElementById(id);
    if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
    } else {
      tel.className = 'textInput inputs-bottom validInput';
    }

    const newState = [...inputFields];
    newState[index].phone = value;
    setInputFields(newState);
  };

  // Tipo de ahorro - Partes iguales?
  const handleChangePartesIguales = (partesIguales) => {
    setForm({
      ...form,
      PartesIgualesMetaColavorativa: partesIguales,
    });
    setPartesIgualesMetaColavorativa(partesIguales);
    const invitados = JSON.parse(
      sessionStorage.getItem('InvitadosMetaColavorativa'),
    );
    calcularPorcentajes(invitados, partesIguales);
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest6EI' });
  };

  const handleChangePorcentajeAhorro = (e, index) => {
    let value = e.target.value.replace('%', '').replace(/,/g, '').trim();
    value = filterFloat(value);
    const newState = [...savingsPercentage];
    if (value !== '') {
      if (value <= 100) {
        newState[index].percentage = `${value}`;
      } else {
        newState[index].percentage = newState[index].percentage;
      }
    } else {
      newState[index].percentage = '';
    }
    setSavingsPercentage(newState);
  };

  const anchop = window.screen.width;

  return (
    <Fragment>
      <div id="activeCollaborative"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text2="Continuemos."
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        textbold="¡Iniciemos esta aventura!"
        text2="Para cumplir tus metas es necesario tener claro qué quieres lograr y cómo quieres llegar a ese objetivo."
        hora={hora}
      />
      {/* pregunta número uno */}
      <TrainingQuestion
        wiw="80%"
        mt="20px"
        className={
          ((metasPorCumplir === '' && propiaMetaColavorativa === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 1))
          && editMetasPorCumplir !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="5%"
          txta="left"
          width="100%"
          widthweb="75%"
          hei="auto"
          margin="auto"
          bc="#a6a6a61f"
          marginL="9.9%"
          marginLmd="0px"
        >
          <div className="cardPrincipal1">
            <TrainingText
              className="cabin"
              align="left"
              weight="bold"
              size="16px"
              sizew="16px"
            >
              ¡Primero lo primero!
            </TrainingText>
            <TrainingText
              className="cabin"
              align="left"
              weight="normal"
              size="16px"
              sizew="16px"
              bot="20px"
            >
              ¿Qué quieres lograr? Aquí te propongo algunas ideas, puedes
              seleccionar una o registrar tu propia meta.
            </TrainingText>
            <div style={{ columnCount: '2' }}>
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                inputflex={true}
              />
            </div>
            <TrainingText
              className="cabin"
              align="left"
              weight="normal"
              size="16px"
              sizew="16px"
            >
              Si las metas anteriores no coinciden con lo que quieres, registra
              tu propia meta aquí:
            </TrainingText>
          </div>
          <TextField
            variant="outlined"
            id="PropiaMetaColavorativa"
            name="PropiaMetaColavorativa"
            label="¿Cuál es tu meta?"
            autoComplete="off"
            onChange={handleChange}
            value={form.PropiaMetaColavorativa}
            required
            fullWidth
            className="textInput inputs-bottom input-coru"
          />
          <CardsBtn
            mtop="20px"
            mtopw="20px"
            mtopmd="20px"
            type="button"
            min_w="100%"
            min_web="100%"
            onClick={(e) => handleChangeMeta(e)}
          >
            Guardar
          </CardsBtn>
        </QuestionsDivs>
      </TrainingQuestion>

      <div id="activeQuest2EI"></div>
      <CardTwo
        classCard={
          (metasPorCumplir !== '' || propiaMetaColavorativa !== '')
          && (editMetasPorCumplir === true
            || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={metasPorCumplir || propiaMetaColavorativa}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            (metasPorCumplir !== '' || propiaMetaColavorativa !== '')
            && dineroMetaColavorativa === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
        />
      </CardTwo>

      {/* pregunta número dos */}
      {dineroMetaColavorativa !== ''
      && (editDineroMetaColavorativa === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 2)) ? (
        <QuestionsOrder
          classCard={
            metasPorCumplir !== '' || propiaMetaColavorativa !== ''
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      <div
        className={
          (((metasPorCumplir !== '' || propiaMetaColavorativa !== '')
            && dineroMetaColavorativa === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 4 && lapiz[0].active === 2)
            || editMetasPorCumplir === true)
          && editDineroMetaColavorativa !== true
            ? ''
            : 'hidden'
        }
      >
        <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
          <ImageCoachInicio
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            topMobile="35px"
          />
          <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
            <TrainingDivSection
              hew="auto"
              className="topOne1"
              paddw="26px 10px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="100%"
              he="auto"
            >
              <TrainingQuestion
                mt="0"
                className={
                  (((metasPorCumplir !== '' || propiaMetaColavorativa !== '')
                    && dineroMetaColavorativa === '')
                    || (lapiz[0].routine === 4 && lapiz[0].active === 2))
                  && (!(lapiz[0].routine === 4 && lapiz[0].active === 2)
                    || editMetasPorCumplir === true)
                  && editDineroMetaColavorativa !== true
                    ? ''
                    : 'hidden'
                }
              >
                <TrainingText
                  className="cabin"
                  align="left"
                  weight="normal"
                  size="16px"
                  sizew="16px"
                  bot="20px"
                >
                  ¿Cuánto dinero necesitas para cumplir tu meta?
                </TrainingText>
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="2-2"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtop="0"
                  onClick={(e) => handleChangeMonto(e)}
                >
                  Continuar
                </CardsBtn>
              </TrainingQuestion>
            </TrainingDivSection>
          </Col7>
        </Container>
      </div>
      <div id="activeQuest3EI"></div>
      <CardTwo
        classCard={
          dineroMetaColavorativa !== ''
          && (editDineroMetaColavorativa === true
            || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={dineroMetaColavorativa}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            dineroMetaColavorativa !== '' && tiempoMetaColavorativa === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
        />
      </CardTwo>

      {/* pregunta número tres */}
      {tiempoMetaColavorativa !== ''
      && (editTiempoMetaColavorativa === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 3)) ? (
        <QuestionsOrder
          classCard={dineroMetaColavorativa !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        ></QuestionsOrder>
        ) : null}
      <div
        className={
          ((dineroMetaColavorativa !== '' && tiempoMetaColavorativa === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 4 && lapiz[0].active === 3)
            || editDineroMetaColavorativa === true)
          && editTiempoMetaColavorativa !== true
            ? ''
            : 'hidden'
        }
      >
        <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
          <ImageCoachInicio
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            topMobile="35px"
          />
          <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
            <TrainingDivSection
              hew="auto"
              className="topOne1"
              paddw="26px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="100%"
              he="auto"
            >
              <TrainingQuestion
                mt="0"
                className={
                  ((dineroMetaColavorativa !== ''
                    && tiempoMetaColavorativa === '')
                    || (lapiz[0].routine === 4 && lapiz[0].active === 3))
                  && (!(lapiz[0].routine === 4 && lapiz[0].active === 3)
                    || editDineroMetaColavorativa === true)
                  && editTiempoMetaColavorativa !== true
                    ? ''
                    : 'hidden'
                }
              >
                <TrainingText
                  className="cabin"
                  align="left"
                  weight="normal"
                  size="16px"
                  sizew="16px"
                  bot="20px"
                >
                  ¿En cuánto tiempo quieres cumplir tu meta?
                </TrainingText>
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="3-3"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  labelInput={'Ingresa el número de meses'}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtop="0"
                  onClick={(e) => handleChangeTiempoMeta(e)}
                >
                  Continuar
                </CardsBtn>
              </TrainingQuestion>
            </TrainingDivSection>
          </Col7>
        </Container>
      </div>
      <div id="activeQuest4EI"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            tiempoMetaColavorativa !== '' && invitadosMetaColavorativa === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
        />
        <CardTwo
          classCard={
            tiempoMetaColavorativa !== ''
            && (editTiempoMetaColavorativa === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={`${tiempoMetaColavorativa} ${
            tiempoMetaColavorativa > 1 ? 'Meses' : 'Mes'
          }`}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* pregunta número cuatro */}
      {tiempoMetaColavorativa !== ''
      && (editTiempoMetaColavorativa === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 5)) ? (
        <>
          <Container width="80%" mt="0" widthMobile="100%" mtMobile="0">
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
          </Container>

          <TrainingQuestion wiw="80%" className="" mt="20px">
            <QuestionsDivs
              padd="5%"
              txta="left"
              width="100%"
              widthweb="75%"
              hei="auto"
              margin="auto"
              bc="#a6a6a61f"
              marginL="9.9%"
              marginLmd="0px"
            >
              <div className="cardPrincipal1">
                <TrainingText
                  className="cabin"
                  align="left"
                  weight="bold"
                  size="16px"
                  sizew="16px"
                >
                  Por último ¿A quién quieres invitar?
                </TrainingText>
                <TrainingText
                  className="cabin"
                  align="left"
                  weight="normal"
                  size="16px"
                  sizew="16px"
                  bot="20px"
                >
                  Para cumplir esta meta en conjunto, invita a tu(s) persona(s)
                  favoritas, quienes recibirán un mensaje por Whatsapp con tu
                  invitación para colaborar.
                </TrainingText>
              </div>
              <TrainingQuestion
                width="100%"
                wiw="100%"
                mt="20px"
                className={
                  ((tiempoMetaColavorativa !== ''
                    && invitadosMetaColavorativa === '')
                    || (lapiz[0].routine === 4 && lapiz[0].active === 4))
                  && (!(lapiz[0].routine === 4 && lapiz[0].active === 4)
                    || editTiempoMetaColavorativa === true)
                  && editInvitadosMetaColavorativa !== true
                    ? ''
                    : 'hidden'
                }
              >
                <QuestionsDivs
                  padd="0"
                  txta="left"
                  width="100%"
                  widthweb="100%"
                  hei="auto"
                  margin="auto"
                  bc="transparent"
                  marginL="0"
                  marginLmd="0px"
                >
                  <div>
                    {inputFields.map((inputField, index) => (
                      <div key={inputField.id}>
                        <TextField
                          name={`Phone${inputField.id}`}
                          variant="outlined"
                          id={`Phone${inputField.id}`}
                          label={`Ingresa celular del invitado${inputField.id}`}
                          value={inputField.phone}
                          autoComplete="off"
                          required
                          fullWidth
                          className="textInput inputs-bottom input-coru"
                          inputProps={{ maxLength: 10 }}
                          onChange={(e) => handleChangePhones(e, index)}
                        />
                      </div>
                    ))}
                  </div>
                  <div id="contentMore"></div>
                  <CardsBtn
                    mtop="20px"
                    mtopw="20px"
                    mtopmd="20px"
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    color="#EC407A !important"
                    back="#fff !important"
                    border="solid 1px !important"
                    padd="8px 15px 4px"
                    heigth="49px"
                    className=""
                    onClick={(e) => agrega(e)}
                  >
                    <ImageSticker
                      marg="-5px 20px auto auto"
                      width="30px"
                      src={iconAgregarAmigo}
                    />
                    <b>Agregar a más personas</b>
                  </CardsBtn>
                  <CardsBtn
                    mtop="20px"
                    mtopw="20px"
                    mtopmd="20px"
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    onClick={(e) => handleChangeInvitados(e)}
                  >
                    Enviar
                  </CardsBtn>
                </QuestionsDivs>
              </TrainingQuestion>
            </QuestionsDivs>
          </TrainingQuestion>
        </>
        ) : null}
      <div id="activeQuest5EI"></div>
      <CardTwo
        classCard={
          invitadosMetaColavorativa !== ''
          && (editInvitadosMetaColavorativa === true
            || !(lapiz[0].routine === 4 && lapiz[0].active === 4))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={
          invitadosMetaColavorativa[0] ? invitadosMetaColavorativa[0].phone : ''
        }
        text2={
          invitadosMetaColavorativa[1] ? invitadosMetaColavorativa[1].phone : ''
        }
        text3={
          invitadosMetaColavorativa[2] ? invitadosMetaColavorativa[2].phone : ''
        }
        text4={
          invitadosMetaColavorativa[3] ? invitadosMetaColavorativa[3].phone : ''
        }
        text5={
          invitadosMetaColavorativa[4] ? invitadosMetaColavorativa[4].phone : ''
        }
        text6={
          invitadosMetaColavorativa[5] ? invitadosMetaColavorativa[5].phone : ''
        }
        text7={
          invitadosMetaColavorativa[6] ? invitadosMetaColavorativa[6].phone : ''
        }
        text8={
          invitadosMetaColavorativa[7] ? invitadosMetaColavorativa[7].phone : ''
        }
        text9={
          invitadosMetaColavorativa[8] ? invitadosMetaColavorativa[8].phone : ''
        }
        text10={
          invitadosMetaColavorativa[9] ? invitadosMetaColavorativa[9].phone : ''
        }
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            invitadosMetaColavorativa !== ''
            && partesIgualesMetaColavorativa === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 4 })}
        />
      </CardTwo>

      {/* pregunta número cinco */}

      {invitadosMetaColavorativa !== ''
      && (editInvitadosMetaColavorativa === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 5)) ? (
        <>
          <Container width="80%" mt="0" widthMobile="100%" mtMobile="0">
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
          </Container>

          <TrainingQuestion wiw="80%" className="" mt="20px">
            <QuestionsDivs
              padd="5%"
              txta="left"
              width="100%"
              widthweb="75%"
              hei="auto"
              margin="auto"
              bc="#a6a6a61f"
              marginL="9.9%"
              marginLmd="0px"
            >
              <div className="cardPrincipal1">
                <TrainingText
                  className="cabin"
                  align="left"
                  weight="normal"
                  size="16px"
                  sizew="16px"
                >
                  ¿Todos ahorrarán en partes iguales?
                </TrainingText>
              </div>
            </QuestionsDivs>
          </TrainingQuestion>
        </>
        ) : null}

      <TrainingQuestion
        wiw="80%"
        mt="0"
        className={
          ((invitadosMetaColavorativa.length > 0
            && partesIgualesMetaColavorativa === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 5))
          && (!(lapiz[0].routine === 4 && lapiz[0].active === 5)
            || editInvitadosMetaColavorativa === true)
          && editPartesIgualesMetaColavorativa !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="75%"
          hei="auto"
          margin="auto"
          bc="#fafafa"
          marginL="9.9%"
          marginLmd="0px"
        >
          <div id="contentMore"></div>
          <CardsBtn
            style={{ boxShadow: '0px 2px 8px rgba(0,0,0,0.08)' }}
            back="#FFFFFF"
            color="#293229"
            min_w="100%"
            min_web="100%"
            type="button"
            onClick={() => handleChangePartesIguales('Si')}
          >
            Sí
          </CardsBtn>
          <CardsBtn
            style={{ boxShadow: '0px 2px 8px rgba(0,0,0,0.08)' }}
            back="#FFFFFF"
            color="#293229"
            min_w="100%"
            min_web="100%"
            type="button"
            onClick={() => handleChangePartesIguales('No')}
          >
            No
          </CardsBtn>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeQuest6EI"></div>
      <CardTwo
        classCard={
          partesIgualesMetaColavorativa !== ''
          && (editPartesIgualesMetaColavorativa === true
            || !(lapiz[0].routine === 4 && lapiz[0].active === 5))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={partesIgualesMetaColavorativa}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            partesIgualesMetaColavorativa !== ''
            && porcentajeAhorroMetaColavorativa === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 5 })}
        />
      </CardTwo>
      {((partesIgualesMetaColavorativa !== ''
        && porcentajeAhorroMetaColavorativa === '')
        || (lapiz[0].routine === 4 && lapiz[0].active === 6))
      && (!(lapiz[0].routine === 4 && lapiz[0].active === 6)
        || editPartesIgualesMetaColavorativa === true)
      && editPorcentajeAhorroMetaColavorativa !== true ? (
        <>
          <Container width="80%" mt="0" widthMobile="100%" mtMobile="0">
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
          </Container>
          <TrainingQuestion wiw="80%" className="" mt="20px">
            <QuestionsDivs
              padd="5%"
              txta="left"
              width="100%"
              widthweb="75%"
              hei="auto"
              margin="auto"
              bc="#a6a6a61f"
              marginL="9.9%"
              marginLmd="0px"
            >
              <div className="cardPrincipal1">
                <TrainingText
                  className="cabin"
                  align="left"
                  weight="normal"
                  size="16px"
                  sizew="16px"
                >
                  Define el porcentaje de ahorro para cada persona:
                </TrainingText>
              </div>
              <div id="contentMore"></div>

              {inputFields.length > 0
                ? savingsPercentage.map((e, index) => (
                    <>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <QuestionsDivs
                          txta="left"
                          padd="14px 5px"
                          width="80%"
                          margin="8px 5px auto auto;"
                          hei="50px"
                          bs="0px 2px 8px rgba(0,0,0,0.08)"
                        >
                          <input
                            type="text"
                            placeholder={e.name || `Compañero ${index + 1}`}
                            autoComplete="off"
                            required
                            name="savingsPercentage"
                            style={styleInputNewService}
                            disabled={true}
                          />
                        </QuestionsDivs>
                        <QuestionsDivs
                          padd="14px 5px"
                          width="80%"
                          margin="8px auto auto 5px"
                          hei="50px"
                          bs="0px 2px 8px rgba(0,0,0,0.08)"
                        >
                          <input
                            type="text"
                            placeholder="0"
                            autoComplete="off"
                            required
                            name="amountService"
                            style={styleInputNewService}
                            value={e.percentage}
                            className="percent-style"
                            onChange={(e) => handleChangePorcentajeAhorro(e, index)
                            }
                          />
                        </QuestionsDivs>
                      </div>
                    </>
                ))
                : null}
              <CardsBtn
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={(e) => saveGoal(e)}
              >
                Guardar
              </CardsBtn>
            </QuestionsDivs>
          </TrainingQuestion>
        </>
        ) : null}
    </Fragment>
  );
};

export default AdventureCollaborative;
