/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  TrainingText,
  QuestionsDivs,
  TrainingDivSection,
  DivRespDP,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import {
  arraySessionsRutines,
  getElementByXpath,
  filterFloat,
} from '../../Helpers';
import editIcon from '../../assets/img/anacoach/editar1.png';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';

const ThereAreDebts = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [tienesDeudas, setTienesDeudas] = useState(
    sessionStorage.getItem('TienesDeudas') || '',
  );
  const [principalesDeudas, sePrincipalesDeudas] = useState(
    sessionStorage.getItem('PrincipalesDeudas') || '',
  );
  const [montoAdeudo, setMontoAdeudo] = useState(
    sessionStorage.getItem('MontoAdeudo') || '',
  );
  const [montoAdeudoInformales, setMontoAdeudoInformales] = useState(
    sessionStorage.getItem('MontoAdeudoInformales') || '',
  );
  const [deudaTotal, setDeudaTotal] = useState(
    sessionStorage.getItem('DeudaTotal') || '',
  );
  const [principalesDeudasObj, setPrincipalesDeudasObj] = useState([]);
  const [activeDeudaFormal, setActiveDeudaFormal] = useState(false);
  const [activeDeudaInformal, setActiveDeudaInformal] = useState(false);
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({
    montoAdeudo: sessionStorage.getItem(montoAdeudo) || '',
    montoAdeudoInformales: sessionStorage.getItem(montoAdeudoInformales) || '',
  });

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const { actions } = ClickButton();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    switch (numQuestion) {
      case 1:
        setedittienesDeudas(true);
        break;
      default:
        break;
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
    switch (step) {
      case 0:
        setTienesDeudas(value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest2Deudas' });
        if (value === 'No') {
          setOrderRutine(nextOrderRoutine);
        }
        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };

  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      setForm({
        ...form,
        [e.target.name]: `$${formatterMx(filterFloat(value))}`,
      });
    } else if (e.target.value === '$') {
      setForm({
        ...form,
        [e.target.name]: '',
      });
    }
  };

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setTienesDeudas(sessionStorage.getItem('TienesDeudas') || '');
      const principalesDebts = sessionStorage.getItem('PrincipalesDeudas') || '';
      sePrincipalesDeudas(principalesDebts);
      recoveryMontosPrincipalesDeudas(principalesDebts);

      setMontoAdeudo(sessionStorage.getItem('MontoAdeudo') || '');
      setMontoAdeudoInformales(
        sessionStorage.getItem('MontoAdeudoInformales') || '',
      );
      setDeudaTotal(sessionStorage.getItem('DeudaTotal') || '');

      if (sessionStorage.getItem('TienesDeudas') === 'No') {
        setOrderRutine(nextOrderRoutine);
      } else if (
        (sessionStorage.getItem('MontoAdeudo') !== ''
          && sessionStorage.getItem('MontoAdeudo') !== null)
        || (sessionStorage.getItem('MontoAdeudoInformales') !== ''
          && sessionStorage.getItem('MontoAdeudoInformales') !== null)
      ) {
        setActiveBtnMontoDueda(false);
        setOnceValidateBtnDeuda(false);
        const deudaTotal = convertirNum(sessionStorage.getItem('MontoAdeudo'))
          + convertirNum(sessionStorage.getItem('MontoAdeudoInformales'));
        sessionStorage.setItem('DeudaTotal', `$${formatterMx(deudaTotal)}`);
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const recoveryMontosPrincipalesDeudas = (principalesDebts) => {
    principalesDebts = principalesDebts.split(',');
    for (let index = 0; index < principalesDebts.length; index++) {
      if (String(principalesDebts[index]).trim() === 'Formales') {
        principalesDeudasObj.push({ id: 'Formales', question: 2, value: 428 });
        if (
          document.getElementsByName('Formales')[0] !== undefined
          && document.getElementsByName('Formales')[0] !== null
        ) {
          document
            .getElementsByName('Formales')[0]
            .classList.add('selectedBen');
        }
        setActiveDeudaFormal(true);
      } else if (String(principalesDebts[index]).trim() === 'Informales') {
        principalesDeudasObj.push({
          id: 'Informales',
          question: 2,
          value: 429,
        });
        if (
          document.getElementsByName('Informales')[0] !== undefined
          && document.getElementsByName('Informales')[0] !== null
        ) {
          document
            .getElementsByName('Informales')[0]
            .classList.add('selectedBen');
        }
        setActiveDeudaInformal(true);
      }
    }
    if (principalesDebts.length > 0) {
      savePrincipalesDeudas();
    }
  };

  useEffect(() => {
    if (
      !activeDeudaFormal
      && document.getElementsByName('Formales')[0] !== undefined
    ) {
      document.getElementsByName('Formales')[0].classList.remove('selectedBen');
    } else if (
      !activeDeudaInformal
      && document.getElementsByName('Informales')[0] !== undefined
    ) {
      document
        .getElementsByName('Informales')[0]
        .classList.remove('selectedBen');
    }
  }, [activeDeudaFormal, activeDeudaInformal]);

  const handleChangePrincipalesDeudas = (
    numQuestion,
    name,
    value,
    type = '',
  ) => {
    console.log('setPrincipalesDeudasObj', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = principalesDeudasObj.filter(
      (element) => element.id === name,
    );
    if (elementExist.length !== 0) {
      const quetionsTemp = [];
      for (const element of principalesDeudasObj) {
        if (element.id !== elementExist[0].id) {
          quetionsTemp.push(element);
        }
      }
      setPrincipalesDeudasObj(quetionsTemp);
    } else {
      setPrincipalesDeudasObj([
        ...principalesDeudasObj,
        {
          id: name,
          question: numQuestion,
          value,
        },
      ]);
    }
  };
  const savePrincipalesDeudas = () => {
    const click = `ContinuarPrinciparesDeudas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    console.log('principalesDeudasObj', principalesDeudasObj);
    const objTemp = principalesDeudasObj.map((item) => item.id);
    seteditprincipalesDeudas(true);
    console.log(objTemp);
    sePrincipalesDeudas(objTemp.join());
    const resp = [
      {
        id: 'principalesDeudas',
        question: 2,
        value: objTemp.join(),
      },
    ];
    createJsonInput(Rutine, resp);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest3Deudas' });
  };

  useEffect(() => {
    console.log('effect', principalesDeudasObj);
    setActiveDeudaFormal(false);
    setActiveDeudaInformal(false);
    const selectorCss = '#root > div.sc-pbYBj.nGnSF > div.sc-pczax.ezRkqv > div > div:nth-child(20) > div:nth-child(';
    const selectorCss2 = '//*[@id="root"]/div[1]/div[5]/div/div[21]/div[';
    for (let i = 1; i <= 3; i++) {
      let elementoDiv = '';
      try {
        elementoDiv = document.querySelector(`${selectorCss + i})>div`);
        if (elementoDiv) {
          console.log('remover clase');
          elementoDiv.classList.remove('border-pink-active');
        }
      } catch (e) {
        elementoDiv = getElementByXpath(`${selectorCss2 + i}]/div`);
        if (elementoDiv) {
          console.log('remover clase');
          elementoDiv.classList.remove('border-pink-active');
        }
      }
    }
    for (let i = 0; i < principalesDeudasObj.length; i++) {
      let elementoDiv = '';

      switch (principalesDeudasObj[i].value) {
        case 428:
          if (
            document.getElementsByName('Formales')[0] !== undefined
            && document.getElementsByName('Formales')[0] !== null
          ) {
            document
              .getElementsByName('Formales')[0]
              .classList.add('selectedBen');
          }
          try {
            elementoDiv = document.querySelector(`${selectorCss}1)>div`);
            elementoDiv.classList.add('border-pink-active');
          } catch (e) {
            elementoDiv = getElementByXpath(`${selectorCss2}1]/div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          }
          setActiveDeudaFormal(true);
          break;
        case 429:
          if (
            document.getElementsByName('Informales')[0] !== undefined
            && document.getElementsByName('Informales')[0] !== null
          ) {
            document
              .getElementsByName('Informales')[0]
              .classList.add('selectedBen');
          }
          try {
            elementoDiv = document.querySelector(`${selectorCss}2)>div`);
            elementoDiv.classList.add('border-pink-active');
          } catch (e) {
            elementoDiv = getElementByXpath(`${selectorCss2}2]/div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          }
          setActiveDeudaInformal(true);
          break;
        default:
          break;
      }
    }
  }, [principalesDeudasObj]);
  const saveAdeudo = (e) => {
    e.preventDefault();
    console.log(questions);
    const click = `ContinuarAdeudo_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    if (getValidateMontosDuedas()) {
      setMontoAdeudo(form.montoAdeudo);
      setMontoAdeudoInformales(form.montoAdeudoInformales);
      const deudaTotal = convertirNum(form.montoAdeudo)
        + convertirNum(form.montoAdeudoInformales);
      console.log('DeudaTotal', deudaTotal);
      sessionStorage.setItem('DeudaTotal', `$${formatterMx(deudaTotal)}`);
      setDeudaTotal(deudaTotal);
      questions.push({
        id: 'deudaTotal',
        question: 5,
        value: `$${formatterMx(deudaTotal)}`,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setActiveBtnMontoDueda(false);
      setOnceValidateBtnDeuda(false);
      setOrderRutine(nextOrderRoutine);
    }
    seteditprincipalesDeudas(true);
    seteditmontoAdeudo(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest4Deudas' });
  };
  const getValidateMontosDuedas = () => {
    let isDeudadFormal = false;
    let isDeudaInformal = false;

    if (activeDeudaFormal && form.montoAdeudo !== '') {
      isDeudadFormal = true;
    }
    if (activeDeudaInformal && form.montoAdeudoInformales !== '') {
      isDeudaInformal = true;
    }

    if (isDeudadFormal || isDeudaInformal) {
      if (
        activeDeudaFormal === isDeudadFormal
        && activeDeudaInformal === isDeudaInformal
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const [activeBtnMontoDeuda, setActiveBtnMontoDueda] = useState(false);
  const [onceValidateBtnDeuda, setOnceValidateBtnDeuda] = useState(true);
  useEffect(() => {
    if (onceValidateBtnDeuda) {
      if (getValidateMontosDuedas()) {
        setActiveBtnMontoDueda(true);
      } else {
        setActiveBtnMontoDueda(false);
      }
    }
  });
  const convertirNum = (num) => (num ? parseFloat(num.replace('$', '').replace(/,/g, '').trim()) : 0);
  const textInfoAdd = [
    {
      text1:
        'Con bancos, financieras, automotriz, hipotecas, créditos, tiendas departamentales u otras entidades financieras',
    },
    {
      text2:
        'Mediante acuerdos o de palabra, tandas o préstamos con familiares, amigos, conocidos',
    },
  ];

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [edittienesDeudas, setedittienesDeudas] = useState(
    tienesDeudas !== '',
  );
  const [editprincipalesDeudas, seteditprincipalesDeudas] = useState(
    principalesDeudas !== '',
  );
  const [editmontoAdeudo, seteditmontoAdeudo] = useState(
    montoAdeudo !== '',
  );
  const handleChangeIcon = (json) => {
    setLapiz([json]);
    if (json.active === 1) {
      console.log('Entro a uno');
      setedittienesDeudas(false);
    }
    if (json.active === 2) {
      console.log('entro a dos');
      seteditprincipalesDeudas(false);
    }
    if (json.active === 3) {
      console.log('entro a 3');
      seteditmontoAdeudo(false);
    }
    console.log(lapiz);
    // lapiz.push(json);
    console.log(json);
  };

  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === 4 && tienesDeudas === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeDeudas' });
      }
    }, 4000);
  }, []);
  return (
    <>
      {!loaderWait || orderRutine !== 4 ? (
        <>
          <div id="activeDeudas"></div>
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              tienesDeudas === ''
              || (lapiz[0].active === 1 && !edittienesDeudas)
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeQuest2Deudas"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                tienesDeudas !== '' && edittienesDeudas ? 'iconEdit1' : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
            />
            <CardTwo
              classCard={
                tienesDeudas !== '' && edittienesDeudas
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={tienesDeudas}
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {/* pregunta numero dos opcional */}
      <QuestionsOrder
        classCard={
          tienesDeudas === 'Sí' && edittienesDeudas ? 'cardOne' : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (principalesDeudas === ''
            && tienesDeudas === 'Sí'
            && edittienesDeudas)
          || (edittienesDeudas && !editprincipalesDeudas)
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangePrincipalesDeudas}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
          AdditionalInformation={true}
          textInformation={textInfoAdd}
        />
      </TrainingQuestion>
      <TrainingQuestion
        mt="0px"
        mtw="0px"
        className={
          (principalesDeudasObj.length > 0
            && principalesDeudas === ''
            && edittienesDeudas)
          || (principalesDeudasObj.length > 0
            && edittienesDeudas
            && !editprincipalesDeudas)
            ? ''
            : 'hidden'
        }
      >
        <div style={{ display: 'flex' }}>
          <CardsBtn1
            mtop="0px"
            mtopmd="0px"
            windthmd="100%"
            onClick={() => savePrincipalesDeudas()}
          >
            Continuar
          </CardsBtn1>
        </div>
      </TrainingQuestion>
      <div id="activeQuest3Deudas"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            principalesDeudas !== '' && editprincipalesDeudas
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
        />
        <CardTwo
          classCard={
            principalesDeudas !== '' && editprincipalesDeudas
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={principalesDeudas}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* pregunta numero tres si selecciono formales */}
      <TrainingSectionGray
        widthn="90%"
        wiw="60%"
        padding="3%"
        paddinglG="3%"
        mn="25px 0 0 5%"
        mw="25px 0 0 18%"
        className={
          (montoAdeudo === ''
            && principalesDeudas !== ''
            && (activeDeudaFormal || activeDeudaInformal)
            && editprincipalesDeudas)
          || (montoAdeudo !== ''
            && editprincipalesDeudas
            && activeDeudaFormal
            && !editmontoAdeudo)
            ? ''
            : 'hidden'
        }
      >
        <TrainingQuestion
          width="100%"
          wiw="100%"
          mt="0"
          className={
            (montoAdeudo === ''
              && principalesDeudas !== ''
              && activeDeudaFormal
              && editprincipalesDeudas)
            || (montoAdeudo !== ''
              && editprincipalesDeudas
              && activeDeudaFormal
              && !editmontoAdeudo)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="transparent"
            bs="transparent"
          >
            <TrainingDivSection
              hew="auto"
              paddw="0px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="transparent"
              backw="transparent"
              bshaw="none"
              bsha=""
              br="4px"
              padding="0px"
              wi="90%"
              he="auto"
              className="topOne1 topOne1Init"
            >
              <form>
                <TrainingText
                  bot="0px"
                  className="cabin"
                  align="left"
                  weight="600"
                  size="16px"
                  sizew="16px"
                >
                  Aproximadamente, ¿cuál es el monto que adeudas formalmente?
                </TrainingText>
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="2-2"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                  labelInput="Monto"
                />
              </form>
            </TrainingDivSection>
          </QuestionsDivs>
        </TrainingQuestion>
        {/* pregunta numero tres si selecciono formales */}
        <TrainingQuestion
          width="100%"
          wiw="100%"
          mt="0"
          className={
            (montoAdeudoInformales === ''
              && principalesDeudas !== ''
              && activeDeudaInformal
              && editprincipalesDeudas)
            || (montoAdeudoInformales !== ''
              && editprincipalesDeudas
              && activeDeudaInformal
              && !editmontoAdeudo)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="transparent"
            bs="transparent"
          >
            <TrainingDivSection
              hew="auto"
              paddw="0px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="transparent"
              backw="transparent"
              bshaw="none"
              bsha=""
              br="4px"
              padding="0px"
              wi="90%"
              he="auto"
              className="topOne1 topOne1Init"
            >
              <form>
                <TrainingText
                  bot="0px"
                  className="cabin"
                  align="left"
                  weight="600"
                  size="16px"
                  sizew="16px"
                >
                  Aproximadamente, ¿cuál es el monto que adeudas informalmente?
                </TrainingText>
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="3-3"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                  labelInput="Monto"
                />
              </form>
            </TrainingDivSection>
          </QuestionsDivs>
        </TrainingQuestion>

        <TrainingQuestion
          mt="0px"
          mtw="0px"
          className={
            activeBtnMontoDeuda
            || (!editmontoAdeudo
              && (montoAdeudoInformales !== '' || montoAdeudo !== '')
              && editprincipalesDeudas)
            || (montoAdeudoInformales === ''
              && principalesDeudas !== ''
              && activeDeudaInformal
              && editprincipalesDeudas)
            || (montoAdeudoInformales !== ''
              && editprincipalesDeudas
              && activeDeudaInformal
              && !editmontoAdeudo)
              ? ''
              : 'hidden'
          }
        >
          <div style={{ display: 'flex' }}>
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={(e) => saveAdeudo(e)}
            >
              Continuar
            </CardsBtn>
          </div>
        </TrainingQuestion>
      </TrainingSectionGray>
      <div id="activeQuest4Deudas"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            (montoAdeudo !== '' || montoAdeudoInformales !== '')
            && editmontoAdeudo
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
        />
        <CardTwo
          classCard={
            (montoAdeudo !== '' || montoAdeudoInformales !== '')
            && editmontoAdeudo
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={montoAdeudo ? `Deuda formal: ${montoAdeudo}` : ''}
          text2={
            montoAdeudoInformales
              ? `Deuda informal: ${montoAdeudoInformales}`
              : ''
          }
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </>
  );
};

export default ThereAreDebts;
