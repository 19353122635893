import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SectionShare from '../../components/common/ShareSection';
import {
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import ClickButton from '../../Helpers/HookHelpers';
import ModalAmazon from '../../components/common/ModalAmazon';
import ProductsFremium from '../../components/common/ProductsFremium';
import InfoWoMonetizable from '../../components/common/InfoWoMonetizable';
const ShareDebts = () => {
  const { actions } = ClickButton();
  const [goFeed] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);
  return (
    <TrainingSectionGray back="#fafafa">
      <InfoWoMonetizable 
        nameClient={sessionStorage.getItem('FirstName') + ' ' + (sessionStorage.getItem('MiddleName') || '') + 
        ' ' + sessionStorage.getItem('FathersLastName') + ' '+ sessionStorage.getItem('MothersLastName')}
        telClient={sessionStorage.getItem('CellPhone')}
        emailClient={sessionStorage.getItem('Email1')}
      />
      <ModalAmazon show={true} wo={'Deudas'}></ModalAmazon>
      <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '12' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
      <TrainingDivSection2
        wiw="670px"
        paddi="0"
        paddiw="0"
        mw="auto"
        back="#fafafa"
      >
        <SectionShare WO="Deudas"></SectionShare>
      </TrainingDivSection2>
    </TrainingSectionGray>
  );
};

export default ShareDebts;
