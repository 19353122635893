/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';

// Import visual components
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// Import query components and helpers
import { useMutation, useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import SectionAdvance from '../Training/AdvanceBudget';
import SectionHappyFace from '../Training/SectionHappyFace';
import { filterFloat, formatterMoney } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import Upchevron from '../../assets/img/anacoach/up-chevron.png';
import DownChevron from '../../assets/img/anacoach/down-chevron.png';
import {
  GET_PROPERTIES_STATUS_GOALS,
} from '../../queries';
import { INSERT_DAT_GOAL } from '../../mutations';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  TrainingSubtitles,
  TrainingDivSection,
  TrainingBenef,
} from '../../assets/styles/Training.styled';
import {
  Col12,
  Container,
  Col2,
  Col4,
  Col10,
  DivGoals,
  DivContainerGoal,
  DivContainerTextGoal,
  Image,
} from './common.styled';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
         <Typography variant="h6">{children}</Typography>
         {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
               <CloseIcon />
            </IconButton>
         ) : null}
      </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const DetailsGoal = ({
  historyGoals, currentTraining, nameGoal, handelChangeRecoveryHistory, setPropertiesStatusGoals,
}) => {
  const { actions } = ClickButton();
  const [open, setOpen] = React.useState(false);
  const [feacturesArray, setFeacturesArray] = useState([]);
  const [detailsGoals, setDetailGoals] = useState([]);
  const [historyAmount, setHistoryAmount] = useState([]);
  const [viewHistoryAmount, setViewHistoryAmount] = useState(3);
  const [ahorro, setAhorro] = useState('');
  const [concepto, setConcepto] = useState('');

  const [metas, setMetas] = useState([]);
  const [montoMeta, setMontoMeta] = useState('');
  const [ahorroMensual, setAhorroMensual] = useState('');
  const [tiempodeAhorro, setTiempodeAhorro] = useState('');
  const [rachaQuincenal, setRachaQuincenal] = useState(0);
  const [progressColor, setProgressColor] = useState('#FFF');
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [avanceGoal, setAvanceGoal] = useState('0%');
  const [imageView, setImageView] = useState(DownChevron);
  const [insertDatGoal] = useMutation(INSERT_DAT_GOAL, {
    onCompleted({ insertDatGoal }) {
      if (insertDatGoal.message === 'success') {
        console.log('insercion exitossa, ID = ', insertDatGoal);
      }
      handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getPropertiesStatusGoals] = useLazyQuery(GET_PROPERTIES_STATUS_GOALS, {
    onCompleted({ getPropertiesStatusGoals }) {
      const properties = JSON.parse(getPropertiesStatusGoals.response);
      if (properties.length > 0) {
        setProgressColor(properties[0].color);
      }
      setPropertiesStatusGoals(properties);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [datum, setDatum] = useState([
    { key: 'Tu avance', y: 0, color: progressColor },
    { key: '', y: 100, color: '#F5F5F5' },
  ]);

  useEffect(() => {
    if (progressPercentage > 0) {
      getPropertiesStatusGoals({
        variables: { percent: progressPercentage },
      });
    }
  }, [progressPercentage]);

  useEffect(() => {
    setViewHistoryAmount(3);

    setRachaQuincenal(0);
    setDetailGoals([]);
    setAvanceGoal('0%');
    let isNameGoal = false;
    for (let i = 0; i < historyGoals.length; i++) {
      const goal = historyGoals[i];
      if (isNameGoal) break;
      for (const [key, value] of Object.entries(goal)) {
        if (key === nameGoal) {
          isNameGoal = true;
          if (value[0].details.length > 0) detailsGoals.push(value[0].details);
          if (value[0].history.length > 0) {
            setHistoryAmount([value[0].history]);
            setRachaQuincenal(value[0].history.length / 2);
            let suma = 0;
            for (const [item, itemValue] of Object.entries(value[0].history)) {
              suma += parseFloat(String(itemValue.amount).replace('$', '').replace(/,/g, '').trim());
              const avance = (suma * 100) / parseFloat(String(detailsGoals[0][0].Value).replace('$', '').replace(/,/g, '').trim());
              setProgressPercentage(avance);
              setAvanceGoal(`${avance.toFixed(2)}%`);
              setDatum([
                { key: 'Tu avance', y: avance, color: `${progressColor}` },
                { key: '', y: 100 - avance, color: '#F5F5F5' },
              ]);
            }
          } else {
            setHistoryAmount([]);
          }
          break;
        } else {
          setHistoryAmount([]);
        }
      }
    }

    if (detailsGoals.length > 0 && isNameGoal) {
      setMontoMeta(detailsGoals[0][0].Value);
      setAhorroMensual(detailsGoals[0][2].Value);
      setTiempodeAhorro(detailsGoals[0][3].Value);

      setFeacturesArray([
        {
          text: `Ahorra ${detailsGoals[0][1].Value} cada quincena durante ${detailsGoals[0][3].Value} meses.`,
          color: '#E3E660',
        },
        {
          text: 'Registra tu ahorro quincenal en Coru para dar seguimiento a tu meta.',
          color: '#E3E660',
        },
        {
          text: 'Una vez que cumplas tu meta te recomiendo crear un portafolio de inversiones para fortalecer tus ingresos.',
          color: '#E3E660',
        },
      ]);
    } else {
      setMontoMeta(0);
      setAhorroMensual(0);
      setTiempodeAhorro(0);
      setFeacturesArray([]);
      setDatum([
        { key: 'Tu avance', y: 0, color: progressColor },
        { key: '', y: 100, color: '#F5F5F5' },
      ]);
    }

    const goals = nameGoal.split(',');
    setMetas(goals);
  }, [historyGoals, nameGoal]);

  const handleClickOpen = () => {
    const click = `RegistraAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOpen(true);
  };
  const handleClickSaveAhorro = async () => {
    const click = `GuardarAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setHistoryAmount([]);
    const idUser = parseInt(sessionStorage.getItem('idUser'));
    const idCatTraining = currentTraining || parseInt(sessionStorage.getItem('idTraining'));
    const newAhorro = parseFloat(ahorro.replace('$', '').replace(/,/g, '').trim());
    const monto = newAhorro > 0 ? String(newAhorro.toFixed(2)) : '';

    const inputInsert = {
      input: {
        idUser,
        idTraining: idCatTraining,
        name: nameGoal,
        goalsDetails: [
          {
            idType: '343',
            value: montoMeta.toString(),
          },
          {
            idType: '344', // fechaMeta
            value: ahorro.replace('$', '').replace(/,/g, '').trim(),
          },
          {
            idType: '345', // fechaMeta
            value: ahorroMensual.toString(),
          },
          {
            idType: '346', // fechaMeta
            value: tiempodeAhorro.toString(),
          },
        ],
        saveAmount: monto,
        description: concepto,
      },
    };

    console.log('inputInsert >>> ', inputInsert);

    const response = await insertDatGoal({
      variables: inputInsert,
    });

    console.log('response >>> ', response);

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handelChangeAhorro = (e) => {
    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      setAhorro(formatterMoney.format(filterFloat(value)));
    } else if (e.target.value === '$') {
      setAhorro('');
    }
  };
  const [sizeHeight, setSizeHeight] = useState(document.body.scrollHeight);
  useEffect(() => {
    setSizeHeight(document.body.scrollHeight);
  });

  const handleViewMore = (e, view) => {
    e.preventDefault();
    let views = 3;
    let img = DownChevron;
    if (view > viewHistoryAmount) {
      views = view;
      img = Upchevron;
    }
    setViewHistoryAmount(views);
    setImageView(img);
  };

  return (
      <div>
         {feacturesArray.length > 0 && metas.length > 0 ? (
            <Container width="100%" mt="0.2rem" widthMobile="100%" mtMobile="0rem">
               <Col12 padding=" " className="cardPrincipal1">
                  <TrainingDivSection
                     hew="auto"
                     paddw="26px"
                     mlw="18%"
                     wiw="60%"
                     back="#F3F3F3"
                     backw="#F3F3F3"
                     bshaw="none"
                     bsha=""
                     br="4px"
                     padding="20px"
                     wi="90%"
                     he="auto"
                     mb="0px"
                     className="topOne1"
                  >
                     <TrainingSubtitles size="20px" fw="600" align="left" alignweb="left" className="cabin">
                        {`Estrategia ${nameGoal}`}
                     </TrainingSubtitles>
                     {feacturesArray.map((feacture, index) => (
                        <Container key={index} width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem">
                           <Col2 display="flex" mt="initial">
                              <TrainingBenef
                                 bbs="solid"
                                 bbsw="none"
                                 bbw="1px"
                                 bbc="#CDCDCD"
                                 pt="17px"
                                 pb="17px"
                                 he="auto"
                                 pl="0px"
                                 mt="0px"
                                 className="roboto"
                                 alignweb="center"
                                 ls="-0.2px"
                                 width="100%"
                              >
                                 <CheckBoxIcon style={{ color: feacture.color, fontSize: 23 }} />
                              </TrainingBenef>
                           </Col2>
                           <Col10 padding="0px" pMobile="0px 15px">
                              <TrainingBenef
                                 bbs="solid"
                                 bbsw="none"
                                 bbw="1px"
                                 bbc="#CDCDCD"
                                 pt="17px"
                                 pb="17px"
                                 he="auto"
                                 pl="0px"
                                 mt="0px"
                                 className="roboto"
                                 alignweb="left"
                                 ls="-0.2px"
                              >
                                 {feacture.text}
                              </TrainingBenef>
                           </Col10>
                        </Container>
                     ))}
                  </TrainingDivSection>
               </Col12>
            </Container>
         ) : null}

         <Container mleft='18%' id="DivPrincipal" width="60%" mt="2rem" widthMobile="90%" mtMobile="1rem">
            <Col4 display="flex" mt="initial" jc="center">
               <DivContainerGoal>
                  <Container width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem">
                     <Col12 display="flex" mt="initial">
                        <DivContainerTextGoal fw="600" className="cabin">
                           {tiempodeAhorro > 1 ? `${tiempodeAhorro} meses` : `${tiempodeAhorro} mes`}
                        </DivContainerTextGoal>
                     </Col12>
                     <Col12 display="flex" mt="initial">
                        <DivGoals className="roboto">Tiempo para meta</DivGoals>
                     </Col12>
                  </Container>
               </DivContainerGoal>
            </Col4>

            <Col4 display="flex" mt="initial" jc="center">
               <DivContainerGoal>
                  <Container width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem">
                     <Col12 display="flex" mt="initial">
                        <DivContainerTextGoal fw="600" className="cabin">
                           {montoMeta}
                        </DivContainerTextGoal>
                     </Col12>
                     <Col12 display="flex" mt="initial">
                        <DivGoals className="roboto">Monto de meta</DivGoals>
                     </Col12>
                  </Container>
               </DivContainerGoal>
            </Col4>
            <Col4 display="flex" mt="initial" jc="center">
               <DivContainerGoal>
                  <Container width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem">
                     <Col12 display="flex" mt="initial">
                        <DivContainerTextGoal fw="600" className="cabin">
                           ${new Intl.NumberFormat('en-IN').format(ahorroMensual)}
                        </DivContainerTextGoal>
                     </Col12>
                     <Col12 display="flex" mt="initial">
                        <DivGoals className="roboto">Ahorro mensual</DivGoals>
                     </Col12>
                  </Container>
               </DivContainerGoal>
            </Col4>
         </Container>
         <SectionAdvance
            datum={datum}
            avance={avanceGoal}
            mesesConcluidos={rachaQuincenal}
            tiempodeAhorro={tiempodeAhorro}
            goal={metas[0]}
         />
         {historyAmount.length > 0 ? (
            <>
               {historyAmount.map((element, i) => (
                  <div key={i}>
                     {element.map((item, j) => (
                        <>
                           {j < viewHistoryAmount ? (
                              <Grid id='testTable'>
                                 <HistoryStyle
                                    key={j}
                                    width="80%"
                                    margin="8px auto auto 5%;"
                                    hei="auto"
                                    padd="2%"
                                    bs="0px 2px 8px rgba(0,0,0,0.08)"
                                 >
                                    <Concepto>
                                       <div
                                          style={{
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            lineHeight: '19px',
                                            color: '#07210A',
                                          }}
                                          className="roboto"
                                       >
                                          {item.description}
                                       </div>
                                       <div className="roboto">{`Quincena ${j + 1} de ${tiempodeAhorro * 2} `}</div>
                                    </Concepto>
                                    <Amount>{formatterMoney.format(item.amount)}</Amount>
                                 </HistoryStyle>

                                 <div
                                    style={{ minWidth: '10%', textAlign: 'center', paddingTop: '3%' }}
                                    onClick={(e) => handleViewMore(e, element.length)}
                                 >
                                    <Image
                                       className={j === 0 ? '' : 'hidden'}
                                       src={imageView}
                                       maxwidth="35%"
                                       width="35%"
                                       right="1em"
                                       alt="Ver mas"
                                    />
                                 </div>
                              </Grid>
                           ) : null}
                        </>
                     ))}
                  </div>
               ))}
            </>
         ) : null}

         <TrainingQuestion>
            <CardsBtn1
               type="button"
               color="#EC407A !important"
               back="#fff !important"
               border="solid 1px !important"
               windthmd="100%"
               onClick={(e) => handleClickOpen(e)}
            >
               Registra ahorro
            </CardsBtn1>
         </TrainingQuestion>

         <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
               Registra ahorro
            </DialogTitle>
            <DialogContent>
               <DialogContentText></DialogContentText>
               <TextField
                  variant="outlined"
                  label="Concepto ahorro Ejem: Quincena 1"
                  autoComplete="off"
                  required
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  className="textInput inputs-bottom input-coru"
                  value={concepto}
                  onBlur={(e) => setConcepto(e.target.value)}
                  onChange={(e) => setConcepto(e.target.value)}
               />
               <TextField
                  variant="outlined"
                  label="Monto de ahorro"
                  autoComplete="off"
                  required
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  className="textInput inputs-bottom input-coru"
                  value={ahorro}
                  onBlur={(e) => handelChangeAhorro(e)}
                  onChange={(e) => handelChangeAhorro(e)}
               />
            </DialogContent>
            <DialogActions>
               <CardsBtn type="button" min_w="100%" min_web="100%" onClick={(e) => handleClickSaveAhorro(e)}>
                  Guardar
               </CardsBtn>
            </DialogActions>
         </Dialog>
         <SectionHappyFace />
      </div>
  );
};
const Grid = styled.div`
   display: inline-flex;
   flex-direction: row;
   justify-content: center;
   width: 65%;
   margin: auto 0 0 15%;
   @media (max-width: 767px) {
      width: ${(props) => props.widthweb || '100%'};
      margin: auto;
   }
`;
const Concepto = styled.div`
   display: flex;
   flex-direction: column;
   width: 80%;
`;
const Amount = styled.div`
   width: 20%;
`;
const HistoryStyle = styled.div`
   display: inline-flex;
   flex-direction: row;
   border-radius: 8px;
   background-color: ${(props) => props.bc || '#F7F7F7'};
   display: ${(props) => props.display || ''};
   height: ${(props) => props.hei || '52px'};
   margin: ${(props) => props.margin || '8px'};
   padding: ${(props) => props.padd || '5px'};
   width: ${(props) => props.width || '45%'};
   box-shadow: ${(props) => props.bs || ''};
   cursor: pointer;
   @media (min-width: 768px) {
      width: ${(props) => props.widthweb || ''};
   }
`;

export default DetailsGoal;
