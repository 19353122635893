import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import DatosBasicos from '../Routines/DatosBasicos';
import PerfilInversion from '../Routines/PerfilInversion';
import TipsInversiones from '../Routines/TipsInversiones';
import Features from '../../components/Training/Features';
import ProductsFremium from '../../components/common/ProductsFremium';
import {
  Feed_Main,
} from '../../assets/styles/Feed.styled';
import CalculadoraInversion from '../Routines/CalculadoraInversion';
import {
  GET_PRODUCTS_BY_IDTRAINING,
  GET_TRAINING_PREVIEW,
} from '../../queries';
import {
  GET_RECOMMENDATION_TRAININGS,
  CREATE_OPPORTUNITIES,
  GET_NIP_TO_USER,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import {
  CardsBtn,
  CardsBtn1,
  Resultado,
  TrainingText,
} from '../../assets/styles/Training.styled';
import {
  Col5,
  Col12,
  ParagrapTwo,
} from '../../components/common/common.styled';
import coruCupon from '../../assets/img/anacoach/Coru-Cupon.png';
import { Container } from '../../assets/styles/Home.styled';
import SectionShare from '../../components/common/ShareSection';
import CardOne from '../../components/Home/Card1Animate';
import RpThanks from '../../components/common/RpThanks';
import ClickButton from '../../Helpers/HookHelpers';
import ModalAmazon from '../../components/common/ModalAmazon';
import Starttraining from '../../components/Training/Starttraining';
import Bookmark from '../../assets/img/logros/Bookmark_Standard.svg';
import Share from '../../assets/img/logros/Share_Standard.svg';
import Wallet from '../../assets/img/logros/Wallet_Standard.svg';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderFirstInervsion = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
  reward,
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const [inversionProducts, setInversionProducts] = useState([]);
  const [activarFlujo, setActivarFlujo] = useState('');
  const [productoPreview, setProductoPreview] = useState({
    name: '',
    status: '',
    tipo: '',
    tiempo: '',
    destacado: '',
    url: '',
    urlvideo: '',
    UrlBigImage: '',
    UrlMediumImage: '',
    UrlTwinImage: '',
    TrainingBigTitle: '',
    TrainingMediumTitle: '',
    TrainingTwinTitle: '',
    TrainingBigDescription: '',
    TrainingMediumDescription: '',
    TrainingTwinDescription: '',
    rutinas: [],
    Show: '',
    TrainingColor: '',
  });
  const anchop = window.screen.widt;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const abrirNuevoTab = (product) => {
    const click = `Invertir_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2, // remplazar por el id del entrenamiento
        idProducts: [product.IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 17,
        idType: '353',
        value: product.Instrumento,
      },
    };
    console.log(opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
    sessionStorage.setItem('clickOutProduct', product.url);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    const win = window.open(product.url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  const [getTrainingPreview] = useLazyQuery(GET_TRAINING_PREVIEW, {
    onCompleted({ getTrainingPreview }) {
      console.log('productos', getTrainingPreview.response.training);

      setProductoPreview({
        id: getTrainingPreview.response.training.IDCatTraining
          ? getTrainingPreview.response.training.IDCatTraining
          : '',
        CatVerticals_IDVertical: getTrainingPreview.response.training
          .CatVerticals_IDVertical
          ? getTrainingPreview.response.training.CatVerticals_IDVertical
          : '',
        name: getTrainingPreview.response.training.Name
          ? getTrainingPreview.response.training.Name
          : '',
        status:
          getTrainingPreview.response.training.Status === 'true'
            ? 'true'
            : 'false',
        tiempo: getTrainingPreview.response.training.TrainingTime
          ? getTrainingPreview.response.training.TrainingTime
          : '',
        tipo: getTrainingPreview.response.training.TrainingType
          ? getTrainingPreview.response.training.TrainingType
          : '',
        destacado: getTrainingPreview.response.training.Outstanding
          ? getTrainingPreview.response.training.Outstanding
          : '',
        url: getTrainingPreview.response.training.TrainingRedirectUrl
          ? getTrainingPreview.response.training.TrainingRedirectUrl
          : '',
        urlvideo: getTrainingPreview.response.training.UrlVideo
          ? getTrainingPreview.response.training.UrlVideo
          : '',
        UrlBigImage: getTrainingPreview.response.training.UrlBigImage,
        UrlMediumImage: getTrainingPreview.response.training.UrlMediumImage,
        UrlTwinImage: getTrainingPreview.response.training.UrlTwinImage,
        TrainingBigTitle: getTrainingPreview.response.training.TrainingBigTitle,
        TrainingMediumTitle:
          getTrainingPreview.response.training.TrainingMediumTitle,
        TrainingTwinTitle:
          getTrainingPreview.response.training.TrainingTwinTitle,
        TrainingBigDescription:
          getTrainingPreview.response.training.TrainingBigDescription,
        TrainingMediumDescription:
          getTrainingPreview.response.training.TrainingMediumDescription,
        TrainingTwinDescription:
          getTrainingPreview.response.training.TrainingTwinDescription,
        rutinas: getTrainingPreview.response.routines,
        Show: getTrainingPreview.response.Show || true,
        TrainingColor: getTrainingPreview.response.TrainingColor || '#91D770',
      });
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    getTrainingPreview({
      variables: {
        Id: '23',
      },
    });
  }, []);

  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsByIdTraining }) {
      const products = JSON.parse(getProductsByIdTraining.response);
      const catProducts = [];
      for (let item = 0; item < products.length; item++) {
        const product = products[item];
        const tasa = product.benefits.filter((item) => {
          if (item.Name === 'Tasa') return item.Description;
        });
        const MontoMinimo = product.benefits.filter((item) => {
          if (item.Name === 'MontoMinimo') return 'Monto mínimo';
        });

        if (MontoMinimo.length > 0) {
          product.montoMin = parseFloat(
            String(MontoMinimo[0].Description).replace('%', '').trim(),
          );
        }

        if (tasa.length > 0) {
          product.tasa = parseFloat(
            String(tasa[0].Description === 'Variable' ? 0 : tasa[0].Description)
              .replace('%', '')
              .trim(),
          );
        } else {
          product.tasa = 0;
        }

        catProducts.push(product);
      }
      sessionStorage.setItem(
        'productsFirstInvestment',
        JSON.stringify(catProducts),
      );
    },
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    getProductsByIdTraining({
      variables: { idTraining: String(currentTraining) },
    });
  }, []);

  const styleBtnDisabled = {
    color: '#fff',
    background: '#aaa',
    border: 'solid 1px #fff',
    display: 'none',
  };
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [btnContinueMoney, setBtnContinueMoney] = useState({});
  const [btnContinueFlujo, setBtnContinueFlujo] = useState({});
  const advanceRoutine = () => {
    const click = `ContinuarInversionista_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueMoney(styleBtnDisabled);
    setOrderRutine(5);
    gsap.to(window, { duration: 1, scrollTo: '#ActivoSuccess' });
  };
  const advanceFlujo = () => {
    const click = `ContinuarCalculadora_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueFlujo(styleBtnDisabled);
    setOrderRutine(4);
    gsap.to(window, { duration: 1, scrollTo: '#ActivoCalculadora' });
  };
  const history = useHistory();
  const handleClickTraining = (training) => {
    history.push(training.url);
  };
  const obtenerNIP = (e) => {
    e.persist();
    getNipToUser({ variables: { idUser: sessionStorage.getItem('idUser') } });
  };

  const [nipAsignado, setNipAsignado] = useState('');
  const [getNipToUser] = useMutation(GET_NIP_TO_USER, {
    onCompleted({ getNipToUser }) {
      if (getNipToUser.statusCode === 200 && getNipToUser.response.length > 0) {
        const { nip } = getNipToUser.response[0];
        sessionStorage.setItem('nipVirality', nip);
        setNipAsignado(nip);
      }
    },
  });
  useEffect(() => {
    if (sessionStorage.getItem('nipVirality')) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'Otpemail',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [nipAsignado]);

  useEffect(() => {
    if (orderRutine === 'rp') {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [inversionProducts]);
  const feacturesArray = useState([
    {
      text: 'Te motiva',
      color: '#99F2A8',
    },
    {
      text: 'Te preguntará tu ingreso',
      color: '#99F2A8',
    },
    {
      text: 'Te da rasgos personales',
      color: '#99F2A8',
    },
    {
      text: 'Te ofrece productos de acuerdo a tu perfil',
      color: '#99F2A8',
    },
  ]);

  useEffect(() => {
    if (activarFlujo === 'perfilInversionista') {
      history.push('/wo/investment-test');
    }
  }, [activarFlujo]);

  const formatExtraText = (item) => {
    const { Name, Description } = item;

    let title = Name;
    let description = Description;
    let isNumber = false;

    switch (Name) {
      case 'MontoMinimo':
        title = 'Monto mínimo';
        isNumber = true;
        break;
      case 'Monto Minimo':
        title = 'Monto mínimo';
        isNumber = true;
        break;
      case 'Información Extra Lista':
        return Description;
      default:
        break;
    }

    if (isNumber) {
      const text = description.replace(',', '').replace('.', '');

      const number = parseInt(text);

      if (number) {
        const len = text.length;

        // const comas = Math.floor(len / 4);

        let flag = 0;
        let newNumber = [];

        for (let i = len - 1; i >= 0; i--) {
          newNumber.push(text[i]);

          if (flag === 2 && i > 0) {
            flag = 0;
            newNumber.push(',');
          }
          flag++;
        }

        newNumber = newNumber.reverse();
        description = `$${newNumber.join('')}`;
      }
    }

    return `${title}: ${description}`;
  };

  return (
    <>
      {orderRutine >= 1 ? (
        // <DatosBasicos
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        //   trainingColor={trainingColor}
        // />
        <ModalLogin
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 ? (
        <>
          <Starttraining
            text={
              <>
                Pero antes de continuar, te compartimos un video donde conocerás
                cómo <span style={{ fontWeight: 'bold' }}>CORU</span> te ayuda a
                elegir el método de inversión que más te conviene.
              </>
            }
          />
          <PerfilInversion
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={2}
            nextOrderRoutine={3}
            setActivarFlujo={setActivarFlujo}
            trainingColor={trainingColor}
          />
        </>
      ) : null}
      {orderRutine >= 3 && activarFlujo === '' ? (
        <TipsInversiones
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
        />
      ) : null}

      <CardOne
        classCard={activarFlujo === 'calculadora' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        textbold="¡Cada vez estás más cerca de invertir!"
        text="Sabemos que decidir el plazo y monto de tu primera inversión no siempre es fácil ni claro.
					Para ayudarte está el Comparador Coru de mi Primera Inversión, usarlo es muy sencillo:"
        text2="-Ingresa el monto de dinero que te gustaría invertir"
        text3="-Indica el plazo de tiempo que podrías dejar invertido tu dinero."
        text4="¡Listo! El comparador te mostrará cuánto podrías ganar según el plan de inversión que elijas."
        hora={hora}
      />
      <div id="ActivoCalculadora"></div>
      <CardsBtn1
        className={activarFlujo === 'calculadora' ? '' : 'hidden'}
        style={btnContinueFlujo}
        onClick={(e) => {
          advanceFlujo(e);
        }}
      >
        Continuar
      </CardsBtn1>
      {activarFlujo === 'perfilInversionista' ? (
        <Features
          title="Test perfil de inversionista"
          Arraytext={feacturesArray}
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          textintro="Sabemos que decidir el plazo y monto de tu primera inversión no siempre es fácil ni claro. Para ayudarte está el Comparador Coru de mi Primera Inversión, usarlo es muy sencillo:"
        />
      ) : null}
      <div id="ActivoSuccess"></div>
      <CardsBtn1
        className={activarFlujo === 'perfilInversionista' ? '' : 'hidden'}
        style={btnContinueMoney}
        onClick={(e) => {
          advanceRoutine(e);
        }}
      >
        Continuar
      </CardsBtn1>
      {orderRutine >= 4 ? (
        <CalculadoraInversion
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={'rp'}
          setInversionProducts={setInversionProducts}
          trainingColor={trainingColor}
        />
      ) : null}

      {orderRutine === 5 ? (
        <RpThanks
          productos={[productoPreview]}
          handleClickTraining={handleClickTraining}
        ></RpThanks>
      ) : null}
      {inversionProducts.length > 0 ? (
        <>
          <ModalVirality />
          <br />
          {inversionProducts.map((producto) => {
            console.log(inversionProducts);
            return (
              <>
                <Resultado
                  bcolor="#fff"
                  ta="left"
                  marriweb="auto"
                  marri=" "
                  border="none"
                  hei="auto"
                  minh="453px"
                  minhweb="auto"
                >
                  <div
                    className="row"
                    style={{ margin: 'auto', width: '100%' }}
                  >
                    <div className="col-6"></div>
                    <div className="col-2">
                      <img src={Wallet} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="col-2">
                      <img src={Bookmark} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="col-2">
                      <img src={Share} style={{ cursor: 'pointer' }} />
                    </div>

                    <div className="col-6" style={{ marginTop: '20px' }}>
                      <img
                        src={producto.ImageURL}
                        style={{ width: '100px', margin: 'auto' }}
                      />
                    </div>
                    <div className="col-6" style={{ marginTop: '20px' }}>
                      <TrainingText
                        size="12px"
                        sizew="12px"
                        weight="400"
                        bot="0px"
                        className="roboto"
                        align="right"
                        alignweb="right"
                        ls="-0.2px"
                      >
                        Ganancia total
                      </TrainingText>
                      <TrainingText
                        size="18px"
                        sizew="18px"
                        weight="600"
                        className="roboto"
                        align="right"
                        alignweb="right"
                        ls="-0.2px"
                      >
                        {formatter.format(producto.resultado)}
                      </TrainingText>
                    </div>

                    <div className="col-5">
                      <TrainingText
                        size="19px"
                        sizew="19px"
                        weight="600"
                        className="roboto"
                        align="left"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        {producto.Instrumento}
                      </TrainingText>
                    </div>
                    <div className="col-7">
                      <TrainingText
                        size="12px"
                        sizew="12px"
                        weight="400"
                        className="roboto"
                        align="right"
                        alignweb="right"
                        ls="-0.2px"
                      >
                        Tasa de intereses {producto.taza}
                      </TrainingText>
                    </div>

                    <div className="col-12">
                      <TrainingText
                        size="14px"
                        sizew="14px"
                        weight="600"
                        className="roboto"
                        align="left"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        Beneficios
                      </TrainingText>
                      <ul style={{ paddingLeft: '18px' }}>
                        {producto.caracteristicas.map((item) => (
                          <>
                            {item.IDProductDetailType === 3 ? (
                              <li>{item.Description}</li>
                            ) : null}
                          </>
                        ))}
                      </ul>
                    </div>
                    <div className="col-12">
                      <TrainingText
                        size="14px"
                        sizew="14px"
                        weight="600"
                        className="roboto"
                        align="left"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        Información extra
                      </TrainingText>
                      <ul style={{ paddingLeft: '18px' }}>
                        {producto.caracteristicas.map((item) => (
                          <>
                            {
                              item.IDProductDetailType !== 3 &&
                                item.Name !== 'MontoMinimo' &&
                                item.Description !== '0' ?
                                (
                                  <li>{formatExtraText(item)}</li>
                                ) : null}
                          </>
                        ))}
                      </ul>
                    </div>
                    <div className="col-12">
                      <CardsBtn
                        type="button"
                        windthmd="100%"
                        minmd="100%"
                        mleftmd="0%"
                        mrightmd="auto"
                        windthG="100%"
                        min_w="100%"
                        min_web="100%"
                        min_mini="100%"
                        mleftmini="10%"
                        mrightmini="10%"
                        mleft="0%"
                        mright="0%"
                        mleftweb="0%"
                        mrightweb="auto"
                        onClick={() => {
                          abrirNuevoTab(producto);
                        }}
                      >
                        Invertir
                      </CardsBtn>
                    </div>
                  </div>
                </Resultado>
              </>
            );
          })}
        </>
      ) : null}
      {inversionProducts.length > 0 ? (
        <>
          <ProductsFremium
            idT={parseInt(sessionStorage.getItem('idTraining'))}
          />
          <ModalAmazon show={true} wo={'PrimeraInversión'}></ModalAmazon>
          <SectionShare WO="PrimeraInversión"></SectionShare>
        </>
      ) : null}
      {inversionProducts.length > 0 && recomendaciones && reward ? (
        <Feed_Main width="100%" background=" ">
          <Feed_Main width="100%" display=" " mtop=" " background="transparent">
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="Aquí tienes tu regalo."
            />
            <Col12 ta="center">
              <Container
                width="100%"
                mt="0%"
                widthMobile="100%"
                mtMobile="0%"
                float="auto"
              >
                <Col5 className="mobileWithChicos" padding="0" border="">
                  <div
                    className="col-12 col-sm-12 col-xs-12"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      obtenerNIP(e);
                    }}
                  >
                    <img
                      alt=""
                      src={coruCupon}
                      className="col-12 col-sm-12 col-xs-12 img-responsive"
                      left="30%"
                      top="15px"
                      position="absolute"
                    ></img>
                    <ParagrapTwo
                      mtWeb="-2.8rem"
                      mb="5%"
                      textalignMobile="right"
                      texttalign="right"
                      className="mx-auto roboto col-12 col-sm-10 col-xs-10"
                      mt="-12%"
                      fontSize="12px"
                    >
                      Da clic aquí y
                    </ParagrapTwo>
                    <ParagrapTwo
                      mtWeb="-0.5rem"
                      mb="5%"
                      textalignMobile="right"
                      texttalign="right"
                      className="mx-auto roboto col-12 col-sm-10 col-xs-10"
                      mt="-5%"
                      fontSize="12px"
                    >
                      descubre tu regalo
                    </ParagrapTwo>
                  </div>
                </Col5>
              </Container>
            </Col12>
            {nipAsignado !== '' ? (
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                textbold="Hemos enviado a tu correo electrónico tu NIP para reclamar tu regalo."
              />
            ) : null}
          </Feed_Main>
        </Feed_Main>
      ) : null}
    </>
  );
};
export default RoutinesOrderFirstInervsion;
