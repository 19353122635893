import React from "react";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import Lottie from "react-lottie";
import Anim1 from "../Assets/Lotties/Coruproductos1.json";
import Anim2 from "../Assets/Lotties/Coruproductos2.json";
import Anim3 from "../Assets/Lotties/Coruproductos3.json";

const MyCarousel = ({ actualPage, setActualPage }) => {
  const renderPagination = ({ pages, activePage, onClick }) => (
    <div className="pagination" style={{ marginTop: "15px" }}>
      {pages.map((page) => {
        const isActive = activePage === page;
        const backgroundColor = isActive ? "#6200EA" : "#E9D8FF";
        const buttonStyle = {
          backgroundColor,
        };

        return (
          <SelectorButton
            key={page}
            onClick={() => onClick(page, setActualPage(page))}
            style={buttonStyle}
          />
        );
      })}
    </div>
  );

  return (
    <section style={{ width: "100%", height: "100%" }}>
      <Carousel
        renderPagination={renderPagination}
        onChange={(e) => setActualPage(e.index)}
        enableAutoPlay
        autoPlaySpeed={12000}
      >
        <PageContainer>
          <Lottie
            style={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "12px",
              margin: "0 0 5px",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Anim1,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </PageContainer>
        <PageContainer>
          <Lottie
            style={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "12px",
              margin: "0 0 5px",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Anim2,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </PageContainer>
        <PageContainer>
          <Lottie
            style={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "12px",
              margin: "0 0 5px",
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Anim3,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </PageContainer>
      </Carousel>
    </section>
  );
};

export default MyCarousel;

const SelectorButton = styled.div`
  width: 40px;
  height: 6px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 15px;
`;

const PageContainer = styled.section`
  min-width: 200px;
  height: fit-content;
`;
