/* eslint-disable consistent-return */
/* eslint-disable space-before-blocks */
/* eslint-disable max-len */
export const day = [];
// eslint-disable-next-line no-plusplus
for (let i = 1; i <= 31; i++) {
  day.push({
    value: i <= 9 ? `0${i}` : i,
    label: i,
  });
}
export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
].map((month, index) => ({
  label: month,
  value: index < 9 ? `0${index + 1}` : index + 1,
}));

export const years = [];
const fecha = new Date();
const anio = fecha.getFullYear();
const limit = anio - 18;
// eslint-disable-next-line no-plusplus
for (let i = 1900; i < limit + 1; i++) {
  years.push({
    value: i,
    label: i,
  });
}

export const getFielOption = (items, value) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const i in items) {
    if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
      return {
        idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
        catDetailTypeUsers_IDType: items[i].IDType,
        fieldOption: items[i].FieldOption,
      };
    }
  }
};

export const updateBinacle = (field, updateSession, createSession) => {
  const timestap = new Date().toLocaleString();
  const mongoInputBinacle = {};
  mongoInputBinacle[field] = [
    {
      value: sessionStorage.getItem(field),
      timestap,
    },
  ];
  mongoInputBinacle.lastUpdate = timestap;
  if (sessionStorage.getItem('id_binacle')) {
    const filterJson = {
      _id: sessionStorage.getItem('id_binacle'),
    };
    const dataStringBinacle = JSON.stringify(mongoInputBinacle);
    const filterString = JSON.stringify(filterJson);
    const mongoInput = {
      input: {
        collection: 'binacle',
        data: dataStringBinacle,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoInput });
  } else {
    mongoInputBinacle.idCont = sessionStorage.getItem('idCont');
    mongoInputBinacle.idUser = sessionStorage.getItem('idUser');
    mongoInputBinacle.lastUpdate = timestap;
    const dataStringBinacle = JSON.stringify(mongoInputBinacle);
    const mongoInput = {
      input: {
        collection: 'binacle',
        data: dataStringBinacle,
      },
    };
    createSession({ variables: mongoInput });
  }
};

export const arraySessionsRutines = {
  81: 'RFC',
  1: 'Credito_automotriz_bancario_en_los_ultimos_do',
  2: 'Ahorro_de_autenticacion',
  8: 'Tiene_tarjeta_de_credito_vigente',
  11: 'Gasto_mensual_en_creditos',
  12: 'Tiene_Historial_Crediticio',
  7: 'Hipoteca_Vigente',
  36: 'Fecha_de_Autenticacion',
  14: 'Pais_de_nacimiento',
  18: 'Numero_exterior_de_la_empresa',
  19: 'Numero_Interior_de_la_Empresa',
  37: 'Comentario_/_Exclusion',
  38: 'Motivo_de_no_autenticacion',
  74: 'FirstName',
  75: 'MiddleName',
  76: 'FathersLastName',
  77: 'MothersLastName',
  78: 'Birthdate',
  79: 'Email1',
  80: 'CellPhone',
  20: 'Ingreso_Mensual_Fijo',
  13: 'Gender',
  25: 'BirthState',
  3: 'Ulltimo_grado_de_estudios',
  26: 'MaritalStatus',
  28: 'Codigo_Postal_de_la_Empresa',
  29: 'Colonia_de_la_empresa',
  30: 'Numero_de_Identificacion_Oficial',
  45: 'Curp',
  6: 'Numero_de_dependientes',
  82: 'AddressType',
  83: 'Street',
  84: 'ExtNo',
  85: 'IntNo',
  86: 'Suburb',
  87: 'Gmaps',
  88: 'ZipCode',
  90: 'State',
  91: 'Municipality',
  92: 'Complete_Address',
  93: 'BCScore',
  94: 'Status_de_autenticación',
  95: 'Tipo_de_exclusión',
  10: 'Tiempo_de_vivir_en_domicilio',
  5: 'Tipo_de_Vivienda',
  89: 'HomePhone',
  39: 'Banco_tarjeta_actual_del_usuario',
  9: 'Ultimos_4_digitos_de_tarjeta',
  4: 'Ocupacion',
  15: 'Tipo_de_Industria',
  16: 'Nombre_de_la_empresa',
  27: 'Tiempo_de_laborar_en_la_empresa_en_años',
  21: 'Nombre_Completo_Referencia_Familiar',
  22: 'Telefono_de_Referencia_Familiar',
  23: 'Nombre_Completo_de_una_Referencia_no_familiar',
  24: 'Telefono_de_Referencia_no_Familiar',
  121: 'Motivo del Crédito',
  122: 'Cantidadquenecesita',
  123: 'Plazodeseado PL',
  124: 'Motivoparaobtenerunatarjeta',
  125: 'TipoCuentaDeposito',
  126: 'TarjetaDeposito',
  127: 'ClabeDeposito',
  128: 'Quetarjetatienes',
  129: 'Comopagastutarjeta',
  130: '3beneficiosentarjetas',
  131: 'Redencióndepuntos',
  132: 'Cadacuantodifierestuscompras',
  133: 'Cadacuantoviajas',
  134: 'bancoActual',
  135: 'Numero_INE',
  136: 'EsshortLead',
  137: 'ComposicionFamiliar',
  138: 'CuantosHijosTienes',
  139: 'EdadesHijo1',
  140: 'EdadesHijo2',
  141: 'EdadesHijo3',
  142: 'EdadesHijo4',
  143: 'EdadesHijo5',
  144: 'EdadPadre',
  145: 'EdadMadre',
  146: 'Preocupaciones',
  147: 'SeguroMedico',
  148: 'TipoSeguroMedico',
  149: 'DispuestoAcontratar',
  150: 'DispuestoApagarSeguro',
  151: 'QueAsegurar',
  152: 'Aseguradora',
  153: 'SumaAsegurada',
  154: 'Hospitales',
  155: 'CasaOdepartamentoPropio',
  156: 'ZonaDeVivienda',
  157: 'PresupuetoFamiliarMensual',
  158: 'SeguroFamiliar',
  159: 'PrioridadFamiliar',
  160: 'EnfermedadesFamiliares',
  161: 'TrabajoDeAltoRiesgo',
  162: 'PrioridadFamiliarNoEstoy',
  163: 'ViajesAlAnio',
  164: 'ComprasEnViajes',
  165: 'PrioridadFamiliarViaje',
  166: 'MontoDeudas',
  167: 'AtrasopPagos',
  168: 'TiempodeRetraso',
  169: 'TipoDeuda',
  170: 'GanarDineroExtra',
  172: 'MejorarAhorro',
  173: 'OportunidadesPrestamo',
  174: 'BancosDeudas',
  175: 'DepartamentalesDeudas',
  176: 'VideoAutomotriz',
  177: 'InstitucionHipotecaria',
  178: 'SolucionesDeudaInmueble',
  179: 'SolucionInfonavit',
  180: 'SolucionInfonavit',
  181: 'SolucionBanco',
  182: 'CapacidadDePago',
  183: 'VideoDietaAhorro',
  184: 'VideoPagarAntes',
  185: 'VideoReestructuración',
  186: 'AvisosPago',
  187: 'DespachosCobranza',
  188: 'NegociacionDirecta',
  189: 'ConocimientoFinanzas',
  190: 'Video',
  191: 'Ingresos2',
  192: 'Ocupacion2',
  193: 'TieneSeguro',
  194: 'TipoSeguro',
  195: 'TienesTarjetaCredito',
  196: 'FindeMes',
  197: 'PrioridadFinanzas',
  198: 'CodigoPostal2',
  199: 'DineroFindeMes',
  200: 'TienesDeudas2',
  201: 'PreferDinero',
  202: 'VideoDeudas',
  203: 'Necesidades',
  204: 'Activo_Pasivo',
  205: 'OrdenMetas',
  206: 'PerioricidadPresupuesto',
  207: 'PreferenciaPresupuesto',
  208: 'GuardarAhorroEmergencia',
  209: 'CuantoInvertirAhorros',
  210: 'FlujoYnoFlujo',
  211: 'CualGastoIndispensable',
  212: 'VideoInversion',
  213: 'PreguntasdePerfilamientoInversion',
  214: 'DecalogoInversor',
  215: 'Video1',
  216: 'Video2',
  217: 'MontoInversion',
  218: 'PlazoInversion',
  219: 'InversionObtenida',
  220: 'DiferenciasAhorro',
  221: 'VideoAhorro',
  222: 'AhorroInicial',
  223: 'CapacidadAhorro',
  224: 'GastosMinimos',
  225: 'ModificacionDatos',
  226: 'FormaDeAhorro',
  227: 'MesesDeAhorro',
  228: 'IniciarAhorroEmegente',
  229: 'ObjetivoAhorro',
  230: 'HerramientaDeAhorro',
  231: 'TablaObjetivos',
  232: 'AvanceAhorro',
  233: 'TipoNotificacion',
  234: 'MedioNotificacion',
  235: 'RecurrenciaNotificacion',
  236: 'VideoAprendeAhorro',
  237: 'VideoBaseVidaFinanciera',
  238: 'VideoAhorrarPagardeudas',
  239: 'Comotienestudinero',
  240: 'StatusDinero',
  241: 'CantidadAhorro',
  242: 'CantidadInvertido',
  243: 'CantidadDeben',
  244: 'TienesDeudas',
  245: 'PrincipalesDeudas',
  246: 'MontoAdeudo',
  247: 'VideoIngresos',
  248: 'FuentesIngresos',
  249: 'MontoIngresos',
  250: 'IngresosVariables',
  251: 'MontoIngresosVariable',
  252: 'VideoIngresosGastos',
  253: 'VideoIdentificaGastos',
  254: 'RentaHipoteca',
  255: 'Agua',
  256: 'Luz',
  257: 'Internet',
  258: 'Alimentos',
  259: 'GasolinaTransporte',
  260: 'EgresosExtras',
  261: 'VideoGastoHormiga',
  262: 'Imprevistos',
  263: 'GastosEntretenimiento',
  264: 'GastosDiversion',
  265: 'GastosPersonales',
  266: 'GastosExtras',
  267: 'BenefCatalogoInversion',
  268: 'MontoInversión',
  269: 'PlazoInversión',
  270: 'ProveedorÓptimo',
  271: 'TasaRendimiento',
  272: 'TipoHerramientaInversión',
  273: 'SeleccioninstrumentoRendimiento',
  274: 'Seleccionactivorefugio',
  275: 'EmpresaMayorCapitalización',
  276: 'PreferenciaPlataforma',
  277: 'ReglaOroInversiones',
  278: 'HoraAperturaBolsa',
  279: 'PreferenciaInversionunAño',
  280: 'S&P500',
  281: 'Names',
  282: 'ComoUsarDinero',
  283: 'PreferenciasTiempoDinero',
  284: 'HacerDineroInversión',
  285: 'AhorrosEmergencias',
  286: 'ConocimientoActualInversiones',
  287: 'ArriesgarCapital',
  288: 'EsperanzasInversión',
  289: 'OpcionesInvertir',
  290: 'OcupaciónActual',
  291: 'HorasDeTrabajo',
  292: 'MotivaciónDinero',
  293: 'OpcionIngresoExtra',
  294: 'InteresesPersonales',
  295: 'BancoDeudas',
  296: 'TipoVehiculo',
  297: 'CompartirAuto',
  298: 'HacerCompras',
  299: 'TipoDeEjercicio',
  300: 'GustoPorLosPerros',
  301: 'ComoTeGustaVender',
  302: 'DescriptionVenta',
  303: 'ActividadOnline ',
  304: 'TrabajoEnCasa',
  305: 'FormaDeTrabajo',
  306: 'AreaLaboral',
  307: 'AhorroInversionTotal',
  308: 'SumaIngresos',
  309: 'SimaEgresos',
  310: 'SumaEgresosIndispensables',
  311: 'SumaGastosExtra',
  312: 'Edad',
  313: 'VideoMejoraPresupuesto',
  314: 'ComienzoPlanMejora',
  315: 'NotificacionReminder',
  316: 'SugerenciaMejoraPresupuesto',
  317: 'MontoAdeudoInformales',
  318: 'DeudaTotal',
  319: 'IntrumentosInversion',
  320: 'VideoInversion',

  321: 'AccionesNacionales',
  322: 'AccionesInternacionales',
  323: 'Cetes',
  324: 'ETFs',
  325: 'Fibras',
  326: 'FondoInversion',
  327: 'Metales',
  328: 'CuantoInvertirAeromexico',
  329: 'CuantoInvertirBimbo',
  330: 'CuantoInvertirAmazon',
  331: 'CuantoInvertirFacebook',
  332: 'CuantoInvertirCetes',
  333: 'CuantoInvertirSPY',
  334: 'CuantoInvertirUSMV',
  335: 'CuantoInvertirFUNO',
  336: 'CuantoInvertirCPODX',
  337: 'CuantoInvertirOro',
  338: 'Acumulado',
  339: 'DisponibleInvertir',
  340: 'VideoCrearMeta',
  341: 'SeleccionaMeta',
  343: 'MontoMeta',
  344: 'DineroMetaQuincenal',
  345: 'AhorroMensual',
  346: 'TiempodeAhorro',
  347: 'ConceptoAhorroRegistro',
  348: 'MontoAhorroRegistro',
  349: 'UsoDinero',
  350: 'DIneroNecesidad',
  351: 'TiempoParaPagarPrestamo',
  352: 'OtroTipoPrestamoTarjeta',
  353: 'FirstInversion',
  355: 'Pregunta1TestViralidad',
  356: 'Pregunta2TestViralidad',
  357: 'Pregunta3TestViralidad',
  358: 'Pregunta4TestViralidad',
  359: 'Pregunta5TestViralidad',
  360: 'EdadHijo',
  361: 'nombreHijo',
  362: 'telefonoHijo',
  363: 'EleccionTareas',
  364: 'DiasTareas',
  365: 'RecompensaTarea',
  366: 'HorarioTareas',
  367: 'RazonesNoPropositos',
  368: 'Seleccionpropositos',
  369: 'RelacionFinanzas',
  370: 'PracticaDeporte',
  371: 'PlataformaDigital',
  372: 'OtraOpcion',
  373: 'ConQuienVives',
  374: 'Vacaciones',
  375: 'ComoTeVesDe40',
  376: 'DurabilidadDeAhorros',
  377: 'DesayunoIdeal',
  378: 'CuantoDineroTeQueda',
  379: 'EdadParaTestamentar',
  380: 'GustoPorDinero',
  381: 'OtraOpcion2',
  382: 'FindeSemana',
  383: 'OtraOpcion3',
  384: 'QuePasaConMiDinero',
  385: 'OtraOpcion4',
  386: 'FindelMundo',
  387: 'EstadoFinanciero',
  388: 'SituacionCrediticia',
  389: 'ExperienciaCrediticia',
  390: 'SabesSumaAseguradaPoliza',
  391: 'SignosVidaSaludable',
  392: 'MesesSalarioAhorro',
  393: 'BuenoAhorroMetas',
  394: 'FondosAhorroRecomendable',
  395: 'FactoresAhorro',
  396: 'FacturaciónEmpresa',
  397: 'NumeroColaboradores',
  404: 'MontoPorDeudaAmigos',
  405: 'MontoDeudaTDC',
  406: 'MontoDeudasTiendas',
  407: 'MontoDeudaCreditoAutomotriz',
  408: 'MontoDeudaCreditoHipotecario',
  409: 'MontoOtraDeuda',
  410: 'QueDeudaSolucionarPrimero',
  411: 'CategoriasDeGastosH',
  412: 'Netflix',
  413: 'Amazon Prime',
  414: 'HBO',
  415: 'You Tube Premium',
  416: 'Disney',
  417: 'Spotify',
  418: 'Twich',
  419: 'Otros',
  420: 'Rappi',
  421: 'Uber Eats',
  422: 'DiDi Food',
  423: 'Otros',
  424: 'Amazon',
  425: 'Mercado Libre',
  426: 'Linio',
  427: 'Segunda mano',
  428: 'Otros',
  429: 'Uber',
  430: 'DiDi',
  431: 'Beat',
  432: 'Cabify',
  433: 'Otros',
  434: 'Papas/frituras/Dulces',
  435: 'Refresco',
  436: 'Cafe',
  437: 'Cigarrillos',
  438: 'Alcohol',
  439: 'Chicles',
  440: 'RegistrarMisGastos',
  441: 'Otro Servicio',
  442: 'QuestionSH',
  443: 'Otros',
  444: 'Otros',
  445: 'Otros',
  446: 'Otros',
  447: 'Otros',
  448: 'Otros',
  449: 'Otros',
  450: 'Otros',
  451: 'Otros',
  452: 'Otros',
  453: 'Otros',
  454: 'Otros',
  455: 'Otros',
  456: 'Otros',
  457: 'Otros',
  458: 'Otros',
  459: 'Otros',
  460: 'Otros',
  461: 'Otros',
  462: 'Otros',
  463: 'Otro Servicio',
  464: 'Otro Servicio',
  465: 'Otro Servicio',
  466: 'Otro Servicio',
  467: 'Otro Servicio',
  468: 'Otro Servicio',
  469: 'Otro Servicio',
  470: 'Otro Servicio',
  471: 'Otro Servicio',
  472: 'Otro Servicio',
  478: 'MetasPorCumplir',
  479: 'PropiaMetaColavorativa',
  480: 'DineroMetaColavorativa',
  481: 'TiempoMetaColavorativa',
  483: 'Ingreso_Mensual_Fijo',
  484: 'IngresoMensualAfore',
  485: 'DineroRetiroAfore',
  486: 'AñosDeRetiroAfore',
  487: 'ConocimientoAfore',
  488: 'AforePerteneciente',
  489: 'CambioAfore',
  490: 'Fumador',
  491: 'Conoce_tu_score',
  492: 'Cigarros_dia',
  494: 'Que_es',
  495: 'Quien_los_otorga',
  496: 'Tipos_de_credito_pyme',
  497: 'Como_se_usan',
  498: 'Grantias_en_creditos_pymes',
  499: 'Requisitos_para_solicitarlos',
  500: 'Tipo_de_credito',
  501: 'Plazo_buscado',
  502: 'Cantidad_requerida',
  503: 'Registro_hacienda',
  504: 'Persona',
  505: 'Tiempo_Facturacion',
  506: 'PlazoCalculadora2',
  507: 'TasaCalculadora2',
  508: 'MontoResultado',
  509: 'OpcionesInversion',
};
export const getElementByXpath = (path) => document.evaluate(
  path,
  document,
  null,
  XPathResult.FIRST_ORDERED_NODE_TYPE,
  null,
).singleNodeValue;
export const createJsonInput = (
  currentRoutine,
  questions,
  insertRoutine,
  orderRutine,
  updateSession,
  createSession,
  TagManager,
) => {
  localStorage.setItem('activityDate', new Date());
  const inputRutine = {
    idUser: '',
    idCont: '',
    email: '',
    idGa: '',
    cellphone: '',
    idTraining: '',
    rutine: [],
  };
  // eslint-disable-next-line no-console
  console.log('currentRoutine', currentRoutine);
  // eslint-disable-next-line no-console
  console.log('questions', questions);
  // eslint-disable-next-line no-console
  console.log('insertRoutine', insertRoutine);

  let numQuestion = 0;
  try {
    questions.forEach((item) => {
      // eslint-disable-next-line radix
      numQuestion = parseInt(item.question) - 1;
      const questionRoutine = currentRoutine.questions[numQuestion];

      const rutine = {
        questionId: '',
        datUserDetailStorage: '',
        questionType: '',
        value: '',
      };
      rutine.questionId = questionRoutine.IDCatQuestions;
      rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
      rutine.questionType = questionRoutine.QuestionType;
      let triggerGTM = false;
      if (
        !sessionStorage.getItem(
          arraySessionsRutines[questionRoutine.DatUserDetailStorage],
        )
      ) {
        triggerGTM = true;
      }

      if (questionRoutine.QuestionType === 'Cat') {
        if (item.value != null && item.value !== '') {
          rutine.catFieldDetail = getFielOption(
            questionRoutine.detailQuestion,
            item.value,
          );
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            rutine.catFieldDetail.fieldOption,
          );
        }
      } else {
        sessionStorage.setItem(
          arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          item.value,
        );

        rutine.value = item.value;
      }
      if (triggerGTM) {
        const tagManagerArgs = {
          dataLayer: {
            event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
      updateBinacle(
        arraySessionsRutines[questionRoutine.DatUserDetailStorage],
        updateSession,
        createSession,
      );
      // sessionStorage.setItem("tmpEventGTM", arraySessionsRutines[questionRoutine.DatUserDetailStorage]);

      inputRutine.email = sessionStorage.getItem('Email1');
      inputRutine.cellphone = sessionStorage.getItem('CellPhone');
      // eslint-disable-next-line radix
      inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
      // eslint-disable-next-line radix
      inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
      // eslint-disable-next-line radix
      inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
      inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
      // eslint-disable-next-line radix
      inputRutine.rutine[parseInt(numQuestion)] = rutine;
    });
    const newRutine = inputRutine.rutine.filter(() => true);

    inputRutine.rutine = newRutine;

    if (inputRutine.rutine.length > 0) {
      if (sessionStorage.getItem('idDatSession')) {
        // eslint-disable-next-line radix
        inputRutine.idMysql = parseInt(sessionStorage.getItem('idDatSession'));
      }
      insertRoutine({ variables: { input: inputRutine } });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

/// wo/card-funnel?contact_user=635&contact=938
export const getQueryVariable = (variable) => {
  const sPaginaURL = window.location.search.substring(1);
  // eslint-disable-next-line no-console
  console.log(sPaginaURL);
  const sURLVariables = sPaginaURL.split('&');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < sURLVariables.length; i++) {
    const pair = sURLVariables[i].split('=');
    if (pair[0] === variable) {
      switch (variable) {
        case 'utm_source':
          sessionStorage.setItem('source', pair[1]);
          break;
        case 'utm_medium':
          sessionStorage.setItem('medium', pair[1]);
          break;
        case 'utm_campaign':
          sessionStorage.setItem('campaign', pair[1]);
          break;
        case 'utm_content':
          sessionStorage.setItem('content', pair[1]);
          break;
        case 'IDTicket':
          sessionStorage.setItem('IDTicket', pair[1]);
          break;
        case 'affid':
          sessionStorage.setItem('affid', pair[1]);
          break;
        case 'sub_id':
          sessionStorage.setItem('Click_ID', pair[1]);
          sessionStorage.setItem('sub_id', pair[1]);
          break;
        case 'utm_id':
          sessionStorage.setItem('utmId', pair[1]);
        // eslint-disable-next-line no-fallthrough
        case 'WoRedirectTo':
          sessionStorage.setItem('WoRedirectTo', pair[1]);
          break;
        default:
          break;
      }
      return pair[1];
    }
  }
  switch (variable) {
    case 'utm_source':
      if (sessionStorage.getItem('source') !== null) {
        return sessionStorage.getItem('source');
      }
      break;
    case 'utm_medium':
      if (sessionStorage.getItem('medium') !== null) {
        return sessionStorage.getItem('medium');
      }
      break;
    case 'utm_campaign':
      if (sessionStorage.getItem('campaign') !== null) {
        return sessionStorage.getItem('campaign');
      }
      break;
    case 'utm_id':
      // eslint-disable-next-line space-before-blocks
      if (sessionStorage.getItem('utmId') !== null){
        return sessionStorage.getItem('utmId');
      }

    // eslint-disable-next-line no-fallthrough
    default:
      return '';
  }
  return '';
};

// #36015#36330#test#test#test
export const getQueryVariableHashtagDeeplink = (pageName) => {
  const query = window.location.hash.substring(1);
  const varsSinHash = query.split('#');
  // var vars = varsSinHash[1].split("&");
  /*
   console.log("query: " + query)
   console.log("var: " + varsSinHash)
   console.log("var0: " + varsSinHash[0])
   console.log("var1: " + varsSinHash[1])
   console.log("var2: " + varsSinHash[2])
   */
  // eslint-disable-next-line no-console
  console.log(' varsSinHash', varsSinHash);

  const hostName = window.location.hostname;
  if (varsSinHash.length >= 3) {
    // sessionStorage.setItem('idUser', varsSinHash[0]);
    // sessionStorage.setItem('idCont', varsSinHash[1]);
    return {
      idUser: varsSinHash[0],
      idCont: varsSinHash[1],
      hostName,
      recovery: varsSinHash[2],
      pageName,
      fromXCoach: varsSinHash[3] || 'false',
      idAdviser: varsSinHash[4] || null,
      email: varsSinHash[5],
    };
  }
  return '';
};

/*
shareCoru: Function created for sharing our website on different social networks

Parameters:
// eslint-disable-next-line max-len
@socialNetwork: String, this paremeter indicates wich socialnetwork will be used for shareing our website
It can be any of the following options:
- "Facebook" - Desktop facebook
- "FacebookMob" - Mobile Facebook
- "Messenger" - Desktop messenger
- "MessengerMob" - Mobile messenger
- "Whatsapp" - Desktop and Mobile whatsapp

// eslint-disable-next-line max-len, max-len, max-len
@announcement: String, Announcement (html file) wich is used to display a custom image for the preview window
Said file must be in the following path: public/announcements

@redirectLink: String, Link without domain used to redirect the user once he clicks the link
Example: "/the-training-feed"
*/

export const shareCoru2 = async (
  socialNetwork,
  announcement,
  redirectLink = '/',
  generateShortendLink,
  getSharedLink = false,
  textWa
) => {
  let host = ''; // "https://coru.com";
  let auxHost = ''; // "https://coru.com";
  let plantillasHTML = '';
  const url = window.location.host;
  if (url === 'coru.com') {
    host = 'https://coru.com';
    auxHost = 'https://coru.com';
  } else if (url === 'dev-rediseno.coru.mx') {
    host = 'https://dev-rediseno.coru.mx';
    auxHost = 'https://dev-rediseno.coru.mx';
  } else {
    host = 'https://staging.coru.mx';
    auxHost = 'https://staging.coru.mx';
  }

  if (process.env.REACT_APP_SERVERTYPE === 'Development') {
    plantillasHTML = '/announcements/';
  } else if (
    process.env.REACT_APP_SERVERTYPE === 'Production'
    || process.env.REACT_APP_SERVERTYPE === 'Stg'
  ) {
    plantillasHTML = '/announcements_prod/';
  }
  // Replace auxHost with host once the domain is placed officialy
  let coruShareLink = `${auxHost}${plantillasHTML}${announcement}.html?coru-redirect-url=${host}${encodeURIComponent(
    redirectLink,
  )}`;
  const shortlink = await generateShortendLink({
    variables: {
      input: {
        url: redirectLink,
      },
    },
  });
  coruShareLink = shortlink.data.generateShortendLink.response;

  if (getSharedLink) {
    // console.log(coruShareLink)
    return coruShareLink;
  }
  if (textWa !== ''){
    coruShareLink = `${textWa} ${coruShareLink}`;
    // window.open(coruShareLink)
    // window.location.href = coruShareLink
    window.open(coruShareLink);
    // eslint-disable-next-line consistent-return
    return;
  }
  switch (socialNetwork) {
    case 'Messenger':
      // safariWindow.location.href = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruShareLink}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`;
      window.open(
        `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${encodeURIComponent(
          `${coruShareLink}`,
        )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`,
      );
      break;
    case 'MessengerMob':
      // safariWindow.location.href = `fb-messenger://share/?link=${encodeURIComponent(coruShareLink)}&app_id=248019326476226`;
      window.open(
        `fb-messenger://share/?link=${coruShareLink}&app_id=248019326476226`,
      );
      break;
    case 'Facebook':
      window.open(
        `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
          coruShareLink,
        )}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}&display=popup`,
      );
      // safariWindow.location.href = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(coruShareLink)}&mobile_iframe=true`;
      break;
    case 'FacebookMob':
      window.open(
        `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
          coruShareLink,
        )}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}&display=popup`,
      );
      // safariWindow.location.href = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(coruShareLink)}`;
      break;
    case 'Whatsapp':
      window.open(`https://wa.me/?text=${encodeURI(coruShareLink)}`);
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(
        'Error: wrong value of parameter "socialNetwork" in shareCoru function',
      );
      break;
  }
};

export const shareCoru = (
  socialNetwork,
  announcement,
  redirectLink = '/',
  getSharedLink = false,
) => {
  let host = ''; // "https://coru.com";
  let auxHost = ''; // "https://coru.com";
  let plantillasHTML = '';
  const url = window.location.host;
  if (url === 'coru.com') {
    host = 'https://coru.com';
    auxHost = 'https://coru.com';
  } else if (url === 'dev-rediseno.coru.mx') {
    host = 'https://dev-rediseno.coru.mx';
    auxHost = 'https://dev-rediseno.coru.mx';
  } else {
    host = 'https://staging.coru.mx';
    auxHost = 'https://staging.coru.mx';
  }

  if (process.env.REACT_APP_SERVERTYPE === 'Development') {
    plantillasHTML = '/announcements/';
  } else if (
    process.env.REACT_APP_SERVERTYPE === 'Production'
    || process.env.REACT_APP_SERVERTYPE === 'Stg'
  ) {
    plantillasHTML = '/announcements_prod/';
  }
  // Replace auxHost with host once the domain is placed officialy
  const coruShareLink = `${auxHost}${plantillasHTML}${announcement}.html?coru-redirect-url=${host}${encodeURIComponent(
    redirectLink,
  )}`;

  if (getSharedLink) {
    // console.log(coruShareLink)
    return coruShareLink;
  }
  switch (socialNetwork) {
    case 'Messenger':
      // safariWindow.location.href = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruShareLink}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`;
      window.open(
        `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${encodeURIComponent(
          `${coruShareLink}`,
        )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`,
      );
      break;
    case 'MessengerMob':
      // safariWindow.location.href = `fb-messenger://share/?link=${encodeURIComponent(coruShareLink)}&app_id=248019326476226`;
      window.open(
        `fb-messenger://share/?link=${coruShareLink}&app_id=248019326476226`,
      );
      break;
    case 'Facebook':
      window.open(
        `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
          coruShareLink,
        )}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}&display=popup`,
      );
      // safariWindow.location.href = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(coruShareLink)}&mobile_iframe=true`;
      break;
    case 'FacebookMob':
      window.open(
        `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
          coruShareLink,
        )}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}&display=popup`,
      );
      // safariWindow.location.href = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(coruShareLink)}`;
      break;
    case 'Whatsapp':
      window.open(`https://wa.me/?text=${encodeURI(coruShareLink)}`);
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(
        'Error: wrong value of parameter "socialNetwork" in shareCoru function',
      );
      break;
  }
};

/*
getURLParam: This function gets a parameter inside the current url

Parameters:
@sParam: String, name of the parameter you wanna get.
*/
export const getURLParam = (sParam) => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < sURLVariables.length; i++) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
};

export const filterFloat = (__val__) => {
  const preg = /^([0-9]+\.?[0-9]{0,2})$/;
  if (preg.test(__val__) === true) {
    return __val__;
  }
  return '';
};

// Funcion para recuperar la posición

export const obtenerPosicionCursor = (idElemento) => {
  const elemento = document.getElementById(idElemento);
  if (document.selection) {
    // Soporte IE
    elemento.focus(); // Poner foco en elemento
    const oSel = document.selection.createRange(); // Para obtener posición del cursor
    oSel.moveStart('character', -elemento.value.length); // Mover cursor a posicion 0
    elemento.selectionEnd = oSel.text.length;
    oSel.setEndPoint('EndToStart', document.selection.createRange());
    elemento.selectionStart = oSel.text.length;
  }
  return { start: elemento.selectionStart, end: elemento.selectionEnd };
};

// Función para establecer la posición

export const establerCursorPosicion = (pos, idElemento, e) => {
  e.preventDefault();
  const elemento = document.getElementById(idElemento);
  if (typeof document.selection !== 'undefined' && document.selection) {
    // método IE
    const tex = elemento.value;
    elemento.value = '';
    elemento.focus();
    const str = document.selection.createRange();
    elemento.value = tex;
    str.move('character', pos);
    str.moveEnd('character', 0);
    str.select();
  }
};

export const getCurrentDateTimeLarge = () => {
  const dateOb = new Date();
  const date = `0${dateOb.getDate()}`.slice(-2);
  // current month
  const month = `0${dateOb.getMonth() + 1}`.slice(-2);
  // current year
  const year = dateOb.getFullYear();
  // current hours
  const hours = dateOb.getHours();
  // current minutes
  const minutes = dateOb.getMinutes();
  // current seconds
  const seconds = dateOb.getSeconds();

  // prints date & time in YYYY-MM-DD HH:MM:SS format
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};

export const formatterMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
