/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import ShareIcon from "@material-ui/icons/Share";
import face from "../../assets/img/anacoach/Facebook.svg";
import twit from "../../assets/img/anacoach/messenger2.svg";
import whats from "../../assets/img/anacoach/whats.png";
import gymOn from "../../assets/img/anacoach/WO.svg";
import {
  TrainingSectionGray,
  TrainingDivSection2,
  DivFlex,
  CardsBtn,
} from "../../assets/styles/Training.styled";
import { ImageSticker } from "../../assets/styles/Feed.styled";
import { shareCoru2 } from "../../Helpers/index";
import CardOne from "../Home/Card1Animate";
import { viralityUTM } from "../../Helpers/HelperUTM";
import {
  SAVE_EVALUATE_TRAINING,
  INSERT_DAT_VIRALITY,
  UPDATE_HISTORY_COLLECTION,
  CREATE_SESSION,
  GENERATE_SHORTENED_LINK,
  ADD_SCORE_TO_FINISHED,
} from "../../mutations";
import { getDateStandart } from "../../Helpers/general";
import ClickButton from "../../Helpers/HookHelpers";
import Messenger from "../../assets/img/anacoach/messenger2.svg";
import ModalCompartir from "./ModalCompartir";
import RatingStars from "./RatingStars";
import { Subtitle } from "./Home.styled";
import Kanda from "../Kanda/Kanda";
import ProductsFremium from "../../components/common/ProductsFremium";
import ShareWin from "./BannerRewards";

const anchop = window.screen.width;

const ShareSection = ({ WO, Modal = false, isCompareCardSuccess, free }) => {
  const [showModal, setShowModal] = useState(false);
  const { actions } = ClickButton();
  const iduserscore = parseInt(sessionStorage.getItem("idUser") || 0);
  const scoretraining = parseInt(sessionStorage.getItem("idTraining") || 0);
  const [addScoreUserFinished] = useMutation(ADD_SCORE_TO_FINISHED);
  const getRedirectUrl = (socialNetwork) => {
    console.log("Entra a social network");
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/first-investment/?utm_source=Share&utm_medium=FirstInversion&utm_campaign=Facebook";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/first-investment/?utm_source=Share&utm_medium=FirstInversion&utm_campaign=MessengerMob";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/first-investment/?utm_source=Share&utm_medium=FirstInversion&utm_campaign=Messenger";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/first-investment/?utm_source=Share&utm_medium=FirstInversion&utm_campaign=Whatsapp";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  // Agregamos Score al usuario al Finalizar un WO Opcion2
  useEffect(() => {
    if (iduserscore !== 0 && !Modal) {
      addScoreUserFinished({
        variables: {
          idUser: iduserscore,
          idTraining: scoretraining,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRedirectUrlPL = (socialNetwork) => {
    console.log("Entra a social network");
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/loans-funnel?utm_source=Share&utm_medium=PersonalLoans&utm_campaign=Facebook";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/loans-funnel?utm_source=Share&utm_medium=PersonalLoans&utm_campaign=MessengerMob";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/loans-funnel?utm_source=Share&utm_medium=PersonalLoans&utm_campaign=Messenger";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/loans-funnel?utm_source=Share&utm_medium=PersonalLoans&utm_campaign=Whatsapp";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlFP = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/family-protection?utm_source=share&utm_medium=facebook&utm_campaign=PF";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/family-protection?utm_source=share&utm_medium=messenger&utm_campaign=PF";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/family-protection?utm_source=share&utm_medium=messenger&utm_campaign=PF";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/family-protection?utm_source=share&utm_medium=whatsapp&utm_campaign=PF";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlEI = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/extra-income?utm_source=share&utm_medium=facebook&utm_campaign=EI";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/extra-income?utm_source=share&utm_medium=messenger&utm_campaign=EI";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/extra-income?utm_source=share&utm_medium=messenger&utm_campaign=EI";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/extra-income?utm_source=share&utm_medium=whatsapp&utm_campaign=EI";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlPurpose = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/new-years-resolutions?utm_source=share&utm_medium=facebook&utm_campaign=NYP";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/new-years-resolutions?utm_source=share&utm_medium=messenger&utm_campaign=NYP";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/new-years-resolutions?utm_source=share&utm_medium=messenger&utm_campaign=NYP";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/new-years-resolutions?utm_source=share&utm_medium=whatsapp&utm_campaign=NYP";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlFastTrack = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/fast-track?utm_source=share&utm_medium=facebook&utm_campaign=FT";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/fast-track?utm_source=share&utm_medium=messenger&utm_campaign=FT";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/fast-track?utm_source=share&utm_medium=messenger&utm_campaign=FT";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/fast-track?utm_source=share&utm_medium=whatsapp&utm_campaign=FT";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlRazones = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/rejection-reasons?utm_source=share&utm_medium=facebook&utm_campaign=MR";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/rejection-reasons?utm_source=share&utm_medium=messenger&utm_campaign=MR";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/rejection-reasons?utm_source=share&utm_medium=messenger&utm_campaign=MR";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/rejection-reasons?utm_source=share&utm_medium=whatsapp&utm_campaign=MR";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlColaborativo = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/collaborative/?utm_source=share&utm_medium=facebook&utm_campaign=invitacion_colaborativa";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/collaborative/?utm_source=share&utm_medium=messenger&utm_campaign=invitacion_colaborativa";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/collaborative/?utm_source=share&utm_medium=messenger&utm_campaign=invitacion_colaborativa";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/collaborative/?utm_source=share&utm_medium=whatsapp&utm_campaign=invitacion_colaborativa";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const getRedirectUrlYourScore = (socialNetwork) => {
    let redirectUrl;
    switch (socialNetwork) {
      case "Facebook":
        redirectUrl =
          "/wo/know-your-score/?utm_source=share&utm_medium=facebook&utm_campaign=know-your-score";
        break;
      case "MessengerMob":
        redirectUrl =
          "/wo/know-your-score/?utm_source=share&utm_medium=messenger&utm_campaign=know-your-score";
        break;
      case "Messenger":
        redirectUrl =
          "/wo/know-your-score/?utm_source=share&utm_medium=messenger&utm_campaign=know-your-score";
        break;
      case "Whatsapp":
        redirectUrl =
          "/wo/know-your-score/?utm_source=share&utm_medium=whatsapp&utm_campaign=know-your-score";
        break;
      default:
        redirectUrl = "/";
        break;
    }
    return redirectUrl;
  };

  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    onCompleted({ generateShortendLink }) {
      const url = generateShortendLink.response;
      return url;
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === "success") {
        console.log("REsponse viralidad", insertDatVirality.response);
        const { response } = insertDatVirality;
        const redirectLink = response.split(",")[0];
        const socialNetwork = response.split(",")[1];
        const announcement = sessionStorage.getItem("auxAnnouncement");
        shareCoru2(
          socialNetwork,
          announcement,
          redirectLink,
          generateShortendLink
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("idClickSocialSN", dynamicMongoSession.response);
        localStorage.setItem("idClickSocialSN", dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickShareNetworkCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("idClickSocialSN", dynamicMongoSession.response);
        localStorage.setItem("idClickSocialSN", dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const ClickShareMongo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: "ClickShareSN".concat(action),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem("idClickSocialSN")) {
      const dataJson = [
        {
          idSession: sessionStorage.getItem("id_session"),
          nameTraining: sessionStorage.getItem("trainingName"),
          medium: action,
          date: getDateStandart(),
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem("idClickSocialSN"),
      });
      const mongoInput = {
        input: {
          collection: "LogShared",
          key: "Shared",
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idUser: sessionStorage.getItem("idUser"),
        Shared: [
          {
            idSession: sessionStorage.getItem("id_session"),
            nameTraining: sessionStorage.getItem("trainingName"),
            medium: action,
            date: getDateStandart(),
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: "LogShared",
          data: dataString,
        },
      };
      createClickShareNetworkCollection({ variables: mongoInput });
    }
  };

  const checkWO = WO;
  let viralityUrl = "";

  switch (checkWO) {
    case "PrimeraInversión":
      viralityUrl = "/wo/first-investment";
      break;
    case "PrestamosPersonales":
      viralityUrl = "/wo/loans-funnel";
      break;
    case "ProtecciónFamiliar":
      viralityUrl = "/wo/family-protection";
      break;
    case "IngresosExtra":
      viralityUrl = "/wo/extra-income";
      break;
    case "YearPurpose":
      viralityUrl = "/wo/new-years-resolutions";
      break;
    case "FastTrack":
      viralityUrl = "/wo/fast-track";
      break;
    case "RazonesRechazo":
      viralityUrl = "/wo/rejection-reasons";
      break;
    case "CatalogoInversiones":
      viralityUrl = "/wo/investment-catalog";
      break;
    case "PrimeraTarjeta":
      viralityUrl = "/wo/first-card";
      break;
    case "Deudas":
      viralityUrl = "/wo/debts";
      break;
    case "Profiling":
      viralityUrl = "/wo/profiling";
      break;
    case "Profiling2":
      viralityUrl = "/wo/profiling-2";
      break;
    case "Budget":
      viralityUrl = "/wo/budget";
      break;
    case "Emergency":
      viralityUrl = "/wo/emergency-saving";
      break;
    case "GastosHormiga":
      viralityUrl = "/wo/micro-expenses";
      break;
    case "ViralityTest":
      viralityUrl = "/wo/virality-test";
      break;
    case "Goals":
      viralityUrl = "/wo/goals";
      break;
    case "SolicitarTarjeta":
      viralityUrl = "/wo/card-funnel";
      break;
    case "TarjetaIdeal":
      viralityUrl = "/wo/compare-credit-card";
      break;
    case "ValorDinero":
      viralityUrl = "/wo/money-value";
      break;
    case "ITest":
      viralityUrl = "/wo/investment-test";
      break;
    case "SimuladordeIn":
      viralityUrl = "/wo/investment-simulator";
      break;
    case "ImproveBudget":
      viralityUrl = "/wo/budget-improve";
      break;
    case "SinHistorial":
      viralityUrl = "/wo/no-credit-history";
      break;
    case "Colaborativo":
      viralityUrl = "/wo/collaborative";
      break;
    case "Afores":
      viralityUrl = "/wo/afores";
      break;
    case "WoScore":
      viralityUrl = "/wo/know-your-score";
      break;
    case "Pymes":
      viralityUrl = "/wo/pymes";
      break;
    case "CI":
      viralityUrl = "/wo/car-insurance";
    default:
      break;
  }

  const shareTraining = (socialNetwork) => {
    console.log("Entra a share");
    ClickShareMongo(socialNetwork);
    let announcement;
    let redirectLink = "/";

    if (WO === "PrimeraInversión") {
      redirectLink = getRedirectUrl(socialNetwork);
      announcement =
        socialNetwork === "Whatsapp"
          ? "FInvertionSuccessWa"
          : "FInvertionSuccess";
    } else if (WO === "PrestamosPersonales") {
      redirectLink = getRedirectUrlPL(socialNetwork);
      announcement = socialNetwork === "Whatsapp" ? "PLSuccessWa" : "PLSuccess";
    } else if (WO === "ProtecciónFamiliar") {
      redirectLink = getRedirectUrlFP(socialNetwork);
      announcement = socialNetwork === "Whatsapp" ? "PFSuccessWa" : "PFSuccess";
    } else if (WO === "IngresosExtra") {
      redirectLink = getRedirectUrlEI(socialNetwork);
      announcement = socialNetwork === "Whatsapp" ? "EISuccessWa" : "EISuccess";
    } else if (WO === "YearPurpose") {
      redirectLink = getRedirectUrlPurpose(socialNetwork);
      announcement =
        socialNetwork === "Whatsapp" ? "ProfileSuccessWa" : "ProfileSuccess";
    } else if (WO === "FastTrack") {
      redirectLink = getRedirectUrlFastTrack(socialNetwork);
      announcement = socialNetwork === "Whatsapp" ? "FTSuccessWa" : "FTSuccess";
    } else if (WO === "RazonesRechazo") {
      redirectLink = getRedirectUrlRazones(socialNetwork);
      announcement = socialNetwork === "Whatsapp" ? "RRSuccessWa" : "RRSuccess";
    } else if (WO === "CatalogoInversiones") {
      announcement =
        socialNetwork === "Whatsapp"
          ? "CatalogoInvSuccessWa"
          : "CatalogoInvSuccess";
    } else if (WO === "PrimeraTarjeta") {
      announcement = socialNetwork === "Whatsapp" ? "FCSuccessWa" : "FCSuccess";
    } else if (WO === "Deudas") {
      announcement =
        socialNetwork === "Whatsapp" ? "DebtsSuccessWa" : "DebtsSuccess";
    } else if (WO === "Profiling") {
      announcement =
        socialNetwork === "Whatsapp" ? "ProfileSuccessWa" : "ProfileSuccess";
    } else if (WO === "Profiling2") {
      announcement =
        socialNetwork === "Whatsapp" ? "Profile2SuccessWa" : "Profile2Success";
    } else if (WO === "Budget") {
      announcement =
        socialNetwork === "Whatsapp" ? "BudgetSuccessWa" : "BudgetSuccess";
    } else if (WO === "ImproveBudget") {
      announcement =
        socialNetwork === "Whatsapp" ? "IBudgetSuccessWa" : "IBudgetSuccess";
    } else if (WO === "Emergency") {
      announcement = socialNetwork === "Whatsapp" ? "EsSuccessWa" : "EsSuccess";
    } else if (WO === "GastosHormiga") {
      announcement = socialNetwork === "Whatsapp" ? "AESuccessWa" : "AESuccess";
    } else if (WO === "ViralityTest") {
      announcement =
        socialNetwork === "Whatsapp"
          ? "ViralityTestSuccessWa"
          : "ViralityTestSuccess";
    } else if (WO === "Goals") {
      announcement =
        socialNetwork === "Whatsapp" ? "GoalsSuccessWa" : "GoalsSuccess";
    } else if (WO === "SolicitarTarjeta") {
      announcement = socialNetwork === "Whatsapp" ? "CCSuccessWa" : "CCSuccess";
    } else if (WO === "TarjetaIdeal") {
      announcement = socialNetwork === "Whatsapp" ? "CTSuccessWa" : "CTSuccess";
    } else if (WO === "ValorDinero") {
      announcement = socialNetwork === "Whatsapp" ? "VDSuccessWa" : "VDSuccess";
    } else if (WO === "ITest") {
      announcement = socialNetwork === "Whatsapp" ? "ITSuccessWa" : "ITSuccess";
    } else if (WO === "SimuladordeIn") {
      announcement = socialNetwork === "Whatsapp" ? "SISuccessWa" : "SISuccess";
    } else if (WO === "SinHistorial") {
      announcement = socialNetwork === "Whatsapp" ? "SISuccessWa" : "SISuccess";
    } else if (WO === "Colaborativo") {
      announcement = socialNetwork === "Whatsapp" ? "COSuccessWa" : "COSuccess";
      redirectLink = getRedirectUrlColaborativo(socialNetwork);
    } else if (WO === "WoScore") {
      announcement =
        socialNetwork === "Whatsapp" ? "KYSSuccessWa" : "KYSSuccess";
      redirectLink = getRedirectUrlYourScore(socialNetwork);
    } else if (WO === "Pymes") {
      announcement =
        socialNetwork === "Whatsapp" ? "PymesSuccessWa" : "PymesSuccess";
      redirectLink = getRedirectUrlYourScore(socialNetwork);
    }
    sessionStorage.setItem("auxAnnouncement", announcement);
    console.log(` insertDatVirality ${insertDatVirality}`);
    console.log(`viralityUrl ${viralityUrl}`);
    console.log(
      `sessionStorage.getItem ${sessionStorage.getItem("trainingName")}`
    );
    console.log(`socialNetwork ${socialNetwork}`);
    viralityUTM(
      insertDatVirality,
      viralityUrl,
      sessionStorage.getItem("trainingName"),
      socialNetwork,
      "",
      "",
      "",
      false,
      Modal
    );
  };

  const [evaluacion, setEvaluacion] = useState(0);
  const [calificado, setCalificacion] = useState(false);
  const [goFeed, setGoFeed] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem("idTraining")}`;
      actions({ action: "ClickButtonCollection", variables: click });
      history.push("/the-training-feed/");
    }
  }, [goFeed]);

  const [saveEvaluateTraining] = useMutation(SAVE_EVALUATE_TRAINING, {
    onCompleted({ saveEvaluateTraining }) {
      if (saveEvaluateTraining.statusCode === 200) {
        console.log("saveEvaluateTraining", saveEvaluateTraining.response);
      }
    },
  });
  function envioEval() {
    const click = `EnviarEvaluacion_${sessionStorage.getItem("idTraining")}`;
    actions({ action: "ClickButtonCollection", variables: click });
    setCalificacion(true);
    // scrollToRoutine();
    console.log(evaluacion);
    if (
      sessionStorage.getItem("IdDatTraining") !== null &&
      sessionStorage.getItem("IdDatTraining") !== ""
    ) {
      saveEvaluateTraining({
        variables: {
          idDatTraining: String(sessionStorage.getItem("IdDatTraining")),
          stars: String(evaluacion),
        },
      });
    }
  }

  useEffect(() => {
    if (showModal) {
      const click = `CompartirCoru_${sessionStorage.getItem("idTraining")}`;
      actions({ action: "ClickButtonCollection", variables: click });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return !Modal ? (
    <TrainingSectionGray back="#fafafa">
      <TrainingDivSection2
        wiw={WO === "ProtecciónFamiliar" ? "670px" : "100%"}
        paddi="0"
        paddiw="0"
        mw="auto"
        back="#fafafa"
      >
        <>
          {!isCompareCardSuccess ? (
            <>
              <TrainingSectionGray back="#fafafa">
                {sessionStorage.getItem("idTraining") === "25" ? (
                  <ProductsFremium
                    idT={parseInt(sessionStorage.getItem("idTraining"))}
                  />
                ) : null}
              </TrainingSectionGray>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: ".5em",
                }}
              >
                <Kanda goToDiagnostico="DiagnosticoX" />
              </div>
            </>
          ) : (
            ""
          )}
          <ModalCompartir
            show={showModal}
            setShow={setShowModal}
            children={
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "85%", marginTop: "-15px" }}>
                    <Subtitle
                      className="roboto"
                      weight="bold"
                      weightM="bold"
                      size="25px"
                      sizeM="20px"
                      heightM=" "
                      mleftM=" "
                      style={{ marginBottom: "20px" }}
                    >
                      Comparte en:
                    </Subtitle>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        borderBottom: "2px solid #CDCDCD",
                        paddingBottom: "20px",
                        cursor: "pointer",
                        paddingTop: "20px",
                      }}
                      onClick={() => shareTraining("Facebook")}
                    >
                      <img
                        alt=""
                        position="initial"
                        left="0"
                        width="50px"
                        height="50px"
                        style={{
                          maxWidth: "100%",
                          width: "50px",
                          height: "50px",
                          position: "initial",
                          marginLeft: "0",
                        }}
                        src={face}
                      />
                      <Subtitle
                        className="roboto"
                        weight="bold"
                        weightM="bold"
                        size="25px"
                        heightM=" "
                        mleftM=" "
                        width=" "
                        aling="center"
                        marginTop=" "
                      >
                        Facebook
                      </Subtitle>
                    </div>
                    <div
                      className="display-mobile-flex"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        borderBottom: "2px solid #CDCDCD",
                        paddingBottom: "20px",
                        cursor: "pointer",
                        paddingTop: "20px",
                      }}
                      onClick={() => shareTraining("Messenger")}
                    >
                      <img
                        alt=""
                        position="initial"
                        left="0"
                        width="44px"
                        height="50px"
                        style={{
                          maxWidth: "100%",
                          width: "50px",
                          height: "54px",
                          position: "initial",
                          marginLeft: "0",
                        }}
                        src={twit}
                      />
                      <Subtitle
                        className="roboto"
                        weight="bold"
                        weightM="bold"
                        size="25px"
                        heightM=" "
                        mleftM=" "
                        width=" "
                        aling="center"
                        marginTop=" "
                      >
                        Messenger
                      </Subtitle>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        paddingBottom: "20px",
                        cursor: "pointer",
                        paddingTop: "20px",
                      }}
                      onClick={() => shareTraining("Whatsapp")}
                    >
                      <img
                        alt=""
                        position="initial"
                        left="0"
                        top="6%"
                        width="50px"
                        height="50px"
                        style={{
                          maxWidth: "100%",
                          width: "50px",
                          height: "50px",
                          position: "initial",
                          marginLeft: "0",
                        }}
                        src={whats}
                      />
                      <Subtitle
                        className="roboto"
                        weight="bold"
                        weightM="bold"
                        size="25px"
                        heightM=" "
                        mleftM=" "
                        width=" "
                        aling="center"
                        marginTop=" "
                      >
                        Whatsapp
                      </Subtitle>
                    </div>
                  </div>
                </div>
              </>
            }
          />
          <ShareWin
            bannerType="mobile"
            margin="20px auto 10px 120px"
            width="60%"
            widthMb="90%"
            name={`ComparteyGana_Training${scoretraining}`}
          />
        </>
        <CardOne
          classAna={anchop <= 768 ? "falseAna" : "trueAna"}
          classCard="cardOne"
          classTop="topOne1"
          children={
            <>
              {!calificado ? (
                <>
                  <p>Ayúdanos a mejorar tu experiencia.</p>
                  <p>¿Te gustó mi asesoría?</p>
                </>
              ) : (
                <>
                  <p>
                    ¡Gracias por tu tiempo! Tú opinión es muy importante para mí{" "}
                    <span aria-label="happy face" role="img">
                      😀
                    </span>
                    .
                  </p>
                  <p>
                    ¡Vuelve pronto!{" "}
                    <span aria-label="hand saying goodbye" role="img">
                      👋
                    </span>
                  </p>
                </>
              )}
              <RatingStars
                value={evaluacion}
                setValue={setEvaluacion}
                calificado={calificado}
              />
              {!calificado ? (
                <CardsBtn
                  windthG="100%"
                  color="#FFF"
                  back={evaluacion === 0 ? "gray" : "#F64282"}
                  onClick={evaluacion !== 0 ? () => envioEval() : null}
                >
                  Enviar evaluación
                </CardsBtn>
              ) : null}
            </>
          }
        />
        {!isCompareCardSuccess ? (
          <CardOne
            classAna={anchop <= 768 ? "falseAna" : "trueAna"}
            classCard="cardOne"
            classTop="topOne1"
            text="Sigue descubriendo más de Coru."
            children={
              <>
                <CardsBtn
                  windthG="100%"
                  type="button"
                  onClick={() => setGoFeed(true)}
                  color="#EC407A !important"
                  back="#fff !important"
                  border="solid 1px !important"
                  padd="8px 15px 4px"
                  heigth="49px"
                >
                  <ImageSticker
                    marg="-8px 20px auto auto"
                    width="35px"
                    src={gymOn}
                  />
                  <b>Más entrenamientos</b>
                </CardsBtn>
              </>
            }
          />
        ) : (
          ""
        )}
      </TrainingDivSection2>
    </TrainingSectionGray>
  ) : (
    <DivFlex
      className="align-items-center mt-2"
      justify="space-between"
      wid="70%"
      margin="auto"
      widthweb="70%"
      marweb="auto"
    >
      <img
        onClick={() => shareTraining("Facebook")}
        alt=""
        position="initial"
        left="0"
        width="50px"
        height="50px"
        style={{
          maxWidth: "100%",
          width: "50px",
          height: "50px",
          position: "initial",
          marginLeft: "0",
          cursor: "pointer",
        }}
        src={face}
      />
      <img
        className="display-web"
        onClick={() => shareTraining("Messenger")}
        alt=""
        position="initial"
        left="0"
        width="44px"
        height="50px"
        style={{
          maxWidth: "100%",
          width: "50px",
          height: "54px",
          position: "initial",
          marginLeft: "0",
          cursor: "pointer",
        }}
        src={Messenger}
      />
      <img
        className="display-mobile"
        onClick={() => shareTraining("MessengerMob")}
        alt=""
        position="initial"
        left="0"
        width="44px"
        height="50px"
        style={{
          maxWidth: "100%",
          width: "50px",
          height: "54px",
          position: "initial",
          marginLeft: "0",
          cursor: "pointer",
        }}
        src={Messenger}
      />
      <img
        onClick={() => shareTraining("Whatsapp")}
        alt=""
        position="initial"
        left="0"
        top="6%"
        width="50px"
        height="50px"
        style={{
          maxWidth: "100%",
          width: "50px",
          height: "50px",
          position: "initial",
          marginLeft: "0",
          cursor: "pointer",
        }}
        src={whats}
      />
    </DivFlex>
  );
};

export default ShareSection;
