import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CheckMark from '../../assets/CheckMark';
import {
  CheckMainContainer,
  CheckItemContainer,
  CheckListDivisor,
} from '../../assets/styles/GloveBox.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';

const GloveBoxCheckList = ({
  routine,
  steper,
  handleChangeQuestionsSend,
  handleChangeCatOption,
}) => {
  // const [atLeastOne, setAtLeastOne] = useState(false);
  const handleCheckeds = (e) => {
    let lineChecked = '';
    const elements = [...e.target.parentElement.children];
    const checkeds = [];
    elements.forEach((element, i) => {
      if (i % 2 === 0 && i < elements.length - 1 && element.lastChild.childNodes.length > 0) {
        checkeds.push(element.firstChild.textContent);
      }
    });
    for (let i = 0; i < checkeds.length; i += 1) {
      if (i === 0) {
        lineChecked = `${checkeds[i]}`;
      } else {
        lineChecked = `${lineChecked},${checkeds[i]}`;
      }
    }
    if (lineChecked !== '') {
      handleChangeCatOption({
        step: steper,
        value: lineChecked,
      });
      // En value se pone el valor que será guardado en BD
      handleChangeQuestionsSend(
        routine.QuestionsOrder,
        routine.QuestionName,
        lineChecked,
        'Multiple',
      );
    }
  };

  return (
    <CheckMainContainer>
        {routine.detailQuestion ? (
          <>
            {routine.detailQuestion.map((item, i) => {
              const { FieldOption } = item;
              return (
                <Fragment key={i}>
                  <CheckItemContainer>
                    <p
                      style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: '400',
                      }}
                    >
                      {FieldOption}
                    </p>
                    <CheckMark checked={false} />
                  </CheckItemContainer>
                  <CheckListDivisor />
                </Fragment>
              );
            })}
          </>
        ) : null }
        <CardsBtn
          type="button"
          min_w="100%"
          min_web="100%"
          mtopw="10px"
          onClick={(e) => { handleCheckeds(e); }}
        >
          Continuar
        </CardsBtn>
      </CheckMainContainer>
  );
};

GloveBoxCheckList.propTypes = {
  routine: PropTypes.object.isRequired,
  steper: PropTypes.number.isRequired,
  handleChangeCatOption: PropTypes.func.isRequired,
  handleChangeQuestionsSend: PropTypes.func.isRequired,
};

export default GloveBoxCheckList;
