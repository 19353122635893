/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const ExtraIncome = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActivarFlujo = '',
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [ocupacionActual, setOcupacionActual] = useState(
    sessionStorage.getItem('OcupacionActual') || '',
  );
  const [otrasActividades, setOtrasActividades] = useState(
    sessionStorage.getItem('OtrasActividades') || '',
  );
  const [principalMotivacion, setPrincipalMotivacion] = useState(
    sessionStorage.getItem('PrincipalMotivacion') || '',
  );
  const [ingresosExtras, setIngresosExtras] = useState(
    sessionStorage.getItem('IngresosExtras') || '',
  );
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editOcupacion, setEditOcupacion] = useState('');
  const [editActividades, setEditActividades] = useState('');
  const [editMotivacion, setEditMotivacion] = useState('');
  const [editIngExtra, setEditIngExtra] = useState('');

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setEditOcupacion(true);
        setOcupacionActual(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest2EI' });
        break;
      case 1:
        setEditActividades(true);
        setOtrasActividades(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest3EI' });
        break;
      case 2:
        setEditMotivacion(true);
        setPrincipalMotivacion(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest4EI' });
        break;
      case 3:
        setEditIngExtra(true);
        setIngresosExtras(val.value);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log('default');
        break;
    }
  };
  const handleChange = (e, question = '') => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];

              if (responseUser && responseUser !== '') {
                switch (questionOrder) {
                  case 1:
                    setOcupacionActual(responseUser);
                    break;

                  case 2:
                    setOtrasActividades(responseUser);
                    break;

                  case 3:
                    setPrincipalMotivacion(responseUser);
                    break;

                  case 4:
                    setIngresosExtras(responseUser);
                    setOrderRutine(nextOrderRoutine);
                    setQuestions([]);
                    break;

                  default:
                    break;
                }
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  const handleChangeIcon = (json) => {
    setEditOcupacion(false);
    setEditActividades(false);
    setEditMotivacion(false);
    setEditIngExtra(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  const [loaderWait, setLoaderWait] = useState(true);
  const anchop = window.screen.width;
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#activeExtraIncome' });
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 2 ? (
        <>
          {/* pregunta número uno */}
          <div id="activeExtraIncome"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (ocupacionActual === ''
                || (lapiz[0].routine === 2 && lapiz[0].active === 1))
              && editOcupacion !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeQuest2EI"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                ocupacionActual !== ''
                && (editOcupacion === true
                  || !(lapiz[0].routine === 2 && lapiz[0].active === 1))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 2, active: 1 })}
            />
            <CardTwo
              classCard={
                ocupacionActual !== ''
                && (editOcupacion === true
                  || !(lapiz[0].routine === 2 && lapiz[0].active === 1))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={ocupacionActual}
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {/* pregunta número dos */}
      {ocupacionActual !== ''
      && (editOcupacion === true
        || !(lapiz[0].routine === 2 && lapiz[0].active === 1)) ? (
        <QuestionsOrder
          classCard={ocupacionActual !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      <TrainingQuestion
        className={
          ((ocupacionActual !== '' && otrasActividades === '')
            || (lapiz[0].routine === 2 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 1)
            || editOcupacion === true)
          && editActividades !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest3EI"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            otrasActividades !== ''
            && (editActividades === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 2))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 2 })}
        />
        <CardTwo
          classCard={
            otrasActividades !== ''
            && (editActividades === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={otrasActividades}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número tres */}
      {otrasActividades !== ''
      && (editActividades === true
        || !(lapiz[0].routine === 2 && lapiz[0].active === 2)) ? (
        <QuestionsOrder
          classCard={otrasActividades !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      <TrainingQuestion
        className={
          ((otrasActividades !== '' && principalMotivacion === '')
            || (lapiz[0].routine === 2 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 2)
            || editActividades === true)
          && editMotivacion !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest4EI"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            principalMotivacion !== ''
            && (editMotivacion === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 3))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 3 })}
        />
        <CardTwo
          classCard={
            principalMotivacion !== ''
            && (editMotivacion === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={principalMotivacion}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número cuatro */}
      {principalMotivacion !== ''
      && (editMotivacion === true
        || !(lapiz[0].routine === 2 && lapiz[0].active === 3)) ? (
        <QuestionsOrder
          classCard={principalMotivacion !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      <TrainingQuestion
        className={
          ((principalMotivacion !== '' && ingresosExtras === '')
            || (lapiz[0].routine === 2 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 3)
            || editMotivacion === true)
          && editIngExtra !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="3-3"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            ingresosExtras !== ''
            && (editIngExtra === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 4))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 4 })}
        />
        <CardTwo
          classCard={
            ingresosExtras !== ''
            && (editIngExtra === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={ingresosExtras}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </Fragment>
  );
};

export default ExtraIncome;
