/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import Modal from '@material-ui/core/Modal';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import '../../assets/styles/Home.css';
import RoutinesOrderEmergencySaving from './RoutinesOrderEmergencySaving';
import imgWo from '../../assets/img/anacoach/ahorro.svg';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import {
  Rectangle13,
  Rectangle24,
  Vector2,
  Vector3,
  UiCard,
  TextoCoach,
  ContentHomeSection,
} from '../../components/common/Home.styled';
import {
  getQueryVariable,
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
} from '../../Helpers';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import {
  Container,
  Col7,
  ParagrapTwo,
} from '../../components/common/common.styled';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  UPDATE_HISTORY_COLLECTION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import PlayYoutube from '../../components/common/PlayYoutube';

import {
  GETPERCENTAGETRAINING,
  RECOVERY_ROUTINE_BY_ORDER,
  VERIFY_USER_WITH_TRAINING,
  GET_TRAINING_STATUS,
} from '../../queries';

import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainigTam,
  TrainingLine,
  CardsBtn,
  CardsBtn1,
} from '../../assets/styles/Training.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import WaitList from '../../components/Training/WaitList';
import Header from '../../components/common/Header';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';
import WOlayout from '../../components/layouts/WOlayout';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';

const items2 = ['Ahorro de emergencia'];
// const config = { mass: 5, tension: 1000, friction: 200 };

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '550px',
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const ModalBudget = () => {
  const history = useHistory();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  const goTrainingBudget = (event) => {
    event.preventDefault();
    sessionStorage.setItem('WO_came_from', '/wo/emergency-saving');
    sessionStorage.setItem('Saving_Budget', 'true');
    history.push('/wo/budget');
  };
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <ContentHomeSection
          displayD="flex"
          leftD="0%"
          floatD="left"
          width="100%;"
        >
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="48%;"
          ></ContentHomeSection>
          <ImageCoachInicio />
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="35%;"
          ></ContentHomeSection>
        </ContentHomeSection>
        <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
          <Rectangle24
            positionD="absolute"
            Ptop="125px"
            PTopM="-65px"
            Pleft="-10%"
            background="#6BD5AF"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="70px"
            height="70px"
            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
          />
          <Vector2
            width="55px"
            left="0"
            PleftM="35%"
            height="65px"
            background="#91D770"
            radius="40%"
            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
            positionD="absolute"
            Pleft="17%"
            Ptop="-5px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="10px"
            Pleft="44%"
            background=" #E3E660;"
            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="none"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="100px"
            Pleft="74%"
            PLeftM="-78%"
            PTopM="-32%"
            background="#BFDD6A"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            transform="rotate(46.41deg)"
          />
          <Vector2
            width="50px"
            widthM="28.81px"
            heightM="35.02px"
            left=""
            height="60px"
            background="#6BD5AF"
            radius="40%"
            transform="rotate(-8.34deg)"
            positionD="absolute"
            Pleft="52%"
            PleftM="-15%"
            PTopM="-10%"
            MtopM="-15%"
            Ptop="135px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="85px"
            Pleft="14%"
            PleftM="-3%"
            background="#E3E660"
            transform="rotate(-15.06deg)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            widthM="35px"
            heightM="35px"
            mLeftM="0"
            mTopM="18%"
            positionD="absolute"
            Pleft="70%"
            Ptop="0"
            displayD=""
            width="60px"
            height="60px"
            left=" "
            top=" "
            background="#91D770"
            transform="rotate(-17.24deg)"
          />

          <UiCard
            positionM=" "
            heightM="150px"
            marginTop="129px"
            marginTopM="120px"
          >
            <Rectangle13
              boxSha=" "
              margin="auto"
              bottomM="0px"
              displayM="block"
              position=" "
              topM="0px"
              height="156px"
              width="100%"
              widthm="100%"
              padding="19px 25px 20px 21px"
            >
              <TextoCoach height="auto" className="roboto" width="auto">
                <b>¡Hola! Soy Ana, tu coach financiero.</b>
                <br />
                <br />
              </TextoCoach>
              <TextoCoach
                height="auto"
                align="left"
                className="roboto"
                width="auto"
              >
                Para continuar con este entrenamiento, te recomendamos que
                realices primero el entrenamiento de presupuesto.
              </TextoCoach>
            </Rectangle13>
          </UiCard>
        </ContentHomeSection>
        <CardsBtn
          min_w="100%"
          min_web="100%"
          onClick={(e) => goTrainingBudget(e)}
        >
          <b>Ir al entrenamiento</b>
        </CardsBtn>
        <br />
        <br />
        <Link to="/the-training-feed/">
          <CardsBtn
            back="#FFFFFF"
            color="#F64282"
            border="2px solid #F64282"
            min_w="100%"
            min_web="100%"
          >
            <b>Ir al inicio</b>
          </CardsBtn>
        </Link>
      </div>
    </Modal>
  );
};

function EmergencySaving() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 21);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const anchop = window.screen.width;
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  // End ValidWaitList
  const [arrayOrderRoutines] = useState([]);
  const [percentage, setPercentage] = useState('');
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(3);
  const [currentTraining] = useState(21);
  const [skipVideo, setSkipVideo] = useState('');
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [urlVideo] = useState('https://www.youtube.com/embed/ad8EpmhS-l4');
  // const [idVideo, setIdVideo] = useState('');
  const [budgetCompleted, setBudgetCompleted] = useState(false);
  const [countQueries, setCountQueries] = useState(1);
  const [saltarVideo, setSaltarVideo] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true'
      && sessionStorage.getItem('Saving_Budget') === 'true',
  );

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);
  //----------------------------------------------------------------
  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickVideoCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const playVideo = (action) => {
    gsap.to(window, { duration: 1, scrollTo: '#SaltarVideo' });
    setSaltarVideo(true);
    setSkipVideo('skip');
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickVideoYT',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickVideo')) {
      const dataJson = [
        {
          nameTraining: sessionStorage.getItem('trainingName'),
          idUser: sessionStorage.getItem('idUser'),
          action,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem('idClickVideo'),
      });
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          key: 'ClickVideo',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem('id_session'),
        ClickVideo: [
          {
            nameTraining: sessionStorage.getItem('trainingName'),
            idUser: sessionStorage.getItem('idUser'),
            action,
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          data: dataString,
        },
      };
      createClickVideoCollection({ variables: mongoInput });
    }
  };
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser, idCont, hostName, pageName, fromXCoach, idAdviser,
    } = getQueryVariableHashtagDeeplink('emergency-saving');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      arrayOrderRoutines.push(orderRoutine);
    }
  };
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    pushArrayOrderRoutine(orderRutine);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') === null
            && sessionStorage.getItem('idDatSession') === null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          if (
            jsonResponse.idSession !== ''
            && jsonResponse.idSession !== null
            && jsonResponse.idSession !== undefined
          ) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        if (porcentaje !== 0) {
          setactive0(true);
          setactive1(true);
          setactive2(true);
          setSkipVideo('skip');
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    pushArrayOrderRoutine(orderRutine);
    saveAdvance(orderRutine);
  }, [orderRutine]);

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    if (!sessionStorage.getItem('ObjetivoAhorro')) {
      const deleteRoutine = arrayOrderRoutines.indexOf('5');
      if (deleteRoutine !== -1) arrayOrderRoutines.splice(deleteRoutine, 1);
    }
    const stringOrderRoutines = arrayOrderRoutines.join(',');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: stringOrderRoutines,
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setSkipVideo('skip');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        const routines = json;
        console.log('caracteristicas', routines);
        for (const idRoutine in routines) {
          const arrayResponseRutine = routines[idRoutine].responses;
          for (let i = 0; i < arrayResponseRutine.length; i++) {
            const idTypeQuestion = arrayResponseRutine[i].idType;
            const responseUser = arrayResponseRutine[i].responseValue;
            const { questionType } = arrayResponseRutine[i];
            if (
              responseUser !== ''
              && responseUser !== 'null'
              && responseUser !== null
            ) {
              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }
          }
        }
        setBudgetCompleted(true);
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [verifyUserWithTraining] = useLazyQuery(VERIFY_USER_WITH_TRAINING, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ verifyUserWithTraining }) {
      console.log(verifyUserWithTraining);
      if (verifyUserWithTraining.message === 'success') {
        const json = JSON.parse(verifyUserWithTraining.response);
        console.log('Achievment === ', json[0].Achievment);
        if (json[0].Achievment === '100' || json[0].Achievment === 100) {
          setBudgetCompleted(true);
          sessionStorage.setItem('Saving_Budget', 'true');
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (orderRutine === 3) {
      verifyUserWithTraining({
        variables: {
          idUser: sessionStorage.getItem('idUser'),
          idTraining: '14',
        },
      });
    }
  }, []);
  useEffect(() => {
    if (budgetCompleted && countQueries <= 3) {
      setBudgetCompleted(false);
      let order = '';
      if (countQueries === 1) {
        order = '3';
        setCountQueries(countQueries + 1);
      } else if (countQueries === 2) {
        order = '6';
        setCountQueries(countQueries + 1);
      } else if (countQueries === 3) {
        order = '10';
        setCountQueries(countQueries + 1);
      }
      recoveryRoutineByOrder({
        variables: {
          idTraining: '14',
          orderRoutine: order,
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, [budgetCompleted]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 21 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  const hora = `${date.getHours()}:${minutos}`;
  const [saludo] = useState('Si');
  const feacturesArray = useState([
    {
      text: 'Dinero reservado para emergencias.',
      color: '#7FBD62',
    },
    {
      text: 'El ahorro debe de alcanzar para cubrir gastos de 3 a 6 meses.',
      color: '#7FBD62',
    },
    {
      text: 'Debe de estar disponible para el momento que se necesite.',
      color: '#7FBD62',
    },
    {
      text: 'Guarda el ahorro de emergencia en un lugar que te dé rendimientos o ganancias mientras no se usa.',
      color: '#7FBD62',
    },
  ]);

  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '20 minutos',
    imgWo: { imgWo },
    txtsubtitle: '¡Que no te ganen los imprevistos!',
  };
  return (
    <WOlayout
      backgroundColor={'#7FBD62'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Ahorro de Emergencia: Aprende a Hcerlo | Coru</title>
        <link rel="canonical" href="https://coru.com/wo/emergency-saving" />
        <meta name="keywords" content="ahorro de emergencia" />
        <meta
          name="description"
          content="Si no tienes ahorro de emergencia ahora es el mejor momento de pensar en hacer uno. Te decimos cómo tienes que hacerle para lograrlo sin quedar al límite. "
        />
      </Helmet>
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            {sessionStorage.getItem('Saving_Budget') === 'true' ? null : (
              <ModalBudget />
            )}
            <TrainingAnimateFunnel
              bcolor="#7FBD62"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <TrainigTam>
                <Header
                  background={'#7FBD62'}
                  pathname={window.location.pathname}
                />
              </TrainigTam>
              <BarraInfoWO propied={propied} />
              <ModalDataPersonalInitWO isData={isData} setData={setisData} />
              <TrainingLine
                id="line-percent-gray"
                mt=""
                mtw="404px"
              ></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
            </TrainingAnimateFunnel>
            <div style={{position: 'relative'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <DataPersonalInitWO
                  Date={Date}
                  hora={hora}
                  orderRutine={orderRutine}
                  setOrderRutine={setOrderRutine}
                  currentTraining={currentTraining}
                  createJsonInput={createJsonInput}
                  questions={questions}
                  setQuestions={setQuestions}
                />
                {active0 && orderRutine !== 0 ? (
                  <TrainingSectionGray
                    wiw="100%"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      <div className="marginComentLgSuccess1">
                        <Starttraining
                          title="¡Hola! Soy Ana, tu coach financiero."
                          text="En este entrenamiento te ayudaré a comenzar con tu ahorro de emergencia."
                        />
                      </div>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                ) : null}
                {active1 && orderRutine !== 0 ? (
                  <Fragment>
                    <TrainingSectionGray
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Features
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="¿Cuáles son las características del ahorro de emergencia?"
                            Arraytext={feacturesArray}
                          />
                        </div>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                  </Fragment>
                ) : null}
                {active2 ? (
                  <Fragment>
                    {orderRutine !== 0 ? (
                      <Fragment>
                        <div className="shapes">
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <div className="marginComentLgSuccess1">
                                <Starttraining
                                  classAna={
                                    anchop <= 768 ? 'falseAna' : 'trueAna'
                                  }
                                  title="Conoce más de este proceso en nuestro video"
                                  text="Te recomiendo ver este video para conocer la diferencia entre un ahorro de emergencia y un ahorro convencional."
                                />
                              </div>
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                          {saludo === 'Si' ? (
                            <Fragment>
                              <div className="marginComentLgSuccess1">
                                <TrainingDivSection
                                  backw="#f8f9fa00"
                                  padding="0px"
                                  paddw="0px"
                                  mb="0"
                                  wi="90%"
                                  wiw="80%"
                                  he="auto"
                                  hew="auto"
                                  bsha="null"
                                >
                                  <TrainingDivSection
                                    mlw="10%"
                                    ml="0px"
                                    mr="0px"
                                    bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                                    mt="10px"
                                    br="4px"
                                    padding="3%"
                                    wi="100%"
                                    wiw="75%"
                                    he="auto"
                                    hew="auto"
                                    mb="0px"
                                    mtw="20px"
                                  >
                                    {/* <div className="triangulo-equilatero-bottom"></div> */}
                                    <div align="center">
                                      {urlVideo !== '' ? (
                                        <Fragment>
                                          <PlayYoutube
                                            idVideo={
                                              urlVideo.includes('http')
                                                ? urlVideo.split('/embed/')[1]
                                                : ''
                                            }
                                            width={'100%'}
                                            height={'190'}
                                            close={false}
                                          />
                                        </Fragment>
                                      ) : null}
                                    </div>
                                  </TrainingDivSection>
                                </TrainingDivSection>
                                <div style={{ display: 'flex' }}>
                                  <CardsBtn1 onClick={() => playVideo('Skip')}>
                                    Continuar
                                  </CardsBtn1>
                                </div>
                                <TrainingDivSection
                                  padding="0px"
                                  mt="0px"
                                  mtw="0px"
                                  paddw="0px"
                                  wiw=""
                                  backw="transparent"
                                  back="transparent"
                                >
                                  <SendNotificationVideo
                                    urlYTDirect={urlVideo}
                                  />
                                </TrainingDivSection>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <PlayYoutube
                                idVideo={
                                  urlVideo.includes('http')
                                    ? urlVideo.split('/embed/')[1]
                                    : ''
                                }
                                width={'100%'}
                                height={'190'}
                                close={true}
                              />
                              <SendNotificationVideo urlYTDirect={urlVideo} />
                            </Fragment>
                          )}
                        </div>
                        <div id="SaltarVideo"></div>
                      </Fragment>
                    ) : null}
                    <div
                      className={
                        orderRutine === 10
                          ? 'marginComentLgSuccess1 '
                          : 'marginComentLgSuccess1'
                      }
                    >
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          {skipVideo === 'skip'
                          && countQueries === 4
                          && saltarVideo ? (
                            <RoutinesOrderEmergencySaving
                              Date={stateDate}
                              hora={hora}
                              orderRutine={orderRutine}
                              setOrderRutine={setOrderRutine}
                              currentTraining={currentTraining}
                              createJsonInput={createJsonInput}
                              questions={questions}
                              setQuestions={setQuestions}
                            />
                            ) : null}
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    </div>
                    {recomendaciones && orderRutine === 10 ? (
                      <Fragment>
                        <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-10 offset-md-1 col-xs-12">
                          <Feed_Main width="100%" widthxs="100%" background=" ">
                            <Feed_Main
                              width="100%"
                              display=" "
                              mtop=" "
                              background="transparent"
                            >
                              <Feed_Section
                                padding="0 0 0 0"
                                paddingxs="0 0 0 0"
                              >
                                <ContentHomeSection
                                  leftD="0%"
                                  floatD=" "
                                  width="100%;"
                                >
                                  <Recommendations
                                    items={recommendedTraining}
                                  ></Recommendations>
                                </ContentHomeSection>
                              </Feed_Section>
                            </Feed_Main>
                          </Feed_Main>
                        </div>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
                {!active2 ? (
                  <Fragment>
                    <br></br>
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <Container
                        width="80%"
                        mt="0rem"
                        widthMobile="90%"
                        mtMobile="0rem"
                      >
                        <ImageCoachInicio topMobile="35px" />
                        <Col7 padding=" " className="cardPrincipal1">
                          <LoadingWo />
                        </Col7>
                      </Container>
                    </TrainingSectionGray>
                  </Fragment>
                ) : null}
              </div>
              <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
}

export default EmergencySaving;
