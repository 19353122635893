import React, { Fragment, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useMutation } from "@apollo/client";
import { Main } from "../../components/common/common.styled";
import "../../assets/styles/perfil/perfiles.css";
import FooterFeed from "../../components/Feed/Footer_Feed";
import Header from "../../components/common/Header";
import {
  DYNAMIC_GET,
  CREATE_SESSION,
  LOGLOGROS_GET_MONGO,
} from "../../mutations";
import PerfileWeb from "./PerfileWeb";
import { getQueryVariableHashtagDeeplink } from "../../Helpers";
import ModalVirality2 from "../../components/common/ModalVirality";

function FinancualProfile() {
  const [dataLoaded, setDataloaded] = useState(false);
  const [flag, setFlag] = useState(false);
  sessionStorage.setItem("PrimerVis", false);
  const [OpenModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
  };

  const [createLog] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem("id_logLogros", dynamicMongoSession.response);
      }
    },
  });

  const [GetLog] = useMutation(LOGLOGROS_GET_MONGO, {
    onCompleted({ LogLogrosGet }) {
      if (LogLogrosGet.response !== null) {
        sessionStorage.setItem("id_logLogros", LogLogrosGet.response);
      } else {
        const mongoInputBinacle = {
          idCont: sessionStorage.getItem("idCont"),
          idUser: sessionStorage.getItem("idUser"),
          FirstName: sessionStorage.getItem("FirstName"),
          MiddleName: sessionStorage.getItem("MiddleName"),
          FathersLastName: sessionStorage.getItem("FathersLastName"),
          MothersLastName: sessionStorage.getItem("MothersLastName"),
          email: sessionStorage.getItem("Email1"),
        };
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        mongoInputBinacle.timestampEC = [
          {
            timeStamp: today,
            idCatRelAction: sessionStorage.getItem("idCatRelAction"),
          },
        ];
        const dataStringBinacle = JSON.stringify(mongoInputBinacle);
        const mongoInput = {
          input: {
            collection: "LogLogros",
            data: dataStringBinacle,
          },
        };
        createLog({ variables: mongoInput });
      }
    },
  });

  /* useEffect(() => {
    const { idUser, idCont } = getQueryVariableHashtagDeeplink("profile");
    if (idUser && idUser !== "" && idCont && idCont !== "") {
      sessionStorage.setItem("idUser", idUser);
      sessionStorage.setItem("idCont", idCont);
      setFlag(true);
      if (!sessionStorage.getItem("id_logLogros")) {
        const mongoInput = {
          input: {
            collection: "LogLogros",
            filter: sessionStorage.getItem("idCont"),
          },
        };
        GetLog({ variables: mongoInput });
      }
    }

    document.querySelector("#root").style.backgroundColor = "#fafafa";
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idTraining')) {
      sessionStorage.removeItem('idTraining');
    }
    sessionStorage.setItem('BannerRewards', 'BannerRewards_Perfil');
  }, []); */

  const personalInformation = {
    nameDisplay: `${sessionStorage.getItem(
      "firstName"
    )} ${sessionStorage.getItem("fathersLastName")}`,
    firstName: sessionStorage.getItem("firstName"),
    middleName: sessionStorage.getItem("middleName"),
    fathersLastName: sessionStorage.getItem("fathersLastName"),
    mothersLastName: sessionStorage.getItem("mothersLastName"),
    CellPhone: sessionStorage.getItem("CellPhone"),
  };
  let userDetail;
  const [getAdress] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let response = JSON.parse(dynamicGet.response);
        if (response.length > 0) {
          response = response[0];
          sessionStorage.setItem("AddressType", response.AddressType || "");
          sessionStorage.setItem("ExtNo", response.ExtNo || "");
          sessionStorage.setItem("Gmaps", response.Gmaps || "");
          sessionStorage.setItem("IDAddress", response.IDAddress || "");
          sessionStorage.setItem("IntNo", response.IntNo || "");
          sessionStorage.setItem("Municipality", response.Municipality || "");
          sessionStorage.setItem("Priority", response.Priority || "");
          sessionStorage.setItem("State", response.State || "");
          sessionStorage.setItem("Street", response.Street || "");
          sessionStorage.setItem("Suburb", response.Suburb || "");
          sessionStorage.setItem("ZipCode", response.ZipCode || "");
        }
      }
    },
  });

  const [getCatUserDetails] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let response = JSON.parse(dynamicGet.response);
        if (response.length > 0) {
          response = response[0];
          sessionStorage.setItem("AddressPeriod", response.Value || "");
        }
      }
    },
  });

  const [getUser] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let userDetail = JSON.parse(dynamicGet.response)[0];
        if (userDetail) {
          sessionStorage.setItem("CellPhone", userDetail.CellPhone || "");
          sessionStorage.setItem("email", userDetail.Email1);
          sessionStorage.setItem("landline", userDetail.HomePhone || "");
          sessionStorage.setItem("firstName", userDetail.FirstName || "");
          sessionStorage.setItem("RFC", userDetail.RFC || "");
          sessionStorage.setItem("middleName", userDetail.MiddleName || "");
          sessionStorage.setItem(
            "fathersLastName",
            userDetail.FathersLastName || ""
          );
          sessionStorage.setItem(
            "mothersLastName",
            userDetail.MothersLastName || ""
          );
          sessionStorage.setItem("BirthDate", userDetail.BirthDate || "");
          sessionStorage.setItem("Gender", userDetail.Gender || "");
          sessionStorage.setItem(
            "MaritalStatus",
            userDetail.MaritalStatus || ""
          );
          sessionStorage.setItem("BirthState", userDetail.BirthState || "");
          sessionStorage.setItem("CaptureDate", userDetail.CaptureDate || "");
          sessionStorage.setItem("EntityType", userDetail.EntityType || "");
          sessionStorage.setItem("DataPerson", true);
          if (
            sessionStorage.getItem("BirthDate") !== "" &&
            sessionStorage.getItem("BirthDate") != null
          ) {
            const BirthDate = new Date(sessionStorage.getItem("BirthDate"));
            sessionStorage.setItem(
              "day",
              BirthDate.getDate() > 9
                ? BirthDate.getDate()
                : `0${BirthDate.getDate()}`
            );
            sessionStorage.setItem(
              "month",
              BirthDate.getMonth() > 8
                ? BirthDate.getMonth() + 1
                : `0${BirthDate.getMonth() + 1}`
            );
            sessionStorage.setItem("year", BirthDate.getFullYear());
          }
          setDataloaded(true);
        } else {
          window.location.href = "/";
        }
      }
    },
  });
  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach((element) => {
          switch (element.IDType) {
            case 3:
              sessionStorage.setItem("levelStudy", element.Value);
              break;
            case 4:
              sessionStorage.setItem("occupation", element.Value);
              break;
            case 5:
              sessionStorage.setItem("TipoDeVivienda", element.Value);
              break;
            case 6:
              sessionStorage.setItem("dependents", element.Value);
              break;
            case 20:
              sessionStorage.setItem("entry", element.Value);
              break;
            case 45:
              sessionStorage.setItem("curp", element.Value);
              break;
            case 69:
              sessionStorage.setItem("expiration", element.Value);
              break;
            case 135:
              sessionStorage.setItem("ine", element.Value);
              break;
            default:
              break;
          }
        });
      }
    },
  });

  const getInfoUser = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const dynamicGetInput = {
      input: {
        table: "CatUserDetails",
        columns: ["*"],
        conditions: [
          {
            valone: "CatUsers_IDUser",
            condition: "=",
            valtwo: sessionStorage.getItem("idUser"),
          },
        ],
      },
    };
    const GetUserInput = {
      input: {
        table: "CatContactMedium, CatContact",
        columns: ["*"],
        conditions: [
          {
            valone: "IDCont",
            condition: "=",
            valtwo: sessionStorage.getItem("idCont"),
          },
          {
            logic: "AND",
            valone: "IDCont",
            condition: "=",
            valtwo: "CatContact_IDCont",
            validColumn: true,
          },
        ],
      },
    };
    const getAdressInput = {
      input: {
        table: "CatAddress",
        columns: ["*"],
        conditions: [
          {
            valone: "CatContact_IDCont",
            condition: "=",
            valtwo: sessionStorage.getItem("idCont"),
          },
        ],
      },
    };

    const getCatUserDetailsInput = {
      input: {
        table: "CatUserDetails",
        columns: ["*"],
        conditions: [
          {
            valone: "CatUsers_IDUser",
            valtwo: sessionStorage.getItem("idUser"),
            condition: "=",
          },
          {
            logic: "AND",
            valone: "IDType",
            valtwo: "10",
            condition: "=",
          },
        ],
      },
    };

    getAdress({ variables: getAdressInput });
    getCatUserDetails({ variables: getCatUserDetailsInput });
    getUser({ variables: GetUserInput });
    getUserDetail({ variables: dynamicGetInput });
  };

  useEffect(() => {
    // Validar si existe un idUser & idCont
    const idUser = sessionStorage.getItem("idUser") || "";
    const idCont = sessionStorage.getItem("idCont") || "";
    if (idUser === "" || idCont === "") {
      const { idUser, idCont } = getQueryVariableHashtagDeeplink("profile");
      if (idUser && idUser !== "" && idCont && idCont !== "") {
        sessionStorage.setItem("idUser", idUser);
        sessionStorage.setItem("idCont", idCont);
        setFlag(true);
        if (!sessionStorage.getItem("id_logLogros")) {
          const mongoInput = {
            input: {
              collection: "LogLogros",
              filter: sessionStorage.getItem("idCont"),
            },
          };
          GetLog({ variables: mongoInput });
        }
        getInfoUser();
      } else {
        window.location.href = "/";
      }
      document.querySelector("#root").style.backgroundColor = "#fafafa";
      if (sessionStorage.getItem('idTraining')) {
        sessionStorage.removeItem('idTraining');
      }
      sessionStorage.setItem('BannerRewards', 'BannerRewards_Perfil');
    } else if (idUser !== '' && idCont !== '') {
      getInfoUser();
    }
  }, []);
  return (
    <Fragment>
      {!dataLoaded ? undefined : (
        <Fragment>
          <Header pathname={window.location.pathname} background="#fafafa" />
          <Main
            heightComponent="100vh"
            background="#fafafa"
            pb="5%"
            mt="0%"
            mtWeb="0%"
            ptuno="0px"
            pt="0px"
          >
            <PerfileWeb name={personalInformation.firstName} />
          </Main>
          <FooterFeed check="perfil"></FooterFeed>
        </Fragment>
      )}
      {flag ? (
        <>
          <ModalVirality2
            setShowModal={handleOpenModal}
            showModal={OpenModal}
          />
        </>
      ) : null}
    </Fragment>
  );
}
export default FinancualProfile;
