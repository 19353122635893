import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Rombo,
  Vector1,
  Vector3,
} from '../../../components/common/Home.styled';
import ImageLogo from '../../../assets/img/anacoach/CoruLogoB.png';
import UserDefaultImg from '../../../assets/img/anacoach/Login.png';
import './styles/woHeader.css';

const WOHeader = ({ headerType, backgroundColor, extraLInks }) => {
  const [optionNavMobile, setOptionNavMobile] = useState('');

  const links = [
    {
      title: 'Entrenamientos',
      type: 'url',
      path: '/the-training-feed/',
      mobile: true,
      browser: true,
    },
    {
      title: 'Logros',
      type: 'url',
      path: '/attainment/',
      mobile: true,
      browser: true,
    },
    {
      title: 'Tarjeta de crédito',
      type: 'url',
      path: '/coach/thetrainig/credito/tarjeta-de-credito/funnel-tarjeta/',
      mobile: true,
      browser: true,
    },
    {
      title: 'Préstamos a tu medida',
      type: 'url',
      path: '/coach/thetrainig/FastTrack/',
      mobile: true,
      browser: true,
    },
    {
      title: 'Perfil',
      type: 'url',
      path: '/peril',
      mobile: true,
      browser: false,
    },
    {
      title: 'Cerrar sesión',
      type: 'function',
      function: () => logOut(),
      mobile: true,
      browser: true,
    },
  ];

  const logOut = () => {
    console.log('Cerrar sesión');
  };

  const loadLinks = (type) => links.map((link) => linkType(link, type));

  const linkType = (link, type) => {
    if (link.type === 'url' && link[type]) {
      return (
        <li>
          <Link to={link.path}>{link.title}</Link>
        </li>
      );
    }

    if (link.type === 'function' && link[type]) {
      return (
        <li>
          <button onClick={link.function}>{link.title}</button>
        </li>
      );
    }
  };

  const handleOptionNav = (opc) => {
    setOptionNavMobile(opc);
  };

  return (
    <div className="sh-wo-header" style={{ backgroundColor }}>
      <div className="logo">
        <img src={ImageLogo} />
      </div>

      <div className="browser-options">
        <ul>
          {loadLinks('browser')}

          <li>
            <img src={UserDefaultImg} />
          </li>
        </ul>
      </div>

      <div className="mobile-options-button">
        <button onClick={() => handleOptionNav('expanded')}>☰</button>
      </div>

      <div className={`mobile-options ${optionNavMobile}`}>
        <div className="header-options">
          <div className="logo-options">
            <img src={ImageLogo} />
          </div>

          <div className="close-c">
            <button
              className="roboto"
              onClick={() => handleOptionNav('collapse')}
            >
              ×
            </button>
          </div>
        </div>

        <div className="options">
          <ul>{loadLinks('mobile')}</ul>
        </div>

        <div className="figures">
          <Rombo
            left="10%"
            width="30.35px"
            height="30.35px"
            transform="rotate(46.41deg)"
            background="#BFDD6A"
          />
          <Vector1
            width="56.12px"
            height="56.12px"
            background="#E3E660"
            transform="rotate(-15.06deg)"
            top="1%"
            left="60%"
          />
          <Rombo
            width="181.84px"
            height="181.84px"
            transform="rotate(-17.24deg)"
            background="#91D770"
            marginlef=""
            margintop="0%"
            index="-1"
            position=" absolute"
          />
          <Vector3 left="30%" top="-5%" />
        </div>
      </div>
    </div>
  );
};

export default WOHeader;
