import React, {
  useState, useEffect, Suspense,
} from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import DatosDeudas from '../Routines/DatosDeudas';
import TiposDeDeudas from '../Routines/TiposDeDeudas';
import DeudasInformales from '../Routines/DeudasInformales';
import DeudasBancarias from '../Routines/DeudasBancarias';
import DeudasDepartamentales from '../Routines/DeudasDepartamentales';
import VideoAutomotriz from '../Routines/VideoAutomotriz';
import ConAtrasoEnPagoDeuda from '../Routines/ConAtrasoEnPagoDeuda';
import SinAtrasoEnPagoDeuda from '../Routines/SinAtrasoEnPagoDeuda';
import CreditoHipotecario from '../Routines/CreditoHipotecario';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';
import {
  CREATE_SESSION_MYSQL,
  CREATE_SESSION,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import { GET_TRAINING_COLOR } from '../../queries';

import { DivLoader } from '../../assets/styles/Training.styled';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import DomicileDebts from '../Routines/DomicileDebts';
import DetailRFC from '../Routines/DetailRFC';
import UserAuthentication from '../Routines/UserAuthentication';
import ModalLogin from '../pantallasInicio/ModalLogin';
const RoutinesOrderDebts = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
}) => {
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [newSession, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const customAdvice = {
    active: true,
    title: '¡Excelente! 👍',
    text: 'Ahora ayúdame con la información que necesitan las instituciones para procesar tu solicitud y conocer más de los créditos que tienes activos.',
  };
  const [opcionesDeudas, setOpcionesDeudas] = useState('');
  const [atrasosBanco, setAtrasosBanco] = useState('');
  const [firstAuth, setFirstAuth] = useState(true);
  const handleOpcDeuda = (opc) => setOpcionesDeudas(opc);
  useEffect(() => {
    sessionStorage.setItem('datOpportunity', '0');
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
    );
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) { newSession({ variables: mongoInput }); }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    if (orderRutine === 'rp') {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
    if (orderRutine === 7 && firstAuth) {
      setFirstAuth(false);
    } else if (orderRutine === 7 && !firstAuth) {
      setOrderRutine(8);
    }
  }, [orderRutine]);

  return (
    <div>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          {/* <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={colorTraining}
          /> */}
          <ModalLogin 
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={4}
            onLoginRoutine={4}
            onRegisterRoutine={4}
          />
        </Suspense>
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          mainDataRoutine={mainDataRoutine}
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
        />
      ) : null}
      {/* {orderRutine >= 3 ? (
        <ContactInformation
          mainDataRoutine={mainDataRoutine}
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
        />
      ) : null} */}
      {orderRutine >= 4 ? (
        <DomicileDebts
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={colorTraining}
          customAdvice={customAdvice}
        />
      ) : null}
      <div id="rutina4"></div>
      <DetailRFC
        Date={Date}
        hora={hora}
        orderRutine={orderRutine}
        setOrderRutine={setOrderRutine}
        currentTraining={currentTraining}
        createJsonInput={createJsonInput}
        questions={questions}
        setQuestions={setQuestions}
        currentRoutine={5}
        nextOrderRoutine={6}
        trainingColor={colorTraining}
      />
      <div id="rutina5"></div>
      {orderRutine >= 6 ? (
        <UserAuthentication
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          nextOrderRoutine={7}
          trainingColor={colorTraining}
          checkBCReport={true}
          createSheet={true}
        />
      ) : null}
      {orderRutine >= 7 ? (
        <DatosDeudas
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={7}
          nextOrderRoutine={8}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 8 ? (
        <TiposDeDeudas
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={8}
          nextOrderRoutine={9}
          possibleRoutines={[9, 11, 12, 13, 14, 15]}
          activePossibleroutines={true}
          handleOpcDeuda={handleOpcDeuda}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 9 && opcionesDeudas === 'DeudasInformales' ? (
        <DeudasInformales
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={9}
          nextOrderRoutine="rp"
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 11 && opcionesDeudas === 'DeudasBancarias' ? (
        <DeudasBancarias
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={11}
          nextOrderRoutine={12}
          possibleRoutines={[15, 16]}
          activePossibleroutines={true}
          setNextNameRoutine={setAtrasosBanco}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 12 && opcionesDeudas === 'DeudasDepartamentales' ? (
        <DeudasDepartamentales
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={12}
          nextOrderRoutine={13}
          possibleRoutines={[15, 16]}
          activePossibleroutines={true}
          setNextNameRoutine={setAtrasosBanco}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 13 && opcionesDeudas === 'VideoAutomotriz' ? (
        <VideoAutomotriz
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={13}
          nextOrderRoutine={14}
        />
      ) : null}
      {orderRutine >= 14 && opcionesDeudas === 'CreditoHipotecario' ? (
        <CreditoHipotecario
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={14}
          nextOrderRoutine="rp"
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 15 && atrasosBanco === 'SinAtrasoEnPagoDeuda' ? (
        <SinAtrasoEnPagoDeuda
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={15}
          nextOrderRoutine="rp"
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 16 && atrasosBanco === 'ConAtrasoEnPagoDeuda' ? (
        <ConAtrasoEnPagoDeuda
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={16}
          nextOrderRoutine="rp"
          trainingColor={colorTraining}
        />
      ) : null}
    </div>
  );
};

export default RoutinesOrderDebts;
