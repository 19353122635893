/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { arraySessionsRutines } from '../../Helpers';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import editIcon from '../../assets/img/anacoach/editar1.png';
import { getProductInversion } from '../../Helpers/TableCalculateInversion';
import {
  TrainingDivSection,
  CardsBtn,
  TrainingSubtitles,
  TrainingText,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate'
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
  },
  margin: {
    height: theme.spacing(3),
  },
  // bolita
  thumb: {
    background: '#ffff',
    height: 10,
  },
  // puntos en el slider de la los rangos o marks
  mark: {
    background: 'transparent',
  },
  rail: {
    background:
      'linear-gradient(90deg, rgba(145,215,112,1) 34%, rgba(246,163,66,1) 34%, rgba(246,163,66,1) 74%, rgba(255,66,0,1) 74%)',
    borderRadius: '10px',
    height: '300px',
  },
  track: {
    background: 'transparent',
    height: '300px',
    borderRadius: '10px',
  },
  ControlSelect: {
    minWidth: 350,
  },
  Btn: {
    backgroundColor: '#ffff',
    marginBottom: '15px',
    height: '50px',
    width: '95%',
    marginLeft: '10px',
  },
  ContainerBtn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  ContainerSlider: {
    marginTop: '10px',
    marginBottom: '15px',
    width: '90%',
    marginLeft: '20px',
  },
}));

const marks = [
  // Conservador 4% - 8%

  {
    value: 4,
  },

  {
    value: 5,
    label: 'Conservador',
  },

  {
    value: 6,
  },

  {
    value: 7,
  },

  {
    value: 8,
  },

  // Moderado 9% - 12%

  {
    value: 9,
  },

  {
    value: 10,
    label: 'Moderado',
  },

  {
    value: 11,
  },

  {
    value: 12,
  },

  //  agresivo  13% - 16%

  {
    value: 13,
  },

  {
    value: 14,
  },

  {
    value: 15,
    label: 'Agresivo',
  },

  {
    value: 16,
  },
];

function NumberFormatCustomDecimal(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={true}
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      prefix="$"
    />
  );
}

NumberFormatCustomDecimal.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={true}
      prefix={'$'}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const CalFirstInvestmenteV2 = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  title,
  setInversionProducts,
  onClick,
}) => {
  const [Rutine, setRutine] = useState({});
  const { actions } = ClickButton();
  const { sendEmail } = SendNotificationEmail();
  const { sendMessage } = SendNotificationFTAndCC();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const handleChangeRutine = (data) => setRutine(data);
  const classes = useStyles();
  const [data, setData] = useState();
  const [seccionCalculadora, setSeccionCal] = useState(false);
  const [inversionCalculada, setInversionCalculada] = useState(0);
  const [valuePlazo, setValuePlazo] = useState(10);
  const [calculo, setCalculo] = useState(false);
  const [inversionObtenida, setInversionObtenida] = useState(
    sessionStorage.getItem('InversionObtenida') || '',
  );
  const [form, setForm] = useState({
    inversion: sessionStorage.getItem('MontoInversion') || '',
    plazo: sessionStorage.getItem('PlazoCalculadora2') || '',
    tasa: sessionStorage.getItem('TasaCalculadora2') || '4',
    monto: sessionStorage.getItem('MontoResultado') || '',
  });
  const [productosObtenidos, setProductoObtenidos] = useState([]);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [editRegistroInversion, setEditRegistroInversion] = useState('');

  useEffect(() => {
    let tasa = sessionStorage.getItem('TasaCalculadora2') || '0.10';
    tasa = parseFloat(tasa) * 100;
    tasa = parseInt(tasa);
    console.log('linea 201 tasa', tasa);
    setValuePlazo(tasa);
  }, []);

  useEffect(() => {
    let InversionObtenida = sessionStorage.getItem('InversionObtenida') || '';
    if (InversionObtenida === 'No disponible') {
      InversionObtenida = '';
    }
    if (InversionObtenida !== '') {
      setInversionProducts(productosObtenidos);
    }
  }, [productosObtenidos]);

  const handleChangeInversion = (name, value) => {
    console.log('handleChandePlazo', name, value);

    switch (name) {
      case 'inversion':
        console.log('linea 213', name, form.plazo);
        if (value !== '' && form.plazo !== '') {
          const products = getProductInversion(value, form.plazo);
          console.log('linea 216', products);
          if (products.length > 0) {
            if (isNaN(products[0].resultado)) {
              setInversionCalculada(products[0].resultado);
              setProductoObtenidos([]);
            } else {
              setProductoObtenidos(products);
              setInversionCalculada(formatter.format(products[0].resultado));
            }
          }
        }
        break;
      case 'plazo':
        if (form.inversion !== '' && value !== '') {
          const products = getProductInversion(form.inversion, value);
          console.log('linea 231', products);
          if (products.length > 0) {
            if (isNaN(products[0].resultado)) {
              setProductoObtenidos([]);
              setInversionCalculada(products[0].resultado);
            } else {
              setProductoObtenidos(products);
              setInversionCalculada(formatter.format(products[0].resultado));
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const handleOpcInversion = (e) => {
    const click = `OpcionesParaInvertir_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    sendEmail({ WO: 'FirstInv', trigger: '1er_inv_alerta5' });
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone') || '',
      trigger: 'Notificación_FT_5_AUX',
    });
    console.log('productosObtenidos', productosObtenidos);
    if (productosObtenidos.length > 0) {
      setEditRegistroInversion(true);
      setInversionObtenida(form.InversionObtenida);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setInversionProducts(productosObtenidos);
      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
      gsap.to(window, { duration: 1, scrollTo: '#OpcionesInversion' });
    }

    console.log('246', form);
    const val = Object.values(form);
    const values = val.map((e) => (e = e.toString()));
    const valid = values.every((value) => value !== '');
    if (valid) {
      console.log('Conocer donde invertir');
      setOrderRutine(nextOrderRoutine);
    } else {
      console.log('Mensaje a Usuario, revise campo');
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      let InversionObtenida = sessionStorage.getItem('InversionObtenida') || '';
      if (InversionObtenida === 'No disponible') {
        InversionObtenida = '';
      }
      setInversionObtenida(InversionObtenida);

      if (InversionObtenida !== '') {
        handleChangeInversion(
          'inversion',
          sessionStorage.getItem('MontoInversion'),
        );
        handleChangeInversion(
          'plazo',
          sessionStorage.getItem('PlazoCalculadora2'),
        );
      }
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    const monto = sessionStorage.getItem('MontoInversion');
    const plazo = sessionStorage.getItem('PlazoCalculadora2');
    if (monto !== '' && monto !== null && plazo !== '' && plazo !== null) {
      handleChangeInversion(
        'inversion',
        sessionStorage.getItem('MontoInversion'),
      );
      handleChangeInversion(
        'plazo',
        sessionStorage.getItem('PlazoCalculadora2'),
      );
    }
  }, []);
  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        const values = Object.values(json)[0];
        console.log('111', values);
        setData(values);
        handleChangeRutine(values);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          setData(values);
          console.log('linea 119 firstinvestmentcalculator', routines);
          console.log('linea 120 data', data);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            console.log('linea 109', idRoutine);
            const arrayResponseRutine = routines[idRoutine].responses;
            console.log('linea 112', arrayResponseRutine);
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    console.log(
                      '362 detailQuestion[x].FieldOption',
                      detailQuestion[x].FieldOption,
                    );
                    console.log(
                      'sessioStorage.setItem',
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  useEffect(() => {
    console.log('cambio la data', data);
  }, [data]);

  const createVS = (rutine, questions) => {
    console.log('linea 349', questions[0].question);
    sessionStorage.setItem('CalRutine', JSON.stringify(rutine));
    switch (questions[0].question) {
      case 1:
        sessionStorage.setItem('CalQuestion1', JSON.stringify(questions));
        break;
      case 2:
        sessionStorage.setItem('CalQuestion2', JSON.stringify(questions));
        break;
      case 3:
        sessionStorage.setItem('CalQuestion3', JSON.stringify(questions));
        break;
      case 4:
        sessionStorage.setItem('CalQuestion4', JSON.stringify(questions));
        break;
      default:
        break;
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('linea 473', numQuestion, name, value, type);
    console.log('linea 474', questions);

    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log('linea 494 cal', questions, ' rutine ', Rutine);
      createVS(Rutine, questions);
      setQuestions([]);
    } else {
      console.log('linea 499 cal', questions, ' rutine ', Rutine);
      createVS(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    let checkValue = value;
    console.log('512 checkvalue', checkValue, index);
    // Cat
    if (
      data.questions[index].detailQuestion.length > 0
      && index !== 0
      && index !== 3
    ) {
      if (index === 2) {
        checkValue = value / 100;
      }
      console.log('linea 522', data.questions[index].detailQuestion.length);
      const answer = data.questions[index].detailQuestion.filter(
        (e) => e.FieldOption === checkValue.toString(),
      );
      console.log('526 answer', answer);
      handleChangeQuestionsSend(
        index + 1,
        answer[0].FieldOption,
        answer[0].IDCatFieldDetail,
        data.questions[index].QuestionType,
      );
    } else {
      console.log('linea 534', data.questions[index].detailQuestion.length);
      console.log(
        '500 answer',
        data.questions[index].DatUserDetailStorage,
        value,
        data.questions[index].QuestionType,
      );
      // OpenField
      handleChangeQuestionsSend(
        index + 1,
        data.questions[index].DatUserDetailStorage,
        value,
        data.questions[index].QuestionType,
      );
    }
    switch (name) {
      case 'inversion':
        handleChangeInversion(name, value);
        break;
      case 'plazo':
        handleChangeInversion(name, value);
        break;
      default:
        break;
    }
  };
  const handleSliderChangePlazo = (event, newValue) => {
    console.log('linea 443', event, newValue);
    if (event && typeof event.persist === 'function') {
      event.persist();
    }
    setValuePlazo(newValue);
    const mark = marks.filter((e) => e.value === newValue);
    console.log('449 mark', mark, newValue, mark[0].label);
    handleInputChange(
      {
        target: {
          name: 'tasa',
          value: newValue,
          valueMarks: mark[0].label,
        },
      },
      2,
    );
  };

  const calculatorV2 = () => {
    console.log('567 calculadora', form.tasa);
    if (form.inversion !== '' && form.plazo !== '' && form.tasa !== '') {
      setCalculo(true);
      let plazo = form.plazo.split(' ');
      plazo = parseInt(plazo[0], 10);
      const inv = parseInt(form.inversion, 10);
      // ? formula calculadora
      const porcent = form.tasa / 100;
      const stepOne = inv * porcent;
      const stepTwo = stepOne / 365;
      const stepThree = stepTwo * plazo;
      const stepFinal = stepThree + inv;

      handleInputChange(
        {
          target: {
            name: 'monto',
            value: stepFinal.toFixed(3),
          },
        },
        3,
      );

      setSeccionCal(true);
    }
  };
  const handleChangeButton = () => {
    setSeccionCal(false);
  };
  return (
    <>
      {data ? (
        <Container
          width="80%"
          mt="0"
          widthMobile="90%"
          mtMobile="0"
          mleft="122px"
        >
          <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
            <TrainingDivSection
              hew="auto"
              className="topOne1 topOne1Init"
              paddw="26px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="100%"
              he="auto"
            >
              <TrainingSubtitles
                size="12px"
                mbotm="5px"
                fw="600"
                align="left"
                alignweb="left"
                className="cabin"
              >
                {title}
              </TrainingSubtitles>
              <form>
                <FormControl variant="outlined">
                  <TextField
                    label={data.questions[0].QuestionName}
                    name="inversion"
                    value={form.inversion}
                    onChange={(e) => handleInputChange(e, 0)}
                    className={classes.ControlSelect}
                    variant="outlined"
                    placeholder="$"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </FormControl>{' '}
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-filled-label">
                    {data.questions[1].QuestionName}
                  </InputLabel>{' '}
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    label="Plazo"
                    name="plazo"
                    value={form.plazo}
                    onChange={(e) => handleInputChange(e, 1)}
                    variant="outlined"
                    className={classes.ControlSelect}
                  >
                    {data.questions[1].detailQuestion.map((op) => (
                      <MenuItem
                        key={op.IDCatFieldDetail}
                        value={op.FieldOption}
                      >
                        {' '}
                        {op.FieldOption}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TrainingText
                  size="14px"
                  className="roboto"
                  bot="15px"
                  align="left"
                  alignweb="left"
                  ls="-0.2px"
                >
                  {data.questions[2].QuestionName}
                </TrainingText>
                <div className={classes.ContainerSlider}>
                  <TrainingText
                    size="14px"
                    className="roboto"
                    bot="0px"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                    left="145px"
                  >
                    {valuePlazo}%
                  </TrainingText>
                  <Slider
                    classes={{
                      thumb: classes.thumb,
                      rail: classes.rail,
                      track: classes.track,
                      valueLabel: classes.valueLabel,
                      mark: classes.mark,
                    }}
                    max={16}
                    defaultValue={10}
                    min={4}
                    step={null}
                    marks={marks}
                    name="tasa"
                    valueLabelDisplay="off"
                    onChange={handleSliderChangePlazo}
                    value={valuePlazo}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <CardsBtn
                    type="button"
                    windthG="98%"
                    mtop="0px"
                    mtopw="0px"
                    onClick={() => calculatorV2()}
                  >
                    Calcular{' '}
                  </CardsBtn>{' '}
                </div>{' '}
                {calculo ? (
                  <FormControl variant="outlined">
                    <TextField
                      id="outlined-basic"
                      label={data.questions[3].QuestionName}
                      type="null"
                      name="monto"
                      value={form.monto}
                      onChange={(e) => handleInputChange(e, 3)}
                      className={classes.ControlSelect}
                      variant="outlined"
                      placeholder="$"
                      InputProps={{
                        inputComponent: NumberFormatCustomDecimal,
                      }}
                    />
                  </FormControl>
                ) : (
                  ''
                )}
              </form>
              {calculo ? (
                <>
                  <div id="OpcionesInversion"></div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    <CardsBtn
                      type="button"
                      windthG="98%"
                      mtop="0px"
                      mtopw="0px"
                      onClick={(e) => handleOpcInversion(e)}
                    >
                      Conocer dónde invertir
                    </CardsBtn>
                  </div>
                </>
              ) : (
                ''
              )}
            </TrainingDivSection>
            <CardTwo
              classCard={seccionCalculadora ? 'cardTwoResp' : 'hidden'}
              classTop="topTwo1"
              imgMan="hidden"
              textT={'Registros de inversión:'}
              text2={`Monto a invertir: $${form.inversion}`}
              text3={`Plazo: ${form.plazo}`}
              text4={`Tasa anual: ${form.tasa}%`}
              text5={`Monto final: $${form.monto}`}
              colorTraining={trainingColor}
            >
              <img
                alt="editIcon"
                className={seccionCalculadora ? 'iconEditCommon' : 'hidden'}
                src={editIcon}
                onClick={() => handleChangeButton()}
              />
            </CardTwo>
          </Col7>
        </Container>
      ) : null}
    </>
  );
};

export default CalFirstInvestmenteV2;
