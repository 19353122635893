import styled, { css } from 'styled-components';

export const ContainerSectionGeneral = styled.div`
display: ${(props) => props.dis || ' '};
background-color: ${(props) => props.bckC || ' '};
flex-direction:  ${(props) => props.fDir || ' '}; 
width:${(props) => props.wid || ' '};
padding:${(props) => props.padd || ' '};

`;

export const ContainerSection = styled.div`

display: ${(props) => props.dis || ' '};
background-color: ${(props) => props.bckC || ' '};
flex-direction:  ${(props) => props.fDir || ' '}; 
width:${(props) => props.wid || ' '};
height:${(props) => props.hei || ' '};
margin-left:${(props) => props.marL || ' '};
padding:${(props) => props.padd || ' '};
flex-wrap:${(props) => props.wrap || ' '};
margin-bottom:${(props) => props.marB || ' '};
margin-top:${(props) => props.marT || ' '};

`;

export const ContainerBox = styled.div`
display: ${(props) => props.dis || ' '};
background-color: ${(props) => props.bckC || ' '};
flex-direction:  ${(props) => props.fDir || ' '}; 
width:${(props) => props.wid || ' '};
height:${(props) => props.hei || ' '};
margin:${(props) => props.margin || ' '};
margin-left:${(props) => props.marL || ' '};
margin-right:${(props) => props.marR || ' '};
padding:${(props) => props.padd || ' '};
margin-top:${(props) => props.marT || ' '};
position: relative;
padding-left:${(props) => props.paddL || ' '};
`;

export const ContainerBoxScrollBar = styled.div`
display: ${(props) => props.dis || ' '};
background-color: ${(props) => props.bckC || ' '};
flex-direction:  ${(props) => props.fDir || ' '}; 
width:${(props) => props.wid || ' '};
height:${(props) => props.hei || ' '};
margin-left:${(props) => props.marL || ' '};
margin-right:${(props) => props.marR || ' '};
padding:${(props) => props.padd || ' '};
margin-top:${(props) => props.marT || ' '};

padding-left:${(props) => props.paddL || ' '};
justify-content: center;
align-items: center;
overflow-y: hidden;

/* &::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
  background-color: red;
    
}

&::-webkit-scrollbar {
    width: 9px;
  
}

 &::-webkit-scrollbar-thumb  {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
 border-radius: 3px;
 
  outline: 1px solid slategrey;
}
   */
`;

export const ContainerTicket = styled.div`
display: ${(props) => props.dis || ' '};
cursor: pointer;
background-color: ${(props) => props.bckC || ' '};
flex-direction:  ${(props) => props.fDir || ' '}; 
width:${(props) => props.wid || ' '};
height:${(props) => props.hei || ' '};
margin-left:${(props) => props.marL || ' '};
margin-right:${(props) => props.marR || ' '};
padding:${(props) => props.padd || ' '};
margin-top:${(props) => props.marT || ' '};
  position: relative;
padding-left:${(props) => props.paddL || ' '};
border: 1px solid #000000;
box-sizing: border-box;
justify-content: center;
align-items: center;
`;
export const Text = styled.p`
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 150%;
/* or 21px */

display: flex;
align-items: center;
letter-spacing: -0.2px;

color: #000000;
`;

export const FormSesiones = styled.form`
width: 100%;
display: flex;
`;

export const Subtitle = styled.h5`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  margin-bottom: ${(props) => props.marB || ' '};
  margin-left:${(props) => props.marL || ' '};
`;

export const Lista = styled.ul`
  display: ${(props) => props.dis || ' '};
  background-color: ${(props) => props.bckC || ' '};
  flex-direction:  ${(props) => props.fDir || ' '}; 
  width:${(props) => props.wid || ' '};
  height:${(props) => props.hei || ' '};
  margin-left:${(props) => props.marL || ' '};
  margin-right:${(props) => props.marR || ' '};
  padding:${(props) => props.padd || ' '};
  margin-top:${(props) => props.marT || ' '};
  list-style:none;
`;

export const ListaItem = styled.li`
  color:black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.20000000298023224px;
  margin-top:7px;
  margin-left:0px;
`;

export const TrData = styled.tr`
`;

export const TdData = styled.td`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
`;

export const TdDataUTM = styled.td`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  ${(props) => props.valido !== null && css`
    color: #0B81D5;
    cursor: pointer;
`}
`;

export const Buttons = styled.button`
  color: #fafafa;
  height: 60px;
  width: 234px;
  left: 294px;
  top: -981px;
  border-radius: 4px;
  margin-left:${(props) => props.marL || ' '};
  margin-top:${(props) => props.marT || ' '};
  background:${(props) => props.bcK || ' '};  
`;

export const ButtonsB = styled.button`
  height: 68px;
  width: 312px;
  height: 60px;
  color: #fafafa;
  background: #007BFF;
  border-radius: 4px;
  margin-left:30px;
  margin-top:10px;
  boder:none;
`;

export const ParrafoSuccess = styled.p`
  text-align: center;
  color: ${(props) => props.color || ' '};
  font-size: 28px;
  font-weight: 400;
  border-radius: 4px;
  margin-left:30px;
  margin-top:10px;
  boder:none;
`;
export const ErrorForm = styled.p`
  text-align: center;
  color: #CC1E1E;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  margin-left:30px;
  margin-top:10px;
  boder:none;
`;
export const RecuperarContraseña = styled.a`
  color: #3395FF !important; 
  text-decoration: none;
  boder:none;
  cursor: pointer;
`;
export const ContenedorEmail = styled.div`
  text-align: center;
`;

export const TextArea = styled.textarea`
  width: 330px;
  height: 90px;
  border: 1px solid #CDCDCD;
  boxSizing: border-box;
  borderRadius: 4px;
`;
