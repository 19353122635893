import React, { Fragment } from 'react';
import Chart from 'react-google-charts';
import LoadingWo from '../Training/loadingWo';

const LineChart = ({ data, legend, colors }) => {
  const tamScreen = window.screen.width;
  console.log('TAMANIO DE PANTALLA', tamScreen);
  return (
        <Fragment>
            <div style={{
              display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
            }}>
                <Chart
                    width={tamScreen > 1200 ? '550px' : '350px'}
                    height={tamScreen > 1200 ? '400px' : '300px'}
                    style={{
                      marginTop: '40px',
                    }}
                    chartType="LineChart"
                    loader={<LoadingWo />}
                    data={data}
                    showLegend={false}
                    options={{
                      legend: { position: 'none' },
                      series: colors,
                    }}
                />
                <div style={{
                  display: 'flex', marginTop: '100px', flexDirection: 'column', marginLeft: '-205px',
                }}>
                    {legend.map((item, index) => (
                        <Fragment key={index}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'start',
                              marginLeft: '20px',

                            }}>
                                <div style={{
                                  background: item.color, width: '25px', height: '25px', borderRadius: '4px', marginTop: '10px',
                                }}></div>
                                <p style={{ margin: '10px' }}>{item.instrument}</p>
                            </div>
                            {/* S&P 500 */}
                        </Fragment>
                    ))}
                </div>
            </div>
        </Fragment>
  );
};

export default LineChart;
