import React, {useState, useEffect} from 'react';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import { Container, Col7 } from '../common/common.styled';
import '../../assets/styles/Card1Animate.css';
import { CardsBtn } from '../../assets/styles/Training.styled';

const CardBold = ({
  classCard,
  classTop,
  text,
  text2,
  text3,
  text4,
  text5,
  text6,
  ClassTxt,
  hora,
  children,
  onClick = null,
  widthcontentw,
  classAna,
  logros = false,
  funct,
  btn,
  wo,
}) => {
  const [message1, setMessage1] = useState('');
  const [message2, setMessage2] = useState('');
  const [message3, setMessage3] = useState('');
  const [message4, setMessage4] = useState('');
  const [message5, setMessage5] = useState('');
  const [message6, setMessage6] = useState('');

  const textWithBold = (rawText) => {
    if(rawText.includes('*')){
      //identificamos donde aparece el primer y ultimo *
      let findex = rawText.indexOf("*");
      let lindex = rawText.lastIndexOf("*");
      //cortamos la parte del mensaje antes del primer *
      let startMessage = rawText.slice(0, findex).trim();
      //cortamos la parte entre * del mensaje 
      let boldMessage = rawText.slice(findex + 1, lindex).trim();
      //cortamos la parte despues del ultimo * en el mensaje
      let endMessage = rawText.slice(lindex + 1).trim();
      //escribimos el mismo mensaje con negritas 
      return <p>{startMessage} <b>{boldMessage}</b> {endMessage}</p>;
    }else {
    //si no contiene asterisco se conserva el mensaje original. 
    return <p>{text}</p>
  }
  }

  useEffect(() => {
    if (text)
      setMessage1(textWithBold(text));
    if(text2)
      setMessage2(textWithBold(text2));
    if(text3)
      setMessage3(textWithBold(text3));
    if(text4)
      setMessage4(textWithBold(text4));
    if(text5)
    setMessage5(textWithBold(text5));
    if(text6)
      setMessage6(textWithBold(text6));
  }, [text, text2, text3, text4, text5, text6]);

  return(
    <Container
    width={
      widthcontentw !== undefined ? widthcontentw : logros ? '100%' : '80%'
    }
    mt="0"
    widthMobile={logros ? '100%' : wo === 'SenNotifyVideo' ? '100%' : '90%'}
    mtMobile="0"
    displayweb={
      classCard === 'cardOne' || classCard === 'cardOne cardOneMargin'
        ? 'flex'
        : 'none'
    }
    displaymobile={classCard === 'cardOne' ? 'flex' : 'none'}
  >
    <ImageCoachInicio classAna={classAna} topMobile="27px" />

    <Col7
      maxwi="100%"
      flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
      maxwidth="100%"
      padding=" "
      className="cardPrincipal1"
    >
      <div className={`${classCard} ${classTop}`} onClick={onClick}>
        <div className={`${ClassTxt}`}>
          {message1 !== '' ? message1 : null}
          {message2 !== '' ? message2 : null}
          {message3 !== '' ? message3 : null}
          {message4 !== '' ? message4 : null}
          {message5 !== '' ? message5 : null}
          {message6 !== '' ? message6 : null}
          {children}
          {btn === true ? (
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={(e) => {
                funct(e);
              }}
            >
              Continuar
            </CardsBtn>
          ) : null}
        </div>
      </div>
    </Col7>
  </Container>
  )
};

export default CardBold;