import React, { useState, Fragment } from 'react';
import { Image } from '../common/common.styled';
import { filterFloat } from '../../Helpers';
import superexample from '../../assets/img/home/Clientes/NewClientes/super.svg';
import arrow from '../../assets/img/anacoach/Training/arr.svg';
import arrowUp from '../../assets/img/anacoach/Training/arrUp.svg';
import {
  TrainingDivSection,
  CardsTxt,
  CardsBtn,
} from '../../assets/styles/Training.styled';

const RecommendedPlans = ({
  logros = false, plan, issuance_fee, premium, sum_insured, refund = 0, abrirNuevoTab, url,
}) => {
  const [showMore, setShowMore] = useState(false);
  const styles = {
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '120%',
    marginLeft: '5px',
    color: 'rgba(97, 89, 89, 1)',
    marginBottom: '0',
  };
  const styles2 = {
    fontStyle: 'normal',
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '120%',
    marginLeft: '5px',
    color: 'rgba(97, 89, 89, 1)',
    marginBottom: '0',
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [maneraPago, setManeraPago] = useState('Mensual');

  return (
    <TrainingDivSection
      backw="#f8f9fa00"
      padding="0px"
      paddw="0px"
      mb="0"
      wi={logros ? '100%' : '90%'}
      wiw="100%"
      he="auto"
      hew="auto"
    >
      <TrainingDivSection
        mb="5px"
        mbw="5px"
        bsha="0px 2px 8px rgba(0,0,0,0.08)"
        bshaw="0px 2px 8px rgba(0,0,0,0.08)"
        back="#FFFFFF;"
        backw="#FFFFFF;"
        mlw={logros ? '10%' : '18%'}
        paddw="20px"
        wiw={logros ? '75%' : '60%'}
        padding="15px"
      >
        <Image src={superexample} width="115px" bottom="10px" bottomMObile="10px" marginRight="0" marginRightM="0" />
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '50%' }}>
            <CardsTxt className="roboto" size="12px">
              Suma asegurada
            </CardsTxt>
            <CardsTxt
              className="cabin"
              weight="600"
              size="18px"
              color="#F64282"
            >
              {formatter.format(filterFloat(sum_insured)) || '$500,000'}
            </CardsTxt>
          </div>
          <div style={{ width: '50%' }}>
            <CardsTxt className="roboto" size="12px">
              Pagando desde
            </CardsTxt>
            <CardsTxt className="cabin" weight="600" size="18px">
              {maneraPago === 'Mensual' || maneraPago === '' ? `${(formatter.format(filterFloat(premium.monthly))).split('.')[0]} al mes` : null}
              {maneraPago === 'Trimestral' ? `${(formatter.format(filterFloat(premium.quarterly))).split('.')[0]} cada 3 meses` : null}
              {maneraPago === 'Anual' ? `${(formatter.format(filterFloat(premium.annual))).split('.')[0]} al año` : null}
            </CardsTxt>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <CardsTxt className="roboto" size="16px" weight="600">
            ¿Cómo funciona?
          </CardsTxt>
          <CardsTxt className="roboto" size="16px" weight="400">
            {plan === 'premium'
              ? 'Después de 5 años te devolvemos tus primas pagadas menos algunos costos administrativos.'
              : 'Es un seguro de vida, en caso de cualquier accidente o deceso se paga la suma asegurada pactada con la aseguradora.'
            }
          </CardsTxt>
          <CardsBtn
            mtopmd="20px"
            mtop="20px"
            mtopw="20px"
            min_w="100%"
            min_web="100%"
            onClick={() => abrirNuevoTab(url)}
          >
            Contratar
          </CardsBtn>
        </div>

        <Fragment>
          {plan === 'premium' && (
            <CardsTxt className="roboto" size="14px" mt="20px">
              Te devuelve hasta{' '}
              <span style={{ color: '#F64282' }}> {formatter.format(filterFloat(refund)) || '$16,150.99'}</span>
            </CardsTxt>
          )}
          <TrainingDivSection
            mt="15px"
            hew="auto"
            paddw="0"
            mlw="0"
            mtw="15px"
            back=" "
            bshaw="none"
            bsha=""
            br="4px"
            padding="10px"
            wi="100%"
            he="auto"
            mb="20px"
            wiw="100%"
          >
            {plan === 'premium' ? (
              <>
                <p className="charSimulator" style={styles2}>
                  {'Devolución'}
                </p>
                <p className="charSimulator" style={styles}>
                  {formatter.format(filterFloat(refund))}
                </p>
                <p className="charSimulator" style={styles2}>
                  {'Gastos administrativos'}
                </p>
                <p className="charSimulator" style={styles}>
                  {formatter.format(filterFloat(issuance_fee))}
                </p>
              </>
            ) : (
              <>
                <p className="charSimulator" style={styles2}>
                  {'Gastos administrativos'}
                </p>
                <p className="charSimulator" style={styles}>
                  {formatter.format(filterFloat(issuance_fee))}
                </p>
              </>
            )}
          </TrainingDivSection>

          <CardsTxt className="roboto" mt="20px" size="14px" style={{ display: 'flex' }}>
            Pagando de manera:  {' '}
            <div style={{ marginLeft: '10px', fontSize: '14px' }} onClick={() => setShowMore(!showMore)}>
              <span style={{ color: '#F64282', fontWeight: '600' }}>
                {maneraPago}
                <Image className="cardImage-1" src={showMore ? arrowUp : arrow} width="12px" marginLeft="8px" marginLeftM="8px"></Image>
              </span>
            </div>
          </CardsTxt>
          <div
            onClick={() => setShowMore(!showMore)}
            style={{ marginTop: '20px', width: '100%' }}
            id="card-1"
            className={showMore ? '' : 'hidden'}
          >
            <div
              style={{
                color: '#F64282', fontSize: '14px', cursor: 'pointer', border: 'solid 1px #cdcdcd', padding: '3px', width: '30%', textAlign: 'center', margin: 'auto',
              }}
              onClick={() => setManeraPago('Mensual')}>
              Mensual
              </div>
            <div
              style={{
                color: '#F64282', fontSize: '14px', cursor: 'pointer', border: 'solid 1px #cdcdcd', padding: '3px', width: '30%', textAlign: 'center', margin: 'auto',
              }}
              onClick={() => setManeraPago('Trimestral')}>
              Trimestral
              </div>
            <div
              style={{
                color: '#F64282', fontSize: '14px', cursor: 'pointer', border: 'solid 1px #cdcdcd', padding: '3px', width: '30%', textAlign: 'center', margin: 'auto',
              }}
              onClick={() => setManeraPago('Anual')}>
              Anual
              </div>
          </div>
        </Fragment>
      </TrainingDivSection>
    </TrainingDivSection >
  );
};

export default RecommendedPlans;
