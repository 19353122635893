/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { Fragment, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Carrusel from "react-elastic-carousel";
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import {
  CardsBtn,
  TrainingSectionGray,
  TrainingDivSection2,
  DivStyle,
  DivGray,
  Resultado,
  ImageSections,
  DivFlex,
  Images,
  FreemiumButton,
  TrainingText
} from '../../assets/styles/Training.styled';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import { Container } from '../../components/common/common.styled';
// recovery
import {
  DYNAMIC_GET,
  CREATE_OPPORTUNITIES,
} from '../../mutations';
import FeaturesTraining from '../../components/Training/FeaturesTraining';
import CardOne from '../../components/Home/Card1Animate';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import star from '../../assets/img/anacoach/StarOn.png';
import ClickButton from '../../Helpers/HookHelpers';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';

const NoHistorySuccess = ({ creditHistoryCheck }) => {
  const anchop = window.screen.width;
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [results, setResults] = useState(false);
  const [resultsProducts, setResultsProducts] = useState([]);
  const { actions } = ClickButton();
  const [recomendationWO, setRecomendationWO] = useState([]);
  const historyResp = sessionStorage.getItem('noHistoryResp');

  const Stars = [
    {
      IDStar: 's1',
      Image: star,
    },
    {
      IDStar: 's2',
      Image: star,
    },
    {
      IDStar: 's3',
      Image: star,
    },
    {
      IDStar: 's4',
      Image: star,
    },
    {
      IDStar: 's5',
      Image: star,
    },
  ];

  const featuresArray = {
    elements: [
      {
        title: 'Solicita Vexi o Stori:',
        text: 'Su proceso de solicitud es sencillo, el tiempo de aprobación es corto y no tienen comisión por anualidad.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray2 = {
    elements: [
      {
        title: 'Utiliza tu tarjeta:',
        text: 'Recuerda usarla la mayor cantidad de veces posible sin llegar al límite de tu línea de crédito.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray3 = {
    elements: [
      {
        title: 'Realiza tus pagos:',
        text: 'Procura realizar el pago total de tu tarjeta para evitar generar intereses.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray4 = {
    elements: [
      {
        title: 'Acepta los incrementos:',
        text: 'Los incrementos en tu línea de crédito son sinónimo de confianza, acéptalos pero evita sobrecargar tu tarjeta.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray5 = {
    elements: [
      {
        title: '¿Otras tarjetas?',
        text: 'Tarjetas como Nu o Rappi ofrecen línea de crédito más grandes y otros beneficios que podrían convenirte.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray6 = {
    elements: [
      {
        title: 'Mayores beneficios, mayor responsabilidad:',
        text: 'Aunque en estas tarjetas la tasa de interés es baja, no te confies, recuerda las fechas de corte y pago para evitar pagar intereses y comisiones.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray7 = {
    elements: [
      {
        title: '¿Cómo va tu historial?',
        text: 'Tu historial debería tener una buena calificación ya que has demostrado que puedes utilizar tu crédito y mantenerlo bajo control.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray8 = {
    elements: [
      {
        title: 'BBVA y American Express',
        text: 'Ambas instituciones ofrecen diferentes productos con beneficios exclusivos.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const featuresArray9 = {
    elements: [
      {
        title: '¿En donde puedo conseguir estas tarjetas?',
        text: 'En Coru tenemos un entrenamiento específico que te ayudará a fortalecer tu músculo financiero.',
        color: '#917AFF',
      },
    ],
    description: '',
  };
  const [getRecomendationWO] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const recommendation = JSON.parse(dynamicGet.response);
        setRecomendationWO(recommendation);
      }
    },
  });

  const [getRecomendationProducts] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const products = [];
        const resultsCards = JSON.parse(dynamicGet.response);
        console.log(resultsCards);
        let idProductTmp = 0;
        for (const i in resultsCards) {
          if (resultsCards[i].IDProduct !== idProductTmp) {
            idProductTmp = resultsCards[i].IDProduct;
            products.push({
              idProduct: resultsCards[i].IDProduct,
              img: resultsCards[i].ImageURL,
              name: resultsCards[i].Name,
              clickOut: resultsCards[i].ClickOut,
              beneficios: [],
              cat: 0,
              tasa: 0,
            });
          }
        }
        for (const n in products) {
          for (const m in resultsCards) {
            if (resultsCards[m].IDProduct === products[n].idProduct) {
              if (resultsCards[m].beneficio.includes('Beneficio')) {
                products[n].beneficios.push({
                  name: resultsCards[m].beneficio,
                  description: resultsCards[m].Description,
                });
              } else if (resultsCards[m].beneficio === 'CAT') {
                products[n].cat = resultsCards[m].Description;
              } else if (resultsCards[m].beneficio === 'Tasa de Interés') {
                products[n].tasa = resultsCards[m].Description;
              }
            }
          }
        }
        console.log(products);
        setResults(true);
        setResultsProducts(products);
      }
    },
  });
  useEffect(() => {
    const getProductsInput = {
      input: {
        table: 'CatProductAvailability, CatProduct, CatProductDetail',
        columns: [
          'CatProductDetail.IDProduct, ImageURL, CatProductDetail.Name as beneficio, CatProduct.Name, CatProductDetail.Description, ClickOut',
        ],
        conditions: [
          {
            valone: 'ValueQuestionsAvailability',
            valtwo: historyResp,
            condition: '=',
          },
          {
            valone: 'CatProduct_IDProduct',
            valtwo: 'CatProduct.IDProduct',
            condition: '=',
            logic: 'AND',
            validColumn: true,
          },
          {
            valone: 'CatProduct.IDProduct',
            valtwo: 'CatProductDetail.IDProduct',
            condition: '=',
            logic: 'AND',
            validColumn: true,
          },
        ],
      },
    };
    getRecomendationProducts({ variables: getProductsInput });
    const getRecomendationInput = {
      input: {
        table:
          'CatTrainingRecomendations, CatAvailabilityRecommendations, CatTraining',
        columns: [
          'CatDetailTypeUsers_IDType, CatAvailabilityRecommendations.Condition, CatDetailTypeUsers_IDType_2, CatTraining.*',
        ],
        conditions: [
          {
            valone:
              'CatAvailabilityRecommendations_idCatAvailabilityRecommendations',
            condition: '=',
            valtwo: 'idCatAvailabilityRecommendations',
            validColumn: true,
          },
          {
            valone: 'CatTraining_IDCatTraining',
            condition: '=',
            valtwo: 'IDCatTraining',
            validColumn: true,
            logic: 'AND',
          },
          {
            valone: 'CatDetailTypeUsers_IDType',
            condition: '=',
            valtwo: '442',
            logic: 'AND',
          },
          {
            valone: 'Valuetotal',
            condition: '=',
            valtwo: historyResp,
            logic: 'AND',
          },
        ],
      },
    };
    getRecomendationWO({ variables: getRecomendationInput });
  }, [creditHistoryCheck]);
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
        let clickOut = sessionStorage.getItem('clickOutProduct');
        clickOut = clickOut.replace(
          '[idOpportunity]',
          response.idOpportunities
        );
        clickOut = clickOut.replace(
            '[IDUser]',
            sessionStorage.getItem('idUser'),
          );
        let win = window.open(
          clickOut,
          '_blank',
        )
        win.focus();
        debugger;
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const abrirNuevoTab = (product) => {
    const click = `ComenzarEntrenamiento_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('clickOutProduct', product.clickOut);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2,
        idProducts: [product.idProduct],
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '353',
        value: product.name,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    //const win = window.open(product.clickOut, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeSuccessNoHistory' });
        setReviewScroll(false);
      }
    }, 1000);
  }, []);
  const [recommendedTraining] = useState({});
  return (
    <>
      <div id="activeSuccessNoHistory"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
      />
      {creditHistoryCheck.includes('1. Solicita tu tarjeta garantizada') ? (
        <>
          <TrainingSectionGray back="#fafafa">
            <TrainingDivSection2
              wiw="670px"
              paddi="0"
              paddiw="0"
              mw="auto"
              back="#fafafa"
            >
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard="cardOne"
                classTop="topOne1"
                text={
                  'Estas tarjetas existen para crear o reparar el historial crediticio, por lo general, son pequeñas líneas de crédito que podrían incrementar con el tiempo.'
                }
              />
            </TrainingDivSection2>
          </TrainingSectionGray>
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray}
          />
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray2}
          />
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray3}
          />
        </>
      ) : creditHistoryCheck.includes('2. Aumenta tu Score Crediticio') ? (
        <>
          <TrainingSectionGray back="#fafafa">
            <TrainingDivSection2
              wiw="670px"
              paddi="0"
              paddiw="0"
              mw="auto"
              back="#fafafa"
            >
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard="cardOne"
                classTop="topOne1"
                text={
                  'Si todo va bien, es buen momento para pensar en obtener otros beneficios.'
                }
              />
            </TrainingDivSection2>
          </TrainingSectionGray>
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray4}
          />
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray5}
          />
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray6}
          />
        </>
      ) : creditHistoryCheck.includes('3. Consigue recompensas y beneficios') ? (
        <>
          <TrainingSectionGray back="#fafafa">
            <TrainingDivSection2
              wiw="670px"
              paddi="0"
              paddiw="0"
              mw="auto"
              back="#fafafa"
            >
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard="cardOne"
                classTop="topOne1"
                text={
                  'Existen tarjetas que se destacan por sus recompensas y beneficios, en este punto puedes hacer una solicitud y las probabilidades de que sea aprobada serán mayores.'
                }
              />
            </TrainingDivSection2>
          </TrainingSectionGray>
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray7}
          />
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray8}
          />
          <FeaturesTraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            Arraytext={featuresArray9}
          />
        </>
      ) : null}
      {results
        ? resultsProducts.map((card) => (
            <Resultado
              MinWidth="60%"
              widthLg="60%"
              marginweb="0 0 0 18%"
              key={card.IDProduct}
              minhweb="500px"
              minh="520px"
              marriweb="auto"
              marri="auto"
              border=" "
            >
              <ImageSections
                width="60%"
                heightweb="auto"
                src={card.img}
                className="img-fluid"
              />
              <br />
              <DivStyle size="15px" line="1.5em" padd="12px 0">
                {card.name}
              </DivStyle>
              <DivGray>
                <div>
                  <DivStyle>
                    <DivStyle bott="8px" weight="bold">
                      Características
                    </DivStyle>
                    <DivFlex justify="space-between">
                      <DivStyle weight="bold">CAT</DivStyle>
                      <div>{parseFloat(card.cat).toFixed(2) * 100}%</div>
                    </DivFlex>
                    <DivFlex justify="space-between">
                      <DivStyle weight="bold">Tasas de interés</DivStyle>
                      <div>{parseFloat(card.tasa).toFixed(2) * 100}%</div>
                    </DivFlex>
                  </DivStyle>
                  <br />
                  <DivFlex
                    justify="space-between"
                    wid="70%"
                    margin="auto"
                    margintop="20px"
                    margintoplg="20px"
                    widthweb="70%"
                    marweb="auto"
                  >
                    {Stars.map((imgstar, index) => (
                        <div key={`im${index}`}>
                          <Images
                            id={`star${index}`}
                            className="evalua2"
                            position="initial"
                            left="0"
                            width="32px"
                            height="32px"
                            src={imgstar.Image}
                          ></Images>
                        </div>
                    ))}
                  </DivFlex>
                  <br />
                  <DivStyle align="left" mh="203px">
                    <DivStyle weight="bold" align="left">
                      Beneficios
                    </DivStyle>
                    {card.beneficios.map((detalle) => <li>{detalle.description}</li>)}
                  </DivStyle>
                  <div className="center">
                    <CardsBtn min_w="100%" onClick={() => abrirNuevoTab(card)}>
                      Aplicar
                    </CardsBtn>
                  </div>
                </div>
              </DivGray>
            </Resultado>
        ))
        : null}
        <ModalVirality/>
      {recomendationWO.length > 0 ? (
        <Fragment>
          <Container
            mlMobile="auto"
            mRMobile="auto"
            mleftmxs="0%"
            mleft="18%"
            mleftm="7%"
            width="100%"
            widthMobile="100%"
            mtMobile="0px"
          >
            <Feed_Section padding="0% 0 5% 0" paddingm="0% 5% 5% 5%">
              <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                <Recommendations items={recomendationWO}></Recommendations>
              </ContentHomeSection>
            </Feed_Section>
          </Container>
        </Fragment>
      ) : null}
      <ModalAmazon show={true} wo={'SinHistorial'}></ModalAmazon>
      <div className="marginComentLgSuccess1">
        <Container
          mlMobile="auto"
          mRMobile="auto"
          mleftmxs="0%"
          mleft="18%"
          mleftm="7%"
          width="100%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
            <ContentHomeSection leftD="0%" floatD=" " width="80%;">
              <Recommendations items={recommendedTraining}></Recommendations>
         </ContentHomeSection>
       </Feed_Section>
        </Container>
      </div>
        <TrainingSectionGray back="#fafafa">
        <ProductsFremium
       idT={parseInt(sessionStorage.getItem('idTraining'))}
            />
      </TrainingSectionGray>
      <SectionShare WO="SinHistorial"></SectionShare>
    </>
  );
};

export default NoHistorySuccess;
