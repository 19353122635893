import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Carousel from 'react-elastic-carousel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import {
  ModalBackground,
  ModalBackCC,
  ModalHeader,
  ModalBody,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle24,
  Title1,
} from './Home.styled';
import {
  ImageSections,
  DivStyle,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import { Col12 } from './common.styled';
import ImageCoachInicio from '../Training/ImageCoachInicio';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 4,
    width: 25,
    height: 25,
    boxShadow: 'inset 0 0 0 2px #200E32, inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 23,
      height: 25,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"
        + " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 "
        + "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
});

const ModalVerCartera = ({
  selection,
  setModalState,
  modalState,
  applyCards,
}) => {
  const classes = useStyles();
  console.log('selection----', selection);
  const cards = selection;
  const [idProducts, setIdProducts] = useState([]);
  const [editWallet, setEditWallet] = useState(true);
  const name = sessionStorage.getItem('FirstName');
  const [form, setForm] = useState({});
  const [removeCards, setRemoveCards] = useState([]);
  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    const valueInt = parseInt(value);
    const wallet = [];
    const walletStorage = sessionStorage.getItem('walletArr');
    if (checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setIdProducts(idProducts.concat(value));

      if (removeCards.length > 1) {
        const tempC = [];
        removeCards.forEach((cId) => {
          if (cId !== valueInt) {
            tempC.push(valueInt);
          }
        });
        setRemoveCards(tempC);
      }
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });

      setRemoveCards(
        [...removeCards,
          valueInt],
      );

      const deleted = idProducts.indexOf(value);
      idProducts.splice(deleted, 1);
    }
  };
  useEffect(() => {
    cards.map((card) => {
      form[card.Name] = true;
      if (!idProducts.includes(String(card.IDProduct))) idProducts.push(String(card.IDProduct));
    });
  }, [cards]);

  const removeCardsOnClose = () => {
    const walletStorage = sessionStorage.getItem('walletArr');
    if (walletStorage) {
      const walletStorageJson = JSON.parse(walletStorage);

      const tempList = [];

      walletStorageJson.forEach((c) => {
        if (!removeCards.includes(c.IDProduct)) {
          tempList.push(c);
        }
      });

      sessionStorage.setItem('walletArr', JSON.stringify(tempList));
    }
  };

  return (
    <>
      {modalState && cards.length > 0 ? (
        <ModalBackground display={'true'}>
          <ModalBackCC
            height="97%"
            margin="10px auto"
            padding="20px"
            paddingM="15px"
          >
            <Rectangle23
              position="absolute"
              background="#91D770"
              Mleft="-30px"
              Mtop="-30px"
              width="60px"
              height="60px"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="90px"
              Mtop="-9px"
              width="28px"
              height="32px"
              transform="rotate(45deg)"
              left="58px"
              top="-27px"
            />
            <Rectangle23
              position="absolute"
              background="#6BD5AF"
              Mleft="200px"
              Mtop="-30px"
              width="30px"
              height="30px"
              transform="rotate(45deg)"
              left="171px"
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="280px"
              Mtop="40px"
              width="42.53px"
              height="45.22px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="-60px"
              Mtop="72px"
              width="60px"
              height="60px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle24
              positionD="absolute"
              radius="50px"
              width="145px"
              left="305px"
              top="118px"
              widthM="145px"
              heightM=""
              mLeftM=" "
              mTopM=" "
            />
            <div
              style={{
                position: 'absolute',
                right: '20px',
                top: '10px',
                fontSize: '18px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setModalState(false);
                setEditWallet(true);
                removeCardsOnClose();
              }}
            >
              X
            </div>
            <ModalHeader height="21%" heightM="22%">
              <ImageCoachInicio
                classAna={'trueAna'}
                topMobile="27px"
                modal={true}
                anamodal={true}
              />
              <Title1
                size="20px"
                aling="center"
                mb="10px"
                mt=" "
                sizeM="18px"
                mleftM=" "
                mtopM=" "
                heightM=" "
              >
                ¡Esta es tu cartera!
              </Title1>
            </ModalHeader>
            <ModalBody>
              <span style={{ padding: '20px 0' }}>
                Hola {name}, actualmente quieres aplicar a las siguientes
                tarjetas. ¿Es correcto?
              </span>
              <Col12
                id="CarruselEmpresasContentLogros"
                ptMobile='10px'
                pt="15px"
                flex="0 0 100%"
                maxwidth="100%"
                display="flex"
              >
                <Carousel
                  id="CarruselMetasLogros"
                  styled={{ background: '#FAFAFA' }}
                  transitionMs={1000}
                  enableAutoPlay
                  autoPlaySpeed={3000}
                  verticalMode
                  itemsToShow={3}
                >
                  {cards.map((card) => (
                    <div
                      key={card.IDProduct}
                      className="d-flex flex-column"
                      style={
                        card.Name.includes('RappiCard')
                          ? {
                            minWidth: '280px',
                            minHeight: '80px',
                            maxHeight: '90px',
                            padding: '5px',
                            margin: '5px',
                            boxShadow: '0px 2px 10px -3px rgb(0 0 0 / 20%)',
                          }
                          : {
                            minWidth: '280px',
                            minHeight: '70px',
                            maxHeight: '90px',
                            padding: '5px',
                            margin: '5px',
                            boxShadow: '0px 2px 10px -3px rgb(0 0 0 / 20%)',
                          }
                      }
                    >
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '60px',
                          }}
                        >
                          <ImageSections
                            pt={card.Name.includes('RappiCard') ? '0' : '8px'}
                            width="70px"
                            hmob="max-content"
                            ml="0"
                            heightweb="max-content"
                            src={card.ImageURL}
                            className="img-fluid"
                          />
                          <div
                            style={
                              card.Name.includes('RappiCard')
                                ? { textAlign: 'right', padding: '0 10px' }
                                : { textAlign: 'right', padding: '5px 12px' }
                            }
                          >
                            <DivStyle
                              className="cabin"
                              mh="60px"
                              mxh="60px"
                              weight="600"
                              align="left"
                              size="14px"
                              line="1.5em"
                              padd="5px 0"
                              color="#07210A"
                            >
                              {card.Name}
                            </DivStyle>
                          </div>
                          <Checkbox
                            defaultChecked
                            className={classes.root}
                            checked={form[card.Name]}
                            disabled={editWallet}
                            name={card.Name}
                            onChange={handleChange}
                            value={card.IDProduct}
                            disableRipple
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.checkedIcon,
                                  classes.icon,
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                            style={!editWallet ? {} : { opacity: '0.3' }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </Col12>
              <CardsBtn
                mtopmd="20px"
                mtop="20px"
                mtopw="20px"
                min_w="100%"
                min_web="100%"
                onClick={() => {
                  applyCards(idProducts);
                }}
              >
                Aplicar a estas tarjetas
              </CardsBtn>
              <CardsBtn
                color="#EC407A !important"
                back="#fff !important"
                border="solid 1px !important"
                mtopmd="20px"
                mtop="20px"
                mtopw="20px"
                min_w="100%"
                min_web="100%"
                onClick={() => setEditWallet(false)}
              >
                Editar mi cartera
              </CardsBtn>
            </ModalBody>
          </ModalBackCC>
        </ModalBackground>
      ) : null}
    </>
  );
};

export default ModalVerCartera;
