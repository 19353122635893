/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery} from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  CardsBtn,
  TrainingDivSection,
  TrainingQuestion,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import confetis from '../../assets/img/anacoach/Hero.png';
import { Image, Container, Col7 } from '../../components/common/common.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import Recommendations from '../../components/Training/RecommendedTraining';
import { ContentHomeSection, Title1 } from '../../components/common/Home.styled';

// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';

const styles = {
  paddingLeft: '10px',
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  letterSpacing: '-0.3px',
};

const NotificationSystem = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  handleNewtool,
  handleOpcTool,
}) => {

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length + contVideos + 1,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [recommendedTraining] = useState({});
  const [recomendaciones] = useState(false);
  useEffect(() => {
    console.log('Entro useEffect Notificaciones');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [notification, setNotification] = useState(
    sessionStorage.getItem('FormaDeAhorro') || '',
  );
  const [mediumNotification, setMediumNotification] = useState(
    sessionStorage.getItem('MedioNotificacion') || '',
  );
  const [recurrentNotification, setRecurrentNotification] = useState(
    sessionStorage.getItem('RecurrenciaNotificacion') || '',
  );
  const [form, setForm] = useState({});
  const backRutine = (e) => {
    const click = `RegresarNotificaciones_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setOrderRutine(6);
    handleOpcTool('');
    sessionStorage.removeItem('HerramientaDeAhorro');
    handleNewtool(true);
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        setNotification(val.value);
        break;
      case 1:
        setMediumNotification(val.value);
        break;
      case 2:
        setRecurrentNotification(val.value);
        break;
      default:
        console.log(val);
    }
  };
  const handleSubmit = (event) => {
    console.log('previniendo el enter');
    event.preventDefault();
  };

  useEffect(() => {
    if (
      recoveryRoutine === 'activa'
      || (sessionStorage.getItem('idUser') === '0'
        && sessionStorage.getItem('idCont') === '0')
    ) {
      setNotification(sessionStorage.getItem('TipoNotificacion') || '');
      setMediumNotification(sessionStorage.getItem('MedioNotificacion') || '');
      setRecurrentNotification(
        sessionStorage.getItem('RecurrenciaNotificacion') || '',
      );
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    let element;
    // let auxOther;
    if (notification === 'Recordatorio de metas mensuales') {
      element = document.getElementsByName(notification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName(
            'Recordatorio para llenar mi herramienta de control de ahorro',
          )[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName(
            'Tips y consejos sobre ahorros de emergencia',
          )[0];
          element.classList.remove('selectedBen');
        }
      }
    } else if (
      notification
      === 'Recordatorio para llenar mi herramienta de control de ahorro'
    ) {
      element = document.getElementsByName(notification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName(
            'Recordatorio de metas mensuales',
          )[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName(
            'Tips y consejos sobre ahorros de emergencia',
          )[0];
          element.classList.remove('selectedBen');
        }
      }
    } else if (notification === 'Tips y consejos sobre ahorros de emergencia') {
      element = document.getElementsByName(notification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName(
            'Recordatorio de metas mensuales',
          )[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName(
            'Recordatorio para llenar mi herramienta de control de ahorro',
          )[0];
          element.classList.remove('selectedBen');
        }
      }
    }
    // medio
    if (mediumNotification === 'WhatsApp') {
      element = document.getElementsByName(mediumNotification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName('Correo electronico')[0];
          element.classList.remove('selectedBen');
        }
      }
    } else {
      element = document.getElementsByName(mediumNotification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName('WhatsApp')[0];
          element.classList.remove('selectedBen');
        }
      }
    }
    // recurrent
    if (recurrentNotification === 'Dia') {
      element = document.getElementsByName(recurrentNotification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName('Semana')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Quincena')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Mes')[0];
          element.classList.remove('selectedBen');
        }
      }
    } else if (recurrentNotification === 'Semana') {
      element = document.getElementsByName(recurrentNotification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName('Dia')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Quincena')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Mes')[0];
          element.classList.remove('selectedBen');
        }
      }
    } else if (recurrentNotification === 'Quincena') {
      element = document.getElementsByName(recurrentNotification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName('Dia')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Semana')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Mes')[0];
          element.classList.remove('selectedBen');
        }
      }
    } else if (recurrentNotification === 'Mes') {
      element = document.getElementsByName(recurrentNotification)[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');

          element = document.getElementsByName('Dia')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Semana')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Quincena')[0];
          element.classList.remove('selectedBen');
        }
      }
    }
  }, [notification, mediumNotification, recurrentNotification]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#SistemaAlertas' });
    }, 3000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        {!loaderWait || orderRutine !== 8 ? (
          <>
            <div className="marginComentLgSuccess1">
              <TrainingDivSection
                backw="#f8f9fa00"
                padding="0px"
                paddw="0px"
                mb="0"
                wi="90%"
                wiw="100%"
                he="auto"
                hew="auto"
                bsha="null"
              >
                <TrainingQuestion>
                  <TrainingDivSection
                    back="transparent"
                    backw="transparent"
                    mlw="18%"
                    paddw="0px"
                    wiw="60%"
                    padding="0px"
                  >
                    <div id="SistemaAlertas"></div>
                    <div style={{ width: '100%' }}>
                      <Image
                        src={confetis}
                        height="238px"
                        marginLeftM="0px"
                        heightMobile="200px"
                        margin="0px"
                        top="110px"
                        left="0%"
                        mleft="0%"
                        marginLeft="0%"
                        mtop="75px"
                      />
                      <Title1
                        style={{ fontStyle: 'normal' }}
                        backgroundM="transparent"
                        pbottom="6%"
                        background="#FAFAFA"
                        position="sticky"
                        positionM="absolute"
                        mleft="auto"
                        mright="auto"
                        heightM="auto"
                        aling="center"
                        className="roboto cabin"
                        mb="30%"
                        mbM="0px"
                        left="0%"
                        size="35px"
                        mtopM="-20%"
                        mt="-147px"
                        mleftM="0px"
                        sizeM="20px"
                        widthM="100%"
                        width="68%"
                      >
                        Sistema de alertas
                      </Title1>
                    </div>
                    <TrainingDivSection
                      bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                      mt="0%"
                      mtw="-20%"
                      br="4px"
                      padding="15px"
                      wi="auto"
                      wiw="auto"
                      he="auto"
                      mb="0px"
                    >
                      <form
                        onSubmit={(e) => handleSubmit(e)}
                        className="wo-input"
                        style={{ marginTop: '0px' }}
                      >
                        <label>
                          Selecciona las notificaciones que quieres recibir
                        </label>
                        <br />
                        <label style={styles}>
                          <b>Tipo de notificación</b>
                        </label>
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="0-0"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                          otherDesignCat={true}
                          typeSpecial="radio"
                          valueGrup={notification}
                        />
                        <br />
                        <label style={styles}>
                          <b>Medio de recepción de notificaciones</b>
                        </label>
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="1-1"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                          otherDesignCat={true}
                          typeSpecial="radio"
                          valueGrup={mediumNotification}
                        />
                        <br />
                        <label style={styles}>
                          <b>Tiempo de recepción de notificaciones</b>
                        </label>
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="2-2"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                          otherDesignCat={true}
                          typeSpecial="radio"
                          valueGrup={recurrentNotification}
                        />
                      </form>
                    </TrainingDivSection>
                    <br />
                    <div>
                      <CardsBtn min_w="100%" min_web="100%">
                        <b>Recibir notificaciones</b>
                      </CardsBtn>
                      <CardsBtn
                        min_w="100%"
                        min_web="100%"
                        border="1px solid #F64282"
                        back="#fff"
                        color="#F64282"
                        onClick={(e) => backRutine(e)}
                      >
                        <b>Regresar</b>
                      </CardsBtn>
                    </div>
                  </TrainingDivSection>
                </TrainingQuestion>
              </TrainingDivSection>
            </div>
          </>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        )}
          {/* Enso Klu Fremium  */}
            <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '21' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
        <ModalAmazon show={true} wo={'Emergency'}></ModalAmazon>
        {recomendaciones ? (
          <div className="marginComentLgSuccess1">
            <Container
              mlMobile="auto"
              mRMobile="auto"
              mleftmxs="0%"
              mleft="18%"
              mleftm="7%"
              width="100%"
              widthMobile="100%"
              mtMobile="0px"
            >
              <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                  <Recommendations
                    items={recommendedTraining}
                  ></Recommendations>
                </ContentHomeSection>
              </Feed_Section>
            </Container>
          </div>
        ) : null}
        <ModalVirality />
        <SectionShare WO="Emergency"></SectionShare>
      </div>
  );
};

export default NotificationSystem;
