import React, { Fragment } from 'react';
import { Col7, Container } from '../common/common.styled';
import ImageCoachInicio from './ImageCoachInicio';
import {
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  CardsBtn,
} from '../../assets/styles/Training.styled';

function Starttraining({
  title, text, text2, text3, text4, classAna, button = false, txtButton, onclick, hiddeAna, colorb='#F3F3F3', children
}) {
  const content = () => (
            <TrainingDivSection
                hew="auto"
                paddw="26px"
                ml={hiddeAna ? '10%' : '0'}
                mlw={hiddeAna ? '2%' : '0'}
                mlM="0%"
                wiw="100%"
                back="#F3F3F3"
                backw={colorb}
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"
                mb="0px"
                className="topOne1Init"
                mbw="0px"
            >
            {title != null
              ? <TrainingSubtitles size="20px" sizew="20px" fw="600" align="left" alignweb="left" className="cabin">
                    {title}
                </TrainingSubtitles>
              : null}
            {text != null
              ? <TrainingText bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                    {text}
                </TrainingText>
              : null}
            {text2 != null
              ? <TrainingText bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                    {text2}
                </TrainingText>
              : null}
            {text3 != null
              ? <TrainingText bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                    {text3}
                </TrainingText>
              : null}
            {text4 != null
              ? <TrainingText bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                    {text4}
                </TrainingText>
              : null}
            {button ? (
                <CardsBtn
                onClick={() => onclick()}
                min_w="95%"
                min_web="100%"
                mtopw="20px"
                mleft="10px"
                mtop="15px"
                weight="normal!important"
                >
                    {txtButton}
                </CardsBtn>
            ) : null}
                 
             <TrainingText bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                   {children}
             </TrainingText>
              
            </TrainingDivSection>
  );

  return (
    <Fragment>
        <Container width={hiddeAna ? '67%' : '80%'} mt="0px" widthMobile={hiddeAna ? '100%' : '90%'} mtMobile="0" displayweb="flex">
            {hiddeAna ? '' : <ImageCoachInicio classAna = {classAna} topMobile="27px"/>}
            {hiddeAna ? (
                <Col7 maxwi="100%" flex="0 0 90%" maxwidth="100%" padding=" " className="cardPrincipal1"
                mlM="5%"
                >
                    {content()}  
                </Col7>
            ) : (
                <Col7 maxwi="100%" flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'} maxwidth="100%" padding=" " className="cardPrincipal1">
                    {content()} 
                </Col7>
            )}
        </Container>
    </Fragment>
  );
}

export default Starttraining;
