/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import editIcon from '../../assets/img/anacoach/editar1.png';
import '../../assets/styles/Home.css';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingText,
  TrainingDivSection,
  CardsBtn,
  ErrorDiv,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, getQueryVariableHashtagDeeplink } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
import Aceptterms from '../../components/common/Acceptterms';

const DatBasic = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  mainDataRoutine = 0,
  trainingColor,
  recoveryTimeout = false,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [alertaFecha] = useState(false);
  const [iduser] = useState(sessionStorage.getItem('idUser') || '');
  const [idcont] = useState(sessionStorage.getItem('idCont') || '');
  const [overwrite] = useState(sessionStorage.getItem('overwrite_WO_29') || 'true');
  const [recoveryDatosPrincipales, setRecoveryDatosPrincipales] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryDatosPrincipales('inactiva');
        } else {
          const routines = json; // Object.values(json);
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else if (
                  arraySessionsRutines[idTypeQuestion] === 'FirstName'
                ) {
                  if (!(responseUser.indexOf('name temporal') !== -1)) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }

            if (
              arrayResponseRutine.length
                < routines[idRoutine].questions.length
              && !stopRoutine
            ) {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      iduser !== '' &&
      idcont !== '' && 
      overwrite !== 'true'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryDatosPrincipales('inactiva');
    }
    const {
      idUser,
      idCont,
    } = getQueryVariableHashtagDeeplink('profile');
    if (idUser && idCont) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(idUser),
          idCont: String(idCont),
        },
      });
    }
  }, []);
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [completeFullName, setCompleteFullName] = useState(
    sessionStorage.getItem('completeFullName') || '',
  );
  const [form, setForm] = useState({
    FirstName:
      sessionStorage.getItem('FirstName') === 'name temporal'
      || sessionStorage.getItem('FirstName') === null
        ? ''
        : sessionStorage.getItem('FirstName'),
    MiddleName: sessionStorage.getItem('MiddleName') || '',
    FathersLastName: sessionStorage.getItem('FathersLastName') || '',
    MothersLastName: sessionStorage.getItem('MothersLastName') || '',
  });
  const [error] = useState({
    FirstName: false,
    MiddleName: false,
    FathersLastName: false,
    MothersLastName: false,
  });
  const [texterror] = useState({
    FirstName: '',
    MiddleName: '',
    FathersLastName: '',
    MothersLastName: '',
  });

  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementsByName(nombre)[0];
    if (element === null || element === undefined) {
      // console.log('null');
    } else if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'MiddleName') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
  };

  const ValidLetrasName = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementsByName(nombre)[0];
    if (element === null) {
      // console.log('null');
    } else {
      if (valor.length > 2) {
        element.className = 'textInput inputs-bottom validInput';
        error[`${nombre}valid`] = 0;
        error[nombre] = false;
        texterror[nombre] = '';
      } else if (nombre === 'MiddleName') {
        error[nombre] = false;
        texterror[nombre] = '';
        error[`${nombre}valid`] = -1;
      } else {
        element.className = 'textInput inputs-bottom invalidInput';
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = 'Debe ingresar más de 3 caracteres';
      }
    }
    // eBtn();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      const path = window.location.pathname;
      if (path.includes('funnel-prestamos')) {
        if (
          document.getElementById('year') !== null
          && document.getElementById('year') !== undefined
        ) {
          document.getElementById('year').className = 'textInput inputs-bottom validSelect';
        }
        if (
          document.getElementById('day') !== null
          && document.getElementById('day') !== undefined
          && form.day !== ''
        ) {
          document.getElementById('day').className = 'textInput inputs-bottom validSelect';
        }
        if (
          document.getElementById('month') !== null
          && document.getElementById('month') !== undefined
          && form.month !== ''
        ) {
          document.getElementById('month').className = 'textInput inputs-bottom validSelect';
        }
      } else if (path.includes('obtener-primer-tarjeta')) {
        if (
          document.querySelectorAll('#year')[1] !== null
          && document.querySelectorAll('#year')[1] !== undefined
        ) {
          document.querySelectorAll('#year')[1].className = 'textInput inputs-bottom validSelect';
        }
        if (
          document.querySelectorAll('#day')[1] !== null
          && document.querySelectorAll('#day')[1] !== undefined
          && form.day !== ''
        ) {
          document.querySelectorAll('#day')[1].className = 'textInput inputs-bottom validSelect';
        }
        if (
          document.querySelectorAll('#month')[1] !== null
          && document.querySelectorAll('#month')[1] !== undefined
          && form.month !== ''
        ) {
          document.querySelectorAll('#month')[1].className = 'textInput inputs-bottom validSelect';
        }
      } else if (path.includes('comparar-tarjeta')) {
        if (
          document.querySelectorAll('#year')[1] !== null
          && document.querySelectorAll('#year')[1] !== undefined
        ) {
          document.querySelectorAll('#year')[1].className = 'textInput inputs-bottom validSelect';
        }
        if (
          document.querySelectorAll('#day')[1] !== null
          && document.querySelectorAll('#day')[1] !== undefined
          && form.day !== ''
        ) {
          document.querySelectorAll('#day')[1].className = 'textInput inputs-bottom validSelect';
        }
        if (
          document.querySelectorAll('#month')[1] !== null
          && document.querySelectorAll('#month')[1] !== undefined
          && form.month !== ''
        ) {
          document.querySelectorAll('#month')[1].className = 'textInput inputs-bottom validSelect';
        }
      } else {
        if (
          document.getElementById('year') !== null
          && document.getElementById('year') !== undefined
        ) {
          document.getElementById('year').className = 'textInput inputs-bottom validSelect';
        }
      }

      if (
        sessionStorage.getItem('FirstName') !== null
        && sessionStorage.getItem('FirstName') !== ''
      ) {
        ValidLetras(
          'FirstName',
          sessionStorage.getItem('FirstName'),
          'Primer Nombre',
        );
      }
      if (
        sessionStorage.getItem('FathersLastName') !== null
        && sessionStorage.getItem('FathersLastName') !== ''
      ) {
        ValidLetras(
          'FathersLastName',
          sessionStorage.getItem('FathersLastName'),
          'Apellido Paterno',
        );
      }
      if (
        sessionStorage.getItem('middleName') !== null
        && sessionStorage.getItem('middleName') !== ''
      ) {
        ValidLetras(
          'MiddleName',
          sessionStorage.getItem('middleName'),
          'Segundo Nombre',
        );
      }
      if (
        sessionStorage.getItem('MothersLastName') !== null
        && sessionStorage.getItem('MothersLastName') !== ''
      ) {
        ValidLetras(
          'MothersLastName',
          sessionStorage.getItem('MothersLastName'),
          'Apellido Materno',
        );
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (recoveryDatosPrincipales === 'activa') {
      let firstName = sessionStorage.getItem('FirstName') || '';
      if (!(firstName.indexOf('name temporal') !== -1)) {
        firstName = sessionStorage.getItem('FirstName');
      }
      const middleName = sessionStorage.getItem('MiddleName') || '';
      const fathersLastName = sessionStorage.getItem('FathersLastName') || '';
      const motherLastName = sessionStorage.getItem('MothersLastName') || '';
      setForm(
        Object.assign(form, {
          FirstName: firstName,
          MiddleName: middleName,
          FathersLastName: fathersLastName,
          MothersLastName: motherLastName,
        }),
      );

      if (
        firstName !== ''
        && fathersLastName !== ''
        && motherLastName !== ''
        && !recoveryTimeout
      ) {
        sessionStorage.setItem('completeFullname', true);
        setCompleteFullName(true);
        if (orderRutine < nextOrderRoutine) {
          setOrderRutine(nextOrderRoutine);
        }
        setSeccionNombre(true);
      }
    }
  }, [recoveryDatosPrincipales]);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleRoutineOrder = (step, prod = '', cantidad = '', tel = '') => {
    // console.log('handleRoutineOrder', step, prod, cantidad, tel);
  };
  const handleChangeCatOption = (val) => {
    handleRoutineOrder(currentRoutine);
  };
  // manejador de cambios para openfield
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case 'FirstName':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
      case 'MiddleName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      case 'FathersLastName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      case 'MothersLastName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };
  const handleChangeNombre = (e) => {
    e.preventDefault();
    if (
      form.FirstName !== ''
      && form.FathersLastName !== ''
      && form.MothersLastName !== ''
    ) {
      if (questions.length === 0) {
        questions.push(
          {
            id: 'FirstName',
            question: 1,
            value: form.FirstName,
          },
          {
            id: 'FathersLastName',
            question: 3,
            value: form.FathersLastName,
          },
          {
            id: 'MothersLastName',
            question: 4,
            value: form.MothersLastName,
          },
        );
        if (form.MiddleName !== '') {
          questions.push({
            id: 'MiddleName',
            question: 2,
            value: form.MiddleName,
          });
        }
      }
      if (sessionStorage.getItem('idTraining') === '35') {
        const clickEventCC = `Nombre_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
        actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
      }
      const click = `ContinuarNombre_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      if (
        sessionStorage.getItem('idTraining') === '23'
        || sessionStorage.getItem('idTraining') === '34'
        || sessionStorage.getItem('idTraining') === '26'
        || sessionStorage.getItem('idTraining') === '36'
        || sessionStorage.getItem('idTraining') === '35'
        || sessionStorage.getItem('idTraining') === '42'
      ) {
        sessionStorage.setItem('Rutine_DatBasic', JSON.stringify(Rutine));
        sessionStorage.setItem('questions_DatBasic', JSON.stringify(questions));
        setQuestions([]);
      } else {
        createJsonInput(Rutine, questions);
        setQuestions([]);
      }
      sessionStorage.setItem('completeFullname', true);
      setCompleteFullName(true);
      if (orderRutine < nextOrderRoutine) {
        setOrderRutine(nextOrderRoutine);
      }
      setSeccionNombre(true);
      sessionStorage.setItem('FirstName', form.FirstName);
    } else {
      setSeccionNombre(false);
    }
  };

  const [seccionNombre, setSeccionNombre] = useState(false);
  const handleChangeButton = () => {
    setOrderRutine(1);
    setSeccionNombre(false);
  };

  const anchop = window.screen.width;
  const [isData] = useState(sessionStorage.getItem('DataPerson') === 'true');

  useEffect(() => {
    if (isData) {
      sessionStorage.setItem('RecoveryA', true);
      if (orderRutine < nextOrderRoutine) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, []);

  if (
    recoveryDatosPrincipales === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return !isData ? (
    <div>
      <form>
        <Fragment>
          <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
            <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
              <TrainingDivSection
                hew="auto"
                className="topOne1 topOne1Init"
                paddw="26px"
                mlw="0%"
                mtw="8%"
                wiw="100%"
                back="#F3F3F3"
                backw="#F3F3F3"
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"
              >
                <TrainingText
                  bot="0px"
                  className="cabin"
                  align="left"
                  weight="600"
                  size="20px"
                  sizew="20px"
                >
                  ¿Cuál es tu nombre?
                </TrainingText>
                {!seccionNombre ? (
                  <Fragment>
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      currentTraining={currentTraining}
                      step="0-3"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                      endQuestion={7}
                      activeValidateRutine={true}
                      inputError={error}
                      texterror={texterror}
                    />
                    <ErrorDiv className={alertaFecha === true ? '' : 'hidden'}>
                      Parece que no has llenado tu fecha de nacimiento
                    </ErrorDiv>
                    <CardsBtn
                      id="SeccionNombre"
                      type="button"
                      min_w="100%"
                      min_web="100%"
                      mtopw="10px"
                      onClick={(e) => handleChangeNombre(e)}
                    >
                      Continuar
                    </CardsBtn>
                    <Aceptterms />
                  </Fragment>
                ) : null}
              </TrainingDivSection>
            </Col7>
          </Container>
        </Fragment>
        <br />
      </form>
      <div id="ActivoBirthDat"></div>
      <CardTwo
        classCard={
          completeFullName !== ''
          && (seccionNombre || orderRutine !== currentRoutine)
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        textT={''}
        text2={`${form.FirstName} ${form.MiddleName} ${form.FathersLastName} ${form.MothersLastName}`}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            completeFullName !== ''
            && (seccionNombre || orderRutine !== currentRoutine)
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeButton()}
        />
      </CardTwo>
    </div>
  ) : null;
};

export default DatBasic;
