import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CardOne from '../../components/Home/Card1Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import confetis from '../../assets/img/anacoach/Hero.png';
import { filterFloat } from '../../Helpers';
// recovery
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import {
  INSERT_HOMEWORKS_CHILDREN,
  DYNAMIC_UPDATE,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  TrainingSectionGray,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import { Container, Col7, Image } from '../../components/common/common.styled';
import { Title1 } from '../../components/common/Home.styled';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationValueOfMoney from '../../components/common/SendNotificationValueOfMoney';
import ModalVirality from '../../components/common/ModalVirality';

const styles = {
  paddingLeft: '10px',
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  display: 'flex',
  justifyContent: 'center',
};

const Homeworks = ({ trainingColor, createJsonInput }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const { sendMessage } = SendNotificationValueOfMoney();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [homeworksList] = useState([]);
  const [showNumber, setShowNumber] = useState(false);
  const [sent, setSent] = useState(false);
  const [goFeed] = useState(false);
  const anchop = window.screen.width;
  useEffect(() => {
    if (showNumber) {
      const click = `ConfirmarNotificaciones_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [showNumber]);
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertHomeworkSchedule] = useMutation(INSERT_HOMEWORKS_CHILDREN, {
    onCompleted({ insertHomeworkSchedule }) {
      if (insertHomeworkSchedule.message === 'success') {
        console.log('IDCATUSERSCHILDREN === ', insertHomeworkSchedule.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log(dynamicUpdate);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const saveHomeworksChildren = (e) => {
    const click = `ConfirmarTareas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const input = {
      input: {
        idUser: sessionStorage.getItem('idUser'),
        sonData: [
          {
            name: sessionStorage.getItem('nombreHijo'),
            age: sessionStorage.getItem('EdadHijo'),
            cellPhone: sessionStorage.getItem('telefonoHijo'),
          },
        ],
        homeworks: [
          {
            nameHw: sessionStorage.getItem('EleccionTareas'),
            time: sessionStorage.getItem('HorarioTareas'),
            reward: sessionStorage.getItem('RecompensaTarea'),
            day: sessionStorage.getItem('DiasTareas'),
          },
        ],
      },
    };
    if (sessionStorage.getItem('insertSuccess') !== 'true') {
      insertHomeworkSchedule({ variables: input });
    }
    sessionStorage.setItem('insertSuccess', 'true');
    setShowNumber(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeChildrenInfo' });
  };

  useEffect(() => {
    let hw = sessionStorage.getItem('EleccionTareas');

    hw = hw.split(',').map((item) => {
      return item;
    });
    const days = sessionStorage.getItem('DiasTareas');
    const horario = sessionStorage.getItem('HorarioTareas');
    const reward = sessionStorage.getItem('RecompensaTarea');
    for (let i = 0; i < hw.length; i++) {
      homeworksList.push({
        hw: hw[i],
        days,
        horario,
        reward,
      });
    }
    setRecoveryRecoveryRoutine('1');
  }, []);

  const history = useHistory();
  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  useEffect(() => {
    if (sent) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [sent]);

  const [form, setForm] = useState({
    cel: sessionStorage.getItem('telefonoHijo') || '',
  });

  const handleChange = (e, question = '') => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const sendHomework = (e) => {
    const click = `EnviarTareas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const questions = [];
    questions.push({
      id: 'telefonoHijo',
      question: 3,
      value: form.cel,
    });
    const Rutine = JSON.parse(sessionStorage.getItem('auxRutine'));
    if (Rutine !== undefined && Rutine !== null) { createJsonInput(Rutine, questions); }
    const inputUpdate = {
      input: {
        table: 'CatUsersChildren',
        columns: [
          {
            setkey: 'CellPhone',
            setval: form.cel,
          },
        ],
        conditions: [
          {
            valone: 'CatUsers_IdUser',
            valtwo: String(sessionStorage.getItem('idUser')),
            condition: '=',
            logic: '',
          },
        ],
      },
    };
    dynamicUpdate({ variables: inputUpdate });
    let url = window.location.href;
    url += `?idUser=${sessionStorage.getItem('idUser')}`;
    sendMessage({
      url,
      cellPhone: form.cel,
      trigger: 'enviar_tareas_hijo',
      variables: {
        varNameChildren: 'Nombre_Hijo',
        varFatherName: '',
        varUrlWO: 'URL_WO',
      },
      nameChildren: sessionStorage.getItem('nombreHijo'),
    });
    setSent(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeSendSuccess' });
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeHomeworks' });
        setReviewScroll(false);
      }
    }, 1400);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeHomeworks"></div>
        <CardOne classCard="cardOne" classTop="topOne1" text="Continuemos" />
        <CardOne
          classCard="cardOne"
          classTop="topOne1"
          text="¡Excelente!"
          text2="Confirma las tareas y recompensas para compartirla con tu hijo (a)."
        />
        <TrainingQuestion
          id="SituacionActual"
          style={{ marginBottom: '35px' }}
        >
          <TrainingDivSection
            paddw = '0px'
            wixs='100%'
            wi='100%'
            back="transparent"
            backw="transparent"
            bsha=""
            mt="44px"
            mtw="40px"
            br="4px"
            mb="-90px"
            padding='0px'
          >
            <TrainingDivSection
            wixs='100%'
            back="transparent"
            backw="transparent"
            bsha=""
            mt="44px"
            mtw="40px"
            br="4px"
            padding="21px 20px"
            wi="100%"
            wiw="58%"
            mlw='18%'
            mb="-90px"
            heSm='1000px'
          >
            <div style={{ width: '115%', marginLeft: '-20px' }}>

              <Image
                src={confetis}
                height="238px"
                marginLeftM="0px"
                heightMobile="200px"
                mwidth="100%"
                margin="auto"
                top="110px"
                left="0%"
                mleft="0%"
                marginLeft="0%"
                mtop="75px"
              />
              <Title1
                style={{ fontStyle: 'normal' }}
                backgroundM="transparent"
                pbottom="6%"
                background="#FAFAFA"
                position="sticky"
                positionM="absolute"
                mleft="auto"
                mright="auto"
                heightM="auto"
                aling="center"
                className="roboto cabin"
                mb="175px"
                mbM="0px"
                left="0%"
                size="45px"
                mtopM="-35%"
                mt="-147px"
                mleftM="0px"
                sizeM="30px"
                widthM="100%"
                width="68%"
              >
                ¡Tareas a realizar:
              </Title1>
            </div>

            {homeworksList.map((item, index) => {
              if (index < 1) {
                let day = item.days.replaceAll(' ', '');
                day = day.replaceAll(',', ', ');
                console.log('day == ', day);
                return (
                  <Fragment key={index}>
                    <label style={styles}>
                      <b>{sessionStorage.getItem('EleccionTareas')}</b>
                    </label>
                    <label
                      style={{
                        display: 'flex',
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: 'black',
                        fontSize: '18px',
                        fontFamily: 'sans-serif',
                      }}
                    >
                      Días:
                    </label>
                    <TextField
                      variant="outlined"
                      id={index}
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        className: item.days ? 'validInputSaving' : '',
                      }}
                      value={day}
                      disabled={true}
                    />
                    <label
                      style={{
                        display: 'flex',
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: 'black',
                        fontSize: '18px',
                        fontFamily: 'sans-serif',
                      }}
                    >
                      Horario:
                    </label>
                    <TextField
                      variant="outlined"
                      id={index}
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        className: item.horario ? 'validInputSaving' : '',
                      }}
                      value={item.horario}
                      disabled={true}
                    />
                    <label
                      style={{
                        display: 'flex',
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: 'black',
                        fontSize: '18px',
                        fontFamily: 'sans-serif',
                      }}
                    >
                      Recompensa:
                    </label>
                    <TextField
                      variant="outlined"
                      id={index}
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        className: item.reward ? 'validInputSaving' : '',
                      }}
                      value={formatter.format(filterFloat(item.reward))}
                      disabled={true}
                    />
                  </Fragment>
                );
              }
            })}
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={(e) => saveHomeworksChildren(e)}
            >
              Confirmar tareas
            </CardsBtn>
          </TrainingDivSection>
            <div id="activeChildrenInfo"></div>

            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showNumber ? 'cardOne' : 'hidden'}
              classTop="topOne1"
              text="¡Ya casi terminamos!"
              text2="Ingresa el teléfono celular de tu hijo(a) para enviar por WhatsApp las tareas y recompensas asignadas:"
            />
            {showNumber && !sent ? (
              <Fragment>
                <TrainingSectionGray
                wiw='60%'
                mn='25px 0 25px 5%'
                widthn ='90%'
                mw='25px 0 0 18%'
                padding = '3%'
                paddinglG='3%'
                >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ fontSize: '18px' }}>
                    Télefono celular de tu hijo(a)
                  </p>
                </div>
                <TrainingQuestion>
                  <QuestionsFlex display="inline">
                    <TextField
                      variant="outlined"
                      id="homework2"
                      name="cel"
                      autoComplete="off"
                      placeholder="Ingresa número celular"
                      required
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      value={form.cel}
                      onChange={handleChange}
                    />
                  </QuestionsFlex>
                </TrainingQuestion>
                <div style={{ display: 'flex', width: '100%' }}>
                  <CardsBtn
                    onClick={(e) => sendHomework(e)}
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw = '0px'
                    mtop = '0px'
                  >
                    Enviar tareas
                  </CardsBtn>
                </div>
              </TrainingSectionGray>
              </Fragment>
            ) : null}
            <div id="activeSendSuccess"></div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={sent ? 'cardOne' : 'hidden'}
              classTop="topOne1"
              text="Continuemos"
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={sent ? 'cardOne' : 'hidden'}
              classTop="topOne1"
              textbold="¡Listo, se han enviado exitosamente las tareas a tu hijo(a)!"
              text="Estoy segura de que tu hija o hijo se divertirá con esta actividad y, lo más importante es que descubrirán el valor del dinero."
            />

            {sent ? (
              <Fragment>
                <ModalAmazon show={true} wo={'ValorDinero'}></ModalAmazon>
                <SectionShare WO="ValorDinero"></SectionShare>
                <ModalVirality />
              </Fragment>
            ) : null}
          </TrainingDivSection>
        </TrainingQuestion>
      </div>
  );
};

export default Homeworks;
