import React, { useState } from 'react';
import ShareIcon from '@material-ui/icons/Share';
import Modal from '@material-ui/core/Modal';
import MaximizeIcon from '@material-ui/icons/Maximize';
import { Feed_Text } from '../../assets/styles/Feed.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import face from '../../assets/img/anacoach/facebook1.png';
import twit from '../../assets/img/anacoach/messenger2.svg';
import whats from '../../assets/img/anacoach/whats.png';
import { ParagrapTwo, ButtonSecon } from './common.styled';
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
} from './Home.styled';

const ModalShareLP = ({
  redirectLink,
  textShare,
  trainingName = '',
  mtop,
  buttonType,
}) => {
  const [openModal, setopenModal] = useState(false);
  const [widthBrowser] = useState(window.outerWidth);

  function shareCoru(socialNetwork) {
    let host = ''; // "https://coru.com";
    let auxHost = ''; // "https://coru.com";
    let plantillasHTML = '';
    let announcement = '';
    if (redirectLink === '/lp/get-card') {
      announcement = socialNetwork === 'Whatsapp'
        ? 'LPgetCardSuccessWa'
        : 'LPgetCardSuccess';
    }

    const url = window.location.host;
    if (url === 'coru.com') {
      host = 'https://coru.com';
      auxHost = 'https://coru.com';
    } else if (url === 'staging.coru.mx') {
      host = 'https://staging.coru.mx';
      auxHost = 'https://staging.coru.mx';
    } else {
      host = 'https://dev-rediseno.coru.mx';
      auxHost = 'https://dev-rediseno.coru.mx';
    }

    if (process.env.REACT_APP_SERVERTYPE === 'Development') {
      plantillasHTML = '/announcements/';
    } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
      plantillasHTML = '/announcements_prod/';
    }
    const coruShareLink = `${auxHost}${plantillasHTML}${announcement}.html?coru-redirect-url=${host}${encodeURIComponent(
      redirectLink,
    )}`;
    switch (socialNetwork) {
      case 'Messenger':

        window.open(
          `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${encodeURIComponent(
            `${coruShareLink}`,
          )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`,
        );
        break;
      case 'MessengerMob':
        window.open(`fb-messenger://share/?link=${coruShareLink}&app_id=248019326476226`);
        break;
      case 'Facebook':

        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruShareLink,
          )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}&display=popup`,
        );
        break;
      case 'Whatsapp':
        window.open(`https://wa.me/?text=${encodeURI(coruShareLink)}`);
        break;
      default:
        break;
    }
  }

  const ModalCompartir = () => (
            <Modal
                open={openModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                onClick={() => {
                  setopenModal(false);
                }}
            >
                <div className="ModalCompartir">
                    <ParagrapTwo
                        fontWeight="700"
                        mt="0px"
                        mb="0px"
                        cursor="pointer"
                        mtWeb="0px"
                        mbweb="0px"
                        texttalign="right"
                        textalignMobile="center"
                        className="mx-auto"
                        onClick={() => {
                          setopenModal(false);
                        }}
                    >
                        {widthBrowser >= 760 ? (
                          'x'
                        ) : (
                            <MaximizeIcon style={{ fontSize: '2.5rem' }} />
                        )}
                    </ParagrapTwo>
                    <ContentHomeSection
                        displayD="flex"
                        leftD="0%"
                        floatD="left"
                        width="100%;"
                    >
                        <Rectangle24
                            positionD="absolute"
                            Ptop="40px"
                            mLeftM="0"
                            PLeftM="0%"
                            mTopM="62%"
                            Pleft="5%"
                            background="#6BD5AF"
                            displayM="block"
                            displayD=" "
                            left=" "
                            top=" "
                            width="40px"
                            height="40px"
                            widthM="30px"
                            heightM="30px"
                            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
                        />
                        <Vector2
                            width="50px"
                            left="0"
                            height="60px"
                            widthM="30px"
                            heightM="40px"
                            background="#91D770"
                            radius="40%"
                            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                            positionD="absolute"
                            Pleft="10%"
                            Ptop="153px"
                            PleftM="56%"
                            PtopM="55%"
                            displayM="block"
                        />
                        <Vector3
                            position="absolute"
                            left=" "
                            top=" "
                            PtopM="35%"
                            PleftM="2%"
                            Ptop="280px"
                            Pleft="-3%"
                            background=" #E3E660;"
                            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                            width="55px"
                            height="55px"
                            widthM="35px"
                            heightM="35px"
                            displayD=" "
                            displayM="block"
                        />
                        <Rectangle24
                            positionD="absolute"
                            Ptop="360px"
                            mLeftM="0"
                            PLeftM="0%"
                            mTopM="0%"
                            Pleft="16%"
                            background="#BFDD6A"
                            displayM="block"
                            displayD=" "
                            left=" "
                            top=" "
                            width="30.94px"
                            height="30.94px"
                            widthM="25px"
                            heightM="25px"
                            transform="rotate(46.41deg)"
                        />
                        <ContentHomeSection
                            leftD="0%"
                            floatD="left"
                            width="25%;"
                            widthM="0"
                        ></ContentHomeSection>
                        <ContentHomeSection
                            leftD="0%"
                            floatD="left"
                            width="50%;"
                            widthM="100%"
                        >
                            <Rectangle24
                                positionD="absolute"
                                Ptop="24px"
                                mLeftM="0"
                                PLeftM="5%"
                                mTopM="3%"
                                Pleft="65%"
                                background="#BFDD6A"
                                displayM="block"
                                displayD=" "
                                left=" "
                                top=" "
                                width="30.94px"
                                height="30.94px"
                                widthM="25px"
                                heightM="25px"
                                transform="rotate(46.41deg)"
                            />
                            <Vector2
                                width="40px"
                                left="0"
                                height="47px"
                                background="#6BD5AF"
                                radius="40%"
                                transform="rotate(-8.34deg)"
                                positionD="absolute"
                                Pleft="81%"
                                Ptop="146px"
                                PleftM="51%"
                                PtopM="50px"
                                displayM="block"
                            />
                            <Vector3
                                position="absolute"
                                left=" "
                                top=" "
                                Ptop="12px"
                                Pleft="82%"
                                PtopM="100px"
                                PleftM="83%"
                                background="#E3E660"
                                transform="rotate(-15.06deg)"
                                width="50px"
                                height="50px"
                                widthM="42px"
                                heightM="42px"
                                displayD=" "
                                displayM="block"
                            />
                            <Rectangle24
                                positionD="absolute"
                                displayM="none"
                                mLeftM="0"
                                PLeftM="15%"
                                mTopM="-65%"
                                Pleft="90%"
                                Ptop="250px"
                                displayD=""
                                width="60px"
                                height="60px"
                                widthM="32px"
                                heightM="32px"
                                left=" "
                                top=" "
                                background="#91D770"
                                transform="rotate(-17.24deg)"
                            />
                            <Rectangle24
                                positionD="absolute"
                                Ptop="350px"
                                mLeftM="0"
                                PLeftM="93%"
                                mTopM="3%"
                                Pleft="80%"
                                background="#E3E660"
                                displayM="block"
                                displayD=" "
                                left=" "
                                top=" "
                                width="30.94px"
                                height="30.94px"
                                widthM="25px"
                                heightM="25px"
                                transform="rotate(46.41deg)"
                            />
                            <ContentHomeSection
                                displayD="flex"
                                leftD="0%"
                                floatD="left"
                                width="100%;"
                            >
                                <Feed_Text
                                    className="roboto"
                                    weight="bold"
                                    width="80%"
                                    margin="auto"
                                >
                                    Comparte en:
                    </Feed_Text>
                            </ContentHomeSection>
                            <ContentHomeSection
                                displayD="flex"
                                cursor="pointer"
                                topD="10%"
                                leftD="0%"
                                floatD="left"
                                width="100%;"
                                borderbottom="2px solid #CDCDCD;"
                                onClick={() => shareCoru('Facebook')}
                            >
                                <img
                                    alt=""
                                    position="initial"
                                    left="0"
                                    width="50px"
                                    height="50px"
                                    style={{
                                      maxWidth: '100%',
                                      width: '50px',
                                      height: '50px',
                                      position: 'initial',
                                      cursor: 'pointer',
                                      margin: '6%',
                                    }}
                                    src={face}
                                />
                                <Feed_Text
                                    className="roboto"
                                    margin="auto"
                                    leftm="0px"
                                    top="auto"
                                >
                                    Facebook
                    </Feed_Text>
                            </ContentHomeSection>
                            <ContentHomeSection
                                displayD="flex"
                                cursor="pointer"
                                leftD="0%"
                                floatD="left"
                                width="100%;"
                                borderbottom="2px solid #CDCDCD;"
                                onClick={() => shareCoru('Whatsapp')}
                            >
                                <img
                                    alt=""
                                    position="initial"
                                    left="0"
                                    top="6%"
                                    width="50px"
                                    height="50px"
                                    style={{
                                      maxWidth: '100%',
                                      width: '50px',
                                      height: '50px',
                                      position: 'initial',
                                      cursor: 'pointer',
                                      margin: '6%',
                                    }}
                                    src={whats}
                                />
                                <Feed_Text
                                    className="roboto"
                                    margin="auto"
                                    leftm="0px"
                                    top="auto"
                                >
                                    Whatsapp
                    </Feed_Text>
                            </ContentHomeSection>
                            <ContentHomeSection
                                displayD="flex"
                                cursor="pointer"
                                leftD="0%"
                                floatD="left"
                                width="100%;"
                                className="display-web"
                                onClick={() => shareCoru('Messenger')}
                            >
                                <img
                                    className="display-web"
                                    alt=""
                                    position="initial"
                                    left="0"
                                    width="44px"
                                    height="50px"
                                    style={{
                                      maxWidth: '100%',
                                      width: '50px',
                                      height: '54px',
                                      position: 'initial',
                                      cursor: 'pointer',
                                      margin: '6%',
                                    }}
                                    src={twit}
                                />
                                <Feed_Text
                                    className="roboto display-web"
                                    margin="auto"
                                    leftm="0px"
                                    top="auto"
                                >
                                    Messenger
                    </Feed_Text>
                            </ContentHomeSection>
                            <ContentHomeSection
                                displayD="flex"
                                cursor="pointer"
                                leftD="0%"
                                floatD="left"
                                width="100%;"
                                className="display-mobile-flex"
                                onClick={() => shareCoru('MessengerMob')}
                            >
                                <img
                                    className="display-mobile"
                                    alt=""
                                    position="initial"
                                    left="0"
                                    width="44px"
                                    height="50px"
                                    style={{
                                      maxWidth: '100%',
                                      width: '50px',
                                      height: '54px',
                                      position: 'initial',
                                      marginLeft: '0',
                                      margin: '6%',
                                    }}
                                    src={twit}
                                />
                                <Feed_Text
                                    className="roboto display-mobile"
                                    margin="auto"
                                    leftm="0px"
                                    top="auto"
                                >
                                    Messenger
                    </Feed_Text>
                            </ContentHomeSection>
                        </ContentHomeSection>
                        <ContentHomeSection
                            leftD="0%"
                            floatD="left"
                            width="25%;"
                            widthM="0"
                        ></ContentHomeSection>
                    </ContentHomeSection>
                </div>
            </Modal>
  );

  return (
        <>
            {
                buttonType === 'outline' ? (
                    <>
                        <CardsBtn
                            type="button"
                            onClick={() => setopenModal(true)}
                            color="#EC407A !important"
                            back="#fff !important"
                            border="solid 1px !important"
                            padd="15px 20px"
                            windthG="100%"
                            windhtmd="100%"
                            weight="bold"
                            heigth=""
                            className=""
                        >
                            <b>Compartir</b>
                            <ShareIcon style={{ float: 'right' }} />
                        </CardsBtn>
                    </>
                ) : (
                    <ButtonSecon className="roboto" mleft=" " mt=" " onClick={() => setopenModal(true)}>
                        Compartir
                    </ButtonSecon>
                )
            }
            <ModalCompartir />
        </>
  );
};

export default ModalShareLP;
