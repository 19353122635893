import React, { useState, Fragment, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  ContentButton,
  Container,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import { Home_hr } from '../../assets/styles/Home.styled';
import '../../assets/styles/TextCustom.css';
import { getItemSessionStorage } from '../../Helpers/general';
import { CALL_SCRAPPER, SAVE_AUTH_OTP } from '../../mutations';
import { getJsonVexi, getVariablesScrapper } from '../../Helpers/JsonScrappers';
import useInputValue from '../../hooks/useInputValue';
import ModalLoader from '../../components/common/ModalLoader';

const VerificationCodeVexi = () => {
  const history = useHistory();
  const email = getItemSessionStorage('Email1');
  const code = useInputValue('');
  const [feedback, setFeedBack] = useState('');
  const [dataFail, setDataFail] = useState('');

  const [scrapperIntent, setScrapperIntent] = useState(0);
  const [loading, setLoading] = useState(false);
  const MAX_INTENT = 3;

  const [saveAuthOtp, { data: dataOTP }] = useMutation(SAVE_AUTH_OTP);
  const [callScrapper, { data: dataScrapper }] = useMutation(CALL_SCRAPPER);
  const encodeString = btoa(JSON.stringify(getJsonVexi()));
  const config = { variables: getVariablesScrapper({ encodeString, bank: 'VEXI' }) };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (loading) return;
    if (!code.value || code.value.length < 3) return;
    if (scrapperIntent < MAX_INTENT) {
      setLoading(true);
      const input = { email, token: code.value, partner: 'VEXI' };
      console.log(input);
      return saveAuthOtp({ variables: { input } });
    }
  };

  useEffect(() => console.log(dataOTP), [dataOTP]);
  useEffect(() => {
    const scrapper = callScrapper(config);
    return () => clearTimeout(scrapper);
  }, []);
  useEffect(() => {
    let timerFeedback; let
      timerScrapper;
    if (dataScrapper) {
      console.log(dataScrapper);
      setLoading(false);
      setScrapperIntent(scrapperIntent + 1);
      const { response } = dataScrapper.callScrapper;
      switch (response) {
        case 'Nip Erroneo':
          if (scrapperIntent < MAX_INTENT) {
            timerScrapper = callScrapper(config);
            setFeedBack(`${response}, te reenviamos el código`);
            timerFeedback = setTimeout(() => setFeedBack(''), 5000);
          }
          history.replace('/success-vexi?error="MaxIntents"');
          break;
        case 'Nip no encontrado':
          if (scrapperIntent < MAX_INTENT) {
            timerScrapper = callScrapper(config);
            setFeedBack(`${response}, te reenviamos el código`);
            timerFeedback = setTimeout(() => setFeedBack(''), 5000);
          }
          history.replace('/success-vexi?error="MaxIntents"');
          break;
        case 'Rechazada':
          history.replace(`/success-vexi?error=${response}`);
          break;
        case 'Aprobada':
          history.replace('/success-vexi');
          break;
        case 'ErrorDesconocido':
          history.replace(`/success-vexi?error=${response}`);
          break;
        default:
          console.log('Error de datos');
          setDataFail(`Error de datos, te invitamos a que verifiques tus datos: ${response}`);
      }
    }
    return () => {
      clearTimeout(timerScrapper);
      clearTimeout(timerFeedback);
    };
  }, [dataScrapper]);

  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="60%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="40px"
              sizeMobile="20px"
              textalingn="center"
              textalingnMobile="center"
            >
              Escribe el código que recibiste
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="400"
              fontSize="16px"
              fontSizeWeb="18px"
              color="858585"
              textalignMobile="center"
              texttalign="center"
            >
              Te enviamos un código al correo
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="500"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#000000"
              textalignMobile="center"
              texttalign="center"
            >
              {email}
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#000000"
              textalignMobile="center"
              texttalign="center"
            >
              Al ingresar el código que te enviamos, aceptas los
              <a href = "https://vexi.mx/terminosycondiciones" target="_blank" rel="noreferrer">
                  <span style={{ color: '#C518DD' }}>
                    {' Términos y Condiciones,'}
                  </span>
              </a>
              <a href = "https://vexi.mx/avisodeprivacidad" target="_blank" rel="noreferrer">
                  <span style={{ color: '#C518DD' }}>
                    {' Aviso de Privacidad '}
                  </span>
              </a>
                  y autorizas a GRIBMA SAPI de CV (Vexi) para hacer investigaciones de tu
                  Historial Crediticio
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
            >
               <TextField
                    id="pass3"
                    name="pass3"
                    label=""
                    type= {email}
                    value={code.value}
                    onChange={code.onChange}
                    className = "verification-vexi"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 4);
                    }}
                    inputProps={{ className: 'text-center font-weight-bold' }}
                  ></TextField>
                  <Home_hr marginTop ="0px" margin-bottom = "0px"
                      mrM = "0px" mlM = "0px" border = "0.1px solid gray"/>
               <ContentButton mt="0" width="70%" mtMobile="0%">
                  {feedback && <p style={{ color: 'red' }}>{feedback}</p>}
                  {dataFail && <p style={{ color: 'red' }}>{dataFail}</p>}
                  {loading && <p>Estamos procesando su solicitud. Espere un momento...</p>}
                  <Button
                    onClick={handleSubmit}
                     href=""
                     className="d-block mt-md-4 roboto"
                     mb="1.2rem"
                     mt="0rem"
                     bg="#C518DD"
                     fw = "400"
                  >
                     Enviar
                  </Button>
                  <ParagrapTwo
                      className="mx-auto roboto"
                      mb="3%"
                      mt="5%"
                      mbweb="3%"
                      fontWeight="400"
                      fontSize="16px"
                      fontSizeWeb="18px"
                      color="#C518DD"
                      textalignMobile="center"
                      texttalign="center"
                      cursor="pointer"
                      >
                      Enviar código nuevamente
                  </ParagrapTwo>
               </ContentButton>
            </form>
          </Col11>
        </Container>
        {loading && <ModalLoader lp="vexi" />}
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
};

export default VerificationCodeVexi;
