import React, { useState, useCallback } from 'react';
import '../../../assets/styles/TextCustom.css';
import { FormValidateOtp } from '../FormValidateOtp';

sessionStorage.removeItem('codval1');
sessionStorage.removeItem('codval2');
sessionStorage.removeItem('codval3');
sessionStorage.removeItem('codval4');
sessionStorage.setItem('otpcode', '');
const initialState = {
  codval1: '',
  codval2: '',
  codval3: '',
  codval4: '',
};

const CcValidateOtp = React.memo(() => {
  const [form, setForm] = useState(initialState);
  const [error, setError] = useState({
    codigo: '',
  });

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setForm((form) => ({ ...form, [name]: value }));

      switch (name) {
        case 'codval1':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval2']").focus();
            sessionStorage.setItem('codval1', value);
          } else {
            return false;
          }
          break;
        case 'codval2':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval3']").focus();
            sessionStorage.setItem('codval2', value);
          } else {
            return false;
          }
          break;
        case 'codval3':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval4']").focus();
            sessionStorage.setItem('codval3', value);
          } else {
            return false;
          }
          break;
        case 'codval4':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval1']").focus();
            sessionStorage.setItem('codval4', value);
          } else {
            return false;
          }
          break;
        default:
          break;
      }
    }, [],
  );
  const forwardCode = () => {

  };
  setTimeout(() => {
    window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/personal-information-cc';
  }, 7000);
  return <FormValidateOtp stateForm={form} handleChange={handleChange} error={error} forwardCode={forwardCode}></FormValidateOtp>;
});

export default CcValidateOtp;
