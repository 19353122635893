import React, { Fragment, useState } from 'react';
import '../../assets/styles/perfil/perfiles.css';
import ShareIcon from '@material-ui/icons/Share';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';

// Modal
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';
import { CREATE_CLICK } from '../../mutations';
import candado from '../../assets/img/logros/Candado.png';
import {
  Container, Col12, SubtitleOne, Image, ParagrapTwo, Col4, Button, CirculeRachas,
} from '../common/common.styled';
import trofeo1 from '../../assets/img/logros/Trofeo1.png';
import trofeo2 from '../../assets/img/logros/Trofeo1.png';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#D6D6D6',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#91D770',
  },
}))(LinearProgress);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = top;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 350,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

function Trofeos() {
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted() {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  }, []);

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) createClickFront({ variables: createClickInput });
    TagManager.dataLayer(tagManagerArgs);
  };

  const [displayTrofeos, setDisplayTrofeos] = useState(false);
  const [StatusTrofeos] = useState({
    trofeo1: false,
    trofeo2: false,
    trofeo3: false,
    trofeo4: false,
    trofeo5: false,
    trofeo6: false,
    compartir: false,
  });

  const [Trofeos] = useState({
    trofeo1: !StatusTrofeos.trofeo1 ? trofeo1 : trofeo1,
    color1: !StatusTrofeos.trofeo1 ? '#07210A' : '#07210A',
    status1: !!StatusTrofeos.trofeo1,

    trofeo2: !StatusTrofeos.trofeo2 ? trofeo1 : trofeo1,
    color2: !StatusTrofeos.trofeo2 ? '#07210A' : '#07210A',
    status2: !!StatusTrofeos.trofeo2,

    trofeo3: !StatusTrofeos.trofeo3 ? trofeo1 : trofeo1,
    color3: !StatusTrofeos.trofeo3 ? '#07210A' : '#07210A',
    status3: !!StatusTrofeos.trofeo3,

    trofeo4: !StatusTrofeos.trofeo4 ? trofeo2 : trofeo2,
    color4: !StatusTrofeos.trofeo4 ? '#07210A' : '#07210A',
    status4: !!StatusTrofeos.trofeo4,

    trofeo5: !StatusTrofeos.trofeo5 ? trofeo2 : trofeo2,
    color5: !StatusTrofeos.trofeo5 ? '#07210A' : '#07210A',
    status5: !!StatusTrofeos.trofeo5,

    trofeo6: !StatusTrofeos.trofeo6 ? trofeo2 : trofeo2,
    color6: !StatusTrofeos.trofeo6 ? '#07210A' : '#07210A',
    status6: !!StatusTrofeos.trofeo6,

  });
  const ShareActivity = (status, numEntrenamiento, background, title) => {
    sessionStorage.setItem('statusActivity', status);
    sessionStorage.setItem('numActivity', numEntrenamiento);
    sessionStorage.setItem('backgroundActivity', background);
    sessionStorage.setItem('trofeoActivity', true);
    sessionStorage.setItem('titleActivity', title);
    history.push('/acitivity-summary');
  };

  const Recuadros = ({
    src, color, texto1, texto2, classe, mwidthimagen, positionimagen, numEntrenamiento, textbtnModal, urlImagen, display, status, background, title,
  }) => (
            <Col4 display={!display ? 'none' : ' '} cursor="pointer" padding="0px" ta={classe} onClick={() => { ShareActivity(status, src, background, title); }} >
                <CirculeRachas border={status ? '10px solid #FFFFFF' : '0px'} background={ status ? background : '#CDCDCD'}>
                    {!status ? <Image src={candado} width="25px" margin="auto"/> : <Image src={src} width="40px" margin="auto"/>}
                </CirculeRachas>
                <ParagrapTwo cursor="ponter" textalignMobile="center" texttalign="center" className='mx-auto roboto' fontSize="14px" fontSizeWeb="14px" color={color}>{texto1} <br/> {texto2}</ParagrapTwo>
            </Col4>
  );

  // Modal
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const [ModalContext] = useState({
    url: ' ',
    numEntrenamiento: 'Un entrenamiento',
    textbtn: ' Nuevo entrenamiento',
  });

  const handleClose = () => {
    setOpen(false);
  };

  const history = useHistory();
  const handleOpenFeed = () => {
    history.push('/the-training-feed/');
  };

  const ModalRecuadros = () => (
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo fontSize="20px" fontSizeWeb="20px" fontWeight="700" cursor="pointer" texttalign ="right" textalignMobile="right" className='mx-auto' onClick={handleClose}>X</ParagrapTwo>
                    <Image width="50%" src={ModalContext.url} />
                    <SubtitleOne mt="10%" mb="10%" className='font-weight-bold roboto' sizeMobile="20px" size="20px">
                        {ModalContext.numEntrenamiento}
                    </SubtitleOne>
                    <ParagrapTwo className='mx-auto roboto'>Cumple el reto y obtén la insignia por tu esfuerzo. </ParagrapTwo>
                    <ParagrapTwo className='mx-auto roboto'>¡Éxito!</ParagrapTwo>
                    <Button className='d-block mt-5 mt-md-4 mb-5 roboto' onClick={handleOpenFeed}>
                        Ir a entrenamientos
                    </Button>
                </div>
            </Modal>
  );
  return (
        <Fragment>
            <Container width='100%' mt='1rem' widthMobile='100%' mtMobile='2rem'float="auto!important" >
                <ContainerPerfiles padding="4%" height="auto" minheight="122px" display="flex" mb="5%">
                    <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" >
                        <Col12 pt=" " ptMobile=" " bottom="10%">
                            <SubtitleOne
                            textalingn="left"
                            textalingnMobile="left"
                            className="font-weight-bold roboto"
                            sizeMobile="20px"
                            size="25px" mb="1%"
                            transform=" "
                            mt="0%">
                            Finalización de Trofeos totales
                            </SubtitleOne>
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                0/6
                            </ParagrapTwo>
                            <BorderLinearProgress variant="determinate" value={0} />
                        </Col12>
                        <Recuadros src={Trofeos.trofeo1} color={Trofeos.color1} texto1={'Perfil'} texto2={'completo'} classe={'center'} mwidthimagen={'50%'} positionimagen={'center'} status={Trofeos.status1} display={true} background="#6BD5AF" title="Perfil Completo"></Recuadros>
                        <Recuadros src={Trofeos.trofeo2} color={Trofeos.color2} texto1={'Treinta'} texto2={'entrenamientos'} classe={'center'} mwidthimagen={'50%'} positionimagen={'center'} status={Trofeos.status2} display={true} background="#6BD5AF" title="Treinta entremanientos completados"></Recuadros>
                        <Recuadros src={Trofeos.trofeo3} color={Trofeos.color3} texto1={'Adquisición de'} texto2={'un producto'} classe={'center'} mwidthimagen={'50%'} positionimagen={'center'} status={Trofeos.status3} display={true} background="#6BD5AF" title="Adquisicion de un producto  completado"></Recuadros>
                        <Recuadros src={Trofeos.trofeo4} color={Trofeos.color4} texto1={'Crédito para'} texto2={'cumplir meta'} classe={'center'} mwidthimagen={'50%'} positionimagen={'center'} status={Trofeos.status4} display={true} background="#91D770" title="Credito para cumplir meta completado" ></Recuadros>
                        <Recuadros src={Trofeos.trofeo5} color={Trofeos.color5} texto1={'Automóvil'} texto2={'asegurado'} classe={'center'} mwidthimagen={'50%'} positionimagen={'center'} status={Trofeos.status5} display={true} background="#91D770" title="Automovil asegurado completado"></Recuadros>
                        <Recuadros src={Trofeos.trofeo6} color={Trofeos.color6} texto1={'Seguro'} texto2={'de vida'} classe={'center'} mwidthimagen={'50%'} positionimagen={'center'} status={Trofeos.status6} display={true} background="#91D770" title="Seguro de vida compledaro"></Recuadros>
                        {StatusTrofeos.compartir
                          ? (<Fragment>
                            <Col12>
                                <ParagrapTwo className='mx-auto' lh="1.3">Comparte tu avance e invitalos a mejorar sus decisiones.</ParagrapTwo>
                            </Col12>
                            <Button className='d-block mt-5 mt-md-4 mb-5'>
                                    <ShareIcon/>  Compartir
                            </Button>
                        </Fragment>
                          ) : (<div></div>)}

                    </Container>
                </ContainerPerfiles>
                {!displayTrofeos ? (
                    <Button className='d-block mt-5 mt-md-4 mb-5 roboto' onClick={() => { setDisplayTrofeos(true); sendMongoAndGTM('ClickTrophies'); }} >
                            Ver trofeos completos
                    </Button>
                ) : (null)}
            </Container>
            <ModalRecuadros/>
        </Fragment>
  );
}
export default Trofeos;
