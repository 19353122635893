/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const SeguroMedico = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  activePossibleroutines = false,
  possibleRoutines = [],
  setRamaSeguroMedico = '',
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editTieneSeguro, setEditTieneSeguro] = useState('');
  const [editTipoSeguro, setEditTipoSeguro] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('caracteristicas', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [gastosMedicos, setGastosMedicos] = useState(
    sessionStorage.getItem('SeguroMedico') || '',
  );
  const [tipoGastosMedicos, setTipoGastosMedicos] = useState(
    sessionStorage.getItem('TipoSeguroMedico') || '',
  );
  const [activeQuestion, setActiveQuestion] = useState(true);
  const [form] = useState({});
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 0:
        setEditTieneSeguro(true);
        setGastosMedicos(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuestionOptions' });
        if (activePossibleroutines) {
          if (val.value === 'No') {
            setRamaSeguroMedico('Prestaciones');
            setOrderRutine(possibleRoutines[0]);
            setActiveQuestion(false);
          } else {
            setActiveQuestion(true);
          }
        }

        break;
      case 1:
        setEditTipoSeguro(true);
        setTipoGastosMedicos(val.value);
        if (activePossibleroutines) {
          if (val.value === 'Seguro de gastos médicos mayores pagados por mi') {
            setOrderRutine(possibleRoutines[1]);
            setRamaSeguroMedico('Gastos');
          } else {
            setRamaSeguroMedico('Prestaciones');
            setOrderRutine(possibleRoutines[0]);
          }
        } else {
          setOrderRutine(nextOrderRoutine);
        }
        break;
      default:
        console.log(val);
    }
  };
  // funcionalidad propia de la rutina

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setGastosMedicos(sessionStorage.getItem('SeguroMedico') || '');
      setTipoGastosMedicos(sessionStorage.getItem('TipoSeguroMedico') || '');
      if (activePossibleroutines) {
        if (sessionStorage.getItem('SeguroMedico') === 'No') {
          setRamaSeguroMedico('Prestaciones');
          setOrderRutine(possibleRoutines[0]);
          setActiveQuestion(false);
        }
        if (
          sessionStorage.getItem('TipoSeguroMedico')
          === 'Seguro de gastos médicos mayores pagados por mi'
        ) {
          setOrderRutine(possibleRoutines[1]);
          setRamaSeguroMedico('Gastos');
        } else if (
          sessionStorage.getItem('TipoSeguroMedico') !== null
          && sessionStorage.getItem('TipoSeguroMedico') !== ''
        ) {
          setRamaSeguroMedico('Prestaciones');
          setOrderRutine(possibleRoutines[0]);
        }
      }
    }
  }, [recoveryRoutine]);
  const handleChangeIcon = (json) => {
    setEditTieneSeguro(false);
    setEditTipoSeguro(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  useEffect(() => {
    if (document.getElementsByClassName('iconEditCommon').length >= 2) { document.getElementsByClassName('iconEditCommon')[0].style.display = 'none'; }
  }, []);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (gastosMedicos === ''
          || (lapiz[0].routine === currentRoutine
            && lapiz[0].active === 1))
        && editTieneSeguro !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeSeguroMedico' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeSeguroMedico"></div>
        <QuestionsOrder
          classCard="cardOne"
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (gastosMedicos === ''
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1))
            && editTieneSeguro !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            {orderRutine === currentRoutine ? (
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            ) : null}
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeQuestionOptions"></div>
        <CardTwo
          classCard={
            gastosMedicos !== ''
            && (editTieneSeguro === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={gastosMedicos}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              gastosMedicos !== '' && tipoGastosMedicos === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            gastosMedicos !== ''
            && activeQuestion
            && (editTieneSeguro === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((gastosMedicos !== ''
              && tipoGastosMedicos === ''
              && activeQuestion)
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 1
            )
              || editTieneSeguro === true)
            && editTipoSeguro !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            {orderRutine === currentRoutine ? (
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="1-1"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            ) : null}
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            tipoGastosMedicos !== ''
            && activeQuestion
            && (editTipoSeguro === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={tipoGastosMedicos}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              tipoGastosMedicos !== '' && activeQuestion
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
            }
          />
        </CardTwo>
      </div>
  );
};

export default SeguroMedico;
