import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_GOAL_DATA_COLLABORATIVE } from '../../queries';
import {
  INSERT_AMOUNT_GOAL_COLLABORATIVE,
} from '../../mutations';
import Features from '../../components/Training/Features';
import SectionAdvanceCollaborative from '../../components/Training/AdvanceCollaborative';
import ModalMetaColaborativo from '../../components/common/ModalMetaColaborativo';
import Starttraining from '../../components/Training/Starttraining';
import {
  TrainingMain,
  CardsBtn,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingSectionGray,
  DivAdvanceGoals,
} from '../../assets/styles/Training.styled';
import { SeparateSection, Feed_Main } from '../../assets/styles/Feed.styled';
import {
  Col12,
  Container,
  Col4,
  DivGoals,
  DivContainerGoal,
  DivContainerTextGoal,
} from '../../components/common/common.styled';
import { filterFloat } from '../../Helpers/index';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import WOlayout from '../../components/layouts/WOlayout';

import SectionShare from '../../components/common/ShareSection';
import ProductsFremium from '../../components/common/ProductsFremium';

const RpCollaborative = () => {
  const idTraining = 37;
  const [goFeed] = useState(false);
  const history = useHistory();
  const [avanceGoal, setAvanceGoal] = useState('0%');
  const [rachaQuincenal] = useState(0);
  const [datum, setDatum] = useState([]);
  const [addSavings, setAddSavings] = useState(false);
  const [conocerMeta, setConocerMeta] = useState(false);
  const [nuevoAhorro, setNuevoAhorro] = useState(0);
  const [totalAhorro, setTotalAhorro] = useState(0);
  const firstName = sessionStorage.getItem('FirstName') || '';
  const [nombreMeta, setNombreMeta] = useState(
    sessionStorage.getItem('MetasPorCumplir')
      || sessionStorage.getItem('PropiaMetaColavorativa'),
  );
  const [tiempoMetaColaborativo, setTiempoMetaColaborativo] = useState(
    sessionStorage.getItem('TiempoMetaColavorativa') || '',
  );
  const [montoMetaColaborativo, setMontoMetaColaborativo] = useState(
    sessionStorage.getItem('DineroMetaColavorativa') || '$0.00',
  );
  const [invitadosMetaColavorativa, setInvitadosMetaColavorativa] = useState(
    [],
  );
  const [nombreMaster, setNombreMaster] = useState('');
  const [featuresArray, setFeaturesArray] = useState([]);
  const [savingsByMonth, setSavingsByMonth] = useState([]);
  const tamCheck = {
    borderRadius: '5px',
    height: '25px',
    marginTop: '-15px',
    width: '25px',
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [integrantesAhorro, setIntegrantesAhorro] = useState([]);
  useEffect(() => {
    if (invitadosMetaColavorativa.length > 0) {
      const featuresArrayTmp = [
        {
          text: `Compartes esta meta con ${
            invitadosMetaColavorativa.length - 1
          } ${
            invitadosMetaColavorativa.length - 1 > 1 ? 'personas' : 'persona'
          } más.`,
          color: '#99F2A8',
        },
        {
          text: `En ${tiempoMetaColaborativo} ${
            tiempoMetaColaborativo > 1 ? 'meses' : 'mes'
          } deberías ahorrar la cantidad de ${montoMetaColaborativo} MXN.`,
          color: '#99F2A8',
        },
        {
          text: `Ahorra ${formatter.format(
            filterFloat(
              (
                parseFloat(montoMetaColaborativo.replace(/[,$]/g, ''))
                / (tiempoMetaColaborativo * 2)
              ).toFixed(2),
            ),
          )} MXN cada quincena durante ${tiempoMetaColaborativo} ${
            tiempoMetaColaborativo > 1 ? 'meses' : 'mes'
          }.`,
          color: '#99F2A8',
        },
      ];

      setFeaturesArray([...featuresArray, featuresArrayTmp]);

      formatMontosAhorro();
    }
  }, [invitadosMetaColavorativa]);

  useEffect(() => {
    if (nuevoAhorro && nuevoAhorro !== 0) {
      const savingInput = {
        input: {
          idUser: parseInt(sessionStorage.getItem('idUser')),
          idDatGoal: parseInt(sessionStorage.getItem('idDatGoalCollaborative')),
          amount: nuevoAhorro,
          description: '',
        },
      };
      console.log(savingInput);
      insertAmountGoalCollaborative({ variables: savingInput });
    }
  }, [nuevoAhorro]);
  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser') || '';
    if (idUser !== '') {
      // Revisar si es primer ingreso o no
      const fromCollaborative = sessionStorage.getItem('fromCollaborative');
      if (fromCollaborative === 'true') {
        setConocerMeta(false);
      } else {
        setConocerMeta(true);
      }
      getGoalData();
    } else {
      history.push('/user-validation/');
    }
  }, []);

  const formatMontosAhorro = () => {
    const idUserLog = parseInt(sessionStorage.getItem('idUser'));
    const usuarioActual = invitadosMetaColavorativa.filter(
      (e) => e.idUser === idUserLog,
    );
    const otrosUsuarios = invitadosMetaColavorativa.filter(
      (e) => e.idUser !== idUserLog,
    );
    let monto = 0;

    // Obtener ahorro por meses de cada usuario
    let maxNumSavings = 0;
    const savingsByMonthTmp = [];
    invitadosMetaColavorativa.map((e) => {
      if (e.amounts.length > maxNumSavings) {
        maxNumSavings = e.amounts.length;
      }
    });

    for (let i = 0; i < maxNumSavings; i++) {
      const saving = invitadosMetaColavorativa.map((e, index) => {
        const pos = index === 0 ? index + 1 : index;
        const name = e.idUser === idUserLog ? 'Tu ahorro' : `Compañero ${pos}`;
        return e.amounts[i]
          ? { name, ahorro: e.amounts[i].amount }
          : { name, ahorro: '$0' };
      });
      savingsByMonthTmp.push(saving);
    }
    setSavingsByMonth(...savingsByMonth, savingsByMonthTmp);

    usuarioActual[0].amounts.map((e) => {
      monto += parseFloat(e.amount.replace(/[,$]/g, ''));
    });

    const integrantesAhorroTmp = [
      {
        id: 0,
        name: 'Tu ahorro',
        phone: '',
        ahorro: formatter.format(filterFloat(monto.toFixed(2))),
        style: {
          background: '#6BD5AF',
          border: 'solid 1px #6BD5AF',
          ...tamCheck,
        },
      },
    ];

    const montoMeta = parseFloat(montoMetaColaborativo.replace(/[,$]/g, ''));
    let percent = (monto / montoMeta) * 100;
    let totalPercent = percent;
    const datumTmp = [{ key: 'Tu ahorro - ', y: percent, color: '#6BD5AF' }];

    for (let i = 0; i < otrosUsuarios.length; i++) {
      monto = 0;
      if (otrosUsuarios[i].idUser !== null) {
        otrosUsuarios[i].amounts.map((e) => {
          monto += parseFloat(e.amount.replace(/[,$]/g, ''));
        });
      }
      percent = (monto / montoMeta) * 100;
      totalPercent += percent;
      integrantesAhorroTmp.push({
        id: `${i + 1}`,
        name: `Compañero ${i + 1}`,
        phone: otrosUsuarios[i].phone,
        ahorro: formatter.format(filterFloat(monto.toFixed(2))),
        style: {
          background: '#F64282',
          border: 'solid 1px #F64282',
          ...tamCheck,
        },
      });

      datumTmp.push({
        key: `Ahorro de tu compañero ${i + 1} - `,
        y: percent,
        color: '#F64282',
      });
    }

    let montoAhorroTotal = 0;
    integrantesAhorroTmp.map((item) => {
      montoAhorroTotal += parseFloat(item.ahorro.replace(/[,$]/g, ''));
    });

    setAvanceGoal(totalPercent.toFixed(2));
    datumTmp.push({
      key: 'Porcentaje ahorro restante',
      y: 100 - totalPercent,
      color: '#F5F5F5',
    });

    setTotalAhorro(formatter.format(filterFloat(montoAhorroTotal.toFixed(2))));
    setIntegrantesAhorro(...integrantesAhorro, integrantesAhorroTmp);
    setDatum(...datum, datumTmp);
  };

  const getConocerMeta = () => {
    sessionStorage.removeItem('fromCollaborative');
    setConocerMeta(true);
  };

  const [getGoalDataCollaborative] = useLazyQuery(GET_GOAL_DATA_COLLABORATIVE, {
    fetchPolicy: 'no-cache',
    onCompleted({ getGoalDataCollaborative }) {
      console.log(getGoalDataCollaborative);
      if (getGoalDataCollaborative.message === 'success') {
        setDatum([]);
        setIntegrantesAhorro([]);
        setSavingsByMonth([]);
        const goalData = JSON.parse(getGoalDataCollaborative.response);
        console.log(goalData);
        setNombreMeta(goalData[0].goalName);
        setNombreMaster(goalData[0].masterName);
        sessionStorage.setItem('idDatGoalCollaborative', goalData[0].idDatGoal);
        const monto = goalData[0].goalTypes.filter((e) => e.IdType === 480)[0];
        const tiempo = goalData[0].goalTypes.filter((e) => e.IdType === 481)[0];
        setMontoMetaColaborativo(monto ? monto.Value : '');
        setTiempoMetaColaborativo(tiempo ? tiempo.Value : '');
        setInvitadosMetaColavorativa(goalData[0].guestData);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const getGoalData = () => {
    // En función conocer el plan, sino llamar directo
    const input = {
      idUser: String(sessionStorage.getItem('idUser')),
      idTraining: String(sessionStorage.getItem('idTraining') || idTraining),
      idDatTraining: String(
        sessionStorage.getItem('idDatTrainingCollaborative') || '',
      ),
    };
    getGoalDataCollaborative({ variables: input });
  };
  const [insertAmountGoalCollaborative, {}] = useMutation(
    INSERT_AMOUNT_GOAL_COLLABORATIVE,
    {
      onCompleted({ insertAmountGoalCollaborative }) {
        if (insertAmountGoalCollaborative.statusCode === 200) {
          getGoalData();
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  useEffect(() => {
    if (
      !conocerMeta
      && document.getElementById('successMeta') !== null
      && document.getElementById('successMeta') !== undefined
    ) {
      document.getElementById('footer_').style.position = 'fixed';
      document.getElementById('footer_').style.bottom = '0';
    } else {
      document.getElementById('footer_').style.position = 'relative';
      document.getElementById('footer_').style.bottom = '0';
    }
  }, [conocerMeta]);

  const styles = {
    background: '#6BD5AF',
    border: 'solid 1px #6BD5AF',
    ...tamCheck,
  };

  const styles2 = {
    background: '#F64282',
    border: 'solid 1px #F64282',
    ...tamCheck,
  };
  return (
    <WOlayout
      backgroundColor={'#f5d495'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      {!conocerMeta ? (
        <>
          <br /> <br /> <br />
          <TrainingSectionGray wiw="100%" mw="5% auto" back="#FAFAFA" mtop="5%">
            <TrainingDivSection2
              wiw="670px"
              paddi="0"
              paddiw="0"
              mw="auto"
              back="#fafafa"
            >
              <div
                id="successMeta"
                className="marginComentLgSuccess1"
                onClick={() => getConocerMeta()}
              >
                {invitadosMetaColavorativa.length <= 2 ? (
                  <Starttraining
                    title="¡Hola! Soy Ana, tu coach financiero."
                    text={`${nombreMaster} te invitó a cumplir la meta "${nombreMeta}". Ya tenemos un plan para iniciar a ahorrar ¿Quieres conocerlo?`}
                    button={true}
                    txtButton="Conocer el plan"
                    onclick={getConocerMeta}
                  />
                ) : (
                  <Starttraining
                    title="¡Hola! Soy Ana, tu coach financiero."
                    text={`${nombreMaster} te invitó a ti y a ${
                      invitadosMetaColavorativa.length - 1
                    } ${
                      invitadosMetaColavorativa.length - 1 > 1
                        ? 'personas'
                        : 'persona'
                    } más a cumplir la meta "${nombreMeta}". Ya tenemos un plan para iniciar a ahorrar ¿Quieres conocerlo?`}
                    button={true}
                    txtButton="Conocer el plan"
                    onclick={getConocerMeta}
                  />
                )}
              </div>
            </TrainingDivSection2>
          </TrainingSectionGray>
        </>
      ) : (
        <>
          <HeaderSuccess
            color1="#f5d495"
            color2="#E3E660"
            color3="#E3E660"
            color4="#6BD5AF"
            color5="#E3E660"
            color6="#BFDD6A"
            color7="#BFDD6A"
            color8="#6BD5AF"
            color9="#f5d495"
            color10="#6BD5AF"
          />
          <TrainingMain mt="50px" mtw="0" className="" id="top">
            <br />
            <TrainingSectionGray back="#fafafa" wiw="100%" mw="auto" mtop="0">
              <Feed_Main width="100%" background=" ">
                <Feed_Main
                  width="80%"
                  display=" "
                  mtop=" "
                  background="transparent"
                >
                  <TrainingDivSection2
                    paddiw="0% 10% 3% 10%"
                    paddi="0% 5% 5% 5%"
                    back="#fafafa"
                  >
                    <TrainingSubtitles
                      size="35px"
                      fw="bold"
                      align="center"
                      alignweb="center"
                      className="cabin"
                    >
                      ¡{firstName}, esta es tu meta!
                    </TrainingSubtitles>
                  </TrainingDivSection2>
                  <SeparateSection />
                  <TrainingSectionGray back="#fafafa">
                    <TrainingDivSection2
                      wiw="670px"
                      paddi="0"
                      paddiw="0"
                      mw="auto"
                      back="#fafafa"
                    >
                      {featuresArray.length > 0 ? (
                        <Features
                          classAna={
                            window.screen.width <= 768 ? 'falseAna' : 'trueAna'
                          }
                          title={nombreMeta}
                          titleAlign="center"
                          Arraytext={featuresArray}
                        />
                      ) : null}

                      <Container
                        id="DivPrincipal"
                        mleft="18%"
                        width="402px"
                        mt="2rem"
                        widthMobile="100%"
                        mtMobile="1rem"
                      >
                        <Col4 display="flex" mt="initial" jc="center">
                          <DivContainerGoal>
                            <Container
                              width="100%"
                              mt="0rem"
                              widthMobile="100%"
                              mtMobile="0rem"
                            >
                              <Col12 ta="center" display="flex" mt="initial">
                                <DivContainerTextGoal
                                  fsm="12.5px"
                                  width="100%"
                                  ta="center"
                                  fw="600"
                                  className="cabin"
                                >
                                  {tiempoMetaColaborativo}{' '}
                                  {tiempoMetaColaborativo > 1 ? 'meses' : 'mes'}
                                </DivContainerTextGoal>
                              </Col12>
                              <Col12 display="flex" mt="initial">
                                <DivGoals
                                  wim="86px"
                                  className="roboto"
                                  style={{ textAlign: 'center' }}
                                >
                                  Tiempo
                                </DivGoals>
                              </Col12>
                            </Container>
                          </DivContainerGoal>
                        </Col4>

                        <Col4 display="flex" mt="initial" jc="center">
                          <DivContainerGoal>
                            <Container
                              width="100%"
                              mt="0rem"
                              widthMobile="100%"
                              mtMobile="0rem"
                            >
                              <Col12 ta="center" display="flex" mt="initial">
                                <DivContainerTextGoal
                                  fsm="12.5px"
                                  width="100%"
                                  ta="center"
                                  fw="600"
                                  className="cabin"
                                >
                                  {montoMetaColaborativo}
                                </DivContainerTextGoal>
                              </Col12>
                              <Col12 display="flex" mt="initial">
                                <DivGoals
                                  wim="86px"
                                  className="roboto"
                                  style={{ textAlign: 'center' }}
                                >
                                  Monto
                                </DivGoals>
                              </Col12>
                            </Container>
                          </DivContainerGoal>
                        </Col4>
                        <Col4 display="flex" mt="initial" jc="center">
                          <DivContainerGoal>
                            <Container
                              width="100%"
                              mt="0rem"
                              widthMobile="100%"
                              mtMobile="0rem"
                            >
                              <Col12 ta="center" display="flex" mt="initial">
                                <DivContainerTextGoal
                                  fsm="12.5px"
                                  width="100%"
                                  ta="center"
                                  fw="600"
                                  className="cabin"
                                >
                                  {formatter.format(
                                    filterFloat(
                                      (
                                        parseFloat(
                                          montoMetaColaborativo.replace(
                                            /[,$]/g,
                                            '',
                                          ),
                                        )
                                        / (tiempoMetaColaborativo * 2)
                                      ).toFixed(2),
                                    ),
                                  )}
                                </DivContainerTextGoal>
                              </Col12>
                              <Col12 display="flex" mt="initial">
                                <DivGoals
                                  wim="86px"
                                  className="roboto"
                                  style={{ textAlign: 'center' }}
                                >
                                  Ahorro quincenal
                                </DivGoals>
                              </Col12>
                            </Container>
                          </DivContainerGoal>
                        </Col4>
                      </Container>
                      <Container
                        id="DivPrincipal"
                        mleft="18%"
                        width="402px"
                        mt="2rem"
                        widthMobile="100%"
                        mtMobile="1rem"
                      >
                        <SectionAdvanceCollaborative
                          datum={datum}
                          avance={avanceGoal}
                          mesesConcluidos={rachaQuincenal}
                          totalAhorro={totalAhorro}
                          tiempodeAhorro={tiempoMetaColaborativo}
                          goal={nombreMeta}
                          integrantesAhorro={integrantesAhorro}
                        />

                        {savingsByMonth.map((item, index) => (
                          <DivAdvanceGoals
                            w="90%"
                            ww="100%"
                            mt="26px"
                            h="auto"
                            bcolor="#F7F7F7"
                            pb="20px"
                          >
                            <Container
                              mleft="20px"
                              width="100%"
                              mt="0rem"
                              widthMobile="100%"
                              mtMobile="0rem"
                            >
                              <Col12
                                display="flex"
                                mt="initial"
                                className="paddleft"
                              >
                                <DivContainerTextGoal
                                  fw="600"
                                  ta="left"
                                  width="auto"
                                  className="cabin"
                                >
                                  Quincena {index + 1}
                                </DivContainerTextGoal>
                              </Col12>
                              <Col12
                                display="flex"
                                pt="0%"
                                ptMobile="0%"
                                bottom="5%"
                                mt="initial"
                                className="paddleft"
                              >
                                <DivGoals className="roboto">
                                  Quincena {index + 1} de{' '}
                                  {tiempoMetaColaborativo * 2}
                                </DivGoals>
                              </Col12>
                            </Container>
                            {item.map((e) => (
                              <div
                                key={e.name}
                                style={{ margin: '2px 20px' }}
                                className="tablagen"
                              >
                                <div className="fila">
                                  <div
                                    style={
                                      e.name === 'Tu ahorro' ? styles : styles2
                                    }
                                    className="mobcubQ"
                                  ></div>
                                  <div className="col">{e.name}</div>
                                  <div className="col">{e.ahorro}</div>
                                </div>
                              </div>
                            ))}
                          </DivAdvanceGoals>
                        ))}

                        <Container
                          id="DivPrincipal"
                          width="100%"
                          mt="2rem"
                          widthMobile="100%"
                          mtMobile="1rem"
                          paddingMobile="0% 5%"
                          padding="0"
                        >
                          {montoMetaColaborativo !== totalAhorro ? (
                            <CardsBtn
                              mtop="20px"
                              mtopw="20px"
                              mtopmd="20px"
                              type="button"
                              min_w="100%"
                              min_web="100%"
                              onClick={() => setAddSavings(true)}
                            >
                              Registrar ahorro
                            </CardsBtn>
                          ) : null}
                        </Container>
                      </Container>
                      {addSavings ? (
                        <ModalMetaColaborativo
                          textTraining=""
                          urlTraining=""
                          close={true}
                          setNuevoAhorro={setNuevoAhorro}
                          setAddSavings={setAddSavings}
                        />
                      ) : null}
                    </TrainingDivSection2>
                  </TrainingSectionGray>

                  <TrainingSectionGray back="#fafafa">
                    <TrainingDivSection2
                      wiw="670px"
                      paddi="0"
                      paddiw="0"
                      mw="auto"
                      back="#fafafa"
                    >
                      <ProductsFremium idT={37} />
                    </TrainingDivSection2>
                  </TrainingSectionGray>

                  <TrainingSectionGray back="#fafafa">
                    <TrainingDivSection2
                      wiw="670px"
                      paddi="0"
                      paddiw="0"
                      mw="auto"
                      back="#fafafa"
                    >
                      <SectionShare WO="Colaborativo"></SectionShare>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                </Feed_Main>
              </Feed_Main>
            </TrainingSectionGray>
          </TrainingMain>
        </>
      )}
    </WOlayout>
  );
};
export default RpCollaborative;
