import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { SEND_NOTIFICATION } from '../../mutations';
import { getCurrentDate, formatAMPM } from '../../Helpers/general';

const SendNotificationDebts = () => {
    const [sendNotification] = useMutation(SEND_NOTIFICATION, {
        fetchPolicy: 'no-cache',
        onCompleted({ sendNotification }) {
            if (sendNotification.statusCode === 200) {
                const send = sendNotification.response;
                console.log('response == SEND ==== ', send);
                console.log('Se envio correctamente el video!!');
            }
        },
        onError(err) {
            console.log(err);
        },
    });

    const inputSendNotification = async (inputSend, triggerWa = '', triggerMail = '') => {
        if (triggerWa !== '') {
            inputSend.input.properties.trigger = triggerWa
            inputSend.input.properties.variables.push(
                {
                    setkey: `nombreusuario`,
                    setval: sessionStorage.getItem('FirstName'),
                },
                {
                    setkey: `WO_NT`,
                    setval: window.location.href,
                }
            )
            await sendNotification({ variables: inputSend });
        }
        if (triggerMail !== '') {
            inputSend.input.properties.variables = []
            inputSend.input.type = 'mail2'
            inputSend.input.properties.trigger = triggerMail
            inputSend.input.properties.variables.push({
                setkey: `Nombre`,
                setval: sessionStorage.getItem('FirstName'),
            })
            await sendNotification({ variables: inputSend });
        }

    }
    const handleSendNotification = async ({ type = 'wamult', step = '' }) => {
        const inputSend = {
            input: {
                type,
                properties: {
                    trigger: ``,
                    telephone: sessionStorage.getItem('CellPhone') || '',
                    subject: '',
                    template: '',
                    variables: [],
                    source: {
                        mail: 'contacto@coru.com',
                        name: 'Tus amigos de Coru',
                    },
                    destination: {
                        mail: sessionStorage.getItem('Email1') || '',
                        name: sessionStorage.getItem('FirstName') || ''
                    },
                },
            },
        };
        switch (step) {
            case '1':
                await inputSendNotification(inputSend, 'wa_deu1', 'Email_Debts1');
                break;
            case '2':
                await inputSendNotification(inputSend, 'wa_deu2', 'Email_Debts2');
                break;
            case '3':
                await inputSendNotification(inputSend, 'wa_deu3', 'Email_Debts3');
                break;
            case '4':
                inputSendNotification(inputSend, 'wa_deu4', 'Email_Debts4');
                break;
            case '5':
                await inputSendNotification(inputSend, 'wa_deu5', 'Email_Debts5');
                break;
            case '6':
                await inputSendNotification(inputSend, 'wa_deu6', 'Email_Debts6');
                break;
            case '7':
                await inputSendNotification(inputSend, '', 'Email_Debts7');
                break;
            case '8':
                await inputSendNotification(inputSend, '', 'Email_Debts8');
                break;
            case '7_mail':
                await inputSendNotification(inputSend, 'wa_deu6', 'Email_Debts7');
                break;
            default:
                break;
        }

    }

    return { handleSendNotification };
}

export default SendNotificationDebts;