import React, { Fragment } from 'react';
import NVD3Chart from 'react-nvd3';
import { TrainingText, DivAdvanceGoals, QuestionsFlex } from '../../assets/styles/Training.styled';
import {
  Col12,
  Container,
  Col6,
  DivContainerTextGoal,
} from '../common/common.styled';

const SectionAdvanceCollaborative = ({
  datum, avance, tiempodeAhorro, goal, mesesConcluidos = 0, totalAhorro = 0, integrantesAhorro = [],
}) => (
      <Fragment>
         <DivAdvanceGoals w='90%' ww='100%' mt="26px" h="auto">
            <QuestionsFlex>
               <div style={{ width: '48%', margin: 'auto' }}>
                  <NVD3Chart
                     id="chart"
                     showLegend={false}
                     showLabels={false}
                     height={118}
                     type="pieChart"
                     datum={datum}
                     x="key"
                     y="y"
                     labelThreshold={0.05}
                     donut
                     donutRatio={0.5}
                     labelType="percent"
                     containerStyle={{
                       marginTop: '0', display: 'flex', justifyContent: 'left', alignItems: 'left',
                     }}
                  />
                  <TrainingText className="roboto" color="#07210A" align="center" size="14px" sizew="14px" lh="120%" weight="500">
                     Su avance {avance < 100 ? avance : 100} {avance !== '0%' ? '%' : ''}
                  </TrainingText>
               </div>
               <div style={{ width: '52%', margin: 'auto' }}>
                  <TrainingText className="roboto" color="#07210A" align="left" size="16px" sizew="16px" lh="120%" weight="bold" bot="5px">
                     {goal}
                  </TrainingText>
                  <TrainingText className="roboto" color="#615959" align="left" size="14px" sizew="14px" lh="120%" weight="500">
                     {integrantesAhorro.length} {integrantesAhorro.length > 1 ? 'integrantes' : 'integrante'} de meta
                  </TrainingText>
                  <TrainingText className="roboto" color="#07210A" align="left" size="16px" sizew="16px" lh="120%" weight="bold" bot="5px">
                     {mesesConcluidos} de {tiempodeAhorro} {tiempodeAhorro > 1 ? 'meses' : 'mes'}
                  </TrainingText>
                  <TrainingText className="roboto" color="#615959" align="left" size="14px" sizew="14px" lh="120%" weight="500" bot="0">
                     Tiempo para meta
                  </TrainingText>
               </div>
            </QuestionsFlex>
            <Container paddingMobile="0 20px" mleft="20px" float="auto 20px" id="DivPrincipal" width="90%" mb="2rem" widthMobile="100%" mtMobile="1rem">
               <Col6 padding="0" display="flex" mt="initial" jc="left">
                  <Container width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem">
                     <Col12 ptl="0" display="flex" mt="initial">
                        <DivContainerTextGoal ta="left" fw="600" width="auto" className="cabin">
                           Ahorro total:
                        </DivContainerTextGoal>
                     </Col12>
                  </Container>
               </Col6>

               <Col6 display="flex" mt="initial" jc="right">
                  <Container width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem">
                     <Col12 display="flex" mt="initial">
                        <DivContainerTextGoal ta="right" fw="600" width="auto" className="cabin">
                           {totalAhorro}
                        </DivContainerTextGoal>
                     </Col12>
                  </Container>
               </Col6>
            </Container>
            {integrantesAhorro.map((item) => (
                  <div key={item.name} style={{ margin: '2px 20px' }} className="tablagen">
                     <div className="fila">
                        <div className="col" style={{ width: '50px' }}>
                           <div style={item.style} className="mobcub"></div>
                        </div>
                        <div className="col">
                           <div style={{
                             height: '50px', minWidth: '60%', width: 'auto', margin: '0 0 0 10px',
                           }} className="fs-16">
                              {item.name}
                           </div>
                        </div>
                        <div className="col itemAhorro">{item.ahorro}</div>
                     </div>
                  </div>
            ))}
         </DivAdvanceGoals>
      </Fragment>
);

export default SectionAdvanceCollaborative;
