import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WOlayout from '../../components/layouts/WOlayout';
import Logo from '../../assets/img/anacoach/CoruLogoB.png';
import Coppel from '../../assets/img/anacoach/Coppel.png';
import CardsCoppel from '../../assets/img/anacoach/CardsCoppel.svg';
import ManoCoppel from '../../assets/img/anacoach/ManoCoppel.svg';
import { Button } from './LpCoppelOTP';
import {
  Container1,
  Subtitle,
  Title2,
} from '../../components/common/Home.styled';
import { Container } from '../../components/common/common.styled';

const LpCoppelSuccess = () => {
  const [status, setStatus] = useState(0);
  useEffect(() => {
    const status = sessionStorage.getItem('CoppelStatus');
    if (status) setStatus(parseInt(status));
  }, []);
  /** El estatus se determina en 0 como erroneo, 1 como rechazado y 2 como exitoso */
  const titulos = [
    'Hubo un error al hacer tu trámite',
    'Lo sentimos, no podemos ofrecerte un crédito',
    '¡Felicidades tu crédito ha sido aprobado!',
  ];
  const messages = [
    'Pero no te preocupes, pronto te contactaremos para apoyarte nuevamente con el trámite',
    'Pero ya estás en nuestra lista y te contactaremos en cuanto podamos para apoyarte nuevamente en el trámite',
    'Pronto te enviaremos tu tarjeta a tu domicilio para que empieces a disfrutar de todos sus beneficios',
  ];
  const images = [CardsCoppel, CardsCoppel, ManoCoppel];
  return (
    <WOlayout>
      <div className="navbar navbar-light bg-light shadow-sm">
        <a href="#" className="navbar-brand">
          <img src={Logo} alt="" style={{ maxWidth: '91px' }} />
        </a>
        <a href="#" className="ml-auto navbar-brand">
          <img
            src={Coppel}
            alt=""
            style={{ maxWidth: '91px', maxHeight: '32px' }}
          />
        </a>
      </div>
      <Container1>
        <Container
          width="100%"
          height="80vh"
          padding="20px"
          boxSizing="border-box"
        >
          <div className="container d-flex align-items-center">
            <div className="row d-flex justify-content-around">
              <div className="col-md-6 d-none d-md-block">
                <img src={images[status]} alt="" className="img-fluid" />
              </div>
              <Contenedor className="col-md-6">
                <Title2
                  topD=" "
                  top=" "
                  aling="left"
                  className="roboto"
                  alignM="center"
                >
                  {titulos[status]}
                </Title2>
                <Subtitle
                  width=" "
                  widthM=" "
                  mleftM=" "
                  marginTop="20px"
                  aling="left"
                  className="roboto"
                  weight="400"
                  alingMm640="center"
                >
                  {messages[status]}
                </Subtitle>
                <img
                  src={images[status]}
                  alt=""
                  className="img-fluid d-block d-md-none"
                  style={{ marginTop: '40px', marginBottom: '40px' }}
                />
                <Button active style={{ marginTop: '20px', maxWidth: '100%' }}>
                  Entendido
                </Button>
              </Contenedor>
            </div>
          </div>
        </Container>
      </Container1>
    </WOlayout>
  );
};

const Contenedor = styled.div`
  border-left: 1px solid #DADADA;
  @media(max-width: 760px) {
    border-left none;
  }
`;

export default LpCoppelSuccess;
