/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  // QuestionsDivs,
  // CardsBtn,
  TrainingQuestion,
  DivLoader,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import editIcon from '../../assets/img/anacoach/editar1.png';

const RecognitionUser = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [motivo, setMotivo] = useState(
    sessionStorage.getItem('Motivoparaobtenerunatarjeta') || '',
  );
  const [editMotivo, seteditMotivo] = useState(motivo !== '');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('rutina recovery', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [form, setForm] = useState({});
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    seteditMotivo(true);
  };

  // se ejecuta cuando es openfield
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    seteditMotivo(true);
  };
  // se ejecuta cuando  la pregunta es de opciones
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);

    setMotivo(val);
    seteditMotivo(true);
    setOrderRutine(nextOrderRoutine);
    gsap.to(window, { duration: 1, scrollTo: '#activeMotivoTarjeta' });
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setMotivo(sessionStorage.getItem('Motivoparaobtenerunatarjeta') || '');
    }
  }, [recoveryRoutine]);

  const handeleIcono = () => {
    seteditMotivo(false);
    setOrderRutine(1);
  };

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <DivLoader>
        <CircularProgress color="inherit" />
      </DivLoader>
    );
  }
  return (
    <Fragment>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="¿Cuál es tu principal motivo para tener una tarjeta de crédito?"
      />
      <TrainingQuestion
        className={
          motivo === '' || (!editMotivo && motivo !== '')
            ? 'motivoCred'
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
        />
      </TrainingQuestion>
      <div id="activeMotivoTarjeta"></div>
      <CardTwo
        classCard={motivo !== '' && editMotivo ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={motivo}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={orderRutine < 3 ? 'iconEditCommon' : 'hidden'}
          src={editIcon}
          onClick={(e) => handeleIcono(e)}
        />
      </CardTwo>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={motivo !== '' && editMotivo ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text={`¡Perfecto ${
          sessionStorage.getItem('FirstName') || ''
        }! Gracias por tus respuestas estoy diseñando tu estrategia personal.`}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={motivo !== '' && editMotivo ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text={`${
          sessionStorage.getItem('FirstName') || ''
        }, conoce tu estrategia personal.`}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={motivo !== '' && editMotivo ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Primero liquida tus deudas. Es mejor que saldes tus deudas a tener un mal historial crediticio. Recuerda de pagar en tiempo y completo. Sobre endeudarte te llevará a pagar más de lo que debías originalmente."
      />
    </Fragment>
  );
};

export default RecognitionUser;
