import Home from "../../anaCoach/Home";
import Feed from "../../anaCoach/Feed";
import FeedOmar from "../../anaCoach/FeedOmar";
import Validate from "../../anaCoach/pantallasInicio/Phone";
import ValidateOtp from "../../anaCoach/pantallasInicio/ValidateOtp";
import PersonalInformation from "../../anaCoach/pantallasInicio/PersonalInformationContainer";
import Login from "../../anaCoach/pantallasInicio/Login";
import EmbajadoresRegistro from "../../anaCoach/embajadores/EmbajadoresRegistro";
import ForgotPass from "../../anaCoach/pantallasInicio/ForgotPassword";
import ResetPass from "../../anaCoach/pantallasInicio/ResetPassword";
import PasswordNew from "../../anaCoach/pantallasInicio/PasswordNew";
import ScoreVideoMessage from "../../components/scoreVideo/ScoreVideoMessage";
import LpHome from "../../anaCoach/LpHome";
import Registro from "../../anaCoach/pantallasInicio/Registro";
import Login2 from "../../anaCoach/pantallasInicio/Login/index";
import ForBusinessLP from "../../anaCoach/ForBusinessLP";
import LPBrasil from "../../anaCoach/LPBrasil";
import LPTuPlan from "../../anaCoach/LPTuPlan";
import LandingPage from "../../components/LandingPage/LandingPage";
import LPCotizadorCI from "../../anaCoach/LPCotizadorCI";
import LPCoach from "../../anaCoach/LPCoach";
import LPMasterClass from "../../anaCoach/LPMasterClass";
import Asegurador from "../../anaCoach/Asegurador";

const Core = {
  Home,
  LpHome,
  Asegurador,
  Feed,
  FeedOmar,
  Validate,
  ValidateOtp,
  PersonalInformation,
  Login,
  EmbajadoresRegistro,
  ForgotPass,
  ResetPass,
  PasswordNew,
  ScoreVideoMessage,
  Registro,
  Login2,
  ForBusinessLP,
  LPBrasil,
  LandingPage,
  LPCotizadorCI,
  LPTuPlan,
  LPMasterClass,
  LPCoach,
};

export default Core;
