/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import '../../assets/styles/Home.css';
import {
  QuestionsDivs,
  CardsBtn,
  TrainingQuestion,
  TrainingDivSection,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';

const DatosBancariosPl = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const anchop = window.screen.width;
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (contResponse === 2) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
              break;
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [dondeRecibe, setDondeRecibe] = useState(
    sessionStorage.getItem('TipoCuentaDeposito') || '',
  );
  const [numeroTarjeta, setTarjeta] = useState(
    sessionStorage.getItem('TarjetaDeposito') || '',
  );
  const [clabeInter, setClabeInter] = useState(
    sessionStorage.getItem('ClabeDeposito') || '',
  );
  // modal banks
  const [form, setForm] = useState({
    numeroTarjeta: sessionStorage.getItem('TarjetaDeposito') || '',
    clabeInter: sessionStorage.getItem('ClabeDeposito') || '',
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'clabeInter':
        validClabeInter(e.target.name, e.target.value, e.target.id);
        break;
      case 'numeroTarjeta':
        validNumTarjeta(e.target.name, e.target.value, e.target.id);
        break;
      default:
        break;
    }
  };

  const [texterror] = useState({
    numeroTarjeta: '',
    clabeInter: '',
  });

  const [error] = useState({
    numeroTarjeta: false,
    clabeInter: false,
  });

  const validClabeInter = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    if (value.length !== 18 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu clabe';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const validNumTarjeta = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    if (value.length !== 16 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu número de tarjeta';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setDondeRecibe(val.value);
        setEditTipoCuenta(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeDigitosDep' });
        break;
      default:
        // console.log('default break handleChangeCatOption case 2');
        break;
    }
  };

  const handleChangeDeposito = (typeDeposit) => {
    if (typeDeposit === 'tarjeta' && form.numeroTarjeta.length === 16) {
      setEditTarjetaDep(true);
      setTarjeta(form.numeroTarjeta);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
      const click = `ContinuarTarjetaDebito_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else if (form.clabeInter) {
      setEditClabeDep(true);
      setClabeInter(form.clabeInter);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
      const click = `ContinuarClabeInterbancaria_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const tarjetaDeposito = sessionStorage.getItem('TarjetaDeposito') || '';
      const clabeBancaria = sessionStorage.getItem('ClabeDeposito') || '';
      setDondeRecibe(sessionStorage.getItem('TipoCuentaDeposito') || '');
      setTarjeta(tarjetaDeposito);
      setClabeInter(clabeBancaria);

      setForm({
        ...form,
        numeroTarjeta: tarjetaDeposito,
        clabeInter: clabeBancaria,
      });
      if (clabeBancaria !== '' || tarjetaDeposito !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editTipoCuenta, setEditTipoCuenta] = useState('');
  const [editTarjetaDep, setEditTarjetaDep] = useState('');
  const [editClabeDep, setEditClabeDep] = useState('');
  const handleChangeIcon = (json) => {
    setEditTipoCuenta(false);
    setEditTarjetaDep(false);
    setEditClabeDep(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [lapizDBancarios, setLapizDBancarios] = useState(
    sessionStorage.getItem('Ocupacion') || '',
  );
  useEffect(() => {
    if (lapizDBancarios !== '') {
      setLapizDBancarios('Quitar');
    }
  }, [lapizDBancarios]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine && dondeRecibe === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeDatosBancarios' });
      }
    }, 4000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        {(() => {
          console.log(orderRutine, currentRoutine, dondeRecibe);
        })()}
        {!loaderWait ? (
          <Fragment>
            <div id="activeDatosBancarios"></div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={
                orderRutine === currentRoutine || dondeRecibe !== ''
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="En caso de que tu crédito sea aprobado ¿Dónde prefieres recibir el depósito de tu crédito?"
              hora={hora}
            />
            <TrainingQuestion
              className={
                (dondeRecibe === ''
                  || (lapiz[0].routine === 9 && lapiz[0].active === 1))
                && editTipoCuenta !== true
                  ? ''
                  : 'hidden'
              }
            >
              {orderRutine === currentRoutine ? (
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="0-0"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
              ) : null}
            </TrainingQuestion>
          </Fragment>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        )}
        <div id="activeDigitosDep"></div>
        <DivRespDP>
          <img
            alt="editIcon"
            className={
              dondeRecibe !== ''
              && (editTipoCuenta === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
                ? 'iconEdit1'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 9, active: 1 })}
          />
          <CardTwo
            classCard={
              dondeRecibe !== ''
              && (editTipoCuenta === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={dondeRecibe}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>

        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard={
            dondeRecibe === 'Tarjeta de débito'
            && (editTipoCuenta === true
              || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="Tarjeta en la que prefieres recibir tu depósito."
          hora={hora}
        />
        <div className="marginComentLgSuccess1">
          <Container
            width="80%"
            widthMobile="90%"
            displayweb={
              ((dondeRecibe === 'Tarjeta de débito' && numeroTarjeta === '')
                || (dondeRecibe !== 'Clabe interbancaria'
                  && lapiz[0].routine === 9
                  && lapiz[0].active === 2))
              && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
                || editTipoCuenta === true)
              && editTarjetaDep !== true
                ? 'flex'
                : 'none'
            }
            displaymobile={
              ((dondeRecibe === 'Tarjeta de débito' && numeroTarjeta === '')
                || (dondeRecibe !== 'Clabe interbancaria'
                  && lapiz[0].routine === 9
                  && lapiz[0].active === 2))
              && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
                || editTipoCuenta === true)
              && editTarjetaDep !== true
                ? 'flex'
                : 'none'
            }
          >
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="27px"
            />
            <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
              <TrainingDivSection
                hew="auto"
                paddw="26px"
                mtw="8%"
                wiw="100%"
                wixs="100%"
                back="#F3F3F3"
                backw="#F3F3F3"
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"
                mb="20px"
              >
                <TrainingQuestion
                  wiw="100%"
                  mt="0px"
                  className={
                    ((dondeRecibe === 'Tarjeta de débito'
                      && numeroTarjeta === '')
                      || (dondeRecibe !== 'Clabe interbancaria'
                        && lapiz[0].routine === 9
                        && lapiz[0].active === 2))
                    && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
                      || editTipoCuenta === true)
                    && editTarjetaDep !== true
                      ? ''
                      : 'hidden'
                  }
                >
                  <QuestionsDivs
                    padd="0"
                    txta="left"
                    width="100%"
                    widthweb="100%"
                    hei="auto"
                    margin="0 auto auto"
                    bc="#F3F3F3"
                  >
                    <form>
                      {orderRutine === currentRoutine ? (
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="1-1"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                        />
                      ) : null}
                      <CardsBtn
                        type="button"
                        min_w="100%"
                        min_web="100%"
                        mtop="0px"
                        mtopw="0px"
                        onClick={() => handleChangeDeposito('tarjeta')}
                      >
                        Continuar
                      </CardsBtn>
                    </form>
                  </QuestionsDivs>
                </TrainingQuestion>
              </TrainingDivSection>
            </Col7>
          </Container>
        </div>
        <DivRespDP>
          <img
            alt="editIcon"
            className={
              numeroTarjeta !== ''
              && (editTarjetaDep === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
                ? 'iconEdit1'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 9, active: 2 })}
          />
          <CardTwo
            classCard={
              numeroTarjeta !== ''
              && (editTarjetaDep === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={numeroTarjeta}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard={
            dondeRecibe === 'Clabe interbancaria'
            && (editTipoCuenta === true
              || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="Clabe interbancaria en la que prefieres recibir tu depósito."
          hora={hora}
        />
        <div className="marginComentLgSuccess1">
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mtw="20px"
            wiw="60%"
            wixs="90%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="0%"
            he="auto"
            mb="20px"
            mlw="18%"
            className={
              ((dondeRecibe === 'Clabe interbancaria' && clabeInter === '')
                || (dondeRecibe !== 'Tarjeta de débito'
                  && lapiz[0].routine === 9
                  && lapiz[0].active === 2))
              && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
                || editTipoCuenta === true)
              && editClabeDep !== true
                ? ''
                : 'hidden'
            }
          >
            <TrainingQuestion
              wiw="100%"
              mt="0px"
              className={
                ((dondeRecibe === 'Clabe interbancaria' && clabeInter === '')
                  || (dondeRecibe !== 'Tarjeta de débito'
                    && lapiz[0].routine === 9
                    && lapiz[0].active === 2))
                && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
                  || editTipoCuenta === true)
                && editClabeDep !== true
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsDivs
                padd="0"
                txta="left"
                width="100%"
                widthweb="100%"
                hei="auto"
                margin="0 auto auto"
                bc="#fafafa"
              >
                <form>
                  {orderRutine >= currentRoutine ? (
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="2-2"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                    />
                  ) : null}
                  <CardsBtn
                    mtopw="0px"
                    mtopmd="0px"
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    onClick={() => handleChangeDeposito('clabe')}
                  >
                    Continuar
                  </CardsBtn>
                </form>
              </QuestionsDivs>
            </TrainingQuestion>
          </TrainingDivSection>
        </div>
        <DivRespDP>
          <img
            alt="editIcon"
            className={
              clabeInter !== ''
              && (editClabeDep === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
                ? 'iconEdit1'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 9, active: 2 })}
          />
          <CardTwo
            classCard={
              clabeInter !== ''
              && (editClabeDep === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={clabeInter}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
      </div>
  );
};

export default DatosBancariosPl;
