import React from 'react';
import './styles/customStepLayout.css';
import logoC from '../../../assets/img/anacoach/logoCoach.png';

/*

- type : Defines space size of columns

- customColor : Custom color for WO

- coachImg : If exists, show coach image in first column, if not, show just coru icon

- stepClass : Class for custom step component css file

- appearAnimation : animation to display when step arrives

- appearAnimation : animation to display when step goes

- isActive : decide wich animation display

- canGoBack : decide if goBack button must be shown

- backText : Text for goBack button. Only displayed if 'canGoBack' is true

- backHandlerOption : option filter to call function

*/

const CustomStepLayout = ({
  children,
  type,
  customColor,
  coachImg,
  stepClass,
  appearAnimation,
  disappearAnimation,
  isActive,
  canGoBack,
  backText,
  backHandler,
  backHandlerOption,
}) => {
  const coachLoader = () => {
    if (coachImg) {
      return (
        <div
          className="img-container"
          style={{ borderColor: customColor || 'red' }}
        >
          <img src={coachImg} />

          <div
            className="coru-logo"
            style={{ backgroundColor: customColor || 'red' }}
          >
            <img src={logoC} />
          </div>
        </div>
      );
    }

    return (
      <div
        className="logo-coach"
        style={{ backgroundColor: customColor || 'red' }}
      >
        <img src={logoC} />
      </div>
    );
  };

  const goBackButton = () => {
    if (canGoBack) {
      return (
        <div className="go-back">
          <button
            onClick={
              backHandlerOption && backHandler
                ? () => backHandler(backHandlerOption)
                : () => {}
            }
            className="custom-text-button"
          >
            {'<'} {backText || 'Anterior'}
          </button>
        </div>
      );
    }
  };

  return (
    <div className={`custom-step-layout ${type || 'default'}`}>
      <div className="coach-column">
        <div className="coach-c">{coachLoader()}</div>
      </div>

      <div
        className={`content-column 
            ${stepClass || 'default'} 
            ${isActive ? appearAnimation : disappearAnimation}`}
      >
        {goBackButton()}

        {children}

        <div className="triangle"></div>
      </div>
    </div>
  );
};

export default CustomStepLayout;
