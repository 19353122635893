import styled from "styled-components";
import { imageComponent } from "../../components/common/common";

export const Input = styled.div`
  width: 100%;
  height: 50px;
  padding: ${(props) => props.padding || '10px 20px'};
  font-size: 14px;
  outline: none;
  background-color: white;
  border: solid 1px #cdcdcd;
  border-radius: 16px;
  color: #252525;
  &:focus {
    border: solid 1px #F64282;
  }
`;

export const TrainingMain = styled.div`
  align-items: center;
  background-color: ${(props) => props.backgroundColor || '#fafafa'};
  font-family: system-ui;
  height: 100%;
  margin: auto;
  width: 100%;
  color: #110e20;
  margin-bottom: 90px;
  margin-top: ${(props) => props.mt || ""};
  @media (min-width: 1024px) {
    width: ${(props) => props.width || "100%"};
    margin-top: ${(props) => props.mtw || ""};
  }
  @media (min-width: 1500px) {
    width: ${(props) => props.width || "100%"};
    margin-top: ${(props) => props.mtw || ""};
  }
`;
export const TrainingAnimateDiv = styled.div`
  margin: -70px 0 5% 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 25% 10% 10% 10%;
  min-height: ${(props) => props.minh || "405px"};

  @media (min-width: 768px) {
    margin: -162px 0 0 0;
    height: 715px;
    padding: 20% 20% 0 0;
    text-align: center;
  }
`;
export const TrainingAnimateFunnel = styled.div`
  margin: ${(props) => props.margin || " 0 0 5% 0"};
  background: ${(props) => props.bcolor || "#91D770"};
  background-image: ${(props) => props.bimage || " "};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: ${(props) => props.pmob || "25% 10% 10% 10%"};
  min-height: ${(props) => props.minh || "309px"};
  background-position: right;
  background-size: contain;
  height: 309px;
  border-radius: ${(props) => props.borderRadius || " "};
  @media (min-width: 768px) {
    margin: 0 0 5px 0;
    height: ${(props) => props.hweb || "250px"};
    padding: 0;
    text-align: center;
    min-height: ${(props) => props.minhweb || "250px"};
  }
`;
export const TrainigTamLeg = styled.div`
  height: ${(props) => props.hm || "100px"};
  width: ${(props) => props.w || "100%"};
  padding-top: ${(props) => props.pt || ""};
  padding-left: ${(props) => props.pl || ""};
  padding-bottom: ${(props) => props.pb || ""};
  background: ${(props) => props.bc || ""};
  position: ${(props) => props.pos || "absolute"};
  @media (max-width: 339px) {
    width: ${(props) => props.wweb || "100%"};
    padding-top: ${(props) => props.ptxs || ""};
    padding-left: ${(props) => props.plxs || ""};
    padding-bottom: ${(props) => props.pbxs || ""};
    position: ${(props) => props.posxs || "absolute"};
  }
  @media (min-width: 767px) and (max-width: 768px) {
    width: ${(props) => props.wweb768 || "100%"};
    padding-top: ${(props) => props.pt768 || ""};
    padding-left: ${(props) => props.plm768 || ""};
    padding-bottom: ${(props) => props.pb768 || ""};
    position: ${(props) => props.pos768 || "absolute"};
  }
  @media (max-width: 766px) {
    width: ${(props) => props.wwebmd || "100%"};
    padding-top: ${(props) => props.ptmd || ""};
    padding-left: ${(props) => props.plmd || ""};
    padding-bottom: ${(props) => props.pbmd || ""};
    position: ${(props) => props.posmd || "absolute"};
  }
  @media (min-width: 769px) {
    width: ${(props) => props.wweb || "100%"};
    padding-top: ${(props) => props.ptw || ""};
    padding-left: ${(props) => props.plw || ""};
    padding-bottom: ${(props) => props.pbw || ""};
    position: ${(props) => props.posw || "absolute"};
  }
  @media (min-width: 1768px) {
    width: ${(props) => props.wwebxl || "100%"};
    padding-top: ${(props) => props.ptxl || ""};
    padding-left: ${(props) => props.plxl || ""};
    padding-bottom: ${(props) => props.pbxl || ""};
    position: ${(props) => props.posxl || "absolute"};
  }
  @media (min-width: 2330px) {
    width: ${(props) => props.wwebxl || "100%"};
    padding-top: ${(props) => props.ptxxl || ""};
    padding-left: ${(props) => props.plxxl || ""};
    padding-bottom: ${(props) => props.pbxxl || ""};
    position: ${(props) => props.posxxl || "absolute"};
  }
  @media (min-width: 2698px) {
    width: ${(props) => props.wwebsmart || "100%"};
    padding-top: ${(props) => props.ptsmart || ""};
    padding-left: ${(props) => props.plmsmart || ""};
    padding-bottom: ${(props) => props.pbsmart || ""};
    position: ${(props) => props.possmart || "absolute"};
  }
`;
export const TrainigTam = styled.div`
  height: ${(props) => props.hm || "100px"};
  width: ${(props) => props.w || "100%"};
  padding-top: ${(props) => props.pt || ""};
  padding-left: ${(props) => props.pl || ""};
  padding-bottom: ${(props) => props.pb || ""};
  background: ${(props) => props.bc || ""};
  position: ${(props) => props.pos || "absolute"};
  @media (max-width: 768px) and (min-width: 768px) {
    padding-top: ${(props) => props.ptmd || ""};
    padding-left: ${(props) => props.plemd || ""};
  }
  @media (max-width: 767px) {
    width: ${(props) => props.widthsm || "100%"};
    padding-top: ${(props) => props.ptsm || ""};
    padding-left: ${(props) => props.plesm || ""};
    padding-bottom: ${(props) => props.pbtsm || ""};
    position: ${(props) => props.possm || "absolute"};
  }
  @media (max-width: 751px) {
    width: ${(props) => props.widthxs || "100%"};
    padding-top: ${(props) => props.ptxs || ""};
    padding-left: ${(props) => props.plexs || ""};
    padding-bottom: ${(props) => props.pbtxs || ""};
    position: ${(props) => props.posxs || "absolute"};
  }
  @media (min-width: 769px) {
    width: ${(props) => props.wweb || "100%"};
    padding-top: ${(props) => props.ptw || ""};
    padding-left: ${(props) => props.plw || ""};
    padding-bottom: ${(props) => props.pbw || ""};
    position: ${(props) => props.posw || "absolute"};
  }
  @media (min-width: 1651px) {
    padding-top: ${(props) => props.ptw2 || props.ptwlg || ""};
  }
  @media (min-width: 1816px) {
    padding-top: ${(props) => props.ptw3 || props.ptwxl || ""};
  }

  @media (min-width: 2400px) {
    width: ${(props) => props.wweb || "100%"};
    padding-top: ${(props) => props.ptwxl || ""};
    padding-left: ${(props) => props.plwxl || ""};
    padding-bottom: ${(props) => props.pbwxl || ""};
    position: ${(props) => props.posw || "absolute"};
  }
`;
export const TrainingCreateImg = styled.div`
  height: ${(props) => props.hm || "100px"};
  width: ${(props) => props.w || "100%"};
  margin-top: ${(props) => props.mt || ""};
  margin-left: ${(props) => props.ml || ""};
  margin-bottom: ${(props) => props.mb || ""};
  margin-right: ${(props) => props.mr || ""};
  background: ${(props) => props.bc || ""};
  left: ${(props) => props.le || ""};
  position: absolute;
  border-radius: 3px;
  box-shadow: ${(props) => props.bsha || ""};
  @media (min-width: 768px) {
    height: ${(props) => props.hw || "100px"};
    width: ${(props) => props.ww || "100%"};
    margin-top: ${(props) => props.mtw || ""};
    margin-left: ${(props) => props.mlw || ""};
    margin-bottom: ${(props) => props.mbw || ""};
    margin-right: ${(props) => props.mrw || ""};
    box-shadow: ${(props) => props.bshaw || ""};
  }
`;
export const TrainignVector = styled.div`
  height: ${(props) => props.hm || "100px"};
  width: ${(props) => props.w || "100%"};
  position: absolute;
  border-radius: 3px;
  position: absolute;
`;
export const TrainingLine = styled.div`
  border-radius: ${(props) => props.br || ""};
  margin-left: 0;
  margin-right: ${(props) => props.mr || "0"};
  margin-top: ${(props) => props.mt || ""};
  width: ${(props) => props.w || "100%"};
  height: 6px;
  position: fixed;
  background: ${(props) => props.bcolor || "#a5a5a5"};
  opacity: ${(props) => props.op || ""};
  z-index: 1;
  @media (min-width: 768px) {
    margin-top: ${(props) => props.mtw || ""};
    width: ${(props) => props.wweb || "100%"};
  }
`;
export const TrainingSectionFlex = styled.div`
  margin: auto;
  justify-content: ${(props) => props.justify || ""};
  align-items: ${(props) => props.aItems || ""};
  flex-direction: ${(props) => props.fdirection || ""};
  @media (min-width: 768px) {
    display: ${(props) => props.display || ""};
  }
`;
export const TrainingSectionFlexCarrusel = styled.div`
  width: ${(props) => props.wiMd || "90%"};
  margin-left: ${(props) => props.marginLeftMd || "auto"};
  margin-right: ${(props) => props.marginRightMd || "auto"};
  margin-top: ${(props) => props.marginTopMd || "20px"};
  @media (min-width: 768px) {
    display: ${(props) => props.display || ""};
    width: ${(props) => props.wiLg || "61%"};
    margin-left: ${(props) => props.marginLeftLg || "20.5%"};
    margin-top: ${(props) => props.marginTopLg || "20px"};
  }
`;
export const TrainingSubtitles = styled.h2`
  color: ${(props) => props.color || "#110e20"};
  font-size: ${(props) => props.size || "16px"};
  text-align: ${(props) => props.align || "center"};
  font-weight: ${(props) => props.fw || "600"};
  margin: ${(props) => props.margin || ""};
  width: ${(props) => props.width || ""};
  margin-bottom: ${(props) => props.mbotm || ""};
  @media (min-width: 768px) {
    font-size: ${(props) => props.sizew || "20px"};
    text-align: ${(props) => props.alignweb || ""};
  }
`;
export const Training_Subtitles_Phones = styled.h2`
  color: #110e20;
  font-size: ${(props) => props.size || "1.5em"};
  text-align: ${(props) => props.align || "justify"};
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "1.8em"};
    margin-top: auto;
    margin-bottom: auto;
  }
`;
export const ImageSections = styled(imageComponent)`
  max-width: 100%;
  padding-bottom: ${(props) => props.pb || ""};
  padding-top: ${(props) => props.pt || ""};
  border: ${(props) => props.border || ""};
  border-radius: ${(props) => props.borderRadius || ""};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.hmob || ""};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-top: ${(props) => props.mt || ""};
  margin-left: ${(props) => props.ml || ""};
  background: ${(props) => props.back || ""};
  padding-left: ${(props) => props.pl || ""};
  padding-right: ${(props) => props.pr || ""};
  @media (min-width: 768px) {
    height: ${(props) => props.heightweb || "auto"};
    width: ${(props) => props.widthweb || ""};
    padding-left: ${(props) => props.plw || ""};
    padding-right: ${(props) => props.prw || ""};
    max-height: ${(props) => props.mhw || ""};
  }
  @media (max-width: 768px) {
    margin-top: ${(props) => props.mt_xs || ""};
    max-height: ${(props) => props.mhm || ""};
    padding-top: ${(props) => props.ptm || props.pt || ""};
  }
`;
export const TrainingContainAna = styled.div`
  text-align: center;
  background: ${(props) => props.back || "#fafafa"};

  @media (min-width: 768px) {
    margin-bottom: ${(props) => props.bottom || ""};
  }
  @media (max-width: 767px) {
    display: ${(props) => props.displayM || ""};
  }
`;
export const DivPrincipalBeta = styled.div`
  text-align: ${(props) => props.ta || "center"};
  @media (min-width: 768px) {
    text-align: ${(props) => props.taw || "center"};
  }
`;
export const ImagesAnaSucces = styled(imageComponent)`
   z-index: ${(props) => props.zindex || ""};
   position: ${(props) => props.position || ""};
   width: ${(props) => props.width || "40px"};

}
`;

export const ImagesModal = styled(imageComponent)`
  max-width: ${(props) => props.maxwidth || "100%"};
  z-index: ${(props) => props.zindex || ""};
  width: ${(props) => props.width || "95px"};
  height: ${(props) => props.height || ""};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || "absolute"};
  margin-bottom: ${(props) => props.bottom || ""};
  background: ${(props) => props.back || ""};
  margin: ${(props) => props.margin || ""};
  margin-left: ${(props) => props.left || "-12%"};
  margin-right: ${(props) => props.rigth || ""};
  margin-top: ${(props) => props.top || "4%"};
  border: ${(props) => props.border || ""};
  box-sizing: ${(props) => props.bs || ""};
  padding: ${(props) => props.padd || ""};
  border-radius: ${(props) => props.br || ""};
  left: ${(props) => props.le || ""};
  z-index: ${(props) => props.zi || ""};
  top: ${(props) => props.topm || ""};
  right: ${(props) => props.rightm || ""};
  bottom: ${(props) => props.bottomm || ""};
  max-height: ${(props) => props.maxh || ""};
  @media (min-width: 769px) {
    margin: ${(props) => props.marginw || ""};
    margin-top: ${(props) => props.topweb || ""};
    margin-left: ${(props) => props.leftw || "-4%"};
    width: ${(props) => props.widthw || ""};
    position: ${(props) => props.positionw || ""};
    margin-right: ${(props) => props.rigthw || ""};
    max-width: ${(props) => props.maxwidthw || "100%"};
    z-index: ${(props) => props.ziw || ""};
    left: ${(props) => props.le || ""};
    top: ${(props) => props.topmd || ""};
    right: ${(props) => props.rightmd || ""};
    bottom: ${(props) => props.bottommd || ""};
  }
  @media (min-width: 768px) and (max-width: 768px) {
    margin-left: ${(props) => props.mleft768 || ""};
    margin-top: ${(props) => props.top768 || ""};
    width: ${(props) => props.width768 || ""};
    height: ${(props) => props.height768 || ""};
  }
  @media (max-width: 767px) {
    margin-left: ${(props) => props.mleftsm || ""};
    margin-top: ${(props) => props.topsmm || ""};
    width: ${(props) => props.widthsm || ""};
    height: ${(props) => props.heightsm || ""};
    left: ${(props) => props.leftsm || ""};
    top: ${(props) => props.topsm || ""};
    right: ${(props) => props.rightsm || ""};
    bottom: ${(props) => props.bottomsm || ""};
  }

  @media (max-width: 370px) {
    margin-top: ${(props) => props.topm || ""};
    margin-right: ${(props) => props.rigthm || ""};
    left: ${(props) => props.leftxs || ""};
  }
  @media (max-width: 600px) {
    position: ${(props) => props.position600 || ""};
  }
`;
export const Images = styled(imageComponent)`
  max-width: ${(props) => props.maxwidth || "100%"};
  z-index: ${(props) => props.zindex || ""};
  width: ${(props) => props.width || "95px"};
  height: ${(props) => props.height || ""};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || "absolute"};
  margin-bottom: ${(props) => props.bottom || ""};
  background: ${(props) => props.back || ""};
  margin: ${(props) => props.margin || ""};
  margin-left: ${(props) => props.left || "-12%"};
  margin-right: ${(props) => props.rigth || ""};
  margin-top: ${(props) => props.top || "4%"};
  border: ${(props) => props.border || ""};
  box-sizing: ${(props) => props.bs || ""};
  padding: ${(props) => props.padd || ""};
  border-radius: ${(props) => props.br || ""};
  left: ${(props) => props.le || ""};
  z-index: ${(props) => props.zi || ""};
  top: ${(props) => props.topm || ""};
  right: ${(props) => props.rightm || ""};
  bottom: ${(props) => props.bottomm || ""};
  max-height: ${(props) => props.maxh || ""};
  @media (min-width: 769px) {
    margin: ${(props) => props.marginw || ""};
    margin-top: ${(props) => props.topweb || ""};
    margin-left: ${(props) => props.leftw || "-4%"};
    width: ${(props) => props.widthw || ""};
    position: ${(props) => props.positionw || ""};
    margin-right: ${(props) => props.rigthw || ""};
    max-width: ${(props) => props.maxwidthw || "100%"};
    z-index: ${(props) => props.ziw || ""};
    left: ${(props) => props.le || ""};
    top: ${(props) => props.topmd || ""};
    right: ${(props) => props.rightmd || ""};
    bottom: ${(props) => props.bottommd || ""};
  }
  @media (min-width: 768px) and (max-width: 768px) {
    margin-left: ${(props) => props.mleft768 || ""};
    margin-top: ${(props) => props.top768 || ""};
    width: ${(props) => props.width768 || ""};
    height: ${(props) => props.height768 || ""};
  }
  @media (max-width: 767px) {
    margin-left: ${(props) => props.mleftsm || ""};
    width: ${(props) => props.widthsm || ""};
    height: ${(props) => props.heightsm || ""};
    left: ${(props) => props.leftsm || ""};
    top: ${(props) => props.topsm || ""};
    right: ${(props) => props.rightsm || ""};
    bottom: ${(props) => props.bottomsm || ""};
  }

  @media (max-width: 370px) {
    margin-top: ${(props) => props.topm || ""};
    margin-right: ${(props) => props.rigthm || ""};
    left: ${(props) => props.leftxs || ""};
  }
  @media (max-width: 600px) {
    position: ${(props) => props.position600 || ""};
  }
`;
export const CoruCoachSucces = styled.div`
  position: ${(props) => props.position || "absolute"};
  z-index: ${(props) => props.zindex || ""};
  bottom: ${(props) => props.pBottom || " "};
  width: ${(props) => props.width || ""};
  height: ${(props) => props.height || ""};
  background: ${(props) => props.bcolor || "#BFDD6A"};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-top: ${(props) => props.marginTop || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  @media (min-width: 1350px) {
    margin-left: ${(props) => props.leftrxl || ""};
  }
  @media (min-width: 2500px) {
    margin-left: ${(props) => props.leftr25 || ""};
  }
  @media (max-width: 769px) {
    margin-left: ${(props) => props.leftD1 || ""};
  }
  @media (max-width: 768px) {
    margin-left: ${(props) => props.leftD2 || ""};
    margin-top: ${(props) => props.marginTop768 || ""};
    width: ${(props) => props.width768 || ""};
    height: ${(props) => props.height768 || ""};
  }
  @media (max-width: 500px) {
    margin-left: ${(props) => props.leftM2 || ""};
    margin-top: ${(props) => props.marginM3 || ""};
  }
  @media (max-width: 600px) {
    margin-left: ${(props) => props.leftM || ""};
    margin-top: ${(props) => props.marginM || ""};
  }
`;
export const CoruCoach = styled.div`
  position: ${(props) => props.position || "absolute"};
  z-index: ${(props) => props.zindex || ""};
  bottom: ${(props) => props.pBottom || " "};
  width: ${(props) => props.width || "25px"};
  height: ${(props) => props.height || "25px"};
  top: ${(props) => props.top1 || ""};
  background: ${(props) => props.bcolor || "#BFDD6A"};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-top: ${(props) => props.marginTop || "-7%"};
  margin-left: ${(props) => props.marginLeft || "55%"};
  @media (max-width: 769px) {
    margin-left: ${(props) => props.leftD1 || "52%"};
    margin-top: ${(props) => props.marginTopmd || ""};
  }
  @media (max-width: 767px) {
    margin-left: ${(props) => props.leftD2 || "52%"};
    margin-top: ${(props) => props.marginTop || "-7%"};
  }
  @media (max-width: 600px) {
    margin-left: ${(props) => props.leftM || "11%"};
    margin-top: ${(props) => props.marginM || "-30%"};
  }
  @media (max-width: 402px) {
    margin-left: ${(props) => props.leftM2 || "11%"};
    margin-top: ${(props) => props.marginM3 || "-7%"};
  }
`;
export const ImageCard = styled(imageComponent)`
  max-width: 100%;
  width: 70%;
  margin-top: 4%;
  margin-left: 15%;
  margin-right: 15%;
  @media (min-width: 768px) {
    width: 30%;
    margin-top: -20%;
    margin-left: 0;
    margin-right: 0;
    float: right;
  }
`;

export const ImageCard4D = styled(imageComponent)`
  max-width: 100%;
  width: 70%;
  margin-top: 4%;
  margin-left: 15%;
  margin-right: 15%;
  @media (min-width: 768px) {
    width: 30%;
    margin-top: 3%;
    margin-left: 33%;
    margin-right: auto;
  }
`;
export const TrainingText = styled.p`
  color: ${(props) => props.color || "#110e20"};
  font-size: ${(props) => props.size || "1.2em"};
  padding: ${(props) => props.padding || "0px"};
  font-weight: ${(props) => props.weight || "400"};
  text-align: ${(props) => props.align || "left"};
  padding-bottom: ${(props) => props.pb || ""};
  margin-bottom: ${(props) => props.bot || ""};
  margin-top: ${(props) => props.top || ""};
  margin-left: ${(props) => props.left || ""};
  line-height: ${(props) => props.lh || "1.6"};
  text-transform: ${(props) => props.trans || ""};
  cursor: ${(props) => props.cursor || ""};
  letter-spacing: ${(props) => props.ls || ""};
  width: ${(props) => props.widthm || ""};
  @media (min-width: 768px) {
    width: ${(props) => props.widthW || ""};
    font-size: ${(props) => props.sizew || "16px"};
    text-align: ${(props) => props.alignweb || ""};
    min-height: ${(props) => props.mheight || ""};
    margin-left: ${(props) => props.leftxl || ""};
  }
  @media (max-width: 320px) {
    font-size: ${(props) => props.sizexs || "16px"};
  }
`;

export const DivSelects = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`;

export const DivText = styled.div`
  color: ${(props) => props.color || "#110e20"};
  font-size: ${(props) => props.size || "1.2em"};
  font-weight: ${(props) => props.weight || "400"};
  text-align: ${(props) => props.align || "left"};
  margin-bottom: ${(props) => props.bot || ""};
  margin-top: ${(props) => props.top || ""};
  margin-left: ${(props) => props.left || ""};
  line-height: ${(props) => props.lh || "1.6"};
  text-transform: ${(props) => props.trans || ""};
  cursor: ${(props) => props.cursor || ""};
  letter-spacing: ${(props) => props.ls || ""};
  width: ${(props) => props.widthm || ""};
  @media (min-width: 768px) {
    width: ${(props) => props.widthW || ""};
    font-size: ${(props) => props.sizew || "16px"};
    text-align: ${(props) => props.alignweb || ""};
    min-height: ${(props) => props.mheight || ""};
    margin-left: ${(props) => props.leftxl || ""};
  }
  @media (max-width: 320px) {
    font-size: ${(props) => props.sizexs || "1em"};
  }
`;
export const DivRespDP = styled.div`
  margin-right: ${(props) => props.marginRight || "-0.7%"};
  /* margin-right: -5.5%; */
  margin-left: -5%;
  @media (min-width: 769px) {
    margin-right: auto;
    margin-left: auto;
  }
`;
export const TrainingBenef = styled.div`
  width: ${(props) => props.width || " "};
  color: ${(props) => props.color || "#252525"};
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight || "normal"};
  text-align: ${(props) => props.align || "left"};
  margin-bottom: ${(props) => props.bot || ""};
  margin-top: ${(props) => props.mt || ""};
  padding-bottom: ${(props) => props.pb || ""};
  padding-left: ${(props) => props.pl || ""};
  padding-top: ${(props) => props.pt || ""};
  margin-left: ${(props) => props.ml || ""};
  line-height: ${(props) => props.lh || "150%"};
  text-transform: ${(props) => props.trans || ""};
  cursor: ${(props) => props.cursor || ""};
  letter-spacing: ${(props) => props.ls || "-0.2px"};
  height: ${(props) => props.he || ""};
  border-bottom: ${(props) => props.borderBottom || ""};
  border-bottom-style: ${(props) => props.bbs || ""};
  border-bottom-width: ${(props) => props.bbw || ""};
  border-bottom-color: ${(props) => props.bbc || ""};
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "1.3em"};
    text-align: ${(props) => props.alignweb || ""};
    min-height: ${(props) => props.mheight || ""};
    border-bottom-style: ${(props) => props.bbsw || ""};
    padding-left: ${(props) => props.plw || ""};
    height: ${(props) => props.hew || ""};
  }
`;
export const TrainingDivSection = styled.div`
  background: ${(props) => props.back || "#ffffff"};
  padding: ${(props) => props.padding || "10%"};
  margin-left: ${(props) => props.ml || " "};
  margin-right: ${(props) => props.mr || "auto"};
  width: ${(props) => props.wi || ""};
  height: ${(props) => props.he || ""};
  margin-bottom: ${(props) => props.mb || "auto"};
  margin-top: ${(props) => props.mt || "20px"};
  border: ${(props) => props.border || ""};
  border-radius: ${(props) => props.br || ""};
  box-shadow: ${(props) => props.bsha || ""};
  display: ${(props) => props.display || ""};
  cursor: ${(props) => props.cursor || ""};
  font-size: ${(props) => props.fontSize || ""};
  @media (min-width: 769px) {
    padding: ${(props) => props.paddw || "3%"};
    background: ${(props) => props.backw || "#ffffff"};
    margin-left: ${(props) => props.mlw || "auto"};
    margin-right: ${(props) => props.mrw || "auto"};
    width: ${(props) => props.wiw || ""};
    height: ${(props) => props.hew || ""};
    margin-bottom: ${(props) => props.mbw || "auto"};
    margin-top: ${(props) => props.mtw || "20px"};
    border-radius: ${(props) => props.brw || ""};
    box-shadow: ${(props) => props.bshaw || ""};
  }
  @media (max-width: 767px) {
    width: ${(props) => props.wixs || ""};
    margin-left: ${(props) => props.mlM || "auto"};
    height: ${(props) => props.heSm || ""};
  }
`;
export const TrainingDivSection2 = styled.div`
  background: ${(props) => props.back || "#fff"};
  padding: ${(props) => props.paddi || "5%"};
  width: ${(props) => props.widthn || ""};
  margin: ${(props) => props.mn || ""};
  margin-bottom: ${(props) => props.mb || " "};
  margin-top: ${(props) => props.mt || " "};
  margin-left: ${(props) => props.ml || " "};
  padding-left: ${(props) => props.pl || " "};
  padding-right: ${(props) => props.pr || " "};
  padding-top: ${(props) => props.pt || " "};
  padding-bottom: ${(props) => props.pb || " "};
  @media (min-width: 768px) {
    width: ${(props) => props.wiw || ""};
    padding: ${(props) => props.paddiw || "1px 10% 3% 10%"};
    margin: ${(props) => props.mw || ""};
  }
  @media (max-width: 769px) {
    margin-bottom: ${(props) => props.mbm || ""};
  }
`;

export const TrainingDivSectionPymes = styled.div`
  width: ${(props) => props.wiw320 || ""};
  padding: ${(props) => props.paddiw320 || "5%"};
  margin: ${(props) => props.mw320 || ""};
  margin-left: auto;
  margin-right: auto;
  margin-left: ${(props) => props.ml320 || " "};
  background: ${(props) => props.back320 || "#F3F3F3  "};
  margin-top: ${(props) => props.mt320 || "30px "};
  @media (min-width: 769px) {
    width: ${(props) => props.wiw || ""};
    padding: ${(props) => props.paddiw || "4%"};
    margin: ${(props) => props.mw || ""};
  }
`;
export const TrainingSection = styled.div`
  background: ${(props) => props.back || "#fff"};
  padding: ${(props) => props.paddi || ""};
  width: ${(props) => props.widthn || ""};
  margin: ${(props) => props.mn || ""};
  margin-bottom: ${(props) => props.mb || " "};
  margin-top: ${(props) => props.mt || " "};
  margin-left: ${(props) => props.ml || " "};
  padding-left: ${(props) => props.pl || " "};
  padding-right: ${(props) => props.pr || " "};
  padding-top: ${(props) => props.pt || " "};
  padding-bottom: ${(props) => props.pb || " "};
  @media (min-width: 768px) {
    width: ${(props) => props.wiw768 || ""};
    padding: ${(props) => props.paddiw768 || ""};
    padding-left: ${(props) => props.padL768 || ""};
    margin: ${(props) => props.mw768 || ""};
  }
`;

export const TrainingSectionCard = styled.div`
  display: ${(props) => props.dis || "  "};
  flex-direction: ${(props) => props.flDir || "  "};
  background: ${(props) => props.back || "  "};
  padding: ${(props) => props.paddi || " "};
  width: ${(props) => props.wid || ""};
  margin: ${(props) => props.mn || ""};
  margin-bottom: ${(props) => props.mb || " "};
  margin-top: ${(props) => props.mt || " "};
  margin-left: ${(props) => props.ml || " "};
  padding-left: ${(props) => props.pl || " "};
  padding-right: ${(props) => props.pr || " "};
  padding-top: ${(props) => props.pt || " "};
  padding-bottom: ${(props) => props.pb || " "};
`;
export const AnaContainer = styled.img`
  display: ${(props) => props.dis || "  "};
  flex-direction: ${(props) => props.flDir || "  "};
  background: ${(props) => props.back || "  "};
  padding: ${(props) => props.paddi || " "};
  width: ${(props) => props.wid || ""};
  height: ${(props) => props.hei || ""};
  margin: ${(props) => props.mn || ""};
  margin-bottom: ${(props) => props.mb || " "};
  margin-top: ${(props) => props.mt || " "};
  margin-left: ${(props) => props.ml || " "};
  padding-left: ${(props) => props.pl || " "};
  padding-right: ${(props) => props.pr || " "};
  padding-top: ${(props) => props.pt || " "};
  padding-bottom: ${(props) => props.pb || " "};
`;

export const TrainingSectionGray = styled.div`
   background: ${(props) => props.back || "#f5f5f5"};
   margin: ${(props) => props.mn || ""};
   margin-top: ${(props) => props.mtop || ""};
   width: ${(props) => props.widthn || ""};
   padding: ${(props) => props.paddinglG || ""};
   @media (min-width: 768px) {
      width: ${(props) => props.wiw || ""};
      margin: ${(props) => props.mw || ""};
      padding: ${(props) => props.padding || ""};
      padding-top: ${(props) => props.paddTop || ""};
`;
export const TrainingSectionGray1 = styled.div`
  background: ${(props) => props.back || "#f5f5f5"};
  margin-top: ${(props) => props.mtop || ""};
  width: ${(props) => props.widthn || ""};
  @media (min-width: 1000px) {
    width: ${(props) => props.wiw || ""};
    margin: ${(props) => props.mw || ""};
  }
  @media (max-width: 999px) {
    width: ${(props) => props.wilg || ""};
    margin-left: ${(props) => props.mllg || "auto"};
    margin-right: ${(props) => props.mrlg || "auto"};
  }
  @media (max-width: 768px) {
    width: ${(props) => props.wimd || ""};
    margin-left: ${(props) => props.mlmd || "auto"};
    margin-right: ${(props) => props.mrmd || "auto"};
  }
`;

export const ImgPL = styled.div`
  @media (min-width: 768px) {
    min-height: 63px;
  }
`;
export const ContainerVideo = styled.div`
  margin-top: ${(props) => props.mt || "30px"};
  @media (max-width: 768px) {
    margin-top: ${(props) => props.mtw || "0px"};
  }
`;
/*Preguntas y respuestas*/
export const TrainingQuestion = styled.div`
  width: ${(props) => props.width || "90%"};
  margin: ${(props) => props.margin || "auto"};
  margin-top: ${(props) => props.mt || "20px"};
  height: ${(props) => props.height || ""};
  overflow: ${(props) => props.flow || ""};
  margin-left: ${(props) => props.leftg || ""};
  margin-right: ${(props) => props.rigthg || ""};
  position: ${(props) => props.pos || ""};
  z-index: ${(props) => props.index || ""};
  padding: ${(props) => props.paddingSm || ""};
  @media (min-width: 768px) {
    width: ${(props) => props.wiw || "100%"};
    margin-top: ${(props) => props.mtw || ""};
    margin-left: ${(props) => props.mLg || ""};
    padding: ${(props) => props.paddingLg || ""};
  }
`;

export const SliderOrder = styled.div`
  width: ${(props) => props.width || "56.5%"};
  margin: auto;
  margin-top: ${(props) => props.mt || "20px"};
  margin-left: ${(props) => props.leftg || "19.5%"};

  @media (min-width: 769px) {
    width: ${(props) => props.wiw || " 56.5%"};
    margin-top: ${(props) => props.mtw || ""};
    margin-left: ${(props) => props.mLg || "19.5%"};
  }

  @media (max-width: 320px) {
    width: ${(props) => props.wiw || " "};
    margin-left: ${(props) => props.mLg || ""};
  }
`;

/*border: 1px solid #e5e5e5;
box-shadow: rgba(0,0,0,0.15) 0px 2px 8px !important;*/
export const QuestionsDivs = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.bc || "#fff"};
  display: ${(props) => props.display || ""};
  height: ${(props) => props.hei || "52px"};
  margin: ${(props) => props.margin || "8px"};
  padding: ${(props) => props.padd || "5px"};
  text-align: ${(props) => props.txta || "center"};
  width: ${(props) => props.width || "45%"};
  /*box-shadow: ${(props) => props.bs || "0px 2px 8px rgba(0,0,0,0.08)"};*/
  margin-left: ${(props) => props.marginL || ""};
  margin-right: ${(props) => props.margiR || ""};
  cursor: pointer;
  @media (min-width: 768px) {
    width: ${(props) => props.widthweb || ""};
    margin-left: ${(props) => props.marginleftLg || ""};
  }
  @media (max-width: 767px) {
    margin-left: ${(props) => props.marginLmd || ""};
    width: ${(props) => props.widthxs || ""};
  }
`;
export const QuestionsDivsbar = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.bc || "#fff"};
  display: ${(props) => props.display || ""};
  height: ${(props) => props.hei || "auto"};
  margin: ${(props) => props.margin || "0 0 20px 18%;"};
  padding: ${(props) => props.padd || "5px"};
  text-align: ${(props) => props.txta || "center"};
  width: ${(props) => props.width || "60%"};
  box-shadow: ${(props) => props.bs || "0px 2px 8px rgba(0,0,0,0.08)"};
  margin-left: ${(props) => props.marginL || ""};
  margin-right: ${(props) => props.margiR || ""};
  cursor: pointer;
  @media (min-width: 769px) {
    width: ${(props) => props.widthweb || "60%"};
  }
  @media (max-width: 768px) {
    margin-left: ${(props) => props.marginLmd || "0%"};
    width: ${(props) => props.width || "100%"};
  }
`;
export const QuestionsDivsbarVideo = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.bc || "rgba(255, 255, 255, 0.7)"};
  display: ${(props) => props.display || ""};
  height: ${(props) => props.hei || "auto"};
  margin: ${(props) => props.margin || "0 0 8px 20%;"};
  padding: ${(props) => props.padd || "5px"};
  text-align: ${(props) => props.txta || "center"};
  width: ${(props) => props.width || "60%"};
  box-shadow: ${(props) => props.bs || "0px 2px 8px rgba(0,0,0,0.08)"};
  margin-left: ${(props) => props.marginL || ""};
  margin-right: ${(props) => props.margiR || ""};
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  @media (min-width: 769px) {
    width: ${(props) => props.widthweb || "60%"};
  }
  @media (max-width: 768px) {
    margin-left: ${(props) => props.marginLmd || "0%"};
    width: ${(props) => props.width || "100%"};
  }
  &:hover {
    border-style: solid;
    border-width: 1px;
    border-color: #f64282;
  }
`;
export const QuestionsFlex = styled.div`
  display: ${(props) => props.display || "flex"};
  align-items: center;
  vertical-align: middle;
  line-height: 25px;
  padding: ${(props) => props.paddingSm || ""};
`;
export const QuestionsText = styled.div`
  margin: ${(props) => props.margin || ""};
  font-size: ${(props) => props.size || "20px"};
  font-weight: ${(props) => props.weight || "600"};
  padding: ${(props) => props.padd || ""};
  text-align: ${(props) => props.ta || ""};
`;
export const QuestionsTextVideo = styled.div`
  margin: ${(props) => props.margin || ""};
  font-size: ${(props) => props.size || "20px"};
  font-weight: ${(props) => props.weight || "600"};
  padding: ${(props) => props.padd || ""};
  text-align: ${(props) => props.ta || ""};
  color: ${(props) => props.color || "#07210A"};
`;

export const QuestionOptionNumber = styled.div`
  float: left;
  width: 20px;
  background-color: #f64282;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
`;
/*Tarjetas actual*/
export const Cardsmain = styled.div`
  display: flex;
  text-align: center;
  width: 62%;
  overflow-y: scroll;
  margin-left: 16.5%;
`;
export const CardsModal = styled.div`
  text-align: center;
  width: 100%;
  @media (min-width: 769px) {
    width: ${(props) => props.widthweb || ""};
    margin: ${(props) => props.marginXl || "auto"};
    margin-left: ${(props) => props.marginLeftXl || ""};
  }
`;
export const CardsU = styled.div`
  background-color: #fff;
  border-style: solid;
  border-color: #eeeeee;
  border-width: 1px;
  border-radius: 3px;
  height: 205px;
  margin: 12px;
  padding: 12px;
  min-width: ${(props) => props.mwi || "192px"};
`;
export const CardsUModal = styled.div`
  background-color: transparent;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  box-sizing: border-box;
  height: ${(props) => props.height || "225px"};
  margin-bottom: 16px;
  padding: 24px 20px;
  min-width: 192px;
  display: inherit;
  width: ${(props) => props.width || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  @media (max-width: 768px) {
    height: ${(props) => props.mheight || "240px"};
    width: ${(props) => props.widthxs || ""};
    margin-left: ${(props) => props.marginLeftxs || ""};
    margin-right: ${(props) => props.marginrightxs || ""};
  }
`;
export const LongDash = styled.div`
  position: ${(props) => props.position || ""};
  width: ${(props) => props.width || "20%"};
  height: ${(props) => props.height || "5px"};
  left: ${(props) => props.left || "0px"};
  margin: ${(props) => props.margin || " "};
  margin-top: ${(props) => props.top || "0px"};
  margin-bottom: 20px;
  background: #76df44;
  border-radius: 4px;
  background-color: ${(props) => props.backColor || "#FFFFFF"};
`;
export const CardsTxt = styled.div`
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight || ""};
  margin-top: ${(props) => props.mt || "5px"};
  margin-left: ${(props) => props.ml || ""};
  margin-bottom: ${(props) => props.mb || "5px"};
  letter-spacing: -0.3px;
  line-height: ${(props) => props.lineH || "120%"};
  color: ${(props) => props.color || ""};
  max-height: ${(props) => props.maxh || ""};
  min-height: ${(props) => props.minh || ""};
  text-align: ${(props) => props.ta || ""};
  @media (min-width: 768px) {
    font-size: ${(props) => props.sizeweb || ""};
  }
`;
export const DivDefineWidth = styled.div`
  height: ${(props) => props.minh || ""};
`;
export const CardsBtn = styled.button`
  color: ${(props) => props.color || "#fff"};
  background: ${(props) => props.back || "#F64282"};
  border-radius: ${(props) => props.border || "3px"};
  border: ${(props) => props.border || "none"};
  padding: ${(props) => props.padd || "12px 15px"};
  width: ${(props) => props.windthG || ""};
  min-width: ${(props) => props.min_w || "145px"};
  max-width: ${(props) => props.max_w || ""};
  z-index: ${(props) => props.zindex || ""};
  font-size: ${(props) => props.font || "16px"};
  transition: 0.3s;
  margin-top: ${(props) => props.mtop || "20px"};
  margin-left: ${(props) => props.mleft || ""};
  margin-right: ${(props) => props.mright || ""};
  font-weight: ${(props) => props.weight || ""};
  height: ${(props) => props.heigth || ""};
  box-shadow: ${(props) => props.shadow || ""};
  border-color: ${(props) => props.bordcolor || ""};
  border-style: ${(props) => props.bordstyle || ""};
  :hover {
    background: ${(props) => props.backHover || ""} !important;
    background-color: ${(props) => props.backColor || ""} !important;
  }
  :focus {
    outline: none !important;
  }
  @media (min-width: 769px) {
    min-width: ${(props) => props.min_web || "250px"};
    margin-left: ${(props) => props.mleftweb || "auto"};
    margin-right: ${(props) => props.mrightweb || "auto"};
    margin-top: ${(props) => props.mtopw || "20px"};
    font-weight: ${(props) => props.weight || ""};
    margin-bottom: ${(props) => props.mbottom || "auto"};
    width: ${(props) => props.windthLg || ""};
  }
  @media (max-width: 768px) {
    min-width: ${(props) => props.minmd || ""};
    margin-left: ${(props) => props.mleftmd || "auto"};
    margin-right: ${(props) => props.mrightmd || "auto"};
    margin-top: ${(props) => props.mtopmd || "20px"};
    font-weight: ${(props) => props.weightmd || ""};
    margin-bottom: ${(props) => props.mbottomM || "auto"};
    width: ${(props) => props.windthmd || "100%"};
  }
  @media (max-width: 345px) {
    min-width: ${(props) => props.min_mini || ""};
    margin-left: ${(props) => props.mleftmini || ""};
    margin-right: ${(props) => props.mrightmini || ""};
  }
`;

export const FreemiumButton = styled(CardsBtn)`
  width: 90%;
  border: 1px solid #a6a6a6;
  &:hover {
    background: #a6a6a6;
    border: 1px solid #a6a6a6;
  }
`;

export const CardsBtn1 = styled.button`
  color: ${(props) => props.color || "#fff"};
  background: ${(props) => props.back || "#F64282"};
  border-radius: 3px;
  border: ${(props) => props.border || "none"};
  padding: ${(props) => props.padd || "12px 15px"};
  width: ${(props) => props.windthG || "60%"};
  z-index: ${(props) => props.zindex || ""};
  font-size: 16px;
  transition: 0.3s;
  margin-top: ${(props) => props.mtop || "20px"};
  margin-left: ${(props) => props.mleft || "18%"};
  box-shadow: ${(props) => props.shadow || ""};
  @media (max-width: 768px) {
    margin-left: ${(props) => props.mleftmd || "auto"};
    margin-right: ${(props) => props.mrightmd || "auto"};
    margin-top: ${(props) => props.mtopmd || "20px"};
    font-weight: ${(props) => props.weightmd || ""};
    margin-bottom: auto;
    width: ${(props) => props.windthmd || "90%"};
  }
`;

export const CardsAnchorBtn = styled.a`
  color: ${(props) => props.color || "#fff"};
  background: ${(props) => props.back || "#F64282"};
  border-radius: 3px;
  border: ${(props) => props.border || "none"};
  padding: ${(props) => props.padd || "12px 15px"};
  min-width: ${(props) => props.min_w || "145px"};
  max-width: ${(props) => props.max_w || ""};
  font-size: 16px;
  transition: 0.3s;
  margin-top: ${(props) => props.mtop || ""};
  margin-left: ${(props) => props.mleft || ""};
  margin-right: ${(props) => props.mright || ""};
  font-weight: ${(props) => props.weight || ""};
  height: ${(props) => props.heigth || ""};
  box-shadow: ${(props) => props.shadow || ""};
  display: ${(props) => props.shadow || "block"};
  text-align: ${(props) => props.shadow || "center"};
  @media (min-width: 768px) {
    min-width: ${(props) => props.min_web || "auto"};
    margin-left: ${(props) => props.mleftweb || "auto"};
    margin-right: ${(props) => props.mrightweb || "auto"};
    margin-top: ${(props) => props.mtopw || "1em"};
    font-weight: ${(props) => props.weight || ""};
    margin-bottom: auto;
  }
  @media (max-width: 345px) {
    min-width: ${(props) => props.min_mini || ""};
    margin-left: ${(props) => props.mleftmini || ""};
    margin-right: ${(props) => props.mrightmini || ""};
  }
`;

export const DivFlex = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "space-around"};
  width: ${(props) => props.wid || "100%"};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.margintop || ""};

  @media (min-width: 768px) {
    width: ${(props) => props.widthweb || ""};
    margin: ${(props) => props.marweb || ""};
    margin-top: ${(props) => props.margintoplg || ""};
  }
`;
export const RPExtraIncome = styled.div`
  display: flex;
  overflow-y: scroll;
`;
export const RPFastTrack = styled.div`
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    overflow-y: auto;
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const ModalDivFlex = styled.div`
  width: ${(props) => props.width || ""};
  text-align: ${(props) => props.align || ""};
`;
export const DivStyle = styled.div`
  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "1.6em"};
  text-align: ${(props) => props.align || "center"};
  font-weight: ${(props) => props.weight || "400"};
  margin-bottom: ${(props) => props.bott || ""};
  margin-top: ${(props) => props.mt || ""};
  min-height: ${(props) => props.mh || ""};
  max-height: ${(props) => props.mxh || ""};
  padding: ${(props) => props.padd || ""};
  color: ${(props) => props.color || ""};
  cursor: ${(props) => props.cursor || ""};
`;
export const DivGray = styled.div`
  background-color: ${(props) => props.bcolor || "#f5f5f5"};
  padding: ${(props) => props.padd || "5%"};
  width: ${(props) => props.wid || ""};
  margin-left: ${(props) => props.malf || ""};
`;
/*Resultados*/
export const Resultado = styled.div`
  display: ${(props) => props.disp || ""};
  justify-content: ${(props) => props.justcont || ""};
  background-color: ${(props) => props.bcolor || ""};
  border-radius: 4px;
  border: ${(props) => props.border || "1px solid rgba(219,219,219,1)"};
  text-align: ${(props) => props.ta || "center"};
  min-width: ${(props) => props.MinWidth || "300px"};
  width: ${(props) => props.wid || "300px"};
  min-height: ${(props) => props.minh || "626px"};
  height: ${(props) => props.hei || ""};
  margin: ${(props) => props.marginGene || "5px auto auto auto"};
  padding: ${(props) => props.padd || "15px 5px"};
  margin-bottom: ${(props) => props.mbot || "15px"};
  margin-top: ${(props) => props.marTopD || ""};
  margin-right: ${(props) => props.marri || "10px"};
  margin-left: ${(props) => props.marL || " "};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  @media (min-width: 768px) {
    margin-top: ${(props) => props.marTop || ""};
    width: ${(props) => props.widthLg || ""};
    min-height: ${(props) => props.minhweb || ""};
    margin-left: ${(props) => props.marLeftLg || ""};
    margin-right: ${(props) => props.marriweb || "10px"};
    background-color: ${(props) => props.bcolorweb || ""};
    height: ${(props) => props.Hweb || ""};
    margin: ${(props) => props.marginweb || "5px auto auto auto"};
    max-width: ${(props) => props.maxwidthLg || ""};
  }
`;
export const Resultado1 = styled.div`
  background-color: ${(props) => props.bcolor || ""};
  border-radius: 4px;
  border: ${(props) => props.border || "1px solid rgba(219,219,219,1)"};
  text-align: ${(props) => props.ta || "center"};
  min-width: 200px;
  width: ${(props) => props.wid || "300px"};
  min-height: ${(props) => props.minh || "626px"};
  height: ${(props) => props.hei || ""};
  margin: 5px auto auto auto;
  padding: ${(props) => props.padd || "15px 5px"};
  margin-bottom: ${(props) => props.mbot || "15px"};
  margin-right: ${(props) => props.marri || "10px"};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  @media (min-width: 768px) {
    min-height: ${(props) => props.minhweb || ""};
    margin-right: ${(props) => props.marriweb || "10px"};
    background-color: ${(props) => props.bcolorweb || ""};
    height: ${(props) => props.Hweb || ""};
  }
`;
export const DivLoader = styled.div`
  text-align: ${(props) => props.ta || "center"};
  margin: ${(props) => props.margin || "12px"};
  width: ${(props) => props.wi || ""};
  height: ${(props) => props.he || ""};
  background-color: ${(props) => props.bcolor || "#a6a6a61f"};
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  justify-content: left;
  align-items: center;
  margin-left: ${(props) => props.ml || "0"};
  margin-top: ${(props) => props.mt || " "};
  @media (min-width: 768px) {
    margin: ${(props) => props.marginw || "12px"};
    margin-left: ${(props) => props.mlw || "0"};
    margin-top: ${(props) => props.mtw || "0"};
  }
`;
export const DivBtn = styled.div`
  margin: auto;
  text-align: center;
  width: 70%;
`;
/*margin-left: -20px;
margin-right: -20px;*/
export const RPProductos = styled.div`
  display: flex;
  overflow-y: ${(props) => props.scroll || "scroll"};
  min-height: 535px;
  @media (min-width: 768px) {
    overflow: ${(props) => props.scrollw || "hidden"};
  }
`;
/*margin-left: -20px;
    margin-right: -20px; */
export const RPProductosPL = styled.div`
  display: flex;
  overflow-y: ${(props) => props.scroll || "scroll"};
  @media (min-width: 768px) {
    overflow: ${(props) => props.scrollw || "hidden"};
  }
`;
export const ErrorDiv = styled.div`
  color: red;
  text-align: ${(props) => props.align || ""};
  margin-top: 0px;
  margin-bottom: 20px;
`;
export const ErrorDiv1 = styled.div`
  color: red;
  text-align: ${(props) => props.align || ""};
  margin-top: ${(props) => props.top || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-left: ${(props) => props.left || ""};
`;
export const SugerenciaDiv = styled.div`
  color: #28a745;
`;

export const GraphicDiv = styled.div`
  height: ${(props) => props.h || "272px"};
`;
export const GraphicContainer = styled.div`
  margin: ${(props) => props.m || "35px 35px"};
`;
export const GraphicInvTest = styled.div`
  max-height: ${(props) => props.mh || "272px"};
  height: ${(props) => props.h || "272px"};
  max-width: ${(props) => props.mw || "13.35px"};
  background-color: ${(props) => props.bcolor || "#C4EEDF"};
  margin-left: ${(props) => props.ml || "80px"};
`;

export const GraphicInvTestColor = styled.div`
  max-height: ${(props) => props.mh || "272px"};
  height: ${(props) => props.h || ""};
  max-width: ${(props) => props.mw || "13.35px"};
  background-color: ${(props) => props.bcolor || ""};
  margin-left: ${(props) => props.ml || "80px"};
  margin-top: ${(props) => props.mt || ""};
`;

export const GraphicText = styled.p`
  color: ${(props) => props.color || "#110e20"};
  font-size: ${(props) => props.size || "12px"};
  font-weight: ${(props) => props.weight || "400"};
  text-align: ${(props) => props.align || "left"};
  margin-bottom: ${(props) => props.bot || ""};
  margin-top: ${(props) => props.top || ""};
  margin-left: ${(props) => props.ml || ""};
  margin-right: ${(props) => props.mr || ""};
  line-height: ${(props) => props.lh || "1.6"};
  text-transform: ${(props) => props.trans || ""};
  cursor: ${(props) => props.cursor || ""};
  letter-spacing: ${(props) => props.ls || ""};
  position: absolute;
  @media (min-width: 768px) {
    font-size: ${(props) => props.sizew || "12px"};
    text-align: ${(props) => props.alignweb || ""};
    min-height: ${(props) => props.mheight || ""};
  }
`;

export const BaseTermometer = styled.div`
  height: ${(props) => props.mh || "40.78px"};
  width: ${(props) => props.mw || "40.78px"};
  background-color: ${(props) => props.bcolor || "#C4EEDF"};
  margin-left: ${(props) => props.ml || "80px"};
  margin-top: ${(props) => props.mt || "auto"};
  margin-right: ${(props) => props.mr || "auto"};
  border-radius: 50%;
`;

export const pattern = styled.div`
  position: "absolute";
  width: "31px";
  height: "40px";
  background: url(image.png);
`;

export const DivAdvanceGoals = styled.div`
   background-color: ${(props) => props.bcolor || "#FFFFFF"};
   height: ${(props) => props.h || "150px"};
   width: ${(props) => props.w || "90%"};}
   margin-left: ${(props) => props.marginLGeneric || ""};
   margin: ${(props) => props.margin || "auto"};
   margin-top: ${(props) => props.mt || ""};
   border-radius: 4px;
   box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
   padding-bottom: ${(props) => props.pb || ""};
   @media (min-width: 768px) {
      height: ${(props) => props.h || "150px"};
      width: ${(props) => props.ww || "80%"};
      margin-left: ${(props) => props.marginL || ""};
   }
`;

export const LineGreen = styled.div`
  width: 10px;
  background-color: #91d770;
  height: 132px;
  border-radius: 4px 0 0 4px;
`;

export const BorderTask = styled.div`
   border-style: ${(props) => props.bs || "solid"}
   border-radius: 4px;
   border-color: #c5c5c5;
   border-width: 1px;
   margin: ${(props) => props.margin || "10px 0"};
   padding: 8px;
   width: ${(props) => props.width || "65%"};
`;

export const StatusTask = styled.div`
  border-style: solid;
  border-radius: 4px;
  border-color: ${(props) => props.bColor || ""};
  border-width: 1px;
  background-color: ${(props) => props.Color || ""};
  height: max-content;
  margin: auto;
  padding: ${(props) => props.padding || "17px 35px"};
  width: ${(props) => props.width || "94px"};
  font-weight: ${(props) => props.fw || ""};
`;

export const DivShadow = styled.div`
  height: ${(props) => props.height || ""};
  width: ${(props) => props.width || ""};
  box-shadow: ${(props) => props.boxShadow || ""};
  border-radius: ${(props) => props.borderR || ""};
  margin-top: ${(props) => props.marginTop || ""};
  text-align: ${(props) => props.txtAlign || ""};
  @media (max-width: 769px) {
    margin-top: ${(props) => props.marginTopmd || ""};
  }
  @media (max-width: 767px) {
    margin-top: ${(props) => props.marginTop || "-7%"};
  }
  @media (max-width: 600px) {
    margin-top: ${(props) => props.marginM || "-15%"};
  }
  @media (max-width: 402px) {
    margin-top: ${(props) => props.marginM3 || "-7%"};
  }
`;

export const DivHeader_ValueMoney = styled.div`
  margin-top: ${(props) => props.marginTop || ""};
  margin-bottom: ${(props) => props.marginBottom || ""};
  @media (min-width: 768px) {
    margin-top: ${(props) => props.marginTopWeb || "0px"};
    margin-bottom: ${(props) => props.marginBottomWeb || "0px"};
  }
`;

export const GeneralTableS = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableTd = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: ${(props) => props.fw || "normal"};
`;

export const TableTh = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: ${(props) => props.color || "#4CAF50"};
  color: #000000;
`;

export const TableTr = styled.tr`
  &:hover {
    background-color: #ddd;
  }
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const DivTamFlex = styled.div`
  width: ${(props) => props.width || "15%"};
  text-align: ${(props) => props.ta || "left"};
  margin-right: ${(props) => props.mr || ""};
`;

export const DivSelectionCC = styled.div`
  width: 402px;
  margin: auto 18%;
  min-height: 60px;
  max-height: 75px;
  padding: 15px;
  background-color: #917aff;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const DivSelectionLogros = styled.div`
  width: 402px;
  margin: auto 18%;
  height: 60px;
  padding: ${(props) => props.padd || "15px"};
  background-color: ${(props) => props.backColor || "#A370FF"};
  color: #ffffff;
  font-size: ${(props) => props.fs || "20px"};
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const DivSelectionFilter = styled.div`
  width: 402px;
  margin: ${(props) => props.margin || "auto 18%"};
  height: ${(props) => props.hei || "60px"};
  padding: ${(props) => props.padd || "15px"};
  background-color: transparent;
  display: ${(props) => props.display || "flex"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
  border-style: solid;
  border-width: 1px;
  border-color: #a370ff;
  column-count: ${(props) => props.columns || ""};
  @media (max-width: 768px) {
    width: 100%;
    margin: ${(props) => props.marginm || "0"};
    padding: 15px 10px;
  }
`;

export const DivSelectionFilter2 = styled.div`
  width: 402px;
  margin: ${(props) => props.margin || "auto 18%"};
  height: ${(props) => props.hei || "60px"};
  padding: ${(props) => props.padd || "15px"};
  background-color: transparent;
  display: ${(props) => props.display || "flex"};
  border-style: ${(props) => props.bs || "solid"};
  border-width: 1px;
  border-color: #a370ff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => props.jc || "space-between"};
  @media (max-width: 768px) {
    width: 100%;
    margin: ${(props) => props.marginm || "0"};
    padding: ${(props) => props.paddM || "15px"};
  }
  @media (max-width: 321px) {
    padding: ${(props) => props.paddMM || "15px"};
  }
`;

export const DivFiltersName = styled.div`
  min-width: ${(props) => props.wi || "105px"};
  background: ${(props) => props.backColor || "#FFFFFF"};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.bcolor || "#A370FF"};
  padding: ${(props) => props.padd || "2px"};
  // filter: drop-shadow(5px 5px 5px #A370FF);
  text-align: center;
  font-weight: normal;
  cursor: ${(props) => props.cursor || "pointer"};
  margin: ${(props) => props.margin || "0"};
  margin-bottom: ${(props) => props.mb || ""};
  color: ${(props) => props.color || ""};
  height: max-content;
  flex: ${(props) => props.flex || "none"};
  @media (max-width: 768px) {
    margin: ${(props) => props.marginM || "0"};
    font-size: 12px;
    min-width: ${(props) => props.wim || "90px"};
  }
  @media (max-width: 412px) {
    margin: ${(props) => props.marginMC || "0"};
  }
  @media (max-width: 376px) {
    margin: ${(props) => props.marginMI || "0"};
  }
  @media (max-width: 361px) {
    font-size: 11px;
    margin: ${(props) => props.marginMG || "0"};
  }
  @media (max-width: 321px) {
    font-size: 9px;
    margin: ${(props) => props.marginMM || "0"};
  }
`;

export const DivFiltersNameText = styled.div`
  width: ${(props) => props.wi || "105px"};
  background: ${(props) => props.backColor || "#FFFFFF"};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.bcolor || "#A370FF"};
  padding: ${(props) => props.padd || "2px"};
  // filter: drop-shadow(5px 5px 5px #A370FF);
  text-align: center;
  font-weight: normal;
  margin: ${(props) => props.margin || "0"};
  margin-bottom: ${(props) => props.mb || ""};
  color: ${(props) => props.color || ""};
  height: max-content;
  flex: ${(props) => props.flex || "none"};
  @media (max-width: 768px) {
    font-size: 12px;
    width: ${(props) => props.wim || "90px"};
  }
  @media (max-width: 361px) {
    font-size: 11px;
  }
  @media (max-width: 321px) {
    font-size: 9px;
  }
`;

export const DivCardsCC = styled.div`
  width: ${(props) => props.width || "402px"};
  margin: ${(props) => props.margin || "auto 18%"};

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
export const CardProdBank = styled.div`
  background: ${(props) => props.bc || ""};
  color: #ffffff;
  margin: -10px -10px 5px -10px;
  height: 30px;
  text-align: center;
  padding: 5px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const ScoreText = styled.p`
  color: ${(props) => props.c || ""};
  font-size: ${(props) => props.fs || ""};
  text-align: ${(props) => props.ta || ""};
  font-weight: ${(props) => props.fw || ""};
  margin-top: ${(props) => props.mt || ""};
  margin-bottom: ${(props) => props.mb || ""};
`;

export const FreemiumCard = styled.div`
  background-color: ${(props) => props.bg || ""};
  border-radius: 4px;
  border: ${(props) => props.border || "1px solid rgba(219,219,219,1)"};
  text-align: ${(props) => props.textAlign || "center"};
  min-width: ${(props) => props.MinWidth || "300px"};
  width: ${(props) => props.width || "300px"};
  min-height: ${(props) => props.minH || "626px"};
  height: ${(props) => props.height || ""};
  margin: ${(props) => props.marginD || "5px auto auto auto"};
  margin-bottom: ${(props) => props.mbot || "15px"};
  margin-right: ${(props) => props.marri || "10px"};
  margin-top: ${(props) => props.mt || "10px"};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  @media (min-width: 768px) {
    margin-top: ${(props) => props.mtM || ""};
    width: ${(props) => props.widthLg || ""};
    min-height: ${(props) => props.minhweb || ""};
    margin-left: ${(props) => props.marLeftLg || ""};
    margin-right: ${(props) => props.marriweb || "10px"};
    background-color: ${(props) => props.bcolorweb || ""};
    height: ${(props) => props.Hweb || ""};
    margin: ${(props) => props.marginweb || "5px auto auto auto"};
  }
`;

export const DivTable = styled.td`
  padding: 6px;
`;

export const DivRecommended = styled.div`
  height: 31px;
  background-color: #22c4ce;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px 15px;
`;

export const DivColProduct = styled.td`
  width: 100%;
  display: ${(props) => props.dis || "flex"};
  padding: ${(props) => props.padd || "20px"};
`;

export const DivInfoProduct = styled.div`
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const DivClickProduct = styled.div`
  cursor: pointer;
`;

export const DivTamProduct = styled.div`
  width: ${(props) => props.wi || "50%"};
  text-align: ${(props) => props.ta || ""};
`;

export const DivTextProduct = styled.p`
  font-weight: ${(props) => props.fw || "bold"};
  text-align: ${(props) => props.ta || "right"};
  font-size: ${(props) => props.fs || "20px"};
  margin-bottom: ${(props) => props.mb || "0"};
`;

export const DivFiltersProduct = styled.div`
  display: block;
  width: 95px;
  height: 98px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;
`;

export const DivFlexProduct = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${(props) => props.mt || "20px"};
  padding: ${(props) => props.padd || "0 20px"};
`;

export const FlexDatProduct = styled.div`
  display: flex;
`;

export const TitleDatProduct = styled.div`
  width: 42%;
  margin-top: auto;
  margin-bottom: auto;
`;

export const InfoVehicle = styled.div`
  @media (max-width: 768px) {
    margin-left: 12px;
  }
`;

export const DisplayFilters = styled.div`
  width: auto;
  background: #ffffff;
  position: absolute;
  z-index: 1;
  margin-left: ${(props) => props.ml || ""};
  @media (max-width: 768px) {
    margin-left: ${(props) => props.mlM || ""};
  }
`;
