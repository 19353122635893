import React, { Fragment, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import "../../assets/styles/Home.css";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import enproceso from "../../assets/img/anacoach/faces-02.png";
import {
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
  // DYNAMIC_GET,
} from "../../mutations";
import HeaderSuccess from "../../components/Training/HeaderSucces";
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingSectionFlex,
} from "../../assets/styles/Training.styled";
import { SeparateSection, Feed_Section } from "../../assets/styles/Feed.styled";
import { ContentHomeSection } from "../../components/common/Home.styled";
import { Container } from "../../assets/styles/Home.styled";
import Recommendations from "../../components/Training/RecommendedTraining";
import CardOne from "../../components/Home/Card1Animate";
import Resultados from "../../components/Training/Success_Result";
import ClickButton from "../../Helpers/HookHelpers";
import WOlayout from "../../components/layouts/WOlayout";
import SectionShare from "../../components/common/ShareSection";
import ProductsHooks from "../../Helpers/HookHelpersProducts";
import ProductsFremium from "../../components/common/ProductsFremium";
import InfoWoMonetizable from "../../components/common/InfoWoMonetizable";
import CreaditeaSuccesPL from "./CrediteaSuccesPL";
import ModalVirality2 from "../../components/common/ModalVirality";
let firstname = "";
let nameClient = "";
let emailClient = "";
let telClient = "";
//prop Creditea
let ingreso = "";

function SuccessPL() {
  const { sendApi } = ProductsHooks();
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [goFeed] = useState(false);
  const [OpenModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenModal(true);
    }, 10000);
  }, []);
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log("Insert finished by Xcoach");
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem("idTraining")}`;
      actions({ action: "ClickButtonCollection", variables: click });
      history.push("/the-training-feed/");
    }
  }, [goFeed]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "SuccessPL",
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    //! se activa api
    const arrayIdProducts = sessionStorage
      .getItem("SelectedProductDetail")
      .split(",");
    const hasApi = arrayIdProducts[3];
    const id = arrayIdProducts[0];
    console.log("id de producto", id);
    console.log(`hasapi ----------->${typeof hasApi}${hasApi}`);
    if (hasApi === "false") {
      console.log(`${hasApi}-----${id}`);
      sendApi({ action: parseInt(id, 10), variables: "" });
    }
  }, []);

  const [top, setTop] = useState(false);
  useEffect(() => {
    if (top === false) {
      document.getElementById("root").scrollIntoView({
        behavior: "smooth",
      });
    } else {
      setTop(true);
    }
  }, [top]);

  const [oneResult, setOneResult] = useState([]);
  useState(() => {
    let detailsProducts = sessionStorage.getItem("SelectedProductDetail");
    if (detailsProducts !== null) {
      detailsProducts = detailsProducts.split(",");
    }
    if (detailsProducts !== null) {
      setOneResult([
        {
          id: detailsProducts[0],
          imgCard: detailsProducts[2],
          titleCard: detailsProducts[1],
          imgresult: enproceso,
          resultado: "Solicitada",
          msgresultado: "",
          info: "No. Folio 12345678",
          txtadicional:
            "La institución realizará el análisis de tu perfil y te notificará si fue aprobado tu crédito. Debes estar pendiente de tu correo y teléfono registrado.",
          documentos: "",
        },
      ]);
    }
  }, []);

  firstname = sessionStorage.getItem("FirstName");
  nameClient = `${sessionStorage.getItem("FirstName")} ${sessionStorage.getItem(
    "FathersLastName"
  )}`;
  emailClient = sessionStorage.getItem("Email1");
  telClient = sessionStorage.getItem("CellPhone");
  const history = useHistory();
  ingreso = sessionStorage.getItem("Ingreso_Mensual_Fijo");

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    }
  );
  const email = sessionStorage.getItem("Email1");
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    const idUser = sessionStorage.getItem("idUser");
    const idTraining = sessionStorage.getItem("idTraining");
    const fromXCoach = sessionStorage.getItem("fromXCoach");
    const idAdviser = sessionStorage.getItem("idAdviser");
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === "true" ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  return (
    <WOlayout
      backgroundColor={"rgb(190, 122, 230)"}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      {OpenModal ? (
        <ModalVirality2 setShowModal={handleOpenModal} showModal={OpenModal} />
      ) : null}{" "}
      <HeaderSuccess
        color1="#E2C2EE"
        color2="#6677E3"
        color3="#6677E3"
        color4="#E2C2EE"
        color5="#8358C2"
        color6="#6677E3"
        color7="#A7FOFA"
        color8="#8358C2"
        color9="#CBBFED"
        color10="#6677E3"
      />
      <TrainingMain mt="0px" mtw="0" className="" id="top">
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            paddiw="2% 10% 3% 10%"
            paddi="22% 5% 5% 5%"
            back="#fafafa"
          >
            <TrainingSubtitles
              size="22px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              ¡Gracias {firstname}!
            </TrainingSubtitles>
            <TrainingText
              size="18px"
              weight="600"
              className="roboto"
              align="center"
              alignweb="center"
              ls="-0.2px"
            >
              Hemos procesado tu solicitud y este es el resultado.
            </TrainingText>
            <br />
            <InfoWoMonetizable
              nameClient={nameClient}
              emailClient={emailClient}
              telClient={telClient}
            />
            <br />
            {/* --------- Creditea Mod ---------- */}
            {/* temp true */}
            {true ? (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CreaditeaSuccesPL ingreso={ingreso}></CreaditeaSuccesPL>
                </div>
                <br />
              </>
            ) : (
              <></>
            )}
            <TrainingSubtitles
              size="20px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              Te interesan las siguientes solicitudes de crédito
            </TrainingSubtitles>
          </TrainingDivSection2>
          <SeparateSection />
        </TrainingSectionGray>
        <TrainingDivSection
          mt="20px"
          back="#fafafa"
          wi="335px"
          padding="0"
          paddw="0"
        >
          <TrainingSectionFlex>
            <Resultados
              idTraining={sessionStorage.getItem("idTraining")}
              resultados={oneResult}
            ></Resultados>
          </TrainingSectionFlex>
        </TrainingDivSection>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            wiw="670px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            <TrainingSectionGray back="#fafafa">
              <ProductsFremium
                idT={parseInt(sessionStorage.getItem("idTraining"))}
              />
            </TrainingSectionGray>

            <SectionShare WO="PrestamosPersonales"></SectionShare>
          </TrainingDivSection2>
        </TrainingSectionGray>
        {recomendaciones ? (
          <Fragment>
            <TrainingSectionGray back="#fafafa">
              <TrainingDivSection2
                wiw="670px"
                paddi="0"
                paddiw="0"
                mw="auto"
                back="#fafafa"
              >
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text="¿Aún quieres ir por más?"
                  text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                />
                <CardOne
                  classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                  classCard="cardOne"
                  classTop="topOne1"
                  text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                />
              </TrainingDivSection2>
            </TrainingSectionGray>
            <Fragment>
              <div className="marginComentLgSuccess1">
                <Container
                  mleftmxs="0%"
                  mleft="18%"
                  mleftm="7%"
                  width="100%"
                  widthMobile="100%"
                  mtMobile="0px"
                  widthMobilexs="100%"
                >
                  <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                    <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                      <Recommendations
                        items={recommendedTraining}
                      ></Recommendations>
                    </ContentHomeSection>
                  </Feed_Section>
                </Container>
              </div>
            </Fragment>
          </Fragment>
        ) : null}
      </TrainingMain>
    </WOlayout>
  );
}

export default SuccessPL;
