/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import InputsOrder from '../../components/common/InputsOrder';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const CreditDetails = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const anchop = window.screen.width;
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          const contResponse = 0;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            setRecoveryRecoveryRoutine('activa');
            setQuestions([]);
            setOrderRutine(nextOrderRoutine);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [motivoCredito, setMotivoCred] = useState(
    sessionStorage.getItem('Motivo del Crédito') || '',
  );
  const [cantidadCredito, setCantidad] = useState(
    sessionStorage.getItem('Cantidadquenecesita') || '',
  );
  const [plazoPago, setPlazo] = useState(
    sessionStorage.getItem('Plazodeseado PL') || '',
  );
  const [form, setForm] = useState({});

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const plazoDeseado = sessionStorage.getItem('Plazodeseado PL') || '';
      setMotivoCred(sessionStorage.getItem('Motivo del Crédito') || '');
      setCantidad(sessionStorage.getItem('Cantidadquenecesita') || '');
      setPlazo(plazoDeseado);
      if (plazoDeseado !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    const plazoDeseado = sessionStorage.getItem('Plazodeseado PL') || '';
    if (plazoDeseado !== '') {
      setOrderRutine(nextOrderRoutine);
    }
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editMotivo, setEditMotivo] = useState('');
  const [editCantidad, setEditCantidad] = useState('');
  const [editPlazo, setEditPlazo] = useState('');

  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setMotivoCred(val.value);
        setEditMotivo(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeCantidadObjetivo' });
        break;
      case 1:
        setCantidad(val.value);
        setEditCantidad(true);
        gsap.to(window, { duration: 1, scrollTo: '#activePlazoPagar' });
        break;
      case 2:
        setPlazo(val.value);
        setEditPlazo(true);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        // console.log('default break handleChangeCatOption case 2');
        break;
    }
  };

  const handleChangeIcon = (json) => {
    setEditMotivo(false);
    setEditCantidad(false);
    setEditPlazo(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine && motivoCredito === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeMotivoCredito' });
      }
    }, 4000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {!loaderWait ? (
        <Fragment>
          <div id="activeMotivoCredito"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || motivoCredito !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || motivoCredito !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="¿Cuál es el motivo por el cual buscas un crédito?"
            hora={hora}
          />
          <TrainingQuestion
            className={
              (motivoCredito === ''
                || (lapiz[0].routine === 2 && lapiz[0].active === 1))
              && editMotivo !== true
                ? ''
                : 'hidden'
            }
          >
            {orderRutine === currentRoutine ? (
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            ) : null}
          </TrainingQuestion>
        </Fragment>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      <div id="activeCantidadObjetivo"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            motivoCredito !== '' && cantidadCredito === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 1 })}
        />
        <CardTwo
          classCard={
            motivoCredito !== ''
            && (!(lapiz[0].routine === 2 && lapiz[0].active === 1)
              || editMotivo === true)
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={motivoCredito}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          motivoCredito !== ''
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 1)
            || editMotivo === true)
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuál es la cantidad que necesitas para cumplir tu objetivo?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((motivoCredito !== '' && cantidadCredito === '')
            || (lapiz[0].routine === 2 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 1)
            || editMotivo === true)
          && editCantidad !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activePlazoPagar"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            cantidadCredito !== '' && plazoPago === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 2 })}
        />
        <CardTwo
          classCard={
            (cantidadCredito !== ''
              && !(lapiz[0].routine === 2 && lapiz[0].active === 2))
            || editCantidad === true
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={cantidadCredito}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          cantidadCredito !== ''
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 2)
            || editCantidad === true)
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿En qué plazo te gustaría pagarlo?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((cantidadCredito !== '' && plazoPago === '')
            || (lapiz[0].routine === 2 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 2 && lapiz[0].active === 2)
            || editCantidad === true)
          && editPlazo !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            plazoPago !== '' && orderRutine <= 3 ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 3 })}
        />
        <CardTwo
          classCard={
            (plazoPago !== ''
              && !(lapiz[0].routine === 2 && lapiz[0].active === 3))
            || editPlazo === true
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={plazoPago}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default CreditDetails;
