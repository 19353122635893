import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  DeleteDocument,
  DocumentViewer,
  FormContainer,
  FormInpust,
  FormTitle,
} from '../../assets/styles/GloveBox.styled';
import { Col12, Col6 } from '../../components/common/common.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import InputsOrder from '../../components/common/InputsOrder';
import { ADD_DOCUMENT_USERS } from '../../mutations/index';

const GloveBoxForm = ({
  formTitle,
  formIcon,
  uploadText,
  description,
  handleChangeQuestionsSend,
  handleChangeRutine,
  orderRutine,
  handleChangeCatOption,
  form,
  handleChange,
  currentTraining,
  activeStep,
  amountService,
  goNextRoutine,
  meses,
  hideHeader = false,
  revExtensionFile = 'Sa',
  revDocumentoFile = 'Sa',
  revsetEx,
}) => {
  const [documentFileUrl, setDocumentFileUrl] = useState(null);
  const [extensionFile, setExtensionFile] = useState('');
  const [stepUp, setStepUp] = useState('');
  const arrTitles = { title: formTitle, ut: uploadText };

  const anulaStyl = {
    width: '100%',
    margin: '0',
    padding: '0',
    backgroundColor: 'transparent',
  };

  const [mutationDocumentUsers] = useMutation(ADD_DOCUMENT_USERS, {
    onCompleted({ insertDocumentsUsers }) {
      if (insertDocumentsUsers.statusCode === 200) {
        // console.log('todo ok');
      } else {
        // console.log('tuvimos error');
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error al subir archivo', err);
    },
  });

  const getExtensionFile = (documentName) => {
    const name = documentName;
    setExtensionFile(name.slice(name.length - 3).trim());
    if (revExtensionFile === '') {
      revsetEx(name.slice(name.length - 3).trim());
    }
  };

  const getBase64 = (file) => {
    const data = new Promise((resolve) => {
      let baseURL = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
    return data;
  };

  const uploadFile = async (file) => {
    const streamString = await getBase64(file);
    const partsStream = streamString.split(',');
    const fileToUpload = {
      File: {
        path: file.name,
        mimetype: partsStream[0].split(';')[0].replace('data:', ''),
        encoding: partsStream[0].split(';')[1],
        body: partsStream[1],
      },
    };
    mutationDocumentUsers({
      variables: {
        input: {
          Doc: fileToUpload,
          Category: amountService + 1,
          Data: '',
          idUser: parseInt(sessionStorage.getItem('idUser'), 10) || parseInt('0', 10),
        },
      },
    });
  };

  const getDocument = (el, routine) => {
    getExtensionFile(el.target.value);
    const ext = el.target.value.slice(el.target.value.length - 3).trim();
    if (el.target.files.length !== 0) {
      // COMPROBANDO EXTENSION Y TAMAÑO SEGURIDAD PARTE 1
      if (ext === 'jpg' || ext === 'png' || ext === 'pdf' || ext === 'peg') {
        const tam = el.target.files[0].size / 1024;
        if (tam < 5120) {
          const documentFile = el.target.files[0];
          const FileUrl = URL.createObjectURL(documentFile);
          setDocumentFileUrl(FileUrl);
          if (revDocumentoFile === '') {
            // eslint-disable-next-line
            revDocumentoFile = FileUrl;
          }
          const e = {
            target: {
              name: 'docV',
              value: {
                url: FileUrl,
                ex: ext,
              },
            },
          };
          handleChange(e, 34, amountService);
          // GUARDANDO SEÑUELO EM BD
          handleChangeQuestionsSend(
            routine.QuestionsOrder,
            routine.QuestionName,
            el.target.files[0].name,
            'DocUpload',
          );
          // SUBIENDO DOC
          uploadFile(documentFile);
        } else {
          // eslint-disable-next-line no-console
          console.log('ERROR, EL ARCHIVO DEBE PESAR MENOS DE 5 MB');
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('ERROR, EL ARCHIVO NO ES UNA IMAGEN O PDF');
      }
    }
  };

  const mobile = ['iphone', 'ipad', 'android', 'nokia', 'opera mini'];
  const ua = navigator.userAgent.toLocaleLowerCase();
  // console.log(ua);
  for (let i = 0; i < mobile.length; i += 1) {
    if (ua.indexOf(mobile[i]) > -1) {
      // console.log('mobile');
    } else {
      // console.log('desktop');
    }
  }

  const deleteFile = (e) => {
    const el = e.target.parentElement.parentElement.parentElement.parentElement;
    el.children[1].firstChild.value = null;
    setDocumentFileUrl(null);
  };

  useEffect(() => {
    if (amountService === 0 || amountService === 1) {
      setStepUp('0-0');
    }
    if (amountService === 4 || amountService === 5 || amountService === 6) {
      setStepUp('3-3');
    }
    if (amountService === 7) {
      setStepUp('5-5');
    }
  }, []);

  return (
    <FormContainer style={hideHeader ? anulaStyl : null}>
      {!hideHeader ? (
        <FormTitle>
          <img
            src={formIcon}
            alt='glove box document'
          />
          <p
            style={{
              fontSize: '16px',
              fontWeight: '700',
              margin: '0',

            }}
          >
            {formTitle}
          </p>
        </FormTitle>
      ) : null}
      {formTitle !== 'Servicio de auto' && formTitle !== 'TAG' && stepUp !== '' ? (
        <InputsOrder
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step={stepUp}
          handleChangeCatOption={handleChangeCatOption}
          form={arrTitles}
          handleChange={getDocument}
          currentTraining={currentTraining}
          activeStep={activeStep}
          amountService={amountService}
        />
      ) : null}

      <FormInpust>
        {formTitle !== 'Verificación' ? (
          <p
            style={{
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            {description}
          </p>
        ) : null}
          { /* --------------------------- Póliza de seguro ----------------------- */
            formTitle === 'Póliza de seguro' ? (
            <>
              <div className='d-flex'>
                <Col6 pt="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="1-1"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col6>
                <Col6 pt="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="2-2"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col6>
              </div>
                <Col12 pt="0" ptMobile="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="3-3"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col12>
                <Col12 pt="0" ptMobile="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="4-4"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col12>
                <Col12 pt="0" ptMobile="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="5-5"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col12>
                <Col12 pt="0" ptMobile="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="6-6"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col12>
            </>
            ) : null
          }
          { /* ----------------------- Póliza de asistencia en el camino ----------------------- */
              formTitle === 'Póliza de asistencia en el camino' ? (
                <>
                  <div className='d-flex'>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="1-1"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="2-2"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                  </div>
                    <Col12 pt="0" ptMobile="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="3-3"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col12>
                    <Col12 pt="0" ptMobile="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="4-4"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col12>
                    <Col12 pt="0" ptMobile="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="5-5"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col12>
                </>
              ) : null
          }
          { /* ----------------------- Servicio de auto ----------------------- */
            formTitle === 'Servicio de auto' ? (
              <>
                  <div className='d-flex'>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="0-0"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="1-1"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                  </div>
                    <Col12 pt="0" ptMobile="0">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="2-2"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col12>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                    >
                      Fecha del próximo servicio
                    </p>
                  <div className='d-flex'>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="3-3"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="4-4"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                  </div>
                    <Col12 pt="0" ptMobile="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="5-5"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col12>
              </>
            ) : null
          }
          { /* ----------------------- TAG ----------------------- */
            formTitle === 'TAG' ? (
              <>
                <Col12 pt="0" ptMobile="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="0-0"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                  />
                </Col12>
              </>
            ) : null
          }
          { /* ----------------- LICENCIA TARGETA DE CIRCULACION, TENENCIA ----------------- */
            formTitle === 'Licencia de conducir'
            || formTitle === 'Tarjeta de circulación'
            || formTitle === 'Tenencia' ? (
              <>
                <div className='d-flex'>
                  <Col6 pt="0" bottom="-1rem">
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="0-0"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                      currentTraining={currentTraining}
                      activeStep={activeStep}
                      amountService={amountService}
                      arrayFilter={meses}
                    />
                  </Col6>
                  <Col6 pt="0" bottom="-1rem">
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="1-1"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                      currentTraining={currentTraining}
                      activeStep={activeStep}
                      amountService={amountService}
                      arrayFilter={meses}
                    />
                  </Col6>
                </div>
                <Col12 pt="0" ptMobile="0" bottom="-1rem">
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="2-2"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={activeStep}
                    amountService={amountService}
                    arrayFilter={meses}
                  />
                </Col12>
              </>
              ) : null
          }
          { /* ----------------- LICENCIA TARGETA DE CIRCULACION, TENENCIA ----------------- */
            formTitle === 'Verificación' ? (
              <>
                  <Col12 pt="0" ptMobile="0">
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="0-0"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                      currentTraining={currentTraining}
                      activeStep={activeStep}
                      amountService={amountService}
                      arrayFilter={meses}
                    />
                  </Col12>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  >
                    Fecha de próxima verificación
                  </p>
                  <Col12 pt="0" ptMobile="0" bottom="-1rem">
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="1-1"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                      currentTraining={currentTraining}
                      activeStep={activeStep}
                      amountService={amountService}
                      arrayFilter={meses}
                    />
                  </Col12>
                  <div className='d-flex'>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="2-2"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                    <Col6 pt="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="3-3"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col6>
                  </div>
                    <Col12 pt="0" ptMobile="0" bottom="-1rem">
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="4-4"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                        currentTraining={currentTraining}
                        activeStep={activeStep}
                        amountService={amountService}
                        arrayFilter={meses}
                      />
                    </Col12>
              </>
            ) : null
          }

          {formTitle !== 'Servicio de auto' && formTitle !== 'TAG' && documentFileUrl !== null ? (
            <>
              <Col12 pt="0" ptMobile="0" bottom="-1rem">
                <DocumentViewer>
                  <DeleteDocument
                    onClick={(e) => deleteFile(e)}
                  >X</DeleteDocument>
                  { extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                    <img
                      src={documentFileUrl}
                      alt='document processed'
                      style={{ width: '100%' }}
                    />) : (
                    <embed
                      type='application/pdf'
                      src={`${documentFileUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="600px"
                    />)
                  }
                </DocumentViewer>
              </Col12>
            </>) : null
          }
          <CardsBtn
            type="button"
            min_w="100%"
            min_web="100%"
            onClick={() => goNextRoutine(amountService)}
          >
            {hideHeader ? 'Guardar' : 'Continuar'}
          </CardsBtn>
      </FormInpust>
    </FormContainer>
  );
};

GloveBoxForm.propTypes = {
  formTitle: PropTypes.string.isRequired,
  formIcon: PropTypes.string.isRequired,
  uploadText: PropTypes.string,
  description: PropTypes.string.isRequired,
  handleChangeQuestionsSend: PropTypes.func.isRequired,
  handleChangeRutine: PropTypes.func.isRequired,
  orderRutine: PropTypes.number.isRequired,
  handleChangeCatOption: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  currentTraining: PropTypes.number.isRequired,
  activeStep: PropTypes.bool.isRequired,
  amountService: PropTypes.number.isRequired,
  goNextRoutine: PropTypes.func.isRequired,
  meses: PropTypes.array,
  hideHeader: PropTypes.bool,
  revExtensionFile: PropTypes.string,
  revDocumentoFile: PropTypes.string,
  revsetEx: PropTypes.func,
};

export default GloveBoxForm;
