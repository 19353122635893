/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
import React from "react";
import styled, { css } from "styled-components";
import checkImage from "../../assets/img/Checkbox.svg";
// import castor from "../../assets/img/home/Castor.png";

const Container = styled.div`
  height: 29.68rem;
  margin: 1rem auto 2rem;
  min-width: 300px;
  width: 335px;
  padding: 1rem 0.5rem;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(219, 219, 219, 1);
`;
const CardImg = styled.div`
  width: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  margin-left: 1rem;
`;

const CardTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
  color: #07210a;
`;

const CardDescription = styled.p`
  font-size: 13px;
  /* display: flex; */
  font-weight: 400;
  color: #4f4f4f;
  line-height: 160%;
  letter-spacing: -0.3px;
  text-align: left;
  margin-right: 0.25rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
`;

const HR = styled.hr`
  width: 85%;
  margin: 1rem auto;
  border: 1px solid #cdcdcd;
`;

const CardInfoList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin: 0.5rem 2rem;
  span {
    text-align: left;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.3px;
    color: #4f4f4f;
  }
`;
const Button = styled.a`
  width: ${(props) => props.Width || "90%"};
  border-radius: 3px;
  max-width: ${(props) => props.maxWidth || "100%"};
  margin: ${(props) => props.margin || ""};
  margin: ${(props) => props.marginD || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  display: ${(props) => props.display || "block"};
  height: ${(props) => props.height || "48px"};
  padding: ${({ padding }) => padding || "13px 20px"};
  outline: none;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  border: none;
  background-color: ${({ color }) => color || "#f64282"};
  color: ${({ colorText }) => colorText || "#fff"};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    margin-left: ${(props) => props.marginleftMd || ""};
    margin: ${(props) => props.marginMd || ""};
    width: ${(props) => props.widthMd || ""};
    width: ${(props) => props.width || ""};
    text-align: center;
  }
`;
const CardCastorDescription = ({
  onClick,
  cardTitle = "Castor App",
  cardDescription = "CastorApp es una tarjeta de débito de Salartio On Demand que te da acceso a tu dueldo diario sin comisiones",
  cardInfoList = [
    "Tu salario de forma diaria y al instante sin esperar a que llegue la quincena",
    "Tu saldo disponible se actualiza diario, basado en tus días trabajados",
    "Sin cargos ni comisiones",
  ],
  imgUrl = "",
}) => (
  <>
    <Container>
      <CardImg>
        <img src={imgUrl} alt="" />
      </CardImg>
      <CardTitle>{cardTitle}</CardTitle>
      <CardDescription>{cardDescription}</CardDescription>
      <HR />
      {cardInfoList.map((info) => (
        <>
          <CardInfoList>
            <img src={checkImage} alt="" /> <span>{info}</span>
          </CardInfoList>
        </>
      ))}
      <Button
        color="#F64282"
        colorText="#fff"
        margin="auto auto 0rem"
        type="active"
        href={onClick}
        target="_blank"
      >
        Aplicar
      </Button>
    </Container>
  </>
);

export default CardCastorDescription;
