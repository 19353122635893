import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
// assets
import { useMutation, useLazyQuery } from '@apollo/client';
import { DYNAMIC_GET, CREATE_OPPORTUNITIES } from '../../mutations';
import { GET_TRAINING_COLOR, GET_PRODUCTS_IDEAL_CARDS } from '../../queries';

import {
  TrainingSubtitles,
  ImageSections,
  TrainingDivSection,
  TrainingSectionFlex,
  DivStyle,
  Resultado,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ClickButton from '../../Helpers/HookHelpers';
import BarFilterBank from '../../components/common/BarFilterBank';
import ResultProduct from '../../components/common/ResultProduct';
import Carousel from 'react-elastic-carousel';

const RpCompareCard = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [colorTraining, setColorTraining] = useState([]);
  const [products, setProducts] = useState([]);
  const { actions } = ClickButton();
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getProducts] = useLazyQuery(GET_PRODUCTS_IDEAL_CARDS, {
    onCompleted({ getProductsIdealCards }) {
      let elements = JSON.parse(getProductsIdealCards.response)
      const nameTarjetaActual = JSON.parse(localStorage.getItem('hasCardSelected')).CardName
      elements = elements.filter(card => card.CardName !== nameTarjetaActual)
      setProducts(elements);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
    getProducts({ variables: { gama: 'alta', nameCard: '' } })
  }, []);
  const [creditCard, setCreditCard] = useState('');

  const [bestCard, setBestCard] = useState([]);
  const [getCards] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        if (dynamicGet.response.length > 0) {
          let products = JSON.parse(dynamicGet.response);
          products = products.map((product) => {
            if (product.hasOwnProperty('ClickOut')) {
              if (product.ClickOut.includes('[IDUser]')) {
                const newClickout = product.ClickOut.replace(
                  '[IDUser]',
                  sessionStorage.getItem('idUser'),
                );
                return { ...product, ClickOut: newClickout };
              }
              return product;
            }
            return product;
          });
          chooseTheBest(products);
        }
      }
    },
  });

  const chooseTheBest = (product) => {
    console.log('opsere', product);
    const prodi = [];
    const cate = sessionStorage.getItem('categoryCardAc');
    const nombre = sessionStorage.getItem('actualCardName');
    for (const j in product) {
      if (product[j].Status !== '0') {
        if (cate === product[j].laCat) {
          if (nombre !== product[j].Name) {
            prodi.push(product[j]);
          }
        }
      }
    }
    setBestCard(prodi);
    const vexi = prodi[0];
    sessionStorage.setItem('CatProductos', JSON.stringify([vexi]));
  };

  useEffect(() => {
    let idBestCard = '2';
    if (!sessionStorage.getItem('Ingreso_Mensual_Fijo')) {
      idBestCard = '19';
    }
    sessionStorage.setItem('idBestCard', idBestCard);
    const getProductsInput = {
      input: {
        table: 'CatProduct, CatProductDetail, CatRelProductTraining',
        columns: [
          'ImageURL, CatProduct.Name, CatProductDetail.Name as nameDetail, Description, CatRelProductTraining.Status',
          'CatProduct.IDProduct, CatProduct.CatVerticals_IDVertical, CatProduct.hasApi, CatProduct.ProductCategory as laCat',
        ],
        conditions: [
          {
            valone: 'CatProductDetail.IDProduct',
            valtwo: 'CatProduct.IDProduct',
            condition: '=',
            validColumn: true,
          },
          {
            valone: 'CatTraining_IDCatTraining',
            valtwo: '3',
            condition: '=',
            logic: 'AND',
          },
          {
            valone: 'CatProduct_IDProduct',
            valtwo: 'CatProduct.IDProduct',
            condition: '=',
            logic: 'AND',
            validColumn: true,
          },
        ],
      },
    };
    getCards({ variables: getProductsInput });
  }, []);

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleChangeSelectCreditCard = () => {
    const click = `Aplicar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    let idProducts = []
    let nameCardsList = []
    for(let prod of products){
      idProducts.push(prod.Id)
      nameCardsList.push(prod.CardName)
    }
    let nameCards = nameCardsList.join(', ')
    sessionStorage.setItem('selectProductName', nameCards);
    sessionStorage.setItem('productsIdealCard', JSON.stringify(products));
    setCreditCard(nameCards);
    // handleRoutineOrder(3, [], "Todas")
    setOrderRutine(nextOrderRoutine);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1, // remplazar por el id del entrenamiento
        idProducts: idProducts, // arreglo de numero ejemplo [10,1,3]
        idTraining: 3,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
  };
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeRPCompare' });
        setReviewScroll(false);
      }
    }, 500);
  }, []);

  return (
    <div>
      {/* comparativo cc */}
      <div id="activeRPCompare"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          orderRutine === currentRoutine || creditCard !== ''
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="Continuemos"
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          orderRutine === currentRoutine || creditCard !== ''
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        textbold={`¡Perfecto ${
          sessionStorage.getItem('FirstName') || ''
        }!🤗`}
        text={`Gracias por tus respuestas, estoy buscando las mejores opciones de tarjeta para ti.`}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          orderRutine === currentRoutine || creditCard !== ''
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text={`¡Tenemos estas opciones para ti! Estamos seguros de tener una opción que podría interesarte.`}
      />
      <TrainingDivSection
        padding="5%"
        back="#fafafa"
        backw="#fafafa"
        className={orderRutine === currentRoutine ? '' : 'hidden'}
      >
        <TrainingSectionFlex>
        {JSON.parse(localStorage.getItem('hasCardSelected')) ? 
          <BarFilterBank
              key={'2'}
              text={'Tu tarjeta actual'}
              disableBtn
            >
              <div className = "d-flex justify-content-center">
                <ResultProduct
                  card={JSON.parse(localStorage.getItem('hasCardSelected'))}
                  showButton={false}
                  ClickButton={() =>{console.log('ok')}}
                />
              </div>
          </BarFilterBank>:null
          }
          <BarFilterBank
          key={'1'}
          text={'Las mejores opciones para ti'}
          disableBtn
          >
            <Carousel
              id="CarruselMetasLogros"
              styled={{ background: '#FAFAFA' }}
              transitionMs={1000}
            >
              {products.map((card, index) => (
                  <ResultProduct
                    card={card}
                    showButton={false}
                    ClickButton={handleChangeSelectCreditCard}
                  />
              ))}                 
            </Carousel>
            <CardsBtn min_w="100%" min_web="100%" onClick={() => handleChangeSelectCreditCard()}>
              Aplicar a todas
            </CardsBtn>
          </BarFilterBank>
        </TrainingSectionFlex>
      </TrainingDivSection>
      {/* end comparativo cc */}
      <CardTwo
        classCard={creditCard !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={creditCard}
        colorTraining={colorTraining}
      />
    </div>
  );
};

export default RpCompareCard;
