import React, { useState } from 'react';
import Minus from '../../assets/img/anacoach/Minus.svg';
import More from '../../assets/img/anacoach/More.svg';
import { Image } from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import {
  DivSelectionLogros,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';

function BarFilterProduct({ children, text, background }) {
  const [changeStatus, setChangeStatus] = useState(false);

  return (
        <TrainingQuestion width="100%">
            <DivSelectionLogros fs="16px" padd="5px 10px" style={{ alignItems: 'center', cursor: 'pointer', userSelect: 'none' }} backColor={background} onClick={() => setChangeStatus(!changeStatus)}>
                {text}
                <Image className='imageSection1' zindex="1" cursor='pointer' src={changeStatus ? Minus : More} width="20px" marginRight="0" marginRightM="0" />
            </DivSelectionLogros>
            {changeStatus ? (children) : null}
        </TrainingQuestion>
  );
}
export default BarFilterProduct;
