import React, {
  useState, useEffect, Suspense,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// rutinas
import { useMutation, useLazyQuery } from '@apollo/client';
import RecognitionUser from '../Routines/RecognitionUser';
import RpFirstCard from '../Routines/RpFirstCard';
import DetailRFC from '../Routines/DetailRFC';
import PersonalInformation from '../Routines/PersonalInformation';
import Domicile from '../Routines/Domicile';
import UserAuthentication from '../Routines/UserAuthentication';
import DatosLaborales from '../Routines/DatosLaborales';
import Referencias from '../Routines/Referencias';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';
import { GET_TRAINING_COLOR } from '../../queries';
import { CREATE_SESSION_MYSQL, CREATE_SESSION } from '../../mutations';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const DatosPrincipales = React.lazy(() => import('../Routines/DatosPrincipales'));

const RoutinesOrderFirstCard = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [colorTraining, setColorTraining] = useState('');

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  console.log('*********************');
  console.log(orderRutine);
  console.log(currentTraining);
  console.log(createJsonInput);
  console.log(createJsonInput);
  console.log(createJsonInput);
  console.log('*********************');
  const [mainDataRoutine, setMainDataRoutine] = useState(0);

  const [newSession, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
    );
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) newSession({ variables: mongoInput });
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    let aux = 150;
    if (orderRutine === 5 || orderRutine === 1) return;
    if (orderRutine === 10) aux = 300;
    if (orderRutine === 2) aux = 640;
    if (orderRutine === 2 || orderRutine === 7 || orderRutine === 10) {
      console.log('########################', orderRutine);
      return;
    }
    if (orderRutine === 3) {
      gsap.to(window, { duration: 1, scrollTo: '#activeDatosPrincipales' });
    }
  }, [orderRutine]);
  return (
    <div>
      {mainDataRoutine > 0 && orderRutine >= 1 ? (
        <RecognitionUser
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina1"></div>
      {orderRutine >= 2 ? (
        <RpFirstCard
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina2"></div>
      {orderRutine >= 3 ? (
        <Suspense
          fallback={
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          }
        >
          <div id="activeDatosPrincipales"></div>
          <DatosPrincipales
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={3}
            nextOrderRoutine={4}
            trainingColor={colorTraining}
          />
        </Suspense>
      ) : null}
      <div id="rutina3"></div>
      <DetailRFC
        Date={Date}
        hora={hora}
        orderRutine={orderRutine}
        setOrderRutine={setOrderRutine}
        currentTraining={currentTraining}
        createJsonInput={createJsonInput}
        questions={questions}
        setQuestions={setQuestions}
        currentRoutine={4}
        nextOrderRoutine={5}
        trainingColor={colorTraining}
      />
      <div id="rutina4"></div>
      {orderRutine >= 5 ? (
        <PersonalInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={7}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina5"></div>
      {orderRutine >= 7 ? (
        <Domicile
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={7}
          nextOrderRoutine={8}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina7"></div>

      {orderRutine >= 8 ? (
        <UserAuthentication
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={8}
          nextOrderRoutine={9}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina8"></div>
      {orderRutine >= 9 ? (
        <DatosLaborales
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={9}
          nextOrderRoutine={10}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina9"></div>
      {orderRutine >= 10 ? (
        <Referencias
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={10}
          nextOrderRoutine={0}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina10"></div>
    </div>
  );
};

export default RoutinesOrderFirstCard;
