/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  QuestionsText,
  TrainingQuestion,
  QuestionsDivs,
  CardsBtn1,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import IconMover from '../../assets/img/anacoach/move.png';
import {
  Image,
  Col10,
  Col2,
  Container,
} from '../../components/common/common.styled';
// función para reordenar la lista

const reorder = (list, startColumn, endColumn, startIndex, endIndex) => {
  // clonamos la lista
  const newList = Array.from(list);
  // encontramos la columna de inicio dentro de la lista
  const startColumnIndex = newList.findIndex(
    (item) => item.columnId === startColumn,
  );
  // encontramos la columna final dentro de la lista
  const endColumnIndex = newList.findIndex(
    (item) => item.columnId === endColumn,
  );
  // quitamos el elemento de la columna original

  const [removed] = newList[startColumnIndex].items.splice(startIndex, 1);
  // añadimos ese elemento dentro de la nueva posición
  newList[endColumnIndex].items.splice(endIndex, 0, removed);
  // y devolvemos
  return newList;
};

const SelectionGoalsOrder = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const anchop = window.screen.width;
  const [seleccionaMeta, setSeleccionaMeta] = useState(
    sessionStorage.getItem('SeleccionaMeta') || '',
  );
  const [goalsMultiple, setGoalsMultiple] = useState([]);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log('MoneySaved', json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const seleccionMeta = sessionStorage.getItem('SeleccionaMeta') || '';
      setSeleccionaMeta(seleccionMeta);
      // const id = 0;
      if (seleccionMeta !== '') {
        let goals = String(seleccionMeta).replace(/\|/g, ',');

        goals = goals.split(',');

        const items = [];
        for (let i = 0; i < goals.length; i++) {
          items.push({ id: `elemento-${i}`, goal: goals[i] });
        }

        if (goals.length > 0) {
          setGoalsMultiple([{ columnId: 'menu-1', items }]);
        }
      }
      if (
        seleccionaMeta !== null
        && seleccionaMeta !== ''
        && sessionStorage.getItem('isOrderGoals') === 'true'
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const onDragEnd = (result) => {
    const { destination, source} = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) { return; }
    console.log(goalsMultiple);
    const newElements = reorder(
      goalsMultiple,
      source.droppableId,
      destination.droppableId,
      source.index,
      destination.index,
    );
    console.log(newElements);
    setGoalsMultiple(newElements);
  };
  const handleContinueGoals = () => {
    const click = `ContinuarOrdenMetas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('isOrderGoals', true);
    let goals = '';
    for (let index = 0; index < goalsMultiple[0].items.length; index++) {
      const element = goalsMultiple[0].items[index].goal;
      goals += `${element},`;
    }
    goals = goals.slice(0, -1);
    sessionStorage.setItem('SeleccionaMeta', goals);

    const resp = [
      {
        id: 'seleccionaMeta',
        question: 1,
        value: goals,
      },
    ];

    createJsonInput(Rutine, resp);
    setQuestions([]);
    setOrderRutine(nextOrderRoutine);
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeOrderGoals' });
        setReviewScroll(false);
      }
    }, 2000);
  }, []);
  return (
    <>
      <div id="activeOrderGoals"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <QuestionsOrder
        classCard={'hidden'}
        orderRutine={5}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="Arrastra cada una de tus metas para saber cuáles son tus prioridades:"
        hora={hora}
      />
      {currentRoutine === orderRutine ? (
        <>
          {console.log('Analicemos 0 goalsMultiple >>> ', goalsMultiple)}
          <TrainingQuestion wiw="75%" mLg="11%">
            <DragDropContext onDragEnd={onDragEnd}>
              {goalsMultiple.map((col, i) => (
                <>
                  {console.log('Analicemos col, i ... 1 >>> ', col, i)}
                  <Droppable droppableId={col.columnId} key={col.columnId}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {console.log(
                          'Analicemos provided, snapshot ... 2 >>> ',
                          provided,
                          snapshot,
                        )}
                        {col.items.map((el, j) => (
                          <Draggable draggableId={el.id} index={j} key={el.id}>
                            {(provided, snapshot) => (
                              <QuestionsDivs
                                widthxs="100%"
                                widthweb="80%"
                                bc="#F7F7F7"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                margin="8px auto auto;"
                                hei="auto"
                                bs="0px 2px 8px rgba(0,0,0,0.08)"
                              >
                                {console.log(
                                  'Analicemos el, j ... 3 >>> ',
                                  el,
                                  j,
                                )}
                                {console.log(
                                  'Analicemos provided, snapshot ... 4 >>> ',
                                  provided,
                                  snapshot,
                                )}
                                <QuestionsText size="14px" weight="400">
                                  <Container>
                                    <Col10>{el.goal}</Col10>
                                    <Col2>
                                      <Image
                                        src={IconMover}
                                        width="40px"
                                        alt={el.goal}
                                      />
                                    </Col2>
                                  </Container>
                                </QuestionsText>
                              </QuestionsDivs>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </>
              ))}
            </DragDropContext>
          </TrainingQuestion>
          <CardsBtn1
            mleftmd="5%"
            type="button"
            onClick={(e) => {
              handleContinueGoals(e);
            }}
          >
            Continuar
          </CardsBtn1>
        </>
      ) : (
        <TrainingQuestion className="">
          {goalsMultiple[0].items.map((el, j) => (
            <QuestionsDivs
              bc="#F7F7F7"
              widthweb="60%"
              widthxs="100%"
              marginLmd="0px"
              marginleftLg="18%"
              hei="auto"
              bs="0px 2px 8px rgba(0,0,0,0.08)"
            >
              <QuestionsText size="14px" weight="400">
                <Container>
                  <Col10>{el.goal}</Col10>
                  <Col2></Col2>
                </Container>
              </QuestionsText>
            </QuestionsDivs>
          ))}
        </TrainingQuestion>
      )}
    </>
  );
};

export default SelectionGoalsOrder;
