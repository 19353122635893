import React, {
  useEffect,
} from 'react';
import { useMutation } from '@apollo/client';
import DatosMvp from '../Routines/DatosMvp2Inv';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';
import { CREATE_SESSION_MYSQL, CREATE_SESSION } from '../../mutations';

const RoutinesOrderMvp = ({
  Date,
  setStateDate,
  mostrarTabla,
  setMostrarTabla,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  idUsuarioQuiz,
  idExercise,
  setIdExercise,
  mostrarQuiz,
  setmostrarQuiz,
  mostrarShareButtons,
  setmostrarShareButtons,
  isMasterView,
  setisMasterView,

  setshareEffect,
  shareEffect,
  shareButton,
  setShareButton,
}) => {
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const scrollToRoutine = (id) => {
    const avance = document.body.scrollHeight;
    window.scroll({
      top: avance,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    if (orderRutine <= 1) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    } else {
      scrollToRoutine(`rutina${orderRutine}`);
    }
  }, [orderRutine]);

  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
    );
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) { newSession({ variables: mongoInput }); }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  return (
    <DatosMvp
      Date={Date}
      hora={hora}
      idUsuarioQuiz={idUsuarioQuiz}
      orderRutine={orderRutine}
      mostrarTabla={mostrarTabla}
      setMostrarTabla={setMostrarTabla}
      setOrderRutine={setOrderRutine}
      currentTraining={currentTraining}
      createJsonInput={createJsonInput}
      questions={questions}
      setQuestions={setQuestions}
      currentRoutine={1}
      idExercise={idExercise}
      setIdExercise={setIdExercise}
      mostrarQuiz={mostrarQuiz}
      setmostrarQuiz={setmostrarQuiz}
      mostrarShareButtons={mostrarShareButtons}
      setmostrarShareButtons={setmostrarShareButtons}
      isMasterView={isMasterView}
      setisMasterView={setisMasterView}
      setshareEffect={setshareEffect}
      shareEffect={shareEffect}
      shareButton={shareButton}
      setShareButton={setShareButton}
    />
  );
};

export default RoutinesOrderMvp;
