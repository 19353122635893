import React, { Fragment, useState, useEffect } from 'react';
import InitialMessage from './InitialMessage';
import ScoreMessage from './ScoreMessage';
import OptionsMessage from './OptionsMessage';
import VideoThanks from './VideoThanks';
import HeaderSV from './HeaderSV';
import Footer from '../common/Footer';
import FormContact from './FormContact';
import {
  CardContainer,
  VideoContainer,
  BtnContainer,
  Button,
  BtnContainerEnd,
  ButtonEnd,

} from './Styled';

const SocoreVideoMessage = ({ pathname, headerType }) => {
  const [showMessage, setShowMessage] = useState(0);
  const [active, setactive] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);

  const completeStep = () => {
    setShowMessage((cur) => cur + 1);
  };

  const StepNo = () => {
    setShowMessage((cur) => cur + 3);
  };

  useEffect(() => {
    setTimeout(() => {
      setactive(true);
    }, 17000);

    setTimeout(() => {
      setactive2(true);
    }, 35000);

    setTimeout(() => {
      setactive3(true);
    }, 45000);
  }, [[active, active2, active3]]);

  // Button Steps function

  const renderScoreVideo = () => {
    if (showMessage === 0) {
      return (<>
         {showMessage === 0
     && <Fragment>
        <VideoContainer>
          <InitialMessage />
        </VideoContainer>
        <BtnContainer >
        {active === true
        && <>
        <Button onClick={completeStep}> Si </Button>
        <Button onClick={StepNo}> No </Button>
        </>
        }
         </BtnContainer>
     </Fragment>
         }
      </>);
    } if (showMessage === 1) {
      return (<>
   {showMessage === 1
     && <Fragment>
        <VideoContainer>
            <ScoreMessage />
        </VideoContainer>
       <BtnContainer >
    {active2 === true
       && <>
      <Button onClick={completeStep}> Si </Button>
      <Button onClick={StepNo}>No</Button>
      </>
      }
       </BtnContainer>
    </Fragment>
        }
        </>);
    } if (showMessage === 2) {
      return (
      <Fragment>
        <VideoContainer>
        {showMessage === 2 && <OptionsMessage />}
      </VideoContainer>
         <BtnContainerEnd>
        { active3 === true
        && <>
           <ButtonEnd> Préstamos a tu Medida </ButtonEnd>
           <ButtonEnd> Rechazo de tarjeta </ButtonEnd>
           <ButtonEnd> Solicitar Rappi Card </ButtonEnd>
           <ButtonEnd> Resuelve tu deuda </ButtonEnd>
           </>
      }
         </BtnContainerEnd>
        </Fragment>
      );
    }
    return (
       <Fragment>
         <VideoContainer>
           <VideoThanks/>
         </VideoContainer>
        <BtnContainer>
         <FormContact/>
        </BtnContainer>
      </Fragment>
    );
  };
  return (
 <>
 <HeaderSV backgroundColor={'#FAFAFA'} pathname={window.location.pathname} headerType="HeaderLP" />
    <CardContainer>
          {renderScoreVideo()}
      </CardContainer>
      <br/>
    <Footer pathname={pathname} />
</>);
};

export default SocoreVideoMessage;
