import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import WOlayout from '../components/layouts/WOLayout';
// import CustomQuestion from '../components/steps/dynamicQuestions/CustomQuestion';
// import CustomOfferContainer from '../components/steps/info/CustomOfferContainer';
import CustomProgressSteps from '../components/steps/info/CustomProgressSteps';
// import CustomVideo from '../components/steps/info/CustomVideo';
// import FilterItemsList from '../components/steps/info/FilterItemsList';
import StepList from '../components/steps/info/StepList';
import anaImg from '../../assets/img/anacoach/AnaCoach1.png';
// import carComplete from '../../assets/img/anacoach/svg/complete_car_ensurance.svg';
import './styles/woSeguroAuto.css';
import { GET_TRAINING_PREVIEW } from '../../queries';
import RoutineSelector from '../components/shared/RoutineSelector';
import { DYNAMIC_GET } from '../../mutations';

const WO_SeguroAuto = ({ match }) => {
  const [headerImage, setHeaderImage] = useState('');

  const { loading, error, data } = useQuery(GET_TRAINING_PREVIEW, {
    variables: {
      Id: '4',
    },
    onCompleted: () => loadWOPercentage(),
  });

  const [dynamicGet] = useMutation(DYNAMIC_GET);

  console.log('>> yeh >>', match);

  const [actualRoutine, setActualRoutine] = useState(0);
  const [routinesStep, setRoutinesStep] = useState({});
  // const [totalRoutines, setTotalRoutines] = useState(0);
  // const [woPercentage, setWoPercentage] = useState(0);
  const [showStepList, setShowStepList] = useState(true);

  const [woData, setWoData] = useState({
    general: {
      customColor: '#6BD5AF',
    },
    layoutHeader: {
      show: true,
      hidden: false,
      title: 'Encuentra el seguro ideal para tu auto',
      timeText: 'En 3 sencillos pasos',
    },
  });

  useEffect(() => {
    if (data) setRoutinesOrderSteps();
  }, [data]);

  const setRoutinesOrderSteps = () => {
    const { routines } = data.getTrainingPreview.response;
    let tempSteps = {};
    routines.forEach((routine) => {
      console.log('vamos viendo >>', routine);
      tempSteps = {
        ...tempSteps,
        [routine.RoutinesOrder]: false,
      };
    });

    setRoutinesStep(tempSteps);
    // setTotalRoutines(tempSteps.length);
  };

  const continueHandler = () => {
    setRoutinesStep({
      ...routinesStep,
      1: true,
    });

    setShowStepList(false);
    setActualRoutine(1);
    setWoData({
      ...woData,
      layoutHeader: {
        ...woData.layoutHeader,
        hidden: true,
      },
    });
  };

  const nextHandler = (opc) => {
    if (!opc) {
      setNextRoutine();
      return;
    }

    switch (opc) {
      case 'a':
        break;

      default:
        break;
    }
  };

  const setNextRoutine = () => {
    const nextRoutine = actualRoutine + 1;
    setRoutinesStep({
      ...routinesStep,
      [actualRoutine]: false,
      [nextRoutine]: true,
    });
    setTimeout(() => {
      setActualRoutine(nextRoutine);
    }, 500);
  };

  const loadWOPercentage = async () => {
    setHeaderImage(data.getTrainingPreview.response.training.UrlBigImage);

    const getTrainingAchievmentInput = {
      input: {
        table:
          'CatRelUsersTraining as RU, DatTraining as dt, CatTraining as ct',
        columns: [
          'RU.IDUserCollaborative',
          'ct.IDCatTraining',
          'dt.Achievment',
          'ct.Name',
        ],
        conditions: [
          {
            valone: 'dt.IDDatTraining',
            condition: '=',
            valtwo: 'RU.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'ct.IDCatTraining',
            condition: '=',
            valtwo: 'dt.CatTraining_IDTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser') || '0',
          },
          {
            logic: 'AND',
            valone: 'ct.IDCatTraining',
            condition: '=',
            valtwo: '4', // TrainingID
            validColumn: true,
          },
        ],
      },
    };

    const dynamicData = await dynamicGet({
      variables: getTrainingAchievmentInput,
    });

    console.log('dynamicdata >> ', dynamicData);

    let percentageData = dynamicData.data.dynamicGet.response;
    percentageData = percentageData.replace('[', '').replace(']', '');

    if (!percentageData || percentageData === '') {
      return;
    }

    percentageData = JSON.parse(percentageData).Achievment;
    // setWoPercentage(percentageData);
    setShowStepList(false);

    /* Just for test, here goes actual routine and must be loaded by db call */
    setActualRoutine(1);
  };
  /*
  const [activeSteps, setActiveSteps] = useState({
    one: true,
    two: true,
    three: true,
    four: true,
    five: true,
    six: true,
  }); */

  if (loading) return 'Loading...';
  if (error) return `Error ${error}`;

  console.log('data>>', data);

  const backHandler = (opc) => {
    let newActualRoutine = 0;
    switch (opc) {
      case 'a':
        newActualRoutine = actualRoutine - 1;
        setRoutinesStep({
          ...routinesStep,
          [actualRoutine]: false,
          [newActualRoutine]: true,
        });
        setTimeout(() => {
          setActualRoutine(newActualRoutine);
        }, 500);
        break;

      case 'personalDataViewer':
        const personalDataRoutine = data.getTrainingPreview.response.routines.find(
          (routine) => routine.ComponentName === 'PersonalData',
        );
        if (!personalDataRoutine) return;
        newActualRoutine = parseInt(personalDataRoutine.RoutinesOrder);
        console.log('ahora seria esta rutina >> ', newActualRoutine);
        setRoutinesStep({
          ...routinesStep,
          [actualRoutine]: false,
          [newActualRoutine]: true,
        });
        setTimeout(() => {
          setActualRoutine(newActualRoutine);
        }, 500);
        break;

      default:
        break;
    }
  };

  const loadRoutines = () => {
    const { routines, training } = data.getTrainingPreview.response;

    /*
        return routines.map( routine => <RoutineSelector routineData={routine}
                                        idTraining={4} backHandler={backHandler}
                                        customColor={training.TrainingColor} />)
        */

    const routineData = routines.find(
      (routine) => routine.RoutinesOrder === actualRoutine,
    );

    if (routineData) {
      return (
        <RoutineSelector
          routineData={routineData}
          idTraining={4}
          backHandler={backHandler}
          nextHandler={nextHandler}
          isActive={routinesStep[actualRoutine]}
          customColor={training.TrainingColor}
        />
      );
    }

    /* return routines.map( routine => console.log("rutina>>", routine)) */
  };

  const stepListData = [
    {
      title: 'Tu auto y tú',
      description:
        'Responde algunas preguntas sobre tu auto, tú y tus preferencias.',
      active: true,
    },
    {
      title: 'Cotiza y compara',
      description: 'Compara precios, aseguradoras y seguros.',
      active: false,
    },
    {
      title: 'Contrata',
      description: 'Completa tus datos y contrata.',
      info: 'Te pediremos datos del número de motor, número de placas y número de serie de tu vehículo.',
      icon: '',
      active: false,
    },
  ];
  /*
  const customVideoData = {
    title: '¡Hola ! Soy Ana, tu coach financiero.',
    description: [
      `Te ayudaré a encontrar el mejor seguro de auto. 
            Primero tenemos que saber más de ti para buscar 
            las opciones que se adapten a tu perfil.`,
    ],
    videos: ['https://www.youtube.com/embed/f02mOEt11OQ'],
  };

  const customQuestionData = {
    title: '¿Qué año es tu auto?',
    description: '',
    descriptionWithLink: {
      text: 'Si no encuentras tu vehículo, es taxi, uber, de carga o de salvamento da click',
      linkText: 'aquí',
      type: 'origin',
      path: '/the-training-feed/',
    },
    instructions: 'Elige un año',
    questionInputs: [
      {
        className: '',
        label: '',
        rowComponents: [
          {
            type: 'select',
            name: '328',
            defaultValue: '0',
            placeholder: 'Año',
            fillOptions: () => loadOptions(),
          },
        ],
      },
    ],
  };

  const loadOptions = () => (
      <>
        <option value={'1'}>1997</option>
        <option value={'2'}>1998</option>
        <option value={'3'}>1999</option>
        <option value={'4'}>2000</option>
        <option value={'5'}>2001</option>
        <option value={'6'}>2002</option>
      </>
  );

  const filterListData = {
    title: 'Cotiza y compara',
    filterTitle: 'FORD FIGO, 2017',
    principalImage: carComplete,
    isReady: true,
    items: [<CustomOfferContainer customColor={woData.general.customColor} />],
  };

  const customProps = {
    customColor: woData.general.customColor,
    type: 'c15-85',
    canGoBack: true,
    appearAnimation: 'animation-from-up',
    disappearAnimation: 'animation-to-down',
  };
 */
  const customAnimations = {
    appearAnimation: 'animation-from-up',
    disappearAnimation: 'animation-to-down',
  };

  return (
    <WOlayout
      headerType="default"
      pageClass="wo-auto-ensurance"
      woData={woData}
      headerImage={headerImage}
    >
      {showStepList ? (
        <StepList
          customColor={woData.general.customColor}
          stepData={stepListData}
          continueHandler={continueHandler}
        />
      ) : (
        ''
      )}

      {loadRoutines()}

      {/*

        <CustomVideo customColor={woData.general.customColor} coachImg={anaImg} stepData={customVideoData} isActive={activeSteps.one} {...customAnimations}/>

        <CustomQuestion {...customProps} isActive={activeSteps.six} stepData={customQuestionData} />

        <FilterItemsList stepData={filterListData} customColor={woData.general.customColor} {...customAnimations} />

        */}

      <CustomProgressSteps
        customColor={woData.general.customColor}
        stepData={stepListData}
        coachImg={anaImg}
        {...customAnimations}
      />
    </WOlayout>
  );
};

export default WO_SeguroAuto;
