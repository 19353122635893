import React from 'react';

import './styles/stepList.css';

const StepList = ({ customColor, stepData, continueHandler }) => {
  const getSteps = () => stepData.map((step, idx) => (
      <div className="step-container" key={`step-list-item-${idx}`}>
        <div className="number-square">
          <div
            className={`number ${step.active ? '' : 'gray'}`}
            style={{ backgroundColor: customColor }}
          >
            <p>{idx + 1}</p>
          </div>

          {idx + 1 < stepData.length ? <div className="line-cool"></div> : ''}
        </div>

        <div className="text-c">
          <div className="title">{step.title}</div>

          <div className="description">{step.description}</div>

          <div className="info">{step.info}</div>
        </div>
      </div>
  ));

  return (
    <div className="step-info-step-list">
      {getSteps()}

      <div className="button">
        <button onClick={() => continueHandler()}>Comenzar</button>
      </div>
    </div>
  );
};

export default StepList;
