import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import CardOne from '../../components/Home/Card1Animate';
import GloveBoxCat from '../../components/common/GloveBoxCat';
import transito from '../../assets/img/anacoach/transito.svg';
import protection from '../../assets/img/anacoach/protection.svg';
import TAG from '../../assets/img/anacoach/TAG.svg';
import carService from '../../assets/img/anacoach/carService.svg';
import insurancePolicy from '../../assets/img/anacoach/insurancePolicy.svg';
import assistancePolicy from '../../assets/img/anacoach/assistancePolicy.svg';
import driverLicense from '../../assets/img/anacoach/driverLicense.svg';
import vehicleRegistration from '../../assets/img/anacoach/vehicleRegistrationCertificate.svg';
import tenancy from '../../assets/img/anacoach/tenancy.svg';
import verification from '../../assets/img/anacoach/verification.svg';
import emergencyPhone from '../../assets/img/anacoach/emergencyPhone.svg';
import checkIn from '../../assets/img/anacoach/travelCheckIn.svg';
import { FormContainer } from '../../assets/styles/GloveBox.styled';
import { RECOVERY_ROUTINE_BY_ORDER, GET_DOCS_USERS } from '../../queries';

const GloveBoxInfo = ({
  createJsonInput,
  questions,
  setQuestions,
}) => {
  const anchop = window.screen.width;
  const [prItems, setPrItems] = useState([]);
  const [trItems, setTrItems] = useState([]);
  const [orderR, setOrderR] = useState(1);
  const [responses, setResponses] = useState({});
  const [docsUrl, setDocsUrl] = useState([]);
  const noHay = 'Sin Información';

  /* ----------------------------------------------------------------
    -------------- RECUPERA LOS DOCUMENTOS DEL USUARIO --------------
    ----------------------------------------------------------------- */
  const [getDocumentsUsers] = useLazyQuery(GET_DOCS_USERS, {
    onCompleted({ getDocsUsers }) {
      try {
        if (!getDocsUsers) return null;
        // eslint-disable-next-line
        const docs = JSON.parse(getDocsUsers.response);
        if (docs.length !== 0) {
          setDocsUrl(docs);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error al obtener documentos !!! ==>>', error);
      }
      return null;
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error al ejecutar query !!! ==>>', err);
    },
  });

  /* ----------------------------------------------------------------
    -------------- RECUPERA LAS RESPUESTAS DEL USUARIO --------------
    ----------------------------------------------------------------- */
  const [getRecoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        if (!recoveryRoutineByOrder) return null;
        // eslint-disable-next-line
        const routines = JSON.parse(recoveryRoutineByOrder.response);
        Object.keys(routines).forEach((idRoutine) => {
          const { responses: responseAll } = routines[idRoutine];
          const responsesObject = {};
          if (responseAll.length) {
            responseAll.forEach((response) => {
              sessionStorage.setItem(
                response.questionName,
                response.responseValue,
              );
              responsesObject[response.idQuestion] = response;
            });
            setResponses({ ...responses, ...responsesObject });
          } else {
            setResponses({ ...responses });
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error al obtener respuestas !!! ==>>', error);
      }
      return null;
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error al ejecutar query !!! ==>>', err);
    },
  });

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser) {
      getDocumentsUsers({ variables: { idUser } });
    }
  }, []);

  useEffect(() => {
    getRecoveryRoutineByOrder({
      variables: {
        idTraining: sessionStorage.getItem('idTraining') || '44',
        orderRoutine: String(orderR),
        idUser: String(sessionStorage.getItem('idUser')),
        idCont: String(sessionStorage.getItem('idCont')),
      },
    });
  }, [orderR]);

  const getExactDoc = (num) => {
    let rel = '';
    if (docsUrl.length !== 0) {
      for (let i = 0; i < docsUrl.length; i += 1) {
        const idx = parseInt(docsUrl[i].CatCategoryDocuments_idCatCategoryDocuments, 10);
        if (idx === num) {
          rel = docsUrl[i].docURL;
          break;
        }
      }
    }
    return rel;
  };

  useEffect(() => {
    /* ----------------------------------------------------------------
    --------- FORMA EL ARREGLO DE LAS RESPUESTAS DEL USUARIO ----------
    ----------------------------------------------------------------- */
    const fechas = { uno: '', dos: '' };
    switch (orderR) {
      case 1:
        setOrderR(orderR + 1);
        break;
      case 2:
        fechas.uno = responses[1249] && responses[1250] && responses[1251]
          ? `${responses[1249].responseValue}/${responses[1250].responseValue}/${responses[1251].responseValue}`
          : noHay;
        setPrItems([
          {
            formIcon: insurancePolicy,
            formTitle: 'Póliza de seguro',
            vigencyText: 'Vigencia de la póliza',
            vigency: fechas.uno,
            payMethod: responses[1254] ? responses[1254].responseValue : noHay,
            coverage: responses[1253] ? responses[1253].responseValue : noHay,
            insurance: responses[1252] ? responses[1252].responseValue : noHay,
            showFileText: 'Póliza',
            documentFile: getExactDoc(1),
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 3:
        fechas.uno = responses[1257] && responses[1258] && responses[1259]
          ? `${responses[1257].responseValue}/${responses[1258].responseValue}/${responses[1259].responseValue}`
          : noHay;
        setPrItems([
          ...prItems,
          {
            formIcon: assistancePolicy,
            formTitle: 'Póliza de asistencia en el camino',
            vigencyText: 'Vigencia de la póliza',
            vigency: fechas.uno,
            payMethod: responses[1260] ? responses[1260].responseValue : noHay,
            insurance: responses[1261] ? responses[1261].responseValue : noHay,
            showFileText: 'Póliza',
            documentFile: getExactDoc(2),
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 4:
        fechas.uno = responses[1262] && responses[1263] && responses[1264]
          ? `${responses[1262].responseValue}/${responses[1263].responseValue}/${responses[1264].responseValue}`
          : noHay;
        fechas.dos = responses[1265] && responses[1266] && responses[1267]
          ? `${responses[1265].responseValue}/${responses[1266].responseValue}/${responses[1267].responseValue}`
          : noHay;
        setPrItems([
          ...prItems,
          {
            formIcon: carService,
            formTitle: 'Servicio de auto',
            lastServiceDate: fechas.uno,
            nextServiceDate: fechas.dos,
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 5:
        setPrItems([
          ...prItems,
          {
            formIcon: TAG,
            formTitle: 'TAG',
            TAGNumber: responses[1268] ? responses[1268].responseValue : noHay,
            balance: '$1000',
            showFileText: 'Recibos de peaje',
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 6:
        fechas.uno = responses[1269] && responses[1270] && responses[1271]
          ? `${responses[1269].responseValue}/${responses[1270].responseValue}/${responses[1271].responseValue}`
          : noHay;
        setTrItems([
          {
            formIcon: driverLicense,
            formTitle: 'Licencia de conducir',
            vigencyText: 'Vigencia de tu licencia',
            vigency: fechas.uno,
            showFileText: 'Licencia',
            documentFile: getExactDoc(5),
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 7:
        fechas.uno = responses[1273] && responses[1274] && responses[1275]
          ? `${responses[1273].responseValue}/${responses[1274].responseValue}/${responses[1275].responseValue}`
          : noHay;
        setTrItems([
          ...trItems,
          {
            formIcon: vehicleRegistration,
            formTitle: 'Tarjeta de circulación',
            vigencyText: 'Vigencia de tu tarjeta',
            vigency: fechas.uno,
            showFileText: 'Tarjeta',
            documentFile: getExactDoc(6),
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 8:
        fechas.uno = responses[1277] && responses[1278] && responses[1279]
          ? `${responses[1277].responseValue}/${responses[1278].responseValue}/${responses[1279].responseValue}`
          : noHay;
        setTrItems([
          ...trItems,
          {
            formIcon: tenancy,
            formTitle: 'Tenencia',
            vigencyText: 'Fecha de pago',
            vigency: fechas.uno,
            showFileText: 'Comprobante',
            documentFile: getExactDoc(7),
          },
        ]);
        setOrderR(orderR + 1);
        break;
      case 9:
        fechas.uno = responses[1283] && responses[1284] && responses[1285]
          ? `${responses[1283].responseValue}/${responses[1284].responseValue}/${responses[1285].responseValue}`
          : noHay;
        setTrItems([
          ...trItems,
          {
            formIcon: verification,
            formTitle: 'Verificación',
            calco: responses[1281] ? responses[1281].responseValue : noHay,
            gummed: responses[1282] ? responses[1282].responseValue : noHay,
            nextVerificationDate: fechas.uno,
            showFileText: 'Comprobante',
            documentFile: getExactDoc(8),
          },
        ]);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Por si se agrega una nueva categoria');
        break;
    }
  }, [responses]);

  /* ----------------------------------------------------------------
    ---------------------- NUMEROS TELEFONICOS ----------------------
    ----------------------------------------------------------------- */
  const securityItems = [
    {
      formIcon: emergencyPhone,
      formTitle: 'Teléfonos de emergencias',
      emergencies: [
        {
          title: 'Emergencias',
          phones: ['911'],
        },
        {
          title: 'CAFUPE',
          phones: ['074'],
        },
        {
          title: 'Ángeles Verdes',
          phones: ['078'],
        },
        {
          title: 'Guardia Nacional',
          phones: ['088'],
        },
        {
          title: 'Cruz Roja',
          phones: ['(55) 5557 5757', '(55) 5395 1111'],
        },
        {
          title: 'Control de Incendios Forestales',
          phones: [
            '(55) 5554 0612',
            '(55) 5554 7097',
            '(55) 5653 1369',
          ],
        },
        {
          title: 'Locatel',
          phones: ['(55) 5658 1111'],
        },
      ],
      insurances: [
        {
          title: 'ABA',
          phones: [
            '800 712 2828',
            '800 834 3400',
            '800 716 5467',
          ],
        },
        {
          title: 'AIG',
          phones: [
            '(CDMX) (55) 5488 4723',
            '800 001 1300',
          ],
        },
        {
          title: 'Allianz',
          phones: [
            '(CDMX) (55) 5201 3181',
            '800 1111 400',
          ],
        },
        {
          title: 'ANA',
          phones: [
            '(CDMX) (55) 5322 8282',
            '800 911 2627',
          ],
        },
        {
          title: 'Aseguradora Patrimonial',
          phones: [
            '(CDMX) (55) 5636 2459',
            '800 552 4910',
          ],
        },
        {
          title: 'Atlas',
          phones: ['800 849 3917'],
        },
        {
          title: 'AXA',
          phones: ['800 900 1292'],
        },
        {
          title: 'Banorte',
          phones: ['800 500 1500'],
        },
        {
          title: 'BBVA Bancomer',
          phones: ['800 874 3283'],
        },
        {
          title: 'Chubb',
          phones: ['800 362 7288'],
        },
        {
          title: 'CitiBanamex',
          phones: ['(55) 1226 8100'],
        },
        {
          title: 'El Águila',
          phones: ['800 705 8800'],
        },
        {
          title: 'El Potosí',
          phones: ['800 009 0000'],
        },
        {
          title: 'General de Seguros',
          phones: [
            '(CDMX) (55) 5278 8000',
            '800 472 7696',
          ],
        },
        {
          title: 'GNP',
          phones: ['(55) 5227 9000'],
        },
        {
          title: 'HDI',
          phones: ['800 019 6000'],
        },
        {
          title: 'HSBC',
          phones: ['800 900 1292'],
        },
        {
          title: 'Inbursa',
          phones: [
            '(CDMX) (55) 5258 2880',
            '800 909 0000',
          ],
        },
        {
          title: 'Mapfre',
          phones: ['800 062 7373'],
        },
        {
          title: 'Primero Seguros',
          phones: ['800 2774 637'],
        },
        {
          title: 'Quálitas',
          phones: [
            '(CDMX) (55) 5258 2880',
            '800 288 6700',
            '800 800 2880',
          ],
        },
        {
          title: 'Santander',
          phones: [
            '(CDMX) (55) 5169 4300',
            '800 501 0000',
          ],
        },
        {
          title: 'Seguros Azteca',
          phones: [
            '(CDMX) (55) 1720 9854',
            '800 810 8181',
          ],
        },
        {
          title: 'Sura (RSA)',
          phones: ['800 911 7692'],
        },
        {
          title: 'Ve Por Más (Multiva)',
          phones: ['800 830 3676'],
        },
        {
          title: 'Zurich',
          phones: ['800 288 6911'],
        },
      ],
    },
    {
      formIcon: checkIn,
      formTitle: 'Check-in de viaje',
    },
  ];

  return (
    <div className='marginComentLgSuccess1'>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard='cardOne'
        classTop='topOne1'
        textbold="Te doy la bienvenida a tu guantera de Auto Digital"
        text="Todos los documentos de tu auto en un solo lugar"
      />

      <FormContainer padding='0' gap='27px' bgColor='transparent'>
        <GloveBoxCat
          color='#22C4CE'
          name='Protección'
          imagen={protection}
          show={false}
          id={0}
          items={prItems}
          isHome={true}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
        />

        <GloveBoxCat
          color='#A5A6F6'
          name='Tránsito / Legal'
          imagen={transito}
          show={false}
          id={0}
          items={trItems}
          isHome={true}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
        />

        <GloveBoxCat
          color='#FF7061'
          name='Seguridad en el camino'
          imagen={TAG}
          show={false}
          id={0}
          items = {securityItems}
          isHome={true}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
        />
      </FormContainer>
    </div>
  );
};

GloveBoxInfo.propTypes = {
  createJsonInput: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
};

export default GloveBoxInfo;
