import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TrainingMain,
  TrainingText,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  CardsBtn,
  DivFlex,
} from '../../assets/styles/Training.styled';
import { ImageSticker } from '../../assets/styles/Feed.styled';
import Starttraining from './Starttraining';
import correct from '../../assets/img/anacoach/corect.png';
import gymOn from '../../assets/img/anacoach/WO.svg';
import ClickButton from '../../Helpers/HookHelpers';

const SuccessBullets = ({
  title, txt, idTraining, bullets,
}) => {
  const [goFeed, setGoFeed] = useState(false);
  const history = useHistory();
  const { actions } = ClickButton();

  const overwrite = () => {
    sessionStorage.setItem(`overwrite_WO_${idTraining}`, true);
    sessionStorage.setItem('cc_allowFastTrack', true);
    sessionStorage.removeItem('UsoDinero');
    sessionStorage.removeItem('DIneroNecesidad');
    sessionStorage.removeItem('TiempoParaPagarPrestamo');
    sessionStorage.removeItem('Ingreso_Mensual_Fijo');
    sessionStorage.removeItem('Ocupacion');
    sessionStorage.setItem('DataPerson', 'true');
    window.location.reload();
  };

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  return (
        <Fragment>
            <TrainingMain wiw="670px">
                <br />
                <br />
                <br />
                <br />
                <br />
                <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="10">
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <Starttraining
                            title={title}
                            text={txt}
                        />
                    </TrainingDivSection2>
                </TrainingSectionGray>

                {bullets
                  ? <DivFlex className="align-items-center mt-4 pt-2" wid="100%" margin="auto" widthweb="50%" marweb="auto">
                        <br />
                        <br />
                        {bullets.map((item) => (
                            <TrainingSectionGray wiw="130px" back="#F3F3F3" mtop="0" style={{ height: '130px', margin: '10px' }}>
                                <div className="d-flex flex-row justify-content-center">
                                    <Images
                                        src={correct}
                                        top="10px"
                                        topweb="10px"
                                        left="0px"
                                        leftw="0px"
                                        width="28px"
                                        widthw="1.6vw"
                                        height="1.6vw"
                                        position="relative"
                                        rigthw="12px"
                                        className="img-fluid"
                                        heightsm="18px"
                                        widthsm="18px"
                                        width768="13px"
                                        height768="13px"
                                    ></Images>
                                </div>
                                <TrainingText size="0.8em" sizew="1em" sizexs="0.8em" className="roboto" align="center" alignweb="center" ls="-0.2px" style={{ padding: '10px' }}>
                                    {item.bullet}
                                </TrainingText>
                            </TrainingSectionGray>
                        ))}
                    </DivFlex>
                  : null
                }
                <TrainingDivSection back="transparent" backw="transparent" bsha="" mt="44px" mtw="40px" br="4px" padding="21px 20px" wi="100%" wiw="670px" he="100%" hew="100%">
                    <div>
                        <CardsBtn
                            windthmd="90%"
                            minmd="40%"
                            mleftmd="5%"
                            mrightmd="auto"
                            windthG="400px"
                            min_w="400px"
                            min_web="400px"
                            min_mini="80%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="15%"
                            mright="15%"
                            mleftweb="18%"
                            mrightweb="auto"
                            mtop="25px"
                            mtopw="25px"
                            type="button"
                            onClick={() => overwrite()}
                        >{bullets ? 'Entrenar de nuevo' : 'Iniciar solicitud'}
                        </CardsBtn>
                        <CardsBtn
                            windthmd="90%"
                            minmd="40%"
                            mleftmd="5%"
                            mrightmd="auto"
                            windthG="400px"
                            min_w="400px"
                            min_web="400px"
                            min_mini="80%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="15%"
                            mright="15%"
                            mleftweb="18%"
                            mrightweb="auto"
                            mtop="25px"
                            mtopw="25px"
                            type="button"
                            onClick={() => setGoFeed(true)}
                            color="#EC407A !important"
                            back="#fff !important"
                            border="solid 1px !important"
                            padd="8px 15px 4px"
                            heigth="49px"
                            className=""
                        >
                            <ImageSticker marg='-5px 20px auto auto' width='35px' src={gymOn} />
                            <b>Ir a entrenamientos</b>
                        </CardsBtn>
                    </div>
                </TrainingDivSection>
            </TrainingMain>
        </Fragment>
  );
};
export default SuccessBullets;
