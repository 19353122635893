/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const QuickSavings = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  handleRp,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    console.log('Entro useEffect AHORRO QUICK');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [mainGoal, setMainGoal] = useState('');

  const [form, setForm] = useState({});

  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        setMainGoal(val.value);
        handleRp('rp', val.value);
        setOrderRutine(0);
        break;
      default:
        console.log(val);
    }
  };
  const anchop = window.screen.width;
  useEffect(() => {
    if (
      recoveryRoutine === 'activa'
      || (sessionStorage.getItem('idUser') === '0'
        && sessionStorage.getItem('idCont') === '0')
    ) {
      setMainGoal(sessionStorage.getItem('ObjetivoAhorro') || '');
      if (sessionStorage.getItem('ObjetivoAhorro')) {
        handleRp('rp', sessionStorage.getItem('ObjetivoAhorro'));
        setOrderRutine(0);
      }
    }
  }, [recoveryRoutine]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: '#DefinePrioridades' });
      setLoaderWait(false);
    }, 3000);
  }, []);
  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        {!loaderWait || orderRutine !== 5 ? (
          <>
            <div id="DefinePrioridades"></div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Definir tus prioridades nos ayudará a acelerar el plan de tu ahorro."
              hora={hora}
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Selecciona cuál es tu principal objetivo:"
              hora={hora}
            />

            <TrainingQuestion className={mainGoal === '' ? '' : 'hidden'}>
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="0-0"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <CardTwo
              classCard={mainGoal !== '' ? 'cardTwoResp' : 'hidden'}
              classTop="topTwo1"
              imgMan="hidden"
              text={mainGoal}
              colorTraining="#7FBD62"
            />
          </>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </div>
  );
};

export default QuickSavings;
