import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';

const DatosAddress = ({
  id, label, helperText, inputProps, value, handleChangeAddress,
}) => (
        <Fragment>
           <TextField
                id={id}
                name={id}
                label={label}
                fullWidth
                variant="outlined"
                onChange={handleChangeAddress}
                helperText={helperText}
                value={value}
                inputProps={inputProps}
                >
            </TextField>
        </Fragment>
);

export default DatosAddress;
