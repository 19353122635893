import React, { Fragment } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Col7, Container, Col2, Col10,
} from '../common/common.styled';
import ImageCoachInicio from './ImageCoachInicio';
import {
  TrainingSubtitles, TrainingText, TrainingDivSection, TrainingBenef,
} from '../../assets/styles/Training.styled';

function Featuresxs({
  title, Arraytext, text2, text3, text4, showImg = true, classAna, textintro, children, titleAlign = 'left',
}) {
  return (
      <Fragment>
         <Container width="134%" mt="0px" widthMobile="90%" mtMobile="0" displayweb="flex">
        {/* {showImg ? <ImageCoachInicio topMobile="35px"  Starttraining={true} /> : null} */}
            {showImg ? <ImageCoachInicio classAna = {classAna} topMobile="35px"/> : null}
            <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
               <TrainingDivSection
                  hew="auto"
                  paddw="26px"
                  mlw="0%"
                  wiw="100%"
                  back="#F3F3F3"
                  backw="#F3F3F3"
                  bshaw="none"
                  bsha=""
                  br="4px"
                  padding="20px"
                  wi="100%"
                  he="auto"
                  mb="0px"
                  className="topOne1Init"
               >
                  {title != null && title !== undefined ? (
                     <>
                     <TrainingSubtitles sizew="20px" size="20px" fw="600" align={titleAlign} alignweb={titleAlign} className="cabin">
                        {title}
                     </TrainingSubtitles>
                     <p className="roboto" style={{ fontSize: 16 }}>{textintro}</p>
                     </>
                  ) : null}
                  {Arraytext[0].map((feacture, index) => (
                     <Container key={index} width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem" /* bb= {index < Arraytext.length ? "1px solid #CDCDCD" : " " } */>
                        <Col2 display="flex" mt="initial">
                           <TrainingBenef
                              bbs="none"
                              bbsw="none"
                              bbw="1px"
                              pt="17px"
                              pb="17px"
                              he="auto"
                              pl="0px"
                              mt="0px"
                              className="roboto"
                              alignweb="center"
                              ls="-0.2px"
                              width="100%"
                           >
                              <CheckBoxIcon style={{ color: feacture.color, fontSize: 23 }} />
                           </TrainingBenef>
                        </Col2>
                        <Col10 padding="0px" pMobile="0px 15px">
                           <TrainingBenef
                              pt="17px"
                              pb="17px"
                              he="auto"
                              pl="0px"
                              mt="0px"
                              className="roboto"
                              alignweb="left"
                              ls="-0.2px"
                              size="16px"
                           >
                              {feacture.text}
                           </TrainingBenef>
                        </Col10>
                     </Container>
                  ))}

                  {text2 != null ? (
                     <TrainingText size="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                        {text2}
                     </TrainingText>
                  ) : null}
                  {children}
               </TrainingDivSection>
            </Col7>
         </Container>
      </Fragment>
  );
}

export default Featuresxs;
