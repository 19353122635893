import React, { useState, Fragment, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
  RECOVERY_TRAINING,
  BACKUP_WO_DATA,
  CREATE_SESSION_MYSQL,
  DYNAMIC_GET,
  DYNAMIC_INSERT,
} from '../../mutations';
import {
  GET_TRAINING_PROGRESS,
  CHECK_TRAINING_FINISHED,
  GET_TRAINING_STATUS,
  GET_TRAINING_COLOR,
} from '../../queries';
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainigTam,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import Header from '../../components/common/Header';
import WaitList from '../../components/Training/WaitList';
import imgWo from '../../assets/img/anacoach/fastTrack.svg';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import { Container, Col7 } from '../../components/common/common.styled';
import RoutinesOrderFastTrack from './RoutinesOrderFastTrack';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import Starttraining from '../../components/Training/Starttraining';
import WOlayout from '../../components/layouts/WOlayout';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import SuccessWOValid from '../../components/Training/SuccessWOValid';
import SuccessBullets from '../../components/Training/SuccessBullets';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';

const items2 = ['Préstamos a tu medida'];
const idTraining = 29;
const FastTrack = () => {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', idTraining);

  const anchop = window.screen.width;

  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  // End ValidWaitList
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(idTraining);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  const [routinesSaves] = useState([
    {
      1: false,
    },
    {
      2: false,
    },
    {
      3: false,
    },
    {
      4: false,
    },
  ]);
  // copia y pega estas mutaciones para crear un nuevo entrenamiento
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GET_TRAINING_PROGRESS, {
    onCompleted({ getTrainingProgress }) {
      let percent = 0;
      if (!getTrainingProgress.achievment) {
        percent = 0;
      } else {
        percent = getTrainingProgress.achievment;
      }
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine !== null) {
        if (insertRoutine.statusCode === 200) {
          const jsonResponse = JSON.parse(insertRoutine.response);
          console.log('insertRoutine');
          console.log(jsonResponse);

          if (insertRoutine.message === 'usuario existente') {
            // validar que no este logueado

            if (
              sessionStorage.getItem('idUser') == null
              && sessionStorage.getItem('idDatSession') == null
            ) {
              sessionStorage.setItem('idUser', jsonResponse.idUser);
              sessionStorage.setItem('idCont', jsonResponse.idCont);
              if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
                sessionStorage.setItem(
                  'datOpportunity',
                  parseInt(jsonResponse.idDatOpportunity),
                );
              }
            } else {
              if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
                sessionStorage.setItem(
                  'datOpportunity',
                  parseInt(jsonResponse.idDatOpportunity),
                );
              }
            }
            if (
              jsonResponse.idSession !== ''
              || jsonResponse.idSession != null
            ) {
              sessionStorage.setItem('idDatSession', jsonResponse.idSession);
              sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
              const inputDynamicUpdate = {
                input: {
                  table: 'DatSession',
                  columns: [
                    {
                      setkey: 'Source',
                      setval: `${sessionStorage.getItem('source')}`,
                    },
                    {
                      setkey: 'Medium',
                      setval: `${sessionStorage.getItem('medium')}`,
                    },
                    {
                      setkey: 'Campaign',
                      setval: `${sessionStorage.getItem('campaign')}`,
                    },
                    {
                      setkey: 'Content',
                      setval: `${sessionStorage.getItem('content')}`,
                    },
                    {
                      setkey: 'PageName',
                      setval: `${sessionStorage.getItem('pagename')}`,
                    },
                    {
                      setkey: 'Hostname',
                      setval: `${sessionStorage.getItem('hostname')}`,
                    },
                  ],
                  conditions: {
                    valone: 'IDdatSession',
                    valtwo: `${jsonResponse.idSession}`,
                    condition: '=',
                  },
                },
              };
              if (
                Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
              ) {
                inputDynamicUpdate.input.columns.push({
                  setkey: 'DatVirality_IdVirality',
                  setval: `${sessionStorage.getItem('viralityID')}`,
                });
              }
              dynamicUpdate({ variables: inputDynamicUpdate });
            }
          } else {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
          const currentOrderRoutine = sessionStorage.getItem(
            'currentOrderRoutine',
          );
          console.log('307', currentOrderRoutine)
          saveAdvance(parseInt(currentOrderRoutine));
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        sessionStorage.setItem(
          'Achievment-29',
          parseFloat(porcentaje[1]).toFixed(2).toString(),
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [backupWOData] = useMutation(BACKUP_WO_DATA, {
    onCompleted({ backupWOData }) {
      if (backupWOData.statusCode === 200) {
        console.log(backupWOData.response);
      }
    },
  });

  // Valid Finished WO
  const [achievementCC, setAchievementCC] = useState(0);
  const [endDateCC, setEndDateCC] = useState();
  const [allowOverwrite, setAllowOverwrite] = useState(false);
  const [backupCC, setBackupCC] = useState();
  const [finished, setFinished] = useState(false);
  const [finishedCC, setFinishedCC] = useState(false);
  const [checkCCTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    fetchPolicy: 'no-cache',
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const { avance, fechaTermino } = JSON.parse(
          checkTrainingFinished.response,
        );
        setAchievementCC(avance);
        setEndDateCC(fechaTermino);
        checkFastTrackTrainingFinished({
          variables: {
            idUser: parseInt(sessionStorage.getItem('idUser')),
            idTraining: parseInt(currentTraining),
          },
        });
      }
    },
  });
  const [checkFastTrackTrainingFinished] = useLazyQuery(
    CHECK_TRAINING_FINISHED,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ checkTrainingFinished }) {
        if (checkTrainingFinished.statusCode === 200) {
          const idTraining = sessionStorage.getItem('idTraining');
          const trainingInfo = JSON.parse(checkTrainingFinished.response);
          const { avance: achievementFastTrack } = trainingInfo;
          const overwrite = sessionStorage.getItem(`overwrite_WO_${idTraining}`) || 'false';
          const allowFastTrack = sessionStorage.getItem('cc_allowFastTrack') || 'false';
          const firstName = sessionStorage.getItem('FirstName') || '';
          const middleName = sessionStorage.getItem('MiddleName')
            ? ` ${sessionStorage.getItem('MiddleName')}`
            : '';
          const fathersLastName = sessionStorage.getItem('FathersLastName')
            ? ` ${sessionStorage.getItem('FathersLastName')}`
            : '';
          const mothersLastName = sessionStorage.getItem('MothersLastName')
            ? ` ${sessionStorage.getItem('MothersLastName')}`
            : '';

          if (overwrite === 'true' && achievementFastTrack === 100) {
            setAllowOverwrite(true);
            setPercentage('0%');
            sessionStorage.removeItem('UsoDinero');
            sessionStorage.removeItem('DIneroNecesidad');
            sessionStorage.removeItem('TiempoParaPagarPrestamo');
            sessionStorage.removeItem('Ingreso_Mensual_Fijo');
            sessionStorage.removeItem('Ocupacion');
            setOrderRutine(4);
            getTrainingData();
          } else if (achievementFastTrack === 100) {
            setFinished(true);
            setTrainingFinishedInfo({
              ...setTrainingFinishedInfo,
              fullName: `${firstName}${middleName}${fathersLastName}${mothersLastName}`,
              phone: sessionStorage.getItem('cellPhone'),
              email: sessionStorage.getItem('Email1'),
              meetsCondition: true,
              shared: true,
              idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
              title: 'Tu solicitud está en proceso',
              txt1: ` Tu última solicitud de préstamo personal se realizó el ${moment(
                trainingInfo.fechaTermino,
              ).format('DD/MM/YYYY')},
                                te recomendamos esperar tres meses para solicitar un nuevo
                                crédito y que tengas más posibilidades de que sea aprobado.`,
            });
          } else if (
            achievementCC === 100
            && achievementFastTrack < 100
            && allowFastTrack !== 'true'
          ) {
            setFinishedCC(true);
            setTrainingFinishedInfo({
              ...setTrainingFinishedInfo,
              idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
              title: `¡Hola, ${firstName}!`,
              txt: ` Realizaste una solicitud de tarjeta de crédito el ${moment(
                endDateCC,
              ).format('DD/MM/YYYY')},
                                te recomendamos esperar tres meses para solicitar un nuevo
                                crédito y que tengas más posibilidades de que sea aprobado.`,
            });
          }
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [recoveryTraining] = useMutation(RECOVERY_TRAINING, {
    onCompleted({ recoveryTraining }) {
      // setRecoveryState(recoveryTraining.response)
      try {
        const json = JSON.parse(recoveryTraining.response);

        const indices = Object.keys(json);
        const bckData = [];
        for (const indice in indices) {
          const idRoutine = indices[indice];
          const {
            Name: name,
            RoutineType: routineType,
            questions,
            responses,
          } = json[idRoutine];

          const routine = {
            name,
            routineType,
            questionsAndresponses: [],
          };

          questions.map((question, index) => {
            routine.questionsAndresponses.push({
              questionName: question.QuestionName,
              responseValue: responses[index]?.responseValue || '',
            });
          });

          bckData.push(routine);
        }

        const idUser = sessionStorage.getItem('idUser');
        const idTraining = sessionStorage.getItem('idTraining');
        const idCont = sessionStorage.getItem('idCont');
        setBackupCC({
          routines: bckData,
          idUser,
          idTraining,
          idCont,
        });
      } catch (error) {
        console.log('exception recovery', error.message);
      }
    },
  });

  useEffect(() => {
    if (backupCC && backupCC.routines.length > 0 && allowOverwrite) {
      const mongoInput = {
        input: {
          collection: 'backup_wo_routines',
          data: JSON.stringify(backupCC),
        },
      };
      backupWOData({ variables: mongoInput });
    }
  }, [backupCC]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    // const timestamp = today;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
const[contQ,setContQ] = useState(0)
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions ob', questions);
    setContQ(contQ + 1);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          const nameSession = arraySessionsRutines[questionRoutine.DatUserDetailStorage];
          sessionStorage.setItem(nameSession, item.value);

          switch (nameSession) {
            case 'Email1':
              localStorage.setItem('Email1', item.value);
              break;
            case 'CellPhone':
              localStorage.setItem('CellPhone', item.value);
              break;
            case 'idCont':
              localStorage.setItem('idCont', item.value);
              break;
            case 'idUser':
              localStorage.setItem('idUser', item.value);
              break;
            default:
              break;
          }

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1') || '';
        inputRutine.cellphone = sessionStorage.getItem('CellPhone') || '';
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  const saveAdvance = async (orderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    if (
      orderRoutine <= 4
      && routinesSaves[orderRutine - 1][orderRutine] === false
    ) {
      const inputProgress = {
        input: {
          idRoutine: '',
          orderRoutine: String(orderRoutine),
          idTraining: String(currentTraining),
          idUser: String(idUser),
          idCont: String(idCont),
          isMaster: '1',
        },
      };
      console.log('input progress');
      console.log(JSON.stringify(inputProgress));
      if (idUser != null) {
        routinesSaves[orderRoutine - 1][orderRoutine] = true;
        console.log(
          ' routinesSaves[idRoutine-1] final',
          routinesSaves[orderRoutine- 1],
        );
        saveStatusTraining({ variables: inputProgress });
      }
    }
    if (orderRoutine === 4 &&  contQ === 8) {
          
          for(let index = 1; index <=4; index++){
            console.log('se activa savesStatusTraining para completar 100%')
            const inputProgress = {
              input: {
                idRoutine: '',
                orderRoutine: String(index),
                idTraining: String(currentTraining),
                idUser: String(idUser),
                idCont: String(idCont),
                isMaster: '1',
              },
            };
            if (idUser != null) {
              //routinesSaves[orderRoutine- 1][orderRoutine] = true;
              console.log('entre en if activa')
              await saveStatusTraining({ variables: inputProgress });
            }

          }
        
    }
    /*
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    } */
  };

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { idUser: parseInt(idUser), idTraining: currentTraining },
      });
    } else {
      setPercentage('0');
    }
  }, []);

  const getTrainingData = () => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryTraining({
        variables: {
          idTraining: sessionStorage.getItem('idTraining') || '29',
          idUser: sessionStorage.getItem('idUser') || '',
          idCont: sessionStorage.getItem('idCont') || '',
        },
      });
    }
  };

  const getProgressAgain = async () => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      await getPercentageTraining({
        variables: { idUser: parseInt(idUser), idTraining: currentTraining },
      });
    } else {
      setPercentage('0');
    }
  };
  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
    } = getQueryVariableHashtagDeeplink('FastTrack');
    const currentTraining = sessionStorage.getItem('idTraining');
    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    const idTrainingCC = 1;
    checkCCTrainingFinished({
      variables: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: idTrainingCC,
      },
    });

    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }

    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);
  const [getIDTriggerNotification] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet) {
        const response = JSON.parse(dynamicGet.response);
        const { id } = response[0];
        insertCatNotificationTracking({
          variables: {
            input: {
              table: 'CatNotificationTracking',
              columns: ['DatSession_IDDatSession', 'CatTriggerNotification_Id'],
              detailValues: {
                values: [sessionStorage.getItem('idDatSession'), String(id)],
              },
            },
          },
        });
      }
    },
  });
  const [insertCatNotificationTracking] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert) {
        console.log(dynamicInsert);
      }
    },
  });
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const triggerName = params.get('trigger');
    if (triggerName && sessionStorage.getItem('idDatSession')) {
      getIDTriggerNotification({
        variables: {
          input: {
            table: 'CatTriggerNotification',
            columns: ['*'],
            conditions: [
              {
                valone: 'triggerName',
                condition: '=',
                valtwo: triggerName,
              },
            ],
          },
        },
      });
    }
  }, [sessionStorage.getItem('idDatSession')]);
  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('#6677E3');

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);
  const scroll1 = document.documentElement.scrollTop;
  const [checkScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
        }
      } else {
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  useEffect(() => {
    if (orderRutine === 5) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
      sessionStorage.setItem(`overwrite_WO_${idTraining}`, false);
    }
  }, [orderRutine]);

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '20 minutos',
    imgWo: { imgWo },
    txtsubtitle: 'Consigue el tuyo de manera sencilla y sin tantos trámites',
  };

  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>
          Ventajas y Desventajas de Solicitar un Crédito o Préstamo | Coru
        </title>
        <link
          rel="canonical"
          href="https://coru.com/wo/fast-track"
        />
        <meta
          name="keywords"
          content="ventajas y desventajas de solicitar un credito o prestamo"
        />
        <meta
          name="description"
          content="Ventajas y Desventajas de Solicitar un Crédito o Préstamo. ¿Cuál es mejor? Gasta menos pagando a tiempo y sigue estos consejos."
        />
      </Helmet>
      <TrainigTam>
        <Header
          background={colorTraining}
          pathname={window.location.pathname}
        />
      </TrainigTam>
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            {!finished && !finishedCC ? (
              <Fragment>
                <TrainingAnimateFunnel
                  bcolor={colorTraining}
                  pmob="0 0 0 0"
                  margin="0 0 24px 0"
                  minh="309px"
                  hweb="410px"
                >
                  {!allowOverwrite ? (
                    <ModalDataPersonalInitWO
                      isData={isData}
                      setData={setisData}
                    />
                  ) : null}
                  <BarraInfoWO propied={propied} />
                  {scroll1 > 0 ? <BarraScroll /> : null}
                  <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
                  {percentage !== '' ? (
                    <TrainingLine
                      id="line-percent-pink"
                      bcolor="#F64282"
                      mt=""
                      mtw="404px"
                      wweb={percentage}
                      w={percentage}
                      mr="auto"
                      op="1"
                      br="100px"
                    ></TrainingLine>
                  ) : null}
                </TrainingAnimateFunnel>
                <DataPersonalInitWO
                  Date={Date}
                  hora={hora}
                  orderRutine={orderRutine}
                  setOrderRutine={setOrderRutine}
                  currentTraining={currentTraining}
                  createJsonInput={createJsonInput}
                  questions={questions}
                  setQuestions={setQuestions}
                />
                <div style={{position: 'relative'}}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                  {active0 ? (
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          <div className="marginComentLgSuccess1">
                            <Starttraining
                              title="¡Hola! Soy Ana, tu coach financiero."
                              text="En este entrenamiento te ayudaré a encontrar el mejor préstamo para tí sin afectar tus finanzas."
                            />
                          </div>
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    ) : null}
                    {active1 ? (
                      <Fragment>
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="Iniciemos esta aventura..."
                            text=" A continuación te haré algunas preguntas para ofrecerte el mejor préstamo para tus necesidades:"
                          />
                        </div>
                        <div
                          className={
                            orderRutine === 5
                              ? 'marginComentLgSuccess1'
                              : 'marginComentLgSuccess1'
                          }
                        >
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <RoutinesOrderFastTrack
                                Date={stateDate}
                                hora={hora}
                                orderRutine={orderRutine}
                                setOrderRutine={setOrderRutine}
                                currentTraining={currentTraining}
                                createJsonInput={createJsonInput}
                                questions={questions}
                                setQuestions={setQuestions}
                                trainingColor={colorTraining}
                                percentage={percentage}
                                getProgressAgain={getProgressAgain}
                                setPercentage={setPercentage}
                              />
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                        </div>
                      </Fragment>
                    ) : null}
                    {recomendaciones && orderRutine === 5 ? (
                      <Fragment>
                        <div className="marginComentLgSuccess1">
                          <Container
                            mleftmxs="0%"
                            mleft="18%"
                            mleftm="7%"
                            width="100%"
                            widthMobile="100%"
                            mtMobile="0px"
                          >
                            <Feed_Section
                              padding="3% 0 5% 0"
                              paddingm="0% 5% 5% 5%"
                            >
                              <ContentHomeSection
                                widthM="100%"
                                leftD="0%"
                                floatD=" "
                                width="80%;"
                              >
                                <Recommendations
                                  items={recommendedTraining}
                                ></Recommendations>
                              </ContentHomeSection>
                            </Feed_Section>
                          </Container>
                        </div>
                      </Fragment>
                    ) : null}
                    {!active1 ? (
                      <Fragment>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="0rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio topMobile="35px" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </div>
                  <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {finished ? <SuccessWOValid {...trainingFinishedInfo} /> : null}

                {finishedCC ? (
                  <SuccessBullets {...trainingFinishedInfo} />
                ) : null}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
};

export default FastTrack;
