/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_VALUE_ANSWER_BY_ID,
  GET_QUESTIONS_CORRECT_ANSWER,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import corect from '../../assets/img/anacoach/corect.png';
import incorect from '../../assets/img/anacoach/incorect.png';

const ViralityQuestionnaire = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
}) => {
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [pregunta1TestViralidad, setPregunta1TestViralidad] = useState('');
  const [pregunta2TestViralidad, setPregunta2TestViralidad] = useState('');
  const [pregunta3TestViralidad, setPregunta3TestViralidad] = useState('');
  const [pregunta4TestViralidad, setPregunta4TestViralidad] = useState('');
  const [pregunta5TestViralidad, setPregunta5TestViralidad] = useState('');
  const [relAnswers, setRelAnswers] = useState([]);
  const [form, setForm] = useState({});
  const [editPregunta1TestViralidad, seteditPregunta1TestViralidad] = useState(
    pregunta1TestViralidad !== '',
  );
  const [editPregunta2TestViralidad, seteditPregunta2TestViralidad] = useState(
    pregunta2TestViralidad !== '',
  );
  const [editPregunta3TestViralidad, seteditPregunta3TestViralidad] = useState(
    pregunta3TestViralidad !== '',
  );
  const [editPregunta4TestViralidad, seteditPregunta4TestViralidad] = useState(
    pregunta4TestViralidad !== '',
  );
  const [editPregunta5TestViralidad, seteditPregunta5TestViralidad] = useState(
    pregunta5TestViralidad !== '',
  );

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contVideos = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (questionType === 'Video') {
                contVideos++;
                console.log('video ', contVideos);
              }
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getValueAnswerById] = useLazyQuery(GET_VALUE_ANSWER_BY_ID, {
    onCompleted({ getValueAnswerById }) {
      const score = getValueAnswerById;
      setRelAnswers((prevState) => [...prevState, score]);
    },
  });

  const [getQuestionsWithCorrectAnswers] = useLazyQuery(
    GET_QUESTIONS_CORRECT_ANSWER,
    {
      onCompleted({ getQuestionsWithCorrectAnswers }) {
        const { questions } = JSON.parse(
          getQuestionsWithCorrectAnswers.response,
        );
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetailViralityTest',
          JSON.stringify(questions),
        );
      },
    },
  );

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    const { IDCatQuestionsDetail, value, step } = val;
    getValueAnswerById({
      variables: {
        idAnswer: String(IDCatQuestionsDetail),
      },
    });

    switch (step) {
      case 0:
        setPregunta1TestViralidad(value);
        seteditPregunta1TestViralidad(true);
        break;
      case 1:
        setPregunta2TestViralidad(value);
        seteditPregunta2TestViralidad(true);
        break;
      case 2:
        setPregunta3TestViralidad(value);
        seteditPregunta3TestViralidad(true);
        break;
      case 3:
        setPregunta4TestViralidad(value);
        seteditPregunta4TestViralidad(true);
        break;
      case 4:
        setPregunta5TestViralidad(value);
        seteditPregunta5TestViralidad(true);
        setTimeout(() => {
          setOrderRutine(nextOrderRoutine);
        }, 1000);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getQuestionsWithCorrectAnswers({
      variables: {
        idTraining: Number(currentTraining),
      },
    });
  }, []);

  useEffect(() => {
    if (relAnswers.length > 0) {
      sessionStorage.setItem(
        'relQuestionsAnswersViralityTest',
        JSON.stringify(relAnswers),
      );
    } else {
      sessionStorage.removeItem('relQuestionsAnswersViralityTest');
    }
  }, [relAnswers]);

  const handleChangeIcon = (json) => {
    switch (json.active) {
      case 1:
        seteditPregunta1TestViralidad(false);
        break;
      case 2:
        seteditPregunta2TestViralidad(false);
        break;
      case 3:
        seteditPregunta3TestViralidad(false);
        break;
      case 4:
        seteditPregunta4TestViralidad(false);
        break;
      case 5:
        seteditPregunta5TestViralidad(false);
        break;
      default:
        break;
    }
  };

  const tamScreen = window.screen.width;
  const scroller = (top_avance, top_avance_mobile) => {
    const avance = document.body.scrollHeight;
    console.log(avance);
    console.log('scrollTo: ', avance - top_avance_mobile);
    if (tamScreen > 1024) {
      window.scroll({
        top: avance - top_avance,
        behavior: 'smooth',
      });
    } else {
      window.scroll({
        top: avance - top_avance_mobile,
        behavior: 'smooth',
      });
    }
  };
  const scrollToElem = (id) => {
    switch (id) {
      case 'uso':
        scroller(950, 1200);
        break;
      case 'rapido':
        scroller(950, 1200);
        break;
      case 'futuro':
        scroller(950, 1200);
        break;
      default:
        console.log('default case');
        scroller(950, 1400);
    }
  };

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <QuestionsOrder
          classCard='cardOne'
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            pregunta1TestViralidad === ''
            || (pregunta1TestViralidad !== '' && !editPregunta1TestViralidad)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        {pregunta1TestViralidad === ''
        || (pregunta1TestViralidad !== '' && !editPregunta1TestViralidad)
          ? scrollToElem('uso')
          : null}
        <CardTwo
          classCard={
            pregunta1TestViralidad !== '' && editPregunta1TestViralidad
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={pregunta1TestViralidad}
          colorTraining="#F074DC"
        >
          <img
            alt="editIcon"
            className={
              !editPregunta2TestViralidad ? 'iconEditCommon' : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
          />
          <img
            alt="corectIcon"
            className={'iconScore'}
            src={relAnswers[0] === 1 ? corect : incorect}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            pregunta1TestViralidad !== '' && editPregunta1TestViralidad
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (pregunta2TestViralidad === ''
              && pregunta1TestViralidad !== ''
              && editPregunta1TestViralidad)
            || (pregunta2TestViralidad !== ''
              && editPregunta1TestViralidad
              && !editPregunta2TestViralidad)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        {(pregunta2TestViralidad === ''
          && pregunta1TestViralidad !== ''
          && editPregunta1TestViralidad)
        || (pregunta2TestViralidad !== ''
          && editPregunta1TestViralidad
          && !editPregunta2TestViralidad)
          ? scrollToElem('rapido')
          : null}
        <CardTwo
          classCard={
            pregunta2TestViralidad !== '' && editPregunta2TestViralidad
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={pregunta2TestViralidad}
          colorTraining="#F074DC"
        >
          <img
            alt="editIcon"
            className={
              !editPregunta3TestViralidad ? 'iconEditCommon' : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
          />
          <img
            alt="corectIcon"
            className={'iconScore'}
            src={relAnswers[1] === 1 ? corect : incorect}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            pregunta2TestViralidad !== '' && editPregunta2TestViralidad
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        <TrainingQuestion
          className={
            (pregunta3TestViralidad === ''
              && pregunta2TestViralidad !== ''
              && editPregunta2TestViralidad)
            || (pregunta3TestViralidad !== ''
              && editPregunta2TestViralidad
              && !editPregunta3TestViralidad)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        {(pregunta3TestViralidad === ''
          && pregunta2TestViralidad !== ''
          && editPregunta2TestViralidad)
        || (pregunta3TestViralidad !== ''
          && editPregunta2TestViralidad
          && !editPregunta3TestViralidad)
          ? scrollToElem('futuro')
          : null}
        <CardTwo
          classCard={
            pregunta3TestViralidad !== '' && editPregunta3TestViralidad
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={pregunta3TestViralidad}
          colorTraining="#F074DC"
        >
          <img
            alt="editIcon"
            className={
              !editPregunta4TestViralidad ? 'iconEditCommon' : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
          />
          <img
            alt="corectIcon"
            className={'iconScore'}
            src={relAnswers[2] === 1 ? corect : incorect}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            pregunta3TestViralidad !== '' && editPregunta3TestViralidad
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (pregunta4TestViralidad === ''
              && pregunta3TestViralidad !== ''
              && editPregunta3TestViralidad)
            || (pregunta4TestViralidad !== ''
              && editPregunta3TestViralidad
              && !editPregunta4TestViralidad)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            pregunta4TestViralidad !== '' && editPregunta4TestViralidad
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={pregunta4TestViralidad}
          colorTraining="#F074DC"
        >
          <img
            alt="editIcon"
            className={
              !editPregunta5TestViralidad ? 'iconEditCommon' : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 4 })}
          />
          <img
            alt="corectIcon"
            className={'iconScore'}
            src={relAnswers[3] === 1 ? corect : incorect}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            pregunta4TestViralidad !== '' && editPregunta4TestViralidad
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={4}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (pregunta5TestViralidad === ''
              && pregunta4TestViralidad !== ''
              && editPregunta4TestViralidad)
            || (pregunta5TestViralidad !== ''
              && editPregunta4TestViralidad
              && !editPregunta5TestViralidad)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>

        <CardTwo
          classCard={
            pregunta5TestViralidad !== '' && editPregunta5TestViralidad
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={pregunta5TestViralidad}
          colorTraining="#F074DC"
        >
          <img
            alt="editIcon"
            className={editPregunta5TestViralidad ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 5 })}
          />
          <img
            alt="corectIcon"
            className={'iconScore'}
            src={relAnswers[4] === 1 ? corect : incorect}
          />
        </CardTwo>

        {pregunta5TestViralidad !== '' && editPregunta5TestViralidad ? (
          <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
            <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="0rem">
              <ImageCoachInicio topMobile="35px" />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          </TrainingSectionGray>
        ) : null}
      </div>
  );
};

export default ViralityQuestionnaire;
