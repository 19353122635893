/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import { Image, Container, Col7 } from '../../components/common/common.styled';
import {
  TrainingSubtitles,
  CardsBtn1,
  TrainingDivSection,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import cincoConsejos from '../../assets/img/anacoach/cincoConsejos.png';
import formula from '../../assets/img/anacoach/formula.png';
import cuatroMetodos from '../../assets/img/anacoach/cuatroMetodos.png';
import cuentaAhorro from '../../assets/img/anacoach/cuentaAhorro.png';
import CardOne from '../../components/Home/Card1Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ClickButton from '../../Helpers/HookHelpers';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';

const LearnMoreSavings = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  handleNewtool,
  handleOpcTool,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length + contVideos
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length + contVideos,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    console.log('Entro useEffect APRENDER MAS');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const backRutine = (e) => {
    const click = `RegresarLearnMore_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setOrderRutine(6);
    handleOpcTool('');
    sessionStorage.removeItem('HerramientaDeAhorro');
    handleNewtool(true);
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOptionUSERPROFILLE====', val);
    switch (val.step) {
      case 0:
        break;
      default:
        console.log(val);
    }
  };
  const abrirNuevoTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };
  const [hacerScroll, setHacerScroll] = useState(true);
  useEffect(() => {
    if (hacerScroll) {
      setTimeout(() => {
        gsap.to(window, { duration: 1, scrollTo: '#AprenderMas' });
        setHacerScroll(false);
      }, 1000);
    }
  });
  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <Fragment>
        <div id="AprenderMas"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="Continuamos"
          hora={hora}
        />
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          textbold="¡El conocimiento es poder!"
          text="Aquí te presentare lugares donde podrás obtener más Información y tips para aprender más sobre Ahorro de emergencia"
          hora={hora}
        />
        <TrainingDivSection
          backw="#f8f9fa00"
          padding="0px"
          paddw="0px"
          mb="0"
          wi="90%"
          wiw="80%"
          he="auto"
          hew="auto"
          bsha="null"
        >
          <TrainingDivSection
            mlw="10%"
            ml="0px"
            mr="0px"
            bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
            mt="10px"
            br="4px"
            padding="3%"
            wi="100%"
            wiw="75%"
            he="auto"
            hew="auto"
            mb="0px"
            mtw="20px"
          >
            <TrainingSubtitles
              size="16px"
              sizew="16px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              Te recomiendo ver el siguiente vídeo para saber dónde puedes
              invertir el dinero de tu fondo de emergencia
            </TrainingSubtitles>
            <div style={{ padding: '4%' }}>
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            </div>
          </TrainingDivSection>
        </TrainingDivSection>
        <TrainingDivSection
          cursor="pointer"
          border="1px solid #D6D6D6"
          bsha=""
          mt="44px"
          mtw="20px"
          mlw="18%"
          br="4px"
          padding="21px 20px"
          wi="90%"
          wixs="90%"
          wiw="60%"
          he="auto"
          mb="6px"
          onClick={() => abrirNuevoTab(
            'https://coru.com/blog/mejora-tu-finanzas-personales/4-metodos-para-ahorrar-dinero-rapido/',
          )
          }
        >
          <Image
            src={cuatroMetodos}
            mwidth="100px"
            width="100px"
            top="110px"
            left="10%"
            mleft="0px"
            mtop="75px"
            height="auto"
          />
          <div
            style={{
              fontSize: '20px',
              cursor: 'pointer',
              marginBottom: '16px',
              marginTop: '3%',
              fontWeight: 'bold',
            }}
          >
            CORU - 4 métodos para ahorrar dinero rápido
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '25px',
            }}
          >
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
          </div>
        </TrainingDivSection>
        <TrainingDivSection
          cursor="pointer"
          border="1px solid #D6D6D6"
          bsha=""
          mt="44px"
          mtw="20px"
          mlw="18%"
          br="4px"
          padding="21px 20px"
          wi="90%"
          wixs="90%"
          wiw="60%"
          he="auto"
          mb="6px"
          onClick={() => abrirNuevoTab(
            'https://www.eleconomista.com.mx/finanzaspersonales/Considere-una-cuenta-de-ahorro-como-aliada-en-sus-finanzas-20190402-0126.html',
          )
          }
        >
          <Image
            src={cuentaAhorro}
            mwidth="100px"
            width="100px"
            top="110px"
            left="10%"
            mleft="0px"
            mtop="75px"
            height="auto"
          />
          <div
            style={{
              fontSize: '20px',
              cursor: 'pointer',
              marginBottom: '16px',
              marginTop: '3%',
              fontWeight: 'bold',
            }}
          >
            El ECONOMISTA - Considere una cuenta de ahorro como aliada en sus
            finanzas{' '}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '25px',
            }}
          >
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
          </div>
        </TrainingDivSection>
        <TrainingDivSection
          cursor="pointer"
          border="1px solid #D6D6D6"
          bsha=""
          mt="44px"
          mtw="20px"
          mlw="18%"
          br="4px"
          padding="21px 20px"
          wi="90%"
          wixs="90%"
          wiw="60%"
          he="auto"
          mb="6px"
          onClick={() => abrirNuevoTab(
            'https://www.pinterest.es/pin/340584790563856887/?autologin=true',
          )
          }
        >
          <Image
            src={formula}
            mwidth="100px"
            width="100px"
            top="110px"
            left="10%"
            mleft="0px"
            mtop="75px"
            height="auto"
          />
          <div
            style={{
              fontSize: '20px',
              cursor: 'pointer',
              marginBottom: '16px',
              marginTop: '3%',
              fontWeight: 'bold',
            }}
          >
            PINTEREST - Tu edad menos 20, la nueva fórma para ahorrar dinero
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '25px',
            }}
          >
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
          </div>
        </TrainingDivSection>
        <TrainingDivSection
          cursor="pointer"
          border="1px solid #D6D6D6"
          bsha=""
          mt="44px"
          mtw="20px"
          mlw="18%"
          br="4px"
          padding="21px 20px"
          wi="90%"
          wixs="90%"
          wiw="60%"
          he="auto"
          mb="6px"
          onClick={() => abrirNuevoTab('https://www.facebook.com/watch/?v=782094011962712')
          }
        >
          <Image
            src={cincoConsejos}
            mwidth="100px"
            width="100px"
            top="110px"
            left="10%"
            mleft="0px"
            mtop="75px"
            height="auto"
          />
          <div
            style={{
              fontSize: '20px',
              cursor: 'pointer',
              marginBottom: '16px',
              marginTop: '3%',
              fontWeight: 'bold',
            }}
          >
            FACEBOOK - 5 Consejos para ahorrar más y mejor
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '25px',
            }}
          >
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
            <div
              style={{
                background: '#76DF44',
                width: '60px',
                height: '5px',
                borderRadius: '15%',
              }}
            ></div>
          </div>
        </TrainingDivSection>
        <div style={{ display: 'flex' }}>
          <CardsBtn1
            border="1px solid #F64282"
            back="#fff"
            color="#F64282"
            onClick={(e) => backRutine(e)}
          >
            <b>Regresar</b>
          </CardsBtn1>
        </div>
        {/* Enso Klu Fremium  */}
            <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '21' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
        <ModalAmazon show={true} wo={'Emergency'}></ModalAmazon>
        <ModalVirality />
        <SectionShare WO="Emergency"></SectionShare>
      </Fragment>
  );
};

export default LearnMoreSavings;
