/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import "../../../assets/styles/WelcomeValidation.css";
import "../../../assets/styles/TextCustom.css";
import moment from "moment";
import TagManager from "react-gtm-module";
import { useLazyQuery, useMutation } from "@apollo/client";
import { consts } from "react-elastic-carousel";
import { MdArrowBackIos } from "react-icons/md";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import {
  Main,
  Col11,
  Container,
  Col12,
  SubtitleOne,
  Image,
  TextFooter,
  LinksFooter,
  ContentGeneric,
} from "../../../components/common/common.styled";
import {
  TrainingMain,
  TrainigTam,
} from "../../../assets/styles/Training.styled";
import InputAna from "../../../components/common/InputAna";
import {
  GET_URL_KANDA,
  GET_NICKNAME_KANDA,
  GET_STATUS_TRAINING_BY_NAME,
} from "../../../queries";
import {
  NEW_LOGIN,
  UPDATE_SESSION,
  CREATE_SESSION,
  CREATE_CLICK,
  CREATE_MAGIC_LINK,
} from "../../../mutations";
import { getQueryVariable } from "../../../Helpers";
import ButtonsInicio from "./partials/ButtonsInicio";
import ButtonsPassword from "./partials/ButtonsPassword";
import Contrasena from "../Contrasena";
import Modallogin from "./partials/Modallogin";
import WOlayout from "../../../components/layouts/WOlayout";
import { ModalMagicLink } from "../ModalLogin";
import ClickButton from "../../../Helpers/HookHelpers";
import { Text } from "../../../components/LpHomeCoru/Assets/styles/KandaContent.styled";
import { ImgGeneric } from "../../../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";

function Login2({ isModal = false, setTypeModal, nextRoutine, isBlocked }) {
  const [form, setForm] = useState({
    texto: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
  });
  const [error, setError] = useState({
    email: false,
    pass1: false,
    pass2: false,
    pass3: false,
    pass4: false,
    emailvalid: -1,
    passwordvalid: -1,
  });
  const [texterror, settexterror] = useState({
    email: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    usuario: "",
  });
  const [redirectToKanda, setRedirectToKanda] = useState(false);
  const [redirectToWoKanda, setRedirectToWoKanda] = useState(false);
  const [urlKanda, setUrlKanda] = useState("");
  const [nickname, setNickname] = useState("");
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);
  const [ispassword, setispassword] = useState(true);
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("password");
  const [maxlength, setmaxlength] = useState("50");
  const [typeText, settypeText] = useState("");
  const [childrend, setChildrend] = useState("");
  const [textBtnPrincipal, settextBtnPrincipal] = useState("");
  const [textBtnSeccon, settextBtnSeccon] = useState("");
  // Valores para el conteo, error de contraseña y bloqueo de cuenta
  const [conteoPassword, setconteoPassword] = useState(4);
  const [errorPassword, seterrorPassword] = useState(false);
  const [cuentaBloqueada, setcuentaBloqueada] = useState(false);
  const [colorTraining, setColorTraining] = useState("");
  const [openModal, setopenModal] = useState(false);
  const [openModalLV2, setopenModalLV2] = useState(false);
  const [isBlockedC, setisBlockedC] = useState(false);

  const [flagOn, setFlagOn] = useState(false);

  // end
  const [disabledbtn, setdisabledbtn] = useState(true);
  const [validCampos, setvalidCampos] = useState({
    password: false,
    email: false,
  });
  const [TypeCorreo, SetTypeCorreo] = useState({
    text: "",
    display: "",
    text_correct: "",
  });

  const handleStatus = () => {
    if (type === "password") setType("text");
    else setType("password");
  };

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem("id_session", dynamicMongoSession.response);
        localStorage.setItem("id_session", dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getUrlKanda] = useLazyQuery(GET_URL_KANDA, {
    onCompleted({ getUrlKanda }) {
      try {
        const obj = JSON.parse(getUrlKanda.response)[0];
        setUrlKanda(obj.Path);
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getNicknameKanda] = useLazyQuery(GET_NICKNAME_KANDA, {
    onCompleted({ getNicknameKanda }) {
      try {
        const obj = JSON.parse(getNicknameKanda.response)[0] || "";
        setNickname(obj.nickname);
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createMagicLink] = useMutation(CREATE_MAGIC_LINK, {
    onCompleted({ createMagicLink }) {
      try {
        const { response, message, error } = createMagicLink;
        if (message === "success") {
          sessionStorage.removeItem("KandaLoginRedirection");
          setIsVerifiedUser(true);
          setopenModal(true);
        } else {
          setIsVerifiedUser(false);
          setChildrend(
            "Los datos ingresados no corresponden a un usuario registrado. Por favor verifique que el número celular o correo electrónico y contraseña sean correctos."
          );
          settextBtnPrincipal("Entendido");
          settextBtnSeccon("");
          setOpen(true);
          // Aquí se manda a llamar el mensaje cuando no existe el usuario en BD
        }
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getStatusDiagnostico] = useLazyQuery(GET_STATUS_TRAINING_BY_NAME, {
    fetchPolicy: "no-cache",
    onCompleted({ getStatusTrainingByName }) {
      try {
        const { statusCode, message, error, response } =
          getStatusTrainingByName;
        const resp = JSON.parse(response);
        if (message === "success" && resp[0]) {
          if (resp[0].AchievmentWO === 100) {
            window.location.href = "/kanda/feed";
          } else {
            window.location.href = "/kanda/wo/diagnosticov3";
          }
        } else {
          window.location.href = "/kanda/wo/diagnosticov3";
        }
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [errorInexistente, seterrorInexistente] = useState(false);
  const [newLoginCoru] = useMutation(NEW_LOGIN, {
    onCompleted({ newLoginCoru }) {
      const {
        token,
        Attemps,
        TimeLocked,
        firstName,
        middleName,
        fathersLastName,
        mothersLastName,
        idUser,
        idCont,
        idSession,
        idCatRelAction,
        ProfilePicture,
        BirthDate,
        Gender,
      } = newLoginCoru;
      if (token === "Usuario inexistente") {
        setForm({
          ...form,
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        // seterrorPassword("Usuario inexistente");
        // seterrorInexistente(true);
        setIsVerifiedUser(false);
        setChildrend(
          "Los datos ingresados no corresponden a un usuario registrado. Por favor verifique los datos."
        );
        seterrorInexistente(true);
        settextBtnPrincipal("Entendido");
        setOpen(true);
      } else if (token === "Bloqueo temporal") {
        setForm({
          ...form,
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        setcuentaBloqueada(true);
        seterrorPassword(
          `Cuenta bloqueada. Vuelve a intentar en ${TimeLocked} ${
            TimeLocked > 1 ? "minutos" : "minuto"
          }`
        );
        setChildrend(
          `Cuenta bloqueada. Vuelve a intentar en ${TimeLocked} ${
            TimeLocked > 1 ? "minutos" : "minuto"
          } o ingresa con un enlace que enviaremos a tu WhatsApp o correo electrónico.`
        );
        settextBtnPrincipal("Entendido");
        setOpen(true);
        setisBlockedC(true);
      } else if (token === "Contraseña incorrecta") {
        const restantes = 5 - Attemps;
        setForm({
          ...form,
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        seterrorPassword(
          `Contraseña incorrecta tienes ${restantes} intentos restantes`
        );
        setconteoPassword(restantes);
        setIsVerifiedUser(false);
        setChildrend(
          `Contraseña incorrecta tienes ${restantes} intentos restantes`
        );
        settextBtnPrincipal("Entendido");
        setOpen(true);
      } else {
        // Iniciar sesión
        const todayLogin = moment().format("DD/MM/YYYY HH:mm:ss");
        const createClickInput = {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "inicioSesion",
            collection: "sessions",
            timeStamp: todayLogin,
          },
        };
        if (flagOn) {
          // localStorage.setItem("flagOn", flagOn);
          sessionStorage.setItem("flagOn", flagOn);
        }
        if (sessionStorage.getItem("id_session")) {
          createClickFront({ variables: createClickInput });
        }
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("FirstName", firstName);
        sessionStorage.setItem("MiddleName", middleName);
        sessionStorage.setItem("FathersLastName", fathersLastName);
        sessionStorage.setItem("MothersLastName", mothersLastName);
        sessionStorage.setItem("idUser", idUser);
        sessionStorage.setItem("idCont", idCont);
        sessionStorage.setItem("idDatSession", idSession);
        sessionStorage.setItem("idCatRelAction", idCatRelAction);
        sessionStorage.setItem("ProfilePicture", ProfilePicture);
        sessionStorage.setItem("token", token);

        localStorage.setItem("FirstName", firstName);
        localStorage.setItem("MiddleName", middleName);
        localStorage.setItem("FathersLastName", fathersLastName);
        localStorage.setItem("MothersLastName", mothersLastName);
        localStorage.setItem("idUser", idUser);
        localStorage.setItem("idCont", idCont);
        localStorage.setItem("idDatSession", idSession);
        localStorage.setItem("idCatRelAction", idCatRelAction);
        localStorage.setItem("ProfilePicture", ProfilePicture);

        sessionStorage.setItem("session", true);
        sessionStorage.setItem("PrimerVis", true);
        sessionStorage.setItem("showModal", false);

        if (BirthDate != null) {
          const day = moment(BirthDate).get("day");
          const month = moment(BirthDate).get("month");
          const year = moment(BirthDate).get("year");
          sessionStorage.setItem("day", day);
          sessionStorage.setItem("month", month);
          sessionStorage.setItem("year", year);
          sessionStorage.setItem("DataPerson", true);
        }

        if (Gender != null) {
          sessionStorage.setItem("Gender", Gender);
        }

        if (redirectToWoKanda) {
          const pathnameP = window.location.pathname;
          const pathname = pathnameP.split("/")[1];
          const replaceRedirect = redirectToWoKanda.replaceAll(".", "/");
          const url = urlKanda.replace("/kanda", replaceRedirect);
          window.open(
            `${url}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda&idUser=${idUser}&idCont=${idCont}`,
            "_self"
          );
        } else if (redirectToKanda) {
          const pathnameP = window.location.pathname;
          const pathname = pathnameP.split("/")[1];
          window.open(
            `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda&idUser=${idUser}&idCont=${idCont}`,
            "_self"
          );
        } else if (sessionStorage.getItem("LPpageplanes") === "true") {
          window.location.href = "/wo/first-investment";
        } else if (isModal) {
          nextRoutine();
        } else {
          sessionStorage.removeItem("KandaLoginRedirection");
          getStatusDiagnostico({
            variables: {
              idUser: Number(idUser),
              NameWO: "Diagnostico V3",
            },
          });
          // window.location.href = '/the-training-feed/';
        }

        setMostrarLoad(false);
      }
    },
  });

  const ValidEmail = (value) => {
    let domain = value.split("@");
    const anexo = domain[0];
    domain = domain[1];

    if (domain !== "" && domain !== "gmail" && domain !== "hotmail") {
      if (domain === "h") {
        SetTypeCorreo({
          display: "block",
          text: `Quieres decir: ${anexo}@hotmail.com`,
          text_correct: `${anexo}@hotmail.com`,
        });
        texterror.email = "";
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === "g") {
        SetTypeCorreo({
          display: "block",
          text: `Quieres decir: ${anexo}@gmail.com`,
          text_correct: `${anexo}@gmail.com`,
        });
        texterror.email = "";
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === "hotmail.com" || domain === "gmail.com") {
        SetTypeCorreo({
          display: "none",
          text: "",
          text_correct: "",
        });
        texterror.email = "";
        error.email = false;
        error.emailvalid = 0;
      }
    } else {
      SetTypeCorreo({
        display: "none",
        text: "",
        text_correct: "",
      });
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      texterror.email = "Correo electrónico incorrecto";
      error.email = true;
      error.emailvalid = 1;
    } else {
      texterror.email = "";
      error.email = false;
      error.emailvalid = 0;
      setvalidCampos({
        ...validCampos,
        email: true,
      });
      SetTypeCorreo({
        display: "none",
        text: "",
        text_correct: "",
      });
    }
    if (value === "") {
      texterror.email = "Correo electrónico incorrecto";
      error.email = true;
      error.emailvalid = 1;
    }
  };

  const ValidaNumero = (nombre, valor) => {
    if (nombre && !/^[0-9]+$/i.test(valor) && valor.length !== 1) {
      texterror[nombre] = "Error";
      error[nombre] = true;
      error[`${nombre}valid`] = 1;
      setvalidCampos({
        ...validCampos,
        email: false,
      });
    } else if (valor.length === 10) {
      texterror[nombre] = "";
      error[nombre] = false;
      error[`${nombre}valid`] = 0;
      setvalidCampos({
        ...validCampos,
        email: true,
      });
    } else if (valor.length === 0) {
      texterror[nombre] = "";
      error[nombre] = true;
      error[`${nombre}valid`] = -1;
      setvalidCampos({
        ...validCampos,
        email: false,
      });
    } else {
      texterror[nombre] = "Por favor verifica tu número telefónico";
      error[nombre] = true;
      error[`${nombre}valid`] = 1;
      setvalidCampos({
        ...validCampos,
        email: false,
      });
    }
  };

  const handleChange = (e) => {
    const valor = e.target.value;
    if (e.target.id === "correocel") {
      setForm({
        ...form,
        texto: valor,
      });
      const iscel = /^([0-9])*$/.test(valor);
      if (valor != "") {
        if (iscel) {
          ValidaNumero("email", valor);
          setmaxlength("10");
          settypeText("cel");
        } else {
          ValidEmail(valor);
          setmaxlength("50");
          settypeText("email");
        }
      } else {
        settypeText("");
      }
    } else {
      e.persist();
      setForm({
        ...form,
        [e.target.name]: e.target.value === "N" ? "" : e.target.value,
      });
      const { name, value } = e.target;
      switch (name) {
        case "pass1":
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='pass2']").focus();
          } else {
            return false;
          }
          break;
        case "pass2":
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='pass3']").focus();
          } else {
            return false;
          }
          break;
        case "pass3":
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='pass4']").focus();
          } else {
            return false;
          }
          break;
        case "pass4":
          if (
            form.pass1.length !== 0 &&
            form.pass2.length !== 0 &&
            form.pass3.length !== 0
          ) {
            error.passwordvalid = 0;
            document.getElementById("btnIniciar").focus();
          }
          break;
        default:
          break;
      }
    }
  };

  const submit = (e) => e.preventDefault();

  const { actions } = ClickButton();

  const validUser = () => {
    actions({
      action: "ClickButtonLoginMagicLink",
      variables: {
        name: `CreateLoginMagicLink`,
        collection: "ClickButtonLoginMagicLink",
      },
    });
    if (form.texto.length > 0 && typeText.length > 0) {
      const KandaLoginRedirection = sessionStorage.getItem(
        "KandaLoginRedirection"
      );
      createMagicLink({
        variables: {
          text: typeText === "cel" ? `52${form.texto}` : form.texto,
          type: typeText,
          origin:
            KandaLoginRedirection === String
              ? KandaLoginRedirection
              : window.location.href,
        },
      });
    }
  };

  const handleClick = async () => {
    let profile = "cliente";
    if (String(form.texto).indexOf("@test") !== -1) {
      sessionStorage.setItem("typeUser", "test");
      localStorage.setItem("typeUser", "test");
      profile = "test";
    }
    const source = getQueryVariable("utm_source");
    const medium = getQueryVariable("utm_medium");
    const campaign = getQueryVariable("utm_campaign");
    const inputLogin = {
      input: {
        text: form.texto,
        type: typeText,
        password: form.pass1 + form.pass2 + form.pass3 + form.pass4,
        typeUser: profile,
        IDGA: sessionStorage.getItem("ID_GA"),
        source,
        medium,
        campaign,
        hostName: window.location.hostname,
        pageName: "Login",
      },
    };
    newLoginCoru({ variables: inputLogin });
  };

  useEffect(() => {
    if (validCampos.email && validCampos.password) {
      setdisabledbtn(false);
      console.log(validCampos);
    } else {
      setdisabledbtn(true);
      console.log(validCampos);
    }
  }, [validCampos]);

  useEffect(() => {
    const idga = sessionStorage.getItem("ID_GA");
    const dataJson = {
      idga,
      campaign: sessionStorage.getItem("campaign"),
      medium: sessionStorage.getItem("medium"),
      source: sessionStorage.getItem("source"),
      timestamp: new Date().toLocaleString(),
      content: sessionStorage.getItem("content"),
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: "sessions",
        data: dataString,
      },
    };
    if (!sessionStorage.getItem("id_session")) {
      createSession({ variables: mongoInput });
    }

    const idUser = getQueryVariable("idUser");
    const idCont = getQueryVariable("idCont");
    const redirect = getQueryVariable("redirectToKanda");
    const redirecto = getQueryVariable("WoRedirectTo");

    if (idUser && idUser !== "" && idCont && idCont !== "") {
      getNicknameKanda({
        variables: {
          idUser: parseInt(idUser),
        },
      });
    }
    if (redirecto) {
      setRedirectToWoKanda(redirecto);
      getUrlKanda({
        variables: { appName: "Kanda" },
      });
    }
    if (redirect) {
      setRedirectToKanda(redirect);
      getUrlKanda({
        variables: { appName: "Kanda" },
      });
    }
  }, []);

  const [WOBigImage, setWOBigImage] = useState("");
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const FcloseModal = () => {
    setopenModal(!openModal);
  };

  return (
    <>
      {openModal ? (
        <ModalMagicLink
          typeInfo={typeText}
          dato={form.texto}
          closeModal={FcloseModal}
        />
      ) : null}
      {isModal === true ? (
        <>
          <TrainingMain backgroundColor="transparent">
            <Main className="container" padding="0 21px" mb="134px">
              <Container
                width="100%"
                mt="36px"
                widthMobile="100%"
                mtMobile="36px"
                height="100vh"
              >
                <Col11 padding="0" margin="0">
                  <SubtitleOne
                    size="24px"
                    sizeMobile="24px"
                    className="roboto"
                    id="idtitle"
                    mb="24px"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        marginRight: ispassword ? "3%" : "",
                      }}
                      onClick={() => {
                        setispassword(false);
                      }}
                    >
                      {ispassword ? "  " : ""}
                    </span>
                    Iniciar sesión
                  </SubtitleOne>
                  {ispassword ? (
                    <SubtitleOne
                      className="font-weight-bold roboto"
                      size="16px"
                      sizeMobile="16px"
                      mb="5%"
                    >
                      Número celular o correo electrónico
                    </SubtitleOne>
                  ) : (
                    <SubtitleOne
                      className="font-weight-bold roboto"
                      size="16px"
                      sizeMobile="16px"
                      mb="5%"
                    >
                      Deposita el correo electrónico registrado
                    </SubtitleOne>
                  )}
                </Col11>
                <Col11 padding="0px">
                  <form autoComplete="off" onSubmit={submit}>
                    <Container widthMobile="100%" width="100%">
                      <Col12 pt="0px" padding="0px" ptMobile="0px">
                        <InputAna
                          placeholder=""
                          id="correocel"
                          value={form.texto}
                          handleChange={handleChange}
                          handleBlur={handleChange}
                          /* type="email" */
                          maxlength={maxlength}
                          error={error.email}
                          texterror={texterror.email}
                          errorinicial={error.emailvalid}
                        />
                      </Col12>
                    </Container>
                    {ispassword ? (
                      <>
                        <Contrasena
                          form={form}
                          setForm={setForm}
                          islogin={true}
                          errorPassword={errorPassword}
                          errorInexistente={errorInexistente}
                          conteoPassword={conteoPassword}
                          validCampos={validCampos}
                          setvalidCampos={setvalidCampos}
                          cuentaBloqueada={cuentaBloqueada}
                          setispassword={setispassword}
                          isModal={isModal}
                        />
                        <ButtonsPassword
                          disabledbtn={disabledbtn}
                          handleClick={handleClick}
                          setispassword={setispassword}
                          flagOn={flagOn}
                          setFlagOn={setFlagOn}
                        />
                      </>
                    ) : (
                      <ButtonsInicio
                        validUser={validUser}
                        setispassword={setispassword}
                        error={error.email}
                        isvalid={error.emailvalid}
                        isModal={isModal}
                        setTypeModal={setTypeModal}
                      />
                    )}
                  </form>
                </Col11>
              </Container>
            </Main>
          </TrainingMain>
        </>
      ) : (
        <>
          <WOlayout
            pathname={window.location.pathname}
            headerType=""
            showFooter={!isModal}
          >
            <Header background="#FFFFFF" pathname={window.location.pathname} />
            <>
              <Main
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "100vh",
                }}
                background="#FFFFFF"
                padding="34px 20px 20px"
                heightComponent="fit-content"
                overflowY="auto"
              >
                <Container
                  flexDir="column"
                  gap="24px"
                  widthDesk="30% !important"
                  widthMobile="100%"
                  background="#FFFFFF"
                  shadow="-4px 4px 12px rgba(57, 58, 128, 0.15), 4px 4px 12px rgba(57, 58, 128, 0.15)"
                  radius="16px"
                  mt="36px"
                  padding="24px 21px"
                  mtMobile="20px"
                >
                  <Col11 padding="0px">
                    {ispassword ? (
                      <SubtitleOne
                        mt="0px"
                        id="idTitle"
                        size="24px"
                        fw="500"
                        className="roboto"
                        mb="24px"
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            marginRight: ispassword ? "0%" : "",
                          }}
                          onClick={() => {
                            setispassword(false);
                          }}
                        >
                          {ispassword ? "" : ""}
                        </span>
                        Iniciar sesión
                      </SubtitleOne>
                    ) : (
                      <SubtitleOne
                        style={{ display: "flex", zIndex: 22 }}
                        onClickCapture={() => setispassword(!ispassword)}
                        mt="0px"
                        id="idTitle"
                        size="24px"
                        fw="500"
                        className="roboto"
                        mb="24px"
                      >
                        <MdArrowBackIos />
                        Ingresar con enlace
                      </SubtitleOne>
                    )}
                    <SubtitleOne
                      fw="500"
                      className="roboto"
                      size="16px"
                      sizeMobile="16px"
                      mb="12px"
                    >
                      {ispassword
                        ? "Número celular o correo electrónico"
                        : "Deposita el correo electrónico registrado"}
                    </SubtitleOne>
                  </Col11>
                  <Col11 padding="0px">
                    <form autoComplete="off" onSubmit={submit}>
                      <Container widthMobile="100%" width="100%">
                        <Col12 pt="0px" padding="0px" ptMobile="0px">
                          <InputAna
                            padding="10px"
                            placeholder=""
                            id="correocel"
                            value={form.texto}
                            handleChange={handleChange}
                            handleBlur={handleChange}
                            /* type="email" */
                            maxlength={maxlength}
                            error={error.email}
                            texterror={texterror.email}
                            errorinicial={error.emailvalid}
                          />
                        </Col12>
                      </Container>
                      {ispassword ? (
                        <>
                          <Contrasena
                            form={form}
                            setForm={setForm}
                            islogin={true}
                            errorPassword={errorPassword}
                            conteoPassword={conteoPassword}
                            validCampos={validCampos}
                            setvalidCampos={setvalidCampos}
                            cuentaBloqueada={cuentaBloqueada}
                            setispassword={setispassword}
                            isModal={isModal}
                          />
                          <ButtonsPassword
                            disabledbtn={disabledbtn}
                            handleClick={handleClick}
                            setispassword={setispassword}
                            flagOn={flagOn}
                            setFlagOn={setFlagOn}
                          />
                          <hr />
                          <LinksFooter
                            className="roboto"
                            fontSize="16px"
                            onClick={() => {
                              window.location.href = "/personal-Information-v2";
                            }}
                            fontWeight="bold"
                            style={{
                              margin: "36px auto 0% auto",
                              display: "block",
                              color: "#F64282",
                              textDecoration: "revert",
                            }}
                          >
                            ¿Aún no tienes cuenta?
                          </LinksFooter>
                        </>
                      ) : (
                        <ButtonsInicio
                          validUser={validUser}
                          setispassword={setispassword}
                          error={error.email}
                          isvalid={error.emailvalid}
                          isModal={isModal}
                          setTypeModal={setTypeModal}
                        />
                      )}
                    </form>
                  </Col11>
                </Container>
              </Main>
            </>
          </WOlayout>
          {/* <Footer /> */}
          <Modallogin
            open={open}
            setopen={setOpen}
            childrent={childrend}
            setChildrend={setChildrend}
            textBtnPrincipal={textBtnPrincipal}
            settextBtnPrincipal={settextBtnPrincipal}
            textBtnSeccon={textBtnSeccon}
            settextBtnSeccon={settextBtnSeccon}
            isBlockedC={isBlockedC}
            setisBlockedC={setisBlockedC}
            objectSession={{
              text: typeText === "cel" ? `52${form.texto}` : form.texto,
              type: typeText,
            }}
            procedure="login"
          />

          {openModal ? (
            <ModalMagicLink
              typeInfo={typeText}
              dato={form.texto}
              closeModal={FcloseModal}
              isBlocked={isBlocked}
            />
          ) : null}
          {/*
      <ModalMagicLink
        typeInfo={typeText}
        dato={form.texto}
        openModal={openModal}
      /> */}
        </>
      )}
      {/* <Header pathname="/login" /> */}
    </>
  );
}

export default Login2;
