import React, { Fragment, useState, useEffect } from 'react';
import ShareIcon from '@material-ui/icons/Share';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import LinearProgress from '@material-ui/core/LinearProgress';

// Modal
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { CREATE_CLICK } from '../../mutations';
import { Num } from '../common/Home.styled';
import {
  Container, Col12, SubtitleOne, Image, ParagrapTwo, Col4, Button, ConterMinusculas, CirculeRachas,
} from '../common/common.styled';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';
import candado from '../../assets/img/logros/Candado.png';
import '../../assets/styles/perfil/perfiles.css';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#D6D6D6',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#91D770',
  },
}))(LinearProgress);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = top;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '350px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

function Rachas() {
  useEffect(() => {
    ModalRecuadros();
  });
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted() {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  }, []);

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) createClickFront({ variables: createClickInput });
    TagManager.dataLayer(tagManagerArgs);
  };

  const trainingProcess = (sessionStorage.getItem('trainingProcess') != null ? sessionStorage.getItem('trainingProcess') : 0);
  console.log('****************trainingProcess*********');
  console.log(trainingProcess);
  console.log(100 * trainingProcess / 30);
  const progress = (100 * trainingProcess) / 30;
  console.log(progress);
  const history = useHistory();
  const [displayRachas, setDisplayRachas] = useState(false);
  const [StatusEntrenamiento] = useState({
    logro1: (sessionStorage.getItem('trainingProcess') != null),
    logro2: (sessionStorage.getItem('trainingProcess') >= 2),
    logro3: (sessionStorage.getItem('trainingProcess') >= 3),
    logro5: (sessionStorage.getItem('trainingProcess') >= 5),
    logro7: (sessionStorage.getItem('trainingProcess') >= 7),
    logro10: (sessionStorage.getItem('trainingProcess') >= 10),
    logro15: (sessionStorage.getItem('trainingProcess') >= 15),
    logro20: (sessionStorage.getItem('trainingProcess') >= 20),
    logro30: (sessionStorage.getItem('trainingProcess') >= 30),
    compartir: false,
  });

  const [ModalContext, SetModalContext] = useState({
    url: ' ',
    numEntrenamiento: 'Un entrenamiento',
    textbtn: ' Nuevo entrenamiento',
  });

  const [Entrenamientos, SetEntrenamiento] = useState({
    logro1: !StatusEntrenamiento.logro1 ? ' ' : ' ',
    color1: !StatusEntrenamiento.logro1 ? '#07210A' : '#07210A',
    status1: !!StatusEntrenamiento.logro1,

    logro2: !StatusEntrenamiento.logro2 ? ' ' : ' ',
    color2: !StatusEntrenamiento.logro2 ? '#07210A' : '#07210A',
    status2: !!StatusEntrenamiento.logro2,

    logro3: !StatusEntrenamiento.logro3 ? ' ' : ' ',
    color3: !StatusEntrenamiento.logro3 ? '#07210A' : '#07210A',
    status3: !!StatusEntrenamiento.logro3,

    logro5: !StatusEntrenamiento.logro5 ? ' ' : ' ',
    color5: !StatusEntrenamiento.logro5 ? '#07210A' : '#07210A',
    status5: !!StatusEntrenamiento.logro5,

    logro7: !StatusEntrenamiento.logro7 ? ' ' : ' ',
    color7: !StatusEntrenamiento.logro7 ? '#07210A' : '#07210A',
    status7: !!StatusEntrenamiento.logro7,

    logro10: !StatusEntrenamiento.logro10 ? ' ' : ' ',
    color10: !StatusEntrenamiento.logro10 ? '#07210A' : '#07210A',
    status10: !!StatusEntrenamiento.logro10,

    logro15: !StatusEntrenamiento.logro15 ? ' ' : ' ',
    color15: !StatusEntrenamiento.logro15 ? '#07210A' : '#07210A',
    status15: !!StatusEntrenamiento.logro15,

    logro20: !StatusEntrenamiento.logro20 ? ' ' : ' ',
    color20: !StatusEntrenamiento.logro20 ? '#07210A' : '#07210A',
    status20: !!StatusEntrenamiento.logro20,

    logro30: !StatusEntrenamiento.logro30 ? ' ' : ' ',
    color30: !StatusEntrenamiento.logro30 ? '#07210A' : '#07210A',
    status30: !!StatusEntrenamiento.logro30,

  });

  const ShareActivity = (status, numEntrenamiento, background, title) => {
    sessionStorage.setItem('statusActivity', status);
    sessionStorage.setItem('numActivity', numEntrenamiento);
    sessionStorage.setItem('backgroundActivity', background);
    sessionStorage.setItem('titleActivity', title);
    sessionStorage.setItem('trofeoActivity', false);
    history.push('/acitivity-summary');
  };

  const Recuadros = ({
    src, color, texto1, texto2, classe, mwidthimagen, positionimagen, numEntrenamiento, textbtnModal, urlImagen, display, status, background, title,
  }) => (
            <Col4 display={!display ? 'none' : ' '} cursor="pointer" padding="0px" ta={classe} onClick={() => { ShareActivity(status, numEntrenamiento, background, title); }} >
                <CirculeRachas border={status ? '10px solid #FFFFFF' : '0px'} background={ status ? background : '#CDCDCD'}>
                    {!status ? <Image src={candado} width="25px" margin="auto"/> : <Num className="roboto">{numEntrenamiento}</Num>}
                </CirculeRachas>
                <ParagrapTwo cursor="ponter" textalignMobile="center" texttalign="center" className='mx-auto roboto' fontSize="14px" fontSizeWeb="14px" color={color}>{texto1} <br/> {texto2}</ParagrapTwo>
            </Col4>
  );

  // Modal
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (src, text1, textbtn) => {
    SetModalContext({
      url: src,
      numEntrenamiento: text1,
      textbtn,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenFeed = () => {
    history.push('/the-training-feed/');
  };

  const ModalRecuadros = () => (
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo fontSize="20px" fontSizeWeb="20px" fontWeight="700" cursor="pointer" texttalign ="right" textalignMobile="right" className='mx-auto roboto' onClick={handleClose} >X</ParagrapTwo>
                    <Image width="35%" src={ModalContext.url} mwidth="50%"/>
                    <SubtitleOne mt="3%" className='font-weight-bold roboto' sizeMobile="20px" size="20px">
                    {ModalContext.numEntrenamiento}
                    </SubtitleOne>
                    <ParagrapTwo className='mx-auto'>Concluye  <ConterMinusculas>{ModalContext.numEntrenamiento}</ConterMinusculas> y obtén una insignia que se registrara en tus rachas.</ParagrapTwo>
                    <ParagrapTwo className='mx-auto'>Sigue entrenando para alcanzar tus metas.</ParagrapTwo>
                    <Button className='d-block mt-5 mt-md-4 mb-5 roboto' onClick={handleOpenFeed}>
                       Nuevo entrenamiento
                    </Button>
                </div>
            </Modal>
  );
  return (
        <Fragment>
            <Container width='100%' mt='1rem' widthMobile='100%' mtMobile='2rem'float="auto!important" >
                <ContainerPerfiles padding="4%" height="auto" minheight="122px" display="flex" mb="5%">
                    <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" >
                        <Col12 pt=" " ptMobile=" " bottom="10%">
                            <SubtitleOne
                            textalingn="left"
                            textalingnMobile="left"
                            className="font-weight-bold roboto"
                            sizeMobile="20px"
                            size="25px" mb="1%"
                            transform=" "
                            mt="0%">
                            Finalización de rachas totales
                            </SubtitleOne>
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                {trainingProcess}/30
                            </ParagrapTwo>
                            <BorderLinearProgress variant="determinate" value={progress} />
                        </Col12>
                        <Recuadros src={Entrenamientos.logro1} color={Entrenamientos.color1} texto1={'Un'} texto2={'entrenamiento'} classe={'center'} numEntrenamiento ={1} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status1} display={true} background="#6BD5AF" title="Primer entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro2} color={Entrenamientos.color2} texto1={'Dos'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={2} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status2} display={true} background="#6BD5AF" title="Segundo entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro3} color={Entrenamientos.color3} texto1={'Tres'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={3} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status3} display={true} background="#6BD5AF" title="Tercer entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro5} color={Entrenamientos.color5} texto1={'Cinco'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={5} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status5} display={true} background="#BFDD6A" title="Quinto entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro7} color={Entrenamientos.color7} texto1={'Siete'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={7} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status7} display={true} background="#BFDD6A" title="Septimo entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro10} color={Entrenamientos.color10} texto1={'Diez'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={10} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status10} display={true} background="#BFDD6A" title="Decimo entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro15} color={Entrenamientos.color15} texto1={'Quince'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={15} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status15} display={displayRachas} background="#91D770" title="Quinceavo entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro20} color={Entrenamientos.color20} texto1={'Veinte'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={20} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status20} display={displayRachas} background="#91D770" title="Veinteavo entrenamiento completado"></Recuadros>
                        <Recuadros src={Entrenamientos.logro30} color={Entrenamientos.color30} texto1={'Treinta'} texto2={'entrenamientos'} classe={'center'} numEntrenamiento ={30} textbtnModal={'Nuevo entrenamiento'} urlImagen={Entrenamientos.logro1} status={Entrenamientos.status30} display={displayRachas} background="#91D770" title="Treintavo entrenamiento completado" ></Recuadros>
                        {StatusEntrenamiento.compartir
                          ? (<Fragment>
                            <Col12>
                                <ParagrapTwo className='mx-auto' lh="1.3">Comparte tu avance e invitalos a mejorar sus decisiones.</ParagrapTwo>
                            </Col12>
                            <Button className='d-block mt-5 mt-md-4 mb-5'>
                                <ShareIcon/>  Compartir
                            </Button>
                        </Fragment>
                          ) : (<div></div>)}
                        </Container>
                </ContainerPerfiles>
                {!displayRachas ? (
                    <Button className='d-block mt-5 mt-md-4 mb-5 roboto' onClick={() => { setDisplayRachas(true); sendMongoAndGTM('ClickStreaks'); }} >
                            Ver rachas completas
                    </Button>
                ) : (null)}
            </Container>
        </Fragment>
  );
}
export default Rachas;
