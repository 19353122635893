import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import reward from '../assets/img/anacoach/badge.png';
import image1 from '../assets/img/anacoach/mejor tarjeta.jpg';
import scrollTop from '../assets/img/anacoach/scrollTop.png';
import ImageLogo from '../assets/img/anacoach/logo-01.svg';
import {
  Feed_Main,
  Feed_Sub_Main,
  Feed_Main_Left,
  Feed_Main_Right,
  ImageSticker,
  Feed_HeadTop,
  Feed_Text,
  Feed_Section,
  Feed_Right_Box,
  Top_Main,
  Top_Text,
  SeparateSection,
} from '../assets/styles/Feed.styled';
import { Title_h1 } from '../assets/styles/Home.styled';
import { Image } from '../components/common/common.styled';
import '../assets/styles/Home.css';
import VideoSection from '../components/Feed/Section_Video';
import ListItems from '../components/Feed/One_Training';
import ProductsSection from '../components/Feed/Products';
import FooterFeed from '../components/Feed/Footer_Feed';
import FooterFeedBlue from '../components/Feed/Footer_Blue';
import { GET_TRAININGS, GET_USER_TRAININGS } from '../queries';
import { DYNAMIC_INSERT } from '../mutations';

function Feed() {
  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message !== 'success') {
        console.log('');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  const validateTwin = [];
  const validateMedium = [];
  const validateBig = [];

  const trainingBig = []; // big

  const trainingProcess = []; // en proceso

  const trainingVertical = []; // vertical cc

  const items4 = []; // entrenamiento destacado

  const itemsMore = []; // informativos

  const trainingTwin = []; // tiwn
  const idUser = sessionStorage.getItem('idUser') || 0;
  const vars = { variables: { idUser: Number(idUser) }, fetchPolicy: 'no-cache' };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);
  const { data: dataUser } = useQuery(GET_USER_TRAININGS, vars);

  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateInformativo.indexOf(idTrainning) !== -1)) {
      const sectionInformativo = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: image1,
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
    }
  };
  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateOutstanding.indexOf(idTrainning) !== -1)) {
      const sectionOutstanding = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: '',
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.Liked = arrValue.Liked;

      items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        image: '',
        Liked: '',
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining && dataUser) {
    const trainingList = dataTraining.getTrainings;
    const userTrainingList = dataUser.getUserTrainings;
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      if (training.TrainingType === 'Informativo') {
        pushInformativo(training);
      }
      switch (training.Section) {
        case 'Big':
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          pushInformativo(training);
          break;
        case 'Medium':
          // items2.push(sectionMedium);
          break;
        case 'Twin':
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default: break;
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === 'Tarjetas de crédito') {
        pushCCVertical(training);
      }
    });
    const validateUserTrainings = [];
    userTrainingList.forEach((training) => {
      if (validateUserTrainings.indexOf(training.IDCatTraining) === -1) {
        if (training.Achievment !== 100) {
          trainingProcess.push(mapPayload(training));
        }
        if (training.TrainingType === 'Informativo') {
          pushInformativo(training);
        }
        if (training.Outstanding === '1') {
          pushOutstanding(training);
        }
        if (training.VerticalName === 'Tarjetas de crédito') {
          pushCCVertical(training);
        }
      }
    });

    return (
      <Fragment>
        {/* <ModalPassword /> */}
        <Feed_Main width="80%">
          <Feed_HeadTop>
            <Image src={ImageLogo} width="21%" alt="logo-coru" />
          </Feed_HeadTop>
          <br />
          <Feed_Sub_Main>
            <Feed_Main_Left>
              <Feed_Section style={{ marginTop: '4rem' }}>
                <VideoSection
                  time="3"
                  text="Conoce más de Ana Coach"
                ></VideoSection>
              </Feed_Section>
              <SeparateSection></SeparateSection>
              <Feed_Section>
                <Title_h1 fz="34px" className="dm-sans">
                  Entrenamientos disponibles para ti
                </Title_h1>
                <Feed_Text>
                  Comienza a entrenar ahora, elige unos de nuestros
                  entrenamientos que sabemos te puede ayudar.
                </Feed_Text>
                <ListItems
                  dynamicInsert={dynamicInsert}
                  items={trainingBig}
                ></ListItems>
              </Feed_Section>
              <SeparateSection></SeparateSection>
              <Feed_Section>
                <Title_h1 fz="34px" className="dm-sans">
                  Entrenamientos en progreso
                </Title_h1>
                <Feed_Text>
                  Concluye tus entrenamientos en progreso y sigue mejorando las
                  decisiones financieras que tomas.
                </Feed_Text>
                <ListItems
                  dynamicInsert={dynamicInsert}
                  items={trainingProcess}
                ></ListItems>
              </Feed_Section>
              <SeparateSection></SeparateSection>
              <Feed_Section>
                <Title_h1 fz="34px" className="dm-sans">
                  Conoce más de ti
                </Title_h1>
                <Feed_Text>
                  Realiza los siguientes entrenamientos para saber qué
                  decisiones podrías mejorar.
                </Feed_Text>
                <ListItems
                  tam="medium"
                  dynamicInsert={dynamicInsert}
                  items={itemsMore}
                ></ListItems>
              </Feed_Section>
              <Feed_Section>
                <VideoSection
                  time="3"
                  text="Mejores prácticas para los entrenamientos"
                ></VideoSection>
              </Feed_Section>
              <SeparateSection></SeparateSection>
              <Feed_Section>
                <Title_h1 fz="34px" className="dm-sans">
                  Entrenamientos para tarjeta de crédito
                </Title_h1>
                <Feed_Text>
                  Conoce las principales decisiones que deberías tomar si entras
                  al mundo de las tarjetas de crédito.
                </Feed_Text>
                <ListItems
                  dynamicInsert={dynamicInsert}
                  items={trainingVertical}
                ></ListItems>
              </Feed_Section>
              <Feed_Section>
                <Title_h1 fz="34px" className="dm-sans">
                  ¿Te gustaría saber más de estos productos?
                </Title_h1>
                <Feed_Text>
                  Conoce las principales decisiones que deberias tomar si entras
                  al mundo de las tarjetas de crédito
                </Feed_Text>
                <ProductsSection products={trainingTwin}></ProductsSection>
              </Feed_Section>
              <Feed_Section>
                <ImageSticker
                  src={reward}
                  width="45px"
                  float="right"
                  className="img-fluid"
                />
                <Title_h1 fz="34px" className="dm-sans">
                  No deberías perderte este entrenamiento
                </Title_h1>
                <Feed_Text>
                  Sabemos que este entrenamiento podría ayudarte con tus
                  decisiones. ¡Descubrelo ahora!
                </Feed_Text>
                <ListItems
                  dynamicInsert={dynamicInsert}
                  items={items4}
                ></ListItems>
              </Feed_Section>
              <Feed_Section>
                <Top_Main>
                  <Top_Text>Volver al inicio</Top_Text>
                  <ImageSticker
                    src={scrollTop}
                    width="45px"
                    className="img-fluid"
                  />
                </Top_Main>
              </Feed_Section>
              <br />
              <br />
              <br />
              <br />
              <FooterFeed bott="feed" check="entrenamiento"></FooterFeed>
              <FooterFeedBlue width="50.4%"></FooterFeedBlue>
            </Feed_Main_Left>
            <Feed_Main_Right>
              <Feed_Right_Box br="8px">
                <Title_h1
                  fz="34px"
                  className="dm-sans"
                  style={{ textAlign: 'center' }}
                >
                  Maricela
                </Title_h1>
                <Title_h1
                  fz="34px"
                  className="dm-sans"
                  style={{ textAlign: 'center' }}
                >
                  1
                </Title_h1>
                <Feed_Text style={{ textAlign: 'center' }}>
                  Entrenamiento en progreso
                </Feed_Text>
                <Feed_Text style={{ textAlign: 'center' }}>
                  ¡Obtén el poder de tu dinero!
                </Feed_Text>
              </Feed_Right_Box>
            </Feed_Main_Right>
          </Feed_Sub_Main>
        </Feed_Main>
      </Fragment>
    );
  }
}

export default Feed;
