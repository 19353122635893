import React, { useState, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Container,
  Col12,
  Col6,
  Col11,
  ContentButton,
} from '../common.styled';
import '../../../assets/styles/TextCustom.css';

const CcPersonalInformationContainer = () => {
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    secondname: '',
    email: '',
    motherslastname: '',
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const [error, setError] = useState({
    email: false,
    firstname: false,
    secondname: false,
    lastname: false,
    motherslastname: false,
  });
  const [texterror, settexterror] = useState({
    email: '',
    firstname: '',
    secondname: '',
    lastname: '',
    motherslastname: '',
  });
  const ValidEmail = () => {
    if (
      form.email
      && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)
    ) {
      texterror.email = 'Correo electronico incorrecto';
      error.email = true;
    } else {
      texterror.email = '';
      error.email = false;
      setbuttonDisabled(true);
    }
    eBtn();
  };
  const eBtn = () => {
    if (
      form.email.length !== ''
      && !error.email
      && form.firstname.length !== ''
      && !error.firstname
      && !error.secondname
      && form.lastname.length !== ''
      && !error.lastname
      && form.motherslastname.length !== ''
      && !error.motherslastname
    ) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  };
  const ValidLetras = (nombre, valor) => {
    if (nombre && !/^[a-zA-Z*\s]+$/i.test(valor)) {
      if (nombre === 'secondname' && valor.length === 0) {
        texterror[nombre] = '';
        error[nombre] = false;
      } else {
        texterror[nombre] = 'El valor no puede tener caracteres numericos';
        error[nombre] = true;
      }
    } else {
      texterror[nombre] = '';
      error[nombre] = false;
    }
    eBtn();
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail();
        break;
      default:
        ValidLetras(e.target.name, e.target.value);
        break;
    }
  };

  const guardar = (e) => {
    e.preventDefault();
    if (
      !error.email
      && !error.firstname
      && !error.secondname
      && !error.lastname
      && !error.motherslastname
    ) {
      window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/password-new-cc';
    }
  };
  return (
    <Fragment>
      <Main background="#fff" pb="15%" minheigth="700px">
        <Container width="50%" mt="1rem" widthMobile="100%">
          <Col11>
            <SubtitleOne id="idTitle6" className="font-weight-bold">
              Ultimo paso para tu registro
            </SubtitleOne>
            <ParagrapTwo className="mx-auto">
              Ingresa tus datos personales para personalizar tu cuenta.
            </ParagrapTwo>
            <form autoComplete="off" className="" onSubmit={guardar}>
              <Container width="100%" widthMobile="100%">
                <Col12>
                  <TextField
                    id="email"
                    name="email"
                    error={error.email}
                    label="Correo electrónico"
                    required
                    className="textInput"
                    fullWidth
                    type="email"
                    value={form.email}
                    onChange={handleChange}
                    helperText={texterror.email}
                  />
                </Col12>
                <Col6>
                  <TextField
                    id="firstname"
                    name="firstname"
                    error={error.firstname}
                    label="Primer Nombre"
                    required
                    fullWidth
                    className="textInput"
                    value={form.firstname}
                    onChange={handleChange}
                    helperText={texterror.firstname}
                  />
                </Col6>
                <Col6>
                  <TextField
                    id="secondname"
                    name="secondname"
                    error={error.secondname}
                    label="Segundo Nombre"
                    className="textInput"
                    fullWidth
                    value={form.secondname}
                    onChange={handleChange}
                    helperText={texterror.secondname}
                  />
                </Col6>
                <Col12>
                  <TextField
                    id="lastname"
                    name="lastname"
                    error={error.lastname}
                    label="Apellido Paterno"
                    required
                    className="textInput"
                    value={form.lastname}
                    fullWidth
                    onChange={handleChange}
                    helperText={texterror.lastname}
                  />
                </Col12>
                <Col12>
                  <TextField
                    id="motherslastname"
                    name="motherslastname"
                    error={error.motherslastname}
                    label="Apellido Materno"
                    required
                    className="textInput"
                    fullWidth
                    value={form.motherslastname}
                    onChange={handleChange}
                    helperText={texterror.motherslastname}
                  />
                </Col12>
              </Container>
              <ContentButton mtMobile="2%">
                <Button
                  disabled={buttonDisabled}
                  type="submit"
                  className="d-block mt-5 mt-md-4 mb-5"
                  label="Guardar"
                >
                  Guardar
                </Button>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
    </Fragment>
  );
};

export default CcPersonalInformationContainer;
