import React from 'react';
import {
  ModalBackground,
  ModalBackFT,
  ModalHeader,
  ModalBody,
} from '../../assets/styles/Home.styled';
import { Rectangle23, Rectangle24, Title1 } from './Home.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import Ana from '../../assets/img/anacoach/anavalordinero.png';
import kueski from '../../assets/img/anacoach/Training/kueski.png';
import creditea from '../../assets/img/anacoach/Training/creditea.png';
import { Image } from './common.styled';

const ModalGeneral = ({
  modalActive,
  setModalActive,
  handleFunction,
  params = {},
  heiMod = '',
  txtTitle = '',
  txtSubtitle = '',
  txtBtn = '',
  showBtnExit = false,
  modalKC = false,
}) => (
    <>
      {
        <ModalBackground
          className={modalActive ? '' : 'hidden'}
          display={'true'}
        >
          <ModalBackFT
            marginMob="20% auto"
            margin="8% auto"
            height1400="62%"
            height={heiMod !== '' ? heiMod : '60%'}
            height440={modalKC ? '65%' : '60%'}
            height375={modalKC ? '70%' : '62%'}
            height360={modalKC ? '75%' : '65%'}
            height320={modalKC ? '80%' : '72%'}
          >
            <Rectangle23
              position="absolute"
              background="#91D770"
              Mleft="-30px"
              Mtop="-30px"
              width="60px"
              height="60px"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="90px"
              Mtop="-9px"
              width="28px"
              height="32px"
              transform="rotate(45deg)"
              left="58px"
              top="-27px"
            />
            <Rectangle23
              position="absolute"
              background="#6BD5AF"
              Mleft="190px"
              Mtop="-30px"
              width="62.36px"
              height="67px"
              transform="rotate(45deg)"
              left="171px"
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="280px"
              Mtop="40px"
              width="42.53px"
              height="45.22px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="-60px"
              Mtop="90px"
              width="60px"
              height="60px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle24
              positionD="absolute"
              radius="50px"
              width="145px"
              left="295px"
              top="118px"
              widthM="145px"
              heightM=""
              mLeftM=" "
              mTopM=" "
            />
            <ModalHeader mtop="50px" mtopM="70px" height="40%" heightM="40%">
              <Image src={Ana} maxwidth="82px" />
              <Title1
                size="23px"
                aling="center"
                mt="12px"
                sizeM="18px"
                mleftM=" "
                mtopM="20px"
                heightM=" "
              >
                {txtTitle}
              </Title1>
            </ModalHeader>
            <ModalBody>
              <span
                style={{ padding: '12px 0', height: '80%' }}
                className="roboto"
              >
                {txtSubtitle}
              </span>
              {modalKC ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '20px',
                  }}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleFunction(params, 276)}
                  >
                    <Image src={kueski} maxwidth="120px" />
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleFunction(params, 281)}
                  >
                    <Image
                      src={creditea}
                      maxwidth="130px"
                      height="21px"
                      heightMobile="21px"
                      margin="auto"
                    />
                  </div>
                </div>
              ) : null}
              <CardsBtn
                mtopmd="40px"
                mtop="40px"
                mtopw="30px"
                min_w="100%"
                min_web="100%"
                onClick={() => handleFunction(params)}
              >
                {txtBtn}
              </CardsBtn>
              {showBtnExit && (
                <CardsBtn
                  color="#EC407A !important"
                  back="#fff !important"
                  border="solid 1px !important"
                  mtopmd="20px"
                  mtop="20px"
                  mtopw="20px"
                  min_w="100%"
                  min_web="100%"
                  onClick={() => setModalActive(false)}
                >
                  Salir
                </CardsBtn>
              )}
            </ModalBody>
          </ModalBackFT>
        </ModalBackground>
      }
    </>
);

export default ModalGeneral;
