import React, { Fragment } from 'react';
import '../../assets/styles/WelcomeValidation.css';
import '../../assets/styles/TextCustom.css';

import Modal from '@material-ui/core/Modal';
import '../../assets/styles/perfil/perfiles.css';
import {
  ParagrapTwo,
} from '../common/common.styled';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import ShareCommonFeed from '../../anaCoach/Training/ShareCommonFeed';
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
} from '../common/Home.styled';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function ModalShare({ openModal, closeModal, redirect }) {
  const [modalStyle] = React.useState(getModalStyle);

  return (
        <Fragment>
            <Modal open={openModal} onClose={closeModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className="modalShare" >
                <ParagrapTwo fontWeight="700" cursor="pointer" texttalign="right" textalignMobile="right" className='mx-auto' onClick={closeModal} >X</ParagrapTwo>
                <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
                    <Rectangle24 positionD="absolute" Ptop= "40px" mLeftM="0" PLeftM="26%" mTopM="22%" Pleft="5%" background="#6BD5AF" displayM="block" displayD=" " left=" " top=" " width="80px" height="80px" widthM="30px" heightM="30px" transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);" />
                    <Vector2 width="60px" left="0" height="70px" widthM="30px" heightM="40px" background="#91D770" radius="40%" transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)" positionD ="absolute" Pleft="10%" Ptop="172px" PleftM="30%" PtopM="88px" displayM="block" />
                    <Vector3 position="absolute" left=" " top=" " PtopM="0" PleftM="40%" Ptop="280px" Pleft ="3%" background=" #E3E660;" transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)" width="55px" height="55px" widthM="35px" heightM="35px" displayD=" " displayM="block" />
                    <ContentHomeSection leftD="0%" floatD="left" width="25%;" widthM="0"></ContentHomeSection>
                    <ContentHomeSection leftD="0%" floatD="left" width="50%;" widthM="100%">
                        <Rectangle24 positionD="absolute" Ptop= "83px" mLeftM="0" PLeftM="5%" mTopM="3%" Pleft="65%" background="#BFDD6A" displayM="block" displayD=" " left=" " top=" " width="30.94px" height="30.94px" widthM="25px" heightM="25px" transform="rotate(46.41deg)" />
                        <Vector2 width="50px" left="0" height="60px" background="#6BD5AF" radius="40%" transform="rotate(-8.34deg)" positionD ="absolute" Pleft="81%" Ptop="146px" PleftM="36%" PtopM="22px" displayM="block" />
                        <Vector3 position="absolute" left=" " top=" " Ptop="12px" Pleft ="82%" PtopM="100px" PleftM ="83%" background="#E3E660" transform="rotate(-15.06deg)" width="80px" height="80px" widthM="42px" heightM="42px" displayD=" " displayM="block" />
                        <Rectangle24 positionD="absolute" mLeftM="0" PLeftM="15%" mTopM="-65%" Pleft="83%" Ptop="250px" displayD="" width="100px" height="100px" widthM="32px" heightM="32px" left=" " top=" " background="#91D770" transform="rotate(-17.24deg)" />
                        <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
                            <ContentHomeSection leftD="0%" floatD="left" width="48%;"></ContentHomeSection>
                            <ImageCoachInicio />
                            <ContentHomeSection leftD="0%" floatD="left" width="35%;"></ContentHomeSection>
                        </ContentHomeSection>
                    </ContentHomeSection>
                    <ContentHomeSection leftD="0%" floatD="left" width="25%;" widthM="0"></ContentHomeSection>
                </ContentHomeSection>
                <ContentHomeSection displayD="flex" leftD="10%" floatD="left" width="80%;" topD="-10px" topDW="-85px">
                    <ShareCommonFeed textShare="¿Te interesó este entrenamiento? Comparte con un amigo en:" url={redirect}></ShareCommonFeed>
                </ContentHomeSection>
                </div>
            </Modal>
        </Fragment>
  );
}

export default ModalShare;
