import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DYNAMIC_INSERT } from '../../mutations';

const Pixeles = ({ location, match }) => {
  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('INSERCION EXITOSA!');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });
  useEffect(() => {
    let mediums = '';
    const params = new URLSearchParams(location.search);
    const { medium } = match.params;
    mediums = medium !== '' || medium != null ? match.params.medium : '';
    mediums = mediums.replace('.gif', '');
    const event = params.get('event') != null ? params.get('event') : '';
    const Id = params.get('id') != null ? params.get('id') : '';
    const step = params.get('step') != null ? params.get('step') : '';
    const alfa = params.get('alfa') != null ? params.get('alfa') : '';
    const beta = params.get('beta') != null ? params.get('beta') : '';

    console.log('parametros == ', mediums, event, Id, step, alfa, beta);
    dynamicInsert({
      variables: {
        input: {
          table: 'datPixel',
          columns: ['medium', 'event', 'idTicket', 'step', 'alfa', 'beta'],
          detailValues: {
            values: [mediums, event, Id, step, alfa, beta],
          },
        },
      },
    });
  }, []);

  return (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#0E0E0E', height: '36.1rem',
        }}>
            <img alt="" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="/>
        </div>
  );
};

export default Pixeles;
