import React, { useState, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  SubtitleOne,
  ParagraphOne,
  Main,
  ParagrapTwo,
  SpanFooter,
  Container,
  Col3,
  Col11,
  ContentButton,
  Col4,
  ButtonPassword,
} from '../common.styled';
import '../../../assets/styles/TextCustom.css';

const CcResetPassword = () => {
  const [form, setForm] = useState({
    pass1: '',
    pass2: '',
    pass3: '',
    pass4: '',
  });
  const [show, setShow] = useState(true);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (form.pass1.length === 1 && form.pass2.length === 1 && form.pass3.length === 1) {
      // history.push("/the-gym/for-you");
    } else {
      // SetvalueError(true);
      // error.codigo = "LLenar todos los recuadros"
    }
  };
  const guardar = (e) => {
    e.preventDefault();
    window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/step2-comparativo';
  };

  const sendOtpbutton = () => {
  };

  const handleClickBtn = (e) => {
    console.log('Entro a handleClickBtn');
    if (form.pass1 === '') {
      setForm({
        ...form,
        pass1: e.target.value,
      });
    } else if (form.pass2 === '') {
      setForm({
        ...form,
        pass2: e.target.value,
      });
    } else if (form.pass3 === '') {
      setForm({
        ...form,
        pass3: e.target.value,
      });
    } else if (form.pass4 === '') {
      setForm({
        ...form,
        pass4: e.target.value,
      });
    }
  };

  return (
        <Fragment>
            <Main className="justify-content-center align-items-center" background='#fff'>
                <Container width="50%" mt="1rem" widthMobile="100%" mb="15%">
                    <Col11>
                        <SubtitleOne mt='7%'>Instrucciones enviadas</SubtitleOne>
                        <ParagrapTwo className="mx-auto" mb="5%">Te hemos enviado las instrucciones para restablecer tu contraseña al telefono registrado a través de WhatsApp</ParagrapTwo>

                        <ContentButton mt="1%" mtMobile="2%" mtMobile="5%">
                            <Button className="d-block  mt-md-4" onClick={sendOtpbutton}>Reenviar OTP WA</Button>
                        </ContentButton>

                        {show ? (
                            <Container width="100%" mtMobile="30%" widthMobile="100%">
                                <SubtitleOne mt='7%' sizeMobile="20px" size="30px" >Ingresa el codigo que te hemos enviado</SubtitleOne>
                                <ParagrapTwo className="mx-auto">Ingresa tu codigo de 4 digitos.</ParagrapTwo>
                                <form autoComplete="off">
                                    <Container width="80%">
                                        <Col3>
                                            <TextField id="pass1" label="1" type="tel" value={form.pass1} onChange={handleChange}/>
                                        </Col3>
                                        <Col3>
                                            <TextField id="pass2" label="2" type="tel" value={form.pass2} onChange={handleChange}/>
                                        </Col3>
                                        <Col3>
                                            <TextField id="pass3" label="3" type="tel" value={form.pass3} onChange={handleChange}/>
                                        </Col3>
                                        <Col3>
                                            <TextField id="pass4" label="4" type="tel" value={form.pass4} onChange={handleChange} />
                                        </Col3>
                                    </Container>
                                    <Container mt="10%" mb="30%" width="50%" widthMobile="80%" displayweb="none" displaymobile="flex">
                                        <Col4>
                                            <ButtonPassword id="btn1" type="button" value="1" onClick={handleClickBtn}>1</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn2" type="button"value="2" onClick={handleClickBtn}>2</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn3" type="button"value="3" onClick={handleClickBtn}>3</ButtonPassword>
                                        </Col4>

                                        <Col4>
                                            <ButtonPassword id="btn4" type="button" value="4" onClick={handleClickBtn}>4</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn5" type="button" value="5" onClick={handleClickBtn}>5</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn6" type="button" value="6" onClick={handleClickBtn}>6</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn7" type="button" value="7" onClick={handleClickBtn}>7</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn8" type="button" value="8" onClick={handleClickBtn}>8</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn9" type="button" value="9" onClick={handleClickBtn}>9</ButtonPassword>
                                        </Col4>
                                        <Col4>
                                            <ButtonPassword id="btn0" type="button" value="0" onClick={handleClickBtn}>0</ButtonPassword>
                                        </Col4>
                                    </Container>
                                </form>
                            </Container>
                        ) : (
                            <div></div>
                        )}
                        <ContentButton mt="1%" mtMobile="2%" mtMobile="5%">
                            <Button className="d-block  mt-md-4" onClick={guardar}>Iniciar Sesión</Button>
                            {show ? (
                                <ParagraphOne fontSize='15px' mt="5%" >Si tienes problemas para restablecer tu contraseña da click <SpanFooter>aqui</SpanFooter></ParagraphOne>
                            ) : (
                                <div></div>
                            )}
                        </ContentButton>
                    </Col11>
                </Container>
            </Main>
        </Fragment>
  );
};

export default CcResetPassword;
