/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DivLoader,
  Images,
  TrainingQuestion,
  TrainingText,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import {
  GET_PRODUCTS_BY_IDTRAINING_AND_NAME,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
// import loader from '../../assets/img/anacoach/loading.svg';
import { Container, Col7 } from '../../components/common/common.styled';

const ConocimientosNuevos = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  const productNames = [
    'Udemy',
    'Domestika',
    'Edx',
    'Coursera',
    'Tus clases',
    'Superprof',
    'Ayo Education',
    'Workana',
    'Fiverr',
    'People Per Hour',
    'Guru',
    'FelexJobs',
    'Freelancer',
    'Up Work',
    'Toptal',
    '99 Designs',
    'Crowdspring',
  ];

  const [productsData, setProductsData] = useState([]);

  const {
    loading,
    error,
    data: prodData,
  } = useQuery(GET_PRODUCTS_BY_IDTRAINING_AND_NAME, {
    variables: {
      idTraining: parseInt(currentTraining),
      names: productNames,
    },
    onCompleted({ getProductsByIdTrainingAndName }) {
      const products = JSON.parse(getProductsByIdTrainingAndName.response);
      setProductsData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  if (loading) {
    return (
      <div>
        <DivLoader
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <Images
            src={''}
            top="-32px"
            topweb=""
            left="2px"
            leftw="0"
            width="95px"
            position="absolute"
            className="img-fluid rotate180"
          ></Images>
          {/* <CircularProgress color="inherit" /> */}
        </DivLoader>
        <DivLoader
          bcolor="transparent"
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <TrainingText
            size="11px"
            sizew="12px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
            color="#A6A6A6"
          >
            Escribiendo...
          </TrainingText>
        </DivLoader>
      </div>
    );
  }
  if (error) return `Error: ${error}`;

  return (
    <ConocimientosNuevosComponent
      Date={Date}
      hora={hora}
      orderRutine={orderRutine}
      setOrderRutine={setOrderRutine}
      currentTraining={currentTraining}
      createJsonInput={createJsonInput}
      questions={questions}
      setQuestions={setQuestions}
      currentRoutine={currentRoutine}
      nextOrderRoutine={nextOrderRoutine}
      trainingColor={trainingColor}
      data={productsData}
      setDescIntro={setDescIntro}
      extraIncomeProducts={extraIncomeProducts}
      setExtraIncomeProducts={setExtraIncomeProducts}
    />
  );
};

const ConocimientosNuevosComponent = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [
    preguntasExtraIncomeConocimientosNuevos,
    setPreguntasExtraIncomeConocimientosNuevos,
  ] = useState(
    sessionStorage.getItem('PreguntasExtraIncomeConocimientosNuevos') || '',
  );

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [primeraPreguntaConociNuevo, setPrimeraPreguntaConociNuevo] = useState(
    sessionStorage.getItem('PrimeraPreguntaConociNuevo') || '',
  );
  const [segundaPreguntaConociNuevo, setSegundaPreguntaConociNuevo] = useState(
    sessionStorage.getItem('SegundaPreguntaConociNuevo') || '',
  );
  const [showSegundaPreguntaConociNuevo, setShowSegundaPreguntaConociNuevo] = useState(true);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    setPreguntasExtraIncomeConocimientosNuevos(val);
    let products = [];
    switch (val.step) {
      case 0:
        setEditQuestOne(true);
        setPrimeraPreguntaConociNuevo(val.value);
        if (
          val.value === 'Aprender cosas nuevas'
          || val.value === 'Me gusta compartir mis conocimientos'
        ) {
          setShowSegundaPreguntaConociNuevo(false);
          if (val.value === 'Aprender cosas nuevas') {
            products = data.filter(
              (e) => e.Name.startsWith('Udemy')
                || e.Name.startsWith('Domestika')
                || e.Name.startsWith('Edx')
                || e.Name.startsWith('Coursera'),
            );
            setDescIntro(
              'Si disfrutas aprender nuevas cosas, investigar y superarte, una de las mejores formas para ganar más dinero es aumentar tus conocimientos y habilidades. Hoy en día existen muchas alternativas para tomar cursos en línea, aquí te compartimos algunas:',
            );
          } else {
            products = data.filter(
              (e) => e.Name.startsWith('Tus clases')
                || e.Name.startsWith('Superprof')
                || e.Name.startsWith('Ayo Education'),
            );
            setDescIntro(
              'Si te consideras bueno en alguna materia básica (como matemáticas, español, física, química) aprovecha tu conocimiento e imparte clases de regularización a niños y adolescentes. Puedes ofrecer tus servicios entre amigos, familiares y vecinos, o inscríbete en las siguientes páginas para tener más clases:',
            );
          }
          // setNewScrollPos(sizeHeight);
          setExtraIncomeProducts(products);
        }
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeConocimientosNuevos2',
        });
        break;
      case 1:
        setEditQuestTwo(true);
        setSegundaPreguntaConociNuevo(val.value);
        if (val.value === 'Tengo habilidades en diferentes áreas') {
          products = data.filter(
            (e) => e.Name.startsWith('Workana')
              || e.Name.startsWith('Fiverr')
              || e.Name.startsWith('People Per Hour')
              || e.Name.startsWith('Guru')
              || e.Name.startsWith('FelexJobs'),
          );
          setDescIntro(
            'Aprovecha tus conocimientos y trabaja en tu tiempo libre como freelance, regístrate en alguna de estás páginas que ofrecen ganar dinero por proyecto',
          );
        }
        if (
          val.value
          === 'Tengo experiencia en áreas de marketing, escritura, traducciones, análisis de datos'
        ) {
          products = data.filter(
            (e) => e.Name.startsWith('Freelancer') || e.Name.startsWith('Up Work'),
          );
          setDescIntro(
            'Aprovecha tus conocimientos y trabaja en tu tiempo libre como freelance, regístrate en alguna de estás páginas:',
          );
        }
        if (
          val.value === 'Me considero bueno en el área de diseño y tecnología'
        ) {
          products = data.filter(
            (e) => e.Name.startsWith('Toptal')
              || e.Name.startsWith('99 Designs')
              || e.Name.startsWith('Crowdspring'),
          );
          setDescIntro(
            'Aprovecha tus conocimientos y trabaja en tu tiempo libre como freelance, regístrate en alguna de estás páginas:',
          );
        }
        // setNewScrollPos(sizeHeight);
        setExtraIncomeProducts(products);
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeConocimientosNuevos3',
        });
        break;
      default:
        // console.log('default');
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;

          const answers = {
            1: '',
            2: '',
          };

          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];

              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            // ---------

            let products = [];

            if (answers[1] !== '') {
              setEditQuestOne(true);
              setPrimeraPreguntaConociNuevo(answers[1]);
              if (
                answers[1] === 'Aprender cosas nuevas'
                || answers[1] === 'Me gusta compartir mis conocimientos'
              ) {
                setShowSegundaPreguntaConociNuevo(false);
                if (answers[1] === 'Aprender cosas nuevas') {
                  products = data.filter(
                    (e) => e.Name.startsWith('Udemy')
                      || e.Name.startsWith('Domestika')
                      || e.Name.startsWith('Edx')
                      || e.Name.startsWith('Coursera'),
                  );
                  setDescIntro(
                    'Si disfrutas aprender nuevas cosas, investigar y superarte, una de las mejores formas para ganar más dinero es aumentar tus conocimientos y habilidades. Hoy en día existen muchas alternativas para tomar cursos en línea, aquí te compartimos algunas:',
                  );
                } else {
                  products = data.filter(
                    (e) => e.Name.startsWith('Tus clases')
                      || e.Name.startsWith('Superprof')
                      || e.Name.startsWith('Ayo Education'),
                  );
                  setDescIntro(
                    'Si te consideras bueno en alguna materia básica (como matemáticas, español, física, química) aprovecha tu conocimiento e imparte clases de regularización a niños y adolescentes. Puedes ofrecer tus servicios entre amigos, familiares y vecinos, o inscríbete en las siguientes páginas para tener más clases:',
                  );
                }
                // setNewScrollPos(sizeHeight);
                setExtraIncomeProducts(products);
              }
              gsap.to(window, {
                duration: 1,
                scrollTo: '#activeConocimientosNuevos2',
              });
            }

            if (
              (answers[2] !== '' && answers[1] !== 'Aprender cosas nuevas')
              || answers[1] !== 'Me gusta compartir mis conocimientos'
            ) {
              setEditQuestTwo(true);
              setSegundaPreguntaConociNuevo(answers[2]);
              if (answers[2] === 'Tengo habilidades en diferentes áreas') {
                products = data.filter(
                  (e) => e.Name.startsWith('Workana')
                    || e.Name.startsWith('Fiverr')
                    || e.Name.startsWith('People Per Hour')
                    || e.Name.startsWith('Guru')
                    || e.Name.startsWith('FelexJobs'),
                );
                setDescIntro(
                  'Aprovecha tus conocimientos y trabaja en tu tiempo libre como freelance, regístrate en alguna de estás páginas que ofrecen ganar dinero por proyecto',
                );
              }
              if (
                answers[2]
                === 'Tengo experiencia en áreas de marketing, escritura, traducciones, análisis de datos'
              ) {
                products = data.filter(
                  (e) => e.Name.startsWith('Freelancer')
                    || e.Name.startsWith('Up Work'),
                );
                setDescIntro(
                  'Aprovecha tus conocimientos y trabaja en tu tiempo libre como freelance, regístrate en alguna de estás páginas:',
                );
              }
              if (
                answers[2]
                === 'Me considero bueno en el área de diseño y tecnología'
              ) {
                products = data.filter(
                  (e) => e.Name.startsWith('Toptal')
                    || e.Name.startsWith('99 Designs')
                    || e.Name.startsWith('Crowdspring'),
                );
                setDescIntro(
                  'Aprovecha tus conocimientos y trabaja en tu tiempo libre como freelance, regístrate en alguna de estás páginas:',
                );
              }
              // setNewScrollPos(sizeHeight);
              setExtraIncomeProducts(products);
              gsap.to(window, {
                duration: 1,
                scrollTo: '#activeConocimientosNuevos3',
              });
            }

            // ---------

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setPreguntasExtraIncomeConocimientosNuevos(
        sessionStorage.getItem('PreguntasExtraIncomeConocimientosNuevos') || '',
      );
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editQuestOne, setEditQuestOne] = useState('');
  const [editQuestTwo, setEditQuestTwo] = useState('');

  const handleChangeIcon = (json) => {
    setEditQuestOne(false);
    setEditQuestTwo(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine) {
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeConocimientosNuevos',
        });
      }
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 8 ? (
        <>
          {/* pregunta número uno */}
          <div id="activeConocimientosNuevos"></div>
          <QuestionsOrder
            classCard={'cardOne'}
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (primeraPreguntaConociNuevo === ''
                || (lapiz[0].routine === 9 && lapiz[0].active === 1))
              && editQuestOne !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeConocimientosNuevos2"></div>
          <CardTwo
            classCard={
              primeraPreguntaConociNuevo !== ''
              && (editQuestOne === true
                || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={primeraPreguntaConociNuevo}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={
                primeraPreguntaConociNuevo !== ''
                && segundaPreguntaConociNuevo === ''
                && showSegundaPreguntaConociNuevo
                  ? 'iconEditCommon'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 9, active: 1 })}
            />
          </CardTwo>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {/* pregunta número dos */}
      <QuestionsOrder
        classCard={
          primeraPreguntaConociNuevo !== ''
          && showSegundaPreguntaConociNuevo
          && (editQuestOne === true
            || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((primeraPreguntaConociNuevo !== ''
            && segundaPreguntaConociNuevo === ''
            && showSegundaPreguntaConociNuevo)
            || (lapiz[0].routine === 9 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
            || editQuestOne === true)
          && editQuestTwo !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeConocimientosNuevos3"></div>
      <CardTwo
        classCard={
          segundaPreguntaConociNuevo !== ''
          && showSegundaPreguntaConociNuevo
          && (editQuestTwo === true
            || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={segundaPreguntaConociNuevo}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            segundaPreguntaConociNuevo !== '' && showSegundaPreguntaConociNuevo
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 9, active: 2 })}
        />
      </CardTwo>
    </Fragment>
  );
};

export default ConocimientosNuevos;
