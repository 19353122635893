import Perfil from '../../anaCoach/perfil/Financial_Profile';
import PerfilPersonalInformation from '../../anaCoach/perfil/Personal_Information_perfil';
import PerfilDirecction from '../../anaCoach/perfil/Direcction_Perfil';
import PerfilPayment from '../../anaCoach/perfil/PaymentMethod';
import Recap from '../../anaCoach/Logros/Recap';
import Logros from '../../anaCoach/Logros/Logros';
import TrainingCompleted from '../../anaCoach/Logros/Training_completed';
import TrainingProgress from '../../anaCoach/Logros/Training_progress';

const PerfilR = {
  Perfil,
  PerfilPersonalInformation,
  PerfilDirecction,
  PerfilPayment,
  Recap,
  Logros,
  TrainingCompleted,
  TrainingProgress,

};

export default (PerfilR);
