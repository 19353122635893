import React, { useState, useEffect, Fragment } from 'react';
import { WhatsappR } from "../common/Home.styled";
import BottonWhatsapp from "../../assets/img/lp-w.svg";
function ChatIcono({url}) {
  
  return (
    <WhatsappR
        id="msjWhats"
        href={
          url !== ""
            ? url
            : `https://api.whatsapp.com/send?phone=000000&text=¡Hola! Quisiera platicar con un experto financiero para obtener más información de Coru.
              )}`
        }
        target="_blank"
      >
        <img src={BottonWhatsapp} alt="Whatsapp" />
    </WhatsappR>
  );
}

export default ChatIcono;
