import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import {
  Main,
  Container,
  ParagrapTwo,
  ContainerInvite,
  TitleInvite,
  InfoInvite,
} from '../../components/common/common.styled';
import FooterFeed from '../../components/Feed/Footer_Feed';
import '../../assets/styles/perfil/perfiles.css';
import Header_Logros from '../../components/Perfiles/Header_Perfil';
import { GET_INVITACIONES_COLLABORATIVE } from '../../queries';
import {
  UPDATE_ID_USER_COLLABORATIVE,
  DYNAMIC_GET,
} from '../../mutations';
import TestViralidad from '../../components/Logros/TestViralidad';
import { filterFloat } from '../../Helpers';
import { CardsBtn } from '../../assets/styles/Training.styled';

function Training_progress({ Novisible, paddingInicio, isViernes }) {
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });
  const [invitaciones, setInvitaciones] = useState([]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  const [getInvitacionesCollaborative] = useLazyQuery(
    GET_INVITACIONES_COLLABORATIVE,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getInvitacionesCollaborative }) {
        if (getInvitacionesCollaborative.message === 'success') {
          const invites = JSON.parse(getInvitacionesCollaborative.response);
          if (invites.length > 0) {
            setInvitaciones(invites);
          }
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const [updateIdUserCollaborative] = useMutation(
    UPDATE_ID_USER_COLLABORATIVE,
    {
      onCompleted({ updateIdUserCollaborative }) {
        if (updateIdUserCollaborative.statusCode === 200) {
          const input = {
            phone: String(sessionStorage.getItem('CellPhone')),
          };
          getInvitacionesCollaborative({ variables: input });
        }
      },
    },
  );
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  let userDetail;
  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach((element) => {
          switch (element.IDType) {
            case '3':
              sessionStorage.setItem('level', element.Value);
              break;
            default:
              break;
          }
        });
      }
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const dynamicGetInput = {
      input: {
        table: 'CatUserDetails',
        columns: ['*'],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
        ],
      },
    };

    // Visual effects
    const scrollToTop = () => {
      document.querySelector('#root').scrollIntoView({
        behavior: 'smooth',
      });
    };
    getUserDetail({ variables: dynamicGetInput });
    scrollToTop();
  }, []);

  const aceptarInvitacion = (data) => {
    // Update Id User Collaborative
    const idUser = sessionStorage.getItem('idUser') || '';
    if (idUser !== '' && idUser !== 'undefined') {
      const update = {
        idUser: String(sessionStorage.getItem('idUser')),
        idDatTraining: String(data.idDatTraining),
        phone: String(sessionStorage.getItem('CellPhone')),
      };
      updateIdUserCollaborative({ variables: update });
    } else {
      sessionStorage.setItem('idDatTrainingCollaborative', data.idDatTraining);
      sessionStorage.setItem('fromCollaborative', true);
      window.location.href = '/user-validation/';
    }
  };

  useEffect(() => {
    const input = {
      phone: String(sessionStorage.getItem('CellPhone')),
    };
    setInvitaciones([]);
    getInvitacionesCollaborative({ variables: input });
  }, []);
  return (
    <Fragment>
      {!Novisible ? (
        <Header_Logros name={sessionStorage.getItem('firstName')} />
      ) : (
        <div></div>
      )}
      <Main
        minheigth="auto"
        background="#fafafa"
        mt={isViernes ? ' ' : '10%'}
        mtWeb={isViernes ? ' ' : '10%'}
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
      >
        {isViernes ? (
          <Fragment>
            <TestViralidad />
            <br />
          </Fragment>
        ) : null}
        <Container
          displayweb="block"
          className="container"
          paddingMobile="0!important"
          width="94%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <ParagrapTwo
            mb="10%"
            fontSize="20px"
            fontSizeWeb="20px"
            mt="5%"
            mbweb="35px"
            textalignMobile="left"
            texttalign="left"
            className="mx-auto roboto"
            mtWeb="10%"
            fontWeight="bold"
          >
            Mis invitaciones:
          </ParagrapTwo>
          {invitaciones.length === 0 ? (
            <div>Sin invitaciones por el momento.</div>
          ) : (
            <Fragment>
              <ContainerInvite>
                <TitleInvite>Metas en equipo</TitleInvite>
              </ContainerInvite>
              {invitaciones.map((e) => (
                <>
                  <ContainerInvite>
                    <div
                      style={{
                        backgroundColor: '#FFF',
                        padding: '10px',
                        color: '#07210A',
                      }}
                    >
                      <p style={{ fontSize: '14px' }}>
                        En este entrenamiento deberán registrar su ahorro para
                        cumplir la meta:
                      </p>
                      <p
                        style={{
                          fontWeight: '600',
                          textAlign: 'center',
                          fontSize: '18px',
                        }}
                      >
                        "{e.goalName || ''}"
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          margin: '14px 0',
                        }}
                      >
                        <InfoInvite>
                          <b>{e.goalTypesTiempo.Value} meses</b>
                          <p style={{ fontSize: '10px', color: '#615959' }}>
                            Tiempo
                          </p>
                        </InfoInvite>
                        <InfoInvite>
                          <b>{e.goalTypesMonto.Value}</b>
                          <p style={{ fontSize: '10px', color: '#615959' }}>
                            Monto
                          </p>
                        </InfoInvite>
                        <InfoInvite>
                          <b>
                            {formatter.format(
                              filterFloat(
                                (
                                  parseFloat(
                                    e.goalTypesMonto.Value.replace(/[,$]/g, ''),
                                  )
                                  / (e.goalTypesTiempo.Value * 2)
                                ).toFixed(2),
                              ),
                            )}
                          </b>
                          <p style={{ fontSize: '10px', color: '#615959' }}>
                            Ahorro quincenal
                          </p>
                        </InfoInvite>
                      </div>
                    </div>
                  </ContainerInvite>
                  <div style={{ textAlign: 'center' }}>
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      min_web="70%"
                      mtopw="0px"
                      mtop="0px"
                      mtopmd="0px"
                      onClick={() => aceptarInvitacion(e)}
                    >
                      Aceptar invitación
                    </CardsBtn>
                  </div>
                  <br />
                  <br />
                </>
              ))}
            </Fragment>
          )}
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="logros" /> : <div></div>}
    </Fragment>
  );
}
export default Training_progress;
