import React, { Fragment } from 'react';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';
import {
  Container, Col12, SubtitleOne, ParagrapTwo,
} from '../common/common.styled';

import '../../assets/styles/perfil/perfiles.css';

function ActivitySummary() {
  return (
        <Fragment>
            <Container width='100%' mt='0rem' widthMobile='100%'>
                <ContainerPerfiles padding="0%" height="auto" background="transparent" borderW="0px" minheight="122px" display="flex" mb="5%" mt="0%" mtMobile="0px" minheight="122px" display="flex">
                    <Container width='100%' mt="0%" widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" >
                        <Col12>
                        <SubtitleOne
                            textalingn="left"
                            textalingnMobile="left"
                            sizeMobile="20px"
                            size="25px" mb="1%"
                            className="font-weight-bold roboto"
                            transform=" "
                            mt="5%">
                             Rachas y trofeos
                            </SubtitleOne>
                            <ParagrapTwo
                            mb="10%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                            Tus logros te ayudarán a conocer tu avance y seguir alcanzando tus metas.
                            </ParagrapTwo>
                        </Col12>
                    </Container>
                </ContainerPerfiles>
            </Container>
        </Fragment>
  );
}
export default ActivitySummary;
