/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TextField from '@material-ui/core/TextField';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import {
  TrainingQuestion,
  QuestionsDivs,
  CardsBtn,
  CardsBtn1,
  QuestionsFlex,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const GastosMedicosMayores = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editAseguradora, setEditAseguradora] = useState('');
  const [editSumaAseg, setEditSumaAseg] = useState('');
  const [editHospitales, setEditHospitales] = useState('');
  const [editSabeSuma, setEditSabeSuma] = useState(''); //
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('caracteristicas', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };
  const [auxAseguradora, setauxAseguradora] = useState([]);
  const [aseguradora, setAseguradora] = useState(
    sessionStorage.getItem('Aseguradora') || '',
  );
  const [sumaAsegurada, setSumaAsegurada] = useState(
    sessionStorage.getItem('SumaAsegurada') || '',
  );
  const [hospitales, setHospitales] = useState(
    sessionStorage.getItem('Hospitales') || '',
  );
  const [sabeSumAsegurada, setSabeSumAsegurada] = useState(
    sessionStorage.getItem('SabesSumaAseguradaPoliza') || '',
  );
  const [auxOtraAseguradora, setAuxOtraAseguradora] = useState(false);
  const [form, setForm] = useState({
    sumaAsegurada: sessionStorage.getItem('SumaAsegurada') || '',
    nombreHospitales: sessionStorage.getItem('Hospitales') || '',
    otraAseguradora: '',
  });

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };

  const [error] = useState({
    sumaAsegurada: false,
  });

  const validateCantidad = (name, value, id) => {
    const elemname = document.getElementsByName(name)[1];
    if (elemname === null) {
      console.log('null');
    } else if (elemname === undefined) {
      const elemname2 = document.getElementsByName(name)[0];
      if (
        value.length >= 1
        && value.length <= 16
        && value !== '$'
        && /[0-9]+$/i.test(form.sumaAsegurada)
      ) {
        elemname2.className = 'textInput inputs-bottom validInput';
        error[`${name}valid`] = 0;
        error[name] = true;
      } else {
        elemname2.className = 'textInput inputs-bottom invalidInput';
        error[name] = true;
        error[`${name}valid`] = 1;
      }
    } else if (
      value.length >= 1
      && value.length <= 16
      && value !== '$'
      && /[0-9]+$/i.test(form.sumaAsegurada)
    ) {
      elemname.className = 'textInput inputs-bottom validInput';
      error[`${name}valid`] = 0;
      error[name] = true;
    } else {
      elemname.className = 'textInput inputs-bottom invalidInput';
      error[name] = true;
      error[`${name}valid`] = 1;
    }
  };

  const handleChange = (e, question = '') => {
    console.log(
      'handleChange InputOrder',
      e.target.name,
      e.target.value,
      e.target.className,
    );

    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      setForm({
        ...form,
        [e.target.name]: `$${formatterMx(filterFloat(value))}`,
      });
    } else if (e.target.value === '$') {
      setForm({
        ...form,
        [e.target.name]: '',
      });
    }
    switch (e.target.name) {
      case 'sumaAsegurada':
        validateCantidad(e.target.name, e.target.value, e.target.id);
        break;
      default:
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleChangeStatus = (numQuestion, name, value, type = '', e) => {
    console.log('handleChangeStatus', numQuestion, name, value);
    if (auxAseguradora.length === 1) {
      let elements = document.querySelectorAll(
        `div[name="${auxAseguradora[0]}"]`,
      );
      let element;
      if (elements.length === 2) {
        element = elements[1];
      } else {
        element = elements[0];
      }
      if (element.classList.contains('border-pink-active')) {
        element.classList.remove('border-pink-active');
      }
      elements = document.querySelectorAll(`div[name="${name}"]`);
      if (elements.length === 2) {
        element = elements[1];
      } else {
        element = elements[0];
      }
      if (!element.classList.contains('border-pink-active')) {
        element.classList.add('border-pink-active');
      }
      setauxAseguradora([name]);
    } else {
      const elements = document.querySelectorAll(`div[name="${name}"]`);
      let element;
      if (elements.length === 2) {
        element = elements[1];
      } else {
        element = elements[0];
      }
      element.classList.add('border-pink-active');
      setauxAseguradora([name]);
    }

    if (numQuestion === undefined) return;
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 1:
        gsap.to(window, { duration: 1, scrollTo: '#activeSumaCantidad' });
        setSabeSumAsegurada(val.value);
        setEditSabeSuma(true);
        break;
      default:
        console.log(val);
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        ...form,
        sumaAsegurada: sessionStorage.getItem('SumaAsegurada') || '',
        nombreHospitales: sessionStorage.getItem('Hospitales') || '',
      });
      setAseguradora(sessionStorage.getItem('Aseguradora') || '');
      setSumaAsegurada(sessionStorage.getItem('SumaAsegurada') || '');
      setHospitales(sessionStorage.getItem('Hospitales') || '');
      setSabeSumAsegurada(
        sessionStorage.getItem('SabesSumaAseguradaPoliza') || '',
      );
      if (
        nextOrderRoutine === 'rp'
        && sessionStorage.getItem('Hospitales') !== null
      ) {
        insuranceActive.push('AXA Seguro de vida');
        setActiveSucces(true);
      }
    }
  }, [recoveryRoutine]);

  const handleChangeInsuranceName = (e, inputBtn = '') => {
    console.log(questions);
    setEditAseguradora(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeSabeSuma' });
    if (inputBtn === 'No se') {
      questions.push({
        id: 'aseguradora',
        question: 1,
        value: 'No lo sé en este momento',
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setAseguradora('No lo sé en este momento');
      const click = `NoloséEnEsteMomento_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else if (inputBtn === 'continuar') {
      questions.push({
        id: 'aseguradora',
        question: 1,
        value: auxAseguradora[0],
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      if (auxAseguradora[0] !== 'Otra') {
        setAseguradora(auxAseguradora[0]);
      } else {
        setAuxOtraAseguradora(true);
      }
      const click = `ContinuarAseguradora_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else if (inputBtn === 'Otra') {
      questions.push({
        id: 'aseguradora',
        question: 1,
        value: form.otraAseguradora,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setAseguradora(form.otraAseguradora);
      const click = `ContinuarAseguradora_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setAuxOtraAseguradora(false);
    }
  };

  const handleChangeInsuranceSum = () => {
    if (
      form.sumaAsegurada.length >= 1
      && form.sumaAsegurada.length <= 16
      && form.sumaAsegurada !== '$'
    ) {
      setEditSumaAseg(true);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setSumaAsegurada(form.sumaAsegurada);
      const click = `ContinuarSumaAsegurada_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  };

  const handleChangeHospitals = () => {
    if (form.nombreHospitales !== '') {
      setEditHospitales(true);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setHospitales(form.nombreHospitales);
      const click = `ContinuarHospitales_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      if (nextOrderRoutine === 'rp') {
        insuranceActive.push('AXA Seguro de vida');
        setActiveSucces(true);

      }
    }
  };
  const handleChangeIcon = (json) => {
    setEditAseguradora(false);
    setEditSumaAseg(false);
    setEditHospitales(false);
    setEditSabeSuma(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  useEffect(() => {
    document.getElementsByClassName('iconEditCommon')[0].style.display = 'none';
  }, []);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (aseguradora === ''
          || (lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
        && editAseguradora !== true
      ) {
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeAseguradoraGMayores',
        });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      <div id="activeAseguradoraGMayores"></div>
      <QuestionsOrder
        classCard="cardOne"
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        wiw="100%"
        className={
          (aseguradora === ''
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
          && editAseguradora !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <form>
            <Fragment>
              <InputsOrder
                handleChangeQuestionsSend={handleChangeStatus}
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                activeStep={true}
                min="1"
                max="100"
              />
            </Fragment>
            <div style={{ display: 'flex' }} className="d-flex flex-column">
              <CardsBtn1
                windthmd="100%"
                onClick={(e) => {
                  handleChangeInsuranceName(e, 'continuar');
                }}
              >
                Continuar
              </CardsBtn1>
              <CardsBtn1
                windthmd="100%"
                type="button"
                back="#FFFFFF"
                color="#F64282"
                border="2px solid #F64282"
                onClick={(e) => {
                  handleChangeInsuranceName(e, 'No se');
                }}
              >
                <b>No lo sé en este momento</b>
              </CardsBtn1>
            </div>
          </form>
        </QuestionsDivs>
      </TrainingQuestion>

      <TrainingQuestion className={auxOtraAseguradora ? '' : 'hidden'}>
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="64%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <TextField
            variant="outlined"
            name="otraAseguradora"
            label="Nombre de aseguradora"
            value={form.otraAseguradora}
            onChange={handleChange}
            fullWidth
          />
          <CardsBtn
            type="button"
            min_w="100%"
            windthG="100%"
            onClick={(e) => handleChangeInsuranceName(e, 'Otra')}
          >
            Continuar
          </CardsBtn>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeSabeSuma"></div>

      <DivRespDP>
        <img
          alt="editIcon"
          className={
            aseguradora !== '' && sabeSumAsegurada === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
          }
        />
        <CardTwo
          classCard={
            aseguradora !== ''
            && (editAseguradora === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          textT={''}
          text2={aseguradora}
          text3=""
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      <QuestionsOrder
        classCard={
          aseguradora !== ''
          && (editAseguradora === true
            || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        wiw="100%"
        className={
          ((sabeSumAsegurada === '' && aseguradora !== '')
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 0))
          && editSabeSuma !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeSumaCantidad"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            sabeSumAsegurada !== '' && sumaAsegurada === '' && hospitales === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 0 })
          }
        />
        <CardTwo
          classCard={
            sabeSumAsegurada !== ''
            && (editSabeSuma === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 0))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          textT={''}
          text2={sabeSumAsegurada}
          text3=""
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      <QuestionsOrder
        classCard={
          sabeSumAsegurada === 'Sí'
          && aseguradora !== ''
          && (editSabeSuma === true
            || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 0))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={2}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        wiw="60%"
        mLg="18%"
        className={
          ((sabeSumAsegurada === 'Sí'
            && aseguradora !== ''
            && sumaAsegurada === '')
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 2))
          && (!(lapiz[0].routine === currentRoutine && lapiz[0].active === 0)
            || editSabeSuma === true)
          && editSumaAseg !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <form>
            <Fragment>
              <InputsOrder
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="2-2"
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            </Fragment>
            <br />
            <br />
            {/* continual family */}
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={(e) => {
                handleChangeInsuranceSum(e);
              }}
            >
              Continuar
            </CardsBtn>
            <br />
          </form>
        </QuestionsDivs>
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            sumaAsegurada !== '' && hospitales === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
          }
        />
        <CardTwo
          classCard={
            sumaAsegurada !== ''
            && (editSumaAseg === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          textT={''}
          text2={sumaAsegurada}
          text3=""
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {((sabeSumAsegurada === 'No' && sumaAsegurada === '')
        || (sabeSumAsegurada === 'Sí' && sumaAsegurada !== ''))
      && (editSabeSuma === true
        || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 0))
      && (editSumaAseg === true
        || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 2)) ? (
        <Fragment>
          <QuestionsOrder
            classCard={
              (sabeSumAsegurada === 'No' && sumaAsegurada === '')
              || (sabeSumAsegurada === 'Sí'
                && sumaAsegurada !== ''
                && (editSabeSuma === true
                  || !(
                    lapiz[0].routine === currentRoutine && lapiz[0].active === 0
                  )))
                ? 'cardOne'
                : 'hidden'
            }
            orderRutine={currentRoutine}
            step={3}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            wiw="60%"
            mLg="18%"
            className={
              ((sabeSumAsegurada === 'No' && sumaAsegurada === '')
                || (sabeSumAsegurada === 'Sí' && sumaAsegurada !== '')
                || hospitales === ''
                || (lapiz[0].routine === currentRoutine
                  && lapiz[0].active === 3))
              && (!(
                lapiz[0].routine === currentRoutine && lapiz[0].active === 0
              )
                || editSabeSuma === true)
              && editHospitales !== true
                ? ''
                : 'hidden'
            }
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width="100%"
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form>
                <Fragment>
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="3-3"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                  />
                </Fragment>
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  onClick={(e) => {
                    handleChangeHospitals(e);
                  }}
                >
                  Continuar
                </CardsBtn>
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
          <DivRespDP>
            <img
              alt="editIcon"
              className={hospitales !== '' ? 'iconEdit1' : 'hidden'}
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: currentRoutine, active: 3 })
              }
            />
            <CardTwo
              classCard={
                hospitales !== ''
                && (editHospitales === true
                  || !(
                    lapiz[0].routine === currentRoutine && lapiz[0].active === 3
                  ))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              textT={''}
              text2={hospitales}
              text3=""
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        </Fragment>
        ) : null}
    </div>
  );
};

export default GastosMedicosMayores;
