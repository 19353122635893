import React, { Fragment, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Main } from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import FooterFeed from '../../components/Feed/Footer_Feed';
import Header from '../../components/common/Header';
import LogrosWeb from './LogrosWeb';
import { GET_DAY_OF_WEEK } from '../../queries';
import { ADD_SCORE_TO_USER } from '../../mutations';
import MetaTags from 'react-meta-tags';

function FinancualProfile() {
  const iduserscore = parseInt(sessionStorage.getItem('idUser') || 0);
  const scoreuserlogros = sessionStorage.getItem('ScoreUserLogros') || false;
  const [addScoreUser] = useMutation(ADD_SCORE_TO_USER);
  const [getDayOfWeek] = useLazyQuery(GET_DAY_OF_WEEK, {
    onCompleted({ getDayOfWeek }) {
      const { today } = getDayOfWeek;
      sessionStorage.setItem('dayOfWeek', today);
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    getDayOfWeek();
  }, []);

  // Agregamos Score al usuario en cada ingreso a plataforma
  useEffect(() => {
    if(iduserscore !== 0 && !scoreuserlogros) {
      sessionStorage.setItem('ScoreUserLogros', true);
      addScoreUser({
        variables: {
          idUser: iduserscore,
          score: 10,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
        <MetaTags>
          <title>Coru: Logros</title>
          <link rel="canonical" href="https://coru.com/achievements" />
        </MetaTags>
        <Header url={window.location.pathname} pathname={window.location.pathname} background="#fafafa"/>
        <Main heightComponent="100vh" background='#fafafa' pb="5%" mt="0%" mtWeb="0%" ptuno="0px" pt="0px">
          <LogrosWeb/>
        </Main>
        <FooterFeed check='logros'/>
    </Fragment>
  );
}
export default FinancualProfile;
