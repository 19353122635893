/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import TagManager from 'react-gtm-module';
import '../../assets/styles/Home.css';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  ImageSections,
  TrainingDivSection,
  TrainingSectionFlex,
  Images,
  DivFlex,
  DivStyle,
  DivGray,
  Resultado,
  CardsBtn,
  ImgPL,
  CardsTxt,
  RPFastTrack,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import { Container, Col7 } from '../../components/common/common.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import stars from '../../assets/img/anacoach/5Stars.png';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ClickButton from '../../Helpers/HookHelpers';
import {
  GET_PRODUCTS_OPORTUNITIES,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';

import { DYNAMIC_UPDATE, DYNAMIC_GET } from '../../mutations/index';

import LoadingWo from '../../components/Training/loadingWo';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';

const ShowAvailablePL = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const anchop = window.screen.width;

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
          // setStateRecovery("inactiva");
        } else {
          const routines = json; // Object.values(json);
          console.log(routines);
          const contResponse = 0;
          // debugger;
          let stopRoutine = false;
          let selectedCreditCards = '';
          for (const idRoutine in routines) {
            if (routines[idRoutine].productsUser !== undefined) {
              const arrayselectedCreditCards = routines[idRoutine].productsUser;
              const { datOpportunities } = routines[idRoutine];
              const { datProducts } = routines[idRoutine];

              selectedCreditCards = arrayselectedCreditCards
                .map((element) => {
                  console.log(element.nameProduct);
                  return element.nameProduct;
                })
                .join();
              // debugger;
              if (
                datOpportunities !== ''
                && datOpportunities !== null
                && datProducts !== ''
                && datProducts !== null
                && selectedCreditCards !== ''
                && selectedCreditCards !== null
                && selectedCreditCards !== 'TEMPORAL'
              ) {
                sessionStorage.setItem('datOpportunity', datOpportunities);
                sessionStorage.setItem('datProducts', datProducts);
                sessionStorage.setItem('recoveryTarjetas', selectedCreditCards);
                setCredito(selectedCreditCards);
              } else {
                stopRoutine = true;
                break;
              }
            } else {
              stopRoutine = true;
              break;
            }
          }
          if (stopRoutine) {
            console.log('detente');
            setRecoveryRecoveryRoutine('activa');
            setQuestions([]);
          } else {
            console.log('avanza');
            setRecoveryRecoveryRoutine('activa');
            setQuestions([]);
            setOrderRutine(nextOrderRoutine);
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const recoveryCredito = sessionStorage.getItem('recoveryTarjetas') || '';
      setCredito(recoveryCredito);
      if (recoveryCredito !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // end recovery
  const [getOpportunity] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const response = JSON.parse(dynamicGet.response)[0];
        console.log('dynamicGet 1 =====>>>>>', dynamicGet);
        if (
          response !== undefined
          && response !== null
          && response.Name !== 'TEMPORAL'
        ) {
          setCredito(response.Name);
          sessionStorage.setItem(
            'SelectedProductDetail',
            `${response.CatProduct_IDProduct
            },${
              response.Name
            },${
              response.ImageURL
            },${
              response.hasApi}`,
          );
        }
      }
    },
  });
  let products = [];
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
        console.log(dynamicUpdate);
      } else {
        console.log(dynamicUpdate.message);
      }
      setOrderRutine(nextOrderRoutine);
    },
  });

  const [results, setResults] = useState([]);
  const [getProductsOportunities] = useLazyQuery(GET_PRODUCTS_OPORTUNITIES, {
    onCompleted({ getProductsOportunities }) {
      if (getProductsOportunities.statusCode === 200) {
        products = JSON.parse(getProductsOportunities.response);
        if(products[products.length - 1].Name === "Fintonic") {
          products.unshift(products[products.length - 1]);
          products.pop();
        }
        setResults(products);
      }
    },
  });

  const [Credito, setCredito] = useState(
    sessionStorage.getItem('CreditoSeleccionado') || '',
  );
  const handleChangeSelectPrestamo = (
    idProduct,
    nameProduct,
    imgProduct,
    hasApi,
  ) => {
    const click = `ElegirCredito_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setCredito(nameProduct);
    setEditRP(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeLlenarSolicitud' });
    sessionStorage.setItem('productPL', nameProduct);
    const inputUpdate = {
      input: {
        table: 'DatOpportunity',
        columns: [
          {
            setkey: 'CatProduct_IDProduct',
            setval: String(idProduct),
          },
        ],
        conditions: [
          {
            valone: 'IDDatOpportunity',
            valtwo: String(sessionStorage.getItem('datOpportunity')),
            condition: '=',
          },
        ],
      },
    };

    dynamicUpdate({ variables: inputUpdate });
    const tagManagerArgs = {
      dataLayer: {
        event: 'createOpportunity',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    sessionStorage.setItem(
      'SelectedProductDetail',
      `${idProduct},${nameProduct},${imgProduct},${hasApi}`,
    );
  };

  const getResultFeatures = (stringifyFeatures) => {
    const features = JSON.parse(stringifyFeatures);
    return features
      .filter((item) => !item.Name.includes('Beneficio'))
      .map((feature) => {
        if (
          feature.Name !== 'Antiguedad mínima en empl'
          && feature.Name !== 'Ingreso Mínimo Requerido'
          && feature.Name !== 'Anualidad'
        ) {
          return (
            <DivFlex justify="space-between">
              <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                {feature.Name === 'CAT'
                  ? `CAT ${(feature.Description * 100).toFixed(2)}%`
                  : feature.Name === 'CAT Diario'
                    ? `CAT ${
                      feature.Description
                    }`
                  : feature.Name === 'Interés anual'
                    ? `Tasas de interés ${
                      (feature.Description * 100).toFixed(2)
                    }%`
                    : `${feature.Name} ${feature.Description}`}
              </CardsTxt>
            </DivFlex>
          );
        }
      });
  };

  const getResultBenefits = (stringifyBenefits) => {
    const benefits = JSON.parse(stringifyBenefits);
    return benefits
      .filter(
        (item) => item.Name.includes('Beneficio') && item.Description.length > 1,
      )
      .map((benefit, index) => {
        if (index < 3) {
          return <li className="roboto styleBenef">{benefit.Description}</li>;
        }
      });
  };

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editRP, setEditRP] = useState('');
  const handleChangeIcon = (json) => {
    setEditRP(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [lapizRP, setLapizRP] = useState(
    sessionStorage.getItem('Gender') || '',
  );
  useEffect(() => {
    if (lapizRP !== '') {
      setLapizRP('Quitar');
    }
  }, [lapizRP]);

  const mapResults = (results) => results.map((result, index) => {
    {
      console.log(result);
    }
    return (
        <Resultado
          bcolor="#fff"
          ta="left"
          border="none"
          hei="530px"
          minh="530px"
          minhweb="530px"
        >
          <ImgPL>
            <ImageSections
              width="auto"
              hmob="45px"
              ml="5%"
              heightweb="45px"
              src={result.ImageURL}
              className="img-fluid"
            />
          </ImgPL>
          <DivGray bcolor="#fff" padd="0 5% 0 5%">
            <hr />
            <DivStyle mh="80px">
              {getResultFeatures(result.productDetail.response)}
            </DivStyle>
            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="img-fluid"
            ></Images>
            <hr />
            <DivStyle align="left" mh="198px" size="14px">
              <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                Beneficios
              </CardsTxt>
              {getResultBenefits(result.productDetail.response)}
            </DivStyle>
            <CardsBtn
              mtop="10px"
              min_w="100%"
              min_web="100%"
              onClick={() => {
                handleChangeSelectPrestamo(
                  result.IDProduct,
                  result.Name,
                  result.ImageURL,
                  result.hasApi,
                );
              }}
            >
              Elegir crédito
            </CardsBtn>
          </DivGray>
        </Resultado>
    );
  });

  useEffect(() => {
    const variables = {
      idUser: parseInt(sessionStorage.getItem('idUser')),
      idCont: parseInt(sessionStorage.getItem('idCont')),
      income: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
      vertical: 5,
      idTraining: parseInt(sessionStorage.getItem('idTraining')),
    };
    console.log('Variables ====>>>>', variables);
    getProductsOportunities({ variables });

    /* Scroll btns */

    const getProductsInput = {
      input: {
        table: 'CatRelUsersOpportunity, DatOpportunity, CatProduct',
        columns: ['*'],
        conditions: [
          {
            valone: 'DatOpportunity.IDDatOpportunity',
            valtwo: 'CatRelUsersOpportunity.IDDatOpportunity',
            condition: '=',
            validColumn: true,
          },
          {
            valone: 'CatProduct_IDProduct',
            valtwo: 'CatProduct.IDProduct',
            condition: '=',
            validColumn: true,
            logic: 'AND',
          },
          {
            valone: 'IDUserCollaborative',
            valtwo: sessionStorage.getItem('idUser'),
            condition: '=',
            logic: 'AND',
          },
          {
            valone: 'CatTraining_IDCatTraining',
            valtwo: sessionStorage.getItem('idTraining'),
            condition: '=',
            logic: 'AND',
          },
        ],
      },
    };
    getOpportunity({ variables: { input: getProductsInput.input } });
  }, []);

  useEffect(() => {
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    const buttonR = document.getElementById('right-button');
    if (buttonR !== null) {
      buttonR.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;
        const cw = content.scrollWidth;

        if (sl + scrollStep >= cw) {
          content.scrollTo(cw, 0);
        } else {
          content.scrollTo(sl + scrollStep, 0);
        }
      });
    }
  }, []);

  useEffect(() => {
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    const buttonL = document.getElementById('left-button');
    if (buttonL !== null) {
      buttonL.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;

        if (sl - scrollStep <= 0) {
          content.scrollTo(0, 0);
        } else {
          content.scrollTo(sl - scrollStep, 0);
        }
      });
    }
  }, []);
  const irDerecha = () => {
    const buttonR2 = document.getElementById('right-button');
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    if (buttonR2 !== null) {
      buttonR2.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;
        const cw = content.scrollWidth;
        if (sl + scrollStep >= cw) {
          content.scrollTo(cw, 0);
        } else {
          content.scrollTo(sl + scrollStep, 0);
        }
      });
    }
  };

  const irIzquierda = () => {
    const buttonL2 = document.getElementById('left-button');
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    if (buttonL2 !== null) {
      buttonL2.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;

        if (sl - scrollStep <= 0) {
          content.scrollTo(0, 0);
        } else {
          content.scrollTo(sl - scrollStep, 0);
        }
      });
    }
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && orderRutine === currentRoutine && Credito === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeRP_PL' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="availableOptions"></div>
      {results.length !== 0 ? (
        <>
          <div id="activeRP_PL"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || Credito !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || Credito !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="✅ Estos son los créditos que te podrían interesar. Revisa las opciones que tenemos para ti y elige la que se adecúe a tus necesidades."
            hora={hora}
          />
        </>
      ) : null}
      {results.length !== 0 && Credito === '' ? (
        <TrainingDivSection
          padding="0 5%"
          back="#fafafa"
          backw="#fafafa"
          className={
            (orderRutine === currentRoutine
              || (lapiz[0].routine === 3 && lapiz[0].active === 1))
            && editRP !== true
              ? ''
              : 'hidden'
          }
        >
          <TrainingSectionFlex
            className="d-flex flex-row"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <button
              className="lefty paddle1FastTrack"
              id="left-button"
              onClick={(e) => irIzquierda(e)}
            ></button>
            <RPFastTrack id="contentRP">{mapResults(results)}</RPFastTrack>
            <button
              className="righty paddle2FastTrack"
              id="right-button"
              onClick={(e) => irDerecha(e)}
            ></button>
          </TrainingSectionFlex>
        </TrainingDivSection>
      ) : Credito === '' ? (
        <div>
          <Container width="80%" mt="0" widthMobile="90%" mtMobile="1rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        </div>
      ) : null}
      <br />
      <div id="activeLlenarSolicitud"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={Credito !== '' && lapizRP === '' ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 3, active: 1 })}
        />
        <CardTwo
          classCard={
            Credito !== ''
            && (!(lapiz[0].routine === 3 && lapiz[0].active === 1)
              || orderRutine !== currentRoutine)
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={Credito}
          hora={hora}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Credito !== ''
          && (!(lapiz[0].routine === 3 && lapiz[0].active === 1)
            || orderRutine !== currentRoutine)
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        textbold="¡Excelente! 👍"
        text="Has elegido una opción para ti."
        text2="Ayúdame con la información que necesitan las instituciones para procesar tu solicitud."
        hora={hora}
      />
    </div>
  );
};

export default ShowAvailablePL;
