/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  TrainingText,
  QuestionsDivs,
  DivRespDP,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import {
  arraySessionsRutines,
  getElementByXpath,
  filterFloat,
} from '../../Helpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';

const MoneySavedOrInvested = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const anchop = window.screen.width;
  const [comotienestudinero, setComotienestudinero] = useState(
    sessionStorage.getItem('Comotienestudinero') || '',
  );
  // openfields
  const [cantidadAhorro, setCantidadAhorro] = useState(
    sessionStorage.getItem('CantidadAhorro') || '',
  );
  const [cantidadInvertido, setCantidadInvertido] = useState(
    sessionStorage.getItem('CantidadInvertido') || '',
  );
  const [cantidadDeben, setCantidadDeben] = useState(
    sessionStorage.getItem('CantidadDeben') || '',
  );
  const [activeCantidadAhorro, setActiveCantidadAhorro] = useState(false);
  const [activeCantidadInvertido, setActiveCantidadInvertido] = useState(false);
  const [activeCcantidadDeben, setActiveCantidadDeben] = useState(false);
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const { actions } = ClickButton();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [form, setForm] = useState({
    cantidadAhorro: sessionStorage.getItem('CantidadAhorro') || '',
    cantidadInvertido: sessionStorage.getItem('CantidadInvertido') || '',
    cantidadDeben: sessionStorage.getItem('CantidadDeben') || '',
  });

  const [editCuestionTwo, setEditCuestionTwo] = useState(false);
  const [editCuestionOne, setEditCuestionOne] = useState(
    comotienestudinero !== '',
  );
  const [editCantidadAhorro, seteditCantidadAhorro] = useState(
    cantidadAhorro !== '',
  );
  const [editCandidadInvertido, seteditCandidadInvertido] = useState(
    cantidadInvertido !== '',
  );
  const [editCandidadPrestado, seteditCandidadPrestado] = useState(
    cantidadDeben !== '',
  );
  const [editMontos, setEditMontos] = useState(
    !!(
      cantidadAhorro !== ''
      || cantidadInvertido !== ''
      || cantidadDeben !== ''
    ),
  );
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [responseViewMoney, setResponseViewMoney] = useState('');
  const [btnMoney, setBtnMoney] = useState(true);
  const [activeBtnMonto, setActiveBtnMonto] = useState(false);
  const [onceValidateBtn, setOnceValidateBtn] = useState(true);
  const [questionMultiple, setQuestionsMultiple] = useState([]);
  useEffect(() => {
    if (
      !activeCantidadAhorro
      && document.getElementsByName(
        '¿Tienes ahorros, ya sea guardados en casa o en el banco?',
      )[0] !== undefined
    ) {
      document
        .getElementsByName(
          '¿Tienes ahorros, ya sea guardados en casa o en el banco?',
        )[0]
        .classList.remove('selectedBen');
    } else if (
      !activeCantidadInvertido
      && document.getElementsByName(
        '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?',
      )[0] !== undefined
    ) {
      document
        .getElementsByName(
          '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?',
        )[0]
        .classList.remove('selectedBen');
    } else if (
      !activeCcantidadDeben
      && document.getElementsByName(
        '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?',
      )[0] !== undefined
    ) {
      document
        .getElementsByName(
          '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?',
        )[0]
        .classList.remove('selectedBen');
    }
  }, [activeCantidadAhorro, activeCantidadInvertido, activeCcantidadDeben]);

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    console.log('*******************************numQuestion*****************');
    console.log(numQuestion);
    switch (numQuestion) {
      case 1:
        setEditCuestionOne(true);
        break;
      default:
        break;
    }
  };

  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
    switch (step) {
      case 0:
        setComotienestudinero(value);
        gsap.to(window, { duration: 1, scrollTo: '#activeMoneyQuest2' });
        if (value.includes('No')) {
          setOrderRutine(nextOrderRoutine);
        }

        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const formatter = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);

    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      setForm({
        ...form,
        [e.target.name]: `$${formatter(filterFloat(value))}`,
      });
    } else if (e.target.value === '$') {
      setForm({
        ...form,
        [e.target.name]: '',
      });
    }
  };
  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log('MoneySaved', json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const setAnswer = () => {
    gsap.to(window, { duration: 1, scrollTo: '#activeMoneyQuest3' });
    const click = `ContinuarDinero_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    console.log('store', questionMultiple);
    const storeTemp = questionMultiple.map((item) => item.id);
    console.log(storeTemp);
    setResponseViewMoney(storeTemp.join());
    setBtnMoney(false);
    const resp = [
      {
        id: 'statusDinero',
        question: 2,
        value: storeTemp.join('|'),
      },
    ];
    createJsonInput(Rutine, resp);
    setQuestions([]);
    setEditCuestionTwo(true);
  };
  const recoveryMontosInversionAhorros = (statusDinero) => {
    statusDinero = statusDinero.split('|');
    for (let index = 0; index < statusDinero.length; index++) {
      if (
        String(statusDinero[index]).trim()
        === '¿Tienes ahorros, ya sea guardados en casa o en el banco?'
      ) {
        questionMultiple.push({
          id: '¿Tienes ahorros, ya sea guardados en casa o en el banco?',
          question: 2,
          value: 423,
        });
        setActiveCantidadAhorro(true);
      } else if (
        String(statusDinero[index]).trim()
        === '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?'
      ) {
        questionMultiple.push({
          id: '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?',
          question: 2,
          value: 424,
        });
        setActiveCantidadInvertido(true);
      } else if (
        String(statusDinero[index]).trim()
        === '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?'
      ) {
        questionMultiple.push({
          id: '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?',
          question: 2,
          value: 425,
        });
        setActiveCantidadDeben(true);
      }
    }
    if (statusDinero.length > 0) {
      setAnswer();
    }
  };
  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm(
        Object.assign(form, {
          cantidadAhorro: sessionStorage.getItem('CantidadAhorro') || '',
          cantidadInvertido: sessionStorage.getItem('CantidadInvertido') || '',
          cantidadDeben: sessionStorage.getItem('CantidadDeben') || '',
        }),
      );
      setComotienestudinero(sessionStorage.getItem('Comotienestudinero') || '');
      const statusDinero = sessionStorage.getItem('StatusDinero') || '';
      setResponseViewMoney(statusDinero.split('|').join());
      if (statusDinero !== '' && statusDinero !== null) {
        recoveryMontosInversionAhorros(statusDinero);
      }

      // openfields
      setCantidadAhorro(sessionStorage.getItem('CantidadAhorro') || '');
      setCantidadInvertido(sessionStorage.getItem('CantidadInvertido') || '');
      setCantidadDeben(sessionStorage.getItem('CantidadDeben') || '');
      if (
        sessionStorage.getItem('Comotienestudinero') === 'No'
        || (cantidadAhorro !== '' && cantidadAhorro !== null)
        || (cantidadInvertido !== '' && cantidadInvertido !== null)
        || (cantidadInvertido !== '' && cantidadInvertido !== null)
      ) {
        setBtnMoney(false);
        setActiveBtnMonto(false);
        setOnceValidateBtn(false);
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    console.log('effect', questionMultiple);
    setActiveCantidadAhorro(false);
    setActiveCantidadInvertido(false);
    setActiveCantidadDeben(false);
    const selectorCss = '//*[@id="root"]/div[1]/div[5]/div/div[7]/div[';
    const selectorCss2 = '#root > div.sc-qXhiz.eCHEao > div:nth-child(5) > div > div:nth-child(7) > div:nth-child(';

    for (let i = 1; i <= 3; i++) {
      let elementoDiv = '';
      try {
        elementoDiv = getElementByXpath(`${selectorCss + i}]/div`);
        if (elementoDiv) {
          console.log('remover clase');
          elementoDiv.classList.remove('border-pink-active');
        }
      } catch (e) {
        elementoDiv = document.querySelector(`${selectorCss2 + i})>div`);
        if (elementoDiv) {
          console.log('remover clase catch');
          elementoDiv.classList.remove('border-pink-active');
        }
      }
    }
    for (let i = 0; i < questionMultiple.length; i++) {
      let elementoDiv = '';

      switch (questionMultiple[i].value) {
        case 423:
          try {
            if (
              document.getElementsByName(
                '¿Tienes ahorros, ya sea guardados en casa o en el banco?',
              )[0] !== undefined
              && document.getElementsByName(
                '¿Tienes ahorros, ya sea guardados en casa o en el banco?',
              )[0] !== null
            ) {
              document
                .getElementsByName(
                  '¿Tienes ahorros, ya sea guardados en casa o en el banco?',
                )[0]
                .classList.add('selectedBen');
            }
            elementoDiv = getElementByXpath(`${selectorCss}1]/div`);
            console.log('selector norma', elementoDiv);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          } catch (e) {
            console.log('selector catch');
            elementoDiv = document.querySelector(`${selectorCss2}1)>div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          }
          setActiveCantidadAhorro(true);
          break;
        case 424:
          if (
            document.getElementsByName(
              '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?',
            )[0] !== undefined
            && document.getElementsByName(
              '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?',
            )[0] !== null
          ) {
            document
              .getElementsByName(
                '¿Tienes inversiones, ya sea en negocios, fondos bancarios o financieras?',
              )[0]
              .classList.add('selectedBen');
          }
          try {
            elementoDiv = getElementByXpath(`${selectorCss}2]/div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          } catch (e) {
            elementoDiv = document.querySelector(`${selectorCss2}2)>div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          }
          setActiveCantidadInvertido(true);
          break;
        case 425:
          if (
            document.getElementsByName(
              '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?',
            )[0] !== undefined
            && document.getElementsByName(
              '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?',
            )[0] !== null
          ) {
            document
              .getElementsByName(
                '¿Te deben dinero o tienes algunas deudas a tu favor por cobrar?',
              )[0]
              .classList.add('selectedBen');
          }
          try {
            elementoDiv = getElementByXpath(`${selectorCss}3]/div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          } catch (e) {
            elementoDiv = document.querySelector(`${selectorCss2}3)>div`);
            if (elementoDiv) {
              elementoDiv.classList.add('border-pink-active');
            }
          }
          setActiveCantidadDeben(true);
          break;
        default:
          break;
      }
    }
  }, [questionMultiple]);

  const handleChangeStatus = (numQuestion, name, value, type = '') => {
    console.log('handleChangeStatus', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questionMultiple.filter(
      (element) => element.id === name,
    );
    if (elementExist.length !== 0) {
      const quetionsTemp = [];
      for (const element of questionMultiple) {
        if (element.id !== elementExist[0].id) {
          console.log(element.id, elementExist[0].id);
          quetionsTemp.push(element);
        }
      }
      setQuestionsMultiple(quetionsTemp);
    } else {
      setQuestionsMultiple([
        ...questionMultiple,
        {
          id: name,
          question: numQuestion,
          value,
        },
      ]);
    }
  };
  const getValidateMontos = () => {
    let isCantidadAhorro = false;
    let isCantidadInvertido = false;
    let isCcantidadDeben = false;
    if (activeCantidadAhorro && form.cantidadAhorro !== '') {
      isCantidadAhorro = true;
    }
    if (activeCantidadInvertido && form.cantidadInvertido !== '') {
      isCantidadInvertido = true;
    }
    if (activeCcantidadDeben && form.cantidadDeben !== '') {
      isCcantidadDeben = true;
    }
    if (isCantidadAhorro || isCantidadInvertido || isCcantidadDeben) {
      if (
        activeCantidadAhorro === isCantidadAhorro
        && activeCantidadInvertido === isCantidadInvertido
        && activeCcantidadDeben === isCcantidadDeben
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const convertirNum = (num) => (num ? parseFloat(num.replace('$', '').replace(/,/g, '').trim()) : 0);
  const advanceRoutine = () => {
    const click = `ContinuarADeudas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOrderRutine(nextOrderRoutine);
    gsap.to(window, { duration: 1, scrollTo: '#activeMoneyQuest5' });
  };
  const saveMontos = (e) => {
    gsap.to(window, { duration: 1, scrollTo: '#activeMoneyQuest4' });
    const click = `ContinuarMontos_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    console.log(questions);

    if (getValidateMontos()) {
      setCantidadAhorro(form.cantidadAhorro);
      setCantidadInvertido(form.cantidadInvertido);
      setCantidadDeben(form.cantidadDeben);
      const totalInversionAhorro = convertirNum(form.cantidadAhorro)
        + convertirNum(form.cantidadInvertido)
        + convertirNum(form.cantidadDeben);
      console.log('totalInversionAhorro', totalInversionAhorro);
      questions.push({
        id: 'ahorroInversionTotal',
        question: 6,
        value: `$${formatter(totalInversionAhorro)}`,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);

      setActiveBtnMonto(false);
      setOnceValidateBtn(false);
    }
    seteditCandidadPrestado(true);
    seteditCandidadInvertido(true);
    seteditCantidadAhorro(true);
    setEditMontos(true);
    advanceRoutine();
  };
  useEffect(() => {
    if (onceValidateBtn) {
      if (getValidateMontos()) {
        setActiveBtnMonto(true);
      } else {
        setActiveBtnMonto(false);
      }
    }
  });
  const textInfoAdd = [
    { text1: '' },
    {
      text2:
        '¿No estás seguro si tu coche o casa son inversiones? Un experto nos explica: ',
      link2: 'https://youtu.be/FOAV_Xfo3SM',
      linkText2: 'https://youtu.be/FOAV_Xfo3SM',
    },
    { text3: '' },
  ];
  const handleChangeIcon = (json) => {
    setLapiz([json]);
    if (json.active === 1) {
      console.log('Entro a uno');
      setEditCuestionOne(false);
    } else if (json.active === 2) {
      console.log('Entro a 2');
      setEditCuestionTwo(false);
    } else if (json.active === 3) {
      if (activeCantidadAhorro) {
        seteditCantidadAhorro(false);
      }
      if (activeCantidadInvertido) {
        seteditCandidadInvertido(false);
      }
      if (activeCcantidadDeben) {
        seteditCandidadPrestado(false);
      }
      setEditMontos(false);
    }
  };

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (comotienestudinero === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeSavedOrInvested' });
      }
    }, 4000);
  }, []);

  return (
    <>
      {!loaderWait || orderRutine !== 3 ? (
        <>
          <div id="activeSavedOrInvested"></div>
          {/* primer pregunta */}
          <QuestionsOrder
            classCard="cardOne"
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              comotienestudinero === ''
              || (lapiz[0].active === 1 && !editCuestionOne)
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeMoneyQuest2"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                comotienestudinero !== '' && editCuestionOne
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 3, active: 1 })}
            />
            <CardTwo
              classCard={
                comotienestudinero !== '' && editCuestionOne
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={comotienestudinero}
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {/* segunda pregunta opcional */}
      <QuestionsOrder
        classCard={
          comotienestudinero === 'Sí' && editCuestionOne ? 'cardOne' : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (comotienestudinero === 'Sí'
            && responseViewMoney === ''
            && btnMoney
            && editCuestionOne)
          || (comotienestudinero === 'Sí'
            && editCuestionOne
            && !editCuestionTwo)
          // (responseViewMoney === '' && editCuestionOne)
          || (comotienestudinero === 'Sí' && editCuestionOne && !editCuestionTwo)
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeStatus}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
          AdditionalInformation={true}
          textInformation={textInfoAdd}
        />
      </TrainingQuestion>
      <div
        className={
          (questionMultiple.length > 0
            && responseViewMoney === ''
            && editCuestionOne)
          || (questionMultiple.length > 0 && editCuestionOne && !editCuestionTwo)
            ? ''
            : 'hidden'
        }
      >
        <div style={{ display: 'flex' }}>
          <CardsBtn1
            mtopmd="0px"
            mtop="0px"
            onClick={() => setAnswer()}
            href="#two"
          >
            Continuar
          </CardsBtn1>
        </div>
      </div>
      <div id="activeMoneyQuest3"></div>
      <DivRespDP id="two">
        <img
          alt="editIcon"
          className={
            responseViewMoney !== '' && editCuestionTwo ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 3, active: 2 })}
        />
        <CardTwo
          classCard={
            responseViewMoney !== '' && editCuestionTwo
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={responseViewMoney}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <TrainingDivSection
        hew="auto"
        className="topOne1"
        paddw="26px"
        mlw="18%"
        mtw="8%"
        wiw="60%"
        back="#F3F3F3"
        backw="#F3F3F3"
        bshaw="none"
        bsha=""
        br="4px"
        padding="20px"
        wi="90%"
        he="auto"
        className={
          responseViewMoney !== ''
          && cantidadAhorro === ''
          && cantidadInvertido === ''
          && cantidadDeben === ''
          && editCuestionTwo
            ? 'topOne1Init d-flex flex-column'
            : 'hidden'
        }
      >
        <TrainingQuestion
          width="100%"
          wiw="100%"
          mt="0px"
          className={
            (activeCantidadAhorro
              && responseViewMoney !== ''
              && cantidadAhorro === ''
              && editCuestionTwo)
            || (activeCantidadAhorro && !editCantidadAhorro && editCuestionTwo)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="transparent"
            bs="transparent"
          >
            <form>
              <TrainingText
                bot="0px"
                className="cabin"
                align="left"
                weight="600"
                size="16px"
                sizew="16px"
              >
                Aproximadamente, ¿cuánto dinero tienes ahorrado?
              </TrainingText>
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="2-2"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                labelInput="Monto"
                typeNum={true}
              />
            </form>
          </QuestionsDivs>
        </TrainingQuestion>
        <TrainingQuestion
          width="100%"
          wiw="100%"
          mt="0px"
          className={
            (activeCantidadInvertido
              && responseViewMoney !== ''
              && cantidadInvertido === ''
              && editCuestionTwo)
            || (activeCantidadInvertido
              && !editCandidadInvertido
              && editCuestionTwo)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="transparent"
            bs="transparent"
          >
            <form>
              <TrainingText
                bot="0px"
                className="cabin"
                align="left"
                weight="600"
                size="16px"
                sizew="16px"
              >
                Aproximadamente, ¿cuánto dinero tienes invertido?
              </TrainingText>
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="3-3"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                labelInput="Monto"
              />
            </form>
          </QuestionsDivs>
        </TrainingQuestion>
        <TrainingQuestion
          width="100%"
          wiw="100%"
          mt="0px"
          className={
            (activeCcantidadDeben
              && responseViewMoney !== ''
              && cantidadDeben === ''
              && editCuestionTwo)
            || (activeCcantidadDeben && !editCandidadPrestado && editCuestionTwo)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="transparent"
            bs="transparent"
          >
            <form>
              <TrainingText
                bot="0px"
                className="cabin"
                align="left"
                weight="600"
                size="16px"
                sizew="16px"
              >
                Aproximadamente, ¿cuánto dinero te deben?
              </TrainingText>
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="4-4"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                labelInput="Monto"
              />
            </form>
          </QuestionsDivs>
        </TrainingQuestion>
        <TrainingQuestion
          width="100%"
          mt="0px"
          mtw="0px"
          className={
            activeBtnMonto || (editCuestionTwo && !editMontos) ? '' : 'hidden'
          }
        >
          <div style={{ display: 'flex' }}>
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              windthLg="100%"
              mtop="0px"
              mtopw="0px"
              onClick={(e) => saveMontos(e)}
            >
              Continuar
            </CardsBtn>
          </div>
        </TrainingQuestion>
      </TrainingDivSection>

      <div id="activeMoneyQuest4"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            (cantidadAhorro !== '' && editMontos)
            || (cantidadInvertido !== '' && editMontos)
            || (cantidadDeben !== '' && editMontos)
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 3, active: 3 })}
        />
        <CardTwo
          classCard={
            (cantidadAhorro !== '' && editMontos)
            || (cantidadInvertido !== '' && editMontos)
            || (cantidadDeben !== '' && editMontos)
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={cantidadAhorro ? `Dinero ahorrado: ${cantidadAhorro}` : ''}
          text2={
            cantidadInvertido ? `Dinero invertido: ${cantidadInvertido}` : ''
          }
          text3={cantidadDeben ? `Dinero prestado: ${cantidadDeben}` : ''}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <div id="activeMoneyQuest5"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={!onceValidateBtn && editMontos ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Muy bien, vamos avanzando para conocer tu presupuesto ideal."
        hora={hora}
      />
    </>
  );
};

export default MoneySavedOrInvested;
