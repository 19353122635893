import React, { useState, Fragment, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import TagManager from 'react-gtm-module';
import { Image } from '../../components/common/common.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import {
  DivFlex,
  DivStyle,
  DivGray,
  Resultado,
  RPProductos,
  CardsBtn,
  ImageSections,
  TrainingDivSection,
  TrainingSectionGray,
  DivLoader,
  CardsTxt,
  Images,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import { Container } from '../../assets/styles/Home.styled';
import { ContentHomeSection, Title1 } from '../../components/common/Home.styled';
import { GET_PRODUCTS_BY_IDTRAINING } from '../../queries';
import cheque from '../../assets/img/anacoach/cheque.png';
import CardOne from '../../components/Home/Card1Animate';
import confetis from '../../assets/img/anacoach/Hero.png';

import {
  GET_RECOMMENDATION_TRAININGS,
  CREATE_OPPORTUNITIES,
} from '../../mutations';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ClickButton from '../../Helpers/HookHelpers';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';

const OptionsToSave = ({
  Date,
  hora,
  currentTraining,
  setOrderRutine,
  handleOpcTool,
  handleNewtool,
}) => {
  const { actions } = ClickButton();
  const [products, setProducts] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    onCompleted({ getProductsByIdTraining }) {
      console.log('Productos', getProductsByIdTraining);
      setProducts(JSON.parse(getProductsByIdTraining.response));
      setShowProduct(true);
    },
    onError(error) {
      console.log('error', error);
    },
  });
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const backRutine = (e) => {
    const click = `RegresarOpcionesAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setOrderRutine(6);
    handleOpcTool('');
    sessionStorage.removeItem('HerramientaDeAhorro');
    handleNewtool(true);
  };

  useEffect(() => {
    getProductsByIdTraining({
      variables: { idTraining: String(currentTraining) },
    });
  }, []);
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const abrirNuevoTab = (url, name, IDProduct) => {
    const click = `ComienzaAAhorrar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('clickOutProduct', name);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 4, // remplazar por el id del entrenamiento
        idProducts: [IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 21,
        idType: '',
        value: name,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    // Abrir nuevo tab
    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  if (!showProduct) {
    return (
      <DivLoader>
        <CircularProgress color="inherit" />
      </DivLoader>
    );
  }
  const resultProducts = products.map((currentValue, index) => (
        <Fragment key={index}>
          <TrainingDivSection
            mtw={index === 0 ? '-20%' : ' '}
            id={index}
            back="transparent"
            backw="transparent"
            padding="5%"
            paddw="3% 3% 0 3%"
            he="645px"
            hew="660px"
          >
            {/* <TrainingSubtitles></TrainingSubtitles> */}
            <DivStyle size="18px" line="1.2em">
              <Resultado
                key={currentValue.IDProduct}
                marriweb="auto"
                marri="auto"
                bcolor="#fff"
                ta="left"
                style={{ boxShadow: 'none' }}
                border="1px solid #D2D2D2"
                hei={
                  currentValue.Name
                  === 'Cetesdirecto la forma segura de ser inversionista '
                    ? '585px'
                    : '620px'
                }
                Hweb={
                  currentValue.Name
                  === 'Cetesdirecto la forma segura de ser inversionista '
                    ? '600px'
                    : '630px'
                }
                minh="500px"
                minhweb="500px"
                padd="0"
              >
                {currentValue.Name === 'DINN de Actinver' ? (
                  <ImageSections
                    width="20%"
                    hmob="20px"
                    ml="4%"
                    mt="15px"
                    heightweb="20px"
                    src={currentValue.ImageURL}
                    className="img-fluid"
                  />
                ) : null}
                {currentValue.Name
                === 'Cetesdirecto la forma segura de ser inversionista ' ? (
                  <ImageSections
                    width=" "
                    hmob="40px"
                    ml="5%"
                    pb="10px"
                    pt="10px"
                    mt="15px"
                    borderRadius="4px"
                    heightweb="45px"
                    plw="5px"
                    prw="5px"
                    pl="5px"
                    pr="5px"
                    src={currentValue.ImageURL}
                    className="img-fluid"
                  />
                  ) : null}
                {currentValue.Name === 'ALBO' ? (
                  <ImageSections
                    width="20%"
                    hmob="20px"
                    ml="5%"
                    mt="15px"
                    heightweb="20px"
                    src={currentValue.ImageURL}
                    className="img-fluid"
                  />
                ) : null}
                <br />
                <DivStyle
                  className="cabin"
                  mxh="40px"
                  weight="600"
                  align="left"
                  size="15px"
                  line="1.5em"
                  padd="12px 5% 0 5%"
                  color="#07210A"
                >
                  {currentValue.Name
                  === 'Cetesdirecto la forma segura de ser inversionista '
                    ? 'Cetesdirecto - La forma segura de ser inversionista'
                    : ''}
                  {currentValue.Name === 'DINN de Actinver'
                    ? 'Dinn - Tan sencillo como ahorrar'
                    : ''}
                  {currentValue.Name === 'ALBO'
                    ? 'Albo - Adiós bancos ¡Hola Albo!'
                    : ''}
                </DivStyle>
                <DivGray bcolor="#fff" padd="0 5% 0 5%">
                  <hr />
                  <div>
                    <DivStyle align="left">
                      <DivFlex justify="space-between">
                        {currentValue.benefits.map((detalle) => {
                          if (detalle.Name === 'Beneficio 1') {
                            return (
                              <Fragment key={detalle.IDProductDetail}>
                                <Images
                                  src={cheque}
                                  // top="94px"
                                  topweb="7px"
                                  // left="29px"
                                  leftw="0%"
                                  left="0%"
                                  top="2%"
                                  width="18px"
                                  position="absolute"
                                  className="img-fluid"
                                ></Images>
                                <CardsTxt
                                  style={{
                                    marginLeft: '30px',
                                    fontFamily: 'calibri',
                                    letterspacing: '-0.2px',
                                  }}
                                  color="#000"
                                  weight="normal"
                                  lineH="150%"
                                  size="16px"
                                >
                                  {detalle.Description}
                                </CardsTxt>
                              </Fragment>
                            );
                          }
                        })}
                      </DivFlex>
                    </DivStyle>

                    <DivStyle align="left" mh="150px" size="14px">
                      <CardsTxt
                        color="#000"
                        size="16px"
                        style={{ textAlign: 'center', fontFamily: 'Cabin' }}
                      >
                        <b>Beneficios</b>
                      </CardsTxt>
                      {currentValue.benefits.map((detalle) => {
                        if (detalle.Name === 'Beneficio 2') {
                          return (
                            <Fragment key={detalle.IDProductDetail}>
                              <Images
                                src={cheque}
                                // top="94px"
                                topweb="7px"
                                // left="29px"
                                leftw="0%"
                                left="0%"
                                top="0.5%"
                                width="18px"
                                position="absolute"
                                className="img-fluid"
                              ></Images>
                              <CardsTxt
                                style={{
                                  marginLeft: '30px',
                                  letterSpacing: '-0.3px',
                                }}
                                color="#000"
                                lineH="150%"
                                size="14px"
                              >
                                {detalle.Description}
                              </CardsTxt>
                              <hr />
                            </Fragment>
                          );
                        }
                        if (detalle.Name === 'Beneficio 3') {
                          return (
                            <Fragment key={detalle.IDProductDetail}>
                              <Images
                                src={cheque}
                                // top="94px"
                                topweb="13px"
                                // left="29px"
                                leftw="0%"
                                left="0%"
                                top="3%"
                                width="18px"
                                position="absolute"
                                className="img-fluid"
                              ></Images>
                              <CardsTxt
                                style={{
                                  marginLeft: '30px',
                                  letterSpacing: '-0.3px',
                                }}
                                color="#000"
                                lineH="150%"
                                mt="15px"
                                size="14px"
                              >
                                {detalle.Description}
                              </CardsTxt>
                              <hr />
                            </Fragment>
                          );
                        }
                        if (detalle.Name === 'Beneficio 4') {
                          if (detalle.Description !== '-') {
                            return (
                              <Fragment key={detalle.IDProductDetail}>
                                <Images
                                  src={cheque}
                                  // top="94px"
                                  topweb="13px"
                                  // left="29px"
                                  leftw="0%"
                                  left="0%"
                                  top="3%"
                                  width="18px"
                                  position="absolute"
                                  className="img-fluid"
                                ></Images>
                                <CardsTxt
                                  style={{
                                    marginLeft: '30px',
                                    letterSpacing: '-0.3px',
                                  }}
                                  color="#000"
                                  mt="15px"
                                  lineH="150%"
                                  size="14px"
                                >
                                  {detalle.Description}
                                </CardsTxt>
                              </Fragment>
                            );
                          }
                        }
                      })}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginTop: '25px',
                        }}
                      >
                        <div
                          style={{
                            background: '#76DF44',
                            width: '60px',
                            height: '5px',
                            borderRadius: '15%',
                          }}
                        ></div>
                        <div
                          style={{
                            background: '#76DF44',
                            width: '60px',
                            height: '5px',
                            borderRadius: '15%',
                          }}
                        ></div>
                        <div
                          style={{
                            background: '#76DF44',
                            width: '60px',
                            height: '5px',
                            borderRadius: '15%',
                          }}
                        ></div>
                        <div
                          style={{
                            background: '#76DF44',
                            width: '60px',
                            height: '5px',
                            borderRadius: '15%',
                          }}
                        ></div>
                      </div>
                    </DivStyle>
                    <br />
                    <div className="center">
                      <CardsBtn
                        min_w="100%"
                        onClick={() => abrirNuevoTab(
                          currentValue.ClickOut,
                          currentValue.Name,
                          currentValue.IDProduct,
                        )
                        }
                      >
                        ¡Comienza a ahorrar!
                      </CardsBtn>
                    </div>
                  </div>
                </DivGray>
                <br />
              </Resultado>
            </DivStyle>
            {/* <SeparateSection /> */}
            <br />
            <RPProductos scroll="none"></RPProductos>
          </TrainingDivSection>
        </Fragment>
  ));
  return (
      <>
        <div className="marginComentLgSuccess1">
          <TrainingDivSection
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="100%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingQuestion>
              <TrainingDivSection
                back="transparent"
                backw="transparent"
                mlw="18%"
                paddw="0px"
                wiw="60%"
                padding="0px"
              >
                <div style={{ width: '100%' }}>
                  <Image
                    src={confetis}
                    height="238px"
                    marginLeftM="0px"
                    heightMobile="200px"
                    margin="0px"
                    top="110px"
                    left="0%"
                    mleft="0%"
                    marginLeft="0%"
                    mtop="75px"
                  />
                  <Title1
                    style={{ fontStyle: 'normal' }}
                    backgroundM="transparent"
                    pbottom="6%"
                    background="#FAFAFA"
                    position="sticky"
                    positionM="absolute"
                    mleft="auto"
                    mright="auto"
                    heightM="auto"
                    aling="center"
                    className="roboto cabin"
                    mb="30%"
                    mbM="0px"
                    left="0%"
                    size="35px"
                    mtopM="-20%"
                    mt="-147px"
                    mleftM="0px"
                    sizeM="20px"
                    widthM="100%"
                    width="68%"
                  >
                    Opciones para ahorrar
                  </Title1>
                  {resultProducts}
                </div>
                <br />
                <br />
                <CardsBtn
                  min_w="88%"
                  min_web="100%"
                  mleft="20px"
                  border="1px solid #F64282"
                  back="#fff"
                  color="#F64282"
                  onClick={(e) => backRutine(e)}
                >
                  <b>Regresar</b>
                </CardsBtn>
              </TrainingDivSection>
            </TrainingQuestion>
          </TrainingDivSection>
        </div>
       {/* Enso Klu Fremium  */}
            <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '21' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
 
        <ModalAmazon show={true} wo={'Emergency'}></ModalAmazon>
        {recomendaciones ? (
          <div className="marginComentLgSuccess1">
            <Container
              mlMobile="auto"
              mRMobile="auto"
              mleftmxs="0%"
              mleft="18%"
              mleftm="7%"
              width="100%"
              widthMobile="100%"
              mtMobile="0px"
            >
              <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                  <Recommendations
                    items={recommendedTraining}
                  ></Recommendations>
           
                </ContentHomeSection>
             
              </Feed_Section>
            </Container>
          </div>
        ) : null}
        <ModalVirality />
        <SectionShare WO="Emergency"></SectionShare>
        {recomendaciones ? (
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="100%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                text="¿Aún quieres ir por más?"
                text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
              />
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
              />
            </Feed_Main>
          </Feed_Main>
        ) : null}
      </>
  );
};

export default OptionsToSave;
