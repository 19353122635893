import React from 'react';
import styled from 'styled-components';
import iconFacebook from '../Assets/img/FacebookL.svg';
import iconWhatsapp from '../Assets/img/WhatsappL.svg';
import iconMessenger from '../Assets/img/MessengerL.svg';
import ShareW from '../Assets/img/Sharew.svg';

const Container = styled.div`
  position: absolute;
  width: auto;
  z-index: 1;
  background: 'transparent';
  cursor:pointer;
  @media (max-width: 375px) {
 
    margin: ${(props) => props.m375 || ''};
  }
`;

const Container2 = styled.div`
  position: relative;
  width: auto;
  height: auto;
  left: -30px;
  top: -75px;
  z-index: 2;

 @media (max-width: 768px) {

    width: auto;
    height: auto;
    left: -100px;
     top: 50px;
  }

  @media (max-width: 425px) {

    width: auto;
    height: auto;
    left: -100px;
     top: 50px;
  }
  @media (max-width: 375px) {
   
  
    width: auto;
    height: auto;
    left: -100px;
     top: 50px;
  }

`;

const ShareIcon = styled.img`
  /* width: ${(props) => props.w || '40px'}; 
   height:${(props) => props.h || '40px'}; */
`;
const ShareIconC = styled.img`
  width: 25px;
  height: 25px;
  margin: 2px 0px 0px 5px;
`;

const ShareIconR = styled.img`
  width:  ${(props) => props.W || '20px'};
  height:${(props) => props.H || '20px'};
  margin: ${(props) => props.M || '1px 0px 0px 10px'};
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  margin: ${(props) => props.m || ''};
  background: ${(props) => props.bckicono || '  '};
  opacity: 0.5;
  border-radius: 20px;
`;

const BoxA = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  margin: ${(props) => props.m || ''};
  background: 'transparent';
  /* opacity: 0.5; */
  border-radius: 20px;
  cursor:pointer;
  @media (max-width: 768px) {
    background: 'red';
    margin: ${(props) => props.m375 || ''};
  }
@media (max-width: 425px) {
    background: 'red';
    margin: ${(props) => props.m375 || ''};
  }
  @media (max-width: 375px) {
    background: 'red';
    margin: ${(props) => props.m375 || ''};
  }
`;

const RedSocial = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  margin: ${(props) => props.m || ''};
  background: ${(props) => props.bckRedS || '  '};
  /* opacity: 0.5; */
  border-radius: 20px;
`;

const RedSocialHover = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  margin: ${(props) => props.m || ''};
  background: ${(props) => props.bckRedShover || '  '};
   opacity: 0.5; 
  border-radius: 20px;
`;

const ShareBtn = (props) => {
  const {
    seccion,
    message,
    shareCoru,
    bckicono,
    ShareImgC,
    bckRedS,
    bckRedShover,
    Micon,
    shortenedM,
    shortenedW,
    shortenedF,
  } = props;
  const [show, setShow] = React.useState(false);
  const [IconColor, setIconColor] = React.useState(false);
  return (
    <>
      <div
        onClick={() => {
          setShow(!show);
        }}
      >
        {!show ? (
         <Container className="iconShare">
             <ShareIcon src={ShareImgC} alt="ShareBtn" />
          </Container>
        ) : (
          <div className="BackgrounMobile">
            <Container   m375="0px 0px 0px 0px">
               <Box bckicono={bckicono} m="0px 0px 0px 0px">
                 <ShareIconC src={ShareW} alt="ShareBtn" />
               </Box>
            </Container>
            <Container2 >
          <BoxA className="iconSocial" m="0px 0px 0px 50px" m375="0px 0px 10px 100px">
               <RedSocial bckRedS={bckRedS}>
                  <ShareIconR src={iconMessenger} alt="F" M="0px 0px 3px 11px"/>
                </RedSocial>

                <RedSocialHover
                  onClick={() => shareCoru(seccion, 'Messenger', '', shortenedM)}
                  bckRedShover={bckRedShover}>
                  <ShareIconR src={iconMessenger} alt="F" M="9px 0px 0px 11px"/>
               </RedSocialHover>
          </BoxA>
          <BoxA className="iconSocial" m="0px 0px 30px 100px" m375="0px 0px 10px 100px">
              <RedSocial bckRedS={bckRedS}>
                  <ShareIconR M="0px 0px 3px 11px" src={iconWhatsapp} alt="F"/>
              </RedSocial>
               <RedSocialHover bckRedShover={bckRedShover}
               onClick={() => shareCoru(seccion, 'Whatsapp', message, shortenedW)} >
               <ShareIconR M="9px 0px 0px 11px"src={iconWhatsapp} alt="F" />
            </RedSocialHover>
          </BoxA>
         <BoxA className="iconSocial" m="0px 0px 0px 100px">
              <RedSocial bckRedS={bckRedS}>
                <ShareIconR src={iconFacebook} alt="F" />
              </RedSocial>
          <RedSocialHover
             onClick={() => shareCoru(seccion, 'Facebook', '', shortenedF)}
             bckRedShover={bckRedShover}>
              <ShareIconR M={Micon} src={iconFacebook} alt="F" />
             </RedSocialHover>
          </BoxA>
            </Container2>
          </div>
        )}
      </div>
    </>
  );
};

export default ShareBtn;
