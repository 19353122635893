import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  ContentButton,
  Container,
  LinksFooter,
  SpanTextR,
  SpanTextL,
  Image,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import '../../assets/styles/TextCustom.css';
import {
  SEND_OTP,
  CREATE_CLICK,
  DYNAMIC_GET,
} from '../../mutations';
import { GET_CONFIG_OTP, GET_COUNTRY_CODES, VERIFY_ACCOUNT } from '../../queries';
import Lada from '../../assets/img/anacoach/lada.png';
// Modal
import GeneralLoader from '../../components/common/GeneralLoader';
import '../../assets/styles/perfil/perfiles.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));
const Phone = () => {
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const [lleno, setlleno] = useState(false);
  const { data: dataOtp } = useQuery(GET_CONFIG_OTP);
  const { data: dataLadas } = useQuery(GET_COUNTRY_CODES);
  const history = useHistory();
  const [form, setForm] = useState({
    numPhone: '',
    lada: 'México lada +(52)',
    textlada: 'México lada +(52)',
  });
  const [btndisabled, Setbtndisabled] = useState(true);
  const [valueError, SetvalueError] = useState(-1);
  const [error] = useState({
    numPhone: '',
    numPhoneValid: -1,
  });
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [sendOtp] = useMutation(SEND_OTP, {
    onCompleted({ sendOtp }) {
      sessionStorage.setItem('clickEmpezarAhoraStep2', true);
      sessionStorage.setItem('lada', sessionStorage.getItem('ladauser'));
      const tagManagerArgs = {
        dataLayer: {
          event: 'clickIniciarSesionStep2',
        },
      };
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickIniciarSesionStep2',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      };
      createClickFront({ variables: createClickInput });

      TagManager.dataLayer(tagManagerArgs);
      history.push('/user-validation-otp/');
      setMostrarLoad(false);
    },
  });

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const handleChange = (e) => {
    e.persist();
    if (e.target.name === 'numPhone') {
      setForm({
        ...form,
        numPhone: e.target.value.replace(/[^0-9]/g, ''),
      });
      if (
        (e.target.value.length < 11 && e.target.value.length !== 10)
        || (e.target.name && !/^[0-9]+$/i.test(e.target.value))
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
        });
        error.numPhone = 'Por favor verifica tu número telefónico';
        error.numPhoneValid = 1;
        SetvalueError(true);
        Setbtndisabled(true);
      } else if (e.target.value.length < 11 && e.target.value.length === 10) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
        });
        sessionStorage.setItem(
          'cellPhone',
          e.target.value.replace(/[^0-9]/g, ''),
        );
        error.numPhone = '';
        error.numPhoneValid = 0;
        Setbtndisabled(false);
        SetvalueError(false);
      }
    } else {
      setForm({
        ...form,
        lada: e.target.value,
        textlada: e.target.value,
      });
      const indexLada = e.target.value.indexOf('+(');
      const ladaselect = e.target.value.substring(indexLada);
      sessionStorage.setItem('ladauser', ladaselect);
    }
  };
  const onBlur = () => {
    let result;

    if (valueError) {
      result = 'ingresonumeroOTPerror';
      const tagManagerArgs = {
        dataLayer: {
          event: 'ingresonumeroOTPerror',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    } else {
      result = 'ingresonumeroOTP';
      const tagManagerArgs = {
        dataLayer: {
          event: 'ingresonumeroOTP',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: result,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    createClickFront({ variables: createClickInput });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [verifyAccount] = useLazyQuery(VERIFY_ACCOUNT, {
    fetchPolicy: 'no-cache',
    onCompleted({ verifyAccount }) {
      const { response } = verifyAccount;
      const data = JSON.parse(response);

      if (JSON.parse(data).length > 0) {
        handleOpen();
      } else {
        const cel = sessionStorage.getItem('cellPhone');
        const medioOtp = 'WA'; // "SMS";
        sessionStorage.setItem('medioOtp', medioOtp);
        const caracteres = '0123456789';
        let cadenaResponse = '';
        const charactersLength = caracteres.length;
        for (let i = 0; i < 4; i++) {
          cadenaResponse += caracteres.charAt(
            Math.floor(Math.random() * charactersLength),
          );
        }
        sessionStorage.setItem('typeUser', 'cliente');
        localStorage.setItem('typeUser', 'cliente');
        sessionStorage.setItem('phoneOTP', cel);
        const phoneInput = {
          input: {
            table: 'CatContactMedium',
            columns: ['CellPhone'],
            conditions: [
              {
                valone: 'CellPhone',
                condition: '=',
                valtwo: String(cel),
              },
            ],
          },
        };
        validExitPhone({ variables: phoneInput });
        // envio simultaneo
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [validExitPhone] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      const { response } = dynamicGet;
      if (JSON.parse(response).length > 0) {
        handleOpen();
      } else {
        const caracteres = '0123456789';
        let cadenaResponse = '';
        const charactersLength = caracteres.length;
        for (let i = 0; i < 4; i++) {
          cadenaResponse += caracteres.charAt(
            Math.floor(Math.random() * charactersLength),
          );
        }
        const inputOtp = {
          input: {
            telefono: sessionStorage.getItem('phoneOTP'),
            medio: '', // sessionStorage.getItem("medioOtp"),
            tipo: 'validacion',
            lastOtp: cadenaResponse,
          },
        };
        if (dataOtp) {
          // config otp admin
          const arrayConfig = dataOtp.getConfigOTP.response;
          const domain = window.location.hostname;
          for (let i = 0; i < arrayConfig.length; i++) {
            if (domain === 'coru.com') {
              if (
                arrayConfig[i].step === 'user validation'
                && arrayConfig[i].ambiente === 'produccion'
              ) {
                if (arrayConfig[i].sms_on_off === 'true') {
                  inputOtp.input.medio = 'SMS';
                  setMostrarLoad(true);
                  sendOtp({ variables: inputOtp });
                }
                if (arrayConfig[i].whatsapp_on_off === 'true') {
                  inputOtp.input.medio = 'WA';
                  setMostrarLoad(true);
                  sendOtp({ variables: inputOtp });
                }
              }
            } else if (domain === 'develop.coru.mx') {
              if (
                arrayConfig[i].step === 'user validation dev'
                && arrayConfig[i].ambiente === 'Dev'
              ) {
                if (arrayConfig[i].sms_on_off === 'true') {
                  inputOtp.input.medio = 'SMS';
                  setMostrarLoad(true);
                  sendOtp({ variables: inputOtp });
                }
                if (arrayConfig[i].whatsapp_on_off === 'true') {
                  inputOtp.input.medio = 'WA';
                  setMostrarLoad(true);
                  sendOtp({ variables: inputOtp });
                }
              }
            } else if (
              arrayConfig[i].step === 'user validation'
              && arrayConfig[i].ambiente === 'produccion'
            ) {
              if (arrayConfig[i].sms_on_off === 'true') {
                inputOtp.input.medio = 'SMS';
                setMostrarLoad(true);
                sendOtp({ variables: inputOtp });
              }
              if (arrayConfig[i].whatsapp_on_off === 'true') {
                inputOtp.input.medio = 'WA';
                setMostrarLoad(true);
                sendOtp({ variables: inputOtp });
              }
            }
          }
        }
      }
    },
  });

  const guardar = (e) => {
    e.preventDefault();
    if (valueError) {
      error.numPhone = 'Por favor verifica tu número telefónico';
    } else {
      const cel = sessionStorage.getItem('cellPhone');
      const validateUser = cel * 1;
      if (validateUser === 0) {
        sessionStorage.setItem('typeUser', 'test');
        localStorage.setItem('typeUser', 'test');
        history.push('/user-validation-otp/');
      } else {
        // Validamos que no existe en Tabla Atlante
        verifyAccount({variables: {value: cel}})
      }
    }
  };
  const initSession = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickIniciarSesionStep2', true);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickIniciarSesionStep2',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    history.push('/login');
  };

  const [Ladas] = useState([
    {
      value: '',
      label: 'México',
      lada: '',
    },
    {
      value: 'Estados Unidos lada +(1)',
      label: 'Estados Unidos',
      lada: '1',
    },
    {
      value: '',
      label: 'Brasil',
      lada: '',
    },
    {
      value: '',
      label: 'Colombia',
      lada: '',
    },
    {
      value: '',
      label: 'Chile',
      lada: '',
    },
  ]);

  const LlenarCampo = () => {
    if (dataLadas) {
      if (!lleno) {
        dataLadas.getCountryCodes.forEach((element) => {
          if (
            element.Country !== 'México'
            && element.Country !== 'Estados Unidos'
            && element.Country !== 'Brasil'
            && element.Country !== 'Colombia'
            && element.Country !== 'Chile'
          ) {
            Ladas.push({
              value: `${element.Country} lada +(${element.CountryCode})`,
              label: element.Country,
              lada: element.CountryCode,
            });
          } else {
            let i = 0;
            switch (element.Country) {
              case 'México':
                i = 0;
                break;
              case 'Estados Unidos':
                i = 1;
                break;
              case 'Brasil':
                i = 2;
                break;
              case 'Colombia':
                i = 3;
                break;
              case 'Chile':
                i = 4;
                break;
              default:
                break;
            }
            Ladas[
              i
            ].value = `${element.Country} lada +(${element.CountryCode})`;
            Ladas[i].label = element.Country;
            Ladas[i].lada = element.CountryCode;
          }
        });
        setlleno(true);
      }
    }
  };

  const handleOpenFeed = () => {
    history.push('/login');
  };

  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleOpenFeed}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleOpenFeed}
        >
          X
        </ParagrapTwo>
        <SubtitleOne
          mt="3%"
          className="font-weight-bold"
          sizeMobile="20px"
          size="20px"
        ></SubtitleOne>
        <ParagrapTwo className="mx-auto" mb="0px">
          Este número ya se encuentra registrado,{' '}
        </ParagrapTwo>
        <ParagrapTwo mtWeb="0px" mt="0px" className="mx-auto">
          ve a iniciar sesión.
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleOpenFeed}
        >
          Iniciar sesión
        </Button>
      </div>
    </Modal>
  );

  // OpenLada
  const [openLada, setOpenLada] = useState(false);
  const handleCloseLada = () => {
    setOpenLada(false);
  };
  const handleOpenLada = () => {
    setOpenLada(true);
  };

  LlenarCampo();
  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="37%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="20px"
              sizeMobile="20px"
            >
              Crea tu cuenta
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="10%"
              fontWeight="normal"
            >
              Ingresa tu número telefónico, recibirás un código de validación en
              tu Whatsapp.
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
              onSubmit={guardar}
            >
              <ParagrapTwo
                cursor="pointer!important"
                fontSize="15px"
                lh="20px"
                texttalign="left"
                textalignMobile="left"
                className="mx-auto intermediateWidth roboto"
                fontWeight="normal"
                mb="5%!important"
                onClick={handleOpenLada}
              >
                {form.lada} <Image src={Lada} width="5%" marginLeft="2%" />
              </ParagrapTwo>
              <Select
                id="lada"
                name="lada"
                open={openLada}
                onClose={handleCloseLada}
                onOpen={handleOpenLada}
                value={form.lada}
                onChange={handleChange}
                className={!openLada ? 'Novisible' : ''}
              >
                {Ladas.map((option) => (
                  <MenuItem key={option.lada} value={option.value}>
                    <SpanTextL className="roboto">
                      {option.label} lada{' '}
                    </SpanTextL>
                    <SpanTextR> +({option.lada})</SpanTextR>
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id="numPhone"
                name="numPhone"
                autoFocus
                error={valueError}
                required
                label="Tu número celular a 10 dígitos"
                type="tel"
                value={form.numPhone}
                onChange={handleChange}
                onBlur={onBlur}
                helperText={error.numPhone}
                inputProps={{
                  type: 'tel',
                  maxLength: 10,
                  className:
                    error.numPhoneValid === 1
                      ? 'invalidInput intermediateWidth roboto'
                      : error.numPhoneValid === 0
                        ? 'validInput intermediateWidth roboto'
                        : 'intermediateWidth roboto',
                }}
                className="registro"
              />
              <ContentButton mt="2%" mtMobile="0%">
                <ParagrapTwo
                  color="#A6A6A6"
                  mt="5%"
                  mb="10%"
                  mbweb="0px"
                  lh="160%"
                  textalignMobile="justify"
                  texttalign="justify"
                  fontSize="10px"
                  fontSizeWeb="10px"
                  className="roboto"
                >
                  Si das clic en el botón "Enviar código", aceptas nuestros
                  Términos, condiciones y Aviso de privacidad. También, autoriza
                  a Coru (Compara Guru México S.A. de C.V.) a transferir mis
                  datos a las instituciones financieras y entidades que se
                  indican en el Aviso de Privacidad, con la finalidad de aplicar
                  a sus propios procesos de solicitud de cotización de productos
                  financieros, de seguros y de inmuebles, para que puedan
                  ofrecerme el producto que consideren adecuado a mis
                  necesidades. También autorizo que me envíen comunicación de
                  Coru y sus filiales por cualquier medio, incluyendo Whatsapp.
                </ParagrapTwo>
                <Button
                  disabled={btndisabled}
                  href="/user-validation-otp/"
                  className="d-block mt-md-4 roboto"
                  mb="1.2rem"
                  mt="1.2rem"
                >
                  Enviar código
                </Button>
                <LinksFooter
                  mt="3%"
                  // mb="10%"
                  display="block"
                  className="link roboto"
                  color="#252525"
                  fontSize="14px"
                  href="/login"
                  onClick={initSession}
                  mb="30px"
                >
                  ¿Ya tienes una cuenta?{' '}
                </LinksFooter>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/user-validation" />
      <ModalMessage />
      {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
    </Fragment>
  );
};

export default Phone;