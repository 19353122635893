/* eslint-disable max-len */
import React, { useState, Fragment, useEffect } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GET_TRAINING_COLOR,
  GET_TRAINING_PREVIEW,
  GET_TRAINING_PROGRESS,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainingContainAna,
  TrainigTam,
  TrainingLine,
  CoruCoach,
} from '../../assets/styles/Training.styled';
import Header from '../../components/common/Header';
import ana from '../../assets/img/anacoach/CoachImage.png';
import logoC from '../../assets/img/anacoach/logoCoach.png';
import RoutinesOrderWoGoals from './RoutinesOrderWoGoals';
import WaitList from '../../components/Training/WaitList';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import WOlayout from '../../components/layouts/WOlayout';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';
import BarraInfoWOdeg from '../../components/Header/BarraInfoWOdeg';

const items2 = ['¿Cómo cumplir tus metas financieras?', ''];

const WOGoals = () => {
  const anchop = window.screen.width;
  const { actions } = GeneralHooks();
  // copia y pega estos states para crear un nuevo entrenamiento
  sessionStorage.setItem('idTraining', 28);
  const [saludo, setSaludo] = useState('No');
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(28);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [hasSuggestions] = useState(true);
  const [showGoals, setShowGoals] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [WOBigImage, setWOBigImage] = useState('');
  const [lastRoutineCheck, setLastRoutineCheck] = useState(false);

  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  // MUTATIONS AND QUERIES
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getTrainingPreview] = useLazyQuery(GET_TRAINING_PREVIEW, {
    onCompleted: ({ getTrainingPreview }) => {
      setWOBigImage(getTrainingPreview.response.training.UrlBigImage);
    },
  });

  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });
  // Obtener porcentaje de avance
  const [getTrainingProgress] = useLazyQuery(GET_TRAINING_PROGRESS, {
    onCompleted({ getTrainingProgress }) {
      const percent = getTrainingProgress.achievment;
      setPercentage(`${percent.toString()}%`);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveStatusTraining }) {
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    sessionStorage.setItem('currentOrderRoutine', orderRutine);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          const nameSession = arraySessionsRutines[questionRoutine.DatUserDetailStorage];
          sessionStorage.setItem(nameSession, item.value);

          switch (nameSession) {
            case 'Email1':
              localStorage.setItem('Email1', item.value);
              break;
            case 'CellPhone':
              localStorage.setItem('CellPhone', item.value);
              break;
            case 'idCont':
              localStorage.setItem('idCont', item.value);
              break;
            case 'idUser':
              localStorage.setItem('idUser', item.value);
              break;
            default:
              break;
          }

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  // useeffect genericos para los entrenamientos
  // Valid WaitList
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: String(currentTraining) } });
  }, []);

  // obtener las utms
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'wo-goals');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  const getProgress = async () => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      await getTrainingProgress({
        variables: { idUser: parseInt(idUser), idTraining: currentTraining },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  };

  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    getProgress();
  }, []);

  // Para obtener la información del WO
  useEffect(() => {
    getTrainingPreview({
      variables: {
        Id: localStorage.getItem('idTraining') || '28',
      },
    });
  }, []);

  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser, idCont, hostName, pageName, fromXCoach, idAdviser,
    } = getQueryVariableHashtagDeeplink('wo-goals');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);

  useEffect(() => {
    getTrainingColor({ variables: { idTraining: String(currentTraining) } });
  }, []);

  useEffect(() => {
    if (orderRutine === 8 && !lastRoutineCheck) {
      const idUser = sessionStorage.getItem('idUser');
      const idCont = sessionStorage.getItem('idCont');

      for (let i = 1; i <= 8; i++) {
        const inputProgress = {
          input: {
            idRoutine: '',
            orderRoutine: String(i),
            idTraining: String(currentTraining),
            idUser: String(idUser),
            idCont: String(idCont),
            isMaster: '1',
          },
        };

        if (idUser != null) {
          saveStatusTraining({ variables: inputProgress });
        }
      }
      setLastRoutineCheck(true);
    }
  }, [orderRutine]);

  const objetivesArray = useState([
    {
      text: 'Identificar y priorizar tus metas por cumplir, así sabrás por cual iniciar.',
      color: '#91D770',
    },
    {
      text: 'Definir el presupuesto que necesitarás y con el que ya cuentas para lograr tus metas.',
      color: '#91D770',
    },
    {
      text: 'Acceder a una herramienta que te ayudará con la administración de tus metas, ¡recuerda utilizarla para dar seguimiento!',
      color: '#91D770',
    },
  ]);

  const featuresArray = useState([
    {
      text: 'Crear tus metas.',
      color: '#91D770',
    },
    {
      text: 'Tomas de desición.',
      color: '#91D770',
    },
    {
      text: 'Cumplir objetivos a corto, mediano y largo plazo.',
      color: '#91D770',
    },
  ]);
  const imgWo = WOBigImage;
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: 'Planifica, organiza y alcánzalas',
    time: '10 minutos',
    imgWo: { imgWo },
  };
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '45px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '45px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);
  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>
          Establecimiento de Plazos para el Logro de mis Metas Financieras
        </title>
        <link rel="canonical" href="https://coru.com/wo/goals" />
        <meta
          name="keywords"
          content="establecimiento de plazos para el logro de mis metas financieras"
        />
        <meta
          name="description"
          content="Define reglas financieras realistas y alcanzables. Te dejamos unos consejos que seguro funcionan para todos. ¡Entrena en Coru!"
        />
      </Helmet>
      <TrainingMain>
        {WOStatus ? (
          <>
            <TrainingAnimateFunnel
              bcolor={colorTraining}// mandar aquí el degradado. Por ejemplo: "linear-gradient(180deg,#E08C8B 0%,#FFFFFF 130.92%)"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
             /*  borderRadius="16px" */ // Poner border cuando sea opcion degradado
            >
              <TrainigTam>
                <Header
                  background={colorTraining}
                  pathname={window.location.pathname}
                />
              </TrainigTam>
              <BarraInfoWO propied={propied} />
              {/* <BarraInfoWOdeg propied={propied} colorHeader="#e49998" /> */}
              {/* Usar cuando sea Barra de información con color degradado y mandar el color que solo irá en el header */}
              {scroll1 > 0 ? <BarraScroll /> : null}
              <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
              <ModalDataPersonalInitWO isData={isData} setData={setisData} />
            </TrainingAnimateFunnel>
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DataPersonalInitWO
                  Date={Date}
                  hora={hora}
                  orderRutine={orderRutine}
                  setOrderRutine={setOrderRutine}
                  currentTraining={currentTraining}
                  createJsonInput={createJsonInput}
                  questions={questions}
                  setQuestions={setQuestions}
                />
                {!showGoals ? (
                  <TrainingSectionGray
                    wiw="100%"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      <div className="marginComentLgSuccess1">
                        <Starttraining
                          title="¡Hola! Soy Ana, tu coach financiero."
                          text="Juntos crearemos una estrategia para cumplir tus sueños y metas. "
                        />

                        <Fragment>
                          <Features
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="En este entrenamiento podrás:"
                            Arraytext={objetivesArray}
                          />
                        </Fragment>

                        <Starttraining
                          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                          title="El objetivo es:"
                          text="La mejor forma de cumplir tus sueños y objetivos es diseñar una estrategia. En este entrenamiento te ayudare a crearla."
                        />

                        <Fragment>
                          <Features
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="¿En qué ayuda este entrenamiento para mí?"
                            Arraytext={featuresArray}
                          />
                        </Fragment>
                      </div>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                ) : null}

                {saludo === 'Si' ? (
                  <TrainingContainAna back="#FAFAFA">
                    <Images
                      src={ana}
                      top="63px"
                      topweb="5%"
                      left="0"
                      leftw="-40px"
                      position="initial"
                      positionw="absolute"
                      border="2px solid #F5CA5D"
                      bs="border-box"
                      padd="5px"
                      br="45px"
                      className="img-fluid"
                    ></Images>

                    <CoruCoach bcolor="#F5CA5D">
                      <Images
                        src={logoC}
                        top="12px"
                        topweb="12px"
                        left="-8px"
                        leftw="-8px"
                        width="18px"
                        position="absolute"
                        className="img-fluid"
                      ></Images>
                    </CoruCoach>
                  </TrainingContainAna>
                ) : null}
                {saludo === 'Si' ? (
                  <TrainingDivSection
                    bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                    mt="44px"
                    mtw="225px"
                    br="4px"
                    padding="21px 20px"
                    wi="335px"
                    wiw="670px"
                    he="auto"
                    hew="auto"
                    mb="66px"
                  >
                    <div className="triangulo-equilatero-bottom"></div>
                    <div className="closeDiv" onClick={() => setSaludo('No')}>
                      ×
                    </div>
                    <TrainingSubtitles
                      size="20px"
                      fw="600"
                      align="left"
                      alignweb="left"
                      className="cabin"
                    >
                      Hola soy Ana, tu coach financiero,
                    </TrainingSubtitles>
                    <TrainingText
                      size="16px"
                      className="roboto"
                      align="left"
                      alignweb="left"
                      ls="-0.2px"
                    >
                      Te ayudaré a buscar alternativas para hacer un presupuesto
                      a tu medida.
                    </TrainingText>
                  </TrainingDivSection>
                ) : null}

                <TrainingSectionGray
                  wiw="670px"
                  mw="auto"
                  back="#FAFAFA"
                  mtop="0"
                >
                  <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                    {hasSuggestions ? (
                      <RoutinesOrderWoGoals
                        setSaludo={setSaludo}
                        Date={stateDate}
                        hora={hora}
                        orderRutine={orderRutine}
                        setOrderRutine={setOrderRutine}
                        currentTraining={currentTraining}
                        createJsonInput={createJsonInput}
                        questions={questions}
                        setQuestions={setQuestions}
                        setShowGoals={setShowGoals}
                        updateProgress={getProgress}
                      />
                    ) : null}
                  </TrainingDivSection2>
                </TrainingSectionGray>
                <br />
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
          </>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
};

export default WOGoals;
