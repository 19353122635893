import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_CAR_INSURANCE_DATA_QUESTION } from '../../../../queries';
import CustomStepLayout from '../../layouts/CustomStepLayout';
import './styles/CarInsurance.css';

const CarInsurance = ({
  customColor,
  stepData,
  type,
  nextHandler,
  handleNextParam,
  appearAnimation,
  disappearAnimation,
  isActive,
  idRoutine,
  canGoBack,
  backText,
  backHandler,
  backHandlerOption,
}) => {
  const stepLayoutProps = {
    customColor,
    type,
    isActive,
    appearAnimation,
    disappearAnimation,
    backText,
    backHandlerOption,
    backHandler,
    canGoBack,
  };

  const [step, setStep] = useState(1);
  const [questionOrder] = useState(1);
  const [questionData, setQuestionData] = useState({});
  const [questionIndications] = useState({
    1: {
      description: 'Elige un año',
      option: 'Año',
    },
    2: {
      description: 'Elige una marca',
      option: 'Marca',
    },
    3: {
      description: 'Elige un modelo',
      option: 'Modelo',
    },
    4: {
      description: 'Elige una transmisión',
      option: 'Transmisión',
    },
    5: {
      description: 'Elige un tipo',
      option: 'Tipo',
    },
  });

  const [questionOptionType] = useState({
    1: 'year',
    2: 'maker',
    3: 'model',
    4: 'transmition',
    5: 'trim',
  });

  const [questionOptions, setQuestionOptions] = useState([]);

  const [dataStep, setDataStep] = useState({
    year: undefined,
    maker: undefined,
    model: undefined,
    transmition: undefined,
  });

  const setData = (dta) => {
    const tempData = JSON.parse(dta.getQuestionInfo.response)[0];

    setQuestionData({
      ...questionData,
      title: tempData.QuestionName || '',
    });

    const tempOptions = dta.getCarsData.response;

    setQuestionOptions(tempOptions);
  };

  const getOptions = () => (
    <>
      <option value=""> {questionIndications[step].option} </option>
      {questionOptions.map((option) => (
        <option value={option[questionOptionType[step]]}>
          {' '}
          {option[questionOptionType[step]]}{' '}
        </option>
      ))}
    </>
  );

  const handleOptionSelected = (e) => {
    const option = questionOptionType[step];
    setDataStep({
      ...dataStep,
      [option]: step === 1 ? parseInt(e.target.value) : e.target.value,
    });

    setStep(step + 1);
  };

  const { loading, error, data } = useQuery(GET_CAR_INSURANCE_DATA_QUESTION, {
    variables: {
      data: {
        step,
        year: dataStep.year,
        maker: dataStep.maker,
        model: dataStep.model,
        transmition: dataStep.transmition,
      },
      questionOrder,
      idRoutine,
    },
    onCompleted: (data) => setData(data),
  });

  if (loading) return 'Loading...';
  if (error) return `Error${error}`;

  console.log('data >> ', data);

  return (
    <CustomStepLayout
      {...stepLayoutProps}
      stepClass="step-static-car-insurance"
    >
      <div className="text-content">
        <div
          className={`title ${
            !questionData.title || questionData.title.trim() === ''
              ? 'no-margin'
              : ''
          }`}
        >
          {questionData.title}
        </div>

        <div className="description">
          Si no encuentras tu vehículo, es taxi, uber, de carga o de salvamento
          da click <a href="#">aquí.</a>
        </div>
      </div>

      <div className="text-content">
        <div className="description">
          {questionIndications[step].description}
        </div>
      </div>

      <form>
        <div className="input-row">
          <select name="" id="" onChange={handleOptionSelected}>
            {getOptions()}
          </select>
        </div>
      </form>
    </CustomStepLayout>
  );
};

export default CarInsurance;
