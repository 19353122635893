import React from 'react';

import WOFooter from '../shared/WOFooter';
import WOHeader from '../shared/WOHeader';

import './styles/woLayout.css';
import clockImg from '../../../assets/img/anacoach/clockTraining.png';
// import temp from '../../../assets/img/perfil/secc_perfil_03.png';

const WOLayout = ({
  children, pageClass, headerType, woData, headerImage,
}) => {
  const LayoutHeader = () => {
    if (woData && woData.layoutHeader && woData.layoutHeader.show) {
      return (
        <div
          className={`wo-layout-header ${
            woData.layoutHeader.hidden ? 'hidden-component' : ''
          }`}
          style={{ backgroundColor: woData.general.customColor }}
        >
          <div className="header-content">
            <div className="text-c">
              <div className="title">
                {woData.layoutHeader.title || 'No hay titulo'}
              </div>

              {descriptionText()}
            </div>

            <div className="img-c">
              <img src={headerImage} />
            </div>
          </div>
        </div>
      );
    }

    return <br />;
  };

  const descriptionText = () => {
    /* This function was made for the next purpose :

            Initially, the WO must show the time for the wo ('10 minutos', 'En 3 sencillos pasos', etc...)
            If in the progress of the WO other text must be shown, then just add text to the property 'subtitle'
            and this other text is going to be shown without the time icon. Can have other style or more data.

        */

    if (woData.layoutHeader.subtitle) {
      return (
        <div className="time-description">{woData.layoutHeader.subtitle}</div>
      );
    }

    if (woData.layoutHeader.timeText) {
      return (
        <div className="time-time-text">
          <img src={clockImg} />
          <p>{woData.layoutHeader.timeText}</p>
        </div>
      );
    }

    return '';
  };

  return (
    <>
      <WOHeader
        backgroundColor={woData.general.customColor || '#fafafa'}
        headerType={headerType}
      />

      <div className={`wo-layout-default ${pageClass}`}>
        {LayoutHeader()}

        <div className="layout-container">{children}</div>
      </div>

      <WOFooter />
    </>
  );
};

export default WOLayout;
