import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import '../../assets/styles/Modal.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import { CardsBtn } from '../../assets/styles/Training.styled';
import { ContentHomeSection } from '../common/Home.styled';
import { ParagrapTwo, Image } from '../common/common.styled';
import logros from '../../assets/img/anacoach/logros.svg';
import ClickButton from '../../Helpers/HookHelpers';

const ModalPirata = ({ show, premio }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '400px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '550px',
    },
  }));

  const useValues = () => ({
    title: `¡Felicidades, ${sessionStorage.getItem('FirstName')}!`,
    subTitle: premio
      ? '¡Ya diste el primer paso hacia una vida libre de estrés financiero! En breve enviaremos tu recompensa al correo que registraste:'
      : '¡Qué emoción! Cada decisión que tomas te acerca a cumplir tus planes. Mantén el ritmo y conoce otros entrenamientos.',
    email: `${sessionStorage.getItem('Email1')}`,
    question: '¿Quieres seguir trabajando en tus finanzas?',
  });

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      height: '650px',
      padding: '0px 32px 24px',
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const { actions } = ClickButton();
  const goToTrainings = (event) => {
    const click = `DescubreMasEntrenamientos_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    event.preventDefault();
    history.push('/the-training-feed/');
  };
  const BtnPerfil = (event) => {
    const click = `IrAMiPerfil_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    event.preventDefault();
  };
  let history = useHistory();
  const classes = useStyles();
  const values = useValues();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <>
      <Modal
        open={open}
        className={showHideClassName}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              onClick={handleClose}
            >
              X
            </ParagrapTwo>
            <Image
              src={logros}
              height="238px"
              marginLeftM="0px"
              marginTopM="-40px"
              heightMobile="200px"
              mwidth="100%"
              width=" "
              margin="auto"
              top="110px"
              marginTop="-60px"
              left="0%"
              mleft="0%"
              mtop="75px"
            />
            <div style={{ marginTop: '-60px' }}>
              <ParagrapTwo
                texttalign="left"
                textalignMobile="left"
                className="mx-auto roboto"
              >
                <b>{values.title}</b>
              </ParagrapTwo>
              <ParagrapTwo
                texttalign="left"
                textalignMobile="left"
                className="mx-auto roboto"
              >
                {values.subTitle}
              </ParagrapTwo>
            </div>
            {premio ? (
              <div>
                <ParagrapTwo
                  texttalign="left"
                  textalignMobile="left"
                  className="mx-auto roboto"
                >
                  <b>{values.email}</b>
                </ParagrapTwo>
                <br />
                <ParagrapTwo
                  texttalign="left"
                  textalignMobile="left"
                  className="mx-auto roboto"
                >
                  {values.question}
                </ParagrapTwo>
              </div>
            ) : null}
            <br />
          </ContentHomeSection>
          <CardsBtn
            min_w="100%"
            min_web="100%"
            onClick={(e) => goToTrainings(e)}
          >
            <b>Descubre más entrenamientos</b>
          </CardsBtn>
          <br />
          <br />
          <Link to="/profile">
            <CardsBtn
              onClick={(e) => BtnPerfil(e)}
              back="#FFFFFF"
              color="#F64282"
              border="2px solid #F64282"
              min_w="100%"
              min_web="100%"
            >
              <b>Ir a mi perfíl</b>
            </CardsBtn>
          </Link>
          <br />
          <br />
          {premio ? (
            <ParagrapTwo
              texttalign="left"
              textalignMobile="left"
              className="mx-auto roboto"
            >
              *Cupón canjeable en restaurantes, tiendas en línea o cines
              participantes.
            </ParagrapTwo>
          ) : (
            <ParagrapTwo
              texttalign="left"
              textalignMobile="left"
              className="mx-auto roboto"
            >
              Si tienes dudas,{' '}
              <b>Ana, nuestra coach en finanzas, te guiará en todo momento</b> y
              te mantendrá al tanto de los entrenamientos financieros que mejor
              se adaptan a tu estilo de vida.
            </ParagrapTwo>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalPirata;
