/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-dupe-keys */
import React, {Fragment} from "react";
// import Carousel from "react-elastic-carousel";
import {
  DivFlex,
  Shapes,
  Container,
} from "./../Assets/styles/StyledGeneric.styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import aig from "./../Assets/imgClientes/aig.svg";
import american from "./../Assets/imgClientes/americanexpress.svg";
import ana from "./../Assets/imgClientes/ana.svg";
import bancoazteca from "./../Assets/imgClientes/bancoazteca.svg";
import banorte from "./../Assets/imgClientes/banorte.svg";
import bbva from "./../Assets/imgClientes/bbva.svg";
import briq from "./../Assets/imgClientes/briq.svg";
import chubb from "./../Assets/imgClientes/chubb.svg";
import citibanamex from "./../Assets/imgClientes/citibanamex.svg";
import creditea from "./../Assets/imgClientes/creditea.svg";
// import copel from "./../Assets/imgClientes/Coppel.svg";
import flink from "./../Assets/imgClientes/flink.svg";
import gnp from "./../Assets/imgClientes/gnp.svg";
import hdi from "./../Assets/imgClientes/hdi.svg";

import gmb from "./../Assets/imgClientes/gmb.svg";
import hsbc from "./../Assets/imgClientes/hsbc.svg";
import invex from "./../Assets/imgClientes/invex.svg";
import kavak from "./../Assets/imgClientes/kavak.svg";
import klar from "./../Assets/imgClientes/klar.svg";
import kueski from "./../Assets/imgClientes/kueski.svg";
import liverpool from "./../Assets/imgClientes/liverpool.svg";
import mapfre from "./../Assets/imgClientes/mapfre.svg";
import nubank from "./../Assets/imgClientes/NewNu.svg";
import palacio from "./../Assets/imgClientes/palacio.svg";
import qualitas from "./../Assets/imgClientes/qualitas.svg";
import rappipay from "./../Assets/imgClientes/rappiCard.svg";
import resuelve from "./../Assets/imgClientes/ResuelveTuDeuda.svg";
import scotiabank from "./../Assets/imgClientes/scotiabank.svg";

import arkangeles from "./../Assets/imgClientes/arkangeles.svg";
import credijusto from "./../Assets/imgClientes/credijustoSinFondo.svg";
import credilikeme from "./../Assets/imgClientes/credilikeme.svg";
import creditas from "./../Assets/imgClientes/creditas.svg";
import creditofamiliar from "./../Assets/imgClientes/creditofamiliar.svg";
import curadeuda from "./../Assets/imgClientes/curadeuda.svg";
import dinn from "./../Assets/imgClientes/dinn.png";
import escampa from "./../Assets/imgClientes/escampa.png";
import falabella from "./../Assets/imgClientes/falabella.svg";
import inverspot from "./../Assets/imgClientes/inverspot.svg";
import mangolife from "./../Assets/imgClientes/mangolife.svg";
import quetarjeta from "./../Assets/imgClientes/quetarjeta.svg";
import story from "./../Assets/imgClientes/story.svg";

import atlas from "./../Assets/imgClientes/atlas.svg";
import fundary from "./../Assets/imgClientes/fundary.svg";
import latasa from "./../Assets/imgClientes/latasa.svg";
import moneyman from "./../Assets/imgClientes/moneyman.svg";
import monific from "./../Assets/imgClientes/monific.svg";
import networth from "./../Assets/imgClientes/networth.svg";
import prestadero from "./../Assets/imgClientes/prestadero.svg";
import prestanomico from "./../Assets/imgClientes/prestanomico.svg";
import rebajatuscuentas from "./../Assets/imgClientes/rebajatuscuentas.svg";
import superr from "./../Assets/imgClientes/super.svg";
import tala from "./../Assets/imgClientes/tala.svg";
import vexi from "./../Assets/imgClientes/vexi.svg";
import yotepresto from "./../Assets/imgClientes/yotepresto2.png";
import zendala from "./../Assets/imgClientes/zendala.svg";
import mazper from "./../Assets/imgClientes/mozper.svg";

import montePiedad from "./../Assets/imgClientes/montePiedad.png";
import oyster from "./../Assets/imgClientes/oyster.svg";
import digitt from "./../Assets/imgClientes/digitt.png";
import uniclick from "./../Assets/imgClientes/uniclick.svg";
import savenest from "./../Assets/imgClientes/savenest.svg";
import baubap from "./../Assets/imgClientes/baubap.png";
import doopla from "./../Assets/imgClientes/doopla.svg";
import clara from "./../Assets/imgClientes/Clara.png";

import {
  ContentGeneric,
  HeadingTwo,
  Description,
  Container1,
} from "../Assets/styles/StyledGeneric.styled";
import "../Assets/styles/styles.css";

const Carrousel = ({tituloSecundario}) => {
  // eslint-disable-next-line
  const [widthBrowser, setwidthBrowser] = React.useState(window.outerWidth);
  React.useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);

  return (
    <ContentGeneric
      mb="32px"
      width="712px"
      height=""
      w375="395px"
      widthM="100%"
      id="ContenCarrousel">
      <HeadingTwo
        h700="auto"
        ml700="3.5%"
        h375="20px"
        w375="280px"
         hm="67px"
         w395 ="280px"
        m="0px 12px 0px 0px"
        color="#AC2E5B">
        {tituloSecundario}
      </HeadingTwo>
      <Description
        h375="84px"
        w375="320px"
       mD700="12px auto 2px "
        m="12px 0px 10px"
        fz="18px"
        className="roboto"
        color="#AC2E5B">
        Hoy con la confianza de +250 empresas aliadas estamos ayudando a +2.5
        millones de personas a mejorar su vida financiera y acceder a los
        mejores productos del mercado.
      </Description>
      <Container1
        w375="80%"
        margin375="auto"
        mt="32px"
        w700="80%"
        margin700="auto"
        h700="">
        <Fragment>
            <div className="webCarrousel">
              <Carousel
                id="CarruselEmpresaWebLp"
                width="100%"
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                autoPlay
                infiniteLoop
                // transitionTime={2500}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = {
                    width: "12px",
                    height: "12px",
                    marginLeft: 10,
                    opacity: "54%",
                    background: "rgb(172,46,101, .54)",
                    cursor: "pointer",
                    // border: "1px solid #000000",
                    boxSizing: "borderBox",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50%",
                  };
                  const style = isSelected
                    ? {...defStyle, background: "#AC2E5B"}
                    : {...defStyle};
                  return (
                    <div
                      style={style}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      // aria-label={`${label} ${index + 1}`}
                    ></div>
                  );
                }}>
                <Shapes
                  id="Empresa1"
                  key={1}
                  mtopD="0%"
                  heightD="auto"
                  height="auto"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex"
                  // style={{background: "red"}}
                >
                  <Container
                    width="100%"
                    mt="0px"
                    widthMobile="100%"
                    mb="0px"
                    mtMobile="auto"
                    mbMobile="auto"
                    className="d-flex flex-column">
                    <DivFlex
                      width="90%"
                      margin=" auto"
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                      <img
                        src={american}
                        style={{
                          margin: "auto",
                          marginLeft: "0px",
                          width: "79px",
                          height: "30px",
                        }}
                      />
                      <img
                        src={ana}
                        style={{
                          margin: "auto auto 9px",
                          marginLeft: "-7px",
                          width: "88px",
                          height: "39px",
                        }}
                      />
                      <img
                        src={bancoazteca}
                        style={{
                          margin: "auto auto 11px",
                          marginLeft: "-9px",
                          width: "72px",
                          height: "31px",
                        }}
                      />
                      <img
                        src={bbva}
                        style={{
                          margin: "auto",
                          marginLeft: "3px",
                          width: "72px",
                          height: "26px",
                        }}
                      />
                      <img
                        src={chubb}
                        style={{
                          margin: "auto",
                          marginLeft: "3px",
                          width: "121px",
                          height: "16px",
                        }}
                      />
                      <img
                        src={citibanamex}
                        style={{
                          margin: "auto",
                          marginLeft: "0px",
                          width: "125px",
                          height: "31px",
                        }}
                      />
                      <img
                        src={''}
                        alt="copel"
                        style={{
                          margin: "auto",
                          marginRight: "0px",
                          width: "110px",
                          height: "26px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                      <img
                        src={credijusto}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "100px",
                          width: "100px",
                          height: "25px",
                        }}
                      />
                      <img
                        src={creditea}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "123px",
                          height: "38px",
                        }}
                      />
                      <img
                        src={curadeuda}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "105px",
                          height: "30px",
                        }}
                      />
                      <img
                        src={gmb}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "92px",
                          height: "23px",
                        }}
                      />
                      <img
                        src={gnp}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "59px",
                          height: "41px",
                        }}
                      />
                      <img
                        src={hdi}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "67px",
                          height: "34px",
                        }}
                      />
                      <img
                        src={kavak}
                        style={{
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "2px",
                          width: "58px",
                          height: "49px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                      <img
                        src={kueski}
                        style={{
                          margin: "auto",
                          marginLeft: "-6px",
                          width: "106px",
                          height: "45px",
                        }}
                      />
                      <img
                        src={nubank}
                        style={{
                          margin: "auto",
                          marginLeft: "-13px",
                          width: "71px",
                          height: "29px",
                        }}
                      />
                      <img
                        src={rappipay}
                        style={{
                          margin: "auto auto 6px",
                          marginLeft: "-7px",
                          width: "105px",
                          height: "25px",
                        }}
                      />
                      <img
                        src={resuelve}
                        style={{
                          margin: "auto auto 3px",
                          marginLeft: "0px",
                          width: "94px",
                          height: "30px",
                        }}
                      />
                      <img
                        src={scotiabank}
                        style={{
                          margin: "auto",
                          marginLeft: "0px",
                          width: "101px",
                          height: "32px",
                        }}
                      />
                      <img
                        src={story}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "83px",
                          height: "31px",
                        }}
                      />
                      <img
                        src={uniclick}
                        style={{
                          margin: "auto",
                          marginRight: "0px",
                          width: "98px",
                          height: "22px",
                        }}
                      />
                    </DivFlex>
                  </Container>
                </Shapes>
                <Shapes
                  id="Empresa2"
                  key={2}
                  mtopD="0%"
                  heightD="auto"
                  height="auto"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="100%"
                    mt="auto"
                    widthMobile="100%"
                    mb="auto"
                    mtMobile="auto">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                      <img
                        src={vexi}
                        style={{
                          margin: "auto",
                          marginLeft: "0px",
                          width: "72px",
                          height: "45px",
                        }}
                      />
                      <img
                        src={aig}
                        style={{margin: "auto", width: "73px", height: "26px" , marginLeft: "-6px"}}
                      />
                      <img
                        src={arkangeles}
                        style={{margin: "auto", width: "117px", height: "38px", marginLeft: "-8px"}}
                      />
                      <img
                        src={banorte}
                        style={{margin: "auto", width: "155px", height: "32px" , marginLeft: "6px"}}
                      />
                      <img
                        src={briq}
                        style={{margin: "auto", width: "84px", height: "20px", marginLeft: "1px"}}
                      />
                      <img
                        src={credilikeme}
                        style={{
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "-10px",
                          width: "99px",
                          height: "35px",
                        }}
                      />
                       <img
                        src={creditas}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "80px",
                          height: "24px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                     
                      <img
                        src={creditofamiliar}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "70px",
                          height: "50px",
                        }}
                      />
                      <img
                        src={dinn}
                        style={{
                          margin: "auto auto -6px",
                          marginLeft: "-22px",
                          width: "100px",
                          height: "62px",
                        }}
                      />
                      <img
                        src={palacio}
                        style={{
                          margin: "auto",
                          marginLeft: "-18px",
                          width: "106px",
                          height: "35px",
                        }}
                      />
                      <img
                        src={escampa}
                        style={{
                          margin: "auto auto 2px",
                          marginLeft: "-10px",
                          width: "99px",
                          height: "41px",
                        }}
                      />
                      <img
                        src={falabella}
                        style={{
                          margin: "auto auto 8px",
                          marginLeft: "-11px",
                          width: "86px",
                          height: "34px",
                        }}
                      />
                      <img
                        src={hsbc}
                        style={{
                          margin: "auto auto 3px ",
                          marginLeft: "-12px",
                          width: "91px",
                          height: "46px",
                        }}
                      />
                       <img
                        src={invex}
                        style={{
                          margin: "auto",
                          marginLeft: "-12px",
                          width: "102px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                     
                      <img
                        src={klar}
                        style={{
                          margin: "auto",
                          marginLeft: "3px",
                          width: "59px",
                          height: "36px",
                        }}
                      />
                      <img
                        src={liverpool}
                        style={{
                          margin: "auto auto 6px",
                          marginLeft: "2px",
                          width: "114px",
                          height: "26px",
                        }}
                      />
                      <img
                        src={mapfre}
                        style={{
                          margin: "auto auto 10px",
                          marginLeft: "-2px",
                          width: "77px",
                          height: "39px",
                        }}
                      />
                      <img
                        src={networth}
                        style={{
                          margin: "auto",
                          marginLeft: "12px",
                          width: "119px",
                          height: "48px",
                        }}
                      />
                      <img
                        src={prestanomico}
                        style={{
                          margin: "auto auto 3px",
                          width: "122px",
                          marginLeft: "10px",
                         height:"30px",
                        }}
                      />
                      <img
                        src={qualitas}
                        style={{
                          margin: "auto auto -11px",
                          marginLeft: "-6px",
                          width: "83px",
                          height: "59px",
                        }}
                      />
                      <img
                        src={quetarjeta}
                        style={{
                          margin: "auto",
                          marginLeft: "-4px",
                          width: "85px",
                          height: "45px",
                        }}
                      />
                    </DivFlex>
                  </Container>
                </Shapes>
                <Shapes
                  id="Empresa3"
                  key={3}
                  mtopD="0%"
                  heightD="auto"
                  height="auto"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="100%"
                    mt="auto"
                    widthMobile="100%"
                    mb="auto"
                    mtMobile="auto">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                      
                      <img
                        src={atlas}
                        alt="seguro Atlas"
                        style={{
                          margin: "auto auto auto -11px",
                          width: "129px",
                          height: "48px",
                    
                        }}
                      />
                      <img
                        src={yotepresto}
                        style={{
                          margin: "auto auto 3px -36px",
                  
                          width: "149px",
                          height: "40px",
                        }}
                      />
                      <img
                        src={baubap}
                        style={{
                          margin: "auto auto 6px -18px",
                          width: "77px",
                          height: "37px",
                        }}
                      />
                      <img
                        src={clara}
                        style={{
                          margin: "auto auto 9px -13px",
                          width: "71px",
                          height: "22px",
                        }}
                      />
                      <img
                        src={digitt}
                        style={{
                          
                          margin:"auto auto 5px -15px",
                          width: "73px",
                          height: "30px",
                        }}
                      />
                      <img
                        src={doopla}
                        style={{margin: "auto auto 9px -16px", width: "82px", height: "34px"}}
                      />
                      <img
                        src={montePiedad}
                        style={{
                          margin: "auto 0px auto -14px",
                          width: "105px",
                          height: "43px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                      <img
                        src={flink}
                        style={{
                          margin: "auto",
                          marginLeft: "6px",
                          width: "101px",
                          height: "56px",
                        }}
                      />
                      <img
                        src={fundary}
                        style={{
                          margin: "auto",
                          marginLeft: "-5px",
                          width: "62px",
                          height: "50px",
                        }}
                      />
                      <img
                        src={inverspot}
                        style={{
                          margin: "auto",
                          marginLeft: "-14px",
                          width: "111px",
                          height: "44px",
                        }}
                      />
                      <img
                        src={latasa}
                        style={{
                          margin: "auto",
                          marginLeft: "-15px",
                          width: "88px",
                          height: "55px",
                        }}
                      />
                      <img
                        src={mangolife}
                        style={{
                          margin: "auto",
                          width: "139px",
                          marginLeft: "-23px",
                         
                          height: "47px",
                        }}
                      />
                      <img
                        src={mazper}
                        style={{
                          margin: "auto auto 0px ",
                          marginLeft: "-38px",
                          width: "120px",
                          height: "79px",
                        }}
                      />
                      <img
                        src={moneyman}
                        style={{
                          marginTop: "13px",
                          marginLeft: "-29px",
                          width: "103px",
                          height: "59px",
                        }}
                      />
                       <img
                        src={monific}
                        style={{
                          margin: "auto auto 28px 5px",
                          width: "89px",
                          height: "32px",
                        }}
                      />
                    </DivFlex>

                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                     
                      <img
                        src={oyster}
                        style={{
                          margin: "auto",
                          marginLeft: "2px",
                          width: "93px",
                          height: "37px",
                        }}
                      />
                      <img
                        src={prestadero}
                        style={{
                          margin: "auto auto 5px",
                          marginLeft: "-14px",
                          width: "107px",
                          height: "42px",
                        }}
                      />
                      <img
                        src={rebajatuscuentas}
                        style={{
                          margin: "auto",
                          marginLeft: "-18px",
                          width: "147px",
                          height: "27px",
                        }}
                      />
                      <img
                        src={savenest}
                        style={{
                          margin: "auto",
                          marginLeft: "-7px",
                          marginBottom: "7px",
                          width: "125px",
                          height: "34px",
                        }}
                      />
                      <img
                        src={superr}
                        style={{
                          margin: "auto",
                          marginLeft: "-48px",
                          width: "100px",
                          height: "26px",
                        }}
                      />
                      <img
                        src={tala}
                        style={{
                          margin: "auto 0px auto -21px",
                          width: "75px",
                          height: "28px",
                        }}
                     
                      />
                       <img
                        src={zendala}
                        style={{
                          margin: "auto 0px auto -7px",
                          width: "91px",
                          height: "28px",
                        }}
                     
                      />
                    </DivFlex>
                  </Container>
                </Shapes>
              </Carousel>
            </div>
            <div className="mobileCarrousel">
              <Carousel
                id="CarruselEmpresaWebLp"
                width="100%"
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                autoPlay
                infiniteLoop
                // transitionTime={2500}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = {
                    width: "12px",
                    height: "12px",
                    marginLeft: 10,
                    opacity: "54%",
                    background: "rgb(172,46,101, .54)",
                    cursor: "pointer",
                    // border: "1px solid #000000",
                    boxSizing: "borderBox",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50%",
                  };
                  const style = isSelected
                    ? {...defStyle, background: "#AC2E5B"}
                    : {...defStyle};
                  return (
                    <div
                      style={style}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      // aria-label={`${label} ${index + 1}`}
                    ></div>
                  );
                }}>
                <Shapes
                  id="Empresa1"
                  key={1}
                  mtopD="7%"
                  heightD="350px"
                  height="350px"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="90%"
                    mt="0px"
                    widthMobile="100%"
                    mb="0px"
                    mtMobile="auto"
                    mbMobile="auto"
                    class="d-flex flex-column">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50.41px",
                        marginLeft: "0px",
                      }}>

                      <img
                        src={american}
                        style={{width: "87px", height: "26px", margin: "auto auto -5px -1px"}}
                      />

                        <img
                        src={ana}
                        style={{width: "69px", height: "35px", margin: "auto auto -8px 39px"}}
                      />

                     <img
                        src={bancoazteca}
                        style={{
                          width: "96px",
                          height: "36px",
                          margin: "auto auto -9px 37px",
                        
                        }}
                      />
                   
                   
                   

                    
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50.41px",
                        marginLeft: "0px",
                      }}>

                     <img
                        src={bbva}
                        style={{width: "71px", height: "21px", margin: "auto auto auto 2px"}}
                      />

                      <img
                        src={chubb}
                        style={{width: "109px", height: "11px", margin: "auto"}}
                      />

                      <img
                        src={citibanamex}
                        style={{
                          width: "115px",
                          height: "32px",
                          margin: "auto 0px auto 9px",
                       
                        }}
                      />

                  
                 
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50.41px",
                        marginLeft: "0px",
                      }}>
                          <img
                        src={''}
                        style={{
                          width: "115px",
                          height: "25px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />

                    <img
                        src={credijusto}
                        style={{
                          width: "106px",
                          height: "28px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
              
                 
                      <img
                        src={creditea}
                        style={{
                          width: "85px",
                          height: "26px",
                          margin: "auto 0px auto auto",
                         
                        }}
                      />

                  
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50.41px",
                        marginLeft: "0px",
                      }}>


                      <img
                        src={curadeuda}
                        style={{width: "93px", height: "24px", margin: "auto auto auto 0px"}}
                      />

                   <img
                        src={gmb}
                        style={{
                          width: "72px",
                          height: "15px",
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "-12px",
                        }}
                      />

                    <img
                        src={gnp}
                        style={{
                          width: "66px",
                          height: "30px",
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "22px",
                        }}
                      />
                     <img
                        src={hdi}
                        style={{
                          width: "43px",
                          height: "33px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      /> 
                   
                     
                    </DivFlex>
                  </Container>
                </Shapes>
                <Shapes
                  id="Empresa2"
                  key={2}
                  mtopD="7%"
                  heightD="350px"
                  height="350px"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="90%"
                    mt="auto"
                    widthMobile="100%"
                    mb="auto"
                    mtMobile="auto">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "28px",
                        marginLeft: "0px",
                      }}>

                    <img
                        src={kavak}
                        style={{
                          width: "77px",
                          height: "16px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                      <img
                        src={kueski}
                        style={{
                          width: "96px",
                          height: "42px",
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "5px",
                        }}
                      />

                        <img
                        src={nubank}
                        style={{
                          width: "37px",
                          height: "34px",
                          margin: "auto",
                          marginLeft: "60px",
                        }}
                      />
                     
                    </DivFlex>

                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "37px",
                        marginLeft: "0px",
                      }}>
                     <img
                        src={rappipay}
                        style={{
                          width: "92px",
                          height: "71px",
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "0px",
                        }}
                      />

                     <img
                        src={resuelve}
                        style={{
                          width: "100px",
                          height: "40px",
                          margin: "auto",
                          marginLeft: "16px",
                        }}
                      />

                    <img
                        src={scotiabank}
                        style={{width: "121px", height: "21px", margin: "auto auto auto 7px"}}
                      />
                 
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "44px",
                        marginLeft: "0px",
                      }}>
                         <img
                        src={story}
                        style={{
                          width: "80px",
                          height: "24px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                      <img
                        src={uniclick}
                        style={{
                          width: "106px",
                          height: "21px",
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft:"-11p"
                        }}
                      />

                    <img
                        src={vexi}
                        style={{width: "53px", height: "27px", margin: "auto auto auto 44px"}}
                      />
                  
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>
                     <img
                        src={aig}
                        style={{
                          width: "55px",
                          height: "28px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />

                      <img
                        src={arkangeles}
                        style={{width: "108px", height: "10px", margin: "auto auto auto 13px"}}
                      />

                      <img
                        src={banorte}
                        style={{
                          width: "147px",
                          height: "13px",
                          margin: "auto",
                          marginLeft: "11px",
                        }}
                      />
                    </DivFlex>
                  </Container>
                </Shapes>
                <Shapes
                  id="Empresa3"
                  key={3}
                  mtopD="7%"
                  heightD="350px"
                  height="350px"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="90%"
                    mt="auto"
                    widthMobile="100%"
                    mb="auto"
                    mtMobile="auto">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "33px",
                        marginLeft: "0px",
                      }}>
                     
                  

                     <img
                        src={briq}
                        style={{
                          width: "85px",
                          height: "25px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      /> 
                  
                  <img
                        src={credilikeme}
                        style={{
                          width: "99px",
                          height: "30px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      />

                       <img
                        src={creditas}
                        style={{width: "84px", height: "20px", margin: "auto auto auto 28px"}}
                      />
             
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "35px",
                        marginLeft: "0px",
                      }}>
                   <img
                        src={creditofamiliar}
                        style={{
                          width: "64px",
                          height: "41px",
                          margin: "auto 0px auto 0px",
                        
                        }}
                      />
                      <img
                        src={dinn}
                        style={{
                          width: "100px",
                          height: "55px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      /> 

                    <img
                        src={palacio}
                        style={{
                          width: "100.18px",
                          height: "29.1px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      />
                     
                     
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "50px",
                        marginLeft: "0px",
                      }}>

                  <img
                        src={escampa}
                        style={{
                          width: "80px",
                          height: "37px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                      <img
                        src={falabella}
                        style={{
                          width: "82px",
                          height: "32px",
                          margin: "auto",
                          marginLeft: "16px",
                        }}
                      />
                     <img
                        src={hsbc}
                        style={{
                          width: "92px",
                          height: "23px",
                          margin: "auto",
                          marginLeft: "27px",
                        }}
                      />
                      
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "46px",
                        marginLeft: "0px",
                      }}>

                    <img
                        src={invex}
                        style={{width: "98px", height: "18px", margin: "auto"}}
                      />

                        <img
                        src={klar}
                        style={{width: "42px", height: "17px", margin: "auto"}}
                      />

                    <img
                        src={liverpool}
                        style={{width: "106px", height: "23px", margin: "auto"}}
                      />
                         <img
                        src={mapfre}
                        style={{width: "59px", height: "30px", margin: "auto auto auto 4px"}}
                      />
                    
                    </DivFlex>
                  </Container>
                </Shapes>
                <Shapes
                  id="Empresa4"
                  key={4}
                  mtopD="7%"
                  heightD="350px"
                  height="350px"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="90%"
                    mt="auto"
                    widthMobile="100%"
                    mb="auto"
                    mtMobile="auto">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "46px",
                        marginLeft: "0px",
                      }}>
                     
                       <img
                        src={networth}
                        style={{width: "70px", height: "20px", margin: "auto auto auto 0px"}}
                      />
                      <img
                        src={prestanomico}
                        style={{
                          width: "108px",
                          height: "23px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                          <img
                        src={qualitas}
                        style={{
                          width: "35px",
                          height: "44px",
                          margin: "auto",
                          marginRight: "14px",
                          marginLeft:"0px",
                        }}
                      />
                        <img
                        src={quetarjeta}
                        style={{
                          width: "70px",
                          height: "43px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "40px",
                        marginLeft: "0px",
                      }}>
                     
                      
                         <img
                        src={atlas}
                        style={{
                          width: "104px",
                          height: "47px",
                          margin: "auto",
                          marginRight: "0px",
                          marginLeft: "0px",
                        }}
                      />
                     
                   <img
                        src={yotepresto}
                        style={{
                          width: "125px",
                          height: "30px",
                          margin: "auto",
                          marginLeft: "-4px",
                        }}
                      />
                              <img
                        src={baubap}
                        style={{
                          margin: "auto",
                          marginLeft: "0px",
                          width: "100px",
                        }}
                      />
                   
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "40px",
                        marginLeft: "0px",
                      }}>

                  
                    
                     <img src={clara} style={{margin: "auto auto auto 0px", width: "80px"}} />
                     <img
                        src={digitt}
                        style={{width: "70px", height: "25px", margin: "auto"}}
                      />

                  <img
                        src={doopla}
                        style={{width: "104px", height: "25px", margin: "auto auto 6px 7px"}}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "46px",
                        marginLeft: "0px",
                      }}>

                

                <img
                        src={montePiedad}
                        style={{
                          width: "108px",
                          height: "41px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                       <img
                      src={flink}
                      style={{width: "75px", height: "37px", margin: "auto auto auto -13px"}}
                    />

                    <img
                        src={fundary}
                        style={{
                          width: "46px",
                          height: "46px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />

                    </DivFlex>
                  </Container>
                </Shapes>
                <Shapes
                  id="Empresa5"
                  key={5}
                  mtopD="7%"
                  heightD="350px"
                  height="350px"
                  width="100%"
                  position=" "
                  displayD="flex"
                  displayM="flex">
                  <Container
                    width="90%"
                    mt="auto"
                    widthMobile="100%"
                    mb="auto"
                    mtMobile="auto">
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "40px",
                        marginLeft: "0px",
                      }}>
                        

                 <img
                        src={inverspot}
                        style={{width: "86px", height: "23px", margin: "auto"}}
                      />
                    
                    <img
                        src={latasa}
                        style={{
                          width: "48px",
                          height: "39px",
                          margin: "auto",
                          marginLeft: "0px",
                        }}
                      />
                       <img
                        src={mangolife}
                        style={{
                          width: "89px",
                          height: "32px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      />
                     <img
                        src={mazper}
                        style={{
                          margin: "auto",
                          width: "60px",
                          marginRight: "0px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "40px",
                        marginLeft: "0px",
                      }}>
                   

                   

                 <img
                        src={moneyman}
                        style={{width: "94px", height: "47px", margin: "auto"}}
                      />

                  <img
                        src={monific}
                        style={{
                          width: "80px",
                          height: "22px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      />
                      <img
                        src={oyster}
                        style={{width: "80px", height: "25px", margin: "auto"}}
                      />

                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "40px",
                        marginLeft: "0px",
                      }}>
                  
                     
                    
                       <img
                        src={prestadero}
                        style={{width: "85px", height: "40px", margin: "auto"}}
                      />
                     
                     <img
                        src={rebajatuscuentas}
                        style={{
                          width: "125px",
                          height: "21px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      />
                      <img
                        src={savenest}
                        style={{
                          width: "115px",
                          height: "27px",
                          margin: "auto",
                          marginRight: "-28px",
                        }}
                      />
                    </DivFlex>
                    <DivFlex
                      width="100%"
                      margin=" "
                      height="auto"
                      style={{
                        width: "100%",
                        marginBottom: "46px",
                        marginLeft: "0px",
                      }}>
                

                   
                     
                     <img
                        src={superr}
                        style={{
                          width: "75px",
                          height: "22px",
                          margin: "auto",
                          marginRight: "0px",
                        }}
                      /> 

                    <img
                        src={tala}
                        style={{
                          width: "70px",
                          height: "25px",
                          margin: "auto",
                          marginLeft: "29px",
                        }}
                      />



                    <img
                        src={zendala}
                        style={{width: "100px", height: "32px", margin: "auto"}}
                      />
                  
                    </DivFlex>
                  </Container>
                </Shapes>
              </Carousel>
            </div>
        </Fragment>
      </Container1>
    </ContentGeneric>
  );
};

export default Carrousel;
