import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { sequence } from '../Statics';
import CardOne from '../../components/Home/Card1Animate';
import {
  TrainingMain,
  TrainingSectionGray,
  TrainingDivSection2,
  QuestionsDivs,
  QuestionsText,
} from '../../assets/styles/Mvp.styled';
import { SUBMIT_INSERT_LANDING, SUBMIT_UPDATE_LANDING } from '../../mutations';

const Form = () => {
  const [submitInsertLanding] = useMutation(SUBMIT_INSERT_LANDING, {
    onCompleted({ submitInsertLanding }) {
      console.log(submitInsertLanding.response);
      sessionStorage.setItem('id_mvp', submitInsertLanding.response);
    },
    onError(e) {
      console.log(e);
    },
  });
  const [submitUpdateLanding] = useMutation(SUBMIT_UPDATE_LANDING, {
    onCompleted({ submitUpdateLanding }) {
      console.log(submitUpdateLanding.response);
    },
    onError(e) {
      console.log('error mongo: ', e.message);
    },
  });

  const history = useHistory();
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;

  const clearFillIn = () => { };
  const [step, setStep] = useState(
    parseInt(sessionStorage.getItem('returnAnswers')) || 0,
  );
  useEffect(() => {
    setStep(parseInt(sessionStorage.getItem('returnAnswers')) || 0);
  }, []);

  const nextQuestion = () => {
    setStep(
      step + 1 < Object.keys(sequence).length
        ? step + 1
        : Object.keys(sequence).length - 1,
    );
  };
  const [answers] = useState([]);
  const [interests, setInterests] = useState([]);
  const [optionMvp, setOptionMvp] = useState([]);
  useEffect(() => {
    if (sessionStorage.getItem('returnAnswers')) {
      const tempAnswer = JSON.parse(sessionStorage.getItem('answers'));
      const tempInterest = JSON.parse(sessionStorage.getItem('interests'));
      const opcInterest = JSON.parse(sessionStorage.getItem('option_mvp'));
      console.log('tempAnswer', tempAnswer);
      console.log('tempInterest', tempInterest);
      answers.push(tempAnswer[0]);
      setInterests(tempInterest);
      setOptionMvp(opcInterest);
      console.log('answers', answers);
      console.log('interests', interests);
      console.log('interests', interests);
    }
  }, []);

  const recordAnswer = (step, option, route) => {

    answers.push(option);
    sessionStorage.setItem('answers', JSON.stringify(answers));
    if (route) {
      const opc = interests.filter((interest) => interest === option + 1);
      if (opc.length === 0) {
        interests.push(option + 1);
        sessionStorage.setItem('interests', JSON.stringify(interests));
        const num = optionMvp.length + 1;
        optionMvp.push(num);
        sessionStorage.setItem('option_mvp', JSON.stringify(optionMvp));
      }
      console.log('route: ', route);
      history.push(route);
    }

    if (sessionStorage.getItem('id_mvp') === null) {
      const inputInsert = {
        input: {
          answers: sessionStorage.getItem('answers') || '',
          name: '',
          phone: '',
          interests: sessionStorage.getItem('interests') || '',
          utm_source: sessionStorage.getItem('utm_source') || '',
          utm_campaign: sessionStorage.getItem('utm_campaign') || '',
          utm_medium: sessionStorage.getItem('utm_medium') || '',
        },
      };
      // Send to backend
      console.log(JSON.stringify(inputInsert));
      submitInsertLanding({ variables: inputInsert });
    } else {
      const inputInsert = {
        input: {
          answers: sessionStorage.getItem('answers') || '',
          name: '',
          phone: '',
          interests: sessionStorage.getItem('interests') || '',
          id: sessionStorage.getItem('id_mvp'),
          position: sessionStorage.getItem('option_mvp') || '',
        },
      };
      // Send to backend
      console.log(JSON.stringify(inputInsert));
      submitUpdateLanding({ variables: inputInsert });
    }

    console.log('answers: ', answers);
    console.log('interests: ', interests);
    nextQuestion();
  };

  return (
    <Fragment>
      <TrainingMain>
        <TrainingSectionGray>
          <TrainingDivSection2 paddi="5% 5% 2% 5%" back="#fafafa">
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              textbold=""
              text={sequence[step].description}
              text2={sequence[step].question}
              hora={hora}
            />

            <div className="styled-form">
              {sequence[step].options.map((el, i) => (
                <Fragment key={`s${step}-${i}`}>
                  <QuestionsDivs
                    onClick={() => {
                      recordAnswer(step, el.optionCode, el.route || null);
                      clearFillIn();
                    }}
                    width="80%"
                    margin="8px auto auto;"
                    height="41px"
                  >
                    <QuestionsText size="14px" weight="400">
                      {el.answer_title && (
                        <div className="answer_title">{el.answer_title}</div>
                      )}
                      <div className="answer_text">{el.text}</div>
                    </QuestionsText>
                  </QuestionsDivs>
                </Fragment>
              ))}
            </div>
          </TrainingDivSection2>
        </TrainingSectionGray>
      </TrainingMain>
      <div id="irTimeDom"></div>
    </Fragment>
  );
};

export default Form;
