/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  TrainingSectionGray,
  ErrorDiv,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import { INSERT_FINISHED_WO_BY_XCOACH } from '../../mutations';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';

import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const Purpose = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [form, setForm] = useState({
    check_1: false,
    check_2: false,
    check_3: false,
    check_4: false,
    check_5: false,
    check_6: false,
    check_7: false,
    check_8: false,
    check_9: false,
    check_10: false,
  });
  const [alertaPurpose, setAlertaPurpose] = useState(false);
  const [purpose, setPurpose] = useState([]);
  const [showPurposeSelected, setShowPurposeSelected] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (purpose.length < 5 && checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setPurpose(purpose.concat(value));
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = purpose.indexOf(value);
      purpose.splice(deleted, 1);
    }
  };
  const history = useHistory();
  const handlePurpose = (e) => {
    e.preventDefault();
    if (purpose.length === 5 && !isEmptyObject(Rutine)) {
      setShowPurposeSelected(true);
      const purposeSelection = purpose.toString();

      questions.push({
        id: 'purposeSelection',
        question: 1,
        value: purposeSelection,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setShowLoader(true);
      setTimeout(() => {
        history.push('/wo/new-years-resolutions-success');
      }, 10000);
    } else {
      setAlertaPurpose(true);
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        break;
      default:
        console.log(val);
    }
  };
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setPurpose(
        sessionStorage.getItem('Seleccionpropositos')
          ? sessionStorage.getItem('Seleccionpropositos').split(',')
          : [],
      );
      if (sessionStorage.getItem('Seleccionpropositos')) {
        const selected = sessionStorage.getItem('Seleccionpropositos').split(',');
        if (selected.length === 5) {
          setShowPurposeSelected(true);
          setShowLoader(true);
          setTimeout(() => {
            history.push('/wo/new-years-resolutions-success');
          }, 10000);
        }
      }
    }
  }, [recoveryRoutine]);
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (purpose.length === 5 && showPurposeSelected) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [purpose]);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <QuestionsOrder
          classCard={'cardOne'}
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <div className={!showPurposeSelected ? '' : 'hidden'}>
          <TrainingQuestion>
            <QuestionsFlex display="inline">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                typeCheckBox={true}
              />
            </QuestionsFlex>
          </TrainingQuestion>
          <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
            <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
              <div style={{ display: 'flex', width: '100%' }}>
                <br />
                <CardsBtn
                  onClick={(e) => handlePurpose(e)}
                  style={{
                    borderRadius: '4px',
                    letterSpacing: '-0.3px',
                    lineHeight: '140%',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                  }}
                  min_w="95%"
                  min_web="350px"
                  mtopw="20px"
                  mleft="10px"
                  mtop="15px"
                  weight="normal!important"
                >
                  Continuar
                </CardsBtn>
              </div>
              <TrainingQuestion>
                <ErrorDiv
                  align="center"
                  className={alertaPurpose === true ? '' : 'hidden'}
                >
                  Aún no seleccionas tus 5 metas financieras
                </ErrorDiv>
              </TrainingQuestion>
            </Container>
          </TrainingSectionGray>
        </div>
        <CardTwo
          classCard={
            purpose.length === 5 && showPurposeSelected
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={purpose[0]}
          text2={purpose[1]}
          text3={purpose[2]}
          text4={purpose[3]}
          text5={purpose[4]}
          colorTraining={trainingColor}
        />
        {showLoader ? (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        ) : null}
      </div>
  );
};

export default Purpose;
