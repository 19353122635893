import React from "react";
import ImageCoachInicio from "../Training/ImageCoachInicio";
import { Container, Col7 } from "../common/common.styled";
import "../../assets/styles/Card1Animate.css";
import { CardsBtn } from "../../assets/styles/Training.styled";

const CardOne = ({
  classCard,
  classTop,
  text,
  textbold,
  text2,
  text3,
  text4,
  text5,
  text6,
  ClassTxt,
  hora,
  children,
  onClick = null,
  widthcontentw,
  classAna,
  logros = false,
  funct,
  funct2,
  btnText = "Continuar",
  btn,
  btnText2 = "Continuar",
  btn2,
  wo,
}) => (
  <Container
    width={
      widthcontentw !== undefined ? widthcontentw : logros ? "100%" : "80%"
    }
    mt="0"
    widthMobile={logros ? "100%" : wo === "SenNotifyVideo" ? "100%" : "90%"}
    mtMobile="0"
    displayweb={
      classCard === "cardOne" || classCard === "cardOne cardOneMargin"
        ? "flex"
        : "none"
    }
    displaymobile={classCard === "cardOne" ? "flex" : "none"}
  >
    <ImageCoachInicio classAna={classAna} topMobile="27px" />

    <Col7
      maxwi="100%"
      flex={classAna === "falseAna" ? "0 0 100%" : "0 0 75%"}
      maxwidth="100%"
      padding=" "
      className="cardPrincipal1"
    >
      <div className={`${classCard} ${classTop}`} onClick={onClick}>
        <div className={`${ClassTxt}`}>
          <p
            className="bold-p"
            style={
              classAna === "falseAna"
                ? { fontSize: "16px" }
                : { fontSize: "20px" }
            }
          >
            {textbold}
          </p>
          {text !== "" ? <p>{text}</p> : null}
          {text2 !== " " ? <p>{text2}</p> : null}
          {text3 !== "" ? <p>{text3}</p> : null}
          {text4 !== "" ? <p>{text4}</p> : null}
          {text5 !== "" ? <p>{text5}</p> : null}
          {text6 !== "" ? <p>{text6}</p> : null}
          {children}
          {btn === true ? (
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={(e) => {
                funct(e);
              }}
            >
              {btnText}
            </CardsBtn>
          ) : null}
          {btn2 === true ? (
            <CardsBtn
              type="button"
              color="#F64282"
              back="#fff"
              border="1px solid #F64282"
              min_w="100%"
              min_web="100%"
              style={{ borderRadius: "4px" }}
              onClick={(e) => {
                funct2(e);
              }}
            >
              {btnText2}
            </CardsBtn>
          ) : null}
        </div>
      </div>
    </Col7>
  </Container>
);

export default CardOne;
