/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import forbes from '../../assets/img/anacoach/forbes.png';

import {
  TrainingQuestion,
  QuestionsFlex,
  Images,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const Inseguridad = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editEsPropio, setEditEsPropio] = useState('');
  const [editZonaPeligro, setEditZonaPeligro] = useState('');
  const [editPresupuesto, setEditPresupuesto] = useState('');
  const [editSegFamiliar, setEditSegFamiliar] = useState('');
  const [editPrioridad, setEditPrioridad] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('caracteristicas', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [casaOdepartamentoPropio, setCasaOdepartamentoPropio] = useState(
    sessionStorage.getItem('CasaOdepartamentoPropio') || '',
  );
  const [zonaDeVivienda, setZonaDeVivienda] = useState(
    sessionStorage.getItem('ZonaDeVivienda') || '',
  );
  const [presupuetoFamiliarMensual, setPresupuetoFamiliarMensual] = useState(
    sessionStorage.getItem('PresupuetoFamiliarMensual') || '',
  );
  const [seguroFamiliar, setSeguroFamiliar] = useState(
    sessionStorage.getItem('SeguroFamiliar') || '',
  );
  const [prioridadFamiliar, setPrioridadFamiliar] = useState(
    sessionStorage.getItem('PrioridadFamiliar') || '',
  );
  const [form, setForm] = useState({});
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 0:
        setEditEsPropio(true);
        setCasaOdepartamentoPropio(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeZonaVivienda' });
        break;
      case 1:
        setEditZonaPeligro(true);
        setZonaDeVivienda(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activePresupuestoFam' });
        break;
      case 2:
        setEditPresupuesto(true);
        setPresupuetoFamiliarMensual(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeSegFamiliar' });
        break;
      case 3:
        setEditSegFamiliar(true);
        setSeguroFamiliar(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activePrioridadFamiliar' });
        if (val.value === 'No') {
          // todo mandar al rp
          if (nextOrderRoutine === 'rp') {
            insuranceActive.push('MAPFRE Seguro de hogar');
            setActiveSucces(true);
          }
        }
        break;
      case 4:
        setEditPrioridad(true);
        setPrioridadFamiliar(val.value);
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          if (val.value === 'Contra robo de casa') {
            insuranceActive.push('GNP Robo de casa');
            insuranceActive.push('AXA Robo de casa');
          } else if (val.value === 'Seguro de secuestro') {
            insuranceActive.push('MAPFRE Seguro de secuestro');
          } else if (val.value === 'Contra Robo de gadgets') {
            insuranceActive.push('AXA Robo de gadgets');
          } else if (val.value === 'Gastos Funerarios') {
            insuranceActive.push('AXA Seguro de gastos funerarios');
            insuranceActive.push('THONA Gastos funerarios');
          }
          setActiveSucces(true);
        }
        break;
      default:
        console.log(val);
    }
  };
  // funcionalidad propia de la rutina

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setCasaOdepartamentoPropio(
        sessionStorage.getItem('CasaOdepartamentoPropio') || '',
      );
      setZonaDeVivienda(sessionStorage.getItem('ZonaDeVivienda') || '');
      setPresupuetoFamiliarMensual(
        sessionStorage.getItem('PresupuetoFamiliarMensual') || '',
      );
      setSeguroFamiliar(sessionStorage.getItem('SeguroFamiliar') || '');
      if (sessionStorage.getItem('SeguroFamiliar') === 'No') {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          insuranceActive.push('MAPFRE Seguro de hogar');
          setActiveSucces(true);
        }
      }
      setPrioridadFamiliar(sessionStorage.getItem('PrioridadFamiliar') || '');
      if (
        sessionStorage.getItem('PrioridadFamiliar') !== null
        && sessionStorage.getItem('PrioridadFamiliar') !== ''
      ) {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          if (
            sessionStorage.getItem('PrioridadFamiliar')
            === 'Contra robo de casa'
          ) {
            insuranceActive.push('GNP Robo de casa');
            insuranceActive.push('AXA Robo de casa');
          } else if (
            sessionStorage.getItem('PrioridadFamiliar')
            === 'Seguro de secuestro'
          ) {
            insuranceActive.push('MAPFRE Seguro de secuestro');
          } else if (
            sessionStorage.getItem('PrioridadFamiliar')
            === 'Contra Robo de gadgets'
          ) {
            insuranceActive.push('AXA Robo de gadgets');
          } else if (
            sessionStorage.getItem('PrioridadFamiliar') === 'Gastos Funerarios'
          ) {
            insuranceActive.push('AXA Seguro de gastos funerarios');
            insuranceActive.push('THONA Gastos funerarios');
          }
          setActiveSucces(true);
        }
      }
    }
  }, [recoveryRoutine]);
  const handleChangeIcon = (json) => {
    setEditEsPropio(false);
    setEditZonaPeligro(false);
    setEditPresupuesto(false);
    setEditSegFamiliar(false);
    setEditPrioridad(false);
    setLapiz([json]);
  };

  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const abrirNuevoTab = (url) => {
    // Abrir nuevo tab
    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (casaOdepartamentoPropio === ''
          || (lapiz[0].routine === currentRoutine
            && lapiz[0].active === 1))
        && editEsPropio !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeInseguridadQ1' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeInseguridadQ1"></div>
        <CardOne
          classCard="cardOne cursorPointer"
          classTop="topOne1"
          text=""
          hora={hora}
        >
          <p
            onClick={() => {
              abrirNuevoTab(
                'https://www.forbes.com.mx/noticias-mexico-6-de-10-ciudades-mas-peligrosas-del-mundo/',
              );
            }}
          >
            Para saber más: México tiene 6 de las ciudades más peligrosas del
            mundo
          </p>
          <Images
            top="0"
            topweb="0"
            left="3%"
            leftw="3%"
            width="97%"
            widthw="97%"
            position="relative"
            className="img-fluid"
            src={forbes}
            bottom="3%"
          ></Images>
        </CardOne>
        <QuestionsOrder
          classCard="cardOne"
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (casaOdepartamentoPropio === ''
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1))
            && editEsPropio !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeZonaVivienda"></div>
        <CardTwo
          classCard={
            casaOdepartamentoPropio !== ''
            && (editEsPropio === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={casaOdepartamentoPropio}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              casaOdepartamentoPropio !== '' && zonaDeVivienda === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            casaOdepartamentoPropio !== ''
            && (editEsPropio === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((casaOdepartamentoPropio !== '' && zonaDeVivienda === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 1
            )
              || editEsPropio === true)
            && editZonaPeligro !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activePresupuestoFam"></div>
        <CardTwo
          classCard={
            zonaDeVivienda !== ''
            && (editZonaPeligro === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={zonaDeVivienda}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              zonaDeVivienda !== '' && presupuetoFamiliarMensual === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            zonaDeVivienda !== ''
            && (editZonaPeligro === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((zonaDeVivienda !== '' && presupuetoFamiliarMensual === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 2
            )
              || editZonaPeligro === true)
            && editPresupuesto !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeSegFamiliar"></div>
        <CardTwo
          classCard={
            presupuetoFamiliarMensual !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={presupuetoFamiliarMensual}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              presupuetoFamiliarMensual !== '' && seguroFamiliar === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 3 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            presupuetoFamiliarMensual !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((presupuetoFamiliarMensual !== '' && seguroFamiliar === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 3
            )
              || editPresupuesto === true)
            && editSegFamiliar !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activePrioridadFamiliar"></div>
        <CardTwo
          classCard={
            seguroFamiliar !== ''
            && (editSegFamiliar === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={seguroFamiliar}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              seguroFamiliar !== '' && prioridadFamiliar === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 4 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            seguroFamiliar !== ''
            && (editSegFamiliar === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={4}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((seguroFamiliar !== '' && prioridadFamiliar === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 5))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 4
            )
              || editSegFamiliar === true)
            && editPrioridad !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            prioridadFamiliar !== ''
            && (editPrioridad === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 5
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={prioridadFamiliar}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={prioridadFamiliar !== '' ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 5 })
            }
          />
        </CardTwo>
      </div>
  );
};

export default Inseguridad;
