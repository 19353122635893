import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Header_Log_Select,
  Header_Log_Select_Option_Container,
  Header_Log_Select_Option,
  Header_Log_Select_Icon,
} from './common.styled';
import Down from '../../assets/img/anacoach/down-chevron.png';
import ClickButton from '../../Helpers/HookHelpers';

const HeaderSelect = ({ options, setSelected }) => {
  const [hideOptions, setHideOptions] = useState('none');
  const history = useHistory();
  const { pathname } = useLocation();
  const { actions } = ClickButton();
  const setCategory = (id) => {
    switch (id) {
      case 1:
        actions({ action: 'ClickButtonCoruHome', variables: 'MenuGastosInteligentes'});
        break;
      case 2:
        actions({ action: 'ClickButtonCoruHome', variables: 'MenuPréstamosConscientes'});
        break;
      case 3:
        actions({ action: 'ClickButtonCoruHome', variables: 'MenuPlaneaciónEficiente'});
        break;
      case 4:
        actions({ action: 'ClickButtonCoruHome', variables: 'MenuProtecciónEfectiva'});
        break;
      default:
        break;
    }
    const iduser = sessionStorage.getItem('idUser');
    if(iduser === '' || iduser === undefined || iduser === null || iduser === 'undefined'){        
      history.push('/');
      return;
    } 
    setHideOptions('none');
    if (pathname.search('the-training-feed') < 1) {
      sessionStorage.setItem('categorieActive', id);
      history.push('/the-training-feed/');
    } else {
      setSelected(id);
    }
  };

  return (
    <Header_Log_Select
      widthW=" "
      width=" "
      rightW="2%"
      textAlign="right"
      className="text-session roboto index-btns"
      onMouseEnter={() => setHideOptions('')}
      onMouseLeave={() => setHideOptions('none')}
    >
      Entrenamientos
      <Header_Log_Select_Icon src={Down} />
      <Header_Log_Select_Option_Container
        display={hideOptions}
        onMouseEnter={() => setHideOptions('')}
        onMouseLeave={() => setHideOptions('none')}
      >
        {options.map((o, i) => (
          <Header_Log_Select_Option
            key={i}
            onClick={() => setCategory(o.idCatCategory)}
          >
            {o.NameCategory}
          </Header_Log_Select_Option>
        ))}
      </Header_Log_Select_Option_Container>
    </Header_Log_Select>
  );
};

export default HeaderSelect;
