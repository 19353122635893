import React from 'react';
import YouTube from 'react-youtube';
import { useMutation } from '@apollo/client';
import { UPDATE_HISTORY_COLLECTION, CREATE_SESSION } from '../../mutations';

const PlayYoutube = (props) => {
  const opts = {
    height: props.height,
    width: props.width,
    playerVars: {
      autoplay: 0,
      rel: 0,
      loop: 0,
      showinfo: 0,
      controls: 1,
      modestbranding: 1,
      autopause: 1,
    },
  };

  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode !== undefined) {
        if (dynamicMongoSession.statusCode === 200) {
          console.log(dynamicMongoSession.response);
          sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
          localStorage.setItem('idClickVideo', dynamicMongoSession.response);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createClickVideoCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const playVideo = (action) => {

    if (sessionStorage.getItem('idClickVideo')) {
      const dataJson = [
        {
          nameTraining: sessionStorage.getItem('trainingName'),
          idUser: sessionStorage.getItem('idUser'),
          action,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({ _id: sessionStorage.getItem('idClickVideo') });
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          key: 'ClickVideo',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idUser: sessionStorage.getItem('idUser'),
        idSession: sessionStorage.getItem('id_session'),
        ClickVideo: [
          {
            nameTraining: sessionStorage.getItem('trainingName'),
            idUser: sessionStorage.getItem('idUser'),
            action,
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          data: dataString,
        },
      };
      createClickVideoCollection({ variables: mongoInput });
    }
  };
  if (props.close) {
    let closeVideo;
    const idTraining = sessionStorage.getItem('trainingName');
    if (sessionStorage.getItem('closeVideo')) {
      closeVideo = sessionStorage.getItem('closeVideo').split(',');
      if (closeVideo.indexOf(idTraining) === -1) {
        closeVideo.push(idTraining);
        sessionStorage.setItem('closeVideo', closeVideo);
        playVideo('Close');
      }
    } else {
      playVideo('Close');
      sessionStorage.setItem('closeVideo', idTraining);
    }
    return null;
  }
  return (
        <YouTube
            videoId={props.idVideo}
            id="v1"
            opts={opts}
            className="frameWeb"
            onPlay={() => { playVideo('Play'); }}
        />
  );
};

export default PlayYoutube;
