import React, { useState, useEffect, Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';
import { DivLoader } from '../../assets/styles/Training.styled';
import {
  CREATE_SESSION_MYSQL,
  CREATE_SESSION,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import UserProfiling from '../Routines/UserProfiling';
import { GET_TRAINING_COLOR } from '../../queries';
import ModalLogin from '../pantallasInicio/ModalLogin';

const DatosBasicos = React.lazy(() => import('../Routines/DatosBasicos'));

const RoutinesOrderProfiling = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) => {
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [prefer, setPrefer] = useState('');
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const scrollToRoutine = (id) => {
    const avance = document.body.scrollHeight;
    window.scroll({
      top: avance,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    if (orderRutine <= 1) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    } else {
      scrollToRoutine(`rutina${orderRutine}`);
    }
  }, [orderRutine]);

  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) { newSession({ variables: mongoInput }); }
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    if (orderRutine === 'rp') {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [orderRutine]);

  return (
    <div>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          {/* <DatosBasicos
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={colorTraining}
          /> */}
          <ModalLogin 
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            onLoginRoutine={2}
            onRegisterRoutine={2} />
        </Suspense>
      ) : null}
      <div id="rutina1"></div>
      {orderRutine >= 2 ? (
        <UserProfiling
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={'rp'}
          setpreferencias={setPrefer}
          trainingColor={colorTraining}
        />
      ) : null}
    </div>
  );
};

export default RoutinesOrderProfiling;
