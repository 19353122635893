import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import TagManager from 'react-gtm-module';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  ContEntrenamientos,
  Container,
} from '../../assets/styles/Home.styled';
import Clock from '../../assets/img/anacoach/clockTraining.png';

function Recommendations({ items }) {
  const tamItems = items.length;
  const tam2 = tamItems - 1;
  const ActualTraining = sessionStorage.getItem('idTraining') || '';
  const newI = [];
  for (let x = 0; x <= tam2; x++) {
    if (items[x].IDCatTraining !== parseInt(ActualTraining)) newI.push(items[x]);
  }
  const history = useHistory();
  const handleClickTraining = (training) => {
    sessionStorage.setItem('clickOutWO', training.name);
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickOutWO',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const urlRedirect = training.TrainingRedirectUrl ? training.TrainingRedirectUrl : '#';
    history.push(urlRedirect);
  };
  const url = window.location.pathname;
  return (
      <Fragment>
         <Container widthMobilexs='100%' width="100%" mtMobile="0px">
            {newI.length !== 0 ? (
            <>
               {newI.map((post, index) => {
                 if (index < 3 || (post.IDCatTraining === 29 && url === '/wo/card-funnel/success')) {
                   if ((post.IDCatTraining === 9 && url === '/wo/card-funnel/success') || post.IDCatTraining === 1) {
                     if(url !== '/wo/no-credit-history'){
                      return (null);
                     }                     
                   }

                   return (
                           <ContEntrenamientos flexMob="0 0 100%" maxwidthMob="100%" flex="0 0 75%" paddingw="0 0 0 0" id="ContEntrenamiento" background="transparent">
                              <ContEntrenamiento margintop="20px" id={post.IDCatTraining} background={post.TrainingColor ? post.TrainingColor : '#917AFF'} widthM="100%" widthD="100%" cursor="pointer">
                                 <div
                                    onClick={() => {
                                      handleClickTraining(post);
                                    }}
                                 >
                                    <ContentTimer
                                       height=" "
                                       maxheightMobile="130px"
                                       heightMobile="140px"
                                       maxheight="107.250px"
                                       display="flex"
                                       top="0px"
                                       width="100%"
                                       position=" "
                                    >
                                    <TitleEntrenamiento className="col-9 roboto" left="0px" margin="auto" top=" ">
                                          {post.TrainingBigTitle}
                                    </TitleEntrenamiento>
                                    <Image src={post.UrlBigImage} widthmoxl="13%" width="25%" widthmob="22%" margin="auto" top="5%" bottom="1%" right="0px"/>

                                    </ContentTimer>
                                    <ContentTimer className="container-bar">
                                       <Image src={Clock} width="20px" left="0px" position=" " top="1%" />
                                       <TextTime className="roboto">Tiempo de entrenamiento: {post.TrainingTime} minutos</TextTime>
                                    </ContentTimer>

                                 </div>
                              </ContEntrenamiento>
                           </ContEntrenamientos>
                   );
                 }
               })}
               </>)
              : null }
         </Container>
      </Fragment>
  );
}

export default Recommendations;
