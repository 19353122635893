import React from 'react';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    fontSize: '0.7em',
  },
}));

function renderRow(props) {
  const { index, style, data } = props;
  const { usersFound, getUserInfo } = data;
  return (
    <ListItem
      button
      key={index}
      style={style}
      onClick={() => getUserInfo(usersFound[index])}
    >
      <ListItemAvatar>
        {usersFound[index].photo ? (
          <img
            src={usersFound[index].photo}
            alt=""
            height={'50px'}
            width={'50px'}
            className="mr-2"
          />
        ) : (
          <AccountCircleIcon style={{ height: '2em', width: '2em' }} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography style={{ fontSize: '0.8rem' }}>
            {`${usersFound[index].fullName}  (ID User: ${usersFound[index].idUser})`}
          </Typography>
        }
        secondary={
          <Typography style={{ fontSize: '0.7rem' }}>
            {`${usersFound[index].email}`}
          </Typography>
        }
      />
    </ListItem>
  );
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

const LocalizedUsers = ({ usersFound, getUserInfo }) => {
  const classes = useStyles();
  const data = { usersFound, getUserInfo };
  return (
    <>
      {usersFound && usersFound.length > 0 ? (
        <div className={classes.root}>
          <div className="header-localized">
            <h5 className="text-muted">
              Usuarios Localizados {usersFound.length}
            </h5>
          </div>
          <FixedSizeList
            height={400}
            width={'100%'}
            itemSize={70}
            itemCount={usersFound.length}
            itemData={data}
          >
            {renderRow}
          </FixedSizeList>
        </div>
      ) : null}
    </>
  );
};

export default LocalizedUsers;
