/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { GET_TRAININGS } from '../../queries';
import {
  GET_RECOMMENDATION_TRAININGS,
  SET_TRAINING_COMPLETE,
} from '../../mutations';
import candado from '../../assets/img/logros/Candado.png';
import ModalAmazon from '../../components/common/ModalAmazon';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
} from '../../assets/styles/Training.styled';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  ContEntrenamientos,
  Container,
} from '../../assets/styles/Home.styled';
import {
  SeparateSection,
  Feed_Main,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import CardOne from '../../components/Home/Card1Animate';
import image1 from '../../assets/img/anacoach/mejor tarjeta.jpg';
import Clock from '../../assets/img/anacoach/clockTraining.png';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import ClickButton from '../../Helpers/HookHelpers';
import WOlayout from '../../components/layouts/WOlayout';
import SectionShare from '../../components/common/ShareSection';

function RpUserProfiling() {
  const { actions } = ClickButton();
  const [preference] = useState(sessionStorage.getItem('preference'));
  const [goFeed] = useState(false);
  const history = useHistory();
  let par = 1;
  const [stateInformativo, setInformativo] = useState(false);
  const [stateOutstanding, setOutstanding] = useState(false);
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  const validateTwin = [];
  const validateMedium = [];
  const validateBig = [];

  const trainingBig = [];
  const trainingVertical = []; // vertical cc
  const items4 = []; // entrenamiento destacado
  const itemsMore = []; // informativos
  const trainingTwin = []; // tiwn

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    console.log('ItemsMore', arrValue);
    if (!(validateInformativo.indexOf(idTrainning) !== -1)) {
      const sectionInformativo = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: image1,
        TrainingRedirectUrl: '',
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      sectionInformativo.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
      if (itemsMore.length >= 1 && !stateInformativo) {
        console.log('activo set stateInformativo');
        setInformativo(true);
      }
    }
  };

  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateOutstanding.indexOf(idTrainning) !== -1)) {
      const sectionOutstanding = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: '',
        TrainingRedirectUrl: '',
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionOutstanding.Liked = arrValue.Liked;
      items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
      if (items4.length >= 1 && !stateOutstanding) {
        console.log('activo set stateOutstanding');
        setOutstanding(true);
      }
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        image: '',
        Liked: '',
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      sectionVertical.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
  };

  const [setSuccessTraining] = useMutation(SET_TRAINING_COMPLETE);

  const setTrainingComplete = async (idU) => {
    idU = parseInt(idU);

    const result = await setSuccessTraining({
      variables: {
        idUser: idU,
        idTraining: 13,
      },
    });

    console.log('complete>>>>> ', result);
  };

  const idUser = sessionStorage.getItem('idUser') || 0;
  const vars = {
    variables: { idUser: Number(idUser) },
    fetchPolicy: 'no-cache',
  };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining) {
    const trainingList = dataTraining.getTrainings;
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      switch (training.Section) {
        case 'Big':
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          break;
        case 'Medium':
          break;
        case 'Twin':
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default:
          break;
      }
      if (training.TrainingType === 'Informativo') {
        pushInformativo(training);
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === 'Tarjetas de crédito') {
        pushCCVertical(training);
      }
    });
  }

  return (
    <WOlayout
      backgroundColor={'#FA9878'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <HeaderSuccess color1="#FA9878" />
      <TrainingMain mt="50px" mtw="0" className="" id="top">
        <ModalAmazon show={true} wo={'Profiling'}></ModalAmazon>
        <br />
        <TrainingSectionGray back="#fafafa">
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <TrainingDivSection2
                paddiw="0% 10% 3% 10%"
                paddi="0% 5% 5% 5%"
                back="#fafafa"
              >
                <TrainingSubtitles
                  size="22px"
                  fw="600"
                  align="center"
                  alignweb="center"
                  className="cabin"
                >
                  ¡Gracias {sessionStorage.getItem('FirstName') || ''}!
                </TrainingSubtitles>
                <TrainingText
                  size="18px"
                  weight="600"
                  className="roboto"
                  align="center"
                  alignweb="center"
                  ls="-0.2px"
                >
                  Hemos procesado tu solicitud y este es el resultado.
                </TrainingText>
                <br />
                <TrainingSubtitles
                  size="18px"
                  fw="600"
                  align="left"
                  alignweb="left"
                  className="cabin"
                >
                  Estos son tus datos que registramos:
                </TrainingSubtitles>
                <br />
                <TrainingText
                  size="16px"
                  className="roboto"
                  align="left"
                  alignweb="left"
                  ls="-0.2px"
                >
                  <b>Nombre cliente:</b>{' '}
                  {sessionStorage.getItem('FirstName') || ''}{' '}
                  {sessionStorage.getItem('MiddleName') || ''}{' '}
                  {sessionStorage.getItem('FathersLastName') || ''}{' '}
                  {sessionStorage.getItem('MothersLastName') || ''}
                  <br />
                  <b>Teléfono:</b> {sessionStorage.getItem('CellPhone') || ''}
                  <br />
                  <b>Email:</b> {sessionStorage.getItem('Email1') || ''}
                </TrainingText>
              </TrainingDivSection2>
              <SeparateSection />
              <Fragment>
                <TrainingSubtitles
                  size="15px"
                  fw="600"
                  width="80%"
                  margin="auto"
                  align="center"
                  alignweb="left"
                  className="cabin"
                >
                  Recomendaciones
                </TrainingSubtitles>
                {(preference === 'Invertir o ahorrar'
                  || sessionStorage.getItem('PrioridadFinanzas')
                    === 'Invertir o ahorrar') && (
                  <Fragment>
                    <Container width="80%" widthMobile="100%" mtMobile="0px">
                      <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                        <ContentHomeSection leftD="0%" floatD=" " width="90%;">
                          <Container
                            width="100%"
                            widthMobile="100%"
                            mtMobile="0px"
                          >
                            {trainingTwin.map((post) => (post.name
                              === 'Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.' ? (
                                <ContEntrenamientos background="transparent">
                                  <ContEntrenamiento
                                    id={post.id}
                                    background={'#A370FF'}
                                    widthM="100%"
                                    widthD="95%"
                                    cursor="pointer"
                                  >
                                    <Link
                                      to={
                                        post.TrainingRedirectUrl
                                          ? post.TrainingRedirectUrl
                                          : '#'
                                      }
                                    >
                                      {post.id !== 1 ? null : null}
                                      <ContentTimer
                                        height=" "
                                        maxheightMobile="130px"
                                        heightMobile="140px"
                                        maxheight="107.250px"
                                        display="flex"
                                        top="0px"
                                        width="100%"
                                        position=" "
                                      >
                                        <br />
                                        <TitleEntrenamiento
                                          className="roboto"
                                          left="0px"
                                          margin="auto"
                                          top=" "
                                          widthD="230px"
                                        >
                                          {post.name}
                                        </TitleEntrenamiento>
                                        <Image
                                          src={
                                            '	https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CWO_CC%20%281%29.svg'
                                          }
                                          width="25%"
                                          widthmob="28.75%"
                                          margin="auto"
                                          top="1%"
                                          bottom="1%"
                                          right="0px"
                                        />
                                      </ContentTimer>
                                      <ContentTimer>
                                        <Image
                                          src={Clock}
                                          width="20px"
                                          left="0px"
                                          position=" "
                                          top="1%"
                                        />
                                        <TextTime className="roboto">
                                          {post.time}
                                        </TextTime>
                                      </ContentTimer>
                                    </Link>
                                  </ContEntrenamiento>
                                </ContEntrenamientos>
                              ) : null))}
                            {trainingBig.map(
                              (post) => (
                                (par = par === 0 ? 1 : 0),
                                post.name
                                === 'La tarjeta de crédito ideal para ti' ? (
                                  <Fragment>
                                    <ContEntrenamientos>
                                      <ContEntrenamiento
                                        background={'#F3F3F3'}
                                        widthM="100%"
                                        widthD="95%"
                                      >
                                        <Link>
                                          <ContentTimer
                                            height="140px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                          >
                                            <TitleEntrenamiento
                                              color="#CDCDCD"
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              Fondo de ahorro
                                            </TitleEntrenamiento>
                                            <Image
                                              src={candado}
                                              width="15%"
                                              margin="auto"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <TextTime
                                              className="roboto"
                                              left="24px"
                                            >
                                              PRÓXIMAMENTE
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                    <ContEntrenamientos>
                                      <ContEntrenamiento
                                        background={'#F3F3F3'}
                                        widthM="100%"
                                        widthD="95%"
                                      >
                                        <Link>
                                          <ContentTimer
                                            height="140px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                          >
                                            <TitleEntrenamiento
                                              color="#CDCDCD"
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              Invertir
                                            </TitleEntrenamiento>
                                            <Image
                                              src={candado}
                                              width="15%"
                                              margin="auto"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <TextTime
                                              className="roboto"
                                              left="24px"
                                            >
                                              PRÓXIMAMENTE
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                  </Fragment>
                                  ) : null
                              ),
                            )}
                          </Container>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Container>
                  </Fragment>
                )}
                {(preference === 'Adquirir nuevos o mejores productos'
                  || sessionStorage.getItem('PrioridadFinanzas')
                    === 'Adquirir nuevos o mejores productos') && (
                  /* recomendaciones */
                  <Fragment>
                    <Container width="90%" widthMobile="100%" mtMobile="0px">
                      <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                        <ContentHomeSection leftD="0%" floatD=" " width="90%;">
                          <Container
                            width="100%"
                            widthMobile="100%"
                            mtMobile="0px"
                          >
                            {trainingTwin.map((post) => (post.name
                              === 'Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.' ? (
                                <Fragment>
                                  <ContEntrenamientos background="transparent">
                                    <ContEntrenamiento
                                      id={post.id}
                                      background={'#A370FF'}
                                      widthM="100%"
                                      widthD="95%"
                                      cursor="pointer"
                                    >
                                      <Link
                                        to={
                                          post.TrainingRedirectUrl
                                            ? post.TrainingRedirectUrl
                                            : '#'
                                        }
                                      >
                                        <ContentTimer
                                          height=" "
                                          maxheightMobile="130px"
                                          heightMobile="140px"
                                          maxheight="107.250px"
                                          display="flex"
                                          top="0px"
                                          width="100%"
                                          position=" "
                                        >
                                          <br />
                                          <TitleEntrenamiento
                                            className="roboto"
                                            left="0px"
                                            margin="auto"
                                            top=" "
                                            widthD="230px"
                                          >
                                            {post.name}
                                          </TitleEntrenamiento>
                                          <Image
                                            src={
                                              '	https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CWO_CC%20%281%29.svg'
                                            }
                                            width="25%"
                                            widthmob="28.75%"
                                            margin="auto"
                                            top="1%"
                                            bottom="1%"
                                            right="0px"
                                          />
                                        </ContentTimer>
                                        <ContentTimer>
                                          <Image
                                            src={Clock}
                                            width="20px"
                                            left="0px"
                                            position=" "
                                            top="1%"
                                          />
                                          <TextTime className="roboto">
                                            {post.time}
                                          </TextTime>
                                        </ContentTimer>
                                      </Link>
                                    </ContEntrenamiento>
                                  </ContEntrenamientos>
                                </Fragment>
                              ) : null))}
                            {trainingBig.map(
                              (post) => (
                                (par = par === 0 ? 1 : 0),
                                post.name
                                === 'La tarjeta de crédito ideal para ti' ? (
                                  <Fragment>
                                    <ContEntrenamientos>
                                      <ContEntrenamiento
                                        background={'#F3F3F3'}
                                        widthM="100%"
                                        widthD="95%"
                                      >
                                        <Link>
                                          <ContentTimer
                                            height="140px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                          >
                                            <br />
                                            <TitleEntrenamiento
                                              color="#CDCDCD"
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              Fondo de ahorro
                                            </TitleEntrenamiento>
                                            <Image
                                              src={candado}
                                              width="15%"
                                              margin="auto"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <TextTime
                                              className="roboto"
                                              left="24px"
                                            >
                                              PRÓXIMAMENTE
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                    <ContEntrenamientos>
                                      <ContEntrenamiento
                                        background={'#F3F3F3'}
                                        widthM="100%"
                                        widthD="95%"
                                      >
                                        <Link>
                                          <ContentTimer
                                            height="140px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                          >
                                            <TitleEntrenamiento
                                              color="#CDCDCD"
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              Seguros
                                            </TitleEntrenamiento>
                                            <Image
                                              src={candado}
                                              width="15%"
                                              margin="auto"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <TextTime
                                              className="roboto"
                                              left="24px"
                                            >
                                              PRÓXIMAMENTE
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                  </Fragment>
                                  ) : null
                              ),
                            )}
                          </Container>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Container>
                  </Fragment>
                )}
                {(preference === 'Cubrirte en temas de seguridad'
                  || sessionStorage.getItem('PrioridadFinanzas')
                    === 'Cubrirte en temas de seguridad') && (
                  <Fragment>
                    <div className="col-xl-12 offset-xl-0 col-lg-12 offset-lg-0 col-md-12 col-xs-12 col-12">
                      <Container
                        width="93%"
                        widthMobile="95%"
                        mleftm="5%"
                        mleftmxs="auto"
                        mtMobile="0px"
                      >
                        {trainingTwin.map((post) => (post.name
                          === 'Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.' ? (
                            <Fragment>
                              <ContEntrenamientos
                                id="ContEntrenamiento"
                                background="transparent"
                              >
                                <ContEntrenamiento
                                  id={post.id}
                                  background="#A370FF"
                                  widthM="100%"
                                  widthD="95%"
                                  cursor="pointer"
                                >
                                  <Link
                                    to={
                                      post.TrainingRedirectUrl
                                        ? post.TrainingRedirectUrl
                                        : '#'
                                    }
                                  >
                                    <ContentTimer
                                      height=" "
                                      maxheightMobile="130px"
                                      heightMobile="140px"
                                      maxheight="107.250px"
                                      display="flex"
                                      top="0px"
                                      width="100%"
                                      position=" "
                                    >
                                      <TitleEntrenamiento
                                        className="col-9 roboto"
                                        left="0px"
                                        margin="auto"
                                        top=" "
                                      >
                                        {post.name}
                                      </TitleEntrenamiento>
                                      <Image
                                        src="https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CWO_CC%20%281%29.svg"
                                        width="25%"
                                        widthmob="22%"
                                        margin="auto"
                                        top="5%"
                                        bottom="1%"
                                        right="0px"
                                      />
                                    </ContentTimer>
                                    <ContentTimer className="container-bar">
                                      <Image
                                        src={Clock}
                                        width="20px"
                                        left="0px"
                                        position=" "
                                        top="1%"
                                      />
                                      <TextTime className="roboto">
                                        {post.time}
                                      </TextTime>
                                    </ContentTimer>
                                  </Link>
                                </ContEntrenamiento>
                              </ContEntrenamientos>
                            </Fragment>
                          ) : null))}
                        {trainingBig.map(
                          (post) => (
                            (par = par == 0 ? 1 : 0),
                            post.name
                            === 'La tarjeta de crédito ideal para ti' ? (
                              <Fragment>
                                <ContEntrenamientos>
                                  <ContEntrenamiento
                                    background={'#F3F3F3'}
                                    widthM="100%"
                                    widthD="95%"
                                  >
                                    <Link>
                                      <ContentTimer
                                        height="140px"
                                        display="flex"
                                        top="0px"
                                        width="100%"
                                      >
                                        <TitleEntrenamiento
                                          color="#CDCDCD"
                                          className="roboto"
                                          left="0px"
                                          margin="auto"
                                          top=" "
                                          widthD="230px"
                                        >
                                          Fondo de ahorro
                                        </TitleEntrenamiento>
                                        <Image
                                          src={candado}
                                          width="16%"
                                          hei="70%"
                                          widthmob="50px"
                                          heiM="70px"
                                          margin="auto"
                                          top="1%"
                                          bottom="1%"
                                          right="0px"
                                        />
                                      </ContentTimer>
                                      <ContentTimer>
                                        <TextTime
                                          className="roboto"
                                          left="24px"
                                        >
                                          PRÓXIMAMENTE
                                        </TextTime>
                                      </ContentTimer>
                                    </Link>
                                  </ContEntrenamiento>
                                </ContEntrenamientos>
                                <ContEntrenamientos>
                                  <ContEntrenamiento
                                    background={'#F3F3F3'}
                                    widthM="100%"
                                    widthD="95%"
                                  >
                                    <Link>
                                      <ContentTimer
                                        height="140px"
                                        display="flex"
                                        top="0px"
                                        width="100%"
                                      >
                                        <TitleEntrenamiento
                                          color="#CDCDCD"
                                          className="roboto"
                                          left="0px"
                                          margin="auto"
                                          top=" "
                                          widthD="230px"
                                        >
                                          Seguros
                                        </TitleEntrenamiento>
                                        <Image
                                          src={candado}
                                          width="16%"
                                          hei="70%"
                                          widthmob="50px"
                                          heiM="70px"
                                          margin="auto"
                                          top="1%"
                                          bottom="1%"
                                          right="0px"
                                        />
                                      </ContentTimer>
                                      <ContentTimer>
                                        <TextTime
                                          className="roboto"
                                          left="24px"
                                        >
                                          PRÓXIMAMENTE
                                        </TextTime>
                                      </ContentTimer>
                                    </Link>
                                  </ContEntrenamiento>
                                </ContEntrenamientos>
                              </Fragment>
                              ) : null
                          ),
                        )}
                      </Container>
                    </div>
                  </Fragment>
                )}
                {(preference === 'Mejorar ingresos, reducir gastos'
                  || sessionStorage.getItem('PrioridadFinanzas')
                    === 'Mejorar ingresos, reducir gastos') && (
                  <Fragment>
                    <Container width="90%" widthMobile="100%" mtMobile="0px">
                      <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                        <ContentHomeSection leftD="0%" floatD=" " width="90%;">
                          <Container
                            width="100%"
                            widthMobile="100%"
                            mtMobile="0px"
                          >
                            {trainingBig.map(
                              (post) => (
                                (par = par === 0 ? 1 : 0),
                                post.name === 'Construye tu presupuesto' ? (
                                  <Fragment>
                                    <ContEntrenamientos background="transparent">
                                      <ContEntrenamiento
                                        id={post.id}
                                        background={'#F5A838'}
                                        widthM="100%"
                                        widthD="95%"
                                        cursor="pointer"
                                      >
                                        <Link
                                          to={
                                            post.TrainingRedirectUrl
                                              ? post.TrainingRedirectUrl
                                              : '#'
                                          }
                                        >
                                          {/* {post.id !== 1 ? <BetaIcon page={'trainings'}></BetaIcon> : null} */}
                                          <ContentTimer
                                            height=" "
                                            maxheightMobile="130px"
                                            heightMobile="140px"
                                            maxheight="107.250px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                            position=" "
                                          >
                                            <br />
                                            <TitleEntrenamiento
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              {post.name}
                                            </TitleEntrenamiento>
                                            <Image
                                              src={post.image}
                                              width="25%"
                                              widthmob="28.75%"
                                              margin="auto"
                                              top="1%"
                                              bottom="1%"
                                              right="0px"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <Image
                                              src={Clock}
                                              width="20px"
                                              left="0px"
                                              position=" "
                                              top="1%"
                                            />
                                            <TextTime className="roboto">
                                              {post.time}
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                    <ContEntrenamientos>
                                      <ContEntrenamiento
                                        background={'#F3F3F3'}
                                        widthM="100%"
                                        widthD="95%"
                                      >
                                        <Link>
                                          <ContentTimer
                                            height="140px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                          >
                                            <TitleEntrenamiento
                                              color="#CDCDCD"
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              Fondo de ahorro
                                            </TitleEntrenamiento>
                                            <Image
                                              src={candado}
                                              widthmob="12%"
                                              heiM="36%"
                                              margin="auto"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <TextTime
                                              className="roboto"
                                              left="24px"
                                            >
                                              PRÓXIMAMENTE
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                    <ContEntrenamientos>
                                      <ContEntrenamiento
                                        background={'#F3F3F3'}
                                        widthM="100%"
                                        widthD="95%"
                                      >
                                        <Link>
                                          <ContentTimer
                                            height="140px"
                                            display="flex"
                                            top="0px"
                                            width="100%"
                                          >
                                            <TitleEntrenamiento
                                              color="#CDCDCD"
                                              className="roboto"
                                              left="0px"
                                              margin="auto"
                                              top=" "
                                              widthD="230px"
                                            >
                                              Seguros
                                            </TitleEntrenamiento>
                                            <Image
                                              src={candado}
                                              width="15%"
                                              margin="auto"
                                            />
                                          </ContentTimer>
                                          <ContentTimer>
                                            <TextTime
                                              className="roboto"
                                              left="24px"
                                            >
                                              PRÓXIMAMENTE
                                            </TextTime>
                                          </ContentTimer>
                                        </Link>
                                      </ContEntrenamiento>
                                    </ContEntrenamientos>
                                    <ContEntrenamientos></ContEntrenamientos>
                                  </Fragment>
                                ) : null
                              ),
                            )}
                          </Container>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Container>
                  </Fragment>
                )}
              </Fragment>
              {recomendaciones ? (
                <Feed_Main width="100%" background=" ">
                  <TrainingDivSection2
                    wiw="670px"
                    paddi="0"
                    paddiw="0"
                    mw="auto"
                    back="#fafafa"
                  >
                    <Feed_Main
                      width="100%"
                      display=" "
                      mtop=" "
                      background="transparent"
                    >
                      <CardOne
                        classCard="cardOne"
                        classTop="topOne1"
                        text="¿Aún quieres ir por más?"
                        text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                      />
                      <CardOne
                        classCard="cardOne"
                        classTop="topOne1"
                        text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                      />
                      <br />
                    </Feed_Main>
                  </TrainingDivSection2>
                </Feed_Main>
              ) : null}
              <div className="marginComentLgSuccess1">
                {recomendaciones ? (
                  <Fragment>
                    <Container
                      mleftmxs="0%"
                      mleft="19%"
                      mleftm="7%"
                      width="85.5%"
                      widthMobile="100%"
                      mtMobile="0px"
                    >
                      <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                        <ContentHomeSection leftD="0%" floatD=" " width="90%;">
                          <Recommendations items={recommendedTraining}>
                            holaa
                          </Recommendations>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Container>
                  </Fragment>
                ) : null}
              </div>
              <TrainingSectionGray back="#fafafa">
                <TrainingDivSection2
                  wiw="670px"
                  paddi="0"
                  paddiw="0"
                  mw="auto"
                  back="#fafafa"
                >
                  <SectionShare WO="Profiling"></SectionShare>
                </TrainingDivSection2>
              </TrainingSectionGray>
            </Feed_Main>
          </Feed_Main>
        </TrainingSectionGray>
      </TrainingMain>
    </WOlayout>
  );
}
export default RpUserProfiling;
