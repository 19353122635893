import { useMutation } from '@apollo/client';
import {
  LIVERPOOL, RTD, ESCAMPA, CREDILIKEME, LA_TASA,
} from '../mutations';

const ProductsHooks = () => {
  const [getLiverpool] = useMutation(LIVERPOOL, {
    onCompleted({ liverpool }) {},
    onError(err) {
      console.log(err);
    },
  });

  const [opportunitexRTD] = useMutation(RTD, {
    onCompleted({ opportunitexRTD }) {},
    onError(err) {
      console.log(err);
    },
  });

  const [sendEscampaApplication] = useMutation(ESCAMPA, {
    onCompleted({ sendEscampaApplication }) {
      console.log(`sendEscampaApplication ${sendEscampaApplication}`);
    },
    onError(err) {
      console.log(`Error en petición Escampa --> ${err}`);
    },
  });

  const [credilikeme] = useMutation(CREDILIKEME, {
    onCompleted({ credilikeme }) {
      console.log(`Response credilikeme----->${credilikeme}`);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [sendLaTasaApplication] = useMutation(LA_TASA, {
    onCompleted({ sendLaTasaApplication }) {
      console.log('Response LA TASA ----->', JSON.stringify(sendLaTasaApplication));
    },
    onError(err) {
      console.log(err);
    },
  });

  const apiTasa = () => {
    const email = sessionStorage.getItem('Email1') || '';
    const names = `${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem('MiddleName')}`;
    const last_name = sessionStorage.getItem('FathersLastName') || '';
    const s_last_name = sessionStorage.getItem('MothersLastName') || '';
    const birthday = sessionStorage.getItem('Birthdate');
    console.log('fecha nacimiento', birthday);
    const cellphone = sessionStorage.getItem('CellPhone') || '';
    const rfc = sessionStorage.getItem('RFC') || '';
    const postal_code = sessionStorage.getItem('ZipCode') || '';
    const state = sessionStorage.getItem('State') || '';
    const municipality = sessionStorage.getItem('Municipality') || '';
    const street = sessionStorage.getItem('Street') || '';
    const number = sessionStorage.getItem('ExtNo') || sessionStorage.getItem('IntNo') || '';
    const monthly_income = sessionStorage.getItem('Ingreso_Mensual_Fijo') || '';
    const loan_intended_use = sessionStorage.getItem('Motivo del Crédito') || sessionStorage.getItem('UsoDinero');
    const amount = sessionStorage.getItem('Cantidadquenecesita') || '';
    const dependent_people = parseInt(sessionStorage.getItem('dependents'), 10) || 0;
    const neighborhood = sessionStorage.getItem('Suburb') || '';
    const inputLaTasa = {
      input: {
        email,
        data: {
          names,
          last_name,
          s_last_name,
          birthday,
          cellphone,
          rfc,
          postal_code,
          state,
          city: state,
          municipality,
          neighborhood,
          street,
          number,
          monthly_income,
          dependent_people,
          loan_intended_use,
          amount,
        },
      },
    };
    sendLaTasaApplication({ variables: inputLaTasa });
  };
  const apiEscampa = () => {
    const tPersona = 'Física';
    const nombre = sessionStorage.getItem('FirstName');
    const nombre2 = sessionStorage.getItem('MiddleName') || '';
    const apellidoPaterno = sessionStorage.getItem('FathersLastName') || '';
    const apellidoMaterno = sessionStorage.getItem('MothersLastName') || '';
    const curp = sessionStorage.getItem('curp') || '';
    let fechaNacimiento = sessionStorage.getItem('Birthdate') || '';
    const estadoCivil = sessionStorage.getItem('MaritalStatus') || '';
    const Estudios = sessionStorage.getItem('Ulltimo_grado_de_estudios') || '';
    const cellPhone = sessionStorage.getItem('CellPhone') || '';
    const telefonoCasa = sessionStorage.getItem('HomePhone') || '';
    const Email = sessionStorage.getItem('Email1') || '';
    const rfc = sessionStorage.getItem('RFC') || '';
    const Score = sessionStorage.getItem('BCScore') || '';
    const medio = 'Comparador';
    const cuentaBancario = sessionStorage.getItem('TarjetaDeposito')
      || sessionStorage.getItem('ClabeDeposito')
      || '';
    let montoCredito = '';
    let montoCreditoSolicitado = sessionStorage.getItem('Cantidadquenecesita') || '';
    const usoCredito = sessionStorage.getItem('Motivo del Crédito') || '';
    const mesesCreditoSolicitado = sessionStorage.getItem('Plazodeseado PL') || '';
    const calle = sessionStorage.getItem('Street') || '';
    const noExt = sessionStorage.getItem('ExtNo') || '';
    const noInt = sessionStorage.getItem('IntNo') || '';
    const cp = sessionStorage.getItem('ZipCode') || '';
    const colonia = sessionStorage.getItem('Suburb') || '';
    const municipio = sessionStorage.getItem('Municipality') || '';
    const estado = sessionStorage.getItem('State') || '';

    fechaNacimiento = sessionStorage.getItem('Birthdate');
    const fechasNacimiento = fechaNacimiento.split('-');
    fechaNacimiento = `${fechasNacimiento[2]}/${fechasNacimiento[1]}/${fechasNacimiento[0]}`;

    const m = montoCreditoSolicitado.split('$');
    montoCreditoSolicitado = `$ ${m[1]}`;

    const monto = sessionStorage.getItem('Ingreso_Mensual_Fijo') || '';

    montoCredito = 'entre20100';

    console.log(`tPersona = ${tPersona}-->${typeof tPersona}`);
    console.log(`nombre = ${nombre}-->${typeof nombre}`);
    console.log(`nombre2 = ${nombre2}-->${typeof nombre2}`);
    console.log(
      `apellidoPaterno = ${apellidoPaterno}-->${typeof apellidoPaterno}`,
    );
    console.log(
      `apellidoMaterno = ${apellidoMaterno}-->${typeof apellidoMaterno}`,
    );
    console.log(`curp = ${curp}-->${typeof curp}`);
    console.log(
      `fechaNacimiento = ${fechaNacimiento}-->${typeof fechaNacimiento}`,
    );
    console.log(`estadoCivil = ${estadoCivil}-->${typeof estadoCivil}`);
    console.log(`Estudios = ${Estudios}-->${typeof Estudios}`);
    console.log(`cellPhone = ${cellPhone}-->${typeof cellPhone}`);
    console.log(`telefonoCasa = ${telefonoCasa}-->${typeof telefonoCasa}`);
    console.log(`Email = ${Email}-->${typeof Email}`);
    console.log(`rfc = ${rfc}-->${typeof rfc}`);
    console.log(`Score = ${Score}-->${typeof Score}`);
    console.log(`medio = ${medio}-->${typeof medio}`);
    console.log(
      `cuentaBancario = ${cuentaBancario}-->${typeof cuentaBancario}`,
    );
    console.log(`montoCredito = ${montoCredito}-->${typeof montoCredito}`);
    console.log(
      `montoCreditoSolicitado = ${montoCreditoSolicitado}-->${typeof montoCreditoSolicitado}`,
    );
    console.log(`usoCredito = ${usoCredito}-->${typeof usoCredito}`);
    console.log(
      `mesesCreditoSolicitado = ${mesesCreditoSolicitado}-->${typeof mesesCreditoSolicitado}`,
    );
    console.log(`calle = ${calle}-->${typeof calle}`);
    console.log(`noExt = ${noExt}-->${typeof noExt}`);
    console.log(`noInt = ${noInt}-->${typeof noInt}`);
    console.log(`cp = ${cp}-->${typeof cp}`);
    console.log(`colonia = ${colonia}-->${typeof colonia}`);
    console.log(`municipio = ${municipio}-->${typeof municipio}`);
    console.log(`estado = ${estado}-->${typeof estado}`);

    const inputEscampa = {
      input: {
        Personal: {
          tPersona,
          nombre,
          nombre2,
          apellidoPaterno,
          apellidoMaterno,
          curp,
          fechaNacimiento,
          estadoCivil,
          Estudios,
          cellPhone,
          telefonoCasa,
          Email,
          rfc,
        },
        Credito: {
          Score,
          medio: 'Comparador',
          cuentaBancario,
          montoCredito,
          montoCreditoSolicitado,
          usoCredito,
          mesesCreditoSolicitado,
        },
        Direccion: {
          calle,
          noExt,
          noInt,
          cp,
          colonia,
          municipio,
          estado,
        },
      },
    };

    if (Score !== '' && Score !== null && Score !== undefined) {
      sendEscampaApplication({ variables: inputEscampa });
    }
  };
  const apiRtd = () => {
    const firstName = sessionStorage.getItem('FirstName');
    const middleName = sessionStorage.getItem('MiddleName') || '';
    const first_surname = sessionStorage.getItem('FathersLastName') || '';
    const second_surname = sessionStorage.getItem('MothersLastName') || '';
    const email = sessionStorage.getItem('Email1') || '';
    const phone = sessionStorage.getItem('HomePhone')
      || sessionStorage.getItem('CellPhone');
    const mobile = sessionStorage.getItem('CellPhone') || '';
    const state = sessionStorage.getItem('State') || '';
    const postal_code = sessionStorage.getItem('ZipCode') || '';
    const utm_source = 'aliados';
    const utm_medium = 'paid';
    const utm_channel = 'api';
    const utm_campaign = 'coru';
    const publisher_id = 'L1';
    const utm_assignment = 'alianzas';
    const utm_adgroup = 'new';
    let debt_amount = sessionStorage.getItem('MontoDeudas');
    switch (debt_amount) {
      case 'De $ 30,000 a $50,000':
        debt_amount = '35000';
        break;
      case 'De $50,000 a $100,000':
        debt_amount = '50000';
        break;
      case 'De $100,000 a $250,000':
        debt_amount = '150000';
        break;
      case 'De $250,000 a $500,000':
        debt_amount = '300000';
        break;
      case 'Más de $500,000':
        debt_amount = '500000';
        break;
      default:
        debt_amount = '35000';
        break;
    }
    const landing = sessionStorage.getItem('urlsheet');

    const inputRTD = {
      input: {
        record: {
          data: {
            system_id: '1',
            user: {
              names: `${firstName} ${middleName}`,
              first_surname,
              second_surname,
              email,
              phone,
              mobile,
              country: 'MX',
              state,
              postal_code,
              contact_by: 'EMAIL',
              contact_by_wa: true,
              terms_conditions: true,
            },
            debts: [
              {
                debt_amount,
              },
            ],
            mkt: {
              landing,
              utm_source,
              utm_medium,
              utm_channel,
              utm_campaign,
              publisher_id,
              utm_adgroup,
              utm_assignment,
            },
          },
        },
      },
    };
    opportunitexRTD({ variables: inputRTD });
  };

  const apiLiverpool = () => {
    console.log('Se quiere ejecutar  API liverpool');
    const datProducts = sessionStorage.getItem('datProducts').split(',');
    for (const i in datProducts) {
      if (
        parseInt(datProducts[i]) === 309
        && Number.isInteger(parseInt(sessionStorage.getItem('BCScore')))
      ) {
        const inputLiverpool = {
          input: {
            Apellidos: `${sessionStorage.getItem(
              'FathersLastName',
            )} ${sessionStorage.getItem('MothersLastName')}`,
            horario_para_contacto: '09:00 20:00',
            Nombre: sessionStorage.getItem('FirstName'),
            st_Email: sessionStorage.getItem('Email1'),
            IDExterno: sessionStorage.getItem('idUser'),
            EXTRASBURO: {
              rfc: sessionStorage.getItem('RFC'),
              credito_hipotecario: sessionStorage.getItem('Hipoteca_Vigente'),
              credito_auto: sessionStorage.getItem(
                'Credito_automotriz_bancario_en_los_ultimos_do',
              ),
              score: sessionStorage.getItem('BCScore'),
              credito_banco: sessionStorage.getItem('activeCard'),
              birthday: sessionStorage.getItem('Birthdate'),
            },
            Telefono: sessionStorage.getItem('CellPhone'),
          },
        };
        if (parseInt(sessionStorage.getItem('BCScore')) >= 560) {
          getLiverpool({ variables: inputLiverpool });
        }
      }
    }
  };

  const apiCrediLikeMe = () => {
    const fechaNacimiento = sessionStorage.getItem('Birthdate');
    console.log(fechaNacimiento);
    const fn = fechaNacimiento.split('-');
    console.log(fn[0]);
    const cantidad = sessionStorage.getItem('Cantidadquenecesita');
    const can = cantidad.split('$').join('').split(',').join('');
    console.log(can);
    let salary = '';
    const getsalary = sessionStorage.getItem('Ingreso_Mensual_Fijo');
    if (getsalary === 'menos_4000') {
      salary = '4000';
    } else if (getsalary === 'de_4000_a_7000') {
      salary = '7000';
    } else if (getsalary === 'de_7000_a_15000') {
      salary = '15000';
    } else {
      salary = '30000';
    }

    const cellphone = sessionStorage.getItem('CellPhone');
    const mail = sessionStorage.getItem('Email1');
    const First_Name = sessionStorage.getItem('FirstName');
    const Second_Surname = sessionStorage.getItem('MiddleName') || '';
    const Last_Name = sessionStorage.getItem('FathersLastName');
    const City = sessionStorage.getItem('Suburb') || '';
    const CURP = sessionStorage.getItem('curp') || '';
    const Loan_Amount = can;
    const Monthly_Salary = salary;
    const Email = sessionStorage.getItem('Email1') || '';
    const Gender = sessionStorage.getItem('Gender') || '';
    const Next_Payment_Date = '';
    const Job_Type = 3;
    const Loan_Purpose = 1;
    const Birthdate = `${fn[0]}${fn[2]}${fn[1]}`;
    const Has_Bank_Account = 1;
    const RFC = sessionStorage.getItem('RFC') || '';
    const Has_Facebook = 0;
    let Dependents = sessionStorage.getItem('Numero_de_dependientes') || 0;
    Dependents = parseInt(Dependents, 10);
    const Phone_No = sessionStorage.getItem('CellPhone') || '';
    const Postal_Code = sessionStorage.getItem('ZipCode') || '';
    const State = sessionStorage.getItem('State') || '';
    const Direction = sessionStorage.getItem('Municipality') || '';
    const Exterior_Number = sessionStorage.getItem('ExtNo') || '';
    const Interior_Number = sessionStorage.getItem('IntNo') || '';
    const Country = 'México';
    const Street = sessionStorage.getItem('Street') || '';

    const inputCrediLikeMe = {
      input: {
        cellphone,
        mail,
        First_Name,
        Second_Surname,
        Last_Name,
        City,
        CURP,
        Loan_Amount,
        Monthly_Salary,
        Email,
        Gender,
        Next_Payment_Date,
        Job_Type,
        Loan_Purpose,
        Birthdate,
        Has_Bank_Account,
        RFC,
        Has_Facebook,
        Dependents,
        Phone_No,
        Postal_Code,
        State,
        Direction,
        Exterior_Number,
        Interior_Number,
        Country,
        Street,
      },
    };

    credilikeme({ variables: inputCrediLikeMe });
  };

  const sendApi = ({ action, variables }) => {
    switch (action) {
      case 309:
        //! Liverpool
        apiLiverpool();
        break;
      case 174:
        console.log('se quiere ejecutar una funcion para 174');
        break;
      case 78:
        //! RTD
        const urlSheet = sessionStorage.getItem('urlsheet');
        if (urlSheet) {
          apiRtd();
          console.log('RTD >>> Ejecutado');
        } else {
          console.log('RTD >>> No hay url');
        }
        break;
      case 277:
        //! Escampa
        apiEscampa();
        console.log('se quiere ejecutar para Escampa');
        break;
      case 280:
        //! CrediLikeMe
        apiCrediLikeMe();
        console.log('se quiere ejecutar para CrediLikeMe');
        break;
      case 333:
        //! La Tasa
        apiTasa();
        console.log('se quiere ejecutar para La Tasa');
        break;
      default:
        console.log('Aun no existe una consulta API para este producto');
        break;
    }
  };
  return { sendApi };
};

export default ProductsHooks;
