import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import { parseInt } from 'lodash-es';
import CaretUp from '../../assets/img/home/caretup.png';
import {
  Feed_Main,
  Feed_Text,
  Feed_Section,
  Top_Text,
} from '../../assets/styles/Feed.styled';
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
  ContainerBottons,
} from '../../components/common/Home.styled';
import { TrainigTam } from '../../assets/styles/Training.styled';
import Header from '../../components/common/DefaultHeader';
import { Title_h1 } from '../../assets/styles/Home.styled';
import { Image } from '../../components/common/common.styled';
import '../../assets/styles/Home.css';
import VideoSection from '../../components/Feed/Section_Video';
import { GET_TRAINING_HOME_CATEGORIES } from '../../queries';
import ShareCommonFeed from './ShareCommonFeed';
import HomeCategories from '../../components/Home/HomeCategories';
import CategoriesFooter from '../../components/common/CategoriesFooter';

function TrainingCategoriesLp() {
  const [categories, setCategories] = useState([]);
  const [catTrainings, setCatTrainings] = useState([]);
  const { data } = useQuery(GET_TRAINING_HOME_CATEGORIES);
  useEffect(() => {
    if (data) {
      const trainings = JSON.parse(data.getTrainingCategories.response);
      setCategories(extractCategories(trainings));
      setCatTrainings(trainings);
    }
  }, [data]);
  const extractCategories = (trainingArray) => {
    let counter = 1;
    const catgrys = trainingArray.filter((training) => {
      if (training.idCatCategory === counter) {
        counter++;
        return training;
      }
    });
    return catgrys;
  };

  // Syles
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollToTop = () => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const [selectedCatego, setSelectedCatego] = useState(0);

  useEffect(() => {
    gsap.to(window, {
      duration: 1,
      scrollTo: `#categorieSelectedOption${selectedCatego}`,
    });
  }, [selectedCatego]);

  useEffect(() => {
    const variable = parseInt(sessionStorage.getItem('categorieActive'));
    if (variable) {
      setSelectedCatego(variable);
      sessionStorage.removeItem('categorieActive');
    }
  });

  const handleCategoryClicked = (categoryId) => {
  };

  // REDIRECCIONAMIENTO

  return (
    <>
      <TrainigTam>
        <Header
          background={'#FAFAFA'}
          pathname={window.location.pathname}
          headerType="HeaderLP"
        />
      </TrainigTam>
      <Feed_Main width="80%">
        <Feed_Section
          className="cuadro-externo"
          back="background-color: rgb(250, 250, 250)"
        >
          <VideoSection time="3" text="Conoce más de Ana Coach" />
        </Feed_Section>
      </Feed_Main>
      <Feed_Main width="100%" backWeb="transparent">
        <Feed_Main width="80%" display="flex" backWeb="transparent">
          <Feed_Section
            padding="5% 5% 5% 5%"
            paddingm="5% 5% 5% 5%"
            position="relative"
          >
            <ContentHomeSection leftD="0%" floatD="" width="100%;">
              <Title_h1
                top="1.2%"
                topM="17%"
                align="left"
                talign="left"
                className="roboto"
              >
                Bienvenido
              </Title_h1>
              <Feed_Text
                className="roboto"
                bottom="0%"
                align="left"
                maxwidth="569px"
              >
                Aquí encontrarás los entrenamientos que te ayudarán a mejorar tu
                vida financiera.
              </Feed_Text>
            </ContentHomeSection>
          </Feed_Section>
          <Rectangle24
            positionD="absolute"
            Ptop="80px"
            Pleft="93%"
            background="#BFDD6A"
            displayM="none"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            transform="rotate(46.41deg)"
          />
          <Vector2
            width="70px"
            left="0"
            height="80px"
            background="#6BD5AF"
            radius="40%"
            transform="rotate(-8.34deg)"
            positionD="absolute"
            Pleft="92%"
            Ptop="119px"
            displayM="none"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="187px"
            Pleft="93%"
            background="#E3E660"
            transform="rotate(-15.06deg)"
            width="100px"
            height="100px"
            displayD=" "
            displayM="none"
          />
        </Feed_Main>
      </Feed_Main>
      <>
        <Feed_Main width="100%" backWeb="#F3F3F3" background="#F3F3F3">
          <Feed_Main
            width="80%"
            display="flex"
            backWeb="#F3F3F3"
            background="#F3F3F3"
            position="relative"
          >
            <div style={{ paddingTop: '20%' }}>
              <Rectangle24
                positionD="absolute"
                Ptop="235px"
                Pleft="67%"
                background="#FFF"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="60px"
                height="60px"
                transform="rotate(46.1deg)"
              />
              <Vector2
                width="70px"
                left="0"
                martop="25%"
                height="80px"
                background="#FFF"
                radius="40%"
                transform="rotate(-8.34deg)"
                positionD="absolute"
                Pleft="92%"
                Ptop="5px"
                displayM="none"
              />
              <Vector3
                position="absolute"
                left=" "
                top=" "
                Ptop="423px"
                Pleft="50%"
                background="#FFF"
                transform="rotate(-43.19deg)"
                width="150px"
                height="150px"
                displayD=" "
                displayM="none"
              />
              <Rectangle24
                positionD="absolute"
                Pleft="90%"
                Ptop="463px"
                displayD=""
                width="150px"
                height="150px"
                left=" "
                top=" "
                background="#FFF"
                transform="rotate(-17.24deg)"
              />
            </div>
            <Feed_Section padding="5% 5% 5% 5%" paddingm="5% 5% 5% 5%">
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="50%;"
                className="mob"
              >
                <Title_h1
                  fz="40px"
                  topM="0%"
                  top="0%"
                  align="left"
                  talign="left"
                  className="roboto"
                >
                  Entrenamientos
                </Title_h1>
                <Feed_Text className="roboto" bottom="5%">
                  Conoce los entrenamientos que tenemos para ayudarte a cumplir
                  tus objetivos.
                </Feed_Text>
              </ContentHomeSection>
              <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                {categories.map((post) => (
                  <HomeCategories
                    color={post.color}
                    name={post.NameCategory}
                    imagen={post.ImageURL}
                    show={selectedCatego === post.idCatCategory}
                    id={post.idCatCategory}
                    description={post.description}
                    setCategory={handleCategoryClicked}
                    trainings={catTrainings}
                    isHome={false}
                  />
                ))}
              </ContentHomeSection>
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="50%;"
                className="web"
              >
                <div style={{ display: 'block' }}>
                  <Title_h1
                    fz="40px"
                    topM="0%"
                    top="0%"
                    align="left"
                    talign="left"
                    className="roboto"
                  >
                    Entrenamientos
                  </Title_h1>
                  <Feed_Text className="roboto" bottom="5%">
                    Conoce los entrenamientos que tenemos para ayudarte a
                    cumplir tus objetivos.
                  </Feed_Text>
                </div>
              </ContentHomeSection>
            </Feed_Section>
          </Feed_Main>
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <Feed_Section
                padding="0% 5% 5% 5%"
                paddingm="0% 5% 5% 5%"
              ></Feed_Section>
            </Feed_Main>
          </Feed_Main>

          <Feed_Main width="100%" backWeb=" ">
            <Feed_Main width="80%" display="flex" backWeb=" ">
              <Feed_Section
                padding="5% 5% 0% 5%"
                paddingm="5% 5% 0% 5%"
                position="relative"
              >
                <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                  <Rectangle24
                    positionD="absolute"
                    Ptop="235px"
                    Pleft="-15%"
                    background="#BFDD6A"
                    displayM="none"
                    displayD=" "
                    left=" "
                    top=" "
                    width="120px"
                    height="120px"
                    transform="matrix(1, -0.08, 0.08, 1, 0, 0);"
                  />
                  <Vector2
                    width="50px"
                    left="0"
                    height="60px"
                    background="#E3E660"
                    radius="40%"
                    transform="matrix(0.7, -0.71, 0.71, 0.7, 0, 0);"
                    positionD="absolute"
                    Pleft="48%"
                    Ptop="115px"
                    displayM="none"
                  />
                  <Vector3
                    position="absolute"
                    left=" "
                    top=" "
                    Ptop="15px"
                    Pleft="-2%"
                    background="#BFDD6A"
                    transform="matrix(-0.31, 0.95, -0.95, -0.31, 0, 0);"
                    width="70px"
                    height="70px"
                    displayD=" "
                    displayM="none"
                  />
                  <Title_h1
                    top="110px"
                    fz="40px"
                    align="left"
                    talign="left"
                    className="roboto"
                  >
                    ¿Quieres ayudar a alguien más?
                  </Title_h1>
                </ContentHomeSection>
                <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                  <Rectangle24
                    positionD="absolute"
                    Ptop="145px"
                    Pleft="-11%"
                    background="#91D770"
                    displayM="none"
                    displayD=" "
                    left=" "
                    top=" "
                    width="30.94px"
                    height="30.94px"
                    transform="rotate(46.41deg)"
                  />
                  <Rectangle24
                    positionD="absolute"
                    Ptop="243px"
                    Pleft="38%"
                    background="#6BD5AF"
                    displayM="none"
                    displayD=" "
                    left=" "
                    top=" "
                    width="40px"
                    height="40px"
                    transform="rotate(46.41deg)"
                  />
                  <Vector3
                    position="absolute"
                    left=" "
                    top=" "
                    Ptop="100px"
                    Pleft="89%"
                    background="#6BD5AF"
                    transform="matrix(0.54, -0.84, 0.85, 0.54, 0, 0);"
                    width="200px"
                    height="200px"
                    displayD=" "
                    displayM="none"
                  />
                  <ShareCommonFeed
                    url="feed"
                    btn={true}
                    trainingName="Feed"
                    textShare="Comparte Coru con tus personas favoritas:"
                    mtop="0px"
                  ></ShareCommonFeed>
                </ContentHomeSection>
              </Feed_Section>
            </Feed_Main>
          </Feed_Main>
          <ContainerBottons top="30px" position=" " onClick={scrollToTop}>
            <Image
              src={CaretUp}
              width="20px"
              margin="auto"
              onClick={scrollToTop}
            />
          </ContainerBottons>
          <ContainerBottons top="2%" position=" " bottomM="0" bottomD="0">
            <Top_Text
              onClick={scrollToTop}
              style={{ cursor: 'pointer' }}
              className="roboto"
            >
              Volver arriba
            </Top_Text>
          </ContainerBottons>
          {/* <Whatsapp url={""} id="Whatsapp"/> */}
        </Feed_Main>
      </>
      {/* </WOlayout> */}
      <CategoriesFooter />
    </>
  );
}

export default TrainingCategoriesLp;
