/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import {
  TrainingQuestion,
  QuestionsDivs,
  QuestionsFlex,
  CardsBtn,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const CaracteristicasFamiliares = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [familyComposition, setFamilyComposition] = useState(
    sessionStorage.getItem('ComposicionFamiliar') || '',
  );
  const [numberChildren, setNumberChildren] = useState(
    sessionStorage.getItem('CuantosHijosTienes') || 0,
  );
  const [activeAgeChildrens, setActiveAgeChildrens] = useState(false);
  const [activeAgeParents, setActiveAgeParents] = useState(false);
  const [form, setForm] = useState({
    EdadesHijo1: sessionStorage.getItem('EdadesHijo1') || '',
    EdadesHijo2: sessionStorage.getItem('EdadesHijo2') || '',
    EdadesHijo3: sessionStorage.getItem('EdadesHijo3') || '',
    EdadesHijo4: sessionStorage.getItem('EdadesHijo4') || '',
    EdadesHijo5: sessionStorage.getItem('EdadesHijo5') || '',
    EdadPadre: sessionStorage.getItem('EdadPadre') || '',
    EdadMadre: sessionStorage.getItem('EdadMadre') || '',
  });
  const [error] = useState({
    EdadesHijo1: false,
    EdadesHijo2: false,
    EdadesHijo3: false,
    EdadesHijo4: false,
    EdadesHijo5: false,
    EdadPadre: false,
    EdadMadre: false,
    phonevalid: form.EdadesHijo1.length !== 0 ? 0 : -1,
  });
  const [texterror] = useState({
    EdadesHijo1: '',
    EdadesHijo2: '',
    EdadesHijo3: '',
    EdadesHijo4: '',
    EdadesHijo5: '',
    EdadPadre: '',
    EdadMadre: '',
  });
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editComFam, setEditComFam] = useState('');
  const [editHijos, setEditHijos] = useState('');
  const [editEdadesHijos, setEditEdadHijos] = useState('');
  const [editEdadPadres, setEditEdadPadres] = useState('');
  const ValidAge = (name, value, id) => {
    const tel = document.getElementById(id);

    if (value.length <= 3) {
      setForm({
        ...form,
        [name]: value.replace(/[^0-9]/i, ''),
      });
      if (tel === null) {
        // console.log('null');
      } else if (
        value.length === ''
        || value.length > 3
        || (name && !/^[0-9]+$/i.test(value))
      ) {
        tel.className = 'textInput inputs-bottom invalidInput';
        texterror[name] = 'Por favor verifica la edad';
        error[name] = false;
        error[name] = 1;
      } else if (value > 100) {
        tel.className = 'textInput inputs-bottom invalidInput';
        texterror[name] = 'Por favor verifica la edad';
        error[name] = false;
      } else {
        tel.className = 'textInput inputs-bottom validInput';
        texterror[name] = '';
        error[name] = 0;
        error[name] = true;
      }
    } else {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica la edad';
      error[name] = false;
    }
  };
  const handleChange = (e) => {
    switch (e.target.name) {
      case 'EdadesHijo1':
      case 'EdadesHijo2':
      case 'EdadesHijo3':
      case 'EdadesHijo4':
      case 'EdadesHijo5':
      case 'EdadPadre':
      case 'EdadMadre':
        ValidAge(e.target.name, e.target.value, e.target.id);
        break;
      default:
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setEditComFam(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuestion2' });
        if (val.value === 'Otros' || val.value === 'Esposo(a)') {
          setOrderRutine(nextOrderRoutine);
        }
        setFamilyComposition(val.value);
        break;
      case 1:
        setEditHijos(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuestion3' });
        setNumberChildren(parseInt(val.value));
        break;
      default:
        break;
    }
  };
  // funcionalidad propia de la rutina

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        ...form,
        EdadesHijo1: sessionStorage.getItem('EdadesHijo1') || '',
        EdadesHijo2: sessionStorage.getItem('EdadesHijo2') || '',
        EdadesHijo3: sessionStorage.getItem('EdadesHijo3') || '',
        EdadesHijo4: sessionStorage.getItem('EdadesHijo4') || '',
        EdadesHijo5: sessionStorage.getItem('EdadesHijo5') || '',
        EdadPadre: sessionStorage.getItem('EdadPadre') || '',
        EdadMadre: sessionStorage.getItem('EdadMadre') || '',
      });
      setFamilyComposition(sessionStorage.getItem('ComposicionFamiliar') || '');
      if (familyComposition === 'otros' || familyComposition === 'Esposo(a)') {
        setOrderRutine(nextOrderRoutine);
      } else if (familyComposition === 'Padres') {
        const edadPadre = sessionStorage.getItem('EdadPadre');
        const edadMadre = sessionStorage.getItem('EdadMadre');
        if (
          edadPadre !== null
          && edadMadre !== null
          && edadPadre !== ''
          && edadMadre !== ''
        ) {
          setActiveAgeParents(true);
          setOrderRutine(nextOrderRoutine);
        }
      }
      if (parseInt(sessionStorage.getItem('CuantosHijosTienes')) > 0) {
        const soon = sessionStorage.getItem(
          `EdadesHijo${sessionStorage.getItem('CuantosHijosTienes')}`,
        );
        if (soon !== null && soon !== '') {
          setActiveAgeChildrens(true);
          setOrderRutine(nextOrderRoutine);
        }
      }
      setNumberChildren(sessionStorage.getItem('CuantosHijosTienes') || 0);
    }
  }, [recoveryRoutine]);

  const ageChildren = () => {
    const questionsAge = [];
    for (let i = 1; i <= numberChildren; i++) {
      questionsAge.push(i + 1);
    }
    return questionsAge.map((value, index) => (
      <InputsOrder
        key={index}
        handleChangeQuestionsSend={handleChangeQuestionsSend}
        handleChangeRutine={handleChangeRutine}
        orderRutine={orderRutine}
        step={`${value}-${value}`}
        handleChangeCatOption={handleChangeCatOption}
        form={form}
        handleChange={handleChange}
        inputError={error}
        texterror={texterror}
      />
    ));
  };

  const parentsAge = () => {
    const questionsAge = [7, 8];

    return questionsAge.map((value, index) => (
      <InputsOrder
        key={index}
        handleChangeQuestionsSend={handleChangeQuestionsSend}
        handleChangeRutine={handleChangeRutine}
        orderRutine={orderRutine}
        step={`${value}-${value}`}
        handleChangeCatOption={handleChangeCatOption}
        form={form}
        handleChange={handleChange}
        inputError={error}
        texterror={texterror}
      />
    ));
  };

  const handleChangeAge = (e) => {
    setEditEdadHijos(true);
    setEditEdadPadres(true);
    e.preventDefault();
    const validacionesSoon = [];
    for (let i = 1; i <= numberChildren; i++) {
      if (
        parseInt(form[`EdadesHijo${i}`]) <= 100
        && error[`EdadesHijo${i}`]
        && form[`EdadesHijo${i}`] !== ''
      ) {
        validacionesSoon.push(true);
      } else {
        validacionesSoon.push(false);
      }
    }
    if (validacionesSoon.indexOf(true) !== -1) {
      gsap.to(window, { duration: 1, scrollTo: '#activeQuestion4' });
      setActiveAgeChildrens(true);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
    } else if (
      parseInt(form.EdadMadre) <= 100
      && parseInt(form.EdadPadre) <= 100
      && form.EdadMadre !== ''
      && form.EdadPadre !== ''
      && error.EdadPadre
      && error.EdadMadre
    ) {
      gsap.to(window, { duration: 1, scrollTo: '#activeQuestion5' });
      setActiveAgeParents(true);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
    }
    const click = `ContinuarEdades_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
  };

  const handleChangeIcon = (json) => {
    setEditComFam(false);
    setEditHijos(false);
    setEditEdadHijos(false);
    setEditEdadPadres(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (familyComposition === ''
          || (lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
        && editComFam !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeQuestion1' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      <div id="activeQuestion1"></div>
      <QuestionsOrder
        classCard="cardOne"
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (familyComposition === ''
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
          && editComFam !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          {orderRutine === currentRoutine ? (
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          ) : null}
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeQuestion2"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            familyComposition !== ''
            && numberChildren === 0
            && !activeAgeParents
            && orderRutine < 4
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
          }
        />
        <CardTwo
          classCard={
            familyComposition !== ''
            && (editComFam === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={familyComposition}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* pregunta 2 debera de aparecer solo si la respuesta de la pregunta anterior es Esposo(a) e hijos o  solo hijos  */}
      <QuestionsOrder
        classCard={
          (familyComposition === 'Esposo(a) e hijos'
            || familyComposition === 'Solo hijos')
          && (editComFam === true
            || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (((familyComposition === 'Esposo(a) e hijos'
            || familyComposition === 'Solo hijos')
            && numberChildren === 0)
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 2))
          && (!(lapiz[0].routine === currentRoutine && lapiz[0].active === 1)
            || editComFam === true)
          && editHijos !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          {orderRutine === currentRoutine ? (
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          ) : null}
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeQuestion3"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            numberChildren !== 0 && !activeAgeChildrens ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
          }
        />
        <CardTwo
          classCard={
            numberChildren !== 0
            && (editHijos === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={numberChildren}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          (numberChildren !== 0 || activeAgeChildrens)
          && (editHijos === true
            || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 2))
          && familyComposition !== 'Padres'
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Qué edades tienen tus hijos?"
        hora={hora}
      />
      <TrainingQuestion
        wiw="60%"
        mLg="18%"
        className={
          ((numberChildren !== 0 && !activeAgeChildrens)
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 3))
          && (!(lapiz[0].routine === currentRoutine && lapiz[0].active === 2)
            || editHijos === true)
          && editEdadesHijos !== true
          && editEdadPadres !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <form>
            <Fragment>{ageChildren()}</Fragment>
            <CardsBtn
              windthmd="100%"
              windthG="100%"
              onClick={(e) => {
                handleChangeAge(e);
              }}
            >
              Continuar
            </CardsBtn>
          </form>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeQuestion4"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            numberChildren !== 0 && activeAgeChildrens && orderRutine < 4
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 3 })
          }
        />
        <CardTwo
          classCard={
            numberChildren !== 0
            && activeAgeChildrens
            && (editEdadesHijos === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={form.EdadesHijo1 ? `Edad hijo 1: ${form.EdadesHijo1}` : ''}
          text2={form.EdadesHijo2 ? `Edad hijo 2: ${form.EdadesHijo2}` : ''}
          text3={form.EdadesHijo3 ? `Edad hijo 3: ${form.EdadesHijo3}` : ''}
          text4={form.EdadesHijo4 ? `Edad hijo 4: ${form.EdadesHijo4}` : ''}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          familyComposition === 'Padres'
          && (editComFam === true
            || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Qué edad tienen tus padres?"
        hora={hora}
      />
      <TrainingQuestion
        wiw="60%"
        mLg="18%"
        className={
          ((familyComposition === 'Padres' && !activeAgeParents)
            || (lapiz[0].routine === currentRoutine && lapiz[0].active === 4))
          && (!(lapiz[0].routine === currentRoutine && lapiz[0].active === 1)
            || editComFam === true)
          && editEdadPadres !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <form>
            <Fragment>{parentsAge()}</Fragment>
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={(e) => {
                handleChangeAge(e);
              }}
            >
              Continuar
            </CardsBtn>
          </form>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeQuestion5"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            familyComposition === 'Padres' && activeAgeParents
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: currentRoutine, active: 4 })
          }
        />
        <CardTwo
          classCard={
            familyComposition === 'Padres'
            && activeAgeParents
            && (editEdadPadres === true
              || !(lapiz[0].routine === currentRoutine && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          textT={''}
          text2={` Edad padre: ${form.EdadPadre}`}
          text3={` Edad madre: ${form.EdadMadre}`}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default CaracteristicasFamiliares;
