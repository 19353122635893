/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

import {
  TrainingQuestion,
  TrainingDivSection,
  QuestionsDivs,
  QuestionsFlex,
  CardsBtn,
  ErrorDiv,
  DivRespDP,
  TrainingSubtitles,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import { Container, Col7 } from '../../components/common/common.styled';
import LoadingWo from '../../components/Training/loadingWo';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import Starttraining from '../../components/Training/Starttraining';
import VideoQuestion from '../../components/common/VideoQuestion';

const DomicileVideo = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  customAdvice,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const { sendMessage } = SendNotificationFTAndCC();
  const { sendEmail } = SendNotificationEmail();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editDomicilio, setEditDomicilio] = useState('');
  const [editTiempoD, setEditTiempoD] = useState('');
  const [editTipoV, setEditTipoV] = useState('');
  const [editTelFijo, setEditTelFijo] = useState('');

  const [letsEditDomicilio, setLetsEditDomicilio] = useState(false);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
          // setStateRecovery("inactiva");
        } else {
          const routines = json; // Object.values(json);
          console.log(routines);
          let contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            if (arrayResponseRutine.length === 11) {
              const insertQuestion = {
                idQuestion: 21,
                idType: 92,
                questionName: 'Ingresa Dirección',
                questionOrder: 1,
                questionType: 'OpenField',
                responseValue: '',
              };
              arrayResponseRutine.unshift(insertQuestion);
            }
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;

              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              const { questionName } = arrayResponseRutine[i];

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (parseInt(idTypeQuestion) === 89) {
                stopRoutine = true;
                console.log('telefono casa ', questionName, responseUser);
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [Domicilio, setDomicilio] = useState(
    sessionStorage.getItem('Suburb') || '',
  );
  const [TimeDom, setTimeDom] = useState(
    sessionStorage.getItem('Tiempo_de_vivir_en_domicilio') || '',
  );
  const [Vivienda, setVivienda] = useState(
    sessionStorage.getItem('Tipo_de_Vivienda') || '',
  );
  const [TelCasa, setTelCasa] = useState(
    sessionStorage.getItem('HomePhone') || '',
  );

  const [texterror] = useState({
    email: '',
    firstname: '',
    secondname: '',
    lastname: '',
    secondlastname: '',
    day: '',
    month: '',
    year: '',
  });

  const [error] = useState({
    genero: false,

    landline: false,
  });
  const [alertaDom, setAlerta] = useState(false);
  const [form, setForm] = useState({
    directionMaps: '',
    mapsGeo: '',
    calle: sessionStorage.getItem('Street') || '',
    numext: sessionStorage.getItem('ExtNo') || '',
    numint: sessionStorage.getItem('IntNo') || '',
    cp: sessionStorage.getItem('ZipCode') || '',
    colonia: sessionStorage.getItem('Suburb') || '',
    municipio: sessionStorage.getItem('Municipality') || '',
    estado: sessionStorage.getItem('State') || '',
    telcasa: sessionStorage.getItem('HomePhone') || '',
  });
  const ValidCP = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
  };
  const ValidPhone = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementById(id);
    if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'secondname') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.name);
    switch (e.target.name) {
      case 'telcasa':
        // setwriteTel('false');
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      case 'directionMaps':
        // setwriteDom('false');
        console.log('Autocomplete');
        break;
      case 'calle':
        // setwriteDom('false');
        console.log('calle');
        break;
      case 'colonia':
        // setwriteDom('false');
        console.log('colonia');
        break;
      case 'numext':
        // setwriteDom('false');
        console.log('numext');
        break;
      case 'numint':
        // setwriteDom('false');
        console.log('numint');
        break;
      case 'cp':
        // setwriteDom('false');
        ValidCP(e.target.name, e.target.value, e.target.id);
        console.log('cp');
        break;
      case 'municipio':
        console.log('municipio');
        break;
      case 'estado':
        console.log('estado');
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  // funcionalidad
  useEffect(() => {
    if (orderRutine === currentRoutine) {
      if (form.mapsGeo !== '') {
        handleChangeQuestionsSend(2, 'mapsGeo', form.mapsGeo);
      } else if (form.numint !== '') {
        handleChangeQuestionsSend(5, 'numint', form.numint);
      }
    }
  }, [form]);
  const handleRoutineOrder = (step, prod = '', cantidad = '', tel = '') => {
    console.log('handleRoutineOrder', step, prod, cantidad, tel);
    setEditTelFijo(true);

    if (tel === 'No tengo teléfono fijo') {
      setTelCasa('No tengo teléfono fijo');
      gsap.to(window, { duration: 1, scrollTo: '#activeSigRoutine' });
      questions.push({
        id: 'telcasa',
        question: 12,
        value: 'No tengo teléfono fijo',
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
      const click = `NoTengoTelefonoFijo_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else {
      console.log('else telcasa', form.telcasa);
      setTelCasa(form.telcasa);
      gsap.to(window, { duration: 1, scrollTo: '#activeSigRoutine' });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
      const click = `ConfirmarTelefono_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 9:
        setTimeDom(val.value);
        setEditTiempoD(true);
        break;
      case 10:
        setVivienda(val.value);
        setEditTipoV(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeTelefonoCasa' });
        break;
      default:
        console.log('default break handleChangeCatOption case 2');
        break;
    }
  };

  const handleChangeAddress = (key, val) => {
    let numQuestion;
    switch (key) {
      case 'postal_code':
        key = 'cp';
        numQuestion = 6;
        break;
      case 'route':
        key = 'calle';
        numQuestion = 3;
        break;
      case 'street_number':
        key = 'numext';
        numQuestion = 4;
        break;
      case 'estado':
        key = 'estado';
        numQuestion = 9;
        break;
      case 'Municipality':
        key = 'municipio';
        numQuestion = 8;
        break;
      case 'Suburb':
        sessionStorage.setItem('Suburb', val);
        key = 'colonia';
        numQuestion = 7;
        form.colonia = val;
        break;
      default:
        break;
    }
    if (key !== undefined) {
      handleChangeQuestionsSend(numQuestion, key, val, 'OpenField');
    }
    setForm({
      ...form,
      [key]: val,
    });
  };

  const handleChangeDomicile = () => {
    setLetsEditDomicilio(false);
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone'),
      trigger: 'wa_cc_4',
    });
    sendEmail({ WO: 'CC', trigger: 'alerta_cc_4_mail' });
    const click = `GuardarDomicilio_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setEditDomicilio(true);
    setAlerta(false);
    setDomicilio('Continuar');
    createJsonInput(Rutine, questions);
    setQuestions([]);
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        ...form,
        directionMaps: '',
        mapsGeo: '',
        calle: sessionStorage.getItem('Street') || '',
        numext: sessionStorage.getItem('ExtNo') || '',
        numint: sessionStorage.getItem('IntNo') || '',
        cp: sessionStorage.getItem('ZipCode') || '',
        colonia: sessionStorage.getItem('Suburb') || '',
        municipio: sessionStorage.getItem('Municipality') || '',
        estado: sessionStorage.getItem('State') || '',
        telcasa: sessionStorage.getItem('HomePhone') || '',
      });
      setDomicilio(sessionStorage.getItem('Suburb') || '');
      setTimeDom(sessionStorage.getItem('Tiempo_de_vivir_en_domicilio') || '');
      setVivienda(sessionStorage.getItem('Tipo_de_Vivienda') || '');
      setTelCasa(sessionStorage.getItem('HomePhone') || '');
      const phone = sessionStorage.getItem('HomePhone') || '';
      if (phone !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);
  const handleChangeIcon = (json) => {
    setEditDomicilio(false);
    setEditTiempoD(false);
    setEditTipoV(false);
    setEditTelFijo(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [lapizTelfijo, setLapizTelfijo] = useState(
    sessionStorage.getItem('TipoCuentaDeposito') || '',
  );
  useEffect(() => {
    if (lapizTelfijo !== '') {
      setLapizTelfijo('Quitar');
    }
  }, [lapizTelfijo]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine && Domicilio === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeDomicilioForm' });
      }
    }, 4000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log(
      'mostrar loader >>>',
      recoveryRoutine,
      sessionStorage.getItem('idUser'),
      sessionStorage.getItem('idCont'),
    );
    return (
      <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {!loaderWait ? (
        <>
          <div id="activeDomicilioForm"></div>

          {customAdvice && customAdvice.active ? (
            <Starttraining
              title={customAdvice.title}
              text={customAdvice.text}
              hiddeAna={true}
            />
          ) : Domicilio === '' ? (
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Continuemos"
              hora={hora}
            />
          ) : null}
          <TrainingQuestion width="100%" wiw="100%" mt="0">
            <QuestionsDivs
              padd="0"
              txta="left"
              width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form className="">
                <Container
                  width="80%"
                  mt="0px"
                  widthMobile="90%"
                  mtMobile="1rem"
                  displayweb={
                    (orderRutine === currentRoutine && Domicilio === '')
                    || (lapiz[0].routine === 7
                      && lapiz[0].active === 1
                      && editDomicilio !== true)
                    || letsEditDomicilio
                      ? 'flex'
                      : 'none'
                  }
                  displaymobile={
                    (orderRutine === currentRoutine && Domicilio === '')
                    || (lapiz[0].routine === 7
                      && lapiz[0].active === 1
                      && editDomicilio !== true)
                    || letsEditDomicilio
                      ? 'flex'
                      : 'none'
                  }
                >
                  <ImageCoachInicio
                    classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                    topMobile="35px"
                  />
                  <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
                    <TrainingDivSection
                      hew="auto"
                      className="topOne1"
                      paddw="26px"
                      mlw="0%"
                      mtw="8%"
                      wiw="100%"
                      back="#F3F3F3"
                      backw="#F3F3F3"
                      bshaw="none"
                      bsha=""
                      br="4px"
                      padding="20px"
                      wi="100%"
                      he="auto"
                      className={
                        (orderRutine === currentRoutine && Domicilio === '')
                        || (lapiz[0].routine === 7
                          && lapiz[0].active === 1
                          && editDomicilio !== true)
                        || letsEditDomicilio
                          ? ''
                          : 'hidden'
                      }
                    >
                      <QuestionsDivs
                        padd="0"
                        txta="left"
                        width="100%"
                        widthweb="100%"
                        hei="auto"
                        margin="0 auto auto"
                        bc="#F3F3F3"
                      >
                        <form>
                          <div
                            form={form}
                            handleChange={handleChange}
                            className="domicile-container"
                            id="domicileForm"
                          />
                          <TrainingSubtitles
                            size="20px"
                            sizew="20px"
                            fw="600"
                            align="left"
                            alignweb="left"
                            className="cabin"
                          >
                            Ingresa tu domicilio para continuar
                          </TrainingSubtitles>
                          <div>
                            {orderRutine === currentRoutine ? (
                              <InputsOrder
                                handleChangeAddress={handleChangeAddress}
                                handleChangeQuestionsSend={
                                  handleChangeQuestionsSend
                                }
                                handleChangeRutine={handleChangeRutine}
                                orderRutine={orderRutine}
                                step="0-8"
                                handleChangeCatOption={handleChangeCatOption}
                                form={form}
                                handleChange={handleChange}
                                currentTraining={currentTraining}
                              />
                            ) : null}
                          </div>
                          <ErrorDiv
                            className={alertaDom === true ? '' : 'hidden'}
                          >
                            Parece que te falto llenar algún campo
                          </ErrorDiv>
                          <CardsBtn
                            type="button"
                            min_w="100%"
                            min_web="100%"
                            onClick={
                              form.calle === ''
                              || form.numext === ''
                              || form.cp === ''
                              || form.colonia === ''
                                ? () => setAlerta(true)
                                : () => handleChangeDomicile()
                            }
                          >
                            Guardar domicilio
                          </CardsBtn>
                        </form>
                      </QuestionsDivs>
                    </TrainingDivSection>
                  </Col7>
                </Container>
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      <div id="activeAniosEnDomicilio"></div>
      <div id="domicileYears"></div>
      {Domicilio !== '' && TimeDom === '' ? (
        <VideoQuestion idVideo="k6JAB32GuNYGadx19uJ"></VideoQuestion>
      ) : null}
      <div id="irDomicilio"></div>
      <TrainingQuestion
        mt="-190px"
        mtw="-190px"
        index="10"
        pos="relative"
        className={
          ((Domicilio !== '' && TimeDom === '')
            || (lapiz[0].routine === 7 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 7 && lapiz[0].active === 1)
            || editDomicilio === true)
          && editTiempoD !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          {orderRutine === currentRoutine ? (
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="9-9"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              inputVideo={true}
            />
          ) : null}
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeTipoVivienda"></div>
      {TimeDom !== '' && Vivienda === '' ? (
        <VideoQuestion idVideo="k2qKmDgDr2MzJGx19xp"></VideoQuestion>
      ) : null}
      <div id="irVivienda"></div>
      <TrainingQuestion
        mt="-212px"
        mtw="-212px"
        index="10"
        pos="relative"
        className={
          ((TimeDom !== '' && Vivienda === '')
            || (lapiz[0].routine === 7 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 7 && lapiz[0].active === 2)
            || editTiempoD === true)
          && editTipoV !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="10-10"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
            inputVideo={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeTelefonoCasa"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Vivienda !== ''
          && (editTipoV === true
            || !(lapiz[0].routine === 7 && lapiz[0].active === 3))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuál es tu teléfono de casa?(10 dígitos) ☎️"
        hora={hora}
      />
      <Container
        width="80%"
        mt="0"
        widthMobile="90%"
        mtMobile="0"
        displayweb={
          ((Vivienda !== '' && TelCasa === '')
            || (lapiz[0].routine === 7 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 7 && lapiz[0].active === 3)
            || editTipoV === true)
          && editTelFijo !== true
            ? 'flex'
            : 'none'
        }
        displaymobile={
          ((Vivienda !== '' && TelCasa === '')
            || (lapiz[0].routine === 7 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 7 && lapiz[0].active === 3)
            || editTipoV === true)
          && editTelFijo !== true
            ? 'flex'
            : 'none'
        }
      >
        <ImageCoachInicio
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          topMobile="35px"
        />
        <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            mtw="8%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="100%"
            he="auto"
            className="topOne1 topOne1Init"
          >
            <TrainingQuestion
              width="100%"
              wiw="100%"
              mt="0"
              className={
                ((Vivienda !== '' && TelCasa === '')
                  || (lapiz[0].routine === 7 && lapiz[0].active === 4))
                && (!(lapiz[0].routine === 7 && lapiz[0].active === 3)
                  || editTipoV === true)
                && editTelFijo !== true
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsDivs
                padd="0"
                txta="left"
                width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
                widthweb="100%"
                hei="auto"
                margin="0 auto auto"
                bc="#F3F3F3"
              >
                <form>
                  {orderRutine === currentRoutine ? (
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="11-11"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                      activeTelefonoFijo={true}
                    />
                  ) : null}
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="0px"
                    mtop="0px"
                    mtopmd="0px"
                    onClick={() => handleRoutineOrder(currentRoutine)}
                  >
                    Confirmar teléfono
                  </CardsBtn>
                  <CardsBtn
                    min_w="100%"
                    min_web="100%"
                    type="button"
                    onClick={() => handleRoutineOrder(
                      currentRoutine,
                      '',
                      '',
                      'No tengo teléfono fijo',
                    )
                    }
                    color="#EC407A !important"
                    back="#fff !important"
                    border="solid 1px !important"
                  >
                    No tengo teléfono fijo
                  </CardsBtn>
                </form>
              </QuestionsDivs>
            </TrainingQuestion>
          </TrainingDivSection>
        </Col7>
      </Container>
      <div id="activeSigRoutine"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            TelCasa !== '' && lapizTelfijo === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 4 })}
        />
        <CardTwo
          classCard={
            TelCasa !== ''
            && (editTelFijo === true
              || !(lapiz[0].routine === 7 && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={TelCasa}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default DomicileVideo;
