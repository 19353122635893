import React, { Component, Suspense } from 'react';
import Loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';
import '../../../../../node_modules/font-awesome/css/font-awesome.css';
import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';

// componente padre del admin
const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader,
});

class AppAdmin extends Component {
  render() {
    return (
            <Aux>
                {/* <ScrollToTop> */}
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            <Route path="/admin-home/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                {/* </ScrollToTop> */}
            </Aux>
    );
  }
}

export default AppAdmin;
