import React, { useState, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  ContentButton,
  Container,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import '../../assets/styles/TextCustom.css';

const VerificationCode = () => {

  const [isPhone] = useState(false);
  const [expiredCode] = useState(false);

  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="60%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="40px"
              sizeMobile="20px"
              textalingn="center"
              textalingnMobile="center"
            >
              Ingresa el código para crear tu cuenta en Rappi
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#858585"
              textalignMobile="center"
              texttalign="center"
            >
              {isPhone
                ? 'Te enviamos un código al'
                : 'Te enviamos un código al correo'}
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="bold"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#000000"
              textalignMobile="center"
              texttalign="center"
            >
              {isPhone ? '+52 5555555555' : 'exampleMail@test.com'}
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#0CC665"
              textalignMobile="center"
              texttalign="center"
            >
              {isPhone ? '¿Este no es tu número?' : '¿Este no es tu correo?'}
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
              // onSubmit={guardar}
            >
              <TextField
                variant="outlined"
                id="code"
                name="code"
                autoFocus
                required
                label="Código de verificación"
                type="tel"
                inputProps={{
                  type: 'tel',
                  maxLength: 6,
                }}
                className="registro"
                style={{ width: '70%', margin: 'auto' }}
              />
              <ContentButton mt="0" width="70%" mtMobile="0%">
                <Button
                  href=""
                  className="d-block mt-md-4 roboto"
                  mb="1.2rem"
                  mt="0rem"
                  bg="#0CC665"
                >
                  Verificar
                </Button>
              </ContentButton>
              <ParagrapTwo
                className="mx-auto roboto"
                mb="3%"
                mt="5%"
                mbweb="3%"
                fontWeight="normal"
                fontSize="16px"
                fontSizeWeb="18px"
                color="#252525"
                textalignMobile="center"
                texttalign="center"
              >
                Tú código vence en: 00:00
              </ParagrapTwo>
              {expiredCode ? (
                <>
                  <ParagrapTwo
                    className="mx-auto roboto"
                    mb="3%"
                    mt="5%"
                    mbweb="3%"
                    fontWeight="normal"
                    fontSize="16px"
                    fontSizeWeb="18px"
                    color="#252525"
                    textalignMobile="center"
                    texttalign="center"
                  >
                    Tú código ha vencido
                  </ParagrapTwo>
                  <ParagrapTwo
                    className="mx-auto roboto"
                    mb="3%"
                    mt="5%"
                    mbweb="5%"
                    fontWeight="normal"
                    fontSize="16px"
                    fontSizeWeb="18px"
                    color="#0CC665"
                    textalignMobile="center"
                    texttalign="center"
                  >
                    Reenviar
                  </ParagrapTwo>
                </>
              ) : null}
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
};

export default VerificationCode;
