import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

const Cc5Autenticacion = () => {
  const [state, setState] = React.useState({
    bank: '',
  });
  const handleChange = (event) => {
    const { name } = event.target;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const guardar = () => {
    window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/step5-laboral';
  };
  return (
        <Fragment>
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '200px',
            }}>
                <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }} >
                    <InputLabel>Banco de tu Tarjeta actual</InputLabel>
                    <Select
                        native
                        value={state.bank}
                        onChange={handleChange}
                        label="Banco de tu Tarjeta actual"
                        inputProps={{
                          name: 'bank',
                          id: 'outlined-age-native-simple',
                        }}
                    >
                        <option>Elige una opción</option>
                    </Select>
                </FormControl>
                <TextField style={{ margin: '10px', width: '30%' }} label="Últimos 4 dígitos de tu tarjeta" variant="outlined" />
                <Button style={{ margin: '10px', width: '30%' }} onClick={guardar} variant="contained" color="secondary">
                    Continuar
                </Button>
                <Button style={{ margin: '10px', width: '30%' }} onClick={guardar} variant="contained" color="primary">
                    No tengo mi tarjeta a la mano
                </Button>
            </div>
        </Fragment>
  );
};

export default Cc5Autenticacion;
