import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import CarouselP from 'react-elastic-carousel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { UPDATE_SESSION } from '../../mutations';
import GeneralLoader from '../common/GeneralLoader';

import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  Container,
} from '../../assets/styles/Home.styled';
import ModalShare from './ModalShare';
import Clock from '../../assets/img/anacoach/clockTraining.png';

function ListItems({ dynamicInsert, tam, items }) {
  const items2 = items[0];

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5,
      marginTop: '-3px',
    },
    colorPrimary: {
      backgroundColor: '#D6D6D6',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#F64282',
    },
  }))(LinearProgress);

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [open, setOpen] = useState(false);
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const history = useHistory();

  const handleClickTraining = (training, ghosting = false) => {
    sessionStorage.setItem('trainingName', training.name);
    const action = 'clickTraining';
    const tagManagerArgs = {
      dataLayer: {
        event: action.concat(training.name),
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    TagManager.dataLayer(tagManagerArgs);
    const urlRedirect = training.TrainingRedirectUrl
      ? training.TrainingRedirectUrl
      : '#';
    const lastClick = {
      name: training.name,
      timestamp,
    };
    if (ghosting) lastClick.ghosting = true;
    let clicksTraining;
    if (sessionStorage.getItem('clicksTraining')) {
      clicksTraining = JSON.parse(sessionStorage.getItem('clicksTraining'));
      clicksTraining.push(lastClick);
      sessionStorage.setItem('clicksTraining', JSON.stringify(clicksTraining));
    } else {
      clicksTraining = [lastClick];
      sessionStorage.setItem('clicksTraining', JSON.stringify(clicksTraining));
    }
    const dataJson = {
      clickTraining: clicksTraining,
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    if (sessionStorage.getItem('id_session')) updateSession({ variables: mongoUpdateInput });
    if (!ghosting) {
      history.push(urlRedirect);
      setMostrarLoad(false);
    }
  };

  useEffect(() => {
    if (mostrarLoad !== false) {
      handleClickTraining(mostrarLoad, false);
      setMostrarLoad(true);
    }
  }, [mostrarLoad]);

  const Targeta = (post) => (
    <Fragment>
      <ContEntrenamiento
        id={post.post.IDCatTraining}
        background={post.post.TrainingColor}
        widthM="100%!important"
        widthD="100%"
        cursor="pointer"
      >
        <div
          onClick={() => {
            setMostrarLoad(post.post);
          }}
        >
          <ContentTimer
            height=" "
            maxheightMobile="130px"
            heightMobile="115px"
            maxheight="107.250px"
            display="flex"
            top="0%"
            width="100%"
            position=" "
          >
            <TitleEntrenamiento
              className="roboto"
              left="0px"
              margin="auto"
              top=" "
              widthD="230px"
            >
              {post.post.Name}
            </TitleEntrenamiento>
            <Image
              src={post.post.UrlBigImage}
              width="25%"
              widthmob="28.75%"
              margin="auto"
              top="1%"
              bottom="1%"
              right="0px"
            />
          </ContentTimer>
          <ContentTimer>
            <Image src={Clock} width="20px" left="0px" position=" " top="1%" />
            <TextTime className="roboto">
              {post.post.TrainingTime} minutos
            </TextTime>
          </ContentTimer>
        </div>
      </ContEntrenamiento>
      <BorderLinearProgress
        variant="determinate"
        value={post.post.Achievment}
      />
    </Fragment>
  );
  const closeModal = () => {
    setOpen(false);
  };

  const [carruselV] = useState(items2.length > 1);

  return (
    <Fragment>
      <Container
        id="CarruselEmpresasContent"
        width="100%"
        widthMobile="100%"
        mtMobile="0px"
        className={carruselV ? '' : 'WithNoCarrusel'}
      >
        {carruselV ? (
          <CarouselP styled={{ background: '#FAFAFA' }}>
            {items2.map((item, index) => (
              <div
                id="divDivisor"
                key={index + 1}
                style={{ minHeight: '500px', width: '100%' }}
              >
                {item.map((post) => (
                  <Targeta post={post} />
                ))}
              </div>
            ))}
          </CarouselP>
        ) : (
          <Fragment>
            {items2.map((item) => (
              <div style={{ width: '100%' }}>
                {item.map((post) => (
                  <Targeta post={post} />
                ))}
              </div>
            ))}
          </Fragment>
        )}
      </Container>
      {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
      <ModalShare openModal={open} closeModal={closeModal}></ModalShare>
    </Fragment>
  );
}

export default ListItems;
