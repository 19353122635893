import React from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import {
  ImageSticker,
  Products_Main,
  Product,
  Product_Title,
} from '../../assets/styles/Feed.styled';
import { Col12, Container, Button } from '../common/common.styled';
import { CREATE_CLICK } from '../../mutations';
import {
  Content_Carousel,
  Carousel,
} from '../../assets/styles/Home.styled';
import ClickButton from '../../Helpers/HookHelpers';

function ProductsSection({ products }) {
  const { actions } = ClickButton();
  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted() {
        localStorage.setItem('activityDate', new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) createClickFront({ variables: createClickInput });
    TagManager.dataLayer(tagManagerArgs);
  };

  const history = useHistory();
  const handleClick = (item, e) => {
    e.preventDefault();
    actions({ action: 'ClickButtonCoruHome', variables: 'TarjetaDeCréditoExplorar'});
    switch (item.idTrainning) {
      case 35:
        sessionStorage.setItem('idTraining', 35);
        sessionStorage.setItem('currentTraining', JSON.stringify(item));
        history.push('/wo/card-funnel');
        sendMongoAndGTM('ClickExplorCC');
        break;
        case 46:
        sessionStorage.setItem('idTraining', 46);
        sessionStorage.setItem('currentTraining', JSON.stringify(item));
        history.push('/wo/card-funnel-two');
        sendMongoAndGTM('ClickExplorCC');
        break;
      case 9:
        sessionStorage.setItem('idTraining', 9);
        history.push('/wo/loans-funnel');
        sendMongoAndGTM('ClickExplorPL');
        break;
      case 3:
        sessionStorage.setItem('idTraining', 3);
        history.push('/wo/compare-credit-card');
        sendMongoAndGTM('ClickExplorCompareCC');
        break;

      default:
        break;
    }
  };

  const content = products.map((post) => (post.Show ? (
    post.title != null && post.title !== undefined && post.title !== '' ? (
        <Content_Carousel>
          <Carousel widthM=" " widthW="352px">
            <Product key={post.id} onClick={(e) => handleClick(post, e)}>
              <Container width="100%" mtMobile="auto">
                <Col12 ta="center">
                  <ImageSticker
                    src={post.image}
                    width="80px"
                    hei="80px"
                    className="img-fluid"
                    marg="auto"
                    widthw="150px"
                    heightw="95px"
                  />
                </Col12>
                <Col12 ta="center">
                  <Product_Title
                    className="roboto"
                    displayMobile="none"
                    displayWeb="block"
                  >
                    {post.title}
                  </Product_Title>
                </Col12>
                <Col12 ta="center">
                  <Button
                    className="d-block mt-5 mt-md-4 roboto"
                    displayW="block"
                    displayM="none!important"
                    onClick={(e) => handleClick(post, e)}
                  >
                    Explorar
                  </Button>
                </Col12>
              </Container>
            </Product>
            <Product_Title className="roboto" displayWeb="none" align="left">
              {post.title}
            </Product_Title>
          </Carousel>
        </Content_Carousel>
    ) : null
  ) : null));
  return <Products_Main>{content}</Products_Main>;
}
export default ProductsSection;
