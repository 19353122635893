import React from 'react';
import on from '../../assets/img/anacoach/StarOn.png';
import off from '../../assets/img/anacoach/StarOff.png';

const RatingStars = ({ value, setValue, calificado }) => {
  const handleClick = (n) => {
    if (!calificado) {
      setValue(n);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: 'transparent',
        width: '100%',
      }}
    >
      <img
        src={value === 0 ? off : on}
        alt="star"
        style={{
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(1)}
      />
      <img
        src={value < 2 ? off : on}
        alt="star"
        style={{
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(2)}
      />
      <img
        src={value < 3 ? off : on}
        alt="star"
        style={{
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(3)}
      />
      <img
        src={value < 4 ? off : on}
        alt="star"
        style={{
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(4)}
      />
      <img
        src={value < 5 ? off : on}
        alt="star"
        style={{
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(5)}
      />
    </div>
  );
};

export default RatingStars;
