import React from 'react';
import {
  TrainingSubtitles,
  TrainingText,
  CardsBtn,
} from '../../assets/styles/Training.styled';

const PymesCard = ({
  title,
  text,
  subtitle,
  subtitle2,
  subtitle3,
  text1,
  text2,
  text3,
  nextQuestion,
  onClick,
}) => (
    <>
      <TrainingSubtitles
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {title}
      </TrainingSubtitles>

      <TrainingText
        size="16px"
        bot="20px"
        className="roboto"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        {text}
      </TrainingText>
      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {subtitle}
      </TrainingSubtitles>

      <TrainingText
        top="1px"
        bot="20px"
        size="16px"
        className="roboto"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        {text1}
      </TrainingText>

      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {subtitle2}
      </TrainingSubtitles>

      <TrainingText
        top="1px"
        bot="20px"
        size="16px"
        className="roboto"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        {text2}
      </TrainingText>

      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {subtitle3}
      </TrainingSubtitles>

      <TrainingText
        top="1px"
        size="16px"
        className="roboto"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        {text3}
      </TrainingText>

      {nextQuestion === '' ? (
        <div style={{ display: 'flex' }}>
          <CardsBtn
            windthLg="100%"
            mtopw="0"
            mtop="0"
            mtopmd="0"
            onClick={onClick}
          >
            Continuar
          </CardsBtn>
        </div>
      ) : null}
    </>
);

export default PymesCard;
