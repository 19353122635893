/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import { Container, Col7 } from '../../components/common/common.styled';

// Import query components and helpers
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_PRODUCTS_FASTTRACK,
} from '../../queries';
import { SEND_CREDITEA_WL } from '../../mutations';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import CurpVista from './CurpVista';
import { getItemSessionStorage, handleIngresoMensual } from '../../Helpers/general';

const FastTrack = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  fastTrackProducts,
  setFastTrackProducts,
  setActiveScrollProducts,
  currentRoutine,
  nextOrderRoutine,
  setActivarFlujo = '',
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const anchop = window.screen.width;
  const birthDatestr = sessionStorage.getItem('Birthdate').split('-');
  const [ftProducts, setFtProducts] = useState([]);
  // Keep data from user's aswers, useful for validations and for saving data
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [form, setForm] = useState({
    empresa: '',
    billingIncome: '',
    collaborators: '',
    handleCreditCard: '',
  });
  const [company, setCompany] = useState(
    sessionStorage.getItem('Nombre_de_la_empresa') || '',
  );
  const [billing, setbilling] = useState(
    sessionStorage.getItem('FacturaciónEmpresa') || '',
  );
  const [collaborator, setCollaborator] = useState(
    sessionStorage.getItem('NumeroColaboradores') || '',
  );
  const [creditCard, setCreditCard] = useState(
    sessionStorage.getItem('OtroTipoPrestamoTarjeta') || '',
  );
  const { sendMessage } = SendNotificationFTAndCC();

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [curpValidado, setCurpValidado] = useState(true);

  const [usoDinero, setUsoDinero] = useState(
    sessionStorage.getItem('UsoDinero') || '',
  );
  const [dIneroNecesidad, setDIneroNecesidad] = useState(
    sessionStorage.getItem('DIneroNecesidad') || '',
  );
  const [tiempoParaPagarPrestamo, setTiempoParaPagarPrestamo] = useState(
    sessionStorage.getItem('TiempoParaPagarPrestamo') || '',
  );

  const [otroTipoPrestamoTarjeta] = useState(
    sessionStorage.getItem('ingresoMostrar') || '',
  );
  const [ingreso, SetIngreso] = useState(
    sessionStorage.getItem('ingresoMostrar')
    || sessionStorage.getItem('Ingreso_Mensual_Fijo')
    || '',
  );
  const [ocupacion, setOcupacion] = useState(
    sessionStorage.getItem('Ocupacion') || '',
  );
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editUsoDinero, setEditUsoDinero] = useState('');
  const [editDineroNecesidad, setEditDineroNecesidad] = useState('');
  const [editTiempoParaPagarPrestamo, setEditTiempoParaPagarPrestamo] = useState('');
  const [editOtroTipoPrestamoTarjeta, setEditOtroTipoPrestamoTarjeta] = useState('');
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    setQuestions([]);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }

    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };
  const ValidColaboradores = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const colaboradores = document.getElementById(id);
    if (colaboradores === null) {
      console.log('null');
    } else if (name && !/^[0-9]+$/i.test(value)) {
      colaboradores.className = 'textInput inputs-bottom invalidInput';
    } else {
      colaboradores.className = 'textInput inputs-bottom validInput';
    }
  };

  const validateCantidad = (name, value) => {
    const elemname = document.getElementsByName(name)[1];

    if (elemname === null) {
      console.log('null');
    } else if (elemname === undefined) {
      const elemname2 = document.getElementsByName(name)[0];
      if (
        value.length >= 1
        && value.length <= 16
        && value !== '$'
        && /[0-9]+$/i.test(form.billingIncome)
      ) {
        elemname2.className = 'textInput inputs-bottom validInput';
      } else {
        elemname2.className = 'textInput inputs-bottom invalidInput';
      }
    } else if (
      value.length >= 1
      && value.length <= 16
      && value !== '$'
      && /[0-9]+$/i.test(form.billingIncome)
    ) {
      elemname.className = 'textInput inputs-bottom validInput';
    } else {
      elemname.className = 'textInput inputs-bottom invalidInput';
    }
  };

  const handleChange = (e, question = '') => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'billingIncome') {
      let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
      value = filterFloat(value);
      setForm({
        ...form,
        [e.target.name]: `$${formatterMx(filterFloat(value))}`,
      });
      validateCantidad(e.target.name, e.target.value, e.target.id);
    } else if (e.target.name === 'collaborators') {
      ValidColaboradores(e.target.name, e.target.value, e.target.id);
    }
  };

  const setNextOrderRoutine = () => {
    setFastTrackProducts(ftProducts);
    setActiveScrollProducts(true);
    setOrderRutine(nextOrderRoutine);
  };

  const applyBusinessRules = () => {
    const values = {
      usoDinero,
      dIneroNecesidad,
      tiempoParaPagarPrestamo,
    };
    return values;
  };

  const [getProductsFastTrack] = useLazyQuery(GET_PRODUCTS_FASTTRACK, {
    onCompleted({ getProductsFastTrack }) {
      if (getProductsFastTrack.statusCode === 200) {
        setFtProducts(JSON.parse(getProductsFastTrack.response));
      }
    },
  });

  useEffect(() => {
    if (ftProducts.length > 0) {
      // getDataCreditea();
      setNextOrderRoutine();
    }
  }, [ftProducts])
  

  const getProducts = () => {
    const {
      usoDinero: uso,
      dIneroNecesidad: necesidad,
      tiempoParaPagarPrestamo: tiempo,
    } = applyBusinessRules();
    getProductsFastTrack({
      variables: {
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        uso,
        necesidad,
        tiempo,
      },
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('overwrite_WO_29') !== 'true'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone'),
      trigger: 'notificacion_pl_abandonopagina',
    });
  }, []);

  useEffect(() => {
    if (
      recoveryRoutine === 'activa'
      && usoDinero !== ''
      && dIneroNecesidad !== ''
      && tiempoParaPagarPrestamo !== ''
      && otroTipoPrestamoTarjeta !== ''
      && ocupacion !== ''
    ) {
      setDIneroNecesidad(sessionStorage.getItem('DIneroNecesidad') || '');
      SetIngreso(sessionStorage.getItem('ingresoMostrar') || '');
      getProducts();
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    if (
      tiempoParaPagarPrestamo !== ''
      && ocupacion !== ''
      && ocupacion !== 'Emprendedor'
    ) {
      getProducts();
    }
  }, [tiempoParaPagarPrestamo]);

  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setEditUsoDinero(true);
        setUsoDinero(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#ActiveDineroNecesidad' });
        break;
      case 1:
        setEditDineroNecesidad(true);
        setDIneroNecesidad(val.value);
        gsap.to(window, {
          duration: 1,
          scrollTo: '#ActiveTiempoParaPagarPrestamo',
        });
        break;
      case 2:
        setEditTiempoParaPagarPrestamo(true);
        setTiempoParaPagarPrestamo(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#ActiveOtroTipoPrestamo' });
        break;
      case 3:
        setEditOtroTipoPrestamoTarjeta(true);
        SetIngreso(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#ActiveOcupacion' });
        break;
      case 4:
        setOcupacion(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#ActiveEsEmprendedor' });
        if (val.value !== 'Emprendedor') {
          getProducts();
        }
        break;
      case 8:
        setCreditCard(val.value);
        getProducts();
        break;
      default:
        console.log('default');
        break;
    }
  };

  const handleChangeIcon = (json) => {
    setEditUsoDinero(false);
    setEditDineroNecesidad(false);
    setEditTiempoParaPagarPrestamo(false);
    setEditOtroTipoPrestamoTarjeta(false);
    setLapiz([json]);
  };

  const handleCompay = (event) => {
    event.preventDefault();
    setCompany(form.empresa);
    gsap.to(window, { duration: 1, scrollTo: '#ActiveBillingCompany' });
    createJsonInput(Rutine, questions);
    setQuestions([]);
  };

  const handleBilling = (event) => {
    event.preventDefault();
    setbilling(form.billingIncome);
    questions.push({
      id: 'FacturacionEmpresa',
      question: 7,
      value: form.billingIncome,
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#ActiveCtnColaboradores' });
  };

  const handleCollaborator = (event) => {
    event.preventDefault();
    setCollaborator(form.collaborators);
    questions.push({
      id: 'colaboradores',
      question: 8,
      value: form.collaborators,
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#ActiveCreditCard' });
  };

  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (usoDinero === ''
          || (lapiz[0].routine === 4 && lapiz[0].active === 1))
        && editUsoDinero !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#ActiveUsoDelDinero' });
        setReviewScroll(false);
      }
    }, 1000);
  }, []);

  return (
    <Fragment>
      {/* <CurpVista
        nombreCompleto={`${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem('MiddleName')}`}
        apPaterno={sessionStorage.getItem('FathersLastName')}
        apMaterno={sessionStorage.getItem('MothersLastName')}
        genero={sessionStorage.getItem('Gender')}
        estado={sessionStorage.getItem('BirthState')}
        fechaNacimiento={sessionStorage.getItem('Birthdate') ? `${birthDatestr[2]}/${birthDatestr[1]}/${birthDatestr[0]}` : ''}
        curpValidado={curpValidado}
        setCurpValidado={setCurpValidado}
      /> */}
      {curpValidado ? (
        <>

          {/* pregunta número uno */}
          <div id="ActiveUsoDelDinero"></div>
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (usoDinero === ''
                || (lapiz[0].routine === 4 && lapiz[0].active === 1))
                && editUsoDinero !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="ActiveDineroNecesidad"></div>
          <CardTwo
            classCard={
              usoDinero !== ''
                && (editUsoDinero === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={usoDinero}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={
                usoDinero !== '' && dIneroNecesidad === ''
                  ? 'iconEditCommon'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
            />
          </CardTwo>
        </>
      ) : null}
      {/* pregunta número dos */}
      {usoDinero !== ''
        && (editUsoDinero === true
          || !(lapiz[0].routine === 4 && lapiz[0].active === 1)) ? (
        <QuestionsOrder
          classCard={usoDinero !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
      ) : null}
      <TrainingQuestion
        className={
          ((usoDinero !== '' && dIneroNecesidad === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 2))
            && (!(lapiz[0].routine === 4 && lapiz[0].active === 1)
              || editUsoDinero === true)
            && editDineroNecesidad !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="ActiveTiempoParaPagarPrestamo"></div>
      <CardTwo
        classCard={
          dIneroNecesidad !== ''
            && (editDineroNecesidad === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={dIneroNecesidad}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            dIneroNecesidad !== '' && tiempoParaPagarPrestamo === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
        />
      </CardTwo>

      {/* pregunta número tres */}
      {dIneroNecesidad !== ''
        && (editDineroNecesidad === true
          || !(lapiz[0].routine === 4 && lapiz[0].active === 2)) ? (
        <QuestionsOrder
          classCard={dIneroNecesidad !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
      ) : null}
      <TrainingQuestion
        className={
          ((dIneroNecesidad !== '' && tiempoParaPagarPrestamo === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 3))
            && (!(lapiz[0].routine === 4 && lapiz[0].active === 2)
              || editDineroNecesidad === true)
            && editTiempoParaPagarPrestamo !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="ActiveOtroTipoPrestamo"></div>
      <CardTwo
        classCard={
          tiempoParaPagarPrestamo !== ''
            && (editTiempoParaPagarPrestamo === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 3))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={tiempoParaPagarPrestamo}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            tiempoParaPagarPrestamo !== '' && ingreso === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
        />
      </CardTwo>

      {/* pregunta número cuatro */}
      {tiempoParaPagarPrestamo !== ''
        && (editTiempoParaPagarPrestamo === true
          || !(lapiz[0].routine === 4 && lapiz[0].active === 3)) ? (
        <QuestionsOrder
          classCard={tiempoParaPagarPrestamo !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
      ) : null}
      <TrainingQuestion
        className={
          ((tiempoParaPagarPrestamo !== '' && ingreso === '')
            || (lapiz[0].routine === 4 && lapiz[0].active === 4))
            && (!(lapiz[0].routine === 4 && lapiz[0].active === 3)
              || editTiempoParaPagarPrestamo === true)
            && editOtroTipoPrestamoTarjeta !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="3-3"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="ActiveOcupacion"></div>
      <CardTwo
        classCard={
          ingreso !== ''
            && tiempoParaPagarPrestamo !== ''
            && (editOtroTipoPrestamoTarjeta === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 4))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={ingreso}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            ingreso !== '' && orderRutine < 4 ? 'iconEditCommon' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 4 })}
        />
      </CardTwo>

      {/* Nuevas pregutnas */}

      <QuestionsOrder
        classCard={
          ingreso !== '' && tiempoParaPagarPrestamo !== ''
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={4}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ingreso !== '' && tiempoParaPagarPrestamo !== '' && ocupacion === ''
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="4-4"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="ActiveEsEmprendedor"></div>
      <CardTwo
        classCard={
          ocupacion !== '' && tiempoParaPagarPrestamo !== ''
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={ocupacion}
        colorTraining={trainingColor}
      />

      {ocupacion === 'Emprendedor' && tiempoParaPagarPrestamo !== '' ? (
        <>
          <QuestionsOrder
            classCard={ocupacion !== '' ? 'cardOne' : 'hidden'}
            orderRutine={currentRoutine}
            step={5}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            width="100%"
            wiw="100%"
            mt="0"
            className={ocupacion !== '' && company === '' ? '' : 'hidden'}
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form className="form-web">
                <Fragment>
                  <Container
                    mlMobile="auto"
                    mleft="18%"
                    width="80%"
                    mt="0"
                    widthMobile="90%"
                    mtMobile="0"
                  >
                    <Col7
                      maxwidth="100%"
                      padding=" "
                      className="cardPrincipal1"
                    >
                      <TrainingDivSection
                        hew="auto"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                        className="topOne1 topOne1Init"
                      >
                        <Fragment>
                          <InputsOrder
                            handleChangeRutine={handleChangeRutine}
                            orderRutine={currentRoutine}
                            step="5-5"
                            handleChangeQuestionsSend={
                              handleChangeQuestionsSend
                            }
                            handleChangeCatOption={handleChangeCatOption}
                            form={form}
                            handleChange={handleChange}
                            currentTraining={currentTraining}
                          />
                          <CardsBtn
                            type="button"
                            mtopw="0px"
                            min_w="100%"
                            min_web="100%"
                            max_w="100%"
                            onClick={(e) => handleCompay(e)}
                          >
                            Continuar
                          </CardsBtn>
                        </Fragment>
                      </TrainingDivSection>
                    </Col7>
                  </Container>
                </Fragment>
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
          <div id="ActiveBillingCompany"></div>
          <CardTwo
            classCard={company !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={company}
            colorTraining={trainingColor}
          />

          <QuestionsOrder
            classCard={company !== '' ? 'cardOne' : 'hidden'}
            orderRutine={currentRoutine}
            step={6}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            width="100%"
            wiw="100%"
            mt="0"
            className={company !== '' && billing === '' ? '' : 'hidden'}
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form className="form-web">
                <Fragment>
                  <Container
                    mlMobile="auto"
                    mleft="18%"
                    width="80%"
                    mt="0"
                    widthMobile="90%"
                    mtMobile="0"
                  >
                    <Col7
                      maxwidth="100%"
                      padding=" "
                      className="cardPrincipal1"
                    >
                      <TrainingDivSection
                        hew="auto"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                        className="topOne1 topOne1Init"
                      >
                        <Fragment>
                          <InputsOrder
                            handleChangeRutine={handleChangeRutine}
                            orderRutine={currentRoutine}
                            step="6-6"
                            handleChangeQuestionsSend={
                              handleChangeQuestionsSend
                            }
                            handleChangeCatOption={handleChangeCatOption}
                            form={form}
                            handleChange={handleChange}
                            currentTraining={currentTraining}
                            labelInput="Ingresar monto de facturación"
                          />
                          <CardsBtn
                            type="button"
                            mtopw="0px"
                            min_w="100%"
                            min_web="100%"
                            max_w="100%"
                            onClick={(e) => handleBilling(e)}
                          >
                            Continuar
                          </CardsBtn>
                        </Fragment>
                      </TrainingDivSection>
                    </Col7>
                  </Container>
                </Fragment>
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
          <div id="ActiveCtnColaboradores"></div>
          <CardTwo
            classCard={billing !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={billing}
            colorTraining={trainingColor}
          />
          <QuestionsOrder
            classCard={billing !== '' ? 'cardOne' : 'hidden'}
            orderRutine={currentRoutine}
            step={7}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            width="100%"
            wiw="100%"
            mt="0"
            className={billing !== '' && collaborator === '' ? '' : 'hidden'}
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form className="form-web">
                <Fragment>
                  <Container
                    mlMobile="auto"
                    mleft="18%"
                    width="80%"
                    mt="0"
                    widthMobile="90%"
                    mtMobile="0"
                  >
                    <Col7
                      maxwidth="100%"
                      padding=" "
                      className="cardPrincipal1"
                    >
                      <TrainingDivSection
                        hew="auto"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                        className="topOne1 topOne1Init"
                      >
                        <Fragment>
                          <InputsOrder
                            handleChangeRutine={handleChangeRutine}
                            orderRutine={currentRoutine}
                            step="7-7"
                            handleChangeQuestionsSend={
                              handleChangeQuestionsSend
                            }
                            handleChangeCatOption={handleChangeCatOption}
                            form={form}
                            handleChange={handleChange}
                            currentTraining={currentTraining}
                            labelInput="Número de colaboradores"
                          />
                          <CardsBtn
                            type="button"
                            mtopw="0px"
                            min_w="100%"
                            min_web="100%"
                            max_w="100%"
                            onClick={(e) => handleCollaborator(e)}
                          >
                            Continuar
                          </CardsBtn>
                        </Fragment>
                      </TrainingDivSection>
                    </Col7>
                  </Container>
                </Fragment>
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
          <div id="ActiveCreditCard"></div>
          <CardTwo
            classCard={collaborator !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={collaborator}
            colorTraining={trainingColor}
          />

          <QuestionsOrder
            classCard={collaborator !== '' ? 'cardOne' : 'hidden'}
            orderRutine={currentRoutine}
            step={8}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={collaborator !== '' && creditCard === '' ? '' : 'hidden'}
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="8-8"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={creditCard !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={creditCard}
            colorTraining={trainingColor}
          />
        </>
      ) : null}
      {/* {scrollToElem("avance")} */}
    </Fragment>
  );
};

export default FastTrack;
