import React, { useState, useEffect } from 'react';
import Minus from '../../assets/img/anacoach/Minus.svg';
import More from '../../assets/img/anacoach/More.svg';
import { Image } from './common.styled';
import '../../assets/styles/perfil/perfiles.css';
import {
  DivSelectionCC,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';

function BarFilterBank({ children, text, disableBtn }) {
  const [changeStatus, setChangeStatus] = useState(true);
  useEffect(() => {
    setChangeStatus(true);
  }, [text]);

  return (
        <TrainingQuestion width="100%">
            <DivSelectionCC onClick={() => setChangeStatus(!changeStatus)}>
                {text}
                {!disableBtn ?<Image className='imageSection1' zindex="1" cursor='pointer' 
                  src={changeStatus ? Minus : More} width="20px" marginRight="0" marginRightM="0" />
                : ""}
            </DivSelectionCC>
            {changeStatus ? (children) : null}
        </TrainingQuestion>
  );
}
export default BarFilterBank;
