import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Container, ParagrapTwo } from '../common/common.styled';
import primerainversion from '../../assets/img/anacoach/inversion1.svg';
import testInversion from '../../assets/img/anacoach/TestInversion.svg';
import catalogoInversion from '../../assets/img/anacoach/catalogoIn.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    {
      label: 'Tu primera inversión',
      url: primerainversion,
      color: '#6BD5AF',
    },
    {
      label: 'Test de inversión',
      url: testInversion,
      color: '#91D770',
    },
    {
      label: 'Catálogo de inversiones',
      url: catalogoInversion,
      color: '#99F2A8',
    },
  ];
}

function TrainingGroups({ items }) {
  const classes = useStyles();
  const [activeStep] = React.useState(0);
  const steps = getSteps();

  return (
        <Container width="100%" mt="0rem" widthMobile="100%" mb="0%" mtMobile="0rem">
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical" id="stepper" style={{ backgroundColor: 'transparent' }}>
                    {steps.map((item) => (
                    <Step key={item.label}>
                        <StepLabel>
                            <div className="row widthRow">
                                <div className="col-3" style={{ padding: '2%', backgroundColor: item.color, textAlign: 'center' }}>
                                    <img src={item.url} style={{ width: '50px', margin: 'auto' }}/>
                                </div>
                                <div className="col-8" style={{ padding: '2%', display: 'flex' }}>
                                    <ParagrapTwo fontSize="15px" className="mx-auto roboto text-justify" mb="auto" mt="auto" mbweb="auto" fontWeight="bold" textalignMobile="left">
                                        {item.label}
                                    </ParagrapTwo>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </div>
        </Container>
  );
}

export default TrainingGroups;
