import React, { useState, Fragment } from 'react';
// radio button
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NVD3Chart from 'react-nvd3';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import gymOn from '../../assets/img/anacoach/WO.svg';
import vectorTop from '../../assets/img/anacoach/vectorTop.svg';
import { filterFloat } from '../../Helpers';
import logoCoru from '../../assets/img/anacoach/logoCoruGrafico.png';
import LineChart from '../../components/common/LineChart';
import {
  CardsBtn,
  TrainingSectionGray,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import {
  Image,
  ParagrapTwo,
  Container,
} from '../../components/common/common.styled';
import {
  Rectangle13,
  UiCard,
  TextoCoach,
} from '../../components/common/Home.styled';
import { ImageSticker } from '../../assets/styles/Feed.styled';
// modal
import iconoavatar from '../../assets/img/anacoach/Login.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '550px',
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: '100px',
    height: '120px',
    border: '2.7px solid #F64282',
    borderRadius: '35%',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const SmallAvatar = withStyles(() => ({
  root: {
    width: 45,
    height: 45,
    marginBottom: '5%',
    cursor: 'pointer',
    marginTop: '90%',
    backgroundColor: '#F64282!important',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    color: '#fff!important',
    border: '2px solid #F64282 !important',
  },
}))(Avatar);
const ModalProfile = ({
  open,
  setOpen,
  portafolio,
  validity,
  gainsTotal,
  instruments,
}) => {
  const tamScreen = window.screen.width;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [avatarSelect] = useState(iconoavatar);
  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };
  const styles = {
    fontSize: '18px',
  };
  const styles2 = {
    fontStyle: 'normal',
    fontSize: '15px',
    fontWeight: 'normal',
    lineHeight: '120%',
    textAlign: 'justify',
    color: 'black',
  };
  const handleOpenModal = () => {
    const objO = document.getElementById('avatarfile');
    objO.click();
  };

  const handleChange = () => {
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <UiCard style={{ height: '110px', marginBottom: '0px' }} positionM=" ">
          <Rectangle13
            style={{ background: '#E5E5E5', textAlign: 'justify' }}
            margin="auto"
            bottomM="0px"
            displayM="flex"
            position=" "
            topM="0px"
            height="110px"
            width="100%"
            widthm="100%"
            padding="19px 25px 20px 21px"
          >
            <TextoCoach style={{ margin: '0' }} className="roboto" width="auto">
              <b>Detalle de inversión</b>
              <br />
              <br />
              <b>Portafolio {portafolio}</b> <br />
              {validity}
            </TextoCoach>
          </Rectangle13>
        </UiCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '20px',
          }}
        >
          <div>
            <Badge
              id="ContainerImage"
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <SmallAvatar alt="Remy Sharp" src="" onClick={handleOpenModal}>
                  <CameraAltIcon color="#ffff" />
                </SmallAvatar>
              }
            >
              <Avatar
                alt="Travis Howard"
                src={avatarSelect}
                className={classes.large}
              />
            </Badge>
            <input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              id="avatarfile"
              name="avatarfile"
              className="noDisplay"
              onChange={handleChange}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'column',
              textAlign: 'justify',
            }}
          >
            <p style={styles}>Corupesos invertidos</p>
            <p style={styles}>$1,000,000</p>
            <p style={styles}>Saldo actual</p>
            <p style={styles}>{1000000 + gainsTotal}</p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '15px',
          }}
        >
          <div>
            <p>
              <b>Instrumento</b>
            </p>
            {instruments.map((item, index) => (
              <p key={index} style={styles2}>
                {item.name}
              </p>
            ))}
            <hr
              style={{
                background: 'black',
                width: tamScreen > 1200 ? '375%' : '375%',
              }}
            />
            <p style={{ color: 'black', textAlign: 'center' }}>Total</p>
          </div>
          <div>
            <p>
              <b>Inversión</b>
            </p>
            {instruments.map((item, index) => (
              <p key={index} style={styles2}>
                {item.amount}
              </p>
            ))}
            <br />
            <p style={{ color: 'black' }}>$1,000,000</p>
          </div>
          <div>
            <p>
              <b>Rendimiento</b>
            </p>
            {instruments.map((item, index) => (
              <p
                key={index}
                style={{
                  fontStyle: 'normal',
                  fontSize: '15px',
                  fontWeight: 'normal',
                  lineHeight: '120%',
                  color: item.gainOrLoss > 0 ? '#0AD321' : '#F82B2B',
                }}
              >
                {item.gainOrLoss > 0 ? `+${item.gainOrLoss}` : item.gainOrLoss}
              </p>
            ))}
            <br />
            <p style={{ color: gainsTotal > 0 ? '#0AD321' : '#F82B2B' }}>
              {gainsTotal > 0
                ? `+${Math.round(gainsTotal)}`
                : `-${Math.round(gainsTotal)}`}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const InvestmentDetails = ({
  value,
  handleRadioChange,
  investmentDetails,
  queryPortafolio,
  showGraph,
  vigencia,
  gainsTotal,
  data, // grafica lineal
  legend,
  colors,
  datum, // grafica dona
  instruments,
  setOpen,
  open,
  investmentDetailsByPort,
  friendsRanking,
  allUsersRanking,
  setGoFeed,
}) => {
  const styles = {
    fontStyle: 'normal',
    fontSize: '15px',
    fontWeight: 'normal',
    lineHeight: '120%',
  };
  const stylesCircle = {
    height: '95px',
    width: '140px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#CDCDCD',
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return (
    <Fragment>
      <RadioGroup
        aria-label="quiz"
        name="quiz"
        value={value}
        onChange={handleRadioChange}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: '40px',
        }}
      >
        {investmentDetails.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.portafolio}
            control={<Radio />}
            label={
              <p style={{ fontFamily: 'Cabin', fontWeight: 'bold' }}>
                {item.portafolio} <br /> del {item.instruments[0].start} al{' '}
                {item.instruments[0].validity}
              </p>
            }
            labelPlacement="start"
            style={{
              borderRadius: '5px',
              width: '91%',
              marginRight: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              boxShadow: '0px 2px 48px rgba(0, 0, 0, 0.2)',
            }}
          />
        ))}
      </RadioGroup>
      <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <br />
            <CardsBtn
              onClick={(e) => queryPortafolio(e)}
              style={{
                borderRadius: '4px',
                letterSpacing: '-0.3px',
                lineHeight: '140%',
                fontWeight: 'bold',
                fontStyle: 'normal',
              }}
              min_w="95%"
              min_web="350px"
              mtopw="20px"
              mleft="10px"
              mtop="15px"
              weight="normal!important"
            >
              Consultar
            </CardsBtn>
            <br />
          </div>
        </Container>
      </TrainingSectionGray>

      {showGraph ? (
        <Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '30px',
              marginBottom: '-30px',
            }}
          >
            <p
              style={{ fontFamily: 'Cabin', fontSize: '20px', fontWeight: 700 }}
            >
              Portafolio {value}
            </p>
            <p
              style={{ fontSize: '16px', fontWeight: 400, marginTop: '-15px' }}
            >
              {vigencia}
            </p>
            <br />
            <p
              style={{ fontFamily: 'Cabin', fontSize: '20px', fontWeight: 700 }}
            >
              Ganancia Total:{' '}
              {formatter.format(filterFloat(Math.round(gainsTotal)))}
            </p>
          </div>
          <LineChart data={data} legend={legend} colors={colors} />
          <hr style={{ width: '100%' }} />
          <TrainingDivSection
            mt="100px"
            hew="auto"
            paddw="26px"
            mlw="1%"
            mtw="20%"
            back=" "
            backw=" "
            bshaw="none"
            bsha=""
            br="4px"
            padding="10px"
            wi="90%"
            he="auto"
            mb="38px"
            wiw="100%"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px',
              }}
            >
              <Image
                src={logoCoru}
                mwidth="35%"
                width="18%"
                margin="auto"
                top="110px"
                left="10%"
                mleft="0"
                mtop="75px"
              />
            </div>
            <NVD3Chart
              id="chart"
              showLegend={false}
              height={300}
              type="pieChart"
              datum={datum}
              x="key"
              y="y"
              donut
              labelType="none"
              containerStyle={{
                marginTop: '-185px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <Image
              src={vectorTop}
              mwidth="5%"
              marginLeft="47%"
              width="4%"
              margin="auto"
              top="110px"
              left="10%"
              mleft="0"
              mtop="75px"
            />
            <div
              onClick={() => setOpen(true)}
              style={{
                textAlign: 'center',
                fontSize: '18px',
                marginBottom: '50px',
                cursor: 'pointer',
              }}
            >
              Ver detalle
            </div>
            <ModalProfile
              key="modal"
              open={open}
              setOpen={setOpen}
              portafolio={value}
              validity={vigencia}
              gainsTotal={gainsTotal}
              instruments={instruments}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '25px',
              }}
            >
              <div style={{ marginTop: '-12px' }}>
                {investmentDetailsByPort.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      background: item.color,
                      width: '25px',
                      height: '25px',
                      borderRadius: '4px',
                      marginTop: '10px',
                    }}
                  ></div>
                ))}
              </div>
              <div>
                {investmentDetailsByPort.map((item, index) => (
                  <p key={index} style={styles}>
                    {item.name}
                  </p>
                ))}
              </div>
              <div>
                {investmentDetailsByPort.map((item, index) => (
                  <p key={index} style={styles}>
                    {item.amount}
                  </p>
                ))}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '50px',
              }}
            >
              <div style={stylesCircle}>
                <div
                  style={{
                    border: '5px solid #F64282',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {friendsRanking.length > 0 ? friendsRanking.length : '0'}
                </div>
                <p>Ranking amigos</p>
              </div>
              <div style={stylesCircle}>
                <div
                  style={{
                    border: '5px solid #6BD5AF',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {allUsersRanking.length > 0 ? allUsersRanking.length : '0'}
                </div>
                <p>Ranking global</p>
              </div>
            </div>
            <CardsBtn
              min_w="70%"
              min_web="50%"
              mleft="15%"
              mright="15%"
              mleftweb="25%"
              mrightweb="25%"
              mtop="70px"
              mtopw="70px"
              type="button"
              onClick={() => setGoFeed(true)}
              color="#EC407A !important"
              back="#fff !important"
              border="solid 1px !important"
              padd="8px 15px 4px"
              heigth="49px"
              className=""
            >
              <ImageSticker
                marg="-5px 20px auto auto"
                width="35px"
                src={gymOn}
              />
              <b>Entrenamientos</b>
            </CardsBtn>
          </TrainingDivSection>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default InvestmentDetails;
