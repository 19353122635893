import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import TagManager from 'react-gtm-module';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  Container,
  Col3,
  ContentButton,
} from '../../components/common/common.styled';
import '../../assets/styles/WelcomeValidation.css';
import '../../assets/styles/TextCustom.css';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import { getQueryVariable } from '../../Helpers';
import {
  LOGIN,
  UPDATE_USERS,
  VALIDATE_250OK,
  CREATE_CLICK,
  UPDATE_SESSION,
  UPDATE_ID_USER_COLLABORATIVE,
} from '../../mutations';

function PasswordNew() {
  const [form, setForm] = useState({
    pass1: '',
    pass2: '',
    pass3: '',
    pass4: '',
  });
  const history = useHistory();
  const [error] = useState({
    pass1: false,
    pass2: false,
    pass3: false,
    pass4: false,
  });

  const [btn, setBtn] = useState(true);
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      today = `${mm}-${dd}-${yyyy}`;
      const dataJson = {
        email: sessionStorage.getItem('Email1'),
        timestamp: today,
        IDUser: login.idUser,
        idSession: login.idSession,
      };
      const filterJson = {
        _id: sessionStorage.getItem('id_session'),
      };
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify(filterJson);
      const mongoUpdateInput = {
        input: {
          collection: 'sessions',
          data: dataString,
          filter: filterString,
        },
      };
      if (sessionStorage.getItem('id_session')) {
        updateSession({ variables: mongoUpdateInput });
      }

      sessionStorage.setItem('token', login.token);
      sessionStorage.setItem('FirstName', login.firstName);
      sessionStorage.setItem('MiddleName', login.middleName);
      sessionStorage.setItem('FathersLastName', login.fathersLastName);
      sessionStorage.setItem('MothersLastName', login.mothersLastName);
      sessionStorage.setItem('idUser', login.idUser);
      sessionStorage.setItem('idCont', login.idCont);
      sessionStorage.setItem('idDatSession', login.idSession);
      sessionStorage.setItem('idCatRelAction', login.idCatRelAction);
      sessionStorage.setItem('ProfilePicture', login.ProfilePicture);
      localStorage.setItem('token', login.token);
      localStorage.setItem('FirstName', login.firstName);
      localStorage.setItem('MiddleName', login.middleName);
      localStorage.setItem('FathersLastName', login.fathersLastName);
      localStorage.setItem('MothersLastName', login.mothersLastName);
      localStorage.setItem('idUser', login.idUser);
      localStorage.setItem('idCont', login.idCont);
      localStorage.setItem('idDatSession', login.idSession);
      localStorage.setItem('idCatRelAction', login.idCatRelAction);
      localStorage.setItem('ProfilePicture', login.ProfilePicture);
      sessionStorage.setItem('session', true);
      sessionStorage.setItem('PrimerVis', true);
      sessionStorage.setItem('showModal', false);
      sessionStorage.setItem('DataPerson', true);

      if (login.Gender != null) {
        sessionStorage.setItem('Gender', login.Gender);
      }
      history.push('/wo/first-investment');
    },
  });

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });
  const LPpageplanes = sessionStorage.getItem('LPpageplanes') === 'true';
  const [updateIdUserCollaborative] = useMutation(
    UPDATE_ID_USER_COLLABORATIVE,
    {
      onCompleted({ updateIdUserCollaborative }) {
        if (updateIdUserCollaborative.statusCode === 200) {
          history.push('/wo/collaborative-success');
        }
      },
    },
  );
  const [dynamicUpdate] = useMutation(UPDATE_USERS, {
    onCompleted({ dynamicUpdate }) {
      sessionStorage.setItem('showModal', false);
      if (!LPpageplanes) {
        const fromCollaborative = sessionStorage.getItem('fromCollaborative');
        if (fromCollaborative === 'true') {
          const update = {
            idUser: String(sessionStorage.getItem('idUser')),
            idDatTraining: String(
              sessionStorage.getItem('idDatTrainingCollaborative'),
            ),
            phone: String(sessionStorage.getItem('CellPhone')),
          };
          updateIdUserCollaborative({ variables: update });
        } else {
          sessionStorage.setItem('startProfiling', false);
          history.push('/wo/profiling-2');
        }
      } else {
        let profile = 'cliente';
        if (String(sessionStorage.getItem('email')).indexOf('@test') != -1) {
          sessionStorage.setItem('typeUser', 'test');
          localStorage.setItem('typeUser', 'test');
          profile = 'test';
        }
        if (sessionStorage.getItem('email') === 'fertdemo@coru.com') {
          sessionStorage.setItem('Email1', 'fernando@coru.com');
        } else {
          sessionStorage.setItem('Email1', sessionStorage.getItem('email'));
          localStorage.setItem('Email1', sessionStorage.getItem('email'));
        }

        const source = getQueryVariable('utm_source');
        const medium = getQueryVariable('utm_medium');
        const campaign = getQueryVariable('utm_campaign');
        const inputLogin = {
          input: {
            email: sessionStorage.getItem('email'),
            password: form.pass1 + form.pass2 + form.pass3 + form.pass4,
            typeUser: profile,
            IDGA: sessionStorage.getItem('ID_GA'),
            source,
            medium,
            campaign,
            hostName: window.location.hostname,
            pageName: 'Login',
          },
        };
        try {
          login({
            variables: inputLogin,
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
  });

  const [validateMail250Ok] = useMutation(VALIDATE_250OK, {
    onCompleted({ validateMail250Ok }) {
      try {
        console.log('validacion250ok');
      } catch (error) {
        console.log(error);
      }
    },
  });

  const ebtn = () => {
    if (
      form.pass1.length === 1
      && !error.pass1
      && form.pass2.length === 1
      && !error.pass2
      && form.pass3.length === 1
      && !error.pass3
    ) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
    });
    const { name, value } = e.target;
    switch (name) {
      case 'pass1':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass2']").focus();
        } else {
          return false;
        }
        break;
      case 'pass2':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass3']").focus();
        } else {
          return false;
        }
        break;
      case 'pass3':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass4']").focus();
        } else {
          return false;
        }
        break;
      default:
        break;
    }
    ebtn();
  };

  const guardar = (e) => {
    e.preventDefault();
    if (
      form.pass1.length === 1
      && !error.pass1
      && form.pass2.length === 1
      && !error.pass2
      && form.pass3.length === 1
      && !error.pass3
      && form.pass4.length === 1
      && !error.pass4
    ) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'clickSavePassword',
        },
      };
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickSavePassword',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      };
      if (sessionStorage.getItem('id_session')) {
        createClickFront({ variables: createClickInput });
      }
      TagManager.dataLayer(tagManagerArgs);
      validateMail250Ok({
        variables: {
          email: sessionStorage.getItem('email'),
        },
      });
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactLogin',
            columns: {
              setkey: 'password',
              setval: form.pass1 + form.pass2 + form.pass3 + form.pass4,
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
      sessionStorage.setItem('session', true);
    }
  };

  return (
    <Fragment>
      <Header pathname="/new-password" />
      <Main
        className="container"
        background=""
        pb="0%"
        margintop="2%"
        overflow=""
        padding="15px"
        mb="0%"
        minheigth="700px"
      >
        <Container
          width="50%"
          mt="1rem"
          widthMobile="100%"
          mtMobile="2rem"
          mb="134px"
        >
          <Col11>
            <SubtitleOne
              // size="1.4rem"
              mt="0%"
              id="idTitle"
              className="font-weight-bold roboto"
              size="20px"
              sizeMobile="20px"
            >
              Último paso para tu registro
            </SubtitleOne>
            <ParagrapTwo
              // mb="8%"
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="10%"
              fontWeight=" "
            >
              {!LPpageplanes
                ? 'Crea tu NIP de 4 digitos'
                : 'Al ingresar a Coru te pediremos que ingreses este NIP, te sugerimos utilizar 4 números que sean fáciles de recordar.'}
            </ParagrapTwo>
            <form autoComplete="off" onSubmit={guardar}>
              <Container width="100%" widthMobile="80%">
                <Col3>
                  <TextField
                    id="pass1"
                    autoFocus
                    name="pass1"
                    label=""
                    type="password"
                    value={form.pass1}
                    onChange={handleChange}
                    inputProps={{ className: 'text-center', maxLength: 1 }}
                    className="registro"
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="pass2"
                    name="pass2"
                    label=""
                    type="password"
                    value={form.pass2}
                    onChange={handleChange}
                    inputProps={{ className: 'text-center', maxLength: 1 }}
                    className="registro"
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="pass3"
                    name="pass3"
                    label=""
                    type="password"
                    value={form.pass3}
                    onChange={handleChange}
                    inputProps={{ className: 'text-center', maxLength: 1 }}
                    className="registro"
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="pass4"
                    name="pass4"
                    label=""
                    type="password"
                    value={form.pass4}
                    onChange={handleChange}
                    inputProps={{ className: 'text-center', maxLength: 1 }}
                    className="registro"
                  />
                </Col3>
              </Container>
              <ContentButton mt="10%" mtMobile="15%">
                <Button
                  type="submit"
                  disabled={btn}
                  className="d-block  mt-md-4 mb-5 roboto"
                >
                  Finalizar registro
                </Button>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/new-password" />
    </Fragment>
  );
}

export default PasswordNew;
