import React, { Fragment } from 'react';

// Resources
import iconRegister from '../../assets/img/embajadores/Emb-01.png';
import iconShare from '../../assets/img/embajadores/Emb-03.png';
import iconMoney from '../../assets/img/embajadores/Emb-02.png';

// Styled components
import {
  Home_DivSection,
  Title_h1,
  Subtitles_Gray,
} from '../../assets/styles/Home.styled';
import { SubtitleOne } from '../../components/common/common.styled';

// Components
import HeaderEmbajadores from '../../components/embajadores/HeaderEmbajadores';
import MultipleCards from '../../components/common/MultipleCards';

const EmbajadoresRegistro = (props) => {
  const cards = [{
    img: iconRegister,
    title: 'Regístrate en el programa de embajadores.',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hoverColor: '#b5e7e4',
  }, {
    img: iconShare,
    title: 'Comparte tu anuncio con tus familiares y amigos',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hoverColor: '#e3d6f1',
  }, {
    img: iconMoney,
    title: 'Gana $ por cada seguro contratado.',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hoverColor: '#87bdec',
  }];
  return <Fragment>
        <HeaderEmbajadores></HeaderEmbajadores>
        <Home_DivSection>
            <Title_h1 align='center'>Embajadores<br/>de Seguros de Auto</Title_h1>
            <SubtitleOne mt='2rem' >Gana dinero por recomendar el mejor<br/>seguro de auto a tus amigos o familiares.</SubtitleOne>
            <Subtitles_Gray align='center'>
                Lo mejor, ¡No necesitas ser experto en seguros de auto!<br/>
                Nuestra plataforma le sugiere las mejores opciones a tus conocidos.
            </Subtitles_Gray>
        </Home_DivSection>
        <Home_DivSection>
            <MultipleCards cards={cards} enumerated={true} />
        </Home_DivSection>
    </Fragment>;
};

export default EmbajadoresRegistro;
