import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { TextPerfil } from '../../assets/styles/pantInicio.styled';
import {
  Main, Container, Col7, Col3,
} from '../common/common.styled';
import '../../assets/styles/perfil/perfiles.css';

function Notifications({ Novisible, paddingInicio }) {
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  });
  const history = useHistory();
  const handleClick = () => {
    history.push('/wo/virality-test');
  };
  return (
       <Fragment>
           <Main overflow=" " pt={!paddingInicio ? '10%' : ' ' } background='#fafafa' mt="0%" mtWeb="0%">
               <Container width='94%' background="#ffff" filter=" drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" mt='0rem' widthMobile='100%' mtMobile='0rem' padding="2%" mb ="0px" >
                   <Col7 pt="0px" pMobile="0px">
                       <TextPerfil fw="600" fs="17px" mt="0%" width="100%" color="rgb(17, 14, 32)" fsMobile="17px" mlMobile="0%" lh="20px">¡Es Viernes!</TextPerfil>
                       <TextPerfil fw="400" fs="17px" mt="0%" width="100%" color="rgb(17, 14, 32)" fsMobile="17px" mlMobile="0%" lh="20px">Responde tu cuestionario semanal y gana una tarjeta de regalo</TextPerfil>
                   </Col7>
                   <Col3 mt="0px" ta="center" margin="auto">
                       <TextPerfil fw="600" fs="14px" color="#F64282" mt="0%" cursor="pointer" width="100%" fsMobile="12px" mlMobile="0%" lh="20px" onClick={handleClick}>Iniciar cuestionario </TextPerfil>
                   </Col3>
               </Container>
           </Main>
       </Fragment>
  );
}
export default Notifications;
