import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import '../../assets/styles/loginAdmin/_form.css';
import '../../assets/styles/loginAdmin/_general.css';
import '../../assets/styles/loginAdmin/authentication.css';
import { LOGIN } from '../../mutations';
const Aux = (props) => props.children;

function LogInAdmin() {
  const [form, setForm] = useState({
    email: '',
    password: '',
    typeUser: 'administrador',
  });
  const [error] = useState({
    email: false,
    password: false,
  });
  const [texterror] = useState({
    email: '',
    password: '',
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const eBtn = () => {
    if ((form.email.length !== '' && !error.email)
           && (form.password.length !== '' && !error.password)) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  };
  const ValidEmail = () => {
    if (form.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) {
      texterror.email = 'Correo electronico incorrecto';
      error.email = true;
    } else {
      texterror.email = '';
      error.email = false;
      setbuttonDisabled(true);
    }
    eBtn();
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail();
        break;
      default:
        eBtn();
        break;
    }
  };
  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      sessionStorage.setItem('token', login.token);
      sessionStorage.setItem('email', form.email);
      localStorage.setItem('token', login.token);
      if (login.token !== 'Contraseña incorrecta' && login.token !== 'El usuario no existe') { window.location.href = '/admin-home/trainings'; }
    },
  });
  const send = (e) => {
    e.preventDefault();
    try {
      login({ variables: { input: form } });
    } catch (e) {
      console.log(e);
    }
  };
  return (
        <Fragment>
            <Aux>
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-bg">
                            <span className="r" />
                            <span className="r s" />
                            <span className="r s" />
                            <span className="r" />
                        </div>
                        <form autoComplete="off" onSubmit={ (e) => send(e)}>
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="mb-4">
                                    </div>
                                    <h3 className="mb-4">Iniciar Sesion</h3>
                                    <div className="input-group mb-3">
                                        <input type="email" name="email" error ={error.email} value={form.email} required onChange={handleChange} className="form-control" placeholder="Ingresa tu correo electrónico" helperText={texterror.email} />
                                    </div>
                                    <div className="input-group mb-4">
                                        <input type="password" name="password" error ={error.password} value={form.password} required onChange={handleChange} className="form-control" placeholder="Ingresa tu contraseña" helperText={texterror.password} />
                                    </div>
                                    <button disabled={buttonDisabled} type="submit" className="btn btn-primary shadow-2 mb-4">Continuar</button>
                                    <p className="mb-2 text-muted">Por el momento, este acceso es únicamente para usuarios internos de administración.</p>
                                    <p className="mb-2 text-muted">Este sistema cuenta con monitoreo en tiempo real.</p>
                                    <p className="mb-2 text-muted">El incorrecto uso de la información podrá ser acreedor a sanciones.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Aux>
        </Fragment>
  );
}

export default LogInAdmin;
