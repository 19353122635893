import gql from 'graphql-tag';

export const GET_AUTH = gql`
  query getAuth {
    getAuth {
      statusCode
      message
      error
      response {
        idCont
      }
    }
  }
`;

export const GET_CAR_INSURANCE_DATA_QUESTION = gql`
   query getCarInsuranceDataQuestion($data: carsDataInput, $questionOrder: Int, $idRoutine:Int) {
      getCarsData(data: $data) {
         response {
            year
            maker
            model
            transmission
         }
         error
         statusCode
      }

      getQuestionInfo(questionOrder: $questionOrder, idRoutine: $idRoutine) {
         response
         statusCode
         message
         error
      }
   }
`;

export const GET_CLIENTES = gql`
  query getCliente($email: String) {
    getCliente(email: $email) {
      id
      nombre
      telefono
      email
    }
  }
`;

export const GETURLSHORT = gql`
  query getUrlShortened($id: String) {
    getUrlShortened(ID: $id) {
      message
      response {
        Url
      }
    }
  }
`;
export const VERIFY_TOKEN = gql`
  query verifyToken($token: String) {
    verifyToken(token: $token) {
      statusCode
      message
      userType
      error
      response
    }
  }
`;
export const GET_URLS_SHORT = gql`
  query getUrlsShortened {
    getUrlsShortened {
      message
      response {
        id
        Uid
        Url
        CreatedAt
        UrlName
        UrlDescription
      }
    }
  }
`;
export const GET_PIXELES = gql`
  query getPixeles {
    getPixeles {
      statusCode
      message
      error
      response {
        id
        medium
        url
        timeStamp
        description
      }
    }
  }
`;
export const GET_CONFIG_OTP = gql`
  query getConfigOTP {
    getConfigOTP {
      statusCode
      message
      error
      response {
        id
        ambiente
        step
        sms_on_off
        whatsapp_on_off
        email_on_off
      }
    }
  }
`;

export const GET_TRAINING = gql`
  query getTrainingRedesign($idUser: Int) {
    getTrainingRedesign(idUser: $idUser)
  }
`;

export const GET_USER_TRAININGS = gql`
  query getUserTrainings($idUser: Int) {
    getUserTrainings(idUser: $idUser) {
      IDCatTrainingAvailability
      IDCatTraining
      TrainingColor
      Achievment
      Outstanding
      TrainingTime
      TrainingBigTitle
      TrainingBigDescription
      UrlBigImage
      TrainingRedirectUrl
      TrainingType
      Status
      VerticalName
      IDCategory
    }
  }
`;

export const GET_SESSION_USER = gql`
  query getSessionUser($idUser: String) {
    getSessionUser(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_TRAININGS = gql`
  query getTrainingList($idUser: Int!, $section: TrainingType) {
    getTrainings(idUser: $idUser, section: $section) {
      IDCategory
      IDCatTraining
      TrainingColor
      IDCatTrainingAvailability
      Section
      TrainingBigTitle
      TrainingBigDescription
      UrlBigImage
      UrlTwinImage
      TrainingTwinTitle
      TrainingTime
      TrainingRedirectUrl
      TrainingType
      Outstanding
      VerticalName
      Liked
    }
  }
`;

// no se usa
export const GET_TRAINING_REDISENO = gql`
  query getTraining($idUser: Int) {
    getTraining(idUser: $idUser)
  }
`;

export const GET_TRAINING_PROGRESS = gql`
  query getTrainingProgress($idUser: Int, $idTraining: Int) {
    getTrainingProgress(idUser: $idUser, idTraining: $idTraining) {
      achievment
      status
    }
  }
`;

export const GET_ROUTINES_BY_ID_TRAINING = gql`
  query getRoutinesByIdTraining($idTraining: Int, $orderRutine: Int) {
    getRoutinesByIdTraining(idTraining: $idTraining, orderRutine: $orderRutine)
  }
`;

export const GET_ROUTINES = gql`
  query getRoutines {
    getRoutines {
      response {
        IDCatRoutine
        Name
        RoutineType
      }
    }
  }
`;

export const GET_QUESTIONS_BY_ID_ROUTINES = gql`
  query getQuestionsByIdRoutines($idRoutine: Int) {
    getQuestionsByIdRoutines(idRoutine: $idRoutine)
  }
`;

export const GET_TEMPLATES_SNC = gql`
  query getTemplatesSnc($tipo: String) {
    getTemplatesSnc(tipo: $tipo) {
      statusCode
      response {
        UId
        Text
        TemplateName
        FileName
        Type
        Removed
      }
    }
  }
`;

export const GET_CAMPAIGNS_TRANS_SNC = gql`
  query getCampaignsTransSnc($tipo: String, $id: String) {
    getCampaignsTransSnc(tipo: $tipo, id: $id) {
      statusCode
      response {
        id
        triggerName
        Subject
        name
        Deleted
        CreatedAt
        tipo
        text
        TemplateName
        cadenaGtm
      }
    }
  }
`;

export const GET_TRAINING_LIST = gql`
  query getTrainingList($input: String!) {
    getTrainingList(Db: $input) {
      db
      fields {
        IDCatTraining
        name
        TrainingBigTitle
        Status
        Show
      }
    }
  }
`;

export const GET_PRODUCTS_LIST = gql`
  query getProductsList($input: String!) {
    getProductsList(Db: $input) {
      db
      fields {
        IDProduct
        Status
        Name
        ProductCategory
        ProductSubCategory
        ProductSubSubCategory
        CatProviders_IDProvider
        CatVerticals_IDVertical
        ImageURL
        hasScrapper
        Enabled
        ClickOut
        hasApi
        hasClickOut
      }
    }
  }
`;

export const GET_PRODUCTS_LIST_CONTROL = gql`
  query getProductsListControl($input: String!) {
    getProductsListControl(Db: $input) {
      db
      totalTrainings
      fields {
        IDProduct
        Status
        Name
        ProductCategory
        ProductSubCategory
        ProductSubSubCategory
        CatProviders_IDProvider
        CatVerticals_IDVertical
        ImageURL
        hasScrapper
        Enabled
        ClickOut
        hasApi
        hasClickOut
        trainings {
          wo_1
          wo_2
          wo_3
          wo_4
          wo_5
          wo_6
          wo_7
          wo_8
          wo_9
          wo_10
          wo_11
          wo_12
          wo_13
          wo_14
          wo_15
          wo_16
          wo_17
          wo_18
          wo_19
          wo_20
          wo_21
          wo_22
          wo_23
          wo_24
          wo_25
          wo_26
          wo_27
          wo_28
          wo_29
          wo_30
          wo_31
          wo_32
          wo_33
          wo_34
          wo_35
          wo_36
          wo_37
          wo_38
          wo_39
          wo_40
          wo_41
        }
      }
    }
  }
`;

export const GET_SCRAPPER_LIST = gql`
  query getScrapperList($input: String!) {
    getScrapperList(Db: $input) {
      db
      fields {
        IDProduct
        Name
        BusinessAbr
        enabled
      }
    }
  }
`;

export const GET_TRAINING_PREVIEW = gql`
  query getTrainingPreview($Id: String!) {
    getTrainingPreview(idTraining: $Id) {
      statusCode
      message
      error
      response {
        training {
          IDCatTraining
          Status
          Name
          TrainingType
          TrainingTime
          CatVerticals_IDVertical
          Outstanding
          TrainingBigTitle
          TrainingBigDescription
          UrlBigImage
          TrainingMediumTitle
          TrainingMediumDescription
          UrlMediumImage
          TrainingTwinTitle
          TrainingTwinDescription
          UrlTwinImage
          TrainingRedirectUrl
          UrlVideo
          IdVideoURL
          Show
          TrainingColor
        }
        routines {
          RoutinesOrder
          IDCatRoutine
          Status
          Name
          RoutineType
        }
      }
    }
  }
`;

export const GET_PRODUCT_INFO = gql`
  query getProductInfo($Id: String!) {
    getProductInfo(Id: $Id) {
      statusCode
      message
      response {
        IDProduct
        Enabled
      }
    }
  }
`;

export const GET_TEMPLATE_TEXT = gql`
  query getTextTemplate($Uid: String) {
    getTextTemplate(Uid: $Uid) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_OPORTUNITIES = gql`
  query getProductsOportunities(
    $idCont: Int
    $idUser: Int
    $income: String
    $vertical: Int
    $idTraining: Int
  ) {
    getProductsOportunities(
      idCont: $idCont
      idUser: $idUser
      income: $income
      vertical: $vertical
      idTraining: $idTraining
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_FASTTRACK = gql`
  query getProductsFastTrack(
    $idTraining: Int
    $uso: String
    $necesidad: String
    $tiempo: String
  ) {
    getProductsFastTrack(
      idTraining: $idTraining
      uso: $uso
      necesidad: $necesidad
      tiempo: $tiempo
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_DAY_OF_WEEK = gql`
  query getDayOfWeek {
    getDayOfWeek {
      today
    }
  }
`;

export const GET_VERTICALS = gql`
  query getVerticals {
    getVerticals {
      response {
        IDVertical
        VerticalName
      }
    }
  }
`;

export const GET_DOMICILIO = gql`
  query getDomicilio($ZipCode: String) {
    getDomicilio(ZipCode: $ZipCode) {
      State
      Municipality
      City
      Suburb
      StateAbreviation
    }
  }
`;

export const GET_BANKS = gql`
  query getBanks($idTraining: Int) {
    getBanks(idTraining: $idTraining) {
      BankName
      ShowName1
      ShowName2
      BankLogo1
      BankLogo2
      BankLogo3
    }
  }
`;
export const GET_IMAGES_S3 = gql`
  query getImageS3 {
    getImageS3 {
      statusCode
      message
      error
      response {
        id
        pathS3
      }
    }
  }
`;

export const RECOVERY_ROUTINE_BY_ORDER = gql`
  query recoveryRoutineByOrder(
    $idTraining: String
    $orderRoutine: String
    $idUser: String
    $idCont: String
  ) {
    recoveryRoutineByOrder(
      idTraining: $idTraining
      orderRoutine: $orderRoutine
      idUser: $idUser
      idCont: $idCont
    ) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_VALUE_ANSWER_BY_ID = gql`
  query getValueAnswerById($idAnswer: String) {
    getValueAnswerById(idAnswer: $idAnswer)
  }
`;

export const GET_VARIABLES_SNC = gql`
  query getVariablesSnc {
    getVariablesSnc {
      statusCode
      message
      error
      response {
        IDType
        Description
        Table
      }
    }
  }
`;

export const GET_CARDS_FRONT = gql`
  query getCardsFront($Bank: String) {
    getCardsFront(Bank: $Bank) {
      statusCode
      message
      error
      response {
        Id
        CardName
        CardImage
        Category
        benefit
        idCatProduct
      }
    }
  }
`;
export const GET_VALUE_VARIABLE_SNC = gql`
  query getValoresVariablesSnc($ID: String) {
    getValoresVariablesSnc(ID: $ID) {
      statusCode
      message
      error
      response {
        IDCatFieldDetail
        CatDetailTypeUsers_IDType
        FieldOption
      }
    }
  }
`;

export const GET_VARIABLE_TEMPLATE_SCN = gql`
  query getVariableTemplate($ID: String) {
    getVariableTemplate(ID: $ID) {
      statusCode
      message
      response {
        variables
      }
    }
  }
`;
export const GET_VIEWS_SNC = gql`
  query getViewsSnc {
    getViewsSnc {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_CAMPAIGNS_SNC = gql`
  query getCampaignsSnc($tipo: String, $idaction: String) {
    getCampaignsSnc(tipo: $tipo, idaction: $idaction) {
      statusCode
      message
      error
      response {
        IDAction
        ActionName
        Action
        Vertical
        CreatedAt
        LastExecution
        Deleted
      }
    }
  }
`;

export const GET_BLACKLIST_SNC = gql`
  query getBlacklistSnc($type: String, $value: Int) {
    getBlacklistSnc(type: $type, value: $value) {
      statusCode
      message
      error
      response {
        Email1
        CellPhone
        HomePhone
        CatContact_IDCont
      }
    }
  }
`;

export const GET_RESPONSE_SCRAPPER_BD = gql`
  query getResponseScrapperBD($idOportunity: String!) {
    getResponseScrapperBD(idOportunity: $idOportunity) {
      folio
      response
      comment
      status
      dateIni
      dateFin
      run
    }
  }
`;

export const GET_COUNTRY_CODES = gql`
  query getCountryCodes {
    getCountryCodes {
      Country
      CountryCode
    }
  }
`;
export const GET_VIEW_OPPORTUNITIES_INFO_DETAIL = gql`
  query getViewOpportunitiesInfoDetail($IDuser: String, $Email: String) {
    getViewOpportunitiesInfoDetail(IDuser: $IDuser, Email: $Email) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_USER_INFO_DETAIL = gql`
  query getViewUserInfoDetail(
    $nameComplete: String
    $IDuser: String
    $Email: String
  ) {
    getViewUserInfoDetail(
      nameComplete: $nameComplete
      IDuser: $IDuser
      Email: $Email
    ) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_DETAIL_USER_OPPORTUNITIES = gql`
  query getDetailUserOrOpportunities(
    $nameComplete: String
    $IDuser: String
    $Email: String
    $filt: Int
  ) {
    getDetailUserOrOpportunities(
      nameComplete: $nameComplete
      IDuser: $IDuser
      Email: $Email
      filt: $filt
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GETURLVIDEO = gql`
  query getVideoTraining($id: String) {
    getVideoTraining(ID: $id) {
      statusCode
      response
    }
  }
`;

export const GETPERCENTAGETRAINING = gql`
  query getPercentageTraining($iduser: Int, $idtrainig: Int) {
    getPercentageTraining(idUser: $iduser, idTraining: $idtrainig) {
      percentage
    }
  }
`;

export const GET_PRODUCTS_FAMILY_PROTECTION = gql`
  query getProductsFamilyProtection($idTraining: String!) {
    getProductsFamilyProtection(idTraining: $idTraining) {
      statusCode
      response
      error
    }
  }
`;

export const GET_PRODUCTS_BY_IDTRAINING = gql`
  query getProductsByIdTraining($idTraining: String!, $idUser: Int) {
    getProductsByIdTraining(idTraining: $idTraining, idUser: $idUser) {
      statusCode
      response
      error
    }
  }
`;

export const GET_PRODUCTS_BY_IDTRAINING_AND_NAME = gql`
  query getProductsByIdTrainingAndName($idTraining: Int!, $names: [String]) {
    getProductsByIdTrainingAndName(idTraining: $idTraining, names: $names) {
      statusCode
      response
      error
    }
  }
`;

export const GET_PRODUCTS_BY_IDTRAINING_AND_NAME_FOR_FREMIUM = gql`
  query getProductsByIdTrainingAndNameForFremium($idTraining: Int!, $idUser: Int!, $names: [String]) {
    getProductsByIdTrainingAndNameForFremium(idTraining: $idTraining, idUser: $idUser, names: $names) {
      statusCode
      response
      error
    }
  }
`;

export const GET_STATUS_MVP_TRAINING = gql`
  query getStatusMvpTraining($idUser: String, $idTraining: String) {
    getStatusMvpTraining(IDuser: $idUser, IDtraining: $idTraining) {
      message
    }
  }
`;

export const GET_EXERCISE_BY_MAIL = gql`
  query getExercisebyMail($Email: String, $idTraining: String) {
    getExercisebyMail(Email: $Email, IDtraining: $idTraining) {
      message
      statusCode
    }
  }
`;

export const GET_LIST_MVP = gql`
  query getListMvp($idUser: String, $catTraining: String, $viewType: String) {
    getListMvp(
      idUser: $idUser
      catTraining: $catTraining
      viewType: $viewType
    ) {
      statusCode
      response {
        FirstName
        FathersLastName
        IDUserMaster
        IDUserFriend
        Score
        ClassificationName
      }
      message
    }
  }
`;

export const GET_SCORE_CLASSIFICATION = gql`
  query getScoreClassification($score: Int) {
    getScoreClassification(score: $score) {
      statusCode
      message
      error
      response {
        idCatUserClassification
        ClassificationName
      }
    }
  }
`;

export const GET_TRAINING_COLOR = gql`
  query getTrainingColor($idTraining: String) {
    getTrainingColor(idTraining: $idTraining)
  }
`;

export const GET_CAMPAING_MODAL = gql`
  query getCampaignControl($idModal: String) {
    getCampaignControl(idModal: $idModal)
  }
`;

export const GET_TRAINING_STATUS = gql`
  query getTrainingStatus($idTraining: String) {
    getTrainingStatus(idTraining: $idTraining)
  }
`;

export const GET_PROFILE_INVESTMENT_TEST = gql`
  query getClasificationProfile($idQuestionDetail: String) {
    getClasificationProfile(idQuestionDetail: $idQuestionDetail) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_WO_AND_SUGGESTIONS = gql`
  query getWOAndSuggestions($idUser: String) {
    getWOAndSuggestions(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_SUGGESTIONS_WO_IMPROVE_BUDGET = gql`
  query getSuggestionsWOImproveBudget($idUser: String, $idTraining: Int) {
    getSuggestionsWOImproveBudget(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_CAT_ADMIN_VALUES = gql`
  query getCatAdminValues($type: String) {
    getCatAdminValues(type: $type) {
      statusCode
      message
      response {
        KeyCode
        Description
      }
    }
  }
`;

export const GET_CAT_PROVIDERS = gql`
  query getCatProviders {
    getCatProviders {
      response {
        IDProvider
        BusinessAbr
        ProviderType
        CatContact_IDCont
      }
    }
  }
`;

export const GET_CONTACT_PROVIDERS = gql`
  query getContactProviders {
    getContactProviders {
      response {
        IDCont
        BusinessName
      }
    }
  }
`;

export const GET_ALL_USERS_CORU_RANKING = gql`
  query getAllUsersCoruRanking($idTraining: Int) {
    getAllUsersCoruRanking(idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const VERIFY_USER_WITH_TRAINING = gql`
  query verifyUserWithTraining($idUser: String, $idTraining: String) {
    verifyUserWithTraining(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_INVESTMENT_RANKING_FRIENDS = gql`
  query getInvestmentRankFriends($idTraining: Int, $idUser: Int) {
    getInvestmentRankFriends(idTraining: $idTraining, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_INVESTMENT_DETAILS = gql`
  query getInvestmentDetailsFriend($idDatTraining: Int) {
    getInvestmentDetailsFriend(idDatTraining: $idDatTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_RESPONSE_USER_BY_IDTYPE = gql`
  query getResponseUserByIdType($types: String, $idUser: String) {
    getResponseUserByIdType(types: $types, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_HISTORY_GOALS = gql`
  query getHistoryGoals($idUser: String, $idTraining: String) {
    getHistoryGoals(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_INVESTMENT_INSTRUMENT_PRICE = gql`
  query getAssetsInvestmentInstrument(
    $instrumentName: String
    $startDate: String
  ) {
    getAssetsInvestmentInstrument(
      instrumentName: $instrumentName
      startDate: $startDate
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_NOTIFICATION_PRIVILEGE = gql`
  query getNotificationPrivilege($idUser: String) {
    getNotificationPrivilege(idUser: $idUser) {
      statusCode
      message
      error
      response {
        sms
        whatsapp
        email
      }
    }
  }
`;
export const GET_QUESTIONS_CORRECT_ANSWER = gql`
  query getQuestionsWithCorrectAnswers($idTraining: Int) {
    getQuestionsWithCorrectAnswers(idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_PROPERTIES_STATUS_GOALS = gql`
  query getPropertiesStatusGoals($percent: Int) {
    getPropertiesStatusGoals(percent: $percent) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_VIDEO_BY_ROUTINE = gql`
  query getVideosByIdTrainingAndRoutineOrder(
    $idTraining: String
    $RoutinesOrder: String
  ) {
    getVideosByIdTrainingAndRoutineOrder(
      idTraining: $idTraining
      RoutinesOrder: $RoutinesOrder
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const RECOVERY_USERS_CHILDREN = gql`
  query recoveryUsersChildren($idUser: String, $idUsersChildren: String) {
    recoveryUsersChildren(idUser: $idUser, idUsersChildren: $idUsersChildren) {
      statusCode
      message
      error
      response
    }
  }
`;

export const FIND_USERS_FOR_ADVISERS = gql`
  query findUsersForAdvisers($txt: String) {
    findUsersForAdvisers(txt: $txt) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_USER_FOR_ADVISERS = gql`
  query getUserForAdvisers(
    $idUser: String,
    $idCoach: String,
    $idCoachCont: String,
    $idCatAsesoresDetails: String
  ) {
    getUserForAdvisers(
      idUser: $idUser,
      idCoach: $idCoach,
      idCoachCont: $idCoachCont,
      idCatAsesoresDetails: $idCatAsesoresDetails
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PENDING_SESSIONS_XCOACH = gql`
  query getPendingSessionsXCoach($idCoach: String) {
    getPendingSessionsXCoach(idCoach: $idCoach) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CATS_XCOACH = gql`
  query getCatsXCoach($idUser: Int) {
    getCatsXCoach(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_COUNT_RECORDS_ADVISER = gql`
  query getCountRecordsAdvisers($fechaInicial: String, $fechaFinal: String) {
    getCountRecordsAdvisers(fechaInicial: $fechaInicial, fechaFinal: $fechaFinal) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_REPORT_ADVISER = gql`
  query getReportAdvisers($idUsers: [idUsersXCoach]) {
    getReportAdvisers(idUsers: $idUsers) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_GOAL_DATA_COLLABORATIVE = gql`
   query getGoalDataCollaborative($idUser: String, $idTraining: String, $idDatTraining: String) {
      getGoalDataCollaborative(idUser: $idUser, idTraining: $idTraining, idDatTraining: $idDatTraining) {
         statusCode
         message
         error
         response
      }
   }
`;

export const GET_GOALS_AMOUNTS_COLLABORATIVE = gql`
   query getGoalsAmountsCollaborative($phone: String) {
      getGoalsAmountsCollaborative(phone: $phone) {
         statusCode
         message
         error
         response
      }
   }
`;

export const GET_INVITACIONES_COLLABORATIVE = gql`
  query getInvitacionesCollaborative($phone: String) {
    getInvitacionesCollaborative(phone: $phone) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CHECK_USER_COLLABORATIVE = gql`
   query checkUserCollaborative($idUser: String, $idTraining: String) {
      checkUserCollaborative(idUser: $idUser, idTraining: $idTraining) {
         statusCode
         message
         error
         response
      }
   }
`;

export const VERIFY_PROFILING_PROGRESS = gql`
  query verifyProfilingProgress($idUser: String, $idTraining: String) {
    verifyProfilingProgress(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CAT_ROUTES_Q = gql`
  query GetCatRoutes($routeType: String!) {
    getCatRoutes(routeType: $routeType) {
      id
      name
      path
      component
      routeType
    }
  }
`;

export const CHECK_TRAINING_FINISHED = gql`
  query checkTrainingFinished($idUser: Int, $idTraining: Int) {
    checkTrainingFinished(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_ANT_EXPENSES = gql`
  query getAntExpenses($idUser: String, $idTraining: String) {
    getAntExpenses(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_TRAINING_CATEGORIES = gql`
  query getCategories {
    getCategories {
      response
    }
  }
`;

export const GET_TRAINING_HOME_CATEGORIES = gql`
  query getTrainingCategories {
    getTrainingCategories {
      response
    }
  }
`;

export const GET_PRODUCTS_BY_RP = gql`
  query getProductsByRP(
    $idTraining: Int
    $RP: Int
    $Income: String
    $HasHistory: Int
    $Score: Int
  ) {
    getProductsByRP(
      idTraining: $idTraining
      RP: $RP
      Income: $Income
      HasHistory: $HasHistory
      Score: $Score
    ) {
      statusCode
      message
      error
      response {
        IDProduct
        Status
        Enabled
        Name
        ProductCategory
        ImageURL
        ClickOut
        Anualidad
        CAT
        Interes
        Beneficios
      }
    }
  }
`;

export const GET_PRODUCTS_DETAILS_BY_PRODUCT = gql`
  query getProductDetailsByProduct( $idProduct: Int ) {
    getProductDetailsByProduct( idProduct: $idProduct ) {
      IDProductDetail
      Status
      Name
      Description
      IDProductDetailType
    }
  }
`;

export const GET_PRODUCTS_BY_LADING = gql`
  query getProductsLp($idLandingPage: Int) {
    getProductsLp(idLandingPage: $idLandingPage) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_BY_ID = gql`
  query getProductsByIdProduct($idProduct: [String]) {
    getProductsByIdProduct(idProduct: $idProduct) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_SUPER_SEGUROS = gql`
query getSuperSeguros($input: inputSuperSeguros){
  getSuperSeguros(input:$input){
    status
    message
    error
    response
  }
}
`;

export const GET_EVENT_LIST = gql`
query getEventList {
  getEventList {
    error
    message
    statusCode
    response {
      id
      description
      name
      campaignName
      transactionalName
      Enabled
    }
  }
}
`;

export const GET_PRODUCTS_PYMES = gql`
  query getProductsPymes($idUser: String, $idTraining: String) {
    getProductsPymes(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_USER_CONTACT = gql`
  query getUserContact($idCont: String!) {
    getUserContact(idCont: $idCont) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_USER_CONTACTV2 = gql`
  query getUserContactV2($idCont: String!) {
    getUserContactV2(idCont: $idCont) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_USER_MEDIUM = gql`
  query getUserMedium($idCont: String!) {
    getUserMedium(idCont: $idCont) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUserDetails($idUser: String!) {
    getUserDetails(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CODE_TEMPORAL = gql`
  query getEmailTemporalCode($code: Int) {
    getEmailTemporalCode(code: $code) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_VALIDATE_STREET_NAME = gql`
  query getValidateStreetName($streetName:String) {
  getValidateStreetName(streetName:$streetName)
  {
   nombresCalles
  }
}
`;

export const GET_CIUDADES_COP = gql`
query getCiudadesCop($Estado:String) {
  getCiudadesCop(Estado:$Estado) 
  {
  ciudades
    {
      clave
      descripcion
    }
  }
}`;

export const GET_COLONIAS_COP = gql`
query getColoniasCop($idCiudad:String) {
  getColoniasCop(idCiudad:$idCiudad) 
  {
  colonias
    {
      clave
      descripcion
    }
  }
}`;

export const GET_STATUS_VISIT_KANDA = gql`
  query getStatusVisitKanda($idUser: Int!) {
    getStatusVisitKanda(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_URL_KANDA = gql`
  query getUrlKanda($appName: String!) {
    getUrlKanda(appName: $appName) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_NICKNAME_KANDA = gql`
  query getNicknameKanda($idUser: Int!) {
    getNicknameKanda(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;
export const LOGBC_GET_MONGO = gql`
  query LogBCGet($input: mongoInputGet) {
    LogBCGet(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_PRODUCTS_IDEAL_CARDS = gql`
  query getProductsIdealCards(
    $gama: String
    $nameCard: String
  ) {
    getProductsIdealCards(
      gama: $gama
      nameCard: $nameCard
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_DOCS_USERS = gql`
  query getDocsUsers($idUser: String!) {
    getDocsUsers(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CATALOGO_XCOACH = gql`
  query getOptionsXcoach {
    getOptionsXcoach {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_VALIDATION_EMAIL = gql`
  query getValidUserMail($email: String) {
    getValidUserMail(email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const EVEREST_VALIDATE_EMAIL = gql`
  query everestValidateEmail($email: String){
    everestValidateEmail(email: $email){
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_WITH_SCORE = gql`
  query getProductsWithScore($idTraining: Int, $score: Int) {
    getProductsWithScore(idTraining: $idTraining, score: $score) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_NEW_REPORT_ADVISER = gql`
  query getNewReportAdvisers($idUsers: [idUsersXCoach]) {
    getNewReportAdvisers(idUsers: $idUsers) {
      statusCode
      message
      error
      response
    }
  }
`;

export const VERIFY_ACCOUNT = gql`
  query verifyAccount($value: String) {
    verifyAccount(value: $value) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PARTNER_CAAS = gql`
  query getPartnerCaaS($idPartner: Int!) {
    getPartnerCaaS(idPartner: $idPartner) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_MAGIC_LINK = gql`
  query getMagicLink ($id: String!) {
    getMagicLink(Uid: $id) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_REWARDS = gql`
  query getRewardsAdmin ($fechaIni: String, $fechaFin: String) {
    getRewardsAdmin(fechaIni: $fechaIni, fechaFin: $fechaFin) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProductsEmida {
    getProductsEmida {
      statusCode
      message
      error
      response
    }
  }
`;

export const VERIFY_AUT_USERS_TO_PAY_REWARDS = gql`
  query verifyAuthorizedUsersToPayRewards($email: String!) {
    verifyAuthorizedUsersToPayRewards(email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_REWARDS_REPORT = gql`
  query getRewardsReport($fechaIni: String, $fechaFin: String) {
    getRewardsReport(fechaIni: $fechaIni, fechaFin: $fechaFin) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_STATUS_TRAINING_BY_NAME = gql`
  query getStatusTrainingByName ($idUser: Int, $NameWO: String) {
    getStatusTrainingByName(idUser: $idUser, NameWO: $NameWO) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_TRANSLATE_INFO = gql`
  query getTranslateInfo ($language: Int) {
    getTranslateInfo(language: $language) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_APPOINTMENTS_DEMO = gql`
  query getAppoinmentsDemo {
    getAppoinmentsDemo {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_WHATSAPP_INFO = gql`
  query getWhatsappTranslateInfo ($language: Int) {
    getWhatsappTranslateInfo(language: $language) {
      statusCode
      message
      error
      response
    }
  }
`;
