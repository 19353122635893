/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  DivRespDP,

} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import {
  PymesCardsOne,
  PymesCardsTwo,
  PymesCardsThree,
  PymesCardsFour,
  PymesCardsFive,
  PymesCardsSix,
} from '../Training/PymesCards.js';

// let elementScrolled = [];
const QuestionsPymes = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActivarFlujo = '',
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [queEs, setQueEs] = useState(sessionStorage.getItem('Que_es') || '');
  const [quienOtorga, setQuienOtorga] = useState(
    sessionStorage.getItem('Quien_los_otorga') || '',
  );
  const [tipoCreditoPyme, setTipoCreditoPyme] = useState(
    sessionStorage.getItem('Tipos_de_credito_pyme') || '',
  );
  const [comoUsan, setComoUsan] = useState(
    sessionStorage.getItem('Como_se_usan') || '',
  );
  const [garantias, setGarantias] = useState(
    sessionStorage.getItem('Grantias_en_creditos_pymes') || '',
  );
  const [requisitos, setRequisitos] = useState(
    sessionStorage.getItem('Requisitos_para_solicitarlos') || '',
  );

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editQueEs, setEditQueEs] = useState('');
  const [editQuienOtorga, setEditQuienOtorga] = useState('');
  const [editTipoCreditoPyme, setEditTipoCreditoPyme] = useState('');
  const [editComoUsan, setEditComoUsan] = useState('');
  const [editGarantias, setEditGarantias] = useState('');
  const [editRequisitos, setEditRequisitos] = useState('');

  const [isCorrectQueEs, setIsCorrectQueEs] = useState(false);
  const [isCorrectQuienOtorga, setIsCorrectQuienOtorga] = useState(false);
  const [isCorrectTipoCreditoPyme, setIsCorrectTipoCreditoPyme] = useState(false);
  const [isCorrectComoUsan, setIsCorrectComoUsan] = useState(false);
  const [isCorrectGarantias, setIsCorrectGarantias] = useState(false);
  const [isCorrectRequisitos, setIsCorrectRequisitos] = useState(false);

  const [nextQuestionQueEs, setNextQuestionQueEs] = useState('');
  const [nextQuestionQuienOtorga, setNextQuestionQuienOtorga] = useState('');
  const [nextQuestionTipoCreditoPyme, setNextQuestionTipoCreditoPyme] = useState('');
  const [nextQuestionComoUsan, setNextQuestionComoUsan] = useState('');
  const [nextQuestionGarantias, setNextQuestionGarantias] = useState('');
  const [nextQuestionRequisitos, setNextQuestionRequisitos] = useState('');

  const arrayAnswersCorrect = useState([
    {
      numberQuestion: 0,
      idCatQuestionaDetail: 884,
    },
    {
      numberQuestion: 1,
      idCatQuestionaDetail: 887,
    },
    {
      numberQuestion: 2,
      idCatQuestionaDetail: 890,
    },
    {
      numberQuestion: 3,
      idCatQuestionaDetail: 893,
    },
    {
      numberQuestion: 4,
      idCatQuestionaDetail: 896,
    },
    {
      numberQuestion: 5,
      idCatQuestionaDetail: 899,
    },
  ]);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleScrollTo = (label) => {
    gsap.to(window, { duration: 1, scrollTo: `#${label}` });
  };

  const handleChangeCatOption = ({ step, value, IDCatQuestionsDetail }) => {
    const answerCorrect = arrayAnswersCorrect[0].filter(
      (e) => e.numberQuestion === step,
    )[0];
    const isCorrect = answerCorrect.idCatQuestionaDetail === IDCatQuestionsDetail;
    switch (step) {
      case 0:
        setEditQueEs(true);
        setQueEs(value);
        setIsCorrectQueEs(isCorrect);
        handleScrollTo('califQuest0');
        break;
      case 1:
        setEditQuienOtorga(true);
        setQuienOtorga(value);
        setIsCorrectQuienOtorga(isCorrect);
        handleScrollTo('califQuest1');
        break;
      case 2:
        setEditTipoCreditoPyme(true);
        setTipoCreditoPyme(value);
        setIsCorrectTipoCreditoPyme(isCorrect);
        handleScrollTo('califQuest2');
        break;
      case 3:
        setEditComoUsan(true);
        setComoUsan(value);
        setIsCorrectComoUsan(isCorrect);
        handleScrollTo('califQuest3');
        break;
      case 4:
        setEditGarantias(true);
        setGarantias(value);
        setIsCorrectGarantias(isCorrect);
        handleScrollTo('califQuest4');
        break;
      case 5:
        setEditRequisitos(true);
        setRequisitos(value);
        setIsCorrectRequisitos(isCorrect);
        handleScrollTo('califQuest5');
        break;
      default:
        console.log('default');
        break;
    }
  };

  const handleNextQuestion = ({ numberQuestion }) => {
    switch (numberQuestion) {
      case 0:
        console.log(`Question ${numberQuestion}`);
        setNextQuestionQueEs('next');
        handleScrollTo('activeQuest1');
        break;
      case 1:
        console.log(`Question ${numberQuestion}`);
        setNextQuestionQuienOtorga('next');
        handleScrollTo('activeQuest2');
        break;
      case 2:
        console.log(`Question ${numberQuestion}`);
        setNextQuestionTipoCreditoPyme('next');
        handleScrollTo('activeQuest3');
        break;
      case 3:
        console.log(`Question ${numberQuestion}`);
        setNextQuestionComoUsan('next');
        handleScrollTo('activeQuest4');
        break;
      case 4:
        console.log(`Question ${numberQuestion}`);
        setNextQuestionGarantias('next');
        handleScrollTo('activeQuest5');
        break;
      case 5:
        console.log(`Question ${numberQuestion}`);
        setNextQuestionRequisitos('next');
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        break;
    }
  };

  const handleChange = (e, question = '') => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const handleChangeIcon = (json) => {
    setEditQueEs(false);
    setEditQuienOtorga(false);
    setEditTipoCreditoPyme(false);
    setEditComoUsan(false);
    setEditGarantias(false);
    setEditRequisitos(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  const [loaderWait, setLoaderWait] = useState(true);
  const anchop = window.screen.width;
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#activePymes' });
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 2 ? (
        <>
          {/* pregunta #1 */}
          <div id="activePymes"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            textbold="¡Excelente! 👍"
            text="Ahora te daré toda la información previa para que sepas más acerca de los créditos PyMEs."
            hora={hora}
          />
          <QuestionsOrder
            classCard="cardOne"
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (queEs === ''
                || (lapiz[0].routine === 4 && lapiz[0].active === 1))
              && editQueEs !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="califQuest0"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                queEs !== ''
                && (editQueEs === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
            />
            <CardTwo
              classCard={
                queEs !== ''
                && (editQueEs === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={queEs}
              colorTraining={trainingColor}
            ></CardTwo>

            {queEs !== ''
            && (editQueEs === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 1)) ? (
              <PymesCardsOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                title={isCorrectQueEs ? '¡Excelente! 👍' : '¡Uy, casi! 👍'}
                nextQuestionQueEs={nextQuestionQueEs}
                handleNextQuestion={() => handleNextQuestion({ numberQuestion: 0 })
                }
              />
              ) : null}
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}

      {/* pregunta #2 */}
      <div id="activeQuest1"></div>
      {queEs !== ''
      && (editQueEs === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
      && nextQuestionQueEs !== '' ? (
        <QuestionsOrder
          classCard={queEs !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      {nextQuestionQueEs !== '' ? (
        <>
          <TrainingQuestion
            className={
              ((queEs !== '' && quienOtorga === '')
                || (lapiz[0].routine === 4 && lapiz[0].active === 2))
              && (!(lapiz[0].routine === 4 && lapiz[0].active === 1)
                || editQueEs === true)
              && editQuienOtorga !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="califQuest1"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                quienOtorga !== ''
                && (editQuienOtorga === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
            />
            <CardTwo
              classCard={
                quienOtorga !== ''
                && (editQuienOtorga === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={quienOtorga}
              colorTraining={trainingColor}
            ></CardTwo>

            {quienOtorga !== ''
            && (editQuienOtorga === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 2)) ? (
              <PymesCardsTwo
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                title={
                  isCorrectQuienOtorga
                    ? '¡Sí que sabes del tema!'
                    : '¡A veces se cree eso, pero no es así!'
                }
                nextQuestionQuienOtorga={nextQuestionQuienOtorga}
                handleNextQuestion={() => handleNextQuestion({ numberQuestion: 1 })
                }
              />
              ) : null}
          </DivRespDP>
        </>
      ) : null}

      {/* pregunta #3 */}
      <div id="activeQuest2"></div>
      {quienOtorga !== ''
      && (editQuienOtorga === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
      && nextQuestionQuienOtorga !== '' ? (
        <QuestionsOrder
          classCard={quienOtorga !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      {nextQuestionQuienOtorga !== '' ? (
        <>
          <TrainingQuestion
            className={
              ((quienOtorga !== '' && tipoCreditoPyme === '')
                || (lapiz[0].routine === 4 && lapiz[0].active === 3))
              && (!(lapiz[0].routine === 4 && lapiz[0].active === 2)
                || editQuienOtorga === true)
              && editTipoCreditoPyme !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="califQuest2"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                tipoCreditoPyme !== ''
                && (editTipoCreditoPyme === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 3))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
            />
            <CardTwo
              classCard={
                tipoCreditoPyme !== ''
                && (editTipoCreditoPyme === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 3))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={tipoCreditoPyme}
              colorTraining={trainingColor}
            ></CardTwo>

            {tipoCreditoPyme !== ''
            && (editTipoCreditoPyme === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 3)) ? (
              <PymesCardsThree
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                title={
                  isCorrectTipoCreditoPyme
                    ? '¡Parece que tenemos un experto en el tema! 👍'
                    : '¡Es normal confundirse con este tema, pero te mostraré lo correcto!'
                }
                nextQuestionTipoCreditoPyme={nextQuestionTipoCreditoPyme}
                handleNextQuestion={() => handleNextQuestion({ numberQuestion: 2 })
                }
              />
              ) : null}
          </DivRespDP>
        </>
      ) : null}

      {/* pregunta #4 */}
      <div id="activeQuest3"></div>
      {tipoCreditoPyme !== ''
      && (editTipoCreditoPyme === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 3))
      && nextQuestionTipoCreditoPyme !== '' ? (
        <QuestionsOrder
          classCard={tipoCreditoPyme !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      {nextQuestionTipoCreditoPyme !== '' ? (
        <>
          <TrainingQuestion
            className={
              ((tipoCreditoPyme !== '' && comoUsan === '')
                || (lapiz[0].routine === 4 && lapiz[0].active === 4))
              && (!(lapiz[0].routine === 4 && lapiz[0].active === 3)
                || editTipoCreditoPyme === true)
              && editComoUsan !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="califQuest3"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                comoUsan !== ''
                && (editComoUsan === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 4))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 4 })}
            />
            <CardTwo
              classCard={
                comoUsan !== ''
                && (editComoUsan === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 4))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={comoUsan}
              colorTraining={trainingColor}
            ></CardTwo>

            {comoUsan !== ''
            && (editComoUsan === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 4)) ? (
              <PymesCardsFour
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                title={
                  isCorrectComoUsan
                    ? '¡Andas imparable!'
                    : '¡No es del todo correcto!'
                }
                nextQuestionComoUsan={nextQuestionComoUsan}
                handleNextQuestion={() => handleNextQuestion({ numberQuestion: 3 })
                }
              />
              ) : null}
          </DivRespDP>
        </>
      ) : null}

      {/* pregunta #5 */}
      <div id="activeQuest4"></div>
      {comoUsan !== ''
      && (editComoUsan === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 4))
      && nextQuestionComoUsan !== '' ? (
        <QuestionsOrder
          classCard={comoUsan !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={4}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      {nextQuestionComoUsan !== '' ? (
        <>
          <TrainingQuestion
            className={
              ((comoUsan !== '' && garantias === '')
                || (lapiz[0].routine === 4 && lapiz[0].active === 5))
              && (!(lapiz[0].routine === 4 && lapiz[0].active === 4)
                || editComoUsan === true)
              && editGarantias !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="califQuest4"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                garantias !== ''
                && (editGarantias === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 5))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 5 })}
            />
            <CardTwo
              classCard={
                garantias !== ''
                && (editGarantias === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 5))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={garantias}
              colorTraining={trainingColor}
            ></CardTwo>

            {garantias !== ''
            && (editGarantias === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 5)) ? (
              <PymesCardsFive
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                title={isCorrectGarantias ? '¡Vas muy bien!' : '¡Por ahí vas!'}
                nextQuestionGarantias={nextQuestionGarantias}
                handleNextQuestion={() => handleNextQuestion({ numberQuestion: 4 })
                }
              />
              ) : null}
          </DivRespDP>
        </>
      ) : null}

      {/* pregunta #6 */}
      <div id="activeQuest5"></div>
      {garantias !== ''
      && (editGarantias === true
        || !(lapiz[0].routine === 4 && lapiz[0].active === 5))
      && nextQuestionGarantias !== '' ? (
        <QuestionsOrder
          classCard={garantias !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={5}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        ) : null}
      {nextQuestionGarantias !== '' ? (
        <>
          <TrainingQuestion
            className={
              ((garantias !== '' && requisitos === '')
                || (lapiz[0].routine === 4 && lapiz[0].active === 6))
              && (!(lapiz[0].routine === 4 && lapiz[0].active === 5)
                || editGarantias === true)
              && editRequisitos !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="5-5"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="califQuest5"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                requisitos !== ''
                && (editRequisitos === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 6))
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 6 })}
            />
            <CardTwo
              classCard={
                requisitos !== ''
                && (editRequisitos === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 6))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={requisitos}
              colorTraining={trainingColor}
            ></CardTwo>

            {requisitos !== ''
            && (editRequisitos === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 6)) ? (
              <>
                <PymesCardsSix
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  title={
                    isCorrectRequisitos ? '¡Súper!' : '¡Se parecen, pero no!'
                  }
                  nextQuestionRequisitos={nextQuestionRequisitos}
                  handleNextQuestion={() => handleNextQuestion({ numberQuestion: 5 })
                  }
                />
              </>
              ) : null}
          </DivRespDP>
        </>
      ) : null}
    </Fragment>
  );
};

export default QuestionsPymes;
