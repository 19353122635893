import React, { Fragment } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  TitleEntrenamiento,
  Container,
} from '../../assets/styles/Home.styled';
import {
  ContentHomeSection, Rectangle24,
  Vector2,
  Vector3,
} from '../common/Home.styled';
import {
  Col4, Col12, Col5, Col6,
  ParagrapTwo,
} from '../common/common.styled';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import ShareCommonFeed from '../../anaCoach/Training/ShareCommonFeed';

// Modal
// MODAL
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// END MODAL

function ModalAprendi({
  openApendi, handleCloseAprendi, nameTitle, aprendi1, aprendi2, aprendi3, urlToShare,
}) {
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '400px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '450px',
    },
  }));

  const ModalShare = () => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
      setOpen(false);
      setShowShareModal(false);
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    };

    return (
            <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo
                        fontWeight="700"
                        cursor="pointer"
                        texttalign="right"
                        textalignMobile="right"
                        className="mx-auto"
                        onClick={handleClose}
                    >
                        X
                 </ParagrapTwo>
                    <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
                        <ContentHomeSection leftD="0%" floatD="left" width="48%;"></ContentHomeSection>
                        <ImageCoachInicio />
                        <ContentHomeSection leftD="0%" floatD="left" width="35%;"></ContentHomeSection>
                    </ContentHomeSection>
                    <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
                        <Rectangle24
                            positionD="absolute"
                            Ptop="125px"
                            PTopM="-65px"
                            Pleft="-10%"
                            background="#6BD5AF"
                            displayM="block"
                            displayD=" "
                            left=" "
                            top=" "
                            width="70px"
                            height="70px"
                            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
                        />
                        <Vector2
                            width="55px"
                            left="0"
                            PleftM="35%"
                            height="65px"
                            background="#91D770"
                            radius="40%"
                            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                            positionD="absolute"
                            Pleft="17%"
                            Ptop="-5px"
                            displayM="block"
                        />
                        <Vector3
                            position="absolute"
                            left=" "
                            top=" "
                            Ptop="10px"
                            Pleft="44%"
                            background=" #E3E660;"
                            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                            width="35px"
                            height="35px"
                            displayD=" "
                            displayM="none"
                        />
                        <Rectangle24
                            positionD="absolute"
                            Ptop="100px"
                            Pleft="74%"
                            PLeftM="-78%"
                            PTopM="-32%"
                            background="#BFDD6A"
                            displayM="block"
                            displayD=" "
                            left=" "
                            top=" "
                            width="30.94px"
                            height="30.94px"
                            transform="rotate(46.41deg)"
                        />
                        <Vector2
                            width="50px"
                            widthM="28.81px"
                            heightM="35.02px"
                            left=""
                            height="60px"
                            background="#6BD5AF"
                            radius="40%"
                            transform="rotate(-8.34deg)"
                            positionD="absolute"
                            Pleft="52%"
                            PleftM="-15%"
                            PTopM="-10%"
                            MtopM="-15%"
                            Ptop="135px"
                            displayM="block"
                        />
                        <Vector3
                            position="absolute"
                            left=" "
                            top=" "
                            Ptop="85px"
                            Pleft="14%"
                            PleftM="-3%"
                            background="#E3E660"
                            transform="rotate(-15.06deg)"
                            width="35px"
                            height="35px"
                            displayD=" "
                            displayM="block"
                        />
                        <Rectangle24
                            widthM="35px"
                            heightM="35px"
                            mLeftM="0"
                            mTopM="18%"
                            positionD="absolute"
                            Pleft="70%"
                            Ptop="0"
                            displayD=""
                            width="60px"
                            height="60px"
                            left=" "
                            top=" "
                            background="#91D770"
                            transform="rotate(-17.24deg)"
                        />
                        <ShareCommonFeed
                            url={urlToShare}
                            trainingName="Logros"
                            textShare="Ayuda a que más personas eliminen su estrés financiero con Coru. Comparte en:"></ShareCommonFeed>
                    </ContentHomeSection>
                </div>
            </Modal>
    );
  };

  return (
        <Fragment>
            <Modal open={openApendi} onClose={handleCloseAprendi} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className="modalShare" >
                    <ParagrapTwo fontWeight="700" cursor="pointer" texttalign="right" textalignMobile="right" className='mx-auto' onClick={handleCloseAprendi} >X</ParagrapTwo>
                    <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
                        <Rectangle24 positionD="absolute" Ptop= "40px" mLeftM="0" PLeftM="26%" mTopM="22%" Pleft="5%" background="#6BD5AF" displayM="none" displayD=" " left=" " top=" " width="60px" height="60px" widthM="30px" heightM="30px" transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);" />
                        <Vector2 width="60px" left="0" height="70px" widthM="30px" heightM="40px" background="#91D770" radius="40%" transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)" positionD ="absolute" Pleft="10%" Ptop="172px" PleftM="30%" PtopM="88px" displayM="none" />
                        <Vector3 position="absolute" left=" " top=" " PtopM="0" PleftM="40%" Ptop="280px" Pleft ="3%" background=" #E3E660;" transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)" width="55px" height="55px" widthM="35px" heightM="35px" displayD=" " displayM="block" />
                        <ContentHomeSection leftD="0%" floatD="left" width="25%;" widthM="0"></ContentHomeSection>
                        <ContentHomeSection leftD="0%" floatD="left" width="50%;" widthM="100%">
                            <Rectangle24 positionD="absolute" Ptop= "162px" mLeftM="0" PLeftM="5%" mTopM="5%" Pleft="70%" background="#BFDD6A" displayM="block" displayD=" " left=" " top=" " width="30.94px" height="30.94px" widthM="25px" heightM="25px" transform="rotate(46.41deg)" />
                            <Vector2 width="50px" left="0" height="60px" background="#6BD5AF" radius="40%" transform="rotate(-8.34deg)" positionD ="absolute" Pleft="81%" Ptop="146px" PleftM="36%" PtopM="22px" displayM="block" />
                            <Vector3 position="absolute" left=" " top=" " Ptop="12px" Pleft ="82%" PtopM="100px" PleftM ="83%" background="#E3E660" transform="rotate(-15.06deg)" width="80px" height="80px" widthM="35px" heightM="35px" displayD=" " displayM="block" />
                            <Rectangle24 positionD="absolute" mLeftM="0" PLeftM="15%" mTopM="-65%" Pleft="87%" Ptop="250px" displayD="" width="60px" height="60px" widthM="32px" heightM="32px" left=" " top=" " background="#91D770" transform="rotate(-17.24deg)" />
                        </ContentHomeSection>
                        <ContentHomeSection leftD="0%" floatD="left" width="25%;" widthM="0"></ContentHomeSection>
                    </ContentHomeSection>
                    <ContentHomeSection displayD="flex" leftD="10%" floatD="left" width="80%;" topD="-10px" topDW="-85px">
                        <ContentHomeSection leftD="0%" floatD="left" width="100%;" widthM="100%" className="Container">
                            <Container>
                                <Col12>
                                    <TitleEntrenamiento className="roboto" margintopM="36%" aling="center" left="0px" margin="auto" top=" ">¡Felicidades, terminaste el entrenamiento <br/> {nameTitle}!</TitleEntrenamiento>
                                </Col12>
                            </Container>
                            <Container>
                                <Col5 back="#F3F3F3" padding="2%" mt="5%!important" bottom="3%!important">
                                    <TitleEntrenamiento weight="400" className="roboto" left="0px" margin="auto" top=" " bottom=" " size ="16px" widthD="100%">Ahora sabes que significa:</TitleEntrenamiento>
                                </Col5>
                            </Container>
                            <Container>
                                <Col4 padding="2%">
                                    <div style={{ background: '#F3F3F3' }} className="heightext">
                                        <CheckBoxIcon style={{ color: '#27AE60', fontSize: 23, marginTop: '10px' }} />
                                        <TitleEntrenamiento weight="400" sizem="12px" className="roboto" left="0px" margin="auto" top="10px" size ="14px" widthD="100%">{aprendi1}</TitleEntrenamiento>
                                    </div>
                                </Col4>
                                <Col4 padding="2%">
                                    <div style={{ background: '#F3F3F3' }} className="heightext">
                                        <CheckBoxIcon style={{ color: '#27AE60', fontSize: 23, marginTop: '10px' }} />
                                        <TitleEntrenamiento weight="400" sizem="12px" className="roboto" left="0px" margin="auto" top="10px" size ="14px" widthD="100%">{aprendi2}</TitleEntrenamiento>
                                    </div>
                                </Col4>
                                <Col4 padding="2%">
                                    <div style={{ background: '#F3F3F3' }} className="heightext">
                                        <CheckBoxIcon style={{ color: '#27AE60', fontSize: 23, marginTop: '10px' }} />
                                        <TitleEntrenamiento weight="400" sizem="12px" className="roboto" left="0px" margin="auto" top="10px" size ="14px" widthD="100%">{aprendi3}</TitleEntrenamiento>
                                    </div>
                                </Col4>
                            </Container>
                            <Container>
                                <Col6 back="#F3F3F3" padding="2%" mt="5%!important" pt=" ">
                                    <TitleEntrenamiento weight="400" sizem="14px" size="18px" className="roboto" left="0px" margin="auto" top=" " bottom=" " widthD="100%">Entrenamiento completo al:</TitleEntrenamiento>
                                </Col6>
                                <Col6 padding="2%" mt="5%!important" pt=" " ta="center">
                                    <TitleEntrenamiento color="#F64282" className="roboto" left="0px" margin="auto" top=" " bottom=" " size ="22px" widthD="100%" aling="center">100%</TitleEntrenamiento>
                                </Col6>
                            </Container>
                            <Container>
                                <CardsBtn
                                    windthmd="90%"
                                    minmd="40%"
                                    mleftmd="5%"
                                    mrightmd="auto"
                                    windthG="400px"
                                    min_w="400px"
                                    min_web="400px"
                                    min_mini="80%"
                                    mleftmini="10%"
                                    mrightmini="10%"
                                    mleft="15%"
                                    mright="15%"
                                    mleftweb="18%"
                                    mrightweb="auto"
                                    mtop="25px"
                                    mtopw="25px"
                                    type="button"
                                    color="#EC407A !important"
                                    back="#fff !important"
                                    border="solid 1px !important"
                                    padd="8px 15px 4px"
                                    heigth="49px"
                                    className=""
                                    onClick={() => setShowShareModal(true)}>
                                    <b>Compartir entrenamiento</b>
                                </CardsBtn>
                            </Container>
                        </ContentHomeSection>
                    </ContentHomeSection>
                </div>
            </Modal>
            {showShareModal
              ? <ModalShare />
              : null
            }
        </Fragment>
  );
}

export default ModalAprendi;
