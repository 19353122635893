import React from 'react';
import manOne from '../../assets/img/anacoach/chat.png';
import '../../assets/styles/Card1Animate.css';

const path = window.location.pathname;
let backColor = '';
if (path.includes('funnel-prestamos')) {
  backColor = '#BE7AE6';
} else if (path.includes('obtener-primer-tarjeta')) {
  backColor = '#DE98FF';
} else if (path.includes('comparar-tarjeta')) {
  backColor = '#917AFF';
} else if (path.includes('debts')) {
  backColor = '#E3E660';
} else if (path.includes('family-protection')) {
  backColor = '#61D2FA';
} else if (path.includes('profiling')) {
  backColor = '#FA9878';
} else if (path.includes('emergency-saving')) {
  backColor = '#7FBD62';
} else if (path.includes('investment-test')) {
  backColor = '#91D770';
} else if (path.includes('investment-catalog')) {
  backColor = '#99F2A8';
} else if (path.includes('funnel-tarjeta')) {
  backColor = '#a370ff';
} else if (path.includes('ExtraIncome')) {
  backColor = '#58D65C';
} else if (path.includes('first-investment')) {
  backColor = '#6BD5AF';
} else if (path.includes('TrainingBudget')) {
  backColor = 'rgb(245, 168, 56)';
} else if (path.includes('new-years-purpose')) {
  backColor = '#F97070';
} else if (path.includes('profiling2_0')) {
  backColor = '#FA9878';
} else if (path.includes('sin-historial')) {
  backColor = '#917AFF';
}

// console.log('**************Card2**********');

const CardTwo = ({
  classCard,
  classTop,
  children,
  textT,
  text,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  text10,
  imgMan,
  array = [],
  icon = false,
  handleChangeIcon,
  colorTraining,
}) => (

  <div className={`${classCard} ${classTop}`}>
    <div
      className="d-block"
      style={
        colorTraining !== undefined
          ? { backgroundColor: colorTraining, padding: '0 2% 0 2%' }
          : { backgroundColor: backColor, padding: '0 2% 0 2%' }
      }
    >
      {!icon ? children : null}
      <b>
        <p>{textT}</p>
      </b>
      <p>{text}</p>
      <p>{text2}</p>
      <p>{text3}</p>
      <p>{text4}</p>
      <p>{text5}</p>
      <p>{text6}</p>
      <p>{text7}</p>
      <p>{text8}</p>
      <p>{text9}</p>
      <p>{text10}</p>
      {array.length !== 0 ? (
        <ul>
          {array.map((item, idx) => (
            <li key={idx}>
              {item}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
    <img alt="man" className={`${imgMan}`} src={manOne} />
    {icon ? children : null}
  </div>
);
export default CardTwo;
