import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DivLoader } from '../../assets/styles/Training.styled';

import GloveBoxProfile from '../Routines/GloveBoxProfile.jsx';
import GloveBoxData from './GloveBoxData.jsx';

import insurancePolicy from '../../assets/img/anacoach/insurancePolicy.svg';
import assistancePolicy from '../../assets/img/anacoach/assistancePolicy.svg';
import carService from '../../assets/img/anacoach/carService.svg';
import TAG from '../../assets/img/anacoach/TAG.svg';
import driverLicense from '../../assets/img/anacoach/driverLicense.svg';
import vehicleRegistration from '../../assets/img/anacoach/vehicleRegistrationCertificate.svg';
import tenancy from '../../assets/img/anacoach/tenancy.svg';
import verification from '../../assets/img/anacoach/verification.svg';

const RoutinesOrderGloveBox = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  trainingColor,
  setQuestions,
}) => {
  const [flagRoutine, setFlagRoutine] = useState({
    routine3: false,
  });
  const libreta = [
    {
      nm: 'Póliza de seguro',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Vigencia de la póliza',
      im: insurancePolicy,
    },
    {
      nm: 'Póliza de asistencia en el camino',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Vigencia de la póliza',
      im: assistancePolicy,
    },
    {
      nm: 'Servicio de auto',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Fecha de último servicio',
      im: carService,
    },
    {
      nm: 'TAG',
      ds: 'Número de TAG',
      im: TAG,
    },
    {
      nm: 'Licencia de conducir',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Vigencia de tu licencia',
      im: driverLicense,
    },
    {
      nm: 'Tarjeta de circulación',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Vigencia de tu tarjeta',
      im: vehicleRegistration,
    },
    {
      nm: 'Tenencia',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Fecha de pago',
      im: tenancy,
    },
    {
      nm: 'Verificación',
      ut: 'Cargar documento (Peso máximo 5MB)',
      ds: 'Fecha de próxima verificación',
      im: verification,
    },
  ];
  return (
    <div>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <GloveBoxProfile
                    Date={Date}
                    hora={hora}
                    orderRutine={orderRutine}
                    setOrderRutine={setOrderRutine}
                    currentTraining={currentTraining}
                    createJsonInput={createJsonInput}
                    questions={questions}
                    setQuestions={setQuestions}
                    trainingColor={trainingColor}
                    currentRoutine={1}
                    nextOrderRoutine={2}
                />
              </div>
          </div>
        </Suspense>
      ) : null}
      {orderRutine >= 2 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={2}
              nextOrderRoutine={3}
              libreta={libreta[0]}
              flagRoutine={flagRoutine}
              setFlagRoutine={setFlagRoutine}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 3 && flagRoutine.routine3 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={3}
              nextOrderRoutine={4}
              libreta={libreta[1]}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 4 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={4}
              nextOrderRoutine={5}
              libreta={libreta[2]}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 5 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={5}
              nextOrderRoutine={6}
              libreta={libreta[3]}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 6 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={6}
              nextOrderRoutine={7}
              libreta={libreta[4]}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 7 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={7}
              nextOrderRoutine={8}
              libreta={libreta[5]}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 8 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={8}
              nextOrderRoutine={9}
              libreta={libreta[6]}
            />
          </div>
        </div>
      ) : null}
      {orderRutine >= 9 ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <GloveBoxData
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              trainingColor={trainingColor}
              currentRoutine={9}
              nextOrderRoutine={10}
              libreta={libreta[7]}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

RoutinesOrderGloveBox.propTypes = {
  Date: PropTypes.object.isRequired,
  hora: PropTypes.string.isRequired,
  orderRutine: PropTypes.number.isRequired,
  setOrderRutine: PropTypes.func.isRequired,
  currentTraining: PropTypes.number.isRequired,
  createJsonInput: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  trainingColor: PropTypes.string.isRequired,
  setQuestions: PropTypes.func.isRequired,
};

export default RoutinesOrderGloveBox;
