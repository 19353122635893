import Clientes from '../../components/Clientes';
import Phone_Validate from '../../components/common/PhoneValidate';
import ParaTi from '../../anaCoach/pantallasInicio/ParaTi';
import AutocompleteAddress from '../../components/common/AutocompleteAddress';
import UrlShortener from '../../anaCoach/UrlShortener';
import CcStep_1 from '../../components/common/CC/ccStep1';
import Cc2Comparativo from '../../components/common/CC/ccStep2Comparativo';
import Cc3InfoPersonal from '../../components/common/CC/CcStep3InfoPersonal';
import Cc4Domicilio from '../../components/common/CC/ccStep4Domicilio';
import Cc5Autenticacion from '../../components/common/CC/CcStep5Authentication';
import Cc5Laboral from '../../components/common/CC/CcStep5Laboral';
import CcLogin from '../../components/common/CC/CcLogin';
import CcForgotPass from '../../components/common/CC/CcForgotPass';
import CcResetPassword from '../../components/common/CC/CcResetPassword';
import CcPhoneValidate from '../../components/common/CC/CcPhoneValidate';
import CcValidateOtp from '../../components/common/CC/CcValidateOTP';
import CcPersonalInformationContainer from '../../components/common/CC/CcPersonalinformation';
import CcPasswordNew from '../../components/common/CC/CcPasswordNew';
import SavePixelActivated from '../../components/common/SavePixelActivated';
import MagicLink from '../../components/MagicLink';
// import Feed from "../anaCoach/Feed";
import Landing from '../../MVP/ComponentsMVP/Landing';
import Form from '../../MVP/ComponentsMVP/Form';
import Description from '../../MVP/ComponentsMVP/Description';
import Contact from '../../MVP/ComponentsMVP/Contact';
import LoginAdmin from '../../components/common/LoginAdmin';
import videoDemo from '../../components/common/videoDemo';
import videoDemo2 from '../../components/common/videoDemo_2';

const Extra = {
  Clientes,
  Phone_Validate,
  ParaTi,
  AutocompleteAddress,
  UrlShortener,
  CcStep_1,
  Cc2Comparativo,
  Cc3InfoPersonal,
  Cc4Domicilio,
  Cc5Autenticacion,
  Cc5Laboral,
  CcLogin,
  CcForgotPass,
  CcResetPassword,
  CcPhoneValidate,
  CcValidateOtp,
  CcPersonalInformationContainer,
  CcPasswordNew,
  SavePixelActivated,
  Landing,
  Form,
  Description,
  Contact,
  LoginAdmin,
  videoDemo,
  videoDemo2,
  MagicLink,
};
export default (Extra);
