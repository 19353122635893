/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Background,
  Subtitle,
  Container,
  GetStartContainer,
  StartText,
  CardsContainer,
  Card,
  TitleCard,
  TextCard,
  WrapperT
} from "./styles.jsx";
import ClickButton from "../../../Helpers/HookHelpers";

/* assets */
import reduceCAC from "../Assets/Imgs/reduce_CAC.svg";
import aumentaLTV from "../Assets/Imgs/aumenta_LTV.svg";
import NETSCORE from "../Assets/Imgs/NETSCORE.png";
/* styles */
import {
 ImgGeneric, SubContent, TextTitle1, LpContainerImageDescription, LpTextContainerImageDescription,
} from "../Assets/Styles/CaasNew.styles.js";

const WhyCoru = ({
  i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, url, WCoru2_Description
}) => {
  const [overCardId, setOverCardId] = useState(0);
  const { actions } = ClickButton();
  const cardList = [
    {
      title: i5, description: i6, id: 1, img: reduceCAC, textInf: WCoru2_Description, subTextinf: undefined, textLTV: undefined, subTextinfLTV: undefined,
    },
    {
      title: i7, description: i8, id: 2, img: NETSCORE, textInf: undefined, subTextinf: undefined, textLTV: undefined, subTextinfLTV: undefined,
    },
    {
      title: i9, description: i10, id: 3, img: aumentaLTV, textInf: undefined, textLTV: 'Lifetime Value', subTextinfLTV: '$4,380.10 USD',
    },
  ];
  const handleClickCoru = () => {
    actions({
      action: 'ClickButtonLPCaaS',
      variables: `Click_btndemoPorque_no.wo`,
      collection: 'ClickButtonLPCaaS',
    });
    window.open(url, '_blank');
  };

  return (
    <WrapperT>
      <Background>
        <Container>
          <SubContent width="-webkit-fill-available" widthM="100%" color='#F64282' msize='20px'>{i1}</SubContent>
          <TextTitle1 weight="900" mobilMargin='28px 0 0 0' margin='28px 0 0 0'>
            {i2}
          </TextTitle1>
          <GetStartContainer>
            <SubContent>
              {i3}
            </SubContent>
          </GetStartContainer>
          <CardsContainer margin="88px auto 20px">
              <Card
                key={cardList[0].title}
                isOver={overCardId === cardList[0].id}
                /* onMouseOver={() => setOverCardId(cardList[0].id)} */
              >
                <TitleCard>{cardList[0].title}</TitleCard>
                <TextCard fontSize="12px" justifyContent="center">{cardList[0].description}</TextCard>
                <ImgGeneric src={cardList[0].img} margin="30px auto 0" marginTopMds="30px" />
                <LpContainerImageDescription>
                  <LpTextContainerImageDescription>
                    {cardList[0].textInf}
                  </LpTextContainerImageDescription>
                </LpContainerImageDescription>
              </Card>

              <Card
                key={cardList[1].title}
                isOver={overCardId === cardList[1].id}
                /* onMouseOver={() => setOverCardId(cardList[1].id)} */
              >
                <TitleCard>{cardList[1].title}</TitleCard>
                <TextCard fontSize="12px" justifyContent="center">{cardList[1].description}</TextCard>
                <ImgGeneric src={cardList[1].img} margin="30px auto 0" marginTopMds="30px" />
              </Card>

              <Card
                key={cardList[2].title}
                isOver={overCardId === cardList[2].id}
                /* onMouseOver={() => setOverCardId(cardList[2].id)} */
              >
                <TitleCard>{cardList[2].title}</TitleCard>
                <TextCard fontSize="12px" justifyContent="center">{cardList[2].description}</TextCard>
                <ImgGeneric src={cardList[2].img} margin="30px auto 0" marginTopMds="30px" />
              </Card>
          </CardsContainer>
          {/* <TitleSecondary>{idc('GoalOriented')}</TitleSecondary>
          <Agiletext>{idc('GoalOriented_Title')}</Agiletext>
          <EnsuringText>
            {idc('GoalOriented_Subtitle')}
          </EnsuringText>
          <CaruselContainer>
            <CarouselProfesion idc={idc} />
          </CaruselContainer> */}
        </Container>
      </Background>
    </WrapperT>
  );
};

export default WhyCoru;
