import React from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import { day, months } from '../../Helpers';

const GloveBoxSelect = ({
  routine,
  form,
  handleChange,
  handleChangeQuestionsSend,
  amountService,
  meses,
}) => {
  const { QuestionName } = routine;

  // CONTANDO LOS AÑOS A MOSTRAR
  const years = [];
  const fecha = new Date();
  const anio = fecha.getFullYear();
  for (let i = anio - 10; i < anio + 11; i += 1) {
    years.push({
      value: i,
      label: i,
    });
  }

  // GUARDA Y ACTUALIZA EL ESTADO DE LO QUE CONTESTE EL USUARIO
  const actionStorage = (e) => {
    handleChange(e, routine.QuestionsOrder, amountService);
    // En value se pone el valor que será guardado en BD
    handleChangeQuestionsSend(
      routine.QuestionsOrder,
      routine.QuestionName,
      String(e.target.value),
      'SelectBasic',
    );
  };

  return (
    <>
      {QuestionName === 'Día' ? (
        <TextField
          key={routine}
          variant="outlined"
          id="day"
          name="day"
          select
          label="Día"
          helperText=""
          fullWidth
          className="input-coru"
          value={form.day}
          onChange={(e) => actionStorage(e)}
        >
          {day.map((option) => (
              <MenuItem
              key={option.value}
              value={option.value}
              >
              {option.label}
              </MenuItem>
          ))
          }
        </TextField>
      ) : null}
      {QuestionName === 'Mes' ? (
        <TextField
          variant="outlined"
          id="month"
          name="month"
          select
          label="Mes"
          helperText=""
          fullWidth
          className="input-coru"
          value={form.month}
          onChange={(e) => actionStorage(e)}
          inputProps={{ className: 'perfil' }}
        >
          {meses.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))
          }
        </TextField>
      ) : null}
      {QuestionName === 'Año' ? (
        <TextField
            variant="outlined"
            id="year"
            name="year"
            select
            label="Año"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.year}
            onChange={(e) => actionStorage(e)}
        >
            {years.map((option) => (
            <MenuItem
            key={option.value}
            value={option.value}
            >
            {option.label}
            </MenuItem>
            ))
        }
        </TextField>
      ) : null}
      {QuestionName === 'Aseguradora' ? (
        <TextField
            variant="outlined"
            id="insurance"
            name="ase"
            label="Aseguradora"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.ase}
            onChange={(e) => actionStorage(e)}
        >
      </TextField>
      ) : null}
      {QuestionName === 'Cobertura' ? (
        <TextField
            variant="outlined"
            id="coverage"
            name="cove"
            select
            label="Cobertura"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.cove}
            onChange={(e) => actionStorage(e)}
        >
            {routine.detailQuestion.map((option) => (
                <MenuItem
                    key={option.CatFieldDetail_IDCatFieldDetail}
                    value={option.QuestionOption}
                >
                {option.QuestionOption}
                </MenuItem>
            ))
            }
        </TextField>
      ) : null}
      {QuestionName === 'Formas de pago' ? (
        <TextField
            variant="outlined"
            id="payMethod"
            name="pay"
            select
            label="Forma de pago"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.pay}
            onChange={(e) => actionStorage(e)}
        >
            {routine.detailQuestion.map((option) => (
                <MenuItem
                key={option.CatFieldDetail_IDCatFieldDetail}
                value={option.QuestionOption}
                >
                {option.QuestionOption}
                </MenuItem>
            ))
            }
        </TextField>
      ) : null}
      {QuestionName === 'Empresa' ? (
        <TextField
            variant="outlined"
            id="company"
            name="compa"
            label="Empresa"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.compa}
            onChange={(e) => actionStorage(e)}
        >
        </TextField>
      ) : null}
      {QuestionName === 'Díaf' ? (
        <TextField
          key={routine}
          variant="outlined"
          id="day2"
          name="day2"
          select
          label="Día"
          helperText=""
          fullWidth
          className="input-coru"
          value={form.day2}
          onChange={(e) => actionStorage(e)}
          >
          {day.map((option) => (
              <MenuItem
              key={option.value}
              value={option.value}
              >
              {option.label}
              </MenuItem>
          ))
          }
        </TextField>
      ) : null}
      {QuestionName === 'Mesf' ? (
        <TextField
          variant="outlined"
          id="month2"
          name="month2"
          select
          label="Mes"
          helperText=""
          fullWidth
          className="input-coru"
          value={form.month2}
          onChange={(e) => actionStorage(e)}
          inputProps={{ className: 'perfil' }}
        >
          {months.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))
          }
        </TextField>
      ) : null}
      {QuestionName === 'Añof' ? (
        <TextField
            variant="outlined"
            id="year2"
            name="year2"
            select
            label="Año"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.year2}
            onChange={(e) => actionStorage(e)}
        >
            {years.map((option) => (
            <MenuItem
            key={option.value}
            value={option.value}
            >
            {option.label}
            </MenuItem>
            ))
        }
        </TextField>
      ) : null}
      {QuestionName === 'Número de TAG' ? (
        <TextField
            variant="outlined"
            id="tag"
            name="numtag"
            label="Introducir número"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.numtag}
            onChange={(e) => actionStorage(e)}
            >
        </TextField>
      ) : null}
      {QuestionName === 'Calcomanía' ? (
        <TextField
            variant="outlined"
            id="calc"
            name="calc"
            label="Calcomanía"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.calc}
            onChange={(e) => actionStorage(e)}
            >
        </TextField>
      ) : null}
      {QuestionName === 'Engomado' ? (
        <TextField
            variant="outlined"
            id="engoma"
            name="engoma"
            label="Engomado"
            helperText=""
            fullWidth
            className="input-coru"
            value={form.engoma}
            onChange={(e) => actionStorage(e)}
            >
        </TextField>
      ) : null}
    </>
  );
};

GloveBoxSelect.propTypes = {
  routine: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeQuestionsSend: PropTypes.func.isRequired,
  amountService: PropTypes.number.isRequired,
  meses: PropTypes.array.isRequired,
};

export default GloveBoxSelect;
