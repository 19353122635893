import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  ImageLoad,
} from '../common/common.styled';
import {
  Rectangle13,
  Rectangle24,
  Vector2,
  Vector3,
  UiCard,
  TextoCoach,
  ContentHomeSection,
} from '../common/Home.styled';
import ImageCoachInicio from './ImageCoachInicio';

// import ImageLoader from '../../assets/img/anacoach/loader_coru.svg';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: 'auto',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function ModalDataPersonalInitWO({ modalRec, isData, setData }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  useEffect(() => {
    if (modalRec !== undefined) {
      if (
        !modalRec
        || sessionStorage.getItem('Birthdate') === null
        || sessionStorage.getItem('Birthdate') === ''
      ) {
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        setData(false);
      }
    }
  }, [modalRec]);

  useEffect(() => {
    if (modalRec === undefined) {
      console.log('Demas');
      if (
        sessionStorage.getItem('Birthdate') === null
        || sessionStorage.getItem('Birthdate') === ''
      ) {
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        setData(false);
      } else {
        setTimeout(() => {
          document.getElementsByTagName('html')[0].style.overflow = 'auto';
          setData(false);
        }, 3000);
      }
    }
  }, []);

  if (isData) {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  } else if (
    sessionStorage.getItem('FirstName')
    && sessionStorage.getItem('FathersLastName')
    && sessionStorage.getItem('day')
  ) {
    sessionStorage.setItem('DataPerson', 'true');
    // setData(true);
  } else {
    sessionStorage.setItem('DataPerson', false);
  }

  return (
    <Modal
      open={modalRec !== undefined ? modalRec : isData}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paperModal}>
         <ContentHomeSection
          displayD="flex"
          leftD="0%"
          floatD="left"
          width="100%;"
          topD="10%"
        >
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="48%;"
          ></ContentHomeSection>
          <ImageCoachInicio />
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="35%;"
          ></ContentHomeSection>
        </ContentHomeSection>
        <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
          <Rectangle24
            positionD="absolute"
            Ptop="125px"
            PTopM="-65px"
            Pleft="-10%"
            background="#6BD5AF"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="70px"
            height="70px"
            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
          />
          <Vector2
            width="55px"
            left="0"
            PleftM="35%"
            height="65px"
            background="#91D770"
            radius="40%"
            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
            positionD="absolute"
            Pleft="17%"
            Ptop="-5px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="-15px"
            Pleft="44%"
            background=" #E3E660;"
            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="none"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="100px"
            Pleft="74%"
            PLeftM="-78%"
            PTopM="-32%"
            background="#BFDD6A"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            transform="rotate(46.41deg)"
          />
          <Vector2
            width="50px"
            widthM="28.81px"
            heightM="35.02px"
            left=""
            height="60px"
            background="#6BD5AF"
            radius="40%"
            transform="rotate(-8.34deg)"
            positionD="absolute"
            Pleft="52%"
            PleftM="-15%"
            PTopM="-10%"
            MtopM="-15%"
            Ptop="135px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="85px"
            Pleft="14%"
            PleftM="-3%"
            background="#E3E660"
            transform="rotate(-15.06deg)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            widthM="35px"
            heightM="35px"
            mLeftM="0"
            mTopM="18%"
            positionD="absolute"
            Pleft="70%"
            Ptop="0"
            displayD=""
            width="60px"
            height="60px"
            left=" "
            top=" "
            background="#91D770"
            transform="rotate(-17.24deg)"
          />

          <UiCard
            positionM=" "
            heightM="auto"
            marginTop="150px"
            marginTopM="120px"
          >
            <Rectangle13
              boxSha=" "
              margin="auto"
              bottomM="0px"
              bottom="0px"
              displayM="block"
              position=" "
              topM="0px"
              height="auto"
              width="100%"
              widthm="100%"
              padding="19px 25px 20px 21px"
            >
              <TextoCoach height="auto" className="roboto" width="auto">
                <b>
                  Estamos cargando tu información <br />
                  registrada, espera un momento ...
                </b>
                <br />
                <br />
              </TextoCoach>
              <ImageLoad
                cursor=""
                src=""
                widthweb="60%"
                width="60%"
                mwidth="90%"
                heightMobile="auto"
                alt="loader-coru"
                position=" "
              />
            </Rectangle13>
          </UiCard>
        </ContentHomeSection>
      </div>
    </Modal>
  );
}

export default ModalDataPersonalInitWO;
