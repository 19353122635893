import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import CuestionarioInversion from '../Routines/CuestionarioInversion';
import {
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import { GET_TRAINING_COLOR } from '../../queries';
import CardOne from '../../components/Home/Card1Animate';
import {
  Feed_Main,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import {
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import { Container } from '../../assets/styles/Home.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import ClickButton from '../../Helpers/HookHelpers';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ModalVirality from '../../components/common/ModalVirality';


const RoutinesOrderImproveBudget = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  setSaludo,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [goFeed] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';

  firstname = sessionStorage.getItem('FirstName');
  nameClient = `${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem(
    'FathersLastName',
  )}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');
  const anchop = window.screen.width;
  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    if (orderRutine >= 5) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
      gsap.to(window, { duration: 1, scrollTo: '#activeSuccessImprove' });
    }
  }, [orderRutine]);

  return (
    <>
      {orderRutine >= 1 && orderRutine < 5 ? (
        <DatBasic
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={1}
          nextOrderRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 && orderRutine < 5 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={2}
          nextOrderRoutine={3}
        />
      ) : null}
      {orderRutine >= 3 && orderRutine < 5 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={3}
          nextOrderRoutine={4}
        />
      ) : null}
      {orderRutine >= 4 && orderRutine < 5 ? (
        <CuestionarioInversion
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={4}
          nextOrderRoutine={5}
        />
      ) : null}
      {orderRutine >= 5 ? (
        <Fragment>
          <TrainingSectionGray back="#fafafa">
            <TrainingDivSection2
              wiw="670px"
              paddi="0"
              paddiw="0"
              mw="auto"
              back="#fafafa"
            >
              <div id="activeSuccessImprove"></div>
              <ModalAmazon show={true} wo={'ImproveBudget'}></ModalAmazon>
              <ModalVirality />
              <SectionShare WO="ImproveBudget"></SectionShare>
            </TrainingDivSection2>
          </TrainingSectionGray>
          {recomendaciones ? (
            <Feed_Main width="100%" background=" ">
              <Feed_Main
                width="100%"
                display=" "
                mtop=" "
                background="transparent"
              >
                <CardOne
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  classCard="cardOne"
                  classTop="topOne1"
                  text="¿Aún quieres ir por más?"
                  text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                />
                <CardOne
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  classCard="cardOne"
                  classTop="topOne1"
                  text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                />
              </Feed_Main>
            </Feed_Main>
          ) : null}
          {recomendaciones ? (
            <Fragment>
              <div className="marginComentLgSuccess1">
                <Container
                  mleftmxs="0%"
                  mleft="18%"
                  mleftm="7%"
                  width="100%"
                  widthMobile="100%"
                  mtMobile="0px"
                >
                  <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                    <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                      <Recommendations
                        items={recommendedTraining}
                      ></Recommendations>
                    </ContentHomeSection>
                  </Feed_Section>
                </Container>
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
    </>
  );
};
export default RoutinesOrderImproveBudget;
