import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField, Select, Button, MenuItem,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ContentHomeSection } from '../../common/Home.styled';
import { ParagrapTwo } from '../../common/common.styled';
import { GET_BANKS } from '../../../queries';

const ModalDatosBC = ({
  modalDatosBC,
  setModalDatosBC,
  ultimosDigitos,
  setUltimosDigitos,
  bancoActual,
  setBancoActual,
}) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '500px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '600px',
    },
  }));

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      height: 'auto',
      padding: '0px 32px 24px',
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);
  const [banks, setBanks] = useState([]);

  const [getBanks, { data }] = useLazyQuery(GET_BANKS);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
    setModalDatosBC(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    event.persist();
    switch (name) {
      case 'ultimosDigitos':
        setUltimosDigitos(value.replace(/[^0-9]/i, ''));
        break;
      case 'bancoActual':
        setBancoActual(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      const { getBanks } = data;
      setBanks(getBanks);
    }
  }, [data]);

  useEffect(() => {
    getBanks({
      variables: {
        idTraining: 1,
      },
    });
  }, []);

  return (
    <>
      <Modal
        open={open}
        className={'modal display-block'}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              zindex="1"
              onClick={handleClose}
            >
              X
            </ParagrapTwo>
            <ParagrapTwo
              texttalign="left"
              textalignMobile="left"
              className="mx-auto roboto"
            >
              <b>Datos para consulta BC</b>
            </ParagrapTwo>

            <TextField
              type="tel"
              name="ultimosDigitos"
              value={ultimosDigitos}
              placeholder="Últimos 4 dígitos del número de tu tarjeta de crédito"
              inputProps={{ maxLength: 4 }}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />

            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel
                className="roboto"
                id="demo-simple-select-outlined-label"
              >
                Bancos
              </InputLabel>
              <Select
                name="bancoActual"
                value={bancoActual}
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                label="Bancos"
                onChange={handleChange}
              >
                {banks.map((e, index) => (
                  <MenuItem key={index} value={e.BankName}>
                    {e.BankName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ContentHomeSection>
          <br />
          <Button
            id="btnGenerar"
            name="btnGenerar"
            type="button"
            variant="contained"
            size="large"
            color="primary"
            onClick={handleClose}
            fullWidth
          >
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalDatosBC;
