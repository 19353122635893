import React, { Fragment, useState, useEffect } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useMutation } from '@apollo/client';
import CardOne from '../../components/Home/Card1Animate';
import logoC from '../../assets/img/anacoach/logoCoach.png';
import ana from '../../assets/img/anacoach/CoachImage.png';
import {
  CardsBtn,
  TrainingSectionGray,
  TrainingDivSection,
  DivFlex,
  TrainingDivSection2,
  TrainingText,
  DivShadow,
  Images,
  CoruCoach,
} from '../../assets/styles/Training.styled';
import {
  Image,
} from '../../components/common/common.styled';
import { Container } from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle25,
  Triangle,
} from '../../components/common/Home.styled';
import { Feed_Main } from '../../assets/styles/Feed.styled';
import TaskAdvance from '../../components/Training/TaskAdvance';
import vectorTop from '../../assets/img/anacoach/vectorTop.svg';
import { DYNAMIC_UPDATE } from '../../mutations';
import ClickButton from '../../Helpers/HookHelpers';
import { weekDay, getCurrentDate, removeAccents } from '../../Helpers/general';
import SendNotificationValueOfMoney from '../../components/common/SendNotificationValueOfMoney';

const styles = {
  paddingLeft: '10px',
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  display: 'flex',
};
const styleDays = {
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '10px',
};
const stylesLabel = {
  display: 'flex',
  marginTop: '10px',
  marginBottom: '10px',
  color: 'black',
  fontSize: '18px',
  paddingLeft: '10px',
};
const stylesP = { color: '#FA9D59', fontSize: '16px', paddingLeft: '10px' };
const datos = [];
const HomeworkSchedule = ({
  infoChildren,
  showExitHomeworkSchedule,
  setShowExitHomeworkSchedule,
  acceptTasks,
}) => {
  const { actions } = ClickButton();
  const { sendMessage } = SendNotificationValueOfMoney();
  const [homeworks, setHomework] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [accept, setAccept] = useState(false);
  const [exit, setExit] = useState(false);

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log(dynamicUpdate);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (exit) {
      const click = `Salir_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setShowExitHomeworkSchedule(false);
    }
  }, [exit]);

  useEffect(() => {
    if (accept) {
      const click = `Acepto_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      acceptTasks();
    }
  }, [accept]);
  const saveAdvance = (e, idDatHomework, idHomeworkSchedule) => {
    e.preventDefault();
    console.log('Tarea registrada === ', idDatHomework, idHomeworkSchedule);
    dynamicUpdate({
      variables: {
        input: {
          table: 'DatHomeworkSchedule',
          columns: [
            {
              setkey: 'Status',
              setval: '1',
            },
          ],
          conditions: [
            {
              valone: 'idHomeworkSchedule',
              valtwo: String(idHomeworkSchedule),
              condition: '=',
              logic: '',
            },
            {
              valone: 'DatHomework_idDatHomework',
              valtwo: String(idDatHomework),
              condition: '=',
              logic: 'AND',
            },
          ],
        },
      },
    });
    // envio al padre
    const url = window.location.href.replace(window.location.search, '');
    sendMessage({
      url,
      cellPhone: sessionStorage.getItem('CellPhone'),
      trigger: 'hijo_tareas_avance',
      variables: {
        varNameChildren: 'Nombre_Hijo',
        varFatherName: 'Nombre_Padre',
        varUrlWO: 'URL_WO',
      },
      nameFather: sessionStorage.getItem('FirstName'),
      nameChildren: sessionStorage.getItem('nombreHijo'),
    });
    setShowExitHomeworkSchedule(true);
  };

  const acceptTask = () => {
    // envio al padre
    sendMessage({
      url: '',
      cellPhone: sessionStorage.getItem('CellPhone'),
      trigger: 'hijo_confirmacion_tareas',
      variables: {
        varNameChildren: 'Nombre_Hijo',
        varFatherName: 'Nombre_Padre',
        varUrlWO: '',
      },
      nameFather: sessionStorage.getItem('FirstName'),
      nameChildren: sessionStorage.getItem('nombreHijo'),
    });
    setAccept(true);
  };

  useEffect(() => {
    const task = infoChildren[0].childData.homeworks;
    const currentDate = getCurrentDate();
    const fullDate = removeAccents(weekDay(currentDate));

    if (task) {
      setHomework(task);
      for (let i = 0; i < task.length; i++) {
        const { days } = task[i];
        for (let day = 0; day < days.length; day++) {
          const formatDay = removeAccents(days[day]);
          if (fullDate === formatDay) {
            datos.push({
              taskName: `${i + 1}. ${task[i].name}`,
              taskTime: task[i].time,
              taskInfo: `Recompensa $${task[i].reward}`,
              idDatHomework: task[i].idDatHomework,
              idHomeworkSchedule: task[i].idHomeworkSchedule[day],
              taskStatus:
                task[i].Status[day] === true ? 'Complete' : 'Incomplete',
              day: days[day],
            });
          }
        }
      }
    }
  }, []);
  return (
    <Fragment>
      {showExitHomeworkSchedule ? (
        <Fragment>
          <TrainingDivSection
            back="#FA9D59"
            backw="#FA9D59"
            bsha=""
            mt="-30px"
            mtw=" "
            mbw="-115px"
            mlw="-300px"
            br="4px"
            padding="21px 20px"
            wi="100%"
            wiw="1270px"
            hew="1150px"
            he="850px"
            mb="-102px"
          >
            <Container
              className="col-12"
              width=""
              mt="40px"
              displayweb="flex"
              displaymobile="none"
              widthMobile=""
              mtMobile="0px"
              background="transparent"
              height="289.406px"
            >
              <Rectangle23
                Ptop="81px"
                Pleft="3%"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Triangle
                width="50px"
                height="50px"
                marginleft="-50px"
                margintop="239px"
                transform="rotate(224deg)"
                widthborder="50px solid transparent"
                widthborderColor={'50px solid #FACFA9;'}
              />
              <Rectangle23
                Ptop="105px"
                Pleft="15%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(9.24deg)"
              />
              <Rectangle25
                positionD="absolute"
                background={'#FACFA9'}
                bradius="14px"
                left="17%"
                top="242px"
                width="30px"
                height="35px"
                displayM=" "
                displayD=" "
              />
              <Rectangle23
                Ptop="100px"
                Pleft="24%"
                transform="rotate(-33.24deg)"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Rectangle23
                Ptop="142px"
                Pleft="38%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(9.24deg)"
              />
              <Rectangle25
                positionD="absolute"
                background={'#FACFA9;'}
                bradius="14px"
                left="49%"
                top="89px"
                width="30px"
                height="35px"
                displayM=" "
                displayD=" "
              />
              <Rectangle23
                Ptop="166px"
                Pleft="52%"
                transform="rotate(62.76deg)"
                width="100px"
                height="100px"
                background={'#FACFA9;'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Rectangle23
                Ptop="81px"
                Pleft="64%"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Rectangle23
                Ptop="250px"
                Pleft="70%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(47.24deg)"
              />
              <Rectangle23
                Ptop="19px"
                Pleft="80%"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(47.24deg)"
              />
              <Rectangle25
                positionD="absolute"
                background={'#FACFA9;'}
                bradius="14px"
                left="85%"
                top="242px"
                width="30px"
                height="35px"
                displayM=" "
                displayD=" "
              />
              <Rectangle23
                Ptop="150px"
                Pleft="95%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(47.24deg)"
              />
              <Rectangle23
                Ptop="243px"
                Pleft="97%"
                transform="rotate(62.76deg)"
                width="100px"
                height="100px"
                background={'#FACFA9;'}
                position="absolute"
                displayD=" "
                displayM=""
              />
            </Container>
            <Container
              width="100%"
              mt="40px"
              displayweb="none"
              displaymobile="flex"
              widthMobile="100%"
              mtMobile="0px"
              mtMobilexs="30px"
              background=""
              height="237.031px"
            >
              <Rectangle23
                left="2%"
                Ptop="81px"
                Pleft="3%"
                width="60px"
                height="60px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="10%"
              />
              <Triangle
                width="50px"
                height="50px"
                marginleft="-5px"
                margintop="180px"
                transform="rotate(452deg)"
                widthborder="50px solid transparent"
                widthborderColor={'50px solid #FACFA9;'}
              />
              <Rectangle23
                transform="rotate(11.76deg)"
                left="34%"
                Ptop="90px"
                Pleft="3%"
                width="25px"
                height="25px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="35%"
              />
              <Rectangle23
                left="60%"
                Ptop="81px"
                Pleft="3%"
                width="60px"
                height="60px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="55%"
              />
              <Rectangle23
                transform="rotate(11.76deg)"
                left="90%"
                Ptop="105px"
                Pleft="3%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="82%"
              />
              <Rectangle25
                topM="0px"
                leftM="70%"
                positionD="absolute"
                background={'#FACFA9'}
                bradius="20px"
                left="17%"
                top="242px"
                width="50px"
                height="55px"
                displayM=" "
                displayD=" "
                leftMb="75%"
              />
            </Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="roboto"
            >
              <div>
                <div style={{ display: 'block' }}>
                  <Images
                    src={ana}
                    top="-40%"
                    width="90px"
                    topweb="-55px"
                    left="125px"
                    leftw="145px"
                    position="absolute"
                    positionw="absolute"
                    border={'2px solid #FA9D59'}
                    bs="border-box"
                    padd="5px"
                    br="45px"
                    className="img-fluid"
                    position600=" "
                  ></Images>
                </div>
                <CoruCoach
                  position="absolute"
                  width="30px"
                  height="30px"
                  bcolor={'#FA9D59'}
                  marginTop="30px"
                  marginLeft="205px"
                  leftM2="50%"
                  leftD1="12%"
                  leftD2="14%"
                  leftM="45%"
                  marginM="-20%"
                  marginTopmd="10%"
                  marginM3="-18%"
                >
                  <Images
                    src={logoC}
                    top="9px"
                    topweb="9px"
                    left="4px"
                    leftw="3px"
                    width="24px"
                    position="absolute"
                    className="img-fluid"
                  ></Images>
                </CoruCoach>
              </div>
              <DivShadow
                style={{ background: '#FFFF' }}
                boxShadow="1px 0px 29px -22px rgba(0,0,0,0.75)"
                height="335px"
                width="370px"
                borderR="45%"
                txtAlign="center"
                marginTop="100px"
                marginM3="-40px"
              >
                <p style={{ paddingTop: '195px' }}>
                  ¡Registro exitoso, tus padres estarán muy orgullosos de ti!
                </p>
              </DivShadow>
            </div>
            <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
              <Container
                width="100%"
                widthMobilexs="75%"
                mt="0rem"
                widthMobile="90%"
                mtMobile="0rem"
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <br />
                  <CardsBtn
                    onClick={() => setExit(true)}
                    style={{
                      borderRadius: '4px',
                      letterSpacing: '-0.3px',
                      lineHeight: '140%',
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                    }}
                    min_w="95%"
                    min_web="350px"
                    mtopw="20px"
                    mleft="10px"
                    mtop="15px"
                    weight="bold !important"
                  >
                    Salir
                  </CardsBtn>
                  <br />
                </div>
              </Container>
            </TrainingSectionGray>
            <Container
              style={{ transform: 'rotate(180deg)' }}
              className="col-12"
              width=""
              mt="40px"
              displayweb="flex"
              displaymobile="none"
              widthMobile=""
              mtMobile="0px"
              background="transparent"
              height="289.406px"
            >
              <Rectangle23
                Ptop="81px"
                Pleft="3%"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Triangle
                width="50px"
                height="50px"
                marginleft="-50px"
                margintop="239px"
                transform="rotate(224deg)"
                widthborder="50px solid transparent"
                widthborderColor={'50px solid #FACFA9;'}
              />
              <Rectangle23
                Ptop="105px"
                Pleft="15%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(9.24deg)"
              />
              <Rectangle25
                positionD="absolute"
                background={'#FACFA9'}
                bradius="14px"
                left="17%"
                top="242px"
                width="30px"
                height="35px"
                displayM=" "
                displayD=" "
              />
              <Rectangle23
                Ptop="100px"
                Pleft="24%"
                transform="rotate(-33.24deg)"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Rectangle23
                Ptop="142px"
                Pleft="38%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(9.24deg)"
              />
              <Rectangle25
                positionD="absolute"
                background={'#FACFA9;'}
                bradius="14px"
                left="49%"
                top="89px"
                width="30px"
                height="35px"
                displayM=" "
                displayD=" "
              />
              <Rectangle23
                Ptop="166px"
                Pleft="52%"
                transform="rotate(62.76deg)"
                width="100px"
                height="100px"
                background={'#FACFA9;'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Rectangle23
                Ptop="81px"
                Pleft="64%"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
              />
              <Rectangle23
                Ptop="250px"
                Pleft="70%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(47.24deg)"
              />
              <Rectangle23
                Ptop="19px"
                Pleft="80%"
                width="100px"
                height="100px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(47.24deg)"
              />
              <Rectangle25
                positionD="absolute"
                background={'#FACFA9;'}
                bradius="14px"
                left="85%"
                top="242px"
                width="30px"
                height="35px"
                displayM=" "
                displayD=" "
              />
              <Rectangle23
                Ptop="150px"
                Pleft="95%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                transform="rotate(47.24deg)"
              />
              <Rectangle23
                Ptop="243px"
                Pleft="97%"
                transform="rotate(62.76deg)"
                width="100px"
                height="100px"
                background={'#FACFA9;'}
                position="absolute"
                displayD=" "
                displayM=""
              />
            </Container>
            <Container
              style={{ transform: 'rotate(180deg)' }}
              width="100%"
              mt="40px"
              displayweb="none"
              displaymobile="flex"
              widthMobile="100%"
              mtMobile="20px"
              background=""
              height="237.031px"
            >
              <Rectangle23
                left="2%"
                Ptop="81px"
                Pleft="3%"
                width="60px"
                height="60px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="10%"
              />
              <Triangle
                width="50px"
                height="50px"
                marginleft="-5px"
                margintop="180px"
                transform="rotate(452deg)"
                widthborder="50px solid transparent"
                widthborderColor={'50px solid #FACFA9;'}
              />
              <Rectangle23
                transform="rotate(11.76deg)"
                left="34%"
                Ptop="90px"
                Pleft="3%"
                width="25px"
                height="25px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="35%"
              />
              <Rectangle23
                left="60%"
                Ptop="81px"
                Pleft="3%"
                width="60px"
                height="60px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="55%"
              />
              <Rectangle23
                transform="rotate(11.76deg)"
                left="90%"
                Ptop="105px"
                Pleft="3%"
                width="40px"
                height="40px"
                background={'#FACFA9'}
                position="absolute"
                displayD=" "
                displayM=""
                leftMb="82%"
              />
              <Rectangle25
                topM="0px"
                leftM="70%"
                positionD="absolute"
                background={'#FACFA9'}
                bradius="20px"
                left="17%"
                top="242px"
                width="50px"
                height="55px"
                displayM=" "
                displayD=" "
                leftMb="75%"
              />
            </Container>
          </TrainingDivSection>
        </Fragment>
      ) : (
        <Fragment>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text={`Estimado: ${
              infoChildren[0].childData.name
                ? infoChildren[0].childData.name
                : 'Gael'
            } tu papá te asigno algunas tareas para esta semana:`}
          />
          <TrainingDivSection
            back="rgba(250, 157, 89, 0.2)"
            backw="rgba(250, 157, 89, 0.2)"
            bsha=""
            mt="20px"
            mtw="25px"
            br="4px"
            padding="21px 20px"
            wi="85%"
            wiw="670px"
            he="100%"
            mb="6px"
          >
            {homeworks.map((item, index) => {
              if (index < 1) {
                return (
                  <Fragment key={index}>
                    <label style={styles}>
                      <b>{`${index + 1}. ${item.name}`}</b>
                    </label>
                    <label
                      className="roboto"
                      style={{
                        display: 'flex',
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: 'black',
                        fontSize: '18px',
                        paddingLeft: '10px',
                      }}
                    >
                      Días:
                    </label>
                    <div style={styleDays}>
                      {item.days.map((item, index) => {
                        const day = item.substr(0, 3);
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: '10px',
                            }}
                          >
                            <label
                              className="roboto"
                              style={{ color: '#FA9D59' }}
                            >
                              {day}
                            </label>
                            <CheckBoxIcon
                              style={{ color: '#FA9D59', fontSize: 25 }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <label className="roboto" style={stylesLabel}>
                      Horario:
                    </label>
                    <p className="roboto" style={stylesP}>
                      {item.time ? item.time : ''}
                    </p>
                    <label className="roboto" style={stylesLabel}>
                      Recompensa:
                    </label>
                    <p className="roboto" style={stylesP}>
                      $ {item.reward ? item.reward : ''}
                    </p>
                  </Fragment>
                );
              } if (!seeMore) {
                return (
                  <Fragment>
                    <hr style={{ background: '#FA9D59' }} />
                    <label style={styles}>
                      <b>{`${index + 1}. ${item.name}`}</b>
                    </label>
                  </Fragment>
                );
              }
            })}
            {homeworks.length > 1 ? (
              <div
                onClick={() => setSeeMore(!seeMore)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginBottom: '20px',
                  paddingLeft: '10px',
                }}
              >
                <div style={{ fontSize: '18px', color: 'black' }}>
                  {' '}
                  {seeMore ? 'Ver menos' : 'Ver detalle'}
                </div>
                <Image
                  transform={seeMore ? 'rotate(180deg)' : 'rotate(90deg)'}
                  src={vectorTop}
                  mwidth="5%"
                  marginLeft="10px"
                  mlMob="10px"
                  width="4%"
                  margin="auto"
                  top="110px"
                  left="10%"
                  mleft="0"
                  mtop="75px"
                />
              </div>
            ) : null}
            {homeworks.map((item, index) => {
              if (index >= 1) {
                return (
                  <Fragment>
                    {seeMore ? (
                      <Fragment key={index}>
                        <label style={styles}>
                          <b>{`${index + 1}. ${item.name}`}</b>
                        </label>
                        <label
                          className="roboto"
                          style={{
                            display: 'flex',
                            marginTop: '10px',
                            marginBottom: '10px',
                            color: 'black',
                            fontSize: '18px',
                            paddingLeft: '10px',
                          }}
                        >
                          Días:
                        </label>
                        <div style={styleDays}>
                          {item.days.map((item, index) => {
                            const day = item.substr(0, 3);
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginRight: '10px',
                                }}
                              >
                                <label
                                  className="roboto"
                                  style={{ color: '#FA9D59' }}
                                >
                                  {day}
                                </label>
                                <CheckBoxIcon
                                  style={{ color: '#FA9D59', fontSize: 25 }}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <label className="roboto" style={stylesLabel}>
                          Horario:
                        </label>
                        <p className="roboto" style={stylesP}>
                          {item.time ? item.time : ''}
                        </p>
                        <label className="roboto" style={stylesLabel}>
                          Recompensa:
                        </label>
                        <p className="roboto" style={stylesP}>
                          $ {item.reward ? item.reward : ''}
                        </p>
                      </Fragment>
                    ) : null}
                  </Fragment>
                );
              }
            })}
          </TrainingDivSection>
          <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
            <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
              <div style={{ display: 'flex', width: '100%' }}>
                <br />
                <CardsBtn
                  onClick={() => acceptTask()}
                  style={{
                    borderRadius: '4px',
                    letterSpacing: '-0.3px',
                    lineHeight: '140%',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                  }}
                  min_w="95%"
                  min_web="350px"
                  mtopw="20px"
                  mleft="10px"
                  mtop="15px"
                  weight="bold !important"
                >
                  Acepto
                </CardsBtn>
                <br />
              </div>
            </Container>
          </TrainingSectionGray>
          {accept ? (
            <Fragment>
              <br />
              <TrainingSectionGray back="#fafafa">
                <Feed_Main width="100%" background=" ">
                  <Feed_Main
                    width="80%"
                    display=" "
                    mtop=" "
                    background="transparent"
                  >
                    <TrainingDivSection2
                      paddiw="0% 10% 3% 10%"
                      paddi="0% 5% 5% 5%"
                      back="#fafafa"
                    >
                      {datos.length > 0 ? (
                        <>
                          <CardOne
                            classCard="cardOne"
                            classTop="topOne1"
                            text={`${
                              infoChildren[0].childData.name
                                ? infoChildren[0].childData.name
                                : 'Gael'
                            }, estas son las tareas asignadas para el día de hoy:`}
                          />
                          <br />
                          <br />
                          <DivFlex widthweb="70%" marweb="auto">
                            <div>
                              <TrainingText weight="600">Tarea</TrainingText>
                            </div>
                            <div>
                              <TrainingText weight="600">Estatus</TrainingText>
                            </div>
                          </DivFlex>
                          <TaskAdvance
                            datos={datos}
                            saveAdvance={saveAdvance}
                          ></TaskAdvance>
                          <TrainingText
                            size="14px"
                            weight="400"
                            className="roboto"
                            align="center"
                            alignweb="center"
                            ls="-0.2px"
                          >
                            Recuerda cumplir con tus tareas al 100%, el día
                            Domingo podrás obtener tus recompensas
                          </TrainingText>
                        </>
                      ) : (
                        <CardOne
                          classCard="cardOne"
                          classTop="topOne1"
                          text={`${
                            infoChildren[0].childData.name
                              ? infoChildren[0].childData.name
                              : 'Gael'
                          }, por el momento no cuentas con tareas activas`}
                        />
                      )}
                    </TrainingDivSection2>
                  </Feed_Main>
                </Feed_Main>
              </TrainingSectionGray>
            </Fragment>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
};

export default HomeworkSchedule;
