import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_RFC } from '../mutations';

const Rfc = ({
  data,
  setOrderRutine,
  routine,
  handleChangeRFC,
  nextOrdeRoutine = 0,
}) => {
  const [saveRfc, setSaveRfc] = useState(true);
  const [rfc, setRfc] = useState('');
  const [createRFC] = useMutation(CREATE_RFC, {
    onCompleted({ createRFC }) {
      // console.log(createRFC);
      setRfc(createRFC.response);
      handleChangeRFC(createRFC.response);
      if (nextOrdeRoutine !== 0) {
        // debugger;
        setOrderRutine(nextOrdeRoutine);
      } else {
        console.log(routine);
        // debugger;
        setOrderRutine(Number(routine) || 4);
      }
    },
  });

  const handleChange = (e) => {
    setRfc(e.target.value);
  };

  useEffect(() => {
    if (rfc === '' && saveRfc) {
      setSaveRfc(false);
      const inputRfc = {
        input: {
          nombres: `${data.firstname} ${data.secondname}`,
          apellidoPaterno: data.lastname,
          apellidoMaterno: data.secondlastname,
          fechaNacimiento: `${data.year}-${data.month}-${data.day}`,
        },
      };
      console.log('INPUT', inputRfc);
      createRFC({
        variables: inputRfc,
      });
    }
  });

  return <input type="text" value={rfc} onChange={handleChange} />;
};

export default Rfc;
