/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import True from '../../assets/img/True.svg';
import { Images, Input } from '../../assets/styles/Training.styled';

function InputAnaIcon({
  placeholder,
  initialValue,
  handleChange,
  type,
  tipo,
  change,
  onBlur,
  nextQuestion,
  disabled = false,
  maxlength,
  value,
  handleBlur,
  texterror,
  error,
  errorinicial,
  id,
  className,
  visibleIcon,
  ...otherProps
}) {
  console.log();
  // console.log(error, errorinicial, texterror);
  // console.log(id, maxlength);
  // console.log('prefix,', visibleIcon);
  return (
    <>
    <Input>
        <input
        {...otherProps}
        id={id}
        name={id}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        className="col-11"
        style={{ height: '30px', border: 'none' }}
        maxLength={maxlength}
        value={value}
        />
        { visibleIcon ? <img src={ True } /> : null}
    </Input>
        {error && errorinicial === 1
            && <p style={{ margin: "0px", color: "red" }} >{texterror}</p>
        }
    </>
  );
}

export default InputAnaIcon;
