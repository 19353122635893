import React, { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
// import { TextField } from '@material-ui/core/';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import WOlayout from '../../components/layouts/WOlayout';
import {
  Container1,
  ContentHomeSection,
  Shapes,
  Title1,
  Subtitle,
  CardTarjetasOpciones,
  CardTarjetasOpcionesHeader,
  CardTarjetasOpcionesContainer,
  CardTarjetasOpcionesCardInformation,
  CardTarjetasOpcionesImagen,
  CardTarjetasOpcionesAnualidadContainer,
  CardTarjetasOpcionesList,
  ResponsiveDiv,
  ContainerBottons,
} from '../../components/common/Home.styled';
import {
  Container,
  // Button,
  // ButtonSecon,
} from '../../components/common/common.styled';
import { Image } from '../../assets/styles/Home.styled';
import Humans from '../../assets/img/home/CardHumans.svg';
import { CardsBtn } from '../../assets/styles/Training.styled';
import CardChecked from '../../assets/img/home/CardCheck.svg';
import ModalShareLP from '../../components/common/ModalShareLP';
import { GET_PRODUCTS_BY_LADING } from '../../queries';
import { CREATE_SESSION } from '../../mutations';
import { Helmet } from 'react-helmet';

const LpObtenerTarjeta = () => {
  const history = useHistory();
  const [slides, setSlides] = useState(3);
  const [optionSelected, setoptionSelected] = useState(undefined);
  const [products, setProducts] = useState([]);
  const [clickout, setClickout] = useState('');
  const [showContent, setShowContent] = useState(false);
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [getProductsLp] = useLazyQuery(GET_PRODUCTS_BY_LADING, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsLp }) {
      if (getProductsLp.message === 'success') {
        setProducts(JSON.parse(getProductsLp.response));
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertLogLPGetCard] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log('dynamicMongoSession', dynamicMongoSession.response);

        history.push(
          '/wo/card-funnel?utm_source=email&utm_medium=superhook&utm_campaign=landing-gral&utm_content=180521',
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (window.screen.width < 480) {
      setSlides(1);
    } else if (window.screen.width > 480 && window.screen.width < 720) {
      setSlides(2);
    } else if (window.screen.width >= 720) {
      setSlides(3);
    }
  }, [window.screen.width]);

  const [creditValue, setCreditValue] = useState(0);
  const [pagoValue, setPagoValue] = useState(0);

  const toggleCreditColor = (value) => {
    // Si value === 1 => Si
    // Si value === 2 => No
    if (value === 1 && creditValue !== value) {
      setCreditValue(value);
    } else if (value === 2 && creditValue !== value) {
      setCreditValue(value);
    }
  };

  const togglePagoColor = (value) => {
    // Si value === 1 => Si
    // Si value === 2 => No
    if (value === 1 && pagoValue !== value) {
      setPagoValue(value);
    } else if (value === 2 && pagoValue !== value) {
      setPagoValue(value);
    }
  };

  const handleProductSelection = (url, index) => {
    console.log(`producto clickout-->${url}`);
    setoptionSelected(index);
    setClickout(url);
  };

  const requestCard = () => {
    if (clickout !== '') {
      const dataJson = {
        activeCredit: creditValue === 1 ? 'Si' : 'No',
        latePayment: pagoValue === 1 ? 'Si' : 'No',
        clickout:
          `${window.location.hostname
          }/wo/card-funnel?utm_source=email&utm_medium=superhook&utm_campaign=landing-gral&utm_content=180521`,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'LogLPGetCard',
          data: dataString,
        },
      };
      insertLogLPGetCard({ variables: mongoInput });
    } else {
      history.push(
        '/wo/card-funnel?utm_source=email&utm_medium=superhook&utm_campaign=landing-gral&utm_content=180521',
      );
    }
  };

  useEffect(() => {
    getProductsLp({
      variables: {
        idLandingPage: 1,
      },
    });
  }, []);

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Obtén una nueva tarjeta de crédito | CORU</title>
      </Helmet>
      <Container1>
        <Shapes
          id="shapes"
          backgound="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="755px"
          height="1000px"
          width="100%"
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Title1
                id="Title1"
                mtopM="80px"
                className="roboto cabin"
                width="655px"
                widthM="85%"
                sizeM="40px"
              >
                En Coru 94% de las personas obtienen una tarjeta de crédito
              </Title1>
              <div className="d-flex flex-column">
                <div className="p-2">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    marginTop=" "
                    mleftM="15px"
                    widthM="85%"
                  >
                    Responde las siguientes preguntas y conoce tus opciones.
                  </Subtitle>
                </div>
                <ResponsiveDiv width="40%" widthM="90%">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="0"
                    mleftM="0"
                    size="16px"
                    weight="bold"
                  >
                    ¿Tienes algún crédito activo?
                  </Subtitle>
                  <div>
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      type="button"
                      onClick={() => toggleCreditColor(1)}
                      color={
                        creditValue === 1
                          ? '#EC407A !important'
                          : '#000 !important'
                      }
                      back="#fff !important"
                      border={
                        creditValue === 1
                          ? '1px solid #EC407A !important'
                          : 'solid #CDCDCD 1px !important'
                      }
                      className="NoFocus"
                    >
                      Si
                    </CardsBtn>
                  </div>
                  <div>
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      type="button"
                      onClick={() => toggleCreditColor(2)}
                      color={
                        creditValue === 2
                          ? '#EC407A !important'
                          : '#000 !important'
                      }
                      back="#fff !important"
                      border={
                        creditValue === 2
                          ? '1px solid #EC407A !important'
                          : 'solid #CDCDCD 1px !important'
                      }
                      className="NoFocus"
                    >
                      No
                    </CardsBtn>
                  </div>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="0"
                    mleftM="0"
                    size="16px"
                    weight="bold"
                  >
                    ¿Tienes algún pago atrasado?
                  </Subtitle>
                  <div>
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      type="button"
                      onClick={() => togglePagoColor(1)}
                      color={
                        pagoValue === 1
                          ? '#EC407A !important'
                          : '#000 !important'
                      }
                      back="#fff !important"
                      border={
                        pagoValue === 1
                          ? '1px solid #EC407A !important'
                          : 'solid #CDCDCD 1px !important'
                      }
                      className="NoFocus"
                    >
                      Si
                    </CardsBtn>
                  </div>
                  <div>
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      type="button"
                      onClick={() => togglePagoColor(2)}
                      color={
                        pagoValue === 2
                          ? '#EC407A !important'
                          : '#000 !important'
                      }
                      back="#fff !important"
                      border={
                        pagoValue === 2
                          ? '1px solid #EC407A !important'
                          : 'solid #CDCDCD 1px !important'
                      }
                      className="NoFocus"
                    >
                      No
                    </CardsBtn>
                  </div>
                  <CardsBtn
                    type="button"
                    onClick={() => {
                      if (creditValue !== 0 && pagoValue !== 0) {
                        setShowContent(true);
                        gsap.to(window, {
                          duration: 1,
                          scrollTo: '#tituloTarjetaGarantizada',
                        });
                      }
                    }}
                    back="#fb245c !important"
                    padd="15px 20px"
                    windthG="100%"
                    windhtmd="100%"
                    weight="bold"
                    heigth=""
                    className=""
                  >
                    <b>Continuar</b>
                  </CardsBtn>
                </ResponsiveDiv>
              </div>
            </ContentHomeSection>
          </Container>
          <ContentHomeSection
            leftD="0px"
            floatD="right"
            rigthD="0%"
            topD="-285px"
            width="60%"
            displayD=" "
            displayM="none"
          >
            <Shapes
              id="Shapes-medio"
              positionD=" "
              position="absolute"
              zindex="-1"
            >
              <Image
                src={Humans}
                maxwidth="430px"
                width="40%"
                position="absolute"
                left="10%"
                top="-440px"
                displayMobile="none"
              />
            </Shapes>
          </ContentHomeSection>
        </Shapes>
      </Container1>
      {showContent && (
        <Container1>
          <Container width="80%" mt="1rem" mb="0%" mtMobile="1rem">
            <Shapes
              id="shapes"
              backgound="#FAFAFA"
              key={1}
              top="100px"
              left="20px"
              heightD="995px"
              height="1180px"
              width="100%"
            >
              <ContentHomeSection leftD=" " floatD=" " width="100%">
                <Title1
                  mtopM="10px"
                  className="roboto cabin"
                  mt="40px"
                  widthM="85%"
                  id="tituloTarjetaGarantizada"
                >
                  Algunas opciones para ti:
                </Title1>
                <Subtitle
                  id="Subtitle"
                  className="roboto"
                  mtopM="0"
                  mleftM="15px"
                  width="100%"
                  mbottomM="65px"
                  widthM="85%"
                >
                  <div>
                    Realiza nuestro entrenamiento y consigue tu tarjeta ideal,
                    recuerda que desde ahora ya tienes dos{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      Tarjetas garantizadas.
                    </span>
                  </div>
                </Subtitle>
                <div
                  style={{ marginTop: '2rem' }}
                  id="CarruselEmpresasContentLogros"
                >
                  <Carousel itemsToShow={slides}>
                    {products.map((product, i) => (
                        <CardTarjetasOpciones
                          className="roboto"
                          height="650px"
                          width="343px"
                          key={i}
                          onClick={() => handleProductSelection(product.clickOut, i)
                          }
                          border={optionSelected === i && '1.5px solid #EC407A'}
                        >
                          <CardTarjetasOpcionesHeader
                            padding="0 20px"
                            vis={!product.headerVisible && 'hidden'}
                          >
                            <Image src={CardChecked} width="8%" height="auto" />
                            <span
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                marginLeft: '20px',
                              }}
                            >
                              Tarjeta Garantizada
                            </span>
                          </CardTarjetasOpcionesHeader>
                          <CardTarjetasOpcionesContainer>
                            <CardTarjetasOpcionesCardInformation width="94%">
                              <CardTarjetasOpcionesImagen
                                src={product.urlImage}
                              />
                              <CardTarjetasOpcionesAnualidadContainer>
                                <span
                                  style={{ color: 'black', fontSize: '12px' }}
                                >
                                  Anualidad
                                </span>
                                <span
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: 'black',
                                  }}
                                >
                                  ${product.annuity}
                                </span>
                              </CardTarjetasOpcionesAnualidadContainer>
                            </CardTarjetasOpcionesCardInformation>
                            <h5
                              style={{ marginTop: '20px', fontWeight: '700' }}
                            >
                              {product.name}
                            </h5>
                            <h6 style={{ fontWeight: '700' }}>Beneficios</h6>
                            <CardTarjetasOpcionesList>
                              {product.benefits.map((benefit, i) => (benefit === '-' ? null : (
                                  <li key={i}>{benefit}</li>
                              )))}
                            </CardTarjetasOpcionesList>
                            <div>
                              <h6 style={{ fontWeight: '700' }}>
                                Información extra
                              </h6>
                              <CardTarjetasOpcionesList>
                                <li>
                                  CAT: {product.extraInfo.cat.toFixed(2)}%
                                </li>
                                <li>
                                  Tasa de interes:{' '}
                                  {product.extraInfo.interes.toFixed(2)}%
                                </li>
                              </CardTarjetasOpcionesList>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <CardsBtn
                                type="button"
                                onClick={() => requestCard()}
                                mtop="0px !important"
                                back="#fb245c !important"
                                padd="10px 15px"
                                windthG="80%"
                                windhtmd="80%"
                                weight="bold"
                              >
                                <b>Solicitar tarjeta</b>
                              </CardsBtn>
                            </div>
                          </CardTarjetasOpcionesContainer>
                        </CardTarjetasOpciones>
                    ))}
                  </Carousel>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <ContainerBottons
                    widthD="420px"
                    leftmd=" "
                    margin=" "
                    position=" "
                  ></ContainerBottons>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <ContainerBottons
                    widthD="420px"
                    leftmd=" "
                    margin=" "
                    position=" "
                  >
                    <ModalShareLP
                      redirectLink="/lp/get-card"
                      trainingName="LPgetCard"
                      textShare="Compartir"
                      mtop="0px"
                      buttonType="outline"
                    />
                  </ContainerBottons>
                </div>
              </ContentHomeSection>
            </Shapes>
          </Container>
        </Container1>
      )}
    </WOlayout>
  );
};

export default LpObtenerTarjeta;
