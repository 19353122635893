import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Carrusel from "react-elastic-carousel";
import { toNumber } from "lodash-es";
import { GET_PRODUCTS_BY_IDTRAINING_AND_NAME_FOR_FREMIUM } from "../../queries";
import {
  INSERT_WAIT_LIST_PRODUCT_USER,
  DYNAMIC_INSERT,
  CHANGE_STATUS,
} from "../../mutations";
import {
  TrainingText,
  TrainingDivSection2,
  Resultado,
  DivStyle,
  FreemiumButton,
  TrainingDivSection,
  TrainingSubtitles,
} from "../../assets/styles/Training.styled";

import ModalLearnMore from "./ModalLearnMore";
import ModalShareFreem from "./ModalShareFreem";
import Starttraining from "../Training/Starttraining";
import Flag from "../../assets/img/logros/Flag.svg";
import FlagB from "../../assets/img/logros/SaveBlack.svg";

const ProductsFremium = ({ idT, products = [], text = false }) => {
  const [productsData, setProductsData] = useState([]);
  const [modalActive, setModalActive] = useState(false);

  const [productSelectedID, setProductSelectedID] = useState(0);

  const IdUser = toNumber(sessionStorage.getItem("idUser"));
  idT = toNumber(idT);
  console.log(IdUser);

  const { loading, error } = useQuery(
    GET_PRODUCTS_BY_IDTRAINING_AND_NAME_FOR_FREMIUM,
    {
      variables: { idTraining: idT, idUser: IdUser, names: products },
      onCompleted({ getProductsByIdTrainingAndNameForFremium }) {
        console.log(
          "# # Productos fremium",
          getProductsByIdTrainingAndNameForFremium
        );
        const products = JSON.parse(
          getProductsByIdTrainingAndNameForFremium.response
        );
        console.log("# # products fremium >>>", products);
        setProductsData(products);
      },
      onError(error) {
        console.log("error", error);
      },
    }
  );

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === "success") {
        console.log("INSERCION EXITOSA!");
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  const [changeStatus] = useMutation(CHANGE_STATUS, {
    onCompleted({ changeStatus }) {
      if (changeStatus.message === "success") {
        console.log(changeStatus);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const toggleProduct = (idProduct, index) => {
    const inputUpdateStatus = {
      input: {
        table: "DatCardSaving",
        columns: [
          {
            setkey: "`status`",
            setval: 0,
          },
        ],
        conditions: [
          {
            valone: "CatUsers_IDUser",
            valtwo: sessionStorage.getItem("idUser"),
            condition: "=",
            logic: "",
          },
          {
            logic: "AND",
            valone: "CatProduct_IdProduct",
            condition: "=",
            valtwo: String(idProduct),
          },
        ],
      },
    };

    if (productsData[index].statusSaving === 0) {
      inputUpdateStatus.input.columns[0].setval = 1;
      changeStatus({ variables: inputUpdateStatus });
    } else if (productsData[index].statusSaving === 1) {
      inputUpdateStatus.input.columns[0].setval = 0;
      changeStatus({ variables: inputUpdateStatus });
    } else if (productsData[index].statusSaving === 2) {
      dynamicInsert({
        variables: {
          input: {
            table: "DatCardSaving",
            columns: [
              "CatProduct_IdProduct",
              "CatUsers_IDUser",
              "status",
              "idTraining",
            ],
            detailValues: {
              values: [
                String(idProduct),
                String(sessionStorage.getItem("idUser")),
                "true",
                String(sessionStorage.getItem("idTraining")),
              ],
            },
          },
        },
      });
    }

    const now = document.getElementsByClassName(`flag-${idProduct}`)[0].src;

    if (now.includes("SaveBlack")) {
      document.getElementsByClassName(`flag-${idProduct}`)[0].src = Flag;
      document.getElementsByClassName(
        `flag-${idProduct}`
      )[0].style.marginRight = "";
    } else {
      document.getElementsByClassName(`flag-${idProduct}`)[0].src = FlagB;
      document.getElementsByClassName(
        `flag-${idProduct}`
      )[0].style.marginRight = "4px";
    }
  };

  const [insertWaitList] = useMutation(INSERT_WAIT_LIST_PRODUCT_USER, {
    onCompleted({ insertWaitListProductUser }) {
      setModalActive(false);
    },
    onError(err) {
      console.log(err);
    },
  });

  if (loading) return "Cargando";
  if (error) return "Error";

  const productSelectHandler = (productId, ClickOut) => {
    setProductSelectedID(productId);
    window.open(ClickOut, '_blank');
  };

  const getAdvice = () => {
    if (!sessionStorage.getItem("idUser")) {
      setModalActive(false);
    }

    insertWaitList({
      variables: {
        input: {
          idUser: parseInt(sessionStorage.getItem("idUser")),
          idProduct: productSelectedID,
        },
      },
    });
  };

  const validFlag = (value) => {
    if (value === "true" || value === "1" || value === true) {
      return true;
    }
    if (value === "false" || value === "0" || value === false) {
      return false;
    }
    return false;
  };

  let text1 = `No olvides que las tarjetas de crédito tienen fecha de corte.
  Es el día en el que debes de pagar el monto acumulado durante esos últimos 30 días.`;
  let text2 =
    "Por eso te recomendamos usar un challenger bank donde podrás crear espacios, separar tu dinero y pagar tu tarjeta de crédito desde la app.";
  let text3 =
    "¡En Coru tenemos esta opción para ti! Estamos seguros que podrá interesarte.";

  if (idT === 11) {
    text1 =
      "¡Un Seguro de Gastos Médicos te ofrece grandes beneficios, el más importante es saber que cuentas con un gran respaldo financiero para hacer frente a los eventos de salud!";
    text2 =
      "Por eso te recomendamos usar un challenger bank donde podrás crear espacios, separar tu dinero y lograr tus objetivos.";
  } else if (idT === 14) {
    text1 =
      "Hacer una planeación mensual de tus ingresos totales y gastos fijos y variables con objetivos específicos y plazos realistas 😎, te ayudará a tener presente tu presupuesto a la hora de pensar en gastar 💸.";
    text2 =
      "Para ello, te recomendamos alguno de los siguientes productos ☝️, ya sea el objetivo que tengas en mente: ahorrar, viajar o cuidar de tu salud.";
    text3 = "";
  } else if (idT === 3 || idT === 35) {
    text1 =
      "Las tarjetas de crédito 💳 son excelentes herramientas para acceder a descuentos, beneficios y promociones exclusivas 🤩, pero debes tener en cuenta la fecha de corte y pago para que tener una tarjeta no se convierta en una mala experiencia.";
    text2 =
      "Contar con una cuenta de débito desde la que puedas monitorear tus gastos , separar tu dinero para distintos objetivos y pagar tu tarjeta de crédito, te facilitará mucho tu vida financiera 😌.";
    text3 = "Para ello te recomendamos:";
  } else if (idT === 37) {
    text1 = "¡En equipo todo es más fácil!😌";
    text2 =
      "Aprovecha el esfuerzo que cada integrante de tu equipo aporta a la meta que tienen en común ¡y vayan por esas recompensas!🏆";
    text3 = "Para ello te recomendamos:";
  } else if (idT === 42) {
    text1 = "¿Buscas mejorar tu negocio?";
    text2 =
      "¡En Coru tenemos esta opción para ti las cuales ayudarán a que tu negocio crezca y mejore! Estamos seguros de que podrán interesarte.";
    text3 = "";
  }

  return (
    <>
      <ModalLearnMore
        modalActive={modalActive}
        setModalActive={setModalActive}
        handleAdvice={getAdvice}
        heiMod=' '
      />
      <TrainingDivSection2 paddiw='0' paddi='0' back='#FAFAFA'>
        <Starttraining
          title=''
          text={text1}
          text2={text2}
          text3={text3}
          hiddeAna={true}
        />
      </TrainingDivSection2>
      <TrainingDivSection
        wixs='90%'
        wiw='60%'
        mlw='18%'
        wi='90%'
        hew='auto'
        paddw='26px'
        back='#F3F3F3'
        backw='#F3F3F3'
        bshaw='none'
        bsha=''
        br='4px'
        padding='20px'
        he='auto'
        mb='0px'
        className='topOne1Init'
        mbw='0px'
      >
        <TrainingSubtitles
          size='16px'
          sizew='16px'
          fw='600'
          align='left'
          alignweb='left'
          className='roboto'
        >
          {sessionStorage.getItem('FirstName')}, ¡Echale un vistazo a otros productos que podrán ayudarte a
          cumplir tus objetivos!
        </TrainingSubtitles>
      </TrainingDivSection>
      <div id='CarruselEmpresasContent' style={{ marginTop: "20px" }}>
        <Carrusel styled={{ background: "#FAFAFA" }}>
          {productsData.map((producto, index) => (
            <Resultado
              marTop='20PX'
              marTopD='20PX'
              bcolor='#fff'
              ta='left'
              marriweb='auto'
              marri=' '
              border='none'
              hei='440px'
              minh='453px'
              minhweb='auto'
              key={index}
            >
              <div className='row' style={{ margin: "auto", width: "100%" }}>
                <div className='col-6' style={{ marginTop: "20px" }}>
                  <img
                    src={producto.ImageURL}
                    style={{
                      maxHeight: "50px",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
                <div className='col-6'>
                  <div
                    className='row'
                    style={{ justifyContent: "flex-end", marginRight: "5px" }}
                  >
                    <img
                      src={validFlag(producto.statusSaving) ? FlagB : Flag}
                      className={`flag-${producto.IDProduct}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleProduct(producto.IDProduct, index)}
                    />
                    <ModalShareFreem />
                  </div>
                </div>

                <DivStyle
                  className='cabin'
                  weight='600'
                  align='left'
                  size={producto.Name.indexOf("AT&T") > -1 ? "18px" : "20px"}
                  line='1.5em'
                  padd='12px 5% 0 5%'
                  color='#07210A'
                >
                  {producto.Name}
                </DivStyle>

                <div className='col-12' style={{ height: "230px" }}>
                  <TrainingText
                    size='14px'
                    sizew='14px'
                    weight='600'
                    className='roboto'
                    align='left'
                    alignweb='left'
                    ls='-0.2px'
                  >
                    Beneficios
                  </TrainingText>
                  {producto.Name === "Mexo" || producto.Name === "Generamás" ?
                    <ul style={{ paddingLeft: "18px" }}>
                      {producto.benefits.map((item, index) => (
                        <>
                          {(item.IDProductDetailType === 3 ||
                          item.IDProductDetailType === 1) && index <= 1 ? (
                            <li style={{ marginTop: "10px" }} key={index}>
                              {item.Description}
                            </li>
                          ) : null}
                        </>
                      ))}
                    </ul>
                  :
                    <ul style={{ paddingLeft: "18px" }}>
                        {producto.benefits.map((item, index) => (
                          <>
                            {item.IDProductDetailType === 3 ||
                            item.IDProductDetailType === 1 ? (
                              <li style={{ marginTop: "10px" }} key={index}>
                                {item.Description}
                              </li>
                            ) : null}
                          </>
                        ))}
                      </ul>
                  }
                </div>
                <FreemiumButton
                  windthmd='90%'
                  color='#A6A6A6'
                  back='#FFFF'
                  border='1px solid #A6A6A6'
                  onClick={() =>
                    productSelectHandler(producto.CatProduct_IDProduct, producto.ClickOut)
                  }
                >
                  Conocer más
                </FreemiumButton>
              </div>
            </Resultado>
          ))}
        </Carrusel>
      </div>
    </>
  );
};
export default ProductsFremium;
