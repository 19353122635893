import React, { useState, Fragment, useEffect } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';

import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
  CREATE_SESSION_MYSQL,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  GET_TRAINING_COLOR,
  GET_TRAINING_PREVIEW,
  GET_TRAINING_STATUS,
} from '../../queries';

// styles
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import { Container, Col7 } from '../../components/common/common.styled';

// components
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import Recommendations from '../../components/Training/RecommendedTraining';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import WOlayout from '../../components/layouts/WOlayout';
import RoutinesOrderWoAfores from './RoutinesOrderWoAfores';
import '../../assets/styles/kanda/KandaDesktop.css';

const items2 = ['Afores, una inversión segura', ''];
const idTraining = 39;

const WoAfores = () => {
  sessionStorage.setItem('idTraining', idTraining);
  const id = sessionStorage.getItem('idTraining');
  const [arrayOrderRoutines] = useState([]);
  const { actions } = GeneralHooks();
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(idTraining);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [anchop, setAnchop] = useState(0);
  const [colorTraining, setColorTraining] = useState('');
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );

  const [recomendaciones, setRecomendaciones] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [checkScroll, setCheckScroll] = useState(true);
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();

  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;

  // mutations and queries
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(getTrainingStatus);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario_existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveStatusTraining }) {
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // useEffect
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    if (active1) {
      setactive2(true);
    }
  }, [active1]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  useEffect(() => {
    const email = sessionStorage.getItem('Email1');
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const IdUser = sessionStorage.getItem('idUser');
    if (IdUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(IdUser), idtrainig: currentTraining },
      });
    } else {
      setPercentage('0');
    }
  }, []);

  const recoveryAdvance = async (currentOrderRoutine, userRoutines) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');

    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: userRoutines || '',
      },
    };

    if (idUser != null) {
      await saveStatusTraining({ variables: inputProgress });
    }
  };

  const saveRoutines = async () => {
    await recoveryAdvance(1);
    await recoveryAdvance(2);
    await recoveryAdvance(3);
  };

  useEffect(() => {
    if (orderRutine <= 1 && percentage !== '100%') {
      saveRoutines();
    }
  }, [orderRutine]);

  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('afores');

    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: currentTraining },
      });
    } else {
      setPercentage('0');
    }

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
      content,
    );
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
        viralityID,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        // console.log("Moviste el scroll");
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  useEffect(() => {
    setAnchop(window.screen.width);
  }, [window.screen.width]);

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      if (arrayOrderRoutines.length === 0 && orderRutine === 4) {
        arrayOrderRoutines.push(1, 2, 3, orderRutine);
      } else {
        arrayOrderRoutines.push(orderRoutine);
      }
    }
  };
  const createJsonInput = (
    currentRoutine,
    questions,
    orderRutineDatBasics = '',
  ) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    pushArrayOrderRoutine(
      orderRutineDatBasics !== '' ? orderRutineDatBasics : orderRutine,
    );
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };

  const feacturesArray = useState([
    {
      text: 'Calcular con cuánto te podrías retirar.',
      color: '#33A376',
    },
    {
      text: 'Conocer los beneficios de tu Afore.',
      color: '#33A376',
    },
    {
      text: 'Hacer aportaciones voluntarias.',
      color: '#33A376',
    },
  ]);

  const [WOBigImage, setWOBigImage] = useState('');
  useEffect(() => {
    getTrainingPreview({
      variables: {
        Id: localStorage.getItem('idTraining') || '39',
      },
    });
  }, []);

  const [getTrainingPreview] = useLazyQuery(GET_TRAINING_PREVIEW, {
    onCompleted: ({ getTrainingPreview }) => {
      console.log(
        `Esta---->${getTrainingPreview.response.training.UrlBigImage}`,
      );
      setWOBigImage(getTrainingPreview.response.training.UrlBigImage);
    },
  });
  const imgWo = WOBigImage;
  // console.log(`IMAGEN WO    ${imgWo}`);

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: 'Elige la mejor AFORE',
    time: '5 minutos',
    imgWo: { imgWo },
  };

  return (
    <Fragment>
      <WOlayout
        backgroundColor={colorTraining}
        pathname={window.location.pathname}
        headerType="HeaderTwo"
      >
        <Helmet>
          <title>Cómo elegir la Mejor AFORE: Asesor Personal | Coru</title>
          <link rel="canonical" href="https://coru.com/wo/afores" />
          <meta name="keywords" content="como elegir la mejor afore" />
          <meta
            name="description"
            content="¿Sabes si tu AFORE te da el mejor rendmiento? Tienes que saber que esto es lo que se te dará para el retiro. Tienes que saber cuánto puede ser."
          />
        </Helmet>
        <TrainingMain>
          <Fragment>
            <TrainingAnimateFunnel
              bcolor={colorTraining}
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <BarraInfoWO propied={propied} />
              <TrainingLine
                id="line-percent-gray"
                mt=""
                mtw="404px"
              ></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
            </TrainingAnimateFunnel>
            <ModalDataPersonalInitWO isData={isData} setData={setisData} />
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Fragment>
                  <DataPersonalInitWO />
                  <TrainingSectionGray
                    wiw="100%"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      {orderRutine !== 'rp' ? (
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            classAna="trueAna"
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="En este entrenamiento aprenderás que una Afore es un fondo para el retiro, donde se acumulan las aportaciones que deposita el patrón, el gobierno y el trabajador. Descubre cuánto dinero tienes y qué rendimientos está generando."
                          />
                          {active1 ? (
                            <Features
                              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                              title="¿Qué más puedes saber?"
                              Arraytext={feacturesArray}
                            />
                          ) : null}
                          {active2 ? (
                            <Starttraining
                              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                              title="Iniciemos esta aventura..."
                              text="A continuación te haré algunas preguntas para conocer que Afore te conviene más..."
                            />
                          ) : null}
                        </div>
                      ) : null}
                      <Fragment>
                        <div className="marginComentLgSuccess1">
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <RoutinesOrderWoAfores
                                Date={stateDate}
                                hora={hora}
                                orderRutine={orderRutine}
                                setOrderRutine={setOrderRutine}
                                currentTraining={currentTraining}
                                createJsonInput={createJsonInput}
                                questions={questions}
                                setQuestions={setQuestions}
                                trainingColor={colorTraining}
                              />
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                        </div>
                        {recomendaciones && orderRutine === 'final' ? (
                          <Fragment>
                            <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-10 offset-md-1 col-xs-12">
                              <Feed_Main
                                width="100%"
                                widthxs="100%"
                                background=" "
                              >
                                <Feed_Main
                                  width="100%"
                                  display=" "
                                  mtop=" "
                                  background="transparent"
                                >
                                  <Feed_Section
                                    padding="0 0 0 0"
                                    paddingxs="0 0 0 0"
                                  >
                                    <ContentHomeSection
                                      leftD="0%"
                                      floatD=" "
                                      width="100%;"
                                    >
                                      <Recommendations
                                        items={recommendedTraining}
                                      ></Recommendations>
                                    </ContentHomeSection>
                                  </Feed_Section>
                                </Feed_Main>
                              </Feed_Main>
                            </div>
                          </Fragment>
                        ) : null}
                      </Fragment>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                  {!active2 ? (
                    <Fragment>
                      <TrainingSectionGray
                        wiw="670px"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <Container
                          width="80%"
                          mt="0rem"
                          widthMobile="90%"
                          mtMobile="0rem"
                        >
                          <ImageCoachInicio topMobile="35px" />
                          <Col7 padding=" " className="cardPrincipal1">
                            <LoadingWo />
                          </Col7>
                        </Container>
                      </TrainingSectionGray>
                    </Fragment>
                  ) : null}
                </Fragment>
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
          </Fragment>
        </TrainingMain>
      </WOlayout>
    </Fragment>
  );
};

export default WoAfores;
