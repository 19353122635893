import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useLazyQuery, useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
// Import visual components
import {
  TrainingQuestion, CardsBtn, QuestionsDivs, TrainingText,
} from '../../assets/styles/Training.styled';
import { INSERT_DAT_GOAL } from '../../mutations';
import CardOne from '../Home/Card1Animate';
// Import query components and helpers
import { GET_RESPONSE_USER_BY_IDTYPE } from '../../queries';
import ClickButton from '../../Helpers/HookHelpers';
// eslint-disable-next-line no-unused-vars
import { filterFloat, getCurrentDateTimeLarge, formatterMoney } from '../../Helpers';
// modal dialog
import ModalDialog from './ModalDialog';

const CreateStrategyGoal = ({
  hora,
  currentTraining,
  className,
  currentGoal,
  handelChangeRecoveryHistory,
  setVerNewGoal,
  sumaSaldoUtilizado,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [montoMetaStrategy, setMontoMetaStrategy] = useState('');
  const [disponibleMetaStrategy, setDisponibleMetaStrategy] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [dineroMetaQuincenalStrategy, setDineroMetaQuincenalStrategy] = useState('');
  const [sumaIngresoStrategy, setSumaIngresoStrategy] = useState('');
  const [sumaGastosFijoStrategy, setSumaGastosFijosStrategy] = useState('');
  const [sumaGastosExtraStrategy, setSumaGastosExtraStrategy] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [ahorroMensualStrategy, setAhorroMensualStrategy] = useState();
  // eslint-disable-next-line no-unused-vars
  const [tiempodeAhorroStrategy, setTiempodeAhorroStrategy] = useState();
  // eslint-disable-next-line no-unused-vars
  const [metas, setMetas] = useState([]);

  // State used for recoveryData process and validations
  // eslint-disable-next-line no-unused-vars
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const { actions } = ClickButton();
  const [form, setForm] = useState({
    MontoMetaStrategy: '',
    DineroMetaQuincenalStrategy: '',
    AhorroMensualStrategy: '',
    TiempodeAhorroStrategy: '',
  });
  const [insertDatGoal] = useMutation(INSERT_DAT_GOAL, {
    // eslint-disable-next-line no-shadow
    onCompleted({ insertDatGoal }) {
      if (insertDatGoal.message === 'success') {
        // eslint-disable-next-line no-console
        console.log('insercion exitossa, ID = ', insertDatGoal);
      }
      handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
    },
    onError(err) {
      handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
      // eslint-disable-next-line no-console
      console.log(err);
    },
  });

  const handleChange = (e) => {
    // eslint-disable-next-line no-console
    console.log('handleChange', e.target.name, e.target.value);

    let value = '';
    switch (e.target.name) {
      case 'MontoMetaStrategy':
        value = e.target.value.replace('$', '').replace(/,/g, '').trim();
        value = filterFloat(value);

        if (value !== '') {
          setForm({
            ...form,
            // eslint-disable-next-line no-use-before-define
            [e.target.name]: formatter.format(filterFloat(value)),
          });
        } else if (e.target.value === '$') {
          setForm({
            ...form,
            [e.target.name]: '',
          });
        }
        break;
      case 'DineroMetaQuincenalStrategy':
        value = e.target.value.replace('$', '').replace(/,/g, '').trim();
        value = filterFloat(value);
        if (value !== '') {
          setForm({
            ...form,
            // eslint-disable-next-line no-use-before-define
            [e.target.name]: formatter.format(filterFloat(value)),
          });
        } else if (e.target.value === '$') {
          setForm({
            ...form,
            [e.target.name]: '',
          });
        }
        break;

      default:
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
    }
  };

  // Recovery variables and functions

  const [getResponseUserByIdType] = useLazyQuery(GET_RESPONSE_USER_BY_IDTYPE, {
    fetchPolicy: 'cache-and-network',
    // eslint-disable-next-line no-shadow
    onCompleted({ getResponseUserByIdType }) {
      try {
        const json = JSON.parse(getResponseUserByIdType.response);
        // eslint-disable-next-line no-console
        console.log('types', json);
        if (json.length > 0) {
          const ingreso = json[0].Value.replace('$', '').replace(/,/g, '').trim();
          const gastoFijo = json[1].Value.replace('$', '').replace(/,/g, '').trim();
          const gastoExtra = json[2].Value.replace('$', '').replace(/,/g, '').trim();

          setSumaIngresoStrategy(ingreso);
          setSumaGastosFijosStrategy(gastoFijo);
          setSumaGastosExtraStrategy(gastoExtra);
          const disponble = ingreso - gastoFijo - gastoExtra - sumaSaldoUtilizado;
          setDisponibleMetaStrategy(disponble);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err);
    },
  });
  useEffect(() => {
    if (montoMetaStrategy !== '') {
      // debugger;
      getResponseUserByIdType({ variables: { types: '308,310,311', idUser: sessionStorage.getItem('idUser') } });
    }
  }, [montoMetaStrategy]);

  // Veify is the questions are already answered
  const [activeModalGoal, setActiveModalGoal] = useState(false);
  const handleChangeMonto = () => {
    const click = `ContinuarCantidadMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const myGoal = form.MontoMetaStrategy.replace('$', '').replace(/,/g, '').trim();

    // eslint-disable-next-line radix
    if (parseInt(myGoal) >= 50) {
      setMontoMetaStrategy(form.MontoMetaStrategy);
    } else {
      setActiveModalGoal(true);
    }
  };

  const [activeModalDisponible, setActiveModalDisponible] = useState(false);

  const handleChangeMontoQuincenal = () => {
    const click = `ContinuarMontoQuincenal_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    let metaQincenal = form.DineroMetaQuincenalStrategy;
    metaQincenal = parseFloat(metaQincenal.replace('$', '').replace(/,/g, '').trim());
    const disponible = disponibleMetaStrategy / 2;
    // eslint-disable-next-line no-debugger
    debugger;
    if (metaQincenal <= disponible) {
      if (metaQincenal >= 50) {
        const meses = parseFloat(form.MontoMetaStrategy.replace('$', '').replace(/,/g, '').trim())
               / parseFloat(form.DineroMetaQuincenalStrategy.replace('$', '').replace(/,/g, '').trim())
               / 2;
        const ahorroMes = parseFloat(metaQincenal) * 2;
        // eslint-disable-next-line radix
        setTiempodeAhorroStrategy(parseInt(meses));
        setDineroMetaQuincenalStrategy(form.DineroMetaQuincenalStrategy);
        setAhorroMensualStrategy(ahorroMes);
        // eslint-disable-next-line radix
        const idUser = parseInt(sessionStorage.getItem('idUser'));
        // eslint-disable-next-line radix
        const idCatTraining = currentTraining || parseInt(sessionStorage.getItem('idTraining'));
        const inputInsert = {
          input: {
            idUser,
            idTraining: idCatTraining,
            name: currentGoal,
            goalsDetails: [
              {
                idType: '343',
                value: form.MontoMetaStrategy,
              },
              {
                idType: '344',
                value: form.DineroMetaQuincenalStrategy,
              },
              {
                idType: '345',
                // eslint-disable-next-line no-use-before-define
                value: formatter.format(filterFloat(ahorroMes)),
              },
              {
                idType: '346',
                // eslint-disable-next-line radix
                value: String(parseInt(meses)),
              },
            ],
            saveAmount: '',
            description: '',
          },
        };
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(inputInsert));

        insertDatGoal({
          variables: inputInsert,
        });

        setVerNewGoal(false);
      }
    } else {
      setActiveModalDisponible(true);
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const RowTable = ({ textOne, textTow = '', marginTop = '' }) => (
         <div className="Row">
            <div className="Cell">
               <p style={{ marginTop }}>{textOne}</p>
            </div>

            <div className="Cell" st>
               <p style={{ marginTop }}>{textTow}</p>
            </div>
         </div>
  );

  return (
      <>
         {/* primer pregunta */}

         <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="Vamos lento pero seguro. ¿Qué cantidad de dinero necesitas para cumplir esta meta?"
            hora={hora}
         />
         <TrainingQuestion className={className}>
            <TextField
               variant="outlined"
               label="Monto"
               autoComplete="off"
               required
               fullWidth
               inputProps={{ maxLength: 20 }}
               className="textInput inputs-bottom input-coru"
               name="MontoMetaStrategy"
               value={form.MontoMetaStrategy}
               onBlur={(e) => handleChange(e)}
               onChange={(e) => handleChange(e)}
            />
         </TrainingQuestion>
         <TrainingQuestion className={className}>
            <QuestionsDivs padd="0" txta="left" width="100%" widthweb="100%" hei="auto" margin="0 auto auto" bc="#fafafa">
               <CardsBtn type="button" min_w="100%" min_web="100%" onClick={(e) => handleChangeMonto(e)}>
                  Continuar
               </CardsBtn>
            </QuestionsDivs>
         </TrainingQuestion>

         <CardOne
            classCard={montoMetaStrategy !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="Para crear una estrategia, consulté el presupuesto que creaste en Coru:"
            hora={hora}
         />
         <TrainingQuestion className={montoMetaStrategy !== '' ? '' : 'hidden'}>
            <QuestionsDivs padd="0" txta="left" width="100%" widthweb="100%" hei="auto" margin="0 auto auto" bc="#fafafa">
               <div className="Table" style={{ width: '100%', borderBottom: 'solid' }}>
                  <RowTable textOne={metas[0]} textTow={montoMetaStrategy}></RowTable>
                  <RowTable textOne="Metas" marginTop="-16px"></RowTable>
                  <RowTable textOne={
                     formatter.format(sumaIngresoStrategy)
                     } textTow={formatter.format(sumaGastosFijoStrategy)}></RowTable>
                  <RowTable textOne="ingresos" textTow="Gastos fijos" marginTop="-16px"></RowTable>
                  <RowTable textOne={formatter.format(sumaGastosExtraStrategy)}></RowTable>
                  <RowTable textOne="Gastos extras" marginTop="-16px"></RowTable>
               </div>
               <div className="Table" style={{ width: '100%' }}>
                  <RowTable textOne="Disponible para meta: " textTow={formatter.format(disponibleMetaStrategy)}></RowTable>
               </div>
            </QuestionsDivs>
         </TrainingQuestion>
         <TrainingQuestion className={montoMetaStrategy !== '' ? '' : 'hidden'}>
            <TrainingText className="cabin" align="left" weight="300" size="20px">
               ¿Cuánto dinero quieres usar <b style={{ fontWeight: 'bold' }}>quincenalmente</b> para cumplir tu meta?
            </TrainingText>

            <TextField
               variant="outlined"
               label="monto"
               autoComplete="off"
               required
               fullWidth
               inputProps={{ maxLength: 20 }}
               className="textInput inputs-bottom input-coru"
               name="DineroMetaQuincenalStrategy"
               value={form.DineroMetaQuincenalStrategy}
               onBlur={(e) => handleChange(e)}
               onChange={(e) => handleChange(e)}
            />
         </TrainingQuestion>
         <TrainingQuestion className={montoMetaStrategy !== '' ? '' : 'hidden'}>
            <QuestionsDivs padd="0" txta="left" width="100%" widthweb="100%" hei="auto" margin="0 auto auto" bc="#fafafa">
               <CardsBtn type="button" min_w="100%" min_web="100%" onClick={(e) => handleChangeMontoQuincenal(e)}>
                  Continuar
               </CardsBtn>
            </QuestionsDivs>
         </TrainingQuestion>
         {activeModalDisponible ? (
            <ModalDialog
               title="Actualizar meta"
               text="Por ahora no tienes dinero disponible para el cumplimiento de tu meta, te recomendamos ajustar tu presupuesto"
               showModal={activeModalDisponible}
            ></ModalDialog>
         ) : null}
         {activeModalGoal ? (
            <ModalDialog
               title="Monto mínimo"
               text="El monto mínimo para poder crear una meta es de $50.00"
               showModal={activeModalGoal}
            ></ModalDialog>
         ) : null}
      </>
  );
};

export default CreateStrategyGoal;
