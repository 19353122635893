import React from 'react';
import PropTypes from 'prop-types';
import {
  DocumentViewer,
  FormContainer,
  FormInpust,
  FormTitle,
} from '../../assets/styles/GloveBox.styled';
import { Col12 } from '../../components/common/common.styled';

const GloveInfoAcum = ({
  formTitle,
  formIcon,
  form,
}) => {
  const noHay = 'Sin Información';
  const mobile = ['iphone', 'ipad', 'android', 'nokia', 'opera mini'];
  const ua = navigator.userAgent.toLocaleLowerCase();
  // console.log(ua);
  for (let i = 0; i < mobile.length; i += 1) {
    if (ua.indexOf(mobile[i]) > -1) {
      // console.log('mobile');
    } else {
      // console.log('desktop');
    }
  }

  return (
    <FormContainer>
      <FormTitle>
        <img
          src={formIcon}
          alt='glove box document'
        />
        <p
          style={{
            fontSize: '16px',
            fontWeight: '700',
            margin: '0',

          }}
        >
          {formTitle}
        </p>
      </FormTitle>
      <FormInpust>
          { /* --------------------------- Póliza de seguro ----------------------- */
            formTitle === 'Póliza de seguro' ? (
            <>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Vigencia de la póliza
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.day !== ''
                      ? `${form.day}/${form.month}/${form.year}`
                      : noHay
                    }
                </p>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Forma de pago
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.pay !== '' ? form.pay : noHay}
                </p>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Cobertura
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.cove !== '' ? form.cove : noHay}
                </p>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Aseguradora
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.ase !== '' ? form.ase : noHay}
                </p>
            </>
            ) : null
          }
          { /* ----------------------- Póliza de asistencia en el camino ----------------------- */
              formTitle === 'Póliza de asistencia en el camino' ? (
                <>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        Vigencia de la póliza
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {form.day !== ''
                          ? `${form.day}/${form.month}/${form.year}`
                          : noHay
                        }
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        Forma de pago
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {form.pay !== '' ? form.pay : noHay}
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        Empresa
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {form.compa !== '' ? form.compa : noHay}
                    </p>
                </>
              ) : null
          }
          { /* ----------------------- Servicio de auto ----------------------- */
            formTitle === 'Servicio de auto' ? (
              <>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Fecha del último servicio
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.day !== ''
                      ? `${form.day}/${form.month}/${form.year}`
                      : noHay
                    }
                </p>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Fecha del próximo servicio
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.day2 !== ''
                      ? `${form.day2}/${form.month2}/${form.year2}`
                      : noHay
                    }
                </p>
              </>
            ) : null
          }
          { /* ----------------------- TAG ----------------------- */
            formTitle === 'TAG' ? (
              <>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Número de TAG
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.numtag !== '' ? form.numtag : noHay}
                </p>
              </>
            ) : null
          }
          { /* ----------------- LICENCIA TARGETA DE CIRCULACION, TENENCIA ----------------- */
            formTitle === 'Licencia de conducir'
            || formTitle === 'Tarjeta de circulación'
            || formTitle === 'Tenencia' ? (
              <>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    {`Vigencia de tu ${formTitle}`}
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.day !== ''
                      ? `${form.day}/${form.month}/${form.year}`
                      : noHay
                    }
                </p>
              </>
              ) : null
          }
          { /* ----------------- LICENCIA TARGETA DE CIRCULACION, TENENCIA ----------------- */
            formTitle === 'Verificación' ? (
              <>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Calcomanía
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.calc !== '' ? form.calc : noHay}
                </p>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Engomado
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.engoma !== '' ? form.engoma : noHay}
                </p>
                <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    Fecha de próxima verificación
                </p>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {form.day !== ''
                      ? `${form.day}/${form.month}/${form.year}`
                      : noHay
                    }
                </p>
              </>
            ) : null
          }

        {formTitle !== 'Servicio de auto' && formTitle !== 'TAG' && form.docV.url !== null ? (
            <>
              <Col12>
                <DocumentViewer>
                  { form.docV.ex === 'jpg' || form.docV.ex === 'png' || form.docV.ex === 'jpeg' ? (
                    <img
                      src={form.docV.url}
                      alt='document processed'
                      style={{ width: '100%' }}
                    />) : (
                    <embed
                      type='application/pdf'
                      src={`${form.docV.url}#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="600px"
                    />)
                  }
                </DocumentViewer>
              </Col12>
            </>) : (
              <>
                {formTitle !== 'Servicio de auto' && formTitle !== 'TAG' ? (
                  <>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Archivo
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        No hay ningun archivo adjunto
                    </p>
                  </>) : null
                }
              </>)
          }
      </FormInpust>
    </FormContainer>
  );
};

GloveInfoAcum.propTypes = {
  formTitle: PropTypes.string.isRequired,
  formIcon: PropTypes.string.isRequired,
  uploadText: PropTypes.string,
  form: PropTypes.object.isRequired,
};

export default GloveInfoAcum;
