/* eslint-disable react/prop-types */
import React, {useState} from "react";
import Parser from "html-react-parser";
import Carousel, {consts} from "react-elastic-carousel";
import {Button, Divider} from "@material-ui/core";
import {
  ContainerPage2,
  ContainerGeneral,
  Content1,
  SubContent,
  TextBase,
  TextTitle1,
  GridContent,
  HoverImageContainer,
  ContentCardP,
  HoverImg,
  ImgProduc,
  SubContentColor,
  TextGeneric,
  ContentCardExp,
  StartedButton,
} from "../Assets/Styles/CaasNew.styles";
import Cel from "../Assets/Imgs/Cel.svg";
import Cabeza from "../Assets/Imgs/Cabeza.svg";
import Card from "../Assets/Imgs/Card.svg";
import AiPersonalizada from "../Assets/Imgs/AiPersonalizada.svg";
import DigitalOnboarding from "../Assets/Imgs/DigitalOnboarding.svg";
import Userdiagnostic from "../Assets/Imgs/Userdiagnostic.svg";
import FinancialGamification from "../Assets/Imgs/FinancialGamification.svg";
import BienestarFinanciero from "../Assets/Imgs/BienestarFinanciero.svg";
import Algoritmo from "../Assets/Imgs/Algorithm.svg";
import {ContentGeneric} from "../../common/common.styled";

const CoruService = ({
  i1,
  i2,
  i3,
  i4,
  i5,
  i6,
  i7,
  i8,
  i9,
  i10,
  i11,
  i12,
  i13,
  i14,
  i15,
  i16,
  i17,
  i18,
  i19,
  i20,
  i21,
  i22,
  i23,
  i24,
  i25,
  i26,
  i27,
  i28,
  i29,
  i30,
  i31,
  i32,
  i33,
  i34,
  i35,
  i36,
  i37,
  i38,
  i39,
  i40,
  i41,
  i42,
}) => {
  const [Tupersonalizada, setTupersonalizada] = useState(false);
  const [OnboardingDigital, setOnboardingDigital] = useState(false);
  const [gamificacion, setGamificacion] = useState(false);
  const [Diagnostico, setDiagnostico] = useState(false);
  const [algoritmo, setAlgoritmo] = useState(false);
  const [bienestar, setBienestar] = useState(false);
  const [viewProductsMobile, setviewProductsMobile] = useState(false);
  const widthWindow = window.screen.availWidth;

  return (
    <ContainerPage2 style={{ backgroundColor: "#F7F6F9"}}>
      <ContainerGeneral
        direction="row"
        padding="60px 160px"
        justify="space-between"
        mpad="40px 16px"
        mobilPadding="40px 16px"
        display="block">
        <Content1>
          { /* <SubContent width="-webkit-fill-available" color="#F64282" msize="20px">
            {i1}
          </SubContent>*/ }
          <TextTitle1 weight="600" size="48px" margin="28px 0px 0px 0px" style={{ textAlign: 'center'}}>
            {i2}
          </TextTitle1>
          {/* <SubContent margin="35px 0 0">{i3}</SubContent> */}
        </Content1>
        <Content1 display="flex" widthD="100%" className="row">
          <GridContent className="col-md-4 col-12">
            <HoverImageContainer>
              <HoverImg alt="" src={Cel} />
            </HoverImageContainer>
            <TextBase
              color="#F64282"
              textAlign="center"
              fontSize="20px"
              margin="12px 0px 0px 0px"
              fontWeight="600">
              {i6}
            </TextBase>
            <TextBase
              color="#464646"
              textAlign="center"
              fontSize="12px"
              fontWeight="400"
              width="256px"
              display="flex"
              margin="16px auto auto auto">
              {i9}
            </TextBase>
          </GridContent>
          <GridContent className="col-md-4 col-12">
            <HoverImageContainer>
              <HoverImg alt="" src={Cabeza} />
            </HoverImageContainer>
            <TextBase
              color="#F64282"
              textAlign="center"
              fontSize="20px"
              margin="12px 0px 0px 0px"
              fontWeight="600">
              {i7}
            </TextBase>
            <TextBase
              color="#464646"
              textAlign="center"
              fontSize="12px"
              fontWeight="400"
              width="70%"
              display="flex"
              margin="16px auto auto auto">
              {i10}
            </TextBase>
          </GridContent>
          <GridContent className="col-md-4 col-12">
            <HoverImageContainer>
              <HoverImg alt="" src={Card} />
            </HoverImageContainer>
            <TextBase
              color="#F64282"
              textAlign="center"
              fontSize="20px"
              margin="12px 0px 0px 0px"
              fontWeight="600">
              {i8}
            </TextBase>
            <TextBase
              color="#464646"
              textAlign="center"
              fontSize="12px"
              fontWeight="400"
              width="70%"
              display="flex"
              margin="16px auto auto auto">
              {i11}
            </TextBase>
          </GridContent>
        </Content1>
      </ContainerGeneral>
    </ContainerPage2>
  );
};

export default CoruService;
