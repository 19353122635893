import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import coruCupon from '../../assets/img/anacoach/Coru-Cupon.png';
import {
  Col5,
  Col12,
  ParagrapTwo,
} from '../../components/common/common.styled';
import { Container } from '../../assets/styles/Home.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import CardOne from '../../components/Home/Card1Animate';
import {
  GET_RECOMMENDATION_TRAININGS,
  GET_NIP_TO_USER,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';

const RpViralityTestProfile = ({ hora }) => {
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [numCorrectas, setNumCorrectas] = useState({});
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          const woFilter = getPersonalizeRecommendationTrainings.response.filter(
            (e) => e.IDCatTraining === idWOPrimeraInversion,
          );
          woFilter[0].TrainingRedirectUrl += '?utm_wosource=viralityTest&utm_womedium=primeraInversion&utm_coupon=coupon';
          setRecommendedTraining(woFilter);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const correcta = 1;
  const idWOPrimeraInversion = 17;
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  useEffect(() => {
    const relAnswers = JSON.parse(
      sessionStorage.getItem('relQuestionsAnswersViralityTest'),
    );
    const relQuestions = JSON.parse(
      sessionStorage.getItem('auxRecoveryIdCatQuestionDetailViralityTest'),
    );
    setNumCorrectas(relAnswers.filter((e) => e === correcta).length);
    setAnswers(relAnswers);
    setQuestions(relQuestions);
    scrollToElem('init');
  }, []);
  const tamScreen = window.screen.width;
  const scroller = (top_avance, top_avance_mobile) => {
    const avance = document.body.scrollHeight;
    console.log(avance);
    console.log('scrollTo: ', avance - top_avance_mobile);
    if (tamScreen > 1024) {
      window.scroll({
        top: avance - top_avance,
        behavior: 'smooth',
      });
    } else {
      window.scroll({
        top: avance - top_avance_mobile,
        behavior: 'smooth',
      });
    }
  };
  const obtenerNIP = (e) => {
    e.persist();
    getNipToUser({ variables: { idUser: sessionStorage.getItem('idUser') } });
  };

  const [nipAsignado, setNipAsignado] = useState('');
  const [getNipToUser] = useMutation(GET_NIP_TO_USER, {
    onCompleted({ getNipToUser }) {
      if (getNipToUser.statusCode === 200 && getNipToUser.response.length > 0) {
        const { nip } = getNipToUser.response[0];
        sessionStorage.setItem('nipVirality', nip);
        setNipAsignado(nip);
      }
    },
  });
  const [goFeed] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
  }, [goFeed]);
  useEffect(() => {
    if (sessionStorage.getItem('nipVirality')) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'Otpemail',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [nipAsignado]);

  const scrollToElem = (id) => {
    switch (id) {
      case 'init':
        window.scrollTo(100, 100);
        break;
      default:
        console.log('default case');
        scroller(900, 500);
    }
  };

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    const idTraining = sessionStorage.getItem('idTraining');
    const fromXCoach = sessionStorage.getItem('fromXCoach');
    const idAdviser = sessionStorage.getItem('idAdviser');
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === 'true' ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  return (
    <div>
      {numCorrectas !== answers.length ? (
        <Fragment>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text={
              `Respondiste bien a ${
                numCorrectas
              } de 5 preguntas ¡No te rindas! Te esperamos la próxima semana.`
            }
            hora={hora}
          />

          {answers.map((e, index) => (e === 0 ? (
              <>
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  textbold={questions[index].QuestionName}
                  text={questions[index].QuestionOption}
                  hora={hora}
                />
              </>
          ) : null))}

          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="¡No le digas a nadie! Si quieres recibir tu cupón de regalo haz el entrenamiento de inversiones, ahí encontrarás las respuestas correctas"
            hora={hora}
          />
          {recomendaciones ? (
            <Fragment>
              <Container
                width="160%"
                mleft="0%"
                widthMobile="100%"
                mleftm="auto"
                mtMobile="0px"
              >
                <Feed_Section padding="0 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </Fragment>
          ) : null}
        </Fragment>
      ) : (
        <Fragment>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="¡Increíble, respondiste bien a todas! Este es tu regalo."
            hora={hora}
          />

          <Col12 ta="center">
            <Container
              width="100%"
              mt="0%"
              widthMobile="100%"
              mtMobile="0%"
              float="auto"
            >
              <Col5 className="mobileWithChicos" padding="0" border="">
                <div
                  className="col-12 col-sm-12 col-xs-12"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    obtenerNIP(e);
                  }}
                >
                  <img
                    alt=""
                    src={coruCupon}
                    className="col-12 col-sm-12 col-xs-12 img-responsive"
                    left="30%"
                    top="15px"
                    position="absolute"
                  ></img>
                  <ParagrapTwo
                    mtWeb="-2.8rem"
                    mb="5%"
                    textalignMobile="right"
                    texttalign="right"
                    className="mx-auto roboto col-12 col-sm-10 col-xs-10"
                    mt="-12%"
                    fontSize="12px"
                  >
                    Da clic aquí y
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="-0.5rem"
                    mb="5%"
                    textalignMobile="right"
                    texttalign="right"
                    className="mx-auto roboto col-12 col-sm-10 col-xs-10"
                    mt="-5%"
                    fontSize="12px"
                  >
                    descubre tu regalo
                  </ParagrapTwo>
                </div>
              </Col5>
            </Container>
          </Col12>
          {nipAsignado !== '' ? (
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              textbold="Hemos enviado a tu correo electrónico tu NIP para reclamar tu regalo."
            />
          ) : null}
        </Fragment>
      )}
      {(numCorrectas === answers.length && nipAsignado !== '')
      || numCorrectas !== answers.length ? (
        <Fragment>
          <ModalAmazon show={true} wo={'ViralityTest'}></ModalAmazon>
          <SectionShare WO="ViralityTest"></SectionShare>
        </Fragment>
        ) : null}
    </div>
  );
};

export default RpViralityTestProfile;
