import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  Image,
  ImageIcon,
  ModalFull,
  ParagrapTwo,
} from './common.styled';
import {
  ContentHomeSection, FInalcialProfile,
  RectangleAna,
  CoachAna1,
  ElipseCoach,
  LogocoruCoach,
} from './Home.styled';
import logocoruCoach from '../../assets/img/home/logoCoach.png';
import fondo_modalNumber from '../../assets/img/anacoach/fondo_modalNumber.svg';
import CoruLogo from '../../assets/img/Coru_Logo.svg';
import { Button, Text } from '../../assets/styles/kanda/KandaContent.style';
import { TextMaxLine } from '../../anaCoach/Routines/KnowScore';
import { Container } from '../../assets/styles/Home.styled';
import ClickButton from '../../Helpers/HookHelpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '55%',
    '@media (max-width: 768px)': {
      height: '38%',
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalSuccessRegister = ({
  setOpen,
  open,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const { actions } = ClickButton();

  useEffect(() => {

  }, []);

  const handleIngresoCoru = () => {
    actions({
      action: 'ClickButtonForBusiness',
      variables: 'clickbuttonConocemásCFB',
      collection: 'ClickButtonHeaderFor_Business',
    });
    window.location.href = '/the-training-feed/';
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };
  const heightW = window.screen.height;
  return (
        <Modal open={open}>
            <ModalFull
              width="50%"
              widthXs="90%"
              height={`${heightW - 300}px`}
              heightXs={`${heightW - 50}px`}
              bottom="auto"
              borderR="16px"
              margin="5% 25%"
              marginXs="5%"
              className='d-flex flex-column'
              justifyC="flex-start"
              textAlign="center"
            >
              <Container height={`${heightW - 50}px`} style={{ overflowY: 'scroll' }} >
                <ParagrapTwo
                  width="70%"
                  widthM="95%"
                  fontWeight="700"
                  cursor="pointer"
                  texttalign="right"
                  textalignMobile="right"
                  className='mx-auto'
                  onClick={handleClose}
                  mt="20PX"
                  mtWeb="20px"
                >
                  X
                </ParagrapTwo>
                <ImageIcon
                  widthMobile="60%"
                  width="40%"
                  margin="30px auto"
                  src={CoruLogo}
                />
                <TextMaxLine
                  lineHeight="74.4px"
                  fontSize="50px"
                  fontSizeXs="40px"
                  lineHeightXs="40px"
                  fontFamily='cabin'
                  fontColor="#393A80"
                  margin="auto 34px"
                >
                  ¡Tu registro se ha enviado con éxito!</TextMaxLine>
                <TextMaxLine
                  lineHeight="29px"
                  fontSize="20px"
                  fontSizeXs="16px"
                  lineHeightXs="20px"
                  fontFamily='cabin'
                  fontColor="#393A80"
                  margin="24px"
                >
                   En un momento, uno de nuestros expertos se pondrá en contacto
                   contigo para darte más información sobre todos los servicios que
                   Coru tiene para ti.
                </TextMaxLine>
                <TextMaxLine
                  lineHeight="29px"
                  fontSize="20px"
                  fontSizeXs="16px"
                  lineHeightXs="20px"
                  fontFamily='cabin'
                  fontColor="#393A80"
                  margin="24px"
                >
                  Si quieres ver más información sobre los entrenamientos y productos
                  que Coru tiene para ti, da clic en “Conocer más de Coru”
                </TextMaxLine>
                <Button
                  width="80%"
                  margin="auto auto 50px"
                  onClick={() => handleIngresoCoru()}
                >
                  Conocer más de Coru
                </Button>
              </Container>
            </ModalFull>
        </Modal >
  );
};

export default ModalSuccessRegister;
