import React from 'react';
import PropTypes from 'prop-types';

import { FormInstruction, InputFileLabel } from '../../assets/styles/GloveBox.styled';
import uploadDocument from '../../assets/img/anacoach/uploadDocument.svg';

const DocUpload = ({
  routine,
  form,
  handleChange,
}) => (
    <FormInstruction>
      <input
        type="file"
        name="uploadfile"
        id={form.title}
        style={{ display: 'none' }}
        onChange={(e) => handleChange(e, routine)}
        />
        <InputFileLabel htmlFor={form.title} >
        <img src={uploadDocument} alt='glove box document' />
        <p
            style={{
              fontSize: '14px',
              fontWeight: '400',
              margin: '0',
            }}
        >
            {form.ut}
        </p></InputFileLabel>
    </FormInstruction>
);

DocUpload.propTypes = {
  routine: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DocUpload;
