/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CSVReader from 'react-csv-reader';
import { Main } from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import { DYNAMIC_GET, DYNAMIC_UPDATE } from '../../mutations';
import { VERIFY_TOKEN } from '../../queries';

function BlackList() {
  const valToken = useQuery(VERIFY_TOKEN, {
    variables: { token: sessionStorage.getItem('token') },
  });
  if (valToken.loading !== true) {
    if (valToken.data.verifyToken.response === 'invalid') {
      window.location.href = '/admin/';
    }
  }
  const [registerUpdated, setUpdated] = useState(0);
  const [registerCreated, setCreated] = useState(0);
  const [registerFound, setFound] = useState(0);
  const [registerError, setError] = useState(0);
  const [registerTotal, setTotal] = useState(0);
  const setTotalRegister = async () => {
    const getRegisterInput = {
      input: {
        table: 'DatBlackList',
        columns: ['count(*) as total'],
      },
    };
    const { data } = await getRegister({ variables: getRegisterInput });
    const register = JSON.parse(data.dynamicGet.response);
    setTotal(register[0].total);
  };
  useEffect(() => {
    setTotalRegister();
  }, []);

  const [getRegister] = useMutation(DYNAMIC_GET, {
    async onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const register = JSON.parse(dynamicGet.response);
        return new Promise((resolve) => {
          resolve(register);
        });
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log(dynamicUpdate);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleForce = async (data, fileInfo) => {
    let updated = 0;
    const created = 0;
    const found = 0;
    let error = 0;
    for (const element of data) {
      if ('idcont' in element && 'email' in element) {
        await dynamicUpdate({
          variables: {
            input: {
              table: 'CatContactMedium',
              columns: [
                {
                  setkey: 'BlacklistEmail',
                  setval: element.email_status,
                },
              ],
              conditions: [
                {
                  valone: 'CatContact_IDCont',
                  condition: '=',
                  valtwo: String(element.idcont),
                },
              ],
            },
          },
        });
        updated++;
      } else if ('idcont' in element && 'cellphone' in element) {
        await dynamicUpdate({
          variables: {
            input: {
              table: 'CatContactMedium',
              columns: [
                {
                  setkey: 'BlacklistCellphone',
                  setval: element.cellphone_status,
                },
              ],
              conditions: [
                {
                  valone: 'CatContact_IDCont',
                  condition: '=',
                  valtwo: String(element.idcont),
                },
              ],
            },
          },
        });
        updated++;
      } else {
        error++;
      }
    }
    setUpdated(updated);
    setCreated(created);
    setFound(found);
    setError(error);
    setTotalRegister();
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };

  return (
    <Fragment>
      <Main
        overflow=" "
        ptuno={'70px'}
        pt={'80px'}
        background="#fafafa"
        pb={'35%'}
        mt="0%"
        mtWeb="0%"
      >
        <div className="container">
          <p>{registerTotal} Registros actualmente</p>
          <CSVReader
            cssClass="react-csv-input"
            label="BLACKLIST SNC"
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
          <br />
          <h3>Resultados de carga:</h3>
          <p>{registerCreated} Emails agregados exitosamente</p>
          <p>{registerUpdated} Emails actualizados</p>
          <p>{registerFound} Emails ya encontrados</p>
          <p>{registerError} Error</p>
        </div>
      </Main>
    </Fragment>
  );
}
export default BlackList;
