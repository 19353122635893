import styled from 'styled-components';

export const FooterViralidad = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  margin-left: ${(props) => props.left || ' '};
  @media (max-width: 769px) {
    width: 100%;
  }
`;

// eslint-disable-next-line camelcase
export const FooterContainerHome = styled.div`
  color: ${(props) => props.color || ' '};
  background-color: ${(props) => props.backcolor || '#fff'};
   bottom: 0!important;
   width: 100%;
   text-align: center;
  display: ${(props) => props.displayW || ' '};

  @media (max-width: 700px) {
    display: ${(props) => props.displayM || ' '};

  }
`;

export const FooterDescriptionHome = styled.div`
  padding: ${(props) => props.padding || '20px'};
  padding-top: ${(props) => props.paddingt || ''};
  padding-bottom: ${(props) => props.paddingMobile || ' '};
  color: ${(props) => props.color || '#000b49'};
  background-color: ${(props) => props.backcolor || '#fff'};
  height: ${(props) => props.height || '350px'};
   @media (max-width: 700px) {
    display: ${(props) => props.displayM || ' '};
    height: ${(props) => props.heightM || '505px'};

  }
  @media (min-width: 480px) {
    display: flex;
    padding-top: ${(props) => props.paddingWeb || '7px'};
    padding-bottom: ${(props) => props.paddingBWeb || ' '};

  }
`;

// eslint-disable-next-line camelcase
export const FooterVerticals = styled.div`
   margin: 0;
    text-align: left;
    width: ${(props) => props.width || '100%'};
    margin-left: ${(props) => props.left || ' '};
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    cursor: ${(props) => props.cursor || 'pointer'};
    font-size: ${(props) => props.size || '16px'};
    line-height: ${(props) => props.line || '255.5%;'};
    /* or 41px */
    letter-spacing: -0.3px;
    display: ${(props) => props.displayD || ' '};
    @media (max-width: 700px) {
        display: ${(props) => props.displayM || ' '};
        width: ${(props) => props.widthM || '100%'};
    }
`;

// eslint-disable-next-line camelcase
export const FooterVerticalsA = styled.a`
   
    margin: 0;
    text-align: left;
    width: ${(props) => props.width || '100%'};
    margin-left: ${(props) => props.left || ' '};
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    cursor: ${(props) => props.cursor || 'pointer'};
    font-size: ${(props) => props.size || '16px'};
    line-height: ${(props) => props.line || '255.5%;'};
    color:#061D80;
    /* or 41px */
    &:hover {
      color:#061D80;

    }
    letter-spacing: -0.3px;
    display: ${(props) => props.displayD || 'block'};
    @media (max-width: 700px) {
        display: ${(props) => props.displayM || 'block'};
    }
`;


// eslint-disable-next-line camelcase
export const FooterDiv3 = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: 100%;
    padding-left: ${(props) => props.pl || ' '};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || 'none'};
  }
`;

export const FooterShareDiv = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || 'block'};
  }
`;

// eslint-disable-next-line camelcase
export const FooterDiv1 = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: ${(props) => props.width || '50%'};
    text-aling: left;
    display: none;
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || 'block'};
  }
`;
// eslint-disable-next-line camelcase
export const FooterDiv2 = styled.div`
  width: auto;
   margin-top: ${(props) => props.mtop|| ' '};
  @media (min-width: 480px) {
    width: ${(props) => props.width || '50%'};
    margin-top: ${(props) => props.mtop|| ' '};
    display: none;
  
  }

  
`;







// eslint-disable-next-line camelcase
export const HeaderLog = styled.div`
  color: #000b49;
  font-weight: 600;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  width: ${(props) => props.width || '50%'};
  cursor: pointer;
  font-size: ${(props) => props.fontSize || '1.1vw'};
  margin-right: ${(props) => props.mr || ' '};
  margin: ${(props) => props.margin || ' '};
  margin-left: ${(props) => props.left || ' '};
  @media (min-width: 480px) {
    text-align: ${(props) => props.textAlign || 'left'};
    width: ${(props) => props.widthW || '100%'};
    margin: ${(props) => props.marginW || 'auto'};
    margin-left: ${(props) => props.leftW || ' '};
    margin-right: ${(props) => props.rightW || ' '};
    font-weight: 400;
  }
  @media (min-width: 1400px) {
    font-weight: 400;
  }
`;
export const ContentFiguras = styled.div`
  width: 227.58px;
  height: 398.56px;
  /* margin-left: 227.42px; */
  /* margin-top: -102%; */
  position: absolute;
  bottom: 0%;
  left: 57%;
`;
// eslint-disable-next-line camelcase
export const HeaderLogMob = styled.div`
  position: ${(props) => props.position || ' '};
  bottom: ${(props) => props.Pbottom || ' '};
  font-weight: ${(props) => props.fw || '400'};
  width: 90%;
  cursor: pointer;
  font-family: Cabin;
  font-style: normal;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: -0.3px;
  color: #07210a;
  text-align: ${(props) => props.aling || 'left'};
  margin-top: ${(props) => props.top || ' '};
  margin-bottom: ${(props) => props.bottom || ' '};
  @media (min-width: 480px) {
    text-align: left;
    font-size: 14px;
    width: 100%;
    margin: auto;
    font-weight: ${(props) => props.fw || '400'};
  }
`;
// eslint-disable-next-line camelcase
export const HeaderLogBtn = styled.div`
  background: #fb245c;
  border-radius: 3px;
  color: #fff !important;
  font-weight: 600;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  width: ${(props) => props.width || '25%'};
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding: ${(props) => props.padding || '9px 15px'};
  margin-left: ${(props) => props.left || '10%'};
  margin-top: ${(props) => props.top || ' '};
  position: ${(props) => props.position || ' '};
  bottom: ${(props) => props.bottom || ' '};
  transition: 0.3s;
  &:hover {
    background-color: #d31e4d;
  }
  float: ${(props) => props.float || ' '};
  max-width: ${(props) => props.maxwidth || ' '};
  @media (min-width: 768px) {
    margin-top: 5%;
  }
  @media (min-width: 480px) {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
`;
// eslint-disable-next-line camelcase
export const HeaderLogVerticalDivisor = styled.div`
  border-left: 1px solid #000000;
  height: auto;
`;

// eslint-disable-next-line camelcase
export const HeaderLogSelect = styled.div`
  color: #000b49;
  font-weight: 600;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  width: ${(props) => props.width || '50%'};
  cursor: pointer;
  font-size: ${(props) => props.fontSize || '1.1vw'};
  margin-right: ${(props) => props.mr || ' '};
  margin: ${(props) => props.margin || ' '};
  margin-left: ${(props) => props.left || ' '};
  @media (min-width: 480px) {
    text-align: ${(props) => props.textAlign || 'left'};
    width: ${(props) => props.widthW || '100%'};
    margin: ${(props) => props.marginW || 'auto'};
    margin-left: ${(props) => props.leftW || ' '};
    margin-right: ${(props) => props.rightW || ' '};
    font-weight: 400;
  }
  @media (min-width: 1400px) {
    font-weight: 400;
  }
`;
// eslint-disable-next-line camelcase
export const HeaderLogSelectOptionContainer = styled.div`
  ${(props) => (props.display !== '' ? `display: ${props.display};` : '')}
  position: absolute;
  padding-top: 2rem;
  background-color: ${(props) => props.bgColor || 'rgb(250, 250, 250)'};
  text-align: left;
  width: ${(props) => props.width || '9%'};
  padding: ${(props) => props.padding || '5px'};
  z-index: ${(props) => props.zIndex || '5'};
`;
// eslint-disable-next-line camelcase
export const HeaderLogSelectOption = styled.div`
  padding-left: ${(props) => props.paddingLeft || '8px'};
  transition: .2s ease;
  margin-bottom: .5rem;
  z-index: ${(props) => props.zIndex || '5'} ;
  &:hover {
    border-left: 6px solid #B5D7AA;
    padding-left: ${(props) => props.paddingLeft || 'px'};
    font-weight: bold;
    color: #07210A;
    /* transition .2s ease; */
  }
`;
// eslint-disable-next-line camelcase
export const HeaderLogSelectIcon = styled.img`
  max-width: 10px;
  position: relative;
  margin-top: 5px;
  margin-left: 5px;
  float: right;
`;

// eslint-disable-next-line camelcase
export const ContentOptions = styled.div`
  width: ${(props) => props.width || ''};
  display: ${(props) => props.dis || 'flex'};
  flex-direction: ${(props) => props.fd || ' '};
   justify-content:${(props) => props.Jc || ' '};




   
`;


export const SpaceMobileHeader = styled.div`

margin-right:320px ;
  margin-left:300px ;

@media (max-width: 768px) {
  margin-right:150px ;
  margin-left:170px ;

   
  }

@media (max-width: 425px) {
  margin-right:25px ;
  margin-left:50px ;

 
  }

  @media (max-width: 390px) {

    margin-right:5px ;
    margin-left:30px ;

 
  }
  @media (max-width: 375px) {

    margin-right:5px ;
    margin-left:30px ;

 
  }

   @media (max-width: 320px) {

    margin-right:0px ;
    margin-left:10px ;

 
  }
   
`;














// eslint-disable-next-line camelcase
export const HeaderOptDiv = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
export const MainHeader = styled.div`
 width: '100%';
 display: flex;
 height: autopx;
 position: fixed;
 top: 0;
 left:0;
 right:0;
 z-index: 20;
 background: rgb(255, 255, 255);
 padding : 10px 20px 24px 20px;

  background: ${(props) => props.background || ' '};
  background-size: ${(props) => props.backgroundsize || ' '};
  background-repeat: ${(props) => props.backgroundrepeat || ' '};
 
  /* position: fixed; */
  text-align: ${(props) => props.ta || 'left'};
  margin-top: ${(props) => props.mt || ''};
  padding-bottom: ${(props) => props.pb || '0px'};
  overflow: ${(props) => props.overflow || ''}; /*auto*/
  border-bottom: ${(props) => props.bbottom || ''};
  margin-bottom: ${(props) => props.mbottom || ''};
  min-height: ${(props) => props.minheigthWeb || ''};
  margin-top: ${(props) => props.margintop || ''};
  

  /* @media (min-width: 599px) {
    padding-top: ${(props) => props.ptuno || ''};
  }
  @media (max-width: 600px) {
    min-height: ${(props) => props.minheigth || ''};
    margin-top: ${(props) => props.mtWeb || ''};
    margin-bottom: ${(props) => props.mbWeb || ''};
    padding: ${(props) => props.paddingWeb || props.padding};
  }
  padding-top: ${(props) => props.pt || ''}; */

  @media (max-width: 700px) {
   border-bottom-right-radius: 16px;
   border-bottom-left-radius: 16px;
  }
`;

export const Header_Div_Web = styled.div`
  display: none;
  @media (min-width: 480px) {
    display: inline-flex;
    width: 70%;
    justify-content: ${(props) => props.justifycontent || 'space-around'};
    margin-right: ${(props) => props.mr || ''};
    font-family: system-ui;
    font-weight: 600;
  }
  justify-content: flex-end;
`;
export const HeaderHomeWeb = styled.div`
  display: none;
   @media (min-width: 1231px) {
    display: block !important;
    width: ${(props) => props.width || '60%'};
   
  } 
  /* @media (max-width: 947px) {
    width: 200%;
  } */
  /* @media (max-width: 814px) {
    width: 230%;
  }
  @media (max-width: 809px) {
    width: 244%;
  }
  @media (max-width: 794px) {
    width: 155%;
  }
  @media (max-width: 778px) {
    width: 114%;
  } */
  /* @media (max-width: 832px) {
    width: 240%;
  }
  @media (max-width: 810px) {
    width: 300%;
  }
  @media (max-width: 800px) {
    width: 125%;
  } */
`;

export const HeaderHomeMob = styled.div`
  display: none;
  @media (max-width: 1231px) {
    display: flex !important;
     width:100%;
     height:75px;
    justify-content:space-between;
  }
  /* @media (max-width: 728px) {
   margin-left: 5px;

   
  }
  @media (max-width: 425px) {
  
   margin-left: 5px;
 
  }
   @media (max-width: 375px) {
 

   margin-left: 10px;

  }

   @media (max-width: 320px) {

  margin-left: 1px;

 
  } */
`;

export const Togglebtn = styled.p`
  font-size:  ${(props) => props.mfonts || ' 35px'};
  cursor: pointer;
  /*background-color: #fff;*/
  color: ${(props) => props.color || 'gray'};
  padding: 0;
  border: none;
   margin: 15px 0px 0px 10px ; 
 

  ::hover {
    background-color: #444;
  }

`;




export const Sidepannel = styled.div`
  width: 0;
  position: fixed;
  z-index: 10;
  height: auto;
  top: 0;
  left: 0;
  bottom:0;
  background-color: rgb(255, 255, 255, 1);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 100px;
`;


export const Image = styled.img`
  max-width: ${(props) => props.maxwidth || '100%'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ' '};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.bottom || ''};
  margin-right: ${(props) => props.marginRight || ''};
  margin-left: ${(props) => props.marginLeft || ''};
  position: ${(props) => props.position || 'initial'};
  left: ${(props) => props.left || ''};
  top: ${(props) => props.top || ''};
  cursor: ${(props) => props.cursor || ' '};
  display: ${(props) => props.displayWeb || ' '};
  border: ${(props) => props.border || ' '};
  z-index: ${(props) => props.zindex || ''};
  transform: ${(props) => props.transform || ''};
  transform-origin: ${(props) => props.to || ''};
  max-height: ${(props) => props.mheight || ' '};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ''};
    width: ${(props) => props.mwidth || props.width || '100%'};
    left: ${(props) => props.mleft || ''};
    top: ${(props) => props.mtop || ''};
    margin-right: ${(props) => props.marginRightM || ''};
    margin-top: ${(props) => props.marginTopM || ''};
    margin-left: ${(props) => props.marginLeftM || ''};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || ' '};
    height: ${(props) => props.heightMobile || ' '};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || '100%'};
    height: ${(props) => props.heightMobile || ' '};
    display: ${(props) => props.displayMobile || ' '};
    margin-top: ${(props) => props.mtMob || ' '};
    margin-bottom: ${(props) => props.mbMob || ' '};
    margin-left: ${(props) => props.mlMob || ' '};
  }
`;
