/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  TrainingDivSection,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import Features from '../../components/Training/Features';
import { Image } from '../../components/common/common.styled';
import { Title1 } from '../../components/common/Home.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import confetis from '../../assets/img/anacoach/Hero.png';
import tablaControlAhorro from '../../assets/img/anacoach/TablaControlA.svg';
import sistemaAlertas from '../../assets/img/anacoach/SistemaDeAlertas.svg';
import broxel from '../../assets/img/anacoach/broxel.svg';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ClickButton from '../../Helpers/HookHelpers';

const styles = {
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  letterSpacing: '-0.3px',
  color: '#000000',
};
const stylesResult = {
  marginTop: '10px',
  textAlign: 'left',
  fontSize: '16px',
  padding: '2% 2% 2% 3%',
  border: '1px solid #888',
  color: '#000000',
  borderRadius: '4px',
};

const SavingsPlan = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  setPlan,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery, setCountRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length + contVideos + 1,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    console.log('Entro useEffect PLAN DE AHORRO');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };
  const [savingWay, setSavingWay] = useState(
    sessionStorage.getItem('FormaDeAhorro') || '',
  );
  const [monthsSavings, setMonthsSavings] = useState(
    sessionStorage.getItem('MesesDeAhorro') || '',
  );
  const [emergingSaving, setEmergingSaving] = useState(
    sessionStorage.getItem('IniciarAhorroEmegente') || '',
  );
  const [startSaving, setStartSaving] = useState('');
  const [continues, setContinue] = useState('');
  const [continues2, setContinue2] = useState('');
  const [form, setForm] = useState({
    savingMethod: '',
    monthlyAmount:
      sessionStorage.getItem('monthlyAmount') < 0
        ? '0'
        : sessionStorage.getItem('monthlyAmount') || '',
    savingTime:
      sessionStorage.getItem('MesesLogroDeAhorro') === NaN
      || sessionStorage.getItem('MesesLogroDeAhorro') === 'NaN'
        ? '0 meses'
        : sessionStorage.getItem('MesesLogroDeAhorro') || '',
  });

  useEffect(() => {
    if (startSaving === 'Iniciar plan de ahorro') {
      gsap.to(window, { duration: 1, scrollTo: '#ComenzarPlan' });
      const click = `IniciarPlanDeAhorro_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [startSaving]);

  useEffect(() => {
    if (continues === 'continuar') {
      gsap.to(window, { duration: 1, scrollTo: '#CtnControlAhorro' });
      const click = `ContinuarHerramientas_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [continues]);

  useEffect(() => {
    if (continues2 === 'continuar') {
      gsap.to(window, { duration: 1, scrollTo: '#CtnOpcionesAhorro' });
      const click = `ContinuarCatalogo_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [continues2]);

  const [texterror] = useState({
    savingMethod: '',
    monthlyAmount: '',
    savingTime: '',
  });
  const [error] = useState({
    savingMethod: false,
    monthlyAmount: false,
    savingTime: false,
  });
  const validDigitos = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (name && !/^[0-9-$]+$/i.test(value)) {
      tel.className = 'textInput inputs-bottom invalidInputSaving';
      texterror[name] = 'Por favor verifica tu código postal';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInputSaving';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    if (e.target.name !== 'savingTime') {
      e.target.value = e.target.value.replace(/[^0-9]/i, '');
      setForm({
        ...form,
        [e.target.name]: e.target.value.replace(/[^0-9]/i, ''),
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === 'monthlyAmount') {
      sessionStorage.setItem('monthlyAmount', e.target.value);
    }

    console.log('**********Entro a handleChange******************');
    console.log(e.target.name);
    console.log('***************************');
    switch (e.target.name) {
      case 'savingMethod':
        validDigitos(e.target.name, e.target.value, e.target.id);
        break;
      case 'monthlyAmount':
        validDigitos(e.target.name, e.target.value, e.target.id);
        break;
      default:
        console.log('handleChange default case');
        break;
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    switch (numQuestion) {
      case 1:
        setEditSavingWay(true);
        break;
      case 2:
        setEditMonthsSavings(true);
        console.log('Entro a cuestion 2');
        break;
      case 3:
        setEditEmergingSaving(true);
        console.log('Entro a cuestion 3');
        break;
      default:
        break;
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        setSavingWay(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#FormaAhorro' });
        break;
      case 1:
        setMonthsSavings(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#MesesAhorro' });
        form.savingTime = val.value;
        break;
      case 2:
        setEmergingSaving(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#AhorroActual' });
        break;
      default:
        console.log(val);
    }
  };

  const handleSubmit = (event) => {
    console.log('previniendo el enter');
    event.preventDefault();
  };
  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);

  useEffect(() => {
    let cantidadMD = convertirNum(sessionStorage.getItem('SumaIngresos'))
      - convertirNum(sessionStorage.getItem('SimaEgresos'));
    let result = '';
    if (savingWay === 'Según tu ingreso' && cantidadMD > 0) {
      const mes = monthsSavings.split(' ')[0];
      const r = parseFloat(mes) * convertirNum(sessionStorage.getItem('SumaIngresos'));
      result = r.toString();
    } else if (savingWay === 'Según tus gastos' && cantidadMD > 0) {
      const mes = monthsSavings.split(' ')[0];
      const r = parseFloat(mes) * convertirNum(sessionStorage.getItem('SimaEgresos'));
      result = r.toString();
    } else if (savingWay === 'Según tu ingreso' && cantidadMD < 0) {
      const mes = monthsSavings.split(' ')[0];
      const r = parseFloat(mes) * convertirNum(sessionStorage.getItem('SumaIngresos'));
      result = r.toString();
      cantidadMD = '0';
      sessionStorage.setItem('TablaObjetivos', r);
      sessionStorage.setItem('MesesLogroDeAhorro', '0 meses');
    } else if (savingWay === 'Según tus gastos' && cantidadMD < 0) {
      const mes = monthsSavings.split(' ')[0];
      const r = parseFloat(mes) * convertirNum(sessionStorage.getItem('SimaEgresos'));
      result = r.toString();
      cantidadMD = '0';
      sessionStorage.setItem('TablaObjetivos', r);
      sessionStorage.setItem('MesesLogroDeAhorro', '0 meses');
    }
    if (
      recoveryRoutine === 'activa'
      || (sessionStorage.getItem('idUser') === '0'
        && sessionStorage.getItem('idCont') === '0')
    ) {
      setSavingWay(sessionStorage.getItem('FormaDeAhorro') || '');
      setMonthsSavings(sessionStorage.getItem('MesesDeAhorro') || '');
      setEmergingSaving(sessionStorage.getItem('IniciarAhorroEmegente') || '');
      if (cantidadMD === '0') {
        setForm({
          savingMethod: result || '',
          monthlyAmount: cantidadMD,
          savingTime: '0 meses',
        });
      } else {
        setForm({
          savingMethod: result || '',
          monthlyAmount:
            sessionStorage.getItem('monthlyAmount')
            || cantidadMD.toString()
            || '0',
          savingTime: sessionStorage.getItem('MesesLogroDeAhorro') || '',
        });
      }
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    const cantidadMD = convertirNum(sessionStorage.getItem('SumaIngresos'))
      - convertirNum(sessionStorage.getItem('SimaEgresos'));
    sessionStorage.setItem('monthlyAmount', form.monthlyAmount);
    if (savingWay === 'Según tu ingreso' && cantidadMD > 0) {
      let meta = 0;
      let mesesLogro = '';
      if (emergingSaving === 'Sí') {
        const mes = monthsSavings.split(' ')[0];
        meta = parseFloat(mes)
          * convertirNum(sessionStorage.getItem('SumaIngresos'));
        const ahorroMensual = form.monthlyAmount
          ? Math.round(
            (meta - convertirNum(sessionStorage.getItem('CantidadAhorro')))
                / convertirNum(form.monthlyAmount),
          )
          : Math.round(
            (meta - convertirNum(sessionStorage.getItem('CantidadAhorro')))
                / cantidadMD,
          );
        mesesLogro = ahorroMensual.toString();
        sessionStorage.setItem('MesesLogroDeAhorro', `${mesesLogro} meses`);
      } else if (emergingSaving === 'No') {
        const mes = monthsSavings.split(' ')[0];
        meta = parseFloat(mes)
          * convertirNum(sessionStorage.getItem('SumaIngresos'));
        const ahorroMensual = Math.round(
          meta / convertirNum(form.monthlyAmount),
        );
        mesesLogro = ahorroMensual.toString();
        sessionStorage.setItem('MesesLogroDeAhorro', `${mesesLogro} meses`);
      }
      setForm({
        ...form,
        savingMethod: meta,
        savingTime: `${mesesLogro} meses`,
      });
      sessionStorage.setItem('TablaObjetivos', meta);
    } else if (savingWay === 'Según tus gastos' && cantidadMD > 0) {
      let meta = 0;
      let mesesLogro = '';
      if (emergingSaving === 'Sí') {
        const mes = monthsSavings.split(' ')[0];
        meta = parseFloat(mes) * convertirNum(sessionStorage.getItem('SimaEgresos'));
        const ahorroMensual = form.monthlyAmount
          ? Math.round(
            (meta - convertirNum(sessionStorage.getItem('CantidadAhorro')))
                / convertirNum(form.monthlyAmount),
          )
          : Math.round(
            (meta - convertirNum(sessionStorage.getItem('CantidadAhorro')))
                / cantidadMD,
          );
        mesesLogro = ahorroMensual.toString();
        sessionStorage.setItem('MesesLogroDeAhorro', `${mesesLogro} meses`);
      } else if (emergingSaving === 'No') {
        const mes = monthsSavings.split(' ')[0];
        meta = parseFloat(mes) * convertirNum(sessionStorage.getItem('SimaEgresos'));
        const ahorroMensual = Math.round(
          meta / convertirNum(form.monthlyAmount),
        );
        mesesLogro = ahorroMensual.toString();
        sessionStorage.setItem('MesesLogroDeAhorro', `${mesesLogro} meses`);
      }
      setForm({
        ...form,
        savingMethod: meta,
        savingTime: `${mesesLogro} meses`,
      });
      sessionStorage.setItem('TablaObjetivos', meta);
    } else if (savingWay === 'Según tu ingreso' && cantidadMD < 0) {
      let meta = 0;
      const mes = monthsSavings.split(' ')[0];
      meta = parseFloat(mes) * convertirNum(sessionStorage.getItem('SumaIngresos'));
      setForm({
        ...form,
        savingMethod: meta,
        savingTime: '0 meses',
      });
      sessionStorage.setItem('TablaObjetivos', meta);
      sessionStorage.setItem('MesesLogroDeAhorro', '0 meses');
    } else if (savingWay === 'Según tus gastos' && cantidadMD < 0) {
      let meta = 0;
      const mes = monthsSavings.split(' ')[0];
      meta = parseFloat(mes) * convertirNum(sessionStorage.getItem('SimaEgresos'));
      setForm({
        ...form,
        savingMethod: meta,
        savingTime: '0 meses',
      });
      sessionStorage.setItem('TablaObjetivos', meta);
      sessionStorage.setItem('MesesLogroDeAhorro', '0 meses');
    }
  }, [monthsSavings, form.monthlyAmount, emergingSaving, form.savingMethod]);

  const [terminaRapido, setTerminaRapido] = useState(false);

  const continueRutine = (plan) => {
    gsap.to(window, { duration: 1, scrollTo: '#TerminarRapido' });
    const click = `TerminarMasRapido_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setPlan(plan);
    setTerminaRapido(true);
    setOrderRutine(nextOrderRoutine);
  };

  const [editSavingWay, setEditSavingWay] = useState(savingWay !== '');
  const [editMonthsSavings, setEditMonthsSavings] = useState(
    monthsSavings !== '',
  );
  const [editEmergingSaving, setEditEmergingSaving] = useState(
    emergingSaving !== '',
  );

  const handleChangeIcon = (json, action) => {
    switch (action) {
      case 1:
        setEditSavingWay(false);
        console.log(editSavingWay);
        break;
      case 2:
        setEditMonthsSavings(false);
        console.log(editSavingWay);
        break;
      case 3:
        setEditEmergingSaving(false);
        console.log(editEmergingSaving);
        break;
      default:
        break;
    }
  };

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
    }, 4000);
  }, []);

  const setCtnSix = () => {
    gsap.to(window, { duration: 1, scrollTo: '#CtnSistemaAlertas' });
    const click = `ContinuarSistemaAlerta_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOrderRutine(6);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const feacturesArray = useState([
    {
      text: 'Sin riesgos',
      color: '#71a358',
    },
    {
      text: 'Disponible 24/7',
      color: '#71a358',
    },
  ]);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {!loaderWait || orderRutine !== 4 ? (
        <>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Por favor elige la forma de cómo te gustaría empezar a ahorrar"
            hora={hora}
          />

          <TrainingQuestion
            className={savingWay === '' || !editSavingWay ? '' : 'hidden'}
          >
            <QuestionsFlex display="inline">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            </QuestionsFlex>
          </TrainingQuestion>
          <div id="FormaAhorro"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                savingWay !== '' && editSavingWay && !editMonthsSavings
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 1 }, 1)}
            />
            <CardTwo
              classCard={
                savingWay !== '' && editSavingWay ? 'cardTwoResp' : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={savingWay}
              colorTraining="#7FBD62"
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={savingWay !== '' && editSavingWay ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="¿Cuántos meses de ingresos o gastos te gustaría tener ahorrado?"
        hora={hora}
      />

      <TrainingQuestion
        className={
          (monthsSavings === '' && savingWay !== '' && editSavingWay)
          || (monthsSavings === '' && !editMonthsSavings && editEmergingSaving)
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="MesesAhorro"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            monthsSavings !== '' && editMonthsSavings && !editEmergingSaving
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 1 }, 2)}
        />
        <CardTwo
          classCard={
            monthsSavings !== '' && editMonthsSavings ? 'cardTwoResp' : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={monthsSavings}
          colorTraining="#7FBD62"
        ></CardTwo>
      </DivRespDP>

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          savingWay !== '' && monthsSavings !== '' && editMonthsSavings
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text={`¿Tu ahorro actual es de ${
          sessionStorage.getItem('CantidadInvertido')
            ? sessionStorage.getItem('CantidadInvertido')
            : sessionStorage.getItem('CantidadAhorro')
              ? sessionStorage.getItem('CantidadAhorro')
              : '$___'
        }, quieres tomarlo en cuenta, para iniciar tu ahorro de emergencia?`}
        hora={hora}
      />

      <TrainingQuestion
        className={
          (emergingSaving === ''
            && monthsSavings !== ''
            && editMonthsSavings)
          || (editMonthsSavings && !editEmergingSaving)
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="AhorroActual"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            emergingSaving !== '' && editEmergingSaving && emergingSaving !== ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 1 }, 3)}
        />
        <CardTwo
          classCard={
            emergingSaving !== '' && editEmergingSaving
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={emergingSaving}
          colorTraining="#7FBD62"
        ></CardTwo>
      </DivRespDP>

      {emergingSaving !== '' && editMonthsSavings ? (
        <div className="marginComentLgSuccess1">
          <TrainingDivSection
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="100%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingQuestion>
              <TrainingDivSection
                back="transparent"
                backw="transparent"
                mlw="18%"
                paddw="0px"
                wiw="60%"
                padding="0px"
              >
                <div style={{ width: '100%' }}>
                  <Image
                    src={confetis}
                    height="238px"
                    marginLeftM="0px"
                    heightMobile="200px"
                    margin="0px"
                    top="110px"
                    left="0%"
                    mleft="0%"
                    marginLeft="0%"
                    mtop="75px"
                  />
                  <Title1
                    style={{ fontStyle: 'normal' }}
                    backgroundM="transparent"
                    pbottom="6%"
                    background="#FAFAFA"
                    position="sticky"
                    positionM="absolute"
                    mleft="auto"
                    mright="auto"
                    heightM="auto"
                    aling="center"
                    className="roboto cabin"
                    mb="30%"
                    mbM="0px"
                    left="0%"
                    size="35px"
                    mtopM="-20%"
                    mt="-147px"
                    mleftM="0px"
                    sizeM="20px"
                    widthM="100%"
                    width="68%"
                  >
                    Plan para ahorro de{' '}
                    {sessionStorage.getItem('FirstName') || 'Laura'}
                  </Title1>
                </div>
                <br />
                <TrainingDivSection
                  bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                  mt="0px"
                  mtw="-20%"
                  br="4px"
                  padding="15px"
                  wi="auto"
                  wiw="auto"
                  he="auto"
                  mb="0px"
                >
                  <form onSubmit={(e) => handleSubmit(e)} className="wo-input">
                    <label
                      style={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'cabin',
                      }}
                    >
                      Este es el plan de ahorro que tenemos para ti
                    </label>
                    <label style={styles}>
                      <b>Meta de ahorro:</b>
                    </label>
                    <p
                      style={stylesResult}
                      id="savingMethods"
                      name="savingMethod"
                      className={form.savingMethod ? 'validInputSaving' : ''}
                    >
                      {formatter.format(form.savingMethod)}
                    </p>
                    <br />
                    <label style={styles}>
                      <b>Cantidad mensual disponible para ahorro:</b>
                    </label>
                    <TextField
                      variant="outlined"
                      id="monthlyAmount"
                      name="monthlyAmount"
                      autoComplete="off"
                      required
                      fullWidth
                      inputProps={{
                        className: form.monthlyAmount
                          ? 'textInput inputs-bottom validInputSaving '
                          : 'textInput inputs-bottom',
                        style: { stylesResult },
                      }}
                      value={formatter.format(form.monthlyAmount)}
                      onChange={handleChange}
                    />
                    <br />
                    <label style={styles}>
                      <b>El tiempo para lograr tu ahorro de emergencia es:</b>
                    </label>
                    <p
                      style={stylesResult}
                      id="savingTime"
                      name="savingTime"
                      className={form.savingTime ? 'validInputSaving ' : ''}
                    >
                      {form.savingTime}
                    </p>
                  </form>
                  <div id="ComenzarPlan"></div>
                  <div id="TerminarRapido"></div>
                  <div
                    className={
                      startSaving !== '' || terminaRapido
                        ? 'hidden d-flex flex-column'
                        : 'center d-flex flex-column'
                    }
                    style={{ display: 'flex' }}
                  >
                    <div className="d-flex flex-column">
                      <CardsBtn
                        type="button"
                        min_w="100%"
                        min_web="100%"
                        mtopw="19px"
                        mtop="19px"
                        onClick={() => setStartSaving('Iniciar plan de ahorro')}
                      >
                        ¡Genial! Comenzar mi plan de ahorro
                      </CardsBtn>
                      <CardsBtn
                        onClick={() => continueRutine('Terminar rapido')}
                        type="button"
                        min_web="100%"
                        mtopw="19px"
                        mtop="19px"
                        color="#EC407A !important"
                        back="#fff !important"
                        border="solid 1px !important"
                        min_w="100%"
                      >
                        Quiero terminar más rápido
                      </CardsBtn>
                    </div>
                  </div>
                </TrainingDivSection>
              </TrainingDivSection>
            </TrainingQuestion>
          </TrainingDivSection>
        </div>
      ) : null}

      {startSaving === 'Iniciar plan de ahorro' ? (
        <div id="initPlan">
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Ahora te mostraré 3 herramientas para que puedas cumplir tu ahorro de emergencia"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="En tu perfil CORU, hay una Tabla de Control de Ahorro, que te servirá para monitorear tu avance"
            hora={hora}
          />
          <TrainingDivSection
            mtw="0px"
            mt="0px"
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="100%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingDivSection
              mtw="0px"
              mt="0px"
              back="transparent"
              backw="transparent"
              mlw="18%"
              paddw="0px"
              wiw="60%"
              padding="0px"
            >
              <Image
                src={tablaControlAhorro}
                mwidth="100%"
                margin="auto"
                top="0"
                left="10%"
                mleft="0px"
                mtop="0"
                height="600px"
                heightMobile="60%"
              />
              <div id="CtnControlAhorro"></div>
              <CardsBtn
                type="button"
                min_w="100%"
                min_web="100%"
                mtopw="0px"
                mtop="px"
                onClick={() => setContinue('continuar')}
              >
                Continuar
              </CardsBtn>
            </TrainingDivSection>
          </TrainingDivSection>
        </div>
      ) : null}
      {continues === 'continuar' ? (
        <Fragment>
          <Fragment>
            <Features
              className={
                savingWay !== '' && monthsSavings !== '' ? 'cardOne' : 'hidden'
              }
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              title=""
              textintro="También encontrarás un catálogo de opciones para guardar tu ahorro:"
              Arraytext={feacturesArray}
            />
          </Fragment>
          <TrainingDivSection
            mtw="0px"
            mt="0px"
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="100%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingDivSection
              mtw="0px"
              mt="0px"
              back="transparent"
              backw="transparent"
              mlw="18%"
              paddw="0px"
              wiw="60%"
              padding="0px"
            >
              <Image
                src={broxel}
                mwidth="auto"
                margin="auto"
                top="0px"
                left="10%"
                mleft="0px"
                mtop="0px"
                height="600px"
                heightMobile="60%"
              />
              <div id="CtnOpcionesAhorro"></div>
              <CardsBtn
                min_w="100%"
                min_web="100%"
                mtopw="0px"
                mtop="0px"
                onClick={() => setContinue2('continuar')}
              >
                Continuar
              </CardsBtn>
            </TrainingDivSection>
          </TrainingDivSection>
        </Fragment>
      ) : null}
      {continues2 === 'continuar' ? (
        <Fragment>
          <div id="continuar2"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Además, hay un Sistema de Alertas para que recuerdes que es momento de ahorrar dinero"
            hora={hora}
          />
          <TrainingDivSection
            mtw="0px"
            mt="0px"
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="100%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingDivSection
              mtw="25px"
              mt="25px"
              back="transparent"
              backw="transparent"
              mlw="18%"
              paddw="0px"
              wiw="60%"
              padding="0px"
            >
              <Image
                src={sistemaAlertas}
                mwidth="100%"
                margin="auto"
                top="0px"
                left="10%"
                mleft="0px"
                mtop="0px"
                height="600px"
                heightMobile="60%"
              />
              <div id="CtnSistemaAlertas"></div>
              <CardsBtn
                min_w="100%"
                min_web="100%"
                mtopw="0px"
                mtop="0px"
                onClick={() => setCtnSix()}
              >
                Continuar
              </CardsBtn>
            </TrainingDivSection>
          </TrainingDivSection>
        </Fragment>
      ) : null}
    </div>
  );
};

export default SavingsPlan;
