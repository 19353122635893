import React, { Fragment, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import '../assets/styles/WelcomeValidation.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import Header from '../components/common/Header';
import ImgAviso from '../assets/img/AvisoPrivacidad.svg'
import {
  Main,
  Col11,
  Container,
  ParagrapTwo,
  SubtitleOne,
  LinkList,
  A,
} from '../components/common/common.styled';
import { Helmet } from 'react-helmet';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    width: '33%',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 15,
  },
  body: {
    fontSize: 14,
    textAlign: 'left',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(Tipo_cookie, Descripción, gestionar) {
  return { Tipo_cookie, Descripción, gestionar };
}

const tecnicas = [
  createData(
    'Sesión',
    'Las cookies de sesión se utilizan para mantener el estado de la aplicación',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Equilibrio de carga',
    'Las cookies de equilibrio de carga se utilizan para distribuir los activos por todo el mundo y aligerar la carga del servidor.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Identificación de usuario',
    'Las cookies de identificación de usuario se utilizan para garantizar que los usuarios solo vean sus propios datos.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Seguridad',
    'Las cookies de seguridad se utilizan para realizar controles y comprobaciones de seguridad.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
];

const personalisadas = [
  createData(
    'Idioma',
    'Las cookies de idioma se utilizan para almacenar el idioma que ha seleccionado el usuario y mostrar las opciones correctas.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Localización',
    'La dirección aproximada del usuario (ciudad, estado, país, código postal), determinada por la dirección IP, se almacena para seleccionar automáticamente el país correcto y mostrar los puntos de venta y días programados para la realización de presentaciones dentro del área correspondiente.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Móviles',
    'Si el usuario accede a la Página a través de un dispositivo móvil, se instala una cookie para visualizar la página web principal (es decir, cuando el dispositivo es compatible con tecnología Flash) o la página móvil sin tecnología Flash.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Vídeo visto más recientemente',
    'La fecha y el título del vídeo visto más recientemente se registran para poder entender mejor las preferencias del usuario.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Cookies Flash',
    'Las cookies Flash se utilizan para permitir la reproducción de contenidos audiovisuales.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Historial de visitas',
    'Las cookies de historial de visitas se utilizan para hacer un seguimiento de las secciones que el usuario ha visitado en la Página. Si el usuario obtiene un mensaje de error mientras visita la Página, los datos de la cookie se guardan en un archivo de registro para la comunicación y resolución de errores.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
];

const social = [
  createData(
    'Facebook',
    'Las cookies se utilizan para hacer un seguimiento de personas que son usuarios [o no] de Facebook a los efectos del análisis de datos de estudios de mercado y del desarrollo de productos.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Twitter',
    'Las cookies se utilizan para hacer un seguimiento de personas que son usuarios [o no] de Twitter para efectos de análisis de datos de estudios de mercado y del desarrollo de productos.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
];

const analiticas = [
  createData(
    'Google Analytics',
    'Las cookies analíticas de Google Analytics recogen datos estadísticos consolidados para mejorar la presentación y navegación de la Página. Google complementa los datos consolidados con información demográfica y de interés, para que podamos entender mejor a nuestros visitantes.',
    'https://tools.google.com/dlpage/gaoptout',
  ),
  createData(
    'Adobe Omniture',
    'Las cookies analíticas de Adobe Omniture recogen datos estadísticos consolidados para mejorar la presentación y navegación de la Página.',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
];

const publicitarias = [
  createData(
    'Publicidad',
    'Entregar publicidad segmentada u orientada en función del comportamiento',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Estudios de mercado',
    'Realizar estudios de mercado',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Campaña / promoción',
    'Medir la eficacia de una campaña',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
  createData(
    'Detección del fraude',
    'Detectar el fraude por Internet',
    'Aceptar/rechazar a través de los ajustes del navegador',
  ),
];

const useStyles = makeStyles({
  table: {
    overflowX: 'auto',
  },
});

function Privacy_notice() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    if (sessionStorage.getItem('goToCookies')) {
      gsap.to(window, { duration: 1, scrollTo: '#PoliticaDeCookies' });
      sessionStorage.removeItem('goToCookies');
    }
  }, []);

  useEffect(() => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  });
  const classes = useStyles();

  const TableTecnicas = (data) => (
    <Fragment>
      <Helmet>
        <title>Aviso de privacidad | CORU</title>
      </Helmet>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tipo de cookie</StyledTableCell>
              <StyledTableCell align="right">Descripción</StyledTableCell>
              <StyledTableCell align="right">
                Cómo gestionar las cookies
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data === 'tecnicas' ? (
              <Fragment>
                {tecnicas.map((row) => (
                  <StyledTableRow key={row.Tipo_cookie}>
                    <StyledTableCell component="th" scope="row">
                      {row.Tipo_cookie}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Descripción}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gestionar}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </Fragment>
            ) : data === 'personalisadas' ? (
              <Fragment>
                {personalisadas.map((row) => (
                  <StyledTableRow key={row.Tipo_cookie}>
                    <StyledTableCell component="th" scope="row">
                      {row.Tipo_cookie}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Descripción}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gestionar}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </Fragment>
            ) : data === 'social' ? (
              <Fragment>
                {social.map((row) => (
                  <StyledTableRow key={row.Tipo_cookie}>
                    <StyledTableCell component="th" scope="row">
                      {row.Tipo_cookie}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Descripción}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gestionar}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </Fragment>
            ) : data === 'analiticas' ? (
              <Fragment>
                {analiticas.map((row) => (
                  <StyledTableRow key={row.Tipo_cookie}>
                    <StyledTableCell component="th" scope="row">
                      {row.Tipo_cookie}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Descripción}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gestionar}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {publicitarias.map((row) => (
                  <StyledTableRow key={row.Tipo_cookie}>
                    <StyledTableCell component="th" scope="row">
                      {row.Tipo_cookie}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Descripción}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gestionar}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
  return (
    <Fragment>
      <Header pathname={window.location.pathname} />
      <Main
        className="container"
        background="#fff"
        pb="0%"
        overflow=""
        padding="15px"
        margintop="2%"
      >
        <img src={ImgAviso} alt="Aviso"  width="100%"/>
        <Container width="80%" mt="0rem" widthMobile="100%" mb="15%">
          <Col11 mtM="35px!important">
            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="30px"
              textalingn="left"
              textalingnMobile="left"
            >
              Aviso de privacidad
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Coru.com es propiedad de y es operado por Comparaguru México S.A. de C.V. El siguiente Aviso de
              privacidad protege tu información personal de acuerdo con la Ley Federal de Protección de Datos en
              Posesión de los Particulares. Este Aviso de privacidad explica cómo recolectamos y usamos algunos
              de tus datos personales, laborales, financieros y patrimoniales, así como las medidas de control y
              prevención tomadas para asegurar que tu información esté segura y permanezca confidencial.
            </ParagrapTwo>
            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Responsable
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              En cumplimiento con la Ley Federal de Protección de Datos 
              Personales en Posesión de los Particulares, la sociedad Comparaguru 
              México, S.A de C.V., (en adelante "Comparaguru" o "Coru" o
              "Coru.com") te informa que Coru.com es responsable de la 
              recolección, utilización, almacenamiento, comunicación y 
              protección de tus datos personales. El "Usuario" podrá contactar a 
              Coru.com en cualquier momento a través del correo electrónico 
              arco@coru.com, para el ejercicio de sus derechos ARCO. A través de 
              mecanismos administrativos, técnicos y físicos, como encriptación 
              de datos o uso de contraseñas para acceder a información 
              confidencial, entre otras, Coru.com protege y salvaguarda tus 
              datos personales financieros y patrimoniales para evitar el daño, 
              pérdida, destrucción, robo, extravío, alteración o tratamiento no 
              autorizado de los mismos.

            </ParagrapTwo>
            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Información personal
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              La información personal solicitada al usuario está compuesta por
              los siguientes rubros: nombre(s), apellido(s), fecha de
              nacimiento, sexo, correo electrónico, teléfono fijo, teléfono
              celular, domicilio, estado, delegación o municipio, calle, número
              exterior e interior, país, ingreso mensual, información
              patrimonial, referencias personales, datos médicos y valor del
              inmueble sujeto a garantía hipotecaria. Tus datos personales serán
              tratados con base en los principios de licitud, consentimiento,
              información, calidad, finalidad, lealtad, proporcionalidad y
              responsabilidad de la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares. ComparaGuru mantendrá
              la confidencialidad de tus datos personales estableciendo y
              manteniendo de forma efectiva, mediante medidas de seguridad
              administrativas, técnicas y físicas como encriptación de datos y
              uso de contraseñas, entre otras, para evitar su daño, pérdida,
              alteración, destrucción, uso, acceso o divulgación indebida. La
              información deberá ser veraz y completa. El usuario responderá en
              todo momento por los datos proporcionados y en ningún caso
              ComparaGuru será responsable de la veracidad de los mismos.
            </ParagrapTwo>
            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Uso de los datos personales
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="3%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              En caso de que aceptes los términos de este Aviso de Privacidad,
              tus datos personales serán usados para:
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              <ul className="padding16">
                <li>
                  Prestar el servicio de asesoría, comparación, simulación y cálculo
                  de cotizaciones de productos financieros, de seguros y de inmuebles. 
                  Como parte de la prestación del servicio, Comparaguru transferirá 
                  tus datos personales a instituciones bancarias o entidades financieras 
                  generales, aseguradoras, brokers hipotecarios y/o inmobiliarios, 
                  desarrolladores inmobiliarios, entidades comerciales que otorgan crédito, 
                  apps de manejo de dinero y entidades de investigación crediticia, con las 
                  que Comparaguru tenga una relación jurídica celebrada, con la finalidad de
                  aplicar a sus propios procesos de solicitud de cotización de productos 
                  financieros, de seguros y de inmuebles, para poder ofrecerte el producto 
                  que se considere adecuado a tus necesidades.
                </li>
                <li>
                  El enlace con instituciones bancarias o entidades financieras 
                  generales, aseguradoras, brokers hipotecarios y/o 
                  inmobiliarios, desarrolladores inmobiliarios, sociedades 
                  promotoras de inversión y entidades de investigación crediticia.
                </li>
                <li>De igual manera estos datos serán utilizados para:</li>
                  <ul>
                    <li>Procurar un servicio eficiente.</li>
                    <li>
                      Informarte sobre cambios o nuevos servicios que estén
                      relacionados con el producto o servicio solicitado.
                    </li>
                    <li>
                      Dar cumplimiento a obligaciones contraídas con los usuarios.
                    </li>
                    <li>Evaluar la calidad de nuestro servicio.</li>
                    <li>
                      Enviar mensajes promocionales por correo electrónico a los
                      Usuarios, relacionados con los productos y servicios ofrecidos
                      por CORU.
                    </li>
                    <li>
                      Contactar a los Usuarios por cualquier medio como
                      sus números telefónicos, vía mensaje de texto, cualquier medio
                      mensajería instantánea, correo electrónico entre otros a
                      efecto de brindarles asistencia y orientación para la
                      realización de solicitudes de crédito y de cualesquiera otros
                      servicios y/o procedimientos disponibles para los Usuarios de
                      CORU.
                    </li>
                  </ul>
              </ul>
            </ParagrapTwo>

            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Consulta de datos personales
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="3%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Como parte de los términos y condiciones, el usuario autoriza por
              única ocasión a Comparaguru México SA de CV y terceros a
              consultar sus antecedentes crediticios ante las Sociedades de
              Información Crediticia que estime conveniente, declarando que
              conoce la naturaleza, alcance y uso que Comparaguru México SA de
              CV hará de tal información.
              <br /><br />
              Entiéndase por terceros a los bancos, entidades financieras o aliados:
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              <ul className="padding16">
                <li>A55 TECHNOLOGY, S.A. de C.V.</li>
                <li>Amex American Express Company (México), S.A. de C.V.</li>
                <li>ANGELES EN ARK S.A DE C.V</li>
                <li>AXA Seguros, S.A. de C.V</li>
                <li>Banco Azteca, S.A., Institución de Banca Múltiple</li>
                <li>Banco Mercantil del Norte, S.A., Institución de Banca Múltiple, Grupo Financiero Banorte</li>
                <li>Banco Santander (México), S.A., Institución de Banca Múltiple, Grupo Financiero Santander México</li>
                <li>BBVA Bancomer, S.A., Institución de Banca Múltiple, Grupo Financiero BBVA Bancomer</li>
                <li>CAPITALTECH FINANCIAL S.A.P.I. DE C.V. SOFOM E.N.R.</li>
                <li>Citibanamex Banco Nacional de México, S.A., Institución de Banca Múltiple, Grupo Financiero Banamex</li>
                <li>COPPEL, S.A. DE C.V.</li>
                <li>Creze, PRESTADORA DE SERVICIOS CICLOMART, S.A.P.I. de C.V., SOFOM E.N.R.</li>
                <li>FINANCIERA REALIDAD S.A DE C.V S.F.O.M ENR</li>
                <li>GEIA SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE (BAUBAP)</li>
                <li>GRUPO DB MENOS, S.A.P.I. DE C.V.</li>
                <li>ION FINANCIERA, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE, SOCIEDAD FINANCIERA DE OBJETO MÚLTIPLE,</li>
                <li>ISON MEXICO S.A DE C.V</li>
                <li>La Tasa, FMG SERVICIOS TECNICOS Y ESPECIALIZADOS SOCIEDAD ANONIMA DE CAPITAL VARIABLE</li>
                <li>Libere, Reparadora Nacional de Deuda S.A.P.I. de C.V.</li>
                <li>Mi Stori S.A. de C.V., SOFOM E.N.R.</li>
                <li>NU BN SERVICIOS MÉXICO, S.A. DE C.V.</li>
                <li>PRETMEX S.A. DE C.V., SOFOM, E.N.R.</li>
                <li>TALA MOBILE, S.A.P.I. DE C.V.,</li>
                <li>TARJETAS DEL FUTURO SAPI DE C.V (RAPPI)</li>
                <li>Vexi, GRIBMA de SAPI de CV (VEXI)</li>
              </ul>
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Los Usuarios podrán enviar cualquier comentario o sugerencia a
              Comparaguru México, S.A. de C.V., a través del portal
              <a href='http://coru.com/expertofinanciero'> http://coru.com/expertofinanciero </a> y/o al correo electrónico: <span style={{ color: '#007bff' }}> servicio@coru.com </span>
            </ParagrapTwo>
            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Limitación de usos y divulgación de los datos personales
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="3%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              En nuestro programa de notificación de promociones, boletín de
              noticias, blog, ofertas y servicios a través de correo
              electrónico, solo ComparaGuru tiene acceso a la información
              recabada. Este tipo de publicidad y comunicación se realiza
              mediante avisos y mensajes promocionales de correo electrónico,
              redes sociales y/o telefonía móvil, los cuales solo serán enviados
              a ti y a los contactos registrados para tal propósito. La
              suscripción a este servicio podrá modificarse en cualquier momento
              escribiendo a arco@coru.com. En los correos electrónicos enviados
              pueden incluirse, ocasionalmente, ofertas de terceras partes que
              sean nuestros socios comerciales.
            </ParagrapTwo>

            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              ¿Qué son las cookies y cómo se utilizan?
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="0%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Las cookies son pequeñas piezas de información que son enviadas
              por el sitio web a tu navegador y se almacenan en el disco duro de
              tu equipo. Se utilizan para determinar tus preferencias cuando te
              conectas a los servicios de nuestros sitios, así como para
              rastrear determinados comportamientos o actividades llevadas a
              cabo por ti dentro del portal.
              <br /> <br/>
              Existen secciones de la página web
              en las que requerimos que el usuario tenga habilitadas las
              cookies, ya que algunas de las funcionalidades del sitio web las
              necesitan para trabajar. Las cookies nos permiten:
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              <ul className="padding16">
                <li>
                  Reconocerte al momento de entrar a nuestros sitios y ofrecerte
                  una experiencia personalizada.
                </li>
                <li>
                  Conocer la configuración personal del sitio especificada por
                  ti; por ejemplo, las cookies nos permiten detectar el ancho de
                  banda que has seleccionado al momento de ingresar a la página
                  de inicio de nuestros sitios, así sabemos qué tipo de
                  información es aconsejable descargar.
                </li>
                <li>
                  Calcular el tamaño de nuestra audiencia y medir algunos
                  parámetros de tráfico. Cada navegador que obtiene acceso a
                  nuestros sitios adquiere una cookie que se usa para determinar
                  la frecuencia de uso y las secciones de los sitios visitadas,
                  reflejando así hábitos y preferencias, información que nos es
                  útil para mejorar el contenido, los titulares y las
                  promociones para los usuarios.
                </li>
              </ul>
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="0%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              Las cookies también nos ayudan a rastrear algunas actividades. Por
              ejemplo, en algunas de las encuestas que lanzamos en línea podemos
              utilizar cookies para detectar si el usuario ya ha llenado la
              encuesta y evitar desplegarla nuevamente. Las cookies te
              permitirán aprovechar los servicios que te ofrecemos, por lo que
              te recomendamos mantenerlas activadas.
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="0%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              Las cookies no serán utilizadas para identificar a los usuarios,
              con excepción de los casos en que se investiguen posibles
              actividades fraudulentas. En el caso de que sea necesaria la
              utilización de cookies, el botón de “ayuda” que se encuentra en la
              barra de herramientas de la mayoría de los navegadores te dirá
              cómo aceptar nuevas cookies, cómo hacer que el navegador te
              notifique cuando recibes una nueva cookie o cómo deshabilitar
              todas las cookies.
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              La sociedad Comparaguru México, S.A. de C.V., titular del portal
              https://coru.com almacena automáticamente los datos del usuario, por
              ejemplo, cuando se registra en el portal o cuando contacta a la
              empresa ya sea personalmente o respondiendo a preguntas por
              correo, redes sociales, fax, a través del portal o por teléfono.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              En cumplimiento de lo dispuesto por los artículos 15, 16, 17, 18 y
              19 de la Ley Federal de Protección de Datos Personales en Posesión
              de Particulares, la sociedad Comparaguru México, S.A. de C.V.,
              titular del portal <a href='http://coru.com/expertofinanciero'> http://coru.com/expertofinanciero </a> ha designado a un Oficial de Datos
              como encargado del Departamento de Servicios y Protección de Datos
              Personales. Para comunicarte con el Oficial de Datos escribe un
              correo electrónico a <span className="pink">arco@coru.com</span>
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Para ejercer derechos ARCO y revocar consentimiento otorgado
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Como titular de sus datos personales, el usuario podrá ejercitar
              los derechos ARCO (Acceso, Rectificación, Cancelación y Oposición
              al tratamiento de sus datos personales), o bien, revocar el
              consentimiento que haya otorgado a ComparaGuru para el tratamiento
              de sus datos, enviando directamente su solicitud al Oficial de
              Datos, encargado del Departamento de Servicio y Protección de
              Datos Personales. Para ejercer sus derechos ARCO, el usuario
              deberá enviar un correo electrónico a{' '}
              <span className="pink">arco@coru.com</span>
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Dicha solicitud deberá contener por lo menos: (a) nombre y
              domicilio u otro medio para comunicar la respuesta a tu solicitud;
              (b) los documentos que acrediten identidad o, en su caso,
              representación legal; (c) la descripción clara y precisa de los
              datos personales respecto de los que se solicita ejercer alguno de
              los derechos ARCO, (d) la manifestación expresa para revocar el
              consentimiento del tratamiento de tus datos personales y por
              tanto, darlos de baja para que no se usen; (e) cualquier otro
              elemento que facilite la localización de los datos personales.{' '}
              <br />{' '}
              
               <A target="_blank" href="https://drive.google.com/file/d/191jx00Ym9-fN7rxWjZBoIdPlUF3Wy7lk/view">Consulta aquí</A>  un Formato básico de solicitud de derechos ARCO
          
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              La petición deberá ir acompañada de los fundamentos por los que se
              solicita dicha revocación y una identificación oficial del titular
              de los datos o de su apoderado. En un plazo máximo de 20 (veinte)
              días hábiles atenderemos la solicitud e informaremos sobre la
              procedencia de la misma a través del correo electrónico del que
              provenga la petición. ComparaGuru solicita al usuario que
              actualice sus datos cada vez que estos sean modificados, ya que
              esto permitirá brindarle un servicio eficiente y personalizado.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Transferencia de Información con Terceros
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Los datos solicitados son indispensables para que ComparaGuru te
              proporcione servicio, por lo que de no contar con los mismos, la
              sociedad se encuentra materialmente imposibilitada de cumplir el
              fin principal de la relación con sus usuarios.
              <br /><br />
              Como parte de la prestación del servicio, Comparaguru transferirá
              tus datos personales a instituciones bancarias o entidades
              financieras generales, aseguradoras, brókers hipotecarios y/o
              inmobiliarios, desarrolladores inmobiliarios, entidades
              comerciales que otorgan crédito y entidades de investigación
              crediticia, con la finalidad de aplicar a sus propios procesos de
              solicitud de cotización de productos financieros, de seguros y de
              inmuebles, para ofrecerte el producto que se considere adecuado a
              tus necesidades.
              <br />
              ComparaGuru únicamente realiza transferencia de datos para cumplir
              con las obligaciones contraídas con los clientes o usuarios,
              permitiendo el acceso a la información a:
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              <ul className="padding16">
                <li>
                  Compañías asociadas que tengan una relación comercial con
                  ComparaGuru, así como sucursales de ComparaGuru.
                </li>
                <li>
                  Instituciones bancarias o entidades financieras generales,
                  aseguradoras, brókers hipotecarios y/o inmobiliarios,
                  desarrolladores inmobiliarios y entidades de investigación
                  crediticia. Terceros cuyos productos o servicios aparezcan en
                  nuestro sitio web y otorguen un servicio/producto solicitado
                  por el usuario. Estos terceros tienen un control parcial de
                  estos datos. Es importante que leas sus avisos de privacidad o
                  los contactes directamente para información adicional.
                </li>
                <li>
                  Agencias de interferencia de fraudes para detectar fraudes y
                  lavado de dinero, en caso de que información falsa o poco
                  precisa haya sido otorgada.
                </li>
              </ul>
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Como parte de este aviso de privacidad, el usuario también
              reconoce y acepta las políticas de privacidad de terceros, con
              quienes se comparten sus datos a fin de brindarle exclusivamente
              el servicio, información y/o producto que solicita. Para
              referencia, aquí se muestran el aviso de privacidad de los
              terceros que colaboran con Coru.
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="0%"
            >
              <ul className="padding16">
                <li>
                  <LinkList
                    target="_blank"
                    href="https://a55-mx-cdn.s3.amazonaws.com/Aviso+Privacidad.pdf"
                    className="linklist"
                  >
                    A55
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.americanexpress.com/mx/privacy-statement.html?inav=mx_legalfooter_conmxd"
                    className="linklist"
                  >
                    America Express
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://axa.mx/anexos/aviso-de-privacidad"
                    className="linklist"
                  >
                    AXA Seguros
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.actinver.com/web/actinver/avisos-de-privacidad"
                    className="linklist"
                  >
                    BANCO ACTINVER
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.bancoazteca.com.mx/content/dam/azteca/docs/footer/aviso-de-privacidad/200817/aviso%20privacidad%20integral%20sitio%20web%20baz.pdf"
                    className="linklist"
                  >
                    BANCO AZTECA
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.banorte.com/wps/portal/gfb?uri=nm:oid:banorte-te-informa.aviso-de-privacidad"
                    className="linklist"
                  >
                    BANORTE
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.santander.com.mx/PDF/personas/AvisoPrivacidad.pdf"
                    className="linklist"
                  >
                    SANTANDER
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.bbva.mx/personas/aviso-de-privacidad.html#bbva"
                    className="linklist"
                  >
                    BBVA
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.banamex.com/es/privacidad_portal.htm"
                    className="linklist"
                  >
                    BANAMEX
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.capitaltech.com/aviso-privacidad"
                    className="linklist"
                  >
                    CAPITALTECH
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.coppel.com/aviso-de-privacidad"
                    className= "linklist"
                  >
                    COPPEL
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://creze.com/AvisoDePrivacidad.aspx"
                    className="linklist"
                  >
                    CREZE
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf"
                    className="linklist"
                  >
                    GRUPO DB MENOS
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://insurama.mx/privacidad/"
                    className="linklist"
                  >
                    INSURAMA
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.ion.com.mx/aviso-privacidad/"
                    className="linklist"
                  >
                    ION
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://assets.website-files.com/5fabd9c2d2a76b9729221ad7/620d3aeeca180f87068daf69_Privacy%20Notice%20-%20Klar%20Cr%C3%A9dito.pdf"
                    className="linklist"
                  >
                    KLAR
                  </LinkList>
                </li>
                {/* <li>
                  <LinkList
                    target="_blank"
                    href="https://vexi.mx/avisodeprivacidad"
                    className="linklist"
                  >
                    GRIBMA
                  </LinkList>
                </li>
                */}
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.hsbc.com.mx/content/dam/hsbc/mx/documents/aviso_privacidad.pdf"
                    className="linklist"
                  >
                    HSBC Mexico
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.scotiabank.com.mx/transparencia/aviso-privacidad.aspx"
                    className="linklist"
                  >
                    SCOTIABANK
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://zenda.la/privacy-policy/"
                    className="linklist"
                  >
                    ZEDALA
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.home.independencia.com.mx/assets/aviso_de_privacidad.pdf"
                    className="linklist"
                  >
                    FINANCIERA INDEPENDIENCIA{' '}
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://finreal.com.mx/aviso-privacidad.html"
                    className="linklist"
                  >
                    FINANCIERA REALIDAD{' '}
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://pagaya.mx/"
                    className="linklist"
                  >
                    ISON MEXICO{' '}
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.storicard.com/aviso-de-privacidad"
                    className="linklist"
                  >
                    Mi Stori{' '}
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://smartlending.mx/aviso-de-privacidad"
                    className="linklist"
                  >
                    SMART LENDIG{' '}
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://vexi.mx/aviso-privacidad/"
                    className="linklist"
                  >
                    VEXI
                  </LinkList>
                </li>
                <li>
                  <LinkList
                    target="_blank"
                    href="https://www.pretmex.com/aviso-privacidad.pdf"
                    className="linklist"
                  >
                    PRETMEX
                  </LinkList>
                </li>
                {/* <li>
                                    <LinkList href="https://legal.rappi.com/mexico/terminos-y-condiciones-de-uso-de-plataforma-rappi-3/" className="linklist">RAPPI</LinkList>
                                </li> */}
              </ul>
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Protección
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Comparaguru, S.A. de C.V., titular del portal <a href='http://coru.com/expertofinanciero'> http://coru.com/expertofinanciero </a> ha
              adoptado medidas de seguridad administrativas, técnicas y físicas
              como encriptación de datos o uso de contraseñas para acceder a
              información confidencial, entre otras, que permiten proteger los
              datos personales que sus usuarios proporcionan contra daño,
              pérdida, alteración, destrucción o su uso, acceso y/o tratamiento
              no autorizado. Asimismo, nuestro personal está debidamente
              capacitado para tratar los datos personales en el mayor marco de
              confidencialidad, privacidad, secrecía y absoluto cumplimiento de
              la Ley Federal de Protección de Datos Personales en Posesión de
              Particulares.
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Queda expresamente señalado que el servicio de asesoría,
              comparación, simulación, cálculo y cotización de productos
              financieros y seguros que presta Comparaguru México, S.A. de C.V.
              a través de su portal,  <a href='http://coru.com/expertofinanciero'> http://coru.com/expertofinanciero </a>, lo hace de forma estimada con
              base en la información publicada por las instituciones financieras
              y de seguros, por lo que se pueden presentar cambios sin previo
              aviso. Comparaguru México, S.A. de C.V. no es responsable de
              cerciorarse o comprobar la veracidad de la información
              proporcionada por las diversas instituciones financieras y de
              seguros, así como por instituciones gubernamentales; tampoco es
              responsable de asegurarse del cumplimiento de estas últimas de los
              ofrecimientos presentados en sus cotizaciones. Los resultados que
              su comparador arroja son para fines meramente ilustrativos.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Fecha de la última actualización
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              El Aviso de privacidad fue modificado el 15 de Enero de 2024.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Autoridad
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Si el usuario considera que han sido vulnerados sus derechos
              respecto de la protección de datos personales, tiene la facultad
              de acudir a la autoridad correspondiente para defender su
              ejercicio. La autoridad es el Instituto Nacional de Transparencia,
              Acceso a la Información y Protección de Datos Personales (INAI),
              su sitio web es: <LinkList>https://home.inai.org.mx/</LinkList>
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Aceptación de los términos
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Este Aviso de privacidad está sujeto a los términos y condiciones
              del sitio web <a href='http://coru.com/expertofinanciero'> http://coru.com/expertofinanciero</a>
              , propiedad de ComparaGuru como fue
              antes descrito, lo cual constituye un acuerdo legal entre el
              usuario y Comparaguru México S.A. de C.V. <br />
              Si el usuario utiliza los servicios en el sitio web
              <a href='http://coru.com/expertofinanciero'> http://coru.com/expertofinanciero </a>
              significa que ha leído, entendido, acordado y manifestado la
              voluntad de apegarse a los términos y condiciones expuestos en el
              Aviso de privacidad y en el aviso legal.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Mejorar nuestros servicios
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Para asegurarnos de que los servicios que ofrecemos cumplen con
              las necesidades de nuestro público, ComparaGuru podría llegar a
              solicitar retroalimentación. Cualquier comentario al servicio
              brindado que nos proporciones será utilizada únicamente para
              mejorar nuestro trabajo y no será revelada en el sitio web de la
              empresa.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Seguridad
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              La seguridad de tu información es muy importante para nosotros.
              Nuestro sitio web está respaldado por “Site Lock Secure”,
              ofreciendo un alto grado de protección. Cabe mencionar que no toda
              transmisión de datos a través de internet es 100% segura.
              Información altamente sensible, como los detalles de tu tarjeta de
              crédito, es encriptada para minimizar los peligros de intercepción
              durante el tránsito. Mientras que nosotros hacemos todo lo posible
              para proteger los datos personales que se nos proporcionan, estos
              se nos otorgan con plena consciencia de los riesgos anteriores.
              Los Términos y Condiciones explican, adicionalmente, tu obligación
              de mantener seguro cualquier nombre de usuario y contraseña que
              utilices para registrarte a cualquiera de nuestros servicios.
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Responsabilidad
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Puede llegar a solicitarse un proceso de registro como parte del
              uso de esta plataforma. El proceso puede incluir la creación de un
              nombre de usuario y una contraseña. Es de suma importancia que no
              compartas tu nombre de usuario y contraseña con nadie. Si decides
              compartir tu nombre de usuario y contraseña con cualquier persona,
              solo tú eres responsable de todas las actividades llevadas a cabo
              en este sitio Web bajo tu nombre de usuario y contraseña.
            </ParagrapTwo>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              El material mostrado en esta plataforma se presenta únicamente con
              carácter informativo. La información no deberá interpretarse como
              una solicitud o una oferta de compra o venta de algún instrumento
              o valor, y no pretende ser bajo ningún escenario una asesoría. No
              se hace ninguna declaración, ni ofrece garantía alguna, ya sea
              explícita o implícita, sobre la exactitud, confiabilidad o
              integridad de la información. Los destinatarios de este material
              no deberán considerarlo como sustituto de su propio juicio u
              opinión. Cualquier opinión expresada en este documento está sujeta
              a cambios sin previo aviso.
            </ParagrapTwo>
            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Sobre este aviso, contáctanos
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Si tienes alguna pregunta o comentario acerca de este Aviso de
              privacidad, contáctanos a través de:{' '}
              <LinkList>arco@coru.com</LinkList>
            </ParagrapTwo>

            <SubtitleOne
              mt="5%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              Darse de baja de la lista de contactos
            </SubtitleOne>
            <ParagrapTwo
              mtWeb="1.5rem"
              mb="5%"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mt="7%"
            >
              Puedes darte de baja de nuestros correos electrónicos
              promocionales, a través del enlace "Darse de baja" que aparece en
              cada correo electrónico sin costo, excepto los costos de
              transmisión. <br />
              El usuario podrá siempre almacenar y/o imprimir este Aviso de
              privacidad.
            </ParagrapTwo>

            <SubtitleOne
              mt="3%"
              id="PoliticaDeCookies"
              size="30px"
              textalingn="left"
              textalingnMobile="left"
            >
              Política de Cookies
            </SubtitleOne>
            <SubtitleOne
              mt="3%"
              id="idTitle"
              size="20px"
              textalingn="left"
              textalingnMobile="left"
            >
              <ul className="padding16 typeList">
                <li>
                  ¿Qué es una cookie? <br />
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Las cookies son pequeños archivos de texto o datos que se
                    almacenan en su ordenador o dispositivo móvil (como, por
                    ejemplo, un smartphone o tablet) cuando usted visita
                    nuestras Páginas. Una cookie suele contener el nombre de la
                    página web de la que procede, la "vida" de la cookie (esto
                    es, el periodo de tiempo que la cookie permanecerá en su
                    dispositivo) y un valor, que suele ser un número único
                    generado aleatoriamente
                  </ParagrapTwo>
                </li>
                <li>
                  ¿Para qué empleamos las cookies? <br />
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Empleamos las cookies para facilitar la utilización de
                    nuestras Páginas y adaptar mejor las Páginas y nuestros
                    productos a sus intereses y necesidades. Con las cookies
                    esto es posible porque las páginas web pueden leer y
                    escribir estos archivos, permitiéndoles reconocerle y
                    recordar información importante que hará más cómoda su
                    navegación por una página web (por ejemplo, recordando sus
                    ajustes preferidos).
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Las cookies también se pueden utilizar para ayudarle a
                    agilizar en el futuro su navegación y su experiencia en
                    nuestras Páginas. También empleamos las cookies para
                    recopilar datos estadísticos consolidados y disociados que
                    nos permiten entender cómo los usuarios utilizan nuestras
                    Páginas y para mejorar la estructura y los contenidos de
                    nuestras Páginas. No podremos identificarle personalmente a
                    través de estos datos.
                  </ParagrapTwo>
                </li>
                <li>
                  ¿Qué tipos de cookies utilizamos?
                  <br />
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    En las Páginas pueden utilizarse dos tipo de cookies: las
                    denominadas "cookies de sesión" y "cookies persistentes".
                    Las cookies de sesión son cookies que permanecen en su
                    dispositivo hasta que usted abandona la Página. Una cookie
                    persistente permanece en su dispositivo mucho más tiempo o
                    hasta que usted la elimina manualmente (el tiempo que la
                    cookie permanezca en su dispositivo dependerá de la duración
                    o "vida" de la cookie en cuestión).
                  </ParagrapTwo>
                  <ParagrapTwo
                    fontWeight="bold"
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Cookies técnicas
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Las cookies técnicas son necesarias para que la Página
                    funcione y usted pueda navegar por ella y utilizar sus
                    servicios y funciones. Sin estas cookies absolutamente
                    necesarias, la Página no funcionará con la fluidez prevista
                    y es posible que no podamos ofrecerle la Página o
                    determinados servicios y funciones que usted nos solicite.
                  </ParagrapTwo>
                  <TableTecnicas datas="tecnicas" />
                  <ParagrapTwo
                    fontWeight="bold"
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Cookies de personalización
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Las cookies de personalización recogen datos acerca de las
                    opciones elegidas por usted y sus preferencias y nos
                    permiten recordar el idioma u otros ajustes locales y
                    personalizar la Página de acuerdo con sus necesidades.
                  </ParagrapTwo>
                  <TableTecnicas datas="personalisadas" />
                  <ParagrapTwo
                    fontWeight="bold"
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Cookies de seguimiento con plug-in social
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Estas cookies se utilizan para hacer un seguimiento de
                    personas que son usuarios [o no] de redes sociales para el
                    análisis de datos de estudios de mercado y el desarrollo de
                    productos.
                  </ParagrapTwo>
                  <TableTecnicas datas="social" />
                  <ParagrapTwo
                    fontWeight="bold"
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Cookies analíticas
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Las cookies analíticas recogen datos acerca de la
                    utilización de la Página por su parte y nos permiten mejorar
                    su funcionamiento. Por ejemplo, las cookies analíticas nos
                    muestran cuáles son las secciones más visitadas de la
                    Página, nos ayudan a llevar un registro de las dificultades
                    que usted experimenta con la Página y nos indican si nuestra
                    publicidad es o no eficaz. Todo ello nos permite conocer las
                    pautas de utilización de la Página en general y no
                    limitándonos a un único usuario
                  </ParagrapTwo>
                  <TableTecnicas datas="analiticas" />
                  <ParagrapTwo
                    fontWeight="bold"
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Cookies publicitarias
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Las cookies publicitarias se utilizan con fines de gestión
                    de los espacios publicitarios.
                  </ParagrapTwo>
                  <TableTecnicas datas="publicitarias" />
                  <br />
                </li>
                <li>
                  ¿Cómo gestiono las cookies? <br />
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Aunque la mayoría de los navegadores de Internet están
                    configurados por defecto para aceptar automáticamente las
                    cookies, también le permiten, en casi todos los casos,
                    cambiar los ajustes para bloquear las cookies o alertarle
                    cuando se envíen cookies a su dispositivo.
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Además de las opciones indicadas anteriormente, usted podrá
                    rechazar, aceptar o eliminar cookies de la Página en
                    cualquier momento activando o accediendo al ajuste
                    correspondiente de su navegador. El procedimiento que debe
                    seguir para permitir, deshabilitar o eliminar las cookies se
                    describe en la página web del proveedor de su navegador, a
                    la que puede acceder a través de la pantalla de ayuda.
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Tenga en cuenta que si se deshabilitan o eliminan las
                    cookies, es posible que las características de la Página no
                    funcionen de la forma prevista. Por ejemplo, usted no podrá
                    acceder a determinadas áreas de nuestra Página o recibir
                    información personalizada cuando visite la Página
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Si utiliza diferentes dispositivos para ver y acceder a las
                    Páginas (por ejemplo, su ordenador, smartphone, tablet,
                    etc.), deberá asegurarse de configurar el navegador de cada
                    dispositivo de acuerdo con sus preferencias en lo que
                    respecta a las cookies.
                  </ParagrapTwo>
                </li>
                <li>
                  Pixel Tags <br />
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Algunas de las secciones que usted visite en la Página
                    pueden recoger también datos mediante la utilización de
                    pixel tags (también denominados clear gifs) que podrán ser
                    compartidos con terceros que apoyen directamente nuestras
                    actividades promocionales y el desarrollo de nuestras
                    Páginas. Por ejemplo, podremos compartir con nuestra agencia
                    publicitaria externa datos relativos a la utilización de
                    nuestras Páginas por parte de los usuarios que las visiten
                    para orientar mejor los anuncios publicados en banners en
                    nuestras páginas web. Aunque los datos recogidos a través de
                    la utilización de pixel tags no le identifiquen
                    personalmente, podrán asociarse a sus datos de carácter
                    personal.
                  </ParagrapTwo>
                </li>
                <li>
                  Contacte Con Nosotros <br />
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Si necesita cualquier aclaración acerca de la presente
                    Política de Cookies, póngase en contacto con nosotros
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Teléfono: (01) 55 50 11 94 00
                    <br />
                    Dirección – Boulevard (Blvd.) Manuel Ávila Camacho No. 118
                    Interior 24, Colonia Lomas de Chapultepec I Sección, Miguel
                    Hidalgo, C.P. 11000, Ciudad de México
                  </ParagrapTwo>
                  <ParagrapTwo
                    mtWeb="1.5rem"
                    mb="5%"
                    textalignMobile="left"
                    texttalign="left"
                    className="mx-auto"
                    mt="7%"
                  >
                    Tenga en cuenta que las comunicaciones por correo
                    electrónico no son siempre seguras; por tanto, no incluya
                    datos sobre tarjetas de crédito o datos especialmente
                    protegidos en los correos electrónicos que nos envíe.
                  </ParagrapTwo>
                </li>
              </ul>
              <ParagrapTwo
                fontSize="13px"
                fontSizeWeb="13px"
                textalignMobile="left"
                texttalign="left"
              >
                El usuario podrá siempre almacenar y/o imprimir este Aviso de
                privacidad.
              </ParagrapTwo>
            </SubtitleOne>
          </Col11>
        </Container>
      </Main>
    </Fragment>
  );
}

export default Privacy_notice;
