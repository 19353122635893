import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import {
  SubtitleOne,
  ParagraphOne,
  Main,
  ParagrapTwo,
  Col3,
  Col11,
  MessageError,
  Container,
  Button,
} from './common.styled';
import Header from './Header';
import Footer from './Footer';
// Modal
import GeneralLoader from './GeneralLoader';

export const FormValidateOtp = ({
  stateForm,
  handleChange,
  error,
  forwardCode,
  celular,
  handleClose,
  open,
  classes,
  modalStyle,
  handleKeyDown,
  clickLink,
  mostrarLoad,
}) => {
  const ModalMessage = () => (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ParagrapTwo
            fontWeight="700"
            cursor="pointer"
            texttalign="right"
            textalignMobile="right"
            className="mx-auto"
            onClick={handleClose}
          >
            X
          </ParagrapTwo>
          <SubtitleOne
            mt="3%"
            className="font-weight-bold"
            sizeMobile="20px"
            size="20px"
          ></SubtitleOne>
          <ParagrapTwo className="mx-auto" mb="0px">
            Se ha reenviado el código <br /> al número ******{celular}
          </ParagrapTwo>
          <Button className="d-block mt-5 mt-md-4 mb-5" onClick={handleClose}>
            Aceptar
          </Button>
        </div>
      </Modal>
  );
  return (
    <Fragment>
      <Header pathname="/user-validation-otp" />
      <Main
        className="container"
        background=" "
        margintop="2%"
        pb="10%"
        overflow=""
        padding="15px"
        mb="10%"
        minheigth="700px"
      >
        <Container width="40%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle3"
              mt="0%"
              className="roboto"
              size="20px"
              sizeMobile="20px"
            >
              Código de validación
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              textalignMobile="left"
              fontWeight=" "
              mb="10%"
              mt="5%"
              mbweb="10%"
            >
              Ingresa el código que hemos enviado al número ******{celular} a
              través de Whatsapp.
            </ParagrapTwo>
            <form autoComplete="off" className="formDigit">
              <Container width="100%" widthMobile="90%">
                <Col3>
                  <TextField
                    id="codval1"
                    name="codval1"
                    label=""
                    type="tel"
                    autoFocus
                    required
                    value={stateForm.codval1}
                    onChange={handleChange}
                    inputProps={{
                      className: 'text-center',
                      maxLength: 1,
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="codval2"
                    name="codval2"
                    label=""
                    type="tel"
                    required
                    value={stateForm.codval2}
                    onChange={handleChange}
                    inputProps={{
                      className: 'text-center',
                      maxLength: 1,
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="codval3"
                    name="codval3"
                    label=""
                    type="tel"
                    required
                    value={stateForm.codval3}
                    onChange={handleChange}
                    inputProps={{
                      className: 'text-center',
                      maxLength: 1,
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="codval4"
                    name="codval4"
                    label=""
                    type="tel"
                    required
                    value={stateForm.codval4}
                    onChange={handleChange}
                    inputProps={{
                      className: 'text-center',
                      maxLength: 1,
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                  />
                </Col3>
                <MessageError id="errorCodigo" pl="13px">
                  {error.codigo}
                </MessageError>
              </Container>
            </form>
            <ParagraphOne
              className={clickLink ? 'link roboto rosa' : 'link roboto'}
              mt="5%"
              fontSize="15px"
              mtD="5%"
              onClick={forwardCode}
            >
              ¿No recibiste el mensaje?
            </ParagraphOne>
          </Col11>
        </Container>
      </Main>
      <ModalMessage />
      <Footer pathname="/user-validation-otp" />
      {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
    </Fragment>
  );
};
