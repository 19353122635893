import React, {
  Fragment, useEffect, useState, Suspense,
} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
// rutinas
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import CarInfo from '../Routines/CarInfo';
import InfoExtraCI from '../Routines/InfoExtraCI';
import Comparativo from '../../components/Training/Comparativo';
import { DivLoader } from '../../assets/styles/Training.styled';

const RoutinesOrderWoCI = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
}) => {
  const [mainDataRoutine] = useState(0);
  useEffect(() => {
    sessionStorage.setItem('datOpportunity', '0');
    const step = sessionStorage.getItem('stepScore') || '';
    if (step === 'true') {
      setOrderRutine(6);
    }
  }, []);

  return (
    <Fragment>
      {orderRutine >= 1 ? (
        <CarInfo
          trainingColor={trainingColor}
          setOrderRutine={setOrderRutine}
          nextOrderRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={2}
            nextOrderRoutine={3}
            trainingColor={trainingColor}
          />
        </Suspense>
      ) : null}
      {orderRutine >= 3 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={3}
          nextOrderRoutine={4}
        />
      ) : null}
      {orderRutine >= 4 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={4}
          nextOrderRoutine={5}
        />
      ) : null}
      {orderRutine >= 5 ? (
        <InfoExtraCI
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={6}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 6 ? <Comparativo /> : null}
    </Fragment>
  );
};

export default RoutinesOrderWoCI;
