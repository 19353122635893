import asesores from '../../components/asesores';
import LoginAsesores from '../../components/common/LoginAsesores';
import Privacy_notice from '../../anaCoach/Privacy_notice';
import know_us from '../../anaCoach/know_usV2';
import ShareActivity from '../../anaCoach/Logros/ShareActivity';
import MVP_Money from '../../MVP/Money/Money';
import MoneyQuiz from '../../MVP/Money/WO_Moneytemp';
import MVP_Investment from '../../MVP/Investment/Investment';
import InvestmentQuiz from '../../MVP/Investment/WO_Investmenttemp';
import TrainingAWS from '../../components/common/AdminHome/App/components/TrainingsAWS';
import DailyTaskLog from '../../components/Training/RegistroDiarioTareas';
import WeeklySummary from '../../components/Training/ResumenSemanal';
import BlackList from '../../anaCoach/perfil/BlackList';

const Other = {
  asesores,
  LoginAsesores,
  Privacy_notice,
  know_us,
  ShareActivity,
  MVP_Money,
  MoneyQuiz,
  MVP_Investment,
  InvestmentQuiz,
  TrainingAWS,
  DailyTaskLog,
  WeeklySummary,
  BlackList,

};

export default (Other);
