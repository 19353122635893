import React, { useState, useEffect, Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';
import { GET_TRAINING_COLOR } from '../../queries';
import { DivLoader } from '../../assets/styles/Training.styled';
import { CREATE_SESSION_MYSQL, CREATE_SESSION, DYNAMIC_GET } from '../../mutations';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import NoHistoryRoutine from '../Routines/NoHistory';
import NoHistorySuccess from '../Routines/NoHistorySuccess';
import ModalLogin from '../pantallasInicio/ModalLogin';

const DatBasic = React.lazy(() => import('../Routines/DatBasic'));
const RoutinesOrderNoHistory = ({
  Date,
  // setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) => {
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [creditHistoryCheck, setCreditHistoryCheck] = useState('');
  const [colorTraining, setColorTraining] = useState('');
  const [isRecoveryUser, setIsRecoveryUser] = useState(false);
  const [userData, setUserData] = useState({});
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getUser] = useMutation(DYNAMIC_GET, {
    onCompleted({dynamicGet}) {
      const {response, message} = dynamicGet;
      if (message === "success") {
        let json = JSON.parse(response);
        const { FirstName, MiddleName, FathersLastName, MothersLastName, Email1, CellPhone } = json[0];
        setUserData({ NameUser: FirstName + MiddleName + FathersLastName + MothersLastName, EmailUser: Email1, PhoneUser: CellPhone });
      }
    }
  })

  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
    );
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      setIsRecoveryUser(true);
      const GetUserInput = {
        input: {
          table: "CatContactMedium, CatContact",
          columns: ["*"],
          conditions: [
            {
              valone: "IDCont",
              condition: "=",
              valtwo: idCont,
            },
            {
              logic: "AND",
              valone: "IDCont",
              condition: "=",
              valtwo: "CatContact_IDCont",
              validColumn: true,
            },
          ],
        },
      };
      getUser({ variables: GetUserInput });
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);
  return (
    <div>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          {/* <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={colorTraining}
            recoveryTimeout={true}
          /> */}
          <ModalLogin 
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            onLoginRoutine={2}
            onRegisterRoutine={2}
            isRecoveryUser={isRecoveryUser}
            userData={userData}
          />
        </Suspense>
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          mainDataRoutine={mainDataRoutine}
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
          recoveryTimeout={true}
        />
      ) : null}
      {/* {orderRutine >= 3 ? (
        <ContactInformation
          mainDataRoutine={mainDataRoutine}
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
          recoveryTimeout={true}
        />
      ) : null} */}
      {orderRutine >= 4 ? (
        <NoHistoryRoutine
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={colorTraining}
          setCreditHistoryCheck={setCreditHistoryCheck}
        />
      ) : null}
      {orderRutine >= 5 ? (
        <NoHistorySuccess creditHistoryCheck={creditHistoryCheck} />
      ) : null}
    </div>
  );
};

export default RoutinesOrderNoHistory;
