import React, { useState, Fragment, useEffect } from 'react';
import { useLazyQuery} from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  Image,
  ParagrapTwo,
} from '../../components/common/common.styled';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';
import {
  ContentHomeSection,
  Rectangle13,
  UiCard,
  TextoCoach,
  Rectangle24,
  Vector2,
  Vector3,
} from '../../components/common/Home.styled';
// recovery
import { GET_HISTORY_GOALS } from '../../queries';
import { filterFloat } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import vectorTop from '../../assets/img/anacoach/vectorTop.svg';

const styles = {
  fontSize: '19px',
  lineHeight: '120%',
  letterSpacing: '-0.3px',
  textTransform: 'capitalize',
  color: 'black',
  display: 'flex',
  fontFamily: 'sans-serif',
};
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '600px',
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function monthName(dateString) {
  const date = new Date(dateString.replace(/-+/g, '/'));
  const options = {
    month: 'long',
  };
  return date.toLocaleDateString('es-MX', options);
}

const ModalHistoryEmergencySaving = ({ setOpenHistory, openHistory }) => {
  const classes = useStyles();
  const [historySave, setHistorySave] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [msgSave, setmsgSave] = useState('');
  const [modalStyle] = useState(getModalStyle);

  const [getHistoryGoals] = useLazyQuery(GET_HISTORY_GOALS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getHistoryGoals }) {
      const resp = JSON.parse(getHistoryGoals.response);
      setHistorySave([]);
      const auxHistory = [];
      if (getHistoryGoals.message === 'success' && resp.length !== 0) {
        const resphistory = resp[0]['Ahorro de emergencia'][0].history;
        for (let i = 0; i < resphistory.length; i++) {
          let amount = 0;
          let flag = false;
          let dateSaving = resphistory[i].LastUpdated.split('T')[0];
          dateSaving = monthName(dateSaving);
          for (let x = 0; x < resphistory.length; x++) {
            let aux = resphistory[x].LastUpdated.split('T')[0];
            aux = monthName(aux);
            if (auxHistory.length > 0) {
              for (let v = 0; v < auxHistory.length; v++) {
                if (auxHistory[v].LastUpdated === dateSaving) {
                  flag = true;
                  break;
                }
              }
              if (flag) {
                break;
              } else if (dateSaving === aux) {
                amount += resphistory[x].amount;
              }
            } else if (dateSaving === aux) {
              amount += resphistory[x].amount;
            }
          }
          if (amount !== 0) {
            auxHistory.push({
              idDatGoalsUpdate: resphistory[i].idDatGoalsUpdate,
              DatGoals_IdDatGoals: resphistory[i].DatGoals_IdDatGoals,
              LastUpdated: dateSaving,
              amount,
            });
          }
        }
        setHistorySave(auxHistory);
        setmsgSave('');
        console.log('history', historySave);
      } else {
        setmsgSave('Por el momento no cuentas con ahorros');
        setHistorySave([]);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (openHistory) {
      getHistoryGoals({
        variables: {
          idUser: sessionStorage.getItem('idUser').toString(),
          idTraining: sessionStorage.getItem('idTraining').toString(),
        },
      });
    }
  }, [openHistory]);
  const handleClose = () => {
    setOpenHistory(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  return (
    <Modal
      open={openHistory}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <ContentHomeSection
          displayD="flex"
          leftD="0%"
          floatD="left"
          width="100%;"
        >
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="48%;"
          ></ContentHomeSection>
          <ImageCoachInicio />
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="35%;"
          ></ContentHomeSection>
        </ContentHomeSection>
        <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
          <Rectangle24
            positionD="absolute"
            Ptop="125px"
            PTopM="-65px"
            Pleft="-10%"
            background="#6BD5AF"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="70px"
            height="70px"
            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
          />
          <Vector2
            width="55px"
            left="0"
            PleftM="35%"
            height="65px"
            background="#91D770"
            radius="40%"
            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
            positionD="absolute"
            Pleft="17%"
            Ptop="-5px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="10px"
            Pleft="44%"
            background=" #E3E660;"
            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="none"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="100px"
            Pleft="74%"
            PLeftM="-78%"
            PTopM="-32%"
            background="#BFDD6A"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            transform="rotate(46.41deg)"
          />
          <Vector2
            width="50px"
            widthM="28.81px"
            heightM="35.02px"
            left=""
            height="60px"
            background="#6BD5AF"
            radius="40%"
            transform="rotate(-8.34deg)"
            positionD="absolute"
            Pleft="52%"
            PleftM="-15%"
            PTopM="-10%"
            MtopM="-15%"
            Ptop="135px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="85px"
            Pleft="14%"
            PleftM="-3%"
            background="#E3E660"
            transform="rotate(-15.06deg)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            widthM="35px"
            heightM="35px"
            mLeftM="0"
            mTopM="18%"
            positionD="absolute"
            Pleft="70%"
            Ptop="0"
            displayD=""
            width="60px"
            height="60px"
            left=" "
            top=" "
            background="#91D770"
            transform="rotate(-17.24deg)"
          />
          <UiCard
            positionM=" "
            heightM="auto"
            marginTop="100px"
            marginTopM="90px"
          >
            <Rectangle13
              boxSha=" "
              margin="auto"
              bottomM="0px"
              displayM="block"
              position=" "
              topM="0px"
              height="auto"
              width="100%"
              widthm="100%"
              padding="19px 25px 20px 21px"
            >
              {historySave.length !== 0 && msgSave === '' ? (
                <Fragment>
                  <TextoCoach
                    height="auto"
                    className="roboto"
                    size="20px"
                    width="auto"
                  >
                    <b>{'Historial de ahorros'}</b>
                  </TextoCoach>
                  <TextoCoach
                    height="auto"
                    className="roboto"
                    width="auto"
                    align="left"
                    top="4%"
                  >
                    A continuación podrás ver todos los ahorros qué has hecho.
                  </TextoCoach>
                  {historySave.map((item, index) => {
                    if (index < 3) {
                      return (
                        <Fragment key={index}>
                          <label style={styles}>
                            <b>{item.LastUpdated}</b>
                          </label>
                          <label
                            style={{
                              display: 'flex',
                              marginTop: '10px',
                              marginBottom: '10px',
                              color: 'black',
                              fontSize: '18px',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            Cantidad ahorrada:
                          </label>
                          <TextField
                            variant="outlined"
                            id={index}
                            autoComplete="off"
                            fullWidth
                            inputProps={{
                              className: item.amount ? 'validInputSaving' : '',
                            }}
                            value={formatter.format(filterFloat(item.amount))}
                            disabled={true}
                          />
                          <hr style={{ width: '100%' }} />
                        </Fragment>
                      );
                    }
                  })}
                  <div
                    onClick={() => setSeeMore(!seeMore)}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <div style={{ fontSize: '18px', color: '#F64282' }}>
                      {' '}
                      {seeMore ? 'Ver menos' : 'Ver más'}
                    </div>
                    <Image
                      transform={seeMore ? '' : 'rotate(180deg)'}
                      src={vectorTop}
                      mwidth="5%"
                      marginLeft="10px"
                      mlMob="10px"
                      width="4%"
                      margin="auto"
                      top="110px"
                      left="10%"
                      mleft="0"
                      mtop="75px"
                    />
                  </div>
                  {historySave.map((item, index) => {
                    if (index >= 3) {
                      return (
                        <Fragment>
                          {seeMore ? (
                            <Fragment key={index}>
                              <label style={styles}>
                                <b>{item.LastUpdated}</b>
                              </label>
                              <label
                                style={{
                                  display: 'flex',
                                  marginTop: '10px',
                                  marginBottom: '10px',
                                  color: 'black',
                                  fontSize: '18px',
                                }}
                              >
                                Cantidad ahorrada:
                              </label>
                              <TextField
                                variant="outlined"
                                id={index}
                                autoComplete="off"
                                fullWidth
                                inputProps={{
                                  className: item.amount
                                    ? 'validInputSaving'
                                    : '',
                                }}
                                value={formatter.format(
                                  filterFloat(item.amount),
                                )}
                                disabled={true}
                              />
                              <hr style={{ width: '100%' }} />
                            </Fragment>
                          ) : null}
                        </Fragment>
                      );
                    }
                  })}
                </Fragment>
              ) : null}
              {historySave.length === 0 && msgSave !== '' ? (
                <Fragment>
                  <TextoCoach
                    height="auto"
                    className="roboto"
                    size="20px"
                    width="auto"
                  >
                    <b>{'Historial de ahorros'}</b>
                  </TextoCoach>
                  <TextoCoach
                    height="auto"
                    className="roboto"
                    width="auto"
                    align="left"
                    top="4%"
                  >
                    {msgSave}
                  </TextoCoach>
                </Fragment>
              ) : null}
            </Rectangle13>
          </UiCard>
        </ContentHomeSection>
        <CardsBtn min_w="100%" min_web="100%" onClick={handleClose}>
          <b>Regresar al entrenamiento</b>
        </CardsBtn>
      </div>
    </Modal>
  );
};

export default ModalHistoryEmergencySaving;
