import React, { useState, useEffect, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import '../../assets/styles/Home.css';
import imgWo from '../../assets/img/anacoach/Family.svg';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import RoutinesOrderFP from './RoutinesOrderFP';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  RECOVERY_TRAINING,
  BACKUP_WO_DATA,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  CHECK_TRAINING_FINISHED,
  GET_TRAINING_STATUS,
} from '../../queries';

import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import WOlayout from '../../components/layouts/WOlayout';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import WaitList from '../../components/Training/WaitList';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import SuccessWOValid from '../../components/Training/SuccessWOValid';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';

const items2 = ['Protege a tu familia'];
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function FamilyProtection() {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 11);
  sessionStorage.setItem('pagename', 'proteccion-tarjeta');
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);

  // End ValidWaitList
  const anchop = window.screen.width;
  const [percentage, setPercentage] = useState('');
  const [arrayOrderRoutines] = useState([]);
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(11);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);
  //----------------------------------------------------------------
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }

  const [backupWOData] = useMutation(BACKUP_WO_DATA, {
    onCompleted({ backupWOData }) {
      if (backupWOData.statusCode === 200) {
        console.log(backupWOData.response);
      }
    },
  });
  const [recoveryTraining] = useMutation(RECOVERY_TRAINING, {
    onCompleted({ recoveryTraining }) {
      try {
        const json = JSON.parse(recoveryTraining.response);

        const indices = Object.keys(json);
        const bckData = [];
        for (const indice in indices) {
          const idRoutine = indices[indice];
          const {
            Name: name,
            RoutineType: routineType,
            questions,
            responses,
          } = json[idRoutine];

          const routine = {
            name,
            routineType,
            questionsAndresponses: [],
          };

          questions.map((question, index) => {
            routine.questionsAndresponses.push({
              questionName: question.QuestionName,
              responseValue: responses[index]?.responseValue || '',
            });
          });

          bckData.push(routine);
        }

        const idUser = sessionStorage.getItem('idUser');
        const idTraining = sessionStorage.getItem('idTraining');
        const idCont = sessionStorage.getItem('idCont');

        setBackupCC({
          routines: bckData,
          idUser,
          idTraining,
          idCont,
        });
      } catch (error) {
        console.log('exception recovery', error.message);
      }
    },
    onError(err) {
      console.log('error recoveryTraining: ', err.message);
    },
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const pushArrayOrderRoutine = (orderRoutine) => {
    console.log('pushArrayOrderRoutine');
    const elementExist = arrayOrderRoutines.filter(
      (element) => element === orderRoutine,
    );
    console.log(elementExist);
    if (elementExist.length === 0) {
      arrayOrderRoutines.push(orderRoutine);
    }
  };

  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    pushArrayOrderRoutine(orderRutine);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value.toString();
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (
            jsonResponse.idSession !== ''
            || jsonResponse.idSession !== null
          ) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  useEffect(() => {
    debugger;
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('obtener-primer-tarjeta');
    const currentTraining = sessionStorage.getItem('idTraining');
    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    checkTrainingFinished({
      variables: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(currentTraining),
      },
    });
  }, []);

  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(`Status Training${saveStatusTraining}`);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        actions({ action: 'getAchievment', variables: '' });
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 11 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, [orderRutine]);
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  const saveAdvance = async (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const tempArrayOrder = arrayOrderRoutines;
    const trueOrder = [1, 2, 3];

    tempArrayOrder.forEach((n) => {
      if (trueOrder.indexOf(n) < 0) {
        trueOrder.push(n);
      }
    });

    const stringOrderRoutines = trueOrder.join(',');

    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: stringOrderRoutines,
      },
    };
    if (idUser != null) {
      const responseStatus = await saveStatusTraining({
        variables: inputProgress,
      });
    }
    if (sessionStorage.getItem('SucessFP') !== null) {
      if (sessionStorage.getItem('SucessFP') === 'Listo') {
        setSaludo('No');
      }
    }
  };
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      console.log(`percent1: ${getPercentageTraining.percentage}`);
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 11 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  // Valid Finished WO
  const [allowOverwrite, setAllowOverwrite] = useState(false);
  const [backupCC, setBackupCC] = useState();
  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    fetchPolicy: 'no-cache',
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const idTraining = sessionStorage.getItem('idTraining');
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const overwrite = sessionStorage.getItem(`overwrite_WO_${idTraining}`) || 'false';
        if (overwrite === 'true' && trainingInfo.avance === 100) {
          setAllowOverwrite(true);
          setOrderRutine(1);
          getTrainingData();
        } else if (trainingInfo.avance === 100) {
          setFinished(true);
          const firstName = sessionStorage.getItem('FirstName') || '';
          const middleName = sessionStorage.getItem('MiddleName')
            ? ` ${sessionStorage.getItem('MiddleName')}`
            : '';
          const fathersLastName = sessionStorage.getItem('FathersLastName')
            ? ` ${sessionStorage.getItem('FathersLastName')}`
            : '';
          const mothersLastName = sessionStorage.getItem('MothersLastName')
            ? ` ${sessionStorage.getItem('MothersLastName')}`
            : '';
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            fullName: `${firstName}${middleName}${fathersLastName}${mothersLastName}`,
            phone: sessionStorage.getItem('cellPhone'),
            email: sessionStorage.getItem('Email1'),
            meetsCondition: trainingInfo.threeMonthsPassed,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: 'Tu solicitud está en proceso',
            txt1: 'Para incrementar las posibilidades de obtener un crédito te recomendamos no solicitar más de uno en tres meses',
            txt2: `Realizaste tu solicitud el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} con los siguientes datos:`,
          });
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const getTrainingData = () => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryTraining({
        variables: {
          idTraining: sessionStorage.getItem('idTraining') || '',
          idUser: sessionStorage.getItem('idUser') || '',
          idCont: sessionStorage.getItem('idCont') || '',
        },
      });
    } else {
      sessionStorage.setItem('Primervisita', true);
    }
  };

  const hora = `${date.getHours()}:${minutos}`;
  const [saludo, setSaludo] = useState('No');
  useEffect(() => {
    const succ = sessionStorage.getItem('SucessFP');
    if (succ !== null) {
      if (succ === 'Listo') {
        setSaludo('No');
      }
    }
  }, []);

  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '45px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '45px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  useEffect(() => {
    if (backupCC && backupCC.routines.length > 0 && allowOverwrite) {
      const mongoInput = {
        input: {
          collection: 'backup_wo_routines',
          data: JSON.stringify(backupCC),
        },
      };
      backupWOData({ variables: mongoInput });
    }
  }, [backupCC]);
  /*
  const feacturesArray1 = useState([
    {
      text: 'Cuida a tu familia todo el tiempo.',
      color: '#61D2FA',
    },
    {
      text: 'No sabemos qué problema pasará.',
      color: '#61D2FA',
    },
    {
      text: 'Prever situaciones complicadas.',
      color: '#61D2FA',
    },
  ]); */

  const feacturesArray = useState([
    {
      text: 'Qué debes proteger.',
      color: '#61D2FA',
    },
    {
      text: 'Formas de proteger a tu familia.',
      color: '#61D2FA',
    },
    {
      text: 'Mejores productos para asegurar a tu familia.',
      color: '#61D2FA',
    },
  ]);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );

  // const { loading, error, data: dataWO } = useQuery(GET_TRAINING_PROGRESS, {
  //    variables: {
  //       idUser: parseInt(sessionStorage.getItem('idUser')) || null ,
  //       idTraining: 11
  //    },
  //    fetchPolicy: 'network-only',
  //    onCompleted: (dta) => successRedirect(dta)
  // })

  // if (loading) return <GeneralLoader />
  // if (error) return 'Error ' + error;
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: 'Adelántate a los imprevistos',
    time: '5 minutos',
    imgWo: { imgWo },
  };
  return (
    <WOlayout
      backgroundColor={'#61D2FA'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Aseguranza Médica Familiar | Coru</title>
        <link rel="canonical" href="https://coru.com/wo/family-protection" />
        <meta name="keywords" content="aseguranza medica familiar" />
        <meta
          name="description"
          content="Asegura a tu familia, aprende a planificar cualquier emergencia y vive más tranquilo. Te decimos cuáles son las mejores opciones."
        />
      </Helmet>
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor="#61D2FA"
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <BarraInfoWO propied={propied} />
          {scroll1 > 0 ? <BarraScroll /> : null}
          <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
          {percentage !== '' ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt="200px"
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{position: 'relative'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {WOStatus ? (
              <Fragment>
                {!finished ? (
                  <Fragment>
                    {!allowOverwrite ? (
                      <Fragment>
                        <ModalDataPersonalInitWO
                          isData={isData}
                          setData={setisData}
                        />
                        <DataPersonalInitWO
                          Date={Date}
                          hora={hora}
                          orderRutine={orderRutine}
                          setOrderRutine={setOrderRutine}
                          currentTraining={currentTraining}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                        />
                      </Fragment>
                    ) : null}
                    <TrainingSectionGray
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="En este entrenamiento aprenderás:"
                          />
                          {active1 ? (
                            <Fragment>
                              <Features
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title=""
                                Arraytext={feacturesArray}
                              />
                            </Fragment>
                          ) : null}
                          {active2 ? (
                            <Starttraining
                              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                              title="Iniciemos esta aventura..."
                              text="Primero necesito conocerte, ayúdame con tus datos personales."
                            />
                          ) : null}
                        </div>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    <div className="marginComentLgSuccess1">
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          {/* 682px */}
                          {active2 ? (
                            <RoutinesOrderFP
                              Date={stateDate}
                              hora={hora}
                              orderRutine={orderRutine}
                              setOrderRutine={setOrderRutine}
                              currentTraining={currentTraining}
                              createJsonInput={createJsonInput}
                              questions={questions}
                              setQuestions={setQuestions}
                            />
                          ) : null}
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    </div>
                    <div className="shapes">
                      {saludo === 'Si' ? (
                        <TrainingDivSection
                          bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                          mt="44px"
                          mtw="261px"
                          br="4px"
                          padding="21px 20px"
                          wi="335px"
                          wiw="670px"
                          he="212px"
                          mb="66px"
                        >
                          <div className="triangulo-equilatero-bottom"></div>
                          <div
                            className="closeDiv"
                            onClick={() => setSaludo('No')}
                          >
                            ×
                          </div>
                          <TrainingSubtitles
                            size="20px"
                            fw="600"
                            align="left"
                            alignweb="left"
                            className="cabin"
                          >
                            ¡Hola! Soy Ana, tu coach financiero.
                          </TrainingSubtitles>
                          <TrainingText
                            size="16px"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Te ayudaré a buscar alternativas para proteger a tu
                            familia.
                          </TrainingText>
                          <TrainingText
                            size="16px"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            A continuación te haré algunas preguntas para
                            conocer un poco más a tu familia.
                          </TrainingText>
                          {/* <Images
                      src={tarjeta}
                      top="5px"
                      topweb="0"
                      left="40px"
                      leftw="29%"
                      position="initial"
                      width="215px"
                      widthw="250px"
                      className="img-fluid"
                    ></Images> */}
                        </TrainingDivSection>
                      ) : null}
                    </div>
                    {!active2 ? (
                      <Fragment>
                        <br></br>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="0rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio topMobile="35px" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : (
                  <Fragment>
                    <SuccessWOValid {...trainingFinishedInfo} />
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <br />
                <br />
                <br />
                <br />
                {executeQuery ? <WaitList /> : null}
              </Fragment>
            )}
          </div>
          <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
          </div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
}

export default FamilyProtection;
