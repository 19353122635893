import React, { useState, useEffect, Fragment } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Header from '../../components/common/Header';
import imgWo from '../../assets/img/anacoach/tarjetanew.svg';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  CREATE_ACCOUNT_USER_TEMP,
  CREATE_SESSION,
  RECOVERY_TRAINING,
  BACKUP_WO_DATA,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GETURLVIDEO,
  GETPERCENTAGETRAINING,
  GET_TRAINING_STATUS,
  CHECK_TRAINING_FINISHED,
} from '../../queries';
import {
  arraySessionsRutines,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import PlayYoutube from '../../components/common/PlayYoutube';
import WaitList from '../../components/Training/WaitList';
import { DivLoader } from '../../assets/styles/Training.styled';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import WOlayout from '../../components/layouts/WOlayout';
// end import base
// estilos
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingText,
  TrainingDivSection,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingSubtitles,
  TrainigTam,
  TrainingLine,
} from '../../assets/styles/Training.styled';
// iamgenes
import RoutinesOrderFirstCard from './RoutinesOrderFirstCard';

import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import SuccessWOValid from '../../components/Training/SuccessWOValid';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';
// import SectionShare from '../../components/common/ShareSection';

// variables
const items2 = ['Domina el uso de tu primera tarjeta', ' '];
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function ObtenerTarjeta() {
  const anchop = window.screen.width;
  const [started, setStarted] = useState(false);
  const [percentage, setPercentage] = useState('');
  const [saludo] = useState('Si');
  const [urlVideo, setUrlVideo] = useState('');
  const [idVideo, setIdVideo] = useState('');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [beneficios] = useState('Si');
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  // Obtener color del entrenamiento
  const [colorTraining] = useState('');
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const { actions } = GeneralHooks();
  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      setactive3(true);
    }, 13000);
  }, [active2]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  useEffect(() => {
    setTimeout(() => setStarted(true), [1000]);
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // mutaciones y funciones principales
  sessionStorage.setItem('idTraining', 2);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  console.log(id);
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);

  // End ValidWaitList
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(2);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);

  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }

  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      console.log(`${percent.toString()}%`);
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setactive3(true);
      }
    },
    onError(err) {
      console.log('getPercentageTraining=> error :', err);
    },
  });
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      console.log('ejecutar porcentaje============');
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 1 },
      });
    } else {
      // console.log("Sin porcentaje de avance");
      setPercentage('0');
    }
  }, []);

  const [getVideoTraining] = useLazyQuery(GETURLVIDEO, {
    onCompleted({ getVideoTraining }) {
      const video = JSON.parse(getVideoTraining.response);
      setUrlVideo(video.UrlVideo);
      setIdVideo(video.IdVideoURL);
    },
    onError(err) {
      console.log(err);
    },
  });

  // Valid Finished WO
  const [allowOverwrite, setAllowOverwrite] = useState(false);
  const [backupCC, setBackupCC] = useState();
  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const idTraining = sessionStorage.getItem('idTraining');
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const overwrite = sessionStorage.getItem(`overwrite_WO_${idTraining}`) || 'false';
        if (overwrite === 'true' && trainingInfo.avance === 100) {
          setAllowOverwrite(true);
          setOrderRutine(1);
          getTrainingData();
        } else if (trainingInfo.avance === 100) {
          setFinished(true);
          const firstName = sessionStorage.getItem('FirstName') || '';
          const middleName = sessionStorage.getItem('MiddleName')
            ? ` ${sessionStorage.getItem('MiddleName')}`
            : '';
          const fathersLastName = sessionStorage.getItem('FathersLastName')
            ? ` ${sessionStorage.getItem('FathersLastName')}`
            : '';
          const mothersLastName = sessionStorage.getItem('MothersLastName')
            ? ` ${sessionStorage.getItem('MothersLastName')}`
            : '';
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            fullName: `${firstName}${middleName}${fathersLastName}${mothersLastName}`,
            phone: sessionStorage.getItem('cellPhone'),
            email: sessionStorage.getItem('Email1'),
            meetsCondition: trainingInfo.threeMonthsPassed,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: 'Tu solicitud está en proceso',
            txt1: 'Para incrementar las posibilidades de obtener un crédito te recomendamos no solicitar más de uno en tres meses',
            txt2: `Realizaste tu solicitud el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} con los siguientes datos:`,
          });
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const getTrainingData = () => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryTraining({
        variables: {
          idTraining: sessionStorage.getItem('idTraining') || '',
          idUser: sessionStorage.getItem('idUser') || '',
          idCont: sessionStorage.getItem('idCont') || '',
        },
      });
    } else {
      sessionStorage.setItem('Primervisita', true);
    }
  };
  useEffect(() => {
    getVideoTraining({ variables: { id: '2' } });
  }, []);

  useEffect(() => {
    if (backupCC && backupCC.routines.length > 0 && allowOverwrite) {
      const mongoInput = {
        input: {
          collection: 'backup_wo_routines',
          data: JSON.stringify(backupCC),
        },
      };
      backupWOData({ variables: mongoInput });
    }
  }, [backupCC]);

  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);

    let numQuestion = 0;
    try {
      questions.forEach((item, index) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }

        const tagManagerArgs = {
          dataLayer: {
            event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        console.log(tagManagerArgs);
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  const [continueTrainings, setContinueTraining] = useState(
    sessionStorage.getItem('idCont') || '',
  );
  const [createAccountUserTemp] = useMutation(CREATE_ACCOUNT_USER_TEMP, {
    onCompleted({ createAccountUserTemp }) {
      if (createAccountUserTemp.statusCode === 200) {
        const jsonResponse = JSON.parse(createAccountUserTemp.response);
        sessionStorage.setItem('Email1', jsonResponse.email);
        sessionStorage.setItem('CellPhone', '0000000000');
        sessionStorage.setItem('idCont', jsonResponse.idCont);
        sessionStorage.setItem('idUser', jsonResponse.idUser);
        localStorage.setItem('Email1', jsonResponse.email);
        localStorage.setItem('CellPhone', '0000000000');
        localStorage.setItem('idCont', jsonResponse.idCont);
        localStorage.setItem('idUser', jsonResponse.idUser);
        setContinueTraining(jsonResponse.idCont);
      }
    },
  });

  const [recoveryTraining] = useMutation(RECOVERY_TRAINING, {
    onCompleted({ recoveryTraining }) {
      // setRecoveryState(recoveryTraining.response)
      try {
        const json = JSON.parse(recoveryTraining.response);

        const indices = Object.keys(json);
        const bckData = [];
        for (const indice in indices) {
          const idRoutine = indices[indice];
          const {
            Name: name,
            RoutineType: routineType,
            questions,
            responses,
          } = json[idRoutine];

          const routine = {
            name,
            routineType,
            questionsAndresponses: [],
          };

          questions.map((question, index) => {
            routine.questionsAndresponses.push({
              questionName: question.QuestionName,
              responseValue: responses[index]?.responseValue || '',
            });
          });

          bckData.push(routine);
        }

        const idUser = sessionStorage.getItem('idUser');
        const idTraining = sessionStorage.getItem('idTraining');
        const idCont = sessionStorage.getItem('idCont');

        setBackupCC({
          routines: bckData,
          idUser,
          idTraining,
          idCont,
        });
      } catch (error) {
        console.log('exception recovery', error.message);
      }
    },
    onError(err) {
      console.log('error recoveryTraining: ', err.message);
    },
  });

  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);
        saveAdvance(parseInt(orderRutine));

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
      }
    },
  });
  const [backupWOData] = useMutation(BACKUP_WO_DATA, {
    onCompleted({ backupWOData }) {
      if (backupWOData.statusCode === 200) {
        console.log(backupWOData.response);
      }
    },
  });
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('obtener-primer-tarjeta');
    const currentTraining = sessionStorage.getItem('idTraining');
    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    checkTrainingFinished({
      variables: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(currentTraining),
      },
    });

    const emailClient = sessionStorage.getItem('Email1') || '';
    createAccountUserTemp({ variables: { email: emailClient } });

    sessionStorage.setItem('pagename', 'obtener-primer-tarjeta');
    sessionStorage.setItem('hostname', window.location.hostname);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie

    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);

  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} SAVE_STATUS_TRAINING [description]
   * @param  {[callback]} { onCompleted({ saveStatusTraining }) {if (saveStatusTraining.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        if (porcentaje !== 0) {
          setactive0(true);
          setactive1(true);
          setactive2(true);
          setactive3(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  const saveAdvance = (idRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(orderRutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  const hora = `${date.getHours()}:${minutos}`;
  // end  mutaciones y funciones principales

  const feacturesArray = useState([
    {
      text: 'Entender bien las comisiones e intereses aplicables',
      color: '#DE98FF',
    },
    {
      text: 'No es una extensión de tus ingresos',
      color: '#DE98FF',
    },
    {
      text: 'Comprar productos que duren más del tiempo de pago',
      color: '#DE98FF',
    },
    {
      text: 'No pagar sólo el mínimo mensual',
      color: '#DE98FF',
    },
  ]);

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: 'Y disfruta de sus ventajas',
    time: '10 minutos',
    imgWo: { imgWo },
  };

  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Cómo usar una Tarjeta de Crédito | Coru</title>
        <link
          rel="canonical"
          href="https://coru.com/wo/first-card"
        />
        <meta name="keywords" content="como usar una tarjeta de credito" />
        <meta
          name="description"
          content="Si tienes tu primera tarjeta de Crédito o simplemente quieres saber cómo usarla mejor, tienes que concoer este Entrenamiento. Seguro te funciona. "
        />
      </Helmet>
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor="#DE98FF"
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <TrainigTam>
            <Header
              background={'#DE98FF'}
              pathname={window.location.pathname}
            />
          </TrainigTam>
          <BarraInfoWO propied={propied} />
          <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
          {percentage !== '' ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt=""
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{position: 'relative'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {WOStatus ? (
              <Fragment>
                {!finished ? (
                  <Fragment>
                    {!allowOverwrite ? (
                      <Fragment>
                        <ModalDataPersonalInitWO
                          isData={isData}
                          setData={setisData}
                        />
                        <DataPersonalInitWO
                          Date={Date}
                          hora={hora}
                          orderRutine={orderRutine}
                          setOrderRutine={setOrderRutine}
                          currentTraining={currentTraining}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                        />
                      </Fragment>
                    ) : null}

                    <TrainingSectionGray
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="Te ayudaré a obtener la mejor tarjeta de crédito para ti. 💳"
                            text2="Lo primero que debes saber es que necesitas mejorar tu score crediticio.
                  ¡No te preocupes, nunca es tarde para comenzar! El score es la referencia
                  que consideran los bancos para prestarte dinero. Lo mejor es contar con
                  un score mayor a 650 para otorgarte un préstamo."
                          />

                          {active1 ? (
                            <Container
                              width="80%"
                              mt="0.2rem"
                              widthMobile="90%"
                              mtMobile="0rem"
                            >
                              {anchop <= 768 ? null : (
                                <ImageCoachInicio topMobile="35px" />
                              )}
                              <Col7
                                maxwidth="100%"
                                padding=" "
                                className="cardPrincipal1"
                              >
                                <TrainingDivSection
                                  hew="auto"
                                  paddw="26px"
                                  mlw="0"
                                  wiw="100%"
                                  back="#F3F3F3"
                                  backw="#F3F3F3"
                                  bshaw="none"
                                  bsha=""
                                  br="4px"
                                  padding="20px"
                                  wi="100%"
                                  he="auto"
                                  mb="0px"
                                  className="topOne1"
                                >
                                  <TrainingSubtitles
                                    size="20px"
                                    fw="600"
                                    align="left"
                                    alignweb="left"
                                    className="cabin"
                                  >
                                    ¿Qué debes saber?
                                  </TrainingSubtitles>
                                  <TrainingText
                                    size="16px"
                                    className="roboto"
                                    align="left"
                                    alignweb="left"
                                    ls="-0.2px"
                                  >
                                    <b>CAT (Costo Anual Total): </b>Suma de las
                                    comisiones e intereses de cada producto.
                                    <br />
                                    <b>Anualidad: </b>Comisión pagada anualmente
                                    por manejo de tarjeta.
                                    <br />
                                    <b>Pago mínimo: </b> Aportación mínima a la
                                    deuda de la tarjeta de crédito, en su
                                    mayoría representa pago de intereses.
                                  </TrainingText>
                                </TrainingDivSection>
                              </Col7>
                            </Container>
                          ) : null}
                        </div>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    <div className="shapes">
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          <div className="marginComentLgSuccess1">
                            {beneficios === 'Si' ? (
                              <Features
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="¿Qué considerar antes de solicitar una tarjeta de crédito?"
                                Arraytext={feacturesArray}
                              />
                            ) : null}

                            {active2 ? (
                              <Fragment>
                                <Starttraining
                                  classAna={
                                    anchop <= 768 ? 'falseAna' : 'trueAna'
                                  }
                                  title="Conoce más de este proceso en nuestro video"
                                  text="Antes de iniciar tu entrenamiento puedes ver un video donde te damos más información del proceso para solicitar tu tarjeta de crédito."
                                />

                                {saludo === 'Si' ? (
                                  <Fragment>
                                    <TrainingDivSection
                                      bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                                      mt="20px"
                                      mtw="20px"
                                      br="4px"
                                      wi="90%"
                                      wiw="60%"
                                      mlw="18%"
                                      he="auto"
                                      hew="auto"
                                      mb="0px"
                                      padding="3%"
                                    >
                                      {/* <div
                                        className="closeDiv"
                                        onClick={() => setSaludo("No")}
                                      >
                                        ×
                                      </div> */}
                                      {/* <TrainingSubtitles size="20px" fw="600" align="left" alignweb="left" className="cabin">
                        ¡Hola! Soy Ana, tu coach financiero.
                                  </TrainingSubtitles>
                                  <TrainingText size="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                    Te ayudaré a obtener la mejor tarjeta de crédito para ti. 💳 <br />
                                    Lo primero que debes saber es que necesitas mejorar tu score crediticio.
                                    ¡No te preocupes, nunca es tarde para comenzar! El score es la referencia
                                    que consideran los bancos para prestarte dinero. Lo mejor es contar con
                                    un score mayor a 650 para otorgarte un préstamo.
                                  </TrainingText> */}
                                      {urlVideo !== '' ? (
                                        <PlayYoutube
                                          idVideo={idVideo}
                                          width={'300'}
                                          height={'190'}
                                          close={false}
                                        />
                                      ) : null}
                                    </TrainingDivSection>
                                    <TrainingDivSection
                                      ml=" "
                                      back=" "
                                      backw=" "
                                      paddw="4px"
                                      //   bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                                      mt="0px"
                                      mtw="0px"
                                      br="4px"
                                      padding="2%"
                                      wi="90%"
                                      wiw="670px"
                                      he="auto"
                                      hew="auto"
                                      mb="0"
                                      mbw="0"
                                    >
                                      <SendNotificationVideo
                                        urlYTDirect={urlVideo}
                                      />
                                      {/* <SectionShare WO="PrimeraTarjeta"></SectionShare> */}
                                    </TrainingDivSection>
                                  </Fragment>
                                ) : (
                                  <PlayYoutube
                                    idVideo={idVideo}
                                    width={'300'}
                                    height={'190'}
                                    close={true}
                                  />
                                )}
                              </Fragment>
                            ) : null}
                          </div>
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    </div>
                    {active3 ? (
                      <TrainingSectionGray
                        wiw="670px"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          <Starttraining
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="Iniciemos esta aventura..."
                            text="Primero necesito conocerte, ayúdame con tus datos personales."
                          />
                          {continueTrainings !== '' ? (
                            <RoutinesOrderFirstCard
                              Date={stateDate}
                              hora={hora}
                              orderRutine={orderRutine}
                              setOrderRutine={setOrderRutine}
                              currentTraining={currentTraining}
                              createJsonInput={createJsonInput}
                              questions={questions}
                              setQuestions={setQuestions}
                            />
                          ) : (
                            <DivLoader>
                              <CircularProgress color="inherit" />
                            </DivLoader>
                          )}
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    ) : null}
                    {!active3 ? (
                      <Fragment>
                        <br></br>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="0rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio topMobile="35px" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : (
                  <Fragment>
                    <SuccessWOValid {...trainingFinishedInfo} />
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <br />
                <br />
                <br />
                <br />
                {executeQuery ? <WaitList /> : null}
              </Fragment>
            )}
          </div>
          <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
          </div>
        </div>
      </TrainingMain>
      {/* <br />
      <Footer pathname={window.location.pathname} /> */}
    </WOlayout>
  );
}

export default ObtenerTarjeta;
