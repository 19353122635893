import React from 'react';
// Sources
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ImageLogo from '../../assets/img/anacoach/logo-01.svg';
// Styled components
import {
  Header_Home_Web,
  Image,
  Main,
  Header_Log,
  Header_Log_Btn,
  Content_Options,
} from '../common/common.styled';
// Dependencies
import { CREATE_CLICK } from '../../mutations';

const HeaderEmbajadores = () => {
  const history = useHistory();
  const initLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickIniciarSesion', true);
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickLogin',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      },
    });
    history.push('/login');
  };
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });
  const headerOptions = [
    'Tarjetas de Crédito',
    'Seguros de Auto',
    'Créditos personales',
    'Blog',
    'Acerca de Coru',
  ];
  const printHeaderOptions = () => headerOptions.map((item) => (
        <Header_Log
          className="text-session"
          width="auto !important"
          textAlign="center"
          fontSize="14px !important"
        >
          {item}
        </Header_Log>
  ));
  return (
    <Main padding="1rem">
      <div className="container home-header-mob">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Image
              cursor="pointer"
              src={ImageLogo}
              width="50%"
              mwidth="auto"
              heightMobile="20.8px"
              alt="logo-coru"
            />
          </div>
          <Header_Home_Web width="70%">
            <Content_Options>
              {printHeaderOptions()}
              <Header_Log_Btn
                fontSize="14px"
                maxwidth="138.22px"
                className="text-session hoverbtn"
                onClick={initLogin}
              >
                Iniciar sesión
              </Header_Log_Btn>
            </Content_Options>
          </Header_Home_Web>
        </div>
      </div>
    </Main>
  );
};
export default HeaderEmbajadores;
