/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import PlayYoutube from '../../components/common/PlayYoutube';
// Import visual components
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  TrainingText,
  Images,
  TrainingSubtitles,
  QuestionsDivs,
  TrainingDivSection,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ana from '../../assets/img/anacoach/CoachImage.png';
import editIcon from '../../assets/img/anacoach/editar1.png';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import { arraySessionsRutines } from '../../Helpers';
import ModalDiv from '../../components/common/ModalDiv';
import Recommendations from '../../components/Training/RecommendedTraining';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import ShareDebts from '../Training/SectionShareDebts';
import imgWhats from '../../assets/img/anacoach/whats.png';
import imgPhone from '../../assets/img/anacoach/phone.png';
import imgMail from '../../assets/img/anacoach/mail.png';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationDebts from '../../components/common/SendNotificationDebts';

const DeudasInformales = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const { handleSendNotification } = SendNotificationDebts();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const history = useHistory();
  const anchop = window.screen.width;
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editNecesidad, setEditNecesidad] = useState('');
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [earnExtraMoney, setEarnExtraMoney] = useState('');
  const [improveSavings, setImproveSavings] = useState('');
  const [loanOpportinities, setLoanOpportunities] = useState('');
  const [need, setNeed] = useState(sessionStorage.getItem('Necesidades') || '');

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
    switch (step) {
      case 3:
        gsap.to(window, { duration: 1, scrollTo: '#activeRecomendation' });
        setEditNecesidad(true);
        if (value === '¿Te gustaría ganar dinero extra para saldar deudas?') {
          setEarnExtraMoney(value);
        } else if (value === '¿Te gustaría mejorar tus hábitos de ahorro?') {
          setImproveSavings(value);
        } else if (
          value
          === '¿Te gustaría tener acceso a oportunidades de préstamo más seguras y con menor tasa de interés ?'
        ) {
          setLoanOpportunities(value);
        }
        setNeed(value);
        if (nextOrderRoutine !== 'rp') {
          setOrderRutine(nextOrderRoutine);
        }

        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              if (nextOrderRoutine !== 'rp') {
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setNeed(sessionStorage.getItem('Necesidades') || '');
      // setForm({...form, directionMaps: "" });
      const needUser = sessionStorage.getItem('Necesidades') || '';
      if (
        needUser === '¿Te gustaría ganar dinero extra para saldar deudas?'
        || needUser === 'Ganar dinero extra'
      ) {
        setEarnExtraMoney(needUser);
      } else if (
        needUser === '¿Te gustaría mejorar tus hábitos de ahorro?'
        || needUser === 'Mejorar habitos de ahorro'
      ) {
        setImproveSavings(needUser);
      } else if (
        needUser
          === '¿Te gustaría tener acceso a oportunidades de préstamo más seguras y con menor tasa de interés ?'
        || needUser === 'Oportunidad de prestamos'
      ) {
        setLoanOpportunities(needUser);
      }

      if (needUser !== '') {
        if (nextOrderRoutine !== 'rp') {
          setOrderRutine(nextOrderRoutine);
        }
      }
    }
  }, [recoveryRoutine]);
  const irWorkout = (wo) => {
    const click = `IniciarEntrenamiento_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    if (wo !== '') {
      history.push(wo);
    }
  };
  const [show, setShow] = useState(false);
  const showModal = () => {
    const click = `Contactenme_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };

  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const showModalWhatsapp = () => {
    setShowWhatsapp(true);
  };
  const handleCloseModalWhatsapp = () => {
    setShowWhatsapp(false);
  };

  const [showEmail, setShowEmail] = useState(false);
  const showModalEmail = () => {
    setShowEmail(true);
  };
  const handleCloseModalEmail = () => {
    setShowEmail(false);
  };

  const [showPhone, setShowPhone] = useState(false);
  const showModalPhone = () => {
    setShowPhone(true);
  };
  const handleCloseModalPhone = () => {
    setShowPhone(false);
  };
  const handleChangeIcon = (json) => {
    setEditNecesidad(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (need === ''
          || (lapiz[0].routine === 4 && lapiz[0].active === 1))
        && editNecesidad !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeDeudaPalabra' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeDeudaPalabra"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        textbold="¡Importante!"
        text="Recuerda que aún en las deudas de palabra es importante firmar un acuerdo que señale
            al menos el nombre completo de los interesados, el monto a deber y cuánto se estima pagar."
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="¡He encontrado tres soluciones ideales para ti!
            Elige la opción que mejor se acomode a tus necesidades."
        hora={hora}
      />
      {/* -----------
        First question
        -------------*/}

      <TrainingQuestion
        className={
          (need === ''
            || (lapiz[0].routine === 4 && lapiz[0].active === 1))
          && editNecesidad !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="3-3"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeRecomendation"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={need !== '' ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 1 })}
        />
        <CardTwo
          classCard={
            need !== ''
            && (editNecesidad
              || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={need}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {need !== ''
      && (editNecesidad
        || !(lapiz[0].routine === 4 && lapiz[0].active === 1)) ? (
        <Fragment>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={earnExtraMoney !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            textbold=""
            text="Realiza el entrenamiento para aumentar tus ingresos. Ahí encontrarás toda la información de plataformas digitales y otras opciones de empleo temporal, para generar ingresos extra en estos tiempos difíciles."
            hora={hora}
          />
          <TrainingQuestion
            className={earnExtraMoney !== '' ? 'center' : 'hidden'}
          >
            <div style={{ display: 'flex' }}>
              <div className="startTraining d-flex flex-column">
                <CardsBtn1
                  windthmd="100%"
                  onClick={() => {
                    irWorkout('/wo/extra-income');
                    handleSendNotification({step: '5'});
                  }}
                >
                  Iniciar entrenamiento
                </CardsBtn1>
                <CardsBtn
                  color="#EC407A"
                  back="#ffffff"
                  border="1px solid #EC407A "
                  type="button"
                  min_w="100%"
                  windthLg="60%"
                  mleftweb="18%"
                  mtopw="19px"
                  mtop="19px"
                  onClick={(e) => {
                    showModal(e);
                  }}
                >
                  Contáctenme
                </CardsBtn>
              </div>
            </div>
          </TrainingQuestion>
          <TrainingDivSection
            className={improveSavings !== '' ? '' : 'hidden'}
            bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
            mt="44px"
            mtw="225px"
            br="4px"
            padding="21px 20px"
            wi="335px"
            wiw="670px"
            he="auto"
            hew="auto"
            mb="66px"
          >
            <div className="triangulo-equilatero-bottom"></div>
            <div className="closeDiv" onClick="">
              ×
            </div>
            <TrainingSubtitles
              size="20px"
              fw="600"
              align="left"
              alignweb="left"
              className="cabin"
            ></TrainingSubtitles>
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              Aprende un plan rápido para ahorrar dinero y mantener tus deudas
              bajo control. Tener un ahorro que te respalde en dificultades es
              una garantía de tranquilidad en tu vida.Lo ideal es tener dinero
              equivalente a 3 meses de sueldo ahorrado para imprevistos. ¿Eres
              un gastador? Aún así puedes ahorrar, mira:
            </TrainingText>
            <div className="embed-responsive embed-responsive-4by3">
              <PlayYoutube
                idVideo={'DdJ6XwJMUr4'}
                width={'300'}
                height={'190'}
                close={false}
              />
            </div>
            <br />
            <div className="center">
              <CardsBtn
                border="1px solid #F64282"
                back="#fff"
                color="#F64282"
                max_w="250px"
                min_w="100%"
                onClick={(e) => {
                  showModal(e);
                }}
              >
                Contáctenme
              </CardsBtn>
              <br />
            </div>
          </TrainingDivSection>

          <CardOne
            classCard={loanOpportinities !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            textbold=""
            text="Realiza los workouts de préstamos personales y tarjetas de crédito que te ayudarán a elegir el mejor crédito a tu medida y a la mejor tasa de interés."
            hora={hora}
          />
          <TrainingQuestion
            mt="0"
            className={loanOpportinities !== '' ? 'center' : 'hidden'}
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width="90%"
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <CardsBtn1
                onClick={() => {
                  irWorkout('/wo/card-funnel');
                  handleSendNotification({step: '6'});
                }}
              >
                Iniciar entrenamiento de <br />
                Tarjeta de crédito
              </CardsBtn1>
              <CardsBtn1
                onClick={() => {
                  irWorkout('/wo/loans-funnel');
                  handleSendNotification({step: '6'});
                }}
              >
                Iniciar entrenamiento de <br />
                Préstamos personales
              </CardsBtn1>
              <CardsBtn1
                border="1px solid #F64282"
                back="#fff"
                color="#F64282"
                min_w="100%"
                max_w="250px"
                onClick={(e) => {
                  showModal(e);
                }}
              >
                Contáctenme
              </CardsBtn1>
            </QuestionsDivs>
          </TrainingQuestion>
          {recomendaciones && need !== '' ? (
            <Feed_Main width="100%" background=" ">
              <Feed_Main
                width="100%"
                display=" "
                mtop=" "
                background="transparent"
              >
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text="¿Aún quieres ir por más?"
                  text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                />
                <CardOne
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  classCard="cardOne"
                  classTop="topOne1"
                  text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                />
                {
                  <Feed_Main width="100%" widthxs="100%" background=" ">
                    <Feed_Main
                      width="100%"
                      display=" "
                      mtop=" "
                      background="transparent"
                    >
                      <Feed_Section padding="0 0 0 0" paddingxs="0 0 0 0">
                        <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                          <Recommendations
                            items={recommendedTraining}
                          ></Recommendations>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Feed_Main>
                  </Feed_Main>
                }
              </Feed_Main>
            </Feed_Main>
          ) : null}
          <ShareDebts></ShareDebts>
        </Fragment>
        ) : null}
      {/* MODALES */}
      <ModalDiv show={show} handleClose={handleCloseModal}>
        <TrainingSubtitles>{`¡Hola ${sessionStorage.getItem(
          'FirstName',
        )}!`}</TrainingSubtitles>
        <TrainingText
          size="12px"
          className="cabin"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          ¿Nos puedes indicar por qué medio te gustaría que nos pusieramos en
          contacto contigo?
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalWhatsapp(e);
            }}
          >
            <Images
              src={imgWhats}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            WhatsApp
          </span>
        </TrainingText>
        <hr />
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalEmail(e);
            }}
          >
            <Images
              src={imgMail}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            E-Mail
          </span>
        </TrainingText>
        <hr />
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalPhone(e);
            }}
          >
            <Images
              src={imgPhone}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            Teléfono
          </span>
        </TrainingText>
      </ModalDiv>

      <ModalDiv show={showWhatsapp} handleClose={handleCloseModalWhatsapp}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="35%"
          leftw="35%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showWhatsapp} handleClose={handleCloseModalWhatsapp}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="35%"
          leftw="35%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showEmail} handleClose={handleCloseModalEmail}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="35%"
          leftw="35%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Dejános tu E-mail, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showPhone} handleClose={handleCloseModalPhone}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="35%"
          leftw="35%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
    </div>
  );
};

export default DeudasInformales;
