import React, {
  Fragment, useEffect, useState, useContext,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import '../../assets/styles/Home.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import enproceso from '../../assets/img/anacoach/faces-02.png';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import BarFilterBank from '../../components/common/BarFilterBank';
import ResultProduct from '../../components/common/ResultProduct';
import ProductsHooks from '../../Helpers/HookHelpersProducts';
import {
  CREATE_OPPORTUNITIES,
  DYNAMIC_GET,
  UPDATE_STATUS_TRAINING_SUCCESS,
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingSectionFlex,
  TrainingQuestion,
  DivLoader,
  TrainigTam,
} from '../../assets/styles/Training.styled';
import {
  SeparateSection,
  Feed_Main,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import Header from '../../components/common/Header';
import CardOne from '../../components/Home/Card1Animate';
import ModalConSinPremio from '../../components/Feed/ModalConSinPremio';
import ClickOutRp from '../../components/rp/ClickOutRp';
import Scrapper from './scrapper';
import ClickButton from '../../Helpers/HookHelpers';
import WOlayout from '../../components/layouts/WOlayout';
import Resultados from '../../components/Training/Success_Result';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';
import InfoWoMonetizable from '../../components/common/InfoWoMonetizable';

export const productsContext = React.createContext();

const ScrapperProccess = () => {
  const ctx = useContext(productsContext);
  const selectProduct = ctx.resultadosItems;

  if (selectProduct !== 0 && ctx.intent === 1) {
    console.log('selectProduct', selectProduct);
    const content = [];
    ctx.setIntent(2);
    for (const index in selectProduct) {
      content.push(
        <Scrapper
          key={index}
          lenProduct={selectProduct.length}
          product={selectProduct[index]}
          numcard={index}
          setHiddenLoader={ctx.setHiddenLoader}
        ></Scrapper>,
      );
      ctx.setResuestasScrapper(content);
    }

    return null;
  }
  return null;
};

function SuccessCompare() {
  const { sendApi } = ProductsHooks();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [respuestasScrapper, setResuestasScrapper] = useState([]);
  const [intent, setIntent] = useState(1);
  const [hiddenLoader, setHiddenLoader] = useState(true);
  const [recommendedTrainingForModal, setRecommendedTrainingForModal] = useState({});
  const [goFeed] = useState(false);
  const history = useHistory();
  let totalSol = '';
  let selectProduct = [];
  const [resultadosItems, setResultadosItems] = useState([]);
  const oneResult = [];
  const dateNow = new Date();
  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';
  firstname = sessionStorage.getItem('FirstName');
  nameClient = `${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem(
    'FathersLastName',
  )}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');

  const resultsProducts = [];
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const positionRappi = (arrayIdProducts) => {
    const rappi = arrayIdProducts.find((product) => product === '297');
    if (rappi !== undefined) {
      const sindex = arrayIdProducts.indexOf(rappi);
      arrayIdProducts.splice(sindex, 1);
      arrayIdProducts.unshift(rappi);
    }
    return arrayIdProducts;
  };

  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
    const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('numIntentoScrapper');
  }, []);

  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
    const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
  }, [goFeed]);

  sessionStorage.setItem('clickEmpezarAhoraHome', 'true');
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'SuccessCC',
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useEffect(() => {

  /*  if (
      (sessionStorage.getItem('CatProductos') != null
        || sessionStorage.getItem('CatProductosTemp') != null)
      && sessionStorage.getItem('datProducts') != null
    ) {
      const jsonProducts = JSON.parse(
        sessionStorage.getItem('CatProductos')
          || sessionStorage.getItem('CatProductosTemp'),
      );
      for (let j = 0; j < jsonProducts.length; j++) {
        jsonProducts[j].productDetail.response = JSON.parse(jsonProducts[j].productDetail.response);
      }
      console.log("Pruebaaaaa", jsonProducts );
      const idsOpportunities = sessionStorage
        .getItem('datOpportunity')
        .split(',');
      totalSol = idsOpportunities.length;
      let arrayIdProducts;

      const tempSessionData = sessionStorage.getItem('datProducts');

      if (tempSessionData.trim() === '' || tempSessionData.length === 0) {
        const tempArr = [];
        let tempDta = sessionStorage.getItem('CatProductos')
          || sessionStorage.getItem('CatProductosTemp');
        let allGood = true;
        try {
          tempDta = JSON.parse(tempDta);
        } catch (e) {
          allGood = false;
        }
        if (!allGood) return;
        tempDta.forEach((p) => tempArr.push(p.IDProduct.toString()));
        arrayIdProducts = tempArr;
      } else {
        arrayIdProducts = sessionStorage.getItem('datProducts').split(',');
      }

      //! Posicionar Rappi en primer lugar
      for (let i = 0; i < arrayIdProducts.length; i++) {
        for (let j = 0; j < jsonProducts.length; j++) {
          //! Mostrar modal Rappi
          // arrayIdProducts[i] == '297' && setShowModalRappi(true);
          if (
            parseInt(jsonProducts[j].IDProduct) === parseInt(arrayIdProducts[i])
          ) {
            console.log(jsonProducts[j].IDProduct, arrayIdProducts[i]);
            jsonProducts[j].idOpportunity = idsOpportunities[i];
            selectProduct.push(jsonProducts[j]);
          }
          // });
        }
      }

      setHiddenLoader(false);
      console.log(`selectProduct ---->${JSON.stringify(selectProduct)}`);
      setResultadosItems(getResultsItems(selectProduct));

      console.log('resultadosItems', resultadosItems);
    } else {
      selectProduct = [
        {
          IDProduct: 2,
          Name: 'The Gold Elite Credit Card American Express',
          ImageURL:
            'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/GoldEliteAMEX.png',
        },
      ];

      setResultadosItems(getResultsItems(selectProduct));
      console.log('resultadosItems', resultadosItems);
    }*/
    let tmpProducts = JSON.parse(sessionStorage.getItem('productsIdealCard'))
    let products = []
    for(let element of tmpProducts){
      let iva = '-'
      for(let benef of JSON.parse(element.benefit)['extra']){
        if(benef.includes('Interés anual')){
          let valid = benef.includes('Interés anual')
          let iva_str = benef.split(' ')[2].replace(/\s/g,'')
          iva = (parseFloat(iva_str)*100).toFixed(2)
        }
      }
      products.push({
        id: element.Id,
        imgCard: element.CardImage,
        imgresult: element.CardImage,
        titleCard: element.CardName,
        txtadicional: 'Solicitud en proceso. Ya realizamos esta solicitud con el proveedor, espera información en tu correo electrónico.',
        CAT: (parseFloat(JSON.parse(element.benefit)['extra'][0].split(' ')[1].replace(/\s/g,''))*100).toFixed(2),
        IVA: iva,
        DescriptionProduct: element.DescriptionProduct,
        hasClickOut: true,
        ClickOut: element.ClickOut
      })
    }
    setResultadosItems(products);
  }, []);

  const getResultsItems = (selectProduct) => {
    console.log(`getResultItems ---->${JSON.stringify(selectProduct)}`);
    const resultItems = [];
    if (selectProduct.length === 3) {
      for (let i = 0; i < selectProduct.length; i++) {
        resultItems.push({
          idOpportunity: selectProduct[i].idOpportunity,
          Name: selectProduct[i].Name,
          BusinessAbr: selectProduct[i].BusinessAbr,
          id: selectProduct[i].IDProduct,
          imgCard: selectProduct[i].ImageURL,
          titleCard: selectProduct[i].Name,
          ClickOut: selectProduct[i].ClickOut,
          hasClickOut: selectProduct[i].hasClickOut,
          DescriptionProduct: selectProduct[i].DescriptionProduct,
          CAT: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === 'CAT') {
              return ((detalle.Description * 100).toFixed(2));
            }
          }), 
          IVA: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === 'Interés anual') {
              return ((detalle.Description * 100).toFixed(2));
            }
          }),
          imgresult: enproceso,
          resultado: 'En proceso',
          msgresultado: '¡Espera! Seguimos trabajando',
          ScrapperHabilitado: selectProduct[i].Enabled,
          info: `${'Fecha de solicitud  ' + ' '}${dateNow.getDate()}/${
            dateNow.getMonth() + 1
          }/${dateNow.getFullYear()}`,
          txtadicional:
            selectProduct[i].IDProduct === 311
              ? 'Estás muy cerca de obtener tu tarjeta BBVA, para terminar tu solicitud ingresa aquí.'
              : 'Solicitud en proceso. Ya realizamos esta solicitud con el proveedor, espera información en tu correo electrónico.',
          documentos: '',
        });
      }
    } else {
      for (let i = 0; i < selectProduct.length; i++) {
        resultItems.push({
          idOpportunity: selectProduct[i].idOpportunity || '',
          Name: selectProduct[i].Name || '',
          BusinessAbr: selectProduct[i].BusinessAbr || '',
          id: selectProduct[i].IDProduct,
          imgCard: selectProduct[i].ImageURL,
          titleCard: selectProduct[i].Name,
          ClickOut: selectProduct[i].ClickOut,
          hasClickOut: selectProduct[i].hasClickOut,
          DescriptionProduct: selectProduct[i].DescriptionProduct,
          CAT: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === 'CAT') {
              return ((detalle.Description * 100).toFixed(2));
            }
          }), 
          IVA: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === 'Interés anual') {
              return ((detalle.Description * 100).toFixed(2));
            }
          }),
          imgresult: enproceso,
          resultado: 'En proceso',
          msgresultado: '¡Espera! Seguimos trabajando',
          ScrapperHabilitado: selectProduct[i].Enabled,
          info: `${'Fecha de solicitud' + ' '}${dateNow.getDate()} / ${
            dateNow.getMonth() + 1
          } / ${dateNow.getFullYear()}`,
          txtadicional:
            selectProduct[i].IDProduct === 311
              ? 'Estás muy cerca de obtener tu tarjeta BBVA, para terminar tu solicitud ingresa aquí.'
              : 'Solicitud en proceso. Ya realizamos esta solicitud con el proveedor, espera información en tu correo electrónico.',
          documentos: '',
        });
      }
    }
    return resultItems;
  };

  useEffect(() => {
    if (sessionStorage.getItem('datOpportunity') !== null) {
      const opportunities = sessionStorage.getItem('datOpportunity').split(',');
      for (const i in opportunities) {
        const getProductsInput = {
          input: {
            table: 'DatOpportunity, CatProduct',
            columns: ['*'],
            conditions: [
              {
                valone: 'IDProduct',
                valtwo: 'CatProduct_IDProduct',
                condition: '=',
                validColumn: true,
              },
              {
                valone: 'IDDatOpportunity',
                valtwo: opportunities[i],
                condition: '=',
                logic: 'AND',
              },
            ],
          },
        };
        getOpportunity({ variables: getProductsInput });
      }
    } else {
      console.log('Sin data opportunity');
    }
  }, []);

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    const idTraining = sessionStorage.getItem('idTraining');
    const fromXCoach = sessionStorage.getItem('fromXCoach');
    const idAdviser = sessionStorage.getItem('idAdviser');
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === 'true' ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getOpportunity] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        console.log('dynamicGet', dynamicGet.response);
        if (dynamicGet.response !== '') {
          const productsDetails = JSON.parse(dynamicGet.response)[0];
          resultsProducts.push(productsDetails);
          console.log(productsDetails);
        }
      }
    },
  });

  const [updateStatusTrainingSuccess] = useMutation(
    UPDATE_STATUS_TRAINING_SUCCESS,
    {
      onCompleted({ updateStatusTrainingSuccess }) {
        if (updateStatusTrainingSuccess.statusCode === 200) {
          console.log('statusTraining', updateStatusTrainingSuccess.response);
        }
      },
    },
  );

  useEffect(() => {
    if (!hiddenLoader) {
      try {
        const datOpportunity = sessionStorage.getItem('datOpportunity');
        const idDatTraining = sessionStorage.getItem('IdDatTraining');
        const idUser = sessionStorage.getItem('idUser');
        const inputStatuFinal = {
          input: {
            datOpportunity,
            idDatTraining,
            idUser,
          },
        };
        if (datOpportunity && idDatTraining && idUser) {
          console.log('inputStatuFinal', inputStatuFinal);
          setTimeout(() => {
            updateStatusTrainingSuccess({ variables: inputStatuFinal });
          }, 4000);
        }
      } catch (e) {
        console.log('inputStatuFinal', e);
      }
    } else {
      console.log('no se oculta loader');
    }
  }, [hiddenLoader]);

  const [WoTraining, setWoTraining] = useState('');

  useEffect(() => {
    console.log(`share training: ${sessionStorage.getItem('idTraining')}`);
    if (
      sessionStorage.getItem('idTraining') === '1'
      || sessionStorage.getItem('idTraining') === '35'
    ) {
      setWoTraining('SolicitarTarjeta');
    } else if (sessionStorage.getItem('idTraining') === '3') {
      setWoTraining('TarjetaIdeal');
    }
  }, []);

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
          const filter = getPersonalizeRecommendationTrainings.response.filter(
            (e) => e.TrainingBigTitle === 'Protege a tu familia'
              || e.TrainingBigTitle === 'Adelgaza tus deudas',
          );
          setRecommendedTrainingForModal(filter);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const rpValid = sessionStorage.getItem('tipoRP') === 'RP_Tipo_1'
    ? 1
    : sessionStorage.getItem('tipoRP') === 'RP_Tipo_2'
      ? 2
      : 0;
  console.log('##rpValid>>>', rpValid);
  const [textSaludo] = useState('Hemos procesado tu solicitud y este es el resultado:');
  return (
    <WOlayout
      backgroundColor={'#917AFF'}
      pathname="/"
      headerType="HeaderTwo"
    >
      <TrainigTam>
        <Header
          background={'#917AFF'}
          pathname="/"
        />
      </TrainigTam>
      <ModalVirality />
      <HeaderSuccess
        color1="#E2C2EE"
        color2="#6677E3"
        color3="#6677E3"
        color4="#E2C2EE"
        color5="#8358C2"
        color6="#6677E3"
        color7="#A7FOFA"
        color8="#8358C2"
        color9="#CBBFED"
        color10="#6677E3"
      />
      <TrainingMain mt="100px" mtw="0" className="" id="top">
        {/* <ModalRappi show={showModalRappi} ></ModalRappi> */}
        <ModalAmazon show={true} wo="TarjetaIdeal"></ModalAmazon>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            paddiw="2% 10% 3% 10%"
            paddi="0% 5% 5% 5%"
            back="#fafafa"
          >
            <TrainingSubtitles
              size="22px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              ¡Gracias {sessionStorage.getItem('FirstName')}!
            </TrainingSubtitles>
            <TrainingText
              size="18px"
              weight="600"
              className="roboto"
              align="center"
              alignweb="center"
              ls="-0.2px"
            >
              Hemos procesado tu solicitud y este es el resultado:
            </TrainingText>
            <InfoWoMonetizable 
              nameClient={sessionStorage.getItem('FirstName') + ' ' + (sessionStorage.getItem('MiddleName') || '') + 
                ' ' + sessionStorage.getItem('FathersLastName') + ' '+ sessionStorage.getItem('MothersLastName')}
              telClient={sessionStorage.getItem('CellPhone') || ''}
              emailClient={sessionStorage.getItem('Email1') || ''}
            />
          </TrainingDivSection2>
          <SeparateSection />
        </TrainingSectionGray>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection
            mt="20px"
            back="#fafafa"
            backw="#fafafa"
            wi="90%"
            wiw="90%"
            padding="0"
            paddw="0"
          >
            <TrainingSectionFlex
              display="flex"
              fdirection='column'
              aItems='center'
              className={
                sessionStorage.getItem('idTraining') === '3'
                  ? 'counstColCC1'
                  : 'counstColCC'
              }
            >
              <div>
                <BarFilterBank
                    key={'2'}
                    text={'Tu tarjeta actual'}
                    disableBtn
                >
                    <div className = "d-flex justify-content-center">
                      <ResultProduct
                        card={JSON.parse(localStorage.getItem('hasCardSelected'))}
                        showButton={false}
                        ClickButton={() =>{console.log('ok')}}
                      />
                    </div>
                </BarFilterBank>
                <BarFilterBank disableBtn text = "Solicitaste">
                    <Resultados
                      idTraining={sessionStorage.getItem('idTraining')}
                      resultados={resultadosItems}
                    ></Resultados>
                </BarFilterBank>
              </div>
            </TrainingSectionFlex>
          </TrainingDivSection>
        </TrainingSectionGray>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            wiw="670px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            <SectionShare WO="TarjetaIdeal" isCompareCardSuccess></SectionShare>
          </TrainingDivSection2>
        </TrainingSectionGray>
      </TrainingMain>
    </WOlayout>
  );
}

export default SuccessCompare;
