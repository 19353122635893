/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';

const SonData = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [Rutine, setRutine] = useState({});
  const [name, setName] = useState(false);
  const [cellPhone, setCellPhone] = useState(false);
  const [age, setAge] = useState(sessionStorage.getItem('EdadHijo') || '');
  const [form, setForm] = useState({
    nombreHijo: sessionStorage.getItem('nombreHijo') || '',
    telefonoHijo: sessionStorage.getItem('telefonoHijo') || '',
  });

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  const handleChangeRutine = (data) => {
    console.log('Rutine==== ', data);
    setRutine(data);
  };

  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 1:
        setAge(val.value);
        break;
      default:
        console.log(val);
    }
  };

  const handleAge = (e) => {
    const click = `ContinuarEdades_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    questions.push({
      id: 'nombreHijo',
      question: 1,
      value: form.nombreHijo,
    });
    sessionStorage.setItem('auxRutine', JSON.stringify(Rutine));
    createJsonInput(Rutine, questions);
    setQuestions([]);
    setName(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeChildrenAges' });
  };

  const handleCellPhone = (e) => {
    e.preventDefault();
    questions.push({
      id: 'telefonoHijo',
      question: 3,
      value: form.telefonoHijo,
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
    setCellPhone(true);
    setOrderRutine(nextOrderRoutine);
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        nombreHijo: sessionStorage.getItem('nombreHijo') || '',
        telefonoHijo: sessionStorage.getItem('telefonoHijo') || '',
      });
      sessionStorage.getItem('nombreHijo') ? setName(true) : setName(false);
      sessionStorage.getItem('telefonoHijo')
        ? setCellPhone(true)
        : setCellPhone(false);
      setAge(sessionStorage.getItem('EdadHijo') || '');
    }
  }, [recoveryRoutine]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && !name) {
        gsap.to(window, { duration: 1, scrollTo: '#activeSonData' });
        setReviewScroll(false);
      }
    }, 1400);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeSonData"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="¡Ahora sí, comencemos!"
        />
        <QuestionsOrder
          classCard={'cardOne'}
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion wiw="60%" mLg="18%" className={!name ? '' : 'hidden'}>
          <TrainingSectionGray style={{ padding: '5%' }}>
            <QuestionsFlex display="inline">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                labelInput="Nombre"
              />
            </QuestionsFlex>
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn
                min_web="100%"
                mtopw="0px"
                windthmd="100%"
                onClick={(e) => handleAge(e)}
              >
                Continuar
              </CardsBtn>
            </div>
          </TrainingSectionGray>
        </TrainingQuestion>

        <div id="activeChildrenAges"></div>
        <CardTwo
          classCard={name ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={form.nombreHijo}
          colorTraining={trainingColor}
        />
        <QuestionsOrder
          classCard={name ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion className={name && age === '' ? '' : 'hidden'}>
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={age !== '' ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={age}
          colorTraining={trainingColor}
        />

        <QuestionsOrder
          classCard={age !== '' ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion
          wiw="60%"
          mLg="18%"
          className={age !== '' && !cellPhone ? '' : 'hidden'}
        >
          <TrainingSectionGray style={{ padding: '5%' }}>
            <QuestionsFlex display="inline">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="2-2"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                labelInput="Teléfono celular"
              />
            </QuestionsFlex>
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn
                onClick={(e) => handleCellPhone(e)}
                min_web="100%"
                mtopw="0px"
              >
                Continuar
              </CardsBtn>
            </div>
          </TrainingSectionGray>
        </TrainingQuestion>
        <CardTwo
          classCard={cellPhone ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={form.telefonoHijo}
          colorTraining={trainingColor}
        />
      </div>
  );
};

export default SonData;
