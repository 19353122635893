import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  ImageSections,
  TrainingText,
  DivStyle,
  DivFlex,
  Resultado,
  CardsBtn,
} from "../../assets/styles/Training.styled";

//Validación acreditado mock
const mock = false;

function CreaditeaSuccesPL({ ingreso }) {
  // const [checked, setChecked] = useState(false)
  const [mockCheck, setMockCheck] = useState(true);
  function validate() {
    if (document.getElementById("continueCheck").checked) {
      alert("Checked");
    }
  }

  let monto = "";

  if (ingreso == "menos_4000") {
    monto = "$4,000";
  }
  if (ingreso == "de_4000_a_7000") {
    monto = "$5,000";
  }
  if (ingreso == "de_7000_a_15000") {
    monto = "$10,000";
  }
  if (ingreso == "de_15000_a_30000") {
    monto = "$22,000";
  }
  if (ingreso == "mas_de_30000") {
    monto = "$30,000";
  }

  console.log(monto);
  return (
    <>
      <Resultado
        MinWidth="345px"
        wid="100%"
        marginGene="0 0 0 0"
        widthLg="65%"
        marTopD="10px"
        mbot="10px"
        ta="left"
        padd="20px"
        className="Roboto"
        minh="215px"
        minhweb="215px"
        maxwidthLg="375px"
        bcolor="#FFFFFF"
        style={{ maxWidth: "375px" }}
      >
        <DivFlex justify="space-between">
          <div>
            <ImageSections
              width="70px"
              widthweb="70px"
              // Modificar  src-------------------------------------------
              className="img-fluid"
              alt={"imagen"}
            />
            <br />
            <DivStyle
              align="left"
              weight="bold"
              mt="20px"
              bott="10px"
              className="roboto"
              size="18px"
              line="100%"
            >
              Creditea
            </DivStyle>
          </div>
        </DivFlex>
        {mock ? (
          <>
            <TrainingText
              className="roboto"
              weight="400"
              align="left"
              mheight="58px"
            >
              <ul>
                <li>
                  <strong style={{ fontWeight: "700" }}>Monto: </strong>
                  {monto}
                </li>
                <li>
                  <strong style={{ fontWeight: "700" }}>Plazo: </strong>Plazo
                </li>
                <li>
                  <strong style={{ fontWeight: "700" }}>
                    Tasa de interés:{" "}
                  </strong>
                </li>
                <li>
                  <strong style={{ fontWeight: "700" }}>
                    Pago quincenal:{" "}
                  </strong>
                </li>
              </ul>
              <br />
              <div>
                Este es un crédito pre-aprobado. <br />
                Para continuar con tu solicitud, aprueba los términos y
                condiciones de Creditea.
              </div>
            </TrainingText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Checkbox
                type="checkbox"
                id="continueCheck"
                checked={mockCheck}
                onClick={() => setMockCheck(!mockCheck)}
              ></Checkbox>
              <TrainingText weight="400px">
                Sí he leído y acepto los{" "}
                <a style={{ color: "rgba(246, 66, 130, 1)" }}>términos</a> y{" "}
                <a style={{ color: "rgba(246, 66, 130, 1)" }}>condiciones</a> de
                Creditea
              </TrainingText>
            </div>

            <CardsBtn
              color="#FFFFFF"
              border="solid"
              bordcolor="#F64282"
              type="button"
              windthG="100%"
              style={{
                fontFamily: "'Roboto', sans-serif !important",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "140%",

                textAlign: "center",
                letterSpacing: "-0.3px",
              }}
              onClick={() => validate()}
            >
              Solicitar
            </CardsBtn>
          </>
        ) : (
          <>
            <TrainingText
              className="roboto"
              weight="400"
              align="left"
              mheight="58px"
              top="20px"
            >
              Debido a tu Score Crediticio, tu crédito personal no fue
              preaprobado, pero hay más opciones para tí.
            </TrainingText>
            <CardsBtn
              color="#FFFFFF"
              border="solid"
              bordcolor="#F64282"
              type="button"
              windthG="100%"
              style={{
                fontFamily: "'Roboto', sans-serif !important",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "140%",

                textAlign: "center",
                letterSpacing: "-0.3px",
              }}
            >
              Ver otras opciones de créditos personales
            </CardsBtn>
          </>
        )}
      </Resultado>
    </>
  );
}

export default CreaditeaSuccesPL;
