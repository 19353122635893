import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Dailymotion from 'react-dailymotion';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '700px',
    backgroundColor: '#FFFFFF',
  },
});

const OptionsMessage = () => {
  const classes = useStyles();
  return (
    <Fragment>
    <div className={classes.root}>
        <CardContent>
          <Dailymotion
           video="k6x3JvbdrRhruSwTrnD"
           width="100%"
           height= "670px"
            autoplay="1"
           controls="false"
           mute="false"
           origin="https://www.dailymotion.com/embed/video/k6x3JvbdrRhruSwTrnD?autoplay=1"
           />
        </CardContent>
      </div>

    </Fragment>
  );
};

export default OptionsMessage;
