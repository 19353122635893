/* eslint-disable react/jsx-key */
import React, { Fragment } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CancelIconOutlined from '@material-ui/icons/CancelOutlined';
import {
  DivFlex,
  TrainingText,
  BorderTask,
  StatusTask,
} from '../../assets/styles/Training.styled';

function TasksStatus({ datos }) {
  return (
    <Fragment>
        {datos.map((post) => (
            <DivFlex widthweb="70%" marweb="auto">
                <BorderTask>
                    <TrainingText size="18px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                        {post.taskName}
                    </TrainingText>
                    <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px">
                        {post.taskTime}
                    </TrainingText>
                    <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px">
                        {post.taskInfo}
                    </TrainingText>
                </BorderTask>

                    {post.taskStatus === 'Incomplete'
                      ? <StatusTask bColor="#FF0000">
                            <CancelIconOutlined style={{ color: '#FF0000' }} />
                        </StatusTask>
                      : <StatusTask bColor="#27AE60">
                            <DoneIcon style={{ color: '#27AE60' }} />
                        </StatusTask>
                    }

            </DivFlex>
        ))
        }
    </Fragment>
  );
}
export default TasksStatus;
