/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-debugger */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import True from '../../assets/img/True.svg';
import es from "react-phone-input-2/lang/es.json";
import validator from "validator";
import startsWith from "lodash.startswith";
// import startsWith from 'lodash.startswith';
import {
  SubtitleOne,
  Container,
  Col12,
  Image,
} from "../../components/common/common.styled";
import "../../assets/styles/TextCustom.css";
import InputAna from "../../components/common/InputAna";
import { GET_CONFIG_OTP, GET_COUNTRY_CODES } from "../../queries";
import { useMutation, useQuery } from "@apollo/client";
import { SEND_OTP, CREATE_CLICK } from "../../mutations";
import TagManager from "react-gtm-module";
import { set } from "react-ga";
import { Form } from "react-bootstrap";
import InputAnaIcon from "../../components/common/InputAnaIcon";
import { Input } from "../../assets/styles/Training.styled";

// const validator = '';

const Contacto = ({
  form, setForm, validCampos, setvalidCampos
}) => {
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [TypeCorreo, SetTypeCorreo] = useState({
    text: "",
    display: "",
    text_correct: "",
  });

  const [error] = useState({
    email: false,
    emailvalid: -1,
    numPhone: false,
    numPhonevalid: -1,
  });

  const [texterror] = useState({
    email: "",
    numPhone: "",
  });

  const [lleno, setlleno] = useState(false);
  const { data: dataOtp } = useQuery(GET_CONFIG_OTP);
  const { data: dataLadas } = useQuery(GET_COUNTRY_CODES);

  const [emailError, setEmailError] = useState("");
  const [message, setmessage] = useState("");
  const [valphone, setvalphone] = useState(null);
  const [valEmail, setvalEmail] = useState(null);
  const [visibleIcon, setvisibleIcon] = useState(false);
  const [visibleIconPhone, setvisibleIconPhone] = useState(true);

  const validateEmail = (e) => {
    const email = e.target.value;
    sessionStorage.setItem('Email1', email);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    if (validator.isEmail(email)) {
      setEmailError("valid Email");
      setmessage("");
      setvalEmail(email);
      setvisibleIcon(true);
    } else {
      setEmailError("Enter valid Email!");
      setmessage("Enter valid Email!");
      setvalEmail(email);
      setvisibleIcon(false);
    }
  };
  const validatePhone = (e) => {
    sessionStorage.setItem('CellPhone', e);
    setForm({
      ...form,
      numPhone: e,
    });
    setvalphone(e);
  };
  useEffect(() => {
    if (valphone !== null && valphone.length === 12 && message === "") {
      setvalidCampos({
        ...validCampos,
        contacto: true,
      });
      if (valphone.length === 12) {
        setvisibleIconPhone(true);
      }
    } else {
      setvalidCampos({
        ...validCampos,
        contacto: false,
      });
      setvisibleIconPhone(false);
    }
  }, [valphone, valEmail]);

  const ValidNumero = (e) => {
    e.persist();
    if (e.target.name === "numPhone") {
      setForm({
        ...form,
        numPhone: e.target.value.replace(/[^0-9]/g, ""),
      });
      if (
        (e.target.value.length < 11 && e.target.value.length !== 10)
        || (e.target.name && !/^[0-9]+$/i.test(e.target.value))
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        texterror.numPhone = "Por favor verifica tu número telefónico";
        error.numPhonevalid = 1;
        error.numPhone = false;

        // (".MuiInput-underline").addClass("MuiInput-underline-error");
      } else if (e.target.value.length < 11 && e.target.value.length === 10) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        sessionStorage.setItem(
          "cellPhone",
          e.target.value.replace(/[^0-9]/g, "")
        );
        error.numPhone = "";
        error.numPhonevalid = 0;
      }
    } else {
      setForm({
        ...form,
        lada: e.target.value,
        textlada: e.target.value,
      });
      const indexLada = e.target.value.indexOf("+(");
      const ladaselect = e.target.value.substring(indexLada);
      sessionStorage.setItem("ladauser", ladaselect);
    }
    ValidCampos();
  };

  const ValidCampos = () => {
    debugger;
    if (
      error.email == false
      && error.emailvalid === 0
      && error.numPhone == false
      && form.numPhone.length >= 10
    ) {
      setvalidCampos({
        ...validCampos,
        contacto: true,
      });
    } else {
      setvalidCampos({
        ...validCampos,
        contacto: false,
      });
    }
  };

  const [sendOtp] = useMutation(SEND_OTP, {
    onCompleted({ sendOtp }) {
      sessionStorage.setItem("clickEmpezarAhoraStep2", true);
      sessionStorage.setItem("lada", sessionStorage.getItem("ladauser"));
      const tagManagerArgs = {
        dataLayer: {
          event: "clickIniciarSesionStep2",
        },
      };
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem("id_session"),
          column: "clickIniciarSesionStep2",
          collection: "sessions",
          timeStamp: timestamp,
        },
      };
      createClickFront({ variables: createClickInput });

      TagManager.dataLayer(tagManagerArgs);
      // history.push("/user-validation-otp/");
      // setMostrarLoad(false);
    },
  });

  // OpenLada
  const [openLada, setOpenLada] = useState(false);
  const handleCloseLada = () => {
    setOpenLada(false);
  };
  const handleOpenLada = () => {
    setOpenLada(true);
  };

  const [Ladas] = useState([
    {
      value: "",
      label: "México",
      lada: "",
    },
    {
      value: "Estados Unidos lada +(1)",
      label: "Estados Unidos",
      lada: "1",
    },
    {
      value: "",
      label: "Brasil",
      lada: "",
    },
    {
      value: "",
      label: "Colombia",
      lada: "",
    },
    {
      value: "",
      label: "Chile",
      lada: "",
    },
  ]);

  return (
    <Fragment>
      <SubtitleOne
        className="font-weight-bold roboto"
        size="16px"
        sizeMobile="16px"
        mb="0px"
        mt="24px"
      >
        Datos de contacto
      </SubtitleOne>
      <form autoComplete="off" className="col-12" style={{ padding: '0px' }}>
        <Container width="100%" widthMobile="100%">
          <Col12 padding="0px" pt="2%">
            <InputAnaIcon
              placeholder="Correo electrónico"
              type="email"
              id="email"
              value={valEmail}
              handleChange={validateEmail}
              handleBlur={validateEmail}
              error={error.email}
              texterror={texterror.email}
              errorinicial={error.emailvalid}
              visibleIcon={visibleIcon}
            />
          </Col12>
          <Col12 padding="0px" pt="2%">
            <Input padding="0px" className="d-flex flex-row">
              <PhoneInput
                country="mx"
                countryCodeEditable={false}
                enableSearch
                containerStyle={{ width: "90%", padding: "0px", border: 'none' }}
                inputStyle={{
                  width: "95%",
                  backgroundColor: 'transparent',
                  height: "48px",
                  borderRadius: "16px",
                  border: "none"
                }}
                value={valphone}
                buttonStyle={{
                  borderRadius: "16px 0 0 16px",
                  backgroundColor: "white",
                  border: 'none'
                }}
                onChange={(e) => validatePhone(e)}
              />
              { visibleIconPhone ? <Image width="2.7%" widthMd="6.5%" marginRight="20px" style={{ width: '6.5%', marginRight: '22px' }} src={True} /> : null }
            </Input>
          </Col12>
          {/* <Col12 padding="0px 5px 0px 0px">
            <InputAna
              placeholder="+ 52"
              id="numPhone"
              type="tel"
              value={form.numPhone}
              handleChange={ValidNumero}
              handleBlur={ValidNumero}
              error={error.numPhone}
              texterror={texterror.numPhone}
              errorinicial={error.numPhonevalid}
              maxlength={"10"}
            />
          </Col12> */}
        </Container>
      </form>
    </Fragment>
  );
};

export default Contacto;
