/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import SectionVideo from '../../components/common/SectionVideo';
import editIcon from '../../assets/img/anacoach/editar1.png';
import {
  TrainingQuestion,
  QuestionsDivs,
  QuestionsFlex,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { CREATE_SESSION, UPDATE_HISTORY_COLLECTION } from '../../mutations';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';

const textInfo = [
  {
    text1:
      'Cambiar a una mejor tarjeta, conseguir un seguro más barato, sacar un crédito para tu negocio y más',
  },
  {
    text2:
      'Desde tu vida y salud o la de tus seres queridos, hasta blindar tu patrimonio',
  },
  {
    text3:
      '¿No quieres que tu dinero se desperdicie? Busquemos una forma de hacerlo rendir más',
  },
  {
    text4:
      '¿Vas al día con tus gastos? ¿Te aterran las deudas? La clave es cuidar tu presupuesto. Aprende cómo.',
  },
];
const UserProfiling = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  setDineroFinDeMes,
  haveDebts,
  setpreferencias,
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let arrayResponseRutine = [];
            for (const idRoutineR in routines) {
              const videoQ = {
                idQuestion: 121,
                idType: 190,
                questionName: 'https://www.youtube.com/embed/nVIKOQoDehM',
                questionOrder: 2,
                questionType: 'Video',
                responseValue: 'https://www.youtube.com/embed/nVIKOQoDehM',
              };
              arrayResponseRutine = routines[idRoutineR].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const questionVideo = routines[idRoutineR].questions[i];
                if (questionVideo.QuestionType === 'Video') {
                  arrayResponseRutine.splice(1, 0, videoQ);
                  break;
                }
              }
            }
            console.log('Nuevo array == ', arrayResponseRutine);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {

                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length + contVideos + 1
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length + contVideos + 1,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    console.log('Entro useEffect PROFILING');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [masterFinance, setMasterFinance] = useState(
    sessionStorage.getItem('ConocimientoFinanzas') || '',
  );
  const [skipVideo, setSkipVideo] = useState(
    sessionStorage.getItem('ConocimientoFinanzas') !== '' ? 'skip' : '',
  );
  const [monthlyIncome, setMonthlyIncome] = useState(
    sessionStorage.getItem('Ingreso_Mensual_Fijo') || '',
  );
  const [occupation, setOccupation] = useState(
    sessionStorage.getItem('Ocupacion2') || '',
  );
  const [insurance, setInsurance] = useState(
    sessionStorage.getItem('TieneSeguro') || '',
  );
  const [typeInsurance, setTypeInsurance] = useState([]);
  const [nextQuestion, setNextQuestion] = useState(false);
  const [creditCard, setCreditCard] = useState(
    sessionStorage.getItem('TienesTarjetaCredito') || '',
  );
  const [endMonth, setEndMonth] = useState(
    sessionStorage.getItem('FindeMes') || '',
  );
  const [financialPriorities, setFinancialPriorities] = useState(
    sessionStorage.getItem('PrioridadFinanzas') || '',
  );
  const [zipCode, setZipCode] = useState(
    sessionStorage.getItem('CodigoPostal2') || '',
  );
  const [clickOnTypeInsurance, setClickOnTypeInsurance] = useState(0);
  const [texterror] = useState({
    zipCode: '',
  });
  const [error] = useState({
    zipCode: false,
  });
  const [form, setForm] = useState({
    zipCode: '',
  });

  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    const value = e.target.value.substring(0, 5);
    switch (e.target.name) {
      case 'zipCode':
        setForm({
          ...form,
          [e.target.name]: value.substring(0, 5),
        });
        validDigitos(e.target.name, value, e.target.id);
        console.log('zipCode');
        break;
      default:
        console.log('handleChange default case');
        break;
    }
  };
  const validDigitos = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (value.length !== 5 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu código postal';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const [updateHistoryCollection, {}] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickVideoCollection, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const playVideo = (action) => {
    setSkipVideo('skip');
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickVideoYT',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const click = `ContinuarObjetivosVideo_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    if (sessionStorage.getItem('idClickVideo')) {
      const dataJson = [
        {
          nameTraining: sessionStorage.getItem('trainingName'),
          idUser: sessionStorage.getItem('idUser'),
          action,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem('idClickVideo'),
      });
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          key: 'ClickVideo',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idUser: sessionStorage.getItem('idUser'),
        idSession: sessionStorage.getItem('id_session'),
        ClickVideo: [
          {
            nameTraining: sessionStorage.getItem('trainingName'),
            idUser: sessionStorage.getItem('idUser'),
            action,
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          data: dataString,
        },
      };
      createClickVideoCollection({ variables: mongoInput });
    }
  };
  const history = useHistory();
  const handleChangeCp = (event) => {
    event.preventDefault();
    ScrollCuestion('Cp');
    if (form.zipCode.length === 5 && form.zipCode !== '') {
      const click = `ContinuarCP_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setZipCode(form.zipCode);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setpreferencias(sessionStorage.getItem('PrioridadFinanzas') || '');
      setOrderRutine(nextOrderRoutine);
      history.push('/wo/profiling/success');
    }
  };
  useEffect(() => {
    console.log('length tipo seguro: ', typeInsurance.length);
    console.log('tipo seguro: ', typeInsurance);
    console.log('Rutine: ', isEmptyObject(Rutine));
    let element;
    let auxOther;
    auxOther = document.getElementsByName('Otro')[0];
    if (auxOther !== undefined && auxOther !== 'undefined') {
      if (!auxOther.classList.contains('selectedBen')) {
        if (
          typeInsurance.includes('Seguro de vida')
          && clickOnTypeInsurance === 294
        ) {
          element = document.getElementsByName('Seguro de vida')[0];
          if (element !== undefined && element !== 'undefined') {
            if (!element.classList.contains('selectedBen')) {
              element.classList.add('selectedBen');
            } else {
              element.classList.remove('selectedBen');
              const deleted = typeInsurance.indexOf('Seguro de vida');
              typeInsurance.splice(deleted, 1);
            }
          }
          setClickOnTypeInsurance(0);
        }
        if (
          typeInsurance.includes('Seguro de auto')
          && clickOnTypeInsurance === 295
        ) {
          element = document.getElementsByName('Seguro de auto')[0];
          if (element !== undefined && element !== 'undefined') {
            if (!element.classList.contains('selectedBen')) {
              element.classList.add('selectedBen');
            } else {
              element.classList.remove('selectedBen');
              const deleted = typeInsurance.indexOf('Seguro de auto');
              typeInsurance.splice(deleted, 1);
            }
          }
          setClickOnTypeInsurance(0);
        }
        if (
          typeInsurance.includes('Seguro de salud privado')
          && clickOnTypeInsurance === 296
        ) {
          element = document.getElementsByName('Seguro de salud privado')[0];
          if (element !== undefined && element !== 'undefined') {
            if (!element.classList.contains('selectedBen')) {
              element.classList.add('selectedBen');
            } else {
              element.classList.remove('selectedBen');
              const deleted = typeInsurance.indexOf('Seguro de salud privado');
              typeInsurance.splice(deleted, 1);
            }
          }
          setClickOnTypeInsurance(0);
        }
      }
    }
    if (typeInsurance.includes('Otro') && clickOnTypeInsurance === 297) {
      element = document.getElementsByName('Otro')[0];
      if (element !== undefined && element !== 'undefined') {
        if (!element.classList.contains('selectedBen')) {
          element.classList.add('selectedBen');
          element = document.getElementsByName('Seguro de vida')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Seguro de auto')[0];
          element.classList.remove('selectedBen');
          element = document.getElementsByName('Seguro de salud privado')[0];
          element.classList.remove('selectedBen');
        } else {
          element.classList.remove('selectedBen');
          const deleted = typeInsurance.indexOf('Otro');
          typeInsurance.splice(deleted, 1);
        }
      }
    }
    setClickOnTypeInsurance(0);
  }, [typeInsurance, clickOnTypeInsurance]);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    setEditFinanzas(true);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOptionUSERPROFILLE====', val);
    console.log(val.step);
    switch (val.step) {
      case 0:
        setEditFinanzas(true);
        console.log('entro en el master finance');
        setMasterFinance(val.value);
        break;
      case 1:
        console.log('entro en el de video');
        break;
      case 2:
        console.log(val.step);
        setEditIngresos(true);
        setMonthlyIncome(val.value);
        break;
      case 3:
        setEditOcupacion(true);
        setOccupation(val.value);
        break;
      case 4:
        setEditSeguro(true);
        setInsurance(val.value);
        break;
      case 5:
        console.log('CASE 5', val.idField);

        switch (val.idField) {
          case 294:
            if (
              typeInsurance.indexOf(val.value) === -1
              && typeInsurance.indexOf('Otro') === -1
            ) { setTypeInsurance(typeInsurance.concat(val.value)); }
            setClickOnTypeInsurance(294);
            break;
          case 295:
            if (
              typeInsurance.indexOf(val.value) === -1
              && typeInsurance.indexOf('Otro') === -1
            ) { setTypeInsurance(typeInsurance.concat(val.value)); }
            setClickOnTypeInsurance(295);
            break;
          case 296:
            if (
              typeInsurance.indexOf(val.value) === -1
              && typeInsurance.indexOf('Otro') === -1
            ) { setTypeInsurance(typeInsurance.concat(val.value)); }
            setClickOnTypeInsurance(296);
            break;
          case 297:
            if (typeInsurance.indexOf(val.value) === -1) { setTypeInsurance([val.value]); }
            setClickOnTypeInsurance(297);
            break;
          default:
            console.log('default break handleChangeCatOption case 5');
            break;
        }
        break;
      case 6:
        setEditCreditCard(true);
        setCreditCard(val.value);
        break;
      case 7:
        setEditEndMonth(true);
        setEndMonth(val.value);
        break;
      case 8:
        setEditFinancialPriorities(true);
        setFinancialPriorities(val.value);
        break;
      case 10:
        // setLackMoney(val.value)
        // setDineroFinDeMes(val.value)
        break;
      case 11:
        // setDebts(val.value)
        // haveDebts(val.value)
        // setOrderRutine(nextOrderRoutine)
        break;
      case 12:
        // setPreference(val.value)
        // setpreferencias(val.value)
        // setOrderRutine(nextOrderRoutine)
        break;
      default:
        console.log(val);
    }
  };
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  const handleTypeinsurance = (e) => {
    setEditTipo(true);
    e.preventDefault();
    const click = `ContinuarTipoSeguro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    if (typeInsurance.length > 0 && !isEmptyObject(Rutine)) {
      let optionsSelected = '';

      if (typeInsurance.length === 1) {
        optionsSelected = typeInsurance[0];
      } else if (typeInsurance.length === 2) {
        optionsSelected = `${typeInsurance[0]},${typeInsurance[1]}`;
      } else if (typeInsurance.length === 3) {
        optionsSelected = `${typeInsurance[0]},${typeInsurance[1]},${typeInsurance[2]}`;
      }

      questions.push({
        id: 'typeInsurance',
        question: 6,
        value: optionsSelected,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    setNextQuestion(true);
  };

  // funcionalidad propia de la rutina

  // Scroll next routine
  const tamScreen = window.screen.width;
  const scrollToRoutine = (mobile, web) => {
    // debugger
    const avance = document.body.scrollHeight;
    console.log(tamScreen);
    if (tamScreen > 1200) {
      window.scroll({
        top: avance - web,
        behavior: 'smooth',
      });
    } else {
      window.scroll({
        top: avance - mobile,
        behavior: 'smooth',
      });
    }
  };

  const ScrollCuestion = (id) => {
    switch (id) {
      case 'masterFinance':
        scrollToRoutine(1300, 900);
        break;
      case 'skipVideo':
        scrollToRoutine(1500, 1200);
        break;
      case 'Ingresos':
        setTimeout(() => {
          scrollToRoutine(1300, 900);
        }, 100);
        break;
      case 'Cp':
        if (tamScreen < 1200) {
          scrollToRoutine(1200, 0);
        }

        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    console.log('previniendo el enter');
    event.preventDefault();
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setMasterFinance(sessionStorage.getItem('ConocimientoFinanzas') || '');
      setSkipVideo(
        sessionStorage.getItem('ConocimientoFinanzas') ? 'skip' : '',
      );
      setMonthlyIncome(sessionStorage.getItem('Ingreso_Mensual_Fijo') || '');
      setOccupation(sessionStorage.getItem('Ocupacion2') || '');
      setInsurance(sessionStorage.getItem('TieneSeguro') || '');
      setTypeInsurance(
        sessionStorage.getItem('TipoSeguro')
          ? sessionStorage.getItem('TipoSeguro').split(',')
          : [],
      );
      sessionStorage.getItem('TipoSeguro')
        ? setNextQuestion(true)
        : setNextQuestion(false);
      setCreditCard(sessionStorage.getItem('TienesTarjetaCredito') || '');
      setEndMonth(sessionStorage.getItem('FindeMes') || '');
      setFinancialPriorities(sessionStorage.getItem('PrioridadFinanzas') || '');
      setZipCode(sessionStorage.getItem('CodigoPostal2') || '');
      setpreferencias(sessionStorage.getItem('PrioridadFinanzas') || '');
      if (
        sessionStorage.getItem('PrioridadFinanzas')
        && sessionStorage.getItem('CodigoPostal2')
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editFinanzas, setEditFinanzas] = useState(
    masterFinance !== '',
  );
  const [editIngresos, setEditIngresos] = useState(
    monthlyIncome !== '',
  );
  const [editOcupacion, setEditOcupacion] = useState(
    occupation !== '',
  );
  const [editSeguro, setEditSeguro] = useState(insurance !== '');
  const [editTipo, setEditTipo] = useState(!!nextQuestion);
  const [editCreditCard, setEditCreditCard] = useState(
    creditCard !== '',
  );
  const [editEndMonth, setEditEndMonth] = useState(
    endMonth !== '',
  );
  const [editFinancialPriorities, setEditFinancialPriorities] = useState(
    financialPriorities !== '',
  );

  const handleChangeIcon = (json, action) => {
    switch (action) {
      case 1:
        setEditFinanzas(false);
        console.log(editFinanzas);
        ScrollCuestion('masterFinance');
        break;
      case 2:
        setEditIngresos(false);
        console.log(editIngresos);
        ScrollCuestion('Ingresos');
        break;
      case 3:
        setEditOcupacion(false);
        console.log(editOcupacion);
        ScrollCuestion('Ingresos');
        break;
      case 4:
        setEditSeguro(false);
        console.log(editSeguro);
        ScrollCuestion('Ingresos');
        break;
      case 5:
        setEditTipo(false);
        console.log(editTipo);
        ScrollCuestion('Ingresos');
        break;
      case 6:
        setEditCreditCard(false);
        console.log(editCreditCard);
        ScrollCuestion('Ingresos');
        break;
      case 7:
        setEditEndMonth(false);
        console.log(editEndMonth);
        ScrollCuestion('Ingresos');
        break;
      case 8:
        setEditFinancialPriorities(false);
        console.log(editFinancialPriorities);
        ScrollCuestion('Ingresos');
        break;
      default:
        break;
    }

    setLapiz([json]);
  };

  const [loaderWait, setLoaderWait] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaderWait(false);
    }, 4000);
    return () => clearTimeout(timeout);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        {form.zipCode === '' ? ScrollCuestion('masterFinance') : null}
        {!loaderWait ? (
          <Fragment>
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="¿Cómo consideras que llevas tus finanzas?"
              hora={hora}
            />

            <TrainingQuestion
              className={
                masterFinance === '' || editFinanzas === false ? '' : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="0-0"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <CardTwo
              classCard={
                masterFinance !== '' && editFinanzas === true
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={masterFinance}
              colorTraining={trainingColor}
            >
              <img
                alt="editIcon"
                className={
                  masterFinance !== '' && !editIngresos
                    ? 'iconEditCommon'
                    : 'hidden'
                }
                src={editIcon}
                onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 1)}
              />
            </CardTwo>
          </Fragment>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        )}
        <TrainingQuestion
          className={
            masterFinance !== '' && editFinanzas === true && skipVideo === ''
              ? ''
              : 'hidden'
          }
        >
          <SectionVideo
            title="Tenemos 5 posibles objetivos y de acuerdo a tus respuestas vamos a recomendarte los mejores workouts."
            id="skipVideo"
          >
            {masterFinance !== '' && editFinanzas === true && skipVideo === ''
              ? ScrollCuestion('skipVideo')
              : null}
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />

            <br />
            <div className="center">
              <CardsBtn
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={() => playVideo('Skip')}
              >
                Continuar
              </CardsBtn>
              <br />
            </div>
          </SectionVideo>
        </TrainingQuestion>

        <CardOne
          classCard={
            masterFinance !== '' && editFinanzas === true && skipVideo !== ''
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="¿Cuáles son tus ingresos mensuales?"
          hora={hora}
        />

        <TrainingQuestion
          className={
            (monthlyIncome === ''
              && masterFinance !== ''
              && editFinanzas
              && skipVideo !== '')
            || (editFinanzas && editIngresos === false && skipVideo !== '')
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            monthlyIncome !== ''
            && editIngresos
            && masterFinance !== ''
            && skipVideo !== ''
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={monthlyIncome}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              monthlyIncome !== '' && editIngresos && !editOcupacion
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 2)}
          />
        </CardTwo>
        <CardOne
          classCard={
            masterFinance !== ''
            && monthlyIncome !== ''
            && editIngresos
            && skipVideo !== ''
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="¿Cuál es tu ocupación?"
          hora={hora}
        />
        <TrainingQuestion
          className={
            (occupation === ''
              && monthlyIncome !== ''
              && editIngresos
              && masterFinance !== ''
              && skipVideo !== '')
            || (!editOcupacion
              && editIngresos
              && masterFinance !== ''
              && skipVideo !== '')
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            occupation !== '' && editOcupacion && skipVideo !== ''
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={occupation}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              monthlyIncome !== '' && editIngresos && !editSeguro
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 3)}
          />
        </CardTwo>
        <CardOne
          classCard={
            masterFinance !== ''
            && monthlyIncome !== ''
            && occupation !== ''
            && editOcupacion
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="¿Tienes algún seguro?"
          hora={hora}
        />
        <TrainingQuestion
          className={
            (insurance === '' && occupation !== '' && editOcupacion)
            || (!editSeguro && editOcupacion)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={insurance !== '' && editSeguro ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={insurance}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              (insurance !== ''
                && insurance === 'Sí'
                && editSeguro
                && !editTipo)
              || (insurance !== ''
                && insurance === 'No'
                && editSeguro
                && !editCreditCard)
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 4)}
          />
        </CardTwo>
        {insurance === 'Sí ' || insurance === 'Sí' ? (
          <Fragment>
            <CardOne
              classCard={
                masterFinance !== ''
                && monthlyIncome !== ''
                && occupation !== ''
                && insurance !== ''
                && editSeguro
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="¿De qué tipo?"
              hora={hora}
            />
            <TrainingQuestion
              className={
                (!nextQuestion
                  && insurance !== ''
                  && editSeguro
                  && masterFinance !== ''
                  && monthlyIncome !== ''
                  && occupation !== ''
                  && insurance !== ''
                  && editSeguro)
                || (!editTipo && editSeguro)
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <form onSubmit={(e) => handleSubmit(e)} className="wo-input">
                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="5-5"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />
                  <br />
                  <br />
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    onClick={(e) => handleTypeinsurance(e)}
                  >
                    Continuar
                  </CardsBtn>
                  <br />
                  <br />
                </form>
              </QuestionsFlex>
            </TrainingQuestion>
            <CardTwo
              classCard={nextQuestion && editTipo ? 'cardTwoResp' : 'hidden'}
              classTop="topTwo1"
              imgMan="hidden"
              text={typeInsurance[0]}
              text2={typeInsurance.length > 0 ? typeInsurance[1] : null}
              text3={typeInsurance.length > 1 ? typeInsurance[2] : null}
              colorTraining={trainingColor}
            >
              <img
                alt="editIcon"
                className={
                  nextQuestion && editSeguro && !editCreditCard
                    ? 'iconEditCommon'
                    : 'hidden'
                }
                src={editIcon}
                onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 5)}
              />
            </CardTwo>
          </Fragment>
        ) : null}
        {insurance === 'Sí ' || insurance === 'Sí' ? (
          <Fragment>
            <CardOne
              classCard={
                masterFinance !== ''
                && monthlyIncome !== ''
                && occupation !== ''
                && insurance !== ''
                && nextQuestion
                && editTipo
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="¿Cuentas con tarjeta de crédito?"
              hora={hora}
            />
            <TrainingQuestion
              className={
                (masterFinance !== ''
                  && monthlyIncome !== ''
                  && occupation !== ''
                  && insurance !== ''
                  && nextQuestion
                  && editTipo
                  && endMonth === ''
                  && !editCreditCard)
                || (!editCreditCard && editTipo)
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="6-6"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <CardTwo
              classCard={
                creditCard !== '' && editCreditCard ? 'cardTwoResp' : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={creditCard}
              colorTraining={trainingColor}
            >
              <img
                alt="editIcon"
                className={
                  nextQuestion && editSeguro && !editEndMonth
                    ? 'iconEditCommon'
                    : 'hidden'
                }
                src={editIcon}
                onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 6)}
              />
            </CardTwo>
          </Fragment>
        ) : (
          <Fragment>
            <CardOne
              classCard={
                masterFinance !== ''
                && monthlyIncome !== ''
                && occupation !== ''
                && insurance !== ''
                && editSeguro
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="¿Cuentas con tarjeta de crédito?"
              hora={hora}
            />
            <TrainingQuestion
              className={
                (masterFinance !== ''
                  && monthlyIncome !== ''
                  && occupation !== ''
                  && insurance !== ''
                  && editSeguro
                  && creditCard === ''
                  && endMonth === ''
                  && !editCreditCard)
                || (editSeguro && !editCreditCard)
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="6-6"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <CardTwo
              classCard={
                creditCard !== '' && editSeguro && editCreditCard
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={creditCard}
              colorTraining={trainingColor}
            >
              <img
                alt="editIcon"
                className={
                  creditCard !== '' && editSeguro && !editEndMonth
                    ? 'iconEditCommon'
                    : 'hidden'
                }
                src={editIcon}
                onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 6)}
              />
            </CardTwo>
          </Fragment>
        )}

        <CardOne
          classCard={
            masterFinance !== ''
            && monthlyIncome !== ''
            && occupation !== ''
            && insurance !== ''
            && creditCard !== ''
            && editCreditCard
            && editSeguro
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="¿En general cómo acabas el mes?"
          hora={hora}
        />
        <TrainingQuestion
          className={
            (endMonth === '' && creditCard !== '' && editCreditCard)
            || (!editEndMonth && editCreditCard && editSeguro)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="7-7"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={endMonth !== '' && editEndMonth ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={endMonth}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              endMonth !== '' && editEndMonth && !editFinancialPriorities
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 7)}
          />
        </CardTwo>
        <CardOne
          classCard={
            masterFinance !== ''
            && monthlyIncome !== ''
            && occupation !== ''
            && insurance !== ''
            && creditCard !== ''
            && endMonth !== ''
            && editEndMonth
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="¿Cuál sería tu prioridad para mejorar tus finanzas personales?"
          hora={hora}
        />
        <TrainingQuestion
          className={
            (financialPriorities === '' && endMonth !== '' && editEndMonth)
            || (editEndMonth && !editFinancialPriorities)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="8-8"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              // activeStep={true}
              AdditionalInformation={true}
              textInformation={textInfo}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            financialPriorities !== '' && editFinancialPriorities
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={financialPriorities}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              financialPriorities !== ''
              && editFinancialPriorities
              && orderRutine <= 3
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 2, active: 2 }, 8)}
          />
        </CardTwo>
        {financialPriorities !== '' && editFinancialPriorities && (
          <Fragment>
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              textbold="Ya casi terminamos,"
              text2="Ingresa tu código postal para garantizar la cobertura de tus productos"
              hora={hora}
            />
          </Fragment>
        )}
        <TrainingQuestion
          className={
            zipCode === ''
            && financialPriorities !== ''
            && editFinancialPriorities
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="87%"
            hei="auto"
            margin="0 auto auto"
            bc="#fafafa"
          >
            <form onSubmit={(e) => handleSubmit(e)} className="wo-input">
              {form.zipCode !== '' ? ScrollCuestion('Cp') : null}
              <InputsOrder
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="9-9"
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
              />
              <br />
              <br />
              <CardsBtn
                type="button"
                min_w="100%"
                onClick={(e) => handleChangeCp(e)}
              >
                Continuar
              </CardsBtn>
              <br />
              <br />
            </form>
          </QuestionsDivs>
        </TrainingQuestion>
        <CardTwo
          classCard={zipCode !== '' ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={zipCode}
          colorTraining={trainingColor}
        />
      </div>
  );
};

export default UserProfiling;
