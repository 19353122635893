import React, { Fragment, useEffect } from "react";
import { useQuery } from "@apollo/client";
import "../../assets/styles/Home.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import PlayYoutube from "./PlayYoutube";
// helpers
import { day, months, years } from "../../Helpers";
// ApolloClient
import { Container, Col7 } from "./common.styled";
import ImageCoachInicio from "../Training/ImageCoachInicio";
import {
  TrainingText,
  QuestionsDivs,
  QuestionsText,
  Images,
  CardsBtn,
  DivStyle,
  DivSelects,
  TrainingDivSection,
  ContainerVideo,
  QuestionsDivsbar,
  DivTamFlex,
  QuestionsTextVideo,
  QuestionsDivsbarVideo,
  QuestionOptionNumber,
} from "../../assets/styles/Training.styled";
import Rfc from "../../Helpers/Rfc";
import InputAddress from "../../anaCoach/Training/InputAddress";
import { GET_ROUTINES_BY_ID_TRAINING } from "../../queries";
import LoadingWo from "../Training/loadingWo";
import CustomTooltip from "./CustomTooltip";
import uncheck from "../../assets/img/anacoach/ticUncheck.svg";

import DocUpload from "./DocUpload";
import InputsColumsTypeNumber from "./InputsOrderColums";
import GloveBoxCheckList from "./GloveBoxCheckList";
import GloveBoxSelect from "./GloveBoxSelect";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 4,
    width: 25,
    height: 25,
    boxShadow: "inset 0 0 0 2px #200E32, inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 23,
      height: 25,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
});

const InputsOrder = ({
  orderRutine,
  form,
  step,
  maxLengthInput,
  handleChangeAddress,
  handleChangeRFC,
  setOrderRutine,
  handleChangeRutine,
  handleChange,
  handleChangeCatOption,
  handleChangeQuestionsSend,
  currentTraining,
  endQuestion = "",
  activeValidateRutine = false,
  activeNames = false,
  namesDatContact = false,
  activeStep = false,
  nextOrdeRoutine = 0,
  activeTelefonoFijo = false,
  inputError = false,
  texterror = "",
  activeImgOption = false,
  arrayImagesOptions = {},
  activeRutine = false,
  AdditionalInformation = false,
  textInformation,
  typeButton = false,
  otherDesignCat = false,
  labelInput = "",
  onClick = "",
  dateFullWidth = false,
  date,
  typeSpecial,
  valueGrup,
  disable = false,
  accordion = false,
  separationCat = false,
  separateLabelOpenField = false,
  typeCheckBox = false,
  typeCheckBoxDescription = false,
  barra = false,
  inputflex = false,
  ValueSlider,
  handleSlider,
  checkBoxName = "check_",
  classificationQuestionDetail = [],
  typeNum,
  componentWo = "",
  amountService = "",
  streaming = false,
  changeColor = {},
  centerLine = false,
  changeCheck = false,
  buttonImageType = false,
  arrayFilter = [],
  inputVideo = false,
}) => {
  const classes = useStyles();

  const valuetext = (value) => `${value}`;
  const { data, loading, error } = useQuery(GET_ROUTINES_BY_ID_TRAINING, {
    variables: {
      idTraining:
        parseInt(sessionStorage.getItem("idTraining")) || currentTraining,
      orderRutine,
    },
  });
  useEffect(() => {
    if (data !== undefined) {
      let { getRoutinesByIdTraining } = data;
      getRoutinesByIdTraining = JSON.parse(getRoutinesByIdTraining);
      getRoutinesByIdTraining = Object.values(getRoutinesByIdTraining);
      const newData = getRoutinesByIdTraining[0];
      handleChangeRutine(newData);
    }
  }, [data]);
 
  if (loading) {
    console.log("Entro a este if");
    return (
      <>
        {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
          <>
            <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
              {/* <ImageCoachInicio /> */}
              <Col7 padding=' ' className='cardPrincipal1'>
                {/* <LoadingWo /> */}
              </Col7>
            </Container>
          </>
        ) : (
          <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
            <ImageCoachInicio />
            <Col7 padding=' ' className='cardPrincipal1'>
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </>
    );
  }
  if (error) return <h2>Hubo un error</h2>;
  let { getRoutinesByIdTraining } = data;
  getRoutinesByIdTraining = JSON.parse(getRoutinesByIdTraining);
  getRoutinesByIdTraining = Object.values(getRoutinesByIdTraining);
  if (step === undefined) return;
  const element = step.split("-")[1];
  const menor = step.split("-")[0];
  let names = [];
  if (activeValidateRutine) {
    names = [
      "firstname",
      "secondname",
      "lastname",
      "secondlastname",
      "",
      "email",
      "cel",
    ];
  } else if (handleChangeRFC !== undefined) {
    return (
      <Rfc
        setOrderRutine={setOrderRutine}
        handleChangeRFC={handleChangeRFC}
        data={form}
        nextOrdeRoutine={nextOrdeRoutine}
      />
    );
  } else if (handleChangeAddress !== undefined) {
    names = [
      "directionMaps",
      "mapsGeo",
      "calle",
      "numext",
      "numint",
      "cp",
      "",
      "municipio",
      "estado",
      "",
      "",
      "telcasa",
    ];
    if (element === "8") {
      return (
        <InputAddress
          names={names}
          handleChangeAddress={handleChangeAddress}
          data={getRoutinesByIdTraining[0].questions}
          form={form}
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChange={handleChange}
        />
      );
    }
  } else if (activeNames) {
    names = ["firstname", "lastname", "secondlastname"];
  } else if (namesDatContact) {
    names = ["email", "cel"];
    /* ######END##### */
  }
  if (activeRutine) {
    return <div></div>;
  }

  const eventMore = (idElement) => {
    const l = idElement.toString();
    const element = document.getElementById(l);
    element.classList.remove("hidden");
    const elementMore = document.getElementById(`${l}-mas`);
    elementMore.classList.add("hidden");
  };

  const eventHidden = (idElement) => {
    const l = idElement.toString();
    const element = document.getElementById(l);
    element.classList.add("hidden");
    const elementMore = document.getElementById(`${l}-mas`);
    elementMore.classList.remove("hidden");
  };

  const jsonNames = {
    89: "telcasa",
    9: "digitoscard",
    127: "clabeInter",
    126: "numeroTarjeta",
    16: "empresa",
    21: "nameref1",
    23: "nameref2",
    22: "telref1",
    24: "telref2",
    74: "FirstName",
    75: "MiddleName",
    76: "FathersLastName",
    77: "MothersLastName",
    79: "Email1",
    80: "CellPhone",
    139: "EdadesHijo1",
    140: "EdadesHijo2",
    141: "EdadesHijo3",
    142: "EdadesHijo4",
    143: "EdadesHijo5",
    144: "EdadPadre",
    145: "EdadMadre",
    152: "nombreSeguroGM",
    153: "sumaAsegurada",
    154: "nombreHospitales",
    217: "MontoInversion",
    218: "PlazoInversion",
    219: "InversionObtenida",
    198: "zipCode",
    222: "initialSavings",
    223: "savingCapacity",
    224: "minimumExpenses",
    231: "goal",
    232: "advance",
    241: "cantidadAhorro",
    242: "cantidadInvertido",
    243: "cantidadDeben",
    246: "montoAdeudo",
    249: "montoIngresos",
    251: "montoIngresosVariable",
    254: "rentaHipoteca",
    255: "agua",
    256: "luz",
    257: "internet",
    258: "alimentos",
    259: "gasolinaTransporte",
    260: "egresosExtras",
    261: "videoGastoHormiga",
    262: "imprevistos",
    263: "gastosEntretenimiento",
    264: "gastosDiversion",
    265: "gastosPersonales",
    266: "gastosExtras",
    317: "montoAdeudoInformales",
    328: "CuantoInvertirAeromexico",
    329: "CuantoInvertirBimbo",
    330: "CuantoInvertirAmazon",
    331: "CuantoInvertirFacebook",
    332: "CuantoInvertirCetes",
    333: "CuantoInvertirSPY",
    334: "CuantoInvertirUSMV",
    335: "CuantoInvertirFUNO",
    336: "CuantoInvertirCPODX",
    337: "CuantoInvertirOro",
    338: "Acumulado",
    343: "MontoMeta",
    344: "DineroMetaQuincenal",
    345: "AhorroMensual",
    346: "TiempodeAhorro",
    369: "RelacionFinanzas",
    370: "PracticaDeporte",
    371: "PlataformaDigital",
    372: "OtraOpcion",
    373: "ConQuienVives",
    374: "Vacaciones",
    375: "ComoTeVesDe40",
    376: "DurabilidadDeAhorros",
    377: "DesayunoIdeal",
    378: "CuantoDineroTeQueda",
    379: "EdadParaTestamentar",
    380: "GustoPorDinero",
    381: "OtraOpcion2",
    382: "FindeSemana",
    383: "OtraOpcion3",
    384: "QuePasaConMiDinero",
    385: "OtraOpcion4",
    386: "FindelMundo",
    387: "EstadoFinanciero",
    361: "nombreHijo",
    362: "telefonoHijo",
    396: "billingIncome",
    397: "collaborators",
    352: "handleCreditCard",
    404: "MontoPorDeudaAmigos",
    405: "MontoDeudaTDC",
    406: "MontoDeudasTiendas",
    407: "MontoDeudaCreditoAutomotriz",
    408: "MontoDeudaCreditoHipotecario",
    409: "MontoOtraDeuda",
    478: "MetasPorCumplir",
    479: "PropiaMetaColavorativa",
    480: "DineroMetaColavorativa",
    481: "TiempoMetaColavorativa",
    484: "IngresoMensualAfore",
    485: "DineroRetiroAfore",
    486: "AniosDeRetiroAfore",
    487: "ConocimientoAfore",
    488: "AforePerteneciente",
    489: "CambioAfore",
    492: "Cigarros_dia",
  };
  const marks = [];
  let valorDiv = 2;
  let valorIncremental = 0;
  const LLenarBarra = (detail) => {
    marks.push({
      value: valorIncremental,
      label: detail.QuestionOption,
      IDCatQuestionsDetail: detail.IDCatQuestionsDetail,
    });
    valorIncremental += valorDiv;
  };
  const content =
    step.indexOf("-") < 0 ? (
      <Fragment>
        {getRoutinesByIdTraining[0].questions.map((routine, index) => {
          if (index === endQuestion && activeValidateRutine) return;
          let inputToShow;
          if (date) {
            if (index === 0) {
              inputToShow = (
                <Fragment>
                  {dateFullWidth ? (
                    <Fragment>
                      <DivSelects>
                        <TextField
                          variant='outlined'
                          id='day'
                          name='day'
                          select
                          label='Día'
                          helperText=''
                          className='marginright6 minwidth50 input-coru'
                          fullWidth
                          value={form.day}
                          onChange={(e) =>
                            handleChange(e, routine.QuestionsOrder)
                          }
                          onClick={onClick || ""}
                        >
                          {day.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          variant='outlined'
                          id='month'
                          name='month'
                          select
                          label='Mes'
                          helperText=''
                          className='minwidth input-coru'
                          fullWidth
                          value={form.month}
                          onChange={(e) =>
                            handleChange(e, routine.QuestionsOrder)
                          }
                        >
                          {months.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label.substring(0, 3)}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          variant='outlined'
                          id='year'
                          name='year'
                          select
                          label='Año'
                          className='marginright input-coru'
                          value={form.year}
                          fullWidth
                          onChange={(e) =>
                            handleChange(e, routine.QuestionsOrder)
                          }
                          onClick={onClick || ""}
                        >
                          {years.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </DivSelects>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <DivSelects>
                        <TextField
                          variant='outlined'
                          id='day'
                          name='day'
                          select
                          label='Día'
                          helperText=''
                          size='small'
                          className='marginright6 minwidth50 inputs-bottom-fn input-coru'
                          value={form.day}
                          onChange={(e) =>
                            handleChange(e, routine.QuestionsOrder)
                          }
                          onClick={onClick || ""}
                        >
                          {day.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          variant='outlined'
                          id='month'
                          name='month'
                          select
                          label='Mes'
                          helperText=''
                          className='minwidth inputs-bottom-fn input-coru'
                          value={form.month}
                          onChange={(e) =>
                            handleChange(e, routine.QuestionsOrder)
                          }
                        >
                          {months.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label.substring(0, 3)}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          variant='outlined'
                          id='year'
                          name='year'
                          select
                          label='Año'
                          className='marginright inputs-bottom-fn input-coru'
                          value={form.year}
                          onChange={(e) =>
                            handleChange(e, routine.QuestionsOrder)
                          }
                          onClick={onClick || ""}
                        >
                          {years.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </DivSelects>
                    </Fragment>
                  )}
                </Fragment>
              );
              return (
                <Fragment key={index}>
                  {inputToShow}
                </Fragment>
              );
            }
          } else {
            switch (routine.QuestionType) {
              case "OpenField":
                if (routine.QuestionName === "Teléfono Celular") {
                  inputToShow = (
                    <TextField
                      variant='outlined'
                      id={routine.QuestionName}
                      name={names[index]}
                      label={
                        labelInput !== "" ? labelInput : routine.QuestionName
                      }
                      autoComplete='off'
                      required
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      className='textInput inputs-bottom input-coru'
                      value={form[names[index]]}
                      onBlur={(e) =>
                        handleChangeQuestionsSend(
                          `${routine.QuestionsOrder}`,
                          names[index],
                          e.target.value,
                          "OpenField"
                        )
                      }
                      onChange={handleChange}
                      onClick={onClick || ""}
                    />
                  );
                } else {
                  inputToShow = (
                    <TextField
                      variant='outlined'
                      id={routine.QuestionName}
                      name={names[index]}
                      label={
                        labelInput !== "" ? labelInput : routine.QuestionName
                      }
                      error={inputError[names[index]]}
                      helperText={texterror[names[index]]}
                      autoComplete='off'
                      required
                      fullWidth
                      className='textInput inputs-bottom input-coru'
                      value={form[names[index]]}
                      onBlur={(e) =>
                        handleChangeQuestionsSend(
                          `${routine.QuestionsOrder}`,
                          names[index],
                          e.target.value,
                          "OpenField"
                        )
                      }
                      onChange={handleChange}
                      onClick={onClick || ""}
                    />
                  );
                }
                break;
              case "Date":
                inputToShow = (
                  <Fragment>
                    {dateFullWidth ? (
                      <Fragment>
                        <DivSelects>
                          <TextField
                            variant='outlined'
                            id='day'
                            name='day'
                            select
                            label='Día'
                            helperText=''
                            className='marginright6 minwidth50 input-coru'
                            fullWidth
                            value={form.day}
                            onChange={(e) =>
                              handleChange(e, routine.QuestionsOrder)
                            }
                            onClick={onClick || ""}
                          >
                            {day.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            variant='outlined'
                            id='month'
                            name='month'
                            select
                            label='Mes'
                            helperText=''
                            className='minwidth input-coru'
                            fullWidth
                            value={form.month}
                            onChange={(e) =>
                              handleChange(e, routine.QuestionsOrder)
                            }
                          >
                            {months.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label.substring(0, 3)}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            variant='outlined'
                            id='year'
                            name='year'
                            select
                            label='Año'
                            className='marginright input-coru'
                            value={form.year}
                            fullWidth
                            onChange={(e) =>
                              handleChange(e, routine.QuestionsOrder)
                            }
                            onClick={onClick || ""}
                          >
                            {years.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </DivSelects>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <TrainingText
                          className='dm-sans'
                          align='left'
                          weight='600'
                          top='12px'
                        >
                          Fecha de nacimiento
                        </TrainingText>
                        <DivSelects>
                          <TextField
                            variant='outlined'
                            id='day'
                            name='day'
                            select
                            label='Día'
                            helperText=''
                            size='small'
                            className='marginright6 minwidth50 inputs-bottom-fn input-coru'
                            value={form.day}
                            onChange={(e) =>
                              handleChange(e, routine.QuestionsOrder)
                            }
                            onClick={onClick || ""}
                          >
                            {day.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            variant='outlined'
                            id='month'
                            name='month'
                            select
                            label='Mes'
                            helperText=''
                            className='minwidth inputs-bottom-fn input-coru'
                            value={form.month}
                            onChange={(e) =>
                              handleChange(e, routine.QuestionsOrder)
                            }
                          >
                            {months.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label.substring(0, 3)}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            variant='outlined'
                            id='year'
                            name='year'
                            select
                            label='Año'
                            className='marginright inputs-bottom-fn input-coru'
                            value={form.year}
                            onChange={(e) =>
                              handleChange(e, routine.QuestionsOrder)
                            }
                            onClick={onClick || ""}
                          >
                            {years.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </DivSelects>
                      </Fragment>
                    )}
                  </Fragment>
                );
                break;
              case "Cat":
                inputToShow = (
                  <Fragment>
                    {routine.detailQuestion.map((detail, index) => (
                      <Fragment key={detail.IDCatQuestionsDetail}>
                        <QuestionsDivsbar
                          onClick={() => {
                            handleChangeCatOption(detail.QuestionOption);
                            handleChangeQuestionsSend(
                              routine.QuestionsOrder,
                              detail.QuestionOption,
                              detail.CatFieldDetail_IDCatFieldDetail,
                              "Cat"
                            );
                          }}
                        >
                          <QuestionsText size='14px' weight='400'>
                            {detail.QuestionOption}
                          </QuestionsText>
                        </QuestionsDivsbar>
                      </Fragment>
                    ))}
                  </Fragment>
                );
                break;
              case "Video":
                inputToShow = (
                  <Fragment>
                    <div>
                      {routine.detailQuestion.map((detail, index) => (
                        <Fragment key={detail.IDCatQuestionsDetail}>
                          <PlayYoutube
                            idVideo={
                              detail.QuestionOption.includes("http")
                                ? detail.QuestionOption.split("/embed/")[1]
                                : ""
                            }
                            width={"300"}
                            height={"190"}
                            close={false}
                          />
                        </Fragment>
                      ))}
                    </div>
                  </Fragment>
                );
                break;
              default:
                break;
            }
            return (
              <Fragment key={index}>
                {inputToShow}
              </Fragment>
            );
          }
        })}
      </Fragment>
    ) : (
      <Fragment>
        {getRoutinesByIdTraining[0].questions.map((routine, index) => {
          if (index > Number(element) || index < Number(menor)) return null;
          if (routine.QuestionType === "OpenField") {
            if (activeTelefonoFijo) {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </Fragment>
              );
            }
            if (routine.QuestionName === "Clabe interbancaria") {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </Fragment>
              );
            }
            if (routine.QuestionName === "Últimos 4 dígitos") {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </Fragment>
              );
            }
            if (routine.QuestionName === "No. tarjeta de débito") {
              return (
                <div
                  className='pl-preferred-credit-card'
                  key={index}
                >
                  <TextField
                    variant='outlined'
                    InputLabelProps={{
                      classes: {
                        root: "",
                      },
                    }}
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    inputProps={{ maxLength: 16 }}
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </div>
              );
            }
            if (
              routine.DatUserDetailStorage === 152 ||
              routine.DatUserDetailStorage === 153 ||
              routine.DatUserDetailStorage === 154
            ) {
              return (
                <div
                  className='pl-preferred-credit-card'
                  key={index}
                >
                  <TextField
                    variant='outlined'
                    InputLabelProps={{
                      classes: {
                        root: "",
                      },
                    }}
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={""}
                    autoComplete='off'
                    required
                    fullWidth
                    inputProps={
                      routine.IDCatQuestions === 85
                        ? { maxLength: 50 }
                        : { maxLength: 16 }
                    }
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </div>
              );
            }
            if (routine.QuestionName === "Teléfono Celular") {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={names[index]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    className='textInput inputs-bottom input-coru'
                    value={form[names[index]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        names[index],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </Fragment>
              );
            }
            if (
              routine.DatUserDetailStorage === 254 ||
              routine.DatUserDetailStorage === 246 ||
              routine.DatUserDetailStorage === 317 ||
              routine.DatUserDetailStorage === 249 ||
              routine.DatUserDetailStorage === 251 ||
              (routine.DatUserDetailStorage >= 262 &&
                routine.DatUserDetailStorage <= 266) ||
              (routine.DatUserDetailStorage >= 241 &&
                routine.DatUserDetailStorage <= 243) ||
              (routine.DatUserDetailStorage >= 255 &&
                routine.DatUserDetailStorage <= 260)
            ) {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    className='textInput inputs-bottom input-coru'
                    inputProps={{ maxLength: 11 }}
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || null}
                    type={typeNum ? "tel" : " "}
                  />
                </Fragment>
              );
            }
            if (routine.DatUserDetailStorage === 217) {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={
                      labelInput !== "" ? labelInput : routine.QuestionName
                    }
                    autoComplete='off'
                    required
                    fullWidth
                    className='textInput inputs-bottom input-coru'
                    inputProps={{ maxLength: 11 }}
                    value={
                      form[jsonNames[routine.DatUserDetailStorage]] || "$0"
                    }
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || null}
                    type={typeNum ? "tel" : " "}
                  />
                </Fragment>
              );
            }
            if (separateLabelOpenField) {
              return (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "15px",
                        marginTop: "15px",
                        width: "315px",
                      }}
                    >
                      {routine.QuestionName}
                    </div>

                    <TextField
                      variant='outlined'
                      id={routine.QuestionName}
                      name={jsonNames[routine.DatUserDetailStorage]}
                      error={inputError[names[index]]}
                      helperText={texterror[names[index]]}
                      autoComplete='off'
                      required
                      disabled={routine.QuestionName === "Acumulado "}
                      // fullWidth
                      value={form[jsonNames[routine.DatUserDetailStorage]]}
                      onBlur={(e) =>
                        handleChangeQuestionsSend(
                          `${routine.QuestionsOrder}`,
                          jsonNames[routine.DatUserDetailStorage],
                          e.target.value,
                          "OpenField"
                        )
                      }
                      onChange={handleChange}
                      onClick={onClick || ""}
                    />
                  </div>
                </Fragment>
              );
            }
            if (
              routine.QuestionName ===
              "¿Cuánto dinero necesitas para cumplir tu meta?"
            ) {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={"Ingresa cantidad"}
                    autoComplete='off'
                    required
                    disabled={disable}
                    inputProps={{ maxLength: maxLengthInput }}
                    fullWidth
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </Fragment>
              );
            }
            if (
              routine.QuestionName ===
              "¿Con cuánto dinero te gustaría retirarte?"
            ) {
              return (
                <Fragment key={index}>
                  <TextField
                    variant='outlined'
                    id={routine.QuestionName}
                    name={jsonNames[routine.DatUserDetailStorage]}
                    label={"¿Con qué cantidad te gustaría retirarte?"}
                    autoComplete='off'
                    required
                    disabled={disable}
                    inputProps={{ maxLength: maxLengthInput }}
                    fullWidth
                    className='textInput inputs-bottom input-coru'
                    value={form[jsonNames[routine.DatUserDetailStorage]]}
                    onBlur={(e) =>
                      handleChangeQuestionsSend(
                        `${routine.QuestionsOrder}`,
                        jsonNames[routine.DatUserDetailStorage],
                        e.target.value,
                        "OpenField"
                      )
                    }
                    onChange={handleChange}
                    onClick={onClick || ""}
                  />
                </Fragment>
              );
            }
            return (
              <Fragment key={index}>
                <TextField
                  variant='outlined'
                  id={routine.QuestionName}
                  name={jsonNames[routine.DatUserDetailStorage]}
                  label={labelInput !== "" ? labelInput : routine.QuestionName}
                  autoComplete='off'
                  required
                  disabled={disable}
                  inputProps={{ maxLength: maxLengthInput }}
                  fullWidth
                  className='textInput inputs-bottom input-coru'
                  value={form[jsonNames[routine.DatUserDetailStorage]]}
                  onBlur={(e) =>
                    handleChangeQuestionsSend(
                      `${routine.QuestionsOrder}`,
                      jsonNames[routine.DatUserDetailStorage],
                      e.target.value,
                      "OpenField"
                    )
                  }
                  onChange={handleChange}
                  onClick={onClick || ""}
                />
              </Fragment>
            );
          }
          if (routine.QuestionType === "Date") {
            if (dateFullWidth) {
              return (
                <Fragment>
                  <DivSelects>
                    <TextField
                      variant='outlined'
                      id='day'
                      name='day'
                      select
                      label='Día'
                      helperText=''
                      className='marginright6 minwidth50 input-coru'
                      fullWidth
                      value={form.day}
                      onChange={(e) => handleChange(e, routine.QuestionsOrder)}
                      onClick={onClick || ""}
                    >
                      {day.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      variant='outlined'
                      id='month'
                      name='month'
                      select
                      label='Mes'
                      helperText=''
                      className='minwidth input-coru'
                      fullWidth
                      value={form.month}
                      onChange={(e) => handleChange(e, routine.QuestionsOrder)}
                    >
                      {months.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label.substring(0, 3)}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      variant='outlined'
                      id='year'
                      name='year'
                      select
                      label='Año'
                      className='marginright input-coru'
                      value={form.year}
                      fullWidth
                      onChange={(e) => handleChange(e, routine.QuestionsOrder)}
                      onClick={onClick || ""}
                    >
                      {years.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </DivSelects>
                </Fragment>
              );
            }
            return (
              <Fragment>
                <TrainingText
                  className='dm-sans'
                  align='left'
                  weight='600'
                  top='12px'
                >
                  Fecha de nacimiento
                </TrainingText>
                <DivSelects>
                  <TextField
                    variant='outlined'
                    id='day'
                    name='day'
                    select
                    label='Día'
                    helperText=''
                    size='small'
                    className='minwidth50 inputs-bottom-fn input-coru'
                    value={form.day}
                    onChange={(e) => handleChange(e, routine.QuestionsOrder)}
                    onClick={onClick || ""}
                  >
                    {day.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    variant='outlined'
                    id='month'
                    name='month'
                    select
                    label='Mes'
                    helperText=''
                    className='minwidth inputs-bottom-fn input-coru'
                    value={form.month}
                    onChange={(e) => handleChange(e, routine.QuestionsOrder)}
                  >
                    {months.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label.substring(0, 3)}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    variant='outlined'
                    id='year'
                    name='year'
                    select
                    label='Año'
                    className='marginright inputs-bottom-fn input-coru'
                    value={form.year}
                    onChange={(e) => handleChange(e, routine.QuestionsOrder)}
                    onClick={onClick || ""}
                  >
                    {years.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </DivSelects>
              </Fragment>
            );
          }
          if (routine.QuestionType === "Cat") {
            if (
              activeStep &&
              routine.Show &&
              !barra &&
              !inputflex &&
              !inputVideo
            ) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbar
                        width={currentTraining === 4 && "100% !important"}
                        margin={currentTraining === 4 && "0px 0px 10px"}
                        height={currentTraining === 4 && "35px !important"}
                        onClick={() => {
                          handleChangeCatOption({
                            step: Number(element),
                            IDCatQuestionsDetail: Number(
                              detail.IDCatQuestionsDetail
                            ),
                            value: detail.QuestionOption,
                          });
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionsText size='14px' weight='400'>
                          {detail.QuestionOption}
                        </QuestionsText>
                      </QuestionsDivsbar>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (
              activeStep &&
              routine.Show &&
              !barra &&
              !inputflex &&
              inputVideo
            ) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbarVideo
                        onClick={() => {
                          handleChangeCatOption({
                            step: Number(element),
                            IDCatQuestionsDetail: Number(
                              detail.IDCatQuestionsDetail
                            ),
                            value: detail.QuestionOption,
                          });
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionOptionNumber>{index + 1}</QuestionOptionNumber>
                        <QuestionsTextVideo size='14px' weight='400'>
                          {detail.QuestionOption}
                        </QuestionsTextVideo>
                      </QuestionsDivsbarVideo>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (activeStep && routine.Show && !barra && inputflex) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbar
                        style={{
                          width: "100%",
                          margin: "0 0 20px 0",
                          height: "50px",
                        }}
                        onClick={() => {
                          handleChangeCatOption({
                            step: Number(element),
                            IDCatQuestionsDetail: Number(
                              detail.IDCatQuestionsDetail
                            ),
                            value: detail.QuestionOption,
                          });
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionsText
                          size='14px'
                          weight='400'
                          margin={
                            detail.QuestionOption !== "Fiesta"
                              ? "8px 0 0 0"
                              : ""
                          }
                        >
                          {detail.QuestionOption}
                        </QuestionsText>
                        {detail.QuestionOption === "Fiesta" ? (
                          <QuestionsText size='10px' weight='400'>
                            (Bodas, XV años, bautizos)
                          </QuestionsText>
                        ) : null}
                      </QuestionsDivsbar>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (activeStep && routine.Show && barra) {
              valorDiv = 100 / (routine.detailQuestion.length - 1);
              {
                routine.detailQuestion.map((detail, index) =>
                  LLenarBarra(detail)
                );
              }
              return (
                <Fragment>
                  <Slider
                    id='ValueSlider'
                    defaultValue={0}
                    value={ValueSlider}
                    getAriaValueText={valuetext}
                    aria-labelledby='discrete-slider-always'
                    step={valorDiv}
                    marks={marks}
                    onChange={handleSlider}
                  />
                </Fragment>
              );
            }
            if (
              routine.Show &&
              !AdditionalInformation &&
              !typeButton &&
              !otherDesignCat &&
              !accordion &&
              !separationCat &&
              classificationQuestionDetail.length === 0 &&
              !centerLine &&
              !buttonImageType &&
              !inputVideo
            ) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbar
                        onClick={() => {
                          handleChangeCatOption(detail.QuestionOption);
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionsText size='14px' weight='400'>
                          {detail.QuestionOption}
                        </QuestionsText>
                      </QuestionsDivsbar>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (
              routine.Show &&
              !AdditionalInformation &&
              !typeButton &&
              !otherDesignCat &&
              !accordion &&
              !separationCat &&
              classificationQuestionDetail.length === 0 &&
              !centerLine &&
              !buttonImageType &&
              inputVideo
            ) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbarVideo
                        onClick={() => {
                          handleChangeCatOption(detail.QuestionOption);
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionOptionNumber>{index + 1}</QuestionOptionNumber>
                        <QuestionsTextVideo size='14px' weight='600'>
                          {detail.QuestionOption}
                        </QuestionsTextVideo>
                      </QuestionsDivsbarVideo>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (
              routine.Show &&
              !AdditionalInformation &&
              !typeButton &&
              !otherDesignCat &&
              !accordion &&
              !separationCat &&
              classificationQuestionDetail.length === 0 &&
              centerLine
            ) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbar
                        onClick={() => {
                          handleChangeCatOption(detail.QuestionOption);
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionsText size='14px' weight='400'>
                          {detail.QuestionOption.includes("(")
                            ? detail.QuestionOption.split("(")[0]
                            : detail.QuestionOption}
                          {detail.QuestionOption.includes("(") ? <br /> : null}
                          {detail.QuestionOption.includes("(")
                            ? detail.QuestionOption.split("(")[1].replace(
                                ")",
                                ""
                              )
                            : null}
                        </QuestionsText>
                      </QuestionsDivsbar>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (routine.Show && AdditionalInformation) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <div
                      key={detail.IDCatQuestionsDetail}
                      style={{ display: "flex" }}
                    >
                      <QuestionsDivsbar
                        onClick={() => {
                          handleChangeCatOption({
                            step: Number(element),
                            value: detail.QuestionOption,
                          });
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                        className='beneficios'
                      >
                        <QuestionsText size='14px' weight='400'>
                          {detail.QuestionOption}
                        </QuestionsText>
                      </QuestionsDivsbar>
                      <OverlayTrigger
                        key={index}
                        overlay={
                          <Tooltip id={`tooltip-${index}`}>
                            {textInformation[index][`text${index + 1}`]}Shu1
                          </Tooltip>
                        }
                      >
                        <button
                          style={{
                            borderStyle: "none",
                            background: "none",
                            outline: "none !important",
                            fontWeight: "normal!important",
                          }}
                        >
                          <HelpOutlineIcon />
                        </button>
                      </OverlayTrigger>
                    </div>
                  ))}
                </Fragment>
              );
            }
            if (typeButton) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <div
                      key={detail.IDCatQuestionsDetail}
                      style={{ display: "flex" }}
                    >
                      {index === 0 ? (
                        <CardsBtn
                          onClick={() => {
                            handleChangeCatOption({
                              step: Number(element),
                              value: detail.QuestionOption,
                            });
                            handleChangeQuestionsSend(
                              routine.QuestionsOrder,
                              detail.QuestionOption,
                              detail.CatFieldDetail_IDCatFieldDetail,
                              "Cat"
                            );
                          }}
                          style={{
                            borderRadius: "4px",
                            letterSpacing: "-0.3px",
                            lineHeight: "140%",
                            fontWeight: "bold",
                            fontStyle: "normal",
                          }}
                          min_w='95%'
                          mtopw='20px'
                          mleft='10px'
                          mtop='15px'
                          weight='normal!important'
                        >
                          {detail.QuestionOption}
                        </CardsBtn>
                      ) : (
                        <CardsBtn
                          onClick={() => {
                            handleChangeCatOption({
                              step: Number(element),
                              value: detail.QuestionOption,
                            });
                            handleChangeQuestionsSend(
                              routine.QuestionsOrder,
                              detail.QuestionOption,
                              detail.CatFieldDetail_IDCatFieldDetail,
                              "Cat"
                            );
                          }}
                          style={{
                            borderRadius: "4px",
                            letterSpacing: "-0.3px",
                            lineHeight: "140%",
                            fontWeight: "bold",
                            fontStyle: "normal",
                          }}
                          min_w='95%'
                          min_web='47%'
                          mtopw='20px'
                          mleft='10px'
                          mtop='15px'
                          weight='normal!important'
                          color='#EC407A !important'
                          back='#fff !important'
                          border='solid 1px !important'
                        >
                          {detail.QuestionOption}
                        </CardsBtn>
                      )}
                    </div>
                  ))}
                </Fragment>
              );
            }
            if (otherDesignCat && typeSpecial === "radio") {
              return (
                <Fragment key={index}>
                  <RadioGroup
                    aria-label={index}
                    name={index}
                    value={valueGrup}
                    onChange={handleChange}
                  >
                    {routine.detailQuestion.map((detail, index) => (
                      <FormControlLabel
                        value={detail.QuestionOption}
                        control={<Radio />}
                        name={detail.QuestionOption}
                        onClick={() => {
                          handleChangeCatOption({
                            step: Number(element),
                            IDCatQuestionsDetail: Number(
                              detail.IDCatQuestionsDetail
                            ),
                            value: detail.QuestionOption,
                          });
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                        label={detail.QuestionOption}
                      />
                    ))}
                  </RadioGroup>
                </Fragment>
              );
            }
            if (otherDesignCat) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbar
                        onClick={() => {
                          handleChangeCatOption({
                            step: Number(element),
                            IDCatQuestionsDetail: Number(
                              detail.IDCatQuestionsDetail
                            ),
                            value: detail.QuestionOption,
                          });
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                        style={{
                          border: "1px solid #D2D2D2",
                          borderRadius: "4px",
                        }}
                      >
                        <QuestionsText size='14px' weight='400'>
                          {detail.QuestionOption}
                        </QuestionsText>
                      </QuestionsDivsbar>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (accordion) {
              return (
                <Fragment>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <Accordion
                        style={{
                          background: "#FAFAFA",
                          margin: 10,
                          borderRadius: 8,
                          border: "1px solid #CDCDCD",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1a-content'
                          id={index}
                        >
                          <Typography>{detail.QuestionOption}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{ background: "#E5E5E5", padding: 10 }}
                          >
                            {detail.descriptionCAT}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (classificationQuestionDetail.length > 0) {
              return (
                <Fragment>
                  {classificationQuestionDetail.map((task) => (
                    <Fragment>
                      {routine.detailQuestion.map((detail, index) => {
                        if (detail.QuestionOption === task) {
                          return (
                            <Fragment key={detail.IDCatQuestionsDetail}>
                              <div key={detail.IDCatQuestionsDetail}>
                                <QuestionsDivsbar
                                  onClick={(e) => {
                                    handleChangeCatOption({
                                      step: Number(element),
                                      IDCatQuestionsDetail: Number(
                                        detail.IDCatQuestionsDetail
                                      ),
                                      value: detail.QuestionOption,
                                    });
                                    handleChangeQuestionsSend(
                                      routine.QuestionsOrder,
                                      detail.QuestionOption,
                                      detail.CatFieldDetail_IDCatFieldDetail,
                                      "Cat"
                                    );
                                  }}
                                >
                                  <QuestionsText size='14px' weight='400'>
                                    {detail.QuestionOption}
                                  </QuestionsText>
                                </QuestionsDivsbar>
                              </div>
                            </Fragment>
                          );
                        }
                      })}
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (buttonImageType) {
              return (
                <div
                  key={index}
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {routine.detailQuestion.map((detail, index) => {
                    if (arrayFilter.includes(detail.QuestionOption)) {
                      return (
                        <Fragment key={detail.IDCatQuestionsDetail}>
                          <QuestionsDivs
                            hei='50px'
                            widthweb='30%'
                            width='48%'
                            marginLmd='0'
                            margin='auto 5px 20px 12%'
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 2px 8px rgba(0,0,0,0.08)",
                            }}
                            onClick={() => {
                              handleChangeCatOption({
                                step: Number(element),
                                IDCatQuestionsDetail: Number(
                                  detail.IDCatQuestionsDetail
                                ),
                                value: detail.QuestionOption,
                              });
                              handleChangeQuestionsSend(
                                routine.QuestionsOrder,
                                detail.QuestionOption,
                                detail.CatFieldDetail_IDCatFieldDetail,
                                "Cat"
                              );
                            }}
                          >
                            <DivTamFlex width='60%' ta=' '>
                              <Images
                                position='relative'
                                width={
                                  detail.QuestionOption === "Afore Coppel"
                                    ? "55px"
                                    : detail.QuestionOption === "Afore Sura"
                                    ? "45px"
                                    : "92px"
                                }
                                left='0'
                                leftw='0'
                                top='0'
                                src={detail.ImageURL}
                              ></Images>
                            </DivTamFlex>
                          </QuestionsDivs>
                        </Fragment>
                      );
                    }
                  })}
                </div>
              );
            }
          } else if (routine.QuestionType === "Multiple") {
            if (componentWo === "glove-box") {
              return (
                <GloveBoxCheckList 
                  key={index}
                  classCard='cardOne'
                  routine={routine}
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  steper={Number(element)}
                />
              );
            }
            if (activeStep && routine.Show && !changeCheck) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => {
                    if (detail.Status === 1 || detail.Status === "1") {
                      return (
                        <Fragment key={detail.IDCatQuestionsDetail}>
                          <div
                            key={detail.IDCatQuestionsDetail}
                            style={{ display: "flex" }}
                          >
                            <QuestionsDivsbar
                              onClick={(e) => {
                                handleChangeCatOption({
                                  step: Number(element),
                                  value: detail.QuestionOption,
                                  idField: detail.IDCatFieldDetail,
                                  IDCatQuestionsDetail: Number(
                                    detail.IDCatQuestionsDetail
                                  ),
                                });
                                handleChangeQuestionsSend(
                                  routine.QuestionsOrder,
                                  detail.QuestionOption,
                                  detail.CatFieldDetail_IDCatFieldDetail,
                                  "Multiple",
                                  e
                                );
                              }}
                              className='beneficios'
                              name={detail.QuestionOption}
                            >
                              {activeImgOption ? (
                                <Images
                                  position='initial'
                                  left='0'
                                  leftw='2%'
                                  width='30px'
                                  height='30px'
                                  top='0'
                                  src={
                                    arrayImagesOptions[
                                      detail.CatFieldDetail_IDCatFieldDetail
                                    ]
                                  }
                                ></Images>
                              ) : null}

                              <QuestionsText
                                margin='auto'
                                size='14px'
                                weight='400'
                              >
                                {detail.QuestionOption}
                              </QuestionsText>
                            </QuestionsDivsbar>
                            {AdditionalInformation &&
                            textInformation[index][`text${index + 1}`] !==
                              "" ? (
                              <>
                                <CustomTooltip
                                  displayText={
                                    textInformation[index][`text${index + 1}`]
                                  }
                                  displayLink={
                                    textInformation[index][
                                      `linkText${index + 1}`
                                    ]
                                  }
                                  link={
                                    textInformation[index][`link${index + 1}`]
                                  }
                                  content={
                                    <button
                                      style={{
                                        borderStyle: "none",
                                        background: "none",
                                        outline: "none !important",
                                        fontWeight: "normal!important",
                                      }}
                                    >
                                      <HelpOutlineIcon />
                                    </button>
                                  }
                                />
                              </>
                            ) : (
                              <div
                                style={{
                                  width: AdditionalInformation ? "40px" : "0px",
                                }}
                              ></div>
                            )}
                          </div>
                        </Fragment>
                      );
                    }
                  })}
                </Fragment>
              );
            }
            if (activeStep && routine.Show && changeCheck) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => {
                    if (detail.Status === 1 || detail.Status === "1") {
                      return (
                        <Fragment key={detail.IDCatQuestionsDetail}>
                          <div
                            key={detail.IDCatQuestionsDetail}
                            style={{ display: "flex" }}
                          >
                            <QuestionsDivsbar
                              display='flex'
                              onClick={(e) => {
                                handleChangeCatOption({
                                  step: Number(element),
                                  value: detail.QuestionOption,
                                  idField: detail.IDCatFieldDetail,
                                  IDCatQuestionsDetail: Number(
                                    detail.IDCatQuestionsDetail
                                  ),
                                });
                                handleChangeQuestionsSend(
                                  routine.QuestionsOrder,
                                  detail.QuestionOption,
                                  detail.CatFieldDetail_IDCatFieldDetail,
                                  "Multiple",
                                  e
                                );
                              }}
                              className='beneficios'
                              name={detail.QuestionOption}
                            >
                              <QuestionsText
                                ta='left'
                                margin='auto auto auto 15px'
                                size='14px'
                                weight='400'
                              >
                                {detail.QuestionOption}
                              </QuestionsText>
                              <DivTamFlex width='40%' mr='15px' ta='right'>
                                <Images
                                  className={
                                    detail.CatFieldDetail_IDCatFieldDetail
                                  }
                                  position='relative'
                                  width='30px'
                                  left='0'
                                  leftw='0'
                                  top='6px'
                                  src={uncheck}
                                ></Images>
                              </DivTamFlex>
                            </QuestionsDivsbar>
                          </div>
                        </Fragment>
                      );
                    }
                  })}
                </Fragment>
              );
            }
            if (
              routine.Show &&
              !separationCat &&
              !typeCheckBox &&
              classificationQuestionDetail.length === 0 &&
              componentWo === ""
            ) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivsbar
                        onClick={() => {
                          handleChangeCatOption(detail.QuestionOption);
                          handleChangeQuestionsSend(
                            routine.QuestionsOrder,
                            detail.QuestionOption,
                            detail.CatFieldDetail_IDCatFieldDetail,
                            "Cat"
                          );
                        }}
                      >
                        <QuestionsText size='14px' weight='400'>
                          {detail.QuestionOption}
                        </QuestionsText>
                      </QuestionsDivsbar>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (separationCat) {
              return (
                <Fragment>
                  {getRoutinesByIdTraining[0].questions.map(
                    (routine, index) => {
                      if (routine.QuestionType === "Multiple") {
                        return (
                          <Fragment>
                            <div
                              style={{
                                display: "flex",
                                margin: "auto",
                                background: "#F0F0F0",
                                justifyContent: "space-between",
                                width: "80%",
                                height: "40px",
                                borderRadius: "5px",
                                alignItems: "center",
                                marginTop: "20px",
                                padding: "5px",
                              }}
                            >
                              {routine.QuestionName}
                              <OverlayTrigger
                                key={index}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    {routine.Description}
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{
                                    borderStyle: "none",
                                    background: "none",
                                    outline: "none !important",
                                    fontWeight: "normal!important",
                                  }}
                                >
                                  <HelpOutlineIcon />
                                </button>
                              </OverlayTrigger>
                            </div>
                            {routine.detailQuestion.map((detail, index) => (
                              <div
                                key={detail.IDCatQuestionsDetail}
                                style={{ display: "flex" }}
                              >
                                <QuestionsDivsbar
                                  onClick={(e) => {
                                    handleChangeCatOption({
                                      step: Number(element),
                                      value: detail.QuestionOption,
                                      idField: detail.IDCatFieldDetail,
                                      IDCatQuestionsDetail: Number(
                                        detail.IDCatQuestionsDetail
                                      ),
                                    });
                                    handleChangeQuestionsSend(
                                      routine.QuestionsOrder,
                                      detail.QuestionOption,
                                      detail.CatFieldDetail_IDCatFieldDetail,
                                      "Multiple",
                                      e
                                    );
                                  }}
                                  className='beneficios'
                                  name={detail.QuestionOption}
                                >
                                  <QuestionsText size='14px' weight='400'>
                                    {detail.QuestionOption}
                                  </QuestionsText>
                                </QuestionsDivsbar>
                              </div>
                            ))}
                          </Fragment>
                        );
                      }
                    }
                  )}
                </Fragment>
              );
            }
            if (typeCheckBox && !typeCheckBoxDescription) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <FormControlLabel
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        control={
                          <Checkbox
                            className={classes.root}
                            checked={form[checkBoxName + (index + 1)]}
                            name={checkBoxName + (index + 1)}
                            onChange={handleChange}
                            value={detail.QuestionOption}
                            disableRipple
                            color='default'
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                        label={detail.QuestionOption}
                        labelPlacement='start'
                      />
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (typeCheckBox && typeCheckBoxDescription) {
              return (
                <Fragment key={index}>
                  {routine.detailQuestion.map((detail, index) => (
                    <Fragment key={detail.IDCatQuestionsDetail}>
                      <QuestionsDivs
                        bc='#FFFFFF'
                        width='80%'
                        margin='8px auto auto;'
                        hei='auto'
                        padd='5px 15px 5px 5px'
                        txta='left'
                        bs='0px 2px 8px rgba(0,0,0,0.08)'
                      >
                        <FormControlLabel
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          control={
                            <Checkbox
                              className={classes.root}
                              checked={form[checkBoxName + (index + 1)]}
                              name={checkBoxName + (index + 1)}
                              onChange={handleChange}
                              value={detail.FieldOption}
                              disableRipple
                              color='default'
                              style={{ padding: "9px 30px" }}
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.icon,
                                    classes.checkedIcon
                                  )}
                                />
                              }
                              icon={<span className={classes.icon} />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={detail.FieldOption}
                          labelPlacement='start'
                        />
                        <DivStyle
                          id={`${detail.IDCatQuestionsDetail}-mas`}
                          onClick={() => eventMore(detail.IDCatQuestionsDetail)}
                          cursor='pointer'
                          className='cabin'
                          mxh='30px'
                          weight='600'
                          align='left'
                          size='12px'
                          line='1.5em'
                          padd='12px 0 0 15px'
                          color='#07210A'
                        >
                          Leer más
                        </DivStyle>

                        <TrainingDivSection
                          wi='100%'
                          br='4px'
                          brw='4px'
                          back='#FFFFFF'
                          backw='#FFFFFF'
                          id={detail.IDCatQuestionsDetail}
                          className='hidden'
                        >
                          <p>{detail.descriptionCAT}</p>
                          <DivStyle
                            id={`${detail.IDCatQuestionsDetail}-menos`}
                            onClick={() =>
                              eventHidden(detail.IDCatQuestionsDetail)
                            }
                            cursor='pointer'
                            className='cabin'
                            mxh='30px'
                            weight='600'
                            align='left'
                            size='12px'
                            line='1.5em'
                            padd='12px 0 0 0'
                            color='#07210A'
                          >
                            Mostrar menos
                          </DivStyle>
                        </TrainingDivSection>
                      </QuestionsDivs>
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (classificationQuestionDetail.length > 0) {
              return (
                <Fragment>
                  {classificationQuestionDetail.map((task) => (
                    <Fragment>
                      {routine.detailQuestion.map((detail, index) => {
                        if (detail.QuestionOption === task) {
                          return (
                            <Fragment key={detail.IDCatQuestionsDetail}>
                              <div
                                key={detail.IDCatQuestionsDetail}
                                style={{ display: "flex" }}
                              >
                                <QuestionsDivsbar
                                  onClick={(e) => {
                                    handleChangeCatOption({
                                      step: Number(element),
                                      value: detail.QuestionOption,
                                      idField: detail.IDCatFieldDetail,
                                      IDCatQuestionsDetail: Number(
                                        detail.IDCatQuestionsDetail
                                      ),
                                    });
                                    handleChangeQuestionsSend(
                                      routine.QuestionsOrder,
                                      detail.QuestionOption,
                                      detail.CatFieldDetail_IDCatFieldDetail,
                                      "Multiple",
                                      e
                                    );
                                  }}
                                  className='beneficios'
                                  name={detail.QuestionOption}
                                >
                                  <QuestionsText
                                    margin='auto'
                                    size='14px'
                                    weight='400'
                                  >
                                    {detail.QuestionOption}
                                  </QuestionsText>
                                </QuestionsDivsbar>
                              </div>
                            </Fragment>
                          );
                        }
                      })}
                    </Fragment>
                  ))}
                </Fragment>
              );
            }
            if (componentWo === "ant-expenses-streaming") {
              return (
                <Fragment>
                  <InputsColumsTypeNumber
                    key={index}
                    handleChangeCatOption={handleChangeCatOption}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    paramsCat={{
                      step: Number(element),
                      value: routine.QuestionName,
                    }}
                    QuestionsOrder={routine.QuestionsOrder}
                    QuestionName={routine.QuestionName}
                    QuestionOption={routine.QuestionName}
                    QuestionType='Multiple'
                    amountService={amountService}
                    streaming={streaming}
                    changeColor={changeColor}
                  />
                </Fragment>
              );
            }
          } else if (routine.QuestionType === "Video") {
            if (activeStep && routine.Show) {
              return (
                <Fragment key='video'>
                  <ContainerVideo mt='0px'>
                    {routine.detailQuestion.map((detail, index) => (
                      <Fragment key={detail.IDCatQuestionsDetail}>
                        <PlayYoutube
                          idVideo={
                            detail.QuestionOption.includes("http")
                              ? detail.QuestionOption.split("/embed/")[1]
                              : ""
                          }
                          width={"100%"}
                          height={"190"}
                          close={false}
                        />
                      </Fragment>
                    ))}
                  </ContainerVideo>
                </Fragment>
              );
            }
            if (routine.Show) {
              return (
                <Fragment>
                  <div className='embed-responsive embed-responsive-4by3'>
                    {routine.detailQuestion.map((detail, index) => (
                      <Fragment key={detail.IDCatQuestionsDetail}>
                        <PlayYoutube
                          idVideo={
                            detail.QuestionOption.includes("http")
                              ? detail.QuestionOption.split("/embed/")[1]
                              : ""
                          }
                          width={"100%"}
                          height={"190"}
                          close={false}
                        />
                      </Fragment>
                    ))}
                  </div>
                </Fragment>
              );
            }
          } else if (routine.QuestionType === "SelectBasic") {
            return (
              <GloveBoxSelect
                key={index}
                routine={routine}
                form={form}
                handleChange={handleChange}
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                amountService={parseInt(amountService, 10)}
                meses={arrayFilter}
              />
            );
          } else if (routine.QuestionType === "DocUpload") {
            return (
              <DocUpload
                key={index}
                routine={routine}
                form={form}
                handleChange={handleChange}
              />
            );
          }
        })}
      </Fragment>
    );
  return <Fragment>{content}</Fragment>;
};

export default InputsOrder;
