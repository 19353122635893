import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DYNAMIC_GET, UPDATE_PERSONAL_DATA } from '../../../../mutations';
import CustomStepLayout from '../../layouts/CustomStepLayout';
import maleAvatar from '../../../../assets/img/anacoach/svg/gender_male_option.svg';
import femaleAvatar from '../../../../assets/img/anacoach/svg/gender_female_option.svg';
import anonimousAvatar from '../../../../assets/img/anacoach/svg/gender_anonimous_option.svg';
import './styles/customDataViewer.css';

const PersonalDataViewer = ({
  customColor,
  coachImg,
  type,
  nextHandler,
  handleNextParam,
  appearAnimation,
  disappearAnimation,
  isActive,
  backHandler,
}) => {
  const [checkboxState, setCheckboxState] = useState({
    saveData: false,
  });

  const [updatePersonalData] = useMutation(UPDATE_PERSONAL_DATA);

  /* Default info data to show. This data can be replaced with prop 'stepData' */
  const defaultContent = {
    title: '¡Genial!',
    description: 'Revisa que tus datos sean correctos.',
    intructions: '',
    saveCheckText: 'Guardar datos para futuras cotizaciones',
  };

  const getBirthDate = (datedata) => {
    let date = sessionStorage.getItem('temp-BirthDate')
      || sessionStorage.getItem('BirthDate')
      || datedata
      || '';

    if (date === '') return date;

    date = new Date(date);

    date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return date;
  };

  const getFullName = (obj) => {
    const FirstName = sessionStorage.getItem('temp-FirstName')
      || sessionStorage.getItem('FirstName')
      || (obj ? obj.FirstName : '');
    const MiddleName = sessionStorage.getItem('temp-MiddleName')
      || sessionStorage.getItem('MiddleName')
      || (obj ? obj.MiddleName : '');
    const FathersLastName = sessionStorage.getItem('temp-FathersLastName')
      || sessionStorage.getItem('FathersLastName')
      || (obj ? obj.FathersLastName : '');
    const MothersLastName = sessionStorage.getItem('temp-MothersLastName')
      || sessionStorage.getItem('MothersLastName')
      || (obj ? obj.MothersLastName : '');

    if (MiddleName) return `${FirstName} ${MiddleName} ${FathersLastName} ${MothersLastName}`;
    return `${FirstName} ${FathersLastName} ${MothersLastName}`;
  };

  const [viewerData, setViewerData] = useState({
    Gender:
      sessionStorage.getItem('temp-Gender')
      || sessionStorage.getItem('Gender')
      || '',
    FullName: getFullName(),
    FirstName:
      sessionStorage.getItem('temp-FirstName')
      || sessionStorage.getItem('FirstName')
      || '',
    MiddleName:
      sessionStorage.getItem('temp-MiddleName')
      || sessionStorage.getItem('MiddleName')
      || '',
    FathersLastName:
      sessionStorage.getItem('temp-FathersLastName')
      || sessionStorage.getItem('FathersLastName')
      || '',
    MothersLastName:
      sessionStorage.getItem('temp-MothersLastName')
      || sessionStorage.getItem('MothersLastName')
      || '',
    Email1:
      sessionStorage.getItem('temp-Email1')
      || sessionStorage.getItem('Email1')
      || '',
    CellPhone:
      sessionStorage.getItem('temp-CellPhone')
      || sessionStorage.getItem('CellPhone')
      || '',
    ZipCode:
      sessionStorage.getItem('temp-ZipCode')
      || sessionStorage.getItem('ZipCode')
      || '',
    BirthDate: getBirthDate(),
  });

  const [dynamicGet] = useMutation(DYNAMIC_GET, {
    onCompleted: (dynamicData) => {
      dynamicData = JSON.parse(dynamicData.dynamicGet.response)[0];

      if (!dynamicData) return;

      if (
        dynamicData.FirstName
        || dynamicData.MiddleName
        || dynamicData.FathersLastName
        || dynamicData.MothersLastName
        || dynamicData.BirthDate
        || dynamicData.Gender
      ) {
        setViewerData({
          ...viewerData,
          Gender:
            sessionStorage.getItem('temp-Gender')
            || sessionStorage.getItem('Gender')
            || dynamicData.Gender
            || '',
          FullName: getFullName(dynamicData),
          FirstName:
            sessionStorage.getItem('temp-FirstName')
            || sessionStorage.getItem('FirstName')
            || dynamicData.FirstName
            || '',
          MiddleName:
            sessionStorage.getItem('temp-MiddleName')
            || sessionStorage.getItem('MiddleName')
            || dynamicData.MiddleName
            || '',
          FathersLastName:
            sessionStorage.getItem('temp-FathersLastName')
            || sessionStorage.getItem('FathersLastName')
            || dynamicData.FathersLastName
            || '',
          MothersLastName:
            sessionStorage.getItem('temp-MothersLastName')
            || sessionStorage.getItem('MothersLastName')
            || dynamicData.MothersLastName
            || '',
          BirthDate: getBirthDate(dynamicData.BirthDate),
        });
      }

      if (dynamicData.Email1 || dynamicData.CellPhone) {
        setViewerData({
          ...viewerData,
          Email1:
            sessionStorage.getItem('temp-Email1')
            || sessionStorage.getItem('Email1')
            || dynamicData.Email1
            || '',
          CellPhone:
            sessionStorage.getItem('temp-CellPhone')
            || sessionStorage.getItem('CellPhone')
            || dynamicData.CellPhone
            || '',
        });
      }

      if (dynamicData.ZipCode) {
        setViewerData({
          ...viewerData,
          ZipCode:
            sessionStorage.getItem('temp-ZipCode')
            || sessionStorage.getItem('ZipCode')
            || dynamicData.ZipCode
            || '',
        });
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const dynamicInputA = {
        table:
          'CatUsers as cu INNER JOIN CatContact as cc ON cu.CatContact_IDCont = cc.IDCont INNER JOIN CatContactMedium as cm ON cc.IDCont = cm.CatContact_IDCont',
        columns: ['cm.Email1', 'cm.CellPhone'],
        conditions: [
          {
            valone: 'cu.IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser') || '0',
          },
          {
            logic: '',
            valone: '',
            condition: '',
            valtwo: '',
            validColumn: false,
          },
        ],
      };

      const dynamicInputB = {
        table:
          'CatUsers as cu INNER JOIN CatContact as cc ON cu.CatContact_IDCont = cc.IDCont ',
        columns: [
          'cc.Gender',
          'cc.BirthDate',
          'cc.FirstName',
          'cc.MiddleName',
          'cc.FathersLastName',
          'cc.MothersLastName',
        ],
        conditions: [
          {
            valone: 'cu.IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser') || '0',
          },
          {
            logic: '',
            valone: '',
            condition: '',
            valtwo: '',
            validColumn: false,
          },
        ],
      };

      const dynamicInputC = {
        table:
          'CatUsers as cu INNER JOIN CatAddress as ca ON cc.IDCont = ca.CatContact_IDCont',
        columns: ['ca.ZipCode'],
        conditions: [
          {
            valone: 'cu.IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser') || '0',
          },
          {
            logic: '',
            valone: '',
            condition: '',
            valtwo: '',
            validColumn: false,
          },
        ],
      };

      await dynamicGet({
        variables: {
          input: dynamicInputA,
        },
      });

      await dynamicGet({
        variables: {
          input: dynamicInputB,
        },
      });

      await dynamicGet({
        variables: {
          input: dynamicInputC,
        },
      });
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updateInput = { ...viewerData };

    const tempDay = viewerData.BirthDate.split('/')[0];
    const tempMonth = viewerData.BirthDate.split('/')[1];
    const tempYear = viewerData.BirthDate.split('/')[2];

    const BirthDate = `${tempYear}-${tempMonth}-${tempDay}`;

    delete updateInput.FullName;
    updateInput = {
      ...updateInput,
      BirthDate,
      idUser: parseInt(sessionStorage.getItem('idUser') || '0'),
    };

    await updatePersonalData({
      variables: {
        input: updateInput,
      },
    });

    nextHandler(handleNextParam);
  };

  /* Checkbox state handler */
  const handleCheckChecked = (e) => {
    setCheckboxState({
      ...checkboxState,
      [e.target.name]: e.target.checked,
    });
  };

  /* Function to get custom color for checkbox if it is selected */
  const getActiveCheckedColor = (opc) => {
    if (checkboxState[opc] === true) {
      return {
        backgroundColor: customColor,
      };
    }
    return {};
  };

  /* Function to get text */
  const getText = (name) => (
    <div
      className={`${name} ${
        defaultContent[name] && defaultContent[name].trim() === ''
          ? 'no-margin'
          : ''
      }`}
    >
      {defaultContent[name]}
    </div>
  );

  const getImage = () => {
    const gender = viewerData.Gender;
    if (!gender) return anonimousAvatar;

    switch (gender.toLowerCase()) {
      case 'masculino':
        return maleAvatar;

      case 'femenino':
        return femaleAvatar;

      default:
        return anonimousAvatar;
    }
  };

  return (
    <CustomStepLayout
      customColor={customColor}
      coachImg={coachImg}
      stepClass="step-static-custom-data-viewer"
      type={type}
      isActive={isActive}
      appearAnimation={appearAnimation}
      disappearAnimation={disappearAnimation}
      canGoBack={false}
      backHandler={backHandler}
    >
      <div className="text-content">
        {getText('title')}

        {getText('description')}
      </div>

      <div className="image-info circular-image">
        <img src={getImage()} alt="" />
      </div>

      <table className="info-content-table">
        <button
          className="edit-data"
          onClick={() => backHandler('personalDataViewer')}
        >
          Editar datos
        </button>

        <tr>
          <td className="item-title">Nombre completo:</td>
          <td className="item-value">{viewerData.FullName}</td>
        </tr>

        <tr>
          <td className="item-title">Correo electrónico:</td>
          <td className="item-value">{viewerData.Email1}</td>
        </tr>

        <tr>
          <td className="item-title">Teléfono:</td>
          <td className="item-value">{viewerData.CellPhone}</td>
        </tr>

        <tr>
          <td className="item-title">Código postal:</td>
          <td className="item-value">{viewerData.ZipCode}</td>
        </tr>

        <tr>
          <td className="item-title">Fecha de nacimiento:</td>
          <td className="item-value">{viewerData.BirthDate}</td>
        </tr>
      </table>

      <div className="check-content">
        <label className="check-option">
          <p>{defaultContent.saveCheckText}</p>
          <input
            type="checkbox"
            name="saveData"
            onChange={handleCheckChecked}
          />
          <span
            className="checkbox"
            style={getActiveCheckedColor('saveData')}
          ></span>
        </label>
      </div>

      <button className="custom-button" onClick={handleSubmit}>
        Siguiente
      </button>
    </CustomStepLayout>
  );
};

export default PersonalDataViewer;
