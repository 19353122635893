import React, { Fragment, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import {
  ContainerPerfiles,
  TextPerfil,
} from '../../assets/styles/pantInicio.styled';
import {
  Main,
  Container,
  Col2,
  Col12,
  Col10,
  StatusColor,
} from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import FooterFeed from '../../components/Feed/Footer_Feed';
import Address from '../../components/Perfiles/FormAddress';
import Header_Logros from '../../components/Perfiles/Header_Perfil';

function Direcction_Profile({ Novisible, paddingInicio }) {
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  });
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  const [status, Setstatus] = useState({
    Dom1: true,
    Dom2: false,
  });
  const handleChange = () => {
    Setstatus({
      Dom1: false,
      Dom2: true,
    });
    status.Dom2 = true;
  };

  const [defaultAddress, SetDefaultAddress] = useState({
    address1: true,
    address2: false,
  });

  const Updatedefault = ({ number }) => {
    if (number === '1') {
      if (status.Dom2 && defaultAddress.address1) {
        SetDefaultAddress({
          address1: false,
          address2: true,
        });
      } else if (status.Dom2) {
        SetDefaultAddress({
          address1: true,
          address2: false,
        });
      }
    } else if (defaultAddress.address2) {
      SetDefaultAddress({
        address1: true,
        address2: false,
      });
    } else {
      SetDefaultAddress({
        address1: false,
        address2: true,
      });
    }
  };

  const handleClickDelete = () => {
    Setstatus({
      Dom1: true,
      Dom2: false,
    });
  };
  return (
    <Fragment>
      {!Novisible ? <Header_Logros /> : <div></div>}
      <Main
        background=" "
        pb={!paddingInicio ? '35%' : ' '}
        mt="0%"
        mtWeb="0%"
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
        overflow=" "
      >
        <Container
          displayweb="block"
          paddingMobile="0px"
          className="container"
          width="94%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <Address
            number="1"
            visibility={true}
            more={defaultAddress.address1}
            mt={'0px'}
            check={true}
          ></Address>
          {!status.Dom2 ? (
            <Container
              width="100%"
              mt="0%"
              mb="0px"
              widthMobile="94%"
              mtMobile="3.5%"
            >
              <ContainerPerfiles
                padding="4%"
                height="auto"
                minheight="auto"
                display="flex"
              >
                <Container
                  width="100%"
                  widthMobile="100%"
                  mtMobile="0px"
                  float="auto"
                  mMobile="auto"
                  onClick={handleChange}
                >
                  <Col12 pt="0px" ptMobile="0px" pMobile="0px">
                    <Container width="100%" widthMobile="100%" mtMobile="0px">
                      <Col10 pt="0px" padding="0px">
                        <TextPerfil
                          fw="400"
                          fs="20px"
                          mt="0%"
                          color="rgb(17, 14, 32)"
                          fsMobile="17px"
                          mlMobile="0%"
                          lh="20px"
                        >
                          Agregar un domicilio
                        </TextPerfil>
                      </Col10>
                      <Col2 mt="0px" padding="0px" margin="0px">
                        <StatusColor
                          backcolor="#fff"
                          ta="center"
                          margin="auto"
                          size="19px"
                          width="30px"
                          height="30px"
                        >
                          +
                        </StatusColor>
                      </Col2>
                    </Container>
                  </Col12>
                </Container>
              </ContainerPerfiles>
            </Container>
          ) : (
            <div></div>
          )}
          {status.Dom2 ? (
            <div style={{ marginBottom: '0px' }}>
              <Address
                number="2"
                mt={'5%'}
                check={defaultAddress.address2}
                Updatedefault={Updatedefault}
                handleClickDelete={handleClickDelete}
              ></Address>
            </div>
          ) : (
            <div></div>
          )}
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="perfil" /> : <div></div>}
    </Fragment>
  );
}
export default Direcction_Profile;
