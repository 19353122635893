import React, { Fragment } from 'react';
import NVD3Chart from 'react-nvd3';
import { TrainingText, DivAdvanceGoals, QuestionsFlex } from '../../assets/styles/Training.styled';

const SectionAdvance = ({
  datum, avance, tiempodeAhorro, goal, mesesConcluidos = 0,
}) => (
      <Fragment>
         <DivAdvanceGoals ww='60%' marginLGeneric='auto' marginL='18%' mt="26px">
            <QuestionsFlex>
               <div style={{ width: '40%', margin: 'auto' }}>
                  <NVD3Chart
                     id="chart"
                     showLegend={false}
                     showLabels={false}
                     height={118}
                     type="pieChart"
                     datum={datum}
                     x="key"
                     y="y"
                     labelThreshold={0.05}
                     donut
                     donutRatio={0.5}
                     labelType="percent"
                     containerStyle={{
                       marginTop: '0', display: 'flex', justifyContent: 'left', alignItems: 'left',
                     }}
                  />
                  <TrainingText className="roboto" color="#07210A" align="center" size="14px" sizew="14px" lh="120%" weight="500">
                     Tu avance {avance}
                  </TrainingText>
               </div>
               <div style={{ width: '60%', margin: 'auto' }}>
                  <TrainingText className="roboto" color="#07210A" align="left" size="16px" sizew="16px" lh="120%" weight="bold" bot="5px">
                     {goal}
                  </TrainingText>
                  <TrainingText className="roboto" color="#615959" align="left" size="14px" sizew="14px" lh="120%" weight="500">
                     Te recomendamos
                  </TrainingText>
                  <TrainingText className="roboto" color="#07210A" align="left" size="16px" sizew="16px" lh="120%" weight="bold" bot="5px">
                     {mesesConcluidos} de {tiempodeAhorro > 1 ? `${tiempodeAhorro} meses` : `${tiempodeAhorro} mes`}
                  </TrainingText>
                  <TrainingText className="roboto" color="#615959" align="left" size="14px" sizew="14px" lh="120%" weight="500" bot="0">
                     Tiempo para meta
                  </TrainingText>
               </div>
            </QuestionsFlex>
         </DivAdvanceGoals>
      </Fragment>
);

export default SectionAdvance;
