import React, { Fragment } from 'react';
import {
  TrainingText,
  DivAdvanceGoals,
  QuestionsFlex,
  Images,
} from '../../assets/styles/Training.styled';
import happyface from '../../assets/img/anacoach/HappyFace.svg';

function SectionHappyFace() {
  return (
        <Fragment>
            <DivAdvanceGoals ww='60%' marginL='18%' mt="26px" h="110px">
                <QuestionsFlex>
                    <div style={{ width: '40%', margin: 'auto' }}>
                        <QuestionsFlex>
                            <div style={{ padding: '18px' }}>
                                <Images
                                    id='happy'
                                    className="evalua"
                                    position="initial"
                                    left="0"
                                    width="68px"
                                    height="auto"
                                    src={happyface}
                                ></Images>
                            </div>
                        </QuestionsFlex>
                    </div>
                    <div style={{ width: '60%', margin: 'auto' }}>
                        <TrainingText
                            className="roboto"
                            color="#07210A"
                            align="left"
                            size="16px"
                            sizew="16px"
                            lh="120%"
                            weight="bold"
                            bot="5px"
                        >
                        Lo estás haciendo increible!!
                        </TrainingText>
                    </div>
                </QuestionsFlex>
            </DivAdvanceGoals>
        </Fragment>
  );
}

export default SectionHappyFace;
