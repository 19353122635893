//Librerías
import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./config/apollo";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./routes";
import { createStore } from "redux";
import { Provider } from "react-redux";
import {
  getTransactionID,
  getLocalStorage,
  setTraining,
  validActivity,
} from "./Helpers/HelperUTM";
import reducer from "./store/reducer";
import IdleTimer from "react-idle-timer";
import Whatsapp from "./components/Training/Whatsapp";
//ScrollToTop
import ScrollToTop from "./components/ScrollToTop";

//URL de Router-Index
import ReactGA from "react-ga";
const onActive = () => {
  console.log("### onActive");
};

const onIdle = () => {
  console.log("### onIdle");
};

const onAction = () => {
  // console.log("### onAction")
};

const store = createStore(reducer);
function App() {
  validActivity();
  setInterval(validActivity, 1000);
  getLocalStorage();
  if (!sessionStorage.getItem("ID_GA")) {
    ReactGA.initialize("UA-53999647-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.ga((tracker) => {
      let id_ga = tracker.get("clientId");
      sessionStorage.setItem("ID_GA", id_ga);
    });
  }
  if (!sessionStorage.getItem("validTransaction")) {
    getTransactionID();
  }
  let path = window.location.pathname;
  setTraining(path);
  return (
    <ApolloProvider client={client()}>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Routes />
          </Switch>
          <IdleTimer
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            onAction={onAction}
            debounce={250}
            timeout={1000 * 5 * 1}
          />
          {window.location.pathname !== "/caas/es/" ||
            window.location.pathname !== "/caas/en/" ||
            window.location.pathname !== "/caas/pt/" && (
              <Whatsapp url={window.location.pathname} id="Whatsapp" />
            )}
        </Router>
      </Provider>
    </ApolloProvider>
  );
}

export default App;