import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  DivFlex,
  TrainingText,
  BorderTask,
  StatusTask,
} from '../../assets/styles/Training.styled';

function TasksReward({ datos, newRewards, handleChange }) {
  return (
        <Fragment>
            {datos.map((post) => (
                <>
                    <DivFlex widthweb="70%" marweb="auto">
                        <BorderTask>
                            <TrainingText size="18px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                {post.taskName}
                            </TrainingText>
                            <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                {post.taskTime}
                            </TrainingText>
                            <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                {post.taskInfo}
                            </TrainingText>
                        </BorderTask>

                        {post.taskStatus === 'Incomplete'
                          ? <TextField
                                variant="outlined"
                                name={`newReward_${post.idDatHomework}`}
                                autoComplete="off"
                                placeholder=""
                                required
                                inputProps={{
                                  style: {
                                    width: 70,
                                  },
                                }}
                                value={newRewards[`newReward_${post.idDatHomework}`]}
                                onChange={handleChange}
                            />
                          : <StatusTask bColor="#27AE60">
                                {post.taskMoney}
                            </StatusTask>
                        }
                    </DivFlex>
                    <DivFlex widthweb="70%" marweb="auto">
                        {post.taskStatus === 'Incomplete'
                          ? <TextField
                                variant="outlined"
                                fullWidth
                                disable={true}
                                focused={false}
                                value={post.comment}
                            /> : null
                        }
                    </DivFlex>
                </>
            ))
            }
        </Fragment>
  );
}
export default TasksReward;
