import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomStepLayout from '../../layouts/CustomStepLayout';

/*

- data : data state for question

- setData : setter for data

- handleFunction : function to handle "next/continue" button

- handleChange : function to handle onChange input event

*/

const CustomQuestion = ({
  customColor,
  coachImg,
  stepData,
  type,
  nextHandler,
  handleNextParam,
  handleChange,
  appearAnimation,
  disappearAnimation,
  isActive,
  canGoBack,
  backText,
  backHandler,
  backHandlerOption,
  questions,
}) => {
  const stepLayoutProps = {
    customColor,
    coachImg,
    type,
    isActive,
    appearAnimation,
    disappearAnimation,
    canGoBack,
    backText,
    backHandler,
  };

  const [buttonActive, setButtonActive] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // handleFunction(data);
  };

  /* Default info data to show. This data can be replaced with prop 'stepData' */
  const defaultContent = {
    title: 'Titulo',
    description: '',
    descriptionWithLink: {
      text: '', // example : "Para saber más, de click"
      linkText: '', // example : "aquí"
      type: '', // options : "origin" - "out"
      path: '', // example : "the-training-feed" - "https://www..."
    },
    instructions: '',
  };

  /* Function to load inputs by row */
  const loadInputs = (items, idx) => (
    <div className={`input-row ${items.className || ''}`}>
      {items.label}
      {items.rowComponents.map((item, iidx) => loadInputType(item, `${item.name}-${idx}-${iidx}`))}
    </div>
  );

  const loadInput = (item) => {
    const questionType = item.QuestionType;

    switch (questionType) {
      case 'Cat':
        break;

      default:
        break;
    }
  };

  /* Function to load inputs */
  const loadInputType = (item, key) => {
    switch (item.type) {
      case 'text':
        return (
          <input
            type="text"
            name={item.name}
            placeholder={item.placeholder}
            onChange={handleChange}
            required={item.required || false}
          />
        );

      case 'number':
        return (
          <input
            type="number"
            name={item.name}
            placeholder={item.placeholder}
            onChange={handleChange}
            required={item.required || false}
          />
        );

      case 'select':
        return (
          <select name={item.name} onChange={handleChange}>
            <option value={item.defaultValue} hidden>
              {item.placeholder}
            </option>
            {item.fillOptions()}
          </select>
        );

      case 'multi-option-img':
        return (
          <div className="multi-option-img">
            {item.options.map((option, idx) => (
              <div className="option">
                <button
                  onClick={
                    option.optionHandler
                      ? () => item.optionHandler(option.optionHandler)
                      : item.optionHandler
                  }
                >
                  <img src={option.src} />
                  <label>{option.label}</label>
                </button>
              </div>
            ))}
          </div>
        );
    }
  };

  /* Function to get text */
  const getText = (name) =>
    /*
            If text parameter is set in props, use it
            If text parameter is not set in props, then use default text parameter
            If text parameter is set in props but it is an empty string, then remove margin from div so as not to ocuppy space
        */
    (
      <div
        className={`${name} ${
          stepData && stepData[name] && stepData[name].trim() === ''
            ? 'no-margin'
            : ''
        }`}
      >
        {stepData
          ? stepData[name] || defaultContent[name]
          : defaultContent[name]}
      </div>
    )
  ;

  /* Function to get text from parameter descriptionWithLink */
  const getDescriptionLinkText = () => {
    /*
            If descriptionWithLink parameter is set in props, use it
            If parameter "type" is "origin", use a Link component
            If parameter "type" is not  "origin", use anchor tag
        */
    if (!stepData || !stepData.descriptionWithLink) return <></>;

    const content = stepData.descriptionWithLink;

    return (
      <p className="description">
        {content.text}{' '}
        {content.type === 'origin' ? (
          <Link to={content.path}>{content.linkText}</Link>
        ) : (
          <a href={content.path}>{content.linkText}</a>
        )}
      </p>
    );
  };

  return (
    <CustomStepLayout
      {...stepLayoutProps}
      stepClass="step-dynamic-custom-question"
    >
      <div className="text-content">
        {getText('title')}

        {getText('description')}

        {getDescriptionLinkText()}
      </div>

      <form onSubmit={handleSubmit}>
        {getText('instructions')}

        {/* Trabajar en el onChange */}

        {questions
          ? questions.map((items, idx) => loadInputs(items, idx))
          : ''}

        <div className="button-container">
          <button
            className={`custom-text-button ${buttonActive ? 'active' : ''}`}
          >
            Siguiente {'>'}{' '}
          </button>
        </div>
      </form>
    </CustomStepLayout>
  );
};

export default CustomQuestion;
