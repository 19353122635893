import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import SelectGoals from '../Routines/SelectGoals';
import SelectionGoalsOrder from '../Routines/SelectionGoalsOrder';
import AddGoal from '../Routines/AddGoal';
import SavingsAmountRecord from '../Routines/SavingsAmountRecord';
import {
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import {
  GET_TRAINING_COLOR,
  VERIFY_USER_WITH_TRAINING,
  GET_HISTORY_GOALS,
  GET_RESPONSE_USER_BY_IDTYPE,
} from '../../queries';
import ModalRecomendacionTraining from '../../components/common/ModalRecomendacionTraining';
import ShareTraining from '../../components/common/ShareTraining';
import ViewGoals from '../../components/common/ViewGoals';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderWoGoals = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  setSaludo,
  setShowGoals,
  updateProgress,
}) => {
  const [nameGoal, setNameGoal] = useState('');
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  const [WOStatusBudget, setWOStatusBudget] = useState(false);
  const [validateStatusTraining, setValidateStatusTraining] = useState(true);

  const [verifyUserWithTraining] = useLazyQuery(VERIFY_USER_WITH_TRAINING, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ verifyUserWithTraining }) {
      console.log('verifyUserWithTraining', verifyUserWithTraining);
      if (verifyUserWithTraining.message === 'success') {
        const json = JSON.parse(verifyUserWithTraining.response);

        if (json[0].Achievment === '100' || json[0].Achievment === 100) {
          setWOStatusBudget(false);
        } else {
          setWOStatusBudget(true);

          console.log(
            historyGoals.length,
            '== 0 &&',
            WOStatusBudget,
            ' &&',
            !validateStatusTraining,
          );
        }
      } else {
        setWOStatusBudget(true);

        console.log(
          historyGoals.length,
          '== 0 &&',
          WOStatusBudget,
          ' &&',
          !validateStatusTraining,
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [dineroDisponible, setDineroDisponible] = useState('');
  const [propertiesStatusGoals, setPropertiesStatusGoals] = useState([]);
  const [getResponseUserByIdType] = useLazyQuery(GET_RESPONSE_USER_BY_IDTYPE, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ getResponseUserByIdType }) {
      try {
        const json = JSON.parse(getResponseUserByIdType.response);
        console.log('types', json);
        if (json.length > 0) {
          const ingreso = json[0].Value.replace('$', '')
            .replace(/,/g, '')
            .trim();
          const gastoFijo = json[1].Value.replace('$', '')
            .replace(/,/g, '')
            .trim();
          const gastoExtra = json[2].Value.replace('$', '')
            .replace(/,/g, '')
            .trim();

          const disponble = ingreso - gastoFijo - gastoExtra;
          setDineroDisponible(disponble);
        }
      } catch (error) {
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getResponseUserByIdType({
      variables: {
        types: '308,310,311',
        idUser: sessionStorage.getItem('idUser'),
      },
    });
  }, []);
  const [historyGoals, setHistoryGoals] = useState([]);
  const [getHistoryGoals] = useLazyQuery(GET_HISTORY_GOALS, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ getHistoryGoals }) {
      if (getHistoryGoals.statusCode === 200) {
        const history = JSON.parse(getHistoryGoals.response);
        setShowGoals(true);
        setSaludo('No');
        // console.log('history', history);
        setHistoryGoals(history);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (historyGoals.length > 0) {
      setRecoveryGoals(Math.floor(Math.random() * 100));
    }
  }, [historyGoals]);

  useEffect(() => {
    if (orderRutine >= 7 && !validateStatusTraining) {
      verifyUserWithTraining({
        variables: {
          idUser: sessionStorage.getItem('idUser'),
          idTraining: '14',
        },
      });
    }
  }, [orderRutine, , validateStatusTraining]);
  const [recoveryGoals, setRecoveryGoals] = useState(0);
  useEffect(() => {
    // debugger;
    if (recoveryGoals >= 0) {
      // debugger;
      getHistoryGoals({
        variables: {
          idUser: sessionStorage.getItem('idUser'),
          idTraining: String(currentTraining),
        },
      });
    }
  }, [recoveryGoals]);
  const handelChangeRecoveryHistory = (number) => {
    // debugger;
    setRecoveryGoals(number + Math.floor(Math.random() * 100));
  };
  useEffect(() => {
    if (propertiesStatusGoals.length > 0 && historyGoals.length > 0) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [propertiesStatusGoals]);
  return (
    <>
      {orderRutine >= 1 && orderRutine <= 2 && historyGoals.length === 0 ? (
        // <DatBasic
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   trainingColor={colorTraining}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        // />
        <ModalLogin 
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 && orderRutine <= 3 && historyGoals.length === 0 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={2}
          nextOrderRoutine={5}
        />
      ) : null}
      {/* {orderRutine === 3 && historyGoals.length === 0 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={3}
          nextOrderRoutine={5}
        />
      ) : null} */}

      {orderRutine >= 5 && historyGoals.length === 0 ? (
        <SelectGoals
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={5}
          nextOrderRoutine={6}
        />
      ) : null}
      {orderRutine >= 6 && historyGoals.length === 0 ? (
        <SelectionGoalsOrder
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={6}
          nextOrderRoutine={7}
        />
      ) : null}
      {orderRutine >= 7 && historyGoals.length === 0 ? (
        <AddGoal
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={7}
          nextOrderRoutine={8}
          setValidateStatusTraining={setValidateStatusTraining}
        />
      ) : null}
      {orderRutine >= 8 && historyGoals.length === 0 ? (
        <SavingsAmountRecord
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={7}
          nextOrderRoutine={8}
          setValidateStatusTraining={setValidateStatusTraining}
          handelChangeRecoveryHistory={handelChangeRecoveryHistory}
        />
      ) : null}
      {historyGoals.length > 0 ? (
        <ViewGoals
          historyGoals={historyGoals}
          currentTraining={currentTraining}
          nameGoal={nameGoal}
          setNameGoal={setNameGoal}
          handelChangeRecoveryHistory={handelChangeRecoveryHistory}
          recoveryGoals={recoveryGoals}
          dineroDisponible={dineroDisponible}
          setDineroDisponible={setDineroDisponible}
          setPropertiesStatusGoals={setPropertiesStatusGoals}
          updateProgress={updateProgress}
        ></ViewGoals>
      ) : null}
      {orderRutine >= 7
      && historyGoals.length === 0
      && WOStatusBudget
      && !validateStatusTraining ? (
        <ModalRecomendacionTraining
          textTraining="presupuesto"
          urlTraining="/wo/budget"
          close={false}
        ></ModalRecomendacionTraining>
        ) : null}
      {historyGoals.length > 0 && propertiesStatusGoals.length > 0 ? (
        <>
        <ModalVirality/>
        <ShareTraining
          nameTraining="Mejorar Presupuesto"
          currentTraining="Crear Meta"
          currentTrainingURL="/wo/goals"
          propertiesStatusGoals={propertiesStatusGoals}
        ></ShareTraining>
        </>
      ) : null}
    </>
  );
};
export default RoutinesOrderWoGoals;
