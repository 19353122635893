/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import CardOne from '../../components/Home/Card1Animate';
import GloveBoxForm from './GloveBoxForm';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  DivRespDP,
  QuestionsDivsbar,
  QuestionsText,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import GloveInfoAcum from './GloveInfoAcum';

const GloveBoxData = ({
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  currentRoutine,
  nextOrderRoutine,
  libreta,
  flagRoutine,
  setFlagRoutine,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [finalWo, setFinalWo] = useState(false);
  const [meses, setMeses] = useState([]);
  const [form, setForm] = useState([
    {
      day: '', month: '', year: '', ase: '', cove: '', pay: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', pay: '', compa: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', day2: '', month2: '', year2: '',
    },
    {
      numtag: '',
    },
    {
      day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
    {
      calc: '', engoma: '', day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
  ]);

  // BANDERAS QUE MUESTRA LAS PREGUNTAS DE LA RUTINA 2
  const [showRutine2, setShowRutine2] = useState({
    step1: false,
    step2: false,
  });

  // BANDERAS PARA EDITAR ALGUNA PREGUNTA CONTESTADA O CAPTURARLA
  const [editQ, setEditQ] = useState({
    dos: false,
    tres: false,
    cuatro: false,
    quinto: false,
    sexto: false,
    septimo: false,
    octavo: false,
    noveno: false,
  });

  // GUARDA LO QUE CONTESTO EL USUARIO PARA IMPRIMIRLO EN SEGUIDA
  const [valUser, setValUser] = useState({
    dosLast: '',
  });

  // GUARDA ESTADOS AL CONTESTAR QUE NO TIENE INFO POR AHORA
  const [showRec, setShowRec] = useState({
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
    step8: false,
    step9: false,
  });
  /*------------------------------------------------------------------------------------------------
  ----------------------------------  MANEJADORES DE ITERACCION ------------------------------------
  --------------------------------------------------------------------------------------------------
  */

  // CAMBIA A LA SIGUEINTE PREGUNTA DENTRO DE UNA RUTINA SOLO RUTINA 2
  const passInside = () => {
    setShowRutine2({ ...showRutine2, step1: true });
    setTimeout(() => {
      if (orderRutine === currentRoutine) {
        gsap.to(window, { duration: 1, scrollTo: '#gloveBox2-2' });
      }
    }, 350);
  };

  const actuaNext = (posi, div) => {
    setEditQ({
      ...editQ,
      [posi]: true,
    });
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: `#${div}` });
    }, 350);
    setTimeout(() => {
      setOrderRutine(nextOrderRoutine);
      gsap.to(window, { duration: 1, scrollTo: `#${div}` });
    }, 3000);
  };

  // VERIFICA SI HAY ALGUN CAMPO VACIO
  const emptyBox = (posi, flag) => {
    let re = true;
    const arrTemp = Object.values(form[posi]);
    if (flag === 1) {
      for (let i = 0; i < arrTemp.length - 1; i += 1) {
        if (arrTemp[i] === '') {
          re = false;
          break;
        }
      }
      /* NO VERIFICA SI SUBIO EL DOCUMENTO
      if (arrTemp[arrTemp.length - 1].url === null) {
        re = false;
      } */
    } else {
      for (let i = 0; i < arrTemp.length; i += 1) {
        if (arrTemp[i] === '') {
          re = false;
          break;
        }
      }
    }
    return re;
  };

  // CAMBIA DE RUTINA A LA SIGUIENTE
  const goNextRoutine = (val) => {
    if (!sessionStorage.getItem('dayDoc')) {
      setMeses([]);
    }
    switch (val) {
      case 0:
        if (emptyBox(val, 1)) {
          setShowRutine2({ ...showRutine2, step2: true });
          setTimeout(() => {
            gsap.to(window, { duration: 1, scrollTo: '#gloveBox3-1' });
          }, 350);
        }
        break;
      case 1:
        if (emptyBox(val, 1)) {
          actuaNext('tres', 'gloveBox4-1');
        }
        break;
      case 2:
        if (emptyBox(val, 0)) {
          actuaNext('cuatro', 'gloveBox5-1');
        }
        break;
      case 3:
        if (emptyBox(val, 0)) {
          actuaNext('cinco', 'gloveBox6-1');
        }
        break;
      case 4:
        if (emptyBox(val, 1)) {
          actuaNext('seis', 'gloveBox7-1');
        }
        break;
      case 5:
        if (emptyBox(val, 1)) {
          actuaNext('siete', 'gloveBox8-1');
        }
        break;
      case 6:
        if (emptyBox(val, 1)) {
          actuaNext('ocho', 'gloveBox9-1');
        }
        break;
      case 7:
        if (emptyBox(val, 1)) {
          // FINAL DEL WO
          setEditQ({
            ...editQ,
            nueve: true,
          });
          setTimeout(() => {
            gsap.to(window, { duration: 1, scrollTo: '#gloveBox10-1' });
          }, 350);
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('another routine next');
        break;
    }
  };

  // CAMBIA ENTRE ESTADOS PARA HABILITAR RUTINAS SIGUIENTES
  const noInfNow = (rec, edit, div, type = 0) => {
    if (!sessionStorage.getItem('dayDoc')) {
      setMeses([]);
    }
    setShowRec({
      ...showRec,
      [rec]: true,
    });
    setEditQ({
      ...editQ,
      [edit]: true,
    });
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: `#${div}` });
    }, 350);
    if (type === 0) {
      setTimeout(() => {
        setOrderRutine(nextOrderRoutine);
        gsap.to(window, { duration: 1, scrollTo: `#${div}` });
      }, 3000);
    }
  };

  // ACTIVA EL FINAL DEL WO
  const final = () => {
    setFinalWo(true);
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: '#gloveBox10-1' });
    }, 350);
    setTimeout(() => {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      setOrderRutine(-1);
    }, 3000);
  };
  /*------------------------------------------------------------------------------------------------
  --------------------------------- MANEJADORES de GUARDADO Y GENERALES ----------------------------
  --------------------------------------------------------------------------------------------------
  */

  // GUARDA LAS RESPUESTAS QUE CONTESTO EL USUARIO
  // Questions es un arreglo que arrastra todas las respuestas del usuario
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || type === 'SelectBasic' || type === 'DocUpload') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  // CAPTURA LA ACCIÓN DE CONTESTAR UNA PREGUNTA
  const handleChangeCatOption = (val) => {
    // eslint-disable-next-line no-console
    if (currentRoutine === 2) {
      switch (val.step) {
        case 7:
          setValUser({ ...valUser, dosLast: val.value });
          setEditQ({ ...editQ, dos: true });
          setTimeout(() => {
            gsap.to(window, { duration: 1, scrollTo: '#gloveBox3-1' });
          }, 350);
          setTimeout(() => {
            if (val.value === 'Sí') {
              setFlagRoutine({ ...flagRoutine, routine3: true });
              setOrderRutine(nextOrderRoutine);
            } else {
              setFlagRoutine({ ...flagRoutine, routine3: false });
              setOrderRutine(nextOrderRoutine + 1);
            }
            gsap.to(window, { duration: 1, scrollTo: '#gloveBox3-1' });
          }, 3000);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('Demas preguntas de la rutina 2');
          break;
      }
    }
  };

  // FUNCION PARA GUARDAR LO QUE CONTESTE EL USUARIO A MOSTRAR
  const handleChange = (e, order, posi) => {
    const arrayTemp = [...form];
    arrayTemp[posi] = {
      ...arrayTemp[posi],
      [e.target.name]: e.target.value,
    };
    setForm(arrayTemp);
    // SETEANDO MESES DE ACUERDO AL DIA SELECCIONADO
    if (e.target.name === 'day') {
      const daydoc = parseInt(e.target.value, 10);
      if (daydoc < 29) {
        setMeses([
          { label: 'Enero', value: '01' },
          { label: 'Febrero', value: '02' },
          { label: 'Marzo', value: '03' },
          { label: 'Abril', value: '04' },
          { label: 'Mayo', value: '05' },
          { label: 'Junio', value: '06' },
          { label: 'Julio', value: '07' },
          { label: 'Agosto', value: '08' },
          { label: 'Septiembre', value: '09' },
          { label: 'Octubre', value: '10' },
          { label: 'Noviembre', value: '11' },
          { label: 'Diciembre', value: '12' },
        ]);
      } else if (daydoc === 29 || daydoc === 30) {
        setMeses([
          { label: 'Enero', value: '01' },
          { label: 'Marzo', value: '03' },
          { label: 'Abril', value: '04' },
          { label: 'Mayo', value: '05' },
          { label: 'Junio', value: '06' },
          { label: 'Julio', value: '07' },
          { label: 'Agosto', value: '08' },
          { label: 'Septiembre', value: '09' },
          { label: 'Octubre', value: '10' },
          { label: 'Noviembre', value: '11' },
          { label: 'Diciembre', value: '12' },
        ]);
      } else {
        setMeses([
          { label: 'Enero', value: '01' },
          { label: 'Marzo', value: '03' },
          { label: 'Mayo', value: '05' },
          { label: 'Julio', value: '07' },
          { label: 'Agosto', value: '08' },
          { label: 'Octubre', value: '10' },
          { label: 'Diciembre', value: '12' },
        ]);
      }
      sessionStorage.setItem('dayDoc', daydoc);
    }
    if (e.target.name === 'month') {
      sessionStorage.removeItem('dayDoc');
    }
  };

  /*------------------------------------------------------------------------------------------------
  --------------------------------- FIN FUNCIONALIDADES RUTINAS ------------------------------------
  --------------------------------------------------------------------------------------------------
  */

  switch (libreta.nm) {
    case 'Póliza de seguro':
      return (
        <div className='marginComentLgSuccess1'>
          <div id='gloveBox2-2'></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard='cardOne'
            classTop='topOne1'
            text="¡Súper! 🙌 Comencemos añadiendo algunos documentos."
            text2=' '
            text3='No olvides incluir las vigencias de tus documentos para notificarte
                  cuando sea momento de renovar 🤓'
            btn={true}
            btnText="De acuerdo"
            funct={() => passInside()}
            hora={hora}
          />
          {showRutine2.step1 ? (
            <>
              {!showRutine2.step2 ? (
                <>
                  <GloveBoxForm
                    formTitle={libreta.nm}
                    formIcon = {libreta.im}
                    uploadText={libreta.ut}
                    description={libreta.ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[0]}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                    amountService={0}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                  />
                  <QuestionsDivsbar
                    margin="1.5rem 0 20px 18%"
                    onClick={() => {
                      if (!sessionStorage.getItem('dayDoc')) {
                        setMeses([]);
                      }
                      setShowRutine2({ ...showRutine2, step2: true });
                      setShowRec({ ...showRec, step2: true });
                      setTimeout(() => {
                        gsap.to(window, { duration: 1, scrollTo: '#gloveBox3-1' });
                      }, 350);
                    }
                    }
                  >
                    <QuestionsText size='14px' weight='400'>
                        No tengo la información ahora
                    </QuestionsText>
                  </QuestionsDivsbar>
                </>
              ) : null}
              <CardOne
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  classCard={showRec.step2 ? 'cardOne' : 'hidden'}
                  classTop='topOne1'
                  text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
                  hora={hora}
              />
            </>
          ) : null}
          {showRutine2.step2 ? (
            <>
              <GloveInfoAcum
                formTitle={libreta.nm}
                formIcon = {libreta.im}
                form={form[0]}
              />
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard='cardOne'
                classTop='topOne1'
                text="¿Cuentas con asistencia en el camino?"
                hora={hora}
              />
              <TrainingQuestion
                className={!editQ.dos ? '' : 'hidden'}
              >
                <InputsOrder
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="7-7"
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                />
            </TrainingQuestion>
          </>
          ) : null}
          <div id="gloveBox3-1"></div>
          { /* Respuestas de la pregunta  SI O NO */
            <DivRespDP>
              <CardTwo
                classCard={editQ.dos ? 'cardTwoResp' : 'hidden'}
                classTop="topTwo1"
                imgMan="hidden"
                text={valUser.dosLast}
                colorTraining={trainingColor}
              ></CardTwo>
            </DivRespDP>
          }
          {editQ.dos && orderRutine === 2 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'Póliza de asistencia en el camino':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.tres ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              uploadText={libreta.ut}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[1]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={1}
              goNextRoutine={goNextRoutine}
              meses={meses}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[1]}
            />
          )}
          {!showRec.step3 && !editQ.tres ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step3', 'tres', 'gloveBox4-1')}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox4-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step3 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.tres && orderRutine === 3 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'Servicio de auto':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.cuatro ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              uploadText={libreta.ut}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[2]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={2}
              goNextRoutine={goNextRoutine}
              meses={meses}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[2]}
            />
          )}
          {!showRec.step4 && !editQ.cuatro ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step4', 'cuatro', 'gloveBox5-1')}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox5-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step4 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.cuatro && orderRutine === 4 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'TAG':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.cinco ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[3]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={3}
              goNextRoutine={goNextRoutine}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[3]}
            />
          )}
          {!showRec.step5 && !editQ.cinco ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step5', 'cinco', 'gloveBox6-1')}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox6-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step5 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.cinco && orderRutine === 5 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'Licencia de conducir':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.seis ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              uploadText={libreta.ut}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[4]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={4}
              goNextRoutine={goNextRoutine}
              meses={meses}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[4]}
            />
          )}
          {!showRec.step6 && !editQ.seis ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step6', 'seis', 'gloveBox7-1')}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox7-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step6 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.seis && orderRutine === 6 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'Tarjeta de circulación':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.siete ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              uploadText={libreta.ut}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[5]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={5}
              goNextRoutine={goNextRoutine}
              meses={meses}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[5]}
            />
          )}
          {!showRec.step7 && !editQ.siete ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step7', 'siete', 'gloveBox8-1')}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox8-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step7 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.siete && orderRutine === 7 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'Tenencia':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.ocho ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              uploadText={libreta.ut}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[6]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={6}
              goNextRoutine={goNextRoutine}
              meses={meses}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[6]}
            />
          )}
          {!showRec.step8 && !editQ.ocho ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step8', 'ocho', 'gloveBox9-1')}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox9-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step8 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.ocho && orderRutine === 8 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    case 'Verificación':
      return (
        <div className='marginComentLgSuccess1'>
          {!editQ.nueve ? (
            <GloveBoxForm
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              uploadText={libreta.ut}
              description={libreta.ds}
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              handleChangeCatOption={handleChangeCatOption}
              form={form[7]}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              amountService={7}
              goNextRoutine={goNextRoutine}
              meses={meses}
            />
          ) : (
            <GloveInfoAcum
              formTitle={libreta.nm}
              formIcon = {libreta.im}
              form={form[7]}
            />
          )}
          {!showRec.step9 && !editQ.nueve ? (
            <QuestionsDivsbar
              margin="1.5rem 0 20px 18%"
              onClick={() => noInfNow('step9', 'nueve', 'gloveBox10-1', 1)}
            >
              <QuestionsText size='14px' weight='400'>
                  No tengo la información ahora
              </QuestionsText>
            </QuestionsDivsbar>
          ) : null}
          <div id="gloveBox10-1"></div>
          <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={showRec.step9 ? 'cardOne' : 'hidden'}
              classTop='topOne1'
              text="¡No te preocupes! 😉 Podrás regresar más tarde a completar tu guantera."
              hora={hora}
          />
          {editQ.nueve && orderRutine === 9 ? (
            // MOSTRANDO LOADER
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard='cardOne'
              classTop='topOne1'
              text="¡Genial!🙌 Pronto podrás ver todos los documentos de auto en tu Guantera de Auto Digital."
              btn={true}
              btnText="Ir a Mi Guantera de Auto Digital"
              funct={() => final()}
              hora={hora}
            />
          ) : null}
          {finalWo && orderRutine === 9 ? (
            // MOSTRANDO LOADER
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : null}
        </div>
      );
    default:
      return <div></div>;
  }
};

GloveBoxData.propTypes = {
  hora: PropTypes.string,
  orderRutine: PropTypes.number.isRequired,
  setOrderRutine: PropTypes.func.isRequired,
  currentTraining: PropTypes.number.isRequired,
  createJsonInput: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  trainingColor: PropTypes.string.isRequired,
  setQuestions: PropTypes.func.isRequired,
  currentRoutine: PropTypes.number.isRequired,
  nextOrderRoutine: PropTypes.number.isRequired,
  libreta: PropTypes.object.isRequired,
  flagRoutine: PropTypes.object,
  setFlagRoutine: PropTypes.func,
};

export default GloveBoxData;
