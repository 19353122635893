import React, {
  useState, useEffect, Fragment, Suspense,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Carrusel from 'react-elastic-carousel';
import TipsInversionesV2 from '../Routines/TipsInversionesV2';
import TipsInversiones from '../Routines/TipsInversiones';
import BirthDat from '../Routines/BirthDat';
import FirstInvestmentCalInV2 from './FirstInvestmentCalInV2';
import CalFirstInvestmenteV2 from './FirstInvestmentCalculatorV2';
import ContactInformation from '../Routines/ContactInformation';
import {
  Feed_Main,
} from '../../assets/styles/Feed.styled';
import {
  GET_PRODUCTS_BY_IDTRAINING,
  GET_TRAINING_PREVIEW,
} from '../../queries';
import {
  GET_RECOMMENDATION_TRAININGS,
  CREATE_OPPORTUNITIES,
  GET_NIP_TO_USER,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import {
  DivLoader,
  CardsBtn,
  Resultado,
  TrainingText,
} from '../../assets/styles/Training.styled';

import {
  Col5,
  Col12,
  ParagrapTwo,
} from '../../components/common/common.styled';
import coruCupon from '../../assets/img/anacoach/Coru-Cupon.png';
import { Container } from '../../assets/styles/Home.styled';
import SectionShare from '../../components/common/ShareSection';
import CardOne from '../../components/Home/Card1Animate';
import ClickButton from '../../Helpers/HookHelpers';
import ModalAmazon from '../../components/common/ModalAmazon';
import {
  getQueryVariableHashtagDeeplink,
} from '../../Helpers/index';

const DatBasic = React.lazy(() => import('../Routines/DatBasic'));
const RoutinesOrderFirstInervsionV2 = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
  reward,
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const [inversionProducts, setInversionProducts] = useState([]);
  const [activarFlujo, setActivarFlujo] = useState('');
  const [onlyOptInvest, setOnlyOptInvest] = useState(false);
  const [activarCalculadora, setActivarCalculadora] = useState(false);
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [recomendaciones, setRecomendaciones] = useState(false);
  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');

    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
  });

  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const abrirNuevoTab = (product) => {
    const click = `Invertir_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2, // remplazar por el id del entrenamiento
        idProducts: [product.IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 17,
        idType: '353',
        value: product.Instrumento,
      },
    };
    console.log(opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
    sessionStorage.setItem('clickOutProduct', product.url);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    const win = window.open(product.url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  const [getTrainingPreview] = useLazyQuery(GET_TRAINING_PREVIEW, {
    onCompleted({ getTrainingPreview }) {
      console.log('productos', getTrainingPreview.response.training);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    getTrainingPreview({
      variables: {
        Id: '23',
      },
    });
  }, []);

  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    onCompleted({ getProductsByIdTraining }) {
      // debugger
      console.log('linea 302 Productos', getProductsByIdTraining);
      const products = JSON.parse(getProductsByIdTraining.response);
      // console.log(products)
      const catProducts = [];
      for (let item = 0; item < products.length; item++) {
        const product = products[item];
        console.log(product);
        const tasa = product.benefits.filter((item) => {
          if (item.Name === 'Tasa') return item.Description;
        });
        const MontoMinimo = product.benefits.filter((item) => {
          if (item.Name === 'MontoMinimo') return 'Monto mínimo';
        });

        if (MontoMinimo.length > 0) {
          product.montoMin = parseFloat(
            String(MontoMinimo[0].Description).replace('%', '').trim(),
          );
        }

        if (tasa.length > 0) {
          product.tasa = parseFloat(
            String(tasa[0].Description === 'Variable' ? 0 : tasa[0].Description)
              .replace('%', '')
              .trim(),
          );
        } else {
          product.tasa = 0;
        }

        catProducts.push(product);
      }
      sessionStorage.setItem(
        'productsFirstInvestment',
        JSON.stringify(catProducts),
      );
    },
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    getProductsByIdTraining({
      variables: { idTraining: String(currentTraining) },
    });
  }, []);
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const history = useHistory();
  const obtenerNIP = (e) => {
    e.persist();
    getNipToUser({ variables: { idUser: sessionStorage.getItem('idUser') } });
  };
  const [nipAsignado, setNipAsignado] = useState('');
  const [getNipToUser] = useMutation(GET_NIP_TO_USER, {
    onCompleted({ getNipToUser }) {
      if (getNipToUser.statusCode === 200 && getNipToUser.response.length > 0) {
        const { nip } = getNipToUser.response[0];
        sessionStorage.setItem('nipVirality', nip);
        setNipAsignado(nip);
      }
    },
  });
  useEffect(() => {
    if (sessionStorage.getItem('nipVirality')) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'Otpemail',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [nipAsignado]);

  useEffect(() => {
    console.log('productos rp', inversionProducts, 'orden rutine', orderRutine);
    if (orderRutine === 6) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [inversionProducts]);

  useEffect(() => {
    if (activarFlujo === 'perfilInversionista') {
      history.push('/wo/investment-test');
    }
  }, [activarFlujo]);

  useEffect(() => {
    if (onlyOptInvest === true) {
      saveAdvance(parseInt(orderRutine - 1));
      const timer = setTimeout(() => {
        saveAdvance(parseInt(orderRutine));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [onlyOptInvest]);

  const formatExtraText = (item) => {
    const { Name, Description } = item;

    let title = Name;
    let description = Description;
    let isNumber = false;

    switch (Name) {
      case 'MontoMinimo':
        title = 'Monto mínimo';
        isNumber = true;
        break;
      case 'Monto Minimo':
        title = 'Monto mínimo';
        isNumber = true;
        break;

      default:
        break;
    }

    if (isNumber) {
      const text = description.replace(',', '').replace('.', '');

      const number = parseInt(text);

      if (number) {
        const len = text.length;
        let flag = 0;
        let newNumber = [];

        for (let i = len - 1; i >= 0; i--) {
          newNumber.push(text[i]);

          if (flag === 2 && i > 0) {
            flag = 0;
            newNumber.push(',');
          }
          flag++;
        }

        newNumber = newNumber.reverse();
        description = `$${newNumber.join('')}`;
      }
    }

    return `${title}: ${description}`;
  };

  useEffect(() => {
    console.log('Cambio rutina', orderRutine);
    if (orderRutine === 5) {
      const question1 = JSON.parse(sessionStorage.getItem('CalQuestion1'));
      const question2 = JSON.parse(sessionStorage.getItem('CalQuestion2'));
      const question3 = JSON.parse(sessionStorage.getItem('CalQuestion3'));
      const question4 = JSON.parse(sessionStorage.getItem('CalQuestion4'));
      const calrutine = JSON.parse(sessionStorage.getItem('CalRutine'));
      console.log('question1', question1, typeof question1);
      console.log('question2', question2, typeof question2);
      console.log('question3', question3, typeof question3);
      console.log('question4', question4, typeof question4);
      console.log('calrutine', calrutine, typeof calrutine);
      createJsonInput(calrutine, question1);
      createJsonInput(calrutine, question2);
      createJsonInput(calrutine, question3);
      createJsonInput(calrutine, question4);
    }
  }, [orderRutine]);
  return (
    <>
      {orderRutine >= 1 ? (
        <>
          <FirstInvestmentCalInV2
            text={
              'Sabemos que invertir por primera vez puede ser muy confuso y más si no hay alguien que pueda orientarte.'
            }
            text2={
              'Para ayudarte, en Coru desarrollamos este entrenamiento, solo tienes que:'
            }
            text3={'Ingresar el monto que te gustaría invertir'}
            text4={'Indicar el plazo de tu inversión'}
            text5={
              'Indicar la tasa anual de rendimiento que esperas de acuerdo a tu perfil de inversionista: conservador, moderado o arriesgado'
            }
            subtitle={'¡Empecemos!'}
            onClick={setActivarCalculadora}
          />
          {activarCalculadora ? (
            <CalFirstInvestmenteV2
              Date={Date}
              hora={hora}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              currentRoutine={1}
              nextOrderRoutine={2}
              trainingColor={trainingColor}
              title={'Calcula tu inversión'}
              setInversionProducts={setInversionProducts}
              onClick={''}
            />
          ) : null}
        </>
      ) : null}
      {orderRutine === 2 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={2}
            nextOrderRoutine={3}
            trainingColor={trainingColor}
          />
        </Suspense>
      ) : null}
      {orderRutine === 3 ? (
        <BirthDat
          mainDataRoutine={mainDataRoutine}
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine === 4 ? (
        <ContactInformation
          mainDataRoutine={mainDataRoutine}
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={trainingColor}
        />
      ) : null}

      {orderRutine >= 5 ? (
        <TipsInversionesV2
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={6}
          nextOrderRoutineOpt={7}
          setOnlyOptInvest={setOnlyOptInvest}
          saveAdvance={saveAdvance}
          setActivarFlujo={setActivarFlujo}
          trainingColor={trainingColor}
        />
      ) : null}
      {orderRutine >= 6 && onlyOptInvest === false ? (
        <TipsInversiones
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          nextOrderRoutine={7}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
        />
      ) : null}

      {inversionProducts.length > 0 && orderRutine === 7 ? (
        <>
          <div
            id="CarruselEmpresasContent"
            style={{ marginRight: '28px', marginTop: '5px', height: 'auto' }}
          >
            <div id="ProductsMyFInV2"></div>
            <Carrusel styled={{ background: '#FAFAFA' }}>
              {inversionProducts.map((producto) => {
                return (
                  <>
                    <Resultado
                      
                      marTop="10px"
                      marTopD="20px"
                      bcolor="#fff"
                      ta="left"
                      marriweb="auto"
                      border="none"
                      marri="auto"
                      marL="auto"
                      hei="500px"
                      minh="453px"
                      minhweb="auto"
                    >
                      <div
                        className="row"
                        style={{
                          margin: 'auto',
                          width: '100%',
                          padding: '10px',
                        }}
                      >
                        <div style={{ marginTop: '10px' }}>
                          <img
                            src={producto.ImageURL}
                            style={{ width: '100px', margin: 'auto' }}
                          />
                        </div>
                        <div className="col-6" style={{ marginTop: '20px' }}>
                          <TrainingText
                            size="12px"
                            sizew="12px"
                            weight="400"
                            bot="0px"
                            className="roboto"
                            align="right"
                            alignweb="right"
                            ls="-0.2px"
                          >
                            Ganancia total
                          </TrainingText>
                          <TrainingText
                            size="18px"
                            sizew="18px"
                            weight="600"
                            className="roboto"
                            align="right"
                            alignweb="right"
                            ls="-0.2px"
                          >
                            {formatter.format(producto.resultado)}
                          </TrainingText>
                        </div>

                        <div className="col-5">
                          <TrainingText
                            size="19px"
                            sizew="19px"
                            weight="600"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            {producto.Instrumento}
                          </TrainingText>
                        </div>
                        <div className="col-7">
                          <TrainingText
                            size="12px"
                            sizew="12px"
                            weight="400"
                            className="roboto"
                            align="right"
                            alignweb="right"
                            ls="-0.2px"
                          >
                            Tasa de intereses {producto.taza}
                          </TrainingText>
                        </div>

                        <div className="col-12">
                          <TrainingText
                            size="14px"
                            sizew="14px"
                            weight="600"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Beneficios
                          </TrainingText>
                          <ul style={{ paddingLeft: '18px' }}>
                            {producto.caracteristicas.map((item) => (
                              <>
                                {item.IDProductDetailType === 3 ? (
                                  <li>{item.Description}</li>
                                ) : null}
                              </>
                            ))}
                          </ul>
                        </div>
                        <div className="col-12">
                          <TrainingText
                            size="14px"
                            sizew="14px"
                            weight="600"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Información extra
                          </TrainingText>
                          <ul style={{ paddingLeft: '18px' }}>
                            {producto.caracteristicas.map((item) => (
                              <>
                                {item.IDProductDetailType !== 3 ? (
                                  <li>{formatExtraText(item)}</li>
                                ) : null}
                              </>
                            ))}
                          </ul>
                        </div>
                        <div className="col-12">
                          <CardsBtn
                            type="button"
                            windthmd="100%"
                            minmd="100%"
                            mleftmd="0%"
                            mrightmd="auto"
                            windthG="100%"
                            min_w="100%"
                            min_web="100%"
                            min_mini="100%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="0%"
                            mright="0%"
                            mleftweb="0%"
                            // mrightweb="auto"
                            onClick={(e) => {
                              abrirNuevoTab(producto);
                            }}
                          >
                            Invertir
                          </CardsBtn>
                        </div>
                      </div>
                    </Resultado>
                  </>
                );
              })}
            </Carrusel>
          </div>
        </>
      ) : null}
      {inversionProducts.length > 0 && orderRutine === 7 ? (
        <>
          <ModalAmazon show={true} wo={'PrimeraInversión'}></ModalAmazon>
          <SectionShare WO="PrimeraInversión"></SectionShare>
        </>
      ) : null}
      {inversionProducts.length > 0
      && recomendaciones
      && reward
      && orderRutine === 6 ? (
        <Feed_Main width="100%" background=" ">
          <Feed_Main width="100%" display=" " mtop=" " background="transparent">
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="Aquí tienes tu regalo."
            />
            <Col12 ta="center">
              <Container
                width="100%"
                mt="0%"
                widthMobile="100%"
                mtMobile="0%"
                float="auto"
              >
                <Col5 className="mobileWithChicos" padding="0" border="">
                  <div
                    className="col-12 col-sm-12 col-xs-12"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      obtenerNIP(e);
                    }}
                  >
                    <img
                      alt=""
                      src={coruCupon}
                      className="col-12 col-sm-12 col-xs-12 img-responsive"
                      left="30%"
                      top="15px"
                      position="absolute"
                    ></img>
                    <ParagrapTwo
                      mtWeb="-2.8rem"
                      mb="5%"
                      textalignMobile="right"
                      texttalign="right"
                      className="mx-auto roboto col-12 col-sm-10 col-xs-10"
                      mt="-12%"
                      fontSize="12px"
                    >
                      Da clic aquí y
                    </ParagrapTwo>
                    <ParagrapTwo
                      mtWeb="-0.5rem"
                      mb="5%"
                      textalignMobile="right"
                      texttalign="right"
                      className="mx-auto roboto col-12 col-sm-10 col-xs-10"
                      mt="-5%"
                      fontSize="12px"
                    >
                      descubre tu regalo
                    </ParagrapTwo>
                  </div>
                </Col5>
              </Container>
            </Col12>
            {nipAsignado !== '' ? (
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                textbold="Hemos enviado a tu correo electrónico tu NIP para reclamar tu regalo."
              />
            ) : null}
          </Feed_Main>
        </Feed_Main>
        ) : null}
    </>
  );
};
export default RoutinesOrderFirstInervsionV2;
