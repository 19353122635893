import React, { Fragment, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import LockIcon from '@material-ui/icons/Lock';
import {
  ContainerPerfiles,
  TextPerfil,
} from '../../assets/styles/pantInicio.styled';
import {
  Main,
  Container,
  Col2,
  Col12,
  Col10,
  StatusColor,
} from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import FooterFeed from '../../components/Feed/Footer_Feed';
import Cards from '../../components/Perfiles/FormCards';
import Header_Logros from '../../components/Perfiles/Header_Perfil';

function PaymentMethod({ Novisible, paddingInicio }) {
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  });

  const [status, Setstatus] = useState({
    Dom1: false,
    Dom2: false,
  });

  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  const handleChange = () => {
    if (!status.Dom1) {
      Setstatus({
        Dom1: true,
        Dom2: false,
      });
      status.Dom1 = true;
    } else {
      Setstatus({
        Dom1: true,
        Dom2: true,
      });
      status.Dom2 = true;
    }
  };

  return (
    <Fragment>
      {!Novisible ? <Header_Logros /> : <div></div>}
      <Main
        overflow=" "
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
        background="#fafafa"
        pb={!paddingInicio ? '35%' : ' '}
        mt="0%"
        mtWeb="0%"
      >
        <Container
          displayweb="block"
          paddingMobile="0px"
          className="container"
          width="100%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <Container
            width="94%"
            mt="0rem"
            widthMobile="100%"
            mtMobile="0rem"
            bb="1px solid #D6D6D6"
            paddingMobile="0!important"
          >
            <Col12 pt="1rem" pMobile="0px">
              <Container width="100%" widthMobile="100%" mtMobile="0px">
                <Col12 pt="0px" pMobile="0px">
                  <TextPerfil
                    fw="400"
                    fs="20px"
                    width="100%"
                    mt="0%"
                    color="rgb(17, 14, 32)"
                    fsMobile="17px"
                    mlMobile="0%"
                    lh="20px"
                    className="roboto"
                  >
                    Datos de tu tarjeta para realizar tus compras en la
                    plataforma.
                  </TextPerfil>
                  <TextPerfil
                    fw="600"
                    fs="20px"
                    width="100%"
                    mt="3%"
                    mb="4%"
                    color="rgb(17, 14, 32)"
                    fsMobile="17px"
                    mlMobile="0%"
                    lh="20px"
                    className="roboto"
                  >
                    Todos tus datos bancarios están protegidos. <LockIcon />
                  </TextPerfil>
                </Col12>
              </Container>
            </Col12>
          </Container>
          {status.Dom1 ? (
            <Cards number="1" visibility={true} more={false}></Cards>
          ) : (
            <div></div>
          )}
          {!status.Dom2 ? (
            <Container
              width="94%"
              mt="1rem"
              widthMobile="100%"
              mtMobile="1rem"
              paddingMobile="0!important"
              mb="0px"
            >
              <ContainerPerfiles
                padding="4%"
                height="auto"
                minheight="auto"
                display="flex"
              >
                <Container
                  width="100%"
                  widthMobile="100%"
                  mtMobile="0px"
                  float="auto"
                  mMobile="auto"
                  onClick={handleChange}
                >
                  <Col12 pt="0px" pMobile="0px">
                    <Container width="100%" widthMobile="100%" mtMobile="0px">
                      <Col10 pt="0px" pMobile="0px">
                        <TextPerfil
                          fw="400"
                          fs="20px"
                          mt="0%"
                          width="100%"
                          color="rgb(17, 14, 32)"
                          fsMobile="17px"
                          mlMobile="0%"
                          lh="20px"
                        >
                          Añadir Tarjeta de crédito o débito
                        </TextPerfil>
                      </Col10>
                      <Col2 mt="0px" padding="0px" margin="0px" pMobile="0px">
                        <StatusColor
                          backcolor="#F64282"
                          ta="center"
                          margin="auto"
                          size="19px"
                          width="30px"
                          height="30px"
                          color="#fff"
                        >
                          +
                        </StatusColor>
                      </Col2>
                    </Container>
                  </Col12>
                </Container>
              </ContainerPerfiles>
            </Container>
          ) : (
            <div></div>
          )}
          {status.Dom2 ? <Cards number="2"></Cards> : <div></div>}
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="perfil" /> : <div></div>}
    </Fragment>
  );
}
export default PaymentMethod;
