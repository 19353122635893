/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const NoHistoryRoutine = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  setCreditHistoryCheck,
  firstQuestion = false,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [countRecovery] = useState(0);
  const [purpose, setPurpose] = useState([]);
  const [opcion, setOpcion] = useState('');
  const [showPurposeSelected] = useState(false);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            // setRecoveryRecoveryRoutine('inactiva');
            // setStateRecovery("inactiva");
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setQuestions([]);
                break;
              } else {
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [form, setForm] = useState({
    check_1_1: false,
    check_1_2: false,
    check_1_3: false,
    check_1_4: false,
    check_1_5: false,
    check_1_6: false,
    check_2_1: false,
    check_2_2: false,
    check_2_3: false,
    check_2_4: false,
    check_2_5: false,
    check_2_6: false,
  });
  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (purpose.length < 3 && checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setPurpose(purpose.concat(value));
      console.log(purpose);
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = purpose.indexOf(value);
      purpose.splice(deleted, 1);
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    createJsonInput(Rutine, questions);
    setQuestions([]);
    setOrderRutine(nextOrderRoutine);
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    sessionStorage.setItem('noHistoryResp', val);
    setCreditHistoryCheck(val);
    setOpcion(val);
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeNoHistoryRoutine' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeNoHistoryRoutine"></div>
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />

      <TrainingQuestion>
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="0-0"
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChange={handleChange}
            currentTraining={currentTraining}
            typeCheckBox={true}
            centerLine={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <CardTwo
        classCard={
          opcion !== ''  ? 'cardTwoResp' : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={opcion}
        colorTraining={trainingColor}
      />
    </div>
  );
};

export default NoHistoryRoutine;
