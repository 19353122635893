import React, { useState, useEffect, Fragment } from 'react';
import { useLazyQuery } from '@apollo/client';
import Carousel from 'react-elastic-carousel';
import NVD3Chart from 'react-nvd3';
import { useHistory } from 'react-router-dom';
import {
  GET_GOALS_AMOUNTS_COLLABORATIVE,
  GET_GOAL_DATA_COLLABORATIVE,
} from '../../queries';
import { Image } from '../../assets/styles/Home.styled';
import { Shapes } from '../../components/common/Home.styled';
import { Container } from '../../components/common/common.styled';
import {
  CardsBtn,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import logoCoru from '../../assets/img/anacoach/logoCoruGrafico.png';
import { filterFloat } from '../../Helpers';

function GoalDetail({ initializeNow, initializeLogin }) {
  const history = useHistory();
  const idTrainingCollaborativo = 37;
  const [widthBrowser, setwidthBrowser] = useState(window.outerWidth);
  const [goals, setGoals] = useState([]);
  const [invitadosMetaColavorativa, setInvitadosMetaColavorativa] = useState(
    [],
  );
  const [montoMetaColaborativo, setMontoMetaColaborativo] = useState('');
  const [integrantesAhorro, setIntegrantesAhorro] = useState([]);
  const [datum, setDatum] = useState([]);
  const [totalAhorro, setTotalAhorro] = useState(0);
  useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const styles = {
    fontStyle: 'normal',
    fontSize: '15px',
    fontWeight: 'normal',
    lineHeight: '120%',
    marginLeft: '5px',
  };
  const registrarAhorro = () => {
    history.push('/wo/collaborative-success');
  };

  const [getGoalDataCollaborative] = useLazyQuery(GET_GOAL_DATA_COLLABORATIVE, {
    fetchPolicy: 'no-cache',
    onCompleted({ getGoalDataCollaborative }) {
      if (getGoalDataCollaborative.message === 'success') {
        setIntegrantesAhorro([]);
        setDatum([]);
        const goalData = JSON.parse(getGoalDataCollaborative.response);
        const monto = goalData[0].goalTypes.filter((e) => e.IdType === 480)[0];
        setMontoMetaColaborativo(monto ? monto.Value : '');
        setInvitadosMetaColavorativa(goalData[0].guestData);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getGoalsAmountsCollaborative] = useLazyQuery(
    GET_GOALS_AMOUNTS_COLLABORATIVE,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getGoalsAmountsCollaborative }) {
        if (getGoalsAmountsCollaborative.message === 'success') {
          const goalsData = JSON.parse(getGoalsAmountsCollaborative.response);

          if (goalsData.length <= 0) return;
          setGoals(goalsData[goalsData.length - 1]);
          const input = {
            idUser: String(sessionStorage.getItem('idUser')),
            idTraining: String(idTrainingCollaborativo),
            idDatTraining: String(
              goalsData[goalsData.length - 1].idDatTraining,
            ),
          };
          getGoalDataCollaborative({ variables: input });
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const formatMontosAhorro = () => {
    const idUserLog = parseInt(sessionStorage.getItem('idUser'));
    const usuarioActual = invitadosMetaColavorativa.filter(
      (e) => e.idUser === idUserLog,
    );
    const otrosUsuarios = invitadosMetaColavorativa.filter(
      (e) => e.idUser !== idUserLog,
    );
    let monto = 0;

    if (!usuarioActual[0]) return;

    usuarioActual[0].amounts.map((e) => {
      monto += parseFloat(e.amount.replace(/[,$]/g, ''));
    });

    const integrantesAhorroTmp = [
      {
        id: 0,
        name: 'Tu ahorro',
        phone: '',
        val: formatter.format(filterFloat(monto.toFixed(2))),
        colorGp: '#91D770',
        percent: '0',
      },
    ];

    const montoMeta = parseFloat(montoMetaColaborativo.replace(/[,$]/g, ''));
    let percent = (monto / montoMeta) * 100;
    let totalPercent = percent;
    const datumTmp = [{ key: 'Tu ahorro', y: percent, color: '#91D770' }];

    for (let i = 0; i < otrosUsuarios.length; i++) {
      monto = 0;
      if (otrosUsuarios[i] && otrosUsuarios[i].idUser !== null) {
        otrosUsuarios[i].amounts.map((e) => {
          monto += parseFloat(e.amount.replace(/[,$]/g, ''));
        });
      }
      percent = (monto / montoMeta) * 100;
      totalPercent += percent;
      integrantesAhorroTmp.push({
        id: `${i + 1}`,
        name: `Ahorro compañero ${i + 1}`,
        phone: otrosUsuarios[i].phone,
        val: formatter.format(filterFloat(monto.toFixed(2))),
        colorGp: '#6BD5AF',
        percent: '0',
      });

      datumTmp.push({
        key: `Ahorro compañero ${i + 1}`,
        y: percent,
        color: '#6BD5AF',
      });
    }

    let montoAhorroTotal = 0;
    integrantesAhorroTmp.map((item) => {
      montoAhorroTotal += parseFloat(item.val.replace(/[,$]/g, ''));
    });

    datumTmp.push({
      key: 'Porcentaje ahorro restante',
      y: 100 - totalPercent,
      color: '#C4C4C4',
    });

    setTotalAhorro(formatter.format(filterFloat(montoAhorroTotal.toFixed(2))));
    setIntegrantesAhorro(...integrantesAhorro, integrantesAhorroTmp);
    setDatum(...datum, datumTmp);
  };
  useEffect(() => {
    const input = {
      phone: String(sessionStorage.getItem('CellPhone')),
    };
    getGoalsAmountsCollaborative({ variables: input });
  }, []);

  useEffect(() => {
    if (invitadosMetaColavorativa.length > 0) {
      formatMontosAhorro();
    }
  }, [invitadosMetaColavorativa]);
  return (
    <Fragment>
      {widthBrowser <= 700 ? (
        <Carousel
          id="CarruselMetasLogros"
          styled={{ background: '#FAFAFA' }}
          transitionMs={1000}
        >
          <Shapes
            id="Meta1"
            key={1}
            mtopD="7%"
            heightD="350px"
            height="940px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="0px"
              widthMobile="100%"
              mb="0px"
              mtMobile="auto"
              mbMobile="auto"
              className="d-flex flex-column"
            >
              <div
                style={{
                  width: '295px',
                  margin: 'auto auto 30px',
                  borderRadius: '4px',
                }}
              >
                <div
                  className="cabin"
                  style={{
                    color: '#000',
                    backgroundColor: '#FFF',
                    textAlign: 'center',
                    padding: '8px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                >
                  {goals.goalName}
                </div>
                <div
                  style={{
                    backgroundColor: '#FFF',
                    padding: '10px',
                    color: '#07210A',
                  }}
                >
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Se les recomendó ahorrar cada quincena:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {formatter.format(
                      filterFloat(
                        (
                          parseFloat(
                            goals?.goalTypesMonto?.Value.replace(/[,$]/g, ''),
                          )
                          / (goals?.goalTypesTiempo?.Value * 2)
                        ).toFixed(2),
                      ),
                    )}
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Durante:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {goals?.goalTypesTiempo?.Value} Meses
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Actualmente su ahorro es de:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {totalAhorro}
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    El monto de su meta es de:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {goals?.goalTypesMonto?.Value}
                  </div>

                  <TrainingDivSection
                    mt="110px"
                    hew="auto"
                    paddw="0"
                    mlw="1%"
                    mtw="0"
                    // wiw="503px"
                    back=" "
                    // backw="#F3F3F3"
                    bshaw="none"
                    bsha=""
                    br="4px"
                    padding="0"
                    wi="100%"
                    he="auto"
                    mb="38px"
                    wiw="100%"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-25px',
                      }}
                    >
                      <Image
                        src={logoCoru}
                        mwidth="35%"
                        width="18%"
                        margin="auto"
                        top="110px"
                        left="41%"
                        mleft="0"
                        mtop="75px"
                      />
                    </div>
                    <NVD3Chart
                      id="chart"
                      showLegend={false}
                      height={260}
                      type="pieChart"
                      datum={datum}
                      x="key"
                      y="y"
                      donut
                      labelType="none"
                      containerStyle={{
                        marginTop: '-146px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '25px',
                      }}
                    >
                      <div style={{ marginTop: '-12px' }}>
                        {integrantesAhorro.map((item) => (
                          <div
                            style={{
                              background: item.colorGp,
                              width: '25px',
                              height: '25px',
                              borderRadius: '4px',
                              marginTop: '10px',
                            }}
                          ></div>
                        ))}
                      </div>
                      <div>
                        {integrantesAhorro.map((item) => {
                          if (item.name === 'Aeromexico') {
                            return (
                              <p className="charSimulator" style={styles}>
                                Aeroméxico
                              </p>
                            );
                          }
                          return (
                            <p className="charSimulator" style={styles}>
                              {item.name}
                            </p>
                          );
                        })}
                      </div>
                      <div>
                        {integrantesAhorro.map((item) => (
                          <p className="charSimulator" style={styles}>
                            {item.val}
                          </p>
                        ))}
                      </div>
                    </div>
                  </TrainingDivSection>
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="0px"
                    mtop="0px"
                    mtopmd="0px"
                    onClick={() => registrarAhorro()}
                  >
                    Registrar ahorro
                  </CardsBtn>
                </div>
              </div>
            </Container>
          </Shapes>
          <Shapes
            id="Meta2"
            key={2}
            mtopD="7%"
            heightD="350px"
            height="940px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <div
                style={{
                  width: '295px',
                  margin: 'auto auto 30px',
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    color: '#000',
                    backgroundColor: '#FFF',
                    textAlign: 'center',
                    padding: '8px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                >
                  {goals.goalName}
                </div>
                <div
                  style={{
                    backgroundColor: '#FFF',
                    padding: '10px',
                    color: '#07210A',
                  }}
                >
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Se les recomendó ahorrar cada quincena:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {formatter.format(
                      filterFloat(
                        (
                          parseFloat(
                            goals?.goalTypesMonto?.Value.replace(/[,$]/g, ''),
                          )
                          / (goals?.goalTypesTiempo?.Value * 2)
                        ).toFixed(2),
                      ),
                    )}
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Durante:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {goals?.goalTypesTiempo?.Value} Meses
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Actualmente su ahorro es de:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {totalAhorro}
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    El monto de su meta es de:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {goals?.goalTypesMonto?.Value}
                  </div>

                  <TrainingDivSection
                    mt="110px"
                    hew="auto"
                    paddw="0"
                    mlw="1%"
                    mtw="0"
                    // wiw="503px"
                    back=" "
                    // backw="#F3F3F3"
                    bshaw="none"
                    bsha=""
                    br="4px"
                    padding="10px"
                    wi="90%"
                    he="auto"
                    mb="38px"
                    wiw="100%"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-25px',
                      }}
                    >
                      <Image
                        src={logoCoru}
                        mwidth="35%"
                        width="18%"
                        margin="auto"
                        top="110px"
                        left="41%"
                        mleft="0"
                        mtop="75px"
                      />
                    </div>
                    <NVD3Chart
                      id="chart"
                      showLegend={false}
                      height={260}
                      type="pieChart"
                      datum={datum}
                      x="key"
                      y="y"
                      donut
                      labelType="none"
                      containerStyle={{
                        marginTop: '-146px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '25px',
                      }}
                    >
                      <div style={{ marginTop: '-12px' }}>
                        {integrantesAhorro.map((item) => (
                          <div
                            style={{
                              background: item.colorGp,
                              width: '25px',
                              height: '25px',
                              borderRadius: '4px',
                              marginTop: '10px',
                            }}
                          ></div>
                        ))}
                      </div>
                      <div>
                        {integrantesAhorro.map((item) => (
                          <p className="charSimulator" style={styles}>
                            {item.name}
                          </p>
                        ))}
                      </div>
                      <div>
                        {integrantesAhorro.map((item) => (
                          <p className="charSimulator" style={styles}>
                            {item.val}
                          </p>
                        ))}
                      </div>
                    </div>
                  </TrainingDivSection>
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="0px"
                    mtop="0px"
                    mtopmd="0px"
                    onClick={() => registrarAhorro()}
                  >
                    Registrar ahorro
                  </CardsBtn>
                </div>
              </div>
            </Container>
          </Shapes>
        </Carousel>
      ) : (
        <Carousel
          id="CarruselMetasLogros"
          styled={{ background: '#FAFAFA' }}
          transitionMs={1000}
        >
          <Shapes
            id="Meta1"
            key={1}
            mtopD="0%"
            heightD="auto"
            height="auto"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="100%"
              mt="0px"
              widthMobile="100%"
              mb="0px"
              mtMobile="auto"
              mbMobile="auto"
              className="d-flex flex-column"
            >
              <div
                style={{
                  width: '295px',
                  margin: 'auto auto 30px',
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    color: '#000',
                    backgroundColor: '#FFF',
                    textAlign: 'center',
                    padding: '8px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                >
                  {goals.goalName}
                </div>
                <div
                  style={{
                    backgroundColor: '#FFF',
                    padding: '10px',
                    color: '#07210A',
                  }}
                >
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Se les recomendó ahorrar cada quincena:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {formatter.format(
                      filterFloat(
                        (
                          parseFloat(
                            goals?.goalTypesMonto?.Value.replace(/[,$]/g, ''),
                          )
                          / (goals?.goalTypesTiempo?.Value * 2)
                        ).toFixed(2),
                      ),
                    )}
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Durante:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {goals?.goalTypesTiempo?.Value} Meses
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    Actualmente su ahorro es de:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {totalAhorro}
                  </div>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>
                    El monto de su meta es de:
                  </p>
                  <div
                    style={{
                      margin: '10px 0',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: '#A6A6A6',
                      borderRadius: '4px',
                      color: '#07210A',
                      height: '50px',
                      fontSize: '16px',
                      padding: '12px',
                    }}
                  >
                    {goals?.goalTypesMonto?.Value}
                  </div>

                  <TrainingDivSection
                    mt="0"
                    hew="auto"
                    paddw="0"
                    mlw="1%"
                    mtw="0"
                    back=" "
                    bshaw="none"
                    bsha=""
                    br="4px"
                    padding="10px"
                    wi="90%"
                    he="auto"
                    mb="38px"
                    wiw="100%"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20px',
                      }}
                    >
                      <Image
                        src={logoCoru}
                        mwidth="35%"
                        width="18%"
                        margin="auto"
                        top="110px"
                        left="41%"
                        mleft="0"
                        mtop="75px"
                      />
                    </div>
                    <NVD3Chart
                      id="chart"
                      showLegend={false}
                      height={300}
                      type="pieChart"
                      datum={datum}
                      x="key"
                      y="y"
                      donut
                      labelType="none"
                      containerStyle={{
                        marginTop: '-172px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '25px',
                      }}
                    >
                      <div style={{ marginTop: '-12px' }}>
                        {integrantesAhorro.map((item) => (
                          <div
                            style={{
                              background: item.colorGp,
                              width: '25px',
                              height: '25px',
                              borderRadius: '4px',
                              marginTop: '10px',
                            }}
                          ></div>
                        ))}
                      </div>
                      <div>
                        {integrantesAhorro.map((item) => (
                          <p className="charSimulator" style={styles}>
                            {item.name}
                          </p>
                        ))}
                      </div>
                      <div>
                        {integrantesAhorro.map((item) => (
                          <p className="charSimulator" style={styles}>
                            {item.val}
                          </p>
                        ))}
                      </div>
                    </div>
                  </TrainingDivSection>
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="0px"
                    mtop="0px"
                    mtopmd="0px"
                    onClick={() => registrarAhorro()}
                  >
                    Registrar ahorro
                  </CardsBtn>
                </div>
              </div>
            </Container>
          </Shapes>
        </Carousel>
      )}
    </Fragment>
  );
}

export default GoalDetail;
