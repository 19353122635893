import React, { Fragment, useState } from 'react';
import '../../assets/styles/perfil/perfiles.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { TextPerfil } from '../../assets/styles/pantInicio.styled';
import CardVisa from '../../assets/img/perfil/visa.png';
import CardBack from '../../assets/img/perfil/card2.png';
import CardFront from '../../assets/img/perfil/card.png';
import {
  Container, Col12, Col6, SubtitleOne, Button, Image, ParagrapTwo, Col2, Col10, ImageIcon,
} from '../common/common.styled';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

// END MODAL
function FormsCards({ number }) {
  const [Card, setCard] = useState(true);
  const [More, setMore] = useState(false);
  const [keyboardStatus] = useState(true);

  const cc_format = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(' ');
    }
    return value;
  };
  const guardarAddress = (e) => {
    e.preventDefault();
    setMore(true);

    handleOpen();
  };
  const [formCard, SetFormCard] = useState({
    number: '',
    name: '',
    validity: '',
    code: '',
  });

  const [errorCard] = useState({
    number: false,
    name: false,
    validity: false,
    code: false,
    numbervalid: sessionStorage.getItem('numbercard') === null ? -1 : 0,
    codevalid: sessionStorage.getItem('codecard') === null ? -1 : 0,
    validityvalid: sessionStorage.getItem('validitycard') === null ? -1 : 0,
    namevalid: sessionStorage.getItem('namecard') === null ? -1 : 0,
    number: sessionStorage.getItem('numbercard') === null ? -1 : 0,
  });
  const [texterrorCard] = useState({
    number: '',
    name: '',
    validity: '',
    code: '',
  });
  const [btnDisabled] = useState(false);
  const [agregard, setagregard] = useState(false);
  const handleChangeCard = (e) => {
    console.log('Entro a handle');
    SetFormCard({
      ...formCard,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'code') {
      setCard(false);
      console.log(Card);
    } else {
      setCard(true);
    }
    if (e.target.name === 'number' || e.target.name === 'code') {
      SetFormCard({
        ...formCard,
        [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
      });
      // debugger
      if (e.target.name === 'number' && e.target.value.length === 16) {
        errorCard.number = false;
        errorCard.numbervalid = 0;
      } else if (e.target.name === 'code' && e.target.value.length === 3) {
        errorCard.code = false;
        errorCard.codevalid = 0;
      } else {
        errorCard[e.target.name] = true;
        errorCard[`${e.target.name}valid`] = 1;
      }
    } else if (e.target.name === 'validity') {
      const contine = e.target.value.indexOf('/');
      if (e.target.value.length === 2 && contine < 0 && !agregard) {
        e.target.value = `${e.target.value}/`;
        setagregard(true);
      } else if (e.target.value.length <= 3 && agregard) {
        setagregard(false);
      }

      if (e.target.value.length === 5) {
        errorCard.validity = false;
        errorCard.validityvalid = 0;
      } else {
        errorCard.validity = true;
        errorCard.validityvalid = 1;
      }
      SetFormCard({
        ...formCard,
        [e.target.name]: e.target.value.replace(/[^0-9/]/g, ''),
      });
    } else {
      SetFormCard({
        ...formCard,
        [e.target.name]: e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
      });
      const secciones = e.target.value.trim().split(' ');
      if (secciones.length > 2) {
        errorCard.namevalid = 0;
        errorCard.name = false;
      } else {
        errorCard.namevalid = 1;
        errorCard.name = true;
      }
    }
  };

  const handleClick = (e) => {
    if (e.target.name === 'code') { setCard(false); } else { setCard(true); }
  };

  const handleClickMore = () => {
    setMore(false);
  };

  // MODAL
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ModalMessage = () => (
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo fontWeight="700" cursor="pointer" texttalign ="right" textalignMobile="right" className='mx-auto' onClick={handleClose}>X</ParagrapTwo>
                    <SubtitleOne mt="3%" className='font-weight-bold' sizeMobile="20px" size="20px">
                    </SubtitleOne>
                    <ParagrapTwo className='mx-auto' mb="0px">Tus cambios se han guardado con éxito.</ParagrapTwo>
                    <Button className='d-block mt-5 mt-md-4 mb-5' onClick={handleClose}>
                        Aceptar
                    </Button>
                </div>
          </Modal>
  );

  return (
        <Fragment>
			<Container width='100%' mt='0rem' widthMobile='100%' mtMobile='2rem' className={!More ? 'Display' : 'NoDisplay' }>
                <Container width='100%' bb="1px solid #D6D6D6" mb="5%" widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" >
                        <Col12 pMobile="0px">
                            <SubtitleOne mb="1%" transform=" " textalingn="left" textalingnMobile="left" className='font-weight-bold roboto' sizeMobile="20px" size="20px">
                                Agregar Tarjeta
                            </SubtitleOne>
                            <ParagrapTwo mtWeb="0px" fontSize="16px" fontSizeWeb="16px" textalignMobile="left" texttalign="left" className='mx-auto'>Datos de tu tarjeta para realizar tus compras en la plataforma.</ParagrapTwo>
                            <Container width='40%' widthMobile='85%' mtMobile="0px" float="auto" mMobile="auto" mt="3%" mtMobile="5%" >
                                <Image src={Card ? CardFront : CardBack} heightMobile="179px"></Image>
                                <ParagrapTwo id="man" className ={Card ? 'Display roboto' : 'NoDisplay roboto'} color="#fff" name="man" ml="4%" zindex="2" fontSize= "25px" textalignMobile="center" mt="-30%" mtWeb="-28%">{cc_format(formCard.number)}</ParagrapTwo>
                                <ParagrapTwo id="man" className ={Card ? 'Display roboto' : 'NoDisplay roboto'} texttalign="left" ml="4%" color="#fff" name="man" ml="5%" zindex="2" fontSize= "12px" fontSizeWeb="11px" textalignMobile="left" mt="-15%" mtWeb="-15%" mlWeb="10px">{formCard.name}</ParagrapTwo>
                            </Container>
                            <form autoComplete='off' className={keyboardStatus ? 'Display' : 'NoDisplay'} onSubmit={guardarAddress}>
                                <Container width='100%' widthMobile='100%'>
                                    <Col12 pMobile="0px">
                                        <TextField
                                            id="number"
                                            name="number"
                                            label="Número de tarjeta"
                                            fullWidth
                                            type="tel"
                                            variant="outlined"
                                            onChange={handleChangeCard}
                                            onClick={handleClick}
                                            helperText={texterrorCard.number}
                                            value={formCard.number}
                                            inputProps={{ maxLength: '16', type: 'tel', className: errorCard.numbervalid == 1 ? 'perfil invalidInput' : errorCard.numbervalid == 0 ? 'perfil validInput' : 'perfil' }}
                                            >
                                        </TextField>
                                    </Col12>
                                    <Col6 flex="0 0 100%" maxwidth="100%" pMobile="0px">
                                        <TextField
                                            id="code"
                                            name="code"
                                            variant="outlined"
                                            label="Código de seguridad "
                                            fullWidth
                                            type="password"
                                            onChange={handleChangeCard}
                                            onClick={handleClick}
                                            helperText={texterrorCard.code}
                                            value={formCard.code}
                                            inputProps={{ maxLength: '3', className: errorCard.codevalid == 1 ? 'perfil invalidInput' : errorCard.codevalid == 0 ? 'perfil validInput' : 'perfil' }}
                                            >
                                        </TextField>
                                    </Col6>
                                    <Col12 pMobile="0px" pMobile="0px">
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Nombre del titular"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChangeCard}
                                            onClick={handleClick}
                                            helperText={texterrorCard.name}
                                            value={formCard.name}
                                            inputProps = {{ className: errorCard.namevalid == 1 ? 'perfil invalidInput' : errorCard.namevalid == 0 ? 'perfil validInput' : 'perfil' }}
                                            >
                                        </TextField>
                                    </Col12>
                                    <Col6 pMobile="0px">

                                        <TextField
                                            id="validity"
                                            variant="outlined"
                                            name="validity"
                                            label="Vigencia"
                                            onChange={handleChangeCard}
                                            onClick={handleClick}
                                            helperText={texterrorCard.validity}
                                            value={formCard.validity}
                                            inputProps={{ maxLength: '5', className: errorCard.validityvalid == 1 ? 'perfil invalidInput' : errorCard.validityvalid == 0 ? 'perfil validInput' : 'perfil' }}
                                            >
                                        </TextField>
                                    </Col6>

                                </Container>
                                <Button disabled={btnDisabled} type='submit' className='d-block mt-5 mt-md-4 mb-5 roboto' label='Guardar'>
                                    Guardar
                                </Button>
                            </form>
                        </Col12>
                    </Container>
            </Container>
            <Container width='94%' mt='5%' widthMobile='94%' mtMobile='2rem' className={More ? 'Display' : 'NoDisplay' } >
                <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" bb="1px solid #D6D6D6" mb="5%" >
                    <Col2 pMobile="0px">
                        <ImageIcon alt="Avatar" src={CardVisa} widthMobile="50px!important"/>
                    </Col2>
                    <Col10 pMobile="0px">
                        <SubtitleOne widthM="75%" marginm="auto" mb="1%" width="75%" textalingn ="left" className='font-weight-bold roboto' sizeMobile="16px" size="18px">
                            {formCard.name}
                        </SubtitleOne>
                        <SubtitleOne widthM="75%" marginm="auto" mb="1%" textalingn="left" className='font-weight-bold roboto' sizeMobile="16px" size="18px">
                            **************{formCard.number.substring(formCard.number.length - 3, formCard.number.length)}
                        </SubtitleOne>
                    </Col10>
                    <Col12 pt="0px" pMobile="0px">
                    <TextPerfil cursor="pointer" color="gray" mt="0%" mb="10%" fs="18px" width="100%" widthMobile="100%" lh="17px" textAling="right" onClick={handleClickMore} className="roboto">Ver o editar</TextPerfil>
                    </Col12>
                </Container>
            </Container>
        </Fragment>
  );
}
export default FormsCards;
