import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { gsap } from 'gsap';
import Val from '../../assets/img/anacoach/Val.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { GET_PRODUCTS_PYMES } from '../../queries';
import {
  CardsBtn,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSectionPymes,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import {
  Container,
  Col7,
} from '../../components/common/common.styled';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ProductsFremium from '../../components/common/ProductsFremium';
import Confeti from '../../assets/img/anacoach/ConfPy.png';
import ProductCardPymes from '../../components/common/ProductsPymes.js';
import '../../assets/styles/txtHeaderPymes.css';
import ModalVirality from '../../components/common/ModalVirality';
import InfoWoMonetizable from '../../components/common/InfoWoMonetizable';

const RpPymes = ({ hora, trainingColor }) => {
  const styles = {
    paddingLeft: '10px',
    fontFamily: 'cabin',
    fontStyle: 'normal',
    fontSize: '19px',
    lineHeight: '120%',
    letterSpacing: '-0.3px',
    textAlign: 'left',
  };

  const stylesResult = {
    display: 'flex',
    padding: '3% 2% 2% 3%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
    border: '1px solid #888',
    borderRadius: '4px',
  };

  const stylesRP = {
    textAlign: 'left',
    fontSize: '16px',

    color: '#252525',
  };


  const idUser = sessionStorage.getItem('idUser');
  const [showBtn, setShowBtn] = useState(true);
  const [loaderWait, setLoaderWait] = useState(true);
  const [currentTraining] = useState(
    sessionStorage.getItem('idTraining'),
  );
  const [products, setProducts] = useState([]);
  const [loaderWaitProduct, setLoaderWaitProduct] = useState(false);

  const [registradoHacienda] = useState(
    sessionStorage.getItem('Registro_hacienda') || 'No',
  );
  const showCreditos = () => {
    getProductsPymes({
      variables: { idUser, idTraining: String(currentTraining) },
    });

    setTimeout(() => {
      setLoaderWaitProduct(false);
      setShowBtn(false);
      gsap.to(window, { duration: 1, scrollTo: '#products' });
    }, 3000);
  };

  const [getProductsPymes] = useLazyQuery(GET_PRODUCTS_PYMES, {
    onCompleted({ getProductsPymes }) {
      console.log('Productos', JSON.parse(getProductsPymes.response));
      setProducts(JSON.parse(getProductsPymes.response));
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#infoCalcPymes' });
    }, 3000);
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      products.map((product) => {
        product.benefits.map((benefit) => {
          if (benefit.Name === 'MontoMinimo') {
            product.Plazo = benefit.Description;
          }
        });
        product.TipoCredito = 'Simple / Revolvente';
      });
    }
  }, [products]);

  return (
    <>
      {!loaderWait ? (
        <>
          <InfoWoMonetizable 
            paddingSm='0 5%'
            nameClient={sessionStorage.getItem('FirstName') + ' ' + (sessionStorage.getItem('MiddleName') || '') + 
            ' ' + sessionStorage.getItem('FathersLastName') + ' '+ sessionStorage.getItem('MothersLastName')}
            telClient={sessionStorage.getItem('CellPhone')}
            emailClient={sessionStorage.getItem('Email1')}
          />
          <br />
          <TrainingDivSectionPymes wiw="60%" mw="0 0 0 18%" wiw320="90%">
            <div id="infoCalcPymes"></div>
            <div
              id='text-pymes'
              style={{backgroundImage: `url(${Confeti})`}}
            >
              <p
                style={{
                  fontSize: '30px',
                  fontFamily: 'cabin',
                  fontStyle: 'bold',
                  lineHeight: '35px',
                  lineHeight: '100%',
                  align: 'center',
                  verticalAlign: 'center',
                  letterSpacing: '-0.3px',
                  textAlign: 'center',
                }}
              >
                <b>
                  {sessionStorage.getItem('FirstName')}, esta es tu información
                  registrada, continua para ver las mejores opciones para ti.
                </b>
              </p>
            </div>
            <TrainingDivSection
              bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
              mt="0px"
              mtw="20%"
              br="4px"
              padding="15px"
              wi="auto"
              wiw="auto"
              he="auto"
              mb="0px"
            >
              <form
                style={{ marginTop: '0px', padding: '0px' }}
              >
                <label style={styles}>
                  <b>¿Qué tipo de crédito buscas?</b>
                </label>
                <div style={stylesResult}>
                  <p style={stylesRP}>
                    {' '}
                    {sessionStorage.getItem('Tipo_de_credito')}{' '}
                  </p>{' '}
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '6px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />
                </div>
                <br />

                <label style={styles}>
                  {' '}
                  <b>¿A qué plazo lo buscas?</b>{' '}
                </label>
                <div style={stylesResult}>
                  <p style={stylesRP}>
                    {' '}
                    {sessionStorage.getItem('Plazo_buscado')}{' '}
                  </p>{' '}
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '6px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />
                </div>
                <br />

                <label style={styles}>
                  {' '}
                  <b>¿Qué cantidad requieres?</b>{' '}
                </label>
                <div style={stylesResult}>
                  <p style={stylesRP}>
                    {' '}
                    {sessionStorage.getItem('Cantidad_requerida')}{' '}
                  </p>{' '}
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '6px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />
                </div>
                <br />

                <label style={styles}>
                  {' '}
                  <b>¿Estás registrado ante Hacienda?</b>
                </label>
                <div style={stylesResult}>
                  <p style={stylesRP}>
                    {sessionStorage.getItem('Registro_hacienda')}{' '}
                  </p>

                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '6px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />
                </div>

                <br />

                <label style={styles}>
                  {' '}
                  <b>¿Tipo de persona fiscal?</b>
                </label>
                <div style={stylesResult}>
                  {' '}
                  <p style={stylesRP}>
                    {' '}
                    {sessionStorage.getItem('Persona')}{' '}
                  </p>{' '}
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '6px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />{' '}
                </div>
                <br />

                <label style={styles}>
                  <b>¿Cuántos meses llevas facturando?</b>
                </label>
                <div style={stylesResult}>
                  <p style={stylesRP}>
                    {' '}
                    {sessionStorage.getItem('Tiempo_Facturacion')} Meses{' '}
                  </p>{' '}
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '6px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />
                </div>
                <br />
              </form>
            </TrainingDivSection>

            <CardsBtn
              className={showBtn ? '' : 'hidden'}
              min_w="100%"
              min_web="100%"
              onClick={() => {
                showCreditos();
                setLoaderWaitProduct(true);
              }}
            >
              Continuar
            </CardsBtn>
          </TrainingDivSectionPymes>
          {!loaderWaitProduct ? (
            <>
              {!showBtn ? (
                <>
                  <div id="products"></div>
                  <ModalVirality />
                  <TrainingSectionGray back="#fafafa">
                    <TrainingDivSection2
                      wiw="670px"
                      paddi="0"
                      paddiw="0"
                      mw="auto"
                      back="#fafafa"
                    >
                      {registradoHacienda === 'No' ? (
                        <>
                          <CardOne
                            classCard="cardOne"
                            classTop="topOne1"
                            children={
                              <>
                                <p>
                                  {sessionStorage.getItem('FirstName')}, al
                                  parecer no cumples con el requisito
                                  <b> "Estar dado de alta en Hacienda" </b>
                                  por lo que, lamentablemente, no puedes
                                  solicitar un crédito PyME en este momento.
                                </p>
                              </>
                            }
                          />

                          <CardOne
                            classCard="cardOne"
                            classTop="topOne1"
                            textbold="Pero ¡No te preocupes! Tenemos las siguientes opciones de créditos personales para ti:"
                            text="Tenemos estas opciones para ti. Selecciona la que más se ajusta a tus necesidades para contactar un ejecutivo y darte mayor información."
                          />
                        </>
                      ) : (
                        <CardOne
                          classCard="cardOne"
                          classTop="topOne1"
                          textbold="Estas son las mejores opciones para ti:"
                          text="Tenemos estas opciones para ti. Selecciona la que más se ajusta a tus necesidades para contactar un ejecutivo y darte mayor información."
                        />
                      )}
                    </TrainingDivSection2>
                    <br />
                  </TrainingSectionGray>

                  {products.length > 0 ? (
                    <ProductCardPymes
                      products={products}
                      currentTraining={currentTraining}
                    />
                  ) : null}

                  <ProductsFremium
                    idT={parseInt(sessionStorage.getItem('idTraining'))}
                  />

                  <TrainingSectionGray back="#fafafa">
                    <TrainingDivSection2
                      wiw="670px"
                      paddi="0"
                      paddiw="0"
                      mw="auto"
                      back="#fafafa"
                    >
                      <ModalAmazon show={false} wo={'Pymes'}></ModalAmazon>
                      <SectionShare WO="Pymes"></SectionShare>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                </>
              ) : null}
            </>
          ) : (
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          )}
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </>
  );
};
export default RpPymes;
