import React, { useState, useEffect, Suspense } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutation, useLazyQuery } from "@apollo/client";
import CreditDetails from "../Routines/CreditDetails";
import ShowAvailablePL from "../Routines/ShowAvailablePL";
import DetailRFC from "../Routines/DetailRFC";
import PersonalInformation from "../Routines/PersonalInformation";
import Domicile from "../Routines/Domicile";
import UserAuthentication from "../Routines/UserAuthentication";
import DatosBancariosPL from "../Routines/DatosBancariosPL";
import DatosLaborales from "../Routines/DatosLaborales";
import Referencias from "../Routines/Referencias";
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from "../../Helpers/index";
import Curp from "../Routines/Curp";
import { DivLoader } from "../../assets/styles/Training.styled";
import {
  CREATE_SESSION_MYSQL,
  CREATE_SESSION,
  SEND_IBILLETE_APP,
} from "../../mutations";
import { GET_TRAINING_COLOR } from "../../queries";

const DatosPrincipales = React.lazy(() =>
  import("../Routines/DatosPrincipales")
);

const RoutinesOrderPL = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  crediteaVal,
}) => {
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [colorTraining, setColorTraining] = useState("");
  const [validateSendiBillete, setValidateSendiBillete] = useState(true);

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem("idTraining");
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("id_session", dynamicMongoSession.response);
        localStorage.setItem("id_session", dynamicMongoSession.response);
      }
    },
  });
  const [sendiBillete] = useMutation(SEND_IBILLETE_APP, {
    onCompleted({ sendIbilleteApp }) {
      console.log("iBillete response", sendIbilleteApp);
      const { url } = sendIbilleteApp.usuario;
      sessionStorage.setItem("urlBillete", url);
      setValidateSendiBillete(false);
    },
    onError(err) {
      console.error(err);
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log("createSessionMysql", createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem("idDatSession", jsonResponse.idSession);
        sessionStorage.setItem("idCatRelAction", jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    // se ejecutara cuando orderRutine === 8
    const productSelected = sessionStorage.getItem("productPL") || "";
    if (
      orderRutine === 8 &&
      productSelected === "iBillete" &&
      validateSendiBillete
    ) {
      setValidateSendiBillete(false);
      const fecNac = `${sessionStorage.getItem(
        "year"
      )}-${sessionStorage.getItem("month")}-${sessionStorage.getItem("day")}`;
      sendiBillete({
        variables: {
          input: {
            personales: {
              nombre: sessionStorage.getItem("FirstName"),
              nombre2: sessionStorage.getItem("MiddleName") || "",
              apePat: sessionStorage.getItem("FathersLastName"),
              apeMat: sessionStorage.getItem("MothersLastName"),
              fecNac,
              eCivil: sessionStorage.getItem("MaritalStatus"),
              rfc: sessionStorage.getItem("RFC") || "XXXX010101XXX",
              genero: sessionStorage.getItem("Gender"),
              birthState: sessionStorage.getItem("BirthState"),
            },
            contacto: {
              email: sessionStorage.getItem("Email1"),
              cellPhone: sessionStorage.getItem("CellPhone"),
            },
            domicilio: {
              cp: sessionStorage.getItem("ZipCode"),
              calle: sessionStorage.getItem("Street"),
              numExt: sessionStorage.getItem("ExtNo"),
              numInt: sessionStorage.getItem("IntNo") || "",
              ciudad: sessionStorage.getItem("Municipality"),
              colonia: sessionStorage.getItem("Suburb"),
              estado: sessionStorage.getItem("State"),
            },
          },
        },
      });
    }
  }, [orderRutine]);
  useEffect(() => {
    const { idUser, idCont, hostName, recovery, pageName } =
      getQueryVariableHashtagDeeplink("funnel-tarjeta");
    const source = getQueryVariable("utm_source");
    const medium = getQueryVariable("utm_medium");
    const campaign = getQueryVariable("utm_campaign");
    const viralityID = getQueryVariable("viralityID");
    sessionStorage.setItem("viralityID", viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign
    );
    if (recovery !== "" && recovery !== undefined && recovery !== "false") {
      setMainDataRoutine(1);
      sessionStorage.setItem("recoveryUser", "true");
    } else {
      sessionStorage.setItem("recoveryUser", "false");
      setMainDataRoutine(2);
    }
    if (
      idUser !== "" &&
      idUser !== undefined &&
      idCont !== "" &&
      idCont !== undefined
    ) {
      sessionStorage.setItem("idUser", idUser);
      sessionStorage.setItem("idCont", idCont);
      localStorage.setItem("idUser", idUser);
      localStorage.setItem("idCont", idCont);
      sessionStorage.setItem("Primervisita", true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem("ID_GA"));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem("viralityID")))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem("viralityID")
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: "sessions",
            data: dataString,
          },
        };
        if (!sessionStorage.getItem("id_session")) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem("Primervisita", false);
    }
  }, []);

  return (
    <div>
      {orderRutine >= 1 && mainDataRoutine !== 0 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <DatosPrincipales
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={colorTraining}
            curp={true}
          />
        </Suspense>
      ) : null}
      <div id="rutina1"></div>
      {orderRutine >= 2 ? (
        <CreditDetails
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina2"></div>
      {orderRutine >= 3 ? (
        <ShowAvailablePL
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina3"></div>
      <DetailRFC
        Date={Date}
        hora={hora}
        orderRutine={orderRutine}
        setOrderRutine={setOrderRutine}
        currentTraining={currentTraining}
        createJsonInput={createJsonInput}
        questions={questions}
        setQuestions={setQuestions}
        currentRoutine={4}
        nextOrderRoutine={5}
        trainingColor={colorTraining}
      />
      <div id="rutina4"></div>
      {orderRutine >= 5 ? (
        <PersonalInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={6}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina5"></div>
      {/* aquie debera ir la rutina de curp 6 */}
      {orderRutine >= 6 ? (
        <Curp
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          idDatRelRoutines={16}
          currentRoutine={6}
          nextOrderRoutine={7}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina6"></div>
      {orderRutine >= 7 ? (
        <Domicile
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={7}
          nextOrderRoutine={8}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina7"></div>
      {orderRutine >= 8 ? (
        <UserAuthentication
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={8}
          nextOrderRoutine={9}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina8"></div>
      {orderRutine >= 9 ? (
        <DatosBancariosPL
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={9}
          nextOrderRoutine={10}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina9"></div>
      {orderRutine >= 10 ? (
        <DatosLaborales
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={10}
          nextOrderRoutine={11}
          trainingColor={colorTraining}
        />
      ) : null}
      <div id="rutina10"></div>
      {orderRutine >= 11 ? (
        <Referencias
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={11}
          nextOrderRoutine={0}
          trainingColor={colorTraining}
          crediteaVal={crediteaVal}
        />
      ) : null}
      <div id="rutina11"></div>
    </div>
  );
};

export default RoutinesOrderPL;
