import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';

import Dailymotion from 'react-dailymotion';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '700px',
    backgroundColor: '#FFFFFF',
  },
});

const InitialMessage = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <CardContent>
          <Dailymotion
            video="k1QMCwKSZWxDR3wTrlD"
            width="100%"
            height="670px"
            autoplay="1"
            controls="false"
            mute="false"
          />
        </CardContent>
      </div>
    </Fragment>
  );
};

export default InitialMessage;
