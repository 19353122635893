/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TextField from '@material-ui/core/TextField';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import ClickButton from '../../Helpers/HookHelpers';

const SonHomework = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [homeWork, setHomeWork] = useState([]);

  const [days, setDays] = useState([]);
  const [arrayDaysSelected, setarrayDaysSelected] = useState([]);
  const [showDaysSelected, setshowDaysSelected] = useState(false);

  const [horario, setHorario] = useState('');
  const [showQuestionReward, setShowQuestionReward] = useState(false);
  const [reward, setReward] = useState('');
  const [flagOpenFieldReward, setFlagOpenFieldReward] = useState(false);

  const [countOpt, setCountOpt] = useState(0);
  const [homeworkByAge, setHomeworkByAge] = useState([]);
  const [tasksList, setTasksList] = useState(false);
  const [showHw1, setShowHw1] = useState(false);
  const [showHw2, setShowHw2] = useState(false);
  const [showRespOther] = useState(false);
  useEffect(() => {
    if (showHw2) {
      const click = `AsignarTarea_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      gsap.to(window, { duration: 1, scrollTo: '#activeResp3SonHom' });
    }
  }, [showHw2]);
  useEffect(() => {
    const edad = sessionStorage.getItem('EdadHijo');
    if (edad) {
      if (edad === '4 a 6 años') {
        setHomeworkByAge([
          'Recoger juguetes',
          'Vestirse solo',
          'Recoger su ropa',
          'Otro',
        ]);
      } else if (edad === '7 a 9 años') {
        setHomeworkByAge([
          'Hacer su cama diario',
          'Levantar mesa después de comer',
          'Asear lugar de tareas',
          'Organizar mochila para la escuela',
          'Realizar tareas antes de cierto horario',
          'Otro',
        ]);
      } else if (edad === '10 a 12 años') {
        setHomeworkByAge([
          'Dar de comer a su mascota',
          'Organizar su ropa',
          'Ayudar a lavar los platos',
          'Limpiar jardín/patio',
          'Asear su cuarto',
          'Otro',
        ]);
      } else if (edad === '13 a 15 años') {
        setHomeworkByAge([
          'Lavar su ropa',
          'Planchar su ropa',
          'Ayudar con la limpieza de casa',
          'Sacar a pasear mascota',
          'Sacar la basura',
          'Cuidar a hermano menor',
          'Otro',
        ]);
      } else if (edad === '15 a 18 años') {
        setHomeworkByAge(['Limpiar jardin', 'Otro']);
      }
    }
  }, []);

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {

                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                    // if (detailQuestionVideo[0].Description === "Video") i = i - 1;
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [form, setForm] = useState({
    homework1: '',
    homework2: '',
    hour: '07',
    minutes: '30',
    reward: '',
  });

  const handleChange = (e, question = '') => {
    const { name, value } = e.target;
    console.log('handleChange === ', name, value);
    setForm({
      ...form,
      [name]: value,
    });
  };

  const saveHour = (e) => {
    const click = `ContinuarHoras_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    if (form.hour !== '' && form.minutes !== '') {
      questions.push({
        id: 'hour',
        question: 3,
        value: `${form.hour}:${form.minutes} ${horario}`,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setShowQuestionReward(true);
      setHorario(`${form.hour}:${form.minutes} ${horario}`);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeResp5SonHom' });
  };
  const saveDays = (e) => {
    const click = `ContinuarDias_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const storeTemp = days.map((item) => item.id);
    const resp = [
      {
        id: 'seleccionDias',
        question: 2,
        value: storeTemp.join(','),
      },
    ];
    createJsonInput(Rutine, resp);
    setQuestions([]);
    setarrayDaysSelected(storeTemp);
    setshowDaysSelected(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeResp4SonHom' });
  };

  const saveTasks = (e) => {
    e.preventDefault();
    if (!isEmptyObject(Rutine) && homeWork.includes('Otro')) {
      questions.push({
        id: 'homeWork',
        question: 1,
        value: homeWork.toString(),
      });
      console.log('Otro-----------------------');
      console.log(`homework: ${homeWork.toString()}`);
      console.log(`Rutine: ${JSON.stringify(Rutine)}`);
      console.log(`Questions: ${JSON.stringify(questions)}`);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setTasksList(true);
      setShowHw2(true);
    } else if (!isEmptyObject(Rutine)) {
      questions.push({
        id: 'homeWork',
        question: 1,
        value: homeWork.toString(),
      });

      console.log(`homework: ${homeWork.toString()}`);
      console.log(`Rutine: ${JSON.stringify(Rutine)}`);
      console.log(`Questions: ${JSON.stringify(questions)}`);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setTasksList(true);
    }
  };

  const handleSaveReward = (e) => {
    const click = `ContinuarGuardado_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    if (form.reward !== '') {
      questions.push({
        id: 'recompensa',
        question: 4,
        value: form.reward,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setReward(form.reward);
      setFlagOpenFieldReward(true);
      setOrderRutine(nextOrderRoutine);
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeStatus = (numQuestion, name, value, type = '', e) => {
    console.log('handleChangeStatus', numQuestion, name, value);

    const elements = document.querySelectorAll(`div[name="${name}"]`);
    let element;
    if (elements.length === 2) {
      element = elements[1];
    } else {
      element = elements[0];
    }
    console.log(`div[name="${name}"]`);
    console.log(element);
    if (element.classList.contains('border-pink-active')) {
      console.log('remover clase catch');
      element.classList.remove('border-pink-active');
    } else {
      element.classList.add('border-pink-active');
      console.log('comentario');
    }

    if (numQuestion === undefined) return;
    const elementExist = days.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      const quetionsTemp = [];
      for (const element of days) {
        if (element.id !== elementExist[0].id) {
          console.log(element.id, elementExist[0].id);
          quetionsTemp.push(element);
        }
      }
      setDays(quetionsTemp);
    } else {
      setDays([
        ...days,
        {
          id: name,
          question: numQuestion,
          value: name,
        },
      ]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    if (homeWork.includes(val.value)) {
      let element;
      element = document.getElementsByName(val.value)[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.remove('selectedBen');
      }
      homeWork.splice(homeWork.indexOf(val.value), 1);
      setCountOpt(countOpt - 1);
    } else {
      switch (val.step) {
        case 0:
          if (val.idField === 733) {
            setCountOpt(countOpt + 1);
            setHomeWork(homeWork.concat(val.value));

            setShowHw1(true);
          } else {
            setCountOpt(countOpt + 1);
            setHomeWork(homeWork.concat(val.value));
          }

          gsap.to(window, { duration: 1, scrollTo: '#activeResp2SonHom' });
          break;
        case 3:
          setReward(val.value);
          if (val.value !== 'Otro') {
            questions.push({
              id: 'recompensa',
              question: 4,
              value: val.value,
            });
            createJsonInput(Rutine, questions);
            setQuestions([]);
            setOrderRutine(nextOrderRoutine);
          }
          gsap.to(window, { duration: 1, scrollTo: '#activeResp6SonHom' });
          break;
        default:
          console.log(val);
          break;
      }
    }
  };

  function isEmptyObject(obj) {
    console.log(`isEmptyObject: ${!Object.keys(obj).length}`);
    return !Object.keys(obj).length;
  }

  useEffect(() => {
    let element;
    if (homeWork.includes('Recoger juguetes')) {
      element = document.getElementsByName('Recoger juguetes')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Vestirse solo')) {
      element = document.getElementsByName('Vestirse solo')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }

    if (homeWork.includes('Recoger su ropa')) {
      element = document.getElementsByName('Recoger su ropa')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Hacer su cama diario')) {
      element = document.getElementsByName('Hacer su cama diario')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Levantar mesa después de comer')) {
      element = document.getElementsByName('Levantar mesa después de comer')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Asear lugar de tareas')) {
      element = document.getElementsByName('Asear lugar de tareas')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Organizar mochila para la escuela')) {
      element = document.getElementsByName(
        'Organizar mochila para la escuela',
      )[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Realizar tareas antes de cierto horario')) {
      element = document.getElementsByName(
        'Realizar tareas antes de cierto horario',
      )[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Dar de comer a su mascota')) {
      element = document.getElementsByName('Dar de comer a su mascota')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Organizar su ropa')) {
      element = document.getElementsByName('Organizar su ropa')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Ayudar a lavar los platos')) {
      element = document.getElementsByName('Ayudar a lavar los platos')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Limpiar jardín/patio')) {
      element = document.getElementsByName('Limpiar jardín/patio')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Asear su cuarto')) {
      element = document.getElementsByName('Asear su cuarto')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Lavar su ropa')) {
      element = document.getElementsByName('Lavar su ropa')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Planchar su ropa')) {
      element = document.getElementsByName('Planchar su ropa')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Ayudar con la limpieza de casa')) {
      element = document.getElementsByName('Ayudar con la limpieza de casa')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Sacar a pasear mascota')) {
      element = document.getElementsByName('Sacar a pasear mascota')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Sacar la basura')) {
      element = document.getElementsByName('Sacar la basura')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Cuidar a hermano menor')) {
      element = document.getElementsByName('Cuidar a hermano menor')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Limpiar jardin')) {
      element = document.getElementsByName('Limpiar jardin')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (homeWork.includes('Otro')) {
      element = document.getElementsByName('Otro')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
  }, [homeWork]);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setHomeWork(
        sessionStorage.getItem('EleccionTareas')
          ? sessionStorage.getItem('EleccionTareas').split(',')
          : [],
      );
      if (sessionStorage.getItem('EleccionTareas')) {
        const eleccion = sessionStorage.getItem('EleccionTareas');
        if (eleccion.includes('Otro')) setShowHw1(true);
      }
      setarrayDaysSelected(
        sessionStorage.getItem('DiasTareas')
          ? sessionStorage.getItem('DiasTareas').split(',')
          : [],
      );
      sessionStorage.getItem('DiasTareas')
        ? setshowDaysSelected(true)
        : setshowDaysSelected(false);
      setShowQuestionReward(
        !!sessionStorage.getItem('HorarioTareas'),
      );
      setHorario(
        sessionStorage.getItem('HorarioTareas')
          ? sessionStorage.getItem('HorarioTareas')
          : '',
      );
      sessionStorage.getItem('HorarioTareas')
        ? setShowQuestionReward(true)
        : setShowQuestionReward(false);
      setReward(
        sessionStorage.getItem('RecompensaTarea')
          ? sessionStorage.getItem('RecompensaTarea')
          : '',
      );
    }
  }, [recoveryRoutine]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && homeWork.length !== 1 && !showHw1 && !showRespOther) {
        gsap.to(window, { duration: 1, scrollTo: '#activeSonHomework' });
        setReviewScroll(false);
      }
    }, 1400);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeSonHomework"></div>
        <QuestionsOrder
          classCard={'cardOne'}
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion
          className={
            tasksList === false && !showHw1 && !showRespOther ? '' : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              classificationQuestionDetail={homeworkByAge}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <TrainingSectionGray
          style={{
            display:
              homeWork.length > 0 && tasksList === false && showHw1 === false
                ? 'block'
                : 'none',
          }}
          wiw="100%"
          mw="auto"
          back="#FAFAFA"
          mtop="0"
        >
          <Container
            mleft="18%"
            width="60%"
            mt="0rem"
            widthMobile="90%"
            mtMobile="0rem"
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn
                onClick={(e) => saveTasks(e)}
                min_web="100%"
                mtopw="0px"
                minmd="100%"
              >
                Continuar
              </CardsBtn>
            </div>
          </Container>
        </TrainingSectionGray>
        <div id="activeResp2SonHom"></div>
        <CardTwo
          classCard={tasksList || showHw1 ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={homeWork.map((task) => {
            if (homeWork.indexOf(task) < homeWork.length - 1) {
              if (task === 'Otro' && form.homework1 !== '') {
                task = form.homework1;
              }
              return `${task}, `;
            } if (homeWork.indexOf(task) === homeWork.length - 1) {
              if (task === 'Otro' && form.homework1 !== '') {
                task = form.homework1;
              }
              return `${task}.`;
            }
          })}
          // text2={!showHw1 ? homeWork[1] : ""}
          colorTraining={trainingColor}
        />
        {showHw1 && !showHw2 ? (
          <Fragment>
            <TrainingSectionGray
              wiw="60%"
              mw=" 20px 0 0 18%"
              padding=" 1% 3% 3% 3%"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  marginTop: '35px',
                }}
              >
                <p style={{ fontSize: '18px' }}>
                  ¿Qué otra tarea quieres asignar a tu hijo?
                </p>
              </div>
              <TrainingQuestion>
                <QuestionsFlex display="inline">
                  <TextField
                    variant="outlined"
                    id="homework1"
                    name="homework1"
                    autoComplete="off"
                    placeholder="Nombre de la tarea 1"
                    required
                    fullWidth
                    value={form.homework1}
                    onChange={handleChange}
                  />
                </QuestionsFlex>
              </TrainingQuestion>
              <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0">
                <Container
                  width="100%"
                  mt="0rem"
                  widthMobile="90%"
                  mtMobile="0rem"
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      min_web="100%"
                      onClick={(e) => saveTasks(e)}
                    >
                      Asignar tarea
                    </CardsBtn>
                  </div>
                </Container>
              </TrainingSectionGray>
            </TrainingSectionGray>
          </Fragment>
        ) : null}
        <div id="activeResp3SonHom"></div>
        {/*
        <CardTwo
          classCard={showHw2 ? "cardTwoResp" : "hidden"}
          classTop="topTwo1"
          imgMan="hidden"
          text={"Otro: " + form.homework1}
          colorTraining={trainingColor}
        />
        */}

        <QuestionsOrder
          classCard={
            showHw2 || (tasksList && !homeWork.includes('Otro'))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion
          className={
            (showHw2 || (tasksList && !homeWork.includes('Otro')))
            && !showDaysSelected
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeStatus}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <TrainingSectionGray
          style={{
            display:
              days.length > 0 && showDaysSelected === false ? 'block' : 'none',
          }}
          wiw="100%"
          mw="auto"
          back="#FAFAFA"
          mtop="0"
        >
          <Container
            mleft="18%"
            width="60%"
            mt="0rem"
            widthMobile="90%"
            mtMobile="0rem"
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn
                onClick={(e) => saveDays(e)}
                min_web="100%"
                mtopw="0px"
                minmd="100%"
              >
                Continuar
              </CardsBtn>
            </div>
          </Container>
        </TrainingSectionGray>
        <div id="activeResp4SonHom"></div>
        <CardTwo
          classCard={showDaysSelected ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={arrayDaysSelected[0]}
          text2={arrayDaysSelected.length >= 2 ? arrayDaysSelected[1] : ''}
          text3={arrayDaysSelected.length >= 3 ? arrayDaysSelected[2] : ''}
          text4={arrayDaysSelected.length >= 4 ? arrayDaysSelected[3] : ''}
          text5={arrayDaysSelected.length >= 5 ? arrayDaysSelected[4] : ''}
          children={
            <>
              <p>{arrayDaysSelected.length >= 6 ? arrayDaysSelected[5] : ''}</p>
              <p>{arrayDaysSelected.length >= 7 ? arrayDaysSelected[6] : ''}</p>
            </>
          }
          colorTraining={trainingColor}
        />
        <QuestionsOrder
          classCard={showDaysSelected ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion
          className={showDaysSelected && !showQuestionReward ? '' : 'hidden'}
        >
          <p
            style={{ fontSize: '20px', textAlign: 'center', fontWeight: '700' }}
          >
            Horario de la tarea
          </p>
          <QuestionsFlex
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <TextField
              variant="outlined"
              id="hour"
              name="hour"
              autoComplete="off"
              required
              inputProps={{
                style: {
                  width: '50px',
                  fontSize: '30px !important',
                },
              }}
              value={form.hour}
              onChange={handleChange}
            />
            <div style={{ fontSize: '50px' }}>:</div>
            <TextField
              variant="outlined"
              id="minutes"
              name="minutes"
              autoComplete="off"
              required
              inputProps={{
                style: {
                  width: '50px',
                  fontSize: '30px !important',
                },
              }}
              value={form.minutes}
              onChange={handleChange}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
                marginLeft: '2px',
              }}
            >
              <p
                style={{
                  cursor: 'pointer',
                  fontSize: '25px',
                  margin: '0',
                  color: horario === 'PM' ? 'black' : '#A5A5A5',
                }}
                onClick={() => setHorario('PM')}
              >
                PM
              </p>
              <p
                style={{
                  cursor: 'pointer',
                  fontSize: '25px',
                  color: horario === 'AM' ? 'black' : '#A5A5A5',
                }}
                onClick={() => setHorario('AM')}
              >
                AM
              </p>
            </div>
          </QuestionsFlex>
        </TrainingQuestion>
        <TrainingSectionGray
          style={{
            display: showDaysSelected && !showQuestionReward ? 'block' : 'none',
          }}
          wiw="670px"
          mw="auto"
          back="#FAFAFA"
          mtop="0"
        >
          <Container
            mleft="18%"
            width="60%"
            mt="0rem"
            widthMobile="90%"
            mtMobile="0rem"
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn
                onClick={(e) => saveHour(e)}
                min_web="100%"
                mtopw="0px"
                minmd="100%"
              >
                Continuar
              </CardsBtn>
            </div>
          </Container>
        </TrainingSectionGray>
        <div id="activeResp5SonHom"></div>
        <CardTwo
          classCard={showQuestionReward ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={horario}
          colorTraining={trainingColor}
        />
        <QuestionsOrder
          classCard={showQuestionReward ? 'cardOne' : 'hidden'}
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion
          className={showQuestionReward && reward === '' ? '' : 'hidden'}
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeResp6SonHom"></div>
        <CardTwo
          classCard={reward !== '' ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={reward}
          colorTraining={trainingColor}
        />
        {reward === 'Otro' && !flagOpenFieldReward ? (
          <Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                marginTop: '35px',
              }}
            >
              <p style={{ fontSize: '18px' }}>Escribe otra recompensa</p>
            </div>
            <TrainingQuestion>
              <QuestionsFlex display="inline">
                <TextField
                  variant="outlined"
                  id="reward"
                  name="reward"
                  autoComplete="off"
                  placeholder="Ej. $15.00"
                  required
                  fullWidth
                  value={form.reward}
                  onChange={handleChange}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
              <Container
                width="100%"
                mt="0rem"
                widthMobile="90%"
                mtMobile="0rem"
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <br />
                  <CardsBtn
                    onClick={(e) => handleSaveReward(e)}
                    style={{
                      borderRadius: '4px',
                      letterSpacing: '-0.3px',
                      lineHeight: '140%',
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                    }}
                    min_w="95%"
                    min_web="350px"
                    // min_web="100%"
                    mtopw="20px"
                    mleft="10px"
                    mtop="15px"
                    weight="normal!important"
                  >
                    Continuar
                  </CardsBtn>
                  <br />
                </div>
              </Container>
            </TrainingSectionGray>
          </Fragment>
        ) : null}
      </div>
  );
};

export default SonHomework;
