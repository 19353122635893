/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  QuestionsDivs,
  QuestionsText,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import Addcircle from '../../assets/img/anacoach/add_circle.png';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import {
  Image,
} from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';

const SelectGoals = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [seleccionaMeta, setSeleccionaMeta] = useState(
    sessionStorage.getItem('SeleccionaMeta') || '',
  );
  const [goalsMultiple, setGoalsMultiple] = useState([]);
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form] = useState({});

  const [isAddGoalActive, setIsAddGoalActive] = useState(false);
  const { actions } = ClickButton();
  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
    switch (step) {
      case 0:
        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log('MoneySaved', json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const seleccionMeta = sessionStorage.getItem('SeleccionaMeta') || '';
      setSeleccionaMeta(seleccionMeta);
      if (seleccionMeta !== '') {
        if (seleccionMeta.replace(/\|/g, ',').split(',').length > 0) {
          setGoalsMultiple(seleccionMeta.replace(/\|/g, ',').split(','));
        }
      }

      if (seleccionMeta !== null && seleccionMeta !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const handleChangeStatus = (numQuestion, name, value, type = '', e) => {
    console.log('handleChangeStatus', numQuestion, name, value);
    const element = document.querySelector(`div[name="${name}"]`);
    if (element.classList.contains('border-pink-active')) {
      console.log('remover clase catch');
      element.classList.remove('border-pink-active');
    } else {
      element.classList.add('border-pink-active');
    }

    if (numQuestion === undefined) return;
    const elementExist = goalsMultiple.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      const quetionsTemp = [];
      for (const element of goalsMultiple) {
        if (element.id !== elementExist[0].id) {
          console.log(element.id, elementExist[0].id);
          quetionsTemp.push(element);
        }
      }
      setGoalsMultiple(quetionsTemp);
    } else {
      setGoalsMultiple([
        ...goalsMultiple,
        {
          id: name,
          question: numQuestion,
          value: name,
        },
      ]);
    }
  };

  const handleChangeGoals = () => {
    const click = `ContinuarMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const storeTemp = goalsMultiple.map((item) => item.id);
    const resp = [
      {
        id: 'seleccionaMeta',
        question: 1,
        value: storeTemp.join(','),
      },
    ];
    setSeleccionaMeta(storeTemp.join(','));
    createJsonInput(Rutine, resp);
    setQuestions([]);
    setOrderRutine(nextOrderRoutine);
  };
  const [newArrayGoals, setNewArrayGoals] = useState([]);
  const [newGoal, setNewGoal] = useState('');
  const [inputNewGoal, setInputNewGoal] = useState(false);
  const handleAddGoals = (e) => {
    const click = `AgregarNuevaMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setInputNewGoal(true);
    setIsAddGoalActive(true);
  };
  const handleSaveAddGoals = (e) => {
    const click = `GuardarMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const goalNew = newGoal;
    console.log('nuevameta>> ', goalNew);
    if (goalNew !== '' && newArrayGoals.length <= 3) {
      console.log(goalNew);
      setNewArrayGoals([...newArrayGoals, goalNew]);
      setNewGoal('');
      setInputNewGoal(false);
      setGoalsMultiple([
        ...goalsMultiple,
        {
          id: goalNew,
          question: 1,
          value: goalNew,
        },
      ]);

      setIsAddGoalActive(false);

      setTimeout(() => {
        const element = document.querySelector(`div[name="${goalNew}"]`);
        console.log('nuevameta>> ', element);
        if (element) {
          element.classList.add('border-pink-active');
        }
      }, 500);
    }
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && seleccionaMeta === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeGoalsAdd' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <>
      {/* primer pregunta */}
      <div id="activeGoalsAdd"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="¿Qué quieres lograr?"
        hora={hora}
      />
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion className={seleccionaMeta === '' ? '' : 'hidden'}>
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeStatus}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
        {newArrayGoals.map((item, i) => (
          <QuestionsDivs
            widthxs="100%"
            marginLmd="0%"
            widthweb="60%"
            marginleftLg="18%"
            onClick={() => {
              handleChangeStatus(1, item, i, 'Multiple');
            }}
            name={item}
            margin="8px auto auto 18%"
            hei="auto"
            bs="0px 2px 8px rgba(0,0,0,0.08)"
          >
            <QuestionsText size="14px" weight="400">
              {item}
              <pattern></pattern>
            </QuestionsText>
          </QuestionsDivs>
        ))}
      </TrainingQuestion>
      <TrainingQuestion className={seleccionaMeta === '' ? '' : 'hidden'}>
        <CardsBtn1
          type="button"
          color="#EC407A !important"
          back="#fff !important"
          border="solid 1px !important"
          windthmd="100%"
          onClick={(e) => {
            handleAddGoals(e);
          }}
        >
          <Image
            src={Addcircle}
            width="30px"
            marginRight="15px"
            alt="logo-coru"
          />
          Agregar una nueva meta
        </CardsBtn1>
      </TrainingQuestion>
      <TrainingQuestion
        className={seleccionaMeta === '' && inputNewGoal ? '' : 'hidden'}
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="62%"
          marginleftLg="18%"
          hei="auto"
          bc="#fafafa"
        >
          <TrainingSectionGray
            padding="5%"
            paddinglG="5%"
            wiw="100%"
            mw="auto"
            mtop="0"
          >
            <TextField
              variant="outlined"
              label="Nueva meta"
              autoComplete="off"
              required
              fullWidth
              className={
                inputNewGoal ? 'textInput inputs-bottom input-coru' : 'hidden'
              }
              value={newGoal}
              onBlur={(e) => setNewGoal(e.target.value)}
              onChange={(e) => setNewGoal(e.target.value)}
            />
            <CardsBtn
              mtopw="0px"
              mtopmd="0px"
              min_w="100%"
              min_web="100%"
              onClick={(e) => {
                handleSaveAddGoals(e);
              }}
            >
              Guardar
            </CardsBtn>
          </TrainingSectionGray>
        </QuestionsDivs>
      </TrainingQuestion>
      <TrainingQuestion
        className={
          goalsMultiple.length > 0 && seleccionaMeta === '' && !isAddGoalActive
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <CardsBtn1
            type="button"
            onClick={(e) => {
              handleChangeGoals(e);
            }}
          >
            Continuar
          </CardsBtn1>
        </QuestionsDivs>
      </TrainingQuestion>

      <CardTwo
        classCard={seleccionaMeta !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        textT={''}
        text2={seleccionaMeta}
        colorTraining={trainingColor}
      ></CardTwo>
    </>
  );
};

export default SelectGoals;
