import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import CardOne from '../../components/Home/Card1Animate';
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import {
  CardsBtn,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import SectionShare from '../../components/common/ShareSection';
import GeneralTable from '../../components/common/GeneralTable';
import ModalAmazon from '../../components/common/ModalAmazon';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Container } from '../../assets/styles/Home.styled';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import ModalVirality from '../../components/common/ModalVirality';
const RpWoAntExpenses = ({ setOrderRutine, dataTable }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [showContinue, setShowContinue] = useState(false);
  const heads = ['Categoría', 'Gasto mensual', 'Gasto anual'];
  const anchop = window.screen.width;

  useEffect(() => {
    setTimeout(() => {
      if (showContinue) {
        gsap.to(window, { duration: 1, scrollTo: '#activeRPServices' });
      }
    }, 1000);
  }, [showContinue]);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${
              getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  return (
    <Fragment>
      
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        textbold="Truco:"
        text="Agrega este gasto a tu presupuesto, ya sea como un extra o dentro de los gastos fijos, pero agrégalo. Así tendrás claridad de este gasto."
        text2="Tip extra: Ojo con los cargos duplicados en tus estados de cuenta."
      >
        {showContinue ? null : (
          <CardsBtn
            min_w="100%"
            windthLg="100%"
            max_w="250"
            onClick={() => setShowContinue(true)}
          >
            <b>Entendido</b>
          </CardsBtn>
        )}
      </CardOne>
      <div id="activeRPServices"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={showContinue ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Continuemos"
      ></CardOne>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={showContinue ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Estos son los servicios registrados."
      ></CardOne>
      {showContinue && dataTable.length > 0 ? (
        <TrainingDivSection
          backw="#f8f9fa00"
          padding="0px"
          paddw="0px"
          mb="0"
          wi="90%"
          wiw="80%"
          he="auto"
          hew="auto"
          bsha="null"
        >
          <TrainingDivSection
            mlw="10%"
            ml="0px"
            mr="0px"
            bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
            mt="10px"
            br="4px"
            padding="0"
            paddw="0"
            wi="100%"
            wiw="75%"
            he="auto"
            hew="auto"
            mb="0px"
            mtw="20px"
          >
            <GeneralTable
              color={'#DE9E83'}
              heads={heads}
              data={dataTable}
            ></GeneralTable>
          </TrainingDivSection>
        </TrainingDivSection>
      ) : null}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={showContinue ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="¿Gastas en algo más?"
      >
        <CardsBtn
          min_w="100%"
          border="1px solid #F64282"
          back="#fff"
          color="#F64282"
          windthLg="100%"
          max_w="250"
          onClick={() => setOrderRutine(4)}
        >
          <b>Registrar más gastos</b>
        </CardsBtn>
      </CardOne>
      {showContinue && (
        <>
          {' '}
          <ModalAmazon show={true} wo={'GastosHormiga'}></ModalAmazon>
          {recomendaciones ? (
            <div className="marginComentLgSuccess1">
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
                <ModalVirality />
              </Container>
            </div>
          ) : null}
          <SectionShare WO="" />
        </>
      )}
      
    </Fragment>
  );
};

export default RpWoAntExpenses;
