/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, Fragment, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Training from './Exportcomponents/TrainingRoutesComponents';
import { GET_CAT_ROUTES_Q } from '../queries';

const CoachTrainingRoutes = () => {
  const [result, setResult] = useState([]);
  const [getCatRoute] = useLazyQuery(GET_CAT_ROUTES_Q, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCatRoutes }) => {
      setResult(getCatRoutes);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    const input = { routeType: 'Training' };
    getCatRoute({ variables: input });
  }, [getCatRoute]);

  const Element = ({ component }) => {
    // console.log('component name =>>>>>', component);
    const Component = Training[component];
    // console.log('Component =>>>>>', Component);
    return <Component />;
  };

  return (
    <Fragment>
      <Switch>
        {result?.map((route) => (
          <Route
            exact
            path={route.path}
            key={route.id}
            render={() => <Element component={route.component} />}
          />
        ))}
      </Switch>
    </Fragment>
  );
};

export default CoachTrainingRoutes;