import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  Image,
  ParagrapTwo,
} from './common.styled';
import {
  ContentHomeSection, FInalcialProfile,
  RectangleAna,
  CoachAna1,
  ElipseCoach,
  LogocoruCoach,
} from './Home.styled';
import logocoruCoach from '../../assets/img/home/logoCoach.png';
import fondo_modalNumber from '../../assets/img/anacoach/fondo_modalNumber.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '55%',
    '@media (max-width: 768px)': {
      height: '38%',
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalNumberPhone = ({
  setOpen,
  open,
  cellPhone,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  useEffect(() => {

  }, []);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  return (
        <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>
                <ParagrapTwo width="70%" widthM="95%" fontWeight="700" cursor="pointer" texttalign="right" textalignMobile="right" className='mx-auto' onClick={handleClose} mt="" mtWeb="0px">X</ParagrapTwo>
                <Image src={fondo_modalNumber} height="280px" marginLeftM="0px" heightMobile="260px" mwidth="500px" width="500px" mtMob="0px" margin="auto" top="110px" left="0%" mleft="0%" marginLeft=" " mtop="-30px" marginTop="-10px" />
                <ContentHomeSection topD="0px" width="100%" leftD=" " rigthD="0%" floatD=" ">
                    <FInalcialProfile width="70px" top="-250px" topD="-265px" topD2="-265px">
                        <RectangleAna width="70px" height="85px">
                            <CoachAna1 width="60px" height="77px" />
                        </RectangleAna>
                        <ElipseCoach marginTop="-6%" marginLeft="10%" marginLeftMob="10%">
                            <LogocoruCoach src={logocoruCoach} />
                        </ElipseCoach>
                    </FInalcialProfile>
                </ContentHomeSection>
                <div style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#F4F4F4', width: '220px', marginLeft: '30%', marginTop: '-10px', padding: '5px',
                }}>
                    <div>
                        El video se ha enviado correctamente a tu teléfono registrado en Coru.<br />
                        {cellPhone}
                    </div>
                </div>
            </div>
        </Modal >
  );
};

export default ModalNumberPhone;
