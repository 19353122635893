import { getItemSessionStorage } from './general';

const jsonGeneral = {
  PrimerNombre: sessionStorage.getItem('FirstName'),
  SegundoNombre: sessionStorage.getItem('MiddleName'),
  ApellidoPaterno: sessionStorage.getItem('FathersLastName'),
  ApellidoMaterno: sessionStorage.getItem('MothersLastName'),
  Correo: sessionStorage.getItem('Email1'),
  TelefonoCelular: sessionStorage.getItem('CellPhone'),
  TelefonoCasa: sessionStorage.getItem('HomePhone'),
  FechaNacimiento: sessionStorage.getItem('Birthdate'),
  CodigoPostal: sessionStorage.getItem('ZipCode'),
  Pais: sessionStorage.getItem('Pais') || 'México',
  EstadoNac: sessionStorage.getItem('BirthState'),
  Nacionalidad: sessionStorage.getItem('nacionalidad') || 'Mexicana',
  Genero: sessionStorage.getItem('Gender'),
  GradoEstudios: sessionStorage.getItem('Ulltimo_grado_de_estudios'),
  RFC: sessionStorage.getItem('RFC'),
  Calle: sessionStorage.getItem('Street'),
  NoExt: sessionStorage.getItem('ExtNo'),
  NoInt: sessionStorage.getItem('IntNo') || '',
  Colonia: sessionStorage.getItem('Suburb'),
  DelegacionMunicipio: sessionStorage.getItem('Municipality'),
  tipovivienda: sessionStorage.getItem('Tipo_de_Vivienda'),
  Ciudad: sessionStorage.getItem('Municipality'),
  Estado: sessionStorage.getItem('State'),
  CPostal2: sessionStorage.getItem('ZipCode'),
  TResidAnios: sessionStorage.getItem('Tiempo_de_vivir_en_domicilio'),
  EntreCalles: sessionStorage.getItem('Street'),
  NombreEmpresa: sessionStorage.getItem('Nombre_de_la_empresa'),
  TipoIndustria: sessionStorage.getItem('Tipo_de_Industria'),
  AntiguedadAnios: sessionStorage.getItem(
    'Tiempo_de_laborar_en_la_empresa_en_años',
  ),
  PuestoOcupacion: sessionStorage.getItem('Ocupacion'),
  IngresoMensual: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
  Tarjeta: sessionStorage.getItem('activeCard') || 'No',
  '4 dígitos': sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta'),
  'Crédito hipotecario': sessionStorage.getItem('Hipoteca_Vigente') || 'No',
  'Crédito automotriz':
      sessionStorage.getItem('Credito_automotriz_bancario_en_los_ultimos_do')
      || 'No', // listo
  TResidMeses: '0',
  AntiguedadMeses: '0',
  Empleo: ' ',
};

export const getVariablesScrapper = ({ encodeString = '', bank = '' }) => ({
  dataJson: encodeString,
  bank,
  idOportunity: '',
  idUser: getItemSessionStorage('idUser'),
  pixelID: '525808197839029',
  ipAddressUser: '',
  url: window.location.href,
  userAgent: window.navigator.userAgent,
  em: getItemSessionStorage('Email1'),
  ph: getItemSessionStorage('CellPhone'),
  eventName: 'Search',
});

const ingreso_mensual_scrappers = (ingresoMensual) => {
  const ingreso = '';
  switch (ingresoMensual) {
    case 'menos_4000':
      return '3999';
    case 'de_4000_a_7000':
      return '6999';
    case 'de_7000_a_15000':
      return '14900';
    case 'de_15000_a_30000':
      return '25000';
    case 'mas_de_30000':
      return '40000';
    default:
      return '4000';
  }
  return ingreso;
};

export const getJsonAmex = (card) => ({
  TarjetaSeleccionada: card,
  ...jsonGeneral,
});

export const getJsonCoppel = (card) => ({
  TarjetaSeleccionada: card,
  idUser:sessionStorage.getItem('idUser'),
  PrimerNombre:sessionStorage.getItem('FirstName'),
  SegundoNombre:sessionStorage.getItem('MiddleName'),
  ApellidoPaterno:sessionStorage.getItem('FathersLastName'),
  ApellidoMaterno:sessionStorage.getItem('MothersLastName'),
  Correo: sessionStorage.getItem('Email1'),
  TelefonoCelular: sessionStorage.getItem('CellPhone'),
  FechaNacimiento: sessionStorage.getItem('Birthdate'),
  tarjetaCredito: sessionStorage.getItem('activeCard') || 'No',
  digitos: sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta'),
  Banco: sessionStorage.getItem('Banco_tarjeta_actual_del_usuario'),
  hipoteca: sessionStorage.getItem('Hipoteca_Vigente') || 'No',
  credautomotriz:sessionStorage.getItem('Credito_automotriz_bancario_en_los_ultimos_do')|| 'No',
  Estado:sessionStorage.getItem("estadoCoppel"),
  municipio:sessionStorage.getItem("delegacionCoppel"),
  estadoCivil: sessionStorage.getItem('MaritalStatus'),
  TelefonoCasa: sessionStorage.getItem('HomePhone'),
  Genero: sessionStorage.getItem('Gender'),
  colonia:sessionStorage.getItem("coloniaCoppel"),
  calle:sessionStorage.getItem("calleCoppel"),
  cp:sessionStorage.getItem("codigopostalCoppel"),
  nExt:sessionStorage.getItem("numeroexteriorCoppel"),
  nInt:sessionStorage.getItem("numerointeriorCoppel"),
  EntreCalle1:sessionStorage.getItem("calle1Coppel"),
  EntreCalle2:sessionStorage.getItem("calle2Coppel"),
  Cercanias:sessionStorage.getItem("referenciaCoppel"),
  GradoEstudios: sessionStorage.getItem('Ulltimo_grado_de_estudios'),
  tipovivienda: sessionStorage.getItem('Tipo_de_Vivienda'),
  AntiguedadAnios: sessionStorage.getItem('Tiempo_de_laborar_en_la_empresa_en_años')||'1',
  TResidAnios: sessionStorage.getItem('Tiempo_de_vivir_en_domicilio'),
  dependientes: sessionStorage.getItem('Numero_de_dependientes'),
  PuestoOcupacion: sessionStorage.getItem('Ocupacion'),
  IngresoMensual: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
  nombreRefUno: sessionStorage.getItem('Nombre_Completo_Referencia_Familiar'),
  celRefUno: sessionStorage.getItem('Telefono_de_Referencia_Familiar'),
  NombreEmpresa: sessionStorage.getItem('Nombre_de_la_empresa'),
  TipoIndustria: sessionStorage.getItem('Tipo_de_Industria'),
});

export const getJsonVexi = () => ({
  TarjetaSeleccionada: 'VEXI',
  ...jsonGeneral,
  curp: sessionStorage.getItem('CURP'),
  ecivil: sessionStorage.getItem('MaritalStatus'),
});

export const getJsonBBVA = (card) => {
  const ingresoMensual = ingreso_mensual_scrappers(
    sessionStorage.getItem('Ingreso_Mensual_Fijo'),
  );
  return {
    TarjetaSeleccionada: card,
    PrimerNombre:
      `${sessionStorage.getItem('FirstName')
      }_${
        sessionStorage.getItem('MiddleName')}`,
    ApellidoPaterno:
      `${sessionStorage.getItem('FathersLastName')
      }_${
        sessionStorage.getItem('MothersLastName')}`,
    RFC: sessionStorage.getItem('RFC'),
    Correo: sessionStorage.getItem('Email1'),
    TelefonoCelular: sessionStorage.getItem('CellPhone'),
    FechaNacimiento: sessionStorage.getItem('Birthdate'),
    CodigoPostal: sessionStorage.getItem('ZipCode'),
    Pais: sessionStorage.getItem('Pais') || 'México',
    EstadoNac: sessionStorage.getItem('BirthState'),
    Nacionalidad: sessionStorage.getItem('nacionalidad') || 'Mexicana',
    Calle: sessionStorage.getItem('Street'),
    Genero: sessionStorage.getItem('Gender'),
    NoExt: sessionStorage.getItem('ExtNo'),
    NoInt: sessionStorage.getItem('IntNo') || '',
    Colonia: sessionStorage.getItem('Suburb'),
    DelegacionMunicipio: sessionStorage.getItem('Municipality'),
    TResidAnios: sessionStorage.getItem('Tiempo_de_vivir_en_domicilio'),
    NombreEmpresa: sessionStorage.getItem('Nombre_de_la_empresa'),
    AntiguedadAnios: sessionStorage.getItem(
      'Tiempo_de_laborar_en_la_empresa_en_años',
    ),
    PuestoOcupacion: sessionStorage.getItem('Ocupacion'),
    IngresoMensual: ingresoMensual,
    telTrabajoE: sessionStorage.getItem('CellPhone'),

    tarjetaCredito: sessionStorage.getItem('activeCard') || 'No',
    digitos: sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta'),
    bancoActual: sessionStorage.getItem('Banco_tarjeta_actual_del_usuario'),
    hipoteca: sessionStorage.getItem('Hipoteca_Vigente') || 'No',
    credautomotriz:
      sessionStorage.getItem('Credito_automotriz_bancario_en_los_ultimos_do')
      || 'No', // listo,
    NumeroIdent: sessionStorage.getItem('Numero_INE') || '1234567890123',
    estadoCivil: sessionStorage.getItem('MaritalStatus'),
  };
};

export const getJsonBanamex = (card) => ({
  TarjetaSeleccionada: card,
  PrimerNombre:
      `${sessionStorage.getItem('FirstName')
      }_${
        sessionStorage.getItem('MiddleName')}`,
  ApellidoPaterno:
      `${sessionStorage.getItem('FathersLastName')
      }_${
        sessionStorage.getItem('MothersLastName')}`,
  FechaNacimiento: sessionStorage.getItem('Birthdate'),
  RFC: sessionStorage.getItem('RFC'),
  Correo: sessionStorage.getItem('Email1'),
  TelefonoCelular: sessionStorage.getItem('CellPhone'),
  Genero: sessionStorage.getItem('Gender'),
  EstadoNac: sessionStorage.getItem('BirthState'),
  ingMin: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
  Calle: sessionStorage.getItem('Street'),
  NoExt: sessionStorage.getItem('ExtNo'),
  NoInt: sessionStorage.getItem('IntNo') || '',
  CodigoPostal: sessionStorage.getItem('ZipCode'),
  Colonia: sessionStorage.getItem('Suburb'),
  tipovivienda: sessionStorage.getItem('Tipo_de_Vivienda'),
  TResidAnios: sessionStorage.getItem('Tiempo_de_vivir_en_domicilio'),
  TelefonoCasa: sessionStorage.getItem('CellPhone'),
  tarjetaCredito: sessionStorage.getItem('activeCard') || 'No',
  bancoActual: sessionStorage.getItem('Banco_tarjeta_actual_del_usuario'),
  digitos: sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta'),
  hipoteca: sessionStorage.getItem('Hipoteca_Vigente'),
  credautomotriz: sessionStorage.getItem(
    'Credito_automotriz_bancario_en_los_ultimos_do',
  ),
  dependientes: sessionStorage.getItem('Numero_de_dependientes'),
  PuestoOcupacion: sessionStorage.getItem('Ocupacion'),
  estadoCivil: sessionStorage.getItem('MaritalStatus'),
  Nacionalidad: sessionStorage.getItem('nacionalidad') || 'Mexicana',
  NombreEmpresa: sessionStorage.getItem('Nombre_de_la_empresa'),
  TipoIndustria: sessionStorage.getItem('Tipo_de_Industria'),
  tiempolab: sessionStorage.getItem(
    'Tiempo_de_laborar_en_la_empresa_en_años',
  ),
  GradoEstudios: sessionStorage.getItem('Ulltimo_grado_de_estudios'),
  calleLaboral: sessionStorage.getItem('Street'),
  numLaboralInt: sessionStorage.getItem('IntNo') || '',
  numLaboralExt: sessionStorage.getItem('ExtNo'),
  Curp: sessionStorage.getItem('Curp'),
  codpostalLaboral: sessionStorage.getItem('ZipCode'),
  coloniaLaboral: sessionStorage.getItem('Suburb'),
  nombreRefUno: sessionStorage.getItem('Nombre_Completo_Referencia_Familiar'),
  celRefUno: sessionStorage.getItem('Telefono_de_Referencia_Familiar'),
  nombreRefDos: sessionStorage.getItem(
    'Nombre_Completo_de_una_Referencia_no_familiar',
  ),
  celRefDos: sessionStorage.getItem('Telefono_de_Referencia_no_Familiar'),
  Estado: sessionStorage.getItem('BirthState'),
  AntiguedadAnios: sessionStorage.getItem(
    'Tiempo_de_laborar_en_la_empresa_en_años',
  ),
  IngresoMensual: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
});

export const responseAmex = {
  solicitada: {
    status: 'SOLICITADA',
    detail: 'Solicitud ingresada exitosamente.',
    sigue:
      'El equipo de AMEX se pondrá en contacto contigo para continuar con la solicitud. Conserva tu número de folio. [xfolio]',
  },
  proceso: {
    status: 'EN PROCESO',
    detail: 'Seguimos trabajando en tu solicitud.',
    sigue:
      'Solicitud en proceso. Ya realizamos esta solicitud con el proveedor, espera información en tu correo electrónico.',
  },
};

export const responseCitibanamex = {
  aprobada: {
    status: 'APROBADA',
    detail: '¡Felicidades! Tu solicitud fue aprobada.',
    sigue:
      'Acude a tu sucursal con tu número de folio y los documentos que te indicaremos por correo electrónico.  [xfolio]',
  },
  proceso: {
    status: 'EN PROCESO',
    detail: 'Seguimos trabajando en tu solicitud.',
    sigue:
      'Tu solicitud no pudo ser completada en línea. Te enviaremos el resultado de tu solicitud a tu correo electrónico registrado.',
  },
  rechazada: {
    status: 'RECHAZADA',
    detail: '¡Lo sentimos! Tu solicitud no fue aceptada.',
    sigue:
      'Te invitamos a que conozcas las otras opciones para obtener crédito y cumplir tus metas que tenemos para ti.',
  },
};

export const responseBBVA = {
  aprobada: {
    status: 'APROBADA',
    detail: '¡Felicidades! Tu solicitud fue aprobada.',
    sigue:
      'Acude a tu sucursal con tu número de folio y los documentos que te indicaremos por correo electrónico. [xfolio]',
  },
  proceso: {
    status: 'EN PROCESO',
    detail: 'Seguimos trabajando en tu solicitud.',
    sigue:
      'Tu solicitud no pudo ser completada en línea. Te enviaremos el resultado de tu solicitud a tu correo electrónico registrado.',
  },
  rechazada: {
    status: 'RECHAZADA',
    detail: '¡Lo sentimos! Tu solicitud no fue aceptada.',
    sigue:
      'Te invitamos a que conozcas las otras opciones para obtener crédito y cumplir tus metas que tenemos para ti.',
  },
};

export const responseBank = (product, responseScrapper, imgFace, numcard) => {
  numcard = parseInt(numcard) + 1;
  console.log('responseBank ', product.BusinessAbr);
  console.log('responseScrapper: ', responseScrapper);
  switch (product.BusinessAbr) {
    case 'CITIBANAMEX':
      if (String(responseScrapper.status).indexOf('SUCCES') !== -1) {
        sessionStorage.setItem(`status-${numcard}`, 'APROBADA');
        product.imgresult = imgFace[1];
        product.resultado = responseCitibanamex.aprobada.status;
        product.msgresultado = responseCitibanamex.aprobada.detail;
        const queSigue = responseCitibanamex.aprobada.sigue.replace(
          'xfolio',
          responseScrapper.folio,
        );
        product.txtadicional = queSigue;
      } else if (String(responseScrapper.status).indexOf('RECHAZADA') !== -1) {
        sessionStorage.setItem(`status-${numcard}`, 'RECHAZADA');
        product.imgresult = imgFace[0];
        product.resultado = responseCitibanamex.rechazada.status;
        product.msgresultado = responseCitibanamex.rechazada.detail;
        const queSigue = responseCitibanamex.rechazada.sigue;
        product.txtadicional = queSigue;
      } else {
        product.imgresult = imgFace[2];
        sessionStorage.setItem(`status-${numcard}`, 'EN PROCESO');
        product.resultado = responseCitibanamex.proceso.status;
        product.msgresultado = responseCitibanamex.proceso.detail;
        const queSigue = responseCitibanamex.proceso.sigue;
        product.txtadicional = queSigue;
      }
      if (sessionStorage.getItem('runCiti') === null) {
        if (sessionStorage.getItem('numCards') !== null) {
          sessionStorage.setItem(
            'numCards',
            parseInt(sessionStorage.getItem('numCards')) + 1,
          );
        } else {
          sessionStorage.setItem('numCards', 1);
        }
        sessionStorage.setItem('runCiti', 'true');
      }

      break;
    case 'BBVA':
      if (String(responseScrapper.status).indexOf('success') !== -1) {
        sessionStorage.setItem(`status-${numcard}`, 'APROBADA');
        product.imgresult = imgFace[1];
        product.resultado = responseBBVA.aprobada.status;
        product.msgresultado = responseBBVA.aprobada.detail;
        const queSigue = responseBBVA.aprobada.sigue.replace(
          'xfolio',
          responseScrapper.folio,
        );
        product.txtadicional = queSigue;
      } else if (
        String(responseScrapper.response || '').indexOf(
          'Fuera de rango de edad',
        ) !== -1
        || (responseScrapper.response || '').indexOf(
          'resultados no satisfactorios',
        ) !== -1
      ) {
        sessionStorage.setItem(`status-${numcard}`, 'RECHAZADA');
        product.imgresult = imgFace[0];
        product.resultado = responseBBVA.rechazada.status;
        product.msgresultado = responseBBVA.rechazada.detail;
        const queSigue = responseBBVA.rechazada.sigue;
        product.txtadicional = queSigue;
      } else {
        sessionStorage.setItem(`status-${numcard}`, 'EN PROCESO');
        product.imgresult = imgFace[2];
        product.resultado = responseBBVA.proceso.status;
        product.msgresultado = responseBBVA.proceso.detail;
        const queSigue = responseBBVA.proceso.sigue;
        product.txtadicional = queSigue;
      }
      if (sessionStorage.getItem('runBBVA') === null) {
        if (sessionStorage.getItem('numCards') !== null) {
          sessionStorage.setItem(
            'numCards',
            parseInt(sessionStorage.getItem('numCards')) + 1,
          );
        } else {
          sessionStorage.setItem('numCards', 1);
        }
        sessionStorage.setItem('runBBVA', 'true');
      }

      break;
    case 'FALABELLA':
      console.log('falabella response');
      sessionStorage.setItem(`status-${numcard}`, 'EN PROCESO');
      if (sessionStorage.getItem('runFALABELLA') === null) {
        if (sessionStorage.getItem('numCards') !== null) {
          sessionStorage.setItem(
            'numCards',
            parseInt(sessionStorage.getItem('numCards')) + 1,
          );
        } else {
          sessionStorage.setItem('numCards', 1);
        }
        sessionStorage.setItem('runFALABELLA', 'true');
      }
      break;
    case 'AMEX':
      if (String(responseScrapper.status).indexOf('solicitada') !== -1) {
        sessionStorage.setItem(`status-${numcard}`, 'SOLICITADA');
        product.imgresult = imgFace[1];
        product.resultado = responseAmex.solicitada.status;
        product.msgresultado = responseAmex.solicitada.detail;
        const queSigue = responseAmex.solicitada.sigue.replace(
          'xfolio',
          responseScrapper.folio,
        );
        product.txtadicional = queSigue;
      } else {
        sessionStorage.setItem(`status-${numcard}`, 'EN PROCESO');
        product.imgresult = imgFace[2];
        product.resultado = responseAmex.proceso.status;
        product.msgresultado = responseAmex.proceso.detail;
        const queSigue = responseAmex.proceso.sigue;
        product.txtadicional = queSigue;
      }
      if (sessionStorage.getItem('runAMEX') === null) {
        if (sessionStorage.getItem('numCards') !== null) {
          sessionStorage.setItem(
            'numCards',
            parseInt(sessionStorage.getItem('numCards')) + 1,
          );
        } else {
          sessionStorage.setItem('numCards', 1);
        }
        sessionStorage.setItem('runAMEX', 'true');
      }
      break;
    default:
      console.log('default case bancos scrapper: ', product.BusinessAbr);
  }

  return product;
};

export const getCreditCardProccess = (setHiddenLoader, lenProduct) => {
  let count = 0;
  if (sessionStorage.getItem('numCards') !== null) {
    count = parseInt(sessionStorage.getItem('numCards'));
  }
  console.log('count ', count, ' lenProduct', lenProduct);

  if (count >= lenProduct) {
    setHiddenLoader(false);
  }
  return count;
};

export const getIpAddress = (ipify) => {
  try {
    let ip = '';
    ipify
      .ipv4()
      .then((ipv4) => {
        console.log(ipv4);
        ip = ipv4;
      })
      .catch((err) => console.log(err));
    return ip;
  } catch (e) {
    console.log(e);
  }
};
