import React, { Fragment, useState } from 'react';
import {
  TrainingMain,
  DivFlex,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
  BorderTask,
  StatusTask,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import { Feed_Main } from '../../assets/styles/Feed.styled';
import { Container } from '../../assets/styles/Home.styled';
import { Rectangle23, Rectangle25, Triangle } from '../common/Home.styled';
import TasksStatus from './WeekTask';

import CardOne from '../Home/Card1Animate';

function SavingAndHomeworks({ data, name, currentSaving }) {
  const [showTips, setShowTips] = useState(false);
  return (
    <Fragment>
      <TrainingMain
        style={{ marginBottom: '20px' }}
        mt="50px"
        mtw="0"
        className=""
        id="top"
      >
        <br />
        <TrainingSectionGray back="#fafafa">
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <TrainingDivSection2
                paddiw="0% 10% 3% 10%"
                paddi="0% 5% 5% 5%"
                back="#fafafa"
              >
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text={`${
                    name || 'Gael'
                  }, este es tu avance con tareas y recompensas:`}
                />
                <br />
                <br />
                <TasksStatus datos={data}></TasksStatus>
              </TrainingDivSection2>
              <TrainingDivSection2
                paddiw="0% 10% 3% 10%"
                paddi="0% 5% 5% 5%"
                back="#fafafa"
              >
                <DivFlex
                  widthweb="70%"
                  marweb="auto"
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '1px 0px 29px -22px rgba(0,0,0,0.75)',
                    borderRadius: '4px',
                  }}
                >
                  <BorderTask bs="none">
                    <TrainingText
                      size="18px"
                      weight="600"
                      className="roboto"
                      align="left"
                      alignweb="left"
                      ls="-0.2px"
                    >
                      Ahorraste
                    </TrainingText>
                  </BorderTask>
                  <StatusTask fw="800" Color="#FA9D59" bColor="#FA9D59">
                    ${currentSaving}
                  </StatusTask>
                </DivFlex>
              </TrainingDivSection2>
            </Feed_Main>
          </Feed_Main>
        </TrainingSectionGray>
      </TrainingMain>

      <DivFlex widthweb="70%" marweb="auto">
        <div>
          <TrainingText
            className="roboto"
            size="16px"
            align="center"
            weight=" "
            style={{ cursor: 'pointer' }}
            onClick={() => setShowTips(!showTips)}
          >
            Te recomendamos los siguientes tips para ahorrar:
          </TrainingText>
          {showTips && (
            <TrainingDivSection2
              paddiw="0% 10% 3% 10%"
              paddi="0% 5% 5% 5%"
              back="#fafafa"
            >
              <ul style={{ listStyleType: 'decimal' }}>
                <li>
                  <TrainingText
                    className="roboto"
                    size="11px"
                    sizew="12px"
                    align="justify"
                    weight=" "
                  >
                    Habla con tus padres sobre la diferencia entre deseos y
                    necesidades. Esto te ayudará a tomar mejores decisiones
                    sobre qué hacer con tu dinero.
                  </TrainingText>
                </li>
                <li>
                  <TrainingText
                    className="roboto"
                    size="11px"
                    sizew="12px"
                    align="justify"
                    weight=" "
                  >
                    Haz una lista de deseos. Anota las cosas que quieres y el
                    dinero que necesitas para cumplirlas, y el orden de
                    importancia que tienen para ti.
                  </TrainingText>
                </li>
                <li>
                  <TrainingText
                    className="roboto"
                    size="11px"
                    sizew="12px"
                    align="justify"
                    weight=" "
                  >
                    Usa una libreta especial para anotar cada vez que recibas
                    dinero, y con otro color cada vez que lo gastes para que
                    sepas cuánto tienes en total al final del mes.
                  </TrainingText>
                </li>
                <li>
                  <TrainingText
                    className="roboto"
                    size="11px"
                    sizew="12px"
                    align="justify"
                    weight=" "
                  >
                    Ahorra siempre. Cada vez que recibas dinero, guarda una
                    parte para tu ahorro, no importa si es una moneda o un
                    billete. ¡No lo olvides!.
                  </TrainingText>
                </li>
                <li>
                  <TrainingText
                    className="roboto"
                    size="11px"
                    sizew="12px"
                    align="justify"
                    weight=" "
                  >
                    ¡Mantén el ánimo! Si no logras ahorrar la cantidad y te
                    gastas el ahorro antes de cumplir tu meta, ¡empieza de nuevo
                    tu ahorro!.
                  </TrainingText>
                </li>
                <li>
                  <TrainingText
                    className="roboto"
                    size="11px"
                    sizew="12px"
                    align="justify"
                    weight=" "
                  >
                    Antes de comprar algo con tu ahorro, busca en otras tiendas.
                    Es posible que encuentres un mejor precio y gastes menos.
                  </TrainingText>
                </li>
              </ul>
            </TrainingDivSection2>
          )}
        </div>
      </DivFlex>
      <CardsBtn
        min_w="90%"
        min_web="50%"
        mleft="5%"
        mleftweb="25%"
        mright="5%"
        mrightweb="25%"
      >
        Salir
      </CardsBtn>
      <Container
        style={{ transform: 'rotate(180deg)' }}
        className="col-12"
        width=""
        mt="40px"
        displayweb="flex"
        displaymobile="none"
        widthMobile=""
        mtMobile="0px"
        background=" "
        height="289.406px"
      >
        <Rectangle23
          Ptop="81px"
          Pleft="3%"
          width="100px"
          height="100px"
          background={'#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Triangle
          width="50px"
          height="50px"
          marginleft="-50px"
          margintop="239px"
          transform="rotate(224deg)"
          widthborder="50px solid transparent"
          widthborderColor={'50px solid' + '#FA9878;'}
        />
        <Rectangle23
          Ptop="105px"
          Pleft="15%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(9.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={'#FACFA9'}
          bradius="14px"
          left="17%"
          top="242px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="100px"
          Pleft="24%"
          transform="rotate(-33.24deg)"
          width="100px"
          height="100px"
          background={'#E3A75F'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="142px"
          Pleft="38%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(9.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={'#FACFA9;'}
          bradius="14px"
          left="49%"
          top="89px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="166px"
          Pleft="52%"
          transform="rotate(62.76deg)"
          width="100px"
          height="100px"
          background={'#E3A75F;'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="81px"
          Pleft="64%"
          width="100px"
          height="100px"
          background={'#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="250px"
          Pleft="70%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle23
          Ptop="19px"
          Pleft="80%"
          width="100px"
          height="100px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={'#FACFA9;'}
          bradius="14px"
          left="85%"
          top="242px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="150px"
          Pleft="95%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle23
          Ptop="243px"
          Pleft="97%"
          transform="rotate(62.76deg)"
          width="100px"
          height="100px"
          background={'#E3A75F;'}
          position="absolute"
          displayD=" "
          displayM=""
        />
      </Container>
      <Container
        style={{ transform: 'rotate(180deg)' }}
        width="100%"
        mtMobile="40px"
        mt="40px"
        displayweb="none"
        displaymobile="flex"
        widthMobile="100%"
        mtMobile="50px"
        background=" "
        height="237.031px"
      >
        <Rectangle23
          left="2%"
          Ptop="81px"
          Pleft="3%"
          width="60px"
          height="60px"
          background={'#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="10%"
        />
        <Triangle
          width="50px"
          height="50px"
          marginleft="-5px"
          margintop="180px"
          transform="rotate(452deg)"
          widthborder="50px solid transparent"
          widthborderColor={'50px solid' + '#FA9878;'}
        />
        <Rectangle23
          transform="rotate(11.76deg)"
          left="34%"
          Ptop="90px"
          Pleft="3%"
          width="25px"
          height="25px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="35%"
        />
        <Rectangle23
          left="60%"
          Ptop="81px"
          Pleft="3%"
          width="60px"
          height="60px"
          background={'#E3A75F'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="55%"
        />
        <Rectangle23
          transform="rotate(11.76deg)"
          left="90%"
          Ptop="105px"
          Pleft="3%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="82%"
        />
        <Rectangle25
          topM="0px"
          leftM="70%"
          positionD="absolute"
          background={'#FACFA9'}
          bradius="20px"
          left="17%"
          top="242px"
          width="50px"
          height="55px"
          displayM=" "
          displayD=" "
          leftMb="75%"
        />
      </Container>
    </Fragment>
  );
}
export default SavingAndHomeworks;
