import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  DYNAMIC_GET,
  GET_RECOMMENDATIONS,
} from '../../../../../mutations';
import { VERIFY_TOKEN } from '../../../../../queries';
import '../../assets/admin.css';

const TrainingsAWS = () => {
  //
  // HOOKS for state and querys
  //
  const valToken = useQuery(VERIFY_TOKEN, {
    variables: { token: sessionStorage.getItem('token') },
  });
  if (valToken.loading !== true) {
    if (valToken.data.verifyToken.response === 'invalid') {
      window.location.href = '/admin/';
    }
  }
  const [trainings, setTrainings] = useState([]);
  const [cambio, setCambio] = useState(false);

  const [getTrainings] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        console.log(JSON.parse(dynamicGet.response));
        setTrainings(JSON.parse(dynamicGet.response));
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getRecommendations] = useMutation(GET_RECOMMENDATIONS, {
    onCompleted({ getPersonalizeRecommendation }) {
      let trainingorder;

      for (const indexTrainings in trainings) {
        for (const indexOportunity in getPersonalizeRecommendation.itemList) {
          if (
            String(trainings[indexTrainings].IDCatTraining)
            === getPersonalizeRecommendation.itemList[indexOportunity].itemId
            && trainings[indexTrainings].IDCatTraining !== 9
          ) {
            trainings[indexTrainings].score = getPersonalizeRecommendation.itemList[indexOportunity].score
              * 100;
            break;
          } else {
            trainings[indexTrainings].score = 0;
          }
        }
      }
      console.log('new', trainings);
      trainingorder = sortJSON(trainings, 'score', 'desc');
      console.log('order', trainingorder);

      setTrainings(trainingorder);
    },
    onError(err) {
      console.log('Entrar error: ', err);
    },
  });

  const sortJSON = (data, key, orden) => data.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    if (orden === 'asc') {
      return x < y ? -1 : x > y ? 1 : 0;
    }
    if (orden === 'desc') {
      return x > y ? -1 : x < y ? 1 : 0;
    }
  });

  const trainingsParameters = {
    input: {
      table: 'CatTraining',
      columns: [
        'IDCatTraining',
        'TrainingTime',
        'TrainingBigTitle',
        'TrainingBigDescription',
        'UrlBigImage',
      ],
    },
  };

  useEffect(() => {
    getTrainings({ variables: trainingsParameters });
  }, []);

  useEffect(() => {
    console.log('clg use effect: ', cambio);
  }, [cambio]);

  const submitUser = async () => {
    setCambio(false);
    await getRecommendations({ variables: { email } });
    setCambio(true);
  };

  const [email, setEmail] = useState('');

  const getEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Fragment>
      <div
        id="training-filters"
        className="container"
        style={{ display: 'flex', alignItems: 'flex-end', marginTop: 50 }}
      >
        <div className="row row-content">
          <div className="col-12">
            <h3>Test Amazon Personalize</h3>
          </div>
          <div className="col-12 col-md-9">
            <div className="form-group row">
              <div className="col-md-12">
                <input
                  type="email"
                  className="form-control"
                  id="emailid"
                  value={email}
                  name="emailid"
                  placeholder="Email"
                  onChange={(e) => getEmail(e)}
                ></input>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12">
                <button onClick={submitUser} className="btn btn-secondary">
                  Obtener Recomendación
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex' }} className=" container-filterer">
        {trainings.map(
          ({
            IDCatTraining,
            TrainingBigDescription,
            TrainingBigTitle,
            TrainingTime,
            UrlBigImage,
            score,
          }) => (
              <div key = {IDCatTraining} className=" filter-element">
                <img src={UrlBigImage} alt="" srcSet="" />
                <div className="title">{TrainingBigTitle}</div>
                <div className="description">{TrainingBigDescription}</div>
                <div className="time">
                  Tiempo de entrenamiento: {TrainingTime} minutos
                </div>
                <div className="score">Score Recomendación: {score}</div>
              </div>
          ),
        )}
      </div>
    </Fragment>
  );
};
export default TrainingsAWS;
