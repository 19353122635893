import React from 'react';
import imgfoot from '../../assets/img/anacoach/footer.png';
import { Image } from '../common/common.styled';
import { FeedFooterMainBlue } from '../../assets/styles/Feed.styled';

function FooterFeedBlue(props) {
  return (
        <FeedFooterMainBlue right='inherit' left='inherit' width={props.width}>
            <Image src={imgfoot} className="img-fluid" bottom='-2px' />
        </FeedFooterMainBlue>
  );
}

export default FooterFeedBlue;
