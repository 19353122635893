/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import Modal from '@material-ui/core/Modal';
import { useMutation } from "@apollo/client";
import { REGISTER_FOR_BUSINESS, CREATE_SESSION } from "../../mutations";
import {
  Button,
  ParagrapTwo,
  Container,
  ImageIcon,
  ModalFull,
} from "../../components/common/common.styled";
import CoruLogo from '../../assets/img/Coru_Logo.svg';
import "../../assets/styles/TextCustom.css";
import NameComplet from "./NameComplet";
import Contacto from "./Contacto";
import DataBusiness from "./DataBusiness";
import ClickButton from "../../Helpers/HookHelpers";

const RegisterWithBusiness = ({
  // nextRoutine,
  setOpen,
  open,
  setSuccess,
  success,
  isBrasilLp = false
}) => {
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    secondname: "",
    motherslastname: "",
    email: "",
    numPhone: "",
    lada: "México lada +(52)",
    textlada: "México lada +(52)",
    Empresa: "",
    Posicion: "",
    Sitio: "",
  });
  const { actions } = ClickButton();
  const heightW = window.screen.height;
  const [validCampos, setvalidCampos] = useState({
    personales: false,
    contacto: false,
    password: false,
  });
  const [disabledbtn, setdisabledbtn] = useState(true);

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
      }
    },
  });

  const [registerClient] = useMutation(REGISTER_FOR_BUSINESS, {
    onCompleted({ AddForBusiness }) {
      const { statusCode } = AddForBusiness;
      if (statusCode === 200) {
        setOpen(!open);
        setSuccess(!success);
      } else if (statusCode === 400) {
        alert('Ocurrio un error');
      }
    },
    onError(err) {
      console.log(err);
      alert('Ocurrio un error');
    },
  });

  const handleClickForm = (e) => {
    if (!isBrasilLp) {
      actions({
        action: 'ClickButtonForBusiness',
        variables: 'clickButtonGuardardatosCFB',
        collection: 'ClickButtonHeaderFor_Business',
      });
      e.preventDefault();
      e.stopPropagation();
      const infoUser = [
        {
          FirstName: form.firstname,
          MiddleName: form.secondname,
          FathersLastName: form.lastname,
          MothersLastName: form.motherslastname,
          PreferredPhone: form.numPhone,
          email: form.email,
          empresa: form.Empresa,
          posicion: form.Posicion,
          sitio: form.Sitio,
        },
      ];
      const encode = btoa(JSON.stringify(infoUser));
      try {
        registerClient({
          variables: {
            DataJson: encode,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      actions({
        action: 'ClickButtonLPBrasil',
        variables: 'ClickDatosUsuario_LpCoruBrasil',
        collection: 'LogClickButtonLpCoruBrasil',
      });

      e.preventDefault();
      e.stopPropagation();

      const infoUser = {
        'User Data': [
          {
            Name: `${form.firstname} ${form.secondname} ${form.lastname} ${form.motherslastname}`,
          }
        ],
        'Contact User': [
          {
            Email: form.email,
            'Phone Number': form.numPhone,
          }
        ],
        'Bussines Data': [
          {
            Name: form.Empresa,
            'Work Position': form.Posicion,
            Web: form.Sitio,
          }
        ]
      };

      const mongoInput = {
        input: {
          collection: 'LogLpCoruBrasil',
          data: JSON.stringify(infoUser),
        },
      };

      createSession({ variables: mongoInput });
    }
  };

  useEffect(() => {
    if (
      validCampos.personales
      && validCampos.contacto
      && validCampos.empresa
    ) {
      setdisabledbtn(false);
    } else {
      setdisabledbtn(true);
    }
  }, [validCampos]);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  return (
    <>
      <Modal open={open}>
        <ModalFull
          width="40%"
          widthXs="90%"
          height={`${heightW - 150}px`}
          heightXs={`${heightW - 50}px`}
          bottom="auto"
          borderR="16px"
          margin="1% 30%"
          marginXs="5%"
          className='d-flex flex-column'
          justifyC="flex-start"
          textAlign="center"
        >
          <Container padding="20px 20px" height={`${heightW - 50}px`} style={{ overflowY: 'scroll' }} >
            <ParagrapTwo
                width="95%"
                widthM="95%"
                fontWeight="700"
                cursor="pointer"
                texttalign="right"
                textalignMobile="right"
                className='mx-auto'
                onClick={handleClose}
                mt="15px"
                mtWeb="0px"
                position="fixed"
                topM="2%"
                rightM="7%"
                top="5.5%"
                right="32%"
              >
                X
              </ParagrapTwo>
            <ImageIcon
              widthMobile="60%"
              width="40%"
              margin="0px auto 10px auto"
              src={CoruLogo}
            />
            <NameComplet form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
            <Contacto form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
            <DataBusiness form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
            <div className="my-1 w-100">
              <Button
                borderRadius="16px"
                className={
                  disabledbtn
                    ? "buttonInactive d-block py-md-3 roboto"
                    : "d-block roboto py-md-3"
                }
                mb="0px"
                mt="1.2rem"
                bg={disabledbtn ? "#d1d1d1" : "#fb245c"}
                onClick={(e) => handleClickForm(e)}
                disabled={disabledbtn}
              >
                Hablar con un experto
              </Button>
            </div>
            {/* <span style={{ color: "red" }}>{errorAccountExist}</span> */}
          </Container>
        </ModalFull>
      </Modal>
    </>
  );
};

export default RegisterWithBusiness;
