/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import { GET_TRAININGS } from "../../queries";
import { GET_RECOMMENDATION_TRAININGS, DYNAMIC_GET } from "../../mutations";
import { GET_PRODUCTS_BY_ID } from "../../queries";
import Resultados from "../../components/Training/ReasonsResults";
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingText,
  TrainingSectionFlex,
} from "../../assets/styles/Training.styled";
import { Container } from "../../assets/styles/Home.styled";
import {
  SeparateSection,
  Feed_Main,
  Feed_Section,
} from "../../assets/styles/Feed.styled";
import { ContentHomeSection } from "../../components/common/Home.styled";
import Recommendations from "../../components/Training/RecommendedTraining";
import CardOne from "../../components/Home/Card1Animate";
import image1 from "../../assets/img/anacoach/mejor tarjeta.jpg";
import HeaderSuccess from "../../components/Training/HeaderSucces";
import WOlayout from "../../components/layouts/WOlayout";
import SectionShare from "../../components/common/ShareSection";
import ModalAmazon from "../../components/common/ModalAmazon";
import Kanda from "../../components/Kanda/Kanda";
import ProductsFremium from "../../components/common/ProductsFremium";
import ModalVirality from "../../components/common/ModalVirality";
import CardCastor from "../../components/common/CardCastorDescription";

const RpReasons = () => {
  const anchop = window.screen.width;
  const [goFeed] = useState(false);
  const [results, setResults] = useState(false);
  const [resultsProducts, setResultsProducts] = useState([]);
  const history = useHistory();
  const [stateInformativo, setInformativo] = useState(false);
  const [stateOutstanding, setOutstanding] = useState(false);
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  const validateTwin = [];
  const validateMedium = [];
  const validateBig = [];
  const email = sessionStorage.getItem("Email1");
  const trainingBig = [];
  const trainingVertical = []; // vertical cc
  const items4 = []; // entrenamiento destacado
  const itemsMore = []; // informativos
  const trainingTwin = []; // tiwn
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [producto, setProducto] = useState(null);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    }
  );
  let resultsCards;
  const products = [];
  const [getResults] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        resultsCards = JSON.parse(dynamicGet.response);
        console.log(resultsCards);
        let idProductTmp = 0;
        for (const i in resultsCards) {
          if (resultsCards[i].IDProduct !== idProductTmp) {
            idProductTmp = resultsCards[i].IDProduct;
            products.push({
              idProduct: resultsCards[i].IDProduct,
              img: resultsCards[i].ImageURL,
              name: resultsCards[i].Name,
              clickOut: resultsCards[i].ClickOut,
              beneficios: [],
            });
          }
        }
        for (const n in products) {
          for (const m in resultsCards) {
            if (
              resultsCards[m].IDProduct === products[n].idProduct &&
              resultsCards[m].beneficio.includes("Beneficio")
            ) {
              products[n].beneficios.push({
                name: resultsCards[m].beneficio,
                description: resultsCards[m].Description,
              });
            }
          }
        }
        console.log(products);
        setResults(true);
        setResultsProducts(products);
      }
    },
  });

  const [getProductsByIdProduct] = useLazyQuery(GET_PRODUCTS_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted({ getProductsByIdProduct }) {
      const { statusCode, response, message, error } = getProductsByIdProduct;
      if (message === "success") {
        const producto = {};
        const dataJson = JSON.parse(response);
        const detallesJson = JSON.parse(dataJson[0].productDetail.response);
        const detalles = detallesJson.map((detalle) => detalle.Description);
        const idUser = sessionStorage.getItem("idUser");
        const clickOut = dataJson[0].ClickOut.replace("[IDUser]", idUser);
        console.log("CASTOR", idUser, dataJson[0]);

        producto.Name = dataJson[0].Name;
        producto.Details = detalles;
        producto.ImageURL = dataJson[0].ImageURL;
        producto.Description = dataJson[0].DescriptionProduct;
        producto.ClickOut = clickOut;
        setProducto(producto);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    getProductsByIdProduct({
      variables: { idProduct: String(573) },
    });
  }, []);

  const effectCall = async () => {
    const getProductsInput = {
      input: {
        table: "CatRelProductTraining, CatProduct, CatProductDetail",
        columns: [
          "CatProductDetail.IDProduct, CatTraining_IDCatTraining, ImageURL, CatProductDetail.Name as beneficio, CatProduct.Name, CatProductDetail.Description, ClickOut",
        ],
        conditions: [
          {
            valone: "CatProduct.IDProduct",
            valtwo: "CatProductDetail.IDProduct",
            condition: "=",
            validColumn: true,
          },
          {
            valone: "CatProduct_IDProduct",
            valtwo: "CatProduct.IDProduct",
            condition: "=",
            logic: "AND",
            validColumn: true,
          },
          {
            valone: "CatTraining_IDCatTraining",
            valtwo: "34",
            condition: "=",
            logic: "AND",
          },
        ],
      },
    };
    await getResults({ variables: getProductsInput });
    getPersonalizeRecommendationTrainings({ variables: { email } });
  };
  useEffect(() => {
    effectCall();
  }, []);

  useEffect(() => {
    if (goFeed) {
      history.push("/the-training-feed/");
    }
  }, [goFeed]);

  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateInformativo.indexOf(idTrainning) !== -1)) {
      const sectionInformativo = {
        id: "",
        idTrainning: "",
        name: "",
        parrafo1: "",
        color: "#fff",
        Liked: "",
        image: image1,
        TrainingRedirectUrl: "",
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      sectionInformativo.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
      if (itemsMore.length >= 1 && !stateInformativo) {
        setInformativo(true);
      }
    }
  };

  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateOutstanding.indexOf(idTrainning) !== -1)) {
      const sectionOutstanding = {
        id: "",
        idTrainning: "",
        name: "",
        parrafo1: "",
        color: "#fff",
        Liked: "",
        image: "",
        TrainingRedirectUrl: "",
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionOutstanding.Liked = arrValue.Liked;
      items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
      if (items4.length >= 1 && !stateOutstanding) {
        setOutstanding(true);
      }
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: "",
        idTrainning: "",
        name: "",
        parrafo1: "",
        color: "#fff",
        image: "",
        Liked: "",
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      sectionVertical.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
  };
  const idUser = sessionStorage.getItem("idUser") || 0;
  const vars = {
    variables: { idUser: Number(idUser) },
    fetchPolicy: "no-cache",
  };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining) {
    const trainingList = dataTraining.getTrainings;
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      switch (training.Section) {
        case "Big":
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          break;
        case "Medium":
          // items2.push(sectionMedium);
          break;
        case "Twin":
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default:
          break;
      }
      if (training.TrainingType === "Informativo") {
        pushInformativo(training);
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === "Tarjetas de crédito") {
        pushCCVertical(training);
      }
    });
  }
  return (
    <WOlayout
      backgroundColor={"#91D770"}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <ModalVirality />
      <HeaderSuccess
        color1="#91D770"
        color2="#E3E660"
        color3="#E3E660"
        color4="#6BD5AF"
        color5="#E3E660"
        color6="#BFDD6A"
        color7="#BFDD6A"
        color8="#6BD5AF"
        color9="#91D770"
        color10="#6BD5AF"
      />
      <TrainingMain mt="50px" mtw="0" className="" id="top">
        <br />
        <TrainingSectionGray back="#fafafa">
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <TrainingDivSection2 wiw="90%" mw="auto" back="#fafafa">
                <TrainingSubtitles
                  size="35px"
                  fw="bold"
                  align="center"
                  alignweb="center"
                  className="cabin"
                >
                  ¡Perfecto!
                </TrainingSubtitles>
                <TrainingText
                  size="18px"
                  weight="600"
                  className="roboto"
                  align="center"
                  alignweb="center"
                  ls="-0.2px"
                >
                  Según lo que nos dijiste, esto es lo que puedes hacer para
                  solucionarlo:
                </TrainingText>
                <TrainingText
                  size="18px"
                  weight="600"
                  className="roboto"
                  align="left"
                  alignweb="center"
                  ls="-0.2px"
                >
                  ¿Qué puedes hacer?
                </TrainingText>
                <Container width="65%">
                  <TrainingText
                    size="16px"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    - {sessionStorage.getItem("recomendations_reasons_1")}
                    {sessionStorage.getItem("recomendations_reasons_url_1") ? (
                      <>
                        <br />
                        <Link
                          to={sessionStorage.getItem(
                            "recomendations_reasons_url_1"
                          )}
                        >
                          {sessionStorage.getItem(
                            "recomendations_reasons_url_1"
                          )}
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </TrainingText>
                  <TrainingText
                    size="16px"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    - {sessionStorage.getItem("recomendations_reasons_2")}
                    {sessionStorage.getItem("recomendations_reasons_url_2") ? (
                      <>
                        <br />
                        <Link
                          to={sessionStorage.getItem(
                            "recomendations_reasons_url_2"
                          )}
                        >
                          {sessionStorage.getItem(
                            "recomendations_reasons_url_2"
                          )}
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </TrainingText>
                  <TrainingText
                    size="16px"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    - {sessionStorage.getItem("recomendations_reasons_3")}
                    {sessionStorage.getItem("recomendations_reasons_url_3") ? (
                      <Link
                        to={sessionStorage.getItem(
                          "recomendations_reasons_url_3"
                        )}
                      >
                        {sessionStorage.getItem("recomendations_reasons_url_3")}
                      </Link>
                    ) : (
                      ""
                    )}
                  </TrainingText>
                  <TrainingText
                    size="16px"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    - {sessionStorage.getItem("recomendations_reasons_4")}
                    {sessionStorage.getItem("recomendations_reasons_url_4") ? (
                      <Link
                        to={sessionStorage.getItem(
                          "recomendations_reasons_url_4"
                        )}
                      >
                        {sessionStorage.getItem("recomendations_reasons_url_4")}
                      </Link>
                    ) : (
                      ""
                    )}
                  </TrainingText>
                  <TrainingText
                    size="16px"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    - {sessionStorage.getItem("recomendations_reasons_5")}
                    {sessionStorage.getItem("recomendations_reasons_url_5") ? (
                      <Link
                        to={sessionStorage.getItem(
                          "recomendations_reasons_url_5"
                        )}
                      >
                        {sessionStorage.getItem("recomendations_reasons_url_5")}
                      </Link>
                    ) : (
                      ""
                    )}
                  </TrainingText>
                  <TrainingText
                    size="16px"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    - {sessionStorage.getItem("recomendations_reasons_6")}
                    {sessionStorage.getItem("recomendations_reasons_url_6") ? (
                      <Link
                        to={sessionStorage.getItem(
                          "recomendations_reasons_url_6"
                        )}
                      >
                        {sessionStorage.getItem("recomendations_reasons_url_6")}
                      </Link>
                    ) : (
                      ""
                    )}
                  </TrainingText>
                </Container>
              </TrainingDivSection2>
              <SeparateSection />
              <TrainingSectionGray back="#fafafa">
                <TrainingDivSection2
                  wiw="670px"
                  paddi="0"
                  paddiw="0"
                  mw="auto"
                  back="#fafafa"
                >
                  <CardOne
                    classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                    classCard="cardOne"
                    classTop="topOne1"
                    textbold="¡Estas son nuestras recomendaciones!"
                    text="Te ayudamos a tener la mejor opción"
                  />
                </TrainingDivSection2>
              </TrainingSectionGray>
              <TrainingDivSection
                mt="20px"
                back="#fafafa"
                backw="#fafafa"
                wi="335px"
                wiw="auto"
                padding="0"
                paddw="0"
              >
                <TrainingSectionFlex fdirection="column" display="flex">
                  {results ? (
                    <Fragment>
                      <Resultados cards={resultsProducts} />
                    </Fragment>
                  ) : null}
                  {producto ? (
                    <CardCastor
                      cardTitle={producto.Name}
                      cardDescription={producto.Description}
                      cardInfoList={producto.Details}
                      imgUrl={producto.ImageURL}
                      onClick={producto.ClickOut}
                      key={1}
                    />
                  ) : null}
                </TrainingSectionFlex>
              </TrainingDivSection>

              {recomendaciones ? (
                <Fragment>
                  <TrainingDivSection2
                    wiw="670px"
                    paddi="0"
                    paddiw="0"
                    mw="auto"
                    back="#fafafa"
                  >
                    <CardOne
                      classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                      classCard="cardOne"
                      classTop="topOne1"
                      textbold="Estos entrenamientos pueden ayudarte a mejorar tu score crediticio:"
                    />
                  </TrainingDivSection2>
                  <div className="marginComentLgSuccess1">
                    <Container
                      mleftmxs="0%"
                      mleft="18%"
                      mleftm="7%"
                      width="100%"
                      widthMobile="100%"
                      mtMobile="0px"
                    >
                      <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                        <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                          <Recommendations
                            items={recommendedTraining}
                          ></Recommendations>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Container>
                  </div>
                </Fragment>
              ) : null}

              <TrainingSectionGray back="#fafafa">
                <TrainingDivSection2
                  wiw="670px"
                  paddi="0"
                  paddiw="0"
                  mw="auto"
                  back="#fafafa"
                >
                  <TrainingSectionGray back="#fafafa">
                    <ProductsFremium
                      idT={parseInt(sessionStorage.getItem("idTraining"))}
                    />
                  </TrainingSectionGray>
                  <ModalAmazon show={true} wo={"RazonesRechazo"}></ModalAmazon>
                  <SectionShare WO="RazonesRechazo"></SectionShare>
                </TrainingDivSection2>
              </TrainingSectionGray>
            </Feed_Main>
          </Feed_Main>
        </TrainingSectionGray>
      </TrainingMain>
    </WOlayout>
  );
};
export default RpReasons;
