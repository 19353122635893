import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import CustomStepLayout from '../../layouts/CustomStepLayout';
import maleAvatar from '../../../../assets/img/anacoach/svg/gender_male_option.svg';
import femaleAvatar from '../../../../assets/img/anacoach/svg/gender_female_option.svg';
import anonimousAvatar from '../../../../assets/img/anacoach/svg/gender_anonimous_option.svg';
import { DYNAMIC_GET } from '../../../../mutations';

const AddressData = ({
  customColor,
  coachImg,
  stepData,
  type,
  nextHandler,
  handleNextParam,
  appearAnimation,
  disappearAnimation,
  isActive,
  canGoBack,
  backText,
  backHandler,
  backHandlerOption,
}) => {
  const stepLayoutProps = {
    customColor,
    coachImg,
    type,
    isActive,
    appearAnimation,
    disappearAnimation,
    backText,
    backHandlerOption,
    backHandler,
    canGoBack,
  };

  const [data, setData] = useState({
    Email1:
      sessionStorage.getItem('temp-Email1')
      || sessionStorage.getItem('Email1')
      || '',
    CellPhone:
      sessionStorage.getItem('temp-CellPhone')
      || sessionStorage.getItem('CellPhone')
      || '',
    Gender:
      sessionStorage.getItem('temp-Gender')
      || sessionStorage.getItem('Gender')
      || '',
  });

  const [genderActive, setGenderActive] = useState('');

  const [buttonActive, setButtonActive] = useState(false);

  const [dynamicGet] = useMutation(DYNAMIC_GET, {
    onCompleted: (dynamicData) => {
      dynamicData = JSON.parse(dynamicData.dynamicGet.response)[0];

      setData({
        ...data,
        Email1:
          sessionStorage.getItem('temp-Email1')
          || sessionStorage.getItem('Email1')
          || dynamicData.Email1
          || '',
        CellPhone:
          sessionStorage.getItem('temp-CellPhone')
          || sessionStorage.getItem('CellPhone')
          || dynamicData.CellPhone
          || '',
        Gender:
          sessionStorage.getItem('temp-Gender')
          || sessionStorage.getItem('Gender')
          || dynamicData.Gender
          || '',
      });

      if (
        sessionStorage.getItem('temp-Gender')
        || sessionStorage.getItem('Gender')
      ) {
        setGenderActive(
          sessionStorage.getItem('temp-Gender')
            || sessionStorage.getItem('Gender'),
        );
      } else if (dynamicData.Gender) {
        setGenderActive(dynamicData.Gender);
      }
    },
  });

  useEffect(() => {
    const getInputData = {
      table:
        'CatUsers as cu INNER JOIN CatContact as cc ON cu.CatContact_IDCont = cc.IDCont INNER JOIN CatContactMedium as cm ON cc.IDCont = cm.CatContact_IDCont',
      columns: ['cm.Email1', 'cm.CellPhone', 'cc.Gender'],
      conditions: [
        {
          valone: 'cu.IDUser',
          condition: '=',
          valtwo: sessionStorage.getItem('idUser') || '0',
        },
        {
          logic: '',
          valone: '',
          condition: '',
          valtwo: '',
          validColumn: false,
        },
      ],
    };

    dynamicGet({
      variables: {
        input: getInputData,
      },
    });
  }, []);

  /* Default info data to show. This data can be replaced with prop 'stepData' */
  const defaultContent = {
    title: '¡Excelente! Ahora cuéntame sobre ti.',
    description: 'Ingresa tus datos para encontrar las mejores opciones.',
    instructions: 'Ingresa tus datos de contacto',
    avatarInstructions: 'Elige tu género',
  };

  /* Gender select handler */
  const handleGender = (opc) => (e) => {
    e.persist();
    e.preventDefault();
    setGenderActive(opc);
    setData({
      ...data,
      Gender: opc,
    });
  };

  /* Data state handler on input change */
  const setDataChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  /* Data listener to activate or deactivate button */
  useEffect(() => {
    setButtonActive(validateFields());
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    /*
            Save temporal data on sessionStorage
        */

    sessionStorage.setItem('temp-Email1', data.Email1);
    sessionStorage.setItem('temp-CellPhone', data.CellPhone);
    sessionStorage.setItem('temp-Gender', data.Gender);

    /* Save data here

         - - - - - - - - - -

         - - - - - - - - - -

         - - - - - - - - - -

         - - - - - - - - - -

        */

    nextHandler(handleNextParam);
  };

  /* Function to validate required fields */
  const validateFields = () => {
    if (
      data.Email1.trim().length > 0
      && data.CellPhone.trim().length > 0
      && data.Gender.trim().length > 0
    ) return true;

    return false;
  };

  /* Function to get text */
  const getText = (name) => (
    /*
            If text parameter is set in props, use it
            If text parameter is not set in props, then use default text parameter
            If text parameter is set in props but it is an empty string, then remove margin from div so as not to ocuppy space
        */
    <div
      className={`${name} ${
        stepData && stepData[name] && stepData[name].trim() === ''
          ? 'no-margin'
          : ''
      }`}
    >
      {stepData ? stepData[name] || defaultContent[name] : defaultContent[name]}
    </div>
  );

  return (
    <CustomStepLayout {...stepLayoutProps} stepClass="step-static-address-data">
      <div className="text-content">
        {getText('title')}

        {getText('description')}
      </div>

      <form onSubmit={handleSubmit}>
        {getText('instructions')}

        <div className="input-row">
          <input
            type="text"
            name="Email1"
            placeholder="Correo electrónico"
            onChange={setDataChange}
            value={data.Email1}
            required
          />
        </div>

        <div className="input-row">
          <input
            type="number"
            name="CellPhone"
            placeholder="Teléfono celular"
            onChange={setDataChange}
            value={data.CellPhone}
            required
          />
        </div>

        <div
          className={`instructions ${
            stepData
            && stepData.avatarInstructions
            && stepData.avatarInstructions.trim() === ''
              ? 'no-margin'
              : ''
          }`}
        >
          {stepData
            ? stepData.avatarInstructions || defaultContent.avatarInstructions
            : defaultContent.avatarInstructions}
        </div>

        <div className="input-row">
          <button
            className={`img-button ${
              genderActive === 'Masculino' ? 'active' : ''
            } `}
            onClick={handleGender('Masculino')}
          >
            <img src={maleAvatar} alt="" />
          </button>

          <button
            className={`img-button ${
              genderActive === 'Femenino' ? 'active' : ''
            } `}
            onClick={handleGender('Femenino')}
          >
            <img src={femaleAvatar} alt="" />
          </button>

          <button
            className={`img-button ${genderActive === 'Otro' ? 'active' : ''} `}
            onClick={handleGender('Otro')}
          >
            <img src={anonimousAvatar} alt="" />
          </button>
        </div>

        <div className="button-container">
          <button
            className={`custom-text-button ${buttonActive ? 'active' : ''}`}
          >
            Siguiente {'>'}{' '}
          </button>
        </div>
      </form>
    </CustomStepLayout>
  );
};

export default AddressData;
