import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  TrainingMain,
  DivFlex,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
  BorderTask,
  StatusTask,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import { Feed_Main } from '../../assets/styles/Feed.styled';
import {
  Container,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle25,
  Triangle,
} from '../common/Home.styled';
import TasksStatus from './WeekTask';

import CardOne from '../Home/Card1Animate';

function ActiveRewards({
  data, reward, name, handleChange, saving, handleClick, setState,
}) {
  return (
        <Fragment>
            {/* <Header background={'#fafafa'} pathname={window.location.pathname} /> */}

            <TrainingMain style={{ marginBottom: '20px' }} mt="50px" mtw="0" className="" id="top">
                <br />
                <TrainingSectionGray back="#fafafa">
                    <Feed_Main width="100%" background=" " >
                        <Feed_Main width="80%" display=" " mtop=" " background="transparent">
                            <TrainingDivSection2 paddiw="0% 10% 3% 10%" paddi="0% 5% 5% 5%" back="#fafafa">
                                <CardOne
                                    classCard="cardOne"
                                    classTop="topOne1"
                                    text={`Felicidades ${name || 'Gael'}, tus recompensas han sido activadas:`}
                                />
                                <br />
                                <br />
                                <DivFlex widthweb="70%" marweb="auto" style={{ borderRadius: '4px' }}>
                                    <div>
                                        <TrainingText weight="600">Tarea</TrainingText>
                                    </div>
                                    <div>
                                        <TrainingText weight="600">Recompensa obtenida</TrainingText>
                                    </div>
                                </DivFlex>
                                <TasksStatus datos={data}></TasksStatus>
                            </TrainingDivSection2>
                            <TrainingDivSection2 paddiw="0% 10% 3% 10%" paddi="0% 5% 5% 5%" back="#fafafa">
                                <DivFlex widthweb="70%" marweb="auto" style={{ backgroundColor: '#FFFFFF', boxShadow: '1px 0px 29px -22px rgba(0,0,0,0.75)', borderRadius: '4px' }}>
                                    <BorderTask bs="none">
                                        <TrainingText size="18px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                            TOTAL RECOMPENSAS
                                    </TrainingText>
                                    </BorderTask>
                                    <StatusTask fw="800" Color="#FA9D59" bColor="#FA9D59" >
                                        ${reward}
                                    </StatusTask>
                                </DivFlex>
                            </TrainingDivSection2>
                        </Feed_Main>
                    </Feed_Main>
                </TrainingSectionGray>
            </TrainingMain>

            <DivFlex widthweb="70%" marweb="auto">
                <div>
                    <TrainingText className="roboto" size="16px" align="center" weight=" ">Es muy importante ahorrar, te recomendamos ahorrar el 50%, para cumplir alguna meta</TrainingText>
                </div>
            </DivFlex>
            <DivFlex widthweb="50%" marweb="auto">
                <div style={{ width: '50%' }}>
                    <StatusTask id="quieroAhorrar" width="85%" style={{ cursor: 'pointer', height: '60px', marginTop: '28px' }} padding="1rem" fw="900" Color="#FA9D59" bColor="#FA9D59" onClick={(e) => { handleClick(e, 'Si', reward); }} >
                        Sí, quiero ahorrar el 50%
                    </StatusTask>
                </div>
                <div style={{ width: '50%' }}>
                    <BorderTask margin="10px auto" width="85%" bs="none">
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            name="saving"
                            fullWidth
                            placeholder="Otra cantidad"
                            inputProps={{
                              style: { borderColor: '#FA9D59 !important' },
                            }}
                            value={saving}
                            onChange={handleChange}
                        />
                    </BorderTask>
                </div>

            </DivFlex>
            {saving !== '' ? (
                // min_w="90%" min_web="50%" mleft="5%" mleftweb="25%" mright="5%" mrightweb="25%" border="1px solid #F64282" back="#fff" color="#F64282"
                <CardsBtn min_w="90%" min_web="50%" mleft="5%" mleftmd="5%" mleftweb="25%" mright="5%" mrightweb="25%" onClick={(e) => { handleClick(e, 'otra_cantidad'); }} >Guardar ahorro</CardsBtn>
            ) : null}
            <br />
            <br />
            <CardsBtn min_w="90%" min_web="50%" mleft="5%" mleftmd="5%" mleftweb="25%" mright="5%" mrightweb="25%" zindex="100" onClick={() => setState(true)} ><b>Ver avance</b></CardsBtn>
            <br />
            <br />
            <Container style={{ transform: 'rotate(180deg)' }} className="col-12" width='' mt="40px" displayweb="flex" displaymobile="none" widthMobile='' mtMobile="0px" background=" " height="289.406px">
                <Rectangle23 Ptop="81px" Pleft="3%" width="100px" height="100px" background={'#F5D495'} position="absolute" displayD=" " displayM="" />
                <Triangle width="50px" height="50px" marginleft="-50px" margintop="239px" transform="rotate(224deg)" widthborder="50px solid transparent" widthborderColor={'50px solid' + ('#FA9878;')} />
                <Rectangle23 Ptop="105px" Pleft="15%" width="40px" height="40px" background={'#FA9878'} position="absolute" displayD=" " displayM="" transform="rotate(9.24deg)" />
                <Rectangle25 positionD="absolute" background={'#FACFA9'} bradius="14px" left="17%" top="242px" width="30px" height="35px" displayM=" " displayD=" " />
                <Rectangle23 Ptop="100px" Pleft="24%" transform="rotate(-33.24deg)" width="100px" height="100px" background={'#E3A75F'} position="absolute" displayD=" " displayM="" />
                <Rectangle23 Ptop="142px" Pleft="38%" width="40px" height="40px" background={
                    '#FA9878'} position="absolute" displayD=" " displayM="" transform="rotate(9.24deg)" />
                <Rectangle25 positionD="absolute" background={'#FACFA9;'} bradius="14px" left="49%" top="89px" width="30px" height="35px" displayM=" " displayD=" " />
                <Rectangle23 Ptop="166px" Pleft="52%" transform="rotate(62.76deg)" width="100px" height="100px" background={'#E3A75F;'} position="absolute" displayD=" " displayM="" />
                <Rectangle23 Ptop="81px" Pleft="64%" width="100px" height="100px" background={'#F5D495'} position="absolute" displayD=" " displayM="" />
                <Rectangle23 Ptop="250px" Pleft="70%" width="40px" height="40px" background={'#FA9878'} position="absolute" displayD=" " displayM="" transform="rotate(47.24deg)" />
                <Rectangle23 Ptop="19px" Pleft="80%" width="100px" height="100px" background={'#FA9878'} position="absolute" displayD=" " displayM="" transform="rotate(47.24deg)" />
                <Rectangle25 positionD="absolute" background={'#FACFA9;'} bradius="14px" left="85%" top="242px" width="30px" height="35px" displayM=" " displayD=" " />
                <Rectangle23 Ptop="150px" Pleft="95%" width="40px" height="40px" background={'#FA9878'} position="absolute" displayD=" " displayM="" transform="rotate(47.24deg)" />
                <Rectangle23 Ptop="243px" Pleft="97%" transform="rotate(62.76deg)" width="100px" height="100px" background={'#E3A75F;'} position="absolute" displayD=" " displayM="" />
            </Container>
            <Container style={{ transform: 'rotate(180deg)' }} mtMobilexs="50px" width='100%' mtMobile="40px" mt="40px" displayweb="none" displaymobile="flex" widthMobile='100%' mtMobile="50px" background=" " height="237.031px">
                <Rectangle23 left="2%" Ptop="81px" Pleft="3%" width="60px" height="60px" background={'#F5D495'} position="absolute" displayD=" " displayM="" leftMb="10%" />
                <Triangle width="50px" height="50px" marginleft="-5px" margintop="180px" transform="rotate(452deg)" widthborder="50px solid transparent" widthborderColor={'50px solid' + ('#FA9878;')} />
                <Rectangle23 transform="rotate(11.76deg)" left="34%" Ptop="90px" Pleft="3%" width="25px" height="25px" background={'#FA9878'} position="absolute" displayD=" " displayM="" leftMb="35%" />
                <Rectangle23 left="60%" Ptop="81px" Pleft="3%" width="60px" height="60px" background={'#E3A75F'} position="absolute" displayD=" " displayM="" leftMb="55%" />
                <Rectangle23 transform="rotate(11.76deg)" left="90%" Ptop="105px" Pleft="3%" width="40px" height="40px" background={'#FA9878'} position="absolute" displayD=" " displayM="" leftMb="82%" />
                <Rectangle25 topM="0px" leftM="70%" positionD="absolute" background={
                    '#FACFA9'} bradius="20px" left="17%" top="242px" width="50px" height="55px" displayM=" " displayD=" " leftMb="75%" />
            </Container>
        </Fragment>
  );
}
export default ActiveRewards;
