import styled from 'styled-components';
import BgImg from './btnAssets.png';

export const CardContainer = styled.div`
  /* Responsive */
 
  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    position: relative;
    padding-top:10px;
   /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
    
  }
  @media (max-width: 375px) {
     height: 650px; 
     padding-top:100px;

  }
  @media (min-width: 411px) {
    height: 700px; 
    padding-top:10px;

  }
  @media (max-width: 768px) {
      height: 600px; 


  }
  /* @media (min-width: 1024px) {
  } */
  @media (min-width: 1312px) {
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:900px;
    background-color: #FAFAFA;
    padding-top:50px;
    flex-direction: row;
   
    

  }
`;

export const VideoContainer = styled.div`
 
    /* Responsive */
   @media (min-width: 320px) {
    display: flex;
    width: 100%;
    height: 600px;
    position: absolute; 
    /* background-color: #fafafa;  */
    
  }
  @media (min-width: 375px) {
   height: 650px; 
  }
  @media (min-width: 411px) {
     height: 700px; 
  }
  @media (max-width: 768px) {
     height: 720px; 
  }
  /* @media (min-width: 1024px) {
  } */
  @media (min-width: 1312px) {
    display: flex;
    width: 60%;
    height: 700px;
    box-shadow:0 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
   
  }
`;

export const BtnContainer = styled.div`
 
  /* Responsive */

  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 160px;
    position: absolute; 
    background-image: none;
    background-color: red;
    border: none;
    top: 450px;
    background: rgba(0, 0, 0, 0); 
  }
  @media (min-width: 375px) {
    /* top: 510px; */

  }
  /* @media (min-width: 411px) {
    } */
  @media (max-width: 768px) {
    /* top: 570px; */
  }
  /* @media (min-width: 1024px) {
  } */
  @media (min-width: 1312px) {

    display: flex;
    width: 33%;
    flex-direction: column;
    align-items: center;
    align-items: center;
    height: 700px;
    margin-left: 20px;
    box-shadow: 0 4px 4px  rgba(0, 0, 0, 0.25);
    background-image: url(${BgImg});
    background-size: contain;
    position: relative; 
    top: 0px;
  }
`;

export const Button = styled.button`
  background: #f64282;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
  color: #fafafa;
   /* Responsive */
  @media (min-width: 320px) {
    width: 80%;
    height: 40px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
  }
  /* @media (min-width: 375px) {

  } */
  @media (min-width: 411px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 70%;

  }
  @media (min-width: 1024px) {
    width: 50%;

  }
  @media (min-width: 1312px) {
    width: 70%;
    height:60px
  }
`;

export const BtnContainerEnd = styled.div` 
 /* Responsive */

  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 230px;
    position: absolute; 
    background-image: none;
  
    border: none;
    top: 380px; 
     background: rgba(0, 0, 0, 0);  
    /* background:red; */
  }
  @media (min-width: 375px) {
    /* top: 410px; */

  }
  /* @media (min-width: 411px) {
    } */
  @media (max-width: 768px) {
    /* top: 570px; */
  }
  /* @media (min-width: 1024px) {
  } */
  @media (min-width: 1312px) {
    display: flex;
    width: 33%;
    flex-direction: column;
    align-items: center;
    align-items: center;
    height: 700px;
    margin-left: 20px;
    box-shadow: 0 4px 4px  rgba(0, 0, 0, 0.25);
    background-image: url(${BgImg});
    background-size: contain;
    position: relative; 
    top: 0px;
  }


`;

export const ButtonEnd = styled.button` 

  background: #f64282;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
  color: #fafafa;
   width: 100%;
   
   /* Responsive */
  @media (min-width: 320px) {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
   
  }
  /* @media (min-width: 375px) {

  } */
  @media (min-width: 411px) {
    width: 90%;
  
  }
  @media (max-width: 768px) {
    width: 90%;
   margin-top: 20px;
  }
  @media (min-width: 1024px) {
    width: 50%;

  }
  @media (min-width: 1312px) {
    width: 70%;
    height:60px
  }


`;

export const formContainer = styled.form` 


 @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    position: relative;
    padding-top:10px;
   /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
    
  }
  @media (max-width: 375px) {
     height: 650px; 
     padding-top:100px;

  }
  @media (min-width: 411px) {
    height: 700px; 
    padding-top:10px;

  }
  @media (max-width: 768px) {
      height: 600px; 


  }
  /* @media (min-width: 1024px) {
  } */
  @media (min-width: 1312px) {
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:900px;
    background-color: #FAFAFA;
    padding-top:50px;
    flex-direction: row;
   
    

  }





`;

export const inputContainer = styled.div`

 @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 0%;
    padding-left:10px;
    position: relative;
    padding-top:10px;
   
   /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
    
  }
  /* @media (max-width: 375px) {
     height: 650px; 
     padding-top:100px;

  }
  @media (min-width: 411px) {
    height: 700px; 
    padding-top:10px;

  }
  @media (max-width: 768px) {
      height: 600px; 


  }
  /* @media (min-width: 1024px) {
  } 
  @media (min-width: 1312px) {
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:900px;
    background-color: #FAFAFA;
    padding-top:50px;
    flex-direction: row;
   
    

  } 

*/


`;
