import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Dailymotion from 'react-dailymotion';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '700px',
    backgroundColor: '#FFFFFF',
  },

});

const VideoThanks = () => {
  const classes = useStyles();

  return (
    <Fragment><div className={classes.root}>
        <CardContent>
        <Dailymotion
           video="k4Y3YEkVbSl48IwTrnB"
           width="100%"
           height= "670px"
           controls="false"
            autoplay="1"
           mute="false"
        />
        </CardContent>
      </div>
    </Fragment>
  );
};

export default VideoThanks;
