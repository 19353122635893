import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Slider from '@material-ui/core/Slider';
import {
  CardsBtn,
  TrainingSubtitles,
  TrainingDivSectionPymes,
  TrainingText,
} from '../../assets/styles/Training.styled';

const useStyles = makeStyles((theme) => ({
  ControlSelect: {
    Width: '100%',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  Btn: {
    backgroundColor: '#ffff',
    marginBottom: '15px',
    height: '50px',
    width: '95%',
    marginLeft: '10px',
  },
  ContainerBtn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  ContainerSlider: {
    marginTop: '10px',
    marginBottom: '15px',
    width: '80%',
    marginLeft: '30px',
  },
}));

const PymesIdealCard = ({
  data,
  title,
  text,
  handleChangeQuestionsSend,
  onClick,
}) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    tipoCredito: sessionStorage.getItem('Tipo_de_credito') || '',
    plazoBuscado: sessionStorage.getItem('Plazo_buscado') || 0,
    cantidadRequerida: sessionStorage.getItem('Cantidad_requerida') || 0,
    registroHacienda: sessionStorage.getItem('Registro_hacienda') || '',
    persona: sessionStorage.getItem('Persona') || '',
    tiempoFacturacion: sessionStorage.getItem('Tiempo_Facturacion') || '',
  });
  const [valuePlazo, setValuePlazo] = useState(0);
  const [valueCantidad, setValueCantidad] = useState(0);
  const [showSliders, setShowSliders] = useState(false);
  const [marksOfPlazoLabels, setMarksOfPlazoLabels] = useState([]);
  const [marksOfPlazoValues, setMarksOfPlazoValues] = useState([]);
  const [marksOfCantidadLabels, setMarksOfCantidadLabels] = useState([]);
  const [marksOfCantidadValues, setMarksOfCantidadValues] = useState([]);

  const [catTipoPersona, setCatTipoPersona] = useState([]);
  const [openfile, setOpenFile] = useState();

  const valueText = (value) => `${value}`;

  const valueLabelFormatPlazos = (value) => {
    const objPlazo = marksOfPlazoValues.filter((e) => e.value === value)[0];
    let label = '';
    if (objPlazo) {
      const arrayPlazo = objPlazo.label.split(' ');
      if (arrayPlazo[0] === 'Más' || arrayPlazo[0] === 'Mas') {
        label = `> ${arrayPlazo[2]}`;
      } else {
        label = arrayPlazo[0];
      }
    }
    return label;
  };

  const valueLabelFormatCantidad = (value) => {
    const objCantidad = marksOfCantidadValues.filter(
      (e) => e.value === value,
    )[0];
    let label = '';
    if (objCantidad) {
      const arrayCantidad = objCantidad.label.split(' ');
      if (arrayCantidad[0] === 'Más' || arrayCantidad[0] === 'Mas') {
        label = `> ${arrayCantidad[2]} ${arrayCantidad[3]}`;
      } else {
        label = `${parseInt(arrayCantidad[0].replace(/[$,]/g, '')) / 1000} K`;
      }
    }
    return label;
  };

  const handleSliderChangePlazo = (event, newValue) => {
    if (event && typeof event.persist === 'function') {
      event.persist();
      event.stopPropagation();
    }
    setValuePlazo(newValue);
    const mark = marksOfPlazoValues.filter((e) => e.value === newValue);
    handleInputChange(
      {
        target: {
          name: 'plazoBuscado',
          value: newValue,
          valueMarks: mark[0].label,
        },
      },
      1,
    );
  };

  const handleSliderChangeCantidad = (event, newValue) => {
    if (event && typeof event.persist === 'function') {
      event.persist();
      event.stopPropagation();
    }
    setValueCantidad(newValue);
    const mark = marksOfCantidadValues.filter((e) => e.value === newValue);
    handleInputChange(
      {
        target: {
          name: 'cantidadRequerida',
          value: newValue,
          valueMarks: mark[0].label,
        },
      },
      2,
    );
  };

  const twoCalls = (event, index) => {
    handleInputChange(event, index);
    validHacienda(event.target.value);
  };

  const validHacienda = (value) => {
    let tipoPersona = [];
    const { detailQuestion } = data.questions[4];

    if (value.toLowerCase() === 'no') {
      tipoPersona = detailQuestion.filter(
        (e) => e.IDCatQuestionsDetail === 911,
      );
    } else if (value.toLowerCase() === 'si') {
      tipoPersona = detailQuestion.filter(
        (e) => e.IDCatQuestionsDetail === 913 || e.IDCatQuestionsDetail === 912,
      );
    }
    setCatTipoPersona(tipoPersona);
  };

  const handleInputChange = (event, index) => {
    const { name, value, valueMarks } = event.target;

    setForm({
      ...form,
      [name]: value,
    });

    const checkValue = valueMarks || value;

    // Cat
    if (data.questions[index].detailQuestion.length > 0) {
      const answer = data.questions[index].detailQuestion.filter(
        (e) => e.FieldOption === checkValue,
      );
      if (answer.length > 0) {
        handleChangeQuestionsSend(
          index + 1,
          answer[0].FieldOption,
          answer[0].IDCatFieldDetail,
          data.questions[index].QuestionType,
        );
      }
    } else {
      // OpenField
      setOpenFile({
        index: index + 1,
        DatUserDetailStorage: data.questions[index].DatUserDetailStorage,
        value: value,
        QuestionType: data.questions[index].QuestionType,
      }
      );
    }
  };

  useEffect(() => {
    let cantidad;
    let plazo;
    let obj;

    // Get QuestionOption Plazos
    const plazosOptions = data.questions[1].detailQuestion.map(
      (e) => e.QuestionOption,
    );
    // Get Marks Plazos
    const { marksValues: valuesPlazo, marks2Labels: labelsPlazo } = getQuestionsOptions(plazosOptions);
    // Set Values Labels Plazos
    setMarksOfPlazoValues(valuesPlazo);
    setMarksOfPlazoLabels(labelsPlazo);
    // Get Plazo Seleccionado
    plazo = sessionStorage.getItem('Plazo_buscado') || 0;
    obj = valuesPlazo.filter((e) => e.label === plazo);
    setValuePlazo(obj.length === 0 ? plazo : obj[0].value);

    // Get QuestionOption Cantidad
    const cantidadesOptions = data.questions[2].detailQuestion.map(
      (e) => e.QuestionOption,
    );
    // Get Marks Cantidades
    const { marksValues: valuesCantidad, marks2Labels: labelsCantidad } = getQuestionsOptions(cantidadesOptions);
    // Set Values Labels Plazos
    setMarksOfCantidadValues(valuesCantidad);
    setMarksOfCantidadLabels(labelsCantidad);
    // Get Cantidad Seleccionado
    cantidad = sessionStorage.getItem('Cantidad_requerida') || 0;
    obj = valuesCantidad.filter((e) => e.label === cantidad);
    setValueCantidad(obj.length === 0 ? cantidad : obj[0].value);

    setShowSliders(true);

    // Validación Registrado en Hacienda
    validHacienda(sessionStorage.getItem('Registro_hacienda') || '');
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem('Plazo_buscado') && marksOfPlazoValues.length > 0) {
      handleSliderChangePlazo(null, 0);
    }
  }, [marksOfPlazoValues])

  useEffect(() => {
    if (!sessionStorage.getItem('Cantidad_requerida') && marksOfCantidadValues.length > 0) {
      handleSliderChangeCantidad(null, 0);
    }
  }, [marksOfCantidadValues])

  const getQuestionsOptions = (options) => {
    const marksValues = [];
    const marks2Labels = [];
    let indexMedium = -1;
    if (options.length % 2 !== 0) {
      indexMedium = Math.floor(options.length / 2);
    }
    for (let i = 0; i < options.length; i++) {
      marksValues.push({ value: i * 10, label: options[i] });
      if (i === 0 || i === options.length - 1 || i === indexMedium) {
        marks2Labels.push({ value: i * 10, label: options[i] });
      } else {
        marks2Labels.push({ value: i * 10, label: '' });
      }
    }
    return { marksValues, marks2Labels };
  };

  return (
    <>
      <TrainingDivSectionPymes
        wiw="60%"
        wiw320="90%"
        mw="20px 0 0 18%"
        back="#F3F3F3"
      >
        <TrainingSubtitles
          size="16px"
          mbotm="20px"
          fw="600"
          align="left"
          alignweb="left"
          className="cabin"
        >
          {title}
        </TrainingSubtitles>
        <form>
          <TrainingText
            size="16px"
            className="roboto"
            bot="5px"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            {data.questions[0].QuestionName}
          </TrainingText>

          <FormControl style={{ width: '100%' }} variant="outlined">
            <InputLabel id="demo-simple-select-filled-label">
              Selecciona tu crédito ideal
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              label="Selecciona tu crédito ideal"
              name="tipoCredito"
              value={form.tipoCredito}
              onChange={(e) => handleInputChange(e, 0)}
              variant="outlined"
              className={classes.ControlSelect}
            >
              {data.questions[0].detailQuestion.map((e, index) => (
                <MenuItem key={index} value={e.QuestionOption}>
                  {e.QuestionOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {showSliders ? (
            <>
              <TrainingText
                size="16px"
                className="roboto"
                bot="15px"
                align="left"
                alignweb="left"
                ls="-0.2px"
              >
                {data.questions[1].QuestionName}
              </TrainingText>

              <div className={classes.ContainerSlider}>
                <Slider
                  value={valuePlazo}
                  getAriaValueText={valueText}
                  aria-labelledby="discrete-slider-small-steps"
                  step={10}
                  min={0}
                  max={(marksOfPlazoLabels.length - 1) * 10}
                  marks={marksOfPlazoLabels}
                  valueLabelFormat={valueLabelFormatPlazos}
                  aria-label="small"
                  valueLabelDisplay="auto"
                  onChange={handleSliderChangePlazo}
                  style={{ fontSize: '2vw', textAlign: 'center' }}
                />
              </div>
            </>
          ) : null}
          <TrainingText
            size="16px"
            className="roboto"
            bot="5px"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            {data.questions[2].QuestionName}
          </TrainingText>

          <div className={classes.ContainerSlider}>
            <Slider
              value={valueCantidad}
              getAriaValueText={valueText}
              aria-labelledby="discrete-slider-small-steps"
              step={10}
              min={0}
              max={(marksOfCantidadLabels.length - 1) * 10}
              marks={marksOfCantidadLabels}
              valueLabelFormat={valueLabelFormatCantidad}
              aria-label="small"
              valueLabelDisplay="auto"
              onChange={handleSliderChangeCantidad}
              style={{ fontSize: '2vw', textAlign: 'center' }}
            />
          </div>

          <TrainingText
            size="16px"
            className="roboto"
            bot="5px"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            {data.questions[3].QuestionName}
          </TrainingText>

          <FormControl style={{ width: '100%' }} variant="outlined">
            <InputLabel id="demo-simple-select-filled-label">
              Selecciona
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              label="Selecciona"
              name="registroHacienda"
              value={form.registroHacienda}
              onChange={(e) => twoCalls(e, 3)}
              className={classes.ControlSelect}
            >
              {data.questions[3].detailQuestion.map((e, index) => (
                <MenuItem key={index} value={e.QuestionOption}>
                  {e.QuestionOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TrainingText
            size="16px"
            className="roboto"
            bot="5px"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            {data.questions[4].QuestionName}
          </TrainingText>

          <FormControl style={{ width: '100%' }} variant="outlined">
            <InputLabel id="demo-simple-select-filled-label">
              Selecciona el tipo de persona
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              label="Selecciona el tipo de persona"
              name="persona"
              value={form.persona}
              onChange={(e) => handleInputChange(e, 4)}
              className={classes.ControlSelect}
            >
              {catTipoPersona.map((e, index) => (
                <MenuItem key={index} value={e.QuestionOption}>
                  {e.QuestionOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TrainingText
            size="16px"
            className="roboto"
            bot="5px"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            {data.questions[5].QuestionName}
          </TrainingText>
          <FormControl style={{ width: '100%' }} variant="outlined">
            <TextField
              id="outlined-basic"
              label="Ingresa la cantidad"
              type="number"
              name="tiempoFacturacion"
              value={form.tiempoFacturacion}
              onChange={(e) => handleInputChange(e, 5)}
              className={classes.ControlSelect}
              variant="outlined"
            />
          </FormControl>
        </form>

        <div style={{ display: 'flex' }}>
          <CardsBtn
            windthG="100%"
            mtop="0px"
            mtopw="0px"
            onClick={() => onClick(form, openfile)}
          >
            Calcular mi crédito PyME
          </CardsBtn>
        </div>
      </TrainingDivSectionPymes>
    </>
  );
};

export default PymesIdealCard;
