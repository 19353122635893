import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Rectangle13,
  Rectangle24,
  Vector2,
  Vector3,
  TextoCoach,
  ContentHomeSection,
  UiCard,
} from './Home.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import ClickButton from '../../Helpers/HookHelpers';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import { filterFloat } from '../../Helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '580px',
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const ModalMetaColaborativo = ({
  textTraining, urlTraining, close, setNuevoAhorro, setAddSavings,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);
  const { actions } = ClickButton();

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  const guardarAhorro = (event) => {
    const click = `GuardarAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    event.preventDefault();
    console.log('Guarda ahorro');
    setNuevoAhorro(form.cantidadAhorro);
    setAddSavings(false);
  };

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };

  const [form, setForm] = useState({
    cantidadAhorro: '',
  });
  const [ahorroC, setAhorroC] = useState('');

  const handleChange = (e, question = '') => {
    console.log('handleChange InputOrder', e.target.name, e.target.value, e.target.className, e.target.id);
    const value2 = e.target.value.replace('$', '').replace(/,/g, '');
    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      setForm({
        ...form,
        [e.target.name]: `$${formatterMx(filterFloat(value))}`,

      });
    } else {
      setForm({
        ...form,
        [e.target.name]: '',

      });
    }
    setAhorroC(`$${formatterMx(filterFloat(value2))}`);
  };
  const [modalActive, setModalActive] = useState(true);
  const click = () => {
    setModalActive(false);
    setAddSavings(false);
  };

  return (
        <Modal className={modalActive || setAddSavings ? '' : 'hidden'} open={true} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>
                <div style={{
                  position: 'absolute', right: '20px', top: '10px', fontSize: '18px', cursor: 'pointer',
                }} onClick={() => click()}><i className="fa fa-times" aria-hidden="true"></i></div>
                <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
                <ContentHomeSection leftD="0%" floatD="left" width="48%;"></ContentHomeSection>
                <ImageCoachInicio />
                <ContentHomeSection leftD="0%" floatD="left" width="35%;"></ContentHomeSection>
                </ContentHomeSection>
                <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
                <Rectangle24
                    positionD="absolute"
                    Ptop="125px"
                    PTopM="-65px"
                    Pleft="-10%"
                    background="#6BD5AF"
                    displayM="block"
                    displayD=" "
                    left=" "
                    top=" "
                    width="70px"
                    height="70px"
                    transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
                />
                <Vector2
                    width="55px"
                    left="0"
                    PleftM="35%"
                    height="65px"
                    background="#91D770"
                    radius="40%"
                    transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                    positionD="absolute"
                    Pleft="17%"
                    Ptop="-5px"
                    displayM="block"
                />
                <Vector3
                    position="absolute"
                    left=" "
                    top="-26px"
                    Ptop="10px"
                    Pleft="44%"
                    background=" #E3E660;"
                    transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                    width="35px"
                    height="35px"
                    displayD=" "
                    displayM="none"
                />
                <Rectangle24
                    positionD="absolute"
                    Ptop="100px"
                    Pleft="74%"
                    PLeftM="-78%"
                    PTopM="-32%"
                    background="#BFDD6A"
                    displayM="block"
                    displayD=" "
                    left=" "
                    top=" "
                    width="30.94px"
                    height="30.94px"
                    transform="rotate(46.41deg)"
                />
                <Vector2
                    width="50px"
                    widthM="28.81px"
                    heightM="35.02px"
                    left=""
                    height="60px"
                    background="#6BD5AF"
                    radius="40%"
                    transform="rotate(-8.34deg)"
                    positionD="absolute"
                    Pleft="52%"
                    PleftM="-15%"
                    PTopM="-10%"
                    MtopM="-15%"
                    Ptop="135px"
                    displayM="block"
                />
                <Vector3
                    position="absolute"
                    left=" "
                    top=" "
                    Ptop="85px"
                    Pleft="14%"
                    PleftM="-3%"
                    background="#E3E660"
                    transform="rotate(-15.06deg)"
                    width="35px"
                    height="35px"
                    displayD=" "
                    displayM="block"
                />
                <Rectangle24
                    widthM="35px"
                    heightM="35px"
                    mLeftM="0"
                    mTopM="18%"
                    positionD="absolute"
                    Pleft="70%"
                    Ptop="0"
                    displayD=""
                    width="60px"
                    height="60px"
                    left=" "
                    top=" "
                    background="#91D770"
                    transform="rotate(-17.24deg)"
                />

                <UiCard positionM=" " heightM="150px" marginTop="120px" marginTopM="120px">
                    <Rectangle13
                        boxSha=" "
                        margin="auto"
                        bottomM="0px"
                        displayM="block"
                        position=" "
                        topM="0px"
                        height="150px"
                        width="100%"
                        widthm="100%"
                        padding="19px 25px 20px 21px"
                        bottom="0"
                        mleftxs='0'
                    >
                        <TextoCoach height="auto" className="roboto" width="auto" margin="auto auto 20px auto">
                            <b>¡Actualicemos esta meta!</b>
                        </TextoCoach>
                        <TextoCoach height="auto" align="left" className="roboto" width="auto">
                            ¡Registra tu primer ahorro de esta quincena!
                        </TextoCoach>
                        <TextoCoach height="auto" align="left" className="roboto" width="auto">
                            Recuerda que para registrar tus próximos ahorros, lo podrás hacer desde nuestra página, en la sección de <b>Logros</b>.
                        </TextoCoach>
                    </Rectangle13>
                </UiCard>
                </ContentHomeSection>
                <div style={{
                  display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '0', textAlign: 'left', padding: '0 17px',
                }}>
                    <TextoCoach height="auto" align="left" className="roboto" width="auto" margin='auto 0' marginW='auto 0'>
                        <b>Cantidad a ahorrar:</b>
                    </TextoCoach>
                    <TextField
                        variant="outlined"
                        id="cantidadMeta"
                        autoComplete="off"
                        name="cantidadAhorro"
                        value={ahorroC}
                        onChange={handleChange}
                        placeholder="Cantidad a ahorrar"
                        inputProps={{ maxLength: 8 }}
                    />
                </div>
                <div style={{ padding: '0 17px' }}>
                  <CardsBtn min_w="100%" min_web="100%" onClick={(e) => guardarAhorro(e)}>
                  <b>Guardar</b>
                  </CardsBtn>
                  <br />
                  <Link >
                  <CardsBtn onClick={handleClose} back="#FFFFFF" color="#F64282" border="2px solid #F64282" min_w="100%" min_web="100%" >
                      <b>Regresar al entrenamiento</b>
                  </CardsBtn>
                  </Link>
                </div>
            </div>
        </Modal>
  );
};

export default ModalMetaColaborativo;
