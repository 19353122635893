import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Buttons, ContainerBox } from '../Styleds/Asesores.Styled';
import CampañasTran from '../DataTable/CampañasTran.js';
import Template from '../DataTable/Template.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {

    background: 'blue',
  },
  mItem: {
    marginLeft: '0px',
  },
  mItem2: {
    marginLeft: '2px',
  },
  mItem3: {
    marginLeft: '3px',
  },

});

const BtnDropDw = (props) => {
  const classes = useStyles();
  const [showOption, setShowOption] = useState(null);
  const [OptionsTemplate, setOptionsTemplate] = useState(null);

  const handleClick = (event) => {
    setShowOption(event.currentTarget);
  };

  const handleClose = () => {
    setShowOption(null);
  };

  const handleClickTemplate = (event) => {
    setOptionsTemplate(event.currentTarget);
  };

  const handleCloseTemplate = () => {
    setOptionsTemplate(null);
  };

  return (
    <ContainerBox>
      <div>
        <Buttons
          bcK="#3F4D67"
          // id=" "
          marT="20px"
          type="button"
          onClick={handleClick}>
          {props.text}
        </Buttons>
        <Menu
          style={{ marginTop: '62px', width: '790px', height: '250px' }}
          id="customized-menu"
          anchorEl={showOption}
          keepMounted
          open={Boolean(showOption)}
          onClose={handleClose}
        >
          <MenuItem className={classes.mItem} onClick={handleClose}> <CampañasTran title="Sms" tipo="sms" />  </MenuItem>
          <MenuItem className={classes.mItem2} onClick={handleClose}> <CampañasTran title="Email" tipo="email" />  </MenuItem>
          <MenuItem className={classes.mItem3} onClick={handleClose}> <CampañasTran title="Whatsapp" tipo="whatsapp" />  </MenuItem>
        </Menu>
      </div>
      <div>
        <Buttons
          bcK="#3F4D67"
          marT="20px"
          type="button"
          onClick={handleClickTemplate}>
          {props.text2}
        </Buttons>
        <Menu
          style={{ marginTop: '62px', width: '790px', height: '250px' }}
          anchorEl={OptionsTemplate}
          keepMounted
          open={Boolean(OptionsTemplate)}
          onClose={handleCloseTemplate}
        >
          <MenuItem onClick={handleCloseTemplate}> <Template title="Template" />  </MenuItem>
        </Menu>
      </div>
    </ContainerBox >
  );
};

export default BtnDropDw;
