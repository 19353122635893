/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { TrainingQuestion, DivRespDP } from '../../assets/styles/Training.styled';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import CardBold from '../../components/Home/CardWithBold';
import Features from '../../components/Training/Features';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import CardTwo from '../../components/Home/Card2Animate';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ModalGloveBox from '../../components/common/ModalGloveBox';

const GloveBoxProfile = ({
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  currentRoutine,
  nextOrderRoutine,
  questions,
  trainingColor,
  setQuestions,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [show, setShow] = useState(false);
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [form, setForm] = useState({});
  const [loaderWait, setLoaderWait] = useState(true);
  const [editGB00, setEditGB00] = useState(false);
  const [editGB11, setEditGB11] = useState(false);
  const [editGB22, setEditGB22] = useState(false);
  const [editGB33, setEditGB33] = useState(false);
  const [GB00, setGB00] = useState('');
  const [GB11, setGB11] = useState('');
  const [GB22, setGB22] = useState('');
  const [GB33, setGB33] = useState('');
  const [avance, setAvance] = useState({
    step00: false,
    step11: false,
    step22: false,
    step33: false,
    step44: false,
  });
  const featuresArray = useState([
    {
      text: 'Póliza de seguro',
      color: 'A5A6F6',
    },
    {
      text: 'Tarjeta de circulación',
      color: 'A5A6F6',
    },
    {
      text: 'Licencia de conducir',
      color: 'A5A6F6',
    },
    {
      text: 'Números de emergencias',
      color: 'A5A6F6',
    },
    {
      text: 'Teléfonos de asistencia',
      color: 'A5A6F6',
    },
    {
      text: 'Verificación',
      color: 'A5A6F6',
    },
  ]);

  const handleShow = (showModal) => {
    setShow(showModal);
  };

  /*------------------------------------------------------------------------------------------------
  --------------------------------- Manejadores de Inputs Order ------------------------------------
  --------------------------------------------------------------------------------------------------
  */

  // GUARDA LAS RESPUESTAS QUE CONTESTO EL USUARIO
  // Questions es un arreglo que arrastra todas las respuestas del usuario
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || type === 'Multiple') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  // ACCIONA IR A LA SIGUIENTE RUTINA
  const handleNextRoutine = () => {
    setTimeout(() => {
      if (orderRutine === currentRoutine) {
        setOrderRutine(nextOrderRoutine);
        gsap.to(window, { duration: 1, scrollTo: '#avGB_guantera44' });
      }
    }, 3000);
  };

  // CAPTURA LA ACCIÓN DE CONTESTAR UNA PREGUNTA
  const handleChangeCatOption = (val) => {
    const arrVals = val.value.split(',');
    switch (val.step) {
      case 0:
        setGB00(val.value); // se guarda el valor seleccionado
        setEditGB00(true); // controla si se puede editar la pregunta
        setAvance({ ...avance, step11: true }); // controla si avanza en las preguntas
        setTimeout(() => {
          if (orderRutine === currentRoutine) {
            gsap.to(window, { duration: 1, scrollTo: '#avGB_guantera11' });
          }
        }, 350);
        break;
      case 1:
        setGB11(val.value);
        setEditGB11(true);
        setAvance({ ...avance, step22: true });
        setTimeout(() => {
          if (orderRutine === currentRoutine) {
            gsap.to(window, { duration: 1, scrollTo: '#avGB_guantera22' });
          }
        }, 350);
        break;
      case 2:
        setGB22(arrVals);
        setEditGB22(true);
        setAvance({ ...avance, step33: true });
        setTimeout(() => {
          if (orderRutine === currentRoutine) {
            gsap.to(window, { duration: 1, scrollTo: '#avGB_guantera33' });
          }
        }, 350);
        break;
      case 3:
        setGB33(val.value);
        setEditGB33(true);
        setAvance({ ...avance, step44: true });
        if (val.value === 'Por ahora no') {
          // SHOW MODAL
          handleShow(true);
        }
        handleNextRoutine();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('default si se agrega una nueva pregunta a la rutina case 4');
        break;
    }
  };

  // FUNCION PARA GUARDAR LO QUE CONTESTO EL USUARIO EN UN STATE DECLARADO EN ESTE SCRIPT
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  /*------------------------------------------------------------------------------------------------
  -----------------------------  MANEJADORES DE ITERACCION DE LA RUTINA 1 --------------------------
  --------------------------------------------------------------------------------------------------
  */
  // PRIMER LOADING AL CARGAR LA PRIMERA RUTINA
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine) {
        setAvance({ ...avance, step00: true });
        gsap.to(window, { duration: 1, scrollTo: '#avGB_guantera00' });
      }
    }, 5000);
  }, []);

  // ACCIONA MODO EDITAR EN LA PREGUNTA N
  const EditQuestion = (order) => {
    // eslint-disable-next-line no-console
    switch (order) {
      case 0:
        setEditGB00(false);
        break;
      case 1:
        setEditGB11(false);
        break;
      case 2:
        setEditGB22(false);
        break;
      case 3:
        setEditGB33(false);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('default si se agrega una nueva pregunta a la rutina');
        break;
    }
  };

  /*------------------------------------------------------------------------------------------------
  --------------------------------- FIN FUNCIONALIDADES RUTINA No 1 --------------------------------
  --------------------------------------------------------------------------------------------------
  */

  return (
    <div className='marginComentLgSuccess1'>
      {!loaderWait ? (
        <Fragment>
          <div id="avGB_guantera00"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={avance.step00 ? 'cardOne' : 'hidden'}
            classTop='topOne1'
            text="¡Comencemos! ¿Para qué crees que sirve la guantera de tu auto? 🤔"
            hora={hora}
          />
          <TrainingQuestion
            className={avance.step00 && !editGB00 ? '' : 'hidden'}
          >
            <InputsOrder
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
        </Fragment>
      ) : (
        // MOSTRANDO LOADER
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      <div id="avGB_guantera11"></div>
      { /* Respuestas de la pregunta 0-0 */
        <DivRespDP>
          <img
            className={editGB00 && orderRutine === 1 ? 'iconEdit1' : 'hidden'}
            alt="editIcon"
            src={editIcon}
            onClick={() => EditQuestion(0)}
          />
          <CardTwo
            classCard={editGB00 ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={GB00}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
      }
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step11 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text="¡Interesante! 🤓 La guantera de tu auto sirve para muchas cosas,
              en especial, para tener a la mano los documentos importantes de
              auto 🚗"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step11 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text="Estos son los documentos que debes llevar siempre contigo:"
        hora={hora}
      />
      {avance.step11 ? (
        <Features
          classAna={
            anchop <= 768 ? 'falseAna' : 'trueAna'
          }
          title=""
          Arraytext={ featuresArray }
          isGloveBox={true}
        >
        </Features>
      ) : null}

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step11 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text="¿Llevas estos documentos siempre contigo? 🤔"
        hora={hora}
      />
      <TrainingQuestion
        className={avance.step11 && !editGB11 ? '' : 'hidden'}
      >
        <InputsOrder
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="1-1"
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>

      <div id="avGB_guantera22"></div>
      { /* Respuestas de la pregunta 1-1 */
        <DivRespDP>
          <img
            className={editGB11 && orderRutine === 1 ? 'iconEdit1' : 'hidden'}
            alt="editIcon"
            src={editIcon}
            onClick={() => EditQuestion(1)}
          />
          <CardTwo
            classCard={editGB11 ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={GB11}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
      }

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step22 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text={<>¡No te preocupes! Puedo ayudarte 🙌<br />En Coru, diseñamos una
        <b> Guantera de Auto Digital</b> 😊 en la que podrás llevar siempre lo
        necesario</>}
      />

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step22 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text="Selecciona los documentos que actualmente llevas en tu guantera:"
        hora={hora}
      />
      <TrainingQuestion
        className={avance.step22 && !editGB22 ? '' : 'hidden'}
      >
        <InputsOrder
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="2-2"
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
          componentWo={'glove-box'}
        />
      </TrainingQuestion>

      <div id="avGB_guantera33"></div>
      { /* Respuestas de la pregunta 2-2 */
        <DivRespDP>
          <img
            className={editGB22 && orderRutine === 1 ? 'iconEdit1' : 'hidden'}
            alt="editIcon"
            src={editIcon}
            onClick={() => EditQuestion(2)}
          />
          <CardTwo
            classCard={editGB22 ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            array={GB22}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
      }

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step33 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text="¡Muy bien! 👍 Sin embargo, es importante tener todos los documentos de la lista.
              ¡A prepararlos!"
        hora={hora}
      />

      <CardBold
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={avance.step33 ? 'cardOne' : 'hidden'}
        classTop='topOne1'
        text="¿Te gustaría utilizar la *Guantera de Auto Digital* para administrar mejor tus
              documentos? 🤓"
      />
      <TrainingQuestion
        className={avance.step33 && !editGB33 ? '' : 'hidden'}
      >
        <InputsOrder
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="3-3"
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>

      <div id="avGB_guantera44"></div>
      { /* Respuestas de la pregunta 3-3 */
        <DivRespDP>
          <CardTwo
            classCard={editGB33 ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={GB33}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
      }
      {avance.step44 && orderRutine === currentRoutine ? (
        // MOSTRANDO LOADER
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      ) : null}
      {
        show ? <ModalGloveBox show={show} setShow={handleShow}/> : null
      }
    </div>
  );
};

GloveBoxProfile.propTypes = {
  hora: PropTypes.string.isRequired,
  orderRutine: PropTypes.number.isRequired,
  setOrderRutine: PropTypes.func.isRequired,
  currentTraining: PropTypes.number.isRequired,
  createJsonInput: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  trainingColor: PropTypes.string.isRequired,
  setQuestions: PropTypes.func.isRequired,
  currentRoutine: PropTypes.number.isRequired,
  nextOrderRoutine: PropTypes.number.isRequired,
};

export default GloveBoxProfile;
