import React, {
  useState, Fragment,
} from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Container,
  Col11,
  ContentButton,
  Col12,
} from '../common.styled';
import '../../../assets/styles/TextCustom.css';

const CcForgotPassword = () => {
  const [form, setForm] = useState({
    email: '',
  });

  const [error] = useState({
    email: false,
  });

  const [texterror] = useState({
    email: '',
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    if (
      e.target.value
            && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      texterror.email = 'Correo electronico incorrecto';
      error.email = true;
      setbuttonDisabled(true);
    } else {
      texterror.email = '';
      error.email = false;
      setbuttonDisabled(false);
    }
  };

  const guardar = (e) => {
    e.preventDefault();
    sessionStorage.setItem('modalCCPassword', true);
    window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/reset-password-cc';
  };

  return (
        <Fragment>
            <Main className="justify-content-center align-items-center" background='#fff'>
                <Container width="50%" mt="1rem" widthMobile="100%" mb="5%">
                    <Col11>
                        <SubtitleOne mt='7%'>¿Olvidaste tu contraseña?</SubtitleOne>
                        <ParagrapTwo className="mx-auto">
                            Ingresa tu correo registrado para identificar tu cuenta.
                    </ParagrapTwo>
                        <form autoComplete="off" onSubmit={guardar}>
                            <Container mb="10%" mt="5%">
                                <Col12 pt="2px">
                                    <TextField
                                        id="email"
                                        name="email"
                                        autoFocus
                                        label="Correo electronico"
                                        error={error.email}
                                        type="email"
                                        fullWidth
                                        required
                                        value={form.email}
                                        onChange={handleChange}
                                        helperText={texterror.email}
                                    />
                                </Col12>
                            </Container>
                            <ContentButton mt="10%" mtMobile="2%">
                                <Button disabled={buttonDisabled} className="d-block  mt-md-4">
                                    Recuperar contraseña
                            </Button>
                            </ContentButton>
                        </form>
                    </Col11>
                </Container>
            </Main>
        </Fragment>
  );
};
export default CcForgotPassword;
