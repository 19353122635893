import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE, DYNAMIC_GET } from '../../mutations';
import { getCurrentDate, formatAMPM } from '../../Helpers/general';

let urlCC = '';
let urlFast = '';
let urlFirtsInv = '';
let urlPL = '';
let triggerN = '';
const SendNotificationFTAndCC = () => {
  const host = window.location.origin;
  const [tel, setTel] = useState('');
  const [wo, setWo] = useState('');
  urlCC = `${host}/wo/card-funnel?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=Notificacion_CC&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
  urlFast = `${host}/wo/fast-track?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=Notificacion_FT&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
  urlFirtsInv = `${host}/wo/first-investment?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=wa_inv&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
  urlPL = `${host}/wo/loans-funnel?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=Notificacion_FT&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('insert exitoso');
      } else {
        console.log(dynamicInsert.message);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const handleUpdateLog = (
    trigger,
    Wo,
    setKey,
    setVal,
    currentDate,
    currentH,
    instrument = '',
  ) => {
    const inputDynamicUpdate = {
      input: {
        table: 'logNotificationWos',
        columns: [
          {
            setkey: '`trigger`',
            setval: trigger,
          },
          {
            setkey: 'type',
            setval: 'wa',
          },
          {
            setkey: 'cellPhone',
            setval: tel || '',
          },
          {
            setkey: 'step',
            setval: Wo,
          },
          {
            setkey: 'hour',
            setval: currentH,
          },
          {
            setkey: 'setKey',
            setval: setKey,
          },
          {
            setkey: 'setVal',
            setval: setVal,
          },
          {
            setkey: '`status`',
            setval: 'true',
          },
          {
            setkey: 'date',
            setval: currentDate,
          },
        ],
        conditions: [
          {
            valone: 'idUser',
            valtwo: `${sessionStorage.getItem('idUser')}`,
            condition: '=',
          },
          {
            logic: 'AND',
            valone: 'type',
            valtwo: 'wa',
            condition: '=',
          },
        ],
      },
    };
    if (instrument !== '') {
      inputDynamicUpdate.input.columns.push({
        setkey: 'extra',
        setval: instrument,
      });
    }
    dynamicUpdate({ variables: inputDynamicUpdate });
  };
  const handleInsertLog = (trigger, setKey, setVal, Wo) => {
    const currentDate = getCurrentDate();
    const currentH = formatAMPM(new Date(), 1);
    const inputLog = {
      input: {
        table: 'logNotificationWos',
        columns: [
          '`trigger`',
          'type',
          'cellPhone',
          'setKey',
          'setVal',
          'destination',
          'subject',
          'idUser',
          'step',
          'status',
          'date',
          'hour',
        ],
        detailValues: {
          values: [
            trigger,
            'wa',
            tel,
            setKey,
            setVal,
            '',
            '',
            `${sessionStorage.getItem('idUser')}`,
            Wo,
            'true',
            currentDate,
            currentH,
          ],
        },
      },
    };
    dynamicInsert({ variables: inputLog });
  };
  const [dynamicGet] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const resp = JSON.parse(dynamicGet.response);
        const currentDate = getCurrentDate();
        const currentH = formatAMPM(new Date(), 1);
        if (resp.length !== 0) {
          if (wo === 'CC') {
            handleUpdateLog(
              'wa_cc_1',
              'CC',
              'URL_WO',
              urlCC,
              currentDate,
              currentH,
            );
          } else if (wo === 'FirstInv') {
            handleUpdateLog(
              'wa_inv_1',
              'FirstInv',
              'URL_WO',
              urlFirtsInv,
              currentDate,
              currentH,
            );
          } else {
            handleUpdateLog(
              'notificacion_pl_abandonopagina',
              'FastTrack',
              'primerNombre,URL_WO',
              `${sessionStorage.getItem(
                'FirstName',
              )},${urlFast}`,
              currentDate,
              currentH,
            );
          }
        } else if (wo === 'CC') {
          handleInsertLog(
            'wa_cc_1',
            'URL_WO',
            urlCC,
            'CC',
          );
        } else if (wo === 'FirstInv') {
          handleInsertLog(
            'wa_inv_1',
            'URL_WO',
            urlFirtsInv,
            'FirstInv',
          );
        } else {
          handleInsertLog(
            'notificacion_pl_abandonopagina',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},${urlFast}`,
            'FastTrack',
          );
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const sendMessage = ({ cellPhone = '', trigger = '', instrument = '' }) => {
    console.log('numero de telefono', cellPhone);
    setTel(cellPhone);
    triggerN = trigger;
    urlCC = `${host}/wo/card-funnel?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=Notificacion_CC&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
    urlFast = `${host}/wo/fast-track?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=Notificacion_FT&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
    urlFirtsInv = `${host}/wo/first-investment?utm_source=Coru&utm_medium=Mkt-A&utm_campaign=wa_inv&trigger=${triggerN}#${sessionStorage.getItem('idUser')}#${sessionStorage.getItem('idCont')}#true`;
    if (sessionStorage.getItem('trainingName') === 'Tu primera inversión') {
      setWo('FirstInv');
      switch (trigger) {
        case 'wa_inv_1': {
          const getInput = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'wa',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInput });
          break;
        }

        case 'wa_inv_2':
          handleUpdateLog(
            trigger,
            'FirstInv',
            'URL_WO',
            urlFirtsInv,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'wa_inv_3':
          handleUpdateLog(
            trigger,
            'FirstInv',
            'URL_WO',
            urlFirtsInv,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'wa_inv_4':
          handleUpdateLog(
            trigger,
            'FirstInv',
            'URL_WO',
            urlFirtsInv,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'Notificación_FT_5_AUX':
          handleUpdateLog(
            trigger,
            'FirstInv',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},https://coru.com/wo/first-investment`,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        default:
          break;
      }
    } else if (
      sessionStorage.getItem('trainingName')
      === 'Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.'
    ) {
      setWo('CC');
      switch (trigger) {
        case 'wa_cc_1': {
          const getInputCC = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'wa',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInputCC });
          break;
        }

        case 'wa_cc_2':
          handleUpdateLog(
            trigger,
            'CC',
            'URL_WO',
            urlCC,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'wa_cc_3':
          handleUpdateLog(
            trigger,
            'CC',
            'URL_WO',
            urlCC,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'wa_cc_4':
          const currentHCC3 = formatAMPM(new Date(), 1);
          handleUpdateLog(
            trigger,
            'CC',
            'URL_WO',
            urlCC,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'Notificación_FT_5_AUX':
          const currentHCC4 = formatAMPM(new Date(), 1);
          handleUpdateLog(
            trigger,
            'CC',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},${urlCC}`,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        default:
          break;
      }
    } else if (
      sessionStorage.getItem('trainingName') === 'Préstamos a tu medida'
    ) {
      switch (trigger) {
        case 'notificacion_pl_abandonopagina': {
          const getInput = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'wa',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInput });
          break;
        }
        case 'Notificación_FT_3':
          handleUpdateLog(
            trigger,
            'FastTrack',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},${urlFast}`,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'Notificación_FT_5':
          handleUpdateLog(
            trigger,
            'FastTrack',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},${urlFast}`,
            getCurrentDate(),
            formatAMPM(new Date(), 0, 5),
            instrument,
          );
          break;
        default:
          break;
      }
    } else if (sessionStorage.getItem('trainingName') === '¿Tienes una meta por cumplir?') {
      switch (trigger) {
        case 'notificacion_pl_abandonopagina': {
          const getInput = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'wa',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInput });
          break;
        }
        case 'Notificación_FT_3':
          handleUpdateLog(
            trigger,
            'PersonalLoans',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},${urlPL}`,
            getCurrentDate(),
            formatAMPM(new Date(), 1),
          );
          break;
        case 'Notificación_FT_5':
          handleUpdateLog(
            trigger,
            'PersonalLoans',
            'primerNombre,URL_WO',
            `${sessionStorage.getItem(
              'FirstName',
            )},${urlPL}`,
            getCurrentDate(),
            formatAMPM(new Date(), 0, 5),
            instrument,
          );
          break;
        default:
          break;
      }
    }
  };

  return { sendMessage };
};

export default SendNotificationFTAndCC;
