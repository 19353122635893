import React, { useEffect, useState, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { GET_DOMICILIO } from '../../queries';

const GetAddressByCp = ({ ZipCode, handleChangeAddress, form }) => {
  const [suburbs, setSuburbs] = useState([]);
  const { data, loading, error } = useQuery(GET_DOMICILIO, {
    variables: {
      ZipCode,
    },
  });
  useEffect(() => {
    console.log('SEPOMEX', data);
    sessionStorage.setItem('ZipCode', ZipCode);
    if (data !== undefined) {
      setSuburbs(data.getDomicilio);
    }
  }, [data]);

  useEffect(() => {
    try {
      const { Municipality } = suburbs[0];
      console.log(Municipality);

      sessionStorage.setItem('Municipality', Municipality);
      sessionStorage.setItem('State', suburbs[0].State);
      form.municipio = Municipality;
      form.estado = suburbs[0].State;
      handleChangeAddress('Municipality', Municipality);
      handleChangeAddress('Suburb', suburbs[0].Suburb);
      handleChangeAddress('estado', suburbs[0].State);
    } catch (e) {}
  }, [suburbs]);

  if (loading) {
    return <option>Cargando</option>;
  }
  if (error) return <h2>ERROR</h2>;

  return (
    <Fragment>
      {suburbs.map((suburb) => (
        <option key={suburb.Suburb} value={suburb.Suburb}>
          {suburb.Suburb}
        </option>
      ))}
    </Fragment>
  );
};

export default GetAddressByCp;
