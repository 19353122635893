export const getParameterByName = (name) => {
  try {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  } catch (error) {
    console.error(error);
  }
};

export const validActivity = () => {
  try {
    const param = getParameterByName('utm_source');
    const uri = window.location.pathname;
    const uriValid = [
      '/the-training-feed',
      '/the-training-feed/',
      '/achievements',
      '/profile',
      '/wo/profiling-2',
      '/wo/emergency-saving',
    ];
    if (localStorage.getItem('activityDate') && param === '') {
      let lastDate = localStorage.getItem('activityDate');
      lastDate = new Date(lastDate);
      const now = new Date();
      const diff = now.getTime() - lastDate.getTime();
      const difference = Math.floor(diff / 1000 / 60);
      if (difference > 1440 && uriValid.includes(uri)) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
      }
    } else {
      localStorage.setItem('activityDate', new Date());
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTransactionID = () => {
  const transactionId = getParameterByName('transactionId');
  if (transactionId) {
    sessionStorage.setItem('transactionidAdcredy', transactionId);
  }
  const affiliateClickId = getParameterByName('affiliateClickId');
  const umtId = getParameterByName('utm_id');
  if (affiliateClickId) {
    sessionStorage.setItem('affiliateClickId', affiliateClickId);
  } else if (umtId) {
    sessionStorage.setItem('affiliateClickId', umtId);
  }
  sessionStorage.setItem('validTransaction', true);
};

export const getLocalStorage = () => {
  if (!sessionStorage.getItem('idUser') && !sessionStorage.getItem('idCont')) {
    const localVariables = [
      'idUser',
      'MiddleName',
      'ProfilePicture',
      'FathersLastName',
      'FirstName',
      'Email1',
      'MothersLastName',
      'idDatSession',
      'idCatRelAction',
      'token',
      'typeUser',
      'idCont',
      'id_session',
      'trainingProcess',
    ];
    localVariables.forEach((id) => {
      if (localStorage.getItem(id)) {
        sessionStorage.setItem(id, localStorage.getItem(id));
      }
    });
  }
};

export const setTraining = (path) => {
  switch (path) {
    case '/wo/card-funnel':
      sessionStorage.setItem(
        'trainingName',
        'Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.',
      );
      break;
      case '/wo/card-funnel-two':
      sessionStorage.setItem(
        'trainingName',
        'Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.',
      );
      break;
    case '/wo/first-card':
      sessionStorage.setItem(
        'trainingName',
        'Domina el uso de tu primera tarjeta de crédito.',
      );
      break;
    case '/wo/compare-credit-card':
      sessionStorage.setItem(
        'trainingName',
        '¿Tienes la mejor tarjeta de crédito para ti?',
      );
      break;
    case '/wo/loans-funnel':
      sessionStorage.setItem('trainingName', '¿Tienes una meta por cumplir?');
      break;
    case '/wo/family-protection':
      sessionStorage.setItem('trainingName', 'Protección Familiar');
      sessionStorage.setItem('pagename', 'proteccion-tarjeta');
      break;
    case '/wo/debts':
      sessionStorage.setItem('trainingName', 'Deudas');
      break;
    case '/wo/profiling':
      sessionStorage.setItem('trainingName', 'Perfilamiento');
      break;
    case '/wo/budget':
      sessionStorage.setItem('trainingName', 'Presupuesto');
      break;
    case '/wo/first-investment':
      sessionStorage.setItem('trainingName', 'Tu primera inversión');
      break;
    case '/wo/investment-catalog':
      sessionStorage.setItem('trainingName', 'Catálogo de inversiones');
      break;
    case '/wo/emergency-saving':
      sessionStorage.setItem('trainingName', 'Ahorro de Emergencia');
      break;
    case '/MVP_Investment':
      sessionStorage.setItem('trainingName', '¿Cuánto sabes de inversión?');
      break;
    case '/wo/investment-test':
      sessionStorage.setItem('trainingName', 'Test de inversión');
      break;
    case '/wo/extra-income':
      sessionStorage.setItem('trainingName', 'Ingresos Extras');
      break;
    case '/wo/budget-improve':
      sessionStorage.setItem('trainingName', 'Mejorar Presupuesto');
      break;
    case '/wo/micro-expenses':
      sessionStorage.setItem('trainingName', 'Gastos hormiga');
      break;
    case '/wo/investment-simulator':
      sessionStorage.setItem('trainingName', 'Inversiones Simulador');
      break;
    case '/wo/goals':
      sessionStorage.setItem('trainingName', 'Crear Meta');
      break;
    case '/wo/fast-track':
      sessionStorage.setItem('trainingName', 'Préstamos a tu medida');
      break;
    case '/wo/virality-test':
      sessionStorage.setItem('trainingName', 'Test viralidad');
      break;
    case '/wo/money-value':
      sessionStorage.setItem('trainingName', 'Valor del Dinero');
      break;
    case '/wo/profiling-2':
      sessionStorage.setItem('trainingName', 'Perfilamiento 2.0');
      break;
    case '/wo/rejection-reasons':
      sessionStorage.setItem('trainingName', 'Motivos de rechazo');
      break;
    case '/wo/collaborative':
      sessionStorage.setItem('trainingName', 'Ahorro Colaborativo');
      break;
    case '/wo/pymes':
      sessionStorage.setItem('trainingName', 'Crédito PyME');
      break;
    default:
      break;
  }
};

const getFullName = (obj) => {
  const FirstName = sessionStorage.getItem('temp-FirstName')
    || sessionStorage.getItem('FirstName')
    || (obj ? obj.FirstName : '');
  const MiddleName = sessionStorage.getItem('temp-MiddleName')
    || sessionStorage.getItem('MiddleName')
    || (obj ? obj.MiddleName : '');
  const FathersLastName = sessionStorage.getItem('temp-FathersLastName')
    || sessionStorage.getItem('FathersLastName')
    || (obj ? obj.FathersLastName : '');
  const MothersLastName = sessionStorage.getItem('temp-MothersLastName')
    || sessionStorage.getItem('MothersLastName')
    || (obj ? obj.MothersLastName : '');

  if (MiddleName) return `${FirstName} ${MiddleName} ${FathersLastName} ${MothersLastName}`;
  return `${FirstName} ${FathersLastName} ${MothersLastName}`;
};

export const viralityUTM = (
  insertDatVirality,
  URlTraining,
  wo,
  socialNetwork,
  idUserMaster = '',
  alfa = '',
  beta = '',
  fromXCoach = false,
  Modal = false,
  coruRewards = '',
  cellphone,
  name,
  email,
  operadorTel,
) => {
  let Training = '';
  if (sessionStorage.getItem('idTraining')) {
    Training = sessionStorage.getItem('idTraining') || '35';
  } else {
    Training = sessionStorage.getItem('BannerRewards') || 'BannerRewards_Entrenamientos';
  }
  const idUser = fromXCoach
    ? sessionStorage.getItem('idUserToSearchAsesores') || ''
    : sessionStorage.getItem('idUser') || '';
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  const fecha = `${yyyy}-${mm}-${dd}`;
  insertDatVirality({
    variables: {
      input: {
        Wo: wo,
        idUser,
        socialNetwork,
        section: Modal ? 'compartir_typ' : 'Compartir_Final',
        alfa,
        Beta: beta,
        date: fecha,
        urlTraining: URlTraining,
        CatUserFriendIdUserMaster: idUserMaster !== '' ? idUserMaster : '',
        fromXCoach,
        coruRewards,
        cellphone,
        name,
        email,
        idTraining: Training,
        operadorTel,
        fullName: getFullName(),
      },
    },
  });
};
