/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  QuestionsDivs,
  CardsBtn,
  TrainingQuestion,
  TrainingDivSection,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';

const DatosLaborales = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [iduser] = useState(sessionStorage.getItem('idUser') || '');
  const [idcont] = useState(sessionStorage.getItem('idCont') || '');
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      iduser !== '' &&
      idcont !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [Ocupacion, setOcupacion] = useState(
    sessionStorage.getItem('Ocupacion') || '',
  );
  const [Industria, setIndustria] = useState(
    sessionStorage.getItem('Tipo_de_Industria') || '',
  );
  const [Empresa, setEmpresa] = useState(
    sessionStorage.getItem('Nombre_de_la_empresa') || '',
  );
  const [TimeTrabajo, setTimeTrabajo] = useState(
    sessionStorage.getItem('Tiempo_de_laborar_en_la_empresa_en_años') || '',
  );

  // modal banks

  const [form, setForm] = useState({
    empresa: sessionStorage.getItem('Nombre_de_la_empresa') || '',
  });
  const [texterror] = useState({
    empresa: '',
  });

  const [error] = useState({
    empresa: false,
  });
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'empresa':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
      default:
        break;
    }
  };

  const ValidLetras = (nombre, valor, id) => {
    const path = window.location.pathname;
    let element = '';
    if (path.includes('funnel-prestamos')) {
      element = document.getElementsByName('empresa')[1];
    } else if (path.includes('obtener-primer-tarjeta')) {
      element = document.getElementsByName('empresa')[0];
    } else if (path.includes('comparar-tarjeta')) {
      element = document.getElementsByName('empresa')[0];
    } else {
      element = document.getElementById(id);
    }
    if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'secondname') {
      error[nombre] = false;
      texterror[nombre] = '';
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setOcupacion(val.value);
        setEditOcupacion(true);
        setTimeout(() => {
          gsap.to(window, { duration: 1, scrollTo: '#activeIndustria' });
        }, 1000);
        break;
      case 1:
        setIndustria(val.value);
        setEditIndustria(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeEmpresaT' });
        break;
      case 3:
        setTimeTrabajo(val.value);
        setEditTiempoLab(true);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        // console.log('default break handleChangeCatOption case 2');
        break;
    }
  };

  const handleChangeNombreEmpresa = () => {
    if (sessionStorage.getItem('idTraining') === '35') {
      const clickEventCC = `Nombre_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
    }
    const click = `ContinuarNombreEmpresa_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setEditNameEmp(true);
    setEmpresa(form.empresa);
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: '#activeAniosEmpresa' });
    }, 1300);
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setOcupacion(sessionStorage.getItem('Ocupacion') || '');
      setIndustria(sessionStorage.getItem('Tipo_de_Industria') || '');
      setEmpresa(sessionStorage.getItem('Nombre_de_la_empresa') || '');
      setTimeTrabajo(
        sessionStorage.getItem('Tiempo_de_laborar_en_la_empresa_en_años') || '',
      );
      setForm({
        ...form,
        empresa: sessionStorage.getItem('Nombre_de_la_empresa') || '',
      });
      if (
        sessionStorage.getItem('Ocupacion')
        && sessionStorage.getItem('Tipo_de_Industria')
        && sessionStorage.getItem('Nombre_de_la_empresa')
        && sessionStorage.getItem('Tiempo_de_laborar_en_la_empresa_en_años')
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editOcupacion, setEditOcupacion] = useState('');
  const [editTIndustria, setEditIndustria] = useState('');
  const [editTiempoLab, setEditTiempoLab] = useState('');
  const [editNameEmp, setEditNameEmp] = useState('');
  const handleChangeIcon = (json) => {
    setEditOcupacion(false);
    setEditIndustria(false);
    setEditTiempoLab(false);
    setEditNameEmp(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      /* gsap.to(window, { duration: 1, scrollTo: '#activeOcupacion' }); */
    }, 6000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    return (
      <>
        {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
          <>
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=' ' className='cardPrincipal1'>
                <LoadingWo />
              </Col7>
            </Container>
          </>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
            <ImageCoachInicio />
            <Col7 padding=' ' className='cardPrincipal1'>
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </>
    );
  }
  return (
    <>
      {!loaderWait ? (
        <Fragment>
          <div id="activeOcupacion"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || Ocupacion !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || Ocupacion !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            textbold="¡Falta poco! Cuéntanos sobre tu ocupación."
            text="¿Cuál es tu ocupación?"
            hora={hora}
          />
          <TrainingQuestion
            className={
              (Ocupacion === ''
                || (lapiz[0].routine === 10 && lapiz[0].active === 1))
              && editOcupacion !== true
                ? ''
                : 'hidden'
            }
          >
            {orderRutine === currentRoutine ? (
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            ) : null}
          </TrainingQuestion>
        </Fragment>
      ) : (
          <>
            {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
              <>
                <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
                  <ImageCoachInicio />
                  <Col7 padding=' ' className='cardPrincipal1'>
                    <LoadingWo />
                  </Col7>
                </Container>
              </>
            ) : (
              <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
                <ImageCoachInicio />
                <Col7 padding=' ' className='cardPrincipal1'>
                  <LoadingWo />
                </Col7>
              </Container>
            )}
          </>
      )}
      <div id="activeIndustria"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            Ocupacion !== ''
            && (Ocupacion === 'Ama de Casa' || Industria === '')
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 10, active: 1 })}
        />
        <CardTwo
          classCard={
            Ocupacion !== ''
            && (editOcupacion === true
              || !(lapiz[0].routine === 10 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={Ocupacion}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {(() => {
        if (
          Ocupacion === 'Ama de Casa'
          && (editOcupacion === true
            || !(lapiz[0].routine === 10 && lapiz[0].active === 1))
        ) {
          if (orderRutine === currentRoutine) {
            setOrderRutine(nextOrderRoutine);
          }
        }
      })()}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Ocupacion !== ''
          && Ocupacion !== 'Ama de Casa'
          && (editOcupacion === true
            || !(lapiz[0].routine === 10 && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿En qué tipo de industria te desempeñas?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((Ocupacion !== ''
            && Ocupacion !== 'Ama de Casa'
            && Industria === '')
            || (lapiz[0].routine === 10 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 10 && lapiz[0].active === 1)
            || editOcupacion === true)
          && editTIndustria !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeEmpresaT"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            Industria !== '' && Ocupacion !== 'Ama de Casa' && Empresa === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 10, active: 2 })}
        />
        <CardTwo
          classCard={
            Industria !== ''
            && Ocupacion !== 'Ama de Casa'
            && (editTIndustria === true
              || !(lapiz[0].routine === 10 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={Industria}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Industria !== ''
          && Ocupacion !== 'Ama de Casa'
          && (editTIndustria === true
            || !(lapiz[0].routine === 10 && lapiz[0].active === 2))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿En qué empresa trabajas?"
        hora={hora}
      />
      <Container
        width="80%"
        mt="0"
        widthMobile="90%"
        mtMobile="0"
        displayweb={
          ((Industria !== ''
            && Ocupacion !== 'Ama de Casa'
            && Empresa === '')
            || (lapiz[0].routine === 10 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 10 && lapiz[0].active === 2)
            || editTIndustria === true)
          && editNameEmp !== true
            ? 'flex'
            : 'none'
        }
        displaymobile={
          ((Industria !== ''
            && Ocupacion !== 'Ama de Casa'
            && Empresa === '')
            || (lapiz[0].routine === 10 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 10 && lapiz[0].active === 2)
            || editTIndustria === true)
          && editNameEmp !== true
            ? 'flex'
            : 'none'
        }
      >
        <ImageCoachInicio
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          topMobile="35px"
        />
        <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            mtw="8%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="100%"
            he="auto"
            className="topOne1 topOne1Init"
          >
            <TrainingQuestion
              width="100%"
              wiw="100%"
              mt="0"
              className={
                ((Industria !== ''
                  && Ocupacion !== 'Ama de Casa'
                  && Empresa === '')
                  || (lapiz[0].routine === 10 && lapiz[0].active === 3))
                && (!(lapiz[0].routine === 10 && lapiz[0].active === 2)
                  || editTIndustria === true)
                && editNameEmp !== true
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsDivs
                padd="0"
                txta="left"
                width="100%"
                widthweb="100%"
                hei="auto"
                margin="0 auto auto"
                bc="#F3F3F3"
              >
                <form>
                  {orderRutine === currentRoutine ? (
                    <InputsOrder
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step="2-2"
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      handleChange={handleChange}
                    />
                  ) : null}
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    onClick={() => handleChangeNombreEmpresa()}
                  >
                    Continuar
                  </CardsBtn>
                </form>
              </QuestionsDivs>
            </TrainingQuestion>
          </TrainingDivSection>
        </Col7>
      </Container>
      <div id="activeAniosEmpresa"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            Empresa !== '' && Ocupacion !== 'Ama de Casa' && TimeTrabajo === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 10, active: 3 })}
        />
        <CardTwo
          classCard={
            Empresa !== ''
            && Ocupacion !== 'Ama de Casa'
            && (editNameEmp === true
              || !(lapiz[0].routine === 10 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={form.empresa}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Empresa !== ''
          && Ocupacion !== 'Ama de Casa'
          && (editNameEmp === true
            || !(lapiz[0].routine === 10 && lapiz[0].active === 3))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuántos años tienes trabajando en esa empresa? 🏢"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((Empresa !== ''
            && Ocupacion !== 'Ama de Casa'
            && TimeTrabajo === '')
            || (lapiz[0].routine === 10 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 10 && lapiz[0].active === 3)
            || editNameEmp === true)
          && editTiempoLab !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="3-3"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            TimeTrabajo !== ''
            && Ocupacion !== 'Ama de Casa'
            && (editTiempoLab === true
              || !(lapiz[0].routine === 10 && lapiz[0].active === 4))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 10, active: 4 })}
        />
        <CardTwo
          classCard={
            TimeTrabajo !== ''
            && Ocupacion !== 'Ama de Casa'
            && (editTiempoLab === true
              || !(lapiz[0].routine === 10 && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={`${TimeTrabajo} años`}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </>
  );
};

export default DatosLaborales;
