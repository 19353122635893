/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const QuestionsExtraIncome = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setTipo,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [primeraPregunta, setPrimeraPregunta] = useState(
    sessionStorage.getItem('PrimeraPregunta') || '',
  );
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editDescripcion, setEditDescripcion] = useState('');

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }

    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const validateAnswer = (value) => {
    switch (value) {
      case 'Me gusta manejar':
        nextOrderRoutine = 4;
        break;
      case 'Me gusta hacer ejercicio':
        nextOrderRoutine = 5;
        break;
      case 'Me gusta convivir con personas y vender cosas':
        nextOrderRoutine = 6;
        break;
      case 'Me gusta navegar en internet':
        nextOrderRoutine = 7;
        break;
      case 'Me gusta estar en casa':
        nextOrderRoutine = 8;
        break;
      case 'Me gusta sacar provecho de mis conocimientos o adquirir nuevos':
        nextOrderRoutine = 9;
        break;
      case 'Me gusta leer':
        nextOrderRoutine = 10;
        break;
      case 'Tengo una habitación o inmueble que no utilizo':
        nextOrderRoutine = 11;
        break;
      default:
        console.log('default');
        break;
    }
    setTipo(value);
    setOrderRutine(nextOrderRoutine);
  };
  const handleChangeCatOption = (val) => {
    setEditDescripcion(true);
    setPrimeraPregunta(val.value);
    validateAnswer(val.value);
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          // setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];

              if (responseUser && responseUser !== '') {
                setPrimeraPregunta(responseUser);
                validateAnswer(responseUser);
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setQuestions([]);
              break;
            } else {
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);

  const handleChangeIcon = (json) => {
    setEditDescripcion(false);
    setExtraIncomeProducts(null);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  const anchop = window.screen.width;
  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      setTimeout(() => {
        if (primeraPregunta === '') {
          gsap.to(window, {
            duration: 1,
            scrollTo: '#activeQuestionsExtraIncome',
          });
        }
      }, 1200);
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 3 ? (
        <>
          <div id="activeQuestionsExtraIncome"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (primeraPregunta === ''
                || (lapiz[0].routine === 3 && lapiz[0].active === 1))
              && editDescripcion !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                primeraPregunta !== '' && !extraIncomeProducts
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 3, active: 1 })}
            />
            <CardTwo
              classCard={
                primeraPregunta !== ''
                && (editDescripcion === true
                  || !(lapiz[0].routine === 3 && lapiz[0].active === 1))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={primeraPregunta}
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </Fragment>
  );
};

export default QuestionsExtraIncome;
