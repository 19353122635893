import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import GraphicPercent from '../../components/Training/GraphicInvestTest';

import { Image } from '../../components/common/common.styled';
import {
  TrainingSubtitles,
  CardsBtn,
  TrainingDivSection,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import { Container } from '../../assets/styles/Home.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import {
  Feed_Main,
  Feed_Section,
  // ImageSticker,
} from '../../assets/styles/Feed.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import CardOne from '../../components/Home/Card1Animate';
import Featuresxs from '../../components/Training/Featuresxs';
import {
  DYNAMIC_INSERT,
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
  SET_TRAINING_COMPLETE,
} from '../../mutations';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ClickButton from '../../Helpers/HookHelpers';
import RpProviders from '../../components/common/RpProviders';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';

let datum = [];
const RpInvestmentTestProfile = ({ sumas, profile, products }) => {
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('INSERCION EXITOSA!');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [setTrainingComplete] = useMutation(SET_TRAINING_COMPLETE);

  const { actions } = ClickButton();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  useEffect(() => {
    setTimeout(() => {
    }, 1000);
  }, []);
  useEffect(() => {
    const suma = sumas.suma_1 + sumas.suma_2 + sumas.suma_3;
    const sum_p1 = Math.round((sumas.suma_1 * 100) / suma);
    const sum_p2 = Math.round((sumas.suma_2 * 100) / suma);
    const sum_p3 = Math.round((sumas.suma_3 * 100) / suma);
    datum = [
      {
        key: 'Paciencia',
        y: sum_p1 < 70 ? sum_p1 + 25 : sum_p1,
        color: '#91D770',
      },
      {
        key: 'Interacción',
        y: sum_p2 < 70 ? sum_p2 + 25 : sum_p2,
        color: '#F64282',
      },
      {
        key: 'Amor al riesgo',
        y: sum_p3 < 70 ? sum_p3 + 25 : sum_p3,
        color: '#6BD5AF',
      },
    ];

    setstate({
      termPac: sum_p1 < 70 ? sum_p1 + 25 : `${sum_p1}%`,
      termInterac: sum_p2 < 70 ? sum_p2 + 25 : `${sum_p2}%`,
      termLove: sum_p3 < 70 ? sum_p3 + 25 : `${sum_p3}%`,
    });
    dynamicInsert({
      variables: {
        input: {
          table: 'CatScoreTrainingProfile',
          columns: [
            'CatUsers_IDUser',
            'CatTraining_IDTraining',
            'Score',
            'Score1',
            'Score2',
            'Score3',
            'CatUserClasificationProfile_IDCatUserClasification',
          ],
          detailValues: {
            values: [
              sessionStorage.getItem('idUser'),
              sessionStorage.getItem('idTraining'),
              String(suma),
              String(sumas.suma_1),
              String(sumas.suma_2),
              String(sumas.suma_3),
              String(profile[0].idCatUserClasificationProfile),
            ],
          },
        },
      },
    });
  }, []);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [state, setstate] = useState({
    termPac: '',
    termInterac: '',
    termLove: '',
  });
  const [goFeed] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  // const completeTraining = () => {};

  const handleContinue = async () => {
    const click = `ContinuarAnalisis_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setVisibleProfile(true);
    await setTrainingComplete({
      variables: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(localStorage.getItem('idTraining') || '23'),
      },
    });
    gsap.to(window, { duration: 1, scrollTo: '#activeCaracteristicas' });
  };

  useEffect(() => {
    if (visibleProfile) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [visibleProfile]);
  const anchop = window.screen.width;
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeRPInvestmentTest' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);
  const feacturesArray = useState([
    {
      text: profile[0].Description || '',
      color: '#91D770',
    },
    {
      text: profile[1].Description || '',
      color: '#91D770',
    },
    {
      text: profile[2].Description || '',
      color: '#91D770',
    },
  ]);
  return (
    <div>
      <div id="activeRPInvestmentTest"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
      />
      <TrainingDivSection
        mlw="18%"
        bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
        mt="44px"
        mtw="20px"
        br="4px"
        padding="21px 20px"
        wi="90%"
        wiw="60%"
        he="100%"
        hew="100%"
        mb="6px"
      >
        <TrainingSubtitles
          size="16px"
          fw="400"
          align="left"
          alignweb="left"
          style={{ lineHeight: '24px', fontFamily: 'sans-serif' }}
        >
          Con tus respuestas anteriores analicé que tienes un perfil:
        </TrainingSubtitles>
        <p
          style={{
            textAlign: 'center',
            fontWeight: '700',
            fontSize: '16px',
            marginTop: '50px',
          }}
        >
          {profile[0].Clasification || ''}
        </p>
        <br />
        <Container>
          {datum.map((post) => (
            <GraphicPercent datum={post}></GraphicPercent>
          ))}
        </Container>
        <br />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                background: '#91D770',
                width: '25px',
                height: '25px',
                marginRight: '-40px',
                borderRadius: '4px',
              }}
            ></div>
            <p>Termómetro de paciencia</p>
            <p>
              <b>{state.termPac}</b>
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                background: '#F64282',
                width: '25px',
                height: '25px',
                marginRight: '-30px',
                borderRadius: '4px',
              }}
            ></div>
            <p>Termómetro de interacción</p>
            <p>
              <b>{state.termInterac}</b>
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                background: '#6BD5AF',
                width: '25px',
                height: '25px',
                marginRight: '-10px',
                borderRadius: '4px',
              }}
            ></div>
            <p>Termómetro de amor al riesgo</p>
            <p>
              <b>{state.termLove}</b>
            </p>
          </div>
        </div>
        <div id="activeCaracteristicas"></div>
        <CardsBtn
          type="button"
          min_w="100%"
          min_web="100%"
          mtop="20px"
          mtopw="40px"
          onClick={() => handleContinue()}
        >
          Continuar
        </CardsBtn>
        {visibleProfile ? (
          <Fragment>
            <p
              style={{
                textAlign: 'center',
                marginTop: '100px',
                fontSize: '16px',
                lineHeight: '150%',
                letterSpacing: '-0.2px',
              }}
            >
              Tus cualidades de inversión son parecidas a las de{' '}
              <b>{profile[0].Profile || ''}</b>
            </p>
            <br />
            <Image
              src={profile[0].LinkImageProfile || ''}
              mwidth="70%"
              width="40%"
              margin="auto"
              top="110px"
              left="10%"
              mleft="10px"
              mtop="75px"
              displayMobile="flex"
              displayWeb="flex"
            />
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '150%',
                textAlign: 'center',
                letterSpacing: '-0.2px',
                marginTop: '30px',
              }}
            >
              Te caracterizas por:
            </p>
            <Featuresxs
              classAna="falseAna"
              title=""
              Arraytext={feacturesArray}
            />
          </Fragment>
        ) : null}
      </TrainingDivSection>

      {visibleProfile ? (
        <Fragment>
          <ModalAmazon show={true} wo={'ITest'}></ModalAmazon>
          <RpProviders products={products} />
           
            <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '23' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
          <ModalVirality />
          <SectionShare WO="ITest"></SectionShare>
        </Fragment>
      ) : null}
      {recomendaciones && visibleProfile ? (
        <Feed_Main width="100%" background=" ">
          <Feed_Main width="100%" display=" " mtop=" " background="transparent">
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="¿Aún quieres ir por más?"
              text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
            />
            <div className="marginComentLgSuccess1">
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
             
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </div>
          </Feed_Main>
        </Feed_Main>
      ) : null}
    </div>
  );
};

export default RpInvestmentTestProfile;
