/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';

// Import visual components
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn1,
  QuestionsDivs,
  QuestionsText,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_SUGGESTIONS_WO_IMPROVE_BUDGET,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import { CREATE_SESSION, UPDATE_HISTORY_COLLECTION } from '../../mutations';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import ClickButton from '../../Helpers/HookHelpers';

const CuestionarioInversion = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [videoMejoraPresupuesto, setVideoMejoraPresupuesto] = useState(false);
  const [comienzoPlanMejora, setComienzoPlanMejora] = useState(
    sessionStorage.getItem('ComienzoPlanMejora') || '',
  );
  const [notificacionReminder, setNotificacionReminder] = useState(
    sessionStorage.getItem('NotificacionReminder') || '',
  );
  const [sugerenciaMejoraPresupuesto, setSugerenciaMejoraPresupuesto] = useState(sessionStorage.getItem('SugerenciaMejoraPresupuesto') || '');

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const { actions } = ClickButton();
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [suggestionsList, setSuggestionsList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsQuestions, setSuggestionsQuestions] = useState([]);
  const [improvementSuggestion, setImprovementSuggestion] = useState([]);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = ({ value, step }) => {
    if (step === 2) {
      setNotificacionReminder(
        sessionStorage.getItem('NotificacionReminder') || value,
      );
      gsap.to(window, {
        duration: 1,
        scrollTo: '#activeCuestionarioInversion4',
      });
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeSuggestions = (opc) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ChangeSuggestion',
      },
    };
    sessionStorage.setItem('SugerenciaMejoraPresupuesto', opc);
    TagManager.dataLayer(tagManagerArgs);
    setSugerenciaMejoraPresupuesto(opc);
    setSuggestionsList(suggestions[opc].map((e) => e.Suggestion));
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getSuggestionsWOImproveBudget] = useLazyQuery(
    GET_SUGGESTIONS_WO_IMPROVE_BUDGET,
    {
      onCompleted({ getSuggestionsWOImproveBudget }) {
        try {
          const json = JSON.parse(getSuggestionsWOImproveBudget.response);
          const { suggestions, improvementSuggestions } = json[0];
          setSuggestions(suggestions);
          setSuggestionsQuestions(Object.keys(suggestions));
          if (improvementSuggestions.length > 0) {
            setImprovementSuggestion(improvementSuggestions);
            sessionStorage.setItem(
              'ComienzoPlanMejora',
              improvementSuggestions[0].Recomendations,
            );
            setComienzoPlanMejora(sessionStorage.getItem('ComienzoPlanMejora'));
          }
          if (sugerenciaMejoraPresupuesto) {
            setSuggestionsList(
              suggestions[sugerenciaMejoraPresupuesto].map((e) => e.Suggestion),
            );
          }
        } catch (error) {
          console.log(error);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  useEffect(() => {
    getSuggestionsWOImproveBudget({
      variables: {
        idUser: sessionStorage.getItem('idUser'),
        idTrainig: currentTraining,
      },
    });
  }, []);
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setComienzoPlanMejora(sessionStorage.getItem('ComienzoPlanMejora') || '');
      setNotificacionReminder(
        sessionStorage.getItem('NotificacionReminder') || '',
      );
      setSugerenciaMejoraPresupuesto(
        sessionStorage.getItem('SugerenciaMejoraPresupuesto') || '',
      );
    }
  }, [recoveryRoutine]);
  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickVideoCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const playVideo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickVideoYT',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickVideo')) {
      const dateJson = [
        {
          nameTraining: sessionStorage.getItem('trainingName'),
          idUser: sessionStorage.getItem('idUser'),
          action,
        },
      ];
      const dateString = JSON.stringify(dateJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem('idClickVideo'),
      });
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          key: 'ClickVideo',
          data: dateString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dateJson = {
        idUser: sessionStorage.getItem('idUser'),
        idSession: sessionStorage.getItem('id_session'),
        ClickVideo: [
          {
            nameTraining: sessionStorage.getItem('trainingName'),
            idUser: sessionStorage.getItem('idUser'),
            action,
          },
        ],
      };
      const dateString = JSON.stringify(dateJson);
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          data: dateString,
        },
      };
      createClickVideoCollection({ variables: mongoInput });
    }
  };

  const viewVideoMejoraPresupuesto = (e) => {
    const click = `ContinuarMejora_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    playVideo('Skip');
    e.preventDefault();
    // setBtnVideoMejoraPresupuesto(styleBtnDisabled);
    setVideoMejoraPresupuesto(true);
    sessionStorage.setItem('VideoMejoraPresupuesto', true);
    gsap.to(window, { duration: 1, scrollTo: '#activeCuestionarioInversion2' });
  };

  const setNextOrderRoutine = (e) => {
    const click = `Continuarsugerencia_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setOrderRutine(nextOrderRoutine);
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeCuestionarioInversion',
        });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);
  const anchop = window.screen.width;
  useEffect(() => {
    if (comienzoPlanMejora !== '') {
      gsap.to(window, {
        duration: 1,
        scrollTo: '#activeCuestionarioInversion3',
      });
    }
  }, [comienzoPlanMejora]);

  useEffect(() => {
    if (sugerenciaMejoraPresupuesto !== '') {
      gsap.to(window, {
        duration: 1,
        scrollTo: '#activeCuestionarioInversion5',
      });
    }
  }, [sugerenciaMejoraPresupuesto]);

  return (
    <>
      {/* <TrainingQuestion className={''}>
            <SectionVideo title="" iconArrow={false}>
               <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="0-0"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
               />
               <br />
            </SectionVideo>
         </TrainingQuestion> */}
      <div id="activeCuestionarioInversion"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        textbold="Análisis de mejora"
        text="Según el análisis de tus finanzas, puedes mejorar en:"
        hora={hora}
      />
      {improvementSuggestion.map((e, index) => (
        <>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            textbold={
              comienzoPlanMejora !== ''
                ? `${index + 1}. ${e.Recomendations}`
                : null
            }
            text2={comienzoPlanMejora !== '' ? e.Description : null}
            hora={hora}
          />
        </>
      ))}
      <div id="activeCuestionarioInversion2"></div>
      <TrainingQuestion className={''}>
        <CardsBtn1
          windthmd="100%"
          onClick={(e) => {
            viewVideoMejoraPresupuesto(e);
          }}
        >
          Continuar
        </CardsBtn1>
      </TrainingQuestion>
      {/* mejora */}
      {/* question */}
      <QuestionsOrder
        classCard={
          comienzoPlanMejora !== '' && videoMejoraPresupuesto
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          comienzoPlanMejora === '' && videoMejoraPresupuesto ? '' : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeCuestionarioInversion3"></div>
      <CardTwo
        classCard={
          comienzoPlanMejora !== '' && videoMejoraPresupuesto
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={comienzoPlanMejora}
        colorTraining={trainingColor}
      ></CardTwo>
      {/* ¿Te gustaría recibir notíficaciones de un reminder semanal? */}
      <QuestionsOrder
        classCard={
          comienzoPlanMejora !== '' && videoMejoraPresupuesto
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={2}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      {/* Pregunta Si  - No */}
      <TrainingQuestion
        className={
          notificacionReminder === '' && videoMejoraPresupuesto ? '' : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      {/* Respuesta Si - No */}
      <div id="activeCuestionarioInversion4"></div>
      <CardTwo
        classCard={
          notificacionReminder !== '' && videoMejoraPresupuesto
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={notificacionReminder}
        colorTraining={trainingColor}
      ></CardTwo>
      {/* Eligé una sugerencia de mejora */}
      <QuestionsOrder
        classCard={
          notificacionReminder !== '' && videoMejoraPresupuesto
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={3}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          notificacionReminder !== ''
          && sugerenciaMejoraPresupuesto === ''
          && videoMejoraPresupuesto
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          onClick={() => handleChangeSuggestions(suggestionsQuestions[0])}
          width="60%"
          marginL="18%"
          widthxs="100%"
          marginLmd="0px"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            {suggestionsQuestions[0]}
          </QuestionsText>
        </QuestionsDivs>
        <QuestionsDivs
          id="cuestionBus"
          onClick={() => handleChangeSuggestions(suggestionsQuestions[1])}
          width="60%"
          marginL="18%"
          widthxs="100%"
          marginLmd="0px"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            {suggestionsQuestions[1]}
          </QuestionsText>
        </QuestionsDivs>
        <QuestionsDivs
          onClick={() => handleChangeSuggestions(suggestionsQuestions[2])}
          width="60%"
          marginL="18%"
          widthxs="100%"
          marginLmd="0px"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            {suggestionsQuestions[2]}
          </QuestionsText>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeCuestionarioInversion5"></div>
      <CardTwo
        classCard={
          sugerenciaMejoraPresupuesto !== '' && videoMejoraPresupuesto
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={sugerenciaMejoraPresupuesto}
        colorTraining={trainingColor}
      ></CardTwo>

      {sugerenciaMejoraPresupuesto !== '' && videoMejoraPresupuesto ? (
        <div className="">
          <CardOne
            classCard={'cardOne'}
            classTop="topOne1"
            text={suggestionsList[0]}
            text2={suggestionsList[1]}
            text3={suggestionsList[2]}
            hora={hora}
          />
          <CardsBtn1
            mleftmd="5%"
            onClick={(e) => {
              setNextOrderRoutine(e);
            }}
          >
            Continuar
          </CardsBtn1>
        </div>
      ) : null}
    </>
  );
};

export default CuestionarioInversion;
