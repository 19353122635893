/* eslint-disable no-trailing-spaces */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { useMutation, useLazyQuery } from '@apollo/client';
import LPKanda from '../components/LpHomeCoru/LpHomeCoru';
import '../components/LpHomeCoru/Assets/styles/styles.css';
// import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import { CREATE_SESSION, CREATE_CLICK, DYNAMIC_GET, LOGLOGROS_GET_MONGO } from '../mutations';
import { GET_DAY_OF_WEEK } from '../queries';
import { getQueryVariableHashtagDeeplink } from '../Helpers';
import ClickButton from '../Helpers/HookHelpers';

const tagManagerArgs = {
  gtmId: 'GTM-NT59K8W',
  dataLayer: {
    pageName: 'home',
  },
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-53999647-2');
ReactGA.pageview(window.location.pathname + window.location.search);
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  // eslint-disable-next-line func-names
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    // eslint-disable-next-line func-names
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
function analyzeCookie() {
  let galletita = document.cookie;
  let source = '';
  let medium = '';
  let campaign = '';
  let variableEstado = '';
  let date = '';
  let content = '';
  let term = '';
  /* let idUser = '';
  let idCont = ''; */
  if (galletita.includes('ReturningSession')) {
    variableEstado = 'ReturningSession';
  } else if (galletita.includes('FirstSession')) {
    variableEstado = 'FirstSession';
  } else {
    variableEstado = 'noencontrada';
  }
  let elementos = galletita.split(';');
  let posicion = 0;
  for (const elem in elementos) {
    if (elementos[elem].includes(variableEstado)) {
      break;
    } else {
      posicion += 1;
    }
  }
  galletita = decodeURIComponent(elementos[posicion]).replace(
    `${variableEstado}=`,
    '',
  );
  elementos = galletita.split('&');
  for (const elem in elementos) {
    if (elementos[elem].includes('source=')) {
      source = elementos[elem].replace('source=', '');
    } else if (elementos[elem].includes('medium=')) {
      medium = elementos[elem].replace('medium=', '');
    } else if (elementos[elem].includes('campaign=')) {
      campaign = elementos[elem].replace('campaign=', '');
    } else if (elementos[elem].includes('term=')) {
      term = elementos[elem].replace('term=', '');
    } else if (elementos[elem].includes('content=')) {
      content = elementos[elem].replace('content=', '');
    } else if (elementos[elem].includes('date=')) {
      date = elementos[elem].replace('date=', '');
    }
  }

  source = source != null && source !== '' ? source : 'direct';
  medium = medium != null && medium !== '' ? medium : 'none';
  campaign = campaign != null && campaign !== '' ? campaign : 'direct';
  sessionStorage.setItem('source', source);
  sessionStorage.setItem('medium', medium);
  sessionStorage.setItem('campaign', campaign);
  sessionStorage.setItem('content', content);
  ReactGA.ga((tracker) => {
    const idGa = tracker.get('clientId');
    sessionStorage.setItem('idGa', idGa);
  });
}

// eslint-disable-next-line react/prop-types
function LpHome({ location }) {
  /* const [flag, setFlag] = useState(false);
  const [dataLoaded, setDataloaded] = useState(true); */
  // se mandan a traer las categorias para las tarjetas de entrenamientos
  // const [categories, setCategories] = useState([]);
  // const { data } = useQuery(GET_TRAINING_HOME_CATEGORIES);
  // useEffect(() => {
  //   if (data) {
  //     const trainings = JSON.parse(data.getTrainingCategories.response);
  //     setCategories(trainings);
  //   }
  // }, [data]);
  // se extraen las categorias de los entrenamientos
  // const extractCategories = (trainingArray) => {
  //   let counter = 1;
  //   const catgrys = trainingArray.filter((training) => {
  //     if (training.idCatCategory === counter) {
  //       counter++;
  //       return training;
  //     }
  //   });
  //   return catgrys;
  // };

  // const [selectedCatego, setSelectedCatego] = useState(0);

  // useEffect(() => {
  //   const variable = parseInt(sessionStorage.getItem('categorieActive'));
  //   if (variable) {
  //     setSelectedCatego(variable);
  //   }
  // });

  const { actions } = ClickButton();
  // eslint-disable-next-line no-console
  const servertype = process.env.REACT_APP_SERVERTYPE;
  // eslint-disable-next-line no-console
  const [MorePrensa, setMorePrensa] = useState(false);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  let seccion = '';
  /* let idUser = '';
  let idCont = ''; */
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
    analyzeCookie();
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    seccion = params.get('utm_seccion') || null;
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
    /* sessionStorage.setItem('idUser', idUser);
    sessionStorage.setItem('idCont', idCont); */
    // utm_seccion sólo se ocupa para hacer scroll Home
    if (seccion !== 'null' && seccion !== null && seccion !== '') {
      sessionStorage.setItem('utm_seccion', `#${seccion}`);
    }
  }
  sessionStorage.setItem('showModal', false);
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        // eslint-disable-next-line no-console
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err);
    },
  });

  const handleClickMorePrensa = (estado) => {
    setMorePrensa(estado);
  };
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted() {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });
  const history = useHistory();

  // const [scrollY, setScrollY] = useState(window.scrollY);
  // eslint-disable-next-line no-underscore-dangle
  const _useTagManager = (seccion, socialNetwork) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const tagManagerArgs = {
      dataLayer: {
        event: `clickShare${seccion}${socialNetwork}`,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const createSocialMediaShare = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: `clickShare${seccion}${socialNetwork}`,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    createClickFront({ variables: createSocialMediaShare });
  };
  function shareCoru(seccion, socialNetwork, message, Shortenedlink) {
    _useTagManager(seccion, socialNetwork);
    let urlshortened = 'https://dev-rediseno.coru.mx';
    let coruRedirectURL;
    const urldevelop = 'https://dev-rediseno.coru.mx';
    const urlprod = 'https://coru.com';
    if (servertype === 'Development') {
      urlshortened = urldevelop;
    } else if (servertype === 'Production') {
      urlshortened = urlprod;
    }
    switch (socialNetwork) {
      case 'Messenger':
        // It seems that custom message is no longer suported by the facebook API
        // if you find a way to implement it, please do it.
        coruRedirectURL = `${urlshortened}/shorter?id=${Shortenedlink}`;
        // eslint-disable-next-line no-case-declarations
        const url = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruRedirectURL}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}`;
        actions({ action: 'ClickButtonCoruHome', variables: `${seccion}Messenger` });
        window.open(url);
        break;
      case 'MessengerMob':
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        actions({ action: 'ClickButtonCoruHome', variables: `${seccion}MessengerMob` });
        window.open(
          `fb-messenger://share/?link=${coruRedirectURL}&app_id=248019326476226`,
        );
        break;
      case 'Facebook':
        coruRedirectURL = `${urlshortened}/shorter?id=${Shortenedlink}`;
        actions({ action: 'ClickButtonCoruHome', variables: `${seccion}Facebook` });
        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruRedirectURL,
          )}&redirect_uri=${encodeURIComponent(
            'https://www.facebook.com/',
          )}&display=popup`,
        );
        break;
      case 'Whatsapp':
        coruRedirectURL = `${urlshortened}/shorter?id=${Shortenedlink}`;
        actions({ action: 'ClickButtonCoruHome', variables: `${seccion}Whatsapp` });
        // eslint-disable-next-line no-case-declarations
        const whatsappMessage = `${message} ${coruRedirectURL}`;
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`,
          'popup',
          'width=600',
          'height=600',
        );
        break;
      case 'Copy':
        actions({ action: 'ClickButtonCoruHome', variables: 'Copy' });
        coruRedirectURL = 'http://coru.com/shorter?id=f26eaeGcF3Zzvi7';
        // eslint-disable-next-line no-case-declarations
        const el = document.createElement('input');
        el.value = coruRedirectURL;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        // handleOpen();
        break;
      default:
        break;
    }
  }
  // Modal Test de Viralidad
  const [openTestViralidad, setOpenTestViralidad] = useState(
    sessionStorage.getItem('dayOfWeek') === 'Hola',
  );

  const [getDayOfWeek] = useLazyQuery(GET_DAY_OF_WEEK, {
    onCompleted({ getDayOfWeek }) {
      const { today } = getDayOfWeek;
      sessionStorage.setItem('dayOfWeek', today);
      setOpenTestViralidad(today === 'Hola');
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    getDayOfWeek();
  }, []);

  useEffect(() => {
    ReactGA.ga((tracker) => {
      const idga = tracker.get('clientId');
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const dataJson = {
        idga,
        campaign: sessionStorage.getItem('campaign'),
        medium: sessionStorage.getItem('medium'),
        source: sessionStorage.getItem('source'),
        idUser: sessionStorage.get('idUser'),
        idCont: sessionStorage.getItem('idCont'),
        timestamp: today,
        content: sessionStorage.getItem('content'),
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        createSession({ variables: mongoInput });
      }
    });
    // const handleScroll = () => setScrollY(window.scrollY);
    // window.addEventListener('scroll', debounce(handleScroll));
    // return () => window.removeEventListener('scroll', debounce(handleScroll));
  }, [campaign, createSession, medio, source]);
  sessionStorage.setItem('clickEmpezarAhoraHome', false);

  // ARIANA obtener datos del usuario
  const [createLog] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("id_logLogros", dynamicMongoSession.response);
      }
    },
  });

  const [GetLog] = useMutation(LOGLOGROS_GET_MONGO, {
    onCompleted({ LogLogrosGet }) {
      if (LogLogrosGet.response !== null) {
        sessionStorage.setItem("id_logLogros", LogLogrosGet.response);
      } else {
        const mongoInputBinacle = {
          idCont: sessionStorage.getItem("idCont"),
          idUser: sessionStorage.getItem("idUser"),
          FirstName: sessionStorage.getItem("FirstName"),
          MiddleName: sessionStorage.getItem("MiddleName"),
          FathersLastName: sessionStorage.getItem("FathersLastName"),
          MothersLastName: sessionStorage.getItem("MothersLastName"),
          email: sessionStorage.getItem("Email1"),
        };
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        mongoInputBinacle.timestampEC = [
          {
            timeStamp: today,
            idCatRelAction: sessionStorage.getItem("idCatRelAction"),
          },
        ];
        const dataStringBinacle = JSON.stringify(mongoInputBinacle);
        const mongoInput = {
          input: {
            collection: "LogLogros",
            data: dataStringBinacle,
          },
        };
        createLog({ variables: mongoInput });
      }
    },
  });

  useEffect(() => {
    const { idUser, idCont } = getQueryVariableHashtagDeeplink("profile");
    if (idUser && idUser !== "" && idCont && idCont !== "") {
      sessionStorage.setItem("idUser", idUser);
      sessionStorage.setItem("idCont", idCont);
      if (!sessionStorage.getItem("id_logLogros")) {
        const mongoInput = {
          input: {
            collection: "LogLogros",
            filter: sessionStorage.getItem("idCont"),
          },
        };
        GetLog({ variables: mongoInput });
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useEffect(() => {
    const IdUser = getQueryVariableHashtagDeeplink('idUser');
    const idCont = getQueryVariableHashtagDeeplink('idCont');
    if (IdUser && idCont) {
      const inputMongo = {
        collection: 'LogDeeplinkCatalogoProductos',
        data: JSON.stringify({
          IdUser,
          idCont,
        }),
      };
      GetLog({
        variables: { input: inputMongo },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getUser] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        // eslint-disable-next-line no-shadow
        const userDetail = JSON.parse(dynamicGet.response)[0];
        if (userDetail) {
          sessionStorage.setItem('CellPhone', userDetail.CellPhone || '');
          sessionStorage.setItem('email', userDetail.Email1);
          sessionStorage.setItem('landline', userDetail.HomePhone || '');
          sessionStorage.setItem('firstName', userDetail.FirstName || '');
          sessionStorage.setItem('RFC', userDetail.RFC || '');
          sessionStorage.setItem('middleName', userDetail.MiddleName || '');
          sessionStorage.setItem(
            'fathersLastName',
            userDetail.FathersLastName || '',
          );
          sessionStorage.setItem(
            'mothersLastName',
            userDetail.MothersLastName || '',
          );
          sessionStorage.setItem('BirthDate', userDetail.BirthDate || '');
          sessionStorage.setItem('Gender', userDetail.Gender || '');
          sessionStorage.setItem('MaritalStatus', userDetail.MaritalStatus || '');
          sessionStorage.setItem('BirthState', userDetail.BirthState || '');
          sessionStorage.setItem('CaptureDate', userDetail.CaptureDate || '');
          sessionStorage.setItem('EntityType', userDetail.EntityType || '');
          sessionStorage.setItem('DataPerson', true);
          if (
            sessionStorage.getItem('BirthDate') !== ''
            && sessionStorage.getItem('BirthDate') != null
          ) {
            const BirthDate = new Date(sessionStorage.getItem('BirthDate'));
            sessionStorage.setItem(
              'day',
              BirthDate.getDate() > 9
                ? BirthDate.getDate()
                : `0${BirthDate.getDate()}`,
            );
            sessionStorage.setItem(
              'month',
              BirthDate.getMonth() > 8
                ? BirthDate.getMonth() + 1
                : `0${BirthDate.getMonth() + 1}`,
            );
            sessionStorage.setItem('year', BirthDate.getFullYear());
          }
          // setDataloaded(true);
        } else {
          /* window.location.href = '/'; */
        }
      }
    },
  });

  useEffect(() => {
    const GetUserInput = {
      input: {
        table: 'CatContactMedium, CatContact',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDCont',
            condition: '=',
            valtwo: sessionStorage.getItem('idCont'),
          },
          {
            logic: 'AND',
            valone: 'IDCont',
            condition: '=',
            valtwo: 'CatContact_IDCont',
            validColumn: true,
          },
        ],
      },
    };
    getUser({ variables: GetUserInput });
  }, []);
  
  const initializeNow = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickEmpezarAhoraHome', true);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickEmpezarAhoraHome',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: 'clickEmpezarAhoraHome',
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    createClickFront({ variables: createClickInput });
    actions({ action: 'ClickButtonCollection', variables: name });
    sessionStorage.setItem('focusheader', '');
    actions({ action: 'ClickButtonCoruHome', variables: `Home${name}`, redirection: '/user-validation/' });
    // history.push('/user-validation/');
  };

  const initializeLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickIniciarSesion', true);
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickLogin',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      },
    });
    actions({ action: 'ClickButtonCollection', variables: 'Login' });
    // history.push('/login');
  };

  return (
    <>
      <LPKanda
        initializeNow={initializeNow}
        initializeLogin={initializeLogin}
        shareCoru={shareCoru}
      />
    </>
  );
}

export default LpHome;
