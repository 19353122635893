/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DivLoader,
  Images,
  TrainingQuestion,
  TrainingText,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// import loader from '../../assets/img/anacoach/loading.svg';
// Import query components and helpers
import {
  GET_PRODUCTS_BY_IDTRAINING_AND_NAME,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const Manejar = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  const productNames = [
    'Nesko',
    'Easy Taxi',
    'Jetty',
    'Urbvan',
    'Uber conecta',
    'Uber',
    'DiDi',
    'Cabify',
    'Beat -',
    'Avant',
    'Bolt',
    'In Driver',
    'KAWI',
    'Ride App',
    'Pooly',
    'BlaBlaCar',
    'Waze Carpool',
    'Uber Eats',
    'Rappi',
    'DiDi Food',
    'SinDelantal',
  ];

  const [productsData, setProductsData] = useState([]);

  const {
    loading,
    error,
    data: prodData,
  } = useQuery(GET_PRODUCTS_BY_IDTRAINING_AND_NAME, {
    variables: {
      idTraining: parseInt(currentTraining),
      names: productNames,
    },
    onCompleted({ getProductsByIdTrainingAndName }) {
      const products = JSON.parse(getProductsByIdTrainingAndName.response);
      setProductsData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  if (loading) {
    return (
      <div>
        <DivLoader
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <Images
            src={''}
            top="-32px"
            topweb=""
            left="2px"
            leftw="0"
            width="95px"
            position="absolute"
            className="img-fluid rotate180"
          ></Images>
        </DivLoader>
        <DivLoader
          bcolor="transparent"
          ta="left"
          margin="0 20px 0 0"
          ml="25%"
          mlw="15%"
          wi="110px"
          he="32px"
        >
          <TrainingText
            size="11px"
            sizew="12px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
            color="#A6A6A6"
          >
            Escribiendo...
          </TrainingText>
        </DivLoader>
      </div>
    );
  }
  if (error) return `Error: ${error}`;

  return (
    <ManejarComponent
      Date={Date}
      hora={hora}
      orderRutine={orderRutine}
      setOrderRutine={setOrderRutine}
      currentTraining={currentTraining}
      createJsonInput={createJsonInput}
      questions={questions}
      setQuestions={setQuestions}
      currentRoutine={currentRoutine}
      nextOrderRoutine={nextOrderRoutine}
      trainingColor={trainingColor}
      data={productsData}
      setDescIntro={setDescIntro}
      extraIncomeProducts={extraIncomeProducts}
      setExtraIncomeProducts={setExtraIncomeProducts}
    />
  );
};

const ManejarComponent = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  data,
  setDescIntro,
  extraIncomeProducts,
  setExtraIncomeProducts,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [form, setForm] = useState({});
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [primeraPreguntaManejar, setPrimeraPreguntaManejar] = useState(
    sessionStorage.getItem('PrimeraPreguntaManejar') || '',
  );
  const [segundaPreguntaManejar, setSegundaPreguntaManejar] = useState(
    sessionStorage.getItem('SegundaPreguntaManejar') || '',
  );
  const [terceraPreguntaManejar, setTerceraPreguntaManejar] = useState(
    sessionStorage.getItem('TerceraPreguntaManejar') || '',
  );
  const [showSegundaPreguntaManejar, setShowSegundaPreguntaManejar] = useState(true);
  const [showTerceraPreguntaManejar, setShowTerceraPreguntaManejar] = useState(true);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editVehiculo, setEditVehiculo] = useState('');
  const [editCompartir, setEditCompartir] = useState('');
  const [editthirdQuestion, setEditthirdQuestion] = useState('');
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {

    let products = [];
    switch (val.step) {
      case 0:
        setPrimeraPreguntaManejar(val.value);
        setEditVehiculo(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeManejar2' });
        if (val.value === 'Un auto propio') {
          setShowTerceraPreguntaManejar(false);
        }
        if (val.value === 'Una motocicleta') {
          setShowSegundaPreguntaManejar(false);
          setSegundaPreguntaManejar(val.value);
        }
        if (val.value === 'Un taxi' || val.value === 'No tengo auto') {
          if (val.value === 'Un taxi') {
            products = data.filter(
              (e) => e.Name.startsWith('Nesko') || e.Name.startsWith('Easy Taxi'),
            );
            setDescIntro(
              'Gana un ingreso extra y mantén tu taxi siempre activo y en circulación. Inscríbete en alguna app para tener más viajes. Cada una varía en incentivos y porcentaje de ganancias.',
            );
          }
          if (val.value === 'No tengo auto') {
            products = data.filter(
              (e) => e.Name.startsWith('Jetty')
                || e.Name.startsWith('Urbvan')
                || e.Name.startsWith('Uber conecta'),
            );
            setDescIntro(
              'Aprovecha tu habilidad y gusto por el volante, inscríbete como conductor en alguna de estas apps:',
            );
          }
          setShowSegundaPreguntaManejar(false);
          setShowTerceraPreguntaManejar(false);
          setExtraIncomeProducts(products);
        }
        break;
      case 1:
        setEditCompartir(true);
        setSegundaPreguntaManejar(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeManejar3' });
        if (val.value === 'No') {
          products = data.filter(
            (e) => e.Name.startsWith('Uber -')
              || e.Name.startsWith('DiDi -')
              || e.Name.startsWith('Cabify')
              || e.Name.startsWith('Beat -')
              || e.Name.startsWith('Avant')
              || e.Name.startsWith('Bolt')
              || e.Name.startsWith('In Driver')
              || e.Name.startsWith('KAWI')
              || e.Name.startsWith('Ride App'),
          );
          setDescIntro(
            'Si quieres sacarle más provecho a tu auto y obtener dinero extra, conviértete en un conductor de transporte privado. Hoy en día hay varias plataformas en las que te puedes inscribir, todas varían en incentivos y porcentaje de ganancias. Algunos ejemplos son:',
          );
        } else {
          products = data.filter(
            (e) => e.Name.startsWith('Pooly')
              || e.Name.startsWith('BlaBlaCar')
              || e.Name.startsWith('Waze Carpool'),
          );
          setDescIntro(
            '¡Viajar acompañado siempre es mejor! Gana dinero mientras llegas a tu destino, sin desviarte. Hay aplicaciones que te permiten aprovechar los asientos vacíos en tu auto mientras llegas a tu destino, por ejemplo:',
          );
        }
        setExtraIncomeProducts(products);
        break;
      case 2:
        setEditthirdQuestion(true);
        setTerceraPreguntaManejar(val.value);

        if (val.value === 'No') {
          products = data.filter(
            (e) => e.Name.startsWith('Uber Eats')
              || e.Name.startsWith('Rappi')
              || e.Name.startsWith('DiDi Food')
              || e.Name.startsWith('SinDelantal'),
          );
          setDescIntro(
            'Aprovecha tu moto y gana dinero ofreciendo servicio delivery de comida u otro producto. Cada app varía en incentivos y porcentaje de ganancias.',
          );
        } else {
          products = data.filter((e) => e.Name.startsWith('Cornershop'));
          setDescIntro(
            'Aprovecha tu pasión por la moto, al mismo tiempo que vas por las compras y gana dinero entregando pedidos mediante:',
          );
        }
        // setNewScrollPos(sizeHeight);
        setExtraIncomeProducts(products);
        break;
      default:
        console.log('default');
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          // setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;

          const answers = {
            1: '',
            2: '',
            3: '',
          };

          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            // ---------

            let products = [];

            if (answers[1] !== '') {
              setPrimeraPreguntaManejar(answers[1]);
              setEditVehiculo(true);
              gsap.to(window, { duration: 1, scrollTo: '#activeManejar2' });
              if (answers[1] === 'Un auto propio') {
                setShowTerceraPreguntaManejar(false);
              }
              if (answers[1] === 'Una motocicleta') {
                setShowSegundaPreguntaManejar(false);
                setSegundaPreguntaManejar(answers[1]);
              }
              if (answers[1] === 'Un taxi' || answers[1] === 'No tengo auto') {
                if (answers[1] === 'Un taxi') {
                  products = data.filter(
                    (e) => e.Name.startsWith('Nesko')
                      || e.Name.startsWith('Easy Taxi'),
                  );
                  setDescIntro(
                    'Gana un ingreso extra y mantén tu taxi siempre activo y en circulación. Inscríbete en alguna app para tener más viajes. Cada una varía en incentivos y porcentaje de ganancias.',
                  );
                }
                if (answers[1] === 'No tengo auto') {
                  products = data.filter(
                    (e) => e.Name.startsWith('Jetty')
                      || e.Name.startsWith('Urbvan')
                      || e.Name.startsWith('Uber conecta'),
                  );
                  setDescIntro(
                    'Aprovecha tu habilidad y gusto por el volante, inscríbete como conductor en alguna de estas apps:',
                  );
                }
                setShowSegundaPreguntaManejar(false);
                setShowTerceraPreguntaManejar(false);
                setExtraIncomeProducts(products);
              }
            }
            if (answers[2] !== '') {
              setEditCompartir(true);
              setSegundaPreguntaManejar(answers[2]);
              gsap.to(window, { duration: 1, scrollTo: '#activeManejar3' });
              if (answers[2] === 'No') {
                products = data.filter(
                  (e) => e.Name.startsWith('Uber -')
                    || e.Name.startsWith('DiDi -')
                    || e.Name.startsWith('Cabify')
                    || e.Name.startsWith('Beat -')
                    || e.Name.startsWith('Avant')
                    || e.Name.startsWith('Bolt')
                    || e.Name.startsWith('In Driver')
                    || e.Name.startsWith('KAWI')
                    || e.Name.startsWith('Ride App'),
                );
                setDescIntro(
                  'Si quieres sacarle más provecho a tu auto y obtener dinero extra, conviértete en un conductor de transporte privado. Hoy en día hay varias plataformas en las que te puedes inscribir, todas varían en incentivos y porcentaje de ganancias. Algunos ejemplos son:',
                );
              } else {
                products = data.filter(
                  (e) => e.Name.startsWith('Pooly')
                    || e.Name.startsWith('BlaBlaCar')
                    || e.Name.startsWith('Waze Carpool'),
                );
                setDescIntro(
                  '¡Viajar acompañado siempre es mejor! Gana dinero mientras llegas a tu destino, sin desviarte. Hay aplicaciones que te permiten aprovechar los asientos vacíos en tu auto mientras llegas a tu destino, por ejemplo:',
                );
              }
              setExtraIncomeProducts(products);
            }

            if (answers[3] !== '') {
              setEditthirdQuestion(true);
              setTerceraPreguntaManejar(answers[3]);

              if (answers[3] === 'No') {
                products = data.filter(
                  (e) => e.Name.startsWith('Uber Eats')
                    || e.Name.startsWith('Rappi')
                    || e.Name.startsWith('DiDi Food')
                    || e.Name.startsWith('SinDelantal'),
                );
                setDescIntro(
                  'Aprovecha tu moto y gana dinero ofreciendo servicio delivery de comida u otro producto. Cada app varía en incentivos y porcentaje de ganancias.',
                );
              } else {
                products = data.filter((e) => e.Name.startsWith('Cornershop'));
                setDescIntro(
                  'Aprovecha tu pasión por la moto, al mismo tiempo que vas por las compras y gana dinero entregando pedidos mediante:',
                );
              }
              setExtraIncomeProducts(products);
            }

            // -----------

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setQuestions([]);
              break;
            } else {
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);
  const handleChangeIcon = (json) => {
    setShowSegundaPreguntaManejar(true);
    setShowTerceraPreguntaManejar(true);
    setExtraIncomeProducts(null);
    setEditVehiculo(false);
    setEditCompartir(false);
    setEditthirdQuestion(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  const anchop = window.screen.width;
  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#activeManejar' });
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 4 ? (
        <>
          {/* pregunta número uno */}
          <div id="activeManejar"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              (primeraPreguntaManejar === ''
                || (lapiz[0].routine === 4 && lapiz[0].active === 1))
              && editVehiculo !== true
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeManejar2"></div>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                primeraPreguntaManejar !== ''
                && ((segundaPreguntaManejar === ''
                  && showSegundaPreguntaManejar)
                  || !showSegundaPreguntaManejar)
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
            />
            <CardTwo
              classCard={
                primeraPreguntaManejar !== ''
                && (editVehiculo === true
                  || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              text={primeraPreguntaManejar}
              colorTraining={trainingColor}
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}

      {/* pregunta número dos */}
      {primeraPreguntaManejar !== '' ? (
        <QuestionsOrder
          classCard={
            primeraPreguntaManejar !== ''
            && showSegundaPreguntaManejar
            && (editVehiculo === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
      ) : null}
      <TrainingQuestion
        className={
          ((primeraPreguntaManejar !== ''
            && segundaPreguntaManejar === ''
            && showSegundaPreguntaManejar)
            || (lapiz[0].routine === 4 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 4 && lapiz[0].active === 1)
            || editVehiculo === true)
          && editCompartir !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeManejar3"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            segundaPreguntaManejar !== ''
            && showSegundaPreguntaManejar
            && (editCompartir === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
        />
        <CardTwo
          classCard={
            segundaPreguntaManejar !== ''
            && showSegundaPreguntaManejar
            && (editCompartir === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={segundaPreguntaManejar}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número tres */}
      {segundaPreguntaManejar !== '' ? (
        <QuestionsOrder
          classCard={
            segundaPreguntaManejar !== ''
            && showTerceraPreguntaManejar
            && (editCompartir === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 2))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
      ) : null}
      <TrainingQuestion
        className={
          ((segundaPreguntaManejar !== ''
            && terceraPreguntaManejar === ''
            && showTerceraPreguntaManejar)
            || (lapiz[0].routine === 4 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 4 && lapiz[0].active === 2)
            || editCompartir === true)
          && editthirdQuestion !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            terceraPreguntaManejar !== '' && showTerceraPreguntaManejar
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
        />
        <CardTwo
          classCard={
            terceraPreguntaManejar !== ''
            && showTerceraPreguntaManejar
            && (editthirdQuestion === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={terceraPreguntaManejar}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </Fragment>
  );
};

export default Manejar;
