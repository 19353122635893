import React, { Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

const Cc5Laboral = () => {
  const [state, setState] = useState({
    ocupation: '',
    industry: '',
    timeLabor: '',
  });
  const handleChange = (event) => {
    const { name } = event.target;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const guardar = () => {
    window.location.href = '';
  };
  return (
        <Fragment>
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '200px',
            }}>
                <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }} >
                    <InputLabel>Ocupación</InputLabel>
                    <Select
                        native
                        value={state.ocupation}
                        onChange={handleChange}
                        label="Ocupación"
                        inputProps={{
                          name: 'ocupation',
                          id: 'outlined-age-native-simple',
                        }}
                    >
                        <option>Elige una categoria</option>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }} >
                    <InputLabel>Tipo de industria</InputLabel>
                    <Select
                        native
                        value={state.industry}
                        onChange={handleChange}
                        label="Tipo de industria"
                        inputProps={{
                          name: 'industry',
                          id: 'outlined-age-native-simple',
                        }}
                    >
                        <option>Elige una sector</option>
                    </Select>
                </FormControl>
                <TextField style={{ margin: '10px', width: '30%' }} label="Empresa en la trabajas" variant="outlined" />
                <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }} >
                    <InputLabel>Tiempo laborado en esta empresa</InputLabel>
                    <Select
                        native
                        value={state.timeLabor}
                        onChange={handleChange}
                        label="Tiempo laborado en esta empresa"
                        inputProps={{
                          name: 'timeLabor',
                          id: 'outlined-age-native-simple',
                        }}
                    >
                        <option>Elige una opción</option>
                    </Select>
                </FormControl>
                <Button style={{ margin: '10px', width: '30%' }} onClick={guardar} variant="contained" color="secondary">
                    Continuar
                </Button>
            </div>
        </Fragment>
  );
};

export default Cc5Laboral;
