import React, { useState, Fragment, useEffect } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  CREATE_ACCOUNT_USER_TEMP,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import {
  GET_TRAINING_STATUS,
  GETPERCENTAGETRAINING,
  GET_SUGGESTIONS_WO_IMPROVE_BUDGET,
} from '../../queries';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainingContainAna,
  TrainigTam,
  TrainingLine,
  CoruCoach,
} from '../../assets/styles/Training.styled';
import { Feed_Section, Feed_Main } from '../../assets/styles/Feed.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import Header from '../../components/common/Header';
import imgWo from '../../assets/img/anacoach/MejoraPresupuesto.svg';
import ana from '../../assets/img/anacoach/CoachImage.png';
import logoC from '../../assets/img/anacoach/logoCoach.png';
import RoutinesOrderImproveBudget from './RoutinesOrderImproveBudget';
import WaitList from '../../components/Training/WaitList';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import {
  ContentHomeSection,
} from '../../components/common/Home.styled';
import Starttraining from '../../components/Training/Starttraining';
import WOlayout from '../../components/layouts/WOlayout';
import ModalRecomendacionTraining from '../../components/common/ModalRecomendacionTraining';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import SectionShare from '../../components/common/ShareSection';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';
import ModalVirality from '../../components/common/ModalVirality';
import { Helmet } from 'react-helmet';


const items2 = ['Mejora tu presupuesto'];

const WOImproveBudget = () => {
  const { actions } = GeneralHooks();
  // copia y pega estos states para crear un nuevo entrenamiento
  sessionStorage.setItem('idTraining', 25);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  console.log(id);
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  // End ValidWaitList
  const [saludo, setSaludo] = useState('No');
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(25);
  const [trainingBudgetComplete, setTrainingBudgetComplete] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [hasSuggestions, setHasSuggestions] = useState('');
  const [recommendedTrainingForModal, setRecommendedTrainingForModal] = useState({});
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  const ModalBudget = () =>
    (
      <ModalRecomendacionTraining
        textTraining="presupuesto"
        urlTraining="/wo/budget"
        close={true}
      ></ModalRecomendacionTraining>
    )
  ;
  // copia y pega estas mutaciones para crear un nuevo entrenamiento
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getSuggestionsWOImproveBudget] = useLazyQuery(
    GET_SUGGESTIONS_WO_IMPROVE_BUDGET,
    {
      onCompleted({ getSuggestionsWOImproveBudget }) {
        try {
          const json = JSON.parse(getSuggestionsWOImproveBudget.response);
          const { improvementSuggestions } = json[0];
          if (improvementSuggestions.length > 0) {
            setHasSuggestions(true);
          } else {
            setHasSuggestions(false);
          }
        } catch (error) {
          console.log(error);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });
  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      if (saveStatusTraining) {
        const porcentaje = saveStatusTraining.message.split('_');
        if (porcentaje.length > 1) {
          console.log(
            'ejecutar porcentaje============',
            `${porcentaje[1].toString()}%`,
          );
          setPercentage(`${porcentaje[1].toString()}%`);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'improve-budget');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  useEffect(() => {
    if (orderRutine >= 4) {
      for (let i = 1; i < 4; i++) {
        saveAdvance(String(orderRutine));
      }
    } else {
      saveAdvance(String(orderRutine));
    }
  }, [orderRutine]);

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          const filter = getPersonalizeRecommendationTrainings.response.filter(
            (e) => e.TrainingBigTitle === 'Tu primera inversión'
              || e.TrainingBigTitle === 'Crear Meta'
              || e.TrainingBigTitle === 'Protege a tu familia',
          );
          setRecommendedTrainingForModal(filter);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');

    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: currentTraining },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  const [completedWO, setCompletedWO] = useState(false);
  useEffect(() => {
    const trainingBudgetPercentage = sessionStorage.getItem(
      'TrainingBudgetPercentage',
    );
    if (trainingBudgetPercentage === '100%') {
      setCompletedWO(true);
      setTrainingBudgetComplete(true);
      getSuggestionsWOImproveBudget({
        variables: { idUser: sessionStorage.getItem('idUser') },
        idTrainig: currentTraining,
      });
    }
  }, []);

  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser, idCont, hostName, pageName, fromXCoach, idAdviser, email,
    } = getQueryVariableHashtagDeeplink('improve-budget');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);

  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [createAccountUserTemp] = useMutation(CREATE_ACCOUNT_USER_TEMP, {
    onCompleted({ createAccountUserTemp }) {
      console.log('createAccountUserTemp', createAccountUserTemp);
      if (createAccountUserTemp.statusCode === 200) {
        const jsonResponse = JSON.parse(createAccountUserTemp.response);
        sessionStorage.setItem('Email1', jsonResponse.email);
        sessionStorage.setItem('CellPhone', '0000000000');
        sessionStorage.setItem('idCont', jsonResponse.idCont);
        sessionStorage.setItem('idUser', jsonResponse.idUser);
        localStorage.setItem('Email1', jsonResponse.email);
        localStorage.setItem('CellPhone', '0000000000');
        localStorage.setItem('idCont', jsonResponse.idCont);
        localStorage.setItem('idUser', jsonResponse.idUser);
      }
    },
  });

  const anchop = window.screen.width;
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '5 minutos',
    imgWo: { imgWo },
    txtsubtitle: '¡Disfruta de tu dinero sin preocupaciones!',
  };

  return (
    <WOlayout
      backgroundColor={'#F5CA5D'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Mejora tu presupuesto | CORU</title>
      </Helmet>
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            <TrainingAnimateFunnel
              bcolor="#F5CA5D"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <TrainigTam>
                <Header
                  background={'#F5CA5D'}
                  pathname={window.location.pathname}
                />
              </TrainigTam>
              <BarraInfoWO propied={propied} />
              <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
            </TrainingAnimateFunnel>
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {trainingBudgetComplete ? (
                  <Fragment>
                    <ModalDataPersonalInitWO
                      isData={isData}
                      setData={setisData}
                    />
                    {hasSuggestions ? (
                      <Fragment>
                        <DataPersonalInitWO
                          Date={Date}
                          hora={hora}
                          orderRutine={orderRutine}
                          setOrderRutine={setOrderRutine}
                          currentTraining={currentTraining}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                        />
                        <TrainingSectionGray
                          className={
                            completedWO && orderRutine === 5 ? 'hidden' : ''
                          }
                          wiw="100%"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <TrainingDivSection2
                            paddiw="0"
                            paddi="0"
                            back="#FAFAFA"
                          >
                            <div className="marginComentLgSuccess1">
                              <Starttraining
                                title="¡Hola! Soy Ana, tu coach financiero."
                                text="En este entrenamiento te ayudaré a mejorar tu presupuesto y mantener más sanas tus finanzas"
                              />

                              <Starttraining
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="El objetivo es:"
                                text="Te ayudaremos a tomar desiciones, sobre como mejorar tu presupuesto y poder lograr esos objetivos que tanto deseas."
                              />

                              <Starttraining
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="¿En qué ayuda este entrenamiento para mí?"
                                text="Te ayudaremos a tomar desiciones, sobre como mejorar tu presupuesto y poder lograr esos objetivos que tanto deseas."
                              />
                            </div>
                          </TrainingDivSection2>
                        </TrainingSectionGray>

                        {saludo === 'Si' ? (
                          <TrainingContainAna back="#FAFAFA">
                            <Images
                              src={ana}
                              top="63px"
                              topweb="5%"
                              left="0"
                              leftw="-40px"
                              position="initial"
                              positionw="absolute"
                              border="2px solid #F5CA5D"
                              bs="border-box"
                              padd="5px"
                              br="45px"
                              className="img-fluid"
                            ></Images>

                            <CoruCoach bcolor="#F5CA5D">
                              <Images
                                src={logoC}
                                top="12px"
                                topweb="12px"
                                left="-8px"
                                leftw="-8px"
                                width="18px"
                                position="absolute"
                                className="img-fluid"
                              ></Images>
                            </CoruCoach>
                          </TrainingContainAna>
                        ) : null}
                        {saludo === 'Si' ? (
                          <TrainingDivSection
                            bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                            mt="44px"
                            mtw="225px"
                            br="4px"
                            padding="21px 20px"
                            wi="335px"
                            wiw="670px"
                            he="auto"
                            hew="auto"
                            mb="66px"
                          >
                            <div className="triangulo-equilatero-bottom"></div>
                            <div
                              className="closeDiv"
                              onClick={() => setSaludo('No')}
                            >
                              ×
                            </div>
                            <TrainingSubtitles
                              size="20px"
                              fw="600"
                              align="left"
                              alignweb="left"
                              className="cabin"
                            >
                              Hola soy Ana, tu coach financiero,
                            </TrainingSubtitles>
                            <TrainingText
                              size="16px"
                              className="roboto"
                              align="left"
                              alignweb="left"
                              ls="-0.2px"
                            >
                              Te ayudaré a buscar alternativas para hacer un
                              presupuesto a tu medida.
                            </TrainingText>
                          </TrainingDivSection>
                        ) : null}
                        <div className="marginComentLgSuccess1">
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <RoutinesOrderImproveBudget
                                setSaludo={setSaludo}
                                Date={stateDate}
                                hora={hora}
                                orderRutine={orderRutine}
                                setOrderRutine={setOrderRutine}
                                currentTraining={currentTraining}
                                createJsonInput={createJsonInput}
                                questions={questions}
                                setQuestions={setQuestions}
                              />
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                        </div>
                      </Fragment>
                    ) : recomendaciones ? (
                      <Fragment>
                        <TrainingSectionGray
                          wiw="100%"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <TrainingDivSection2
                            className="marginComentLgSuccess1"
                            paddiw="0"
                            paddi="0"
                            back="#FAFAFA"
                          >
                            <Starttraining
                              title="¡Impresionante!"
                              text="¡Tus finanzas están en excelente forma!"
                              text2="Ahora prueba los entrenamientos avanzados para fortalecer tus ingresos:"
                            />
                          </TrainingDivSection2>
                          <TrainingDivSection2
                            paddiw="0"
                            paddi="0"
                            back="#FAFAFA"
                          >
                            <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-10 offset-md-1 col-xs-12">
                              <Feed_Main
                                width="100%"
                                widthxs="100%"
                                background=" "
                              >
                                <Feed_Main
                                  width="100%"
                                  display=" "
                                  mtop=" "
                                  background="transparent"
                                >
                                  <Feed_Section
                                    padding="0 0 0 0"
                                    paddingxs="0 0 0 0"
                                  >
                                    <ContentHomeSection
                                      leftD="0%"
                                      floatD=" "
                                      width="100%;"
                                    >
                                      <Recommendations
                                        items={recommendedTrainingForModal}
                                      ></Recommendations>
                                    </ContentHomeSection>
                                  </Feed_Section>
                                </Feed_Main>
                              </Feed_Main>
                            </div>
                          </TrainingDivSection2>
                          <TrainingDivSection2
                            className="marginComentLgSuccess1"
                            paddiw="0"
                            paddi="0"
                            back="#FAFAFA"
                          >
                            <SectionShare WO="ImproveBudget"></SectionShare>
                          </TrainingDivSection2>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : (
                  <ModalBudget />
                )}
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
};

export default WOImproveBudget;
