import React from 'react';
import {
  Shapes,
  Title2,
  Subtitle2,
  Rectangle13,
  ElipseCoach1,
  LogocoruCoach,
  FInalcialProfile,
  UiCard1,
  Polygon,
  TextoCoach,
  ContentHomeSection,
  ContentHomeSection1,
  Rectangle23,
  Vector3,
} from '../common/Home.styled';
import Kanda  from '../Kanda/Kanda'
import logocoruCoach from '../../assets/img/home/logoCoach.png';
import ana from '../../assets/img/anacoach/CoachImage.png';
import { Images } from '../../assets/styles/Training.styled';

function SectionEntrenamiento({ heigthBrowser }) {
  console.log(heigthBrowser);
  return (
    <Shapes
      id="Shapes3"
      key={3}
      mtopD="60px"
      top=""
      left=" "
      height={heigthBrowser >= 600 ? '600px' : '480px'}
      width="100%"
      position=" "
      background="#FAFAFA"
    >
      <ContentHomeSection width="40%" leftD="10%" floatD="left">
        <div className="d-flex flex-column">
          <div className="p-2">
            <Title2
              top={heigthBrowser >= 550 ? '0' : '45px'}
              topD="0px"
              className="roboto"
            >
              Entrenamientos a la medida de tus objetivos
            </Title2>
          </div>
          <div className="p-2">
            <Subtitle2 position=" " className="roboto" bottom=" " top="9px">
              Ana,{' '}
              <b>
                nuestro coach financiero virtual, te escucha para ayudarte a
                elegir la forma más fácil
              </b>{' '}
              y simple de cumplirlos. No importa si quieres eliminar tus deudas,
              irte de viaje o crear una empresa, Ana siempre está contigo.
            </Subtitle2>
          </div>
        </div>
      </ContentHomeSection>
      <ContentHomeSection1
        width="40%"
        leftD=" "
        rigthD="10%"
        floatD="right"
        className=""
      >
        <div className="d-flex flex-column">
          <div className="p-2">
            <FInalcialProfile top="0px" topD="0px!important">
              <Images
                rigthm="0%"
                leftxs="37%"
                topmd=""
                leftw="10%"
                maxwidth="100%"
                rightm="0"
                bottomm="0"
                topm="0"
                le="40%"
                position="initial"
                zindex="1"
                padd="0.2%"
                br="45px 45px 45px 45px"
                width="100px"
                margin="auto"
                top="0"
                left="0"
                right="0"
                bottom="0"
                zi="1"
                widthw="80px"
                src={ana}
                border={'2px solid #BFDD6A'}
                className="img-fluid"
              ></Images>
              <ElipseCoach1>
                <LogocoruCoach src={logocoruCoach} />
              </ElipseCoach1>
            </FInalcialProfile>
          </div>
          <div className="p-2 ">
            <UiCard1>
              <Rectangle13
                margin="auto"
                bottomM="0px"
                displayM="flex"
                position=" "
                topM="0px"
                height="180px"
                width="335px"
                padding="19px 25px 20px 21px"
              >
                <Polygon topMob="-13%" />
                <TextoCoach className="roboto">
                  <b>¡Hola! Soy Ana, tu coach financiero.</b>
                  <br />
                  <br />
                  ¡El viaje que estás planeando suena increíble! Cuéntame más
                  para saber cómo te puedo ayudar a cumplirlo, ya sea con un
                  plan de ahorro para dar seguimiento a tu meta sea la opción
                  más adecuada a tu estilo de vida.
                </TextoCoach>
              </Rectangle13>
            </UiCard1>
          </div>
          <div className="p-2 ">
              {/* <Kanda /> */}
          </div>          
        </div>       
      </ContentHomeSection1>
      <Rectangle23
        displayD="block"
        displayM="none"
        width="80px"
        widthMb="150.37px"
        heightMb="150.37px"
        height="80px"
        Mleft="-1%"
        background="#6BD5AF"
        transform="rotate(-17.24deg)"
        top=""
      />
      <Rectangle23
        displayD="block"
        displayM="none"
        width="100px"
        widthMb="150.37px"
        heightMb="150.37px"
        height="100px"
        Mleft="95%"
        background="#BFDD6A"
        transform="rotate(-17.24deg)"
        top=""
      />
      <Vector3
        displayD="block"
        displayM="none"
        position=""
        width="100px"
        height="100px"
        left="90%"
        top="2%"
        background="#6BD5AF"
        transform="rotate(-90deg)"
      />
    </Shapes>
  );
}

export default SectionEntrenamiento;
