import { gql } from "@apollo/client";

export const UPDATE_PERSONAL_DATA = gql`
  mutation updatePersonalData($input: updatePersonalDataInput) {
    updatePersonalData(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const APLICAR_TODAS_TARJETAS = gql`
  mutation aplicarTodasTarjetas($idUser: Int!) {
    aplicarTodasTarjetas(idUser: $idUser) {
      idUser
    }
  }
`;
export const GET_PHONE_VALIDATE = gql`
  mutation phoneValidate($phone: String!) {
    phoneValidate(phone: $phone)
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      idCont
      idUser
      idCatRelAction
      firstName
      middleName
      fathersLastName
      mothersLastName
      idSession
      token
      ProfilePicture
      type
      BirthDate
      Gender
    }
  }
`;

export const CREATE_USER = gql`
  mutation registerClient($input: RegisterInput) {
    registerClient(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_IMG_PERFIL = gql`
  mutation uploadFilePerfil($input: img!) {
    uploadFilePerfil(input: $input) {
      ETag
      Location
      Key
      Bucket
    }
  }
`;

export const DYNAMIC_INSERT = gql`
  mutation dynamicInsert($input: dynamicinsertInput) {
    dynamicInsert(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const UPDATE_USERS = gql`
  mutation dynamicUpdate($input: dynamicUpdateInput) {
    dynamicUpdate(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const INSERT_CAT_RECURRENCE = gql`
  mutation insertCatRecurrence($idExercise: String) {
    insertCatRecurrence(idExercise: $idExercise)
  }
`;
export const CREATE_LOG_SHARE_MVP = gql`
  mutation createLogShareMvp($input: inputcreateLogShareMvp) {
    createLogShareMvp(input: $input)
  }
`;
export const SEND_OTP = gql`
  mutation sendOtp($input: envioOtpInput) {
    sendOtp(input: $input)
  }
`;
export const ENABLE_DISABLE_TRAINING = gql`
  mutation enableDisableTraining($input: enableDisableTrainingInput) {
    enableDisableTraining(input: $input) {
      statusCode
      message
    }
  }
`;

export const SHOW_HIDE_TRAINING = gql`
  mutation showHideTraining($input: showHideTrainingInput) {
    showHideTraining(input: $input) {
      statusCode
      message
    }
  }
`;
export const VALIDATE_OTP = gql`
  mutation validateOtp($input: OtpValidateInput) {
    validateOtp(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation dynamicMongoSession($input: mongoInput) {
    dynamicMongoSession(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_HISTORY_COLLECTION = gql`
  mutation dynamicUpdateHistoryMongo($input: mongoUpdateHistoryInput) {
    dynamicUpdateHistoryMongo(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_SESSION_USER = gql`
  mutation updateSessionUser($idUser: String!, $Flag: Boolean) {
    updateSessionUser(idUser: $idUser, Flag: $Flag) {
      statusCode
      message
      error
      response
    }
  }
`;
export const INSERT_SESSION_USER = gql`
  mutation insertSessionUser($idUser: String!, $Flag: Boolean) {
    insertSessionUser(idUser: $idUser, Flag: $Flag) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_SESSION = gql`
  mutation dynamicMongoSessionUpdate($input: mongoUpdateInput) {
    dynamicMongoSessionUpdate(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const CREATE_SHORTLINK = gql`
  mutation createShortUrl($input: createShortUrlInput) {
    createShortUrl(input: $input) {
      message
      error
      response
    }
  }
`;
export const CREATE_CLICK = gql`
  mutation createClick($input: createClickInput) {
    createClick(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DYNAMIC_UPDATE = gql`
  mutation dynamicUpdate($input: dynamicUpdateInput) {
    dynamicUpdate(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: newLogin) {
    changePassword(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DYNAMIC_GET = gql`
  mutation dynamicGet($input: dynamicGetInput) {
    dynamicGet(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DYNAMIC_GET_MONGO = gql`
  mutation dynamicMongoSessionGet($input: mongoInputGet) {
    dynamicMongoSessionGet(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_STATUS_TRAINING = gql`
  mutation saveStatusTraining($input: statusTraining) {
    saveStatusTraining(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const INSERT_ROUTINE = gql`
  mutation insertRoutine($input: insertRoutineInput) {
    insertRoutine(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_QUIZ_EXERCISE = gql`
  mutation createQuizExercise($input: inputcreateQuizExercise) {
    createQuizExercise(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: createTemplateInput) {
    createTemplate(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const VALIDATE_250OK = gql`
  mutation validateMail250Ok($email: String!) {
    validateMail250Ok(email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($input: sentNotificationInput) {
    sendNotification(input: $input) {
      statusCode
      message
      response
      error
    }
  }
`;
export const SAVE_TRANSACT_CAMPAIGN = gql`
  mutation saveTransactCampaign($input: saveTransactCampaignInput) {
    saveTransactCampaign(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_RFC = gql`
  mutation createRFC($input: RFCInput) {
    createRFC(input: $input) {
      statusCode
      response
    }
  }
`;

export const CREATE_OPPORTUNITIES = gql`
  mutation createOpportunities($input: OpportunityDetailInput) {
    createOpportunities(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ENABLE_DISABLE_CAMPAIGNSCN = gql`
  mutation enableDisableCampaignSnc($input: statusCampaignInput) {
    enableDisableCampaignSnc(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ENABLE_DISABLE_SCRAPPER = gql`
  mutation enableDisableScrapper($input: enableDisableScrapperInput) {
    enableDisableScrapper(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const HASAPI_DISABLE_CONTROL = gql`
  mutation hasApiDisableControlClickouts(
    $input: hasApiDisableControlClicksoutsInput
  ) {
    hasApiDisableControlClickouts(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const HASCLICKOUT_DISABLE_CONTROL = gql`
  mutation hasClickOutDisableControlClickouts(
    $input: hasClickOutDisableControlClickoutsInput
  ) {
    hasClickOutDisableControlClickouts(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_PRODUCT_WO_REL_STATUS = gql`
  mutation updateProductWORelStatus($input: inputUpdateProductWORelStatus) {
    updateProductWORelStatus(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_PRODUCTS_DETAILS = gql`
  mutation updateProductsDetails(
    $input: [ProductDetailsUpdate]
    $DB: String
    $IDUser: String
  ) {
    updateProductsDetails(input: $input, DB: $DB, IDUser: $IDUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_AUTHENTICATE_BC = gql`
  mutation callAuthenticator($input: personalInformation) {
    callAuthenticator(input: $input) {
      RespuestaBC
      BCScore
      RespComentario
      FechaAut
      activeCard
      digits
      mortgageCredit
      automotiveCredit
      authenticationSavings
    }
  }
`;

export const GET_BC_REPORT = gql`
  mutation getBCReport($input: personalInformation) {
    getBCReport(input: $input)
  }
`;

export const CALL_BC_REPORT = gql`
  mutation callBCReport($input: personalInformation) {
    callBCReport(input: $input)
  }
`;

export const CALL_SCRAPPER = gql`
  mutation callScrapper(
    $dataJson: String!
    $bank: String
    $idOportunity: String
    $idUser: String
    $pixelID: String
    $ipAddressUser: String
    $url: String
    $userAgent: String
    $em: String
    $ph: String
    $eventName: String
  ) {
    callScrapper(
      dataJson: $dataJson
      bank: $bank
      idOportunity: $idOportunity
      idUser: $idUser
      pixelID: $pixelID
      ipAddressUser: $ipAddressUser
      url: $url
      userAgent: $userAgent
      em: $em
      ph: $ph
      eventName: $eventName
    ) {
      folio
      response
      comment
      status
      dateIni
      dateFin
      run
    }
  }
`;
export const RECOVERY_TRAINING = gql`
  mutation recoveryTraining(
    $idTraining: String
    $idUser: String
    $idCont: String
  ) {
    recoveryTraining(
      idTraining: $idTraining
      idUser: $idUser
      idCont: $idCont
    ) {
      statusCode
      message
      error
      response
    }
  }
`;
export const UPLOAD_IMAGE_S3 = gql`
  mutation uploadFile($input: img!) {
    uploadFile(input: $input) {
      Location
    }
  }
`;
export const UPLOAD_TEMPLATE = gql`
  mutation uploadTemplate($File: Upload!) {
    uploadTemplate(File: $File) {
      ETag
      Location
      Key
      Bucket
    }
  }
`;

export const SAVE_EVALUATE_TRAINING = gql`
  mutation saveEvaluateTraining($idDatTraining: String!, $stars: String!) {
    saveEvaluateTraining(idDatTraining: $idDatTraining, stars: $stars) {
      statusCode
      message
      error
      response
    }
  }
`;
export const CREATE_ACTION_SNC = gql`
  mutation createActionSnc($input: createActionSncInput) {
    createActionSnc(input: $input) {
      response
      message
      error
    }
  }
`;

export const INSERT_USER_BLACKLIST_SNC = gql`
  mutation insertUserBlacklist($input: userBlacklist) {
    insertUserBlacklist(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_SESSION_MYSQL = gql`
  mutation createSessionMysql($input: createSessionMysql) {
    createSessionMysql(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SUBMIT_INSERT_LANDING = gql`
  mutation submitInsertLanding($input: createSubmit) {
    submitInsertLanding(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_EMAIL_LANDING = gql`
  mutation updateEmailLanding($phone: String, $email: String) {
    updateEmailLanding(phone: $phone, email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SUBMIT_UPDATE_LANDING = gql`
  mutation submitUpdateLanding($input: updateSubmit) {
    submitUpdateLanding(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_STATUS_TRAINING_SUCCESS = gql`
  mutation updateStatusTrainingSuccess($input: successStatusTraining) {
    updateStatusTrainingSuccess(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_RELROUTINE = gql`
  mutation createRelRoutine($input: createcreateRelRoutineInput) {
    createRelRoutine(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const CREATE_ACCOUNT_USER_TEMP = gql`
  mutation createAccountUserTemp($email: String) {
    createAccountUserTemp(email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_UPDATE_TRAINING = gql`
  mutation createUpdateTraining($input: createUpdateTrainingInput) {
    createUpdateTraining(input: $input) {
      message
      response
      statusCode
      error
    }
  }
`;

export const GET_RECOMMENDATIONS = gql`
  mutation getPersonalizeRecommendation($email: String) {
    getPersonalizeRecommendation(email: $email) {
      recommendationId
      itemList
    }
  }
`;

export const GET_RECOMMENDATION_TRAININGS = gql`
  mutation getPersonalizeRecommendationTrainings($email: String) {
    getPersonalizeRecommendationTrainings(email: $email) {
      response
      statusCode
      message
      error
    }
  }
`;

export const CALL_API_CONVERSION_FB = gql`
  mutation callApiConversionFB($input: inputApiFacebook) {
    callApiConversionFB(input: $input) {
      statusCode
      response
    }
  }
`;
export const INSERT_DAT_VIRALITY = gql`
  mutation insertDatVirality($input: ViralityParams) {
    insertDatVirality(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_PRODUCT_ADMIN = gql`
  mutation CreateProductAdmin($input: inputCreateProductAdmin) {
    CreateProductAdmin(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_PROVIDER_CONTACT = gql`
  mutation insertProviderContact($input: inputinsertProviderContact) {
    insertProviderContact(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_WAIT_LIST = gql`
  mutation insertWaitList($input: inputinsertWaitList) {
    insertWaitList(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const INSERT_DAT_GOAL = gql`
  mutation insertDatGoal($input: inputGoal) {
    insertDatGoal(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_DAT_GOAL_COLLABORATIVE = gql`
  mutation insertDatGoalCollaborative($input: inputGoalCollaborative) {
    insertDatGoalCollaborative(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_AMOUNT_GOAL_COLLABORATIVE = gql`
  mutation insertAmountGoalCollaborative($input: inputAmountGoalCollaborative) {
    insertAmountGoalCollaborative(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_ID_USER_COLLABORATIVE = gql`
  mutation updateIdUserCollaborative(
    $idUser: String
    $idDatTraining: String
    $phone: String
  ) {
    updateIdUserCollaborative(
      idUser: $idUser
      idDatTraining: $idDatTraining
      phone: $phone
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_REL_IDUSER_WITH_IDUSERFRIEND = gql`
  mutation dynamicInsert($input: dynamicinsertInput) {
    dynamicInsert(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_DAT_INVESTMENT_PORTAFOLIOS = gql`
  mutation insertInvestmentPortafolio($input: inputUserPortafolio) {
    insertInvestmentPortafolio(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPSERT_NOTIFICATION_PERMISION = gql`
  mutation upsertNotificationPermision(
    $input: inputupsertNotificationPermision
  ) {
    upsertNotificationPermision(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GENERATE_SHORTENED_LINK = gql`
  mutation generateShortendLink($input: inputgenerateShortendLink) {
    generateShortendLink(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_NIP_TO_USER = gql`
  mutation getNipToUser($idUser: String) {
    getNipToUser(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_HOMEWORKS_CHILDREN = gql`
  mutation insertHomeworkSchedule($input: inputHomeworks) {
    insertHomeworkSchedule(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_FORM_ADVISERS = gql`
  mutation insertFormAdvisers($input: inputFormAdvisers) {
    insertFormAdvisers(input: $input) {
      statusCode
      response
    }
  }
`;

export const SET_PROFILING_PROGRESS = gql`
  mutation setProfilingProgress($idUser: String, $idTraining: String) {
    setProfilingProgress(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SET_TRAINING_COMPLETE = gql`
  mutation setTrainingComplete($idUser: Int, $idTraining: Int) {
    setTrainingComplete(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_FINISHED_WO_BY_XCOACH = gql`
  mutation insertFinishedByXCoach(
    $idUser: Int
    $idTraining: Int
    $fromXCoach: Int
    $idAdviser: Int
  ) {
    insertFinishedByXCoach(
      idUser: $idUser
      idTraining: $idTraining
      fromXCoach: $fromXCoach
      idAdviser: $idAdviser
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const BACKUP_WO_DATA = gql`
  mutation backupWOData($input: mongoInput) {
    backupWOData(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_KANDA_WAITLIST = gql`
  mutation saveKandaWaitlist($input: mongoInput) {
    saveKandaWaitlist(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CURP = gql`
  mutation createCURP($input: inputCURP) {
    createCURP(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_DAT_EXPENSES = gql`
  mutation insertDatAntExpenses($input: DatExpensesInput) {
    insertDatAntExpenses(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const VALIDATE_CSV_EMAILS250OK = gql`
  mutation validateListEmails($listEmails: String, $condition: String) {
    validateListEmails(listEmails: $listEmails, condition: $condition) {
      statusCode
      message
      error
      response
    }
  }
`;

export const LIVERPOOL = gql`
  mutation liverpool($input: Liverpool) {
    liverpool(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const RTD = gql`
  mutation opportunitexRTD($input: RTDopportunitex) {
    opportunitexRTD(input: $input) {
      id
      date_created
      uid
    }
  }
`;

export const CREDILIKEME = gql`
  mutation credilikeme($input: InputCrediLikeMe) {
    credilikeme(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const LOG_SAVIG_DOLLAR = gql`
  mutation logSavigDollar($input: LogSavigDollar) {
    logSavigDollar(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ESCAMPA = gql`
  mutation sendEscampaApplication($input: inputEscampa) {
    sendEscampaApplication(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const LA_TASA = gql`
  mutation sendLaTasaApplication($input: laTasaInput) {
    sendLaTasaApplication(input:$input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation changeStatus($input: dynamicUpdateInputStatus) {
    changeStatus(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_WAIT_LIST_PRODUCT_USER = gql`
  mutation insertWaitListProductUser($input: inputInsertWaitListProductUser) {
    insertWaitListProductUser(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_EVENT_NOTIFICATION = gql`
  mutation createEventnotification($input: createEventInput) {
    createEventnotification(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ENABLE_DISABLE_EVENT_NOTIFIACTION = gql`
  mutation enableDisableNotificationEvent($input: statusEventInput) {
    enableDisableNotificationEvent(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const WEB_SERVICE_CI = gql`
  mutation webServiceCI($input: dataWebServiceCI) {
    webServiceCI(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const SAVE_AUTH_OTP = gql`
mutation saveAuthOtp($input:saveAuthOtpInput)
{
  saveAuthOtp(input:$input)
  {
    statusCode
    message
    error
    response
  }
}
`;
export const SAVE_TIME_SESSION = gql`
mutation timeSession($input:timeSessionInput)
{
  timeSession(input:$input)
  {
    statusCode
    message
    error
    response
  }
}
`;
export const UPDATE_USER_EMAIL_TELEFONO = gql`
  mutation updateEmailTelefono($input: updateEmailInput) {
    updateEmailTelefono(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const LOGLOGROS_GET_MONGO = gql`
  mutation LogLogrosGet($input: mongoInputGet) {
    LogLogrosGet(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const SET_VISIT_KANDA = gql`
  mutation setVisitKanda($kandaEntered: Int!, $idUser: Int!) {
    setVisitKanda(kandaEntered: $kandaEntered, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const COUNT_MODAL_VIEWS = gql`
  mutation dynamicMongoSession($input: mongoInput) {
    dynamicMongoSession(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_IBILLETE_APP = gql`
mutation sendIbilleteApp($input: inputIbillete) {
  sendIbilleteApp(input: $input) {
    statusCode
    message
    response
    error
    usuario {
      id
      user
      pass
      url
    }
  }
}
`;

export const ADD_DOCUMENT_USERS = gql`
mutation insertDocumentsUsers($input: insertDocumentsUsers) {
  insertDocumentsUsers(input: $input) {
    statusCode
    message
    response
    error    
  }
}
`;

export const INSERT_PARTNER_UTM = gql`
mutation insertPartnerByUTM($input: inputPartner){
  insertPartnerByUTM(input: $input){
    statusCode
    message
    response
    error
  }
}
`;

export const UPDATE_CLICKOUT_OPPORTUNITY = gql`
  mutation updateClickOutOpportunity($opportunities: String!, $idProduct: Int!) {
    updateClickOutOpportunity(opportunities: $opportunities, idProduct: $idProduct) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_VALUES_CAAS = gql`
  mutation updateDynamicCaaS($inputvalues: String!) {
    updateDynamicCaaS(inputvalues: $inputvalues) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_HUBSPOT_INFO = gql`
mutation sendInfoHubForm($dataJson: String) {
    sendInfoHubForm(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_CREDITEA_WL = gql`
mutation sendCrediteaApplicationWL($input: inputCreditea!) {
    sendCrediteaApplicationWL(input: $input) {
      statusCode
      message
      error
      response
      responseRaw
      url
    }
  }
`;

export const SEND_OTP_CREDITEA_WL = gql`
mutation sendOTPCrediteaApplicationWL($OTP: String!, $id: String!, $email: String!) {
    sendOTPCrediteaApplicationWL(OTP: $OTP, id: $id, email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const RE_SEND_OTP_CREDITEA_WL = gql`
  mutation reSendOTPCrediteaApplicationWL($id: String!, $email: String!) {
    reSendOTPCrediteaApplicationWL(id: $id, email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_STORI_MAIL = gql`
mutation sendStoriMail($input: inputStori) {
  sendStoriMail(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ADD_SCORE_TO_USER = gql`
  mutation addScoreToUser($idUser: Int!, $score: Int!) {
    addScoreToUser(idUser: $idUser, score: $score) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ADD_SCORE_TO_FINISHED = gql`
  mutation addScoreToFinished($idUser: Int!, $idTraining: Int!) {
    addScoreToFinished(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_CURP = gql`
  mutation saveCurp($idUser: String!, $curp: String!) {
    saveCurp(idUser: $idUser, curp: $curp) {
      statusCode
      message
      error
      response
    }
  }
`;

export const NEW_LOGIN = gql`
  mutation newLoginCoru ($input: LoginCoruInput) {
    newLoginCoru(input: $input) {
      idUser
      idCont
      idSession
      firstName
      middleName
      fathersLastName
      mothersLastName
      token
      ProfilePicture
      Gender
      TimeLocked
      Attemps
      BirthDate
      idCont
      Email
      type
    }
  }
`;

export const CREATE_MAGIC_LINK = gql`
  mutation createMagicLink ($type: String!, $text: String!, $origin: String!, $recoveryId: String) {
    createMagicLink(type: $type, text: $text, origin: $origin, recoveryId: $recoveryId) {
      statusCode
      message
      response
      error
    }
  }
`;

export const PASS_TEMPORAL = gql`
  mutation passTemporal($idUser: String) {
    passTemporal(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const EMIDA_BALANCE = gql`
  mutation emidaBalance {
    emidaBalance {
      statusCode
      message
      error
      response
    }
  }
`;

export const PAGAR_REWARDS = gql`
  mutation pagarRewards($fechaIni: String, $fechaFin: String, $reward: String) {
    pagarRewards(fechaIni: $fechaIni, fechaFin: $fechaFin, reward: $reward) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_FOR_BUSINESS = gql`
  mutation AddForBusiness($DataJson: String!) {
    AddForBusiness(DataJson: $DataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_DRIPIFY_HSFORMS = gql`
  mutation sendHubSpotByDripify($dataJson: String!) {
    sendHubSpotByDripify(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SCHEDULE_APPOINTMENT_DEMO = gql`
  mutation scheduleAppointmentDemo($input: inputScheduleAppointment) {
    scheduleAppointmentDemo(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
