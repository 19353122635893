import React from 'react';
import PolarGraphic from './PolarGraphic';
import marca from '../../assets/img/anacoach/Training/line.svg';
import circle from '../../assets/img/anacoach/Training/circle.svg';
import {
  Image,
} from '../common/common.styled';
import {
  TrainingDivSection,
  TrainingSubtitles,
} from '../../assets/styles/Training.styled';

const ScoreGraphic = ({ score, logros = false }) => {
  let deg = '';
  const score2 = parseInt(score);
  if (score2 <= 400) {
    console.log('400');
    deg = 'rotate(410deg)';
  } else if (score2 > 401 && score2 <= 500) {
    console.log('500');
    deg = 'rotate(340deg)';
  } else if (score2 > 501 && score2 <= 600) {
    console.log('600');
    deg = 'rotate(272deg)';
  } else if (score2 > 601 && score2 <= 700) {
    console.log('700');
    deg = 'rotate(200deg)';
  } else if (score2 > 701) {
    console.log('800');
    deg = 'rotate(130deg)';
  }

  return (
        <TrainingDivSection backw="#f8f9fa00" padding="0px" paddw="0px" mb="0" wi={logros ? '100%' : '90%'} wiw="100%" he="auto" hew="auto">
            <TrainingDivSection mb='5px' mbw='5px' bsha='0px 2px 8px rgba(0,0,0,0.08)' bshaw='0px 2px 8px rgba(0,0,0,0.08)' back="#FFFFFF;" backw="#FFFFFF;" mlw={logros ? '10%' : '18%'} paddw="10px 0 0 0" wiw={logros ? '75%' : '60%'} padding="0px" style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                    <Image src={circle} zindex="1" width='15px' height='auto' position='absolute' marginTop='121px' marginLeft='101px' marginTopM='98px' marginLeftM='78px' ></Image>
                    <Image src={marca} width='50px' height='auto' position='absolute' marginTop='104px' marginLeft='57px' marginTopM='80px' marginLeftM='35px' transform={deg} to='100% 50% 0' ></Image>
                    <PolarGraphic score={score}></PolarGraphic>
                </div>
                <div style={logros ? { width: '35%', margin: 'auto' } : { width: '50%', margin: 'auto' }}>
                    <TrainingSubtitles sizew="24px" fw="bold" align="center" alignweb="center" className="cabin">
                        Tu Score crediticio es de:
                    </TrainingSubtitles>
                    <TrainingSubtitles color="#F64282" size="24px" sizew="40px" fw="bold" align="center" alignweb="center" className="cabin">
                        {score}
                    </TrainingSubtitles>
                </div>
            </TrainingDivSection>
        </TrainingDivSection>
  );
};

export default ScoreGraphic;
