import React from 'react';
import '../../assets/styles/CardMultipleDesc.css';

const CardMultipleDesc = ({ children, colorTraining, extraClass }) => (
  <div className={`card-container-coru ${extraClass}`}>
    <div
      className="card-multiple-desc"
      style={
        colorTraining !== undefined
          ? { backgroundColor: colorTraining }
          : { backgroundColor: '' }
      }
    >
      {children}
    </div>
  </div>
);

export default CardMultipleDesc;
