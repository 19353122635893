/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { CREATE_SESSION } from "../../mutations";
import "../../assets/styles/TextCustom.css";
import ClickButton from "../../Helpers/HookHelpers";
import FullNameOfBrasil from "./FullNameOfBrasil";
import ContactoOfBrasil from "./ContactoOfBrasil";
import DataBusinessOfBrasil from "./DataBusinessOfBrasil";

const RegisterWithBusinessOfBrasil = () => {
  const [form, setForm] = useState({
    fullname: "",
    email: "",
    numPhone: "",
    lada: "México lada +(52)",
    textlada: "México lada +(52)",
    Empresa: "",
    Posicion: "",
    Sitio: ""
  });

  const { actions } = ClickButton();

  const [validCampos, setvalidCampos] = useState({
    personales: false,
    contacto: false,
    empresa: false,
  });
  const [disabledbtn, setdisabledbtn] = useState(true);

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
      }
    },
  });

  const handleClickForm = (e) => {
    actions({
      action: 'ClickButtonLPBrasil',
      variables: 'ClickDatosUsuario_LpCoruBrasil',
      collection: 'LogClickButtonLpCoruBrasil',
    });

    e.preventDefault();
    e.stopPropagation();

    const infoUser = {
      'User Data': [
        {
          Name: form.fullname,
        }
      ],
      'Contact User': [
        {
          Email: form.email,
          'Phone Number': form.numPhone,
        }
      ],
      'Bussines Data': [
        {
          Name: form.Empresa,
          'Work Position': form.Posicion,
          Web: form.Sitio,
        }
      ]
    };

    const mongoInput = {
      input: {
        collection: 'LogLpCoruBrasil',
        data: JSON.stringify(infoUser),
      },
    };

    createSession({ variables: mongoInput });
  };

  useEffect(() => {
    if (
      validCampos.personales
      && validCampos.contacto
      && validCampos.empresa
    ) {
      setdisabledbtn(false);
    } else {
      setdisabledbtn(true);
    }
  }, [validCampos]);

  return (
    <div>
      <FullNameOfBrasil form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
      <ContactoOfBrasil form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
      <DataBusinessOfBrasil form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
      <BoxInput>
        <ButtonNew
          borderRadius="16px"
          className={
            disabledbtn
              ? "buttonInactive d-block mt-md-4 roboto"
              : "d-block mt-md-4 roboto"
          }
          mb="24px"
          width="59px"
          mt="1.2rem"
          bg={disabledbtn ? "#d1d1d1" : "#fb245c"}
          onClick={(e) => handleClickForm(e)}
          disabled={disabledbtn}
          >
          Fale com um especialista
        </ButtonNew>
      </BoxInput>
    </div>
  );
};

export default RegisterWithBusinessOfBrasil;

const ButtonNew = styled.button`
  display: flex;
  border-radius: 16px;
  /* align-self: flex-end; */
  width: 59%;
  cursor: pointer;
  height: 48px;
  border: none;
`;

const BoxInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
