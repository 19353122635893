import React from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_SESSION } from '../mutations'

export const useCreateClickBtnMongo = () => {
    const [createCollection] = useMutation(CREATE_SESSION, {
        onCompleted({ dynamicMongoSession }) {
            if (dynamicMongoSession) {
                console.log("🚀 ~ file: useCreateCollectionButtons.js:8 ~ onCompleted ~ dynamicMongoSession:", dynamicMongoSession)
            }
        },
        onError(error) {
            console.log("🚀 ~ file: useCreateCollectionButtons.js:12 ~ onError ~ error:", error)

        }

    })

    const createClickBttn = ({ action}) => {
        const data = {
            idSession: sessionStorage.getItem('idDatSession') || sessionStorage.getItem('id_session') || '0',
            ClickButton: [
                {
                    idUser: sessionStorage.getItem('idUser') || '0',
                    action,
                }
            ],
            env: process.env.REACT_APP_SERVERTYPE === 'Production' ? 'Prod' : 'Dev',
        }
        createCollection({
            variables: {
                input: {
                    collection: 'ClickButtonLPCaaS',
                    data: JSON.stringify(data),
                    
                }
            }
        })
    }

    return {
        createClickBttn
    }

}
