import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { CREATE_CLICK } from '../../mutations';
import Login from '../../assets/img/anacoach/Login.png';
import ImageLogo from '../../assets/img/anacoach/CoruLogoB.png';
import {
  Header_Log,
  Header_Home_Web,
  Content_Options,
  Toggle_btn,
  Image,
  Main,
  Header_Home_Mob,
} from '../common/common.styled';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: 155.92,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function HeaderPerfil({ url, background2 }) {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
  const timestamp = today;

  const classes = useStyles();
  console.log(background2);
  const background = background2 === undefined ? '#FAFAFA' : background2;
  const ProfilePicture = sessionStorage.getItem('ProfilePicture') !== 'null'
    && sessionStorage.getItem('ProfilePicture') != null
    ? sessionStorage.getItem('ProfilePicture')
    : Login;
  console.log(sessionStorage.getItem('ProfilePicture'));
  console.log(ProfilePicture);
  console.log(url);
  const history = useHistory();

  const handleClick = () => {
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickFeedHeader',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      },
    });
    history.push('/the-training-feed/');
  };

  const handleOpen = (ruta, click) => {
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: click,
          collection: 'sessions',
          timeStamp: timestamp,
        },
      },
    });
    if (ruta === '/') {
      sessionStorage.removeItem('clickIniciarSesion');
      history.push(ruta);
    } else {
      sessionStorage.setItem('originFeed', true);
      history.push(ruta);
    }
  };

  const logout = () => {
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickLogoutHeader',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      },
    });
    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
  };

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted() {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });

  return (
    <Fragment>
      <Main padding="1rem" style={{ background }}>
        <div
          className="paddingLaterales home-header-mob"
          style={{ background }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ height: '70px', background }}
          >
            <div onClick={handleClick}>
              <Image
                cursor="pointer"
                src={ImageLogo}
                width="60%"
                mwidth="auto"
                heightMobile="20.8px"
                alt="logo-coru"
                onClick={handleClick}
              />
            </div>
            <Header_Home_Web width="160%">
              <Content_Options>
                <Fragment>
                  <Header_Log
                    widthW=" "
                    width=" "
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen('/the-training-feed/', 'clickFeedHeader');
                    }}
                  >
                    Inicio
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen('/achievements', 'clickAttainmentHeader');
                    }}
                  >
                    Logros
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen('/wo/card-funnel', 'clickCCHeader');
                    }}
                  >
                    Tarjeta de crédito
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      handleOpen('/wo/loans-funnel', 'clickPLHeader');
                    }}
                  >
                    Prestamos personales
                  </Header_Log>
                  <Header_Log widthW=" " width=" " leftW="1%" rightW="2%">
                    <Paper component="form" className={classes.root}>
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        className={(classes.input, 'busqueda')}
                        placeholder="Buscar"
                        inputProps={{ 'aria-label': 'Buscar' }}
                      />
                    </Paper>
                  </Header_Log>
                  <Header_Log
                    widthW=" "
                    width=" "
                    leftW="1%"
                    rightW="2%"
                    textAlign="right"
                    className="text-session roboto index-btns"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Cerrar sesión
                  </Header_Log>
                  {url !== '/profile' ? (
                    <Header_Log
                      widthW=" "
                      width=" "
                      margin="0px 1%"
                      leftW="1%"
                      rightW="2%"
                    >
                      <Header_Log
                        widthW=" "
                        width=" "
                        onClick={() => {
                          handleOpen('/profile', 'clickProfileHeader');
                        }}
                      >
                        <Image
                          src={ProfilePicture}
                          border="1px solid #A6A6A6"
                          className="img-fluid"
                          alt=""
                          width="3.5vw"
                        />
                      </Header_Log>
                    </Header_Log>
                  ) : null}
                </Fragment>
              </Content_Options>
            </Header_Home_Web>
            <Header_Home_Mob>
              <Content_Options>
                <Toggle_btn color="#07210A" className="" mfonts="28px">
                  <Paper component="form" className={classes.root}>
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      className={(classes.input, 'busqueda')}
                      placeholder="Buscar"
                      inputProps={{ 'aria-label': 'Buscar' }}
                    />
                  </Paper>
                </Toggle_btn>
              </Content_Options>
            </Header_Home_Mob>
          </div>
        </div>
      </Main>
    </Fragment>
  );
}

export default HeaderPerfil;
