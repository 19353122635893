import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CALL_BC_REPORT,
  GET_AUTHENTICATE_BC,
  UPDATE_SESSION,
} from '../../mutations';

const Authenticate = ({
  setOrderRutine,
  currentRoutine,
  nextOrdeRoutine = 0,
  data,
  setForm,
  triedAut,
  setTriedAut,
  bancoActual,
  responseBuro,
  setResponseBuro,
  checkBCReport,
  createSheet,
}) => {
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
    onError(e) {
      console.log('error mongo: ', e.message);
    },
  });
  const [BCScore, setBCScore] = useState({
    success: false,
  });

  const firstname = sessionStorage.getItem('FirstName') || '';
  const lastname = sessionStorage.getItem('FathersLastName') || '';
  const secondname = sessionStorage.getItem('MiddleName') || '';
  const secondlastname = sessionStorage.getItem('MothersLastName') || '';
  const numext = sessionStorage.getItem('ExtNo') || '';
  const calle = sessionStorage.getItem('Street') || '';
  const numint = sessionStorage.getItem('IntNo') || '';
  const cp = sessionStorage.getItem('ZipCode') || '';
  const colonia = sessionStorage.getItem('Suburb') || '';
  const municipio = sessionStorage.getItem('Municipality') || '';
  const estado = sessionStorage.getItem('State') || '';
  const email = sessionStorage.getItem('Email1') || '';
  const digitoscard = sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '';
  const rfc = sessionStorage.getItem('RFC') || '';
  let activeCard = 'No';
  const mortgageCredit = 'No';
  const automotiveCredit = 'No';

  if (digitoscard !== '') {
    activeCard = 'Si';
  }

  const [getBCReport] = useMutation(CALL_BC_REPORT);

  const callBCReport = async () => {
    const request = `primerNombre=${firstname.trim()}&segundoNombre=${secondname.trim()}&apPat=${lastname.trim()}&apMat=${secondlastname.trim()}&rfc=${rfc.trim()}&calle=${calle.trim()}&noext=${numext.trim()}&noint=${numint.trim()}&cpostal=${cp.trim()}&colonia=${colonia.trim()}&deleg=${municipio.trim()}&estado=${estado.trim()}&email=${email.trim()}&tarjeta=${activeCard}&digitos=${digitoscard}&hipotecario=${mortgageCredit}&automotriz=${automotiveCredit}&source=Coru`;
    const { domain } = window.document;
    const inputBC = {
      input: {
        idUser: sessionStorage.getItem('idUser'),
        idOportunity: sessionStorage
          .getItem('datOpportunity')
          .replace(/,/g, '_'),
        user: 'CORU',
        password: 'C0rU*2O19',
        dataPersonaEncode: btoa(request),
        bancoActual: bancoActual || sessionStorage.getItem('bancoActual') || '',
        createSheet: !!createSheet,
        host: String(domain),
      },
    };

    const datares = await getBCReport({
      variables: inputBC,
    });

    if (createSheet) {
      const urlsheet = datares.data.callBCReport;
      sessionStorage.setItem('urlsheet', urlsheet);
    }
  };

  const [callAuthenticator] = useMutation(
    GET_AUTHENTICATE_BC,
    {
      onCompleted({ callAuthenticator }) {
        console.log('Auth completed!!!!!!!');
        console.log(callAuthenticator);
        sessionStorage.setItem('activeCard', callAuthenticator.activeCard);
        sessionStorage.setItem(
          'Hipoteca_Vigente',
          callAuthenticator.mortgageCredit,
        );
        sessionStorage.setItem(
          'Ultimos_4_digitos_de_tarjeta',
          callAuthenticator.digits,
        );
        sessionStorage.setItem(
          'Credito_automotriz_bancario_en_los_ultimos_do',
          callAuthenticator.automotiveCredit,
        );

        sessionStorage.setItem(
          'authenticationSavings',
          callAuthenticator.authenticationSavings,
        );
        sessionStorage.setItem('BCScore', callAuthenticator.BCScore);
        sessionStorage.setItem('RespuestaBC', callAuthenticator.RespuestaBC);
        sessionStorage.setItem('FechaAut', callAuthenticator.FechaAut);
        sessionStorage.setItem(
          'RespComentario',
          callAuthenticator.RespComentario,
        );

        if (
          callAuthenticator.activeCard === 'Si'
          || callAuthenticator.mortgageCredit === 'Si'
          || callAuthenticator.automotiveCredit === 'Si'
        ) {
          sessionStorage.setItem('tieneHistorial', true);
        } else {
          sessionStorage.setItem('tieneHistorial', false);
        }
        const tipoRP = getRPType();
        sessionStorage.setItem('tipoRP', tipoRP);

        if (callAuthenticator.BCScore.indexOf('BC Score ') !== -1) {
          sessionStorage.setItem(
            'BCScore',
            callAuthenticator.BCScore.replace('BC Score ', '').replace(/-/g, ''),
          );
          sessionStorage.setItem('statusAutenticacion', 'Autenticado');
          if (checkBCReport) callBCReport();
        } else {
          sessionStorage.setItem('statusAutenticacion', 'No autenticado');
        }
        sessionStorage.setItem(
          'Resp_Autenticate',
          JSON.stringify(callAuthenticator),
        );

        const buro = JSON.stringify(callAuthenticator).replace(/\\/g, '');
        console.log('### respuesta de buro >>>', buro);
        setResponseBuro(buro);
        setBCScore({
          success: true,
        });
        console.log('intento aout', triedAut);

        const filterJson = {
          _id: sessionStorage.getItem('id_session'),
        };
        const mongoUpdateInput = {
          input: {
            collection: 'sessions',
            data: JSON.stringify(callAuthenticator),
            filter: JSON.stringify(filterJson),
          },
        };
        if (sessionStorage.getItem('id_session')) { updateSession({ variables: mongoUpdateInput }); }
        setResponseBuro(buro);
        if (triedAut === 1) {
          setTriedAut(2);
        }
        if (sessionStorage.getItem('id_binacle')) {
          const timestap = new Date().toLocaleString();
          const filterJson = {
            _id: sessionStorage.getItem('id_binacle'),
          };
          const mongoInputBinacle = {
            RespuestaBC: [
              {
                value: sessionStorage.getItem('RespuestaBC'),
                timestap,
              },
            ],
            FechaAut: [
              {
                value: sessionStorage.getItem('FechaAut'),
                timestap,
              },
            ],
            Resp_Autenticate: [
              {
                value: sessionStorage.getItem('Resp_Autenticate'),
                timestap,
              },
            ],
            bancoActual: [
              {
                value: bancoActual || sessionStorage.getItem('bancoActual'),
                timestap,
              },
            ],
            digitoscard: [
              {
                value: digitoscard || '',
                timestap,
              },
            ],
            lastUpdate: timestap,
          };
          const filterString = JSON.stringify(filterJson);
          const dataStringBinacle = JSON.stringify(mongoInputBinacle);
          const mongoInput = {
            input: {
              collection: 'binacle',
              data: dataStringBinacle,
              filter: filterString,
            },
          };
          updateSession({ variables: mongoInput });
        }
        // debugger;
        if (
          nextOrdeRoutine !== 0
          && parseInt(sessionStorage.getItem('intentoAuth')) >= 1
        ) {
          console.log(`NextOrdeRoutine,${nextOrdeRoutine}`);
          if (
            sessionStorage.getItem('TarjetaDeposito')
            && buro.includes('BC Score ')
          ) {
            console.log('== ULTIMO ==');
            setOrderRutine(10);
          }
        }
        setTriedAut(triedAut + 1);
      },
      onError(err) {
        console.log('error autenticion: ', err);
        if (nextOrdeRoutine !== 0) {
          console.log(`Error,${nextOrdeRoutine}`);
          setOrderRutine(nextOrdeRoutine);
        }
      },
    },
    [triedAut],
  );

  const getRPType = () => {
    const ingreso = sessionStorage.getItem('Ingreso_Mensual_Fijo');
    const tieneHistorial = sessionStorage.getItem('tieneHistorial') === 'true' ? 'Si' : 'No';
    const score = parseInt(sessionStorage.getItem('BCScore')) || 0;

    if (ingreso === 'de_7000_a_15000') {
      if (tieneHistorial === 'Si') {
        if (score >= 640) {
          return 'RP_Tipo_1';
        }
        return 'RP_Tipo_2';
      }
      if (score >= 640) {
        return 'RP_Tipo_1';
      }
      return 'RP_Tipo_2';
    }
    if (ingreso === 'de_15000_a_30000') {
      if (tieneHistorial === 'Si') {
        if (score >= 640) {
          return 'RP_Tipo_1';
        }
        return 'RP_Tipo_2';
      }
      if (score >= 640) {
        return 'RP_Tipo_1';
      }
      return 'RP_Tipo_2';
    }
    if (ingreso === 'mas_de_30000') {
      if (tieneHistorial === 'Si') {
        if (score >= 600) {
          return 'RP_Tipo_1';
        }
        return 'RP_Tipo_2';
      }
      if (score >= 600) {
        return 'RP_Tipo_1';
      }
      return 'RP_Tipo_2';
    }
    return 'RP_Tipo_0';
  };

  useEffect(() => {
    console.log('ENtra Authtnticate');
    const arrayPaht = String(window.location.pathname).split('/');
    const ingreso = sessionStorage.getItem('Ingreso_Mensual_Fijo');

    console.log('## ingreso >>> ', ingreso);
    console.log(
      '## datOpportunity >>> ',
      sessionStorage.getItem('datOpportunity'),
    );
    const pathSource = arrayPaht[arrayPaht.length - 1];
    // debugger;
    if (ingreso !== 'menos_4000' && ingreso !== 'de_4000_a_7000') {
      if (
        sessionStorage.getItem('datOpportunity') != null
        && sessionStorage.getItem('datOpportunity') !== ''
        && sessionStorage.getItem('datOpportunity') !== undefined
      ) {
        console.log(sessionStorage.getItem('datOpportunity'));
        if (!BCScore.success) {
          if (sessionStorage.getItem('intentoAuth') === null) {
            sessionStorage.setItem('intentoAuth', parseInt(1));
          } else if (parseInt(sessionStorage.getItem('intentoAuth')) >= 2) { sessionStorage.setItem('intentoAuth', parseInt(1)); } else {
            sessionStorage.setItem(
              'intentoAuth',
              parseInt(sessionStorage.getItem('intentoAuth')) + 1,
            );
          }

          if (parseInt(sessionStorage.getItem('intentoAuth')) < 4) {
            const request = `primerNombre=${firstname.trim()}&segundoNombre=${secondname.trim()}&apPat=${lastname.trim()}&apMat=${secondlastname.trim()}&rfc=${rfc.trim()}&calle=${calle.trim()}&noext=${numext.trim()}&noint=${numint.trim()}&cpostal=${cp.trim()}&colonia=${colonia.trim()}&deleg=${municipio.trim()}&estado=${estado.trim()}&email=${email.trim()}&tarjeta=${activeCard}&digitos=${digitoscard}&hipotecario=${mortgageCredit}&automotriz=${automotiveCredit}&source=Coru`;
            console.log('consulta a buro', request);
            const { domain } = window.document;
            const inputAuth = {
              input: {
                idUser: sessionStorage.getItem('idUser'),
                idOportunity: sessionStorage
                  .getItem('datOpportunity')
                  .replace(/,/g, '_'),
                user: 'CORU',
                password: 'C0rU*2O19',
                dataPersonaEncode: btoa(request),
                bancoActual:
                  bancoActual || sessionStorage.getItem('bancoActual') || '',
                host: String(domain),
              },
            };
            console.log('### inputAuth >>>', JSON.stringify(inputAuth));
            callAuthenticator({
              variables: inputAuth,
            });
          }
        } else {
          console.log('Bscore condition failed', triedAut);
          setResponseBuro('Exclusión');
          if (nextOrdeRoutine !== 0) {
            console.log(`ErrorElse,${nextOrdeRoutine}`);
            setOrderRutine(nextOrdeRoutine);
          }
        }
      } else if (nextOrdeRoutine !== 0) {
        console.log(`ErrorElse,${nextOrdeRoutine}`);
        setOrderRutine(nextOrdeRoutine);
        setResponseBuro('Exclusión');
      }
    } else {
      // debugger;
      if (pathSource.indexOf('funnel-tarjeta') !== -1) {
        if (nextOrdeRoutine !== 0) {
          console.log('## Exclusión >>> 1');
          console.log(`ErrorElse,${nextOrdeRoutine}`);
          setOrderRutine(nextOrdeRoutine);
          setResponseBuro('Exclusión');
        }
      } else {
        console.log('## Exclusión >>> 2');
        setResponseBuro('Exclusión');
        console.log('NO AUTENTICA POR INGRESOS');
        setOrderRutine(nextOrdeRoutine);
      }
    }
  }, []);
  return <div></div>;
};

export default Authenticate;
