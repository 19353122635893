/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import { List, arrayMove } from 'react-movable';
import {
  TrainingQuestion,
  TrainingSectionGray,
  CardsBtn,
  CardsBtn1,
  DivRespDP,
  QuestionsText,
  QuestionsDivs,
  ErrorDiv,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import {
  Container, Image, Col10, Col2,
} from '../../components/common/common.styled';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import ShareDebts from '../Training/SectionShareDebts';
import IconMover from '../../assets/img/anacoach/move.png';

import check from '../../assets/img/anacoach/ticCheck.svg';
import uncheck from '../../assets/img/anacoach/ticUncheck.svg';
import CardMultipleDesc from '../../components/Home/CardMultipleDesc';
import SendNotificationDebts from '../../components/common/SendNotificationDebts';

const response = [
  'Deudas con amigos, conocidos o familiares (Prestamista informal)',
  'Tarjetas de crédito o crédito personal',
  'Tiendas departamentales',
  'Crédito automotriz',
  'Crédito hipotecario',
  'Otro tipo de deuda',
];
// const count = 0;
const TiposDeDeudas = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  activePossibleroutines = false,
  possibleRoutines = [],
  handleOpcDeuda,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [debtType, setDebtType] = useState([]);
  const [showDebtType, setShowDebtType] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [debtTypeSelected, setDebtTypeSelected] = useState([]); // sessionStorage.getItem('TipoDeuda') ||
  const { handleSendNotification } = SendNotificationDebts();
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [debtSolution, setDebtSolution] = useState(
    sessionStorage.getItem('QueDeudaSolucionarPrimero') || '',
  );
  const [amounts, setAmounts] = useState([]);
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({
    MontoPorDeudaAmigos: sessionStorage.getItem('MontoPorDeudaAmigos') || '',
    MontoDeudaTDC: sessionStorage.getItem('MontoDeudaTDC') || '',
    MontoDeudasTiendas: sessionStorage.getItem('MontoDeudasTiendas') || '',
    MontoDeudaCreditoAutomotriz:
      sessionStorage.getItem('MontoDeudaCreditoAutomotriz') || '',
    MontoDeudaCreditoHipotecario:
      sessionStorage.getItem('MontoDeudaCreditoHipotecario') || '',
    MontoOtraDeuda: sessionStorage.getItem('MontoOtraDeuda') || '',
  });
  const [showForm] = useState({
    MontoPorDeudaAmigos: false,
    MontoDeudaTDC: false,
    MontoDeudasTiendas: false,
    MontoDeudaCreditoAutomotriz: false,
    MontoDeudaCreditoHipotecario: false,
    MontoOtraDeuda: false,
  });
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const [showDebtsSelected, setShowDebtsSelected] = useState(false);
  const [alertFirst, setAlertFirst] = useState(false);
  const [showAmountsCard, setShowAmountCard] = useState(false);

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  useEffect(() => {
    if (debtType.length >= 1) {
      setShowBtn(true);
    }
  }, [debtType]);
  const handleChangeStatus = (numQuestion, name, value, type = '', e) => {
    const elements = document.querySelectorAll(`div[name="${name}"]`);
    let element;
    if (elements.length === 2) {
      element = elements[1];
    } else {
      element = elements[0];
    }

    const valId = value;
    if (element.classList.contains('border-pink-actives')) {
      document.getElementsByClassName(valId)[0].src = uncheck;
      element.classList.remove('border-pink-actives');
    } else {
      document.getElementsByClassName(valId)[0].src = check;
      element.classList.add('border-pink-actives');
    }

    if (numQuestion === undefined) return;
    const elementExist = debtType.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      const quetionsTemp = [];
      for (const element of debtType) {
        if (element.id !== elementExist[0].id) {
          quetionsTemp.push(element);
        }
      }
      setDebtType(quetionsTemp);
    } else {
      setDebtType([
        ...debtType,
        {
          id: name,
          question: numQuestion,
          value: name,
        },
      ]);
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      // console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const [asesorContact, setAsesorContact] = useState(false);
  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 7:
        setDebtSolution(value);
        if (activePossibleroutines) {
          handleSendNotification({ step: '3' })
          if (
            value
            === 'Deudas con amigos, conocidos o familiares (Prestamista informal)'
          ) {
            setOrderRutine(possibleRoutines[0]);
            handleOpcDeuda('DeudasInformales');
            setAsesorContact(false);
          } else if (value === 'Tarjetas de crédito o crédito personal') {
            setOrderRutine(possibleRoutines[1]);
            handleOpcDeuda('DeudasBancarias');
            setAsesorContact(false);
          } else if (value === 'Tiendas departamentales') {
            setOrderRutine(possibleRoutines[2]);
            handleOpcDeuda('DeudasDepartamentales');
            setAsesorContact(false);
          } else if (value === 'Crédito automotriz') {
            setOrderRutine(possibleRoutines[3]);
            handleOpcDeuda('VideoAutomotriz');
            setAsesorContact(false);
          } else if (value === 'Crédito hipotecario') {
            setOrderRutine(possibleRoutines[4]);
            handleOpcDeuda('CreditoHipotecario');
            setAsesorContact(false);
          } else if (value === 'Otro tipo de deuda') {
            setAsesorContact(true);
          }
        } else {
          setOrderRutine(nextOrderRoutine);
        }
        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const val = value.replace('$', '').replace(/,/g, '').trim();
    setForm({
      ...form,
      [name]: formatter.format(filterFloat(val)),
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          // console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              if (
                questionOrder > 1
                && responseUser !== null
                && responseUser !== ''
                && responseUser
              ) {
                setShowAmountCard(true);
              }

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  if (questionOrder === 1) {
                    // Si es la pregunta 1, vamos a revisar...

                    const newTipos = responseUser.split(',');

                    // La opción "Deudas con amigos, conocidos o ..." ya continene una coma,
                    // por lo que la separará como si fuesen 2 opciones
                    // Revisemos si esta opción se encuentra y en que índice del arreglo
                    const deudaAmigo = newTipos.indexOf('Deudas con amigos');

                    if (deudaAmigo !== -1) {
                      // Si la opción se encuentra...
                      const tempTipos = [];

                      // Loop en las opciones
                      for (let i = 0; i < newTipos.length; i++) {
                        if (i === deudaAmigo || i === deudaAmigo + 1) {
                          // Si la iteración es igual al índice o al siguiente de este
                          // (Recordemos que la opción se separó en dos)

                          if (i === deudaAmigo + 1) {
                            // Si nos encontramos en la iteración siguiente del índice
                            // juntamos la opción de la iteración anterior y la actual
                            // y la agregamos al arreglo temporal
                            tempTipos.push(`${newTipos[i - 1]},${newTipos[i]}`);
                          }
                        } else {
                          // Si la iteración no entra en la condición, entonces solo agregamos
                          // la opción de la iteración actual al arreglo temporal
                          tempTipos.push(newTipos[i]);
                        }
                      }

                      // Juntamos las opciones temporales en un solo string y las separamos con el símbolo /
                      const tiposToStorage = tempTipos.join('/');
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        tiposToStorage,
                      );
                    } else {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              // console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  const handleAmount = (e) => {
    handleSendNotification({ step: '4' })
    e.preventDefault();
    if (!isEmptyObject(Rutine)) {
      if (questions.length > 0) {
        const questionL = questions.length;
        const tempAmounts = [];
        for (let i = 0; i < questionL; i++) {
          tempAmounts.push(questions[i]);
        }
        setAmounts(tempAmounts);
        for (let i = 0; i < questions.length; i++) {
          const name = questions[i].id;
          showForm[name] = true;
          sessionStorage.setItem(name, questions[i].value);
        }
        createJsonInput(Rutine, questions);
        setQuestions([]);
      }
      setShowBtn(false);

      evaluate();
      setShowAmountCard(true);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeAmountComplete' });
  };

  const evaluate = () => {
    const tempNewDebts = [];

    const tamDebts = document.getElementById('activeTypesDebts').children.length;
    for (let index = 0; index < tamDebts; index++) {
      const element = document.getElementById('activeTypesDebts').children[index].innerText;

      tempNewDebts.push(element);
    }

    if (tempNewDebts && tempNewDebts.length === 1) {
      continueTypes();
    }
  };

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setDebtTypeSelected(
        sessionStorage.getItem('TipoDeuda')
          ? sessionStorage.getItem('TipoDeuda').split('/')
          : [],
      );
      setShowDebtType(!!sessionStorage.getItem('TipoDeuda'));
      setDebtSolution(
        sessionStorage.getItem('QueDeudaSolucionarPrimero') || '',
      );
      const TipoDeuda = sessionStorage.getItem('QueDeudaSolucionarPrimero') || '';
      if (activePossibleroutines) {
        if (
          TipoDeuda
          === 'Deudas con amigos, conocidos o familiares (Prestamista informal)'
        ) {
          setOrderRutine(possibleRoutines[0]);
          handleOpcDeuda('DeudasInformales');
        } else if (TipoDeuda === 'Tarjetas de crédito o crédito personal') {
          setOrderRutine(possibleRoutines[1]);
          handleOpcDeuda('DeudasBancarias');
        } else if (TipoDeuda === 'Tiendas departamentales') {
          setOrderRutine(possibleRoutines[2]);
          handleOpcDeuda('DeudasDepartamentales');
        } else if (TipoDeuda === 'Crédito automotriz') {
          setOrderRutine(possibleRoutines[3]);
          handleOpcDeuda('VideoAutomotriz');
        } else if (TipoDeuda === 'Crédito hipotecario') {
          setOrderRutine(possibleRoutines[4]);
          handleOpcDeuda('CreditoHipotecario');
        } else if (TipoDeuda === 'Otro tipo de deuda') {
          setAsesorContact(true);
        }
      } else {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const handleDebt = (e) => {
    e.preventDefault();

    if (debtType.length >= 1 && debtType.length <= 3) {
      setShowDebtsSelected(true);
      const storeTemp = debtType.map((item) => item.id);

      const resp = [
        {
          id: 'selecciontiposDeudas',
          question: 1,
          value: storeTemp.join(','),
        },
      ];

      createJsonInput(Rutine, resp);
      setQuestions([]);
      setDebtTypeSelected(storeTemp);
      setShowDebtType(true);
      setShowBtn(true);
    } else {
      setAlertFirst(true);
    }
  };

  const [lapiz] = useState([{ routine: '', active: '' }]);
  const [editTipoDeuda] = useState('');

  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (!showDebtType
          || (lapiz[0].routine === 3 && lapiz[0].active === 1))
        && editTipoDeuda !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeTipoDeuda' });
        setReviewScroll(false);
      }
    }, 1100);
  }, []);
  const [reviewScrollMonto, setReviewScrollMonto] = useState(true);
  const scrollToMontosDeuda = () => {
    setTimeout(() => {
      if (reviewScrollMonto) {
        gsap.to(window, { duration: 1, scrollTo: '#activeMontosDeudas' });
        setReviewScrollMonto(false);
      }
    }, 1200);
  };

  const newDebts = [];
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!showBtn) {
      const tamDebts = document.getElementById('activeTypesDebts').children.length;
      for (let index = 0; index < tamDebts; index++) {
        const element = document.getElementById('activeTypesDebts').children[index].innerText;
        newDebts.push(element);
        setItems(newDebts);
      }
    }
  }, [showBtn]);

  const continueTypes = () => {
    const tempNewDebts = [];

    let value = '';

    const tamDebts = document.getElementById('activeTypesDebts').children.length;
    if (tamDebts === 1) {
      for (let index = 0; index < tamDebts; index++) {
        const element = document.getElementById('activeTypesDebts').children[index].innerText;

        tempNewDebts.push(element);
      }
      value = tempNewDebts[0];
    } else {
      value = items[0];
    }

    setDebtSolution(value);

    if (activePossibleroutines) {
      if (
        value
        === 'Deudas con amigos, conocidos o familiares (Prestamista informal)'
      ) {
        setOrderRutine(possibleRoutines[0]);
        handleOpcDeuda('DeudasInformales');
        setAsesorContact(false);
      } else if (value === 'Tarjetas de crédito o crédito personal') {
        setOrderRutine(possibleRoutines[1]);
        handleOpcDeuda('DeudasBancarias');
        setAsesorContact(false);
      } else if (value === 'Tiendas departamentales') {
        setOrderRutine(possibleRoutines[2]);
        handleOpcDeuda('DeudasDepartamentales');
        setAsesorContact(false);
      } else if (value === 'Crédito automotriz') {
        setOrderRutine(possibleRoutines[3]);
        handleOpcDeuda('VideoAutomotriz');
        setAsesorContact(false);
      } else if (value === 'Crédito hipotecario') {
        setOrderRutine(possibleRoutines[4]);
        handleOpcDeuda('CreditoHipotecario');
        setAsesorContact(false);
      } else if (value === 'Otro tipo de deuda') {
        setAsesorContact(true);
      }
    } else {
      setOrderRutine(nextOrderRoutine);
    }
  };

  const pStyle = {
    marginTop: '2px',
    marginLeft: '5px',
    fontSize: '.8rem',
  };

  const handleContinueTypes = () => {
    continueTypes();
  };

  return (
    <div>
      <div id="activeTipoDeuda"></div>
      <QuestionsOrder
        classCard='cardOne'
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2={<p style={pStyle}>* Elige 3 Opciones. </p>}
        hora={hora}
      />

      <TrainingQuestion
        className={
          (!showDebtType
            || (lapiz[0].routine === 3 && lapiz[0].active === 1))
          && editTipoDeuda !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeStatus}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
          changeCheck={true}
        />

        <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
          <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
            <div style={{ display: 'flex', width: '100%' }}>
              <br />
              {!showDebtsSelected ? (
                <CardsBtn
                  onClick={(e) => handleDebt(e)}
                  style={{
                    borderRadius: '4px',
                    letterSpacing: '-0.3px',
                    lineHeight: '140%',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                  }}
                  min_w="95%"
                  min_web="350px"
                  mtopw="20px"
                  mleft="10px"
                  mtop="15px"
                  weight="normal!important"
                >
                  Continuar
                </CardsBtn>
              ) : null}
              <br />
            </div>
            <TrainingQuestion className={alertFirst === true ? '' : 'hidden'}>
              <ErrorDiv align="center">
                Selecciona por lo menos un tipo de deuda
              </ErrorDiv>
            </TrainingQuestion>
          </Container>
        </TrainingSectionGray>
      </TrainingQuestion>
      <div id="activeMontosDeudas"></div>
      <DivRespDP>
        {currentRoutine === orderRutine ? (
          <img
            alt="editIcon"
            className={showDebtType ? 'iconEdit1' : 'hidden'}
            src={editIcon}
            onClick={() => {
              if (debtSolution === '' && !showBtn) {
                setShowBtn(true);
                setShowAmountCard(false);
              } else if (showDebtType && showBtn) {
                if (!showAmountsCard) {
                  setShowDebtType(false);
                  setShowDebtsSelected(false);
                } else {
                  setShowAmountCard(false);
                  setShowDebtsSelected(false);
                }
              } else {
                setShowDebtType(false);
              }
            }}
          />
        ) : null}

        {!showAmountsCard ? (
          <CardTwo
            classCard={
              showDebtType
              && (editTipoDeuda
                || !(lapiz[0].routine === 3 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={debtTypeSelected[0] ? debtTypeSelected[0] : ''}
            text2={debtTypeSelected[1] ? debtTypeSelected[1] : ''}
            text3={debtTypeSelected[2] ? debtTypeSelected[2] : ''}
            colorTraining={trainingColor}
          ></CardTwo>
        ) : null}
      </DivRespDP>

      {/* nuevos inputs */}
      {showDebtType ? (e) => scrollToMontosDeuda(e) : null}

      {!showAmountsCard
        ? response.map((questionOption, index) => {
          if (
            debtTypeSelected.indexOf(questionOption) !== -1
              && amounts.length <= 3
              && showDebtType
          ) {
            const arrayForm = Object.keys(showForm);
            if (sessionStorage.getItem(arrayForm[index])) {
              showForm[arrayForm[index]] = true;
            }
            return (
                <>
                  <QuestionsOrder
                    classCard={showDebtType ? 'cardOne' : 'hidden'}
                    orderRutine={currentRoutine}
                    step={index + 1}
                    handleChangeRutine={handleChangeRutine}
                    currentTraining={currentTraining}
                    textBold=""
                    text2=""
                    hora={hora}
                  />
                  <TrainingQuestion
                    wiw="60%"
                    mLg="18%"
                    className={
                      ''
                      /*! showForm[arrayForm[index]] ? '' : 'hidden' */
                    }
                  >
                    <InputsOrder
                      handleChangeRutine={handleChangeRutine}
                      orderRutine={orderRutine}
                      step={`${index + 1}-${index + 1}`}
                      handleChangeQuestionsSend={handleChangeQuestionsSend}
                      handleChangeCatOption={handleChangeCatOption}
                      form={form}
                      labelInput="Ingresa el monto de la deuda"
                      handleChange={handleChange}
                      currentTraining={currentTraining}
                    />
                  </TrainingQuestion>
                </>
            );
          }
        })
        : null}

      {showAmountsCard ? (
        <CardMultipleDesc colorTraining={trainingColor} extraClass="topTwo1">
          {response.map((questionOption, index) => {
            if (
              debtTypeSelected.indexOf(questionOption) !== -1
              && amounts.length <= 3
              && showDebtType
            ) {
              const arrayForm = Object.keys(showForm);
              if (sessionStorage.getItem(arrayForm[index])) {
                showForm[arrayForm[index]] = true;
              }
              return (
                <>
                  {index > 0 ? <span className="space-top" /> : ''}
                  <p>
                    {debtTypeSelected[index] ? debtTypeSelected[index] : ''}
                  </p>
                  <b>
                    Monto de deuda:{' '}
                    {sessionStorage.getItem(arrayForm[index])
                      ? sessionStorage.getItem(arrayForm[index])
                      : 'sin datos'}
                  </b>
                </>
              );
            }
          })}
        </CardMultipleDesc>
      ) : null}

      <div id="activeAmountComplete"></div>
      <TrainingSectionGray
        className={showDebtType && showBtn ? '' : 'hidden'}
        wiw="670px"
        mw="auto"
        back=" "
        mtop="0"
      >
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn1 onClick={(e) => handleAmount(e)} windthmd="100%">
              Continuar
            </CardsBtn1>
            <br />
          </div>
        </Container>
      </TrainingSectionGray>

      <QuestionsOrder
        classCard={!showBtn ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={7}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        wiw="75%"
        mLg="11%"
        className={debtSolution === '' && !showBtn ? '' : 'hidden'}
      >
        <List
          values={items}
          onChange={({ oldIndex, newIndex }) => {
            setItems(arrayMove(items, oldIndex, newIndex));
          }}
          renderList={({ children, props }) => (
            <ul style={{ listStyle: 'none', paddingLeft: 0 }} {...props}>
              {children}
            </ul>
          )}
          renderItem={({ value, props }) => (
            <li {...props}>
              <QuestionsDivs
                bc="#F7F7F7"
                width="100%"
                widthweb="80%"
                margin="8px auto auto;"
                hei="auto"
                bs="0px 2px 8px rgba(0,0,0,0.08)"
              >
                <QuestionsText size="14px" weight="400">
                  <Container>
                    <Col10>{value}</Col10>
                    <Col2>
                      <Image src={IconMover} width="40px" alt={value} />
                    </Col2>
                  </Container>
                </QuestionsText>
              </QuestionsDivs>
            </li>
          )}
        />

        <div id="activeTypesDebts" className="hidden">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="7-7"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            classificationQuestionDetail={debtTypeSelected}
          />
        </div>
      </TrainingQuestion>

      <TrainingSectionGray
        className={debtSolution === '' && !showBtn ? '' : 'hidden'}
        wiw="670px"
        mw="auto"
        back=" "
        mtop="0"
      >
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn1 onClick={(e) => handleContinueTypes(e)} windthmd="100%">
              Continuar
            </CardsBtn1>
            <br />
          </div>
        </Container>
      </TrainingSectionGray>

      <CardTwo
        classCard={debtSolution !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={debtSolution}
        colorTraining={trainingColor}
      />

      <CardOne
        classCard={asesorContact ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        textbold="Contactar un asesor financiero"
        text="En tu caso, la mejor alternativa es tener la orientación personal de un asesor Coru, quien te explicará cómo administrar tu deuda y si es necesario dar un seguimiento especial a cada caso."
        text2="Programa una asesoría con Coru, sin costo ni compromiso, en el correo servicio@coru.com o el teléfono (+52) 55 5011 9400"
        hora={hora}
      >
        {/* <Link to="https://www.elfinanciero.com.mx/inmobiliario/como-se-hace-un-traspaso-de-credito-hipotecario-estos-pasos-te-ayudaran">¿Cómo se hace un traspaso de crédito hipotecario?</Link>
         */}
      </CardOne>
      {asesorContact === true ? <ShareDebts></ShareDebts> : null}
    </div>
  );
};

export default TiposDeDeudas;
