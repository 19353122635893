import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, ParagrapTwo,
} from '../common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
// componente usado para mostrar un texto descriptivo en el componente de categorias
function CategoryText({ categorySelected, setCategory, description }) {
  const [paragraph1, setParagraph1] = useState('');
  const [paragraph2, setParagraph2] = useState('');
  const [beforeBold, setBeforeBold] = useState('');
  const [boldText, setBoldText] = useState('');
  const [afterBold, setAfterBold] = useState('');
  const { actions } = ClickButton();

  const history = useHistory();

  useEffect(() => {
    console.log('category',categorySelected, setCategory, description)
    setCategory(categorySelected);
  }, []);

  useEffect(() => {
    const brIndex = description.indexOf('<br />');
    if (brIndex > 0) {
      setParagraph1(description.substring(0, brIndex));
      setParagraph2(description.substring(brIndex + 6, description.length));
    } else {
      setParagraph2(description);
    }
  }, []);

  useEffect(() => {
    const strongIndex = paragraph2.indexOf('<strong>');
    const strongClousureIndex = paragraph2.indexOf('</strong>');
    if (paragraph2.length > 0) {
      setBeforeBold(paragraph2.substring(0, strongIndex));
      setBoldText(paragraph2.substring(strongIndex + 8, strongClousureIndex));
      setAfterBold(paragraph2.substring(strongClousureIndex + 9, paragraph2.length));
    }
  }, [paragraph2]);

  const handleClick = (e) => {
    e.stopPropagation();
    sessionStorage.setItem('categorieActive', categorySelected);
    switch (categorySelected) {
      case 1:
        actions({ action: 'ClickButtonCoruHome', variables: 'GastosInteligentesVerEntrenamientos', redirection: '/lp/categories-products'});
        break;
      case 2:
        actions({ action: 'ClickButtonCoruHome', variables: 'PréstamosConscientesVerEntrenamientos', redirection: '/lp/categories-products'});
        break;
      case 3:
        actions({ action: 'ClickButtonCoruHome', variables: 'PlaneaciónEficienteVerEntrenamientos', redirection: '/lp/categories-products'});
        break;
      case 4:
        actions({ action: 'ClickButtonCoruHome', variables: 'ProtecciónEfectivaVerEntrenamientos', redirection: '/lp/categories-products'});
        break;
      default:
        break;
    }
    // history.push('/lp/categories-products');
  };

  return (
        <>
            <ParagrapTwo className="roboto" fontSizeWeb="25px" mt="0" mtWeb="0">
                {paragraph1} {paragraph1.length > 0 ? <br /> : null} {beforeBold} <b> {boldText} </b> {afterBold}
            </ParagrapTwo>
            <Button className="d-block mt-5 mt-md-4 roboto" onClick={(e) => handleClick(e)}>
                Ver entrenamientos
            </Button>
        </>
  );
}

export default CategoryText;
