import React, { useState } from 'react';

import '../../assets/styles/perfil/perfiles.css';
import {
  DivSelectionFilter2,
  DivFiltersName,
  DivFiltersNameText,
} from '../../assets/styles/Training.styled';

function FiltersOptions({
  options, setBankFilter = [], setAnnuityFilter = [], setCardFilter = [], filterType, setFlag, display, around = false, countResults, handleShowResults,
}) {
  const [optionsActive, setOptionsActive] = useState([]);
  const activeOptions = (id) => {
    if (optionsActive.includes(id)) {
      if (optionsActive.length > 1) {
        const newOptions = optionsActive.filter((el) => el != id);
        setOptionsActive(newOptions);
      } else {
        setOptionsActive([]);
      }
    } else {
      setOptionsActive([...optionsActive, id]);
    }
    const filterActive = document.getElementById(id);
    const x2 = filterActive.classList;
    if (String(x2).includes('borderFilter')) {
      filterActive.classList.remove('borderFilter');
    } else {
      filterActive.classList.add('borderFilter');
    }
    setFlag(true);
    if (filterType === 'Banco') {
      if (!setBankFilter.includes(id)) {
        setBankFilter.push(id);
      } else {
        const index = setBankFilter.indexOf(id);
        setBankFilter.splice(index, 1);
      }
    } else if (filterType === 'Anualidad') {
      if (!setAnnuityFilter.includes(id)) {
        if (id !== 'Todas las anualidades') {
          setAnnuityFilter.push(id);
        }
      } else {
        const index = setAnnuityFilter.indexOf(id);
        setAnnuityFilter.splice(index, 1);
      }
    } else if (!setCardFilter.includes(id)) {
      setCardFilter.push(id);
    } else {
      const index = setCardFilter.indexOf(id);
      setCardFilter.splice(index, 1);
    }
  };

  const cleanFilters = () => {
    for (let bank = 0; bank < setBankFilter.length; bank++) {
      const filterActive = document.getElementById(setBankFilter[bank]);
      const x2 = filterActive.classList;
      if (String(x2).includes('borderFilter')) {
        filterActive.classList.remove('borderFilter');
      }
    }
    for (let annuity = 0; annuity < setAnnuityFilter.length; annuity++) {
      const filterActive = document.getElementById(setAnnuityFilter[annuity]);
      const x2 = filterActive.classList;
      if (String(x2).includes('borderFilter')) {
        filterActive.classList.remove('borderFilter');
      }
    }
    for (let category = 0; category < setCardFilter.length; category++) {
      const filterActive = document.getElementById(setCardFilter[category]);
      const x2 = filterActive.classList;
      if (String(x2).includes('borderFilter')) {
        filterActive.classList.remove('borderFilter');
      }
    }
    setBankFilter.length = 0;
    setAnnuityFilter.splice(0, setAnnuityFilter.length);
    setCardFilter.splice(0, setCardFilter.length);
    setFlag(true);
  };

  const getText = () => {
    if (optionsActive.length <= 0) return <></>;
    return <>Se {countResults > 1 ? 'muestran' : 'muestra'} {countResults} {countResults > 1 ? 'resultados' : 'resultado'}</>;
  };

  return (
        <>
            <DivSelectionFilter2 paddM="6px" paddMM="0" padd="0" display={display} margin="20px auto auto 18%" marginm="20px 0 0 0" hei="auto">
                <DivSelectionFilter2 paddM="0" paddMM="0" jc="start" display={display} columns="3" id="OptionsFilters" padd="0" bs="none" margin="0" marginm="0" hei="auto" >
                    {options.map((option, index) => (
                        <DivFiltersName marginM="10px 8.8px" marginMG="10px 6.5px" marginMI="10px 8.8px" margin="10px 14px" marginMM="10px 2.5px" marginMC="10px 14px" wi={around ? '45%' : '105px'} wim={around ? '45%' : '90px'} key={`${`${index}-${option}`}`} mb="15px" onClick={() => activeOptions(`${option}`)} id={option}>{option}</DivFiltersName>
                    ))}
                </DivSelectionFilter2>
                <hr />
                <div style={{
                  display: 'flex', width: '100%', justifyContent: 'space-around', padding: '10px 5px',
                }}>
                <DivFiltersName padd='4px' wi='45%' wim='45%' bcolor='#F64282' mb='0' color="#F64282" onClick={() => cleanFilters()}>Limpiar filtros</DivFiltersName>
                <DivFiltersNameText padd='4px' wi='47%' wim='47%' bcolor='#FAFAFA' backColor='#FAFAFA' mb='0' color="#F64282" >{getText()}</DivFiltersNameText>
                </div>
            </DivSelectionFilter2>
        </>
  );
}
export default FiltersOptions;
