import React, { Fragment, useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, ParagrapTwo } from './common.styled';

function GeneralLoader() {
  const [form, setform] = useState({
    expuesta: false,
    antecedentes: true,
    privacidad: true,
  });
  const date = new Date();
  const mes = date.getMonth() + 1;
  const fecha = `${date.getDate()}/${mes}/${date.getFullYear()}`;
  const [text] = useState({
    antecedentes: `Hoy siendo ${fecha} autorizo a Coru (Compara Guru México S.A de C.V) a consultar mis antecedentes crediticios por única ocasión ante las Sociedades De Información Crediticia que estime convenientes, declarando que conozco la naturaleza, alcance y uso que Coru hará de tal información.`,
    privacidad: `Hoy siendo ${fecha} autorizo a las instituciones financieras que se indican en el aviso de privacidad a consultar mis antecedentes crediticios por única ocasión ante las Sociedades De Información Crediticia que estimen convenientes, declarando que conozco la naturaleza, alcance y uso que harán de tal información, autorizo a Coru a confirmar y/o comunicar esta autorización a dichas instituciones financieras en propios portales de internet.`,
  });

  const handleChange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };
  // eslint-disable-next-line no-console
  console.log('Hola');

  useEffect(() => {
    if (!form.antecedentes || !form.privacidad) {
      if (document.getElementById('btnContinuarDisclaimers') !== null) {
        document.getElementById('btnContinuarDisclaimers').disabled = true;
      }
    } else if (document.getElementById('btnContinuarDisclaimers') !== null) {
      document.getElementById('btnContinuarDisclaimers').disabled = false;
    }
  }, [form.antecedentes, form.privacidad]);

  return (
        <Fragment>
            <Container style={{ marginTop: '35px', marginBottom: '35px' }}>
                <div className="col-2 col-md-2 col-lg-2 checkAzul" style={{padding:"0px"}}>
                    <Checkbox checked={form.antecedentes} onChange={handleChange} name="antecedentes"/>
                </div>
                <div className="col-10 col-md-10 col-lg-10" style={{padding:"0px"}}>
                    <ParagrapTwo style={{ textAlign: 'justify' }} fontSize="11px" fontSizeWeb="11px" className="mx-auto roboto" mb='0%' mt="10px" mbweb="0%" color="#000" fontWeight=" " mtWeb="10px">
                        {text.antecedentes}
                    </ParagrapTwo>
                </div>
                <div className="col-2 col-md-2 col-lg-2 checkAzul" style={{padding:"0px"}}>
                    <Checkbox checked={form.privacidad} onChange={handleChange} name="privacidad"/>
                </div>
                <div className="col-10 col-md-10 col-lg-10" style={{padding:"0px"}}>
                    <ParagrapTwo style={{ textAlign: 'justify' }} fontSize="11px" fontSizeWeb="11px" className="mx-auto roboto" mb='0%' mt="10px" mbweb="0%" color="#000" fontWeight=" " mtWeb="10px">
                        {text.privacidad}
                    </ParagrapTwo>
                </div>
                <div className="col-2 col-md-2 col-lg-2 checkAzul" style={{padding:"0px"}}>
                    <Checkbox checked={form.expuesta} onChange={handleChange} name="expuesta"/>
                </div>
                <div className="col-10 col-md-10 col-lg-10" style={{padding:"0px"}}>
                    <ParagrapTwo fontSize="11px" fontSizeWeb="11px" className="mx-auto roboto" mb='0%' mt="10px" mbweb="0%" color="#000" fontWeight=" " mtWeb="10px">
                        Soy una persona políticamente expuesta.
                    </ParagrapTwo>
                </div>
            </Container>
        </Fragment>
  );
}

export default GeneralLoader;
