/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const BienestarViajes = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [viajesAlAnio, setViajesAlAnio] = useState(
    sessionStorage.getItem('ViajesAlAnio') || '',
  );
  const [comprasEnViajes, setComprasEnViajes] = useState(
    sessionStorage.getItem('ComprasEnViajes') || '',
  );
  const [presupuetoFamiliarMensual, setPresupuetoFamiliarMensual] = useState(
    sessionStorage.getItem('PresupuetoFamiliarMensual') || '',
  );
  const [seguroFamiliar, setSeguroFamiliar] = useState(
    sessionStorage.getItem('SeguroFamiliar') || '',
  );
  const [prioridadFamiliarViaje, setPrioridadFamiliarViaje] = useState(
    sessionStorage.getItem('PrioridadFamiliarViaje') || '',
  );
  const [form, setForm] = useState({});
  const handleChange = (e, question = '') => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setEditCuantoViajas(true);
        setViajesAlAnio(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeComprasViajes' });
        break;
      case 1:
        setEditVasdeCompras(true);
        setComprasEnViajes(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activesPresupuestoMenV' });
        break;
      case 2:
        setEditPresupuesto(true);
        setPresupuetoFamiliarMensual(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeSeguroFamV' });
        break;
      case 3:
        setEditSegFam(true);
        setSeguroFamiliar(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activePrioridadFamV' });
        if (val.value === 'No') {
          // todo mandar al rp

          if (nextOrderRoutine === 'rp') {
            insuranceActive.push('AXA Seguro de viaje');
            setActiveSucces(true);
          }
        }
        break;
      case 4:
        setEditPrioridad(true);
        setPrioridadFamiliarViaje(val.value);
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          if (val.value === 'Seguro de viaje') {
            insuranceActive.push('MAPFRE Seguro de viaje');
            insuranceActive.push('AXA Seguro de viaje');
          } else if (val.value === 'Seguro de robo contra gadgets') {
            insuranceActive.push('AXA Robo de gadgets');
          } else if (val.value === 'Seguro de compras') {
            insuranceActive.push('AXA Seguro de compras');
          } else if (
            val.value === 'Seguro de accidentes personales generales'
          ) {
            insuranceActive.push(
              'GNP Seguro de accidentes personales generales',
            );
            insuranceActive.push(
              'MAPFRE Seguro de accidentes personales generales',
            );
          }
          setActiveSucces(true);
        }
        break;
      default:
        console.log(val);
    }
  };
  // funcionalidad propia de la rutina

  // Scroll next routine
  const scrollToRoutine = (id) => {
    const scroll = document.getElementById(id);
    scroll.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      // setForm({...form,	directionMaps: "" });
      setViajesAlAnio(sessionStorage.getItem('ViajesAlAnio') || '');
      setComprasEnViajes(sessionStorage.getItem('ComprasEnViajes') || '');
      setPresupuetoFamiliarMensual(
        sessionStorage.getItem('PresupuetoFamiliarMensual') || '',
      );
      setSeguroFamiliar(sessionStorage.getItem('SeguroFamiliar') || '');
      setPrioridadFamiliarViaje(
        sessionStorage.getItem('PrioridadFamiliarViaje') || '',
      );
      if (sessionStorage.getItem('SeguroFamiliar') === 'No') {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          insuranceActive.push('AXA Seguro de viaje');
          setActiveSucces(true);
        }
      }
      if (
        sessionStorage.getItem('PrioridadFamiliarViaje') !== null
        && sessionStorage.getItem('PrioridadFamiliarViaje') !== ''
      ) {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          if (
            sessionStorage.getItem('PrioridadFamiliarViaje')
            === 'Seguro de viaje'
          ) {
            insuranceActive.push('MAPFRE Seguro de viaje');
            insuranceActive.push('AXA Seguro de viaje');
          } else if (
            sessionStorage.getItem('PrioridadFamiliarViaje')
            === 'Seguro de robo contra gadgets'
          ) {
            insuranceActive.push('AXA Robo de gadgets');
          } else if (
            sessionStorage.getItem('PrioridadFamiliarViaje')
            === 'Seguro de compras'
          ) {
            insuranceActive.push('AXA Seguro de compras');
          } else if (
            sessionStorage.getItem('PrioridadFamiliarViaje')
            === 'Seguro de accidentes personales generales'
          ) {
            insuranceActive.push(
              'GNP Seguro de accidentes personales generales',
            );
            insuranceActive.push(
              'MAPFRE Seguro de accidentes personales generales',
            );
          }
          setActiveSucces(true);
        }
      }
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editCuantoviajas, setEditCuantoViajas] = useState('');
  const [editVasdeCompras, setEditVasdeCompras] = useState('');
  const [editPresupuesto, setEditPresupuesto] = useState('');
  const [editSegFamiliar, setEditSegFam] = useState('');
  const [editPrioridad, setEditPrioridad] = useState('');
  const handleChangeIcon = (json) => {
    setEditCuantoViajas(false);
    setEditVasdeCompras(false);
    setEditPresupuesto(false);
    setEditSegFam(false);
    setEditPrioridad(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (viajesAlAnio === ''
          || (lapiz[0].routine === currentRoutine
            && lapiz[0].active === 1))
        && editCuantoviajas !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeBienestarViajes' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeBienestarViajes"></div>

        <QuestionsOrder
          classCard="cardOne"
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (viajesAlAnio === ''
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1))
            && editCuantoviajas !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeComprasViajes"></div>
        <CardTwo
          classCard={
            viajesAlAnio !== ''
            && (editCuantoviajas === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={viajesAlAnio}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              viajesAlAnio !== '' && comprasEnViajes === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            viajesAlAnio !== ''
            && (editCuantoviajas === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((viajesAlAnio !== '' && comprasEnViajes === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 1
            )
              || editCuantoviajas === true)
            && editVasdeCompras !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activesPresupuestoMenV"></div>
        <CardTwo
          classCard={
            comprasEnViajes !== ''
            && (editVasdeCompras === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={comprasEnViajes}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              comprasEnViajes !== '' && presupuetoFamiliarMensual === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            comprasEnViajes !== ''
            && (editVasdeCompras === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((comprasEnViajes !== '' && presupuetoFamiliarMensual === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 2
            )
              || editVasdeCompras === true)
            && editPresupuesto !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeSeguroFamV"></div>
        <CardTwo
          classCard={
            presupuetoFamiliarMensual !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={presupuetoFamiliarMensual}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              presupuetoFamiliarMensual !== '' && seguroFamiliar === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 3 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            presupuetoFamiliarMensual !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((presupuetoFamiliarMensual !== '' && seguroFamiliar === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 3
            )
              || editPresupuesto === true)
            && editSegFamiliar !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activePrioridadFamV"></div>
        <CardTwo
          classCard={
            seguroFamiliar !== ''
            && (editSegFamiliar === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={seguroFamiliar}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              seguroFamiliar !== '' && prioridadFamiliarViaje === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 4 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            seguroFamiliar !== ''
            && (editSegFamiliar === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={4}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((seguroFamiliar !== '' && prioridadFamiliarViaje === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 5))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 4
            )
              || editSegFamiliar === true)
            && editPrioridad !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            prioridadFamiliarViaje !== ''
            && (editPrioridad === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 5
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={prioridadFamiliarViaje}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              prioridadFamiliarViaje !== '' ? 'iconEditCommon' : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 5 })
            }
          />
        </CardTwo>
      </div>
  );
};

export default BienestarViajes;
