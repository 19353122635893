import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { GET_PHONE_VALIDATE } from '../../mutations';

export const GET_CLIENTES = gql`
  {
    getClientes {
      id
      nombre
      apellido
      email
    }
  }
`;

const Phone_Validate = () => {
  const [phone, setPhone] = useState('');
  const [
    phoneValidate,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(GET_PHONE_VALIDATE, {
    onCompleted({ phoneValidate }) {
      console.log(phoneValidate);
    },
  });

  if (mutationLoading) return <p>Loading...</p>;
  if (mutationError) return <p>Error :(</p>;
  if (mutationData) {
    console.log(mutationData.phoneValidate);
    return <p>`${mutationData.phoneValidate}`</p>;
  }

  const send = (e) => {
    e.preventDefault();
    try {
      const cel = e.target.value;
      setPhone(cel);
      if (cel.length === 10) {
        phoneValidate({ variables: { phone: cel } });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <input
        value={phone}
        onChange={(e) => {
          send(e);
        }}
      />
      <button>Update Todo</button>
    </div>
  );
};

export default Phone_Validate;
