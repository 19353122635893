import React from 'react';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  ImageSections,
  DivStyle,
  TrainingBenef,
  CardsBtn,
  Resultado,
}
  from '../../assets/styles/Training.styled';
import {
  CREATE_OPPORTUNITIES,
} from '../../mutations';
import { Container, Col2, Col10 } from '../common/common.styled';

function Resultados(props) {
  console.log(props);
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        let clickOut = sessionStorage.getItem('clickOutProduct');
        clickOut = clickOut.replace(
          '[idOpportunity]',
          response.idOpportunities
        );
        clickOut = clickOut.replace(
            '[IDUser]',
            sessionStorage.getItem('idUser'),
          );
        window.open(
          clickOut,
          '_blank',
        )
        TagManager.dataLayer(tagManagerArgs);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const createOpportunity = (clickOut, idProduct) => {
    sessionStorage.setItem('clickOutProduct', clickOut)
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1, // remplazar por el id del entrenamiento
        idProducts: Number(idProduct), // arreglo de numero ejemplo [10,1,3]
        idTraining: 34,
      },
    };
    console.log('Datos', opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
  };
  const content = props.cards.map((post) =>
    // const content =
    <>
        <Resultado ta="left" padd="20px" wid="335px" className='dm-sans' minh='245px' minhweb='245px'>
            <ImageSections width='80px' widthweb='80px'
                src={post.img} className='img-fluid' />
            <br />
            <DivStyle align="left" weight="bold" mt="22px" bott="13px" className="cabin" size='20px' line='120%'>
                {post.name}
            </DivStyle>
            <hr />
            <Container key="" width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem" bb="none">
                <Col2 display="flex" mt="initial">
                    <TrainingBenef
                        bbs="none"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="center"
                        ls="-0.2px"
                        width="100%"
                    >
                        <CheckBoxIcon style={{ color: '#91D770', fontSize: 23 }} />
                    </TrainingBenef>
                </Col2>
                <Col10 padding="0px" pMobile="0px 15px">
                    <TrainingBenef
                        bbsw="none"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                    >
                        En línea, en minutos
                    </TrainingBenef>
                </Col10>
                <Col2 display="flex" mt="initial">
                    <TrainingBenef
                        bbs="none"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="center"
                        ls="-0.2px"
                        width="100%"
                    >
                        <CheckBoxIcon style={{ color: '#91D770', fontSize: 23 }} />
                    </TrainingBenef>
                </Col2>
                <Col10 padding="0px" pMobile="0px 15px">
                    <TrainingBenef
                        bbsw="none"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                    >
                        Sin anualidad
                    </TrainingBenef>
                </Col10>
                <Col2 display="flex" mt="initial">
                    <TrainingBenef
                        bbs="none"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="center"
                        ls="-0.2px"
                        width="100%"
                    >
                        <CheckBoxIcon style={{ color: '#91D770', fontSize: 23 }} />
                    </TrainingBenef>
                </Col2>
                <Col10 padding="0px" pMobile="0px 15px">
                    <TrainingBenef
                        bbsw="none"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                    >
                        Sin historial crediticio
                    </TrainingBenef>
                </Col10>
                <CardsBtn min_w="100%" min_web="100%"><b>Guardar</b></CardsBtn>
            </Container>

        </Resultado>
    </>);
  return (
        <div className="marginAuto">
        {props.cards.map((post) => <Resultado ta="left" padd="20px" wid="335px" className='dm-sans' minh='245px' minhweb='245px'>
            <ImageSections width='80px' widthweb='80px'
                src={post.img} className='img-fluid' />
            <br />
            <DivStyle align="left" weight="bold" mt="22px" bott="13px" className="cabin" size='20px' line='120%'>
                {post.name}
            </DivStyle>
            <hr />
            <Container key="" width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem" bb="none">
                {post.beneficios.map((beneficio) => <>
                <Col2 display="flex" mt="initial">
                    <TrainingBenef
                        bbs="none"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="center"
                        ls="-0.2px"
                        width="100%"
                    >
                        <CheckBoxIcon style={{ color: '#91D770', fontSize: 23 }} />
                    </TrainingBenef>
                </Col2>
                <Col10 padding="0px" pMobile="0px 15px">
                    <TrainingBenef
                        bbsw="none"
                        pt="17px"
                        pb="17px"
                        he="auto"
                        pl="0px"
                        mt="0px"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                    >
                        {beneficio.description}
                    </TrainingBenef>
                </Col10>
                </>)}
                {post.clickOut !== '' && post.clickOut !== null && post.clickOut !== undefined ? (
                    <CardsBtn min_w="100%" min_web="100%" onClick={() => createOpportunity(post.clickOut, post.idProduct)}><b>Aplicar</b></CardsBtn>
                ) : null }
            </Container>

        </Resultado>)}
        </div>
  );
}

export default Resultados;
