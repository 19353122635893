import React, { Fragment, useEffect } from 'react';
import { Main, Container } from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import FooterFeed from '../../components/Feed/Footer_Feed';
import Summary from '../../components/Logros/ActivitySummary';
import Rachas from '../../components/Logros/Rachas';
import Trofeos from '../../components/Logros/Trofeos';
import Destacar from '../../components/Logros/Destacar';
import Header_Logros from '../../components/Perfiles/Header_Perfil';

function Recap({ Novisible, paddingInicio }) {
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  return (
    <Fragment>
      {!Novisible ? (
        <Header_Logros name={sessionStorage.getItem('firstName')} />
      ) : (
        <div></div>
      )}
      <Main
        minheigth="auto"
        background="#fafafa"
        pb={!paddingInicio ? '10%' : ' '}
        mt="0%"
        mtWeb="0%"
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
      >
        <Container
          id="Recap"
          displayweb="block"
          className="container"
          paddingMobile="0!important"
          width="94%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <Summary />
          <Rachas />
          <Trofeos />
          <Destacar />
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="logros" /> : <div></div>}
    </Fragment>
  );
}
export default Recap;
