import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_ROUTINES_BY_ID_TRAINING } from '../../../queries';
import CustomQuestion from '../steps/dynamicQuestions/CustomQuestion';
import AddressData from '../steps/staticQuestions/AddressData';
import BirthData from '../steps/staticQuestions/BirthData';
import CarInsurance from '../steps/staticQuestions/CarInsurance';
import PersonalData from '../steps/staticQuestions/PersonalData';

const RoutineSelector = ({
  routineData,
  idTraining,
  customColor,
  isActive,
  nextHandler,
  backHandler,
}) => {
  console.log('idTraining >> ', idTraining, ' orderRoutine >> ', routineData);

  const [routineComponentData, setRoutineComponentData] = useState({});
  const [isReady, setIsReady] = useState(false);

  const { loading, error, data } = useQuery(GET_ROUTINES_BY_ID_TRAINING, {
    variables: {
      idTraining,
      orderRutine: parseInt(routineData.RoutinesOrder),
    },
    onCompleted: (resData) => {
      setRoutineComponentData(
        JSON.parse(resData.getRoutinesByIdTraining)[routineData.IDCatRoutine],
      );
      setIsReady(true);
    },
  });

  if (loading) return 'Loading...';
  if (error) return `Error ${error}`;

  console.log(
    'dataRoutine >>',
    JSON.parse(data.getRoutinesByIdTraining)[routineData.IDCatRoutine],
  );

  const getRoutine = (routineName, customColor) => {
    console.log(' 1 >>', routineComponentData);

    console.log(' 1.5 >>', routineComponentData.RoutineProps);

    let routineProps = JSON.parse(routineComponentData.RoutineProps);
    routineProps = {
      ...routineProps,
      idRoutine: parseInt(routineData.IDCatRoutine),
    };

    console.log(' 2 >>', routineProps);

    switch (routineName) {
      case 'PersonalData':
        return (
          <PersonalData
            customColor={customColor}
            {...routineProps}
            backHandler={backHandler}
            nextHandler={nextHandler}
            isActive={isActive}
          />
        );

      case 'AddressData':
        return (
          <AddressData
            customColor={customColor}
            {...routineProps}
            backHandler={backHandler}
            nextHandler={nextHandler}
            isActive={isActive}
          />
        );

      case 'BirthData':
        return (
          <BirthData
            customColor={customColor}
            {...routineProps}
            backHandler={backHandler}
            nextHandler={nextHandler}
            isActive={isActive}
          />
        );

      case 'CustomQuestion':
        return (
          <CustomQuestion
            customColor={customColor}
            {...routineProps}
            backHandler={backHandler}
            nextHandler={nextHandler}
            isActive={isActive}
            questions={routineComponentData.questions}
          />
        );

      case 'CarInsuranceInfo':
        return (
          <CarInsurance
            customColor={customColor}
            {...routineProps}
            backHandler={backHandler}
            nextHandler={nextHandler}
            isActive={isActive}
          />
        );

      default:
        return <></>;
    }
  };

  if (!isReady) return 'Loading...';

  return <>{getRoutine(routineComponentData.ComponentName, customColor)}</>;
};

export default RoutineSelector;
