import React, { useState, Fragment, useEffect } from 'react';
import {
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import face from '../../assets/img/anacoach/facebook1.png';
import twit from '../../assets/img/embajadores/twitter1.png';
import whats from '../../assets/img/anacoach/whats.png';
import {
  TrainingQuestion,
  QuestionsDivs,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import {
  GET_VALUE_ANSWER_BY_ID,
  GET_SCORE_CLASSIFICATION,
} from '../../queries';
import {
  DYNAMIC_INSERT,
  CREATE_QUIZ_EXERCISE,
  CREATE_LOG_SHARE_MVP,
} from '../../mutations';
const DatosMvp = ({
  Date,
  hora,
  orderRutine,
  mostrarTabla,
  setMostrarTabla,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  idUsuarioQuiz,
  idExercise,
  setIdExercise,
  mostrarQuiz,
  setmostrarQuiz,
  mostrarShareButtons,
  setmostrarShareButtons,
  isMasterView,
  setisMasterView,

  setshareEffect,
  shareEffect,
  shareButton,
  setShareButton,
}) => {
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  // Preguntas const
  const [tempCaseOption, setTempCaseOption] = useState();
  const [FlagEnd, setFlagEnd] = useState('');
  const [nombreInput, setNombreInput] = useState('');
  const [mensajeError, setmensajeError] = useState('');
  const [clasificacion, setClasificacion] = useState('');
  const [mostrarCompartir, setmostrarCompartir] = useState(false);

  const [p1, setp1] = useState({
    cadena: '',
    valor: '',
  });
  const [p2, setp2] = useState({
    cadena: '',
    valor: '',
  });
  const [p3, setp3] = useState({
    cadena: '',
    valor: '',
  });
  const [p4, setp4] = useState({
    cadena: '',
    valor: '',
  });
  const [p5, setp5] = useState({
    cadena: '',
    valor: '',
  });
  const [p6, setp6] = useState({
    cadena: '',
    valor: '',
  });
  const [p7, setp7] = useState({
    cadena: '',
    valor: '',
  });
  const [p8, setp8] = useState({
    cadena: '',
    valor: '',
  });

  // final preguntas const
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      const idTypeTemp = Rutine.questions[numQuestion - 1].DatUserDetailStorage;
      dynamicInsert({
        variables: {
          input: {
            table: 'CatExerciseDetails',
            columns: ['IDType', 'Value', 'CatExercise_idCatExercise'],
            detailValues: {
              values: [idTypeTemp.toString(), name, String(idExercise)],
            },
          },
        },
      });
      setQuestions([]);
    }
  };

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('insertado');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });
  const [createLogShareMvp] = useMutation(CREATE_LOG_SHARE_MVP, {
    onCompleted({ createLogShareMvp }) {
      if (createLogShareMvp === 'ok') {
        console.log('insertado');
      } else {
        console.log(createLogShareMvp);
      }
    },
  });
  const [createQuizExercise] = useMutation(CREATE_QUIZ_EXERCISE, {
    onCompleted({ createQuizExercise }) {
      if (createQuizExercise.statusCode === 200) {
        sessionStorage.setItem('idExercise', createQuizExercise.response);
        setIdExercise(createQuizExercise.response);
      } else {
        console.log(createQuizExercise.message);
      }
    },
  });
  const [getValueAnswerById] = useLazyQuery(GET_VALUE_ANSWER_BY_ID, {
    onCompleted({ getValueAnswerById }) {
      const tempVal1 = getValueAnswerById;
      switch (tempCaseOption) {
        case 0:
          setp1({ ...p1, valor: tempVal1 });
          break;
        case 1:
          setp2({ ...p2, valor: tempVal1 });
          break;
        case 2:
          setp3({ ...p3, valor: tempVal1 });
          break;
        case 3:
          setp4({ ...p4, valor: tempVal1 });
          break;
        case 4:
          setp5({ ...p5, valor: tempVal1 });
          break;
        case 5:
          setp6({ ...p6, valor: tempVal1 });
          break;
        case 6:
          setp7({ ...p7, valor: tempVal1 });
          break;
        case 7:
          setp8({ ...p8, valor: tempVal1 });
          setFlagEnd('1');
          break;
        default:
          break;
      }
    },
  });
  const handleChangeCatOption = (val) => {
    const { IDCatQuestionsDetail, value, step } = val;
    getValueAnswerById({
      variables: {
        idAnswer: String(IDCatQuestionsDetail),
      },
    });
    switch (step) {
      case 0:
        setp1({
          cadena: value,
        });
        setTempCaseOption(0);
        break;
      case 1:
        setp2({
          cadena: value,
        });
        setTempCaseOption(1);
        break;
      case 2:
        setp3({
          cadena: value,
        });
        setTempCaseOption(2);
        break;
      case 3:
        setp4({
          cadena: value,
        });
        setTempCaseOption(3);
        break;
      case 4:
        setp5({
          cadena: value,
        });
        setTempCaseOption(4);
        break;
      case 5:
        setp6({
          cadena: value,
        });
        setTempCaseOption(5);
        break;
      case 6:
        setp7({
          cadena: value,
        });
        setTempCaseOption(6);
        break;
      case 7:
        setp8({
          cadena: value,
        });
        setTempCaseOption(7);
        break;
      default:
        break;
    }
  };

  const [error] = useState({
    Email1: true,
    FirstName: true,
    MiddleName: false,
    FathersLastName: true,
    MothersLastName: false,
  });
  const [texterror] = useState({
    Email1: '',
    FirstName: '',
    MiddleName: '',
    FathersLastName: '',
    MothersLastName: '',
  });
  const [getScoreClassification] = useLazyQuery(GET_SCORE_CLASSIFICATION, {
    onCompleted({ getScoreClassification }) {
      try {
        setClasificacion(
          getScoreClassification.response[0].idCatUserClassification.toString(),
        );
      } catch (error) {
        setClasificacion('1');
      }
    },
  });
  useEffect(() => {
    if (FlagEnd !== '') {
      const total = parseInt(p1.valor)
        + parseInt(p2.valor)
        + parseInt(p3.valor)
        + parseInt(p4.valor)
        + parseInt(p5.valor)
        + parseInt(p6.valor)
        + parseInt(p7.valor)
        + parseInt(p8.valor);

      getScoreClassification({
        variables: {
          score: parseInt(total),
        },
      });
    }
  }, [FlagEnd]);
  useEffect(() => {
    if (clasificacion !== '') {
      const total = parseInt(p1.valor)
        + parseInt(p2.valor)
        + parseInt(p3.valor)
        + parseInt(p4.valor)
        + parseInt(p5.valor)
        + parseInt(p6.valor)
        + parseInt(p7.valor)
        + parseInt(p8.valor);
      const idtraining = sessionStorage.getItem('idTraining');
      try {
        dynamicInsert({
          variables: {
            input: {
              table: 'CatScoreTraining',
              columns: [
                'CatUser_IDUser',
                'CatTraining_IDTraining',
                'Score',
                'CatUserClassification_idCatUserClassification',
              ],
              detailValues: {
                values: [
                  String(idExercise),
                  String(idtraining),
                  String(total),
                  String(clasificacion),
                ],
              },
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
      setShareButton(true);
      let idMasterSession = '';
      if (sessionStorage.getItem('idMaster') == null) {
        idMasterSession = parseInt(idExercise);
      } else {
        setisMasterView('invitado');
        idMasterSession = sessionStorage.getItem('idMaster');
      }
      try {
        dynamicInsert({
          variables: {
            input: {
              table: 'CatUserFriends',
              columns: [
                'CatTraining_IDTraining',
                'IDUserMaster',
                'IDUserFriend',
              ],
              detailValues: {
                values: [
                  idtraining.toString(),
                  idMasterSession.toString(),
                  idExercise.toString(),
                ],
              },
            },
          },
        });
        setMostrarTabla('verdadero');
      } catch (e) {
        console.log(e);
      }
      if (sessionStorage.getItem('idMaster') !== null) {
        try {
          dynamicInsert({
            variables: {
              input: {
                table: 'CatUserFriends',
                columns: [
                  'CatTraining_IDTraining',
                  'IDUserMaster',
                  'IDUserFriend',
                ],
                detailValues: {
                  values: [
                    idtraining.toString(),
                    idExercise.toString(),
                    idExercise.toString(),
                  ],
                },
              },
            },
          });
          setMostrarTabla('verdadero');
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [clasificacion]);

  const [form, setForm] = useState({
    firstname: sessionStorage.getItem('FirstName') || '',
    lastname: sessionStorage.getItem('FathersLastName') || '',
    secondname: sessionStorage.getItem('MiddleName') || '',
    email: sessionStorage.getItem('Email1') || '',
    secondlastname: sessionStorage.getItem('MothersLastName') || '',
  });

  const ValidEmail = (id, valueEmail) => {
    let domain = valueEmail.split('@');
    const anexo = domain[0];
    domain = domain[1];
    const idmail = document.getElementsByName(id)[0];
    if (idmail === null) {
      // console.log('null');
    } else if (
      valueEmail
        && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.Email1 = 'Correo electrónico incorrecto';
      error.Email1 = true;
    } else if (
      domain !== ''
        && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.Email1 = 'Correo electrónico incorrecto';
      error.Email1 = true;
    } else {
      idmail.className = 'textInput inputs-bottom validInput';
      texterror.Email1 = '';
      error.Email1 = false;
      error.emailvalid = 0;
      setmensajeError('');
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementById(id);
    if (element === null) {
      // console.log('null');
    } else if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
      setmensajeError('');
    } else if (nombre === 'secondname') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
      setmensajeError('');
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
  };

  useEffect(() => {
    if (idUsuarioQuiz !== null) {
      if (idExercise !== '') {
        createQuizExercise({
          variables: {
            input: {
              nombre: nombreInput,
              idUser: idUsuarioQuiz.toString(),
              idExercise: idExercise.toString(),
            },
          },
        });
      }
    }
  }, [idUsuarioQuiz]);

  const handleChangeDatosMvp = () => {
    // validar datos
    if (
      !error.Email1
      && !error.FirstName
      && !error.MiddleName
      && !error.FathersLastName
      && !error.MothersLastName
    ) {
      createJsonInput(Rutine, questions);
      setMostrarTabla('verdadero');
      setmostrarCompartir(false);
      setmostrarShareButtons(true);
      setmensajeError('');
    } else {
      const cadenaErrores = 'Revisa los campos con Error o Faltantes';
      setmensajeError(cadenaErrores);
    }
  };
  const crearExercise = () => {
    if (!error.FirstName) {
      createQuizExercise({
        variables: {
          input: {
            nombre: nombreInput,
            idUser: '',
          },
        },
      });
      setOrderRutine(2);
      setmensajeError('');
      setQuestions([]);
    }
  };

  useEffect(() => {
    if (shareEffect !== false) {
      setmostrarCompartir(true);
      setMostrarTabla('falso');
      setShareButton(false);
      setmostrarQuiz(false);
      setOrderRutine(3);
    }
  }, [shareEffect]);
  useEffect(() => {
    console.log('buscame aqui', idExercise);
  }, [idExercise]);
  const handleSocial = (socialNetwork) => {
    const idtraining = sessionStorage.getItem('idTraining');
    createLogShareMvp({
      variables: {
        input: {
          idSession: sessionStorage.getItem('idDatSession'),
          idUser: idUsuarioQuiz.toString(),
          idExercise: idExercise.toString(),
          socialNetwork,
          email: '',
          training: idtraining.toString(),
        },
      },
    });
    const coruRedirectURL = `https://coru.com/mvp-money?idMaster=${idExercise}`;
    switch (socialNetwork) {
      case 'Facebook':
        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruRedirectURL,
          )}&redirect_uri=${encodeURIComponent(
            'https://www.facebook.com/',
          )}&display=popup`,
        );
        break;
      case 'Whatsapp':
        const whatsappMessage = `¿Sabes más que yo?, responde este quiz y averigüémoslo. ${coruRedirectURL}`;
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`,
          'popup',
          'width=600',
          'height=600',
        );
        break;
      case 'Twitter':
        const twitterMessage = `¿Sabes más que yo?, responde este quiz y averigüémoslo. ${coruRedirectURL}`;
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURI(twitterMessage)}`,
        );
        break;
      default:
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'FirstName':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        setNombreInput(e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''));
        break;
      case 'MiddleName':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
      case 'FathersLastName':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
      case 'MothersLastName':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
      case 'Email1':
        ValidEmail(e.target.name, e.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {/* rutina 1 */}
      {mostrarQuiz === true ? (
        <div>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="¿Cómo prefieres que te llamemos? Además de experto."
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={idExercise === '' ? '' : 'hidden'}
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-5"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              inputError={error}
              texterror={texterror}
            />
            <h4>{mensajeError}</h4>
            <CardsBtn
              type="button"
              min_w="100%"
              className=""
              min_web="100%"
              onClick={() => crearExercise()}
            >
              Continuar
            </CardsBtn>
          </TrainingQuestion>
          <CardTwo
            classCard={idExercise !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={nombreInput}
          />
          {/* rutina 2 */}
          {/* pregunta 1 */}
          <CardOne
            classCard={
              (idExercise !== '' && mostrarTabla === 'falso')
              || (p1.cadena !== '' && idExercise !== '')
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="¿Cuál de las siguientes categorías NO entra como activo/pasivo?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p1.cadena === ''
              && idExercise !== ''
              && idExercise !== undefined
              && mostrarTabla === 'falso'
                ? ''
                : 'hidden'
            }
          >
            <CardTwo
              classCard={p1.valor !== '' ? 'cardTwoResp' : 'hidden'}
              classTop="topTwo1"
              imgMan="hidden"
              text={p1.cadena}
            />
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p1.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p1.cadena}
          />
          {/* Pregunta 2 */}
          <CardOne
            classCard={p1.valor !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿En qué orden deberías seguir estas metas?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p2.valor === '' && p1.valor !== '' && p1.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p2.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p2.cadena}
          />
          {/* Pregunta 3 */}
          <CardOne
            classCard={p2.valor !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿Cada cuándo debes hacer un presupuesto?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p3.valor === '' && p2.valor !== '' && p2.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p3.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p3.cadena}
          />
          {/* Pregunta 4 */}
          <CardOne
            classCard={p3.valor !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿Dónde es preferible hacer un presupuesto?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p4.valor === '' && p3.valor !== '' && p3.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p4.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p4.cadena}
          />
          {/* Pregunta 5 */}
          <CardOne
            classCard={p4.valor !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿Cómo guardas tu ahorro de emergencia?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p5.valor === '' && p4.valor !== '' && p4.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p5.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p5.cadena}
          />
          {/* Pregunta 6 */}
          <CardOne
            classCard={p5.valor !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿Cuándo debes de pensar en invertir tus ahorros?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p6.valor === '' && p5.valor !== '' && p5.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="5-5"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p6.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p6.cadena}
          />
          {/* Pregunta 7 */}
          <CardOne
            classCard={p6.valor !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿Cuál de las siguientes categorías NO entra como un flujo?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p7.valor === '' && p6.valor !== '' && p6.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="6-6"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p7.valor !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p7.cadena}
          />
          {/* Pregunta 8 */}
          <CardOne
            classCard={p7.cadena !== '' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="De los siguientes gastos ¿Cuál es gasto indispensable?"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={
              p8.cadena === '' && p7.cadena !== '' && p7.valor !== undefined
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="7-7"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <CardTwo
            classCard={p8.cadena !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={p8.cadena}
          />
        </div>
      ) : (
        ''
      )}
      {/* rutina 3 */}
      {mostrarCompartir === true ? (
        <div>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="Identifícate para compartir"
            hora={hora}
          />
          <TrainingQuestion
            wiw="100%"
            className={mostrarCompartir === true ? '' : 'hidden'}
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-5"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
              inputError={error}
              texterror={texterror}
            />
            <h4>{mensajeError}</h4>
            <CardsBtn
              type="button"
              min_w="100%"
              className=""
              min_web="100%"
              onClick={() => handleChangeDatosMvp()}
            >
              Continuar
            </CardsBtn>
          </TrainingQuestion>
          <CardTwo
            classCard={idUsuarioQuiz !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={nombreInput}
          />
        </div>
      ) : (
        ''
      )}
      {/* fin rutina 3 */}
      <QuestionsDivs
        padd="0"
        txta="left"
        width="100%"
        widthweb="100%"
        hei="auto"
        margin="0 auto auto"
        bc="#fafafa"
      >
        <form>
          <br />
          <br />
        </form>
      </QuestionsDivs>
      {mostrarShareButtons === true ? (
        <div>
          <CardOne
            classCard="cardOne"
            classTop="topOne1"
            text="Comparte usando"
            hora={hora}
          />
          <br />
          <table align="center" alignweb="center">
            <tr>
              <td>
                <img
                  alt=""
                  position="initial"
                  left="0"
                  width="50px"
                  height="50px"
                  style={{
                    maxWidth: '100%',
                    width: '50px',
                    height: '50px',
                    position: 'initial',
                    marginLeft: '0',
                    cursor: 'pointer',
                  }}
                  src={face}
                  onClick={() => handleSocial('Facebook')}
                />
              </td>
              <td>
                <img
                  alt=""
                  position="initial"
                  left="0"
                  width="50px"
                  height="50px"
                  style={{
                    maxWidth: '100%',
                    width: '50px',
                    height: '50px',
                    position: 'initial',
                    marginLeft: '20px',
                    cursor: 'pointer',
                  }}
                  src={twit}
                  onClick={() => handleSocial('Twitter')}
                />
              </td>
              <td>
                <img
                  alt=""
                  position="initial"
                  left="0"
                  width="50px"
                  height="50px"
                  style={{
                    maxWidth: '100%',
                    width: '50px',
                    height: '50px',
                    position: 'initial',
                    marginLeft: '20px',
                    cursor: 'pointer',
                  }}
                  src={whats}
                  onClick={() => handleSocial('Whatsapp')}
                />
              </td>
            </tr>
          </table>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default DatosMvp;
