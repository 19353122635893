import React, { Fragment } from 'react';
import {
  TrainingText, DivAdvanceGoals, QuestionsFlex, LineGreen,
} from '../../assets/styles/Training.styled';

function SectionDetailGoal({ goal, tiempoAhorro, montoMeta }) {
  return (
      <Fragment>
         <DivAdvanceGoals ww='60%' marginL='18%' margin='auto' w='90%' mt="26px" h="132px">
            <QuestionsFlex>
               <div style={{ width: '50%', margin: 'auto' }}>
                  <QuestionsFlex>
                     <LineGreen />
                     <div style={{ padding: '18px' }}>
                        <TrainingText
                           className="roboto"
                           color="#07210A"
                           align="left"
                           size="16px"
                           sizew="16px"
                           lh="120%"
                           weight="bold"
                           bot="5px"
                        >
                           {goal}
                        </TrainingText>
                        <TrainingText className="roboto" color="#615959" align="left" size="14px" sizew="14px" lh="120%" weight="500">
                           Tu meta
                        </TrainingText>
                        <TrainingText
                           className="roboto"
                           color="#07210A"
                           align="left"
                           size="16px"
                           sizew="16px"
                           lh="120%"
                           weight="bold"
                           bot="5px"
                        >
                           {tiempoAhorro > 1 ? `${tiempoAhorro} meses` : `${tiempoAhorro} mes`}
                        </TrainingText>
                        <TrainingText
                           className="roboto"
                           color="#615959"
                           align="left"
                           size="14px"
                           sizew="14px"
                           lh="120%"
                           weight="500"
                           bot="0"
                        >
                           Tiempo para meta
                        </TrainingText>
                     </div>
                  </QuestionsFlex>
               </div>
               <div style={{ width: '50%', margin: 'auto' }}>
                  <TrainingText className="roboto" color="#07210A" align="left" size="16px" sizew="16px" lh="120%" weight="bold" bot="5px">
                     {montoMeta}
                  </TrainingText>
                  <TrainingText className="roboto" color="#615959" align="left" size="14px" sizew="14px" lh="120%" weight="500">
                     Monto de meta
                  </TrainingText>
               </div>
            </QuestionsFlex>
         </DivAdvanceGoals>
      </Fragment>
  );
}

export default SectionDetailGoal;
