import React, { useEffect } from 'react';
import { SEND_NOTIFICATION } from '../mutations';
import { useMutation } from '@apollo/client';

const NotificationCoruRewards = () => {

    const [sendNotificationWa] = useMutation(SEND_NOTIFICATION, {
        fetchPolicy: 'no-cache',
        onCompleted({ sendNotification }) {
            if (sendNotification.statusCode === 200) {
                const send = sendNotification.response;
                console.log('Se envio correctamente !!', send);
            }
        },
        onError(err) {
            console.log(err);
        },
    });
    const sendNotification = () => {
        const params = new URLSearchParams(window.location.search);
        let coruRewardsCelName = params.get('dataP') != null ? params.get('dataP') : '';
        const FirstName = sessionStorage.getItem('FirstName');

        if (coruRewardsCelName !== '') {
            coruRewardsCelName = coruRewardsCelName.split('_numb');
            const name = coruRewardsCelName[0];
            const cellphone = coruRewardsCelName[1];

            const inputSend = {
                input: {
                    type: 'wamult',
                    properties: {
                        trigger: 'coru_rewards_v2',
                        telephone: cellphone,
                        subject: '',
                        template: '',
                        variables: [
                            {
                                "setkey": "nombre",
                                "setval": name
                            },
                            {
                                "setkey": "nombre_referido",
                                "setval": FirstName
                            }
                        ],
                        source: {},
                        destination: {},
                    },
                },
            };
            console.log(
                'response == inputSendVideo == ',
                JSON.stringify(inputSend),
            );
            sendNotificationWa({ variables: inputSend });

        }
    }
    return { sendNotification };
}

export default NotificationCoruRewards;