/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  QuestionsFlex,
  TrainingSectionGray,
  CardsBtn,
  CardsBtn1,
  TrainingQuestion,
  DivRespDP,
  TrainingDivSection,
  SliderOrder,
} from '../../assets/styles/Training.styled';
import { Container } from '../../components/common/common.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

const Perfilamiento2_0 = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  handledPuntosDinero,
  handledPuntosRiesgo,
  handledPuntosFuturo,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // const anchop = window.screen.width;
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [relPuntosDineroId] = useState([
    { IDCatQuestionsDetail: 764, puntos: 1 },
    { IDCatQuestionsDetail: 765, puntos: 2 },
    { IDCatQuestionsDetail: 766, puntos: 3 },
    { IDCatQuestionsDetail: 767, puntos: 3 },
    { IDCatQuestionsDetail: 768, puntos: 2 },
    { IDCatQuestionsDetail: 769, puntos: 1 },
    { IDCatQuestionsDetail: 773, puntos: 3 },
    { IDCatQuestionsDetail: 774, puntos: 2 },
    { IDCatQuestionsDetail: 775, puntos: 1 },
    { IDCatQuestionsDetail: 776, puntos: 1 },
    { IDCatQuestionsDetail: 777, puntos: 2 },
    { IDCatQuestionsDetail: 778, puntos: 3 },
  ]);
  const [relPuntosRiesgoId] = useState([
    { IDCatQuestionsDetail: 752, puntos: 3 },
    { IDCatQuestionsDetail: 753, puntos: 2 },
    { IDCatQuestionsDetail: 754, puntos: 1 },
    { IDCatQuestionsDetail: 764, puntos: 1 },
    { IDCatQuestionsDetail: 765, puntos: 2 },
    { IDCatQuestionsDetail: 766, puntos: 3 },
    { IDCatQuestionsDetail: 773, puntos: 3 },
    { IDCatQuestionsDetail: 774, puntos: 2 },
    { IDCatQuestionsDetail: 775, puntos: 1 },
    { IDCatQuestionsDetail: 785, puntos: 3 },
    { IDCatQuestionsDetail: 786, puntos: 2 },
    { IDCatQuestionsDetail: 787, puntos: 1 },
  ]);
  const [relPuntosFuturoId] = useState([
    { IDCatQuestionsDetail: 764, puntos: 1 },
    { IDCatQuestionsDetail: 765, puntos: 2 },
    { IDCatQuestionsDetail: 766, puntos: 3 },
    { IDCatQuestionsDetail: 776, puntos: 1 },
    { IDCatQuestionsDetail: 777, puntos: 3 },
    { IDCatQuestionsDetail: 778, puntos: 2 },
    { IDCatQuestionsDetail: 785, puntos: 1 },
    { IDCatQuestionsDetail: 786, puntos: 3 },
    { IDCatQuestionsDetail: 787, puntos: 2 },
  ]);

  const [plataformaDig, setPlataformaDig] = useState(
    sessionStorage.getItem('PlataformaDigital')
      ? sessionStorage.getItem('PlataformaDigital').split(',')
      : [],
  );
  const [showPlataformaDigSelected, setShowPlataformaDigSelected] = useState(
    sessionStorage.getItem('PlataformaDigital') || false,
  );

  const [gustoDinero, setGustoDinero] = useState(
    sessionStorage.getItem('GustoPorDinero')
      ? sessionStorage.getItem('GustoPorDinero').split(',')
      : [],
  );

  const [dedicarFines, setDedicarFines] = useState(
    sessionStorage.getItem('FindeSemana')
      ? sessionStorage.getItem('FindeSemana').split(',')
      : [],
  );
  const [showDedicarFinesSelected, setShowDedicarFinesSelected] = useState(
    sessionStorage.getItem('FindeSemana') || false,
  );

  const [pasarDinero, setPasarDinero] = useState(
    sessionStorage.getItem('QuePasaConMiDinero')
      ? sessionStorage.getItem('QuePasaConMiDinero').split(',')
      : [],
  );
  const [showPasarDineroSelected, setShowPasarDineroSelected] = useState(
    sessionStorage.getItem('QuePasaConMiDinero') || false,
  );

  const [estadoFinanciero, setEstadoFinanciero] = useState(
    sessionStorage.getItem('EstadoFinanciero') || '',
  );
  const [relacionFinanzas, setRelacionFinanzas] = useState(
    sessionStorage.getItem('RelacionFinanzas') || '',
  );
  const [practicaDeporte, setPracticaDeporte] = useState(
    sessionStorage.getItem('PracticaDeporte') || '',
  );
  const [plataformaDigital, setPlataformaDigital] = useState(
    sessionStorage.getItem('PlataformaDigital') || '',
  );
  const [otraOpcion, setOtraOpcion] = useState(
    sessionStorage.getItem('OtraOpcion') || '',
  );
  const [otraOpcionObj, setOtraOpcionObj] = useState([]);
  const [conQuienVives, setConQuienVives] = useState(
    sessionStorage.getItem('ConQuienVives') || '',
  );
  const [vacaciones, setVacaciones] = useState(
    sessionStorage.getItem('Vacaciones') || '',
  );
  const [comoTeVesDe40, setComoTeVesDe40] = useState(
    sessionStorage.getItem('ComoTeVesDe40') || '',
  );
  const [durabilidadDeAhorros, setDurabilidadDeAhorros] = useState(
    sessionStorage.getItem('DurabilidadDeAhorros') || '',
  );
  const [desayunoIdeal, setDesayunoIdeal] = useState(
    sessionStorage.getItem('DesayunoIdeal') || '',
  );
  const [cuantoDineroTeQueda, setCuantoDineroTeQueda] = useState(
    sessionStorage.getItem('CuantoDineroTeQueda') || '',
  );
  const [edadParaTestamentar, setEdadParaTestamentar] = useState(
    sessionStorage.getItem('EdadParaTestamentar') || '',
  );
  const [gustoPorDinero, setGustoPorDinero] = useState(
    sessionStorage.getItem('GustoPorDinero') || '',
  );
  const [otraOpcion2, setOtraOpcion2] = useState(
    sessionStorage.getItem('OtraOpcion2') || '',
  );
  const [otraOpcion2Obj, setOtraOpcion2Obj] = useState([]);
  const [findeSemana, setFindeSemana] = useState(
    sessionStorage.getItem('FindeSemana') || '',
  );
  const [otraOpcion3, setOtraOpcion3] = useState(
    sessionStorage.getItem('OtraOpcion3') || '',
  );
  const [otraOpcion3Obj, setOtraOpcion3Obj] = useState([]);
  const [quePasaConMiDinero, setQuePasaConMiDinero] = useState(
    sessionStorage.getItem('QuePasaConMiDinero') || '',
  );
  const [otraOpcion4, setOtraOpcion4] = useState(
    sessionStorage.getItem('OtraOpcion4') || '',
  );
  const [otraOpcion4Obj, setOtraOpcion4Obj] = useState([]);
  const [findelMundo, setFindelMundo] = useState(
    sessionStorage.getItem('FindelMundo') || '',
  );
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editQuestOne, setEditQuestOne] = useState('');
  const [editQuestTwo, setEditQuestTwo] = useState('');
  const [editQuestThree, setEditQuestThree] = useState('');
  const [editQuestFour, setEditQuestFour] = useState('');
  const [editQuestSix, setEditQuestSix] = useState('');
  const [editQuestSeven, setEditQuestSeven] = useState('');
  const [editQuestEight, setEditQuestEight] = useState('');
  const [editQuestNine, setEditQuestNine] = useState('');
  const [editQuestTen, setEditQuestTen] = useState('');
  const [editQuestEleven, setEditQuestEleven] = useState('');
  const [editQuestTwelve, setEditQuestTwelve] = useState('');
  const [editQuestThirteen, setEditQuestThirteen] = useState('');
  const [editQuestFifteen, setEditQuestFifteen] = useState('');
  const [editQuestSeventeen, setEditQuestSeventeen] = useState('');
  const [editQuestNineteen, setEditQuestNineteen] = useState('');
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const setAnswerPasarDinero = (e) => {
    e.persist();
    if (!isEmptyObject(Rutine) && pasarDinero.toString() !== '') {
      setShowPasarDineroSelected(true);
      const pasarDineroSelection = pasarDinero.toString();
      setEditQuestSeventeen(true);
      setQuePasaConMiDinero(pasarDineroSelection);
      questions.push({
        id: 'pasarDineroSelection',
        question: 17,
        value: pasarDineroSelection,
      });
      if (otraOpcion4Obj.length === 0) {
        const opcionObj = {
          id: 'OtraOpcion4',
          question: 18,
          value: '',
        };
        setOtraOpcion4Obj(opcionObj);
        questions.push(opcionObj);
      } else {
        questions = questions.reverse();
      }
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest15' });
  };

  const setAnswerDedicarFines = (e) => {
    e.persist();
    if (!isEmptyObject(Rutine) && dedicarFines.toString() !== '') {
      setShowDedicarFinesSelected(true);
      const dedicarFinesSelection = dedicarFines.toString();
      setEditQuestFifteen(true);
      setFindeSemana(dedicarFinesSelection);
      questions.push({
        id: 'dedicarFinesSelection',
        question: 15,
        value: dedicarFinesSelection,
      });
      if (otraOpcion3Obj.length === 0) {
        const opcionObj = {
          id: 'OtraOpcion3',
          question: 16,
          value: '',
        };
        setOtraOpcion3Obj(opcionObj);
        questions.push(opcionObj);
      } else {
        questions = questions.reverse();
      }
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest14' });
  };

  const setAnswerGustoDinero = (e) => {
    e.persist();
    if (!isEmptyObject(Rutine) && gustoDinero.toString() !== '') {
      const gustoDineroSelection = gustoDinero.toString();
      setEditQuestThirteen(true);
      setGustoPorDinero(gustoDineroSelection);
      questions.push({
        id: 'gustoDineroSelection',
        question: 13,
        value: gustoDineroSelection,
      });
      if (otraOpcion2Obj.length === 0) {
        const opcionObj = {
          id: 'OtraOpcion2',
          question: 14,
          value: '',
        };
        setOtraOpcion2Obj(opcionObj);
        questions.push(opcionObj);
      } else {
        questions = questions.reverse();
      }
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest13' });
  };

  const setAnswerPlataformaDigital = (e) => {
    e.persist();
    if (!isEmptyObject(Rutine) && plataformaDig.toString() !== '') {
      setShowPlataformaDigSelected(true);
      const plataformaDigSelection = plataformaDig.toString();
      setEditQuestFour(true);
      setPlataformaDigital(plataformaDigSelection);
      questions.push({
        id: 'plataformaDigSelection',
        question: 4,
        value: plataformaDigSelection,
      });
      if (otraOpcionObj.length === 0) {
        const opcionObj = {
          id: 'OtraOpcion',
          question: 5,
          value: '',
        };
        setOtraOpcionObj(opcionObj);
        questions.push(opcionObj);
      } else {
        questions = questions.reverse();
      }
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest5' });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    } else if (type === 'OpenField') {
      switch (name) {
        case 'OtraOpcion':
          setOtraOpcion(value);
          setOtraOpcionObj(questions);
          break;
        case 'OtraOpcion2':
          setOtraOpcion2(value);
          setOtraOpcion2Obj(questions);
          break;
        case 'OtraOpcion3':
          setOtraOpcion3(value);
          setOtraOpcion3Obj(questions);
          break;
        case 'OtraOpcion4':
          setOtraOpcion4(value);
          setOtraOpcion4Obj(questions);
          break;
        default:
          break;
      }
    }
  };

  const handleSetPoints = (val) => {
    const dinero = relPuntosDineroId.find((e) => e.IDCatQuestionsDetail === val);

    const riesgo = relPuntosRiesgoId.find((e) => e.IDCatQuestionsDetail === val);

    const futuro = relPuntosFuturoId.find((e) => e.IDCatQuestionsDetail === val);

    if (dinero) {
      handledPuntosDinero(dinero.puntos);
    }

    if (riesgo) {
      handledPuntosRiesgo(riesgo.puntos);
    }

    if (futuro) {
      handledPuntosFuturo(futuro.puntos);
    }
  };

  const handleChangeCatOption = (val) => {
    const { IDCatQuestionsDetail } = val;
    handleSetPoints(IDCatQuestionsDetail);
    switch (val.step) {
      case 0:
        let idValue = 799;
        const descValue = val.value !== '' ? val.value : 'Malo';
        setEditQuestOne(true);
        setEstadoFinanciero(descValue);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest2' });
        switch (descValue) {
          case 'Malo':
            idValue = 799;
            break;
          case 'Regular':
            idValue = 800;
            break;
          case 'Excelente':
            idValue = 801;
            break;
          default:
            break;
        }
        handleChangeQuestionsSend(1, descValue, idValue, 'Cat');

        break;
      case 1:
        setEditQuestTwo(true);
        setRelacionFinanzas(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest3' });
        break;
      case 2:
        setEditQuestThree(true);
        setPracticaDeporte(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest4' });
        break;
      case 3:
        setEditQuestFour(true);
        setPlataformaDigital(val.value);
        break;
      case 4:
        setOtraOpcion(val.value);
        break;
      case 5:
        setEditQuestSix(true);
        setConQuienVives(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest6' });
        break;
      case 6:
        setEditQuestSeven(true);
        setVacaciones(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest7' });
        break;
      case 7:
        setEditQuestEight(true);
        setComoTeVesDe40(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest8' });
        break;
      case 8:
        setEditQuestNine(true);
        setDurabilidadDeAhorros(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest9' });
        break;
      case 9:
        setEditQuestTen(true);
        setDesayunoIdeal(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest10' });
        break;
      case 10:
        setEditQuestEleven(true);
        setCuantoDineroTeQueda(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest11' });
        break;
      case 11:
        setEditQuestTwelve(true);
        setEdadParaTestamentar(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest12' });
        break;
      case 12:
        setEditQuestThirteen(true);
        setGustoPorDinero(val.value);
        break;
      case 13:
        setOtraOpcion2(val.value);
        break;
      case 14:
        setEditQuestFifteen(true);
        setFindeSemana(val.value);
        break;
      case 15:
        setOtraOpcion3(val.value);
        break;
      case 16:
        setEditQuestSeventeen(true);
        setQuePasaConMiDinero(val.value);
        break;
      case 17:
        setOtraOpcion4(val.value);
        break;
      case 18:
        setEditQuestNineteen(true);
        setFindelMundo(val.value);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log('default');
        break;
    }
  };
  const handleChangeChekedPlataformaDigital = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setPlataformaDig(plataformaDig.concat(value));
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = plataformaDig.indexOf(value);
      plataformaDig.splice(deleted, 1);
    }
  };
  const handleChangeChekedGustoDinero = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setGustoDinero(gustoDinero.concat(value));
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = gustoDinero.indexOf(value);
      gustoDinero.splice(deleted, 1);
    }
  };
  const handleChangeChekedDedicarFines = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setDedicarFines(dedicarFines.concat(value));
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = dedicarFines.indexOf(value);
      dedicarFines.splice(deleted, 1);
    }
  };
  const handleChangeChekedPasarDinero = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setPasarDinero(pasarDinero.concat(value));
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = pasarDinero.indexOf(value);
      pasarDinero.splice(deleted, 1);
    }
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (questionType === 'Cat') {
                const { detailQuestion } = routines[idRoutine].questions[i];
                for (let x = 0; x < detailQuestion.length; x++) {
                  if (detailQuestion[x].FieldOption === responseUser) {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else {
                contResponse++;
                sessionStorage.setItem(
                  arraySessionsRutines[idTypeQuestion],
                  responseUser,
                );
              }
            }

            if (
              arrayResponseRutine.length <= routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setEstadoFinanciero(sessionStorage.getItem('EstadoFinanciero') || '');
      setRelacionFinanzas(sessionStorage.getItem('RelacionFinanzas') || '');
      setPracticaDeporte(sessionStorage.getItem('PracticaDeporte') || '');
      setPlataformaDigital(sessionStorage.getItem('PlataformaDigital') || '');
      setOtraOpcion(sessionStorage.getItem('OtraOpcion') || '');
      setConQuienVives(sessionStorage.getItem('ConQuienVives') || '');
      setVacaciones(sessionStorage.getItem('Vacaciones') || '');
      setComoTeVesDe40(sessionStorage.getItem('ComoTeVesDe40') || '');
      setDurabilidadDeAhorros(
        sessionStorage.getItem('DurabilidadDeAhorros') || '',
      );
      setDesayunoIdeal(sessionStorage.getItem('DesayunoIdeal') || '');
      setCuantoDineroTeQueda(
        sessionStorage.getItem('CuantoDineroTeQueda') || '',
      );
      setEdadParaTestamentar(
        sessionStorage.getItem('EdadParaTestamentar') || '',
      );
      setGustoPorDinero(sessionStorage.getItem('GustoPorDinero') || '');
      setOtraOpcion2(sessionStorage.getItem('OtraOpcion2') || '');
      setFindeSemana(sessionStorage.getItem('FindeSemana') || '');
      setOtraOpcion3(sessionStorage.getItem('OtraOpcion3') || '');
      setQuePasaConMiDinero(sessionStorage.getItem('QuePasaConMiDinero') || '');
      setOtraOpcion4(sessionStorage.getItem('OtraOpcion4') || '');
      setFindelMundo(sessionStorage.getItem('FindelMundo') || '');
      if (sessionStorage.getItem('FindelMundo')) { setOrderRutine(nextOrderRoutine); }
    }
  }, [recoveryRoutine]);

  const handleChangeIcon = (json) => {
    setEditQuestOne(false);
    setEditQuestTwo(false);
    setEditQuestThree(false);
    setEditQuestFour(false);
    setEditQuestSix(false);
    setEditQuestSeven(false);
    setEditQuestEight(false);
    setEditQuestNine(false);
    setEditQuestTen(false);
    setEditQuestEleven(false);
    setEditQuestTwelve(false);
    setEditQuestThirteen(false);
    setEditQuestFifteen(false);
    setEditQuestSeventeen(false);
    setEditQuestNineteen(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  const [ValueSlider, setValueSlider] = useState(0);
  const [textValorSlider, setTextValorSlider] = useState('');

  const handleSlider = (e, valor) => {
    setValueSlider(valor);
    let textvalor = '';
    switch (valor) {
      case 0:
        textvalor = 'Malo';
        break;
      case 50:
        textvalor = 'Regular';
        break;
      case 100:
        textvalor = 'Excelente';
        break;
      default:
        break;
    }
    setTextValorSlider(textvalor);
  };

  return (
    <Fragment>
      {/* pregunta número uno */}
      <QuestionsOrder
        classCard='cardOne'
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <SliderOrder
        leftg="11%"
        width="75%"
        className={
          (estadoFinanciero === ''
            || (lapiz[0].routine === 1 && lapiz[0].active === 1))
          && editQuestOne !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
          barra={true}
          ValueSlider={ValueSlider}
          handleSlider={handleSlider}
        />
      </SliderOrder>
      <TrainingSectionGray
        wiw="100%"
        mw="auto"
        back="#FAFAFA"
        mtop="0"
        className={
          (estadoFinanciero === ''
            || (lapiz[0].routine === 1 && lapiz[0].active === 1))
          && editQuestOne !== true
            ? ''
            : 'hidden'
        }
      >
        <Container
          width="60%"
          mleft="18%"
          widthMobile="90%"
          mlMobile="auto"
          mrMobile="auto"
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn
              type="button"
              min_w="100%"
              min_web="100%"
              mtopw="19px"
              mtop="19px"
              onClick={() => handleChangeCatOption({ step: 0, value: textValorSlider })
              }
            >
              Continuar
            </CardsBtn>
          </div>
        </Container>
      </TrainingSectionGray>
      <div id="activeQuest2"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            estadoFinanciero !== '' && relacionFinanzas === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 1 })}
        />
        <CardTwo
          classCard={
            estadoFinanciero !== ''
            && (editQuestOne === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={estadoFinanciero}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <QuestionsOrder
        classCard={
          estadoFinanciero !== ''
          && (editQuestOne === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((estadoFinanciero !== '' && relacionFinanzas === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 1)
            || editQuestOne === true)
          && editQuestTwo !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest3"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            relacionFinanzas !== '' && practicaDeporte === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 2 })}
        />
        <CardTwo
          classCard={
            relacionFinanzas !== ''
            && (editQuestTwo === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={relacionFinanzas}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número tres */}
      <QuestionsOrder
        classCard={
          relacionFinanzas !== ''
          && (editQuestTwo === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 2))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={2}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((relacionFinanzas !== '' && practicaDeporte === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 2)
            || editQuestTwo === true)
          && editQuestThree !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest4"></div>
      <CardTwo
        classCard={
          practicaDeporte !== ''
          && (editQuestThree === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 3))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={practicaDeporte}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            practicaDeporte !== '' && plataformaDigital === ''
              ? 'iconEditCommon'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 3 })}
        />
      </CardTwo>

      {/* pregunta número cuatro */}
      <QuestionsOrder
        classCard={
          practicaDeporte !== ''
          && (editQuestThree === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 3))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={3}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          ((practicaDeporte !== '' && plataformaDigital === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 3)
            || editQuestThree === true)
          && editQuestFour !== true
            ? ''
            : 'hidden'
        }
      >
        <TrainingDivSection
          hew="auto"
          paddw="26px"
          mlw="18%"
          mtw="8%"
          wiw="60%"
          back="#F3F3F3"
          backw="#F3F3F3"
          bshaw="none"
          bsha=""
          br="4px"
          padding="20px"
          wi="90%"
          he="auto"
          className="topOne1 topOne1Init"
        >
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="3-3"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChangeChekedPlataformaDigital}
            currentTraining={currentTraining}
            typeCheckBox={true}
            checkBoxName="check_1_"
          />
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="4-4"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </TrainingDivSection>
      </TrainingQuestion>
      <TrainingSectionGray
        wiw="670px"
        mw="auto"
        back="#FAFAFA"
        mtop="0"
        className={
          ((practicaDeporte !== '' && plataformaDigital === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 3)
            || editQuestThree === true)
          && editQuestFour !== true
            ? ''
            : 'hidden'
        }
      >
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn1
              windthmd="100%"
              onClick={(e) => setAnswerPlataformaDigital(e)}
            >
              Continuar
            </CardsBtn1>
          </div>
        </Container>
      </TrainingSectionGray>
      <div id="activeQuest5"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            showPlataformaDigSelected && conQuienVives === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 4 })}
        />
        <CardTwo
          classCard={
            showPlataformaDigSelected
            && (editQuestFour === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={plataformaDig[0]}
          text2={plataformaDig[1]}
          text3={plataformaDig[2]}
          text4={otraOpcion}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* pregunta número seis */}
      <QuestionsOrder
        classCard={
          plataformaDigital !== ''
          && (editQuestFour === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 4))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={5}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((plataformaDigital !== '' && conQuienVives === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 6))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 4)
            || editQuestFour === true)
          && editQuestSix !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="5-5"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest6"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            conQuienVives !== '' && vacaciones === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 6 })}
        />
        <CardTwo
          classCard={
            conQuienVives !== ''
            && (editQuestSix === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 6))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={conQuienVives}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* pregunta número siete */}
      <QuestionsOrder
        classCard={
          conQuienVives !== ''
          && (editQuestSix === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 6))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={6}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((conQuienVives !== '' && vacaciones === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 7))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 6)
            || editQuestSix === true)
          && editQuestSeven !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="6-6"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest7"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            vacaciones !== '' && comoTeVesDe40 === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 7 })}
        />
        <CardTwo
          classCard={
            vacaciones !== ''
            && (editQuestSeven === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 7))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={vacaciones}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número ocho */}
      <QuestionsOrder
        classCard={
          vacaciones !== ''
          && (editQuestSeven === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 7))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={7}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((vacaciones !== '' && comoTeVesDe40 === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 8))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 7)
            || editQuestSeven === true)
          && editQuestEight !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="7-7"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest8"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            comoTeVesDe40 !== '' && durabilidadDeAhorros === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 8 })}
        />
        <CardTwo
          classCard={
            comoTeVesDe40 !== ''
            && (editQuestEight === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 8))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={comoTeVesDe40}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número nueve */}
      <QuestionsOrder
        classCard={
          comoTeVesDe40 !== ''
          && (editQuestEight === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 8))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={8}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((comoTeVesDe40 !== '' && durabilidadDeAhorros === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 9))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 8)
            || editQuestEight === true)
          && editQuestNine !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="8-8"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest9"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            durabilidadDeAhorros !== '' && desayunoIdeal === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 9 })}
        />
        <CardTwo
          classCard={
            durabilidadDeAhorros !== ''
            && (editQuestNine === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 9))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={durabilidadDeAhorros}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número diez */}
      <QuestionsOrder
        classCard={
          durabilidadDeAhorros !== ''
          && (editQuestNine === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 9))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={9}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((durabilidadDeAhorros !== '' && desayunoIdeal === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 10))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 9)
            || editQuestNine === true)
          && editQuestTen !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="9-9"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest10"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            desayunoIdeal !== '' && cuantoDineroTeQueda === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 10 })}
        />
        <CardTwo
          classCard={
            desayunoIdeal !== ''
            && (editQuestTen === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 10))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={desayunoIdeal}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número once */}
      <QuestionsOrder
        classCard={
          desayunoIdeal !== ''
          && (editQuestTen === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 10))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={10}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((desayunoIdeal !== '' && cuantoDineroTeQueda === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 11))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 10)
            || editQuestTen === true)
          && editQuestEleven !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="10-10"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest11"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            cuantoDineroTeQueda !== '' && edadParaTestamentar === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 11 })}
        />
        <CardTwo
          classCard={
            cuantoDineroTeQueda !== ''
            && (editQuestEleven === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 11))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={cuantoDineroTeQueda}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número doce */}
      <QuestionsOrder
        classCard={
          cuantoDineroTeQueda !== ''
          && (editQuestEleven === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 11))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={11}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((cuantoDineroTeQueda !== '' && edadParaTestamentar === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 12))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 11)
            || editQuestEleven === true)
          && editQuestTwelve !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="11-11"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest12"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            edadParaTestamentar !== '' && gustoPorDinero === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 12 })}
        />
        <CardTwo
          classCard={
            edadParaTestamentar !== ''
            && (editQuestTwelve === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 12))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={edadParaTestamentar}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <div id="trece"></div>
      {/* pregunta número trece */}
      <QuestionsOrder
        classCard={
          edadParaTestamentar !== ''
          && (editQuestTwelve === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 12))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={12}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          ((edadParaTestamentar !== '' && gustoPorDinero === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 13))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 12)
            || editQuestTwelve === true)
          && editQuestThirteen !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="18%"
            mtw="8%"
            wiw="60%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="90%"
            he="auto"
            className="topOne1 topOne1Init"
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="12-12"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChangeChekedGustoDinero}
              currentTraining={currentTraining}
              typeCheckBox={true}
              checkBoxName="check_2_"
            />
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="13-13"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingDivSection>
        </QuestionsFlex>
      </TrainingQuestion>
      <TrainingSectionGray
        wiw="670px"
        mw="auto"
        back="#FAFAFA"
        mtop="0"
        className={
          ((edadParaTestamentar !== '' && gustoPorDinero === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 13))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 12)
            || editQuestTwelve === true)
          && editQuestThirteen !== true
            ? ''
            : 'hidden'
        }
      >
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn1 windthmd="100%" onClick={(e) => setAnswerGustoDinero(e)}>
              Continuar
            </CardsBtn1>
          </div>
        </Container>
      </TrainingSectionGray>
      <div id="activeQuest13"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            gustoPorDinero !== '' && findeSemana === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 13 })}
        />
        <CardTwo
          classCard={
            gustoPorDinero !== ''
            && (editQuestThirteen === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 13))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={gustoDinero[0]}
          text2={gustoDinero[1]}
          text3={gustoDinero[2]}
          text4={otraOpcion2}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <div id="quice"></div>
      {/* pregunta número quince */}
      <QuestionsOrder
        classCard={
          gustoPorDinero !== ''
          && (editQuestThirteen === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 13))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={14}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          ((gustoPorDinero !== '' && findeSemana === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 15))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 13)
            || editQuestThirteen === true)
          && editQuestFifteen !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="18%"
            mtw="8%"
            wiw="60%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="90%"
            he="auto"
            className="topOne1 topOne1Init"
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="14-14"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChangeChekedDedicarFines}
              currentTraining={currentTraining}
              typeCheckBox={true}
              checkBoxName="check_3_"
            />
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="15-15"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingDivSection>
        </QuestionsFlex>
      </TrainingQuestion>
      <TrainingSectionGray
        wiw="670px"
        mw="auto"
        back="#FAFAFA"
        mtop="0"
        className={
          ((gustoPorDinero !== '' && findeSemana === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 15))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 13)
            || editQuestThirteen === true)
          && editQuestFifteen !== true
            ? ''
            : 'hidden'
        }
      >
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn1
              windthmd="100%"
              onClick={(e) => setAnswerDedicarFines(e)}
            >
              Continuar
            </CardsBtn1>
          </div>
        </Container>
      </TrainingSectionGray>
      <div id="activeQuest14"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            findeSemana !== '' && quePasaConMiDinero === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 15 })}
        />
        <CardTwo
          classCard={
            findeSemana !== ''
            && showDedicarFinesSelected
            && (editQuestFifteen === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 15))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={dedicarFines[0]}
          text2={dedicarFines[1]}
          text3={dedicarFines[2]}
          text4={otraOpcion3}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número diecisiete */}
      <QuestionsOrder
        classCard={
          findeSemana !== ''
          && (editQuestFifteen === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 15))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={16}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          ((findeSemana !== '' && quePasaConMiDinero === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 17))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 15)
            || editQuestFifteen === true)
          && editQuestSeventeen !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsFlex display="inline">
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="18%"
            mtw="8%"
            wiw="60%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="90%"
            he="auto"
            className="topOne1 topOne1Init"
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="16-16"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChangeChekedPasarDinero}
              currentTraining={currentTraining}
              typeCheckBox={true}
              checkBoxName="check_4_"
            />
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="17-17"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingDivSection>
        </QuestionsFlex>
      </TrainingQuestion>
      <TrainingSectionGray
        wiw="670px"
        mw="auto"
        back="#FAFAFA"
        mtop="0"
        className={
          ((findeSemana !== '' && quePasaConMiDinero === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 17))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 15)
            || editQuestFifteen === true)
          && editQuestSeventeen !== true
            ? ''
            : 'hidden'
        }
      >
        <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
          <div style={{ display: 'flex', width: '100%' }}>
            <CardsBtn1 windthmd="100%" onClick={(e) => setAnswerPasarDinero(e)}>
              Continuar
            </CardsBtn1>
          </div>
        </Container>
      </TrainingSectionGray>
      <div id="activeQuest15"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            quePasaConMiDinero !== '' && findelMundo === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 17 })}
        />
        <CardTwo
          classCard={
            quePasaConMiDinero !== ''
            && showPasarDineroSelected
            && (editQuestSeventeen === true
              || !(lapiz[0].routine === 1 && lapiz[0].active === 17))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={pasarDinero[0]}
          text2={pasarDinero[1]}
          text3={pasarDinero[2]}
          text4={otraOpcion4}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* pregunta número diecinueve */}
      <QuestionsOrder
        classCard={
          quePasaConMiDinero !== ''
          && (editQuestFifteen === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 17))
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={18}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((quePasaConMiDinero !== '' && findelMundo === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 19))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 17)
            || editQuestFifteen === true)
          && editQuestNineteen !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="18-18"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <CardTwo
        classCard={
          findelMundo !== ''
          && (editQuestNineteen === true
            || !(lapiz[0].routine === 1 && lapiz[0].active === 19))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={findelMundo}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={findelMundo !== '' ? 'iconEditCommon' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 19 })}
        />
      </CardTwo>
    </Fragment>
  );
};

export default Perfilamiento2_0;
