import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonEnd, inputContainer } from './Styled';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 600,
    paddingLeft: 30,

  },
  input: {
    width: '90%',
    height: 60,
    color: '#ffff',
  },

}));

const FormContact = (props) => {
  const [nombreValue, setNombreValue] = useState('');
  const [correoValue, setCorreoValue] = useState('');
  const [valName, setValName] = useState(false);
  const [valMail, setValMail] = useState(false);
  const classes = useStyles();

  const ValidEmail = (name, valueEmail) => {
    let domain = valueEmail.split('@');
    domain = domain[1];

    const idmail = document.getElementsByName(name)[0];

    if (valueEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)) {
      console.log('domain 0 >> ');
      if (idmail) {
        idmail.className = 'textInput inputs-bottom invalidInput';
        setValMail(false);
      }
    } else if (domain !== '' && (domain === 'gmail.co' || domain === 'hotmail.co')) {
      console.log('domain 1 >> ');
      if (domain === 'h') {
        console.log('domain 1.5 >> ');
      } else if (domain === 'g') {
        console.log('domain 2 >> ');
      }
      if (idmail) {
        idmail.className = 'textInput inputs-bottom invalidInput';
        setValMail(false);
      }
    } else {
      console.log('domain 3 >> ');
      if (idmail) {
        idmail.className = 'textInput inputs-bottom validInput';
        setValMail(true);
      }
    }
  };

  const ValidLetras = (name, value) => {
    setNombreValue(value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''));

    const element = document.getElementsByName(name)[0];
    if (element === null) {
      console.log('es null');
    } else if (value.length > 3) {
      element.className = 'textInput inputs-bottom validInput';
      setValName(true);
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      setValName(false);
    }
  };

  const validForm = (e) => {
    e.persist();
    if (e.target.name === 'name') {
      setNombreValue(e.target.value);
      ValidLetras(e.target.name, e.target.value);
    } else if (e.target.name === 'mail') {
      setCorreoValue(e.target.value);
      ValidEmail(e.target.name, e.target.value);
    }
  };

  return (
        <div className={classes.root}>

      <inputContainer >
        <TextField
          className={classes.input}
          id="standard-full-width"
          label="Nombre"
          name="name"

          placeholder=" "
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => validForm(e)}
          value={nombreValue}/>
         <TextField
          className={classes.input}
          id="standard-full-width"
          name="mail"
          label="Correo electrónico"

          placeholder=" "
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => validForm(e)}
          value={correoValue}/>
          {
           valName && valMail
             ? <ButtonEnd className="roboto" mb=" " mt=" " > Enviar </ButtonEnd> : null }
      </inputContainer>

        </div>
  );
};

export default FormContact;

// // const ValidPhone = (name, value, id) => {
// //   setTelefonoValue(value.replace(/[^0-9]/i, ''));
// //   const tel = document.getElementsByName(name)[0];
// //   if (tel === null) {
// //      console.log('null');
// //   } else {
// //      if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
// //         tel.className = 'textInput inputs-bottom invalidInput';
// //         setValTel(false);
// //      } else {
// //         tel.className = 'textInput inputs-bottom validInput';
// //         setValTel(true);
// //      }
// //   }
// // };
