import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { GET_TRAININGS } from '../../queries';
import {
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
} from '../../assets/styles/Training.styled';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  ContEntrenamientos,
  Container,
} from '../../assets/styles/Home.styled';
import { SeparateSection, Feed_Main } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import CardOne from '../../components/Home/Card1Animate';
import image1 from '../../assets/img/anacoach/mejor tarjeta.jpg';
import Clock from '../../assets/img/anacoach/clockTraining.png';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import WOlayout from '../../components/layouts/WOlayout';
import SectionShare from '../../components/common/ShareSection';

const RpNewYearsPurpose = () => {
  const [goFeed, setGoFeed] = useState(false);
  const history = useHistory();
  let par = 1;
  const [stateInformativo, setInformativo] = useState(false);
  const [stateOutstanding, setOutstanding] = useState(false);
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  const validateTwin = [];
  const validateMedium = [];
  const validateBig = [];
  const trainingBig = [];
  const trainingVertical = []; // vertical cc
  const items4 = []; // entrenamiento destacado
  const itemsMore = []; // informativos
  const trainingTwin = []; // tiwn
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted() { },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateInformativo.indexOf(idTrainning) !== -1)) {
      const sectionInformativo = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: image1,
        TrainingRedirectUrl: '',
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      sectionInformativo.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
      if (itemsMore.length >= 1 && !stateInformativo) {
        setInformativo(true);
      }
    }
  };

  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateOutstanding.indexOf(idTrainning) !== -1)) {
      const sectionOutstanding = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: '',
        TrainingRedirectUrl: '',
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionOutstanding.Liked = arrValue.Liked;
      items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
      if (items4.length >= 1 && !stateOutstanding) {
        setOutstanding(true);
      }
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        image: '',
        Liked: '',
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      sectionVertical.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
  };
  const idUser = sessionStorage.getItem('idUser') || 0;
  const vars = { variables: { idUser: Number(idUser) }, fetchPolicy: 'no-cache' };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining) {
    const trainingList = dataTraining.getTrainings;
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      switch (training.Section) {
        case 'Big':
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          break;
        case 'Medium':
          // items2.push(sectionMedium);
          break;
        case 'Twin':
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default: break;
      }
      if (training.TrainingType === 'Informativo') {
        pushInformativo(training);
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === 'Tarjetas de crédito') {
        pushCCVertical(training);
      }
    });
  }

  return (
    <WOlayout
      backgroundColor={'#EB4040'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <HeaderSuccess
        color1="#91D770"
        color2="#E3E660"
        color3="#E3E660"
        color4="#6BD5AF"
        color5="#E3E660"
        color6="#BFDD6A"
        color7="#BFDD6A"
        color8="#6BD5AF"
        color9="#91D770"
        color10="#6BD5AF"
      />
      <TrainingMain mt="50px" mtw="0" className="" id="top">
        <br />
        <TrainingSectionGray back="#fafafa">
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <TrainingDivSection2
                paddiw="0% 10% 3% 10%"
                paddi="0% 5% 5% 5%"
                back="#fafafa"
              >
                {sessionStorage.getItem('BtnNotengoMetas') !== 'true' ? (
                  <>
                    <TrainingSubtitles
                      size="35px"
                      fw="bold"
                      align="center"
                      alignweb="center"
                      className="cabin"
                    >
                      ¡Felicidades, {sessionStorage.getItem('FirstName') || ''}!
                    </TrainingSubtitles>
                    <TrainingText
                      size="18px"
                      weight="600"
                      className="roboto"
                      align="center"
                      alignweb="center"
                      ls="-0.2px"
                    >
                      Ya tienes coach financiero
                    </TrainingText>
                  </>
                ) : null}
                <TrainingText
                  size="18px"
                  weight="600"
                  className="roboto"
                  align="center"
                  alignweb="center"
                  ls="-0.2px"
                >
                  Acabas de dar el primer paso para un año sin estrés
                  financiero.
                </TrainingText>
                <br />
                <TrainingText
                  size="16px"
                  className="roboto"
                  align="left"
                  alignweb="left"
                  ls="-0.2px"
                >
                  Estaré al pendiente de ti para asegurar que no olvides tus
                  propósitos. Recuerda no gastarte el aguinaldo completito y
                  ¡Que tengas un excelente 2022! Ah, y no olvides usar
                  cubrebocas y mantener la sana distancia para cuidar a tus
                  seres queridos.
                </TrainingText>
              </TrainingDivSection2>
              <SeparateSection />
              <TrainingSectionGray back="#fafafa">
                <TrainingDivSection2
                  wiw="670px"
                  paddi="0"
                  paddiw="0"
                  mw="auto"
                  back="#fafafa"
                >
                  <CardOne
                    classCard="cardOne"
                    classTop="topOne1"
                    textbold="¡Empieza el 2022 con el pie derecho!"
                    text="Te ayudamos a cumplir tus metas con este nuevo entrenamiento:"
                  />

                  <ContentHomeSection
                    leftD="15%"
                    floatD=" "
                    width="130%;"
                    widthM="94%"
                    marginLeftM="10px"
                  >
                    <Container width="100%" widthMobile="100%" mtMobile="0px">
                      {trainingBig.map(
                        (post) => (
                          (par = par === 0 ? 1 : 0),
                          post.name === 'Crear Meta' ? (
                            <Fragment>
                              <ContEntrenamientos background="transparent">
                                <ContEntrenamiento
                                  id={post.id}
                                  background={'#F5A838'}
                                  widthM="100%"
                                  widthD="95%"
                                  cursor="pointer"
                                >
                                  <Link
                                    to={
                                      post.TrainingRedirectUrl
                                        ? post.TrainingRedirectUrl
                                        : '#'
                                    }
                                  >
                                    <ContentTimer
                                      height=" "
                                      maxheightMobile="130px"
                                      heightMobile="140px"
                                      maxheight="107.250px"
                                      display="flex"
                                      top="0px"
                                      width="100%"
                                      position=" "
                                    >
                                      <br />
                                      <TitleEntrenamiento
                                        className="roboto"
                                        left="0px"
                                        margin="auto"
                                        top=" "
                                        widthD="230px"
                                      >
                                        {post.name}
                                      </TitleEntrenamiento>
                                      <Image
                                        src={post.image}
                                        width="25%"
                                        widthmob="28.75%"
                                        margin="auto"
                                        top="1%"
                                        bottom="1%"
                                        right="0px"
                                      />
                                    </ContentTimer>
                                    <ContentTimer>
                                      <Image
                                        src={Clock}
                                        width="20px"
                                        left="0px"
                                        position=" "
                                        top="1%"
                                      />
                                      <TextTime className="roboto">
                                        {post.time}
                                      </TextTime>
                                    </ContentTimer>
                                  </Link>
                                </ContEntrenamiento>
                              </ContEntrenamientos>
                            </Fragment>
                          ) : null
                        ),
                      )}
                    </Container>
                  </ContentHomeSection>
                </TrainingDivSection2>
              </TrainingSectionGray>
              <SectionShare WO="YearPurpose"></SectionShare>
            </Feed_Main>
          </Feed_Main>
        </TrainingSectionGray>
      </TrainingMain>
    </WOlayout>
  );
};
export default RpNewYearsPurpose;
