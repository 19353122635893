/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import { arraySessionsRutines } from '../../Helpers';
import {
  CardsBtn,
  TrainingQuestion,
  QuestionsDivs,
  QuestionsFlex,
  QuestionsText,
  Images,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import RpDebs from './RpDebs';
import CardTwo from '../../components/Home/Card2Animate';
import CardOne from '../../components/Home/Card1Animate';
import InputsOrder from '../../components/common/InputsOrder';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';

// Main Banks
import bancomer from '../../assets/img/anacoach/bancomer.png';
import amex from '../../assets/img/anacoach/amex.png';
import santander from '../../assets/img/anacoach/santander.png';
import scotia from '../../assets/img/anacoach/scotia.png';
import citi from '../../assets/img/anacoach/citi.png';
import infonavit from '../../assets/img/anacoach/Infonavit.png';
import editIcon from '../../assets/img/anacoach/editar1.png';
import Banks from '../Training/Banks';
import Recommendations from '../../components/Training/RecommendedTraining';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ModalRespConSinPremio from '../../components/Feed/ModalRespConSinPremio';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationDebts from '../../components/common/SendNotificationDebts';

const CreditoHipotecario = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const { handleSendNotification } = SendNotificationDebts();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [mortgage, setMortgage] = useState(
    sessionStorage.getItem('InstitucionHipotecaria')
      ? sessionStorage.getItem('InstitucionHipotecaria').split(',')
      : [],
  );
  const [mortgageView, setMortgageView] = useState(
    sessionStorage.getItem('InstitucionHipotecaria') || '',
  );
  const [solucionesDeudaInmueble, setSolucionesDeudaInmueble] = useState(
    sessionStorage.getItem('SolucionesDeudaInmueble') || '',
  );
  const [solucionInfonavit, setSolucionInfonavit] = useState(
    sessionStorage.getItem('SolucionInfonavit') || '',
  );
  const [form, setForm] = useState({});
  // State used for "other bank" option
  const [modalBanks, setModalBanks] = useState(false);
  const [viewProducts, setViewProducts] = useState(false);
  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 1:
        handleSendNotification({ step: '7_mail'})
        setEditSoluciones(true);
        setSolucionesDeudaInmueble(value);
        gsap.to(window, { duration: 1, scrollTo: '#activeSolucionesHipoteca' });
        break;
      case 2:
        setEditInfonavit(true);
        setSolucionInfonavit(value);
        gsap.to(window, { duration: 1, scrollTo: '#activeSolucionInfonavit' });
        break;
      default:
        // console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const pushMortgage = (bank) => {
    setMortgage([...mortgage, bank]);
  };
  const [changeMainDebs, setChangeMainDebs] = useState({
    bank: '',
    doClass: '',
  });
  useEffect(() => {
    const { bank, doClass } = changeMainDebs;
    let elementoDiv = '';
    const selectorCss = `div[data-maindebtsH="${bank}"]`;
    if (doClass === 'add') {
      elementoDiv = document.querySelector(selectorCss);
      elementoDiv.classList.add('border-pink-active');
    } else if (doClass === 'remove') {
      elementoDiv = document.querySelector(selectorCss);
      elementoDiv.classList.remove('border-pink-active');
    }
  }, [changeMainDebs]);
  const handleChangeMortgage = (bank) => {
    if (!mortgage.includes(bank)) {
      pushMortgage(bank);
      setChangeMainDebs({ bank, doClass: 'add' });
    }
  };

  const abrirNuevoTab = (url) => {
    // Abrir nuevo tab
    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  const setAnswer = () => {
    const click = `EnviarCreditoHipotecario_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setMortgageView(mortgage.join());
    setEditInstitucion(true);
    questions.push({
      id: 'mortgage',
      question: 1,
      value: mortgage.join(),
    });

    createJsonInput(Rutine, questions);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#activeQuestionHip2' });
  };

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editInstitucion, setEditInstitucion] = useState('');
  const [editInfonavit, setEditInfonavit] = useState('');
  const [editSoluciones, setEditSoluciones] = useState('');

  const anchop = window.screen.width;

  const handleChangeIcon = (json) => {
    setEditInstitucion(false);
    setEditSoluciones(false);
    setEditInfonavit(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  useEffect(() => {
    if (viewProducts) {
      const click = `MostrarMas_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [viewProducts]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (mortgageView === ''
          || (lapiz[0].routine === 9 && lapiz[0].active === 1))
        && editInstitucion !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeBancosHipoteca' });
        setReviewScroll(false);
      }
    }, 1500);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <>
        <div id="activeBancosHipoteca"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="Continuemos"
          hora={hora}
        />
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="¿Con qué bancos o institución financiera tienes tu hipoteca?"
          hora={hora}
        />
        <TrainingQuestion
          className={
            (mortgageView === ''
              || (lapiz[0].routine === 9 && lapiz[0].active === 1))
            && editInstitucion !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex>
            <QuestionsDivs
              className="border-pink"
              onClick={() => handleChangeMortgage('BBVA')}
              data-maindebtsH="BBVA"
            >
              <Images
                position="relative"
                width="100px"
                left="0"
                top="0"
                src={bancomer}
              ></Images>
            </QuestionsDivs>
            <QuestionsDivs
              className="border-pink"
              onClick={() => handleChangeMortgage('Citibanamex')}
              data-maindebtsH="Citibanamex"
            >
              <Images
                position="relative"
                width="100px"
                left="0"
                top="12px"
                src={citi}
              ></Images>
            </QuestionsDivs>
          </QuestionsFlex>

          <QuestionsFlex>
            <QuestionsDivs
              className="border-pink"
              onClick={() => handleChangeMortgage('Santander')}
              data-maindebtsH="Santander"
            >
              <Images
                position="relative"
                width="100px"
                left="0"
                top="12px"
                src={santander}
              ></Images>
            </QuestionsDivs>
            <QuestionsDivs
              className="border-pink"
              onClick={() => handleChangeMortgage('Scotiabank')}
              data-maindebtsH="Scotiabank"
            >
              <Images
                position="relative"
                width="100px"
                left="0"
                top="12px"
                src={scotia}
              ></Images>
            </QuestionsDivs>
          </QuestionsFlex>
          <QuestionsFlex>
            <QuestionsDivs
              className="border-pink"
              onClick={() => handleChangeMortgage('American Express')}
              data-maindebtsH="American Express"
            >
              <Images
                position="relative"
                width="90px"
                left="0"
                top="2px"
                src={amex}
              ></Images>
            </QuestionsDivs>
            <QuestionsDivs
              className="border-pink"
              onClick={() => handleChangeMortgage('Infonavit')}
              data-maindebtsH="Infonavit"
            >
              <Images
                position="relative"
                width="61px"
                left="0"
                top="2px"
                src={infonavit}
              ></Images>
            </QuestionsDivs>
          </QuestionsFlex>
          <QuestionsFlex>
            <QuestionsDivs
              className="border-pink"
              onClick={() => setModalBanks(true)}
            >
              <QuestionsText size="14px" weight="400" padd="5px">
                Otro
              </QuestionsText>
            </QuestionsDivs>
          </QuestionsFlex>
          {modalBanks ? (
            <div>
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard={modalBanks ? 'cardOne' : 'hidden'}
                classTop="topOne1"
                text="Selecciona la institución:"
                hora={hora}
              />
              <br />
              <Banks handleChangeBanks={handleChangeMortgage} />
            </div>
          ) : null}
          {((mortgage.length > 0 && mortgageView === '')
            || (lapiz[0].routine === 9 && lapiz[0].active === 1))
          && editInstitucion !== true ? (
            <div style={{ display: 'flex' }}>
              <CardsBtn
                type="button"
                min_w="100%"
                min_web="62%"
                mtopw="19px"
                mtop="19px"
                mleftweb="18%"
                onClick={() => setAnswer()}
              >
                Enviar
              </CardsBtn>
            </div>
            ) : null}
        </TrainingQuestion>
        <div id="activeQuestionHip2"></div>
        <DivRespDP>
          <img
            alt="editIcon"
            className={mortgageView !== '' ? 'iconEdit1' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 7, active: 1 })}
          />
          <CardTwo
            classCard={
              mortgageView !== ''
              && (editInstitucion
                || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={mortgageView}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>

        {/* next question */}
        <CardOne
          classCard={
            mortgageView.includes('Infonavit')
            && mortgage.length > 1
            && (editInstitucion
              || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="De acuerdo con la información de tu deuda he encontrado estas soluciones  para ti."
          text2="Te sugerimos solucionar primero tu problema con INFONAVIT y después con tu institución bancaria"
          hora={hora}
        />
        <TrainingQuestion
          className={
            ((mortgageView.includes('Infonavit')
              && mortgage.length > 1
              && solucionInfonavit === '')
              || (lapiz[0].routine === 9 && lapiz[0].active === 2))
            && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
              || editInstitucion)
            && editInfonavit !== true
              ? ''
              : 'hidden'
          }
        >
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </TrainingQuestion>
        <div id="activeSolucionInfonavit"></div>
        <CardTwo
          classCard={
            solucionInfonavit !== ''
            && (editInfonavit
              || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={solucionInfonavit}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={solucionInfonavit !== '' ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 7, active: 2 })}
          />
        </CardTwo>
        {/* next question */}
        <CardOne
          classCard={
            !mortgageView.includes('Infonavit')
            && mortgage.length >= 1
            && mortgageView !== ''
            && (editInstitucion
              || !(lapiz[0].routine === 9 && lapiz[0].active === 1))
            && !(lapiz[0].routine === 9 && lapiz[0].active === 2)
              ? 'cardOne'
              : 'hidden'
          }
          classTop="topOne1"
          text="He encontrado dos soluciones ideales para ti. Elige la que mejor se acomode a tus necesidades."
          hora={hora}
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        />
        <TrainingQuestion
          className={
            ((!mortgageView.includes('Infonavit')
              && mortgage.length >= 1
              && mortgageView !== ''
              && solucionesDeudaInmueble === '')
              || (lapiz[0].routine === 9 && lapiz[0].active === 3))
            && (!(lapiz[0].routine === 9 && lapiz[0].active === 1)
              || editInstitucion
              || !(lapiz[0].routine === 9 && lapiz[0].active === 2))
            && editSoluciones !== true
              ? ''
              : 'hidden'
          }
        >
          {orderRutine === currentRoutine ? (
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          ) : null}
        </TrainingQuestion>
        <div id="activeSolucionesHipoteca"></div>
        <CardTwo
          classCard={
            solucionesDeudaInmueble !== ''
            && (editSoluciones
              || !(lapiz[0].routine === 9 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={solucionesDeudaInmueble}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              solucionesDeudaInmueble !== '' ? 'iconEditCommon' : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 9, active: 3 })}
          />
        </CardTwo>
        {(editSoluciones
          || !(lapiz[0].routine === 9 && lapiz[0].active === 3))
        && (editInfonavit
          || !(lapiz[0].routine === 9 && lapiz[0].active === 2)) ? (
          <Fragment>
            {/* cuadros */}
            <CardOne
              classCard={
                (mortgageView.includes('Infonavit') && mortgage.length === 1)
                || solucionInfonavit === 'Soluciona tu problema con el INFONAVIT'
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              text="De acuerdo con la información de tu deuda he encontrado la solución ideal para ti. "
              text2="Solicita el Flexipago Infonavit, que ofrece esquemas de prórrogas y protección de pagos, ya sea por desempleo, disminución de ingresos y/o incapacidad."
              text3=""
              hora={hora}
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            >
              <p
                className="cursor-p"
                onClick={() => abrirNuevoTab(
                  'https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/trabajadores/tengo-un-credito/soluciones/!ut/p/z1/jZDNCoJQEEafpYXLnE8tsXYWdiv6IUqy2YSG3QTzhlnS22e1Ciqb3QznDDMfMQXEWXhNZFgkKgvTqt-wvbUFMOy3jBnsVR_uYLzAdC5MLEHrJ2A6gOjBmAln6FSAN_I6E8sUvkH8j48v5eI__wfAv9eviZ_Itw_aqAGMOuCRQd0VY2KZqugVuJtFliOJ83gf53GuX_JqfCiK07mrQUNZlrpUSqaxvlNHDZ-UgzoXFLyTdDr6foBk1OToVrqNxh04ckCL/dz/d5/L2dBISEvZ0FBIS9nQSEh/',
                )
                }
              >
                Llama a Infonatel al 55 9171 5050 en la Ciudad de México o al
                800 008 3900 desde cualquier parte del país. También puedes
                averiguar más del Flexipago aquí.
              </p>
            </CardOne>

            <CardOne
              classCard={
                solucionesDeudaInmueble
                === 'Quiero deshacerme de la deuda, aunque pierda derechos sobre la propiedad.'
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              textbold="Si quieres deshacerte de la deuda, aunque pierdas derechos sobre la propiedad:"
              text="Traspaso de deuda hipotecaria, además de ceder los derechos de propiedad"
              text2="Esta opción solo puede realizarse con el visto bueno de quien da el crédito, y muy importante: averigua los antecedentes del nuevo propietario del crédito."
              text3="Te recomiendo leer este artículo:"
              hora={hora}
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            >
              <a
              href='https://www.elfinanciero.com.mx/inmobiliario/como-se-hace-un-traspaso-de-credito-hipotecario-estos-pasos-te-ayudaran'
              target='_blanks'
              >
              ¿Cómo se hace un traspaso de crédito hipotecario?
              </a>
             
            </CardOne>

            <CardOne
              classCard={
                solucionInfonavit === 'Soluciona tu problema con el banco'
                || solucionesDeudaInmueble
                  === 'Quiero organizar mi deuda hipotecaria, y conservar el inmueble'
                  ? 'cardOne'
                  : 'hidden'
              }
              classTop="topOne1"
              textbold="Si quieres organizar tu deuda hipotecaria, y conservar el inmueble:"
              text="Acércate al banco o acreedor y expón tus problemas financieros para seguir pagando el crédito, así como tu buena voluntad de ponerse al corriente. La entidad verificará si calificas a una de las siguientes opciones:"
              hora={hora}
            >
              <li id="lidHipoteca">
                1. <b>Reestructura.</b> Si tu acreedor es un banco, puedes
                solicitar una reestructura de pagos, similar a los créditos al
                consumo.
              </li>
              <li id="lidHipoteca">
                2. <b>Seguro de desempleo.</b> La mayoría de las hipotecas
                contratadas con bancos incluyen un seguro de desempleo, o algún
                tipo de protección al crédito en caso de dejes de pagarlo. Por
                lo general, el seguro del crédito hipotecario cubre impago por
                un número limitado de mensualidades que van de 3 hasta 12 meses.
              </li>
              <li id="lidHipoteca">
                3. <b>Transfiere tu hipoteca</b> a otra institución que ofrezca
                mejores términos de tu deuda
              </li>
              <CardsBtn
                type="button"
                min_web="100%"
                onClick={() => setViewProducts(true)}
              >
                Mostrar más
              </CardsBtn>
              <br />
              <br />
            </CardOne>
            {(solucionInfonavit === 'Soluciona tu problema con el banco'
              || solucionesDeudaInmueble
                === 'Quiero organizar mi deuda hipotecaria, y conservar el inmueble')
            && nextOrderRoutine === 'rp'
            && viewProducts ? (
              <RpDebs
                Date={Date}
                hora={hora}
                currentTraining={currentTraining}
                insuranceActive={[
                  'RESUELVE TU DEUDA',
                  'SCOTIABANK liquidar crédito hipotecario',
                  'HSBC cambia tu crédito hipotecario',
                  'BBVA mejora condiciones de crédito hipotecario',
                  'BANORTE transfiere crédito hipotecario',
                ]}
              />
              ) : null}

            {recomendaciones && mortgageView !== '' ? (
              <Feed_Main width="100%" background=" ">
                <Feed_Main
                  width="100%"
                  display=" "
                  mtop=" "
                  background="transparent"
                >
                  <CardOne
                    classCard="cardOne"
                    classTop="topOne1"
                    text="¿Aún quieres ir por más?"
                    text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                  />
                  <CardOne
                    classCard="cardOne"
                    classTop="topOne1"
                    text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                    classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  />
                  <Feed_Section padding="0 0 5% 0" paddingm="0% 5% 5% 5%">
                    <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                      <Recommendations
                        items={recommendedTraining}
                      ></Recommendations>
                    </ContentHomeSection>
                  </Feed_Section>
                </Feed_Main>

                <ModalRespConSinPremio
                  show={
                    sessionStorage.getItem('medium') === 'recompensas'
                  }
                  premio={
                    sessionStorage.getItem('campaign') === 'con-premio'
                  }
                />
              </Feed_Main>
            ) : null}
            {recomendaciones && mortgageView !== '' ? (
              <>
                <ModalAmazon show={true} wo={'Deudas'}></ModalAmazon>
                <SectionShare WO="Deudas"></SectionShare>
              </>
            ) : null}
          </Fragment>
          ) : null}
      </>
  );
};

export default CreditoHipotecario;
