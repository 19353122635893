import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  TrainingMain,
  TrainingDivSection,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import {
  Rectangle24, Vector2, Vector3, ContentHomeSection,
} from '../common/Home.styled';
import { ParagrapTwo } from '../common/common.styled';
import ImageCoachInicio from './ImageCoachInicio';
import { ImageSticker } from '../../assets/styles/Feed.styled';
import gymOn from '../../assets/img/anacoach/WO.svg';
import ClickButton from '../../Helpers/HookHelpers';
import ShareCommonFeed from '../../anaCoach/Training/ShareCommonFeed';
import InfoWoMonetizable from '../../components/common/InfoWoMonetizable';

const SuccessWOValid = ({
  fullName, phone, email, meetsCondition, idTraining, title, txt1, txt2, shared,
}) => {
  const [goFeed, setGoFeed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { actions } = ClickButton();

  const overwrite = () => {
    sessionStorage.setItem(`overwrite_WO_${idTraining}`, true);
    sessionStorage.removeItem('UsoDinero');
    sessionStorage.removeItem('DIneroNecesidad');
    sessionStorage.removeItem('TiempoParaPagarPrestamo');
    sessionStorage.removeItem('Ingreso_Mensual_Fijo');
    sessionStorage.removeItem('Ocupacion');
    sessionStorage.setItem('DataPerson', 'true');
    window.location.reload();
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '400px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '450px',
    },
  }));

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const ModalBudget = () => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(true);

    const handleClose = () => {
      setOpen(false);
      setShowModal(false);
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    };

    return (
            <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo
                        fontWeight="700"
                        cursor="pointer"
                        texttalign="right"
                        textalignMobile="right"
                        className="mx-auto"
                        onClick={handleClose}
                    >
                        X
                 </ParagrapTwo>
                    <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;">
                        <ContentHomeSection leftD="0%" floatD="left" width="48%;"></ContentHomeSection>
                        <ImageCoachInicio />
                        <ContentHomeSection leftD="0%" floatD="left" width="35%;"></ContentHomeSection>
                    </ContentHomeSection>
                    <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
                        <Rectangle24
                            positionD="absolute"
                            Ptop="125px"
                            PTopM="-65px"
                            Pleft="-10%"
                            background="#6BD5AF"
                            displayM="block"
                            displayD=" "
                            left=" "
                            top=" "
                            width="70px"
                            height="70px"
                            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
                        />
                        <Vector2
                            width="55px"
                            left="0"
                            PleftM="35%"
                            height="65px"
                            background="#91D770"
                            radius="40%"
                            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                            positionD="absolute"
                            Pleft="17%"
                            Ptop="-5px"
                            displayM="block"
                        />
                        <Vector3
                            position="absolute"
                            left=" "
                            top=" "
                            Ptop="10px"
                            Pleft="44%"
                            background=" #E3E660;"
                            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                            width="35px"
                            height="35px"
                            displayD=" "
                            displayM="none"
                        />
                        <Rectangle24
                            positionD="absolute"
                            Ptop="100px"
                            Pleft="74%"
                            PLeftM="-78%"
                            PTopM="-32%"
                            background="#BFDD6A"
                            displayM="block"
                            displayD=" "
                            left=" "
                            top=" "
                            width="30.94px"
                            height="30.94px"
                            transform="rotate(46.41deg)"
                        />
                        <Vector2
                            width="50px"
                            widthM="28.81px"
                            heightM="35.02px"
                            left=""
                            height="60px"
                            background="#6BD5AF"
                            radius="40%"
                            transform="rotate(-8.34deg)"
                            positionD="absolute"
                            Pleft="52%"
                            PleftM="-15%"
                            PTopM="-10%"
                            MtopM="-15%"
                            Ptop="135px"
                            displayM="block"
                        />
                        <Vector3
                            position="absolute"
                            left=" "
                            top=" "
                            Ptop="85px"
                            Pleft="14%"
                            PleftM="-3%"
                            background="#E3E660"
                            transform="rotate(-15.06deg)"
                            width="35px"
                            height="35px"
                            displayD=" "
                            displayM="block"
                        />
                        <Rectangle24
                            widthM="35px"
                            heightM="35px"
                            mLeftM="0"
                            mTopM="18%"
                            positionD="absolute"
                            Pleft="70%"
                            Ptop="0"
                            displayD=""
                            width="60px"
                            height="60px"
                            left=" "
                            top=" "
                            background="#91D770"
                            transform="rotate(-17.24deg)"
                        />
                        <ShareCommonFeed
                            url="feed"
                            trainingName="Logros"
                            textShare="Ayuda a que más personas eliminen su estrés financiero con Coru. Comparte en:"></ShareCommonFeed>
                    </ContentHomeSection>
                </div>
            </Modal>
    );
  };

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${idTraining}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);
  return (
        <Fragment>
            <TrainingMain wiw="670px">
                <ContentHomeSection displayD="flex" leftD="0%" floatD="left" width="100%;" topD="-50px">
                    <ContentHomeSection leftD="0%" floatD="left" width="48%;"></ContentHomeSection>
                    <ImageCoachInicio/>
                    <ContentHomeSection leftD="0%" floatD="left" width="35%;"></ContentHomeSection>
                </ContentHomeSection>
                <TrainingDivSection back="transparent" backw="transparent" bsha="" mt="100px" mtw="100px" br="4px" padding="21px 20px" wi="100%" wiw="670px" he="100%" hew="100%">
                    <ParagrapTwo texttalign="center" textalignMobile="center" className='mx-auto roboto'>
                        <b>{title}</b>
                    </ParagrapTwo>

                    <br />

                    <ParagrapTwo texttalign="center" textalignMobile="center" className='mx-auto roboto'>
                        {txt1}
                    </ParagrapTwo>

                    {txt2
                      ? <ParagrapTwo texttalign="center" textalignMobile="center" className='mx-auto roboto'>
                            {txt2}
                        </ParagrapTwo>
                      : null
                    }

                    <br />
                    <InfoWoMonetizable 
                      nameClient={fullName}
                      emailClient={email}
                      telClient={phone}
                    />
                    <div>
                        {meetsCondition
                          ? <CardsBtn
                                windthmd="90%"
                                minmd="40%"
                                mleftmd="5%"
                                mrightmd="auto"
                                windthG="400px"
                                min_w="400px"
                                min_web="400px"
                                min_mini="80%"
                                mleftmini="10%"
                                mrightmini="10%"
                                mleft="15%"
                                mright="15%"
                                mleftweb="18%"
                                mrightweb="auto"
                                mtop="25px"
                                mtopw="25px"
                                type="button"
                                onClick={() => overwrite()}>
                                Iniciar solicitud de nuevo
                            </CardsBtn>
                          : null
                        }
                        {shared
                          ? <CardsBtn
                                windthmd="90%"
                                minmd="40%"
                                mleftmd="5%"
                                mrightmd="auto"
                                windthG="400px"
                                min_w="400px"
                                min_web="400px"
                                min_mini="80%"
                                mleftmini="10%"
                                mrightmini="10%"
                                mleft="15%"
                                mright="15%"
                                mleftweb="18%"
                                mrightweb="auto"
                                mtop="25px"
                                mtopw="25px"
                                type="button"
                                onClick={() => setShowModal(true)}>
                                La solicitud es para alguien más
                            </CardsBtn>
                          : null
                        }
                        <CardsBtn
                            windthmd="90%"
                            minmd="40%"
                            mleftmd="5%"
                            mrightmd="auto"
                            windthG="400px"
                            min_w="400px"
                            min_web="400px"
                            min_mini="80%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="15%"
                            mright="15%"
                            mleftweb="18%"
                            mrightweb="auto"
                            mtop="25px"
                            mtopw="25px"
                            type="button"
                            onClick={() => setGoFeed(true)}
                            color="#EC407A !important"
                            back="#fff !important"
                            border="solid 1px !important"
                            padd="8px 15px 4px"
                            heigth="49px"
                            className=""
                        >
                            <ImageSticker marg='-5px 20px auto auto' width='35px' src={gymOn} />
                            <b>Ir a entrenamientos</b>
                        </CardsBtn>
                    </div>
                </TrainingDivSection>
            </TrainingMain>
            {showModal
              ? <ModalBudget />
              : null
            }
        </Fragment>
  );
};
export default SuccessWOValid;
