/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import {
  ModalBackground,
  ModalBackCC,
  ModalHeader,
  ModalBody,
  Label_txt,
  Home_hr,
} from "../../assets/styles/Home.styled";
import { Rectangle23, Title1 } from "./Home.styled";
import { CardsBtn } from "../../assets/styles/Training.styled";
import { Image } from "./common.styled";
import {
  INSERT_DAT_VIRALITY,
  GENERATE_SHORTENED_LINK,
  COUNT_MODAL_VIEWS,
} from "../../mutations";
import { viralityUTM } from "../../Helpers/HelperUTM";
import { shareCoru2 } from "../../Helpers/index";
import Ana from "../../assets/img/anacoach/anavalordinero.png";
import Cine from "../../assets/img/premiosModal/boletoscine.svg";
import Zoom from "../../assets/img/premiosModal/zoom.svg";
import Saldo from "../../assets/img/premiosModal/saldo.svg";
// import Amazon from '../../assets/img/premiosModal/amazon.svg';
// import Uber from '../../assets/img/premiosModal/uber.svg';
// import Spotify from '../../assets/img/premiosModal/spotify.svg';
// import Netflix from '../../assets/img/premiosModal/netflix.svg';
// import Google from '../../assets/img/premiosModal/googlePlay.svg';
// import Starbucks from '../../assets/img/premiosModal/starbucks.svg';
// import Xbox from '../../assets/img/premiosModal/xbox.svg';

const ModalVirality2 = (props) => {
  console.log(props.openMod);
  const urlPDF =
    "https://resources-new-coru.s3.us-east-2.amazonaws.com/downloadable/TyC+Rewards+Octubre.pdf";
  const name = sessionStorage.getItem("FirstName");
  const [clickRecomienda, setClickRecomienda] = useState(1);
  const [rewardSelected, setRewardSelected] = useState("");
  const [modalState, setModalState] = useState("flex");
  const [operadorTel, setOperadorTel] = useState("");
  const [rewardsData, setRewardsData] = useState([
    ["Boletos de Cine", "Boletos de Cine", Cine],
    ["Coaching de Finanzas Personales", "Coaching finanzas", Zoom],
    ["Saldo para Celular", "Saldo celular", Saldo],
    // ['Café Starbucks', 'Café Starbucks', Starbucks],
    // ['Saldo para Google Play', 'Saldo Google', Google],
    // ['Saldo para Uber', 'Saldo Uber', Uber],
    // ['Tarjeta Amazon', 'Tarjeta Amazon', Amazon],
    // ['Tarjeta Netflix', 'Tarjeta Netflix', Netflix],
    // ['Tarjeta Spotify', 'Tarjeta Spotify', Spotify],
    // ['Tarjeta Xbox', 'Tarjeta Xbox', Xbox],
  ]);
  const [counterModal] = useMutation(COUNT_MODAL_VIEWS, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.response !== "") {
        console.log("SUCCESS MONGO COUNTER VIEW");
      }
    },
  });
  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    onCompleted({ generateShortendLink }) {
      const url = generateShortendLink.response;
      return url;
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === "success") {
        const { response } = insertDatVirality;
        const redirectLink = response.split(",")[0];
        const socialNetwork = response.split(",")[1];
        const announcement = sessionStorage.getItem("auxAnnouncement");
        const textWa =
          "https://api.whatsapp.com/send?text=%20Regístrate%20en%20Coru,%20obtén%20tu%20tarjeta%20de%20crédito%20y%20ayúdame%20a%20ganar.%20%20Además,%20¡recomienda%20y%20gana%20tu%20también!%20🎁%20%20";
        // const textWa = `whatsapp://send?text=%20Regístrate%20en%20Coru,%20obtén%20tu%20tarjeta%20de%20crédito%20y%20ayúdame%20a%20ganar.%20%20Además,%20¡recomienda%20y%20gana%20tu%20también!%20🎁%20%20`;

        shareCoru2(
          socialNetwork,
          announcement,
          redirectLink,
          generateShortendLink,
          false,
          textWa
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleChangeRadio = (e) => {
    const { value } = e.target;
    setOperadorTel(value);
  };

  const handleShare = () => {
    const cellPhone = sessionStorage.getItem("CellPhone");
    const name = sessionStorage.getItem("FirstName");
    const email = sessionStorage.getItem("Email1");
    sessionStorage.setItem("auxAnnouncement", "CoruRewardsCC");
    viralityUTM(
      insertDatVirality,
      "/wo/card-funnel",
      sessionStorage.getItem("trainingName"),
      "Whatsapp", // socialNetwork,
      "",
      "",
      "",
      false,
      false,
      rewardSelected,
      cellPhone,
      name,
      email,
      operadorTel
    );
    setModalState(false);
  };

  const handleChangeModal = () => setClickRecomienda(clickRecomienda + 1);

  const handleClickReward = (rewardSelect) => {
    setRewardSelected(rewardSelect);
    handleChangeModal();
  };

  useEffect(() => {
    const IDUser = sessionStorage.getItem("idUser");
    const IdTraining = sessionStorage.getItem("idTraining");
    const data = JSON.stringify({
      IDUser,
      View_Modal: true,
      IdTraining,
    });
    if (IDUser && IDUser !== "" && IdTraining && IdTraining !== "") {
      counterModal({
        variables: {
          input: {
            collection: "Rewards_count",
            data,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    window.document.body.style.overflowY = "hidden";
    setRewardsData(() => rewardsData.sort(() => Math.random() - 0.5));
  }, []);

  const ContainerRewards = styled.div`
    height: 20rem;
    width: 100%;
    margin: 15px 5px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: auto;
    margin-top: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 12rem;
      margin-top: 10px;
    }
    @media (max-width: 700px) {
      width: 100%;
      margin-top: 10px;
    }
  `;
  const Rewards = styled.div`
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 100%;
    img {
      width: 3rem;
      height: 1.5rem;
      margin-right: 5px;
    }
    @media (max-width: 768px) {
      margin-top: 5px;
      margin-left: 2.5%;
      margin-right: 5%;
      span {
        font-size: 12px;
      }
    }
    @media (max-width: 290px) {
      margin-top: 5px;
      span {
        font-size: 10px;
      }
    }
  `;

  return (
    <>
      <ModalBackground display={modalState}>
        <ModalBackCC
          mwidthM="400px"
          height={clickRecomienda !== "" ? "580px" : "95%"}
          height768={clickRecomienda !== "" ? "50%" : "95%"}
          height440="55%"
          height375="70%"
          height320="95%"
        >
          <Rectangle23
            position="absolute"
            background="#91D770"
            Mleft="-30px"
            Mtop="-30px"
            width="60px"
            height="60px"
            left=" "
          />
          <Rectangle23
            position="absolute"
            background="#E3E660"
            Mleft="90px"
            Mtop="-9px"
            width="28px"
            height="32px"
            transform="rotate(45deg)"
            left="58px"
            top="-27px"
          />
          <Rectangle23
            position="absolute"
            background="#6BD5AF"
            Mleft="190px"
            Mtop="-30px"
            width="30.36px"
            height="30px"
            transform="rotate(45deg)"
            left="171px"
          />
          <Rectangle23
            position="absolute"
            background="#E3E660"
            Mleft="280px"
            Mtop="40px"
            width="42.53px"
            height="45.22px"
            transform="rotate(45deg)"
            left=" "
          />
          <div
            style={{
              position: "absolute",
              right: "20px",
              top: "10px",
              fontSize: "18px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={
              props.openMod === undefined || props.openMod === null
                ? () => setModalState("none")
                : props.openMod
            }
          >
            <CloseIcon />
          </div>
          {clickRecomienda === 1 ? (
            <>
              <ModalHeader
                heightM={clickRecomienda !== 0 ? "20%" : "15%"}
                height={clickRecomienda !== 0 ? "20%" : "15%"}
                mtopM="2%"
              >
                <Title1
                  size="22px"
                  aling="center"
                  mt=" "
                  sizeM="17px"
                  mleftM=" "
                  mtopM=" "
                  heightM=" "
                >
                  ¡Recomienda y gana <br />
                  con Coru!
                </Title1>
              </ModalHeader>
              <ModalBody>
                <span
                  style={{
                    padding: "2px auto",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {name}, por cada persona que invites, Coru te regala
                  cualquiera de los siguientes premios:
                </span>
                <ContainerRewards>
                  {rewardsData.map((reward, index) => (
                    <Rewards key={index}>
                      <div>
                        <img src={reward[2]} alt={reward[1]} />
                      </div>
                      <div>
                        <span>{reward[1]}</span>
                      </div>
                      <div>
                        <CardsBtn
                          font="10px"
                          min_w="100px"
                          min_web="80px"
                          minmd="80px"
                          mtop="0px"
                          mtopw="0px"
                          mtopmd="0px"
                          padd="6px 3px"
                          onClick={() => handleClickReward(reward[0])}
                        >
                          Recomienda y Gana
                        </CardsBtn>
                      </div>
                    </Rewards>
                  ))}
                </ContainerRewards>
                <div
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  *Al dar click en <b>Recomienda y gana</b> aceptas
                  <span style={{ color: "#F64282" }}>
                    <u
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(urlPDF, "_blank").focus();
                      }}
                    >
                      {" "}
                      Términos y Condiciones
                    </u>
                  </span>
                </div>
                <div style={{ fontSize: "12px", textAlign: "center" }}>
                  **Limitado a 10 premios acreditados por persona.
                </div>
              </ModalBody>
            </>
          ) : clickRecomienda === 2 ? (
            rewardSelected === "Saldo para Celular" ? (
              <>
                <ModalHeader
                  heightM={clickRecomienda != "" ? "42%" : "15%"}
                  height={clickRecomienda != "" ? "42%" : "15%"}
                >
                  <Title1
                    size="23px"
                    aling="center"
                    mt=" "
                    sizeM="18px"
                    mleftM=" "
                    mtopM=" "
                    heightM=" "
                  >
                    ¿Cuál es tu operador
                    <br />
                    de telefonía?
                  </Title1>
                </ModalHeader>
                <ModalBody>
                  <form style={{ padding: "0px 1rem" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label_txt> AT{"&"}T </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="ATT"
                        style={{ width: "1rem", height: "1rem" }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                    <Home_hr marginTop=".5rem" marginBottom=".5rem" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label_txt> Telcel </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="TELCEL"
                        style={{ width: "1rem", height: "1rem" }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                    <Home_hr marginTop=".5rem" marginBottom=".5rem" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label_txt> Movistar </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="MOVISTAR"
                        style={{ width: "1rem", height: "1rem" }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                    <Home_hr marginTop=".5rem" marginBottom=".5rem" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label_txt> Otro </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="OTRO"
                        style={{ width: "1rem", height: "1rem" }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                  </form>
                  <CardsBtn
                    mtopmd="20px"
                    mtop="20px"
                    mtopw="20px"
                    min_w="100%"
                    min_web="100%"
                    onClick={() => setClickRecomienda(3)}
                  >
                    Continuar
                  </CardsBtn>
                  <CardsBtn
                    mtopmd="20px"
                    mtop="20px"
                    mtopw="20px"
                    min_w="100%"
                    min_web="100%"
                    back="white"
                    color="#F64282"
                    border="1px solid #F64282"
                    onClick={() => setClickRecomienda(1)}
                  >
                    Ir por otro premio
                  </CardsBtn>
                </ModalBody>
              </>
            ) : (
              <>{setClickRecomienda(3)}</>
            )
          ) : clickRecomienda === 3 ? (
            <>
              <ModalHeader
                heightM={clickRecomienda != "" ? "42%" : "15%"}
                height={clickRecomienda != "" ? "42%" : "15%"}
              >
                <Image src={Ana} width="90px" bottom="20px" />
                <Title1
                  size="23px"
                  aling="center"
                  mt=" "
                  sizeM="18px"
                  mleftM=" "
                  mtopM=" "
                  heightM=" "
                >
                  ¡Recomienda y gana <br />
                  con Coru!
                </Title1>
              </ModalHeader>
              <ModalBody>
                <span style={{ padding: "12px 5px" }}>
                  Elegiste {rewardSelected}.
                </span>
                <br />
                <br />
                <span style={{ padding: "12px 5px" }}>
                  Por cada registro válido de tus invitados, ¡podrás ganar un
                  premio diferente! &#127873;
                </span>
                <br />
                <br />
                <span style={{ padding: "12px 5px" }}>Comparte ahora:</span>
                <CardsBtn
                  mtopmd="20px"
                  mtop="20px"
                  mtopw="20px"
                  min_w="100%"
                  min_web="100%"
                  onClick={() => handleShare()}
                >
                  Compartir por WhatsApp
                </CardsBtn>
                <CardsBtn
                  mtopmd="20px"
                  mtop="20px"
                  mtopw="20px"
                  min_w="100%"
                  min_web="100%"
                  back="white"
                  color="#F64282"
                  border="1px solid #F64282"
                  onClick={() => setClickRecomienda(1)}
                >
                  Ir por otro premio
                </CardsBtn>
              </ModalBody>
            </>
          ) : (
            <></>
          )}
        </ModalBackCC>
      </ModalBackground>
    </>
  );
};

export default ModalVirality2;
