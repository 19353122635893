import React, { Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Inicio_Title,
  Inicio_Texts,
  Inicio_Container,
  Inicio_Options,
  ContainerRutinas,
  ImageShare,
  TextRutinas,
} from '../../assets/styles/pantInicio.styled';
import imgcompara from '../../assets/img/anacoach/comparaCard.jpg';
import imgobten from '../../assets/img/anacoach/obtenCard.jpg';

function ParaTi() {
  const history = useHistory();
  const firtsName = sessionStorage.getItem('firstName');
  const secondName = sessionStorage.getItem('middleName');

  const handleClick = (e) => {
    console.log(e.target);
  };

  const handleClickOption = (e) => {
    if (e.target.id === 'enMarcha') {
      history.push('/thegym/enmarcha/');
    } else {
      history.push('/thegym/categorias/');
    }
  };

  return (
    <Fragment>
      <div className="container text-center">
        <div className="col-12 col-md-6 m-auto">
          <Inicio_Title className="text-center col-8 mx-auto font-weight-bold">
            ¡Hola {`${firtsName} ${secondName}`}!
          </Inicio_Title>
          <Inicio_Container>
            <Inicio_Options onClick={handleClickOption} id="enMarcha">
              En marcha
            </Inicio_Options>
            <Inicio_Options border_c="#EC407A" color="#EC407A">
              Para ti
            </Inicio_Options>
            <Inicio_Options id="categorias" onClick={handleClickOption}>
              Categorías
            </Inicio_Options>
          </Inicio_Container>
          <Inicio_Texts fontWeight="bold" className="col-12 mx-auto">
            Recomendados para ti
          </Inicio_Texts>
          <Inicio_Texts mt="2%" fontSize="18px" ta="left">
            Elige un entrenamiento para mejorar tu perfil financiero y lograr
            tus metas.
          </Inicio_Texts>
          <br></br>
          <ContainerRutinas onClick={handleClick}>
            <ImageShare
              name="comparaTarjetas"
              src={imgcompara}
              className="img-fluid"
            />
            <TextRutinas mt="-32%" mb="0px" fw="bold">
              Compara tu tarjeta
            </TextRutinas>
            <TextRutinas fs="17px" mt="2%">
              Te ayudaré a evaluar si tu tarjeta es la mejor para ti
            </TextRutinas>
          </ContainerRutinas>
          <Link to="/wo/first-card">
            <ContainerRutinas id="comparaObten" mb="30%">
              <ImageShare src={imgobten} className="img-fluid" />
              <TextRutinas mt="-42%" mb="0px" fw="bold">
                Obtén tu primer tarjeta
              </TextRutinas>
              <TextRutinas fs="17px" mt="2%">
                ¿Cómo obtener tu primer tarjeta de crédito?
              </TextRutinas>
            </ContainerRutinas>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default ParaTi;
