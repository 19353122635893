import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_BANKS } from '../../queries';
import {
  QuestionsDivs,
  DivLoader,
  QuestionsText,
} from '../../assets/styles/Training.styled';

const Banks = ({ handleChangeBanks }) => {
  const curretTraining = JSON.parse(sessionStorage.getItem('currentTraining'));
  const [banks, setBanks] = useState([]);
  const [banksActive, setBanksActive] = useState({});
  const { data, loading, error } = useQuery(GET_BANKS, {
    variables: {
      idTraining:
        curretTraining === null ? 1 : parseInt(sessionStorage.getItem('idTraining')),
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const staticBanks = [
        'bancomer',
        'citibanamex',
        'santander',
        'scotiabank',
        'american express',
      ];
      const { getBanks } = data;
      const newData = getBanks.filter(
        (bank) => staticBanks.indexOf(bank.ShowName1.toLowerCase()) === -1,
      );
      setBanks(newData);
    }
  }, [data]);

  if (loading) {
    return (
      <DivLoader>
        <CircularProgress color="inherit" />
      </DivLoader>
    );
  }
  if (error) {
    return <h3>Error</h3>;
  }
  const banksOrder = banks.sort((a, b) => (a.ShowName1 < b.ShowName1 ? -1 : +(a.ShowName1 > b.ShowName1)));
  const BanksActiveSetter = (idx) => {
    setBanksActive({
      ...banksActive,
      [idx]: !banksActive[idx],
    });
  };

  return (
    <div>
      {banksOrder.map((bank, idx) => (
        <QuestionsDivs
          className={banksActive[idx] ? 'border-pink-active' : ''}
          width="45%"
          margin="7px"
          hei="41px"
          display="inline-block"
          padd="5px 0"
          key={bank.ShowName1}
          onClick={() => {
            handleChangeBanks(bank.ShowName1);
            BanksActiveSetter(idx);
          }}
          data-MainDebts={`${bank.ShowName1}`}
        >
          <QuestionsText size="13px" weight="400" padd="0">
            {bank.ShowName1}
          </QuestionsText>
        </QuestionsDivs>
      ))}
    </div>
  );
};

export default Banks;
