import React from 'react';

import {
  Text,
  ContainerTicket,
} from '../Styleds/Asesores.Styled.js';
import 'react-perfect-scrollbar/dist/css/styles.css';

const TrainingList = ({
  pendingSessions,
  tabTraining,
  setTabTraining,
  getUserInfo,
}) => {
  const setActive = (item, index) => {
    const newArr = [...tabTraining];
    newArr.map((e, index) => {
      newArr[index].active = false;
    });
    newArr[index].active = true;
    setTabTraining(newArr);
    getUserInfo({
      idUser: item.idUser,
      idCatAsesoresDetails: item.idCatAsesoresDetails,
    });
  };
  return (
    <>
      <div className="training-list">
        {tabTraining.map((item, index) => (
          <div
            key={index}
            onClick={() => setActive(item, index)}
          >

            <ContainerTicket
              wid="110px"
              hei="38px"
              dis="flex"
              fDir="column"
              key={index}
              style={{ position: 'initial' }}
              className={
                item.active
                  ? 'training-item bg-selected'
                  : 'training-item bg-normal'
              }
              onClick={() => setActive(item, index)}
            >
              <Text className="roboto">{item.cellPhone}</Text>
            </ContainerTicket>
          </div>
        ))}
      </div>
    </>
  );
};

export default TrainingList;
