/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';

import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const PurposeReasons = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {

                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [purposeReason, setPurposeReason] = useState(
    sessionStorage.getItem('RazonesNoPropositos') || '',
  );

  const [form, setForm] = useState({});

  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        setPurposeReason(val.value);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log(val);
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setPurposeReason(sessionStorage.getItem('RazonesNoPropositos') || '');
    }
  }, [recoveryRoutine]);

  const tamScreen = window.screen.width;
  const scroller = (top_avance, top_avance_mobile) => {
    const scrol1 = document.documentElement.scrollHeight;
    if (tamScreen > 768) {
      console.log('se ejecutò este 768');

      window.scrollTo({ top: scrol1 - 700, behavior: 'smooth' });
    } else {
      console.log('se ejecutò este < 768');
      window.scrollTo({ top: scrol1 - 1000, behavior: 'smooth' });
    }
  };
  const scrollToElem = (id) => {
    switch (id) {
      case 'uso':
        scroller(950, 1200);
        break;
      case 'rapido':
        scroller(950, 1200);
        break;
      case 'futuro':
        scroller(950, 1200);
        break;
      default:
        console.log('default case');
        scroller(950, 1400);
    }
  };

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    return (
      <Container width="100%" mt="1rem" widthMobile="70%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <QuestionsOrder
          classCard='cardOne'
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />

        <TrainingQuestion
          className={purposeReason === '' ? '' : 'hidden'}
          leftg="9%"
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        {purposeReason === '' || purposeReason !== ''
          ? scrollToElem('uso')
          : null}
        <CardTwo
          classCard={purposeReason !== '' ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={purposeReason}
          colorTraining="#EB4040"
        />

        <CardOne
          classCard={purposeReason !== '' ? 'cardOne' : 'hidden'}
          classTop="topOne1"
          text={
            purposeReason === 'Porque no plantean propósitos de manera realista'
              ? '¡Correcto!'
              : '¡Incorrecto!'
          }
          text2=" Las personas no logran sus propósitos, ya que no los plantean de manera realista.
                    La mejor manera de plantear tus propósitos es con el método S.M.A.R.T. que dice que cada uno debe ser:"
          children={
            <ul
              style={{
                listStyle: 'none',
                marginLeft: '-20px',
                lineHeight: '0.85',
              }}
            >
              <li>
                - e<b>S</b>pecífico
              </li>
              <br />
              <li>
                - <b>M</b>edible
              </li>
              <br />
              <li>
                - <b>A</b>lcanzable
              </li>
              <br />
              <li>
                - <b>R</b>elevante
              </li>
              <br />
              <li>
                - <b>T</b>emporal (con fechas claras)
              </li>
            </ul>
          }
        />
        <CardOne
          classCard={purposeReason !== '' ? 'cardOne' : 'hidden'}
          classTop="topOne1"
          text="Pero recuerda que si necesitas ayuda para plantear tus propósitos, aquí estaré.
                    Déjame tus datos y me encargaré de recordarte tus metas para 2022:"
        />
      </div>
  );
};

export default PurposeReasons;
