import React from 'react';
import PymesCard from './PymesCard.js';
import PymesCardList from './PymesCardList.js';
import PymesCardCalCredit from './PymesCardCalCredit.js';
import {
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import {
  Col7,
  Container,
} from '../../components/common/common.styled';

const PymesCardsOne = ({
  title,
  nextQuestionQueEs,
  handleNextQuestion,
  classAna,
}) => (
    <>
      <Container
        width="80%"
        mt="0px"
        widthMobile="100%"
        mtMobile="0"
        displayweb="flex"
      >
        {classAna === 'trueAna' ? (
          <ImageCoachInicio classAna={classAna} topMobile="35px" />
        ) : null}
        <Col7
          flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
          maxwidth="100%"
          wiSm="90%"
          padding=" "
          className="cardPrincipal1"
        >
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="85%"
            he="auto"
            mb="0px"
            className="topOne1Init"
            mlM="10%"
          >
            <PymesCard
              title={title}
              text1="Un crédito PyME es un financiamiento creado para apoyar la sostenibilidad y crecimiento de las pequeñas y medianas empresas."
              text2="Este crédito puede ser de 3 tipos: corto, mediano y largo plazo"
              nextQuestion={nextQuestionQueEs}
              onClick={handleNextQuestion}
            />
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
);

const PymesCardsTwo = ({
  title,
  nextQuestionQuienOtorga,
  handleNextQuestion,
  classAna,
}) => (
    <>
      <Container
        width="80%"
        mt="0px"
        widthMobile="100%"
        mtMobile="0"
        displayweb="flex"
      >
        {classAna === 'trueAna' ? (
          <ImageCoachInicio classAna={classAna} topMobile="35px" />
        ) : null}
        <Col7
          flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
          maxwidth="100%"
          wiSm="90%"
          padding=" "
          className="cardPrincipal1"
        >
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="85%"
            he="auto"
            mb="0px"
            className="topOne1Init"
            mlM="10%"
          >
            <PymesCardList
              title={title}
              text="Ellos otorgan estos créditos y estas son sus características."
              subtitle=" Gobierno: "
              subtitle2="Bancos tradicionales:"
              subtitle3="Financieras no bancarias:"
              text1="Tasas de interés bajas."
              text2="Alta Demanda."
              text3="Tiempo de espera Alto."
              text4="Tasas de interés bajas."
              text5="Requisitos excesivos."
              text6="Aprobación de crédito baja."
              text7="Tasas de  interés y plazos accesibles."
              text8="Utiliza  tecnología  para dar mejor servicio."
              text9="Tiempo de respuesta más rápido."
              nextQuestion={nextQuestionQuienOtorga}
              onClick={handleNextQuestion}
            />
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
);

const PymesCardsThree = ({
  title,
  nextQuestionTipoCreditoPyme,
  handleNextQuestion,
  classAna,
}) => (
    <>
      <Container
        width="80%"
        mt="0px"
        widthMobile="100%"
        mtMobile="0"
        displayweb="flex"
      >
        {classAna === 'trueAna' ? (
          <ImageCoachInicio classAna={classAna} topMobile="35px" />
        ) : null}
        <Col7
          flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
          maxwidth="100%"
          wiSm="90%"
          padding=" "
          className="cardPrincipal1"
        >
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="85%"
            he="auto"
            mb="0px"
            className="topOne1Init"
            mlM="10%"
          >
            <PymesCard
              title={title}
              text="Estos son los diferentes tipos de crédito y sus características."
              subtitle=" Crédito simple:"
              subtitle2="Crédito revolvente:"
              subtitle3="Crédito arrendamiento:"
              text1="Es una línea de crédito donde la persona prestataria obtiene un monto, un plazo determinado para pagarlo y el CAT promedio de su deuda."
              text2="En este caso, la persona prestataria puede disponer de más de una vez del capital, después de haber pagado cierto porcentaje de su deuda, o bien, la totalidad de la misma dependiendo de su contrato."
              text3="Su objetivo es el uso o goce temporal de un bien, con la característica de que la persona arrendataria no tiene opción de compra del bien al término de la vigencia del contrato."
              nextQuestion={nextQuestionTipoCreditoPyme}
              onClick={handleNextQuestion}
            />
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
);

const PymesCardsFour = ({
  title,
  nextQuestionComoUsan,
  handleNextQuestion,
  classAna,
}) => (
    <>
      <Container
        width="80%"
        mt="0px"
        widthMobile="100%"
        mtMobile="0"
        displayweb="flex"
      >
        {classAna === 'trueAna' ? (
          <ImageCoachInicio classAna={classAna} topMobile="35px" />
        ) : null}
        <Col7
          flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
          maxwidth="100%"
          wiSm="90%"
          padding=" "
          className="cardPrincipal1"
        >
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="85%"
            he="auto"
            mb="0px"
            className="topOne1Init"
            mlM="10%"
          >
            <PymesCardList
              title={title}
              text="Estos créditos se usan:"
              subtitle=" Para adquirir activo fijo: "
              subtitle2="Para adquirir tecnología o hacer investigación: "
              subtitle3=" Para capital de trabajo:"
              text1="Cubrir gastos de remodelación del inmueble."
              text2="Comprar equipo/máquinas, etc."
              styleli="none"
              text4="Contratar software."
              text5="Realizar investigaciones o estudios de mercado."
              text7="Pagar sueldos/salarios"
              text8="Cubrir servicios."
              text9="Y más..."
              nextQuestion={nextQuestionComoUsan}
              onClick={handleNextQuestion}
            />
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
);

const PymesCardsFive = ({
  title,
  nextQuestionGarantias,
  handleNextQuestion,
  classAna,
}) => (
    <>
      <Container
        width="80%"
        mt="0px"
        widthMobile="100%"
        mtMobile="0"
        displayweb="flex"
      >
        {classAna === 'trueAna' ? (
          <ImageCoachInicio classAna={classAna} topMobile="35px" />
        ) : null}
        <Col7
          flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
          maxwidth="100%"
          wiSm="90%"
          padding=" "
          className="cardPrincipal1"
        >
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="85%"
            he="auto"
            mb="0px"
            className="topOne1Init"
            mlM="10%"
          >
            <PymesCard
              title={title}
              text="Estos son los diferentes tipos de crédito y sus características."
              subtitle="Prendaria o hipotecaria:"
              subtitle2="Garantía personal:"
              text1="La persona solicitante del crédito cuenta con un bien, con el cual se puede cubrir la deuda en caso de no poder pagarla."
              text2="Si no se cuenta con un bien prendario o hipotecario, se puede recurrir a la figura de un aval u obligado solidario quien se compromete a pagar la deuda en caso de que la persona prestataria no pueda cumplir con ella."
              nextQuestion={nextQuestionGarantias}
              onClick={handleNextQuestion}
            />
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
);

const PymesCardsSix = ({
  title,
  nextQuestionRequisitos,
  handleNextQuestion,
  classAna,
}) => (
    <>
      <Container
        width="80%"
        mt="0px"
        widthMobile="100%"
        mtMobile="0"
        displayweb="flex"
      >
        {classAna === 'trueAna' ? (
          <ImageCoachInicio classAna={classAna} topMobile="35px" />
        ) : null}
        <Col7
          flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'}
          maxwidth="100%"
          wiSm="90%"
          padding=" "
          className="cardPrincipal1"
        >
          <TrainingDivSection
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="85%"
            he="auto"
            mb="0px"
            className="topOne1Init"
            mlM="10%"
          >
            <PymesCardCalCredit
              title={title}
              text="Estos son los requisitos y sus características."
              nextQuestion={nextQuestionRequisitos}
              onClick={handleNextQuestion}
            />
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
);

export {
  PymesCardsOne,
  PymesCardsTwo,
  PymesCardsThree,
  PymesCardsFour,
  PymesCardsFive,
  PymesCardsSix,
};
