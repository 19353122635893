import React from 'react';

import ana from '../../assets/img/anacoach/CoachImage.png';
import play from '../../assets/img/anacoach/play.png';
import clock from '../../assets/img/anacoach/clock.png';
import {
  VideoMain, ImageSticker, VideoText, Video_Details,
} from '../../assets/styles/Feed.styled';

function VideoSection({ time, text }) {
  return (
        <VideoMain>
            <ImageSticker src={ana} width='55px' className="img-fluid" />
            <VideoText>
                <b>{text}</b>
                <br />
                <Video_Details>
                    <ImageSticker src={clock} width='15px' className="img-fluid" /> {time} min
                </Video_Details>
            </VideoText>
            <ImageSticker src={play} width='30px' className="img-fluid" />
        </VideoMain>
  );
}
export default VideoSection;
