/* eslint-disable no-unused-vars */

const updateEventDataInfobip = () => {
    const phoneCell = sessionStorage.getItem('CellPhone') || '';
    const email = sessionStorage.getItem('Email1') || '';
    window.pe.setPerson({ phone: `52${phoneCell}` });
    window.pe.updatePerson({
        firstName: sessionStorage.getItem('FirstName') || '',
        lastName: `${sessionStorage.getItem('FathersLastName') || ''} ${sessionStorage.getItem('MothersLastName') || ''}`,
        customAttributes: {
            'BC Score': sessionStorage.getItem('BCScore') || '',
        },
        contactInformation: {
            email: [
                {
                    address: email,
                },
            ],
            phone: [
                {
                    number: `52${phoneCell}`
                }
            ]
        },
    });
};

export const INFOBIP_ACTIONS = {
    CREDIT_CARD: {
        APLICAR_TODAS: () => {
            updateEventDataInfobip();
            // Evento: ocaSend
            // Etiqueta: ocaSend
            window.pe.track('ocaSend', { ocaSend: true });
        },
        OTRAS_OPCIONES: () => {
            updateEventDataInfobip();
            // Evento: ocaSend
            // Etiqueta: ocaSend
            window.pe.track('ocaSend', { ocaSend: false });
        },
        INGRESO_MENOS15K_SCORE_BAJO: () => {
            // BCScore Bajo <540
            updateEventDataInfobip();

            // Evento: rpUno
            // Etiqueta: rpUno
            window.pe.track('rpUno', { rpUno: true });
        },
        INGRESO_MAS15K_SCORE_BAJO: () => {
            // BCScore Bajo <540
            updateEventDataInfobip();
            // Evento: rpDos
            // Etiqueta: bienFinanDK
            window.pe.track('rpDos', { rpDos: true });
        },
        INGRESO_MENOS15K_SCORE_MEDIO: () => {
            //BCScore 540< Medio <640
            updateEventDataInfobip();

            // Evento: rpUno
            // Etiqueta: rpUno
            window.pe.track('rpUnoMedio', { rpUnoMedio: true });
        },
        INGRESO_MAS15K_SCORE_MEDIO: () => {
            //BCScore 540< Medio <640

            updateEventDataInfobip();
            // Evento: rpDos
            // Etiqueta: bienFinanDK
            window.pe.track('rpDosMedio', { rpDosMedio: true });
        },
        INGRESO_MENOS15K_SCORE_ALTO: () => {
            // BC Score Alto >640
            updateEventDataInfobip();

            // Evento: rpUno
            // Etiqueta: rpUno
            window.pe.track('rpUnoAlto', { rpUnoAlto: true });
        },
        INGRESO_MAS15K_SCORE_ALTO: () => {
            // BC Score Alto >640
            updateEventDataInfobip();
            // Evento: rpDos
            // Etiqueta: bienFinanDK
            window.pe.track('rpDosAlto', { rpDosAlto: true });
        },
        STICKINESS: () => {
            updateEventDataInfobip();
            // Evento: rpDos
            // Etiqueta: bienFinanDK
            window.pe.track('datosCCSend', { datosCCSend: true });
        }
    }
};
