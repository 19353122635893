import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';
import {
  Main, Container, Col12, ParagrapTwo,
} from '../../components/common/common.styled';
import FooterFeed from '../../components/Feed/Footer_Feed';
import '../../assets/styles/perfil/perfiles.css';
import Header_Logros from '../../components/Perfiles/Header_Perfil';
import NoTraining from '../../components/Logros/NoTraining';
import TestViralidad from '../../components/Logros/TestViralidad';
import { DYNAMIC_GET } from '../../mutations';
import ListItemsTrainings from '../../components/Feed/TrainingComplete';

function Training_completed({ Novisible, paddingInicio, isViernes }) {
  const [entrenamientos, setentrenamientos] = useState(0);
  const [ejecuto, setejecuto] = useState(false);
  const [Trainings, SetTrainigs] = useState([]);
  let Training;
  const ArrayP = [];
  const ArrrayS = [];

  const ConstruccionArray = (items2) => {
    setentrenamientos(items2.length);
    const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
    for (let i = 0; i < items2.length; i += LONGITUD_PEDAZOS) {
      const pedazo = items2.slice(i, i + LONGITUD_PEDAZOS);
      ArrayP.push(pedazo);
    }
    SetTrainigs([ArrayP]);
  };
  const [getAllTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        Training = JSON.parse(dynamicGet.response);
        for (let i = 0; i < Training.length; i++) {
          if (Training[i].Achievment >= 100) {
            ArrrayS.push(Training[i]);
          }
        }
        if (ArrrayS.length === 0) {
          return;
        }
        if (!ejecuto) {
          ConstruccionArray(ArrrayS);
          setejecuto(true);
        }
      }
    },
  });

  const getAllTrainingFunction = () => {
    const getAllTrainingInput = {
      input: {
        table: 'CatRelUsersTraining, DatTraining, CatTraining',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
          {
            logic: 'AND',
            valone: 'CatRelUsersTraining.IDDatTraining',
            condition: '=',
            valtwo: 'DatTraining.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDCatTraining',
            condition: '=',
            valtwo: 'CatTraining_IDTraining',
            validColumn: true,
          },
        ],
      },
    };
    getAllTraining({ variables: getAllTrainingInput });
  };

  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
    getAllTrainingFunction();
  }, []);
  return (
        <Fragment>
            {!Novisible ? (<Header_Logros name={sessionStorage.getItem('firstName')}/>) : (<div></div>)}
            <Main minheigth="auto" background='#fafafa' mt="0%" mtWeb="0%" ptuno={!paddingInicio ? '70px' : ' ' } pt={!paddingInicio ? '80px' : ' ' }>
                { isViernes
                  ? <TestViralidad/> : null
                }
                <Container id="Completed" displayweb="block" className="container" paddingMobile="0!important" width="94%" widthMobile="100%" mtMobile="0px">
                    <ParagrapTwo
                        mb="10%" fontSize="20px" fontSizeWeb="20px" mt="5%" mbweb="35px"
                        textalignMobile="left"
                        texttalign="left"
                        className="mx-auto roboto" mtWeb="10%"
                        fontWeight="bold">
                        Haz completado:
                    </ParagrapTwo>
                    {Trainings.length === 0 ? (
                      <NoTraining tipo={'concluidos'}></NoTraining>
                    )
                      : (
                      <Fragment>
                        <ContainerPerfiles padding="4%" height="auto" width="80%" minheight="122px" display="flex" mb="5%" background="#fff">
                          <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto">
                              <Col12 ta="center" pt="0px" >
                                <ParagrapTwo mt="0px" fontSize="40px" color="#F64282" fontSizeWeb="40px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>{entrenamientos}</ParagrapTwo>
                              </Col12>
                              <Col12 ta="center" pt="0px" >
                                  <ParagrapTwo mt="0px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>Entrenamientos</ParagrapTwo>
                              </Col12>
                          </Container>
                        </ContainerPerfiles>
                        <ListItemsTrainings
                          items={Trainings}
                        ></ListItemsTrainings>
                      </Fragment>
                      )
                    }
                </Container>
            </Main>
            {!Novisible ? (<FooterFeed check='logros'/>) : (<div></div>)}
        </Fragment>
  );
}
export default Training_completed;
