/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const TipsInversionesV2 = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  nextOrderRoutineOpt,
  setOnlyOptInvest,
  saveAdvance,
  setActivarFlujo,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [decalogoInversor, setDecalogoInversor] = useState('');
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});

  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log('linea 83 tips', Rutine, questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          let contVideos = 0;
          const stopRoutine = false;
          // eslint-disable-next-line guard-for-in
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  console.log(
                    'linea 141 tips',
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (questionType === 'Video') {
                contVideos++;
                console.log('video ', contVideos);
              }
            }

            if (
              arrayResponseRutine.length + contVideos
              < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length + contVideos,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              console.log('recuperando rutina 174 tips');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              // setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editDecalogoInv, setEditDecalogoInv] = useState('');

  const handleChangeIcon = (json) => {
    setEditDecalogoInv(false);
    setDecalogoInversor('');
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  useEffect(() => {
    if (decalogoInversor === 'Sí') {
      //  gsap.to(window, { duration: 1, scrollTo: '#videoDecalogo' });
    } else if (decalogoInversor === 'No') {
      gsap.to(window, { duration: 1, scrollTo: '#PrimerTip' });
    }
  }, [decalogoInversor]);

  const [inicioDec, setInicioDec] = useState(true);
  useEffect(() => {
    if (inicioDec) {
      setTimeout(() => {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
        gsap.to(window, { duration: 1, scrollTo: '#inicio' });
        setInicioDec(false);
      }, 1500);
    }
  }, []);
  useEffect(() => {
    console.log('340', form);
  }, [form]);
  const handleChangeCatOption = ({ value, step }) => {
    console.log('92', value, step);
    switch (step) {
      case 0:
        setEditDecalogoInv(true);
        setDecalogoInversor(value);
        if (value === 'Obtener tips para invertir') {
          console.log('linea 271 tips');
          saveAdvance(parseInt(currentRoutine));
          setOrderRutine(nextOrderRoutine);
        } else if (value === 'Conocer opciones para invertir') {
          saveAdvance(parseInt(currentRoutine));
          setOnlyOptInvest(true);
          setOrderRutine(nextOrderRoutineOpt);
        } else {
          setActivarFlujo('perfilInversionista');
        }
        break;
      default:
        console.log('default break handleChangeCatOption case 4');
        break;
    }
  };
  return (
    <>
      {/* -----------
        First question
        -------------*/}
      <div id="inicio"></div>
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (decalogoInversor === ''
            || (lapiz[0].routine === 3 && lapiz[0].active === 1))
          && editDecalogoInv !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="PrimerTip"></div>
      <div>
        <img
          alt="editIcon"
          className={
            decalogoInversor !== ''
            && (editDecalogoInv === true
              || !(lapiz[0].routine === 5 && lapiz[0].active === 1))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 1 })}
        />

        <CardTwo
          classCard={
            decalogoInversor !== ''
            && (editDecalogoInv === true
              || !(lapiz[0].routine === 5 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={decalogoInversor}
          colorTraining={trainingColor}
        ></CardTwo>
      </div>
    </>
  );
};

export default TipsInversionesV2;
