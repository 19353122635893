/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import React, { Fragment, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import {
  TrainingQuestion,
  TrainingText,
  TrainingDivSection,
  CardsBtn,
  QuestionsDivs,
} from '../../assets/styles/Training.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7, Image } from '../../components/common/common.styled';

import { Title1 } from '../../components/common/Home.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import confetis from '../../assets/img/anacoach/Hero.png';
import CardComponents from '../../components/Home/Card1AnimateComponents';

const styles = {
  paddingLeft: '10px',
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  letterSpacing: '-0.3px',
  textAlign: 'left',
};
const stylesResult = {
  marginTop: '10px',
  textAlign: 'left',
  fontSize: '16px',
  padding: '2% 2% 2% 3%',
  border: '1px solid #888',
  color: '#EC407A',
  borderRadius: '4px',
};

const AforeCalculator = ({
  // Date,
  // hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  currentRoutine,
  nextOrderRoutine,
}) => {
  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const [Rutine, setRutine] = useState({});
  const [countRecovery] = useState(0);
  const [anchop, setAnchop] = useState(0);

  const [savingsGoal, setSavingsGoal] = useState('');
  const [missingMoney, setMissingMoney] = useState('');
  const [showAforeCalculated, setShowAforeCalculated] = useState(false);

  const [form, setForm] = useState({
    IngresoMensualAfore: '',
    DineroRetiroAfore: '',
    AniosDeRetiroAfore: '',
  });
  const [reviewScroll, setReviewScroll] = useState(true);
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }
              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setTimeout(() => setRecoveryRoutine('activa'), 1000);
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setTimeout(() => setRecoveryRoutine('activa'), 1000);
                setQuestions([]);
                break;
              } else {
                setTimeout(() => setRecoveryRoutine('activa'), 1000);
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleChangeRutine = (data) => setRutine(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'AniosDeRetiroAfore') {
      setForm({
        ...form,
        [name]: value,
      });
    } else {
      const val = value.replace('$', '').replace(/,/g, '').trim();
      setForm({
        ...form,
        [name]: formatter.format(filterFloat(val)),
      });
    }
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        break;
      default:
        console.log(val);
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  const calculateMyAfore = () => {
    let aux = '';
    const montlhyRetirement = convertirNum(form.DineroRetiroAfore);
    const monthlyIncome = convertirNum(form.IngresoMensualAfore);
    const yearsRetirement = convertirNum(form.AniosDeRetiroAfore);

    const monthlySavings = Math.round(
      montlhyRetirement / (yearsRetirement * 12),
    );
    aux = formatter.format(filterFloat(monthlySavings));
    setSavingsGoal(aux);

    const missing = Math.round(monthlySavings - 0.065 * monthlyIncome);

    aux = formatter.format(filterFloat(missing));
    setMissingMoney(aux);
    if (!isEmptyObject(Rutine)) {
      questions.push(
        {
          id: 'IngresoMensualAfore',
          question: '1',
          value: form.IngresoMensualAfore,
        },
        {
          id: 'DineroRetiroAfore',
          question: '2',
          value: form.DineroRetiroAfore,
        },
        {
          id: 'AniosDeRetiroAfore',
          question: '3',
          value: form.AniosDeRetiroAfore,
        },
      );
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }

    setShowAforeCalculated(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeAfore' });
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && !showAforeCalculated
        && document.getElementById('activeCalculator') !== null
        && document.getElementById('activeCalculator') !== undefined
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeCalculator' });
        setReviewScroll(false);
      }
    }, 3000);
  }, []);
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      if (
        sessionStorage.getItem('AñosDeRetiroAfore')
        || sessionStorage.getItem('DineroRetiroAfore')
        || sessionStorage.getItem('IngresoMensualAfore')
      ) {
        form.AniosDeRetiroAfore = sessionStorage.getItem('AñosDeRetiroAfore');
        form.DineroRetiroAfore = sessionStorage.getItem('DineroRetiroAfore');
        form.IngresoMensualAfore = sessionStorage.getItem(
          'IngresoMensualAfore',
        );

        calculateMyAfore();
      }
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setTimeout(() => setRecoveryRoutine('inactiva'), 1000);
    }
  }, []);
  useEffect(() => {
    setAnchop(window.screen.width);
  }, [window.screen.width]);

  const [viewBtn, setViewBtn] = useState(true);
  const setNext = () => {
    setOrderRutine(nextOrderRoutine);
    setViewBtn(false);
  };
  const [feacturesArray] = useState([]);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      <div id="activeCalculator"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos."
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        textbold="¡Excelente! 👍"
        text="Ahora te haré algunas preguntas para calcular tu Afore ideal."
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={!showAforeCalculated ? '' : 'hidden'}
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <Fragment>
            <Container
              width="80%"
              mt="0"
              widthMobile="90%"
              mtMobile="0"
              mleft="18%"
              mlMobile="auto"
            >
              <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
                <TrainingDivSection
                  hew="auto"
                  className="topOne1"
                  paddw="26px"
                  mlw="0%"
                  mtw="8%"
                  wiw="100%"
                  back="#F3F3F3"
                  backw="#F3F3F3"
                  bshaw="none"
                  bsha=""
                  br="4px"
                  padding="20px"
                  wi="100%"
                  he="auto"
                >
                  <TrainingText
                    bot="0px"
                    className="cabin"
                    align="left"
                    weight="600"
                    size="20px"
                    sizew="20px"
                  >
                    Calculemos tu Afore ideal
                  </TrainingText>

                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    step="0-0"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />

                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    step="1-1"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />
                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    step="2-2"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />

                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    onClick={() => calculateMyAfore()}
                  >
                    Calcular mi Afore
                  </CardsBtn>
                </TrainingDivSection>
              </Col7>
            </Container>
          </Fragment>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeAfore"></div>
      <TrainingDivSection
        className={showAforeCalculated ? '' : 'hidden'}
        backw="#f8f9fa00"
        padding="0px"
        paddw="0px"
        mb="0"
        wi="90%"
        wiw="100%"
        he="auto"
        hew="auto"
        bsha="null"
      >
        <TrainingQuestion id="SituacionActual">
          <TrainingDivSection
            back="transparent"
            backw="transparent"
            mlw="18%"
            paddw="0px"
            wiw="60%"
            padding="0px"
          >
            <div style={{ width: '100%' }}>
              <Image
                src={confetis}
                height="238px"
                marginLeftM="0px"
                heightMobile="200px"
                margin="0px"
                top="110px"
                left="0%"
                mleft="0%"
                marginLeft="0%"
                mtop="75px"
              />
              <Title1
                style={{ fontStyle: 'normal' }}
                backgroundM="transparent"
                pbottom="6%"
                background="#FAFAFA"
                position="sticky"
                positionM="absolute"
                mleft="auto"
                mright="auto"
                heightM="auto"
                aling="center"
                className="roboto cabin"
                mb="30%"
                mbM="0px"
                left="0%"
                size="35px"
                mtopM="-20%"
                mt="-147px"
                mleftM="0px"
                sizeM="20px"
                widthM="100%"
                width="68%"
              >
                ¡{sessionStorage.getItem('FirstName') || 'Laura'}, calculamos tu{' '}
                <br /> Afore ideal!
              </Title1>
            </div>
            <TrainingDivSection
              bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
              mt="0px"
              mtw="-20%"
              br="4px"
              padding="15px"
              wi="auto"
              wiw="auto"
              he="auto"
              mb="0px"
            >
              <div
                className="wo-input"
                style={{ marginTop: '0px', padding: '0px' }}
              >
                <br />
                <label style={styles}>
                  <b>¿Cuál es tu ingreso mensual?</b>
                </label>
                <p style={stylesResult}>{form.IngresoMensualAfore}</p>
                <br />

                <label style={styles}>
                  <b>¿Con cuánto dinero te gustaría retirarte?</b>
                </label>
                <p style={stylesResult}>{form.DineroRetiroAfore}</p>
                <br />

                <label style={styles}>
                  <b>¿En cuántos años te gustaría retirarte?</b>
                </label>
                <p style={stylesResult}>{form.AniosDeRetiroAfore} años</p>
                <br />

                <label style={styles}>
                  <b>Esto tendrías que ahorrar al mes para alcanzar tu meta.</b>
                </label>
                <p style={stylesResult}>{savingsGoal}</p>
                <br />

                <label style={styles}>
                  <b>Esto te falta mensualmente para alcanzar tu meta.</b>
                </label>
                <p style={stylesResult}>{missingMoney}</p>
              </div>
            </TrainingDivSection>
          </TrainingDivSection>
        </TrainingQuestion>
      </TrainingDivSection>
      <div className={showAforeCalculated ? '' : 'hidden'}>
        <CardComponents
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          Arraytext={feacturesArray}
          text="Estos son cálculos con base en el 6.5% de tu salario mensual base de aportaciones que realizas tú, tu patrón y el gobierno."
        >
          {viewBtn ? (
            <CardsBtn
              min_w="100%"
              windthLg="100%"
              max_w="250"
              onClick={() => setNext()}
            >
              Continuar
            </CardsBtn>
          ) : null}
        </CardComponents>
      </div>
    </div>
  );
};

export default AforeCalculator;
