import React, { Fragment, useState, useEffect } from "react";
import { gsap } from "gsap";
import TagManager from 'react-gtm-module';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CardOne from "../../components/Home/Card1Animate";
import InputsOrder from "../../components/common/InputsOrder";
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  TrainingText,
  TrainingSectionGray,
  ErrorDiv,
  TrainingDivSection,
  DivRespDP
} from "../../assets/styles/Training.styled";
import CardTwo from "../../components/Home/Card2Animate";
import ImageCoachInicio from "../../components/Training/ImageCoachInicio";
import LoadingWo from "../../components/Training/loadingWo";
import {
  Container,
  Col7,
} from "../../components/common/common.styled";
//recovery
import { RECOVERY_ROUTINE_BY_ORDER } from "../../queries";
import { useLazyQuery } from "@apollo/client";
import { arraySessionsRutines } from "../../Helpers";
import QuestionsOrder from "../../components/common/QuestionsOrder";
import { useHistory } from "react-router-dom";
import { WEB_SERVICE_CI } from '../../mutations';
import { useMutation } from '@apollo/client';
import Recommendations from "../../components/Training/RecommendedTraining";
import SelectSeguros from '../../components/common/SelectSeguros';
import editIcon from '../../assets/img/anacoach/editar1.png';

const CarInfo = ({
  trainingColor,
  setOrderRutine,
  nextOrderRoutine
}) => {
  const anchop = window.screen.width;

  let currentRoutine = nextOrderRoutine - 1;

  const [models, setModels] = useState([]);
  const [currentModel, setCurrentModel] = useState('');

  const [makers, setMakers] = useState([])
  const [currentMaker, setCurrentMaker] = useState('')

  const [submakers, setSubMakers] = useState([])
  const [currentSubMaker, setCurrentSubMaker] = useState('')

  const [version, setVersion] = useState([]);
  const [currentVersion, setCurrentVersion] = useState('');

  const [subVersion, setSubVersion] = useState([]);
  const [currentSubVersion, setCurrentSubVersion] = useState('');

  const [detail, setDetail] = useState([]);
  const [currentDetail, setCurrentDetail] = useState('');

  const [transmission, setTransmission] = useState([]);
  const [currentTransmission, setCurrentTransmission] = useState('');

  const [selectModel, setSelectModel] = useState(false);
  const [selectMaker, setSelectMaker] = useState(false);
  const [selectSubMaker, setSelectSubMaker] = useState(false);
  const [selectVersion, setSelectVersion] = useState(false);
  const [selectSubVersion, setSelectSubVersion] = useState(false);
  const [selectDetail, setSelectDetail] = useState(false);
  const [selectTransmission, setSelectTransmission] = useState(false);
  const [flagSuccess, setFlagSuccess] = useState(false)
  const [webServiceCI] = useMutation(WEB_SERVICE_CI, {
    fetchPolicy: 'no-cache',
    onCompleted({ webServiceCI }) {
      let tmp_array = [];
      let response = {};
      switch (webServiceCI.message) {
        case 'getYears':
          response = JSON.parse(webServiceCI.response)
          for (let model of response.ListadoDescripciones) {
            tmp_array.push({ value: model['Modelo'], label: model['Modelo'] })
          }
          setModels(tmp_array)
          setCurrentModel(models[0])
          break;
        case 'getMakers':
          response = JSON.parse(webServiceCI.response);
          for (let maker of response.ListadoMarcas) {
            tmp_array.push({ value: maker['Marca'], label: maker['Marca'], img: maker['img'] })
          }
          setMakers(tmp_array)
          break;
        case 'getDescription':
          response = JSON.parse(webServiceCI.response);
          for (let submaker of response.descripcion) {
            tmp_array.push({ value: submaker, label: submaker })
          }
          let cadena = response.cadena.split('|');
          if (response.id) {
            setOrderRutine(nextOrderRoutine);
            setFlagSuccess(true)
            sessionStorage.setItem('Year',cadena[0]);
            sessionStorage.setItem('Marca',cadena[1]);
            sessionStorage.setItem('Model',cadena[2]);
            sessionStorage.setItem('Submodel',cadena[3]);
            sessionStorage.setItem('Detail',cadena[4]);
            sessionStorage.setItem('Diferenciador', cadena[5]);
            sessionStorage.setItem('Transmision', cadena[6]);      
            sessionStorage.setItem('cadena', response.cadena);      
            sessionStorage.setItem('idCar', response.id);      
          }
          switch (cadena.length) {
            case 2:
              setSubMakers(tmp_array);
              break;
            case 3:
              setVersion(tmp_array);
              break;
            case 4:
              setSubVersion(tmp_array);
              break;
            case 5:
              setDetail(tmp_array);
              break;
            case 6:
              setTransmission(tmp_array);
              break;
          }
          break;
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    let inputGetModels = {
      input: {
        action: "getYears",
        param: ""
      }
    }
    webServiceCI({ variables: inputGetModels });
  }, []);
  const getMaker = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectYear',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetMakers = {
      input: {
        action: "getMakers",
        param: currentModel
      }
    }
    webServiceCI({ variables: inputGetMakers });
    setSelectModel(true);
  }

  useEffect(() => {
    getMaker();
  }, [currentModel]);

  const getSubMaker = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectMaker',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetSubMakers = {
      input: {
        action: "getDescription",
        param: `${currentModel}|${currentMaker}`
      }
    }
    setSelectMaker(true);
    webServiceCI({ variables: inputGetSubMakers });
  }

  useEffect(() => {
    getSubMaker();
  }, [currentMaker]);

  const getVersion = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectSubMaker',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetSubMakers = {
      input: {
        action: "getDescription",
        param: `${currentModel}|${currentMaker}|${currentSubMaker}`
      }
    }
    setSelectSubMaker(true);
    webServiceCI({ variables: inputGetSubMakers });
  }

  useEffect(() => {
    getVersion();
  }, [currentSubMaker]);

  const getSubVersion = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectVersion',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetVersion = {
      input: {
        action: "getDescription",
        param: `${currentModel}|${currentMaker}|${currentSubMaker}|${currentVersion}`
      }
    }
    setSelectVersion(true);
    webServiceCI({ variables: inputGetVersion });
  }

  useEffect(() => {
    getSubVersion();
  }, [currentVersion]);

  const getDetail = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectSubVersion',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetSubMakers = {
      input: {
        action: "getDescription",
        param: `${currentModel}|${currentMaker}|${currentSubMaker}|${currentVersion}|${currentSubVersion}`
      }
    }
    setSelectSubVersion(true);
    webServiceCI({ variables: inputGetSubMakers });
  }

  useEffect(() => {
    getDetail();
  }, [currentSubVersion]);

  const getTransmission = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectDetail',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetSubMakers = {
      input: {
        action: "getDescription",
        param: `${currentModel}|${currentMaker}|${currentSubMaker}|${currentVersion}|${currentSubVersion}|${currentDetail}`
      }
    }
    setSelectDetail(true);
    webServiceCI({ variables: inputGetSubMakers });
  }

  useEffect(() => {
    getTransmission();
  }, [currentDetail]);

  const getInfoCar = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'getInfoCar',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let inputGetSubMakers = {
      input: {
        action: "getDescription",
        param: `${currentModel}|${currentMaker}|${currentSubMaker}|${currentVersion}|${currentSubVersion}|${currentDetail}|${currentTransmission}`
      }
    }
    setSelectTransmission(true);
    webServiceCI({ variables: inputGetSubMakers });
  }

  useEffect(() => {
    getInfoCar();
  }, [currentTransmission]);

  const [lapiz, setLapiz] = useState({routine: '', active: ''});
  const [editYear, setEditYear] = useState('');
  const [editMarca, setEditMarca] = useState('');
  const [editSubmarca, setEditSubmarca] = useState('');
  const [editVersion, setEditVersion] = useState('');
  const [editSubversion, setEditSubversion] = useState('');
  const [editDetalle, setEditDetalle] = useState('');
  const [editTrainsmision, setEditTrainsmision] = useState('');

  const handleChangeIcon = (json) => {
    setEditYear(false);
    setEditMarca(false);
    setEditSubmarca(false);
    setEditVersion(false);
    setEditSubversion(false);
    setEditDetalle(false);
    setEditTrainsmision(false);
    setLapiz(json);
  };

  useEffect(() => {
    if(lapiz.routine!==''){
      setOrderRutine(lapiz.routine);
    }
  }, [lapiz]);

  return (
    <div>
      <Fragment>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard={'cardOne'}
          classTop="topOne1"
          text={"Comencemos con los datos del vehículo"}
        />                
        <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
          <ImageCoachInicio
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            topMobile="35px"
          />
          <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
            <TrainingDivSection
              hew="auto"
              className="topOne1 topOne1Init"
              paddw="26px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="100%"
              he="auto"
            >
              <Fragment className={'hidden'}>
                <SelectSeguros 
                  label={'Año de tu vehículo'} 
                  selectImage={false} 
                  currencies={models} 
                  currentData={currentModel} 
                  setData={setCurrentModel} 
                />
                {makers.length > 0 ? (
                  <SelectSeguros 
                    label={'Marca de tu vehículo'} 
                    selectImage={true} 
                    currencies={makers} 
                    currentData={currentMaker} 
                    setData={setCurrentMaker} 
                  />
                ):null}
                {submakers.length > 0 ? (
                  <SelectSeguros 
                    label={'Modelo de tu vehículo'} 
                    selectImage={false} 
                    currencies={submakers} 
                    currentData={currentSubMaker} 
                    setData={setCurrentSubMaker} 
                  />
                ):null}
                {version.length > 0 ? (
                  <SelectSeguros 
                    label={'Versión de tu vehículo'} 
                    selectImage={false} 
                    currencies={version} 
                    currentData={currentVersion} 
                    setData={setCurrentVersion} 
                  />
                ):null}
                {subVersion.length > 0 ? (
                  <SelectSeguros 
                    label={'Subversión de tu vehículo'} 
                    selectImage={false} 
                    currencies={subVersion} 
                    currentData={currentSubVersion} 
                    setData={setCurrentSubVersion} 
                  />
                ):null}
                {detail.length > 0 ? (
                  <SelectSeguros 
                    label={'Detalles de tu vehículo'} 
                    selectImage={false} 
                    currencies={detail} 
                    currentData={currentDetail} 
                    setData={setCurrentDetail}
                  />
                ):null}
                {transmission.length > 0 ? (
                  <SelectSeguros 
                    label={'Transmisión de tu vehículo'} 
                    selectImage={false} 
                    currencies={transmission} 
                    currentData={currentTransmission} 
                    setData={setCurrentTransmission} 
                  />
                ):null}
              </Fragment>
            </TrainingDivSection>
          </Col7>
        </Container>
        {flagSuccess ? (
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text={"¡Vamos muy bien! Ahora necesito algunos datos personales."}
            text2={"Ayúdame a llenar lo siguiente:"}
          />                
        ):null}
      </Fragment>
    </div >
  );
};

export default CarInfo;
