import React from 'react';
import {
  ModalBackground,
  ModalBackCC,
  ModalHeader,
  ModalBody,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle24,
  Title1,
} from './Home.styled';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';
import Ana from '../../assets/img/anacoach/anavalordinero.png';
import { Image } from './common.styled';

const ModalLearnMore = ({
  modalActive,
  setModalActive,
  handleAdvice,
  heiMod = '',
}) => {
  const name = sessionStorage.getItem('FirstName');

  const click = () => setModalActive(false);

  return (
    <>
      {
        <ModalBackground
          className={modalActive ? '' : 'hidden'}
          display={'true'}
        >
          <ModalBackCC
            height={heiMod !== '' ? heiMod : '60%'}
            height440="77%"
            height375="85%"
            height360="85%"
            height320="95%"
          >
            <Rectangle23
              position="absolute"
              background="#91D770"
              Mleft="-30px"
              Mtop="-30px"
              width="60px"
              height="60px"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="90px"
              Mtop="-9px"
              width="28px"
              height="32px"
              transform="rotate(45deg)"
              left="58px"
              top="-27px"
            />
            <Rectangle23
              position="absolute"
              background="#6BD5AF"
              Mleft="190px"
              Mtop="-30px"
              width="62.36px"
              height="67px"
              transform="rotate(45deg)"
              left="192px"
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="280px"
              Mtop="40px"
              width="42.53px"
              height="45.22px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle23
              position="absolute"
              background="#E3E660"
              Mleft="-60px"
              Mtop="140px"
              width="60px"
              height="60px"
              transform="rotate(45deg)"
              left=" "
            />
            <Rectangle24
              positionD="absolute"
              radius="50px"
              width="145px"
              left="266px"
              top="118px"
              widthM="145px"
              heightM=""
              mLeftM=" "
              mTopM=" "
            />
            <ModalHeader>
              <Image src={Ana} maxwidth="82px" />
              <Title1
                size="23px"
                aling="center"
                mt=" "
                sizeM="23px"
                mleftM=" "
                mtopM="8%"
                heightM=" "
              >
                ¡En Coru no conocemos <br />
                limites!
              </Title1>
            </ModalHeader>
            <ModalBody style={{ marginTop: '30px' }}>
              <div className="d-flex flex-column">
                <span
                  style={{ padding: '12px 5px', height: '80%' }}
                  className="roboto"
                >
                  Hola {name}, seguimos trabajando para garantizarte el acceso a
                  los mejores productos y servicios financieros.
                  <br />
                </span>
                <span style={{ padding: '20px 0 0 0 ' }} className="roboto">
                  ¿Te gustaría saber cuando esta opción forme parte de nuestro
                  catálogo?
                </span>
              </div>
              <CardsBtn
                mtopmd="20px"
                mtop="50px"
                mtopw="50px"
                min_w="100%"
                min_web="100%"
                onClick={() => handleAdvice()}
              >
                Quiero que me avisen
              </CardsBtn>
              <CardsBtn
                color="#EC407A !important"
                back="#fff !important"
                border="solid 1px !important"
                mtopmd="20px"
                mtop="20px"
                mtopw="20px"
                min_w="100%"
                min_web="100%"
                onClick={() => click()}
              >
                Salir
              </CardsBtn>
            </ModalBody>
          </ModalBackCC>
        </ModalBackground>
      }
    </>
  );
};

export default ModalLearnMore;
