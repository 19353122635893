import React, { useState } from 'react';
import './styles/customProgressSteps.css';
import Helper from '../../helper/index';

const CustomProgressSteps = ({ customColor, stepData, coachImg }) => {
  const [helpState, setHelpState] = useState(false);

  const toggleHelp = () => {
    setHelpState(!helpState);
  };

  const getSteps = () => stepData.map((step, idx) => (
      <div className="step-container">
        <div className={`number ${step.active ? 'active' : ''}`}>
          <p>{idx + 1}</p>
        </div>

        {idx + 1 < stepData.length ? <div className="line-cool"></div> : ''}
      </div>
  ));

  return (
    <>
      <div
        className="step-info-custom-progress-step"
        style={{ backgroundColor: customColor }}
      >
        <div className="step-list-container">{getSteps()}</div>

        <div className="coach-container">
          <button
            className="coach"
            style={{ backgroundColor: customColor }}
            onClick={() => toggleHelp()}
          >
            <img src={coachImg} alt="" />

            <p>AYUDA</p>
          </button>
        </div>
      </div>
      <Helper estado={helpState} setEstado={setHelpState} />
    </>
  );
};

export default CustomProgressSteps;
