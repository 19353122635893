/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingText,
  TrainingDivSection,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import { Container, Col7 } from '../../components/common/common.styled';

const UserProfiling = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  const anchop = window.screen.width;
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery, setCountRecovery] = useState(0);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let arrayResponseRutine = [];
            for (const idRoutineR in routines) {
              const videoQ = {
                idQuestion: 121,
                idType: 190,
                questionName: 'https://www.youtube.com/embed/nVIKOQoDehM',
                questionOrder: 2,
                questionType: 'Video',
                responseValue: 'https://www.youtube.com/embed/nVIKOQoDehM',
              };
              arrayResponseRutine = routines[idRoutineR].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const questionVideo = routines[idRoutineR].questions[i];
                if (questionVideo.QuestionType === 'Video') {
                  arrayResponseRutine.splice(1, 0, videoQ);
                  break;
                }
              }
            }
            console.log('Nuevo array == ', arrayResponseRutine);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length + contVideos + 1
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length + contVideos + 1,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    console.log('Entro useEffect PROFILING');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [genero, setGenero] = useState(sessionStorage.getItem('Gender') || '');
  const [zipCode, setZipCode] = useState(
    sessionStorage.getItem('ZipCode') || '',
  );
  const [selectZipCode, setSelectZipCode] = useState(false);
  const [texterror] = useState({
    zipCode: '',
  });
  const [error] = useState({
    zipCode: false,
  });
  const [form, setForm] = useState({
    zipCode: '',
  });

  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    if (e.target.name === undefined) e.target.name = 'zipCode';
    const tagManagerArgs = {
      dataLayer: {
        event: 'e.target.name',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    const value = e.target.value.substring(0, 5);
    switch (e.target.name) {
      case 'zipCode':
        setForm({
          ...form,
          [e.target.name]: value.substring(0, 5),
        });
        validDigitos('zipCode', value, e.target.id);
        console.log('zipCode');
        break;
      default:
        console.log('handleChange default case');
        break;
    }
  };
  const validDigitos = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (value.length !== 5 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu código postal';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };

  const handleChangeCp = (event) => {
    event.preventDefault();
    if (zipCode.length === 5 && zipCode !== '') {
      setZipCode(zipCode);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
      setSelectZipCode(true);
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      if (name === undefined) name = 'zipCode';
      if (value.length === 5) setZipCode(value);
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOptionUSERPROFILLE====', val);
    console.log(val.step);
    switch (val.step) {
      case 0:
        sessionStorage.setItem('Gender', val.value);
        setGenero(val.value);
        console.log('entro en el master finance');
        break;
      case 1:
        setZipCode(val.value);
        sessionStorage.setItem('CodigoPostal2', val.value);
        console.log('entro en el de video');
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setZipCode(sessionStorage.getItem('CodigoPostal2') || '');
    }
  }, [recoveryRoutine]);
  console.log(`***************${orderRutine}`);
  return (
    <div>
      <Fragment>
        <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
          <ImageCoachInicio
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            topMobile="35px"
          />
          <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
            <TrainingDivSection
              hew="auto"
              className="topOne1 topOne1Init"
              paddw="26px"
              mlw="0%"
              mtw="8%"
              wiw="100%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="100%"
              he="auto"
            >
              <TrainingText
                bot="0px"
                className="cabin"
                align="left"
                weight="400"
                size="16px"
                sizew="16px"
              >
                ¿Cuál es tu género?
              </TrainingText>
              {genero === '' ? (
                <Fragment>
                  <br />
                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="0-0"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />
                </Fragment>
              ) : null}
            </TrainingDivSection>
          </Col7>
        </Container>
        <CardTwo
          classCard={genero !== '' ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          textT={''}
          text2={genero}
          colorTraining={trainingColor}
        />
      </Fragment>
      {genero !== '' ? (
        <Fragment>
          <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
            <ImageCoachInicio
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              topMobile="35px"
            />
            <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
              <TrainingDivSection
                hew="auto"
                className="topOne1 topOne1Init"
                paddw="26px"
                mlw="0%"
                mtw="8%"
                wiw="100%"
                back="#F3F3F3"
                backw="#F3F3F3"
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"
              >
                <TrainingText
                  bot="0px"
                  className="cabin"
                  align="left"
                  weight="400"
                  size="16px"
                  sizew="16px"
                >
                  Ingresa tu código postal.
                </TrainingText>
                {zipCode === '' || !selectZipCode ? (
                  <>
                    <Fragment>
                      <InputsOrder
                        handleChangeQuestionsSend={handleChangeQuestionsSend}
                        handleChangeRutine={handleChangeRutine}
                        orderRutine={orderRutine}
                        step="1-1"
                        handleChangeCatOption={handleChangeCatOption}
                        form={form}
                        handleChange={handleChange}
                      />
                    </Fragment>
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      min_web="100%"
                      mtop="0"
                      mtopw="0"
                      mtopmd="0"
                      onClick={handleChangeCp}
                    >
                      Cotizar ahora
                    </CardsBtn>
                    <br />
                    <TrainingText size="12px" top="10px" align="justify">
                      Si das clic en el botón "Cotizar ahora", aceptas
                      nuestros Términos, Condiciones y Aviso de privacidad.
                      También, autorizo a Coru (Compara Guru México S.A. de
                      C.V.) a transferir mis datos a las instituciones
                      financieras y entidades que se indican en el Aviso de
                      Privacidad, con la finalidad de aplicar a sus propios
                      procesos de solicitud de cotización de productos
                      financieros, de seguros y de inmuebles, para que puedan
                      ofrecerme el producto que consideren adecuado a mis
                      necesidades. También autorizo que me envíen comunicación
                      de Coru y sus filiales por cualquier medio, incluyendo
                      Whatsapp.
                    </TrainingText>
                  </>
                ) : null}
              </TrainingDivSection>
            </Col7>
          </Container>
          <CardTwo
            classCard={
              zipCode !== '' && selectZipCode ? 'cardTwoResp' : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            textT={''}
            text2={zipCode}
            colorTraining={trainingColor}
          />
        </Fragment>
      ) : null}
    </div>
  );
};
export default UserProfiling;
