/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
/* eslint-disable quotes */
/* eslint-disable camelcase */
/* Home */
import styled from "styled-components";
import {
  LinkComponent,
  imageComponent,
  HrComponent,
} from "../../components/common/common";

/* General */
export const Home_Main = styled.div`
  align-items: center;
  background-color: #fff;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  height: 100%;
  margin: auto;
  width: 100%;
`;
export const Title_h1 = styled.h1`
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.fz || "52px"};
  line-height: 120%;
  letter-spacing: -0.3px;
  color: #07210a;
  text-align: ${(props) => props.align || ""};
  margin-top: ${(props) => props.top || ""};
  @media (max-width: 768px) {
    font-size: ${(props) => props.fz || "34px"};
    text-align: ${(props) => props.talign || "center"};
    margin-top: ${(props) => props.topM || props.top};
  }
`;
export const Text_p = styled.p`
  color: #110e20;
  font-size: ${(props) => props.size || "1.2em"};
  font-weight: ${(props) => props.weight || "400"};
  text-align: ${(props) => props.align || "left"};
  margin-bottom: ${(props) => props.bot || ""};
  margin-top: ${(props) => props.top || ""};
  line-height: ${(props) => props.lineMob || "1.6"};
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "20px"};
    line-height: ${(props) => props.line || "1.6"};
    text-align: ${(props) => props.alignw || "left"};
    margin-top: ${(props) => props.topw || ""};
  }
`;
export const Subtitles_Gray = styled.h5`
  font-size: 24px;
  text-align: ${(props) => props.align || "left"};
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    text-align: center;
  }
`;
export const Principal_Btn = styled(LinkComponent)`
  background-color: ${(props) => props.bgColor || "#fb245c "};
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  color: ${(props) => props.color || "white;"};
  font-size: ${(props) => props.size || "20px"};
  font-weight: ${(props) => props.fontw || "700"};
  height: auto;
  outline: none;
  padding: ${(props) => props.padd || "20px 30px"};
  text-align: center;
  margin-left: ${(props) => props.left || "auto"};
  margin-right: auto;
  max-width: ${(props) => props.width || "50%"};
  transition: 0.3s;
  &:hover {
    background-color: #d31e4d;
  }
  @media (max-width: 768px) {
    min-width: ${(props) => props.minw || "100% !important"};
    max-width: ${(props) => props.maxw || "100% !important"};
    font-size: ${(props) => props.size || "18px"};
    height: auto;
    padding: ${(props) => props.padd || "15px 20px"};
  }
`;
export const Principal_lista = styled.li`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fs || "16px"};
  line-height: ${(props) => props.lh || "20px"};
  font-weight: ${(props) => props.fw || "400"};
  margin-bottom: ${(props) => props.mb || "5px"};
  text-indent: ${(props) => props.ti || "0em"};
  @media (max-width: 768px) {
    font-size: ${(props) => props.fsM || "16px"};
    line-height: ${(props) => props.lhM || "20px"};
    font-weight: ${(props) => props.fwM || "400"};
    margin-bottom: ${(props) => props.mbM || "5px"};
    text-indent: ${(props) => props.tiM || props.ti || "0em"};
  }
`;
export const Vertical_align = styled.div`
  margin: auto;
`;
export const Flex_50 = styled.div`
  width: 50%;
  margin: auto 10px;
  text-align: ${(props) => props.ta || ""};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px auto;
    text-align: ${(props) => props.tam || ""};
  }
`;
export const Label_txt = styled.label`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: black;
`;
export const Field_txt = styled.input`
  font-size: 16px;
  width: 70%;
  padding: 9px 15px;
  margin: 0;
  margin-top: 8px;
  height: 41.69px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const More_Web = styled.div`
  position: absolute !important;
  display: block !important;
  transform: translateY(-50%) !important;
  z-index: 1 !important;
  top: ${(props) => props.top || "46.5%"};
  right: ${(props) => props.right || "12%"};
  @media (max-width: 768px) {
    display: none !important;
  }
`;
export const Btn_More = styled.button`
  display: inline-block !important;
  cursor: pointer !important;
  text-align: center !important;
  line-height: 1 !important;
  position: relative !important;
  touch-action: manipulation !important;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px 1px !important;
  border-radius: 50% !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
  background: rgb(255, 255, 255);
  width: 32px;
  height: 32px;
  &:hover {
    background-color: #f0f0f0;
  }
`;
export const Div_Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fd || ""};
  justify-content: ${(props) => props.jc || "space-around"};
  align-items: ${(props) => props.alignItems || ""};
  margin: ${(props) => props.margin || "auto"};
  margin-top: ${(props) => props.margintop || " "};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || " "};
  cursor: ${(props) => props.cursor || " "};
  @media (max-width: 768px) {
    margin-top: ${(props) => props.margintopM || " "};
  }
`;
/* End General */
export const Home_AnimateDiv = styled.div`
  margin: -60px 0 5% 0;
  background: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20% 3% 0% 3%;
  min-height: 260px;
  max-height: 650px;
  @media (min-width: 481px) {
    background: none;
    margin: -162px 0 0 0;
    height: auto;
    padding: 20% 10% 0 10%;
    text-align: center;
  }
  @media (min-width: 768px) {
    background: none;
    margin: -162px 0 0 0;
    height: auto;
    padding: 20% 5% 0 5%;
    text-align: center;
  }
  @media (min-width: 1024px) {
    background: none;
    margin: 0;
    height: auto;
    padding: 10% 6% 0 6%;
    text-align: center;
  }
  @media (min-width: 1200px) {
    background: none;
    margin: 0;
    height: auto;
    padding: 10% 0 0 0;
    text-align: center;
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const Home_SectionFlex = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: ${(props) => props.reverse || ""};
    margin-top: 5%;
  }
`;
export const Home_Subtitles = styled.h2`
  color: #110e20;
  font-size: ${(props) => props.size || "1.7em"};
  text-align: ${(props) => props.align || "justify;"};
  font-weight: 600;
  @media (min-width: 481px) {
    font-size: ${(props) => props.size || "48px"};
    text-align: ${(props) => props.align || "left;"};
    letter-spacing: -0.05em;
    font-weight: 600;
  }
`;
export const Share_Subtitles = styled.h2`
  color: #110e20;
  font-size: ${(props) => props.size || "1.7em"};
  text-align: ${(props) => props.align || "justify;"};
  font-weight: ${(props) => props.fw || "600"};
  width: 73%;
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "48px"};
    text-align: ${(props) => props.align || "left;"};
    letter-spacing: -0.04em;
    font-weight: ${(props) => props.fw || "600"};
  }
`;
export const Share_Subtitles_Feed = styled.h2`
  color: #110e20;
  font-size: ${(props) => props.sizemob || "1.7em"};
  text-align: ${(props) => props.align || "left;"};
  font-weight: ${(props) => props.fw || "600"};
  width: 100%;
  min-height: 60px;
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "48px"};
    text-align: ${(props) => props.align || "left;"};
    letter-spacing: -0.04em;
    font-weight: ${(props) => props.fw || "600"};
    min-height: 60px;
  }
`;
export const Image = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.hei || ""};
  display: ${(props) => props.display || ""};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin: ${(props) => props.margin || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-top: ${(props) => props.top || ""};
  background: ${(props) => props.back || ""};
  margin-left: ${(props) => props.left || ""};
  margin-right: ${(props) => props.right || ""};
  padding: ${(props) => props.padd || ""};
  z-index: ${(props) => props.zindex || ""};
  left: ${(props) => props.Pleft || ""};
  transform: ${(props) => props.transform || ""};
  cursor: ${(props) => props.cursor || ""};
  @media (max-width: 768px) {
    margin-bottom: ${(props) => props.bottmob || ""};
    padding: ${(props) => props.paddmob || ""};
    width: ${(props) => props.widthmob || ""};
    margin: ${(props) => props.marginM || props.margin || ""};
    height: ${(props) => props.heiM || ""};
    margin-right: ${(props) => props.right || ""};
    margin-left: ${(props) => props.mlM || ""};
    display: ${(props) => props.displayMobile || ""};
    margin-top: ${(props) => props.mtMobile || ""};
  }
  @media (min-width: 1420px) {
    max-width: ${(props) => props.MaxWidth || "100%"};
  }
  @media (min-width: 1920px) {
    max-width: ${(props) => props.MaxWidthX || "100%"};
  }
`;

export const Image_SociosWeb = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  background: ${(props) => props.back || ""};
  display: none;
  @media (min-width: 481px) {
    display: block;
  }
`;

export const Image_SociosMob = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  background: ${(props) => props.back || ""};
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`;

export const Image_Carrousel1 = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  background: ${(props) => props.back || ""};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-radius: ${(props) => props.bordes || ""};
  max-height: 375.98px;
  height: ${(props) => props.hmob || "375.98px"};
  max-height: ${(props) => props.mhmob || ""};
  @media (min-width: 768px) {
    width: 282px;
    /*height: 277.17px;*/
    max-height: 375.98px;
  }
`;
export const Share_Info = styled.div`
  padding: ${(props) => props.padding || "12px"};
`;

export const Image_50 = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-left: ${(props) => props.left || ""};
  background: ${(props) => props.back || ""};
`;
export const Image_50_Dif = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-left: ${(props) => props.left || "100px"};
  background: ${(props) => props.back || ""};
  @media (max-width: 768px) {
    width: ${(props) => props.width || "100%"};
    margin: 0;
    margin-left: ${(props) => props.left || "0 !important"};
  }
`;

export const ImageSections = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  float: ${(props) => props.float || ""};
  position: ${(props) => props.position || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  background: ${(props) => props.back || ""};
  @media (min-width: 481px) {
    height: ${(props) => props.height || "150px"};
  }
`;

export const ImageShare = styled(imageComponent)`
    max-width: 100%; 
    width: ${(props) => props.width || "100%"}
    border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
    border: 0px solid #000000;
`;

export const Home_DivFlexShare = styled.div`
  @media (min-width: 481px) {
    width: 50%;
    margin-bottom: auto;
    margin-top: auto;
  }
`;
export const Home_Div_Btn = styled.div`
  @media (min-width: 481px) {
    text-align: center;
  }
`;
export const Home_Div_Btn_Mob = styled.div`
  display: block;
  @media (min-width: 481px) {
    text-align: center;
    display: none;
  }
`;
export const Home_Text = styled.p`
  color: #110e20;
  font-size: ${(props) => props.size || "1.2em"};
  font-weight: ${(props) => props.weight || "400"};
  text-align: ${(props) => props.align || "left"};
  margin-bottom: ${(props) => props.bot || ""};
  line-height: ${(props) => props.lineMob || ""};
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "20px"};
    line-height: ${(props) => props.line || "32px"};
  }
`;
export const Home_Text_Ent = styled.p`
  color: #110e20;
  font-size: ${(props) => props.size || "1.2em"};
  font-weight: ${(props) => props.weight || "400"};
  text-align: ${(props) => props.align || "left"};
  margin-bottom: ${(props) => props.bot || ""};
  line-height: ${(props) => props.lineMob || ""};
  max-width: 70%;
  @media (min-width: 768px) {
    font-size: ${(props) => props.size || "20px"};
    line-height: ${(props) => props.line || "18px"};
    max-width: 70%;
  }
`;

export const Home_DivSection = styled.div`
  background: ${(props) => props.back || "#fff"};
  padding: 5%;
  @media (min-width: 481px) {
    padding: ${(props) => props.padd || "3% 10% 3% 10%"};
  }
  @media (min-width: 768px) {
    padding: ${(props) => props.padd || "3% 5% 3% 5%"};
  }
  @media (min-width: 1024px) {
    padding: ${(props) => props.padd || "3% 6% 3% 6%"};
  }
  @media (min-width: 1200px) {
    padding: ${(props) => props.padd || "0"};
    margin: auto;
    max-width: 1060px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (min-width: 1281px) {
    padding: ${(props) => props.padd || "0"};
    margin: auto;
    max-width: 1152px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const Home_DivSection2 = styled.div`
  background: ${(props) => props.back || "#fff"};
  padding: 5%;
  padding-bottom: 5%;
  @media (min-width: 481px) {
    padding: 3% 10% 3% 10%;
  }
  @media (min-width: 768px) {
    padding: ${(props) => props.padd || "3% 5% 3% 5%"};
  }
  @media (min-width: 1024px) {
    padding: ${(props) => props.padd || "3% 6% 3% 6%"};
  }
  @media (min-width: 1200px) {
    padding: ${(props) => props.padd || "0"};
    margin: auto;
    max-width: 1060px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (min-width: 1281px) {
    padding: ${(props) => props.padd || "0"};
    margin: auto;
    max-width: 1060px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const Home_hr = styled.hr`
  margin-top: ${(props) => props.marginTop || "1rem"};
  margin-bottom: ${(props) => props.marginBottom || "1rem"};
  border: ${(props) => props.border || "1px solid #F7F7F9"};
  width: ${(props) => props.width || "70%"};
  @media (max-width: 768px) {
    width: 100%;
    margin-left: ${(props) => props.mlM || "1rem"};
    margin-right: ${(props) => props.mrM || "1rem"};
  }
`;

export const Home_SectionGray = styled.div`
  background: ${(props) => props.back || "#f5f5f5"};
`;

export const Home_ShareDiv = styled.div`
  -webkit-box-shadow: 0px 0px 5px 1px rgba(212, 212, 212, 1);
  -moz-box-shadow: 0px 0px 5px 1px rgba(212, 212, 212, 1);
  box-shadow: 0px 0px 5px 1px rgba(212, 212, 212, 1);
  border-radius: 4px;
  height: 405px;
  padding: 10px;
  text-align: center;
  margin: auto;
  width: 280px;
  @media (min-width: 481px) {
    width: 30%;
    height: auto;
    margin-bottom: 5px;
  }
`;
export const Home_ShareIcons = styled.div`
  display: flex;
  justify-content: space-around;
  @media (min-width: 481px) {
    display: none;
  }
`;
export const Home_ShareIcons_Web = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`;
export const Papers_Contain = styled.div`
  display: flex;
  overflow-y: scroll;
  margin-left: -20px;
  margin-right: -20px;
  @media (min-width: 480px) {
    display: inline-flex;
    overflow: initial;
    margin-left: 0;
    margin-right: 0;
  }
`;
export const Papers_Contain_Feed = styled.div`
  display: flex;
  overflow-y: scroll;
  margin-left: -20px;
  margin-right: -20px;
  padding: 8px;
  @media (min-width: 768px) {
    display: flex;
    overflow-y: hidden;
    margin: auto;
    padding: 8px;
  }
`;
export const Papers_Contain_History = styled.div`
  display: flex;
  overflow-y: scroll;
  @media (min-width: 480px) {
    overflow: initial;
    justify-content: center;
  }
`;

export const Content_Carousel = styled.div`
  margin-top: 20px;
`;
export const Carousel = styled.div`
  width: ${(props) => props.widthM || "300px"};
  margin: auto;
  @media (min-width: 480px) {
    width: ${(props) => props.widthW || "100%"};
    text-align: center;
  }
`;
export const Paper_Div1 = styled.div`
  @media (min-width: 480px) {
    width: 100%;
    margin: auto;
  }
`;
export const Paper_Div2 = styled.div`
  @media (min-width: 480px) {
    width: 90%;
    margin: auto;
    padding-top: 15px;
  }
`;
export const Paper_SecondDiv = styled.div`
  padding: 12px;
`;
export const Item_Paper = styled.div`
  border: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  border-radius: 4px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  @media (min-width: 480px) {
    margin-top: 10px;
  }
`;
export const Item_Paper_Ent = styled.div`
  border: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  margin-right: 10px;
  height: ${(props) => props.hei || "auto"};
  @media (min-width: 768px) {
    width: ${(props) => props.wid || "305px"};
  }
`;
export const Item_Paper_Fedd = styled.div`
  border: none;
  border-color: #e5e5e5;
  border-width: 1px;
  border-radius: 16px;
  margin-right: 10px;
  height: ${(props) => props.hei || "auto"};
  width: ${(props) => props.widMobile || "157.67px"};
  max-height: ${(props) => props.mheimob || "417.15px"};
  @media (min-width: 768px) {
    width: ${(props) => props.wid || "282px"};
    max-height: ${(props) => props.mhei || "417.15px"}; //417.15px
  }
`;

export const Btn_Share = styled.div`
  text-align: end;
  width: 100%;
  margin-top: ${(props) => props.top || "0"};
  margin-left: ${(props) => props.left || ""};
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: ${(props) => props.leftw || ""};
  }
  @media (min-width: 1700px) {
    margin-left: ${(props) => props.leftrG || ""};
  }
`;

export const Btn_Prev = styled.button`
  height: 28px;
  margin: auto;
  margin-right: -40px;
  z-index: 2;
`;

export const ContEntrenamiento = styled.div`
  position: relative;
  width: ${(props) => props.widthM || "335px"};
  height: ${(props) => props.height || "177px"};
  margin: auto;
  margin-top: ${(props) => props.margintop || "17px"};
  background: ${(props) => props.background || " "};
  border: ${(props) => props.border || " "};
  box-sizing: ${(props) => props.box || " "};
  border-radius: ${(props) => props.radius || " "};
  padding: ${(props) => props.padding || "4%"};
  border-radius: 5px;
  cursor: ${(props) => props.cursor || " "};
  @media (min-width: 768px) {
    margin-left: ${(props) => props.marginleft || "0px"};
    width: ${(props) => props.widthD || "335px"};
    float: ${(props) => props.floatD || ""};
  }
  @media (max-width: 768px) {
    padding: ${(props) => props.paddingM || "10px 15px 22px 12px"};
  }
`;

export const TitleEntrenamiento = styled.p`
  font-family: Cabin;
  font-style: normal;
  font-weight: ${(props) => props.weight || "bold"};
  font-size: ${(props) => props.size || "20px"};
  line-height: 120%;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#07210A"};
  margin: ${(props) => props.margin || " "};
  padding-top: ${(props) => props.top || "20px"};
  padding-bottom: ${(props) => props.bottom || " "};
  margin-left: ${(props) => props.left || "22px"};
  width: ${(props) => props.width || ""};
  text-align: ${(props) => props.aling || " "};
  @media (min-width: 768px) {
    width: ${(props) => props.widthD || props.width};
  }
  @media (max-width: 767px) {
    margin-top: ${(props) => props.margintopM || " "};
    font-size: ${(props) => props.sizem || props.size || "20px"};
    padding-left: ${(props) => props.pl || " "};
    padding-right: ${(props) => props.pr || " "};
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  width: 335px;
  height: 6px;
  margin-left: 0px;
  margin-top: 15%;
`;

export const TextTime = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: -0.2px;
  color: #07210a;
  margin-left: ${(props) => props.left || "30px"};
  margin-top: ${(props) => props.top || "-19px"};
  margin-buttom: ${(props) => props.buttom || " "};
  @media (min-width: 700px) {
    margin-top: ${(props) => props.topD || props.top || "-19px"};
    margin-left: ${(props) => props.leftD || props.left || "30px"};
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
  @media (max-width: 360px) {
    font-size: 12px;
  }
  @media (max-width: 700px) {
    margin-left: ${(props) => props.leftD || props.leftM || "30px"};
  }
`;

export const Bar = styled.div`
  position: absolute;
  left: -5.97%;
  right: -5.97%;
  /*top: 96.61%;*/
  bottom: 0%;

  /* Color 2/2 */

  background: #07210a;
  opacity: 0.24;
`;

export const Progress = styled.div`
  position: absolute;
  /* left: -5.97%; */
  /* right: -5.97%; */
  /* top: 96.61%; */
  bottom: 0%;
  background: #07210a;
  opacity: 0.24;
  width: 89%;
  height: 6px;
  margin-left: 20px;
`;

export const SubtitlesHistory = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: -0.3px;
  color: #4f4f4f;
  text-align: ${(props) => props.aling || " "};
`;

export const ContentTimer = styled.div`
  position: ${(props) => props.position || " "};
  margin-top: ${(props) => props.top || "0"};
  height: ${(props) => props.height || " "};
  max-height: ${(props) => props.maxheight || " "};
  display: ${(props) => props.display || ""};
  width: ${(props) => props.width || ""};
  @media (min-width: 2000px) {
    margin-top: ${(props) => props.marginTopRG || "-0.5vw"};
  }
  @media (max-width: 768px) {
    max-height: ${(props) => props.maxheightMobile || " "};
    height: ${(props) => props.heightMobile || " "};
  }
  @media (max-width: 320px) {
    top: ${(props) => props.top || "20%"};
  }
`;

export const ContEntrenamientos = styled.div`
  flex: ${(props) => props.flex || "0 0 50%"};
  max-width: 100%;
  padding: ${(props) => props.paddingw || "0px 5px"};
  margin-left: ${(props) => props.marginL || "0%"};
  text-align: ${(props) => props.ta || "left"};
  padding-top: ${(props) => props.pt || "0%"};
  background-color: ${(props) => props.background || ""};
  @media (max-width: 768px) {
    flex: ${(props) => props.flexMob || "0 0 90%"};
    max-width: ${(props) => props.maxwidthMob || "90%"};
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }
`;

export const Container = styled.div`
  width: ${(props) => props.width || ""};
  height: ${(props) => props.height || ""};
  margin: ${(props) => props.float || "auto"};
  margin-top: ${(props) => props.mt || "auto"};
  margin-left: ${(props) => props.mleft || ""};
  margin-right: ${(props) => props.mright || ""};
  margin-bottom: ${(props) => props.mb || "auto"};
  background-color: ${(props) => props.background || ""};
  display: flex;
  display: ${(props) => props.displayweb || ""};
  border-bottom: ${(props) => props.bb || ""};
  flex-wrap: ${(props) => props.fw || "wrap"};
  @media (max-width: 768px) {
    width: ${(props) => props.widthMobile || "90%"};
    height: ${(props) => props.heightMobile || ""};
    display: ${(props) => props.displaymobile || ""};
    margin-top: ${(props) => props.mtMobile || "0"};
    margin: ${(props) => props.mMobile || ""};
    margin-left: ${(props) => props.mleftm || ""};
    margin-right: ${(props) => props.mrightmb || ""};
  }
  @media (max-width: 700px) {
    width: ${(props) => props.widthMobilexs || "98%"};
    display: ${(props) => props.displaymobilexs || ""};
    margin-top: ${(props) => props.mtMobilexs || "0"};
    margin: ${(props) => props.mMobilexs || ""};
    margin-left: ${(props) => props.mleftmxs || ""};
    margin-right: ${(props) => props.mrightmbxs || ""};
  }
`;

export const ContainerV2 = styled.div`
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.float || "auto"};
  margin-top: ${(props) => props.mt || "auto"};
  margin-left: ${(props) => props.mleft || ""};
  margin-right: ${(props) => props.mright || ""};
  margin-bottom: ${(props) => props.mb || "auto"};
  display: flex;
  display: ${(props) => props.displayweb || ""};
  border-bottom: ${(props) => props.bb || ""};
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: ${(props) => props.widthMobile || "100%"};
    display: ${(props) => props.displaymobile || ""};
    margin-top: ${(props) => props.mtMobile || "0"};
    margin: ${(props) => props.mMobile || ""};
    margin-left: ${(props) => props.mleftm || ""};
  }
`;

export const UlStyle = styled.ul`
  list-style: none;
  width: 476px;
  @media (max-width: 769px) {
    width: 95%;
  }
`;

export const ListStyle = styled.li`
  color: #252525;
  font-size: 16px;
  margin: 10px 0;
  ::before {
    content: "•";
    color: #91d770;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const ModalBackground = styled.div`
  display: ${(props) => props.display || "flex"};
  position: fixed;
  width: ${(props) => props.width || "100%"};
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) => props.bcolor || "rgba(196, 196, 196, .55)"};
  z-index: 100;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.bradius || ""};
  padding: ${(props) => props.padding || "0"};
  margin-left: ${(props) => props.marginLeft || "0"};
  padding-top: ${(props) => props.paddingTop || "0"};
  overflow-y: ${(props) => props.overflowy || "none"};
  @media (max-width: 769px) {
    background-color: ${(props) => props.bcolorm || "rgba(196, 196, 196, .55)"};
  }
  @media (min-width: 769px) {
    width: ${(props) => props.widthM || ""};
  }
`;

export const ModalCard = styled.div`
  background: #fff;
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.MaxWidth || "335px"};
  padding: ${(props) => props.padding || "20px"};
  height: ${(props) => props.height || "100%"};
  max-height: ${(props) => props.maxheight || "668px"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: ${(props) => props.overflowy || "none"};
`;

export const ModalHeader = styled.div`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || ""};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justCont || "center"};
  align-items: ${(props) => props.alignIt || "center"};
  margin-top: ${(props) => props.mtop || ""};
  @media (min-width: 769px) {
    height: ${(props) => props.heightM || "35%"};
  }
  @media (max-width: 769px) {
    margin-top: ${(props) => props.mtopM || ""};
  }
`;

export const ModalBody = styled.div`
  font-size: 16px;
  color: #000;
  margin-top: ${(props) => props.mtop || ""};
  margin-bottom: ${(props) => props.mbottom || ""};
  overflow-y: ${(props) => props.overflowy || "none"};
`;

export const ModalShareContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

export const ModalBackCC = styled.div`
  background: ${(props) => props.bcolor || "#fff"};
  border-radius: ${(props) => props.bradius || ""};
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.MaxWidth || "335px"};
  padding: ${(props) => props.padding || "20px"};
  height: ${(props) => props.height || "90%"};
  max-height: ${(props) => props.maxheight || "688px"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${(props) => props.margin || "2% auto"};
  @media (min-width: 769px) {
    padding: ${(props) => props.paddingM || "20px"};
    max-width: ${(props) => props.mwidthM || "335px"};
  }

  @media (max-width: 768px) {
    height: ${(props) => props.height768 || " "};
  }

  @media (max-width: 440px) {
    padding: ${(props) => props.paddingM || ""};
    height: ${(props) => props.height440 || " "};
  }

  @media (max-width: 376px) {
    padding: ${(props) => props.paddingM || " "};
    height: ${(props) => props.height375 || " "};
  }

  @media (max-width: 360px) {
    padding: ${(props) => props.paddingM || " "};
    height: ${(props) => props.height360 || " "};
  }

  @media (max-width: 320px) {
    padding: ${(props) => props.paddingM || " "};
    height: ${(props) => props.height320 || " "};
  }

  @media (min-width: 1400px) {
    height: ${(props) => props.height1400 || " "};
  }
`;

export const ModalBackFT = styled.div`
  background: #fff;
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.MaxWidth || "335px"};
  padding: ${(props) => props.padding || "20px"};
  height: ${(props) => props.height || "90%"};
  max-height: ${(props) => props.maxheight || "668px"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${(props) => props.margin || "2% auto"};

  @media (max-width: 769px) {
    margin: ${(props) => props.marginMob || "35% auto"};
  }
  @media (min-width: 769px) {
    padding: ${(props) => props.paddingM || "20px"};
  }

  @media (max-width: 440px) {
    padding: ${(props) => props.paddingM || ""};
    height: ${(props) => props.height440 || " "};
  }

  @media (max-width: 375px) {
    padding: ${(props) => props.paddingM || " "};
    height: ${(props) => props.height375 || " "};
  }

  @media (max-width: 360px) {
    padding: ${(props) => props.paddingM || " "};
    height: ${(props) => props.height360 || " "};
  }

  @media (max-width: 320px) {
    padding: ${(props) => props.paddingM || " "};
    height: ${(props) => props.height320 || " "};
  }

  @media (min-width: 1400px) {
    height: ${(props) => props.height1400 || " "};
  }
`;

export const ModalBox = styled.div`



@media (min-width: 320px) {
        margin-top: ${(props) => props.mtop320 || ""};
       margin-right: ${(props) => props.mleR320 || ""};
    }

@media (min-width: 360px) {
        margin-top: ${(props) => props.mtop360 || ""};
       margin-left: ${(props) => props.mleft360 || ""};
    }

@media (min-width: 375px) {
        margin-top: ${(props) => props.mtop375 || ""};
       margin-left: ${(props) => props.mleft375 || ""};
    }

 @media (min-width: 414px) {
        margin-top: ${(props) => props.mtop440 || ""};
       margin-left: ${(props) => props.mleft440 || ""};
    }



  /* @media (max-width: 769px) {
        margin: ${(props) => props.marginMob || "35% auto"};
}

 @media (min-width: 769px) {
        padding: ${(props) => props.paddingM || "20px"};
    } */

   

    
 
     @media (min-width: 1024px) {
    margin-top: ${(props) => props.mtop || "350px"};
    margin-left: ${(props) => props.mleft || "350px"}; *
    } 
    
    @media (min-width: 1400px) {
    margin-top: ${(props) => props.mtop || "250px"};
    margin-left: ${(props) => props.mleft || "650px"}; *
    } 
    
    
`;
