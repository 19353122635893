import React, { useState, useEffect } from 'react';
import {
  ModalBackground,
  ModalBody,
  ModalCard,
} from '../../assets/styles/Home.styled';
import {
  Rectangle24,
  Vector2,
  Vector3,
  Triangu,
} from './Home.styled';

const ModalCompartir = ({ show, children, setShow }) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalBackground display={!showModal && 'none'}>
      <ModalCard maxheight="430px" MaxWidth="345px">
        <Triangu margintop="-58px" widthborder="25px solid transparent" />
        <Vector2
          width="45px"
          left="0"
          height="50px"
          widthM="40px"
          heightM="45px"
          background="#E3E660"
          radius="40%"
          transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
          positionD="absolute"
          Pleft="-6%"
          Ptop="253px"
          PleftM="1%"
          PtopM="55%"
          MlefM="-6%"
          displayM="block"
          id="ovalo"
        />
        <Vector3
          position="absolute"
          left=" "
          top=" "
          PtopM="25%"
          PleftM="-10%"
          Ptop="140px"
          Pleft="-8%"
          background=" #6BD5AF;"
          transform="rotate(-90deg)"
          width="50px"
          height="50px"
          widthM="45px"
          heightM="45px"
          displayD=" "
          displayM="block"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="370px"
          mLeftM="28%"
          PLeftM="0%"
          mTopM="12%"
          Pleft="2%"
          background="#BFDD6A"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="30.94px"
          height="30.94px"
          widthM="35px"
          heightM="35px"
          transform="rotate(46.41deg)"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="24px"
          mLeftM="48%"
          PLeftM="20%"
          mTopM="2%"
          Pleft="72%"
          background="#6BD5AF"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="30.94px"
          height="30.94px"
          widthM="25px"
          heightM="25px"
          transform="rotate(46.41deg)"
        />
        <Vector3
          position="absolute"
          left=" "
          top=" "
          Ptop="112px"
          Pleft="90%"
          PtopM="100px"
          PleftM="91%"
          background="#BFDD6A"
          transform="rotate(-75deg)"
          width="55px"
          height="55px"
          widthM="42px"
          heightM="42px"
          displayD=" "
          displayM="block"
        />
        <Vector3
          position="absolute"
          left=" "
          top=" "
          Ptop="222px"
          Pleft="94%"
          PtopM="230px"
          PleftM="93%"
          background="#E3E660"
          transform="rotate(75deg)"
          width="55px"
          height="55px"
          widthM="42px"
          heightM="42px"
          displayD=" "
          displayM="block"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="340px"
          mLeftM="0"
          PLeftM="90%"
          mTopM="10%"
          Pleft="93%"
          background="#91D770"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="50px"
          height="50px"
          widthM="30px"
          heightM="30px"
          transform="rotate(46.41deg)"
        />
        <i
          style={{
            position: 'absolute',
            right: '10px',
            top: '0px',
            fontSize: '30px',
            cursor: 'pointer',
          }}
          onClick={() => setShow(false)}
        >
          ×
        </i>
        <ModalBody mtop="30px">{children}</ModalBody>
      </ModalCard>
    </ModalBackground>
  );
};

export default ModalCompartir;
