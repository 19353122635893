import styled from 'styled-components';



export const HeaderKanda = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    margin-top: 25px;   
    margin-bottom: 8px;   
`;

export const MessagesContainer = styled.div`
    display: flex;
    flex-direction: row;   
    justify-content: center;
    margin: 0px 15px;
    background: transparent;
        @media (max-width: 375px) {
        max-width: 245px;
        margin-left: 25px;
    }
    @media (max-width: 700px) {
        max-width: 85%;
        margin-left: 25px;
    }
`;

export const PicKandaBot = styled.div`
    width: 54px;
    height: 53px;
       @media ( max-width: 375px) {
        width: 50px;
        height: 50px;
    

    }
    /* @media (max-width: 320px) {
        width: 50px;
        height: 50px;

    } */
`;

export const Messages = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 456px;
    margin-left: 15px;
       @media (max-width: 375px) {
        max-width: 245px;

    }
    /* @media (max-width: 320px) {
        max-width: 245px;
        margin-left: 10px;

    } */
    @media (max-width: 700px) {
        max-width: 85%;
    }
`;

export const Message = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding:${(props) => props.p|| '12px 12px 12px 16px'}; 

position: static;
width:  ${(props) => props.w || '456px;'};
height: ${(props) => props.h|| '105px'};

left: 0px;
top: 0px;
 

background: rgba(255, 255, 255, 0.69);

border-radius: 0px 15px 15px 15px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 15px 0px;



@media (max-width: 375px) {
 width: 220px;
}
@media (max-width: 700px) {
    width: 100%;
   height: ${(props) => props.hm|| ' '};
}




/* Inside auto layout */


/* order: 1; */


`;

export const Text = styled.span`
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: ${props => (props.button? 'bold' : "normalnpm stat")};
    font-size: ${props => props.button? "16px" : "14px"};
    line-height: 120%;
    letter-spacing: -0.3px;
    color: ${props => props.button? "#FFFFFF" : "#252525"};
    margin: ${props => props.header? "10px 0px" : props.list? "4px 0px" : "8px 0px"};
        @media (max-width: 375px) {
    font-size:11px;
  }
`;

export const TextList = styled.span`
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #AC2E5B;
    margin-right: 12px;
`;

export const Button = styled.button`
    background-color: #F64282;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    padding: 13px 20px;    
`;