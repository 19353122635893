import React from 'react';
import './styles/index.css';
import Message from './Message';
import Cerrar from '../../../assets/img/anacoach/cerrar.svg';

const index = ({ estado, setEstado }) => {
  const close = () => {
    setEstado(false);
  };
  return (
    <div className={`helper-modal ${!estado ? 'hidden' : ''}`}>
      <div className="helper-modal-container">
        <div className="helper-modal-header">
          <h3>Ayuda Coru</h3>
          <button className="helper-modal-btn-cerrar" onClick={(e) => close()}>
            <img src={Cerrar} alt="Cerrar" />
          </button>
        </div>
        <div className="helper-modal-message-container">
          <Message />
        </div>
        <form className="helper-modal-message-writter">
          <div className="helper-modal-writter">
            <input
              type="text"
              id="helper-modal-message"
              className="helper-modal-message"
            />
            <button type="submit" className="helper-modal-submit">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default index;
