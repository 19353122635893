import React from 'react';
// import { makeStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordion from '@material-ui/core/Accordion';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import {
  ContentGeneric,
  QuestionT,
  HeadingTwo,
  Description,
  ResText,
  ContainerList,
  ContainerListOl,
  Ancor,

} from '../Assets/styles/StyledGeneric.styled';

const Accordion = withStyles({
  root: {
    background: 'transparent',
    margin: '20px 0px',
    // border :"16px",
    borderRadius: '16px',
    borderTop: 'none',
    '@media (max-width: 700px)': {
      width: '90%',
      margin: '10px auto',
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
      paddingLeft: '0px',
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 28px',
    width: '712px',
    height: '30px',
    margin: '0px 0px',
    background: '#FFFFFF',

    // boxSizing: "border-box",
    // boxShadow: "0px 1px 4px rgba(30, 7, 15, 0.12)",
    borderRadius: '16px',
    borderBottom: ' 0px solid transparent',
    '&$expanded': {
      minHeight: 56,
    },
    '@media (max-width: 700px)': {
      width: '100%',
    },
  },
  content: {
    '&$expanded': {
      margin: '1px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

// eslint-disable-next-line no-unused-vars
const AccordionDetails = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',

    width: '712px',
    height: 'auto',
    margin: '0px 0px',
    background: 'transparent',
    border: 'none',
    paddingRight: '0px',
    '@media (max-width: 700px)': {
      width: '90%',
      margin: 'auto',
    },
    '&.MuiAccordionDetails-root': {
      boxShadow: 'none',
      padding: '10px 10px',
    },
  },
}))(MuiAccordionDetails);

const DataBase = [
  {
    id: 1,
    question: '¿Qué es y qué hace Coru?',
    textStrong:'Es una plataforma de coaching financiero sin costo,',
    text1: ' para ayudarte y acompañarte a entender y manejar mejor tu dinero y liberarte del estrés financiero.  ',
    disB:'block',
  },
  {
    id: 2,
    question: '¿Cómo lo hace?',
    text: ' A través de planes, cursos y entrenamientos en línea para que tomes mejores decisiones con tu dinero y puedas obtener los mejores productos financieros del mercado para resolver tus problemas económicos. ',
  },
  {
    id: 3,
    question: '¿Qué es el estrés financiero?',
    text: ' Es un problema que afecta a los que tienen dinero y a los que no. De acuerdo a nuestro estudio, el 87% de la gente en México vive con estrés financiero, lo que genera efectos negativos en su salud física y mental.',
  },
  {
    id: 4,
    question: '¿Para quién es? ',
    text: ' Coru es para todas las personas que quieran tener tranquilidad financiera en cualquier etapa de su vida. Nuestra plataforma está optimizada para personas entre los 18 y 55 años de edad. ',
  },
  {
    id: 5,
    question:
      '¿Coru tiene productos financieros propios como créditos o seguros?',
    text: ' No, recuerda que en Coru no otorgamos créditos o productos financieros, pero te podemos conectar con quien sí lo hace. Nuestro propósito es ayudarte a entender cómo funcionan los productos financieros en el mercado actual. ',
  },
  {
    id: 6,
    question: '¿Qué productos ofrece Coru?',
    disOl: 'block',
    textB1: 'Tu Plan Financiero,',
    listOl1: 'plan personalizado para mejorar tus finanzas.',
    textB2: 'Soluciones rápidas,',
    listOl2: 'entrenamientos para resolver problemas pequeños y grandes.',
    textB3: 'Coru Master Class,',
    listOl3: 'clases sobre el mundo de las finanzas.',
    textB4: 'XCoach,',
    listOl4: 'asesoría de expertos financieros.',
  },
  {
    id: 7,
    question: '¿Cómo tengo acceso?',
    text: ' Visita coru.com o descarga la app, crea tu cuenta y recibe coaching financiero de la mano de nuestros expertos.',
  },
  {
    id: 8,
    question: '¿Tiene algún costo este servicio?',
    text: 'No tiene ningún costo para nuestros usuarios. Estamos convencidos de que el coaching financiero que brindamos genera mejores ciudadanos financieros, quienes no solo viven con menos estrés sino que también se convierten en mejores clientes. Por ello, son las empresas quienes pagan.  ',
  },
  {
    id: 9,
    question: '¿Es seguro proporcionar mis datos?',
    disL: 'block',
    text9:
      'Nos preocupamos por ti y tus datos confidenciales, te invitamos a revisar el Aviso de Privacidad en nuestro sitio de internet:',
    ancor: 'https://coru.com/privacy-notice',
    href: 'https://coru.com/privacy-notice',
  },

  {
    id: 10,
    question: '¿Cuáles son los canales digitales oficiales de Coru?',
    dis: 'block',
    list1: 'Página web:',
    list2: 'Facebook: @Coru',
    list3: 'Instagram: @coru_mx ',
    list4: 'Linkedin: @Coru',
    list5: 'Twitter: @Coru_mx ',
    ancor1: 'https://coru.com/',
    href1: 'https://coru.com/ ',
    ancor2: 'https://www.facebook.com/Coru/ ',
    href2: 'https://www.facebook.com/Coru/',
    ancor3: ' https://www.instagram.com/coru_mx/',
    href3: 'https://www.instagram.com/coru_mx/',
    ancor4: 'https://www.linkedin.com/company/coru-com',
    href4: 'https://www.linkedin.com/company/coru-com',
    ancor5: 'https://twitter.com/Coru_mx',
    href5: 'https://twitter.com/Coru_mx',
    // paneldHeader:"panel1d-header",
    // panelaContent:"panel1a-content",
  },
];

// eslint-disable-next-line react/prop-types
const SectionQuestions = ({ tituloSecundario }) => {
  const [expanded, setExpanded] = React.useState(' ');

  const handleChange = (id) => (event, newExpanded) => {
    setExpanded(newExpanded ? id : false);
  };

  return (
    <ContentGeneric id="PreguntasFrecuentes" className="widthmobile">

      <HeadingTwo
           h700="auto"
          ml700="5%"
          hm="60px"
          h375="20px"
          w375="283px"
          className="bold"
          m="0px 12px 0px 0px"
         color="#AC2E5B">
        {tituloSecundario}
      </HeadingTwo>

      <Description
        h375="84px"
        w375="320px"

        mD700="6px 5.5% 14px "
        m="12px 0px 10px"
        fz="18px"
        className="roboto"
        color="#AC2E5B"
        p700="12px"

        >
        Sé parte de las +2.5 millones de personas que están mejorando su vida
        financiera.
      </Description>

      {DataBase.map((data, idx) => (
        <Accordion
          key={idx}
          square={false}
          expanded={expanded === data.id}
          onChange={handleChange(data.id)}
          style={{ boxShadow: 0 }}>
          <AccordionSummary
            //  aria-controls="panel1d-content"
            //  id={data.paneldHeader}
            expandIcon={<ExpandMoreIcon />}
            //  aria-controls={data.panelaContent}
          >
            <QuestionT className="bold">{data.question}</QuestionT>
          </AccordionSummary>

          <AccordionDetails>
            <ResText className="roboto">
            {data.text}
            <ContainerList dis={data.disB} lstyle="none">
            <b>{data.textStrong}</b>   {data.text1}
              </ContainerList>
               
               <ContainerList dis={data.disL} lstyle="none">
                <li> {data.text9} <Ancor target="_blanck" href={data.href}>{data.ancor}</Ancor></li>
              </ContainerList>

              <ContainerList dis={data.dis}  >
                <li>  {data.list1}  <Ancor href={data.href1}>{data.ancor1} </Ancor></li>
                <li>  {data.list2}  <Ancor href={data.href2}>{data.ancor2} </Ancor></li>
                <li>  {data.list3}  <Ancor href={data.href3}>{data.ancor3} </Ancor></li>
                <li>  {data.list4}  <Ancor href={data.href4}>{data.ancor4} </Ancor></li>
                <li>  {data.list5}  <Ancor href={data.href5}>{data.ancor5} </Ancor ></li>
              </ContainerList>
              <ContainerListOl disOl={data.disOl}>
                <li><b>{data.textB1}</b>  {data.listOl1}  </li>
                <li><b>{data.textB2}</b>  {data.listOl2}  </li>
                <li><b>{data.textB3}</b>  {data.listOl3}  </li>
                <li><b>{data.textB4}</b>  {data.listOl4}  </li>
              </ContainerListOl>
            </ResText>

        </AccordionDetails>
        </Accordion>
      ))}
    </ContentGeneric>
  );
};

export default SectionQuestions;
