import React, { Fragment, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import {
  Main,
  Container,
  ParagrapTwo,
} from '../../components/common/common.styled';
import FooterFeed from '../../components/Feed/Footer_Feed';
import '../../assets/styles/perfil/perfiles.css';
import Header_Logros from '../../components/Perfiles/Header_Perfil';
import { DYNAMIC_GET } from '../../mutations';
import TestViralidad from '../../components/Logros/TestViralidad';
import BarFilterProduct from './SavedProductsBar';
import ProductsLogros from './ProductsLogros';
import {
  TrainingSectionFlex,
  DivCardsCC,
} from '../../assets/styles/Training.styled';

function Training_progress({
  Novisible,
  paddingInicio,
  isViernes,
  cardsByProvider,
}) {
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  let Training;
  let userDetail;

  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach((element) => {
          switch (element.IDType) {
            case '3':
              sessionStorage.setItem('level', element.Value);
              break;
            default:
              break;
          }
        });
      }
    },
  });
  const ArrayP = [];
  const ArrrayS = [];

  const ConstruccionArray = (items2) => {
    // setentrenamientos(items2.length);
    const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
    for (let i = 0; i < items2.length; i += LONGITUD_PEDAZOS) {
      const pedazo = items2.slice(i, i + LONGITUD_PEDAZOS);
      ArrayP.push(pedazo);
    }
  };
  const [getAllTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        Training = JSON.parse(dynamicGet.response);
        for (let i = 0; i < Training.length; i++) {
          if (Training[i].Achievment < 100) {
            ArrrayS.push(Training[i]);
          }
        }
        if (ArrrayS.length === 0) {
          return;
        }
        ConstruccionArray(ArrrayS);
      }
    },
  });

  const getAllTrainingFunction = () => {
    const getAllTrainingInput = {
      input: {
        table: 'CatRelUsersTraining, DatTraining, CatTraining',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
          {
            logic: 'AND',
            valone: 'CatRelUsersTraining.IDDatTraining',
            condition: '=',
            valtwo: 'DatTraining.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDCatTraining',
            condition: '=',
            valtwo: 'CatTraining_IDTraining',
            validColumn: true,
          },
        ],
      },
    };
    getAllTraining({ variables: getAllTrainingInput });
  };
  // Visual effects
  const scrollToTop = () => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const dynamicGetInput = {
      input: {
        table: 'CatUserDetails',
        columns: ['*'],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
        ],
      },
    };

    getUserDetail({ variables: dynamicGetInput });
    getAllTrainingFunction();
    scrollToTop();
  }, []);

  return (
    <Fragment>
      {!Novisible ? (
        <Header_Logros name={sessionStorage.getItem('firstName')} />
      ) : (
        <div></div>
      )}
      <Main
        minheigth="auto"
        background="#fafafa"
        mt={isViernes ? ' ' : '10%'}
        mtWeb={isViernes ? ' ' : '10%'}
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
      >
        {isViernes ? (
          <Fragment>
            <TestViralidad />
            <br />
          </Fragment>
        ) : null}
        <Container
          displayweb="block"
          className="container"
          paddingMobile="0!important"
          width="94%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <ParagrapTwo
            mb="10%"
            fontSize="20px"
            fontSizeWeb="20px"
            mt="5%"
            mbweb="35px"
            textalignMobile="left"
            texttalign="left"
            className="mx-auto roboto"
            mtWeb="10%"
            fontWeight="bold"
          >
            Mis productos guardados:
          </ParagrapTwo>
          {cardsByProvider.map(({ training }) => (
            <BarFilterProduct
              key={1}
              text={training[0].NameTraining}
              background={training[0].TrainingColor}
            >
              <DivCardsCC>
                <TrainingSectionFlex
                  key={1}
                  className=""
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                  <ProductsLogros
                    cards={training}
                    background={training[0].TrainingColor.length > 0 ? training[0].TrainingColor : '#A370FF'}
                  ></ProductsLogros>
                </TrainingSectionFlex>
              </DivCardsCC>
            </BarFilterProduct>
          ))}
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="logros" /> : <div></div>}
    </Fragment>
  );
}
export default Training_progress;
