import React from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import {
  FooterLink,
  Footer_container,
  Footer_description,
  Footer_description_Home,
  Image,
  Footer_container_Home,
  Footer_Verticals,
  Footer_Aviso,
  Footer_Div1,
  Footer_Div2,
  Footer_Div3,
  FooterShareDiv,
  Footer_VerticalsA,
} from './common.styled';
import img from '../../assets/img/anacoach/footer.png';
import CoruLogo from '../../assets/img/home/CoruLogo.svg';
import { CREATE_CLICK } from '../../mutations';
import SectionShareFooter from './SectionShareFooter';
import ClickButton from '../../Helpers/HookHelpers';

const anio = '2021';

const FooterX = () => {
  const { actions } = ClickButton();
  const pathnameP = window.location.pathname;
  const pathname = pathnameP.split('/')[1];

  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted({ createClick }) {
        console.log(createClick);
        localStorage.setItem('activityDate', new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) {
      createClickFront({ variables: createClickInput });
    }
    TagManager.dataLayer(tagManagerArgs);
  };

  const history = useHistory();
  const handleClickAviso = () => {
    history.push('/privacy-notice');
    sendMongoAndGTM('ClickFooterPrivacy');
  };
  const handleClickConocenos = () => {
    history.push('/about-us');
    sendMongoAndGTM('ClickFooterUs');
  };
  const handleClickAdvisers = () => {
    history.push('/advisers');
    sendMongoAndGTM('ClickFooterAdvisers');
  };

  const handleClickBlog = () => {
    actions({ action: 'ClickButtonCoruHome', variables: 'FooterBlog', redirection: `https://coru.com/blog/`,
    href: true,});
    // history.push('/advisers');
  };

  const handleOpen = (url, click) => {
    history.push(url);
    sendMongoAndGTM(click);
  };

  return (
    <div className="text-center">
      <Footer_container_Home color="#fff" backcolor="#2C3337">
        <Footer_description_Home
          color="#fff"
          backcolor="#2C3337"
          paddingWeb="3%"
          paddingMobile="0px"
        >
          <Image
            src={CoruLogo}
            className="img-fluid"
            displayMobile="block"
            width="92.28px"
            marginTop="62px"
            bottom="30px"
            displayWeb="none"
            bottomMObile="block"
          />
          <Footer_Div3>
            <Image
              src={CoruLogo}
              className="img-fluid"
              displayMobile="none"
              bottomMObile="none"
              width="92.28px"
              marginTop="62px"
              bottom="30px"
            />
          </Footer_Div3>
          <Footer_Div3>
            <Footer_VerticalsA
              className="roboto"
              href={`https://coru.com/the-training-feed/?utm_source=footer&utm_medium=${pathname}`}
            >
              Entrenamiento
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              href={`https://coru.com/wo/card-funnel?utm_source=footer&utm_medium=${pathname}`}
            >
              Tarjeta de crédito
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              href={`https://coru.com/wo/fast-track?utm_source=footer&utm_medium=${pathname}`}
            >
              Préstamos a tu medida
            </Footer_VerticalsA>
          </Footer_Div3>
          <Footer_Div3>
            <Footer_Verticals className="roboto">
              Seguro de Auto
            </Footer_Verticals>
            <Footer_Verticals className="roboto" border="none">
              Coru
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Div3>
            <Footer_Verticals className="roboto" onClick={handleClickConocenos}>
              Conócenos
            </Footer_Verticals>
            <Footer_Verticals className="roboto">Contacto</Footer_Verticals>
            <Footer_Verticals className="roboto" onClick={handleClickBlog}>Blog</Footer_Verticals>
            <Footer_Verticals className="roboto" onClick={handleClickAdvisers}>
              Colaboradores Coru
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Div1 width="100%">
            <Footer_Verticals className="roboto">
              Entrenamiento
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => {
                handleOpen('/wo/card-funnel', 'ClickFooterCC');
              }}
            >
              Tarjeta de crédito
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => {
                handleOpen('/wo/fast-track', 'ClickFooterPL');
              }}
            >
              Préstamos a tu medida
            </Footer_Verticals>
          </Footer_Div1>
          <Footer_Div2 width="100%">
            <Footer_Verticals className="roboto">
              Seguro de Auto
            </Footer_Verticals>
            <Footer_Verticals className="roboto"></Footer_Verticals>
            <Footer_Verticals className="roboto" border="none">
              Coru
            </Footer_Verticals>
            <hr style={{ border: '1px solid rgba(255, 255, 255, 0.24)' }} />
            <Footer_Verticals className="roboto" onClick={handleClickConocenos}>
              Conócenos
            </Footer_Verticals>
            <Footer_Verticals className="roboto">Contacto</Footer_Verticals>
            <Footer_Verticals className="roboto" onClick={handleClickBlog}>Blog</Footer_Verticals>
            <Footer_Verticals className="roboto" onClick={handleClickAdvisers}>
              Colaboradores Coru
            </Footer_Verticals>
            <hr style={{ border: '1px solid rgba(255, 255, 255, 0.24)' }} />
          </Footer_Div2>
        </Footer_description_Home>
        <Footer_description_Home
          color="#fff"
          backcolor="#2C3337"
          paddingBWeb="3%"
          paddingMobile="0px"
        >
          <Footer_Div1 width="40%">
            <Footer_Verticals
              size="14px"
              line="160%"
              onClick={handleClickAviso}
              className="roboto"
              cursor="pointer"
            >
              Aviso de privacidad
            </Footer_Verticals>
          </Footer_Div1>
          <Footer_Div1 displaymobile="none" width="40%">
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              onClick={handleClickConocenos}
              className="roboto"
              cursor="pointer"
            >
              Conócenos
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              className="roboto"
            >
              Contacto
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              className="roboto"
              onClick={handleClickBlog}
            >
              Blog
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              onClick={handleClickAdvisers}
              className="roboto"
              cursor="pointer"
            >
              Colaboradores Coru
            </Footer_Aviso>
          </Footer_Div1>
        </Footer_description_Home>
        <Footer_description pb="2%" color="#fff" backcolor="#2C3337">
          <FooterShareDiv>
            <SectionShareFooter />
          </FooterShareDiv>
          <Footer_Div3 width="100%">
            <Footer_Verticals
              size="14px"
              line="160%"
              onClick={handleClickAviso}
              className="roboto"
              width="91%"
              left="auto"
              cursor="pointer"
            >
              Aviso de privacidad ©{anio} All Rights Reserved
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Verticals
            size="14px"
            line="160%"
            className="roboto"
            displayD="none"
            displayM="block"
          >
            ©{anio}
            <FooterLink
              href="/"
              label="Coru"
              color="#fff"
              fontSize="14px"
              fontSizeMobile="14px"
              className="roboto"
            />
            All Rights Reserved
          </Footer_Verticals>
        </Footer_description>
      </Footer_container_Home>
    </div>
  );
};

const FooterFixed = () => {
  const { actions } = ClickButton();
  const pathnameP = window.location.pathname;
  const pathname = pathnameP.split('/')[1];

  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted({ createClick }) {
        console.log(createClick);
        localStorage.setItem('activityDate', new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) {
      createClickFront({ variables: createClickInput });
    }
    TagManager.dataLayer(tagManagerArgs);
  };
  const history = useHistory();
  const handleClickAviso = () => {
    history.push('/privacy-notice');
    sendMongoAndGTM('ClickFooterPrivacy');
  };
  const handleClickConocenos = () => {
    history.push('/about-us');
    sendMongoAndGTM('ClickFooterUs');
  };
  const handleClickAdvisers = () => {
    history.push('/advisers');
    sendMongoAndGTM('ClickFooterAdvisers');
  };
  const handleOpen = (url, click) => {
    history.push(url);
    sendMongoAndGTM(click);
  };

  const handleClickBlog = () => {
    actions({ action: 'ClickButtonCoruHome', variables: 'FooterBlog', redirection: `https://coru.com/blog/`,
    href: true,});
    // history.push('/advisers');
  };

  return (
    <div className="text-center">
      <Footer_container_Home color="#fff" backcolor="#2C3337">
        <Footer_description_Home
          color="#fff"
          backcolor="#2C3337"
          paddingWeb="3%"
          paddingMobile="0px"
        >
          <Image
            src={CoruLogo}
            className="img-fluid"
            width="92.28px"
            marginTop="62px"
            bottom="30px"
            displayWeb="none"
            bottomMObile="block"
            displayMobile="block"
          />
          <Footer_Div3>
            <Image
              src={CoruLogo}
              className="img-fluid"
              displayMobile="none"
              bottomMObile="none"
              width="92.28px"
              marginTop="62px"
              bottom="30px"
            />
          </Footer_Div3>
          <Footer_Div3>
            <Footer_VerticalsA
              className="roboto"
              href={`https://coru.com/the-training-feed/?utm_source=footer&utm_medium=${pathname}`}
            >
              Entrenamiento
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              href={`https://coru.com/wo/card-funnel?utm_source=footer&utm_medium=${pathname}`}
            >
              Tarjeta de crédito
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              href={`https://coru.com/wo/fast-track?utm_source=footer&utm_medium=${pathname}`}
            >
              Préstamos a tu medida
            </Footer_VerticalsA>
          </Footer_Div3>
          <Footer_Div3>
            <Footer_Verticals className="roboto">
              Seguro de Auto
            </Footer_Verticals>
            <Footer_Verticals className="roboto"></Footer_Verticals>
            <Footer_Verticals className="roboto" border="none">
              Coru
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Div3>
            <Footer_Verticals className="roboto" onClick={handleClickConocenos}>
              Conócenos
            </Footer_Verticals>
            <Footer_Verticals className="roboto">Contacto</Footer_Verticals>
            <Footer_VerticalsA className="roboto"  href={`https://coru.com/blog/`}>Blog</Footer_VerticalsA>
            <Footer_Verticals className="roboto" onClick={handleClickAdvisers}>
              Colaboradores Coru
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Div1 width="100%">
            <Footer_Verticals className="roboto">
              Entrenamiento
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => {
                handleOpen('/wo/card-funnel', 'ClickFooterCC');
              }}
            >
              Tarjeta de crédito
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => {
                handleOpen('/wo/fast-track', 'ClickFooterPL');
              }}
            >
              Préstamos a tu medida
            </Footer_Verticals>
          </Footer_Div1>
          <Footer_Div2 width="100%">
            <Footer_Verticals className="roboto">
              Seguro de Auto
            </Footer_Verticals>
            <Footer_Verticals className="roboto"></Footer_Verticals>
            <Footer_Verticals className="roboto" border="none">
              Coru
            </Footer_Verticals>
            <hr style={{ border: '1px solid rgba(255, 255, 255, 0.24)' }} />
            <Footer_Verticals className="roboto" onClick={handleClickConocenos}>
              Conócenos
            </Footer_Verticals>
            <Footer_Verticals className="roboto">Contacto</Footer_Verticals>
            <Footer_VerticalsA className="roboto"  href={`https://coru.com/blog/`}>Blog</Footer_VerticalsA>
            <Footer_Verticals className="roboto" onClick={handleClickAdvisers}>
              Colaboradores Coru
            </Footer_Verticals>
            <hr style={{ border: '1px solid rgba(255, 255, 255, 0.24)' }} />
          </Footer_Div2>
        </Footer_description_Home>
        <Footer_description_Home
          color="#fff"
          backcolor="#2C3337"
          paddingBWeb="3%"
          paddingMobile="0px"
        >
          <Footer_Div1 width="40%">
            <Footer_Verticals
              size="14px"
              line="160%"
              onClick={handleClickAviso}
              className="roboto"
              cursor="pointer"
            >
              Aviso de privacidad
            </Footer_Verticals>
          </Footer_Div1>
          <Footer_Div1 displaymobile="none" width="40%">
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              onClick={handleClickConocenos}
              className="roboto"
              cursor="pointer"
            >
              Conócenos
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              className="roboto"
            >
              Contacto
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              className="roboto"
              onClick={handleClickBlog}
            >
              Blog
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              onClick={handleClickAdvisers}
              className="roboto"
              cursor="pointer"
            >
              Colaboradores Coru
            </Footer_Aviso>
          </Footer_Div1>
        </Footer_description_Home>
        <Footer_description pb="2%" color="#fff" backcolor="#2C3337">
          <FooterShareDiv>
            <SectionShareFooter />
          </FooterShareDiv>
          <Footer_Div3 width="100%">
            <Footer_Verticals
              size="14px"
              line="160%"
              onClick={handleClickAviso}
              className="roboto"
              width="91%"
              left="auto"
              cursor="pointer"
            >
              Aviso de privacidad ©{anio} All Rights Reserved
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Verticals
            size="14px"
            line="160%"
            className="roboto"
            displayD="none"
            displayM="block"
          >
            ©{anio}
            <FooterLink
              href="/"
              label="Coru"
              color="#fff"
              fontSize="14px"
              fontSizeMobile="14px"
              className="roboto"
            />
            All Rights Reserved
          </Footer_Verticals>
        </Footer_description>
      </Footer_container_Home>
    </div>
  );
};

const FooterHome = () => {
  const pathnameP = window.location.pathname;
  const pathname = pathnameP.split('/')[1];
  const { actions } = ClickButton();

  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted({ createClick }) {
        console.log(createClick);
        localStorage.setItem('activityDate', new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) {
      createClickFront({ variables: createClickInput });
    }
    TagManager.dataLayer(tagManagerArgs);
  };
  const history = useHistory();
  const handleClickAviso = () => {
    history.push('/privacy-notice');
    sendMongoAndGTM('ClickFooterPrivacy');
  };

  const handleClickConocenos = () => {
    actions({
      action: 'ClickButtonCoruHome',
      variables: 'FooterConócenos',
      redirection: '/about-us',
    });
    // history.push("/about-us");
    sendMongoAndGTM('ClickFooterUs');
  };
  const handleClickAdvisers = () => {
    actions({
      action: 'ClickButtonCoruHome',
      variables: 'FooterColaboradoresCoru',
      redirection: '/advisers',
    });
    // history.push("/advisers");
    sendMongoAndGTM('ClickFooterAdvisers');
  };
  const handleOpen = (url, click) => {
    history.push(url);
    sendMongoAndGTM(click);
  };
  const handleClickCoru = (name) => {
    switch (name) {
      case 'Entrenamiento':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/the-training-feed/?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'Tarjetadecrédito':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/wo/card-funnel?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'Préstamosatumedida':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/wo/fast-track?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'Contactopartners':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://docs.google.com/forms/d/e/1FAIpQLSfy4gXsbgZLMaIrouFWyVw0tFvEAbpNWflYvHYNvskUBnEO5Q/viewform?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'SegurodeAuto':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/wo/car-insurance?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'Contacto':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
        });
        break;
      case 'Blog':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/blog/`,
          href: true,
        });
        break;
      case 'Coru':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
        });
        break;
      default:
        break;
    }
    // history.push('/lp/categories-products');
  };

  return (
    <div className="text-center">
      <Footer_container_Home color="#fff" backcolor="#2C3337">
        <Footer_description_Home
          color="#fff"
          backcolor="#2C3337"
          paddingWeb="3%"
          paddingMobile="0px"
        >
          <Image
            src={CoruLogo}
            className="img-fluid"
            width="92.28px"
            marginTop="62px"
            bottom="30px"
            displayWeb="none"
            bottomMObile="block"
            displayMobile="block"
          />
          <Footer_Div3>
            <Image
              src={CoruLogo}
              className="img-fluid"
              displayMobile="none"
              bottomMObile="none"
              width="92.28px"
              marginTop="62px"
              bottom="30px"
            />
          </Footer_Div3>
          <Footer_Div3>
            <Footer_VerticalsA
              className="roboto"
              onClick={() => handleClickCoru('Entrenamiento')}
            >
              Entrenamiento
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              onClick={() => handleClickCoru('Tarjetadecrédito')}
            >
              Tarjeta de crédito
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              onClick={() => handleClickCoru('Préstamosatumedida')}
            >
              Préstamos a tu medida
            </Footer_VerticalsA>
            <Footer_VerticalsA
              className="roboto"
              onClick={() => handleClickCoru('Contactopartners')}
              target="_blank"
            >
              Contacto partners
            </Footer_VerticalsA>
          </Footer_Div3>
          <Footer_Div3>
            <Footer_VerticalsA
              className="roboto"
              onClick={() => handleClickCoru('SegurodeAuto')}
            >
              Seguro de Auto
            </Footer_VerticalsA>
            <Footer_Verticals className="roboto"></Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              border="none"
              onClick={() => handleClickCoru('Coru')}
            >
              Coru
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Div3>
            <Footer_Verticals className="roboto" onClick={handleClickConocenos}>
              Conócenos
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => handleClickCoru('Contacto')}
            >
              Contacto
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => handleClickCoru('Blog')}
            >
              Blog
            </Footer_Verticals>
            <Footer_Verticals className="roboto" onClick={handleClickAdvisers}>
              Colaboradores Coru
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Div1 width="100%">
            <Footer_Verticals className="roboto">
              Entrenamiento
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => {
                handleOpen('/wo/card-funnel', 'ClickFooterCC');
              }}
            >
              Tarjeta de crédito
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => {
                handleOpen('/wo/fast-track', 'ClickFooterPL');
              }}
            >
              Préstamos a tu medida
            </Footer_Verticals>
          </Footer_Div1>
          <Footer_Div2 width="100%">
            <Footer_Verticals className="roboto">
              Seguro de Auto
            </Footer_Verticals>
            <Footer_Verticals className="roboto"></Footer_Verticals>
            <Footer_VerticalsA
              className="roboto"
              href=" https://docs.google.com/forms/d/e/1FAIpQLSfy4gXsbgZLMaIrouFWyVw0tFvEAbpNWflYvHYNvskUBnEO5Q/viewform"
              target="_blank"
            >
              Contacto partners
            </Footer_VerticalsA>
            <Footer_Verticals className="roboto" border="none">
              Coru
            </Footer_Verticals>
            <hr style={{ border: '1px solid rgba(255, 255, 255, 0.24)' }} />
            <Footer_Verticals className="roboto" onClick={handleClickConocenos}>
              Conócenos
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => handleClickCoru('Contacto')}
            >
              Contacto
            </Footer_Verticals>
            <Footer_Verticals
              className="roboto"
              onClick={() => handleClickCoru('Blog')}
            >
              Blog
            </Footer_Verticals>
            <Footer_Verticals className="roboto" onClick={handleClickAdvisers}>
              Colaboradores Coru
            </Footer_Verticals>
            <hr style={{ border: '1px solid rgba(255, 255, 255, 0.24)' }} />
          </Footer_Div2>
        </Footer_description_Home>
        <Footer_description_Home
          color="#fff"
          backcolor="#2C3337"
          paddingBWeb="3%"
          paddingMobile="0px"
        >
          <Footer_Div1 width="40%">
            <Footer_Verticals
              size="14px"
              line="160%"
              onClick={handleClickAviso}
              className="roboto"
              cursor="pointer"
            >
              Aviso de privacidad
            </Footer_Verticals>
            {/* <Footer_Aviso>Términos y condiciones</Footer_Aviso> */}
          </Footer_Div1>
          <Footer_Div1 displaymobile="none" width="40%">
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              onClick={handleClickConocenos}
              className="roboto"
              cursor="pointer"
            >
              Conócenos
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              className="roboto"
            >
              Contacto
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              className="roboto"
              onClick={() => handleClickCoru('Blog')}
            >
              Blog
            </Footer_Aviso>
            <Footer_Aviso
              color="#387199"
              fw="700!important"
              size="1.2em"
              onClick={handleClickAdvisers}
              className="roboto"
              cursor="pointer"
            >
              Colaboradores Coru
            </Footer_Aviso>
          </Footer_Div1>
        </Footer_description_Home>
        <Footer_description pb="2%" color="#fff" backcolor="#2C3337">
          <FooterShareDiv>
            <SectionShareFooter />
          </FooterShareDiv>
          <Footer_Div3 width="100%">
            <Footer_Verticals
              size="14px"
              line="160%"
              onClick={handleClickAviso}
              className="roboto"
              width="91%"
              left="auto"
              cursor="pointer"
            >
              Aviso de privacidad ©{anio} All Rights Reserved
            </Footer_Verticals>
          </Footer_Div3>
          <Footer_Verticals
            size="14px"
            line="160%"
            className="roboto"
            displayD="none"
            displayM="block"
          >
            ©{anio}
            <FooterLink
              href="/"
              label="Coru"
              color="#fff"
              fontSize="14px"
              fontSizeMobile="14px"
              className="roboto"
            />
            All Rights Reserved
          </Footer_Verticals>
        </Footer_description>
      </Footer_container_Home>
    </div>
  );
};

const FooterAdmin = () => (
  <div className="text-center">
    <Footer_container backcolor="#F4F7FA">
      <Image src={img} className="img-fluid" bottom="-2px" />
    </Footer_container>
  </div>
);

const Footer = ({ pathname }) => {
  let component;
  if (pathname === '/' || pathname === '/about-us') {
    component = <FooterHome />;
  } else if (pathname === '/admin') {
    component = <FooterAdmin />;
  } else if (
    String(pathname).includes('/p/analytics/')
    || pathname === '/admin-home/'
    || pathname === '/admin-home'
    || pathname === '/admin-home/pixel/'
    || pathname === '/admin-home/shortlink/'
    || pathname === '/admin-home/config-otp/'
    || pathname === '/admin-home/trainings'
    || pathname === '/admin-home/imageUpload/'
    || pathname === '/admin-home/transactional'
    || pathname === '/admin-home/template/'
    || pathname === '/admin-home/campaign/'
    || pathname === '/admin-home/dashboardSnc/'
    || pathname === '/admin-home/blacklist/'
    || pathname === '/admin-home/overview/'
    || pathname === '/admin-home/opportunititesInfo/'
    || pathname === '/admin-home/viewUserInfoDetail/'
  ) {
    component = <div></div>;
  } else if (
    pathname === '/user-validation'
    || pathname === '/personal-Information'
  ) {
    component = <FooterX />;
  } else {
    component = <FooterFixed />;
  }

  return <div>{component}</div>;
};

export default Footer;
