import ObtenerTarjeta from "../../anaCoach/Training/GetCard";
import Motivos from "../../anaCoach/Training/Motivos";
import ComparaTarjeta from "../../anaCoach/Training/CompareCard";
import FunnelTarjeta from "../../anaCoach/Training/CreditCardVideo";
import FunnelTarjetaTest from "../../anaCoach/Training/CCTest";
import FunnelTarjetaTestCopy from "../../anaCoach/Training/CCTest_Copy";
import FunnelTarjetaAB from "../../anaCoach/Training/CCTest_AB";
import FunnelTarjetaOCA from '../../anaCoach/Training/CCTestOCA';
import Mvp from "../../anaCoach/Training/Mvp";
import FamilyProtection from "../../anaCoach/Training/FamilyProtection";
import TrainingProfiling from "../../anaCoach/Training/TrainingProfiling";
import FirstInversion from "../../anaCoach/Training/FirstInversion";
import LPFirstInversion from "../../anaCoach/Training/LpFirstInversion";
import LpXCoach from "../../anaCoach/Training/LpXCoach";
import LPpageplanes from "../../anaCoach/Training/LPpageplanes";
import InvestmentCatalog from "../../anaCoach/Training/InvestmentCatalog";
import TrainingProfiling2_0 from "../../anaCoach/Training/TrainingProfiling2_0";
import TrainingProfiling2_0Sucess from "../../anaCoach/Training/SucessProfiling2_0";
import ExtraIncome from "../../anaCoach/Training/ExtraIncome";
import FastTrack from "../../anaCoach/Training/FastTrack";
import TrainingBudget from "../../anaCoach/Training/TrainingBudget";
import WOImproveBudget from "../../anaCoach/Training/WOImproveBudget";
import WOGoals from "../../anaCoach/Training/WOGoals";
import WOCollaborative from "../../anaCoach/Training/WOCollaborative";
import RpCollaborative from "../../anaCoach/Routines/RpCollaborative";
import WOPymes from "../../anaCoach/Training/WOPymes";
import RpPymes from "../../anaCoach/Routines/RpPymes";
import WOValueOfMoney from "../../anaCoach/Training/WOValueOfMoney";
import PersonalLoans from "../../anaCoach/Training/PersonalLoans";
import Debts from "../../anaCoach/Training/Debts";
import SuccessCompare from "../../anaCoach/Training/SuccessCompare";

// import SuccessTarjeta from "../../anaCoach/Training/SuccessCard";
import SuccessTarjeta from "../../anaCoach/Training/SuccessCard_Replica";
import SuccessTarjetaFirstCard from "../../anaCoach/Training/SucessFirstCard";
import SuccessProfiling from "../../anaCoach/Training/SuccessProfiling";
import SuccessPL from "../../anaCoach/Training/SuccessPL";
import FamilyProtectionSuccess from "../../anaCoach/Training/SuccessFamilyProtection";
import EmergencySaving from "../../anaCoach/Training/EmergencySaving";
import InvestmentTest from "../../anaCoach/Training/InvestmentTest";
import ViralityTest from "../../anaCoach/Training/ViralityTest";
import InvestmentSimulator from "../../anaCoach/Training/InvestmentSimulator";
import NewYearsPurpose from "../../anaCoach/Training/NewYearsPurpose";
import RpNewYearsPurpose from "../../anaCoach/Routines/RpNewYearsPurpose";
import RpReasons from "../../anaCoach/Routines/RpReasons";
import SuccessPrestamosMedida from "../../anaCoach/Training/SuccessPrestamosMedida";
import WOAntExpenses from "../../anaCoach/Training/WoAntExpenses";
import NoHistorial from "../../anaCoach/Training/NoHistory";
import LpObtenerTarjeta from "../../anaCoach/Training/LpObtenerTarjeta";
import LpAhorroDolares from "../../anaCoach/Training/LpAhorroEnDolares";
import LpStori from "../../anaCoach/Training/LpStori";
import LpAhorroDolaresSuccess from "../../anaCoach/Training/LpAhorroEnDolaresSuccess";
import WoAfores from "../../anaCoach/Training/WoAfores";
import LpSuperSeguro from "../../anaCoach/Training/LpSuperSeguro";
import WoScore from "../../anaCoach/Training/WoScore";
import CarInsurance from "../../anaCoach/Training/WOCI";
import LpRappi from "../../anaCoach/Training/LpRappi";
import ContactRappi from "../../anaCoach/rappi/ContactRappi";
import VerificationCodeRappi from "../../anaCoach/rappi/VerificationCodeRappi";
import CreditHistoryRappi from "../../anaCoach/rappi/CreditHistoryRappi";
import VerificationErrorRappi from "../../anaCoach/rappi/VerificationErrorRappi";
import SuccessRappi from "../../anaCoach/rappi/SuccessRappi";
import LpNu from "../../anaCoach/Training/LpNubank";
import ContactNu from "../../anaCoach/nubank/ContactNu";
import VerificationCodeNu from "../../anaCoach/nubank/VerificationCodeNu";
import SuccessNu from "../../anaCoach/nubank/SuccessNu";
import RpCI from "../../anaCoach/Routines/RpCI";
import LpVexi from "../../anaCoach/Training/LpVexi";
import ContactVexi from "../../anaCoach/vexi/ContactVexi";
import SuccessVexi from "../../anaCoach/vexi/SuccessVexi";
import VerificationCodeVexi from "../../anaCoach/vexi/VerificationCodeVexi";
import FirstInvestmentV2 from "../../anaCoach/Training/WOFirstInvestmentV2";
import TrainingCategoriesLp from "../../anaCoach/Training/TrainingCategoriesLp";
import LpCoppel from '../../anaCoach/Training/LpCoppel';
import LpCoppelOTP from '../../anaCoach/Training/LpCoppelOTP';
import LpCoppelAdress from '../../anaCoach/Training/LpCoppelAdress';
import LpCoppelSuccess from '../../anaCoach/Training/LpCoppelSuccess';
import WaitlistKanda from '../../anaCoach/WaitlistKanda';
import GloveBox from "../../anaCoach/Training/GloveBox";
import CaaS from '../../components/CaasHomeNew/CaasNew';
import Demo from '../../components/CaasHomeNew/Demo';

const Training = {
  ObtenerTarjeta,
  Motivos,
  NoHistorial,
  ComparaTarjeta,
  FunnelTarjeta,
  FunnelTarjetaTest,
  FunnelTarjetaTestCopy,
  FunnelTarjetaAB,
  FunnelTarjetaOCA,
  Mvp,
  FamilyProtection,
  TrainingProfiling,
  FirstInversion,
  LPFirstInversion,
  LpXCoach,
  LPpageplanes,
  InvestmentCatalog,
  TrainingProfiling2_0,
  TrainingProfiling2_0Sucess,
  ExtraIncome,
  FastTrack,
  TrainingBudget,
  WOImproveBudget,
  WOGoals,
  WOCollaborative,
  WOPymes,
  RpPymes,
  RpCollaborative,
  WOValueOfMoney,
  PersonalLoans,
  Debts,
  SuccessTarjeta,
  SuccessTarjetaFirstCard,
  SuccessProfiling,
  SuccessPL,
  FamilyProtectionSuccess,
  EmergencySaving,
  InvestmentTest,
  ViralityTest,
  InvestmentSimulator,
  NewYearsPurpose,
  RpNewYearsPurpose,
  RpReasons,
  SuccessPrestamosMedida,
  WOAntExpenses,
  LpObtenerTarjeta,
  LpAhorroEnDolares: LpAhorroDolares,
  LpStori,
  LpAhorroEnDolaresSuccess: LpAhorroDolaresSuccess,
  WoAfores,
  LpSuperSeguro,
  WoScore,
  CarInsurance,
  LpRappi,
  ContactRappi,
  VerificationCodeRappi,
  CreditHistoryRappi,
  VerificationErrorRappi,
  SuccessRappi,
  LpNu,
  ContactNu,
  VerificationCodeNu,
  SuccessNu,
  RpCI,
  LpVexi,
  ContactVexi,
  SuccessVexi,
  VerificationCodeVexi,
  FirstInvestmentV2,
  TrainingCategoriesLp,
  LpCoppel,
  LpCoppelAdress,
  LpCoppelOTP,
  LpCoppelSuccess,
  WaitlistKanda,
  GloveBox,
  SuccessCompare,
  CaaS,
  Demo,
};
export default Training;
