import React, { Fragment } from 'react';

import {
  Content_Carousel,
  Image,
  Carousel,
  Papers_Contain_History,
  Div_Flex,
  ContEntrenamiento,
  TitleEntrenamiento,
  SubtitlesHistory,
} from '../../assets/styles/Home.styled';
import image1 from '../../assets/img/anacoach/Arturo.jpg';
import image2 from '../../assets/img/anacoach/Antonio.jpg';
import image3 from '../../assets/img/anacoach/Diana.jpg';
import star from '../../assets/img/home/star.png';
import trustpilot from '../../assets/img/home/trustpilot.png';

function ListItems(props) {
  const widthBrowser = window.outerWidth;
  const content = props.items.map(
    (post) => (
      <ContEntrenamiento
        height="197px"
        border="1px solid #CDCDCD"
        marginleft=" "
        box="border-box"
        radius="4px"
        border="1px solid #CDCDCD"
        padding="24px 21px"
      >
        <TitleEntrenamiento
          aling="left"
          size="20px"
          top=" "
          left=" "
          width="100%"
          className="roboto"
        >
          {post.name}
        </TitleEntrenamiento>
        <SubtitlesHistory aling="left" className="roboto">
          "{post.parrafo1}"
        </SubtitlesHistory>
        <Div_Flex width="116px" margin=" ">
          <Image width="20px" hei="20px" src={star} className="img-fluid" />
          <Image width="20px" hei="20px" src={star} className="img-fluid" />
          <Image width="20px" hei="20px" src={star} className="img-fluid" />
          <Image width="20px" hei="20px" src={star} className="img-fluid" />
          <Image width="20px" hei="20px" src={star} className="img-fluid" />
        </Div_Flex>
      </ContEntrenamiento>
    ),
  );
  return (
    <Fragment>
      {widthBrowser < 700 ? (
        content
      ) : (
        <Content_Carousel>
          <Carousel>
            <Papers_Contain_History>{content}</Papers_Contain_History>
          </Carousel>
        </Content_Carousel>
      )}
    </Fragment>
  );
}
const items = [
  {
    id: 1,
    name: 'Facilidad',
    parrafo1:
      'Facilidad para la adquisición de productos financieros así como opciones de financiamiento atractivos.',
    color: '#fff',
    image: image1,
  },
  {
    id: 2,
    name: 'Fácil y flexible',
    parrafo1:
      'Me gustó mucho la idea de poder hacer un trámite por Internet, más fácil y rápido sin tantas complicaciones.',
    color: '#fff',
    image: image2,
  },
  {
    id: 3,
    name: 'Calidad',
    parrafo1:
      'Excelente, muy fácil y sin muchas complicaciones. Fue muy rápido y con mucha atención, gracias amigos.',
    color: '#fff',
    image: image3,
  },
];

export default class History_Clients extends React.Component {
  render() {
    return (
      <Fragment>
        <ListItems items={items} />
        <Image
          width="110px"
          hei="53px"
          src={trustpilot}
          className="img-fluid"
          float="right"
          marginM="8% 0px 0 5%"
        />
      </Fragment>
    );
  }
}
