import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import iconoavatar from '../../assets/img/anacoach/Login.png';
import { Images, TrainingSectionGray, TrainingDivSection2 } from '../../assets/styles/Training.styled';
import { Col7, ParagrapTwo, ContentLink } from '../common/common.styled';

function ModalDataPersonalInitWO({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) {
  const [isData] = useState(sessionStorage.getItem('DataPerson') === 'true');
  const history = useHistory();
  const [avatarSelect] = useState(sessionStorage.getItem('ProfilePicture') !== 'null' && sessionStorage.getItem('ProfilePicture') != null ? sessionStorage.getItem('ProfilePicture') : iconoavatar);
  const path = window.location.pathname;
  let backColor = '';
  if (path.includes('funnel-prestamos') || path.includes('prestamos-personales/success')) {
    backColor = '#BE7AE6';
  } else if (path.includes('obtener-primer-tarjeta') || path.includes('/tarjeta-de-credito/success')) {
    backColor = '#DE98FF';
  } else if (path.includes('compare-credit-card')) {
    backColor = '#917AFF';
  } else if (path.includes('debts')) {
    backColor = '#E3E660';
  } else if (path.includes('family-protection') || path.includes('family/succcess')) {
    backColor = '#61D2FA';
  } else if (path.includes('profiling') || path.includes('weekly-summary')) {
    backColor = '#FA9878';
  } else if (path.includes('emergency-saving')) {
    backColor = '#7FBD62';
  } else if (path.includes('investment-test')) {
    backColor = '#91D770';
  } else if (path.includes('investment-catalog')) {
    backColor = '#99F2A8';
  } else if (path.includes('funnel-tarjeta')) {
    backColor = '#a370ff';
  } else if (path.includes('extra-incomee')) {
    backColor = '#58D65C';
  } else if (path.includes('first-investment')) {
    backColor = '#6BD5AF';
  } else if (path.includes('budget')) {
    backColor = 'rgb(245, 168, 56)';
  } else if (path.includes('investment-simulator')) {
    backColor = '#42A145';
  } else if (path.includes('budget-improve')) {
    backColor = 'rgb(245, 202, 93)';
  } else if (path.includes('fast-track')) {
    backColor = '#6677E3';
  } else if (path.includes('virality-test')) {
    backColor = '#F074DC';
  } else if (path.includes('/the-training-feed/') || path.includes('/achievements') || path.includes('/profile')) {
    backColor = '#BFDD6A';
  } else if (path.includes('new-years-purpose')) {
    backColor = '#EB4040';
  } else if (path.includes('rejection-reasons') || path.includes('reasonsSuccess')) {
    backColor = '#8892E3';
  } else if (path.includes('money-value')) {
    backColor = '#FA9D59';
  } else if (path.includes('goals')) {
    backColor = '#f5d495';
  } else if (path.includes('money-value')) {
    backColor = '#fa9d59';
  } else if (path.includes('pymes')) {
    backColor = '#33A376';
  } else {
    backColor = '#000';
  }
  const [editData, seteditData] = useState(false);

  const handleClickEditData = () => {
    history.push('/profile');
    sessionStorage.setItem('DataPerson', false);
    seteditData(true);
  };
  const fname = sessionStorage.getItem('FirstName') || '';
  let sname = sessionStorage.getItem('MiddleName') || '';
  const lastname = sessionStorage.getItem('FathersLastName') || '';
  const mothername = sessionStorage.getItem('MothersLastName') || '';
  if(sname === null || sname === 'null') {
    sname = '';
  }
  const name = `${fname} ${sname} ${lastname} ${mothername}`;

  return (

    isData
      ? <>
            {!editData
              ? <TrainingSectionGray wiw="670px" widthn="90%" mw="auto" mn="auto" back="transparent" className="topOne1Init" >
                <TrainingDivSection2 paddiw="0" paddi="0" back="transparent">
                    <Col7 maxwidth="100%" padding=" " maxwi="60%" mlw="18%" mlM="0px" className="cardPrincipal1" background="rgb(250,232,238)">
                        <div className="row rowMargin0">
                            <div className="col-3 col-md-3 marginUserBorder" style={{
                              display: 'flex', border: `2px solid${backColor}`, borderRadius: '45px', maxWidth: '65px', padding: '0',
                            }}>
                                <Images
                                    src={avatarSelect}
                                    className='img-fluid'
                                    width="auto"
                                    height="auto"
                                    topweb=" "
                                    top=" "
                                    left="auto"
                                    leftw= "auto"
                                    position=" "
                                    positionw=" "
                                    bs="border-box"
                                    padd="5px"
                                    position600="inherit"
                                    margin="auto"
                                    marginw="auto"
                                    br="45px"
                                    maxwidth="62px"
                                    maxwidthw="62px"
                                    maxh="79px"
                                ></Images>
                            </div>
                            <div className="col-9 col-md-9" style={{ padding: '3%' }}>
                              <ParagrapTwo mb="10%" mbweb="3%" className="mx-auto cabin" texttalign="left" textalignMobile="left" fontSize="20px" fontSizeWeb="20px" fontWeight ="bold" >
                                 {name}
                              </ParagrapTwo>
                              <ParagrapTwo mb="10%" mbweb="3%" className="mx-auto roboto" texttalign="left" textalignMobile="left" fontSize="16px" fontWeight ="normal" >
                                 ¡Espera! Antes de iniciar, ¿deseas actualizar tus datos? <span aria-label="thinking face" role="img">🤔</span>  <ContentLink onClick={handleClickEditData}>Edítalos aquí</ContentLink>
                              </ParagrapTwo>

                            </div>
                        </div>
                    </Col7>
                </TrainingDivSection2>
            </TrainingSectionGray>
              : <TrainingSectionGray wiw="670px" widthn="90%" mw="auto" mn="auto" back="transparent" className="topOne1Init" >
               <TrainingDivSection2 paddiw="0" paddi="0" back="transparent">
               </TrainingDivSection2>
            </TrainingSectionGray> }
         </>
      : null
  );
}

export default ModalDataPersonalInitWO;
