import React, { Fragment, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import '../../assets/styles/Home.css';
import Carousel from 'react-elastic-carousel';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingSectionFlex,
  DivCardsCC,
  CardsBtn,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';

import { Col12 } from '../../components/common/common.styled';
import CardOne from '../../components/Home/Card1Animate';
import ModalProductos from '../../components/Training/ProductosModal';
import ProductsRP from '../../components/common/ProductsRP';
import FiltersRP from '../../components/common/FiltersRP';
import BarFilterBank from '../../components/common/BarFilterBank';
import VideoQuestion from '../../components/common/VideoQuestion';
import {
  GET_PRODUCTS_OPORTUNITIES,
} from '../../queries';
import { DYNAMIC_GET, CREATE_OPPORTUNITIES } from '../../mutations/index';
import ModalVerCartera from '../../components/common/Modal_ViewWallet';

const ShowAvailableCardsV2 = ({
  Date,
  hora,
  handleRoutineOrder,
  currentRoutine,
  orderRutine,
  editCS,
  Card,
  createJsonInput,
  trainingColor,
  setOrderRutine,
  nextOrderRoutine,
}) => {
  const [ingreso, setIngreso] = useState(
    sessionStorage.getItem('ingresoMostrar') || '',
  );
  const [ingresoString, setIngresoString] = useState(
    sessionStorage.getItem('IngresoMensual')
      || sessionStorage.getItem('Ingreso_Mensual_Fijo')
      || '',
  );
  const [Rutine, setRutine] = useState({});
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [Opciones, setOpciones] = useState('');
  const [mejoresOpciones, setMejoresOpciones] = useState('');
  const anchop = window.screen.width;
  const { sendMessage } = SendNotificationFTAndCC();
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeCatOption = (val) => {
    setIngreso(val);
  };
  const [form, setForm] = useState({
    firstname: sessionStorage.getItem('FirstName') || '',
    lastname: sessionStorage.getItem('FathersLastName') || '',
    secondname: sessionStorage.getItem('MiddleName') || '',
    email: sessionStorage.getItem('Email1') || '',
    secondlastname: sessionStorage.getItem('MothersLastName') || '',
    day: sessionStorage.getItem('day') || Date.day,
    month: sessionStorage.getItem('month') || Date.month,
    year: sessionStorage.getItem('year') || Date.year,
    cel: sessionStorage.getItem('CellPhone') || '',
    ingreso: sessionStorage.getItem('ingresoMostrar') || '',
  });

  const [cardsProducts, setCardsProducts] = useState(
    sessionStorage.getItem('productByProvider')
      || sessionStorage.getItem('CatProductosTemp')
      || [],
  );

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  const [cardNames] = useState([]);
  const [banks, setBanks] = useState([]);
  const [annuities, setAnnuities] = useState(['Todas las anualidades']);
  const [bankFilter] = useState([]);
  const [annuityFilter] = useState([]);
  const [cardsFilter] = useState([]);
  const [flag, setFlag] = useState(false);
  const [countResults, setCountResults] = useState(0);
  const [showResults, setShowResults] = useState(true);
  const [optionsTipos, setOptionsTipos] = useState([
    'Tarjetas Premium',
    'Tarjetas Básicas',
  ]);
  const [showFilterCategory, setShowFilterCategory] = useState(true);
  const [cardsProductsFilt, setCardsProductsFil] = useState([]);
  const [selectedComplete, setSelectedComplete] = useState(
    JSON.parse(sessionStorage.getItem('walletArr')) || [],
  );
  const [modalCartera, setModalCartera] = useState(false);
  const [allIdProductsApply, setAllIdProductsApply] = useState([]);

  const [getProductsName] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log(dynamicGet);
      if (dynamicGet.statusCode === 200) {
        // console.log("## response >> ", dynamicGet)
        const namesProducts = JSON.parse(dynamicGet.response);
        sessionStorage.setItem('numProducts', namesProducts.length);
        for (const i in namesProducts) {
          const num = parseInt(i) + 1;
          sessionStorage.setItem(
            `product-${String(num)}`,
            namesProducts[i].Name,
          );
          cardNames.push(namesProducts[i].Name);
        }
      }
    },
  });

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        // console.log("## createOpportunitiesUser>>>", response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleAnnuitiesReverse = (annuity) => {
    if (annuity < 350 && !annuities.includes('Menos de $350')) {
      annuities.push('Menos de $350');
    } else if (
      annuity >= 350
      && annuity < 550
      && !annuities.includes('$350 - $550')
    ) {
      annuities.push('$350 - $550');
    } else if (
      annuity >= 550
      && annuity < 700
      && !annuities.includes('$550 - $700')
    ) {
      annuities.push('$550 - $700');
    } else if (
      annuity >= 700
      && annuity < 1000
      && !annuities.includes('$700 - $1,000')
    ) {
      annuities.push('$700 - $1,000');
    } else if (annuity >= 1000 && !annuities.includes('Más de $1,000')) {
      annuities.push('Más de $1,000');
    }
  };

  const handleAnnuities = (a) => {
    switch (a) {
      case 'Todas las anualidades':
        return ['Todas'];
      case 'Menos de $350':
        return ['Menos', 350];
      case '$350 - $550':
        return [350, 550];
      case '$550 - $700':
        return [550, 700];
      case '$700 - $1,000':
        return [700, 1000];
      case 'Más de $1,000':
        return ['Mas', 1000];
      default:
        break;
    }
  };

  const filteringForBanks = (byXfilter) =>
    // recibe un array de arrays
    // return cardsProducts.filter((cardsByProvider) => bankFilter.includes(cardsByProvider[0].BusinessAbr));
    byXfilter.filter((cardsByProvider) => bankFilter.includes(cardsByProvider[0].BusinessAbr));
  const filterProducts = () => {
    console.log('Excute 2');
    if (!flag) return;
    let byBank = [];
    let byAnnuity = [];
    let byCategory = [];
    setShowResults(false);

    if (bankFilter.length > 0) {
      byBank = filteringForBanks(cardsProducts);
      setCardsProductsFil(byBank);
      setCountResults(byBank.length);
    } else {
      setCardsProductsFil(cardsProducts);
      setCountResults(cardsProducts.length);
    }

    if (byBank.length > 0 && annuityFilter.length > 0) {
      byAnnuity = filteringForAnnuity(byBank);
      setCardsProductsFil(byAnnuity);
      setCountResults(byAnnuity.length);
    } else if (annuityFilter.length > 0) {
      byAnnuity = filteringForAnnuity(cardsProducts);
      setCardsProductsFil(byAnnuity);
      setCountResults(byAnnuity.length);
    }

    if (byAnnuity.length > 0 && cardsFilter.length > 0) {
      byCategory = filteringForCategory(byAnnuity);
      setCardsProductsFil(byCategory);
      setCountResults(byCategory.length);
    } else if (byBank.length > 0 && cardsFilter.length > 0) {
      byCategory = filteringForCategory(byBank);
      setCardsProductsFil(byCategory);
      setCountResults(byCategory.length);
    } else if (cardsFilter.length > 0) {
      byCategory = filteringForCategory(cardsProducts);
      setCardsProductsFil(byCategory);
      setCountResults(byCategory.length);
    }

    setFlag(false);
    setShowResults(true);
  };

  useEffect(() => {
    console.log('Excute 1');
    if (
      bankFilter.length > 0
      || annuityFilter.length > 0
      || cardsFilter.length > 0
    ) {
      filterProducts();
    } else {
      setCountResults(cardsProducts.length);
      let tempJson;
      let isValid = 1;
      try {
        tempJson = JSON.parse(cardsProducts);
      } catch (error) {
        isValid = 0;
      }

      if (isValid === 1) {
        setCardsProductsFil(tempJson);
      } else {
        setCardsProductsFil(cardsProducts);
      }

      setFlag(false);
    }
  }, [flag]);

  useEffect(() => {
    let basicF = false;
    let premiumF = false;

    let tempContent;
    let jsonStatus = 1;
    try {
      tempContent = JSON.parse(cardsProducts);
    } catch (error) {
      console.log('Not json');
      jsonStatus = 0;
    }

    let tempCards;

    if (jsonStatus === 1) {
      tempCards = tempContent;
    } else {
      tempCards = cardsProducts;
    }

    // console.log("## tempCards >", tempCards)

    tempCards.forEach((cardsByProvider) => {
      if (cardsByProvider[0].ProductCategory === 'Básica') basicF = true;
      else if (cardsByProvider[0].ProductCategory === 'Premium') { premiumF = true; }
    });

    // console.log("## basicF >", basicF)
    // console.log("## premiumF >", premiumF)

    const filterCardTypes = [];

    if (basicF) filterCardTypes.push('Tarjetas Básicas');
    if (premiumF) filterCardTypes.push('Tarjetas Premium');

    setOptionsTipos(filterCardTypes);

    if (filterCardTypes.length <= 1) setShowFilterCategory(false);
    else setShowFilterCategory(true);
  }, [cardsProducts]);

  const filteringForAnnuity = (byXfilter) => {
    // recibe un array de arrays
    const byAnnuity = [];
    for (let i = 0; i < byXfilter.length; i++) {
      const bank = byXfilter[i];
      for (let b = 0; b < bank.length; b++) {
        const arrayObject = JSON.parse(bank[b].productDetail.response);
        for (let p = 0; p < arrayObject.length; p++) {
          const name = arrayObject[p].Name;
          const value = parseFloat(arrayObject[p].Description);
          if (name === 'Anualidad') {
            annuityFilter.map((annuity) => {
              const auxAnn = handleAnnuities(annuity);
              if (
                !auxAnn.includes('Todas')
                && !auxAnn.includes('Menos')
                && !auxAnn.includes('Mas')
              ) {
                const resolve = resolveOperation('Rango', value, auxAnn);
                if (resolve) byAnnuity.push(bank);
              }
              // else if(auxAnn.includes('Todas')) {
              //     return byXfilter;
              // }
              else if (resolveOperation(auxAnn[0], value, auxAnn[1])) { byAnnuity.push(bank); }
            });
          }
        }
      }
    }
    return byAnnuity;
  };

  const handleCategory = (category) => {
    if (category === 'Básica') {
      return 'Tarjetas Básicas';
    } if (category === 'Premium') {
      return 'Tarjetas Premium';
    }
  };

  const resolveOperation = (operator, valueUser, ValueTable) => {
    switch (operator) {
      case 'Todas':
        if (valueUser === ValueTable) return true;
        return false;
      case 'Mas':
        if (parseFloat(valueUser) > parseFloat(ValueTable)) return true;
        return false;

      case 'Menos':
        if (parseFloat(valueUser) < parseFloat(ValueTable)) return true;
        return false;
      case 'Rango':
        const range = ValueTable;
        if (valueUser >= range[0] && valueUser < range[1]) return true;
        return false;

      default:
        break;
    }
  };

  const filteringForCategory = (byXfilter) =>
    // recibe un array de arrays
    // return cardsProducts.filter((cardsByProvider) => bankFilter.includes(cardsByProvider[0].BusinessAbr));
    byXfilter.filter((cardsByProvider) => {
      console.log('cardsByProvider >>> ', cardsByProvider);
      const category = handleCategory(cardsByProvider[0].ProductCategory);
      return cardsFilter.includes(category);
    });
  const handleShowResults = () => setShowResults(true);

  const insertOpportunitiesUser = async (idProducts) => {
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1, // remplazar por el id del entrenamiento
        idProducts, // arreglo de numero ejemplo [10,1,3]
        idTraining: 35,
      },
    };
    const conditions = [];
    for (const i in idProducts) {
      const conditionsTemp = {
        valone: 'IDProduct',
        condition: '=',
        valtwo: String(idProducts[i]),
        logic: 'OR',
      };
      conditions.push(conditionsTemp);
    }
    const productsNameInput = {
      input: {
        table: 'CatProduct',
        columns: ['Name'],
        conditions,
      },
    };
    if (sessionStorage.getItem('datOpportunity')) {
      opportunityDetailInput.input.idOpportunities = sessionStorage
        .getItem('datOpportunity')
        .split(',')
        .map(Number);
    }
    await getProductsName({ variables: productsNameInput });
    setOrderRutine(nextOrderRoutine);
    createOpportunitiesUser({ variables: opportunityDetailInput });
  };

  const applyCards = (idProducts) => {
    if (idProducts.length <= 0) {
      const arrW = sessionStorage.getItem('walletArr');
      if (arrW) {
        let allGood = true;
        const tempArr = [];
        try {
          const jsonTemp = JSON.parse(arrW);
          jsonTemp.forEach((l) => tempArr.push(l.IDProduct.toString()));
        } catch (e) {
          allGood = false;
        }
        if (allGood) idProducts = tempArr;
      }
    }
    setModalCartera(false);
    const apply = [];
    for (let d = 0; d < idProducts.length; d++) {
      const check = idProducts[d];
      apply.push(parseInt(check));
    }
    sessionStorage.setItem('SelectIds', apply);
    insertOpportunitiesUser(apply);
  };

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log(name);
    sessionStorage.setItem('IngresoMensual', name);
    setIngresoString(name);
    const questions = [
      {
        id: name,
        question: numQuestion,
        value,
      },
    ];
    createJsonInput(Rutine, questions);
    obtenerProductos();
  };
  const handleChange = (e, question = '') => {
    console.log(e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone'),
      trigger: 'wa_cc_3',
    });
    if (mejoresOpciones === 'Si') {
      setOpciones('');
    }
  }, [mejoresOpciones]);

  const [scrollIng, setScrollIng] = useState(false);
  useEffect(() => {
    if (
      ingreso !== ''
      && ingresoString !== ''
      && cardsProducts.length > 0
      && !scrollIng
    ) {
      setScrollIng(true);
    }
  }, [ingreso]);

  useEffect(() => {
    if (Opciones === 'Si') {
      setMejoresOpciones('');
    }
  }, [Opciones]);

  let products;
  let productsDetails;

  const [getProductsDetails] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        productsDetails = JSON.parse(dynamicGet.response);
        const details = {};
        productsDetails.forEach((x) => {
          const id = `idProduct-${x.IDProduct}`;
          if (!details.hasOwnProperty(id)) {
            details[id] = {
              detail: [],
            };
          }
          details[id].detail.push(x);
        });
        sessionStorage.setItem('Detalles2', JSON.stringify(details));
        sessionStorage.setItem('DetallesProd', dynamicGet.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const obtenerProductos = () => {
    const getProductsInput = {
      idUser: parseInt(sessionStorage.getItem('idUser')),
      idCont: parseInt(sessionStorage.getItem('idCont')),
      income: sessionStorage.getItem('Ingreso_Mensual_Fijo'),
      vertical: 1,
      idTraining: parseInt(sessionStorage.getItem('idTraining')),
    };
    console.log('realizo la peticion');

    setBanks([]);
    setAllIdProductsApply([]);
    setCountResults(0);
    setAnnuities(['Todas las anualidades']);

    /* - - - - - - - - - - - - - */

    getProductsOportunities({ variables: getProductsInput });
    sessionStorage.setItem('FlagProducts', false);
  };

  const [getProductsOportunities] = useLazyQuery(GET_PRODUCTS_OPORTUNITIES, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsOportunities }) {
      if (getProductsOportunities.statusCode === 200) {
        // setTimeout(() => {
        products = JSON.parse(getProductsOportunities.response);
        products = products.map((product) => {
          if (product.ClickOut !== null) {
            if (product.ClickOut.includes('[IDUser]')) {
              const newClickout = product.ClickOut.replace(
                '[IDUser]',
                sessionStorage.getItem('idUser'),
              );
              return { ...product, ClickOut: newClickout };
            }
            return product;
          }
          return product;
        });
        /* - - - - - - - - - - - - - - - - - - - - - - - - - - */

        const productByProvider = [];
        for (let p = 0; p < products.length; p++) {
          setCountResults(p + 1);
          const productDetail = JSON.parse(products[p].productDetail.response);
          productDetail.map((detail) => {
            if (detail.Name === 'Anualidad') {
              handleAnnuitiesReverse(parseFloat(detail.Description));
            }
          });
          console.log('products == ', productDetail);
          const iDProvider = products[p].CatProviders_IDProvider;
          allIdProductsApply.push(products[p].IDProduct);
          const getProductByProvider = products.filter((card) => {
            if (card.CatProviders_IDProvider === iDProvider) return card;
            // else return null;
          });
          productByProvider.push(getProductByProvider);
          if (!banks.includes(getProductByProvider[0].BusinessAbr)) {
            banks.push(getProductByProvider[0].BusinessAbr);
          }
        }
        sessionStorage.setItem(
          'productByProvider',
          JSON.stringify(productByProvider),
        );

        /* - - - - - - - - - - - - - - - - - - - - - - - - - - */
        // console.log("Productos:" + getProductsOportunities.response)
        sessionStorage.setItem('CatProductos', JSON.stringify(products));
        sessionStorage.setItem('CatProductosTemp', JSON.stringify(products));
        setCardsProducts(productByProvider);
        /* - - - - - - - - - - - - - - - - - - - - - - - - - - */

        setCardsProductsFil(productByProvider);

        /* - - - - - - - - - - - - - - - - - - - - - - - - - - */
        const conditions = [];
        for (const i in products) {
          const conditionsTemp = {
            valone: 'IDProduct',
            condition: '=',
            valtwo: String(products[i].IDProduct),
            logic: 'OR',
          };
          conditions.push(conditionsTemp);
        }
        const productsDetailsInput = {
          input: {
            table: 'CatProductDetail',
            columns: ['*'],
            conditions,
          },
        };
        getProductsDetails({ variables: productsDetailsInput });
      }
    },
  });

  const cardButtons = () => {
    const ingresoFijo = sessionStorage.getItem('Ingreso_Mensual_Fijo');
    if (ingresoFijo === 'de_4000_a_7000' || ingresoFijo === 'menos_4000') { return ''; }
    return (
      <Fragment>
        <CardsBtn
          min_w="100%"
          mleftweb="18%"
          min_web="60%"
          onClick={() => handleRoutineOrder(2, [], 'Todas')}
        >
          Aplicar a todas
        </CardsBtn>
        <CardsBtn
          min_w="100%"
          min_web="60%"
          mleftweb="18%"
          onClick={() => {
            setOpciones('Si');
          }}
          color="#EC407A !important"
          back="#fff !important"
          border="solid 1px !important"
        >
          Ver otras opciones
        </CardsBtn>
      </Fragment>
    );
  };

  const shouldStop = (conditionName) => {
    switch (conditionName) {
      case 'cards':
        const ingresoFijo = sessionStorage.getItem('Ingreso_Mensual_Fijo');
        if (ingresoFijo === 'de_4000_a_7000' || ingresoFijo === 'menos_4000') { return true; }
        return false;

      default:
        return true;
    }
  };

  useEffect(() => {
    console.log('obtenerProductos');
    sessionStorage.removeItem('FlagProducts');
    sessionStorage.removeItem('CatProductos');
    if (
      sessionStorage.removeItem('CatProductos') !== 'null'
      && sessionStorage.removeItem('CatProductos')
    ) {
      sessionStorage.setItem(
        'CatProductosTemp',
        sessionStorage.removeItem('CatProductos'),
      );
    }
    obtenerProductos();
  }, []);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeRpIngreso"></div>
      {ingreso === '' ? (
        <VideoQuestion idVideo="k2qKmDgDr2MzJGx19xp"></VideoQuestion>
      ) : null}
      <TrainingQuestion
        mt="-210px"
        mtw="-210px"
        index="10"
        pos="relative"
        className={ingreso === '' ? '' : 'hidden'}
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          inputVideo={true}
        />
      </TrainingQuestion>
      {ingreso !== '' && ingresoString !== '' && cardsProducts.length > 0 ? (
        <>
          <div id="activeRpCC"></div>
          <div id="activeVerTodasOpciones"></div>
          <div className={editCS ? 'hidden' : ''}>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={'cardOne'}
              classTop="topOne1"
              text="¡Tenemos estas opciones para ti! Estamos seguros de tener una opción que podría interesarte."
              hora={hora}
            />
          </div>
          <TrainingQuestion className={editCS ? 'hidden' : null}>
            {sessionStorage.getItem('DetallesProd')
            && (sessionStorage.getItem('CatProductos')
              || sessionStorage.getItem('CatProductosTemp'))
            && Opciones !== 'Si'
            && (mejoresOpciones === 'Si' || mejoresOpciones === '') ? (
              <>
                <div>
                  <ModalProductos
                    products={
                      sessionStorage.getItem('CatProductosTemp')
                      || sessionStorage.getItem('CatProductos')
                    }
                    showButton={shouldStop('cards')}
                  ></ModalProductos>
                  {/* mas de 7k */ cardButtons()}
                </div>
              </>
              ) : null}
            {Opciones === 'Si' ? (
              <>
                <CardOne
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  classCard={'cardOne'}
                  classTop="topOne1"
                  text="Recuerda que puedes aplicar a las mejores opciones que tenemos para ti."
                  hora={hora}
                />
                <br />
                <CardsBtn
                  color="#EC407A"
                  back="#ffffff"
                  border="1px solid #EC407A"
                  type="button"
                  min_w="100%"
                  windthLg="60%"
                  mleftweb="18%"
                  mtopw="19px"
                  mtop="19px"
                  marginBxl="20px"
                  onClick={() => setMejoresOpciones('Si')}
                >
                  Elegir mejores opciones
                </CardsBtn>

                {ingreso !== ''
                && ingresoString !== ''
                && cardsProducts.length > 0 ? (
                  <Fragment>
                    <div id="activeRpCC"></div>
                    <div id="activeVerTodasOpciones"></div>
                    <CardOne
                      classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                      classCard={'cardOne'}
                      classTop="topOne1"
                      text="¡Tenemos estas opciones para ti! Estamos seguros de tener una opción que podría interesarte."
                      hora={hora}
                    />
                    {cardNames.length === 0 ? (
                      <Fragment>
                        <FiltersRP
                          optionsBanks={banks}
                          optionsAnualidades={annuities}
                          setBankFilter={bankFilter}
                          setAnnuityFilter={annuityFilter}
                          setCardFilter={cardsFilter}
                          setFlag={setFlag}
                          countResults={countResults}
                          handleShowResults={handleShowResults}
                          optionsTipos={optionsTipos}
                          showFilterCategory={showFilterCategory}
                        />

                        {showResults
                          && cardsProductsFilt.map((cardsByProvider, index) =>
                            /* if (cardsProducts.length !== (index)) { */
                            (
                              <BarFilterBank
                                key={index}
                                text={cardsByProvider[0].BusinessAbr}
                              >
                                <DivCardsCC>
                                  <TrainingSectionFlex
                                    key={`${index}-${cardsByProvider[0].BusinessAbr}`}
                                    className="d-flex flex-row"
                                    style={{
                                      marginTop: '20px',
                                      marginBottom: '20px',
                                    }}
                                  > <Col12
                                      id="CarruselEmpresasContentLogros"
                                      pt="0px"
                                      flex="0 0 100%"
                                      maxwidth="100%"
                                      display="flex"
                                    >
                                      <Carousel
                                        id="CarruselMetasLogros"
                                        styled={{ background: '#FAFAFA' }}
                                        transitionMs={1000}
                                      >
                                        <ProductsRP
                                          setSelectedComplete={
                                            setSelectedComplete
                                          }
                                          cardNames={cardNames}
                                          cards={cardsByProvider}
                                          setOrderRutine={setOrderRutine}
                                          nextOrderRoutine={nextOrderRoutine}
                                          applyCards={applyCards}
                                        />
                                      </Carousel>
                                    </Col12>
                                    {/* <button className="righty paddle2FastTrack" id="right-button" onClick={(e) => irDerecha(e)}></button> */}
                                  </TrainingSectionFlex>
                                </DivCardsCC>
                              </BarFilterBank>
                            ),
                            /* } */
                          )}
                        {selectedComplete.length > 0 && (
                          <CardsBtn
                            mtopmd="20px"
                            mtop="20px"
                            mtopw="20px"
                            windthmd="90%"
                            minmd="40%"
                            mleftmd="5%"
                            mrightmd="auto"
                            windthG="400px"
                            min_w="400px"
                            min_web="400px"
                            min_mini="80%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="15%"
                            mright="15%"
                            mleftweb="18%"
                            mrightweb="auto"
                            color="#EC407A !important"
                            back="#fff !important"
                            border="solid 1px !important"
                            onClick={() => setModalCartera(true)}
                          >
                            Aplicar a las tarjetas de mi cartera
                          </CardsBtn>
                        )}
                        {showResults && (
                          <CardsBtn
                            mtopmd="20px"
                            mtop="20px"
                            mtopw="20px"
                            windthmd="90%"
                            minmd="40%"
                            mleftmd="5%"
                            mrightmd="auto"
                            windthG="400px"
                            min_w="400px"
                            min_web="400px"
                            min_mini="80%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="15%"
                            mright="15%"
                            mleftweb="18%"
                            mrightweb="auto"
                            onClick={() => applyCards(allIdProductsApply)}
                          >
                            Aplicar a todas
                          </CardsBtn>
                        )}
                      </Fragment>
                    ) : null
                    }
                    <ModalVerCartera
                      selection={selectedComplete}
                      setModalState={setModalCartera}
                      modalState={modalCartera}
                      applyCards={applyCards}
                    />
                  </Fragment>
                  ) : null}
              </>
            ) : null}
          </TrainingQuestion>
          <div id="activeElegioOpcion"></div>
        </>
      ) : null}
    </div>
  );
};

export default ShowAvailableCardsV2;
