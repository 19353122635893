import React, { useState, Fragment, useEffect } from 'react';
import ProductoCI from '../../components/Training/ProductoCI';
import ModalGeneral from '../../components/common/ModalGeneral';
import TagManager from 'react-gtm-module';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
} from '../../assets/styles/Training.styled';
import { SeparateSection } from '../../assets/styles/Feed.styled';
import SectionShare from '../../components/common/ShareSection';
import WOlayout from '../../components/layouts/WOlayout';
import Header from '../../components/common/Header';
import HeaderSuccess from '../../components/Training/HeaderSucces';

const RpUserProfiling = ({}) => {
  const [insurance, setInsurance] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [selectInsurance, setSelectInsurance] = useState(false);
  const [trainingColor] = useState(sessionStorage.getItem('ColorFP'));
  useEffect(() => {
    setInsurance(JSON.parse(sessionStorage.getItem('insuranceSelect')));
    setSelectInsurance(true);
  }, []);

  return (
    <WOlayout
      backgroundColor={'#fafafa'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Header
        url={window.location.pathname}
        pathname={window.location.pathname}
        background={trainingColor}
      />
      <HeaderSuccess
        color1="#22C4CE"
        color2="#CBBFED"
        color3="#22C4CE"
        color4="#CBBFED"
        color5="#DE98FF"
        color6="#CBBFED"
        color7="#DE98FF"
        color8="#CBBFED"
        color9="#81D5DE"
        color10="#CBBFED"
        bg="#FAFAFA"
      />
      <TrainingMain mt="100px" mtw="0" className="" id="top">
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            paddiw="1% 35% 0 35%"
            paddi="35% 5% 5% 5%"
            back="#fafafa"
          >
            <TrainingSubtitles
              size="22px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              ¡Gracias {sessionStorage.getItem('FirstName') || ''}!
            </TrainingSubtitles>
            <TrainingText
              size="18px"
              weight="600"
              className="roboto"
              align="center"
              alignweb="center"
              ls="-0.2px"
            >
              Hemos procesado tu solicitud y este es el resultado.
            </TrainingText>
            <br />
            <br />
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              <TrainingSubtitles
                size="18px"
                fw="600"
                align="left"
                alignweb="left"
                className="cabin"
              >
                Estos son tus datos que registramos:
              </TrainingSubtitles>
              <b>Nombre cliente:</b> {sessionStorage.getItem('FirstName') || ''}{' '}
              {sessionStorage.getItem('MiddleName') || ''}{' '}
              {sessionStorage.getItem('FathersLastName') || ''}{' '}
              {sessionStorage.getItem('MothersLastName') || ''}
              <br />
              <b>Teléfono:</b> {sessionStorage.getItem('CellPhone') || ''}
              <br />
              <b>Email:</b> {sessionStorage.getItem('Email1') || ''}
              <br />
              <b>Marca del vehículo:</b> {sessionStorage.getItem('Marca')}
              <br />
              <b>Versión del vehículo:</b> {sessionStorage.getItem('Model')}
              <br />
              <b>Año del vehículo:</b> {sessionStorage.getItem('Year') || ''}
            </TrainingText>
          </TrainingDivSection2>
          <SeparateSection />
        </TrainingSectionGray>
      </TrainingMain>
      <Fragment>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            wiw="370px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            {selectInsurance ? (
              <ProductoCI
                insurance={insurance}
                cobertura={insurance.Paquete}
                index={1}
                selectInsurance={() => {
                  setModalActive(true);
                  const tagManagerArgs = {
                    dataLayer: {
                      event: 'ContactarAsesorModal',
                    },
                  };
                  TagManager.dataLayer(tagManagerArgs);        
                }}
                textButton={'Seleccionar'}
                success={true}
              />
            ) : null}
          </TrainingDivSection2>
          <TrainingDivSection2
            wiw="670px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            <SectionShare WO="CI"></SectionShare>
          </TrainingDivSection2>
        </TrainingSectionGray>
      </Fragment>
      <ModalGeneral
        modalActive={modalActive}
        setModalActive={setModalActive}
        handleFunction={() => {
          setModalActive(false);
          const tagManagerArgs = {
            dataLayer: {
              event: 'ContactarAsesor',
            },
          };
          TagManager.dataLayer(tagManagerArgs);
        }}
        txtTitle="¡Excelente!"
        txtSubtitle="Hemos guardado tus datos, la aseguradora con la que deseas contratar tu seguro ya sabe de ello, te contactara en cualquier momento, sigue pendiente de tu correo electrónico. También puedes ser contactado por un asesor si lo necesitas, solo da clic al botón de abajo"
        txtBtn="Me gustaria contactar a un asesor"
        heiMod="90%"
      />
    </WOlayout>
  );
};
export default RpUserProfiling;
