import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Shapes,
  Title1,
  Subtitle,
  ContentHomeSection,
  ContainerBottons,
  Container1,
  ContainerMenu,
  ContainerSubMenu,
} from '../components/common/Home.styled';
import {
  Container,
  Image,
} from '../components/common/common.styled';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import numeros from '../assets/img/anacoach/404.png';
import back from '../assets/img/anacoach/Back404.png';
import { CardsBtn } from '../assets/styles/Training.styled';

function NotFound({ setBandera }) {
  const history = useHistory();

  const handleClick = () => {
    setBandera(false);
    history.push('/');
  };

  return (
    <Fragment>
      <Container1>
        <ContainerMenu>
          <ContainerSubMenu>
            <Header pathname={'/'} />
          </ContainerSubMenu>
        </ContainerMenu>
        <Shapes
          background="#FAFAFA"
          top="100px"
          left="0px"
          heightD="auto"
          height="auto"
          width="100%"
          position=" "
          leftD="0px"
        >
          <ContentHomeSection
            leftD=""
            floatD="left"
            heightD="606px"
            width="100%"
            heightM="430px"
          >
            <Image
              src={back}
              width="80%"
              mwidth="100%"
              margin="auto"
              position="absolute"
              top="110px"
              left="10%"
              mleft="0px"
              mtop="75px"
            />
            <Image
              src={numeros}
              width="40%"
              mwidth="70%"
              margin="auto"
              position="absolute"
              top="110px"
              left="30%"
              mleft="17%"
              mtop="77px"
            />
            <Title1
              aling="center"
              className="roboto cabin"
              position="absolute"
              top="20%"
              left="0%"
              size="35px"
              mtopM="0px"
              mleftM="0px"
              sizeM="20px"
              width="100%"
            >
              Página no encontrada
            </Title1>
            <Subtitle
              aling="center"
              height="98px"
              bottom="0px"
              className="roboto"
              position="absolute"
              top="20%"
              left="0%"
              size="16px"
              marginTop="237px"
              width="100%"
              mleftM="27%"
              widthM="44%"
              mtopM="41px"
              sizeM="11px"
            >
              Esta página ha sido movida, borrada o quizás nunca ha existido
            </Subtitle>
          </ContentHomeSection>

          <Container width="80%" mt="0" widthMobile="100%" mtMobile="0" mb="5%">
            <ContainerBottons
              top="0%"
              topM="2%"
              position=" "
              bottomM="5%"
              bottomD="0"
            >
              <CardsBtn
                id="SeccionNombre"
                type="button"
                min_w="100%"
                min_web="100%"
                zindex="100"
                onClick={handleClick}
              >
                Volver al inicio
              </CardsBtn>
            </ContainerBottons>
          </Container>
        </Shapes>
      </Container1>
      <Footer pathname="/" />
    </Fragment>
  );
}

export default NotFound;
