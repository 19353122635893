import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Image } from './common.styled';
import {
  QuestionsText,
  QuestionsDivsbar,
} from '../../assets/styles/Training.styled';
import vw from '../../assets/img/anacoach/Training/vw.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '10px 0',
      width: '100%',
    },
    '& .MuiPopover-paper': {
      left: '462px !important',
    },
    '& .MuiMenu-paper': {
      minWidth: '28% !important',
    },
  },
}));

export default function MultilineTextFields({
  selectImage = false, currencies, currentData, setData, label, datCat = false
}) {
  const classes = useStyles();
  const [currency, setCurrency] = useState(currentData);

  const handleChange = (event) => {
    setCurrency(event.target.value);
    setData(event.target.value);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
        <div>
            { !selectImage && !datCat
              ? <TextField
                id="outlined-select-currency"
                select
                label={label}
                value={currency}
                onChange={handleChange}
                //   helperText="Selecciona año"
                variant="outlined"
                >
                {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
            </TextField>
              : (datCat && !selectImage
                ? <QuestionsDivsbar
                  onClick=""
                >
                  <QuestionsText size="14px" weight="400">
                    Exemplo
                  </QuestionsText>
                </QuestionsDivsbar>
                : <TextField
                    id="outlined-select-currency"
                    select
                    label={label}
                    value={currency}
                    onChange={handleChange}
                    //   helperText="Selecciona año"
                    variant="outlined"
                    >
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Image src={option.img ? option.img : vw} maxwidth="30px" marginRight="20px" marginRightM="20px" />
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
              )
            }
        </div>
    </form>
  );
}
