/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import CardOne from '../../components/Home/Card1Animate';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn1,
  TrainingSectionGray,
  ErrorDiv,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import { DYNAMIC_GET } from '../../mutations';

const Reasons = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  firstQuestion = false,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [alertFirst, setAlertFirst] = useState(false);
  const [alertSecond, setAlertSecond] = useState(false);
  const [getRecomendationWO] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      const recomendationWO_array = [];
      if (dynamicGet.statusCode === 200) {
        const recommendation = JSON.parse(dynamicGet.response);
        console.log(recommendation);
        const i = 1;
        purpose.forEach((element) => {
          recommendation.forEach((recom) => {
            if (recom.CatDetailTypeUsers_IDType_2 === element) {
              recomendationWO_array.push(recom);
            }
          });
        });
        sessionStorage.setItem(
          'recommendationWO',
          JSON.stringify({ data: recomendationWO_array }),
        );
        history.push('/wo/reasons-success');
      }
    },
  });
  const [getRecomendation] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      // const recomendation_array = [];
      if (dynamicGet.statusCode === 200) {
        const recommendation = JSON.parse(dynamicGet.response);
        let i = 1;
        purpose.forEach((element) => {
          recommendation.forEach((recom) => {
            if (recom.CatDetailTypeUsers_IDType_2 === element && i < 7) {
              const name = 'recomendations_reasons_'.concat(i);
              sessionStorage.setItem(name, recom.Recomendations);
              i++;
              if (recom.Description) {
                sessionStorage.setItem(
                  'recomendations_reasons_url_'.concat(i),
                  recom.Description,
                );
              }
            }
          });
        });
        const getRecomendationInput = {
          input: {
            table:
              'CatTrainingRecomendations, CatAvailabilityRecommendations, CatTraining',
            columns: [
              'CatDetailTypeUsers_IDType, CatAvailabilityRecommendations.Condition, CatDetailTypeUsers_IDType_2, CatTraining.*',
            ],
            conditions: [
              {
                valone:
                  'CatAvailabilityRecommendations_idCatAvailabilityRecommendations',
                condition: '=',
                valtwo: 'idCatAvailabilityRecommendations',
                validColumn: true,
              },
              {
                valone: 'CatTraining_IDCatTraining',
                condition: '=',
                valtwo: 'IDCatTraining',
                validColumn: true,
                logic: 'AND',
              },
            ],
          },
        };
        getRecomendationWO({ variables: getRecomendationInput });
      }
    },
  });

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];

                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [form, setForm] = useState({
    check_1_1: false,
    check_1_2: false,
    check_1_3: false,
    check_1_4: false,
    check_1_5: false,
    check_1_6: false,
    check_2_1: false,
    check_2_2: false,
    check_2_3: false,
    check_2_4: false,
    check_2_5: false,
    check_2_6: false,
  });

  const [purpose, setPurpose] = useState([]);
  const [showPurposeSelected, setShowPurposeSelected] = useState(false);
  const [showReasonsSelected, setShowReasonsSelected] = useState(false);
  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (purpose.length < 3 && checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setPurpose(purpose.concat(value));
      console.log(purpose);
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = purpose.indexOf(value);
      purpose.splice(deleted, 1);
    }
  };
  const handleChangeTwo = (e) => {
    const { name, checked, value } = e.target;
    console.log('handleChange', name, checked, value);
    if (purpose.length < 6 && checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      setPurpose(purpose.concat(value));
      console.log(purpose);
    } else if (!checked) {
      setForm({
        ...form,
        [name]: checked,
      });
      const deleted = purpose.indexOf(value);
      purpose.splice(deleted, 1);
    }
  };
  const history = useHistory();
  const handlePurpose = (e) => {
    e.preventDefault();
    if (purpose.length === 3 && !isEmptyObject(Rutine)) {
      setShowPurposeSelected(true);
      const purposeSelection = purpose.toString();
      setAlertFirst(false);
      questions.push({
        id: 'purposeSelection',
        question: 1,
        value: purposeSelection,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      gsap.to(window, { duration: 1, scrollTo: '#activeFrases' });
    } else {
      setAlertFirst(true);
    }
  };

  const handleReasons = (e) => {
    e.preventDefault();
    if (purpose.length === 6 && !isEmptyObject(Rutine)) {
      setShowReasonsSelected(true);
      setAlertSecond(false);
      const purposeSelection = purpose.slice(3, 6).toString();
      questions.push({
        id: 'purposeSelection',
        question: 2,
        value: purposeSelection,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      const getRecomendationInput = {
        input: {
          table: 'CatAvailabilityRecommendations, CatRecomendations',
          columns: ['*'],
          conditions: [
            {
              valone:
                'CatAvailabilityRecommendations_idCatAvailabilityRecommendations',
              condition: '=',
              valtwo: 'idCatAvailabilityRecommendations',
              validColumn: true,
            },
          ],
        },
      };
      getRecomendation({ variables: getRecomendationInput });
      gsap.to(window, { duration: 1, scrollTo: '#activeFrasesSelected' });
    } else {
      setAlertSecond(true);
    }
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        break;
      default:
        console.log(val);
    }
  };
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  const anchop = window.screen.width;
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeReasons' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      <div id="activeReasons"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
      />
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion wiw="75%" mLg="11%">
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="0-0"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            typeCheckBox={true}
            typeCheckBoxDescription={true}
            checkBoxName="check_1_"
          />
        </QuestionsFlex>
      </TrainingQuestion>

      <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0">
        <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
          <div className="marginComentLgSuccess1">
            {!showPurposeSelected ? (
              <CardsBtn1 onClick={(e) => handlePurpose(e)} windthmd="100%">
                Continuar
              </CardsBtn1>
            ) : null}
          </div>
          <TrainingQuestion className={alertFirst === true ? '' : 'hidden'}>
            <ErrorDiv align="center">Aún no seleccionas tus 3 frases.</ErrorDiv>
          </TrainingQuestion>
        </TrainingDivSection2>
      </TrainingSectionGray>
      <div id="activeFrases"></div>
      <CardTwo
        classCard={
          purpose.length >= 3 && showPurposeSelected ? 'cardTwoResp' : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={purpose[0]}
        text2={purpose[1]}
        text3={purpose[2]}
        colorTraining={trainingColor}
      />
      {purpose.length >= 3 && showPurposeSelected ? (
        <>
          <QuestionsOrder
            classCard={'cardOne'}
            orderRutine={currentRoutine}
            step={1}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
          />
          <TrainingQuestion wiw="75%" mLg="11%">
            <QuestionsFlex display="inline">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="1-1"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChangeTwo}
                currentTraining={currentTraining}
                typeCheckBox={true}
                typeCheckBoxDescription={true}
                checkBoxName="check_2_"
              />
            </QuestionsFlex>
          </TrainingQuestion>
          <TrainingSectionGray wiw="100%" mw="auto" back="#FAFAFA" mtop="0">
            <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
              <div className="marginComentLgSuccess1">
                {!showReasonsSelected ? (
                  <CardsBtn1
                    onClick={(e) => handleReasons(e)}
                    windthmd="100%"
                    mleft="18.5%"
                  >
                    Continuar
                  </CardsBtn1>
                ) : null}
              </div>
              <TrainingQuestion
                className={alertSecond === true ? '' : 'hidden'}
              >
                <ErrorDiv align="center">
                  Aún no seleccionas tus 3 frases.
                </ErrorDiv>
              </TrainingQuestion>
            </TrainingDivSection2>
          </TrainingSectionGray>
          <div id="activeFrasesSelected"></div>
          <CardTwo
            classCard={
              purpose.length === 6 && showReasonsSelected
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={purpose[3]}
            text2={purpose[4]}
            text3={purpose[5]}
            colorTraining={trainingColor}
          />
        </>
      ) : null}
      {purpose.length === 6 && showReasonsSelected ? (
        <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
          <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="0rem">
            <ImageCoachInicio topMobile="35px" />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        </TrainingSectionGray>
      ) : null}
    </div>
  );
};

export default Reasons;
