/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { useMutation, useQuery } from '@apollo/client';
import { SAVE_STATUS_TRAINING, INSERT_ROUTINE } from '../../../mutations';
import { GET_ROUTINES_BY_ID_TRAINING } from '../../../queries';
import GeneralHooks from '../../../Helpers/HookHelpersGeneral';

let inputRutine = {
  idUser: '',
  idCont: '',
  email: '',
  idGa: '',
  cellphone: '',
  rutine: [],
};
const CcStep_1 = React.memo(() => {
  const { actions } = GeneralHooks();
  const history = useHistory();
  let routines = [];
  let currentRoutine = {};
  const curretTraining = JSON.parse(sessionStorage.getItem('currentTraining'));
  /** [useQuery sirve para gardar las preguntas de la rutina]
   * [variables description]
   * @type {Object}
   */
  const { data, loading, error } = useQuery(GET_ROUTINES_BY_ID_TRAINING, {
    variables: {
      idTraining:
        curretTraining === null ? 1 : parseInt(curretTraining.idTrainning),
      orderRutine: 1,
    },
  });
  /**
   * [useMutation guardar el estatus de la rutina]
   * @param  {[type]} SAVE_STATUS_TRAINING [description]
   * @param  {[type]} {		onCompleted({   saveStatusTraining }) {			console.log(saveStatusTraining);			if (saveStatusTraining.statusCode [description]
   * @return {[type]}                      [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      if (saveStatusTraining.statusCode === 200) {
        sessionStorage.setItem('modalCCcomparativo', true);
        history.push(
          '/coach/training/adquieretutarjetadecredito/twinfunnel/step2-comparativo',
        );
      }
    },
  });

  /**
   * [useMutation mutacion para insertar los valores]
   * @param  {[type]} INSERT_ROUTINE_TWIN_FUNNEL_CC [description]
   * @param  {[type]} {			onCompleted({           insertRoutine }) {				if (insertRoutine.message [description]
   * @return {[type]}                               [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      const jsonResponse = JSON.parse(insertRoutine.response);
      console.log(jsonResponse);

      if (insertRoutine.message === 'usuario existente') {
        // validar que no este logueado
        sessionStorage.setItem('initLogin', false);
        console.log(
          sessionStorage.getItem('idUser'),
          sessionStorage.getItem('idDatSession'),
        );
        if (
          sessionStorage.getItem('idUser') == null
          && sessionStorage.getItem('idDatSession') == null
        ) {
          console.log('existe el usuario pero no esta logueado');
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('initLogin', true);
          sessionStorage.setItem('modalCCcomparativo', true);
          history.push('./login-cc');
        } else {
          console.log('existe el usuario pero  esta logueado');
          sessionStorage.setItem('modalCCcomparativo', true);
          history.push(
            '/coach/training/adquieretutarjetadecredito/twinfunnel/step2-comparativo',
          );
        }
      } else {
        sessionStorage.setItem('idUser', jsonResponse.idUser);
        sessionStorage.setItem('idCont', jsonResponse.idCont);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
        localStorage.setItem('idUser', jsonResponse.idUser);
        localStorage.setItem('idCont', jsonResponse.idCont);
        localStorage.setItem('idDatSession', jsonResponse.idSession);
        const inputStatusTraining = {
          idRoutine: String(currentRoutine.IDCatRoutine),
          idTraining: String(curretTraining.idTrainning),
          idUser: sessionStorage.getItem('idUser'),
          statusTraining: 'En progreso',
          statusRoutine: 'En progreso',
          collaborativeTraining: 'Individual',
          collaborativeRoutine: 'Individual',
          idDatRelAction: sessionStorage.getItem('idCatRelAction'),
          AchievmentTraining: '0',
          AchievmentRoutine: '0',
          isMaster: '1',
        };
        console.log(JSON.stringify(inputStatusTraining));
        saveStatusTraining({
          variables: { input: inputStatusTraining },
        });
      }
    },
  });

  const [form, setForm] = useState({
    firstName: sessionStorage.getItem('firstName') || '',
    middleName: sessionStorage.getItem('middleName') || '',
    fathersLastName: sessionStorage.getItem('fathersLastName') || '',
    mothersLastName: sessionStorage.getItem('mothersLastName') || '',
    email: sessionStorage.getItem('email') || '',
    CellPhone: sessionStorage.getItem('CellPhone') || '',
    options_years: '',
    options_month: '',
    options_day: '',
    options_ing: '',
  });

  useEffect(() => {
    if (data) {
      routines = JSON.parse(data.getRoutinesByIdTraining);
      for (const i in routines) {
        currentRoutine = routines[i];
        console.log(currentRoutine);
      }
    }
  }, [data, currentRoutine]);

  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const createJsonInput = () => {
    localStorage.setItem('activityDate', new Date());
    const id = document.querySelectorAll("[id^='q_']"); // [myAttribute=value]
    let numQuestion = 0;
    id.forEach((item, index) => {
      if (item.id.indexOf('q_') !== -1 && !(item.id.indexOf('-label') !== -1)) {
        numQuestion = parseInt(item.id.split('_')[1]) - 1;
        const question = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = question.IDCatQuestions;
        rutine.datUserDetailStorage = question.DatUserDetailStorage;
        rutine.questionType = question.QuestionType;
        if (question.QuestionType === 'Cat') {
          rutine.catFieldDetail = getFielOption(
            question.detailQuestion,
            item.value,
          );
        } else {
          rutine.value = item.value;
        }
        console.log(rutine);

        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      }
    });
    const qFechaNacimiento = {
      questionId: '',
      datUserDetailStorage: '',
      questionType: '',
      value: '',
      // catFieldDetail: {},
    };
    for (const i in currentRoutine.questions) {
      const quest = currentRoutine.questions[i];

      if (quest.QuestionName.indexOf('nacimiento') !== -1) {
        qFechaNacimiento.questionId = quest.IDCatQuestions;
        qFechaNacimiento.datUserDetailStorage = quest.DatUserDetailStorage;
        qFechaNacimiento.questionType = quest.QuestionType;
        qFechaNacimiento.value = `${sessionStorage.getItem(
          'options_years',
        )}-${sessionStorage.getItem('options_month')}-${sessionStorage.getItem(
          'options_day',
        )}`;

        inputRutine.rutine[parseInt(numQuestion) + 1] = qFechaNacimiento;
      }
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  const handleChange = (event) => {
    const { name } = event.target;
    sessionStorage.setItem(name, event.target.value);
    setForm({
      ...form,
      [name]: event.target.value,
    });
  };

  const guardar = (e) => {
    e.preventDefault();
    console.log(
      sessionStorage.getItem('email'),
      sessionStorage.getItem('CellPhone'),
    );
    inputRutine.email = sessionStorage.getItem('email');
    inputRutine.cellphone = sessionStorage.getItem('CellPhone');
    inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
    inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
    inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
    createJsonInput();
    const newRutine = inputRutine.rutine.filter(() => true);

    inputRutine.rutine = newRutine;
    console.log(JSON.stringify(inputRutine));
    if (sessionStorage.getItem('idDatSession')) {
      inputRutine.idMysql = parseInt(sessionStorage.getItem('idDatSession'));
    }
    insertRoutine({ variables: { input: inputRutine } });
    inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
  };

  return (
    <Fragment>
      <form
        autoComplete="off"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          id="q_1"
          style={{ margin: '10px' }}
          label="Primer Nombre"
          variant="outlined"
          name="firstName"
          value={form.firstName}
          onChange={handleChange}
        />
        <TextField
          id="q_2"
          label="Segundo nombre (opcional)"
          variant="outlined"
          name="middleName"
          value={form.middleName}
          onChange={handleChange}
        />
        <TextField
          id="q_3"
          style={{ margin: '10px' }}
          label="Apellido paterno"
          variant="outlined"
          name="fathersLastName"
          value={form.fathersLastName}
          onChange={handleChange}
        />
        <TextField
          id="q_4"
          label="Apellido materno"
          variant="outlined"
          name="mothersLastName"
          value={form.mothersLastName}
          onChange={handleChange}
        />
        <TextField
          id="q_6"
          style={{ margin: '10px' }}
          label="Correo electronico"
          variant="outlined"
          name="email"
          value={form.email}
          onChange={handleChange}
        />
        <TextField
          id="q_7"
          label="Telefono celular"
          variant="outlined"
          name="CellPhone"
          value={form.cellphone}
          onChange={handleChange}
        />
        <div>
          <p style={{ margin: '10px', color: 'black' }}>
            <b>Fecha de nacimiento</b>
          </p>
          <FormControl variant="outlined" style={{ margin: '10px' }}>
            <InputLabel>Año</InputLabel>
            <Select
              native
              value={form.options_years}
              onChange={handleChange}
              label="year"
              inputProps={{
                name: 'options_years',
                id: 'outlined-year-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value={2002}>2002</option>
              <option value={2001}>2001</option>
              <option value={2000}>2000</option>
              <option value={1999}>1999</option>
              <option value={1998}>1998</option>
              <option value={1997}>1997</option>
              <option value={1996}>1996</option>
              <option value={1995}>1995</option>
              <option value={1994}>1994</option>
              <option value={1993}>1993</option>
              <option value={1992}>1992</option>
              <option value={1991}>1991</option>
              <option value={1990}>1990</option>
              <option selected value={1989}>
                1989
              </option>
              <option value={1988}>1988</option>
              <option value={1987}>1987</option>
              <option value={1986}>1986</option>
              <option value={1985}>1985</option>
              <option value={1984}>1984</option>
              <option value={1983}>1983</option>
              <option value={1982}>1982</option>
              <option value={1981}>1981</option>
              <option value={1980}>1980</option>
              <option value={1979}>1979</option>
              <option value={1978}>1978</option>
              <option value={1977}>1977</option>
              <option value={1976}>1976</option>
              <option value={1975}>1975</option>
              <option value={1974}>1974</option>
              <option value={1973}>1973</option>
              <option value={1972}>1972</option>
              <option value={1971}>1971</option>
              <option value={1970}>1970</option>
              <option value={1969}>1969</option>
              <option value={1968}>1968</option>
              <option value={1967}>1967</option>
              <option value={1966}>1966</option>
              <option value={1965}>1965</option>
              <option value={1964}>1964</option>
              <option value={1963}>1963</option>
              <option value={1962}>1962</option>
              <option value={1961}>1961</option>
              <option value={1960}>1960</option>
              <option value={1959}>1959</option>
              <option value={1958}>1958</option>
              <option value={1957}>1957</option>
              <option value={1956}>1956</option>
              <option value={1955}>1955</option>
              <option value={1954}>1954</option>
              <option value={1953}>1953</option>
              <option value={1952}>1952</option>
              <option value={1951}>1951</option>
              <option value={1950}>1950</option>
              <option value={1949}>1949</option>
              <option value={1948}>1948</option>
              <option value={1947}>1947</option>
              <option value={1946}>1946</option>
              <option value={1945}>1945</option>
              <option value={1944}>1944</option>
              <option value={1943}>1943</option>
              <option value={1942}>1942</option>
              <option value={1941}>1941</option>
              <option value={1940}>1940</option>
              <option value={1939}>1939</option>
              <option value={1938}>1938</option>
              <option value={1937}>1937</option>
              <option value={1936}>1936</option>
              <option value={1935}>1935</option>
              <option value={1934}>1934</option>
              <option value={1933}>1933</option>
              <option value={1932}>1932</option>
              <option value={1931}>1931</option>
              <option value={1930}>1930</option>
              <option value={1929}>1929</option>
              <option value={1928}>1928</option>
              <option value={1927}>1927</option>
              <option value={1926}>1926</option>
              <option value={1925}>1925</option>
              <option value={1924}>1924</option>
              <option value={1923}>1923</option>
              <option value={1922}>1922</option>
              <option value={1921}>1921</option>
              <option value={1920}>1920</option>
              <option value={1919}>1919</option>
              <option value={1918}>1918</option>
              <option value={1917}>1917</option>
              <option value={1916}>1916</option>
              <option value={1915}>1915</option>
              <option value={1914}>1914</option>
              <option value={1913}>1913</option>
              <option value={1912}>1912</option>
              <option value={1911}>1911</option>
              <option value={1910}>1910</option>
              <option value={1909}>1909</option>
              <option value={1908}>1908</option>
              <option value={1907}>1907</option>
              <option value={1906}>1906</option>
              <option value={1905}>1905</option>
              <option value={1904}>1904</option>
              <option value={1903}>1903</option>
              <option value={1902}>1902</option>
              <option value={1901}>1901</option>
              <option value={1900}>1900</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ margin: '10px' }}>
            <InputLabel>Mes</InputLabel>
            <Select
              native
              value={form.options_month}
              onChange={handleChange}
              label="month"
              inputProps={{
                name: 'options_month',
                id: 'outlined-month-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value={1}>Ene</option>
              <option value={2}>Feb</option>
              <option value={3}>Mar</option>
              <option value={4}>Abr</option>
              <option value={5}>May</option>
              <option value={6}>Jun</option>
              <option value={7}>Jul</option>
              <option value={8}>Ago</option>
              <option value={9}>Sep</option>
              <option value={10}>Oct</option>
              <option value={11}>Nov</option>
              <option value={12}>Dic</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ margin: '10px' }}>
            <InputLabel>Día</InputLabel>
            <Select
              native
              value={form.options_day}
              onChange={handleChange}
              label="Día"
              inputProps={{
                name: 'options_day',
                id: 'outlined-day-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
              <option value={21}>21</option>
              <option value={22}>22</option>
              <option value={23}>23</option>
              <option value={24}>24</option>
              <option value={25}>25</option>
              <option value={26}>26</option>
              <option value={27}>27</option>
              <option value={28}>28</option>
              <option value={29}>29</option>
              <option value={30}>30</option>
              <option value={31}>31</option>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControl
            variant="outlined"
            style={{ margin: '10px', marginLeft: '25px', width: '100%' }}
          >
            <InputLabel>Selecciona tu ingreso</InputLabel>
            <Select
              id="q_3"
              native
              value={form.options_ing}
              onChange={handleChange}
              label="Selecciona tu ingreso"
              inputProps={{
                name: 'options_ing',
                id: 'q_8',
              }}
            >
              <option aria-label="None" value="" />
              <option value="">Selecciona tu ingreso</option>
              <option value="3">Menos de $4,000</option>
              <option value="4">De $4,000 a $7,000</option>
              <option value="5">De $7,000 a $15,000</option>
              <option value="6">De $15,000 a $30,000</option>
              <option value="7">Mas de $30,000</option>
            </Select>
          </FormControl>
          <Button
            style={{
              margin: '10px',
              marginBottom: '200px',
              marginLeft: '25px',
              width: '100%',
            }}
            onClick={(e) => {
              guardar(e);
            }}
            variant="contained"
            color="secondary"
          >
            Aceptar
          </Button>
        </div>
      </form>
    </Fragment>
  );
});

export default CcStep_1;
