import React from 'react';

export default class GoogleAutoComplete extends React.Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);
    this.state = {
      autocomplete: {},
    };
  }

  componentDidMount() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete((this.refs.autoCompletePlaces), { types: ['geocode'] });
    autocomplete.setComponentRestrictions(
      { country: ['mx'] },
    );
    autocomplete.addListener('place_changed', () => {
      const componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
      };
      const place = autocomplete.getPlace();
      for (const component in componentForm) {
        document.getElementById(component).bind = '';
        document.getElementById(component).disabled = false;
      }
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          const val = place.address_components[i][componentForm[addressType]];
          document.getElementById(addressType).value = val;
        }
      }
    });
    this.setState({ autocomplete });
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        //! never used
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }
  }

  render() {
    const styleInput = {
      width: '100%',
      'margin-bottom': '10px',
    };
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('#autocomplete').forEach((node) => node.addEventListener('keypress', (e) => {
        document.getElementById('street_number').value = '';
        document.getElementById('route').value = '';
        document.getElementById('locality').value = '';
        document.getElementById('administrative_area_level_1').value = '';
        document.getElementById('postal_code').value = '';
        document.getElementById('country').value = '';
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      }));
    });
    return (
      <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <form>
            <div id="locationField" autoComplete="off" >
              <input style={styleInput}
                autoComplete="off"
                id="autocomplete"
                placeholder="Ingresa tu direccion"
                onFocus={this.geolocate}
                onChange={this.handleInputChange}
                ref = {(ref) => this.autoCompletePlaces = ref}
              />
            </div>
          </form>
          <table id="address" style={styleInput}>
            <tbody>
              <tr>
                <td>
                  <input
                    placeholder="Direccion 1"
                    id="street_number"
                    disabled={true} />
                </td>
                <td>
                  <input
                    placeholder="Direccion 2"
                    id="route"
                    disabled={true} />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    placeholder="Ciudad"
                    id="locality"
                    disabled={true} />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    placeholder="Estado"
                    id="administrative_area_level_1"
                    disabled={true} />
                </td>
                <td>
                  <input
                    placeholder="Codigo postal"
                    id="postal_code"
                    disabled={true} />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    placeholder="Pais"
                    id="country"
                    disabled={true} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-4"></div>
      </div>
    );
  }
}
