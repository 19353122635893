import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import Modal from "@material-ui/core/Modal";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { CREATE_MAGIC_LINK } from '../../../../mutations';
import {
  Button,
  ButtonAlt,
  ParagrapTwo,
  SubtitleOne,
} from "../../../../components/common/common.styled";
// Modal
import { getQueryVariable } from "../../../../Helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "380px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    borderRadius: "5px",
  },
}));
function Modallogin({
  open,
  setopen,
  childrent,
  setChildrend,
  imagen,
  textBtnPrincipal = "",
  settextBtnPrincipal,
  textBtnSeccon = "",
  settextBtnSeccon,
  isBlockedC,
  objectSession,
  procedure = "registro",
  setisBlockedC,
}) {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [createMagicLink] = useMutation(CREATE_MAGIC_LINK, {
    onCompleted({ createMagicLink }) {
      try {
        const { response, message, error } = createMagicLink;
        if (message === "success") {
          // setIsVerifiedUser(true);
          setChildrend("En un par de minutos recibirás un enlace para que inicies sesión.");
          settextBtnPrincipal("Entendido");
          settextBtnSeccon("");
          setisBlockedC(false);
          setopen(true);
          console.log('Enviado exitosamente');
        } else {
          console.log('Error!!!', error);
          // setIsVerifiedUser(false);
          setChildrend(
            "Los datos ingresados no corresponden a un usuario registrado. Por favor verifique los datos."
          );
          settextBtnPrincipal("Entendido");
          settextBtnSeccon("");
          setisBlockedC(false);
          setopen(true);
          // // Aquí se manda a llamar el mensaje cuando no existe el usuario en BD
        }
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const irInicio = () => {
    window.location.href = '/login-v2';
  };

  const sendMagicLink = () => {
    createMagicLink({
      variables: {
        text: procedure === 'registro' ? objectSession.email : objectSession.text,
        type: procedure === 'registro' ? 'email' : objectSession.type,
        origin: window.location.href
      },
    });
    setopen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div style={modalStyle} className={classes.paper}>
        <SubtitleOne
          color="red"
          mt="3%"
          className="font-weight-bold"
          sizeMobile="20px"
          size="20px"
          textalingn="center"
          textalingnMobile="center"
        >
          {childrent !== "En un par de minutos recibirás un enlace para que inicies sesión."
            ? (<ErrorOutlineIcon style={{ fontSize: 70, color: '#FF6969' }}/>) : <>✨</> }
        </SubtitleOne>
        <ParagrapTwo
          fontSize="12px"
          fontSizeWeb="12px"
          className="mx-auto roboto"
          mb="24px"
          mbweb="24px"
          textalignMobile="center"
          texttalign="center">
          {childrent}
        </ParagrapTwo>
        {isBlockedC
          ? <Button
            id="btnIniciar"
            mt="0px"
            type="submit"
            className="d-block roboto"
            borderRadius="16px"
            bg="#fff"
            border="1px solid #F64282"
            color="#fb245c"
            onClick={sendMagicLink}
          >
            Ingresar con enlace ✨
          </Button>
          : null}
        {textBtnPrincipal !== "" && (
          <Button
            id="btnIniciar"
            mt="0px"
            type="submit"
            className="d-block roboto"
            borderRadius="16px"
            onClick={textBtnPrincipal === "Iniciar sesión" ? () => irInicio() : () => { setopen(false); }}>
            {textBtnPrincipal}
          </Button>
        )}
        {textBtnSeccon !== "" && (
          <ButtonAlt
            id="btnContrasena"
            mt="24px"
            type="submit"
            className="d-block roboto"
            borderRadius="16px">
            {textBtnSeccon}
          </ButtonAlt>
        )}
      </div>
    </Modal>
  );
}

export default Modallogin;
