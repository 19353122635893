/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import NVD3Chart from 'react-nvd3';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  CardsBtn1,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_ALL_USERS_CORU_RANKING,
} from '../../queries';
import {
  INSERT_DAT_INVESTMENT_PORTAFOLIOS,
} from '../../mutations';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import { addDaysToDate } from '../../Helpers/general';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
// imagenes
import { Image } from '../../components/common/common.styled';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import logoCoru from '../../assets/img/anacoach/logoCoruGrafico.png';
import coruRanking from '../../assets/img/anacoach/coruRanking.svg';
import ClickButton from '../../Helpers/HookHelpers';

const MoneyToInvest = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  const history = useHistory();
  const styles = {
    fontStyle: 'normal',
    fontSize: '15px',
    fontWeight: 'normal',
    lineHeight: '120%',
  };
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const anchop = window.screen.width;
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [availableInvestement, setAvailableInvestement] = useState('$1,000,000');
  const [Rutine, setRutine] = useState({});
  const [continues, setContinues] = useState(false);
  const [portafolios] = useState([]);
  const [graph, setGraph] = useState(false);
  const [datum] = useState([]);
  const [goFeed] = useState(false);
  const [colorsGraph] = useState([
    '#91D770',
    '#F64282',
    '#6BD5AF',
    '#6CA3FB',
    '#DDB439',
    '#6BD26F',
    '#25B4F8',
    '#7627DE',
    '#F5A82C',
    '#D5CC2A',
  ]);
  const [showCoruRanking, setShowCoruRanking] = useState(false);
  const [allUsersRanking, setallUsersRanking] = useState([]);
  const [goShareOrExit, setGoShareOrExit] = useState('');
  const [insertPort, setInsertPort] = useState(
    sessionStorage.getItem('cantidadInversionesAgragadas') || true,
  );
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalPercent, setTotalPercent] = useState(0);
  const [validityDate, setValidityDate] = useState('');
  const [disabledBtn, setdisabledBtn] = useState(true);
  const [colorBtn, setcolorBtn] = useState('#A6A6A6');
  const [recommendedTraining] = useState([
    {
      IDCatTraining: 18,
      TrainingBigTitle: 'Catálogo de inversiones',
      TrainingColor: '#99F2A8',
      TrainingRedirectUrl: '/wo/investment-catalog',
      TrainingTime: 20,
      UrlBigImage:
        'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/routines/C%3A%5Cfakepath%5CWO_Catalogo_Inversiones.svg',
      UrlMediumImage: null,
    },
    {
      IDCatTraining: 17,
      TrainingBigTitle: 'Tu primera inversión',
      TrainingColor: '#6BD5AF',
      TrainingRedirectUrl: '/wo/first-investment',
      TrainingTime: 20,
      UrlBigImage:
        '	https://resources-new-coru.s3.us-east-2.amazonaws.com/images/routines/C%3A%5Cfakepath%5Cinversion1.svg',
      UrlMediumImage: null,
    },
    {
      IDCatTraining: 23,
      TrainingBigTitle: 'Test de inversión',
      TrainingColor: '#91D770',
      TrainingRedirectUrl: '/wo/investment-test',
      TrainingTime: 10,
      UrlBigImage:
        'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CTestInversion.svg',
      UrlMediumImage: null,
    },
  ]);
  const [totalinstruments, settotalinstruments] = useState(0);

  useEffect(() => {
    const AccionesNacionales = sessionStorage.getItem('AccionesNacionales') !== ''
      && sessionStorage.getItem('AccionesNacionales') !== undefined
      ? sessionStorage.getItem('AccionesNacionales').split(',').length
      : 0;
    const AccionesInternacionales = sessionStorage.getItem('AccionesInternacionales') !== ''
      && sessionStorage.getItem('AccionesInternacionales') !== undefined
      ? sessionStorage.getItem('AccionesInternacionales').split(',').length
      : 0;
    const Cetes = sessionStorage.getItem('Cetes') !== ''
      && sessionStorage.getItem('Cetes') !== undefined
      ? sessionStorage.getItem('Cetes').split(',').length
      : 0;
    const ETFs = sessionStorage.getItem('ETFs') !== ''
      && sessionStorage.getItem('ETFs') !== undefined
      ? sessionStorage.getItem('ETFs').split(',').length
      : 0;
    const Fibras = sessionStorage.getItem('Fibras') !== ''
      && sessionStorage.getItem('Fibras') !== undefined
      ? sessionStorage.getItem('Fibras').split(',').length
      : 0;
    const FondoInversion = sessionStorage.getItem('FondoInversion') !== ''
      && sessionStorage.getItem('FondoInversion') !== undefined
      ? sessionStorage.getItem('FondoInversion').split(',').length
      : 0;
    const Metales = sessionStorage.getItem('Metales') !== ''
      && sessionStorage.getItem('Metales') !== undefined
      ? sessionStorage.getItem('Metales').split(',').length
      : 0;
    const sumaTotal = AccionesNacionales
      + AccionesInternacionales
      + Cetes
      + ETFs
      + Fibras
      + FondoInversion
      + Metales;
    settotalinstruments(sumaTotal);
  }, []);

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertInvestmentPortafolio] = useMutation(
    INSERT_DAT_INVESTMENT_PORTAFOLIOS,
    {
      onCompleted({ insertInvestmentPortafolio }) {
        if (insertInvestmentPortafolio.message === 'success') {
          sessionStorage.setItem('cantidadInversionesAgragadas', false);
          setInsertPort(false);
        }
      },
    },
  );
  const [getAllUsersCoruRanking] = useLazyQuery(GET_ALL_USERS_CORU_RANKING, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ getAllUsersCoruRanking }) {
      if (getAllUsersCoruRanking.message === 'success') {
        const resp = JSON.parse(getAllUsersCoruRanking.response);
        resp.sort((a, b) => (a.amountInvestmentTotal > b.amountInvestmentTotal ? -1 : 1));
        const path = window.location.hostname;
        if (path === 'coru.com') {
          const orderByEmail = resp.filter(({ email }) => {
            if (!email.endsWith('@test.com')) return email;
          });
          setallUsersRanking(orderByEmail);
        } else {
          setallUsersRanking(resp);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);
  useEffect(() => {
    if (
      sessionStorage.getItem('NuevaInversion') !== 'true'
      && sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  // States de preguntas

  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };

  const [form, setForm] = useState({
    CuantoInvertirAeromexico:
      sessionStorage.getItem('CuantoInvertirAeroméxico') || '',
    CuantoInvertirBimbo: sessionStorage.getItem('CuantoInvertirBimbo') || '',
    CuantoInvertirAmazon: sessionStorage.getItem('CuantoInvertirAmazon') || '',
    CuantoInvertirFacebook:
      sessionStorage.getItem('CuantoInvertirFacebook') || '',
    CuantoInvertirCetes: sessionStorage.getItem('CuantoInvertirCetes') || '',
    CuantoInvertirSPY: sessionStorage.getItem('CuantoInvertirSPY') || '',
    CuantoInvertirUSMV: sessionStorage.getItem('CuantoInvertirUSMV') || '',
    CuantoInvertirFUNO: sessionStorage.getItem('CuantoInvertirFUNO') || '',
    CuantoInvertirCPODX: sessionStorage.getItem('CuantoInvertirCPODX') || '',
    CuantoInvertirOro: sessionStorage.getItem('CuantoInvertirOro') || '',
  });
  const [formAux, setFormAux] = useState({
    Acumulado: sessionStorage.getItem('Acumulado') || '',
  });
  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const handleChange = (e) => {
    console.log('handleChange >>>> ', e.target.name, e.target.value);
    const { name, value } = e.target;
    let names = '';
    names = String(name);
    const val = value.replace('$', '').replace(/,/g, '').trim();
    console.log('antes de set >>>> ', val);
    setForm({
      ...form,
      [name]: formatter.format(filterFloat(val)),
    });
    console.log('totalinstruments >>>> ', totalinstruments);
    ValidValores();
  };
  const [totalIngresado, settotalIngresado] = useState(0);
  const ValidValores = () => {
    let totalIngresados = 0;

    if (form.CuantoInvertirAeromexico !== '') {
      totalIngresados++;
    }

    if (form.CuantoInvertirAmazon !== '') {
      totalIngresados++;
    }

    if (form.CuantoInvertirBimbo !== '') {
      totalIngresados++;
    }

    if (form.CuantoInvertirCPODX !== '') {
      totalIngresados++;
    }
    if (form.CuantoInvertirCetes !== '') {
      totalIngresados++;
    }
    if (form.CuantoInvertirFUNO !== '') {
      totalIngresados++;
    }
    if (form.CuantoInvertirFacebook !== '') {
      totalIngresados++;
    }
    if (form.CuantoInvertirOro !== '') {
      totalIngresados++;
    }
    if (form.CuantoInvertirSPY !== '') {
      totalIngresados++;
    }
    if (form.CuantoInvertirUSMV !== '') {
      totalIngresados++;
    }

    settotalIngresado(totalIngresados);
    let quitarformat = totalInvestment.toString().replace('$', '');
    quitarformat = quitarformat.replace(',', '');
    quitarformat = parseInt(quitarformat);

    console.log(
      `>>>> (${totalinstruments} - 1) === ${totalIngresado} && ${quitarformat} <= 1000000`,
    );

    let totalinstrumentsAux = totalinstruments;
    if (totalIngresado === 1) {
      totalinstrumentsAux--;
    }

    if (totalinstrumentsAux === totalIngresado && quitarformat <= 1000000) {
      setdisabledBtn(false);
      setcolorBtn('#F64282');
    } else {
      setdisabledBtn(true);
      setcolorBtn('#A6A6A6');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      console.log('Acumulado');
      const arrayForm = Object.values(form);
      let suma = 0;
      for (let i = 0; i < arrayForm.length; i++) {
        suma += convertirNum(arrayForm[i]);
      }
      if (suma !== 0 && suma !== '0') {
        const diff = 1000000 - suma;
        const result = formatter.format(filterFloat(diff));
        setAvailableInvestement(result);
        const val = formatter.format(filterFloat(suma));
        setFormAux({
          ...formAux,
          Acumulado: val,
        });
        sessionStorage.setItem(
          'Acumulado',
          formatter.format(filterFloat(suma)),
        );
        setTotalInvestment(formatter.format(filterFloat(suma)));
      }
    }, 500);
  }, [form]);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        break;
      default:
        console.log(val);
    }
  };

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  const handleGraph = (e) => {
    const click = `ContinuarDineroDisponible_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setGraph(!graph);
    getAllUsersCoruRanking({ variables: { idTraining: 27 } });
    gsap.to(window, { duration: 1, scrollTo: '#activeGraph' });
  };

  const handleShareOrExit = (e, action) => {
    const click = `CompartirOSalir_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setGoShareOrExit(action);
    if (action === 'compartir') {
      gsap.to(window, { duration: 1, scrollTo: '#activeShareInv' });
    }
  };
  const handleContinues = (e) => {
    const click = `ContinuarCantidadInversion_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setContinues(true);
    const arrayValues = Object.values(form);
    const arrayKeys = Object.keys(form);
    let auxTotalPercent = 0;
    for (let i = 0; i < arrayKeys.length; i++) {
      if (arrayValues[i] !== '') {
        const val = convertirNum(arrayValues[i]);
        const percent = ((val * 100) / 1000000).toFixed(1);
        const aux = parseFloat(percent);
        auxTotalPercent += aux;
        portafolios.push({
          name: arrayKeys[i].replace('CuantoInvertir', ''),
          val: arrayValues[i],
          percent,
          colorGp: colorsGraph[i],
        });
        datum.push({
          key: arrayKeys[i].replace('CuantoInvertir', ''),
          y: percent,
          color: colorsGraph[i],
        });
      }
    }
    setTotalPercent(auxTotalPercent);

    console.log('PORTAFOLIOS', portafolios);
    const idUser = parseInt(sessionStorage.getItem('idUser'));
    const idTraining = parseInt(sessionStorage.getItem('idTraining'));
    if (!isEmptyObject(Rutine)) {
      if (insertPort !== 'false') {
        const insertPortafolio = {
          input: {
            namePortafolio: `${sessionStorage.getItem(
              'FirstName',
            )} ${sessionStorage.getItem('FathersLastName')}`,
            idUser,
            idTraining,
            amount: sessionStorage.getItem('Acumulado'),
            amountByInstruments: [],
          },
        };
        const inversiones = [
          'Aeroméxico',
          'Bimbo',
          'Amazon',
          'Facebook',
          'Cetes',
          'SPY',
          'USMV',
          'FUNO',
          'CPODX',
          'Oro',
        ];
        for (let i = 0; i < inversiones.length; i++) {
          if (form[`CuantoInvertir${inversiones[i]}`] !== '') {
            insertPortafolio.input.amountByInstruments.push({
              name: inversiones[i],
              amount: form[`CuantoInvertir${inversiones[i]}`],
            });
          }
        }
        insertInvestmentPortafolio({ variables: insertPortafolio });
      }
      if (form.CuantoInvertirAeromexico !== '') {
        questions.push({
          id: 'CuantoInvertirAeromexico',
          question: 1,
          value: form.CuantoInvertirAeromexico,
        });
      }
      if (form.CuantoInvertirBimbo !== '') {
        questions.push({
          id: 'CuantoInvertirBimbo',
          question: 2,
          value: form.CuantoInvertirBimbo,
        });
      }
      if (form.CuantoInvertirAmazon !== '') {
        questions.push({
          id: 'CuantoInvertirAmazon',
          question: 3,
          value: form.CuantoInvertirAmazon,
        });
      }

      if (form.CuantoInvertirFacebook !== '') {
        questions.push({
          id: 'CuantoInvertirFacebook',
          question: 4,
          value: form.CuantoInvertirFacebook,
        });
      }
      if (form.CuantoInvertirCetes !== '') {
        questions.push({
          id: 'CuantoInvertirCetes',
          question: 5,
          value: form.CuantoInvertirCetes,
        });
      }
      if (form.CuantoInvertirSPY !== '') {
        questions.push({
          id: 'CuantoInvertirSPY',
          question: 6,
          value: form.CuantoInvertirSPY,
        });
      }

      if (form.CuantoInvertirUSMV !== '') {
        questions.push({
          id: 'CuantoInvertirUSMV',
          question: 7,
          value: form.CuantoInvertirUSMV,
        });
      }

      if (form.CuantoInvertirFUNO !== '') {
        questions.push({
          id: 'CuantoInvertirFUNO',
          question: 8,
          value: form.CuantoInvertirFUNO,
        });
      }

      if (form.CuantoInvertirCPODX !== '') {
        questions.push({
          id: 'CuantoInvertirCPODX',
          question: 9,
          value: form.CuantoInvertirCPODX,
        });
      }

      if (form.CuantoInvertirOro !== '') {
        questions.push({
          id: 'CuantoInvertirOro',
          question: 10,
          value: form.CuantoInvertirOro,
        });
      }

      if (formAux.Acumulado !== '') {
        questions.push({
          id: 'Acumulado',
          question: 11,
          value: formAux.Acumulado,
        });
      }

      if (availableInvestement !== '') {
        questions.push({
          id: 'disponibleinvertir',
          question: 12,
          value: availableInvestement,
        });
      }
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeContinuesInv' });
  };

  const handleCoruRanking = (e) => {
    const click = `ContinuarVerRendimiento_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const today = new Date();
    const day = `0${today.getDate()}`.slice(-2);
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const year = today.getFullYear();
    let valid = addDaysToDate(7, `${year}-${month}-${day}`);
    valid = valid.split('-').reverse().join('/');
    const validity = `Del ${day}/${month}/${year} al ${valid}`;
    setValidityDate(validity);
    setShowCoruRanking(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeCotnRanking' });
  };
  useEffect(() => {
    if (
      recoveryRoutine === 'activa'
      && sessionStorage.getItem('NuevaInversion') !== 'true'
    ) {
      setForm({
        CuantoInvertirAeromexico:
          sessionStorage.getItem('CuantoInvertirAeromexico') || '',
        CuantoInvertirBimbo:
          sessionStorage.getItem('CuantoInvertirBimbo') || '',
        CuantoInvertirAmazon:
          sessionStorage.getItem('CuantoInvertirAmazon') || '',
        CuantoInvertirFacebook:
          sessionStorage.getItem('CuantoInvertirFacebook') || '',
        CuantoInvertirCetes:
          sessionStorage.getItem('CuantoInvertirCetes') || '',
        CuantoInvertirSPY: sessionStorage.getItem('CuantoInvertirSPY') || '',
        CuantoInvertirUSMV: sessionStorage.getItem('CuantoInvertirUSMV') || '',
        CuantoInvertirFUNO: sessionStorage.getItem('CuantoInvertirFUNO') || '',
        CuantoInvertirCPODX:
          sessionStorage.getItem('CuantoInvertirCPODX') || '',
        CuantoInvertirOro: sessionStorage.getItem('CuantoInvertirOro') || '',
      });
      setFormAux({
        Acumulado: sessionStorage.getItem('Acumulado') || '',
      });
    }
  }, [recoveryRoutine]);

  const tamScreen = window.screen.width;

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeMoneyToInvest' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {!continues ? (
        <>
          <div id="activeMoneyToInvest"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuemos"
          />
          <TrainingDivSection
            backw="#F3F3F3"
            mlw="18%"
            wiw="60%"
            back="#F3F3F3"
            wixs="90%"
          >
            <TrainingQuestion
              className={
                sessionStorage
                  .getItem('AccionesNacionales')
                  .includes('Aeroméxico')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="0-0"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('AccionesNacionales').includes('Bimbo')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="1-1"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage
                  .getItem('AccionesInternacionales')
                  .includes('Amazon')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="2-2"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage
                  .getItem('AccionesInternacionales')
                  .includes('Facebook')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="3-3"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('Cetes').includes('Cetes a 30 días')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="4-4"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('ETFs').includes('SPY') ? '' : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="5-5"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('ETFs').includes('USMV') ? '' : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="6-6"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('Fibras').includes('FUNO11.MX')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="7-7"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('FondoInversion').includes('CPODX')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="8-8"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <TrainingQuestion
              className={
                sessionStorage.getItem('Metales').includes('Oro')
                  ? ''
                  : 'hidden'
              }
            >
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step="9-9"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <hr style={{ background: 'black' }} />
            <TrainingQuestion>
              <QuestionsFlex display="inline">
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step="10-10"
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={formAux}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  separateLabelOpenField={true}
                />
              </QuestionsFlex>
            </TrainingQuestion>
            <p style={{ margin: '20px', color: '#F64282' }}>
              Disponible para invertir {availableInvestement}
            </p>
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn
                id="btn2de monetTolInverst"
                back={colorBtn}
                disabled={disabledBtn}
                onClick={(e) => handleContinues(e)}
                stype="button"
                min_w="100%"
                min_web="100%"
                mtopw="19px"
                mtop="19px"
              >
                Continuar
              </CardsBtn>
              <br />
            </div>
          </TrainingDivSection>
        </>
      ) : (
        <>
          <div id="activeContinuesInv"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text2="Continuemos"
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text2="Tu inversión acumulada:"
          />
          <CardTwo
            classCard="cardTwoResp"
            classTop="topTwo1"
            imgMan="hidden"
            text={totalInvestment}
            colorTraining={trainingColor}
          />
        </>
      )}
      {continues ? (
        <Fragment>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="¡Excelente!"
            text2="Tu portafolio de inversión esta listo:"
          />
          <TrainingDivSection
            backw="#F3F3F3"
            mlw="18%"
            wiw="60%"
            back="#F3F3F3"
            wixs="90%"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '25px',
              }}
            >
              <div>
                <p>
                  <b>Instrumento</b>
                </p>
                {portafolios.map((item) => {
                  if (item.name === 'Aeromexico') {
                    return <p style={styles}>Aeroméxico</p>;
                  }
                  return <p style={styles}>{item.name}</p>;
                })}
                <hr
                  style={{
                    background: 'black',
                    width: tamScreen > 1200 ? '375%' : '340%',
                  }}
                />
                <p>Total</p>
              </div>
              <div>
                <p>
                  <b>Inversión</b>
                </p>
                {portafolios.map((item) => (
                  <p style={styles}>{item.val}</p>
                ))}
                <br />
                <p>{totalInvestment}</p>
              </div>
              <div>
                <p>
                  <b>%</b>
                </p>
                {portafolios.map((item) => (
                  <p style={styles}>{item.percent}%</p>
                ))}
                <br />
                <p>{totalPercent}%</p>
              </div>
            </div>
            <p style={{ margin: '20px', color: '#F64282' }}>
              Disponible para invertir {availableInvestement}
            </p>
          </TrainingDivSection>
          {!graph ? (
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn1
                id="btn1deMoneyToInvest"
                onClick={(e) => handleGraph(e)}
              >
                Continuar
              </CardsBtn1>
            </div>
          ) : null}
        </Fragment>
      ) : null}
      {graph ? (
        <Fragment>
          <div id="activeGraph"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuemos"
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Tu portafolio de inversión P001 ha quedado activo"
            text2="Esta es la distribución de tu portafolio:"
          />
          <TrainingDivSection
            wiw="60%"
            mlw="18%"
            mt="20px"
            hew="auto"
            paddw="26px"
            mtw="8%"
            back=" "
            // backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="10px"
            wi="90%"
            he="auto"
            mb="38px"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '30%',
              }}
            >
              <Image
                src={logoCoru}
                mwidth="35%"
                width="18%"
                margin="auto"
                top="110px"
                left="10%"
                mleft="0"
                mtop="75px"
              />
            </div>
            <NVD3Chart
              id="chart"
              showLegend={false}
              height={300}
              type="pieChart"
              datum={datum}
              x="key"
              y="y"
              donut
              labelType="none"
              containerStyle={{
                marginTop: '-185px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '25px',
              }}
            >
              <div style={{ marginTop: '-12px' }}>
                {portafolios.map((item) => (
                  <div
                    style={{
                      background: item.colorGp,
                      width: '25px',
                      height: '25px',
                      borderRadius: '4px',
                      marginTop: '10px',
                    }}
                  ></div>
                ))}
              </div>
              <div>
                {portafolios.map((item) => {
                  if (item.name === 'Aeromexico') {
                    return (
                      <p className="charSimulator" style={styles}>
                        Aeroméxico
                      </p>
                    );
                  }
                  return (
                    <p className="charSimulator" style={styles}>
                      {item.name}
                    </p>
                  );
                })}
              </div>
              <div>
                {portafolios.map((item) => (
                  <p className="charSimulator" style={styles}>
                    {item.percent}%
                  </p>
                ))}
              </div>
            </div>
          </TrainingDivSection>
          <div id="activeCotnRanking"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Recuerda que puedes consultar diariamente para conocer los rendimientos de tu inversión"
          />
          {!showCoruRanking ? (
            <div style={{ display: 'flex', width: '100%' }}>
              <CardsBtn1 onClick={(e) => handleCoruRanking(e)}>
                Continuar
              </CardsBtn1>
            </div>
          ) : null}
          {showCoruRanking ? (
            <Fragment>
              <div className="marginComentLgSuccess1">
                <p
                  style={{
                    fontFamily: 'Cabin',
                    fontSize: '20px',
                    fontWeight: 700,
                    marginLeft: '18%',
                    marginTop: '30px',
                    width: '60%',
                  }}
                >
                  CoruRanking
                </p>
                <p
                  style={{
                    fontFamily: 'Cabin',
                    fontSize: '16px',
                    marginLeft: '18%',
                    marginTop: '-10px',
                    width: '60%',
                  }}
                >
                  {validityDate}
                </p>
                <Image
                  src={coruRanking}
                  displayMobile="flex"
                  mtMob="90px"
                  displayWeb="flex"
                  mwidth="85%"
                  width="55%"
                  margin="auto"
                  top="110px"
                  left="10%"
                  mleft="0"
                  marginTop="50px"
                />
                <div
                  style={{
                    width: '60%',
                    marginLeft: '18%',
                    marginTop: '50px',
                    overflowY: 'scroll',
                    padding: '15px',
                    height: '250px',
                    boxShadow: '0px 2px 48px rgba(0, 0, 0, 0.08)',
                    borderRadius: '10px',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {allUsersRanking.map((item, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <p style={{ width: '20px' }}>{`${index + 1}.`}</p>
                          <p style={{ width: '120px' }}>{item.nameComplete}</p>
                          <p style={{ width: '40px' }}>
                            {`${(
                              String(item.nameComplete).split('')[0]
                              + String(item.nameComplete)
                                .split(' ')[1]
                                .split('')[0]
                            ).toUpperCase()}001`}
                          </p>
                          <p style={{ width: '50px' }}>
                            {formatter.format(
                              filterFloat(item.amountInvestmentTotal),
                            )}
                          </p>
                        </div>
                        <hr style={{ width: '100%', background: '#CDCDCD' }} />
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', width: '100%' }}>
                <CardsBtn1 onClick={(e) => handleShareOrExit(e, 'compartir')}>
                  Compartir
                </CardsBtn1>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                <CardsBtn
                  bordcolor="#F64282"
                  color="#F64282"
                  back="#FFFFFF"
                  border="solid 1px "
                  type="button"
                  min_w="100%"
                  windthmd="90%"
                  windthLg="60%"
                  mleftweb="18%"
                  mtopw="19px"
                  mtop="19px"
                  onClick={(e) => handleShareOrExit(e, 'salir')}
                >
                  Salir
                </CardsBtn>
              </div>
              {goShareOrExit === 'compartir' ? (
                <Fragment>
                  <ModalAmazon show={true} wo={'SimuladordeIn'}></ModalAmazon>
                  <div id="activeShareInv"></div>
                  <SectionShare WO="SimuladordeIn"></SectionShare>
                </Fragment>
              ) : null}
              {goShareOrExit === 'salir' ? (
                <Fragment>
                  <CardOne
                    classCard="cardOne"
                    classTop="topOne1"
                    text="Durante esta semana puedes seguir ejercitándote con los siguientes entrenamientos:"
                  />
                  <div className="marginComentLgSuccess1">
                    <Container
                      mlMobile="auto"
                      mRMobile="auto"
                      mleftmxs="0%"
                      mleft="18%"
                      mleftm="7%"
                      width="100%"
                      widthMobile="100%"
                      mtMobile="0px"
                    >
                      <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                        <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                          <Recommendations
                            items={recommendedTraining}
                          ></Recommendations>
                        </ContentHomeSection>
                      </Feed_Section>
                    </Container>
                  </div>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
};

export default MoneyToInvest;
