import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CardsBtn,
  TrainingSectionGray,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
// recovery
import {
  GET_ALL_USERS_CORU_RANKING,
  GET_INVESTMENT_RANKING_FRIENDS,
  GET_INVESTMENT_DETAILS,
  GET_INVESTMENT_INSTRUMENT_PRICE,
} from '../../queries';
import {
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import { filterFloat } from '../../Helpers';
import { ImageSticker } from '../../assets/styles/Feed.styled';
import { weekDay } from '../../Helpers/general';
// imagenes
import { Image, Container, Col7 } from '../../components/common/common.styled';
import SectionShare from '../../components/common/ShareSection';
import coruRanking from '../../assets/img/anacoach/coruRanking.svg';
import iconRankingGlobal from '../../assets/img/anacoach/iconRankingGlobal.svg';
import iconRankingAmigos from '../../assets/img/anacoach/iconRankingAmigos.svg';
import iconMisInversiones from '../../assets/img/anacoach/iconMisInversiones.svg';
import iconNuevaInversion from '../../assets/img/anacoach/iconNuevaInversion.svg';
import iconAgregarAmigo from '../../assets/img/anacoach/iconAgregarAmigo.svg';
import InvestmentDetails from './InvestmentDetails';
import ClickButton from '../../Helpers/HookHelpers';

const InvestmentsAndRanking = ({
  setOrderRutine,
  setWoComplete,
  setActiveRecovery,
}) => {
  const history = useHistory();
  const [active1, setactive1] = useState(true);
  const [active2, setactive2] = useState(true);
  const [active3, setactive3] = useState(true);
  const [active4, setactive4] = useState(true);
  const [goFeed, setGoFeed] = useState(false);

  const [showRankingGlobal, setShowRankingGlobal] = useState(false);
  const [showRankingFriends, setShowRankingFriends] = useState(false);
  const [showMyInvestments, setMyInvestments] = useState(false);
  const [showNewInvestments, setNewInvestments] = useState(false);
  const [showdetailFriendsOrGlobal, setShowdetailFriendsOrGlobal] = useState(false);

  const { actions } = ClickButton();

  const [datum, setDatum] = useState([]);
  const [gainsTotal, setGainTotal] = useState(0);

  // grafica lineal
  const [colors, setColors] = useState({});
  const [data, setData] = useState([]);
  const [legend, setlegend] = useState([]);

  const [colorsGraph] = useState([
    '#91D770',
    '#F64282',
    '#6BD5AF',
    '#6CA3FB',
    '#DDB439',
    '#6BD26F',
    '#25B4F8',
    '#7627DE',
    '#F5A82C',
    '#D5CC2A',
  ]);
  const [allUsersRanking, setallUsersRanking] = useState([]);
  const [friendsRanking, setFriendsRanking] = useState([]);
  const [investmentDetails, setInvestmentDetails] = useState([]);
  const [investmentDetailsByPort, setInvestmentDetailsByPort] = useState([]);
  const [addFriend, setAddFriend] = useState(false);
  // states para el modal
  const [instruments, setinstruments] = useState([]);
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState('');
  const [showGraph, setshowGraph] = useState(false);
  const [vigencia, setVigencia] = useState('');

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getAllUsersCoruRanking] = useLazyQuery(GET_ALL_USERS_CORU_RANKING, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ getAllUsersCoruRanking }) {
      if (getAllUsersCoruRanking.message === 'success') {
        const resp = JSON.parse(getAllUsersCoruRanking.response);
        resp.sort((a, b) => (a.amountInvestmentTotal > b.amountInvestmentTotal ? -1 : 1));
        const path = window.location.hostname;
        if (path === 'coru.com') {
          const orderByEmail = resp.filter(({ email }) => {
            if (!email.endsWith('@test.com')) return email;
          });
          setallUsersRanking(orderByEmail);
        } else {
          setallUsersRanking(resp);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getAssetsInvestmentInstrument] = useLazyQuery(
    GET_INVESTMENT_INSTRUMENT_PRICE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted({ getAssetsInvestmentInstrument }) {
        if (getAssetsInvestmentInstrument.message === 'success') {
          const resp = JSON.parse(getAssetsInvestmentInstrument.response);
          // setInstrumentPrice(resp);
          let daysCompleted = false;
          for (let i = 0; i < investmentDetails.length; i++) {
            const portf = investmentDetails[i].portafolio;
            if (value === portf) {
              const auxLineChart = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
              const arrayDays = [
                'lunes',
                'martes',
                'miércoles',
                'jueves',
                'viernes',
                'sábado',
                'domingo',
              ];
              const arrayLineChart = [''];
              const auxArray = [];
              const intrumento = investmentDetails[i].instruments;
              for (let x = 0; x < intrumento.length; x++) {
                // aqui voy multiplicar los activos diariamente con los portafolios
                // Grafica de dona
                investmentDetailsByPort.push({
                  name: intrumento[x].name,
                  amount: intrumento[x].amount,
                  start: intrumento[x].start,
                  validity: intrumento[x].validity,
                  color: colorsGraph[x],
                });
                datum.push({
                  key: intrumento[x].name,
                  y: convertirNum(intrumento[x].amount),
                  color: colorsGraph[x],
                });
                // fin Grafica de dona aplicar dias dinamicos en grafica
                if (!daysCompleted) {
                  for (let p = 0; p < resp.length; p++) {
                    if (resp[p].instrumentName === intrumento[x].name) {
                      const startDay = weekDay(intrumento[x].start);
                      // let endDay = weekDay(intrumento[x].validity);
                      for (let d = 0; d < arrayDays.length; d++) {
                        if (arrayDays[d] === startDay) {
                          let indexStart = arrayDays.indexOf(startDay);
                          arrayLineChart.push(auxLineChart[indexStart]);
                          auxArray.push(arrayDays[indexStart]);
                          indexStart++;
                          for (
                            indexStart;
                            indexStart < arrayDays.length;
                            indexStart++
                          ) {
                            arrayLineChart.push(auxLineChart[indexStart]);
                            auxArray.push(arrayDays[indexStart]);
                          }
                          if (arrayLineChart.length === 8) {
                            daysCompleted = true;
                            break;
                          } else {
                            for (
                              let rellenar = 0;
                              rellenar < arrayDays.length;
                              rellenar++
                            ) {
                              if (!auxArray.includes(arrayDays[rellenar])) {
                                arrayLineChart.push(auxLineChart[rellenar]);
                              }
                              if (arrayLineChart.length === 8) {
                                daysCompleted = true;
                                break;
                              }
                            }
                          }
                        }
                      }
                      if (arrayLineChart.length === 8) {
                        daysCompleted = true;
                        break;
                      }
                    }
                  }
                }
                // fin aplicar dias dinamicos en grafica
                legend.push({
                  instrument: intrumento[x].name,
                  color: colorsGraph[x],
                });
                setVigencia(
                  `del ${intrumento[x].start} al ${intrumento[x].validity}`,
                );
              }
              legend.push({
                instrument: 'Tu portafolio', // esto es la ganancia total o perdida
                color: colorsGraph[resp.length],
              });
              const nameIntruments = intrumento.map((item) => item.name);
              nameIntruments.splice(nameIntruments.length, 0, 'Tu portafolio');
              nameIntruments.splice(0, 0, '');
              console.log('name instrument', nameIntruments);
              const amountInstrument = []; // origin
              amountInstrument.push(nameIntruments);
              let totalGain = 0;
              let flag = true;
              for (let m = 0; m < arrayLineChart.length - 1; m++) {
                const auxAmountByInstrument = [];
                for (
                  let columns = 0;
                  columns < nameIntruments.length - 2;
                  columns++
                ) {
                  const priceByDay = resp[columns].pricesByDay;
                  if (m < priceByDay.length) {
                    for (
                      let auxByInstrument = 0;
                      auxByInstrument < intrumento.length;
                      auxByInstrument++
                    ) {
                      if (
                        resp[columns].instrumentName
                        === intrumento[auxByInstrument].name
                      ) {
                        const atClose = parseFloat(priceByDay[m].atClose); // precio de venta
                        const previousClose = parseFloat(
                          priceByDay[m].previousClose,
                        ); // precio de compra
                        const investment = convertirNum(
                          intrumento[auxByInstrument].amount,
                        );
                        const gain = (atClose - previousClose)
                          * (investment / previousClose);
                        totalGain += gain;
                        auxAmountByInstrument.push(gain); // ['1','2','3','gancncia']
                        // ganancias o perdidas datos prop para modal
                        instruments.push({
                          name: intrumento[columns].name,
                          amount: intrumento[columns].amount,
                          gainOrLoss: gain,
                        });
                        break;
                      }
                    }
                  } else if (priceByDay.length === 0) {
                    auxAmountByInstrument.push(0);
                  } else {
                    auxAmountByInstrument.push(null);
                    flag = false;
                  }
                }
                if (flag) {
                  auxAmountByInstrument.push(totalGain); // inserto la ganancia al final del array
                  setGainTotal(totalGain);
                  auxAmountByInstrument.splice(0, 0, arrayLineChart[m + 1]);
                } else {
                  auxAmountByInstrument.push(null);
                  auxAmountByInstrument.splice(0, 0, arrayLineChart[m + 1]);
                }
                amountInstrument.push(auxAmountByInstrument);
              }
              for (let color = 0; color < nameIntruments.length - 1; color++) {
                colors[color] = { color: colorsGraph[color] };
              }
              setData(amountInstrument);
              console.log('Montos por activos', amountInstrument);
              console.log('COLORES === ', colors);
              setshowGraph(true);
              break;
            }
          }
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const [getInvestmentRankFriends] = useLazyQuery(
    GET_INVESTMENT_RANKING_FRIENDS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted({ getInvestmentRankFriends }) {
        if (getInvestmentRankFriends.message === 'success') {
          const resp = JSON.parse(getInvestmentRankFriends.response);
          resp.sort((a, b) => (a.amountInvestmentTotal > b.amountInvestmentTotal ? -1 : 1));
          console.log(resp);
          setFriendsRanking(resp);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [getInvestmentDetailsFriend] = useLazyQuery(GET_INVESTMENT_DETAILS, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ getInvestmentDetailsFriend }) {
      if (getInvestmentDetailsFriend.message === 'success') {
        const resp = JSON.parse(getInvestmentDetailsFriend.response);
        setInvestmentDetails(resp);
        console.log('reponse == ', resp);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    const idTraining = sessionStorage.getItem('idTraining');
    const fromXCoach = sessionStorage.getItem('fromXCoach');
    const idAdviser = sessionStorage.getItem('idAdviser');
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === 'true' ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  const idUser = parseInt(sessionStorage.getItem('idUser'));

  useEffect(() => {
    if (showRankingGlobal || showGraph) {
      getAllUsersCoruRanking({ variables: { idTraining: 27 } });
    } else if (showRankingFriends) {
      getInvestmentRankFriends({
        variables: { idTraining: 27, idUser },
      });
    } else if (showMyInvestments || showGraph) {
      if (!showdetailFriendsOrGlobal) {
        console.log('ENTRO USEEFFECT');
        const idDatTraining = parseInt(
          sessionStorage.getItem('auxIdDatTraining'),
        );
        getInvestmentDetailsFriend({
          variables: { idDatTraining },
        });
      }
    }
  }, [showRankingGlobal, showRankingFriends, showMyInvestments, showGraph]);

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  useEffect(() => {
    console.log('showRankingGlobal1');
    if (showRankingGlobal) {
      console.log('showRankingGlobal2');
      setTimeout(() => {
        setactive1(false);
      }, 5000);
    }
  }, [showRankingGlobal]);
  useEffect(() => {
    if (showMyInvestments) {
      setTimeout(() => {
        setactive3(false);
      }, 5000);
    }
  }, [showMyInvestments]);

  useEffect(() => {
    if (showRankingFriends) {
      setTimeout(() => {
        setactive2(false);
      }, 5000);
    }
  }, [showRankingFriends]);

  useEffect(() => {
    if (showdetailFriendsOrGlobal) {
      setTimeout(() => {
        setactive4(false);
      }, 5000);
    }
  }, [showdetailFriendsOrGlobal]);

  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const handleOptions = (e, opt) => {
    setShowdetailFriendsOrGlobal(false);
    setlegend([]);
    setColors({});
    setDatum([]);
    setinstruments([]);
    setInvestmentDetails([]);
    setInvestmentDetailsByPort([]);
    setshowGraph(false);
    setVigencia('');
    setValue('');
    setGainTotal(0);
    setData([]);
    e.preventDefault();
    if (opt === 'Ranking Global') {
      const click = `RankingGlobal_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setShowRankingGlobal(!showRankingGlobal);
      setShowRankingFriends(false);
      setMyInvestments(false);
      setNewInvestments(false);
    } else if (opt === 'Ranking Amigos') {
      const click = `RankingAmigos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setShowRankingFriends(!showRankingFriends);
      setShowRankingGlobal(false);
      setMyInvestments(false);
      setNewInvestments(false);
    } else if (opt === 'Mis inversiones') {
      const click = `MisInversiones_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      setMyInvestments(!showMyInvestments);
      setShowRankingGlobal(false);
      setShowRankingFriends(false);
      setNewInvestments(false);
    } else if (opt === 'Nueva inversion') {
      const click = `NuevaInversion_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      sessionStorage.setItem('NuevaInversion', 'true');
      const inversiones = [
        'Aeroméxico',
        'Bimbo',
        'Amazon',
        'Facebook',
        'Cetes',
        'SPY',
        'USMV',
        'FUNO',
        'CPODX',
        'Oro',
      ];
      for (let i = 0; i < inversiones.length; i++) {
        if (sessionStorage.getItem(`CuantoInvertir${inversiones[i]}`)) {
          sessionStorage.removeItem(`CuantoInvertir${inversiones[i]}`);
        }
      }
      if (sessionStorage.getItem('AccionesNacionales')) { sessionStorage.removeItem('AccionesNacionales'); }
      if (sessionStorage.getItem('AccionesInternacionales')) { sessionStorage.removeItem('AccionesInternacionales'); }
      if (sessionStorage.getItem('Cetes')) sessionStorage.removeItem('Cetes');
      if (sessionStorage.getItem('ETFs')) sessionStorage.removeItem('ETFs');
      if (sessionStorage.getItem('Fibras')) sessionStorage.removeItem('Fibras');
      if (sessionStorage.getItem('AccionesInternacionales')) { sessionStorage.removeItem('AccionesInternacionales'); }
      if (sessionStorage.getItem('AccionesNacionales')) { sessionStorage.removeItem('AccionesNacionales'); }
      if (sessionStorage.getItem('FondoInversion')) { sessionStorage.removeItem('Metales'); }
      if (sessionStorage.getItem('Acumulado')) { sessionStorage.removeItem('Acumulado'); }
      setWoComplete(false);
      setOrderRutine(1);
      setActiveRecovery(true);
      sessionStorage.setItem('cantidadInversionesAgragadas', true);
      sessionStorage.setItem('currentOrderRoutine', 3);
      setShowRankingGlobal(false);
      setShowRankingFriends(false);
      setMyInvestments(false);
    }
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setValue(value);
    setInvestmentDetailsByPort([]);
    setlegend([]);
    setColors({});
    setDatum([]);
    setinstruments([]);
    setshowGraph(false);
  };

  const queryPortafolio = (e) => {
    const click = `Consultar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    for (let i = 0; i < investmentDetails.length; i++) {
      const portf = investmentDetails[i].portafolio;
      if (value === portf) {
        const intrumento = investmentDetails[i].instruments;
        const arrayInstrumentName = [];
        let startDate = '';
        for (let x = 0; x < intrumento.length; x++) {
          arrayInstrumentName.push(intrumento[x].name);
          startDate = intrumento[x].start;
        }
        getAssetsInvestmentInstrument({
          variables: {
            instrumentName: arrayInstrumentName.toString(),
            startDate,
          },
        });
        break;
      }
    }
  };
  const showInvestmentDetails = (idDatTraining) => {
    setShowdetailFriendsOrGlobal(false);
    if (
      idDatTraining !== null
      || idDatTraining !== ''
      || idDatTraining !== undefined
    ) {
      getInvestmentDetailsFriend({
        variables: { idDatTraining: parseInt(idDatTraining) },
      });
      setShowdetailFriendsOrGlobal(true);
    }
  };
  return (
    <div>
      <TrainingDivSection
        mt="20px"
        hew="auto"
        paddw="26px"
        mlw="-5%"
        mtw="8%"
        back=" "
        backw=" "
        bshaw="none"
        bsha=""
        br="4px"
        padding="10px"
        wi="90%"
        he="auto"
        mb="38px"
        wiw="100%"
      >
        <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
          <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
            <div style={{ display: 'flex', width: '100%' }}>
              <br />
              <CardsBtn
                onClick={(e) => handleOptions(e, 'Ranking Global')}
                style={{
                  borderRadius: '4px',
                  fontSize: '20px',
                  outline: 'none !important',
                  fontFamily: 'Cabin',
                }}
                min_w="100%"
                min_web="350px"
                mright="15%"
                mleftweb="25%"
                mrightweb="25%"
                mtopw="30px"
                type="button"
                color={showRankingGlobal ? 'white' : '#07210A !important'}
                back={
                  showRankingGlobal ? '#F64282 !important' : '#fff !important'
                }
                mtop="15px"
                shadow="0px 2px 48px rgba(0, 0, 0, 0.1)"
                weight="bold"
              >
                <ImageSticker
                  marg="-5px 20px auto auto"
                  width="25px"
                  back={'black'}
                  borderR="50%"
                  src={iconRankingGlobal}
                />
                Ranking global
              </CardsBtn>
              <br />
            </div>
          </Container>
        </TrainingSectionGray>
        <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
          <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
            <div style={{ display: 'flex', width: '100%' }}>
              <br />
              <CardsBtn
                onClick={(e) => handleOptions(e, 'Ranking Amigos')}
                style={{
                  borderRadius: '4px',
                  fontSize: '20px',
                  outline: 'none !important',
                  fontFamily: 'Cabin',
                }}
                min_w="100%"
                min_web="350px"
                mright="15%"
                mleftweb="25%"
                mrightweb="25%"
                mtopw="30px"
                type="button"
                color={showRankingFriends ? 'white' : '#07210A !important'}
                back={
                  showRankingFriends ? '#F64282 !important' : '#fff !important'
                }
                mtop="15px"
                shadow="0px 2px 48px rgba(0, 0, 0, 0.1)"
                weight="bold"
              >
                <ImageSticker
                  marg="-5px 20px auto auto"
                  width="25px"
                  src={iconRankingAmigos}
                />
                Ranking amigos
              </CardsBtn>
              <br />
            </div>
          </Container>
        </TrainingSectionGray>
        <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
          <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
            <div style={{ display: 'flex', width: '100%' }}>
              <br />
              <CardsBtn
                onClick={(e) => handleOptions(e, 'Mis inversiones')}
                style={{
                  borderRadius: '4px',
                  fontSize: '20px',
                  outline: 'none !important',
                  fontFamily: 'Cabin',
                }}
                min_w="100%"
                min_web="350px"
                // mleft="10px"
                mright="15%"
                mleftweb="25%"
                mrightweb="25%"
                mtopw="30px"
                type="button"
                color={showMyInvestments ? 'white' : '#07210A !important'}
                back={
                  showMyInvestments ? '#F64282 !important' : '#fff !important'
                }
                // border="solid 1px !important"
                mtop="15px"
                shadow="0px 2px 48px rgba(0, 0, 0, 0.1)"
                weight="bold"
              >
                <ImageSticker
                  marg="-5px 20px auto auto"
                  width="25px"
                  src={iconMisInversiones}
                />
                Mis inversiones
              </CardsBtn>
              <br />
            </div>
          </Container>
        </TrainingSectionGray>
        <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
          <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
            <div style={{ display: 'flex', width: '100%' }}>
              <br />
              <CardsBtn
                onClick={(e) => handleOptions(e, 'Nueva inversion')}
                style={{
                  borderRadius: '4px',
                  fontSize: '20px',
                  outline: 'none !important',
                  fontFamily: 'Cabin',
                }}
                min_w="100%"
                min_web="350px"
                // mleft="10px"
                mright="15%"
                mleftweb="25%"
                mrightweb="25%"
                mtopw="30px"
                type="button"
                color={showNewInvestments ? 'white' : '#07210A !important'}
                back={
                  showNewInvestments ? '#F64282 !important' : '#fff !important'
                }
                // border="solid 1px !important"
                mtop="15px"
                shadow="0px 2px 48px rgba(0, 0, 0, 0.1)"
                weight="bold"
              >
                <ImageSticker
                  marg="-5px 20px auto auto"
                  width="25px"
                  src={iconNuevaInversion}
                />
                Nueva inversión
              </CardsBtn>
              <br />
            </div>
          </Container>
        </TrainingSectionGray>
      </TrainingDivSection>
      {showRankingGlobal ? (
        <Fragment>
          {active1 ? (
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : (
            <Fragment>
              {allUsersRanking.map((item, index) => {
                if (
                  item.idUser === parseInt(sessionStorage.getItem('idUser'))
                ) {
                  return (
                    <p
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        width: '75%',
                        margin: 'auto',
                        fontFamily: 'Cabin',
                      }}
                    >
                      Ocupas el #{index + 1} del CoruRanking general
                    </p>
                  );
                }
              })}
              <Image
                src={coruRanking}
                displayMobile="flex"
                mtMob="90px"
                displayWeb="flex"
                mwidth="85%"
                width="55%"
                margin="auto"
                top="110px"
                left="10%"
                mleft="0"
                marginTop="50px"
              />
              <div
                style={{
                  overflowY: 'scroll',
                  margin: '30px',
                  padding: '15px',
                  height: '250px',
                  boxShadow: '0px 2px 48px rgba(0, 0, 0, 0.08)',
                  borderRadius: '10px',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {allUsersRanking.map((item, index) => (
                    <Fragment key={index}>
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          height: '50px',
                          cursor: 'pointer',
                        }}
                        onClick={() => showInvestmentDetails(item.idDatTraining)
                        }
                      >
                        <p style={{ width: '10px', marginLeft: '-15px' }}>{`${
                          index + 1
                        }.`}</p>
                        <p style={{ width: '120px' }}>{item.nameComplete}</p>
                        <p style={{ width: '40px' }}>
                          {`${(
                            String(item.nameComplete).split('')[0]
                            + String(item.nameComplete).split(' ')[1].split('')[0]
                          ).toUpperCase()}001`}
                        </p>
                        <p style={{ width: '50px' }}>
                          {formatter.format(
                            filterFloat(item.amountInvestmentTotal),
                          )}
                        </p>
                        <p style={{ width: '10px' }}>
                          <b>{'>'}</b>
                        </p>
                      </div>
                      <hr
                        style={{
                          width: '100%',
                          margin: '0',
                          marginBottom: '10px',
                        }}
                      />
                    </Fragment>
                  ))}
                </div>
              </div>
              <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
                <Container
                  width="100%"
                  mt="0rem"
                  widthMobile="90%"
                  mtMobile="0rem"
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <br />
                    <CardsBtn
                      onClick={() => setGoFeed(true)}
                      style={{
                        borderRadius: '4px',
                        letterSpacing: '-0.3px',
                        lineHeight: '140%',
                        fontWeight: 'bold',
                        fontStyle: 'normal',
                      }}
                      min_w="95%"
                      min_web="350px"
                      // min_web="100%"
                      mtopw="20px"
                      mleft="10px"
                      mtop="15px"
                      weight="normal!important"
                    >
                      Salir
                    </CardsBtn>
                    <br />
                  </div>
                </Container>
              </TrainingSectionGray>
            </Fragment>
          )}
        </Fragment>
      ) : null}
      {showRankingFriends ? (
        <Fragment>
          {active2 ? (
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : (
            <Fragment>
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                text="Recuerda que puedes ver el portafolio de inversión que utilizó cada uno de tus amigos para complementar o mejorar tu estrategia de inversión."
              />
              {friendsRanking.map((item, index) => {
                if (
                  item.idUser === parseInt(sessionStorage.getItem('idUser'))
                ) {
                  return (
                    <p
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        width: '75%',
                        margin: 'auto',
                        fontFamily: 'Cabin',
                      }}
                    >
                      Ocupas el #{index + 1} del CoruRanking general
                    </p>
                  );
                }
              })}
              {friendsRanking.length > 0 && (
                <Fragment>
                  <Image
                    src={coruRanking}
                    displayMobile="flex"
                    mtMob="90px"
                    displayWeb="flex"
                    mwidth="85%"
                    width="55%"
                    margin="auto"
                    top="110px"
                    left="10%"
                    mleft="0"
                    marginTop="50px"
                  />
                  <div
                    style={{
                      overflowY: 'scroll',
                      margin: '30px',
                      padding: '15px',
                      height: '250px',
                      boxShadow: '0px 2px 48px rgba(0, 0, 0, 0.08)',
                      borderRadius: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {friendsRanking.map((item, index) => (
                        <Fragment key={index}>
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              height: '50px',
                            }}
                            onClick={() => showInvestmentDetails(item.idDatTraining)
                            }
                          >
                            <p style={{ width: '10px' }}>{`${index + 1}.`}</p>
                            <p style={{ width: '120px', marginLeft: '-10px' }}>
                              {item.nameComplete}
                            </p>
                            <p style={{ width: '40px' }}>
                              {`${(
                                String(item.nameComplete).split('')[0]
                                + String(item.nameComplete).split('')[1]
                              ).toUpperCase()}001`}
                            </p>
                            <p style={{ width: '50px' }}>
                              {formatter.format(
                                filterFloat(item.amountInvestmentTotal),
                              )}
                            </p>
                            <p style={{ width: '10px' }}>
                              <b>{'>'}</b>
                            </p>
                          </div>
                          <hr
                            style={{
                              width: '100%',
                              margin: '0',
                              marginBottom: '10px',
                            }}
                          />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </Fragment>
              )}
              <TrainingSectionGray wiw="670px" mw="auto" back=" " mtop="0">
                <Container
                  width="100%"
                  mt="0rem"
                  widthMobile="90%"
                  mtMobile="0rem"
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <br />
                    <CardsBtn
                      onClick={() => setGoFeed(true)}
                      style={{
                        borderRadius: '4px',
                        letterSpacing: '-0.3px',
                        lineHeight: '140%',
                        fontWeight: 'bold',
                        fontStyle: 'normal',
                      }}
                      min_w="95%"
                      min_web="350px"
                      // min_web="100%"
                      mtopw="20px"
                      mleft="10px"
                      mtop="15px"
                      weight="normal!important"
                    >
                      Salir
                    </CardsBtn>
                    <br />
                  </div>
                </Container>
              </TrainingSectionGray>
              <CardsBtn
                min_w="85%"
                min_web="350px"
                mleft="8%"
                mleftmd="26px"
                // mright="15%"
                mleftweb="24%"
                // mrightweb="25%"
                mtop="20px"
                mtopw="20px"
                type="button"
                onClick={() => setAddFriend(true)}
                color="#EC407A !important"
                back="#fff !important"
                border="solid 1px !important"
                padd="8px 15px 4px"
                heigth="49px"
                className=""
              >
                <ImageSticker
                  marg="-5px 20px auto auto"
                  width="35px"
                  src={iconAgregarAmigo}
                />
                <b>Agregar amigo</b>
              </CardsBtn>
              {addFriend && (
                <div>
                  <SectionShare WO="SimuladordeIn"></SectionShare>
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}
      {showMyInvestments ? (
        <Fragment>
          {active3 ? (
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : (
            <Fragment>
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                text={`¡Hola, ${
                  sessionStorage.getItem('FirstName')
                    ? sessionStorage.getItem('FirstName')
                    : 'Yessica'
                } Estos son tus portafolios de inversión, selecciona el que desees consultar:`}
              />
              <InvestmentDetails
                value={value}
                handleRadioChange={handleRadioChange}
                investmentDetails={investmentDetails}
                queryPortafolio={queryPortafolio}
                showGraph={showGraph}
                vigencia={vigencia}
                gainsTotal={gainsTotal}
                data={data} // grafica lineal
                legend={legend}
                colors={colors}
                datum={datum} // grafica dona
                instruments={instruments}
                setOpen={setOpen}
                open={open}
                investmentDetailsByPort={investmentDetailsByPort}
                friendsRanking={friendsRanking}
                allUsersRanking={allUsersRanking}
                setGoFeed={setGoFeed}
              />
            </Fragment>
          )}
        </Fragment>
      ) : null}
      {showdetailFriendsOrGlobal ? (
        <Fragment>
          {active4 ? (
            <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
              <ImageCoachInicio />
              <Col7 padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          ) : (
            <Fragment>
              <InvestmentDetails
                value={value}
                handleRadioChange={handleRadioChange}
                investmentDetails={investmentDetails}
                queryPortafolio={queryPortafolio}
                showGraph={showGraph}
                vigencia={vigencia}
                gainsTotal={gainsTotal}
                data={data} // grafica lineal
                legend={legend}
                colors={colors}
                datum={datum} // grafica dona
                instruments={instruments}
                setOpen={setOpen}
                open={open}
                investmentDetailsByPort={investmentDetailsByPort}
                friendsRanking={friendsRanking}
                allUsersRanking={allUsersRanking}
                setGoFeed={setGoFeed}
              />
            </Fragment>
          )}
        </Fragment>
      ) : null}
    </div>
  );
};

export default InvestmentsAndRanking;
