import React, {
  Fragment, useState, useEffect, useCallback,
} from 'react';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Col11,
  Container,
  ParagrapTwo,
  SubtitleOne,
} from '../common/common.styled';
import '../../assets/styles/WelcomeValidation.css';
import '../../assets/styles/TextCustom.css';
import { DivFlex } from '../../assets/styles/Training.styled';
import face from '../../assets/img/anacoach/facebook1.png';
import twit from '../../assets/img/anacoach/messenger.png';
import whats from '../../assets/img/anacoach/whats.png';
// Modal
import '../../assets/styles/perfil/perfiles.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function ModalShare({ openModal, closeModal }) {
  const [modalStyle] = React.useState(getModalStyle);
  const [form] = useState({
    link: 'http://coru.com/shorter?id=mvpSFrAluvsbTCI',
  });

  const [btn, setBtn] = useState(true);

  const handleChange = () => {};

  const ebtn = useCallback(() => {
    if (form.link !== '') {
      setBtn(false);
    } else {
      setBtn(true);
    }
  });
  useEffect(() => {
    ebtn();
  }, [ebtn]);
  /*
  const guardar = (e) => {
    e.preventDefault();
  }; */

  function shareCoru(socialNetwork) {
    let coruRedirectURL;
    switch (socialNetwork) {
      case 'Messenger':
        // It seems that custom message is no longer suported by the facebook API
        // if you find a way to implement it, please do it.
        coruRedirectURL = "http://coru.com/shorter?id=G7YBzV6LXqXcNfx'";
        const url = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${encodeURIComponent(
          coruRedirectURL,
        )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`;
        window.open(url);
        break;
      case 'Facebook':
        coruRedirectURL = 'http://coru.com/shorter?id=okcB17lUJQrhOJq';
        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruRedirectURL,
          )}&redirect_uri=${encodeURIComponent(
            'https://www.facebook.com/',
          )}&display=popup`,
        );
        break;
      case 'Whatsapp':
        coruRedirectURL = 'http://coru.com/shorter?id=R1hdXbWIByRFE6B';
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURI(coruRedirectURL)}`,
          'popup',
          'width=600',
          'height=600',
        );
        break;
      case 'MessengerMob':
        coruRedirectURL = 'http://coru.com/shorter?id=G7YBzV6LXqXcNfx';
        const waurl = `fb-messenger://share/?link=${encodeURIComponent(
          coruRedirectURL,
        )}?app_id=248019326476226`;
        window.open(waurl);
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className="modalShare">
          <ParagrapTwo
            fontWeight="700"
            cursor="pointer"
            texttalign="right"
            textalignMobile="right"
            className="mx-auto"
            onClick={closeModal}
          >
            X
          </ParagrapTwo>
          <SubtitleOne
            mt="0%"
            mb="0%"
            className="font-weight-bold"
            sizeMobile="22px"
            size="25px"
          >
            ¿Te gustó?
          </SubtitleOne>
          <SubtitleOne
            mt="3%"
            mb="3%"
            className="font-weight-bold"
            sizeMobile="22px"
            size="25px"
          >
            ¡Cuéntale a un amigo!
          </SubtitleOne>
          <ParagrapTwo className="mx-auto" mb="0px">
            Ayuda a un amigo a que realice el entrenamiento que te interesó.
          </ParagrapTwo>
          <ParagrapTwo mtWeb="0px" mt="0px" mb="10%" className="mx-auto">
            Copia el link del entrenamiento y compártelo con tu amigo
          </ParagrapTwo>
          <form autoComplete="off">
            <Container width="80%">
              <Col11>
                <TextField
                  id="link"
                  name="link"
                  label=""
                  type="tel"
                  fullWidth
                  value={form.link}
                  onChange={handleChange}
                />
                <Button type="button" className="d-block  mt-md-4">
                  Copiar el link para compartir
                </Button>
              </Col11>
            </Container>
            <Container width="80%">
              <Col11>
                <DivFlex wid="100%" margin="0 0% 8% 0%" marweb="0 0% 8% 0%">
                  <img
                    onClick={() => shareCoru('Facebook')}
                    alt=""
                    position="initial"
                    left="0"
                    top="6%"
                    width="50px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                      marginTop: '6%',
                    }}
                    src={face}
                  />
                  <img
                    className="display-web"
                    onClick={() => shareCoru('Messenger')}
                    alt=""
                    position="initial"
                    left="0"
                    top="6%"
                    width="44px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                      marginTop: '6%',
                    }}
                    src={twit}
                  />
                  <img
                    className="display-mobile"
                    onClick={() => shareCoru('MessengerMob')}
                    alt=""
                    position="initial"
                    left="0"
                    top="6%"
                    width="44px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                      marginTop: '6%',
                    }}
                    src={twit}
                  />
                  <img
                    onClick={() => shareCoru('Whatsapp')}
                    alt=""
                    position="initial"
                    left="0"
                    top="6%"
                    width="50px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                      marginTop: '6%',
                    }}
                    src={whats}
                  />
                </DivFlex>
                <Button
                  type="button"
                  className="d-block  mt-md-4"
                  onClick={closeModal}
                >
                  Cerrar
                </Button>
              </Col11>
            </Container>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalShare;
