import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TrainingMain,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingSubtitles,
  TrainingText,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import {
  SeparateSection,
} from '../../assets/styles/Feed.styled';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import WOlayout from '../../components/layouts/WOlayout';
import ModalShareWO from '../../components/Feed/ModalShareWO';

const SuccessPrestamosMedida = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };
  const goFeed = () => {
    history.push('/the-training-feed');
  };

  return (
    <Fragment>
      <WOlayout
        backgroundColor={'#fafafa'}
        pathname={window.location.pathname}
        headerType="HeaderTwo"
      >
        <HeaderSuccess
          mtopAnaMob="100px"
          mtopIconCoruMob="175px"
          heightContainerMob="300px"
          color1="#E2C3EF"
          color2="#6677E3"
          color3="#6677E3"
          color4="#E2C3EF"
          color5="#8458C1"
          color6="#6677E3"
          color7="#A7FOFA"
          color8="#8458C1"
          color9="#6677E3"
          color10="#6677E3"
        />
        <TrainingMain>
          <TrainingSectionGray
            className="col-12"
            mw="auto"
            back="#FAFAFA"
            mtop="0"
          >
            <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
              <div className="container">
                <TrainingSectionGray
                  className="col-md-7 offset-md-2"
                  back="#fafafa"
                >
                  <TrainingDivSection2
                    paddiw="10% 0 3% 0"
                    paddi="35% 5% 5% 5%"
                    back="#fafafa"
                  >
                    <br />
                    <br />
                    <TrainingSubtitles
                      size="20px"
                      fw="600"
                      align="center"
                      alignweb="center"
                      style={{ fontFamily: 'Cabin' }}
                    >
                      Tu solicitud está en proceso
                    </TrainingSubtitles>
                    <TrainingText
                      size="16px"
                      className="roboto"
                      align="center"
                      alignweb="center"
                      ls="-0.2px"
                    >
                      Tu última solicitud de préstamo personal se realizó el {},
                      te recomendamos esperar tres meses para solicitar un nuevo
                      crédito y que tengas más posibilidades de que sea
                      aprobado.
                    </TrainingText>
                    <br />
                    <TrainingSubtitles
                      size="20px"
                      fw="600"
                      align="left"
                      alignweb="left"
                      className="cabin"
                    >
                      Estos son tus datos que registramos:
                    </TrainingSubtitles>
                    <br />
                    <TrainingText
                      size="16px"
                      className="roboto"
                      align="left"
                      alignweb="left"
                      ls="-0.2px"
                    >
                      <b>Nombre cliente:</b> {'nameClient'}
                      <br />
                      <b>Teléfono:</b> {'telClient'}
                      <br />
                      <b>Email:</b> {'emailClient'}
                    </TrainingText>
                  </TrainingDivSection2>
                  <SeparateSection />
                  <CardsBtn
                    min_w="100%"
                    min_web="100%"
                    type="button"
                    onClick={() => goFeed()}
                  >
                    <b>Ir a entrenamientos</b>
                  </CardsBtn>
                  <CardsBtn
                    back="#FFFFFF"
                    color="#293229"
                    border="2px solid #293229"
                    min_w="100%"
                    min_web="100%"
                    type="button"
                  >
                    <b>Iniciar solicitud de nuevo</b>
                  </CardsBtn>
                  <CardsBtn
                    back="#FFFFFF"
                    color="#293229"
                    border="2px solid #293229"
                    min_w="100%"
                    min_web="100%"
                    type="button"
                    onClick={() => setOpen(true)}
                  >
                    <b>La solicitud es para alguien más</b>
                  </CardsBtn>
                </TrainingSectionGray>
              </div>
            </TrainingDivSection2>
          </TrainingSectionGray>
        </TrainingMain>
      </WOlayout>
      <ModalShareWO
        openModal={open}
        closeModal={closeModal}
        redirect={'/wo/fast-track'}
      />
    </Fragment>
  );
};
export default SuccessPrestamosMedida;
