import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import GeneralLoader from '../../components/common/GeneralLoader';
import { GET_AUTH } from '../../queries';

const PrivateRoute = ({ component: Component, redirectPath, ...rest }) => {
  const { loading, error, data } = useQuery(GET_AUTH);

  if (loading) return <GeneralLoader/>;
  if (error) return `Error ${error}`;

  const condition = data && data.response && data.response.statusCode === 200;

  return (
        <Route

        {...rest}

        render={(props) => (condition ? (
            <Component {...props} />
        ) : (
            <Redirect to={redirectPath || '/'}/>
        ))}

        />
  );
};

export default PrivateRoute;
