import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ModalBackground,
  ModalBody,
  ModalCard,
} from '../../assets/styles/Home.styled';
import {
  Rectangle24,
  Vector2,
  FInalcialProfile, ElipseCoach1, LogocoruCoach, Subtitle,
} from './Home.styled';

import { Images } from '../../assets/styles/Training.styled';
import logocoruCoach from '../../assets/img/home/logoCoach.png';
import ana from '../../assets/img/anacoach/CoachImage.png';
import { Button, ButtonAlt, ParagrapTwo } from './common.styled';

const ModalGloveBox = ({ show, setShow }) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalBackground display={!showModal && 'none'}>
      <ModalCard maxheight="700px" MaxWidth="345px">
        <Vector2
          width="80px"
          left="0"
          height="60px"
          widthM="80px"
          heightM="60px"
          background="#BFDD6A"
          radius="40%"
          transform="rotate(-25deg)"
          positionD="absolute"
          Pleft="90%"
          Ptop="135px"
          PleftM="93%"
          PtopM="125px"
          MlefM="-6%"
          displayM="block"
          id="ovalo"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="25px"
          mLeftM="92%"
          PLeftM="-6px"
          mTopM="25px"
          Pleft="92%"
          background="#E3E660"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="60px"
          height="45px"
          widthM="60px"
          heightM="45px"
          transform="rotate(-25deg)"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="-5px"
          mLeftM="-6px"
          PLeftM="-8px"
          mTopM="-5px"
          Pleft="-8px"
          background="#91D770"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="60px"
          height="60px"
          widthM="60px"
          heightM="60px"
          transform="rotate(-17deg)"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="0"
          mLeftM="6px"
          PLeftM="100px"
          mTopM="-5px"
          Pleft="100px"
          background="#E3E660"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="26px"
          height="26px"
          widthM="26px"
          heightM="26px"
          transform="rotate(30deg)"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="180px"
          PTopM="160px"
          mLeftM="6px"
          PLeftM="-13px"
          mTopM="5px"
          Pleft="-13px"
          background="#E3E660"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="26px"
          height="26px"
          widthM="26px"
          heightM="26px"
          transform="rotate(45deg)"
        />
        <Rectangle24
          positionD="absolute"
          Ptop="-10px"
          PtopM="-10px"
          mLeftM="48%"
          PLeftM="56px"
          mTopM="2%"
          Pleft="56%"
          background="#6BD5AF"
          displayM="block"
          displayD=" "
          left=" "
          top=" "
          width="50px"
          height="50px"
          widthM="50px"
          heightM="50px"
          transform="rotate(50deg)"
        />
        <i
          style={{
            position: 'absolute',
            right: '10px',
            top: '0px',
            fontSize: '30px',
            cursor: 'pointer',
          }}
          onClick={() => setShow(false)}
        >
          ×
        </i>
        <ModalBody>
        <FInalcialProfile >
              <Images
                rigthm="0%"
                leftxs="37%"
                topmd=""
                leftw="10%"
                maxwidth="100%"
                rightm="0"
                bottomm="0"
                topm="0"
                le="40%"
                position="initial"
                zindex="1"
                padd="0.2%"
                br="45px 45px 45px 45px"
                width="100px"
                margin="auto"
                top="0"
                left="0"
                right="0"
                bottom="0"
                zi="1"
                widthw="80px"
                src={ana}
                border={'2px solid #BFDD6A'}
                className="img-fluid"
              ></Images>
              <ElipseCoach1 marginTop='-7%' marginLeft='20%'>
                <LogocoruCoach src={logocoruCoach} />
              </ElipseCoach1>
            </FInalcialProfile>
          <Subtitle weight='700' aling="center" width="100%" style={{ fontFamily: 'inherit' }}>
            ¡Recuerda!
          </Subtitle>
          <ParagrapTwo
            className="roboto"
            mt="2rem" fontWeight="500"
            fontSize="20px"
            fontSizeWeb="20px"
          >
              La <b>Guantera de Auto Digital</b> 🚗 siempre estará disponible para ayudarte
              a llevar contigo todos los documentos 🗂 necesarios para circular.
          </ParagrapTwo>
          <br />
          <Button
            onClick={() => { window.location.href = '/the-training-feed/'; }}
            style={{ fontFamily: 'inherit' }}
            mb="0"
          >
             Entendido
          </Button>
          <ButtonAlt
            onClick={() => setShow(false)}
            style={{ fontFamily: 'inherit' }}
          >
            Regresar al entrenamiento</ButtonAlt>
        </ModalBody>
      </ModalCard>
    </ModalBackground>
  );
};

ModalGloveBox.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default ModalGloveBox;
