/* eslint-disable no-new */
import React, { Fragment, useState, useEffect } from 'react';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import Compressor from 'compressorjs';
import iconoavatar from '../../assets/img/anacoach/Login.png';
import { TextPerfil } from '../../assets/styles/pantInicio.styled';
import {
  Col12,
  SubtitleOne,
  ParagrapTwo,
} from '../common/common.styled';
import { Rectangle24 } from '../common/Home.styled';
import { SAVE_IMG_PERFIL } from '../../mutations';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: '155px',
    height: '180px',
    border: '2.7px solid #F64282',
    borderRadius: '35%',
    marginTop: '77.844px',
    backgroundColor: '#FAFAFA',
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 45,
    height: 45,
    border: '2px solid #F64282',
    marginBottom: '5%',
    cursor: 'pointer',
    marginTop: '90%',
    backgroundColor: '#F64282!important',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    color: '#fff!important',
  },
}))(Avatar);

function Avatars({ name }) {
  const [avatarSelect, SetAvatarSelect] = useState(
    sessionStorage.getItem('ProfilePicture') !== 'null'
      && sessionStorage.getItem('ProfilePicture') != null
      ? sessionStorage.getItem('ProfilePicture')
      : iconoavatar,
  );
  const classes = useStyles();
  const idUser = sessionStorage.getItem('idUser') || 0;

  const handleOpenModal = () => {
    const objO = document.getElementById('avatarfile');
    objO.click();
  };

  const handleChange = (e) => {
    const input = document.getElementById('avatarfile');
    const file = e.target.files[0];
    const reader = new FileReader();
    let base64 = '';
    let type = '';
    if (!file) {
      return;
    }
    if(file.size/1024 > 3000){
      SetTextImageSize('Solo puedes subir imágenes PNG, JPG y JPEG de hasta 3MB')
      return
    }else{
      SetTextImageSize('')
    }
    new Compressor(file, {
      quality: 0.7,
      convertSize: 0.8,
      success(result) {
        console.log(result);
        if (input.files && result) {
          reader.onloadend = function () {
            base64 = reader.result;
            base64 = base64.split(',');
            base64 = base64[1].toString();
            name = result.name.replace(/ /g, '_');
            name = name.toString();
            console.log(base64);
            type = result.type;
            SaveImag(base64, name, type);
          };
          if (result) {
            reader.readAsDataURL(result);
          }
        }
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const [uploadFilePerfil] = useMutation(SAVE_IMG_PERFIL, {
    onCompleted({ uploadFilePerfil }) {
      console.log('******Se completo la carga de la imagen');
      sessionStorage.setItem('ProfilePicture', uploadFilePerfil.Location);
      SetAvatarSelect(uploadFilePerfil.Location);
    },
  });

  const SaveImag = (base, name, type) => {
    const input = {
      base64: base,
      name,
      type,
      folder: 'Perfil',
      idUser,
    };
    console.log(input);
    uploadFilePerfil({ variables: { input } });
  };

  const [textBienvedio, SetTextBienvenido] = useState('Buenas');

  const [textImageSize, SetTextImageSize] = useState('');

  const TextInicial = () => {
    const date = new Date();
    const horas = date.getHours();
    console.log(horas);
    if (horas >= 0 && horas <= 11) {
      SetTextBienvenido('Buenos días');
    } else if (horas >= 12 && horas <= 19) {
      SetTextBienvenido('Buenas tardes');
    } else {
      SetTextBienvenido('Buenas noches');
    }
  };

  useEffect(() => {
    TextInicial();
  });
  return (
    <Fragment>
      <Col12 ta="center">
        <Rectangle24
          width="70px"
          height="70px"
          positionD="absolute"
          mLeftM=" "
          mTopM=" "
          PTopM="80px"
          PLeftM="8%"
          Ptop="100px"
          Pleft="8%"
          displayM=" "
          displayD=" "
          left=" "
          top=" "
        />
        <Rectangle24
          width="30px"
          height="30px"
          positionM="absolute"
          mLeftM=" "
          mTopM=" "
          PLeftM="43%"
          transform="rotate(-67.79deg)"
          background="#E3E660"
          positionD="absolute"
          Ptop="85px"
          Pleft="25%"
          displayD=" "
          left=" "
          top=" "
        />
        <Rectangle24
          width="70px"
          height="70px"
          positionM="absolute"
          mLeftM=" "
          mTopM=" "
          PTopM="85px"
          PLeftM="65%"
          transform="rotate(-30deg)"
          background="#6BD5AF"
          positionD="absolute"
          Ptop="50px"
          Pleft="35%"
          displayD=" "
          left=" "
          top=" "
        />
        <Rectangle24
          width="30px"
          height="30px"
          positionM="absolute"
          mLeftM=" "
          mTopM=" "
          PTopM="123px"
          PLeftM="94%"
          transform="rotate(-30deg)"
          background="#E3E660"
          positionD="absolute"
          Ptop="203px"
          Pleft="48%"
          displayD=" "
          left=" "
          top=" "
        />
        <Rectangle24
          positionD="absolute"
          mLeftM=" "
          mTopM=" "
          PTopM="210px"
          PLeftM="0%"
          positionM="absolute"
          width="100px"
          height="100px"
          background="#E3E660"
          Ptop="261px"
          Pleft="-4%"
          transform="rotate(-45deg)"
          displayD=" "
          left=" "
          top=" "
        />
        <Rectangle24
          width="50px"
          positionM="absolute"
          height="60px"
          mLeftM=" "
          mTopM=" "
          PTopM=" "
          PLeftM="90%"
          radius="40px"
          transform="rotate(-123.08deg)"
          background="#BFDD6A"
          positionD="absolute"
          Ptop="300px"
          Pleft="48%"
          displayD=" "
          left=" "
          top=" "
        />
        <Badge
          id="ContainerImage"
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <SmallAvatar alt="Remy Sharp" src="" onClick={handleOpenModal}>
              <CameraAltIcon color="#ffff" />
            </SmallAvatar>
          }
        >
          <Avatar
            alt="Travis Howard"
            src={avatarSelect}
            className={classes.large}
          />
        </Badge>
        <input
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          id="avatarfile"
          name="avatarfile"
          className="noDisplay"
          onChange={handleChange}
        />
        <ParagrapTwo
          mb="10%"
          mbweb="3%"
          className="mx-auto roboto"
          texttalign="center"
          textalignMobile="center"
          fontSize="20px"
          fontWeight="bold"
        >
          {textImageSize}        
        </ParagrapTwo>
        <SubtitleOne
          mb="3%"
          transform=" "
          sizeMobile="25px"
          mt="7%"
          textalingn="center"
          size="35px"
          className="roboto"
          textalingnMobile="center"
        >
          {name}
        </SubtitleOne>
        <ParagrapTwo
          mb="10%"
          mbweb="3%"
          className="mx-auto roboto"
          texttalign="center"
          textalignMobile="center"
          fontSize="20px"
          fontWeight="bold"
        >
          {textBienvedio}
        </ParagrapTwo>
        <TextPerfil
          fw="bold"
          fs="20px"
          mt="2%"
          fsMobile="17px"
          mb="5%"
        ></TextPerfil>
      </Col12>
    </Fragment>
  );
}

export default Avatars;
