import styled from "styled-components";
import { LinkComponent, imageComponent, LinkFooter } from "./common";
import BackFiguras from "../../assets/img/anacoach/Confetti Shapes-3.png";

export const CheckBox = styled.input`
  width: ${(props) => props.width || "50px"};
  height: ${(props) => props.height || "20px"};
  border: ${(props) => props.border || "1px solid #000000"};
`;

export const ModalFull = styled.div`
  display: ${(props) => props.dis || "flex"};
  flex-direction: ${(props) => props.flexDirection || ""};
  align-items: ${(props) => props.alignItems || ""};
  align-self: ${(props) => props.alignSelf || ""};
  text-align: ${(props) => props.textAlign || ""};
  margin: ${(props) => props.margin || ""};
  padding: ${(props) => props.padding || ""};
  width: ${(props) => props.width || ""};
  height: ${(props) => props.height || ""};
  position: ${(props) => props.position || "fixed"};
  background-color: ${(props) => props.background || "white"};
  top: ${(props) => props.top || ""};
  bottom: ${(props) => props.bottom || "0px"};
  overflow: ${(props) => props.overflow || ""};
  overflow-y: ${(props) => props.overflowY || ""};
  overflow-x: ${(props) => props.overflowX || ""};
  border-radius: ${(props) => props.borderR || "16px 16px 0 0"};
  border: ${(props) => props.borderC || ""};
  justify-content: ${(props) => props.justifyC || "center"};
  gap: ${(props) => props.gap || ""};
  color: ${(props) => props.color || ""};
  z-index: ${(props) => props.zIndex || "1"};
  grid-template-columns: ${(props) => props.templateColumns || ""};
  grid-gap: ${(props) => props.gridGap || ""};
  @media (min-width: 768px) {
    margin: ${(props) => props.marginMd || ""};
    position: ${(props) => props.positionMd || ""};
    top: ${(props) => props.topMd || ""};
    bottom: ${(props) => props.bottomMd || ""};
    width: ${(props) => props.widthMd || ""};
    padding: ${(props) => props.paddingMd || ""};
    overflow: ${(props) => props.overflow || ""};
    overflow-y: ${(props) => props.overflowYG || ""};
    overflow-x: ${(props) => props.overflowXG || ""};
  }

  @media (max-width: 768px) {
    margin: ${(props) => props.marginXs || ""};
    position: ${(props) => props.positionXs || ""};
    top: ${(props) => props.topXs || ""};
    bottom: ${(props) => props.bottomXs || ""};
    width: ${(props) => props.widthXs || ""};
    height: ${(props) => props.heightXs || ""};
    padding: ${(props) => props.paddingXs || ""};
    overflow: ${(props) => props.overflXs || ""};
    overflow-y: ${(props) => props.overflowXs || ""};
    overflow-x: ${(props) => props.overflowXs || ""};
  }
  @media (max-width: 360px) and (max-height: 640px) {
    top: ${(props) => props.topxs || ""};
    bottom: ${(props) => props.bottomxs || ""};
    padding: ${(props) => props.paddingxs || ""};
    overflow: ${(props) => props.overflowxs || ""};
    overflow-y: ${(props) => props.overflowxs || ""};
    overflow-x: ${(props) => props.overflowxs || ""};
  }
`;

export const Button = styled.button`
  width: ${(props) => props.width || "100%"};
  background-color: ${(props) => props.bg || "#F64282"};
  box-sizing: border-box;
  color: ${(props) => props.color || "#fff"};
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props) => props.fw || "bold"};
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  font-size: ${(props) => props.size || "1.1vw"};
  height: auto;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "16px"};
  outline: none;
  min-width: ${(props) => props.minwidth || "100% !important"};
  max-width: ${(props) => props.maxwidth || "100% !important"};
  padding: 0.7vw 0.7vw;
  transition: all 0.3s ease 0s;
  margin-bottom: ${(props) => props.mb || "1.5rem"};
  margin-top: ${(props) => props.mt || "1.5rem"};
  cursor: pointer;
  display: ${(props) => props.displayW || " "};
  @media (max-width: 697px) {
    display: ${(props) => props.displayM || " "};
    font-size: ${(props) => props.size || "16px"};
    height: 48px;
  }
`;

export const ButtonAlt = styled.button`
  width: ${(props) => props.width || "100%"};
  background-color: ${(props) => props.bg || "#FFF"};
  box-sizing: border-box;
  border: none;
  color: rgb(246, 66, 130);
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  font-size: ${(props) => props.size || "1.1vw"};
  height: auto !important;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(246, 66, 130);
  border-image: initial;
  border-radius: ${(props) => props.borderRadius || "16px"};
  outline: none;
  min-width: ${(props) => props.minwidth || "100% !important"};
  min-height: ${(props) => props.minwidth || "48px !important"};
  max-width: 100% !important;
  padding: 0.7vw 0.7vw;
  transition: all 0.3s ease 0s;
  margin-bottom: ${(props) => props.mb || "1.5rem"};
  margin-top: ${(props) => props.mt || "1.5rem"};
  cursor: pointer;
  display: ${(props) => props.displayW || " "};
  @media (max-width: 697px) {
    display: ${(props) => props.displayM || " "};
    font-size: ${(props) => props.size || "16px"};
    height: 48px;
  }
`;

export const ButtonSecon = styled.button`
  width: ${(props) => props.width || "100%"};
  background-color: transparent;
  box-sizing: border-box;
  color: #07210a;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props) => props.weight || "bold"};
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  font-size: ${(props) => props.size || "1.1vw"};
  height: ${(props) => props.height || "auto"};
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  outline: none;
  min-width: ${(props) => props.minwidth || "100% !important"};
  max-width: 100% !important;
  padding: 0.7vw 0.7vw;
  transition: all 0.3s ease 0s;
  margin-left: ${(props) => props.mleft || "5%"};
  margin-bottom: ${(props) => props.mb || "1.5rem"};
  margin-top: ${(props) => props.mt || "1.5rem"};
  cursor: pointer;
`;

export const ButtonLink = styled(LinkComponent)`
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif !important;
  background-color: ${(props) => props.bgColor || "#fb245c"};
  color: ${(props) => props.color || "#fff"};
  box-sizing: border-box;
  border: none;
  padding: ${(props) => props.padd || "8px"};
  outline: none;
  height: ${(props) => props.hei || "47px"};
  font-size: ${(props) => props.size || "18px"};
  text-align: center;
  margin-top: ${(props) => props.mt || "8px"};
  margin-bottom: ${(props) => props.mb || "8px"};
  width: ${(props) => props.wi || ""};
  cursor: pointer;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
`;

// eslint-disable-next-line camelcase
export const Header_Div_Web = styled.div`
  display: none;
  @media (min-width: 480px) {
    display: inline-flex;
    width: 70%;
    justify-content: ${(props) => props.justifycontent || "space-around"};
    margin-right: ${(props) => props.mr || ""};
    font-family: system-ui;
    font-weight: 600;
  }
  justify-content: flex-end;
`;
// eslint-disable-next-line camelcase
export const Header_Container_Web = styled.div`
  display: none;
  @media (min-width: 480px) {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
    font-family: system-ui;
    font-weight: 600;
  }
`;
// eslint-disable-next-line camelcase
export const Header_Container_Mobile = styled.div`
  display: none;
  @media (max-width: 479px) {
    display: inline-flex;
    width: ${(props) => props.width || "100%"};
    justify-content: space-around;
    font-family: system-ui;
    font-weight: 600;
  }
`;

export const FooterLink = styled(LinkFooter)`
  background-color: ${(props) => props.bgColor || "transparent"};
  color: ${(props) => props.color || "#000b49"};
  box-sizing: border-box;
  border: none;
  padding: 8px 8px 0 8px;
  border-radius: 5px;
  height: 40px;
  font-size: ${(props) => props.fontSize || "18px"};
  @media (max-width: 479px) {
    font-size: ${(props) => props.fontSizeMobile || "18px"};
  }
`;
// eslint-disable-next-line camelcase
export const Footer_container = styled.div`
  color: ${(props) => props.color || "#000b49"};
  background-color: ${(props) => props.backcolor || "#fff"};
  margin-top: 20px;
  bottom: 0 !important;
  width: 100%;
  height: auto;
  text-align: center;
  z-index: 2;
  @media (max-width: 700px) {
    position: fixed;
  }
  /*position: ${(props) => props.position || "fixed"};


    color: ${(props) => props.color || "#000b49"};
    background-color: ${(props) => props.backcolor || "#fff"};
    margin-top: 20px;
    bottom: 0;
    width: 100%;
    height: auto;
    text-align: center;*/
`;

// eslint-disable-next-line camelcase
export const Footer_containerFixed = styled.div`
  color: ${(props) => props.color || "#000b49"};
  background-color: ${(props) => props.backcolor || "#fff"};
  margin-top: 20px;
  bottom: 0 !important;
  width: 100%;
  height: auto;
  text-align: center;
  position: fixed;
  z-index: 2;
`;

// eslint-disable-next-line camelcase
export const Footer_container_Home = styled.div`
  color: ${(props) => props.color || "#000b49"};
  background-color: ${(props) => props.backcolor || "#fff"};
  margin-top: 20px;
  bottom: 0;
  width: 100%;
  height: auto;
  text-align: center;
  display: ${(props) => props.displayW || " "};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
  }
`;
// eslint-disable-next-line camelcase
export const Footer_Verticals = styled.div`
  /*border-bottom: 1px solid #387199;
    border-bottom-style: ${(props) => props.border || "solid"};
    border-bottom-color: #387199; */
  margin: 0;
  text-align: left;
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || "pointer"};
  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "255.5%;"};
  /* or 41px */
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || " "};
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || " "};
    width: ${(props) => props.widthM || "100%"};
  }
`;

// eslint-disable-next-line camelcase
export const Footer_VerticalsA = styled.a`
  /*border-bottom: 1px solid #387199;
    border-bottom-style: ${(props) => props.border || "solid"};
    border-bottom-color: #387199; */
  margin: 0;
  text-align: left;
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  cursor: ${(props) => props.cursor || "pointer"};
  font-size: ${(props) => props.size || "16px"};
  line-height: ${(props) => props.line || "255.5%;"};
  color: ${(props) => props.color || "#fff"};
  /* or 41px */
  letter-spacing: -0.3px;
  display: ${(props) => props.displayD || "block"};
  :hover  {
    color: ${(props) => props.colorHover || "#fff"};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayM || "block"};
  }
`;
// eslint-disable-next-line camelcase
export const Footer_Coru = styled.div`
  display: flex;
  justify-content: space-around;
  color: #387199;
  font-size: 1.2em;
  font-weight: 700;
  padding: 8px;
  @media (min-width: 700px) {
    display: none;
  }
`;
// eslint-disable-next-line camelcase
export const Footer_Options = styled.span`
  width: 25%;
  cursor: pointer;
`;
// eslint-disable-next-line camelcase
export const Footer_Aviso = styled.div`
  color: ${(props) => props.color || "#fff"};
  font-size: ${(props) => props.size || " "};
  font-weight: ${(props) => props.fw || " "};
  padding: 8px;
  cursor: pointer;
  align-items: left;
`;
// eslint-disable-next-line camelcase
export const Footer_description = styled.div`
  padding-left: 20px;
  padding-top: 7px;
  padding-bottom: ${(props) => props.pb || " "};
  min-height: 70px;
  color: ${(props) => props.color || "#000b49"};
  background-color: ${(props) => props.backcolor || "#fff"};
  z-index: 2;
  text-align: left;
  @media (max-width: 600px) {
    min-height: 60px;
  }
`;
// eslint-disable-next-line camelcase
export const Footer_description_Home = styled.div`
  padding: ${(props) => props.padding || "20px"};
  padding-top: ${(props) => props.paddingt || "7px"};
  padding-bottom: ${(props) => props.paddingMobile || " "};
  color: ${(props) => props.color || "#000b49"};
  background-color: ${(props) => props.backcolor || "#fff"};

  @media (min-width: 480px) {
    display: flex;
    padding-top: ${(props) => props.paddingWeb || "7px"};
    padding-bottom: ${(props) => props.paddingBWeb || " "};
  }
`;

// eslint-disable-next-line camelcase
export const Footer_Div3 = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || "none"};
  }
`;

export const FooterShareDiv = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || "block"};
  }
`;

// eslint-disable-next-line camelcase
export const Footer_Div1 = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: ${(props) => props.width || "50%"};
    text-aling: left;
    display: none;
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displaymobile || "block"};
  }
`;
// eslint-disable-next-line camelcase
export const Footer_Div2 = styled.div`
  width: auto;
  @media (min-width: 480px) {
    width: ${(props) => props.width || "50%"};
    display: none;
  }
`;
// eslint-disable-next-line camelcase
export const Header_Log = styled.div`
  color: #000b49;
  font-weight: 600;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  width: ${(props) => props.width || "50%"};
  cursor: pointer;
  font-size: ${(props) => props.fontSize || "1.1vw"};
  margin-right: ${(props) => props.mr || " "};
  margin: ${(props) => props.margin || " "};
  margin-left: ${(props) => props.left || " "};
  @media (min-width: 480px) {
    text-align: ${(props) => props.textAlign || "left"};
    width: ${(props) => props.widthW || "100%"};
    margin: ${(props) => props.marginW || "auto"};
    margin-left: ${(props) => props.leftW || " "};
    margin-right: ${(props) => props.rightW || " "};
    font-weight: 400;
  }
  @media (min-width: 1400px) {
    font-weight: 400;
  }
`;
export const ContentFiguras = styled.div`
  width: 227.58px;
  height: 398.56px;
  /* margin-left: 227.42px; */
  /* margin-top: -102%; */
  position: absolute;
  bottom: 0%;
  left: 57%;
`;
// eslint-disable-next-line camelcase
export const Header_Log_Mob = styled.div`
  position: ${(props) => props.position || " "};
  bottom: ${(props) => props.Pbottom || " "};
  font-weight: ${(props) => props.fw || "400"};
  width: 90%;
  cursor: pointer;
  font-family: Cabin;
  font-style: normal;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: -0.3px;
  color: #07210a;
  text-align: ${(props) => props.aling || "left"};
  margin-top: ${(props) => props.top || " "};
  margin-bottom: ${(props) => props.bottom || " "};
  @media (min-width: 480px) {
    text-align: left;
    font-size: 14px;
    width: 100%;
    margin: auto;
    font-weight: ${(props) => props.fw || "400"};
  }
`;
// eslint-disable-next-line camelcase
export const Header_Log_Btn = styled.div`
  background: #fb245c;
  border-radius: 3px;
  color: #fff !important;
  font-weight: 600;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  width: ${(props) => props.width || "25%"};
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding: ${(props) => props.padding || "9px 15px"};
  margin-left: ${(props) => props.left || "10%"};
  margin-top: ${(props) => props.top || " "};
  position: ${(props) => props.position || " "};
  bottom: ${(props) => props.bottom || " "};
  transition: 0.3s;
  &:hover {
    background-color: #d31e4d;
  }
  float: ${(props) => props.float || " "};
  max-width: ${(props) => props.maxwidth || " "};
  @media (min-width: 768px) {
    margin-top: 5%;
  }
  @media (min-width: 480px) {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
`;
// eslint-disable-next-line camelcase
export const Header_Log_Vertical_Divisor = styled.div`
  border-left: 1px solid #000000;
  height: auto;
`;

// eslint-disable-next-line camelcase
export const Header_Log_Select = styled.div`
  color: #000b49;
  font-weight: 600;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  width: ${(props) => props.width || "50%"};
  cursor: pointer;
  font-size: ${(props) => props.fontSize || "1.1vw"};
  margin-right: ${(props) => props.mr || " "};
  margin: ${(props) => props.margin || " "};
  margin-left: ${(props) => props.left || " "};
  @media (min-width: 480px) {
    text-align: ${(props) => props.textAlign || "left"};
    width: ${(props) => props.widthW || "100%"};
    margin: ${(props) => props.marginW || "auto"};
    margin-left: ${(props) => props.leftW || " "};
    margin-right: ${(props) => props.rightW || " "};
    font-weight: 400;
  }
  @media (min-width: 1400px) {
    font-weight: 400;
  }
`;
// eslint-disable-next-line camelcase
export const Header_Log_Select_Option_Container = styled.div`
  ${(props) => (props.display !== "" ? `display: ${props.display};` : "")}
  position: absolute;
  padding-top: 2rem;
  background-color: ${(props) => props.bgColor || "rgb(250, 250, 250)"};
  text-align: left;
  width: ${(props) => props.width || "9%"};
  padding: ${(props) => props.padding || "5px"};
  z-index: ${(props) => props.zIndex || "5"};
`;
// eslint-disable-next-line camelcase
export const Header_Log_Select_Option = styled.div`
  padding-left: ${(props) => props.paddingLeft || "8px"};
  transition: .2s ease;
  margin-bottom: .5rem;
  z-index: ${(props) => props.zIndex || "5"} ;
  &:hover {
    border-left: 6px solid #B5D7AA;
    padding-left: ${(props) => props.paddingLeft || "px"};
    font-weight: bold;
    color: #07210A;
    transition .2s ease;
  }
`;
// eslint-disable-next-line camelcase
export const Header_Log_Select_Icon = styled.img`
  max-width: 10px;
  position: relative;
  margin-top: 5px;
  margin-left: 5px;
  float: right;
`;

// eslint-disable-next-line camelcase
export const Content_Options = styled.div`
  width: ${(props) => props.width || ""};
  display: flex;
`;
// eslint-disable-next-line camelcase
export const Header_OptDiv = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const LoaderDivComplete = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  padding-left: 40%;
  padding-top: 15%;
  @media (max-width: 760px) {
    padding-left: 30%;
    padding-top: 65%;
  }
`;

export const ImageLoad = styled(imageComponent)`
  max-width: ${(props) => props.maxwidth || "100%"};
  width: ${(props) => props.widthweb || "100%"};
  height: ${(props) => props.height || " "};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-right: ${(props) => props.marginRight || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  position: ${(props) => props.position || "initial"};
  left: ${(props) => props.left || ""};
  top: ${(props) => props.top || ""};
  cursor: ${(props) => props.cursor || " "};
  display: ${(props) => props.displayWeb || " "};
  border: ${(props) => props.border || " "};
  z-index: 0;

  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ""};
    width: ${(props) => props.mwidth || props.width || "100%"};
    left: ${(props) => props.mleft || ""};
    top: ${(props) => props.mtop || ""};
    margin-right: ${(props) => props.marginRightM || ""};
    margin-top: ${(props) => props.marginTopM || ""};
  }
  @media (max-width: 700px) {
    display: ${(props) => props.displayMobile || " "};
    height: ${(props) => props.heightMobile || " "};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.width || "100%"};
    height: ${(props) => props.heightMobile || " "};
    display: ${(props) => props.displayMobile || " "};
  }
`;
export const Image = styled(imageComponent)`
  max-width: ${(props) => props.maxwidth || "100%"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || " "};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop || ""};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-right: ${(props) => props.marginRight || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  position: ${(props) => props.position || "initial"};
  left: ${(props) => props.left || ""};
  top: ${(props) => props.top || ""};
  bottom: ${(props) => props.bottom || ""};
  cursor: ${(props) => props.cursor || " "};
  display: ${(props) => props.displayWeb || " "};
  border: ${(props) => props.border || " "};
  z-index: ${(props) => props.zindex || ""};
  transform: ${(props) => props.transform || ""};
  transform-origin: ${(props) => props.to || ""};
  max-height: ${(props) => props.mheight || " "};
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ""};
    width: ${(props) => props.mwidth || props.width || "100%"};
    left: ${(props) => props.mleft || ""};
    top: ${(props) => props.mtop || ""};
    margin-right: ${(props) => props.marginRightM || ""};
    margin-top: ${(props) => props.marginTopM || ""};
    margin-left: ${(props) => props.marginLeftM || ""};
  }
  @media (max-width: 767px) {
    display: ${(props) => props.displayMobile || " "};
    position: ${(props) => props.positionMobile || " "};
    height: ${(props) => props.heightMobile || " "};
    width: ${(props) => props.widthMd || "100%"};
    margin: ${(props) => props.marginMd || ""};
    height: ${(props) => props.heightMd || " "};
    margin-right: ${(props) => props.marginRightMd || ""};
    object-fit: ${(props) => props.objectFitMd || ""};
  }
  @media (max-width: 460px) {
    width: ${(props) => props.mwidth || props.widthsm || ""};
    height: ${(props) => props.heightMobile || " "};
    display: ${(props) => props.displayMobile || " "};
    margin-top: ${(props) => props.mtMob || " "};
    margin-bottom: ${(props) => props.mbMob || " "};
    margin-left: ${(props) => props.mlMob || " "};
  }
`;

export const ImageFooter = styled(imageComponent)`
    max-width: 100%;
    width: ${(props) => props.width || "60%"};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.bottom || ""};
    margin-right: ${(props) => props.marginRight || ""};
    margin-left: ${(props) => props.marginLeft || ""};
    position: ${(props) => props.position || "initial"};
    left: ${(props) => props.left || ""};
    cursor : ${(props) => props.cursor || " "};
    z-index: 25;
    @media (max-width: 600px) {
        width: ${(props) => props.mwidth || "100%"};
        height:  ${(props) => props.heightMobile || ""}
        margin-bottom: ${(props) => props.bottomMObile || ""};
        margin-left: ${(props) => props.marginLeftmob || ""};
    }
`;

export const ImageIcon = styled(imageComponent)`
  width: ${(props) => props.width || "100%"};
  margin-bottom: ${(props) => props.bottom || ""};
  margin-right: ${(props) => props.marginRight || ""};
  margin: ${(props) => props.margin || ""};
  position: initial;
  z-index: 0;
  @media (max-width: 800px) {
    margin-bottom: ${(props) => props.bottomMObile || ""};
    margin-right: ${(props) => props.mrMObile || ""};
    width: ${(props) => props.widthMobile || "60px"};
  }
`;
export const SubtitleOne = styled.h3`
  text-align: ${(props) => props.textalingn || "left"};
  font-weight: ${(props) => props.fw || "700"};
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#07210A"};
  font-size: ${(props) => props.size || "28px"};
  margin-top: ${(props) => props.mt || "0%"};
  margin-bottom: ${(props) => props.mb || "24px"};
  width: ${(props) => props.width || "100%"};
  padding: ${(props) => props.padding || " "};
  text-transform: ${(props) => props.transform || " "};
  @media (max-width: 767px) {
    text-align: ${(props) => props.textalingnMobile || "left"};
    font-weight: ${(props) => props.fwmd || "700"};
    color: ${(props) => props.color || "#07210A"};
    font-size: ${(props) => props.sizeMobile || "20px"};
    margin: ${(props) => props.marginm || " "};
    margin-top: ${(props) => props.mtMob || props.mt || "0px"};
    margin-bottom: ${(props) => props.mb || "12px"};
    width: ${(props) => props.widthM || props.width || "100%"};
  }
`;

export const TiteNum = styled.span`
  float: right;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 7%;
`;

export const ParagraphOne = styled.p`
  text-align: ${(props) => props.ta || "center"};
  font-size: ${(props) => props.fontSize || "20px"};
  margin-top: ${(props) => props.mt || "35px"};
  margin-bottom: ${(props) => props.mb || "16px"};
  font-weight: ${(props) => props.fontWeight || "400"};
  cursor: ${(props) => props.cursor || "default"};
  width: ${(props) => props.width || "100%"};
  @media (min-width: 769px) {
    margin-top: ${(props) => props.mtD || "1rem"};
  }
`;

export const LinksFooter = styled.a`
  text-align: ${(props) => props.ta || "center"};
  font-size: ${(props) => props.fontSize || "20px"};
  margin-top: ${(props) => props.mt || "35px"};
  margin-bottom: ${(props) => props.mb || "16px"};
  color: ${(props) => props.color || "#000b49"};
  font-weight: ${(props) => props.fontWeight || "400"};
  cursor: ${(props) => props.cursor || "default"};
  width: ${(props) => props.width || "100%"};
  display: ${(props) => props.display || " "};
  line-height: ${(props) => props.lineHeight || ""};
  @media (min-width: 769px) {
    margin-top: ${(props) => props.mtD || "1rem"};
  }
`;

export const CorrecionCorreo = styled.p`
  font-size: ${(props) => props.fontSize || "15px"};
  display: ${(props) => props.display || "none"};
  cursor: pointer;
`;

export const Main = styled.main`
  margin: ${(props) => props.margin || ""};
  min-height: ${(props) => props.heightComponent || ""};
  background-color: ${(props) => props.background || "transparent !important"};
  background-size: ${(props) => props.backgroundsize || " "};
  background-repeat: ${(props) => props.backgroundrepeat || " "};
  padding: ${(props) => props.padding || "0rem"};
  text-align: ${(props) => props.ta || "left"};
  margin-top: ${(props) => props.mt || ""};
  padding-bottom: ${(props) => props.pb || ""};
  overflow: ${(props) => props.overflow || ""}; /*auto*/
  overflow-y: ${(props) => props.overflowY || ""};
  border-bottom: ${(props) => props.bbottom || ""};
  margin-bottom: ${(props) => props.mbottom || ""};
  min-height: ${(props) => props.minheigthWeb || ""};
  margin-top: ${(props) => props.margintop || ""};
  @media (min-width: 599px) {
    padding-top: ${(props) => props.ptuno || ""};
  }
  @media (max-width: 600px) {
    min-height: ${(props) => props.minheigth || ""};
    margin-top: ${(props) => props.mtWeb || ""};
    margin-bottom: ${(props) => props.mbWeb || ""};
    padding: ${(props) => props.paddingWeb || props.padding};
  }
  padding-top: ${(props) => props.pt || ""};
`;

export const MainHeader = styled.main`
  background: ${(props) => props.background || ""};
  padding: ${(props) => props.padding || "0rem"};
  text-align: ${(props) => props.ta || "left"};
  margin-top: ${(props) => props.mt || ""};
  padding-bottom: ${(props) => props.pb || ""};
  border-bottom: ${(props) => props.bbottom || ""};
  margin-bottom: ${(props) => props.mbottom || ""};
  min-height: ${(props) => props.minheigthWeb || ""};
  z-index: 100;
  width: 100%; /* hacemos que la cabecera ocupe el ancho completo de la página */
  left: 0; /* Posicionamos la cabecera al lado izquierdo */
  top: 0; /* Posicionamos la cabecera pegada arriba */
  position: fixed; /* Hacemos que la cabecera tenga una posición fija */
`;

export const InputsOTP = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 3%;
`;
export const InputsValue = styled.input`
  width: 20%;
  font-size: 36px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  outline: none;
`;

export const ParagrapTwo = styled.p`
  text-align: ${(props) => props.textalignMobile || "left"};
  font-size: ${(props) => props.fontSize || "16px"};
  margin-top: ${(props) => props.mt || "9px"};
  margin-bottom: ${(props) => props.mb || "9px"};
  color: ${(props) => props.color || "#07210A"};
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: ${(props) => props.lh || "120%"};
  letter-spacing: ${(props) => props.spacing || "-0.3px"};
  cursor: ${(props) => props.cursor || "default"};
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.ml || ""};
  z-index: ${(props) => props.zindex || ""};
  margin-left: ${(props) => props.ml || ""};
  padding-left: ${(props) => props.paddingleft || ""};
  position: ${(props) => props.position || ""};
  top: ${(props) => props.topM || ""};
  right: ${(props) => props.rightM || ""};
  @media (min-width: 769px) {
    margin-top: ${(props) => props.mtWeb || "1rem"};
    margin-bottom: ${(props) => props.mbweb || " 9px"};
    text-align: ${(props) => props.texttalign || "left"};
    font-size: ${(props) => props.fontSizeWeb || "16px"}; //20px
    margin-left: ${(props) => props.mlWeb || ""};
    width: ${(props) => props.widthM || "100%"};
    top: ${(props) => props.top || ""};
    right: ${(props) => props.right || ""};
  }
`;

export const ConterMinusculas = styled.span`
  text-transform: lowercase;
  color: #fb245c;
`;

export const Carent = styled.span`
  margin-left: 1%;
  font-size: ${(props) => props.size || "24px"};
  @media (max-width: 769px) {
    /*float: ${(props) => props.float || "right"}; */
    font-size: ${(props) => props.sizeMobile || "24px"};
  }
`;

export const SpanFooter = styled.span`
  color: ${(props) => props.color || "#fb245c!important"};
  margin-left: ${(props) => props.ml || "5%"};
`;
export const SpanLinkFooter = styled.a`
  color: ${(props) => props.color || "#fb245c!important"};
  margin-left: ${(props) => props.ml || "3%"};
  text-decoration: none;
`;
export const Container = styled.div`
  flex-direction: ${(props) => props.flexDir || ""}
  gap: ${(props) => props.gap || ""}
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.float || "auto"};
  margin-top: ${(props) => props.mt || "0%"};
  margin-bottom: ${(props) => props.mb || "auto"};
  margin-left: ${(props) => props.mleft || "auto"};
  display: flex;
  display: ${(props) => props.displayweb || ""};
  border-bottom: ${(props) => props.bb || ""};
  flex-wrap: wrap;
  overflow-y: ${(props) => props.overflowY || ""};
  padding: ${(props) => props.padding || ""};
  padding-top: ${(props) => props.paddingTop || ""};
  border: ${(props) => props.border || ""};
  border-top: ${(props) => props.bordertop || ""};
  background: ${(props) => props.background || ""};
  border-radius: ${(props) => props.radius || ""};
  border-top-right-radius: ${(props) => props.btopRradius || ""};
  border-top-left-radius: ${(props) => props.btopLradius || ""};
  box-shadow: ${(props) => props.shadow || ""};
  box-sizing: ${(props) => props.boxSizing || ""};
  cursor: ${(props) => props.cursor || ""};
  height: ${(props) => props.height || ""};
  filter: ${(props) => props.filter || ""};
  @media (max-width: 767px) {
    width: ${(props) => props.widthMobile || "100%"};
    display: ${(props) => props.displaymobile || ""};
    margin: ${(props) => props.mMobile || ""};
    margin-left: ${(props) => props.mlMobile || "auto"};
    margin-right: ${(props) => props.mRMobile || "auto"};
    margin-bottom: ${(props) => props.mbMobile || props.mb};
    margin-top: ${(props) => props.mtMobile || ""}; //1rem
    padding: ${(props) => props.paddingMobile || ""};
  }
  @media (min-width: 768px) {
    width: ${(props) => props.widthDesk || ""};
    display: ${(props) => props.displayDesk || ""};
    margin: ${(props) => props.mDesk || ""};
    margin-left: ${(props) => props.mlDesk || "auto"};
    margin-right: ${(props) => props.mRDesk || "auto"};
    margin-bottom: ${(props) => props.mbDesk || props.mb};
    margin-top: ${(props) => props.mtDesk || ""}; //1rem
    padding: ${(props) => props.paddingDesk || ""};
  }
`;

export const ContainerMenu = styled.div`
  width: ${(props) => props.width || "50%"};
  margin: ${(props) => props.margin || "auto"};
  margin-top: ${(props) => props.mt || "auto"};
  margin-bottom: ${(props) => props.mb || "auto"};
  display: ${(props) => props.displayweb || ""};
  padding: ${(props) => props.padding || "2rem 2rem 2rem 0rem;"};
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: ${(props) => props.widthMobile || "100%"};
    padding: ${(props) => props.paddingMobile || props.padding};
  }
`;

export const ContainerTextConocenos = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  padding: ${(props) => props.padding || "0px 15px"};
  text-align: ${(props) => props.ta || "center"};
  @media (max-width: 769px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 400px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 7%;
  }
`;

export const ConterTheOne = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 5%;
  text-align: center;
  /*background-color : #fb245c; */
  padding: ${(props) => props.padding || "5%"};
`;

export const ContainerImagenConocenos = styled.div`
  flex: 0 0 40%;
  max-width: 40%;
  margin: auto;
  padding: ${(props) => props.padding || "0px 15px"};
  text-align: ${(props) => props.ta || "center"};
  @media (max-width: 769px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const FooterViralidad = styled.div`
  display: flex;
  width: ${(props) => props.width || "100%"};
  margin-left: ${(props) => props.left || " "};
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const Col3 = styled.div`
  flex: ${(props) => props.flexD || "0 0 25%"};
  max-width: ${(props) => props.maxwidthD || "25%"};
  padding: ${(props) => props.padding || "0px 15px"};
  text-align: ${(props) => props.ta || "center"};
  margin: ${(props) => props.margin || " "};
  margin-top: ${(props) => props.mt || " "};
  border: ${(props) => props.border || " "};
  max-height: ${(props) => props.maxhei || "auto"};
  @media (max-width: 769px) {
    flex: ${(props) => props.flexmob || "0 0 25%"};
    max-width: ${(props) => props.maxwidthmob || "25%"};
  }
`;

export const Col12 = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding: ${(props) => props.padding || "0px 5px"};
  text-align: ${(props) => props.ta || "left"};
  padding-top: ${(props) => props.pt || "5%"};
  padding-left: ${(props) => props.ptl || ""};
  margin-bottom: ${(props) => props.bottom || " "};
  @media (max-width: 769px) {
    display: ${(props) => props.displayMobile || ""};
    padding: ${(props) => props.pMobile || " "};
    padding-top: ${(props) => props.ptMobile || "5%"};
  }
`;
export const ContentGeneric = styled.div`
  display: ${(props) => props.dis || ""};
  align-items: ${(props) => props.alignItems || ""};
  align-self: ${(props) => props.alignSelf || ""};
  text-align: ${(props) => props.textAlign || ""};
  margin: ${(props) => props.margin || ""};
  position: ${(props) => props.position || ""};
  padding: ${(props) => props.padding || ""};
  width: ${(props) => props.width || ""};
  max-width: ${(props) => props.maxWidth || ""};
  max-height: ${(props) => props.maxHeight || ""};
  min-height: ${(props) => props.minHeight || ""};
  height: ${(props) => props.height || ""};
  position: ${(props) => props.position || ""};
  background-color: ${(props) => props.background || ""};
  background: ${(props) => props.backgroundI || ""};
  top: ${(props) => props.top || ""};
  flex-direction: ${(props) => props.flexDirection || ""};
  gap: ${(props) => props.gap || ""};
  left: ${(props) => props.left || ""};
  bottom: ${(props) => props.bottom || ""};
  overflow: ${(props) => props.overflow || ""};
  overflow-y: ${(props) => props.overflowY || ""};
  border-radius: ${(props) => props.borderR || ""};
  border: ${(props) => props.borderC || ""};
  justify-content: ${(props) => props.justifyC || ""};
  gap: ${(props) => props.gap || ""};
  color: ${(props) => props.color || ""};
  z-index: ${(props) => props.zIndex || ""};
  box-shadow: ${(props) => props.boxShadow || ""};
  height: ${(props) => props.height || ""};
  @media (min-width: 769px) {
    margin: ${(props) => props.marginMd || ""};
    position: ${(props) => props.positionMd || ""};
    top: ${(props) => props.topMd || ""};
    bottom: ${(props) => props.bottomMd || ""};
    width: ${(props) => props.widthMd || ""};
    padding: ${(props) => props.paddingMd || ""};
  }
  @media (max-width: 768px) {
    max-width: ${(props) => props.maxWidthSm || ""};
    width: ${(props) => props.widthSm || ""};
    padding: ${(props) => props.paddingSm || ""};
    gap: ${(props) => props.gapSm || ""};
  }
`;

export const Col6 = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: ${(props) => props.padding || "0px 5px;"};
  text-align: ${(props) => props.ta || "left"};
  padding-top: ${(props) => props.pt || "7%"};
  border: ${(props) => props.border || " "};
  border-radius: ${(props) => props.radius || " "};
  display: ${(props) => props.display || " "};
  margin: ${(props) => props.margin || " "};
  margin-top: ${(props) => props.mt || " "};
  margin-bottom: ${(props) => props.bottom || " "};
  background: ${(props) => props.back || " "};
  @media (max-width: 992px) {
    padding-top: ${(props) => props.ptMd || " "};
  }
  @media (max-width: 769px) {
    border: ${(props) => props.borderM || props.border || " "};
    padding: ${(props) => props.pMobile || " "};
    padding-top: ${(props) => props.ptMobile || " "};
    flex: ${(props) => props.flex || "0 0 50%"};
    max-width: ${(props) => props.maxwidth || "50%"};
    display: ${(props) => props.displayM || " "};
  }
`;

export const Col7 = styled.div`
  /* flex: 0 0 75%; */
  flex: ${(props) => props.flex || "0 0 75%"};
  max-width: ${(props) => props.maxwi || "75%"};
  padding: ${(props) => props.padding || "0px 5px"};
  text-align: ${(props) => props.ta || "left"};
  padding-top: ${(props) => props.pt || "0%"};
  border: ${(props) => props.border || " "};
  border-radius: ${(props) => props.radius || " "};
  display: ${(props) => props.display || " "};
  background: ${(props) => props.background || " "};
  margin-left: ${(props) => props.mlw || " "};
  width: ${(props) => props.wiSm || " "};
  @media (max-width: 769px) {
    padding: ${(props) => props.pMobile || " "};
    padding-top: ${(props) => props.ptMobile || " "};
    flex: ${(props) => props.flex || "0 0 100%"};
    max-width: ${(props) => props.maxwidth || "75%"};
    display: ${(props) => props.displayM || " "};
    margin-left: ${(props) => props.mlM || " "};
    width: ${(props) => props.wiM || " "};
  }
`;

export const Col11 = styled.div`
  flex: 0 0 91.666667%;
  max-width: ${(props) => props.maxWidth || "91.666667%"};
  margin: auto !important;
  padding: ${(props) => props.padding || "0px 15px"};
  margin: ${(props) => props.margin || "0px 15px"};
  margin-top: ${(props) => props.mt || "0px!important"};
  @media (max-width: 500px) {
    flex: ${(props) => props.flex || "0 0 100%"};
    max-width: ${(props) => props.maxwidth || "100%"};
    margin-top: ${(props) => props.mtM || "0px!important"};
  }
`;

export const Col4 = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  margin: auto !important;
  padding: ${(props) => props.padding || "0px 15px"};
  margin: ${(props) => props.margin || "0px 15px"};
  margin-top: ${(props) => props.mt || "8.5px!important"};
  text-align: ${(props) => props.ta || ""};
  border: ${(props) => props.border || " "};
  border-radius: ${(props) => props.radius || " "};
  cursor: ${(props) => props.cursor || " "};
  display: ${(props) => props.display || " "};
  justify-content: ${(props) => props.jc || " "};
  background: ${(props) => props.background || " "};
  @media (max-width: 500px) {
    padding: ${(props) => props.paddingM || "0px 15px"};
  }
`;

export const Col2 = styled.div`
  flex: 0 0 16.66666666666667%;
  max-width: 16.66666666666667%;
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "0px 15px"};
  margin-top: ${(props) => props.mt || ""};
  display: ${(props) => props.display || ""};
  text-align: ${(props) => props.ta || ""};
  @media (max-width: 769px) {
    flex: ${(props) => props.flexm || "0 0 16.66666666666667%"};
    max-width: ${(props) => props.maxwidthm || "16.66666666666667%"};
    margin: ${(props) => props.marginm || "auto"};
    padding: ${(props) => props.paddingm || "0px 15px"};
  }
`;

export const Col5 = styled.div`
  flex: 0 0 41.66666666666667%;
  max-width: 41.66666666666667%;
  margin: auto !important;
  background: ${(props) => props.back || " "};
  padding: ${(props) => props.padding || "0px 15px"};
  margin: ${(props) => props.margin || "0px 15px"};
  margin-top: ${(props) => props.mt || "8.5px!important"};
  margin-bottom: ${(props) => props.bottom || " "};
  text-align: ${(props) => props.ta || ""};
  border: ${(props) => props.border || " "};
  border-radius: ${(props) => props.radius || " "};
  @media (max-width: 769px) {
    max-width: ${(props) => props.maxwidthMobile || "41.66666666666667%;"};
  }
`;

export const Col10 = styled.div`
  flex: 0 0 83.33333333333333%;
  max-width: 83.33333333333333%;
  margin: ${(props) => props.margin || "auto!important"};
  padding: ${(props) => props.padding || "0px 15px"};
  @media (max-width: 769px) {
    padding: ${(props) => props.pMobile || " "};
    flex: ${(props) => props.flexMobile || "0 0 83.33333333333333%"};
    max-width: ${(props) => props.widthMobile || "83.33333333333333%"};
  }
  @media (max-width: 325px) {
    flex: 0 0 82.5%;
    max-width: 82%;
  }
  @media (max-width: 325px) {
    flex: 0 0 82.5%;
    max-width: 82%;
  }

  @media (max-width: 284px) {
    flex: 0 0 79%;
    max-width: 79%;
  }
`;

export const Col8 = styled.div`
  flex: 0 0 66.666667‬%;
  max-width: 66.666667‬%;
  min-width: ${(props) => props.minWidth || ""};
  margin: ${(props) => props.margin || "auto!important;"};
  padding: ${(props) => props.padding || "0px 15px"};
`;

export const ButtonPassword = styled.button`
  width: ${(props) => props.width || "50px"};
  border-radius: ${(props) => props.br || "5px"};
  background: ${(props) => props.background || "white"};
  color: ${(props) => props.color || "#7084a3"};
  border: ${(props) => props.border || "1px solid #7084a3"};
  margin: ${(props) => props.margin || "10%"};
  height: 47px;
  font-size: 18px;
  padding: 8px;
  box-sizing: border-box;
  margin-left: 0px !important;
  cursor: pointer;
`;

export const ContentButton = styled.div`
  width: ${(props) => props.width || "100%"};
  display: ${(props) => props.display || ""};
  text-align: center;
  margin: auto;
  margin-top: ${(props) => props.mt || "15%"};
  margin-bottom: ${(props) => props.mbW || ""};
  @media (max-width: 769px) {
    width: 100%;
    margin: auto;
    margin-top: ${(props) => props.mtMobile || "20%"};
    margin-bottom: ${(props) => props.mtMobile || ""};
  }
`;

export const LastSession = styled.p`
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  flex: 0 0 100%;
  max-width: 100%;
  font-size: 13px;
  padding: 1%;
  text-align: center;
  color: #7084a3 !important;
  background: #d7e1e6;
  margin-bottom: 1%;
  letter-spacing: -0.015em;
  font-weight: 600;
  @media (max-width: 769px) {
    padding: ${(props) => props.paddingMobile || "2%"};
  }
`;

export const MessageError = styled.p`
  flex: 0 0 100%;
  max-width: 100%;
  font-size: 10px;
  color: red;
  margin-bottom: 0%;
  margin-top: 2%;
  padding-left: ${(props) => props.pl || "0px"};
`;

export const TextFooter = styled.p`
  font-size: ${(props) => props.fontSize || "10px"};
  color: ${(props) => props.color || "#ccd6e5"};
  float: ${(props) => props.float || "center"};
  width: ${(props) => props.width || "50%"};
  padding-left: ${(props) => props.pl || "0px"};
  margin: 0px;
  cursor: pointer;
  text-align: ${(props) => props.ta || "auto"};
  margin-left: ${(props) => props.ml || "0%"};
  margin-right: ${(props) => props.mr || "0%"};
  margin-top: ${(props) => props.mt || "-2%"};
  position: ${(props) => props.position || " "};
  @media (max-width: 460px) {
    font-size: 10px;
    width: ${(props) => props.widthMobile || "50%"};
  }
`;

export const RightsReserved = styled.p`
  display: block;
  font-size: ${(props) => props.fontSize || "10px"};
  margin: 0px;
  width: ${(props) => props.width || "100%"};
  text-align: center;
  padding-bottom: ${(props) => props.pb || "2%"};
  padding-top: ${(props) => props.pt || "2%"};
  color: ${(props) => props.color || ""};
  @media (max-width: 460px) {
    font-size: 10px;
    padding-top: ${(props) => props.ptMobile || "2%"};
  }
`;

export const HeaderReturn = styled.p`
  width: ${(props) => props.width || "50%"};
  margin: auto;
  font-size: 20px;
  color: #7084a3;
  margin-left: ${(props) => props.marginLeft || ""};
`;

// eslint-disable-next-line camelcase
export const Register_Main = styled.div`
  align-items: center;
  background-color: #fff;
  font-family: system-ui;
  height: 100%;
  margin: auto;
  width: 100%;
  margin-bottom: 15%;
`;

export const StatusColor = styled.p`
  background: ${(props) => props.backcolor || ""};
  border-radius: 50%;
  width: ${(props) => props.width || "13px"};
  height: ${(props) => props.height || "13px"};
  float: right;
  border: 1px solid rgb(219, 219, 219);
  cursor: pointer;
  text-align: ${(props) => props.ta || " "};
  margin: ${(props) => props.margin || " "};
  font-size: ${(props) => props.size || " "};
  color: ${(props) => props.color || " "};
  ::hover {
    background: rgb(219, 219, 219);
    color: "#000";
  }
`;

/* Burguer menu */
// eslint-disable-next-line camelcase
export const Toggle_btn = styled.p`
  font-size: 35px;
  cursor: pointer;
  /*background-color: #fff;*/
  color: ${(props) => props.color || "gray"};
  padding: 0;
  border: none;
  margin: 0px;
  margin-top: -5px;
  ::hover {
    background-color: #444;
  }
  @media (max-width: 460px) {
    font-size: ${(props) => props.mfonts || "35px"};
  }
`;
export const Sidepannel = styled.div`
  width: 0;
  position: fixed;
  z-index: 10;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fafafa;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
`;
// eslint-disable-next-line camelcase
export const Header_Home_Mob = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block !important;
  }
`;
// eslint-disable-next-line camelcase
export const Header_Home_Web = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: block !important;
    width: ${(props) => props.width || "60%"};
  }
  @media (max-width: 947px) {
    width: 200%;
  }
  @media (max-width: 814px) {
    width: 230%;
  }
  @media (max-width: 809px) {
    width: 244%;
  }
  @media (max-width: 794px) {
    width: 155%;
  }
  @media (max-width: 778px) {
    width: 114%;
  }
  @media (max-width: 832px) {
    width: 240%;
  }
  @media (max-width: 810px) {
    width: 300%;
  }
  @media (max-width: 800px) {
    width: 125%;
  }
`;
export const CardDescriptionStyled = styled.div`
  width: 32%;
  height: 400px;
  padding: 2rem;
  border: 1px solid #efefef;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
  .top-img {
    width: 50px;
    position: absolute;
    transition: opacity 0.3s ease;
  }
  .text-container {
    color: black;
    margin-top: ${(props) => (props.enumerated ? "6rem" : "8rem")};
    transition: margin-top 0.3s ease;
  }
  .number {
    font-size: 36px;
    line-height: 46px;
  }
  .title {
    font-size: 36px;
    line-height: 46px;
  }
  .subtitle {
    font-size: 20px;
    line-height: 26px;
    margin-top: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover {
    background-color: ${(props) => props.hoverBC || "#56BE8E"};
    color: red;
    .top-img {
      opacity: 0;
    }
    .text-container {
      margin-top: 1rem;
      color: white;
    }
    .subtitle {
      opacity: 1;
    }
  }
`;
/* End burguer menu */

export const DivOptionsCampaign = styled.div`
  display: flex;
  margin: 150px;
  margin-top: ${(props) => props.marginTop || "10px"};
  margin-bottom: ${(props) => props.marginBottom || "20px"};
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  text-align: ${(props) => props.textAlign || ""};
`;

export const SpanTextL = styled.span`
  display: block;
  text-align: ${(props) => props.textAlign || "left"};
  width: 70%;
  float: left;
`;

export const SpanTextR = styled.span`
  display: block;
  text-align: ${(props) => props.textAlign || "left"};
  width: 30%;
  float: ringth;
  white-space: ${(props) => props.ws || ""};
  text-overflow: ${(props) => props.to || ""};
  overflow: ${(props) => props.ov || ""};
`;

export const LinkList = styled.a`
  color: #07210a !important;
`;

export const CirculeRachas = styled.div`
  background: ${(props) => props.background || "#6BD5AF"};
  border: ${(props) => props.border || "10px solid #FFFFFF"};
  box-sizing: border-box;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
  width: ${(props) => props.width || "95px"};
  border-radius: 50%;
  height: ${(props) => props.height || "95px"};
  display: flex;
  margin: ${(props) => props.margin || " "};
  @media (min-width: 769px) {
    width: ${(props) => props.widthD || "83px"};
    margin: auto;
    height: ${(props) => props.heightD || "83px"};
  }
`;

export const SpanResumen = styled.span`
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #07210a;
  margin: auto;
  text-align: center;
`;

export const ContainerLogrados = styled.div`
    width: 90%;
    margin:auto;
    margin-top: 10%;
    margin-bottom: 5%;
    display: flex;
    background: url(${BackFiguras}), url(${BackFiguras});
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    @media (min-width: 769px) {
        background-size: 50%;
        background-repeat: repeat-x;
    }
}
`;

export const CainerBloqueados = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  height: 300px;
`;

export const CirculeRedes = styled.div`
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background: #f64282;
  display: flex;
`;

export const DivScroll = styled.div`
  /*height: 150px;*/
  /*overflow: auto;*/
`;

export const DivGoals = styled.div`
  position: absolute;
  width: 96px;
  height: 22px;
  font-weight: ${(props) => props.fw || "normal"};
  font-size: 11px;
  line-height: 110%;
  /* or 12px */
  letter-spacing: -0.003em;
  color: #615959;
  @media (max-width: 769px) {
    width: ${(props) => props.wim || "96px"};
  }
`;
export const DivContainerGoal = styled.div`
  /* Rectangle 5 */
  width: 103px;
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
`;
export const DivContainerTextGoal = styled.div`
  /* $10,000.00 */
  width: ${(props) => props.width || "81px"};
  height: ${(props) => props.hei || "19px"};
  text-align: ${(props) => props.ta || "center"};
  font-weight: ${(props) => props.fw || "500"};
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #07210a;
  @media (max-width: 769px) {
    font-size: ${(props) => props.fsm || "16px"};
  }
`;

export const DivNotoficacion = styled.div`
  margin-left: auto;
  margin-top: -35%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f64282;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-letter-spacing: -0.3px;
  -moz-letter-spacing: -0.3px;
  -ms-letter-spacing: -0.3px;
  letter-spacing: -0.3px;
  color: #fff;
`;

export const ContentLink = styled.span`
  color: #f64282;
  cursor: pointer;
  z-index: 2;
`;

export const DivContainerFlex = styled.div`
  display: flex;
  margin: auto auto auto 18%;
  @media (max-width: 769px) {
    margin: auto;
  }
`;

export const ContainerInvite = styled.div`
  width: 70%;
  margin: auto auto 30px;
  border-radius: 4px;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const TitleInvite = styled.div`
  background-color: #f5d495;
  color: #fff;
  text-align: center;
  padding: 8px;
  font-size: 18px;
`;

export const InfoInvite = styled.div`
  background-color: #f7f7f7;
  font-size: 12px;
  width: 27%;
  text-align: center;
`;

export const ContainerRequest = styled.div`
  min-width: ${(props) => props.mw || ""};
  width: ${(props) => props.width || "452px"};
  margin: ${(props) => props.margin || "auto auto 30px"};
  border-radius: 4px;
  border: ${(props) => props.border || "1px solid #F3F3F3"};
  box-sizing: border-box;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
  @media (max-width: 992px) {
    width: 100%;
    min-width: ${(props) => props.mwM || ""};
  }
`;

export const ContainerFlexSolicitud = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.jc || "space-between"};
  align-items: ${(props) => props.ai || "start"};
  flex-wrap: ${(props) => props.fw || "nowrap"};
  padding: ${(props) => props.pd || "16px 21px"};
`;

export const SolicitudText = styled.div`
  font-size: ${(props) => props.fs || "16px"};
  color: ${(props) => props.fc || "#238700"};
  font-weight: ${(props) => props.fw || "bold"};
  margin-left: ${(props) => props.ml || ""};
  margin-top: ${(props) => props.mt || ""};
  margin-bottom: ${(props) => props.mb || ""};
  margin-right: ${(props) => props.mr || ""};
  min-height: ${(props) => props.minh || ""};
  @media (max-width: 768px) {
    font-size: ${(props) => props.fsM || "16px"};
  }
`;

export const TxtDetalle = styled.div`
  text-align: center;
  padding: 21px;
  font-size: 16px;
  color: #252525;
  cursor: ${(props) => props.cursor || ""};
`;

export const ContTel = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const UlCoppel = styled.ul`
  padding-inline-start: 20px;
`;

export const CustomLi = styled.li`
  list-style-image: url(${(props) => props.image || ""});
  &::marker {
    width: 5px;
  }
  font-size: 18px;
  line-height: 35px;
  letter-spacing: -0.2px;
  color: black;
  font-weight: 400;
`;

export const A = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
`;
export const TextGeneric = styled.p`
  text-align: ${(props) => props.textAlign || "center"};
  font-family: ${(props) => props.fontFamily || ""};
  font-weight: ${(props) => props.fontWeight || ""};
  font-style: ${(props) => props.fontStyle || ""};
  text-align: ${(props) => props.textAlign || ""};
  align-items: ${(props) => props.alignItems || ""};
  margin-bottom: ${(props) => props.marginBottom || ""};
  margin-top: ${(props) => props.marginTop || ""};
  margin-right: ${(props) => props.marginRight || ""};
  margin-left: ${(props) => props.marginLeft || ""};
  letter-spacing: ${(props) => props.letterSpacing || ""};
  padding: ${(props) => props.padding || "0"};
  width: ${(props) => props.wid || ""};
  max-width: ${(props) => props.maxWidth || ""};
  height: ${(props) => props.height || ""};
  font-size: ${(props) => props.fontSize || ""};
  color: ${(props) => props.fontColor || ""};
  margin: ${(props) => props.margin || ""};
  padding-top: ${(props) => props.pt || " "};
  line-height: ${(props) => props.lineHeight || "130%"};
  display: ${(props) => props.display || ""};
  @media (min-width: 768px) {
    font-size: ${(props) => props.fontSizeG || ""};
    margin: ${(props) => props.marginG || ""};
    margin-bottom: ${(props) => props.marginBottomL || ""};
    margin-top: ${(props) => props.marginTopL || ""};
    text-align: ${(props) => props.textAlignL || ""};
    padding-left: ${(props) => props.paddingLeftL || ""};
  }
  @media (max-width: 767px) {
    font-size: ${(props) => props.fontSizeSm || ""};
    margin: ${(props) => props.marginSm || ""};
    margin-bottom: ${(props) => props.marginBottomSm || ""};
    margin-top: ${(props) => props.marginTopSm || ""};
    text-align: ${(props) => props.textAlignSm || ""};
    padding-left: ${(props) => props.paddingLeftSm || ""};
  }
`;

export const HelpIcon = styled.span`
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 100%;
  font-size: 10px;
  text-align: center;
  color: #f64282 !important;
  border: 1.5px solid #f64282;
  font-weight: 700;
  margin-left: 12px;
  cursor: pointer;
  line-height: 13px;
`;
