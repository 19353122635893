import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ContainerRequest, Image, ContainerFlexSolicitud, SolicitudText,
} from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import Cartera from '../../assets/img/logros/Cartera.svg';
import Flag from '../../assets/img/logros/Flag.svg';
import Share from '../../assets/img/logros/Share.svg';
import { GET_PRODUCTS_BY_LADING } from '../../queries';
import { CREATE_OPPORTUNITIES } from '../../mutations';

import {
  CardsBtn,
} from '../../assets/styles/Training.styled';

function RelacionadoSolicitud({ id, idProd }) {
  const history = useHistory();
  const [products, setProducts] = useState([]);

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted() {
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getProductsLp] = useLazyQuery(GET_PRODUCTS_BY_LADING, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsLp }) {
      if (getProductsLp.message === 'success') {
        setProducts(JSON.parse(getProductsLp.response));
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const selectProduct = (idProduct, clickOut) => {
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1, // remplazar por el id del entrenamiento
        idProducts: Number(idProduct), // arreglo de numero ejemplo [10,1,3]
        idTraining: 1,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    window.open(clickOut, '_blank');
  };
  useEffect(() => {
    getProductsLp({
      variables: {
        idLandingPage: 2,
      },
    });
  }, []);
  const [goTrainingRazones] = useState(false);
  useEffect(() => {
    if (goTrainingRazones) {
      history.push('/wo/rejection-reasons');
    }
  }, [goTrainingRazones]);

  return (
        <Fragment>
            <div className="" id={`InfoRechazo-${id}`}>
                <ContainerRequest>
                    <SolicitudText ml='20px' mt="30px" fs='30px' fc='#252525' fw='600'>{idProd === -1 ? 'Recomendado' : 'Relacionado a lo que solicitaste'}</SolicitudText>
                        <div style={{ display: 'flex', overflowX: 'scroll', backgroundColor: '#FFFFFF' }}>
                        {products.map((product) => {
                          if (idProd !== product.iDProduct) {
                            return (
                                <ContainerRequest mwM="85%" width="335px" mw="335px" margin="0 10px">
                                    <ContainerFlexSolicitud>
                                        <Image src={product.urlImage} width="auto" height="60px" marginRight="0" marginRightM="0"/>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Image cursor='pointer' src={Cartera} width="20px" marginRight="0" marginRightM="0"/>
                                                <Image cursor='pointer' src={Flag} width="20px" marginRight="0" marginRightM="0"/>
                                                <Image cursor='pointer' src={Share} width="20px" marginRight="0" marginRightM="0"/>
                                            </div>
                                            <SolicitudText fs='14px' fc="#07210A" fw='400'>
                                                Anualidad
                                            </SolicitudText>
                                            <SolicitudText fc='#07210A' fw='600' fs='20px'>${product.annuity}</SolicitudText>
                                        </div>
                                    </ContainerFlexSolicitud>
                                    <SolicitudText minh="50px" ml='22px' fs='18px' fc='#07210A' fw='600'>{product.name}</SolicitudText>
                                    <div style={{ padding: '10px 22px', color: '#07210A' }}>
                                        <SolicitudText fs='16px' fc="#07210A" fw='bold'>
                                            Beneficios:
                                        </SolicitudText>
                                        <ul style={{ minHeight: '160px' }}>
                                        {
                                            product.benefits.map((benefit) => (benefit === '-' ? null : <li className="roboto" style={{ fontSize: '12px' }}>{benefit}</li>))
                                        }
                                        </ul>
                                        <SolicitudText fs='16px' fc="#07210A" fw='bold'>
                                            Información extra:
                                        </SolicitudText>
                                        <ul>
                                            <li className="roboto" style={{ fontSize: '12px' }}>CAT: {(product.extraInfo.cat).toFixed(2)}%</li>
                                            <li className="roboto" style={{ fontSize: '12px' }}>Tasa de interés: {(product.extraInfo.interes).toFixed(2)}%</li>
                                        </ul>
                                        <CardsBtn
                                            type="button"
                                            min_w="100%"
                                            min_web="100%"
                                            mtopw="10px"
                                            mtop="0px"
                                            mtopmd="0px"
                                            onClick={() => selectProduct(product.iDProduct, product.clickOut)}
                                        >
                                            Continuar
                                        </CardsBtn>
                                    </div>
                                </ContainerRequest>
                            );
                          }
                        })}
                        </div>
                    <SolicitudText mr='20px' mb='30px' ml='20px' mt="24px" fs='14px' fc='#252525' fw='400'>
                        Te recomendamos esperar 3 meses para aplicar de nuevo a esta(s) tarjeta(s). Si necesitas
                        un crédito de manera urgente, <b>estas opciones</b> de tarjetas garantizadas pueden ayudarte.
                    </SolicitudText>
                </ContainerRequest>
            </div>
        </Fragment>
  );
}
export default RelacionadoSolicitud;
