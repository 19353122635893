import React, {
  Fragment, useEffect, useState,
} from 'react';
import '../../assets/styles/Home.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import HeaderSuccess from '../Training/HeaderSucces';
import enproceso from '../../assets/img/anacoach/faces-02.png';
import {
  DYNAMIC_GET,
  UPDATE_STATUS_TRAINING_SUCCESS,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';

import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingSectionFlex,
  DivLoader,
} from '../../assets/styles/Training.styled';
import {
  SeparateSection,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../common/Home.styled';
import { Container } from '../../assets/styles/Home.styled';
import Recommendations from '../Training/RecommendedTraining';
import Header from '../common/Header';
import Footer from '../common/Footer';
import CardOne from '../Home/Card1Animate';
import ModalConSinPremio from '../Feed/ModalConSinPremio';
import ShareSection from '../common/ShareSection';
import ClickButton from '../../Helpers/HookHelpers';
import Resultados from '../Training/Success_Result';

function ClickOutRp() {
  const { actions } = ClickButton();
  const [hiddenLoader, setHiddenLoader] = useState(true);
  const [
    recommendedTrainingForModal,
    setRecommendedTrainingForModal,
  ] = useState({});
  const [goFeed, setGoFeed] = useState(false);
  const history = useHistory();
  useEffect(() => {
    sessionStorage.removeItem('numCards');
  }, []);
  let totalSol = '';
  sessionStorage.setItem('clickEmpezarAhoraHome', 'true');
  let selectProduct = [];
  const [resultadosItems, setResultadosItems] = useState([]);

  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
    const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
  }, [goFeed]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'SuccessCC',
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  useEffect(() => {
    if (
      (sessionStorage.getItem('CatProductos') != null
        || sessionStorage.getItem('CatProductosTemp') != null)
      && sessionStorage.getItem('datProducts') != null
    ) {
      const jsonProducts = JSON.parse(
        sessionStorage.getItem('CatProductos')
          || sessionStorage.getItem('CatProductosTemp'),
      );
      const idsOpportunities = sessionStorage
        .getItem('datOpportunity')
        .split(',');
      totalSol = idsOpportunities.length;
      const arrayIdProducts = sessionStorage.getItem('datProducts').split(',');
      for (let i = 0; i < arrayIdProducts.length; i++) {
        for (let j = 0; j < jsonProducts.length; j++) {
          if (
            parseInt(jsonProducts[j].IDProduct) === parseInt(arrayIdProducts[i])
          ) {
            console.log(jsonProducts[j].IDProduct, arrayIdProducts[i]);
            jsonProducts[j].idOpportunity = idsOpportunities[i];
            selectProduct.push(jsonProducts[j]);
          }
          // });
        }
      }
      setHiddenLoader(false);
      setResultadosItems(getResultsItems(selectProduct));

      console.log('resultadosItems', resultadosItems);
    } else {
      selectProduct = [
        {
          IDProduct: 2,
          Name: 'The Gold Elite Credit Card American Express',
          ImageURL:
            'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/GoldEliteAMEX.png',
        },
      ];

      setResultadosItems(getResultsItems(selectProduct));
      console.log('resultadosItems', resultadosItems);
    }
  }, []);
  const getResultsItems = (selectProduct) => {
    const resultItems = [];
    if (selectProduct.length === 3) {
      for (let i = 0; i < selectProduct.length; i++) {
        resultItems.push({
          idOpportunity: selectProduct[i].idOpportunity,
          Name: selectProduct[i].Name,
          BusinessAbr: selectProduct[i].BusinessAbr,
          id: selectProduct[i].IDProduct,
          imgCard: selectProduct[i].ImageURL,
          titleCard: selectProduct[i].Name,
          ClickOut: selectProduct[i].ClickOut,
          hasClickOut: selectProduct[i].hasClickOut,
          imgresult: enproceso,
          resultado: 'En proceso',
          msgresultado: '¡Espera! Seguimos trabajando',
          ScrapperHabilitado: selectProduct[i].Enabled,
          info:
            `${'Fecha de solicitud  '
            + ' '}${
              dateNow.getDate()
            }/${
              dateNow.getMonth() + 1
            }/${
              dateNow.getFullYear()}`,
          txtadicional:
            'Tu solicitud no pudo ser completada en línea. Te enviaremos el estatus a tu correo electrónico registrado.',
          documentos: '',
          ClickOut: selectProduct[i].ClickOut,
        });
      }
    } else {
      for (let i = 0; i < selectProduct.length; i++) {
        resultItems.push({
          idOpportunity: selectProduct[i].idOpportunity || '',
          Name: selectProduct[i].Name || '',
          BusinessAbr: selectProduct[i].BusinessAbr || '',
          id: selectProduct[i].IDProduct,
          imgCard: selectProduct[i].ImageURL,
          titleCard: selectProduct[i].Name,
          ClickOut: selectProduct[i].ClickOut,
          hasClickOut: selectProduct[i].hasClickOut,
          imgresult: enproceso,
          resultado: 'En proceso',
          msgresultado: '¡Espera! Seguimos trabajando',
          ScrapperHabilitado: selectProduct[i].Enabled,
          info:
            `${'Fecha de solicitud'
            + ' '}${
              dateNow.getDate()
            } / ${
              dateNow.getMonth() + 1
            } / ${
              dateNow.getFullYear()}`,
          txtadicional:
            'Tu solicitud no pudo ser completada en línea. Te enviaremos el estatus a tu correo electrónico registrado.',
          documentos: '',
        });
      }
    }
    return resultItems;
  };
  var dateNow = new Date();

  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';

  firstname = sessionStorage.getItem('FirstName');
  nameClient = `${sessionStorage.getItem('FirstName')
  } ${
    sessionStorage.getItem('FathersLastName')}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');
  const resultsProducts = [];
  useEffect(() => {
    if (sessionStorage.getItem('datOpportunity') !== null) {
      const opportunities = sessionStorage.getItem('datOpportunity').split(',');
      for (const i in opportunities) {
        const getProductsInput = {
          input: {
            table: 'DatOpportunity, CatProduct',
            columns: ['*'],
            conditions: [
              {
                valone: 'IDProduct',
                valtwo: 'CatProduct_IDProduct',
                condition: '=',
                validColumn: true,
              },
              {
                valone: 'IDDatOpportunity',
                valtwo: opportunities[i],
                condition: '=',
                logic: 'AND',
              },
            ],
          },
        };
        getOpportunity({ variables: getProductsInput });
      }
    } else {
      console.log('Sin data opportunity');
    }
  }, []);

  const [getOpportunity] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        console.log('dynamicGet', dynamicGet.response);
        if (dynamicGet.response !== '') {
          const productsDetails = JSON.parse(dynamicGet.response)[0];
          resultsProducts.push(productsDetails);
          console.log(productsDetails);
        }
      }
    },
  });

  const [updateStatusTrainingSuccess] = useMutation(
    UPDATE_STATUS_TRAINING_SUCCESS,
    {
      onCompleted({ updateStatusTrainingSuccess }) {
        if (updateStatusTrainingSuccess.statusCode === 200) {
          console.log('statusTraining', updateStatusTrainingSuccess.response);
        }
      },
    },
  );
  const [top, setTop] = useState(false);
  useEffect(() => {
    if (top === false) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setTop(true);
    }
  }, [top]);

  useEffect(() => {
    if (!hiddenLoader) {
      try {
        const datOpportunity = sessionStorage.getItem('datOpportunity');
        const idDatTraining = sessionStorage.getItem('IdDatTraining');
        const idUser = sessionStorage.getItem('idUser');
        const inputStatuFinal = {
          input: {
            datOpportunity,
            idDatTraining,
            idUser,
          },
        };
        if (datOpportunity && idDatTraining && idUser) {
          console.log('inputStatuFinal', inputStatuFinal);
          setTimeout(() => {
            updateStatusTrainingSuccess({ variables: inputStatuFinal });
          }, 4000);
        }
      } catch (e) {
        console.log('inputStatuFinal', e);
      }
    } else {
      console.log('no se oculta loader');
    }
  }, [hiddenLoader]);

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
          const filter = getPersonalizeRecommendationTrainings.response.filter(
            (e) => e.TrainingBigTitle === 'Protege a tu familia'
              || e.TrainingBigTitle === 'Adelgaza tus deudas',
          );
          setRecommendedTrainingForModal(filter);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  return (
    <Fragment>
      {recommendedTrainingForModal.length > 0 ? (
        <ModalConSinPremio
          show={
            sessionStorage.getItem('medium') === 'recompensas'
          }
          recommendedTraining={recommendedTrainingForModal}
          premio={
            sessionStorage.getItem('campaign') === 'con-premio'
          }
        />
      ) : null}

      <Header background={'#fafafa'} pathname={window.location.pathname} />
      <HeaderSuccess
        color1="#E2C2EE"
        color2="#6677E3"
        color3="#6677E3"
        color4="#E2C2EE"
        color5="#8358C2"
        color6="#6677E3"
        color7="#A7FOFA"
        color8="#8358C2"
        color9="#CBBFED"
        color10="#6677E3"
      />
      <TrainingMain mt="100px" mtw="0" className="" id="top">
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            paddiw="10% 10% 3% 10%"
            paddi="35% 5% 5% 5%"
            back="#fafafa"
          >
            <TrainingSubtitles
              size="22px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              ¡Gracias {firstname?.trim()}!
            </TrainingSubtitles>
            <TrainingText
              size="18px"
              weight="600"
              className="roboto"
              align="center"
              alignweb="center"
              ls="-0.2px"
            >
              Hemos procesado tu solicitud y este es el resultado.
            </TrainingText>
            <br />
            <TrainingSubtitles
              size="18px"
              fw="600"
              align="left"
              alignweb="left"
              className="cabin"
            >
              Estos son tus datos que registramos:
            </TrainingSubtitles>
            <br />
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              <b>Nombre cliente:</b> {nameClient}
              <br />
              <b>Teléfono:</b> {telClient}
              <br />
              <b>Email:</b> {emailClient}
            </TrainingText>
          </TrainingDivSection2>
          <SeparateSection />
        </TrainingSectionGray>
        <TrainingDivSection
          mt="20px"
          back="#fafafa"
          backw="#fafafa"
          wi="335px"
          wiw="auto"
          padding="0"
          paddw="0"
        >
          <TrainingSectionFlex display="flex">
            {resultadosItems.length > 0 ? (
              <Resultados
                idTraining={sessionStorage.getItem('idTraining')}
                resultados={
                  resultadosItems.length > 0 ? resultadosItems : resultadosItems
                }
              ></Resultados>
            ) : null}

            {hiddenLoader ? (
              <TrainingDivSection id="loadsuccess">
                <DivLoader>
                  <CircularProgress color="inherit" />
                  <br />
                  <TrainingText className="dm-sans" align="center">
                    Estamos procesando tu solicitud, tardaremos unos minutos...
                  </TrainingText>
                </DivLoader>
              </TrainingDivSection>
            ) : null}
          </TrainingSectionFlex>
        </TrainingDivSection>

        <ShareSection WO="SolicitarTarjeta" />
        {recomendaciones ? (
          <Fragment>
            <TrainingSectionGray back="#fafafa">
              <TrainingDivSection2
                wiw="670px"
                paddi="0"
                paddiw="0"
                mw="auto"
                back="#fafafa"
              >
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text="¿Aún quieres ir por más?"
                  text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                />
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                />
              </TrainingDivSection2>
            </TrainingSectionGray>

            <Fragment>
              <Container
                mleftmxs="0%"
                mleft="5%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="90%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </Fragment>
          </Fragment>
        ) : null}
      </TrainingMain>
      <Footer pathname={window.location.pathname} />
    </Fragment>
  );
}

export default ClickOutRp;
