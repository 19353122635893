import styled from "styled-components";

export const CheckMainContainer = styled.div`
  width: 60%;
  position: relative;
  margin-top: 20px;
  margin-left: 18%;
  padding: 22px 17px;
  background-color: rgba(166, 166, 166, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 768px){
    width: 90%;
    margin: 20px auto 0 auto;
  }
`;

export const CheckItemContainer = styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center;
`;

export const CheckListDivisor =  styled.div`
  height: 0.5px;
  background: #A6A6A6;
`;

export const CheckBoxItem = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid #A5A6F6;
  background: #FFF;
  cursor: pointer;
`;

export const FormContainer = styled.div`
  width: 60%;
  position: relative;
  margin-top: 20px;
  margin-left: 18%;
  padding: ${props => props.padding || '22px 17px'};
  background-color: ${props => props.bgColor || 'rgba(166, 166, 166, 0.12)'};
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '12px'};
  @media (max-width: 768px){
    width: 90%;
    margin: 20px auto 0 auto;
  }
`;

export const ItemContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
  padding: 22px 17px;
  background-color: rgba(166, 166, 166, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 768px){
    margin: 20px auto 0 auto;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 22px;
  align-items: center;
  padding: 2px 6px;
`;


export const FormInstruction = styled.div`
  padding: 6.25px 2.25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const FormInpust = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentViewer = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const DocumentObject = styled.object`
  width: 100%;
  &::webkit-scrollbar{
    display: none;
  }
`;

export const DeleteDocument = styled.div`
  width: 24px;
  height: 24px;
  bakground-color: #e3e3e3;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  color: black;
  &:hover {
    cursor: pointer;
  }
`;

export const InputFileLabel = styled.label`
  padding: 6.25px 2.25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:12px;
  &:hover {
    cursor: pointer;
  }
`;