import React, {Fragment, useState} from "react";
import {
  SubtitleOne,
  Container,
  Col6,
} from "../../components/common/common.styled";
import "../../assets/styles/TextCustom.css";
import InputAna from "../../components/common/InputAna";

const NameComplet = ({form, setForm, setvalidCampos, validCampos}) => {
  const [error] = useState({
    firstname: false,
    secondname: false,
    lastname: false,
    motherslastname: false,
    firstnamevalid: -1,
    secondnamevalid: -1,
    lastnamevalid: -1,
    motherslastnamevalid:-1,
  });

  const [texterror] = useState({
    firstname: "",
    secondname: "",
    lastname: "",
    motherslastname: "",
  });

  const ValidLetras = (e) => {
    var nombre = e.target.name;
    var valor = e.target.value;

    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ""),
    });
    if (valor.length > 2) {
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = "";
    } else if (nombre === "secondname") {
      if (valor.length === 0) {
        error[nombre] = false;
        texterror[nombre] = "";
        error[`${nombre}valid`] = -1;
      } else {
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = "Debe ingresar más de 3 caracteres";
      }
    } else {
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = "Debe ingresar más de 3 caracteres";
    }
    ValidCampos();
  };

  const ValidCampos = () =>
  {
    if (
      (!error.firstname && form.firstname.length >= 3) &&
      (!error.lastname && form.lastname.length >= 3) &&
      (!error.motherslastname && form.motherslastname.length >= 3) &&
      (!error.secondname)
     ) {
       setvalidCampos({
         ...validCampos, 
         personales: true
       });
     }
     else{
       setvalidCampos({
         ...validCampos, 
         personales: false
       });
     }
  }


  return (
    <Fragment>
      <SubtitleOne
        className="font-weight-bold roboto"
        size="16px"
        sizeMobile="16px"
        mb="0px">
        Nombre completo
      </SubtitleOne>
      <form autoComplete="off" className="">
        <Container width="100%" widthMobile="100%">
          <Col6 padding="0px 2.5px 0px 0px" pt="2%">
            <InputAna
              placeholder="Primer nombre"
              value={form.firstname}
              id={"firstname"}
              type="text"
              handleChange={ValidLetras}
              handleBlur={ValidLetras}
              error={error.firstname}
              texterror={texterror.firstname}
              errorinicial={error.firstnamevalid}
            />
          </Col6>
          <Col6 padding="0px 0px 0px 2.5px" pt="2%">
            <InputAna
              placeholder="Segundo Nombre"
              id={"secondname"}
              value={form.secondname}
              type="text"
              handleChange={ValidLetras}
              error={error.secondname}
              texterror={texterror.secondname}
              errorinicial={error.secondnamevalid}
            />
          </Col6>
          <Col6 padding="0px 2.5px 0px 0px" pt="2%">
            <InputAna
              placeholder="Apellido Paterno"
              id={"lastname"}
              value={form.lastname}
              type="text"
              handleBlur={ValidLetras}
              handleChange={ValidLetras}
              error={error.lastname}
              texterror={texterror.lastname}
              errorinicial={error.lastnamevalid}
            />
          </Col6>
          <Col6 padding="0px 0px 0px 2.5px" pt="2%">
            <InputAna
              placeholder="Apellido Materno"
              id={"motherslastname"}
              value={form.motherslastname}
              type="text"
              handleChange={ValidLetras}
              handleBlur={ValidLetras}
              maxlength={52}
              error={error.motherslastname}
              texterror={texterror.motherslastname}
              errorinicial={error.motherslastnamevalid}
            />
          </Col6>
        </Container>
      </form>
    </Fragment>
  );
};

export default NameComplet;
