import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import WOlayout from '../../components/layouts/WOlayout';
import Logo from '../../assets/img/anacoach/CoruLogoB.png';
import Coppel from '../../assets/img/anacoach/Coppel.png';
import {
  Container1,
  Subtitle,
  Title2,
} from '../../components/common/Home.styled';
import { Container } from '../../components/common/common.styled';
import { CALL_SCRAPPER, SAVE_AUTH_OTP } from '../../mutations';
import {
  getJsonCoppel,
  getVariablesScrapper,
} from '../../Helpers/JsonScrappers';
import { getItemSessionStorage } from '../../Helpers/general';

const LpCoppelOTP = () => {
  const [callScrapper, { data: dataScrapper }] = useMutation(CALL_SCRAPPER);
  const jsonAmex = getJsonCoppel('Coppel');
  const [saveAuthOtp, { data: dataOTP }] = useMutation(SAVE_AUTH_OTP);
  const encodeString = btoa(JSON.stringify(jsonAmex));
  const config = {
    variables: getVariablesScrapper({ encodeString, bank: 'Coppel' }),
  };
  const [numberPhone, setNumberPhone] = useState(
    sessionStorage.getItem('CellPhone'),
  );

  const [otp, setOtp] = useState({
    num1: null,
    num2: null,
    num3: null,
    num4: null,
  });
  const [valid, setValid] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const {
      num1, num2, num3, num4,
    } = otp;
    if (num1 && num2 && num3 && num4) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [otp]);
  useEffect(() => {
    const scrapper = callScrapper(config);
    return () => clearTimeout(scrapper);
  }, []);
  useEffect(() => {
    if (dataScrapper) {
      console.log(dataScrapper);

      const { response, comment } = dataScrapper.callScrapper;
      switch (response) {
        case 'ErrorDatos':
          sessionStorage.setItem('CoppelStatus', '1');
          sessionStorage.setItem('CoppelComment', comment);
          break;
        case 'Rechazada':
          sessionStorage.setItem('CoppelStatus', '1');
          sessionStorage.setItem('CoppelComment', comment);
          break;
        case 'Aprobada':
          sessionStorage.setItem('CoppelStatus', '2');
          break;
        default:
          sessionStorage.setItem('CoppelStatus', '0');
          sessionStorage.setItem('CoppelComment', comment);
          break;
      }
      window.location = '/lp/coppelSuccess';
    }
  }, [dataScrapper]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const email = getItemSessionStorage('Email1');
    const code = `${otp.num1}${otp.num2}${otp.num3}${otp.num4}`;
    const input = { email, token: code, partner: 'Coppel' };
    setClicked(true);
    return saveAuthOtp({ variables: { input } });
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setOtp({ ...otp, [name]: value });
    if (value !== '') {
      switch (name) {
        case 'num1':
          document.querySelector("input[name='num2']").focus();
          break;
        case 'num2':
          document.querySelector("input[name='num3']").focus();
          break;
        case 'num3':
          document.querySelector("input[name='num4']").focus();
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case 'num2':
          document.querySelector("input[name='num1']").focus();
          break;
        case 'num3':
          document.querySelector("input[name='num2']").focus();
          break;
        case 'num4':
          document.querySelector("input[name='num3']").focus();
          break;
        default:
          break;
      }
    }
  };

  return (
    <WOlayout>
      <div className="navbar navbar-light bg-light shadow-sm">
        <a href="#" className="navbar-brand">
          <img src={Logo} alt="" style={{ maxWidth: '91px' }} />
        </a>
        <a href="#" className="ml-auto navbar-brand">
          <img
            src={Coppel}
            alt=""
            style={{ maxWidth: '91px', maxHeight: '32px' }}
          />
        </a>
      </div>
      <Container1>
        <Container
          width="100%"
          height="80vh"
          padding="20px"
          boxSizing="border-box"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100%', maxWidth: '450px' }}>
            <Title2 topD=" " aling="center" className="roboto">
              Ingresa tu código
            </Title2>
            <Subtitle
              width=" "
              widthM=" "
              mleftM=" "
              marginTop="20px"
              aling="center"
              className="roboto"
            >
              Enviamos el código al número ******{numberPhone.slice(-4)}
            </Subtitle>
            <Subtitle
              width=" "
              widthM=" "
              mleftM=" "
              marginTop="20px"
              aling="center"
              className="roboto"
              size="18px"
              sizeM="14px"
            >
              Ingresa tu código de 4 dígitos
            </Subtitle>
          </div>
          <Contendor>
            <div className="row justify-content-around">
              <Input
                value={otp.num1}
                name="num1"
                type="tel"
                maxLength={1}
                onChange={handleChange}
                disabled={clicked}
              />
              <Input
                value={otp.num2}
                name="num2"
                type="tel"
                maxLength={1}
                onChange={handleChange}
                disabled={clicked}
              />
              <Input
                value={otp.num3}
                name="num3"
                type="tel"
                maxLength={1}
                onChange={handleChange}
                disabled={clicked}
              />
              <Input
                value={otp.num4}
                name="num4"
                type="tel"
                maxLength={1}
                onChange={handleChange}
                disabled={clicked}
              />
            </div>
          </Contendor>
          <Reenviar className="roboto">Reenviar Código</Reenviar>
          <Button className="roboto" active={valid} onClick={handleSubmit}>
            {clicked ? (
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Solicitar'
            )}
          </Button>
        </Container>
      </Container1>
    </WOlayout>
  );
};

const Input = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  background-color: transparent;
  max-width: 120px;
  height: 64px;
  text-align: center;
  @media (max-width: 480px) {
    max-width: 72px;
    height: 56px;
  }
`;

const Contendor = styled.div`
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 970px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 100%;
  }
`;

const Reenviar = styled.button`
  border: none;
  background: none;
  color: blue;
  text-align: center;
  font-size: 16px;
  &: hover {
    background: none;
    color: blue;
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  background: ${(props) => (props.active ? '#FFDD2F' : '#C4C4C4')};
  color: black;
  width: 100%;
  height: 50px;
  max-width: 400px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  @media (max-width: 480px) {
    max-width: 100%;
  }
  &: hover {
    background: ${(props) => (props.active ? '#FFDD2F' : '#C4C4C4')};
    color: black;
    border: none;
  }
`;

export default LpCoppelOTP;
