import React from 'react';
import { Col7, Container } from '../common/common.styled';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import { ScoreText } from '../../assets/styles/Training.styled';
import '../../assets/styles/Card1Animate.css';

const CardTitle = ({
  classCard,
  classTop,
  text,
  textbold,
  textColor,
  color,
  ClassTxt,
  text2,
  onClick = null,
  widthcontentw,
  classAna,
}) => (
  <Container
    width={widthcontentw !== undefined ? widthcontentw : '80%'}
    mt="0"
    widthMobile="90%"
    mtMobile="0"
    displayweb={
      classCard === 'cardOne' || classCard === 'cardOne cardOneMargin'
        ? 'flex'
        : 'none'
    }
    displaymobile={classCard === 'cardOne' ? 'flex' : 'none'}
  >
    <ImageCoachInicio classAna={classAna} topMobile="27px" />
    <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
      <div className={`${classCard} ${classTop}`} onClick={onClick}>
        <div className={`${ClassTxt}`}>
          <p className="bold-p">{textbold}</p>
          {text !== '' ? <p>{text}</p> : null}
          <ScoreText
            c={color}
            fw="bold"
            fs="18px !important"
            ta="center"
            mt="20px"
            mb="20px !important"
          >
            {textColor}
          </ScoreText>
          {text2 !== '' ? <p>{text2}</p> : null}
        </div>
      </div>
    </Col7>
  </Container>
);

export default CardTitle;
