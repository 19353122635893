import React, { Fragment } from 'react';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import AforeCalculator from '../Routines/AforeCalculator';
import Afores from '../Routines/Afores';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderWoAfores = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
}) =>
  (
    <Fragment>
      {orderRutine >= 1 ? (
        <ModalLogin
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={1}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
        // <DatBasic
        // Date={Date}
        // hora={hora}
        // orderRutine={orderRutine}
        // setOrderRutine={setOrderRutine}
        // currentTraining={currentTraining}
        // createJsonInput={createJsonInput}
        // questions={questions}
        // setQuestions={setQuestions}
        // trainingColor={trainingColor}
        // currentRoutine={1}
        // nextOrderRoutine={2}
        // recoveryTimeout={true}
        // />
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={2}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null }
      {/* {orderRutine >= 3 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={3}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null} */}
      {orderRutine >= 4 ? (
        <AforeCalculator
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={4}
          nextOrderRoutine={5}
        />
      ) : null}
      {orderRutine >= 5 ? (
        <Afores
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={5}
          nextOrderRoutine={'rp'}
        />
      ) : null}
    </Fragment>
  );
export default RoutinesOrderWoAfores;
