import React, { useState, useEffect, Fragment } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import imgWo from '../../assets/img/anacoach/Sinhistorial.svg';
import WOlayout from '../../components/layouts/WOlayout';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import BarraScroll from '../BarraScroll';
// import base
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GETURLVIDEO,
  GETPERCENTAGETRAINING,
  GET_TRAINING_STATUS,
  CHECK_TRAINING_FINISHED,
} from '../../queries';
import {
  arraySessionsRutines,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import WaitList from '../../components/Training/WaitList';
import SuccessBullets from '../../components/Training/SuccessBullets';
import { CardsBtn } from '../../assets/styles/Training.styled';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
// end import base
// estilos
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingLine,
} from '../../assets/styles/Training.styled';
// iamgenes
import RoutinesOrderNoHistory from './RoutinesOrderNoHistory';

import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import '../../assets/styles/kanda/KandaDesktop.css';

// variables
const items2 = ['Inicia tu historial crediticio', ' '];
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function NoHistorial() {
  const anchop = window.screen.width;
  const [percentage, setPercentage] = useState('');
  const [active3, setactive3] = useState(false);
  const [beneficios] = useState('Si');
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();

  const [newSession, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const { actions } = GeneralHooks();

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // mutaciones y funciones principales
  sessionStorage.setItem('idTraining', 36);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  console.log(id);
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  // End ValidWaitList

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser !== null) {
      checkTrainingFinished({
        variables: {
          idUser: parseInt(idUser),
          idTraining: currentTraining,
        },
      });
    }
  }, []);

  // manejo de orden de la rutina
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(36);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }

  const recoveryAdvance = async (currentOrderRoutine, userRoutines) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');

    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: userRoutines || '',
      },
    };

    if (idUser != null) {
      await saveStatusTraining({ variables: inputProgress });
    }
  };

  const saveRoutines = async () => {
    await recoveryAdvance(1);
    await recoveryAdvance(2);
    await recoveryAdvance(3);
  };

  useEffect(() => {
    if (orderRutine <= 1 && percentage !== '100%') {
      saveRoutines();
    }
  }, [orderRutine]);

  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      console.log(`${percent.toString()}%`);
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive3(true);
      }
    },
    onError(err) {
      console.log('getPercentageTraining=> error :', err);
    },
  });
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      console.log('ejecutar porcentaje============');
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 1 },
      });
    } else {
      setPercentage('0');
    }
  }, []);

  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const allowOverwrite = sessionStorage.getItem(`overwrite_WO_${currentTraining}`) || 'false';
        const firstName = sessionStorage.getItem('FirstName') || '';
        if (trainingInfo.avance === 100 && allowOverwrite !== 'true') {
          setFinished(true);
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            bullets: trainingInfo.bullets,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: `¡Hola, ${firstName}!`,
            txt: `Realizaste este entrenamiento el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} y ahora sabes:`,
          });
        }

        if (allowOverwrite === 'true') {
          setOrderRutine(2);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getVideoTraining] = useLazyQuery(GETURLVIDEO, {
    onCompleted() {
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getVideoTraining({ variables: { id: '2' } });
  }, []);

  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (
    currentRoutine,
    questions,
    orderRutineDatBasics = '',
  ) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra >>>> ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem(
      'currentOrderRoutine',
      orderRutineDatBasics !== '' ? orderRutineDatBasics : orderRutine,
    );
    let numQuestion = 0;
    try {
      questions.forEach((item, index) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }

        const tagManagerArgs = {
          dataLayer: {
            event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        console.log(tagManagerArgs);
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      pageName,
      fromXCoach,
      idAdviser,
    } = getQueryVariableHashtagDeeplink('motivos-de-rechazo');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie

    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);

  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} SAVE_STATUS_TRAINING [description]
   * @param  {[callback]} { onCompleted({ saveStatusTraining }) {if (saveStatusTraining.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        if (porcentaje !== 0) {
          setactive3(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  const saveAdvance = (idRoutine) => {
    const idTraining = 36;
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    idRoutine = idRoutine;
    console.log('estatus de rutina ', idRoutine);
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(idRoutine),
        idTraining: String(idTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  const hora = `${date.getHours()}:${minutos}`;
  // end  mutaciones y funciones principales

  const feacturesArray = useState([
    {
      text: 'Hay tarjetas que sirven para crear o reparar el historial crediticio.',
      color: '#917AFF',
    },
    {
      text: 'Tus deudas no deberán exceder más del 30% de tus ingresos.',
      color: '#917AFF',
    },
    {
      text: 'Pagar más del mínimo te ayudará a no generar intereses y mejorar tu Score crediticio.',
      color: '#917AFF',
    },
    {
      text: 'Después de 3 meses de buen uso de tu tarjeta, podrás acceder a más productos financieros.',
      color: '#917AFF',
    },
  ]);
  const initWO = () => {
    setactive3(true);
  };
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '5 minutos',
    imgWo: { imgWo },
    txtsubtitle: 'Accede a los instrumentos financieros que siempre quisiste',
  };
  return (
    <WOlayout
      backgroundColor={'#917AFF'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Cómo Generar Historial Crediticio: En 3 pasos | Coru</title>
        <link rel="canonical" href="https://coru.com/wo/no-credit-history" />
        <meta name="keywords" content="como generar historial crediticio" />
        <meta
          name="description"
          content="Cómo generar historial Crediticio. Empieza a generarle confianza a los Bancos y obtén un crédito en poco tiempo. Te decimos cómo"
        />
      </Helmet>
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor="#917AFF"
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <BarraInfoWO propied={propied} />
          {scroll1 > 0 ? <BarraScroll /> : null}
          <TrainingLine id="line-percent-gray" mt=""></TrainingLine>
          {percentage !== '' ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt=""
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {WOStatus ? (
              <Fragment>
                {!finished ? (
                  <Fragment>
                    <ModalDataPersonalInitWO
                      isData={isData}
                      setData={setisData}
                    />
                    <DataPersonalInitWO
                      Date={Date}
                      hora={hora}
                      orderRutine={orderRutine}
                      setOrderRutine={setOrderRutine}
                      currentTraining={currentTraining}
                      createJsonInput={createJsonInput}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                    <TrainingSectionGray
                      className={orderRutine === 5 ? 'hidden' : ''}
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="Existen varias razones por las que una solicitud de crédito es rechazada: puede deberse a la falta de historial crediticio,
                            o bien, o una mala calificación en el (Score)."
                            text2="Pero no te preocupes, ¡estoy aquí para ayudarte!😀"
                          />
                          <Starttraining
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="El camino a un buen historial crediticio:"
                            text="Esta guía es un plan a futuro, en ella encontrarás una serie de recomendaciones para tener un historial crediticio increíble."
                          />
                        </div>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    <div className={orderRutine === 5 ? 'hidden' : 'shapes'}>
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          <div className="marginComentLgSuccess1">
                            {beneficios === 'Si' ? (
                              <>
                                <Features
                                  classAna={
                                    anchop <= 768 ? 'falseAna' : 'trueAna'
                                  }
                                  title="Ten en mente que:"
                                  Arraytext={feacturesArray}
                                >
                                  {!active3 ? (
                                    <CardsBtn
                                      type="button"
                                      min_w="100%"
                                      min_web="100%"
                                      onClick={initWO}
                                    >
                                      Iniciar
                                    </CardsBtn>
                                  ) : null}
                                </Features>
                              </>
                            ) : null}
                          </div>
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    </div>
                    {active3 ? (
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          <div className="marginComentLgSuccess1">
                            {orderRutine === 5 ? null : (
                              <Starttraining
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="Iniciemos esta aventura..."
                                text="Conocemos a alguien que puede ayudarte, inicia desde hoy tu historial."
                              />
                            )}
                            <RoutinesOrderNoHistory
                              Date={stateDate}
                              hora={hora}
                              orderRutine={orderRutine}
                              setOrderRutine={setOrderRutine}
                              currentTraining={currentTraining}
                              createJsonInput={createJsonInput}
                              questions={questions}
                              setQuestions={setQuestions}
                            />
                          </div>
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    ) : null}
                  </Fragment>
                ) : (
                  <Fragment>
                    <SuccessBullets {...trainingFinishedInfo} />
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <br />
                <br />
                <br />
                <br />
                {executeQuery ? <WaitList /> : null}
              </Fragment>
            )}
          </div>
          <div
            style={{ position: 'absolute', top: '1em', right: '3em' }}
            id="kandaDesktop"
          ></div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
}

export default NoHistorial;
