/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
import "../assets/styles/kanda/KandaDesktop.css";
import GreyForm from "../assets/img/GreyForm.svg";
import WhiteForm from "../assets/img/WhiteForm.svg";
import Asesora from "../assets/img/Img_call_Xcoach.png";
import Person from "../assets/img/Img_Header_Xcoach.png";
import GifCel from "../assets/img/Coru-gifwebchat-03-11-22.gif";
import RandomGiff from "../assets/img/RandomGif2.gif";
import Asesor1 from "../assets/img/Asesores1.png";
import Asesor2 from "../assets/img/Asesores2.png";
import Asesor3 from "../assets/img/Asesores3.png";
import Asesor4 from "../assets/img/Asesores4.png";
import Asesor5 from "../assets/img/Asesores5.png";
import Arrow from "../assets/img/SPLIT_DOWN.svg";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/anacoach/CoruLogoB.png";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import ClickButton from "../Helpers/HookHelpers";
import { Helmet } from "react-helmet";

const Container1 = styled.div`
  width: 100%;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const TitleBlue = styled.div`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => props.fontS || "62px"};
  line-height: 120%;
  align-items: center;
  text-align: right;
  letter-spacing: -0.3px;
  color: #393a80;
  margin: ${(props) => props.marginD || ""};
  @media (max-width: 770px) {
    font-size: ${(props) => props.fontSD || "35px"};
  }
`;

const TextGray = styled.div`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #595959;

  @media (max-width: 770px) {
    display: block;
    font-size: 24px;
    text-align:center};
  }
`;

const ContainerArrows = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 349px;
  height: fit-content;
  margin-top: 100px;
  @media (min-width: 770px) {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 25%;
    margin-top: 15px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 75%;
  }
`;

const PersonIcon = styled.img`
    z-index: -2;
    width: 33%;
    height: 33%;
    max-width: 413px;
    position: relative;
    top:0px;
    @media (max-width: 800px) {
        top: 0px;
        margin-left:-30px;
        width: 50%;
        height: 50%;
      }
      @media (max-width: 500px ) {
        top: 120px;
        margin-left:-30px;
        width: 50%;
        height: 50%;
      }
      @media (min-width: 1500px ) {
          // !---------------------------------------------------------------------------------------------
        top: 2vw;
        margin-left:-30px;
        width: 50%;
        height: 50%;
      }
  }
`;

const RandomGif = styled.img`
    z-index: -2;
    max-width: 100px;
    position: absolute;
    top:100px;
    left:20%;
      @media (max-width: 500px ) {
        top: 220px;
        max-width: 70px;
      }
      @media (min-width: 1130px ) {
        top:140px;
          left: 30%;
        }
        @media (min-width: 1500px ) {
          top: 14vw;
          left: 36%;
        }
  }
`;

/* const Select = styled.select`
  border: 1px solid #cdcdcd;
  color: #595959;
  border-radius: 16px;
  width: 100%;
  height: 56px;
  padding: 0px 24px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  background: url(${Arrow}) right 24px center no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:valid {
    color: #070b11;
  }
`; */

function LPCoach() {
  const { actions } = ClickButton();
  const whatsappMessage = `¡Hola! 👋 Quiero hablar con un experto sobre mis dudas financieras 🤩`;

  const handleClick = (name) => {
    actions({
      action: 'ClickButtonLpXcoach',
      variables: name,
    });
    switch (name) {
      case 'Ingresar':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.location.href = '/login';
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.location.href = 'https://coru.com/login';
        }
        break;
      case 'CrearCuenta':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.location.href = '/personal-Information-v2';
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.location.href = 'https://coru.com/personal-Information-v2';
        }
        break;
      case 'PlaticaXcoach1':
        window.open(
          `https://api.whatsapp.com/send?phone=5215541693124&text=${whatsappMessage}`,
          "popup",
          "width=600",
          "height=600"
        );
        break;
      case 'PlaticaXcoach2':
        window.open(
          `https://api.whatsapp.com/send?phone=5215541693124&text=${whatsappMessage}`,
          "popup",
          "width=600",
          "height=600"
        );
        break;

      default:
        break;
    }
  };

  const Header = () => (
    <div style={{ height: "85px", display: "flex" }}>
    <ContLogo>
      <Image
        cursor="pointer"
        src={ImageLogo}
        width="120px"
        mwidth="97.92px"
        heightMobile="20.8px"
        alt="logo-coru"
      />
    </ContLogo>
    <ContLogo2>
      <ContentGeneric width="40%" widthMd="10%">
        <KandaButton
        mr="54px"
        width="100%"
        height="19px"
        typeBtn="text"
        action={() => handleClick('Ingresar')}>
          Ingresar
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="60%" widthMd="15%">
        <KandaButton
          action={() => handleClick('CrearCuenta')}
          mr="96px"
          width="100%"
          typeBtn="active"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "48px",
            padding: "13 0",
          }}
        >
          Crear Cuenta
        </KandaButton>
      </ContentGeneric>
    </ContLogo2>
  </div>
  );
  const URLactual = window.location.hostname;
  let isProd = false;
  if (URLactual === 'coru.com') {
    isProd = true;
  } else {
    isProd = false;
  }

  return (
    <>
      <div style={{ overflow: "hidden", height: "100%", position: "relative" }}>
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(239, 209, 247, 0.32) 0.52%, #E7E7FE 26.58%, #E9ECFF 53.41%, #F2E6F7 68.04%, #FEDDF3 100%)",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "-2",
          }}
        ></div>
        <Container1>
          <Helmet>
            <title>{isProd ? 'Coaching financiero: libera tu estrés de la mano de expertos | CORU' : 'Coaching financiero: libera tu estrés de la mano de expertos'}</title>
            <meta
              name="description"
              content="Entra y recibe ayuda de nuestros expertos financieros vía Whatsapp para resolver tus dudas y tomar decisiones inteligentes sobre tus finanzas."
            />
            <meta name="robots" content="index,follow" />
          </Helmet>
          <Header />
          <div
            style={{
              maxWidth: "1056px",
              display: "flex",
              flexDirection: "column",
              justifySelf: "center",
            }}
          >
            <div style={{ display: "flex", flex: "2 3" }}>
              <PersonIcon alt="" src={Person} />
              <RandomGif alt="" src={RandomGiff} />
              <div>
                <TitleBlue marginD="10% 0 0 0" style={{ fontSize: "24px" }}>
                  Conoce a tu XCoach
                </TitleBlue>
                <TitleBlue fontS="60px">
                  Recibe atención humana de los Expertos Financieros
                </TitleBlue>
              </div>
            </div>
            <ContainerArrows>
              <div>
                <TextGray>Chatea en vivo </TextGray>
                <TextGray
                  style={{
                    fontSize: "32px",
                    margin: "24px 0",
                    color: " #000000",
                    lineHeight: "39px",
                  }}
                >
                  Nuestros expertos están preparados para brindar respuestas a
                  tus preguntas financieras.
                </TextGray>
                <KandaButton
                  typeBtn="active"
                  style={{ height: "fit-content" }}
                  action={() => {
                    handleClick('PlaticaXcoach1');
                  }}
                >
                  Platica con tu XCoach
                </KandaButton>
              </div>
              <img
                src={GifCel}
                alt=""
                style={{
                  height: "635px",
                  objectFit: "scale-down",
                  margin: "24px 0",
                }}
              />
            </ContainerArrows>
            <ContainerArrows style={{ justifyContent: "space-between" }}>
              <img alt="" src={Asesora} />
              <div
                style={{
                  maxWidth: "450px",
                  margin: "24px 0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <TextGray
                  style={{
                    fontSize: "16px",
                    margin: "0",
                    textAlign: "right",
                  }}
                >
                  Sin costo y personalizado
                </TextGray>
                <TextGray
                  style={{
                    fontSize: "32px",
                    margin: "0",
                    color: " #393A80",
                    textAlign: "right",
                  }}
                >
                  Te acompañamos con atención humana y tecnología.
                </TextGray>
              </div>
            </ContainerArrows>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                margin: "120px 0",
              }}
            >
              <TitleBlue
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  color: "#595959",
                }}
              >
                ¿Estás listo para mejorar tus finanzas?
              </TitleBlue>
              <TextGray
                style={{
                  fontSize: "32px",
                  textAlign: "center",
                  marginTop: "12px",
                  color: "#393A80",
                }}
              >
                Contacta a un experto
              </TextGray>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  width: "100%",
                  margin: "24px 0",
                }}
              >
                <div
                  style={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <img alt="Jordan" src={Asesor4} />
                  <TitleBlue
                    style={{
                      color: "#070B11",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Jordan
                  </TitleBlue>
                </div>
                <div
                  style={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <img alt="Jordan" src={Asesor3} />
                  <TitleBlue
                    style={{
                      color: "#070B11",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    María
                  </TitleBlue>
                </div>
                <div
                  style={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <img alt="Jordan" src={Asesor2} />
                  <TitleBlue
                    style={{
                      color: "#070B11",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Alexis
                  </TitleBlue>
                </div>
                <div
                  style={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <img alt="Jordan" src={Asesor1} />
                  <TitleBlue
                    style={{
                      color: "#070B11",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Karla
                  </TitleBlue>
                </div>
                <div
                  style={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <img alt="Jordan" src={Asesor5} />
                  <TitleBlue
                    style={{
                      color: "#070B11",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    Javier
                  </TitleBlue>
                </div>
              </div>
              <KandaButton
              typeBtn={"active"}
              style={{ maxWidth: "260px" }}
              action={() => {
                handleClick('PlaticaXcoach2');
              }}>
                Habla con tu XCoach
              </KandaButton>
            </div>
          </div>
          <Footer isAboutUS="si" />
        </Container1>

        <img
          alt=""
          src={GreyForm}
          style={{
            width: "80%",
            position: "absolute",
            top: "340px",
            zIndex: "-1",
            overflow: "hidden",
          }}
        />
        <img
          alt=""
          src={WhiteForm}
          style={{
            width: "80%",
            position: "absolute",
            right: "0",
            top: "300px",
            zIndex: "-1",
            overflow: "hidden",
          }}
        />
      </div>
    </>
  );
}

export default LPCoach;
