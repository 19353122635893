import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { Image } from '../../assets/styles/Home.styled';
import {
  Container1,
  ContainerBottons,
  ContentHomeSection,
  Shapes,
  Subtitle,
  Title2,
  Subtitle2,
} from '../../components/common/Home.styled';
import {
  Button,
  Container,
  Col6,
} from '../../components/common/common.styled';
import cuenta from '../../assets/img/lpRappi/cuenta.svg';
import solicitud from '../../assets/img/lpRappi/solicitud.svg';
import tarjeta from '../../assets/img/lpRappi/tarjeta.svg';
import rappi1 from '../../assets/img/lpRappi/RappiCard.png';
import rappi2 from '../../assets/img/lpRappi/rappi2.png';
import WOlayout from '../../components/layouts/WOlayout';
// Modal
import ClickButton from '../../Helpers/HookHelpers';
import { Helmet } from 'react-helmet';

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function LpRappi({ location }) {
  const { actions } = ClickButton();
  console.log('fijate aqui');
  console.log(process.env.REACT_APP_SERVERTYPE);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);

  function shareCoru(e) {
    e.preventDefault();
    const coruRedirectURL = 'https://coru.com/';
    const whatsappMessage = `¡Hola! Quiero mi RappiCard. ${coruRedirectURL}`;
    window.open(
      `https://api.whatsapp.com/send/?phone=5215541693124&text=${encodeURI(
        whatsappMessage,
      )}`,
      'popup',
      'width=600',
      'height=600',
    );
    const click = 'EntrenarAhora_XCoach';
    actions({ action: 'ClickButtonCollection', variables: click });
  }

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Solicita tu tarjeta de crédito Rappi | CORU</title>
      </Helmet>
      <Container1>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="555px"
          height="640px"
          width="100%"
          position=" "
        >
          <div className="row justify-content-center">
            <div className="col-12 col-xs-8">
              <ContainerBottons
                position=" "
                displayD="none"
                displayM="block"
                marginW="auto 1%"
              >
                <Image
                  src={rappi1}
                  maxwidth="430px"
                  margin="80px auto"
                  width="100%"
                  position=""
                  left="0%"
                  top="0%"
                  displayMobile="block"
                  display="none"
                />
              </ContainerBottons>
            </div>
          </div>
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="-14rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Title2
                sizeD="50px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="15%"
                className="roboto"
              >
                {/* Llegó el momento de {window.innerWidth <= 550 ? '' : <br/> } entrenar tus finanzas */}
                Solicita ya tu <br /> Rappicard
              </Title2>
              <div className="d-flex flex-column">
                <div className="p-2">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    widthM="90%"
                    mtopM="10%"
                    mbottomM="5%"
                  >
                    Sigue unos sencillos pasos y tramita tu RappiCard en
                    instantes para disfrutar de todos los beneficios que tiene
                    para ti.
                  </Subtitle>
                </div>
              </div>
              <ContainerBottons
                widthD="42%"
                position=" "
                floatD="left"
                displayD="block"
                displayM="none"
                onClick={(e) => shareCoru(e)}
              >
                <Button
                  className="roboto"
                  bg="#FF441F"
                  mb="0"
                  onClick={(e) => shareCoru(e)}
                >
                  ¡Quiero mi RappiCard!
                </Button>
                <span style={{ fontSize: '12px', color: '#252525' }}>
                  Sujeto a aprobación de crédito, consulta nuestros Términos y
                  Condiciones y Aviso de Privacidad
                </span>
              </ContainerBottons>
            </ContentHomeSection>
          </Container>
          <ContentHomeSection
            leftD="0px"
            floatD="right"
            rigthD="0%"
            topD="-500px"
            width="40%"
            displayD=" "
            displayM="none"
          >
            <Shapes
              id="Shapes-medio"
              positionD=" "
              position="absolute"
              zindex="-1"
            >
              <Image
                src={rappi1}
                maxwidth="430px"
                MaxWidth="14%"
                MaxWidthX="12%"
                width="28%"
                position="absolute"
                left="0%"
                top="4%"
                displayMobile="none"
              />
            </Shapes>
          </ContentHomeSection>
        </Shapes>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
              margin="45px auto"
            >
              <Button
                className="d-block roboto"
                bg="#FF441F"
                mb="0"
                onClick={(e) => shareCoru(e)}
              >
                ¡Quiero mi RappiCard!
              </Button>
              <span style={{ fontSize: '12px', color: '#252525' }}>
                Sujeto a aprobación de crédito, consulta nuestros Términos y
                Condiciones y Aviso de Privacidad
              </span>
            </ContainerBottons>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Image
                src={rappi2}
                maxwidth="430px"
                margin="40px auto 0 auto"
                width="100%"
                position=""
                left="0%"
                top="0%"
                displayMobile="block"
                display="none"
              />
            </ContainerBottons>
          </div>
        </div>
        <Shapes
          id="Shapes2"
          key={2}
          top=" "
          left=" "
          heightD="auto"
          height="auto"
          width="100%"
          position=" "
          background="#FAFAFA"
        >
          <ContentHomeSection
            leftD=" "
            floatD=" "
            displayD="none"
            displayM="block"
          >
            <Title2
              top={heigthBrowser >= 550 ? '30px' : '45px'}
              topD="15%"
              className="roboto"
            >
              Conoce los beneficios de tu RappiCard
            </Title2>
            <Subtitle2 className="roboto" bottom="17px" top="10px">
              Anualidad sin costo, cancelación en instantes desde la app y
              ¡muchos beneficios más!
            </Subtitle2>
            <div style={{ width: '100%' }}>
              <Subtitle2 className="roboto" bottom="17px" top="10px">
                <b style={{ fontSize: '20px' }}>Cashback</b>
                <br />
                Te regresamos hasta el 3% en dinero real en todas las compras
                que hagas
              </Subtitle2>
              <Subtitle2 className="roboto" bottom="17px" top="10px">
                <b style={{ fontSize: '20px' }}>Descuentos</b>
                <br />
                Hasta 50% de descuento en tiendas participantes
              </Subtitle2>
              <Subtitle2 className="roboto" bottom="17px" top="10px">
                <b style={{ fontSize: '20px' }}>Meses sin intereses</b>
                <br />
                Hasta 18 meses sin intereses en establecimientos participantes
              </Subtitle2>
              <Subtitle2 className="roboto" bottom="17px" top="10px">
                <b style={{ fontSize: '20px' }}>Inclonable</b>
                <br />
                Tu tarjeta física no tiene números y control total desde la app
              </Subtitle2>
            </div>
          </ContentHomeSection>
          <div style={{ width: '100%' }}>
            <Container
              width="80%"
              mt="0rem"
              widthMobile="100%"
              mb="0%"
              mtMobile="0rem"
            >
              <Col6 pt="0px" flex="0 0 100%" maxwidth="100%">
                <Image
                  src={rappi2}
                  maxwidth="430px"
                  width="32%"
                  position="absolute"
                  left="0"
                  top="5%"
                  displayMobile="none"
                />
              </Col6>
              <Col6 pt="0px" display="flex" displayM="none">
                <div style={{ margin: 'auto' }}>
                  <Title2
                    top={heigthBrowser >= 550 ? '85px' : '45px'}
                    topD="15%"
                    className="roboto"
                  >
                    Conoce los beneficios <br /> de tu RappiCard
                  </Title2>
                  <Subtitle2 className="roboto" bottom="17px" top="10px">
                    Anualidad sin costo, cancelación en instantes desde la app y
                    ¡muchos beneficios más!
                  </Subtitle2>
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <Subtitle2
                        className="roboto"
                        bottom="17px"
                        top="10px"
                        width="210px"
                        style={{ marginRight: '30px' }}
                      >
                        <b style={{ fontSize: '22px' }}>Cashback</b>
                        <br />
                        Te regresamos hasta el 3% en dinero real en todas las
                        compras que hagas
                      </Subtitle2>
                      <Subtitle2
                        className="roboto"
                        bottom="17px"
                        top="10px"
                        width="210px"
                      >
                        <b style={{ fontSize: '22px' }}>Descuentos</b>
                        <br />
                        Hasta 50% de descuento en tiendas participantes
                      </Subtitle2>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <Subtitle2
                        className="roboto"
                        bottom="17px"
                        top="10px"
                        width="210px"
                        style={{ marginRight: '30px' }}
                      >
                        <b style={{ fontSize: '22px' }}>Meses sin intereses</b>
                        <br />
                        Hasta 18 meses sin intereses en establecimientos
                        participantes
                      </Subtitle2>
                      <Subtitle2
                        className="roboto"
                        bottom="17px"
                        top="10px"
                        width="210px"
                      >
                        <b style={{ fontSize: '22px' }}>Inclonable</b>
                        <br />
                        Tu tarjeta física no tiene números y control total desde
                        la app
                      </Subtitle2>
                    </div>
                  </div>
                </div>
              </Col6>
            </Container>
            <ContentHomeSection
              leftD="auto"
              rigthD="auto"
              floatD=" "
              width="80%"
              topD="200px"
              topDW="120px"
              topMm320="40px"
            >
              <Title2
                top={heigthBrowser >= 550 ? '85px' : '45px'}
                aling="center"
                topD="15%"
                className="roboto"
              >
                ¿Cómo solicito mi RappiCard?
              </Title2>
              <div className="row mt-4 justify-content-between text-center">
                <div className="col-md-3">
                  <Image src={cuenta} width="75px" />
                  <Subtitle
                    mbottomM="0"
                    bottomMm640="0"
                    sizeM="20px"
                    sizeM640="20px"
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Crea tu cuenta Rappi
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    heightM="80px"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="18px"
                    widthMm320="280px"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Ingresa tus datos para crear tu cuenta en Rappi y validar
                    tus datos
                  </Subtitle>
                </div>
                <div className="col-md-3">
                  <Image src={solicitud} width="30px" />
                  <Subtitle
                    mbottomM="0"
                    bottomMm640="0"
                    sizeM="20px"
                    sizeM640="20px"
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Haz tu solicitud
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    heightM="80px"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mleftMm320="20px"
                    widthMm320="280px"
                    alingMm320="justify"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Te ayudaremos haciendo una consulta de tu historial
                    crediticio para determinar tu línea de crédito
                  </Subtitle>
                </div>
                <div className="col-md-3">
                  <Image src={tarjeta} width="85px" />
                  <Subtitle
                    mbottomM="0"
                    bottomMm640="0"
                    sizeM="20px"
                    sizeM640="20px"
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Obtén tu tarjeta
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    heightM="80px"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mleftMm320="20px"
                    widthMm320="280px"
                    alingMm320="justify"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    En caso de ser aprobada, recibe tu tarjeta y disfruta de
                    todos sus beneficios
                  </Subtitle>
                </div>
              </div>
            </ContentHomeSection>
            <div className="row justify-content-center">
              <div className="col-12 col-xs-8">
                <ContainerBottons
                  widthD="30%"
                  position=" "
                  displayD="block"
                  displayM="block"
                  marginW="auto 1%"
                >
                  <Button
                    className="d-block roboto"
                    bg="#FF441F"
                    mb="5.5rem"
                    onClick={(e) => shareCoru(e)}
                  >
                    Solicitar mi RappiCard
                  </Button>
                </ContainerBottons>
              </div>
            </div>
          </div>
        </Shapes>
      </Container1>
      {/* <Whatsapp url={""} id="Whatsapp"/> */}
    </WOlayout>
  );
}

export default LpRappi;
