/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DocumentViewer,
  FormInpust,
  FormTitle,
  ItemContainer,
} from '../../assets/styles/GloveBox.styled';
import downArrow from '../../assets/img/anacoach/downArrow.svg';
import upArrow from '../../assets/img/anacoach/upArrow.svg';
import insurancePolicy from '../../assets/img/anacoach/insurancePolicy.svg';
import assistancePolicy from '../../assets/img/anacoach/assistancePolicy.svg';
import carService from '../../assets/img/anacoach/carService.svg';
import TAG from '../../assets/img/anacoach/TAG.svg';
import driverLicense from '../../assets/img/anacoach/driverLicense.svg';
import vehicleRegistration from '../../assets/img/anacoach/vehicleRegistrationCertificate.svg';
import tenancy from '../../assets/img/anacoach/tenancy.svg';
import verification from '../../assets/img/anacoach/verification.svg';
import { Col12 } from './common.styled';
import GloveBoxCheckIn from './GloveBoxCheckIn';
import GloveBoxForm from '../../anaCoach/Training/GloveBoxForm';

const items = [
  { title: 'Póliza de seguro', isChecked: true },
  { title: 'Póliza de asistencia en el camino', isChecked: true },
  { title: 'TAG', isChecked: false },
  { title: 'Tarjeta de circulación', isChecked: true },
  { title: 'Licencia de conducir', isChecked: true },
  { title: 'Tenencia', isChecked: true },
  { title: 'Llanta de refacción', isChecked: false },
  { title: 'Herramienta', isChecked: true },
  { title: 'Botiquín/Extinguidor', isChecked: true },
];

const libreta = [
  {
    nm: 'Póliza de seguro',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Vigencia de la póliza',
    im: insurancePolicy,
  },
  {
    nm: 'Póliza de asistencia en el camino',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Vigencia de la póliza',
    im: assistancePolicy,
  },
  {
    nm: 'Servicio de auto',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Fecha de último servicio',
    im: carService,
  },
  {
    nm: 'TAG',
    ds: 'Número de TAG',
    im: TAG,
  },
  {
    nm: 'Licencia de conducir',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Vigencia de tu licencia',
    im: driverLicense,
  },
  {
    nm: 'Tarjeta de circulación',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Vigencia de tu tarjeta',
    im: vehicleRegistration,
  },
  {
    nm: 'Tenencia',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Fecha de pago',
    im: tenancy,
  },
  {
    nm: 'Verificación',
    ut: 'Cargar documento (Peso máximo 5MB)',
    ds: 'Fecha de próxima verificación',
    im: verification,
  },
];

const GloveBoxDataItem = (
  {
    formIcon = '',
    formTitle = '',
    vigencyText = '',
    vigency = '',
    coverage = '',
    insurance = '',
    payMethod = '',
    TAGNumber = '',
    // balance = '',
    lastServiceDate = '',
    nextServiceDate = '',
    calco = '',
    gummed = '',
    nextVerificationDate = '',
    showFileText = '',
    documentFile = '',
    emergencyNumbers = [],
    insuranceNumbers = [],
    createJsonInput,
    questions,
    setQuestions,
  },
) => {
  const [showInfo, setShowInfo] = useState(false);
  const [edit, setEdit] = useState(true);
  const [extensionFile, setExtensionFile] = useState('');
  const [showInsurances, setShowInsurances] = useState(false);
  const [meses, setMeses] = useState([]);
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [editP, setEditP] = useState({
    tag: false,
    polizaS: false,
    polizaA: false,
    serviceA: false,
    licenciaC: false,
    tarjetaC: false,
    tenencia: false,
    verifica: false,
  });
  const [form, setForm] = useState([
    {
      day: '', month: '', year: '', ase: '', cove: '', pay: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', pay: '', compa: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', day2: '', month2: '', year2: '',
    },
    {
      numtag: '',
    },
    {
      day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
    {
      day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
    {
      calc: '', engoma: '', day: '', month: '', year: '', docV: { url: null, ex: '' },
    },
  ]);

  useEffect(() => {
    if (documentFile !== null && documentFile !== '') {
      setExtensionFile(documentFile.slice(documentFile.length - 3).trim());
    }
  }, []);

  // GUARDA LAS RESPUESTAS QUE CONTESTO EL USUARIO
  // Questions es un arreglo que arrastra todas las respuestas del usuario
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || type === 'SelectBasic' || type === 'DocUpload') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  // CAPTURA LA ACCIÓN DE CONTESTAR UNA PREGUNTA
  const handleChangeCatOption = (val) => {
    if (val) {
      // console.log('handleChangeCatOption', val);
    }
  };

  // CAMBIA DE RUTINA A LA SIGUIENTE
  const goNextRoutine = (val) => {
    if (!sessionStorage.getItem('dayDoc')) {
      setMeses([]);
    }
    switch (val) {
      case 0:
        setEditP({ ...editP, polizaS: false });
        break;
      case 1:
        setEditP({ ...editP, polizaA: false });
        break;
      case 2:
        setEditP({ ...editP, serviceA: false });
        break;
      case 3:
        setEditP({ ...editP, tag: false });
        break;
      case 4:
        setEditP({ ...editP, licenciaC: false });
        break;
      case 5:
        setEditP({ ...editP, tarjetaC: false });
        break;
      case 6:
        setEditP({ ...editP, tenencia: false });
        break;
      case 7:
        setEditP({ ...editP, verifica: false });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('another routine next');
        break;
    }
  };

  // FUNCION PARA GUARDAR LO QUE CONTESTE EL USUARIO A MOSTRAR
  const handleChange = (e, order, posi) => {
    const arrayTemp = [...form];
    arrayTemp[posi] = {
      ...arrayTemp[posi],
      [e.target.name]: e.target.value,
    };
    setForm(arrayTemp);
    // SETEANDO MESES DE ACUERDO AL DIA SELECCIONADO
    if (e.target.name === 'day') {
      const daydoc = parseInt(e.target.value, 10);
      if (daydoc < 29) {
        setMeses([
          { label: 'Enero', value: '01' },
          { label: 'Febrero', value: '02' },
          { label: 'Marzo', value: '03' },
          { label: 'Abril', value: '04' },
          { label: 'Mayo', value: '05' },
          { label: 'Junio', value: '06' },
          { label: 'Julio', value: '07' },
          { label: 'Agosto', value: '08' },
          { label: 'Septiembre', value: '09' },
          { label: 'Octubre', value: '10' },
          { label: 'Noviembre', value: '11' },
          { label: 'Diciembre', value: '12' },
        ]);
      } else if (daydoc === 29 || daydoc === 30) {
        setMeses([
          { label: 'Enero', value: '01' },
          { label: 'Marzo', value: '03' },
          { label: 'Abril', value: '04' },
          { label: 'Mayo', value: '05' },
          { label: 'Junio', value: '06' },
          { label: 'Julio', value: '07' },
          { label: 'Agosto', value: '08' },
          { label: 'Septiembre', value: '09' },
          { label: 'Octubre', value: '10' },
          { label: 'Noviembre', value: '11' },
          { label: 'Diciembre', value: '12' },
        ]);
      } else {
        setMeses([
          { label: 'Enero', value: '01' },
          { label: 'Marzo', value: '03' },
          { label: 'Mayo', value: '05' },
          { label: 'Julio', value: '07' },
          { label: 'Agosto', value: '08' },
          { label: 'Octubre', value: '10' },
          { label: 'Diciembre', value: '12' },
        ]);
      }
      sessionStorage.setItem('dayDoc', daydoc);
    }
    if (e.target.name === 'month') {
      sessionStorage.removeItem('dayDoc');
    }
  };

  const handleShowInfo = (e) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const activeEdite = (key) => {
    switch (key) {
      case 'polizaS':
        sessionStorage.setItem('currentOrderRoutinew', '2');
        break;
      case 'polizaA':
        sessionStorage.setItem('currentOrderRoutinew', '3');
        break;
      case 'serviceA':
        sessionStorage.setItem('currentOrderRoutinew', '4');
        break;
      case 'tag':
        sessionStorage.setItem('currentOrderRoutinew', '5');
        break;
      case 'licenciaC':
        sessionStorage.setItem('currentOrderRoutinew', '6');
        break;
      case 'tarjetaC':
        sessionStorage.setItem('currentOrderRoutinew', '7');
        break;
      case 'tenencia':
        sessionStorage.setItem('currentOrderRoutinew', '8');
        break;
      case 'verifica':
        sessionStorage.setItem('currentOrderRoutinew', '9');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('another routine next');
        break;
    }
    const objTemp = { ...editP };
    for (let i = 0; i < Object.keys(objTemp).length; i += 1) {
      if (Object.keys(objTemp)[i] === key) {
        objTemp[Object.keys(objTemp)[i]] = true;
      } else {
        objTemp[Object.keys(objTemp)[i]] = false;
      }
    }
    setEditP(objTemp);
  };

  return (
    <ItemContainer>
      <div
        className='d-flex justify-content-between roboto'
        onClick={(e) => { handleShowInfo(e); }}
      >
        <FormTitle>
          <img
            src={formIcon}
            alt='glove box document'
          />
          <p
            style={{
              fontSize: '16px',
              fontWeight: '700',
              margin: '0',

            }}
          >
            {formTitle}
          </p>
        </FormTitle>
        {
          showInfo ? (
            <img src={upArrow} alt='down arrow' />
          ) : (
            <img src={downArrow} alt='up arrow' />
          )
        }
      </div>
      {
        showInfo ? (
          <FormInpust>
          { // TAG
            formTitle === 'TAG' ? (
              <>
                {editP.tag ? (
                  <GloveBoxForm
                    formTitle={libreta[3].nm}
                    formIcon = {libreta[3].im}
                    uploadText={libreta[3].ut}
                    description={libreta[3].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={5}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[3]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={3}
                    goNextRoutine={goNextRoutine}
                    hideHeader={true}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          Número de TAG
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[3].numtag !== '' ? form[3].numtag : TAGNumber}
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('tag') }
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    {/* <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Saldo
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {balance}
                    </p> */}
                  </>
                )}
              </>
            ) : null
          }
          { // Póliza de seguro
            formTitle === 'Póliza de seguro' ? (
              <>
                {editP.polizaS ? (
                  <GloveBoxForm
                    formTitle={libreta[0].nm}
                    formIcon = {libreta[0].im}
                    uploadText={libreta[0].ut}
                    description={libreta[0].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={2}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[0]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={0}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                    hideHeader={true}
                    revExtensionFile={extensionFile}
                    revDocumentoFile={documentFile}
                    revsetEx={setExtensionFile}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        {vigencyText}
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[0].day !== '' && form[0].month !== '' && form[0].year !== ''
                            ? `${form[0].day}/${form[0].month}/${form[0].year}`
                            : vigency
                          }
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('polizaS') }
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Forma de pago
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[0].pay !== '' ? form[0].pay : payMethod}
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Cobertura
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[0].cove !== '' ? form[0].cove : coverage}
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Aseguradora
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[0].ase !== '' ? form[0].ase : insurance}
                    </p>
                    {extensionFile !== '' ? (
                      <>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          {showFileText}
                        </p>
                        <Col12>
                          {
                            documentFile !== null ? (
                              <DocumentViewer>
                                {extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                                  <img
                                    src={
                                      form[0].docV.url !== null ? form[0].docV.url : documentFile
                                    }
                                    alt='document processed'
                                    style={{ width: '100%', height: '300px' }}
                                  />
                                ) : (
                                  <embed
                                    type='application/pdf'
                                    src={`${form[0].docV.url !== null ? form[0].docV.url : documentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="300px"
                                  />
                                )
                                }
                              </DocumentViewer>
                            ) : null
                          }
                        </Col12>
                      </>) : null
                    }
                  </>
                )}
              </>
            ) : null
          }
          { // Póliza de asistencia en el camino
            formTitle === 'Póliza de asistencia en el camino' ? (
              <>
                {editP.polizaA ? (
                  <GloveBoxForm
                    formTitle={libreta[1].nm}
                    formIcon = {libreta[1].im}
                    uploadText={libreta[1].ut}
                    description={libreta[1].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={3}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[1]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={1}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                    hideHeader={true}
                    revExtensionFile={extensionFile}
                    revDocumentoFile={documentFile}
                    revsetEx={setExtensionFile}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        {vigencyText}
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[1].day !== '' && form[1].month !== '' && form[1].year !== ''
                            ? `${form[1].day}/${form[1].month}/${form[1].year}`
                            : vigency
                          }
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('polizaA')}
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Forma de pago
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[1].pay !== '' ? form[1].pay : payMethod}
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Empresa
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[1].compa !== '' ? form[1].compa : insurance}
                    </p>
                    {extensionFile !== '' ? (
                      <>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          {showFileText}
                        </p>
                        <Col12>
                          {
                            documentFile !== null ? (
                              <DocumentViewer>
                                {extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                                  <img
                                    src={
                                      form[1].docV.url !== null ? form[1].docV.url : documentFile
                                    }
                                    alt='document processed'
                                    style={{ width: '100%', height: '300px' }}
                                  />
                                ) : (
                                  <embed
                                    type='application/pdf'
                                    src={`${form[1].docV.url !== null ? form[1].docV.url : documentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="300px"
                                  />
                                )
                                }
                              </DocumentViewer>
                            ) : null
                          }
                        </Col12>
                      </>) : null
                    }
                  </>
                )}
              </>
            ) : null
          }
          { // Servicio de auto
            formTitle === 'Servicio de auto' ? (
              <>
                {editP.serviceA ? (
                  <GloveBoxForm
                    formTitle={libreta[2].nm}
                    formIcon = {libreta[2].im}
                    uploadText={libreta[2].ut}
                    description={libreta[2].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={4}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[2]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={2}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                    hideHeader={true}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          Fecha del último servicio
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[2].day !== '' && form[2].month !== '' && form[2].year !== ''
                            ? `${form[2].day}/${form[2].month}/${form[2].year}`
                            : lastServiceDate
                          }
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('serviceA') }
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Fecha del próximo servicio
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[2].day2 !== '' && form[2].month2 !== '' && form[2].year2 !== ''
                        ? `${form[2].day2}/${form[2].month2}/${form[2].year2}`
                        : nextServiceDate
                      }
                    </p>
                  </>
                )}
              </>
            ) : null
          }
          { // Licencia de conducir
            formTitle === 'Licencia de conducir' ? (
              <>
              {editP.licenciaC ? (
                <GloveBoxForm
                  formTitle={libreta[4].nm}
                  formIcon = {libreta[4].im}
                  uploadText={libreta[4].ut}
                  description={libreta[4].ds}
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={6}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form[4]}
                  handleChange={handleChange}
                  currentTraining={44}
                  activeStep={true}
                  amountService={4}
                  goNextRoutine={goNextRoutine}
                  meses={meses}
                  hideHeader={true}
                  revExtensionFile={extensionFile}
                  revDocumentoFile={documentFile}
                  revsetEx={setExtensionFile}
                />
              ) : (
                <>
                  <div
                    className="col-12 d-flex flex-row justify-content-between align-items-center"
                    style={{ padding: '0px' }}
                  >
                    <div>
                      <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      {vigencyText}
                      </p>
                      <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {form[4].day !== '' && form[4].month !== '' && form[4].year !== ''
                          ? `${form[4].day}/${form[4].month}/${form[4].year}`
                          : vigency
                        }
                      </p>
                    </div>
                    <div>
                      <div
                        style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                        onClick={() => activeEdite('licenciaC') }
                      >
                        Editar
                      </div>
                    </div>
                  </div>
                  {extensionFile !== '' ? (
                    <>
                      <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        {showFileText}
                      </p>
                      <Col12>
                        {
                          documentFile !== null ? (
                            <DocumentViewer>
                              {extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                                <img
                                  src={
                                    form[4].docV.url !== null ? form[4].docV.url : documentFile
                                  }
                                  alt='document processed'
                                  style={{ width: '100%', height: '300px' }}
                                />
                              ) : (
                                <embed
                                  type='application/pdf'
                                  src={`${form[4].docV.url !== null ? form[4].docV.url : documentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                  width="100%"
                                  height="300px"
                                />
                              )
                              }
                            </DocumentViewer>
                          ) : null
                        }
                      </Col12>
                    </>) : null
                  }
                </>
              )}
              </>
            ) : null
          }
          { // Tarjeta de circulación
            formTitle === 'Tarjeta de circulación' ? (
              <>
                {editP.tarjetaC ? (
                   <GloveBoxForm
                    formTitle={libreta[5].nm}
                    formIcon = {libreta[5].im}
                    uploadText={libreta[5].ut}
                    description={libreta[5].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={7}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[5]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={5}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                    hideHeader={true}
                    revExtensionFile={extensionFile}
                    revDocumentoFile={documentFile}
                    revsetEx={setExtensionFile}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        {vigencyText}
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[5].day !== '' && form[5].month !== '' && form[5].year !== ''
                            ? `${form[5].day}/${form[5].month}/${form[5].year}`
                            : vigency
                          }
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('tarjetaC') }
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    {extensionFile !== '' ? (
                      <>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          {showFileText}
                        </p>
                        <Col12>
                          {
                            documentFile !== null ? (
                              <DocumentViewer>
                                {extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                                  <img
                                    src={
                                      form[5].docV.url !== null ? form[5].docV.url : documentFile
                                    }
                                    alt='document processed'
                                    style={{ width: '100%', height: '300px' }}
                                  />
                                ) : (
                                  <embed
                                    type='application/pdf'
                                    src={`${form[5].docV.url !== null ? form[5].docV.url : documentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="300px"
                                  />
                                )
                                }
                              </DocumentViewer>
                            ) : null
                          }
                        </Col12>
                      </>) : null
                    }
                  </>
                )}
              </>
            ) : null
          }
          {// Tenencia
            formTitle === 'Tenencia' ? (
              <>
                {editP.tenencia ? (
                  <GloveBoxForm
                    formTitle={libreta[6].nm}
                    formIcon = {libreta[6].im}
                    uploadText={libreta[6].ut}
                    description={libreta[6].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={8}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[6]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={6}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                    hideHeader={true}
                    revExtensionFile={extensionFile}
                    revDocumentoFile={documentFile}
                    revsetEx={setExtensionFile}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                        {vigencyText}
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[6].day !== '' && form[6].month !== '' && form[6].year !== ''
                            ? `${form[6].day}/${form[6].month}/${form[6].year}`
                            : vigency
                          }
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('tenencia') }
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    {extensionFile !== '' ? (
                      <>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          {showFileText}
                        </p>
                        <Col12>
                          {
                            documentFile !== null ? (
                              <DocumentViewer>
                                {extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                                  <img
                                    src={
                                      form[6].docV.url !== null ? form[6].docV.url : documentFile
                                    }
                                    alt='document processed'
                                    style={{ width: '100%', height: '300px' }}
                                  />
                                ) : (
                                  <embed
                                    type='application/pdf'
                                    src={`${form[6].docV.url !== null ? form[6].docV.url : documentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="300px"
                                  />
                                )
                                }
                              </DocumentViewer>
                            ) : null
                          }
                        </Col12>
                      </>) : null
                    }
                  </>
                )}
              </>) : null
          }
          {// Verificación
            formTitle === 'Verificación' ? (
              <>
                {editP.verifica ? (
                  <GloveBoxForm
                    formTitle={libreta[7].nm}
                    formIcon = {libreta[7].im}
                    uploadText={libreta[7].ut}
                    description={libreta[7].ds}
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={9}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form[7]}
                    handleChange={handleChange}
                    currentTraining={44}
                    activeStep={true}
                    amountService={7}
                    goNextRoutine={goNextRoutine}
                    meses={meses}
                    hideHeader={true}
                    revExtensionFile={extensionFile}
                    revDocumentoFile={documentFile}
                    revsetEx={setExtensionFile}
                  />
                ) : (
                  <>
                    <div
                      className="col-12 d-flex flex-row justify-content-between align-items-center"
                      style={{ padding: '0px' }}
                    >
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          Calcomanía
                        </p>
                        <p style={{ fontSize: '14px', fontWeight: '400' }}>
                          {form[7].calc !== '' ? form[7].calc : calco}
                        </p>
                      </div>
                      <div>
                        <div
                          style={{ color: '#F64282', cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => activeEdite('verifica') }
                        >
                          Editar
                        </div>
                      </div>
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Engomado
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[7].engoma !== '' ? form[7].engoma : gummed}
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '700' }}>
                      Fecha de próxima verificación
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {form[7].day !== '' && form[7].month !== '' && form[7].year !== ''
                        ? `${form[7].day}/${form[7].month}/${form[7].year}`
                        : nextVerificationDate
                      }
                    </p>
                    {extensionFile !== '' ? (
                      <>
                        <p style={{ fontSize: '14px', fontWeight: '700' }}>
                          {showFileText}
                        </p>
                        <Col12>
                          {
                            documentFile !== null ? (
                              <DocumentViewer>
                                {extensionFile === 'jpg' || extensionFile === 'png' || extensionFile === 'peg' ? (
                                  <img
                                    src={
                                      form[7].docV.url !== null ? form[7].docV.url : documentFile
                                    }
                                    alt='document processed'
                                    style={{ width: '100%', height: '300px' }}
                                  />
                                ) : (
                                  <embed
                                    type='application/pdf'
                                    src={`${form[7].docV.url !== null ? form[7].docV.url : documentFile}#toolbar=0&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="300px"
                                  />
                                )
                                }
                              </DocumentViewer>
                            ) : null
                          }
                        </Col12>
                      </>) : null
                    }
                  </>
                )}
              </>
            ) : null
          }
          {// numeros de emergencia
            formTitle === 'Teléfonos de emergencias' ? (
              <>
              {emergencyNumbers.map((emergency, i) => (
                  <div
                    className='d-flex justify-content-between'
                    style={{ margin: '11px 0' }}
                    key={i}
                  >
                    <div style={{ width: '50%' }}>{emergency.title}</div>
                    <div style={{ width: '50%' }}>
                      {emergency.phones.map((phone, j) => (
                        <p
                          key={j}
                          style={{
                            textAlign: 'right',
                            fontSize: '14px',
                            fontWeight: '700',
                            color: '#6677E3',
                          }}>
                          {phone}
                        </p>
                      ))}
                    </div>
                  </div>
              ))}
              <div
                className='d-flex justify-content-between roboto'
                onClick={() => { setShowInsurances(!showInsurances); }}
              >
                <FormTitle>
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '700',
                      margin: '11px 0 11px -6px',

                    }}
                  >
                    Aseguradoras
                  </p>
                </FormTitle>
                {
                  showInsurances ? (
                    <img src={upArrow} alt='down arrow' />
                  ) : (
                    <img src={downArrow} alt='up arrow' />
                  )
                }
              </div>
              {
                showInsurances ? (
                  <>
                  {insuranceNumbers.map((insuranc, i) => (
                      <div
                        key={i}
                        className='d-flex justify-content-between'
                        style={{ margin: '11px 0' }}
                      >
                        <div style={{ width: '50%' }}>{insuranc.title}</div>
                        <div style={{ width: '50%' }}>
                          {insuranc.phones.map((phone, j) => {
                            let blackLetters = '';
                            let purpleLetters = phone;
                            if (phone.includes('CDMX')) {
                              blackLetters = phone.slice(0, 6);
                              purpleLetters = phone.slice(6);
                            }
                            return (
                              <p
                                key={j}
                                style={{
                                  textAlign: 'right',
                                  fontSize: '14px',
                                  fontWeight: '700',
                                  color: '#6677E3',
                                  margin: '0',
                                }}>
                                <span style={{ fontWeight: '400', color: '#000' }}>{blackLetters}</span>
                                {purpleLetters}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                  ))}
                </>
                ) : null
              }
            </>
            ) : null
          }
          { // Check-in de viaje
            formTitle === 'Check-in de viaje' && <>
              <div className='roboto'
                style={{
                  display: 'flex', justifyContent: 'space-between', gap: '15px', marginBottom: '22px',
                }}>
                <p style={{ fontSize: '14px' }}>Revisa que lleves todo lo necesario para un viaje seguro</p>
                <p
                  style={{ fontSize: '14px', color: '#F64282' }}
                  onClick={() => setEdit(!edit)}
                >
                  Editar
                </p>
              </div>
              <GloveBoxCheckIn items={items} edit={edit} />
            </>
          }
        </FormInpust>
        ) : null
      }
    </ItemContainer>
  );
};

GloveBoxDataItem.propTypes = {
  formIcon: PropTypes.string,
  formTitle: PropTypes.string,
  vigencyText: PropTypes.string,
  vigency: PropTypes.string,
  coverage: PropTypes.string,
  insurance: PropTypes.string,
  payMethod: PropTypes.string,
  TAGNumber: PropTypes.string,
  balance: PropTypes.string,
  lastServiceDate: PropTypes.string,
  nextServiceDate: PropTypes.string,
  calco: PropTypes.string,
  gummed: PropTypes.string,
  nextVerificationDate: PropTypes.string,
  showFileText: PropTypes.string,
  documentFile: PropTypes.string,
  emergencyNumbers: PropTypes.array,
  insuranceNumbers: PropTypes.array,
  createJsonInput: PropTypes.func,
  questions: PropTypes.array,
  setQuestions: PropTypes.func,
};

export default GloveBoxDataItem;
