import React, { useState } from 'react';
import ShareIcon from '@material-ui/icons/Share';
import Modal from '@material-ui/core/Modal';
import MaximizeIcon from '@material-ui/icons/Maximize';
import face from '../../assets/img/anacoach/Facebook.svg';
import twit from '../../assets/img/anacoach/messenger2.svg';
import whats from '../../assets/img/anacoach/whats.png';
import { ParagrapTwo } from './common.styled';
import {
  Subtitle,
  Rectangle24,
  Vector2,
  Vector3,
  Triangu,
} from './Home.styled';

import {
  ModalBody,
  ModalCard,
  ModalBox,

} from '../../assets/styles/Home.styled';

const ModalShareFreem = ({
  redirectLink,
  textShare,
  trainingName = '',
  mtop,
  buttonType,
}) => {
  const [openModal, setopenModal] = useState(false);
  const [widthBrowser] = useState(window.outerWidth);

  function shareCoru(socialNetwork) {
    let host = ''; // "https://coru.com";
    let auxHost = ''; // "https://coru.com";
    let plantillasHTML = '';
    let announcement = '';
    if (redirectLink === '/lp/get-card') {
      announcement = socialNetwork === 'Whatsapp'
        ? 'LPgetCardSuccessWa'
        : 'LPgetCardSuccess';
    }

    const url = window.location.host;
    if (url === 'coru.com') {
      host = 'https://coru.com';
      auxHost = 'https://coru.com';
    } else if (url === 'staging.coru.mx') {
      host = 'https://staging.coru.mx';
      auxHost = 'https://staging.coru.mx';
    } else {
      host = 'https://dev-rediseno.coru.mx';
      auxHost = 'https://dev-rediseno.coru.mx';
    }

    if (process.env.REACT_APP_SERVERTYPE === 'Development') {
      plantillasHTML = '/announcements/';
    } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
      plantillasHTML = '/announcements_prod/';
    }
    const coruShareLink = `${auxHost}${plantillasHTML}${announcement}.html?coru-redirect-url=${host}${encodeURIComponent(
      redirectLink,
    )}`;
    switch (socialNetwork) {
      case 'Messenger':

        window.open(
          `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${encodeURIComponent(
            `${coruShareLink}`,
          )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`,
        );
        break;
      case 'MessengerMob':
        window.open(`fb-messenger://share/?link=${coruShareLink}&app_id=248019326476226`);
        break;
      case 'Facebook':

        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruShareLink,
          )}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}&display=popup`,
        );
        break;
      case 'Whatsapp':
        window.open(`https://wa.me/?text=${encodeURI(coruShareLink)}`);
        break;
      default:
        break;
    }
  }

  const ModalCompartir = () => (

    <Modal
      open={openModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClick={() => {
        setopenModal(false);
      }}
    >
      <ModalBox mtop414="180px" mleft414="30px" mtop375="130px" mleft375="16px" mtop360="100px" mleft360="7px" mtop320="80px" mleR320="1px" >
        <ModalCard maxheight="430px" MaxWidth="345px">
          <ParagrapTwo
            fontWeight="700"
            mt="0px"
            mb="0px"
            cursor="pointer"
            mtWeb="0px"
            mbweb="0px"
            texttalign="right"
            textalignMobile="center"
            className="mx-auto"
            onClick={() => {
              setopenModal(false);
            }}
          >
            {widthBrowser >= 760 ? (
              'x'
            ) : (
              <MaximizeIcon style={{ fontSize: '2.5rem' }} />
            )}
          </ParagrapTwo>

          <Triangu margintop="-58px" widthborder="25px solid transparent" />
          <Vector2
            width="45px"
            left="0"
            height="50px"
            widthM="40px"
            heightM="45px"
            background="#E3E660"
            radius="40%"
            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
            positionD="absolute"
            Pleft="-6%"
            Ptop="253px"
            PleftM="1%"
            PtopM="55%"
            MlefM="-6%"
            displayM="block"
            id="ovalo"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            PtopM="25%"
            PleftM="-10%"
            Ptop="140px"
            Pleft="-8%"
            background=" #6BD5AF;"
            transform="rotate(-90deg)"
            width="50px"
            height="50px"
            widthM="45px"
            heightM="45px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="370px"
            mLeftM="28%"
            PLeftM="0%"
            mTopM="12%"
            Pleft="2%"
            background="#BFDD6A"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            widthM="35px"
            heightM="35px"
            transform="rotate(46.41deg)"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="24px"
            mLeftM="48%"
            PLeftM="20%"
            mTopM="2%"
            Pleft="72%"
            background="#6BD5AF"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            widthM="25px"
            heightM="25px"
            transform="rotate(46.41deg)"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="112px"
            Pleft="90%"
            PtopM="100px"
            PleftM="91%"
            background="#BFDD6A"
            transform="rotate(-75deg)"
            width="55px"
            height="55px"
            widthM="42px"
            heightM="42px"
            displayD=" "
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="222px"
            Pleft="94%"
            PtopM="230px"
            PleftM="93%"
            background="#E3E660"
            transform="rotate(75deg)"
            width="55px"
            height="55px"
            widthM="42px"
            heightM="42px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="340px"
            mLeftM="0"
            PLeftM="90%"
            mTopM="10%"
            Pleft="93%"
            background="#91D770"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="50px"
            height="50px"
            widthM="30px"
            heightM="30px"
            transform="rotate(46.41deg)"

          />
          <ModalBody mtop="10px">
            <div style={{
              width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}>
              <div style={{ width: '85%', marginTop: '-15px' }}>
                <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" sizeM="20px" heightM=" " mleftM=" " style={{ marginBottom: '20px' }}>
                  Comparte en:
                </Subtitle>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderBottom: '2px solid #CDCDCD',
                    paddingBottom: '20px',
                    cursor: 'pointer',
                    paddingTop: '20px',
                  }}
                  onClick={() => shareCoru('Facebook')}>
                  <img
                    alt=""
                    position="initial"
                    left="0"
                    width="50px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                    }}
                    src={face}
                  />
                  <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" heightM=" " mleftM=" " width=" " aling="center" marginTop=" ">
                    Facebook
                  </Subtitle>
                </div>
                <div
                  className="display-mobile-flex"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderBottom: '2px solid #CDCDCD',
                    paddingBottom: '20px',
                    cursor: 'pointer',
                    paddingTop: '20px',
                  }}
                  onClick={() => shareCoru('Messenger')}>
                  <img
                    alt=""
                    position="initial"
                    left="0"
                    width="44px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '54px',
                      position: 'initial',
                      marginLeft: '0',
                    }}
                    src={twit}
                  />
                  <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" heightM=" " mleftM=" " width=" " aling="center" marginTop=" ">
                    Messenger
                  </Subtitle>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    paddingBottom: '20px',
                    cursor: 'pointer',
                    paddingTop: '20px',
                  }}
                  onClick={() => shareCoru('Whatsapp')}>
                  <img
                    alt=""
                    position="initial"
                    left="0"
                    top="6%"
                    width="50px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                    }}
                    src={whats}
                  />
                  <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" heightM=" " mleftM=" " width=" " aling="center" marginTop=" ">
                    Whatsapp
                  </Subtitle>
                </div>
              </div>
            </div>

          </ModalBody>

        </ModalCard>
      </ModalBox>
    </Modal>

  );

  return (
    <>
      <ShareIcon style={{ float: 'right', color: 'black' }} onClick={() => setopenModal(true)} />
      <ModalCompartir />
    </>
  );
};

export default ModalShareFreem;
