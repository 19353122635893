/* eslint-disable max-len */
/* eslint-disable import/no-useless-path-segments */
import React, { useState } from "react";
import styled from "styled-components";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
// import WOlayout from '../components/layouts/WOlayout';
import "../assets/styles/kanda/KandaDesktop.css";
// import TribuCoru from "../assets/img/TribuCoru.svg";
import ImgBanner from "../assets/img/LPBrasil/CaaS.svg";
import Ballons from "../assets/img/LPBrasil/Ballons.svg";
import GifLogos from "../assets/img/LPBrasil/GifColaboradores.gif";
import GifLogos1 from "../assets/img/LPBrasil/GifColaboradores1.gif";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/forBusiness/CoruLogoFB.svg";
// import { imageComponent } from "../components/common/common";
import { CardsBtn } from "../assets/styles/Training.styled";
// import Colaboradores from "../components/rediseno_about_us/Colaboradores";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import RegisterWithBusiness from "./pantallasInicio/ registerWithBusiness";
import ModalSuccessRegister from "../components/common/ModalSuccessRegister";
import ClickButton from "../Helpers/HookHelpers";
import RegisterWithBusinessOfBrasil from "./pantallasInicio/registerWithBusinessOfBrasil";
import ManchasBrasil from "../assets/img/LPBrasil/ManchasBrasil.svg";

const Container1 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(360deg, rgba(239, 209, 247, 0.32) 0.52%, rgba(209, 209, 247, 0.32) 39.06%, rgba(255, 255, 255, 0.32) 100%);
  min-height: 100vh;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const TitleBlue = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: ${(props) => props.textA || "center"};
  letter-spacing: -0.3px;
  color: #393a80;
  margin: ${(props) => props.marginM || ""};
  @media (min-width: 770px) {
    text-align: ${(props) => props.textAM || "center"};
    font-size: ${(props) => props.fontSD || "52px"};
    margin: ${(props) => props.marginD || ""};
  }
`;

const TextGray = styled.div`
  font-weight: ${(props) => props.fontW || "400"};
  font-size: ${(props) => props.fontS || "16px"};
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.3px;
  color: #595959;
  text-align: ${(props) => props.textA || ""};
  margin-top: ${(props) => props.marginT || ""};
  margin: ${(props) => props.margin || ""};
  cursor: ${(props) => props.cursor || ""};
  @media (min-width: 770px) {
    display: ${(props) => props.displayMQ || "block"};
    font-size: ${(props) => props.fontSD || "24px"};
    text-align: ${(props) => props.textAD || ""};
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "24px"};
  justify-content: ${(props) => props.justifyC || ""};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.marginT || ""};
  text-align: ${(props) => props.textA || ""};
  min-height: ${(props) => props.minH || ""};
  @media (max-width: 769px) {
    display: ${(props) => props.displayM || ""};
    direction: ${(props) => props.directionM || ""};
    margin: ${(props) => props.marginM || ""};
  }
`;

const ContainerW = styled.div`
  width: 100%;
  @media (min-width: 769px) {
    width: 50%;
    margin: ${(props) => props.margin || ""};
  }
`;

const ImageInfo = styled.img`
  width: 100%;
  max-height: 50%;

  @media (min-width: 770px) {
    max-height: 100%;
    width: ${(props) => props.widthD || "605px"};
    margin: ${(props) => props.margin || ""};
    margin-top: ${(props) => props.mtop || ""};
    max-width: ${(props) => props.MwidthD || ""};
  }
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 50%;
    margin-top: 12px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 50%;
  }
`;

const ContainerFD = styled.div`
  /* display: block;
  @media (min-width: 770px) {
    display: flex;
    margin-top: 30px;
  } */
`;

const ContainerOfForm = styled.div`
  max-width: 606px;
  width: 100%;
  /* max-height: 646px; */
  height: 100%;
  
  background-color: ${(p) => p.bgColor || 'rgba(255, 255, 255, 0.5)'};
  border-radius: 16px;
  display: flex;
  padding: 24px 24px;
  @media (max-width: 770px) {
    display: ${(props) => props.displayM || "flex"};
  }
`;

const TitleOfForm = styled.div`
  font-size: 32px;
  color: #393a80;
  font-weight: 700;
  font-family: Cabin;
`;

const SubTitleForm = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding-top: 12px;
  /* font-family: Roboto; */
  line-height: 20.8px;
  padding-bottom: 24px;
`;

const Cases = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  max-width: 488px;
  height: ${(props) => props.height || "90px"};
  background: ${(props) => props.bg || "#FFFFFF"};
  box-shadow: ${(props) => props.bs || "0px 4px 20px #C9CEEE"};
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  backdrop-filter: ${(props) => props.bf || ""};
`;

const Desafios = styled.div`
  width: 20%;
  background: ${(props) => props.bg || "#FFFFFF"};
  padding: 16px 24px;
  color: ${(props) => props.color || "#A6A6A6"};
  font-size: ${(props) => props.fs || "16px"};
  font-weight: ${(props) => props.fw || ""};
  box-shadow: 0px 8px 16px rgba(197, 214, 243, 0.6);
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 770px) {
    width: 100%;
    margin: 12px 0;
  }
`;

const ContainerBanner = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 770px) {
    display: block;
  }
`;

const InfoDesafio = styled.span`
padding: 24px;
max-width: 25%;
min-height: auto;
background: rgba(255, 255, 255, 0.8);
backdrop-filter: blur(8px);
border-radius: 16px;
margin: 24px;
height: fit-content;
@media (max-width: 770px) {
  width: 70%;
  position: absolute;
  max-width: 100%;
}
`;

const FotoDesafio = styled.div`
  min-width: 75%;
  margin: 24px;
  @media (max-width: 770px) {
    min-width: 10%;
  }
`;

const ImgMancha = styled.img`
  height: 100%;
  width: 100%;
  max-width: 606px;
  max-height: 616px;
`;
const WrapperOFElementsForm = styled.div`
  /* border: 1px solid  red; */
`;

function LPBrasil() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [changeAdquisicion, setchangeAdquisicion] = useState(true);
  const [changeOnboarding, setchangeOnboarding] = useState(false);
  const [changeCrosselling, setchangeCrosselling] = useState(false);
  const [changeExperiencia, setchangeExperiencia] = useState(false);
  const [modalregister, setModalRegister] = useState(false);
  const [openModalSuccess, setopenModalSuccess] = useState(false);
  const [changeConversacion, setchangeConversacion] = useState(true);
  const [changeOptimizacion, setchangeOptimizacion] = useState(false);
  const [changePerfil, setchangePerfil] = useState(false);
  const [changeEstrategia, setchangeEstrategia] = useState(false);
  const [changeSoluciones, setchangeSoluciones] = useState(false);

  const [form, setForm] = useState({
    fullname: "",
    email: "",
    numPhone: "",
    lada: "México lada +(52)",
    textlada: "México lada +(52)",
    Empresa: "",
    Posicion: "",
    Sitio: "",
  });
  const [validCampos, setvalidCampos] = useState({
    personales: false,
    contacto: false,
    password: false,
  });

  const handleClickCoru = (name) => {
    actions({
      action: 'ClickButtonLPBrasil',
      variables: 'ClickDemo_LpCoruBrasil',
      collection: 'LogClickButtonLpCoruBrasil',
    });
    switch (name) {
      case 'Ingresar':
        sessionStorage.setItem('Training', 'Business');
        window.location.href = '/login-v2';
        break;
      case 'CrearCuenta':
        gsap.to(window, { duration: 1, scrollTo: '#CrearCuenta' });
        // window.location.href = '/personal-Information-v2';
        break;
      default:
        break;
    }
  };

  const Header = () => (
    <div style={{ height: "85px", display: "flex" }}>
      <ContLogo>
        <Image
          cursor="pointer"
          src={ImageLogo}
          width="230px"
          mwidth="97.92px"
          heightMobile="20.8px"
          alt="logo-coru"
        />
      </ContLogo>
      <ContLogo2>
        <ContentGeneric width="100%" widthMd="20%">
          <KandaButton
            action={() => handleClickCoru('CrearCuenta')}
            mr="96px"
            p="10px 5px"
            height="40px"
            width="100%"
            fontS="12px"
            typeBtn="active">
            Agendar uma Demo
          </KandaButton>
        </ContentGeneric>
      </ContLogo2>
    </div>
  );

  const handleClickInteraccion = (name) => {
    switch (name) {
      case 'Adquisicion':
        if (!changeAdquisicion) {
          setchangeAdquisicion(true);
          setchangeOnboarding(false);
          setchangeCrosselling(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Onboarding':
        if (!changeOnboarding) {
          setchangeAdquisicion(false);
          setchangeOnboarding(true);
          setchangeCrosselling(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Crosselling':
        if (!changeCrosselling) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeCrosselling(true);
          setchangeExperiencia(false);
        }
        break;
      case 'Experiencia':
        if (!changeExperiencia) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeCrosselling(false);
          setchangeExperiencia(true);
        }
        break;
      case 'Conversacion':
        if (!changeConversacion) {
          setchangeConversacion(true);
          setchangeOptimizacion(false);
          setchangePerfil(false);
          setchangeEstrategia(false);
          setchangeSoluciones(false);
        }
        break;
      case 'Optimizacion':
        if (!changeOptimizacion) {
          setchangeConversacion(false);
          setchangeOptimizacion(true);
          setchangePerfil(false);
          setchangeEstrategia(false);
          setchangeSoluciones(false);
        }
        break;
      case 'Perfil':
        if (!changePerfil) {
          setchangeConversacion(false);
          setchangeOptimizacion(false);
          setchangePerfil(true);
          setchangeEstrategia(false);
          setchangeSoluciones(false);
        }
        break;
      case 'Estrategia':
        if (!changeEstrategia) {
          setchangeConversacion(false);
          setchangeOptimizacion(false);
          setchangePerfil(false);
          setchangeEstrategia(true);
          setchangeSoluciones(false);
        }
        break;
      case 'Soluciones':
        if (!changeSoluciones) {
          setchangeConversacion(false);
          setchangeOptimizacion(false);
          setchangePerfil(false);
          setchangeEstrategia(false);
          setchangeSoluciones(true);
        }
        break;
      default:
        break;
    }
  };

  const handleClickModal = (name) => {
    actions({
      action: 'ClickButtonLPBrasil',
      variables: `${name}`,
      collection: 'LogClickButtonLpCoruBrasil',
    });
    setModalRegister(!modalregister);
  };

  return (
    <Container1>
      <Header />
      <div
        style={{
          maxWidth: "1056px",
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
        }}
      >
        <ContainerBanner>
          <TitleBlue className="cabin" style={{ margin: "15px 0" }}>
            O seu<br />aliado
          </TitleBlue>
          <ImageInfo widthD="257px" mtop="30px" src={ImgBanner} />
          <TitleBlue className="cabin" marginD="160px 0 15px 0">
            de canais<br />digitais
          </TitleBlue>
        </ContainerBanner>
        <TextGray
          className="roboto"
          textA={"center"}
          textAD="center"
          fontSD="16px"
          style={{ margin: "15px 0" }}
        >
          Somos <span style={{ color: '#A5C3FF' }}>especialistas em escalar digitalmente o seu negócio.</span>
          Potencializamos rapidamente os seus resultados com Inteligência Artificial e Machine Learning.
        </TextGray>
        <CardsBtn
          styledMaxWidth="300px"
          type="button"
          minmd="155px"
          min_w="300px"
          min_web="300px"
          max_w="300px"
          windthmd="60%"
          style={{ borderRadius: "16px" }}
          onClick={() => handleClickModal('ClickEspecialista_LpCoruBrasil')}
        >
          Fale com um especialista
        </CardsBtn>
        <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
          Qual é o seu desafio?
        </TitleBlue>
        <div
          style={{
            width: '100%', background: '#E0E9FE', borderRadius: '16px 16px 0px 0px', minHeight: 'auto'
          }}
        >
          <ContainerFlex minH="292px" displayM="block">
            <FotoDesafio>Foto</FotoDesafio>
            <InfoDesafio>
              {changeConversacion ? 'Baixo engajamento e conversão nos seus canais digitais' : ''}
              {changeOptimizacion ? 'Ineficiência nas estratégias de digitalização da relação com seus usuários ou clientes' : ''}
              {changePerfil ? 'Entender melhor o perfil de cada usuário ou cliente para direcionar comunicações, produtos ou ofertas específicas' : ''}
              {changeEstrategia ? 'Baixa eficiência em gerar recorrência e vendas de produtos complementares com seus usuários ou clientes' : ''}
              {changeSoluciones ? 'Dificuldade em gerar fidelização e retenção de seus usuários ou clientes' : ''}
            </InfoDesafio>
          </ContainerFlex>
          <ContainerFlex gap="0" displayM="block">
            <Desafios
              onClick={() => handleClickInteraccion('Conversacion')}
              bg={changeConversacion ? 'transparent' : ''}
              color={changeConversacion ? '#393A80' : ''}
              fw={changeConversacion ? '700' : ''}
            >
              Gegeração de engajamento
            </Desafios>
            <Desafios
              onClick={() => handleClickInteraccion('Optimizacion')}
              bg={changeOptimizacion ? 'transparent' : ''}
              color={changeOptimizacion ? '#393A80' : ''}
              fw={changeOptimizacion ? '700' : ''}
            >
              Otimização de estratégias de digitalização
            </Desafios>
            <Desafios
              onClick={() => handleClickInteraccion('Perfil')}
              bg={changePerfil ? 'transparent' : ''}
              color={changePerfil ? '#393A80' : ''}
              fw={changePerfil ? '700' : ''}
            >
              Perfil do usuário
            </Desafios>
            <Desafios
              onClick={() => handleClickInteraccion('Estrategia')}
              bg={changeEstrategia ? 'transparent' : ''}
              color={changeEstrategia ? '#393A80' : ''}
              fw={changeEstrategia ? '700' : ''}
            >
              Criação de estratégias de recorrência
            </Desafios>
            <Desafios
              onClick={() => handleClickInteraccion('Soluciones')}
              bg={changeSoluciones ? 'transparent' : ''}
              color={changeSoluciones ? '#393A80' : ''}
              fw={changeSoluciones ? '700' : ''}
            >
              Idealização de soluções de fidelização
            </Desafios>
          </ContainerFlex>
        </div>
        <ContainerFD>
          <ContainerFlex marginT="105px" justifyC="space-between" displayM="block" directionM="rtl">
            <ContainerW margin="auto">
              <TitleBlue marginM="20px" className="cabin" fontS="32px" fontSD="32px" textAM="left" marginD="24px 0">
                Que soluções oferecemos?
              </TitleBlue>
              <TextGray className="roboto" fontSD="16px" textAD="left">
                <span style={{ color: '#F64282' }}>De soluções conversacionais e inteligentes até gamification</span>,
                criamos, através de nossa plataforma, experiências digitais sob medida para seus usuários ou clientes.
              </TextGray>
            </ContainerW>
            <ContainerW>
              <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="24px 0">
                Cases
              </TitleBlue>
              <Cases
                onClick={() => handleClickInteraccion('Adquisicion')}
                height
                bf={!changeAdquisicion ? 'blur(16px)' : ''}
                bs={!changeAdquisicion ? '0px' : '0px 4px 20px #C9CEEE'}
              >
                <TextGray fontW="700" fontSD="16px">Perfilamento e aquisição</TextGray>
                {changeAdquisicion ? <div>De usuários ou clientes via canais digitais</div> : null}
              </Cases>
              <Cases
                onClick={() => handleClickInteraccion('Onboarding')}
                height
                bf={!changeOnboarding ? 'blur(16px)' : ''}
                bs={!changeOnboarding ? '0px' : '0px 4px 20px #C9CEEE'}
              >
                <TextGray fontW="700" fontSD="16px">Onboarding digital </TextGray>
                {changeOnboarding ? <div>Para usuários ou clientes</div> : null}
              </Cases>
              <Cases
                onClick={() => handleClickInteraccion('Crosselling')}
                height
                bf={!changeCrosselling ? 'blur(16px)' : ''}
                bs={!changeCrosselling ? '0px' : '0px 4px 20px #C9CEEE'}
              >
                <TextGray fontW="700" fontSD="16px">Crosselling</TextGray>
                {changeCrosselling ? <div>De produtos complementares</div> : null}
              </Cases>
              <Cases
                onClick={() => handleClickInteraccion('Experiencia')}
                height
                bf={!changeExperiencia ? 'blur(16px)' : ''}
                bs={!changeExperiencia ? '0px' : '0px 4px 20px #C9CEEE'}
              >
                <TextGray fontW="700" fontSD="16px">Melhoria da experiência</TextGray>
                {changeExperiencia ? <div>e satisfação dos seus usuários, clientes ou parceiros</div> : null}
              </Cases>
            </ContainerW>
          </ContainerFlex>
        </ContainerFD>
        <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
          Cases de sucesso
        </TitleBlue>
        <TextGray
          className="roboto"
          textA={"center"}
          textAD="center"
          fontSD="16px"
          style={{ margin: "15px 0" }}
        >
          +300 empresas/partners y 3M de usuarios en <span style={{ color: '#F64282' }}>coru.com</span>
        </TextGray>
        <ImageInfo widthD="auto" MwidthD="614px" margin="auto" mtop="30px" src={Ballons} />
        <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
          Eles confiam en nós
        </TitleBlue>
        <TextGray
          className="roboto"
          textA={"center"}
          textAD="center"
          fontSD="16px"
          style={{ margin: "15px 0" }}
        >
          Já são mais de 300 empresas e startups transformando seus canais digitais…
        </TextGray>
        <ImageInfo widthD="auto" MwidthD="200px" margin="auto" mtop="30px" src={GifLogos1} />
        <TextGray
          className="roboto"
          textA={"center"}
          textAD="center"
          fontSD="16px"
          style={{ margin: "15px 0" }}
        >
          E contamos com os melhores parceiros!
        </TextGray>
        <ImageInfo widthD="auto" MwidthD="200px" margin="auto" mtop="30px" src={GifLogos} />
        {/* <div>Hasta aquí</div> */}
        <div id="CrearCuenta" style={{
          display: 'flex', margin: "15px 0", justifyContent: 'space-between', width: '100%', gap: '12px'
        }}>
          <ContainerOfForm bgColor="none" displayM="none" >
            <ImgMancha src={ManchasBrasil} alt=""/>
          </ContainerOfForm>
          <ContainerOfForm>
            <WrapperOFElementsForm>
              <TitleOfForm>
              Conte-nos o seu caso
              </TitleOfForm>
              <SubTitleForm>
              Para falar com um de nossos especialistas, use o fo use o formulário abaixo e entraremos em contato em breve
              </SubTitleForm>
              <RegisterWithBusinessOfBrasil />
            </WrapperOFElementsForm>
          </ContainerOfForm>
        </div>
        <br /><br />
      </div>
      <RegisterWithBusiness
        key={129}
        open={modalregister}
        setOpen={setModalRegister}
        setSuccess={setopenModalSuccess}
        success={openModalSuccess}
        isBrasilLp={true}
      />
      <ModalSuccessRegister
        key={130}
        open={openModalSuccess}
        setOpen={setopenModalSuccess}
      />
      <Footer isAboutUS="si" isLpBrasil="si" section="LPBrasil"/>
    </Container1>
  );
}

export default LPBrasil;
