import React, { Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  Container,
  Col3,
  Col4,
  ButtonPassword,
  ContentButton,
} from '../common.styled';
import '../../../assets/styles/WelcomeValidation.css';
import '../../../assets/styles/TextCustom.css';

function CcPasswordNew() {
  const [form, setForm] = useState({
    pass1: '',
    pass2: '',
    pass3: '',
    pass4: '',
  });
  const [error, setError] = useState({
    pass1: false,
    pass2: false,
    pass3: false,
    pass4: false,
  });

  const [btn, setBtn] = useState(true);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    ebtn();
  };

  const ebtn = () => {
    if (
      form.pass1.length === 1
      && !error.pass1
      && form.pass2.length === 1
      && !error.pass2
      && form.pass3.length === 1
      && !error.pass3
    ) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  };

  const handleClick = (e) => {
    if (form.pass1 === '') {
      setForm({
        ...form,
        pass1: e.target.value,
      });
    } else if (form.pass2 === '') {
      setForm({
        ...form,
        pass2: e.target.value,
      });
    } else if (form.pass3 === '') {
      setForm({
        ...form,
        pass3: e.target.value,
      });
    } else if (form.pass4 === '') {
      setForm({
        ...form,
        pass4: e.target.value,
      });
    }
    ebtn();
  };

  const guardar = (e) => {
    e.preventDefault();
    if (
      form.pass1.length === 1
      && !error.pass1
      && form.pass2.length === 1
      && !error.pass2
      && form.pass3.length === 1
      && !error.pass3
      && form.pass4.length === 1
      && !error.pass4
    ) {
      window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/step2-comparativo';
    }
  };

  return (
    <Fragment>
      <Main background="#fff" mb="15%" minheigth="700px">
        <Container width="50%" mt="1rem" widthMobile="100%" mb="15%">
          <Col11>
            <SubtitleOne
              size="1.4rem"
              mt="7%"
              id="idTitle"
              className="font-weight-bold"
            >
              Último paso para tu registro
            </SubtitleOne>
            <ParagrapTwo className="mx-auto">
              Ingresa tu contraseña de 4 digitos. Recuérdala para regresar a
              Coru.
            </ParagrapTwo>
            <form autoComplete="off" onSubmit={guardar}>
              <Container width="50%" widthMobile="80%">
                <Col3>
                  <TextField
                    id="pass1"
                    name="pass1"
                    label="1"
                    type="tel"
                    value={form.pass1}
                    onChange={handleChange}
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="pass2"
                    name="pass2"
                    label="2"
                    type="tel"
                    value={form.pass2}
                    onChange={handleChange}
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="pass3"
                    name="pass3"
                    label="3"
                    type="tel"
                    value={form.pass3}
                    onChange={handleChange}
                  />
                </Col3>
                <Col3>
                  <TextField
                    id="pass4"
                    name="pass4"
                    label="4"
                    type="tel"
                    value={form.pass4}
                    onChange={handleChange}
                  />
                </Col3>
              </Container>
              <Container
                mt="8%"
                mb="8%"
                width="50%"
                widthMobile="80%"
                displayweb="none"
                displaymobile="flex"
              >
                <Col4>
                  <ButtonPassword
                    id="btn1"
                    type="button"
                    value="1"
                    onClick={handleClick}
                  >
                    1
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn2"
                    type="button"
                    value="2"
                    onClick={handleClick}
                  >
                    2
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn3"
                    type="button"
                    value="3"
                    onClick={handleClick}
                  >
                    3
                  </ButtonPassword>
                </Col4>

                <Col4>
                  <ButtonPassword
                    id="btn4"
                    type="button"
                    value="4"
                    onClick={handleClick}
                  >
                    4
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn5"
                    type="button"
                    value="5"
                    onClick={handleClick}
                  >
                    5
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn6"
                    type="button"
                    value="6"
                    onClick={handleClick}
                  >
                    6
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn7"
                    type="button"
                    value="7"
                    onClick={handleClick}
                  >
                    7
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn8"
                    type="button"
                    value="8"
                    onClick={handleClick}
                  >
                    8
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn9"
                    type="button"
                    value="9"
                    onClick={handleClick}
                  >
                    9
                  </ButtonPassword>
                </Col4>
                <Col4>
                  <ButtonPassword
                    id="btn0"
                    type="button"
                    value="0"
                    onClick={handleClick}
                  >
                    0
                  </ButtonPassword>
                </Col4>
              </Container>
              <ContentButton mt="10%" mtMobile="2%">
                <Button
                  type="submit"
                  disabled={btn}
                  className="d-block  mt-md-4 mb-5"
                >
                  Finalizar registro
                </Button>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
    </Fragment>
  );
}

export default CcPasswordNew;
