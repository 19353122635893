/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-tabs */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { TextField, MenuItem } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import {
  TrainingText,
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
} from '../../assets/styles/Training.styled';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardTwo from '../../components/Home/Card2Animate';
import ClickButton from '../../Helpers/HookHelpers';
import CardOne from '../../components/Home/Card1Animate';

const CalculadoraInvestmentCatalogo = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setInvestmentCatalogProducts,
  saveAdvance,
  data,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [providersResult, setProvidersResult] = useState([]);
  const [catPlazos, setCatPlazos] = useState([]);
  const [catTasa, setCatTasa] = useState([]);
  const [catProviders, setCatProviders] = useState([]);
  const [catTipos, setCatTipos] = useState([]);
  const [productosObtenidos, setProductosObtenidos] = useState([]);
  const [disableTasa, setDisableTasa] = useState(catTasa.length !== 0);
  const [disabledPlazo, setDisablePlazo] = useState(catPlazos.length === 0);
  const [disabledProveedor, setDisabledProveedor] = useState(
    catProviders.length === 0,
  );
  const [disabledTipo, setDisabledTipo] = useState(catTipos.length === 0);
  const [form, setForm] = useState({
    MontoInversion: sessionStorage.getItem('MontoInversión') || '',
    PlazoInversion: sessionStorage.getItem('PlazoInversión') || '',
    TasaRendimiento: sessionStorage.getItem('TasaRendimiento') || '',
    ProveedorOptimo: sessionStorage.getItem('ProveedorÓptimo') || '',
    TipoHerramientaInversion:
      sessionStorage.getItem('TipoHerramientaInversión') || '',
  });

  const {
    MontoInversion,
    PlazoInversion,
    TasaRendimiento,
    ProveedorOptimo,
    TipoHerramientaInversion,
  } = form;

  const amounts = [
    { value: '100 pesos', label: '100 pesos' },
    { value: '500 pesos', label: '500 pesos' },
    { value: '1,000 pesos', label: '1,000 pesos' },
    { value: '5,000 pesos', label: '5,000 pesos' },
    { value: '10,000 pesos', label: '10,000 pesos' },
    { value: '50,000 pesos', label: '50,000 pesos' },
    { value: '100,000 pesos', label: '100,000 pesos' },
    { value: '1,000,000 pesos', label: '1,000,000 pesos' },
  ];

  const [Rutine, setRutine] = useState({});
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined || !value) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChange = ({ target }) => {
    let next;
    switch (target.name) {
      case 'MontoInversion':
        next = 'PlazoInversion';
        break;
      case 'PlazoInversion':
        next = 'TasaRendimiento';
        break;
      case 'TasaRendimiento':
        next = 'ProveedorOptimo';
        break;
      case 'ProveedorOptimo':
        next = 'TipoHerramientaInversion';
        break;
      default:
        break;
    }
    setForm({
      ...form,
      [target.name]: target.value,
      [next]: '',
    });
  };

  const setValidSelect = (name) => {
    try {
      document.getElementById(name).className = 'textInput inputs-bottom validSelectCatalogo';
    } catch (error) {
      document.querySelectorAll(`#${name}`)[1].className = 'textInput inputs-bottom validSelectCatalogo';
    }
  };

  const removeValidSelect = (name) => {
    try {
      document.getElementById(name).className = 'textInput inputs-bottom';
    } catch (error) {
      document.querySelectorAll(`#${name}`)[1].className = 'textInput inputs-bottom';
    }
  };

  useEffect(() => {
    if (catTasa.length !== 0) {
      setDisableTasa(false);
    } else {
      setDisableTasa(true);
    }
  }, [catTasa]);

  useEffect(() => {
    if (catPlazos.length !== 0) {
      setDisablePlazo(false);
    } else {
      setDisablePlazo(true);
    }
  }, [catPlazos]);

  useEffect(() => {
    if (catProviders.length !== 0) {
      setDisabledProveedor(false);
    } else {
      setDisabledProveedor(true);
    }
  }, [catProviders]);

  useEffect(() => {
    if (catTipos.length !== 0) {
      setDisabledTipo(false);
    } else {
      setDisabledTipo(true);
    }
  }, [catTipos]);
  const sortCatPlazos = (plazos, unit) => {
    const filterByUnit = plazos.filter((e) => e.Name.endsWith(unit));
    const num = filterByUnit.map((e) => e.Name.split(' ')[0]);
    num.sort((a, b) => a - b);
    const setLabel = num.map((e) => {
      if (e === unit) {
        return { Name: `${unit}` };
      }
      return { Name: `${e} ${unit}` };
    });
    return setLabel;
  };
  const handleChangeMonto = () => {
    let filter = [];
    setProvidersResult([]);
    if (form.MontoInversion) {
      setValidSelect('MontoInversion');

      filter = data.filter(
        (v) => v.montoMin <= parseInt(form.MontoInversion.replace(',', ''), 10),
      );
      const plazos = filter.map((x) => ({ Name: x.ProductSubSubCategory }));
      let catPlazos = plazos.filter(
        (v, i, a) => a.findIndex((t) => t.Name === v.Name && t.Name && v.Name) === i,
      );
      const elegir = sortCatPlazos(catPlazos, 'elegir');
      const vista = sortCatPlazos(catPlazos, 'Vista');
      const days = sortCatPlazos(catPlazos, 'días');
      const year = sortCatPlazos(catPlazos, 'año');
      const years = sortCatPlazos(catPlazos, 'años');
      catPlazos = elegir.concat(vista).concat(days).concat(year).concat(years);
      setCatPlazos(catPlazos);
      setDisablePlazo(false);
    }
  };
  const handleChangePlazo = () => {
    setCatProviders([]);
    setCatTasa([]);
    setProvidersResult([]);
    removeValidSelect('TasaRendimiento');
    if (form.PlazoInversion) {
      setValidSelect('PlazoInversion');
      if (form.PlazoInversion === 'A elegir') {
        setCatTasa([{ value: 'Variable', label: 'Variable' }]);
        setDisableTasa(false);
      } else {
        setCatTasa([{ value: 'Fija', label: 'Fija' }]);
        setDisableTasa(false);
      }
      const filterByAmount = data.filter(
        (v) => v.montoMin <= parseInt(form.MontoInversion.replace(',', ''), 10),
      );
      const filterByTimeLimit = filterByAmount.filter(
        (v) => v.ProductSubSubCategory === form.PlazoInversion,
      );
      const proveedores = filterByTimeLimit.map((x) => ({ Name: x.Name }));
      const catProveedores = proveedores.filter(
        (v, i, a) => a.findIndex((t) => t.Name === v.Name) === i,
      );
      setCatProviders(catProveedores);
      setDisabledProveedor(false);
    } else {
      removeValidSelect('PlazoInversion');
      setForm({
        ...form,
        TasaRendimiento: '',
      });
    }
  };

  const handleChangeTasa = () => {
    if (form.TasaRendimiento) {
      setValidSelect('TasaRendimiento');
    } else {
      removeValidSelect('TasaRendimiento');
      setForm({
        ...form,
        ProveedorOptimo: '',
      });
    }
  };

  const handleChangeProviders = () => {
    setCatTipos([]);
    setProvidersResult([]);
    if (form.ProveedorOptimo) {
      setValidSelect('ProveedorOptimo');
      const filterByAmount = data.filter(
        (v) => v.montoMin <= parseInt(form.MontoInversion.replace(',', ''), 10),
      );
      const filterByTimeLimit = filterByAmount.filter(
        (v) => v.ProductSubSubCategory === form.PlazoInversion,
      );
      const filterByProviders = filterByTimeLimit.filter(
        (e) => e.Name === form.ProveedorOptimo,
      );
      const benefitsTipo = filterByProviders.map((e) => e.benefits.filter((benefit) => benefit.Name === 'Tipo'));
      let catTipos = benefitsTipo
        .map((e) => e.map((v) => ({ Name: v.Description })))
        .flat();
      catTipos = catTipos.filter(
        (v, i, a) => a.findIndex((t) => t.Name === v.Name) === i,
      );
      setCatTipos(catTipos);
      setDisabledTipo(false);
    } else {
      removeValidSelect('ProveedorOptimo');
      setForm({
        ...form,
        TipoHerramientaInversion: '',
      });
    }
  };

  const handleChangeTipo = () => {
    setProvidersResult([]);
    setProductosObtenidos([]);
    setInvestmentCatalogProducts([]);
    if (form.TipoHerramientaInversion) {
      setValidSelect('TipoHerramientaInversion');
      const filterByAmount = data.filter(
        (v) => v.montoMin <= parseInt(form.MontoInversion.replace(',', ''), 10),
      );
      const filterByTimeLimit = filterByAmount.filter(
        (v) => v.ProductSubSubCategory === form.PlazoInversion,
      );
      const filterByProviders = filterByTimeLimit.filter(
        (e) => e.Name === form.ProveedorOptimo,
      );
      const filterResult = filterByProviders.filter((e) => e.benefits.find(
        (benefit) => benefit.Description === form.TipoHerramientaInversion,
      ));
      setProvidersResult(filterResult);
    } else {
      removeValidSelect('TipoHerramientaInversion');
    }
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const routines = JSON.parse(recoveryRoutineByOrder.response);
        const firstdRoutineId = Object.keys(routines)[0];
        setRutine(routines[firstdRoutineId]);
        if (Object.keys(routines).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                }
              }
            }

            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    setProductosObtenidos(providersResult);
  });
  const [DatosCalculadora, setDatosCalculadora] = useState(false);

  const handleOpcInvestmentCat = () => {
    const click = `ContinuarCalculadora_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    if (form.TasaRendimiento && productosObtenidos.length > 0) {
      setDatosCalculadora(true);
      setInvestmentCatalogProducts(productosObtenidos);
      createJsonInput(Rutine, questions);
      saveAdvance(parseInt(currentRoutine));
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      if (sessionStorage.getItem('TipoHerramientaInversión')) {
        handleOpcInvestmentCat();
      }
    }
  }, [recoveryRoutine]);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);

  const handleChangeIcon = (json) => {
    setDatosCalculadora(false);
    setInvestmentCatalogProducts([]);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const anchop = window.screen.width;
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && !DatosCalculadora && orderRutine === 3) {
        gsap.to(window, { duration: 1, scrollTo: '#activeCatalogoInversion' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);
  useEffect(() => {
    handleChangeMonto();
  }, [MontoInversion]);

  useEffect(() => {
    handleChangePlazo();
  }, [PlazoInversion]);

  useEffect(() => {
    handleChangeTasa();
  }, [TasaRendimiento]);

  useEffect(() => {
    handleChangeProviders();
  }, [ProveedorOptimo]);

  useEffect(() => {
    handleChangeTipo();
  }, [TipoHerramientaInversion]);

  return (
    <>
      <div id="activeCatalogoInversion"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
      />
      <TrainingQuestion
        wiw="80%"
        className={!DatosCalculadora && orderRutine === 3 ? '' : 'hidden'}
        mt="20px"
      >
        <QuestionsDivs
          padd="5%"
          txta="left"
          width="100%"
          widthweb="75%"
          hei="auto"
          margin="auto"
          bc="#a6a6a61f"
          marginL="9.9%"
          marginLmd="0px"
        >
          <form>
            <div className="cardPrincipal1">
              <TrainingText
                className="cabin"
                align="left"
                weight="600"
                size="20px"
                sizew="20px"
              >
                Catálogo de inversión
              </TrainingText>

              <TextField
                style={{ marginBottom: '0px' }}
                variant="outlined"
                id="MontoInversion"
                name="MontoInversion"
                select
                label="Monto a invertir"
                helperText=""
                className="minwidth50 input-coru width100 cursor-p"
                onChange={handleChange}
                onBlur={(e) => handleChangeQuestionsSend(
                  '1',
                  'MontoInversion',
                  e.target.value,
                  'Cat',
                )
                }
                value={form.MontoInversion}
                inputProps={{ className: 'cursor-p' }}
              >
                {amounts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                style={{ marginBottom: '0px' }}
                variant="outlined"
                id="PlazoInversion"
                name="PlazoInversion"
                select
                disabled={disabledPlazo}
                label="Plazo"
                helperText=""
                className="minwidth50 input-coru width100"
                onChange={handleChange}
                onBlur={(e) => handleChangeQuestionsSend(
                  '2',
                  'PlazoInversion',
                  e.target.value,
                  'Cat',
                )
                }
                value={form.PlazoInversion}
              >
                {catPlazos.map((option) => (
                  <MenuItem key={option.Name} value={option.Name}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                style={{ marginBottom: '0px' }}
                variant="outlined"
                id="TasaRendimiento"
                name="TasaRendimiento"
                select
                disabled={disableTasa}
                label="Tasa"
                helperText=""
                className="minwidth50 input-coru width100"
                onChange={handleChange}
                onBlur={(e) => handleChangeQuestionsSend(
                  '4',
                  'TasaRendimiento',
                  e.target.value,
                  'Cat',
                )
                }
                value={form.TasaRendimiento}
              >
                {catTasa.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                style={{ marginBottom: '0px' }}
                variant="outlined"
                id="ProveedorOptimo"
                name="ProveedorOptimo"
                select
                disabled={disabledProveedor}
                label="Proveedor"
                helperText=""
                className="minwidth50 input-coru width100"
                onChange={handleChange}
                onBlur={(e) => handleChangeQuestionsSend(
                  '3',
                  'ProveedorOptimo',
                  e.target.value,
                  'Cat',
                )
                }
                value={form.ProveedorOptimo}
              >
                {catProviders.map((option) => (
                  <MenuItem key={option.Name} value={option.Name}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                style={{ marginBottom: '0px' }}
                variant="outlined"
                id="TipoHerramientaInversion"
                name="TipoHerramientaInversion"
                select
                disabled={disabledTipo}
                label="Tipo"
                helperText=""
                className="minwidth50 input-coru width100"
                onChange={handleChange}
                onBlur={(e) => handleChangeQuestionsSend(
                  '5',
                  'TipoHerramientaInversion',
                  e.target.value,
                  'Cat',
                )
                }
                value={form.TipoHerramientaInversion}
              >
                {catTipos.map((option) => (
                  <MenuItem key={option.Name} value={option.Name}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>
              <CardsBtn
                mtop="0px"
                mtopw="0px"
                mtopmd="0px"
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={(e) => handleOpcInvestmentCat(e)}
              >
                Continuar
              </CardsBtn>
              <TrainingText
                size="12px"
                sizew="12px"
                className="roboto"
                align="left"
                weight="400"
                top="5%"
              >
                Tasas de interés actualizadas al 15 de julio del 2021
              </TrainingText>
            </div>
          </form>
        </QuestionsDivs>
      </TrainingQuestion>
      <div>
        <img
          alt="editIcon"
          className={DatosCalculadora ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 3, active: 1 })}
        />
        <CardTwo
          classCard={DatosCalculadora ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          textT={`Monto a invertir: ${form.MontoInversion}`}
          text2={`Plazo: ${form.PlazoInversion}`}
          text3={`Tasa: ${form.TasaRendimiento}`}
          text4={`Proveedor: ${form.ProveedorOptimo}`}
          text5={`Tipo: ${form.TipoHerramientaInversion}`}
          icon={true}
          colorTraining={trainingColor}
        ></CardTwo>
      </div>
    </>
  );
};
export default CalculadoraInvestmentCatalogo;
