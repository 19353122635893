/* eslint-disable no-tabs */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { Link } from 'react-router-dom';
// Import visual components
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TrainingQuestion,
  QuestionsDivsbar,
  QuestionsText,
  Images,
  CardsBtn,
  TrainingText,
  TrainingSubtitles,
} from '../../assets/styles/Training.styled';

import { ContentHomeSection } from '../../components/common/Home.styled';
import { Container } from '../../components/common/common.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import SectionVideo from '../../components/common/SectionVideo';
import ana from '../../assets/img/anacoach/CoachImage.png';
import imgWhats from '../../assets/img/anacoach/whats.png';
import imgPhone from '../../assets/img/anacoach/phone.png';
import imgMail from '../../assets/img/anacoach/mail.png';
import editIcon from '../../assets/img/anacoach/editar1.png';

import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';

// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import Recommendations from '../../components/Training/RecommendedTraining';
import ModalDiv from '../../components/common/ModalDiv';
import ModalRespConSinPremio from '../../components/Feed/ModalRespConSinPremio';

// Other banks
import RpDebs from './RpDebs';
import SendNotificationDebts from '../../components/common/SendNotificationDebts';

const ConAtrasoEnPagoDeuda = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const { handleSendNotification } = SendNotificationDebts();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  const anchop = window.screen.width;

  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [avisosPago, setAvisosPago] = useState(
    sessionStorage.getItem('AvisosPago') || '',
  );
  const [videoOne, setVideoOne] = useState(false);
  const [videoNegociacion, setVideoNegociacion] = useState(false);
  const [opc1, setOpc1] = useState(false);
  const [opc2, setOpc2] = useState(false);
  const [idealSolutions, setIdealSolutions] = useState(
    sessionStorage.getItem('idealSolutions') || '',
  );
  const [reparadora, setReparadora] = useState(false);

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  useEffect(() => {
    if (videoOne) {
      const click = `ContinuarVideo1_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [videoOne]);

  useEffect(() => {
    if (videoNegociacion) {
      gsap.to(window, { duration: 1, scrollTo: '#activeVideoReestructura' });
      const click = `ContinuarVideoNegociacion_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [videoNegociacion]);

  useEffect(() => {
    if (reparadora) {
      const click = `ContinuarReparadora_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [reparadora]);

  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 0:
        gsap.to(window, { duration: 1, scrollTo: '#activeRespuesta2' });
        if (value === 'No') validateOpcSelected();
        if(value !== 'No') handleSendNotification({step: '7_mail'});
        setAvisosPago(value);
        setEditAvisosPago(true);
        break;
      default:
        // console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e, question = '') => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [show, setShow] = useState(false);
  const showModal = () => {
    const click = `Contactenme_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };

  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const showModalWhatsapp = () => {
    setShowWhatsapp(true);
  };
  const handleCloseModalWhatsapp = () => {
    setShowWhatsapp(false);
  };

  const [showEmail, setShowEmail] = useState(false);
  const showModalEmail = () => {
    setShowEmail(true);
  };
  const handleCloseModalEmail = () => {
    setShowEmail(false);
  };

  const [showPhone, setShowPhone] = useState(false);
  const showModalPhone = () => {
    setShowPhone(true);
  };
  const handleCloseModalPhone = () => {
    setShowPhone(false);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    // console.log("&&& recovery >>>", recoveryRoutine)
    if (recoveryRoutine === 'activa') {
      setAvisosPago(sessionStorage.getItem('AvisosPago') || '');
      const avisosPago = sessionStorage.getItem('AvisosPago') || '';
      if (avisosPago === 'No') validateOpcSelected();
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    if (videoOne) validateOpcSelected();
  }, [videoOne]);
  const validateOpcSelected = () => {
    if (sessionStorage.getItem('MontoDeudas') === 'Menos de $30,000') {
      setOpc2(true);
    } else {
      setOpc1(true);
      setOpc2(true);
    }
  };
  const handleChangeSolutions = (opc) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ChangeSolution',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setIdealSolutions(opc);

    setEditSoluciones(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeSolucionesIdeales' });
  };
  const abrirNuevoTab = (url) => {
    // Abrir nuevo tab
    sessionStorage.setItem('clickOutProduct', url);
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickOutBuro',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    // win.focus();
  };

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editAvisosPago, setEditAvisosPago] = useState('');
  const [editSoluciones, setEditSoluciones] = useState('');
  const handleChangeIcon = (json) => {
    setEditAvisosPago(false);
    setEditSoluciones(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (avisosPago === ''
          || (lapiz[0].routine === 11 && lapiz[0].active === 1))
        && editAvisosPago !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeTeHanBuscado' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeTeHanBuscado"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="¿Te han buscado de algún despacho de cobranza para recordarte que pagues? A veces llegan estos avisos mediante llamada, correo e incluso visitas a domicilio."
        hora={hora}
      />
      <TrainingQuestion
        className={
          (avisosPago === ''
            || (lapiz[0].routine === 11 && lapiz[0].active === 1))
          && editAvisosPago !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeRespuesta2"></div>
      <CardTwo
        classCard={
          avisosPago !== ''
          && (editAvisosPago
            || !(lapiz[0].routine === 11 && lapiz[0].active === 1))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={avisosPago}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={avisosPago !== '' ? 'iconEditCommon' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 11, active: 1 })}
        />
      </CardTwo>

      {editAvisosPago || !(lapiz[0].routine === 11 && lapiz[0].active === 1) ? (
        <Fragment>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={avisosPago === 'Sí' ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="¿Qué pueden y qué no deben hacer los despacho de cobranza?"
            hora={hora}
          />
          <TrainingQuestion
            className={avisosPago === 'Sí' || videoOne ? '' : 'hidden'}
          >
            <SectionVideo title="">
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="1-1"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
              <TrainingText
                size="16px"
                className="roboto"
                align="justify"
                alignweb="justify"
                ls="-0.2px"
              >
                <p
                  className="cursor-p fs-16"
                  onClick={() => abrirNuevoTab('https://www.burodecredito.com.mx/')
                  }
                >
                  <b>¡Consulta gratis tu Buró de Crédito!</b>
                  <p>
                    {' '}
                    <Link
                      onClick={() => abrirNuevoTab('https://www.burodecredito.com.mx/')
                      }
                      style={{ color: 'blue' }}
                    >
                      https://www.burodecredito.com.mx/
                    </Link>
                  </p>
                </p>
              </TrainingText>
              <div style={{ display: 'flex' }}>
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtopw="19px"
                  mtop="19px"
                  onClick={() => {
                    setVideoOne(true);
                    handleChangeSolutions(
                      'Acudir a una reparadora de crédito.',
                    );
                  }}
                >
                  Continuar
                </CardsBtn>
              </div>
            </SectionVideo>
          </TrainingQuestion>
        </Fragment>
      ) : null}
      {!editSoluciones ? (
        <>
          <TrainingQuestion
            className={
              (((avisosPago === 'No' || videoOne) && idealSolutions === '')
                || (lapiz[0].routine === 11 && lapiz[0].active === 2))
              && (!(lapiz[0].routine === 11 && lapiz[0].active === 1)
                || editAvisosPago)
              && editSoluciones !== true
                ? ''
                : 'hidden'
            }
          >
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={avisosPago === 'No' ? 'cardOne' : 'hidden'}
              classTop="topOne1"
              text={
                opc1 && opc2
                  ? 'He encontrado dos soluciones ideales para ti. Elige la opción que mejor se acomode a tus necesidades.'
                  : 'De acuerdo con la información de tu deuda he encontrado la solución ideal para ti. '
              }
              text2={
                opc1 && opc2
                  ? ''
                  : '¡Esto te recomiendo! Elige la opción que mejor se acomode a tus necesidades:'
              }
              hora={hora}
            ></CardOne>
          </TrainingQuestion>

          <TrainingQuestion
            className={
              (((avisosPago === 'No' || videoOne) && idealSolutions === '')
                || (lapiz[0].routine === 11 && lapiz[0].active === 2))
              && (!(lapiz[0].routine === 11 && lapiz[0].active === 1)
                || editAvisosPago)
              && editSoluciones !== true
                ? ''
                : 'hidden'
            }
          >
            {opc2 ? (
              <QuestionsDivsbar
                onClick={() => handleChangeSolutions(
                  'Negociación directa de tu deuda con el banco o institución a quien le debes a pagar.',
                )
                }
                display={opc2 ? '' : 'none'}
              >
                <QuestionsText size="14px" weight="400">
                  Negociación directa de tu deuda con el Banco o institución a
                  quien le debes pagar.
                </QuestionsText>
              </QuestionsDivsbar>
            ) : (
              ''
            )}

            {opc1 ? (
              <QuestionsDivsbar
                display={opc1 ? '' : 'none'}
                onClick={() => handleChangeSolutions('Acudir a una reparadora de crédito.')
                }
              >
                <QuestionsText size="14px" weight="400">
                  Acudir a una reparadora de crédito.
                </QuestionsText>
              </QuestionsDivsbar>
            ) : (
              ''
            )}
          </TrainingQuestion>
        </>
      ) : (
        ''
      )}

      {/* ---------------------------------- */}

      <div id="activeSolucionesIdeales"></div>
      <CardTwo
        classCard={
          idealSolutions !== ''
          && (editSoluciones
            || !(lapiz[0].routine === 11 && lapiz[0].active === 2))
            ? 'hidden'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={idealSolutions}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={idealSolutions !== '' ? 'iconEditCommon' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 11, active: 2 })}
        />
      </CardTwo>

      {/*  */}

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          idealSolutions
            === 'Negociación directa de tu deuda con el banco o institución a quien le debes a pagar.'
          && nextOrderRoutine === 'rp'
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topTwo1"
        text="En este caso debes acudir directamente y exponer tu situación real, demuestra que ya no puedes pagar pero tienes intención de resolver este adeudo lo antes posible."
        text2="Pide una opción para pactar nuevas condiciones del crédito a tu favor, ya sea pagar en plazos más largos, con tasa de interés menor o para reducir el monto a pagar."
        hora={hora}
      >
        <span id="activeVideoReestructura"></span>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '0',
            backgroundColor: 'transparent',
            overflow: 'hidden',
          }}
          className={reparadora ? 'hidden' : 'd-flex flex-column'}
        >
          <CardsBtn
            color="#EC407A !important"
            back="#fff !important"
            border="solid 1px !important"
            type="button"
            min_w="100%"
            min_web="100%"
            mtopw="19px"
            mtop="19px"
            onClick={() => setVideoNegociacion(true)}
          >
            Mostrar más
          </CardsBtn>
          {videoNegociacion ? (
            <>
              <div
                className="d-flex flex-column"
                style={{
                  position: ' relative',
                  left: '0',
                  backgroundColor: 'transparent',
                  overflowX: 'hidden',
                  width: '100%',
                  margin: '0px',
                  padding: '0px',
                }}
              >
                <TrainingText
                  left="0%"
                  leftxl="0%"
                  top="20px"
                  size="16px"
                  className="roboto"
                  align="left"
                  ls="-0.2px"
                >
                  El banco podría ofrecerte una reestructuración de tu deuda
                  para que puedas mantener una buena calificación en tu
                  historial crediticio si cumples con las nuevas condiciones de
                  pago acordadas.
                </TrainingText>
                <TrainingText
                  bot="20px"
                  size="16px"
                  className="roboto"
                  align="left"
                  alignweb="left"
                  ls="-0.2px"
                >
                  O bien, si el adeudo es muy atrasado, te podrían proponer una
                  quita. Esto significa que el banco reducirá el monto de tu
                  deuda, para recuperar al menos algo del capital. Esta opción
                  tiene consecuencias negativas en tu buró de crédito, y
                  generalmente es la última salida cuando la deuda ya lleva
                  mucho tiempo sin pagarse.
                </TrainingText>
                <div
                  className="center VideoIntoCardOne"
                  style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    padding: '0',
                  }}
                >
                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    step="2-2"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />
                </div>
                <div
                  className="d-flex flex-column"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0',
                    backgroundColor: 'transparent',
                  }}
                >
                  <CardsBtn
                    color="#EC407A !important"
                    back="#fff !important"
                    border="solid 1px !important"
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="19px"
                    mtop="19px"
                    onClick={(e) => {
                      showModal(e);
                    }}
                  >
                    Contáctenme
                  </CardsBtn>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </CardOne>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          idealSolutions === 'Acudir a una reparadora de crédito.'
          && nextOrderRoutine === 'rp'
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="Lo más importante en una negociación para liquidar rápido tus deudas es conseguir las mejores condiciones de pago, y no siempre sabemos cómo hacerlo nosotros directamente."
        text2="Por eso te conviene usar el apoyo de una reparadora de deuda y lograr un buen trato lo antes posible para evitar más daño en tu buró de crédito."
        hora={hora}
      >
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            padding: '0',
          }}
          className={reparadora ? 'hidden' : 'center'}
        >
          <CardsBtn
            min_w="100%"
            color="#EC407A !important"
            back="#fff !important"
            border="solid 1px !important"
            onClick={() => setReparadora(true)}
          >
            Continuar
          </CardsBtn>
          <br />
        </div>
      </CardOne>
      {idealSolutions === 'Acudir a una reparadora de crédito.'
      && nextOrderRoutine === 'rp'
      && reparadora ? (
        <RpDebs
          Date={Date}
          hora={hora}
          currentTraining={currentTraining}
          insuranceActive={[
            'RESUELVE TU DEUDA',
            'CURADEUDA',
            'YOTEPRESTO',
            'Digitt',
          ]}
        />
        ) : null}

      {/* modal */}
      {reparadora
      && recomendaciones
      && idealSolutions
      && avisosPago !== ''
      && nextOrderRoutine === 'rp' ? (
        <Feed_Main width="100%" background=" ">
          <Feed_Main width="100%" display=" " mtop=" " background="transparent">
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="¿Aún quieres ir por más?"
              text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
            />
            <div className="marginComentLgSuccess1">
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </div>
          </Feed_Main>
        </Feed_Main>
        ) : null}
      {reparadora
      && recomendaciones
      && idealSolutions
      && avisosPago !== ''
      && nextOrderRoutine === 'rp' ? (
        <>
          <ModalAmazon show={true} wo={'Deudas'}></ModalAmazon>
          <SectionShare WO="Deudas"></SectionShare>
        </>
        ) : null}
      <ModalDiv show={show} handleClose={handleCloseModal}>
        <TrainingSubtitles>{`¡Hola ${sessionStorage.getItem(
          'FirstName',
        )}!`}</TrainingSubtitles>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¿Nos puedes indicar por qué medio te gustaría que nos pusieramos en
          contacto contigo?
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalWhatsapp(e);
            }}
          >
            <Images
              src={imgWhats}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            WhatsApp
          </span>
        </TrainingText>
        <hr />
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalEmail(e);
            }}
          >
            <Images
              src={imgMail}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            E-Mail
          </span>
        </TrainingText>
        <hr />
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalPhone(e);
            }}
          >
            <Images
              src={imgPhone}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            Teléfono
          </span>
        </TrainingText>
      </ModalDiv>

      <ModalDiv show={showWhatsapp} handleClose={handleCloseModalWhatsapp}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showWhatsapp} handleClose={handleCloseModalWhatsapp}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showEmail} handleClose={handleCloseModalEmail}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Dejános tu E-mail, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showPhone} handleClose={handleCloseModalPhone}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalRespConSinPremio
        show={sessionStorage.getItem('medium') === 'recompensas'}
        premio={sessionStorage.getItem('campaign') === 'con-premio'}
      />
    </div>
  );
};

export default ConAtrasoEnPagoDeuda;
