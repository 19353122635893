import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { Image } from '../../assets/styles/Home.styled';

import {
  Container1,
  ContentHomeSection,
  Shapes,
  Title2,
  Subtitle2,
} from '../../components/common/Home.styled';
import {
  Button,
  Container,
  Col6,
} from '../../components/common/common.styled';
import { getItemSessionStorage } from '../../Helpers/general';
import nu1 from '../../assets/img/lpNubank/NuCards.png';
import sombra from '../../assets/img/lpNubank/sombra.svg';
import nu2 from '../../assets/img/lpNubank/success2.svg';
import googleplay from '../../assets/img/lpNubank/googleplay.svg';
import appstore from '../../assets/img/lpNubank/appstore.svg';
import purpleI from '../../assets/img/lpNubank/successpurple.svg';
import WOlayout from '../../components/layouts/WOlayout';

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function SuccessNu({ location }) {
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  const email = getItemSessionStorage('Email1');
  const url = new URL(window.location.href);
  const errorMessage = url.searchParams.get('error');
  const hasError = errorMessage !== null;
  const userName = `${getItemSessionStorage(
    'FirstName',
  )} ${getItemSessionStorage('MiddleName')} ${getItemSessionStorage(
    'FathersLastName',
  )} ${getItemSessionStorage('MothersLastName')}`;

  const errorMessages = {
    title: [
      'Lo sentimos, no podemos ofrecerte una Tarjeta Nu',
      'Oh no... Hubo un error en tu trámite',
    ],
    description: [
      'Pero ya estás en nuestra lista y te contactaremos en cuanto podamos para apoyarte nuevamente en el trámite de tu tarjeta.',
      `No pudimos procesar tu solicitud porque [${errorMessage}], por favor vuelve a intentarlo más adelante.`,
    ],
  };
  const handleClick = () => window.location.replace('/the-training-feed/');
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);
  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      {hasError ? (
        <Container1>
          <Shapes
            id="Shapes2"
            key={2}
            top=" "
            left=" "
            heightD="800px"
            height="auto"
            heightD480="auto"
            width="100%"
            position=" "
            background="#FAFAFA"
          >
            <ContentHomeSection
              topMm320="85px"
              topDW="85px"
              leftD=" "
              floatD=" "
              displayD="none"
              displayM="block"
            >
              <Title2 sizem="28px" top="100px" topD="15%" className="roboto">
                {errorMessage.length ? errorMessages.title[1] : errorMessages.title[0]}
              </Title2>
              <Subtitle2 className="roboto" bottom="17px" top="10px">
                {errorMessage.length ? errorMessages.description[1] : errorMessages.description[0]}
              </Subtitle2>
              <div style={{ textAlign: 'center' }}>
                <Image
                  src={nu2}
                  maxwidth="430px"
                  width="90%"
                  position="relative"
                  left="0"
                  top="5%"
                  displayMobile=""
                />
              </div>
              <div style={{ margin: 'auto', width: '90%' }}>
                <Button
                  // mb="2.5rem"
                  className="d-block roboto"
                  width="90%"
                  mb="120px"
                  bg="#820AD1"
                  onClick={handleClick}
                >
                  Entendido
                </Button>
              </div>
            </ContentHomeSection>
            <div className="web" style={{ width: '100%' }}>
              <Container
                width="80%"
                mt="6%"
                widthMobile="100%"
                mb="0%"
                mtMobile="6%"
              >
                <Col6
                  className={heigthBrowser >= 550 ? '' : 'hidden'}
                  pt="0px"
                  flex="0 0 100%"
                  maxwidth="100%"
                >
                  <Image
                    src={nu2}
                    maxwidth="430px"
                    width="32%"
                    position="absolute"
                    left="0"
                    top="12%"
                    displayMobile="none"
                  />
                </Col6>
                <Col6 pt="0px" display="flex" displayM="none">
                  <hr
                    style={{
                      border: 'none',
                      color: '#DADADA',
                      borderLeft: '2px solid #DADADA',
                      height: '500px',
                      width: '2px',
                      marginRight: '20px',
                    }}
                  ></hr>
                  <div style={{ margin: 'auto' }}>
                  <Title2
                    sizeD="35px"
                    top={heigthBrowser >= 550 ? '85px' : '45px'}
                    topD="15%"
                    className="roboto"
                  >
                    {errorMessage.length ? errorMessages.title[1] : errorMessages.title[0]}
                  </Title2>
                  <Subtitle2 className="roboto" bottom="17px" top="10px">
                    {errorMessage.length ? errorMessages.description[1] : errorMessages.description[0]}
                  </Subtitle2>
                    <Button
                      className="d-block roboto"
                      bg="#820AD1"
                      mb="0"
                      onClick={handleClick}
                    >
                      Entendido
                    </Button>
                  </div>
                </Col6>
              </Container>
            </div>
          </Shapes>
        </Container1>
      ) : (
        <Container1>
          <Shapes
            id="Shapes2"
            key={2}
            top=" "
            left=" "
            heightD="auto"
            height="auto"
            heightD480="auto"
            width="100%"
            position=" "
            background="#FAFAFA"
          >
            <ContentHomeSection
              topMm320="85px"
              topDW="85px"
              leftD=" "
              floatD=" "
              displayD="none"
              displayM="block"
              style={{ backgroundColor: '#9800BB' }}
            >
              <Image
                src={purpleI}
                maxwidth="430px"
                width="100%"
                position="relative"
                left="auto"
                top="0"
                displayMobile="block"
              />
              <Title2
                color="#FFFFFF"
                sizem="28px"
                top={heigthBrowser >= 550 ? '30px' : '85px'}
                topD="15%"
                className="roboto"
              >
                ¡Felicidades!
              </Title2>
              <Subtitle2
                color="#FFFFFF"
                className="roboto"
                bottom="17px"
                top="10px"
              >
                {userName || '(Nombre)'}, aprobamos tu tarjeta de
                crédito. Enviamos un código de invitación al correo{' '}
                {email || 'test@test.com'}
              </Subtitle2>
              <Subtitle2
                color="#FFFFFF"
                className="roboto"
                height="auto"
                bottom="17px"
                top="10px"
              >
                Ahora, para terminar tu registro, necesitamos que nos des
                algunos datos adicionales.
              </Subtitle2>
              <Subtitle2
                color="#FFFFFF"
                size="12px"
                className="roboto"
                height="auto"
                bottom="17px"
                bottomD="25px"
                top="10px"
              >
                Descarga la app para continuar
              </Subtitle2>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingBottom: '120px',
                  marginLeft: '20px',
                }}
              >
                <a
                  style={{ cursor: 'pointer' }}
                  href="https://play.google.com/store/apps/details?id=com.nu.production&hl=es_MX"
                  target="_blank" rel="noreferrer"
                >
                  <Image
                    src={googleplay}
                    maxwidth="430px"
                    width="80%"
                    position="relative"
                    left="0"
                    top="0"
                    displayMobile="block"
                  />
                </a>
                <a
                  style={{ cursor: 'pointer' }}
                  href="https://apps.apple.com/mx/app/nu/id814456780"
                  target="_blank" rel="noreferrer"
                >
                  <Image
                    src={appstore}
                    maxwidth="430px"
                    width="80%"
                    position="relative"
                    left="0"
                    top="0"
                    displayMobile="block"
                  />
                </a>
              </div>
            </ContentHomeSection>
            <div className="web" style={{ width: '100%' }}>
              <Container
                width="80%"
                mt="0"
                widthMobile="100%"
                mb="0%"
                mtMobile="0"
              >
                <Col6
                  className={heigthBrowser >= 550 ? '' : 'hidden'}
                  pt="0px"
                  flex="0 0 100%"
                  maxwidth="100%"
                >
                  <Image
                    src={nu1}
                    maxwidth="430px"
                    width="30%"
                    position="absolute"
                    left="0"
                    top="12%"
                    displayMobile="none"
                  />
                  <Image
                    src={sombra}
                    maxwidth="430px"
                    width="77%"
                    position="relative"
                    left="0"
                    top="110%"
                    displayMobile="none"
                  />
                </Col6>
                <Col6
                  pt="0px"
                  display="flex"
                  displayM="none"
                  style={{ backgroundColor: '#9800BB', height: '800px' }}
                >
                  <div style={{ margin: 'auto', width: '80%' }}>
                    <Image
                      src={purpleI}
                      maxwidth="430px"
                      width="100%"
                      position="relative"
                      left="auto"
                      top="-15%"
                      displayMobile="none"
                    />
                    <Title2
                      color="#FFFFFF"
                      className="roboto"
                      sizeD="35px"
                      top={heigthBrowser >= 550 ? '85px' : '45px'}
                      topD="1%"
                    >
                      ¡Felicidades!
                    </Title2>
                    <Subtitle2
                      width="100%"
                      color="#FFFFFF"
                      className="roboto"
                      height="auto"
                      bottom="17px"
                      top="10px"
                    >
                      {userName || '(Nombre)'}, aprobamos tu tarjeta
                      de crédito. <br />
                      Enviamos un código de invitación al correo{' '}
                      {email || 'test@test.com'}
                    </Subtitle2>
                    <Subtitle2
                      width="100%"
                      color="#FFFFFF"
                      className="roboto"
                      height="auto"
                      bottom="17px"
                      top="10px"
                    >
                      Ahora, para terminar tu registro, necesitamos que nos des
                      algunos datos adicionales.
                    </Subtitle2>
                    <Subtitle2
                      width="100%"
                      color="#FFFFFF"
                      size="12px"
                      className="roboto"
                      height="auto"
                      bottom="17px"
                      bottomD="25px"
                      top="10px"
                    >
                      Descarga la app para continuar
                    </Subtitle2>
                    <div style={{ display: 'flex' }}>
                      <a
                        style={{ cursor: 'pointer' }}
                        href="https://play.google.com/store/apps/details?id=com.nu.production&hl=es_MX"
                        target="_blank"
                        style={{ marginRight: '40px' }} rel="noreferrer"
                      >
                        <Image
                          src={googleplay}
                          maxwidth="430px"
                          width="100%"
                          position="relative"
                          left="0"
                          top="0"
                          displayMobile="none"
                        />
                      </a>
                      <a
                        style={{ cursor: 'pointer' }}
                        href="https://apps.apple.com/mx/app/nu/id814456780"
                        target="_blank" rel="noreferrer"
                      >
                        <Image
                          src={appstore}
                          maxwidth="430px"
                          width="100%"
                          position="relative"
                          left="0"
                          top="0"
                          displayMobile="none"
                        />
                      </a>
                    </div>
                  </div>
                </Col6>
              </Container>
            </div>
          </Shapes>
        </Container1>
      )}
    </WOlayout>
  );
}

export default SuccessNu;
