/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingText,
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import '../../assets/styles/Home.css';
import editIcon from '../../assets/img/anacoach/editar1.png';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import { getProductInversion } from '../../Helpers/TableCalculateInversion';
import { Container, Col7 } from '../../components/common/common.styled';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';

const CalculadoraInversion = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setInversionProducts,
  trainingColor,
}) => {
  const [inversionObtenida, setInversionObtenida] = useState(
    sessionStorage.getItem('InversionObtenida') || '',
  );
  const [inversionCalculada, setInversionCalculada] = useState(0);
  const [productosObtenidos, setProductoObtenidos] = useState([]);
  const { sendEmail } = SendNotificationEmail();
  const { sendMessage } = SendNotificationFTAndCC();
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editRegistroInversion, setEditRegistroInversion] = useState('');
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const { actions } = ClickButton();
  const [form, setForm] = useState({
    MontoInversion: sessionStorage.getItem('MontoInversion') || '',
    PlazoInversion: sessionStorage.getItem('PlazoInversion') || '1',
    InversionObtenida: sessionStorage.getItem('InversionObtenida') || '',
  });
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = () => {};

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    setForm({ ...form, InversionObtenida: inversionCalculada });
    handleChangeQuestionsSend(3, 'Inversion', inversionCalculada);
  }, [inversionCalculada]);

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          let contVideos = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (questionType === 'Video') {
                contVideos++;
              }
            }

            if (
              arrayResponseRutine.length + contVideos
              < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        // console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      // console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
    sendEmail({ WO: 'FirstInv', trigger: 'alerta4_1er_inv' });
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone') || '',
      trigger: 'wa_inv_4',
    });
  }, []);
  const handleChangeInversion = (name, value) => {
    switch (name) {
      case 'MontoInversion':
        if (value !== '' && form.PlazoInversion !== '') {
          const products = getProductInversion(value, form.PlazoInversion);
          if (products.length > 0) {
            if (isNaN(products[0].resultado)) {
              setInversionCalculada(products[0].resultado);
              setProductoObtenidos([]);
            } else {
              setProductoObtenidos(products);
              setInversionCalculada(formatter.format(products[0].resultado));
            }
          }
        }
        break;
      case 'PlazoInversion':
        if (form.MontoInversion !== '' && value !== '') {
          const products = getProductInversion(form.MontoInversion, value);
          if (products.length > 0) {
            if (isNaN(products[0].resultado)) {
              setProductoObtenidos([]);
              setInversionCalculada(products[0].resultado);
            } else {
              setProductoObtenidos(products);
              setInversionCalculada(formatter.format(products[0].resultado));
            }
          }
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      let InversionObtenida = sessionStorage.getItem('InversionObtenida') || '';
      if (InversionObtenida === 'No disponible') {
        InversionObtenida = '';
      }
      setInversionObtenida(InversionObtenida);

      if (InversionObtenida !== '') {
        handleChangeInversion(
          'MontoInversion',
          sessionStorage.getItem('MontoInversion'),
        );
        handleChangeInversion(
          'PlazoInversion',
          sessionStorage.getItem('PlazoInversion'),
        );
      }
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    let InversionObtenida = sessionStorage.getItem('InversionObtenida') || '';
    if (InversionObtenida === 'No disponible') {
      InversionObtenida = '';
    }
    if (InversionObtenida !== '') {
      setInversionProducts(productosObtenidos);
    }
  }, [productosObtenidos]);

  const handleOpcInversion = (e) => {
    const click = `OpcionesParaInvertir_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    sendEmail({ WO: 'FirstInv', trigger: '1er_inv_alerta5' });
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone') || '',
      trigger: 'Notificación_FT_5_AUX',
    });
    if (productosObtenidos.length > 0) {
      setEditRegistroInversion(true);
      setInversionObtenida(form.InversionObtenida);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setInversionProducts(productosObtenidos);
      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
      gsap.to(window, { duration: 1, scrollTo: '#OpcionesInversion' });
    }
  };
  const handleChangeIcon = (json) => {
    setEditRegistroInversion(false);
    setInversionProducts('');
    setProductoObtenidos([]);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);
  const anchop = window.screen.width;
  const handleChange = (e) => {
    switch (e.target.name) {
      case 'MontoInversion':
        const value = e.target.value.replace('$', '').replace(/,/g, '').trim();
        setForm({
          ...form,
          [e.target.name]: formatter.format(filterFloat(value)),
        });

        handleChangeInversion(e.target.name, e.target.value);
        break;
      case 'PlazoInversion':
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
        handleChangeInversion(e.target.name, e.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {/* -----------
        First question
        -------------*/}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={inversionObtenida === '' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          (inversionObtenida === ''
            || (lapiz[0].routine === 4 && lapiz[0].active === 1))
          && editRegistroInversion !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <Fragment>
            <Container
              width="80%"
              mt="0"
              widthMobile="90%"
              mtMobile="0"
              mleft="18%"
              mlMobile="auto"
            >
              <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
                <TrainingDivSection
                  hew="auto"
                  className="topOne1"
                  paddw="26px"
                  mlw="0%"
                  mtw="8%"
                  wiw="100%"
                  back="#F3F3F3"
                  backw="#F3F3F3"
                  bshaw="none"
                  bsha=""
                  br="4px"
                  padding="20px"
                  wi="100%"
                  he="auto"
                >
                  <TrainingText
                    bot="0px"
                    className="cabin"
                    align="left"
                    weight="600"
                    size="20px"
                    sizew="20px"
                  >
                    Calcula tu inversión
                  </TrainingText>

                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    step="0-0"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />
                  <div className="selectParent">
                    <select
                      style={{
                        padding: '10px 12px',
                        marginBottom: '20px',
                        color: 'black !important',
                        fontSize: '16px',
                      }}
                      className="form-control"
                      name="PlazoInversion"
                      onBlur={(e) => handleChangeQuestionsSend(
                        '2',
                        'PlazoInversion',
                        e.target.value,
                        'OpenField',
                      )
                      }
                      onChange={handleChange}
                      value={form.PlazoInversion}
                    >
                      <option value="">Selecciona una opción</option>
                      <option value="Vista">A la vista (Un día)</option>
                      <option value="30 días">30 días</option>
                      <option value="90 días">90 días</option>
                      <option value="180 días">180 días</option>
                      <option value="1 año">1 año</option>
                      {/* <option value='2 años'>2 años</option> */}
                      <option value="3 años">3 años</option>
                      <option value="5 años">5 años</option>
                    </select>
                  </div>
                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    step="2-2"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                    disabled
                  />
                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    onClick={(e) => handleOpcInversion(e)}
                  >
                    Opciones para invertir
                  </CardsBtn>
                </TrainingDivSection>
              </Col7>
            </Container>
          </Fragment>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="OpcionesInversion"></div>
      <div>
        <img
          alt="editIcon"
          className={
            inversionObtenida !== ''
            && (editRegistroInversion === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
        />
        <CardTwo
          classCard={
            inversionObtenida !== ''
            && (editRegistroInversion === true
              || !(lapiz[0].routine === 4 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          textT={'Registros de inversión:'}
          text2={`MontoInversion: ${form.MontoInversion}`}
          text3={`PlazoInversion: ${form.PlazoInversion}`}
          text4={`InversionObtenida: ${form.InversionObtenida}`}
          colorTraining={trainingColor}
        ></CardTwo>
      </div>
    </>
  );
};

export default CalculadoraInversion;
