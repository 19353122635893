/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

const AntExpenseCategories = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  currentRoutine,
  nextOrderRoutine,
  possibleRoutines = [],
  handleServices,
}) => {
  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const anchop = window.screen.width;
  const [Rutine, setRutine] = useState({});
  const [countRecovery] = useState(0);
  const [antExpense, setAntExpense] = useState('');
  const [form, setForm] = useState({});
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }
              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                break;
              } else {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                // setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (recoveryRoutine === 'activas') {
      if (sessionStorage.getItem('CategoriasDeGastosH')) {
        const categoria = sessionStorage.getItem('CategoriasDeGastosH');
        setAntExpense(categoria);
        if (categoria === 'Servicios de entretenimiento') {
          setOrderRutine(possibleRoutines[0]);
          handleServices('ServicioEntretenimiento');
        } else if (categoria === 'Comida a domicilio') {
          setOrderRutine(possibleRoutines[1]);
          handleServices('Comida');
        } else if (categoria === 'Compras en línea') {
          setOrderRutine(possibleRoutines[2]);
          handleServices('Compras');
        } else if (categoria === 'Transporte') {
          setOrderRutine(possibleRoutines[3]);
          handleServices('Transporte');
        } else if (categoria === 'Postres o chatarra') {
          setOrderRutine(possibleRoutines[4]);
          handleServices('Postres');
        } else if (categoria === 'Registrar mis gastos') {
          setOrderRutine(possibleRoutines[5]);
          handleServices('RegistroGastos');
        }
      }
    }
  }, [recoveryRoutine]);

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setTimeout(() => setRecoveryRoutine('inactiva'), 11000);
    }
  }, []);

  const handleChangeRutine = (data) => {
    setRutine(data);
  };
  const handleChange = (e, question = '') => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setAntExpense(val.value);
        if (val.value === 'Servicios de entretenimiento') {
          setOrderRutine(possibleRoutines[0]);
          handleServices('ServicioEntretenimiento');
        } else if (val.value === 'Comida a domicilio') {
          setOrderRutine(possibleRoutines[1]);
          handleServices('Comida');
        } else if (val.value === 'Compras en línea') {
          setOrderRutine(possibleRoutines[2]);
          handleServices('Compras');
        } else if (val.value === 'Transporte') {
          setOrderRutine(possibleRoutines[3]);
          handleServices('Transporte');
        } else if (val.value === 'Postres o chatarra') {
          setOrderRutine(possibleRoutines[4]);
          handleServices('Postres');
        } else if (val.value === 'Registrar mis gastos') {
          setOrderRutine(possibleRoutines[5]);
          handleServices('RegistroGastos');
        }
        break;
      default:
        break;
    }
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && antExpense === ''
        && document.getElementById('activeExpenseCat') !== null
        && document.getElementById('activeExpenseCat') !== undefined
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeExpenseCat' });
        setReviewScroll(false);
      }
    }, 14000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeExpenseCat"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="Continuemos"
        />
        <QuestionsOrder
          classCard={'cardOne'}
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
        />
        <TrainingQuestion className={antExpense === '' ? '' : 'hidden'}>
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={antExpense !== '' ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={antExpense}
          colorTraining={trainingColor}
        />
      </div>
  );
};

export default AntExpenseCategories;
