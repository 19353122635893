/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery, useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import {
  CardsBtn,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

import { UPDATE_HISTORY_COLLECTION, CREATE_SESSION } from '../../mutations';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';
import Features from '../../components/Training/Features';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import ClickButton from '../../Helpers/HookHelpers';

const BudgetExplanation = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  const { actions } = ClickButton();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 0:
        break;
      default:
        // console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const videoAhorro = sessionStorage.getItem('VideoAhorrarPagardeudas');
      if (videoAhorro === 'true') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const [updateHistoryCollection, {}] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickVideoCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('idClickVideo', dynamicMongoSession.response);
        localStorage.setItem('idClickVideo', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const playVideo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickVideoYT',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickVideo')) {
      const dataJson = [
        {
          nameTraining: sessionStorage.getItem('trainingName'),
          idUser: sessionStorage.getItem('idUser'),
          action,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem('idClickVideo'),
      });
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          key: 'ClickVideo',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem('id_session'),
        ClickVideo: [
          {
            nameTraining: sessionStorage.getItem('trainingName'),
            idUser: sessionStorage.getItem('idUser'),
            action,
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'clickVideo',
          data: dataString,
        },
      };
      createClickVideoCollection({ variables: mongoInput });
    }
  };
  const viewVideoAhorrarPagardeudas = (e) => {
    e.preventDefault();
    sessionStorage.setItem('VideoAhorrarPagardeudas', true);
    setOrderRutine(nextOrderRoutine);
  };
  const viewVideoBaseVidaFinanciera = (e) => {
    const click = `ContinuarVideoVidaFinanciera_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    playVideo('Continue');
    sessionStorage.setItem('VideoBaseVidaFinanciera', true);
    viewVideoAhorrarPagardeudas(e);
  };

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#activeExplanation' });
    }, 4000);
  }, []);
  const anchop = window.screen.width;
  const feacturesArray = useState([
    {
      text: 'Ingresos: dinero que recibes',
      color: '#f5a838',
    },
    {
      text: 'Egresos: dinero que gastas',
      color: '#f5a838',
    },
    {
      text: 'Deudas: dinero que debes',
      color: '#f5a838',
    },
    {
      text: 'Ahorros: dinero que tienes guardado',
      color: '#f5a838',
    },
  ]);

  return (
    <>
      {!loaderWait || orderRutine !== 2 ? (
        <>
          <div id="activeExplanation"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text="¡A continuación te daré más detalles del entrenamiento!"
            hora={hora}
          />
          <Features
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title="Para hacer un presupuesto necesitas saber lo básico de tu vida financiera:"
            Arraytext={feacturesArray}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            text='Quizá no tienes dinero en alguna o varias de las categorías mencionadas. No te preocupes, ¡para eso es el presupuesto! Es una "radiografía" de tu situación actual que te dice en qué deberías poner atención para mejorar o salir de problemas financieros.'
            hora={hora}
          />
          <TrainingDivSection
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="80%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingDivSection
              mlw="10%"
              ml="0px"
              mr="0px"
              bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
              mt="10px"
              br="4px"
              padding="3%"
              wi="100%"
              wiw="75%"
              he="auto"
              hew="auto"
              mb="0px"
              mtw="20px"
            >
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
              <div className="center">
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtopw="19px"
                  mtop="19px"
                  onClick={(e) => {
                    viewVideoBaseVidaFinanciera(e);
                  }}
                >
                  Continuar
                </CardsBtn>
              </div>
            </TrainingDivSection>
          </TrainingDivSection>
          <SendNotificationVideo currentRoutine={currentRoutine} />
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="0rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </>
  );
};

export default BudgetExplanation;
