import React, { Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import AutocompleteAddress from '../AutocompleteAddress';

const Cc4Domicilio = () => {
  const history = useHistory();
  const [state, setState] = useState({
    timeHome: '',
    typeV: '',
  });
  const handleChange = (event) => {
    const { name } = event.target;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const guardar = () => {
    history.push('/coach/training/adquieretutarjetadecredito/twinfunnel/step5-datos-autenticación');
  };
  return (
        <Fragment>
            <div><AutocompleteAddress /></div>
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '200px',
            }}>
                <TextField style={{ margin: '10px', width: '30%' }} label="Colonia" variant="outlined" />
                <TextField style={{ margin: '10px', width: '30%' }} label="Número interior (Opcional)" variant="outlined" />
                <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }} >
                    <InputLabel>Tiempo de vivir en el domicilio</InputLabel>
                    <Select
                        native
                        value={state.timeHome}
                        onChange={handleChange}
                        label="Tiempo de vivir en el domicilio"
                        inputProps={{
                          name: 'timeHome',
                          id: 'outlined-age-native-simple',
                        }}
                    >
                        <option>Años</option>
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                        <option value={25}>25</option>
                        <option value={26}>26</option>
                        <option value={27}>27</option>
                        <option value={28}>28</option>
                        <option value={29}>29</option>
                        <option value={30}>30</option>
                        <option value={31}>31</option>
                        <option value={32}>32</option>
                        <option value={33}>33</option>
                        <option value={34}>34</option>
                        <option value={35}>35</option>
                        <option value={36}>36</option>
                        <option value={37}>37</option>
                        <option value={38}>38</option>
                        <option value={39}>39</option>
                        <option value={40}>40</option>
                        <option value={41}>41</option>
                        <option value={42}>42</option>
                        <option value={43}>43</option>
                        <option value={44}>44</option>
                        <option value={45}>45</option>
                        <option value={46}>46</option>
                        <option value={47}>47</option>
                        <option value={48}>48</option>
                        <option value={49}>49</option>
                        <option value={50}>50</option>
                        <option value={51}>51</option>
                        <option value={52}>52</option>
                        <option value={53}>53</option>
                        <option value={54}>54</option>
                        <option value={55}>55</option>
                        <option value={56}>56</option>
                        <option value={57}>57</option>
                        <option value={58}>58</option>
                        <option value={59}>59</option>
                        <option value={60}>60</option>
                        <option value={61}>61</option>
                        <option value={62}>62</option>
                        <option value={63}>63</option>
                        <option value={64}>64</option>
                        <option value={65}>65</option>
                        <option value={66}>66</option>
                        <option value={67}>67</option>
                        <option value={68}>68</option>
                        <option value={69}>69</option>
                        <option value={70}>70</option>
                        <option value={71}>71</option>
                        <option value={72}>72</option>
                        <option value={73}>73</option>
                        <option value={74}>74</option>
                        <option value={75}>75</option>
                        <option value={76}>76</option>
                        <option value={77}>77</option>
                        <option value={78}>78</option>
                        <option value={79}>79</option>
                        <option value={80}>80</option>
                        <option value={81}>81</option>
                        <option value={82}>82</option>
                        <option value={83}>83</option>
                        <option value={84}>84</option>
                        <option value={85}>85</option>
                        <option value={86}>86</option>
                        <option value={87}>87</option>
                        <option value={88}>88</option>
                        <option value={89}>89</option>
                        <option value={90}>90</option>
                        <option value={91}>91</option>
                        <option value={92}>92</option>
                        <option value={93}>93</option>
                        <option value={94}>94</option>
                        <option value={95}>95</option>
                        <option value={96}>96</option>
                        <option value={97}>97</option>
                        <option value={98}>98</option>
                        <option value={99}>99</option>
                    </Select>
                </FormControl>
                <TextField id="firstN" style={{ margin: '10px', width: '30%' }} label="Télefono de casa (Opcional)" variant="outlined" />
                <FormControl variant="outlined" style={{ margin: '10px', width: '30%' }} >
                    <InputLabel>Tipo de Vivienda</InputLabel>
                    <Select
                        native
                        value={state.typeV}
                        onChange={handleChange}
                        label="Selecciona tu ingreso"
                        inputProps={{
                          name: 'typeV',
                          id: 'outlined-typeV-native-simple',
                        }}
                    >
                        <option>Elige una opción</option>
                        <option value="Piso">Piso</option>
                        <option value="Ático">Ático</option>
                        <option value="Casa">Casa</option>
                        <option value="Estudio">Estudio</option>
                        <option value="Loft">Loft</option>
                        <option value="Dúplex">Dúplex</option>
                    </Select>
                </FormControl>
                <Button style={{ margin: '10px', marginBottom: '200px', width: '30%' }} onClick={guardar} variant="contained" color="secondary">
                    Continuar
                    </Button>
            </div>
        </Fragment>
  );
};

export default Cc4Domicilio;
