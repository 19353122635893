import React, { Fragment } from 'react';
import {
  TrainingMain,
  DivFlex,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
  BorderTask,
  StatusTask,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import { Feed_Main } from '../../assets/styles/Feed.styled';
import TasksStatus from './WeekTask';

import CardOne from '../Home/Card1Animate';

function ResumenSemanal({
  data, name, reward, comment, handleChange, requestRewards,
}) {
  return (
        <Fragment>
            <TrainingMain mt="50px" mtw="0" className="" id="top">
                <br />
                <TrainingSectionGray back="#fafafa">
                    <Feed_Main width="100%" background=" " >
                        <Feed_Main width="80%" display=" " mtop=" " background="transparent">
                            <TrainingDivSection2 paddiw="0% 10% 3% 10%" paddi="0% 5% 5% 5%" back="#fafafa">
                                <CardOne
                                    classCard="cardOne"
                                    classTop="topOne1"
                                    text={`${name || 'Gael'}, ya puedes reclamar las recompensas obtenidad durante la semana:`}
                                />
                                <TrainingText top="35px" size="18px" weight="600" className="roboto" align="center" alignweb="center" ls="-0.2px">
                                    Resumen semanal
                            </TrainingText>
                                <DivFlex widthweb="70%" marweb="auto">
                                    <div>
                                        <TrainingText weight="600">Tarea</TrainingText>
                                    </div>
                                    <div>
                                        <TrainingText weight="600">Recompensa</TrainingText>
                                    </div>
                                </DivFlex>
                                <TasksStatus datos={data} comment={comment} handleChange={handleChange} ></TasksStatus>
                                <DivFlex widthweb="70%" marweb="auto" style={{ backgroundColor: '#FFFFFF' }}>
                                    <BorderTask bs="none">
                                        <TrainingText size="18px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                            TOTAL RECOMPENSAS
                                    </TrainingText>
                                    </BorderTask>
                                    <StatusTask fw="800" Color="#FA9D59" bColor="#FA9D59" >
                                        ${reward}
                                    </StatusTask>
                                </DivFlex>
                            </TrainingDivSection2>
                        </Feed_Main>
                    </Feed_Main>
                </TrainingSectionGray>
            </TrainingMain>
            <CardsBtn min_w="90%" min_web="50%" mleft="5%" mleftmd="5%" mleftweb="25%" mright="5%" mrightweb="25%" onClick={(e) => requestRewards(e)}>Solicitar recompensas</CardsBtn>
            <br />
            <br />
        </Fragment>
  );
}
export default ResumenSemanal;
