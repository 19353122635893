/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ImageSections,
  TrainingText,
  DivStyle,
  DivFlex,
  TrainingBenef,
  Images,
  Resultado,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import check from '../../assets/img/anacoach/mdi_check_box.png';
import enproceso from '../../assets/img/anacoach/faces-02.png';
import ClickButton from '../../Helpers/HookHelpers';
import {
  DYNAMIC_GET,
  GET_CURP,
  UPDATE_CLICKOUT_OPPORTUNITY,
  SEND_STORI_MAIL
} from '../../mutations';
import { GET_PRODUCTS_BY_ID } from '../../queries';
import ProductsHooks from '../../Helpers/HookHelpersProducts';
import ModalStori from '../common/ModalStori';

function Resultados(props) {
  const { sendApi } = ProductsHooks();
  const { actions } = ClickButton();
  const [goFeed, setGoFeed] = useState(false);
  const [tarjetaNoAmerican, setTarjetaNoAmerican] = useState(true); 
  const [resultPrducts, setResultPrducts] = useState([]);
  const [cardsReqDone, setCardsReqDone] = useState([]);
  const [cardsReqUndone, setCardsReqUndone] = useState([]);
  const [modalStori, setModalStori] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
      const click = `ElegirCredito_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [goFeed]);

  const [getProductsByIdProduct, { loading }] = useLazyQuery(GET_PRODUCTS_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsByIdProduct }) {
      let detailsProducts = sessionStorage.getItem('SelectedProductDetail');debugger
      detailsProducts = detailsProducts.split(',');
      const dataJson = JSON.parse(getProductsByIdProduct.response);
      // const detailsProduct = JSON.parse(dataJson[0].productDetail.response)
      try {
        setResultPrducts(dataJson.map(({ IDProduct, ImageURL, BusinessAbr, DescriptionProduct, productDetail}) => {        
          const CAT = JSON.parse(productDetail.response).filter(({ Name }) => Name === 'CAT');
          const IVA = JSON.parse(productDetail.response).filter(({ Name }) => Name === 'Interés anual');
          return {
            id: IDProduct,
            imgCard: ImageURL,
            titleCard: BusinessAbr,
            imgresult: enproceso,
            IVA: IVA.length > 0 ? (IVA[0].Description * 100).toFixed(2) : null,
            CAT: CAT.length > 0 ? CAT : null,
            DescriptionProduct,
            resultado: 'Solicitada',
            msgresultado: '',
            info: 'No. Folio 12345678',
            txtadicional:
              'La institución realizará el análisis de tu perfil y te notificará si fue aprobado tu crédito. Debes estar pendiente de tu correo y teléfono registrado.',
            documentos: '',
          }
          
        })) 
      } catch (error) {
        console.error(error)
      }   
      if (dataJson[0].hasApi) {
        //! se realiza consulta a la api correspondiente si la api para el producto esta activada
        sendApi({ action: parseInt(detailsProducts[0], 10), variables: '' });
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateClickOutOpportunity] = useMutation(UPDATE_CLICKOUT_OPPORTUNITY, {
    onCompleted({ updateClickOutOpportunity }) {
      if (updateClickOutOpportunity.message === 'success') {
        console.log("");
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createCURP] = useMutation(GET_CURP, {
    onCompleted({ createCURP }) {
      if (createCURP.statusCode === 200) {
        sessionStorage.setItem('curp', createCURP.response);
        let detailsProducts = sessionStorage.getItem('SelectedProductDetail');
        if (detailsProducts !== null) {
          detailsProducts = detailsProducts.split(',');
          getProductsByIdProduct({
            variables: {
              idProduct: detailsProducts[0],
            },
          });
        }
      }
    },
  });

  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        let userDetail;
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach((element) => {
          switch (element.IDType) {
            case 3:
              sessionStorage.setItem('levelStudy', element.Value);
              break;
            case 4:
              sessionStorage.setItem('occupation', element.Value);
              break;
            case 5:
              sessionStorage.setItem('TipoDeVivienda', element.Value);
              break;
            case 6:
              sessionStorage.setItem('dependents', element.Value);
              break;
            case 20:
              sessionStorage.setItem('entry', element.Value);
              break;
            case 45:
              sessionStorage.setItem('curp', element.Value);
              break;
            case 69:
              sessionStorage.setItem('expiration', element.Value);
              break;
            case 135:
              sessionStorage.setItem('ine', element.Value);
              break;
            default:
              break;
          }
        });
        let detailsProducts = sessionStorage.getItem('SelectedProductDetail');
        if (detailsProducts !== null) {
          detailsProducts = detailsProducts.split(',');
          getProductsByIdProduct({
            variables: {
              idProduct: detailsProducts[0],
            },
          });
        }
      }
    },
  });

  const [sendStoriMail] = useMutation(SEND_STORI_MAIL, {
    onCompleted({ sendStoriMail }) {
      if (sendStoriMail.statusCode === 200) { }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem('idUser')) {
      const dynamicGetInput = {
        input: {
          table: 'CatUserDetails',
          columns: ['*'],
          conditions: [
            {
              valone: 'CatUsers_IDUser',
              condition: '=',
              valtwo: sessionStorage.getItem('idUser'),
            },
          ],
        },
      };
      getUserDetail({ variables: dynamicGetInput });
    } else {
      const birthDatestr = sessionStorage.getItem('Birthdate').split('-');
      const inputCURP = {
        input: {
          nombre: `${sessionStorage.getItem(
            'FirstName',
          )} ${sessionStorage.getItem('MiddleName')}`,
          apellido_paterno: sessionStorage.getItem('FathersLastName'),
          apellido_materno: sessionStorage.getItem('MothersLastName'),
          genero: sessionStorage.getItem('Gender'),
          estado: sessionStorage.getItem('BirthState'),
          fecha_nacimiento: sessionStorage.getItem('Birthdate')
            ? `${birthDatestr[2]}/${birthDatestr[1]}/${birthDatestr[0]}`
            : '',
        },
      };
      createCURP({ variables: inputCURP });
    }
  }, []);

  const abrirNuevoTab = (url, id=0) => {
    // Abrir nuevo tab
    const idusernu = sessionStorage.getItem('idUser') || 0;
    if (id === 290) {
      setModalStori(true);
      const dataJson = {
        email: sessionStorage.getItem('Email1'),
      };
      const input = {
        input: {
          idUser: sessionStorage.getItem('idUser'),
          dataJson: btoa(JSON.stringify(dataJson)),
        },
      };
      sendStoriMail({ variables: input });
    }
    if(sessionStorage.getItem('datOpportunity')){
      let idOpportunity = sessionStorage.getItem('datOpportunity')
      if(id == 19){
        let i = 0
        for(let elem of sessionStorage.getItem('datProducts').split(',')){
          if(elem == 19) idOpportunity = sessionStorage.getItem('datOpportunity').split(',')[i]
          i++
        }
      }
      url = url.replace(
        '[idOpportunity]',
        idOpportunity
      );  
      url = url.replace(
        '[IDUser]',
        sessionStorage.getItem('idUser'),
      );
      // Actualizar Clickout
      updateClickOutOpportunity({
        variables: {
          opportunities: idOpportunity,
          idProduct: id,
        },
      });
      
    }
    if (id === 290) {
      const timer = setTimeout(() => {
        const win = window.open(url, '_blank');
        // Cambiar el foco al nuevo tab (punto opcional)
        win.focus();
      }, 600);
      return () => clearTimeout(timer);
    } else {
      if (id === 564) {
      const newurl =  url.replace('[IDUser]',idusernu);
      const win = window.open(newurl, '_blank');
        win.focus();
      } else {
        const win = window.open(url, '_blank');
        // Cambiar el foco al nuevo tab (punto opcional)
        win.focus();
      }
    }
  };

  const finishRequest = "Terminar solicitud";
  
  useEffect(() => {
    setCardsReqDone(
      props.resultados.map(el => {
        if(el.id === 6) {
          return el
        }
      }).filter(el => el !== undefined)
    )

    setCardsReqUndone(
      props.resultados.filter(el => el.id !== 6)
    )

    
  }, [props])


  const content = cardsReqDone.map((post) => (
  // const content = loading ? null : resultPrducts.map((post) => (
    <>
      {post.id === 6
        ? <>
            <Resultado
              MinWidth="300px"
              wid="300px"
              marginGene="0 0 0 0"
              widthLg="65%"
              marginweb={
                window.location.href.includes('/first-card/success')
                  ? 'auto'
                  : '0 0 0 0'
              }
              marTopD="10px"
              mbot="10px"
              ta="left"
              padd="20px"
              className="dm-sans"
              minh="215px"
              minhweb="215px"
              maxwidthLg="300px"
            >
              <DivFlex justify="space-between" >
                <div>
                  <ImageSections
                    width="70px"
                    widthweb="70px"
                    src={post.imgCard}
                    className="img-fluid"
                  />
                  <ImageSections
                    width="40px"
                    heightweb=" "
                    src={post.imgresult}
                    className="img-fluid hidden"
                  />
                    <br />
                  <DivStyle
                    align="left"
                    weight="bold"
                    mt="20px"
                    bott="10px"
                    className="cabin"
                    size="18px"
                    line="100%"
                  >
                    {post.titleCard}
                  </DivStyle>
                </div>
                <div>
                  <TrainingText className="roboto" align="right" weight="300" size=".8em" lh="1">
                    Tasa de Interés {post.hasClickOut}
                  </TrainingText>
                  <TrainingText
                    className="roboto"
                    weight="600"
                    trans="uppercase"
                    align="right"
                    lh="1"
                  >
                    {post.IVA === '-' ? '0' : post.IVA}%
                  </TrainingText>
                  {post.CAT && (
                    <>
                      <TrainingText className="roboto" align="right" weight="300" size=".8em" lh="1">
                        CAT
                      </TrainingText>
                      <TrainingText
                        className="roboto"
                        weight="600"
                        trans="uppercase"
                        align="right"
                        lh="1"
                      >
                        {post.CAT}%
                      </TrainingText>
                    </>
                  )}
                </div>
              </DivFlex>
              <TrainingText
                className="roboto"
                weight="400"
                align="left"
                mheight="58px"
              >
                {post.id === 6 ? post.txtadicional : post.DescriptionProduct}
              </TrainingText>
              <TrainingText className="roboto hidden" weight="600" align="left">
                ¿Qué sigue?
              </TrainingText>
              {post.id !== 297 ? (
                <>
                  <TrainingText className="roboto" color="#fb245c" align="left">
                    {post.documentos}
                  </TrainingText>  
                </>
              ) : (
                ''
              )}
              {post.id === 297 ? (
                <>
                  <CardsBtn
                    back="#FFF"
                    color="#F64282"
                    border="solid"
                    bordcolor="#F64282"
                    type="button"
                    min_w="100%"
                    onClick={() => abrirNuevoTab(post.ClickOut, post.id)}
                  >
                    {finishRequest}
                  </CardsBtn>
                </>
              ) : null}
              {post.hasClickOut && post.id !== 297 && post.id !== 6 ? (
                <CardsBtn
                  back="#FFF"
                  color="#F64282"
                  border="solid"
                  bordcolor="#F64282"
                  type="button"
                  min_w="100%"
                  onClick={() => abrirNuevoTab(post.id === 311 ? `${post.ClickOut}&iDCoru=C2-${sessionStorage.getItem('idUser')}` : post.ClickOut, post.id)}
                >
                  {post.id === 311 || props.idTraining == '3' ? finishRequest : 'Solicitar'}
                </CardsBtn>
              ) : null}

              {post.id === 440 || post.id === '440'? (
                <CardsBtn
                  back="#FFF"
                  color="#F64282"
                  border="solid"
                  bordcolor="#F64282"
                  type="button"
                  min_w="100%"
                  onClick={() => abrirNuevoTab(sessionStorage.getItem('urlBillete'), post.id)}
                >
                  Solicitar
                </CardsBtn>
              ) : null}

              {post.resultado === 'RECHAZADA' ? (
                <TrainingText
                  className="roboto"
                  align="center"
                  size="12px"
                  bot="0"
                  onClick={() => setGoFeed(true)}
                  color="#fb245c"
                  cursor="pointer"
                >
                  Conoce más...
                </TrainingText>
              ) : null}
            </Resultado>
          </>
        : null}
    </>
  ));
  const contentP2 = cardsReqUndone.map((post) => (
    <>
      {post.id !== 6
        ? <>
            <Resultado
              MinWidth="300px"
              wid="300px"
              marginGene="0 0 0 0"
              widthLg="65%"
              marginweb={
                window.location.href.includes('/first-card/success')
                  ? 'auto'
                  : '0 0 0 0'
              }
              marTopD="10px"
              mbot="10px"
              ta="left"
              padd="20px"
              className="dm-sans"
              minh="215px"
              minhweb="215px"
              maxwidthLg="300px"
            >
              <DivFlex justify="space-between" >
                <div>
                  <ImageSections
                    width="70px"
                    widthweb="70px"
                    src={post.imgCard}
                    className="img-fluid"
                  />
                  <ImageSections
                    width="40px"
                    heightweb=" "
                    src={post.imgresult}
                    className="img-fluid hidden"
                  />
                    <br />
                  <DivStyle
                    align="left"
                    weight="bold"
                    mt="20px"
                    bott="10px"
                    className="cabin"
                    size="18px"
                    line="100%"
                  >
                    {post.titleCard}
                  </DivStyle>
                </div>
                <div>
                  <TrainingText className="roboto" align="right" weight="300" size=".8em" lh="1">
                    Tasa de Interés {post.hasClickOut}
                  </TrainingText>
                  <TrainingText
                    className="roboto"
                    weight="600"
                    trans="uppercase"
                    align="right"
                    lh="1"
                  >
                    {post.IVA === '-' ? '0' : post.IVA}%
                  </TrainingText>
                  {post.CAT && (
                    <>
                      <TrainingText className="roboto" align="right" weight="300" size=".8em" lh="1">
                        CAT
                      </TrainingText>
                      <TrainingText
                        className="roboto"
                        weight="600"
                        trans="uppercase"
                        align="right"
                        lh="1"
                      >
                        {post.CAT}%
                      </TrainingText>
                    </>
                  )}
                </div>
              </DivFlex>
              <TrainingText
                className="roboto"
                weight="400"
                align="left"
                mheight="58px"
              >
                {post.id === 6 ? post.txtadicional : post.DescriptionProduct}
              </TrainingText>
              <TrainingText className="roboto hidden" weight="600" align="left">
                ¿Qué sigue?
              </TrainingText>
              {post.id !== 297 ? (
                <>
                  <TrainingText className="roboto" color="#fb245c" align="left">
                    {post.documentos}
                  </TrainingText>  
                </>
              ) : (
                ''
              )}
              {post.id === 297 ? (
                <>
                  <CardsBtn
                    back="#FFF"
                    color="#F64282"
                    border="solid"
                    bordcolor="#F64282"
                    type="button"
                    min_w="100%"
                    onClick={() => abrirNuevoTab(post.ClickOut, post.id)}
                  >
                    {finishRequest}
                  </CardsBtn>
                </>
              ) : null}
              {post.hasClickOut && post.id !== 297 && post.id !== 6 ? (
                <CardsBtn
                  back="#FFF"
                  color="#F64282"
                  border="solid"
                  bordcolor="#F64282"
                  type="button"
                  min_w="100%"
                  onClick={() => abrirNuevoTab(post.id === 311 ? `${post.ClickOut}&iDCoru=C2-${sessionStorage.getItem('idUser')}` : post.ClickOut, post.id)}
                >
                  {post.id === 311 || props.idTraining == '3' ? finishRequest : 'Solicitar'}
                </CardsBtn>
              ) : null}

              {post.id === 440 || post.id === '440'? (
                <CardsBtn
                  back="#FFF"
                  color="#F64282"
                  border="solid"
                  bordcolor="#F64282"
                  type="button"
                  min_w="100%"
                  onClick={() => abrirNuevoTab(sessionStorage.getItem('urlBillete'), post.id)}
                >
                  Solicitar
                </CardsBtn>
              ) : null}

              {post.resultado === 'RECHAZADA' ? (
                <TrainingText
                  className=""
                  align="center"
                  size="12px"
                  bot="0"
                  onClick={() => setGoFeed(true)}
                  color="#fb245c"
                  cursor="pointer"
                >
                  Conoce más...
                </TrainingText>
              ) : null}
              {post.id === 290 && modalStori ? <ModalStori /> : null}
            </Resultado>
          </>
        : null}
    </>
  )); 
  return ( 
    <>
      <div className="marginAutoCC" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {cardsReqDone.length !== 0 ?
          <DivStyle
            align="center"
            weight="bold"
            mt="22px"
            bott="15px"
            className="cabin"
            size="20px"
            line="120%"
          >
            Tu solicitud ¡Ya fue enviada! 🚀
          </DivStyle>
          : null
        }
        {content}
        {cardsReqUndone.length !== 0 ? 
          <DivStyle
            align="center"
            weight="bold"
            mt="22px"
            bott="15px"
            className="cabin"
            size="20px"
            line="120%"
          >
            ¡Ya casi tienes tu tarjeta de crédito! 💳 Termina tu solicitud:               
          </DivStyle>
          : null
          }
        {contentP2}
      </div>
    </>
  );
}

export default Resultados;
