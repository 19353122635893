import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Col7, Container } from '../common/common.styled';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import '../../assets/styles/Card1Animate.css';

const CardComponents = ({
  Arraytext,
  classCard,
  classTop,
  text,
  text2,
  text3,
  textbold,
  textbold2,
  ClassTxt,
  onClick = null,
  widthcontentw,
  children,
  logros = false,
  classAna,
}) => (
  <Container
    width={
      widthcontentw !== undefined ? widthcontentw : logros ? '100%' : '80%'
    }
    mt="0"
    widthMobile="90%"
    mtMobile="0"
    displayweb={
      classCard === 'cardOne' || classCard === 'cardOne cardOneMargin'
        ? 'flex'
        : 'none'
    }
    displaymobile={classCard === 'cardOne' ? 'flex' : 'none'}
  >
    {classAna === 'falseAna' ? null : <ImageCoachInicio topMobile="27px" />}
    <Col7 maxwidth="100%" wiM="100%" padding=" " className="cardPrincipal1">
      <div className={`${classCard} ${classTop}`} onClick={onClick}>
        <div className={`${ClassTxt}`}>
          {text !== '' ? <p>{text}</p> : null}
          {textbold !== '' ? <p className="bold-p">{textbold}</p> : null}
          {text2 !== '' ? <p>{text2}</p> : null}
          {textbold2 !== '' ? <p className="bold-p">{textbold2}</p> : null}
          {text3 !== '' ? <p>{text3}</p> : null}
          {Arraytext.map((feacture, index) => (
            <p key={`bull${index}`}>
              <CheckBoxIcon
                style={{
                  color: feacture.color,
                  fontSize: 23,
                  margin: '0 10px 4px 0',
                }}
              />
              {feacture.text}
            </p>
          ))}
          {children}
        </div>
      </div>
    </Col7>
  </Container>
);

export default CardComponents;
