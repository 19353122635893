import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import '../../assets/styles/loginAdmin/_form.css';
import '../../assets/styles/loginAdmin/_general.css';
import '../../assets/styles/loginAdmin/authentication.css';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LOGIN } from '../../mutations';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -22,
    marginLeft: -12,
  },
}));

function LogInAsesores() {
  const classes = useStyles();
  const [form, setForm] = useState({
    email: '',
    password: '',
    typeUser: 'asesor',
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });
  const [texterror, settexterror] = useState({
    email: '',
    password: '',
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const eBtn = () => {
    if ((form.email.length !== 0 && !error.email)
            && (form.password.length !== 0 && !error.password)) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  };
  const ValidEmail = () => {
    if (form.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
    } else {
      texterror.email = '';
      error.email = false;
      setbuttonDisabled(true);
    }
    eBtn();
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      if(login.token === 'Rol no autorizado' ){
        texterror.email = 'Rol no autorizado';
        setbuttonDisabled(false);
        setLoading(false);
        return;
      }
      if (login.token !== 'El usuario no existe' && login.token !== 'Contraseña incorrecta') {
        window.location.href = '/advisers-home';

        sessionStorage.setItem('token', login.token);
        sessionStorage.setItem('FirstName', login.firstName);
        sessionStorage.setItem('MiddleName', login.middleName);
        sessionStorage.setItem('FathersLastName', login.fathersLastName);
        sessionStorage.setItem('MothersLastName', login.mothersLastName);
        sessionStorage.setItem('idUser', login.idUser);
        sessionStorage.setItem('idCont', login.idCont);
        sessionStorage.setItem('ProfilePicture', login.ProfilePicture);
        sessionStorage.setItem('type', login.type);
        sessionStorage.setItem('DataPerson', true);

        localStorage.setItem('token', login.token);
        localStorage.setItem('FirstName', login.firstName);
        localStorage.setItem('MiddleName', login.middleName);
        localStorage.setItem('FathersLastName', login.fathersLastName);
        localStorage.setItem('MothersLastName', login.mothersLastName);
        localStorage.setItem('idUser', login.idUser);
        localStorage.setItem('idCont', login.idCont);
        localStorage.setItem('ProfilePicture', login.ProfilePicture);
      } else {
        const field = login.token === 'El usuario no existe' ? 'email' : 'password';
        setError({
          ...error,
          email: false,
          password: false,
          [field]: true,
        });

        settexterror({
          ...texterror,
          email: '',
          password: '',
          [field]: login.token,
        });
      }
      setbuttonDisabled(false);
      setLoading(false);
    },
  });
  const send = (e) => {
    e.preventDefault();
    try {
      setbuttonDisabled(true);
      setLoading(true);
      login({ variables: { input: form } });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    ValidEmail();
    eBtn();
  }, [form]);
  return (
        <Fragment>
            <div className="auth-wrapper">
                <div className="auth-content">
                    <div className="auth-bg">
                        <span className="r" />
                        <span className="r s" />
                        <span className="r s" />
                        <span className="r" />
                    </div>
                    <form autoComplete="off" onSubmit={(e) => send(e)}>
                        <div className="card" style={{ marginTop: 0 }}>
                            <div className="card-body">
                                <h3 className="mb-4 text-center">Iniciar Sesión</h3>
                                <div className={classes.wrapper}>
                                    <TextField
                                        type="email"
                                        id="email"
                                        name="email"
                                        error={error.email}
                                        value={form.email}
                                        required
                                        label="Correo electrónico"
                                        variant="outlined"
                                        onChange={handleChange}
                                        helperText={texterror.email}
                                        fullWidth />
                                </div>
                                <div className={classes.wrapper}>
                                    <TextField
                                        type="password"
                                        id="password"
                                        name="password"
                                        error={error.password}
                                        value={form.password}
                                        required
                                        label="Contraseña"
                                        variant="outlined"
                                        onChange={handleChange}
                                        helperText={texterror.password}
                                        fullWidth />
                                </div>
                                <div className={classes.wrapper}>

                                    <CardActions style={{ justifyContent: 'center' }}>
                                        <div className={classes.wrapper}>
                                            <Button type="submit" variant="contained" color="primary" className="shadow-2 mb-4" disabled={buttonDisabled}>
                                                Continuar
                                            </Button>
                                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>
                                    </CardActions>
                                    <p className="mb-2 text-muted">Por el momento, este acceso es únicamente para usuarios asesores</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
  );
}

export default LogInAsesores;
