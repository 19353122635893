import React, { Fragment, useState, useEffect } from 'react';
import '../../assets/styles/perfil/perfiles.css';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation } from '@apollo/client';

// Modal
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { TextPerfil } from '../../assets/styles/pantInicio.styled';
import { UPDATE_USERS } from '../../mutations';
import {
  Container,
  Col12,
  Col6,
  SubtitleOne,
  Button,
  ParagrapTwo,
} from '../common/common.styled';
import GetAddressByCp from '../../anaCoach/Training/GetAddressByCp';
import DatosAddress from './DatosAddress';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

// END MODAL

function FormsAddress({
  number, mt, check, Updatedefault, handleClickDelete,
}) {
  const { google } = window;
  const [More, setMore] = useState('Mostrar menos');
  const [keyboardStatus, setKeyboardStatus] = useState(true);
  const [autocompleteForm, setAutocompleteForm] = useState({});
  const [ZipCode, setCP] = useState(sessionStorage.getItem('ZipCode') || '');

  const [saveAddress] = useMutation(UPDATE_USERS);

  const GetDataAdres = (tipo, data) => {
    switch (tipo) {
      case 'Municipality':
        SetFormAddress({
          ...formAddress,
          municipio: data,
        });
        sessionStorage.setItem('Municipality', data);
        console.log(data);
        console.log(formAddress);

        break;
      case 'Suburb':
        debugger;
        SetFormAddress({
          ...formAddress,
          suburb: data,
        });
        sessionStorage.setItem('Suburb', data);
        console.log(data);
        break;
      case 'estado':
        SetFormAddress({
          ...formAddress,
          estado: data,
          suburb: `${sessionStorage.setItem('Suburb')} `,
        });
        sessionStorage.setItem('State', data);
        console.log(data);
        SetFormAddress({
          ...formAddress,
          estado: data,
          suburb: `${sessionStorage.setItem('Suburb')} `,
        });
        break;
      default:
        break;
    }
  };

  const guardarAddress = async (e) => {
    e.preventDefault();
    sessionStorage.setItem('AddressType', formAddress.typeDom);
    sessionStorage.setItem('address', formAddress.address);
    sessionStorage.setItem('Street', formAddress.Street);
    sessionStorage.setItem('ExtNo', formAddress.outdoor);
    sessionStorage.setItem('IntNo', formAddress.interior);
    sessionStorage.setItem('ZipCode', formAddress.postalCode);
    sessionStorage.setItem('Suburb', formAddress.suburb);
    sessionStorage.setItem('Municipality', formAddress.municipio);
    sessionStorage.setItem('State', formAddress.estado);

    await saveAddress({
      variables: {
        input: {
          table: 'CatAddress',
          columns: [
            {
              setkey: 'AddressType',
              setval: formAddress.typeDom,
            },
            {
              setkey: 'Street',
              setval: formAddress.Street,
            },
            {
              setkey: 'ZipCode',
              setval: formAddress.postalCode,
            },
            {
              setkey: 'ExtNo',
              setval: formAddress.outdoor,
            },
            {
              setkey: 'IntNo',
              setval: formAddress.interior,
            },
            {
              setkey: 'Suburb',
              setval: formAddress.suburb,
            },
            {
              setkey: 'Municipality',
              setval: formAddress.municipio,
            },
            {
              setkey: 'State',
              setval: formAddress.estado,
            },
          ],
          conditions: {
            valone: 'CatContact_IDCont',
            valtwo: sessionStorage.getItem('idCont'),
            condition: '=',
          },
        },
      },
    });

    await saveAddress({
      variables: {
        input: {
          table: 'CatUserDetails',
          columns: [
            {
              setkey: 'Value',
              setval: formAddress.period.toString(),
            },
          ],
          conditions: [
            {
              valone: 'CatUsers_IDUser',
              valtwo: sessionStorage.getItem('idUser'),
              condition: '=',
            },
            {
              logic: 'AND',
              valone: 'IDType',
              valtwo: '10',
              condition: '=',
            },
          ],
        },
      },
    });

    handleOpen();
  };

  const [formAddress, SetFormAddress] = useState({
    typeDom: sessionStorage.getItem('AddressType') || '',
    address: sessionStorage.getItem('address') || '',
    Street: sessionStorage.getItem('Street') || '',
    outdoor: sessionStorage.getItem('ExtNo') || '',
    interior: sessionStorage.getItem('IntNo') || '',
    postalCode: sessionStorage.getItem('ZipCode'),
    suburb: sessionStorage.getItem('Suburb') || '',
    municipio: sessionStorage.getItem('Municipality') || '',
    estado: sessionStorage.getItem('State'),
    period: sessionStorage.getItem('AddressPeriod'),
    check,
  });

  const Periods = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
  ];
  const [errorAddress] = useState({
    typeDom: false,
    address: false,
    Street: false,
    outdoor: false,
    interior: false,
    postalCode: false,
    suburb: false,
    municipio: false,
    estado: false,
    housingType: false,
    period: false,
    typeDomValid: sessionStorage.getItem('AddressType') === null ? -1 : 0,
    addressValid: sessionStorage.getItem('address') === null ? -1 : 0,
    StreetValid: sessionStorage.getItem('Street') === null ? -1 : 0,
    outdoorValid: sessionStorage.getItem('ExtNo') === null ? -1 : 0,
    interiorValid: sessionStorage.getItem('IntNo') === null ? -1 : 0,
    postalCodeValid: sessionStorage.getItem('ZipCode') === null ? -1 : 0,
    suburbValid: sessionStorage.getItem('Suburb') === null ? -1 : 0,
    municipioValid: sessionStorage.getItem('Municipality') === null ? -1 : 0,
    estadoValid: sessionStorage.getItem('State') === null ? -1 : 0,
    housingTypeValid: sessionStorage.getItem('housingType') === null ? -1 : 0,
  });

  const [texterrorAddress] = useState({
    typeDom: '',
    address: '',
    Street: '',
    outdoor: '',
    interior: '',
    postalCode: '',
    suburb: '',
    municipio: '',
    estado: '',
    housingType: '',
    period: '',
  });
  const [btnDisabledAddress, SetbtnDisabledAddress] = useState(true);

  const Type = [
    {
      value: 'Casa',
      label: 'Casa',
    },
    {
      value: 'Trabajo',
      label: 'Trabajo',
    },
  ];

  const handleChangeAddress = (e) => {
    if (e.target.name === 'check') {
      if (formAddress.check) {
        SetFormAddress({
          ...formAddress,
          check: false,
        });
      } else {
        SetFormAddress({
          ...formAddress,
          check: true,
        });
      }
      // Updatedefault(number);
    } else {
      SetFormAddress({
        ...JSON.parse(
          JSON.stringify({
            ...formAddress,
            [e.target.name]: e.target.value,
          }),
        ),
      });
      const { name, value } = e.target;
      errorAddress[name] = false;
      errorAddress[`${name}Valid`] = 0;
      switch (name) {
        case 'route':
          setAutocompleteForm(
            Object.assign(autocompleteForm, {
              ...autocompleteForm,
              Street: value,
            }),
          );
          break;
        case 'street_number':
          setAutocompleteForm(
            Object.assign(autocompleteForm, {
              ...autocompleteForm,
              outdoor: value,
            }),
          );
          break;
        case 'postalCode':
          if (e.target.value.length === 5) {
            setCP(e.target.value);
            sessionStorage.setItem('ZipCode', e.target.value);
            errorAddress.postalCode = false;
            errorAddress.postalCodeValid = 0;
          } else {
            SetFormAddress({
              ...formAddress,
              postalCode: e.target.value,
              municipio: '',
              state: '',
            });
            errorAddress.postalCode = true;
            errorAddress.postalCodeValid = 1;
          }
          setAutocompleteForm(
            Object.assign(autocompleteForm, {
              ...autocompleteForm,
              postalCode: value,
            }),
          );
          break;
        case 'sublocality_level_1':
          setAutocompleteForm(
            Object.assign(autocompleteForm, {
              ...autocompleteForm,
              suburb: value,
            }),
          );
          break;
        case 'locality':
          setAutocompleteForm(
            Object.assign(autocompleteForm, {
              ...autocompleteForm,
              municipio: value,
            }),
          );
          break;
        case 'administrative_area_level_1':
          setAutocompleteForm(
            Object.assign(autocompleteForm, {
              ...autocompleteForm,
              state: value,
            }),
          );
          break;
        default:
          break;
      }

      ebtn();
    }
  };

  const handleClickMore = () => {
    if (keyboardStatus) {
      setKeyboardStatus(false);
      setMore('Mostrar más');
    } else {
      setKeyboardStatus(true);
      setMore('Mostrar menos');
    }
  };

  // MODAL
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    console.log('Open modal');
    setOpen(true);
  };

  const ebtn = () => {
    if (
      (errorAddress.typeDom === false && formAddress.typeDom !== '')
      || (errorAddress.address === false && formAddress.address !== '')
      || (errorAddress.Street === false && formAddress.Street !== '')
      || (errorAddress.outdoor === false && formAddress.outdoor !== '')
      || errorAddress.interior === false
      || (errorAddress.postalCode === false && formAddress.postalCode !== '')
      || formAddress.postalCode !== ''
      || (errorAddress.municipio === false && formAddress.postalCode !== '')
      || (errorAddress.state === false && formAddress.state !== '')
      || (errorAddress.housingType === false && formAddress.state !== '')
    ) {
      SetbtnDisabledAddress(false);
    } else {
      SetbtnDisabledAddress(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <SubtitleOne
          mt="3%"
          className="font-weight-bold roboto"
          sizeMobile="20px"
          size="20px"
        ></SubtitleOne>
        <ParagrapTwo className="mx-auto roboto" mb="0px">
          Tus cambios se han guardado con éxito.
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Aceptar
        </Button>
      </div>
    </Modal>
  );

  const setAddressItem = (name, value) => {
    console.log('*************************');
    console.log(name);
    switch (name) {
      case 'route':
        setAutocompleteForm(
          Object.assign(autocompleteForm, {
            ...autocompleteForm,
            Street: value,
          }),
        );
        break;
      case 'street_number':
        setAutocompleteForm(
          Object.assign(autocompleteForm, {
            ...autocompleteForm,
            outdoor: value,
          }),
        );
        break;
      case 'postal_code':
        setAutocompleteForm(
          Object.assign(autocompleteForm, {
            ...autocompleteForm,
            postalCode: value,
          }),
        );
        break;
      case 'sublocality_level_1':
        setAutocompleteForm(
          Object.assign(autocompleteForm, {
            ...autocompleteForm,
            suburb: value,
          }),
        );
        break;
      case 'locality':
        setAutocompleteForm(
          Object.assign(autocompleteForm, {
            ...autocompleteForm,
            municipio: value,
          }),
        );
        break;
      case 'administrative_area_level_1':
        setAutocompleteForm(
          Object.assign(autocompleteForm, {
            ...autocompleteForm,
            state: value,
          }),
        );
        break;
      default:
        break;
    }
    // console.log(autocompleteForm)
  };

  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('address'),
      {
        types: ['geocode'],
      },
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      SetFormAddress({
        typeDom: '',
        address: '',
        Street: '',
        outdoor: '',
        interior: '',
        postalCode: '',
        suburb: '',
        municipio: '',
        state: '',
        housingType: '',
        period: '',
        check,
      });
      console.log('Initial form ====>>>', formAddress);
      const nearPlace = autocomplete.getPlace();
      nearPlace.address_components.forEach((addressitem) => {
        setAddressItem(addressitem.types[0], addressitem.long_name);
      });
      SetFormAddress(
        Object.assign(formAddress, {
          ...autocompleteForm,
          address: nearPlace.formatted_address,
        }),
      );
      console.log('Autocomplete => ', autocompleteForm);
      console.log('Final ====>>>', formAddress);
    });
  }, []);

  useEffect(() => {
    SetFormAddress({
      ...formAddress,
      estado: sessionStorage.getItem('State'),
      suburb: `${sessionStorage.getItem('Suburb')} `,
    });
    console.log('*******************Estado*****************');
    console.log(formAddress.estado);
    SetFormAddress({
      ...formAddress,
      estado: sessionStorage.getItem('State'),
      suburb: `${sessionStorage.getItem('Suburb')} `,
    });
  }, [formAddress.estado]);

  return (
    <Fragment>
      <Container width="100%" mt="0rem" widthMobile="100%" mtMobile={mt}>
        <Container
          width="100%"
          mt="0%"
          bb="1px solid #D6D6D6"
          mb="5%"
          widthMobile="100%"
          mtMobile="0px"
          float="auto"
          mMobile="auto"
        >
          <Col12 pMobile="0px">
            <SubtitleOne
              textalingn="left"
              textalingnMobile="left"
              className="font-weight-bold"
              sizeMobile="20px"
              size="25px"
              mb="1%"
            >
              Domicilio {number}
              {number === '2' ? (
                <TextPerfil
                  cursor="pointer!important"
                  mt="0%"
                  width="auto"
                  display=" "
                  widthMobile="auto"
                  float=" right"
                  fs="14px"
                  lh="17px"
                  textAling="right"
                  onClick={handleClickDelete}
                  className="link"
                >
                  Eliminar
                </TextPerfil>
              ) : (
                ''
              )}
            </SubtitleOne>
            <FormControlLabel
              value="check"
              control={
                <Checkbox
                  id="check"
                  name="check"
                  color=" "
                  checked={formAddress.check}
                  onChange={handleChangeAddress}
                />
              }
              label="Predeterminado"
              labelPlacement="Predeterminado"
            />
            <ParagrapTwo
              mb="0px"
              textalignMobile="left"
              texttalign="left"
              className="mx-auto"
              mtWeb="0px"
            >
              Dirección de tu casa o trabajo para completar tu perfil.{' '}
            </ParagrapTwo>
            <form
              autoComplete="off"
              className={keyboardStatus ? 'Display' : 'NoDisplay'}
              onSubmit={guardarAddress}
            >
              <Container width="100%" widthMobile="100%">
                <Col12 pMobile="0px">
                  <TextField
                    id="typeDom"
                    name="typeDom"
                    select
                    label="Tipo de Domicilio"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.typeDom}
                    value={formAddress.typeDom}
                    inputProps={{
                      className:
                        errorAddress.typeDomValid === 1
                          ? 'perfil invalidSelect'
                          : errorAddress.typeDomValid === 0
                            ? 'perfil validSelect'
                            : ' ',
                    }}
                  >
                    {Type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col12>
                <Col12
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="postalCode"
                    name="postalCode"
                    label="Código postal"
                    type="tel"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.postalCode}
                    value={formAddress.postalCode}
                    inputProps={{
                      className:
                        errorAddress.postalCodeValid === 1
                          ? 'perfil invalidInput'
                          : errorAddress.postalCodeValid === 0
                            ? 'perfil validInput'
                            : ' ',
                    }}
                  ></TextField>
                </Col12>
                <Col12 pMobile="0px">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="Street"
                    name="Street"
                    label="Calle"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.Street}
                    value={formAddress.Street}
                    inputProps={{
                      className:
                        errorAddress.StreetValid === 1
                          ? 'perfil invalidInput'
                          : errorAddress.StreetValid === 0
                            ? 'perfil validInput'
                            : ' ',
                    }}
                  ></TextField>
                </Col12>
                <Col6
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="outdoor"
                    name="outdoor"
                    label="Número exterior"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.outdoor}
                    value={formAddress.outdoor}
                    inputProps={{
                      className:
                        errorAddress.outdoorValid === 1
                          ? 'perfil invalidInput'
                          : errorAddress.outdoorValid === 0
                            ? 'perfil validInput'
                            : ' ',
                    }}
                  ></TextField>
                </Col6>
                <Col6
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="interior"
                    name="interior"
                    label="Número interior"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.interior}
                    value={formAddress.interior}
                    inputProps={{
                      className:
                        errorAddress.interiorValid === 1
                          ? 'perfil invalidInput'
                          : errorAddress.interiorValid === 0
                            ? 'perfil validInput'
                            : ' ',
                    }}
                  ></TextField>
                </Col6>
                <Col6
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <select
                    name="colonia"
                    onChange={handleChangeAddress}
                    className={
                      ZipCode.length === 0
                        ? 'form-control adress-input perfil'
                        : 'form-control adress-input perfil '
                    }
                    style={{ backgroundColor: '#ffff' }}
                  >
                    {formAddress.postalCode !== '' ? (
                      <GetAddressByCp
                        handleChangeAddress={GetDataAdres}
                        ZipCode={ZipCode}
                        form={formAddress}
                      />
                    ) : (
                      <option>Selecciona una opción</option>
                    )}
                  </select>
                </Col6>
                <Col6
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <DatosAddress
                    id="municipio"
                    label="Municipio"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.municipio}
                    value={formAddress.municipio}
                    inputProps={{
                      className:
                        errorAddress.municipioValid === 1
                          ? 'perfil invalidInput'
                          : errorAddress.municipioValid === 0
                            ? 'perfil validInput'
                            : ' ',
                    }}
                  />
                </Col6>
                <Col6
                  flex="0 0 100%"
                  maxwidth="100%"
                  pMobile="0px"
                  ptMobile="7%!important"
                >
                  <TextField
                    id="estado"
                    name="estado"
                    label="Estado"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.estado}
                    value={
                      formAddress.postalCode !== '' ? formAddress.estado : ''
                    }
                    inputProps={{
                      className:
                        errorAddress.estadoValid === 1
                          ? 'perfil invalidInput'
                          : errorAddress.estadoValid === 0
                            ? 'perfil validInput'
                            : ' ',
                    }}
                  ></TextField>
                </Col6>
                <Col12 pMobile="0px">
                  <TextField
                    id="period"
                    name="period"
                    label="Años de vivir en el domicilio"
                    fullWidth
                    select
                    variant="outlined"
                    onChange={handleChangeAddress}
                    helperText={texterrorAddress.period}
                    value={formAddress.period}
                    inputProps={{
                      className:
                        errorAddress.periodValid === 1
                          ? 'perfil invalidSelect'
                          : errorAddress.periodValid === 0
                            ? 'perfil validSelect'
                            : ' ',
                    }}
                  >
                    {Periods.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={
                          option.value === '20' ? 'Más de 20' : option.value
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col12>
              </Container>
              <Button
                disabled={btnDisabledAddress}
                type="submit"
                className="d-block mt-5 mt-md-4 mb-5 roboto"
                label="Guardar"
              >
                Guardar
              </Button>
            </form>
            <TextPerfil
              mb="5%"
              cursor="pointer!important"
              className="roboto"
              mt="0%"
              fs="14px"
              width="100%"
              widthMobile="100%"
              lh="17px"
              textAling="right"
              onClick={handleClickMore}
            >
              {More}
              {keyboardStatus ? (
                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
              ) : (
                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
              )}{' '}
            </TextPerfil>
          </Col12>
        </Container>
      </Container>
      <ModalMessage />
    </Fragment>
  );
}
export default FormsAddress;
