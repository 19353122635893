import React, { useState, Fragment, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTrail, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import '../../assets/styles/Home.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Shapes from '../../assets/img/anacoach/Shapes/Rectangle 23.png';
import Shapes2 from '../../assets/img/anacoach/Shapes/Vector.png';
import Shapes4 from '../../assets/img/anacoach/Shapes/Rectangle 25.png';
import Shapes5 from '../../assets/img/anacoach/Shapes/Vector-1.png';
import Trofeo from '../../assets/img/anacoach/Trofeo.png';
import face from '../../assets/img/anacoach/facebook1.png';
import twit from '../../assets/img/embajadores/twitter1.png';
import whats from '../../assets/img/anacoach/whats.png';
import {
  CardsBtn,
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingText,
  TrainingSectionGray,
  TrainingDivSection2,
  TextFriends,
} from '../../assets/styles/Mvp.styled';
import { TrainigTam, Images } from '../../assets/styles/Training.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import WaitList from '../../components/Training/WaitList';
// Modal
import { GET_EXERCISE_BY_MAIL, GET_LIST_MVP } from '../../queries';
import { CREATE_LOG_SHARE_MVP, INSERT_CAT_RECURRENCE } from '../../mutations';
import { Button, ParagrapTwo } from '../../components/common/common.styled';

const items2 = ['¿Cuánto sabes de tu', 'inversión?'];
const config = { mass: 4, tension: 600, friction: 100 };

const Investment = () => {
  sessionStorage.setItem('idTraining', 22);
  const WOStatus = sessionStorage.getItem('MVP Cuanto Sabes de Inversión') == 'on';
  const {
    utm_source, utm_medium, utm_campaign, idMaster,
  } = qs.parse(
    window.location.search,
    { ignoreQueryPrefix: true },
  );
  const [stateUtm, setStateUtm] = useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  });
  const history = useHistory();
  const [toggle2] = useState(true);
  const [email, setEmail] = useState('');
  const [emailTemp, setEmailTemp] = useState('');
  const trail2 = useTrail(items2.length, {
    config,
    opacity: toggle2 ? 1 : 0,
    a: toggle2 ? 0 : 20,
    height: toggle2 ? 80 : 0,
    from: { opacity: 0, a: 20, height: 0 },
  });

  // modal
  const [idExerciseEmail, setidExerciseEmail] = useState('');
  const [Name1, setName1] = useState('-----');
  const [Name2, setName2] = useState('-----');
  const [Name3, setName3] = useState('-----');
  const [Name4, setName4] = useState('-----');
  const [Name5, setName5] = useState('-----');
  const [getExercisebyMail] = useLazyQuery(GET_EXERCISE_BY_MAIL, {
    onCompleted({ getExercisebyMail }) {
      // console.log(getExercisebyMail);
      if (getExercisebyMail.message !== '') {
        setEmail('');
        setidExerciseEmail(getExercisebyMail.message);
      } else {
        settableContent(false);
        setidExerciseEmail('0');
        setEmail('');
        setName1('-----');
        setName2('-----');
        setName3('-----');
        setName4('-----');
        setName5('-----');
        setOpen2(true);
      }
    },
  });
  const [createLogShareMvp] = useMutation(CREATE_LOG_SHARE_MVP, {
    onCompleted({ createLogShareMvp }) {
      if (createLogShareMvp === 'ok') {
        console.log('insertado');
      } else {
        console.log(createLogShareMvp);
      }
    },
  });
  const [insertCatRecurrence] = useMutation(INSERT_CAT_RECURRENCE, {
    onCompleted({ insertCatRecurrence }) {
      if (insertCatRecurrence === 'ok') {
        console.log('insertado');
      } else {
        console.log('fallo');
      }
    },
  });
  const [getListMvp] = useLazyQuery(GET_LIST_MVP, {
    onCompleted({ getListMvp }) {
      // console.log(getListMvp);
      setName1('-----');
      setName2('-----');
      setName3('-----');
      setName4('-----');
      setName5('-----');
      if (getListMvp.statusCode === 200) {
        const responseLength = getListMvp.response.length;
        switch (responseLength) {
          case 0:
            break;
          case 1:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            settableContent(true);
            break;
          case 2:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            settableContent(true);
            break;
          case 3:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[2].ClassificationName}`,
            );
            setName3(
              `${getListMvp.response[2].FirstName} ${getListMvp.response[2].FathersLastName}- ${getListMvp.response[3].ClassificationName}`,
            );
            settableContent(true);
            break;
          case 4:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            setName3(
              `${getListMvp.response[2].FirstName} ${getListMvp.response[2].FathersLastName}- ${getListMvp.response[2].ClassificationName}`,
            );
            setName4(
              `${getListMvp.response[3].FirstName} ${getListMvp.response[3].FathersLastName}- ${getListMvp.response[3].ClassificationName}`,
            );
            settableContent(true);
            break;
          default:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            setName3(
              `${getListMvp.response[2].FirstName} ${getListMvp.response[2].FathersLastName}- ${getListMvp.response[2].ClassificationName}`,
            );
            setName4(
              `${getListMvp.response[3].FirstName} ${getListMvp.response[3].FathersLastName}- ${getListMvp.response[3].ClassificationName}`,
            );
            setName5(
              `${getListMvp.response[4].FirstName} ${getListMvp.response[4].FathersLastName}- ${getListMvp.response[4].ClassificationName}`,
            );
            settableContent(true);
            break;
        }
      }
      setOpen2(true);
    },
  });

  useEffect(() => {
    if (idExerciseEmail !== '') {
      if (idExerciseEmail !== '0') {
        insertCatRecurrence({
          variables: {
            idExercise: idExerciseEmail,
          },
        });
      }
      getListMvp({
        variables: {
          idUser: idExerciseEmail,
          catTraining: '22',
          viewType: 'master',
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [idExerciseEmail]);
  //   fin modal
  const answer = () => history.push('/quiz_investment');
  const handleOnClick = (e) => {
    e.preventDefault();
    setStateUtm({
      ...stateUtm,
      utm_source,
      utm_medium,
      utm_campaign,
    });
    sessionStorage.setItem('utm_source', utm_source);
    if (idMaster !== undefined) {
      sessionStorage.setItem('idMaster', idMaster);
    }
    sessionStorage.setItem('utm_medium', utm_medium);
    sessionStorage.setItem('utm_campaign', utm_campaign);
    answer();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const cerrarModal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    if (error.email) {
    } else if (email !== emailTemp) {
      setEmailTemp(email);
      getExercisebyMail({
        variables: {
          Email: email,
          idTraining: '22',
        },
        fetchPolicy: 'network-only',
      });
      setOpen(false);
    } else {
      if (Name1 !== '-----') {
        settableContent(true);
      }
      setOpen(false);
      setOpen2(true);
      setEmail('');
    }
  };

  const handleClose2 = () => {
    setidExerciseEmail('');
    // setEmail("");
    settableContent(false);
    setOpen2(false);
  };

  // MODAL

  const handleSocial = (socialNetwork) => {
    const idtraining = '22';
    createLogShareMvp({
      variables: {
        input: {
          idSession: sessionStorage.getItem('idDatSession'),
          idUser: '',
          idExercise: idExerciseEmail.toString(),
          socialNetwork,
          email,
          training: idtraining.toString(),
        },
      },
    });
    const coruRedirectURL = `https://coru.com/mvp-investment?idMaster=${idExerciseEmail}`;
    // console.log(coruRedirectURL);

    switch (socialNetwork) {
      case 'Facebook':
        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruRedirectURL,
          )}&redirect_uri=${encodeURIComponent(
            'https://www.facebook.com/',
          )}&display=popup`,
        );
        break;
      case 'Whatsapp':
        const whatsappMessage = `¿Sabes más que yo?, responde este quiz y averigüémoslo. ${coruRedirectURL}`;
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`,
          'popup',
          'width=600',
          'height=600',
        );
        break;
      case 'Twitter':
        const twitterMessage = `¿Sabes más que yo?, responde este quiz y averigüémoslo. ${coruRedirectURL}`;
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURI(twitterMessage)}`,
        );
        break;
    }
  };

  const [error] = useState({
    email: true,
  });
  const [texterror] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setEmail(e.target.value);
    ValidEmail(e.target.name, e.target.value);
  };
  const ValidEmail = (id, valueEmail) => {
    let domain = valueEmail.split('@');
    domain = domain[1];

    const idmail = document.getElementsByName(id)[0];
    if (idmail === null) {
      console.log('null');
    } else if (
      valueEmail
      && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
    } else if (
      domain !== ''
      && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
    } else {
      idmail.className = 'textInput inputs-bottom validInput';
      texterror.email = '';
      error.email = false;
      error.emailvalid = 0;
    }
    // eBtn();
  };
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '380px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
    },
  }));
  const useStylesTable = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '600px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
    },
  }));
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [tableContent, settableContent] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={cerrarModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={cerrarModal}
        >
          X
        </ParagrapTwo>
        <TextField
          id="email"
          name="email"
          autoFocus
          label="Ingresa tu correo electrónico"
          required
          value={email}
          fullWidth
          type="email"
          onChange={handleChange}
          className="registro"
        />
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
        >
          {texterror.email}
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Ver resultados
        </Button>
      </div>
    </Modal>
  );
  const ModalMessage2 = () => (
    <Modal
      open={open2}
      onClose={handleClose2}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={modalStyle}
        className={tableContent ? classesTable.paper : classes.paper}
      >
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose2}
        >
          X
        </ParagrapTwo>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
        >
          {/* test */}

          {tableContent ? (
            <div>
              <ParagrapTwo
                fontWeight="700"
                cursor="pointer"
                texttalign="Left"
                textalignMobile="Left"
                className="mx-auto"
              >
                Compartir
              </ParagrapTwo>
              <table align="center" alignweb="center">
                <tr>
                  <td>
                    <img
                      alt=""
                      position="initial"
                      left="0"
                      width="50px"
                      height="50px"
                      style={{
                        maxWidth: '100%',
                        width: '50px',
                        height: '50px',
                        position: 'initial',
                        marginLeft: '0',
                        cursor: 'pointer',
                      }}
                      src={face}
                      onClick={() => handleSocial('Facebook')}
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      position="initial"
                      left="0"
                      width="50px"
                      height="50px"
                      style={{
                        maxWidth: '100%',
                        width: '50px',
                        height: '50px',
                        position: 'initial',
                        marginLeft: '20px',
                        cursor: 'pointer',
                      }}
                      src={twit}
                      onClick={() => handleSocial('Twitter')}
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      position="initial"
                      left="0"
                      width="50px"
                      height="50px"
                      style={{
                        maxWidth: '100%',
                        width: '50px',
                        height: '50px',
                        position: 'initial',
                        marginLeft: '20px',
                        cursor: 'pointer',
                      }}
                      src={whats}
                      onClick={() => handleSocial('Whatsapp')}
                    />
                  </td>
                </tr>
              </table>
              <TrainingDivSection
                back="#fafafa"
                padding="0 10% 2% 10%"
                paddw="8% 0 0 0"
              >
                <TrainingText
                  className="roboto"
                  align="center"
                  alignweb="center"
                >
                  <b>¿Eres el experto en finanzas de tus amigos?</b>
                  <br />
                </TrainingText>
              </TrainingDivSection>
              <TrainingSectionGray
                wiw="550px"
                mw="auto"
                back="#FAFAFA"
                mtop="0"
              >
                <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                  <Images
                    src={Trofeo}
                    width="60px"
                    top="0"
                    topweb="0"
                    left="45%"
                    leftw="45%"
                    position="relative"
                    className="img-fluid"
                  ></Images>
                  <br />
                  <br />
                  <TextFriends>
                    <b>1</b> {Name1}
                  </TextFriends>
                  <br />
                  <TextFriends>
                    <b>2</b> {Name2}
                  </TextFriends>
                  <br />
                  <TextFriends>
                    <b>3</b> {Name3}
                  </TextFriends>
                  <br />
                  <TextFriends>
                    <b>4</b> {Name4}
                  </TextFriends>
                  <br />
                  <TextFriends>
                    <b>5</b> {Name5}
                  </TextFriends>
                  <br />
                  <br />
                </TrainingDivSection2>
              </TrainingSectionGray>
            </div>
          ) : (
            <div>Usuario inexistente</div>
          )}

          {/* test fin */}
        </ParagrapTwo>

        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose2}
        >
          Cerrar
        </Button>
      </div>
    </Modal>
  );
  return (
    <Fragment>
      <TrainingMain>
        <TrainigTam>
          <Header background={'#fafafa'} pathname={window.location.pathname} />
        </TrainigTam>
        {WOStatus ? (
          <Fragment>
            <Images
              src={Shapes}
              width="50px"
              widthw="125px"
              top="18%"
              topweb="8%"
              left="5%"
              leftw="75%"
              position="absolute"
              className="img-fluid"
            ></Images>
            <Images
              src={Shapes}
              widthw="42px"
              width="20px"
              top="45%"
              topweb="12%"
              left="55%"
              leftw="55%"
              position="absolute"
              className="img-fluid"
            ></Images>
            <Images
              src={Shapes2}
              width="45px"
              widthw="90px"
              top="35%"
              topweb="24%"
              left="77%"
              leftw="77%"
              position="absolute"
              className="img-fluid"
            ></Images>
            <Images
              src={Shapes4}
              width="38px"
              widthw="78px"
              top="18%"
              topweb="18%"
              left="85%"
              leftw="85%"
              position="absolute"
              className="img-fluid"
            ></Images>
            <Images
              src={Shapes4}
              width="27px"
              widthw="55px"
              top="20%"
              topweb="20%"
              left="62%"
              leftw="62%"
              position="absolute"
              className="img-fluid"
            ></Images>
            <Images
              src={Shapes5}
              width="30px"
              widthw="60px"
              top="41%"
              topweb="9%"
              left="20%"
              leftw="67%"
              position="absolute"
              className="img-fluid"
              pr="20px"
            ></Images>
            <Images
              src={Shapes5}
              width="30px"
              widthw="60px"
              top="18%"
              topweb="34%"
              left="35%"
              leftw="85%"
              position="absolute"
              className="img-fluid"
            ></Images>
            <TrainingAnimateFunnel
              bcolor="#fafafa"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="auto"
              hweb="auto"
            >
              <TrainigTam bc="" hm="auto" pt="108px" pl="20px" plw="0">
                {trail2.map(({ a, height, ...rest }, index) => (
                  <animated.div
                    key={items2[index]}
                    className="trails-text text-funnelcc cabin mvp-landing"
                    style={{
                      ...rest,
                      transform: a.interpolate(
                        (a) => `translate3d(0,${a}px,0)`,
                      ),
                    }}
                  >
                    <animated.div style={{ height }}>
                      {items2[index]}
                    </animated.div>
                  </animated.div>
                ))}
              </TrainigTam>
            </TrainingAnimateFunnel>
            <TrainingDivSection
              back="transparent"
              padding="200px 10% 2% 20px"
              paddw="8% 0 5% 0"
            >
              <TrainingText className="roboto" align="left" alignweb="left">
                Responde este quiz, compártelo con tus amigos y descubran al
                experto en finanzas del grupo.
              </TrainingText>
            </TrainingDivSection>

            <TrainingSectionGray back="transparent">
              <CardsBtn
                styledMaxWidth="60%"
                mleft="20px"
                type="button"
                min_w="30%"
                min_web="30%"
                onClick={(e) => handleOnClick(e)}
              >
                Iniciar quiz
              </CardsBtn>
            </TrainingSectionGray>
            <br />
            <TrainingText
              className="roboto"
              left="20px"
              align="left"
              leftw="0"
              alignweb="left"
            >
              {/* <u>Resultados de mis amigos</u> */}
              <CardsBtn
                type="button"
                min_w="30%"
                min_web="30%"
                color="#EC407A !important"
                back="#fff !important"
                border="solid 1px !important"
                onClick={(e) => handleOpen(e)}
              >
                Resultados de mis amigos
              </CardsBtn>
            </TrainingText>
            <br />
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            <WaitList />
          </Fragment>
        )}
      </TrainingMain>
      <Footer pathname={window.location.pathname} />
      <div id="irTimeDom"></div>
      <ModalMessage />
      <ModalMessage2 />
    </Fragment>
  );
};
export default Investment;
