import React, { useState, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useQuery } from '@apollo/client';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  ContentButton,
  Container,
  SpanTextR,
  SpanTextL,
  ContTel,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import '../../assets/styles/TextCustom.css';
import { GET_COUNTRY_CODES } from '../../queries';
const ContactRappi = () => {
  const [error] = useState({
    numPhone: '',
    numPhoneValid: -1,
  });
  const [lleno, setlleno] = useState(false);
  const { data: dataLadas } = useQuery(GET_COUNTRY_CODES);
  const [form, setForm] = useState({
    numPhone: '',
    lada: 'México lada +(52)',
    textlada: 'México lada +(52)',
  });

  const [Ladas] = useState([
    {
      value: '',
      label: 'México',
      lada: '',
    },
    {
      value: 'Estados Unidos lada +(1)',
      label: 'Estados Unidos',
      lada: '1',
    },
    {
      value: '',
      label: 'Brasil',
      lada: '',
    },
    {
      value: '',
      label: 'Colombia',
      lada: '',
    },
    {
      value: '',
      label: 'Chile',
      lada: '',
    },
  ]);
  const handleChange = (e) => {
    if (e.target.name === 'numPhone') {
      setForm({
        ...form,
        numPhone: e.target.value.replace(/[^0-9]/g, ''),
      });
      if (
        (e.target.value.length < 11 && e.target.value.length !== 10)
        || (e.target.name && !/^[0-9]+$/i.test(e.target.value))
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
        });
        error.numPhone = 'Por favor verifica tu número telefónico';
        error.numPhoneValid = 1;
      } else if (e.target.value.length < 11 && e.target.value.length === 10) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
        });
        sessionStorage.setItem(
          'cellPhone',
          e.target.value.replace(/[^0-9]/g, ''),
        );
        error.numPhone = '';
        error.numPhoneValid = 0;
      }
    } else {
      setForm({
        ...form,
        lada: e.target.value,
        textlada: e.target.value,
      });
      const indexLada = e.target.value.indexOf('+(');
      const ladaselect = e.target.value.substring(indexLada);
      sessionStorage.setItem('ladauser', ladaselect);
    }
  };
  const LlenarCampo = () => {
    if (dataLadas) {
      if (!lleno) {
        dataLadas.getCountryCodes.forEach((element) => {
          if (
            element.Country !== 'México'
            && element.Country !== 'Estados Unidos'
            && element.Country !== 'Brasil'
            && element.Country !== 'Colombia'
            && element.Country !== 'Chile'
          ) {
            Ladas.push({
              value: `${element.Country} lada +(${element.CountryCode})`,
              label: element.Country,
              lada: element.CountryCode,
            });
          } else {
            let i = 0;
            switch (element.Country) {
              case 'México':
                i = 0;
                break;
              case 'Estados Unidos':
                i = 1;
                break;
              case 'Brasil':
                i = 2;
                break;
              case 'Colombia':
                i = 3;
                break;
              case 'Chile':
                i = 4;
                break;
              default:
                break;
            }
            Ladas[
              i
            ].value = `${element.Country} lada +(${element.CountryCode})`;
            Ladas[i].label = element.Country;
            Ladas[i].lada = element.CountryCode;
          }
        });
        setlleno(true);
      }
    }
  };

  // OpenLada
  const [openLada, setOpenLada] = useState(false);
  const handleCloseLada = () => {
    setOpenLada(false);
  };
  const handleOpenLada = () => {
    setOpenLada(true);
  };

  LlenarCampo();
  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="60%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="40px"
              sizeMobile="20px"
              textalingn="center"
              textalingnMobile="center"
            >
              Ingresa tu número telefónico
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="10%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#858585"
              textalignMobile="center"
              texttalign="center"
            >
              Te enviaremos nuevamente un código al
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
            >
              <ContTel>
                <Select
                  variant="outlined"
                  id="lada"
                  name="lada"
                  open={openLada}
                  onClose={handleCloseLada}
                  onOpen={handleOpenLada}
                  value={form.lada}
                  onChange={handleChange}
                  style={{ width: '40%' }}
                >
                  {Ladas.map((option) => (
                    <MenuItem key={option.lada} value={option.value}>
                      <SpanTextL className="roboto">{option.label}</SpanTextL>
                      <SpanTextR> +({option.lada})</SpanTextR>
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  variant="outlined"
                  id="numPhone"
                  name="numPhone"
                  autoFocus
                  required
                  label="Tu número telefónico"
                  type="tel"
                  inputProps={{
                    type: 'tel',
                    maxLength: 10,
                  }}
                  className="registro"
                  style={{ width: '60%' }}
                />
              </ContTel>
              <ContentButton mt="2%" width="80%" mtMobile="0%">
                <Button
                  href=""
                  className="d-block mt-md-4 roboto"
                  mb="1.2rem"
                  mt="1.2rem"
                  bg="#0CC665"
                >
                  Reenviar
                </Button>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
};

export default ContactRappi;
