export const insertOrUpdateUserDetail = async (
  columnsSet,
  getUserDetail,
  dynamicInsert,
  dynamicUpdate,
) => {
  const idUser = sessionStorage.getItem('idUser');
  const dynamicGetInput = {
    input: {
      table: 'CatUserDetails',
      columns: ['*'],
      conditions: [
        {
          valone: 'CatUsers_IDUser',
          condition: '=',
          valtwo: idUser,
        },
      ],
    },
  };
  const { data } = await getUserDetail({ variables: dynamicGetInput });
  const userDetail = JSON.parse(data.dynamicGet.response);
  if (userDetail.length > 0) {
    columnsSet.forEach((column) => {
      let insertColumn = true;
      userDetail.forEach((element) => {
        if (element.IDType === column.setkey) {
          insertColumn = false;
          if (element.Value !== column.setval) {
            dynamicUpdate({
              variables: {
                input: {
                  table: 'CatUserDetails',
                  columns: {
                    setkey: 'Value',
                    setval: String(column.setval),
                  },
                  conditions: {
                    valone: 'IDUserDetails',
                    condition: '=',
                    valtwo: String(element.IDUserDetails),
                  },
                },
              },
            });
          }
        }
      });
      if (insertColumn) {
        dynamicInsert({
          variables: {
            input: {
              table: 'CatUserDetails',
              columns: ['IDType', 'Value', 'CatUsers_IDUser'],
              detailValues: {
                values: [column.setkey, column.setval, idUser],
              },
            },
          },
        });
      }
    });
  } else {
    columnsSet.forEach((column) => {
      dynamicInsert({
        variables: {
          input: {
            table: 'CatUserDetails',
            columns: ['IDType', 'Value', 'CatUsers_IDUser'],
            detailValues: {
              values: [column.setkey, column.setval, idUser],
            },
          },
        },
      });
    });
  }
};
