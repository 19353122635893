import React, { Fragment, useState } from 'react';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  Container,
} from '../../assets/styles/Home.styled';
import CategoryText from './CtegoryText';
import TrainingCarousel from '../Training/TrainingCarousel';
import { Helmet } from 'react-helmet';

function HomeCategories({
  color, name, imagen, id, setCategory, description, trainings, isHome, show,
}) {
  // variable para saber el estado de mostrar texto de categoria o el titulo de la categoria
  const [showCategoryText, setShowCategoryText] = useState(show);
  const ColorRGB = (h) => {
    let r = 0; let g = 0; let
      b = 0;
    // 3 digits
    if (h.length === 4) {
      r = `0x${h[1]}${h[1]}`;
      g = `0x${h[2]}${h[2]}`;
      b = `0x${h[3]}${h[3]}`;
      // 6 digits
    } else if (h.length === 7) {
      r = `0x${h[1]}${h[2]}`;
      g = `0x${h[3]}${h[4]}`;
      b = `0x${h[5]}${h[6]}`;
    }

    return `rgb(${+r},${+g},${+b} , 0.2)`;
  };

  const handleClickShowCategorias = (e) => {
    e.stopPropagation();
    // verifica cuando se haga click en la categoria y se
    // actualiza la varaible para mostrar el texto de la categoria
    setShowCategoryText(!showCategoryText);
  };

  return (
        <Fragment>
          <Helmet>
            <title>Mejora tu vida financiera | CORU</title>
          </Helmet> 
            <Container width="100%" widthMobile="100%" mtMobile="0px">
                <div style={{ backgroundColor: ColorRGB(color) }} className="divEntrenamiento">
                    <ContEntrenamiento id={`categorieSelectedOption${id}`} height="auto" widthM="100%" margintop="auto" padding="0px" paddingM="0px" widthD="100%" cursor="pointer" marginleft="auto">
                        <div className="row divCategorias" style={{ border: `1px solid ${color}` }}
                            onClick={(e) => handleClickShowCategorias(e)}
                            >
                            <div className="col-2 d-flex flex-column justify-content-start align-items-center">
                                <Image
                                    src={imagen}
                                    float=" "
                                    position=" "
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-9 center paddingmobile">

                                {// mostrar el titulo del entrenamiento o el texto de la categoria cuando se haga click
                                !showCategoryText ? <TitleEntrenamiento className="roboto" aling="left" sizem="19px" left="0px" margin="auto" top=" ">
                                    {name}
                                </TitleEntrenamiento>
                                  : isHome
                                    ? <CategoryText
                                  categorySelected={id}
                                  setCategory={setCategory}
                                  description={description}
                                />
                                    : <TitleEntrenamiento className="roboto" aling="left" sizem="19px" left="0px" margin="auto" top=" ">
                                    {name}
                                </TitleEntrenamiento>
                                }
                            </div>

                        </div>
                    </ContEntrenamiento>

                {
                  !isHome && showCategoryText
                    ? <TrainingCarousel
                        items={trainings.filter((training) => training.idCatCategory === id)}
                        feed={true}
                    /> : null
                }
              </div>
            </Container>
        </Fragment>
  );
}

export default HomeCategories;
