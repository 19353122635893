export const getDateStandart = () => {
  let today = new window.Date(); // new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
  return today;
};

export const getTimeStamp = () => {
  const d = new Date();
  let month = (d.getMonth() + 1).toString();
  month = month.length == 2 ? month : `0${month}`;

  let day = d.getDate().toString();
  day = day.length == 2 ? day : `0${day}`;

  let hours = d.getHours().toString();
  hours = hours.length == 2 ? hours : `0${hours}`;

  let minutes = d.getMinutes().toString();
  minutes = minutes.length == 2 ? minutes : `0${minutes}`;

  let seconds = d.getSeconds().toString();
  seconds = seconds.length == 2 ? seconds : `0${seconds}`;

  const dformat = `${[d.getFullYear(), month, day].join('-')
  } ${
    [hours, minutes, seconds].join(':')}`;

  return dformat;
};

export const weekDay = (d) => {
  const date = new Date(d.replace(/-+/g, '/'));
  const options = {
    weekday: 'long',
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric'
  };
  return date.toLocaleDateString('es-MX', options);
};

export const daysPassed = (dateString) => {
  const today = new Date();
  const start = new Date(dateString);
  const days = today.getDay() - start.getDay();
  return days;
};
const getDateFormat = (fecha) => {
  const day = fecha.getDate();
  // el mes es devuelto entre 0 y 11
  const d = day < 10 ? `0${day}` : day;
  const month = fecha.getMonth() + 1;
  const year = fecha.getFullYear();
  return `${year}-${month}-${d}`;
};

export const addDaysToDate = (days, f) => {
  const fecha = new Date(f);
  fecha.setDate(fecha.getDate() + days);
  return getDateFormat(fecha);
};

export const getCurrentDate = () => {
  const dateOb = new Date();

  const date = `0${dateOb.getDate()}`.slice(-2);
  const month = `0${dateOb.getMonth() + 1}`.slice(-2);
  const year = dateOb.getFullYear();

  return `${year}/${month}/${date}`;
};

export const removeAccents = (str) => str
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .trim()
  .toLowerCase();

export const formatAMPM = (date, addHour = 0, min = 0) => {
  let hours;
  let minutes;
  if (addHour === 0) {
    hours = date.getHours();
  } else {
    hours = date.getHours() + addHour;
  }
  if (min === 0) {
    minutes = date.getMinutes();
  } else {
    minutes = date.getMinutes() + 5;
  }
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const validationsCreateTicket = () => {
  const clientId = sessionStorage.getItem('idGa');
  let valid_ga = '';
  valid_ga = `${clientId}:360027373473,`;
  let device = '';
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent,
    )
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  ) {
    device = 'mobile:360027971413,';
  } else {
    device = 'desktop:360027971413,';
  }
  const validCreateTicket = valid_ga + device;
  return validCreateTicket;
};

export function getItemSessionStorage(key) {
  return sessionStorage.getItem(key) || '';
}

export const handleIngresoMensual = () => {
  let ingresoMensual = getItemSessionStorage('Ingreso_Mensual_Fijo');
  switch (ingresoMensual) {
    case 'menos_4000':
      ingresoMensual = '4000';
      break;
    case 'de_4000_a_7000':
      ingresoMensual = '5000';
      break;
    case 'de_7000_a_15000':
      ingresoMensual = '10000';
      break;
    case 'de_15000_a_30000':
      ingresoMensual = '22000';
      break;
    case 'mas_de_30000':
      ingresoMensual = '30000';
      break;
  }
  return ingresoMensual;
};

export const handleOtpCreditea = ({ sendCrediteaApplicationWL, verOtrasOpciones }) => {
  const curp = getItemSessionStorage('CURP_FT');
  const dataCreditea = {
    idUser: getItemSessionStorage('idUser'),
    withOffer: 'true',
    Personal: {
      nombre: getItemSessionStorage('FirstName'),
      nombre2: getItemSessionStorage('MiddleName'),
      apellidoPaterno: getItemSessionStorage('FathersLastName'),
      apellidoMaterno: getItemSessionStorage('MothersLastName'),
      estadoCivil: getItemSessionStorage('MaritalStatus'),
      Estudios: getItemSessionStorage('Ulltimo_grado_de_estudios'),
      cellPhone: getItemSessionStorage('CellPhone'),
      Email: getItemSessionStorage('Email1'),
      identificacion: curp,
    },
    Credito: {
      trabajo: getItemSessionStorage('occupation'),
      montoCreditoSolicitado: "0",
      mesesCreditoSolicitado: "0",
      salario: handleIngresoMensual(),
    },
    Direccion: {
      calle: getItemSessionStorage('Street'),
      noExt: getItemSessionStorage('ExtNo'),
      cp: getItemSessionStorage('ZipCode'),
      municipio: getItemSessionStorage('Municipality'),
    }
  };

  if (
    curp !== '' &&
    dataCreditea.Personal.estadoCivil !== '' &&
    dataCreditea.Personal.Estudios !== '' &&
    dataCreditea.Credito.trabajo !== '' &&
    dataCreditea.Credito.salario !== '' &&
    dataCreditea.Direccion.calle !== '' &&
    dataCreditea.Direccion.noExt !== '' &&
    dataCreditea.Direccion.cp !== '' &&
    dataCreditea.Direccion.municipio !== ''
  ) {
    sendCrediteaApplicationWL({
      variables: {
        input: dataCreditea
      }
    });
  } else {
    verOtrasOpciones();
  }
};
