import React, { useState, Fragment } from 'react';
import CardOne from '../../components/Home/Card1Animate';
import {
  TrainingSubtitles,
  CardsBtn,
  TrainingMain,
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Mvp.styled';
import '../../assets/styles/Mvp.css';

import TextField from '@material-ui/core/TextField';

import { SUBMIT_UPDATE_LANDING, UPDATE_EMAIL_LANDING } from '../../mutations';

import { useMutation } from '@apollo/client';

const Contact = () => {
  const [submitUpdateLanding] = useMutation(SUBMIT_UPDATE_LANDING, {
    onCompleted({ submitUpdateLanding }) {
      console.log(submitUpdateLanding.response);
    },
    onError(e) {
      console.log(e);
    },
  });
  const [updateEmailLanding] = useMutation(UPDATE_EMAIL_LANDING, {
    onCompleted({ updateEmailLanding }) {
      console.log(updateEmailLanding.response);
      if (updateEmailLanding.statusCode === 200) {
        alert('¡Muchas gracias por tu respuesta!');
        window.location.replace(
          'https://coru.com/?utm_source=HOOKS&utm_medium=PROGRAMAS&utm_campaign=HOOK_MVP',
        );
      }
    },
    onError(e) {
      console.log('error mongo: ', e.message);
    },
  });

  const [state, setState] = useState({
    step: 'C1',
    name: sessionStorage.getItem('name') || '',
    email: sessionStorage.getItem('email') || '',
    phone: sessionStorage.getItem('phone') || '',
  });

  const next = () => {
    setState({ step: 'C2' });
  };

  const submitAnswers = (e) => {
    e.preventDefault();
    // Llamado desde Step 4
    const { phone, name } = state;
    const inputInsert = {
      input: {
        answers: sessionStorage.getItem('answers') || '',
        name,
        phone,
        interests: sessionStorage.getItem('interests') || '',
        id: sessionStorage.getItem('id_mvp'),
        position: sessionStorage.getItem('option_mvp') || '',
      },
    };
    // Send to backend
    console.log(JSON.stringify(inputInsert));
    submitUpdateLanding({ variables: inputInsert });
  };

  const updateEmail = () => {
    // Llamado desde Step 5
    const { email } = state;
    // Send to backend
    const phone = sessionStorage.getItem('phone') || state.phone;
    updateEmailLanding({ variables: { phone, email } });
  };
  const handleStepChange = (e) => {
    submitAnswers(e);
    next();
  };
  const updateField = (e, field) => {
    const { value } = e.target;
    setState({ ...state, [field]: value });
  };

  const handleFormChange = (e, field) => {
    const { value } = e.target;
    sessionStorage.setItem(field, value);
    setState({ ...state, [field]: value });
    updateField(e, field);
    switch (e.target.name) {
      case 'phone':
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      case 'name':
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
      case 'email':
        ValidEmail(e.target.id, e.target.value);
        break;
      default:
        console.log('campo');
        break;
    }
  };

  const [error] = useState({
    phone: false,
    name: false,
    email: true,
  });
  const [texterror] = useState({
    phone: '',
    name: '',
    email: '',
  });
  const ValidPhone = (name, value, id) => {
    setState({
      ...state,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    console.log(id);
    if (tel === null) {
      console.log('null');
    } else if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };

  const ValidLetras = (nombre, valor, id) => {
    setState({
      ...state,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });

    const element = document.getElementById(id);
    if (element === null) {
      console.log('null');
    } else {
      if (valor.length > 2) {
        element.className = 'textInput inputs-bottom validInput';
        error[`${nombre}valid`] = 0;
        error[nombre] = false;
        texterror[nombre] = '';
      } else if (nombre === 'secondname') {
        error[nombre] = false;
        texterror[nombre] = '';
        error[`${nombre}valid`] = -1;
      } else {
        element.className = 'textInput inputs-bottom invalidInput';
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = 'Debe ingresar más de 3 caracteres';
      }
    }
  };
  const ValidEmail = (id, valueEmail) => {
    setState({
      ...state,
      email: valueEmail.toLowerCase(),
    });
    let domain = valueEmail.split('@');
    domain = domain[1];
    const idmail = document.getElementById(id);
    if (idmail === null || valueEmail === '') {
      console.log('null');
    } else if (
      valueEmail
      && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
    } else if (
      domain !== ''
      && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
    } else {
      idmail.className = 'textInput inputs-bottom validInput';
      texterror.email = '';
      error.email = false;
    }
  };

  // Integrar en state el step siguiente y submit

  return (
    <Fragment>
      <TrainingMain>
        <TrainingSectionGray>
          <TrainingDivSection2 paddi="5% 5% 2% 5%" back="#fafafa">
            {state.step === 'C1' && (
              <>
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  textbold=""
                  text="¡Perfecto! Uno de los primeros éxitos financieros es lograr
              equilibrar tus gastos e ingresos para sobrevivir sin
              problemas, y además ¡ahorrar un poco de dinero!"
                  text2="¿Quién eres?"
                  hora=""
                />
                <form>
                  <TextField
                    id="name"
                    name="name"
                    label="Nombre"
                    autoComplete="off"
                    required
                    fullWidth
                    className="textInput inputs-bottom"
                    value={state.name}
                    onChange={(e) => handleFormChange(e, 'name')}
                  />
                  <TextField
                    id="phone"
                    name="phone"
                    label="Tu teléfono (10 dígitos):"
                    autoComplete="off"
                    required
                    fullWidth
                    className="textInput inputs-bottom"
                    value={state.phone}
                    inputProps={{
                      maxLength: 10,
                      pattern: '[0-9]',
                    }}
                    onChange={(e) => handleFormChange(e, 'phone')}
                  />
                  <br />
                  <br />
                  <div className="div-center">
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      onClick={(e) => handleStepChange(e)}
                    >
                      Continuar
                    </CardsBtn>
                  </div>
                </form>
              </>
            )}
            {state.step === 'C2' && (
              <>
                <br />
                <TrainingSubtitles fw="600" alignweb="center">
                  ¡Comenzaremos muy pronto!
                </TrainingSubtitles>
                <div className="contact-text text-first">
                  Y para eso, queremos darte la mejor experiencia.
                </div>
                <div className="contact-text">
                  Un líder del Equipo Coru te buscará para saber cómo atenderte
                  mejor y conocer todas tus expectativas.
                </div>
                <div className="contact-text">
                  Coru es una fintech con más de 5 años de experiencia, experta
                  en entrenamiento financiero para encontrar las mejores
                  opciones en:
                </div>
                <ul className="contact-list">
                  <li>Ahorro y presupuesto</li>
                  <li>Crédito</li>
                  <li>Inversión</li>
                  <li>Seguros</li>
                  <li>Pago de deudas y cuidado de tu dinero</li>
                </ul>
                <div className="contact-text">
                  ¡Atención! Lanzaremos esta serie de entrenamientos muy pronto.
                </div>
                <div className="contact-text">
                  <b>¡Sé el primero en recibirlos!</b>
                </div>

                <form>
                  <TextField
                    id="email"
                    name="email"
                    label="Ingresa tu email"
                    autoComplete="off"
                    required
                    fullWidth
                    className="textInput inputs-bottom"
                    value={state.email}
                    error={error.email}
                    helperText={texterror.email}
                    onChange={(e) => handleFormChange(e, 'email')}
                  />
                  <br />
                  <br />
                  <div className="div-center">
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      onClick={
                        error.email === true ? '' : (e) => updateEmail(e)
                      }
                    >
                      Enviar
                    </CardsBtn>
                  </div>
                </form>
              </>
            )}
          </TrainingDivSection2>
        </TrainingSectionGray>
      </TrainingMain>
      <div id="irTimeDom"></div>
    </Fragment>
  );
};
export default Contact;
