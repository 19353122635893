import React from 'react';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import Carrusel from 'react-elastic-carousel';
import {
  TrainingText,
  CardsBtn,
  Resultado,
  DivStyle,
} from '../../assets/styles/Training.styled';
import ModalShareFreem from './ModalShareFreem';
import Flag from '../../assets/img/logros/Flag.svg';
import FlagB from '../../assets/img/logros/SaveBlack.svg';
import { DYNAMIC_INSERT, CHANGE_STATUS, CREATE_OPPORTUNITIES } from '../../mutations';

const ProductsPymes = ({
  idT,
  products = [],
  text = false,
  currentTraining,
}) => {

  const selectProduct = (idProduct, clickOut) => {
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1,
        idProducts: Number(idProduct),
        idTraining: 42,
      },
    };
    console.log('Datos', opportunityDetailInput);
     createOpportunitiesUser({ variables: opportunityDetailInput });
    window.open(clickOut, '_blank');
  };

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        // console.log("Oportunidad",response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    },
    onError(err) {
      console.log('Error Create Oportunitie', err);
    },
  });

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('INSERCION EXITOSA!');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  const [changeStatus] = useMutation(CHANGE_STATUS, {
    onCompleted({ changeStatus }) {
      if (changeStatus.message === 'success') {
        console.log(changeStatus);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const toggleProduct = (idProduct, index) => {
    /**
     * 0 si existe pero esta apagado
     * 1 si existe esta prendido
     * 2 no existe
     */

    const inputUpdateStatus = {
      input: {
        table: 'DatCardSaving',
        columns: [
          {
            setkey: '`status`',
            setval: 0,
          },
        ],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            valtwo: sessionStorage.getItem('idUser'),
            condition: '=',
            logic: '',
          },
          {
            logic: 'AND',
            valone: 'CatProduct_IdProduct',
            condition: '=',
            valtwo: String(idProduct),
          },
        ],
      },
    };

    if (products[index].statusSaving === 0) {
      inputUpdateStatus.input.columns[0].setval = 1;
      changeStatus({ variables: inputUpdateStatus });
      products[index].statusSaving = 1;
    } else if (products[index].statusSaving === 1) {
      inputUpdateStatus.input.columns[0].setval = 0;
      products[index].statusSaving = 0;
      changeStatus({ variables: inputUpdateStatus });
    } else if (products[index].statusSaving === 2) {
      inputUpdateStatus.input.columns[0].setval = 1;
      dynamicInsert({
        variables: {
          input: {
            table: 'DatCardSaving',
            columns: ['CatProduct_IdProduct', 'CatUsers_IDUser', 'status', 'idTraining'],
            detailValues: {
              values: [String(idProduct), String(sessionStorage.getItem('idUser')), 'true', String(sessionStorage.getItem('idTraining'))],
            },
          },
        },
      });
    }

    const now = document.getElementsByClassName(`flag-${idProduct}`)[0].src;

    if (now.includes('SaveBlack')) {
      document.getElementsByClassName(`flag-${idProduct}`)[0].src = Flag;
      document.getElementsByClassName(`flag-${idProduct}`)[0].style.marginRight = '';
    } else {
      document.getElementsByClassName(`flag-${idProduct}`)[0].src = FlagB;
      document.getElementsByClassName(`flag-${idProduct}`)[0].style.marginRight = '4px';
    }
  };

  const validFlag = (value) => {
    if (value === 'true' || value === '1' || value === true) {
      return true;
    } if (value === 'false' || value === '0' || value === false) {
      return false;
    }
    return false;
  };

  return (
    <>
      <div id="CarruselEmpresasContent">
        <Carrusel styled={{ background: '#FAFAFA' }}>
          {products.map((product, index) => <Resultado key={product.IDProduct} bcolor="#fff" ta="left" marri="10px" marriweb="auto" marri=" " border="none" hei="430px" minh="453px" minhweb="auto" key={index} >
              <div className="row" style={{ margin: 'auto', width: '100%' }}>
                <div className="col-6" style={{ marginTop: '20px' }}>
                  <img src={product.ImageURL} style={{ maxHeight: '50px', width: 'auto', margin: 'auto' }} />
                </div>
                <div className="col-6" >
                  <div className="row" style={{ justifyContent: 'flex-end', marginRight: '5px' }}>
                    <img src={validFlag(product.statusSaving) ? FlagB : Flag} style={{ cursor: 'pointer' }} className={`flag-${product.IDProduct}`} onClick={() => toggleProduct(product.IDProduct, index)} />
                    <ModalShareFreem />
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  </div>
                </div>

                <DivStyle className="cabin" weight="600" align="left" line="1.5em" padd="12px 5% 0 5%" color="#07210A">
                  <TrainingText size="16px" sizew="16px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                    {product.Name}
                  </TrainingText>
                </DivStyle>

                <div className="col-12" style={{ height: '230px' }}>
                  <TrainingText size="14px" sizew="14px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                    Beneficios
                  </TrainingText>
                  <ul style={{ paddingLeft: '18px' }}>
                    <li style={{ marginBottom: '10px' }}>Plazo: {product.Plazo}</li>

                    <li style={{ marginBottom: '10px' }}>{product.ProductCategory === 'Básica' ? 'Sin garantía' : ''}</li>

                    <li style={{ marginBottom: '10px' }}>Tipo de crédito: {product.TipoCredito}</li>

                    <li style={{ marginBottom: '10px' }}>Rango de crédito: {product.ProductCategory}</li>

                    <li> Sin tasa fija </li>
                  </ul>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '7px', margiLeft: '20px' }}>
                <CardsBtn
                  type="button"
                  windthG="88%"
                  mleft="100px"
                  mtop='0px'
                  mtopw='0px'
                  onClick={() => selectProduct(product.IDProduct, product.ClickOut)}
                >
                  Continuar
                </CardsBtn>
              </div>
            </Resultado>)}
        </Carrusel>
      </div>
    </>
  );
};
export default ProductsPymes;
