import React, { useState, useEffect, Fragment } from 'react';
import Carousel from 'react-elastic-carousel';
import { Div_Flex } from '../../assets/styles/Home.styled';
import { Shapes } from '../common/Home.styled';
import { Container } from '../common/common.styled';
// Empresas nuevo
import aig from '../../assets/img/home/Clientes/NewClientes/aig.svg';
import american from '../../assets/img/home/Clientes/NewClientes/americanexpress.svg';
import ana from '../../assets/img/home/Clientes/NewClientes/ana.svg';
import bancoazteca from '../../assets/img/home/Clientes/NewClientes/bancoazteca.svg';
import banorte from '../../assets/img/home/Clientes/NewClientes/banorte.svg';
import bbva from '../../assets/img/home/Clientes/NewClientes/bbva.svg';
import briq from '../../assets/img/home/Clientes/NewClientes/briq.svg';
import chubb from '../../assets/img/home/Clientes/NewClientes/chubb.svg';
import citibanamex from '../../assets/img/home/Clientes/NewClientes/citibanamex.svg';
import creditea from '../../assets/img/home/Clientes/NewClientes/creditea.svg';
import flink from '../../assets/img/home/Clientes/NewClientes/flink.svg';
import gnp from '../../assets/img/home/Clientes/NewClientes/gnp.svg';
import hdi from '../../assets/img/home/Clientes/NewClientes/hdi.svg';

import gmb from '../../assets/img/home/Clientes/NewClientes/gmb.svg';
import hsbc from '../../assets/img/home/Clientes/NewClientes/hsbc.svg';
import invex from '../../assets/img/home/Clientes/NewClientes/invex.svg';
import kavak from '../../assets/img/home/Clientes/NewClientes/kavak.svg';
import klar from '../../assets/img/home/Clientes/NewClientes/klar.svg';
import kueski from '../../assets/img/home/Clientes/NewClientes/kueski.svg';
import liverpool from '../../assets/img/home/Clientes/NewClientes/liverpool.svg';
import mapfre from '../../assets/img/home/Clientes/NewClientes/mapfre.svg';
import nubank from '../../assets/img/home/Clientes/NewClientes/NewNu.svg';
import palacio from '../../assets/img/home/Clientes/NewClientes/palacio.svg';
import qualitas from '../../assets/img/home/Clientes/NewClientes/qualitas.svg';
import rappipay from '../../assets/img/home/Clientes/NewClientes/rappiCard.svg';
import resuelve from '../../assets/img/home/Clientes/NewClientes/ResuelveTuDeuda.svg';
import scotiabank from '../../assets/img/home/Clientes/NewClientes/scotiabank.svg';

import arkangeles from '../../assets/img/home/Clientes/NewClientes/arkangeles.svg';
import credijusto from '../../assets/img/home/Clientes/NewClientes/credijusto.svg';
import credilikeme from '../../assets/img/home/Clientes/NewClientes/credilikeme.svg';
import creditas from '../../assets/img/home/Clientes/NewClientes/creditas.svg';
import creditofamiliar from '../../assets/img/home/Clientes/NewClientes/creditofamiliar.svg';
import curadeuda from '../../assets/img/home/Clientes/NewClientes/curadeuda.svg';
import dinn from '../../assets/img/home/Clientes/NewClientes/dinn.svg';
import escampa from '../../assets/img/home/Clientes/NewClientes/escampa.png';
import falabella from '../../assets/img/home/Clientes/NewClientes/falabella.svg';
import inverspot from '../../assets/img/home/Clientes/NewClientes/inverspot.svg';
import mangolife from '../../assets/img/home/Clientes/NewClientes/mangolife.svg';
import quetarjeta from '../../assets/img/home/Clientes/NewClientes/quetarjeta.svg';
import story from '../../assets/img/home/Clientes/NewClientes/story.svg';

import atlas from '../../assets/img/home/Clientes/NewClientes/atlas.svg';
import fundary from '../../assets/img/home/Clientes/NewClientes/fundary.svg';
import latasa from '../../assets/img/home/Clientes/NewClientes/latasa.svg';
import moneyman from '../../assets/img/home/Clientes/NewClientes/moneyman.svg';
import monific from '../../assets/img/home/Clientes/NewClientes/monific.svg';
import networth from '../../assets/img/home/Clientes/NewClientes/networth.svg';
import prestadero from '../../assets/img/home/Clientes/NewClientes/prestadero.svg';
import prestanomico from '../../assets/img/home/Clientes/NewClientes/prestanomico.svg';
import rebajatuscuentas from '../../assets/img/home/Clientes/NewClientes/rebajatuscuentas.svg';
import superr from '../../assets/img/home/Clientes/NewClientes/super.svg';
import tala from '../../assets/img/home/Clientes/NewClientes/tala.svg';
import vexi from '../../assets/img/home/Clientes/NewClientes/vexi.svg';
import yotepresto from '../../assets/img/home/Clientes/NewClientes/yotepresto2.png';
import zendala from '../../assets/img/home/Clientes/NewClientes/zendala.svg';
import mozper from '../../assets/img/home/Clientes/NewClientes/mozper.svg';

import montePiedad from '../../assets/img/home/Clientes/NewClientes/montePiedad.png';
import oyster from '../../assets/img/home/Clientes/NewClientes/oyster.svg';
import digitt from '../../assets/img/home/Clientes/NewClientes/digitt.png';
import uniclick from '../../assets/img/home/Clientes/NewClientes/uniclick.svg';
import savenest from '../../assets/img/home/Clientes/NewClientes/savenest.svg';
import baubap from '../../assets/img/home/Clientes/NewClientes/baubap.png';
import doopla from '../../assets/img/home/Clientes/NewClientes/doopla.svg';
import clara from '../../assets/img/home/Clientes/NewClientes/Clara.png';

function CarouselEmpresas({ initializeNow, initializeLogin }) {
  const [widthBrowser, setwidthBrowser] = useState(window.outerWidth);
  useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);

  return (
    <Fragment>
      {widthBrowser <= 700 ? (
        <Carousel
          id="CarruselEmpresa"
          styled={{ background: '#FAFAFA' }}
          transitionMs={1000}
        >
          <Shapes
            id="Empresa1"
            key={1}
            mtopD="7%"
            heightD="350px"
            height="350px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="0px"
              widthMobile="100%"
              mb="0px"
              mtMobile="auto"
              mbMobile="auto"
              class="d-flex flex-column"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50.41px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={aig}
                  style={{
                    width: '55px',
                    height: '28px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={american}
                  style={{ width: '77px', height: '25px', margin: 'auto' }}
                />
                <img
                  src={ana}
                  style={{ width: '47px', height: '30px', margin: 'auto' }}
                />
                <img
                  src={bancoazteca}
                  style={{
                    width: '85px',
                    height: '36px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50.41px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={banorte}
                  style={{
                    width: '171px',
                    height: '16px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={bbva}
                  style={{ width: '71px', height: '21px', margin: 'auto' }}
                />
                <img
                  src={hdi}
                  style={{
                    width: '43px',
                    height: '33px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50.41px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={briq}
                  style={{
                    width: '85px',
                    height: '25px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={chubb}
                  style={{ width: '109px', height: '11px', margin: 'auto' }}
                />
                <img
                  src={creditea}
                  style={{
                    width: '80px',
                    height: '25px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50.41px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={citibanamex}
                  style={{
                    width: '115px',
                    height: '32px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={flink}
                  style={{ width: '75px', height: '37px', margin: 'auto' }}
                />
                <img
                  src={gnp}
                  style={{
                    width: '66px',
                    height: '30px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
          <Shapes
            id="Empresa2"
            key={2}
            mtopD="7%"
            heightD="350px"
            height="350px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={hsbc}
                  style={{
                    width: '92px',
                    height: '23px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={invex}
                  style={{ width: '98px', height: '18px', margin: 'auto' }}
                />
                <img
                  src={kueski}
                  style={{
                    width: '96px',
                    height: '42px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>

              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={kavak}
                  style={{
                    width: '61px',
                    height: '16px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={liverpool}
                  style={{ width: '106px', height: '23px', margin: 'auto' }}
                />
                <img
                  src={qualitas}
                  style={{
                    width: '35px',
                    height: '44px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '25px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={nubank}
                  style={{
                    width: '37px',
                    height: '34px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={mapfre}
                  style={{ width: '59px', height: '19px', margin: 'auto' }}
                />
                <img
                  src={klar}
                  style={{ width: '42px', height: '17px', margin: 'auto' }}
                />
                <img
                  src={palacio}
                  style={{
                    width: '100.18px',
                    height: '29.1px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={resuelve}
                  style={{
                    width: '100px',
                    height: '40px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={scotiabank}
                  style={{ width: '121px', height: '21px', margin: 'auto' }}
                />
                <img
                  src={rappipay}
                  style={{
                    width: '78px',
                    height: '78px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
          <Shapes
            id="Empresa3"
            key={3}
            mtopD="7%"
            heightD="350px"
            height="350px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '60px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={story}
                  style={{
                    width: '80px',
                    height: '24px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={arkangeles}
                  style={{ width: '108px', height: '10px', margin: 'auto' }}
                />
                <img
                  src={dinn}
                  style={{
                    width: '68px',
                    height: '22px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '60px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={credijusto}
                  style={{
                    width: '106px',
                    height: '28px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={curadeuda}
                  style={{ width: '93px', height: '24px', margin: 'auto' }}
                />
                <img
                  src={credilikeme}
                  style={{
                    width: '99px',
                    height: '30px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={falabella}
                  style={{
                    width: '82px',
                    height: '32px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={creditas}
                  style={{ width: '84px', height: '20px', margin: 'auto' }}
                />
                <img
                  src={creditofamiliar}
                  style={{
                    width: '64px',
                    height: '41px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={escampa}
                  style={{
                    width: '80px',
                    height: '37px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={inverspot}
                  style={{ width: '86px', height: '23px', margin: 'auto' }}
                />
                <img
                  src={mangolife}
                  style={{
                    width: '89px',
                    height: '32px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
          <Shapes
            id="Empresa4"
            key={4}
            mtopD="7%"
            heightD="350px"
            height="350px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={prestanomico}
                  style={{
                    width: '108px',
                    height: '23px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={networth}
                  style={{ width: '70px', height: '20px', margin: 'auto' }}
                />
                <img
                  src={superr}
                  style={{
                    width: '75px',
                    height: '22px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '40px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={yotepresto}
                  style={{
                    width: '125px',
                    height: '30px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={moneyman}
                  style={{ width: '94px', height: '47px', margin: 'auto' }}
                />
                <img
                  src={atlas}
                  style={{
                    width: '104px',
                    height: '47px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '40px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={latasa}
                  style={{
                    width: '48px',
                    height: '39px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={prestadero}
                  style={{ width: '85px', height: '40px', margin: 'auto' }}
                />
                <img
                  src={monific}
                  style={{
                    width: '80px',
                    height: '22px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={fundary}
                  style={{
                    width: '46px',
                    height: '46px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={vexi}
                  style={{ width: '53px', height: '27px', margin: 'auto' }}
                />
                <img
                  src={rebajatuscuentas}
                  style={{
                    width: '125px',
                    height: '21px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
          <Shapes
            id="Empresa5"
            key={5}
            mtopD="7%"
            heightD="350px"
            height="350px"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="90%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '40px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={tala}
                  style={{
                    width: '70px',
                    height: '25px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={doopla}
                  style={{ width: '104px', height: '25px', margin: 'auto' }}
                />
                <img
                  src={gmb}
                  style={{
                    width: '72px',
                    height: '15px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '40px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={montePiedad}
                  style={{
                    width: '108px',
                    height: '41px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={zendala}
                  style={{ width: '100px', height: '32px', margin: 'auto' }}
                />
                <img
                  src={savenest}
                  style={{
                    width: '115px',
                    height: '27px',
                    margin: 'auto',
                    marginRight: '-28px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '40px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={baubap}
                  style={{ margin: 'auto', marginLeft: '0px', width: '100px' }}
                />
                <img
                  src={digitt}
                  style={{ width: '70px', height: '25px', margin: 'auto' }}
                />
                <img
                  src={uniclick}
                  style={{
                    width: '106px',
                    height: '21px',
                    margin: 'auto',
                    marginRight: '0px',
                  }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '46px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={quetarjeta}
                  style={{
                    width: '70px',
                    height: '43px',
                    margin: 'auto',
                    marginLeft: '0px',
                  }}
                />
                <img
                  src={oyster}
                  style={{ width: '80px', height: '25px', margin: 'auto' }}
                />
                <img src={clara} style={{ margin: 'auto', width: '80px' }} />
                <img
                  src={mozper}
                  style={{ margin: 'auto', width: '60px', marginRight: '0px' }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
        </Carousel>
      ) : (
        <Carousel
          id="CarruselEmpresaWeb"
          styled={{ background: '#FAFAFA' }}
          transitionMs={1000}
        >
          <Shapes
            id="Empresa1"
            key={1}
            mtopD="0%"
            heightD="auto"
            height="auto"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="100%"
              mt="0px"
              widthMobile="100%"
              mb="0px"
              mtMobile="auto"
              mbMobile="auto"
              class="d-flex flex-column"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img src={aig} style={{ margin: 'auto', marginLeft: '0px' }} />
                <img src={american} style={{ margin: 'auto' }} />
                <img src={ana} style={{ margin: 'auto' }} />
                <img src={bancoazteca} style={{ margin: 'auto' }} />
                <img src={banorte} style={{ margin: 'auto' }} />
                <img src={bbva} style={{ margin: 'auto' }} />
                <img
                  src={chubb}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={creditea}
                  style={{ margin: 'auto', marginLeft: '0px' }}
                />
                <img src={citibanamex} style={{ margin: 'auto' }} />
                <img src={gnp} style={{ margin: 'auto' }} />
                <img src={hsbc} style={{ margin: 'auto' }} />
                <img src={hdi} style={{ margin: 'auto' }} />
                <img src={invex} style={{ margin: 'auto' }} />
                <img
                  src={qualitas}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '0px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={liverpool}
                  style={{ margin: 'auto', marginLeft: '0px' }}
                />
                <img src={kueski} style={{ margin: 'auto' }} />
                <img src={kavak} style={{ margin: 'auto' }} />
                <img src={mapfre} style={{ margin: 'auto' }} />
                <img src={palacio} style={{ margin: 'auto' }} />
                <img src={scotiabank} style={{ margin: 'auto' }} />
                <img
                  src={rappipay}
                  style={{ margin: 'auto', marginRight: '0px', width: '95px' }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
          <Shapes
            id="Empresa2"
            key={2}
            mtopD="0%"
            heightD="auto"
            height="auto"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="100%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={briq}
                  style={{ margin: 'auto', marginLeft: '0px', width: '102px' }}
                />
                <img src={gmb} style={{ margin: 'auto' }} />
                <img src={nubank} style={{ margin: 'auto', width: '44px' }} />
                <img
                  src={resuelve}
                  style={{ margin: 'auto', width: '102px' }}
                />
                <img src={creditas} style={{ margin: 'auto' }} />
                <img
                  src={klar}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={story}
                  style={{ margin: 'auto', marginLeft: '0px' }}
                />
                <img src={arkangeles} style={{ margin: 'auto' }} />
                <img src={dinn} style={{ margin: 'auto' }} />
                <img
                  src={credijusto}
                  style={{ margin: 'auto', width: '100px' }}
                />
                <img src={credilikeme} style={{ margin: 'auto' }} />
                <img src={falabella} style={{ margin: 'auto' }} />
                <img
                  src={creditofamiliar}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={escampa}
                  style={{ margin: 'auto', marginLeft: '0px', width: '102px' }}
                />
                <img src={quetarjeta} style={{ margin: 'auto' }} />
                <img src={prestanomico} style={{ margin: 'auto' }} />
                <img src={networth} style={{ margin: 'auto' }} />
                <img src={atlas} style={{ margin: 'auto' }} />
                <img
                  src={yotepresto}
                  style={{ margin: 'auto', width: '112px' }}
                />
                <img
                  src={moneyman}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
          <Shapes
            id="Empresa3"
            key={3}
            mtopD="0%"
            heightD="auto"
            height="auto"
            width="100%"
            position=" "
            displayD="flex"
            displayM="flex"
          >
            <Container
              width="100%"
              mt="auto"
              widthMobile="100%"
              mb="auto"
              mtMobile="auto"
            >
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={superr}
                  style={{ margin: 'auto', marginLeft: '0px' }}
                />
                <img src={latasa} style={{ margin: 'auto', width: '55px' }} />
                <img src={prestadero} style={{ margin: 'auto' }} />
                <img src={tala} style={{ margin: 'auto' }} />
                <img src={fundary} style={{ margin: 'auto' }} />
                <img
                  src={mozper}
                  style={{ margin: 'auto', minWidth: '45px' }}
                />
                <img src={zendala} style={{ margin: 'auto' }} />
                <img
                  src={rebajatuscuentas}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={montePiedad}
                  style={{ margin: 'auto', marginLeft: '0px', width: '110px' }}
                />
                <img src={vexi} style={{ margin: 'auto' }} />
                <img src={oyster} style={{ margin: 'auto', width: '90px' }} />
                <img src={digitt} style={{ margin: 'auto', width: '80px' }} />
                <img
                  src={uniclick}
                  style={{ margin: 'auto', width: '110px' }}
                />
                <img
                  src={savenest}
                  style={{ margin: 'auto', width: '110px' }}
                />
                <img
                  src={baubap}
                  style={{ margin: 'auto', marginRight: '0px', width: '100px' }}
                />
              </Div_Flex>
              <Div_Flex
                width="100%"
                margin=" "
                height="auto"
                style={{
                  width: '100%',
                  marginBottom: '50px',
                  marginLeft: '0px',
                }}
              >
                <img
                  src={mangolife}
                  style={{ margin: 'auto', marginLeft: '0px' }}
                />
                <img src={flink} style={{ margin: 'auto' }} />
                <img src={curadeuda} style={{ margin: 'auto' }} />
                <img src={inverspot} style={{ margin: 'auto' }} />
                <img src={doopla} style={{ margin: 'auto', width: '100px' }} />
                <img src={clara} style={{ margin: 'auto', width: '100px' }} />
                <img
                  src={monific}
                  style={{ margin: 'auto', marginRight: '0px' }}
                />
              </Div_Flex>
            </Container>
          </Shapes>
        </Carousel>
      )}
    </Fragment>
  );
}

export default CarouselEmpresas;
