import React, { useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
import "../assets/styles/kanda/KandaDesktop.css";
import { Modal } from "@material-ui/core";
import GreyForm from "../assets/img/GreyForm.svg";
import WhiteForm from "../assets/img/WhiteForm.svg";
import GabyAg from "../assets/img/GabyAg.png";
import Person from "../assets/img/Img_Header_CMC.png";
import GifCel from "../assets/img/Coru-gifwebvideos-04-11-22.gif";
import RandomGiff from "../assets/img/RandomGif.gif";
import Arrow from "../assets/img/SPLIT_DOWN.svg";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/anacoach/CoruLogoB.png";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import InputAna from "../components/common/InputAna";
import ClickButton from "../Helpers/HookHelpers";
import { Helmet } from "react-helmet";

const Container1 = styled.div`
  width: 100%;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const TitleBlue = styled.div`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => props.fontS || "62px"};
  line-height: 120%;
  align-items: center;
  text-align: right;
  letter-spacing: -0.3px;
  color: #393a80;
  margin: ${(props) => props.marginD || ""};
  @media (max-width: 770px) {
    font-size: ${(props) => props.fontSD || "35px"};
  }
`;

const TextGray = styled.div`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #595959;

  @media (max-width: 770px) {
    display: block;
    font-size: 24px;
    text-align:center};
  }
`;

const ContainerArrows = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 349px;
  height: fit-content;
  margin-top: 100px;
  @media (min-width: 770px) {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 25%;
    margin-top: 15px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 75%;
  }
`;

const PersonIcon = styled.img`
    z-index: -2;
    width: 33%;
    height: 33%;
    max-width: 413px;
    position: relative;
    top:80px;
    @media (max-width: 800px) {
        top: 80px;
        margin-left:-30px;
        width: 50%;
        height: 50%;
      }
      @media (max-width: 500px ) {
        top: 200px;
        margin-left:-30px;
        width: 50%;
        height: 50%;
      }
      @media (min-width: 1500px ) {
        top: 6vw;
        margin-left:-30px;
        width: 50%;
        height: 50%;
      }
  }
`;

const RandomGif = styled.img`
    z-index: -2;
    max-width: 100px;
    position: absolute;
    top:180px;
    left:20%;
      @media (max-width: 500px ) {
        top: 300px;
        max-width: 70px;
      }
      @media (min-width: 1130px ) {
        top:220px;
          left: 30%;
        }
        @media (min-width: 1500px ) {
          top: 16vw;
          left: 36%;
        }
  }
`;

const ModalCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 21px;
  gap: 24px;
  position: absolute;
  width: 100%;
  max-width: 520px;
  min-width: 300px;
  background: #ffffff;
  border-radius: 16px;
  margin: auto;
`;
const Select = styled.select`
  border: 1px solid #cdcdcd;
  color: #595959;
  border-radius: 16px;
  width: 100%;
  height: 56px;
  padding: 0px 24px;
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  background: url(${Arrow}) right 24px center no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:valid {
    color: #070b11;
  }
`;

const Header = ({ handleClick }) => (
  <div style={{ height: "85px", display: "flex" }}>
    <ContLogo>
      <Image
        cursor="pointer"
        src={ImageLogo}
        width="120px"
        mwidth="97.92px"
        heightMobile="20.8px"
        alt="logo-coru"
      />
    </ContLogo>
    <ContLogo2>
      <ContentGeneric width="40%" widthMd="10%">
        <KandaButton
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
          action={() => handleClick("Ingresar")}
        >
          Ingresar
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="60%" widthMd="15%">
        <KandaButton
          mr="96px"
          width="100%"
          typeBtn="active"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "48px",
            padding: "13 0",
          }}
          action={() => handleClick("CrearCuenta")}
        >
          Crear Cuenta
        </KandaButton>
      </ContentGeneric>
    </ContLogo2>
  </div>
);

function LPMasterClass() {
  const [modal, setModal] = useState(false);
  const { actions } = ClickButton();
  const [modalForm, setModalForm] = useState();

  const handleChange = (e) => {
    setModalForm({
      ...modalForm,
      [e.target.name]: String(e.target.value).toLowerCase(),
    });
  };

  const handleClick = (name) => {
    switch (name) {
      case "Ingresar":
        actions({
          action: "ClickButtonLpCMC",
          variables: "ClickButtoningresoLpCMC",
          redirection: "/login-v2",
          href: true,
        });
        break;
      case "CrearCuenta":
        actions({
          action: "ClickButtonLpCMC",
          variables: "ClickButtonCrearCuentaLpCMC",
          redirection: `/personal-Information-v2`,
          href: true,
        });
        break;
      case "QuieroUnaMC":
        actions({
          action: "ClickButtonLpCMC",
          variables: "ClickButtonQuieroUnaMC",
        });
        break;
      case "MeInteresaMC":
        actions({
          action: "ClickButtonLpCMC",
          variables: "ClickButtonMeInteresaMC",
        });
        break;
      case "EnviarMC":
        actions({
          action: "LogDataLPCMC",
          variables: modalForm,
        });
        setModal(false);
        break;
      default:
        break;
    }
  };

  const URLactual = window.location.hostname;
  let isProd = false;
  if (URLactual === 'coru.com') {
    isProd = true;
  } else {
    isProd = false;
  }
  return (
    <>
      <div style={{ overflow: "hidden", height: "100%", position: "relative" }}>
        <div
          style={{
            background:
              "linear-gradient( 180deg,rgba(255, 154, 154, 0.2) 40.9%,rgba(238, 238, 238, 0) 100%)",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "-2",
          }}
        ></div>
        <Container1>
        <Helmet>
          <title>{isProd ? 'Clase de finanzas personales: coaching financiero | CORU' : 'Clase de finanzas personales: coaching financiero'}</title>
          <meta
            name="description"
            content="Entra ahora y aprende de nuestras clases de finanzas personales. Experiencia y consejos de expertos financieros para que tomes mejores decisiones con tu dinero."
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
          <Header handleClick={handleClick} />
          <div
            style={{
              maxWidth: "1056px",
              display: "flex",
              flexDirection: "column",
              justifySelf: "center",
            }}
          >
            <div style={{ display: "flex", flex: "2 3", marginBottom: "100px" }}>
              <PersonIcon alt="" src={Person} />
              <RandomGif alt="" src={RandomGiff} />
              <div>
                <TitleBlue marginD="100px 0 0 0" style={{ fontSize: "24px" }}>
                  Aprende en Coru Master Class{" "}
                </TitleBlue>
                <TitleBlue>
                  Los Expertos Financieros te comparten su experiencia y consejos
                </TitleBlue>
              </div>
            </div>
            <ContainerArrows>
              <div style={{ maxWidth: "400px" }}>
                <TextGray>Aprende de finanzas personales</TextGray>
                <TextGray
                  style={{
                    fontSize: "32px",
                    margin: "24px 0",
                    color: " #000000",
                  }}
                >
                  Clases de temas puntuales para entender tus finanzas y sentir más
                  tranquilidad
                </TextGray>
                <TextGray style={{ marginBottom: "24px" }}>Sin costo y en línea</TextGray>
                <KandaButton
                  typeBtn="active"
                  style={{ height: "fit-content" }}
                  action={() => {
                    setModal(true);
                    handleClick("QuieroUnaMC");
                  }}
                >
                  Quiero conocer una Master Class
                </KandaButton>
              </div>
              <img
                src={GifCel}
                alt=""
                style={{
                  height: "635px",
                  objectFit: "scale-down",
                  margin: "24px 0",
                }}
              />
            </ContainerArrows>
            <TitleBlue
              style={{
                fontSize: "24px",
                textAlign: "center",
                marginTop: "60px",
              }}
            >
              Conoce a nuestra asesora financiera
            </TitleBlue>
            <ContainerArrows>
              <img alt="" src={GabyAg} />
              <div style={{ maxWidth: "290px", margin: "24px 0" }}>
                <TextGray
                  style={{
                    fontSize: "32px",

                    color: " #000000",
                  }}
                >
                  Gaby Aguirre
                </TextGray>
                <TextGray
                  style={{
                    fontSize: "16px",
                    margin: "12px 0 16px",
                  }}
                >
                  Coach de finanzas personales
                </TextGray>
                <TextGray
                  style={{
                    fontSize: "16px",
                    color: "#393A80",
                  }}
                >
                  “Cuidar de tu salud financiera, es cuidar de tu presente y tu futuro”
                </TextGray>
                <KandaButton
                  typeBtn="active"
                  style={{ height: "fit-content", marginTop: "24px" }}
                  action={() => {
                    setModal(true);
                    handleClick("MeInteresaMC");
                  }}
                >
                  Me interesa una Master Class
                </KandaButton>
              </div>
            </ContainerArrows>
          </div>
          <Footer isAboutUS="si" />
        </Container1>

        <img
          alt=""
          src={GreyForm}
          style={{
            width: "80%",
            position: "absolute",
            top: "421px",
            zIndex: "-1",
            overflow: "hidden",
          }}
        />
        <img
          alt=""
          src={WhiteForm}
          style={{
            width: "80%",
            position: "absolute",
            right: "0",
            top: "380px",
            zIndex: "-1",
            overflow: "hidden",
          }}
        />
      </div>
      <Modal open={modal} handleClick={handleClick} style={{}}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModalCont>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setModal(false);
              }}
              style={{ alignSelf: "flex-end" }}
            >
              <path
                d="M11.9999 1.20857L10.8745 0L6.41293 4.79143L1.95131 0L0.825928 1.20857L5.28754 6L0.825928 10.7914L1.95131 12L6.41293 7.20857L10.8745 12L11.9999 10.7914L7.53831 6L11.9999 1.20857Z"
                fill="#393A80"
              />
            </svg>

            <TextGray
              style={{
                fontSize: "32px",
                textAlign: "left",
                color: " #000000",
              }}
            >
              ¡Estás a un paso de obtener la Coru Master Class que buscas!
            </TextGray>
            <TextGray
              style={{
                fontSize: "16px",
                textAlign: "left",
                color: " #000000",
              }}
            >
              ¿Cuál es tu tema de interés?
            </TextGray>
            <Select required name="temaInteres" onChange={handleChange}>
              <option value="">Seleccione una opción</option>
              <option value="Domina tu tarjeta de crédito">
                Domina tu tarjeta de crédito
              </option>
              <option value="Razones para tener un coach financiero">
                Razones para tener un coach financiero
              </option>
              <option value="¿Cómo hago un presupuesto?">¿Cómo hago un presupuesto?</option>
              <option value="¿En dónde puedo invertir con poco capital?">
                ¿En dónde puedo invertir con poco capital?
              </option>
            </Select>
            <TextGray
              style={{
                fontSize: "16px",
                textAlign: "left",
                color: " #000000",
              }}
            >
              Nombre completo
            </TextGray>
            <InputAna id="nombreCompleto" handleChange={handleChange} />
            <TextGray
              style={{
                fontSize: "16px",
                textAlign: "left",
                color: " #000000",
              }}
            >
              Correo electrónico
            </TextGray>
            <InputAna id="Correo" handleChange={handleChange} />
            <KandaButton
              typeBtn={"active"}
              action={() => {
                handleClick("EnviarMC");
              }}
            >
              Enviar
            </KandaButton>
            <TextGray
              style={{
                fontSize: "12px",
                textAlign: "center",
                color: "#000000",
                fontWeight: "400",
                alignSelf: "center",
              }}
            >
              Enviaremos el enlace al correo que nos proporciones
            </TextGray>
          </ModalCont>
        </div>
      </Modal>
    </>
  );
}

export default LPMasterClass;
