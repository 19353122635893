/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { DYNAMIC_GET, CREATE_OPPORTUNITIES } from '../../mutations';
import {
  GET_PRODUCTS_OPORTUNITIES,
  RECOVERY_ROUTINE_BY_ORDER,
} from '../../queries';
import {
  DivFlex,
  DivStyle,
  DivGray,
  Resultado,
  RPProductos,
  CardsBtn,
  TrainingSubtitles,
  ImageSections,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import { SeparateSection } from '../../assets/styles/Feed.styled';
import CardTwo from '../../components/Home/Card2Animate';
import CardOne from '../../components/Home/Card1Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ClickButton from '../../Helpers/HookHelpers';

const RpFirstCard = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // end recovery
  const [Rutine] = useState({});
  const [OneCard, setOneCard] = useState(
    sessionStorage.getItem('recoveryTarjetas') || '',
  );
  let products;
  let productsDetails;
  const [isProduct, setIsProduct] = useState(false);
  const [flagProducts, setFlagProducts] = useState(false);
  const [editisProduct, seteditisProduct] = useState(OneCard !== '');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('REcovery Routines', routines);
          const contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            if (routines[idRoutine].productsUser !== undefined) {
              const arrayselectedCreditCards = routines[idRoutine].productsUser;
              const { datOpportunities } = routines[idRoutine];
              const { datProducts } = routines[idRoutine];
              let selectedCreditCards = '';
              selectedCreditCards = arrayselectedCreditCards
                .map((element) => {
                  console.log(element.nameProduct);
                  return element.nameProduct;
                })
                .join();

              if (
                datOpportunities !== ''
                && datOpportunities != null
                && datProducts !== ''
                && datProducts != null
                && selectedCreditCards !== ''
                && selectedCreditCards != null
              ) {
                sessionStorage.setItem('datOpportunity', datOpportunities);
                sessionStorage.setItem('datProducts', datProducts);
                sessionStorage.setItem('recoveryTarjetas', selectedCreditCards);
                setOneCard(selectedCreditCards);
                setIsProduct(false);
              } else {
                stopRoutine = true;
                break;
              }
            } else {
              stopRoutine = true;
              break;
            }
          }
          if (stopRoutine) {
            console.log('detente');
            setRecoveryRecoveryRoutine('activa');
            setQuestions([]);
          } else {
            setRecoveryRecoveryRoutine('activa');
            setQuestions([]);
            setOrderRutine(nextOrderRoutine);
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const [getProductsDetails] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        productsDetails = JSON.parse(dynamicGet.response);
        const details = {};
        productsDetails.forEach((x) => {
          const id = `idProduct-${x.IDProduct}`;
          if (!details.hasOwnProperty(id)) {
            details[id] = {
              detail: [],
            };
          }
          details[id].detail.push(x);
        });
        sessionStorage.setItem('Detalles2', JSON.stringify(details));
        sessionStorage.setItem('DetallesProd', dynamicGet.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  // obtenemos los productos
  const [getProductsOportunities] = useLazyQuery(GET_PRODUCTS_OPORTUNITIES, {
    onCompleted({ getProductsOportunities }) {
      console.log('Productos', getProductsOportunities);
      if (getProductsOportunities.statusCode === 200) {
        products = JSON.parse(getProductsOportunities.response);
        products = products.map((product) => {
          if (product.ClickOut.includes('[IDUser]')) {
            const newClickout = product.ClickOut.replace(
              '[IDUser]',
              sessionStorage.getItem('idUser'),
            );
            return { ...product, ClickOut: newClickout };
          }
          return product;
        });
        sessionStorage.setItem('CatProductos', JSON.stringify(products));
        const conditions = [];
        for (const i in products) {
          const conditionsTemp = {
            valone: 'IDProduct',
            condition: '=',
            valtwo: String(products[i].IDProduct),
            logic: 'OR',
          };
          conditions.push(conditionsTemp);
        }
        const productsDetailsInput = {
          input: {
            table: 'CatProductDetail',
            columns: ['*'],
            conditions,
          },
        };
        // debugger;
        getProductsDetails({ variables: productsDetailsInput });
        setIsProduct(true);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  // crea las oportinidades
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
      setOrderRutine(nextOrderRoutine);
    },
    onError(err) {
      console.log('Error Create Oportunitie', err);
    },
  });

  const insertOpportunitiesUser = (idProducts) => {
    console.log('Crea oportinidad');
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 1, // remplazar por el id del entrenamiento
        idProducts: Number(idProducts), // arreglo de numero ejemplo [10,1,3]
        idTraining: currentTraining,
      },
    };
    console.log('Datos', opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });
  };
  const obtenerProductos = () => {
    // debugger;
    if (!flagProducts) {
      const getProductsInput = {
        idUser: parseInt(sessionStorage.getItem('idUser')), // || 67,
        idCont: parseInt(sessionStorage.getItem('idCont')), // || 105,
        income: 'de_4000_a_7000',
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
      };

      getProductsOportunities({ variables: getProductsInput });
      sessionStorage.setItem('FlagProducts', false);
      setFlagProducts(true);
    } else {
      console.log('Petición enviada');
    }
  };
  useEffect(() => {
    obtenerProductos();
  }, []);
  const handleRoutineOrder = (step, prod, cantidad, tel) => {
    const click = `Aplicar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    console.log('Entro a handleRoutineOrder');
    seteditisProduct(true);
    switch (step) {
      case 2:
        if (cantidad === 'Todas') {
          const divs = document.getElementsByClassName('productoMod');
          const apply = [];
          for (let d = 0; d < 3; d++) {
            const check = divs[d].id;
            apply.push(parseInt(check));
          }
          // console.log(apply);
          sessionStorage.setItem('SelectIds', apply);
          insertOpportunitiesUser(apply);
          createJsonInput(Rutine, questions);
          setQuestions([]);
        } else {
          setOneCard('Vexi');
          sessionStorage.setItem('TDCSelect', 'Vexi');
          sessionStorage.setItem('SelectIds', prod);
          insertOpportunitiesUser(prod);
          createJsonInput(Rutine, questions);
          setQuestions([]);
        }

        break;
      default:
        break;
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeAplicoOpcion' });
  };
  const handeleIcono = () => {
    seteditisProduct(false);
    setOrderRutine(2);
  };

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <Fragment>
      {!isProduct && OneCard === '' ? (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      ) : null}
      <div id="activeAplicoOpcion"></div>
      {(OneCard === '' && isProduct) || (isProduct && !editisProduct) ? (
        <TrainingDivSection back="#FAFAFA" backw="#FAFAFA">
          <TrainingSubtitles>Estrategia 1</TrainingSubtitles>
          <DivStyle size="18px" line="1.2em">
            <b>Tarjeta de crédito</b>
            <br />
            Con VEXI puedes comprar mientras mejoras tu score
          </DivStyle>
          <SeparateSection />
          <br />
          <RPProductos scroll="none">
            {JSON.parse(sessionStorage.getItem('CatProductos'))
              .filter((product) => product.BusinessAbr === 'VEXI')
              .map((card) => (
                <Resultado
                  key={card.IDProduct}
                  minhweb="500px"
                  minh="520px"
                  marriweb="auto"
                  marri="auto"
                  border=" "
                >
                  <ImageSections
                    width="55%"
                    heightweb="auto"
                    src={card.ImageURL}
                    className="img-fluid"
                  />
                  <br />
                  <DivStyle size="15px" line="1.5em" padd="12px 0">
                    {card.Name}
                  </DivStyle>
                  <DivGray>
                    <div>
                      <DivStyle>
                        <DivStyle bott="8px" weight="bold">
                          Características
                        </DivStyle>
                        <DivFlex justify="space-between">
                          <DivStyle weight="bold">Anualidad</DivStyle>
                          {JSON.parse(card.productDetail.response).map(
                            (detalle) => {
                              if (detalle.Name === 'Anualidad') {
                                return (
                                  <div key={detalle.IDProductDetail}>
                                    ${detalle.Description}
                                  </div>
                                );
                              }
                            },
                          )}
                        </DivFlex>
                        <DivFlex justify="space-between">
                          <DivStyle weight="bold">CAT</DivStyle>
                          {JSON.parse(card.productDetail.response).map(
                            (detalle) => {
                              if (detalle.Name === 'CAT') {
                                return (
                                  <div key={detalle.IDProductDetail}>
                                    {(detalle.Description * 100).toFixed(0)}%
                                  </div>
                                );
                              }
                            },
                          )}
                        </DivFlex>
                        <DivFlex justify="space-between">
                          <DivStyle weight="bold">Tasas de interés</DivStyle>
                          {JSON.parse(card.productDetail.response).map(
                            (detalle) => {
                              if (detalle.Name === 'Interés anual') {
                                return (
                                  <div key={detalle.IDProductDetail}>
                                    {(detalle.Description * 100).toFixed(0)}%
                                  </div>
                                );
                              }
                            },
                          )}
                        </DivFlex>
                      </DivStyle>
                      <br />
                      <DivStyle align="left" mh="203px">
                        <DivStyle weight="bold" align="left">
                          Beneficios
                        </DivStyle>
                        {JSON.parse(card.productDetail.response).map(
                          (detalle) => {
                            if (detalle.Name === 'Beneficio 1') {
                              return (
                                <li key={detalle.IDProductDetail}>
                                  {detalle.Description}
                                </li>
                              );
                            }
                            if (detalle.Name === 'Beneficio 2') {
                              return (
                                <li key={detalle.IDProductDetail}>
                                  {detalle.Description}
                                </li>
                              );
                            }
                            if (detalle.Name === 'Beneficio 3') {
                              return (
                                <li key={detalle.IDProductDetail}>
                                  {detalle.Description}
                                </li>
                              );
                            }
                          },
                        )}
                      </DivStyle>
                      <div className="center">
                        <CardsBtn
                          min_w="100%"
                          onClick={() => handleRoutineOrder(2, '19', 'Una')}
                        >
                          Aplicar
                        </CardsBtn>
                      </div>
                    </div>
                  </DivGray>
                </Resultado>
              ))}
          </RPProductos>
        </TrainingDivSection>
      ) : (
        ((OneCard) => {
          if (OneCard === '') {
            return (
              <Container
                width="80%"
                mt="1rem"
                widthMobile="90%"
                mtMobile="1rem"
              >
                <ImageCoachInicio />
                <Col7 padding=" " className="cardPrincipal1">
                  <LoadingWo />
                </Col7>
              </Container>
            );
          }
        })(OneCard)
      )}
      <CardOne
        classCard={OneCard !== '' && editisProduct ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="¡Listo! ahora tengo la siguiente opción para ti."
      />
      <CardTwo
        classCard={OneCard !== '' && editisProduct ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={OneCard}
        hora={hora}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={orderRutine < 4 ? 'iconEditCommon' : 'hidden'}
          src={editIcon}
          onClick={handeleIcono}
        />
      </CardTwo>
    </Fragment>
  );
};
export default RpFirstCard;
