/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import PlayYoutube from '../../components/common/PlayYoutube';
// Import visual components
import {
  CardsBtn,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';
import ClickButton from '../../Helpers/HookHelpers';

const PerfilInvestmentCatalog = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  saveAdvance,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [idTypeQuestion, setIdTypeQuestion] = useState(0);
  const [urlVideo, setUrlVideo] = useState('');
  const { actions } = ClickButton();
  const anchop = window.screen.width;
  const [loaderWait, setLoaderWait] = useState(true);
  const [reviewScroll, setReviewScroll] = useState(true);

  const handleNextRutine = (e) => {
    const click = `ContinuarVideo1_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    sessionStorage.setItem(
      arraySessionsRutines[idTypeQuestion],
      idTypeQuestion,
    );
    setOrderRutine(nextOrderRoutine);
    saveAdvance(parseInt(currentRoutine));
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          // setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].questions;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idQuestion = arrayResponseRutine[i].detailQuestion[0].IDType;
              setIdTypeQuestion(idQuestion);
              const questionType = arrayResponseRutine[i].QuestionType;

              if (questionType === 'Video') {
                setUrlVideo(
                  arrayResponseRutine[i].detailQuestion[0].QuestionOption,
                );
                setQuestions([]);
                const allowOverwrite = sessionStorage.getItem(
                  `overwrite_WO_${currentTraining}`,
                );
                if (
                  sessionStorage.getItem(arraySessionsRutines[idQuestion])
                  && allowOverwrite !== 'true'
                ) {
                  setOrderRutine(nextOrderRoutine);
                }
              }
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (reviewScroll && orderRutine === 2) {
        setReviewScroll(false);
        gsap.to(window, { duration: 1, scrollTo: '#activeVideoInversiones' });
      }
    }, 4000);
  }, []);

  return (
    <Fragment>
      {!loaderWait || orderRutine !== 2 ? (
        <>
          <div id="activeVideoInversiones"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuemos"
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text=""
            text2="A continuación te presentaré un video con la información necesaria sobre inversiones."
          />
          <TrainingDivSection
            backw="#f8f9fa00"
            padding="0px"
            paddw="0px"
            mb="0"
            wi="90%"
            wiw="80%"
            he="auto"
            hew="auto"
            bsha="null"
          >
            <TrainingDivSection
              mlw="10%"
              ml="0px"
              mr="0px"
              bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
              mt="10px"
              br="4px"
              padding="3%"
              wi="100%"
              wiw="75%"
              he="auto"
              hew="auto"
              mb="0px"
              mtw="20px"
            >
              <div align="center">
                {urlVideo !== '' ? (
                  <PlayYoutube
                    idVideo={
                      urlVideo.includes('http')
                        ? urlVideo.split('/embed/')[1]
                        : ''
                    }
                    width={'100%'}
                    height={'190'}
                    close={false}
                  />
                ) : null}
              </div>
              <div style={{ display: 'flex' }}>
                <CardsBtn
                  className={orderRutine === currentRoutine ? '' : 'hidden'}
                  mtop="19px"
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  max_w="100%"
                  onClick={(e) => handleNextRutine(e)}
                >
                  Continuar
                </CardsBtn>
              </div>
            </TrainingDivSection>
          </TrainingDivSection>
          <SendNotificationVideo currentRoutine={currentRoutine} />
        </>
      ) : (
        <Container width="80%" mt="20px" widthMobile="90%" mtMobile="20px">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </Fragment>
  );
};

export default PerfilInvestmentCatalog;
