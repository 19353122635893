/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import SendNotificationDebts from '../../components/common/SendNotificationDebts';

const DatosDeudas = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [debtAmount, setDebtAmount] = useState(
    sessionStorage.getItem('MontoDeudas') || '',
  );
  const [latePayments, setLatePayments] = useState(
    sessionStorage.getItem('AtrasopPagos') || '',
  );
  const [latePaymentTime, setLatePaymentTime] = useState(
    sessionStorage.getItem('TiempodeRetraso') || '',
  );
  const [monthlyIncome, setMonthlyIncome] = useState(
    sessionStorage.getItem('Ingreso_Mensual_Fijo')
      && sessionStorage.getItem('TiempodeRetraso')
      ? sessionStorage.getItem('Ingreso_Mensual_Fijo')
      : '',
  );

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});
  const anchop = window.screen.width;

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { handleSendNotification } = SendNotificationDebts();
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 0:
        setDebtAmount(value);
        handleSendNotification({ step: '2' });
        setEditMontoD(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeAtrasadoPagos' });
        break;
      case 1:
        setLatePayments(value);
        setEditRetrasoD(true);
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeTiempoRetraso-Ingreso',
        });
        break;
      case 2:
        setLatePaymentTime(value);
        setEditTiempoRestraso(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeIngresoMensual' });
        if (sessionStorage.getItem('ingresoMostrar')) {
          setMonthlyIncome(sessionStorage.getItem('ingresoMostrar'));
          setEditIngMensual(true);
          setOrderRutine(nextOrderRoutine);
        } else if (sessionStorage.getItem('Ingreso_Mensual_Fijo')) {
          setMonthlyIncome(sessionStorage.getItem('Ingreso_Mensual_Fijo'));
          setEditIngMensual(true);
          setOrderRutine(nextOrderRoutine);
        }
        break;
      case 3:
        setMonthlyIncome(value);
        setEditIngMensual(true);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        // console.log('default break handleChangeCatOption case 4');
        break;
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          let contVideos = 0;
          const stopRoutine = false;

          const answers = {
            1: '',
            2: '',
            3: '',
          };

          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (questionType === 'Video') {
                contVideos++;
              }
            }

            if (answers[1] && answers[2] && answers[3] && answers[4]) {
              setOrderRutine(nextOrderRoutine);
            }

            if (
              arrayResponseRutine.length + contVideos
              < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      // setForm({...form, directionMaps: "" });
      setDebtAmount(
        sessionStorage.getItem('MontoDeudas')
          ? sessionStorage.getItem('MontoDeudas')
          : '',
      );
      setLatePayments(
        sessionStorage.getItem('AtrasopPagos')
          ? sessionStorage.getItem('AtrasopPagos')
          : '',
      );
      setLatePaymentTime(
        sessionStorage.getItem('TiempodeRetraso')
          ? sessionStorage.getItem('TiempodeRetraso')
          : '',
      );
      if (
        sessionStorage.getItem('ingresoMostrar')
        && (sessionStorage.getItem('TiempodeRetraso')
          || sessionStorage.getItem('AtrasopPagos'))
      ) {
        setMonthlyIncome(sessionStorage.getItem('ingresoMostrar'));
        setOrderRutine(nextOrderRoutine);
      } else if (
        sessionStorage.getItem('Ingreso_Mensual_Fijo')
        && (sessionStorage.getItem('TiempodeRetraso')
          || sessionStorage.getItem('AtrasopPagos'))
      ) {
        setMonthlyIncome(sessionStorage.getItem('Ingreso_Mensual_Fijo'));
        setOrderRutine(nextOrderRoutine);
      }

      if (
        sessionStorage.getItem('MontoDeudas')
        && sessionStorage.getItem('AtrasopPagos')
        && sessionStorage.getItem('TiempodeRetraso')
        && sessionStorage.getItem('Ingreso_Mensual_Fijo')
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editMontoD, setEditMontoD] = useState('');
  const [editRestrasoD, setEditRetrasoD] = useState('');
  const [editTiempoRetraso, setEditTiempoRestraso] = useState('');
  const [editIngMensual, setEditIngMensual] = useState('');

  const handleChangeIcon = (json) => {
    setEditMontoD(false);
    setEditRetrasoD(false);
    setEditTiempoRestraso(false);
    setEditIngMensual(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (debtAmount === ''
          || (lapiz[0].routine === 1 && lapiz[0].active === 1))
        && editMontoD !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeMontoDeudas' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      {/* -----------
        First question
        -------------*/}
      <div id="activeMontoDeudas"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="¿Cúal es el aproximado de tus deudas?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          (debtAmount === ''
            || (lapiz[0].routine === 1 && lapiz[0].active === 1))
          && editMontoD !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeAtrasadoPagos"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            debtAmount !== '' && latePayments === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 1 })}
        />
        <CardTwo
          classCard={
            debtAmount !== ''
            && (editMontoD
              || !(lapiz[0].routine === 1 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={debtAmount}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* -----------
        Second question
        -------------*/}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          debtAmount !== ''
          && (editMontoD
            || !(lapiz[0].routine === 1 && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Estás atrasado en tus pagos?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((latePayments === '' && debtAmount !== '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 1)
            || editMontoD)
          && editRestrasoD !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeTiempoRetraso-Ingreso"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            latePayments !== ''
            && latePaymentTime === ''
            && monthlyIncome === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 2 })}
        />
        <CardTwo
          classCard={
            latePayments !== ''
            && (editRestrasoD
              || !(lapiz[0].routine === 1 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={latePayments}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* -----------
        Third question
        -------------*/}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          latePayments !== ''
          && latePayments.includes('Sí')
          && (editRestrasoD
            || !(lapiz[0].routine === 1 && lapiz[0].active === 2))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuánto tiempo llevas de retraso en el pago de tus deudas?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((latePayments !== ''
            && latePayments.includes('Sí')
            && latePaymentTime === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 1 && lapiz[0].active === 2)
            || editRestrasoD)
          && editTiempoRetraso !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeIngresoMensual"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            latePaymentTime !== '' && monthlyIncome === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 3 })}
        />
        <CardTwo
          classCard={
            latePaymentTime !== ''
            && (editTiempoRetraso
              || !(lapiz[0].routine === 1 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={latePaymentTime}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* -----------
        Fourth question
        -------------*/}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          (latePaymentTime !== '' || latePayments.includes('No'))
          && (latePayments.includes('No')
            || editTiempoRetraso
            || !(lapiz[0].routine === 1 && lapiz[0].active === 3))
          && (editRestrasoD
            || !(lapiz[0].routine === 1 && lapiz[0].active === 2))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuál es tu ingreso mensual?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          (((latePaymentTime !== '' || latePayments.includes('No'))
            && monthlyIncome === '')
            || (lapiz[0].routine === 1 && lapiz[0].active === 4))
          && (latePayments.includes('No')
            || editTiempoRetraso
            || !(lapiz[0].routine === 1 && lapiz[0].active === 3))
          && (editRestrasoD
            || !(lapiz[0].routine === 1 && lapiz[0].active === 2))
          && editIngMensual !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="3-3"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            monthlyIncome !== '' && orderRutine < 3 ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 4 })}
        />
        <CardTwo
          classCard={
            monthlyIncome !== ''
            && (editIngMensual
              || !(lapiz[0].routine === 1 && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={monthlyIncome}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default DatosDeudas;
