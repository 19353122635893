export const TextAntExpenses = {
  entertainment: {
    textAnnual: ', imagina todo lo que podrías hacer en un año con:',
    rangos: [
      {
        amount: '98',
        condition: '<',
        textAnnual:
          ', te recomendamos incluir tus gastos hormiga a tu presupuesto mensual para que puedas monitorearlos y los mantenerlos bajo control.',
        recommendations: [],
      },
      {
        amount: '99_300',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Remodelar tu habitación.',
            color: '#f5a838',
          },
          {
            text: 'Comprar Cetes.',
            color: '#f5a838',
          },
          {
            text: 'Pagar inscripción al gimnasio.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '301_500',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Pagar tu seguro de auto.',
            color: '#f5a838',
          },
          {
            text: 'Planear un viaje de fin de semana.',
            color: '#f5a838',
          },
          {
            text: 'Hacer tu primera inversión.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '501_700',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar una nueva lavadora.',
            color: '#f5a838',
          },
          {
            text: 'Iniciar ahorro de emergencia.',
            color: '#f5a838',
          },
          {
            text: 'Invertir en un curso académico.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '701_900',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar un nuevo teléfono.',
            color: '#f5a838',
          },
          {
            text: 'Comprar un nuevo refrigerador.',
            color: '#f5a838',
          },
          {
            text: 'Pagar un viaje.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '901_1100',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar una computadora.',
            color: '#f5a838',
          },
          {
            text: 'Reparaciones del hogar.',
            color: '#f5a838',
          },
          {
            text: 'Invertir en bitcoin.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '1101',
        condition: '>=',
        textAnnual: '',
        recommendations: [
          {
            text: 'Adquirir un seguro de vida.',
            color: '#f5a838',
          },
          {
            text: 'Reparaciones del hogar.',
            color: '#f5a838',
          },
          {
            text: 'Reunir fondos para emergencias.',
            color: '#f5a838',
          },
        ],
      },
    ],
  },
  Delivery: {
    textAnnual: ', imagina todo lo que podrías hacer en un año con:',
    rangos: [
      {
        amount: '199',
        condition: '<',
        textAnnual:
          ', te recomendamos incluir tus gastos hormiga a tu presupuesto mensual para que puedas monitorearlos y los mantenerlos bajo control.',
        recommendations: [],
      },
      {
        amount: '200_500',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Remodelar tu habitación.',
            color: '#f5a838',
          },
          {
            text: 'Comprar Cetes.',
            color: '#f5a838',
          },
          {
            text: 'Pagar inscripción al gimnasio.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '501_800',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Pagar tu seguro de auto.',
            color: '#f5a838',
          },
          {
            text: 'Planear un viaje de fin de semana.',
            color: '#f5a838',
          },
          {
            text: 'Hacer tu primera inversión.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '801_1100',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar una nueva lavadora.',
            color: '#f5a838',
          },
          {
            text: 'Iniciar ahorro de emergencia.',
            color: '#f5a838',
          },
          {
            text: 'Invertir en un curso académico.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '1101_1400',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar un nuevo teléfono.',
            color: '#f5a838',
          },
          {
            text: 'Comprar un nuevo refrigerador.',
            color: '#f5a838',
          },
          {
            text: 'Pagar un viaje.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '1401_1700',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar una computadora.',
            color: '#f5a838',
          },
          {
            text: 'Reparaciones del hogar.',
            color: '#f5a838',
          },
          {
            text: 'Invertir en bitcoin.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '1700',
        condition: '>',
        textAnnual: '',
        recommendations: [
          {
            text: 'Adquirir un seguro de vida.',
            color: '#f5a838',
          },
          {
            text: 'Reparaciones del hogar.',
            color: '#f5a838',
          },
          {
            text: 'Reunir fondos para emergencias.',
            color: '#f5a838',
          },
        ],
      },
    ],
  },
  Market_Apps_Desserts: {
    textAnnual: ', imagina todo lo que podrías hacer en un año con:',
    rangos: [
      {
        amount: '99',
        condition: '<',
        textAnnual:
          ', te recomendamos incluir tus gastos hormiga a tu presupuesto mensual para que puedas monitorearlos y los mantenerlos bajo control.',
        recommendations: [],
      },
      {
        amount: '100_500',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Remodelar tu habitación.',
            color: '#f5a838',
          },
          {
            text: 'Comprar Cetes.',
            color: '#f5a838',
          },
          {
            text: 'Pagar inscripción al gimnasio.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '501_1000',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Pagar tu seguro de auto.',
            color: '#f5a838',
          },
          {
            text: 'Planear un viaje de fin de semana.',
            color: '#f5a838',
          },
          {
            text: 'Hacer tu primera inversión.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '1001_1500',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar una nueva lavadora.',
            color: '#f5a838',
          },
          {
            text: 'Iniciar ahorro de emergencia.',
            color: '#f5a838',
          },
          {
            text: 'Invertir en un curso académico.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '1501_2000',
        condition: 'Rango',
        textAnnual: '',
        recommendations: [
          {
            text: 'Comprar un nuevo teléfono.',
            color: '#f5a838',
          },
          {
            text: 'Comprar un nuevo refrigerador.',
            color: '#f5a838',
          },
          {
            text: 'Pagar un viaje.',
            color: '#f5a838',
          },
        ],
      },
      {
        amount: '2000',
        condition: '>',
        textAnnual: '',
        recommendations: [
          {
            text: 'Adquirir un seguro de vida.',
            color: '#f5a838',
          },
          {
            text: 'Reparaciones del hogar.',
            color: '#f5a838',
          },
          {
            text: 'Reunir fondos para emergencias.',
            color: '#f5a838',
          },
        ],
      },
    ],
  },
};

export const resolveOperation = (operator, valueUser, ValueTable) => {
  switch (operator) {
    case '=':
      if (valueUser === ValueTable) return true;
      return false;

    case '!=':
      if (valueUser !== ValueTable) return true;
      return false;

    case '<>':
      if (valueUser !== ValueTable) return true;
      return false;

    case '>':
      if (parseFloat(valueUser) > parseFloat(ValueTable)) return true;
      return false;

    case '<':
      if (parseFloat(valueUser) < parseFloat(ValueTable)) return true;
      return false;

    case '>=':
      if (parseFloat(valueUser) >= parseFloat(ValueTable)) return true;
      return false;

    case '<=':
      if (parseFloat(valueUser) <= parseFloat(ValueTable)) return true;
      return false;
    case 'Rango': {
      const range = ValueTable.split('_');
      if (valueUser >= range[0] && valueUser <= range[1]) return true;
      return false;
    }

    default:
      break;
  }
};
