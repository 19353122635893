import React from 'react';
import {
  Shapes,
  Title2,
  Subtitle2,
  Rectangle13,
  VectorNum,
  Num,
  TextRectangle13,
  ContentHomeSection,
} from '../common/Home.styled';
import { Container, Col6 } from '../common/common.styled';

function SectionAlcanza({ heigthBrowser }) {
  return (
    <Shapes
      id="Shapes2"
      key={2}
      top=" "
      left=" "
      heightD=" 350px"
      height={heigthBrowser >= 600 ? '600px' : '480px'}
      width="100%"
      position=" "
      background="#FAFAFA"
    >
      <ContentHomeSection leftD=" " floatD=" " displayD="none" displayM="block">
        <Title2
          top={heigthBrowser >= 550 ? '85px' : '45px'}
          topD="15%"
          className="roboto"
        >
          {' '}
          Alcanza tu bienestar financiero{' '}
        </Title2>
        <Subtitle2 className="roboto" bottom="17px" top="10px">
          Nuestros entrenamientos te enseñarán cómo tomar las mejores decisiones
          y obtener más por tu dinero.
        </Subtitle2>
      </ContentHomeSection>
      <div style={{ width: '100%' }}>
        <Container
          width="80%"
          mt="0rem"
          widthMobile="100%"
          mb="0%"
          mtMobile="0rem"
        >
          <Col6 pt="0px" flex="0 0 100%" maxwidth="100%">
            <Rectangle13 bottomM="10px">
              <VectorNum>
                <Num className="roboto">1</Num>
              </VectorNum>
              <TextRectangle13 className="roboto">
                Identificar la causa de tus problemas financieros.
              </TextRectangle13>
            </Rectangle13>
            <Rectangle13 top="20px" bottomM="10px">
              <VectorNum back="#BFDD6A">
                <Num className="roboto">2</Num>
              </VectorNum>
              <TextRectangle13 className="roboto">
                Crear soluciones personalizadas a la medida de tus necesidades.
              </TextRectangle13>
            </Rectangle13>
            <Rectangle13 top="20px" bottomM="10px" bottom="0px!important">
              <VectorNum back="#E3E660">
                <Num className="roboto">3</Num>
              </VectorNum>
              <TextRectangle13 top="569px" className="roboto">
                Acompañarte y ayudarte a cumplir tus sueños y objetivos
                financieros.{' '}
              </TextRectangle13>
            </Rectangle13>
          </Col6>
          <Col6 pt="0px" display="flex" displayM="none">
            <div style={{ margin: 'auto' }}>
              <Title2 top="46px" className="roboto" topD=" ">
                Recupera tu tranquilidad financiera{' '}
              </Title2>
              <Subtitle2 className="roboto">
                Coru, el único gimnasio financiero,{' '}
                <b>100% gratuito y online, </b> que te ayuda a sentir
                tranquilidad cuando piensas en tu dinero en tres sencillos
                pasos.
              </Subtitle2>
            </div>
          </Col6>
        </Container>
      </div>
    </Shapes>
  );
}

export default SectionAlcanza;
