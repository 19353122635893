/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';
import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const SituacionFamiliar = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('caracteristicas', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [enfermedadesFamiliares, setEnfermedadesFamiliares] = useState(
    sessionStorage.getItem('EnfermedadesFamiliares') || '',
  );
  const [trabajoDeAltoRiesgo, setTrabajoDeAltoRiesgo] = useState(
    sessionStorage.getItem('TrabajoDeAltoRiesgo') || '',
  );
  const [presupuetoFamiliarMensual, setPresupuetoFamiliarMensual] = useState(
    sessionStorage.getItem('PresupuetoFamiliarMensual') || '',
  );
  const [seguroFamiliar, setSeguroFamiliar] = useState(
    sessionStorage.getItem('SeguroFamiliar') || '',
  );
  const [prioridadFamiliar, setPrioridadFamiliar] = useState(
    sessionStorage.getItem('PrioridadFamiliarNoEstoy') || '',
  );
  const [form, setForm] = useState({});
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 0:
        setEditAntecedentes(true);
        setEnfermedadesFamiliares(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeAltoRiesgo' });
        break;
      case 1:
        setEditTrabajoRiesgo(true);
        setTrabajoDeAltoRiesgo(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activePresFamMensual' });
        break;
      case 2:
        setEditPresupuesto(true);
        setPresupuetoFamiliarMensual(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeSegFamiliarQ' });
        break;
      case 3:
        setEditSegFam(true);
        setSeguroFamiliar(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activePrioridadFamQ' });
        if (val.value === 'No') {
          // todo mandar al rp
          if (nextOrderRoutine === 'rp') {
            insuranceActive.push('GNP Seguro de gastos medicos');
            setActiveSucces(true);
          }
        }
        break;
      case 4:
        setEditPrioridad(true);
        setPrioridadFamiliar(val.value);
        // todo mandar al rp

        if (nextOrderRoutine === 'rp') {
          if (val.value === 'Seguro de vida') {
            insuranceActive.push('GNP Seguro de vida');
            insuranceActive.push('AXA Seguro de vida');
          } else if (
            val.value === 'Seguro indemnizatorio por enfermedades graves'
          ) {
            insuranceActive.push(
              'AXA Seguro indemnizatorio por enfermedades graves',
            );
            insuranceActive.push(
              'THONA Seguro indemnizatorio por enfermedades graves',
            );
          } else if (val.value === 'Seguro de desempleo') {
            insuranceActive.push('AXA Seguro de desempleo');
            insuranceActive.push('THONA Seguro de desempleo');
          } else if (val.value === 'Seguro de gastos funerarios') {
            insuranceActive.push('GNP Gastos funerarios');
            insuranceActive.push('AXA Seguro de gastos funerarios');
          }
          setActiveSucces(true);
        }

        break;
      default:
        console.log(val);
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setEnfermedadesFamiliares(
        sessionStorage.getItem('EnfermedadesFamiliares') || '',
      );
      setTrabajoDeAltoRiesgo(
        sessionStorage.getItem('TrabajoDeAltoRiesgo') || '',
      );
      setPresupuetoFamiliarMensual(
        sessionStorage.getItem('PresupuetoFamiliarMensual') || '',
      );
      setSeguroFamiliar(sessionStorage.getItem('SeguroFamiliar') || '');
      setPrioridadFamiliar(
        sessionStorage.getItem('PrioridadFamiliarNoEstoy') || '',
      );
      if (sessionStorage.getItem('SeguroFamiliar') === 'No') {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          insuranceActive.push('GNP Seguro de gastos medicos');
          setActiveSucces(true);
        }
      }
      setPrioridadFamiliar(
        sessionStorage.getItem('PrioridadFamiliarNoEstoy') || '',
      );
      if (
        sessionStorage.getItem('PrioridadFamiliarNoEstoy') !== null
        && sessionStorage.getItem('PrioridadFamiliarNoEstoy') !== ''
      ) {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          if (
            sessionStorage.getItem('PrioridadFamiliarNoEstoy')
            === 'Seguro de vida'
          ) {
            insuranceActive.push('GNP Seguro de vida');
            insuranceActive.push('AXA Seguro de vida');
          } else if (
            sessionStorage.getItem('PrioridadFamiliarNoEstoy')
            === 'Seguro indemnizatorio por enfermedades graves'
          ) {
            insuranceActive.push(
              'AXA Seguro indemnizatorio por enfermedades graves',
            );
            insuranceActive.push(
              'THONA Seguro indemnizatorio por enfermedades graves',
            );
          } else if (
            sessionStorage.getItem('PrioridadFamiliarNoEstoy')
            === 'Seguro de desempleo'
          ) {
            insuranceActive.push('AXA Seguro de desempleo');
            insuranceActive.push('THONA Seguro de desempleo');
          } else if (
            sessionStorage.getItem('PrioridadFamiliarNoEstoy')
            === 'Seguro de gastos funerarios'
          ) {
            insuranceActive.push('GNP Gastos funerarios');
            insuranceActive.push('AXA Seguro de gastos funerarios');
          }
          setActiveSucces(true);
        }
      }
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editAntecedentes, setEditAntecedentes] = useState('');
  const [editTrabajoRiesgo, setEditTrabajoRiesgo] = useState('');
  const [editPresupuesto, setEditPresupuesto] = useState('');
  const [editSegFamiliar, setEditSegFam] = useState('');
  const [editPrioridad, setEditPrioridad] = useState('');
  const handleChangeIcon = (json) => {
    setEditAntecedentes(false);
    setEditTrabajoRiesgo(false);
    setEditPresupuesto(false);
    setEditSegFam(false);
    setEditPrioridad(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const abrirNuevoTab = (url) => {
    // Abrir nuevo tab
    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (enfermedadesFamiliares === ''
          || (lapiz[0].routine === currentRoutine
            && lapiz[0].active === 1))
        && editAntecedentes !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeSituacionFamiliar' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeSituacionFamiliar"></div>

        <QuestionsOrder
          classCard="cardOne"
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (enfermedadesFamiliares === ''
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1))
            && editAntecedentes !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeAltoRiesgo"></div>
        <CardTwo
          classCard={
            enfermedadesFamiliares !== ''
            && (editAntecedentes === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={enfermedadesFamiliares}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              enfermedadesFamiliares !== '' && trabajoDeAltoRiesgo === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            enfermedadesFamiliares !== ''
            && (editAntecedentes === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        <CardOne
          classCard={
            enfermedadesFamiliares !== ''
            && (editAntecedentes === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardOne cursorPointer'
              : 'hidden'
          }
          classTop="topOne1"
          text="Para saber más:"
          hora={hora}
        >
          <p
            className="cursorPointer enlace"
            onClick={() => abrirNuevoTab(
              'https://blogs.imf-formacion.com/blog/prevencion-riesgos-laborales/especial-master-prevencion/trabajos-mas-riesgo/',
            )
            }
          >
            Cuáles son los trabajos de más riesgo
          </p>
        </CardOne>

        <TrainingQuestion
          className={
            ((enfermedadesFamiliares !== '' && trabajoDeAltoRiesgo === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 1
            )
              || editAntecedentes === true)
            && editTrabajoRiesgo !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activePresFamMensual"></div>
        <CardTwo
          classCard={
            trabajoDeAltoRiesgo !== ''
            && (editTrabajoRiesgo === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={trabajoDeAltoRiesgo}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              trabajoDeAltoRiesgo !== '' && presupuetoFamiliarMensual === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            trabajoDeAltoRiesgo !== ''
            && (editTrabajoRiesgo === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((trabajoDeAltoRiesgo !== '' && presupuetoFamiliarMensual === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 2
            )
              || editTrabajoRiesgo === true)
            && editPresupuesto !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeSegFamiliarQ"></div>
        <CardTwo
          classCard={
            presupuetoFamiliarMensual !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={presupuetoFamiliarMensual}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              presupuetoFamiliarMensual !== '' && seguroFamiliar === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 3 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            presupuetoFamiliarMensual !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((presupuetoFamiliarMensual !== '' && seguroFamiliar === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 3
            )
              || editPresupuesto === true)
            && editSegFamiliar !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activePrioridadFamQ"></div>
        <CardTwo
          classCard={
            seguroFamiliar !== ''
            && (editSegFamiliar === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={seguroFamiliar}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={
              seguroFamiliar !== '' && prioridadFamiliar === ''
                ? 'iconEditCommon'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 4 })
            }
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            seguroFamiliar !== ''
            && (editSegFamiliar === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 4
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={4}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((seguroFamiliar !== '' && prioridadFamiliar === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 5))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 4
            )
              || editSegFamiliar === true)
            && editPrioridad !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={
            prioridadFamiliar !== ''
            && (editPrioridad === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 5
              ))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={prioridadFamiliar}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={prioridadFamiliar !== '' ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 5 })
            }
          />
        </CardTwo>
      </div>
  );
};

export default SituacionFamiliar;
