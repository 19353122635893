import React, { useState, useEffect } from 'react';
// assets
import InputsOrder from '../../components/common/InputsOrder';

const DetailRFC = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
}) => {
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [rfc, setRFC] = useState(sessionStorage.getItem('RFC') || '');

  // modal banks

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    secondname: '',
    email: '',
    secondlastname: '',
    day: '',
    month: '',
    year: '',
    cel: '',
  });

  useEffect(() => {
    form.firstname = sessionStorage.getItem('FirstName') || '';
    form.lastname = sessionStorage.getItem('FathersLastName') || '';
    form.secondname = sessionStorage.getItem('MiddleName') || '';
    form.email = sessionStorage.getItem('Email1') || '';
    form.secondlastname = sessionStorage.getItem('MothersLastName') || '';
    form.day = sessionStorage.getItem('day') || Date.day;
    form.month = sessionStorage.getItem('month') || Date.month;
    form.year = sessionStorage.getItem('year') || Date.year;
    form.cel = sessionStorage.getItem('CellPhone') || '';
  });

  const handleChange = (e, question = '') => {
    // console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // console.log(e.target.name);
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    // console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (name === 'RFC') {
      // console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log("");
    // console.log('handleChangeCatOption', val);
  };

  const handleChangeRFC = (val) => {
    setRFC(val);
    handleChangeQuestionsSend(1, 'RFC', val);
  };
  useEffect(() => {
    // console.log(orderRutine, currentRoutine, form.firstname, rfc);
    if (rfc !== '' && orderRutine === currentRoutine) {
      setOrderRutine(nextOrderRoutine);
    }
  }, [orderRutine]);
  return (
    <div>
      <div className="d-none">
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeRFC={handleChangeRFC}
            setOrderRutine={setOrderRutine}
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="0-0"
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            currentTraining={currentTraining}
            handleChange={handleChange}
            nextOrdeRoutine={nextOrderRoutine}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DetailRFC;
