import React, { useState, Fragment } from 'react';
import { useTrail, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import ImageLogo from '../../assets/img/anacoach/logo-01.svg';
import '../../assets/styles/Home.css';

import {
  Images,
  CardsBtn,
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingText,
  TrainingContainAna,
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Mvp.styled';

const items2 = ['Dale poder a tus finanzas', 'entrena con los expertos.'];
const config = { mass: 4, tension: 600, friction: 100 };
const Landing = () => {
  const { utm_source, utm_medium, utm_campaign } = qs.parse(
    window.location.search,
    { ignoreQueryPrefix: true },
  );

  const [stateUtm, setStateUtm] = useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  });
  const history = useHistory();
  const [toggle2] = useState(true);
  const trail2 = useTrail(items2.length, {
    config,
    opacity: toggle2 ? 1 : 0,
    a: toggle2 ? 0 : 20,
    height: toggle2 ? 80 : 0,
    from: { opacity: 0, a: 20, height: 0 },
  });

  const answer = () => history.push('/answer');
  const handleOnClick = (e) => {
    e.preventDefault();
    setStateUtm({
      ...stateUtm,
      utm_source,
      utm_medium,
      utm_campaign,
    });
    sessionStorage.setItem('utm_source', utm_source);
    sessionStorage.setItem('utm_medium', utm_medium);
    sessionStorage.setItem('utm_campaign', utm_campaign);
    answer();
  };
  return (
    <Fragment>
      <TrainingMain>
        <TrainingContainAna>
          <Images
            src={ImageLogo}
            width="40%"
            top="16%"
            topweb="5%"
            left="0"
            position="initial"
            className="img-fluid"
          ></Images>
        </TrainingContainAna>
        <TrainingAnimateFunnel pmob="8% 0 0 0" minh="130px">
          {trail2.map(({ a, height, ...rest }, index) => (
            <animated.div
              key={items2[index]}
              className="trails-text text-funnelcc dm-sans centrar"
              style={{
                ...rest,
                transform: a.interpolate((a) => `translate3d(0,${a}px,0)`),
              }}
            >
              <animated.div style={{ height }}>{items2[index]}</animated.div>
            </animated.div>
          ))}
        </TrainingAnimateFunnel>
        <TrainingDivSection padding="0 10% 2% 10%">
          <TrainingText className="dm-sans" align="left" alignweb="left">
            No importa cuánto ganas o qué deudas tienes, mejora tu situación
            económica y aprende a tomar buenas decisiones con tu dinero.
          </TrainingText>
          <TrainingText className="dm-sans">
            Entrena tus finanzas con los expertos en Coru.
          </TrainingText>
        </TrainingDivSection>

        <TrainingSectionGray className="centrar">
          <TrainingDivSection2>
            <CardsBtn
              styledMaxWidth="60%"
              type="button"
              min_w="30%"
              min_web="30%"
              onClick={(e) => handleOnClick(e)}
            >
              Comenzar entrenamiento
            </CardsBtn>
          </TrainingDivSection2>
        </TrainingSectionGray>
        <br />
      </TrainingMain>
      <div id="irTimeDom"></div>
    </Fragment>
  );
};
export default Landing;
