import React, { Fragment } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Col7, Container, Col2, Col10,
} from '../common/common.styled';
import ImageCoachInicio from './ImageCoachInicio';
import {
  TrainingDivSection, TrainingBenef,
} from '../../assets/styles/Training.styled';

function FeaturesTraining({
  Arraytext, showImg = true, classAna, children,
}) {
  return (
      <Fragment>
         <Container width="80%" mt="0px" widthMobile="90%" mtMobile="0" displayweb="flex">
            {showImg ? <ImageCoachInicio classAna = {classAna} topMobile="35px"/> : null}
            <Col7 flex={classAna === 'falseAna' ? '0 0 100%' : '0 0 75%'} maxwidth="100%" padding=" " className="cardPrincipal1">
               <TrainingDivSection
                  hew="auto"
                  paddw="26px"
                  mlw="0%"
                  wiw="100%"
                  back="#F3F3F3"
                  backw="#F3F3F3"
                  bshaw="none"
                  bsha=""
                  br="4px"
                  padding="20px"
                  wi="100%"
                  he="auto"
                  mb="0px"
                  className="topOne1Init"
               >
                    {
                        Arraytext.description !== ''
                          ? <p className="roboto" style={{ fontSize: 16 }}>{Arraytext.description}</p> : null
                    }
                    {Arraytext.elements.map((feacture, index) => (
                        <Container key={index} width="100%" mt="0rem" widthMobile="100%" mtMobile="0rem" /* bb= {index < Arraytext.length ? "1px solid #CDCDCD" : " " } */>
                            <Col2 display="flex" mt="initial">
                            <TrainingBenef
                                bbs="none"
                                bbsw="none"
                                bbw="1px"
                                bbc="#CDCDCD"
                                pt={(Arraytext.elements).length === 1 ? '0' : '17px'}
                                pb={(Arraytext.elements).length === 1 ? '0' : '17px'}
                                he="auto"
                                pl="0px"
                                mt="0px"
                                className="roboto"
                                alignweb="center"
                                ls="-0.2px"
                                width="100%"
                            >
                                <CheckBoxIcon style={{ color: feacture.color, fontSize: 23 }} />
                            </TrainingBenef>
                            </Col2>
                            <Col10 padding="0px" pMobile="0px 15px">
                            <TrainingBenef
                                pt={(Arraytext.elements).length === 1 ? '0' : '17px'}
                                pb={(Arraytext.elements).length === 1 ? '0' : '17px'}
                                he="auto"
                                pl="0px"
                                mt="0px"
                                className="roboto"
                                alignweb="left"
                                ls="-0.2px"
                                size="16px"
                            >
                                    <b>{feacture.title}</b>
                                    <br />
                                    {feacture.text}
                            </TrainingBenef>
                            </Col10>
                        </Container>
                    ))}
                    {children}
                </TrainingDivSection>
            </Col7>
         </Container>
      </Fragment>
  );
}

export default FeaturesTraining;
