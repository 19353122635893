import React, { useState, Fragment, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  setSessionVariablesUserContact,
  setSessionVariablesUserDetails,
} from '../../Helpers/setSessionVariables';
import {
  Button,
  SubtitleOne,
  Main,
  Col11,
  ContentButton,
  Container,
  SpanTextR,
  SpanTextL,
  ContTel,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import '../../assets/styles/TextCustom.css';
import {
  GET_COUNTRY_CODES,
  GET_USER_CONTACT,
  GET_USER_DETAILS,
} from '../../queries';
const ContactNu = () => {
  const [error] = useState({
    numPhone: '',
    numPhoneValid: -1,
  });
  const url = new URL(window.location.href);
  const idUser = url.searchParams.get('id') || '';
  const idCont = url.searchParams.get('idCont') || '';
  const [lleno, setlleno] = useState(false);
  const { data: dataLadas } = useQuery(GET_COUNTRY_CODES);
  const [getUserDetails, { data: userDetailsData }] = useLazyQuery(GET_USER_DETAILS);
  const [getUserContact, { data: userContact }] = useLazyQuery(GET_USER_CONTACT);
  // const history = useHistory();
  const [form, setForm] = useState({
    numPhone: '',
    lada: 'México lada +(52)',
    textlada: 'México lada +(52)',
  });

  const [Ladas] = useState([
    {
      value: '',
      label: 'México',
      lada: '',
    },
    {
      value: 'Estados Unidos lada +(1)',
      label: 'Estados Unidos',
      lada: '1',
    },
    {
      value: '',
      label: 'Brasil',
      lada: '',
    },
    {
      value: '',
      label: 'Colombia',
      lada: '',
    },
    {
      value: '',
      label: 'Chile',
      lada: '',
    },
  ]);
  const handleChange = (e) => {
    if (e.target.name === 'numPhone') {
      setForm({
        ...form,
        numPhone: e.target.value.replace(/[^0-9]/g, ''),
      });
      if (
        (e.target.value.length < 11 && e.target.value.length !== 10)
        || (e.target.name && !/^[0-9]+$/i.test(e.target.value))
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
        });
        error.numPhone = 'Por favor verifica tu número telefónico';
        error.numPhoneValid = 1;
      } else if (e.target.value.length < 11 && e.target.value.length === 10) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ''),
        });
        sessionStorage.setItem(
          'cellPhone',
          e.target.value.replace(/[^0-9]/g, ''),
        );
        error.numPhone = '';
        error.numPhoneValid = 0;
      }
    } else {
      setForm({
        ...form,
        lada: e.target.value,
        textlada: e.target.value,
      });
      const indexLada = e.target.value.indexOf('+(');
      const ladaselect = e.target.value.substring(indexLada);
      sessionStorage.setItem('ladauser', ladaselect);
    }
  };

  const LlenarCampo = () => {
    if (dataLadas) {
      if (!lleno) {
        dataLadas.getCountryCodes.forEach((element) => {
          if (
            element.Country !== 'México'
            && element.Country !== 'Estados Unidos'
            && element.Country !== 'Brasil'
            && element.Country !== 'Colombia'
            && element.Country !== 'Chile'
          ) {
            Ladas.push({
              value: `${element.Country} lada +(${element.CountryCode})`,
              label: element.Country,
              lada: element.CountryCode,
            });
          } else {
            let i = 0;
            switch (element.Country) {
              case 'México':
                i = 0;
                break;
              case 'Estados Unidos':
                i = 1;
                break;
              case 'Brasil':
                i = 2;
                break;
              case 'Colombia':
                i = 3;
                break;
              case 'Chile':
                i = 4;
                break;
              default:
                break;
            }
            Ladas[
              i
            ].value = `${element.Country} lada +(${element.CountryCode})`;
            Ladas[i].label = element.Country;
            Ladas[i].lada = element.CountryCode;
          }
        });
        setlleno(true);
      }
    }
  };

  // OpenLada
  const [openLada, setOpenLada] = useState(false);
  const handleCloseLada = () => {
    setOpenLada(false);
  };
  const handleOpenLada = () => {
    setOpenLada(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sessionStorage.setItem('CellPhone', form.numPhone);
    sessionStorage.setItem('ladauser', form.lada || '+(52)');
    window.location.href = '/verification-code-nu';
    // hay que volver a refrescar la página porque estamos seteando variables de session
  };

  LlenarCampo();

  useEffect(() => {
    console.log({ idUser, idCont });
    if (idUser) getUserDetails({ variables: { idUser } });
    if (idCont) getUserContact({ variables: { idCont } });
  }, []);

  useEffect(() => {
    if (userContact) {
      const { response } = userContact.getUserContact;
      if (response) setSessionVariablesUserContact(response);
    }
  }, [userContact]);

  useEffect(() => {
    if (userDetailsData) {
      const { response } = userDetailsData.getUserDetails;
      if (response) setSessionVariablesUserDetails(response);
    }
  }, [userDetailsData]);

  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="60%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11 padding="0">
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="40px"
              sizeMobile="20px"
              textalingn="center"
              textalingnMobile="center"
            >
              Ingresa tu número celular para validar tus datos
            </SubtitleOne>
            <form
              autoComplete="off"
              className="width100 displaygrid"
              onSubmit={handleSubmit}
            >
              <ContTel>
                <Select
                  variant="outlined"
                  id="lada"
                  name="lada"
                  open={openLada}
                  onClose={handleCloseLada}
                  onOpen={handleOpenLada}
                  value={form.lada}
                  onChange={handleChange}
                  style={{ width: '42%' }}
                >
                  {Ladas.map((option) => (
                    <MenuItem key={option.lada} value={option.value}>
                      <SpanTextL className="roboto">{option.label}</SpanTextL>
                      <SpanTextR> +({option.lada})</SpanTextR>
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  variant="outlined"
                  id="numPhone"
                  name="numPhone"
                  autoFocus
                  required
                  label="Número de celular"
                  type="tel"
                  value={form.numPhone}
                  onChange={handleChange}
                  inputProps={{
                    type: 'tel',
                    maxLength: 10,
                  }}
                  className="registro"
                  style={{ width: '58%' }}
                />
              </ContTel>

              <ContentButton mbW="100px" mt="5%" width="80%" mtMobile="0%">
                {form.numPhone.length === 10 ? (
                  <Button
                    className="d-block mt-md-4 roboto"
                    mb="1.2rem"
                    mt="1.2rem"
                    bg="#820AD1"
                  >
                    Continuar
                  </Button>
                ) : null}
                <span>
                  Al ingresar los datos que solicitamos, aceptas el{' '}
                  <a
                    style={{ cursor: 'pointer' }}
                    href="https://www.nu.com.mx/aviso-de-privacidad-prospectos/"
                    target="_blank" rel="noreferrer"
                  >
                    <b style={{ color: '#820AD1' }}> Aviso de Privacidad.</b>
                  </a>
                </span>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
};

export default ContactNu;
