import React, { Fragment } from 'react';
import BetaHome from '../../assets/img/anacoach/BETA.png';
import BetaWo from '../../assets/img/anacoach/BETA_WO.png';
import { Images, DivPrincipalBeta } from '../../assets/styles/Training.styled';

const BetaIcon = ({ page }) => (
    <DivPrincipalBeta ta={page === 'Home' ? 'revert' : ''}>
        {
            // eslint-disable-next-line no-nested-ternary
            page === 'Home'
              ? <Fragment>
                <Images
                    src={BetaHome}
                    top="55px"
                    topweb="80px"
                    left="70%"
                    leftw="-90px"
                    width="110px"
                    widthw="180px"
                    position="absolute"
                    positionw="absolute"
                    zi="1000"
                    className="img-fluid"
                ></Images>
            </Fragment>
              : page === 'trainings'
                ? <Fragment>
                        <Images
                            src={BetaWo}
                            top="-4px"
                            topweb=""
                            left="0"
                            leftw="0"
                            width="72px"
                            widthw="72px"
                            position="absolute"
                            le='0'
                            className="img-fluid"
                        ></Images>
                    </Fragment>
                : <Fragment>
                    <Images
                        src={BetaWo}
                        top="90px"
                        topweb="100px"
                        left="0"
                        leftw="0"
                        width="90px"
                        widthw="115px"
                        position="absolute"
                        le='0'
                        className="img-fluid"
                    ></Images>
                </Fragment>
        }
    </DivPrincipalBeta>
);

export default BetaIcon;
