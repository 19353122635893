import React from 'react';
import { Image, TextGeneric } from '../common/common.styled';
import { Container } from '../LpHomeCoru/Assets/styles/StyledGeneric.styled';
import telLanding from '../../assets/img/Cel_Landing.svg';
import GooglePlay from '../../assets/img/GooglePlay.svg';
import AppStore from '../../assets/img/AppStore.svg';
import Manchaslanding from '../../assets/img/Manchaslanding.svg';
import CoruLogo from '../../assets/img/LOGO.svg';

const LandingPage = () => {
  const hola = '';
  return (
        <Container height="100vh">
            <Image src={Manchaslanding} displayMobile="revert" position="absolute" positionMobile="fixed" top="0" bottom="0" displayWeb="flex" width="100%" heightMobile="100vh" objectFitMd="cover" />
            <Image src={CoruLogo} displayWeb="flex" width="10%" widthMd="35%" margin="20px" marginMd="50px auto"/>
            <Container className="d-flex flex-row col-12" background="transparent" mt="0px">
                <Container className="d-flex flex-column col-md-4 col-12" position="relative">
                    <TextGeneric textAlignL="left" fontWeight="700" fontSizeSm="40px" textAlignSm="left" width="90%" fontSize="62px" fontColor="#393A80" margin="35% 0 12px 0" marginSm="30px 0">Coru App</TextGeneric>
                    <TextGeneric textAlignL="left" textAlignSm="left" fontWeight="700" width="90%" fontSizeSm="16px" fontSize="52px" fontColor="#595959" margin="0 0 12px 0" className="Roboto">Tu Coach Financiero</TextGeneric>
                    <TextGeneric textAlignL="left" textAlignSm="left" width="90%" fontSizeSm="16px" fontSize="24px" fontColor="#595959" margin="0" className="Roboto">¡Aprende finanzas de manera divertida y olvídate del estrés financiero!</TextGeneric>
                    <Container className="d-flex flex-row col-md-12 col-12" margin="30px 0" mt="50px">
                        <Container className="d-flex flex-column col-md-5 col-6">
                            <a href='https://play.google.com/store/apps/details?id=app.coru.com' target='_blank' rel="noreferrer"><Image src={GooglePlay} width="100%" style={{ cursor: 'pointer ' }}/></a>
                        </Container>
                        <Container className="d-flex flex-column col-md-5 col-6">
                            <a href='https://apps.apple.com/us/app/coru-app/id6443549787' target='_blank' rel="noreferrer"><Image src={AppStore} width="100%" style={{ cursor: 'pointer ' }}/></a>
                        </Container>
                    </Container>
                </Container>
                <Container className="d-flex flex-column col-md-7 col-12" position="relative" mt="auto" mtMobile="30px">
                    <Image src={telLanding} width="70% !important" position="relative" margin="auto"/>
                </Container>
            </Container>
      </Container>
  );
};
export default LandingPage;
