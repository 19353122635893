/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ChatIcono from "../Home/ChatIcono";
import { useHistory } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import { WhatsappDiv, WhatsappR } from "../common/Home.styled";
import { SAVE_TIME_SESSION } from "../../mutations";
import { GET_WHATSAPP_INFO } from "../../queries";
import ClickButton from "../../Helpers/HookHelpers";
// import { INFOBIP_ACTIONS } from '../../Helpers/infobip'

function Whatsapp({pathname = window.location.pathname}) {
  const history = useHistory();
  const [url, setUrl] = useState("");
  const [isWhat, setIsWhat] = useState(false);
  const [urlActual, setUrlActual] = useState(window.location.pathname);
  const [urlAntigua, setUrlAntigua] = useState("");
  const [selectLang, setSelectLang] = useState(1);
  const { actions } = ClickButton();
  const urlh = window.location.href;

  const [whatsInfo, setWhatsInfo] = useState([]);

  const [getWhatsAppTranslate] = useLazyQuery(GET_WHATSAPP_INFO, {
    onCompleted({ getWhatsappTranslateInfo }) {
      const { error, message, response } = getWhatsappTranslateInfo;
      setWhatsInfo(JSON.parse(response));
    },
  });


  const setValorWhat = () => {
    let selectedDescription = "";

    whatsInfo.forEach(infoPathWapp => {
      if (infoPathWapp.Path === pathname) {
        selectedDescription = infoPathWapp.Description;
      }
    });
    return selectedDescription;
    
  };
  const setNumWhat = () => {
    let numwhats = "+525613953649";
    if (selectLang === 3) {
      numwhats = "+5511997124616";
    }
    return numwhats;
  };


  useEffect(() => {
    if (urlh.includes("/en/")) {
      setSelectLang(2);
    } else if (urlh.includes("/pt/")) {
      setSelectLang(3);
    } else {
      setSelectLang(1);
    }
  }, [urlh]);

  const [flag, setFlag] = useState(0);

  useEffect(() => {
    if (flag !== selectLang) {
      getWhatsAppTranslate({
        variables: {
          language: selectLang,
        },
      });
      setFlag(selectLang);
    }
    CaptValor();
  }, []);

  useEffect(() => {
    setUrl(
      `https://api.whatsapp.com/send?phone=${setNumWhat()}&text=${encodeURIComponent(
        setValorWhat()
      )}`
    );
    console.error(url);
  }, [pathname, whatsInfo]);

  const CaptValor = () => {
    setIsWhat(document.getElementById("chat").value === "Si");
    if (urlActual !== urlAntigua) {
      setUrlAntigua(urlActual);
    }
    setUrl(
        `https://api.whatsapp.com/send?phone=${setNumWhat()}&text=${encodeURIComponent(
          setValorWhat()
        )}`
      );
    console.log(url);
  };

  const [updateSessionTime] = useMutation(SAVE_TIME_SESSION, {
    onCompleted({ timeSession }) {
      console.log(timeSession);
    },
  });

  useEffect(() => {
    const lastUri = sessionStorage.getItem("lastURI") || "";
    const updateTimeSessionInput = {
      input: {
        lastURI: lastUri,
        currentURI: window.location.pathname,
        idSession: sessionStorage.getItem("id_session"),
      },
    };
    if (sessionStorage.getItem("id_session")) {
      updateSessionTime({ variables: updateTimeSessionInput });
    }
    sessionStorage.setItem("lastURI", window.location.pathname);
    componentDidUpdate(window.location.pathname);
  }, []);

  useEffect(() => {
    return history.listen(location => {
      const lastUri = sessionStorage.getItem("lastURI") || "";
      const updateTimeSessionInput = {
        input: {
          lastURI: lastUri,
          currentURI: location.pathname,
          idSession: sessionStorage.getItem("id_session"),
        },
      };
      if (sessionStorage.getItem("id_session")) {
        updateSessionTime({ variables: updateTimeSessionInput });
      }
      sessionStorage.setItem("lastURI", location.pathname);
      componentDidUpdate(location.pathname);
    });
  }, [history]);

  const componentDidUpdate = path => {
    if (path.includes("/wo") && !path.includes("success")) {
      window.onbeforeunload = function () {
        const lastUri = sessionStorage.getItem("lastURI") || "";
        const updateTimeSessionInput = {
          input: {
            lastURI: lastUri,
            currentURI: window.location.pathname,
            idSession: sessionStorage.getItem("id_session"),
          },
        };
        if (sessionStorage.getItem("id_session")) {
          updateSessionTime({ variables: updateTimeSessionInput });
        }
        sessionStorage.setItem("lastURI", window.location.pathname);
        return "¿Estás seguro de que deseas salir?";
      };
    } else {
      window.onbeforeunload = undefined;
    }
  };

  return (
    <WhatsappDiv>
      <ChatIcono url={url}/>
    </WhatsappDiv>
  )
}

export default Whatsapp;
