import React from 'react';
import {
  TrainingSubtitles,
  TrainingText,
  CardsBtn,
} from '../../assets/styles/Training.styled';

const PymesCardCalCredit = ({
  title, text, nextQuestion, onClick,
}) => (
    <>
      <TrainingSubtitles
        size="16px"
        fw="600"
        align="left"
        alignweb="left"
        className="roboto"
      >
        {title}
      </TrainingSubtitles>
      <TrainingText
        size="16px"
        className="roboto"
        bot="20px"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        {text}
      </TrainingText>
      <TrainingText
        size="16px"
        className="roboto"
        bot="20px"
        align="left"
        alignweb="left"
        ls="-0.2px"
      >
        <ul>
          <li>
            <TrainingText
              bot="0px"
              size="16px"
              sizew="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              <b>Estar dado de alta en Hacienda </b> como persona moral o
              persona física con actividades empresariales.{' '}
            </TrainingText>
          </li>
          <li>
            {' '}
            Contar con copia del{' '}
            <b> acta constitutiva y otorgamiento de poderes </b> para personas
            morales.{' '}
          </li>
          <br />
          <li>
            {' '}
            Contar con un mínimo de <b> 6 meses de facturación.</b>{' '}
          </li>
          <br />
          <li>
            {' '}
            Tener <b>comprobante de domicilio </b> de fecha reciente{' '}
          </li>
          <br />
          <li>
            {' '}
            La persona física, representante o principal accionista del negocio
            debe tener <strong> entre 25 y 70 años.</strong>{' '}
          </li>
          <br />
          <li>
            {' '}
            Contar con <b>Clave CIEC.</b>{' '}
          </li>
          <br />
          <li>
            {' '}
            Tener una <b>identificación oficial vigente </b> con fotografía.{' '}
          </li>
          <br />
          <li>
            {' '}
            <b>Buró de crédito</b>{' '}
          </li>
        </ul>
      </TrainingText>
      {nextQuestion === '' ? (
        <div style={{ display: 'flex' }}>
          <CardsBtn
            type="button"
            windthLg="100%"
            mtop="0px"
            mtopw="0px"
            onClick={onClick}
          >
            Calcular mi crédito
          </CardsBtn>
        </div>
      ) : null}
    </>
);
export default PymesCardCalCredit;
