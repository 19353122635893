/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import {
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
  TrainingText,
  QuestionsText,
} from '../../assets/styles/Training.styled';
import InputsOrder from '../../components/common/InputsOrder';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import CardOne from '../../components/Home/Card1Animate';
import { Container, Col7 } from '../../components/common/common.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import {
  TextAntExpenses,
  resolveOperation,
} from '../../Helpers/TextMonthlyAmount';

import InputsColumsTypeNumber from '../../components/common/InputsOrderColums';
import { ImageSticker } from '../../assets/styles/Feed.styled';
import iconAgregarAmigo from '../../assets/img/anacoach/iconAgregarAmigo.svg';
import CardComponents from '../../components/Home/Card1AnimateComponents';

const TransportApps = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  insertDatAntExpenses,
  currentRoutine,
  nextOrderRoutine,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const styleInputNewService = {
    border: 'none',
    fontSize: '15px',
    width: '137px',
  };
  const [entertainment] = useState([
    { step: 0 },
    { step: 1 },
    { step: 2 },
    { step: 3 },
  ]);
  const [feacturesArray, setFeactures] = useState([]);
  const [textAnnual, setTextAnnual] = useState('');

  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const [Rutine, setRutine] = useState({});
  const [countRecovery] = useState(0);
  const [form, setForm] = useState({
    nameService: '',
    amountService: '',
  });
  const [selectedServices, setSelectedServices] = useState([]);
  const [newService, setNewService] = useState([]);
  const [showNewService, setShowNewService] = useState(false);
  const [expenses, setExpenses] = useState(false);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [annualAmount, setAnnualAmount] = useState(0);
  const [amount] = useState(0);

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }
              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRoutine('activa');
                setQuestions([]);
                setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRoutine('inactiva');
    }
  }, []);

  const handleChangeRutine = (data) => {
    console.log('Rutine==== ', data);
    setRutine(data);
  };
  const handleChange = (e, question = '') => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleChangeCatOption = (val) => {};

  const handleChangeStatus = (numQuestion, name, value, type = '', e) => {
    console.log('handleChangeStatus', numQuestion, name, value);
    const element = document.querySelector(`div[name="${name}"]`);
    if (element.classList.contains('border-pink-active') && value === 0) {
      console.log('remover clase catch');
      element.classList.remove('border-pink-active');
    } else {
      element.classList.add('border-pink-active');
    }

    if (numQuestion === undefined) return;
    const elementExist = selectedServices.filter(
      (element) => element.id === name,
    );
    if (elementExist.length !== 0 && value <= 0) {
      const quetionsTemp = [];
      for (const element of selectedServices) {
        if (element.id !== elementExist[0].id) {
          console.log(element.id, elementExist[0].id);
          quetionsTemp.push(element);
        }
      }
      setSelectedServices(quetionsTemp);
    } else if (elementExist.length !== 0) {
      for (const element in selectedServices) {
        if (selectedServices[element].id === elementExist[0].id) {
          selectedServices[element].value = String(value);
        }
      }
    } else {
      setSelectedServices([
        ...selectedServices,
        {
          id: name,
          question: numQuestion,
          value: String(value),
        },
      ]);
    }
  };

  const handleFood = (showAndSave) => {
    setShowNewService(showAndSave);
    if (!showAndSave) {
      const { nameService, amountService } = form;
      setNewService([
        ...newService,
        {
          nameService,
          amountService,
        },
      ]);
    }
    setForm({
      amountService: '',
      nameService: '',
    });
  };

  const handleSaveService = () => {
    setExpenses(true);
    let auxAmount = 0;
    for (let i = 0; i < selectedServices.length; i++) {
      auxAmount += parseInt(selectedServices[i].value);
    }
    setMonthlyAmount(auxAmount);
    setAnnualAmount(auxAmount * 12);
    const arrayServices = TextAntExpenses.Market_Apps_Desserts.rangos;
    setTextAnnual('');
    for (let i = 0; i < arrayServices.length; i++) {
      const resolve = resolveOperation(
        arrayServices[i].condition,
        auxAmount,
        arrayServices[i].amount,
      );
      if (resolve) {
        if (arrayServices[i].textAnnual === '') {
          setTextAnnual(TextAntExpenses.Market_Apps_Desserts.textAnnual);
        }
        setFeactures(arrayServices[i].recommendations);
      }
    }
    const annualExpenditure = auxAmount * 12;
    insertDatAntExpenses({
      variables: {
        input: {
          category: String(sessionStorage.getItem('CategoriasDeGastosH')),
          monthlyExpenditure: String(auxAmount),
          annualExpenditure: String(annualExpenditure),
          idUser: String(sessionStorage.getItem('idUser')),
          idTraining: String(sessionStorage.getItem('idTraining')),
        },
      },
    });
    createJsonInput(Rutine, selectedServices);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#activeConocerTransport' });
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && !expenses) {
        gsap.to(window, { duration: 1, scrollTo: '#activeTransportApp' });
        setReviewScroll(false);
      }
    }, 1600);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeTransportApp"></div>
        <div id="activeConocerTransport"></div>
        <CardOne classCard="cardOne" classTop="topOne1" text="Continuemos" />
        <TrainingQuestion
          wiw="80%"
          className={expenses ? 'hidden' : ''}
          mt="20px"
        >
          <QuestionsDivs
            padd="5%"
            txta="left"
            width="100%"
            widthweb="75%"
            hei="auto"
            margin="auto"
            bc="#a6a6a61f"
            marginL="9.9%"
            marginLmd="0px"
          >
            <div className="cardPrincipal1">
              <TrainingText
                className="cabin"
                align="left"
                weight="normal"
                size="16px"
                sizew="16px"
              >
                Lo tuyo es las aplicaciones de transporte, ¿Cuánto pagas al mes?
              </TrainingText>
              {entertainment.map(({ step }) => (
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={orderRutine}
                  step={`${step}-${step}`}
                  handleChangeQuestionsSend={handleChangeStatus}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  componentWo="ant-expenses-streaming"
                />
              ))}
              {newService.map((service, index) => (
                <InputsColumsTypeNumber
                  key={index}
                  handleChangeCatOption={handleChangeCatOption}
                  handleChangeQuestionsSend={handleChangeStatus}
                  paramsCat={{
                    step: index + 4,
                    value: service.nameService,
                  }}
                  QuestionsOrder={index + 5}
                  QuestionName={service.nameService}
                  QuestionOption={service.nameService}
                  QuestionType="Multiple"
                  amountService={amount}
                  streaming={false}
                />
              ))}
              {showNewService && (
                <div style={{ display: 'flex', width: '100%' }}>
                  <QuestionsDivs
                    txta="left"
                    padd="14px 5px"
                    width="80%"
                    margin="8px 5px auto auto;"
                    hei="50px"
                    bs="0px 2px 8px rgba(0,0,0,0.08)"
                  >
                    <input
                      type="text"
                      placeholder="Escribe aquí"
                      autoComplete="off"
                      required
                      name="nameService"
                      style={styleInputNewService}
                      value={form.nameService}
                      onChange={handleChange}
                    />
                  </QuestionsDivs>
                  <QuestionsDivs
                    padd="14px 5px"
                    width="80%"
                    margin="8px auto auto 5px"
                    hei="50px"
                    bs="0px 2px 8px rgba(0,0,0,0.08)"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      background: '#A6A6A6',
                      cursor: 'not-allowed',
                    }}
                  >
                    <RemoveCircleOutlineOutlinedIcon
                    ></RemoveCircleOutlineOutlinedIcon>
                    <QuestionsText
                      size="14px"
                      weight="400"
                      id="amountReviewEjemplo"
                    >
                      {'$'}
                      {amount}
                    </QuestionsText>
                    <AddCircleOutline
                    ></AddCircleOutline>
                  </QuestionsDivs>
                </div>
              )}
            </div>
            {newService.length <= 5 && (
              <CardsBtn
                mtop="20px"
                mtopw="20px"
                mtopmd="20px"
                type="button"
                min_w="100%"
                min_web="100%"
                color="#EC407A !important"
                back="#fff !important"
                border="solid 1px !important"
                padd="8px 15px 4px"
                heigth="49px"
                className=""
                onClick={() => handleFood(!showNewService)}
              >
                {showNewService ? (
                  <b>Guardar</b>
                ) : (
                  <Fragment>
                    <ImageSticker
                      marg="-5px 20px auto auto"
                      width="30px"
                      src={iconAgregarAmigo}
                    />
                    <b>Agregar otro servicio</b>
                  </Fragment>
                )}
              </CardsBtn>
            )}
            <br />
            <br />
            <p style={{ fontSize: 14 }}>Si ya estás listo, continuemos.</p>
            <CardsBtn
              className={selectedServices.length === 0 ? 'hidden' : ''}
              mtop="20px"
              mtopw="20px"
              mtopmd="20px"
              type="button"
              min_w="100%"
              min_web="100%"
              onClick={() => handleSaveService()}
            >
              Conocer cuánto gasto al mes
            </CardsBtn>
          </QuestionsDivs>
        </TrainingQuestion>

        <CardComponents
          classCard={expenses ? 'cardOne' : 'hidden'}
          classTop="topOne1"
          text="Tu gasto mensual en servicios de transporte es de:"
          textbold={
            monthlyAmount >= 100
              ? `$${monthlyAmount}`
              : `$${monthlyAmount}, al año $${annualAmount}`
          }
          text2={
            textAnnual === ''
              ? `${sessionStorage.getItem('FirstName')}${
                TextAntExpenses.Market_Apps_Desserts.rangos[0].textAnnual
              }`
              : `${sessionStorage.getItem('FirstName')}${textAnnual}`
          }
          textbold2={monthlyAmount >= 100 ? `$${annualAmount}` : ''}
          text3={textAnnual === '' ? '' : 'Con este dinero podrás:'}
          Arraytext={feacturesArray}
        >
          <CardsBtn
            min_w="100%"
            windthLg="100%"
            max_w="250"
            onClick={() => setOrderRutine(nextOrderRoutine)}
          >
            Continuar
          </CardsBtn>
        </CardComponents>
      </div>
  );
};

export default TransportApps;
