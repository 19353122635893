import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import { CREATE_CLICK } from '../../mutations';
import {
  Image,
  Footer_container_Home,
} from '../common/common.styled';
import CoruLogo from '../../assets/img/home/CoruLogo.svg';
import GeneralLoader from '../common/GeneralLoader';
import Whatsapp from '../../assets/img/footer/Whatsapp.svg';
import Facebook from '../../assets/img/footer/Facebook.svg';
import Messenger from '../../assets/img/footer/Messenger.svg';
import ClickButton from '../../Helpers/HookHelpers';

function FooterFeed(props) {
  const { actions } = ClickButton();
  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted() {
        localStorage.setItem('activityDate', new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) { createClickFront({ variables: createClickInput }); }
    TagManager.dataLayer(tagManagerArgs);
  };
  const date = new Date();
  const anio = date.getFullYear();
  const history = useHistory();
  const [mostrarLoad] = useState(false);

  const handleClickAviso = () => {
    history.push('/privacy-notice');
    sendMongoAndGTM('ClickFooterPrivacy');
  };

  const handleClickConocenos = () => {
    // history.push('/about-us');
    actions({ action: 'ClickButtonCoruHome', variables: 'FooterNosotros', redirection: '/about-us'});
    sendMongoAndGTM('ClickFooterUs');
  };

  const handleClickAdvisers = () => {
    actions({ action: 'ClickButtonCoruHome', variables: 'FooterColaboradoresCoru', redirection: '/advisers'});
    // history.push('/advisers');
  };

  const handleClickBlog = () => {
    actions({ action: 'ClickButtonCoruHome', variables: 'FooterBlog', redirection: `https://coru.com/blog/`,
    href: true,});
    // history.push('/advisers');
  };

  const actualizaDatos = () => {
    setTimeout(() => {
      actualizaDatos();
    }, 1000);
  };

  const handleClick = (socialNetwork) => {
    actions({ action: 'ClickButtonCoruHome', variables: `RedSocial${socialNetwork}` });
    // history.push('/lp/categories-products');
  };

  useEffect(() => {
    actualizaDatos();
  });

  return (
    <Fragment>
      <Footer_container_Home color="#fff" backcolor="#2C3337" displayM="none">
        <div className="row  card-active" style={{ padding: '2% 3%' }}>
          <div className="col-2" style={{ textAlign: 'left' }}>
            <Image
              src={CoruLogo}
              marginTop="3%"
              className="img-fluid"
              bottom="3%"
              displayMobile="none"
              bottomMObile="none"
              width="92.28px"
            />
          </div>
          <div className="col">
            <p
              onClick={handleClickConocenos}
              className="roboto"
              style={{ cursor: 'pointer' }}
            >
              Nosotros
            </p>
          </div>
          <div className="col">
            <p
              onClick={ handleClickBlog}
              className="roboto"
              style={{ cursor: 'pointer' }}
            >
              Blog
            </p>
          </div>
          <div className="col">
            <p
              onClick={handleClickAdvisers}
              className="roboto"
              style={{ cursor: 'pointer' }}
            >
              Colaboradores Coru
            </p>
          </div>
          <div className="col-6" style={{ textAlign: 'right' }}>
            <img
              src={Facebook}
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
              onClick={()=> handleClick('Facebook')}
            />
            <img
              src={Whatsapp}
              onClick={()=> handleClick('Whatsapp')}
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
            />
            <img src={Messenger} style={{ cursor: 'pointer' }} onClick={()=> handleClick('Messenger')} />
          </div>
        </div>
        <div className="row  card-active" style={{ padding: '2% 3%' }}>
          <div className="col-6"></div>
          <div className="col-6" style={{ textAlign: 'right' }}>
            <p>
              <span style={{ cursor: 'pointer' }} onClick={handleClickAviso}>
                Notice of privacy
              </span>
              <span> | </span>
              <span style={{ cursor: 'pointer' }} onClick={handleClickAviso}>
                Terms and Conditions
              </span>
              <span> | </span>
              <span style={{ cursor: 'pointer' }}>
                ©{anio} Coru. All Rights Reserved
              </span>
            </p>
          </div>
        </div>
      </Footer_container_Home>
      <Footer_container_Home
        color="#fff"
        backcolor="#2C3337"
        displayW="none"
        displayM="block"
      >
        <div className="row  card-active" style={{ padding: '2% 3%' }}>
          <div className="col-2 pl-1 pr-0 ml-1">
            <Image src={CoruLogo} className="img-fluid" bottomMObile="none" />
          </div>
          <div className="col">
            <p
              onClick={handleClickConocenos}
              className="roboto"
              style={{
                cursor: 'pointer',
                marginBottom: '0',
                paddingBottom: '0',
                marginTop: '5px',
              }}
            >
              Nosotros
            </p>
          </div>
          <div className="col">
            <p
              onClick={handleClickBlog}
              className="roboto"
              style={{
                cursor: 'pointer',
                marginBottom: '0',
                paddingBottom: '0',
                marginTop: '5px',
              }}
            >
              Blog
            </p>
          </div>
          <div className="col">
            <p
              onClick={handleClickAdvisers}
              className="roboto"
              style={{
                cursor: 'pointer',
                marginBottom: '0',
                paddingBottom: '0',
                marginTop: '5px',
              }}
            >
              Colaboradores Coru
            </p>
          </div>
          <div className="col-12 mt-2" style={{ textAlign: 'left' }}>
            <img
              src={Facebook}
              onClick={()=> handleClick('Facebook')}
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
            />
            <img
              src={Whatsapp}
              onClick={()=> handleClick('Whatsapp')}
              style={{ marginRight: '.5rem', cursor: 'pointer' }}
            />
            <img src={Messenger} style={{ cursor: 'pointer' }} onClick={()=> handleClick('Messenger')} />
          </div>
        </div>
        <div className="row  card-active" style={{ padding: '2% 3%' }}>
          <div className="col-4"></div>
          <div className="col-8" style={{ textAlign: 'right' }}>
            <p>
              <span style={{ cursor: 'pointer' }} onClick={handleClickAviso}>
                Notice of privacy
              </span>
              <span> | </span>
              <span style={{ cursor: 'pointer' }} onClick={handleClickAviso}>
                Terms and Conditions
              </span>
              <span> | </span>
              <span style={{ cursor: 'pointer' }}>
                ©{anio} Coru. All Rights Reserved
              </span>
            </p>
          </div>
        </div>
      </Footer_container_Home>
      {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
    </Fragment>
  );
}

export default FooterFeed;
