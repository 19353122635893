import React, { useState } from 'react';
// Import visual components
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Dialog } from '@material-ui/core';

import DialogContentText from '@material-ui/core/DialogContentText';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
         <Typography variant="h6">{children}</Typography>
         {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
               <CloseIcon />
            </IconButton>
         ) : null}
      </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const ModalDialog = ({
  title, text, showBtn = false, handleBtn = null, showModal = false, children,
}) => {
  const [openModal, setOpenModal] = useState(showModal);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
      <Dialog open={openModal} aria-labelledby="form-dialog-title">
         <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
            {title}
         </DialogTitle>
         <DialogContent>
            <DialogContentText>{text}</DialogContentText>

            {children}
         </DialogContent>
         <DialogActions>
            {showBtn ? (
               <CardsBtn type="button" min_w="100%" min_web="100%" onClick={(e) => handleBtn(e)}>
                  Guardar
               </CardsBtn>
            ) : null}
         </DialogActions>
      </Dialog>
  );
};

export default ModalDialog;
