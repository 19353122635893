import React, { useState, Fragment, useEffect } from 'react';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  DYNAMIC_GET,
  DYNAMIC_INSERT,
  CREATE_SESSION_MYSQL,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  GET_TRAINING_COLOR,
  CHECK_TRAINING_FINISHED,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import imgWo from '../../assets/img/anacoach/PrimerInversion.svg';
import RoutinesOrderFirstInversion from './RoutinesOrderFirstInversion';
import WaitList from '../../components/Training/WaitList';
import SuccessBullets from '../../components/Training/SuccessBullets';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import WOlayout from '../../components/layouts/WOlayout';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import moment from 'moment';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';

const items2 = ['Mi primera inversión'];
const FirstInversion = () => {
  const { actions } = GeneralHooks();
  // copia y pega estos states para crear un nuevo entrenamiento
  sessionStorage.setItem('idTraining', 17);
  const { sendEmail } = SendNotificationEmail();
  const { sendMessage } = SendNotificationFTAndCC();
  const anchop = window.screen.width;
  const [reward, setReward] = useState(false);
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();
  const [getIDTriggerNotification] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet) {
        const response = JSON.parse(dynamicGet.response);
        debugger;
        const { id } = response[0];
        insertCatNotificationTracking({
          variables: {
            input: {
              table: 'CatNotificationTracking',
              columns: ['DatSession_IDDatSession', 'CatTriggerNotification_Id'],
              detailValues: {
                values: [sessionStorage.getItem('idDatSession'), String(id)],
              },
            },
          },
        });
      }
    },
  });
  const [insertCatNotificationTracking] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert) {
        console.log(dynamicInsert);
      }
    },
  });
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const triggerName = params.get('trigger');
    if (triggerName && sessionStorage.getItem('idDatSession')) {
      console.log(triggerName);
      getIDTriggerNotification({
        variables: {
          input: {
            table: 'CatTriggerNotification',
            columns: ['*'],
            conditions: [
              {
                valone: 'triggerName',
                condition: '=',
                valtwo: triggerName,
              },
            ],
          },
        },
      });
    }
  }, [sessionStorage.getItem('idDatSession')]);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });
  const [nipAsignado] = useState('');
  useEffect(() => {
    if (sessionStorage.getItem('nipVirality')) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'Otpemail',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [nipAsignado]);

  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
    sendEmail({ WO: 'FirstInv', trigger: '1er_inv_alerta1' });
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone') || '',
      trigger: 'wa_inv_1',
    });
  }, []);
  // End Valida WaitList
  const [arrayOrderRoutines] = useState([]);
  const [saludo, setSaludo] = useState('No');
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(17);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  // copia y pega estas mutaciones para crear un nuevo entrenamiento
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [recomendaciones, setRecomendaciones] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  const LPpageplanes = sessionStorage.getItem('LPpageplanes') === 'true';
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });

    if (
      LPpageplanes
      && sessionStorage.getItem('FirstName')
      && sessionStorage.getItem('FathersLastName')
      && sessionStorage.getItem('Email1')
      && sessionStorage.getItem('MothersLastName')
      && sessionStorage.getItem('day')
      && sessionStorage.getItem('month')
      && sessionStorage.getItem('year')
      && sessionStorage.getItem('cellPhone')
    ) {
      setOrderRutine(2);
    }
  }, []);
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser !== null) {
      checkTrainingFinished({
        variables: {
          idUser: parseInt(idUser),
          idTraining: currentTraining,
        },
      });
    }
  }, []);
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });
  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        actions({ action: 'getAchievment', variables: '' });
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Cupon from Virality Test
    const wosource = getQueryVariable('utm_wosource');
    const coupon = getQueryVariable('utm_coupon');
    const hasReward = wosource === 'viralityTest' && coupon === 'coupon';
    setReward(hasReward);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [active0, setactive0] = useState(false);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    pushArrayOrderRoutine(orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      arrayOrderRoutines.push(orderRoutine);
    }
  };
  useEffect(() => {
    if (orderRutine !== 5) {
      pushArrayOrderRoutine(orderRutine);
      console.log('orderRutine', orderRutine);
      saveAdvance(orderRutine);
    }
  }, [orderRutine]);
  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const stringOrderRoutines = arrayOrderRoutines.join(',');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: stringOrderRoutines,
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      console.log('saveStatus >>> ', inputProgress);
      saveStatusTraining({ variables: inputProgress });
    }
  };

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: currentTraining },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);
  // use useEffect para obtener el deeplink o source, medium o capanign
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
    } = getQueryVariableHashtagDeeplink('FirstInversion');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'FirstInversion');
    sessionStorage.setItem('hostname', window.location.hostname);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
      content,
    );
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }

    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
        viralityID,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const allowOverwrite = sessionStorage.getItem(`overwrite_WO_${currentTraining}`) || 'false';
        const firstName = sessionStorage.getItem('FirstName') || '';
        if (trainingInfo.avance === 100 && allowOverwrite !== 'true') {
          setFinished(true);
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            bullets: trainingInfo.bullets,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: `¡Hola, ${firstName}!`,
            txt: `Realizaste este entrenamiento el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} y ahora sabes:`,
          });
        }

        if (allowOverwrite === 'true') {
          setOrderRutine(2);
          sessionStorage.removeItem('PreguntasdePerfilamientoInversion');
          sessionStorage.removeItem('MontoInversion');
          sessionStorage.removeItem('PlazoInversion');
          sessionStorage.removeItem('InversionObtenida');
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  // mover barra de avance según posición scroll
  const [checkScroll, setCheckScroll] = useState(true);
  const scroll1 = document.documentElement.scrollTop;
  // mover barra de avance según posición scroll
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  const feacturesArray = useState([
    {
      text: 'Con cuánto puedes empezar',
      color: '#6BD5AF',
    },
    {
      text: 'Cuánto puedes ganar',
      color: '#6BD5AF',
    },
    {
      text: 'Mejores lugares para comenzar',
      color: '#6BD5AF',
    },
  ]);
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '5 minutos',
    imgWo: { imgWo },
    txtsubtitle: 'Todo lo que necesitas saber',
  };

  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>En Dónde Invertir mi Dinero | Coru</title>
        <link
          rel="canonical"
          href="https://coru.com/wo/first-investment"
        />
        <meta name="keywords" content="en donde invertir mi dinero" />
        <meta
          name="description"
          content="Algunas acciones son mejores que otras, todo se debe al rendimiento que te pueden dar. Si quieres saber cómo y en dónde entonces acá tienes esto..."
        />
      </Helmet>
      <TrainingMain>
        <div style={{position: 'relative'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {WOStatus ? (
              <Fragment>
                <TrainingAnimateFunnel
                  bcolor={colorTraining}
                  pmob="0 0 0 0"
                  margin="0 0 24px 0"
                  minh="309px"
                  hweb="410px"
                >
                  <BarraInfoWO propied={propied} />
                  {scroll1 > 0 ? <BarraScroll /> : null}
                  <ModalDataPersonalInitWO
                    isData={isData}
                    setData={setisData}
                  />
                  <TrainingLine
                    id="line-percent-gray"
                    mt=""
                    mtw="404px"
                  ></TrainingLine>
                  {percentage !== '' ? (
                    <TrainingLine
                      id="line-percent-pink"
                      bcolor="#F64282"
                      mt=""
                      mtw="404px"
                      wweb={percentage}
                      w={percentage}
                      mr="auto"
                      op="1"
                      br="100px"
                    ></TrainingLine>
                  ) : null}
                </TrainingAnimateFunnel>
                {!finished ? (
                  <Fragment>
                    <DataPersonalInitWO
                      Date={Date}
                      hora={hora}
                      orderRutine={orderRutine}
                      setOrderRutine={setOrderRutine}
                      currentTraining={currentTraining}
                      createJsonInput={createJsonInput}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                    <TrainingSectionGray
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="En este entrenamiento aprenderás:"
                          />
                          {active0 ? (
                            <Fragment>
                              <Features
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title=""
                                Arraytext={feacturesArray}
                              />
                            </Fragment>
                          ) : null}
                          {saludo === 'Si' ? (
                            <TrainingDivSection
                              bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                              mt="44px"
                              mtw="225px"
                              br="4px"
                              padding="21px 20px"
                              wi="335px"
                              wiw="670px"
                              he="auto"
                              hew="auto"
                              mb="66px"
                            >
                              <div className="triangulo-equilatero-bottom"></div>
                              <div
                                className="closeDiv"
                                onClick={() => setSaludo('No')}
                              >
                                ×
                              </div>
                              <TrainingSubtitles
                                size="20px"
                                fw="600"
                                align="left"
                                alignweb="left"
                                className="cabin"
                              >
                                ¡Hola! Soy Ana, tu coach financiero.
                              </TrainingSubtitles>
                              <TrainingText
                                size="16px"
                                className="roboto"
                                align="left"
                                alignweb="left"
                                ls="-0.2px"
                              >
                                Te ayudaré en tu proceso para obtener tu crédito
                                personal.
                              </TrainingText>
                            </TrainingDivSection>
                          ) : null}
                        </div>

                        {active1 ? (
                          <Fragment>
                            <div
                              className={
                                orderRutine === 4
                                  ? 'marginComentLgSuccess1'
                                  : 'marginComentLgSuccess1'
                              }
                            >
                              <TrainingSectionGray
                                wiw="100%"
                                mw="auto"
                                back="#FAFAFA"
                                mtop="0"
                              >
                                <TrainingDivSection2
                                  paddiw="0"
                                  paddi="0"
                                  back="#FAFAFA"
                                >
                                  <RoutinesOrderFirstInversion
                                    Date={stateDate}
                                    hora={hora}
                                    orderRutine={orderRutine}
                                    setOrderRutine={setOrderRutine}
                                    currentTraining={currentTraining}
                                    createJsonInput={createJsonInput}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    saveAdvance={saveAdvance}
                                    reward={reward}
                                    trainingColor={colorTraining}
                                  />
                                </TrainingDivSection2>
                              </TrainingSectionGray>
                            </div>
                            {recomendaciones && orderRutine === 4 ? (
                              <Fragment>
                                <div className="marginComentLgSuccess1">
                                  <Container
                                    mlMobile="auto"
                                    mRMobile="auto"
                                    mleftmxs="0%"
                                    mleft="18%"
                                    mleftm="7%"
                                    width="100%"
                                    widthMobile="100%"
                                    mtMobile="0px"
                                  >
                                    <Feed_Section
                                      padding="3% 0 5% 0"
                                      paddingm="0% 5% 5% 5%"
                                    >
                                      <ContentHomeSection
                                        leftD="0%"
                                        floatD=" "
                                        width="80%;"
                                      >
                                        <Recommendations
                                          items={recommendedTraining}
                                        ></Recommendations>
                                      </ContentHomeSection>
                                    </Feed_Section>
                                  </Container>
                                </div>
                              </Fragment>
                            ) : null}
                          </Fragment>
                        ) : null}
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    {!active1 ? (
                      <Fragment>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="0rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio topMobile="35px" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                    <br />
                  </Fragment>
                ) : (
                  <Fragment>
                    <SuccessBullets {...trainingFinishedInfo} />
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <br />
                <br />
                <br />
                <br />
                {executeQuery ? <WaitList /> : null}
              </Fragment>
            )}
          </div>
          <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
          </div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
};

export default FirstInversion;
