import React from 'react';
import {
  ModalBackground, ModalBackCC, ModalHeader, ModalBody,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle24,
  Title1,
} from './Home.styled';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';
import Ana from '../../assets/img/anacoach/anavalordinero.png';
import { Image } from './common.styled';

const ModalNodisponible = ({ setModalState, handleReminder, idProduct }) => {
  const name = sessionStorage.getItem('FirstName');

  return (
        <>
            {
                <ModalBackground display={'true'}>
                    <ModalBackCC>
                        <Rectangle23 position="absolute" background="#91D770" Mleft="-30px" Mtop="-30px" width="60px" height="60px" left=" " />
                        <Rectangle23 position="absolute" background="#E3E660" Mleft="90px" Mtop="-9px" width="28px" height="32px" transform="rotate(45deg)" left="58px" top="-27px" />
                        <Rectangle23 position="absolute" background="#6BD5AF" Mleft="190px" Mtop="-30px" width="62.36px" height="67px" transform="rotate(45deg)" left="171px" />
                        <Rectangle23 position="absolute" background="#E3E660" Mleft="280px" Mtop="40px" width="42.53px" height="45.22px" transform="rotate(45deg)" left=" " />
                        <Rectangle23 position="absolute" background="#E3E660" Mleft="-60px" Mtop="140px" width="60px" height="60px" transform="rotate(45deg)" left=" " />
                        <Rectangle24 positionD="absolute" radius="50px" width="145px" left="266px" top="118px" widthM="145px" heightM="" mLeftM=" " mTopM=" " />
                        <div style={{
                          position: 'absolute', right: '20px', top: '10px', fontSize: '18px', cursor: 'pointer',
                        }} onClick={() => setModalState(false)}>X</div>
                        <ModalHeader>
                            <Image src={Ana} maxwidth="82px" />
                            <Title1 size="23px" aling="center" mt=" " sizeM="18px" mleftM=" " mtopM=" " heightM=" ">
                                ¡Estamos por <br />cumplir tu sueño!
                            </Title1>
                        </ModalHeader>
                        <ModalBody>
                            <span style={{ padding: '12px 5px' }}>
                                Hola, {name} te comentamos que seguimos trabajando para ti y poderte apoyar para que puedas obtener
                                esta tarjeta. ¿Te gustaría que te avisáramos cuando tengamos esta tarjeta en nuestro catálogo?
                            </span>
                            <CardsBtn mtopmd="20px" mtop="20px" mtopw="20px" min_w="100%" min_web="100%" onClick={() => handleReminder(idProduct)}>
                                Quiero que me avisen
                            </CardsBtn>
                            <CardsBtn
                                color="#EC407A !important"
                                back="#fff !important"
                                border="solid 1px !important"
                                mtopmd="20px"
                                mtop="20px"
                                mtopw="20px"
                                min_w="100%"
                                min_web="100%"
                                onClick={() => setModalState(false)}
                            >
                                Salir
                            </CardsBtn>
                        </ModalBody>
                    </ModalBackCC>
                </ModalBackground>
            }
        </>
  );
};

export default ModalNodisponible;
