import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

export const LinkComponent = ({ className, href = '/', label }) => (
  <Fragment>
    <Link to={href} className={className}>
      {label}
    </Link>
  </Fragment>
);

export const imageComponent = ({ className, src, alt, onClick }) => (
  <img className={className} alt={alt} src={src} onClick={onClick} />
);

export const LinkFooter = ({ className, href, label }) => (
  <Fragment>
    <Link to={href} className={className}>
      {label}
    </Link>
  </Fragment>
);
