import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField, Select } from '@material-ui/core';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import MenuItem from '@material-ui/core/MenuItem';
import { months } from '../../../Helpers';
import { makeStyles } from '@material-ui/core/styles';
import DynamicDropdown from './DynamicDropdown';
import { INSERT_FORM_ADVISERS } from '../../../mutations';
import {
  GET_CATS_XCOACH,
  GET_CATALOGO_XCOACH,
} from '../../../queries';
import {
  ContainerSection,
  Subtitle,
  ContainerBox,
  Buttons,
  Lista,
  TextArea,
} from '../Styleds/Asesores.Styled.js';
// import CommentsDirector from './CommentsDirector';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '',
  },
  formControl: {
    width: '330px',
    fontSize: '16px',
  },
}));

const UnfinishedTraining = ({
  userInfo,
  tabTraining,
  setTabTraining,
  setShowTyping,
  setTyping,
  setBtnDisabled,
  getPendingSessions,
  idasesoresdetails,
}) => {
  const { idUser } = userInfo.basicInfo;
  const { cellPhone } = userInfo.basicInfo;
  const { formCoach } = userInfo.formData;
  const [DataXcoachForm] = useState(userInfo.DataXcoach);
  const [typesSession, setTypesSession] = useState([]);
  const [procedencia, setProcedencia] = useState([]);
  const [cuentacoru, setCuentacoru] = useState([]);
  const [recomendado, setRecomendado] = useState([]);
  const [tema, setTema] = useState([]);
  const [requerimiento, setRequerimiento] = useState([]);
  const [tarjeta, setTarjeta] = useState([]);
  const [banco, setBanco] = useState([]);
  const [tramite, setTramite] = useState([]);
  const [tramitecorrecto, setTramitecorrecto] = useState([]);
  const [Producto1, setProducto1] = useState([]);
  const [Producto2, setProducto2] = useState([]);
  const [Producto3, setProducto3] = useState([]);
  const [workout, setWorkout] = useState([]);
  const [wosucces, setWosucces] = useState([]);
  const [prestamo, setPrestamo] = useState([]);
  const [financiera, setFinanciera] = useState([]);
  const [workouts, setWorkoutS] = useState([]);
  const [acercatramite, setAcercaTramite] = useState([]);
  const [partecoru, setPartecoru] = useState([]);
  const [solucionado, setSolucionado] = useState([]);
  const [proyecto, setProyecto] = useState([]);
  const [eproyecto, setEproyecto] = useState([]);
  const [btn, setBtn] = useState(true);
  // const [commentsxcoach, setCommentsXcoach] = useState(['']);
  const { data: Catalogo } = useQuery(GET_CATALOGO_XCOACH);
  const [form, setForm] = useState({});
  const [formsession, setFormSession] = useState({
    lstCoach: [],
    sessionTipification: '',
    sessionComments: '',
    errorLink: '',
    errorDescription : '',
    customerDoubt: '',
    idCoach: parseInt(sessionStorage.getItem('idUser') || 0),
    idCatAsesoresUpdate: idasesoresdetails !=='' ? parseInt(idasesoresdetails) : 0,
    idUser,
  });
  let { lstCoach, sessionTipification } = formsession;
 
  const classes = useStyles();

  const changeSessionTipification = (e) => {
    if (e.target.value !== 'Seleccionar') {
      e.persist();
      setForm({
        ...form,
        [e.target.name]: `${e.target.value}`,
      });
    }
  };
  const changeSession = (e) => {
      setFormSession({
        ...formsession,
        [e.target.name]: e.target.value,
      });
  };

  const insertInformation = () => {
    setBtn(false);
    if (sessionTipification !== 'Seleccionar' && sessionTipification !== '') {
      insertFormAdvisers({ variables: { input: formsession } });
    }
  };

  const [insertFormAdvisers] = useMutation(INSERT_FORM_ADVISERS, {
    onCompleted({ insertFormAdvisers }) {
      if (insertFormAdvisers.statusCode === 200) {
        const found = tabTraining.find((e) => e.idUser === idUser);
        tabTraining.map((e) => (e.active = false));
        if (sessionTipification === 'Pendiente' && !found) {
          setTabTraining([
            ...tabTraining,
            { idUser, cellPhone, active: false },
          ]);
        }
        sessionStorage.setItem('txtToSearchAsesores', '');
        sessionStorage.setItem('idUserToSearchAsesores', '');
        setTyping(sessionTipification);
        setShowTyping(true);
        setBtnDisabled(true);
        getPendingSessions();
      }
    },
    onError(err) {
      setBtn(true);
      console.log(err);
    },
  });
  
  const [getCatsXCoach] = useLazyQuery(GET_CATS_XCOACH, {
    fetchPolicy: 'no-cache',
    onCompleted({ getCatsXCoach }) {
      if (getCatsXCoach.message === 'success') {
        const { typesSession } = JSON.parse(
          getCatsXCoach.response,
        );
        setTypesSession(typesSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  
  useEffect(() => {   
    getCatsXCoach({ variables: { idUser } });
  }, []);
  
  useEffect(() => {
    if (Catalogo) {
      const { getOptionsXcoach } = Catalogo;
      const json = JSON.parse(getOptionsXcoach.response);
      setProcedencia(json[0].DetailNewCoach[0]);
      setCuentacoru(json[1].DetailNewCoach[0]);
      setRecomendado(json[2].DetailNewCoach[0]);
      setTema(json[3].DetailNewCoach[0]);
      setRequerimiento(json[4].DetailNewCoach[0]);
      setTarjeta(json[5].DetailNewCoach[0]);
      setBanco(json[6].DetailNewCoach[0]);
      setTramite(json[7].DetailNewCoach[0]);
      setTramitecorrecto(json[8].DetailNewCoach[0]);
      setProducto1(json[9].DetailNewCoach[0]);
      setProducto2(json[10].DetailNewCoach[0]);
      setProducto3(json[11].DetailNewCoach[0]);
      setWorkout(json[12].DetailNewCoach[0]);
      setWosucces(json[14].DetailNewCoach[0]);
      setPrestamo(json[15].DetailNewCoach[0]);
      setFinanciera(json[16].DetailNewCoach[0]);
      setWorkoutS(json[17].DetailNewCoach[0]);
      setAcercaTramite(json[18].DetailNewCoach[0]);
      setPartecoru(json[19].DetailNewCoach[0]);
      setSolucionado(json[20].DetailNewCoach[0]);
      setProyecto(json[21].DetailNewCoach[0]);
      setEproyecto(json[22].DetailNewCoach[0]);
    }

  }, [Catalogo]);

  useEffect(() => {
   lstCoach = JSON.stringify(Object.values(form));
  setFormSession({
    ...formsession,
    lstCoach,
  });
  }, [form]);

  useEffect(() => {
    if(DataXcoachForm !== '' && DataXcoachForm.length > 0) {
      setForm(DataXcoachForm[0]);
    };    
  }, [DataXcoachForm]);

  return (
    <>
      {
        <ContainerSection
          bckC="#ffff"
          dis="flex"
          fDir="row"
          hei="auto"
          marL="175px"
          marT="20px"
          marB="40px"
        >
          <ContainerBox
            dis="flex"
            wid="329px"
            fDir="column"
            hei="auto"
            marR="60px"
          >
            <Subtitle className="roboto">
              Procedencía del chat
            </Subtitle>
            <FormControl variant="outlined">
              <InputLabel
                className="roboto"
              >
                Procedencia del chat
              </InputLabel>
              <Select
                name="chatOrigin"
                value={form.chatOrigin ? form.chatOrigin : ''}
                variant="outlined"
                label="Procedencia del chat"
                onChange={(e) => changeSessionTipification(e)}                
              >
                {Object.values(procedencia).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  >
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Subtitle className="roboto">
              Registro de cuenta Coru
            </Subtitle>
            <FormControl variant="outlined">
              <InputLabel
                className="roboto"
              >
                Registro de cuenta Coru
              </InputLabel>
              <Select
                name="coruAccount"
                value={form.coruAccount ? form.coruAccount: ''}
                onChange={(e) => changeSessionTipification(e)}
                variant="outlined"
                label="Procedencia del chat"
              >
                {Object.values(cuentacoru).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Subtitle className="roboto">
              WOs recomendados
            </Subtitle>
            <FormControl variant="outlined">
              <InputLabel
                className="roboto"
              >
                WOs recomendados
              </InputLabel>
              <Select
                name="recommendedWOs"
                value={form.recommendedWOs ? form.recommendedWOs : ''}
                onChange={(e) => changeSessionTipification(e)}
                variant="outlined"
                label="WOs recomendados"
              >
                {Object.values(recomendado).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Subtitle className="roboto">
              Tema de interes
            </Subtitle>
            <FormControl variant="outlined">
              <InputLabel
                className="roboto"
              >
                Tema de interés
              </InputLabel>
              <Select
                name="interestTopic"
                value={form.interestTopic ? form.interestTopic : ''}
                onChange={(e) => changeSessionTipification(e)}
                variant="outlined"
                label="Tema de interés"
              >
                {Object.values(tema).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Subtitle className="roboto">
              Fecha de entrada
            </Subtitle>
            <FormControl variant="outlined">
                <TextField
                  id="entryDate"
                  name="entryDate"
                  type='date'
                  value={form.entryDate ? form.entryDate : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
              />
            </FormControl>
            <Subtitle className="roboto">
              Mes de entrada
            </Subtitle>
            <FormControl variant="outlined">
              <InputLabel
                className="roboto"
              >
                Mes de entrada
              </InputLabel>
              <Select
                name="entryMonth"
                value={form.entryMonth ? form.entryMonth : ''}
                onChange={(e) => changeSessionTipification(e)}
                variant="outlined"
                label="Mes de entrada"
              >
                {Object.values(months).map((data, index) => (
                  <MenuItem key={index} value={data.label}>
                  {data.label}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
              <DynamicDropdown title="Comentarios">
                <>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <TextArea
                      className={classes.texTa}
                      rowsMin={3}
                      id="sessionComments"
                      name="sessionComments"
                      placeholder="Comentario"
                      variant="outlined"
                      value={formsession.sessionComments}
                      onChange={(e) => changeSession(e)}       
                    />
                  </FormControl>
                  <br/>
                  <DynamicDropdown title="Ver Comentarios">
                  <Lista dis="flex" fDir="column" padd="0px">
                    {formCoach.map((data, index) => (
                      <MenuItem key={index}>
                        {data.sessionComments}
                      </MenuItem>
                      
                    ))}
                  </Lista>
                  </DynamicDropdown>
                </>
              </DynamicDropdown>
          </ContainerBox>

          <ContainerBox
            dis="flex"
            wid="329px"
            fDir="column"
            hei="auto"
            marR="60px"
          >
            <DynamicDropdown title="Buró de crédito">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Tipo de requerimiento
                </InputLabel>
                <Select
                  name="requirementType"
                  value={form.requirementType ? form.requirementType : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Tipo de requerimiento"
                >
                  {Object.values(requerimiento).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
            <DynamicDropdown title="Adquirir tarjeta">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Uso de tarjeta
                </InputLabel>
                <Select
                  name="cardUse"
                  value={form.cardUse ? form.cardUse : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Uso de tarjeta"
                >
                  {Object.values(tarjeta).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Banco
                </InputLabel>
                <Select
                  name="bank"
                  value={form.bank ? form.bank : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Banco"
                >
                  {Object.values(banco).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Wo success
                </InputLabel>
                <Select
                  name="WOsuccess"
                  value={form.WOsuccess ? form.WOsuccess : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Wo success"
                >
                  {Object.values(wosucces).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Acerca del trámite
                </InputLabel>
                <Select
                  name="aboutTramite"
                  value={form.aboutTramite ? form.aboutTramite : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Acerca del trámite"
                >
                  {Object.values(tramite).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
            <DynamicDropdown title="Seguimiento de solicitud">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  ¿Trámite correcto?
                </InputLabel>
                <Select
                  name="correctTramite"
                  value={form.correctTramite ? form.correctTramite : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="¿Trámite correcto?"
                >
                  {Object.values(tramitecorrecto).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Producto 1
                </InputLabel>
                <Select
                  name="product1"
                  value={form.product1 ? form.product1 : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Producto 1"
                >
                  {Object.values(Producto1).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Producto 2
                </InputLabel>
                <Select
                  name="product2"
                  value={form.product2 ? form.product2 : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Producto 2"
                >
                  {Object.values(Producto2).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Producto 3
                </InputLabel>
                <Select
                  name="product3"
                  value={form.product3 ? form.product3 : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Producto 2"
                >
                  {Object.values(Producto3).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
            <DynamicDropdown title="Ayuda con flujo WOs">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Work Out
                </InputLabel>
                <Select
                  name="workOut"
                  value={form.workOut ? form.workOut : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Work Out"
                >
                  {Object.values(workout).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  id="customerDoubt"
                  name="customerDoubt"
                  placeholder="Duda del cliente"
                  variant="outlined"
                  type="text"
                  value={formsession.customerDoubt ? formsession.customerDoubt : ''}
                  onChange={(e) => changeSession(e)}                
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  ¿WO success?
                </InputLabel>
                <Select
                  name="succeedWO"
                  value={form.succeedWO ? form.succeedWO : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="¿WO success?"
                >
                  {Object.values(wosucces).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
            <Subtitle className="roboto" marB="20px">
              Tipificación de la sesión.
            </Subtitle>
            <FormControl variant="outlined">
              <InputLabel
                className="roboto"
                id="demo-simple-select-outlined-label"
              >
                Tipificación de la sesion
              </InputLabel>
              <Select
                name="sessionTipification"
                value={formsession.sessionTipification}
                onChange={(e) => changeSession(e)}
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                label="Tipificación de la sesion"
              >
                {typesSession.map((e) => (
                  <MenuItem key={e.id} value={e.description}>
                    {e.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {btn ? (
              <Buttons
                bcK="#3F4D67"
                marT="20px"
                type="button"
                style={{ margin: '0 auto', width: '50%' }}
                onClick={insertInformation}
              >
                Tipificar
              </Buttons>
            ) : null }
          </ContainerBox>

          <ContainerBox dis="flex" wid="329px" fDir="column" hei="auto">
            <DynamicDropdown title="Adquirir préstamo">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  ¿Uso de préstamo?
                </InputLabel>
                <Select
                  name="lendingUse"
                  value={form.lendingUse ? form.lendingUse : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="¿Uso de préstamo?"
                >
                  {Object.values(prestamo).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Financiera
                </InputLabel>
                <Select
                  name="Finance"
                  value={form.Finance ? form.Finance : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Financiera"
                >
                  {Object.values(financiera).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  WO success
                </InputLabel>
                <Select
                  name="woSuccessFinance"
                  value={form.woSuccessFinance ? form.woSuccessFinance : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="WO success"
                >
                  {Object.values(workouts).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Acerca del trámite
                </InputLabel>
                <Select
                  name="aboutTramiteloan"
                  value={form.aboutTramiteloan ? form.aboutTramiteloan : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Acerda del trámite"
                >
                  {Object.values(acercatramite).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
            <DynamicDropdown title="Error en el portal">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  ¿En que parte de Coru?
                </InputLabel>
                <Select
                  name="errorPlacement"
                  value={form.errorPlacement ? form.errorPlacement : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="¿En que parte de Coru?"
                >
                  {Object.values(partecoru).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <TextField
                id="errorLink"
                name="errorLink"
                value={formsession.errorLink ? formsession.errorLink : ''}
                onChange={(e) => changeSession(e)}
                variant="outlined"
                placeholder="Link"
              />
              <TextField
                id="errorDescription"
                name="errorDescription"
                value={formsession.errorDescription ? formsession.errorDescription : ''}
                onChange={(e) => changeSession(e)}
                variant="outlined"
                placeholder="Descripción"
              />
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  ¿Solucionado?
                </InputLabel>
                <Select
                  name="errorStatus"
                  value={form.errorStatus ? form.errorStatus : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="¿Solucionado?"
                >
                  {Object.values(solucionado).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
            <DynamicDropdown title="Estatus de proyectos">
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Proyecto
                </InputLabel>
                <Select
                  name="project"
                  value={form.project ? form.project : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Proyecto"
                >
                  {Object.values(proyecto).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel
                  className="roboto"
                >
                  Estatus
                </InputLabel>
                <Select
                  name="Estatusdeproyecto"
                  value={form.Estatusdeproyecto ? form.Estatusdeproyecto : ''}
                  onChange={(e) => changeSessionTipification(e)}
                  variant="outlined"
                  label="Estatus"
                >
                  {Object.values(eproyecto).map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.idCatDetailNewCoach}
                    name={data.QuestionsOption}
                  > 
                    {data.QuestionsOption}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </DynamicDropdown>
          </ContainerBox>
        </ContainerSection>
      }
    </>
  );
};

export default UnfinishedTraining;
