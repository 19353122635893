import React, { useState,useEffect } from 'react';
import WOlayout from '../../components/layouts/WOlayout';
import Logo from '../../assets/img/anacoach/CoruLogoB.png';
import Coppel from '../../assets/img/anacoach/Coppel.png';
import CardsCoppel from '../../assets/img/anacoach/CardsCoppel.svg';
import ManoCoppel from '../../assets/img/anacoach/ManoCoppel.svg';
import Listed from '../../assets/img/anacoach/ListedCoppel.svg';
import Form from '../../assets/img/anacoach/form-line-coppel.svg';
import Key from '../../assets/img/anacoach/coppelkey.svg';
import styled from 'styled-components';
import {
  Container1,
  Subtitle,
  Title2,
} from '../../components/common/Home.styled';
import {
  Container,
  CustomLi,
  UlCoppel
} from '../../components/common/common.styled';
import {
  setSessionVariablesUserContactCoopel,
  setSessionVariablesUserDetailsCoppel,
  setSessionVariablesuserMediumCoopel
} from '../../Helpers/setSessionVariables';
import {
  GET_USER_CONTACT,
  GET_USER_DETAILS,
  GET_USER_MEDIUM
} from '../../queries';
import { useLazyQuery, useQuery } from '@apollo/client';

const LpCoppel = () => {
  const [heigthBrowser] = useState(window.outerHeight);
  const [widthBrowser] = useState(window.outerWidth);
  const url = new URL(window.location.href);
  const idUser = url.searchParams.get('id') || '';
  if(idUser!="")
  {
    sessionStorage.setItem('idUser', idUser);
  }
  const idCont = url.searchParams.get('idCont') || '';
  const [getUserDetails, { data: userDetailsData }] = useLazyQuery(GET_USER_DETAILS);
  const [getUserContact, { data: userContact }] = useLazyQuery(GET_USER_CONTACT);
  const [getUserMedium, { data: userMedium }] = useLazyQuery(GET_USER_MEDIUM);
  useEffect(() => {
    console.log({ idUser, idCont });
    if (idUser) getUserDetails({ variables: { idUser } });
    if (idCont) getUserContact({ variables: { idCont } });
    if (idCont) getUserMedium({ variables: { idCont } });
  }, []);

  useEffect(() => {
    if (userContact) {
      const { response } = userContact.getUserContact;
      if (response) setSessionVariablesUserContactCoopel(response);
    }
  }, [userContact]);

  useEffect(() => {
    if (userMedium) {
      const { response } = userMedium.getUserMedium;
      if (response) setSessionVariablesuserMediumCoopel(response);
    }
  }, [userMedium]);

  useEffect(() => {
    if (userDetailsData) {
      const { response } = userDetailsData.getUserDetails;
      if (response) setSessionVariablesUserDetailsCoppel(response);
    }
  }, [userDetailsData]);
  
  return (
    <WOlayout>
      <div className="navbar navbar-light bg-light shadow-sm">
        <a href="#" className="navbar-brand">
          <img src={Logo} alt="" style={{ maxWidth: '91px' }} />
        </a>
        <a href="#" className="ml-auto navbar-brand">
          <img src={Coppel} alt="" style={{ maxWidth: '91px', maxHeight: '32px' }} />
        </a>
      </div>
      <Container1>
        <Container
          width="85%"
          mt="70px"
          widthMobile="100%"
          mb="0%"
          mtMobile="75px"
          paddingMobile="20px"
        >
          <div className="row justify-content-between">
            <div className="d-block d-md-none" style={{ marginBottom: '40px' }}>
              <img src={CardsCoppel} alt="Coppel" className="img-fluid" />
            </div>
            <div className="col-lg-5 col-md-6 col-12 ">
              <Title2
                sizeD="50px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="3%"
                className="roboto"
                fSizeMd="30px"
                sizem="25px"
                top="0px"
                mleftm="0px"
                widthM="100%"
                width="518px"
              >
                Obtén tu crédito, fácil y rápido
              </Title2>
              <Subtitle
                className="roboto"
                widthM="100%"
                mtopM="0%"
                mbottomM="5%"
                width="100%"
                sizeMm992="15px"
                sizeM="18px"
                heightM=" "
                mleftM="0px"
                sizeM640="18px"
              >
                Solicita tu tarjeta y entra al mundo financiero sin necesidad de comprobar ingresos.
              </Subtitle>
              <Button
                className="roboto"
                mt="20px"
                active
                maxwidth=" "
                onClick={() => window.location= "/lp/coppelAdress"}
              >
                Solicitar
              </Button>
            </div>
            <div className="d-flex col-lg-5 col-md-6 col-12 align-items-center">
              <img src={CardsCoppel} alt="Coppel" className="img-fluid d-none d-md-block" />
            </div>
          </div>
          <div className="row justify-content-between" style={{marginTop: '4rem'}}>
            <div className="d-flex col-lg-5 col-md-6 col-12 align-items-center">
              <img src={ManoCoppel} alt="Mano" className="img-fluid" />
            </div>
            <div className="col-lg-5 col-md-6 col-12">
              <Title2
                sizeD="30px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="3%"
                className="roboto"
                fSizeMd="30px"
                sizem="25px"
                top="0px"
                mleftm="0px"
                widthM="100%"
                width="518px"
              >
                Beneficios
              </Title2>
              <UlCoppel className="roboto">
                <CustomLi image={Listed}>Sin anualidad para titular y adicionales</CustomLi>
                <CustomLi image={Listed}>Préstamos desde $400 y hasta $40,000</CustomLi>
                <CustomLi image={Listed}>Realiza compras en miles de comercios nacionales e internacionales</CustomLi>
                <CustomLi image={Listed}>Disfruta de promociones exclusivas en diversos comercios</CustomLi>
                <CustomLi image={Listed}>Obtén el precio de contado al pagar en 30 días</CustomLi>
                <CustomLi image={Listed}>Retira efectivo en sucursales BanCoppel o en Cajeros Automáticos AFIRME y Scotiabank con comisión preferencial</CustomLi>
                <CustomLi image={Listed}>Difiere tus compras, retiros de efectivo o el saldo de tu tarjeta</CustomLi>
                <CustomLi image={Listed}>Tu Tarjeta te protege en todo momento con Seguros VISA</CustomLi>
              </UlCoppel>
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Title2
                sizeD="30px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="3%"
                className="roboto"
                fSizeMd="30px"
                sizem="25px"
                top="0px"
                mleftm="0px"
                widthM="100%"
                aling="center"
              >
                ¿Cómo lo solicito?
              </Title2>
          </div>
          <Contenedor>
            <Columna>
              <img src={Coppel} alt="LogoCoppel" style={{ width: '52.63px', height: '40px', marginBottom: '10px' }} />
              <span style={{ fontWeight: 400, fontSize: "20px", color: 'black' }} className="roboto">Verifica tus datos</span>
              <span className="roboto" style={{ fontSize: "16px", fontWeight: 300, color: 'black', textAlign: 'center' }}>Ingresa y revisa tu información para validar tus datos </span>
            </Columna>
            <Columna>
              <img src={Form} alt="LogoCoppel" style={{ marginBottom: '10px' }} />
              <span style={{ fontWeight: 400, fontSize: "20px", color: 'black' }} className="roboto">Haz tu solicitud</span>
              <span className="roboto" style={{ fontSize: "16px", fontWeight: 300, color: 'black', textAlign: 'center' }}>Te ayudaremos haciendo una consulta de tu historial crediticio para determinar tu línea de crédito</span>
            </Columna>
            <Columna>
              <img src={Key} alt="LogoCoppel" style={{ marginBottom: '10px' }} />
              <span style={{ fontWeight: 400, fontSize: "20px", color: 'black' }} className="roboto">Obtén tu crédito</span>
              <span className="roboto" style={{ fontSize: "16px", fontWeight: 300, color: 'black', textAlign: 'center' }}>En caso de ser aprobado, recibe tu crédito y disfruta de todos sus beneficios</span>
            </Columna>
          </Contenedor>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              className="roboto"
              active
              maxwidth={widthBrowser > 768 ? "400px" : "100%"}
              onClick={() => window.location= "/lp/coppelAdress"}
            >
              Solicitar
            </Button>
          </div>
          <div style={{ width: '100%', height: '150px' }}></div>
        </Container>
      </Container1>
    </WOlayout>
  )
};

const Contenedor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Columna = styled.div`
  display: flex;
  flex-direction: column;
  height: 206px;
  width: 322px;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  background: ${(props) => props.active ? '#FFDD2F' : "#C4C4C4"};
  color: black;
  width: 100%;
  height: 50px;
  max-width: ${(props) => props. maxwidth || '400px'};
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  @media (max-width: 480px) {
    max-width: 100%;
  }
  &: hover {
    background: ${(props) => props.active ? '#FFDD2F' : "#C4C4C4"};
    color: black;
    border: none;
  }
`;

export default LpCoppel;