/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import TagManager from "react-gtm-module";
import { useLazyQuery } from "@apollo/client";
import editIcon from "../../assets/img/anacoach/editar1.png";
import InputsOrder from "../../components/common/InputsOrder";
import {
  TrainingText,
  TrainingQuestion,
  QuestionsDivs,
  CardsBtn,
  ErrorDiv,
  TrainingDivSection,
  DivRespDP,
} from "../../assets/styles/Training.styled";
import CardOne from "../../components/Home/Card1Animate";
import CardTwo from "../../components/Home/Card2Animate";
import { RECOVERY_ROUTINE_BY_ORDER, GET_TRAINING_COLOR } from "../../queries";
import {
  arraySessionsRutines,
  getQueryVariableHashtagDeeplink,
} from "../../Helpers";
import ImageCoachInicio from "../../components/Training/ImageCoachInicio";
import LoadingWo from "../../components/Training/loadingWo";
import { Container, Col7 } from "../../components/common/common.styled";
import Aceptterms from "../../components/common/Acceptterms";
import ClickButton from "../../Helpers/HookHelpers";
import SendNotificationFTAndCC from "../../components/common/SendNotificationFTAndCC";

const DatosPrincipales = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  mainDataRoutine = 0,
  trainingColor,
  curp,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [colorTraining, setColorTraining] = useState("");
  const anchop = window.screen.width;
  const { sendMessage } = SendNotificationFTAndCC();
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const dataPersonInit = sessionStorage.getItem("DataPerson") || "";
  const [dataPerson, setDatosPersonales] = useState(
    sessionStorage.getItem("dataPerson") || ""
  );
  const [seccionNombre, setSeccionNombre] = useState(false);
  const [seccionFechaNacimiento, setSeccionFechaNacimiento] = useState(false);
  const [alertaDatosP, setAlertaDP] = useState(false);
  const [alertaFecha, setAlertaFecha] = useState(false);
  const [ingreso, setIngreso] = useState(
    sessionStorage.getItem("ingresoMostrar") || ""
  );
  const emailTmp = sessionStorage.getItem("Email1") || "";
  const [form, setForm] = useState({
    FirstName: sessionStorage.getItem("FirstName") || "",
    FathersLastName: sessionStorage.getItem("FathersLastName") || "",
    MiddleName: sessionStorage.getItem("MiddleName") || "",
    Email1: emailTmp.indexOf("user_tem_") !== -1 ? "" : emailTmp,
    MothersLastName: sessionStorage.getItem("MothersLastName") || "",
    day: sessionStorage.getItem("day") || Date.day,
    month: sessionStorage.getItem("month") || Date.month,
    year: sessionStorage.getItem("year") || Date.year,
    cel:
      emailTmp.indexOf("user_tem_") !== -1
        ? ""
        : sessionStorage.getItem("CellPhone") || "",
  });
  const [lapiz, setLapiz] = useState([{ routine: "", active: "" }]);
  const [editDP, setEditDP] = useState("");
  const [editIM, setEditIM] = useState("");

  //curp ----------
  const [curpDisplay, setCurpDisplay] = useState(false);
  const [correctCurp, setCorrectCurp] = useState("ejemploCurp");
  const [collapse, setCollapse] = useState(false);

  const keyUp = () => {
    let curp = new String(document.getElementById("curp").value);
    let upperCurp = curp.toUpperCase().replace(/[^a-z0-9]/gi, "");
    document.getElementById("curp").value = upperCurp;
    setCorrectCurp(upperCurp);
  };

  const curpValidation = () => {
    let name = document.getElementById("curp").value;

    if (name.length > 18) {
      console.log("debería de limitar");
      name = name.slice(0, 18);
      document.getElementById("curp").value = name;
    }
  };

  const editCurp = () => {
    document.getElementById("curp").disabled = false;
    document.getElementById("curp").focus();
  };

  const updateCurp = (e) => {
    if (document.getElementById("curp").value.length == 18) {
      document.getElementById("curpIncorrecto").innerText = "";
      setCorrectCurp(document.getElementById("curp").value);
      console.log(correctCurp);
      handleChangeDatosPersonales(e);
      setCollapse(true);
    } else {
      document.getElementById("curpIncorrecto").innerText = "CURP incorrecto.";
    }
  };

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem("idTraining");
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);
  const [recoveryDatosPrincipales, setRecoveryDatosPrincipales] = useState("");
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: "cache-and-network",
    onCompleted({ recoveryRoutineByOrder }) {
      let stopRecovery = false;
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryDatosPrincipales("inactiva");
        } else {
          const routines = json; // Object.values(json);
          console.log(`recovery routine, ${orderRutine}`);
          console.log(routines);
          const contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== "" &&
                responseUser !== "null" &&
                responseUser != null
              ) {
                if (questionType === "Cat") {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      if (idTypeQuestion === 20) {
                        // debugger;
                        sessionStorage.setItem(
                          "ingresoMostrar",
                          detailQuestion[x].QuestionOption
                        );
                      }

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser
                      );
                    }
                  }
                } else {
                  if (parseInt(idTypeQuestion) === 78) {
                    if (responseUser !== null && responseUser !== "null") {
                      let date = responseUser.substr(0, 10);

                      date += "".split("-")[0];
                      sessionStorage.setItem("year", date.split("-")[0]);
                      sessionStorage.setItem("month", date.split("-")[1]);
                      sessionStorage.setItem("day", date.split("-")[2]);
                      form.day = date.split("-")[2];
                      form.month = date.split("-")[1];
                      form.year = date.split("-")[0];

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        date
                      );
                    } else {
                      stopRoutine = true;
                      // break;
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser
                    );
                  }
                }
              } else if (parseInt(idTypeQuestion) === 78) {
                stopRoutine = true;
                // break;
              }
              if (mainDataRoutine === 1 && i === 6) {
                stopRecovery = true;
                setRecoveryDatosPrincipales("activa");
                setQuestions([]);
                break;
              }
            }
            if (stopRecovery) {
              break;
            }

            if (
              arrayResponseRutine.length <
                routines[idRoutine].questions.length &&
              !stopRoutine
            ) {
              console.log("rutina actual: ", orderRutine);
              console.log(
                "questions: ",
                routines[idRoutine].questions.length,
                "responses: ",
                arrayResponseRutine.length
              );
              setRecoveryDatosPrincipales("activa");
              setQuestions([]);
              break;
            }

            if (
              arrayResponseRutine.length === 7 ||
              arrayResponseRutine.length === 8
            ) {
              console.log("stopRoutine", stopRoutine);
              if (!stopRoutine) {
                console.log("stop");
                sessionStorage.setItem("dataPerson", "Envio Datos");
                setDatosPersonales("Envio Datos");
              }
              setRecoveryDatosPrincipales("activa");
              setQuestions([]);
            } else {
              setRecoveryDatosPrincipales("activa");
              setQuestions([]);
            }
            setRecoveryDatosPrincipales("activa");
            setQuestions([]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    // const { idUser, idCont } = getQueryVariableHashtagDeeplink('');
    if (
      sessionStorage.getItem("idUser") !== "" &&
      sessionStorage.getItem("idCont") !== "" &&
      sessionStorage.getItem("auxTempValidation") !== "true"
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem("idUser")),
          idCont: String(sessionStorage.getItem("idCont")),
        },
      });
    } else {
      sessionStorage.setItem("auxTempValidation", "false");
      setRecoveryDatosPrincipales("inactiva");
    }

    const { idUser, idCont } = getQueryVariableHashtagDeeplink("profile");
    console.log("INFOS", idUser, idCont);
    if (idUser && idCont) {
      sessionStorage.setItem("idUser", idUser);
      sessionStorage.setItem("idCont", idCont);
      console.log("Ejecutando recovery", {
        idTraining: String(currentTraining),
        orderRoutine: String(currentRoutine),
        idUser: String(idUser),
        idCont: String(idCont),
      });
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(idUser),
          idCont: String(idCont),
        },
      });
    }
  }, []);

  const [error] = useState({
    Email1: false,
    FirstName: false,
    MiddleName: false,
    FathersLastName: false,
    MothersLastName: false,
    day: false,
    month: false,
    year: false,
    cel: false,
    landline: false,
    Email1valid: form.Email1.length !== 0 ? 0 : -1,
    FirstNamevalid: form.FirstName.length !== 0 ? 0 : -1,
    MiddleNamevalid: form.MiddleName.length !== 0 ? 0 : -1,
    FathersLastNamevalid: form.FathersLastName.length !== 0 ? 0 : -1,
    MothersLastNamevalid: form.MothersLastName.length !== 0 ? 0 : -1,
    phonevalid: form.cel.length !== 0 ? 0 : -1,
  });
  const [texterror] = useState({
    Email1: "",
    FirstName: "",
    MiddleName: "",
    FathersLastName: "",
    MothersLastName: "",
    day: "",
    month: "",
    year: "",
  });
  const ValidEmail = (id, valueEmail, nombre) => {
    console.log(nombre);
    let domain = valueEmail.split("@");
    const anexo = domain[0];
    domain = domain[1];
    console.log(domain);
    const idmail = document.getElementsByName(nombre)[0];
    if (idmail === null || idmail === undefined) {
      console.log("null");
    } else if (
      valueEmail &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      idmail.className = "textInput inputs-bottom invalidInput";
      texterror.Email1 = "Correo electrónico incorrecto";
      error.Email1 = true;
      error.Email1valid = 1;
    } else if (
      domain !== "" &&
      (domain === "gmail.co" || domain === "hotmail.co")
    ) {
      idmail.className = "textInput inputs-bottom invalidInput";
      texterror.Email1 = "Correo electrónico incorrecto";
      error.Email1 = true;
      error.Email1valid = 1;
    } else {
      idmail.className = "textInput inputs-bottom validInput";
      texterror.Email1 = "";
      error.Email1 = false;
      error.Email1valid = 0;
    }
  };
  const handleChangeAge = (name, value) => {
    let nacimiento = `${form.year}-${form.month}-${form.day}`;
    const path = window.location.pathname;
    let elem = document.getElementById(name);
    if (path.includes("funnel-prestamos")) {
      elem = document.getElementById(name);
    } else if (path.includes("obtener-primer-tarjeta")) {
      elem = document.querySelectorAll(`#${name}`)[1];
    } else if (path.includes("comparar-tarjeta")) {
      elem = document.querySelectorAll(`#${name}`)[0];
    } else {
      elem = document.getElementById(name);
    }
    switch (name) {
      case "year":
        elem.className = "textInput inputs-bottom validSelect";
        nacimiento = `${value}-${form.month}-${form.day}`;
        break;
      case "month":
        elem.className = "textInput inputs-bottom validSelect";
        nacimiento = `${form.year}-${value}-${form.day}`;
        break;
      case "day":
        elem.className = "textInput inputs-bottom validSelect";
        nacimiento = `${form.year}-${form.month}-${value}`;
        break;
      default:
        nacimiento = `${form.year}-${form.month}-${form.day}`;
        break;
    }
  };
  const ValidPhone = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ""),
    });
    const tel = document.getElementsByName(name)[0];
    console.log(id);
    if (tel === null || tel === undefined) {
      console.log("null");
    } else if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = "textInput inputs-bottom invalidInput";
      texterror[name] = "Por favor verifica tu número telefónico";
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = "textInput inputs-bottom validInput";
      texterror[name] = "";
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ""),
    });
    const element = document.getElementsByName(nombre)[0];
    console.log(element);
    if (element === null || element === undefined) {
      console.log("null");
    } else if (valor.length > 2 && valor !== "name temporal") {
      element.className = "textInput inputs-bottom validInput";
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = "";
    } else if (nombre === "MiddleName") {
      error[nombre] = false;
      texterror[nombre] = "";
      error[`${nombre}valid`] = -1;
    } else {
      element.className = "textInput inputs-bottom invalidInput";
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = "Debe ingresar más de 3 caracteres";
    }
    // eBtn();
  };

  useEffect(() => {
    if (recoveryDatosPrincipales === "activa") {
      setForm(
        Object.assign(form, {
          FirstName:
            emailTmp.indexOf("user_tem_") !== -1
              ? ""
              : sessionStorage.getItem("FirstName") || "",
          FathersLastName: sessionStorage.getItem("FathersLastName") || "",
          MiddleName: sessionStorage.getItem("MiddleName") || "",
          Email1: emailTmp.indexOf("user_tem_") !== -1 ? "" : emailTmp,
          MothersLastName: sessionStorage.getItem("MothersLastName") || "",
          day: sessionStorage.getItem("day") || Date.day,
          month: sessionStorage.getItem("month") || Date.month,
          year: sessionStorage.getItem("year") || Date.year,
          cel:
            emailTmp.indexOf("user_tem_") !== -1
              ? ""
              : sessionStorage.getItem("CellPhone") || "",
        })
      );
      const ingresoMostrar = sessionStorage.getItem("ingresoMostrar") || "";
      setIngreso(ingresoMostrar);
      if (ingresoMostrar !== "") {
        setOrderRutine(nextOrderRoutine);
      }

      console.log("Form ====>>>", form);
    }
  }, [recoveryDatosPrincipales]);
  // funcionalidad
  useEffect(() => {
    if (orderRutine === currentRoutine) {
      if (form.year !== "" && form.month !== "" && form.day !== "-") {
        handleChangeQuestionsSend(
          5,
          "dateBurn",
          `${form.year}-${form.month}-${form.day}`
        );
        sessionStorage.setItem("year", form.year);
        sessionStorage.setItem("month", form.month);
        sessionStorage.setItem("day", form.day);
      }
    }
  }, [form, dataPerson]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const path = window.location.pathname;
      if (path.includes("funnel-prestamos")) {
        if (
          document.getElementById("year") !== null &&
          document.getElementById("year") !== undefined
        ) {
          document.getElementById("year").className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.getElementById("day") !== null &&
          document.getElementById("day") !== undefined &&
          form.day !== ""
        ) {
          document.getElementById("day").className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.getElementById("month") !== null &&
          document.getElementById("month") !== undefined &&
          form.month !== ""
        ) {
          document.getElementById("month").className =
            "textInput inputs-bottom validSelect";
        }
      } else if (path.includes("obtener-primer-tarjeta")) {
        if (
          document.querySelectorAll("#year")[1] !== null &&
          document.querySelectorAll("#year")[1] !== undefined
        ) {
          document.querySelectorAll("#year")[1].className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.querySelectorAll("#day")[1] !== null &&
          document.querySelectorAll("#day")[1] !== undefined &&
          form.day !== ""
        ) {
          document.querySelectorAll("#day")[1].className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.querySelectorAll("#month")[1] !== null &&
          document.querySelectorAll("#month")[1] !== undefined &&
          form.month !== ""
        ) {
          document.querySelectorAll("#month")[1].className =
            "textInput inputs-bottom validSelect";
        }
      } else if (path.includes("comparar-tarjeta")) {
        if (
          document.querySelectorAll("#year")[1] !== null &&
          document.querySelectorAll("#year")[1] !== undefined
        ) {
          document.querySelectorAll("#year")[1].className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.querySelectorAll("#day")[1] !== null &&
          document.querySelectorAll("#day")[1] !== undefined &&
          form.day !== ""
        ) {
          document.querySelectorAll("#day")[1].className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.querySelectorAll("#month")[1] !== null &&
          document.querySelectorAll("#month")[1] !== undefined &&
          form.month !== ""
        ) {
          document.querySelectorAll("#month")[1].className =
            "textInput inputs-bottom validSelect";
        }
      } else {
        if (
          document.getElementById("year") !== null &&
          document.getElementById("year") !== undefined
        ) {
          document.getElementById("year").className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.getElementById("day") !== null &&
          document.getElementById("day") !== undefined &&
          form.day !== ""
        ) {
          document.getElementById("day").className =
            "textInput inputs-bottom validSelect";
        }
        if (
          document.getElementById("month") !== null &&
          document.getElementById("month") !== undefined &&
          form.month !== ""
        ) {
          document.getElementById("month").className =
            "textInput inputs-bottom validSelect";
        }
      }

      if (
        sessionStorage.getItem("FirstName") !== null &&
        sessionStorage.getItem("FirstName") !== ""
      ) {
        ValidLetras(
          "FirstName",
          sessionStorage.getItem("FirstName"),
          "Primer Nombre"
        );
      }
      if (
        sessionStorage.getItem("FathersLastName") !== null &&
        sessionStorage.getItem("FathersLastName") !== ""
      ) {
        ValidLetras(
          "FathersLastName",
          sessionStorage.getItem("FathersLastName"),
          "Apellido Paterno"
        );
      }
      if (
        sessionStorage.getItem("middleName") !== null &&
        sessionStorage.getItem("middleName") !== ""
      ) {
        ValidLetras(
          "MiddleName",
          sessionStorage.getItem("middleName"),
          "Segundo Nombre"
        );
      }
      if (
        sessionStorage.getItem("MothersLastName") !== null &&
        sessionStorage.getItem("MothersLastName") !== ""
      ) {
        ValidLetras(
          "MothersLastName",
          sessionStorage.getItem("MothersLastName"),
          "Apellido Materno"
        );
      }
      if (
        sessionStorage.getItem("Email1") !== null &&
        sessionStorage.getItem("Email1") !== ""
      ) {
        ValidEmail("Email1", sessionStorage.getItem("Email1"), "Email1");
        const campo = document.getElementsByName("Email1");
        campo.readOnly = true;
        campo.disabled = true;
      }
      if (
        sessionStorage.getItem("CellPhone") !== null &&
        sessionStorage.getItem("CellPhone") !== ""
      ) {
        ValidPhone(
          "cel",
          sessionStorage.getItem("CellPhone"),
          "Teléfono Celular"
        );
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = "") => {
    console.log("handleChangeQuestionsSend", numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === "Cat") {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleRoutineOrder = (step, prod = "", cantidad = "", tel = "") => {
    console.log("handleRoutineOrder", step, prod, cantidad, tel);
  };
  const handleChangeCatOption = (val) => {
    console.log("handleChangeCatOption", val);
    setIngreso(val);
    setEditIM(true);
    handleRoutineOrder(currentRoutine);
    setOrderRutine(nextOrderRoutine);
  };
  const handleChangeNombre = (e) => {
    e.preventDefault();
    if (
      form.FirstName.length >= 3 &&
      form.FathersLastName.length >= 3 &&
      form.MothersLastName.length >= 3
    ) {
      gsap.to(window, { duration: 1, scrollTo: "#activeFechaNac" });
      setSeccionNombre(true);
      setAlertaDP(false);
      sessionStorage.setItem("FirstName", form.FirstName);
      if (sessionStorage.getItem("idTraining") === "35") {
        const clickEventCC = `Nombre_${orderRutine}.${sessionStorage.getItem(
          "idTraining"
        )}`;
        actions({ action: "ClickButtonCreditCards", variables: clickEventCC });
      }
      const click = `ContinuarNombre_${sessionStorage.getItem("idTraining")}`;
      actions({ action: "ClickButtonCollection", variables: click });
    } else {
      setSeccionNombre(false);
      setAlertaDP(true);
    }
  };

  const handleChangeFechaNacimiento = (e) => {
    e.preventDefault();
    if (form.day !== "" && form.month !== "" && form.year !== "") {
      setAlertaFecha(false);
      setSeccionFechaNacimiento(true);
      setTimeout(() => {
        if (
          sessionStorage.getItem("Email1") !== null &&
          sessionStorage.getItem("Email1") !== ""
        ) {
          const campo = document.getElementById("Email");
          const email = sessionStorage.getItem("Email1") || "";
          if (email.indexOf("user_tem_") === -1) {
            campo.readOnly = true;
            campo.disabled = true;
          }
        }
      }, 1000);
      const click = `ContinuarFechaNacimiento_${sessionStorage.getItem(
        "idTraining"
      )}`;
      actions({ action: "ClickButtonCollection", variables: click });
      gsap.to(window, { duration: 1, scrollTo: "#activeDatosContacto" });
    } else if (form.day === "" || form.month === "" || form.year === "") {
      setAlertaFecha(true);
      setSeccionFechaNacimiento(false);
    }
  };
  const handleChangeDatosPersonales = (e) => {
    e.preventDefault();
    if (
      texterror.FirstName === "" &&
      texterror.FathersLastName === "" &&
      texterror.MothersLastName === "" &&
      form.day !== "" &&
      form.month !== "" &&
      form.year !== "" &&
      form.Email1 !== "" &&
      error.Email1 === false &&
      form.cel.length === 10
    ) {
      setEditDP(true);
      actions({ action: "validAffiliate", variables: form.email });
      sendMessage({
        cellPhone: sessionStorage.getItem("CellPhone"),
        trigger: "wa_cc_2",
      });
      sendMessage({
        cellPhone: sessionStorage.getItem("CellPhone") || "",
        trigger: "Notificación_FT_3",
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setDatosPersonales("Envio Datos");
      const tagManagerArgs = {
        dataLayer: {
          event: "PersonalInfo",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      sessionStorage.setItem("dataPerson", "Envio Datos");
      const click = `ComenzarEntrenamiento_${sessionStorage.getItem(
        "idTraining"
      )}`;
      gsap.to(window, { duration: 1, scrollTo: "#activeIngresoMensual" });
      actions({ action: "ClickButtonCollection", variables: click });
    } else if (
      form.FirstName !== "" &&
      form.FathersLastName !== "" &&
      form.MothersLastName !== "" &&
      form.Email1 !== "" &&
      form.cel !== "" &&
      (form.day === "" || form.month === "" || form.year === "")
    ) {
      setAlertaFecha(true);
      setAlertaDP(false);
    } else {
      setAlertaFecha(false);
      setAlertaDP(true);
    }
  };
  const handleChangeIcon = (json) => {
    setEditDP(false);
    setEditIM(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== "") {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [lapizIngreso, setLapizIngreso] = useState(
    sessionStorage.getItem("Motivo del Crédito") || ""
  );
  useEffect(() => {
    if (lapizIngreso !== "") {
      setLapizIngreso("Quitar");
    }
  }, [lapizIngreso]);

  if (
    recoveryDatosPrincipales === "" &&
    sessionStorage.getItem("idCont") !== "" &&
    sessionStorage.getItem("idCont") !== null &&
    sessionStorage.getItem("idUser") !== "" &&
    sessionStorage.getItem("idUser") != null
  ) {
    return (
      <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }

  const handleChange = (e) => {
    console.log("handleChange", e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.name);
    switch (e.target.name) {
      case "email":
        ValidEmail(e.target.id, e.target.value, e.target.name);
        break;
      case "Email1":
        ValidEmail(e.target.id, e.target.value, e.target.name);
        break;
      case "day":
        handleChangeAge(e.target.name, e.target.value);
        break;
      case "month":
        handleChangeAge(e.target.name, e.target.value);
        break;
      case "year":
        handleChangeAge(e.target.name, e.target.value);
        break;
      case "cel":
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };

  return (
    <div>
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          orderRutine === currentRoutine &&
          (dataPerson === "" ||
            (lapiz[0].routine === 1 && lapiz[0].active === 1)) &&
          (editDP === false || editDP === "")
            ? ""
            : "hidden"
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width={anchop <= 768 ? "0 0 96%" : "0 0 90%"}
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <form className="">
            {orderRutine === currentRoutine ? (
              <Fragment>
                <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
                  <ImageCoachInicio
                    classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                    topMobile="35px"
                  />
                  <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
                    <TrainingDivSection
                      hew="auto"
                      paddw="26px"
                      mlw="0%"
                      mtw="8%"
                      wiw="100%"
                      back="#F3F3F3"
                      backw="#F3F3F3"
                      bshaw="none"
                      bsha=""
                      br="4px"
                      padding="20px"
                      wi="100%"
                      he="auto"
                      className="topOne1 topOne1Init"
                    >
                      <TrainingText
                        bot="0px"
                        className="cabin"
                        align="left"
                        weight="600"
                        size="20px"
                        sizew="20px"
                      >
                        ¿Cuál es tu nombre?
                      </TrainingText>
                      {!seccionNombre ? (
                        <Fragment>
                          <InputsOrder
                            handleChangeQuestionsSend={
                              handleChangeQuestionsSend
                            }
                            handleChangeRutine={handleChangeRutine}
                            orderRutine={orderRutine}
                            currentTraining={currentTraining}
                            step="0-3"
                            handleChangeCatOption={handleChangeCatOption}
                            form={form}
                            handleChange={handleChange}
                            endQuestion={7}
                            activeValidateRutine={true}
                            inputError={error}
                            texterror={texterror}
                          />
                          <ErrorDiv
                            className={alertaFecha === true ? "" : "hidden"}
                          >
                            Parece que no has llenado tu fecha de nacimiento
                          </ErrorDiv>
                          <CardsBtn
                            id="SeccionNombre"
                            type="button"
                            min_w="100%"
                            min_web="100%"
                            mtopw="10px"
                            onClick={(e) => handleChangeNombre(e)}
                          >
                            Continuar
                          </CardsBtn>
                          <Aceptterms />
                        </Fragment>
                      ) : null}
                    </TrainingDivSection>
                  </Col7>
                </Container>
                <div id="activeFechaNac"></div>
                <DivRespDP>
                  <CardTwo
                    classCard={seccionNombre ? "cardTwoResp" : "hidden"}
                    classTop="topTwo1"
                    imgMan="hidden"
                    textT={""}
                    text2={`${form.FirstName} ${form.MiddleName} ${form.FathersLastName} ${form.MothersLastName}`}
                    icon={true}
                    colorTraining={colorTraining}
                  ></CardTwo>
                </DivRespDP>
                {seccionNombre ? (
                  <Fragment>
                    <Container
                      width="80%"
                      mt="0"
                      widthMobile="90%"
                      mtMobile="0"
                    >
                      <ImageCoachInicio
                        classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                        topMobile="35px"
                      />
                      <Col7
                        maxwidth="100%"
                        padding=" "
                        className="cardPrincipal1"
                      >
                        <TrainingDivSection
                          hew="auto"
                          className="topOne1"
                          paddw="26px"
                          mlw="0%"
                          mtw="8%"
                          wiw="100%"
                          back="#F3F3F3"
                          backw="#F3F3F3"
                          bshaw="none"
                          bsha=""
                          br="4px"
                          padding="20px"
                          wi="100%"
                          he="auto"
                        >
                          <TrainingText
                            bot="0px"
                            className="cabin"
                            align="left"
                            weight="600"
                            size="20px"
                            sizew="20px"
                          >
                            ¿Cuál es tu fecha de nacimiento?
                          </TrainingText>
                          {!seccionFechaNacimiento ? (
                            <Fragment>
                              <InputsOrder
                                handleChangeQuestionsSend={
                                  handleChangeQuestionsSend
                                }
                                handleChangeRutine={handleChangeRutine}
                                orderRutine={orderRutine}
                                currentTraining={currentTraining}
                                step="1"
                                handleChangeCatOption={handleChangeCatOption}
                                form={form}
                                handleChange={handleChange}
                                endQuestion={7}
                                activeValidateRutine={true}
                                inputError={error}
                                texterror={texterror}
                                date={true}
                              />
                              <ErrorDiv
                                className={
                                  alertaDatosP === true ? "" : "hidden"
                                }
                              >
                                Parece que te falto llenar algún campo
                              </ErrorDiv>
                              <CardsBtn
                                type="button"
                                min_w="100%"
                                min_web="100%"
                                mtopw="10px"
                                onClick={(e) => handleChangeFechaNacimiento(e)}
                              >
                                Continuar
                              </CardsBtn>
                            </Fragment>
                          ) : null}
                        </TrainingDivSection>
                      </Col7>
                    </Container>
                    <div id="activeDatosContacto"></div>
                    <DivRespDP>
                      <CardTwo
                        classCard={
                          seccionFechaNacimiento ? "cardTwoResp" : "hidden"
                        }
                        classTop="topTwo1"
                        imgMan="hidden"
                        textT={""}
                        text2={`${form.day}/${form.month}/${form.year}`}
                        icon={true}
                        colorTraining={colorTraining}
                      ></CardTwo>
                    </DivRespDP>
                  </Fragment>
                ) : null}
                {seccionFechaNacimiento ? (
                  <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
                    <ImageCoachInicio
                      classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                      topMobile="35px"
                    />
                    <Col7
                      maxwidth="100%"
                      padding=" "
                      className="cardPrincipal1"
                    >
                      <TrainingDivSection
                        hew="auto"
                        className="topOne1"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                      >
                        <TrainingText
                          bot="0px"
                          className="cabin"
                          align="left"
                          weight="600"
                          size="20px"
                          sizew="20px"
                        >
                          Datos de contacto
                        </TrainingText>
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          currentTraining={currentTraining}
                          step="5-6"
                          handleChangeCatOption={handleChangeCatOption}
                          form={form}
                          handleChange={handleChange}
                          endQuestion={7}
                          activeValidateRutine={true}
                          inputError={error}
                          texterror={texterror}
                        />
                        <ErrorDiv
                          className={alertaFecha === true ? "" : "hidden"}
                        >
                          Parece que no has llenado tu fecha de nacimiento
                        </ErrorDiv>
                        <ErrorDiv
                          className={alertaDatosP === true ? "" : "hidden"}
                        >
                          Parece que te falto llenar algún campo
                        </ErrorDiv>
                        <CardsBtn
                          min_w="100%"
                          min_web="100%"
                          mtopw="10px"
                          onClick={(e) => handleChangeDatosPersonales(e)}
                        >
                          Empezar solicitud
                        </CardsBtn>
                        {/* {curp ? (
                          <>
                            <CardsBtn
                              min_w="100%"
                              min_web="100%"
                              mtopw="10px"
                              onClick={(e) => {
                                e.preventDefault();
                                setCurpDisplay(true);
                              }}
                            >
                              Empezar solicitud
                            </CardsBtn>
                          </>
                        ) : (
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            mtopw="10px"
                            onClick={(e) => handleChangeDatosPersonales(e)}
                          >
                            Empezar solicitud
                          </CardsBtn>
                        )} */}
                      </TrainingDivSection>
                    </Col7>
                  </Container>
                ) : null}
              </Fragment>
            ) : null}

            <br />
          </form>
        </QuestionsDivs>
      </TrainingQuestion>
      {curpDisplay ? (
        <>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                dataPerson !== "" && ingreso === "" ? "iconEdit1" : "hidden"
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 1, active: 1 })}
            />
            <div id="activeIngresoMensual"></div>
            <CardTwo
              classAna={anchop <= 768 ? "falseAna" : "trueAna"}
              classCard={
                (dataPersonInit !== "true" &&
                  dataPerson !== "" &&
                  !(lapiz[0].routine === 1 && lapiz[0].active === 1)) ||
                editDP === true
                  ? "cardTwoResp"
                  : "hidden"
              }
              classTop="topTwo1"
              imgMan="hidden"
              textT={"Tus datos registrados:"}
              text2={`${form.FirstName} ${form.MiddleName} ${form.FathersLastName} ${form.MothersLastName}`}
              text3={` Email: ${form.Email1}`}
              text4={`Teléfono celular: ${form.cel}`}
              text5={` Fecha de nacimiento: ${form.day}/${form.month}/${form.year}`}
              colorTraining={colorTraining}
            ></CardTwo>
          </DivRespDP>
        </>
      ) : (
        <>
          <DivRespDP>
            <img
              alt="editIcon"
              className={
                dataPerson !== "" && ingreso === "" ? "iconEdit1" : "hidden"
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 1, active: 1 })}
            />
            <div id="activeIngresoMensual"></div>
            <CardTwo
              classAna={anchop <= 768 ? "falseAna" : "trueAna"}
              classCard={
                (dataPersonInit !== "true" &&
                  dataPerson !== "" &&
                  !(lapiz[0].routine === 1 && lapiz[0].active === 1)) ||
                editDP === true
                  ? "cardTwoResp"
                  : "hidden"
              }
              classTop="topTwo1"
              imgMan="hidden"
              textT={"Tus datos registrados:"}
              text2={`${form.FirstName} ${form.MiddleName} ${form.FathersLastName} ${form.MothersLastName}`}
              text3={` Email: ${form.Email1}`}
              text4={`Teléfono celular: ${form.cel}`}
              text5={` Fecha de nacimiento: ${form.day}/${form.month}/${form.year}`}
              colorTraining={colorTraining}
            ></CardTwo>
          </DivRespDP>
        </>
      )}
      <CardOne
        classAna={anchop <= 768 ? "falseAna" : "trueAna"}
        classCard={
          dataPerson !== "" &&
          (!(lapiz[0].routine === 1 && lapiz[0].active === 1) ||
            editDP === true)
            ? "cardOne"
            : "hidden"
        }
        classTop="topOne1"
        text="¿Cuál es tu ingreso mensual?"
        hora={hora}
      />
      <div id="irIngreso"></div>
      <TrainingQuestion
        className={
          ((dataPerson !== "" && ingreso === "") ||
            (lapiz[0].routine === 1 && lapiz[0].active === 2)) &&
          (!(lapiz[0].routine === 1 && lapiz[0].active === 1) ||
            editDP === true) &&
          !editIM
            ? ""
            : "hidden"
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="7-7"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
          />
        ) : null}
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            ingreso !== "" && lapizIngreso === "" ? "iconEdit1" : "hidden"
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 2 })}
        />
        <CardTwo
          classCard={
            ingreso !== "" &&
            (!(lapiz[0].routine === 1 && lapiz[0].active === 2) || editIM)
              ? "cardTwoResp"
              : "hidden"
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={ingreso}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default DatosPrincipales;
