import React, { useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
// import WOlayout from '../components/layouts/WOlayout';
import "../assets/styles/kanda/KandaDesktop.css";
import { Helmet } from "react-helmet";
import Porcentaje from "../assets/gifs/porcentaje.gif";
import PorcentajeMex from "../assets/gifs/porcentajemex.gif";
// import TribuCoru from "../assets/img/TribuCoru.svg";
import Function1 from "../assets/img/function1.svg";
import Function2 from "../assets/img/function2.svg";
import Function3 from "../assets/img/function3.svg";
import Function4 from "../assets/img/function4.svg";
import AppStore from "../assets/img/AppStore.svg";
import GooglePlay from "../assets/img/GooglePlay.svg";
import Usuarios from "../assets/img/Usuarios.svg";
import Empresas from "../assets/img/Empresas.svg";
import LeftGray from "../assets/img/leftGray.svg";
import LeftPink from "../assets/img/leftPink.svg";
import RightGray from "../assets/img/rightGray.svg";
import RightPink from "../assets/img/rightPink.svg";
import InversionistasD from "../assets/img/equipoCoru.jpg";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/anacoach/CoruLogoB.png";
import ColaboradoresI from "../assets/img/Coru_colaboradores.gif";
// import { imageComponent } from "../components/common/common";
import { CardsBtn } from "../assets/styles/Training.styled";
// import Colaboradores from "../components/rediseno_about_us/Colaboradores";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";

const Container1 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    360deg,
    rgba(239, 209, 247, 0.32) 0.52%,
    rgba(209, 209, 247, 0.32) 39.06%,
    rgba(255, 255, 255, 0.32) 100%
  );
  min-height: 100vh;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const TitleBlue = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  color: #393a80;
  margin: ${(props) => props.marginD || ""};
  @media (min-width: 770px) {
    text-align: left;
    font-size: ${(props) => props.fontSD || "52px"};
  }
`;

const TitleWhite = styled.div`
  font-weight: ${(props) => props.fontW || "700"};
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  color: #fafafa;
  margin: ${(props) => props.marginD || ""};
`;

const TextGray = styled.div`
  font-weight: ${(props) => props.fontW || "400"};
  font-size: ${(props) => props.fontS || "16px"};
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.3px;
  color: #595959;
  text-align: ${(props) => props.textA || ""};
  margin-top: ${(props) => props.marginT || ""};
  @media (min-width: 770px) {
    display: ${(props) => props.displayMQ || "block"};
    font-size: ${(props) => props.fontSD || "24px"};
    text-align: ${(props) => props.textAD || ""};
  }
`;

const TextPink = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #f64282;
`;

const ContainerArrows = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  height: 349px;
  background: rgba(57, 58, 128, 0.08);
  border-radius: 16px;
  @media (min-width: 770px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  gap: 24px;
  justify-content: ${(props) => props.justifyC || ""};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.marginT || ""};
  text-align: ${(props) => props.textA || ""};
`;

const ImageFunction = styled.img`
  min-width: 48px;
  height: 48px;
  width: 48px;
`;

const ImageDownload = styled.img`
  width: 40%;
  @media (min-width: 900px) {
    width: 140px;
  }
`;

// const ImageTribu = styled.img`
//   width: 100%;
//   height: auto;
//   left: 1px;
//   top: 0px;
//   background: #393a80;
//   mix-blend-mode: hard-light;
//   opacity: 0.6;
//   border-radius: 16px;
//   @media (min-width: 770px) {
//     display: none;
//   }
// `;

// const ImageTribuDesk = styled.img`
//   width: 100%;
//   height: auto;
//   background: #393a80;
//   mix-blend-mode: hard-light;
//   opacity: 0.6;
//   border-radius: 16px;
//   margin: 24px 0;
//   @media (max-width: 770px) {
//     display: none;
//   }
// `;

const ImageCoach = styled.img`
  width: 100%;
  max-height: 50%;

  @media (min-width: 770px) {
    max-height: 100%;
    max-width: 50%;
  }
`;

const ImageArrow = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 25%;
    margin-top: 15px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 75%;
  }
`;

const PercentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 770px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ContainerTextB = styled.div`
  @media (min-width: 770px) {
    background: rgba(181, 225, 246, 0.16);
    border-radius: 16px;
    padding: 24px;
    margin: 48px 0;
  }
`;
const ContainerFM = styled.div`
  display: none;
  @media (max-width: 770px) {
    display: block;
  }
`;
const ContainerFD = styled.div`
  display: none;
  @media (min-width: 770px) {
    display: flex;
    margin-top: 30px;
  }
`;

const ContainerTribu = styled.div`
  width: 100%;
  height: 500px;
  // background-image: url(/static/media/equipoCoru.7ddadc47.jpg);
  background-image: ${(props) => props.backgroundImage || ""};
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  margin: 24px 0;
  @media (max-width: 770px) {
    display: none;
  }
`;

const ContainerTribuMob = styled.div`
  width: 100%;
  height: 200px;
  // background-image: url(/static/media/equipoCoru.7ddadc47.jpg);
  background-image: ${(props) => props.backgroundImage || ""};
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  @media (min-width: 770px) {
    display: none;
  }
`;

const handleClickCoru = (name) => {
  switch (name) {
    case 'Ingresar':
      window.location.href = '/login';
      break;
    case 'CrearCuenta':
      window.location.href = '/user-validation/';
      break;
    default:
      break;
  }
};

const Header = () => (
  <div style={{ height: "85px", display: "flex" }}>
    <ContLogo>
      <Image
        cursor="pointer"
        src={ImageLogo}
        width="120px"
        mwidth="97.92px"
        heightMobile="20.8px"
        alt="logo-coru"
      />
    </ContLogo>
    <ContLogo2>
      <ContentGeneric width="40%" widthMd="10%">
        <KandaButton
          action={() => handleClickCoru('Ingresar')}
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
        >
          Ingresar
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="60%" widthMd="15%">
        <KandaButton
          action={() => handleClickCoru('CrearCuenta')}
          mr="96px"
          width="100%"
          typeBtn="active">
          Crear Cuenta
        </KandaButton>
      </ContentGeneric>
      {/* <div>
        <a
          className="textHeader roboto nav-link"
          style={{
            textAlign: "center",
            paddingRight: "2rem",
            cursor: "pointer",
            fontWeight: "bold",
            color: "#070B11",
          }}
        >
          Regístrate
        </a>
      </div>
      <div>
        <a
          className="textHeader roboto nav-link"
          style={{
            textAlign: "center",
            cursor: "pointer",
            color: "#070B11",
            paddingRight: "0",
          }}
        >
          Iniciar Sesión
        </a>
      </div> */}
    </ContLogo2>
  </div>
);

function AboutUs() {
  const [changePeople, setchangePeople] = useState(false);
  const handleLeft = () => {
    setchangePeople(false);
  };
  const handleRight = () => {
    setchangePeople(true);
  };

  const URLactual = window.location.hostname;
  let isProd = false;
  if (URLactual === 'coru.com') {
    isProd = true;
  } else {
    isProd = false;
  }

  return (
    <Container1>
      <Helmet>
        <title>{isProd ? 'Conoce sobre nosotros | CORU' : 'Conoce sobre nosotros'}</title>
      </Helmet>
      <Header />
      <div
        style={{
          maxWidth: "1056px",
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
        }}
      >
        <TitleBlue className="cabin" style={{ margin: "15px 0" }}>
          Somos el primer coach de finanzas personales en México
        </TitleBlue>
        <TextGray
          className="roboto"
          textA={"center"}
          textAD="left"
          style={{ margin: "15px 0" }}
        >
          Y queremos ayudarte a entender, manejar y conocer tu dinero y tus
          productos financieros.
        </TextGray>
        <ContainerArrows style={{ margin: "15px 0" }}>
          <ImageCoach src={!changePeople ? Usuarios : Empresas} />
          <div style={{}}>
            <TextGray className="roboto" textA="center">
              Actualmente, ayudamos a más de
            </TextGray>
            {!changePeople
              ? <><TextPink>2.5 millones</TextPink><TextGray className="roboto" textA="center">
                  de usuarios
                </TextGray></>
              : <><TextPink>250</TextPink><TextGray className="roboto" textA="center">
                  de empresas
                </TextGray></>
            }
            <ContainerFlex justifyC="center" marginT="24px">
              <ImageArrow
                src={!changePeople ? LeftGray : LeftPink}
                onClick={() => handleLeft()}
              />
              <ImageArrow
                src={changePeople ? RightGray : RightPink}
                onClick={() => handleRight()}
              />
            </ContainerFlex>
          </div>
        </ContainerArrows>
        <PercentContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <TitleBlue
              className="cabin"
              fontS="32px"
              style={{ margin: "15px 0" }}
            >
              ¿Por qué creamos Coru?
            </TitleBlue>
            <TextGray
              fontW="600"
              fontS="18px"
              className="roboto"
              textA="center"
              textAD="center"
              style={{ margin: "15px 0" }}
            >
              Descubrimos que el
            </TextGray>
            <img src={Porcentaje} style={{ maxWidth: "96px" }} />
            <TextGray
              fontW="700"
              fontS="24px"
              className="roboto"
              textA="center"
              textAD="center"
            >
              de la gente en México vive con estrés financiero.
            </TextGray>
            <TextGray className="roboto" textA="center" textAD="center" marginT="30px">
              Estamos comprometidos con liberarte del estrés financiero.
            </TextGray>
          </div>
          <img
            src={PorcentajeMex}
            style={{ width: "100%", maxWidth: "425px" }}
          />
        </PercentContainer>
        <ContainerTextB>
          <TextGray className="roboto" textAD="center">
            Nuestro objetivo es hacer las cosas más fáciles para ti, informarte y
            acompañarte en el proceso para que tus preocupaciones económicas en
            cada etapa de tu vida sean cada vez menos.
          </TextGray>
        </ContainerTextB>
        <TitleBlue className="cabin" fontS="32px" marginD="24px 0">
          ¿Quiénes somos?
        </TitleBlue>
        {/* <ImageTribu src={TribuCoru} /> */}
        {/* <ImageTribuDesk src={InversionistasD} /> */}
        <ContainerTribuMob backgroundImage={`url(${InversionistasD})`}>
          <TitleWhite marginD="45px 0 0 0" fontS="18px" fontW="500">Somos la</TitleWhite>
          <TitleWhite fontS="24px">
            Tribu Coru
          </TitleWhite>
        </ContainerTribuMob>
        <ContainerTribu backgroundImage={`url(${InversionistasD})`}>
          <TitleWhite marginD="50px 0 0 0" fontS="50px">Somos la<br />Tribu Coru</TitleWhite>
          <TitleWhite fontS="24px" marginD="20px 0 0 0" fontW="500">
            Un equipo de personas que dedican su tiempo, pasión y habilidades para
            lograr un mejor lugar, sin estrés financiero.
          </TitleWhite>
        </ContainerTribu>
        <TextGray className="roboto" displayMQ="none" marginT="30px">
          Un equipo de personas que dedican su tiempo, pasión y habilidades para
          lograr un mejor lugar, sin estrés financiero.
        </TextGray>
        <TitleBlue className="cabin" fontS="32px" marginD="24px 0">
          Ellos confían en nosotros
        </TitleBlue>
        <img src={ColaboradoresI} style={{ maxWidth: '200px', margin: 'auto' }}/>
        {/* <Colaboradores /> */}
        <TextGray className="roboto" marginT="30px">
          Nuestros inversionistas comparten nuestra misión e incluyen fondos de
          gran prestigio.
        </TextGray>
        <TitleBlue className="cabin" fontS="32px" marginD="24px 0">
          ¿Cómo funciona?
        </TitleBlue>
        <TextGray className="roboto">
          <b>Entra a coru.com o </b>
          <b style={{ color: "#F64282" }}>descarga la app </b>
          desde cualquier dispositivo móvil para conocer a tu Coach, con quien
          podrás:
        </TextGray>
        <ContainerFM>
          <ContainerFlex margin="15px 0">
            <ImageFunction src={Function1} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Hacer diagnóstico
              </TextGray>
              <TextGray className="roboto">
                de la situación actual de tus finanzas.
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0">
            <ImageFunction src={Function2} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Crear
              </TextGray>
              <TextGray className="roboto">Tu Plan Financiero</TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0">
            <ImageFunction src={Function3} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Aprender
              </TextGray>
              <TextGray className="roboto">
                Con entrenamientos interactivos
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0">
            <ImageFunction src={Function4} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Conversar
              </TextGray>
              <TextGray className="roboto">
                En vivo con nuestros expertos financieros para resolver tus dudas.
              </TextGray>
            </div>
          </ContainerFlex>
        </ContainerFM>
        <ContainerFD>
          <ContainerFlex margin="15px 0" textA="center" style={{ width: '25%' }}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Hacer diagnóstico
              </TextGray>
              <ImageFunction src={Function1} />
              <TextGray className="roboto">
                de la situación actual de tus finanzas.
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 15px" textA="center" style={{ width: '25%' }}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Crear
              </TextGray>
              <ImageFunction src={Function2} />
              <TextGray className="roboto">Tu Plan Financiero</TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 15px" textA="center" style={{ width: '25%' }}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Aprender
              </TextGray>
              <ImageFunction src={Function3} />
              <TextGray className="roboto">
                Con entrenamientos interactivos
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0" textA="center" style={{ width: '25%' }}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Conversar
              </TextGray>
              <ImageFunction src={Function4} />
              <TextGray className="roboto">
                En vivo con nuestros expertos financieros para resolver tus dudas.
              </TextGray>
            </div>
          </ContainerFlex>
        </ContainerFD>
        <CardsBtn
          styledMaxWidth="300px"
          type="button"
          min_w="300px"
          min_web="300px"
          max_w="300px"
          windthmd="60%"
          style={{ borderRadius: "16px" }}
        >
          Hablar con un experto
        </CardsBtn>
        <TextGray fontW="600" fontS="18px" className="roboto" textA="center" marginT="30px">
          Consigue la App
        </TextGray>
        <ContainerFlex justifyC="center" margin="30px">
          <ImageDownload src={AppStore} />
          <ImageDownload src={GooglePlay} />
        </ContainerFlex>
      </div>
      <Footer isAboutUS="si" />
    </Container1>
  );
}

export default AboutUs;
