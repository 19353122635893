import React, { Fragment } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { Container } from '../../assets/styles/Home.styled';
import { Rectangle23, Rectangle25, Triangle } from '../common/Home.styled';
import {
  Images,
  CoruCoachSucces,
} from '../../assets/styles/Training.styled';
import ana from '../../assets/img/anacoach/Training/anaSuccess.png';
import logoC from '../../assets/img/anacoach/logoCoach.png';

function HeaderSuccess({
  color1,
  color2,
  color3,
  color4,
  color5,
  color6,
  color7,
  color8,
  color9,
  color10,
  mtopAnaMob = '',
  mtopIconCoruMob = '',
  heightContainerMob = '',
  bg = '',
}) {
  return (
    <Fragment>
      <Container
        className="col-12"
        width=""
        mt="40px"
        displayweb="flex"
        displaymobile="none"
        widthMobile=""
        mtMobile="0px"
        background={bg || '#fff'}
        height="289.406px"
      >
        <Rectangle23
          Ptop="81px"
          Pleft="3%"
          width="100px"
          height="100px"
          background={color1 || '#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Triangle
          width="50px"
          height="50px"
          marginleft="-50px"
          margintop="239px"
          transform="rotate(224deg)"
          widthborder="50px solid transparent"
          widthborderColor={`50px solid${color2 || '#FA9878;'}`}
        />
        <Rectangle23
          Ptop="105px"
          Pleft="15%"
          width="40px"
          height="40px"
          background={color3 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(9.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={color4 || '#FACFA9'}
          bradius="14px"
          left="17%"
          top="242px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="100px"
          Pleft="24%"
          transform="rotate(-33.24deg)"
          width="100px"
          height="100px"
          background={color5 || '#E3A75F'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="142px"
          Pleft="38%"
          width="40px"
          height="40px"
          background={color6 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(9.24deg)"
        />
        <div className="col-12 containerAnaSucces">
          <div className="d-flex flex-row">
            <div className="p-2">
              <Images
                mleftsm="0%"
                leftsm="5%"
                leftw="47%"
                maxwidth="100%"
                rightm="0"
                bottomm="0"
                topm="0"
                le="0"
                position="absolute"
                zindex="1"
                padd="0.2%"
                br="45px 45px 45px 45px"
                width="100px"
                margin="auto"
                top="0"
                left="0"
                right="0"
                bottom="0"
                zi="1"
                widthw="95px"
                src={ana}
                border={`2px solid${color1 || '#FA9878'}`}
                className="img-fluid"
              ></Images>
            </div>
            <div className="p-2">
              <CoruCoachSucces
                pBottom={''}
                bcolor={color1 || '#FA9878'}
                topD1="20%"
                width="40px"
                height="40px"
                zindex="2"
                marginLeft="48%"
                marginTop="80px"
                leftr25="47.4%"
                leftrxl="48%"
              >
                <Images
                  src={logoC}
                  top="9px"
                  topweb="13px"
                  left="-8px"
                  leftw="-13px"
                  width="27px"
                  position="absolute"
                  className="img-fluid"
                ></Images>
              </CoruCoachSucces>
            </div>
          </div>
        </div>

        <Rectangle25
          positionD="absolute"
          background={color7 || '#FACFA9;'}
          bradius="14px"
          left="49%"
          top="89px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="166px"
          Pleft="55%"
          transform="rotate(62.76deg)"
          width="100px"
          height="100px"
          background={color8 || '#E3A75F;'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="81px"
          Pleft="64%"
          width="100px"
          height="100px"
          background={color9 || '#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="230px"
          Pleft="70%"
          width="40px"
          height="40px"
          background={color10 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle23
          Ptop="19px"
          Pleft="80%"
          width="100px"
          height="100px"
          background={color1 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={color2 || '#FACFA9;'}
          bradius="14px"
          left="85%"
          top="242px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="150px"
          Pleft="95%"
          width="40px"
          height="40px"
          background={color3 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
      </Container>
      <Container
        widthMobilexs="100%"
        mtMobilexs="-30px"
        width="100%"
        // mtMobile="40px"
        mt="40px"
        displayweb="none"
        displaymobile="flex"
        widthMobile="100%"
        mtMobile="0px"
        background=""
        height={heightContainerMob || '237.031px'}
      >
        <Rectangle23
          left="2%"
          Ptop="81px"
          Pleft="3%"
          width="60px"
          height="60px"
          background={color1 || '#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="10%"
        />
        <Triangle
          width="50px"
          height="50px"
          marginleft="-5px"
          margintop="180px"
          transform="rotate(452deg)"
          widthborder="50px solid transparent"
          widthborderColor={`50px solid${color2 || '#FA9878;'}`}
        />
        <Rectangle23
          transform="rotate(11.76deg)"
          left="34%"
          Ptop="90px"
          Pleft="3%"
          width="25px"
          height="25px"
          background={color3 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="35%"
        />
        <Rectangle23
          left="60%"
          Ptop="81px"
          Pleft="3%"
          width="60px"
          height="60px"
          background={color4 || '#E3A75F'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="55%"
        />
        <Rectangle23
          transform="rotate(11.76deg)"
          left="90%"
          Ptop="105px"
          Pleft="3%"
          width="40px"
          height="40px"
          background={color5 || '#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="82%"
        />
        <Rectangle25
          topM="0px"
          leftM="70%"
          positionD="absolute"
          background={color6 || '#FACFA9'}
          bradius="20px"
          left="17%"
          top="242px"
          width="50px"
          height="55px"
          displayM=" "
          displayD=" "
          leftMb="75%"
        />
        <div className="col-12 containerAnaSucces successCC CCAna">
          <div className="d-flex flex-row">
            <div className="p-2">
              <Images
                rigthm="0%"
                leftxs="37%"
                topmd=""
                leftw="50%"
                maxwidth="100%"
                rightm="0"
                bottomm="0"
                topm="0"
                le="40%"
                position="absolute"
                zindex="1"
                padd="0.2%"
                br="45px 45px 45px 45px"
                width="90px"
                margin="auto"
                top={mtopAnaMob || '0'}
                left="0"
                right="0"
                bottom="0"
                zi="1"
                widthw="80px"
                src={ana}
                border={`2px solid${color1 || '#FA9878'}`}
                className="img-fluid"
              ></Images>
            </div>
            <div className="p-2">
              <CoruCoachSucces
                pBottom={''}
                bcolor={color1 || '#FA9878'}
                topD1="20%"
                width="40px"
                height="40px"
                zindex="2"
                marginLeft="45%"
                marginTop="15px"
                leftr25="40%"
                leftD2="45%"
                width768="35px"
                height768="35px"
                marginTop768={mtopIconCoruMob || '77px'}
                leftM2="48%"
                leftrxl="30%"
              >
                <Images
                  src={logoC}
                  top="13px"
                  topweb="13px"
                  left="-12px"
                  leftw="-13px"
                  width="27px"
                  position="absolute"
                  className="img-fluid"
                ></Images>
              </CoruCoachSucces>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export default HeaderSuccess;
