import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import {
  Main,
  Container,
  ParagrapTwo,
} from '../../components/common/common.styled';
import FooterFeed from '../../components/Feed/Footer_Feed';
import '../../assets/styles/perfil/perfiles.css';
import Header_Logros from '../../components/Perfiles/Header_Perfil';
import { DYNAMIC_GET } from '../../mutations';
import TestViralidad from '../../components/Logros/TestViralidad';
import CardOne from '../../components/Home/Card1Animate';
import CardComponents from '../../components/Home/Card1AnimateComponents';
import ScoreGraphic from '../../components/Training/ScoreGraphic';
import { CardsBtn } from '../../assets/styles/Training.styled';

function Score({ Novisible, paddingInicio, isViernes }) {
  const [score, setScore] = useState('');
  const [tip, setTip] = useState('');
  const [txtBtnTip, setTxtBtnTip] = useState('');
  const [feacturesArray] = useState([]);
  const anchop = window.screen.width;
  const [viewBtn, setViewBtn] = useState(false);
  const [arrayText] = useState([
    {
      question: '¿Qué es score crediticio?',
      p1:
        'Es el resultado del comportamiento crediticio actual y pasado de una persona u organización, de tal forma que, si se realizan los pagos financieros de manera puntual, el resultado será un score crediticio alto.',
      p2: '',
    },
    {
      question: '¿Para qué nos sirve el score crediticio?',
      p1:
        'Tener un buen score crediticio te ayudará a obtener mejores créditos con una baja tasa de interés, buró de crédito te da una calificación entre los 400 y 850 puntos, entre más arriba estés en esta puntuación mejor será tu score.',
      p2:
        'Si tu score es menor a 650 estas en problemas con buró de crédito será complicado aspirar a un crédito, con un score mayor a 650, estarás dentro de lo regular y podrás adquirir créditos con una tasa de interés un poco alta, y si tu score es mayor a 750, felicidades tendrás la oportunidad de tener cualquier crédito que te ofrezcan y que tú desees.',
    },
  ]);
  const history = useHistory();
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  let Training;
  let userDetail;

  const [getScore] = useMutation(DYNAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      if (dynamicGet) {
        const response = JSON.parse(dynamicGet.response);
        if (response.length > 0) {
          setScore(response[0].Value);
          setViewBtn(true);
          const bcScore = parseInt(response[0].Value);
          if (bcScore < 650) {
            setTip(
              'Tu Score crediticio es muy bajo, pero ¡no te desanimes! Podemos mejorarlo a través de un crédito para reparar tu historial. Trata de pagar a tiempo y por encima del monto mínimo para no generar intereses y con el tiempo mejorará tu Score.',
            );
            setTxtBtnTip('Repara tu historial');
          } else if (bcScore >= 650 && bcScore < 750) {
            setTip(
              '¡Muy bien! Aunque tu Score no es el ideal, es una calificación que te da acceso a algunos créditos, aunque con una tasa de interés un poco alta. No sobregires tu tarjeta y trata de pagar puntualmente para que aumente tu puntuación.',
            );
            setTxtBtnTip('Saber recomendaciones');
          } else if (bcScore >= 750) {
            setTip(
              '¡Excelente! Tu Score crediticio es alto, esto quiere decir que vas al corriente con tus pagos y sueles liquidar el total de tu crédito o por encima del mínimo. Mantenerte así te garantizará el acceso a  mejores créditos con una tasa de interés más baja.',
            );
            setTxtBtnTip('Saber recomendaciones');
          }
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach((element) => {
          switch (element.IDType) {
            case '3':
              sessionStorage.setItem('level', element.Value);
              break;
            default:
              break;
          }
        });
      }
    },
  });
  const ArrayP = [];
  const ArrrayS = [];

  const ConstruccionArray = (items2) => {
    const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
    for (let i = 0; i < items2.length; i += LONGITUD_PEDAZOS) {
      const pedazo = items2.slice(i, i + LONGITUD_PEDAZOS);
      ArrayP.push(pedazo);
    }
  };
  const [getAllTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        Training = JSON.parse(dynamicGet.response);
        for (let i = 0; i < Training.length; i++) {
          if (Training[i].Achievment < 100) {
            ArrrayS.push(Training[i]);
          }
        }
        if (ArrrayS.length === 0) {
          return;
        }
        ConstruccionArray(ArrrayS);
      }
    },
  });

  const getAllTrainingFunction = () => {
    const getAllTrainingInput = {
      input: {
        table: 'CatRelUsersTraining, DatTraining, CatTraining',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
          {
            logic: 'AND',
            valone: 'CatRelUsersTraining.IDDatTraining',
            condition: '=',
            valtwo: 'DatTraining.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDCatTraining',
            condition: '=',
            valtwo: 'CatTraining_IDTraining',
            validColumn: true,
          },
        ],
      },
    };
    getAllTraining({ variables: getAllTrainingInput });
  };
  useEffect(() => {
    getScore({
      variables: {
        input: {
          table: 'CatUserDetails',
          columns: ['*'],
          conditions: [
            {
              valone: 'CatUsers_IDUser',
              condition: '=',
              valtwo: String(sessionStorage.getItem('idUser')),
            },
            {
              logic: 'AND',
              valone: 'IDType',
              condition: '=',
              valtwo: '93',
            },
          ],
        },
      },
    });
  }, []);

  // Visual effects
  const scrollToTop = () => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const dynamicGetInput = {
      input: {
        table: 'CatUserDetails',
        columns: ['*'],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
        ],
      },
    };
    getUserDetail({ variables: dynamicGetInput });
    getAllTrainingFunction();
    scrollToTop();
  }, []);

  const goWoScore = (step = '') => {
    history.push('/wo/know-your-score');
    if (step === 'stepScore') sessionStorage.setItem('stepScore', 'true');
    else sessionStorage.setItem('stepScore', 'false');
  };
  return (
    <Fragment>
      {!Novisible ? (
        <Header_Logros name={sessionStorage.getItem('firstName')} />
      ) : (
        <div></div>
      )}
      <Main
        minheigth="auto"
        background="#fafafa"
        mt={isViernes ? ' ' : '10%'}
        mtWeb={isViernes ? ' ' : '10%'}
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
      >
        {isViernes ? (
          <Fragment>
            <TestViralidad />
            <br />
          </Fragment>
        ) : null}
        <Container
          displayweb="block"
          className="container"
          paddingMobile="0!important"
          width="94%"
          widthMobile="100%"
          mtMobile="0px"
        >
          <ParagrapTwo
            mb="10%"
            fontSize="20px"
            fontSizeWeb="20px"
            mt="5%"
            mbweb="35px"
            textalignMobile="left"
            texttalign="left"
            className="mx-auto roboto"
            mtWeb="10%"
            fontWeight="bold"
          >
            Score crediticio
          </ParagrapTwo>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            textbold="¡Hola! Soy Ana, tu coach financiero."
            text="En este entrenamiento podrás conocer tu calificación o Score crediticio en Buró de Crédito y aprenderás a usarlo a tu favor para conseguir los créditos que deseas."
            logros={true}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            textbold={
              score !== ''
                ? arrayText[0].question
                : '¿Qué es el Score crediticio?'
            }
            text={
              score !== ''
                ? arrayText[0].p1
                : 'Es un informe del comportamiento financiero de una persona u organización, de tal forma que, si realizas los pagos de tus créditos de manera puntual y por encima del mínimo, tu Score crediticio asignado será alto.'
            }
            logros={true}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={'cardOne'}
            classTop="topOne1"
            textbold={
              score !== ''
                ? arrayText[1].question
                : '¿Para qué sirve el Score crediticio?'
            }
            text={
              score !== ''
                ? arrayText[1].p1
                : 'El Score crediticio es clave para obtener mejores créditos con una tasa de interés baja. Buró de crédito asigna una calificación entre los 400 y 850 puntos, entre más alta sea tu puntuación mejor será tu Score.'
            }
            text2={
              score !== ''
                ? arrayText[1].p2
                : 'Si tu Score es menor a 650, será complicado aspirar a obtener un crédito. Si es mayor a 650, podrás adquirir créditos, aunque con una tasa de interés un poco alta. Si es mayor a 750, ¡enhorabuena! Tienes la oportunidad de aplicar y obtener cualquier crédito que desees.'
            }
            logros={true}
          />
          <CardComponents
            classCard={score !== '' ? 'hidden' : 'cardOne'}
            classTop="topOne1"
            Arraytext={feacturesArray}
            textbold="Conozcamos tu Score crediticio"
            text="¿Estás listo para conocer tu Score? Descubrámoslo juntos."
            logros={true}
          >
            <CardsBtn
              min_w="100%"
              windthLg="100%"
              max_w="250"
              onClick={() => goWoScore()}
            >
              Conoce tu Score
            </CardsBtn>
          </CardComponents>
          {viewBtn ? (
            <>
              <ScoreGraphic score={score} logros={true}></ScoreGraphic>
              <CardComponents
                classCard="cardOne"
                classTop="topOne1"
                Arraytext={feacturesArray}
                textbold="Consejo"
                text2={tip}
                logros={true}
              >
                <CardsBtn
                  min_w="100%"
                  windthLg="100%"
                  max_w="250"
                  onClick={() => goWoScore('stepScore')}
                >
                  {txtBtnTip}
                </CardsBtn>
              </CardComponents>
            </>
          ) : null}
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="logros" /> : <div></div>}
    </Fragment>
  );
}
export default Score;
