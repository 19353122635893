import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import AdventureCollaborative from '../Routines/AdventureCollaborative';
import {
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import { GET_TRAINING_COLOR } from '../../queries';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderWoCollaborative = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) => {
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  return (
    <>
      {orderRutine >= 1 && orderRutine <= 2 ? (
        // <DatBasic
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   trainingColor={colorTraining}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        // />
        <ModalLogin 
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 && orderRutine <= 3 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={2}
          nextOrderRoutine={4}
        />
      ) : null}
      {/* {orderRutine >= 3 && orderRutine <= 4 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={3}
          nextOrderRoutine={4}
        />
      ) : null} */}
      {orderRutine >= 4 ? (
        <AdventureCollaborative
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={'rp'}
          trainingColor={colorTraining}
        />
      ) : null}
      {/* {orderRutine == 'rp' ? (
            <RpCollaborative />
         ) : null} */}
    </>
  );
};
export default RoutinesOrderWoCollaborative;
