import React, { useState, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Container,
  Col11,
  ContentButton,
  Col12,
  CorrecionCorreo,
} from '../../components/common/common.styled';
import '../../assets/styles/TextCustom.css';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import { GET_CLIENTES } from '../../queries';
import { SEND_OTP, CREATE_CLICK } from '../../mutations';

const ForgotPassword = () => {
  const [form, setForm] = useState({
    email: '',
  });

  const history = useHistory();
  const [error] = useState({
    email: false,
    emailvalid: -1,
  });
  //! Never used: const [clientValid, setclientValid] = useState();
  const [texterror] = useState({
    email: '',
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [isTelephone, setisTelephone] = useState(true);
  const [valorBusqueda, setvalorBusqueda] = useState('');
  const tagManagerArgs = {
    dataLayer: {
      pageName: 'forgotPassword',
    },
  };
  TagManager.dataLayer(tagManagerArgs);
  const [sendOtp] = useMutation(SEND_OTP, {
    onCompleted({ sendOtp }) {
      if (sendOtp != null) {
        sessionStorage.setItem('showModal', true);
        history.push('/reset-password');
      } else {
        alert('Ocurrio un error al enviar el OTP');
      }
    },
  });

  const [TypeCorreo, SetTypeCorreo] = useState({
    text: '',
    display: '',
    text_correct: '',
  });

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getCliente] = useLazyQuery(GET_CLIENTES, {
    onCompleted({ getCliente }) {
      if (getCliente) {
        if (isTelephone === false) {
          if (getCliente.email != null) {
            sessionStorage.setItem('clickRecoverpassword', true);
            sessionStorage.setItem('Email1', getCliente.email);
            sessionStorage.setItem('email', getCliente.email);
            const caracteres = '0123456789';
            let cadenaResponse = '';
            const charactersLength = caracteres.length;
            for (let i = 0; i < 4; i++) {
              cadenaResponse += caracteres.charAt(
                Math.floor(Math.random() * charactersLength),
              );
            }
            const inputOtp = {
              input: {
                telefono: getCliente.email,
                medio: 'MAIL',
                tipo: 'recovery',
                lastOtp: cadenaResponse,
              },
            };
            const tagManagerArgs = {
              dataLayer: {
                event: 'clickRecoverpassword',
              },
            };
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) dd = `0${dd}`;
            if (mm < 10) mm = `0${mm}`;
            const hours = today.getHours();
            const minutes = today.getMinutes();
            const seconds = today.getSeconds();
            today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
            const timestamp = today;
            const createClickInput = {
              input: {
                id: sessionStorage.getItem('id_session'),
                column: 'clickRecoverpassword',
                collection: 'sessions',
                timeStamp: timestamp,
              },
            };
            if (sessionStorage.getItem('id_session')) { createClickFront({ variables: createClickInput }); }
            TagManager.dataLayer(tagManagerArgs);
            if (getCliente.email !== '') {
              sendOtp({ variables: inputOtp });
              sessionStorage.setItem('recoveryMedium', 'email');
              history.push('/reset-password');
            } else {
              alert('Correo Invalido');
            }
          } else {
            alert('usuario no encontrado');
          }
        } else {
          // solo telefono
          console.log(`Solo Telefono${getCliente.email}`);
          sessionStorage.setItem('email', getCliente.email);
          sessionStorage.setItem('Email1', getCliente.email);
          if (getCliente.telefono != null) {
            sessionStorage.setItem('clickRecoverpassword', true);
            sessionStorage.setItem('cellphone', getCliente.telefono);
            const caracteres = '0123456789';
            let cadenaResponse = '';
            const charactersLength = caracteres.length;
            for (let i = 0; i < 4; i++) {
              cadenaResponse += caracteres.charAt(
                Math.floor(Math.random() * charactersLength),
              );
            }
            const inputOtp = {
              input: {
                telefono: getCliente.telefono,
                medio: 'WA',
                tipo: 'recovery',
                lastOtp: cadenaResponse,
              },
            };

            const tagManagerArgs = {
              dataLayer: {
                event: 'clickRecoverpassword',
              },
            };
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) dd = `0${dd}`;
            if (mm < 10) mm = `0${mm}`;
            const hours = today.getHours();
            const minutes = today.getMinutes();
            const seconds = today.getSeconds();
            today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
            const timestamp = today;
            const createClickInput = {
              input: {
                id: sessionStorage.getItem('id_session'),
                column: 'clickRecoverpassword',
                collection: 'sessions',
                timeStamp: timestamp,
              },
            };
            if (sessionStorage.getItem('id_session')) { createClickFront({ variables: createClickInput }); }
            TagManager.dataLayer(tagManagerArgs);
            if (getCliente.telefono !== '0000000000') {
              inputOtp.input.medio = 'WA';

              sendOtp({
                variables: {
                  input: {
                    telefono: getCliente.telefono,
                    medio: 'WA',
                    tipo: 'recovery',
                    lastOtp: cadenaResponse,
                  },
                },
              });
              inputOtp.input.medio = 'SMS';

              sendOtp({
                variables: {
                  input: {
                    telefono: getCliente.telefono,
                    medio: 'SMS',
                    tipo: 'recovery',
                    lastOtp: cadenaResponse,
                  },
                },
              });
              sessionStorage.setItem('recoveryMedium', 'telephone');
              history.push('/reset-password');
            } else {
              alert('Numero Invalido');
            }
          } else {
            alert('usuario no encontrado');
          }
          // solo telefono fin
        }
      }
    },
  });
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    const emailregular = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const telefono = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    if (telefono.test(e.target.value)) {
      if (e.target.value.length < 10) {
        texterror.email = 'Correo electrónico o número celular incorrecto';
        error.email = true;
        error.emailvalid = 1;
        setbuttonDisabled(true);
      } else {
        SetTypeCorreo({
          display: 'none',
          text: '',
          text_correct: '',
        });
        texterror.email = '';
        error.email = false;
        error.emailvalid = 0;
        setvalorBusqueda(e.target.value);
        setisTelephone(true);
        setbuttonDisabled(false);
      }
    } else {
      let domain = e.target.value.split('@');
      const anexo = domain[0];
      domain = domain[1];
      if (domain !== '' && domain !== 'gmail' && domain !== 'hotmail') {
        if (domain === 'h') {
          SetTypeCorreo({
            display: 'block',
            text: `Quieres decir: ${anexo}@hotmail.com`,
            text_correct: `${anexo}@hotmail.com`,
          });
          texterror.email = '';
          error.email = true;
          error.emailvalid = 1;
        } else if (domain === 'g') {
          SetTypeCorreo({
            display: 'block',
            text: `Quieres decir: ${anexo}@gmail.com`,
            text_correct: `${anexo}@gmail.com`,
          });
          texterror.email = '';
          error.email = true;
          error.emailvalid = 1;
        } else if (domain === 'hotmail.com' || domain === 'gmail.com') {
          SetTypeCorreo({
            display: 'none',
            text: '',
            text_correct: '',
          });
          texterror.email = '';
          error.email = false;
          error.emailvalid = 0;
          setbuttonDisabled(false);
        }
      } else {
        SetTypeCorreo({
          display: 'none',
          text: '',
          text_correct: '',
        });
      }

      if (!emailregular.test(e.target.value)) {
        texterror.email = 'Correo electrónico o número celular incorrecto';
        error.email = true;
        error.emailvalid = 1;
        setbuttonDisabled(true);
      } else {
        texterror.email = '';
        error.email = false;
        error.emailvalid = 0;
        setbuttonDisabled(false);
        sessionStorage.setItem('email', e.target.value);
        setvalorBusqueda(e.target.value);
        setisTelephone(false);

        SetTypeCorreo({
          display: 'none',
          text: '',
          text_correct: '',
        });
      }
      if (e.target.value === '') {
        texterror.email = 'Campo obligatorio';
        error.email = true;
        error.emailvalid = 1;
      }
    }
  };

  const handleClickCorreo = () => {
    setForm({
      ...form,
      email: TypeCorreo.text_correct,
    });
    SetTypeCorreo({
      text: '',
      display: '',
      text_correct: '',
    });
    texterror.email = '';
    error.email = false;
    error.emailvalid = 0;
  };

  const guardar = (e) => {
    e.preventDefault();
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickSaveForgotPassword',
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: 'clickSaveForgotPassword',
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    if (sessionStorage.getItem('id_session')) { createClickFront({ variables: createClickInput }); }
    TagManager.dataLayer(tagManagerArgs);

    getCliente({ variables: { email: valorBusqueda } });
  };

  return (
    <Fragment>
      <Header pathname="/forgot-password" />
      <Main
        className="container"
        background=" "
        pb="0%"
        overflow=""
        padding="15px"
        mtWeb=""
        margintop="2%"
      >
        <Container
          width="45%"
          mt="1rem"
          widthMobile="100%"
          mb="15%"
          mtMobile="2rem"
        >
          <Col11>
            <SubtitleOne size="18px" sizeMobile="18px" className="roboto">
              ¿Olvidaste tu contraseña?
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="10%"
              fontWeight="bold"
            >
              Ingresa tu correo o número celular registrado para identificar tu
              cuenta.
            </ParagrapTwo>
            <form autoComplete="off" onSubmit={guardar}>
              <Container mb="10%" mt="5%" width="100%" widthMobile="100%">
                <Col12 pt="2px">
                  <TextField
                    id="email"
                    name="email"
                    autoFocus
                    label="Correo electrónico o número celular"
                    error={error.email}
                    fullWidth
                    required
                    value={form.email}
                    onChange={handleChange}
                    helperText={texterror.email}
                    inputProps={{
                      className:
                        error.emailvalid === 1
                          ? 'invalidInput intermediateWidth'
                          : error.emailvalid === 0
                            ? 'validInput intermediateWidth'
                            : 'intermediateWidth',
                    }}
                    className="registro"
                    // type="email"
                  />
                  <CorrecionCorreo
                    display={TypeCorreo.display}
                    onClick={handleClickCorreo}
                    className="roboto"
                  >
                    {TypeCorreo.text}
                  </CorrecionCorreo>
                </Col12>
              </Container>
              <ContentButton
                mt="10%"
                mtMobile="2%"
                width="98%"
                widthMobile="98%"
              >
                <Button
                  disabled={buttonDisabled}
                  className="d-block  mt-md-4 roboto"
                >
                  Recuperar contraseña
                </Button>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer />
    </Fragment>
  );
};
export default ForgotPassword;
