import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import {
  Main, Container, ParagrapTwo, Col12,
} from '../../components/common/common.styled';
import FooterFeed from '../../components/Feed/Footer_Feed';
import '../../assets/styles/perfil/perfiles.css';
import Header_Logros from '../../components/Perfiles/Header_Perfil';
import NoTraining from '../../components/Logros/NoTraining';
import ListItemsTrainings from '../../components/Feed/TrainingLogros';
import { DYNAMIC_GET, DYNAMIC_INSERT } from '../../mutations';
import TestViralidad from '../../components/Logros/TestViralidad';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';

function Training_progress({ Novisible, paddingInicio, isViernes }) {
  const [entrenamientos, setentrenamientos] = useState(0);
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });

  const [Trainings, SetTrainigs] = useState([]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  let Training;
  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  let userDetail;

  const [getUser] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response)[0];
        sessionStorage.setItem('CellPhone', userDetail.CellPhone || '');
        sessionStorage.setItem('email', userDetail.Email1);
        sessionStorage.setItem('HomePhone', userDetail.HomePhone || '');
        sessionStorage.setItem('firstName', userDetail.FirstName || '');
        sessionStorage.setItem('RFC', userDetail.RFC || '');
        sessionStorage.setItem('middleName', userDetail.MiddleName || '');
        sessionStorage.setItem('fathersLastName', userDetail.FathersLastName || '');
        sessionStorage.setItem('mothersLastName', userDetail.MothersLastName || '');
        sessionStorage.setItem('BirthDate', userDetail.BirthDate || '');
        sessionStorage.setItem('Gender', userDetail.Gender || '');
        sessionStorage.setItem('MaritalStatus', userDetail.MaritalStatus || '');
        sessionStorage.setItem('BirthState', userDetail.BirthState || '');
        sessionStorage.setItem('Nationality', userDetail.BirthState || '');
        sessionStorage.setItem('BirthState', userDetail.BirthState || '');
        sessionStorage.setItem('BirthState', userDetail.BirthState || '');
        sessionStorage.setItem('DataPerson', true);
        if (sessionStorage.getItem('BirthDate') !== '' && sessionStorage.getItem('BirthDate') != null) {
          const BirthDate = new Date(sessionStorage.getItem('BirthDate'));
          sessionStorage.setItem('day', (BirthDate.getDay()) ? BirthDate.getDay() : `0${BirthDate.getDay()}`);
          sessionStorage.setItem('month', (BirthDate.getMonth() > 8) ? BirthDate.getMonth() + 1 : `0${BirthDate.getMonth() + 1}`);
          sessionStorage.setItem('year', BirthDate.getFullYear());
        }
      }
    },
  });
  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => {
            switch (element.IDType) {
              case '3':
                sessionStorage.setItem('level', element.Value);
                break;
              default:
                break;
            }
          },
        );
      }
    },

  });
  const ArrayP = [];
  const ArrrayS = [];

  const ConstruccionArray = (items2) => {
    setentrenamientos(items2.length);
    const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
    for (let i = 0; i < items2.length; i += LONGITUD_PEDAZOS) {
      const pedazo = items2.slice(i, i + LONGITUD_PEDAZOS);
      ArrayP.push(pedazo);
    }
    SetTrainigs([ArrayP]);
  };
  const [getAllTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        Training = JSON.parse(dynamicGet.response);
        for (let i = 0; i < Training.length; i++) {
          if (Training[i].Achievment < 100) {
            ArrrayS.push(Training[i]);
          }
        }
        if (ArrrayS.length === 0) {
          return;
        }
        ConstruccionArray(ArrrayS);
      }
    },
  });

  const getAllTrainingFunction = () => {
    const getAllTrainingInput = {
      input: {
        table: 'CatRelUsersTraining, DatTraining, CatTraining',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
          {
            logic: 'AND',
            valone: 'CatRelUsersTraining.IDDatTraining',
            condition: '=',
            valtwo: 'DatTraining.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDCatTraining',
            condition: '=',
            valtwo: 'CatTraining_IDTraining',
            validColumn: true,
          },
        ],
      },
    };
    getAllTraining({ variables: getAllTrainingInput });
  };
  // Visual effects
  const scrollToTop = () => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const dynamicGetInput = {
      input: {
        table: 'CatUserDetails',
        columns: ['*'],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
        ],
      },
    };
    const GetUserInput = {
      input: {
        table: 'CatContactMedium, CatContact',
        columns: ['*'],
        conditions: [
          {
            valone: 'IDCont',
            condition: '=',
            valtwo: sessionStorage.getItem('idCont'),
          },
          {
            logic: 'AND',
            valone: 'IDCont',
            condition: '=',
            valtwo: 'CatContact_IDCont',
            validColumn: true,
          },
        ],
      },
    };
    getUser({ variables: GetUserInput });
    getUserDetail({ variables: dynamicGetInput });
    getAllTrainingFunction();
    scrollToTop();
  }, []);
  return (
        <Fragment>
            {!Novisible ? (<Header_Logros name={sessionStorage.getItem('firstName')}/>) : (<div></div>)}
            <Main minheigth="auto" background='#fafafa' mt={isViernes ? ' ' : '10%'} mtWeb= {isViernes ? ' ' : '10%'} ptuno={!paddingInicio ? '70px' : ' ' } pt={!paddingInicio ? '80px' : ' ' }>
                { isViernes
                  ? <Fragment>
                    <TestViralidad/>
                    <br/>
                  </Fragment> : null
                }
                <Container displayweb="block" className="container" paddingMobile="0!important" width="94%" widthMobile="100%" mtMobile="0px">
                    <ParagrapTwo
                        mb="10%" fontSize="20px" fontSizeWeb="20px" mt="5%" mbweb="35px"
                        textalignMobile="left"
                        texttalign="left"
                        className="mx-auto roboto" mtWeb="10%"
                        fontWeight="bold">
                        Tienes pendientes:
                    </ParagrapTwo>
                    {Trainings.length === 0 ? (
                         <NoTraining tipo={'en progreso'}></NoTraining>
                    )
                      : (
                      <Fragment>
                        <ContainerPerfiles padding="4%" height="auto" width="80%" minheight="122px" display="flex" mb="5%" background="#fff">
                          <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto">
                              <Col12 ta="center" pt="0px" >
                                <ParagrapTwo mt="0px" fontSize="40px" color="#F64282" fontSizeWeb="40px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>{entrenamientos}</ParagrapTwo>
                              </Col12>
                              <Col12 ta="center" pt="0px" >
                                  <ParagrapTwo mt="0px" mtWeb="0px" lh="1.2" textalignMobile="center" texttalign="center" className='mx-auto roboto'>Entrenamientos</ParagrapTwo>
                              </Col12>
                          </Container>
                        </ContainerPerfiles>
                        <ListItemsTrainings
                          items={Trainings}
                          dynamicInsert={dynamicInsert}
                        ></ListItemsTrainings>
                      </Fragment>

                      )
                    }

                </Container>
            </Main>
            {!Novisible ? (<FooterFeed check='logros'/>) : (<div></div>)}
        </Fragment>
  );
}
export default Training_progress;
