import React, { Fragment, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import AntExpenseCategories from '../Routines/AntExpenseCategories';
import EntertainmentServices from '../Routines/EntertainmentServices';
import RpWoAntExpenses from '../Routines/RpWoAntExpenses';
import FoodDelivery from '../Routines/FoodDelivery';
import OnlineShopping from '../Routines/OnlineShopping';
import TransportApps from '../Routines/TransportApps';
import Desserts from '../Routines/Desserts';
import RecordMyExpenses from '../Routines/RecordMyExpenses';
import { INSERT_DAT_EXPENSES } from '../../mutations';
import { GET_ANT_EXPENSES } from '../../queries';
import { filterFloat } from '../../Helpers';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderAntExpenses = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
}) => {

  const [typeOfServices, setTypeOfServices] = useState('');
  const handleServices = (opc) => setTypeOfServices(opc);
  const [dataTable, setDataTable] = useState([]);
  const [insertDatAntExpenses] = useMutation(INSERT_DAT_EXPENSES, {
    fetchPolicy: 'no-cache',
    onCompleted({ insertDatAntExpenses }) {
      if (insertDatAntExpenses.response === 'success') {
        getAntExpenses({
          variables: {
            idUser: String(sessionStorage.getItem('idUser')),
            idTraining: String(sessionStorage.getItem('idTraining')),
          },
        });
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const [getAntExpenses] = useLazyQuery(GET_ANT_EXPENSES, {
    fetchPolicy: 'no-cache',
    onCompleted({ getAntExpenses }) {
      if (getAntExpenses.message === 'success') {
        const resp = JSON.parse(getAntExpenses.response);
        const arrayExpenses = [];
        let annualExpenditureTotal = 0;
        let monthlyExpenditureTotal = 0;
        for (let i = 0; i < resp.length; i++) {
          arrayExpenses.push({
            text1: resp[i].category,
            text2: formatter.format(filterFloat(resp[i].MonthlyExpenditure)),
            text3: formatter.format(filterFloat(resp[i].AnnualExpenditure)),
          });
          monthlyExpenditureTotal += parseFloat(resp[i].MonthlyExpenditure);
          annualExpenditureTotal += parseFloat(resp[i].AnnualExpenditure);
        }
        setDataTable([
          ...arrayExpenses,
          {
            text1: 'Total',
            text2: formatter.format(filterFloat(monthlyExpenditureTotal)),
            text3: formatter.format(filterFloat(annualExpenditureTotal)),
          },
        ]);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  return (
    <Fragment>
      {orderRutine >= 1 ? (
        // <DatBasic
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   trainingColor={trainingColor}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        //   recoveryTimeout={true}
        // />
        <ModalLogin 
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={2}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null}
      {/* {orderRutine >= 3 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={3}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null} */}
      {orderRutine >= 4 ? (
        <AntExpenseCategories
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={4}
          nextOrderRoutine={'rp'}
          possibleRoutines={[5, 6, 7, 8, 9, 10]}
          handleServices={handleServices}
        />
      ) : null}
      {orderRutine >= 5 && typeOfServices === 'ServicioEntretenimiento' ? (
        <EntertainmentServices
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={5}
          insertDatAntExpenses={insertDatAntExpenses}
          nextOrderRoutine={'rp'}
        />
      ) : null}
      {orderRutine >= 6 && typeOfServices === 'Comida' ? (
        <FoodDelivery
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          insertDatAntExpenses={insertDatAntExpenses}
          currentRoutine={6}
          nextOrderRoutine={'rp'}
        />
      ) : null}
      {orderRutine >= 7 && typeOfServices === 'Compras' ? (
        <OnlineShopping
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          insertDatAntExpenses={insertDatAntExpenses}
          currentRoutine={7}
          nextOrderRoutine={'rp'}
        />
      ) : null}
      {orderRutine >= 8 && typeOfServices === 'Transporte' ? (
        <TransportApps
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          insertDatAntExpenses={insertDatAntExpenses}
          currentRoutine={8}
          nextOrderRoutine={'rp'}
        />
      ) : null}
      {orderRutine >= 9 && typeOfServices === 'Postres' ? (
        <Desserts
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          insertDatAntExpenses={insertDatAntExpenses}
          currentRoutine={9}
          nextOrderRoutine={'rp'}
        />
      ) : null}
      {orderRutine >= 10 && typeOfServices === 'RegistroGastos' ? (
        <RecordMyExpenses
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          insertDatAntExpenses={insertDatAntExpenses}
          currentRoutine={10}
          nextOrderRoutine={'rp'}
        />
      ) : null}
      {orderRutine === 'rp' ? (
        <>
        
        <RpWoAntExpenses
          setOrderRutine={setOrderRutine}
          dataTable={dataTable}
        />
        </>
        
      ) : null}
    </Fragment>
  );
};

export default RoutinesOrderAntExpenses;
