/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import ShareWinWo from "../../assets/gifs/rewardsCoruallpage.gif";
import ShareWinCoruPage from "../../assets/gifs/rewardsTraining.gif";
import ModalVirality2 from "./ModalVirality";
import ClickButton from "../../Helpers/HookHelpers";

export default function ShareWin({ bannerType, margin, width, widthMb, name }) {
  const [OpenModal, setOpenModal] = useState(false);
  const nameactions = `BannerRewards_${name}`;
  const activeProd = true;
  const { actions } = ClickButton();
  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
    actions({
      action: 'ClickButtonCollection',
      variables: `BannerRewards_${name}`,
    });
  };

  return (
    <>
      <CardContainer
        onClick={() => setOpenModal(true)}
        widthMd={width}
        marginMd={margin}
        widthMb={widthMb}
        width="100%"
      >
        {bannerType === "wo" ? (
          <img className="img-fluid" alt="" src={ShareWinWo} />
        ) : (
          <img className="img-fluid" alt="" src={ShareWinCoruPage} />
        )}
      </CardContainer>
      {OpenModal && activeProd ? (
        <ModalVirality2
          setShowModal={handleOpenModal}
          showModal={OpenModal}
          nameactions={nameactions}  
        />
      ) : null}
    </>
  );
}
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: ${(props) => props.widthMb || ""};
  cursor: pointer;
  @media (min-width: 768px) {
    margin: ${(props) => props.marginMd || ""};
    position: ${(props) => props.positionMd || ""};
    top: ${(props) => props.topMd || ""};
    bottom: ${(props) => props.bottomMd || ""};
    width: ${(props) => props.widthMd || ""};
    height: ${(props) => props.heigthMd || ""};
    padding: ${(props) => props.paddingMd || ""};
    align-self: ${(props) => props.alignSelfMd || ""};
  }
`;
