/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import SectionVideo from '../../components/common/SectionVideo';
import editIcon from '../../assets/img/anacoach/editar1.png';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';

// let elementScrolled = [];
const PerfilIversion = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActivarFlujo = '',
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const { sendEmail } = SendNotificationEmail();
  const { sendMessage } = SendNotificationFTAndCC();
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [VideoInversion, setVideoInversion] = useState(false);
  const [
    preguntasdePerfilamientoInversion,
    setPreguntasdePerfilamientoInversion,
  ] = useState(
    sessionStorage.getItem('PreguntasdePerfilamientoInversion') || '',
  );

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend >>> ', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    console.log(
      'handleChangeCatOption >>> value > ',
      value,
      ' | step > ',
      step,
    );
    switch (step) {
      case 1:
        setEditTipoInversion(true);
        sendEmail({ WO: 'FirstInv', trigger: '1er_inv_alerta3' });
        sendMessage({
          cellPhone: sessionStorage.getItem('CellPhone') || '',
          trigger: 'wa_inv_3',
        });
        setPreguntasdePerfilamientoInversion(value);
        if (value === 'Conocer tips e ir a la calculadora de inversiones') {
          setOrderRutine(nextOrderRoutine);
        } else if (value === 'Directo a la calculadora de inversiones') {
          setActivarFlujo('calculadora');
        } else if (
          value
          === 'Me gustaría conocer mi perfil de inversionista y realizar el test Coru'
        ) {
          setActivarFlujo('perfilInversionista');
        }
        break;

      default:
        console.log('default break handleChangeCatOption case 4');
        break;
    }
    gsap.to(window, { duration: 1, scrollTo: '#tipoInversion' });
  };
  const handleChange = (e) => {
    console.log('handleChange >>> ', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          let contVideos = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (questionType === 'Video') {
                contVideos++;
                console.log('video ', contVideos);
              }
            }

            if (
              arrayResponseRutine.length + contVideos
              < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length + contVideos,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
    sendEmail({ WO: 'FirstInv', trigger: '1er_inv_alerta2' });
    sendMessage({
      cellPhone: sessionStorage.getItem('CellPhone') || '',
      trigger: 'wa_inv_2',
    });
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setPreguntasdePerfilamientoInversion(
        sessionStorage.getItem('PreguntasdePerfilamientoInversion') || '',
      );

      const PreguntasdePerfilamientoInversion = sessionStorage.getItem('PreguntasdePerfilamientoInversion') || '';
      if (PreguntasdePerfilamientoInversion !== '') {
        setVideoInversion(true);
        if (
          PreguntasdePerfilamientoInversion
          === 'Conocer tips e ir a la calculadora de inversiones'
        ) {
          setOrderRutine(nextOrderRoutine);
        } else if (
          PreguntasdePerfilamientoInversion
          === 'Directo a la calculadora de inversiones'
        ) {
          setActivarFlujo('calculadora');
        } else if (
          PreguntasdePerfilamientoInversion
          === 'Me gustaría conocer mi perfil de inversionista y realizar el test Coru'
        ) {
          setActivarFlujo('perfilInversionista');
        }
      }
    }
  }, [recoveryRoutine]);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editTipoInversion, setEditTipoInversion] = useState('');

  const handleChangeIcon = (json) => {
    setEditTipoInversion(false);
    setActivarFlujo('');
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const styleBtnDisabled = {
    color: '#fff',
    background: '#aaa',
    border: 'solid 1px #fff',
    display: 'none',
  };
  const [btnContinueVideo, setBtnContinueVideo] = useState({});
  const advanceVideo = (e) => {
    e.preventDefault();
    const click = `ContinuarVideoPerfil_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueVideo(styleBtnDisabled);
    setVideoInversion(true);
    gsap.to(window, { duration: 1, scrollTo: e.target.getAttribute('href') });
  };

  return (
    <>
      {/* -----------
        First question
        -------------*/}
      <TrainingQuestion mt="0px" wiw="100%" className="">
        <SectionVideo title="" benefits={['']}>
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="0-0"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
          <div id="CtnVideo"></div>
          <CardsBtn
            style={btnContinueVideo}
            mtop="19px"
            type="button"
            min_w="100%"
            min_web="100%"
            max_w="100%"
            onClick={(e) => advanceVideo(e)}
            href="#CtnVideo"
          >
            Continuar
          </CardsBtn>
        </SectionVideo>
      </TrainingQuestion>

      <SendNotificationVideo currentRoutine={currentRoutine} />
      {/* -----------
        Second question
        -------------*/}
      <QuestionsOrder
        classCard={VideoInversion ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((VideoInversion && preguntasdePerfilamientoInversion === '')
            || (lapiz[0].routine === 2 && lapiz[0].active === 1))
          && editTipoInversion !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="tipoInversion"></div>
      <div>
        <img
          alt="editIcon"
          className={
            VideoInversion
            && preguntasdePerfilamientoInversion !== ''
            && (editTipoInversion === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 1))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 2, active: 1 })}
        />
        <CardTwo
          classCard={
            VideoInversion
            && preguntasdePerfilamientoInversion !== ''
            && (editTipoInversion === true
              || !(lapiz[0].routine === 2 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={preguntasdePerfilamientoInversion}
          colorTraining={trainingColor}
        ></CardTwo>
      </div>
    </>
  );
};

export default PerfilIversion;
