/* eslint-disable no-tabs */
import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { TrainingText, ErrorDiv } from '../../assets/styles/Training.styled';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Container,
  Col12,
  Col6,
  Col11,
  ContentButton,
  CorrecionCorreo,
  LinksFooter,
} from '../../components/common/common.styled';
import '../../assets/styles/TextCustom.css';
import { day, months, years } from '../../Helpers';
import {
  CREATE_USER,
  CREATE_SESSION,
  UPDATE_SESSION,
  CREATE_CLICK,
} from '../../mutations';
import { EVEREST_VALIDATE_EMAIL, VERIFY_ACCOUNT } from '../../queries';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Aceptterms from '../../components/common/Acceptterms';

const PersonalInformationContainer = () => {

  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted({ createClick }) {
        console.log(createClick);
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );
  const LPpageplanes = sessionStorage.getItem('LPpageplanes') === 'true';
  const [alertDatosP, setAlertDP] = useState(false);
  const [alertDate, setAlertDate] = useState(false);
  const [TypeCorreo, SetTypeCorreo] = useState({
    text: '',
    display: '',
    text_correct: '',
  });
  const [everestValidateEmail] = useLazyQuery(EVEREST_VALIDATE_EMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted({ everestValidateEmail }) {
      console.log('response everes =', everestValidateEmail.response)
    }
  })
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const history = useHistory();
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [registerClient] = useMutation(CREATE_USER, {
    onCompleted({ registerClient }) {
      if (registerClient.message === 'El usuario ya existe') {
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
        SetTypeCorreo({
          display: 'block',
          text: 'Usuario existente inicie sesión',
          text_correct: '',
        });
      } else {
        const response = JSON.parse(registerClient.response);
        sessionStorage.setItem('idUser', response.idUser);
        sessionStorage.setItem('idCont', response.idCont);
        sessionStorage.setItem('idSession', response.idSession);
        sessionStorage.setItem('idCatRelAction', response.idCatRelAction);
        localStorage.setItem('idUser', response.idUser);
        localStorage.setItem('idCont', response.idCont);
        localStorage.setItem('idSession', response.idSession);
        localStorage.setItem('idCatRelAction', response.idCatRelAction);
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const dataJson = {
          idCont: response.idCont,
          idUser: response.idUser,
          idSession: response.idSession,
          clickPersonalInformation: timestamp,
        };
        const filterJson = {
          _id: sessionStorage.getItem('id_session'),
        };
        const dataString = JSON.stringify(dataJson);
        const filterString = JSON.stringify(filterJson);
        const mongoUpdateInput = {
          input: {
            collection: 'sessions',
            data: dataString,
            filter: filterString,
          },
        };
        updateSession({ variables: mongoUpdateInput });
        const timestap = new Date().toLocaleString();
        const mongoInputBinacle = {
          idCont: response.idCont,
          idUser: response.idUser,
          FirstName: [
            {
              value: sessionStorage.getItem('firstName'),
              timestap,
            },
          ],
          MiddleName: [
            {
              value: sessionStorage.getItem('middleName'),
              timestap,
            },
          ],
          FathersLastName: [
            {
              value: sessionStorage.getItem('fathersLastName'),
              timestap,
            },
          ],
          MothersLastName: [
            {
              value: sessionStorage.getItem('mothersLastName'),
              timestap,
            },
          ],
          PreferredPhone: [
            {
              value: sessionStorage.getItem('cellPhone'),
              timestap,
            },
          ],
          email: [
            {
              value: sessionStorage.getItem('email'),
              timestap,
            },
          ],
          profile: [
            {
              value: sessionStorage.getItem('typeUser'),
              timestap,
            },
          ],
          lastUpdate: timestap,
        };
        const dataStringBinacle = JSON.stringify(mongoInputBinacle);
        const mongoInput = {
          input: {
            collection: 'binacle',
            data: dataStringBinacle,
          },
        };
        createSession({ variables: mongoInput });
        const tagManagerArgs = {
          dataLayer: {
            event: 'clickRegisterUser',
          },
        };
        const createClickInput = {
          input: {
            id: sessionStorage.getItem('id_session'),
            column: 'clickRegisterUser',
            collection: 'sessions',
            timeStamp: timestamp,
          },
        };
        if (sessionStorage.getItem('id_session')) {
          createClickFront({ variables: createClickInput });
        }
        TagManager.dataLayer(tagManagerArgs);
        history.push('/new-password');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [stateDate, setStateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    secondname: '',
    email: '',
    motherslastname: '',
    cel: '',
  });
  useEffect(() => {
    if (
      stateDate.day !== ''
      && stateDate.month !== ''
      && stateDate.year !== ''
    ) {
      setAlertDate(false);
      setAlertDP(false);
    }
  }, [stateDate.day, stateDate.month, stateDate.year]);
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const [error] = useState({
    email: false,
    firstname: false,
    secondname: false,
    lastname: false,
    motherslastname: false,
    cel: false,
    emailvalid: -1,
    firstnamevalid: -1,
    secondnamevalid: -1,
    lastnamevalid: -1,
    motherslastnamevalid: -1,
    celvalid: -1,
    day: false,
    month: false,
    year: false,
  });
  const [texterror] = useState({
    email: '',
    firstname: '',
    secondname: '',
    lastname: '',
    motherslastname: '',
    cel: '',
  });

  const eBtn = () => {
    if (
      form.email.length !== ''
      && !error.email
      && form.firstname.length !== ''
      && !error.firstname
      && !error.secondname
      && form.lastname.length !== ''
      && !error.lastname
      && form.motherslastname.length !== ''
      && !error.motherslastname
    ) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  };

  const ValidEmail = (name, value) => {
    let domain = value.split('@');
    const anexo = domain[0];
    domain = domain[1];

    if (domain !== '' && domain !== 'gmail' && domain !== 'hotmail') {
      if (domain === 'h') {
        SetTypeCorreo({
          display: 'block',
          text: `Quieres decir: ${anexo}@hotmail.com`,
          text_correct: `${anexo}@hotmail.com`,
        });
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === 'g') {
        SetTypeCorreo({
          display: 'block',
          text: `Quieres decir: ${anexo}@gmail.com`,
          text_correct: `${anexo}@gmail.com`,
        });
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === 'hotmail.com' || domain === 'gmail.com') {
        SetTypeCorreo({
          display: 'none',
          text: '',
          text_correct: '',
        });
        texterror.email = '';
        error.email = false;
        error.emailvalid = 0;
      }
    } else {
      SetTypeCorreo({
        display: 'none',
        text: '',
        text_correct: '',
      });
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailvalid = 1;
    } else {
      texterror.email = '';
      error.email = false;
      error.emailvalid = 0;
      setbuttonDisabled(true);
      SetTypeCorreo({
        display: 'none',
        text: '',
        text_correct: '',
      });
    }
    if (value === '') {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailvalid = 1;
    }
    eBtn();
  };
  const ValidLetras = (nombre, valor) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    if (valor.length > 2) {
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'secondname') {
      if (valor.length === 0) {
        error[nombre] = false;
        texterror[nombre] = '';
        error[`${nombre}valid`] = -1;
      } else {
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = 'Debe ingresar más de 3 caracteres';
      }
    } else {
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    eBtn();
  };
  const ValidCel = (name, value) => {
    if (name === 'cel') {
      setForm({
        ...form,
        numPhone: value.replace(/[^0-9]/g, ''),
      });
      if (
        (value.length < 11 && value.length !== 10)
        || (name && !/^[0-9]+$/i.test(value))
      ) {
        setForm({
          ...form,
          [name]: value.replace(/[^0-9]/g, ''),
        });
        error.cel = 'Por favor verifica tu número telefónico';
        error.celvalid = 1;

        // (".MuiInput-underline").addClass("MuiInput-underline-error");
      } else if (value.length < 11 && value.length === 10) {
        setForm({
          ...form,
          [name]: value.replace(/[^0-9]/g, ''),
        });
        sessionStorage.setItem('cellPhone', value.replace(/[^0-9]/g, ''));
        error.cel = '';
        error.celvalid = 0;
      }
    } else {
      setForm({
        ...form,
        lada: value,
        textlada: value,
        cel: value,
      });
      const indexLada = value.indexOf('+(');
      const ladaselect = value.substring(indexLada);
      sessionStorage.setItem('ladauser', ladaselect);
    }
  };
  const handleChangeDate = (e) => {
    setStateDate({
      ...stateDate,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    switch (e.target.name) {
      case 'email':
        ValidEmail(e.target.name, e.target.value);
        break;
      case 'cel':
        ValidCel(e.target.name, e.target.value);
        break;
      default:
        ValidLetras(e.target.name, e.target.value);
        break;
    }
  };
  const ValidDate = () => {
    if (
      stateDate.day === ''
      && stateDate.month === ''
      && stateDate.year === ''
    ) {
      setAlertDate(true);
      // eBtnLp();
      return false;
    }
    if (
      stateDate.day === ''
      || stateDate.month === ''
      || stateDate.year === ''
    ) {
      setAlertDP(true);
      // eBtnLp();
      return false;
    }
    setAlertDate(false);
    setAlertDP(false);
    error.day = true;
    error.month = true;
    error.year = true;
    // eBtnLp();
    return true;
  };

  const [verifyAccount] = useLazyQuery(VERIFY_ACCOUNT, {
    fetchPolicy: 'no-cache',
    onCompleted({ verifyAccount }) {
      const { response } = verifyAccount;
      const data = JSON.parse(response);
      if (JSON.parse(data).length > 0) {
        SetTypeCorreo({
          display: 'block',
          text: 'Usuario existente inicie sesión',
          text_correct: '',
        });
      } else {
        guardarDatos();
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const getVerifyAccount = (e) => {
    e.preventDefault();
    verifyAccount({variables: {value: form.email}})
  }
  const guardarDatos = () => {
    if (LPpageplanes) {
      const validDate = ValidDate();
      if (
        validDate
        && !error.email
        && !error.firstname
        && !error.secondname
        && !error.lastname
        && !error.motherslastname
      ) {
        if (String(form.email).indexOf('@test') !== -1) {
          sessionStorage.setItem('typeUser', 'test');
          localStorage.setItem('typeUser', 'test');
        }
        const BirthDate = `${stateDate.year}-${stateDate.month}-${stateDate.day}`;
        const idGa = sessionStorage.getItem('ID_GA');
        const infoUser = {
          FirstName: form.firstname,
          MiddleName: form.secondname,
          FathersLastName: form.lastname,
          MothersLastName: form.motherslastname,
          PreferredPhone: sessionStorage.getItem('cellPhone'),
          email: form.email,
          BirthDate,
          profile: sessionStorage.getItem('typeUser'),
          idGa,
        };
        const inputUser = {
          input: infoUser,
        };

        sessionStorage.setItem('firstName', form.firstname);
        sessionStorage.setItem('middleName', form.secondname);
        sessionStorage.setItem('fathersLastName', form.lastname);
        sessionStorage.setItem('mothersLastName', form.motherslastname);
        sessionStorage.setItem('email', form.email);
        sessionStorage.setItem('FirstName', form.firstname);
        sessionStorage.setItem('MiddleName', form.secondname);
        sessionStorage.setItem('FathersLastName', form.lastname);
        sessionStorage.setItem('MothersLastName', form.motherslastname);
        sessionStorage.setItem('Email1', form.email);
        sessionStorage.setItem('day', stateDate.day);
        sessionStorage.setItem('month', stateDate.month);
        sessionStorage.setItem('year', stateDate.year);
        localStorage.setItem('Email1', form.email);
        localStorage.setItem('FirstName', form.firstname);
        localStorage.setItem('MiddleName', form.secondname);
        localStorage.setItem('FathersLastName', form.lastname);
        localStorage.setItem('MothersLastName', form.motherslastname);

        const tagManagerArgs = {
          dataLayer: {
            event: 'clickCrearusuarioStep4',
          },
        };
        TagManager.dataLayer(tagManagerArgs);

        everestValidateEmail({
          variables: {
            email: form.email
          }
        })
        setTimeout(() => {
          registerClient({
            variables: inputUser,
          });
        }, 3600)
      }
    } else {
      guardar();
    }
  };

  const guardar = () => {
    if (
      !error.email
      && !error.firstname
      && !error.secondname
      && !error.lastname
      && !error.motherslastname
    ) {
      if (String(form.email).indexOf('@test') !== -1) {
        sessionStorage.setItem('typeUser', 'test');
        localStorage.setItem('typeUser', 'test');
      }

      const idGa = sessionStorage.getItem('ID_GA');
      const infoUser = {
        FirstName: form.firstname,
        MiddleName: form.secondname,
        FathersLastName: form.lastname,
        MothersLastName: form.motherslastname,
        PreferredPhone: sessionStorage.getItem('cellPhone'),
        email: form.email,
        profile: sessionStorage.getItem('typeUser'),
        idGa,
      };
      const inputUser = {
        input: infoUser,
      };

      sessionStorage.setItem('firstName', form.firstname);
      sessionStorage.setItem('middleName', form.secondname);
      sessionStorage.setItem('fathersLastName', form.lastname);
      sessionStorage.setItem('mothersLastName', form.motherslastname);
      sessionStorage.setItem('email', form.email);
      sessionStorage.setItem('FirstName', form.firstname);
      sessionStorage.setItem('MiddleName', form.secondname);
      sessionStorage.setItem('FathersLastName', form.lastname);
      sessionStorage.setItem('MothersLastName', form.motherslastname);
      sessionStorage.setItem('Email1', form.email);
      localStorage.setItem('Email1', form.email);
      localStorage.setItem('FirstName', form.firstname);
      localStorage.setItem('MiddleName', form.secondname);
      localStorage.setItem('FathersLastName', form.lastname);
      localStorage.setItem('MothersLastName', form.motherslastname);

      const tagManagerArgs = {
        dataLayer: {
          event: 'clickCrearusuarioStep4',
        },
      };
      TagManager.dataLayer(tagManagerArgs);

      everestValidateEmail({
        variables: {
          email: form.email
        }
      })
      try {
        setTimeout(() => {
          registerClient({
            variables: inputUser,
          });
        }, 3600)
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleClickCorreo = () => {
    if (TypeCorreo.text !== 'Usuario existente inicie sesión') {
      setForm({
        ...form,
        email: TypeCorreo.text_correct,
      });
      SetTypeCorreo({
        text: '',
        display: '',
        text_correct: '',
      });
      texterror.email = '';
      error.email = false;
      error.emailvalid = 0;
    } else {
      history.push('/login');
    }
  };

  const initSession = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickIniciarSesionStep4', true);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickIniciarSesionStep4',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    history.push('/login');
  };

  return (
    <Fragment>
      <Header pathname="/personal-Information" />
      <Main
        className="container"
        background=" "
        pb="3%"
        mbWeb="30%"
        overflow=""
        padding="15px 0px"
        mb="0%"
        paddingWeb="0px 0px"
        margintop="2%"
      >
        <Container width="50%" mt="1rem" widthMobile="100%" mtMobile="3rem">
          <Col11 flex="0 0 95%" maxwidth="95%">
            <SubtitleOne
              id="idTitle6"
              className="font-weight-bold roboto"
              size="20px"
              sizeMobile="20px"
            >
              Datos principales
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="5%"
              mt="5%"
              mbweb="5%"
              fontWeight=" "
            >
              {!LPpageplanes
                ? 'Ingresa tus datos personales para personalizar tu cuenta.'
                : 'Con tus datos crearemos una cuenta en Coru, para poder dar seguimiento a tus avances en tu plan.'}
            </ParagrapTwo>
            <form autoComplete="off" className="" onSubmit={getVerifyAccount}>
              <Container width="100%" widthMobile="100%">
                <Col12>
                  <TextField
                    id="email"
                    name="email"
                    autoFocus
                    error={error.email}
                    label="Correo electrónico"
                    required
                    className="textInput registro"
                    fullWidth
                    type="email"
                    value={form.email}
                    onChange={handleChange}
                    helperText={texterror.email}
                    inputProps={{
                      className:
                        error.emailvalid === 1
                          ? 'invalidInput intermediateWidth'
                          : error.emailvalid === 0
                            ? 'validInput intermediateWidth'
                            : 'intermediateWidth',
                    }}
                  />
                  <CorrecionCorreo
                    display={TypeCorreo.display}
                    onClick={handleClickCorreo}
                    className="roboto"
                  >
                    {TypeCorreo.text}
                  </CorrecionCorreo>
                </Col12>
                {LPpageplanes ? (
                  <Col12>
                    <TextField
                      id="cel"
                      name="cel"
                      error={error.cel}
                      label="Teléfono"
                      className="textInput registro"
                      fullWidth
                      type="tel"
                      value={form.cel}
                      onChange={handleChange}
                      helperText={texterror.cel}
                      inputProps={{
                        className:
                          error.celvalid === 1
                            ? 'invalidInput intermediateWidth'
                            : error.celvalid === 0
                              ? 'validInput intermediateWidth'
                              : 'intermediateWidth',
                      }}
                    />
                  </Col12>
                ) : null}
                <Col6>
                  <TextField
                    id="firstname"
                    name="firstname"
                    error={error.firstname}
                    label="Primer Nombre"
                    required
                    fullWidth
                    className="textInput registro"
                    value={form.firstname}
                    onChange={handleChange}
                    helperText={texterror.firstname}
                    inputProps={{
                      className:
                        error.firstnamevalid === 1
                          ? 'invalidInput intermediateWidth'
                          : error.firstnamevalid === 0
                            ? 'validInput intermediateWidth'
                            : 'intermediateWidth',
                    }}
                  />
                </Col6>
                <Col6>
                  <TextField
                    id="secondname"
                    name="secondname"
                    error={error.secondname}
                    label="Segundo Nombre"
                    className="textInput registro"
                    fullWidth
                    value={form.secondname}
                    onChange={handleChange}
                    helperText={texterror.secondname}
                    inputProps={{
                      className:
                        error.secondnamevalid === 1
                          ? 'invalidInput intermediateWidth'
                          : error.secondnamevalid === 0
                            ? 'validInput intermediateWidth'
                            : 'intermediateWidth',
                    }}
                  />
                </Col6>
                <Col6>
                  <TextField
                    id="lastname"
                    name="lastname"
                    error={error.lastname}
                    label="Apellido Paterno"
                    required
                    className="textInput registro"
                    value={form.lastname}
                    fullWidth
                    onChange={handleChange}
                    helperText={texterror.lastname}
                    inputProps={{
                      className:
                        error.lastnamevalid === 1
                          ? 'invalidInput intermediateWidth'
                          : error.lastnamevalid === 0
                            ? 'validInput intermediateWidth'
                            : 'intermediateWidth',
                    }}
                  />
                </Col6>
                <Col6>
                  <TextField
                    id="motherslastname"
                    name="motherslastname"
                    error={error.motherslastname}
                    label="Apellido Materno"
                    required
                    className="textInput registro"
                    fullWidth
                    value={form.motherslastname}
                    onChange={handleChange}
                    helperText={texterror.motherslastname}
                    inputProps={{
                      className:
                        error.motherslastnamevalid === 1
                          ? 'invalidInput intermediateWidth'
                          : error.motherslastnamevalid === 0
                            ? 'validInput intermediateWidth'
                            : 'intermediateWidth',
                    }}
                  />
                </Col6>
                {LPpageplanes ? (
                  <Col12>
                    <TrainingText
                      bot="0px"
                      className="cabin"
                      align="left"
                      weight="400"
                      size="17px"
                      sizew="17px"
                    >
                      Fecha de nacimiento
                    </TrainingText>
                  </Col12>
                ) : null}
                {LPpageplanes ? (
                  <Fragment>
                    <Col12>
                      <TextField
                        variant="outlined"
                        id="day"
                        name="day"
                        select
                        label="Día"
                        size="small"
                        className="marginright6 minwidth50 inputs-bottom-fn input-coru"
                        value={stateDate.day}
                        onChange={handleChangeDate}
                      >
                        {day.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>{' '}
                      <TextField
                        variant="outlined"
                        id="month"
                        name="month"
                        select
                        label="Mes"
                        className="minwidth inputs-bottom-fn input-coru"
                        value={stateDate.month}
                        onChange={handleChangeDate}
                      >
                        {months.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>{' '}
                      <TextField
                        variant="outlined"
                        id="year"
                        name="year"
                        select
                        label="Año"
                        className="marginright inputs-bottom-fn input-coru"
                        value={stateDate.year}
                        onChange={handleChangeDate}
                      >
                        {years.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Col12>
                    <ErrorDiv className={alertDate === true ? '' : 'hidden'}>
                      Parece que no has llenado tu fecha de nacimiento
                    </ErrorDiv>
                    <ErrorDiv className={alertDatosP === true ? '' : 'hidden'}>
                      Parece que te falto llenar algún campo
                    </ErrorDiv>
                  </Fragment>
                ) : null}
              </Container>
              <ContentButton mtMobile="2%">
                <Button
                  disabled={buttonDisabled}
                  mb="1.2rem!important"
                  type="submit"
                  className="d-block mt-5 mt-md-4 mb-5 roboto"
                  label="Guardar"
                >
                  {!LPpageplanes ? 'Guardar' : 'Continuar'}
                </Button>
                {!LPpageplanes ? (
                  <LinksFooter
                    className="link roboto"
                    fontSize="14px"
                    href="/login"
                    onClick={initSession}
                  >
                    Iniciar sesión
                  </LinksFooter>
                ) : null}
                <Aceptterms />
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/personal-Information" />
    </Fragment>
  );
};

export default PersonalInformationContainer;
