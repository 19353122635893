import React, { Fragment } from 'react';
import {
  GeneralTableS, TableTd, TableTh, TableTr,
} from '../../assets/styles/Training.styled';

function GeneralTable({ color, heads, data }) {
  return (
        <Fragment>
            <GeneralTableS id="generaltable">
                <TableTr>
                    {heads.map((item) => (
                        <TableTh color={color}>{item}</TableTh>
                    ))}
                </TableTr>
                    {data.map(({ text1, text2, text3 }) => {
                      if (text1 === 'Total') {
                        return (
                                <TableTr>
                                    <TableTd fw="bold">{text1}</TableTd>
                                    <TableTd fw="bold">{text2}</TableTd>
                                    <TableTd fw="bold">{text3}</TableTd>
                                </TableTr>
                        );
                      }
                      return (
                                <TableTr>
                                    <TableTd>{text1}</TableTd>
                                    <TableTd>{text2}</TableTd>
                                    <TableTd>{text3}</TableTd>
                                </TableTr>
                      );
                    })}
            </GeneralTableS>
        </Fragment>
  );
}

export default GeneralTable;
