import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { useMutation, useQuery } from '@apollo/client';
import { INSERT_ROUTINE } from '../../../mutations';
import { GET_ROUTINES_BY_ID_TRAINING } from '../../../queries';
import GeneralHooks from '../../../Helpers/HookHelpersGeneral';

let inputRutine = {
  email: '',
  cellphone: '',
  rutine: [],
};
const Cc3InfoPersonal = () => {
  const { actions } = GeneralHooks();
  const history = useHistory();
  let routines = [];
  let currentRoutine = {};
  const curretTraining = JSON.parse(sessionStorage.getItem('currentTraining'));
  /** [useQuery sirve para gardar las preguntas de la rutina]
     * [variables description]
     * @type {Object}
     */
  const { data, loading, error } = useQuery(GET_ROUTINES_BY_ID_TRAINING, {
    variables: {
      idTraining: parseInt(curretTraining.idTrainning),
      orderRutine: 4,
    },
  });
    /**
     * [useMutation mutacion para insertar los valores]
     * @param  {[type]} INSERT_ROUTINE_TWIN_FUNNEL_CC [description]
     * @param  {[type]} {           onCompleted({           insertRoutine }) {              if (insertRoutine.message [description]
     * @return {[type]}                               [description]
     */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      const jsonResponse = JSON.parse(insertRoutine.response);
      console.log(jsonResponse);
      sessionStorage.setItem('idUser', jsonResponse.idUser);
      sessionStorage.setItem('idCont', jsonResponse.idCont);
      sessionStorage.setItem('idDatSession', jsonResponse.idSession);
      sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
      localStorage.setItem('idUser', jsonResponse.idUser);
      localStorage.setItem('idCont', jsonResponse.idCont);
      localStorage.setItem('idDatSession', jsonResponse.idSession);
      history.push(
        '/coach/training/adquieretutarjetadecredito/twinfunnel/step4-domicilio',
      );
    },
  });
  useEffect(() => {
    if (data) {
      routines = JSON.parse(data.getRoutinesByIdTraining);
      for (const i in routines) {
        currentRoutine = routines[i];
        console.log(currentRoutine);
        break;
      }
    }
  }, [data, currentRoutine]);

  const [state, setState] = useState({
    genero: '',
    est_nac: '',
    studies: '',
    est_civil: '',
    dependents: '',
  });

  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };

  const createJsonInput = () => {
    localStorage.setItem('activityDate', new Date());
    const id = document.querySelectorAll("[id^='q_']"); // [myAttribute=value]
    let numQuestion = 0;
    id.forEach((item, index) => {
      if (
        item.id.indexOf('q_') !== -1
                && !(item.id.indexOf('-label') !== -1)
      ) {
        numQuestion = parseInt(item.id.split('_')[1]) - 1;
        const question = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = question.IDCatQuestions;
        rutine.datUserDetailStorage = question.DatUserDetailStorage;
        rutine.questionType = question.QuestionType;
        if (question.QuestionType === 'Cat') {
          rutine.catFieldDetail = getFielOption(
            question.detailQuestion,
            item.value,
          );
        } else {
          rutine.value = item.value;
        }

        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      }
    });
    actions({ action: 'getAchievment', variables: '' });
  };

  const handleChange = (event) => {
    const { name } = event.target;
    sessionStorage.setItem(name, event.target.value);
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const guardar = () => {
    inputRutine.email = sessionStorage.getItem('email');
    inputRutine.cellphone = sessionStorage.getItem('CellPhone');
    inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
    inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
    inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
    createJsonInput();
    const newRutine = inputRutine.rutine.filter(() => true);
    inputRutine.rutine = newRutine;
    console.log(inputRutine);

    console.log(JSON.stringify(inputRutine));
    if (sessionStorage.getItem('idDatSession')) {
      inputRutine.idMysql = parseInt(sessionStorage.getItem('idDatSession'));
    }
    insertRoutine({ variables: { input: inputRutine } });
    inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
  };
  return (
        <Fragment>
            <form
                autoComplete="off"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
                <div>
                    <p style={{ margin: '10px', color: 'black' }}>
                        <b>
                            Los bancos requieren esta información para procesar
                            tu solicitud
                        </b>
                    </p>
                </div>
                <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                >
                    <FormControl
                        variant="outlined"
                        style={{
                          margin: '10px',
                          marginLeft: '25px',
                          width: '100%',
                        }}
                    >
                        <InputLabel>Género</InputLabel>
                        <Select
                            native
                            value={state.genero}
                            onChange={handleChange}
                            label="Selecciona tu ingreso"
                            inputProps={{
                              name: 'genero',
                              id: 'q_1',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option>Selecciona tu genero</option>
                            <option value={1}>Masculino</option>
                            <option value={2}>Femenino</option>
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{
                          margin: '10px',
                          marginLeft: '25px',
                          width: '100%',
                        }}
                    >
                        <InputLabel>Estado de nacimiento</InputLabel>
                        <Select
                            native
                            value={state.est_nac}
                            onChange={handleChange}
                            label="Selecciona tu ingreso"
                            inputProps={{
                              name: 'est_nac',
                              id: 'q_2',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option>Elige una opción</option>
                            <option value={8}> Aguascalientes</option>
                            <option value={9}> Baja California</option>
                            <option value={10}> Baja California Sur</option>
                            <option value={11}> Campeche</option>
                            <option value={12}> Chiapas</option>
                            <option value={13}> Chihuahua</option>
                            <option value={14}> Ciudad de México</option>
                            <option value={15}> Coahuila de Zaragoza</option>
                            <option value={16}> Colima</option>
                            <option value={17}> Durango</option>
                            <option value={18}> Estado de México</option>
                            <option value={19}> Guanajuato</option>
                            <option value={20}> Guerrero</option>
                            <option value={21}> Hidalgo</option>
                            <option value={22}> Jalisco</option>
                            <option value={23}> Michoacán de Ocampo</option>
                            <option value={24}> Morelos</option>
                            <option value={25}> Nayarit</option>
                            <option value={26}> Nuevo León</option>
                            <option value={27}> Oaxaca</option>
                            <option value={28}> Puebla</option>
                            <option value={29}> Querétaro de Arteaga</option>
                            <option value={30}> Quintana Roo</option>
                            <option value={31}> San Luis Potosí</option>
                            <option value={32}> Sin Localidad</option>
                            <option value={33}> Sinaloa</option>
                            <option value={34}> Sonora</option>
                            <option value={35}> Tabasco</option>
                            <option value={36}> Tamaulipas</option>
                            <option value={37}> Tlaxcala</option>
                            <option value={38}> Veracruz</option>
                            <option value={39}> Yucatán</option>
                            <option value={40}> Zacatecas</option>s
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{
                          margin: '10px',
                          marginLeft: '25px',
                          width: '100%',
                        }}
                    >
                        <InputLabel>Máximo grado de estudios</InputLabel>
                        <Select
                            native
                            value={state.studies}
                            onChange={handleChange}
                            label="Selecciona tu ingreso"
                            inputProps={{
                              name: 'studies',
                              id: 'q_3',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option>Elige una opción</option>
                            <option value={41}> Licenciatura</option>
                            <option value={42}> Posgrado</option>
                            <option value={43}> Preparatoria</option>
                            <option value={44}> Primaria</option>
                            <option value={45}> Secundaria</option>
                            <option value={46}> Sin educación</option>
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{
                          margin: '10px',
                          marginLeft: '25px',
                          width: '100%',
                        }}
                    >
                        <InputLabel>Estado Civil</InputLabel>
                        <Select
                            native
                            value={state.est_civil}
                            onChange={handleChange}
                            label="Estado Civil"
                            inputProps={{
                              name: 'est_civil',
                              id: 'q_4',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option>Elige una opción</option>
                            <option value={47}> Soltero</option>
                            <option value={48}> Casado</option>
                            <option value={49}> Separado</option>
                            <option value={50}> Divorciado</option>
                            <option value={51}> Viúdo</option>
                            <option value={52}> Unión Libre</option>
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{
                          margin: '10px',
                          marginLeft: '25px',
                          width: '100%',
                        }}
                    >
                        <InputLabel>Dependientes económicos</InputLabel>
                        <Select
                            native
                            value={state.dependents}
                            onChange={handleChange}
                            label="Selecciona tu ingreso"
                            inputProps={{
                              name: 'dependents',
                              id: 'q_5',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option>Elige una opción</option>
                            <option value={53}> 1</option>
                            <option value={54}> 2</option>
                            <option value={55}> 3</option>
                            <option value={56}> 4</option>
                            <option value={57}> 5</option>
                            <option value={58}>Más de 6</option>
                        </Select>
                    </FormControl>
                    <Button
                        style={{
                          margin: '10px',
                          marginBottom: '200px',
                          marginLeft: '25px',
                          width: '100%',
                        }}
                        onClick={guardar}
                        variant="contained"
                        color="secondary"
                    >
                        Continuar
                    </Button>
                </div>
            </form>
        </Fragment>
  );
};

export default Cc3InfoPersonal;
