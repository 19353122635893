import React, { useState, useEffect, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import '../../assets/styles/Home.css';
import imgWo from '../../assets/img/anacoach/logo_investmentSimulator.svg';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  VERIFY_USER_WITH_TRAINING,
} from '../../queries';

import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainigTam,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import RoutinesOrderInvestmentSimulator from './RoutinesOrderInvestmentSimulator';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariable,
  getQueryVariableHashtagDeeplink,
} from '../../Helpers';
import Header from '../../components/common/Header';
import WaitList from '../../components/Training/WaitList';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import WOlayout from '../../components/layouts/WOlayout';
import {
  Container,
  Col7,
} from '../../components/common/common.styled';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';

const items2 = ['Simulador de inversiones', ''];
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function InvestmentSimulator() {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 27);
  sessionStorage.setItem('disponibleParaInversion', 1000000);
  const [percentage, setPercentage] = useState('');
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(27);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [arrayOrderRoutines] = useState([]);
  const [woComplete, setWoComplete] = useState(false);
  const [activeRecovery, setActiveRecovery] = useState(false);
  const [initTraining] = useState(true);
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        // console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        // console.log("update exitoso")
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      arrayOrderRoutines.push(orderRoutine);
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log('CreateJsonInput');
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    pushArrayOrderRoutine(orderRutine);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1') || '';
        inputRutine.cellphone = sessionStorage.getItem('CellPhone') || '';
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') === null
            && sessionStorage.getItem('idDatSession') === null
          ) {
            // console.log("existe el usuario pero no esta logueado");
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            // console.log("existe el usuario pero  esta logueado");
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (
            jsonResponse.idSession !== ''
            || (jsonResponse.idSession !== null
              && jsonResponse.idSession !== undefined)
          ) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
            const currentOrderRoutine = sessionStorage.getItem(
              'currentOrderRoutine',
            );
            saveAdvance(parseInt(currentOrderRoutine));
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
          const currentOrderRoutine = sessionStorage.getItem(
            'currentOrderRoutine',
          );
          saveAdvance(parseInt(currentOrderRoutine));
        }
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [verifyUserWithTraining] = useLazyQuery(VERIFY_USER_WITH_TRAINING, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ verifyUserWithTraining }) {
      console.log(verifyUserWithTraining);
      if (verifyUserWithTraining.message === 'success') {
        const json = JSON.parse(verifyUserWithTraining.response);
        if (json[0].Achievment === '100' || json[0].Achievment === 100) {
          sessionStorage.setItem('auxIdDatTraining', json[0].IDDatTraining);
          setWoComplete(true);
          setOrderRutine('rp');
        } else {
          setActiveRecovery(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (orderRutine === 1) {
      verifyUserWithTraining({
        variables: {
          idUser: sessionStorage.getItem('idUser'),
          idTraining: sessionStorage.getItem('idTraining'),
        },
      });
    }
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('wo-goals');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);

  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveStatusTraining }) {
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (activeRecovery) {
      pushArrayOrderRoutine(orderRutine);
      saveAdvance(orderRutine);
    }
    if (orderRutine === 1 || orderRutine === 2) {
      saveAdvance(orderRutine);
    }
    if (orderRutine === 4) {
      saveAdvance(3);
    }
  }, [orderRutine, activeRecovery]);
  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    if (arrayOrderRoutines.includes(3) || arrayOrderRoutines.includes(4)) {
      let deleteRoutine = arrayOrderRoutines.indexOf(3);
      if (deleteRoutine !== -1) arrayOrderRoutines.splice(deleteRoutine, 1);
      deleteRoutine = arrayOrderRoutines.indexOf(4);
      if (deleteRoutine !== -1) arrayOrderRoutines.splice(deleteRoutine, 1);
    }
    if (orderRutine !== 6 && orderRutine.length >= 4) {
      arrayOrderRoutines.splice(3, 1);
    }
    if (!arrayOrderRoutines.includes(1)) {
      arrayOrderRoutines.unshift(1);
    }
    const stringOrderRoutines = arrayOrderRoutines.join(',');

    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: stringOrderRoutines,
      },
    };

    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent != 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setactive3(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null && !woComplete) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 27 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  const hora = `${date.getHours()}:${minutos}`;
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      setactive3(true);
    }, 13000);
  }, [active2]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  const feacturesArray = useState([
    {
      text: 'Jugar con tu dinero.',
      color: '#42A145',
    },
    {
      text: 'Aprender a invertir.',
      color: '#42A145',
    },
    {
      text: 'Saber en qué te conviene y en que no te conviene invertir.',
      color: '#42A145',
    },
  ]);

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '5 minutos',
    imgWo: { imgWo },
    txtsubtitle: '¡Entrena tu músculo inversionista!',
  };
  const anchop = window.screen.width;

  return (
    <WOlayout
      backgroundColor={'#42A145'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Simulador Inversión | Coru</title>
        <link rel="canonical" href="https://coru.com/wo/investment-simulator" />
        <meta name="keywords" content="simulador inversion" />
        <meta
          name="description"
          content="Si quieres invertir y saber qué podría darte mejores rendmientos entonces tienes que usar este simulador. Seguro podemos ayudarte. Te toma 10 minutos."
        />
      </Helmet>
      <TrainingMain>
        {true ? (
          <Fragment>
            <TrainingAnimateFunnel
              bcolor="#42A145"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <TrainigTam>
                <Header
                  background={'#42A145'}
                  pathname={window.location.pathname}
                />
              </TrainigTam>
              <BarraInfoWO propied={propied} />
              <TrainingLine id="line-percent-gray" mt="" mtw="0"></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="0"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
            </TrainingAnimateFunnel>
            <div style={{position: 'relative'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {orderRutine !== 'rp' ? (
                  <Fragment>
                    <ModalDataPersonalInitWO
                      isData={isData}
                      setData={setisData}
                    />
                    <DataPersonalInitWO
                      Date={Date}
                      hora={hora}
                      orderRutine={orderRutine}
                      setOrderRutine={setOrderRutine}
                      currentTraining={currentTraining}
                      createJsonInput={createJsonInput}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                    <div className="shapes">
                      <TrainingSectionGray
                        wiw="100%"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <TrainingDivSection2
                          paddiw="0"
                          paddi="0"
                          back="#FAFAFA"
                        >
                          <div className="marginComentLgSuccess1">
                            {active0 ? (
                              <Starttraining
                                classAna="trueAna"
                                title="¡Hola! Soy Ana, tu coach financiero"
                                text="En este entrenamiento podrás practicar cómo, dónde y cuanto invertir para obtener los mejores rendimientos."
                              />
                            ) : null}
                            {active1 ? (
                              <Starttraining
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                classAna="trueAna"
                                title="El objetivo es:"
                                text="Descubrirás lo que podrías ganar o perder si invirtieras en realidad."
                              />
                            ) : null}
                            {active2 ? (
                              <Features
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="¿En qué ayuda este entrenamiento para mí?"
                                Arraytext={feacturesArray}
                              />
                            ) : null}
                          </div>
                        </TrainingDivSection2>
                      </TrainingSectionGray>
                    </div>

                    {!active3 ? (
                      <Fragment>
                        <br></br>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="1rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio colorAna="#917AFF" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
                {active3 ? (
                  <TrainingSectionGray
                    wiw="100%"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      {initTraining ? (
                        <div className="marginComentLgSuccess1">
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <RoutinesOrderInvestmentSimulator
                                Date={stateDate}
                                hora={hora}
                                orderRutine={orderRutine}
                                setOrderRutine={setOrderRutine}
                                currentTraining={currentTraining}
                                createJsonInput={createJsonInput}
                                questions={questions}
                                setQuestions={setQuestions}
                                woComplete={woComplete}
                                setWoComplete={setWoComplete}
                                setActiveRecovery={setActiveRecovery}
                              />
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                        </div>
                      ) : null}
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                ) : null}
                {recomendaciones && orderRutine === 7 ? (
                  <Fragment>
                    <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-10 offset-md-1 col-xs-12">
                      <Feed_Main width="100%" widthxs="100%" background=" ">
                        <Feed_Main
                          width="100%"
                          display=" "
                          mtop=" "
                          background="transparent"
                        >
                          <Feed_Section padding="0 0 0 0" paddingxs="0 0 0 0">
                            <ContentHomeSection
                              leftD="0%"
                              floatD=" "
                              width="100%;"
                            >
                              <Recommendations
                                items={recommendedTraining}
                              ></Recommendations>
                            </ContentHomeSection>
                          </Feed_Section>
                        </Feed_Main>
                      </Feed_Main>
                    </div>
                  </Fragment>
                ) : null}
              </div>
              <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
              </div>
            </div>
            <br />
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            <WaitList />
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
}

export default InvestmentSimulator;
