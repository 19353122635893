/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useLazyQuery, useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import InputsOrder from '../../components/common/InputsOrder';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import ProductsFremium from '../../components/common/ProductsFremium';
import {
  Container,
  Image,
  Col7,
  ParagrapTwo,
} from '../../components/common/common.styled';
import {
  CardsBtn,
  TrainingDivSection,
  TrainingQuestion,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, filterFloat} from '../../Helpers';
import confetis from '../../assets/img/anacoach/Hero.png';
import Recommendations from '../../components/Training/RecommendedTraining';
import {
  Title1,
  Rectangle13,
  UiCard,
  TextoCoach,
  ContentHomeSection,

  Rectangle24,
  Vector2,
  Vector3,
} from '../../components/common/Home.styled';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import {
  INSERT_DAT_GOAL,
} from '../../mutations';
import ModalHistoryEmergencySaving from './ModalHistoryEmergencySaving';
import ClickButton from '../../Helpers/HookHelpers';
import ModalVirality from '../../components/common/ModalVirality';

const styles = {
  paddingLeft: '10px',
  fontFamily: 'cabin',
  fontStyle: 'normal',
  fontSize: '19px',
  lineHeight: '120%',
  letterSpacing: '-0.3px',
};

const stylesResult = {
  marginTop: '10px',
  textAlign: 'left',
  fontSize: '16px',
  padding: '2% 2% 2% 3%',
  border: '1px solid #888',
  color: '#EC407A',
  borderRadius: '4px',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '600px',
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function monthName(dateString) {
  const date = new Date(dateString.replace(/-+/g, '/'));
  const options = {
    month: 'long',
  };
  return date.toLocaleDateString('es-MX', options);
}

function NumberFormatCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={true}
      prefix={'$'}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const ModalRegisterSAving = ({
  setOpen,
  open,
  handleRegisterSaving,
  handleChange,
  ahorro,
  setahorro,
}) => {
  const { actions } = ClickButton();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [currentMonth, setCurrentMonth] = useState('');

  const handleClose = () => {
    const click = `RegresaralEntrenamiento_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOpen(false);
    setahorro('$');
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };
  useEffect(() => {
    const date = new Date();
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    const nameDay = monthName(`${year}-${month}-${day}`);
    setCurrentMonth(nameDay[0].toUpperCase() + nameDay.slice(1));
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <ContentHomeSection
          displayD="flex"
          leftD="0%"
          floatD="left"
          width="100%;"
        >
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="48%;"
          ></ContentHomeSection>
          <ImageCoachInicio />
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="35%;"
          ></ContentHomeSection>
        </ContentHomeSection>
        <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
          <Rectangle24
            positionD="absolute"
            Ptop="125px"
            PTopM="-65px"
            Pleft="-10%"
            background="#6BD5AF"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="70px"
            height="70px"
            transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
          />
          <Vector2
            width="55px"
            left="0"
            PleftM="35%"
            height="65px"
            background="#91D770"
            radius="40%"
            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
            positionD="absolute"
            Pleft="17%"
            Ptop="-5px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="10px"
            Pleft="44%"
            background=" #E3E660;"
            transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="none"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="100px"
            Pleft="74%"
            PLeftM="-78%"
            PTopM="-32%"
            background="#BFDD6A"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            transform="rotate(46.41deg)"
          />
          <Vector2
            width="50px"
            widthM="28.81px"
            heightM="35.02px"
            left=""
            height="60px"
            background="#6BD5AF"
            radius="40%"
            transform="rotate(-8.34deg)"
            positionD="absolute"
            Pleft="52%"
            PleftM="-15%"
            PTopM="-10%"
            MtopM="-15%"
            Ptop="135px"
            displayM="block"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            Ptop="85px"
            Pleft="14%"
            PleftM="-3%"
            background="#E3E660"
            transform="rotate(-15.06deg)"
            width="35px"
            height="35px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            widthM="35px"
            heightM="35px"
            mLeftM="0"
            mTopM="18%"
            positionD="absolute"
            Pleft="70%"
            Ptop="0"
            displayD=""
            width="60px"
            height="60px"
            left=" "
            top=" "
            background="#91D770"
            transform="rotate(-17.24deg)"
          />
          <UiCard
            positionM=" "
            heightM="150px"
            marginTop="95px"
            marginTopM="88px"
          >
            <Rectangle13
              boxSha=" "
              margin="auto"
              bottomM="0px"
              displayM="block"
              position=" "
              topM="0px"
              height="156px"
              width="100%"
              widthm="100%"
              padding="19px 25px 20px 21px"
            >
              <TextoCoach height="auto" className="roboto" width="auto">
                <b>¡Hagamos tu primer registro!</b>
                <br />
                <br />
              </TextoCoach>
              <TextoCoach
                height="auto"
                align="left"
                className="roboto"
                width="auto"
              >
                Por favor, ingresa la cantidad que te gustaria ahorrar el dia de
                hoy.
              </TextoCoach>
              <TextoCoach
                className="roboto"
                width="auto"
                size="20px"
                align="left"
                top="4%"
              >
                <b>Mes: {currentMonth} </b>
              </TextoCoach>
            </Rectangle13>
          </UiCard>
        </ContentHomeSection>
        <TextField
          variant="outlined"
          name="ahorro"
          value={ahorro}
          onChange={handleChange}
          placeholder="$"
          fullWidth
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />

        <CardsBtn
          min_w="100%"
          min_web="100%"
          onClick={(e) => handleRegisterSaving(e)}
        >
          <b>Guardar</b>
        </CardsBtn>
        <br />
        <br />
        <Link to="">
          <CardsBtn
            back="#FFFFFF"
            color="#F64282"
            border="2px solid #F64282"
            min_w="100%"
            min_web="100%"
            onClick={handleClose}
          >
            <b>Regresar al entrenamiento</b>
          </CardsBtn>
        </Link>
      </div>
    </Modal>
  );
};
const ControlTooling = ({
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  handleOpcTool,
  handleNewtool,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    goal: `$${sessionStorage.getItem('TablaObjetivos')}` || '',
    advance:
      sessionStorage.getItem('IniciarAhorroEmegente') === 'Sí'
        ? sessionStorage.getItem('CantidadAhorro')
        : '',
    advanceToday: sessionStorage.getItem('MesesLogroDeAhorro') || '',
  });
  const [Rutine, setRutine] = useState({});
  const [ahorro, setahorro] = useState('$');
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery, setCountRecovery] = useState(0);
  const [openHistory, setOpenHistory] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                // setOrderRutine(nextOrderRoutine);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertDatGoal] = useMutation(INSERT_DAT_GOAL, {
    onCompleted({ insertDatGoal }) {
      if (insertDatGoal.message === 'success') {
        console.log('insercion exitossa, ID = ', insertDatGoal.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  const handleChangeRutine = (data) => {
    setRutine(data);
  };
  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: formatter.format(filterFloat(value)),
    });
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log(val);
    }
  };
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  const getCurrentDateTime = (m) => {
    const dateOb = new Date();
    const day = (`0${dateOb.getDate()}`).slice(-2);
    dateOb.setMonth(dateOb.getMonth() + m);
    const month = (`0${dateOb.getMonth() + 1}`).slice(-2);
    const year = dateOb.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const handleRegisterSaving = (e) => {
    const click = `Guardar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    if (!isEmptyObject(Rutine)) {
      questions.push(
        {
          id: 'goal',
          question: '1',
          value: form.goal,
        },
        {
          id: 'advance',
          question: '2',
          value: ahorro,
        },
      );
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    if (form.goal !== '' || ahorro !== '') {
      const dateComplete = getCurrentDateTime(parseInt(form.advanceToday));
      const idUser = parseInt(sessionStorage.getItem('idUser'));
      const idCatTraining = parseInt(sessionStorage.getItem('idTraining'));
      const monto = convertirNum(ahorro);
      const inputInsert = {
        input: {
          idUser,
          idTraining: idCatTraining,
          name: 'Ahorro de emergencia',
          goalsDetails: [
            {
              idType: '231',
              value: form.goal,
            },
            {
              idType: '227', // fechaMeta
              value: dateComplete,
            },
          ],
          saveAmount: monto.toString() || 0,
        },
      };
      insertDatGoal({
        variables: inputInsert,
      });
    }
    setOpen(false);
  };
  const handlerChangeSaving = (e) => {
    setahorro(e.target.value);
  };
  const backRutine = (e) => {
    const click = `Regresar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setOrderRutine(6);
    handleOpcTool('');
    sessionStorage.removeItem('HerramientaDeAhorro');
    handleNewtool(true);
  };

  useEffect(() => {
    if (
      recoveryRoutine === 'activa'
      || (sessionStorage.getItem('idUser') === '0'
        && sessionStorage.getItem('idCont') === '0')
    ) {
      setForm({
        goal: sessionStorage.getItem('TablaObjetivos') || '',
        advance:
          sessionStorage.getItem('IniciarAhorroEmegente') === 'Sí'
            ? sessionStorage.getItem('CantidadAhorro')
            : '',
        advanceToday: sessionStorage.getItem('MesesLogroDeAhorro') || '',
      });
    }
  }, [recoveryRoutine]);

  const setOpencont = () => {
    const click = `RegistrarAhorro_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOpen(true);
  };

  const setOpenHistoryTrue = () => {
    const click = `VerHistorico_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOpenHistory(true);
  };
  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      gsap.to(window, { duration: 1, scrollTo: '#TablaCtrlAhorro' });
    }, 3000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (
        document.getElementsByName('goal')[2] !== null
        && document.getElementsByName('goal')[2] !== undefined
      ) {
        const valObj = document.getElementsByName('goal')[2].value;
        document.getElementsByName('goal')[2].value = formatter.format(valObj);
      }
    }, 5000);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <Fragment>
        {!loaderWait || orderRutine !== 7 ? (
          <>
            <div className="marginComentLgSuccess1">
              <TrainingDivSection
                backw="#f8f9fa00"
                padding="0px"
                paddw="0px"
                mb="0"
                wi="90%"
                wiw="100%"
                he="auto"
                hew="auto"
                bsha="null"
              >
                <TrainingQuestion>
                  <TrainingDivSection
                    back="transparent"
                    backw="transparent"
                    mlw="18%"
                    paddw="0px"
                    wiw="60%"
                    padding="0px"
                  >
                    <div id="TablaCtrlAhorro"></div>
                    <div style={{ width: '100%' }}>
                      <Image
                        src={confetis}
                        height="238px"
                        marginLeftM="0px"
                        heightMobile="200px"
                        margin="0px"
                        top="110px"
                        left="0%"
                        mleft="0%"
                        marginLeft="0%"
                        mtop="75px"
                      />
                      <Title1
                        style={{ fontStyle: 'normal' }}
                        backgroundM="transparent"
                        pbottom="6%"
                        background="#FAFAFA"
                        position="sticky"
                        positionM="absolute"
                        mleft="auto"
                        mright="auto"
                        heightM="auto"
                        aling="center"
                        className="roboto cabin"
                        mb="175px"
                        mbM="0px"
                        left="0%"
                        size="35px"
                        mtopM="-25%"
                        mt="-147px"
                        mleftM="0px"
                        sizeM="30px"
                        widthM="100%"
                        width="68%"
                      >
                        Tabla Control de Ahorro
                      </Title1>
                    </div>
                    <TrainingDivSection
                      bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                      mt="0px"
                      mtw="-20%"
                      br="4px"
                      padding="15px"
                      wi="auto"
                      wiw="auto"
                      he="auto"
                      mb="0px"
                    >
                      <form
                        onSubmit={(e) => handleSubmit(e)}
                        className="wo-input"
                        style={{ marginTop: '0px', padding: '10px' }}
                      >
                        <label>
                          Recuerda{' '}
                          {sessionStorage.getItem('FirstName') || 'Laura'}, tu
                          objetivo es llegar a:
                        </label>
                        <label style={styles}>
                          <b>Objetivo de ahorro</b>
                        </label>
                        <InputsOrder
                          handleChangeQuestionsSend={handleChangeQuestionsSend}
                          handleChangeRutine={handleChangeRutine}
                          orderRutine={orderRutine}
                          step="0-0"
                          handleChangeCatOption={handleChangeCatOption}
                          inputProps={{
                            style: { stylesResult },
                          }}
                          form={form}
                          handleChange={handleChange}
                          disable={true}
                        />

                        <br />
                        <label style={styles}>
                          <b>Tiempo para cumplir meta establecida</b>
                        </label>
                        <TextField
                          variant="outlined"
                          id="advanceTodays"
                          name="advanceToday"
                          autoComplete="off"
                          required
                          // select
                          fullWidth
                          inputProps={{
                            className: form.advanceToday
                              ? 'textInput inputs-bottom validInputSaving'
                              : 'textInput inputs-bottom',
                            style: { stylesResult },
                          }}
                          value={form.advanceToday}
                          onChange={handleChange}
                          disabled={true}
                        />
                      </form>
                      <br />
                      <br />
                    </TrainingDivSection>
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      onClick={() => setOpencont()}
                    >
                      <b>Registrar ahorro</b>
                    </CardsBtn>
                    <ModalVirality />
                    <ModalRegisterSAving
                      setOpen={setOpen}
                      open={open}
                      ahorro={ahorro}
                      setahorro={setahorro}
                      handleRegisterSaving={handleRegisterSaving}
                      handleChange={handlerChangeSaving}
                    />
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      border="1px solid #F64282"
                      back="#fff"
                      color="#F64282"
                      onClick={() => setOpenHistoryTrue()}
                    >
                      <b>Ver histórico</b>
                    </CardsBtn>
                    <ModalHistoryEmergencySaving
                      setOpenHistory={setOpenHistory}
                      openHistory={openHistory}
                    />
                    <CardsBtn
                      min_w="100%"
                      min_web="100%"
                      border="1px solid #F64282"
                      back="#fff"
                      color="#F64282"
                      onClick={(e) => backRutine(e)}
                    >
                      <b>Regresar</b>
                    </CardsBtn>
                  </TrainingDivSection>
                </TrainingQuestion>
              </TrainingDivSection>
            </div>
             <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '21' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
            <ModalAmazon show={true} wo={'Emergency'}></ModalAmazon>
            {recomendaciones ? (
              <div className="marginComentLgSuccess1">
                <Container
                  mlMobile="auto"
                  mRMobile="auto"
                  mleftmxs="0%"
                  mleft="18%"
                  mleftm="7%"
                  width="100%"
                  widthMobile="100%"
                  mtMobile="0px"
                >
                  <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                    <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                      <Recommendations
                        items={recommendedTraining}
                      ></Recommendations>
                    </ContentHomeSection>
                  </Feed_Section>
                </Container>
              </div>
            ) : null}
            <SectionShare WO="Emergency"></SectionShare>
          </>
        ) : (
          <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
            <ImageCoachInicio />
            <Col7 padding=" " className="cardPrincipal1">
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </Fragment>
  );
};

export default ControlTooling;
