import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { LOG_SAVIG_DOLLAR } from '../../mutations';
import WOlayout from '../../components/layouts/WOlayout';
import {
  Container1,
  ContentHomeSection,
  Shapes,
  Title2,
  Subtitle,
  Rectangle24,
  Vector1,
  ResponsiveDiv,
  StoriCardContainer,
  StoriCard,
} from '../../components/common/Home.styled';
import { Container, Button } from '../../components/common/common.styled';
import { Image } from '../../assets/styles/Home.styled';
import BolsaValor from '../../assets/img/anacoach/BolsaValor.svg';
import Diamante from '../../assets/img/anacoach/Diamante.svg';
import EscudoFlecha from '../../assets/img/anacoach/EscudoFlecha.svg';
import ListItems from '../../components/Feed/One_Training';

const LpAhorroEnDolaresSuccess = () => {
  const [logSavigDollar] = useMutation(LOG_SAVIG_DOLLAR, {
    onCompleted({ logSavigDollar }) {
      setIsRegistered(true);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [isRegistered, setIsRegistered] = useState(false);
  const [nombreValue, setNombreValue] = useState('');
  const [correoValue, setCorreoValue] = useState('');
  const [telefonoValue, setTelefonoValue] = useState('');
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser && idUser !== null && idUser !== undefined && idUser !== '') {
      setIsRegistered(true);
    }
  }, []);

  const register = () => {
    const inputRegister = {
      input: {
        Q1: sessionStorage.getItem('Q1'),
        Q2: sessionStorage.getItem('Q2'),
        Q3: sessionStorage.getItem('Q3'),
        Q4: sessionStorage.getItem('Q4'),
        Q5: sessionStorage.getItem('Q5'),
        Q6: sessionStorage.getItem('Q6'),
        Q7: sessionStorage.getItem('Q7'),
        Q8: sessionStorage.getItem('Q8'),
        Q9: sessionStorage.getItem('Q9'),
        Q10: sessionStorage.getItem('Q10'),
        Q11: sessionStorage.getItem('Q11'),
        Name: nombreValue,
        Email: correoValue,
        CellPhone: telefonoValue,
      },
    };
    logSavigDollar({ variables: inputRegister });
  };

  const items = [
    {
      id: 49,
      idTrainning: 17,
      name: 'Tu primera inversión',
      parrafo1: null,
      color: '#6BD5AF',
      Liked: false,
      image:
        '\thttps://resources-new-coru.s3.us-east-2.amazonaws.com/images/routines/C%3A%5Cfakepath%5Cinversion1.svg',
      Show: true,
      time: 'Tiempo de entrenamiento: 20 minutos',
      TrainingRedirectUrl: '/wo/first-investment',
      category: 3,
    },
    {
      id: 61,
      idTrainning: 21,
      name: 'Ahorro de Emergencia ',
      parrafo1: 'pendiente',
      color: '#7FBD62',
      Liked: false,
      image:
        'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/routines/C%3A%5Cfakepath%5CAhorro_de_Emergencia.svg',
      Show: true,
      time: 'Tiempo de entrenamiento: 20 minutos',
      TrainingRedirectUrl: '/wo/emergency-saving',
      category: 3,
    },
  ];

  const validForm = (e) => {
    e.persist();
    if (e.target.name === 'name') {
      setNombreValue(e.target.value);
      ValidLetras(e.target.name, e.target.value);
    } else if (e.target.name === 'mail') {
      setCorreoValue(e.target.value);
      ValidEmail(e.target.name, e.target.value);
    } else {
      ValidPhone(e.target.name, e.target.value);
      setTelefonoValue(e.target.value.replace(/[^0-9]/i, ''));
    }
  };

  const [valName, setValName] = useState(false);
  const [valMail, setValMail] = useState(false);
  const [valTel, setValTel] = useState(false);

  const ValidPhone = (name, value, id) => {
    setTelefonoValue(value.replace(/[^0-9]/i, ''));
    const tel = document.getElementsByName(name)[0];
    if (tel === null) {
      console.log('null');
    } else if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      setValTel(false);
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      setValTel(true);
    }
  };

  const ValidEmail = (name, valueEmail) => {
    let domain = valueEmail.split('@');
    domain = domain[1];

    const idmail = document.getElementsByName(name)[0];

    if (
      valueEmail
      && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      console.log('domain 0 >> ');
      if (idmail) {
        idmail.className = 'textInput inputs-bottom invalidInput';
        setValMail(false);
      }
    } else if (
      domain !== ''
      && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      console.log('domain 1 >> ');
      if (domain === 'h') {
        console.log('domain 1.5 >> ');
      } else if (domain === 'g') {
        console.log('domain 2 >> ');
      }
      if (idmail) {
        idmail.className = 'textInput inputs-bottom invalidInput';
        setValMail(false);
      }
    } else {
      console.log('domain 3 >> ');
      if (idmail) {
        idmail.className = 'textInput inputs-bottom validInput';
        setValMail(true);
      }
    }
  };

  const ValidLetras = (name, value) => {
    setNombreValue(value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''));

    const element = document.getElementsByName(name)[0];
    if (element === null) {
      console.log('es null');
    } else if (value.length > 3) {
      element.className = 'textInput inputs-bottom validInput';
      setValName(true);
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      setValName(false);
    }
  };

  const anchop = window.screen.width;

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Container1>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="1355px"
          height="auto"
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="4rem"
            widthMobile="100%"
            mb="150px"
            mtMobile="70px"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Vector1
                positionD="absolute"
                Pleft="150px"
                Ptop="-20px"
                displayM="none"
                displayD=" "
                background="#6BD4AE"
                transform=" "
                width="70px"
                height="70px"
              />
              <Vector1
                positionD="absolute"
                Pleft="75%"
                Ptop="2.9%"
                displayM="none"
                displayD=" "
                background="#91D770"
                transform="rotate(75deg)"
                width="65px"
                height="65px"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="210px"
                Pleft="97%"
                displayD=" "
                left=" "
                top=" "
                width="65.26px"
                height="85.26px"
                background="#E2E560"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="260px"
                Pleft="88%"
                displayD=" "
                left=" "
                top=" "
                width="45.26px"
                height="45.26px"
                background="#6BD5AF"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="112px"
                Pleft="90%"
                displayD=" "
                left=" "
                top=" "
                width="65.26px"
                height="65.26px"
                background="#91D770"
                transform="rotate(-35.58deg)"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="380px"
                Pleft="95%"
                displayD=" "
                left=" "
                top=" "
                width="145.26px"
                height="135.26px"
                background="#BFDD6A"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="435px"
                Pleft="-3%"
                displayD=" "
                left=" "
                top=" "
                width="105.26px"
                height="105.26px"
                background="#91D770"
                transform="rotate(-35.58deg)"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="1142px"
                Pleft="88%"
                displayD=" "
                left=" "
                top=" "
                width="240px"
                height="240px"
                background="#91D770"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="800px"
                Pleft="90%"
                displayD=" "
                left=" "
                top=" "
                width="45.26px"
                height="45.26px"
                background="#BFDD6A"
              />
              <Vector1
                positionD="absolute"
                Pleft="90%"
                Ptop="740px"
                displayM="none"
                displayD=" "
                background="#E3E660"
                transform=" "
                width="194px"
                height="190px"
              />
              <Title2 sizeD="50px" top="45px" topD="12%" className="roboto">
                Gracias por tus respuestas
              </Title2>
              <Subtitle
                id="Subtitle"
                className="roboto"
                mtopM="10%"
                mbottomM="5%"
                width="650px"
                widthM="325px"
              >
                Aquí te mostramos algunos beneficios de invertir en dólares,
                tómalos en cuenta para tus futuras decisiones financieras
              </Subtitle>
              <StoriCardContainer width="100%">
                <StoriCard
                  mWidth="305px"
                  mHeight="348px"
                  height=" "
                  bsh="0px 2px 15px rgba(0, 0, 0, 0.25)"
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Image src={BolsaValor} width="100px" />
                    <span
                      style={{
                        fontSize: '18px',
                        color: '#07210A',
                        textAlign: 'center',
                      }}
                    >
                      El crecimiento anual del dólar contra el peso mexicano es
                      de 6.8% promedio anual.
                    </span>
                  </div>
                </StoriCard>
                <StoriCard
                  mWidth="305px"
                  mHeight="348px"
                  height=" "
                  bsh="0px 2px 15px rgba(0, 0, 0, 0.25)"
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Image src={Diamante} width="100px" />
                    <span
                      style={{
                        fontSize: '18px',
                        color: '#07210A',
                        textAlign: 'center',
                      }}
                    >
                      Reduce el riesgo de afectación ante inflación de moneda
                      nacional evitando perder hasta el 4% de tu dinero anual.
                    </span>
                  </div>
                </StoriCard>
                <StoriCard
                  mWidth="305px"
                  mHeight="348px"
                  height=" "
                  bsh="0px 2px 15px rgba(0, 0, 0, 0.25)"
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Image src={EscudoFlecha} width="100px" />
                    <span
                      style={{
                        fontSize: '18px',
                        color: '#07210A',
                        textAlign: 'center',
                      }}
                    >
                      Preservar el valor de tu patrimonio a través del tiempo
                    </span>
                  </div>
                </StoriCard>
              </StoriCardContainer>
              {isRegistered ? (
                <>
                  <Title2
                    sizeD="30px"
                    sizem="22px"
                    top="45px"
                    topD="12%"
                    className="roboto"
                  >
                    Tenemos más entrenamientos
                  </Title2>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="5%"
                    width="650px"
                    widthM="325px"
                  >
                    Recuerda mantener en forma tu músculo financiero con
                    nuestros entrenamientos.
                  </Subtitle>
                  <ListItems
                    items={items}
                    feed={false}
                    progress={true}
                    lp={true}
                  />
                </>
              ) : (
                <>
                  <Title2
                    sizeD="30px"
                    sizem="22px"
                    top="45px"
                    topD="12%"
                    className="roboto"
                  >
                    Prueba nuestro entrenamiento de ahorro en{' '}
                    {anchop >= 768 ? <br /> : null} dólares antes que nadie
                  </Title2>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="5%"
                    width="650px"
                    widthM="325px"
                  >
                    Registra estos datos y recibe una notificación cuando este
                    entrenamiento este listo.
                  </Subtitle>
                  <ResponsiveDiv
                    width="40%"
                    widthM="90%"
                    style={{ marginTop: '20px' }}
                  >
                    <TextField
                      id="standard-full-width"
                      label="Nombre"
                      name="name"
                      style={{ margin: 8 }}
                      placeholder=" "
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => validForm(e)}
                      value={nombreValue}
                    />
                    <TextField
                      id="standard-full-width"
                      name="mail"
                      label="Correo electrónico"
                      style={{ margin: 8 }}
                      placeholder=" "
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => validForm(e)}
                      value={correoValue}
                    />
                    <TextField
                      id="standard-full-width"
                      label="Telefono"
                      name="telefono"
                      style={{ margin: 8 }}
                      placeholder=" "
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => validForm(e)}
                      value={telefonoValue}
                      inputProps={{ maxLength: 10 }}
                    />
                    {valName && valMail && valTel ? (
                      <Button
                        className="roboto"
                        mb=" "
                        mt=" "
                        onClick={register}
                      >
                        Registrarme
                      </Button>
                    ) : null}
                  </ResponsiveDiv>
                </>
              )}
            </ContentHomeSection>
          </Container>
        </Shapes>
      </Container1>
    </WOlayout>
  );
};

export default LpAhorroEnDolaresSuccess;
