import React, { useState, Fragment, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  ContentButton,
  Container,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import '../../assets/styles/TextCustom.css';
import { CALL_SCRAPPER, SAVE_AUTH_OTP } from '../../mutations';
import { useMutation } from '@apollo/client';
import { getJsonAmex, getVariablesScrapper } from '../../Helpers/JsonScrappers';
import useInputValue from '../../hooks/useInputValue';
import { getItemSessionStorage } from '../../Helpers/general';
import ModalLoader from '../../components/common/ModalLoader';

function VerificationCodeNu() {
  const email = getItemSessionStorage('Email1');
  const ladaUser = getItemSessionStorage('ladauser');
  const ladaRecorte = ladaUser.split(' ');
  const lada = ladaRecorte[ladaRecorte.length - 1];
  const phone = getItemSessionStorage('CellPhone');
  const history = useHistory();
  const code = useInputValue('');
  const validateCode = useInputValue('');
  const [scrapperIntent, setScrapperIntent] = useState(0);
  const [feedback, setFeedBack] = useState('');
  const [dataFail, setDataFail] = useState('');
  const [loading, setLoading] = useState(false);
  const MAX_INTENT = 3;

  const [callScrapper, { data: dataScrapper }] = useMutation(CALL_SCRAPPER);
  const [saveAuthOtp, { data: dataOTP }] = useMutation(SAVE_AUTH_OTP);
  const jsonAmex = getJsonAmex('NU');
  const encodeString = btoa(JSON.stringify(jsonAmex));
  const config = {
    variables: getVariablesScrapper({ encodeString, bank: 'NU' }),
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (code.value !== validateCode.value) return;
    if (scrapperIntent < MAX_INTENT) {
      setLoading(true);
      if (loading) return;
      const input = { email, token: code.value, partner: 'NU' };
      return saveAuthOtp({ variables: { input } });
    }
  };

  if (!phone || !ladaUser) {
    history.replace('/contact-nu');
  }

  useEffect(() => {
    const scrapper = callScrapper(config);
    return () => clearTimeout(scrapper);
  }, []);
  useEffect(() => console.log(dataOTP), [dataOTP]);
  useEffect(() => {
    let timerFeedback; let
      timerScrapper;
    if (dataScrapper) {
      setLoading(false);
      setScrapperIntent(scrapperIntent + 1);
      const { response } = dataScrapper.callScrapper;
      switch (response) {
        case 'Nip Erroneo':
          if (scrapperIntent < MAX_INTENT) {
            timerScrapper = callScrapper(config);
            setFeedBack(`${response}, te reenviamos el código`);
            timerFeedback = setTimeout(() => setFeedBack(''), 5000);
          }
          history.replace('/success-nu?error="MaxIntents"');
          break;
        case 'Nip no encontrado':
          if (scrapperIntent + 1 < MAX_INTENT) {
            timerScrapper = callScrapper(config);
            setFeedBack(`${response}, te reenviamos el código`);
            timerFeedback = setTimeout(() => setFeedBack(''), 5000);
          }
          history.replace('/success-nu?error="MaxIntents"');
          break;
        case 'Rechazada':
          history.replace(`/success-nu?error=${response}`);
          break;
        case 'Aprobada':
          history.replace('/success-nu');
          break;
        case 'ErrorDesconocido':
          history.replace(`/success-nu?error=${response}`);
          break;
        default:
          setDataFail(
            'Error de datos, te invitamos a que verifiques tus datos.',
          );
      }
    }
    return () => {
      clearTimeout(timerScrapper);
      clearTimeout(timerFeedback);
    };
  }, [dataScrapper]);

  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="60%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="40px"
              sizeMobile="20px"
              textalingn="center"
              textalingnMobile="center"
            >
              ¡Super! Un paso más
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#000000"
              textalignMobile="center"
              texttalign="center"
            >
              Escribe el número de solicitud (NIP) que te enviamos por mensaje de texto o a tu correo electrónico.
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#000000"
              textalignMobile="center"
              texttalign="center"
            >
              Al ingresar el número de solicitud (NIP) que te enviamos,
              autorizas la{' '}
              <a
                style={{ cursor: 'pointer' }}
                href="https://www.nu.com.mx/consulta-historial-crediticio/"
                target="_blank" rel="noreferrer"
              >
                <span style={{ color: '#820AD1' }}>
                  Consulta de tu Historial crediticio
                </span>
              </a>{' '}
              a favor de NU BN Servicios México S.A de C.V
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
              onSubmit={handleSubmit}
            >
              <TextField
                variant="outlined"
                id="code"
                name="code"
                autoFocus
                required
                label="Escribe o pega el número"
                type="tel"
                value={code.value}
                onChange={code.onChange}
                inputProps={{
                  type: 'tel',
                  maxLength: 6,
                }}
                className="registro"
                style={{ width: '70%', margin: 'auto' }}
              />
              <TextField
                variant="outlined"
                id="codeV"
                name="codeV"
                autoFocus
                required
                label="Confirma el número"
                type="tel"
                value={validateCode.value}
                onChange={validateCode.onChange}
                inputProps={{
                  type: 'tel',
                  maxLength: 6,
                }}
                className="registro"
                style={{ width: '70%', margin: 'auto' }}
              />

              <ContentButton mt="0" width="70%" mtMobile="0%">
                <ParagrapTwo
                  className="mx-auto roboto"
                  mb="3%"
                  mt="5%"
                  mbweb="3%"
                  fontWeight="normal"
                  fontSize="16px"
                  fontSizeWeb="18px"
                  color="#000000"
                  textalignMobile="left"
                  texttalign="left"
                  cursor="pointer"
                >
                  Enviar de nuevo el número →
                </ParagrapTwo>
                {feedback && <p style={{ color: 'red' }}>{feedback}</p>}
                {dataFail && <p style={{ color: 'red' }}>{dataFail}</p>}
                {loading && (
                  <p>Estamos procesando su solicitud. Espere un momento...</p>
                )}
                <Button
                  href=""
                  className="d-block mt-md-4 roboto"
                  mb="1.2rem"
                  mt="0rem"
                  bg="#820AD1"
                  disabled={loading}
                >
                  Continuar
                </Button>
              </ContentButton>
            </form>
          </Col11>
        </Container>
        {loading && <ModalLoader />}
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
}

export default VerificationCodeNu;
