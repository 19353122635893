import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { GENERATE_SHORTENED_LINK, SEND_NOTIFICATION } from '../../mutations';

const SendNotificationValueOfMoney = () => {
  const [trigger, setTrigger] = useState('');
  const [inputSendNotification] = useState({
    input: {
      type: 'wamult',
      properties: {
        trigger: '',
        telephone: '',
        subject: '',
        template: '',
        variables: [
          {
            setkey: '',
            setval: '',
          },
          {
            setkey: '',
            setval: '',
          },
        ],
        source: {},
        destination: {},
      },
    },
  });

  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted({ generateShortendLink }) {
      if (generateShortendLink.message === 'Exito') {
        const urlShortened = generateShortendLink.response; debugger;
        if (trigger === 'enviar_tareas_hijo' || trigger === 'recoleccion_recompesas_hijo') {
          inputSendNotification.input.properties.variables[1].setval = urlShortened;
        } else if (trigger === 'hijo_tareas_avance') {
          inputSendNotification.input.properties.variables[2].setval = urlShortened;
        }
        console.log('response == ', inputSendNotification);
        sendNotification({ variables: inputSendNotification });
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [sendNotification] = useMutation(SEND_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted({ sendNotification }) {
      if (sendNotification.statusCode === 200) {
        const send = sendNotification.response;
        console.log('response == SEND ==== ', send);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const sendMessage = ({
    url, cellPhone, trigger, variables, nameChildren, nameFather,
  }) => {
    setTrigger(trigger);
    let inputShortened = {};
    switch (trigger) {
      case 'enviar_tareas_hijo':
        inputSendNotification.input = {
          type: 'wamult',
          properties: {
            trigger,
            telephone: cellPhone,
            subject: '',
            template: '',
            variables: [
              {
                setkey: variables.varNameChildren,
                setval: nameChildren,
              },
              {
                setkey: variables.varUrlWO,
                setval: '',
              },
            ],
            source: {},
            destination: {},
          },
        };
        inputShortened = {
          input: {
            url,
            telefono: cellPhone,
            mensaje: '',
            idGen: 'Valor del dinero',
          },
        };
        generateShortendLink({ variables: inputShortened });
        break;
      case 'hijo_confirmacion_tareas':
        inputSendNotification.input = {
          type: 'wamult',
          properties: {
            trigger,
            telephone: cellPhone,
            subject: '',
            template: '',
            variables: [
              {
                setkey: variables.varNameChildren,
                setval: nameChildren,
              },
              {
                setkey: variables.varFatherName,
                setval: nameFather,
              },
            ],
            source: {},
            destination: {},
          },
        };

        sendNotification({ variables: inputSendNotification });
        break;
      case 'hijo_tareas_avance':
        inputSendNotification.input = {
          type: 'wamult',
          properties: {
            trigger,
            telephone: cellPhone,
            subject: '',
            template: '',
            variables: [
              {
                setkey: variables.varNameChildren,
                setval: nameChildren,
              },
              {
                setkey: variables.varFatherName,
                setval: nameFather,
              },
              {
                setkey: variables.varUrlWO,
                setval: '',
              },
            ],
            source: {},
            destination: {},
          },
        };
        inputShortened = {
          input: {
            url,
            telefono: cellPhone,
            mensaje: '',
            idGen: 'Valor del dinero',
          },
        };
        generateShortendLink({ variables: inputShortened });
        break;
      case 'recoleccion_recompesas_hijo':
        inputSendNotification.input = {
          type: 'wamult',
          properties: {
            trigger,
            telephone: cellPhone,
            subject: '',
            template: '',
            variables: [
              {
                setkey: variables.varNameChildren,
                setval: nameChildren,
              },
              {
                setkey: variables.varUrlWO,
                setval: '',
              },
            ],
            source: {},
            destination: {},
          },
        };
        inputShortened = {
          input: {
            url,
            telefono: cellPhone,
            mensaje: '',
            idGen: 'Valor del dinero',
          },
        };
        generateShortendLink({ variables: inputShortened });
        break;
      default:
        break;
    }
  };

  return { sendMessage };
};

export default SendNotificationValueOfMoney;
