import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { DYNAMIC_GET } from '../../../../mutations';
import CustomStepLayout from '../../layouts/CustomStepLayout';
import PersonalDataViewer from '../info/PersonalDataViewer';

const BirthData = ({
  customColor,
  coachImg,
  stepData,
  type,
  nextHandler,
  handleNextParam,
  appearAnimation,
  disappearAnimation,
  isActive,
  canGoBack,
  backText,
  backHandler,
  backHandlerOption,
}) => {
  const stepLayoutProps = {
    customColor,
    coachImg,
    type,
    isActive,
    appearAnimation,
    disappearAnimation,
    backText,
    backHandlerOption,
    backHandler,
    canGoBack,
  };

  const getDate = (opc) => {
    let date = sessionStorage.getItem('temp-BirthDate')
      || sessionStorage.getItem('BirthDate')
      || null;
    if (!date) return 0;

    date = new Date(date);

    switch (opc) {
      case 'day':
        return date.getDate();

      case 'month':
        return date.getMonth() + 1;

      case 'year':
        return date.getFullYear();

      default:
        break;
    }
  };

  const [data, setData] = useState({
    day: getDate('day'),
    month: getDate('month'),
    year: getDate('year'),
    ZipCode:
      sessionStorage.getItem('temp-ZipCode')
      || sessionStorage.getItem('ZipCode')
      || '',
  });

  const [showFinalCard, setShowFinalCard] = useState(false);

  const [buttonActive, setButtonActive] = useState(false);

  const [dynamicGet] = useMutation(DYNAMIC_GET, {
    onCompleted: (dynamicData) => {
      console.log('dynamicData >> ', dynamicData);

      console.log('dynamicData2 >> ', dynamicData);

      dynamicData = JSON.parse(dynamicData.dynamicGet.response)[0];

      let tempData = {};

      if (
        !sessionStorage.getItem('temp-BirthDate')
        && !sessionStorage.getItem('BirthDate')
        && dynamicData
        && dynamicData.BirthDate
      ) {
        const date = new Date(dynamicData.BirthDate);

        tempData = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
      }

      tempData = {
        ...tempData,
        ZipCode:
          sessionStorage.getItem('temp-ZipCode')
          || sessionStorage.getItem('ZipCode')
          || (dynamicData ? dynamicData.ZipCode || '' : ''),
      };

      setData({
        ...data,
        ...tempData,
      });
    },
  });

  useEffect(() => {
    const dynamicInput = {
      table:
        'CatUsers as cu INNER JOIN CatContact as cc ON cu.CatContact_IDCont = cc.IDCont INNER JOIN CatAddress as ca ON cc.IDCont = ca.CatContact_IDCont ',
      columns: ['cc.BirthDate', 'ca.ZipCode'],
      conditions: [
        {
          valone: 'cu.IDUser',
          condition: '=',
          valtwo: sessionStorage.getItem('idUser') || '0',
        },
        {
          logic: '',
          valone: '',
          condition: '',
          valtwo: '',
          validColumn: false,
        },
      ],
    };

    dynamicGet({
      variables: {
        input: dynamicInput,
      },
    });
  }, []);

  /* Default info data to show. This data can be replaced with prop 'stepData' */
  const defaultContent = {
    title: '¡Excelente! Ahora cuéntame sobre ti.',
    description: '',
    instructions: 'Ingresa tu fecha de nacimiento',
  };

  /* Data state handler on input change */
  const setDataChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  /* Data listener to activate or deactivate button */
  useEffect(() => {
    setButtonActive(validateFields());
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    /*
            Save temporal data on sessionStorage
        */

    const date = `${data.year}-${parseInt(data.month)}-${data.day}`;
    sessionStorage.setItem('temp-BirthDate', date);
    sessionStorage.setItem('temp-ZipCode', data.ZipCode);

    setShowFinalCard(true);
  };

  /* Function to validate required fields */
  const validateFields = () => {
    if (
      data.day > 0
      && data.month > 0
      && data.year > 0
      && data.ZipCode.trim().length > 0
    ) return true;

    return false;
  };

  /* Data arrays to fill selects */
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  /* Functions to return select options */
  const getDays = () => days.map((day) => (
      <option value={day} key={`day-opt-${day}`} selected={day === data.day}>
        {day}
      </option>
  ));

  const getMonths = () => months.map((month) => (
      <option
        value={month}
        key={`month-opt-${month}`}
        selected={month === data.month}
      >
        {month}
      </option>
  ));

  const getYears = () => {
    const years = [];
    for (let i = 1900; i < 2006; i++) {
      years.push(i);
    }

    return years.map((year) => (
      <option
        value={year}
        key={`year-opt-${year}`}
        selected={year === data.year}
      >
        {year}
      </option>
    ));
  };

  /* Function to get text */
  const getText = (name) => (
    /*
            If text parameter is set in props, use it
            If text parameter is not set in props, then use default text parameter
            If text parameter is set in props but it is an empty string, then remove margin from div so as not to ocuppy space
        */
    <div
      className={`${name} ${
        stepData && stepData[name] && stepData[name].trim() === ''
          ? 'no-margin'
          : ''
      }`}
    >
      {stepData ? stepData[name] || defaultContent[name] : defaultContent[name]}
    </div>
  );

  if (showFinalCard) {
    return (
      <PersonalDataViewer
        customColor={customColor}
        type={type}
        isActive={isActive}
        nextHandler={nextHandler}
        handleNextParam={handleNextParam}
        backHandler={backHandler}
        appearAnimation={appearAnimation}
        disappearAnimation={disappearAnimation}
      />
    );
  }

  return (
    <CustomStepLayout {...stepLayoutProps} stepClass="step-static-birth-data">
      <div className="text-content">
        {getText('title')}

        {getText('description')}
      </div>

      <form onSubmit={handleSubmit}>
        {getText('instructions')}

        <div className="input-row">
          <select name="day" onChange={setDataChange}>
            <option value="0" hidden>
              Día
            </option>
            {getDays()}
          </select>
          <select name="month" onChange={setDataChange}>
            <option value="0" hidden>
              Mes
            </option>
            {getMonths()}
          </select>
        </div>

        <div className="input-row">
          <select name="year" onChange={setDataChange}>
            <option value="0" hidden>
              Año
            </option>
            {getYears()}
          </select>
        </div>

        <div className="input-row">
          <input
            type="text"
            name="ZipCode"
            placeholder="Código postal"
            onChange={setDataChange}
            value={data.ZipCode}
            required
          />
        </div>

        <div className="button-container">
          <button
            className={`custom-text-button ${buttonActive ? 'active' : ''}`}
          >
            Siguiente {'>'}{' '}
          </button>
        </div>
      </form>
    </CustomStepLayout>
  );
};

export default BirthData;
