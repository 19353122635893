import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  width: calc(100% - 40px);
  max-width: 338px;
  height: auto;
  background: #ffffff;
  border-radius: 16px;
`;

const Title = styled.p`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 15px;
`;

const Text = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: -0.3px;
  color: ${(props) => props.inputColor || "#252525"};
  margin-bottom: 15px;
`;

const Input = styled.input`
  height: 50px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 16px;
  height: 50px;
  width: 100%;
  padding: 18px 20px;
`;
const InputTel = styled.input`
  height: 50px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 16px;
  height: 50px;
  width: 100%;
  padding: 18px 20px;
`;

const ValidateButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 48px;
  background: ${(props) => props.inputColor || "#f64282"};
  border: 1px solid #f64282;
  border-radius: 16px;
  margin-bottom: 15px;

  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%%;
  letter-spacing: -0.2px;
  color: ${(props) => props.inputColor || "#FFFFFF"};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const AuxText = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #d40014;
`;

const AuxTextTel = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #d40014;
`;

const Option = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.3px;
  text-decoration-line: underline;
  color: #f64282;
  cursor: pointer;
`;

function ModalContent({ close, setOrder }) {
  const [valueLength, setValueLength] = useState(0);
  const [changeTel, setChangeTel] = useState(false);

  //Validor on change -> valida la longitud
  const lengthValidation = () => {
    let name = document.getElementById("myTextBox");
    setValueLength(name.value.length);
    name.addEventListener("keyup", () => {
      if (name.value.length > 4) {
        name.value = name.value.slice(0, 4);
      }
    });
  };

  const telLengthValidation = () => {
    let name = document.getElementById("myTextTelBox");
    setValueLength(name.value.length);
    name.addEventListener("keyup", () => {
      if (name.value.length > 10) {
        name.value = name.value.slice(0, 10);
      }
    });
  };

  //Validador onClick -> valida que sea un numero
  const validation = () => {
    let n = document.getElementById("myTextBox").value;
    if (isNaN(n)) {
      // Validación provicional: isNaN
      document.getElementById("numberText").innerHTML = "Código incorrecto";
      return false;
    } else {
      document.getElementById("numberText").innerHTML = "";
      close(false);
      setOrder();
      // Prueba ----
      return true; // llamada a succes (?)
    }
  };

  const telValidation = () => {
    let n = document.getElementById("myTextTelBox").value;
    if (isNaN(n)) {
      document.getElementById("telText").innerHTML = "Número incorrecto";
      return false;
    } else {
      document.getElementById("telText").innerHTML = "";
      setChangeTel(false);

      return true; //llamada a succes (?)
    }
  };

  return (
    <Container>
      <Title>Código Creditea</Title>
      {changeTel ? (
        <>
          <Text inputColor={"#A6A6A6"}>
            Ingresa el número celular al que deseas reenviar el código de
            Creditea.
          </Text>
          <Title>Teléfono celular</Title>
          <InputTel
            maxLength="10"
            onChange={() => {
              telLengthValidation();
              console.log("change");
            }}
            type="text"
            placeholder="(55) 12345678"
            id="myTextTelBox"
          ></InputTel>
          <AuxTextTel id="telText"></AuxTextTel>
          {valueLength == 10 ? ( //Si el codigo son 10 digitos, activa el boton. Pasa por validación
            <ValidateButton
              style={{}}
              onClick={() => {
                telValidation();
                console.log("click");
              }}
            >
              Enviar código
            </ValidateButton>
          ) : (
            <ValidateButton
              onClick={() => {
                document.getElementById("telText").innerHTML =
                  "Número incorrecto";
              }}
            >
              Enviar código
            </ValidateButton>
          )}
        </>
      ) : (
        <>
          <Text inputColor={"#A6A6A6"}>
            Hemos enviado un código por medio de SMS al número celular que
            registraste.
          </Text>
          <Text>Ingresa el código de 4 dígitos para continuar.</Text>
          <Input
            maxLength="4"
            onChange={() => lengthValidation()}
            type="text"
            placeholder="Ingresa el código"
            id="myTextBox"
          ></Input>
          <AuxText id="numberText"></AuxText>
          {valueLength == 4 ? ( //Si el codigo son cuatro digitos, activa el boton. Pasa por validación
            <ValidateButton
              onClick={() => {
                validation();
                console.log("click");
              }}
            >
              Validar código
            </ValidateButton>
          ) : (
            <ValidateButton
              onClick={() =>
                (document.getElementById("numberText").innerHTML =
                  "Código incorrecto")
              }
            >
              Validar código
            </ValidateButton>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Option onClick={() => setChangeTel(true)}>Modificar número</Option>
            <Option>Reenviar código</Option>
          </div>
        </>
      )}
    </Container>
  );
}

export default ModalContent;
