import React, { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import { Button2 } from '../Assets/Styles/CaasNew.styles';
import CloseIcon from "@material-ui/icons/Close";
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import dayjs from 'dayjs';

const timeInterval = ['12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];

const CalendarTime = ({
  openModal,
  onSetOpenModal,
  onSelectDateTime,
  appointments = [],
  onSetSelectedTime,
  selectedTime,
}) => {
    const buttonDisabled = !selectedTime

    const [selectedDate, setSelectedDate] = useState(null)

  const disableWeekends = (timeValue, clockType) => {
    if (moment(timeValue.$d).format('YYYY-MM-DD HH:mm') < moment().format('YYYY-MM-DD HH:mm') ||
      appointments.includes(moment(timeValue.$d).format('YYYY-MM-DD HH:mm'))) {
      return true;
    }
  };

  const handleDisableDate = (timeValue) => {
    if (
      moment(timeValue.$d).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ||
      moment(timeValue.$d).day() === 0 ||
      moment(timeValue.$d).day() === 6
    ) {
      return true;
    }
  };

  const handleChangeDateTime = (e) => {
    setSelectedDate(e);
  };

  useEffect(() => {
    if (selectedDate !== null) {
      onSelectDateTime(selectedDate);
    }
  }, [selectedDate])
  
  return (
    <Dialog open={openModal}>
      <Box>
      <Box padding="20px 20px 0 20px" display="flex" justifyContent="space-between">
        <Box>
          <Typography fontSize="16px" color="#393A80" fontWeight="600">Agenda tu Demo</Typography>
          <Typography fontSize="14px" color="#A6A6A6">Duración de llamada: 30 minutos</Typography>
        </Box>
      <CloseIcon
            htmlColor="#393A80"
            style={{ width: "20px", height: "22px" }}
            onClick={() => onSetOpenModal(false)}
        />
      </Box>
      <Box display="flex">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          disablePast
          shouldDisableDate={handleDisableDate}
          defaultValue={dayjs('2022-04-17T15:30')}
          value={selectedDate}
          onChange={(e) => handleChangeDateTime(e)}
        />
      </LocalizationProvider>
      <Box
        marginTop="20px"
        marginBottom="10px"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        height={290}
        width={110}
        sx={{
          overflowY: 'scroll',
          borderLeft: '1px solid #E9E9E9',
          paddingLeft: '12px',
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#F64282B2',
            borderRadius: '16px',
          },
        }}
      >
        <Box>
          <Box>
            <Typography fontSize="14px" fontWeight='500' color="#393A80" textAlign="center">Hora</Typography>
          </Box>
          <Box marginTop="12px" gap="10px" display="flex" flexDirection="column">
            {timeInterval.map((time) => (
              <Box
                key={time}
                padding="4px 16px"
                sx={{ borderWidth: '1px', borderStyle: 'solid', borderRadius: '16px', cursor: 'pointer'}}
                borderColor={time === selectedTime ? '#393A80' : '#E9E9E9'}
                height="fit-content"
                onClick={() => onSetSelectedTime(time)}
              >
                <Typography fontSize="14px" color="#595959">{time}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" padding="0 20px 20px 20px">
        <Button2
          width="93px"
          height="32px"
          borderadius="8px"
          disabled={buttonDisabled}
          onClick={() => onSetOpenModal(false)}
        >
          Agendar
        </Button2>
      </Box>
      </Box>
    </Dialog>
  )
}

export default CalendarTime