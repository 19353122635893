import React, { useState, useEffect } from 'react';
import { Polar } from 'react-chartjs-2';

const DynamicChart = () => {
  const [chartData, setChartData] = useState({});

  const Chart = () => {
    setChartData({
      labels: ['Score 850', 'Score 700', 'Score 600', 'Score 500', 'Score 400'],
      datasets: [{
        label: 'Score',
        data: [5, 4, 3, 2, 1],
        backgroundColor: [
          '#6BD4AE',
          '#91D770',
          '#FFD363',
          '#FFA563',
          '#FF8C8C',
        ],
        borderColor: [
          '#6BD4AE',
          '#91D770',
          '#FFD363',
          '#FFA563',
          '#FF8C8C',
        ],
        borderWidth: 1,
      }],
    });
  };
  useEffect(() => {
    Chart();
  }, []);

  return (
    <Polar
      width="600" height="400"
      data={chartData}
      options={{
        legend: {
          display: false,
        },
        responsive: true,
        title: { text: 'SCORE CREDITICIO', display: false },
        scale: {
          display: false,
        },
      }}/>
  );
};

export default DynamicChart;
