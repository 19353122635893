import React, { useState, Fragment } from 'react';
import {
  TrainingDivSection,
  CardsBtn,
  TrainingSubtitles,
  TrainingText,
} from '../../assets/styles/Training.styled';
import Val from '../../assets/img/anacoach/Val.png';
import { Container, Col7 } from '../../components/common/common.styled';

const FirstInvestmentCalInV2 = ({
  text,
  text2,
  text3,
  text4,
  text5,
  subtitle,
  onClick,
}) => {
  const [calcular, setCalcular] = useState(false);
  const calcularInversion = () => {
    onClick(true);
    setCalcular(true);
  };
  return (
    <>
      <Container
        width="80%"
        mt="0"
        widthMobile="90%"
        mtMobile="0"
        mleft="122px"
      >
        <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
          <TrainingDivSection
            hew="auto"
            className="topOne1 topOne1Init"
            paddw="26px"
            mlw="0%"
            mtw="8%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="100%"
            he="auto"
          >
            <TrainingText
              size="14px"
              className="roboto"
              bot="30px"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              {text}
              <br />
              <br />
              {text2}
            </TrainingText>
            <div style={{ margin: '0px' }}>
              <ul>
                <li style={{ listStyleType: 'none' }}>
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '20px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />{' '}
                  {text3}
                </li>
                <br />
                <li style={{ listStyleType: 'none' }}>
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '9px',
                      marginRight: '20px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />{' '}
                  {text4}{' '}
                </li>
                <br />
                <li
                  style={{
                    display: 'flex',
                    listStyleType: 'none',
                    alingText: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '15.85px',
                      height: '11.5px',
                      marginTop: '22px',
                      marginRight: '20px',
                    }}
                    src={Val}
                    alt="Validacion polomita"
                  />
                  <p
                    style={{
                      marginTop: '9px',
                      marginLeft: '4px',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    {text5}
                  </p>
                </li>
              </ul>
            </div>

            <TrainingSubtitles
              size="12px"
              fw="600"
              align="left"
              alignweb="left"
              className="roboto"
            >
              {subtitle}
            </TrainingSubtitles>

            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              {calcular ? (
                ''
              ) : (
                <CardsBtn
                  type="button"
                  windthG="98%"
                  mtop="0px"
                  mtopw="0px"
                  onClick={() => calcularInversion()}
                >
                  Calcular inversión
                </CardsBtn>
              )}
            </div>
          </TrainingDivSection>
        </Col7>
      </Container>
    </>
  );
};

export default FirstInvestmentCalInV2;
