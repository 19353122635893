/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import { Container, Col7 } from '../../components/common/common.styled';
import {
  TrainingQuestion,
  QuestionsFlex,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
// recovery
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_PROFILE_INVESTMENT_TEST,
} from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

const InvestmentQuestionnaire = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  setSumas,
  setProfile,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [countRecovery] = useState(0);
  const [idResp, setIdResp] = useState([]);

  const [investmentListStatus, setInvestmentListStatus] = useState({
    551: false,
    552: false,
    553: false,
    554: false,
    555: false,
    556: false,
  });

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            console.log('caracteristicas', routines);
            let contResponse = 0;
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        idResp.push(detailQuestion[x].IDCatQuestionsDetail);
                        sessionStorage.setItem(
                          'auxRecoveryIdCatQuestionDetail',
                          idResp,
                        );

                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                        contResponse++;
                      }
                    }
                  } else if (questionType === 'Multiple') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    const responsesUser = responseUser.split(',');
                    for (let x = 0; x < detailQuestion.length; x++) {
                      for (let m = 0; m < responsesUser.length; m++) {
                        if (
                          detailQuestion[x].FieldOption === responsesUser[m]
                        ) {
                          idResp.push(detailQuestion[x].IDCatQuestionsDetail);
                          sessionStorage.setItem(
                            'auxRecoveryIdCatQuestionDetail',
                            idResp,
                          );

                          sessionStorage.setItem(
                            arraySessionsRutines[idTypeQuestion],
                            responseUser,
                          );
                          contResponse++;
                        }
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                  console.log('video ', contVideos);
                }
              }

              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                console.log('rutina actual: ', orderRutine);
                console.log(
                  'questions: ',
                  routines[idRoutine].questions.length,
                  'responses: ',
                  arrayResponseRutine.length,
                );
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                console.log('detente');
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                break;
              } else {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                categoryProfile();
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getClasificationProfile] = useLazyQuery(GET_PROFILE_INVESTMENT_TEST, {
    onCompleted({ getClasificationProfile }) {
      if (getClasificationProfile.message === 'success') {
        const resp = getClasificationProfile.response.split('___');
        const resProfile = JSON.parse(resp[0]);
        const sumas = resp[1].split(',');
        console.log('Respuesta de perfil === ', resp[0]);
        setProfile(resProfile);
        sessionStorage.setItem('profileTest', JSON.stringify(resProfile));
        sessionStorage.setItem('suma_1', sumas[0]);
        sessionStorage.setItem('suma_2', sumas[1]);
        sessionStorage.setItem('suma_3', sumas[2]);
        setSumas({
          suma_1: parseInt(sumas[0]),
          suma_2: parseInt(sumas[1]),
          suma_3: parseInt(sumas[2]),
        });
        setOrderRutine(nextOrderRoutine);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    console.log('Entro useEffect AHORRO PRESUPUESTO');
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
      && sessionStorage.getItem('idUser') !== '0'
      && sessionStorage.getItem('idCont') !== '0'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => {
    console.log('Rutine====', data);
    setRutine(data);
  };
  const [useInvestmentMoney, setUseInvestmentMoney] = useState(
    sessionStorage.getItem('ComoUsarDinero') || '',
  );
  const [preferMoney, setPreferMoney] = useState(
    sessionStorage.getItem('PreferenciasTiempoDinero') || '',
  );
  const [makeMoneyInvestment, setMakeMoneyInvestment] = useState(
    sessionStorage.getItem('HacerDineroInversión') || '',
  );
  const [emergencySaving, setEmergencySaving] = useState(
    sessionStorage.getItem('AhorrosEmergencias') || '',
  );
  const [currentInvestment, setCurrentInvestment] = useState(
    sessionStorage.getItem('ConocimientoActualInversiones') || '',
  );
  const [riskCapital, setRiskCapital] = useState(
    sessionStorage.getItem('ArriesgarCapital') || '',
  );
  const [investmentHopes, setInvestmentHopes] = useState(
    sessionStorage.getItem('EsperanzasInversión') || '',
  );
  const [investmentOpt, setInvestmentOpt] = useState([]);
  const [countOpt, setCountOpt] = useState(0);

  const [form, setForm] = useState({});

  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value, type);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const categoryProfile = () => {
    console.log(JSON.stringify(idResp));
    sessionStorage.removeItem('query');
    const query = JSON.stringify(idResp);
    sessionStorage.setItem('query', query);
    getClasificationProfile({
      variables: {
        idQuestionDetail: query || sessionStorage.getItem('query'),
      },
    });
  };
  const investmentOptDataChangeHandler = (opc, val) => {
    if (!investmentListStatus[opc]) {
      setCountOpt(countOpt + 1);
      setInvestmentListStatus({
        ...investmentListStatus,
        [opc]: true,
      });
      setInvestmentOpt(investmentOpt.concat(val.value));
      setIdResp(idResp.concat(val.IDCatQuestionsDetail));
      sessionStorage.setItem(
        'auxRecoveryIdCatQuestionDetail',
        idResp.concat(val.IDCatQuestionsDetail),
      );
    } else {
      setCountOpt(countOpt - 1);
      setInvestmentListStatus({
        ...investmentListStatus,
        [opc]: false,
      });
      let cleanArray = investmentOpt.filter((item) => item !== val.value);
      setInvestmentOpt(cleanArray);
      cleanArray = idResp.filter((item) => item !== val.IDCatQuestionsDetail);

      setIdResp(cleanArray);
      let itemStorage = sessionStorage.getItem(
        'auxRecoveryIdCatQuestionDetail',
      );
      itemStorage = itemStorage.replace(`${val.IDCatQuestionsDetail},`, '');
      itemStorage = itemStorage.replace(`${val.IDCatQuestionsDetail}`, '');
      sessionStorage.setItem('auxRecoveryIdCatQuestionDetail', itemStorage);

      const element = document.getElementsByName(val.value)[0];
      element.classList.remove('selectedBen');
    }
  };

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  useEffect(() => {
    console.log('length investmentOpt: ', investmentOpt.length);
    console.log('investmentOpt: ', investmentOpt);
    console.log('Rutine: ', isEmptyObject(Rutine));
    let element;
    if (investmentOpt.includes('Invertir en CETES')) {
      element = document.getElementsByName('Invertir en CETES')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.includes('Financiar emprendimientos')) {
      element = document.getElementsByName('Financiar emprendimientos')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.includes('Plata y oro')) {
      element = document.getElementsByName('Plata y oro')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.includes('Facebook y Amazon')) {
      element = document.getElementsByName('Facebook y Amazon')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.includes('Divisas')) {
      element = document.getElementsByName('Divisas')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.includes('Bitcoins')) {
      element = document.getElementsByName('Bitcoins')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.includes('Criptomonedas')) {
      element = document.getElementsByName('Criptomonedas')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (investmentOpt.length && !sessionStorage.getItem('OpcionesInvertir')) {
      for (let b = 0; b <= investmentOpt.length; b++) {
        switch (investmentOpt[b]) {
          case 'Invertir en CETES':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Financiar emprendimientos':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Plata y oro':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Facebook y Amazon':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Divisas':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Bitcoins':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Criptomonedas':
            element = document.getElementsByName(investmentOpt[b])[0];
            element.classList.add('selectedBen');
            break;
          default:
            break;
        }
      }
    }

    if (investmentOpt.length === 3 && !isEmptyObject(Rutine)) {
      const optionsSelected = `${investmentOpt[0]},${investmentOpt[1]},${investmentOpt[2]}`;
      questions.push({
        id: 'investmentOpt',
        question: 8,
        value: optionsSelected,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      categoryProfile();
    }
  }, [investmentOpt]);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setUseInvestmentMoney(sessionStorage.getItem('ComoUsarDinero') || '');
      setPreferMoney(sessionStorage.getItem('PreferenciasTiempoDinero') || '');
      setMakeMoneyInvestment(
        sessionStorage.getItem('HacerDineroInversión') || '',
      );
      setEmergencySaving(sessionStorage.getItem('AhorrosEmergencias') || '');
      setCurrentInvestment(
        sessionStorage.getItem('ConocimientoActualInversiones') || '',
      );
      setRiskCapital(sessionStorage.getItem('ArriesgarCapital') || '');
      setInvestmentHopes(sessionStorage.getItem('EsperanzasInversión') || '');
      setInvestmentOpt(
        sessionStorage.getItem('OpcionesInvertir')
          ? sessionStorage.getItem('OpcionesInvertir').split(',')
          : [],
      );
    }
  }, [recoveryRoutine]);

  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [edituseInvestmentMoney, setedituseInvestmentMoney] = useState(
    useInvestmentMoney !== '',
  );
  const [editpreferMoney, seteditpreferMoney] = useState(
    preferMoney !== '',
  );
  const [editmakeMoneyInvestment, seteditmakeMoneyInvestment] = useState(
    makeMoneyInvestment !== '',
  );
  const [editcurrentInvestment, seteditcurrentInvestment] = useState(
    currentInvestment !== '',
  );
  const [editemergencySaving, seteditemergencySaving] = useState(
    emergencySaving !== '',
  );
  const [editriskCapital, seteditriskCapital] = useState(
    riskCapital !== '',
  );
  const [editinvestmentHopes, seteditinvestmentHopes] = useState(
    investmentHopes !== '',
  );
  const [editcountOpt, seteditcountOpt] = useState(
    countOpt === 3,
  );
  const handleChangeIcon = (json) => {
    // setEditDP(false);
    setLapiz([json]);
    switch (json.active) {
      case 1:
        setedituseInvestmentMoney(false);
        break;
      case 2:
        seteditpreferMoney(false);
        break;
      case 3:
        seteditmakeMoneyInvestment(false);
        break;
      case 4:
        seteditemergencySaving(false);
        break;
      case 5:
        seteditcurrentInvestment(false);
        break;
      case 6:
        seteditriskCapital(false);
        break;
      case 7:
        seteditinvestmentHopes(false);
        break;
      case 8:
        seteditcountOpt(false);
        break;
      default:
        break;
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption====', val);
    switch (val.step) {
      case 0:
        const idEdad = parseInt(sessionStorage.getItem('auxIdCatQuestionDetail'));
        idResp.push(idEdad);
        setUseInvestmentMoney(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        setedituseInvestmentMoney(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire2' });
        break;
      case 1:
        setPreferMoney(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        seteditpreferMoney(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire3' });
        break;
      case 2:
        setMakeMoneyInvestment(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        seteditmakeMoneyInvestment(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire4' });
        break;
      case 3:
        setEmergencySaving(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        seteditemergencySaving(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire5' });
        break;
      case 4:
        setCurrentInvestment(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        seteditcurrentInvestment(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire6' });
        break;
      case 5:
        setRiskCapital(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        seteditriskCapital(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire7' });
        break;
      case 6:
        setInvestmentHopes(val.value);
        setIdResp(idResp.concat(val.IDCatQuestionsDetail));
        sessionStorage.setItem(
          'auxRecoveryIdCatQuestionDetail',
          idResp.concat(val.IDCatQuestionsDetail),
        );
        seteditinvestmentHopes(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire8' });
        break;
      case 7:
        switch (val.idField) {
          case 551:
            investmentOptDataChangeHandler(551, val);
            break;
          case 552:
            investmentOptDataChangeHandler(552, val);
            break;
          case 553:
            investmentOptDataChangeHandler(553, val);
            break;
          case 554:
            investmentOptDataChangeHandler(554, val);
            break;
          case 555:
            investmentOptDataChangeHandler(555, val);
            break;
          case 556:
            investmentOptDataChangeHandler(556, val);
            break;
          default:
            console.log('default break handleChangeCatOption case 2');
            break;
        }
        if (countOpt >= 3) {
          seteditcountOpt(true);
        }
        break;
      default:
        console.log(val);
    }
    console.log('investmentOpt >> ', investmentOpt, ' >> idResp >> ', idResp);
  };
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (useInvestmentMoney === ''
          || (useInvestmentMoney !== '' && !edituseInvestmentMoney))
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeInvQuestionnaire' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') != null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') != null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeInvQuestionnaire"></div>
        <QuestionsOrder
          classCard='cardOne'
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            useInvestmentMoney === ''
            || (useInvestmentMoney !== '' && !edituseInvestmentMoney)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire2"></div>
        <CardTwo
          classCard={
            useInvestmentMoney !== '' && edituseInvestmentMoney
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={useInvestmentMoney}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={!editpreferMoney ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 1 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            useInvestmentMoney !== '' && edituseInvestmentMoney
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (preferMoney === ''
              && useInvestmentMoney !== ''
              && edituseInvestmentMoney)
            || (preferMoney !== '' && edituseInvestmentMoney && !editpreferMoney)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire3"></div>
        <CardTwo
          classCard={
            preferMoney !== '' && editpreferMoney ? 'cardTwoResp' : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={preferMoney}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={!editmakeMoneyInvestment ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 2 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            preferMoney !== '' && editpreferMoney ? 'cardOne' : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />
        <TrainingQuestion
          className={
            (makeMoneyInvestment === ''
              && preferMoney !== ''
              && editpreferMoney)
            || (makeMoneyInvestment !== ''
              && editpreferMoney
              && !editmakeMoneyInvestment)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire4"></div>
        <CardTwo
          classCard={
            makeMoneyInvestment !== '' && editmakeMoneyInvestment
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={makeMoneyInvestment}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={!editemergencySaving ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 3 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            makeMoneyInvestment !== '' && editmakeMoneyInvestment
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={3}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (emergencySaving === ''
              && makeMoneyInvestment !== ''
              && editmakeMoneyInvestment)
            || (emergencySaving !== ''
              && editmakeMoneyInvestment
              && !editemergencySaving)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire5"></div>
        <CardTwo
          classCard={
            emergencySaving !== '' && editemergencySaving
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={emergencySaving}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={!editcurrentInvestment ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 4 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            emergencySaving !== '' && editemergencySaving ? 'cardOne' : 'hidden'
          }
          orderRutine={currentRoutine}
          step={4}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (currentInvestment === ''
              && emergencySaving !== ''
              && editemergencySaving)
            || (currentInvestment !== ''
              && editemergencySaving
              && !editcurrentInvestment)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="4-4"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire6"></div>
        <CardTwo
          classCard={
            currentInvestment !== '' && editcurrentInvestment
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={currentInvestment}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={!editriskCapital ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 5 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            currentInvestment !== '' && editcurrentInvestment
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={5}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (riskCapital === ''
              && currentInvestment !== ''
              && editcurrentInvestment)
            || (!editriskCapital && editcurrentInvestment && riskCapital !== '')
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="5-5"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire7"></div>
        <CardTwo
          classCard={
            riskCapital !== '' && editriskCapital ? 'cardTwoResp' : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={riskCapital}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={!editinvestmentHopes ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 6 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            riskCapital !== '' && editriskCapital ? 'cardOne' : 'hidden'
          }
          orderRutine={currentRoutine}
          step={6}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (investmentHopes === '' && riskCapital !== '' && editriskCapital)
            || (!editinvestmentHopes && editriskCapital && investmentHopes !== '')
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="6-6"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeInvQuestionnaire8"></div>
        <CardTwo
          classCard={
            investmentHopes !== '' && editinvestmentHopes
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={investmentHopes}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={investmentHopes !== '' ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 7 })}
          />
        </CardTwo>

        <QuestionsOrder
          classCard={
            investmentHopes !== '' && editinvestmentHopes ? 'cardOne' : 'hidden'
          }
          orderRutine={currentRoutine}
          step={7}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (investmentHopes !== '' && countOpt !== 3 && editinvestmentHopes)
            || (!editcountOpt && editinvestmentHopes)
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="7-7"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <CardTwo
          classCard={countOpt === 3 && editcountOpt ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={investmentOpt[0]}
          text2={investmentOpt[1]}
          text3={investmentOpt[2]}
          colorTraining="#91D770"
        >
          <img
            alt="editIcon"
            className={countOpt === 3 ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: 4, active: 8 })}
          />
        </CardTwo>
      </div>
  );
};

export default InvestmentQuestionnaire;
