import React from "react";
import { MenuOptions, Menu2 } from "../Assets/Styles/CaasNew.styles";
import Selected from "../Assets/Imgs/Selected.svg";
import NotSelected from "../Assets/Imgs/NotSelected.svg";

const LanguageDemo = ({ selectLang, setSelectLang }) => {
  return (
    <>
      <Menu2>
        <MenuOptions
          jcontent="flex-start"
          onClick={() => setSelectLang(2)}
          style={{ cursor: "pointer" }}
        >
          {selectLang === 2 ? (
            <img alt="" src={Selected} />
          ) : (
            <img alt="" src={NotSelected} />
          )}
          English
        </MenuOptions>
        <MenuOptions
          jcontent="flex-start"
          onClick={() => setSelectLang(1)}
          style={{ cursor: "pointer" }}
        >
          {selectLang === 1 ? (
            <img alt="" src={Selected} />
          ) : (
            <img alt="" src={NotSelected} />
          )}
          Spanish
        </MenuOptions>
        <MenuOptions
          jcontent="flex-start"
          onClick={() => setSelectLang(3)}
          style={{ cursor: "pointer" }}
        >
          {selectLang === 3 ? (
            <img alt="" src={Selected} />
          ) : (
            <img alt="" src={NotSelected} />
          )}
          Portuguese
        </MenuOptions>
      </Menu2>
    </>
  );
};

export default LanguageDemo;
