import React, { useState } from 'react';
import {
  ImageSections,
  CardsTxt,
  CardsBtn,
  DivStyle,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/perfil/perfiles.css';
import creditea from '../../assets/img/creditea.svg';
import Checkbox from '@material-ui/core/Checkbox';
import { ParagrapTwo } from '../../components/common/common.styled';
function CardCreditea() {

  const [defaultCardSize, setDefaultCardSize] = useState({ 'width': '75px', 'height': 'fit-content'})
  const [terminos, setterminos] = useState(true);

  const handelClickoutCreditea = () => {
    const url = sessionStorage.getItem("clickoutCreditea") || '';
    if (url !== '') {
        const win = window.open(url, '_blank');
        win.focus();
    }
  }
  
  return (
    <div key={'221'} className="d-flex flex-column" style={{
      minWidth: '270px', maxWidth: '320px', minHeight:'200px', maxHeight: '502px', padding: '11px', margin: '10px', boxShadow: '0px 4px 16px 0px rgb(0 0 0 / 20%)', background:'#fff',
    }}>
        <div className="p-md-0 p-4">
            <div className='row' style={{ display: 'flex', justifydefaultCardSizeContent: 'space-between', height: '95px' }}>
                <div className='col-6'>
                    <ImageSections pt="0px" ptm="0px" width={['width']} hmob={defaultCardSize['height']} ml="0" heightweb={defaultCardSize['height']} mhw="75px" mhm="75px" src={creditea} className="img-fluid" mt="25px" />
                </div>
                <div  className='col-6'>
                    <CardsTxt className="roboto" size="16px" ta="right">
                        Tasa de interés<br/>
                        <strong style={{fontWeight: "bold"}}>47.80%</strong>
                    </CardsTxt>
                    <CardsTxt className="roboto" size="16px" ta="right">
                        CAT<br/>
                        <strong style={{fontWeight: "bold"}}>127.87%</strong>
                    </CardsTxt>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <DivStyle
                        className="cabin"
                        mh="60px"
                        mxh="60px"
                        weight="600"
                        align="left"
                        size="18px"
                        line="1.5em"
                        padd="12px 0"
                        color="#07210A"
                    >
                        Creditea
                    </DivStyle>
                </div>
                <div className='col-6'>
                </div>
            </div>
            <DivStyle align="left" mh="80px" size="14px">
                <CardsTxt className="roboto" size="16px" mb="15px">
                    Este es un crédito pre-aprobado.
                    Para continuar con tu solicitud es necesario que apruebes los terminos y condiciones de Creditea.
                </CardsTxt>
                <DivStyle className='row'>
                    <div className="col-3 col-md-2 col-lg-2">
                        <Checkbox checked={terminos} onChange={() => {setterminos(!terminos)}} name="terminos"/>
                    </div>
                    <div className="col-9 col-md-10 col-lg-10">
                        <ParagrapTwo fontSize="14px" fontSizeWeb="14px" className="mx-auto roboto" mb='10%' mbweb='10%' mt="10px" color="#07210A" fontWeight=" " mtWeb="10px">
                            Si he leído y acepto <a href='https://www.creditea.mx/terminos-y-condiciones' target="_blank" className='linkColor'>términos</a> y <a href='https://www.creditea.mx/terminos-y-condiciones' target="_blank" className='linkColor'>condiciones</a> de Creditea
                        </ParagrapTwo>
                    </div>
                </DivStyle>
            </DivStyle>
            <CardsBtn mtopmd="10px" mtop="10px" mtopw="10px" min_w="100%" min_web="100%" mbottom="5%" mbottomM="5%" onClick={() => handelClickoutCreditea()}>
                Continuar solicitud
            </CardsBtn>
        </div>
    </div>
);
}
export default CardCreditea;
