import React, { useEffect, Fragment, useState } from 'react';

import { Route, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import PrivateRoute from './filter/PrivateRoute';
import Other from './Exportcomponents/OtherRoutesComponents';
import { GET_CAT_ROUTES_Q } from '../queries';
import LoginAsesores from '../components/common/LoginAsesores';
import WO_SeguroAuto from '../anaCoachNew/Trainings/WO_SeguroAuto';

const OtherRoutes = () => {
  const [result, setResult] = useState([])
  const [getCatRoute] = useLazyQuery(
    GET_CAT_ROUTES_Q,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getCatRoutes }) {
        setResult(getCatRoutes);
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  useEffect(() => {
    const input = { routeType: 'Other' };
    getCatRoute({ variables: input });
  }, []);

  const Element = ({ component }) => {
    const Component = Other[component];
    return <Component />;
  };
  return (
    <Fragment>
      <Switch>
        {result?.map((route) => (
          <Route
            exact
            path={route.path}
            component={() => Element(route)}
            key={route.id}
          />
        ))}
        <PrivateRoute exact path="/asesores-prueba" component={LoginAsesores} />
        <Route
            exact
            path={'/wo/ci-test'}
            component={() => <WO_SeguroAuto/>}
          />
        {/* <Route exact path="/score-question-video" component={ScoreVideoMessage} /> */}
      </Switch>
    </Fragment>
  );
};

export default OtherRoutes;
