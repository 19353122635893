import React, { useState, useEffect, Fragment } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useHistory, Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { Chart } from "react-chartjs-2";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_SESSION,
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
  INSERT_WAIT_LIST_PRODUCT_USER,
} from "../../mutations";

import ProductsFremium from "../../components/common/ProductsFremium";
// Import visual components
import {
  TrainingQuestion,
  TrainingText,
  CardsBtn,
  CardsBtn1,
  Images,
  TrainingContainAna,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingSectionGray,
  Resultado,
  DivStyle,
  FreemiumButton,
} from "../../assets/styles/Training.styled";
import { Feed_Main, Feed_Section } from "../../assets/styles/Feed.styled";
import { ContentHomeSection } from "../../components/common/Home.styled";
import Recommendations from "../../components/Training/RecommendedTraining";
import CardOne from "../../components/Home/Card1Animate";
import {
  GET_PRODUCTS_BY_IDTRAINING,
  GET_WO_AND_SUGGESTIONS,
} from "../../queries";
import ana from "../../assets/img/anacoach/CoachImage.png";
import ClickButton from "../../Helpers/HookHelpers";
import SectionShare from "../../components/common/ShareSection";
import ModalAmazon from "../../components/common/ModalAmazon";
import Starttraining from "../../components/Training/Starttraining";
import ModalLearnMore from "../../components/common/ModalLearnMore";
import ModalVirality from "../../components/common/ModalVirality";
import ModalVirality2 from "../../components/common/ModalVirality";
const SuccesTrainingBudget = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  success,
  setSuccess,
  setSaludo,
}) => {
  useEffect(() => {
    if (orderRutine === 11) {
      setSaludo(sessionStorage.getItem("successBudget") ? "No" : "Si");
      setSuccess(!!sessionStorage.getItem("successBudget"));
      setGraphics(!!sessionStorage.getItem("successBudget"));
    }
  });
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [graphcis, setGraphics] = useState(false);
  const [goFeed] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    }
  );
  const email = sessionStorage.getItem("Email1");
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  useEffect(() => {
    if (graphcis) {
      const idUser = sessionStorage.getItem("idUser");
      const idTraining = sessionStorage.getItem("idTraining");
      const fromXCoach = sessionStorage.getItem("fromXCoach");
      const idAdviser = sessionStorage.getItem("idAdviser");
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === "true" ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [graphcis]);
  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem("idTraining")}`;
      actions({ action: "ClickButtonCollection", variables: click });
      history.push("/the-training-feed/");
    }
  }, [goFeed]);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log("Insert finished by Xcoach");
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [suggestions, setSuggestions] = useState([]);
  const [getWOAndSuggestions] = useLazyQuery(GET_WO_AND_SUGGESTIONS, {
    onCompleted({ getWOAndSuggestions }) {
      try {
        const json = JSON.parse(getWOAndSuggestions.response);
        const { suggestions } = json[0];
        setSuggestions(suggestions);
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [stepSuggestion, setStepSuggestion] = useState(false);
  useEffect(() => {
    getWOAndSuggestions({
      variables: { idUser: sessionStorage.getItem("idUser") },
    });
  }, [stepSuggestion]);
  const continueSucces = () => {
    const click = `ContinuarSuccess_${sessionStorage.getItem("idTraining")}`;
    actions({ action: "ClickButtonCollection", variables: click });
    sessionStorage.setItem("successBudget", "true");
    setSuccess(true);
    setSaludo("No");
    setGraphics(true);
    console.log(orderRutine);
    gsap.to(window, { duration: 1, scrollTo: "#activeCongratulations" });
  };
  const tamCheck = {
    height: "25px",
    width: "25px",
    "margin-top": "-20px",
  };
  const convertirNum = (num) =>
    num ? parseFloat(num.replace(/,/g, "").replace("$", "").trim()) : 0;
  const getPercentage = (monto) => {
    const total =
      convertirNum(sessionStorage.getItem("SumaIngresos")) +
      convertirNum(sessionStorage.getItem("SimaEgresos"));
    const percentage = (convertirNum(monto) * 100) / total;
    return percentage.toFixed(2);
  };
  const getPercentageDebts = (monto) => {
    const total =
      convertirNum(sessionStorage.getItem("DeudaTotal")) +
      convertirNum(sessionStorage.getItem("AhorroInversionTotal"));
    const percentage = (convertirNum(monto) * 100) / total;
    return percentage.toFixed(2);
  };

  const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw() {
      originalDoughnutDraw.apply(this, arguments);
      const { chart } = this.chart;
      const { ctx } = chart;
      const { width } = chart;
      const { height } = chart;

      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em Verdana`;
      ctx.textBaseline = "middle";

      const { text } = chart.config.data;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;
      ctx.fillText(text, textX, textY);
    },
  });

  const stylesJSON = [
    {
      name: "Total ingresos",
      nameP: "Ingresos",
      monto: sessionStorage.getItem("SumaIngresos"),
      percentage: getPercentage(sessionStorage.getItem("SumaIngresos")),
      style: {
        background: "#91D770",
        border: "solid 1px #91D770",
        ...tamCheck,
      },
      detail: [
        {
          nameDetail: "Fijos",
          monto: sessionStorage.getItem("MontoIngresos"),
        },
        {
          nameDetail: "Variables",
          monto: sessionStorage.getItem("MontoIngresosVariable"),
        },
      ],
    },

    {
      name: "Total egresos",
      nameP: "Egresos",
      percentage: getPercentage(sessionStorage.getItem("SimaEgresos")),
      monto: sessionStorage.getItem("SimaEgresos"),
      style: {
        background: "#BFDD6A",
        border: "solid 1px #BFDD6A",
        ...tamCheck,
      },
      detail: [
        {
          nameDetail: "Indispensables",
          monto: sessionStorage.getItem("SumaEgresosIndispensables"),
        },
        {
          nameDetail: "Extras",
          monto: sessionStorage.getItem("SumaGastosExtra"),
        },
      ],
    },
  ];

  const stylesJSONDebts = [
    {
      name: "Ahorro",
      nameP: "Ahorro",
      monto: sessionStorage.getItem("AhorroInversionTotal"),
      percentage: getPercentageDebts(
        sessionStorage.getItem("AhorroInversionTotal")
      ),
      style: {
        background: "#F64282",
        border: "solid 1px #F64282",
        ...tamCheck,
      },
      detail: [],
    },

    {
      name: "Total deuda",
      nameP: "Deuda",
      monto: sessionStorage.getItem("DeudaTotal"),
      percentage: getPercentageDebts(sessionStorage.getItem("DeudaTotal")),
      style: {
        background: "#6BD5AF",
        border: "solid 1px #6BD5AF",
        ...tamCheck,
      },
      detail: [
        {
          nameDetail: "Formales",
          monto: sessionStorage.getItem("MontoAdeudo"),
        },
        {
          nameDetail: "Informales",
          monto: sessionStorage.getItem("MontoAdeudoInformales"),
        },
      ],
    },
  ];

  const continueSuggestions = (e) => {
    const click = `ContinuarSugerencias_${sessionStorage.getItem(
      "idTraining"
    )}`;
    actions({ action: "ClickButtonCollection", variables: click });
    e.preventDefault();
    setStepSuggestion(true);
    gsap.to(window, { duration: 1, scrollTo: "#activeSiguientesPasos" });
  };
  const [stepWoRecommend, setStepWoRecommend] = useState(false);
  const continueRecommend = (e) => {
    const click = `ContinuarRecomendaciones_${sessionStorage.getItem(
      "idTraining"
    )}`;
    actions({ action: "ClickButtonCollection", variables: click });
    e.preventDefault();
    setStepWoRecommend(true);
    const tagManagerArgs = {
      dataLayer: {
        event: "continueRecommend",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    gsap.to(window, { duration: 1, scrollTo: "#activeRecommended" });
  };
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [mostrarLoad, setMostrarLoad] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (mostrarLoad !== false) {
      handleClickTraining(mostrarLoad, false);
      setMostrarLoad(true);
    }
  }, [mostrarLoad]);
  const handleClickTraining = (training, ghosting = false) => {
    sessionStorage.setItem("trainingName", training.name);
    const action = "clickTraining";
    const tagManagerArgs = {
      dataLayer: {
        event: action.concat(training.name),
      },
    };
    let today = new window.Date(); // new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    TagManager.dataLayer(tagManagerArgs);
    const urlRedirect = training.TrainingRedirectUrl
      ? training.TrainingRedirectUrl
      : "#";
    const lastClick = {
      name: training.name,
      timestamp,
    };
    if (ghosting) lastClick.ghosting = true;
    let clicksTraining;
    if (sessionStorage.getItem("clicksTraining")) {
      clicksTraining = JSON.parse(sessionStorage.getItem("clicksTraining"));
      clicksTraining.push(lastClick);
      sessionStorage.setItem("clicksTraining", JSON.stringify(clicksTraining));
    } else {
      clicksTraining = [lastClick];
      sessionStorage.setItem("clicksTraining", JSON.stringify(clicksTraining));
    }
    const dataJson = {
      clickTraining: clicksTraining,
    };
    const filterJson = {
      _id: sessionStorage.getItem("id_session"),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: "sessions",
        data: dataString,
        filter: filterString,
      },
    };
    if (sessionStorage.getItem("id_session")) {
      updateSession({ variables: mongoUpdateInput });
    }
    if (!ghosting) {
      history.push(urlRedirect);
      setMostrarLoad(false);
    }
  };

  const hookRegresarAhorro = () => {
    const click = `RegresarAWOAhorroDeEmergencia_${sessionStorage.getItem(
      "idTraining"
    )}`;
    actions({ action: "ClickButtonCollection", variables: click });
  };

  const [reviewScroll, setReviewScroll] = useState(true);
  const [OpenModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenModal(true);
    }, 10000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        setReviewScroll(false);
        if (!success) {
          gsap.to(window, { duration: 1, scrollTo: "#activeSuccessBudget" });
        }
      }
    }, 2000);
  }, []);
  const anchop = window.screen.width;

  return (
    <>
      <div className="marginComentLgSuccess">
        <div id="activeSuccessBudget"></div>
        <CardOne
          classAna={anchop <= 768 ? "falseAna" : "trueAna"}
          classCard={!success ? "cardOne" : "hidden"}
          classTop="topOne1"
          textbold="¡Buen trabajo!"
          text="¡Casi tienes tu propio presupuesto! Vamos con la última parte..."
          text2="De vez en cuando hay que parar un momento y analizar como llega y se va tu dinero."
          text3="Enseguida te mostraré tu presupuesto personal."
          hora={hora}
          btn={true}
          funct={continueSucces}
        />
      </div>
      <div id="activeCongratulations"></div>
      <TrainingContainAna back="#FAFAFA" className={graphcis ? "" : "hidden"}>
        <Images
          src={ana}
          top="63px"
          topweb="5%"
          left="0"
          leftw="0"
          position="initial"
          border="2px solid #F5A838"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
      </TrainingContainAna>
      <TrainingSectionGray className={graphcis ? "" : "hidden"} back="#fafafa">
        <TrainingDivSection2
          paddiw="5% 10% 3% 10%"
          paddi="5% 5% 5% 5%"
          back="#fafafa"
        >
          <TrainingSubtitles
            size="22px"
            fw="600"
            align="center"
            alignweb="center"
            className="cabin"
          >
            ¡Felicidades!
          </TrainingSubtitles>
          <TrainingText
            size="18px"
            weight="500"
            className="roboto"
            align="center"
            alignweb="center"
            ls="-0.2px"
          >
            Hemos preparado una vista detallada de cómo gastas tu dinero
            mensualmente.
          </TrainingText>
        </TrainingDivSection2>
      </TrainingSectionGray>
      {graphcis ? (
        <div className="marginComentLgSuccess1">
          <TrainingQuestion wiw="60%" mLg="18%" width="100%">
            <TrainingQuestion className={graphcis ? "tablePresupuesto" : null}>
              {stylesJSON.map((item, index) => (
                <div style={{ margin: "2px 2px 0 2px" }} className="tablagen">
                  <div className="fila">
                    <div className="col">
                      <div
                        style={{
                          fontWeight: "bold",
                          height: "50px",
                          minWidth: "60%",
                          width: "auto",
                          margin: "0 0 0 10px",
                        }}
                        className="fs-16"
                      >
                        {item.name}
                      </div>
                    </div>
                    <div className="col">
                      <div
                        style={{
                          fontWeight: "bold",
                          height: "50px",
                          minWidth: "60%",
                          width: "auto",
                          margin: "0 0 0 10px",
                        }}
                        className="fs-16"
                      >
                        {item.monto}
                      </div>
                    </div>
                  </div>
                  <hr style={{ marginTop: "-5%" }} />
                  {item.detail.map((itemDetail, indexDetails) => (
                    <div className="fila">
                      <div className="col">
                        <div
                          style={{
                            fontWeight: "400",
                            height: "50px",
                            minWidth: "60%",
                            width: "auto",
                            margin: "0 0 0 20px",
                          }}
                          className="fs-16"
                        >
                          {itemDetail.nameDetail}
                        </div>
                      </div>
                      <div className="col">
                        <div
                          style={{
                            fontWeight: "400",
                            height: "50px",
                            minWidth: "60%",
                            width: "auto",
                            margin: "0 0 0 10px",
                          }}
                          className="fs-16"
                        >
                          {itemDetail.monto}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </TrainingQuestion>
          </TrainingQuestion>
        </div>
      ) : null}
      {/* DUEDAS */}
      <TrainingSectionGray back="#fafafa">
        <div className="marginComentLgSuccess1">
          <CardOne
            classAna={anchop <= 768 ? "falseAna" : "trueAna"}
            classCard={graphcis ? "cardOne" : "hidden"}
            classTop="topOne1"
            text="Ahora veremos tu ahorro contra tus deudas."
            hora={hora}
          />
        </div>
      </TrainingSectionGray>

      <div className="marginComentLgSuccess1">
        <TrainingQuestion wiw="60%" mLg="18%" width="100%">
          <TrainingQuestion
            className={graphcis ? "tablePresupuesto" : "hidden"}
          >
            {stylesJSONDebts.map((item, index) => (
              <div style={{ margin: "2px" }} className="tablagen">
                <div className="fila">
                  <div className="col">
                    <div
                      style={{
                        fontWeight: "bold",
                        height: "50px",
                        minWidth: "60%",
                        width: "auto",
                        margin: "0 0 0 10px",
                      }}
                      className="fs-16"
                    >
                      {item.name}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        fontWeight: "bold",
                        height: "50px",
                        minWidth: "60%",
                        width: "auto",
                        margin: "0 0 0 10px",
                      }}
                      className="fs-16"
                    >
                      {item.monto}
                    </div>
                  </div>
                </div>
                <hr style={{ marginTop: "-5%" }} />
                {item.detail.map((itemDetail, indexDetails) => (
                  <div className="fila">
                    <div className="col">
                      <div
                        style={{
                          fontWeight: "400",
                          height: "50px",
                          minWidth: "60%",
                          width: "auto",
                          margin: "0 0 0 20px",
                        }}
                        className="fs-16"
                      >
                        {itemDetail.nameDetail}
                      </div>
                    </div>
                    <div className="col">
                      <div
                        style={{
                          fontWeight: "400",
                          height: "50px",
                          minWidth: "60%",
                          width: "auto",
                          margin: "0 0 0 10px",
                        }}
                        className="fs-16"
                      >
                        {itemDetail.monto}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </TrainingQuestion>
        </TrainingQuestion>
      </div>
      <TrainingSectionGray back="#fafafa">
        <div className="marginComentLgSuccess1">
          <TrainingQuestion
            wiw="60%"
            mLg="18%"
            className={graphcis ? "" : "hidden"}
          >
            <div
              className={
                suggestions.length > 0 && stepSuggestion ? "hidden" : ""
              }
            >
              <CardsBtn
                className={
                  suggestions.length > 0 && stepSuggestion ? "hidden" : ""
                }
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={(e) => {
                  continueSuggestions(e);
                }}
              >
                Continuar
              </CardsBtn>
            </div>
          </TrainingQuestion>
          <div id="activeSiguientesPasos"></div>
          <CardOne
            classAna={anchop <= 768 ? "falseAna" : "trueAna"}
            classCard={
              suggestions.length > 0 && stepSuggestion ? "cardOne" : "hidden"
            }
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? "falseAna" : "trueAna"}
            classCard={
              suggestions.length > 0 && stepSuggestion ? "cardOne" : "hidden"
            }
            classTop="topOne1"
            text="¿Qué sigue? Estos son los siguientes pasos para poner en forma tu salud financiera."
            hora={hora}
          />

          <TrainingQuestion
            wiw="60%"
            mLg="18%"
            className={suggestions.length > 0 && stepSuggestion ? "" : "hidden"}
          >
            {suggestions.map((item, index) => (
              <ul>
                <div style={{ margin: "2px" }} className="tablagen">
                  <div className="fila">
                    <div className="col">
                      <li
                        style={{
                          height: "50px",
                          minWidth: "90%",
                          width: "auto",
                          margin: "0 0 0 10px",
                        }}
                      >
                        {item.Recomendations}
                      </li>
                    </div>
                  </div>
                </div>
              </ul>
            ))}
          </TrainingQuestion>

          {suggestions.length > 0 && stepSuggestion ? (
            <div className="marginComentLgSuccess1">
              <TrainingSectionGray back="#fafafa">
                {sessionStorage.getItem("idTraining") === "14" ? (
                  <ProductsFremium
                    idT={parseInt(sessionStorage.getItem("idTraining"))}
                  />
                ) : null}
              </TrainingSectionGray>
            </div>
          ) : null}

          <div id="activeRecommended"></div>
          <TrainingSectionGray back="#fafafa">
            <div className="marginComentLgSuccess1">
              <TrainingQuestion
                leftg="5%"
                mLg="18%"
                wiw="60%"
                width="100%"
                className={
                  suggestions.length > 0 && stepSuggestion ? "" : "hidden"
                }
              >
                <CardsBtn1
                  mleft="0px"
                  windthG="100%"
                  className={stepWoRecommend ? "hidden" : ""}
                  onClick={(e) => {
                    continueRecommend(e);
                  }}
                >
                  Continuar
                </CardsBtn1>
              </TrainingQuestion>
            </div>
          </TrainingSectionGray>
        </div>
      </TrainingSectionGray>
      {/* entrenamientosDB */}

      {recomendaciones && stepWoRecommend ? (
        <TrainingSectionGray back="#fafafa">
          {OpenModal ? (
            <ModalVirality2
              setShowModal={handleOpenModal}
              showModal={OpenModal}
            />
          ) : null}

          <TrainingDivSection2
            wiw="670px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="¿Aún quieres ir por más?"
              text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
            />
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
            />
          </TrainingDivSection2>
        </TrainingSectionGray>
      ) : null}
      {
        /* recomendaciones */
        recomendaciones && stepWoRecommend ? (
          <Fragment>
            <div className="marginComentLgSuccess1">
              <Feed_Main width="100%" background=" ">
                <Feed_Main
                  mLeft="18%"
                  widthxs="90%"
                  width="80%"
                  display=" "
                  mtop=" "
                  background="transparent"
                >
                  <Feed_Section padding="0 0 0 0" paddingxs="0 0 0 0">
                    <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                      <Recommendations
                        items={recommendedTraining}
                      ></Recommendations>
                    </ContentHomeSection>
                  </Feed_Section>
                </Feed_Main>
              </Feed_Main>
            </div>
          </Fragment>
        ) : null
      }
      <TrainingSectionGray
        back="#fafafa"
        className={stepWoRecommend ? "" : "hidden"}
      >
        <ModalAmazon show={true} wo={"Budget"}></ModalAmazon>
        <TrainingDivSection2
          wiw="670px"
          paddi="0"
          paddiw="0"
          mw="auto"
          back="#fafafa"
        >
          <SectionShare WO="Budget"></SectionShare>
          <div>
            {sessionStorage.getItem("Saving_Budget") === "true" ? (
              <Link to={sessionStorage.getItem("WO_came_from")}>
                <CardsBtn
                  min_w="70%"
                  min_web="50%"
                  mleft="15%"
                  mright="15%"
                  mleftweb="25%"
                  mrightweb="25%"
                  mtop="25px"
                  mtopw="25px"
                  type="button"
                  color="#EC407A !important"
                  back="#fff !important"
                  border="solid 1px !important"
                  padd="8px 15px 4px"
                  heigth="55px"
                  className=""
                  onClick={() => hookRegresarAhorro()}
                >
                  <b>Regresar al entrenamiento anterior</b>
                </CardsBtn>
              </Link>
            ) : null}
          </div>
        </TrainingDivSection2>
        <br />
      </TrainingSectionGray>
    </>
  );
};

const LoadProducts = () => {
  const [productsData, setProductsData] = useState([]);

  const [modalActive, setModalActive] = useState(false);

  const [productSelectedID, setProductSelectedID] = useState(0);

  const { loading, error, data } = useQuery(GET_PRODUCTS_BY_IDTRAINING, {
    variables: { idTraining: String("14") },
    onCompleted({ getProductsByIdTraining }) {
      console.log("# # Productos", getProductsByIdTraining);
      const products = JSON.parse(getProductsByIdTraining.response);
      console.log("# # products >>>", products);
      setProductsData(products);
    },
    onError(error) {
      console.log("error", error);
    },
  });

  const [insertWaitList] = useMutation(INSERT_WAIT_LIST_PRODUCT_USER, {
    onCompleted({ insertWaitListProductUser }) {
      setModalActive(false);
    },
    onError(err) {
      console.log(err);
    },
  });

  if (loading) return "Cargando";
  if (error) return "Error";

  const productSelectHandler = (productId) => {
    setProductSelectedID(productId);
    setModalActive(true);
  };

  const getAdvice = () => {
    if (!sessionStorage.getItem("idUser")) {
      setModalActive(false);
    }

    insertWaitList({
      variables: {
        input: {
          idUser: parseInt(sessionStorage.getItem("idUser")),
          idProduct: productSelectedID,
        },
      },
    });
  };

  return (
    <>
      <ModalLearnMore
        heiMod="90%"
        modalActive={modalActive}
        setModalActive={setModalActive}
        handleAdvice={getAdvice}
      />
      {productsData.map((producto) => {
        let text1 = "";
        let text2 = "";
        let text3 = "";

        switch (true) {
          case producto.Name.indexOf("Viaje") !== -1:
            text1 = "¿Tu presupuesto es para viajar?";
            text2 =
              "Te recomendamos planificar bien tu viaje con un buscador de viajes que te ayude a obtener los mejores precios.";
            text3 =
              "¡En Coru tenemos esta opción para ti! Estamos seguros que podrá interesarte.";
            break;

          case producto.Name.indexOf("Albo") !== -1:
            text1 = "¿No sabes donde guardar tu presupuesto?";
            text2 =
              "Nosotros te recomendamos usar un challenger bank donde podrás crear espacios, separar tu dinero y lograr tus objetivos.";
            text3 =
              "¡En Coru tenemos esta opción para ti! Estamos seguros de que podrá interesarte.";
            break;

          case producto.Name.indexOf("Tele") !== -1:
            text1 = "¿Tu presupuesto es para red telefónica?";
            text2 =
              "Te recomendamos este servicio móvil avanzado, navegación de alta velocidad y soluciones inteligentes para personas y empresas.";
            text3 =
              "¡En Coru tenemos esta opción para ti! Estamos seguros que podrá interesarte.";
            break;

          default:
            break;
        }

        if (producto.Name.indexOf("Viaje") !== -1) {
          if (!sessionStorage.getItem("MontoIngresosVariable")) return "";
        }

        return (
          <>
            <TrainingDivSection2
              paddiw="0"
              paddi="0"
              back="#fff"
              mb="20px"
              mt="20px"
            >
              <Starttraining
                className="hidden"
                title=""
                text={text1}
                text2={text2}
                text3={text3}
              />
            </TrainingDivSection2>

            <Resultado
              bcolor="#fff"
              ta="left"
              marriweb="auto"
              marri=" "
              border="none"
              hei="auto"
              minh="453px"
              minhweb="auto"
            >
              <div className="row" style={{ margin: "auto", width: "100%" }}>
                <div className="col-6" style={{ marginTop: "20px" }}>
                  <img
                    src={producto.ImageURL}
                    style={{ width: "100px", margin: "auto" }}
                  />
                </div>

                <DivStyle
                  className="cabin"
                  weight="600"
                  align="left"
                  size="20px"
                  line="1.5em"
                  padd="12px 5% 0 5%"
                  color="#07210A"
                >
                  {producto.Name}
                </DivStyle>

                <div
                  className="col-12"
                  style={{ marginTop: "20px", height: "210px" }}
                >
                  <TrainingText
                    size="14px"
                    sizew="14px"
                    weight="600"
                    className="roboto"
                    align="left"
                    alignweb="left"
                    ls="-0.2px"
                  >
                    Beneficios
                  </TrainingText>
                  <ul style={{ paddingLeft: "18px", height: "" }}>
                    {producto.benefits.map((item) => (
                      <>
                        {item.IDProductDetailType === 3 ? (
                          <li>{item.Description}</li>
                        ) : null}
                      </>
                    ))}
                  </ul>
                </div>

                <div className="col-12">
                  <FreemiumButton
                    type="button"
                    windthmd="100%"
                    minmd="100%"
                    mleftmd="0%"
                    mrightmd="auto"
                    windthG="100%"
                    min_w="100%"
                    min_web="100%"
                    min_mini="100%"
                    mleftmini="10%"
                    mrightmini="10%"
                    mleft="0%"
                    mright="0%"
                    mleftweb="0%"
                    mrightweb="auto"
                    color="#A6A6A6"
                    back="#FFFF"
                    border="1px solid #A6A6A6"
                    onClick={() =>
                      productSelectHandler(producto.CatProduct_IDProduct)
                    }
                  >
                    Conocer más
                  </FreemiumButton>
                </div>
              </div>
            </Resultado>
          </>
        );
      })}
    </>
  );
};

export default SuccesTrainingBudget;
