/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';

// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
} from '../../assets/styles/Training.styled';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import {
  arraySessionsRutines,
  filterFloat,
  establerCursorPosicion,
} from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import ClickButton from '../../Helpers/HookHelpers';

const Egresos = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [sumaEgresosIndispensables, setSumaEgresosIndispensables] = useState(
    sessionStorage.getItem('SumaEgresosIndispensables') || '',
  );
  const [activeInputs, setActiveInputs] = useState(true);

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const { actions } = ClickButton();
  const [form, setForm] = useState({
    rentaHipoteca: sessionStorage.getItem('RentaHipoteca') || '$',
    agua: sessionStorage.getItem('Agua') || '$',
    luz: sessionStorage.getItem('Luz') || '$',
    internet: sessionStorage.getItem('Internet') || '$',
    alimentos: sessionStorage.getItem('Alimentos') || '$',
    gasolinaTransporte: sessionStorage.getItem('GasolinaTransporte') || '$',
    egresosExtras: sessionStorage.getItem('EgresosExtras') || '$',
  });

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
    switch (step) {
      case 0:
        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e, question = '') => {
    e.preventDefault();
    console.log('handleChange', e.target.name, e.target.value);
    const value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    setForm({
      ...form,
      [e.target.name]: `$${formatterMx(filterFloat(value))}`,
    });
  };

  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setForm({
        rentaHipoteca: sessionStorage.getItem('RentaHipoteca') || '$',
        agua: sessionStorage.getItem('Agua') || '$',
        luz: sessionStorage.getItem('Luz') || '$',
        internet: sessionStorage.getItem('Internet') || '$',
        alimentos: sessionStorage.getItem('Alimentos') || '$',
        gasolinaTransporte: sessionStorage.getItem('GasolinaTransporte') || '$',
        egresosExtras: sessionStorage.getItem('EgresosExtras') || '$',
      });
      setSumaEgresosIndispensables(
        sessionStorage.getItem('SumaEgresosIndispensables') || '',
      );
      if (
        sessionStorage.getItem('RentaHipoteca') !== ''
        && sessionStorage.getItem('RentaHipoteca') !== null
      ) {
        setActiveInputs(false);
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const styleBtnDisabled = {
    color: '#fff',
    background: '#aaa',
    border: 'solid 1px #fff',
  };
  const numQuestionArray = ['0-0', '1-1', '2-2', '3-3', '4-4', '5-5', '6-6'];
  const nameForm = [
    'rentaHipoteca',
    'agua',
    'luz',
    'internet',
    'alimentos',
    'gasolinaTransporte',
    'egresosExtras',
  ];

  const [questionGasto, setQuestionGasto] = useState(0);
  const convertirNum = (num) => (num
    ? parseFloat(num.replace(/,/g, '').replace('$', '').trim().replace('', 0))
    : 0);
  useEffect(() => {
    const total = convertirNum(form.rentaHipoteca)
      + convertirNum(form.agua)
      + convertirNum(form.luz)
      + convertirNum(form.internet)
      + convertirNum(form.alimentos)
      + convertirNum(form.gasolinaTransporte)
      + convertirNum(form.egresosExtras);
    // setTotalGasto(`$${formatterMx(total)}`);
    setSumaEgresosIndispensables(`$${formatterMx(total)}`);
  }, [form]);
  const [nameBtn, setNameBtn] = useState('Siguiente >');
  const btnPrev = (e) => {
    e.preventDefault();
    const click = `AnteriorEgresos_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const prev = questionGasto === 0 ? questionGasto : questionGasto - 1;
    setQuestionGasto(prev);
    setNameBtn('Siguiente >');
  };
  const btnNext = (e) => {
    e.preventDefault();
    const next = questionGasto === 6 ? questionGasto : questionGasto + 1;
    console.log(next);
    if (
      next >= 1
      && (form.rentaHipoteca === '' || form.rentaHipoteca === '$')
    ) {
      form.rentaHipoteca = '$0';
    } else if (next >= 2 && (form.agua === '' || form.agua === '$')) {
      form.agua = '$0';
    } else if (next >= 3 && (form.luz === '' || form.luz === '$')) {
      form.luz = '$0';
    } else if (next >= 4 && (form.internet === '' || form.internet === '$')) {
      form.internet = '$0';
    } else if (next >= 5 && (form.alimentos === '' || form.alimentos === '$')) {
      form.alimentos = '$0';
    } else if (
      next >= 6
      && (form.gasolinaTransporte === '' || form.gasolinaTransporte === '$')
    ) {
      form.gasolinaTransporte = '$0';
    }
    if (nameBtn === 'Continuar') {
      if (form.egresosExtras === '' || form.egresosExtras === '$') {
        form.egresosExtras = '$0';
      }
      setActiveInputs(false);
      questions.push({
        id: 'sumaEgresosIndispensables',
        question: 8,
        value: sumaEgresosIndispensables,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
    }
    if (next === 6) {
      setNameBtn('Continuar');
    } else if (next < 6) {
      setNameBtn('Siguiente >');
    }
    setQuestionGasto(next);
  };
  const tamCheck = {
    height: '25px',
    width: '25px',
    marginTop: '-15px',
  };
  const egresosJSON = [
    {
      name: 'Renta/Hipoteca',
      style: {
        background: '#6AA84F',
        border: 'solid 1px #6AA84F',
        ...tamCheck,
      },
    },
    {
      name: 'Agua',
      style: {
        background: '#6D9EEB',
        border: 'solid 1px #6D9EEB',
        ...tamCheck,
      },
    },
    {
      name: 'Luz',
      style: {
        background: '#E06666',
        border: 'solid 1px #E06666',
        ...tamCheck,
      },
    },
    {
      name: 'Internet',
      style: {
        background: '#EC3F7A',
        border: 'solid 1px #EC3F7A',
        ...tamCheck,
      },
    },
    {
      name: 'Alimentos',
      style: {
        background: '#F1C232',
        border: 'solid 1px #F1C232',
        ...tamCheck,
      },
    },
    {
      name: 'Gasolina/Transporte',
      style: {
        background: '#674EA7',
        border: 'solid 1px #674EA7',
        ...tamCheck,
      },
    },
    {
      name: 'Extras',
      style: {
        background: '#0097A7',
        border: 'solid 1px #0097A7',
        ...tamCheck,
      },
    },
  ];
  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
    }, 4000);
  }, []);

  return (
    <>
      {!loaderWait || orderRutine !== 8 ? (
        <>
          <TrainingQuestion
            mLg="18%"
            leftg="auto"
            rigthg="auto"
            wiw="60%"
            width="90%"
            height="auto"
            className={activeInputs ? '' : 'hidden'}
          >
            <QuestionsDivs
              marginL="0px"
              marginLmd="0px"
              padd="0"
              txta="left"
              width="100%"
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="transparent"
            >
              <form>
                <InputsOrder
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  step={numQuestionArray[questionGasto]}
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  currentTraining={currentTraining}
                  activeStep={true}
                  labelInput=""
                  onClick={(e) => establerCursorPosicion(1, e.target.id, e)}
                />
              </form>
            </QuestionsDivs>
          </TrainingQuestion>
          <TrainingQuestion
            width="90%"
            wiw="60%"
            mLg="18%"
          >
            <div
              style={{ cssFloat: 'left' }}
              className={activeInputs ? '' : 'hidden'}
            >
              <CardsBtn
                style={styleBtnDisabled}
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={(e) => {
                  btnPrev(e);
                }}
              >
                {'< Anterior'}
              </CardsBtn>
            </div>
            <div
              style={{ cssFloat: 'right' }}
              className={activeInputs ? '' : 'hidden'}
            >
              <CardsBtn
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={(e) => {
                  btnNext(e);
                }}
              >
                {nameBtn}
              </CardsBtn>
            </div>
            {egresosJSON.map((item, index) => (
                <div
                  key={item.name}
                  style={{ margin: '2px', paddingTop: '25px' }}
                  className="tablagen col-12"
                >
                  <div className="fila">
                    <div className="col" style={{ width: '50px' }}>
                      <div style={item.style}></div>
                    </div>
                    <div className="col">
                      <div
                        style={{
                          height: '50px',
                          minWidth: '60%',
                          width: 'auto',
                          margin: '0 0 0 10px',
                        }}
                        className="fs-16"
                      >
                        {item.name}
                      </div>
                    </div>
                    <div className="col">
                      {' '}
                      <div>{form[nameForm[index]]}</div>
                    </div>
                  </div>
                </div>
            ))}
          </TrainingQuestion>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
    </>
  );
};

export default Egresos;
