import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import { GET_TRAINING_COLOR } from '../../queries';
import PurposeReasons from '../Routines/PurposeReasons';
import Purpose from '../Routines/Purpose';

const RoutinesOrderNewYearPurpose = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) => {
  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
    sessionStorage.setItem('BtnNotengoMetas', 'false');
  }, []);

  return (
    <>
      {orderRutine >= 1 ? (
        <PurposeReasons
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          trainingColor={colorTraining}
        />
      ) : null}
      {orderRutine >= 2 ? (
        <DatBasic
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={2}
          nextOrderRoutine={3}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 3 ? (
        <BirthDat
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={3}
          nextOrderRoutine={4}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 4 ? (
        <ContactInformation
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={4}
          nextOrderRoutine={5}
          recoveryTimeout={true}
        />
      ) : null}
      {orderRutine >= 5 ? (
        <Purpose
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={'rp'}
          trainingColor={colorTraining}
        />
      ) : null}
    </>
  );
};
export default RoutinesOrderNewYearPurpose;
