import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import {
  CREATE_SESSION,
  UPDATE_HISTORY_COLLECTION,
  DYNAMIC_GET,
  UPDATE_SESSION,
} from "../mutations";
import { getTimeStamp } from "./general";

const ClickButton = () => {
  const [url, seturl] = useState("");
  const [ishref, sethref] = useState(false);
  // const [close, setLogout] = useState(false);
  const history = useHistory();
  const [getUserValid] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const user = JSON.parse(dynamicGet.response);
        if (user.length > 0) {
          sessionStorage.setItem("Affiliate", "Si");
          const tagManagerArgs = {
            dataLayer: {
              event: "ExistAffiliate",
            },
          };
          TagManager.dataLayer(tagManagerArgs);
        }
      }
    },
  });

  const validAffiliate = (email) => {
    const getUserInput = {
      input: {
        table: "CatContactMedium",
        columns: ["Email1"],
        conditions: [
          {
            valone: "Email1",
            condition: "=",
            valtwo: email,
          },
        ],
      },
    };
    getUserValid({ variables: getUserInput });
  };

  const [createClickButtonCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        const clickButtonType = sessionStorage.getItem("clickButtonType");
        sessionStorage.setItem(clickButtonType, dynamicMongoSession.response);
        localStorage.setItem(clickButtonType, dynamicMongoSession.response);

        const clickButtonHref = sessionStorage.getItem("ClickButton_href");
        const clickButtonUrl = sessionStorage.getItem("ClickButton_url");
        if (clickButtonUrl && clickButtonUrl !== "") {
          sessionStorage.setItem("prevUrl", window.location.pathname);
          if (clickButtonHref) {
            window.location.href = clickButtonUrl;
          } else {
            history.push(clickButtonUrl);
          }
          // window.location.href = url;
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicUpdateHistoryMongo }) {
      if (dynamicUpdateHistoryMongo !== undefined) {
        if (dynamicUpdateHistoryMongo.statusCode === 200) {
          const { _id } = JSON.parse(dynamicUpdateHistoryMongo.response);
          const clickButtonType = sessionStorage.getItem("clickButtonType");
          sessionStorage.setItem(clickButtonType, _id);
          localStorage.setItem(clickButtonType, _id);

          const clickButtonHref = sessionStorage.getItem("ClickButton_href");
          const clickButtonUrl = sessionStorage.getItem("ClickButton_url");
          if (clickButtonUrl && clickButtonUrl !== "") {
            sessionStorage.setItem("prevUrl", window.location.pathname);
            if (clickButtonHref) {
              window.location.href = clickButtonUrl;
            } else {
              history.push(clickButtonUrl);
            }
            // window.location.href = url;
          }
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        const dataJson = [
          {
            action: sessionStorage.getItem("ActionClickButtonCreditCards"),
          },
        ];
        const dataString = JSON.stringify(dataJson);
        const filterString = JSON.stringify({
          _id: sessionStorage.getItem("idClickButton"),
        });
        const mongoInput = {
          input: {
            collection: "clickButtonCreditCards",
            key: "ClickButton",
            data: dataString,
            filter: filterString,
          },
        };
        updateHistoryCollection({ variables: mongoInput });
      }
    },
  });

  const ClickButtonCollection = (name) => {
    const action = "clickButton".concat(name);
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem("idClickButton")) {
      const dataJson = [
        {
          idUser: sessionStorage.getItem("idUser"),
          action,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem("idClickButton"),
      });
      const mongoInput = {
        input: {
          collection: "clickButton",
          key: "ClickButton",
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem("id_session"),
        ClickButton: [
          {
            idUser: sessionStorage.getItem("idUser"),
            action,
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: "clickButton",
          data: dataString,
        },
      };
      createClickButtonCollection({ variables: mongoInput });
    }

    // dynamicInsert( { variables } )
  };

  const ClickButtonCreditCards = (name) => {
    const action = "Click_".concat(name);
    sessionStorage.setItem("ActionClickButtonCreditCards", action);
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem("idClickButton")) {
      const dataJson = {
        idUser: sessionStorage.getItem("idUser"),
        idCont: sessionStorage.getItem("idCont"),
      };
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem("idClickButton"),
      });
      const mongoInput = {
        input: {
          collection: "clickButtonCreditCards",
          data: dataString,
          filter: filterString,
        },
      };
      updateSession({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem("id_session"),
        idUser: sessionStorage.getItem("idUser"),
        idCont: sessionStorage.getItem("idCont"),
        ClickButton: [
          {
            action,
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: "clickButtonCreditCards",
          data: dataString,
        },
      };

      createClickButtonCollection({ variables: mongoInput });
    }
  };

  const ClickButtonCoruHome = (
    variables,
    collection = "clickbuttonhomecoru",
    idStorage = "idClickButtonHomeCoru",
    withConcat = true
  ) => {
    const action = withConcat ? "clickButton".concat(variables) : variables;
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const timestamp = getTimeStamp();
    if (sessionStorage.getItem(idStorage)) {
      const dataJson = [
        {
          idUser: sessionStorage.getItem("idUser"),
          action,
          timestamp,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem(idStorage),
      });
      const mongoInput = {
        input: {
          collection: collection,
          key: "ClickButton",
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem("id_session"),
        ClickButton: [
          {
            idUser: sessionStorage.getItem("idUser"),
            action,
            timestamp,
          },
        ],
        env: process.env.REACT_APP_SERVERTYPE === "Production" ? "Prod" : "Dev",
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: collection,
          data: dataString,
        },
      };
      createClickButtonCollection({ variables: mongoInput });
    }

    // dynamicInsert( { variables } )
  };

  const ClickButtonLpCMC = (
    variables,
    collection = "clickButtonLpCMC",
    idStorage = "idClickButtonLpCMC"
  ) => {
    const action = variables;
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const timestamp = getTimeStamp();
    if (sessionStorage.getItem(idStorage)) {
      const dataJson = [
        {
          idUser: sessionStorage.getItem("idUser"),
          action,
          timestamp,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem(idStorage),
      });
      const mongoInput = {
        input: {
          collection: collection,
          key: "ClickButton",
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem("id_session"),
        ClickButton: [
          {
            idUser: sessionStorage.getItem("idUser") || "0",
            action,
            timestamp,
          },
        ],
        env: process.env.REACT_APP_SERVERTYPE === "Production" ? "Prod" : "Dev",
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: collection,
          data: dataString,
        },
      };
      createClickButtonCollection({ variables: mongoInput });
    }

    // dynamicInsert( { variables } )
  };
  const LogDataLPCMC = (
    variables,
    collection = "LogDataLPCMC",
    idStorage = "idLogDataLPCMC"
  ) => {
    const action = variables;
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const timestamp = getTimeStamp();
    if (sessionStorage.getItem(idStorage)) {
      const dataJson = [
        {
          idUser: sessionStorage.getItem("idUser") || "0",
          action,
          timestamp,
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem(idStorage),
      });
      const mongoInput = {
        input: {
          collection: collection,
          key: "ClickButton",
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idSession: sessionStorage.getItem("id_session"),
        ClickButton: [
          {
            idUser: sessionStorage.getItem("idUser") || "0",
            action,
            timestamp,
          },
        ],
        env: process.env.REACT_APP_SERVERTYPE === "Production" ? "Prod" : "Dev",
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: collection,
          data: dataString,
        },
      };
      createClickButtonCollection({ variables: mongoInput });
    }

    // dynamicInsert( { variables } )
  };

  const actions = ({
    action,
    variables,
    redirection = "",
    href = false,
    logout = false,
    collection,
  }) => {
    switch (action) {
      case "ClickButtonCollection":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButton");
        ClickButtonCollection(variables);
        break;
      case "validAffiliate":
        validAffiliate(variables);
        break;
      case "ClickButtonCoruHome":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonHomeCoru");
        ClickButtonCoruHome(variables, collection);
        break;
      case "ClickButtonForBusiness":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonHomeBusiness");
        ClickButtonCoruHome(variables, collection, "idClickButtonHomeBusiness");
        break;
      case "ClickButtonProductos":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonHomeProductos");
        ClickButtonCoruHome(variables, collection, "idClickButtonHomeProductos");
        break;
      case "ClickButtonNosotros":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonHomeNosotros");
        ClickButtonCoruHome(variables, collection, "idClickButtonHomeNosotros");
        break;
      case "ClickButtonCreditCards":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButton");
        ClickButtonCreditCards(variables);
        break;
      case "ClickButtonLoginMagicLink":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonLoginMagicLink");
        ClickButtonCoruHome(variables, "idClickButtonLoginMagicLink");
        break;
      case "ClickButtonLPBrasil":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonLPBrasil");
        ClickButtonCoruHome(variables, collection, "idClickButtonLPBrasil", false);
        break;
      case "ClickButtonLpCMC":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButton");
        ClickButtonLpCMC(variables);
        break;
      case "LogDataLPCMC":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idLogDataLPCMC");
        LogDataLPCMC(variables, collection, "idLogDataLPCMC", false);
        break;
      case "ClickButtonLPCaaS":
        sethref(href);
        seturl(redirection);
        sessionStorage.setItem("ClickButton_href", href);
        sessionStorage.setItem("ClickButton_url", redirection);
        sessionStorage.setItem("clickButtonType", "idClickButtonLPCaaS");
        ClickButtonCoruHome(variables, collection, "idClickButtonLPCaaS", false);
        break;
      default:
        break;
    }
  };
  return { actions };
};

export default ClickButton;
