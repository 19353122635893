/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import editIcon from '../../assets/img/anacoach/editar1.png';

import {
  TrainingQuestion,
  QuestionsFlex,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';

const SeguroMedicoPrestaciones = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  setActiveSucces,
  insuranceActive,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editPagaria, setEditPagaria] = useState('');
  const [editPresupuesto, setEditPresupuesto] = useState('');
  const [editAseguras, setEditAseguras] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log('caracteristicas', routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser !== null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];

                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                      contResponse++;
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [dispuestoAcontratar, setDispuestoAcontratar] = useState(
    sessionStorage.getItem('DispuestoAcontratar') || '',
  );
  const [dispuestoApagarSeguro, setDispuestoApagarSeguro] = useState(
    sessionStorage.getItem('DispuestoApagarSeguro') || '',
  );
  const [queAsegurar, setQueAsegurar] = useState(
    sessionStorage.getItem('QueAsegurar') || '',
  );
  const [form] = useState({});
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 0:
        setEditPagaria(true);
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeQuestion2Prestaciones',
        });
        if (val.value === 'No') {
          // todo mandar al rp
          if (nextOrderRoutine === 'rp') {
            setActiveSucces(true);
            insuranceActive.push('AXA Seguro de gastos médicos mayores');
          }
        }
        setDispuestoAcontratar(val.value);
        break;
      case 1:
        setEditPresupuesto(true);
        setDispuestoApagarSeguro(val.value);
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeQuestion3Prestaciones',
        });
        break;
      case 2:
        setEditAseguras(true);
        setQueAsegurar(val.value);
        if (
          val.value === 'Accidentes ya sea de trabajo, en casa o vía pública'
        ) {
          insuranceActive.push('THONA Seguro de accidentes');
          insuranceActive.push('MAPFRE Seguro de accidentes');
        } else if (val.value === 'Enfermedades de Alto Riesgo') {
          insuranceActive.push('GNP Seguro de enfermedades de alto riesgo');
          insuranceActive.push('AXA Seguro de enfermedades de alto riesgo');
        } else if (val.value === 'Enfermedades de Bajo Riesgo') {
          insuranceActive.push('GNP Seguro de enfermedades de bajo riesgo');
          insuranceActive.push('MAPFRE Seguro de enfermedades de bajo riesgo');
        }
        // enviar al rp
        setActiveSucces(true);
        break;
      default:
        console.log(val);
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setDispuestoAcontratar(
        sessionStorage.getItem('DispuestoAcontratar') || '',
      );
      if (sessionStorage.getItem('DispuestoAcontratar') === 'No') {
        // todo mandar al rp
        if (nextOrderRoutine === 'rp') {
          insuranceActive.push('AXA Seguro de gastos médicos mayores');
          setActiveSucces(true);
        }
      }
      setDispuestoApagarSeguro(
        sessionStorage.getItem('DispuestoApagarSeguro') || '',
      );
      setQueAsegurar(sessionStorage.getItem('QueAsegurar') || '');
      if (
        sessionStorage.getItem('QueAsegurar')
        === 'Accidentes ya sea de trabajo, en casa o vía pública'
      ) {
        insuranceActive.push('THONA Seguro de accidentes');
        insuranceActive.push('MAPFRE Seguro de accidentes');
        setActiveSucces(true);
      } else if (
        sessionStorage.getItem('QueAsegurar') === 'Enfermedades de Alto Riesgo'
      ) {
        insuranceActive.push('GNP Seguro de enfermedades de alto riesgo');
        insuranceActive.push('AXA Seguro de enfermedades de alto riesgo');
        setActiveSucces(true);
      } else if (
        sessionStorage.getItem('QueAsegurar') === 'Enfermedades de Bajo Riesgo'
      ) {
        insuranceActive.push('GNP Seguro de enfermedades de bajo riesgo');
        insuranceActive.push('MAPFRE Seguro de enfermedades de bajo riesgo');
        setActiveSucces(true);
      }
    }
  }, [recoveryRoutine]);
  const handleChangeIcon = (json) => {
    setEditPagaria(false);
    setEditPresupuesto(false);
    setEditAseguras(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (dispuestoAcontratar === ''
          || (lapiz[0].routine === currentRoutine
            && lapiz[0].active === 1))
        && editPagaria !== true
      ) {
        gsap.to(window, {
          duration: 1,
          scrollTo: '#activeSeguroMedicoPrestaciones',
        });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeSeguroMedicoPrestaciones"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="Continuemos"
          hora={hora}
        />
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard="cardOne"
          classTop="topOne1"
          text="El Seguro Social y el seguro que te brinde tu empresa puede ser efectivo para ciertos casos como accidentes de trabajo y enfermedades crónicas, pero generalmente no incluye coberturas de accidentes en viaje, ni atención prioritaria en emergencias."
          hora={hora}
        />

        <QuestionsOrder
          classCard="cardOne"
          orderRutine={currentRoutine}
          step={0}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            (dispuestoAcontratar === ''
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1))
            && editPagaria !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeQuestion2Prestaciones"></div>
        <DivRespDP>
          <img
            alt="editIcon"
            className={
              dispuestoAcontratar !== '' && dispuestoApagarSeguro === ''
                ? 'iconEdit1'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
            }
          />
          <CardTwo
            classCard={
              dispuestoAcontratar !== ''
              && (editPagaria === true
                || !(
                  lapiz[0].routine === currentRoutine
                  && lapiz[0].active === 1
                ))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={dispuestoAcontratar}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={
                dispuestoAcontratar !== '' && dispuestoApagarSeguro === ''
                  ? 'iconEditCommon'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: currentRoutine, active: 1 })
              }
            />
          </CardTwo>
        </DivRespDP>
        <QuestionsOrder
          classCard={
            dispuestoAcontratar !== ''
            && (editPagaria === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 1
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={1}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((dispuestoAcontratar !== '' && dispuestoApagarSeguro === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 1
            )
              || editPagaria === true)
            && editPresupuesto !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="1-1"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <div id="activeQuestion3Prestaciones"></div>
        <DivRespDP>
          <img
            alt="editIcon"
            className={
              dispuestoApagarSeguro !== '' && queAsegurar === ''
                ? 'iconEdit1'
                : 'hidden'
            }
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 2 })
            }
          />
          <CardTwo
            classCard={
              dispuestoApagarSeguro !== ''
              && (editPresupuesto === true
                || !(
                  lapiz[0].routine === currentRoutine
                  && lapiz[0].active === 2
                ))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={dispuestoApagarSeguro}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>

        <QuestionsOrder
          classCard={
            dispuestoApagarSeguro !== ''
            && (editPresupuesto === true
              || !(
                lapiz[0].routine === currentRoutine
                && lapiz[0].active === 2
              ))
              ? 'cardOne'
              : 'hidden'
          }
          orderRutine={currentRoutine}
          step={2}
          handleChangeRutine={handleChangeRutine}
          currentTraining={currentTraining}
          textBold=""
          text2=""
          hora={hora}
        />

        <TrainingQuestion
          className={
            ((dispuestoApagarSeguro !== '' && queAsegurar === '')
              || (lapiz[0].routine === currentRoutine
                && lapiz[0].active === 3))
            && (!(
              lapiz[0].routine === currentRoutine && lapiz[0].active === 2
            )
              || editPresupuesto === true)
            && editAseguras !== true
              ? ''
              : 'hidden'
          }
        >
          <QuestionsFlex display="inline">
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={orderRutine}
              step="2-2"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </QuestionsFlex>
        </TrainingQuestion>
        <DivRespDP>
          <img
            alt="editIcon"
            className={queAsegurar !== '' ? 'iconEdit1' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeIcon({ routine: currentRoutine, active: 3 })
            }
          />
          <CardTwo
            classCard={
              queAsegurar !== ''
              && (editAseguras === true
                || !(
                  lapiz[0].routine === currentRoutine
                  && lapiz[0].active === 3
                ))
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={queAsegurar}
            colorTraining={trainingColor}
          ></CardTwo>
        </DivRespDP>
      </div>
  );
};

export default SeguroMedicoPrestaciones;
