import React, { useState } from 'react';
import {
  ModalBackground, ModalBackCC,
} from '../../assets/styles/Home.styled';
import '../../assets/styles/Loader.css';

const ModalLoader = ({lp}) => {
    const [modalState, setModalState] = useState('flex');
    return (
        <>
            {
                <ModalBackground display={modalState} bcolor="rgb(37 37 37 / 90%)" bcolorm="rgb(37 37 37 / 90%)">
                    <ModalBackCC height="auto" margin="5% auto" width="auto" bcolor="transparent" style={{ textAlign: 'center' }}>
                        <div style={{ color: '#ffffff', fontSize: '16px', marginBottom: '30px' }}>
                            Estamos trabajando en tu solicitud, esto puede tardar unos minutos
                        </div>
                        <div class={lp === "vexi" ? "lds-ringVexi" : lp === "coppel" ? "lds-ringCoppel" : "lds-ring"}><div></div><div></div><div></div><div></div></div>
                    </ModalBackCC>
                </ModalBackground>
            }
        </>
    );
};

export default ModalLoader;
