import React, { useState, useEffect, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { useTrail, animated } from 'react-spring';
import '../../assets/styles/Home.css';
import { useMutation, useLazyQuery } from '@apollo/client';
import Shapes from '../../assets/img/anacoach/Shapes/Rectangle 23.png';
import Shapes2 from '../../assets/img/anacoach/Shapes/Vector.png';
import Shapes4 from '../../assets/img/anacoach/Shapes/Rectangle 25.png';
import Shapes5 from '../../assets/img/anacoach/Shapes/Vector-1.png';
import Trofeo from '../../assets/img/anacoach/Trofeo.png';
import RoutinesOrderMvp from '../../anaCoach/Training/RoutinesOrderMvp2Inv';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';

import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
} from '../../mutations';
import { GET_STATUS_MVP_TRAINING, GET_LIST_MVP } from '../../queries';

import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingText,
  TrainingSectionGray,
  TrainingDivSection2,
  TextFriends,
  CardsBtn,
} from '../../assets/styles/Mvp.styled';
import { TrainigTam, Images } from '../../assets/styles/Training.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariable,
} from '../../Helpers';

const items2 = ['¿Cuánto sabes de tu', 'inversión?'];
const config = { mass: 4, tension: 600, friction: 100 };
const WO_Money = () => {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 22);
  // manejo de orden de la ruitna
  const [shareEffect, setshareEffect] = useState(false);
  const [shareButton, setShareButton] = useState(false);

  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(1);
  const [mostrarShareButtons, setmostrarShareButtons] = useState(false);
  const [userActive, setuserActive] = useState('');
  const [isMasterView, setisMasterView] = useState('master');
  const [mostrarQuiz, setmostrarQuiz] = useState(true);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState('falso');
  const [idUserTemp, setIdUserTemp] = useState('');
  const [idUserExiste, setIdUserExiste] = useState(false);
  const [Name1, setName1] = useState('-----');
  const [Name2, setName2] = useState('-----');
  const [Name3, setName3] = useState('-----');
  const [Name4, setName4] = useState('-----');
  const [Name5, setName5] = useState('-----');

  const [bshark, setbshark] = useState(false);
  const [bapren, setbapren] = useState(false);
  const [bmaster, setbmaster] = useState(false);
  const [idExercise, setIdExercise] = useState('');

  //----------------------------------------------------------------
  const [toggle2] = useState(true);
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  if (sessionStorage.getItem('idUser') != null && userActive === '') {
    setuserActive('true');
  }
  const trail2 = useTrail(items2.length, {
    config,
    opacity: toggle2 ? 1 : 0,
    a: toggle2 ? 0 : 20,
    height: toggle2 ? 80 : 0,
    from: { opacity: 0, a: 20, height: 0 },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [getStatusMvpTraining] = useLazyQuery(GET_STATUS_MVP_TRAINING, {
    onCompleted({ getStatusMvpTraining }) {
      console.log(getStatusMvpTraining);
      if (getStatusMvpTraining.message !== '') {
        setIdExercise(getStatusMvpTraining.message);
        setmostrarShareButtons(true);
        setmostrarQuiz(false);
        setMostrarTabla('verdadero');
      }
    },
  });

  const [getListMvp] = useLazyQuery(GET_LIST_MVP, {
    onCompleted({ getListMvp }) {
      if (getListMvp.statusCode === 200) {
        const responseLength = getListMvp.response.length;
        for (let cont = 0; cont < getListMvp.response.length; cont++) {
          switch (getListMvp.response[cont].ClassificationName) {
            case 'Baby Shark':
              if (
                bshark === false
                && getListMvp.response[cont].IDUserFriend === idExercise.toString()
              ) {
                setbshark(true);
              }
              break;
            case 'The Apprentice':
              if (
                bapren === false
                && getListMvp.response[cont].IDUserFriend === idExercise.toString()
              ) {
                setbapren(true);
              }
              break;
            case 'Master of Puppets':
              if (
                bmaster === false
                && getListMvp.response[cont].IDUserFriend === idExercise.toString()
              ) {
                setbmaster(true);
              }
              break;
            default:
              break;
          }
        }
        switch (responseLength) {
          case 0:
            break;
          case 1:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            break;
          case 2:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            break;
          case 3:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            setName3(
              `${getListMvp.response[2].FirstName} ${getListMvp.response[2].FathersLastName}- ${getListMvp.response[2].ClassificationName}`,
            );
            break;
          case 4:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            setName3(
              `${getListMvp.response[2].FirstName} ${getListMvp.response[2].FathersLastName}- ${getListMvp.response[2].ClassificationName}`,
            );
            setName4(
              `${getListMvp.response[3].FirstName} ${getListMvp.response[3].FathersLastName}- ${getListMvp.response[3].ClassificationName}`,
            );
            break;
          default:
            setName1(
              `${getListMvp.response[0].FirstName} ${getListMvp.response[0].FathersLastName}- ${getListMvp.response[0].ClassificationName}`,
            );
            setName2(
              `${getListMvp.response[1].FirstName} ${getListMvp.response[1].FathersLastName}- ${getListMvp.response[1].ClassificationName}`,
            );
            setName3(
              `${getListMvp.response[2].FirstName} ${getListMvp.response[2].FathersLastName}- ${getListMvp.response[2].ClassificationName}`,
            );
            setName4(
              `${getListMvp.response[3].FirstName} ${getListMvp.response[3].FathersLastName}- ${getListMvp.response[3].ClassificationName}`,
            );
            setName5(
              `${getListMvp.response[4].FirstName} ${getListMvp.response[4].FathersLastName}- ${getListMvp.response[4].ClassificationName}`,
            );
            break;
        }
      }
    },
  });
  useEffect(() => {
    if (idUserExiste === true) {
      setIdUserTemp(sessionStorage.getItem('idUser'));
    }
  }, [idUserExiste]);

  useEffect(() => {
    if (userActive === 'true') {
      getStatusMvpTraining({
        variables: {
          idUser: sessionStorage.getItem('idUser'),
          idTraining: '22',
        },
      });
    }
  }, [userActive]);

  useEffect(() => {
    if (mostrarTabla === 'verdadero') {
      getListMvp({
        variables: {
          idUser: idExercise.toString(),
          catTraining: '22',
          viewType: isMasterView,
        },
      });
    }
  }, [mostrarTabla]);
  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            console.log('getFielOption', questionRoutine, item);
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);
        saveAdvance(parseInt(orderRutine));
        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado
          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            // console.log("existe el usuario pero no esta logueado");
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            setIdUserExiste(true);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            // console.log("existe el usuario pero  esta logueado");
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          setIdUserExiste(true);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
      }
    },
  });

  useEffect(() => {
    sessionStorage.setItem('pagename', 'funnel-prestamos');
    sessionStorage.setItem('hostname', window.location.hostname);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);

  const [newSession, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  const saveAdvance = (idRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(orderRutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };
  const shareAction = () => {
    setshareEffect(true);
  };

  const hora = `${date.getHours()}:${minutos}`;

  return (
    <Fragment>
      <TrainingMain>
        <TrainigTam>
          <Header background={'#fafafa'} pathname={window.location.pathname} />
        </TrainigTam>
        <Images
          src={Shapes}
          width="125px"
          top="8%"
          topweb="8%"
          left="75%"
          leftw="75%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <Images
          src={Shapes}
          width="42px"
          top="12%"
          topweb="12%"
          left="55%"
          leftw="55%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <Images
          src={Shapes2}
          width="90px"
          top="24%"
          topweb="24%"
          left="77%"
          leftw="77%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <Images
          src={Shapes4}
          width="78px"
          top="18%"
          topweb="18%"
          left="85%"
          leftw="85%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <Images
          src={Shapes4}
          width="55px"
          top="20%"
          topweb="20%"
          left="62%"
          leftw="62%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <Images
          src={Shapes5}
          width="60px"
          top="9%"
          topweb="9%"
          left="67%"
          leftw="67%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <Images
          src={Shapes5}
          width="60px"
          top="34%"
          topweb="34%"
          left="85%"
          leftw="85%"
          position="absolute"
          className="img-fluid"
        ></Images>
        <TrainingAnimateFunnel
          bcolor="#fafafa"
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="auto"
          hweb="auto"
        >
          <TrainigTam
            w="auto"
            wweb="auto"
            pos="initial"
            posw="initial"
            bc=""
            hm="auto"
            pt="108px"
            pl="20px"
            plw="0"
          >
            {trail2.map(({ a, height, ...rest }, index) => (
              <animated.div
                key={items2[index]}
                className="trails-text text-funnelcc cabin mvp-landing cabin centrar"
                style={{
                  ...rest,
                  transform: a.interpolate((a) => `translate3d(0,${a}px,0)`),
                }}
              >
                <animated.div style={{ height }}>{items2[index]}</animated.div>
              </animated.div>
            ))}
          </TrainigTam>
        </TrainingAnimateFunnel>

        <br />
        <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
          <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
            <RoutinesOrderMvp
              Date={stateDate}
              hora={hora}
              idUsuarioQuiz={idUserTemp}
              mostrarTabla={mostrarTabla}
              setMostrarTabla={setMostrarTabla}
              orderRutine={orderRutine}
              setOrderRutine={setOrderRutine}
              currentTraining={currentTraining}
              createJsonInput={createJsonInput}
              questions={questions}
              setQuestions={setQuestions}
              idExercise={idExercise}
              setIdExercise={setIdExercise}
              mostrarQuiz={mostrarQuiz}
              setmostrarQuiz={setmostrarQuiz}
              mostrarShareButtons={mostrarShareButtons}
              setmostrarShareButtons={setmostrarShareButtons}
              isMasterView={isMasterView}
              setisMasterView={setisMasterView}
              setshareEffect={setshareEffect}
              shareEffect={shareEffect}
              shareButton={shareButton}
              setShareButton={setShareButton}
            />
          </TrainingDivSection2>
        </TrainingSectionGray>
        {mostrarTabla !== 'falso' && mostrarTabla !== '' ? (
          <div>
            <TrainingDivSection
              back="#fafafa"
              padding="0 10% 2% 10%"
              paddw="8% 0 0 0"
            >
              <TrainingText className="roboto" align="center" alignweb="center">
                <b>Resultados Quiz</b>
              </TrainingText>
            </TrainingDivSection>
            <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
              <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                {bmaster !== false ? (
                  <TextFriends>
                    <b>Master of puppets</b>
                    <br /> ¡Tu dinero y tu se conocen bien!
                    <br />
                    <br />
                    Tienes todo bajo control. Recuerda la importancia de
                    comparar antes de invertir o gastar, pero que podemos
                    decirte aquí ¡Tú eres el experto!.
                  </TextFriends>
                ) : (
                  ''
                )}
                <br />
                {bapren !== false ? (
                  <TextFriends>
                    <b>The apprentice</b> ¡Lo estás logrando!
                    <br />
                    <br />
                    Conoces lo suficiente sobre tu dinero, pero en este mundo
                    todo pasa muy rápido y tu dinero también se acaba rápido.
                    <br />
                    <br />
                    Trata de investigar más antes de tomar decisiones serias.
                  </TextFriends>
                ) : (
                  ''
                )}
                <br />
                {bshark !== false ? (
                  <TextFriends>
                    <b>Baby shark</b> <br />
                    ¡Tenemos mucho que aprender!
                    <br />
                    <br />
                    Es importante saber en qué y cómo estamos usando nuestro
                    dinero pero sobre todo hacer que funcione para nosotros y no
                    solo trabajar para obtenerlo. No todo está perdido, en
                    cuanto nuestros entrenamientos estén listos serás de los
                    primeros en recibirlos.
                  </TextFriends>
                ) : (
                  ''
                )}
              </TrainingDivSection2>
            </TrainingSectionGray>
            <TrainingDivSection
              back="#fafafa"
              padding="0 10% 2% 10%"
              paddw="8% 0 0 0"
            >
              <TrainingText className="roboto" align="center" alignweb="center">
                <b>¿Eres el experto en finanzas de tus amigos?</b>
                <br />
                Comparte este quiz con ellos y conozcan al experto.
              </TrainingText>
            </TrainingDivSection>
            <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
              <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                <Images
                  src={Trofeo}
                  width="60px"
                  top="0"
                  topweb="0"
                  left="45%"
                  leftw="45%"
                  position="relative"
                  className="img-fluid"
                ></Images>
                <br />
                <br />
                <TextFriends>
                  <b>1</b> {Name1}
                </TextFriends>
                <br />
                <TextFriends>
                  <b>2</b> {Name2}
                </TextFriends>
                <br />
                <TextFriends>
                  <b>3</b> {Name3}
                </TextFriends>
                <br />
                <TextFriends>
                  <b>4</b> {Name4}
                </TextFriends>
                <br />
                <TextFriends>
                  <b>5</b> {Name5}
                </TextFriends>
                <br />
                <br />
              </TrainingDivSection2>
            </TrainingSectionGray>
          </div>
        ) : null}
        {shareButton === true ? (
          <CardsBtn
            padding="0px 0px 0px 0px"
            type="button"
            min_w="40%"
            className=""
            min_web="40%"
            mleft="30%"
            mleftweb="30%"
            onClick={() => shareAction()}
          >
            Compartir
          </CardsBtn>
        ) : (
          ''
        )}
      </TrainingMain>
      <Footer pathname={window.location.pathname} />
      <div id="irTimeDom"></div>
    </Fragment>
  );
};
export default WO_Money;
