import React from 'react';
import '../../assets/styles/common/customTooltip.css';
import '../../assets/styles/Home.css';

const CustomTooltip = ({
  position, content, displayText, displayLink, link,
}) => {
  const getContent = () => (
            <span className="tooltip-content">{content}</span>
  );

  return (
        <div className="coru-custom-tooltip">
            {getContent()}
            <span className="tooltip-text">
                {displayText} {displayLink
                  ? <a href={link} target="_blank" rel="noreferrer">
                    {displayLink}
                </a> : ''}
            </span>
        </div>
  );
};

export default CustomTooltip;
