/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import Carousel from 'react-elastic-carousel';
import '../../assets/styles/Home.css';
import {
  Image as ImageH,
  ContEntrenamiento,
  ContEntrenamientos,
  ContentTimer,
  TitleEntrenamiento,
  TextTime,
} from '../../assets/styles/Home.styled';
// assets

import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import Starttraining from '../../components/Training/Starttraining';
import BarFilterBank from '../../components/common/BarFilterBank';
import ResultProduct from '../../components/common/ResultProduct';

// bancos
import bancomer from '../../assets/img/anacoach/bancomer.png';
import amex from '../../assets/img/anacoach/amex.png';
import santander from '../../assets/img/anacoach/santander.png';
import scotia from '../../assets/img/anacoach/scotia.png';
import citi from '../../assets/img/anacoach/citi.png';
import viajes from '../../assets/img/anacoach/Viajar.png';
import reembolso from '../../assets/img/anacoach/efectivo.png';
import puntospre from '../../assets/img/anacoach/premios.png';
import meses from '../../assets/img/anacoach/sin intereses.png';
import ProductsRPOCA from '../../components/common/ProductsRPOCA';

import preventas from '../../assets/img/anacoach/preventa.png';
import anualidad from '../../assets/img/anacoach/Anualidad-01.png';
import {
  ImageSections,
  Images,
  TrainingQuestion,
  QuestionsDivs,
  QuestionsFlex,
  QuestionsText,
  Cardsmain,
  CardsU,
  CardsTxt,
  CardsBtn,
  DivRespDP,
  TrainingText,
  TrainingSectionGray,
  TrainingDivSection2,
  DivStyle,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardOne from '../../components/Home/Card1Animate';
import SectionShare from '../../components/common/ShareSection';
import CardTwo from '../../components/Home/Card2Animate';
import {
  GET_CARDS_FRONT,
  RECOVERY_ROUTINE_BY_ORDER,
  GET_TRAINING_COLOR,
} from '../../queries';
import Banks from '../Training/Banks';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7, Image } from '../../components/common/common.styled';
import Clock from '../../assets/img/anacoach/clockTraining.png';
// recovery
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import { TextField } from '@material-ui/core';
const PerfilamientoCC = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [colorTraining, setColorTraining] = useState('');
  const history = useHistory();

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  const [activeRutine, setActiveRutine] = useState(false);
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // end recovery
  const arrayImagesOptions = {
    146: reembolso,
    145: puntospre,
    147: meses,
    148: viajes,
    149: preventas,
    150: anualidad,
  };
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  // Hooks
  const [cardOptions, setCardOptions] = useState([]);
  const [cardOptionsActive, setCardOptionsActive] = useState(false);
  const [bancoActual, setBancoActual] = useState(sessionStorage.getItem('bancoActual') || '');

  const [Card, setTarjeta] = useState(
    sessionStorage.getItem('Quetarjetatienes') || '',
  );  
  const [cardTmp, setCardTmp] = useState(
    sessionStorage.getItem('Quetarjetatienes') || '',
  );
  const [FormaPago, setPago] = useState('');
  const [countBen, setCountBen] = useState(0);
  const [beneficios, setBeneficios] = useState([]);
  const [CanjePuntos, setCanjeP] = useState('');
  const [Difiere, setDifiere] = useState('');
  const [Viajas, setViajas] = useState('');
  const [editbancoActual, setEditbancoActual] = useState(false);
  const [editCard, seteditCard] = useState(Card !== '');
  const [editFormaPago, seteditFormaPago] = useState(FormaPago !== '');
  const [editbeneficios, seteditbeneficios] = useState(beneficios === 3);
  const [editCanjeP, seteditCanjeP] = useState(CanjePuntos !== '');
  const [editDifiere, seteditDifiere] = useState(Difiere !== '');
  const [editViajas, seteditViajas] = useState(Viajas !== '');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log(routines);
          setActiveRutine(true);
          let contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (parseInt(idTypeQuestion) === 128) {
                stopRoutine = true;
                break;
              }
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      setRecoveryRecoveryRoutine('inactiva');
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const [getCardsFront] = useLazyQuery(GET_CARDS_FRONT, {
    onCompleted({ getCardsFront }) {
      console.log('cards response ===>>>', getCardsFront.response);
      setCardOptions(Object.assign(cardOptions, getCardsFront.response));
      setCardOptionsActive(!cardOptionsActive);
      gsap.to(window, { duration: 1, scrollTo: '#activeTarjetaTienes' });
    },
    onError(err) { 
      console.log(err);
    },
  });
  // ----------- end hooks --------------------------------
  const [form, setForm] = useState({
    empresa: sessionStorage.getItem('Nombre_de_la_empresa') || '',
  });
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }
  useEffect(() => {
    console.log('length beneficios: ', beneficios.length);
    console.log('beneficios: ', beneficios);
    console.log('Rutine: ', isEmptyObject(Rutine));
    let element;
    if (beneficios.includes('Puntos premio')) {
      element = document.getElementsByName('Puntos premio')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (beneficios.includes('Reembolso de efectivo')) {
      element = document.getElementsByName('Reembolso de efectivo')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (beneficios.includes('Meses sin intereses')) {
      element = document.getElementsByName('Meses sin intereses')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (beneficios.includes('Beneficios de viaje y salas VIP')) {
      element = document.getElementsByName(
        'Beneficios de viaje y salas VIP',
      )[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (beneficios.includes('Preventas')) {
      element = document.getElementsByName('Preventas')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (beneficios.includes('Anualidad Gratis')) {
      element = document.getElementsByName('Anualidad Gratis')[0];
      if (element !== undefined && element !== 'undefined') {
        element.classList.add('selectedBen');
      }
    }
    if (beneficios.length && !sessionStorage.getItem('3beneficiosentarjetas')) {
      for (let b = 0; b <= beneficios.length; b++) {
        switch (beneficios[b]) {
          case 'Puntos premio':
            element = document.getElementsByName(beneficios[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Reembolso de efectivo':
            element = document.getElementsByName(beneficios[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Meses sin intereses':
            element = document.getElementsByName(beneficios[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Beneficios de viaje y salas VIP':
            element = document.getElementsByName(beneficios[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Preventas':
            element = document.getElementsByName(beneficios[b])[0];
            element.classList.add('selectedBen');
            break;
          case 'Anualidad Gratis':
            element = document.getElementsByName(beneficios[b])[0];
            element.classList.add('selectedBen');
            break;
          default:
            break;
        }
      }
    }

    if (beneficios.length === 3 && !isEmptyObject(Rutine)) {
      const optionsSelected = `${beneficios[0]},${beneficios[1]},${beneficios[2]}`;
      questions.push({
        id: 'beneficios',
        question: 4,
        value: optionsSelected,
      });

      createJsonInput(Rutine, questions);
      setQuestions([]);
      gsap.to(window, { duration: 1, scrollTo: '#activeCanjePuntos' });
    }
  }, [beneficios]);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend >>>> ', numQuestion, name, value);
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log('Cat or empresa >>>>');
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);

    switch (val.step) {
      case 0:
        // setOcupacion(val.value);
        break;
      case 1:
        // setIndustria(val.value);
        break;
      case 2:
        setPago(val.value);
        seteditFormaPago(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeBeneficiosTarjeta' });
        break;
      case 3:
        switch (val.idField) {
          case 146:
            // setReembolso('Si');
            setCountBen(countBen + 1);
            setBeneficios(beneficios.concat(val.value));
            break;
          case 145:
            setCountBen(countBen + 1);
            setBeneficios(beneficios.concat(val.value));
            // setPuntos('Si');
            break;
          case 147:
            setCountBen(countBen + 1);
            setBeneficios(beneficios.concat(val.value));
            // setMeses('Si');
            break;
          case 148:
            setCountBen(countBen + 1);
            setBeneficios(beneficios.concat(val.value));
            // setViajeSalas('Si');
            break;
          case 149:
            setCountBen(countBen + 1);
            setBeneficios(beneficios.concat(val.value));
            // setPreventas('Si');
            break;
          case 150:
            setCountBen(countBen + 1);
            setBeneficios(beneficios.concat(val.value));
            // setAnualidad('Si');

            break;
          default:
            console.log('default break handleChangeCatOption case 2');
            break;
        }
        seteditbeneficios(true);
        break;
      case 4:
        setCanjeP(val.value);
        seteditCanjeP(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeDifieresPagos' });
        break;
      case 5:
        setDifiere(val.value);
        seteditDifiere(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeCuandoViajas' });
        break;
      case 6:
        setViajas(val.value);
        seteditViajas(true);
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log('default break handleChangeCatOption case 2');
        break;
    }
  };

  const setCardActual = (nameCard, imgCard, categoryCard, card) => {
    debugger;
    const click = `SeleccionarTarjetaActual_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('actualCardName', nameCard);
    sessionStorage.setItem('Quetarjetatienes', nameCard);
    sessionStorage.setItem('categoryCardAc', categoryCard);
    localStorage.setItem('hasCardSelected', JSON.stringify(card));
    setTarjeta(nameCard);
    const tagManagerArgs = {
      dataLayer: {
        event: 'actualCard',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    sessionStorage.setItem('actualImgCard', imgCard);
    questions.push({
      id: 'tarjeta-actual',
      question: 2,
      value: nameCard,
    });

    createJsonInput(Rutine, questions);
    setQuestions([]);
    gsap.to(window, { duration: 1, scrollTo: '#activeComoPagas' });
  };

  const handleChangeCunrrentBank = (bank) => {
    const variables = {
      Bank: bank,
    };

    getCardsFront({ variables });
    console.log(bank);
    setBancoActual(bank);
    questions.push({
      id: bank,
      question: 1,
      value: bank,
    });

    createJsonInput(Rutine, questions);
    setQuestions([]);
    setEditbancoActual(true);
    gsap.to(window, { duration: 1, scrollTo: '#activeTarjetaTienes' });
    if(bank === 'No tengo tarjeta de crédito') history.push('/wo/card-funnel');
  };
  const [modalBanks, setModalBanks] = useState(false);
  const [sinTarjeta, setSinTarjeta] = useState(false);
  const [otraTarjeta, setOtraTarjeta] = useState(false);
  const [ban, setFueBan] = useState(false);
  useEffect(() => {
    if (modalBanks && ban === false) {
      setFueBan(true);
      gsap.to(window, { duration: 1, scrollTo: '#activeOtrosBancos' });
    }
  }, [modalBanks, ban]);
  //------------------
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setTarjeta(sessionStorage.getItem('Quetarjetatienes') || '');
      setSinTarjeta(sessionStorage.getItem('sinTarjeta'))
      setPago(sessionStorage.getItem('Comopagastutarjeta') || '');
      setBeneficios(
        sessionStorage.getItem('3beneficiosentarjetas')
          ? sessionStorage.getItem('3beneficiosentarjetas').split(',')
          : [],
      );
      setCanjeP(sessionStorage.getItem('Redencióndepuntos') || '');
      setDifiere(sessionStorage.getItem('Cadacuantodifierestuscompras') || '');
      setViajas(sessionStorage.getItem('Cadacuantoviajas') || '');
    }
  }, [recoveryRoutine]);
  console.log(editbancoActual);
  useEffect(() => {
    if (bancoActual && !Card && cardOptions.length === 0) {
      console.log('Searching fir cardsFront ===>>>>');
      getCardsFront({
        variables: {
          Bank: bancoActual,
        },
      });
    }
    if (bancoActual !== '') {
      setEditbancoActual(true);
    }
  }, [bancoActual]);

  useEffect(() => {
    debugger;
    if (bancoActual && !Card && cardOptions.length === 0) {
      console.log('Searching fir cardsFront ===>>>>');
      getCardsFront({
        variables: {
          Bank: bancoActual,
        },
      });
    }
    if (bancoActual !== '') {
      setEditbancoActual(true);
    }
  }, []);

  useEffect(() => {
    if (Card !== '') {
      seteditCard(true);
    } else {
      seteditCard(false);
    }
  }, [Card]);

  const handleChangeIcon = (json) => {
    switch (json.active) {
      case 1:
        setEditbancoActual(false);
        setBancoActual('');
        console.log(editbancoActual);
        break;
      case 2:
        seteditCard(false);
        setTarjeta('');
        console.log(editCard);
        break;
      case 3:
        seteditFormaPago(false);
        break;
      case 4:
        seteditbeneficios(false);
        setBeneficios([]);
        setCountBen(0);
        break;
      case 5:
        seteditCanjeP(false);
        break;
      case 6:
        seteditDifiere(false);
        break;
      case 7:
        seteditViajas(false);
        setOrderRutine(1);
        break;
      default:
        break;
    }
  };
  console.log('********************orderrutine*********************');
  console.log(orderRutine);
  const anchop = window.screen.width;
  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      {activeRutine === true ? (
        <>
          <InputsOrder
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            setOrderRutine={setOrderRutine}
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="0-0"
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            currentTraining={currentTraining}
            handleChange={handleChange}
            activeRutine={activeRutine}
          />
        </>
      ) : null}
      {/* pregunta 1 */}
      <TrainingQuestion
        wiw="64%"
        mLg="17%"
        className={bancoActual !== '' || editbancoActual ? 'hidden' : ''}
      > <div style = {{backgroundColor:"#F3F3F3"}} className = "pb-5">
          <TrainingText padding="1.5rem 5px" bot="0px" sizexs="14px" size="14px" sizew="14px" className="roboto" align="left" alignweb="left" ls="-0.2px">
          Para ayudarte en este entrenamiento, ayudame indicando de qué institución es tu tarjeta de crédito actual.
          </TrainingText>
          <QuestionsFlex>
          <QuestionsDivs onClick={() => handleChangeCunrrentBank('BBVA')}>
            <Images
              position="relative"
              width="100px"
              left="0"
              top="0"
              src={bancomer}
            ></Images>
          </QuestionsDivs>
          <QuestionsDivs
            onClick={() => handleChangeCunrrentBank('Citibanamex')}
          >
            <Images
              position="relative"
              width="100px"
              left="0"
              top="12px"
              src={citi}
            ></Images>
          </QuestionsDivs>
        </QuestionsFlex>
        <QuestionsFlex>
          <QuestionsDivs onClick={() => handleChangeCunrrentBank('Santander')}>
            <Images
              position="relative"
              width="100px"
              left="0"
              top="12px"
              src={santander}
            ></Images>
          </QuestionsDivs>
          <QuestionsDivs onClick={() => handleChangeCunrrentBank('Scotiabank')}>
            <Images
              position="relative"
              width="100px"
              left="0"
              top="12px"
              src={scotia}
            ></Images>
          </QuestionsDivs>
        </QuestionsFlex>
        <div id="activeOtrosBancos"></div>
        <QuestionsFlex>
          <QuestionsDivs
            onClick={() => handleChangeCunrrentBank('American Express')}
          >
            <Images
              position="relative"
              width="90px"
              left="0"
              top="2px"
              src={amex}
            ></Images>
          </QuestionsDivs>
          <QuestionsDivs onClick={() => setModalBanks(true)}>
            <QuestionsText size="14px" weight="400" padd="5px">
              Otro
            </QuestionsText>
          </QuestionsDivs>
        </QuestionsFlex>
        <CardsBtn
          windthmd="95%"
          mleftweb="5px"
          mrightmd="5px"
          mleftmd="5px"
          mleftmini="5px"
          mrightmini="5px"
          mrightweb="5px"
          mleft="5px"
          mright="5px"
          min_web="95%"
          color="#EC407A !important"
          back="#fff !important"
              border="solid 1px !important"
          onClick={() => {
            handleChangeCunrrentBank("No tengo tarjeta de crédito");
            setSinTarjeta(true);
            sessionStorage.setItem('sinTarjeta', true)
           }
          }
        >
          No tengo tarjeta de crédito
        </CardsBtn>
        {modalBanks ? (
          <div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={modalBanks ? 'cardOne' : 'hidden'}
              classTop="topOne1"
              text="Selecciona la institución:"
              hora={hora}
            />
            <br />
            <Banks handleChangeBanks={handleChangeCunrrentBank} />
          </div>
        ) : null}
      </div>
      </TrainingQuestion>
      <div id="activeTarjetaTienes"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            bancoActual !== '' && editbancoActual ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => {handleChangeIcon({ routine: 1, active: 1 }); setSinTarjeta(false)}}
        />
        <CardTwo
          classCard={
            bancoActual !== '' && editbancoActual ? 'cardTwoResp' : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={bancoActual}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
      {false  ?
        <>
          <Starttraining
            title="¡Ups, parece que algo salió mal! 😞"
            text="Para poder hacer este entrenamiento es necesario que cuentes con una tarjeta de crédito, ya que comparamos tu tarjeta actual, con la mejor opción para ti en el mercado."
          />
        <ContEntrenamientos>
          <ContEntrenamiento
            id='101'
            background={'#A370FF'}
            widthM="100%"
            widthD="80%"
          >
            <Link
              to='/wo/card-funnel'
            >
              <ContentTimer
                height=' '
                maxheightMobile='130px'
                heightMobile='140px'
                maxheight='107.250px'
                display='flex'
                top='0px'
                width='100%'
                position=' '
              >
                <br />
                <TitleEntrenamiento
                  className="roboto"
                  margin="auto"
                  top=" "
                  widthD="230px"
                >
                  Solicita tu tarjeta sin tantos trámites: En menos de 5 minutos.
                </TitleEntrenamiento>
                <ImageH src='https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CWO_CC%20%281%29.svg' width="25%" margin="auto" />
              </ContentTimer>
              <ContentTimer>
                <Image
                  src={Clock}
                  width='20px'
                  left='0px'
                  position=' '
                  top='1%'
                />
                <TextTime className='roboto'>
                  Tiempo de entrenamiento: 5 minutos
                </TextTime>
              </ContentTimer>
            </Link>
          </ContEntrenamiento>
          </ContEntrenamientos>
          <SectionShare WO="TarjetaIdeal" isCompareCardSuccess></SectionShare>
      </>:null}

      {/* pregunta 2 */}
      <div id="question2"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={(bancoActual !== '' || editbancoActual) && !sinTarjeta ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="¿Qué tarjeta tienes?"
      />
      {!sinTarjeta ? 
      <TrainingQuestion
        wiw="100%"
        className={
          (bancoActual !== '' && Card === '')
            ? ''
            : 'hidden'
        }
      >
        <BarFilterBank
          key={'1'}
          text={bancoActual}
          disableBtn
        >
          <Carousel
            id="CarruselMetasLogros"
            styled={{ background: '#FAFAFA' }}
            transitionMs={1000}
          >
            {cardOptions.map((card, index) => (
                <div style ={{margin:"auto 20% auto 16.5%"}} className="w-100 containerCard text-center d-flex justify-content-center">
                  <ResultProduct
                    card={card}
                    showBenefit={true}
                    ClickButton={setCardActual}
                  />
                </div>
            ))}                 
          </Carousel>
        </BarFilterBank>
        <div style={{
          display:"flex",
          justifyContent:"center",    
        }}>
          {Card == '' ? <CardsBtn
            windthmd="80%"
            mleftweb="5px"
            mrightmd="5px"
            mleftmd="5px"
            mleftmini="5px"
            mrightmini="5px"
            mrightweb="30px"
            mleft="5px"
            mright="5px"
            min_web="50%"
            color="#EC407A !important"
            back="#fff !important"
            border="solid 1px !important"
            onClick={() => {
              setOtraTarjeta(true);
            }}
          >
            Tengo otra tarjeta
          </CardsBtn>:null}

        </div>
          {otraTarjeta && Card == '' ?
            <div style={{
              display:"flex",
              justifyContent:"center",    
            }}>
              <TrainingDivSection
                hew="auto"
                className="topOne1"
                paddw="26px"
                mlw="0%"
                mrw="5%"
                mtw="8%"
                wiw="65%"
                back="#F3F3F3"
                backw="#F3F3F3"
                bshaw="none"
                bsha=""
                br="4px"
                padding="20px"
                wi="100%"
                he="auto"              
              >
                <TrainingText
                  bot="0px"
                  className="cabin"
                  align="left"
                  weight="600"
                  size="20px"
                  sizew="20px"
                >
                  Por favor, ingresa el nombre de tu tarjeta
                </TrainingText>
                <TextField
                  variant='outlined'
                  id='nombreTarjeta'
                  name='nombreTarjeta'
                  label='Nombre de la tarjeta'
                  helperText=''
                  className='textInput inputs-bottom input-coru'
                  fullWidth
                  value={cardTmp}
                  onChange={(e) => setCardTmp(e.target.value)}
                />
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtopw="0px"
                  mtop="0px"
                  mtopmd="0px"
                  onClick={ () =>                    
                  setCardActual(cardTmp, 'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CGroup.png', 'Alta',
                  {
                    CardImage: 'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CGroup.png',
                    Id: 0,
                    benefit: JSON.stringify({
                      beneficios: ['Desconocidos'],
                      extra: ['Desconocidos'],
                      anualidad: 'Desconocidos'
                    }),
                    CardName: cardTmp,
                  })}
                >
                  Continuar 
                </CardsBtn>
              </TrainingDivSection>  
            </div>: null                        
          }  
      </TrainingQuestion>:null}

      <div id="formaPago"></div>
      <div id="activeComoPagas"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={Card !== '' && editCard ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 2 })}
        />
        <CardTwo
          classCard={Card !== '' && editCard ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={Card}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
      <div id="irBancosMore"></div>
      {/* Pregunta tres */}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={Card !== '' && editCard ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="¿Cómo pagas tu tarjeta?"
      />
      <TrainingQuestion
        className={
          (bancoActual !== '' && Card !== '' && FormaPago === '' && editCard)
          || (!editFormaPago && editCard && FormaPago !== '')
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>

      <div id="3beneficiosentarjetas"></div>
      <div id="activeBeneficiosTarjeta"></div>

      <DivRespDP>
        <img
          alt="editIcon"
          className={
            Card !== '' && FormaPago !== '' && editFormaPago
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 3 })}
        />
        <CardTwo
          classCard={
            Card !== '' && FormaPago !== '' && editFormaPago
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={FormaPago}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Card !== '' && FormaPago !== '' && editFormaPago
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        textbold={`¡Excelente ${sessionStorage.getItem('FirstName') || ''}! 👍`}
        text={`Recuerda que pagar el total y en tiempo puede hacerte obtener créditos con intereses más bajos.`}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Card !== '' && FormaPago !== '' && editFormaPago
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="Ahora selecciona 3 beneficios que para ti son los más importantes tener en tu tarjeta de crédito:"
      />
      <TrainingQuestion
        className={
          (FormaPago !== '' && countBen !== 3 && editFormaPago)
          || (countBen === 3 && !editbeneficios)
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="3-3"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
            arrayImagesOptions={arrayImagesOptions}
            activeImgOption={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeCanjePuntos"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={countBen === 3 && editbeneficios ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 4 })}
        />
        <CardTwo
          classCard={
            countBen === 3 && editbeneficios ? 'cardTwoResp' : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={beneficios[0]}
          text2={beneficios[1]}
          text3={beneficios[2]}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={countBen === 3 && editbeneficios ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="¿Cada cuánto canjeas tus puntos?"
      />
      <TrainingQuestion
        className={
          (countBen === 3 && CanjePuntos === '' && editbeneficios)
          || (editbeneficios && !editCanjeP && CanjePuntos !== '')
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="4-4"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeDifieresPagos"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            countBen === 3 && CanjePuntos !== '' && editCanjeP
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 5 })}
        />
        <CardTwo
          classCard={
            countBen === 3 && CanjePuntos !== '' && editCanjeP
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={CanjePuntos}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          countBen === 3 && CanjePuntos !== '' && editCanjeP
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cada cuánto difieres tus compras a meses?"
      />
      <TrainingQuestion
        className={
          (CanjePuntos !== '' && Difiere === '' && editCanjeP)
          || (editCanjeP && !editDifiere && Difiere !== '')
            ? ''
            : 'hidden'
        }
      >
        {console.log(
          `**********Orderrutina en pregunta**************${orderRutine}*********CURRENTrOUTINE******${currentRoutine}`,
        )}
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="5-5"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeCuandoViajas"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            CanjePuntos !== '' && Difiere !== '' && editDifiere
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 6 })}
        />
        <CardTwo
          classCard={
            CanjePuntos !== '' && Difiere !== '' && editDifiere
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={Difiere}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          CanjePuntos !== '' && Difiere !== '' && editDifiere
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cada cuándo viajas?"
      />
      <TrainingQuestion
        className={
          (Difiere !== '' && Viajas === '' && editDifiere)
          || (Viajas !== '' && editDifiere && !editViajas)
            ? ''
            : 'hidden'
        }
      >
        {console.log(`orderRutine en cuanto viajas ${orderRutine}`)}
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="6-6"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={Viajas !== '' && editViajas ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 1, active: 7 })}
        />
        <CardTwo
          classCard={Viajas !== '' && editViajas ? 'cardTwoResp' : 'hidden'}
          classTop="topTwo1"
          imgMan="hidden"
          text={Viajas}
          colorTraining={colorTraining}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default PerfilamientoCC;
