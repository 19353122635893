import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import {
  TextField, Button, Grid, Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Modal from '@material-ui/core/Modal';
import { JsonToCsv, useJsonToCsv } from 'react-json-csv';
import {
  ContainerSection,
  Buttons,
  Subtitle,
  FormSesiones,
  ContainerBox,
  ButtonsB,
  ContainerSectionGeneral,
} from './Styleds/Asesores.Styled';
import BtnDropDw from './Util/BtnDropDw.js';
import {
  VERIFY_TOKEN,
  FIND_USERS_FOR_ADVISERS,
  GET_USER_FOR_ADVISERS,
  GET_PENDING_SESSIONS_XCOACH,
  GET_REPORT_ADVISER,
  GET_COUNT_RECORDS_ADVISER,
  LOGBC_GET_MONGO,
  GET_NEW_REPORT_ADVISER,
} from '../../queries';
import logo from '../../assets/img/white-logo.svg';
import './styles.css';
import { Rectangle13, UiCard, TextoCoach } from '../common/Home.styled';
import { ParagrapTwo } from '../common/common.styled';
import AuthenticateXCoach from '../../anaCoach/Training/AuthenticateXcoach';
import UserData from './fragments/UserData';
import TrainingData from './fragments/TrainingData';
import UnfinishedTraining from './fragments/UnfinishedTraining';
import LocalizedUsers from './fragments/LocalizedUsers';
import TrainingList from './fragments/TrainingList';
import TypingResult from './fragments/TypingResult';
import { CREATE_CLICK } from '../../mutations';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgressWithLabel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  paper: {
    position: 'absolute',
    width: '350px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '500px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const fields = {};
const newfields = {};
let data = [];
let Newdata = [];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const Datos = () => {
  const { saveAsCsv } = useJsonToCsv();
  const classes = useStyles();
  // Validación de sesión
  const valToken = useQuery(VERIFY_TOKEN, {
    variables: { token: sessionStorage.getItem('token') },
  });
  if (valToken.loading !== true) {
    if (valToken.data.verifyToken.response === 'invalid') {
      window.location.href = '/advisers';
    }
  }
  //! never used: const history = useHistory();
  const text = 'DESCARGAR REPORTE';
  const style = { padding: '5px' };
  const [progress, setProgress] = useState(0);
  const [nameReport, setNameReport] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [usersFound, setUsersFound] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [tabTraining, setTabTraining] = useState([]);
  const [auxTabTraining, setAuxTabTraining] = useState([]);
  const [dataBC, setDataBC] = useState();
  const [typing, setTyping] = useState('');
  const [showTyping, setShowTyping] = useState(false);
  const [realizarConsultaBC, setRealizarConsultaBC] = useState(false);
  const [ultimosDigitos, setUltimosDigitos] = useState();
  const [bancoActual, setBancoActual] = useState();

  const [next, setNext] = useState(false);
  const [nextnewreporte, setNextNewReporte] = useState(false);
  const [Logbc, setLogBc] = useState(false);
  const [ultimafecha, setUltimaFecha] = useState('');
  const [arrays, setArrays] = useState([]);
  const [countArrays, setCountArrays] = useState(0);

  const firstName = sessionStorage.getItem('FirstName') || '';
  const fathersLastName = sessionStorage.getItem('FathersLastName')
    ? ` ${sessionStorage.getItem('FathersLastName')}`
    : '';
  const options = ['Reporte', 'NewReporte'];
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const [modalStyle] = useState(getModalStyle);
  const [loadingReporte, setLoadingReporte] = useState(false);
  const [btnDisabledReporte, setBtnDisabledReporte] = useState(false);
  const [reporteGenerado, setReporteGenerado] = useState(false);
  const [newreporteGenerado, setNewReporteGenerado] = useState(false);
  const [reporteSinRegistros, setReporteSinRegistros] = useState(false);
  const [fechaInicial, setFechaInicial] = useState('');
  const [fechaFinal, setFechaFinal] = useState('');
  const idCoach = parseInt(sessionStorage.getItem('idUser') || 0);
  const [isXCoachReport, setIsXCoachReport] = useState(false);
  const idUsersXCoach = [48887, 2212088, 51308, 2090931];
  const [form, setForm] = useState({
    txtToSearch: sessionStorage.getItem('txtToSearchAsesores') || '',
    phoneToSearch: sessionStorage.getItem('phoneToSearchAsesores') || '',
  });
  const [formbc, setFormBC] = useState({
    nombre: '',
    rfc: '',
    historial: '',
  });
  const { txtToSearch } = form;
  const [responseBuro, setResponseBuro] = useState('');
  const [typeasesor, setTypeAsesor] = useState('');
  const [idasesoresdetails, setIdAsesoresDetails] = useState('');
  const [newreporte, setNewReporte] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });

    switch (name) {
      case 'txtToSearch':
        setBtnDisabled(!value.trim());
        break;
      case 'phoneToSearch':
        const newTabTraining = auxTabTraining.filter((e) => e.cellPhone.includes(value));
        setTabTraining(newTabTraining);
        break;
      default:
        break;
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setBtnDisabledReporte(false);
  };

  //! never used:  const handleDateChange = (date) => {};

  const handleModal = (e) => {
    const idTxt = e.target.innerText;
    handleClose();
    setOpenModal(true);
    if (idTxt === 'Reporte') {
      setNewReporte(false)
    } else if (idTxt === 'NewReporte') {
      setNewReporte(true);
    }
  };

  const consultarBC = () => {
    setRealizarConsultaBC(true);
  };

  const getReportCustomer = () => {
    const customerName = userInfo.basicInfo.fullName.replaceAll(' ', '_');
    const { idUser } = userInfo.basicInfo;
    const filename = `Reporte_${customerName}_IdUser_${idUser}`;
    data = [userInfo.basicInfo];
    const fieldsTmp = Object.keys(userInfo.basicInfo);
    fieldsTmp.map((e) => {
      fields[e] = e;
    });
    saveAsCsv({ data, fields, filename });
  };

  const generarReporte = () => {
    setProgress(0);
    setLoadingReporte(true);
    setBtnDisabledReporte(true);
    setReporteGenerado(false);
    setNewReporteGenerado(false);
    setReporteSinRegistros(false);

    getCountRecordsAdvisers({
      variables: {
        fechaInicial,
        fechaFinal,
      },
    });
  };

  const getUserInfo = ({ idUser, idCatAsesoresDetails }) => {
    sessionStorage.setItem('idUserToSearchAsesores', idUser);
    if(idCatAsesoresDetails === undefined || idCatAsesoresDetails === 'undefined') {
      idCatAsesoresDetails = '';
    } else {
      setIdAsesoresDetails(idCatAsesoresDetails);
    }
    setBtnDisabled(true);
    getUserForAdvisers({
      variables: {
        idUser: String(idUser),
        idCoach: String(idCoach),
        idCoachCont: sessionStorage.getItem('idCont'),
        idCatAsesoresDetails: String(idCatAsesoresDetails),
      },
    });
  };
  const buscar = (e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    setUserInfo();
    setIdAsesoresDetails('');
    setFechaFinal('');
    setLoading(true);
    setBtnDisabled(true);
    sessionStorage.setItem('txtToSearchAsesores', form.txtToSearch);
    sessionStorage.setItem('idUserToSearchAsesores', '');
    findUsersForAdvisers({
      variables: {
        txt: form.txtToSearch,
      },
    });
  };

  const getPendingSessions = () => {
    getPendingSessionsXCoach({
      variables: {
        idCoach: String(idCoach),
      },
    });
  };

  const setActiveTab = (index) => {
    const newArr = [...tabTraining];
    newArr.map((e, index) => {
      newArr[index].active = false;
    });
    if (index > -1) {
      newArr[index].active = true;
    }
    setTabTraining(newArr);
  };

  const [findUsersForAdvisers] = useLazyQuery(FIND_USERS_FOR_ADVISERS, {
    fetchPolicy: 'no-cache',
    onCompleted({ findUsersForAdvisers }) {
      if (findUsersForAdvisers.message === 'success') {
        setUsersFound(JSON.parse(findUsersForAdvisers.response));
        tabTraining.map((e) => (e.active = false));
      }
      setLoading(false);
      setBtnDisabled(false);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getUserForAdvisers] = useLazyQuery(GET_USER_FOR_ADVISERS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getUserForAdvisers }) {
      if (getUserForAdvisers.message === 'success') {
        setUserInfo(JSON.parse(getUserForAdvisers.response));
        setUsersFound([]);
        setDataBC();
        setLogBc(true);
        const idUserToSearch = sessionStorage.getItem('idUserToSearchAsesores');
        const indexFound = tabTraining.findIndex(
          (e) => e.idUser === idUserToSearch,
        );
        setActiveTab(indexFound);
      }
      setBtnDisabled(false);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPendingSessionsXCoach] = useLazyQuery(GET_PENDING_SESSIONS_XCOACH, {
    fetchPolicy: 'no-cache',
    onCompleted({ getPendingSessionsXCoach }) {
      if (getPendingSessionsXCoach.message === 'success') {
        const pendingSessions = JSON.parse(getPendingSessionsXCoach.response);

        const sessions = [];
        pendingSessions.map((e) => {
          sessions.push({
            idUser: e.idUser,
            idCatAsesoresDetails: e.idCatAsesoresDetails, 
            cellPhone: e.cellPhone,
            active: false,
          });
        });
        setTabTraining(sessions);
        setAuxTabTraining(sessions);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getCountRecordsAdvisers] = useLazyQuery(GET_COUNT_RECORDS_ADVISER, {
    fetchPolicy: 'no-cache',
    onCompleted({ getCountRecordsAdvisers }) {
      if (getCountRecordsAdvisers.message === 'success') {
        const idUsers = JSON.parse(getCountRecordsAdvisers.response);
        const arrays = [];
        const size = 10;

        for (let i = 0; i < idUsers.length; i += size) {
          arrays.push(idUsers.slice(i, i + size));
        }

        setArrays(arrays);
        setCountArrays(0);
        if (newreporte) {
          if (arrays.length > 0) {
            setNextNewReporte(true);
          } else {
            setReporteSinRegistros(true);
            setBtnDisabledReporte(false);
          }
        } else {
          if (arrays.length > 0) {
            setNext(true);
          } else {
            setReporteSinRegistros(true);
            setBtnDisabledReporte(false);
          }
        }
      }
    },
    onError(err) {
      console.log(err);
      setBtnDisabledReporte(false);
      setReporteSinRegistros(true);
    },
  });

  const [getReportAdvisers] = useLazyQuery(GET_REPORT_ADVISER, {
    fetchPolicy: 'no-cache',
    onCompleted({ getReportAdvisers }) {
      const calc = 100 / arrays.length;
      if (getReportAdvisers.message === 'success') {
        const newArray = JSON.parse(getReportAdvisers.response).flat();
        data = data.concat(newArray);
        setProgress(calc * (countArrays + 1));
        if (countArrays < arrays.length - 1) {
          setCountArrays(countArrays + 1);
          setNext(true);
        } else if (countArrays === arrays.length - 1) {
          let maxSize = 0;
          let posMaxSize = 0;
          let lastNameReport = 'ReporteAsesores';

          if (fechaInicial !== '' && fechaFinal !== '') {
            lastNameReport += `_${fechaInicial}_${fechaFinal}`;
          }

          setNameReport(lastNameReport);

          for (let i = 0; i < data.length; i++) {
            if (Object.keys(data[i]).length > maxSize) {
              maxSize = Object.keys(data[i]).length;
              posMaxSize = i;
            }
          }

          const fieldsTmp = Object.keys(data[posMaxSize]);
          fieldsTmp.map((e) => {
            fields[e] = e;
          });

          setLoadingReporte(false);
          setBtnDisabledReporte(true);
          setReporteGenerado(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getNewReportAdvisers] = useLazyQuery(GET_NEW_REPORT_ADVISER, {
    fetchPolicy: 'no-cache',
    onCompleted({ getNewReportAdvisers }) {
      const calc = 100 / arrays.length;
      if (getNewReportAdvisers.message === 'success') {
        const newArray = JSON.parse(getNewReportAdvisers.response).flat();
        Newdata = Newdata.concat(newArray);
        setProgress(calc * (countArrays + 1));
        if (countArrays < arrays.length - 1) {
          setCountArrays(countArrays + 1);
          setNextNewReporte(true);
        } else if (countArrays === arrays.length - 1) {
          let maxSize = 0;
          let posMaxSize = 0;
          let lastNameReport = 'ReporteNewAsesores';

          if (fechaInicial !== '' && fechaFinal !== '') {
            lastNameReport += `_${fechaInicial}_${fechaFinal}`;
          }

          setNameReport(lastNameReport);

          for (let i = 0; i < Newdata.length; i++) {
            if (Object.keys(Newdata[i]).length > maxSize) {
              maxSize = Object.keys(Newdata[i]).length;
              posMaxSize = i;
            }
          }

          const fieldsTmp = Object.keys(Newdata[posMaxSize]);
          fieldsTmp.map((e) => {
            newfields[e] = e;
          });

          setLoadingReporte(false);
          setBtnDisabledReporte(true);
          setNewReporteGenerado(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [LogBC] = useLazyQuery(LOGBC_GET_MONGO, {
    fetchPolicy: 'no-cache',
    onCompleted({ LogBCGet }) {
      if (LogBCGet.response !== null) {
        setUltimaFecha(LogBCGet.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (next) {
      setNext(false);
      getReportAdvisers({
        variables: {
          idUsers: arrays[countArrays],
        },
      });
    }
  }, [next]);

  useEffect(() => {
    if (nextnewreporte) {
      setNextNewReporte(false);
      getNewReportAdvisers({
        variables: {
          idUsers: arrays[countArrays],
        },
      });
    }
  }, [nextnewreporte]);

  useEffect(() => {
    if(Logbc){
      setLogBc(false);
      const mongoInput = {
        input: {
          collection: 'LogAuthentication',
          filter:`${userInfo.basicInfo.idUser}`,
        }
      }
      LogBC({ variables: mongoInput });
    }
    
  }, [Logbc])


  const logout = () => {
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickLogoutHeader',
          collection: 'sessions',
          timeStamp: new Date(),
        },
      },
    });
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/advisers';
  };

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (showTyping) {
      setTimeout(() => {
        setShowTyping(false);
        setUsersFound([]);
        setUserInfo();
        setIdAsesoresDetails('');
        setFechaFinal('');
        setForm({
          ...form,
          txtToSearch: '',
        });
        setBtnDisabled(true);
      }, 2000);
    }
  }, [showTyping]);

  useEffect(() => {
    setBtnDisabled(!txtToSearch.trim());
    if (txtToSearch.trim() !== '') {
      const idUserToSearchAsesores = sessionStorage.getItem('idUserToSearchAsesores') || '';
      if (idUserToSearchAsesores !== '') {
        getUserInfo({
          idUser: idUserToSearchAsesores,
          idCatAsesoresDetails: '',
        });
      } else {
        buscar();
      }
    }
  }, []);

  useEffect(() => {
    if (idUsersXCoach.includes(idCoach)) {
      setIsXCoachReport(true);
    }
  }, []);

  useEffect(() => {
    getPendingSessions();
  }, []);

  useEffect(() => {
    if (txtToSearch === '') {
      sessionStorage.setItem('txtToSearchAsesores', '');
      sessionStorage.setItem('idUserToSearchAsesores', '');
      setUsersFound([]);
      setUserInfo();
      setIdAsesoresDetails('');
      setUltimaFecha('');
      setActiveTab(-1);
    }
  }, [txtToSearch]);

  useEffect(() => {
    if (responseBuro !== '') {
      setDataBC(JSON.parse(responseBuro));
      setRealizarConsultaBC(false);
    }
  }, [responseBuro]);

  useEffect(() => {
    setTypeAsesor(sessionStorage.getItem('type'));
  }, []);

  return (
    <>
      <>
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              onClick={handleCloseModal}
            >
              X
            </ParagrapTwo>

            <UiCard
              positionM=" "
              heightM="150px"
              marginTop="-10px"
              marginTopM="88px"
            >
              <Rectangle13
                boxSha=" "
                margin="auto"
                bottomM="0px"
                displayM="block"
                position=" "
                topM="0px"
                height="156px"
                width="100%"
                widthm="100%"
                padding="19px 25px 20px 21px"
              >
                <TextoCoach height="auto" className="roboto" width="auto">
                  <b>Generación de reporte</b>
                  <br />
                  <br />
                </TextoCoach>
                <br />
                <TextField
                  id="date"
                  label="Fecha Inicio"
                  type="date"
                  value={fechaInicial}
                  onChange={(e) => setFechaInicial(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                <br />
                <TextField
                  id="date"
                  label="Fecha Fin"
                  type="date"
                  value={fechaFinal}
                  onChange={(e) => setFechaFinal(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                <br />
                <div className={classes.wrapper}>
                  <Button
                    id="btnGenerar"
                    name="btnGenerar"
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={btnDisabledReporte}
                    onClick={generarReporte}
                    fullWidth
                  >
                    Generar reporte
                  </Button>
                </div>
                {loadingReporte ? 'Generando...' : null}
                <br />
                <br />
                {loadingReporte && (
                  <CircularProgressWithLabel
                    size={40}
                    value={progress}
                    className={classes.buttonProgressWithLabel}
                  />
                )}
                {reporteGenerado ? (
                    <JsonToCsv
                      data={data}
                      filename={nameReport}
                      fields={fields}
                      style={style}
                      text={text}
                    />
                ) : null}
                {newreporteGenerado ? (
                    <JsonToCsv
                      data={Newdata}
                      filename={nameReport}
                      fields={newfields}
                      style={style}
                      text={text}
                    />
                ) : null}
                {reporteSinRegistros ? 'No se encontraron registros' : null}
              </Rectangle13>
            </UiCard>
          </div>
        </Modal>
      </>
      <Box style={{ overflow: 'hidden' }}>
        <Grid container>
          <Grid item xs={12}>
            <nav className="navbar navbar-dark bg-dark-coru">
              <a className="navbar-brand" href="/advisers-home">
                <img alt="" src={logo} width={150} className="logo" />
              </a>
              <h4
                className="navbar-nav ml-auto"
                style={{ color: '#f8f9fa', marginRight: '15px' }}
              >
                ¡ Bienvenido(a) asesor(a) {firstName}
                {fathersLastName} !
              </h4>

              <ul className="navbar-nav">
                <li className="nav-item" onClick={logout}>
                  <a className="btn btn-outline-light btn-block">
                    Cerrar sesión
                  </a>
                </li>
              </ul>
              {isXCoachReport ? (
                <ul className="navbar-nav">
                  <li>
                    <div>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} onClick={handleModal}>
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </li>
                </ul>
              ) : null}
            </nav>
          </Grid>
        </Grid>
        <ContainerSectionGeneral bckC="#ffff" wid="100%" padd="0px">
          <ContainerSection
            dis="flex"
            bckC="#ffff"
            fDir="row-reverse"
            wid="83.3%"
            hei="250px"
            marL="150px"
            padd="10px"
            marB="0px"
          >
            <ContainerBox marT="15px">
              <Buttons
                bcK="#3F4D67"
                onClick={getReportCustomer}
                disabled={!userInfo}
              >
                Descargar información del usuario
              </Buttons>

              {/* {!userInfo ? (
                <BtnDropDw text="Campañas Transaccionales" text2="Templates" />
              ) : null} */}
            </ContainerBox>

            <FormSesiones onSubmit={buscar}>
              <Grid container spacing={3} direction="column">
                <ContainerBox dis="flex" fDir="column" wid="280px" marT="15px">
                  <Subtitle className="roboto">Sesiones pendientes</Subtitle>
                  <TextField
                    id="phoneToSearch"
                    name="phoneToSearch"
                    value={form.phoneToSearch}
                    onChange={handleInputChange}
                    variant="outlined"
                    placeholder="Teléfono"
                  />
                </ContainerBox>

                <ContainerBox dis="flex" fDir="row" wid="85%">
                  <TextField
                    id="txtToSearch"
                    name="txtToSearch"
                    value={form.txtToSearch}
                    onChange={handleInputChange}
                    // label="ID User, E-mail o Teléfono"
                    variant="outlined"
                    placeholder="E-mail o Teléfono"
                    fullWidth
                  />
                  <ButtonsB
                    id="btnSearch"
                    name="btnSearch"
                    type="submit"
                    disabled={btnDisabled}
                    fullWidth
                  >
                    Buscar
                  </ButtonsB>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </ContainerBox>
              </Grid>
            </FormSesiones>
          </ContainerSection>

          <Grid container>
            <Grid item xs={12} md={1} style={{ margin: 10 }}>
              {!showTyping ? (
                <TrainingList
                  {...userInfo}
                  tabTraining={tabTraining}
                  setTabTraining={setTabTraining}
                  getUserInfo={getUserInfo}
                />
              ) : null}
            </Grid>
            {!userInfo && !showTyping ? (
              <Grid item xs={12} md={10} style={{ margin: 10 }}>
                <LocalizedUsers
                  usersFound={usersFound}
                  getUserInfo={getUserInfo}
                />
              </Grid>
            ) : null}

            {userInfo && !showTyping ? (
              <>
                <UserData
                  {...userInfo}
                  dataBC={dataBC}
                  consultarBC={consultarBC}
                  tabTraining={tabTraining}
                  setTabTraining={setTabTraining}
                  getUserInfo={getUserInfo}
                  ultimosDigitos={ultimosDigitos}
                  setUltimosDigitos={setUltimosDigitos}
                  bancoActual={bancoActual}
                  setBancoActual={setBancoActual}
                  typeasesor={typeasesor}
                  ultimafecha={ultimafecha}
                  setFormBC={setFormBC}
                  formbc={formbc}
                />

                <TrainingData {...userInfo} />

                <UnfinishedTraining
                  userInfo={userInfo}
                  tabTraining={tabTraining}
                  setTabTraining={setTabTraining}
                  setShowTyping={setShowTyping}
                  setTyping={setTyping}
                  setBtnDisabled={setBtnDisabled}
                  getPendingSessions={getPendingSessions}
                  idasesoresdetails={idasesoresdetails}
                />
              </>
            ) : null}
            {showTyping ? (
              <Grid item xs={10}>
                <TypingResult typing={typing} />
              </Grid>
            ) : null}
            {realizarConsultaBC && userInfo ? (
              <AuthenticateXCoach
                {...userInfo.basicInfo}
                responseBuro={responseBuro}
                setResponseBuro={setResponseBuro}
                ultimosDigitos={ultimosDigitos}
                bancoActual={bancoActual}
              />
            ) : null}
          </Grid>
        </ContainerSectionGeneral>
      </Box>
    </>
  );
};

export default Datos;
