import React, { useState, useEffect, Fragment } from 'react';
import Carousel from 'react-elastic-carousel';
import {} from '../common/Home.styled';
import SectionObten from './SectionObten';
import SectionAlcanza from './SectionAlcanza';
import SectionEntrenamientos from './SectionEntrenamientos';

function CarouselPrincipal({ initializeNow, initializeLogin }) {
  const [widthBrowser, setwidthBrowser] = useState(window.outerWidth);
  useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);
  return (
    <Fragment>
      {widthBrowser <= 700 ? (
        <Carousel
          styled={{ background: '#FAFAFA' }}
          transitionMs={1000}
          className={heigthBrowser >= 600 ? '' : 'CaruselC'}
        >
          <SectionObten
            initializeNow={initializeNow}
            initializeLogin={initializeLogin}
            heigthBrowser={heigthBrowser}
          />
          <SectionAlcanza heigthBrowser={heigthBrowser} />
          <SectionEntrenamientos heigthBrowser={heigthBrowser} />
        </Carousel>
      ) : (
        <Fragment>
          <SectionObten
            initializeNow={initializeNow}
            initializeLogin={initializeLogin}
            heigthBrowser={heigthBrowser}
          />
          <SectionAlcanza heigthBrowser={heigthBrowser} />
          <div id='Entrenamientos'></div>
          <SectionEntrenamientos heigthBrowser={heigthBrowser} />
        </Fragment>
      )}
    </Fragment>
  );
}

export default CarouselPrincipal;
