import React, { useState } from 'react';
import GloveBoxStar from '../../assets/img/anacoach/GloveBoxStar.svg';
import { Image } from '../../assets/styles/Home.styled';
import { Checkbox } from '@material-ui/core';

const GloveBoxCheckIn = ({items, edit}) => {

  const [checkedItems, setCheckedItems] = useState(items);

  const handleChange = (e) => {
    const index = checkedItems.findIndex(element => 
      element.title === e.target.value
    );
    const actualCheck = {
      title: e.target.value,
      isChecked: e.target.checked
    }

    let temporalItems = [...checkedItems];
    temporalItems[index] = actualCheck
    setCheckedItems(temporalItems);
  };
 
  return(
    <>
      {
        checkedItems.map(item => 
          <div key={item.title}>
            <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
              <div style={{width: '85%', display: 'flex', gap: '20px'}}>
                <div style={{width: '24px'}}>
                  <Image 
                    src={GloveBoxStar}
                    float=" "
                    position=" "
                    className="img-fluid"
                  />
                </div>
                <p className='roboto' style={{fontSize: '14px', margin: '0'}} >{item.title}</p>
              </div>
              <Checkbox
                color="default"
                checked={item.isChecked}
                disabled={edit}
                onChange={(e) => handleChange(e)}
                value={item.title}
              />
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: 'solid 1px #cdcdcd',
                margin: '12px 0'
              }}
            />
          </div>
        )
      }
    </>
  )
}

export default GloveBoxCheckIn;