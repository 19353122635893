import React from 'react'
import styled from "styled-components";
import { ContentGeneric, ImageFooter, TextGeneric } from '../components/common/common.styled'
import { ImgGeneric } from '../components/CaasHomeNew/Assets/Styles/CaasNew.styles'

/* assets */
import LogoAsegurador from '../assets/img/LogoAsegurador.svg'
import AseguradorAuto from '../assets/img/Asegurador/aseguradorAuto.svg'
import AseguradorFamily from '../assets/img/Asegurador/aseguradorFamily.svg'
import AseguradorGMayores from '../assets/img/Asegurador/aseguradorGMayores.svg'
import ImgAsegurador from '../assets/img/Asegurador/ImgAsegurador.svg'


const ContentAS = styled.div`
  align-selft: center;
  padding: 10px;
  width: 33%;
  height: 130px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 1);
  justify-content: center;
  @media (max-width: 768px) {
    width: 31%;
    height: auto !important;
  }
`;
const TextAS = styled.div`
  fontSize: 12px
  fontFamily: cabin;
  fontWeight: 600;
  margin: 10px auto 0;
  color: white;
  line-height: 17.5px;

  
`;

const Asegurador = () => {
  return (
    <ContentGeneric
      textAlign="center"
      justifyC="center"
      overflowY="auto"
      width="100%"
      height="100vh"
      position="fixed"
      padding="50px 10px"
      backgroundI="linear-gradient(359.08deg, #023085 2.31%, #0190F9 99.3%)"
    >
      <ImgGeneric src={LogoAsegurador} />
      <ContentGeneric maxWidth="540px" maxWidthSm="100%" margin="40px auto" justifyC="center">
        <TextGeneric fontColor="white" fontFamily="cabin" fontSizeSm="25px" fontSize="32px" fontWeight="700">
          Somos aseguradores, con más de 10 años de experiencia en el mercado
        </TextGeneric>
      </ContentGeneric>
      <ContentGeneric 
        maxWidth="490px" 
        maxWidthSm="100%" 
        dis="flex" 
        flexDirection="row" 
        margin="40px auto"
        gap="3.5%"
        gapSm="1%"
        justifyC="space-between"
      >
        <ContentAS>
          <ImgGeneric src={AseguradorAuto}/>
          <TextAS>Seguros de auto</TextAS>
        </ContentAS>
        <ContentAS>
          <ImgGeneric src={AseguradorFamily}/>
          <TextAS>Seguros de vida</TextAS>
        </ContentAS>
        <ContentAS>
          <ImgGeneric src={AseguradorGMayores}/>
          <TextAS>Seguros de gastos médicos mayores</TextAS>
        </ContentAS>
      </ContentGeneric>
      <ImgGeneric widthMds="100%" src={ImgAsegurador} />
      <ContentGeneric 
        position="fixed" 
        bottom="0px" 
        width="100%" 
        height="56px" 
        background="rgba(2, 48, 133, 1)"
        padding="17.5px 0px"
        
      >
        <TextGeneric fontFamily="inter" fontSize="16px" fontColor="white" margin="0px" >
          2023 Derechos Reservados.
        </TextGeneric>
      </ContentGeneric>
    </ContentGeneric>
  )
}
export default Asegurador;
