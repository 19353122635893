import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers/index';

import {
  CREATE_SESSION_MYSQL,
  CREATE_SESSION,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import BudgetSavings from '../Routines/BudgetSavings';
import SavingsPlan from '../Routines/SavingsPlan';
import QuickSavings from '../Routines/QuickSavings';
import SavingTools from '../Routines/SavingTools';
import ControlTooling from '../Routines/ControlTooling';
import RpMainGoalES from '../Routines/RpMainGoalES';
import OptionsToSave from '../Routines/OptionsToSave';
import NotificationSystem from '../Routines/NotificationSystem';
import LearnMoreSavings from '../Routines/LearnMoreSavings';

const RoutinesOrderEmergencySaving = ({
  Date,
  // setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
}) => {
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [plan, setPlan] = useState('');
  const [auxMainGoal, setAuxMainGoal] = useState('');
  const [optionTool, setOptionTool] = useState('');
  const [newTool, setNewtool] = useState(false);
  const [rp, setRp] = useState('');
  const handleRp = (val, mainGoal) => {
    setRp(val);
    setAuxMainGoal(mainGoal);
  };
  const handleNewtool = (val) => setNewtool(val);
  const handleOpcTool = (opc) => setOptionTool(opc);
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const {
      idUser, idCont, hostName, recovery, pageName,
    } = getQueryVariableHashtagDeeplink('funnel-tarjeta');
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
    );
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) { newSession({ variables: mongoInput }); }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    if ((orderRutine >= 7 && orderRutine <= 10) || orderRutine === 'rp') {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [orderRutine]);
  return (
    <div>
      {orderRutine >= 3 ? (
        <BudgetSavings
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
        />
      ) : null}
      <div id="rutina4"></div>
      {orderRutine >= 4 ? (
        <SavingsPlan
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          setPlan={setPlan}
          currentRoutine={4}
          nextOrderRoutine={5}
        />
      ) : null}
      {orderRutine <= 4
        ? sessionStorage.setItem('flagInicio', false)
        : sessionStorage.setItem('flagInicio', false)}
      {orderRutine >= 5 && plan === 'Terminar rapido' ? (
        <QuickSavings
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={'rp'}
          handleRp={handleRp}
        />
      ) : null}
      <div id="rutina6"></div>
      {orderRutine >= 6 ? (
        <SavingTools
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          nextOrderRoutine={7}
          possibleRoutines={[7, 8, 9, 10]}
          activePossibleroutines={true}
          handleOpcTool={handleOpcTool}
          newTool={newTool}
          handleNewtool={handleNewtool}
        />
      ) : null}
      {orderRutine >= 7 && optionTool === 'HerramientaControl' ? (
        <ControlTooling
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={7}
          handleOpcTool={handleOpcTool}
          handleNewtool={handleNewtool}
        />
      ) : null}
      <div id="rutina8"></div>
      {orderRutine >= 8 && optionTool === 'SistemaNotificacion' ? (
        <NotificationSystem
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={8}
          handleOpcTool={handleOpcTool}
          handleNewtool={handleNewtool}
        />
      ) : null}
      <div id="rutina9"></div>
      {orderRutine >= 9 && optionTool === 'AprenderMas' ? (
        <LearnMoreSavings
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={9}
          handleOpcTool={handleOpcTool}
          handleNewtool={handleNewtool}
        />
      ) : null}
      {optionTool === 'OpcionAhorrar' ? (
        <OptionsToSave
          Date={Date}
          hora={hora}
          currentTraining={currentTraining}
          setOrderRutine={setOrderRutine}
          handleOpcTool={handleOpcTool}
          handleNewtool={handleNewtool}
        />
      ) : null}
      {rp === 'rp' ? (
        <RpMainGoalES
          Date={Date}
          hora={hora}
          currentTraining={currentTraining}
          MainGoal={auxMainGoal}
        />
      ) : null}
    </div>
  );
};

export default RoutinesOrderEmergencySaving;
