import React from 'react';
import GetAddressByCp from './GetAddressByCp';

export default class InputAddree extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      autocomplete: {},
    };
  }

  componentDidMount() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(
      this.refs.autoCompletePlaces,
      { types: ['geocode'] },
    );
    autocomplete.setComponentRestrictions({ country: ['mx'] });
    autocomplete.addListener('place_changed', () => {
      const componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        country: 'long_name',
        postal_code: 'short_name',
      };
      const place = autocomplete.getPlace();
      for (const component in componentForm) {
        document.getElementById(component).bind = '';
        document.getElementById(component).disabled = false;
      }
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          const val = place.address_components[i][componentForm[addressType]];
          document.getElementById(addressType).value = val;
          console.log('Type', addressType, val);
          this.props.handleChangeAddress(addressType, val);
        }
      }
    });
    this.setState({ autocomplete });
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
      });
    }
  }

  render() {
    const {
      data,
      names,
      form,
      handleChange,
      handleChangeAddress,
      handleChangeQuestionsSend,
    } = this.props;
    let locationField;
    let MapsGeo;
    let street;
    let numExt;
    let numInt;
    let cp;
    let municipio;
    let estado;
    const styleInput = {
      width: '100%',
      marginBottom: '10px',
    };
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('#autocomplete').forEach((node) => node.addEventListener('keypress', (e) => {
        document.getElementById('street_number').value = '';
        document.getElementById('route').value = '';
        document.getElementById('locality').value = '';
        document.getElementById('administrative_area_level_1').value = '';
        document.getElementById('postal_code').value = '';
        document.getElementById('country').value = '';
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      }));
    });

    return (
      <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
        <div className="col-md-4 col-web"></div>
        <div className="col-md-4 col-web-tam">
          {data.map((routine, indx) => {
            if (indx === 0) {
              locationField = (
                <input
                  style={styleInput}
                  id="autocomplete"
                  autoComplete="off"
                  name={names[indx]}
                  label={routine.QuestionName}
                  required
                  fullWidth
                  placeholder={routine.QuestionName}
                  className="textInput w-100 form-control adress-input"
                  onFocus={this.geolocate}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  onChange={handleChange}
                  ref="autoCompletePlacesRemove"
                />
              );
            } else if (indx === 1) {
            } else if (indx === 2) {
              street = (
                <input
                  id="route"
                  autoComplete="off"
                  name={names[indx]}
                  label={routine.QuestionName}
                  required
                  fullWidth
                  value={form[names[indx]]}
                  placeholder={routine.QuestionName}
                  onChange={handleChange}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  className="textInput w-100 form-control adress-input"
                />
              );
            } else if (indx === 3) {
              numExt = (
                <input
                  autoComplete="off"
                  name={names[indx]}
                  label={routine.QuestionName}
                  required
                  fullWidth
                  value={form[names[indx]]}
                  placeholder={routine.QuestionName}
                  onChange={handleChange}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  className="textInput w-100 form-control adress-input"
                />
              );
            } else if (indx === 4) {
              numInt = (
                <input
                  autoComplete="off"
                  name={names[indx]}
                  label={routine.QuestionName}
                  required
                  fullWidth
                  value={form[names[indx]]}
                  placeholder={routine.QuestionName}
                  onChange={handleChange}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  className="textInput w-100 form-control adress-input"
                />
              );
            } else if (indx === 5) {
              cp = (
                <input
                  id="postal_code"
                  autoComplete="off"
                  name={names[indx]}
                  maxLength="5"
                  label={routine.QuestionName}
                  required
                  fullWidth
                  value={form[names[indx]]}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  placeholder={routine.QuestionName}
                  onChange={handleChange}
                  className="textInput w-100 form-control adress-input"
                />
              );
            } else if (indx === 9) {
              estado = (
                <input
                  name={names[indx]}
                  label={routine.QuestionName}
                  required
                  fullWidth
                  value={form[names[indx]]}
                  placeholder={routine.QuestionName}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  onChange={handleChange}
                  className="textInput w-100 form-control adress-input estado"
                />
              );
            } else if (indx === 8) {
              municipio = (
                <input
                  placeholder="Estado"
                  id="administrative_area_level_1"
                  name={names[indx]}
                  label={routine.QuestionName}
                  required
                  fullWidth
                  value={form[names[indx]]}
                  onChange={handleChange}
                  onBlur={(e) => handleChangeQuestionsSend(
                    `${routine.QuestionsOrder}`,
                    names[indx],
                    e.target.value,
                    'OpenField',
                  )
                  }
                  className="textInput w-100 form-control adress-input municipio"
                />
              );
            }
          })}
          <div id="locationField" autoComplete="off">
            {/* {locationField} */}
          </div>
          <div className="w-100">
            {cp}
            <div className="selectParentAddress">
              <select
                className="form-control adress-input"
                name="colonia"
                onChange={handleChange}
              >
                {form.cp !== '' ? (
                  <GetAddressByCp
                    handleChangeAddress={handleChangeAddress}
                    ZipCode={form.cp}
                    form={form}
                  />
                ) : (
                  <option>Selecciona una opción</option>
                )}
              </select>
            </div>
            {street}
            {MapsGeo}

            {numExt}
            {numInt}

            {municipio}
            {estado}
          </div>
          <div className="d-none">
            <table id="address" style={styleInput}>
              <tbody>
                <tr>
                  <td>
                    <input
                      placeholder="Direccion 1"
                      id="street_number"
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input placeholder="Ciudad" id="locality" disabled={true} />
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>
                    <input placeholder="Pais" id="country" disabled={true} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-4 col-web"></div>
      </div>
    );
  }
}
