import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import Cobertura from '../../assets/img/anacoach/Training/Comparativo/Cobertura.svg';
import Deducible from '../../assets/img/anacoach/Training/Comparativo/Deducible.svg';
import Auto from '../../assets/img/anacoach/Training/Comparativo/Auto.svg';
import Lapiz from '../../assets/img/anacoach/Training/Comparativo/Lapiz.svg';

import CoberturaA from '../../assets/img/anacoach/Training/Comparativo/CoberturaA.svg';
import CoberturaL from '../../assets/img/anacoach/Training/Comparativo/CoberturaL.svg';
import CoberturaB from '../../assets/img/anacoach/Training/Comparativo/CoberturaB.svg';

import PagoC from '../../assets/img/anacoach/Training/Comparativo/PagoC.svg';
import PagoT from '../../assets/img/anacoach/Training/Comparativo/PagoT.svg';
import PagoS from '../../assets/img/anacoach/Training/Comparativo/PagoS.svg';
import PagoA from '../../assets/img/anacoach/Training/Comparativo/PagoA.svg';

import DeducibleB from '../../assets/img/anacoach/Training/Comparativo/DeducibleB.svg';
import DeducibleM from '../../assets/img/anacoach/Training/Comparativo/DeducibleM.svg';
import DeducibleA from '../../assets/img/anacoach/Training/Comparativo/DeducibleA.svg';
// import loader from '../../assets/img/anacoach/loading.svg';

import ProductoCI from './ProductoCI';
import { Col12, Image } from '../common/common.styled';
import { validationsCreateTicket } from '../../Helpers/general';
import ModalVirality from '../../components/common/ModalVirality';


import {
  TrainingDivSection,
  TrainingSubtitles,
  DivTamProduct,
  DivFiltersProduct,
  FlexDatProduct,
  DivFlexProduct,
  TitleDatProduct,
  InfoVehicle,
  DisplayFilters,
  DivLoader,
  Images,
  TrainingText,
} from '../../assets/styles/Training.styled';
import { WEB_SERVICE_CI } from '../../mutations';

const CotizaCompara = ({ logros = false }) => {
  const history = useHistory();
  const [cobertura, setCobertura] = useState('AMPLIA');
  const [formaPago, setFormaPago] = useState('CONTADO');
  const [deducible, setDeducible] = useState('5%-10%');
  const [deducibleMin, setDeducibleMin] = useState('5');
  const [deducibleMax, setDeducibleMax] = useState('10');
  const [insurances, setInsurance] = useState([]);
  const formaDePago = {
    CONTADO: '0',
    SEMESTRAL: '2',
    TRIMESTRAL: '3',
    MENSUAL: '4',
  };
  const [webServiceCI] = useMutation(WEB_SERVICE_CI, {
    onCompleted({ webServiceCI }) {
      // const tmp_array = [];
      let response = {};
      switch (webServiceCI.message) {
        case 'cotizar':
          response = JSON.parse(webServiceCI.response);
          const insurances_first = [];
          const insurance_last = [];
          for (const insurance of response) {
            const price = insurance.Cotizacion.PrimaTotal;
            if (price !== '' && price != null) {
              insurances_first.push(insurance);
              sessionStorage.setItem(
                'price'.concat(insurance.Aseguradora),
                price,
              );
            } else {
              insurance_last.push(insurance);
            }
          }
          if (insurances_first.length > 0) {
            insurances_first.sort(
              (a, b) => parseFloat(a.Cotizacion.PrimaTotal)
                - parseFloat(b.Cotizacion.PrimaTotal),
            );
          }
          Array.prototype.push.apply(insurances_first, insurance_last);
          setInsurance(insurances_first);
          sessionStorage.getItem('idTicket')
            ? actionTicket('putTicket')
            : actionTicket('postTicket');
          break;
        case 'postTicket':
          const idTicket = webServiceCI.response;
          sessionStorage.setItem('idTicket', idTicket);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    setInsurance([]);
    const birthdate = sessionStorage.getItem('Birthdate').split('-');
    const name = sessionStorage.getItem('FirstName');
    const fechaNac = `${birthdate[2]}/${birthdate[1]}/${birthdate[0]}`;
    const cpostal = sessionStorage.getItem('ZipCode');
    const edad = '32';
    const genero = sessionStorage.getItem('Gender');
    const telefono = sessionStorage.getItem('CellPhone');
    const email = sessionStorage.getItem('Email1');
    const tipouso = 'Personal o Familiar';
    const Year = sessionStorage.getItem('Year');
    const Marca = sessionStorage.getItem('Marca');
    const Descripcion = sessionStorage.getItem('cadena').replaceAll('|', ' ');
    const data = `{'Aseguradora':'<%aseguradora%>','Cliente':{'TipoPersona':null,'Nombre':'${name}','ApellidoPat':null,'ApellidoMat':null,'RFC':null,'FechaNacimiento':'${fechaNac}','Ocupacion':null,'CURP':null,'Direccion':{'Calle':null,'NoExt':null,'NoInt':null,'Colonia':null,'CodPostal':'${cpostal}','Poblacion':null,'Ciudad':null,'Pais':null},'Edad':${edad},'Genero':'${genero}','Telefono':'${telefono}','Email':'${email}'},'Vehiculo':{'Uso':'${tipouso}','Marca':'${Marca}','Modelo':'${Year}','NoMotor':'','NoSerie':'','NoPlacas':'','Descripcion':'${Descripcion}','CodMarca':'','CodDescripcion':'','CodUso':'','Clave':'<%clave%>','Servicio':'PARTICULAR'},'Coberturas':[{'DanosMateriales':'${deducibleMin}','DanosMaterialesPP':'-','RoboTotal':'${deducibleMax}','RCBienes':'-','RCPersonas':'-','RC':'-','RCFamiliar':null,'RCExtension':'-','RCExtranjero':'-','RCPExtra':'-','AsitenciaCompleta':'-','DefensaJuridica':'-','GastosMedicosOcupantes':'-','MuerteAccidental':'-','GastosMedicosEvento':'-','Cristales':'-'}],'Paquete':'${cobertura}','Descuento':'0','PeriodicidadDePago':${formaDePago[formaPago]},'Cotizacion':{'PrimaTotal':null,'PrimaNeta':null,'Derechos':null,'Impuesto':null,'Recargos':null,'PrimerPago':null,'PagosSubsecuentes':null,'IDCotizacion':null,'CotID':null,'VerID':null,'CotIncID':null,'VerIncID':null,'Resultado':null},'Emision':{'PrimaTotal':null,'PrimaNeta':null,'Derechos':null,'Impuesto':null,'Recargos':null,'PrimerPago':null,'PagosSubsecuentes':null,'IDCotizacion':null,'Terminal':null,'Documento':null,'Poliza':null,'Resultado':null},'Pago':{'MedioPago':null,'NombreTarjeta':null,'Banco':null,'NoTarjeta':null,'MesExp':null,'AnioExp':null,'CodigoSeguridad':null,'NoClabe':null,'Carrier':0},'CodigoError':null,'urlRedireccion':null}`;
    console.log(data);
    const paramJson = {
      data,
      description: sessionStorage.getItem('cadena'),
    };
    const param = JSON.stringify(paramJson);
    console.log(param);
    const inputGetModels = {
      input: {
        action: 'cotizar',
        param,
      },
    };
    webServiceCI({ variables: inputGetModels });
  }, [deducible, formaPago, cobertura]);

  const actionTicket = (action) => {
    const extraZendesk = validationsCreateTicket();
    const birthdate = sessionStorage.getItem('Birthdate').split('-');
    const name = sessionStorage
      .getItem('FirstName')
      .concat(` ${sessionStorage.getItem('MiddleName')}`);
    const pname = sessionStorage.getItem('FirstName');
    const sname = sessionStorage.getItem('MiddleName');
    const apat = sessionStorage.getItem('FathersLastName');
    const amat = sessionStorage.getItem('MothersLastName');
    const fechaNac = `${birthdate[2]}/${birthdate[1]}/${birthdate[0]}`;
    const cpostal = sessionStorage.getItem('ZipCode');
    const edad = '32';
    const genero = sessionStorage.getItem('Gender');
    const telefono = sessionStorage.getItem('CellPhone');
    const email = sessionStorage.getItem('Email1');
    const tipouso = 'Personal o Familiar';
    const Year = sessionStorage.getItem('Year');
    const Marca = sessionStorage.getItem('Marca');
    const Descripcion = sessionStorage.getItem('cadena').replaceAll('|', '/');
    const nombreCompleto = `${sessionStorage.getItem(
      'FirstName',
    )} ${sessionStorage.getItem('FathersLastName')} ${sessionStorage.getItem(
      'MothersLastName',
    )}`;
    const idcont = sessionStorage.getItem('idCont');
    const url = window.location.host;
    const Model = sessionStorage.getItem('Model');
    const Submodel = sessionStorage.getItem('Submodel');
    const Detail = sessionStorage.getItem('Detail');
    const Diferenciador = sessionStorage.getItem('Diferenciador');
    const Transmision = sessionStorage.getItem('Transmision');
    const costoAba = sessionStorage.getItem('priceABA') || '';
    const costoAxa = sessionStorage.getItem('priceAXA') || '';
    const costoAna = sessionStorage.getItem('priceANA') || '';
    const costoBanorte = sessionStorage.getItem('priceBANORTE') || '';
    const costoGnp = sessionStorage.getItem('priceGNP') || '';
    const costoQualitas = sessionStorage.getItem('priceQUALITAS') || '';
    const costoMapfre = sessionStorage.getItem('priceMAPFRE') || '';
    const costoHdi = sessionStorage.getItem('priceHDI') || '';
    const codPromocion = sessionStorage.getItem('priceABA') || '';

    const camposZend = `personal_o_familiar:360000541771,${extraZendesk}${nombreCompleto}:360000234128,física_:360000234068,car_insurance:360000348447,${Year}:360001173851,${Marca}:360000510988,${genero}:360000289967,${edad}:360000510948,${name}:360000505467,${cpostal}:360001174731,step_4:360000516192, solicitud_electrónica:360000513032, cotización:360000541051, página_web_:360000540451, abandono_cotización:360001180892, automóviles_individuales:360000515172,${Descripcion}:360000541971,${codPromocion}: 360000195588,${fechaNac}:360000326427,${pname}:360000357948,${sname}:360000357968,${apat}:360000348607,${amat}:360000357988,${telefono}:360000226708,${idcont}:360011832813&`;
    const configTicket = 'usuario:,asunto:Oportunidad seguro de auto,grupo:360000115672,idForm:360000005171,prioridad:Normal,estatus:New,tipo:question';
    const camposForm = `Url:${url},Nombre:${name},RFC:,Fecha de nacimiento:${fechaNac},email:${email},Telefono:${telefono},Edad:${edad},Genero:${genero},Uso:${tipouso},Anio:${Year},Marca:${Marca},Descripcion:${Model},Subdescripcion:${Submodel},Detalle:${Detail},Diferenciador:${Diferenciador},Transmisión:${Transmision},Codigo postal:${cpostal},ABA:${costoAba},AXA:${costoAxa},ANA:${costoAna},BANORTE:${costoBanorte},GNP:${costoGnp},QUALITAS:${costoQualitas},MAPFRE:${costoMapfre},HDI:${costoHdi},Agente asignado:DIRECTO COMPARAGURU`
      + '&';
    let encodedString = btoa(
      `camposZend=${camposZend}camposForm=${camposForm}configTicket=${configTicket}`,
    );
    console.log(
      `camposZend=${camposZend}camposForm=${camposForm}configTicket=${configTicket}`,
    );
    if (action === 'putTicket') {
      const idTicket = sessionStorage.getItem('idTicket');
      encodedString = btoa(
        `IdTicket=${idTicket}&camposZend=${camposZend}camposForm=${camposForm}configTicket=${configTicket}`,
      );
    }
    const inputGetModels = {
      input: {
        action,
        param: encodedString,
      },
    };
    webServiceCI({ variables: inputGetModels });
  };

  const changeCobertura = (cob) => {
    sessionStorage.setItem('Paquete', cob);
    setCobertura(cob);
    setOpenCobertura(false);
  };

  const changePago = (pago) => {
    setFormaPago(pago);
    setOpenFormaPago(false);
  };

  const changeDeducible = (ded) => {
    setDeducible(ded);
    const arrayDeducible = ded.split('-');
    setDeducibleMin(arrayDeducible[0].replace('%', ''));
    setDeducibleMax(arrayDeducible[1].replace('%', ''));
    setOpenDeducibles(false);
  };

  const [openCobertura, setOpenCobertura] = useState(false);
  const [openFormaPago, setOpenFormaPago] = useState(false);
  const [openDeducibles, setOpenDeducibles] = useState(false);

  const openFilters = (filter) => {
    if (filter === 'Cobertura') {
      setOpenCobertura(!openCobertura);
    } else if (filter === 'FormaPago') {
      setOpenFormaPago(!openFormaPago);
    } else if (filter === 'Deducibles') {
      setOpenDeducibles(!openDeducibles);
    }
  };
  const selectInsurance = (insuranceSelect) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'selectInsurance',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const idTicket = sessionStorage.getItem('idTicket');
    const email = sessionStorage.getItem('Email1');
    const name = `${sessionStorage.getItem(
      'FirstName',
    )} ${sessionStorage.getItem('FathersLastName')} ${sessionStorage.getItem(
      'MothersLastName',
    )}`;
    const cobertura = sessionStorage.getItem('Paquete') || 'AMPLIA';
    sessionStorage.setItem('insuranceSelect', JSON.stringify(insuranceSelect));
    window.location.hash = `#${email}`;
    let urlSend = window.location.href;
    const array = urlSend.split('//');
    urlSend = array[1];
    const urlsend2 = urlSend.replace(/:/g, '-');
    urlSend = urlsend2;
    window.location.hash = '';

    const aseguradora = insuranceSelect.Aseguradora;
    sessionStorage.setItem('AsegSelected', aseguradora);
    let selectedAseg = '';
    if (aseguradora === 'HDI') {
      selectedAseg = 'hdi_cg_10__-_67366:360000234828';
    } else if (aseguradora === 'BANORTE') {
      selectedAseg = 'banorte_cg_10__-_17342:360000234828';
    } else if (aseguradora === 'GNP') {
      selectedAseg = 'gnp_cg_10__-_16260:360000234828';
    } else if (aseguradora === 'QUALITAS') {
      selectedAseg = 'qualitas_cg_10__-_74462:360000234828';
    } else if (aseguradora === 'MAPFRE') {
      selectedAseg = 'mapfre_cg_10__-_66132:360000234828';
    } else if (aseguradora === 'ANA') {
      selectedAseg = 'ana_cg_10__-_13308:360000234828';
    } else if (aseguradora === 'AXA') {
      selectedAseg = 'axa_cg_10__-_628331:360000234828';
    } else if (aseguradora === 'ABA') {
      selectedAseg = 'aba_cg_10__-_94328:360000234828';
    } else {
      console.log('No hay coincidencias con aseguradora');
    }
    const encodedString = btoa(
      `IdTicket=${idTicket}&camposZend=`
        + `4__abandono_checkout:360001180892,${selectedAseg},${urlSend}:360011640113&camposForm=Nombre:${name},email:${email},Cobertura:${cobertura},Aseguradora Seleccionada:${aseguradora},Url:${urlSend}`,
    );
    const inputGetModels = {
      input: {
        action: 'putTicket',
        param: encodedString,
      },
    };
    webServiceCI({ variables: inputGetModels });
    history.push('/success-ci');
  };
  return (
    <TrainingDivSection
      backw="#f8f9fa00"
      padding="0px"
      paddw="0px"
      mb="0"
      wi={logros ? '100%' : '90%'}
      wiw="100%"
      he="auto"
      hew="auto"
    >
      {insurances.length > 0 ? (
        <TrainingDivSection
          mb="5px"
          mbw="5px"
          bsha="0px 2px 8px rgba(0,0,0,0.08)"
          bshaw="0px 2px 8px rgba(0,0,0,0.08)"
          back="#FFFFFF;"
          backw="#FFFFFF;"
          mlw={logros ? '10%' : '18%'}
          paddw="0"
          wiw={logros ? '75%' : '60%'}
          padding="0px"
        >
          <div style={{ backgroundColor: '#ECECEC', padding: '20px 0' }}>
            <DivFlexProduct mt="">
              <TitleDatProduct>
                <TrainingSubtitles
                  size="16px"
                  sizew="18px"
                  fw="bold"
                  align="left"
                  alignweb="left"
                  className="cabin"
                >
                  Cotiza y compara
                </TrainingSubtitles>
              </TitleDatProduct>
              <DivTamProduct wi="58%">
                <FlexDatProduct>
                  <Image
                    src={Auto}
                    zindex="1"
                    width="55px"
                    height="auto"
                    position=""
                    bottom="auto"
                    mbMob="auto"
                    marginTop="auto"
                    marginLeft="auto"
                    marginTopM="auto"
                    marginLeftM="auto"
                    marginTopM="auto"
                    marginRight="auto"
                  ></Image>
                  <InfoVehicle>
                    <p
                      style={{ fontWeight: 'bold', marginBottom: '0.2rem' }}
                    >{`${sessionStorage.getItem(
                      'Marca',
                    )} ${sessionStorage.getItem(
                      'Model',
                    )}, ${sessionStorage.getItem('Year')}`}</p>
                    <p style={{ marginBottom: '0' }}>
                      {sessionStorage.getItem('Submodel')}{' '}
                      {sessionStorage.getItem('Detail')}
                    </p>
                    <p style={{ marginBottom: '0' }}>
                      {sessionStorage.getItem('Diferenciador')}{' '}
                      {sessionStorage.getItem('Transmision')}
                    </p>
                    <Image
                      src={Lapiz}
                      zindex="1"
                      width="10px"
                      height="auto"
                      position=""
                      bottom="auto"
                      mbMob="auto"
                      marginTop="-25px"
                      marginLeft="95%"
                      marginTopM="-25px"
                      marginLeftM="95%"
                      marginTopM="auto"
                      marginRight="auto"
                    ></Image>
                  </InfoVehicle>
                </FlexDatProduct>
              </DivTamProduct>
            </DivFlexProduct>
            <DivFlexProduct padd="0">
              <DivTamProduct
                wi="30%"
                ta="center"
                style={{ cursor: 'pointer' }}
                onClick={() => openFilters('Cobertura')}
              >
                <span>Cobertura</span>
                <DivFiltersProduct>
                  <Image
                    src={
                      cobertura === 'AMPLIA'
                        ? CoberturaA
                        : cobertura === 'LIMITADA'
                          ? CoberturaL
                          : cobertura === 'BASICA'
                            ? CoberturaB
                            : Cobertura
                    }
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>{cobertura}</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct
                wi="30%"
                ta="center"
                onClick={() => openFilters('FormaPago')}
              >
                <span>Forma de pago</span>
                <DivFiltersProduct
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    src={
                      formaPago === 'CONTADO'
                        ? PagoC
                        : formaPago === 'TRIMESTRAL'
                          ? PagoT
                          : formaPago === 'SEMESTRAL'
                            ? PagoS
                            : PagoA
                    }
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft=" "
                    marginTopM="10px"
                    marginLeftM=" "
                  ></Image>
                  <p>{formaPago}</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct
                wi="30%"
                ta="center"
                onClick={() => openFilters('Deducibles')}
              >
                <span>Deducibles</span>
                <DivFiltersProduct
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    src={
                      deducible === '3%-5%'
                        ? DeducibleB
                        : deducible === '5%-10%'
                          ? DeducibleM
                          : deducible === '10%-20%'
                            ? DeducibleA
                            : Deducible
                    }
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft=" "
                    marginTopM="10px"
                    marginLeftM=" "
                  ></Image>
                  <p>{deducible}</p>
                </DivFiltersProduct>
              </DivTamProduct>
            </DivFlexProduct>
            <DisplayFilters
              id="cambioCoberturas"
              className={openCobertura ? '' : 'hidden'}
              ml="13px"
            >
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changeCobertura('AMPLIA')}>
                  <Image
                    src={CoberturaA}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Amplia</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changeCobertura('LIMITADA')}>
                  <Image
                    src={CoberturaL}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Limitada</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changeCobertura('BASICA')}>
                  <Image
                    src={CoberturaB}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Básica</p>
                </DivFiltersProduct>
              </DivTamProduct>
            </DisplayFilters>
            <DisplayFilters
              id="cambioFormaPago"
              className={openFormaPago ? '' : 'hidden'}
              mlM="32%"
              ml="154px"
            >
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changePago('CONTADO')}>
                  <Image
                    src={PagoC}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Contado</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changePago('TRIMESTRAL')}>
                  <Image
                    src={PagoT}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Trimestral</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changePago('SEMESTRAL')}>
                  <Image
                    src={PagoS}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Semestral</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changePago('MENSUAL')}>
                  <Image
                    src={PagoA}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>Mensual</p>
                </DivFiltersProduct>
              </DivTamProduct>
            </DisplayFilters>
            <DisplayFilters
              id="cambioDeducibles"
              className={openDeducibles ? '' : 'hidden'}
              ml="294px"
              mlM="63%"
            >
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changeDeducible('3%-5%')}>
                  <Image
                    src={DeducibleB}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>3% - 5%</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changeDeducible('5%-10%')}>
                  <Image
                    src={DeducibleM}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>5% - 10%</p>
                </DivFiltersProduct>
              </DivTamProduct>
              <DivTamProduct wi="100%" ta="center">
                <DivFiltersProduct onClick={() => changeDeducible('10%-20%')}>
                  <Image
                    src={DeducibleA}
                    zindex="1"
                    width="52px"
                    height="auto"
                    position=""
                    marginTop="10px"
                    marginLeft="auto"
                    marginTopM="10px"
                    marginLeftM="auto"
                  ></Image>
                  <p>10% - 20%</p>
                </DivFiltersProduct>
              </DivTamProduct>
            </DisplayFilters>
          </div>
          <Col12
            id="CarruselEmpresasContentHome"
            style={{ backgroundColor: '#ececec' }}
            ptMobile="0"
            pMobile="0"
            pt="0px"
            flex="0 0 100%"
            maxwidth="100%"
            display="flex"
          >
            {/*<Carousel
              id="CarruselMetasLogros"
              style={{ backgroundColor: '#ececec' }}
              transitionMs={1000}
            >*/}
              {insurances.map((insurance, index) => (
                <>
                  <ModalVirality />
                  <ProductoCI
                    insurance={insurance}
                    cobertura={cobertura}
                    index={index}
                    selectInsurance={selectInsurance}
                  />
                  <br/>
                </>
              ))}
            {/*</Carousel>*/}
          </Col12>
        </TrainingDivSection>
      ) : (
        <div>
          <DivLoader
            ta="left"
            margin="0 20px 0 0"
            ml="62%"
            mlw="15%"
            wi="110px"
            he="32px"
          >
            <Images
                    src={''}
                    top="-32px"
              topweb=""
              left="2px"
              leftw="0"
              width="95px"
              position="absolute"
              className="img-fluid rotate180"
            ></Images>
          </DivLoader>
          <DivLoader
            bcolor="transparent"
            ta="left"
            margin="0 20px 0 0"
            ml="62%"
            mlw="15%"
            wi="110px"
            he="32px"
          >
            <TrainingText
              size="11px"
              sizew="12px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
              color="#A6A6A6"
            >
              Escribiendo...
            </TrainingText>
          </DivLoader>
        </div>
      )}
    </TrainingDivSection>
  );
};

export default CotizaCompara;
