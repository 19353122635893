import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import DateCountdown from 'react-date-countdown-timer';
import { imageComponent } from '../components/common/common';
import {CardsBtn} from '../assets/styles/Training.styled';
import { Image } from '../assets/styles/Home.styled';
import ImageLogo from '../assets/img/anacoach/CoruLogoB.png';
import ImageBack1 from '../assets/img/WaitlistKanda/Background1.svg';
import ImageBack2 from '../assets/img/WaitlistKanda/Background2.svg';
import Elements from '../assets/img/WaitlistKanda/Elementos_Waitlist.svg';
import Star from '../assets/img/WaitlistKanda/star.svg';
import User from '../assets/img/WaitlistKanda/Profile.svg';
import BackFooter from '../assets/img/WaitlistKanda/BackFooter.svg';
import Thankyou from '../assets/img/WaitlistKanda/Thanks.svg';
import Facebook from '../assets/img/WaitlistKanda/Facebook.svg';
import Share from '../assets/img/WaitlistKanda/Share.svg';
import Link from '../assets/img/WaitlistKanda/Link.svg';
import Whatsapp from '../assets/img/WaitlistKanda/Whatsapp.svg';
import Messenger from '../assets/img/WaitlistKanda/Messenger.svg';
import RSS from '../assets/img/WaitlistKanda/Fondo.svg';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
	Button,
	ParagrapTwo,
  } from '../components/common/common.styled';
import { useMutation } from '@apollo/client';
import { SAVE_KANDA_WAITLIST } from '../mutations';
import '../assets/styles/Home.css';

// Modal
import ClickButton from '../Helpers/HookHelpers';
import { Helmet } from 'react-helmet';
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

const ImageUser = styled(imageComponent)`
	position: static;
	width: 48px;
	height: 48px;
	margin-top: -16px;
`;
const ImageBack = styled(imageComponent)`
	position: absolute;
	min-width: 100%;
	height: 760.05px;
	left: 0;
	top: -198px;
	z-index: -1;
`;
const ImageBackS2 = styled(imageComponent)`
	position: absolute;
	width: 869px;
	height: 830px;
	right: 0;
    bottom: 0;
	z-index: -1;
`;
const ImageAvatars = styled(imageComponent)`
	position: absolute;
	width: 644px;
	height: 378px;
	left: 35px;
	top: 96px;
	@media (max-width: 769px) {
        display: none;
    }
`;
const ImageAvatarsMob = styled(imageComponent)`
	display: none;
	@media (max-width: 769px) {
		display: block;
        position: relative;
		width: 100%;
		height: auto;
		left: 0;
		top: 25px;
    }
`;
const ImageStar = styled(imageComponent)`
	width: 30px;
	height: 30px;
	margin: auto;
	z-index: -1;
`;
const ImageShare1 = styled(imageComponent)`
	position: relative;
	left: 15.07%;
	right: 75.56%;
	top: 7.78%;
	bottom: 68.26%;
	cursor: pointer;
`;
const ImageShare2 = styled(imageComponent)`
	position: relative;
	left: 11.46%;
	right: 58.16%;
	top: 110.89%;
	bottom: 19.16%;
	cursor: pointer;
	@media (max-width: 769px) {
		position: relative;
		left: -25.54%;
		right: 0;
		top: 88%;
		bottom: 0;
		cursor: pointer;
	}
`;
const ImageShare3 = styled(imageComponent)`
	position: relative;
	left: 42%;
	right: 5.99%;
	top: 9.88%;
	bottom: 66.17%;
	cursor: pointer;
	@media (max-width: 769px) {
		position: relative;
		left: 25%;
		right: 0;
		top: 28.88%;
		bottom: 0;
		cursor: pointer;
	}
`;
const ImageShare4 = styled(imageComponent)`
	position: relative;
	width: 80.2px;
	height: 80px;
	left: 21%;
    top: 124%;
	cursor: pointer;
	@media (max-width: 769px) {
		position: relative;
		width: 80.2px;
		height: 80px;
		left: -23%;
		top: 79%;
		cursor: pointer;
	}
`;
const ImageShare5 = styled(imageComponent)`
	position: relative;
	width: 80.2px;
	height: 80px;
	left: -42%;
    top: 140%;
	cursor: pointer;
`;
const ImageThanks = styled(imageComponent)`
	position: absolute;
	width: 304px;
	height: 260.43px;
	left: 947px;
	top: 255px;
	z-index: -1;
`;
const ImageRSS = styled(imageComponent)`
	position: absolute;
	width: auto;
	height: auto;
	margin: 0 -22%;
	z-index: -1;
	@media (max-width: 769px) {
		width: auto;
    	margin: 0 -100%;
	}
`;
const BackgroundFooter = styled(imageComponent)`
	position: absolute;
	width: auto;
	height: 160px;
	left: -283px;
	bottom: 0;
	z-index: 0;
	@media (max-width: 769px) {
		display: none;
	}
`;	
const TitleKanda = styled.span `
	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	line-height: 120%;
	text-align: center;
	color: #070B11;
	@media (max-width: 769px) {
		font-size: 60px;
		line-height: 100%;
	}
`;
const SubtitleKanda = styled.span `
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 120%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
	margin-top: 34px;
`;
const NumberText = styled.span `
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 120%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
	margin-top: 34px;
	@media (max-width: 769px) {
		font-size: 20px;
	}
`;
const TitleKanda2 = styled.span `
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 47px;
	text-align: center;
	color: #070B11;
`;
const TitleKanda3 = styled.div `
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 39px;
	color: #070B11;
	text-align: left;
    margin-top: 60px;
`;
const DescriptionCont = styled.div `
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 120%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
	margin: 30px auto;
    width: 480px;
    height: 215px;
    padding: 50px 41px;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: rgb(0 0 0 / 7%) 0px 60px 80px, rgb(0 0 0 / 5%) 0px 21.901px 29.2013px, rgb(0 0 0 / 4%) 0px 10.6325px 14.1767px, rgb(0 0 0 / 3%) 0px 5.21226px 6.94968px, rgb(0 0 0 / 2%) 0px 2.06094px 2.74791px;
	@media (max-width: 769px) {
		font-size: 16px;
		margin: 10px auto;
		width: 100%;
		height: auto;
		padding: 20px;
	}
	`;
const Description = styled.div `
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 120%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
	@media (max-width: 769px) {
		font-size: 20px;
	}
`;
const DivForm = styled.div `
	padding: 40px 48px;
	position: static;
	width: 550px;
	height: 792px;
	right: 684px;
	top: 0px;
	background: #FFFFFF;
	box-shadow: 0px 60px 80px rgba(0, 0, 0, 0.07), 0px 21.901px 29.2013px rgba(0, 0, 0, 0.0482987), 0px 10.6325px 14.1767px rgba(0, 0, 0, 0.0389404), 0px 5.21226px 6.94968px rgba(0, 0, 0, 0.0310596), 0px 2.06094px 2.74791px rgba(0, 0, 0, 0.0217013);
	border-radius: 24px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 48px auto;
	text-align: center;
	@media (max-width: 769px) {
		padding: 20px;
		width: 100%;
		height: auto;
    }
`;
const TitleForm = styled.span `
	font-style: normal;
	font-weight: bold;
	font-size: 50px;
	line-height: 65px;
	text-align: center;
	color: #000000;
	margin-bottom: 20px;
	@media (max-width: 769px) {
		font-size: 36px;
		line-height: 47px;
    }
`;
const Input = styled.input `
	width:100%;
	height: 64px;
	border-radius: 16px;
	border: 1px solid rgba(7, 11, 17, 0.2);
	padding: 20px 22px;
`;
const Label = styled.label `
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	letter-spacing: -0.2px;
	color: #070B11;
	opacity: 0.6;
	margin-top: 24px;
`;
const InfoD = styled.p `
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 130%;
	letter-spacing: -0.2px;
	color: #070B11;
	text-align: left;
`;
const TextFooter = styled.div `
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 160%;
	letter-spacing: -1.07515px;
	color: #070B11;
	@media (max-width: 769px) {
		font-size: 13px;
	}
`;
const NumberKanda = styled.div `
	width: 148px;
	height: 148px;
	background: #FAFAFA;
	border: 3px solid #6BD5AF;
	box-sizing: border-box;
	border-radius: 120px;
	margin: auto;
	@media (max-width: 769px) {
		width: 110px;
		height: 110px;
		margin: auto 0 0 auto;
	}
`;
const Number = styled.div `
	font-style: normal;
	font-weight: bold;
	font-size: 42px;
	line-height: 120%;
	display: block;
	align-items: center;
	text-align: center;
	color: #F64282;
	padding: 33% 0;
	@media (max-width: 769px) {
        width: auto;
		margin: auto;
		text-align: center;
		padding: 25% 0;
    }
`;
const ContainerF = styled.div `
	display: flex;
	margin-top: 119px;
	@media (max-width: 769px) {
        display: block;
		margin-top: 10px;
    }
`;
const Container50 = styled.div `
	width: 50%;
	@media (max-width: 769px) {
        width: 100%;
    }
`;
const Container50Top = styled.div `
	width: 50%;
	text-align: center;
    margin-top: 100px;
	@media (max-width: 769px) {
        width: 100%;
		text-align: center;
    	margin-top: 0;
    }
`;
const ContainerP = styled.div `
	height: 562px;
	padding: 20px 40px;
	@media (max-width: 769px) {
        height: auto;
		padding: 20px;
    }
`;
const Container3 = styled.div `
	display: flex;
	padding: 20px 40px;
	@media (max-width: 769px) {
		display: block;
		padding: 20px;
    }
`;
const ContainerSec2 = styled.div `
	height: 313px;
	background: rgba(107, 213, 175, 0.3);
	@media (max-width: 769px) {
		height: auto;
        margin-top: 80px;
    }
`;
const FlexSec2 = styled.div `
	display: flex;
	padding: 20px 40px;
	height: 100%;
	margin: auto;
	@media (max-width: 769px) {
		display: block;
		padding: 20px;
	}
`;
const ContainerSec3 = styled.div `
	height: 930px;
    max-height: 930px;
    overflow: hidden;
	@media (max-width: 769px) {
		height: auto;
		max-height: 100%;
	}
`;
const ContainerBenef = styled.div `
	width: 80%;
	margin: 68px auto auto;
	text-align: left;
	@media (max-width: 769px) {
		width: 100%;
		margin-bottom: 40px;
	}
`;
const ContLogo = styled.div `
	width: 20%;
    margin-left: 0;
	@media (max-width: 769px) {
		margin-left: 0;
		width: 25%;
		margin-top: 5px;
	}
`;
const ContLogo2 = styled.div `
	width: 80%;
	display: flex;
	justify-content: flex-end;
	@media (max-width: 769px) {
		width: 75%;
	}
`;
const ContNumKanda = styled.div `
	width: 85%;
	display: flex;
	margin: auto;
	@media (max-width: 769px) {
		width: 100%;
	}
`;
const ContainerN = styled.div `
	display: flex;
	margin-top: 80px;
	@media (max-width: 769px) {
		display: block;
	}
`;
const Comparte = styled.div `
	margin: auto;
    width: 80%;
    display: flex;
	@media (max-width: 769px) {
		width: 100%;
    	display: block;
		padding: 20px;
	}
`;
const Container60 = styled.div `
	width: 50%;
	margin-top: auto;
	@media (max-width: 769px) {
		width: 100%;
	}
`;
const Container40 = styled.div `
	width: 50%;
	@media (max-width: 769px) {
		width: 100%;
		height: 245px;
	}
`;
const ContainerInt = styled.div `
	width: 80%;
	margin: auto;
	@media (max-width: 769px) {
		width: 90%;
	}
`;
const ContFooterI = styled.div `
	display: flex;
    padding: 20px 40px;
	@media (max-width: 769px) {
		padding: 20px;
	}
`;

const Footer = () => (
	<div style={{ height: '160px', backgroundColor: '#6bd5af80', zIndex: '1' }}>
		<BackgroundFooter src={BackFooter} />
		<ContFooterI>
			<div style={{ width: '50%', zIndex: '1' }}>
				<Image
					cursor="pointer"
					src={ImageLogo}
					width="100px"
					mwidth="97.92px"
					heightMobile="20.8px"
					alt="logo-coru"
				/>	
			</div>
			<div style={{ width: '50%', textAlign: 'right', zIndex: '1' }}>
				<TextFooter className="roboto">coru.com</TextFooter>
			</div>
		</ContFooterI>
		<div style={{ textAlign: 'center', zIndex: '1' }}>
			<TextFooter className="roboto" style={{ marginTop: '15px' }}>
				<a style={{color: '#070B11'}} href='https://coru.com/privacy-notice'>Aviso de Privacidad | Términos y condiciones</a> |
				<br /> @2021 Coru. Todos los derechos reservados.
			</TextFooter>
		</div>
	</div>
);
const Header = () => (
    <div style={{ height: '85px', display: 'flex' }}>
		<ImageBack src={ImageBack1} />
		<ContLogo>
			<Image
				cursor="pointer"
				src={ImageLogo}
				width="120px"
				mwidth="97.92px"
				heightMobile="20.8px"
				alt="logo-coru"
			/>
		</ContLogo>
		<ContLogo2>
			<div>
				<a className="textHeader roboto nav-link" 
					style={{
						textAlign: 'center',
						paddingRight: '2rem',
						cursor: 'pointer',
						fontWeight: 'bold',
						color: '#070B11',
					}}>
					Regístrate
				</a>
			</div>
			<div>
				<a className="textHeader roboto nav-link" 
					style={{
						textAlign: 'center',
						cursor: 'pointer',
						color: '#070B11',
						paddingRight: '0',
					}}>
					Iniciar Sesión
				</a>
			</div>
		</ContLogo2>
	</div>
);

function WaitlistKanda() {

const [numUsoKanda, setNumUsoKanda] = useState(0);
const [lpkanda, setLpkanda] = useState(true);
const [form, setForm] = useState({
    fname: '',
   	correo: '',
    edad: '',
    telefono: '',
});
const [error] = useState({
    correo: false,
    fname: false,
    telefono: false,
    edad: false,
});
const [texterror] = useState({
    correo: '',
    fname: '',
    telefono: '',
    edad: '',
});

const classes = useStyles();
const [modalStyle] = React.useState(getModalStyle);
const [open, setOpen] = React.useState(false);

const handleOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        ></ParagrapTwo>
        <ParagrapTwo
          className="mx-auto"
          mb="0px"
          textalignMobile="center"
          texttalign="center"
        >
          {' '}
          Se ha copiado el enlace <br /> correctamente
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </div>
    </Modal>
);

const [saveKandaWaitlist, {}] = useMutation(SAVE_KANDA_WAITLIST, {
    onCompleted({ saveKandaWaitlist }) {
      if (saveKandaWaitlist.statusCode === 200) {
		  setNumUsoKanda(parseInt(saveKandaWaitlist.response));
		  setLpkanda(false);
		  setTimeout(
			document.getElementsByClassName("day")[0].classList.add('containerNum'),
			document.getElementsByClassName("hour")[0].classList.add('containerNum'),
			document.getElementsByClassName("min")[0].classList.add('containerNum'),
			document.getElementsByClassName("sec")[0].classList.add('containerNum'),
			// document.getElementsByClassName("countDownTimer")[0].children[0].style.display = 'flex',
			// document.getElementsByClassName("countDownTimer")[0].children[0].style.gap = '55px',
			document.getElementsByClassName("odometer-block")[0].children[0].style.display = 'flex',
			document.getElementsByClassName("odometer-block")[0].children[0].style.flexFlow = 'column-reverse',
			document.getElementsByClassName("odometer-block")[0].children[0].style.width = '160px',
			document.getElementsByClassName("odometer-block")[0].children[1].style.display = 'flex',
			document.getElementsByClassName("odometer-block")[0].children[1].style.flexFlow = 'column-reverse',
			document.getElementsByClassName("odometer-block")[0].children[1].style.width = '160px',
			document.getElementsByClassName("odometer-block")[0].children[2].style.display = 'flex',
			document.getElementsByClassName("odometer-block")[0].children[2].style.flexFlow = 'column-reverse',
			document.getElementsByClassName("odometer-block")[0].children[2].style.width = '160px',
			document.getElementsByClassName("odometer-block")[0].children[3].style.display = 'flex',
			document.getElementsByClassName("odometer-block")[0].children[3].style.flexFlow = 'column-reverse',
			document.getElementsByClassName("odometer-block")[0].children[3].style.width = '160px',
		1500,
		);
      }
    },
});
  
const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
		case 'correo':
			ValidEmail(e.target.id, e.target.value, e.target.name);
			break;
		case 'telefono':
			ValidPhone(e.target.name, e.target.value, e.target.id);
			break;
		case 'edad':
			ValidEdad(e.target.name, e.target.value, e.target.id);
			break;
		default:
			ValidLetras(e.target.name, e.target.value, e.target.id);
			break;
    }
};
const ValidEmail = (id, valueEmail, nombre) => {
    let domain = valueEmail.split('@');
    const anexo = domain[0];
    domain = domain[1];
    const idmail = document.getElementsByName(nombre)[0];
    if (idmail === null || idmail === undefined) {
      console.log('null');
    } else if (
      valueEmail
      && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      idmail.className = 'invalidInputWL';
      texterror.Email1 = 'Correo electrónico incorrecto';
      error.correo = true;
      error.Email1valid = 1;
    } else if (
      domain !== ''
      && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      idmail.className = 'invalidInputWL';
      texterror.Email1 = 'Correo electrónico incorrecto';
      error.correo = true;
      error.Email1valid = 1;
    } else {
      idmail.className = 'validInputWL';
      texterror.Email1 = '';
      error.correo = false;
      error.Email1valid = 0;
    }
};
const ValidPhone = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementsByName(name)[0];
    if (tel === null || tel === undefined) {
      console.log('null');
    } else if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'invalidInputWL';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'validInputWL';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = false;
    }
};
const ValidEdad = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const edad = document.getElementsByName(name)[0];
    if (edad === null || edad === undefined) {
      console.log('null');
    } else if (value.length !== 2 || (name && !/^[0-9]+$/i.test(value))) {
      edad.className = 'invalidInputWL';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      edad.className = 'validInputWL';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = false;
    }
};
const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementsByName(nombre)[0];
    if (element === null || element === undefined) {
      console.log('null');
    } else if (valor.length > 2 && valor !== 'name temporal') {
      element.className = 'validInputWL';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'MiddleName') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      element.className = 'invalidInputWL';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
};

const saveLogKandaList = () => {
	const jsonData = {
		Nombre: form.fname,
		Edad: form.edad,
		Email: form.correo,
		Phone: form.telefono,
	};

	const mongoInput = {
        input: {
			collection: 'Kanda_Waitlist',
          	data: JSON.stringify(jsonData),
        },
    };

	const formComplete = Object.values(form).every((value) => value !== "");
	if(!error.correo && !error.edad && !error.fname && !error.telefono && formComplete){
		saveKandaWaitlist({ variables: mongoInput });
	}
};

const shareCoru = (socialNetwork) => {
    let coruRedirectURL;
	if (process.env.REACT_APP_SERVERTYPE === 'Development') {
		coruRedirectURL = "https://dev-rediseno.coru.mx/shorter?id=oDKDNWbpGa3R5Rd";
	} else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
		coruRedirectURL = "https://coru.com/shorter?id=oDKDNWbpGa3R5Rd";
	}

    switch (socialNetwork) {
      case 'Messenger':
        const url = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruRedirectURL}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}`;
        window.open(url);
        break;
      case 'Facebook':
        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruRedirectURL,
          )}&redirect_uri=${encodeURIComponent(
            'https://www.facebook.com/',
          )}&display=popup`,
        );
        break;
      case 'Whatsapp':
        const whatsappMessage = `¡Obtén el poder de tu dinero! Coru es la forma más inteligente de tomar tus decisiones financieras. Nuestro coach financiero te llevará de la mano para entender tus necesidades. Regístrate y alcanza tus metas financieras. ${coruRedirectURL}`;
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`,
          'popup',
          'width=600',
          'height=600',
        );
        break;
      case 'Copy':
        const el = document.createElement('input');
        el.value = coruRedirectURL;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        handleOpen();
        break;
      default:
        break;
    }
};

return (
    <Fragment>
		<Helmet>
          <title>Pon tu nombre en la lista | CORU</title>
        </Helmet>
		{ lpkanda ?
			<div>
				<ContainerP>
					<Header />
					<ContainerF>
						<Container50>
							<ImageAvatars src={Elements}></ImageAvatars>
						</Container50>
						<Container50>
							<TitleKanda className="cabin">
								{/*Descubre Kanda*/} Descubre Coru 
							</TitleKanda>
							<br />
							<SubtitleKanda className="cabin">
								Crea tu Plan Financiero
								<br />
								¡Completamente gratis!
								
							</SubtitleKanda>
						</Container50>
					</ContainerF>
					<ImageAvatarsMob src={Elements}></ImageAvatarsMob>
				</ContainerP>
				<ContainerSec2>
					<ContainerInt>
						<FlexSec2>
							<Container50Top>
								<TitleKanda2 className="roboto">
									{/*¿Qué es Kanda?*/} ¿Qué es Tu Plan Financiero?
								</TitleKanda2>
							</Container50Top>
							<Container50>
								<DescriptionCont className="cabin" >
									Es una plataforma interactiva en la que podrás hacer un 
									diagnóstico de tus finanzas y desarrollar tu Plan de 
									Bienestar Financiero personalizado, para que logres todos 
									tus objetivos.
								</DescriptionCont>
							</Container50>
						</FlexSec2>
					</ContainerInt>
				</ContainerSec2>
				<ContainerSec3>
					<ImageBackS2  src={ImageBack2}/>
					<Container3>
						<Container50 style={{ zIndex: '1' }}>
							<DivForm>
								<TitleForm className="cabin">¡Quiero probarlo primero!</TitleForm>
								<form autoComplete="off" style={{ marginTop: '24px', textAlign: 'left' }}>
									<Label className="roboto" for="fname">Nombre</Label>
									<br />
									<Input type="text" id="fname" name="fname" value={form.fname} onChange={(e) => handleChange(e)}/>
									<br />
									<Label className="roboto" for="edad">Edad</Label>
									<br />
									<Input type="tel" id="edad" name="edad" pattern="[0-9]*" maxLength={2} value={form.edad} onChange={(e) => handleChange(e)}/>
									<Label className="roboto" for="correo">Correo electrónico</Label>
									<br />
									<Input type="text" id="correo" name="correo" value={form.correo} onChange={(e) => handleChange(e)}/>
									<Label className="roboto" for="telefono">Teléfono</Label>
									<br />
									<Input type="tel" id="telefono" name="telefono" pattern="[0-9]*" maxLength={10} value={form.telefono} onChange={(e) => handleChange(e)}/>
								</form>
								<div className="centrar">
									<CardsBtn
										styledMaxWidth="60%"
										type="button"
										min_w="60%"
										min_web="60%"
										style={{ borderRadius: '16px' }}
										onClick={saveLogKandaList}
									>
										Registrarme
									</CardsBtn>
								</div>
							</DivForm>
						</Container50>
						<Container50>
							<ContainerBenef>
								<TitleKanda2 className="roboto">
									¿Por qué deberías registrarte?
								</TitleKanda2>
								<br /><br />
								<InfoD>
									Puedes ser una de las primeras personas en vivir una experiencia totalmente
									nueva (y gratuita) para entender tus finanzas y tomar el control de tu vida.
								</InfoD>
								<br />
								<InfoD>
									Además, si te registras ahora ¡podrás obtener beneficios adicionales!
								</InfoD>
								<TitleKanda3 className="cabin">
									Beneficios
								</TitleKanda3>
								<div style={{ marginTop: '50px' }}>
									<div style={{ display:'flex' }}>
										<div style={{ width: '30px'}}>
											<ImageStar src={Star} />
										</div>
										<div style={{ width: 'auto', marginLeft: '30px'}}>
											<InfoD>
												Conocerás el Diagnóstico de tu situación financiera actual
											</InfoD>
										</div>
									</div>
									<div style={{ display:'flex' }}>
										<div style={{ width: '30px'}}>
											<ImageStar src={Star} />
										</div>
										<div style={{ width: 'auto', marginLeft: '30px'}}>
											<InfoD>
												Obtendrás tu Plan Financiero totalmente gratuito
											</InfoD>
										</div>
									</div>
									<div style={{ display:'flex' }}>
										<div style={{ width: '30px'}}>
											<ImageStar src={Star} />
										</div>
										<div style={{ width: 'auto', marginLeft: '30px'}}>
											<InfoD>
												Podrás acceder a una de las 10 asesorías personalizadas en línea
											</InfoD>
										</div>
									</div>
									<div style={{ display:'flex' }}>
										<div style={{ width: '30px'}}>
											<ImageStar src={Star} />
										</div>
										<div style={{ width: 'auto', marginLeft: '30px'}}>
											<InfoD>
												Alcanzarás tu libertad financiera
											</InfoD>
										</div>
									</div>
								</div>
							</ContainerBenef>
						</Container50>
					</Container3>
				</ContainerSec3>
				<Footer />
			</div>
			:
			<div>
				<ContainerP>
					<Header />
					<div style={{ marginTop: '20px', textAlign: 'center' }}>
						<TitleKanda className="cabin">
							Gracias por registrarte
						</TitleKanda>
						<ContainerN>
							<Container50>
								<ContNumKanda>
									<div style={{ width: '60%' }}>
										<NumberText className="cabin">
											Tu número en la lista para utilizar Coru es:
										</NumberText>
									</div>
									<div style={{ width: '40%' }}>
										<NumberKanda>
											<Number className="cabin">{numUsoKanda}</Number>
										</NumberKanda>
									</div>
								</ContNumKanda>
							</Container50>
							<Container50>
								<ImageThanks src={Thankyou}></ImageThanks>
							</Container50>
						</ContainerN>
					</div>
				</ContainerP>
				<div className="countDownTimer" >
					<DateCountdown 
					  id="count" 
					  dateTo='October 11, 2021 00:00:00 GMT+06:00' 
					  callback={()=>alert('Hello')}
					  locales = {['Año','Mes','Día','Hora','Minuto','Segundo'] }
					  locales_plural = {['Años','Meses','Días','Horas','Minutos','Segundos'] }
					/>
				</div>
				<div style={{ marginTop: '48px', marginBottom: '90px', textAlign: 'left', minHeight: '280px' }}>
					<Comparte>
						<Container60>
							<Description className="cabin" style={{ textAlign: 'left' }}>
								<b>¡Comparte con tus personas favoritas!</b>
							</Description>	
							<Description className="cabin" style={{ textAlign: 'left' }}>
								Entre más veces compartas, podrás subir de turno en la lista de espera 
								y acceder a una de las 10 asesorías personalizadas en línea.
							</Description>
						</Container60>
						<Container40>
							<ImageShare1 src={Facebook} onClick={() => shareCoru('Facebook')}/>
							<ImageShare2 src={Whatsapp} onClick={() => shareCoru('Whatsapp')}/>
							<ImageShare3 src={Messenger} onClick={() => shareCoru('Messenger')}/>
							<ImageShare4 src={Link} onClick={() => shareCoru('Copy')}/>
							{/* <ImageShare5 src={Share}/> */}
							<ImageRSS src={RSS}/>
						</Container40>
					</Comparte>
				</div>
				<Footer />

				<ModalMessage />
			</div>
		}
    </Fragment>
);
}
export default WaitlistKanda;
