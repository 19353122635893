import React, { Fragment } from 'react';
import '../../assets/styles/Modal.css';
import {
  TrainingQuestion,
  QuestionsDivs,
  CardsBtn,
} from '../../assets/styles/Training.styled';

const ModalDiv = ({
  handleClose, show, children, txtModal = 'Cerrar',
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <>
      <div className={showHideClassName}>
        <section className="modal-main">
          <TrainingQuestion wiw="60%" className="">
            <QuestionsDivs
              padd="0"
              txta="left"
              width="100%"
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#ffffff"
            >
              {children}

              <CardsBtn
                type="button"
                min_w="100%"
                min_web="100%"
                onClick={(e) => {
                  handleClose(e);
                }}
              >
                {txtModal}
              </CardsBtn>
            </QuestionsDivs>
          </TrainingQuestion>
          <br />
        </section>
      </div>
    </>
  );
};

export default ModalDiv;
