import React, { useState, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  ContentButton,
  Container,
} from '../../components/common/common.styled';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import '../../assets/styles/TextCustom.css';
import { GET_COUNTRY_CODES } from '../../queries';

const CreditHistory = () => {
  const [lleno, setlleno] = useState(false);
  const { data: dataLadas } = useQuery(GET_COUNTRY_CODES);
  const [Ladas] = useState([
    {
      value: '',
      label: 'México',
      lada: '',
    },
    {
      value: 'Estados Unidos lada +(1)',
      label: 'Estados Unidos',
      lada: '1',
    },
    {
      value: '',
      label: 'Brasil',
      lada: '',
    },
    {
      value: '',
      label: 'Colombia',
      lada: '',
    },
    {
      value: '',
      label: 'Chile',
      lada: '',
    },
  ]);

  const LlenarCampo = () => {
    if (dataLadas) {
      if (!lleno) {
        dataLadas.getCountryCodes.forEach((element) => {
          if (
            element.Country !== 'México'
            && element.Country !== 'Estados Unidos'
            && element.Country !== 'Brasil'
            && element.Country !== 'Colombia'
            && element.Country !== 'Chile'
          ) {
            Ladas.push({
              value: `${element.Country} lada +(${element.CountryCode})`,
              label: element.Country,
              lada: element.CountryCode,
            });
          } else {
            let i = 0;
            switch (element.Country) {
              case 'México':
                i = 0;
                break;
              case 'Estados Unidos':
                i = 1;
                break;
              case 'Brasil':
                i = 2;
                break;
              case 'Colombia':
                i = 3;
                break;
              case 'Chile':
                i = 4;
                break;
              default:
                break;
            }
            Ladas[
              i
            ].value = `${element.Country} lada +(${element.CountryCode})`;
            Ladas[i].label = element.Country;
            Ladas[i].lada = element.CountryCode;
          }
        });
        setlleno(true);
      }
    }
  };
  LlenarCampo();

  const [expiredCode] = useState(false);

  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="20%"
        margintop="2%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="60%" mt="1rem" widthMobile="100%" mtMobile="2rem">
          <Col11>
            <SubtitleOne
              id="idTitle2"
              mt="0%"
              className="roboto"
              size="40px"
              sizeMobile="20px"
              textalingn="center"
              textalingnMobile="center"
            >
              Consulta a Buró de Crédito
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#858585"
              textalignMobile="center"
              texttalign="center"
            >
              Haremos una consulta a tu Historial Crediticio para determinar la
              línea de crédito que podríamos asignarte.
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#858585"
              textalignMobile="center"
              texttalign="center"
            >
              Para continuar ingresa el código que recibiste vía Whatsapp al
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="bold"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#000000"
              textalignMobile="center"
              texttalign="center"
            >
              +52 ******5555
            </ParagrapTwo>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="5%"
              fontWeight="normal"
              fontSize="16px"
              fontSizeWeb="18px"
              color="#0CC665"
              textalignMobile="center"
              texttalign="center"
            >
              ¿Este no es tu número?
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
            >
              <TextField
                variant="outlined"
                id="code"
                name="code"
                autoFocus
                required
                label="Código de verificación"
                type="tel"
                inpu9tProps={{
                  type: 'tel',
                  maxLength: 6,
                }}
                className="registro"
                style={{ width: '70%', margin: 'auto' }}
              />
              <ContentButton mt="0" width="70%" mtMobile="0%">
                <Button
                  href=""
                  className="d-block mt-md-4 roboto"
                  mb="1.2rem"
                  mt="0rem"
                  bg="#0CC665"
                >
                  Verificar
                </Button>
              </ContentButton>
              <ParagrapTwo
                className="mx-auto roboto"
                mb="3%"
                mt="5%"
                mbweb="3%"
                fontWeight="normal"
                fontSize="16px"
                fontSizeWeb="18px"
                color="#252525"
                textalignMobile="center"
                texttalign="center"
              >
                Tú código vence en: 00:00
              </ParagrapTwo>
              {expiredCode ? (
                <>
                  <ParagrapTwo
                    className="mx-auto roboto"
                    mb="3%"
                    mt="5%"
                    mbweb="3%"
                    fontWeight="normal"
                    fontSize="16px"
                    fontSizeWeb="18px"
                    color="#252525"
                    textalignMobile="center"
                    texttalign="center"
                  >
                    Tú código ha vencido
                  </ParagrapTwo>
                  <ParagrapTwo
                    className="mx-auto roboto"
                    mb="3%"
                    mt="5%"
                    mbweb="5%"
                    fontWeight="normal"
                    fontSize="16px"
                    fontSizeWeb="18px"
                    color="#0CC665"
                    textalignMobile="center"
                    texttalign="center"
                  >
                    Reenviar
                  </ParagrapTwo>
                </>
              ) : null}
            </form>
          </Col11>
        </Container>
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
};

export default CreditHistory;
