import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { Image } from '../../assets/styles/Home.styled';
import {
  Container1,
  ContentHomeSection,
  Shapes,
  Title2,
  Subtitle2,
} from '../../components/common/Home.styled';
import { Button, Container, Col6 } from '../../components/common/common.styled';
import rappi2 from '../../assets/img/lpRappi/cardRappi.png';
import WOlayout from '../../components/layouts/WOlayout';


export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function VerificationError({ location }) {
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);
  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Container1>
        <Shapes
          id="Shapes2"
          key={2}
          top=" "
          left=" "
          heightD="800px"
          height="auto"
          heightD480="auto"
          width="100%"
          position=" "
          background="#FAFAFA"
        >
          <ContentHomeSection
            topMm320="85px"
            topDW="85px"
            leftD=" "
            floatD=" "
            displayD="none"
            displayM="block"
          >
            <Title2
              sizem="28px"
              top={heigthBrowser >= 550 ? '30px' : '85px'}
              topD="15%"
              className="roboto"
            >
              Lo sentimos, hubo un error al verificar tu cuenta
            </Title2>
            <Subtitle2 className="roboto" bottom="17px" top="10px">
              ¡Pero no te preocupes! Envíanos un mensaje y en cuanto podamos nos
              pondremos en contacto contigo para apoyarte nuevamente en el
              trámite de tu RappiCard.
            </Subtitle2>
            <Title2
              sizem="28px"
              top={heigthBrowser >= 550 ? '30px' : '45px'}
              topD="15%"
              className="roboto"
            >
              RappiCard
            </Title2>
            <div style={{ textAlign: 'center' }}>
              <Image
                src={rappi2}
                maxwidth="430px"
                width="80%"
                position="relative"
                left="0"
                top="5%"
                displayMobile=""
              />
            </div>
            <div style={{ margin: 'auto', width: '90%' }}>
              <Button
                className="d-block roboto"
                width="90%"
                mb="120px"
                bg="#0CC665"
                onClick=""
              >
                Enviar mensaje
              </Button>
            </div>
          </ContentHomeSection>
          <div className="web" style={{ width: '100%' }}>
            <Container
              width="80%"
              mt="6%"
              widthMobile="100%"
              mb="0%"
              mtMobile="6%"
            >
              <Col6
                className={heigthBrowser >= 550 ? '' : 'hidden'}
                pt="0px"
                flex="0 0 100%"
                maxwidth="100%"
              >
                <Title2
                  className="roboto"
                  sizeD="35px"
                  top={heigthBrowser >= 550 ? '85px' : '45px'}
                  topD="15%"
                >
                  RappiCard
                </Title2>
                <Image
                  src={rappi2}
                  maxwidth="430px"
                  width="25%"
                  position="absolute"
                  left="0"
                  top="40px"
                  displayMobile="none"
                />
              </Col6>
              <Col6 pt="0px" display="flex" displayM="none">
                <hr
                  style={{
                    border: 'none',
                    color: '#DADADA',
                    borderLeft: '2px solid #DADADA',
                    height: '500px',
                    width: '2px',
                    marginRight: '20px',
                  }}
                ></hr>
                <div style={{ margin: 'auto' }}>
                  <Title2
                    sizeD="35px"
                    top={heigthBrowser >= 550 ? '85px' : '45px'}
                    topD="15%"
                    className="roboto"
                  >
                    Lo sentimos, hubo un error al verificar tu cuenta
                  </Title2>
                  <Subtitle2 className="roboto" bottom="17px" top="10px">
                    ¡Pero no te preocupes! Envíanos un mensaje y en cuanto
                    podamos nos pondremos en contacto contigo para apoyarte
                    nuevamente en el trámite de tu RappiCard.
                  </Subtitle2>
                  <Button
                    className="d-block roboto"
                    bg="#0CC665"
                    mb="0"
                    onClick=""
                  >
                    Enviar mensaje
                  </Button>
                </div>
              </Col6>
            </Container>
          </div>
        </Shapes>
      </Container1>
    </WOlayout>
  );
}

export default VerificationError;
