import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import '../../assets/styles/Modal.css';
import { makeStyles } from '@material-ui/core/styles';
import { ContentHomeSection } from '../common/Home.styled';
import { Container } from '../../assets/styles/Home.styled';
import { ParagrapTwo, Image } from '../common/common.styled';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import confetis from '../../assets/img/anacoach/Hero.png';
import Recommendations from '../Training/RecommendedTraining';

const ModalConSinPremio = ({ show, recommendedTraining, premio }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '400px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '550px',
    },
  }));

  const useValues = () => ({
    title: premio
      ? '¡Te damos una recompensa al trabajar en tus finanzas personales!'
      : `¡Cumple todas tus metas, ${sessionStorage.getItem('FirstName')}`,
  });

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      height: 'auto',
      padding: '0px 32px 24px',
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const classes = useStyles();
  const values = useValues();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <>
      <Modal
        open={open}
        className={showHideClassName}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              zindex="1"
              onClick={handleClose}
            >
              X
            </ParagrapTwo>
            <ParagrapTwo
              texttalign="left"
              textalignMobile="left"
              className="mx-auto roboto"
            >
              <b>{values.title}</b>
            </ParagrapTwo>
            {premio ? (
              <ParagrapTwo
                texttalign="center"
                textalignMobile="center"
                className="mx-auto roboto"
              >
                ¿Te hace falta motivación? <b>Recibe un cupón de descuento</b>{' '}
                al terminar los siguientes entrenamientos:
              </ParagrapTwo>
            ) : (
              <ParagrapTwo
                texttalign="left"
                textalignMobile="left"
                className="mx-auto roboto"
              >
                Tenemos nuevos entrenamientos para que cumplas con tus objetivos
                financieros ¡Conócelos y descubre lo que Coru puede hacer por
                tí!
              </ParagrapTwo>
            )}
            <>
              <Container
                width="100%"
                mleft="10%"
                widthMobile="100%"
                mleftm="auto"
                mtMobile="0px"
              >
                <Feed_Section padding="0% 5% 5% 5%" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </>
          </ContentHomeSection>
          {premio ? (
            <ParagrapTwo
              texttalign="left"
              textalignMobile="left"
              className="mx-auto roboto"
            >
              *Cupón canjeable en restaurantes, tiendas en línea o cines
              participantes.
            </ParagrapTwo>
          ) : null}
          <Image
            src={confetis}
            marginLeftM="0px"
            heightMobile="200px"
            mwidth="100%"
            margin="auto"
            top="-55px"
            left="0%"
            mtop="-25px"
            position="absolute"
          />
        </div>
      </Modal>
    </>
  );
};

export default ModalConSinPremio;
