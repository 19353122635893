import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import InputsOrder from '../../components/common/InputsOrder';
import {
    CardsBtn,
    TrainingDivSection,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/TextCustom.css';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import { Container, Col7 } from '../../components/common/common.styled';
import { GET_CURP, SAVE_CURP } from '../../mutations';

const Input = styled.input`
    border: 1px solid rgba(7,11,17,0.2);
    height: 50px;
    letter-spacing: 0.5px;
    margin-bottom: 14px;
    padding: 20px 22px;
    width: 100%;
`;

const CurpVista = ({
    nombreCompleto,
    apPaterno,
    apMaterno,
    genero,
    estado,
    fechaNacimiento,
    curpValidado,
    setCurpValidado,
}) => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const anchop = window.screen.width;
    const curpLength = 18;
    const [editcurp, setEditcurp] = useState(false);
    const [form, setForm] = useState({
        curp: '',
    });

    const [saveCurp] = useMutation(SAVE_CURP, {
        onCompleted({ saveCurp }) {
            if (saveCurp.statusCode === 200) {
                setCurpValidado(true);
                setEditcurp(false);
                sessionStorage.setItem('CURP_FT', form.curp);
            }
        },
    });

    const [createCURP] = useMutation(GET_CURP, {
        onCompleted({ createCURP }) {
            if (createCURP.statusCode === 200) {
                setForm({
                    ...form,
                    curp: createCURP.response,
                });
            } else {
                setEditcurp(true);
            }
        },
    });

    const handleChange = (e) => {
        const val = e.target.value.toUpperCase();
        setForm({
            ...form,
            [e.target.name]: val,
        });
    };

    const insertCurp = () => {
        if (form.curp.length === curpLength) {
            saveCurp({
                variables: {
                    idUser: sessionStorage.getItem('idUser'),
                    curp: form.curp,
                }
            });
        }
    };

    useEffect(() => {
        const inputCURP = {
            input: {
                nombre: nombreCompleto,
                apellido_paterno: apPaterno,
                apellido_materno: apMaterno,
                genero: genero,
                estado: estado,
                fecha_nacimiento: fechaNacimiento,
            },
        };
        createCURP({ variables: inputCURP });
    }, []);

    return (
        <div>
            <Container
                width="80%"
                mt="0"
                widthMobile="90%"
                mtMobile="0"
            >
                <ImageCoachInicio
                    classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                    topMobile="35px"
                />
                <Col7
                    maxwidth="100%"
                    padding=" "
                    className="cardPrincipal1"
                >
                    <TrainingDivSection
                        hew="auto"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                        className="topOne1 topOne1Init"
                    >
                        <p className="bold-p" style={{ fontSize: '20px' }}>
                            CURP
                        </p>
                        <br />
                        <p
                            style={{
                                fontSize: '16px',
                                fontFamily: 'sans-serif',
                                letterSpacing: '-0.2px',
                                fontStyle: 'normal'
                            }}>
                            {sessionStorage.getItem('FirstName') || ''},
                            con los datos que nos acabas de proporcionar hemos encontrado tu CURP ¿Es correcto?
                        </p>
                        <br />
                        <Input
                            type="text"
                            id="curp"
                            name="curp"
                            maxLength={curpLength}
                            value={form.curp}
                            onChange={(e) => handleChange(e)}
                            disabled={!editcurp}
                            placeholder={editcurp ? "Introduce tu CURP" : ""}
                        />
                        {!curpValidado ? (
                            <>
                                <CardsBtn
                                    type="button"
                                    min_w="100%"
                                    min_web="100%"
                                    mtopw="0"
                                    mtop="0"
                                    disabled={!(form.curp.length === curpLength)}
                                    onClick={insertCurp}
                                >
                                    Continuar
                                </CardsBtn>
                                <CardsBtn
                                    min_w="100%"
                                    min_web="100%"
                                    mtop="12px"
                                    mtopw="12px"
                                    type="button"
                                    onClick={(e) => setEditcurp(true)}
                                    color="#EC407A !important"
                                    back="#fff !important"
                                    border="solid 1px !important"
                                >
                                    Editar CURP
                                </CardsBtn>
                            </>
                        ) : null}
                    </TrainingDivSection>
                </Col7>
            </Container>
        </div>
    );
};

export default CurpVista;
