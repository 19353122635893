import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {
  ParagrapTwo,
} from './common.styled';
import {
  ContentHomeSection,
} from './Home.styled';
import styled from "styled-components";

import Checkbox from "@material-ui/core/Checkbox";
import {
  ImageSections,
  TrainingText,
  DivStyle,
  DivFlex,
  Resultado,
  CardsBtn,
} from "../../assets/styles/Training.styled";
import creditea from '../../assets/img/creditea.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  width: calc(100%);
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 16px;
`;
const ContainerResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 15px;
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 16px;
`;

const Title = styled.p`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 15px;
  text-align: left;
`;

const Text = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: -0.3px;
  color: ${(props) => props.inputColor || "#252525"};
  margin-bottom: 15px;
  text-align: left;
`;

const Input = styled.input`
  height: 50px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 16px;
  height: 50px;
  width: 100%;
  padding: 18px 20px;
`;
const InputTel = styled.input`
  height: 50px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 16px;
  height: 50px;
  width: 100%;
  padding: 18px 20px;
`;

const ValidateButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 48px;
  background: ${(props) => props.inputColor || "#f64282"};
  border: 1px solid #f64282;
  border-radius: 16px;
  margin-bottom: 15px;

  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%%;
  letter-spacing: -0.2px;
  color: ${(props) => props.inputColor || "#FFFFFF"};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const AuxText = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #d40014;
`;

const AuxTextTel = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #d40014;
`;

const Option = styled.div`
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.3px;
  text-decoration-line: underline;
  color: #f64282;
  cursor: pointer;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '25%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    '@media (max-width: 768px)': {
      width: '80%',
      height: 'auto',
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const CrediteaModals = ({
  setOpen,
  open,
  ingreso,
  result=false,
  success,
  validation,
  reenviarCodigo,
  sendOtpCreditea,
  ClickOut,
  resultcreditea,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  useEffect(() => {

  }, []);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
    setChangeTel(false);
  };

  const [valueLength, setValueLength] = useState(0);
  const [crediteaOTP, setCrediteaOTP] = useState(0);
  const [changeTel, setChangeTel] = useState(false);

  const [crediteaMonto, setMontoCreditea] = useState('');
  const [crediteaPlazo, setPlazoCreditea] = useState('');
  const [crediteaPago, setPagoCreditea] = useState('');
  const [crediteaTasa, setTasaCreditea] = useState('');

  //Validor on change -> valida la longitud
  const lengthValidation = (e) => {
    setCrediteaOTP(e.target.value);
    setValueLength(e.target.value.length);
    let name = document.getElementById("myTextBox");
    // setValueLength(name.value.length);
    name.addEventListener("keyup", () => {
      if (name.value.length > 4) {
        name.value = name.value.slice(0, 4);
      }
    });
  };

  const telLengthValidation = (e) => {
    let name = document.getElementById("myTextTelBox");
    setValueLength(e.value.length);
    name.addEventListener("keyup", () => {
      if (e.value.length >= 10) {
        sessionStorage.setItem('CellPhone', e.target.value);
        e.value = e.value.slice(0, 10);
      }
    });
  };

  //Validador onClick -> valida que sea un numero
  // const validation = () => {
  //   if (isNaN(crediteaOTP)) {
  //     // Validación provicional: isNaN
  //     document.getElementById("numberText").innerHTML = "Código incorrecto";
  //     return false;
  //   } else {
  //     document.getElementById("numberText").innerHTML = "";
  //     setOpen
  //     (false);
  //     return true;
  //   }
  // };

  // const telValidation = () => {
  //   let n = document.getElementById("myTextTelBox").value;
  //   if (isNaN(n)) {
  //     document.getElementById("telText").innerHTML = "Número incorrecto";
  //     return false;
  //   } else {
  //     document.getElementById("telText").innerHTML = "";
  //     setChangeTel(false);

  //     return true;
  //   }
  // };

  // const [succes, setSuccess] =  useState(false);
  // const [error, setError] =  useState(false);

  useEffect(() => {
    if (Object.keys(resultcreditea).length !== 0) {
      setMontoCreditea(resultcreditea.product.amount);
      setPlazoCreditea(resultcreditea.repayment.periods.quantity);
      setTasaCreditea(resultcreditea.product.interestRate);
      setPagoCreditea(resultcreditea.repayment.installmentAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultcreditea]);

  const [mockCheck, setMockCheck] = useState(true);
  function validate() {
    if (document.getElementById("continueCheck").checked) {
      window.open(ClickOut, '_blank').focus();
      setOpen(false);
    }
  }

  let monto = "";

  if (ingreso == "menos_4000") {
    monto = "$4,000";
  }
  if (ingreso == "de_4000_a_7000") {
    monto = "$5,000";
  }
  if (ingreso == "de_7000_a_15000") {
    monto = "$10,000";
  }
  if (ingreso == "de_15000_a_30000") {
    monto = "$22,000";
  }
  if (ingreso == "mas_de_30000") {
    monto = "$30,000";
  }

  return (
        <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>
                <ParagrapTwo width="70%" widthM="95%" fontWeight="700" cursor="pointer" texttalign="right" textalignMobile="right" className='mx-auto' onClick={handleClose} mt="" mtWeb="10px">X</ParagrapTwo>
                <ContentHomeSection topD="0px" width="100%" leftD=" " rigthD="0%" floatD=" ">
                  {!result ? (
                    <Container>
                      <Title>Código Creditea</Title>
                      {changeTel ? (
                          <>
                          <Text inputColor={"#A6A6A6"}>
                              Ingresa el número celular al que deseas reenviar el código de
                              Creditea.
                          </Text>
                          <Title>Teléfono celular</Title>
                          <InputTel
                              maxLength="10"
                              onChange={(e) => telLengthValidation(e)}
                              type="text"
                              placeholder="(55) 12345678"
                              id="myTextTelBox"
                          ></InputTel>
                          <AuxTextTel id="telText"></AuxTextTel>
                          {valueLength == 10 ? ( //Si el codigo son 10 digitos, activa el boton. Pasa por validación
                              <ValidateButton
                              style={{}}
                              onClick={() => sendOtpCreditea()}
                              >
                              Enviar código
                              </ValidateButton>
                          ) : (
                              <ValidateButton
                              onClick={() => {
                                  document.getElementById("telText").innerHTML =
                                  "Número incorrecto";
                              }}
                              >
                              Enviar código
                              </ValidateButton>
                          )}
                          </>
                      ) : (
                          <>
                          <Text inputColor={"#A6A6A6"}>
                              Hemos enviado un código por medio de SMS al número celular que
                              registraste.
                          </Text>
                          <Text>Ingresa el código de 4 dígitos para continuar.</Text>
                          <Input
                              maxLength="4"
                              onChange={(e) => lengthValidation(e)}
                              type="text"
                              placeholder="Ingresa el código"
                              name='myTextBox'
                              id="myTextBox"
                          ></Input>
                          <AuxText id="numberText"></AuxText>
                          {valueLength == 4 ? ( //Si el codigo son cuatro digitos, activa el boton. Pasa por validación
                              <ValidateButton
                              onClick={() => validation(crediteaOTP)}
                              >
                              Validar código
                              </ValidateButton>
                          ) : (
                              <ValidateButton
                              onClick={() =>
                                  (document.getElementById("numberText").innerHTML =
                                  "Código incorrecto")
                              }
                              >
                              Validar código
                              </ValidateButton>
                          )}
                          <div
                              style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                              }}
                          >
                              <Option onClick={() => setChangeTel(true)}>Modificar número</Option>
                              <Option onClick={() => reenviarCodigo()}>Reenviar código</Option>
                          </div>
                          </>

                      )}
                    </Container>
                  ) : (
                    <>
                      <ContainerResult>
                        <DivFlex justify="space-between">
                          <div>
                            <ImageSections
                              width="90px"
                              widthweb="90px"
                              src={creditea}
                              className="img-fluid"
                              alt={"imagen"}
                            />
                            <br />
                            <DivStyle
                              align="left"
                              weight="bold"
                              mt="20px"
                              bott="10px"
                              className="roboto"
                              size="18px"
                              line="100%"
                              color="#07210A"
                            >
                              Creditea
                            </DivStyle>
                          </div>
                        </DivFlex>
                        {success ? (
                          <>
                            <TrainingText
                              className="roboto"
                              weight="400"
                              align="left"
                              mheight="58px"
                            >
                              <ul>
                                <li>
                                  <strong style={{ fontWeight: "700" }}>Monto: </strong>
                                  ${crediteaMonto}MXN
                                </li>
                                <li>
                                  <strong style={{ fontWeight: "700" }}>Plazo: </strong>{crediteaPlazo} Meses
                                </li>
                                <li>
                                  <strong style={{ fontWeight: "700" }}>
                                    Tasa de interés:{" "}
                                  </strong>{crediteaTasa}%
                                </li>
                                <li>
                                  <strong style={{ fontWeight: "700" }}>
                                    Pago quincenal:{" "}
                                  </strong>${crediteaPago}MXN
                                </li>
                              </ul>
                              <br />
                              <div>
                                Este es un crédito pre-aprobado. <br />
                                Para continuar con tu solicitud, aprueba los términos y
                                condiciones de Creditea.
                              </div>
                            </TrainingText>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                              }}
                            >
                              <Checkbox
                                type="checkbox"
                                id="continueCheck"
                                checked={mockCheck}
                                onClick={() => setMockCheck(!mockCheck)}
                              ></Checkbox>
                              <TrainingText weight="400px">
                                Sí he leído y acepto los{" "}
                                <a target="_blank" href="https://www.creditea.mx/terminos-y-condiciones" style={{ color: "rgba(246, 66, 130, 1)" }}>términos</a> y{" "}
                                <a target="_blank" href="https://www.creditea.mx/terminos-y-condiciones" style={{ color: "rgba(246, 66, 130, 1)" }}>condiciones</a> de
                                Creditea
                              </TrainingText>
                            </div>

                            <CardsBtn
                              color="#FFFFFF"
                              border="solid"
                              bordcolor="#F64282"
                              type="button"
                              windthG="100%"
                              style={{
                                fontFamily: "'Roboto', sans-serif !important",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "140%",

                                textAlign: "center",
                                letterSpacing: "-0.3px",
                              }}
                              onClick={() => validate()}
                            >
                              Continuar solicitud
                            </CardsBtn>
                          </>
                        ) : (
                          <>
                            <TrainingText
                              className="roboto"
                              weight="400"
                              align="left"
                              mheight="58px"
                              top="20px"
                            >
                              Debido a tu Score Crediticio, tu crédito personal no fue
                              preaprobado, pero hay más opciones para tí.
                            </TrainingText>
                            <CardsBtn
                              color="#FFFFFF"
                              border="solid"
                              bordcolor="#F64282"
                              type="button"
                              windthG="100%"
                              onClick={handleClose}
                              style={{
                                fontFamily: "'Roboto', sans-serif !important",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "15px",
                                lineHeight: "140%",
                                textAlign: "center",
                                letterSpacing: "-0.3px",
                              }}
                            >
                              Ver otras opciones de créditos personales
                            </CardsBtn>
                          </>
                        )}
                      </ContainerResult>
                    </>
                  )}
                </ContentHomeSection>
            </div>
        </Modal >
  );
};

export default CrediteaModals;
