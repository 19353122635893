/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, Fragment } from 'react';
import Carousel from 'react-elastic-carousel';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useLazyQuery, useMutation } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import {
  TrainingQuestion,
  QuestionsFlex,
  ImageSections,
  DivStyle,
  DivGray,
  Resultado,
  CardsTxt,
  CardsBtn,
  TrainingSectionFlex,
  DivCardsCC,
} from '../../assets/styles/Training.styled';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import SectionShare from '../../components/common/ShareSection';
import { Container, Col7, Col12 } from '../../components/common/common.styled';
// recovery
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_PRODUCTS_BY_IDTRAINING,
} from '../../queries';
import { CREATE_OPPORTUNITIES } from '../../mutations';
import { arraySessionsRutines } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';
import ModalVirality from '../../components/common/ModalVirality';
import InfoWoMonetizable from '../../components/common/InfoWoMonetizable';


const ShowAforesRp = ({ showAforeResponse, yesOrNot, abrirNuevoTab }) =>
  (
    <Fragment>
      <DivCardsCC width="70%" margin="auto 12%">
        <TrainingSectionFlex
          className="d-flex flex-row"
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          <Col12
            id="CarruselEmpresasContentHome"
            pt="0px"
            flex="0 0 100%"
            maxwidth="100%"
            display="flex"
          >
            <Carousel
              id="CarruselMetasLogros"
              styled={{ background: '#FAFAFA' }}
              transitionMs={1000}
            >
              {showAforeResponse[yesOrNot].map((card) => (
                <>
                  <Resultado
                    className="d-flex flex-column"
                    key={card.IDProduct}
                    bcolor="#ffffff"
                    ta="left"
                    marri="auto"
                    border="none"
                    wid="90%"
                    widthLg="80%"
                    hei="385px"
                    minh="385px"
                    minhweb="385px"
                  >
                    <div className="p-2" style={{ height: '82%' }}>
                      <ImageSections
                        width="auto"
                        hmob="50px"
                        ml="5%"
                        heightweb="50px"
                        src={card.ImageURL}
                        className="img-fluid"
                      />
                      <DivStyle
                        className="cabin"
                        mxh="45px"
                        weight="600"
                        align="left"
                        size="14px"
                        line="1.5em"
                        padd="12px 5% 0 5%"
                        color="#07210A"
                      >
                        {card.Name}
                      </DivStyle>
                      <DivGray bcolor="#fff" padd="3% 5% 0 5%">
                        <DivStyle align="left" mh="120px" size="14px">
                          <CardsTxt
                            color="#07210a"
                            className="roboto"
                            size="14px"
                            weight="600"
                          >
                            Beneficios
                          </CardsTxt>
                          {card.benefits.map((detalle) => {
                            if (
                              detalle.Name === 'Beneficio 1'
                              || detalle.Name === 'Beneficio 2'
                              || detalle.Name === 'Beneficio 3'
                              || detalle.Name === 'Beneficio 4'
                            ) {
                              return (
                                <li
                                  className="roboto styleBenef"
                                  style={{ color: '#07210a' }}
                                >
                                  {detalle.Description}
                                </li>
                              );
                            }
                          })}
                        </DivStyle>
                      </DivGray>
                    </div>
                    <div className="p-2" style={{ height: '20%' }}>
                      <CardsBtn
                        mtop="0px"
                        mtopw="0px"
                        min_web="100%"
                        min_w="100%"
                        onClick={() => abrirNuevoTab(card)}
                      >
                        {yesOrNot === 'Si'
                          ? 'Continuar'
                          : 'Hacer mi aportación'}
                      </CardsBtn>
                    </div>
                  </Resultado>
                </>
              ))}
            </Carousel>
          </Col12>
        </TrainingSectionFlex>
      </DivCardsCC>
      <ModalVirality />
      <br />
      <InfoWoMonetizable 
      paddingSm='0 5%'
        nameClient={sessionStorage.getItem('FirstName') + ' ' + (sessionStorage.getItem('MiddleName') || '') + 
        ' ' + sessionStorage.getItem('FathersLastName') + ' '+ sessionStorage.getItem('MothersLastName')}
        telClient={sessionStorage.getItem('CellPhone')}
        emailClient={sessionStorage.getItem('Email1')}
      />
      <SectionShare WO="Afores" />
    </Fragment>
  );
const Afores = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  currentRoutine,
  nextOrderRoutine,
}) => {
  const anchop = window.screen.width;
  const { actions } = ClickButton();
  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const [Rutine, setRutine] = useState({});
  const [countRecovery] = useState(0);
  const [knowAfore, setKnowAfore] = useState('');
  const [aforeYouBelong, setAforeYouBelong] = useState('');
  const [changeAfore, setChangeAfore] = useState('');
  const [idProductSelected, setIdProductSelected] = useState('');
  const [aforesContributions] = useState({});

  const [showAforeResponse] = useState({
    Si: [],
    No: [],
    contributions: [],
  });

  const [reviewScroll, setReviewScroll] = useState(true);
  const [arrayFilter] = useState([]);
  const [form, setForm] = useState({});
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }
              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setTimeout(() => setRecoveryRoutine('activa'), 1000);
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setTimeout(() => setRecoveryRoutine('activa'), 1000);
                setQuestions([]);
                break;
              } else {
                setTimeout(() => setRecoveryRoutine('activa'), 1000);
                setQuestions([]);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsByIdTraining }) {
      if (getProductsByIdTraining) {
        const response = JSON.parse(getProductsByIdTraining.response);
        const AFORES = response.filter((afore) => {
          if (afore.ProductSubCategory === 'Afore') {
            arrayFilter.push(afore.Name);
            return afore;
          }
        });
        const AFORES_CONTRIBUTIONS = response.filter((afore) => {
          if (afore.ProductSubCategory === 'Afore Aportaciones') {
            aforesContributions[afore.Name] = String(afore.IDProduct);
            return afore;
          }
        });

        const myAfore = AFORES_CONTRIBUTIONS.filter(
          (afore) => afore.IDProduct === parseInt(idProductSelected),
        );

        showAforeResponse.Si = AFORES;
        showAforeResponse.No = myAfore;
        showAforeResponse.contributions = AFORES_CONTRIBUTIONS;
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleChangeRutine = (data) => {
    setRutine(data);
  };
  const handleChange = (e, question = '') => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setKnowAfore(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeKnow' });
        break;
      case 1:
        setAforeYouBelong(val.value);
        setIdProductSelected(aforesContributions[val.value]);
        gsap.to(window, { duration: 1, scrollTo: '#activeYouBelong' });
        break;
      case 2:
        setChangeAfore(val.value);
        gsap.to(window, { duration: 1, scrollTo: '#activeChangeAfore' });
        break;
      default:
        console.log(val);
    }
  };

  const abrirNuevoTab = (product) => {
    const click = `ComenzarEntrenamiento_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2,
        idProducts: [product.IDProduct],
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '353',
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    sessionStorage.setItem('clickOutProduct', product.ClickOut);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    const win = window.open(product.ClickOut, '_blank');
    win.focus();
  };

  useEffect(() => {
    getProductsByIdTraining({ variables: { idTraining: '39' } });
  }, [aforeYouBelong]);
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setTimeout(() => setRecoveryRoutine('inactiva'), 1000);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && knowAfore === ''
        && document.getElementById('activeAforeMeta') !== null
        && document.getElementById('activeAforeMeta') !== undefined
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeAforeMeta' });
        setReviewScroll(false);
      }
    }, 2500);
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
    <div>
      <div id="activeAforeMeta"></div>
      <CardOne
        classCard="cardOne"
        classTop="topOne1"
        textbold="¡Vamos muy bien! 👍"
        text="Ahora te ayudare a cumplir tu meta para el retiro."
      />
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion className={knowAfore === '' ? '' : 'hidden'}>
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="0-0"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeKnow"></div>
      <CardTwo
        classCard={knowAfore !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={knowAfore}
        colorTraining={trainingColor}
      />
      {knowAfore === 'No' ? (
        <Fragment>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            children={
              <Fragment>
                <p>
                  <b>¿Qué debes hacer para lograr tu meta de retiro?</b> 🤔
                </p>
                <p>
                  1. Es muy importante que sepas en qué Afore estás. Este dato
                  determina el ahorro que emplearás cuando te retires
                  laboralmente. Para conocer tu Afore da{' '}
                  <a
                    style={{
                      textDecoration: 'underline',
                      color: '#F64282',
                      cursor: 'pointer',
                    }}
                    onClick={() => window.open(
                      'https://www.e-sar.com.mx/PortalEsar/public/consultaAforeInicio.do',
                      '_blank',
                    )
                    }
                  >
                    clic aquí.
                  </a>
                </p>
                <p>2. Conoce los beneficios que tu Afore te ofrece.</p>
                <p>
                  3. Haz aportaciones voluntarias a la Afore de tu elección.
                </p>
              </Fragment>
            }
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            textbold="Encuentra una Afore acorde a tus objetivos."
            text="Tenemos estas opciones para ti. Selecciona la que más se ajusta a tus necesidades para contactar un ejecutivo y darte mayor información."
          />
          <ShowAforesRp
            showAforeResponse={showAforeResponse}
            yesOrNot="Si"
            abrirNuevoTab={abrirNuevoTab}
          />
        </Fragment>
      ) : null}

      <QuestionsOrder
        classCard={knowAfore === 'Si' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion
        wiw="93%"
        mLg="7%"
        className={aforeYouBelong === '' && knowAfore === 'Si' ? '' : 'hidden'}
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            buttonImageType={true}
            arrayFilter={arrayFilter}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeYouBelong"></div>
      <CardTwo
        classCard={aforeYouBelong !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={aforeYouBelong}
        colorTraining={trainingColor}
      />

      <QuestionsOrder
        classCard={aforeYouBelong !== '' ? 'cardOne' : 'hidden'}
        orderRutine={currentRoutine}
        step={2}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
      />
      <TrainingQuestion
        className={changeAfore === '' && aforeYouBelong !== '' ? '' : 'hidden'}
      >
        <QuestionsFlex display="inline">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        </QuestionsFlex>
      </TrainingQuestion>
      <div id="activeChangeAfore"></div>
      <CardTwo
        classCard={changeAfore !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={changeAfore}
        colorTraining={trainingColor}
      />

      {changeAfore === 'Si' ? (
        <Fragment>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            textbold="Encuentra una Afore acorde a tus objetivos."
            text="Tenemos estas opciones para ti. Selecciona la que más se ajusta a tus necesidades para contactar un ejecutivo y darte mayor información."
          />
          <ShowAforesRp
            showAforeResponse={showAforeResponse}
            yesOrNot={changeAfore}
            abrirNuevoTab={abrirNuevoTab}
          />
        </Fragment>
      ) : null}
      {changeAfore === 'No' ? (
        <Fragment>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            textbold="¿Qué tal si hacemos una aportación voluntaria?"
            text="Puedes hacer crecer tu patrimonio depositando aportaciones voluntarias a tu cuenta, ya sea como método de ahorro o como una forma de inversión a plazo fijo."
          />
          <ShowAforesRp
            showAforeResponse={showAforeResponse}
            yesOrNot={changeAfore}
            abrirNuevoTab={abrirNuevoTab}
          />
        </Fragment>
      ) : null}
    </div>
  );
};

export default Afores;
