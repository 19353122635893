export const setMongoPersonalInfo = (form, updateBinacle, binacle) => {
  const timestap = new Date().toLocaleString();
  let value;
  const filterJson = {
    _id: sessionStorage.getItem('id_binacle'),
  };
  const mongoInputBinacle = {};
  const setVariables = (column, columnForm) => {
    const tmpValue = {
      value: '',
      timestap,
    };
    if (binacle.hasOwnProperty(column)) {
      value = binacle[column][binacle[column].length - 1].value;
      if (value !== form[columnForm]) {
        tmpValue.value = form[columnForm];
        binacle[column].push(tmpValue);
        mongoInputBinacle[column] = binacle[column];
      } else {
        console.log('mismo valor');
      }
    } else {
      tmpValue.value = form[columnForm];
      mongoInputBinacle[column] = [tmpValue];
    }
  };
  setVariables('FirstName', 'firstname');
  setVariables('MiddleName', 'secondname');
  setVariables('FathersLastName', 'lastname');
  setVariables('MothersLastName', 'motherslastname');
  setVariables('Gender', 'genero');
  setVariables('CellPhone', 'phone');
  setVariables('HomePhone', 'landline');
  setVariables('email', 'email');
  const filterString = JSON.stringify(filterJson);
  mongoInputBinacle.lastUpdate = timestap;
  const dataStringBinacle = JSON.stringify(mongoInputBinacle);
  const mongoInput = {
    input: {
      collection: 'binacle',
      data: dataStringBinacle,
      filter: filterString,
    },
  };
  updateBinacle({ variables: mongoInput });
};

export const setMongoInfoBinacle = (form, updateBinacle, binacle, keys) => {
  const timestap = new Date().toLocaleString();
  let value;
  const filterJson = {
    _id: sessionStorage.getItem('id_binacle'),
  };
  const mongoInputBinacle = {};
  const setVariables = (column, columnForm) => {
    const tmpValue = {
      value: '',
      timestap,
    };
    if (binacle.hasOwnProperty(column)) {
      value = binacle[column][binacle[column].length - 1].value;
      if (value !== form[columnForm]) {
        tmpValue.value = form[columnForm];
        binacle[column].push(tmpValue);
        mongoInputBinacle[column] = binacle[column];
      } else {
        console.log('mismo valor');
      }
    } else {
      tmpValue.value = form[columnForm];
      mongoInputBinacle[column] = [tmpValue];
    }
  };
  keys.forEach((key) => {
    setVariables(key.column, key.form);
  });
  mongoInputBinacle.lastUpdate = timestap;
  const filterString = JSON.stringify(filterJson);
  const dataStringBinacle = JSON.stringify(mongoInputBinacle);
  const mongoInput = {
    input: {
      collection: 'binacle',
      data: dataStringBinacle,
      filter: filterString,
    },
  };
  updateBinacle({ variables: mongoInput });
};
