import React, { Fragment } from 'react';
import {
  CardsBtn,
  TrainingMain,
  DivFlex,
  TrainingSubtitles,
  TrainingDivSection2,
  TrainingSectionGray,
  TrainingText,
  DivHeader_ValueMoney,
} from '../../assets/styles/Training.styled';
import { Feed_Main } from '../../assets/styles/Feed.styled';
import TasksStatus from './TasksStatus';
import { Container } from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle25,
  Triangle,
} from '../common/Home.styled';

function RegistroDiarioTareas({ name, data }) {
  return (
    <Fragment>
      <Container
        className="col-12"
        width=""
        mt="40px"
        displayweb="flex"
        displaymobile="none"
        widthMobile=""
        mtMobile="0px"
        background=" "
        height="289.406px"
      >
        <Rectangle23
          Ptop="81px"
          Pleft="3%"
          width="100px"
          height="100px"
          background={'#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Triangle
          width="50px"
          height="50px"
          marginleft="-50px"
          margintop="239px"
          transform="rotate(224deg)"
          widthborder="50px solid transparent"
          widthborderColor={'50px solid' + '#FA9878;'}
        />
        <Rectangle23
          Ptop="105px"
          Pleft="15%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(9.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={'#FACFA9'}
          bradius="14px"
          left="17%"
          top="242px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="100px"
          Pleft="24%"
          transform="rotate(-33.24deg)"
          width="100px"
          height="100px"
          background={'#E3A75F'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="142px"
          Pleft="38%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(9.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={'#FACFA9;'}
          bradius="14px"
          left="49%"
          top="89px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="166px"
          Pleft="52%"
          transform="rotate(62.76deg)"
          width="100px"
          height="100px"
          background={'#E3A75F;'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="81px"
          Pleft="64%"
          width="100px"
          height="100px"
          background={'#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
        />
        <Rectangle23
          Ptop="250px"
          Pleft="70%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle23
          Ptop="19px"
          Pleft="80%"
          width="100px"
          height="100px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle25
          positionD="absolute"
          background={'#FACFA9;'}
          bradius="14px"
          left="85%"
          top="242px"
          width="30px"
          height="35px"
          displayM=" "
          displayD=" "
        />
        <Rectangle23
          Ptop="150px"
          Pleft="95%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          transform="rotate(47.24deg)"
        />
        <Rectangle23
          Ptop="243px"
          Pleft="97%"
          transform="rotate(62.76deg)"
          width="100px"
          height="100px"
          background={'#E3A75F;'}
          position="absolute"
          displayD=" "
          displayM=""
        />
      </Container>
      <Container
        width="100%"
        // mtMobile="40px"
        mt="40px"
        displayweb="none"
        displaymobile="flex"
        widthMobile="100%"
        mtMobile="0px"
        background=" "
        height="237.031px"
      >
        <Rectangle23
          left="2%"
          Ptop="81px"
          Pleft="3%"
          width="60px"
          height="60px"
          background={'#F5D495'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="10%"
        />
        <Triangle
          width="50px"
          height="50px"
          marginleft="-5px"
          margintop="180px"
          transform="rotate(452deg)"
          widthborder="50px solid transparent"
          widthborderColor={'50px solid' + '#FA9878;'}
        />
        <Rectangle23
          transform="rotate(11.76deg)"
          left="34%"
          Ptop="90px"
          Pleft="3%"
          width="25px"
          height="25px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="35%"
        />
        <Rectangle23
          left="60%"
          Ptop="81px"
          Pleft="3%"
          width="60px"
          height="60px"
          background={'#E3A75F'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="55%"
        />
        <Rectangle23
          transform="rotate(11.76deg)"
          left="90%"
          Ptop="105px"
          Pleft="3%"
          width="40px"
          height="40px"
          background={'#FA9878'}
          position="absolute"
          displayD=" "
          displayM=""
          leftMb="82%"
        />
        <Rectangle25
          topM="0px"
          leftM="70%"
          positionD="absolute"
          background={'#FACFA9'}
          bradius="20px"
          left="17%"
          top="242px"
          width="50px"
          height="55px"
          displayM=" "
          displayD=" "
          leftMb="75%"
        />
      </Container>
      <TrainingMain mt="50px" mtw="0" className="" id="top">
        <br />
        <TrainingSectionGray back="#fafafa">
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display=" "
              mtop=" "
              background="transparent"
            >
              <TrainingDivSection2
                paddiw="0% 10% 3% 10%"
                paddi="0% 5% 5% 5%"
                back="#fafafa"
              >
                <DivHeader_ValueMoney marginTop="-150px" marginBottom="100px">
                  <TrainingSubtitles
                    size="22px"
                    sizew="25px"
                    fw="600"
                    align="center"
                    alignweb="center"
                    className="cabin"
                  >
                    ¡Hola {sessionStorage.getItem('FirstName')}! Este es el
                    avance de las tareas de {name || 'Gael'} al día de
                    hoy.
                  </TrainingSubtitles>
                </DivHeader_ValueMoney>
                <TrainingText
                  size="18px"
                  sizew="23px"
                  weight="600"
                  className="roboto"
                  align="center"
                  alignweb="center"
                  ls="-0.2px"
                >
                  Registro diario de tareas
                </TrainingText>
                <DivFlex widthweb="70%" marweb="auto">
                  <div>
                    <TrainingText size="18px" sizew="21px" weight="bold">
                      Tarea(s)
                    </TrainingText>
                  </div>
                  <div>
                    <TrainingText size="18px" sizew="21px" weight="bold">
                      Estatus
                    </TrainingText>
                  </div>
                </DivFlex>
                <TasksStatus datos={data}></TasksStatus>
                <TrainingText
                  size="14px"
                  weight="400"
                  className="roboto"
                  align="center"
                  alignweb="center"
                  ls="-0.2px"
                >
                  Recuerde que el día domingo {name || 'Gael'} podrá
                  reclamar las recompensas por el cumplimiento de sus tareas
                  diarias.
                </TrainingText>
              </TrainingDivSection2>
              <TrainingSectionGray
                wiw="670px"
                mw="auto"
                back="#FAFAFA"
                mtop="0"
              >
                <Container
                  width="100%"
                  mt="0rem"
                  widthMobile="90%"
                  mtMobile="0rem"
                >
                  <CardsBtn
                    onClick={console.log('click')}
                    windthmd="90%"
                    minmd="40%"
                    mleftmd="5%"
                    mrightmd="auto"
                    windthG="400px"
                    type="button"
                    min_w="400px"
                    min_web="400px"
                    min_mini="80%"
                    mleftmini="10%"
                    mrightmini="10%"
                    mleft="15%"
                    mright="15%"
                    mleftweb="18%"
                    mrightweb="auto"
                    mtop="20px"
                  >
                    Enterado
                  </CardsBtn>
                </Container>
              </TrainingSectionGray>
            </Feed_Main>
          </Feed_Main>
        </TrainingSectionGray>
      </TrainingMain>
    </Fragment>
  );
}
export default RegistroDiarioTareas;
