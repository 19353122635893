/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import {
  ModalBackground,
  ModalBackCC,
  ModalHeader,
  ModalBody,
  Label_txt,
  Home_hr,
} from "../../assets/styles/Home.styled";
import { Rectangle23, Title1 } from "./Home.styled";
import { CardsBtn } from "../../assets/styles/Training.styled";
import { Image } from "./common.styled";
import {
  INSERT_DAT_VIRALITY,
  GENERATE_SHORTENED_LINK,
  COUNT_MODAL_VIEWS,
} from "../../mutations";
import { viralityUTM } from "../../Helpers/HelperUTM";
import { shareCoru2 } from "../../Helpers/index";
import Star from "../../assets/img/premiosModal/Figures/Star.svg";
import Mancha from "../../assets/img/premiosModal/Figures/Manchas.svg";
import Premio from "../../assets/img/premiosModal/Figures/Premio.svg";
import PremioRecarga from "../../assets/img/premiosModal/Figures/RecargaIcon.svg";
import Cine from "../../assets/img/premiosModal/boletoscine.svg";
import Zoom from "../../assets/img/premiosModal/zoom.svg";
import Saldo from "../../assets/img/premiosModal/saldo.svg";

const ModalVirality2 = (props) => {
  const { nameactions } = props;
  console.log(props.openMod);
  const urlPDF =
    "https://resources-new-coru.s3.us-east-2.amazonaws.com/downloadable/TyC+Rewards+Octubre.pdf";
  const name = sessionStorage.getItem("FirstName");
  const [clickRecomienda, setClickRecomienda] = useState(1);
  const [rewardSelected, setRewardSelected] = useState("");
  const [modalState, setModalState] = useState("flex");
  const [operadorTel, setOperadorTel] = useState("");
  const [rewardsData, setRewardsData] = useState([
    ["Boletos de Cine", "Boletos de Cine", Cine],
    ["Coaching de Finanzas Personales", "Coaching finanzas", Zoom],
    ["Saldo para Celular", "Saldo celular", Saldo],
    // ['Café Starbucks', 'Café Starbucks'],
    // ['Saldo para Google Play', 'Saldo Google'],
    // ['Saldo para Uber', 'Saldo para Uber'],
    // ['Tarjeta Amazon', 'Tarjeta 🎁 Amazon'],
    // ['Tarjeta Netflix', 'Tarjeta 🎁 Netflix'],
    // ['Tarjeta Spotify', 'Tarjeta 🎁 Spotify'],
    // ['Tarjeta Xbox', 'Tarjeta 🎁 Xbox'],
  ]);
  const [counterModal] = useMutation(COUNT_MODAL_VIEWS, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.response !== "") {
        console.log("SUCCESS MONGO COUNTER VIEW");
      }
    },
  });
  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    onCompleted({ generateShortendLink }) {
      const url = generateShortendLink.response;
      return url;
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === "success") {
        const { response } = insertDatVirality;
        const redirectLink = response.split(",")[0];
        const socialNetwork = response.split(",")[1];
        const announcement = sessionStorage.getItem("auxAnnouncement");
        let text = '';
        let redirectLinkText = '';
        const IdTraining = sessionStorage.getItem("idTraining");
        const msg1 = ["35", "2", "3", "36", "28", "21", "15", "31", "11", "32", "25", "42", "34", "26", "12", "37", "14", "44", "33", "20", "13"];
        const msg2 = ["41"];
        const msg3 = ["9", "29"];
        const msg4 = ["17", "23", "18", "27", "22"];
        const utmData = response.split('&');
        const utm = `?utm_source=Coru2&utm_medium=referral&${utmData[2]}&${utmData[3]}`;
        if (msg1.includes(IdTraining)) {
          text = 'Regístrate en Coru, obtén tu tarjeta de crédito y ayúdame a ganar. Además, ¡recomienda y gana tú también! 🎁 ';
          redirectLinkText = `https://coru.com/kanda/wo/cc-ft${utm}`;
        } else if (msg2.includes(IdTraining)) {
          text = 'Regístrate en Coru, conoce tu score crediticio y ayúdame a ganar. Además, ¡recomienda y gana tú también! 🎁 ';
          redirectLinkText = `https://coru.com/wo/know-your-score${utm}`;
        } else if (msg3.includes(IdTraining)) {
          text = 'Regístrate en Coru, obtén tu préstamo personal y ayúdame a ganar. Además, ¡recomienda y gana tú también! 🎁 ';
          redirectLinkText = `https://coru.com/kanda/wo/pl-ft${utm}`;
        } else if (msg4.includes(IdTraining)) {
          text = 'Regístrate en Coru, conoce cómo puedes comenzar a invertir y ayúdame a ganar. Además, ¡recomienda y gana tú también! 🎁 ';
          redirectLinkText = `https://coru.com/wo/investment-test${utm}`;
        } else {
          text = 'Regístrate en Coru, obtén tu tarjeta de crédito y ayúdame a ganar. Además, ¡recomienda y gana tú también! 🎁 ';
          redirectLinkText = `https://coru.com/kanda/wo/cc-ft${utm}`;
        }
        const textWa =
          `https://api.whatsapp.com/send?text=${text}`;
        // const textWa = `whatsapp://send?text=%20Regístrate%20en%20Coru,%20obtén%20tu%20tarjeta%20de%20crédito%20y%20ayúdame%20a%20ganar.%20%20Además,%20¡recomienda%20y%20gana%20tu%20también!%20🎁%20%20`;
        shareCoru2(
          socialNetwork,
          announcement,
          redirectLinkText,
          generateShortendLink,
          false,
          textWa
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleChangeRadio = (e) => {
    const { value } = e.target;
    setOperadorTel(value);
  };

  const handleShare = () => {
    const cellPhone = sessionStorage.getItem("CellPhone");
    const name = sessionStorage.getItem("FirstName");
    const email = sessionStorage.getItem("Email1");
    sessionStorage.setItem("auxAnnouncement", "CoruRewardsCC");
    viralityUTM(
      insertDatVirality,
      "/wo/card-funnel",
      sessionStorage.getItem("trainingName"),
      "Whatsapp", // socialNetwork,
      "",
      "",
      "",
      false,
      false,
      rewardSelected,
      cellPhone,
      name,
      email,
      operadorTel
    );
    setModalState(false);
  };

  const handleChangeModal = () => setClickRecomienda(clickRecomienda + 1);

  const handleClickReward = (rewardSelect) => {
    setRewardSelected(rewardSelect);
    handleChangeModal();
  };

  useEffect(() => {
    const IDUser = sessionStorage.getItem("idUser");
    const IdTraining = sessionStorage.getItem("idTraining") || '0';
    const data = JSON.stringify({
      IDUser,
      View_Modal: true,
      IdTraining,
      action: nameactions,
    });
    if (IDUser && IDUser !== "" && IdTraining && IdTraining !== "") {
      counterModal({
        variables: {
          input: {
            collection: "Rewards_count",
            data,
          },
        },
      });
    } 
  }, []);

  useEffect(() => {
    if (props.showModal) {
      document.body.style.overflow = "hidden";
      window.document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      window.document.body.style.overflow = "auto";
    }
    setRewardsData(() => rewardsData.sort(() => Math.random() - 0.5));
    return () => {
      document.body.style.overflow = "auto";
      window.document.body.style.overflow = "auto";
    };
  }, [props.showModal, rewardsData]);

  useEffect(() => {
    setModalState(props.showModal ? "flex" : "none");
    // setModalState("none");
  }, [props.showModal]);

  const ContainerRewards = styled.div`
    height: 10.5rem;
    width: 100%;
    margin: 20px 5px 20px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 95%;
      margin-top: 10px;
    }
    @media (max-height: 490px) {
      width: 100%;
      margin-top: 10px;
      height: 77.5%;
    }
  `;
  const Rewards = styled.div`
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    z-index: 2;
    cursor: pointer;
    img {
      width: 4rem;
      height: 2rem;
      margin-right: 5px;
    }
    @media (max-width: 768px) {
      margin-top: 5px;
      span {
        font-size: 12px;
      }
      img {
        width: 4rem;
        height: 1.85rem;
        margin-right: 2px;
      }
    }
    @media (max-width: 290px) {
      margin-top: 5px;
      span {
        font-size: 10px;
      }
    }
  `;

  return (
    <>
      {clickRecomienda === 1 ? (
        <>
          <ModalBackground bradius="16px" display={modalState}>
            <ModalBackCC
              bradius="16px"
              bcolor="#0b0146"
              mwidthM="21.5rem"
              height="30rem"
              paddingM="1.65rem"
              height320="95%"
            >
              <Rectangle23
                position="absolute"
                background="none"
                // Mleft="-30px"
                // Mtop="-30px"
                width="20px"
                height="20px"
                heightM="20px"
                widthM="20.36px"
                left=" "
              >
                <img src={Star} style={{ height: "100%" }} alt="" />
              </Rectangle23>
              <Rectangle23
                position="absolute"
                background="none"
                Mleft="90px"
                Mtop="-9px"
                width="28px"
                height="32px"
                heightM="20px"
                widthM="20.36px"
                transform="rotate(45deg)"
                left="58px"
                top="-27px"
              >
                <img src={Star} style={{ height: "100%" }} alt="" />
              </Rectangle23>
              <Rectangle23
                position="absolute"
                background="none"
                Mleft="190px"
                Mtop="-30px"
                width="30.36px"
                height="30px"
                heightM="20px"
                widthM="20.36px"
                transform="rotate(45deg)"
                left="171px"
              >
                <img src={Star} style={{ height: "100%" }} alt="" />
              </Rectangle23>
              <Rectangle23
                position="absolute"
                background="none"
                Mleft="300px"
                Mtop="40px"
                width="42.53px"
                height="45.22px"
                heightM="20px"
                widthM="20.36px"
                transform="rotate(45deg)"
                left=" "
              >
                <img src={Star} style={{ height: "100%" }} alt="" />
              </Rectangle23>
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "10px",
                  fontSize: "18px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "white",
                }}
                onClick={() => props.setShowModal(false)}
              >
                <CloseIcon />
              </div>
              <ModalHeader
                heightM={clickRecomienda !== 0 ? "auto" : "15%"}
                height={clickRecomienda !== 0 ? "auto" : "15%"}
                mtopM="10%"
                mtop="15%"
              >
                <Title1
                  size="30px"
                  aling="center"
                  mt="1px"
                  sizeM="30px"
                  mleftM=" "
                  mtopM="0"
                  heightM=" "
                  color="white"
                >
                  ¡Comparte y gana
                  <br /> con Coru!
                </Title1>
              </ModalHeader>
              <ModalBody mtop="1rem">
                <span
                  style={{
                    padding: "2px auto",
                    fontSize: "1rem",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Elige uno de estos premios:
                </span>
                <ContainerRewards>
                  {rewardsData.map((reward, index) => (
                    <Rewards
                      key={index}
                      onClick={() => handleClickReward(reward[0])}
                    >
                      <div>
                        <img src={reward[2]} alt={reward[1]} />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <span>{reward[1]}</span>
                      </div>
                    </Rewards>
                  ))}
                </ContainerRewards>
                <div
                  style={{
                    fontSize: "12px",
                    marginTop: "1rem",
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  *LLimitado a 10 premios acreditados por persona.
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  **Al dar click aceptas{" "}
                  <span style={{ color: "white" }}>
                    <u
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(urlPDF, "_blank").focus();
                      }}
                    >
                      TyC
                    </u>
                  </span>
                </div>
              </ModalBody>
            </ModalBackCC>
          </ModalBackground>
        </>
      ) : clickRecomienda === 2 ? (
        rewardSelected === "Saldo para Celular" ? (
          <>
            <ModalBackground bradius="16px" display={modalState}>
              <ModalBackCC
                bradius="16px"
                bcolor="#0b0146"
                mwidthM="21.5rem"
                height="auto"
                paddingM="2rem 1.65rem 1rem"
                height320="98%"
              >
                <Rectangle23
                  position="absolute"
                  background="none"
                  // Mleft="-30px"
                  // Mtop="-30px"
                  width="70%"
                  height="70%"
                  widthM="70%"
                  heightM="70%"
                  style={{ zIndex: "1" }}
                  Ptop="-1%"
                  left="-5%"
                  top="10%"
                >
                  <img src={Mancha} style={{ height: "100%" }} alt="" />
                </Rectangle23>
                <div
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "10px",
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  onClick={() => props.setShowModal(false)}
                >
                  <CloseIcon />
                </div>
                <ModalHeader>
                  <Image src={PremioRecarga} width="90px" bottom="20px" />
                  <Title1
                    size="30px"
                    aling="center"
                    sizeM="30px"
                    mleftM=" "
                    mtopM="0"
                    heightM=" "
                    mt="0"
                    color="white"
                  >
                    ¿Cuál es tu proveedor <br />
                    de telefonía?
                  </Title1>
                </ModalHeader>
                <ModalBody mtop="12%">
                  <form
                    style={{
                      padding: "0px 1rem",
                      display: "flex",
                      alignItems: "stretch",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: "99",
                      }}
                    >
                      <Label_txt
                        style={{
                          color: "white",
                          fontWeight: "400",
                          pointerEvents: "none",
                        }}
                      >
                        AT{"&"}T{" "}
                      </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="ATT"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          cursor: "pointer",
                        }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                    <Home_hr
                      width="100%"
                      marginTop=".5rem"
                      marginBottom=".5rem"
                      border="1px solid #FFFF"
                      mlM="0rem"
                      mrM="0rem"
                    />
                    <div
                      style={{
                        display: "flex",
                        zIndex: "99",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label_txt
                        style={{
                          color: "white",
                          fontWeight: "400",
                          pointerEvents: "none",
                        }}
                      >
                        Telcel{" "}
                      </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="TELCEL"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          cursor: "pointer",
                        }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                    <Home_hr
                      width="100%"
                      marginTop=".5rem"
                      marginBottom=".5rem"
                      border="1px solid #FFFF"
                      mlM="0rem"
                      mrM="0rem"
                    />
                    <div
                      style={{
                        display: "flex",
                        zIndex: "99",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label_txt
                        style={{
                          color: "white",
                          fontWeight: "400",
                          pointerEvents: "none",
                        }}
                      >
                        Movistar{" "}
                      </Label_txt>
                      <input
                        name="company"
                        type="radio"
                        value="MOVISTAR"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          cursor: "pointer",
                        }}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                    <Home_hr
                      width="100%"
                      marginTop=".5rem"
                      marginBottom=".5rem"
                      border="1px solid #FFFF"
                      mlM="0rem"
                      mrM="0rem"
                    />
                  </form>
                  <CardsBtn
                    mtopmd="10px"
                    mtop="15px"
                    mtopw="10px"
                    min_w="100%"
                    min_web="100%"
                    border="16px"
                    onClick={() => setClickRecomienda(3)}
                  >
                    Continuar
                  </CardsBtn>
                  <CardsBtn
                    mtopmd="5px"
                    mtop="5px"
                    mtopw="10px"
                    min_w="100%"
                    min_web="100%"
                    back="#0b0146"
                    color="#F64282"
                    border="0px solid #0b0146"
                    backHover="none"
                    onClick={() => setClickRecomienda(1)}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      Ir por otro premio
                    </span>
                  </CardsBtn>
                </ModalBody>
              </ModalBackCC>
            </ModalBackground>
          </>
        ) : (
          <>{setClickRecomienda(3)}</>
        )
      ) : clickRecomienda === 3 ? (
        <>
          <ModalBackground bradius="16px" display={modalState}>
            <ModalBackCC
              bradius="16px"
              bcolor="#0b0146"
              mwidthM="21.5rem"
              height="32rem"
              paddingM="1.65rem"
              height768="60%"
              height375="75%"
              height320="95%"
            >
              <Rectangle23
                position="absolute"
                background="none"
                width="70%"
                height="70%"
                widthM="70%"
                heightM="70%"
                Ptop="-1%"
                left="5%"
                Mleft="5%"
                top="-2.5%"
              >
                <img src={Mancha} style={{ height: "100%" }} alt="" />
              </Rectangle23>
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "10px",
                  fontSize: "18px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "white",
                }}
                onClick={() => props.setShowModal(false)}
              >
                <CloseIcon />
              </div>
              <ModalHeader
                heightM={clickRecomienda != "" ? "100%" : "15%"}
                height={clickRecomienda != "" ? "100%" : "15%"}
              >
                <Image src={Premio} width="60px" bottom="20px" />
              </ModalHeader>
              <ModalBody mtop="auto">
                <span style={{ padding: "12px 1px", color: "white" }}>
                  Elegiste {rewardSelected}.
                </span>
                <br />
                <br />
                <span style={{ padding: "12px 1px", color: "white" }}>
                  Por cada registro válido de tus invitados, ¡podrás ganar un
                  premio diferente! &#127873;
                </span>
                <br />
                <br />
                <span style={{ padding: "12px 1px", color: "white" }}>
                  Comparte ahora:
                </span>
                <CardsBtn
                  mtopmd="20px"
                  mtop="20px"
                  mtopw="20px"
                  min_w="100%"
                  min_web="100%"
                  border="16px"
                  onClick={() => handleShare()}
                >
                  Compartir por WhatsApp
                </CardsBtn>
                <CardsBtn
                  mtopmd="5px"
                  mtop="5px"
                  mtopw="10px"
                  min_w="100%"
                  min_web="100%"
                  back="#0b0146"
                  color="#F64282"
                  border="0px solid #0b0146"
                  backHover="none"
                  onClick={() => setClickRecomienda(1)}
                >
                  <span style={{ textDecoration: "underline" }}>
                    Ir por otro premio
                  </span>
                </CardsBtn>
              </ModalBody>
            </ModalBackCC>
          </ModalBackground>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModalVirality2;
