import React from 'react';
import { CardDescriptionStyled } from './common.styled';

// eslint-disable-next-line max-len
const CardDescription = ({
  img, title, subtitle, hoverColor, number,
}) => <CardDescriptionStyled hoverBC={hoverColor} enumerated={Boolean(number)}>
        <div className="absolute-content">
            <img src={img} alt="" className="top-img"/>
        </div>
        <div className="text-container">
            {number ? <div className="number">{number}</div> : undefined}
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
        </div>
    </CardDescriptionStyled>;

export default CardDescription;
