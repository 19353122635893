import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import ModalCcPassword from './ModalCcNewPassword';
import FullScreenDialogCC from './modalComparativo';
import { GET_ROUTINES_BY_ID_TRAINING } from '../../../queries';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const Cc2Comparativo = () => {
  const classes = useStyles();

  let routines = [];
  let currentRoutine = {};
  const curretTraining = JSON.parse(sessionStorage.getItem('currentTraining'));
  /** [useQuery sirve para gardar las preguntas de la rutina]
   * [variables description]
   * @type {Object}
   */
  const { data, loading, error } = useQuery(GET_ROUTINES_BY_ID_TRAINING, {
    variables: {
      idTraining: parseInt(curretTraining.idTrainning),
      orderRutine: 2,
    },
  });
  useEffect(() => {
    if (data) {
      routines = JSON.parse(data.getRoutinesByIdTraining);
      for (const i in routines) {
        currentRoutine = routines[i];
        console.log(currentRoutine);
        break;
      }
    }
  }, [data, currentRoutine]);

  const guardar = () => {
    window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/step3-infopersonal';
  };
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <Fragment>
      {sessionStorage.getItem('modalCCcomparativo') === 'true' ? (
        <FullScreenDialogCC />
      ) : null}
      {sessionStorage.getItem('modalCCPassword') === 'true' ? (
        <ModalCcPassword />
      ) : null}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '200px',
          }}
        >
          <Card className={classes.root} style={{ margin: '10px' }}>
            <CardActionArea>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                style={{ textAlign: 'center' }}
              >
                Tarjeta de Crédito Citibanamex Rewards
              </Typography>
              <CardMedia component="img" alt="" height="40" image="" title="" />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {bull} 1 ThankYou Point por cada dólar gastado (o su
                  equivalente en pesos).
                  <br />
                  {bull} Realiza compras más seguras usando tu NIP
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button variant="contained" color="secondary">
                Solicitar
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.root}>
            <CardActionArea>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                style={{ textAlign: 'center' }}
              >
                Tarjeta vive BBVA Bancomer
              </Typography>
              <CardMedia component="img" alt="" height="40" image="" title="" />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {bull} Compra seguro en línea con BBVA wallet <br />
                  {bull} Al comprar con tu tarjeta de crédito ganas Puntos BBVA.
                  No aplican compras a meses sin interes.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button variant="contained" color="secondary">
                Solicitar
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.root} style={{ margin: '10px' }}>
            <CardActionArea>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                style={{ textAlign: 'center' }}
              >
                Falabella Soriana Mastercard
              </Typography>
              <CardMedia component="img" alt="" height="40" image="" title="" />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {bull} 3% dinero electrónico al pagar en Soriana, Soriana.com
                  y Citi Club <br />
                  {bull} 1% dinero electrónico al pagar en otros
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button variant="contained" color="secondary">
                Solicitar
              </Button>
            </CardActions>
          </Card>
          <Button
            variant="contained"
            color="#C0C0C0"
            onClick={guardar}
            style={{ width: '28%', outline: 'none' }}
          >
            Mostras más
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Cc2Comparativo;
