import React, { useState, Fragment, useEffect } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  CHECK_TRAINING_FINISHED,
  GET_TRAINING_COLOR,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import WaitList from '../../components/Training/WaitList';
import SuccessBullets from '../../components/Training/SuccessBullets';
import imgWo from '../../assets/img/anacoach/catalogo.svg';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import {
  Container,
  Col7,
} from '../../components/common/common.styled';

import RoutinesOrderInvestmentCatalog from './RoutinesOrderInvestmentCatalog';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import WOlayout from '../../components/layouts/WOlayout';
import Kanda from '../../components/Kanda/Kanda';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';

const items2 = ['Catálogo de Inversiones'];
const idTraining = 18;
const InvestmentCatalog = () => {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', idTraining);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  // End ValidWaitList
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(idTraining);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();
  const anchop = window.screen.width;
  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      setactive3(true);
    }, 13000);
  }, [active2]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;

  // Valid Finished WO
  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const allowOverwrite = sessionStorage.getItem(`overwrite_WO_${idTraining}`) || 'false';
        const firstName = sessionStorage.getItem('FirstName') || '';
        if (trainingInfo.avance === 100 && allowOverwrite !== 'true') {
          setFinished(true);
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            bullets: trainingInfo.bullets,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: `¡Hola, ${firstName}!`,
            txt: `Realizaste este entrenamiento el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} y ahora sabes:`,
          });
        }

        if (allowOverwrite === 'true') {
          setOrderRutine(2);
          sessionStorage.removeItem('MontoInversión');
          sessionStorage.removeItem('PlazoInversión');
          sessionStorage.removeItem('ProveedorÓptimo');
          sessionStorage.removeItem('TasaRendimiento');
          sessionStorage.removeItem('TipoHerramientaInversión');
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser !== null) {
      checkTrainingFinished({
        variables: {
          idUser: parseInt(idUser),
          idTraining: currentTraining,
        },
      });
    }
  }, []);
  // End FinishedWO

  // copia y pega estas mutaciones para crear un nuevo entrenamiento
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setactive3(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario_existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            // console.log("existe el usuario pero  esta logueado");
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  const [recomendaciones, setRecomendaciones] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [firstRoutineCheck, setFirstRoutineCheck] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
    if (!firstRoutineCheck) {
      saveAdvance(1);
      setFirstRoutineCheck(true);
    }
  }, []);

  /** guarda y actualiza el status del entrenamiento */

  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].QuestionOption === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };

  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else >>>>', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: currentTraining },
      });
    } else {
      setPercentage('0');
    }
  }, []);
  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
    } = getQueryVariableHashtagDeeplink('InvestmentCatalog');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    sessionStorage.setItem('pagename', 'InvestmentCatalog');
    sessionStorage.setItem('hostname', window.location.hostname);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
  }, []);
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  const feacturesArray = useState([
    {
      text: 'Tasas de inversión',
      color: '#99F2A8',
    },
    {
      text: 'Tener más detalles sobre una inversión',
      color: '#99F2A8',
    },
    {
      text: 'Distintos proveedores para elegir donde invertir tu dinero',
      color: '#99F2A8',
    },
  ]);
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '20 minutos',
    imgWo: { imgWo },
    txtsubtitle: 'Conoce las mejores empresas para invertir',
  };

  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Las Mejores Empresas para Invertir 2021 | Coru</title>
        <link
          rel="canonical"
          href="https://coru.com/wo/investment-catalog"
        />
        <meta
          name="keywords"
          content="las mejores empresas para invertir 2021"
        />
        <meta
          name="description"
          content="Las mejores empresas para invertir son las que te dan mejor rendmiento, conoce cuáles son y cómo puedes lograr un mejor resultado en tu inversión."
        />
      </Helmet>
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            <TrainingAnimateFunnel
              bcolor={colorTraining}
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <BarraInfoWO propied={propied} />
              {scroll1 > 0 ? <BarraScroll /> : null}
              <TrainingLine
                id="line-percent-gray"
                mt=""
                mtw="404px"
              ></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
            </TrainingAnimateFunnel>
            <div style={{position: 'relative'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <ModalDataPersonalInitWO isData={isData} setData={setisData} />
                {!finished ? (
                  <Fragment>
                    <DataPersonalInitWO
                      Date={Date}
                      hora={hora}
                      orderRutine={orderRutine}
                      setOrderRutine={setOrderRutine}
                      currentTraining={currentTraining}
                      createJsonInput={createJsonInput}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                    <TrainingSectionGray
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            classAna="trueAna"
                            title="¡Hola! Soy Ana, tu coach financiero"
                            text="Te ayudaré a buscar alternativas para que realices la mejor inversión"
                          />
                          {active1 ? (
                            <Starttraining
                              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                              title="Catálogo de inversiones"
                              text="En este entrenamiento te mostraremos un listado de algunas empresas con las que podrás invertir tu dinero cómodamente"
                            />
                          ) : null}
                          {active2 ? (
                            <Fragment>
                              <Features
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="¿Qué es lo que encontrarás en este entrenamiento?"
                                Arraytext={feacturesArray}
                              />
                            </Fragment>
                          ) : null}
                        </div>
                        {active3 ? (
                          <Fragment>
                            <div className="marginComentLgSuccess1">
                              <Starttraining
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="Iniciemos esta aventura..."
                                text=" A continuación te haré algunas preguntas para conocer tu tipo de inversión:"
                              />
                            </div>

                            <div
                              className={
                                orderRutine === 3
                                  ? 'marginComentLgSuccess1'
                                  : 'marginComentLgSuccess1'
                              }
                            >
                              <TrainingSectionGray
                                wiw="100%"
                                mw="auto"
                                back="#FAFAFA"
                                mtop="0"
                              >
                                <TrainingDivSection2
                                  paddiw="0"
                                  paddi="0"
                                  back="#FAFAFA"
                                >
                                  <RoutinesOrderInvestmentCatalog
                                    Date={stateDate}
                                    hora={hora}
                                    orderRutine={orderRutine}
                                    setOrderRutine={setOrderRutine}
                                    currentTraining={currentTraining}
                                    createJsonInput={createJsonInput}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    saveAdvance={saveAdvance}
                                    trainingColor={'#99F2A8'}
                                  />
                                </TrainingDivSection2>
                              </TrainingSectionGray>
                            </div>
                            {recomendaciones && orderRutine === 'final' ? (
                              <Fragment>
                                <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-10 offset-md-1 col-xs-12">
                                  <Feed_Main
                                    width="100%"
                                    widthxs="100%"
                                    background=" "
                                  >
                                    <Feed_Main
                                      width="100%"
                                      display=" "
                                      mtop=" "
                                      background="transparent"
                                    >
                                      <Feed_Section
                                        padding="0 0 0 0"
                                        paddingxs="0 0 0 0"
                                      >
                                        <ContentHomeSection
                                          leftD="0%"
                                          floatD=" "
                                          width="100%;"
                                        >
                                          <Recommendations
                                            items={recommendedTraining}
                                          ></Recommendations>
                                        </ContentHomeSection>
                                      </Feed_Section>
                                    </Feed_Main>
                                  </Feed_Main>
                                </div>
                              </Fragment>
                            ) : null}
                          </Fragment>
                        ) : null}
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    {!active3 ? (
                      <Fragment>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="0rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio topMobile="35px" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : (
                  <Fragment>
                    <SuccessBullets {...trainingFinishedInfo} />
                  </Fragment>
                )}
              </div>
              <div style={{position: 'absolute', top: '1em', right: '3em'}} id='kandaDesktop'>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
};

export default InvestmentCatalog;
