import { useMutation } from '@apollo/client';
import {
  DYNAMIC_GET,
} from '../mutations';

const GeneralHooks = () => {
  const [getTrainingAdvance] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const Training = JSON.parse(dynamicGet.response);
        if (Training.length > 0) {
          for (const i in Training) {
            sessionStorage.setItem(
              'Achievment-'.concat(Training[i].IDCatTraining),
              Training[i].Achievment,
            );
          }
        }
      }
    },
  });

  const getAchievment = (variables) => {
    //   debugger;
    const getTrainingAdvanceInput = {
      input: {
        table:
          'CatRelUsersTraining as RU, DatTraining as dt, CatTraining as ct',
        columns: [
          'RU.IDUserCollaborative',
          'ct.IDCatTraining',
          'dt.Achievment',
          'ct.Name',
        ],
        conditions: [
          {
            valone: 'dt.IDDatTraining',
            condition: '=',
            valtwo: 'RU.IDDatTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'ct.IDCatTraining',
            condition: '=',
            valtwo: 'dt.CatTraining_IDTraining',
            validColumn: true,
          },
          {
            logic: 'AND',
            valone: 'IDUserCollaborative',
            condition: '=',
            valtwo: sessionStorage.getItem('idUser'),
          },
        ],
      },
    };
    if (sessionStorage.getItem('idUser')) {
      getTrainingAdvance({ variables: getTrainingAdvanceInput });
    }
  };

  const actions = ({ action, variables }) => {
    switch (action) {
      case 'getAchievment':
        getAchievment(variables);
        break;
      default:
        break;
    }
  };
  return { actions };
};

export default GeneralHooks;
