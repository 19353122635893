import React, {
  useState, Fragment, useEffect, Suspense,
} from 'react';
import { useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import QuestionsPymes from '../Routines/QuestionsPymes';
import CalculadoraPymes from '../Routines/CalculadoraPymes';
import RpPymes from '../Routines/RpPymes';
import { getQueryVariableHashtagDeeplink } from '../../Helpers/index';
import {
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import { DivLoader } from '../../assets/styles/Training.styled';

const DatBasic = React.lazy(() => import('../Routines/DatBasic'));
const RoutinesOrderPymes = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
  colorTraining,
}) => {
  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    const { recovery } = getQueryVariableHashtagDeeplink('funnel-tarjeta');

    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
  });

  return (
    <Fragment>
      {orderRutine >= 1 ? (
        <Suspense
          fallback={
            <DivLoader>
              <CircularProgress color="inherit" />
            </DivLoader>
          }
        >
          <DatBasic
            mainDataRoutine={mainDataRoutine}
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            currentRoutine={1}
            nextOrderRoutine={2}
            trainingColor={colorTraining}
          />
        </Suspense>
      ) : null}

      {orderRutine >= 2 ? (
        <BirthDat
          mainDataRoutine={mainDataRoutine}
          date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          trainingColor={colorTraining}
        />
      ) : null}

      {orderRutine >= 3 ? (
        <ContactInformation
          mainDataRoutine={mainDataRoutine}
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          trainingColor={colorTraining}
        />
      ) : null}

      {orderRutine >= 4 ? (
        <QuestionsPymes
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          trainingColor={colorTraining}
        />
      ) : null}

      {orderRutine >= 5 ? (
        <CalculadoraPymes
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={'rp'}
          trainingColor={colorTraining}
        />
      ) : null}

      {orderRutine === 'rp' ? (
        <RpPymes hora={hora} trainingColor={colorTraining} />
      ) : null}
    </Fragment>
  );
};
export default RoutinesOrderPymes;
