import React from "react";
import {
  ContentGeneric,
  Container1,
  Heading,
  DescriptionVideo,
  ContainerVideo,
  HeadingTwo,
  Description,
  VideoI,
} from "../Assets/styles/StyledGeneric.styled";
import KandaButon from "../utils/KandaButton";
import IconGo from "./../Assets/img/Go.svg";
import ShareBtn from "../utils/ShareBtn";

import ShareC from "./../Assets/img/ShareCafe.svg";
// #393A80

export const SectionVideoPrincipal = ({
  tituloPrincial,
  video,
  formato,
  handleClick,
}) => {
  return (
    <ContentGeneric id="Top" pt="60px" ptmobile="100px" className="widthmobile">
      <Heading
        className="bold"
        m="58px 12px 36px 0px"
        color="#393A80"
        fw="bold">
        {tituloPrincial}
      </Heading>

      <VideoI
        width="711"
        height="389"
        src="https://www.youtube.com/embed/zpF6n_6BS6o?start=1&rel=0&modestbranding=1&fs=1&yt:stretch=4:3"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
        allowfullscreen></VideoI>

      <Description
        fz375="18px"
        h375="138px"
        w375="320px"
        m="48px 0px 24px"
        className="roboto"
        color="#393A80"
        display="block"
        mD700="24px auto"
        id="tuplanMobile"
      >
        <b>Te ayudamos a entender tus finanzas con planes, cursos y entrenamientos
        sin costo en línea</b> para que uses mejor tu dinero y accedas a productos
        financieros adecuados porque <b>mereces vivir sin estrés financiero.</b>
      </Description>

      <KandaButon
        display="flex"
        fd="row"
        jc="start"
        p="0px 0px"
        width="250px"
        typeBtn="text"
        action={() => handleClick("CoachingFinanciero", "Conoce_más")}>
        Conoce más
        <img
          alt="icon"
          src={IconGo}
          style={{
            width: "16px",
            height: "16px",
            marginTop: "2px",
            marginLeft: "12px",
            marginRight: "40px",
          }}
        />
      </KandaButon>
    </ContentGeneric>
  );
};

export const SectionVideo = ({
  tituloSecundario,
  textBtn,
  DescripciónVideo,
  video,
  handleClick,
  shareCoru,
}) => {
  const shortenedM = 'niZpBkOonb5fHv3';
  const shortenedW = 'Ubsx1DrDRuQ29RX';
  const shortenedF = 'ZN7qdFN9GR8r2yh';
  const message =
    "Oye, descubrí una plataforma que se llama Coru en la que puedes tomar clases sin costo con expertos en finanzas. Seguro te van a gustar 😋:";
  return (
    <ContentGeneric id="CoruMasterClass" className="Container3">
      <div
        style={{display: "flex", flexDirection: "row"}}
        className="ContainerTitleShare3">
        <HeadingTwo
            ml700="19px"
          h375="20px"
          w375="283px"
         
          className="bold"
          m="0px 12px 0px 0px"
          color="#80460C">
          {tituloSecundario}
        </HeadingTwo>

        <ShareBtn
          Micon="10px 0px 0px 10px"
          className="ItemShare3"
          bckRedS="#80460C"
          bckRedShover="#80460C"
          ShareImgC={ShareC}
          bckicono="#80460C"
          seccion="AprendeFinanzasPersonales"
          message={message}
          shortenedM={shortenedM}
          shortenedW={shortenedW}
          shortenedF={shortenedF}
          shareCoru={shareCoru}
        />
      </div>
      <Description
        h375="84px"
        w375="320px"
        mD700="12px auto 11px "
        m="12px 0px 10px"
        fz="18px"
        className="roboto"
        color="#80460C"
        p700="12px"
        >
        Experimenta las clases gratuitas con nuestros expertos financieros y
        dale respuesta a todas tus preguntas sobre el mundo de las finanzas.
      </Description>

      <VideoI
        src="https://www.youtube.com/embed/Jc4J1fEA3D0?rel=0&modestbranding=1&fs=1&yt:stretch=4:3"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></VideoI>
      <DescriptionVideo className="roboto">
        Hablamos sobre la importancia de llevar un presupuesto 📝 y respondemos
        preguntas como qué es, para qué sirve y seguirlo al pie de la letra.
      </DescriptionVideo>

      <KandaButon
        margin="25px 0px 0px 0px"
        display="flex"
        fd="row"
        w375="308px"
        jc="start"
        p="0px 0px"
        width="250px"
        typeBtn="text"
        action={() => handleClick("AprendeFinanzas", "Regístrate")}
        id="xcoachmobile">
        Regístrate
        <img
          alt="icon"
          src={IconGo}
          style={{
            width: "16px",
            height: "16px",
            marginTop: "tpx",
            marginLeft: "12px",
            marginRight: "40px",
          }}
        />
      </KandaButon>
    </ContentGeneric>
  );
};

export const Video = [SectionVideoPrincipal, SectionVideo];
