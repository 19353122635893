/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTrail, animated } from 'react-spring';
import { useLazyQuery } from '@apollo/client';
import {
  TrainingText,
  Images,
  TrainigTam,
} from '../../assets/styles/Training.styled';
import Header from '../common/Header';
import BetaIcon from '../common/BetaIcon';
import clock from '../../assets/img/anacoach/clockTraining.png';
import { GET_TRAINING_COLOR } from '../../queries';

const BarraInfoWO = (props) => {
  const {
    beta, title, subtitle, txtsubtitle, time, imgWo,
  } = props.propied;
  const { items2 } = title;
  const imgWo1 = imgWo.imgWo;
  const [toggle2] = useState(true);
  const config = { mass: 5, tension: 1000, friction: 200 };
  const trail2 = useTrail(items2.length, {
    config,
    opacity: toggle2 ? 1 : 0,
    a: toggle2 ? 0 : 20,
    height: toggle2 ? 80 : 0,
    from: { opacity: 0, a: 20, height: 0 },
  });

  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  return (
    <>
      <TrainigTam>
        <Header
          background={colorTraining}
          pathname={window.location.pathname}
        />
      </TrainigTam>
      {beta === 'false' ? null : <BetaIcon page={'WO'}></BetaIcon>}
      <TrainigTam plw="0" hm="209px" mt="49px" ml="20px">
        <div className="d-flex flex-row ">
          <div className="col-8 col-sm-9">
            <TrainigTam
              widthxs="96%"
              hm="auto"
              ptmd="100px"
              pt="140px"
              ptsm="107px"
              pl="20px"
              plw="0"
              ptwxl="120px"
            >
              {trail2.map(({ a, height, ...rest }, index) => (
                <animated.div
                  key={items2[index]}
                  className="trails-text text-funnelcc cabin"
                  style={{
                    ...rest,
                    transform: a.interpolate((a) => `translate3d(0,${a}px,0)`),
                  }}
                >
                  <animated.div style={{ height }}>
                    {items2[index]}
                  </animated.div>
                </animated.div>
              ))}
            </TrainigTam>
            {subtitle === 'true' ? (
              <TrainigTam
                hm="auto"
                ptsm="200px"
                plemd="20px"
                plesm={txtsubtitle.length <= 43 ? '20px' : '20px'}
                pb="55px"
                pl="45px"
                pt="236px"
                ptmd="250px"
                plw="6.5%"
                ptw="235px"
              >
                <TrainingText
                  className={
                    txtsubtitle.length <= 43
                      ? 'roboto container-bar-static'
                      : 'roboto container-bar'
                  }
                  color="#07210A"
                  align="left"
                  alignweb="left !important"
                  size="16px"
                  sizew="1.6vw"
                  lh="120%"
                  weight="normal"
                >
                  {txtsubtitle}
                </TrainingText>
              </TrainigTam>
            ) : null}
            <TrainigTam
              hm="auto"
              pb="55px"
              ptmd="330px"
              pl="45px"
              pt="236px"
              ptsm="260px"
              plemd="20px"
              plesm="20px"
              plw="6.5%"
              ptw="310px"
            >
              <div className="d-flex flex-row">
                <Images
                  src={clock}
                  top="0"
                  topweb=""
                  left="0px"
                  leftw="0px"
                  width="18px"
                  widthw="1.6vw"
                  height="1.6vw"
                  position="relative"
                  rigthw="12px"
                  className="img-fluid"
                  heightsm="18px"
                  widthsm="18px"
                  width768="13px"
                  height768="13px"
                ></Images>
                <TrainingText
                  className="roboto marginTime container-bar-static"
                  color="#07210A"
                  align="left"
                  alignweb="left !important"
                  size="16px"
                  sizew="1.6vw"
                  lh="120%"
                  weight="normal"
                  bot="0px"
                >
                  Tiempo de entrenamiento: {time}.
                </TrainingText>
              </div>
            </TrainigTam>
          </div>
          <div className="col-3 col-md-4 col-xl-5 marginImgHeader">
            <img
              src={imgWo1}
              className="col-12 col-xl-4 col-lg-7 col-md-8 col-sm-10 col-xs-10 img-relative imgTitle2200"
              left="30%"
              position="absolute"
            ></img>
          </div>
        </div>
      </TrainigTam>
    </>
  );
};
export default BarraInfoWO;
