import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { GET_PRODUCTS_FAMILY_PROTECTION } from '../../queries';
import {
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
  SET_TRAINING_COMPLETE,
  CREATE_OPPORTUNITIES,
} from '../../mutations';
import PlayYoutube from '../../components/common/PlayYoutube';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ana from '../../assets/img/anacoach/CoachImage.png';
import imgWhats from '../../assets/img/anacoach/WhatsApp.png';
import imgPhone from '../../assets/img/anacoach/Tel.png';
import imgMail from '../../assets/img/anacoach/Email.png';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';

import {
  TrainingSectionGray,
  TrainingDivSection2,
  DivStyle,
  DivGray,
  Resultado1,
  CardsBtn,
  TrainingSubtitles,
  ImageSections,
  TrainingDivSection,
  DivLoader,
  TrainingText,
  Images,
  DivDefineWidth,
} from '../../assets/styles/Training.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Container } from '../../assets/styles/Home.styled';
import {
  SeparateSection,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import ModalDiv from '../../components/common/ModalDiv';
import CardOne from '../../components/Home/Card1Animate';
import Recommendations from '../../components/Training/RecommendedTraining';
import ClickButton from '../../Helpers/HookHelpers';
import ProductsFremium from '../../components/common/ProductsFremium';

const RpFamilyProtection = ({
  Date,
  hora,
  currentTraining,
  insuranceActive,
}) => {
  const { actions } = ClickButton();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${
              getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const [products, setProducts] = useState({});
  const [getProductsFamilyProtection] = useLazyQuery(
    GET_PRODUCTS_FAMILY_PROTECTION,
    {
      onCompleted({ getProductsFamilyProtection }) {
        console.log('Productos', getProductsFamilyProtection);
        setProducts(JSON.parse(getProductsFamilyProtection.response));
        setShowProduct(true);
      },
      onError(error) {
        console.log('error', error);
      },
    },
  );

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log(dynamicUpdate);
    },
    onError() {},
  });

  const [setTrainingComplete] = useMutation(SET_TRAINING_COMPLETE);
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getProductsFamilyProtection({
      variables: { idTraining: String(currentTraining) },
    });
  }, []);
  const updateTrainingAchievement = async () => {
    console.log('>>>>> 2');

    const idUserM = parseInt(sessionStorage.getItem('idUser'));

    const result = await setTrainingComplete({
      variables: {
        idUser: idUserM,
        idTraining: 11,
      },
    });

    console.log('>>>>> 3 ', result);
  };
  useEffect(() => {
    const conv1 = document.getElementById('conversacion1');
    const conv2 = document.getElementById('conversacion2');
    const conv3 = document.getElementById('conversacion3');
    if (
      conv1 !== undefined
      && conv2 !== undefined
      && conv3 !== undefined
      && conv1 !== null
      && conv2 !== null
      && conv3 !== null
    ) {
      conv1.classList.add('hidden');
      conv2.classList.add('hidden');
      conv3.classList.add('hidden');
    }
    console.log('>>>>> 1');
    updateTrainingAchievement();
  }, []);

  const contactOpptunity = (name, IDProduct) => {
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 4, // remplazar por el id del entrenamiento
        idProducts: [IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 11,
        idType: '',
        value: name,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
  };

  const abrirNuevoTab = (url, name, IDProduct) => {
    // Abrir nuevo tab
    const click = `ConoceMas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('clickOutProduct', name);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 4, // remplazar por el id del entrenamiento
        idProducts: [IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 11,
        idType: '',
        value: name,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };
  const [catContactPhone, setcatContactPhone] = useState('');
  const [catContactMail, setcatContactMail] = useState('');
  const [show, setShow] = useState(false);
  const showModal = () => {
    const click = `Contactenme_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setShow(true);
  };
  useEffect(() => {
    setcatContactPhone(sessionStorage.getItem('CellPhone') || '');
  }, []);
  useEffect(() => {
    setcatContactMail(sessionStorage.getItem('Email1') || '');
  }, []);
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleChangeContactPhone = (e) => {
    console.log(e.target.value);
    setcatContactPhone(e.target.value);
  };
  const handleChangeEmail = (e) => {
    console.log(e.target.value);
    setcatContactMail(e.target.value);
  };
  const [showThanks, setShowThanks] = useState(false);
  const showModalThanks = () => {
    setShowThanks(true);
  };
  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const showModalWhatsapp = () => {
    setShowWhatsapp(true);
  };
  const handleCloseModalWhatsapp = () => {
    setShowWhatsapp(false);
    if (
      catContactPhone !== sessionStorage.getItem('CellPhone')
      && catContactPhone !== ''
    ) {
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactMedium',
            columns: {
              setkey: 'ContactPhone',
              setval: String(catContactPhone),
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
    }
    showModalThanks();
  };

  const [showEmail, setShowEmail] = useState(false);
  const showModalEmail = () => {
    setShowEmail(true);
  };
  const handleCloseModalEmail = () => {
    setShowEmail(false);
    if (
      catContactMail !== sessionStorage.getItem('Email1')
      && catContactMail !== ''
    ) {
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactMedium',
            columns: {
              setkey: 'Email2',
              setval: String(catContactMail),
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
    }
    showModalThanks();
  };

  const [showPhone, setShowPhone] = useState(false);
  const showModalPhone = () => {
    setShowPhone(true);
  };

  const handleCloseModalPhone = () => {
    setShowPhone(false);
    if (
      catContactPhone !== sessionStorage.getItem('CellPhone')
      && catContactPhone !== ''
    ) {
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactMedium',
            columns: {
              setkey: 'ContactPhone',
              setval: String(catContactPhone),
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
    }

    showModalThanks();
  };
  const handleCloseModalThanks = () => {
    setShowThanks(false);
  };
  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';

  firstname = sessionStorage.getItem('FirstName');
  nameClient = `${sessionStorage.getItem('FirstName')
  } ${
    sessionStorage.getItem('FathersLastName')}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');
  const [goFeed] = useState(false);
  const history = useHistory();
  const anchop = window.screen.width;

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  if (!showProduct) {
    return (
      <DivLoader>
        <CircularProgress color="inherit" />
      </DivLoader>
    );
  }
  const resultProducts = products.map((currentValue, index) => {
    if (insuranceActive.indexOf(currentValue.Name) !== -1) {
      return (
          <Fragment key={index}>
            <div className="marginComentLgSuccess1">
              <TrainingDivSection
                wiw="66%"
                mlw="15%"
                padding="20px"
                back="#fafafa"
                backw="#fafafa"
              >
                <TrainingSubtitles></TrainingSubtitles>
                <DivStyle size="18px" line="1.2em">
                  <Resultado1
                    wid="100%"
                    key={currentValue.IDProduct}
                    minhweb="500px"
                    minh="520px"
                    marriweb="auto"
                    marri="auto"
                    border=" "
                  >
                    <DivDefineWidth minh="90px">
                      <ImageSections
                        width={
                          currentValue.ImageURL.includes('ThonaSeguros')
                            === true
                          || currentValue.ImageURL.includes('MafreSeguros')
                            === true
                            ? '110px'
                            : currentValue.ImageURL.includes('GNP') === true
                              ? '130px'
                              : '85px'
                        }
                        heightweb="auto"
                        src={currentValue.ImageURL}
                        className="img-fluid"
                      />
                    </DivDefineWidth>
                    <DivGray>
                      <div>
                        <DivStyle>
                          <DivStyle bott="8px" weight="bold">
                            {currentValue.Name}
                          </DivStyle>
                        </DivStyle>
                        <br />
                        <DivStyle align="justify" mh="180px">
                          {currentValue.benefits.map((benefits) => {
                            const separate = benefits.Description.split('/');

                            return separate.map((txt) => <div>{txt}</div>);
                          })}
                        </DivStyle>
                        <br />
                        <br />
                        <div className="embed-responsive embed-responsive-4by3">
                          <PlayYoutube
                            idVideo={
                              currentValue.URLVideo.includes('http')
                                ? currentValue.URLVideo.split('/embed/')[1]
                                : ''
                            }
                            width={'300'}
                            height={'190'}
                            close={false}
                          />
                          </div>
                        <br />
                        <DivStyle weight="bold" align="center">
                          Tenemos el seguro perfecto para tí.
                        </DivStyle>
                        <br />
                        <div className="center">
                          <CardsBtn
                            min_web="100%"
                            min_w="100%"
                            onClick={() => {
                              abrirNuevoTab(
                                currentValue.ClickOut,
                                currentValue.Name,
                                currentValue.IDProduct,
                              );
                            }}
                          >
                            Conoce más
                          </CardsBtn>
                          <br />
                          <br />
                          <CardsBtn
                            min_web="100%"
                            border="1px solid #F64282"
                            back="#fff"
                            color="#F64282"
                            min_w="100%"
                            onClick={(e) => {
                              contactOpptunity(
                                currentValue.Name,
                                currentValue.IDProduct,
                              );
                              showModal(e);
                            }}
                          >
                            Contáctenme
                          </CardsBtn>
                        </div>
                      </div>
                    </DivGray>
                    <br />
                  </Resultado1>
                </DivStyle>
              </TrainingDivSection>
            </div>
          </Fragment>
      );
    }
  });
  return (
      <>
        <div className="container">
          <ModalAmazon show={true} wo={'ProtecciónFamiliar'}></ModalAmazon>
          <TrainingSectionGray className="col-md-7 offset-md-2" back="#fafafa">
            <TrainingDivSection2
              paddiw="10% 0 3% 0"
              paddi="35% 5% 5% 5%"
              back="#fafafa"
            >
              <br />
              <br />
              <TrainingSubtitles
                size="20px"
                fw="600"
                align="center"
                alignweb="center"
                className="cabin"
              >
                ¡Gracias {firstname.trim()}!
              </TrainingSubtitles>
              <TrainingText
                size="16px"
                className="roboto"
                align="center"
                alignweb="center"
                ls="-0.2px"
              >
                Tenemos propuestas de seguros para ti.
              </TrainingText>
              <br />
              <TrainingSubtitles
                size="20px"
                fw="600"
                align="left"
                alignweb="left"
                className="cabin"
              >
                Estos son tus datos que registramos:
              </TrainingSubtitles>
              <br />
              <TrainingText
                size="16px"
                className="roboto"
                align="left"
                alignweb="left"
                ls="-0.2px"
              >
                <b>Nombre cliente:</b> {nameClient}
                <br />
                <b>Teléfono:</b> {telClient}
                <br />
                <b>Email:</b> {emailClient}
              </TrainingText>
            </TrainingDivSection2>
            <SeparateSection />
          </TrainingSectionGray>
        </div>

        {resultProducts}

        <div className="marginComentLgSuccess1">
          <TrainingSectionGray back="#fafafa">
            <ProductsFremium
              idT={parseInt(sessionStorage.getItem('idTraining'))}
            />
          </TrainingSectionGray>
        </div>
        {recomendaciones ? (
          <TrainingDivSection2
            wiw="670px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="¿Aún quieres ir por más?"
              text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
            />
          </TrainingDivSection2>
        ) : null}
        {recomendaciones ? (
          <div className="marginComentLgSuccess1">
            <Container
              mlMobile="auto"
              mRMobile="auto"
              mleftmxs="0%"
              mleft="18%"
              mleftm="7%"
              width="100%"
              widthMobile="100%"
              mtMobile="0px"
            >
              <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                  <Recommendations
                    items={recommendedTraining}
                  ></Recommendations>
                </ContentHomeSection>
              </Feed_Section>
            </Container>
          </div>
        ) : null}
        <SectionShare WO="ProtecciónFamiliar"></SectionShare>
        <ModalDiv show={show} handleClose={handleCloseModal}>
          <TrainingSubtitles
            size="20px"
            align="left"
            className="cabin"
          >{`¡Hola ${sessionStorage.getItem('FirstName')}!`}</TrainingSubtitles>
          <TrainingText
            size="14px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
            top="30px"
            bot="30px"
          >
            Indicanos por que medio te gustaría ser contactado.
          </TrainingText>
          <TrainingText
            size="16px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            <span
              onClick={(e) => {
                showModalWhatsapp(e);
              }}
            >
              <Images
                src={imgWhats}
                top="0"
                topweb="0"
                left="0"
                leftw="0"
                width="50px"
                rigth="15px"
                position="relative"
                className="img-fluid"
              ></Images>
              WhatsApp
            </span>
          </TrainingText>
          <hr />
          <TrainingText
            size="16px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            <span
              onClick={(e) => {
                showModalEmail(e);
              }}
            >
              <Images
                src={imgMail}
                top="0"
                topweb="0"
                left="0"
                leftw="0"
                width="50px"
                rigth="15px"
                position="relative"
                className="img-fluid"
              ></Images>
              E-Mail
            </span>
          </TrainingText>
          <hr />
          <TrainingText
            size="16px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            <span
              onClick={(e) => {
                showModalPhone(e);
              }}
            >
              <Images
                src={imgPhone}
                top="0"
                topweb="0"
                left="0"
                leftw="0"
                width="50px"
                rigth="15px"
                position="relative"
                className="img-fluid"
              ></Images>
              Teléfono
            </span>
          </TrainingText>
          <br />
        </ModalDiv>

        <ModalDiv
          show={showWhatsapp}
          handleClose={handleCloseModalWhatsapp}
          txtModal="Enviar"
        >
          <Images
            src={ana}
            top="0px"
            topweb="0%"
            left="32%"
            leftw="25%"
            position="initial"
            positionw="initial"
            border="2px solid #68CDC7"
            bs="border-box"
            padd="5px"
            br="45px"
            className="img-fluid"
          ></Images>
          <TrainingText
            size="14px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
          </TrainingText>
          <TextField
            variant="outlined"
            id="telefonoW"
            name="telefono"
            label="Teléfono"
            autoComplete="off"
            value={catContactPhone}
            required
            fullWidth
            inputProps={{
              maxLength: 10,
              pattern: '[0-9]',
            }}
            className="textInput inputs-bottom input-coru"
            onBlur=""
            onChange={(e) => handleChangeContactPhone(e)}
          />
          <br />
          <br />
        </ModalDiv>
        <ModalDiv
          show={showEmail}
          handleClose={handleCloseModalEmail}
          txtModal="Enviar"
        >
          <Images
            src={ana}
            top="0px"
            topweb="0%"
            left="25%"
            leftw="25%"
            position="initial"
            positionw="initial"
            border="2px solid #68CDC7"
            bs="border-box"
            padd="5px"
            br="45px"
            className="img-fluid"
          ></Images>
          <TrainingText
            size="14px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            ¡Dejános tu E-mail, nosotros te contactaremos a la brevedad!
          </TrainingText>
          <TextField
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            autoComplete="off"
            value={catContactMail}
            required
            fullWidth
            inputProps=""
            className="textInput inputs-bottom input-coru"
            onBlur=""
            onChange={(e) => handleChangeEmail(e)}
          />
          <br />
          <br />
        </ModalDiv>
        <ModalDiv
          show={showPhone}
          handleClose={handleCloseModalPhone}
          txtModal="Enviar"
        >
          <Images
            src={ana}
            top="0px"
            topweb="0%"
            left="25%"
            leftw="25%"
            position="initial"
            positionw="initial"
            border="2px solid #68CDC7"
            bs="border-box"
            padd="5px"
            br="45px"
            className="img-fluid"
          ></Images>
          <TrainingText
            size="14px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
          </TrainingText>
          <TextField
            variant="outlined"
            id="phone"
            name="phone"
            label="Teléfono"
            autoComplete="off"
            value={catContactPhone}
            required
            fullWidth
            inputProps={{
              maxLength: 10,
              pattern: '[0-9]',
            }}
            className="textInput inputs-bottom input-coru"
            onBlur=""
            onChange={(e) => handleChangeContactPhone(e)}
          />
          <br />
          <br />
        </ModalDiv>

        <ModalDiv show={showThanks} handleClose={handleCloseModalThanks}>
          <br />
          <TrainingText
            size="14px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.3px"
          >
            ¡Tu mensaje ha sido enviado correctamente!
          </TrainingText>
          <br />
        </ModalDiv>
      </>
  );
};
export default RpFamilyProtection;
