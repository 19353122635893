import React, { useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
// import WOlayout from '../components/layouts/WOlayout';
import "../assets/styles/kanda/KandaDesktop.css";
import { Helmet } from "react-helmet";
import manchas from "../assets/img/lpTuPlan/manchas.svg";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/lpTuPlan/CoruLogo.svg";
import chica from "../assets/img/lpTuPlan/chica.png";
import chicaAnim from "../assets/img/lpTuPlan/TuPlan_gif_LPsSEO.gif";
import chico from "../assets/img/lpTuPlan/chico.svg";
import avatar from "../assets/img/lpTuPlan/avatar.svg";
import celAnim from "../assets/img/lpTuPlan/Coru-videoPlanFinanciero.gif";
// import { imageComponent } from "../components/common/common";
import { CardsBtn } from "../assets/styles/Training.styled";
// import Colaboradores from "../components/rediseno_about_us/Colaboradores";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import ClickButton from "../Helpers/HookHelpers";

const Container1 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, rgba(99, 189, 255, 0.2) 40.9%, rgba(238, 238, 238, 0) 100%);
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const Container2 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const Container3 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  background: #f7e2f5;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const TitleBlue = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: ${(props) => props.textA || "center"};
  letter-spacing: -0.3px;
  color: #393a80;
  margin: ${(props) => props.marginD || ""};
  @media (min-width: 770px) {
    text-align: ${(props) => props.textAD || "left"};
    font-size: ${(props) => props.fontSD || "52px"};
  }
`;

const TextGray = styled.div`
  font-weight: ${(props) => props.fontW || "400"};
  font-size: ${(props) => props.fontS || "16px"};
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#595959"};
  text-align: ${(props) => props.textA || ""};
  margin-top: ${(props) => props.marginT || ""};
  @media (min-width: 770px) {
    display: ${(props) => props.displayMQ || "block"};
    font-size: ${(props) => props.fontSD || "24px"};
    text-align: ${(props) => props.textAD || ""};
  }
`;

const ImageGeneral = styled.img`
  width: 100%;
  max-width: ${(props) => props.maxW || "320px"};
  @media (max-width: 770px) {
    width: 100%;
    max-width: ${(props) => props.maxWM || "180px"};
    margin: 24px auto;
  }
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 25%;
    margin-top: 15px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 75%;
  }
`;

const PercentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 770px) {
    flex-direction: row;
    align-items: center;
    margin-top: ${(props) => props.marginT || "100px"};
  }
`;

const ContainerManchas = styled.div`
  background-image: ${(props) => props.backgroundImage || ""};
  background-size: cover;
  background-position: top;
  margin-top: -13%;
  }
`;

const ContainerGreen = styled.div`
  width: 100%;
  max-width: 514px;
  padding: 16px;
  background: rgba(107, 213, 175, 0.3);
  box-shadow: -131.714px 105.167px 67.3887px rgba(68, 23, 135, 0.01), -74.536px 59.2204px 57.1783px rgba(68, 23, 135, 0.03), -32.6733px 26.5471px 41.8627px rgba(68, 23, 135, 0.05), -8.16833px 6.12625px 23.4839px rgba(68, 23, 135, 0.05), 0px 0px 0px rgba(68, 23, 135, 0.05);
  border-radius: 0px 20.2261px 20.2261px 20.2261px;
`;

const ContainerListo = styled.div`
  width: 100%;
  margin: 100px auto;
  max-width: 914px;
  max-height: 244px;
  border-radius: 16px;
  background: rgba(205, 205, 205, 0.2);
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.08);
  padding: 36px;
  gap: 24px;
  isolation: isolate;
  display: flex;
  justify-content: space-around;
`;
function TuPlan() {
  const { actions } = ClickButton();

  const handleClickCoru = (name) => {
    actions({
      action: 'ClickButtonCollection',
      variables: name,
    });
    switch (name) {
      case 'Ingresar':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.location.href = '/login';
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.location.href = 'https://coru.com/login';
        }
        break;
      case 'CrearCuenta':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.location.href = '/personal-Information-v2';
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.location.href = 'https://coru.com/personal-Information-v2';
        }
        break;
      case 'Diagnostico':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.location.href = 'https://dev-rediseno.coru.mx/kanda/wo/diagnosticov3';
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.location.href = 'https://coru.com/kanda/wo/diagnosticov3';
        }
        break;

      default:
        break;
    }
  };

  const Header = () => (
  <div style={{ height: "85px", display: "flex" }}>
    <ContLogo>
      <Image
        cursor="pointer"
        src={ImageLogo}
        width="120px"
        mwidth="97.92px"
        heightMobile="20.8px"
        alt="logo-coru"
      />
    </ContLogo>
    <ContLogo2>
      <ContentGeneric width="40%" widthMd="10%">
        <KandaButton
          action={() => handleClickCoru('Ingresar')}
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
          fz320="13px"
        >
          Ingresar
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="60%" widthMd="15%">
        <KandaButton
          action={() => handleClickCoru('CrearCuenta')}
          fz320="13px"
          mr="96px"
          width="100%"
          typeBtn="active">
          Crear Cuenta
        </KandaButton>
      </ContentGeneric>
      {/* <div>
        <a
          className="textHeader roboto nav-link"
          style={{
            textAlign: "center",
            paddingRight: "2rem",
            cursor: "pointer",
            fontWeight: "bold",
            color: "#070B11",
          }}
        >
          Regístrate
        </a>
      </div>
      <div>
        <a
          className="textHeader roboto nav-link"
          style={{
            textAlign: "center",
            cursor: "pointer",
            color: "#070B11",
            paddingRight: "0",
          }}
        >
          Iniciar Sesión
        </a>
      </div> */}
    </ContLogo2>
  </div>
  );

  const [changePeople, setchangePeople] = useState(false);
  const handleLeft = () => {
    setchangePeople(false);
  };
  const handleRight = () => {
    setchangePeople(true);
  };

  const URLactual = window.location.hostname;
  let isProd = false;
  if (URLactual === 'coru.com') {
    isProd = true;
  } else {
    isProd = false;
  }

  return (
    <div style={{ background: 'linear-gradient(180deg,#f1f6fa 2.78%,#f1f3fd 26.58%,#f0f3fd 53.41%,#F2E6F7 68.04%,#FEDDF3 100%)' }}>
      <Container1>
        <Helmet>
          <title>{isProd ? 'Crea ahora tu plan financiero personal | CORU' : 'Crea ahora tu plan financiero personal'}</title>
          <meta
            name="description"
            content="Comienza con tu diagnóstico y crea tu plan financiero personalizado y sin costo. Organiza tus finanzas y logra tus metas."
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <Header />
        <div
          style={{
            maxWidth: "1056px",
            margin: "auto",
            width: "100%",
          }}
        >
          <PercentContainer>
            <div>
              <img
                src={chica}
                style={{ width: "auto", maxWidth: "346px" }}
              />
              <img
                src={chicaAnim}
                style={{
                  width: "100%", maxWidth: "156px", position: "absolute", marginLeft: "-125px"
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "right",
                alignItems: "right",
                height: "100%",
              }}
            >
              <TitleBlue
                className="cabin"
                fontS="24px"
                fontSD="24px"
                textAD="right"
                textA="left"
                style={{ margin: "15px 0" }}
              >
                Conoce Tu Plan Financiero
              </TitleBlue>
              <TitleBlue
                className="cabin"
                fontS="32px"
                fontSD="62px"
                textAD="right"
                textA="left"
                style={{ margin: "15px 0" }}
              >
                Mejora tus finanzas de la mano de tu Coach Digital
              </TitleBlue>
            </div>
          </PercentContainer>
        </div>
      </Container1>
      <ContainerManchas backgroundImage={`url(${manchas})`}>
        <Container2>
          <div
              style={{
                maxWidth: "1056px",
                margin: "auto",
                width: "100%",
              }}
            >
            <PercentContainer marginT="250px">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "left",
                  height: "100%",
                }}
              >
                <TextGray className="roboto" textA="left" textAD="left" marginT="30px">
                  Identifica todas las causas de tu estrés financiero
                </TextGray>
                <TextGray
                  fontW="600"
                  fontS="18px"
                  fontSD="32px"
                  className="roboto"
                  textA="left"
                  textAD="left"
                  style={{ margin: "15px 0" }}
                >
                  Realiza tu diagnóstico y descubre por <br /> dónde se escapa tu dinero.
                </TextGray>
                <CardsBtn
                  styledMaxWidth="300px"
                  type="button"
                  min_w="300px"
                  min_web="300px"
                  max_w="300px"
                  windthmd="60%"
                  mleft="0"
                  mleftweb="0"
                  minmd="100%"
                  style={{ borderRadius: "16px" }}
                  onClick={() => handleClickCoru('Diagnostico')}
                >
                  Inicia tu diagnóstico
                </CardsBtn>
              </div>
              <ImageGeneral
                src={celAnim}
              />
            </PercentContainer>
            <PercentContainer>
              <img
                src={chico}
                style={{ width: "100%", maxWidth: "266px" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "right",
                  alignItems: "right",
                  height: "100%",
                }}
              >
                <TextGray className="roboto" textA="right" textAD="right" marginT="30px">
                  Personalizado, sin costo y fácil de usar
                </TextGray>
                <TitleBlue
                  className="cabin"
                  fontS="24px"
                  fontSD="32px"
                  textAD="right"
                  textA="right"
                  style={{ margin: "15px 0" }}
                >
                  Con Tu Plan Financiero alcanza tus objetivos y ten las finanzas que mereces.
                </TitleBlue>
              </div>
            </PercentContainer>
            <ContainerListo>
              <ImageGeneral
                src={avatar}
                maxW="156px"
                maxWM="70px"
              />
              <div style={{ marginTop: "-55px", textAlign: "center" }}>
                <ContainerGreen>
                  <TextGray className="roboto" textA="left" textAD="left" marginT="0" color="#070B11">
                    ¿Estás listo para cambiar tu vida?
                  </TextGray>
                  <TextGray className="roboto" textA="left" textAD="left" marginT="30px" color="#070B11" fontSD="30px" fontW="700">
                    ¡Empecemos por tus finanzas!
                  </TextGray>
                </ContainerGreen>
                <CardsBtn
                  styledMaxWidth="300px"
                  type="button"
                  min_w="300px"
                  min_web="300px"
                  max_w="300px"
                  minmd="190px"
                  windthmd="50%"
                  mleft="auto"
                  mleftweb="auto"
                  style={{ borderRadius: "16px" }}
                  onClick={() => handleClickCoru('Diagnostico')}
                >
                  Crea Tu Plan Financiero
                </CardsBtn>
              </div>
            </ContainerListo>
          </div>
        </Container2>
      </ContainerManchas>
      <Container3>
        <Footer isAboutUS="si" />
      </Container3>
    </div>
  );
}

export default TuPlan;
