import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DYNAMIC_INSERT, DYNAMIC_UPDATE, DYNAMIC_GET } from '../../mutations';
import { getCurrentDate, formatAMPM } from '../../Helpers/general';

let triggerName = '';
const SendNotificationEmail = () => {
  const [wo, setWo] = useState('');
  let [inputDynamicUpdate] = useState({});

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('insert exitoso');
      } else {
        console.log(dynamicInsert.message);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [dynamicGet] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const resp = JSON.parse(dynamicGet.response);
        const currentDate = getCurrentDate();
        const currentH = formatAMPM(new Date(), 1);
        if (resp.length !== 0) {
          console.log('response get == ', resp);
          handleUpdateLog(currentDate, currentH);
        } else {
          handleInserLog(currentDate, currentH);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleUpdateLog = (currentDate, currentH) => {
    inputDynamicUpdate = {
      input: {
        table: 'logNotificationWos',
        columns: [
          {
            setkey: '`trigger`',
            setval: triggerName,
          },
          {
            setkey: 'type',
            setval: 'mail',
          },
          {
            setkey: 'step',
            setval: wo,
          },
          {
            setkey: 'setKey',
            setval: 'Nombre',
          },
          {
            setkey: 'setVal',
            setval: sessionStorage.getItem('FirstName'),
          },
          {
            setkey: 'destination',
            setval: `${sessionStorage.getItem(
              'Email1',
            )},${sessionStorage.getItem('FirstName')}`,
          },
          {
            setkey: '`status`',
            setval: 'true',
          },
          {
            setkey: 'date',
            setval: currentDate,
          },
          {
            setkey: 'hour',
            setval: currentH,
          },
          {
            setkey: 'extra',
            setval: '0',
          },
        ],
        conditions: [
          {
            valone: 'idUser',
            valtwo: `${sessionStorage.getItem('idUser')}`,
            condition: '=',
          },
          {
            logic: 'AND',
            valone: 'type',
            valtwo: 'mail',
            condition: '=',
          },
        ],
      },
    };
    dynamicUpdate({ variables: inputDynamicUpdate });
  };

  const handleInserLog = (currentDate, currentH) => {
    const inputLog = {
      input: {
        table: 'logNotificationWos',
        columns: [
          '`trigger`',
          'type',
          'cellPhone',
          'setKey',
          'setVal',
          'destination',
          'subject',
          'idUser',
          'step',
          'status',
          'date',
          'hour',
        ],
        detailValues: {
          values: [
            triggerName,
            'mail',
            `${sessionStorage.getItem('CellPhone') || ''}`,
            'Nombre',
            `${sessionStorage.getItem('FirstName')}`,
            `${sessionStorage.getItem('Email1')},${sessionStorage.getItem(
              'FirstName',
            )}`,
            '¡No te detengas! continua con tu entrenamiento!',
            `${sessionStorage.getItem('idUser')}`,
            wo,
            'true',
            currentDate,
            currentH,
          ],
        },
      },
    };
    dynamicInsert({ variables: inputLog });
  };

  // functions by WO's
  const alertsCC = (trigger) => {
    const currentDate = getCurrentDate();
    const currentH = formatAMPM(new Date(), 1);
    switch (trigger) {
      case 'alerta_cc_1_mail':
        {
          const getInput = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'mail',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInput });
        }

        break;
      case 'alerta_cc_2_mail':
        handleUpdateLog(currentDate, currentH);
        break;
      case 'alerta_cc_3_mail':
        handleUpdateLog(currentDate, currentH);
        break;
      case 'alerta_cc_4_mail':
        handleUpdateLog(currentDate, currentH);
        break;
      case 'alerta_cc_5_mail':
        handleUpdateLog(currentDate, currentH);
        break;
      default:
        break;
    }
  };

  const alertsFirstInv = (trigger) => {
    const currentDate = getCurrentDate();
    const currentH = formatAMPM(new Date(), 1);

    switch (trigger) {
      case '1er_inv_alerta1':
        {
          const getInput = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'mail',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInput });
        }
        break;
      case '1er_inv_alerta2':
        handleUpdateLog(currentDate, currentH);
        break;
      case '1er_inv_alerta3':
        handleUpdateLog(currentDate, currentH);
        break;
      case 'alerta4_1er_inv':
        handleUpdateLog(currentDate, currentH);
        break;
      case '1er_inv_alerta5':
        handleUpdateLog(currentDate, currentH);
        break;
      default:
        break;
    }
  };

  const alertsFastTrack = (trigger) => {
    const currentDate = getCurrentDate();
    const currentH = formatAMPM(new Date(), 1);

    switch (trigger) {
      case 'email_FT1':
        {
          const getInput = {
            input: {
              table: 'logNotificationWos',
              columns: ['*'],
              conditions: [
                {
                  valone: 'idUser',
                  condition: '=',
                  valtwo: `${sessionStorage.getItem('idUser')}`,
                },
                {
                  logic: 'AND',
                  valone: 'type',
                  valtwo: 'mail',
                  condition: '=',
                },
              ],
            },
          };
          dynamicGet({ variables: getInput });
        }
        break;
      case 'email_FT3':
        handleUpdateLog(currentDate, currentH);
        break;
      case 'email_FT5':
        handleUpdateLog(currentDate, currentH);
        break;
      case 'email_FT_7':
        handleUpdateLog(currentDate, currentH);
        break;
      default:
        break;
    }
  };

  const sendEmail = ({ trigger, WO }) => {
    setWo(WO);
    triggerName = trigger;
    if (WO === 'CC') {
      alertsCC(trigger);
    } else if (WO === 'FirstInv') {
      alertsFirstInv(trigger);
    } else if (WO === 'FastTrack') {
      alertsFastTrack(trigger);
    }
  };

  return { sendEmail };
};

export default SendNotificationEmail;
