import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  DivFlex,
  TrainingText,
  BorderTask,
  StatusTask,
} from '../../assets/styles/Training.styled';

function TasksStatus({ datos, comment, handleChange }) {
  return (
        <Fragment>
            {datos.map((post) => (
                <>
                    <DivFlex widthweb="70%" marweb="auto" style={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}>
                        <BorderTask bs="none">
                            <TrainingText size="18px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                {post.taskName}
                            </TrainingText>
                            <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px">
                                {post.taskInfo}
                            </TrainingText>
                        </BorderTask>

                        {post.taskStatus === 'Incomplete'
                          ? <StatusTask fw="800" Color="rgba(250, 157, 89, 0.2)" bColor="rgba(250, 157, 89, 0.2)" style={{ color: '#FA9D59' }}>
                                {'$0'}
                            </StatusTask>
                          : <StatusTask style={{ padding: post.taskName === 'Tareas realizadas' || post.taskName === 'Recompensas obtenidas' ? '17px 20px' : '' }} fw="800" Color="rgba(250, 157, 89, 0.2)" bColor="rgba(250, 157, 89, 0.2)">
                                {post.taskMoney}
                            </StatusTask>
                        }
                    </DivFlex>
                    <DivFlex widthweb="70%" marweb="auto" style={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}>
                        {post.taskStatus === 'Incomplete' && post.comment === ''
                          ? <TextField
                                variant="outlined"
                                name={`comment_${post.idDatHomework}`}
                                autoComplete="off"
                                placeholder="Escribe un comentario, de por que no se cumplio"
                                required
                                fullWidth
                                value={comment[`comment_${post.idDatHomework}`]}
                                onChange={handleChange}
                            /> : null
                        }
                        {post.taskStatus === 'Incomplete' && post.comment !== ''
                          ? <TextField
                                variant="outlined"
                                autoComplete="off"
                                required
                                fullWidth
                                value={post.comment}
                                disabled={true}
                            />
                          : null}
                    </DivFlex>
                </>
            ))
            }
        </Fragment>
  );
}
export default TasksStatus;
