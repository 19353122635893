import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Core from './Exportcomponents/CoreRoutesComponents';
import { GET_CAT_ROUTES_Q } from '../queries';

const CoreRoutes = () => {
  const [result, setResult] = useState([])
  const [getCatRoute] = useLazyQuery(
    GET_CAT_ROUTES_Q,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getCatRoutes }) {
        setResult(getCatRoutes);
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  useEffect(() => {
    const input = { routeType: 'Core' };
    getCatRoute({ variables: input });
  }, []);

  const Element = ({ component }) => {
    const Component = Core[component];
    return <Component />;
  };
  return (
    <>
      <Switch>
        {result?.map((route) => (
          <Route
            exact
            path={route.path}
            component={() => Element(route)}
            key={route.id}
          />
        ))}
      </Switch>
    </>
  );
};

export default CoreRoutes;
