import React, { useState, useEffect } from 'react';
import {
  ImageSections,
  CardsTxt,
  CardsBtn,
  DivStyle,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/perfil/perfiles.css';

function ResultProduct({card, ClickButton, showButton=true, showBenefit=true}) {

  const [defaultCardSize, setDefaultCardSize] = useState({ 'width': '75px', 'height': 'fit-content'})

  useEffect(() => {
    if(card.CardImage === 'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CGroup.png') {
      setDefaultCardSize({'width': '60px', 'height': '70px'})
    }
  }, [card]);
  
  return (
    <div key={card.Id} className="d-flex flex-column" style={{
      minWidth: '270px', maxWidth: '320px', minHeight: showBenefit ? '502px': '200px', maxHeight: '502px', padding: '11px', margin: '10px', boxShadow: '0px 4px 16px 0px rgb(0 0 0 / 20%)',
    }}>
        <div className="p-md-0 p-4">
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '95px' }}>
                <ImageSections pt="25px" width={defaultCardSize['width']} hmob={defaultCardSize['height']} ml="0" heightweb={defaultCardSize['height']} mhw="75px" mhm="75px" src={card.CardImage} className="img-fluid" />
                {showBenefit ? <div style={{ textAlign: 'right', paddingTop: '25px' }}>
                    <CardsTxt className="roboto" size="16px">
                        Anualidad
                    </CardsTxt>
                    {card.benefit ? 
                      (<CardsTxt key={'Anualidad'} className="roboto" weight="600" size="18px">
                        {JSON.parse(card.benefit)['anualidad']}
                      </CardsTxt>): null
                    }
                </div>: null}
            </div>

            <DivStyle
                className="cabin"
                mh="60px"
                mxh="60px"
                weight="600"
                align="left"
                size="18px"
                line="1.5em"
                padd="12px 0"
                color="#07210A"
            >
                {card.CardName}
            </DivStyle>
            {showBenefit ? 
              <DivStyle align="left" mh="110px" size="14px" >
                  <CardsTxt className="roboto" size="16px" weight="600">
                      Beneficios
                  </CardsTxt>
                  {card.benefit ? JSON.parse(card.benefit)['beneficios'].slice(0,2).map((detalle) => {
                      return <li className="roboto styleBenef">{detalle}</li>;
                  }): null}
              </DivStyle>
            :null}
            {showBenefit ? 
              <DivStyle align="left" mh="80px" size="14px">
                  <CardsTxt className="roboto" size="16px" weight="600">
                      Información extra
                  </CardsTxt>
                  {card.benefit ? JSON.parse(card.benefit)['extra'].slice(0,2).map((detalle) => {
                      return <li className="roboto styleBenef">{detalle}</li>;
                  }): null}
              </DivStyle>
            : null}
            {showButton ? 
              <CardsBtn mtopmd="10px" mtop="10px" mtopw="10px" min_w="100%" min_web="100%" onClick={() => ClickButton(card.CardName, card.CardImage, card.Category, card)}>
                Continuar
              </CardsBtn>: null 
            }
        </div>
    </div>
);
}
export default ResultProduct;
