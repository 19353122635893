import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { GET_CLIENTES } from '../queries';

const Clientes = () => (
  <Query query={GET_CLIENTES}>
    {({ loading, error, data }) => {
      if (loading) return 'Cargando';
      if (error) return `Error: ${error.message}`;
      console.log(data.getClientes);
      const { getClientes } = data;
      return (
        <Fragment>
          <h2 className="text-center">Listado Clientes</h2>
          <ul className="list-group">
            {getClientes.map((cliente) => (
              <li key={cliente.id} className="list-group-item">
                <div className="row justify-content-between align-items-center">
                  <div className="col-md-8 d-flex jusify-content-center align-items-center">
                    <p>
                      {cliente.nombre} {cliente.apellido} - {cliente.email}
                    </p>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end align-items-center">
                    <a className="btn btn-success d-block d-md-inline-block">
                      Editar Cliente
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Fragment>
      );
    }}
  </Query>
);

export default Clientes;
