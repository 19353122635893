/* eslint-disable no-return-assign */
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { UPDATE_SESSION } from '../../mutations';
import GeneralLoader from '../common/GeneralLoader';
import {
  Image,
  Btn_Share,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  ContEntrenamientos,
  Container,
} from '../../assets/styles/Home.styled';
import share from '../../assets/img/anacoach/IconShare.png';
import ModalShareWO from './ModalShareWO';
import Clock from '../../assets/img/anacoach/clockTraining.png';

function ListItems({
  dynamicInsert,
  tam,
  items,
  feed,
  progress = false,
  lp = false,
}) {
  const openT = sessionStorage.getItem('openNameTraining');

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [open, setOpen] = useState(false);
  const [infoTraining, setInfoTraining] = useState([]);
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const [newmostrarLoad, setNewMostrarLoad] = useState(false);

  const handleClickShare = (TrainingRedirectUrl) => {
    setOpen(true);
    console.log(TrainingRedirectUrl);
  };
  useEffect(() => {
    if (infoTraining.length !== 0) {
      handleClickShare(infoTraining.TrainingRedirectUrl);
    }
  }, [infoTraining]);

  const history = useHistory();

  const handleClickTraining = (training, ghosting = false) => {
    localStorage.setItem('activityDate', new Date());
    sessionStorage.setItem('trainingName', training.name);
    const action = 'clickTraining';
    const tagManagerArgs = {
      dataLayer: {
        event: action.concat(training.name),
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    TagManager.dataLayer(tagManagerArgs);
    // debugger
    const urlRedirect = training.TrainingRedirectUrl
      ? training.TrainingRedirectUrl
      : '#';
    console.log(training);
    // debugger
    if (training.Show) {
      sessionStorage.setItem(training.name, 'on');
    } else {
      sessionStorage.setItem(training.name, 'off');
    }
    const lastClick = {
      name: training.name,
      timestamp,
    };
    if (ghosting) lastClick.ghosting = true;
    let clicksTraining;
    if (sessionStorage.getItem('clicksTraining')) {
      clicksTraining = JSON.parse(sessionStorage.getItem('clicksTraining'));
      clicksTraining.push(lastClick);
      sessionStorage.setItem('clicksTraining', JSON.stringify(clicksTraining));
    } else {
      clicksTraining = [lastClick];
      sessionStorage.setItem('clicksTraining', JSON.stringify(clicksTraining));
    }
    const dataJson = {
      clickTraining: clicksTraining,
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    if (sessionStorage.getItem('id_session')) updateSession({ variables: mongoUpdateInput });
    if (!ghosting) {
      // history.push(urlRedirect);
      sessionStorage.setItem('RedirectUrl', urlRedirect);
      setMostrarLoad(false);
      setNewMostrarLoad(false);
      window.location.href = urlRedirect;
    }
  };

  useEffect(() => {
    if (mostrarLoad !== false) {
      handleClickTraining(mostrarLoad, false);
      // setMostrarLoad(true);
      setNewMostrarLoad(true);
    }
  }, [mostrarLoad]);

  const closeModal = () => {
    setOpen(false);
  };
  let par = 1;

  return (
    <Fragment>
      <Container
        width={lp ? '45%' : '100%'}
        fw={lp ? '' : 'wrap'}
        float={lp ? 'auto 0' : 'auto'}
        displayweb={lp ? 'block' : 'flex'}
        widthMobile="100%"
        mtMobile="0px"
      >
        {items.map(
          (post) => (
            (par = par === 0 ? 1 : 0),
            (
              /* post.Show ? ( */
              <ContEntrenamientos
                className={
                  !progress && feed && openT.includes(post.name)
                    ? 'hidden'
                    : ''
                }
                id="ContEntrenamiento"
                paddingw="0px"
                background="transparent"
                flex={feed ? '0 0 100%;' : '0 0 50%'}
                flexMob={feed ? '0 0 100%;' : '0 0 90%'}
                maxwidthMob={feed ? '100%;' : '90%'}
              >
                <Btn_Share
                  left={progress ? '-50px' : '-26px'}
                  leftw="-8.5%"
                  leftrG="-10%"
                  onClick={() => setInfoTraining(post)}
                >
                  <Image
                    src={share}
                    width="18px"
                    widthmob="18px"
                    float="right"
                    position="absolute"
                    bottom="-50px"
                    bottmob="-52px"
                    top={lp ? '10px' : '24px'}
                    padd="0"
                    paddmob="0"
                    cursor="pointer !important"
                    className="img-fluid"
                    index="1"
                    zindex="1"
                    onClick={''}
                  />
                </Btn_Share>
                <ContEntrenamiento
                  id={post.id}
                  background={post.color}
                  widthM="100%"
                  widthD={feed ? '100%' : '95%'}
                  cursor="pointer"
                >
                  <div
                    onClick={() => {
                      setMostrarLoad(post);
                    }}
                  >
                    <ContentTimer
                      height=" "
                      maxheightMobile="130px"
                      heightMobile="140px"
                      maxheight="107.250px"
                      display="flex"
                      top="0px"
                      width="100%"
                      position=" "
                    >
                      <TitleEntrenamiento
                        className="col-9 roboto"
                        left="0px"
                        margin="auto"
                        top="0"
                        pl="0"
                        pr="0"
                      >
                        {post.name}
                      </TitleEntrenamiento>
                      <Image
                        src={post.image}
                        width="25%"
                        widthmob="22%"
                        margin="auto"
                        top="5%"
                        bottom="1%"
                        right="0px"
                      />
                    </ContentTimer>
                    <ContentTimer className="container-bar">
                      <Image
                        src={Clock}
                        width="20px"
                        left="0px"
                        position=" "
                        top="1%"
                      />
                      <TextTime leftM="25px" className="roboto">
                        {post.time}
                      </TextTime>
                    </ContentTimer>
                  </div>
                </ContEntrenamiento>
              </ContEntrenamientos>
            )
          ),
        )}
      </Container>
      {newmostrarLoad ? <GeneralLoader></GeneralLoader> : null}
      <ModalShareWO
        openModal={open}
        closeModal={closeModal}
        redirect={infoTraining.TrainingRedirectUrl}
      ></ModalShareWO>
    </Fragment>
  );
}

export default ListItems;
