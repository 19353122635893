import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import DatosBasicos from '../Routines/DatosBasicos';
import BudgetExplanation from '../Routines/BudgetExplanation';
import MoneySavedOrInvested from '../Routines/MoneySavedOrInvested';
import ThereAreDebts from '../Routines/ThereAreDebts';
import VideoIngresos from '../Routines/VideoIngresos';
import Ingresos from '../Routines/Ingresos';
import EgresosVideos from '../Routines/EgresosVideos';
import Egresos from '../Routines/Egresos';
import Gastos from '../Routines/Gastos';
import GastosExtra from '../Routines/GastosExtra';
import SuccesTrainingBudget from '../Routines/SuccesTrainingBudget';
import { GET_TRAINING_COLOR } from '../../queries';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderTrainingBudget = ({
  Date,
  setStateDate,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  setSaludo,
}) => {

  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (orderRutine === 11) {
      setSaludo(sessionStorage.getItem('successBudget') ? 'No' : 'Si');
      setSuccess(!!sessionStorage.getItem('successBudget'));
    }
  });
  const [colorTraining, setColorTraining] = useState('');
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  return (
    <>
      {orderRutine >= 1 && !success ? (
        // <DatosBasicos
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   trainingColor={colorTraining}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        // />
        <ModalLogin
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}
      {orderRutine >= 2 && !success ? (
        <>
          <BudgetExplanation
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            trainingColor={colorTraining}
            currentRoutine={2}
            nextOrderRoutine={3}
          />
        </>
      ) : null}
      {orderRutine >= 3 && !success ? (
        <MoneySavedOrInvested
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={3}
          nextOrderRoutine={4}
        />
      ) : null}
      {orderRutine >= 4 && !success ? (
        <ThereAreDebts
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={4}
          nextOrderRoutine={5}
        />
      ) : null}
      {orderRutine >= 5 && !success ? (
        <VideoIngresos
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={5}
          nextOrderRoutine={6}
        />
      ) : null}
      {orderRutine >= 6 && !success ? (
        <Ingresos
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={6}
          nextOrderRoutine={7}
        />
      ) : null}
      {orderRutine >= 7 && !success ? (
        <EgresosVideos
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={7}
          nextOrderRoutine={8}
        />
      ) : null}
      {orderRutine >= 8 && !success ? (
        <Egresos
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={8}
          nextOrderRoutine={9}
        />
      ) : null}
      {orderRutine >= 9 && !success ? (
        <Gastos
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={9}
          nextOrderRoutine={10}
        />
      ) : null}
      {orderRutine >= 10 && !success ? (
        <GastosExtra
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={colorTraining}
          currentRoutine={10}
          nextOrderRoutine={11}
        />
      ) : null}

      {orderRutine >= 11 ? (
        <>
          <SuccesTrainingBudget
            Date={Date}
            hora={hora}
            orderRutine={orderRutine}
            setOrderRutine={setOrderRutine}
            currentTraining={currentTraining}
            createJsonInput={createJsonInput}
            questions={questions}
            setQuestions={setQuestions}
            trainingColor={colorTraining}
            currentRoutine={11}
            nextOrderRoutine={11}
            success={success}
            setSuccess={setSuccess}
            setSaludo={setSaludo}
          />
        </>
      ) : null}
    </>
  );
};
export default RoutinesOrderTrainingBudget;
