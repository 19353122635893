/* eslint-disable no-nested-ternary */
import React, { Fragment, useState, useEffect } from "react";
import {
  SubtitleOne,
  Container,
  Col6,
} from "../../components/common/common.styled";
import "../../assets/styles/TextCustom.css";
import InputAna from "../../components/common/InputAna";
import Eye from "../../assets/img/Eye.svg";
import Eyeblocked from "../../assets/img/Eye-blocked.svg";

const Contrasena = ({
  form,
  setForm,
  validCampos,
  setvalidCampos,
  islogin = false,
  errorPassword,
  conteoPassword = 0,
  cuentaBloqueada = false,
  errorInexistente,
}) => {
  const [error] = useState({
    pass1: false,
    pass2: false,
    pass3: false,
    pass4: false,
  });

  const [type, setType] = useState("password");

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
    });
    const { name, value } = e.target;
    switch (name) {
      case "pass1":
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass2']").focus();
        } else {
          return false;
        }
        break;
      case "pass2":
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass3']").focus();
        } else {
          return false;
        }
        break;
      case "pass3":
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass4']").focus();
        } else {
          return false;
        }
        break;
      default:
        break;
    }
    ValidCampos();
  };

  const handleStatus = () => {
    if (type === "password") setType("text");
    else setType("password");
  };

  const ValidCampos = () => {
    if (
      form.pass1 !== ""
      && form.pass1 !== null
      && form.pass2 !== ""
      && form.pass2 !== null
      && form.pass3 !== ""
      && form.pass3 !== null
    ) {
      setvalidCampos({
        ...validCampos,
        password: true,
      });
      document.getElementById("pass1").blur();
      document.getElementById("pass2").blur();
      document.getElementById("pass3").blur();
      document.getElementById("pass4").blur();
    } else {
      setvalidCampos({
        ...validCampos,
        password: false,
      });
    }
  };
  return (
    <Fragment>
      <SubtitleOne
        className="font-weight-bold roboto"
        size="16px"
        sizeMobile="16px"
        mb="8px"
        mt="24px">
        Contraseña
      </SubtitleOne>
      <SubtitleOne
        className="roboto"
        size="12px"
        sizeMobile="12px"
        fw="300"
        fwmd="300"
      >
        Ingresa tu contraseña de 4 dígitos
      </SubtitleOne>
      <form autoComplete="off" style={{ marginBottom: "24px" }}>
        <Container width="100%" widthMobile="100%">
          <Container widthDesk="20%" widthMobile="20%">
            <InputAna
              placeholder={""}
              id="pass1"
              value={form.pass1}
              type={type}
              inputMode="numeric"
              pattern="^[0-9]\d*\d*$"
              handleChange={handleChange}
              handleBlur={handleChange}
              error={error.pass1}
              maxlength={"1"}
              className={
                errorPassword || cuentaBloqueada
                  ? "center errorPassword"
                  : "center"
              }
            />
          </Container>
          <Container widthDesk="20%" widthMobile="20%">
            <InputAna
              placeholder={""}
              id="pass2"
              value={form.pass2}
              type={type}
              inputMode="numeric"
              pattern="^[0-9]\d*\d*$"
              handleChange={handleChange}
              handleBlur={handleChange}
              error={error.pass2}
              maxlength={"1"}
              className={
                errorPassword || cuentaBloqueada
                  ? "center errorPassword"
                  : "center"
              }
            />
          </Container>
          <Container widthDesk="20%" widthMobile="20%">
            <InputAna
              placeholder={""}
              id="pass3"
              value={form.pass3}
              type={type}
              inputMode="numeric"
              pattern="^[0-9]\d*\d*$"
              maxLength={1}
              handleChange={handleChange}
              handleBlur={handleChange}
              error={error.pass4}
              maxlength={"1"}
              className={
                errorPassword || cuentaBloqueada
                  ? "center errorPassword"
                  : "center"
              }
            />
          </Container>
          <Container widthDesk="20%" widthMobile="20%">
            <InputAna
              placeholder={""}
              id="pass4"
              value={form.pass4}
              type={type}
              inputMode="numeric"
              pattern="^[0-9]\d*\d*$"
              maxLength={1}
              handleChange={handleChange}
              handleBlur={handleChange}
              error={error.pass4}
              maxlength={"1"}
              className={
                errorPassword || cuentaBloqueada
                  ? "center errorPassword"
                  : "center"
              }
            />
          </Container>
          <Container mt="auto" width="15%" widthMobile="15%" style={{ display: "flex" }}>
            <img
              src={type === "password" ? Eyeblocked : Eye}
              style={{ margin: "auto" }}
              onClick={handleStatus}
            />
          </Container>
        </Container>
        {((islogin && errorPassword) || cuentaBloqueada || errorInexistente) && (
          <>
            <p style={{ margin: "0px", color: "red" }}>
            {errorPassword}
              {/* {errorInexistente ? "El usuario ingresado no existe"
                : (cuentaBloqueada
                  ? "Cuenta bloqueada. Vuelve a intentar en 10 minutos o ingresa con un enlace que enviaremos a tu WhatsApp o correo electrónico."
                  : `Contraseña incorrecta. Tienes ${
                    conteoPassword
                  } intentos restantes.`)} */}
            </p>
          </>
        )}
      </form>
    </Fragment>
  );
};

export default Contrasena;
