import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import CarouselP from 'react-elastic-carousel';
import { UPDATE_SESSION } from '../../mutations';
import GeneralLoader from '../common/GeneralLoader';

import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  ContentTimer,
  Container,
} from '../../assets/styles/Home.styled';
import ModalShare from './ModalShare';
import ModalAprendi from '../Logros/ModalAprendi';

function ListItems({ dynamicInsert, tam, items }) {
  console.log('Items', items[0]);
  const items2 = items[0];

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [open, setOpen] = useState(false);
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const history = useHistory();

  const handleClickTraining = (training, ghosting = false) => {
    sessionStorage.setItem('trainingName', training.name);
    const action = 'clickTraining';
    const tagManagerArgs = {
      dataLayer: {
        event: action.concat(training.name),
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    TagManager.dataLayer(tagManagerArgs);
    const urlRedirect = training.TrainingRedirectUrl
      ? training.TrainingRedirectUrl
      : '#';
    const lastClick = {
      name: training.name,
      timestamp,
    };
    if (ghosting) lastClick.ghosting = true;
    let clicksTraining;
    if (sessionStorage.getItem('clicksTraining')) {
      clicksTraining = JSON.parse(sessionStorage.getItem('clicksTraining'));
      clicksTraining.push(lastClick);
      sessionStorage.setItem('clicksTraining', JSON.stringify(clicksTraining));
    } else {
      clicksTraining = [lastClick];
      sessionStorage.setItem('clicksTraining', JSON.stringify(clicksTraining));
    }
    const dataJson = {
      clickTraining: clicksTraining,
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    if (sessionStorage.getItem('id_session')) updateSession({ variables: mongoUpdateInput });
    if (!ghosting) {
      history.push(urlRedirect);
      setMostrarLoad(false);
    }
  };
  useEffect(() => {
    if (mostrarLoad !== false) {
      handleClickTraining(mostrarLoad, false);
      setMostrarLoad(true);
    }
  }, [mostrarLoad]);

  const closeModal = () => {
    setOpen(false);
  };

  // MODAL
  const [openApendi, setOpenAprendi] = useState(false);
  const [Aprendi, setAprendi] = useState({
    aprendi1: '',
    aprendi2: '',
    aprendi3: '',
  });

  const handleOpenAprendi = (id, name) => {
    setOpenAprendi(true);
    setnameTitle(name);
    const { TrainingRedirectUrl: url } = items2[0].filter((e) => e.CatTraining_IDTraining === id)[0];
    setUrlToShare(url);
    switch (id) {
      case 1:
        console.log('Solicita tu tarjeta sin trámites');
        setAprendi({
          aprendi1: 'Solicitaste tu tarjeta ',
          aprendi2: 'Ya sabes cuál es la mejor tarjeta para ti',
          aprendi3: 'Conoces todas tus opciones',
        });
        break;
      case 12:
        console.log('Adelgaza tus deudas');
        setAprendi({
          aprendi1: 'Identificaste tu tipo de deuda',
          aprendi2: 'Sabes que hace un despacho de cobranza',
          aprendi3: 'Opciones para adelgazar tu deuda',
        });
        break;
      case 11:
        console.log('Protege a tu familia');
        setAprendi({
          aprendi1: 'Asesoría para elegir',
          aprendi2: 'Sabes que protección necesitas',
          aprendi3: 'Las mejores opciones de protección',
        });
        break;
      case 9:
        console.log('Solicita un préstamo personal');
        setAprendi({
          aprendi1: 'Identifica el tipo de crédito que necesitas',
          aprendi2: 'Que plazo de pago existen',
          aprendi3: 'Que opciones de crédito tienes',
        });
        break;
      case 17:
        console.log('Tu primera inversión');
        setAprendi({
          aprendi1: 'Conoces perfil de inversionista',
          aprendi2: 'Conceptos básicos de inversiones',
          aprendi3: 'Herramientas para iniciar',
        });
        break;
      case 3:
        console.log('La tarjeta de crédito ideal para ti');
        setAprendi({
          aprendi1: 'Encontraste la tarjeta ideal para ti',
          aprendi2: 'Conoces todas tus opciones',
          aprendi3: 'Conoces los requisitos para solicitarla',
        });
        break;
      case 21:
        console.log('Ahorro de emergencia');
        setAprendi({
          aprendi1: 'La importancia del ahorro de emergencia',
          aprendi2: 'Cuando debo ahorrar',
          aprendi3: 'En donde puedo ahorrar',
        });
        break;
      case 24:
        console.log('Aumenta ingresos');
        setAprendi({
          aprendi1: 'Preparación para el futuro',
          aprendi2: 'Como puedo explotarlas ',
          aprendi3: 'Conociste opciones',
        });
        break;
      case 2:
        console.log('Domina el uso de tu primera tarjeta de crédito');
        setAprendi({
          aprendi1: 'Las comisiones no te tomarán por sorpresa',
          aprendi2: 'Comparaste productos',
          aprendi3: 'Como evitar intereses ',
        });
        break;
      case 14:
        console.log('Construye tu presupuesto');
        setAprendi({
          aprendi1: 'Entender la importancia de tener un presupuesto',
          aprendi2: 'Identificaste ingresos y gastos',
          aprendi3: 'Organizaste tus gastos',
        });
        break;
      case 32:
        console.log('Mis propositos de año nuevo');
        setAprendi({
          aprendi1: 'Priorizaste tus propósitos ',
          aprendi2: 'Darás seguimiento a tus propósitos',
          aprendi3: 'Aprendiste del método S.M.A.R.T',
        });
        break;
      case 23:
        console.log('Test de inversión');
        setAprendi({
          aprendi1: 'Sabes cual es tu perfil inversionista',
          aprendi2: 'Identificaste las mejores opciones de inversión',
          aprendi3: 'Conociste tu nivel de aversión al riesgo',
        });
        break;
      case 18:
        console.log('Catalogo de inversiones');
        setAprendi({
          aprendi1: 'Conociste opciones para invertir',
          aprendi2: 'Entiendes más sobre inversiones',
          aprendi3: 'Entiendes sobre rendimientos ',
        });
        break;
      case 29:
        console.log('Prestamos a tu medida');
        setAprendi({
          aprendi1: 'Identificaste el objetivo de tu crédito',
          aprendi2: 'Solicitaste tu préstamo personal',
          aprendi3: 'Darás seguimiento a la aprobación',
        });
        break;
      case 33:
        console.log('Perfilamiento 2.0');
        setAprendi({
          aprendi1: 'Entendiste tu relación con el dinero',
          aprendi2: 'Nivel de riesgo',
          aprendi3: 'Preparación para el futuro',
        });
        break;
      case 27:
        console.log('Inversiones Simulador');
        setAprendi({
          aprendi1: 'Aprendiste a invertir sin riesgo',
          aprendi2: 'Sabes cuánto podrías ganar',
          aprendi3: 'Identificaste en que te conviene invertir',
        });
        break;
      case 25:
        console.log('Mejorar presupuesto');
        setAprendi({
          aprendi1: 'Tienes tu presupuesto al día',
          aprendi2: 'Identificaste áreas de mejora',
          aprendi3: 'Mejoraraste el control con tus gastos',
        });
        break;
      case 34:
        console.log('¿Por qué no me dan una tarjeta de crédito?');
        setAprendi({
          aprendi1: 'Causas de una tarjeta negada',
          aprendi2: 'Entendiste la importancia del historial crediticio',
          aprendi3: 'Sabes cuanto tiempo esperar para solicitar un crédito',
        });
        break;
      case 28:
        console.log('Crear Meta');
        setAprendi({
          aprendi1: 'Fijaste metas',
          aprendi2: 'Creaste una estrategía',
          aprendi3: 'Darás seguimiento a tu meta',
        });
        break;
      case 22:
        console.log('Cuánto sabes de inversión');
        setAprendi({
          aprendi1: '',
          aprendi2: '',
          aprendi3: '',
        });
        break;
      case 20:
        console.log('Cuánto sabes de tu dinero');
        setAprendi({
          aprendi1: '',
          aprendi2: '',
          aprendi3: '',
        });
        break;
      default:
        console.log('default');
        setAprendi({
          aprendi1: 'Aprender a invertir sin riesgo',
          aprendi2: 'Conoce cuanto podrías ganar',
          aprendi3: 'En que te conviene invertir',
        });
        break;
    }
  };

  const handleCloseAprendi = () => {
    setOpenAprendi(false);
  };

  const [nameTitle, setnameTitle] = useState('');
  const [urlToShare, setUrlToShare] = useState('');

  const Targeta = (post) => {
    console.log(post);
    return (
      <ContEntrenamiento
        id={post.post.IDCatTraining}
        onClick={() => {
          handleOpenAprendi(post.post.IDCatTraining, post.post.Name);
        }}
        background={post.post.TrainingColor}
        widthM="100%"
        widthD="100%"
        cursor="pointer!important"
      >
        <div
          onClick={() => {
            handleOpenAprendi(post.post.IDCatTraining, post.post.Name);
          }}
        >
          <ContentTimer
            height=" "
            maxheightMobile="130px"
            heightMobile="115px"
            maxheight="107.250px"
            display="flex"
            top="0%"
            width="100%"
            position=" "
          >
            <TitleEntrenamiento
              className="roboto"
              left="0px"
              margin="auto"
              top=" "
              widthD="230px"
            >
              {post.post.Name}
            </TitleEntrenamiento>
            <Image
              src={post.post.UrlBigImage}
              width="25%"
              widthmob="28.75%"
              margin="auto"
              top="1%"
              bottom="1%"
              right="0px"
            />
          </ContentTimer>
          <ContentTimer top="1%">
            <p className="roboto" style={{ cursor: 'pointer', color: '#000' }}>
              ¿Qué aprendí?
            </p>
          </ContentTimer>
        </div>
      </ContEntrenamiento>
    );
  };
  const [carruselV] = useState(!(items2.length > 1));
  return (
    <Fragment>
      <Container
        id="CarruselEmpresasContent"
        width="100%"
        widthMobile="100%"
        mtMobile="0px"
        className="WithNoCarrusel"
      >
        {carruselV > 3 ? (
          <CarouselP styled={{ background: '#FAFAFA' }}>
            {items2.map((item, index) => (
              <div
                id="divDivisor"
                key={index + 1}
                style={{ minHeight: '500px', width: '100%' }}
              >
                {item.map((post) => (
                  <Targeta post={post} />
                ))}
              </div>
            ))}
          </CarouselP>
        ) : (
          <Fragment>
            {items2.map((item) => (
              <div style={{ width: '100%' }}>
                {item.map((post) => (
                  <Targeta post={post} />
                ))}
              </div>
            ))}
          </Fragment>
        )}
      </Container>
      <ModalAprendi
        openApendi={openApendi}
        handleCloseAprendi={handleCloseAprendi}
        nameTitle={nameTitle}
        aprendi1={Aprendi.aprendi1}
        aprendi2={Aprendi.aprendi2}
        aprendi3={Aprendi.aprendi3}
        urlToShare={urlToShare}
      />
      {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
      <ModalShare openModal={open} closeModal={closeModal}></ModalShare>
    </Fragment>
  );
}

export default ListItems;
