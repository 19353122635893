/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Carousel from 'react-elastic-carousel';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery, useMutation } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import {
  Rectangle23,
  ContentHomeSection,
  Vector3,
  Vector2,
} from '../../components/common/Home.styled';
import {
  TrainingQuestion,
  QuestionsFlex,
  CardsBtn,
  Resultado,
  TrainingText,
  TrainingSectionFlex,
  CardsTxt,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/Modal.css';
import '../../assets/styles/Carrusel.css';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import Recommendations from '../../components/Training/RecommendedTraining';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import {
  Container,
  Col7,
  Col12,
  Button,
  ParagrapTwo,
  ContentGeneric,
} from '../../components/common/common.styled';
import { Feed_Section } from '../../assets/styles/Feed.styled';
// recovery
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_PRODUCTS_BY_IDTRAINING,
  GET_PRODUCTS_WITH_SCORE,
} from '../../queries';
import { DYNAMIC_GET } from '../../mutations';
import { arraySessionsRutines } from '../../Helpers';
import ScoreGraphic from '../../components/Training/ScoreGraphic';
import CardScore from '../../components/Home/Card1AnimateTitle';

import SectionShare from '../../components/common/ShareSection';
import RpProductsWithStars from '../../components/common/RpProductsWithStars';

import Bookmark from '../../assets/img/logros/Bookmark_Standard.svg';
import Share from '../../assets/img/logros/Share_Standard.svg';
import Wallet from '../../assets/img/logros/Wallet_Standard.svg';
import scorew from '../../assets/img/logros/ScoreW.svg';
import Star_green from '../../assets/img/anacoach/Star_green.svg';
import ClickButton from '../../Helpers/HookHelpers';

const Div_Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fd || ''};
  justify-content: ${(props) => props.jc || 'space-around'};
  align-items: ${(props) => props.alignItems || ''};
  align-self: ${(props) => props.alignSelf || ''};
  margin: ${(props) => props.margin || 'auto'};
  margin-top: ${(props) => props.margintop || ' '};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ' '};
  cursor: ${(props) => props.cursor || ' '};
  @media (max-width: 768px) {
    margin-top: ${(props) => props.margintopM || ' '};
  }
`;
export const TextMaxLine = styled.p`
  padding: 0;
  weight: ${(props) => props.weight || ''};
  width:${(props) => props.wid || ''};
  font-size: ${(props) => props.fontSize || ''};
  font-family: ${(props) => props.fontFamily || ''};
  font-weight: ${(props) => props.fontWeight || ''};
  color: ${(props) => props.fontColor || ''};
  margin: ${(props) => props.margin || '0'};
  text-align:${(props) => props.textAlign || ''};
  line-height: ${(props) => props.lineHeight || '130%'};
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp:${(props) => props.maxLine || ''}; /* number of lines to show */
   -webkit-box-orient: vertical;
   @media(max-width: 768px){
    font-size: ${(props) => props.fontSizeXs || ''};
    line-height: ${(props) => props.lineHeightXs || ''};
   }
`;
const TextBold16 = styled.p`
  padding: 0;
  margin: ${(props) => props.margin || '0'};
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.fontColor || ''};
  line-height: 120%;
`;
const Text14 = styled.p`
  padding: 0;
  width:${(props) => props.wid || ''};
  font-size: 14px;
  color: ${(props) => props.fontColor || ''};
  margin: ${(props) => props.margin || ''};
  padding-top: ${(props) => props.pt || ' '};
  line-height: ${(props) => props.lineHeight || '130%'};
`;
const TextBold14 = styled.p`
  padding:  ${(props) => props.padding || '0'}; 
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.fontColor || ''};
  align-self:  ${(props) => props.alignSelf || ''};
  margin-left:  ${(props) => props.mL || ''}; 
  margin-right:  ${(props) => props.mR || ''}; 
  line-height: 120%;
`;

const KnowScore = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  currentRoutine,
  nextOrderRoutine,
  backWO,
}) => {
  const [recoveryRoutine, setRecoveryRoutine] = useState('');
  const [recommendedTraining, setRecommendedTraining] = useState([]);
  const anchop = window.screen.width;
  const [Rutine, setRutine] = useState({});
  const [countRecovery] = useState(0);
  const [goal, setGoal] = useState(sessionStorage.getItem('Conoce_tu_score') || '');
  const [statusAuth, setStatusAuth] = useState(
    sessionStorage.getItem('statusAutenticacion') || '',
  );
  const [tip, setTip] = useState('');
  const [showNextQuestion, setShowNextQuestion] = useState(false);
  const [bcScore, setBcScore] = useState(
    sessionStorage.getItem('BCScore') || '',
  );
  const [openModal, setopenModal] = useState(false);
  const [oPenModalSi, setoPenModalSi] = useState(false);
  const [commentBuro, setCommentBuro] = useState('');
  const [products, setProducts] = useState([]);
  const [productsAvailable, setProductsAvailable] = useState([]);

  const [form, setForm] = useState({});
  const { actions } = ClickButton();
  const [idTrainingsByResponse] = useState({
    'Quiero mejorar mi Score crediticio': 'productos',
    'Estoy en busca de un préstamo personal': 9,
    'Quiero solucionar mis deudas': 12,
    'Me interesa conocer sus productos': 18,
    'No tengo historial. ¿Qué debo hacer?': 36,
  });

  const handleClose = () => {
    setopenModal(false);
  };
  const handleCloseS = () => {
    setoPenModalSi(!oPenModalSi);
  };

  useEffect(() => {
    setTimeout(() => {
      if (backWO) {
        setopenModal(false);
      } else {
        setopenModal(true);
      }
    }, 3000);
  }, [bcScore]);
  const SiQ = () => {
    const click = `SiQuieroRecibirMiScore_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setopenModal(false);
    setoPenModalSi(!oPenModalSi);
  };
  const abrirNuevoTab = (url) => {
    if (url.includes('[IDUser]')) {
      url = url.replace(
        '[IDUser]',
        sessionStorage.getItem('idUser'),
      );
    }
    if (url.includes('[idOpportunity]')) {
      url = url.replace(
        '[idOpportunity]',
        sessionStorage.getItem('datOpportunity'),
      );
    }
    const win = window.open(url, '_blank');
    win.focus();
  };
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (countRecovery < 2) {
          if (Object.keys(json).length === 0) {
            setRecoveryRoutine('inactiva');
          } else {
            const routines = json; // Object.values(json);
            let contVideos = 0;
            const stopRoutine = false;
            for (const idRoutine in routines) {
              const arrayResponseRutine = routines[idRoutine].responses;
              for (let i = 0; i < arrayResponseRutine.length; i++) {
                const idTypeQuestion = arrayResponseRutine[i].idType;
                const responseUser = arrayResponseRutine[i].responseValue;
                const { questionType } = arrayResponseRutine[i];
                if (
                  responseUser !== ''
                  && responseUser !== 'null'
                  && responseUser !== null
                ) {
                  if (questionType === 'Cat') {
                    const { detailQuestion } = routines[idRoutine].questions[i];
                    for (let x = 0; x < detailQuestion.length; x++) {
                      if (detailQuestion[x].FieldOption === responseUser) {
                        sessionStorage.setItem(
                          arraySessionsRutines[idTypeQuestion],
                          responseUser,
                        );
                      }
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                } else if (questionType === 'Video') {
                  contVideos++;
                }
              }
              if (
                arrayResponseRutine.length
                < routines[idRoutine].questions.length
              ) {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                break;
              }

              if (stopRoutine) {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
                break;
              } else {
                setTimeout(() => setRecoveryRoutine('activa'), 11000);
                setQuestions([]);
              }
            }
            setGoal(sessionStorage.getItem('Conoce_tu_score') || '');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getMessageByComment] = useMutation(DYNAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      if (dynamicGet) {
        const resp = JSON.parse(dynamicGet.response);
        if (resp.length > 0) {
          const { message } = resp[0];
          setTip(message);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getRecommendationsById] = useMutation(DYNAMIC_GET, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicGet }) {
      if (dynamicGet) {
        const resp = JSON.parse(dynamicGet.response);
        if (resp.length > 0) {
          setRecommendedTraining(resp);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getProductsWithScore] = useLazyQuery(GET_PRODUCTS_WITH_SCORE, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsWithScore }) {
      if (getProductsWithScore) {
        const response = JSON.parse(getProductsWithScore.response);
        setProductsAvailable(response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ getProductsByIdTraining }) {
      if (getProductsByIdTraining) {
        const response = JSON.parse(getProductsByIdTraining.response);
        setProducts(response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('Resp_Autenticate') !== null
      && sessionStorage.getItem('Resp_Autenticate') !== ''
    ) {
      const respBuro = JSON.parse(sessionStorage.getItem('Resp_Autenticate'));
      if (
        statusAuth === 'Autenticado'
        || respBuro.RespuestaBC === 'autenticado'
      ) {
        setStatusAuth(
          respBuro.RespuestaBC === 'autenticado' ? 'Autenticado' : '',
        );
        if (respBuro.RespComentario !== '') {
          let comment = respBuro.RespComentario.split('|')[0];
          comment = comment.split('-')[1].trim();
          setCommentBuro(comment);
          setBcScore(sessionStorage.getItem('BCScore'));
          getMessageByComment({
            variables: {
              input: {
                table: 'CatScoreAvailability',
                columns: ['*'],
                conditions: [
                  {
                    valone: 'Value',
                    condition: 'like',
                    valtwo: `${comment}%`,
                  },
                ],
              },
            },
          });
        } else {
          setShowNextQuestion(true);
          setBcScore('');
        }
      } else {
        setShowNextQuestion(true);
        setBcScore(sessionStorage.getItem('BCScore'));
      }
    }
  }, [sessionStorage.getItem('Resp_Autenticate')]);

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setTimeout(() => setRecoveryRoutine('inactiva'), 9000);
    }
  }, []);

  const handleChangeRutine = (data) => {
    setRutine(data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (type === 'Multiple') {
      return false;
    }
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    switch (val.step) {
      case 0:
        setGoal(val.value);
        const inputRecommendation = {
          input: {
            table: 'CatTraining',
            columns: [
              'IDCatTraining',
              'TrainingBigTitle',
              'UrlBigImage',
              'UrlMediumImage',
              'TrainingTime',
              'TrainingColor',
              'TrainingRedirectUrl',
            ],
            conditions: [
              {
                valone: 'IDCatTraining',
                condition: '=',
                valtwo: String(idTrainingsByResponse[val.value]),
              },
            ],
          },
        };
        getRecommendationsById({ variables: inputRecommendation });
        setTimeout(() => {
          gsap.to(window, { duration: 1, scrollTo: '#aciveRPKnowYourScore' });
        }, 1000);
        break;
      default:
        console.log(val);
    }
  };
  // const handleChangeCatOption = (val) => {
  //   switch (val.step) {
  //     case 0:
  //       setGoal(val.value);
  //       if (val.value === 'Quiero mejorar mi Score crediticio') {
  //         getProductsByIdTraining({ variables: { idTraining: '41' } });
  //         setTimeout(() => {
  //           gsap.to(window, { duration: 1, scrollTo: '#aciveRPKnowYourScore' });
  //         }, 1800);
  //       } else {
  //         const inputRecommendation = {
  //           input: {
  //             table: 'CatTraining',
  //             columns: [
  //               'IDCatTraining',
  //               'TrainingBigTitle',
  //               'UrlBigImage',
  //               'UrlMediumImage',
  //               'TrainingTime',
  //               'TrainingColor',
  //               'TrainingRedirectUrl',
  //             ],
  //             conditions: [
  //               {
  //                 valone: 'IDCatTraining',
  //                 condition: '=',
  //                 valtwo: String(idTrainingsByResponse[val.value]),
  //               },
  //             ],
  //           },
  //         };
  //         getRecommendationsById({ variables: inputRecommendation });
  //         setTimeout(() => {
  //           gsap.to(window, { duration: 1, scrollTo: '#aciveRPKnowYourScore' });
  //         }, 1000);
  //       }
  //       break;
  //     default:
  //       console.log(val);
  //   }
  // };

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && goal === ''
        && document.getElementById('activeGoal') !== null
        && document.getElementById('activeGoal') !== undefined
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeGoal' });
        setReviewScroll(false);
      }
      sessionStorage.setItem('stepScore', 'false');
    }, 14000);
    getProductsWithScore({ variables: { idTraining: 41, score: bcScore !== '' ? parseInt(bcScore) : 0 } });
  }, []);

  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
    && sessionStorage.getItem('Resp_Autenticate') === null
    && sessionStorage.getItem('Resp_Autenticate') === ''
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <div id="activeGoal"></div>
        <CardOne
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          classCard={'cardOne'}
          classTop="topOne1"
          text="Continuemos"
        />
        <ScoreGraphic score={bcScore !== '' ? bcScore : 'Sin historial'} />
        <Modal
        style={{ width: '100%', height: '300px' }}
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ContentGeneric
            background = '#ffffff'
            width = '90%'
            widthMd = '25%'
            borderR = '10px'
            height = 'auto'
            margin = " 30% auto auto auto"
            marginMd = "10% auto"
            alignItems = 'center'
            textAlign = 'center'
            padding = '0px 20px'
            overflow= 'hidden'
            >
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              onClick={handleClose}
            >
              X
            </ParagrapTwo>
              <Rectangle23
              background="#91D770"
              Mleft="-30px"
              Mtop="-30px"
              width="60px"
              height="60px"
              left=" " />
              <Rectangle23
              background="#E3E660"
              Mleft="-40px"
              Mtop="40px"
              width="40px"
              height="60px"
              left=" " />
              <Rectangle23
              background="#E3E660"
              Mleft="90px"
              Mtop="-130px"
              width="28px"
              height="32px"
              transform="rotate(45deg)"
              left="58px"
              top="-27px"
              topMb="-47% !important"
              leftMb="30% !important" />
              <Rectangle23
              background="#6BD5AF"
              Mleft="190px"
              Mtop="-50px"
              width="50.36px"
              height="50px"
              transform="rotate(45deg)"
              left="171px"
              leftMb="70% !important" />
              <Rectangle23
              background="#E3E660"
              Mleft="320px"
              Mtop="-30px"
              width="42.53px"
              height="45.22x"
              transform="rotate(45deg)"
              left=" "
              leftMb="59% !important" />
              <Vector2
                left="325px"
                top="40px"
                Ptop="12px"
                Pleft="90%"
                PtopM="100px"
                PleftM="90%"
                background="#91D770"
                transform="rotate(40.06deg)"
                width="60px"
                height="50px"
                widthM="47px"
                heightM="32px"
                displayD=" "
                displayM="block"
                radius="20px"
                martop="20px"
                MlefM="98%"
                MtopM="20%"
              />
            <Container
              width="5%"
              mt="-20%"
              mb="40%"
              widthMobile="350px"
              padding="0 0 0 40px"
              mbMobile="5%"
              paddingMobile="0 0 0 45%"
              mtMobile="-25%"
            >
              <ImageCoachInicio
                topMobile="35px"
                colorAna="#BFDD6A"
                /* closeModal={handleClose} */
                inactive={true}
              />
            </Container>
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
            ></ParagrapTwo>
            <ParagrapTwo
              fontSizeWeb ="26px"
              className="mx-auto"
              mt="30px"
              mtWeb ="30px"
              mb="0px"
              textalignMobile="center"
              texttalign="center"
            >
              ¿Quieres recibir tu score de crédito cada 3 meses?
            </ParagrapTwo>
            <ParagrapTwo
              fontSizeWeb ="16px"
              className="mx-auto"
              mb="0px"
              textalignMobile="center"
              texttalign="center"
            >
              Te servirá para monitorear si tu historial crediticio está mejorando.
            </ParagrapTwo>
            <Button
              minwidth ="70%"
              width="70%"
              className="roboto"
              style={{ marginLeft: 'auto', marginRight: 'auto', height: '35px' }}
              onClick={SiQ}
            >
              ¡Sí quiero!
            </Button>
          </ContentGeneric>
        </Modal>

        <Modal
        style={{ width: '100%', height: '300px' }}
          open={oPenModalSi}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ContentGeneric
            background = '#ffffff'
            width = '90%'
            widthMd = '25%'
            borderR = '10px'
            height = 'auto'
            margin = " 30% auto auto auto"
            marginMd = "10% auto"
            alignItems = 'center'
            textAlign = 'center'
            padding = '0px 20px 0 40px'
            overflow= 'hidden'
            >
            <ParagrapTwo
              fontWeight="700"
              cursor="pointer"
              texttalign="right"
              textalignMobile="right"
              className="mx-auto"
              onClick={handleCloseS}
            >
              X
            </ParagrapTwo>
              <Rectangle23
              background="#91D770"
              Mleft="-30px"
              Mtop="-40px"
              width="60px"
              height="60px"
              left=" " />
              <Rectangle23
              background="#E3E660"
              Mleft="-40px"
              Mtop="30px"
              width="40px"
              height="60px"
              left=" " />
              <Rectangle23
              background="#E3E660"
              Mleft="90px"
              Mtop="-140px"
              width="28px"
              height="32px"
              transform="rotate(45deg)"
              left="58px"
              top="-27px"
              topMb="-47% !important"
              leftMb="30% !important" />
              <Rectangle23
              background="#6BD5AF"
              Mleft="190px"
              Mtop="-60px"
              width="50.36px"
              height="50px"
              transform="rotate(45deg)"
              left="171px"
              leftMb="70% !important" />
              <Rectangle23
              background="#E3E660"
              Mleft="300px"
              Mtop="-40px"
              width="42.53px"
              height="45.22x"
              transform="rotate(45deg)"
              left=" "
              leftMb="59% !important" />
              <Vector2
                left="270px"
                top="45px"
                Ptop="12px"
                Pleft="90%"
                PtopM="100px"
                PleftM="90%"
                background="#91D770"
                transform="rotate(40.06deg)"
                width="60px"
                height="50px"
                widthM="47px"
                heightM="32px"
                displayD=" "
                displayM="block"
                radius="20px"
                martop="50px"
                MlefM="98%"
                MtopM="20%"
              />
            <ParagrapTwo mt="0px" mtWeb="0px"
              fontSizeWeb ="32px"
              fontWeight="bold"
              className="mx-auto"
              mb="0px"
              textalignMobile="center"
              texttalign="center"
            >
              ¡Listo!
            </ParagrapTwo>
            <ParagrapTwo
              fontSizeWeb ="16px"
              className="mx-auto"
              mb="40px"
              mbweb ="40px"
              textalignMobile="center"
              texttalign="center"
              zindex="2"
              style={{ position: 'relative' }}
            >
              Ahora recibirás tu Score de Crédito en tu correo cada 3 meses totalmente gratis.
            </ParagrapTwo>
          </ContentGeneric>
        </Modal>

      <CardScore
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={statusAuth === 'Autenticado' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        textbold="De acuerdo con Buró de Crédito esta es la calificación de tu historial crediticio:"
        textColor={statusAuth === 'Autenticado' ? commentBuro : ''}
        color="#F64282 !important"
        text2="Con la consulta de Buró de Crédito puedes saber que tan sano es tu historial crediticio. Con un buen historial, tendrás acceso a mejores créditos."
      ></CardScore>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={tip !== '' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        textbold="Tip:"
        text={tip}
      >
        {!showNextQuestion ? (
          <CardsBtn
            min_w="100%"
            min_web="100%"
            mtop="15px"
            mtopw="15px"
            onClick={() => setShowNextQuestion(true)}
          >
            <b>Entendido</b>
          </CardsBtn>
        ) : null}
      </CardOne>
      <CardOne
        classAna={'falseAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="Estas son algunas recomendaciones de tarjetas de crédito de acuerdo a tu Score Crediticio."
      ></CardOne>

      {showNextQuestion && (
        <>
          {backWO ? null : (
            <Fragment>
              <QuestionsOrder
                classCard={'cardOne'}
                orderRutine={currentRoutine}
                step={0}
                handleChangeRutine={handleChangeRutine}
                currentTraining={currentTraining}
                textBold=""
                text2=""
              />
              <TrainingQuestion className={goal === '' ? '' : 'hidden'}>
                <QuestionsFlex display="inline">
                  <InputsOrder
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={orderRutine}
                    step="0-0"
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    currentTraining={currentTraining}
                    activeStep={true}
                  />
                </QuestionsFlex>
              </TrainingQuestion>
              <div id="aciveRPKnowYourScore"></div>
              <CardTwo
                classCard={goal !== '' ? 'cardTwoResp' : 'hidden'}
                classTop="topTwo1"
                imgMan="hidden"
                text={goal}
                colorTraining={trainingColor}
              />
            </Fragment>
          )}

          {recommendedTraining.length > 0 ? (
            <Fragment>
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </Fragment>
          ) : null}

          {goal !== '' && (
            <TrainingSectionFlex>
              <Col12
                id="CarruselEmpresasContentHome" /* //aqui */
                pt="20px"
                flex="0 0 100%"
                maxwidth="100%"
                display="flex"
              >
                {/* <Carousel
                  id="CarruselMetasHome"
                  styled={{ background: '#FAFAFA' }}
                  transitionMs={1000}
                >
                  {productsAvailable.map((item) => (
                    <Resultado
                      bcolor="#fff"
                      ta="left"
                      marri="10px"
                      marriweb="auto"
                      marri=" "
                      border="none"
                      hei="auto"
                      minh="453px"
                      minhweb="auto"
                    >
                      <div
                        className="row"
                        style={{ margin: 'auto', width: '100%' }}
                      >
                        <div className="col-6"></div>
                        <div className="col-2">
                          <img src={Wallet} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="col-2">
                          <img src={Bookmark} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="col-2">
                          <img src={Share} style={{ cursor: 'pointer' }} />
                        </div>

                        <div className="col-6" style={{ marginTop: '20px' }}>
                          <img
                            /* src={product}
                            src={item.ImageURL}
                            style={{ width: '100px', margin: 'auto' }}
                          />
                        </div>
                        <div className="col-6" style={{ marginTop: '20px' }}>
                          <TrainingText
                            size="12px"
                            sizew="12px"
                            weight="400"
                            bot="0px"
                            className="roboto"
                            align="right"
                            alignweb="right"
                            ls="-0.2px"
                          >
                            Anualidad
                          </TrainingText>
                          <TrainingText
                            size="18px"
                            sizew="18px"
                            weight="600"
                            className="roboto"
                            align="right"
                            alignweb="right"
                            ls="-0.2px"
                          >
                            {item.benefits.map((item) => (
                              <>
                                {item.Name === 'Anualidad' ? (
                                  item.Description
                                ) : null}
                              </>
                            ))}
                          </TrainingText>
                        </div>

                        <div className="col-5">
                          <TrainingText
                            size="19px"
                            sizew="19px"
                            weight="600"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            {item.Name}
                          </TrainingText>
                        </div>

                        <div className="col-12">
                          <TrainingText
                            size="14px"
                            sizew="14px"
                            weight="600"
                            className="roboto"
                            align="left"
                            alignweb="left"
                            ls="-0.2px"
                          >
                            Beneficios
                          </TrainingText>
                          <ul style={{ paddingLeft: '18px' }}>
                            {item.benefits.map((item) => (
                              <>
                                {item.IDProductDetailType === 3 ? (
                                  <li>{item.Description}</li>
                                ) : null}
                              </>
                            ))}
                          </ul>
                        </div>
                        <div className="col-12">
                          <CardsBtn
                            type="button"
                            windthmd="100%"
                            minmd="100%"
                            mleftmd="0%"
                            mrightmd="auto"
                            windthG="100%"
                            min_w="100%"
                            min_web="100%"
                            min_mini="100%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="0%"
                            mright="0%"
                            mleftweb="0%"
                            mrightweb="auto"
                            onClick={() => {
                              abrirNuevoTab(item.ClickOut);
                            }}
                          >
                            Aceptar
                          </CardsBtn>
                        </div>
                      </div>
                    </Resultado>
                  ))}
                </Carousel> */}
                <Carousel
                  id="CarruselMetasHome"
                  styled={{ background: '#FAFAFA' }}
                  transitionMs={1000}
                >
                  {productsAvailable.map((item) => (
                    <Resultado
                      Hweb ="680px"
                      hei="680px"
                      bcolor="#fff"
                      ta="left"
                      marri="10px"
                      marriweb="auto"
                      border="none"
                      minh="400px"
                      minhweb="auto"
                    >
                     <Container height="fit-content">
                        <Div_Flex
                          margin=" "
                          height="80px"
                          style={{
                            marginBottom: '10.41px',
                            marginLeft: '0px',
                            marginTop: '17px',
                          }}
                          className="d-flex flex-column"
                        >
                          <img
                            src={item.ImageURL}
                            style={{
                              width: '100px',
                              margin: 'auto',
                              marginLeft: '10px',
                            }}
                          />
                        </Div_Flex>
                        <ContentGeneric
                          margin=" 0 20px 0 20px"
                          className="d-flex flex-column"
                          height="430px"
                        >
                          <TextBold16>{item.Name}</TextBold16>
                          <CardsTxt color="#4F4F4F" size="14px">
                            {item.BusinessAbr}
                          </CardsTxt>
                          <Divider
                            style={{
                              width: '100%',
                              fontWeight: 'bold',
                              backgroundColor: '#d3d3d3',
                              marginTop: '4%',
                            }}
                          />
                          <ContentGeneric margin=" 16px 0 12px 0 ">
                            {item.benefits.map((item) => (
                              <>
                                <Text14>
                                   {item.Name === 'CAT' ? (
                                     `CAT: ${(item.Description * 100).toFixed(2)}%`
                                   ) : null}
                                </Text14>
                                <Text14>
                                   {item.Name === 'Interés anual' ? (
                                     `Tasa de Interés: ${(item.Description * 100).toFixed(2)}%`
                                   ) : null}
                                </Text14>
                              </>
                            ))}
                          </ContentGeneric>
                          <ContentGeneric className='d-flex flex-row'>
                            <img style={{ width: '30px', marginLeft: '-7px' }} src={Star_green} />
                            <img style={{ width: '30px', marginLeft: '-7px' }} src={Star_green} />
                            <img style={{ width: '30px', marginLeft: '-7px' }} src={Star_green} />
                            <img style={{ width: '30px', marginLeft: '-7px' }} src={Star_green} />
                            <img style={{ width: '30px', marginLeft: '-7px' }} src={Star_green} />
                          </ContentGeneric>
                          <Divider
                            style={{
                              width: '100%',
                              fontWeight: 'bold',
                              backgroundColor: '#d3d3d3',
                              marginTop: '4%',
                              marginBottom: '20px',
                            }}
                          />
                          <ContentGeneric className='d-flex flex-column' minHeight="200px" height="200px">
                            <Text14>
                            Beneficios
                            </Text14>
                            <Text14>
                              <ul style={{ paddingLeft: '18px' }}>
                                {item.benefits.map((item) => (
                                  <>
                                    {item.IDProductDetailType === 3 ? (
                                      <li>{item.Description}</li>
                                    ) : null}
                                  </>
                                ))}
                              </ul>
                            </Text14>
                          </ContentGeneric>
                        </ContentGeneric>
                        <ContentGeneric
                          position="relative"
                          bottom="0px"
                          alignSelf="center"
                          textAlign="center"
                          className="col-12 d-flex justify-content-center"
                        >
                          <CardsBtn
                            type="button"
                            windthmd="100%"
                            minmd="100%"
                            mleftmd="0%"
                            mrightmd="auto"
                            windthG="100%"
                            min_w="100%"
                            min_web="100%"
                            min_mini="100%"
                            mleftmini="10%"
                            mrightmini="10%"
                            mleft="0%"
                            mright="0%"
                            mleftweb="0%"
                            mrightweb="auto"
                            onClick={() => {
                              abrirNuevoTab(item.ClickOut);
                            }}
                          >
                            Seleccionar
                          </CardsBtn>
                        </ContentGeneric>
                      </Container>
                    </Resultado>
                  ))}
                </Carousel>
              </Col12>
            </TrainingSectionFlex>
          )}
        </>
      )}
      {/* {products.length > 0 ? (
        <RpProductsWithStars products={products} />
      ) : null} */}
      {productsAvailable.length > 0 && goal !== '' ? (
        <Fragment>
          <SectionShare WO="WoScore" />
        </Fragment>
      ) : null}
    </div>
  );
};

export default KnowScore;
