import React, { Fragment, useEffect, useState } from 'react';
// rutinas
import DatBasic from '../Routines/DatBasic';
import BirthDat from '../Routines/BirthDat';
import ContactInformation from '../Routines/ContactInformation';
import DomicileDebts from '../Routines/DomicileDebts';
import DetailRFC from '../Routines/DetailRFC';
import UserAuthentication from '../Routines/UserAuthentication';
import KnowScore from '../Routines/KnowScore';
import ScoreComboBox from '../../components/common/ScoreComboBox';

const RoutinesOrderWoScoreReEntry = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  trainingColor,
  backWO,
}) => {
  const [skipStep, setSkipStep] = useState(false);

  return (
    <Fragment>
      <div className='hidden'>
        <UserAuthentication
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          nextOrderRoutine={7}
          trainingColor={trainingColor}
        />
      </div>
      {orderRutine >= 7 ? (
      <ScoreComboBox
        orderRutine={orderRutine}
        setOrderRutine={setOrderRutine}
        currentTraining={currentTraining}
        setSkipStep={setSkipStep}
        skipStep={skipStep}
      />
      ) : null}
      {skipStep ? (
        <KnowScore
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          trainingColor={trainingColor}
          currentRoutine={7}
          nextOrderRoutine="none"
          backWO={backWO}
        />
      ) : null}
    </Fragment>
  );
};

export default RoutinesOrderWoScoreReEntry;
