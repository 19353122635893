import React, { useState, useEffect, Fragment } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import "../../assets/styles/Home.css";
import { useMutation, useLazyQuery } from "@apollo/client";
import BarraInfoWO from "../../components/Header/BarraInfoWO";
import check from "../../assets/img/anacoach/Vector.png";
import RoutinesOrderPL from "./RoutinesOrderPL";
import PlayYoutube from "../../components/common/PlayYoutube";
import GeneralHooks from "../../Helpers/HookHelpersGeneral";
import SendNotificationFTAndCC from "../../components/common/SendNotificationFTAndCC";

import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  INSERT_PARTNER_UTM,
} from "../../mutations";
import {
  GETURLVIDEO,
  GETPERCENTAGETRAINING,
  GET_TRAINING_STATUS,
} from "../../queries";

import {
  TrainingMain,
  TrainingSubtitles,
  // TrainingText,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainingLine,
  TrainingBenef,
} from "../../assets/styles/Training.styled";
import ImageCoachInicio from "../../components/Training/ImageCoachInicio";
import LoadingWo from "../../components/Training/loadingWo";
import { Container, Col7 } from "../../components/common/common.styled";

import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from "../../Helpers";

import WaitList from "../../components/Training/WaitList";
import Starttraining from "../../components/Training/Starttraining";
import WOlayout from "../../components/layouts/WOlayout";
import "../../assets/styles/kanda/KandaDesktop.css";
import BarraScroll from "../BarraScroll";

const items2 = ["Solicita un préstamo personal", "", ""];
const imgWo =
  "https://resources-new-coru.s3.us-east-2.amazonaws.com/images/routines/C%3A%5Cfakepath%5CMoneyBag.svg";
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  // eslint-disable-next-line func-names
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    // eslint-disable-next-line func-names
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function PersonalLoans() {
  const { actions } = GeneralHooks();
  const { sendMessage } = SendNotificationFTAndCC();
  sessionStorage.setItem("idTraining", 9);
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(9);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  const [urlVideo, setUrlVideo] = useState("");
  const [idVideo, setIdVideo] = useState("");
  const [percentage, setPercentage] = useState("");
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [routinesSaves] = useState([
    {
      1: false,
    },
    {
      2: false,
    },
    {
      3: false,
    },
    {
      4: false,
    },
    {
      5: false,
    },
    {
      6: false,
    },
    {
      7: false,
    },
    {
      8: false,
    },
    {
      9: false,
    },
    {
      10: false,
    },
    {
      11: false,
    },
    {
      12: false,
    },
  ]);

  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    // eslint-disable-next-line no-shadow
    onCompleted({ getTrainingStatus }) {
      // eslint-disable-next-line no-console
      console.log(
        "getTrainingStatus ----------------------- ",
        getTrainingStatus
      );
      setWOStatus(getTrainingStatus === "true");
      setExecuteStatus(true);
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem("idTraining");
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
    sendMessage({
      cellPhone: sessionStorage.getItem("CellPhone") || "",
      trigger: "notificacion_pl_abandonopagina",
    });
  }, []);

  // End ValidWaitList

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      // setactive3(true);
    }, 13000);
  }, [active2]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);
  const [stateDate] = useState({
    year: "1989",
    month: "",
    day: "",
  });

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }

  const [getVideoTraining] = useLazyQuery(GETURLVIDEO, {
    // eslint-disable-next-line no-shadow
    onCompleted({ getVideoTraining }) {
      const video = JSON.parse(getVideoTraining.response);
      setUrlVideo(video.UrlVideo);
      setIdVideo(video.IdVideoURL);
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err);
    },
  });
  useEffect(() => {
    getVideoTraining({ variables: { id: "9" } });
  }, []);

  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    // eslint-disable-next-line no-shadow
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err);
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem("idUser");
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 9 },
      });
    } else {
      console.log("Sin porcentaje de avance");
      setPercentage("0");
    }
  }, []);

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        // eslint-disable-next-line no-console
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem("ID_GA"),
      idUser: sessionStorage.getItem("idUser"),
      idCont: sessionStorage.getItem("idCont"),
    };
    const filterJson = {
      _id: sessionStorage.getItem("id_session"),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: "sessions",
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    // eslint-disable-next-line no-shadow
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === "success") {
        console.log("update exitoso");
      } else {
        // eslint-disable-next-line no-console
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        // eslint-disable-next-line no-console
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("id_binacle", dynamicMongoSession.response);
      }
    },
  });
  const [insertPartner] = useMutation(INSERT_PARTNER_UTM, {
    onCompleted(response) {
      console.log("responseParter", response);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("id_session", dynamicMongoSession.response);
        localStorage.setItem("id_session", dynamicMongoSession.response);
      }
    },
  });

  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  // eslint-disable-next-line consistent-return
  const getFielOption = (items, value) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  // eslint-disable-next-line no-shadow
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem("activityDate", new Date());
    const inputRutine = {
      idUser: "",
      idCont: "",
      email: "",
      idGa: "",
      cellphone: "",
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log("questions", questions);
    sessionStorage.setItem("currentOrderRoutine", orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: "",
          datUserDetailStorage: "",
          questionType: "",
          value: "",
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage]
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === "Cat") {
          if (item.value != null && item.value !== "") {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession
          );
        }
        inputRutine.email = sessionStorage.getItem("Email1");
        inputRutine.cellphone = sessionStorage.getItem("CellPhone");
        inputRutine.idCont =
          parseInt(sessionStorage.getItem("idCont")) || parseInt("0");
        inputRutine.idUser =
          parseInt(sessionStorage.getItem("idUser")) || parseInt("0");
        inputRutine.idTraining =
          parseInt(sessionStorage.getItem("idTraining")) || parseInt("0");
        inputRutine.idGa = String(sessionStorage.getItem("ID_GA"));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        // debugger;
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem("idDatSession")) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem("idDatSession")
          );
        }
        // eslint-disable-next-line no-use-before-define
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log("else", inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: "getAchievment", variables: "" });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    // eslint-disable-next-line no-shadow
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log("insertRoutine");
        console.log(jsonResponse);

        if (insertRoutine.message === "usuario existente") {
          if (
            sessionStorage.getItem("idUser") == null &&
            sessionStorage.getItem("idDatSession") == null
          ) {
            sessionStorage.setItem("idUser", jsonResponse.idUser);
            sessionStorage.setItem("idCont", jsonResponse.idCont);
            addDatSession(jsonResponse.idSession);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                "datOpportunity",
                parseInt(jsonResponse.idDatOpportunity)
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                "datOpportunity",
                parseInt(jsonResponse.idDatOpportunity)
              );
            }
          }
          if (jsonResponse.idSession !== "" || jsonResponse.idSession != null) {
            sessionStorage.setItem("idDatSession", jsonResponse.idSession);
            sessionStorage.setItem("idCatRelAction", jsonResponse.idSession);
            //sessionStorage.setItem('idDatSession', jsonResponse.idSession); ari
            addDatSession(jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: "DatSession",
                columns: [
                  {
                    setkey: "Source",
                    setval: `${sessionStorage.getItem("source")}`,
                  },
                  {
                    setkey: "Medium",
                    setval: `${sessionStorage.getItem("medium")}`,
                  },
                  {
                    setkey: "Campaign",
                    setval: `${sessionStorage.getItem("campaign")}`,
                  },
                  {
                    setkey: "Content",
                    setval: `${sessionStorage.getItem("content")}`,
                  },
                  {
                    setkey: "PageName",
                    setval: `${sessionStorage.getItem("pagename")}`,
                  },
                  {
                    setkey: "Hostname",
                    setval: `${sessionStorage.getItem("hostname")}`,
                  },
                ],
                conditions: {
                  valone: "IDdatSession",
                  valtwo: `${jsonResponse.idSession}`,
                  condition: "=",
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem("viralityID")))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: "DatVirality_IdVirality",
                setval: `${sessionStorage.getItem("viralityID")}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem("idUser", jsonResponse.idUser);
          sessionStorage.setItem("idCont", jsonResponse.idCont);
          sessionStorage.setItem("idDatSession", jsonResponse.idSession);
          localStorage.setItem("idUser", jsonResponse.idUser);
          localStorage.setItem("idCont", jsonResponse.idCont);
          localStorage.setItem("idDatSession", jsonResponse.idSession);
          addDatSession(jsonResponse.idSession);
          sessionStorage.setItem("idCatRelAction", jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              "datOpportunity",
              parseInt(jsonResponse.idDatOpportunity)
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: "DatSession",
              columns: [
                {
                  setkey: "Source",
                  setval: `${sessionStorage.getItem("source")}`,
                },
                {
                  setkey: "Medium",
                  setval: `${sessionStorage.getItem("medium")}`,
                },
                {
                  setkey: "Campaign",
                  setval: `${sessionStorage.getItem("campaign")}`,
                },
                {
                  setkey: "Content",
                  setval: `${sessionStorage.getItem("content")}`,
                },
                {
                  setkey: "PageName",
                  setval: `${sessionStorage.getItem("pagename")}`,
                },
                {
                  setkey: "Hostname",
                  setval: `${sessionStorage.getItem("hostname")}`,
                },
              ],
              conditions: {
                valone: "IDdatSession",
                valtwo: `${jsonResponse.idSession}`,
                condition: "=",
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem("viralityID")))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: "DatVirality_IdVirality",
              setval: `${sessionStorage.getItem("viralityID")}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }

        let partner = getQueryVariable("utm_source");
        const utmId = getQueryVariable("utm_id");
        console.log(
          "partnerITM",
          partner,
          utmId,
          sessionStorage.getItem("idDatSession")
        );
        insertPartner({
          variables: {
            input: {
              idDatSession: parseInt(sessionStorage.getItem("idDatSession")),
              partner: String(partner),
              value: String(utmId),
            },
          },
        });

        const currentOrderRoutine = sessionStorage.getItem(
          "currentOrderRoutine"
        );
        // eslint-disable-next-line no-use-before-define
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  useEffect(() => {
    if (orderRutine > 0 && orderRutine < 12) {
      console.log("#### rutina > ", orderRutine);
    }
  }, [orderRutine]);
  useEffect(() => {
    const { idUser, idCont, fromXCoach, idAdviser } =
      getQueryVariableHashtagDeeplink("funnel-prestamos");

    if (fromXCoach === "true") {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem("idUser", idUser);
      sessionStorage.setItem("idCont", idCont);
      sessionStorage.setItem("fromXCoach", fromXCoach);
      sessionStorage.setItem("idAdviser", idAdviser);
    }

    sessionStorage.setItem("pagename", "funnel-prestamos");
    sessionStorage.setItem("hostname", window.location.hostname);
    let source = getQueryVariable("utm_source");
    let medium = getQueryVariable("utm_medium");
    let campaign = getQueryVariable("utm_campaign");
    let content = getQueryVariable("utm_content");
    const viralityID = getQueryVariable("viralityID");
    sessionStorage.setItem("viralityID", viralityID);
    // cookie
    let galletita = document.cookie;
    let variableEstado = "";
    if (galletita.includes("ReturningSession")) {
      variableEstado = "ReturningSession";
    } else if (galletita.includes("FirstSession")) {
      variableEstado = "FirstSession";
    } else {
      variableEstado = "noencontrada";
    }
    sessionStorage.setItem("visitorType", variableEstado);
    let elementos = galletita.split(";");
    let posicion = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      ""
    );
    elementos = galletita.split("&");
    for (const elem in elementos) {
      if (elementos[elem].includes("source=") && source === "") {
        source = elementos[elem].replace("source=", "");
      } else if (elementos[elem].includes("medium=") && medium === "") {
        medium = elementos[elem].replace("medium=", "");
      } else if (elementos[elem].includes("campaign=") && campaign === "") {
        campaign = elementos[elem].replace("campaign=", "");
      } else if (elementos[elem].includes("content=") && content === "") {
        content = elementos[elem].replace("content=", "");
      }
    }
    source = source != null && source !== "" ? source : "direct";
    medium = medium != null && medium !== "" ? medium : "none";
    campaign = campaign != null && campaign !== "" ? campaign : "direct";

    // cookie
    if (source !== "" || source !== null || source !== undefined) {
      sessionStorage.setItem("source", source);
    }

    if (medium !== "" || medium !== null || medium !== undefined) {
      sessionStorage.setItem("medium", medium);
    }

    if (campaign !== "" || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem("campaign", campaign);
    }

    if (content !== "" || content !== null || content !== undefined) {
      sessionStorage.setItem("content", content);
    }
    const idGa = String(sessionStorage.getItem("ID_GA"));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: "sessions",
        data: dataString,
      },
    };
    if (!sessionStorage.getItem("id_session")) {
      newSession({ variables: mongoInput });
    }
  }, []);

  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    // eslint-disable-next-line no-shadow
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split("_");
      if (porcentaje.length > 1) {
        console.log(
          "ejecutar porcentaje============",
          `${porcentaje[1].toString()}%`
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        sessionStorage.setItem(
          "Achievment-9",
          parseFloat(porcentaje[1]).toFixed(2).toString()
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  // eslint-disable-next-line no-shadow
  const saveAdvance = async (orderRutine) => {
    const idUser = sessionStorage.getItem("idUser");
    const idCont = sessionStorage.getItem("idCont");
    const idTraining = 9;
    if (
      orderRutine <= 11 &&
      routinesSaves[orderRutine - 1][orderRutine] === false
    ) {
      const inputProgress = {
        input: {
          idRoutine: "",
          orderRoutine: String(orderRutine),
          idTraining: String(currentTraining),
          idUser: String(idUser),
          idCont: String(idCont),
          isMaster: "1",
        },
      };
      console.log("input progress");
      console.log(JSON.stringify(inputProgress));
      if (idUser != null) {
        routinesSaves[orderRutine - 1][orderRutine] = true;
        console.log(
          " routinesSaves[idRoutine-1] final",
          routinesSaves[orderRutine - 1]
        );
        saveStatusTraining({ variables: inputProgress });
      }
    }
    if (orderRutine === 11) {
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index <= 11; index++) {
        console.log("se activa savesStatusTraining para completar 100%");
        // Pregunta?? aqui va el numeor de rutinas
        const inputProgress = {
          input: {
            idRoutine: "",
            orderRoutine: String(index),
            idTraining: String(idTraining),
            idUser: String(idUser),
            idCont: String(idCont),
            isMaster: "1",
          },
        };
        if (idUser != null) {
          // eslint-disable-next-line no-await-in-loop
          await saveStatusTraining({ variables: inputProgress });
        }
      }
    }
  };

  const hora = `${date.getHours()}:${minutos}`;
  const [saludo, setSaludo] = useState("Si");
  const [beneficios] = useState("No");
  const anchop = window.screen.width;

  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    // eslint-disable-next-line func-names
    window.onscroll = function () {
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log("Moviste el scroll");
        const grayline = document.getElementById("line-percent-gray");
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = "40px";
        }
        const pinkline = document.getElementById("line-percent-pink");
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = "40px";
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById("line-percent-gray");
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = "404px";
          }
          const pinkline = document.getElementById("line-percent-pink");
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = "404px";
          }
        } else {
          const grayline = document.getElementById("line-percent-gray");
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = "303px";
          }
          const pinkline = document.getElementById("line-percent-pink");
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = "303px";
          }
        }
      }
    };
  }, []);
  const propied = {
    beta: "false",
    title: { items2 },
    subtitle: "true",
    time: "10 minutos",
    imgWo: { imgWo },
    txtsubtitle: "En pocos minutos y 100% en línea",
  };

  const URLactual = window.location.hostname;
  let isProd = false;
  if (URLactual === 'coru.com') {
    isProd = true;
  } else {
    isProd = false;
  }

  return (
    <WOlayout
      backgroundColor={"#BE7AE6"}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>{isProd ? 'Solicita tu préstamo personal fácil y seguro | CORU' : 'Solicita tu préstamo personal fácil y seguro'}</title>
        <link rel="canonical" href="https://coru.com/wo/loans-funnel" />
        <meta name="keywords" content="simulador prestamo personal" />
        <meta
          name="description"
          content="Compara todos las préstamos de personales en México. Nuestro sistema de ayuda a encontrar el mejor. Coru es tu opción 100% confiable."
        />
      </Helmet>
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor="#BE7AE6"
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <BarraInfoWO propied={propied} />
          {scroll1 > 0 ? <BarraScroll /> : null}
          <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
          {percentage !== "" ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt=""
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{ position: "relative" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {WOStatus ? (
              <Fragment>
                <TrainingSectionGray
                  wiw="100%"
                  mw="auto"
                  back="#FAFAFA"
                  mtop="0"
                >
                  <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                    <div className="marginComentLgSuccess1">
                      <Starttraining
                        title="¡Hola! Soy Ana, tu coach financiero."
                        text="Te ayudaré a obtener el mejor préstamo personal para ti."
                      />
                      {active1 ? (
                        <Starttraining
                          classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                          title="¿Qué es un crédito personal?"
                          text="Es un préstamo de dinero que ponen a tu disposición las
                        instituciones de crédito, te otorgan dinero a cambio del
                        pago de la cantidad prestada más intereses."
                        />
                      ) : null}
                    </div>
                  </TrainingDivSection2>
                </TrainingSectionGray>
                <div className="shapes">
                  {beneficios === "Si" ? (
                    <TrainingDivSection
                      mlw="10%"
                      padding="0 20px"
                      paddw="0"
                      back="transparent"
                      backw="transparent"
                    >
                      <Images
                        src={check}
                        top="94px"
                        topweb="70px"
                        left="29px"
                        leftw="2%"
                        width="18px"
                        position="absolute"
                        className="img-fluid"
                      ></Images>
                      <Images
                        src={check}
                        top="160px"
                        topweb="138px"
                        left="29px"
                        leftw="2%"
                        width="18px"
                        position="absolute"
                        className="img-fluid"
                      ></Images>
                      <Images
                        src={check}
                        top="218px"
                        topweb="196px"
                        left="29px"
                        leftw="2%"
                        width="18px"
                        position="absolute"
                        className="img-fluid"
                      ></Images>
                      <TrainingSubtitles
                        size="20px"
                        fw="600"
                        align="left"
                        alignweb="left"
                        className="cabin"
                      >
                        Solicita solo el monto que necesites
                      </TrainingSubtitles>
                      <TrainingBenef
                        bbs="solid"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="68px"
                        pl="73px"
                        mt="26px"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        Revisa que tus gastos te permitan pagarlo
                      </TrainingBenef>
                      <TrainingBenef
                        bbs="solid"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="58px"
                        pl="73px"
                        mt="0"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        Adelanta pagos si es posible
                      </TrainingBenef>
                      <TrainingBenef
                        bbs="solid"
                        bbsw="none"
                        bbw="1px"
                        bbc="#CDCDCD"
                        pt="17px"
                        pb="17px"
                        he="68px"
                        pl="73px"
                        mt="0"
                        className="roboto"
                        alignweb="left"
                        ls="-0.2px"
                      >
                        Compara y entiende bien las condiciones antes de
                        solicitarlo
                      </TrainingBenef>
                    </TrainingDivSection>
                  ) : null}
                  <TrainingSectionGray
                    className="hidden"
                    wiw="670px"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      {active2 ? (
                        <Fragment>
                          <Starttraining
                            title=" Conoce más de este proceso en nuestro video"
                            text="Antes de iniciar tu entrenamiento puedes ver un video donde te damos más información del proceso para solicitar tu tarjeta de crédito."
                          />

                          {saludo === "Si" ? (
                            <TrainingDivSection
                              bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                              mt="44px"
                              mtw="85px"
                              br="4px"
                              padding="21px 20px"
                              wi="335px"
                              wiw="670px"
                              he="auto"
                              hew="auto"
                              mb="0px"
                            >
                              <div className="triangulo-equilatero-bottom"></div>
                              <div
                                className="closeDiv"
                                onClick={() => setSaludo("No")}
                              >
                                ×
                              </div>
                              {urlVideo !== "" ? (
                                <PlayYoutube
                                  idVideo={idVideo}
                                  width={"300"}
                                  height={"190"}
                                  close={false}
                                />
                              ) : null}
                            </TrainingDivSection>
                          ) : (
                            <PlayYoutube
                              idVideo={idVideo}
                              width={"300"}
                              height={"190"}
                              close={true}
                            />
                          )}
                        </Fragment>
                      ) : null}
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                </div>
                <div className="marginComentLgSuccess1">
                  {active2 ? (
                    <Fragment>
                      <Starttraining
                        classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                        title="Iniciemos esta aventura..."
                        text="Primero necesito conocerte, ayúdame con tus datos
                  personales."
                      />
                      <RoutinesOrderPL
                        Date={stateDate}
                        hora={hora}
                        orderRutine={orderRutine}
                        setOrderRutine={setOrderRutine}
                        currentTraining={currentTraining}
                        createJsonInput={createJsonInput}
                        questions={questions}
                        setQuestions={setQuestions}
                        crediteaVal={true}
                      />
                    </Fragment>
                  ) : null}
                </div>
                {!active2 ? (
                  <Fragment>
                    <br></br>
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <Container
                        width="80%"
                        mt="0rem"
                        widthMobile="90%"
                        mtMobile="0rem"
                      >
                        <ImageCoachInicio topMobile="35px" />
                        <Col7 padding=" " className="cardPrincipal1">
                          <LoadingWo />
                        </Col7>
                      </Container>
                    </TrainingSectionGray>
                  </Fragment>
                ) : null}
                <br />
              </Fragment>
            ) : (
              <Fragment>
                <br />
                <br />
                <br />
                <br />
                {executeQuery ? <WaitList /> : null}
              </Fragment>
            )}
          </div>
          <div
            style={{ position: "absolute", top: "1em", right: "3em" }}
            id="kandaDesktop"
          ></div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
}

export default PersonalLoans;
