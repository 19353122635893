import React, { Fragment } from 'react';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import Facelogo from '../../assets/img/anacoach/F-Facebook.svg';
import Messlogo from '../../assets/img/anacoach/F-Messenger.svg';
import Whatslogo from '../../assets/img/anacoach/F-Whatsapp.svg';
import {
  Image,
  Footer_Verticals,
  FooterViralidad,
} from './common.styled';
import { shareCoru } from '../../Helpers/index';
import { viralityUTM } from '../../Helpers/HelperUTM';
import { INSERT_DAT_VIRALITY, UPDATE_HISTORY_COLLECTION, CREATE_SESSION } from '../../mutations';
import { getDateStandart } from '../../Helpers/general';

const ShareSection = () => {
  const getRedirectUrl = (socialNetwork) => {
    console.log('Entra a social network');
    let redirectUrl;
    switch (socialNetwork) {
      case 'Facebook':
        redirectUrl = '?utm_source=share&utm_medium=feed&utm_campaign=Facebook';
        break;
      case 'MessengerMob':
        redirectUrl = '?utm_source=share&utm_medium=feed&utm_campaign=MessengerMob';
        break;
      case 'Messenger':
        redirectUrl = '?utm_source=share&utm_medium=feed&utm_campaign=Messenger';
        break;
      case 'Whatsapp':
        redirectUrl = '?utm_source=share&utm_medium=feed&utm_campaign=Whatsapp';
        break;
      default:
        redirectUrl = '/';
        break;
    }
    return redirectUrl;
  };

  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === 'success') {
        console.log('REsponse viralidad', insertDatVirality.response);
        const { response } = insertDatVirality;
        const redirectLink = response.split(',')[0];
        const socialNetwork = response.split(',')[1];
        const announcement = sessionStorage.getItem('auxAnnouncement');
        shareCoru(socialNetwork, announcement, redirectLink);
      }
    },
  });
  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickShareNetworkCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const ClickShareMongo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickShareSN'.concat(action),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickSocialSN')) {
      const dataJson = [
        {
          idSession: sessionStorage.getItem('id_session'),
          nameTraining: sessionStorage.getItem('trainingName'),
          medium: action,
          date: getDateStandart(),
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({ _id: sessionStorage.getItem('idClickSocialSN') });
      const mongoInput = {
        input: {
          collection: 'LogShared',
          key: 'Shared',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idUser: sessionStorage.getItem('idUser'),
        Shared: [
          {
            idSession: sessionStorage.getItem('id_session'),
            nameTraining: sessionStorage.getItem('trainingName'),
            medium: action,
            date: getDateStandart(),
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'LogShared',
          data: dataString,
        },
      };
      createClickShareNetworkCollection({ variables: mongoInput });
    }
  };

  const viralityUrl = '/';

  const path = window.location.pathname;

  const shareTraining = (socialNetwork) => {
    if (path === '/') {
      shareHome(socialNetwork);
    } else {
      console.log('Entra a share');
      ClickShareMongo(socialNetwork);
      let announcement;
      let redirectLink = '/';
      redirectLink = getRedirectUrl(socialNetwork);
      announcement = socialNetwork === 'Whatsapp' ? 'ShareFeed' : 'ShareFeed';
      sessionStorage.setItem('auxAnnouncement', announcement);
      viralityUTM(insertDatVirality, viralityUrl, sessionStorage.getItem('trainingName'), socialNetwork);
    }
  };

  function shareHome(socialNetwork) {
    let coruRedirectURL;
    switch (socialNetwork) {
      case 'Messenger': {
        // It seems that custom message is no longer suported by the facebook API
        // if you find a way to implement it, please do it.
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        const url = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruRedirectURL}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`;
        window.open(url);
        break; }
      case 'MessengerMob':
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        window.open(
          `fb-messenger://share/?link=${coruRedirectURL}&app_id=248019326476226`,
        );
        break;
      case 'Facebook':
        coruRedirectURL = 'https://coru.com/shorter?id=P6Ca2ipVL5Vq87H';
        window.open(`https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(coruRedirectURL)}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}&display=popup`);
        break;
      case 'Whatsapp':
      { coruRedirectURL = 'https://coru.com/shorter?id=yVn6F9Odtw0LnBH';
        const whatsappMessage = `¡Obtén el poder de tu dinero! Coru es la forma más inteligente de tomar tus decisiones financieras. Nuestro coach financiero te llevará de la mano para entender tus necesidades. Regístrate y alcanza tus metas financieras. ${coruRedirectURL}`;
        window.open(`https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`, 'popup', 'width=600', 'height=600');
        break; }
      default:
        break;
    }
  }

  return (
        <Fragment>
            <Footer_Verticals widthM="100%" width="91%" left="auto" className="roboto">Comparte en:</Footer_Verticals>
            <FooterViralidad width="91%" left="auto" >
                <div onClick={() => shareTraining('Messenger')}><Image src={Messlogo} marginRight="17px" className="img-fluid cursor-p" width="34.06px" marginTop="2px" bottom ="30px" displayWeb="block" bottomMObile="block" displayMobile="none" /></div>
                <div onClick={() => shareTraining('MessengerMob')}><Image src={Messlogo} marginRight="17px" className="img-fluid cursor-p" width="34.06px" marginTop="2px" bottom ="30px" displayWeb="none" bottomMObile="block" displayMobile="block" /></div>
                <div onClick={() => shareTraining('Facebook')}><Image src={Facelogo} marginRight="17px" className="img-fluid cursor-p" width="34.06px" marginTop="2px" bottom ="30px" displayWeb="block" bottomMObile="block" displayMobile="block" /></div>
                <div onClick={() => shareTraining('Whatsapp')}><Image src={Whatslogo} marginRight="17px" className="img-fluid cursor-p" width="34.06px" marginTop="2px" bottom ="30px" displayWeb="block" bottomMObile="block" displayMobile="block" /></div>
            </FooterViralidad>
        </Fragment>
  );
};

export default ShareSection;
