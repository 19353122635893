import React, { useState, Fragment, useEffect } from 'react';
import { useTrail, animated } from 'react-spring';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  CREATE_SESSION_MYSQL,
} from '../../mutations';
import {
  GET_TRAINING_COLOR,
  GETPERCENTAGETRAINING,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainigTam,
  TrainigTamLeg,
  TrainingLine,
  CardsBtn,
} from '../../assets/styles/Training.styled';
import Header from '../../components/common/Header';
import WaitList from '../../components/Training/WaitList';

import clock from '../../assets/img/anacoach/clockTraining.png';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import RoutinesOrderNewYearPurpose from './RoutinesOrderNewYearPurpose';
import { Container, Col7 } from '../../components/common/common.styled';
import patron from '../../assets/img/anacoach/PatronNavideno.png';
import WOlayout from '../../components/layouts/WOlayout';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';
import { Helmet } from 'react-helmet';

const items2 = ['Mis propósitos de año nuevo'];
const config = { mass: 5, tension: 1000, friction: 200 };

const NewYearsPurpose = () => {
  const history = useHistory();
  const { actions } = GeneralHooks();
  // copia y pega estos states para crear un nuevo entrenamiento
  sessionStorage.setItem('idTraining', 32);
  const [toggle2] = useState(true);
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(32);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [hasSuggestions] = useState(true);
  const [goWoGoals, setGoWoGoals] = useState(false);
  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');
  const [showOrderRutine, setShowOrderRutine] = useState(false);
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  const trail2 = useTrail(items2.length, {
    config,
    opacity: toggle2 ? 1 : 0,
    a: toggle2 ? 0 : 20,
    height: toggle2 ? 30 : 0,
    from: { opacity: 0, a: 20, height: 0 },
  });

  // MUTATIONS AND QUERIES
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (goWoGoals) {
      setTimeout(() => {
        // no tengo metas
        history.push('/wo/new-years-resolutions-success');
        sessionStorage.setItem('BtnNotengoMetas', 'true');
      }, 6000);
    }
  }, [goWoGoals]);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            addDatSession(jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          addDatSession(jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });

  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    // const timestamp = today;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };
  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);

    let numQuestion = 0;
    try {
      questions.forEach((item, index) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          const nameSession = arraySessionsRutines[questionRoutine.DatUserDetailStorage];
          sessionStorage.setItem(nameSession, item.value);

          switch (nameSession) {
            case 'Email1':
              localStorage.setItem('Email1', item.value);
              break;
            case 'CellPhone':
              localStorage.setItem('CellPhone', item.value);
              break;
            case 'idCont':
              localStorage.setItem('idCont', item.value);
              break;
            case 'idUser':
              localStorage.setItem('idUser', item.value);
              break;
            default:
              break;
          }

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  // END FUNCIONES

  // useeffect genericos para los entrenamientos
  // Valid WaitList
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: String(currentTraining) } });
  }, []);

  // obtener las utms
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'wo-goals');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: currentTraining },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);
  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('Wo-Purpose');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
      content,
    );
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    // createTempUser(idUser, idCont);
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
        viralityID,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    getTrainingColor({ variables: { idTraining: String(currentTraining) } });
  }, []);
  const feacturesArray = useState([
    {
      text: 'Cómo priorizar tus propósitos de año nuevo',
      color: '#F97070',
    },
    {
      text: 'Dar seguimiento puntual  a tus propósitos',
      color: '#F97070',
    },
    {
      text: 'Método S.M.A.R.T',
      color: '#F97070',
    },
  ]);
  const scrollToElem = () => {
    const anchop = window.screen.width;
    const scrol1 = document.documentElement.scrollHeight;
    if (anchop > 768) {
      console.log('eres web');
      if (orderRutine === orderRutine) {
        window.scrollTo({ top: scrol1 - 1100, behavior: 'smooth' });
      } else {
        const scrol1 = document.documentElement.scrollHeight;
        window.scrollTo({ top: scrol1 - 1000, behavior: 'smooth' });
      }
    } else {
      console.log('eres movil');
      if (orderRutine === orderRutine) {
        window.scrollTo({ top: scrol1, behavior: 'smooth' });
      } else {
        console.log('estás en ELSE de scroller');
        window.scrollTo({ top: scrol1, behavior: 'smooth' });
      }
    }
  };
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '45px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '45px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);
  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
      backurl={patron}
    >
      <Helmet>
        <title>Propósitos de año nuevo | CORU</title>
      </Helmet>
      {/* {!hasSuggestions && WOStatus ? <ModalBudget /> : null} */}
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            <TrainingAnimateFunnel
              bcolor={colorTraining}
              bimage={`url(${patron})`}
              className="BackWO"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <TrainigTam>
                <Header
                  background={colorTraining}
                  pathname={window.location.pathname}
                  backurl={patron}
                />
              </TrainigTam>
              {scroll1 > 0 ? <BarraScroll /> : null}
              <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
              {/* <BetaIcon page={'WO'}></BetaIcon> */}
              <TrainigTam plw="0" hm="209px" mt="49px" ml="20px">
                <div className="d-flex flex-row container-bar">
                  <div className="col-9 col-md-8 col-xl-6">
                    <TrainigTamLeg
                      ptmd="143px"
                      pt768="120px"
                      ptxl="10%"
                      ptxxl="80px"
                      ptsmart="10px"
                      hm="auto"
                      pt="150px"
                      pl="15px"
                      plw="0"
                    >
                      {trail2.map(({ a, height, ...rest }, index) => (
                        <animated.div
                          key={items2[index]}
                          className="trails-text text-funnelcc cabin"
                          style={{
                            ...rest,
                            transform: a.interpolate(
                              (a) => `translate3d(0,${a}px,0)`,
                            ),
                          }}
                        >
                          <animated.div style={{ height }}>
                            {items2[index]}
                          </animated.div>
                        </animated.div>
                      ))}
                    </TrainigTamLeg>
                    <TrainigTamLeg
                      ptmd="190px"
                      pt768="260px"
                      plm768="12%"
                      ptxl="22%"
                      ptxxl="220px"
                      ptsmart="217px"
                      hm="auto"
                      pb="0px"
                      pl="15px"
                      pt="190px"
                      plw="9.5%"
                      ptw="250px"
                    >
                      <TrainingText
                        className="roboto marginTime container-bar"
                        color="#07210A"
                        align="left"
                        alignweb="left !important"
                        size="16px"
                        sizew="1.6vw"
                        lh="120%"
                        weight="normal"
                      >
                        ¡Cumple tus propósitos de Año Nuevo!
                      </TrainingText>
                    </TrainigTamLeg>

                    <TrainigTamLeg
                      plmd="40px"
                      ptmd="230px"
                      pt768="348px"
                      ptxl="30%"
                      ptxxl="280px"
                      ptsmart="278px"
                      hm="auto"
                      pb="55px"
                      pl="45px"
                      plxs="40px"
                      pt="236px"
                      plw="12.5%"
                      ptw="310px"
                      plm768="80px"
                    >
                      <Images
                        src={clock}
                        top="0"
                        topweb=""
                        left="-25px"
                        leftw="-46.5%"
                        width="18px"
                        widthw="1.6vw"
                        position="absolute"
                        className="img-fluid"
                        mleft768="-46%"
                        top768="-0.5%"
                      ></Images>
                      <TrainingText
                        className="roboto marginTime container-bar"
                        color="#07210A"
                        align="left"
                        alignweb="left !important"
                        size="16px"
                        sizew="1.6vw"
                        lh="120%"
                        weight="normal"
                        sizexs="4.7vw"
                        leftxl="2%"
                      >
                        Tiempo de entrenamiento: 5 minutos.
                      </TrainingText>
                    </TrainigTamLeg>
                  </div>
                </div>
              </TrainigTam>
            </TrainingAnimateFunnel>
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {orderRutine !== 'rp' ? (
                  <Fragment>
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <Starttraining
                          title="¡Hola! Soy Ana, tu coach financiero."
                          text="En este entrenamiento aprenderás:"
                        />
                        <Fragment>
                          <Features title="" Arraytext={feacturesArray} />
                        </Fragment>
                        <Starttraining
                          text="Inicia el año 2022. El 2021 ha sido un año difícil para todos y estamos ansiosos por que acabe. Tienes 12 uvas para pedir deseos para 2022, pero... 
                                  ¿Qué tal si mejor convertimos esos deseos en metas?"
                        />
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <div className="col-md-7 offset-md-3">
                        <Container
                          width="90%"
                          mwg="auto"
                          mwgr="auto"
                          mt="0.2rem"
                          widthMobile="90%"
                          mtMobile="0rem"
                        >
                          <CardsBtn
                            className="topOne1"
                            min_w="80%"
                            min_web="80%"
                            mtop=" "
                            mleft="auto"
                            mright="auto"
                            mtopw=" "
                            onClick={() => setShowOrderRutine(true)}
                          >
                            ¡Quiero lograr mis metas!
                          </CardsBtn>
                          {/* <CardsBtn
                            className="topOne1"
                            back="#FFFFFF"
                            color="#F64282"
                            mleft="auto"
                            mright="auto"
                            mtop=" "
                            mtopw=" "
                            border="2px solid #F64282"
                            min_w="80%"
                            min_web="80%"
                            onClick={() => setGoWoGoals(true)}
                          >
                            No tengo metas para el 2021
                          </CardsBtn> */}
                        </Container>
                      </div>
                    </TrainingSectionGray>
                  </Fragment>
                ) : null}
                {goWoGoals ? (
                  <Fragment>
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2
                        widthn="80%"
                        mwg="auto"
                        mwgr="auto"
                        paddiw="0"
                        paddi="0"
                        back="#FAFAFA"
                      >
                        <Starttraining text="¡Éxito en 2022! Recuerda que si en algún momento deseas fortalecer tus hábitos financieros, estaré aquí para apoyarte." />
                        <Container
                          width="80%"
                          mt="1rem"
                          widthMobile="90%"
                          mtMobile="1rem"
                        >
                          <ImageCoachInicio />
                          <Col7 padding=" " className="cardPrincipal1">
                            <LoadingWo />
                          </Col7>
                        </Container>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                  </Fragment>
                ) : null}
                {showOrderRutine && orderRutine !== 'rp' ? (
                  <TrainingSectionGray
                    wiw="670px"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      <Starttraining text="Tal vez quieres bajar de peso, salirte de casa de tus papás o hacer el viaje de tus sueños. Y seamos honestos, todos estos se traducen en gastos." />
                      <Starttraining text="Es muy fácil hacer propósitos, pero la cosa se pone difícil a la hora de cumplirlos ¿O no?" />
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                ) : null}
                <TrainingSectionGray
                  wiw="670px"
                  mw="auto"
                  back="#FAFAFA"
                  mtop="0"
                >
                  <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                    {hasSuggestions && showOrderRutine ? (
                      <RoutinesOrderNewYearPurpose
                        Date={stateDate}
                        hora={hora}
                        orderRutine={orderRutine}
                        setOrderRutine={setOrderRutine}
                        currentTraining={currentTraining}
                        createJsonInput={createJsonInput}
                        questions={questions}
                        setQuestions={setQuestions}
                      />
                    ) : null}
                  </TrainingDivSection2>
                </TrainingSectionGray>
                {scrollToElem()}
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
};

export default NewYearsPurpose;
