import styled from "styled-components";
import { imageComponent } from "../../components/common/common";

export const TrainingMain = styled.div`
	align-items: center;
	background-color: #fafafa;
	font-family: system-ui;
	height: 100%;
	margin: auto;
	width: 100%;
	color: #110e20;
	margin-bottom: 90px;
	@media (min-width: 1024px) {
		width: ${(props) => props.width || "80%"};
	}
	@media (min-width: 1500px) {
		width: ${(props) => props.width || "70%"};
	}
`;
export const CardsBtn = styled.button`
	color: ${(props) => props.color || "#fff"};
	background: ${(props) => props.back || "#fb245c"};
	border-radius: 3px;
	border: ${(props) => props.border || "none"};
	padding: 9px 15px;
	min-width: ${(props) => props.min_w || "150px"};
	max-width: ${(props) => props.styledMaxWidth || "100%"};

	font-size: 16px;
	transition: 0.3s;
	margin-top: ${(props) => props.mtop || ""};
	margin-left: ${(props) => props.mleft || ""};
	margin-right: ${(props) => props.mright || ""};
	@media (min-width: 768px) {
		min-width: ${(props) => props.min_web || "250px"};
		margin-left: ${(props) => props.mleftweb || "auto"};
		margin-right: ${(props) => props.mrightweb || "auto"};
		margin-top: auto;
		margin-bottom: auto;
		max-width: ${(props) => props.styledMaxWidth || "100%"};
	}
`;
export const Images = styled(imageComponent)`
	max-width: 100%;
	width: ${(props) => props.width || "95px"};
	height: ${(props) => props.height || ""};
	float: ${(props) => props.float || ""};
	position: ${(props) => props.position || "absolute"};
	margin-bottom: ${(props) => props.bottom || ""};
	background: ${(props) => props.back || ""};
	margin-left: ${(props) => props.left || "-12%"};
	margin-top: ${(props) => props.top || "4%"};
	@media (min-width: 768px) {
		margin-top: ${(props) => props.topweb || ""};
		margin-left: ${(props) => props.leftw || "-4%"};
	}
`;

export const TrainingAnimateFunnel = styled.div`
	margin: ${(props) => props.margin || " 0px 0 5% 0"};
	background: ${(props) => props.bcolor || "#fff"};
	padding: ${(props) => props.pmob || "25% 10% 10% 10%"};
	min-height: ${(props) => props.minh || "405px"};
	@media (min-width: 768px) {
		margin: 0 0 0 0;
		height: ${(props) => props.hweb || "250px"};
		padding: 5% 0 0 0;
		text-align: center;
		min-height: ${(props) => props.minhweb || "250px"};
	}
`;

export const TrainingSubtitles = styled.h2`
	color: ${(props) => props.color || "#110e20"};
	font-size: ${(props) => props.size || "24px"};
	text-align: ${(props) => props.align || "center"};
	font-weight: ${(props) => props.fw || "600"};
	@media (min-width: 768px) {
		font-size: ${(props) => props.size || "1.8em"};
		text-align: ${(props) => props.alignweb || ""};
	}
`;

export const TrainingDivSection = styled.div`
	background: ${(props) => props.back || "#fff"};
	padding: ${(props) => props.padding || "10%"};
	width: ${(props) => props.styleWidth || "100%"};
	margin: auto;
	display: ${(props) => props.styleDisplay || "block"};
	@media (min-width: 768px) {
		padding: ${(props) => props.paddw || "3%"};
		width: "100%";
	}
`;

export const TrainingText = styled.p`
	color: ${(props) => props.color || "#110e20"};
	font-size: ${(props) => props.size || "1.2em"};
	font-weight: ${(props) => props.weight || "400"};
	text-align: ${(props) => props.align || "left"};
	margin-bottom: ${(props) => props.bot || ""};
	margin-top: ${(props) => props.top || ""};
	margin-left: ${(props) => props.left || ""};
	line-height: ${(props) => props.lh || "1.6"};
	text-transform: ${(props) => props.trans || ""};
	cursor: ${(props) => props.cursor || ""};
	@media (min-width: 768px) {
		font-size: ${(props) => props.size || "1.3em"};
		text-align: ${(props) => props.alignweb || ""};
		min-height: ${(props) => props.mheight || ""};
		margin-bottom: ${(props) => props.botw || ""};
		margin-left: ${(props) => props.leftw || ""};
	}
`;

export const TrainingContainAna = styled.div`
	text-align: center;
	background: ${(props) => props.back || "#ffffff"};
	@media (min-width: 768px) {
		margin-bottom: ${(props) => props.bottom || ""};
	}
`;

export const TrainingSectionGray = styled.div`
	background: ${(props) => props.back || "#f5f5f5"};
	margin-top: ${(props) => props.mtop || ""};
	@media (min-width: 768px) {
		width: ${props => props.wiw || ''};
		margin: ${props => props.mw || ''};
	}
`;
export const TrainingDivSection2 = styled.div`
	background: ${(props) => props.back || "#fff"};
	padding: ${(props) => props.paddi || "5%"};
	@media (min-width: 768px) {
		padding: 1px 10% 3% 10%;
	}
`;
/*Preguntas y respuestas*/
export const TrainingQuestion = styled.div`
	width: 90%;
	margin: auto;
	margin-top: 5%;
	height: ${(props) => props.height || ""};
	overflow: ${(props) => props.flow || ""};
`;
export const QuestionsDivs = styled.div`
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background-color: #fff;
	display: ${(props) => props.display || ""};
	height: ${(props) => props.hei || "auto"};
	margin: ${(props) => props.margin || "8px"};
	padding: ${(props) => props.padd || "5px"};
	text-align: ${(props) => props.txta || "center"};
	width: ${(props) => props.width || "45%"};
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
	cursor: pointer;
	@media (min-width: 768px) {
		width: ${(props) => props.widthweb || ""};
	}
`;
export const QuestionsFlex = styled.div`
	display: ${(props) => props.display || "flex"};
`;
export const QuestionsText = styled.div`
	margin-top: 4px;
	margin: ${(props) => props.margin || ""};
	font-size: ${(props) => props.size || "20px"};
	font-weight: ${(props) => props.weight || "600"};
	padding: ${(props) => props.padd || ""};
`;
export const QuestionsQuiz = styled.div`
	background-color: #2C3337;
	padding: 10px 5px 10px 5px;
	width: ${(props) => props.width || ""};
	margin: auto;
	@media (min-width: 768px) {
		width: ${(props) => props.widthweb || "100%"};
	}
`;

export const FlexQues = styled.div`
	display: flex;
	justify-content: space-around;
`;
export const BordeComplete = styled.div `
	width: 40%;
	height: 120px;
	border-style: solid;
	border-width: 1px;
	text-align: center;
`;
export const BordesLaterales = styled.div `
	width: 40%;
	height: 120px;
	border-left-style: solid;
	border-right-style: solid;
	border-width: 1px;
	text-align: center;
`;
export const BordesBottom = styled.div `
	width: 40%;
	height: 120px;
	border-left-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-width: 1px;
	text-align: center;
`;
export const BordesTop = styled.div `
	width: 40%;
	height: 120px;
	border-left-style: solid;
	border-right-style: solid;
	border-top-style: solid;
	border-width: 1px;
	text-align: center;
`;
export const TextFriends = styled.div `
	width: 80%;
	margin: auto;
	padding: 15px;
	text-align: left;
	-webkit-box-shadow: 3px 3px 7px 2px rgba(94,94,94,0.22);
	-moz-box-shadow: 3px 3px 7px 2px rgba(94,94,94,0.22);
	box-shadow: 3px 3px 7px 2px rgba(94,94,94,0.22);
`;