import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Container,
  SubtitleOne,
  Image, Button,
  CirculeRachas,
  SpanResumen,
  ContainerLogrados,
  CainerBloqueados,
} from '../../components/common/common.styled';
import { Num } from '../../components/common/Home.styled';
import candado from '../../assets/img/logros/Candado.png';
import '../../assets/styles/perfil/perfiles.css';
import FooterFeed from '../../components/Feed/Footer_Feed';
import ShareCommonFeed from '../Training/ShareCommonFeed';

function ShareAntivity() {
  const status = sessionStorage.getItem('statusActivity') === 'true' || false;
  const numEntrenamiento = sessionStorage.getItem('numActivity') || 1;
  const background = sessionStorage.getItem('backgroundActivity') || '#6BD5AF';
  const title = sessionStorage.getItem('titleActivity') || 'Primer entrenamiento completado';
  const trofeo = sessionStorage.getItem('trofeoActivity') === 'true' || false;
  const history = useHistory();

  const handleClickArrow = () => {
    history.push('/achievements');
  };

  const handleClickBottom = () => {
    if (trofeo) { history.push('/achievements'); } else { history.push('/the-training-feed/'); }
  };

  return (
        <Fragment>
            <Container width='50%' mt='2rem' widthMobile='99%' mtMobile='2rem'float="auto" className="container resumen" >
                <ArrowBackIosIcon onClick={handleClickArrow} color="#07210A"/> <SpanResumen className="roboto"> Resumen de actividad</SpanResumen>
                <SubtitleOne
                    textalingn="center"
                    textalingnMobile="center"
                    className="font-weight-bold roboto"
                    sizeMobile="30px"
                    size="30px" mb="1%"
                    transform=" "
                    mt="2rem">
                    {title}
                </SubtitleOne>
                {status ? (
                <Fragment>
                  <ContainerLogrados>
                      <CirculeRachas border="10px solid #FFFFFF" widthD="150px" heightD="150px" width="150px" height="150px" margin="auto" background ={background}>
                        {trofeo ? (
                            <Image src={numEntrenamiento} width="40px" margin="auto"/>
                        ) : (
                          <Num className="roboto">{numEntrenamiento}</Num>
                        )}
                      </CirculeRachas>
                  </ContainerLogrados>
                  <SpanResumen className="roboto">Comparte con tus amigos</SpanResumen>
                  <ShareCommonFeed url="feed" trainingName="Logros" textShare="" sumary={true}></ShareCommonFeed>
                </Fragment>)
                  : (
                <Fragment>
                  <CainerBloqueados>
                    <CirculeRachas border="0px" widthD="150px" heightD="150px" width="150px" height="150px" margin="auto" background="#CDCDCD">
                        <Image src={candado} width="25px" margin="auto"/>
                      </CirculeRachas>
                  </CainerBloqueados>
                  <SpanResumen className="roboto">Completa esta tarea y comparte</SpanResumen>
                  <Button className='d-block roboto' onClick={handleClickBottom}>
                    {trofeo ? ('Ir a entrenamientos') : ('Ver rachas completas')}
                  </Button>
                </Fragment>)}
            </Container>
            <FooterFeed/>
        </Fragment>
  );
}
export default ShareAntivity;
