import React, { useState, useEffect } from 'react';
import {
  ModalBackground,
  ModalCard,
  ModalHeader,
  ModalBody,
  ModalShareContainer,
} from '../../assets/styles/Home.styled';
import {
  Rectangle23,
  Rectangle24,
  Title1,
  Subtitle,
} from './Home.styled';
import Ana from '../../assets/img/anacoach/anavalordinero.png';
import { Image } from './common.styled';
import ShareSection from './ShareSection';

const ModalAmazon = ({ show = false, wo }) => {
  // Hay que pasar un show en true para activar el que aparezca en exactamente 3 segundos.
  // Se tiene que pasar un objeto con la url de messenger, facebook y whatsapp para que funcionen los botones de compartir
  const [modalState, setModalState] = useState(false);
  const [current, setCurrent] = useState(3);

  const TIMER = {
    PAUSED: 'PAUSED',
    TICKING: 'TICKING',
  };

  const [timerState, setTimerState] = useState(TIMER.PAUSED);
  const isTicking = () => timerState === TIMER.TICKING;
  const pause = () => setTimerState(TIMER.PAUSED);

  useEffect(() => {
    show ? setTimerState(TIMER.TICKING) : setTimerState(TIMER.PAUSED);
  });

  function handleStart() {
    if (isTicking()) {
      return;
    }
    setTimerState(TIMER.TICKING);
  }

  useEffect(() => {
    let intervalId;

    const reset = (_) => pause();
    const pauseTimer = (_) => clearInterval(intervalId);

    function tick() {
      setCurrent((current) => {
        if (current === 0) {
          setModalState(false);
          reset();
        }
        return current - 1;
      });
    }
    switch (timerState) {
      case TIMER.PAUSED:
        break;
      case TIMER.TICKING:
        intervalId = setInterval(tick, 1000);
        break;
      default:
        break;
    }

    return pauseTimer;
  }, [timerState]);

  return (
    <ModalBackground display={!modalState && 'none'}>
      <ModalCard>
        <Rectangle23
          position="absolute"
          background="#91D770"
          Mleft="-30px"
          Mtop="-30px"
          width="60px"
          height="60px"
          left=" "
        />
        <Rectangle23
          position="absolute"
          background="#E3E660"
          Mleft="90px"
          Mtop="-9px"
          width="28px"
          height="32px"
          transform="rotate(45deg)"
          left="58px"
          top="-27px"
        />
        <Rectangle23
          position="absolute"
          background="#6BD5AF"
          Mleft="190px"
          Mtop="-30px"
          width="62.36px"
          height="67px"
          transform="rotate(45deg)"
          left="171px"
        />
        <Rectangle23
          position="absolute"
          background="#E3E660"
          Mleft="280px"
          Mtop="40px"
          width="42.53px"
          height="45.22px"
          transform="rotate(45deg)"
          left=" "
        />
        <Rectangle23
          position="absolute"
          background="#E3E660"
          Mleft="-60px"
          Mtop="140px"
          width="60px"
          height="60px"
          transform="rotate(45deg)"
          left=" "
        />
        <Rectangle24
          positionD="absolute"
          radius="50px"
          width="145px"
          left="266px"
          top="118px"
          widthM="145px"
          heightM=""
          mLeftM=" "
          mTopM=" "
        />
        <i
          style={{
            position: 'absolute',
            right: '10px',
            top: '0px',
            fontSize: '30px',
            cursor: 'pointer',
          }}
          onClick={(e) => setModalState(false)}
        >
          ×
        </i>
        <ModalHeader>
          <Image src={Ana} maxwidth="82px" />
          <Title1
            size="23px"
            aling="center"
            mt=" "
            sizeM="18px"
            mleftM=" "
            mtopM=" "
            heightM=" "
          >
            ¡Gana una tarjeta de <br />
            Amazon!
          </Title1>
        </ModalHeader>
        <ModalBody>
          <span>
            Recomienda Coru a tus amigos y obtén una tarjeta de regalo de{' '}
            <span style={{ fontWeight: 'bold' }}>Amazon</span> con valor de $100
            MXN. Solo necesitan registrarse e iniciar un entrenamiento.
          </span>
          <Subtitle className="roboto" size="16px">
            Comparte en:
          </Subtitle>
          <ModalShareContainer>
            <ShareSection WO={wo} Modal={true} />
          </ModalShareContainer>
          <div style={{ marginTop: '40px' }}>
            <span style={{ width: '100%', fontSize: '14px' }}>
              *Limitado a dos tarjetas de regalo por persona.
            </span>
            <br />
            <span style={{ width: '100%', fontSize: '14px' }}>
              *La tarjeta de regalo será enviada por WhatsApp al número que está
              registrado en la plataforma.
            </span>
          </div>
        </ModalBody>
      </ModalCard>
    </ModalBackground>
  );
};

export default ModalAmazon;
