import React, { useState, useEffect } from 'react';
import WOlayout from '../../components/layouts/WOlayout';
import Logo from '../../assets/img/anacoach/CoruLogoB.png';
import Coppel from '../../assets/img/anacoach/Coppel.png';
import { Estados } from '../../assets/Estados.js';
import { useLazyQuery } from '@apollo/client';
import {
  Container1,
  Subtitle,
  Title2,
} from '../../components/common/Home.styled';
import {
  Container,
} from '../../components/common/common.styled';
import { Button } from './LpCoppelOTP';
import styled from 'styled-components';
import { GET_VALIDATE_STREET_NAME, GET_CIUDADES_COP, GET_COLONIAS_COP } from '../../queries';
import ModalLoader from '../../components/common/ModalLoader';

const LpCoppelAdress = () => {
  const [valid, setValid] = useState(false);
  const [displayList, setDisplayList] = useState(false);
  const [calles, setCalles] = useState([]);
  const [Municipios, setMunicipios] = useState([]);
  const [Colonias, setColonias] = useState([]);
  const [form, setForm] = useState({
    estado: '',
    delegacion: '',
    colonia: '',
    calle: '',
    codigopostal: '',
    numeroexterior: '',
    numerointerior: '',
    calle1: '',
    calle2: '',
    referencia: '',
    historialcrediticio: false
  });
  const [modalLoad, setModalLoad] = useState(false);
  const [banderaList, setBanderaList] = useState(false);
  const [getValidateStreetName] = useLazyQuery(GET_VALIDATE_STREET_NAME, {
    onCompleted({ getValidateStreetName }) {
      setCalles(getValidateStreetName.nombresCalles) //Cambiar
    }
  });
  const [getCiudadesCop] = useLazyQuery(GET_CIUDADES_COP, {
    onCompleted({ getCiudadesCop }) {
      setMunicipios(getCiudadesCop.ciudades);
    }
  });
  const [getColoniasCop] = useLazyQuery(GET_COLONIAS_COP, {
    onCompleted({getColoniasCop}) {
      console.log(getColoniasCop);
      setColonias(getColoniasCop.colonias);
    }
  })
  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "historialcrediticio":
        setForm({ ...form, historialcrediticio: !form.historialcrediticio });
        break;

      default:
        setForm({ ...form, [id]: value });
        break;
    }
  };
  const changeCalle = (e) => {
    const { id, value } = e.target;
    setBanderaList(false);
    setForm({ ...form, [id]: value });
    if (value.length >= 3) {
      getValidateStreetName({ variables: { streetName: form.calle } });
      banderaList ? setDisplayList(false) : setDisplayList(true)
    } else {
      banderaList ? setDisplayList(false) : setDisplayList(true);
    }
  }

  const callScrapperCoppel = (e) => {
    setModalLoad(true);
    if (valid) {
      window.location = "/lp/coppelOTP"
    }
    else {
      alert("desactivado")
    }
  };

  useEffect(() => {
    form.estado.length > 0 && getCiudadesCop({ variables: { Estado: form.estado } });
  }, [form.estado]);

  useEffect(() => {
    form.delegacion.length > 0 && getColoniasCop({variables: {idCiudad: form.delegacion}});
  }, [form.delegacion])

  useEffect(() => {
    let { estado, delegacion, colonia, calle, codigopostal, numeroexterior, calle1, calle2, referencia, historialcrediticio, numerointerior } = form;
    if (estado && delegacion && colonia && calle && codigopostal && numeroexterior && calle1 && calle2 && referencia && historialcrediticio) {
      sessionStorage.setItem('estadoCoppel', estado);
      sessionStorage.setItem('delegacionCoppel', delegacion);
      sessionStorage.setItem('coloniaCoppel', colonia);
      sessionStorage.setItem('calleCoppel', calle);
      sessionStorage.setItem('codigopostalCoppel', codigopostal);
      sessionStorage.setItem('numeroexteriorCoppel', numeroexterior);
      sessionStorage.setItem('calle1Coppel', calle1);
      sessionStorage.setItem('calle2Coppel', calle2);
      sessionStorage.setItem('referenciaCoppel', referencia);
      sessionStorage.setItem('numerointeriorCoppel', numerointerior)
      setValid(true)
    } else {
      setValid(false);
    }
  }, [form]);

  return (
    <WOlayout>
      <div className="navbar navbar-light bg-light shadow-sm">
        <a href="#" className="navbar-brand">
          <img src={Logo} alt="" style={{ maxWidth: '91px' }} />
        </a>
        <a href="#" className="ml-auto navbar-brand">
          <img src={Coppel} alt="" style={{ maxWidth: '91px', maxHeight: '32px' }} />
        </a>
      </div>
      <Container1>
        <Container
          width="100%"
          padding="20px"
          boxSizing="border-box"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}
        >
          <div style={{ width: "100%" }}>
            <Title2
              topD=" "
              aling="center"
              className="roboto"
            >
              Domicilio
            </Title2>
            <Subtitle
              width=" "
              widthM=" "
              mleftM=" "
              marginTop="20px"
              aling="center"
              className="roboto"
              weight="bold"
            >
              Revisa que la información sea correcta para continuar con tu solicitud
            </Subtitle>
          </div>
          <div className="container-sm" style={{ marginTop: '20px' }}>
            <Contenedor>
              <Subtitle
                width=" "
                widthM=" "
                mleftM=" "
                marginTop="20px"
                aling="left"
                size="18px"
                sizeM="16px"
                className="roboto"
              >
                *Campos requeridos
              </Subtitle>
              <div></div>
              <Colum grid>
                <Label htmlFor="estado">Estado*</Label>
                <Select id="estado" value={form.estado} onChange={handleChange} valid={form.estado}>
                  <option value="" disabled>ESTADO</option>
                  {
                    Estados.map((estado, index) => {
                      return <option key={index} value={estado}>{estado.toUpperCase()}</option>
                    })
                  }
                </Select>
              </Colum>
              <Colum grid>
                <Label htmlFor="delegacion">Delegación o municipio*</Label>
                <Select id="delegacion" value={form.delegacion} onChange={handleChange} valid={form.delegacion}>
                  <option value="" disabled>DELEGACIÓN O MUNICIPIO</option>
                  {
                    form.estado.length === 0 && <option value="" disabled>Primero debes seleccionar un Estado</option>
                  }
                  {
                    Municipios.length > 0 ? Municipios.map((municipio, index) => {
                      return <option key={index} value={municipio.clave} selected={form.delegacion == municipio.clave}>{municipio.descripcion}</option>
                    }) : form.estado.length > 0 && <option>Cargando datos...</option>
                  }
                </Select>
              </Colum>
              <Colum grid>
                <Label htmlFor="colonia">Colonia*</Label>
                <Select id="colonia" value={form.colonia} onChange={handleChange} valid={form.colonia}>
                  <option value="">COLONIA</option>
                  {
                    form.delegacion.length === 0 && <option value="" disabled>Primero debes seleccionar una delegación o municipio</option>
                  }
                  {
                    Colonias.length > 0 ? Colonias.map((colonia, index) => {
                      return <option key={index} value={colonia.clave} selected={form.colonia === colonia.clave}>{colonia.descripcion}</option>
                    }) : form.delegacion.length > 0 && <option>Cargando datos...</option>
                  }
                </Select>
              </Colum>
              <Colum grid position="relative">
                <Label htmlFor="calle">Calle*</Label>
                <Input type="text" placeholder="CALLE" id="calle" value={form.calle} onChange={changeCalle} valid={form.calle} autoComplete="off" />
                {
                  form.calle.length > 0 && (
                    <List display={displayList}>
                      {
                        calles.length > 0 ? calles.map((calle, index) => {
                          return (
                            <ItemList
                              value={calle}
                              key={index}
                              onClick={() => {
                                setForm({ ...form, calle: calle });
                                setDisplayList(false);
                                setBanderaList(true);
                              }}
                            >
                              {calle}
                            </ItemList>
                          )
                        }) : (
                          <ItemList>
                            <div class="spinner-border text-dark" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </ItemList>)
                      }
                    </List>
                  )
                }
              </Colum>
              <Colum grid>
                <Label htmlFor="codigopostal">Código postal*</Label>
                <Input type="text" pattern="[0-9]*" placeholder="CÓDIGO POSTAL" maxLength={5} id="codigopostal" value={form.codigopostal} onChange={handleChange} valid={form.codigopostal} />
              </Colum>
              <Numeros>
                <Colum>
                  <Label htmlFor="numeroexterior">Número exterior*</Label>
                  <Input type="text" pattern="[0-9]*" placeholder="NÚMERO" maxLength={5} width="100%" id="numeroexterior" value={form.numeroexterior} onChange={handleChange} valid={form.numeroexterior} />
                </Colum>
                <Colum>
                  <Label htmlFor="numerointerior">Número interior</Label>
                  <Input type="text" pattern="[0-9]*" placeholder="NÚMERO" maxLength={5} width="100%" id="numerointerior" value={form.numerointerior} onChange={handleChange} valid={form.numerointerior} />
                </Colum>
              </Numeros>
              <Calles>
                <Label style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>Entre calles del domicilio*</Label>
                <div style={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'grid', gridTemplateColumns: 'auto auto' }}>
                  <div style={{ padding: '10px', paddingLeft: 0, paddingTop: 0 }}>
                    <Input type="text" placeholder="CALLE 1" width="100%" id="calle1" value={form.calle1} onChange={handleChange} valid={form.calle1} />
                  </div>
                  <div style={{ padding: '10px', paddingLeft: 0, paddingTop: 0 }}>
                    <Input type="text" placeholder="CALLE 2" width="100%" style={{ marginLeft: '10px' }} id="calle2" value={form.calle2} onChange={handleChange} valid={form.calle2} />
                  </div>
                </div>
              </Calles>
              <Colum grid>
                <Label htmlFor="referencia">¿Qué hay cerca del domicilio?*</Label>
                <Input type="text" placeholder="REFERENCIA" id="referencia" value={form.referencia} onChange={handleChange} valid={form.referencia} />
              </Colum>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', gridColumnStart: 1, gridColumnEnd: 3 }}>
                <input type="checkbox" id="historialcrediticio" checked={form.historialcrediticio} value={form.historialcrediticio} onChange={handleChange} valid={form.historialcrediticio} />
                <label style={{ marginLeft: '5px', color: 'black', fontSize: '16px', fontWeight: 400, marginBottom: 'unset' }} htmlFor="historialcrediticio" className="roboto">
                  Autorizo consultar mi historial crediticio*
                </label>
              </div>
              <div style={{ gridColumnStart: 1, gridColumnEnd: 3, padding: '10px', display: 'flex', justifyContent: 'center' }}>
                <Button active={valid} disabled={!valid} onClick={callScrapperCoppel}>Continuar</Button>
              </div>
              <div style={{ gridColumnStart: 1, gridColumnEnd: 3, padding: '10px' }}>
                <span style={{ marginLeft: '5px', color: 'black', fontSize: '12px', fontWeight: 400 }} className="roboto">
                  “EL CLIENTE” expresamente faculta a “EL BANCO” para que por conducto de sus funcionarios facultados lleva a cabo investigaciones, sobre su comportamiento crediticio en las Sociedades de Información Crediticia. Asimismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que “EL BANCO”, hará de tal información y de que ésta podrá realizar consultas periódicas sobre mi historial, consintiendo que ésta autorización se encuentre vigente por un periodo de 3(tres) años contados a partir de su expedición y en todo caso durante el tiempo que se mantenga la relación jurídica. El que suscribe, por así convenir mis intereses, con fundamento y en términos del artículo 2554 y 2587 del Código Civil Federal y del Código Civil para el Distrito Federal y sus correlativos de las demás entidades federativas en los Estados Unidos Mexicanos, otorgo poder especial, a “EL BANCO”, en su calidad de comisionista mercantil, para que en mi nombre y representación, comparta la información que obtenga, de mi comportamiento crediticio, de las Sociedades de Información Crediticia a Coppel, S.A. de C.V., sus filiales, subsidiaras y/o cualquier otra sociedad integrante de dicho grupo empresarial, para correr paramétricos y posibles contrataciones de productos y servicios. Estoy consciente y acepto que este documento quede bajo propiedad y resguardo de “EL BANCO” para efectos legales, de control y cumplimiento.
                </span>
              </div>
            </Contenedor>
          </div>
          <div style={{ width: '100%', height: '150px' }}></div>
        </Container>
        { modalLoad ?
          <ModalLoader lp="coppel" />
          : null
        }
      </Container1>
    </WOlayout>
  )
};

const Contenedor = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media(max-width: 1200px) {
    width: 100%;
  }
`;

const Colum = styled.div`
  padding: 10px;
  display: flex; 
  flex-direction: column;
  position: ${(props) => props.position || ''};
  ${(props) => props.grid ? `
    @media(max-width: 760px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  ` : ' '}
`;

const Input = styled.input`
  background: #FFF;
  border: 1px solid ${(props) => props.valid ? '#006CBA' : '#A6A6A6'};
  border-radius: 5px;
  width: 100%;
  height: ${(props) => props.height || '50px'};
  font-size: 20px;
`;

const Select = styled.select`
  background: #FFF;
  border: 1px solid ${(props) => props.valid ? '#006CBA' : '#A6A6A6'};
  border-radius: 5px;
  width: 100%;
  height: 50px;
  font-size: 20px;
`;

const Label = styled.label`
  color: black;
  font-size: 18px;
  font-weight: 500;
  @media(max-width: 990px) {
    font-size: 15px;
  }
`;

const Calles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; 
  padding: 10px;
  @media(max-width: 760px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const Numeros = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media(max-width: 760px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const List = styled.ul`
  display: ${(props) => props.display ? '' : 'none'};
  position: absolute;
  top: 95px;
  left: 12px;
  background-color: #FFF;
  color: black;
  width: 94%;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding: 5px;
  max-height: 240px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
}
`;

const ItemList = styled.li`
  font-size: 16px;
  cursor: pointer;
  transition: .5s ease;
  &: hover {
    text-decoration: underline;
  }
`;

export default LpCoppelAdress
