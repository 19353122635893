import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Image } from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import Flag from '../../assets/img/logros/SaveBlack.svg';
import Share from '../../assets/img/logros/Share.svg';
import { CHANGE_STATUS } from '../../mutations';
import {
  ImageSections,
  DivStyle,
  CardsBtn,
  CardsTxt,
  CardProdBank,
} from '../../assets/styles/Training.styled';

function ProductsLogros({ cards, background }) {
  const history = useHistory();
  const [cardsByProvider] = useState(
    cards || JSON.parse(sessionStorage.getItem('CatProductosTemp')),
  );
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [changeStatus] = useMutation(CHANGE_STATUS, {
    onCompleted({ changeStatus }) {
      if (changeStatus.message === 'success') {
        console.log(changeStatus);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const saveProduct = (idProd) => {
    for (let i = 0; i < cardsByProvider.length; i++) {
      if (cardsByProvider[i].IDProduct === idProd) {
        cardsByProvider.splice(i, 1);
      }
    }
    const inputUpdateStatus = {
      input: {
        table: 'DatCardSaving',
        columns: [
          {
            setkey: '`status`',
            setval: 0,
          },
        ],
        conditions: [
          {
            valone: 'CatUsers_IDUser',
            valtwo: sessionStorage.getItem('idUser'),
            condition: '=',
            logic: '',
          },
          {
            logic: 'AND',
            valone: 'CatProduct_IdProduct',
            condition: '=',
            valtwo: String(idProd),
          },
        ],
      },
    };
    changeStatus({ variables: inputUpdateStatus });
  };

  return cardsByProvider.map((card) => (
    <div
      key={card.IDProduct}
      className="d-flex flex-column"
      style={{
        width: '320px',
        minHeight: '450px',
        maxHeight: '550px',
        padding: '11px',
        margin: '20px auto',
        borderRadius: '4px',
        boxShadow: '0px 4px 16px 0px rgb(0 0 0 / 20%)',
      }}
    >
      <div>
        <CardProdBank bc={background}>{card.BusinessAbr}</CardProdBank>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '20%',
            marginLeft: '80%',
            marginBottom: '-20px',
          }}
        >
          <div
            style={{ zIndex: '1' }}
            onClick={() => saveProduct(card.IDProduct)}
          >
            <Image
              onClick=""
              zindex="1"
              cursor="pointer"
              src={Flag}
              width="12px"
              marginRight="0"
              marginRightM="0"
            />
          </div>
          <Image
            onClick=""
            zindex="1"
            cursor="pointer"
            src={Share}
            width="20px"
            marginRight="0"
            marginRightM="0"
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '95px',
          }}
        >
          <ImageSections
            pt="25px"
            width="80px"
            hmob="max-content"
            ml="0"
            heightweb="max-content"
            src={card.ImageURL}
            className="img-fluid"
          />
          <div style={{ textAlign: 'right', paddingTop: '25px' }}>
            {
               card.Name.indexOf('Sport World') < 0
               && card.Name.indexOf('AT&T') < 0
               && card.Name.indexOf('doc.com') < 0
               && card.Name.indexOf('Guru de Viaje') < 0
               && card.Name.indexOf('Sofía') < 0
               && card.Name.indexOf('LinkedIn') < 0 ? (
                <CardsTxt className="roboto" size="16px" color="#07210A">
                  Anualidad
                </CardsTxt>
                 ) : null
            }
            {JSON.parse(card.productDetail.response).map((detalle) => {
              if (detalle.Name === 'Anualidad') {
                return (
                  <CardsTxt
                    key={detalle.Description}
                    className="roboto"
                    weight="600"
                    size="14px"
                    color="#07210A"
                  >
                    {detalle.Description.includes('USD')
                    || detalle.Description.includes('MXN')
                      ? detalle.Description
                      : formatter.format(detalle.Description)}
                  </CardsTxt>
                );
              }
            })}
          </div>
        </div>

        <DivStyle
          className="cabin"
          mh="60px"
          mxh="60px"
          weight="600"
          align="left"
          size="18px"
          line="1.5em"
          padd="12px 0"
          color="#07210A"
        >
          {card.Name}
        </DivStyle>
        <DivStyle align="left" mh="110px" size="14px">
          <CardsTxt className="roboto" size="16px" weight="600" color="#07210A">
            Beneficios
          </CardsTxt>
          {JSON.parse(card.productDetail.response).map((detalle) => {
            if (detalle.Name === 'Beneficio 1') {
              return (
                <li className="roboto styleBenef">{detalle.Description}</li>
              );
            }
            if (detalle.Name === 'Beneficio 2') {
              return (
                <li className="roboto styleBenef">{detalle.Description}</li>
              );
            }
            if (detalle.Name === 'Beneficio 3') {
              return (
                <li className="roboto styleBenef">{detalle.Description}</li>
              );
            }
            if (detalle.Name === 'Beneficio 4') {
              return (
                <li className="roboto styleBenef">{detalle.Description}</li>
              );
            }
            if (detalle.Name === 'Beneficio') {
              return (
                <li className="roboto styleBenef">{detalle.Description}</li>
              );
            }
          })}
        </DivStyle>
        <DivStyle align="left" mh="80px" size="14px">
          {
            card.Name.indexOf('Sport World') < 0
            && card.Name.indexOf('Albo') < 0
            && card.Name.indexOf('AT&T') < 0
            && card.Name.indexOf('doc.com') < 0
            && card.Name.indexOf('Guru de Viaje') < 0
            && card.Name.indexOf('Sofía') < 0
            && card.Name.indexOf('LinkedIn') < 0 ? (
              <CardsTxt className="roboto" size="16px" weight="600" color="#07210A">
                Información extra
              </CardsTxt>
              ) : null
          }
          {JSON.parse(card.productDetail.response).map((detalle) => {
            if (detalle.Name === 'CAT') {
              return (
                <li className="roboto styleBenef">
                  CAT {(detalle.Description * 100).toFixed(2)}%
                </li>
              );
            }
          })}
          {JSON.parse(card.productDetail.response).map((detalle) => {
            if (detalle.Name === 'Interés anual') {
              return (
                <li className="roboto styleBenef">
                  Tasas de interés {(detalle.Description * 100).toFixed(2)}%
                </li>
              );
            }
          })}
        </DivStyle>
        <CardsBtn
          mtopmd="0"
          mtop="0"
          mtopw="0"
          min_w="100%"
          min_web="100%"
          onClick={() => {
            history.push('/wo/card-funnel');
          }}
        >
          Volver a la recomendación
        </CardsBtn>
      </div>
    </div>
  ));
}
export default ProductsLogros;
