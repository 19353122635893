import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { GETURLSHORT } from '../queries';

const UrlShortener = ({ location }) => {
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  });

  const urlBarra = window.location.search;
  let mensajeMostrar = '';
  let idShortUrl = urlBarra.split('&')[0].replace('?id=', '');
  if (idShortUrl === undefined || idShortUrl === '') {
    mensajeMostrar = 'Url no encontrada';
    idShortUrl = 'vacio';
  }

  const { data, loading } = useQuery(GETURLSHORT, {
    variables: { id: idShortUrl },
  });
  if (loading) return 'Cargando...';

  try {
    if (data.getUrlShortened.message === 'no existe la url') {
      window.location.assign('/404');
    } else {
      let urlRedirect = data.getUrlShortened.response[0].Url;
      if (!urlRedirect.includes('http://')) {
        if (!urlRedirect.includes('https://')) {
          urlRedirect = `http://${urlRedirect}`;
        }
      }

      window.location.assign(urlRedirect);
      mensajeMostrar = 'Redirigiendo....';
    }
  } catch (error) {
    console.log(error);
    mensajeMostrar = 'Fallo';
  }

  return (
    <div>{mensajeMostrar}</div>
  );
};
export default UrlShortener;
