import React, { useState, useEffect } from 'react';
import { Image } from './common.styled';
import '../../assets/styles/perfil/perfiles.css';
import {
  DivSelectionFilter,
  DivFiltersName,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';
import FiltersOptions from './FiltersOptions';
import triangleF from '../../assets/img/anacoach/triangleF.svg';

function Filters({
  optionsBanks,
  optionsAnualidades,
  setBankFilter,
  setAnnuityFilter,
  setCardFilter,
  setFlag,
  countResults,
  handleShowResults,
  optionsTipos = ['Tarjetas Premium', 'Tarjetas Básicas'],
  showFilterCategory = true,
}) {
  const [activeFilter, setActiveFilter] = useState('');

  useEffect(() => {
    if (activeFilter !== '') {
      const filter1 = document.getElementById('filterBanco');
      const filter2 = document.getElementById('filterAnualidad');
      const filter3 = document.getElementById('filterTipoTarjeta');
      if (activeFilter === 'Banco') {
        if (filter1) filter1.classList.add('borderFilter');
        if (filter2) filter2.classList.remove('borderFilter');
        if (filter3) filter3.classList.remove('borderFilter');
      } else if (activeFilter === 'Anualidad') {
        if (filter1) filter1.classList.remove('borderFilter');
        if (filter2) filter2.classList.add('borderFilter');
        if (filter3) filter3.classList.remove('borderFilter');
      } else if (activeFilter === 'Tipo de tarjeta') {
        if (filter1) filter1.classList.remove('borderFilter');
        if (filter2) filter2.classList.remove('borderFilter');
        if (filter3) filter3.classList.add('borderFilter');
      }
    }
  }, [activeFilter]);

  const emptyActiveFilter = (filt) => {
    if (activeFilter === filt) {
      setActiveFilter('');
    } else {
      setActiveFilter(filt);
    }
  };
  return (
    <TrainingQuestion width="100%">
      <DivSelectionFilter padd="15px 12px" justifyContent="space-evenly">
        <DivFiltersName
          margin="0 4px"
          marginM="0 6px"
          marginMC="0 10px"
          marginMI="0 5px"
          marginMG="0 5px"
          flex="1"
          wi="115px"
          wim="100px"
          onClick={() => emptyActiveFilter('Banco')}
          id="filterBanco"
          style={{ display: 'flex' }}
        >
          <div style={{ width: '85%' }}>Banco</div>
          <Image
            marginLeft="0"
            marginTop="-5px"
            zindex="1"
            src={triangleF}
            width="10px"
            marginRight="0"
            marginRightM="0"
          />
        </DivFiltersName>
        <DivFiltersName
          margin="0 4px"
          marginM="0 6px"
          marginMC="0 10px"
          marginMI="0 5px"
          marginMG="0 5px"
          flex="1"
          wi="115px"
          wim="100px"
          onClick={() => emptyActiveFilter('Anualidad')}
          id="filterAnualidad"
          style={{ display: 'flex' }}
        >
          <div style={{ width: '85%' }}>Anualidad</div>
          <Image
            marginLeft="0"
            marginTop="-5px"
            zindex="1"
            src={triangleF}
            width="10px"
            marginRight="0"
            marginRightM="0"
          />
        </DivFiltersName>

        {showFilterCategory && <DivFiltersName
          margin="0 4px"
          marginM="0 6px"
          marginMC="0 10px"
          marginMI="0 5px"
          marginMG="0 5px"
          flex="1"
          wi="115px"
          wim="100px"
          onClick={() => emptyActiveFilter('Tipo de tarjeta')}
          id="filterTipoTarjeta"
          style={{ display: 'flex' }}
        >

          <div style={{ width: '85%' }}>Tipo de tarjeta</div>
          <Image
            marginLeft="0"
            marginTop="-5px"
            zindex="1"
            src={triangleF}
            width="10px"
            marginRight="0"
            marginRightM="0"
          />
        </DivFiltersName>}
      </DivSelectionFilter>
      <FiltersOptions
        display={activeFilter === 'Banco' ? 'inline-block' : 'none !important'}
        setFlag={setFlag}
        filterType={activeFilter}
        countResults={countResults}
        handleShowResults={handleShowResults}
        setBankFilter={setBankFilter}
        options={optionsBanks}
      />
      <FiltersOptions
        setFlag={setFlag}
        display={
          activeFilter === 'Anualidad' ? 'inline-block' : 'none !important'
        }
        filterType={activeFilter}
        countResults={countResults}
        handleShowResults={handleShowResults}
        setAnnuityFilter={setAnnuityFilter}
        options={optionsAnualidades}
      />
      {showFilterCategory && <FiltersOptions
        setFlag={setFlag}
        display={
          activeFilter === 'Tipo de tarjeta'
            ? 'inline-block'
            : 'none !important'
        }
        filterType={activeFilter}
        countResults={countResults}
        handleShowResults={handleShowResults}
        setCardFilter={setCardFilter}
        options={optionsTipos}
        around={optionsTipos.length < 3}
      />}
    </TrainingQuestion>
  );
}
export default Filters;
