import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Image,
  ParagrapTwo,
} from './common.styled';
import {
  ContentHomeSection, FInalcialProfile,
  RectangleAna,
  CoachAna1,
  ElipseCoach,
  LogocoruCoach,
} from './Home.styled';
import {
  CardsBtn,
} from '../../assets/styles/Training.styled';
import logocoruCoach from '../../assets/img/home/logoCoach.png';
import fondomodal1_video from '../../assets/img/anacoach/fondomodal1_video.svg';
import fondomodal2_video from '../../assets/img/anacoach/fondomodal2_video.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '585px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '65%',
    '@media (max-width: 768px)': {
      width: '500px',
      height: '50%',
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalWritePhoneNumber = ({
  setOpen,
  open,
  cellPhone,
  handleChange,
  sendVideo,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };

  return (
        <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>
                <ParagrapTwo width="70%" widthM="95%" fontWeight="700" cursor="pointer" texttalign="right" textalignMobile="right" className='mx-auto' onClick={handleClose} mt="" mtWeb="0px">X</ParagrapTwo>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Image src={fondomodal1_video} height="350px" marginLeftM="0px" heightMobile="360px" mwidth="200px" width="180px" mtMob="0px" margin=" " top="110px" left="0%" mleft="0%" marginLeft=" " mtop="-30px" marginTop="-30px" />
                    <Image src={fondomodal2_video} height="350px" marginLeftM="0px" heightMobile="360px" mwidth="230px" width="150px" mtMob="0px" margin=" " top="110px" left="0%" mleft="0%" marginLeft=" " mtop="-30px" marginTop="-10px" />
                </div>
                <ContentHomeSection topD="0px" width="100%" leftD=" " rigthD="0%" floatD=" ">
                    <FInalcialProfile width="70px" top="-330px" topD="-300px" topD2="-300px">
                        <RectangleAna width="70px" height="85px">
                            <CoachAna1 width="60px" height="77px" />
                        </RectangleAna>
                        <ElipseCoach marginTop="-6%" marginLeft="10%" marginLeftMob="10%">
                            <LogocoruCoach src={logocoruCoach} />
                        </ElipseCoach>
                    </FInalcialProfile>
                </ContentHomeSection>
                <div style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#F4F4F4', width: '220px', marginLeft: '31.5%', marginTop: '-10px', padding: '5px',
                }}>
                    <div>
                        Ingresa el teléfono al que deseas enviemos el video.
                    </div>
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'column', width: '45%', marginLeft: '30%',
                }}>
                    <TextField
                        variant="outlined"
                        id="cellPhone"
                        autoComplete="off"
                        value={cellPhone}
                        onChange={handleChange}
                        placeholder="Teléfono celular"
                        inputProps={{ maxLength: 10 }}
                    />
                    <CardsBtn min_w="100%" min_web="100%" mtop="15px" mtopw="15px" onClick={(e) => sendVideo(e)} ><b>Enviar</b></CardsBtn>
                </div>
            </div>
        </Modal >
  );
};

export default ModalWritePhoneNumber;
