/* eslint-disable react/jsx-key */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
} from '../../assets/styles/Home.styled';
import Clock from '../../assets/img/anacoach/clockTraining.png';

function CarouselItems({ items }) {
  console.log(items);
  return (
    <>
      {items.map((post) => (
        <ContEntrenamiento
          id={post.id}
          background={post.TrainingColor}
          widthM="100%"
          widthD="100%"
        >
          <Link to={post.TrainingRedirectUrl ? post.TrainingRedirectUrl : '#'}>

            <ContentTimer
              height=" "
              maxheightMobile="130px"
              heightMobile="140px"
              maxheight="107.250px"
              display="flex"
              top="0px"
              width="100%"
              position=" "
            >
              <TitleEntrenamiento
                className="roboto"
                left="0px"
                margin="auto"
                top=" "
                widthD="230px"
              >
                {post.Name}
              </TitleEntrenamiento>
              <Image
                src={post.UrlBigImage}
                width="25%"
                widthmob="28.75%"
                margin="auto"
                top="1%"
                bottom="1%"
                right="0px"
              />
            </ContentTimer>
            <ContentTimer>
              <Image
                src={Clock}
                width="20px"
                left="0px"
                position=" "
                top="1%"
              />
              <TextTime className="roboto">Tiempo de entrenamiento: {post.TrainingTime} minutos</TextTime>
            </ContentTimer>
          </Link>
        </ContEntrenamiento>
      ))}
    </>
  );
}

export default CarouselItems;
