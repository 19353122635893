/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, Fragment } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
  TrainingText,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';

import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';

// Import query components and helpers
import {
  RECOVERY_ROUTINE_BY_ORDER,
  GET_RESPONSE_USER_BY_IDTYPE,
} from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import ClickButton from '../../Helpers/HookHelpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
// modal dialog
import ModalDialog from '../../components/common/ModalDialog';

const AddGoal = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  setValidateStatusTraining,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [montoMeta, setMontoMeta] = useState(
    sessionStorage.getItem('MontoMeta') || '',
  );
  const [disponibleMeta, setDisponibleMeta] = useState(0);
  const [dineroMetaQuincenal, setDineroMetaQuincenal] = useState(
    sessionStorage.getItem('DineroMetaQuincenal') || '',
  );
  const [sumaIngreso, setSumaIngreso] = useState('');
  const [sumaGastosFijo, setSumaGastosFijos] = useState('');
  const [sumaGastosExtra, setSumaGastosExtra] = useState('');
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({
    MontoMeta: sessionStorage.getItem('MontoMeta') || '',
    DineroMetaQuincenal: sessionStorage.getItem('DineroMetaQuincenal') || '',
    AhorroMensual: sessionStorage.getItem('AhorroMensual') || '',
    TiempodeAhorro: sessionStorage.getItem('TiempodeAhorro') || '',
  });
  const { actions } = ClickButton();

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 0:
        break;
      default:
        break;
    }
  };
  const handleChange = (e, question = '') => {
    let value = '';
    switch (e.target.name) {
      case 'MontoMeta':
        value = e.target.value.replace('$', '').replace(/,/g, '').trim();
        value = filterFloat(value);

        if (value !== '') {
          setForm({
            ...form,
            [e.target.name]: formatter.format(filterFloat(value)),
          });
        } else if (e.target.value === '$') {
          setForm({
            ...form,
            [e.target.name]: '',
          });
        }
        break;
      case 'DineroMetaQuincenal':
        value = e.target.value.replace('$', '').replace(/,/g, '').trim();
        value = filterFloat(value);
        if (value !== '') {
          setForm({
            ...form,
            [e.target.name]: formatter.format(filterFloat(value)),
          });
        } else if (e.target.value === '$') {
          setForm({
            ...form,
            [e.target.name]: '',
          });
        }
        break;

      default:
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
    }
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      // const valueCat = detailQuestion[x].CatFieldDetail_IDCatFieldDetail;

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              // setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getResponseUserByIdType] = useLazyQuery(GET_RESPONSE_USER_BY_IDTYPE, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ getResponseUserByIdType }) {
      try {
        const json = JSON.parse(getResponseUserByIdType.response);
        if (json.length > 0) {
          const ingreso = json[0].Value.replace('$', '')
            .replace(/,/g, '')
            .trim();
          const gastoFijo = json[1].Value.replace('$', '')
            .replace(/,/g, '')
            .trim();
          const gastoExtra = json[2].Value.replace('$', '')
            .replace(/,/g, '')
            .trim();

          setSumaIngreso(ingreso);
          setSumaGastosFijos(gastoFijo);
          setSumaGastosExtra(gastoExtra);
          const disponble = ingreso - gastoFijo - gastoExtra;
          setDisponibleMeta(disponble);
        }
      } catch (error) {
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const [metas, setMetas] = useState([]);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const metaquincenal = sessionStorage.getItem('DineroMetaQuincenal') || '';
      setForm(
        Object.assign(form, {
          MontoMeta: sessionStorage.getItem('MontoMeta') || '',
          DineroMetaQuincenal: metaquincenal,
          AhorroMensual: sessionStorage.getItem('AhorroMensual') || '',
          TiempodeAhorro: sessionStorage.getItem('TiempodeAhorro') || '',
        }),
      );
      if (
        sessionStorage.getItem('MontoMeta') !== null
        && sessionStorage.getItem('MontoMeta') !== ''
      ) {
        setValidateStatusTraining(false);
      }
      // openfields
      setMontoMeta(sessionStorage.getItem('MontoMeta') || '');
      setDineroMetaQuincenal(metaquincenal);
      const seleccionMeta = sessionStorage.getItem('SeleccionaMeta') || '';

      if (seleccionMeta !== '') {
        let goals = String(seleccionMeta).replace(/\|/g, ',');
        goals = goals.split(',');
        setMetas(goals);
      }
      if (metaquincenal !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);
  const [activeModalGoal, setActiveModalGoal] = useState(false);
  const handleChangeMonto = () => {
    const click = `ContinuarMontoMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const myGoal = form.MontoMeta.replace('$', '').replace(/,/g, '').trim();

    if (parseInt(myGoal) >= 50) {
      questions.push({
        id: 'MontoMeta',
        question: 1,
        value: form.MontoMeta,
      });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setMontoMeta(form.MontoMeta);
      setValidateStatusTraining(false);
    } else {
      setActiveModalGoal(true);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activePresupuestoCoru' });
  };
  const [activeModalDisponible, setActiveModalDisponible] = useState(false);
  const handleChangeMontoQuincenal = () => {
    const click = `ContinuarMontoQuincenal_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    let metaQincenal = form.DineroMetaQuincenal;
    metaQincenal = parseFloat(
      metaQincenal.replace('$', '').replace(/,/g, '').trim(),
    );
    const disponible = disponibleMeta / 2;
    if (metaQincenal <= disponible) {
      if (metaQincenal >= 50) {
        const meses = parseFloat(montoMeta.replace('$', '').replace(/,/g, '').trim())
          / parseFloat(metaQincenal)
          / 2;
        const ahorroMes = parseFloat(metaQincenal) * 2;
        setDineroMetaQuincenal(form.DineroMetaQuincenal);
        questions.push({
          id: 'DineroMetaQuincenal',
          question: 2,
          value: form.DineroMetaQuincenal,
        });
        questions.push({
          id: 'AhorroMensual',
          question: 3,
          value: String(ahorroMes),
        });
        questions.push({
          id: 'TiempodeAhorro',
          question: 4,
          value: String(parseInt(meses)),
        });

        createJsonInput(Rutine, questions);
        setQuestions([]);
        setOrderRutine(nextOrderRoutine);
      }
    } else {
      setActiveModalDisponible(true);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeRespGoal' });
  };
  useEffect(() => {
    if (montoMeta !== '') {
      getResponseUserByIdType({
        variables: {
          types: '308,310,311',
          idUser: sessionStorage.getItem('idUser'),
        },
      });
    }
  }, [montoMeta]);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && montoMeta === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeGoalsAdds' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <>
      {/* primer pregunta */}
      <div id="activeGoalsAdds"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingSectionGray
        padding="5%"
        mw="20px 0 0 18%"
        mtop="20px"
        paddinglG="5%"
        wiw="60%"
        widthn="90%"
        mn="auto auto auto auto"
        className={montoMeta === '' ? '' : 'hidden'}
      >
        <TrainingQuestion mt="0px" className={montoMeta === '' ? '' : 'hidden'}>
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="0-0"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
            labelInput="Monto"
          />
        </TrainingQuestion>
        <TrainingQuestion className={montoMeta === '' ? '' : 'hidden'}>
          <CardsBtn
            type="button"
            min_w="100%"
            min_web="100%"
            mtopw="0px"
            mtopmd="0px"
            onClick={(e) => handleChangeMonto(e)}
          >
            Continuar
          </CardsBtn>
        </TrainingQuestion>
      </TrainingSectionGray>
      <div id="activePresupuestoCoru"></div>
      <CardTwo
        classCard={montoMeta !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={montoMeta}
        colorTraining={trainingColor}
      >
      </CardTwo>

      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          montoMeta !== '' && dineroMetaQuincenal === '' ? 'cardOne' : 'hidden'
        }
        classTop="topOne1"
        text="Para crear una estrategia, consulte el presupuesto que creaste en Coru:"
        hora={hora}
      />
      <TrainingQuestion
        wiw="60%"
        mLg="18%"
        width="90%"
        leftg="5%"
        className={montoMeta !== '' ? '' : 'hidden'}
      >
        <div className="d-flex flex-column alignItemCenter">
          <td className="tabletrtitle"></td>
          <table className="table table-striped tablew">
            <tbody>
              <tr>
                <td className="tabletd">
                  {metas[0]}
                  <p>Metas</p>
                </td>
                <td>{montoMeta}</td>
              </tr>
              <tr>
                <td className="tabletd">
                  {formatter.format(sumaIngreso)}
                  <p>Ingresos</p>
                </td>
                <td>{formatter.format(sumaGastosFijo)}</td>
              </tr>
              <tr>
                <td className="tabletd">
                  {formatter.format(sumaGastosExtra)}
                  <p>Gastos extras</p>
                </td>
                <td></td>
              </tr>
              <tr className="tableTotal">
                <td className="tabletd">Disponible para meta:</td>
                <td>{formatter.format(disponibleMeta)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </TrainingQuestion>
      {disponibleMeta > 0 ? (
        <Fragment>
          <TrainingQuestion
            className={
              montoMeta !== '' && dineroMetaQuincenal === '' ? '' : 'hidden'
            }
          >
            <TrainingSectionGray
              padding="5%"
              paddinglG="5%"
              wiw="60%"
              widthn="100%"
              mw="20px 0 0 18%"
            >
              <TrainingText
                className="cabin"
                align="left"
                weight="300"
                size="20px"
              >
                ¿Cuánto dinero quieres usar{' '}
                <b style={{ fontWeight: 'bold' }}>quincenalmente</b> para
                cumplir tu meta?
              </TrainingText>
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="1-1"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                labelInput="Monto"
              />
              <QuestionsDivs
                padd="0"
                txta="left"
                width="100%"
                widthweb="100%"
                hei="auto"
                margin="0 auto auto"
                bc="#fafafa"
              >
                <CardsBtn
                  mtopw="0px"
                  mtopmd="0px"
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  onClick={(e) => handleChangeMontoQuincenal(e)}
                >
                  Continuar
                </CardsBtn>
              </QuestionsDivs>
            </TrainingSectionGray>
          </TrainingQuestion>
        </Fragment>
      ) : (
        <ModalDialog
          title=""
          text="Por ahora no tienes dinero disponible para el cumplimiento de tu meta, te recomendamos ajustar tu presupuesto"
          showModal={false}
        ></ModalDialog>
      )}
      <div id="activeRespGoal"></div>
      <CardTwo
        classCard={dineroMetaQuincenal !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={dineroMetaQuincenal}
        colorTraining={trainingColor}
      >
      </CardTwo>
      {activeModalDisponible ? (
        <ModalDialog
          title=""
          text="Por ahora no tienes dinero disponible para el cumplimiento de tu meta, te recomendamos ajustar tu presupuesto"
          showModal={activeModalDisponible}
        ></ModalDialog>
      ) : null}
      {activeModalGoal ? (
        <ModalDialog
          title="Monto mínimo"
          text="El monto mínimo para poder crear una meta es de $50.00"
          showModal={activeModalGoal}
        ></ModalDialog>
      ) : null}
    </>
  );
};

export default AddGoal;
