import React, { Fragment, useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  Container,
} from '../../assets/styles/Home.styled';
import ListItems from './One_Training';
import ClickButton from '../../Helpers/HookHelpers';

function Categorias({
  color, name, imagen, trainings, show, dynamicInsert, id,
}) {
  const [showCategorias, setshowCategorias] = useState(show);
  const { actions } = ClickButton();
  useEffect(() => {
    setshowCategorias(show);
  }, [show]);

  const ColorRGB = (h) => {
    let r = 0; let g = 0; let
      b = 0;
    // 3 digits
    if (h.length === 4) {
      r = `0x${h[1]}${h[1]}`;
      g = `0x${h[2]}${h[2]}`;
      b = `0x${h[3]}${h[3]}`;
      // 6 digits
    } else if (h.length === 7) {
      r = `0x${h[1]}${h[2]}`;
      g = `0x${h[3]}${h[4]}`;
      b = `0x${h[5]}${h[6]}`;
    }

    return `rgb(${+r},${+g},${+b} , 0.2)`;
  };

  const handleClickShowCategorias = (e) => {
    console.log('holaaaa', id)
    switch (id) {
      case 1:
        actions({ action: 'ClickButtonCoruHome', variables: 'GastosInteligentesVerEntrenamientos'});
        break;
      case 2:
        actions({ action: 'ClickButtonCoruHome', variables: 'PréstamosConscientesVerEntrenamientos'});
        break;
      case 3:
        actions({ action: 'ClickButtonCoruHome', variables: 'PlaneaciónEficienteVerEntrenamientos'});
        break;
      case 4:
        actions({ action: 'ClickButtonCoruHome', variables: 'ProtecciónEfectivaVerEntrenamientos'});
        break;
      default:
        break;
    }
    e.stopPropagation();
    if (showCategorias) {
      setshowCategorias(false);
    } else { setshowCategorias(true); }
  };

  return (
        <Fragment>
            <Container width="100%" widthMobile="100%" mtMobile="0px">
                <div style={{ backgroundColor: ColorRGB(color) }} className="divEntrenamiento">
                    <ContEntrenamiento id={`categorieSelectedOption${id}`} height="auto" widthM="100%" margintop="auto" padding="0px" paddingM="0px" widthD="100%" cursor="pointer" marginleft="auto">
                        <div className="row divCategorias" style={{ border: `1px solid ${color}` }} onClick={(e) => handleClickShowCategorias(e)}>
                            <div className="col-2 center">
                                <Image
                                    src={imagen}
                                    float=" "
                                    position=" "
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-8 center paddingmobile">

                                <TitleEntrenamiento className="roboto" aling="left" sizem="19px" left="0px" margin="auto" top=" ">
                                    {name}
                                </TitleEntrenamiento>

                            </div>
                            <div className="col-2 center">
                                {

                                    !showCategorias
                                      ? <TitleEntrenamiento className="roboto" left="0px" margin="auto" top=" " onClick={() => { setshowCategorias(true); }}>
                                        <AddIcon/>
                                    </TitleEntrenamiento>
                                      : <TitleEntrenamiento className="roboto" left="0px" margin="auto" top=" " onClick={() => { setshowCategorias(false); }}>
                                        <RemoveIcon/>
                                    </TitleEntrenamiento>
                                }

                            </div>
                        </div>
                    </ContEntrenamiento>
                    {
                        showCategorias
                          ? <ListItems
                                dynamicInsert={dynamicInsert}
                                items={trainings}
                                feed={true}
                            ></ListItems>
                          : null
                    }

                </div>
            </Container>
        </Fragment>
  );
}

export default Categorias;
