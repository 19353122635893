import React from 'react';
import CardDescription from './CardDescription';
import { Div_Flex } from '../../assets/styles/Home.styled';

const MultipleCards = ({ cards, enumerated }) => {
  const buildCards = () => {
    if (enumerated) {
      return cards.map(({
        img, title, subtitle, hoverColor,
      }, index) => <CardDescription img={img} title={title} subtitle={subtitle} hoverColor={hoverColor} number={index + 1}/>);
    }
    return cards.map(({
      img, title, subtitle, hoverColor,
    }) => <CardDescription img={img} title={title} subtitle={subtitle} hoverColor={hoverColor} />);
  };
  return <Div_Flex jc='space-between' alignItems='center'>
        {buildCards()}
    </Div_Flex>;
};

export default MultipleCards;
