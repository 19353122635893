import React, { Fragment } from 'react';
import { Images, CoruCoach, ImagesModal } from '../../assets/styles/Training.styled';
import { Col3 } from '../common/common.styled';
import logoC from '../../assets/img/anacoach/logoCoach.png';
import ana from '../../assets/img/anacoach/CoachImage.png';
import '../../assets/styles/Home.css';

function ImageCoachInicio({
  topMobile, pBottom, colorAna, closeModal, inactive, Starttraining, classAna, modal = false, anamodal = false,
}) {
  // console.log(topMobile);
  const path = window.location.pathname;
  // console.log(path);
  let backColor = '';
  const isLP = !!path.includes('LPFirstInversion');
  if (path.includes('loans-funnel') || path.includes('/personal-loans/success')) {
    backColor = '#BE7AE6';
  } else if (path.includes('obtener-primer-tarjeta') || path.includes('wo/first-card') || path.includes('/tarjeta-de-credito/success')) {
    backColor = '#DE98FF';
  } else if (path.includes('comparar-tarjeta') || path.includes('wo/compare-credit-card')) {
    backColor = '#917AFF';
  } else if (path.includes('debts')) {
    backColor = '#E3E660';
  } else if (path.includes('family-protection') || path.includes('family/succcess')) {
    backColor = '#61D2FA';
  } else if (path.includes('profiling') || path.includes('weekly-summary')) {
    backColor = '#FA9878';
  } else if (path.includes('emergency-saving')) {
    backColor = '#7FBD62';
  } else if (path.includes('investment-test')) {
    backColor = '#91D770';
  } else if (path.includes('investment-catalog')) {
    backColor = '#99F2A8';
  } else if (path.includes('funnel-tarjeta') || path.includes('wo/card-funnel')) {
    backColor = '#a370ff';
  } else if (path.includes('ExtraIncome') || path.includes('wo/extra-income')) {
    backColor = '#58D65C';
  } else if (path.includes('first-investment')) {
    backColor = '#6BD5AF';
  } else if (path.includes('TrainingBudget')) {
    backColor = 'rgb(245, 168, 56)';
  } else if (path.includes('investment-simulator')) {
    backColor = '#42A145';
  } else if (path.includes('ImproveBudget') || path.includes('wo/budget-improve')) {
    backColor = 'rgb(245, 202, 93)';
  } else if (path.includes('wo/budget')) {
    backColor = '#f5a838';
  } else if (path.includes('FastTrack') || path.includes('wo/fast-track')) {
    backColor = '#6677E3';
  } else if (path.includes('virality-test')) {
    backColor = '#F074DC';
  } else if (path.includes('/the-training-feed/') || path.includes('/achievements') || path.includes('/profile')) {
    backColor = '#BFDD6A';
  } else if (path.includes('new-years-purpose')) {
    backColor = '#EB4040';
  } else if (path.includes('wo/rejection-reasons') || path.includes('wo/reasons-success')) {
    backColor = '#8892E3';
  } else if (path.includes('WOValueOfMoney')) {
    backColor = '#FA9D59';
  } else if (path.includes('WOGoals') || path.includes('wo/goals')) {
    backColor = '#f5d495';
  } else if (path.includes('money-value')) {
    backColor = '#fa9d59';
  } else if (path.includes('/wo/micro-expenses')) {
    backColor = '#de9e83';
  } else if (path.includes('/no-credit-history')) {
    backColor = '#917AFF';
  } else if (path.includes('/wo/collaborative')) {
    backColor = '#f5d495';
  } else if (path.includes('/wo/afores')) {
    backColor = '#33A376';
  } else if (path.includes('/wo/know-your-score')) {
    backColor = '#2EA5B6';
  } else if (path.includes('/lp/super-seguros')) {
    backColor = '#BFDD6A';
  } else if (path.includes('/wo/pymes')) {
    backColor = '#8358C2';
  } else if (path.includes('/wo/car-insurance')) {
    backColor = '#22C4CE';
  } else if (path.includes('wo/glove-box')) {
    backColor = '#A5A6F6';
  } else if (path.includes('/wo/new-years-resolutions')) {
    backColor = '#EB4040';
  } else if (path.includes('/wo/card-compare/success')) {
    backColor = '#917AFF';
  }
   else {
    backColor = colorAna;
  }

  const handleCLickAsesor = () => {
    closeModal();
    document.getElementById('chat').value = 'Si';
    sessionStorage.setItem('InicioChat', true);
  };

  return (
      <Fragment>
         <Col3 className = {classAna} maxhei={modal ? '110px' : '89px'} padding="0px" flexD="10%" maxwidthD="10%" onClick={isLP ? handleCLickAsesor() : null}>
            <div style={{ display: 'block' }} className={ anamodal ? 'topAnaModal' : '' }>
               {!modal
                 ? <Images
                     src={ana}
                     className={Starttraining ? 'topOne1Init img-fluid ' : 'img-fluid' }
                     top={topMobile !== undefined ? topMobile : '0px'}
                     maxwidth={modal ? '65px' : '100%'}
                     width="65px"
                     topweb="20px"
                     left={modal ? '-12px' : '-25px'}
                     leftw= {modal ? '-30px' : '-52px'}
                     position="absolute"
                     positionw="absolute"
                     border={`2px solid ${backColor}`}
                     bs="border-box"
                     padd="5px"
                     br="45px"
                     position600="inherit"
                  ></Images>
                 : <ImagesModal
                  src={ana}
                  className={Starttraining ? 'topOne1Init img-fluid ' : 'img-fluid' }
                  top={topMobile !== undefined ? topMobile : '0px'}
                  maxwidth={modal ? '65px' : '100%'}
                  width="65px"
                  topweb="20px"
                  left={modal ? '-12px' : '-25px'}
                  leftw= {modal ? '-30px' : '-52px'}
                  leftsm="-30px"
                  mleft768="-30px"
                  top768="-32px"
                  topsmm="-32px"
                  position="absolute"
                  positionw="absolute"
                  border={`2px solid ${backColor}`}
                  bs="border-box"
                  padd="5px"
                  br="45px"
                  position600="inherit"
               ></ImagesModal>
            }
            </div>
            <CoruCoach
               position="absolute"
               pBottom={pBottom !== undefined ? pBottom : ' '}
               bcolor={backColor}
               marginTop="67px"
               marginLeft= {inactive ? '7px' : '20px'}
               leftD1={modal ? '6%' : '12%'}
               leftD2="14%"
               leftM={inactive ? '7%' : '11%'}
               marginM="-5%"
               marginTopmd={modal ? '7%' : '10%'}
               leftM2={inactive ? '7%' : '11%'}
            >
               <Images
                  src={logoC}
                  top="9px"
                  topweb="9px"
                  left="-8px"
                  leftw="-9px"
                  width="18px"
                  position="absolute"
                  className="img-fluid"
               ></Images>
            </CoruCoach>
         </Col3>
      </Fragment>
  );
}

export default ImageCoachInicio;
