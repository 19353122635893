import React, { Fragment, useState, useEffect } from 'react';
import '../../assets/styles/perfil/perfiles.css';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useMutation } from '@apollo/client';
// Modal
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import { setMongoInfoBinacle } from '../../Helpers/HelperBinacle';
import { insertOrUpdateUserDetail } from '../../Helpers/HelperUserDetail';
import {
  DYNAMIC_GET, UPDATE_USERS, DYNAMIC_INSERT, UPDATE_SESSION, DYNAMIC_GET_MONGO,
} from '../../mutations';
import {
  Container, Col12, Col6, SubtitleOne, Button, ParagrapTwo,
} from '../common/common.styled';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function toUpperCase(origin) {
  origin.value = origin.value.toUpperCase();
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

// END MODAL

function FormsMore() {
  const [MaritalCombo] = useState([]);
  const [Dependents] = useState([]);
  const [Levels] = useState([]);
  const [States] = useState([]);
  const [Occupations] = useState([]);
  const [buttonDisabled] = useState(false);

  const Countrys = [
    {
      value: 'México',
      label: 'México',
    },
  ];

  const Entrys = [
    {
      value: 'menos_4000',
      label: 'Menos de $4,000',
    },
    {
      value: 'de_4000_a_7000',
      label: 'De $4,000 a $7,000',
    },
    {
      value: 'de_7000_a_15000',
      label: 'De $7,000 a $15,000',
    },
    {
      value: 'de_15000_a_30000',
      label: 'De $15,000 a $30,000',
    },
    {
      value: 'mas_de_30000',
      label: 'Más de $30,000',
    },
  ];

  const [dynamicUpdate] = useMutation(UPDATE_USERS, {
    onCompleted({ dynamicUpdate }) {
      console.log(dynamicUpdate);
    },
  });

  const [getUserDetail] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        return userDetail;
      }
    },
  });

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  const [updateBinacle] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [getBinacle] = useMutation(DYNAMIC_GET_MONGO, {
    onCompleted({ dynamicMongoSessionGet }) {
      const binacle = JSON.parse(dynamicMongoSessionGet.response);
      sessionStorage.setItem('id_binacle', binacle._id);
      const keys = [
        {
          column: 'MaritalStatus',
          form: 'maritalStatus',
        },
        {
          column: 'Ocupacion',
          form: 'occupation',
        },
        {
          column: 'PaisDeNacimiento',
          form: 'country',
        },
        {
          column: 'BirthState',
          form: 'state',
        },
        {
          column: 'UltimoGradoDeEstudios',
          form: 'levelStudy',
        },
        {
          column: 'NumeroDeDependientes',
          form: 'dependents',
        },
        {
          column: 'IngresoMensualFijo',
          form: 'entry',
        },
        {
          column: 'Curp',
          form: 'curp',
        },
        {
          column: 'RFC',
          form: 'rfc',
        },
        {
          column: 'NumeroINE',
          form: 'ine',
        },
        {
          column: 'AnioDeVencimientoOCR',
          form: 'expiration',
        },
      ];
      setMongoInfoBinacle(formMore, updateBinacle, binacle, keys);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getMarital] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => MaritalCombo.push({ value: element.QuestionOption, label: element.QuestionOption }),
        );
      }
    },
  });
  const [getDependents] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => Dependents.push({ value: element.QuestionOption, label: element.QuestionOption }),
        );
      }
    },
  });

  const [getLevels] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => Levels.push({ value: element.QuestionOption, label: element.QuestionOption }),
        );
      }
    },
  });

  const [getStates] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => States.push({ value: element.QuestionOption, label: element.QuestionOption }),
        );
      }
    },
  });

  const [getOccupations] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => Occupations.push({ value: element.QuestionOption, label: element.QuestionOption }),
        );
      }
    },
  });

  const [getEntrys] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        userDetail.forEach(
          (element) => Entrys.push({ value: element.QuestionOption, label: element.QuestionOption }),
        );
      }
    },
  });

  const guardarMore = async (e) => {
    e.preventDefault();
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    sessionStorage.setItem('maritalStatus', formMore.maritalStatus);
    sessionStorage.setItem('occupation', formMore.occupation);
    sessionStorage.setItem('country', formMore.country);
    sessionStorage.setItem('state', formMore.state);
    sessionStorage.setItem('levelStudy', formMore.levelStudy);
    sessionStorage.setItem('dependents', formMore.dependents);
    sessionStorage.setItem('entry', formMore.entry);
    sessionStorage.setItem('curp', formMore.curp);
    sessionStorage.setItem('rfc', formMore.rfc);
    sessionStorage.setItem('ine', formMore.ine);
    sessionStorage.setItem('expiration', formMore.expiration);
    const catContactUpdateInput = {
      input: {
        table: 'CatContact',
        columns: [
          {
            setkey: 'MaritalStatus',
            setval: formMore.maritalStatus,
          },
          {
            setkey: 'BirthState',
            setval: formMore.state,
          },
          {
            setkey: 'RFC',
            setval: formMore.rfc,
          },
        ],
        conditions: [{
          valone: 'IDCont',
          valtwo: idCont,
          condition: '=',
        }],
      },
    };
    await dynamicUpdate({ variables: catContactUpdateInput });
    const columnsSet = [
      {
        setkey: '4',
        setval: formMore.occupation,
      },
      {
        setkey: '3',
        setval: formMore.levelStudy,
      },
      {
        setkey: '6',
        setval: formMore.dependents,
      },
      {
        setkey: '20',
        setval: formMore.entry,
      },
      {
        setkey: '45',
        setval: formMore.curp,
      },
      {
        setkey: '135',
        setval: formMore.ine,
      },
      {
        setkey: '69',
        setval: formMore.expiration,
      },
    ];
    insertOrUpdateUserDetail(columnsSet, getUserDetail, dynamicInsert, dynamicUpdate);
    const getBinacleInput = {
      input: {
        collection: 'binacle',
        filter: JSON.stringify(
          {
            idCont: parseInt(sessionStorage.getItem('idCont')),
          },
        ),
      },
    };
    getBinacle({ variables: getBinacleInput });
    handleOpen();
  };

  useEffect(() => {
    async function getData() {
      const getDynamicInput = {
        input: {
          table: 'CatQuestionsDetail',
          columns: ['QuestionOption'],
          conditions: [
            {
              valone: 'CatQuestions_IDCatQuestions',
              condition: '=',
              valtwo: '19',
            },
          ],
        },
      };

      await getMarital({ variables: getDynamicInput });
      getDynamicInput.input.conditions[0].valtwo = '20';
      await getDependents({ variables: getDynamicInput });
      getDynamicInput.input.conditions[0].valtwo = '15';
      getDynamicInput.input.conditions[0].valtwo = '45';
      await getOccupations({ variables: getDynamicInput });
      getDynamicInput.input.conditions[0].valtwo = '18';
      await getLevels({ variables: getDynamicInput });
      getDynamicInput.input.conditions[0].valtwo = '17';
      await getStates({ variables: getDynamicInput });
      getDynamicInput.input.conditions[0].valtwo = '17';
      await getStates({ variables: getDynamicInput });
    }
    getData();
  }, []);

  const [formMore, SetFormMore] = useState({
    maritalStatus: sessionStorage.getItem('MaritalStatus') === null || sessionStorage.getItem('MaritalStatus') === '' || sessionStorage.getItem('MaritalStatus') === undefined ? '' : sessionStorage.getItem('MaritalStatus'),
    occupation: sessionStorage.getItem('occupation') === null || sessionStorage.getItem('occupation') === undefined ? '' : sessionStorage.getItem('occupation'),
    country: 'México',
    state: sessionStorage.getItem('BirthState') === null || sessionStorage.getItem('BirthState') === undefined ? '' : sessionStorage.getItem('BirthState'),
    levelStudy: sessionStorage.getItem('levelStudy') === null || sessionStorage.getItem('levelStudy') === undefined ? '' : sessionStorage.getItem('levelStudy'),
    dependents: sessionStorage.getItem('dependents') === null || sessionStorage.getItem('dependents') === undefined ? '' : String(sessionStorage.getItem('dependents')),
    entry: sessionStorage.getItem('entry') === null || sessionStorage.getItem('entry') === undefined ? '' : sessionStorage.getItem('entry'),
    curp: sessionStorage.getItem('curp') === null ? '' : sessionStorage.getItem('curp'),
    rfc: sessionStorage.getItem('RFC') === null ? '' : sessionStorage.getItem('RFC'),
    ine: sessionStorage.getItem('ine') === null ? '' : sessionStorage.getItem('ine'),
    expiration: sessionStorage.getItem('expiration') === null || sessionStorage.getItem('expiration') === undefined ? '' : sessionStorage.getItem('expiration'),
  });
  const [errorMore] = useState({
    maritalStatus: false,
    occupation: false,
    country: false,
    state: false,
    levelStudy: false,
    dependents: false,
    entry: false,
    curp: false,
    rfc: false,
    ine: false,
    expiration: false,
    maritalStatusValid: sessionStorage.getItem('MaritalStatus') === null || sessionStorage.getItem('MaritalStatus') === '' || sessionStorage.getItem('MaritalStatus') === undefined || sessionStorage.getItem('MaritalStatus').trim() === '' ? -1 : 0,
    occupationValid: sessionStorage.getItem('occupation') === null ? -1 : 0,
    countryValid: 0,
    stateValid: sessionStorage.getItem('BirthState') === null || sessionStorage.getItem('BirthState') === '' ? -1 : 0,
    levelStudyValid: sessionStorage.getItem('levelStudy') === null || sessionStorage.getItem('levelStudy') === '' ? -1 : 0,
    dependentsValid: sessionStorage.getItem('dependents') === null || sessionStorage.getItem('dependents') === '' ? -1 : 0,
    entryValid: sessionStorage.getItem('entry') === null || sessionStorage.getItem('entry') === '' ? -1 : 0,
    curpValid: sessionStorage.getItem('curp') === null || sessionStorage.getItem('curp') === '' ? -1 : 0,
    rfcValid: sessionStorage.getItem('rfc') === null || sessionStorage.getItem('rfc') === '' ? -1 : 0,
    ineValid: sessionStorage.getItem('ine') === null || sessionStorage.getItem('ine') === '' ? -1 : 0,
    expirationValid: sessionStorage.getItem('expiration') === null || sessionStorage.getItem('expiration') === undefined || sessionStorage.getItem('expiration') === '' || sessionStorage.getItem('expiration').trim() === '' ? -1 : 0,

  });

  console.log(errorMore.maritalStatusValid);

  const [texterrorMore] = useState({
    maritalStatus: '',
    occupation: '',
    country: '',
    state: '',
    levelStudy: '',
    dependents: '',
    entry: '',
    curp: '',
    rfc: '',
    ine: '',
    expiration: '',
  });

  const handleChangeMore = (e) => {
    SetFormMore({
      ...formMore,
      [e.target.name]: e.target.value,
    });
    const { name } = e.target;
    errorMore[name] = false;
    errorMore[`${name}Valid`] = 0;
    console.log('changing');
  };

  let yeasArray2 = 2020;
  const Expirations = [];
  for (let i = 0; i < 10; i++) {
    yeasArray2++;
    Expirations.push({
      value: yeasArray2,
      label: yeasArray2,
    });
  }

  // MODAL
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ModalMessage = () => (
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo fontWeight="700" cursor="pointer" texttalign ="right" textalignMobile="right" className='mx-auto' onClick={handleClose}>X</ParagrapTwo>
                    <SubtitleOne mt="3%" className='font-weight-bold' sizeMobile="20px" size="20px">
                    </SubtitleOne>
                    <ParagrapTwo className='mx-auto' mb="0px">Tus cambios se han guardado con éxito.</ParagrapTwo>
                    <Button className='d-block mt-5 mt-md-4 mb-5' onClick={handleClose}>
                        Aceptar
                    </Button>
                </div>
            </Modal>
  );

  const informacion = useState('Si tiene una ine se encuentra en la parte inferior trasera de la credencial son los 9 primeros digitos');

  return (
        <Fragment>
            <Container width='94%' mt='1rem' widthMobile='100%' mtMobile='2rem'float="auto!important" bb="1px solid #D6D6D6" mb="5%" >
                <Col12>
                    <form autoComplete='off' className='' onSubmit={guardarMore}>
                        <Container width='100%' widthMobile='100%'>
                            <Col6 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                                <TextField
                                    id="maritalStatus"
                                    name="maritalStatus"
                                    select
                                    label="Estado civil"
                                    fullWidth
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.maritalStatus}
                                    variant="outlined"
                                    value={formMore.maritalStatus ? formMore.maritalStatus : ''}
                                    inputProps={{ className: errorMore.maritalStatusValid == 1 ? 'perfil invalidSelect' : errorMore.maritalStatusValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                    {MaritalCombo.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col6>
                            <Col6 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                                <TextField
                                    id="occupation"
                                    name="occupation"
                                    select
                                    label="Ocupación"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.occupation}
                                    value={formMore.occupation ? formMore.occupation : ''}
                                    inputProps={{ className: errorMore.occupationValid == 1 ? 'perfil invalidSelect2' : errorMore.occupationValid == 0 ? 'perfil validSelect2' : 'perfil' }}
                                    >
                                    {Occupations.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col6>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="country"
                                    name="country"
                                    select
                                    label="País de nacimiento"
                                    fullWidth
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.country}
                                    value={formMore.country ? formMore.country : ''}
                                    inputProps={{ className: errorMore.countryValid == 1 ? 'perfil invalidSelect' : errorMore.countryValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    variant="outlined"
                                    >
                                    {Countrys.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                            <TextField
                                    id="state"
                                    name="state"
                                    select
                                    label="Estado de nacimiento"
                                    fullWidth
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.state}
                                    value={formMore.state ? formMore.state : ''}
                                    inputProps={{ className: errorMore.stateValid == 1 ? 'perfil invalidSelect' : errorMore.stateValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    variant="outlined"
                                    >
                                    {States.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="levelStudy"
                                    name="levelStudy"
                                    select
                                    label="Último grado de estudios"
                                    fullWidth
                                    onChange={handleChangeMore}
                                    variant="outlined"
                                    helperText={texterrorMore.levelStudy}
                                    value={formMore.levelStudy ? formMore.levelStudy : ''}
                                    inputProps={{ className: errorMore.levelStudyValid == 1 ? 'perfil invalidSelect' : errorMore.levelStudyValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                    {Levels.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="dependents"
                                    name="dependents"
                                    select
                                    label="No. de dependientes económicos"
                                    fullWidth
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.dependents}
                                    value={formMore.dependents ? formMore.dependents : ''}
                                    className = {errorMore.dependentsValid === 1 ? 'invalidSelect' : errorMore.dependentsValid === 0 ? 'validSelect' : ' '}
                                    variant="outlined"
                                    inputProps={{ className: errorMore.dependentsValid == 1 ? 'perfil invalidSelect' : errorMore.dependentsValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                    {Dependents.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="entry"
                                    name="entry"
                                    select
                                    label="Ingreso mensual fijo"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.entry}
                                    value={formMore.entry ? formMore.entry : ''}
                                    inputProps={{ className: errorMore.entryValid == 1 ? 'perfil invalidSelect' : errorMore.entryValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                    {Entrys.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="curp"
                                    name="curp"
                                    label="CURP"
                                    fullWidth
                                    onChange={(e) => {
                                      handleChangeMore(e);
                                      toUpperCase(e.target);
                                    }}
                                    variant="outlined"
                                    helperText={texterrorMore.curp}
                                    value={formMore.curp ? formMore.curp.toUpperCase() : ''}
                                    inputProps={{ className: 'Uppercase', maxLength: 18, className: errorMore.curpValid == 1 ? 'perfil invalidSelect' : errorMore.curpValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="rfc"
                                    name="rfc"
                                    label="RFC"
                                    fullWidth
                                    onChange={(e) => {
                                      handleChangeMore(e);
                                      toUpperCase(e.target);
                                    }}
                                    variant="outlined"
                                    helperText={texterrorMore.rfc}
                                    value={formMore.rfc}
                                    inputProps={{ className: 'Uppercase', maxLength: 13, className: errorMore.rfcValid == 1 ? 'perfil invalidSelect' : errorMore.rfcValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                </TextField>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <Tooltip title={informacion}>
                                    <TextField
                                        id="ine"
                                        name="ine"
                                        label="No. de identificación (INE)"
                                        fullWidth
                                        onChange={handleChangeMore}
                                        helperText={texterrorMore.ine ? texterrorMore.ine : ''}
                                        value={formMore.ine}
                                        variant="outlined"
                                        inputProps={{ className: 'Uppercase', maxLength: 18, className: errorMore.ineValid == 1 ? 'perfil invalidInput' : errorMore.ineValid == 0 ? 'perfil validInput' : 'perfil' }}
                                        >
                                    </TextField>
                                </Tooltip>
                            </Col12>
                            <Col12 mt="7%!important" padding="0px" pMobile="0px">
                                <TextField
                                    id="expiration"
                                    name="expiration"
                                    select
                                    label="Año vencimiento ID"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangeMore}
                                    helperText={texterrorMore.expiration}
                                    value={formMore.expiration ? formMore.expiration : ''}
                                    inputProps={{ className: errorMore.expirationValid == 1 ? 'perfil invalidSelect' : errorMore.expirationValid == 0 ? 'perfil validSelect' : 'perfil' }}
                                    >
                                    {Expirations.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col12>
                        </Container>
                        <Button disabled={buttonDisabled} mt="2.5rem!important" type='submit' className='d-block mt-5 mt-md-4 mb-5 roboto' label='Guardar'>
                            Guardar
                        </Button>
                    </form>
                </Col12>
            </Container>
            <ModalMessage/>
        </Fragment>
  );
}
export default FormsMore;
