/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
import React, { Fragment, useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import TagManager from "react-gtm-module";
import { parseInt } from "lodash-es";
import image1 from "../assets/img/anacoach/mejor tarjeta.jpg";
import {
  Feed_Main,
  Feed_Text,
  Feed_Section,
  Top_Text,
  SeparateSection,
} from "../assets/styles/Feed.styled";
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
  ContainerBottons,
} from "../components/common/Home.styled";
import CaretUp from "../assets/img/home/caretup.png";
import { Title_h1 } from "../assets/styles/Home.styled";
import { Image } from "../components/common/common.styled";
import "../assets/styles/Home.css";
import GeneralLoader from "../components/common/GeneralLoader";
import VideoSection from "../components/Feed/Section_Video";
import ListItems from "../components/Feed/One_Training";
import ProductsSection from "../components/Feed/Products";
import FooterFeed from "../components/Feed/Footer_Feed";
import GeneralHooks from "../Helpers/HookHelpersGeneral";
import {
  GET_TRAINING_CATEGORIES,
  GET_TRAININGS,
  GET_USER_TRAININGS,
} from "../queries";
import {
  DYNAMIC_INSERT,
  DYNAMIC_GET_MONGO,
  GET_RECOMMENDATIONS,
  DYNAMIC_GET,
  CREATE_SESSION,
} from "../mutations";
import Header from "../components/common/Header";
import ShareCommonFeed from "./Training/ShareCommonFeed";
import CategoriasView from "../components/Feed/Categorias";
import SendNotificationFTAndCC from "../components/common/SendNotificationFTAndCC";
import SendNotificationEmail from "../components/common/SendNotificationEmail";
import Kanda from "../components/Kanda/Kanda";
import "../assets/styles/kanda/KandaDesktop.css";
import ShareWin from "../components/common/BannerRewards";
import { Hidden } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {getQueryVariableHashtagDeeplink} from '../Helpers/index';

function Feed() {
  const [stateInformativo, setInformativo] = useState(false);
  const [stateOutstanding, setOutstanding] = useState(false);
  const [orderRecomienda, setOrderRecomienda] = useState([]);
  const { actions } = GeneralHooks();
  const { sendMessage } = SendNotificationFTAndCC();
  const { sendEmail } = SendNotificationEmail();
  const [categories, setCategories] = useState([]);
  const [getCategories] = useLazyQuery(GET_TRAINING_CATEGORIES, {
    fetchPolicy: "cache-and-network",
    onCompleted({ getCategories }) {
      setCategories(JSON.parse(getCategories.response));
    },
    onError(err) {
      console.log('Error con categories: ', err);
    },
  });

  useEffect(() => {
    const idUser = sessionStorage.getItem("idUser") || "";
    const idCont = sessionStorage.getItem("idCont") || "";
    if (idUser === "" || idCont === "") {
      const { idUser, idCont } = getQueryVariableHashtagDeeplink("profile");
      if (idUser && idUser !== "" && idCont && idCont !== "") {
        sessionStorage.setItem("idUser", idUser);
        sessionStorage.setItem("idCont", idCont);
      } else {
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    sendMessage({
      cellPhone: sessionStorage.getItem("CellPhone") || "",
      trigger: "wa_cc_1",
    });
    sendEmail({ WO: "CC", trigger: "alerta_cc_1_mail" });
    setTimeout(
      () => sendEmail({ WO: "FastTrack", trigger: "email_FT1" }),
      2500
    );
    actions({ action: "getAchievment", variables: "" });
    getCategories();
    sessionStorage.setItem("originFeed", true);
    if (sessionStorage.getItem('idTraining')) {
      sessionStorage.removeItem('idTraining');
    }
    sessionStorage.setItem('BannerRewards', 'BannerRewards_Entrenamientos');
  }, []);
  const [getTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response);
        const idTrainning = [];
        const nameTraining = [];
        for (let i = 0; i < userDetail.length; i += 1) {
          idTrainning.push(userDetail[i].IDCatTraining);
          nameTraining.push(userDetail[i].TrainingBigTitle);
        }
        sessionStorage.setItem("openIdTrainning", idTrainning);
        sessionStorage.setItem("openNameTraining", nameTraining);
        const tagManagerArgs = {
          dataLayer: {
            event: window.location.pathname,
            pageName: window.location.pathname,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    },
  });

  useEffect(() => {
    const getTrainingInput = {
      input: {
        table:
          "CatRelUsersTraining as crut, DatTraining as dt, CatTraining as ct",
        columns: ["TrainingBigTitle", "ct.IDCatTraining"],
        conditions: [
          {
            valone: "IDUserCollaborative",
            condition: "=",
            valtwo: sessionStorage.getItem("idUser"),
          },
          {
            logic: "AND",
            valone: "dt.IDDatTraining",
            condition: "=",
            valtwo: "crut.IDDatTraining",
            validColumn: true,
          },
          {
            logic: "AND",
            valone: "ct.IDCatTraining",
            condition: "=",
            valtwo: "dt.CatTraining_IDTraining",
            validColumn: true,
          },
          {
            logic: "AND",
            valone: "Achievment",
            condition: "!=",
            valtwo: "100",
          },
        ],
      },
    };
    getTraining({ variables: getTrainingInput });

    if (
      !sessionStorage.getItem("id_binacle") &&
      sessionStorage.getItem("idCont")
    ) {
      const getBinacleInput = {
        input: {
          collection: "binacle",
          filter: JSON.stringify({
            idCont: parseInt(sessionStorage.getItem("idCont")),
          }),
        },
      };
      getBinacle({ variables: getBinacleInput });
    }

    if (!sessionStorage.getItem("Email1" != null)) {
      getRecomienda({ variables: { email: sessionStorage.getItem("Email1") } });
    }
  }, []);
  sessionStorage.setItem("clickEmpezarAhoraHome", "true");

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem("id_binacle", dynamicMongoSession.response);
      }
    },
  });
  const [getBinacle] = useMutation(DYNAMIC_GET_MONGO, {
    onCompleted({ dynamicMongoSessionGet }) {
      const binacle = JSON.parse(dynamicMongoSessionGet.response);
      if (binacle) {
        sessionStorage.setItem("id_binacle", binacle._id);
      } else if (
        sessionStorage.getItem("idCont") &&
        sessionStorage.getItem("idUser")
      ) {
        const timestap = new Date().toLocaleString();
        const mongoInputBinacle = {
          idCont: sessionStorage.getItem("idCont"),
          idUser: sessionStorage.getItem("idUser"),
          FirstName: [
            {
              value: sessionStorage.getItem("firstName"),
              timestap,
            },
          ],
          MiddleName: [
            {
              value: sessionStorage.getItem("middleName"),
              timestap,
            },
          ],
          FathersLastName: [
            {
              value: sessionStorage.getItem("fathersLastName"),
              timestap,
            },
          ],
          MothersLastName: [
            {
              value: sessionStorage.getItem("mothersLastName"),
              timestap,
            },
          ],
          PreferredPhone: [
            {
              value: sessionStorage.getItem("cellPhone"),
              timestap,
            },
          ],
          email: [
            {
              value: sessionStorage.getItem("email"),
              timestap,
            },
          ],
          profile: [
            {
              value: sessionStorage.getItem("typeUser"),
              timestap,
            },
          ],
          lastUpdate: timestap,
        };
        const dataStringBinacle = JSON.stringify(mongoInputBinacle);
        const mongoInput = {
          input: {
            collection: "binacle",
            data: dataStringBinacle,
          },
        };
        createSession({ variables: mongoInput });
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getRecomienda] = useMutation(GET_RECOMMENDATIONS, {
    onCompleted({ getPersonalizeRecommendation }) {
      setOrderRecomienda(getPersonalizeRecommendation.itemList);
    },
    onError(err) {
      console.log(err);
    },
  });

  // Syles
  useEffect(() => {
    document.querySelector("#root").style.backgroundColor = "#fafafa";
  });

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const [selectedCatego, setSelectedCatego] = useState(0);

  useEffect(() => {
    gsap.to(window, {
      duration: 1,
      scrollTo: `#categorieSelectedOption${selectedCatego}`,
    });
  }, [selectedCatego]);

  useEffect(() => {
    const variable = parseInt(sessionStorage.getItem("categorieActive"));
    if (variable) {
      setSelectedCatego(variable);
      sessionStorage.removeItem("categorieActive");
    }
  });

  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message !== "success") {
        console.log("");
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  // const PrimerVis = sessionStorage.getItem('PrimerVis') === 'true' || false;
  const trainingBig = []; // big

  const trainingProcess = []; // en proceso

  const trainingVertical = []; // vertical cc

  const items4 = []; // entrenamiento destacado

  const itemsMore = []; // informativos

  const itemsRecomienda = []; // big ordenadas

  const categoriastrainings = []; // categorias de entrenamientos

  const trainingTwin = []; // tiwn
  const idUser = sessionStorage.getItem("idUser") || 0;
  const vars = {
    variables: { idUser: parseInt(idUser) },
    fetchPolicy: "no-cache",
  };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);
  const { data: dataUser } = useQuery(GET_USER_TRAININGS, vars);

  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (validateInformativo.indexOf(idTrainning) === -1) {
      const sectionInformativo = {
        id: "",
        idTrainning: "",
        name: "",
        parrafo1: "",
        color: "#fff",
        Liked: "",
        image: image1,
        Show: true,
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      sectionInformativo.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionInformativo.Show = arrValue.Show || true;
      sectionInformativo.color = arrValue.TrainingColor
        ? arrValue.TrainingColor
        : "#6BD5AF";
      sectionInformativo.category = arrValue.IDCategory;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
      if (itemsMore.length >= 1 && !stateInformativo) {
        setInformativo(true);
      }
    }
    itemsMore.sort((x) => (x.Show ? -1 : 1));
  };

  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (validateOutstanding.indexOf(idTrainning) === -1) {
      const sectionOutstanding = {
        id: "",
        idTrainning: "",
        name: "",
        parrafo1: "",
        color: "#fff",
        Liked: "",
        image: "",
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionOutstanding.Liked = arrValue.Liked;
      sectionOutstanding.Liked = arrValue.Show;
      // items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
      if (items4.length >= 1 && !stateOutstanding) {
        setOutstanding(true);
      }
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: "",
        idTrainning: "",
        name: "",
        parrafo1: "",
        color: "#fff",
        image: "",
        Liked: "",
        Show: true,
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      sectionVertical.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionVertical.Show = arrValue.Show || true;
      sectionVertical.color = arrValue.TrainingColor;
      console.log({ sectionVertical, TrainingColor: arrValue.TrainingColor });
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
    trainingVertical.sort((x) => (x.Show ? -1 : 1));
  };

  if (
    sessionStorage.getItem("FirstName") !== undefined &&
    sessionStorage.getItem("FathersLastName") !== undefined &&
    sessionStorage.getItem("day") !== undefined
  ) {
    sessionStorage.setItem("DataPerson", true);
  } else {
    sessionStorage.setItem("DataPerson", false);
  }

  const OrderRecomendado = () => {
    console.log('Orden Recomienda >>>', orderRecomienda);
    if (orderRecomienda.length !== 0) {
      for (const i in orderRecomienda) {
        for (const a in trainingBig) {
          if (
            parseInt(orderRecomienda[i].itemId) ===
              trainingBig[a].idTrainning &&
            itemsRecomienda.length < 3
          ) {
            itemsRecomienda.push(trainingBig[a]);
          }
        }
      }
      console.log('New Array >>>', itemsRecomienda);
    } else {
      for (const a in trainingBig) {
        if (itemsRecomienda.length < 3) {
          itemsRecomienda.push(trainingBig[a]);
          itemsRecomienda.sort((x) => (x.Show ? -1 : 1));
        }
      }
    }
  };

  if (loading) return <GeneralLoader></GeneralLoader>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining && dataUser) {
    const trainingList = dataTraining.getTrainings;
    const userTrainingList = dataUser.getUserTrainings;
    const validateBig = [];
    const validateTwin = [];
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      if (training.TrainingType === "Informativo") {
        pushInformativo(training);
      }
      switch (training.Section) {
        case "Big":
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          pushInformativo(training);
          break;
        case "Medium":
          // items2.push(sectionMedium);
          break;
        case "Twin":
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default:
          break;
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === "Tarjetas de crédito") {
        pushCCVertical(training);
      }
    });
    const validateUserTrainings = [];
    userTrainingList.forEach((training) => {
      if (validateUserTrainings.indexOf(training.IDCatTraining) === -1) {
        if (training.Achievment !== 100) {
          trainingProcess.push(mapPayload(training));
        }
        if (training.TrainingType === "Informativo") {
          pushInformativo(training);
        }
        if (training.Outstanding === "1") {
          pushOutstanding(training);
        }
        if (training.VerticalName === "Tarjetas de crédito") {
          pushCCVertical(training);
        }
      }
    });

    const Capitalize = (word) => {
      if (word !== "" && word != null) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return "";
    };

    // Variable for User personal data
    const user = {
      name:
        Capitalize(sessionStorage.getItem("FirstName")) ||
        sessionStorage.getItem("firstName"),
    };

    // Visual effects
    const scrollToTop = () => {
      document.querySelector("#root").scrollIntoView({
        behavior: "smooth",
      });
    };
    let par = 0;
    // const widthBrowser = window.outerWidth;

    const Categorizacion = () => {
      for (let i = 0; i < categories.length; i += 1) {
        const categoria = categories[i];
        const result = itemsMore.filter(
          (training) => training.category === categoria.idCatCategory
        );

        const sectionInformativo = {
          id: "",
          trainnings: "",
          nameCategory: "",
          colorCategory: "#fff",
          imagen: "",
          showCategory: false,
        };
        sectionInformativo.id = categoria.idCatCategory;
        sectionInformativo.nameCategory = categoria.NameCategory;
        sectionInformativo.colorCategory = categoria.color;
        sectionInformativo.trainnings = result;
        sectionInformativo.imagenCategory = categoria.ImageURL;
        categoriastrainings.push(sectionInformativo);
      }
    };

    // Order Amazon
    OrderRecomendado();
    Categorizacion();
    return (
      <Fragment>
        <Helmet>
          <title>Soluciones rápidas | CORU</title>
        </Helmet>
        <Feed_Main width="100%">
          <Header
            url={window.location.pathname}
            pathname={window.location.pathname}
            background={"#fafafa"}
            optionSelected={selectedCatego}
            setOptionSelected={setSelectedCatego}
          />
          <Feed_Main width="80%">
            <Feed_Section
              className="cuadro-externo"
              back="background-color: rgb(250, 250, 250)"
            >
              <VideoSection
                time="3"
                text="Conoce más de Ana Coach"
              ></VideoSection>
            </Feed_Section>
          </Feed_Main>
          <Feed_Main width="100%" backWeb="transparent">
            <Feed_Main width="80%" display="flex" backWeb="transparent">
              <Feed_Section
                padding="5% 5% 5% 5%"
                paddingm="5% 5% 5% 5%"
                position="relative"
              >
                <ContentHomeSection leftD="0%" floatD="" width="100%;">
                  <Title_h1
                    top="1.2%"
                    topM="10%"
                    align="left"
                    talign="left"
                    className="roboto"
                  >
                    Bienvenido
                  </Title_h1>
                  <Feed_Text
                    className="roboto"
                    bottom="0%"
                    align="left"
                    maxwidth="569px"
                  >
                    <b>{user.name}</b>, aquí encontrarás los entrenamientos que
                    te ayudarán a mejorar tu vida financiera.
                  </Feed_Text>
                </ContentHomeSection>
              </Feed_Section>
              <Rectangle24
                positionD="absolute"
                Ptop="80px"
                Pleft="93%"
                background="#BFDD6A"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="30.94px"
                height="30.94px"
                transform="rotate(46.41deg)"
              />
              <Vector2
                width="70px"
                left="0"
                height="80px"
                background="#6BD5AF"
                radius="40%"
                transform="rotate(-8.34deg)"
                positionD="absolute"
                Pleft="92%"
                Ptop="119px"
                displayM="none"
              />
              <Vector3
                position="absolute"
                left=" "
                top=" "
                Ptop="187px"
                Pleft="93%"
                background="#E3E660"
                transform="rotate(-15.06deg)"
                width="100px"
                height="100px"
                displayD=" "
                displayM="none"
              />
            </Feed_Main>
          </Feed_Main>
          <Fragment>
            <Feed_Main
              width="100%"
              backWeb="rgba(243, 243, 243, .5)"
              background="#F3F3F3"
              style={{ zIndex: "2" }}
            >
              <Feed_Main
                width="80%"
                display="flex"
                backWeb="rgba(243, 243, 243, .5)"
                background="#F3F3F3"
                position="relative"
              >
                <div style={{ paddingTop: "20%" }}>
                  <Rectangle24
                    positionD="absolute"
                    Ptop="235px"
                    Pleft="67%"
                    background="#FFF"
                    displayM="none"
                    displayD=" "
                    left=" "
                    top=" "
                    width="60px"
                    height="60px"
                    transform="rotate(46.1deg)"
                    style={{ zIndex: "0" }}
                  />
                  <Vector2
                    width="70px"
                    left="0"
                    martop="25%"
                    height="80px"
                    background="#FFF"
                    radius="40%"
                    transform="rotate(-8.34deg)"
                    positionD="absolute"
                    Pleft="92%"
                    Ptop="5px"
                    displayM="none"
                    style={{ zIndex: "0" }}
                  />
                  <Vector3
                    position="absolute"
                    left=" "
                    top=" "
                    Ptop="423px"
                    Pleft="50%"
                    background="#FFF"
                    transform="rotate(-43.19deg)"
                    width="150px"
                    height="150px"
                    displayD=" "
                    displayM="none"
                    style={{ zIndex: "0" }}
                  />
                  <Rectangle24
                    positionD="absolute"
                    Pleft="90%"
                    Ptop="463px"
                    displayD=""
                    width="150px"
                    height="150px"
                    left=" "
                    top=" "
                    background="#FFF"
                    transform="rotate(-17.24deg)"
                    style={{ zIndex: "-1" }}
                  />
                </div>
                <Feed_Section padding="5% 5% 5% 5%" paddingm="5% 5% 5% 5%">
                  <ContentHomeSection
                    leftD="0%"
                    floatD="left"
                    width="50%;"
                    className="mob"
                  >
                    <Title_h1
                      fz="40px"
                      topM="0%"
                      top="0%"
                      align="left"
                      talign="left"
                      className="roboto"
                    >
                      Entrenamientos
                    </Title_h1>
                    <Feed_Text className="roboto" bottom="5%">
                      Conoce los entrenamientos que tenemos para ayudarte a
                      cumplir tus objetivos.
                    </Feed_Text>
                  </ContentHomeSection>
                  <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                    {categoriastrainings.map((post) => (
                      <CategoriasView
                        key={post.id}
                        color={post.colorCategory}
                        name={post.nameCategory}
                        imagen={post.imagenCategory}
                        trainings={post.trainnings}
                        dynamicInsert={dynamicInsert}
                        show={selectedCatego === post.id}
                        id={post.id}
                        isHome={false}
                      />
                    ))}
                  </ContentHomeSection>
                  <ContentHomeSection
                    leftD="0%"
                    floatD="left"
                    width="50%;"
                    className="mob"
                    id="KandaFeed"
                  >
                    <Kanda goToDiagnostico="DiagnosticoX" />
                  </ContentHomeSection>
                  <ContentHomeSection
                    leftD="0%"
                    floatD="left"
                    width="50%;"
                    className="web"
                  >
                    <div style={{ display: "block" }}>
                      <Title_h1
                        fz="40px"
                        topM="0%"
                        top="0%"
                        align="left"
                        talign="left"
                        className="roboto"
                      >
                        Entrenamientos
                      </Title_h1>
                      <Feed_Text className="roboto" bottom="5%">
                        Conoce los entrenamientos que tenemos para ayudarte a
                        cumplir tus objetivos.
                      </Feed_Text>
                    </div>
                  </ContentHomeSection>
                  <ContentHomeSection
                    width="50%"
                    className="web KandaFeedClass"
                  >
                    <div
                      style={{
                        background: "#FFF",
                        borderRadius: "16px",
                        boxShadow: "0px 0px 30px rgba(41, 41, 41, 0.06)",
                        zIndex: "1",
                      }}
                    >
                      <Kanda goToDiagnostico="DiagnosticoX" />
                    </div>
                  </ContentHomeSection>
                </Feed_Section>
              </Feed_Main>
            </Feed_Main>
            <Feed_Main width="100%" background=" ">
              <Feed_Main
                width="80%"
                display=" "
                mtop=" "
                background="transparent"
              >
                <Feed_Section
                  padding="5% 5% 0% 5%"
                  paddingm="5% 5% 0% 5%"
                  mTopWeb="-5%"
                ></Feed_Section>
              </Feed_Main>
            </Feed_Main>
            <SeparateSection></SeparateSection>
          </Fragment>

          {typeof trainingProcess === "object" &&
            trainingProcess.length > 0 &&
            ((par = par === 0 ? 1 : 0),
            (
              <Fragment>
                <Feed_Main width="100%" backWeb="transparent">
                  <Feed_Main width="80%" display="flex" backWeb="transparent">
                    <Feed_Section
                      padding="3% 5% 5% 5%!important"
                      paddingm="5% 5% 0% 5%"
                    >
                      <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                        <Rectangle24
                          positionD="absolute"
                          Ptop="80px"
                          Pleft="93%"
                          background="#BFDD6A"
                          displayM="none"
                          displayD=" "
                          left=" "
                          top=" "
                          width="30.94px"
                          height="30.94px"
                          transform="rotate(46.41deg)"
                        />
                        <Vector2
                          width="70px"
                          left="0"
                          height="80px"
                          background="#6BD5AF"
                          radius="40%"
                          transform="rotate(-8.34deg)"
                          positionD="absolute"
                          Pleft="92%"
                          Ptop="119px"
                          displayM="none"
                        />
                        <Vector3
                          position="absolute"
                          left=" "
                          top=" "
                          Ptop="187px"
                          Pleft="93%"
                          background="#E3E660"
                          transform="rotate(-15.06deg)"
                          width="100px"
                          height="100px"
                          displayD=" "
                          displayM="none"
                        />
                        <Title_h1
                          fz="40px"
                          topM="6%"
                          top="0%"
                          align="left"
                          talign="left"
                          className="roboto"
                        >
                          Entrenamientos en progreso
                        </Title_h1>
                        <Feed_Text className="roboto" bottom="5%">
                          Concluye tus entrenamientos en progreso y sigue
                          mejorando las decisiones financieras que tomas.
                        </Feed_Text>
                      </ContentHomeSection>
                    </Feed_Section>
                  </Feed_Main>
                </Feed_Main>
                <Feed_Main width="100%" background=" ">
                  <Feed_Main
                    width="80%"
                    display=" "
                    mtop=" "
                    background="transparent"
                  >
                    <Feed_Section
                      padding="0% 5% 5% 5%"
                      paddingm="0% 5% 5% 5%"
                      mTopWeb="-5%"
                    >
                      <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                        <ListItems
                          dynamicInsert={dynamicInsert}
                          items={trainingProcess}
                          progress={true}
                        ></ListItems>
                      </ContentHomeSection>
                    </Feed_Section>
                  </Feed_Main>
                </Feed_Main>
                <SeparateSection></SeparateSection>
              </Fragment>
            ))}

          <SeparateSection className="cuadro-externo"></SeparateSection>
          <Feed_Section className="cuadro-externo" padding="5%">
            <VideoSection
              time="3"
              text="Mejores prácticas para los entrenamientos"
            ></VideoSection>
          </Feed_Section>
          <SeparateSection></SeparateSection>
          <Feed_Main width="100%" backWeb=" ">
            <Feed_Main width="80%" display="flex" backWeb=" ">
              <Feed_Section
                padding="5% 5% 0% 5%"
                paddingm="5% 5% 0% 5%"
                className={stateInformativo ? "" : "FeedSectionDisplayHidden"}
                position="relative"
              >
                <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                  <Title_h1
                    fz="40px"
                    align="left"
                    talign="left"
                    className="roboto"
                  >
                    ¿Te gustaría saber más de estos productos?
                  </Title_h1>
                  <Feed_Text className="roboto">
                    Conoce las principales decisiones que deberías tomar si
                    entras al mundo de las tarjetas de crédito.
                  </Feed_Text>
                </ContentHomeSection>
              </Feed_Section>
            </Feed_Main>
          </Feed_Main>
          <Feed_Main width="100%" background=" ">
            <Feed_Main
              width="80%"
              display="block"
              mtop=" "
              background="transparent"
            >
              <Feed_Section
                padding="0% 5% 5% 5%"
                paddingm="0% 5% 5% 5%"
                className={stateInformativo ? "" : "FeedSectionDisplayHidden"}
              >
                <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                  <ProductsSection products={trainingTwin}></ProductsSection>
                </ContentHomeSection>
              </Feed_Section>
            </Feed_Main>
          </Feed_Main>
          <Feed_Section
            position="relative"
            padding="5%"
            className={stateOutstanding ? "" : "FeedSectionDisplayHidden"}
          >
            {(par = par === 0 ? 1 : 0)}
            <ContentHomeSection
              leftD="0%"
              floatD={par === 0 ? "left" : "right"}
              width="40%;"
            >
              <Rectangle24
                positionD="absolute"
                Ptop="5px"
                Pleft="60%"
                background="#F3F3F3"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="60px"
                height="60px"
                transform="rotate(46.1deg)"
              />
              <Vector2
                width="70px"
                left="0"
                height="80px"
                background="#F3F3F3"
                radius="40%"
                transform="rotate(-8.34deg)"
                positionD="absolute"
                Pleft="92%"
                Ptop="5px"
                displayM="none"
              />
              <Vector3
                position="absolute"
                left=" "
                top=" "
                Ptop="187px"
                Pleft="50%"
                background="#F3F3F3"
                transform="rotate(-43.19deg)"
                width="200px"
                height="200px"
                displayD=" "
                displayM="none"
              />
              <Rectangle24
                positionD="absolute"
                Pleft="90%"
                Ptop="300px"
                displayD=""
                width="200px"
                height="200px"
                left=" "
                top=" "
                background="#F3F3F3"
                transform="rotate(-17.24deg)"
              />

              <Title_h1 fz="40px" align="left" talign="left" className="roboto">
                No deberías perderte este entrenamiento
              </Title_h1>
              <Feed_Text className="roboto">
                Sabemos que este entrenamiento podría ayudarte con tus
                decisiones. ¡Descúbrelo ahora!
              </Feed_Text>
            </ContentHomeSection>
            <ContentHomeSection leftD="0%" floatD=" " width="100%">
              <ListItems
                dynamicInsert={dynamicInsert}
                items={items4}
              ></ListItems>
            </ContentHomeSection>
          </Feed_Section>
          <Feed_Main width="100%" backWeb=" ">
            <Feed_Main width="80%" display="flex" backWeb=" ">
              <Feed_Section
                padding="5% 5% 0% 5%"
                paddingm="5% 5% 0% 5%"
                className={stateInformativo ? "" : "FeedSectionDisplayHidden"}
                position="relative"
              >
                <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                  <Title_h1
                    top="20px"
                    fz="40px"
                    align="left"
                    talign="left"
                    className="roboto"
                  >
                    ¿Quieres ayudar a alguien más?
                  </Title_h1>
                </ContentHomeSection>
                <ContentHomeSection leftD="0%" floatD="left" width="50%;">
                  <Rectangle24
                    positionD="absolute"
                    Ptop="145px"
                    Pleft="-11%"
                    background="#91D770"
                    displayM="none"
                    displayD=" "
                    left=" "
                    top=" "
                    width="30.94px"
                    height="30.94px"
                    transform="rotate(46.41deg)"
                  />
                  <Hidden mdUp>
                    <ShareWin
                      bannerType="mobile"
                      margin="20px auto 80px"
                      width="100%"
                      name="Entrenamientos"
                    />
                  </Hidden>
                  <Hidden mdDown>
                    <ShareWin
                      bannerType="wo"
                      margin="20px auto 80px"
                      width="80%"
                      name="Entrenamientos"
                    />
                  </Hidden>
                </ContentHomeSection>
              </Feed_Section>
            </Feed_Main>
          </Feed_Main>
          <ContainerBottons top="30px" position=" " onClick={scrollToTop}>
            <Image
              src={CaretUp}
              width="20px"
              margin="auto"
              onClick={scrollToTop}
            />
          </ContainerBottons>
          <ContainerBottons top="2%" position=" " bottomM="101px" bottomD="0">
            <Top_Text
              onClick={scrollToTop}
              style={{ cursor: "pointer" }}
              className="roboto"
            >
              Volver arriba
            </Top_Text>
          </ContainerBottons>
          {/* <Whatsapp url={""} id="Whatsapp"/> */}
          <FooterFeed
            bott="feed"
            check="entrenamiento"
            style={{ marginBottom: "7rem" }}
          ></FooterFeed>
          {/* <FooterFeedBlue width='80%'></FooterFeedBlue>   */}
        </Feed_Main>
      </Fragment>
    );
  }
  return null;
}

export default Feed;
