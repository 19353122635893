import React from 'react';
import '../../assets/styles/ModalValorDinero.css';
import Shape1 from '../../assets/img/anacoach/Shapes/Rectangle 26.png';
import Shape2 from '../../assets/img/anacoach/Shapes/Rectangle 27.png';
import Shape3 from '../../assets/img/anacoach/Shapes/Vector-2.png';
import Shape4 from '../../assets/img/anacoach/Shapes/Vector-3.png';
import AnaChat from '../../assets/img/anacoach/anavalordinero.png';

const ModalValorDinero = ({
  title, text, showed, setShowed,
}) => (
  <>
    <div className={showed ? 'valor-modal' : 'valor-modal hidden'}>
      <div className="contenedor-valor">
        <div className="valor-header">
          <div className="valor-shapes">
            <img src={Shape4} alt="Shape" className="shape-one" />
            <img src={Shape2} alt="Shape" className="shape-two" />
            <img src={Shape1} alt="Shape" className="shape-three" />
            <img src={Shape2} alt="Shape" className="shape-four" />
            <img src={Shape1} alt="Shape" className="shape-five" />
            <img src={Shape3} alt="Shape" className="shape-six" />
          </div>
          <div className="ana-profile">
            <img src={AnaChat} alt="" />
          </div>
        </div>
        <div className="valor-contenido">
          <div className="titulo">{title}</div>
          <br />
          <div className="contenido">{text}</div>
          <button
            className="btn btn-block btn-success btn-cerrar"
            onClick={() => setShowed(false)}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </>
);
export default ModalValorDinero;
