import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// assets
import InputsOrder from '../../components/common/InputsOrder';
import {
  QuestionsDivs,
  CardsBtn,
  TrainingQuestion,
  TrainingDivSection,
  ErrorDiv,
  ImageCard4D,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/TextCustom.css';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import vigencia1 from '../../assets/img/anacoach/ine_vigencia1.png';
import vigencia2 from '../../assets/img/anacoach/ine_vigencia2.png';
import { Container, Col7 } from '../../components/common/common.styled';

const INE = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [showINE, setShowINE] = useState('');
  const [validarIne] = useState(true);
  const [INE, setINE] = useState(sessionStorage.getItem('Numero_INE') || '');
  const [saltarIne, setSaltarINE] = useState(false);
  const [Rutine, setRutine] = useState(currentRoutine);
  const handleChangeRutine = (data) => setRutine(data);
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [form, setForm] = useState({
    firstname: sessionStorage.getItem('FirstName') || '',
    lastname: sessionStorage.getItem('FathersLastName') || '',
    secondname: sessionStorage.getItem('MiddleName') || '',
    email: sessionStorage.getItem('Email1') || '',
    secondlastname: sessionStorage.getItem('MothersLastName') || '',
    day: sessionStorage.getItem('day') || Date.day,
    month: sessionStorage.getItem('month') || Date.month,
    year: sessionStorage.getItem('year') || Date.year,
    cel: sessionStorage.getItem('CellPhone') || '',
    directionMaps: '',
    mapsGeo: '',
    calle: sessionStorage.getItem('Street') || '',
    numext: sessionStorage.getItem('ExtNo') || '',
    numint: sessionStorage.getItem('IntNo') || '',
    cp: sessionStorage.getItem('ZipCode') || '',
    colonia: sessionStorage.getItem('Suburb') || '',
    municipio: sessionStorage.getItem('Municipality') || '',
    estado: sessionStorage.getItem('State') || '',
    telcasa: sessionStorage.getItem('HomePhone') || '',
    digitoscard: sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
    empresa: sessionStorage.getItem('Nombre_de_la_empresa') || '',
    nameref1:
      sessionStorage.getItem('Nombre_Completo_Referencia_Familiar') || '',
    telref1: sessionStorage.getItem('Telefono_de_Referencia_Familiar') || '',
    nameref2:
      sessionStorage.getItem('Nombre_Completo_de_una_Referencia_no_familiar')
      || '',
    telref2: sessionStorage.getItem('Telefono_de_Referencia_no_Familiar') || '',
    numINE: sessionStorage.getItem('Numero_INE') || '',
  });

  const handleChangeIne = (ine) => {
    sessionStorage.setItem('NumeroIdent', ine);
    setINE(ine);
    questions.push({
      id: 'ine',
      question: 1,
      value: ine,
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
    setOrderRutine(nextOrderRoutine);
  };

  const ValidINE = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const ine = document.getElementById(id);
    if (
      (value.length !== 13 && value.length !== 9)
      || (name && !/^[0-9]+$/i.test(value))
    ) {
      ine.className = 'textInput inputs-bottom invalidInput';
      setTextError('Por favor verifica el número de tu identificación');
    } else {
      ine.className = 'textInput inputs-bottom validInput';
      setError(false);
    }
  };

  const handleChange = (e, question = '') => {
    const ine = document.getElementById('MuiInputBase-input MuiOutlinedInput-input');
    console.log(ine);
    setForm({
      ...form,
      numINE: e.target.value,
    });
    if (e.target.value.length < 1) {
      ine.className = 'invalidInput';
      console.log('jssjsjsj');
      console.log(e.target.id);
    } else {
      ine.className = 'validInput';
      console.log(e.target.id);
      console.log('jsjsjsjooooo');
    }
    ValidINE('numINE', e.target.value, e.target.id);
  };
  const handleSaltarIne = () => {
    setSaltarINE(true);
    setOrderRutine(nextOrderRoutine);
  };

  const handleSubmit = (event) => {
    console.log('previniendo el enter');
    event.preventDefault();
  };
  const handleChangeCatOption = (val) => {
    console.log(val);
  };
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log(numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else if (name == 'dateBurn') {
      numQuestion = 5;
    }
    if (
      type === 'Cat'
      || name === 'RFC'
      || name === 'digitoscard'
      || name === 'bank'
      || name === 'empresa'
    ) {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('Numero_INE') !== null
      && sessionStorage.getItem('Numero_INE') !== ''
    ) {
      setOrderRutine(nextOrderRoutine);
    } else {
      console.log('no hay ine');
    }
  }, [INE]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && document.getElementById('activeSolicitarINE') !== null) {
        gsap.to(window, { duration: 1, scrollTo: '#activeSolicitarINE' });
        setReviewScroll(false);
      }
    }, 2200);
  }, []);

  return (
    <div>
      {(orderRutine === currentRoutine && validarIne) || INE !== '' ? (
        <div>
          <div id="activeSolicitarINE"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuemos"
            hora={hora}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Para continuar con tu solicitud necesitamos que nos indiques el número de tu identificación oficial vigente (INE o IFE)"
            hora={hora}
          />
          <div id="activeIne"></div>
          <TrainingQuestion
            width="100%"
            wiw="100%"
            mt="0"
            className={INE === '' ? '' : 'hidden'}
          >
            <QuestionsDivs
              padd="0"
              txta="left"
              width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
              widthweb="100%"
              hei="auto"
              margin="0 auto auto"
              bc="#fafafa"
            >
              <form className="" onSubmit={(e) => handleSubmit(e)}>
                {INE === '' ? (
                  <Fragment>
                    <Container
                      width="80%"
                      mt="0"
                      widthMobile="90%"
                      mtMobile="0"
                    >
                      <ImageCoachInicio
                        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                        topMobile="35px"
                      />
                      <Col7
                        maxwidth="100%"
                        padding=" "
                        className="cardPrincipal1"
                      >
                        <TrainingDivSection
                          hew="auto"
                          paddw="26px"
                          mlw="0%"
                          mtw="8%"
                          wiw="100%"
                          back="#F3F3F3"
                          backw="#F3F3F3"
                          bshaw="none"
                          bsha=""
                          br="4px"
                          padding="20px"
                          wi="100%"
                          he="auto"
                          className="topOne1 topOne1Init"
                        >
                          <InputsOrder
                            handleChangeQuestionsSend={
                              handleChangeQuestionsSend
                            }
                            handleChangeRutine={handleChangeRutine}
                            orderRutine={11}
                            step="0-0"
                            handleChangeCatOption={handleChangeCatOption}
                            form={form}
                            maxLengthInput={13}
                            handleChange={handleChange}
                            inputIne={true}
                            showINE={showINE}
                            setShowINE={setShowINE}
                          />
                          <CardsBtn
                            type="button"
                            min_w="100%"
                            min_web="100%"
                            mtopw="0"
                            mtop="0"
                            onClick={
                              form.numINE.length === 9
                              || form.numINE.length === 13
                                ? () => handleChangeIne(form.numINE)
                                : null
                            }
                          >
                            Continuar
                          </CardsBtn>
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            mtop="12px"
                            mtopw="12px"
                            type="button"
                            onClick={(e) => handleSaltarIne(e)}
                            color="#EC407A !important"
                            back="#fff !important"
                            border="solid 1px !important"
                            className={
                              saltarIne !== true && saltarIne !== 'true'
                                ? ''
                                : 'hidden'
                            }
                          >
                            No la tengo en este momento
                          </CardsBtn>
                        </TrainingDivSection>
                      </Col7>
                    </Container>
                  </Fragment>
                ) : null}
              </form>
            </QuestionsDivs>
          </TrainingQuestion>

          <ErrorDiv className={error ? '' : 'hidden'}>{textError}</ErrorDiv>

          <CardOne
            classCard={INE === '' && showINE ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            text="Si tienes problemas para identificar el número de tu INE o IFE, aquí te decimos dónde buscarlos."
            hora={hora}
          />
          <ImageCard4D
            src={vigencia1}
            className={INE === '' ? 'img-fluid' : 'hidden'}
          ></ImageCard4D>
          <ImageCard4D
            src={vigencia2}
            className={INE === '' ? 'img-fluid' : 'hidden'}
          ></ImageCard4D>
          <CardTwo
            classCard={INE !== '' ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={INE}
            colorTraining={trainingColor}
          ></CardTwo>
        </div>
      ) : null}
    </div>
  );
};

export default INE;
