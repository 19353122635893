import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { useHistory } from 'react-router-dom';
import { Image } from '../../assets/styles/Home.styled';
import TrainingGroups from '../../components/Training/TrainingGroups';
import {
  Container1,
  Rombo,
  ContainerBottons,
  Rectangle20,
  TitleEntrenamientos,
  SubTexto,
  ContentHomeSection,
  Shapes,
  Rectangle23,
  Rectangle25,
  Rectangle24,
  Vector1,
  Title1,
  Subtitle,
  Vector3,
} from '../../components/common/Home.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import { Container, Col6 } from '../../components/common/common.styled';

import Pesas from '../../assets/img/anacoach/ana_pesas.svg';
import WOlayout from '../../components/layouts/WOlayout';
import { getQueryVariableHashtagDeeplink } from '../../Helpers';
import { Helmet } from 'react-helmet';

function LPpageplanes({ location }) {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (sessionStorage.getItem('idUser') && sessionStorage.getItem('idCont')) {
      sessionStorage.setItem('DataPerson', true);
      //  setisData(true)
      setRedirect(true);
    }

    const {
      idUser,
      idCont,
      recovery,
    } = getQueryVariableHashtagDeeplink('LPpageplanes');
    sessionStorage.setItem('idUser', idUser);
    sessionStorage.setItem('idCont', idCont);
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
      sessionStorage.setItem('DataPerson', true);
      // setisData(true)
      setRedirect(true);
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
  }, []);

  const RedirectDatePerson = () => {
    sessionStorage.setItem('LPpageplanes', true);
    history.push('/personal-Information');
  };

  const RedirectFirstIn = () => {
    sessionStorage.setItem('DataPerson', true);
    sessionStorage.setItem('LPpageplanes', true);
    history.push('/wo/first-investment');
  };

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Que tu dinero trabaje para ti | CORU</title>
      </Helmet>
      <Container1>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="555px"
          height="335px"
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Rectangle23
                Ptop="100px"
                Pleft="80%"
                position="absolute"
                displayD=" "
                displayM="none"
              />
              <Rectangle25
                positionD="absolute"
                left="63%"
                top="107px"
                displayM="none"
                displayD=" "
              />
              <Rectangle24
                positionD="absolute"
                Ptop="120px"
                Pleft="97%"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
              />
              <Rectangle24
                positionD="absolute"
                Ptop="370px"
                Pleft="1%"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
              />
              <Vector1
                positionD="absolute"
                Pleft="44%"
                Ptop="2.9%"
                displayM="none"
                displayD=" "
              />
              <Vector1
                positionD="absolute"
                Pleft="5%"
                Ptop="10px"
                background="#6BD4AE"
                displayM="none"
                displayD=" "
                transform="matrix(1, -0.02, -0.07, 1, 0, 0);"
              />
              <div className="mt-5 pt-5"></div>

              <Title1
                id="Title1"
                mtopM="0px"
                className="roboto cabin"
                heightM="auto"
                mt="120px"
              >
                Aprender a <br /> invertir
              </Title1>
              <div className="d-flex flex-column">
                <div className="p-2">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="0%"
                    mbottomM="5%"
                    heightM="auto"
                  >
                    En Coru nos dimos a la tarea de desarrollar una rutina
                    completa para que adquieras o mejores tus habilidades de
                    inversionista
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="0%"
                    mbottomM="5%"
                    heightM="auto"
                  >
                    Comienza a entrenar y conoce todo lo que necesitas saber
                    para hacer que tu dinero trabaje por ti.
                  </Subtitle>
                </div>
              </div>
              <Rombo
                displayD=" "
                displayM="none"
                marginleft="50%"
                margintop="0%"
              />
              <Rectangle20
                displayD=" "
                width="50px"
                height="50px"
                displayM="none"
                marginleft="103%"
              />
              <Rectangle23 top="-125%" displayD="none" displayM="block" />
              <Rectangle25 displayD="none" displayM="block" />
              <Rectangle24 displayD="none" displayM="block" />
            </ContentHomeSection>
          </Container>
          <ContentHomeSection
            leftD="0px"
            floatD="right"
            rigthD="0%"
            topD="-485px"
            width="40%"
            displayD=" "
            displayM="none"
          >
            <Shapes
              id="Shapes-medio"
              positionD=" "
              position="absolute"
              zindex="-1"
            >
              <Image
                src={Pesas}
                maxwidth="430px"
                width="250px"
                position="absolute"
                left="0%"
                top="4%"
                displayMobile="none"
              />
            </Shapes>
          </ContentHomeSection>
        </Shapes>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Image
                src={Pesas}
                maxwidth="430px"
                margin="auto"
                width="100%"
                position=""
                left="0%"
                top="0%"
                displayMobile="block"
                display="none"
                mtMobile="50px"
              />
            </ContainerBottons>
          </div>
        </div>
        <Rombo displayD="none" displayM="block" />
        <Rectangle20 displayD="none" displayM="block" margintop="-70px" />
        <Shapes
          id="Aliados"
          top=" "
          left=" "
          heightD="auto"
          height=" "
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <Col6
              pt="0px"
              flex="0 0 100%"
              maxwidth="100%"
              display="inline!important"
              displayM="block"
            >
              <div style={{ margin: 'auto' }}>
                <TitleEntrenamientos
                  top="5%"
                  topD=" "
                  className="roboto"
                  minWindthweb="360px"
                  marginleftweb="0px"
                >
                  Estos entrenamientos incluyen este plan:
                </TitleEntrenamientos>
                <SubTexto
                  bottom="28px"
                  className="roboto"
                  heightD="auto"
                  heightmb1="auto"
                >
                  Entrena tu músculo financiero con los siguientes
                  entrenamientos y conviértete en una persona experta en
                  inversiones
                </SubTexto>
              </div>
            </Col6>
            <Col6
              id="CarruselEmpresasContent"
              pt="0px"
              flex="0 0 100%"
              maxwidth="100%"
              display="flex"
            >
              <TrainingGroups />
            </Col6>
            <Rectangle24
              positionD="absolute"
              Ptop="61%"
              Pleft="90%"
              background="#BFDD6A"
              transform="rotate(46.41deg)"
              displayM="none"
              displayD=" "
              left=" "
              top=" "
            />
            <Rectangle23
              position="absolute"
              Ptop="67%"
              displayD="block"
              displayM="none"
              radius="40px"
              width="100px"
              widthMb="150.37px"
              heightMb="150.37px"
              height="100px"
              Pleft="90%"
              background="#6BD5AF"
              transform="rotate(-17.24deg)"
              top=""
            />
            <Vector3
              displayD="block"
              position="absolute"
              displayM="none"
              width="100px"
              height="100px"
              left="84%"
              top="18%"
              background="#E3E660"
              transform="rotate(-15.06deg)"
            />
          </Container>
        </Shapes>
        <ContainerBottons
          top="0px"
          topD="20px"
          topM="0px"
          position=" "
          displayM="flex"
          bottomM="100px"
          bottomD="0px"
        >
          <CardsBtn
            back="#fb245c"
            weight="bold"
            onClick={redirect ? RedirectFirstIn : RedirectDatePerson}
          >
            Comenzar a entrenar
          </CardsBtn>
        </ContainerBottons>
      </Container1>
      {/* <Whatsapp url={""} id="Whatsapp"/> */}
    </WOlayout>
  );
}

export default LPpageplanes;
