/* eslint consistent-return:  */
/* eslint no-unused-vars: */
import React, { useState, useEffect, Fragment } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import {
  TrainingAnimateFunnel,
  TrainingLine,
  TrainingMain,
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import WOlayout from '../../components/layouts/WOlayout';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import imgWo from '../../assets/img/anacoach/guantera.svg';
import BarraScroll from '../BarraScroll';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import RoutinesOrderGloveBox from './RoutinesOrderGloveBox';
import GloveBoxInfo from './GloveBoxInfo';

import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  CREATE_SESSION_MYSQL,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  GET_TRAINING_COLOR,
  GET_TRAINING_STATUS,
} from '../../queries';

import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariable,
  getQueryVariableHashtagDeeplink,
} from '../../Helpers';

import GeneralHooks from '../../Helpers/HookHelpersGeneral';

const items2 = ['Guantera de auto'];
const getTime = () => {
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  return `${date.getHours()}:${minutos}`;
};

const GloveBox = () => {
  // STATES GLOBALES
  const [percentage, setPercentage] = useState('');
  const { actions } = GeneralHooks();
  const [orderRutine, setOrderRutine] = useState(1);
  const [arrayOrderRoutines] = useState([]);
  const [currentTraining] = useState(44);
  const [questions, setQuestions] = useState([]);
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [colorTraining, setColorTraining] = useState('');
  const [checkScroll, setCheckScroll] = useState(true);

  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });

  /*------------------------------------------------------------------------------------------------
  ------------------------------------------ DATA BASICA -------------------------------------------
  --------------------------------------------------------------------------------------------------
  */

  sessionStorage.setItem('idTraining', 44); // Id del WO
  const id = '44';
  const anchop = window.screen.width;

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '10 minutos',
    imgWo: { imgWo },
    txtsubtitle: 'Tus documentos en un solo lugar',
  };

  const scroll1 = document.documentElement.scrollTop;

  const featuresArray = useState([
    {
      text: <>Saber para qué sirve la <b>Guantera de Auto Digital</b></>,
      color: 'A5A6F6',
    },
    {
      text: 'Conocer qué documentos debes incluir en ella',
      color: 'A5A6F6',
    },
  ]);

  /*------------------------------------------------------------------------------------------------
  ------------------------------- DECLARACION DE QUERIES Y MUTACIONES ------------------------------
  --------------------------------------------------------------------------------------------------
  */

  const [getTrainingStatusG] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      // eslint-disable-next-line no-console
      console.log(getTrainingStatus);
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error query getTrainingStatusG', err);
    },
  });
  const [getTrainingColorG] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error query getTrainingColorG', err);
    },
  });

  const [getPercentageTrainingG] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      // 11.11 solo la 1ra rutina
      if (percent > 11.11) {
        setOrderRutine(-1);
      }
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error query getPercentageTrainingG', err);
    },
  });

  /* const [getPersonalizeRecommendationTrainingsG] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          // console.log(`Array recommended ------------>${recommendedTraining}`);
          setRecomendaciones(true);
        }
      },
      onError(err) {
        // eslint-disable-next-line no-console
        console.log('Error mutation getPersonalizeRecommendationTrainingsG', err);
      },
    },
  ); */

  /*------------------------------------------------------------------------------------------------
  ------------------------ GUARDA, ACTUALIZA Y CREA STATUS DEL WO y SESIONES -----------------------
  --------------------------------------------------------------------------------------------------
  */

  /* const [checkTrainingFinishedG] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const allowOverwrite = sessionStorage.getItem(`overwrite_WO_${id}`) || 'false';
        const firstName = sessionStorage.getItem('FirstName') || '';
        if (trainingInfo.avance === 100 && allowOverwrite !== 'true') {
          setFinished(true);
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            bullets: trainingInfo.bullets,
            idTraining: parseInt(sessionStorage.getItem('idTraining'), 10) || 0,
            title: `¡Hola, ${firstName}!`,
            txt: `Realizaste este entrenamiento el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} y ahora sabes:`,
          });
        }

        if (allowOverwrite === 'true') {
          setOrderRutine(2);
        }
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error query checkTrainingFinishedG', err);
    },
  }); */

  const [saveStatusTrainingG] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        /* console.log(
          'kokl============',
          `${porcentaje[1].toString()}%`,
        ); */
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error mutation saveStatusTrainingG', err);
    },
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        // console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdateG] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        // console.log('update exitoso');
      } else {
        // eslint-disable-next-line no-console
        console.log('Error mutation dynamicUpdateG', dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        // console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        // console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };

  const [createSessionMysqlG] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      // console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error mutation createSessionMysqlG', err);
    },
  });

  const saveAdvance = (currentOrderRoutine) => {
    const idTraining = 44;
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(idTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    if (idUser != null) {
      saveStatusTrainingG({ variables: inputProgress });
    }
  };

  /*------------------------------------------------------------------------------------------------
  ---------------------------- FUNCIONES PRINCIPALES DEL ENTRENAMIENTO -----------------------------
  --------------------------------------------------------------------------------------------------
  */

  const getFielOption = (items, value) => {
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };

  // ?? primeras 4 rutinas corroborar si se cumplen en este WO
  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      if (arrayOrderRoutines.length === 0 && orderRutine === 4) {
        arrayOrderRoutines.push(1, 2, 3, orderRutine);
      } else {
        arrayOrderRoutines.push(orderRoutine);
      }
    }
  };

  const [insertRoutineG] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        // console.log('insertRoutine');
        // console.log(jsonResponse);

        if (insertRoutine.message === 'usuario_existente') {
          // validar que no este logueado
          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity, 10) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity, 10),
              );
            }
          } else if (parseInt(jsonResponse.idDatOpportunity, 10) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity, 10),
            );
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID'), 10))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdateG({ variables: inputDynamicUpdate });
            const currentOrderRoutine = sessionStorage.getItem(
              'currentOrderRoutine',
            );
            if (parseInt(currentOrderRoutine, 10) === -1) {
              const editCurrentRoutine = sessionStorage.getItem(
                'currentOrderRoutinew',
              );
              saveAdvance(parseInt(editCurrentRoutine, 10));
            } else {
              saveAdvance(parseInt(currentOrderRoutine, 10));
            }
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity, 10) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity, 10),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID'), 10))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdateG({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        if (parseInt(currentOrderRoutine, 10) === -1) {
          const editCurrentRoutine = sessionStorage.getItem(
            'currentOrderRoutinew',
          );
          saveAdvance(parseInt(editCurrentRoutine, 10));
        } else {
          saveAdvance(parseInt(currentOrderRoutine, 10));
        }
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('Error mutation insertRoutineG', err);
    },
  });

  const createJsonInput = (
    currentRoutine,
    questionsj,
    orderRutineDatBasics = '',
  ) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    sessionStorage.setItem(
      'currentOrderRoutine',
      orderRutineDatBasics !== '' ? orderRutineDatBasics : orderRutine,
    );
    pushArrayOrderRoutine(
      orderRutineDatBasics !== '' ? orderRutineDatBasics : orderRutine,
    );
    let numQuestion = 0;
    try {
      questionsj.forEach((item) => {
        numQuestion = parseInt(item.question, 10) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont'), 10) || parseInt('0', 10);
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser'), 10) || parseInt('0', 10);
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining'), 10) || parseInt('0', 10);
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion, 10)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        // console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'), 10,
          );
        }
        insertRoutineG({ variables: { input: inputRutine } });
      } else {
        // console.log('else', inputRutine);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error en createJsonInput', e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };

  /*------------------------------------------------------------------------------------------------
  ----------------------------------- LOS EFECTS AL CARGAR EL WO -----------------------------------
  --------------------------------------------------------------------------------------------------
  */

  useEffect(() => {
    getTrainingStatusG({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    getTrainingColorG({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    if (active1) {
      setactive2(true);
    }
  }, [active1]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  /* useEffect(() => {
    const email = sessionStorage.getItem('Email1');
    getPersonalizeRecommendationTrainingsG({ variables: { email } });
  }, []); */

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTrainingG({
        variables: { iduser: parseInt(idUser, 10), idtrainig: currentTraining },
      });
    } else {
      setPercentage('0');
      window.location.href = '/login';
    }
  }, []);
  /* useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser !== null) {
      checkTrainingFinishedG({
        variables: {
          idUser: parseInt(idUser, 10),
          idTraining: currentTraining,
        },
      });
    }
  }, []); */
  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('micro-expenses');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    // eslint-disable-next-line no-console
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
      content,
    );
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (let elem = 0; elem < elementos.length; elem += 1) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (let elem = 0; elem < elementos.length; elem += 1) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID'), 10))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'), 10,
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        // console.log(inputCreateSessionMysql);
        createSessionMysqlG({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
        viralityID,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  /*------------------------------------------------------------------------------------------------
  --------------------------------------------------------------------------------------------------
  ----------------------------------- FIN DE LA CARGA PRINCIPAL ------------------------------------
  --------------------------------------------------------------------------------------------------
  --------------------------------------------------------------------------------------------------
  */

  return (
    <WOlayout
      backgroundColor={colorTraining || '#A5A6F6'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor={colorTraining || '#A5A6F6'}
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <BarraInfoWO propied={propied} />
          {scroll1 > 0 ? <BarraScroll /> : null}
          <TrainingLine
            id="line-percent-gray"
            mt=""
            mtw="404px"
          />
          {percentage !== '' ? (
            <TrainingLine
              id='line-percent-pink'
              bcolor='#F64282'
              mt=""
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            />
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }} >
            {active0 ? (
              <TrainingSectionGray
                wiw="100%"
                mw="auto"
                back="#FAFAFA"
                mtop="0"
              >
                <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                  <>
                    {orderRutine !== -1 ? (
                      <>
                        <div className='marginComentLgSuccess1'>
                          <Starttraining
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="En este entrenamiento podrás:"
                          />
                          <Features
                            classAna={
                              anchop <= 768 ? 'falseAna' : 'trueAna'
                            }
                            title=""
                            Arraytext={ featuresArray }
                            isGloveBox={true}
                        />
                        </div>
                        <RoutinesOrderGloveBox
                          Date={stateDate}
                          hora={getTime()}
                          orderRutine={orderRutine}
                          setOrderRutine={setOrderRutine}
                          currentTraining={currentTraining}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                          trainingColor={colorTraining}
                        />
                      </>
                    ) : (
                      <GloveBoxInfo
                        createJsonInput={createJsonInput}
                        questions={questions}
                        setQuestions={setQuestions}
                      />
                    )}
                  </>
                </TrainingDivSection2>
              </TrainingSectionGray>
            ) : null }
          </div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
};

export default GloveBox;
