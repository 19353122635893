import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useLazyQuery, useMutation } from '@apollo/client';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { TextPerfil } from '../../assets/styles/pantInicio.styled';
import DatosBasicos from '../Routines/DatosBasicos';
import PerfilInvestmentCatalog from '../Routines/PerfilInvestmentCatalog';
import CalculadoraInvestmentCatalog from '../Routines/CalculadoraInvestmentCatalog';
import { GET_PRODUCTS_BY_IDTRAINING } from '../../queries';
import ProductsFremium from '../../components/common/ProductsFremium';
import stars from '../../assets/img/anacoach/5Stars.png';
import {
  Container,
  Col12,
  ParagrapTwo,
} from '../../components/common/common.styled';
import {
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
  CREATE_OPPORTUNITIES,
} from '../../mutations';
import { Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';

import {
  DivFlex,
  CardsTxt,
  DivGray,
  CardsBtn,
  DivStyle,
  Images,
  TrainingDivSection,
  TrainingSubtitles,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import ClickButton from '../../Helpers/HookHelpers';
import CardOne from '../../components/Home/Card1Animate';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';

const RoutinesOrderInvestmentCatalog = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [investmentCatalogProducts, setInvestmentCatalogProducts] = useState(
    [],
  );
  const [keyboardStatus, setKeyboardStatus] = useState([]);
  const [more, setMore] = useState([]);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const anchop = window.screen.width;

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  const handleClickMore = (index) => {
    if (keyboardStatus[index]) {
      const kbStatus = [...keyboardStatus];
      kbStatus[index] = false;
      setKeyboardStatus(kbStatus);

      const label = [...more];
      label[index] = 'Mostrar más';
      setMore(label);
    } else {
      const kbStatus = [...keyboardStatus];
      kbStatus[index] = true;
      setKeyboardStatus(kbStatus);

      const label = [...more];
      label[index] = 'Mostrar menos';
      setMore(label);
    }
  };

  useEffect(() => {
    const kbStatus = [];
    const label = [];
    investmentCatalogProducts.map((e, i) => {
      kbStatus[i] = false;
      label[i] = 'Mostrar más';
    });
    setKeyboardStatus(kbStatus);
    setMore(label);
  }, [investmentCatalogProducts]);

  const [data, setData] = useState([]);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const abrirNuevoTab = (url, name, IDProduct) => {
    const click = `Invertir_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('clickOutProduct', name);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 2, // remplazar por el id del entrenamiento
        idProducts: [IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 18,
        idType: '',
        value: name,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const win = window.open(url, '_blank');
    win.focus();
  };
  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    onCompleted({ getProductsByIdTraining }) {
      console.log('Productos', getProductsByIdTraining);
      const products = JSON.parse(getProductsByIdTraining.response);
      console.log('products >>>', products);
      setData(products);
      for (let item = 0; item < products.length; item++) {
        const product = products[item];
        const MontoMinimo = product.benefits.filter((item) => {
          if (item.Name === 'MontoMinimo') {
            return 'Monto mínimo';
          }
        });
        if (MontoMinimo[0]) {
          product.montoMin = parseFloat(
            String(MontoMinimo[0].Description).replace('%', '').trim(),
          );
        } else {
          console.log('<<<<<< ¡Sin monto mínimo! >>>>>');
        }
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    getProductsByIdTraining({
      variables: { idTraining: String(currentTraining) },
    });
  }, []);

  useEffect(() => {
    if (orderRutine === 'rp') {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [investmentCatalogProducts]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && investmentCatalogProducts.length > 0) {
        gsap.to(window, { duration: 1, scrollTo: '#activeProductosCat' });
        setReviewScroll(false);
      }
    }, 1000);
  }, [investmentCatalogProducts]);
  return (
    <Fragment>
      {orderRutine >= 1 ? (
        // <DatosBasicos
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        //   trainingColor={trainingColor}
        // />
        <ModalLogin
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}

      {orderRutine >= 2 ? (
        <PerfilInvestmentCatalog
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          saveAdvance={saveAdvance}
        />
      ) : null}

      {orderRutine >= 3 && data.length > 0 ? (
        <CalculadoraInvestmentCatalog
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={'rp'}
          data={data}
          saveAdvance={saveAdvance}
          setInvestmentCatalogProducts={setInvestmentCatalogProducts}
          trainingColor={trainingColor}
        />
      ) : null}
      {investmentCatalogProducts.length > 0 ? (
        <>
          <div id="activeProductosCat"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard="cardOne"
            classTop="topOne1"
            text="Continuemos"
          />
        </>
      ) : null}
      {investmentCatalogProducts.length > 0 ? (
        <>
          {investmentCatalogProducts.map((producto, index) => (
            <Fragment key={index}>
              <TrainingDivSection
                backw="#f8f9fa00"
                padding="0px"
                paddw="0px"
                mb="0"
                wixs="90%"
                wi="89%"
                wiw="80%"
                he="auto"
                hew="auto"
                bsha="null"
              >
                <TrainingDivSection
                  mlw="10%"
                  ml="0px"
                  mr="0px"
                  bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                  mt="20px"
                  br="4px"
                  padding="3%"
                  wi="100%"
                  wiw="75%"
                  he="auto"
                  hew="auto"
                  mb="0.5px"
                  mtw="20px"
                >
                  <TrainingSubtitles></TrainingSubtitles>
                  <DivStyle size="18px" line="1.2em"></DivStyle>
                  <DivStyle
                    className="cabin"
                    mxh="30px"
                    weight="600"
                    align="left"
                    size="20px"
                    line="1.5em"
                    padd="12px 5% 0 5%"
                    color="#07210A"
                  >
                    {producto.Name}
                  </DivStyle>
                  <DivGray bcolor="#fff" padd="0 5% 0 5%">
                    <hr />
                    <div>
                      <DivStyle align="left">
                        <DivFlex justify="space-between">
                          <CardsTxt
                            color="#4F4F4F"
                            className="roboto"
                            size="16px"
                          >
                            Tasa de interés:
                            {producto.benefits.map((benefit) => {
                              if (benefit.Name === 'Tasa') {
                                return isNaN(benefit.Description)
                                  ? ` ${benefit.Description}`
                                  : ` ${benefit.Description}%`;
                              }
                            })}
                          </CardsTxt>
                        </DivFlex>
                        <DivFlex justify="space-between">
                          <CardsTxt
                            color="#4F4F4F"
                            className="roboto"
                            size="16px"
                          >
                            Monto mínimo de inversión:{' '}
                            {formatter.format(producto.montoMin)}
                          </CardsTxt>
                        </DivFlex>
                        <Container
                          width="100%"
                          widthMobile="100%"
                          mtMobile="0px"
                          float="auto"
                          mMobile="auto"
                          bb="1px solid #D6D6D6"
                          mb="5%"
                        >
                          <Col12 ptl="0px" pMobile="0px">
                            <Container
                              width="100%"
                              mt="5%"
                              widthMobile="94%"
                              mtMobile="2rem"
                              className={
                                keyboardStatus[index] ? 'Display' : 'NoDisplay'
                              }
                            >
                              <ParagrapTwo
                                mb="0px"
                                textalignMobile="left"
                                texttalign="left"
                                className="mx-auto roboto"
                                color="#4F4F4F"
                                mtWeb="0px"
                              >
                                {producto.benefits.map((benefit) => {
                                  if (benefit.Name === 'Beneficio 2') {
                                    return benefit.Description;
                                  }
                                })}
                              </ParagrapTwo>
                            </Container>
                            <TextPerfil
                              lh="120%"
                              lh2="120%"
                              color="#4F4F4F"
                              mb="5%"
                              cursor="pointer!important"
                              className="roboto"
                              mt="0%"
                              textAling="right"
                              fs="12px"
                              width="100%"
                              widthMobile="100%"
                              onClick={() => handleClickMore(index)}
                            >
                              {more[index]}
                              {keyboardStatus[index] ? (
                                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                              ) : (
                                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                              )}
                            </TextPerfil>
                          </Col12>
                        </Container>
                      </DivStyle>
                    </div>
                  </DivGray>
                  <DivGray bcolor="#fff" padd="0 5% 0 5%">
                    <Images
                      src={stars}
                      top="0"
                      topweb="0"
                      left="0"
                      leftw="0"
                      position="initial"
                      width="116px"
                      widthw="116px"
                      className="img-fluid"
                    ></Images>
                    <br />
                    <br />
                    <CardsBtn
                      type="button"
                      min_w="100%"
                      min_web="100%"
                      onClick={() => {
                        abrirNuevoTab(
                          producto.ClickOut,
                          producto.Name,
                          producto.IDProduct,
                        );
                      }}
                    >
                      Invertir
                    </CardsBtn>
                  </DivGray>
                </TrainingDivSection>
              </TrainingDivSection>
            </Fragment>
          ))}
        </>
      ) : null}

      {orderRutine === 'rp' ? (
        <>

          <ModalAmazon show={true} wo={'CatalogoInversiones'}></ModalAmazon>
          <ModalVirality />
          {recomendaciones ? (
            <div className="marginComentLgSuccess1">
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>

                  </ContentHomeSection>
                </Feed_Section>
              </Container>
              <TrainingSectionGray back="#fafafa">
                {sessionStorage.getItem('idTraining') === '18' ? (
                  <ProductsFremium
                    idT={parseInt(sessionStorage.getItem('idTraining'))}
                  />
                ) : null}
              </TrainingSectionGray>
            </div>
          ) : null}
          <SectionShare WO="CatalogoInversiones"></SectionShare>
        </>
      ) : null}
    </Fragment>
  );
};
export default RoutinesOrderInvestmentCatalog;
