import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import {
  Container,
  Col12,
  Col6,
  SubtitleOne,
  ParagrapTwo,
  Button,
  CorrecionCorreo,
} from '../common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import ManGray from '../../assets/img/perfil/mangray.png';
import womanGray from '../../assets/img/perfil/womangray.png';
import ManPink from '../../assets/img/perfil/manpink.png';
import womanPink from '../../assets/img/perfil/womanpink.png';
import { UPDATE_USERS, UPDATE_SESSION, DYNAMIC_GET_MONGO } from '../../mutations';
// Modal
// Helpers
import { day, months, years } from '../../Helpers';
import { setMongoPersonalInfo } from '../../Helpers/HelperBinacle';
import {
  TrainingText,
} from '../../assets/styles/Training.styled';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

// END MODAL

function FormPersonalInformation() {
  const getGenere = (value) => {
    if (value == 'Masculino') {
      handleClickGeneroH();
    } else {
      handleClickGeneroW();
    }
  };
  const [dynamicUpdate] = useMutation(UPDATE_USERS, {
    onCompleted({ dynamicUpdate }) {
      console.log(dynamicUpdate);
    },
  });

  const [updateBinacle] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [getBinacle] = useMutation(DYNAMIC_GET_MONGO, {
    onCompleted({ dynamicMongoSessionGet }) {
      const binacle = JSON.parse(dynamicMongoSessionGet.response);
      sessionStorage.setItem('id_binacle', binacle._id);
      setMongoPersonalInfo(form, updateBinacle, binacle);
    },
    onError(err) {
      console.log(err);
    },
  });

  const colorgray = '#110e20';
  const colorpink = '#EC407A';
  const [genero, setGenero] = useState({
    man: ManGray,
    woman: womanPink,
    mancolor: colorgray,
    womancolor: colorpink,
  });

  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [form, setForm] = useState({
    firstname: sessionStorage.getItem('firstName') == null ? '' : sessionStorage.getItem('firstName'),
    lastname: sessionStorage.getItem('fathersLastName') == null ? '' : sessionStorage.getItem('fathersLastName'),
    secondname: sessionStorage.getItem('middleName') == null ? '' : sessionStorage.getItem('middleName'),
    email: sessionStorage.getItem('email') == null ? '' : sessionStorage.getItem('email'),
    motherslastname: sessionStorage.getItem('mothersLastName') == null ? '' : sessionStorage.getItem('mothersLastName'),
    day: sessionStorage.getItem('day') == null || sessionStorage.getItem('day') === '0NaN' || sessionStorage.getItem('day') === '00' ? '01' : sessionStorage.getItem('day'),
    month: sessionStorage.getItem('month') == null || sessionStorage.getItem('month') === '0NaN' || sessionStorage.getItem('month') === '00' ? '01' : sessionStorage.getItem('month'),
    year: sessionStorage.getItem('year') == null || sessionStorage.getItem('year') === 'NaN' ? '1990' : sessionStorage.getItem('year'),
    age: '30',
    genero: sessionStorage.getItem('Gender') == null ? '' : sessionStorage.getItem('Gender'),
    phone: sessionStorage.getItem('CellPhone') == null ? '' : sessionStorage.getItem('CellPhone'),
    landline: sessionStorage.getItem('landline') == null ? '' : sessionStorage.getItem('landline'),
  });
  const [error] = useState({
    email: false,
    firstname: false,
    secondname: false,
    lastname: false,
    motherslastname: false,
    day: false,
    month: false,
    year: false,
    age: false,
    genero: false,
    phone: false,
    landline: false,
    emailvalid: form.email.length !== 0 ? 0 : -1,
    firstnamevalid: form.firstname.length !== 0 ? 0 : -1,
    secondnamevalid: form.secondname.length !== 0 ? 0 : -1,
    lastnamevalid: form.lastname.length !== 0 ? 0 : -1,
    motherslastnamevalid: form.motherslastname.length !== 0 ? 0 : -1,
    dayvalid: form.day.length !== 0 ? 0 : -1,
    monthvalid: form.month.length !== 0 ? 0 : -1,
    yearvalid: form.year.length !== 0 ? 0 : -1,
    agevalid: form.age.length !== 0 ? 0 : -1,
    generovalid: form.genero.length !== 0 ? 0 : -1,
    phonevalid: form.phone.length === 10 ? 0 : -1,
    landlinevalid: form.landline.length !== 0 ? 0 : -1,
  });

  const [texterror] = useState({
    email: '',
    firstname: '',
    secondname: '',
    lastname: '',
    motherslastname: '',
    day: '',
    month: '',
    year: '',
    age: '',
    genero: '',
  });

  const [Age, SetAge] = useState(30);
  const ValidEmail = (value) => {
    let domain = value.split('@');
    const anexo = domain[0];
    domain = domain[1];

    if (domain !== '' && domain !== 'gmail' && domain !== 'hotmail') {
      if (domain === 'h') {
        SetTypeCorreo({
          display: 'block',
          text: `Quieres decir: ${anexo}@hotmail.com`,
          text_correct: `${anexo}@hotmail.com`,
        });
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === 'g') {
        SetTypeCorreo({
          display: 'block',
          text: `Quieres decir: ${anexo}@gmail.com`,
          text_correct: `${anexo}@gmail.com`,
        });
        texterror.email = '';
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === 'hotmail.com' || domain === 'gmail.com') {
        SetTypeCorreo({
          display: 'none',
          text: '',
          text_correct: '',
        });
        texterror.email = '';
        error.email = false;
        error.emailvalid = 0;
      }
    } else {
      SetTypeCorreo({
        display: 'none',
        text: '',
        text_correct: '',
			  });
    }
    if (value
		&& !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailvalid = 1;
    } else {
      texterror.email = '';
      error.email = false;
      error.emailvalid = 0;
      setbuttonDisabled(true);
      SetTypeCorreo({
        display: 'none',
        text: '',
        text_correct: '',
      });
    }
    if (value === '') {
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailvalid = 1;
    }
    eBtn();
  };

  const eBtn = () => {
    if (
      form.email.length !== ''
      && !error.email
      && form.firstname.length !== ''
      && !error.firstname
      && !error.secondname
      && form.lastname.length !== ''
      && !error.lastname
      && form.motherslastname.length !== ''
      && !error.motherslastname
    ) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  };
  const ValidLetras = (nombre, valor) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    if (valor.length > 2) {
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'secondname') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    eBtn();
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail(e.target.value);
        break;
      case 'day':
        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'month':
        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'year':
        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'phone':
        ValidPhone(e.target.name, e.target.value);
        break;
      case 'landline':
        ValidPhone(e.target.name, e.target.value);
        break;
      default:
        ValidLetras(e.target.name, e.target.value);
        break;
    }
  };

  const ValidPhone = (name, value) => {
    if ((value.length < 11 && value.length !== 10) || (name && !/^[0-9]+$/i.test(value))) {
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
    eBtn();
  };

  const guardar = async (e) => {
    e.preventDefault();
    const idCont = sessionStorage.getItem('idCont');
    const tmpBirthday = `${form.year}-${form.month}-${form.day}`;
    sessionStorage.setItem('day', form.day);
    sessionStorage.setItem('month', form.month);
    sessionStorage.setItem('year', form.year);
    sessionStorage.setItem('Gender', form.genero);
    sessionStorage.setItem('firstName', form.firstname);
    sessionStorage.setItem('fathersLastName', form.lastname);
    sessionStorage.setItem('middleName', form.secondname);
    sessionStorage.setItem('email', form.email);
    sessionStorage.setItem('mothersLastName', form.motherslastname);
    sessionStorage.setItem('landline', form.landline);
    sessionStorage.setItem('DataPerson', true);
    console.log(tmpBirthday);
    const catContactUpdateInput = {
      input: {
        table: 'CatContact',
        columns: [
          {
            setkey: 'FirstName',
            setval: form.firstname,
          },
          {
            setkey: 'MiddleName',
            setval: form.secondname,
          },
          {
            setkey: 'FathersLastName',
            setval: form.lastname,
          },
          {
            setkey: 'MothersLastName',
            setval: form.motherslastname,
          },
          {
            setkey: 'BirthDate',
            setval: tmpBirthday,
          },
          {
            setkey: 'Gender',
            setval: form.genero,
          },
        ],
        conditions: [{
          valone: 'IDCont',
          valtwo: idCont,
          condition: '=',
        }],
      },
    };
    await dynamicUpdate({ variables: catContactUpdateInput });
    const catContactMediumUpdateInput = {
      input: {
        table: 'CatContactMedium',
        columns: [
          {
            setkey: 'CellPhone',
            setval: form.phone,
          },
          {
            setkey: 'HomePhone',
            setval: form.landline,
          },
          {
            setkey: 'Email1',
            setval: form.email,
          },
        ],
        conditions: [{
          valone: 'CatContact_IDCont',
          valtwo: idCont,
          condition: '=',
        }],
      },
    };
    await dynamicUpdate({ variables: catContactMediumUpdateInput });
    if (sessionStorage.getItem('idCont')) {
      const getBinacleInput = {
        input: {
          collection: 'binacle',
          filter: JSON.stringify(
            {
              idCont: parseInt(sessionStorage.getItem('idCont')),
            },
          ),
        },
      };
      getBinacle({ variables: getBinacleInput });
    }
    handleOpen();
  };

  const handleChangeAge = (name, value) => {
    let nacimiento = `${form.year}-${form.month}-${form.day}`;
    switch (name) {
      case 'year':
        nacimiento = `${value}-${form.month}-${form.day}`;
        break;
      case 'mont':
        nacimiento = `${form.year}-${value}-${form.day}`;
      case 'day':
        nacimiento = `${form.year}-${form.month}-${value}`;
        break;
      default:
        nacimiento = `${form.year}-${form.month}-${form.day}`;
        break;
    }
    const birthday = moment(nacimiento);
    const hoy = moment();
    const anios = hoy.diff(birthday, 'years');
    SetAge(anios);
    eBtn();
  };
  const handleClickGeneroH = () => {
    setGenero({
      man: ManPink,
      woman: womanGray,
      mancolor: colorpink,
      womancolor: colorgray,
    });
    setForm({
      ...form,
      genero: 'Masculino',
    });
    eBtn();
  };

  const handleClickGeneroW = () => {
    setGenero({
      man: ManGray,
      woman: womanPink,
      mancolor: colorgray,
      womancolor: colorpink,
    });
    setForm({
      ...form,
      genero: 'Femenino',
    });
  };

  const [TypeCorreo, SetTypeCorreo] = useState({
    text: '',
    display: '',
    text_correct: '',
  });

  const handleClickCorreo = () => {
    setForm({
      ...form,
      email: TypeCorreo.text_correct,
    });
    SetTypeCorreo({
      text: '',
      display: '',
      text_correct: '',
    });
    texterror.email = '';
    error.email = false;
    error.emailvalid = 0;
  };

  const [gener] = useState(
    [
      {
        value: 'Masculino',
        label: 'Masculino',
      },
      {
        value: 'Femenino',
        label: 'Femenino',
      },
    ],
  );

  useEffect(() => {
    getGenere(sessionStorage.getItem('Gender'));
    handleChangeAge('', '');
  }, []);

  // MODAL
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ModalMessage = () => (
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <ParagrapTwo fontWeight="700" cursor="pointer" texttalign ="right" textalignMobile="right" className='mx-auto' onClick={handleClose}>X</ParagrapTwo>
                    <SubtitleOne mt="3%" className='font-weight-bold roboto' sizeMobile="20px" size="20px">
                    </SubtitleOne>
                    <ParagrapTwo className='mx-auto' mb="0px">Tus cambios se han guardado con éxito.</ParagrapTwo>
                    <Button className='d-block mt-5 mt-md-4 mb-5 roboto' onClick={handleClose}>
                       Aceptar
                    </Button>
                </div>
            </Modal>
  );

  return (
    <Fragment>
      <Container
          width="94%"
          mt="1rem"
          widthMobile="98%"
          mtMobile="2rem"
          float="auto!important"
          bb="1px solid #D6D6D6" mb="5%">
            <Col12 pt="0px" ptMobile="0px" >
                <form autoComplete="off" className="" onSubmit={guardar}>
                  <Container width="100%" widthMobile="100%" mtMobile="0px">
                    <Col12 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                      <TextField
                        id="firstname"
                        name="firstname"
                        variant="outlined"
                        error={error.firstname}
                        label="Primer Nombre"
                        required
                        fullWidth
                        value={form.firstname}
                        onChange={handleChange}
                        helperText={texterror.firstname}
                        inputProps={{ className: error.firstnamevalid === 1 ? 'perfil invalidInput' : error.firstnamevalid === 0 ? ' perfil validInput' : 'perfil' }}
                      />
                    </Col12>
                    <Col12 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                      <TextField
                        id="secondname"
                        name="secondname"
                        error={error.secondname}
                        label="Segundo Nombre"
                        variant="outlined"
                        inputProps={{ className: error.secondnamevalid === 1 ? 'perfil invalidInput' : error.secondnamevalid === 0 ? 'perfil validInput' : 'perfil' }}
                        fullWidth
                        value={form.secondname}
                        onChange={handleChange}
                        helperText={texterror.secondname}
                      />
                    </Col12>
                    <Col12 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                      <TextField
                        id="lastname"
                        name="lastname"
                        variant="outlined"
                        error={error.lastname}
                        label="Apellido Paterno"
                        required
                        value={form.lastname}
                        fullWidth
                        onChange={handleChange}
                        inputProps={{ className: error.lastnamevalid === 1 ? 'perfil invalidInput' : error.lastnamevalid === 0 ? 'perfil validInput' : 'perfil' }}
                        helperText={texterror.lastname}
                      />
                    </Col12>
                    <Col12 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                      <TextField
                        id="motherslastname"
                        name="motherslastname"
                        variant="outlined"
                        error={error.motherslastname}
                        label="Apellido Materno"
                        required
                        fullWidth
                        inputProps={{ className: error.motherslastnamevalid === 1 ? 'perfil invalidInput' : error.motherslastnamevalid === 0 ? 'perfil validInput' : 'perfil' }}
                        value={form.motherslastname}
                        onChange={handleChange}
                        helperText={texterror.motherslastname}
                      />
                    </Col12>
                    <Col6>
                        <TextField
                            variant="outlined"
                            id="genero"
                            name="genero"
                            select
                            label="Genero"
                            helperText=""
                            fullWidth
                            className="input-coru"
                            value={form.genero}
                            onChange={handleChange}
                            inputProps={{ className: error.dayvalid === 1 ? 'perfil genero invalidSelect' : error.dayvalid === 0 ? 'perfil genero validSelect' : 'perfil' }}
                        >
                            {gener.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col6>
                    <Col12>
                        <TrainingText
                            className="cabin"
                            align="left"
                            weight="600"
                            top="12px"
                            size="20px"
                        >
                            Fecha de nacimiento
                        </TrainingText>
                    </Col12>
                    <Col6>
                      <TextField
                            variant="outlined"
                            id="day"
                            name="day"
                            select
                            label="Día"
                            helperText=""
                            fullWidth
                            className="input-coru"
                            value={form.day}
                            onChange={handleChange}
                            inputProps={{ className: error.dayvalid === 1 ? 'perfil fecha invalidSelect' : error.dayvalid === 0 ? 'perfil fecha validSelect' : 'perfil' }}
                        >
                            {day.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col6>
                    <Col6>
                        <TextField
                            variant="outlined"
                            id="month"
                            name="month"
                            select
                            label="Mes"
                            helperText=""
                            fullWidth
                            className="input-coru"
                            value={form.month}
                            onChange={handleChange}
                            inputProps={{ className: error.monthvalid === 1 ? 'perfil fecha invalidSelect' : error.monthvalid === 0 ? 'perfil fecha validSelect' : 'perfil' }}
                        >
                            {months.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col6>
                    <Col6>
                        <TextField
                            variant="outlined"
                            id="year"
                            name="year"
                            select
                            label="Año"
                            fullWidth
                            className="input-coru"
                            value={form.year}
                            onChange={handleChange}
                            inputProps={{ className: error.yearvalid === 1 ? 'perfil fecha invalidSelect' : error.yearvalid === 0 ? 'perfil fecha validSelect' : 'perfil' }}
                        >
                            {years.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col6>
                    <Col6>
                      <TextField
                        id="age"
                        name="age"
                        label="Edad"
                        variant="outlined"
                        required
                        className="input-coru"
                        value={Age}
                        fullWidth
                        disabled
                        helperText={texterror.age}
                      />
                    </Col6>
                    <Col12 pMobile="0px">
                      <TextField
                        id="email"
                        name="email"
                        variant="outlined"
                        error={error.email}
                        label="Correo electrónico"
                        required
                        className={error.emailvalid === 1 ? 'invalidInput' : error.emailvalid === 0 ? 'perfil validInput' : ' '}
                        fullWidth
                        type="email"
                        value={form.email}
                        onChange={handleChange}
                        inputProps={{ className: error.emailvalid === 1 ? 'perfil invalidInput' : error.emailvalid === 0 ? 'perfil validInput' : 'perfil' }}
                        helperText={texterror.email}
                      />
                      <CorrecionCorreo display={TypeCorreo.display} onClick={handleClickCorreo}>{TypeCorreo.text}</CorrecionCorreo>
                    </Col12>
                    <Col6 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                      <TextField
                        id="phone"
                        name="phone"
                        error={error.phone}
                        label="Télefono celular"
                        required
                        variant="outlined"
                        inputProps={{
                          maxLength: 10,
                        }}
                        className={error.phonevalid === 1 ? 'invalidInput' : error.phonevalid === 0 ? 'perfil validInput' : ' '}
                        inputProps={{ className: error.phonevalid === 1 ? 'perfil invalidInput' : error.phonevalid === 0 ? 'perfil validInput ' : 'perfil' }}
                        fullWidth
                        value={form.phone}
                        onChange={handleChange}
                        helperText={texterror.phone}

                      />
                    </Col6>
                    <Col6 flex="0 0 100%" maxwidth="100%" pMobile="0px" ptMobile="7%!important">
                      <TextField
                        id="landline"
                        name="landline"
                        error={error.landline}
                        label="Télefono fijo"
                        variant="outlined"
                        inputProps={{
                          maxLength: 10,
                        }}
                        className={error.landlinevalid === 1 ? 'invalidInput' : error.landlinevalid === 0 ? 'perfil validInput' : ' '}
                        inputProps={{ className: error.landlinevalid === 1 ? 'perfil invalidInput' : error.landlinevalid === 0 ? 'perfil validInput' : 'perfil' }}
                        fullWidth
                        value={form.landline}
                        onChange={handleChange}
                        helperText={texterror.landline}
                      />
                    </Col6>
                  </Container>
                  <Button
                    disabled={buttonDisabled}
                    type="submit"
                    className="d-block mt-5 mt-md-4 mb-5 roboto"
                    label="Guardar" mt="2.5rem!important">
                    Guardar
                  </Button>
                </form>
            </Col12>
        </Container>
        <ModalMessage/>
    </Fragment>
  );
}
export default FormPersonalInformation;
