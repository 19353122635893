import React, { useState } from 'react';
import { Image } from './common.styled';
import '../../assets/styles/perfil/perfiles.css';
import Cartera from '../../assets/img/logros/Cartera.svg';
import CarteraB from '../../assets/img/logros/carteraB.png';
import Flag from '../../assets/img/logros/Flag.svg';
import FlagB from '../../assets/img/logros/SaveBlack.svg';
import Share from '../../assets/img/logros/Share.svg';
import face from '../../assets/img/anacoach/Facebook.svg';
import twit from '../../assets/img/anacoach/messenger2.svg';
import whats from '../../assets/img/anacoach/whats.png';
import { shareCoru2 } from '../../Helpers/index';
import { getDateStandart } from '../../Helpers/general';
import { viralityUTM } from '../../Helpers/HelperUTM';
import TagManager from 'react-gtm-module';
import {
  ImageSections,
  DivStyle,
  CardsBtn,
  CardsTxt,
} from '../../assets/styles/Training.styled';
import {
  CREATE_SESSION,
  DYNAMIC_INSERT, GENERATE_SHORTENED_LINK, INSERT_DAT_VIRALITY, UPDATE_HISTORY_COLLECTION,
} from '../../mutations';
import { useMutation } from '@apollo/client';
import ModalAgregarCartera from './Modal_AddWallet';
import ModalNoDisponible from './ModalNoDisponible';
import ModalCompartir from './ModalCompartir';
import { Subtitle } from './Home.styled';

function Products({
  cardNames, cards, setSelectedComplete, setOrderRutine, nextOrderRoutine, applyCards, disableControlButtons
}) {
  const [cardsByProvider] = useState(cards || JSON.parse(sessionStorage.getItem('CatProductosTemp')));
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [modalAgregar, setModalAgregar] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [selected, setSelected] = useState();
  const [dynamicInsert] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.message === 'success') {
        console.log('INSERCION EXITOSA!');
      } else {
        console.log(dynamicInsert.message);
      }
    },
  });

  const agregarProducto = (card, id) => {
    console.log('card................', card);
    const wallet = [];
    const walletStorage = sessionStorage.getItem('walletArr');
    let flag = true;
    if (walletStorage) {
      if (!walletStorage.includes(card.Name)) {
        const walletStorageJson = JSON.parse(walletStorage);
        walletStorageJson.push(card);
        sessionStorage.setItem('walletArr', JSON.stringify(walletStorageJson));
        setSelectedComplete(walletStorageJson);
      } else {
        const walletStorageJson = JSON.parse(walletStorage);
        const newValues = walletStorageJson.filter((crd) => crd.IDProduct !== id);
        sessionStorage.setItem('walletArr', JSON.stringify(newValues));
        setSelectedComplete(newValues);
        document.getElementsByClassName(`car-${id}`)[0].src = Cartera;

        flag = false;
      }
    } else {
      wallet.push(card);
      sessionStorage.setItem('walletArr', JSON.stringify(wallet));
      setSelectedComplete(wallet);
    }
    if (!flag) return;
    const actual = document.getElementsByClassName(`car-${id}`)[0].src;
    if (actual.includes('png')) {
      document.getElementsByClassName(`car-${id}`)[0].src = Cartera;
    } else {
      document.getElementsByClassName(`car-${id}`)[0].src = CarteraB;
    }
    setModalAgregar(true);
    setSelected(JSON.stringify(card));
  };

  const revisarProductoCartera = (id) => {
    const walletStorage = sessionStorage.getItem('walletArr');
    if (!walletStorage) return Cartera;
    const walletStorageJson = JSON.parse(walletStorage);
    const finded = walletStorageJson.find((crd) => crd.IDProduct === id);
    if (finded) return CarteraB;
    return Cartera;
  };

  const saveProduct = (idProduct) => {
    dynamicInsert({
      variables: {
        input: {
          table: 'DatCardSaving',
          columns: ['CatProduct_IdProduct', 'CatUsers_IDUser', 'status', 'idTraining'],
          detailValues: {
            values: [String(idProduct), String(sessionStorage.getItem('idUser')), 'true', String(sessionStorage.getItem('idTraining'))],
          },
        },
      },
    });
    const actual = document.getElementsByClassName(`save-${idProduct}`)[0].src;
    if (actual.includes('SaveBlack')) {
      document.getElementsByClassName(`save-${idProduct}`)[0].src = Flag;
    } else {
      document.getElementsByClassName(`save-${idProduct}`)[0].src = FlagB;
    }
  };

  const reminder = (idProduct) => {
    dynamicInsert({
      variables: {
        input: {
          table: 'DatWaitListProduct',
          columns: ['CatProduct_IdProduct', 'CatUsers_IdUser'],
          detailValues: {
            values: [String(idProduct), String(sessionStorage.getItem('idUser'))],
          },
        },
      },
    });
  };

  /** ************************* */

  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === 'success') {
        console.log('REsponse viralidad', insertDatVirality.response);
        const { response } = insertDatVirality;
        const redirectLink = response.split(',')[0];
        const socialNetwork = response.split(',')[1];
        const announcement = sessionStorage.getItem('auxAnnouncement');
        shareCoru2(
          socialNetwork,
          announcement,
          redirectLink,
          generateShortendLink,
        );
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    onCompleted({ generateShortendLink }) {
      const url = generateShortendLink.response;
      return url;
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createClickShareNetworkCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const ClickShareMongo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickShareSN'.concat(action),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickSocialSN')) {
      const dataJson = [
        {
          idSession: sessionStorage.getItem('id_session'),
          nameTraining: sessionStorage.getItem('trainingName'),
          medium: action,
          date: getDateStandart(),
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem('idClickSocialSN'),
      });
      const mongoInput = {
        input: {
          collection: 'LogShared',
          key: 'Shared',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idUser: sessionStorage.getItem('idUser'),
        Shared: [
          {
            idSession: sessionStorage.getItem('id_session'),
            nameTraining: sessionStorage.getItem('trainingName'),
            medium: action,
            date: getDateStandart(),
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'LogShared',
          data: dataString,
        },
      };
      createClickShareNetworkCollection({ variables: mongoInput });
    }
  };

  const shareTraining = (socialNetwork) => {
    console.log('Entra a share');
    ClickShareMongo(socialNetwork);
    let announcement;
    const viralityUrl = '/wo/card-funnel';

    announcement = socialNetwork === 'Whatsapp' ? 'CCSuccessWa' : 'CCSuccess';

    sessionStorage.setItem('auxAnnouncement', announcement);
    console.log(` insertDatVirality ${insertDatVirality}`);
    console.log(`viralityUrl ${viralityUrl}`);
    console.log(`socialNetwork ${socialNetwork}`);
    viralityUTM(
      insertDatVirality,
      viralityUrl,
      sessionStorage.getItem('trainingName'),
      socialNetwork,
      '',
      '',
      '',
      false,
      true,
    );
  };

  return (
        <>
        {cardsByProvider.map((card) => (
            <div key={card.IDProduct} className="d-flex flex-column" style={{
              minWidth: '320px', width: '320px', minHeight: '390px', maxHeight: '430px', padding: '11px', margin: '10px', boxShadow: '0px 4px 16px 0px rgb(0 0 0 / 20%)',
            }}>
                <div>
                  { !disableControlButtons 
                    ?  <div style={{
                      display: 'flex', justifyContent: 'space-between', width: '30%', marginLeft: '70%', marginBottom: '-20px',
                    }}>
                      {
                          card.FlagShow === '1'
                            ? <div style={{ zIndex: '1' }} onClick={() => agregarProducto(card, card.IDProduct)}><Image className={`car-${card.IDProduct}`} zindex="1" cursor='pointer' src={revisarProductoCartera(card.IDProduct)} width="20px" marginRight="0" marginRightM="0" /></div>
                            : null
                      }
                      <div style={{ zIndex: '1' }} onClick={() => saveProduct(card.IDProduct)}><Image className={`save-${card.IDProduct}`} zindex="1" cursor='pointer' src={Flag} width="20px" marginRight="0" marginRightM="0" height='20px' /></div>
                      <div style={{ zIndex: '1' }} onClick={() => setShowModal(true)}><Image zindex="1" cursor='pointer' src={Share} width="20px" marginRight="0" marginRightM="0" /></div>

                    </div> 
                    : ""}
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: '95px' }}>
                        {
                            card.FlagShow === '1'
                              ? <ImageSections pt="25px" width="70px" hmob="fit-content" ml="0" heightweb="fit-content" mhw="95px" mhm="95px" src={card.ImageURL} className="img-fluid" />
                              : <ImageSections pt="25px" width="70px" hmob="fit-content" ml="0" heightweb="fit-content" mhw="95px" mhm="95px" src={card.ImageURL} className="img-fluid" />
                        }
                        <div style={{ textAlign: 'right', paddingTop: '25px' }}>
                            <CardsTxt className="roboto" size="16px">
                                Anualidad
                            </CardsTxt>
                            {JSON.parse(card.productDetail.response).map((detalle) => {
                              if (detalle.Name === 'Anualidad') {
                                return (
                                        <CardsTxt key={detalle.Description} className="roboto" weight="600" size="18px">
                                            {detalle.Description.includes('USD')
                                              ? detalle.Description
                                              : (detalle.Description.includes('MXN') ? `$${detalle.Description}`
                                                : formatter.format(detalle.Description))}
                                        </CardsTxt>
                                );
                              }
                            })}
                        </div>
                    </div>

                    <DivStyle
                        className="cabin"
                        mh="60px"
                        mxh="60px"
                        weight="600"
                        align="left"
                        size="18px"
                        line="1.5em"
                        padd="12px 0"
                        color="#07210A"
                    >
                        {card.Name}
                    </DivStyle>
                    <DivStyle align="left" mh="110px" size="14px" >
                        <CardsTxt className="roboto" size="16px" weight="600">
                            Beneficios
                        </CardsTxt>
                        {JSON.parse(card.productDetail.response).map((detalle) => {
                          if (detalle.Name === 'Beneficio 1') {
                            return <li className="roboto styleBenef">{detalle.Description}</li>;
                          }
                          if (detalle.Name === 'Beneficio 2') {
                            return <li className="roboto styleBenef">{detalle.Description}</li>;
                          }
                        })}
                    </DivStyle>
                    <DivStyle align="left" mh="80px" size="14px">
                        <CardsTxt className="roboto" size="16px" weight="600">
                            Información extra
                        </CardsTxt>
                        {JSON.parse(card.productDetail.response).map((detalle) => {
                          if (detalle.Name === 'CAT') {
                            return (
                                    <li className="roboto styleBenef">
                                        CAT {(detalle.Description * 100).toFixed(2)}%
                                    </li>
                            );
                          }
                        })}
                        {JSON.parse(card.productDetail.response).map((detalle) => {
                          if (detalle.Name === 'Interés anual') {
                            return (
                                    <li className="roboto styleBenef">
                                        Tasas de interés {(detalle.Description * 100).toFixed(2)}%
                                    </li>
                            );
                          }
                        })}
                    </DivStyle>
                    {
                        card.FlagShow === '1'
                          ? <CardsBtn mtopmd="0" mtop="0" mtopw="0" min_w="100%" min_web="100%" onClick={() => applyCards([card.IDProduct])}>
                            Continuar
                          </CardsBtn>
                          : <CardsBtn mtopmd="0" mtop="0" mtopw="0" min_w="100%" min_web="100%" onClick={() => setModalAviso(true)}>
                            Continuar
                          </CardsBtn>
                    }

                </div>
                {
                    !modalAgregar ? null : <ModalAgregarCartera selected={selected} modalAgregar={modalAgregar} setModalAgregar={setModalAgregar} setSelectedComplete={setSelectedComplete} />
                }
                {
                    modalAviso ? <ModalNoDisponible setModalState={setModalAviso} handleReminder={reminder} idProduct={card.IDProduct} /> : null
                }
            </div>

        ))}

        <ModalCompartir
        show={showModal}
        setShow={setShowModal}
        children={
            <>
            <div style={{
              width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}>
                <div style={{ width: '85%', marginTop: '-15px' }}>
                <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" sizeM="20px" heightM=" " mleftM=" " style={{ marginBottom: '20px' }}>
                    Comparte en:
                </Subtitle>
                <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      borderBottom: '2px solid #CDCDCD',
                      paddingBottom: '20px',
                      cursor: 'pointer',
                      paddingTop: '20px',
                    }}
                    onClick={() => shareTraining('Facebook')}>
                    <img
                    alt="" position="initial" left="0"
                    width="50px" height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                    }}
                    src={face}
                    />
                    <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" heightM=" " mleftM=" " width=" " aling="center" marginTop=" ">
                    Facebook
                    </Subtitle>
                </div>
                <div
                    className="display-mobile-flex"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      borderBottom: '2px solid #CDCDCD',
                      paddingBottom: '20px',
                      cursor: 'pointer',
                      paddingTop: '20px',
                    }}
                    onClick={() => shareTraining('Messenger')}>
                    <img
                    alt="" position="initial" left="0" width="44px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '54px',
                      position: 'initial',
                      marginLeft: '0',
                    }}
                    src={twit}
                    />
                    <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" heightM=" " mleftM=" " width=" " aling="center" marginTop=" ">
                    Messenger
                    </Subtitle>
                </div>
                <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      paddingBottom: '20px',
                      cursor: 'pointer',
                      paddingTop: '20px',
                    }}
                    onClick={() => shareTraining('Whatsapp')}>
                    <img
                    alt="" position="initial" left="0" top="6%"
                    width="50px" height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                    }}
                    src={whats}
                    />
                    <Subtitle className="roboto" weight="bold" weightM="bold" size="25px" heightM=" " mleftM=" " width=" " aling="center" marginTop=" ">
                    Whatsapp
                    </Subtitle>
                </div>
                </div>
            </div>
            </>
            }
        />

    </>

  );
}
export default Products;
