/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';

// Import visual components
import { useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import {
  TrainingQuestion,
  Images,
  QuestionsDivs,
  QuestionsText,
  CardsBtn1,
  DivRespDP,
  DivTamFlex,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';

// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

// Other components
import ClickButton from '../../Helpers/HookHelpers';

// Stores
import Suburbia from '../../assets/img/anacoach/LogoSuburbia.png';
import Sears from '../../assets/img/anacoach/LogoSears.png';
import Liverpool from '../../assets/img/anacoach/LogoLiverpool.png';
import PalacioDeHierro from '../../assets/img/anacoach/LogoPalacioHierro.png';
import Coppel from '../../assets/img/anacoach/LogoCoppel.png';
import editIcon from '../../assets/img/anacoach/editar1.png';
import check from '../../assets/img/anacoach/ticCheck.svg';
import uncheck from '../../assets/img/anacoach/ticUncheck.svg';

const DeudasDepartamentales = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  activePossibleroutines = false,
  possibleRoutines = [],
  setNextNameRoutine = '',
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [stores, setStores] = useState(
    sessionStorage.getItem('DepartamentalesDeudas')
      ? sessionStorage.getItem('DepartamentalesDeudas').split(',')
      : [],
  );
  const [otherStore, setOtherStore] = useState(false);
  const [responseView, setResponseView] = useState('');

  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [ediTiendasD, setEdiTiendasD] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const { actions } = ClickButton();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const anchop = window.screen.width;

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          handleChangeRutine(Object.values(routines)[0]);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Special functions fot this component
  const pushStore = (store) => {
    setStores([...stores, store]);
  };

  const deleteStore = (store) => {
    const arregloTemp = stores.filter((e) => e !== store);
    setStores([]);
    setStores(arregloTemp);
  };
  const [atrasoPagos] = useState(
    sessionStorage.getItem('AtrasopPagos') || '',
  );

  const [changeStore, setChangeStore] = useState({
    store: '',
    doClass: '',
    id: '',
  });

  useEffect(() => {
    const { doClass, id } = changeStore;
    if (doClass === 'add') {
      document.getElementsByClassName(id)[0].src = check;
    } else if (doClass === 'remove') {
      document.getElementsByClassName(id)[0].src = uncheck;
    }
  }, [changeStore]);

  const handleChangeStoreDebts = (store, id) => {
    if (!stores.includes(store)) {
      pushStore(store);
      setChangeStore({ store, doClass: 'add', id });
    } else {
      deleteStore(store);
      setChangeStore({ store, doClass: 'remove', id });
    }
    const tagManagerArgs = {
      dataLayer: {
        event: 'SelectStoreDebts',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const choseOtherStore = () => {
    // setStores([]);
    setOtherStore(true);
  };
  const [otherDeps, setOtherDeps] = useState([]);
  const handleChangeOtherStore = (e) => {
    if (e.target.value !== '') {
      const temp = e.target.value.split(',');
      setOtherDeps(temp);
    }
  };

  const setAnswer = () => {
    const click = `EnviarDeudasDepartamentales_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    console.log('store', stores);
    let storeTemp = stores;
    storeTemp = storeTemp.concat(otherDeps);
    setStores(storeTemp);
    setEdiTiendasD(true);
    console.log(storeTemp);
    setResponseView(storeTemp.join());
    questions.push({
      id: 'stores',
      question: 1,
      value: storeTemp.join(),
    });

    createJsonInput(Rutine, questions);
    setQuestions([]);
    if (activePossibleroutines) {
      if (atrasoPagos === 'No') {
        setNextNameRoutine('SinAtrasoEnPagoDeuda');
        setOrderRutine(possibleRoutines[0]);
      } else if (atrasoPagos === 'Sí') {
        setNextNameRoutine('ConAtrasoEnPagoDeuda');
        setOrderRutine(possibleRoutines[1]);
      }
    } else {
      setOrderRutine(nextOrderRoutine);
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setStores(
        sessionStorage.getItem('DepartamentalesDeudas')
          ? sessionStorage.getItem('DepartamentalesDeudas').split(',')
          : [],
      );
      const storesRecovery = sessionStorage.getItem('DepartamentalesDeudas') || '';
      setResponseView(stores.join());
      console.log('storesRecovery', storesRecovery);
      if (storesRecovery !== '') {
        if (activePossibleroutines) {
          if (atrasoPagos === 'No') {
            setNextNameRoutine('SinAtrasoEnPagoDeuda');
            setOrderRutine(possibleRoutines[0]);
          } else if (atrasoPagos === 'Sí') {
            setNextNameRoutine('ConAtrasoEnPagoDeuda');
            setOrderRutine(possibleRoutines[1]);
          }
        } else {
          setOrderRutine(nextOrderRoutine);
        }
      }
    }
  }, [recoveryRoutine]);

  const handleChangeIcon = (json) => {
    setEdiTiendasD(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (currentRoutine === orderRutine
          || (lapiz[0].routine === 7 && lapiz[0].active === 1))
        && ediTiendasD !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeDeudasBancosDep' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeDeudasBancosDep"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="¿Con qué tiendas son tus principales deudas?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          (currentRoutine === orderRutine
            || (lapiz[0].routine === 7 && lapiz[0].active === 1))
          && ediTiendasD !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeStoreDebts('Liverpool', 'check-Liverpool')}
          data-MainDebts="Liverpool"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              left="0"
              leftw="15px"
              top="0"
              src={Liverpool}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Liverpool"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeStoreDebts(
            'Palacio de Hierro',
            'check-Palacio-de-Hierro',
          )
          }
          data-MainDebts="Palacio de Hierro"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              left="0"
              leftw="15px"
              top="0"
              src={PalacioDeHierro}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Palacio-de-Hierro"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeStoreDebts('Suburbia', 'check-Suburbia')}
          data-MainDebts="Suburbia"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              left="0"
              leftw="15px"
              top="0"
              src={Suburbia}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Suburbia"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeStoreDebts('Sears', 'check-Sears')}
          data-MainDebts="Sears"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              left="0"
              leftw="15px"
              top="0"
              src={Sears}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Sears"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeStoreDebts('Coppel', 'check-Coppel')}
          data-MainDebts="Coppel"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              left="0"
              leftw="15px"
              top="0"
              src={Coppel}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Coppel"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          txta="left"
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          className="border-pink"
          onClick={choseOtherStore}
        >
          <QuestionsText
            style={{ width: '60%' }}
            size="16px"
            weight="400"
            padd="5px"
          >
            Otras
          </QuestionsText>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-American-Express"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
      </TrainingQuestion>
      {otherStore && responseView === '' ? (
        <TrainingQuestion>
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="#fafafa"
          >
            <TextField
              variant="outlined"
              label={'Otra tienda'}
              autoComplete="off"
              required
              fullWidth
              className="textInput inputs-bottom input-coru"
              onChange={(e) => handleChangeOtherStore(e)}
            />
          </QuestionsDivs>
        </TrainingQuestion>
      ) : null}

      {((stores.length > 0 && responseView === '')
        || (lapiz[0].routine === 7 && lapiz[0].active === 1))
      && ediTiendasD !== true ? (
        <TrainingQuestion className="">
          <div style={{ display: 'flex' }}>
            <CardsBtn1
              type="button"
              min_w="100%"
              min_web="100%"
              windthmd="100%"
              mtopw="19px"
              mtop="19px"
              onClick={() => setAnswer()}
            >
              Enviar
            </CardsBtn1>
          </div>
        </TrainingQuestion>
        ) : null}
      <DivRespDP>
        <img
          alt="editIcon"
          className={responseView !== '' ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 1 })}
        />
        <CardTwo
          classCard={
            responseView !== ''
            && (ediTiendasD
              || !(lapiz[0].routine === 7 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={responseView}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </div>
  );
};

export default DeudasDepartamentales;
