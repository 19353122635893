/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { Fragment } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styled from "styled-components";
import ClickButton from "../../../Helpers/HookHelpers";
import BarNav from "./BarNav";
import KandaButton from "../utils/KandaButton";
import SectionShare from "../utils/SectionShare.js";
import { ContentGeneric, Logo } from "../Assets/styles/StyledGeneric.styled";
import LogoCoru from "../Assets/img/CoruLogo.svg";
import {
  HeaderHomeMob,
  HeaderHomeWeb,
  Image,
  ContentOptions,
  Togglebtn,
  Sidepannel,
  MainHeader,
  HeaderLogMob,
  SpaceMobileHeader,
} from "../Assets/styles/Footer.styled";

const TitleNavMobile = styled.p`
  position: static;
  width: auto;
  left: calc(50% - 256px / 2 + 45.5px);
  top: 0%;
  bottom: 96.02%;

  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props) => props.fw || "bold "};

  font-size: ${(props) => props.fz || " "};
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.003em;

  /* Body Copy Grey */

  color: ${(props) => props.color || "#252525"};

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: ${(props) => props.m || "24px 0px 0px 34px "};
`;

const Line = styled.hr`
  position: static;
  width: 165px;
  left: calc(50% - 165px / 2);
  top: 59.96%;
  bottom: 40.04%;

  /* Coru_Grey */

  border: 0.5px solid #cdcdcd;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: ${(props) => props.m || "24px 0px 0px 36px "};
`;

const Header = (props) => {
  const idUserDataSesion = !!sessionStorage.getItem("idUser");
  const idUserData = !!localStorage.getItem("idUser");
  const [viewMenu, setMenu] = React.useState(false);
  const { initializeLogin } = props;
  const { actions } = ClickButton();
  // const classes = useStyles();
  // const pathnameP = window.location.pathname;
  // const pathname = pathnameP.split('/')[1];
  // const idUser = parseInt(sessionStorage.getItem('idUser') || 0);
  // const idCont = sessionStorage.getItem('idCont');
  // const URLkanda = sessionStorage.getItem('url_kanda');
  const handleClickCoru = (name) => {
    switch (name) {
      case "Ingresar":
        if (idUserData && idUserDataSesion) {
          window.location.href = "/kanda/wo/diagnosticov3";
          actions({
            action: "ClickButtonCoruHome",
            variables: `Header${name}`,
          });
        } else {
          actions({
            action: "ClickButtonCoruHome",
            variables: `Header${name}`,
            redirection: "/login-v2",
            href: true,
          });
        }
        initializeLogin();
        break;
      case "CrearCuenta":
        if (sessionStorage.getItem("idUser")) {
          window.location.href = "/kanda/wo/diagnosticov3";
          actions({
            action: "ClickButtonCoruHome",
            variables: `Header${name}`,
            redirection: `/kanda/wo/diagnosticov3`,
            href: true,
          });
        } else {
          actions({
            action: "ClickButtonCoruHome",
            variables: `Header${name}`,
            redirection: `/personal-Information-v2`,
            href: true,
          });
        }
        initializeLogin();
        break;
      case "TuPlan":
        actions({
          action: "ClickButtonCoruHome",
          variables: `Header${name}`,
        });
        break;
      case "Soluciones":
        actions({
          action: "ClickButtonCoruHome",
          variables: `Header${name}`,
        });
        break;
      case "CoruMasterClass":
        actions({
          action: "ClickButtonCoruHome",
          variables: `Header${name}`,
        });
        break;
      case "XCoach":
        actions({
          action: "ClickButtonCoruHome",
          variables: `Header${name}`,
        });
        break;
      case "PreguntasFrecuentes":
        actions({
          action: "ClickButtonCoruHome",
          variables: `Header${name}`,
        });
        break;
      case "CatalogoDeProductos":
        actions({
          action: "ClickButtonProductos",
          variables: `Header${name}`,
          collection: "ClickButtonHeaderProductos",
        });
        break;
      case "ForBusiness":
        actions({
          action: "ClickButtonForBusiness",
          variables: `Header${name}`,
          collection: "ClickButtonHeaderFor_Business",
        });
        break;
      case "Nosotros":
        actions({
          action: "ClickButtonNosotros",
          variables: `Header${name}`,
          collection: "ClickButtonHeaderNosotros",
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MainHeader>
        <HeaderHomeWeb width="100%">
          <ContentOptions width="100%" Jc id="Contenidoid" className="col-12">
            <AnchorLink
              href="#Top"
              style={{ justifyConten: "end", width: "15%" }}
            >
              <Logo
                w="80%"
                src={LogoCoru}
                // width="30%"
                alignItems="right"
                m="auto"
                alt="logo"
              />
            </AnchorLink>
            <BarNav handleClickCoru={handleClickCoru} />
            <ContentGeneric width="10%" margin="2px 0 30px">
              <KandaButton
                action={() => handleClickCoru("Ingresar")}
                mr="54px"
                width="100%"
                height="19px"
                typeBtn="text"
              >
                Ingresar
              </KandaButton>
            </ContentGeneric>
            <ContentGeneric width="15%" margin="2px 0 30px">
              <KandaButton
                action={() => handleClickCoru("CrearCuenta")}
                mr="96px"
                width="100%"
                typeBtn="active"
              >
                Crear Cuenta
              </KandaButton>
            </ContentGeneric>
          </ContentOptions>
        </HeaderHomeWeb>

        <HeaderHomeMob width="100%">
          <Togglebtn
            color="#07210A"
            onClick={() => setMenu(true)}
            mfonts="28px"
          >
            ☰
          </Togglebtn>

          <AnchorLink href="#Top">
            <Logo src={LogoCoru} w="120px" m="14px 0px 0px 35px" alt="logo" />
          </AnchorLink>

          <KandaButton
            action={() => handleClickCoru("Ingresar")}
            mt="12px"
            ml700="0px"
            width="auto"
            height="19px"
            typeBtn="text"
          >
            Ingresar
          </KandaButton>
        </HeaderHomeMob>

        <Sidepannel
          className={viewMenu === true ? "sidepanel view" : "sidepanel no-view"}
          // id="mySidepanel"
        >
          <div className="closebtn roboto" onClick={() => setMenu(false)}>
            ×
          </div>
          <Image
            cursor="pointer"
            position="absolute"
            marginTop="-47px"
            left=" "
            marginLeft="8%"
            bottom="5%"
            src={LogoCoru}
            width="25%"
            alt="logo-coru"
          />
          <Fragment>
            <TitleNavMobile fz="18px" className="roboto" color="#393A80">
              {" "}
              ¡Dile adiós al estrés financiero!
            </TitleNavMobile>
            <Line />
            <HeaderLogMob>
              <BarNav handleClickCoru={handleClickCoru && setMenu} />
            </HeaderLogMob>
            <Line m="0px 0px 0px 36px" />
            {sessionStorage.getItem("hasSession") === undefined ||
            sessionStorage.getItem("hasSession") === null ? (
              <>
                <TitleNavMobile
                  color="#F64282"
                  m="15px 0px 34px 37px "
                  fz="16px"
                  className="roboto"
                  onClick={() => handleClickCoru("CrearCuenta")}
                >
                  Crear cuenta
                </TitleNavMobile>
                <TitleNavMobile
                  m="15px 0px 34px 37px "
                  fz="16px"
                  className="roboto"
                  onClick={() => handleClickCoru("Ingresar")}
                >
                  Iniciar sesión
                </TitleNavMobile>
              </>
            ) : (
              <TitleNavMobile
                color="#F64282"
                m="15px 0px 34px 37px "
                fz="16px"
                className="roboto"
                onClick={() => handleClickCoru("CerrarSesion")}
              >
                Cerrar sesión
              </TitleNavMobile>
            )}
          </Fragment>
          <div className="GradiBanMobile">
            <TitleNavMobile
              fw="400"
              m="20px 0px 4px 7px "
              fz="16px"
              className="roboto"
            >
              {" "}
              ¡Síguenos!
            </TitleNavMobile>
            <SectionShare />
          </div>
        </Sidepannel>
      </MainHeader>
    </>
  );
};

export default Header;
