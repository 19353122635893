import React from 'react';
import Header from '../common/DefaultHeader';
import { TrainigTam } from '../../assets/styles/Training.styled';

const HeaderSV = ({
  backgroundColor, pathname, headerType, backurl, pathnameSearch = '',
}) => (
            <>

                <TrainigTam><Header background={backgroundColor} pathname={pathname} headerType={headerType} backurl={backurl} /></TrainigTam>

            </>
);
export default HeaderSV;
