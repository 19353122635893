/* eslint-disable react/prop-types */
import React from "react";
import {
  ContainerGeneral,
  TextTitle1,
  ScalesContainer,
  ScalesContentCont,
  DecoSideBar,
  ContentSpan,
  ScaleInfoCont,
  SubContent

} from "../Assets/Styles/CaasNew.styles";

const GlobalScale = ({
  i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, selectLang
}) => {
  console.log('selectLang', selectLang);
  return (
  <ContainerGeneral direction="column" align="center" height="auto">
    <TextTitle1
      weight="600"
      size="48px"
      msize="32px"
      mobilSize="32px"
      margin="0px 0px 80px 0px"
      mobilMargin="0px 0px 80px 0px"
      tabletMargin="0px 0px 80px 0px"
    >
      {i1}
    </TextTitle1>
    <ScalesContainer justifyContent={selectLang === 3 ? 'center' : 'space-between' } gap={selectLang === 3 ? '50px' : '20px'}>
      <ScalesContentCont>
        <DecoSideBar />
        <ScaleInfoCont>
          <TextTitle1 margin="0px 0px 8px" className="roboto" weight="600" size="19px" msize="19px">
            {i2}
          </TextTitle1>
          <ContentSpan weight="600" msize="52px">
            {i3}
            <br />
            {i4}
          </ContentSpan>
          <SubContent textAlign="left" size="12px" color="#464646" margin="8px 0px 0px 0" line="16px">
            {i5}
          </SubContent>
        </ScaleInfoCont>
      </ScalesContentCont>
      <ScalesContentCont>
        <DecoSideBar />
        <ScaleInfoCont>
        <TextTitle1 margin="0px 0px 8px" className="roboto" weight="600" size="19px" msize="19px">
        {i6}{" "}
          </TextTitle1>
          <ContentSpan weight="600" msize="52px">
            {i7}
            <br />
            {i8}
          </ContentSpan>
          <SubContent textAlign="left" size="12px" color="#464646" margin="8px 0px 0px 0" line="16px">
            {i9}
          </SubContent>
        </ScaleInfoCont>
      </ScalesContentCont>
      <ScalesContentCont>
        <DecoSideBar />
        <ScaleInfoCont>
          <TextTitle1 margin="0px 0px 8px" className="roboto" weight="600" size="19px" msize="19px">
            {i10}{" "}
          </TextTitle1>
          <ContentSpan weight="600" msize="52px">
            {i11}
            <br />
            {i12}
          </ContentSpan>
           <SubContent textAlign="left" size="12px" color="#464646" margin="8px 20px 0px 0" line="16px">
            {i13}
          </SubContent>
        </ScaleInfoCont>
      </ScalesContentCont>
    </ScalesContainer>
  </ContainerGeneral>
  );
};

export default GlobalScale;
