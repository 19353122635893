import React, { Fragment, useState } from 'react';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import {
  QuestionsDivs,
  QuestionsText,
} from '../../assets/styles/Training.styled';

const InputsColumsTypeNumber = ({
  handleChangeCatOption,
  handleChangeQuestionsSend,
  paramsCat,
  QuestionsOrder,
  QuestionType,
  QuestionName,
  amountService,
  streaming = false,
  newServiceStreaming,
  newService,
  changeColor,
}) => {
  const [amount, setAmount] = useState(amountService || 0);
  const addToMoney = (e, id) => {
    setAmount(amount + 50);
    handleChangeCatOption(
      paramsCat,
    );
    handleChangeQuestionsSend(
      QuestionsOrder,
      QuestionName,
      (amount + 50),
      QuestionType,
    );
  };

  const subtractToMoney = (e, id) => {
    if (amount !== 0) {
      setAmount(amount - 50);
      handleChangeCatOption(
        paramsCat,
      );
      handleChangeQuestionsSend(
        QuestionsOrder,
        QuestionName,
        (amount - 50),
        QuestionType,
      );
    }
  };

  const handleSelected = () => {
    handleChangeCatOption(
      paramsCat,
    );
    handleChangeQuestionsSend(
      QuestionsOrder,
      QuestionName,
      amountService,
      QuestionType,
    );
    changeColor[`clic_${paramsCat.step}`] = !changeColor[`clic_${paramsCat.step}`];
  };

  return (

        <Fragment
        >
            {streaming || newServiceStreaming
              ? <div style={{ display: 'flex', width: '100%' }}>
                    <QuestionsDivs
                        onClick={() => handleSelected()}
                        name={QuestionName}
                        txta="left"
                        padd="14px 5px"
                        width="80%"
                        margin="8px 5px auto auto;"
                        hei="50px"
                        bs="0px 2px 8px rgba(0,0,0,0.08)"
                    >
                        <QuestionsText
                            size="14px"
                            weight="400"
                        >
                            {newServiceStreaming ? '' : QuestionName}
                        </QuestionsText>
                    </QuestionsDivs>
                    <QuestionsDivs
                        padd="14px 5px"
                        width="80%"
                        margin="8px auto auto 5px"
                        hei="50px"
                        bs="0px 2px 8px rgba(0,0,0,0.08)"
                        style={{ background: changeColor[`clic_${paramsCat.step}`] ? '' : '#A6A6A6' }}
                    >
                        <QuestionsText
                            size="14px"
                            weight="400"
                        >
                            {amountService}
                        </QuestionsText>
                    </QuestionsDivs>
                </div>
              : <div style={{ display: 'flex', width: '100%' }}>
                    <QuestionsDivs
                        onClick={() => {
                          handleChangeCatOption(
                            paramsCat,
                          );
                          handleChangeQuestionsSend(
                            QuestionsOrder,
                            QuestionName,
                            amount,
                            QuestionType,
                          );
                        }}
                        name={QuestionName}
                        txta="left"
                        padd="14px 5px"
                        width="80%"
                        margin="8px 5px auto auto;"
                        hei="50px"
                        bs="0px 2px 8px rgba(0,0,0,0.08)"
                    >
                        <QuestionsText
                            size="14px"
                            weight="400"
                        >
                            {newServiceStreaming ? '' : QuestionName}
                        </QuestionsText>
                    </QuestionsDivs>
                    <QuestionsDivs
                        padd="14px 5px"
                        width="80%"
                        margin="8px auto auto 5px"
                        hei="50px"
                        bs="0px 2px 8px rgba(0,0,0,0.08)"
                        style={{ display: 'flex', justifyContent: 'space-around' }}
                    >
                        <RemoveCircleOutlineOutlinedIcon onClick={(e) => subtractToMoney(e, 'Ejemplo')}></RemoveCircleOutlineOutlinedIcon>
                        <QuestionsText
                            size="14px"
                            weight="400"
                            id="amountReviewEjemplo"
                        >
                            {/* {newService ? '' : '$0'} */}
                            {'$'}{amount}
                        </QuestionsText>
                        <AddCircleOutline onClick={(e) => addToMoney(e, 'Ejemplo')}></AddCircleOutline>
                    </QuestionsDivs>
                </div>
            }
        </Fragment>
  );
};
export default InputsColumsTypeNumber;
