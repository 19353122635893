import React, { useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Subtitle } from '../Styleds/Asesores.Styled.js';
import styled from 'styled-components';

const DynamicDropdown = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <Contenedor>
      <Header
        onClick={() => setOpen(!open)}
      >
        <Subtitle>{title}</Subtitle>
        {
          open ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />
        }
      </Header>
      <Body open={open}>
        {children}
      </Body>
    </Contenedor>
  )
}

const Contenedor = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  `;
  const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  `;
  const Body = styled.div`
    display: ${(props) => props.open ? 'grid' : 'none' };
    width: 100%;
    animation: ease-in-out .5s;
  `;

export default DynamicDropdown;
