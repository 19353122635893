/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { KandaAvatar } from '../Assets/img/KandaAvatar';
import {
  // Button,
  Message,
  Messages,
  MessagesContainer,
  PicKandaBot,
  Text,
  TextList,
} from '../Assets/styles/KandaContent.styled';
import ShareBF from '../Assets/img/ShareBf.svg';
import ShareC from '../Assets/img/ShareCafe.svg';
import IconGo from '../Assets/img/Go.svg';
// eslint-disable-next-line import/no-unresolved
import Experto from '../Assets/img/Experto2.svg';
import ImgUniversal from '../Assets/img/Universal.svg';
import ImgReforma from '../Assets/img/Reforma.svg';
import ImgPublimero from '../Assets/img/Publimero.svg';
import ImgTrustP from '../Assets/img/trustpilot.svg';
import KandaButton from '../utils/KandaButton';
import { GET_STATUS_VISIT_KANDA, GET_URL_KANDA } from '../../../queries';
import { SET_VISIT_KANDA } from '../../../mutations';
import ClickButton from '../../../Helpers/HookHelpers';

import ShareBtn from '../utils/ShareBtn';
import Stars from '../Assets/img/Stars.svg';

import {
  ContentGeneric,
  CardGeneric,
  Container1,
  Text3,
  TextSmall,
  TextSmallPink,
  CardGenericTwo,
  HeadingTwo,
  Description,
  ContainerExpertos,
  ImgExperto,
  Text2,
  ImgPrensa,
  SubContainer,
  ItemBox,
  DateCard,
  TitleCard,
  ImgTrustPilot,
  ImgStars,
} from '../Assets/styles/StyledGeneric.styled';

const DataBaseTwo = [
  {
    id: 1,
    name: 'El Universal',
    imgPrensa: ImgUniversal,
    fecha: ' Ene 9, 2022',
    text: 'Una encuesta realizada por la empresa especializada en finanzas personales, Coru, revela que en México solamente 34% de las personas gasta menos de lo que gana.',
    urlMedios:
      'https://www.eluniversal.com.mx/cartera/inflacion-se-come-el-aumento-de-salario',
  },
  {
    id: 2,
    name: 'Publimetro',
    imgPrensa: ImgPublimero,
    fecha: 'Nov 21, 2021',
    text: 'Según una encuesta de Coru, tres de cada 10 mexicanos con ingresos mensuales inferiores a 10 mil pesos tienen el potencial de ahorrar.',
    urlMedios:
      'https://www.publimetro.com.mx/noticias/2021/11/22/10-errores-financieros-que-comenten-los-mexicanos-y-les-impiden-ahorrar/',
  },
  {
    id: 3,
    name: 'Reforma',
    imgPrensa: ImgReforma,
    fecha: 'Oct 29, 2021',
    text: 'Para 87 por ciento de los mexicanos, es decir, 9 de cada 10, sus finanzas personales son fuente de ansiedad y estrés, según la fintech de bienestar financiero Coru.',
    urlMedios:
      'https://www.reforma.com/estresan-finanzas-a-9-de-cada-10-mexicanos/ar2287189',
  },
];

const DataBase = [
  {
    id: 1,
    coment: 'Excelente página',
    where: 'Testimonio en Trustpilot',
    textComent:
      'Muy buena página, me ayudaron a organizar mis gastos y con eso pude liquidar mis deudas. También me ayudaron a reconstruir mi historial crediticio con una tarjeta de crédito.',
  },
  {
    id: 2,
    coment: 'Crédito',
    where: 'Testimonio en Trustpilot',
    textComent:
      'Pues gracias a Coru pude conseguir mi primera tarjeta de crédito... Gracias!!!!!.',
  },
  {
    id: 3,
    coment: 'Es un buen apoyo',
    where: 'Testimonio en Trustpilot',
    textComent:
      'Es un buen apoyo, ya que ahorra tiempo y puede comparar entre varias opciones, muy buen servicio.',
  },
];

// eslint-disable-next-line react/prop-types
export const CreaTuPlanFinanciero = ({ tituloSecundario, shareCoru }) => {
  // eslint-disable-next-line no-unused-vars
  const [clickeado, setClickeado] = useState(false);
  const [urlKanda, setUrlKanda] = useState('');
  const shortenedM = '7YA6olOpcski7CH';
  const shortenedW = 'Eh3xG5rNPpL4Z2f';
  const shortenedF = 'Qag749RGScMZJ89';
  const message = 'Oye, descubrí una plataforma que se llama Coru con la que puedes saber qué pasa con tus finanzas, crear un plan personalizado y hacer entrenamientos para manejar tus finanzas ¡sin costo! 😇:';
  const { actions } = ClickButton();
  const goToKanda = (name) => {
    const pathnameP = window.location.pathname;
    const pathname = pathnameP.split('/')[1];
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    actions({
      action: 'ClickButtonCoruHome',
      variables: name,
      redirection: `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda#${idUser}#${idCont}`,
      href: true,
    });
  };

  const [getStatusVisitKanda] = useLazyQuery(GET_STATUS_VISIT_KANDA, {
    onCompleted({ getStatusVisitKanda }) {
      try {
        const json = JSON.parse(getStatusVisitKanda.response);
        if (json.length > 0) {
          if (json[0].WoKanda === true) {
            setClickeado(true);
          }
        }
      } catch (error) {
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getUrlKanda] = useLazyQuery(GET_URL_KANDA, {
    // eslint-disable-next-line no-shadow
    onCompleted({ getUrlKanda }) {
      try {
        const obj = JSON.parse(getUrlKanda.response)[0] || '';
        setUrlKanda(obj.Path);
        sessionStorage.setItem('url_kanda', obj.Path);
      } catch (error) {
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [setVisitKanda] = useMutation(SET_VISIT_KANDA, {
    // eslint-disable-next-line no-shadow
    onCompleted({ setVisitKanda }) {
      if (setVisitKanda.message === 'success') {
        setClickeado(true);
        goToKanda('Hacermidiagnóstico');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line radix
    const idUser = parseInt(sessionStorage.getItem('idUser') || 0);
    if (idUser) {
      getStatusVisitKanda({
        variables: { idUser },
      });
    }
  }, [sessionStorage.getItem('idUser')]);

  useEffect(() => {
    getUrlKanda({
      variables: { appName: 'DiagnosticoX' },
    });
  }, []);

  const handleClick = () => {
    const pathnameP = window.location.pathname;
    const pathname = pathnameP.split('/')[1];
    // eslint-disable-next-line radix
    const idUser = parseInt(sessionStorage.getItem('idUser') || 0);
    if (idUser) {
      setVisitKanda({
        variables: {
          kandaEntered: 1,
          idUser,
        },
      });
    } else if (pathname === '') {
      actions({
        action: 'ClickButtonCoruHome',
        variables: 'LPTuPlan',
        redirection: `/lp/tu-plan`,
        href: true,
      });
      // window.location.href = `${urlKanda}?utm_medium=coru&utm_source=Home&utm_campaign=Kanda`;
    } else {
      actions({
        action: 'ClickButtonCoruHome',
        variables: 'Hacermidiagnóstico',
        redirection: `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda`,
        href: true,
      });
      // window.location.href = `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda`;
    }
  };
  return (
    // Crea tu plan
    <ContentGeneric className=" Container1 " id="TuPlan">
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className="ContainerTitleShare1">
        <HeadingTwo
          h375="19px"
          w375="283px"
          h425="22px"
          hm="22px"
          ml700="17px"
          ml425="15px"
          fz375="26px"
          className="bold"
          m="0px 12px 0px 0px"
          color="#393A80">
          {tituloSecundario}
        </HeadingTwo>
        <ShareBtn
          className="ItemShare1"
          Micon="10px 0px 0px 10px"
          bckRedS="#393a80"
          bckRedShover="#393a80"
          ShareImgC={ShareBF}
          bckicono="#393a80"
          seccion="CreaTuPlanFinanciero"
          message={message}
          shortenedM={shortenedM}
          shortenedW={shortenedW}
          shortenedF={shortenedF}
          shareCoru={shareCoru}
        />
      </div>
      <Description
        h375="84px"
        w375="320px"
        mD700="12px auto 15px "
        m="0px 0px 48px"
        fz="18px"
        className="roboto"
        color="#393A80">
        Conoce tus finanzas actuales, crea tu plan personalizado y practica en
        diferentes escenarios para mantener tu tranquilidad financiera.
      </Description>

      <MessagesContainer>
        <PicKandaBot>
          <KandaAvatar />
        </PicKandaBot>
        <Messages>
          <Fragment>
            <Message w="403px" h="41px" hm="58px">
              <Text>
                ¿Te gustaría sentirte más tranquilo con tu salud financiera?
              </Text>
            </Message>
            <Message>
              <Text list>
                <TextList>1</TextList>Realiza tu diagnóstico
              </Text>
              <Text list>
                <TextList>2</TextList>Descubre tu plan personalizado
              </Text>
              <Text list>
                <TextList>3</TextList>
                <b>¡Dile adiós al estrés financiero! 🙌</b>
              </Text>
            </Message>
          </Fragment>
        </Messages>
      </MessagesContainer>
      <KandaButton
        margin="0px 0px 0px 164px"
        width="64%"
        w390="66%"
        ml390="97px"
        ml425 ="95px"
        typeBtn="active"
        ml700="23%"
        w700="69%"
        action={() => handleClick()}
        id="SolucionesMobile">
        Crear Tu Plan
      </KandaButton>
    </ContentGeneric>
  );
};

export const PlaticaConExpertos = ({
  tituloSecundario,
  handleClick,
  shareCoru,
}) => {
  const shortenedM = 'XLMXX4AtSQ6gay4';
  const shortenedW = '6WvCC19MgJrOmxf';
  const shortenedF = 'pZd3agedcoAojEW';
  const message = 'Oye, descubrí una plataforma que se llama Coru. Si tienes dudas de finanzas ahí puedes platicar con un experto 😎 sin costo.';
  return (
    <ContentGeneric id="XCoach" className="Container4">
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className="ContainerTitleShare4">
        <HeadingTwo
           ml700="17px"
          h375="47px"
          ml375="27px"
          w375="283px"
          fz375="26px"

          className="bold"
          m="0px 12px 14px 0px"
          color="#80460C">
          {tituloSecundario}
        </HeadingTwo>
        <ShareBtn
          Micon="10px 0px 0px 10px"
          className="ItemShare4"
          bckRedS="#80460C"
          bckRedShover="#80460C"
          ShareImgC={ShareC}
          bckicono="#80460C"
          seccion="PlaticaConExpertos"
          message={message}
          shortenedM={shortenedM}
          shortenedW={shortenedW}
          shortenedF={shortenedF}
          shareCoru={shareCoru}
        />
      </div>
      <ContainerExpertos>
        <Text3 m="0px 14px 0px 0px" className="roboto" w700="55%">
          ¿No sabes por dónde empezar? Platica con uno de nuestros XCoaches o
          expertos financieros para recibir atención personalizada.
        </Text3>
        <ImgExperto src={Experto} alt="ImgExperto" />
      </ContainerExpertos>

      <KandaButton
        display="flex"
        ml="4px"
        fd="row"
        jc="start"
        ml375="24px"
        w375="315px"
        p="0px 0px"
        width="250px"
        typeBtn="text"
        action={() => handleClick('PlaticaExpertos', 'Whatsapp_Xcoaches')}>
        Contacta a un experto
        <img
          alt="icon"
          src={IconGo}
          style={{
            width: '16px',
            height: '16px',
            marginTop: '2px',
            marginLeft: '12px',
            marginRight: '40px',
          }}
        />
      </KandaButton>
    </ContentGeneric>
  );
};

export const QuienesYaProbaronCoru = ({ tituloSecundario }) => (
    <ContentGeneric>
      <HeadingTwo
        ml700="3.4%"
        className="bold"
        h375="20px"
        w375="304px"
        m="0px 0px 26px 0px"
        color="#AC2E5B">
        {tituloSecundario}
      </HeadingTwo>
      <Container1>
        {DataBase.map((data, idx) => (
          <CardGenericTwo key={idx}>
            <ImgStars src={Stars} alt="ImgStarts" />
            <Text2 className="bold" fz="20px" color="#AC2E5B" w425="100%" m="10px 0px ">
              {data.coment}
            </Text2>
            <Text2 fw="400" className="roboto" fz="14px" w425="100%" m="1px 0px" color="#AC2E5B">
              {data.where}
            </Text2>
            <TextSmallPink className="roboto">
              "{data.textComent}"
            </TextSmallPink>
          </CardGenericTwo>
        ))}
      </Container1>
      <div style={{ marginTop: '5%' }}>
        <div className="trustpilot-widget" data-locale="es-ES" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5bec5c50eeebd0000156321b" data-style-height="150px" data-style-width="100%" data-theme="light">
          <a href="https://es.trustpilot.com/review/coru.com" target="_blank" rel="noopener noreferrer">
            <ImgTrustPilot src={ImgTrustP} alt="ImgTraspilot" />
          </a>
        </div>
      </div>

    </ContentGeneric>
);

export const QueDicenLosMedios = ({ tituloSecundario }) => (
    <ContentGeneric>
      <HeadingTwo
       h700="auto"
        ml700="3%"
         h375="13px"
        w375="281px"

        className="bold"
        m="0px 12px 36px 0px"
        color="#AC2E5B">
        {tituloSecundario}
      </HeadingTwo>

      <Container1 dis375="none" dis700="none" fd="row" fd769="row">
        {DataBaseTwo.map((data, idx) => (
          <CardGeneric key={idx}>
            <a target="_blank" href={data.urlMedios} rel="noreferrer">
              <SubContainer>
                <ImgPrensa src={data.imgPrensa} alt="Prensa" />
                <ItemBox>
                  <TitleCard className="roboto">{data.name}</TitleCard>
                  <DateCard className="roboto">{data.fecha}</DateCard>
                </ItemBox>
              </SubContainer>
              <TextSmall className="roboto">{data.text}</TextSmall>
            </a>
          </CardGeneric>
        ))}
      </Container1>

      <Container1 h375="300px" dis768="none" id="carouselMedios" >
        <Carousel
          id="CarruselEmpresaWebLp"
          width="100%"
          h
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          // autoPlay
          // infiniteLoop
          // transitionTime={2500}
          renderIndicator={(onClickHandler, isSelected, index) => {
            const defStyle = {
              width: '12px',
              height: '12px',
              marginLeft: 10,
              opacity: '54%',
              background: 'rgb(172,46,101, .54)',
              cursor: 'pointer',
              // border: "1px solid #000000",
              boxSizing: 'borderBox',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '50%',
            };
            const style = isSelected
              ? { ...defStyle, background: '#AC2E5B' }
              : { ...defStyle };
            return (
              <div
                style={style}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                // aria-label={`${label} ${index + 1}`}
              ></div>
            );
          }}>
          {DataBaseTwo.map((data, idx) => (
            <CardGeneric key={idx}>
              <a href={data.urlMedios} target="_blank" rel="noreferrer">
                <SubContainer>
                  <ImgPrensa src={data.imgPrensa} alt="Prensa" />
                  <ItemBox>
                    <TitleCard className="roboto">{data.name}</TitleCard>
                    <DateCard className="roboto">{data.fecha}</DateCard>
                  </ItemBox>
                </SubContainer>
                <TextSmall className="roboto">{data.text}</TextSmall>
              </a>
            </CardGeneric>
          ))}
        </Carousel>
      </Container1>
      <div id="preguntasMobile"></div>
    </ContentGeneric>
);

export const Sections = [
  CreaTuPlanFinanciero,
  PlaticaConExpertos,
  QuienesYaProbaronCoru,
  QueDicenLosMedios,
];
