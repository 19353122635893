import React, { useEffect } from "react";
import Lottie from "react-lottie";
import CoruAnim1 from "../Assets/Lotties/Coru-gifheroCorto-spa.json";
import CoruAnim1Eng from "../Assets/Lotties/Coru-gifheroCorto-eng.json";
import CoruAnim1Por from "../Assets/Lotties/Coru-gifheroCorto-port.json";

import {
  ContainerPage1,
  TextTitle1,
  ContentSpan,
  Button2,
  Video,
  LpFirstSectionContent
} from "../Assets/Styles/CaasNew.styles";
import ClickButton from "../../../Helpers/HookHelpers";
import { Box } from "@material-ui/core";

const EmpowerBusiness = ({
  i1, i2, i3, i4, i5, i6, language, url
}) => {
  const { actions } = ClickButton();
  useEffect(() => {
    const videostatus = document.getElementById("video1");
  }, []);

  const handleClickCoru = (name) => {
    switch (name) {
      case "solutions":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_solutions_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "products":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_products_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "coru":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_coru_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "successstories":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_casosexito_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "traductor":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_traductor_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "getstarted":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_demo_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonReadytoStartHero":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonDemo_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        // window.location.href = "/caas/agendademo";
        window.open(url, '_blank');
        break;
      case "BottonLetsGetToWork":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_btndemoProductos_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonStartnow":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonStartnow`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonSeeallProducts":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonSeeallProducts`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      default:
        break;
    }
  };

  return (
    <ContainerPage1>
      <LpFirstSectionContent>
        <Box
          maxWidth="425px"
        >
        <TextTitle1 size="52px" weight="900" mobilMargin="0">
            {/* IA */}
          <ContentSpan size="52px" weight="900" style={{ background: 'none', color: '#006DFF'}}>{i1}</ContentSpan>{" "}
          {/* que habla el idioma de tu negocio */}
          {i2}
        </TextTitle1>
                <Button2
                  margin="32px 0 0"
                  onClick={() => handleClickCoru("BottonReadytoStartHero")}
                >
                  {i5}
                </Button2>
        </Box>
        <Video>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: language === 1 ? CoruAnim1 : language === 2 ? CoruAnim1Eng : CoruAnim1Por,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </Video>
      </LpFirstSectionContent>
    </ContainerPage1>
  );
};

export default EmpowerBusiness;
