import React, { Fragment, useState } from "react";
import {
  SubtitleOne,
  Container,
  Col12,
} from "../../components/common/common.styled";
import "../../assets/styles/TextCustom.css";
import InputAna from "../../components/common/InputAna";

const DataBusiness = ({
  form, setForm, setvalidCampos, validCampos
}) => {
  const [error] = useState({
    Empresa: false,
    Posicion: false,
    Sitio: false,
    Empresavalid: -1,
    Posicionvalid: -1,
    Sitiovalid: -1,
  });
  const [texterror] = useState({
    Empresa: "",
    Posicion: "",
    Sitio: "",
  });

  const ValidCampos = () => {
    if (
      (!error.Empresa && form.Empresa.length >= 3) &&
      (!error.Posicion && form.Posicion.length >= 3) &&
      (!error.Sitio && form.Sitio.length >= 3)
     ) {
       setvalidCampos({
         ...validCampos, 
         empresa: true
       });
     }
     else{
       setvalidCampos({
         ...validCampos, 
         empresa: false
       });
     }
  }

  const ValidLetras = (e) => {
    var nombre = e.target.name;
    var valor = e.target.value;

    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ""),
    });
    if (valor.length > 2) {
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = "";
    } else if (nombre === "secondname") {
      if (valor.length === 0) {
        error[nombre] = false;
        texterror[nombre] = "";
        error[`${nombre}valid`] = -1;
      } else {
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = "Debe ingresar más de 3 caracteres";
      }
    } else {
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = "Debe ingresar más de 3 caracteres";
    }
    ValidCampos();
  };

  const ValidLetrasNum = (e) => {
    var nombre = e.target.name;
    var valor = e.target.value;

    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ0-9 ?=(?:.,{}*\d@)]/g, ""),
    });
    if (valor.length > 2) {
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = "";
    } else if (nombre === "secondname") {
      if (valor.length === 0) {
        error[nombre] = false;
        texterror[nombre] = "";
        error[`${nombre}valid`] = -1;
      } else {
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = "Debe ingresar más de 3 caracteres";
      }
    } else {
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = "Debe ingresar más de 3 caracteres";
    }
    ValidCampos();
  };

  return (
    <Fragment>
      <SubtitleOne
        className="font-weight-bold roboto"
        size="16px"
        sizeMobile="16px"
        mb="0px"
        mt="20px">
       Empresa
      </SubtitleOne>
      <form autoComplete="off" className="col-12" style={{ padding: '0px' }}>
        <Container width="100%" widthMobile="100%">
          <Col12 padding="0px 2.5px 0px 0px" pt="2%">
            <InputAna
              placeholder="Empresa"
              value={form.Empresa}
              id={"Empresa"}
              type="text"
              handleChange={ValidLetras}
              handleBlur={ValidLetras}
              error={error.Empresa}
              texterror={texterror.Empresa}
              errorinicial={error.Empresavalid}
            />
          </Col12>
          <Col12 padding="0px 0px 0px 2.5px" pt="2%">
          <InputAna
              placeholder="Posición"
              value={form.Posicion}
              id={"Posicion"}
              type="text"
              handleChange={ValidLetras}
              handleBlur={ValidLetras}
              error={error.Posicion}
              texterror={texterror.Posicion}
              errorinicial={error.Posicionvalid}
            />
          </Col12>
          <Col12 padding="0px 2.5px 0px 0px" pt="2%">
          <InputAna
              placeholder="Sitio Web"
              value={form.Sitio}
              id={"Sitio"}
              type="text"
              handleChange={ValidLetrasNum}
              handleBlur={ValidLetrasNum}
              error={error.Sitio}
              texterror={texterror.Sitio}
              errorinicial={error.Sitiovalid}
            />
          </Col12>
        </Container>
      </form>
    </Fragment>
  );
};

export default DataBusiness;
