import React, {
  Fragment, useState, useEffect, useCallback,
} from 'react';
// Modal
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Container,
  Col3,
  ParagrapTwo,
  ParagraphOne,
  SubtitleOne,
} from '../../common/common.styled';
import LogoCoru from '../Assets/img/CoruLogo.svg';
import Avatar from '../Assets/img/Modal_Home_Avatar.svg';
import Mensaje from '../Assets/img/Modal_Home_Balloon.svg';
import KandaButton from '../utils/KandaButton';
import ClickButton from '../../../Helpers/HookHelpers';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

function ModalAviso(openModal) {
  const classes = useStyles();
  const { actions } = ClickButton();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const pathnameP = window.location.pathname;
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickCoru = (name) => {
    document.getElementsByTagName("html")[0].style.overflowY = "auto";
    switch (name) {
      case "Ingresar":
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Modal${name}`,
          redirection: '/login',
          href: true,
        });
        break;
      case "CrearCuenta":
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Modal${name}`,
          redirection: `/user-validation/`,
          href: true,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (openModal.openModal) {
       document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    } else {
       document.getElementsByTagName("html")[0].style.overflowY = "auto";
    }
 }, [openModal.openModal]);
  return (
    <Fragment>
      <Modal
        open={openModal.openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{overflow:"hidden"}}
        className="backdrop-blur"
      >
        <div style={modalStyle} className={classes.paper}>
          <ParagrapTwo
            fontWeight="700"
            cursor="pointer"
            texttalign="right"
            textalignMobile="right"
            className="mx-auto"
            onClick={handleClose}
          >
            
          </ParagrapTwo>
            <div className='row'>
                <div className='col-12' style={{marginBottom:"5%"}}>
                    <img src={LogoCoru} className="center" style={{width:"120px"}}/>
                </div>
                <div className='col-4' style={{padding:"0px", textAlign:"right"}}>
                    <img src={Avatar} style={{ }}/>
                </div>
                <div className='col-8' style={{padding:"0px", margin:"auto"}}>
                    <img src={Mensaje} style={{margin:"auto"}}/>
                    <SubtitleOne
                        mt="-23%"
                        mb="0px"
                        textalingn="center"
                        className="font-weight-bold roboto"
                        sizeMobile="20px"
                        size="20px"
                        textalingnMobile="center"
                    >
                        ¡Descúbrelo <br/> muy pronto!
                    </SubtitleOne>
                </div>
            </div>
          <SubtitleOne
            mt="5%"
            mb="10%"
            className="font-weight-bold roboto"
            sizeMobile="14px"
            size="14px"
            textalingn="center"
            textalingnMobile="center"
          >
              Estamos transformando <br/> nuestro sitio para ti.
          </SubtitleOne>
          <ParagrapTwo textalignMobile="center" texttalign="center"  className="mx-auto roboto" mb="10%" mbweb="10%" fontSize="14px" fontSizeWeb="14px">
            No te preocupes, aún puedes ingresar y encontrar <br/> soluciones que se ajusten a tus necesidades.
          </ParagrapTwo>
          <KandaButton
            action={() => handleClickCoru('CrearCuenta')}
            margin="auto" width="269px" typeBtn="active" ml700="auto">
            Crear Cuenta
          </KandaButton>
          <KandaButton
            margin="5% auto"
            action={() => handleClickCoru('Ingresar')}
            mr="auto" width="100%" height="19px" typeBtn="text" ml700="auto">
            Ingresar
          </KandaButton>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalAviso;
