import React, { useState, Fragment, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import {
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import ana from '../../assets/img/anacoach/CoachImage.png';
import imgWhats from '../../assets/img/anacoach/WhatsApp.png';
import imgPhone from '../../assets/img/anacoach/Tel.png';
import imgMail from '../../assets/img/anacoach/Email.png';
import Clock from '../../assets/img/anacoach/clockTraining.png';
import candado from '../../assets/img/logros/Candado.png';
import Starttraining from '../Training/Starttraining';
import {
  Feed_Main,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from './Home.styled';
import {
  TrainingSubtitles,
  Images,
  TrainingText,
} from '../../assets/styles/Training.styled';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
  TextTime,
  ContentTimer,
  ContEntrenamientos,
} from '../../assets/styles/Home.styled';
import ModalDiv from './ModalDiv';
import ClickButton from '../../Helpers/HookHelpers';

const RpThanks = ({
  Date,
  hora,
  currentTraining,
  insuranceActive,
  productos,
  handleClickTraining,
}) => {
  const { actions } = ClickButton();
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
 
  const [catContactPhone, setcatContactPhone] = useState('');
  const [catContactMail, setcatContactMail] = useState('');
  const [show, setShow] = useState(false);
  const anchop = window.screen.width;

  useEffect(() => {
    setcatContactPhone(sessionStorage.getItem('CellPhone') || '');
  }, []);
  useEffect(() => {
    setcatContactMail(sessionStorage.getItem('Email1') || '');
  }, []);
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleChangeContactPhone = (e) => {
    console.log(e.target.value);
    setcatContactPhone(e.target.value);
  };
  const handleChangeEmail = (e) => {
    console.log(e.target.value);
    setcatContactMail(e.target.value);
  };

  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const showModalWhatsapp = () => {
    setShowWhatsapp(true);
  };
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      console.log(dynamicUpdate);
    },
    onError() {},
  });
  const handleCloseModalWhatsapp = () => {
    setShowWhatsapp(false);
    if (
      catContactPhone !== sessionStorage.getItem('CellPhone')
      && catContactPhone !== ''
    ) {
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactMedium',
            columns: {
              setkey: 'ContactPhone',
              setval: String(catContactPhone),
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
    }
    showModalThanks();
  };

  const [showEmail, setShowEmail] = useState(false);
  const showModalEmail = () => {
    setShowEmail(true);
  };
  const handleCloseModalEmail = () => {
    setShowEmail(false);
    if (
      catContactMail !== sessionStorage.getItem('Email1')
      && catContactMail !== ''
    ) {
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactMedium',
            columns: {
              setkey: 'Email2',
              setval: String(catContactMail),
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
    }
    showModalThanks();
  };

  const [showPhone, setShowPhone] = useState(false);
  const showModalPhone = () => {
    setShowPhone(true);
  };

  const handleCloseModalPhone = () => {
    setShowPhone(false);
    if (
      catContactPhone !== sessionStorage.getItem('CellPhone')
      && catContactPhone !== ''
    ) {
      dynamicUpdate({
        variables: {
          input: {
            table: 'CatContactMedium',
            columns: {
              setkey: 'ContactPhone',
              setval: String(catContactPhone),
            },
            conditions: {
              valone: 'CatContact_IDCont',
              valtwo: sessionStorage.getItem('idCont'),
              condition: '=',
            },
          },
        },
      });
    }

    showModalThanks();
  };
  const [showThanks, setShowThanks] = useState(false);
  const showModalThanks = () => {
    setShowThanks(true);
  };
  const handleCloseModalThanks = () => {
    setShowThanks(false);
  };
  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';

  firstname = sessionStorage.getItem('FirstName');
  nameClient = `${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem(
    'FathersLastName',
  )}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');
  const [goFeed] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (goFeed) {
      const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      history.push('/the-training-feed/');
    }
  }, [goFeed]);
  console.log('productos', productos);

  return (
    <>
      <br />

      <div className="marginComentLgSuccess1">
        <Starttraining
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          text="Realizar el test Coru, ayudará a saber que tipo de inversionista eres, podrás encontrarlo aquí abajo:"
        />
      </div>
      {productos.length > 0 ? (
        <Fragment className={true ? '' : 'hidden'}>
          <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0 col-md-10 offset-md-1 col-xs-12">
            <Feed_Main width="100%" widthxs="100%" background=" ">
              <Feed_Main
                width="100%"
                display=" "
                mtop=" "
                background="transparent"
              >
                <Feed_Section padding="0 0 0 0" paddingxs="0 0 0 0">
                  <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                    {productos.map((currentItem) => {
                      const wo = currentItem || null;

                      return wo !== null && wo.Show ? (
                        <ContEntrenamientos
                          id="ContEntrenamiento"
                          background="transparent"
                        >
                          <ContEntrenamiento
                            id={wo.id}
                            background={wo.TrainingColor}
                            widthM="100%"
                            widthD="100%"
                            cursor="pointer"
                          >
                            <div
                              onClick={() => {
                                handleClickTraining(wo);
                              }}
                            >
                              <ContentTimer
                                height=" "
                                maxheightMobile="130px"
                                heightMobile="140px"
                                maxheight="107.250px"
                                display="flex"
                                top="0px"
                                width="100%"
                                position=" "
                              >
                                <TitleEntrenamiento
                                  className="col-9 roboto"
                                  left="0px"
                                  margin="auto"
                                  top=" "
                                >
                                  {wo.name}
                                </TitleEntrenamiento>
                                <Image
                                  src={wo.UrlBigImage}
                                  widthmoxl="13%"
                                  width="25%"
                                  widthmob="22%"
                                  margin="auto"
                                  top="5%"
                                  bottom="1%"
                                  right="0px"
                                />
                              </ContentTimer>
                              <ContentTimer>
                                <Image
                                  src={Clock}
                                  width="20px"
                                  left="0px"
                                  position=" "
                                  top="1%"
                                />
                                <TextTime className="roboto">
                                  Tiempo de entrenamiento: {wo.tiempo} minutos
                                </TextTime>
                              </ContentTimer>
                            </div>
                          </ContEntrenamiento>
                        </ContEntrenamientos>
                      ) : (
                        <ContEntrenamientos>
                          <ContEntrenamiento
                            background={'#F3F3F3'}
                            widthM="100%"
                            widthD="95%"
                            cursor="default"
                          >
                            <div onClick={() => {}}>
                              <ContentTimer
                                height=" "
                                maxheightMobile="130px"
                                heightMobile="140px"
                                maxheight="107.250px"
                                display="flex"
                                top="0px"
                                width="100%"
                                position=" "
                              >
                                <TitleEntrenamiento
                                  className="col-9 roboto"
                                  left="0px"
                                  margin="auto"
                                  top=" "
                                >
                                  {currentItem.name}
                                </TitleEntrenamiento>
                                <Image
                                  src={candado}
                                  widthmoxl="13%"
                                  width="25%"
                                  widthmob="22%"
                                  margin="auto"
                                  top="5%"
                                  bottom="1%"
                                  right="0px"
                                />
                              </ContentTimer>
                              <ContentTimer
                                className="roboto"
                                left="0px"
                                buttom="0px"
                                top="0px"
                              >
                                <TextTime className="roboto">
                                  {' '}
                                  PRÓXIMAMENTE
                                </TextTime>
                              </ContentTimer>
                            </div>
                          </ContEntrenamiento>
                        </ContEntrenamientos>
                      );
                    })}
                  </ContentHomeSection>
                </Feed_Section>
              </Feed_Main>
            </Feed_Main>
          </div>
        </Fragment>
      ) : null}
      <ModalDiv show={show} handleClose={handleCloseModal}>
        <TrainingSubtitles
          size="20px"
          align="left"
          className="cabin"
        >{`¡Hola ${sessionStorage.getItem('FirstName')}!`}</TrainingSubtitles>
        <TrainingText
          size="14px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
          top="30px"
          bot="30px"
        >
          Indicanos por que medio te gustaría ser contactado.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalWhatsapp(e);
            }}
          >
            <Images
              src={imgWhats}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            WhatsApp
          </span>
        </TrainingText>
        <hr />
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalEmail(e);
            }}
          >
            <Images
              src={imgMail}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            E-Mail
          </span>
        </TrainingText>
        <hr />
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          <span
            onClick={(e) => {
              showModalPhone(e);
            }}
          >
            <Images
              src={imgPhone}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              width="50px"
              rigth="15px"
              position="relative"
              className="img-fluid"
            ></Images>
            Teléfono
          </span>
        </TrainingText>
        <br />
      </ModalDiv>
      <ModalDiv
        show={showWhatsapp}
        handleClose={handleCloseModalWhatsapp}
        txtModal="Enviar"
      >
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="32%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="14px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
        <TextField
          variant="outlined"
          id="telefonoW"
          name="telefono"
          label="Teléfono"
          autoComplete="off"
          value={catContactPhone}
          required
          fullWidth
          inputProps={{
            maxLength: 10,
            pattern: '[0-9]',
          }}
          className="textInput inputs-bottom input-coru"
          onBlur=""
          onChange={(e) => handleChangeContactPhone(e)}
        />
        <br />
        <br />
      </ModalDiv>
      <ModalDiv
        show={showEmail}
        handleClose={handleCloseModalEmail}
        txtModal="Enviar"
      >
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="14px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          ¡Dejános tu E-mail, nosotros te contactaremos a la brevedad!
        </TrainingText>
        <TextField
          variant="outlined"
          id="email"
          name="email"
          label="Email"
          autoComplete="off"
          value={catContactMail}
          required
          fullWidth
          inputProps=""
          className="textInput inputs-bottom input-coru"
          onBlur=""
          onChange={(e) => handleChangeEmail(e)}
        />
        <br />
        <br />
      </ModalDiv>
      <ModalDiv
        show={showPhone}
        handleClose={handleCloseModalPhone}
        txtModal="Enviar"
      >
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="14px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
        <TextField
          variant="outlined"
          id="phone"
          name="phone"
          label="Teléfono"
          autoComplete="off"
          value={catContactPhone}
          required
          fullWidth
          inputProps={{
            maxLength: 10,
            pattern: '[0-9]',
          }}
          className="textInput inputs-bottom input-coru"
          onBlur=""
          onChange={(e) => handleChangeContactPhone(e)}
        />
        <br />
        <br />
      </ModalDiv>
      <ModalDiv show={showThanks} handleClose={handleCloseModalThanks}>
        <br />
        <TrainingText
          size="14px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.3px"
        >
          ¡Tu mensaje ha sido enviado correctamente!
        </TrainingText>
        <br />
      </ModalDiv>
    </>
  );
};
export default RpThanks;
