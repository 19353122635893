import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonAlt,
  ContentButton,
  LinksFooter,
} from "../../../../components/common/common.styled";

const ButtonsInicio = ({ validUser, setispassword, error, isvalid, isModal, setTypeModal }) => {
  const [disabledbtn, setdisabledbtn] = useState(true);

  useEffect(() => {
    if (error === false && isvalid === 0) {
      setdisabledbtn(false);
    }
    else {
      setdisabledbtn(true);
    }
  }, [error, isvalid]);

  return (
    <>
      <ContentButton mt="36px" mtMobile="24px">
        <Button
          id="btnIniciar"
          mt="0px"
          type="button"
          borderRadius="16px"
          mb="5%"
          onClick={validUser}
          disabled={disabledbtn}
          className={
            disabledbtn
              ? "buttonInactive d-block  roboto"
              : "d-block  roboto"
          }
          bg={disabledbtn ? "#d1d1d1" : "#F64282"}
        >
          Solicitar enlace ✨
        </Button>
        <LinksFooter
          className="roboto"
          fontSize="12px"
          lineHeight="22px"
          color="#252525"
        >
          Enviaremos un enlace para que puedas acceder de una forma fácil a tu cuenta en Coru.com
        </LinksFooter>
        {/* <ButtonAlt
          id="btnContrasena"
          mt="36px"
          type="button"
          className="d-block roboto"
          borderRadius="16px"
          onClick={() => { setispassword(true) }}>
          O inicia sesión con tu contraseña
        </ButtonAlt> */}
      </ContentButton>
      {/* <hr />
      <LinksFooter
        className="roboto"
        fontSize="16px"
        onClick={() => {
          if (isModal) {
            setTypeModal('register')
          } else {
            window.location.href = "/personal-Information-v2"
          }
        }}
        fontWeight="bold"
        style={{
          margin: "36px auto 0% auto",
          display: "block",
          color: "#F64282",
          textDecoration: "revert",
        }}>
        ¿Aún no tienes cuenta?
      </LinksFooter> */}
    </>
  );
};

export default ButtonsInicio;
