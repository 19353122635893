import React, { Fragment, useEffect } from 'react';
import { Main, Container } from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import FooterFeed from '../../components/Feed/Footer_Feed';
import FormPesonal from '../../components/Perfiles/FormPersonalInformation';
import FormMore from '../../components/Perfiles/FormMore';
import FormPassword from '../../components/Perfiles/FormPassword';
import Header_Logros from '../../components/Perfiles/Header_Perfil';

function Personal_Information_perfile({ Novisible, paddingInicio }) {
  useEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#fafafa';
  });
  return (
    <Fragment>
      {!Novisible ? <Header_Logros /> : <div></div>}
      <Main
        background=" "
        pb={!paddingInicio ? '35%' : ' '}
        mt="0%"
        mtWeb="0%"
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
      >
        <Container
          displayweb="block"
          className="container"
          width="100%"
          widthMobile="100%"
          mtMobile="0px"
          paddingMobile="0px"
        >
          <FormPesonal></FormPesonal>
          <FormMore></FormMore>
          <FormPassword></FormPassword>
        </Container>
      </Main>
      {!Novisible ? <FooterFeed check="perfil" mb="0px" /> : <div></div>}
    </Fragment>
  );
}
export default Personal_Information_perfile;
