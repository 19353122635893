import React, { Fragment } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { ContainerPerfiles } from '../../assets/styles/pantInicio.styled';
import {
  Container, Col12, SubtitleOne, ParagrapTwo,
} from '../common/common.styled';
import '../../assets/styles/perfil/perfiles.css';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#D6D6D6',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#91D770',
  },
}))(LinearProgress);

const BorderLinearProgressTwo = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#D6D6D6',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#A6A6A6',
  },
}))(LinearProgress);

function Destacar() {
  return (
        <Fragment>
            <Container width='100%' mt='1rem' widthMobile='100%' mtMobile='2rem'float="auto!important" >
                <ContainerPerfiles padding="4%" height="auto" minheight="122px" display="flex" mb="5%">
                    <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" >
                        <Col12 pt=" " ptMobile=" " bottom="10%">
                            <SubtitleOne
                            textalingn="left"
                            textalingnMobile="left"
                            sizeMobile="20px"
                            size="25px" mb="1%"
                            className="font-weight-bold roboto"
                            transform=" "
                            mt="0%">
                            Destacar Rachas
                            </SubtitleOne>
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                Has completado 20 rachas de forma consistente esta semana en comparación con la semana pasada.
                            </ParagrapTwo>
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                Esta semana
                            </ParagrapTwo>
                            <BorderLinearProgress variant="determinate" value={80} />
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                La semana pasada
                            </ParagrapTwo>
                            <BorderLinearProgressTwo variant="determinate" value={50} />
                        </Col12>
                    </Container>
                </ContainerPerfiles>
                <ContainerPerfiles padding="4%" height="auto" minheight="122px" display="flex" mb="5%">
                    <Container width='100%' widthMobile='100%' mtMobile="0px" float="auto" mMobile="auto" >
                        <Col12 pt=" " ptMobile=" " bottom="10%">
                            <SubtitleOne
                            textalingn="left"
                            textalingnMobile="left"
                            sizeMobile="20px"
                            size="25px" mb="1%"
                            className="font-weight-bold roboto"
                            transform=" "
                            mt="0%">
                            Destacar Trofeos
                            </SubtitleOne>
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                Has ganado 6 trofeos esta semana en comparación con la semana pasada.</ParagrapTwo>
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                Esta semana
                            </ParagrapTwo>
                            <BorderLinearProgress variant="determinate" value={80} />
                            <ParagrapTwo
                            mb="5%"
                            mt="3%"
                            fontSize="16px"
                            fontSizeWeb="16px"
                            textalignMobile="left"
                            texttalign="left"
                            className="mx-auto roboto" mtWeb="3%">
                                La semana pasada
                            </ParagrapTwo>
                            <BorderLinearProgressTwo variant="determinate" value={50} />
                        </Col12>
                    </Container>
                </ContainerPerfiles>

            </Container>
        </Fragment>
  );
}
export default Destacar;
