import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CardsBtn,
  TrainingMain,
  TrainingSubtitles,
  TrainingDivSection,
  TrainingText,
} from '../../assets/styles/Mvp.styled';

const Description = () => {
  const history = useHistory();
  const contact = () => history.push('/c');
  const answer = () => {
    sessionStorage.setItem('returnAnswers', 1);
    history.push('/answer');
  };
  // detail es un arreglo de párrafos, para ser lo más flexible posible.
  const options = {
    P1: {
      title: 'Ruta competitiva',
      emphasis:
        '¡Mide tu avance financiero y supera a los demás rompiendo tus propios retos!',
      detail: [
        '¿Cuánto dinero deberías ahorrar según tu edad y estilo de vida?',
        '¿Cómo están tus finanzas en lo que se refiere a tu generación y tu ciudad?',
        'Este entrenamiento te da:',
        '• Análisis de tu perfil financiero comparado con gente similar a ti',
        '• Medición de tus metas de dinero',
        '• Recomendaciones financieras personalizadas',
      ],
    },
    P2: {
      title: 'Ruta saludable',
      emphasis:
        'Cuida al mismo tiempo la salud de tu mente, cuerpo, ¡y billetera!',
      detail: [
        'Relacionamos tus objetivos financieros con metas de salud física para motivarte a cumplir.',
        'Este entrenamiento te ayuda a:',
        '• Tener un perfil de bienestar físico y financiero',
        '• Aprender sobre aspectos integrales de tu vida que impactan en tu dinero',
      ],
    },
    P3: {
      title: 'Ruta ganar - ganar',
      emphasis: 'Gana premios por avanzar en tus metas financieras',
      detail: [
        'Acumula ganancias y premios por aprender a cuidar tus finanzas y llegar a las metas que definas.',
        'Con este entrenamiento:',
        '• Ganas por lograr tus objetivos',
        '• Generas más ingresos a partir de tu salud financiera',
      ],
    },
    P4: {
      title: 'Ruta paso a paso',
      emphasis: '¡Ten tu propio entrenador financiero personal!',
      detail: [
        'Cumple tus objetivos financieros de la mano de un asesor experto',
        'Con esta modalidad obtienes:',
        '• Asesoría personal vía chat o telefónica que esté monitoreando todo tu avance',
      ],
    },
    P5: {
      title: 'Desafío semanal',
      emphasis:
        'Rápidamente notarás tus avances ¡Invita a tus amigos al reto semanal!',
      detail: [
        'Llega a tus metas financieras y de bienestar en modo exprés. En esta modalidad obtienes:',
        '• Monitoreo semanal de retos',
        '• Asesoría personal exprés para acelerar tu progreso',
        '• Retos colectivos para compartir beneficios con tus amigos',
      ],
    },
  };

  // Destructure to get content

  const option = window.location.pathname.replace(/\/f/g, 'P');
  console.log(option);
  const { title, emphasis, detail } = options[option];
  return (
    <Fragment>
      <TrainingMain>
        <TrainingDivSection padding="10% 10% 0 10%">
          <TrainingSubtitles fw="600" alignweb="center">
            {title}
          </TrainingSubtitles>
          <TrainingSubtitles
            className="sans-pro"
            color="rgba(0,0,0,0.5)"
            align="center"
            alignweb="center !important"
          >
            {emphasis}
          </TrainingSubtitles>
          <TrainingDivSection styleWidth="90%">
            {detail.map((el, i) => (
              <TrainingText className="dm-sans" key={`detail-p-${i}`}>
                {el}
              </TrainingText>
            ))}
          </TrainingDivSection>
        </TrainingDivSection>
        <TrainingDivSection
          padding="0 10% 10% 10%"
          styleWidth="100%"
          className="div-center flex-web"
        >
          <CardsBtn min_w="100%" type="button" onClick={(e) => contact()}>
            Seleccionar esta ruta
          </CardsBtn>
          <br />
          <br />
          <CardsBtn
            min_w="100%"
            color="#EC407A !important"
            back="#fff !important"
            border="solid 1px !important"
            type="button"
            onClick={() => answer()}
          >
            Ver otras rutas
          </CardsBtn>
        </TrainingDivSection>
      </TrainingMain>
      <div id="irTimeDom"></div>
    </Fragment>
  );
};

export default Description;
