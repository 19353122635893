import React, { useEffect, useState } from 'react';
import CustomStepLayout from '../../layouts/CustomStepLayout';
import './styles/personalData.css';

const PersonalData = ({
  customColor,
  coachImg,
  stepData,
  type,
  nextHandler,
  handleNextParam,
  appearAnimation,
  disappearAnimation,
  isActive,
  canGoBack,
  backText,
  backHandler,
  backHandlerOption,
}) => {
  const stepLayoutProps = {
    customColor,
    coachImg,
    type,
    isActive,
    appearAnimation,
    disappearAnimation,
    backText,
    backHandlerOption,
    backHandler,
    canGoBack,
  };

  const [data, setData] = useState({
    FirstName:
      sessionStorage.getItem('temp-FirstName')
      || sessionStorage.getItem('FirstName')
      || '',
    MiddleName:
      sessionStorage.getItem('temp-MiddleName')
      || sessionStorage.getItem('MiddleName')
      || '',
    FathersLastName:
      sessionStorage.getItem('temp-FathersLastName')
      || sessionStorage.getItem('FathersLastName')
      || '',
    MothersLastName:
      sessionStorage.getItem('temp-MothersLastName')
      || sessionStorage.getItem('MothersLastName')
      || '',
  });

  const [buttonActive, setButtonActive] = useState(false);

  /* Default info data to show. This data can be replaced with prop 'stepData' */
  const defaultContent = {
    title: '¡Excelente! Ahora cuéntame sobre ti.',
    description: 'Ingresa tus datos para encontrar las mejores opciones.',
    instructions: 'Ingresa tu nombre completo',
  };

  /* Data state handler on input change */
  const setDataChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  /* Data listener to activate or deactivate button */
  useEffect(() => {
    setButtonActive(validateFields());
  }, [data]);

  /* Function to validate required fields */
  const validateFields = () => {
    if (
      data.FirstName.trim().length > 0
      && data.FathersLastName.trim().length > 0
      && data.MothersLastName.trim().length > 0
    ) return true;

    return false;
  };

  /* Function to get text */
  const getText = (name) => (
    /*
            If text parameter is set in props, use it
            If text parameter is not set in props, then use default text parameter
            If text parameter is set in props but it is an empty string, then remove margin from div so as not to ocuppy space
        */
    <div
      className={`${name} ${
        stepData && stepData[name] && stepData[name].trim() === ''
          ? 'no-margin'
          : ''
      }`}
    >
      {stepData ? stepData[name] || defaultContent[name] : defaultContent[name]}
    </div>
  );

  const submitHanlder = (e) => {
    console.log('paso1 >>');

    e.preventDefault();

    if (!validateFields()) return;

    console.log('paso2 >>');

    /*
            Save temporal data on sessionStorage
        */

    sessionStorage.setItem('temp-FirstName', data.FirstName);
    sessionStorage.setItem('temp-MiddleName', data.MiddleName);
    sessionStorage.setItem('temp-FathersLastName', data.FathersLastName);
    sessionStorage.setItem('temp-MothersLastName', data.MothersLastName);

    /* Save data here

         - - - - - - - - - -

         - - - - - - - - - -

         - - - - - - - - - -

         - - - - - - - - - -

        */

    nextHandler(handleNextParam);
  };

  return (
    <CustomStepLayout
      {...stepLayoutProps}
      stepClass="step-static-personal-data"
    >
      <div className="text-content">
        {getText('title')}

        {getText('description')}
      </div>

      <form onSubmit={submitHanlder}>
        {getText('instructions')}

        <div className="input-row">
          <input
            type="text"
            name="FirstName"
            placeholder="Primer nombre"
            onChange={setDataChange}
            value={data.FirstName}
            required
          />
        </div>

        <div className="input-row">
          <input
            type="text"
            name="MiddleName"
            placeholder="Segundo nombre"
            onChange={setDataChange}
            value={data.MiddleName}
          />
        </div>

        <div className="input-row">
          <input
            type="text"
            name="FathersLastName"
            placeholder="Apellido paterno"
            onChange={setDataChange}
            value={data.FathersLastName}
            required
          />
        </div>

        <div className="input-row">
          <input
            type="text"
            name="MothersLastName"
            placeholder="Apellido materno"
            onChange={setDataChange}
            value={data.MothersLastName}
            required
          />
        </div>

        <div className="button-container">
          <button
            className={`custom-text-button ${buttonActive ? 'active' : ''}`}
            onClick={submitHanlder}
          >
            Siguiente {'>'}{' '}
          </button>
        </div>
      </form>
    </CustomStepLayout>
  );
};

export default PersonalData;
