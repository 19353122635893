import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { useLazyQuery } from '@apollo/client';
import {
  ContainerRequest, Image, ContainerFlexSolicitud, SolicitudText,
  Main, Container,
} from '../../components/common/common.styled';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import { CardsBtn } from '../../assets/styles/Training.styled';
import { Subtitle, Title2 } from '../../components/common/Home.styled';

import vexi1 from '../../assets/img/lpVexi/cartnetVexi.svg';
import vexi2 from '../../assets/img/lpVexi/AEvexi.svg';
import activePagee from '../../assets/img/lpVexi/activePage.svg';
import inactivePage from '../../assets/img/lpVexi/inactivePage.svg';

import { Principal_lista } from '../../assets/styles/Home.styled';
import { GET_USER_CONTACT, GET_USER_DETAILS } from '../../queries';
import { setSessionVariablesUserContact, setSessionVariablesUserDetails } from '../../Helpers/setSessionVariables';

const VexiCard = (props) => {
  const history = useHistory();
  const url = new URL(window.location.href);
  const idUser = url.searchParams.get('id') || '';
  const idCont = url.searchParams.get('idCont') || '';
  const [getUserDetails, { data: userDetailsData }] = useLazyQuery(GET_USER_DETAILS);
  const [getUserContact, { data: userContact }] = useLazyQuery(GET_USER_CONTACT);
  const handleSelectCard = () => {
    history.push('/verification-code-vexi');
  };

  useEffect(() => {
    console.log({ idUser, idCont });
    if (idUser) getUserDetails({ variables: { idUser } });
    if (idCont) getUserContact({ variables: { idCont } });
  }, []);

  useEffect(() => {
    if (userContact) {
      const { response } = userContact.getUserContact;
      if (response) setSessionVariablesUserContact(response);
    }
  }, [userContact]);

  useEffect(() => {
    if (userDetailsData) {
      const { response } = userDetailsData.getUserDetails;
      if (response) setSessionVariablesUserDetails(response);
    }
  }, [userDetailsData]);

  return <ContainerRequest mwM="85%" mw="335px" margin="0 10px" >
      <ContainerFlexSolicitud pd = "13px 21px">
         <Image src={props.image} width="100px" />
      </ContainerFlexSolicitud>
      <div style={{ padding: '0px 22px', color: '#07210A' }}>
         <Subtitle mbottomM="0" bottomMm640="0" sizeM="19px" marginTop = "0px"
            sizeM640="19px" id="Subtitle" className="roboto" sizeMm320 = "19px"
            aling="left" weight="bold" size="20px" width="100%" sizeMm360 = "19px"
            weightM=" " mleftM = "0px" mleftMm320="0px" widthM = "100%"
            mleftMm640 = "0px" mleftMm360="0px" widthMm320="300px" >
            {props.name}
         </Subtitle>
         <SolicitudText fs='16px' fc="#07210A" fw='500'>
            <Subtitle mbottomM="0" bottomMm640="0" sizeM="20px"
               sizeM640="20px" id="Subtitle" className="roboto"
               aling="left" weight="bold" size="17px" width="100%"
               weightM=" " mleftM = "0px" mleftMm320="0px"
               mleftMm640 = "0px" mleftMm360="0px" widthMm320="300px" >
               Es para ti que:
            </Subtitle>
         </SolicitudText>
         {props.children}
         <CardsBtn
            back = {props.color}
            type="button"
            min_w="100%"
            min_web="100%"
            mtopw="10px"
            mtop="0px"
            mtopmd="0px"
            mbottom = "5%"
            mbottomM = "26px"
            onClick={handleSelectCard}
         >
            Seleccionar
         </CardsBtn>
      </div>
   </ContainerRequest>;
};

const ContactVexi = () => {
  const reasonsToRequest1 = ['Quieres construir o reconstruir tu historial crediticio.',
    'Tienes ingresos desde $2,000.', 'Comisión por apertura $450'];
  const reasonsToRequest2 = ['Quieres construir tu historial crediticio.',
    'No tienes marcas negativas en buró.', 'Tienes ingresos desde $5,000'];
  return (
    <Fragment>
      <Header pathname="/user-validation" />
      <Main
        className="container"
        mbWeb="18%"
        margintop="1%"
        background=""
        pb="0%"
        overflow=""
        padding="15px 15px 0px 15px"
        mb="134px"
      >
        <Container width="90%" mt="1rem" widthMobile="100%" mtMobile="2rem">
            <Title2 sizeD="30px" topD="0%" className="roboto" fSizeMd = "20px" sizem = "19px"
               top = "0%" mleftm = "0px" widthM = "100%" aling = "center" alignM = "left">
               <div style = {{ margin: '1% 10px' }}>
                  Estamos listos para iniciar tu solicitud, indícanos cual tarjeta de crédito es
                  de tu interés*
               </div>
            </Title2>
            <div className = "w-100 mt-3 mt-md-2 d-md-block d-none">
               <div className = "row w-100">
                  <div className = "col-md-6 col-12 d-flex justify-content-center">
                     <VexiCard name = "Tarjeta Vexi Carnet" image = {vexi1} color = "#C518DD">
                        {reasonsToRequest1.map((reason, idx) => <Principal_lista fsM = "14.5px"
                           lhM = "24px" lh = "24px" fw ="bold" tiM = "-1em" fs = "15px"
                           fwM = "bold" key = {idx}>
                          {reason}
                        </Principal_lista>)}
                     </VexiCard>
                  </div>
                  <div className = "col-md-6 col-12 d-flex justify-content-center">
                     <VexiCard name = "Tarjeta Vexi American Express" image = {vexi2}
                        color = "#C518DD">
                        {reasonsToRequest2.map((reason, idx) => <Principal_lista fsM = "14.5px"
                           lhM = "24px" lh = "24px" fw ="bold" tiM = "-1em" fs = "15px"
                           fwM = "bold" key = {idx}>
                           {reason}
                        </Principal_lista>)}
                     </VexiCard>
                  </div>
               </div>
            </div>
            <Carousel pagination
               id="CarruselMetasLogros"
               styled={{ background: '#FAFAFA' }}
               className = "d-md-none d-block"
               renderPagination={({ pages, activePage, onClick }) => (
                  <div className = "row d-flex justify-content-center">
                     {pages.map((page) => {
                       const isActivePage = activePage === page;
                       return <span key={page} className = "m-1 mt-2" onClick={() => onClick(page)}>
                           <Image src={isActivePage ? activePagee : inactivePage} width ="15px" />
                        </span>;
                     })}
                  </div>
               )}
               transitionMs={1000}>
               <VexiCard name = "Tarjeta Vexi Carnet" image = {vexi1} color = "#C518DD">
                  {reasonsToRequest1.map((reason, idx) => <Principal_lista fsM = "14.5px"
                     lhM = "24px" lh = "24px" fw ="bold" tiM = "-1em" fs = "15px"
                     fwM = "bold" key = {idx}>
                     {reason}
                  </Principal_lista>)}
               </VexiCard>
               <VexiCard name = "Tarjeta Vexi American Express" image = {vexi2}
                  color = "#C518DD">
                  {reasonsToRequest2.map((reason, idx) => <Principal_lista fsM = "14.5px"
                     lhM = "24px" lh = "24px" fw ="bold" tiM = "-1em" fs = "15px"
                     fwM = "bold" key = {idx}>
                     {reason}
                  </Principal_lista>)}
               </VexiCard>
            </Carousel>
            <div className = "row w-100 m-0">
               <Subtitle mbottomM="0" bottomMm640="0" sizeM="14px" marginTop = "0px"
                  sizeM640="14px" id="Subtitle" className="roboto" sizeMm320 = "14px"
                  aling="left" weight="normal" size="14px" width="100%" sizeMm360 = "14px"
                  weightM="normal" mleftM = "0px" mleftMm320="0px" widthM = "100%"
                  mleftMm640 = "0px" mleftMm360="0px" widthMm320="300px" sizeMm992 = "14.5px" >
                  <div style = {{ margin: '1% 10px' }}>
                     *Sujeto a análisis y aprobación de crédito. <br/>
                     *Vexi se reserva el derecho a recomendarte una tarjeta acorde a tu perfil.
                  </div>
               </Subtitle>
            </div>
        </Container>
      </Main>
      <Footer pathname="/" />
    </Fragment>
  );
};

export default ContactVexi;
