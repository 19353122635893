import React, { useState, useEffect, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { useTrail, animated } from 'react-spring';
import '../../assets/styles/Home.css';
import { useMutation, useLazyQuery } from '@apollo/client';
import clock from '../../assets/img/anacoach/clockTraining.png';
import logoTest from '../../assets/img/anacoach/viralityTest.svg';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';

import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
} from '../../mutations';
import { GETPERCENTAGETRAINING, GET_TRAINING_STATUS } from '../../queries';

import {
  TrainingMain,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainigTam,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import RoutinesOrderViralityTest from './RoutinesOrderViralityTest';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import Header from '../../components/common/Header';
import WaitList from '../../components/Training/WaitList';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import {
  Container,
  Col7,
} from '../../components/common/common.styled';
import WOlayout from '../../components/layouts/WOlayout';
import '../../assets/styles/kanda/KandaDesktop.css';

const items2 = ['¡Responde y gana con Coru!', ''];
const config = { mass: 5, tension: 1000, friction: 200 };
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function ViralityTest() {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 30);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  console.log(id);
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);

  // End ValidWaitList
  const [arrayOrderRoutines] = useState([]);
  const [percentage, setPercentage] = useState('');
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(30);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);
  //----------------------------------------------------------------
  const [toggle2] = useState(true);
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const trail2 = useTrail(items2.length, {
    config,
    opacity: toggle2 ? 1 : 0,
    a: toggle2 ? 0 : 20,
    height: toggle2 ? 80 : 0,
    from: { opacity: 0, a: 20, height: 0 },
  });

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      arrayOrderRoutines.push(orderRoutine);
    }
  };

  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    pushArrayOrderRoutine(orderRutine);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1') || '';
        inputRutine.cellphone = sessionStorage.getItem('CellPhone') || '';
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);

        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }

        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
  });
  const [newSession, {}] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });
  useEffect(() => {
    const {
      idUser, idCont, hostName, pageName, fromXCoach, idAdviser,
    } = getQueryVariableHashtagDeeplink('virality-test');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
    }

    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    pushArrayOrderRoutine(orderRutine);
    saveAdvance(orderRutine);
  }, [orderRutine]);

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    console.log('input progress');
    console.log(JSON.stringify(inputProgress));

    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };
  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 30 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  const hora = `${date.getHours()}:${minutos}`;
  const [active0, setactive0] = useState(false);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  return (
    <WOlayout
      backgroundColor={'#F074DC'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <TrainingMain>
        {WOStatus ? (
          <Fragment>
            <TrainingAnimateFunnel
              bcolor="#F074DC"
              pmob="0 0 0 0"
              margin="0 0 24px 0"
              minh="309px"
              hweb="410px"
            >
              <TrainigTam>
                <Header
                  background={'#F074DC'}
                  pathname={window.location.pathname}
                />
              </TrainigTam>
              <TrainigTam plw="0" hm="209px" mt="49px" ml="20px">
                <div className="d-flex flex-row ">
                  <div className="col-8 col-sm-9 col-xl-8 ">
                    <TrainigTam
                      hm="auto"
                      ptmd="100px"
                      pt="140px"
                      pl="20px"
                      plw="0"
                      ptwxl="120px"
                    >
                      {trail2.map(({ a, height, ...rest }, index) => (
                        <animated.div
                          key={items2[index]}
                          className="trails-text text-funnelcc cabin"
                          style={{
                            ...rest,
                            transform: a.interpolate(
                              (a) => `translate3d(0,${a}px,0)`,
                            ),
                          }}
                        >
                          <animated.div style={{ height }}>
                            {items2[index]}
                          </animated.div>
                        </animated.div>
                      ))}
                    </TrainigTam>

                    <TrainigTam
                      hm="auto"
                      pb="55px"
                      ptmd="330px"
                      pl="45px"
                      pt="236px"
                      ptsm="250px"
                      plemd="20px"
                      plesm="20px"
                      plw="6.5%"
                      ptw="310px"
                    >
                      <div className="d-flex flex-row">
                        <Images
                          src={clock}
                          top="0"
                          topweb=""
                          left="0px"
                          leftw="0px"
                          width="18px"
                          widthw="1.6vw"
                          height="1.6vw"
                          position="relative"
                          rigthw="12px"
                          className="img-fluid"
                          heightsm="18px"
                          widthsm="18px"
                          width768="13px"
                          height768="13px"
                        ></Images>
                        <TrainingText
                          className="roboto marginTime container-bar"
                          color="#07210A"
                          align="left"
                          alignweb="left !important"
                          size="16px"
                          sizew="1.6vw"
                          lh="120%"
                          weight="normal"
                          bot="0px"
                        >
                          Tiempo de entrenamiento: 5 minutos.
                        </TrainingText>
                      </div>
                    </TrainigTam>
                  </div>
                  <div className="col-3 col-md-4 col-xl-4 marginImgHeader">
                    <img
                      alt=""
                      src={logoTest}
                      className="col-12 col-xl-4 col-lg-7 col-md-8 col-sm-10 col-xs-10 img-relative imgTitle2200"
                      left="30%"
                      position="absolute"
                    ></img>
                  </div>
                </div>
              </TrainigTam>
              <TrainingLine
                id="line-percent-gray"
                mt="40px"
                mtw="0"
              ></TrainingLine>
              {percentage !== '' ? (
                <TrainingLine
                  id="line-percent-pink"
                  bcolor="#F64282"
                  mt=""
                  mtw="404px"
                  wweb={percentage}
                  w={percentage}
                  mr="auto"
                  op="1"
                  br="100px"
                ></TrainingLine>
              ) : null}
            </TrainingAnimateFunnel>
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Fragment>
                  <div className="shapes">
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        {active0 ? (
                          <RoutinesOrderViralityTest
                            Date={stateDate}
                            hora={hora}
                            orderRutine={orderRutine}
                            setOrderRutine={setOrderRutine}
                            currentTraining={currentTraining}
                            createJsonInput={createJsonInput}
                            questions={questions}
                            setQuestions={setQuestions}
                          />
                        ) : null}
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                  </div>
                  {!active0 ? (
                    <Fragment>
                      <br></br>
                      <TrainingSectionGray
                        wiw="670px"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <Container
                          width="80%"
                          mt="0rem"
                          widthMobile="90%"
                          mtMobile="0rem"
                        >
                          <ImageCoachInicio topMobile="35px" />
                          <Col7 padding=" " className="cardPrincipal1">
                            <LoadingWo />
                          </Col7>
                        </Container>
                      </TrainingSectionGray>
                    </Fragment>
                  ) : null}
                </Fragment>
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
            <br />
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            {executeQuery ? <WaitList /> : null}
          </Fragment>
        )}
      </TrainingMain>
    </WOlayout>
  );
}

export default ViralityTest;
