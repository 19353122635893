import React, { useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { useMutation } from '@apollo/client';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import WOlayout from '../../components/layouts/WOlayout';
import { LOG_SAVIG_DOLLAR } from '../../mutations';
import {
  Container1,
  ContentHomeSection,
  Shapes,
  Title2,
  Subtitle,
  Rectangle24,
  Vector1,
  ResponsiveDiv,
  ContainerContentStep,
  ContainerContentLabel,
  ContentStepContainerOption,
} from '../../components/common/Home.styled';
import { Container, Button } from '../../components/common/common.styled';
import { Image } from '../../assets/styles/Home.styled';
import Money from '../../assets/img/anacoach/USAMoney.svg';
import { CardsBtn } from '../../assets/styles/Training.styled';
import { Helmet } from 'react-helmet';

const LpAhorroEnDolares = () => {
  const [logSavigDollar] = useMutation(LOG_SAVIG_DOLLAR, {
    onCompleted({ logSavigDollar }) {
      window.location.href = '/lp/saving-dollars/Success';
    },
    onError(err) {
      console.log(err);
    },
  });
  const [invertionValue, setInvertionValue] = useState(undefined);
  const [step, setStep] = useState(0);
  const [wasInverted, setWasInverted] = useState(undefined);
  const [whyValue, setWhyValue] = useState(undefined);
  const [experienceValue, setExperienceValue] = useState(undefined);
  const [flag, setFlag] = useState(true);

  // Nuevas preguntas:
  const [whereMoney, setWhereMoney] = useState(undefined);
  const [easyAhorro, setEasyAhorro] = useState(0);

  const [InstruAhorro, setInstruAhorro] = useState({
    dolar: false,
    euro: false,
    crypto: false,
    otro: false,
  });

  const [InstruMiedos, setInstruMiedos] = useState({
    perder: false,
    divisa: false,
    momento_ideal: false,
    no_saber: false,
  });

  const [otroValue, setOtroValue] = useState(undefined);
  const [couldInvert, setCouldInvert] = useState(0);
  const [haveTool, setHaveTool] = useState(0);
  const nextStep = (_) => {
    if (wasInverted === 2 && flag) {
      debugger;
      setStep(step + 3);
      setFlag(false);
    } else {
      setStep(step + 1);
    }
  };

  const handleInvertion = (e) => setInvertionValue(e.target.value);
  const handleWhereMoney = (e) => setWhereMoney(e.target.value);
  const handleNoSe = (e) => setWhyValue(e.target.value);

  const baseAhorro = {
    dolar: false,
    euro: false,
    crypto: false,
    otro: false,
  };

  const [cuenta] = useState([]);
  const [cuenta2, setCuenta2] = useState(' ');
  const handleInstruAhorro = (e) => {
    setInstruAhorro({ ...InstruAhorro, [e.target.name]: e.target.checked });
    if (e.target.checked === true || e.target.checked === 'true') {
      setCuenta2(true);
    } else {
      setCuenta2(false);
    }
  };

  const [btnC, setBtnC] = useState(false);

  useEffect(() => {
    if (cuenta2 === ' ') {
      console.log('Inicio');
    } else if (cuenta2 === true) {
      cuenta.push(true);
    } else {
      cuenta.push(false);
    }
    const counts = {};
    for (let i = 0; i < cuenta.length; i++) {
      const num = cuenta[i];
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    if (counts.true === counts.false) {
      setBtnC(false);
    } else {
      setBtnC(true);
    }
  }, [InstruAhorro]);

  const [cuentaMiedo] = useState([]);
  const [cuenta2Miedo, setCuenta2Miedo] = useState(' ');
  const handleInstruMiedo = (e) => {
    setInstruMiedos({ ...InstruMiedos, [e.target.name]: e.target.checked });
    if (e.target.checked === true || e.target.checked === 'true') {
      setCuenta2Miedo(true);
    } else {
      setCuenta2Miedo(false);
    }
  };
  const [btnMi, setBtnCMi] = useState(false);
  useEffect(() => {
    if (cuenta2Miedo === ' ') {
      console.log('Inicio');
    } else if (cuenta2Miedo === true) {
      cuentaMiedo.push(true);
    } else {
      cuentaMiedo.push(false);
    }
    const countsmi = {};
    for (let i = 0; i < cuentaMiedo.length; i++) {
      const nummi = cuentaMiedo[i];
      countsmi[nummi] = countsmi[nummi] ? countsmi[nummi] + 1 : 1;
    }

    if (countsmi.true === countsmi.false) {
      setBtnCMi(false);
    } else {
      setBtnCMi(true);
    }
  }, [InstruMiedos]);

  const handleCould = (n) => {
    const bandera = step;
    if (bandera === 6 || bandera === 5) nextStep();
    setCouldInvert(n);
  };

  const handleEasyAhorro = (n) => {
    nextStep();
    setEasyAhorro(n);
  };

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);

  // Slider components
  const marks = [
    {
      value: 0,
      label: '1',
    },
    {
      value: 25,
      label: '2',
    },
    {
      value: 50,
      label: '3',
    },
    {
      value: 75,
      label: '4',
    },
    {
      value: 100,
      label: '5',
    },
  ];

  const valuetext = (value) => `${value}`;

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  const [ValueSlider, setValueSlider] = useState('');
  const [ValueSliderc, setValueSliderc] = useState(0);
  const [BotonSlider, setBotonSlider] = useState(0);
  const handleSlider = (e, valor) => {
    setValueSliderc(valor);
    let textvalor = '';
    switch (valor) {
      case 0:
        textvalor = '1, Nada seguro';
        break;
      case 25:
        textvalor = '2, Poco seguro';
        break;
      case 50:
        textvalor = '3, Indiferente';
        break;
      case 75:
        textvalor = '4, Seguro';
        break;
      case 100:
        textvalor = '5, Muy seguro';
        break;
      default:
        break;
    }
    setValueSlider(textvalor);
  };
  const handleBtnSlider = () => {
    setBotonSlider(1);
    if (ValueSliderc < 51) {
      nextStep();
    } else {
      setStep(step + 2);
    }
  };

  const finish = () => {
    console.log('entraste a finish');
    haveTool === 1
      ? sessionStorage.setItem('Q7', 'Si')
      : sessionStorage.setItem('Q7', 'No');
    wasInverted === 1
      ? sessionStorage.setItem('Q2', 'Si')
      : sessionStorage.setItem('Q2', 'No');
    if (couldInvert === 1) {
      sessionStorage.setItem('Q6', 'CETES');
    } else if (couldInvert === 2) {
      sessionStorage.setItem('Q6', 'ETFs');
    } else if (couldInvert === 3) {
      sessionStorage.setItem('Q6', 'Dolares');
    } else if (couldInvert === 4) {
      sessionStorage.setItem('Q6', 'Criptomonedas');
    } else {
      sessionStorage.setItem('Q6', 'Pesos mexicanos');
    }
    sessionStorage.setItem('Q1', invertionValue);
    sessionStorage.setItem('Q3', whyValue);
    sessionStorage.setItem('Q4', experienceValue);
    sessionStorage.setItem('Q5', JSON.stringify(InstruAhorro));
    // Nuevas
    sessionStorage.setItem('Q11', ValueSlider); // steper
    sessionStorage.setItem('Q8', JSON.stringify(InstruMiedos)); // steper miedos
    sessionStorage.setItem('Q9', whereMoney); // numero 1
    if (easyAhorro === 1) {
      // penultima
      sessionStorage.setItem('Q10', 'Facil');
    } else {
      sessionStorage.setItem('Q10', 'Dificil');
    }
    const idUser = sessionStorage.getItem('idUser');
    if (idUser && idUser !== null && idUser !== undefined && idUser !== '') {
      const inputRegister = {
        input: {
          Q1: sessionStorage.getItem('Q1'),
          Q2: sessionStorage.getItem('Q2'),
          Q3: sessionStorage.getItem('Q3'),
          Q4: sessionStorage.getItem('Q4'),
          Q5: sessionStorage.getItem('Q5'),
          Q6: sessionStorage.getItem('Q6'),
          Q7: sessionStorage.getItem('Q7'),
          Q8: sessionStorage.getItem('Q8'),
          Q9: sessionStorage.getItem('Q9'),
          Q10: sessionStorage.getItem('Q10'),
          Q11: sessionStorage.getItem('Q11'),
        },
      };
      logSavigDollar({ variables: inputRegister });
    } else {
      console.log('Ingrese a window');
      window.location.href = '/lp/saving-dollars/Success';
    }
    // console.log("parse: " + JSON.parse(InstruAhorro));
    console.log(`string: ${JSON.stringify(InstruAhorro)}`);
    console.log(`q1${invertionValue}`);
    console.log(`q2${wasInverted}`);
    console.log(`q3${whyValue}`);
    console.log(`q4${experienceValue}`);
    console.log(`q5${InstruAhorro}`);
    console.log(`q6${couldInvert}`);
    console.log(`q7${haveTool}`);
    console.log(`q8${InstruMiedos}`);
    console.log(`q9${whereMoney}`);
    console.log(`q10${easyAhorro}`);
    console.log(`q11${ValueSlider}`);
  };
  const handleInverted = (n) => {
    setWasInverted(n);
    if (step < 3) {
      if (n === 1) {
        setStep(step + 2);
      } else {
        nextStep();
      }
    }
  };

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Ahorra en dólares | CORU</title>
      </Helmet>
      <Container1 ov="" ovX="" style={{ height: 'auto' }}>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="auto"
          height="auto"
          width="100%"
          position=" "
          mb="100px"
        >
          <Container
            width="80%"
            mt="4rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Vector1
                positionD="absolute"
                Pleft="150px"
                Ptop="-20px"
                displayM="none"
                displayD=" "
                background="#6BD4AE"
                transform=" "
                width="70px"
                height="70px"
              />
              <Vector1
                positionD="absolute"
                Pleft="75%"
                Ptop="2.9%"
                displayM="none"
                displayD=" "
                background="#91D770"
                transform="rotate(75deg)"
                width="65px"
                height="65px"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="210px"
                Pleft="97%"
                displayD=" "
                left=" "
                top=" "
                width="65.26px"
                height="85.26px"
                background="#E2E560"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="310px"
                Pleft="60%"
                displayD=" "
                left=" "
                top=" "
                width="45.26px"
                height="45.26px"
                background="#6BD5AF"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="335px"
                Pleft="87%"
                displayD=" "
                left=" "
                top=" "
                width="65.26px"
                height="65.26px"
                background="#91D770"
                transform="rotate(-35.58deg)"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="380px"
                Pleft="95%"
                displayD=" "
                left=" "
                top=" "
                width="145.26px"
                height="135.26px"
                background="#BFDD6A"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="435px"
                Pleft="-3%"
                displayD=" "
                left=" "
                top=" "
                width="105.26px"
                height="105.26px"
                background="#91D770"
                transform="rotate(-35.58deg)"
              />
              <Title2
                sizeD="50px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="12%"
                className="roboto"
              >
                ¿Te interesa {window.innerWidth <= 550 ? '' : <br />} ahorrar en
                dólares?
              </Title2>
              <Subtitle
                id="Subtitle"
                className="roboto"
                mtopM="10%"
                mbottomM="5%"
                width="650px"
                widthM="325px"
                lh="30px"
                heightM="auto"
              >
                En Coru estamos creando contenido para mejorar tu salud
                financiera, ayudanos a responder la siguientes preguntas.
              </Subtitle>
              <Image
                src={Money}
                maxwidth="430px"
                width="30%"
                position="absolute"
                left="50%"
                displayMobile="none"
              />
              <div className="d-flex flex-column">
                <ResponsiveDiv width="50%" widthM="90%">
                  <ContainerContentStep ml="auto" mr="auto" padding="22px 20px">
                    <Subtitle
                      id="Subtitle"
                      className="roboto"
                      mtopM="0"
                      mbottomM="0"
                      width="auto"
                      widthM="auto"
                      lh="30px"
                      marginTop="0"
                      mbW="13px"
                      mleftM="0"
                      heightM="auto"
                    >
                      ¿Dónde guardas tu dinero?
                    </Subtitle>
                    {step === 0 ? (
                      <>
                        <ContentStepContainerOption>
                          <ContainerContentLabel htmlFor="Cuenta bancaria">
                            Cuenta bancaria
                          </ContainerContentLabel>
                          <Radio
                            onChange={(e) => handleWhereMoney(e)}
                            value="Cuenta bancaria"
                            checked={whereMoney === 'Cuenta bancaria'}
                            id="Cuenta_bancaria"
                          />
                        </ContentStepContainerOption>
                        <ContentStepContainerOption>
                          <ContainerContentLabel htmlFor="Cuenta nomina">
                            Cuenta nómina
                          </ContainerContentLabel>
                          <Radio
                            onChange={(e) => handleWhereMoney(e)}
                            value="Cuenta nomina"
                            checked={whereMoney === 'Cuenta nomina'}
                            id="Cuenta_nomina"
                          />
                        </ContentStepContainerOption>
                        <ContentStepContainerOption>
                          <ContainerContentLabel htmlFor="Fondo de ahorro">
                            Fondo de ahorro
                          </ContainerContentLabel>
                          <Radio
                            onChange={(e) => handleWhereMoney(e)}
                            value="Fondo de ahorro"
                            checked={whereMoney === 'Fondo de ahorro'}
                            id="Fondo_de_ahorro"
                          />
                        </ContentStepContainerOption>
                        <ContentStepContainerOption>
                          <ContainerContentLabel htmlFor="En el colchon">
                            En el colchón
                          </ContainerContentLabel>
                          <Radio
                            onChange={(e) => handleWhereMoney(e)}
                            value="En el colchon"
                            checked={whereMoney === 'En el colchon'}
                            id="En_el_colchon"
                          />
                        </ContentStepContainerOption>
                      </>
                    ) : null}
                    {whereMoney && step === 0 ? (
                      <Button
                        className="roboto"
                        mb=" "
                        mt="13px"
                        bg={!whereMoney ? 'gray' : ''}
                        onClick={() => {
                          if (whereMoney && step === 0) nextStep();
                        }}
                      >
                        Continuar
                      </Button>
                    ) : null}
                  </ContainerContentStep>
                  {step > 0 && whereMoney && (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="0"
                        bg="transparent"
                      >
                        <ContainerContentStep width="fit-content" ml="auto">
                          <span style={{ marginLeft: '2rem' }}>
                            {whereMoney}{' '}
                            <CheckCircle style={{ color: '#91D770' }} />
                          </span>
                        </ContainerContentStep>
                      </ContainerContentStep>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Actualmente ahorras o inviertes?
                        </Subtitle>
                        {step === 1 ? (
                          <>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Ahorro">
                                Ahorro
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => handleInvertion(e)}
                                value="Ahorro"
                                checked={invertionValue === 'Ahorro'}
                                id="Ahorro"
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Invierto">
                                Invierto
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => handleInvertion(e)}
                                value="Invierto"
                                checked={invertionValue === 'Invierto'}
                                id="Invierto"
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Ambas">
                                Ambas
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => handleInvertion(e)}
                                value="Ambas"
                                checked={invertionValue === 'Ambas'}
                                id="Ambas"
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Ninguna">
                                Ninguna de las anteriores
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => handleInvertion(e)}
                                value="Ninguna"
                                checked={invertionValue === 'Ninguna'}
                                id="Ninguna"
                              />
                            </ContentStepContainerOption>
                          </>
                        ) : null}
                        {invertionValue && step === 1 ? (
                          <Button
                            className="roboto"
                            mb=" "
                            mt="13px"
                            bg={!invertionValue ? 'gray' : ''}
                            onClick={() => {
                              if (invertionValue && step === 1) nextStep();
                            }}
                          >
                            Continuar
                          </Button>
                        ) : null}
                      </ContainerContentStep>
                    </>
                  )}
                  {step > 1 && invertionValue && (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="0"
                        bg="transparent"
                      >
                        <ContainerContentStep width="fit-content" ml="auto">
                          <span style={{ marginLeft: '2rem' }}>
                            {invertionValue}{' '}
                            <CheckCircle style={{ color: '#91D770' }} />
                          </span>
                        </ContainerContentStep>
                      </ContainerContentStep>

                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Has ahorrado en otra divisa distinta a la mexicana?
                        </Subtitle>
                        {step === 2 ? (
                          <>
                            <CardsBtn
                              min_w="100%"
                              min_web="100%"
                              type="button"
                              onClick={() => handleInverted(1)}
                              color="#000 !important"
                              back="#fff !important"
                              border={
                                wasInverted === 1
                                  ? 'solid 1px #EC407A !important'
                                  : 'solid 1px #bababa !important'
                              }
                            >
                              Si
                            </CardsBtn>
                            <CardsBtn
                              min_w="100%"
                              min_web="100%"
                              type="button"
                              onClick={() => handleInverted(2)}
                              color="#000 !important"
                              back="#fff !important"
                              border={
                                wasInverted === 2
                                  ? 'solid 1px #EC407A !important'
                                  : 'solid 1px #bababa !important'
                              }
                            >
                              No
                            </CardsBtn>
                          </>
                        ) : null}
                      </ContainerContentStep>
                    </>
                  )}
                  {step > 1 && wasInverted === 1 ? (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="0"
                      bg="transparent"
                    >
                      <ContainerContentStep width="fit-content" ml="auto">
                        <span style={{ marginLeft: '2rem' }}>
                          Si <CheckCircle style={{ color: '#91D770' }} />
                        </span>
                      </ContainerContentStep>
                    </ContainerContentStep>
                  ) : null}
                  {step > 1 && wasInverted === 2 ? (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="0"
                      bg="transparent"
                    >
                      <ContainerContentStep width="fit-content" ml="auto">
                        <span style={{ marginLeft: '2rem' }}>
                          No <CheckCircle style={{ color: '#91D770' }} />
                        </span>
                      </ContainerContentStep>
                    </ContainerContentStep>
                  ) : null}
                  {step > 1 && wasInverted === 3 ? (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Cuál ha sido tu experiencia?
                        </Subtitle>
                        {step === 3 ? (
                          <>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Buena">
                                Buena
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => setExperienceValue(e.target.value)
                                }
                                value="Buena"
                                checked={experienceValue === 'Buena'}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Regular">
                                Regular
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => setExperienceValue(e.target.value)
                                }
                                value="Regular"
                                checked={experienceValue === 'Regular'}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="Mala">
                                Mala
                              </ContainerContentLabel>
                              <Radio
                                onChange={(e) => setExperienceValue(e.target.value)
                                }
                                value="Mala"
                                checked={experienceValue === 'Mala'}
                              />
                            </ContentStepContainerOption>
                          </>
                        ) : null}
                        {experienceValue && step === 3 ? (
                          <Button
                            className="roboto"
                            mb=" "
                            mt="13px"
                            bg={!experienceValue ? 'gray' : ''}
                            onClick={(_) => {
                              if (experienceValue && step < 4) nextStep();
                            }}
                          >
                            Continuar
                          </Button>
                        ) : null}
                      </ContainerContentStep>
                      {step > 3 ? (
                        <ContainerContentStep
                          ml="auto"
                          mr="auto"
                          padding="0"
                          bg="transparent"
                        >
                          <ContainerContentStep width="fit-content" ml="auto">
                            <span style={{ marginLeft: '2rem' }}>
                              {experienceValue}{' '}
                              <CheckCircle style={{ color: '#91D770' }} />
                            </span>
                          </ContainerContentStep>
                        </ContainerContentStep>
                      ) : null}
                    </>
                  ) : wasInverted === 2 ? (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="22px 20px"
                    >
                      <Subtitle
                        id="Subtitle"
                        className="roboto"
                        mtopM="0"
                        mbottomM="0"
                        width="auto"
                        widthM="auto"
                        lh="30px"
                        marginTop="0"
                        mbW="13px"
                        mleftM="0"
                        heightM="auto"
                      >
                        ¿Por qué?
                      </Subtitle>
                      {step < 4 ? (
                        <>
                          <ContentStepContainerOption>
                            <ContainerContentLabel htmlFor="No se">
                              No sé como
                            </ContainerContentLabel>
                            <Radio
                              onChange={(e) => handleNoSe(e)}
                              value="No sé como"
                              checked={whyValue === 'No sé como'}
                              id="No_se_como"
                            />
                          </ContentStepContainerOption>
                          <ContentStepContainerOption>
                            <ContainerContentLabel htmlFor="No sabia">
                              No sabía que podia ahorrar en otra divísa
                            </ContainerContentLabel>
                            <Radio
                              onChange={(e) => handleNoSe(e)}
                              value="No sabía que podia ahorrar en otra divísa"
                              checked={
                                whyValue
                                === 'No sabía que podia ahorrar en otra divísa'
                              }
                              id="No_sabia"
                            />
                          </ContentStepContainerOption>
                          <ContentStepContainerOption>
                            <ContainerContentLabel htmlFor="No confianza">
                              No me da confianza
                            </ContainerContentLabel>
                            <Radio
                              onChange={(e) => handleNoSe(e)}
                              value="No me da confianza"
                              checked={whyValue === 'No me da confianza'}
                              id="No_confianza"
                            />
                          </ContentStepContainerOption>
                          <ContentStepContainerOption>
                            <ContainerContentLabel htmlFor="No interesa">
                              No me interesa ahorrar
                            </ContainerContentLabel>
                            <Radio
                              onChange={(e) => handleNoSe(e)}
                              value="No me interesa ahorrar"
                              checked={whyValue === 'No me interesa ahorrar'}
                              id="No_interesa"
                            />
                          </ContentStepContainerOption>
                          {whyValue !== undefined ? (
                            <Button
                              className="roboto"
                              mb=" "
                              mt=" "
                              bg={whyValue === '' ? 'gray' : ''}
                              onClick={(_) => {
                                if (step < 4) nextStep();
                              }}
                            >
                              Continuar
                            </Button>
                          ) : null}
                        </>
                      ) : null}
                    </ContainerContentStep>
                  ) : null}
                  {step > 3 && whyValue !== undefined ? (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="0"
                      bg="transparent"
                    >
                      <ContainerContentStep width="fit-content" ml="auto">
                        <span style={{ marginLeft: '2rem' }}>
                          {whyValue}{' '}
                          <CheckCircle style={{ color: '#91D770' }} />
                        </span>
                      </ContainerContentStep>
                    </ContainerContentStep>
                  ) : null}
                  {step > 3 && wasInverted === 1 && (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿En qué divisa has ahorrado?
                        </Subtitle>
                        {step === 4 && wasInverted === 1 ? (
                          <>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="cuentas">
                                Dolar americano
                              </ContainerContentLabel>
                              <Checkbox
                                id="dolar"
                                name="dolar"
                                onChange={(e) => handleInstruAhorro(e)}
                                checked={InstruAhorro.dolar}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="seguro">
                                Euro
                              </ContainerContentLabel>
                              <Checkbox
                                id="euro"
                                name="euro"
                                onChange={(e) => handleInstruAhorro(e)}
                                checked={InstruAhorro.euro}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="fondos">
                                Crypto
                              </ContainerContentLabel>
                              <Checkbox
                                id="crypto"
                                name="crypto"
                                onChange={(e) => handleInstruAhorro(e)}
                                checked={InstruAhorro.crypto}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="cripto">
                                Otro
                              </ContainerContentLabel>
                              <Checkbox
                                id="otro"
                                name="otro"
                                onChange={(e) => handleInstruAhorro(e)}
                                checked={InstruAhorro.otro}
                              />
                            </ContentStepContainerOption>
                          </>
                        ) : null}
                        {step === 4 && btnC ? (
                          <Button
                            className="roboto"
                            mb=" "
                            mt=" "
                            bg={InstruAhorro === baseAhorro ? 'gray' : ''}
                            onClick={() => {
                              if (step < 5 && InstruAhorro.otro) {
                                nextStep();
                              } else if (step < 5 && !InstruAhorro.otro) {
                                setStep(6);
                              }
                            }}
                          >
                            Continuar
                          </Button>
                        ) : null}
                      </ContainerContentStep>
                    </>
                  )}
                  {step > 4 && wasInverted === 1 ? (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="0"
                        bg="transparent"
                      >
                        <ContainerContentStep
                          width="fit-content"
                          ml="auto"
                          display="flex"
                        >
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span style={{ marginLeft: '2rem', width: '100%' }}>
                              {InstruAhorro.cuentas && 'Dolar americano'}
                            </span>
                            <span style={{ marginLeft: '2rem', width: '100%' }}>
                              {InstruAhorro.seguro && 'Euro'}
                            </span>
                            <span style={{ marginLeft: '2rem', width: '100%' }}>
                              {InstruAhorro.fondos && 'Crypto'}
                            </span>
                            <span style={{ marginLeft: '2rem', width: '100%' }}>
                              {InstruAhorro.cripto && 'Otro'}
                            </span>
                          </div>
                          <CheckCircle style={{ color: '#91D770' }} />
                        </ContainerContentStep>
                      </ContainerContentStep>
                      {InstruAhorro.otro && (
                        <ContainerContentStep
                          ml="auto"
                          mr="auto"
                          padding="22px 20px"
                        >
                          <Subtitle
                            id="Subtitle"
                            className="roboto"
                            mtopM="0"
                            mbottomM="0"
                            width="auto"
                            widthM="auto"
                            lh="30px"
                            marginTop="0"
                            mbW="13px"
                            mleftM="0"
                            heightM="auto"
                          >
                            ¿Cuál?
                          </Subtitle>
                          {step < 6 ? (
                            <>
                              <textarea
                                rows="5"
                                style={{
                                  width: '100%',
                                  border: '1px solid #dadada',
                                  borderRadius: '5px',
                                }}
                                value={otroValue}
                                onChange={(e) => setOtroValue(e.target.value)}
                              ></textarea>
                              <Button
                                className="roboto"
                                mb=" "
                                mt=" "
                                bg={otroValue === '' ? 'gray' : ''}
                                onClick={() => {
                                  if (experienceValue || step < 6) nextStep();
                                }}
                              >
                                Continuar
                              </Button>
                            </>
                          ) : null}
                        </ContainerContentStep>
                      )}
                    </>
                  ) : null}
                  {step > 5 && otroValue !== undefined ? (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="0"
                      bg="transparent"
                    >
                      <ContainerContentStep width="fit-content" ml="auto">
                        <span style={{ marginLeft: '2rem' }}>
                          {otroValue}{' '}
                          <CheckCircle style={{ color: '#91D770' }} />
                        </span>
                      </ContainerContentStep>
                    </ContainerContentStep>
                  ) : null}
                  {step > 5 && (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="22px 20px"
                    >
                      <Subtitle
                        id="Subtitle"
                        className="roboto"
                        mtopM="0"
                        mbottomM="0"
                        width="auto"
                        widthM="auto"
                        lh="30px"
                        marginTop="0"
                        mbW="13px"
                        mleftM="0"
                        heightM="auto"
                      >
                        Si tienes $100, los ahorrarías en:
                      </Subtitle>
                      {couldInvert === 0 ? (
                        <>
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            type="button"
                            onClick={() => handleCould(1)}
                            color="#000 !important"
                            back="#fff !important"
                            border={
                              couldInvert === 1
                                ? 'solid 1px #EC407A !important'
                                : 'solid 1px #bababa !important'
                            }
                          >
                            CETES
                          </CardsBtn>
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            type="button"
                            onClick={() => handleCould(2)}
                            color="#000 !important"
                            back="#fff !important"
                            border={
                              couldInvert === 2
                                ? 'solid 1px #EC407A !important'
                                : 'solid 1px #bababa !important'
                            }
                          >
                            ETFs
                          </CardsBtn>
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            type="button"
                            onClick={() => handleCould(3)}
                            color="#000 !important"
                            back="#fff !important"
                            border={
                              couldInvert === 3
                                ? 'solid 1px #EC407A !important'
                                : 'solid 1px #bababa !important'
                            }
                          >
                            Dólares
                          </CardsBtn>
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            type="button"
                            onClick={() => handleCould(4)}
                            color="#000 !important"
                            back="#fff !important"
                            border={
                              couldInvert === 4
                                ? 'solid 1px #EC407A !important'
                                : 'solid 1px #bababa !important'
                            }
                          >
                            Criptomonedas
                          </CardsBtn>
                          <CardsBtn
                            min_w="100%"
                            min_web="100%"
                            type="button"
                            onClick={() => handleCould(5)}
                            color="#000 !important"
                            back="#fff !important"
                            border={
                              couldInvert === 5
                                ? 'solid 1px #EC407A !important'
                                : 'solid 1px #bababa !important'
                            }
                          >
                            Pesos mexicanos
                          </CardsBtn>
                        </>
                      ) : null}
                    </ContainerContentStep>
                  )}
                  {step > 6 && couldInvert !== 0 && (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="0"
                        bg="transparent"
                      >
                        <ContainerContentStep width="fit-content" ml="auto">
                          <span style={{ marginLeft: '2rem', width: '100%' }}>
                            {couldInvert === 1
                              ? 'CETES'
                              : couldInvert === 2
                                ? 'ETFs'
                                : couldInvert === 3
                                  ? 'Dólares'
                                  : couldInvert === 4
                                    ? 'Criptomonedas'
                                    : couldInvert === 5 && 'Pesos mexicanos'}
                          </span>
                          <CheckCircle style={{ color: '#91D770' }} />
                        </ContainerContentStep>
                      </ContainerContentStep>

                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Qué tan seguro te sentirías ahorrando tu dinero en
                          otra divisa (dólares, etc.)?
                        </Subtitle>
                        {BotonSlider === 0 ? (
                          <>
                            <Slider
                              defaultValue={0}
                              valueLabelFormat={valueLabelFormat}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider-restrict"
                              step={null}
                              valueLabelDisplay="auto"
                              marks={marks}
                              onChange={handleSlider}
                            />
                            <Button
                              className="roboto"
                              mb=" "
                              mt="13px"
                              onClick={() => {
                                handleBtnSlider();
                              }}
                            >
                              Continuar
                            </Button>
                          </>
                        ) : null}
                      </ContainerContentStep>
                    </>
                  )}
                  {step > 7 && BotonSlider !== 0 && (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="0"
                      bg="transparent"
                    >
                      <ContainerContentStep width="fit-content" ml="auto">
                        <span style={{ marginLeft: '2rem' }}>
                          {ValueSlider}{' '}
                          <CheckCircle style={{ color: '#91D770' }} />
                        </span>
                      </ContainerContentStep>
                    </ContainerContentStep>
                  )}
                  {step > 7 && BotonSlider !== 0 && ValueSliderc < 51 ? (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Cuáles son tus principales miedos?
                        </Subtitle>
                        {step === 8 ? (
                          <>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="perder">
                                Perder mi dinero
                              </ContainerContentLabel>
                              <Checkbox
                                id="perder"
                                name="perder"
                                onChange={(e) => handleInstruMiedo(e)}
                                checked={InstruMiedos.perder}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="divisa">
                                Que la divisa se devalue
                              </ContainerContentLabel>
                              <Checkbox
                                id="divisa"
                                name="divisa"
                                onChange={(e) => handleInstruMiedo(e)}
                                checked={InstruMiedos.divisa}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="momento_ideal">
                                Que no sea el momento ideal para ahorrar
                              </ContainerContentLabel>
                              <Checkbox
                                id="momento_ideal"
                                name="momento_ideal"
                                onChange={(e) => handleInstruMiedo(e)}
                                checked={InstruMiedos.momento_ideal}
                              />
                            </ContentStepContainerOption>
                            <ContentStepContainerOption>
                              <ContainerContentLabel htmlFor="no_saber">
                                No saber que monto me conviene ahorrar
                              </ContainerContentLabel>
                              <Checkbox
                                id="no_saber"
                                name="no_saber"
                                onChange={(e) => handleInstruMiedo(e)}
                                checked={InstruMiedos.no_saber}
                              />
                            </ContentStepContainerOption>
                          </>
                        ) : null}
                        {step === 8 && btnMi ? (
                          <Button
                            className="roboto"
                            mb=" "
                            mt=" "
                            onClick={() => {
                              nextStep();
                            }}
                          >
                            Continuar
                          </Button>
                        ) : null}
                      </ContainerContentStep>
                    </>
                  ) : null}
                  {step > 8 && BotonSlider !== 0 && ValueSliderc < 51 ? (
                    <ContainerContentStep
                      ml="auto"
                      mr="auto"
                      padding="0"
                      bg="transparent"
                    >
                      <ContainerContentStep
                        width="fit-content"
                        ml="auto"
                        display="flex"
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span style={{ marginLeft: '2rem', width: '100%' }}>
                            {InstruMiedos.perder && 'Perder mi dinero'}
                          </span>
                          <span style={{ marginLeft: '2rem', width: '100%' }}>
                            {InstruMiedos.divisa && 'Que la divisa se devalue'}
                          </span>
                          <span style={{ marginLeft: '2rem', width: '100%' }}>
                            {InstruMiedos.momento_ideal
                              && 'Que no sea el momento ideal para ahorrar'}
                          </span>
                          <span style={{ marginLeft: '2rem', width: '100%' }}>
                            {InstruMiedos.no_saber
                              && 'No saber que monto me conviene ahorrar'}
                          </span>
                        </div>
                        <CheckCircle style={{ color: '#91D770' }} />
                      </ContainerContentStep>
                    </ContainerContentStep>
                  ) : null}
                  {step > 8 && BotonSlider !== 0 && (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Qué tan fácil crees que podría ser ahorrar tu dinero
                          en dólares?
                        </Subtitle>
                        {easyAhorro === 0 ? (
                          <>
                            <CardsBtn
                              min_w="100%"
                              min_web="100%"
                              type="button"
                              onClick={() => handleEasyAhorro(1)}
                              color="#000 !important"
                              back="#fff !important"
                              border={
                                easyAhorro === 1
                                  ? 'solid 1px #EC407A !important'
                                  : 'solid 1px #bababa !important'
                              }
                            >
                              Fácil, conozco varias herramientas
                            </CardsBtn>
                            <CardsBtn
                              min_w="100%"
                              min_web="100%"
                              type="button"
                              onClick={() => handleEasyAhorro(2)}
                              color="#000 !important"
                              back="#fff !important"
                              border={
                                easyAhorro === 2
                                  ? 'solid 1px #EC407A !important'
                                  : 'solid 1px #bababa !important'
                              }
                            >
                              Difícil, no conozco ninguna herramienta para
                              hacerlo
                            </CardsBtn>
                          </>
                        ) : null}
                      </ContainerContentStep>
                    </>
                  )}
                  {step > 9 && easyAhorro !== 0 && (
                    <>
                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="0"
                        bg="transparent"
                      >
                        <ContainerContentStep width="fit-content" ml="auto">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'nowrap',
                            }}
                          >
                            <span style={{ marginLeft: '2rem', width: '100%' }}>
                              {easyAhorro === 1
                                ? 'Fácil, conozco varias herramientas'
                                : easyAhorro === 2
                                  && 'Difícil, no conozco ninguna herramienta para hacerlo'}
                            </span>
                            <CheckCircle style={{ color: '#91D770' }} />
                          </div>
                        </ContainerContentStep>
                      </ContainerContentStep>

                      <ContainerContentStep
                        ml="auto"
                        mr="auto"
                        padding="22px 20px"
                      >
                        <Subtitle
                          id="Subtitle"
                          className="roboto"
                          mtopM="0"
                          mbottomM="0"
                          width="auto"
                          widthM="auto"
                          lh="30px"
                          marginTop="0"
                          mbW="13px"
                          mleftM="0"
                          heightM="auto"
                        >
                          ¿Te gustaría tener una herramienta que te ayude a
                          entender mejor el tema?
                        </Subtitle>
                        <CardsBtn
                          min_w="100%"
                          min_web="100%"
                          type="button"
                          onClick={() => setHaveTool(1)}
                          color={haveTool === 1 ? '#fff' : '#000'}
                          back={haveTool === 1 ? '#fb245c' : '#fff '}
                        >
                          Si
                        </CardsBtn>
                        <CardsBtn
                          min_w="100%"
                          min_web="100%"
                          type="button"
                          onClick={() => setHaveTool(2)}
                          color={haveTool === 2 ? '#fff' : '#000'}
                          back={haveTool === 2 ? '#fb245c' : '#fff'}
                        >
                          No
                        </CardsBtn>
                        <Button
                          className="roboto"
                          mb=" "
                          mt=" "
                          onClick={() => {
                            if (haveTool !== 0 && step < 12) finish();
                          }}
                          bg={!haveTool ? 'gray' : ''}
                          style={{ marginTop: '20px' }}
                        >
                          Continuar
                        </Button>
                      </ContainerContentStep>
                    </>
                  )}
                </ResponsiveDiv>
              </div>
            </ContentHomeSection>
          </Container>
        </Shapes>
      </Container1>
    </WOlayout>
  );
};

export default LpAhorroEnDolares;
