import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { Image } from '../../assets/styles/Home.styled';

import {
  Container1,
  ContainerBottons,
  ContentHomeSection,
  Shapes,
  Subtitle,
  Title2,
  Subtitle2,
} from '../../components/common/Home.styled';
import { Button, Container, Col6 } from '../../components/common/common.styled';
import cuenta from '../../assets/img/lpNubank/descargasNu.svg';
import solicitud from '../../assets/img/lpRappi/solicitud.svg';
import tarjeta from '../../assets/img/lpNubank/tarjetaNu.svg';

import nu1 from '../../assets/img/lpNubank/NuCards.png';
import nu2 from '../../assets/img/lpNubank/beneficiosNu.svg';
import WOlayout from '../../components/layouts/WOlayout';
import { Helmet } from 'react-helmet';

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function LpNubank({ location }) {
  console.log('fijate aqui');
  console.log(process.env.REACT_APP_SERVERTYPE);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  const url = new URL(window.location.href);
  const idUser = url.searchParams.get('id') || '';
  const idCont = url.searchParams.get('idCont') || '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }

  sessionStorage.setItem('showModal', false);
  const history = useHistory();

  function shareCoru() {
    // TODO redirect contact-nu
    if (idUser !== '' && idCont !== '') {
      history.push(`/contact-nu?id=${idUser}&idCont=${idCont}`);
    } else {
      history.push('/contact-nu');
    }
  }

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Obtén tu Tarjeta Nu | CORU</title>
      </Helmet>
      <Container1 ov="hidden" ovX="">
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="555px"
          height="560px"
          width="100%"
          position=" "
        >
          <div className="row justify-content-center">
            <div className="col-12 col-xs-8">
              <ContainerBottons
                position=" "
                displayD="none"
                displayM="block"
                marginW="auto 1%"
              >
                <Image
                  src={nu1}
                  maxwidth="430px"
                  margin="100px auto"
                  width="65%"
                  position=""
                  left="15%"
                  top="25%"
                  displayMobile="block"
                  display=" "
                />
              </ContainerBottons>
            </div>
          </div>
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="-14rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Title2
                sizeD="50px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="15%"
                className="roboto"
              >
                {/* Llegó el momento de {window.innerWidth <= 550 ? '' : <br/> } entrenar tus finanzas */}
                Nu, una tarjeta de
                <br /> crédito sin
                <br />
                complicaciones
              </Title2>
              <div className="d-flex flex-column">
                <div>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    widthM="90%"
                    mtopM="10%"
                    mbottomM="5%"
                  >
                    Solicita tu tarjeta Nu fácil y rápido, evitando aquellas
                    largas filas de sucursal y la burocracia innecesaria que las
                    acompaña.
                  </Subtitle>
                </div>
              </div>
              <ContainerBottons
                widthD="42%"
                position=" "
                floatD="left"
                displayD="block"
                displayM="none"
                onClick={(e) => shareCoru(e)}
              >
                <Button
                  className="roboto"
                  bg="#820AD1"
                  mb="0"
                  onClick={(e) => shareCoru(e)}
                >
                  ¡Quiero mi tarjeta Nu!
                </Button>
              </ContainerBottons>
            </ContentHomeSection>
          </Container>
          <ContentHomeSection
            leftD="0px"
            floatD="right"
            rigthD="0%"
            topD="-500px"
            width="40%"
            displayD=" "
            displayM="none"
          >
            <Shapes
              id="Shapes-medio"
              positionD=" "
              position="absolute"
              zindex="-1"
            >
              <Image
                src={nu1}
                maxwidth="430px"
                MaxWidth="26%"
                MaxWidthX="26%"
                width="28%"
                position="absolute"
                left="0%"
                top="4%"
                displayMobile="none"
              />
            </Shapes>
          </ContentHomeSection>
        </Shapes>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
              margin="45px auto"
            >
              <Button
                className="d-block roboto"
                bg="#820AD1"
                mb="0"
                onClick={shareCoru}
              >
                ¡Quiero mi tarjeta Nu!
              </Button>
            </ContainerBottons>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              aling="center"
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Image
                src={nu2}
                maxwidth="430px"
                margin="40px auto auto auto"
                width="72%"
                position=""
                left="auto"
                top="0%"
                displayMobile="block"
              />
            </ContainerBottons>
          </div>
        </div>
        <Shapes
          id="Shapes2"
          key={2}
          top=" "
          left=" "
          heightD="auto"
          height="auto"
          width="100%"
          position=" "
          background="#FAFAFA"
        >
          <ContentHomeSection
            leftD=" "
            floatD=" "
            displayD="none"
            displayM="block"
          >
            <Title2
              top={heigthBrowser >= 550 ? '30px' : '45px'}
              topD="15%"
              className="roboto"
            >
              Beneficios de tu tarjeta Nu
            </Title2>
            <Subtitle2 size="14px" className="roboto" bottom="17px" top="10px">
              Adiós burocracia, adiós complejidad. Te presentamos la nueva
              tarjeta de crédito Nu: el primer grito revolucionario en contra
              del sistema financiero tradicional.
            </Subtitle2>
            <div style={{ width: '100%' }}>
              <Subtitle2
                size="14px"
                className="roboto"
                bottom="17px"
                top="10px"
              >
                <b style={{ fontSize: '20px', lineHeight: '2.5' }}>
                  Aceptada en todo el mundo
                </b>
                <br />
                Gracias al respaldo de Mastercard, la tarjeta de crédito Nu
                funciona en más de 30 millones de comercios a nivel
                internacional y sin comisión por compras en el extranjero.
              </Subtitle2>
              <Subtitle2
                size="14px"
                className="roboto"
                bottom="17px"
                top="10px"
              >
                <b style={{ fontSize: '20px', lineHeight: '2.5' }}>
                  Sin costos ni anualidad
                </b>
                <br />
                Ofrecemos cero comisiones, ni si quiera por saldo mínimo. No hay
                anualidad y contamos con una tasa de interés por debajo del
                mercado.
              </Subtitle2>
              <Subtitle2
                size="14px"
                className="roboto"
                bottom="17px"
                top="10px"
              >
                <b style={{ fontSize: '20px', lineHeight: '2.5' }}>
                  Control total desde la app Nu
                </b>
                <br />
                En tiempo real podrás ajustar tu límite de gasto, consultar
                estados de cuenta y elegir días de pago, entre otras funciones
                más. En cualquier momento puedes bloquear y desbloquear la
                tarjeta plástica y la tarjeta virtual, para proteger tus compras
                en línea.
              </Subtitle2>
            </div>
          </ContentHomeSection>
          <div style={{ width: '100%' }}>
            <Container
              width="80%"
              mt="0rem"
              widthMobile="100%"
              mb="0%"
              mtMobile="0rem"
            >
              <Col6 pt="0px" flex="0 0 100%" maxwidth="100%">
                <Image
                  src={nu2}
                  maxwidth="430px"
                  width="32%"
                  position="absolute"
                  left="0"
                  top="5%"
                  displayMobile="none"
                />
              </Col6>
              <Col6 pt="0px" display="flex" displayM="none">
                <div style={{ margin: 'auto' }}>
                  <Title2
                    top={heigthBrowser >= 550 ? '85px' : '45px'}
                    topD="15%"
                    className="roboto"
                  >
                    Beneficios de tu tarjeta Nu
                  </Title2>
                  <Subtitle2 className="roboto" bottom="17px" top="10px">
                    Adiós burocracia, adiós complejidad. Te presentamos la nueva
                    tarjeta de crédito Nu: el primer grito revolucionario en
                    contra del sistema financiero tradicional.
                  </Subtitle2>
                  <div style={{ width: '100%' }}>
                    <Subtitle2
                      className="roboto"
                      bottom="17px"
                      top="10px"
                      width="80%"
                    >
                      <b style={{ fontSize: '22px' }}>
                        Aceptada en todo el mundo
                      </b>
                      <br />
                      Gracias al respaldo de Mastercard, la tarjeta de crédito
                      Nu funciona en más de 30 millones de comercios a nivel
                      internacional y sin comisión por compras en el extranjero.
                    </Subtitle2>
                    <Subtitle2
                      className="roboto"
                      bottom="17px"
                      top="10px"
                      width="80%"
                    >
                      <b style={{ fontSize: '22px' }}>
                        Sin costos ni anualidad
                      </b>
                      <br />
                      Ofrecemos cero comisiones, ni si quiera por saldo mínimo.
                      No hay anualidad y contamos con una tasa de interés por
                      debajo del mercado.
                    </Subtitle2>
                    <Subtitle2
                      className="roboto"
                      bottom="17px"
                      top="10px"
                      width="80%"
                    >
                      <b style={{ fontSize: '22px' }}>
                        Control total desde la app Nu
                      </b>
                      <br />
                      En tiempo real podrás ajustar tu límite de gasto,
                      consultar estados de cuenta y elegir días de pago, entre
                      otras funciones más. En cualquier momento puedes bloquear
                      y desbloquear la tarjeta plástica y la tarjeta virtual,
                      para proteger tus compras en línea.
                    </Subtitle2>
                  </div>
                </div>
              </Col6>
            </Container>
            <ContentHomeSection
              leftD="auto"
              rigthD="auto"
              floatD=" "
              width="80%"
              topD="200px"
              topDW="120px"
              topMm320="40px"
            >
              <Title2
                top={heigthBrowser >= 550 ? '85px' : '45px'}
                aling="center"
                topD="15%"
                className="roboto"
              >
                ¿Cómo solicito mi tarjeta Nu?
              </Title2>
              <div className="row mt-4 justify-content-between text-center">
                <div className="col-md-3">
                  <Image src={cuenta} width="65px" />
                  <Subtitle
                    mbottomM="0"
                    bottomMm640="0"
                    sizeM="20px"
                    sizeM640="20px"
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Regístrate con Nu
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    heightM="80px"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="18px"
                    widthMm320="280px"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Ingresa tu información en Nu para validar tus datos
                  </Subtitle>
                </div>
                <div className="col-md-3">
                  <Image src={solicitud} width="40px" />
                  <Subtitle
                    mbottomM="0"
                    bottomMm640="0"
                    sizeM="20px"
                    sizeM640="20px"
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Haz tu solicitud
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    heightM="80px"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mleftMm320="20px"
                    widthMm320="280px"
                    alingMm320="justify"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Te ayudaremos haciendo una consulta de tu historial
                    crediticio para determinar tu línea de crédito
                  </Subtitle>
                </div>
                <div className="col-md-3">
                  <Image src={tarjeta} width="65px" />
                  <Subtitle
                    mbottomM="0"
                    bottomMm640="0"
                    sizeM="20px"
                    sizeM640="20px"
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Obtén tu tarjeta
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    heightM="80px"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mleftMm320="20px"
                    widthMm320="280px"
                    alingMm320="justify"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    En caso de ser aprobada, recibe tu tarjeta y disfruta de
                    todos sus beneficios
                  </Subtitle>
                </div>
              </div>
            </ContentHomeSection>
            <div className="row justify-content-center">
              <div className="col-12 col-xs-8">
                <ContainerBottons
                  widthD="30%"
                  widthM="90%"
                  widthMd="90%"
                  position=" "
                  displayD="block"
                  displayM="block"
                  marginW="auto 1%"
                >
                  <Button
                    className="d-block roboto"
                    bg="#820AD1"
                    mb="5.5rem"
                    onClick={shareCoru}
                  >
                    Solicitar mi tarjeta Nu
                  </Button>
                </ContainerBottons>
              </div>
            </div>
          </div>
        </Shapes>
      </Container1>
      {/* <Whatsapp url={""} id="Whatsapp"/> */}
    </WOlayout>
  );
}

export default LpNubank;
