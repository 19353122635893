/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  QuestionsDivs,
  QuestionsText,
  Images,
  CardsBtn1,
  DivTamFlex,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';

// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

// Other components

// Main Banks
import bancomer from '../../assets/img/anacoach/bancomer.png';
import amex from '../../assets/img/anacoach/amex.png';
import santander from '../../assets/img/anacoach/santander.png';
import scotia from '../../assets/img/anacoach/scotia.png';
import citi from '../../assets/img/anacoach/citi.png';
import editIcon from '../../assets/img/anacoach/editar1.png';
import check from '../../assets/img/anacoach/ticCheck.svg';
import uncheck from '../../assets/img/anacoach/ticUncheck.svg';

// Other banks
import Banks from '../Training/Banks';
import ClickButton from '../../Helpers/HookHelpers';

const DeudasBancarias = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  activePossibleroutines = false,
  possibleRoutines = [],
  setNextNameRoutine = '',
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"

  const [mainDebts, setMainDebts] = useState(
    sessionStorage.getItem('BancosDeudas')
      ? sessionStorage.getItem('BancosDeudas').split(',')
      : [],
  );
  const [responseViewMainDebts, setResponseViewMainDebts] = useState(
    sessionStorage.getItem('BancosDeudas') || '',
  );
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  // State used for "other bank" option
  const [modalBanks, setModalBanks] = useState(false);
  const { actions } = ClickButton();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const anchop = window.screen.width;

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [atrasoPagos] = useState(
    sessionStorage.getItem('AtrasopPagos') || '',
  );
  const [changeMainDebs, setChangeMainDebs] = useState({
    bank: '',
    doClass: '',
    id: '',
  });
  const [otherBanks, setOtherBanks] = useState(false);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editBancosD, setEditBancosD] = useState('');
  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        // console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          // console.log(routines);
          handleChangeRutine(Object.values(routines)[0]);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;
            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              // console.log("detente");
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else if (activePossibleroutines) {
              if (atrasoPagos === 'No') {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setNextNameRoutine('SinAtrasoEnPagoDeuda');
                setOrderRutine(possibleRoutines[0]);
              } else if (atrasoPagos === 'Sí') {
                setRecoveryRecoveryRoutine('activa');
                setQuestions([]);
                setNextNameRoutine('ConAtrasoEnPagoDeuda');
                setOrderRutine(possibleRoutines[1]);
              }
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Special functions fot this component
  const pushMainDebts = (bank) => {
    setMainDebts([...mainDebts, bank]);
  };

  const deleteBank = (bank) => {
    const arregloTemp = mainDebts.filter((e) => e !== bank);
    setMainDebts([]);
    setMainDebts(arregloTemp);
  };
  useEffect(() => {
    const { doClass, id } = changeMainDebs;
    if (doClass === 'add') {
      document.getElementsByClassName(id)[0].src = check;
    } else if (doClass === 'remove') {
      document.getElementsByClassName(id)[0].src = uncheck;
    }
  }, [changeMainDebs]);
  const handleChangeMainDebts = (bank, id) => {
    if (bank === 'otro') {
      if (!otherBanks) {
        setChangeMainDebs({ bank, doClass: 'add', id });
        setOtherBanks(true);
      } else {
        setChangeMainDebs({ bank, doClass: 'remove', id });
        setOtherBanks(false);
      }

      return;
    }
    if (!mainDebts.includes(bank)) {
      pushMainDebts(bank);
      setChangeMainDebs({ bank, doClass: 'add', id });
    } else {
      deleteBank(bank);
      setChangeMainDebs({ bank, doClass: 'remove', id });
    }
  };
  const setAnswerBank = () => {
    const click = `EnviarDeudasBancarias_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setEditBancosD(true);
    setResponseViewMainDebts(mainDebts.join());
    questions.push({
      id: 'mainDebts',
      question: 1,
      value: mainDebts.join(),
    });
    createJsonInput(Rutine, questions);
    setQuestions([]);
    if (activePossibleroutines) {
      if (atrasoPagos === 'No') {
        setNextNameRoutine('SinAtrasoEnPagoDeuda');
        setOrderRutine(possibleRoutines[0]);
      } else if (atrasoPagos === 'Sí') {
        setNextNameRoutine('ConAtrasoEnPagoDeuda');
        setOrderRutine(possibleRoutines[1]);
      }
    } else {
      setOrderRutine(nextOrderRoutine);
    }
  };

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setMainDebts(
        sessionStorage.getItem('BancosDeudas')
          ? sessionStorage.getItem('BancosDeudas').split(',')
          : [],
      );
      const viewResponseMainDebts = sessionStorage.getItem('BancosDeudas') || '';
      setResponseViewMainDebts(sessionStorage.getItem('BancosDeudas') || '');
      if (viewResponseMainDebts !== '') {
        if (activePossibleroutines) {
          if (atrasoPagos === 'No') {
            setNextNameRoutine('SinAtrasoEnPagoDeuda');
            setOrderRutine(possibleRoutines[0]);
          } else if (atrasoPagos === 'Sí') {
            setNextNameRoutine('ConAtrasoEnPagoDeuda');
            setOrderRutine(possibleRoutines[1]);
          }
        } else {
          setOrderRutine(nextOrderRoutine);
        }
      }
    }
  }, [recoveryRoutine]);
  const handleChangeIcon = (json) => {
    setEditBancosD(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (responseViewMainDebts === ''
          || currentRoutine === orderRutine
          || (lapiz[0].routine === 6 && lapiz[0].active === 1))
        && editBancosD !== true
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#activeBancosDeudas' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeBancosDeudas"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="¿Con qué bancos o entidades tienes tus principales deudas?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          (responseViewMainDebts === ''
            || currentRoutine === orderRutine
            || (lapiz[0].routine === 6 && lapiz[0].active === 1))
          && editBancosD !== true
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeMainDebts('BBVA', 'check-BBVA')}
          data-MainDebts="BBVA"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              left="0"
              leftw="15px"
              top="0"
              src={bancomer}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-BBVA"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeMainDebts('Citibanamex', 'check-Citibanamex')
          }
          data-MainDebts="Citibanamex"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              height="auto"
              left="0"
              leftw="15px"
              top="12px"
              src={citi}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Citibanamex"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeMainDebts('Santander', 'check-Santander')}
          data-MainDebts="Santander"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              height="auto"
              left="0"
              leftw="15px"
              top="12px"
              src={santander}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Santander"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeMainDebts('Scotiabank', 'check-Scotiabank')
          }
          data-MainDebts="Scotiabank"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="100px"
              height="auto"
              left="0"
              leftw="15px"
              top="12px"
              src={scotia}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-Scotiabank"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          className="border-pink"
          onClick={() => handleChangeMainDebts('American Express', 'check-American-Express')
          }
          data-MainDebts="American Express"
        >
          <DivTamFlex width="60%">
            <Images
              position="relative"
              width="90px"
              left="0"
              leftw="15px"
              top="2px"
              src={amex}
            ></Images>
          </DivTamFlex>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-American-Express"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        <QuestionsDivs
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          }}
          txta="left"
          widthweb="60%"
          width="100%"
          marginLmd="0"
          margin="auto auto 20px 18%"
          className="border-pink"
          onClick={() => {
            handleChangeMainDebts('otro', 'check-otro');
            setModalBanks(!modalBanks);
          }}
        >
          <QuestionsText
            style={{ width: '60%' }}
            size="16px"
            weight="400"
            padd="5px"
          >
            Otro
          </QuestionsText>
          <DivTamFlex width="40%" mr="15px" ta="right">
            <Images
              className="check-otro"
              position="relative"
              width="30px"
              left="0"
              leftw="0"
              top="6px"
              src={uncheck}
            ></Images>
          </DivTamFlex>
        </QuestionsDivs>
        {modalBanks ? (
          <div>
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard={modalBanks ? 'cardOne' : 'hidden'}
              classTop="topOne1"
              text="Selecciona la institución:"
              hora={hora}
            />
            <br />
            <Banks handleChangeBanks={handleChangeMainDebts} />
          </div>
        ) : null}
      </TrainingQuestion>
      {((mainDebts.length > 0 && responseViewMainDebts === '')
        || (lapiz[0].routine === 6 && lapiz[0].active === 1))
      && editBancosD !== true ? (
        <TrainingQuestion className="">
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="#fafafa"
          >
            <CardsBtn1 onClick={() => setAnswerBank()}>Continuar</CardsBtn1>
          </QuestionsDivs>
        </TrainingQuestion>
        ) : null}
      <CardTwo
        classCard={
          responseViewMainDebts !== ''
          && (editBancosD
            || !(lapiz[0].routine === 6 && lapiz[0].active === 1))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={responseViewMainDebts.split(',')[0]}
        text2={responseViewMainDebts.split(',')[1]}
        text3={responseViewMainDebts.split(',')[2]}
        text4={responseViewMainDebts.split(',')[3]}
        text5={responseViewMainDebts.split(',')[4]}
        text6={responseViewMainDebts.split(',')[5]}
        text7={responseViewMainDebts.split(',')[6]}
        text8={responseViewMainDebts.split(',')[7]}
        text9={responseViewMainDebts.split(',')[8]}
        text10={responseViewMainDebts.split(',')[9]}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={responseViewMainDebts !== '' ? 'iconEditCommon' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 7, active: 1 })}
        />
      </CardTwo>
    </div>
  );
};

export default DeudasBancarias;
