import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  TrainingMain,
  TrainingSectionGray,
  TrainingDivSection2,
} from '../../assets/styles/Training.styled';
import { INSERT_FINISHED_WO_BY_XCOACH } from '../../mutations';
import Header from '../../components/common/Header';
import RpFamilyProtection from '../Routines/RpFamilyProtection';
import ModalRespConSinPremio from '../../components/Feed/ModalRespConSinPremio';
import WOlayout from '../../components/layouts/WOlayout';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import ModalVirality from '../../components/common/ModalVirality';

function SuccessFamilyProtection() {
  const [currentTraining] = useState(
    sessionStorage.getItem('CurrentFP'),
  );
  const [insuranceActive] = useState(
    sessionStorage.getItem('InsuranceFP'),
  );
  const [trainingColor] = useState(
    sessionStorage.getItem('ColorFP'),
  );

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    const idTraining = sessionStorage.getItem('idTraining');
    const fromXCoach = sessionStorage.getItem('fromXCoach');
    const idAdviser = sessionStorage.getItem('idAdviser');
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === 'true' ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  return (
    <WOlayout
      backgroundColor={'#fafafa'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Header
        url={window.location.pathname}
        pathname={window.location.pathname}
        background={trainingColor}
      />
      <ModalVirality />
      <HeaderSuccess
        color1="#61D2FA"
        color2="#56B7FA"
        color3="#62B6E3"
        color4="#8DC2E3"
        color5="#BODBFF"
        color6="#69A3E6"
        color7="#A7FOFA"
        color8="#80EDFB"
        color9="#81D5DE"
        color10="#65BCC7"
      />

      <TrainingMain>
        <TrainingSectionGray
          className="col-12"
          mw="auto"
          back="#FAFAFA"
          mtop="0"
        >
          <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
            <RpFamilyProtection
              currentTraining={currentTraining}
              insuranceActive={insuranceActive}
              trainingColor={trainingColor}
            />
          </TrainingDivSection2>
        </TrainingSectionGray>
      </TrainingMain>
      <ModalRespConSinPremio
        show={sessionStorage.getItem('medium') === 'recompensas'}
        premio={sessionStorage.getItem('campaign') === 'con-premio'}
      />
    </WOlayout>
  );
}
export default SuccessFamilyProtection;
