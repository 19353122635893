import React, { Fragment } from "react";
import CoachTrainingRoutes from "./CoachTrainingRoutes";
import CoreRoutes from "./CoreRoutes";
import ExtraRoutes from "./ExtraRoutes";
import OtherRoutes from "./OtherRoutes";
import PerfilRoutes from "./PerfilRoutes";

const index = () => (
  <Fragment>
    <CoreRoutes />

    <PerfilRoutes />

    <CoachTrainingRoutes />

    <OtherRoutes />

    <ExtraRoutes />
  </Fragment>
);

export default index;
