import React, { Fragment } from 'react';
import {
  ImageSections,
  CardsUModal,
  CardsTxt,
  ModalDivFlex,
  Images,
  CardsAnchorBtn,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/Home.css';
import stars from '../../assets/img/anacoach/5Stars.png';
import SectionShare from '../common/ShareSection';

function ModalProductosOCA(props) {
  const pro = JSON.parse(props.products);
  let productsThree = pro[0].Name;
  if (pro.length === 2) {
    productsThree = `${pro[0].Name} ${pro[1].Name}`;
  } else if (pro.length === 3) {
    productsThree = `${pro[0].Name}, ${pro[1].Name}, ${pro[2].Name}`;
  } else if (pro.length > 3) {
    productsThree = `${pro[0].Name}, ${pro[1].Name}, ${pro[2].Name}, ${pro[3].Name}`;
  }

  const showButton = props.showButton ? props.showButton : false;
  return (
    <>
      <CardsUModal
        width="60%"
        marginLeft="18%"
        widthxs="100%"
        marginrightxs="auto"
        marginLeftxs="auto"
        className="productoMod"
        id={pro[0].IDProduct}
        height={'auto'}
        mheight={'auto'}
      >
        <ModalDivFlex width="100%" align="left">
          <ImageSections
            width="74px"
            widthweb="74px"
            heightweb="auto"
            src={pro[0].ImageURL}
            className="img-fluid"
          />
        </ModalDivFlex>
        <ModalDivFlex width="100%" align="left">
          <CardsTxt
            className="cabin"
            color="#07210A"
            mt="14px"
            mb="14px"
            weight="600"
            size="20px"
            sizeweb="20px"
          >
            {pro[0].Name}
          </CardsTxt>
          <hr />
          {pro[0].DescriptionProduct === '' || pro[0].DescriptionProduct === null || pro[0].DescriptionProduct === undefined
            ?
            (
              <CardsTxt color="#4F4F4F" size="14px">
                {pro[0].BusinessAbr}
              </CardsTxt>)
            :
            (<CardsTxt color="#4F4F4F" size="14px">
              {pro[0].DescriptionProduct}
            </CardsTxt>)
          }
          {JSON.parse(pro[0].productDetail.response).map((detalle) => {
            if (detalle.Name === 'CAT') {
              return (
                <CardsTxt color="#4F4F4F" size="14px">
                  CAT {(detalle.Description * 100).toFixed(2)}%
                </CardsTxt>
              );
            }
          })}
          {JSON.parse(pro[0].productDetail.response).map((detalle) => {
            if (detalle.Name === 'Interés anual') {
              return (
                <CardsTxt color="#4F4F4F" size="14px">
                  Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                </CardsTxt>
              );
            }
          })}

          {showButton ? (
            <CardsAnchorBtn
              min_w="100%"
              min_web="100%"
              href={pro[0].ClickOut}
              target="_blank"
            >
              Seleccionar
            </CardsAnchorBtn>
          ) : (
            ''
          )}

          <Images
            src={stars}
            top="0"
            topweb="0"
            left="0"
            leftw="0"
            position="initial"
            width="116px"
            widthw="116px"
            className="hidden img-fluid"
          ></Images>
        </ModalDivFlex>
      </CardsUModal>
      {pro.length > 1 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[1].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[1].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[1].Name}
            </CardsTxt>
            <hr />
            {pro[1].DescriptionProduct === '' || pro[1].DescriptionProduct === null || pro[1].DescriptionProduct === undefined
            ?
            (
              <CardsTxt color="#4F4F4F" size="14px">
                {pro[1].BusinessAbr}
              </CardsTxt>)
            :
            (<CardsTxt color="#4F4F4F" size="14px">
              {pro[1].DescriptionProduct}
            </CardsTxt>)
          }
            {JSON.parse(pro[1].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[1].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsAnchorBtn
                min_w="100%"
                min_web="100%"
                href={pro[1].ClickOut}
                target="_blank"
              >
                Seleccionar
              </CardsAnchorBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 2 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[2].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[2].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[2].Name}
            </CardsTxt>
            <hr />
            {pro[2].DescriptionProduct === '' || pro[2].DescriptionProduct === null || pro[2].DescriptionProduct === undefined
            ?
            (
              <CardsTxt color="#4F4F4F" size="14px">
                {pro[2].BusinessAbr}
              </CardsTxt>)
            :
            (<CardsTxt color="#4F4F4F" size="14px">
              {pro[2].DescriptionProduct}
            </CardsTxt>)
          }
            {JSON.parse(pro[2].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[2].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsAnchorBtn
                min_w="100%"
                min_web="100%"
                href={pro[2].ClickOut}
                target="_blank"
              >
                Seleccionar
              </CardsAnchorBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 3 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[3].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[3].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[3].Name}
            </CardsTxt>
            <hr />
            {pro[3].DescriptionProduct === '' || pro[3].DescriptionProduct === null || pro[3].DescriptionProduct === undefined
            ?
            (
              <CardsTxt color="#4F4F4F" size="14px">
                {pro[3].BusinessAbr}
              </CardsTxt>)
            :
            (<CardsTxt color="#4F4F4F" size="14px">
              {pro[3].DescriptionProduct}
            </CardsTxt>)
          }
            {JSON.parse(pro[3].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[3].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsAnchorBtn
                min_w="100%"
                min_web="100%"
                href={pro[3].ClickOut}
                target="_blank"
              >
                Seleccionar
              </CardsAnchorBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 4 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[4].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[4].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[4].Name}
            </CardsTxt>
            <hr />
            {pro[4].DescriptionProduct === '' || pro[4].DescriptionProduct === null || pro[4].DescriptionProduct === undefined
            ?
            (
              <CardsTxt color="#4F4F4F" size="14px">
                {pro[4].BusinessAbr}
              </CardsTxt>)
            :
            (<CardsTxt color="#4F4F4F" size="14px">
              {pro[4].DescriptionProduct}
            </CardsTxt>)
          }
            {JSON.parse(pro[4].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[4].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsAnchorBtn
                min_w="100%"
                min_web="100%"
                href={pro[4].ClickOut}
                target="_blank"
              >
                Seleccionar
              </CardsAnchorBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      <div id="productos3" style={{ display: 'none' }}>
        {productsThree}
      </div>
      {/* menos de 7k */}
      {showButton ? <SectionShare WO=""></SectionShare> : null}
    </>
  );
}

export default ModalProductosOCA;