import React, { useState, Fragment, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/common/Header';
import {
  Feed_Main,
  Feed_Section,
} from '../../assets/styles/Feed.styled';
import CardOne from '../../components/Home/Card1Animate';
import HeaderSuccess from '../../components/Training/HeaderSucces';
import {
  Container,
  ParagrapTwo,
  LinksFooter,
} from '../../components/common/common.styled';
import {
  Rectangle13,
  UiCard,
  TextoCoach,
  ContentHomeSection,
  Title1,
  Subtitle2,
  Rectangle24,
  Vector2,
  Vector3,
} from '../../components/common/Home.styled';
import { Div_Flex } from '../../assets/styles/Home.styled';
import Recommendations from '../../components/Training/RecommendedTraining';
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import {
  TrainingSectionGray,
  TrainingDivSection2,
  CardsBtn,
  TrainingSubtitles,
  TrainingDivSection,
  TrainingQuestion,
  TrainingText,
  TrainigTam,
  TrainingMain,
} from '../../assets/styles/Training.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import WOlayout from '../../components/layouts/WOlayout';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ModalVirality from '../../components/common/ModalVirality';


function RpUserProfiling2_0() {
  const [open, setOpen] = useState(false);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [titleModalRiesgo, setTitleModalRiesgo] = useState('');
  const [descModalRiesgo, setDescModalRiesgo] = useState('');
  const [titleModalFuturo, setTitleModalFuturo] = useState('');
  const [descModalFuturo, setDescModalFuturo] = useState('');
  const [type, setType] = useState('');
  const [puntosDinero] = useState(sessionStorage.getItem('puntosDinero') || 0);
  const [puntosRiesgo] = useState(sessionStorage.getItem('puntosRiesgo') || 0);
  const [puntosFuturo] = useState(sessionStorage.getItem('puntosFuturo') || 0);

  let firstname = '';
  let nameClient = '';
  let emailClient = '';
  let telClient = '';
  firstname = sessionStorage.getItem('FirstName') || '';
  nameClient = `${sessionStorage.getItem('FirstName')} ${sessionStorage.getItem(
    'FathersLastName',
  )}`;
  emailClient = sessionStorage.getItem('Email1');
  telClient = sessionStorage.getItem('CellPhone');

  const handleOpenModal = (type) => {
    setType(type);
    console.log(type);
    setOpen(true);
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '400px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      borderRadius: '5px',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '550px',
    },
  }));
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const ModalBudget = () => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const handleClose = () => {
      setOpen(false);
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    };

    return (
      /* logo Ana */
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ParagrapTwo
            fontWeight="700"
            cursor="pointer"
            texttalign="right"
            textalignMobile="right"
            className="mx-auto"
            onClick={handleClose}
          >
            X
          </ParagrapTwo>
          <ContentHomeSection
            displayD="flex"
            leftD="0%"
            floatD="left"
            width="100%;"
          >
            <ContentHomeSection
              leftD="0%"
              floatD="left"
              width="48%;"
            ></ContentHomeSection>
            <ImageCoachInicio />
            <ContentHomeSection
              leftD="0%"
              floatD="left"
              width="35%;"
            ></ContentHomeSection>
          </ContentHomeSection>
          <ContentHomeSection width="100%" leftD=" " rigthD="0%" floatD=" ">
            <Rectangle24
              positionD="absolute"
              Ptop="125px"
              PTopM="-65px"
              Pleft="-10%"
              background="#6BD5AF"
              displayM="block"
              displayD=" "
              left=" "
              top=" "
              width="70px"
              height="70px"
              transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
            />
            <Vector2
              width="55px"
              left="0"
              PleftM="35%"
              height="65px"
              background="#91D770"
              radius="40%"
              transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
              positionD="absolute"
              Pleft="17%"
              Ptop="-5px"
              displayM="block"
            />
            <Vector3
              position="absolute"
              left=" "
              top=" "
              Ptop="10px"
              Pleft="44%"
              background=" #E3E660;"
              transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
              width="35px"
              height="35px"
              displayD=" "
              displayM="none"
            />
            <Rectangle24
              positionD="absolute"
              Ptop="100px"
              Pleft="74%"
              PLeftM="-78%"
              PTopM="-32%"
              background="#BFDD6A"
              displayM="block"
              displayD=" "
              left=" "
              top=" "
              width="30.94px"
              height="30.94px"
              transform="rotate(46.41deg)"
            />
            <Vector2
              width="50px"
              widthM="28.81px"
              heightM="35.02px"
              left=""
              height="60px"
              background="#6BD5AF"
              radius="40%"
              transform="rotate(-8.34deg)"
              positionD="absolute"
              Pleft="52%"
              PleftM="-15%"
              PTopM="-10%"
              MtopM="-15%"
              Ptop="135px"
              displayM="block"
            />
            <Vector3
              position="absolute"
              left=" "
              top=" "
              Ptop="85px"
              Pleft="14%"
              PleftM="-3%"
              background="#E3E660"
              transform="rotate(-15.06deg)"
              width="35px"
              height="35px"
              displayD=" "
              displayM="block"
            />
            <Rectangle24
              widthM="35px"
              heightM="35px"
              mLeftM="0"
              mTopM="18%"
              positionD="absolute"
              Pleft="70%"
              Ptop="0"
              displayD=""
              width="60px"
              height="60px"
              left=" "
              top=" "
              background="#91D770"
              transform="rotate(-17.24deg)"
            />
            <UiCard
              positionM=" "
              heightM="150px"
              marginTop="150px"
              marginTopM="120px"
            >
              <Rectangle13
                margin="auto"
                bottomM="0px"
                displayM="flex"
                position=" "
                topM="0px"
                height="156px"
                width="100%"
                widthm="100%"
                padding="19px 25px 20px 21px"
              >
                <TextoCoach className="roboto" width="auto">
                  {type === 'riesgo' ? (
                    <>
                      {' '}
                      <b>{titleModalRiesgo}</b>
                      <br />
                      <br />
                      {descModalRiesgo}{' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      <b>{titleModalFuturo}</b>
                      <br />
                      <br />
                      {descModalFuturo}{' '}
                    </>
                  )}
                </TextoCoach>
              </Rectangle13>
            </UiCard>
          </ContentHomeSection>
          <br />
          <CardsBtn min_w="100%" min_web="100%" onClick={() => handleClose()}>
            <b>Cerrar</b>
          </CardsBtn>
          <br />
        </div>
      </Modal>
    );
  };

  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    if (puntosRiesgo >= 1 && puntosRiesgo <= 3) {
      setTitleModalRiesgo('Nivel 1 de riesgo');
      setDescModalRiesgo(
        'No te gusta arriesgar nada y tus actividades comunes te permiten tomar el control de tus decisiones.',
      );
    } else if (puntosRiesgo >= 4 && puntosRiesgo <= 7) {
      setTitleModalRiesgo('Nivel 2 de riesgo');
      setDescModalRiesgo(
        'Tomas decisiones que pueden ser arriesgadas pero sabes como resolverlo, recuerda que siempre es bueno tener un plan B.',
      );
    } else if (puntosRiesgo >= 8 && puntosRiesgo <= 12) {
      setTitleModalRiesgo('Nivel 3 de riesgo');
      setDescModalRiesgo(
        'Tu vida se ve agitada, recuerda que es bueno tener protección para ti, tus seres queridos y sobretodo tener protegido tu dinero.',
      );
    }
  }, [puntosRiesgo]);

  useEffect(() => {
    if (puntosFuturo >= 1 && puntosFuturo <= 2) {
      setTitleModalFuturo('Nivel 1 de planeación');
      setDescModalFuturo(
        'Es importante pensar en tu futuro y que beneficios financieros necesitas',
      );
    } else if (puntosFuturo >= 3 && puntosFuturo <= 5) {
      setTitleModalFuturo('Nivel 2 de planeación');
      setDescModalFuturo(
        'Recuerda que una prioridad debería ser tu futuro y como lo construyes poco a poco',
      );
    } else if (puntosFuturo >= 6 && puntosFuturo <= 9) {
      setTitleModalFuturo('Nivel 3 de planeación');
      setDescModalFuturo(
        'Tu ambición por el futuro es increible, vas por un buen camino sigue prestando atención a esos detalles.',
      );
    }
  }, [puntosFuturo]);

  return (
    <WOlayout
      backgroundColor={'#FAB78C'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <TrainigTam>
        <Header background={'#FAB78C'} pathname={window.location.pathname} />
      </TrainigTam>
      <HeaderSuccess
        color1="#FAB78C"
        color2="#E0B965"
        color3="#F5CA5D"
        color4="#F5A838"
        color5="#E3A75F"
        color6="#FA9D59"
        color7="#FACFA9"
        color8="#FAB78C"
        color9="#FA9878"
        color10="#DE9E83"
      />

      <TrainingMain mt="50px" mtw="0" className="" id="top">
        <ModalAmazon show={true} wo={'Profiling2'}></ModalAmazon>
        <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-12 col-xs-12">
          <TrainingSectionGray
            className="col-12"
            mw="auto"
            back="#FAFAFA"
            mtop="9%"
          >
            <TrainingDivSection2
              paddiw="2% 10% 0 10%"
              paddi="0% 5% 5% 5%"
              back="#fafafa"
            >
              <TrainingSubtitles
                size="20px"
                fw="600"
                align="center"
                alignweb="center"
                className="cabin"
              >
                ¡Gracias {firstname.trim()}!
              </TrainingSubtitles>
              <TrainingText
                size="16px"
                weight="400"
                className="roboto"
                align="center"
                alignweb="center"
                ls="-0.2px"
              >
                Hemos procesado tu solicitud y este es el resultado.
              </TrainingText>
              <TrainingSubtitles
                size="20px"
                fw="600"
                align="center"
                alignweb="center"
                className="cabin"
              >
                Estos son tus datos que registramos:
              </TrainingSubtitles>
              <TrainingQuestion width="100%" wiw="100%" mt="0">
                <div className="marginComentLgSuccess1">
                  <TrainingDivSection
                    hew="auto"
                    paddw="26px 26px 0"
                    mlw="18%"
                    mtw="8%"
                    wiw="60%"
                    back="trasparent"
                    backw="trasparent"
                    bshaw="none"
                    bsha=""
                    br="4px"
                    padding="0"
                    wi="100%"
                    he="auto"
                    className="topOne1 topOne1Init"
                  >
                    <TrainingText
                      size="16px"
                      className="roboto"
                      align="left"
                      alignweb="left"
                      ls="-0.2px"
                    >
                      <b>Nombre cliente:</b> {nameClient}
                      <br />
                      <b>Teléfono:</b> {telClient}
                      <br />
                      <b>Email:</b> {emailClient}
                    </TrainingText>
                  </TrainingDivSection>
                </div>
              </TrainingQuestion>
            </TrainingDivSection2>
          </TrainingSectionGray>
          <TrainingQuestion width="100%" wiw="100%" mt="0">
            <div className="marginComentLgSuccess1">
              <TrainingDivSection
                hew="auto"
                paddw="26px"
                mlw="18%"
                mtw="8%"
                wiw="60%"
                back="#FFFFFF"
                backw="#FFFFFFF"
                bshaw="none"
                br="4px"
                padding="20px"
                wi="90%"
                he="auto"
                className="topOne1 topOne1Init"
                bsha="0px 2px 8px rgba(0, 0, 0, 0.08)"
              >
                <Title1
                  style={{ fontStyle: 'normal' }}
                  backgroundM="transparent"
                  pbottomM="6%"
                  pbottom="2%"
                  background="transparent"
                  heightM="auto"
                  aling="left"
                  className="roboto cabin"
                  size="16px"
                  mtopM="0%"
                  mt="0px"
                  mleftM="0px"
                  sizeM="20px"
                  widthM="100%"
                  width="68%"
                >
                  Relación con el dinero:
                </Title1>
                <Div_Flex width="100%">
                  <Subtitle2
                    aling="left"
                    widthm="100%"
                    marginTop="4px"
                    mlm="auto"
                    width="auto"
                    size="16px"
                    height="auto"
                    className="roboto"
                    bottom=" "
                    top="4px"
                  >
                    {puntosDinero === 1 ? 'Mantener en forma' : null}
                    {puntosDinero === 2 ? 'Es un buen camino' : null}
                    {puntosDinero === 3 ? 'Hay mucho que mejorar' : null}
                    {puntosDinero >= 4 && puntosDinero <= 6
                      ? 'El control es lo tuyo. Tienes el control de tu dinero, eres realista y prevenido. en Coru encontrarás entrenamientos para mantenerte en forma'
                      : null}
                    {puntosDinero >= 7 && puntosDinero <= 8
                      ? 'Mejoremos juntos. Actualmente tus finanzas son un tema serio y nosotros te ayudaremos a mejorar en ellas. Tranquilo tenemos entrenamientos para comenzar y que vuelvas a hacer las paces con tu dinero'
                      : null}
                    {puntosDinero >= 9 && puntosDinero <= 12
                      ? 'Mejoremos juntos. Actualmente tus finanzas son un tema serio y nosotros te ayudaremos a mejorar en ellas. Tranquilo tenemos entrenamientos para comenzar y que vuelvas a hacer las paces con tu dinero'
                      : null}
                  </Subtitle2>
                </Div_Flex>
                <Div_Flex width="100%">
                  <div className="col6 col-md-6 col-lg-6">
                    <Title1
                      style={{ fontStyle: 'normal' }}
                      backgroundM="transparent"
                      pbottom="0%"
                      pbottomM="6%"
                      background="transparent"
                      mleft="auto"
                      mright="auto"
                      heightM="auto"
                      aling="center"
                      className="roboto cabin"
                      size="16px"
                      mtopM="0%"
                      mt="0px"
                      mleftM="0px"
                      sizeM="20px"
                      widthM="100%"
                      width="68%"
                    >
                      Nivel de riesgo
                    </Title1>
                  </div>
                  <div className="col6 col-md-6 col-lg-6">
                    <Title1
                      style={{ fontStyle: 'normal' }}
                      backgroundM="transparent"
                      pbottom="0%"
                      pbottomM="6%"
                      background="transparent"
                      mleft="auto"
                      mright="auto"
                      heightM="auto"
                      aling="center"
                      className="roboto cabin"
                      size="16px"
                      mtopM="0%"
                      mt="0px"
                      mleftM="0px"
                      sizeM="20px"
                      widthM="100%"
                      width="68%"
                    >
                      Plan a futuro
                    </Title1>
                  </div>
                </Div_Flex>
                <Div_Flex width="100%">
                  <div className="col6 col-md-6 col-lg-6">
                    <Subtitle2
                      aling="center"
                      widthm="100%"
                      marginTop="4px"
                      mlm="auto"
                      width="auto"
                      size="14px"
                      height="auto"
                      className="roboto"
                      bottom=" "
                      top="4px"
                      onClick={() => handleOpenModal('riesgo')}
                    >
                      <LinksFooter
                        className="link roboto"
                        fontSize="14px"
                        color="#F11CA9!important"
                      >
                        ¿Qué es esto?{' '}
                      </LinksFooter>
                    </Subtitle2>
                  </div>
                  <div className="col6 col-md-6 col-lg-6">
                    <Subtitle2
                      aling="center"
                      widthm="100%"
                      marginTop="4px"
                      mlm="auto"
                      width="auto"
                      size="14px"
                      height="auto"
                      className="roboto"
                      bottom=" "
                      top="4px"
                      onClick={() => handleOpenModal('futuro')}
                    >
                      <LinksFooter
                        className="link roboto"
                        fontSize="14px"
                        color="#F11CA9!important"
                      >
                        ¿Qué es esto?{' '}
                      </LinksFooter>
                    </Subtitle2>
                  </div>
                </Div_Flex>
              </TrainingDivSection>
            </div>
          </TrainingQuestion>
        </div>
      </TrainingMain>

      {open ? <ModalBudget /> : null}
      {recomendaciones ? (
        <Fragment>
          <div className="form-web">
            <Feed_Main width="100%" background=" ">
              <Feed_Main
                width="100%"
                display=" "
                mtop=" "
                background="transparent"
              >
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  textbold="¡Sin miedo al éxito!"
                  text2="Estos son los entrenamientos que te ayudarán a cumplir tus objetivos:"
                />
              </Feed_Main>
            </Feed_Main>
          </div>
          <div className="marginComentLgSuccess1">
            <Container
              mleftmxs="0%"
              mleft="18%"
              mleftm="7%"
              width="100%"
              widthMobile="100%"
              mtMobile="0px"
            >
              <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                  <Recommendations
                    items={recommendedTraining}
                  ></Recommendations>
                </ContentHomeSection>
              </Feed_Section>
            </Container>
          </div>
        </Fragment>
      ) : null}

      <TrainingSectionGray back="#fafafa">
        <TrainingDivSection2
          wiw="670px"
          paddi="0"
          paddiw="0"
          mw="auto"
          back="#fafafa"
          mbm="100px"
        >
          <SectionShare WO="Profiling2"></SectionShare>
          <ModalVirality />
        </TrainingDivSection2>
      </TrainingSectionGray>
    </WOlayout>
  );
}
export default RpUserProfiling2_0;
