/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React from "react";
import {
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingQuestion,
} from '../../assets/styles/Training.styled';

import { useMutation } from '@apollo/client';
import { PASS_TEMPORAL } from '../../mutations';

const InfoWoMonetizable = ({ nameClient, telClient, emailClient, paddingSm }) => {

  const [passTemporal] = useMutation(PASS_TEMPORAL, {
    onCompleted({passTemporal}) {
      const { statusCode, message, error, response } = passTemporal;
      console.log("MESSAGE PASSTEMPO", message)
      if (message == "error") {
        console.log('Error PassTemp', error);
      }
      if (message == "success") {
       console.log('Successs temporal');
      }
    }
  });

  return (

    <TrainingQuestion width="100%" wiw="100%" mt="0" paddingSm={paddingSm}>
      <div className="marginComentLgSuccess1">
        <TrainingDivSection
          hew="auto"
          paddw="26px 26px 0"
          mlw="18%"
          mtw="8%"
          wiw="65%"
          back="trasparent"
          backw="trasparent"
          bshaw="none"
          bsha=""
          br="4px"
          padding="0"
          wi="100%"
          he="auto"
          className="topOne1 topOne1Init"
        >
          <TrainingSubtitles
            size="18px"
            fw="600"
            align="left"
            alignweb="left"
            className="cabin"
          >
            Estos son tus datos que registramos:
          </TrainingSubtitles>
          <br />
          <TrainingText
            size="16px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            <b>Nombre cliente:</b> {nameClient}
            <br />
            <b>Teléfono:</b> {telClient}
            <br />
            <b>Email:</b> {emailClient}
          </TrainingText>
          <TrainingText
            size="16px"
            className="roboto"
            align="left"
            alignweb="left"
            ls="-0.2px"
          >
            Creamos una contraseña temporal para ti, podrás encontrarla en tu correo y
            modificarla cuando quieras en Perfil.
          </TrainingText>
        </TrainingDivSection>
      </div>
    </TrainingQuestion>
  );
};

export default InfoWoMonetizable;
