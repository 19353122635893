/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useLazyQuery, useMutation } from '@apollo/client';
import editIcon from '../../assets/img/anacoach/editar1.png';
import '../../assets/styles/Home.css';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingText,
  TrainingDivSection,
  TrainingQuestion,
  QuestionsDivs,
  CardsBtn,
  ErrorDiv,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, getQueryVariableHashtagDeeplink } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
import { DYNAMIC_GET } from '../../mutations';

const DatosBasicos = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  mainDataRoutine = 0,
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const [recoveryDatosPrincipales, setRecoveryDatosPrincipales] = useState('');
  const [isData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const anchop = window.screen.width;
  const [dynamicGet] = useMutation(DYNAMIC_GET, {
    onCompleted: (dynamicData) => {
      dynamicData = JSON.parse(dynamicData.dynamicGet.response)[0];

      if (!dynamicData) return;

      const tempFirstName = sessionStorage.getItem('temp-FirstName')
        || sessionStorage.getItem('FirstName')
        || dynamicData.FirstName
        || '';
      const tempMiddleName = sessionStorage.getItem('temp-MiddleName')
        || sessionStorage.getItem('MiddleName')
        || dynamicData.MiddleName
        || '';
      const tempFathersLastName = sessionStorage.getItem('temp-FathersLastName')
        || sessionStorage.getItem('FathersLastName')
        || dynamicData.FathersLastName
        || '';
      const tempMothersLastName = sessionStorage.getItem('temp-MothersLastName')
        || sessionStorage.getItem('MothersLastName')
        || dynamicData.MothersLastName
        || '';
      const tempEmail1 = sessionStorage.getItem('temp-Email1')
        || sessionStorage.getItem('Email1')
        || dynamicData.Email1
        || '';
      const tempCellPhone = sessionStorage.getItem('temp-CellPhone')
        || sessionStorage.getItem('CellPhone')
        || dynamicData.CellPhone
        || '';
      const tempZipCode = sessionStorage.getItem('temp-ZipCode')
        || sessionStorage.getItem('ZipCode')
        || dynamicData.ZipCode
        || '';
      const tempBirthDate = sessionStorage.getItem('temp-BirthDate')
        || sessionStorage.getItem('BirthDate')
        || dynamicData.BirthDate;

      if (
        tempFirstName
        && tempMiddleName
        && tempFathersLastName
        && tempMothersLastName
        && tempEmail1
        && tempCellPhone
        && tempZipCode
        && tempBirthDate
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    },
  });

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      let stopRecovery = false;
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryDatosPrincipales('inactiva');
        } else {
          const routines = json; // Object.values(json);
          const contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else if (parseInt(idTypeQuestion) === 78) {
                  if (responseUser !== null && responseUser !== 'null') {
                    let date = responseUser.substr(0, 10);

                    date += ''.split('-')[0];
                    sessionStorage.setItem('year', date.split('-')[0]);
                    sessionStorage.setItem('month', date.split('-')[1]);
                    sessionStorage.setItem('day', date.split('-')[2]);
                    form.day = date.split('-')[2];
                    form.month = date.split('-')[1];
                    form.year = date.split('-')[0];

                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      date,
                    );
                  } else {
                    stopRoutine = true;
                    // break;
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (parseInt(idTypeQuestion) === 78) {
                stopRoutine = true;
                // break;
              }
              if (mainDataRoutine === 1 && i === 6) {
                stopRecovery = true;
                setRecoveryDatosPrincipales('activa');
                setQuestions([]);
                break;
              }
            }

            if (stopRecovery) {
              break;
            }

            if (
              arrayResponseRutine.length
                < routines[idRoutine].questions.length
              && !stopRoutine
            ) {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
              break;
            }
            if (
              arrayResponseRutine.length === 7
              || arrayResponseRutine.length === 8
            ) {
              if (!stopRoutine) {
                sessionStorage.setItem('dataPerson', 'Envio Datos');
                setDatosPersonales('Envio Datos');
                if (nextOrderRoutine >= orderRutine) {
                  setOrderRutine(nextOrderRoutine);
                }
              }
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
            } else {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser')
      && sessionStorage.getItem('idCont')
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryDatosPrincipales('inactiva');
    }
    const {
      idUser,
      idCont,
    } = getQueryVariableHashtagDeeplink('profile');
    if (idUser && idCont) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(idUser),
          idCont: String(idCont),
        },
      });
    }
  }, []);

  useEffect(() => {
    const dynamicInput = {
      table:
        'CatUsers as cu INNER JOIN CatContact as cc ON cu.CatContact_IDCont = cc.IDCont INNER JOIN CatContactMedium as cm ON cc.IDCont = cm.CatContact_IDCont INNER JOIN CatAddress as ca ON cc.IDCont = ca.CatContact_IDCont',
      columns: [
        'cm.Email1',
        'cm.CellPhone',
        'cc.Gender',
        'cc.BirthDate',
        'ca.ZipCode',
        'cc.FirstName',
        'cc.MiddleName',
        'cc.FathersLastName',
        'cc.MothersLastName',
      ],
      conditions: [
        {
          valone: 'cu.IDUser',
          condition: '=',
          valtwo: sessionStorage.getItem('idUser') || '0',
        },
        {
          logic: '',
          valone: '',
          condition: '',
          valtwo: '',
          validColumn: false,
        },
      ],
    };

    dynamicGet({
      variables: {
        input: dynamicInput,
      },
    });
  }, []);

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [dataPerson, setDatosPersonales] = useState(
    sessionStorage.getItem('dataPerson') || '',
  );
  const [alertaDatosP, setAlertaDP] = useState(false);
  const [alertaFecha, setAlertaFecha] = useState(false);
  const [seccionNombre, setSeccionNombre] = useState(false);
  const [seccionFechaNacimiento, setSeccionFechaNacimiento] = useState(false);
  const [form, setForm] = useState({
    FirstName: sessionStorage.getItem('FirstName') || '',
    FathersLastName: sessionStorage.getItem('FathersLastName') || '',
    MiddleName: sessionStorage.getItem('MiddleName') || '',
    Email1: sessionStorage.getItem('Email1') || '',
    MothersLastName: sessionStorage.getItem('MothersLastName') || '',
    day: sessionStorage.getItem('day') || Date.day,
    month: sessionStorage.getItem('month') || Date.month,
    year: sessionStorage.getItem('year') || Date.year,
    cel: sessionStorage.getItem('CellPhone') || '',
  });
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editDP, setEditDP] = useState('');
  const handleChange = (e) => {
    if (e.target.name === 'Email1') {
      setForm({
        ...form,
        [e.target.name]: String(e.target.value).replace(' ', '').toLowerCase(),
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
    switch (e.target.name) {
      case 'FirstName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      case 'MiddleName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      case 'FathersLastName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      case 'MothersLastName':
        ValidLetrasName(e.target.name, e.target.value, e.target.id);
        break;
      case 'Email1':
        ValidEmail(e.target.id, String(e.target.value).toLowerCase());
        break;
      case 'day':
        try {
          document.getElementById('day').className = 'textInput inputs-bottom validSelect';
        } catch (error) {
          document.querySelectorAll('#day')[1].className = 'textInput inputs-bottom validSelect';
        }

        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'month':
        try {
          document.getElementById('month').className = 'textInput inputs-bottom validSelect';
          document.getElementById('SeccionFecha').disabled = false;
          document.getElementById('SeccionFecha').onclick = (e) => handleChangeFechaNacimiento(e);
          document.getElementById('SeccionFecha').style.backgroundColor = '#F64282';
        } catch (error) {
          document.querySelectorAll('#month')[1].className = 'textInput inputs-bottom validSelect';
          document.getElementById('SeccionFecha').disabled = false;
          document.getElementById('SeccionFecha').onclick = (e) => handleChangeFechaNacimiento(e);
          document.getElementById('SeccionFecha').style.backgroundColor = '#F64282';
        }

        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'year':
        try {
          document.getElementById('year').className = 'textInput inputs-bottom validSelect';
        } catch (error) {
          document.querySelectorAll('#year')[1].className = 'textInput inputs-bottom validSelect';
        }

        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'cel':
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };
  const [error] = useState({
    Email1: false,
    FirstName: false,
    MiddleName: false,
    FathersLastName: false,
    MothersLastName: false,
    day: false,
    month: false,
    year: false,
    cel: false,
    landline: false,
    Email1valid: form.Email1.length !== 0 ? 0 : -1,
    Firstnamevalid: form.FirstName.length !== 0 ? 0 : -1,
    MiddleNamevalid: form.MiddleName.length !== 0 ? 0 : -1,
    lastnamevalid: form.FathersLastName.length !== 0 ? 0 : -1,
    MothersLastNamevalid: form.MothersLastName.length !== 0 ? 0 : -1,
    phonevalid: form.cel.length !== 0 ? 0 : -1,
  });
  const [texterror] = useState({
    Email1: '',
    FirstName: '',
    MiddleName: '',
    FathersLastName: '',
    MothersLastName: '',
    day: '',
    month: '',
    year: '',
  });
  const [TypeCorreo, SetTypeCorreo] = useState({
    text: '',
    display: '',
  });
  const ValidEmail = (id, valueEmail) => {
    const idmail = document.getElementById(id);
    let domain = valueEmail.split('@');
    const anexo = domain[0];
    domain = domain[1];
    texterror.Email1 = 'El formato del email no es correcto';
    error.Email1 = true;
    error.Email1valid = 1;
    if (idmail === null) {
      // console.log('null');
    } else if (
      valueEmail === ''
        || (valueEmail
          && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail))
    ) {
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.Email1 = 'Correo electrónico incorrecto';
      error.Email1 = true;
      error.Email1valid = 1;
      document.getElementById('SeccionContacto').disabled = true;
      document.getElementById('SeccionContacto').style.backgroundColor = '#A6A6A6';
      setAlertaDP(true);
    } else if (
      domain !== ''
        && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      if (domain === 'h') {
        SetTypeCorreo({
          display: 'block',
          text: `Quisiste decir: ${anexo}@hotmail`,
        });
      } else if (domain === 'g') {
        SetTypeCorreo({
          display: 'block',
          text: `Quisiste decir: ${anexo}@gmail.com`,
        });
      }
      idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.Email1 = 'Correo electrónico incorrecto';
      error.Email1 = true;
      error.Email1valid = 1;
      setAlertaDP(true);
      document.getElementById('SeccionContacto').disabled = true;
      document.getElementById('SeccionContacto').style.backgroundColor = '#A6A6A6';
    } else {
      idmail.className = 'textInput inputs-bottom validInput';
      texterror.Email1 = '';
      error.Email1 = false;
      error.Email1valid = 0;
      setAlertaDP(false);
      document.getElementById('SeccionContacto').disabled = false;
      document.getElementById('SeccionContacto').onclick = (e) => handleChangeDatosPersonales(e);
      document.getElementById('SeccionContacto').style.backgroundColor = '#F64282';
    }
  };
  const handleChangeAge = (name, value) => {
    let nacimiento = `${form.year}-${form.month}-${form.day}`;
    switch (name) {
      case 'year':
        nacimiento = `${value}-${form.month}-${form.day}`;
        break;
      case 'mont':
        nacimiento = `${form.year}-${value}-${form.day}`;
        break;
      case 'day':
        nacimiento = `${form.year}-${form.month}-${value}`;
        break;
      default:
        nacimiento = `${form.year}-${form.month}-${form.day}`;
        break;
    }
  };
  const ValidPhone = (name, value, id) => {
    if (document.getElementById('SeccionContacto') !== null) {
      document.getElementById('SeccionContacto').disabled = true;
      document.getElementById('SeccionContacto').style.backgroundColor = '#A6A6A6';
      setForm({
        ...form,
        [name]: value.replace(/[^0-9]/i, ''),
      });
      const tel = document.getElementById(id);
      if (tel === null) {
        document.getElementById('SeccionContacto').disabled = true;
        document.getElementById('SeccionContacto').style.backgroundColor = '#A6A6A6';
        setAlertaFecha(true);
      } else if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
        tel.className = 'textInput inputs-bottom invalidInput';
        texterror[name] = 'Por favor verifica tu número telefónico';
        error[name] = true;
        error[`${name}valid`] = 1;
        setAlertaFecha(true);
      } else {
        const Emailval = document.getElementById('Email').value;
        const telval = document.getElementById('Teléfono Celular').value;
        if (Emailval !== '' && telval !== '') {
          tel.className = 'textInput inputs-bottom validInput';
          setAlertaFecha(false);
          document.getElementById('SeccionContacto').disabled = false;
          document.getElementById('SeccionContacto').onclick = (e) => handleChangeDatosPersonales(e);
          document.getElementById('SeccionContacto').style.backgroundColor = '#F64282';
          if (document.getElementById('error1') !== null) {
            document.getElementById('error1').style.display = 'none';
          }
        }
      }
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementById(id);
    if (element === null) {
      // console.log('es null');
    } else if (valor.length > 1) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else if (nombre === 'MiddleName') {
      error[nombre] = false;
      texterror[nombre] = '';
      error[`${nombre}valid`] = -1;
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
  };

  const ValidLetrasName = (nombre, valor, id) => {
    if (document.getElementById('SeccionNombre') !== null) {
      document.getElementById('SeccionNombre').disabled = true;
      document.getElementById('SeccionNombre').style.backgroundColor = '#A6A6A6';
      document.getElementById(id).style.textTransform = 'capitalize';
      setForm({
        ...form,
        [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
      });
      const el = document.getElementById(id);

      if (valor === null || valor === undefined || valor === '') {
        if (nombre === 'MiddleName') {
          el.className = 'textInput inputs-bottom invalidInput';
          const Pnom1 = document.getElementById('Primer Nombre').value;
          const apeP1 = document.getElementById('Apellido Paterno').value;
          const apeM1 = document.getElementById('Apellido Materno').value;
          if (Pnom1 !== '' && apeP1 !== '' && apeM1 !== '') {
            document.getElementById('SeccionNombre').disabled = false;
            document.getElementById('SeccionNombre').style.backgroundColor = '#F64282';
            document.getElementById('SeccionNombre').onclick = (e) => handleChangeNombre(e);
            document.getElementById('error1').style.display = 'none';
            setAlertaFecha(false);
          } else {
            document.getElementById('SeccionNombre').disabled = true;
            document.getElementById('SeccionNombre').style.backgroundColor = '#A6A6A6';
          }
        } else {
          document.getElementById('SeccionNombre').disabled = true;
          document.getElementById('SeccionNombre').style.backgroundColor = '#A6A6A6';
          el.className = 'textInput inputs-bottom invalidInput';
        }
      } else if (valor.length >= 1) {
        el.className = ' textInput inputs-bottom validInput';
        setAlertaFecha(true);

        const Pnom = document.getElementById('Primer Nombre').value;
        const apeP = document.getElementById('Apellido Paterno').value;
        const apeM = document.getElementById('Apellido Materno').value;
        if (Pnom !== '' && apeP !== '' && apeM !== '') {
          document.getElementById('SeccionNombre').disabled = false;
          document.getElementById('SeccionNombre').style.backgroundColor = '#F64282';
          document.getElementById('SeccionNombre').onclick = (e) => handleChangeNombre(e);
          document.getElementById('error1').style.display = 'none';
          setAlertaFecha(false);
        }
      } else if (nombre === 'MiddleName') {
        error[nombre] = false;
        texterror[nombre] = '';
        error[`${nombre}valid`] = -1;
        el.className = 'textInput inputs-bottom invalidInput';
      } else {
        el.className = 'textInput inputs-bottom invalidInput';
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = 'Debe ingresar más de 3 caracteres';
      }
    }
  };

  useEffect(() => {
    if (recoveryDatosPrincipales === 'activa') {
      let firtsName = sessionStorage.getItem('FirstName') || '';
      let cellPhone = sessionStorage.getItem('CellPhone') || '';
      cellPhone = firtsName.indexOf('name temporal') !== -1 ? '' : cellPhone;
      sessionStorage.setItem('CellPhone', cellPhone);

      firtsName = firtsName.indexOf('name temporal') !== -1 ? '' : firtsName;
      sessionStorage.setItem('FirstName', firtsName);
      localStorage.setItem('FirstName', firtsName);

      let email = sessionStorage.getItem('Email1') || '';
      email = email.indexOf('user_tem_') !== -1 ? '' : email;
      sessionStorage.setItem('Email1', email);
      localStorage.setItem('Email1', email);

      setForm(
        Object.assign(form, {
          FirstName: sessionStorage.getItem('FirstName') || '',
          FathersLastName: sessionStorage.getItem('FathersLastName') || '',
          MiddleName: sessionStorage.getItem('MiddleName') || '',
          Email1: sessionStorage.getItem('Email1') || '',
          MothersLastName: sessionStorage.getItem('MothersLastName') || '',
          day: sessionStorage.getItem('day') || Date.day,
          month: sessionStorage.getItem('month') || Date.month,
          year: sessionStorage.getItem('year') || Date.year,
          cel: sessionStorage.getItem('CellPhone') || '',
        }),
      );
    }
  }, [recoveryDatosPrincipales]);
  // funcionalidad
  useEffect(() => {
    if (orderRutine === currentRoutine) {
      if (form.year !== '' && form.month !== '' && form.day !== '-') {
        handleChangeQuestionsSend(
          5,
          'dateBurn',
          `${form.year}-${form.month}-${form.day}`,
        );
        sessionStorage.setItem('year', form.year);
        sessionStorage.setItem('month', form.month);
        sessionStorage.setItem('day', form.day);
      }
    }
  }, [form, dataPerson]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        sessionStorage.getItem('FirstName') !== null
        && sessionStorage.getItem('FirstName') !== ''
      ) {
        ValidLetrasName(
          'FirstName',
          sessionStorage.getItem('FirstName'),
          'Primer Nombre',
        );
      }
      if (
        sessionStorage.getItem('FathersLastName') !== null
        && sessionStorage.getItem('FathersLastName') !== ''
      ) {
        ValidLetrasName(
          'FathersLastName',
          sessionStorage.getItem('FathersLastName'),
          'Apellido Paterno',
        );
      }
      if (
        sessionStorage.getItem('middleName') !== null
        && sessionStorage.getItem('middleName') !== ''
      ) {
        ValidLetrasName(
          'MiddleName',
          sessionStorage.getItem('middleName'),
          'Segundo Nombre',
        );
      }
      if (
        sessionStorage.getItem('MothersLastName') !== null
        && sessionStorage.getItem('MothersLastName') !== ''
      ) {
        ValidLetrasName(
          'MothersLastName',
          sessionStorage.getItem('MothersLastName'),
          'Apellido Materno',
        );
      }
      if (document.getElementById('SeccionContacto') !== null) {
        if (
          sessionStorage.getItem('Email1') !== null
          && sessionStorage.getItem('Email1') !== ''
        ) {
          ValidEmail('Email', sessionStorage.getItem('Email1'));
          const campo = document.getElementById('Email');
          if (campo !== null && !error.Email1) {
            campo.readOnly = true;
            campo.disabled = true;
          }
        }
        if (
          sessionStorage.getItem('CellPhone') !== null
          && sessionStorage.getItem('CellPhone') !== ''
        ) {
          ValidPhone(
            'cel',
            sessionStorage.getItem('CellPhone'),
            'Teléfono Celular',
          );
        }
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (document.getElementById('SeccionNombre') !== null) {
        if (
          sessionStorage.getItem('FirstName') !== null
          && sessionStorage.getItem('FirstName') !== ''
        ) {
          ValidLetrasName(
            'FirstName',
            sessionStorage.getItem('FirstName'),
            'Primer Nombre',
          );
        }
        if (
          sessionStorage.getItem('FathersLastName') !== null
          && sessionStorage.getItem('FathersLastName') !== ''
        ) {
          ValidLetrasName(
            'FathersLastName',
            sessionStorage.getItem('FathersLastName'),
            'Apellido Paterno',
          );
        }
        if (
          sessionStorage.getItem('middleName') !== null
          && sessionStorage.getItem('middleName') !== ''
        ) {
          ValidLetrasName(
            'MiddleName',
            sessionStorage.getItem('middleName'),
            'Segundo Nombre',
          );
        }
        if (
          sessionStorage.getItem('MothersLastName') !== null
          && sessionStorage.getItem('MothersLastName') !== ''
        ) {
          ValidLetrasName(
            'MothersLastName',
            sessionStorage.getItem('MothersLastName'),
            'Apellido Materno',
          );
        }
      }
      if (document.getElementById('SeccionFecha') !== null) {
        if (form.day !== '' && form.month !== '' && form.year !== '') {
          validAnio();
          validDay();
          validMonth();
        }
      }
      if (document.getElementById('SeccionContacto') !== null) {
        if (
          sessionStorage.getItem('Email1') !== null
          && sessionStorage.getItem('Email1') !== ''
        ) {
          ValidEmail('Email', sessionStorage.getItem('Email1'));
        }
        if (
          sessionStorage.getItem('CellPhone') !== null
          && sessionStorage.getItem('CellPhone') !== ''
        ) {
          ValidPhone(
            'cel',
            sessionStorage.getItem('CellPhone'),
            'Teléfono Celular',
          );
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [seccionNombre, editDP, seccionFechaNacimiento]);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const handleChangeNombre = (e) => {
    e.preventDefault();

    if (
      form.FirstName !== ''
      && form.FathersLastName !== ''
      && form.MothersLastName !== ''
    ) {
      gsap.to(window, { duration: 1, scrollTo: e.target.getAttribute('href') });
      setSeccionNombre(true);
      setAlertaDP(false);
      sessionStorage.setItem('FirstName', form.FirstName);
      if (sessionStorage.getItem('idTraining') === '35') {
        const clickEventCC = `Nombre_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
        actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
      }
      const click = `ContinuarNombre_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else {
      setSeccionNombre(false);
      setAlertaDP(true);
    }
  };

  const validAnio = () => {
    try {
      document.getElementById('year').className = 'textInput inputs-bottom validSelect';
      document.getElementById('SeccionFecha').disabled = false;
      document.getElementById('SeccionFecha').onclick = (e) => handleChangeFechaNacimiento(e);
      document.getElementById('SeccionFecha').style.backgroundColor = '#F64282';
    } catch (error) {
      document.querySelectorAll('#year')[1].className = 'textInput inputs-bottom validSelect';
    }
  };
  const validMonth = () => {
    try {
      document.getElementById('month').className = 'textInput inputs-bottom validSelect';
    } catch (error) {
      document.querySelectorAll('#month')[1].className = 'textInput inputs-bottom validSelect';
    }
  };
  const validDay = () => {
    try {
      document.getElementById('day').className = 'textInput inputs-bottom validSelect';
    } catch (error) {
      document.querySelectorAll('#day')[1].className = 'textInput inputs-bottom validSelect';
    }
  };

  const handleChangeFechaNacimiento = (e) => {
    e.preventDefault();
    const mon = document.getElementById('month').textContent;
    const dia = document.getElementById('day').textContent;
    if (mon !== '' && dia !== '') {
      document.getElementById('SeccionFecha').disabled = false;
      gsap.to(window, { duration: 1, scrollTo: e.target.getAttribute('href') });
      setAlertaFecha(false);
      setSeccionFechaNacimiento(true);
      setTimeout(() => {
        if (
          sessionStorage.getItem('Email1') !== null
          && sessionStorage.getItem('Email1') !== ''
        ) {
          ValidEmail('Email', sessionStorage.getItem('Email1'));
          const campo = document.getElementById('Email');
          if (campo !== null && !error.Email1) {
            campo.readOnly = true;
            campo.disabled = true;
          }
        }
      }, 1000);
      if (sessionStorage.getItem('idTraining') === '35') {
        const clickEventCC = `FechaDeNacimiento_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
        actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
      }
      const click = `ContinuarFechaDeNacimiento_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else {
      setAlertaFecha(true);
      setSeccionFechaNacimiento(false);
    }
  };

  const handleChangeCatOption = (val) => {
  };
  const handleChangeDatosPersonales = (e) => {
    e.preventDefault();
    if (!error.Email1 && !error.cel !== '' && form.cel.length === 10) {
      gsap.to(window, { duration: 1, scrollTo: e.target.getAttribute('href') });
      setEditDP(true);
      actions({ action: 'validAffiliate', variables: form.email });
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setDatosPersonales('Envio Datos');
      setOrderRutine(nextOrderRoutine);
      const tagManagerArgs = {
        dataLayer: {
          event: 'PersonalInfo',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      sessionStorage.setItem('dataPerson', 'Envio Datos');
      const click = `ComenzarEntrenamiento_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      sessionStorage.setItem('DataPerson', true);
    } else if (form.Email1 !== '' && form.cel !== '' && form.cel.length === 9) {
      gsap.to(window, { duration: 1, scrollTo: e.target.getAttribute('href') });
      setEditDP(true);
      createJsonInput(Rutine, questions);
      setQuestions([]);
      setDatosPersonales('Envio Datos');
      setOrderRutine(nextOrderRoutine);
      const tagManagerArgs = {
        dataLayer: {
          event: 'PersonalInfo',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      sessionStorage.setItem('dataPerson', 'Envio Datos');
      const click = `ComenzarEntrenamiento_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else {
      setAlertaFecha(false);
      setAlertaDP(true);
    }
  };
  const path = window.location.pathname;
  const handleChangeIcon = (json) => {
    setEditDP(false);
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      if (path.includes('debts')) {
        setOrderRutine(2);
      } else {
        setOrderRutine(lapiz[0].routine);
      }
    }
  }, [lapiz]);

  if (
    recoveryDatosPrincipales === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="0rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return (
      <div>
        <TrainingQuestion
          width="100%"
          wiw="100%"
          mt="0"
          className={
            (dataPerson === ''
              || (lapiz[0].routine === 1 && lapiz[0].active === 1))
            && (editDP === false || editDP === '')
              ? ''
              : 'hidden'
          }
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="#fafafa"
            bs="transparent"
          >
            <form className="form-web">
              {orderRutine === currentRoutine ? (
                <Fragment>
                  <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
                    <ImageCoachInicio
                      classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                      topMobile="35px"
                    />
                    <Col7
                      maxwidth="100%"
                      padding=" "
                      className="cardPrincipal1"
                    >
                      <TrainingDivSection
                        hew="auto"
                        paddw="26px"
                        mlw="0%"
                        mtw="8%"
                        wiw="100%"
                        back="#F3F3F3"
                        backw="#F3F3F3"
                        bshaw="none"
                        bsha=""
                        br="4px"
                        padding="20px"
                        wi="100%"
                        he="auto"
                        className="topOne1 topOne1Init"
                      >
                        <TrainingText
                          bot="0px"
                          className="cabin"
                          align="left"
                          weight="600"
                          size="20px"
                          sizew="20px"
                        >
                          ¿Cuál es tu nombre?
                        </TrainingText>
                        {!seccionNombre ? (
                          <Fragment>
                            <InputsOrder
                              handleChangeQuestionsSend={
                                handleChangeQuestionsSend
                              }
                              handleChangeRutine={handleChangeRutine}
                              orderRutine={orderRutine}
                              step="0-3"
                              handleChangeCatOption={handleChangeCatOption}
                              form={form}
                              handleChange={handleChange}
                              TypeCorreo={TypeCorreo}
                            />
                            <br />
                            <ErrorDiv
                              className={alertaFecha === true ? '' : 'hidden'}
                            >
                              Parece que te falto llenar algún campo
                            </ErrorDiv>
                            <ErrorDiv
                              id="error1"
                              className={alertaDatosP === true ? '' : 'hidden'}
                            >
                              Parece que te falto llenar algún campo
                            </ErrorDiv>
                            <CardsBtn
                              disabled
                              back="#A6A6A6"
                              id="SeccionNombre"
                              type="button"
                              min_w="100%"
                              min_web="100%"
                              mtopw="10px"
                              onClick={(e) => handleChangeNombre(e)}
                              href="#one"
                            >
                              Continuar
                            </CardsBtn>
                          </Fragment>
                        ) : null}
                      </TrainingDivSection>
                    </Col7>
                  </Container>
                  <DivRespDP id="one">
                    <img
                      alt="editIcon"
                      className={
                        seccionNombre && !seccionFechaNacimiento
                          ? 'iconEdit1'
                          : 'hidden'
                      }
                      src={editIcon}
                      onClick={() => setSeccionNombre(false)}
                    />
                    <CardTwo
                      classCard={seccionNombre ? 'cardTwoResp' : 'hidden'}
                      classTop="topTwo1"
                      imgMan="hidden"
                      textT={''}
                      text2={
                        `${form.FirstName
                        } ${
                          form.MiddleName
                        } ${
                          form.FathersLastName
                        } ${
                          form.MothersLastName}`
                      }
                      icon={true}
                      colorTraining={trainingColor}
                    ></CardTwo>
                  </DivRespDP>
                  {seccionNombre ? (
                    <Fragment>
                      <Container
                        width="80%"
                        mt="0"
                        widthMobile="90%"
                        mtMobile="0"
                      >
                        <ImageCoachInicio
                          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                          topMobile="35px"
                        />
                        <Col7
                          maxwidth="100%"
                          padding=" "
                          className="cardPrincipal1"
                        >
                          <TrainingDivSection
                            hew="auto"
                            className="topOne1"
                            paddw="26px"
                            mlw="0%"
                            mtw="8%"
                            wiw="100%"
                            back="#F3F3F3"
                            backw="#F3F3F3"
                            bshaw="none"
                            bsha=""
                            br="4px"
                            padding="20px"
                            wi="100%"
                            he="auto"
                          >
                            <TrainingText
                              bot="0px"
                              className="cabin"
                              align="left"
                              weight="600"
                              size="20px"
                              sizew="20px"
                            >
                              ¿Cuál es tu fecha de nacimiento?
                            </TrainingText>
                            {!seccionFechaNacimiento ? (
                              <Fragment>
                                <InputsOrder
                                  handleChangeQuestionsSend={
                                    handleChangeQuestionsSend
                                  }
                                  handleChangeRutine={handleChangeRutine}
                                  orderRutine={orderRutine}
                                  step="1"
                                  handleChangeCatOption={handleChangeCatOption}
                                  form={form}
                                  handleChange={handleChange}
                                  TypeCorreo={TypeCorreo}
                                  date={true}
                                />
                                <ErrorDiv
                                  className={
                                    alertaFecha === true ? '' : 'hidden'
                                  }
                                >
                                  Parece que no has llenado tu fecha de
                                  nacimiento
                                </ErrorDiv>
                                <ErrorDiv
                                  className={
                                    alertaDatosP === true ? '' : 'hidden'
                                  }
                                >
                                  Parece que te falto llenar algún campo
                                </ErrorDiv>
                                <CardsBtn
                                  disabled
                                  back="#A6A6A6"
                                  id="SeccionFecha"
                                  type="button"
                                  min_w="100%"
                                  min_web="100%"
                                  mtopw="10px"
                                  href="#two"
                                  onClick={(e) => handleChangeFechaNacimiento(e)
                                  }
                                >
                                  Continuar
                                </CardsBtn>
                              </Fragment>
                            ) : null}
                          </TrainingDivSection>
                        </Col7>
                      </Container>
                      <DivRespDP id="two">
                        <img
                          alt="editIcon"
                          className={
                            seccionFechaNacimiento && !editDP
                              ? 'iconEdit1'
                              : 'hidden'
                          }
                          src={editIcon}
                          onClick={() => setSeccionFechaNacimiento(false)}
                        />
                        <CardTwo
                          classCard={
                            seccionFechaNacimiento ? 'cardTwoResp' : 'hidden'
                          }
                          classTop="topTwo1"
                          imgMan="hidden"
                          textT={''}
                          text2={`${form.day}/${form.month}/${form.year}`}
                          icon={true}
                          colorTraining={trainingColor}
                        ></CardTwo>
                      </DivRespDP>
                    </Fragment>
                  ) : null}
                  {seccionFechaNacimiento ? (
                    <Container
                      width="80%"
                      mt="0"
                      widthMobile="90%"
                      mtMobile="0"
                    >
                      <ImageCoachInicio
                        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                        topMobile="35px"
                      />
                      <Col7
                        maxwidth="100%"
                        padding=" "
                        className="cardPrincipal1"
                      >
                        <TrainingDivSection
                          hew="auto"
                          className="topOne1"
                          paddw="26px"
                          mlw="0%"
                          mtw="8%"
                          wiw="100%"
                          back="#F3F3F3"
                          backw="#F3F3F3"
                          bshaw="none"
                          bsha=""
                          br="4px"
                          padding="20px"
                          wi="100%"
                          he="auto"
                        >
                          <TrainingText
                            bot="0px"
                            className="cabin"
                            align="left"
                            weight="600"
                            size="20px"
                            sizew="20px"
                          >
                            Datos de contacto
                          </TrainingText>
                          <InputsOrder
                            handleChangeQuestionsSend={
                              handleChangeQuestionsSend
                            }
                            handleChangeRutine={handleChangeRutine}
                            orderRutine={orderRutine}
                            step="5-6"
                            handleChangeCatOption={handleChangeCatOption}
                            form={form}
                            handleChange={handleChange}
                            TypeCorreo={TypeCorreo}
                            activeValidateRutine={true}
                          />
                          <br />
                          <ErrorDiv
                            className={alertaFecha === true ? '' : 'hidden'}
                          >
                            El formato del teléfono no es correcto
                          </ErrorDiv>
                          <ErrorDiv
                            className={alertaDatosP === true ? '' : 'hidden'}
                          >
                            Parece que algún campo no se lleno correctamente
                          </ErrorDiv>
                          <CardsBtn
                            disabled
                            back="#A6A6A6"
                            id="SeccionContacto"
                            type="button"
                            min_w="100%"
                            min_web="100%"
                            mtopw="10px"
                            onClick={(e) => handleChangeDatosPersonales(e)}
                            href="#video"
                          >
                            Comenzar entrenamiento
                          </CardsBtn>
                        </TrainingDivSection>
                      </Col7>
                    </Container>
                  ) : null}
                </Fragment>
              ) : null}
              <br />
            </form>
          </QuestionsDivs>
        </TrainingQuestion>
        {isData ? null : (
          <Fragment>
            <img
              id="iconDatContacto"
              alt="editIcon"
              className={
                (dataPerson !== ''
                  && !(lapiz[0].routine === 1 && lapiz[0].active === 1))
                || editDP === true
                  ? 'iconEdit1'
                  : 'hidden'
              }
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 1, active: 1 })}
            />
            <CardTwo
              classCard={
                (dataPerson !== ''
                  && !(lapiz[0].routine === 1 && lapiz[0].active === 1))
                || editDP === true
                  ? 'cardTwoResp'
                  : 'hidden'
              }
              classTop="topTwo1"
              imgMan="hidden"
              textT={'Tus datos registrados:'}
              text2={
                `${form.FirstName
                } ${
                  form.MiddleName
                } ${
                  form.FathersLastName
                } ${
                  form.MothersLastName}`
              }
              text3={` Email: ${form.Email1}`}
              text4={`Teléfono celular: ${form.cel}`}
              text5={
                ` Fecha de nacimiento: ${
                  form.day
                }/${
                  form.month
                }/${
                  form.year}`
              }
              icon={true}
              colorTraining={trainingColor}
            ></CardTwo>
          </Fragment>
        )}
        <div id="video"></div>
      </div>
  );
};

export default DatosBasicos;
