import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import {
  ContainerSection, Subtitle, TrData, TdData, ContainerBox, Buttons,
} from '../Styleds/Asesores.Styled.js';
import ShareCommonFeed from '../../../anaCoach/Training/ShareCommonFeed';
import { GET_RECOMMENDATION_TRAININGS } from '../../../mutations';

const useStyles = makeStyles((theme) => ({
  ControlSelect: {
    minWidth: 360,
  },
}));

const TrainingData = ({ basicInfo, trainingByUser }) => {
  const classes = useStyles();
  const [activeTrainings, setActiveTrainings] = useState([]);
  const [woActivo, setWoActivo] = useState('');
  const [urlWoActivo, setUrlWoActivo] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;

    if (value.TrainingRedirectUrl) {
      setWoActivo(value);
      setUrlWoActivo(
        `${value.TrainingRedirectUrl}#${basicInfo.idUser}#${basicInfo.idCont}#true#true#${sessionStorage.getItem('idUser')}#${basicInfo.email}`,
      );
    } else {
      setWoActivo('');
      setUrlWoActivo();
    }
  };

  const goToTraining = () => {
    if (urlWoActivo) {
      window.open(urlWoActivo, '_blank');
    }
  };

  const Id1 = { IDCatTraining: 1 };
  const Id35 = { IDCatTraining: 38 };
  const Id43 = { IDCatTraining: 43 };

  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          // idTrainings que están como concluidos o en progreso
          trainingByUser.push(Id1, Id35, Id43);
          const lstIdTrainings = trainingByUser.map((e) => e.IDCatTraining);
          const toDelete = new Set(lstIdTrainings);

          // Todos los trainings
          const trainings = getPersonalizeRecommendationTrainings.response;
          const activeTrainings = trainings.filter((obj) => !toDelete.has(obj.IDCatTraining));

          setActiveTrainings(activeTrainings);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const { email } = basicInfo;
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  return (
    <ContainerSection bckC="#FFFFFF" dis="flex" fDir="row" marB="20px" marL="175px" marT="20px" >

      <ContainerBox wid="300px" marR="90px" >
        <Subtitle className="roboto" marB="20px">Entrenamientos en progreso</Subtitle>
        <table className="table table-borderless table-sm">
          <tbody>
            {trainingByUser.map((training, index) => {
              if (training.Achievment < 100) {
                return (
                  <TrData key={index}>
                    <TdData className="roboto">
                      <a
                        href={`${training.TrainingRedirectUrl}#${basicInfo.idUser}#${basicInfo.idCont
                          }#true#true#${sessionStorage.getItem('idUser')}#${basicInfo.email
                          }`}
                        target="_blank"
                        className="enlaceXcoach"
                        title={training.Name} rel="noreferrer"
                      >
                        {training.Name}
                      </a>
                    </TdData>
                    <TdData className="roboto">{moment(training.LastUpdated).format('DD/MM/YYYY')}</TdData>
                    {/* <TdData className="roboto">
                      <ShareCommonFeed
                        url={training.TrainingRedirectUrl}
                        listXCoach={true}
                        trainingName={training.Name}
                        mtop="0px"
                      ></ShareCommonFeed>
                    </TdData> */}
                  </TrData>

                );
              }
            })}
          </tbody>
        </table>
      </ContainerBox >

      {/* <ContainerBox dis="flex" fDir="column" marL="100px" marR="100px" wid="518px">
        <Subtitle className="roboto" marB="20px">Iniciar flujo guiado</Subtitle>
        <div style={{ width: 'auto', height: '150px', overflow: 'auto' }}>
        <table className="table table-borderless table-sm">
            <tbody>
              {activeTrainings.map((training, index) => {
                return (
                  <TrData key={index}>
                    <TdData className="roboto">
                      <a
                        style={{textDecoration: 'none'}}
                        href={`https://www.coru.com${training.TrainingRedirectUrl}`}
                        target="_blank" className="enlaceXcoach" title={training.Name}
                        rel="noreferrer"
                      >
                        <MenuItem
                          key={index}
                          value={training}
                        >
                          {training.TrainingBigTitle}
                        </MenuItem>
                      </a>
                    </TdData>
                  </TrData>
                )
              })}
            </tbody>
        </table>
        </div>
      </ContainerBox > */}

      <ContainerBox wid="429px" marT="40px" marL="100px">
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-filled-label">
            WO activos
          </InputLabel>{' '}
          <Select
            labelId="demo-simple-select-outlined-label"
            name="woActivo"
            value={woActivo}
            onChange={(e) => handleInputChange(e)}
            variant="outlined"
            className={classes.ControlSelect}
            label="WO Activos"
          >
            {activeTrainings.map((e) => (
              <MenuItem key={e.IDCatTraining} value={e}>{e.TrainingBigTitle}</MenuItem>
            ))}
          </Select>
        </FormControl> 
      </ContainerBox >
      <ContainerBox wid="429px" marT="48px">
        <Buttons
          bcK="#3F4D67"
          onClick={goToTraining}
          disabled={!urlWoActivo}
        >
          Ir al entrenamiento
        </Buttons>
      </ContainerBox >
    </ContainerSection>
  );
};

export default TrainingData;
