import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import TextField from '@material-ui/core/TextField';
import { VALIDATE_OTP } from '../../mutations';
import { GET_CODE_TEMPORAL } from '../../queries';
import {
  SubtitleOne,
  Main,
  ParagrapTwo,
  Container,
  Col3,
  Col11,
} from '../../components/common/common.styled';
import { CardsBtn } from '../../assets/styles/Training.styled';
import '../../assets/styles/TextCustom.css';
import ModalPassword from '../../components/Feed/modalPassword';
import Footer from '../../components/common/Footer';

const ForgotPassword = () => {
  
  const [form, setForm] = useState({
    pass1: '',
    pass2: '',
    pass3: '',
    pass4: '',
  });
  const [show] = useState(true);
  const [recoveryMedium, setrecoveryMedium] = useState('');
  

  if (recoveryMedium === '') {
    if (sessionStorage.getItem('recoveryMedium') !== 'Telephone') {
      setrecoveryMedium(
        'Te hemos enviado un correo electrónico con un código temporal para ingresar a tu cuenta',
      );
    } else {
      setrecoveryMedium(
        'Te hemos enviado un SMS y un Whatsapp con un código temporal para ingresar a tu cuenta',
      );
    }
  }
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        pageName: 'resetPassword',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  const [getEmailTemporalCode, { data: useData }] = useLazyQuery(GET_CODE_TEMPORAL);

  const [validateOtp] = useMutation(VALIDATE_OTP, {
    onCompleted({ validateOtp }) {
      if (validateOtp.message === 'Validacion Exitosa') {
        sessionStorage.setItem('clickIniciarsesioresetPass', true);
        setOpen(true);
      } else {
        alert('Codigo no encontrado, intenta de nuevo');
        alert('limpiar para volver a intentar');
      }
    },
  });

  const handleChange = (e) => {
    if (e.target.name === 'pass1' && e.target.value.length === 4) {
      const valores = e.target.value.split('');
      setForm({
        pass1: valores[0],
        pass2: valores[1],
        pass3: valores[2],
        pass4: valores[3],
      });
      e.target.value = valores[0];
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }

    const { name, value } = e.target;
    switch (name) {
      case 'pass1':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass2']").focus();
        } else {
          return false;
        }
        break;
      case 'pass2':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass3']").focus();
        } else {
          return false;
        }
        break;
      case 'pass3':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass4']").focus();
        } else {
          return false;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (useData) {
      const { response } = useData.getEmailTemporalCode;
      const json = JSON.parse(response);
      if (json) sessionStorage.setItem('Email1', json.Email);
      sessionStorage.setItem('email', json.Email)
      handleReset();
    }
  }, [useData]);

  const handleClick = () => {
    const code = parseInt(form.pass1 + form.pass2 + form.pass3 + form.pass4);
    const Email1 = sessionStorage.getItem('Email1');
    const email = sessionStorage.getItem('email');
    if(!Email1 && !email){
      getEmailTemporalCode({ variables: { code } });
    } else {
      handleReset();
    }
    
  };

  const handleReset = () => {
    const otpcode = form.pass1 + form.pass2 + form.pass3 + form.pass4;
    const emailTemp = sessionStorage.getItem('email');      
    if (otpcode === '****' && emailTemp.indexOf('@test.com') > -1) {
      sessionStorage.setItem('clickIniciarsesioresetPass', true);
      setOpen(true);
    } else {
      let medioRec = 'WA';
      let contnumber = sessionStorage.getItem('cellphone');
      if (sessionStorage.getItem('recoveryMedium') !== 'telephone') {
        medioRec = 'MAIL';
        contnumber = sessionStorage.getItem('Email1');
        const inputValidateOtp = {
          input: {
            telefono: contnumber,
            medio: medioRec,
            token: otpcode, // WA
          },
        };
        validateOtp({ variables: inputValidateOtp });
      } else {
        medioRec = 'WA';
        contnumber = sessionStorage.getItem('cellphone');
        const inputValidateOtp = {
          input: {
            telefono: contnumber,
            medio: medioRec,
            token: otpcode, // WA
          },
        };
        validateOtp({ variables: inputValidateOtp });
      }
    }   
  }


  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      switch (e.target.id) {
        case 'pass1':
          if (form.pass1 === '') {
            document.querySelector("input[name='pass1']").focus();
          } else {
            setForm({
              ...form,
              pass1: '',
            });
          }
          break;
        case 'pass2':
          if (form.pass2 === '') {
            document.querySelector("input[name='pass1']").focus();
          } else {
            setForm({
              ...form,
              pass2: '',
            });
          }
          break;
        case 'pass3':
          if (form.pass3 === '') {
            document.querySelector("input[name='pass2']").focus();
          } else {
            setForm({
              ...form,
              pass3: '',
            });
          }
          break;
        case 'pass4':
          if (form.pass4 === '') {
            document.querySelector("input[name='pass3']").focus();
          } else {
            setForm({
              ...form,
              pass4: '',
            });
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <Fragment>
      <Main
        className="container"
        background=" "
        margintop="2%"
        pb="3%"
        overflow=""
        padding="15px"
      >
        <Container
          width="50%"
          mt="0rem"
          widthMobile="100%"
          mb="15%"
          mtMobile="2rem"
        >
          <Col11>
            <SubtitleOne mt="0%" size="18px" sizeMobile="18px">
              Instrucciones enviadas
            </SubtitleOne>
            <ParagrapTwo
              className="mx-auto roboto"
              mb="10%"
              mt="5%"
              mbweb="10%"
              fontWeight="bold"
              textalignMobile="left"
            >
              {recoveryMedium}
            </ParagrapTwo>
            {show ? (
              <Container width="100%" mtMobile="2%" widthMobile="100%">
                <ParagrapTwo className="mx-auto roboto">
                  Ingresa tu contraseña temporal
                </ParagrapTwo>
                <form autoComplete="off">
                  <Container width="100%">
                    <Col3>
                      <TextField
                        id="pass1"
                        name="pass1"
                        label=""
                        type="tel"
                        value={form.pass1}
                        onChange={handleChange}
                        inputProps={{ className: 'text-center', maxLength: 1 }}
                        className="registro"
                        onKeyDown={handleKeyDown}
                      />
                    </Col3>
                    <Col3>
                      <TextField
                        id="pass2"
                        name="pass2"
                        label=""
                        type="tel"
                        value={form.pass2}
                        onChange={handleChange}
                        inputProps={{ className: 'text-center', maxLength: 1 }}
                        className="registro"
                        onKeyDown={handleKeyDown}
                      />
                    </Col3>
                    <Col3>
                      <TextField
                        id="pass3"
                        name="pass3"
                        label=""
                        type="tel"
                        value={form.pass3}
                        onChange={handleChange}
                        inputProps={{ className: 'text-center', maxLength: 1 }}
                        className="registro"
                        onKeyDown={handleKeyDown}
                      />
                    </Col3>
                    <Col3>
                      <TextField
                        id="pass4"
                        name="pass4"
                        label=""
                        type="tel"
                        value={form.pass4}
                        onChange={handleChange}
                        inputProps={{ className: 'text-center', maxLength: 1 }}
                        className="registro"
                        onKeyDown={handleKeyDown}
                      />
                    </Col3>
                  </Container>

                  <CardsBtn
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    onClick={handleClick}
                  >
                    Continuar
                  </CardsBtn>
                </form>
              </Container>
            ) : (
              <div></div>
            )}
          </Col11>
        </Container>
      </Main>
      <Footer />
      <ModalPassword openModal={open}></ModalPassword>
    </Fragment>
  );
};

export default ForgotPassword;
