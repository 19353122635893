import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import escudo from '../../assets/img/anacoach/Training/Comparativo/Escudo.svg';
import master from '../../assets/img/anacoach/Training/Comparativo/TarjetasC.png';
import info2 from '../../assets/img/anacoach/Training/Comparativo/Info2.png';
import info from '../../assets/img/anacoach/Training/Comparativo/Info.png';
import { WEB_SERVICE_CI } from '../../mutations';
import {
  Image,
} from '../common/common.styled';
import {
  CardsBtn,
  DivTable,
  DivRecommended,
  DivColProduct,
  DivInfoProduct,
  DivClickProduct,
  DivTamProduct,
  DivTextProduct,
} from '../../assets/styles/Training.styled';

const ProductoCI = ({
  insurance, cobertura, index, selectInsurance, textButton = 'Continuar', success = false,
}) => {
  const [MoreInfoC, setMoreInfoC] = useState(false);
  const [MinusInfoC, setMinusInfoC] = useState(false);
  const [beneficios, setBeneficios] = useState([]);
  const dataInsurance = {
    ABA: {
      beneficios: ['RC Familiar', 'RC Fallecimiento a Terceros'],
      sa: 'Valor comercial',
      urlImg: 'https://i.coru.mx/static/img/logos-aseg/xABA.jpg.pagespeed.ic.hjIOUnJ1P-.webp',
    },
    ANA: {
      beneficios: ['RC Hijo Menor', 'RC Remolques'],
      sa: 'Valor comercial',
      urlImg: 'https://i.coru.mx/static/img/logos-aseg/xANA.jpg.pagespeed.ic.t_0L9XcY8S.webp',
    },
    HDI: {
      beneficios: ['Protección Mascotas', '3 eventos de grúa al año'],
      sa: 'Valor comercial',
      urlImg: 'https://i.coru.mx/static/img/logos-aseg/xHDI.png.pagespeed.ic.Dof-dbyWHv.webp',
    },
    QUALITAS: {
      beneficios: ['Uso de grúa sin límite', 'RC EUA'],
      sa: 'Valor convenido',
      urlImg: 'https://i.coru.mx/static/img/logos-aseg/xQUALITAS.jpg.pagespeed.ic.rwqV1RpLRS.webp',
    },
    MAPFRE: {
      beneficios: ['Uso de grúa sin límite', 'Extensión de RC'],
      sa: 'Valor comercial',
      urlImg: 'https://i.coru.mx/static/img/logos-aseg/xMAPFRE.jpg.pagespeed.ic.Q2nvxLSnuE.webp',
    },
    GNP: {
      beneficios: ['5 eventos de grúa al año', 'Extensión de RC'],
      sa: 'Valor convenido',
      urlImg: 'https://i.coru.mx/static/img/logos-aseg/xGNP.jpg.pagespeed.ic.CgTOoA90a5.webp',
    },
  };
  const [webServiceCI] = useMutation(WEB_SERVICE_CI, {
    onCompleted({ webServiceCI }) {
      // const tmp_array = [];
      let response = {};
      debugger;
      switch (webServiceCI.message) {
        case 'getCoverage':
          response = JSON.parse(webServiceCI.response);
          setBeneficios(response.Coberturas);
          break;
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const getCoverage = (nameInsurance) => {
    const inputGetModels = {
      input: {
        action: 'getCoverage',
        param: `${nameInsurance}-${cobertura}`,
      },
    };
    webServiceCI({ variables: inputGetModels });
    setMoreInfoC(true);
  };
  useEffect(() => {
    if (MinusInfoC) {
      setMoreInfoC(false);
      setMinusInfoC(false);
    }
  }, [MinusInfoC]);

  // const productoRecomendado = true;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const formatterCost = (price) => {
    if (price !== '' && price != null) {
      return formatter.format(price).concat(' MXN');
    }

    return 'La tarifa no esta disponible por el momento';
  };
  return (
        <div style={{ width: '100%', backgroundColor: '#ffffff' }}>
            {
                index === 0
                  ? <DivRecommended>
                        <b>Recomendación Coru</b>
                    </DivRecommended>
                  : <DivRecommended/>
            }
            <DivColProduct>
                <DivTamProduct wi='45%'>
                    <Image src={dataInsurance[insurance.Aseguradora].urlImg} zindex="1" width='70px' height='auto' position='' marginTop='0' marginLeft='auto' marginTopM='0' marginLeftM='auto' ></Image>
                </DivTamProduct>
                <DivTamProduct wi='55%'>
                    <DivTextProduct fs='16px' fw='normal'>{cobertura}</DivTextProduct>
                    <DivTextProduct>{formatterCost(insurance.Cotizacion.PrimaTotal)}</DivTextProduct>
                    <DivTamProduct wi='100%' ta='right'>
                        <Image src={escudo} zindex="1" width='18px' height='auto' position='' marginTop='0' marginLeft='auto' marginTopM='0' marginLeftM='auto' ></Image>
                    </DivTamProduct>

                </DivTamProduct>
            </DivColProduct>
            {success
              ? <DivColProduct dis=''>
            <div>
            <hr/>
            <p>
            Esta es la opción que te intereso, un asesor se pondrá en contacto cuanto antes contigo para continuar con tu solicitud.
            </p>
            <hr/>
            <p style={{ marginBottom: '0' }}><b>Beneficios:</b></p>
            {dataInsurance[insurance.Aseguradora].beneficios.map(
              (beneficio) => (
                <li>{beneficio}</li>
              ),
            )}
            </div>
            </DivColProduct>
              : <>
            <DivColProduct padd='0 20px'>
                <DivTamProduct>
                    <p style={{ marginBottom: '0' }}>Suma Asegurada:</p>
                    <p style={{ fontWeight: 'bold' }}>{dataInsurance[insurance.Aseguradora].sa}</p>
                    <p style={{ marginBottom: '0' }}>Deducible Daños</p>
                    <p>Materiales: <b>{insurance.Coberturas[0].DanosMateriales}%</b></p>
                    <p>Deducible Robo: <b>{insurance.Coberturas[0].RoboTotal}%</b></p>
                </DivTamProduct>
                <DivTamProduct>
                    <p style={{ marginBottom: '0' }}><b>Beneficios:</b></p>
                    {dataInsurance[insurance.Aseguradora].beneficios.map(
                      (beneficio) => (
                        <li>{beneficio}</li>
                      ),
                    )}
                    <Image src={master} zindex="1" width='87px' height='auto' position='' marginTop='0' marginLeft='auto' marginTopM='0' marginLeftM='auto' ></Image>
                </DivTamProduct>
            </DivColProduct>
            </>}
            <DivColProduct dis=''>
                {insurance.Cotizacion.PrimaTotal !== null && insurance.Cotizacion.PrimaTotal !== '' ? (
                <CardsBtn
                    onClick={() => selectInsurance(insurance)}
                    min_w="100%"
                    min_web="100%"
                    // min_web="100%"
                    mtopw="0"
                    mleft="auto"
                    mleftweb="auto"
                    mleftmd="auto"
                    mleftmini="auto"
                    mtop="0"
                    weight="normal!important"
                >
                    {textButton}
                </CardsBtn>) : null
                }
            </DivColProduct>
            { !MoreInfoC && !success
              ? <DivInfoProduct onClick={() => getCoverage(insurance.Aseguradora)}>
                    <DivClickProduct>
                        Más información
                            <Image src={info} zindex="1" width='15px' height='auto' position='' marginTop='0' marginLeft='10px' marginTopM='0' marginLeftM='10px' ></Image>
                    </DivClickProduct>
                </DivInfoProduct>
              : null
            }
            { MoreInfoC && !MinusInfoC
              ? <div>
                    <table style={{ fontSize: '11px' }}>
                        <tr>
                            <th style={{ width: '42%', padding: '6px' }}>Nombre Cobertura</th>
                            <th style={{ padding: '6px' }}>Suma Asegurada</th>
                            <th style={{ padding: '6px' }}>Deducible</th>
                        </tr>
                        {beneficios.map((beneficio) => (
                            <tr>
                                <DivTable>{beneficio.NombreCobertura}</DivTable>
                                <DivTable>{beneficio.SumaAsegurada}</DivTable>
                                <DivTable>{beneficio.Deducible !== '' ? beneficio.Deducible : '-'}</DivTable>
                            </tr>
                        ))}
                    </table>
                </div>
              : null
            }
            { MoreInfoC && !MinusInfoC
              ? <DivInfoProduct onClick={() => setMinusInfoC(true)}>
                    <DivClickProduct>
                        Menos información
                        <Image src={info2} zindex="1" width='15px' height='auto' position='' marginTop='0' marginLeft='10px' marginTopM='0' marginLeftM='10px' ></Image>
                    </DivClickProduct>
                </DivInfoProduct>
              : null
            }
        </div>
  );
};

export default ProductoCI;
