import React, { Fragment, useEffect } from 'react';
// helpers
import { useQuery } from '@apollo/client';
import CardOne from '../Home/Card1Animate';
// ApolloClient
import { GET_ROUTINES_BY_ID_TRAINING } from '../../queries';
import '../../assets/styles/Home.css';
import LoadingWo from '../Training/loadingWo';
import ImageCoachInicio from '../Training/ImageCoachInicio';
import { Container, Col7 } from './common.styled';

const InputsOrder = ({
  orderRutine,
  step,
  handleChangeRutine,
  currentTraining,
  textBold,
  text2,
  hora,
  classCard,
}) => {
  const { data, loading, error } = useQuery(GET_ROUTINES_BY_ID_TRAINING, {
    variables: {
      idTraining:
        parseInt(sessionStorage.getItem('idTraining')) || currentTraining,
      orderRutine,
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      let { getRoutinesByIdTraining } = data;
      getRoutinesByIdTraining = JSON.parse(getRoutinesByIdTraining);
      getRoutinesByIdTraining = Object.values(getRoutinesByIdTraining);
      const newData = getRoutinesByIdTraining[0];
      handleChangeRutine(newData);
    }
  }, [data]);
  const anchop = window.screen.width;

  if (loading) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  if (error) return <h2>Hubo un error</h2>;
  let { getRoutinesByIdTraining } = data;
  getRoutinesByIdTraining = JSON.parse(getRoutinesByIdTraining);
  getRoutinesByIdTraining = Object.values(getRoutinesByIdTraining);
  if (step === undefined) return;

  return (
    <Fragment>
      {getRoutinesByIdTraining[0].questions.map((routine, index) => {
        const questionName = routine.QuestionName;
        if (step === index) {
          return (
            <Fragment key={index}>
              <CardOne
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                classCard={classCard}
                classTop="topOne1"
                textbold={textBold}
                text={questionName}
                text2={text2}
                hora={hora}
              ></CardOne>
            </Fragment>
          );
        }
      })}
    </Fragment>
  );
};

export default InputsOrder;
