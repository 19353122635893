import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckBoxItem } from './styles/GloveBox.styled';
import Mark from './img/anacoach/checkmark.svg';

const CheckMark = ({ checked }) => {
  const [isChecked, setChecked] = useState(checked);

  const handleClick = () => {
    setChecked(!isChecked);
  };

  return (

    <CheckBoxItem onClick={() => handleClick()} {...isChecked ? 'checked' : null} >
      {isChecked ? <img src={Mark} alt='mark' /> : null}
    </CheckBoxItem>
  );
};
CheckMark.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default CheckMark;
