import React from 'react';
import Carousel from 'react-elastic-carousel';
import { useHistory } from 'react-router-dom';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import WOlayout from '../../components/layouts/WOlayout';
import {
  Container1,
  ContainerBottons,
  ContentHomeSection,
  Shapes,
  Rectangle23,
  Vector1,
  Title1,
  Subtitle,
  StoriCardContainer,
  StoriCard,
  CardTarjetasOpcionesCardInformation,
  CardTarjetasOpcionesImagen,
  CardTarjetasOpcionesAnualidadContainer,
  CardTarjetasOpcionesList,
  ContainerBeneficios,
  ContainerMiniCard,
  StoriCardContainerCarousel,
  ContainerText,
  ContainerText2,
} from '../../components/common/Home.styled';
import {
  Container,
  Button,
  Image,
} from '../../components/common/common.styled';
import StoriCardImage from '../../assets/img/anacoach/StoriCard.svg';
import StoriCardMini from '../../assets/img/anacoach/StoriCardMini.svg';
import iconoTarjetaAprov from '../../assets/img/anacoach/iconoTarjetaAprov.png';
import List from '../../assets/img/anacoach/ListStori.svg';
import StoriProduct from '../../assets/img/anacoach/StoriProduct.svg';
import Phone from '../../assets/img/anacoach/PhoneStori.svg';
import { Helmet } from 'react-helmet';

const LpStori = () => {
  const text = [
    { text: 'No pagas anualidad.' },
    { text: 'Mejoras o creas tu historial crediticio en Buró de Crédito.' },
    { text: 'Bonificación en compras seleccionadas.' },
    { text: 'Sin comprobante de ingresos.' },
    { text: 'Chip inclonable y firma con NIP.' },
    { text: 'Aceptada en todo el mundo con el respaldo de Mastercard.' },
  ];
  const history = useHistory();
  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Obtén tu tarjeta de crédito Stori | CORU</title>
      </Helmet>
      <Container1>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="2855px"
          height="3999px"
          width="100%"
          position=" "
          heightD480="1800px"
          heightD320="1800px"
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            {/* Secci+on 1 */}
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Image
                src={StoriCardImage}
                maxwidth="430px"
                width="19%"
                position="absolute"
                left="60%"
                top="8%"
                displayMobile="none"
              />
              <Rectangle23
                Ptop="100px"
                Pleft="60%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#BFDD6A"
                transform="rotate(-35.58deg)"
              />
              <Rectangle23
                Ptop="180px"
                Pleft="97%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#91D770"
                transform="rotate(-35.58deg)"
                width="105px"
                height="105px"
              />
              <Rectangle23
                Ptop="590px"
                Pleft="96%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#BFDD6A"
                transform="rotate(-35.58deg)"
                width="155px"
                height="155px"
              />
              <Rectangle23
                Ptop="600px"
                Pleft="47%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#6BD5AF"
                transform="rotate(-45deg)"
                width="55px"
                height="55px"
              />
              <Vector1
                positionD="absolute"
                top="22%"
                left="30%"
                width="68px"
                height="68px"
                background="#91D770"
                displayM="none"
              />
              <Vector1
                positionD="absolute"
                top="42%"
                left="-10.8%"
                width="58px"
                height="58px"
                background="#6BD5AF"
                transform="rotate(-57.54deg)"
                displayM="none"
              />
              <Vector1
                positionD="absolute"
                top="55%"
                left="-10.8%"
                width="58px"
                height="58px"
                background="#91D770"
                transform="rotate(45deg)"
                displayM="none"
              />
              <Rectangle23
                Ptop="34%"
                Pleft="60%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#BFDD6A"
                transform="rotate(40deg)"
                radius="30px"
                height="55px"
              />
              <Rectangle23
                Ptop="40%"
                Pleft="4%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#E3E660"
                transform="rotate(40deg)"
                radius="30px"
                height="55px"
              />
              <Rectangle23
                Ptop="40%"
                Pleft="96%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#BFDD6A"
                transform="rotate(-35.58deg)"
                width="155px"
                height="155px"
              />
              <Rectangle23
                Ptop="45%"
                Pleft="-3%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#BFDD6A"
                transform="rotate(45deg)"
                width="110px"
                height="110px"
              />
              <Vector1
                positionD="absolute"
                top="74.8%"
                left="80%"
                width="356px"
                height="356px"
                background="#6BD5AF"
                transform="rotate(-90deg)"
                displayM="none"
              />
              {/* cudrado sobre Boton */}
              <Rectangle23
                Ptop="65%"
                Pleft="40%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#BFDD6A"
                transform="rotate(-45deg)"
                width="55px"
                height="55px"
              />
              <Rectangle23
                Ptop="64%"
                Pleft="73%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#91D770"
                transform="rotate(-45deg)"
                width="55px"
                height="55px"
              />
              <Rectangle23
                Ptop="84%"
                Pleft="3%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#E3E660"
                transform="rotate(-45deg)"
                width="55px"
                height="55px"
              />
              <Rectangle23
                Ptop="89%"
                Pleft="20%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#E3E660"
                transform="rotate(-45deg)"
                width="45px"
                height="45px"
              />
              <Rectangle23
                Ptop="78%"
                Pleft="43%"
                position="absolute"
                displayD=" "
                displayM="none"
                background="#91D770"
                transform="rotate(-30deg)"
                width="55px"
                height="55px"
              />
              <Vector1
                positionD="absolute"
                top="97.8%"
                left="-470px"
                width="356px"
                height="356px"
                background="#E3E660"
                transform="rotate(-153.21deg)"
                displayM="none"
              />
              <Title1
                id="Title1"
                mtopM="80px"
                className="roboto cabin"
                mt="250px"
                size="60px"
              >
                ¡Obtén tu tarjeta Stori <br />
                garantizada!
              </Title1>
              <ContainerMiniCard src={StoriCardMini} />
              <Subtitle
                id="Subtitle"
                className="roboto"
                mtopM="10%"
                mbottomM="3%"
                mleftMm320="21px"
                bottomMm320="0px"
              >
                Sin anualidad y sin historial crediticio.
              </Subtitle>
              <Subtitle
                id="Subtitle"
                className="roboto"
                mtopM="10%"
                mbottomM="5%"
                mleftMm320="21px"
                alingMm320="justify"
                alingMm640="justify"
                widthMm640="300px"
                sizeM640="15px"
                widthMm320="280px"
                bottomMm320="35px"
                bottomMm640="35px"
              >
                Sólo necesitas descargar la app, completar la información para
                solicitar tu tarjeta con un 100% de aprobación.
              </Subtitle>

              <ContainerBottons
                widthD="420px"
                leftmd="20px"
                margin=" "
                position=" "
                mleftMm320="15px"
                mleftMm360="18px"
              >
                <Button
                  className="roboto"
                  onClick={() => history.push(
                    'https://www.storicard.com/?utm_source=Coru&utm_campaign=stapp',
                  )
                  }
                >
                  Descarga la app
                </Button>
              </ContainerBottons>
            </ContentHomeSection>
            {/* Sección 2 */}
            <ContentHomeSection
              leftD=" "
              floatD=" "
              width="100%"
              topD="200px"
              topDW="120px"
              topMm320="40px"
            >
              <Subtitle
                id="Subtitle"
                className="roboto"
                aling="center"
                weight="bold"
                size="26px"
                width=" "
                weightM="bold"
                mleftMm320="10px"
                widthMm320="300px"
                mleftMm360="8px"
              >
                ¿Cómo obtengo la tarjeta Stori?
              </Subtitle>
              <div className="row mt-4 justify-content-between text-center">
                <div className="col-md-3">
                  <Image src={Phone} maxwidth="64px" />
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Descarga la app
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="18px"
                    widthMm320="280px"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Regístrate en Stori desde tu celular al instante
                  </Subtitle>
                </div>
                <div className="col-md-3">
                  <Image src={List} maxwidth="64px" />
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Llena el formulario
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mleftMm320="20px"
                    widthMm320="280px"
                    alingMm320="justify"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Necesitamos algunos datos para conocerte y puedas tramitar
                    tu tarjeta
                  </Subtitle>
                </div>
                <div className="col-md-3">
                  <Image src={StoriCardMini} maxwidth="64px" />
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    weight="bold"
                    size="18px"
                    width=" "
                    mtopM=" "
                    weightM=" "
                    mleftMm320="10px"
                    widthMm320="300px"
                    mleftMm360="10px"
                  >
                    Obtén tu tarjeta
                  </Subtitle>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop=" "
                    mleftMm320="20px"
                    widthMm320="280px"
                    alingMm320="justify"
                    widthMm360="300px"
                    alingMm360="justify"
                    mleftMm360="22px"
                  >
                    Recibe tu tarjeta garantizada en tu domicilio completamente
                    gratis
                  </Subtitle>
                </div>
              </div>
            </ContentHomeSection>
            {/* Sección 3 */}
            <ContentHomeSection
              leftD=" "
              floatD=" "
              width="100%"
              topD="100px"
              topDW="120px"
              topMm320="40px"
            >
              <Subtitle
                id="Subtitle"
                className="roboto"
                aling="center"
                weight="bold"
                size="26px"
                width=" "
                weightM="bold"
                mleftMm320="20px"
                widthMm320="300px"
                widthMm360="350px"
                alingMm360="justify"
                mleftMm360="22px"
              >
                Conoce las tarjetas que Stori tiene para ti:
              </Subtitle>

              <StoriCardContainer>
                <StoriCard mWidth="363px" height="485px">
                  <CardTarjetasOpcionesCardInformation>
                    <CardTarjetasOpcionesImagen
                      src={StoriCardMini}
                      transform="rotate(-30deg)"
                    />
                    <CardTarjetasOpcionesAnualidadContainer>
                      <span style={{ color: 'black', fontSize: '12px' }}>
                        Anualidad
                      </span>
                      <span
                        className="roboto"
                        style={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: 'black',
                        }}
                      >
                        $0 MXN
                      </span>
                    </CardTarjetasOpcionesAnualidadContainer>
                  </CardTarjetasOpcionesCardInformation>
                  <h5
                    className="roboto"
                    style={{ marginTop: '20px', fontWeight: '700' }}
                  >
                    Stori Clásica - MasterCard
                  </h5>
                  <h6 style={{ fontWeight: '700' }}>Ten el control de nuevo</h6>
                  <CardTarjetasOpcionesList mtop="20px" height="45%">
                    <li>5% de cashback en compras seleccionadas</li>
                    <li>Sin comisión por contratación</li>
                    <li>Tienes el respaldo internacional Mastercard</li>
                    <li>
                      Reporta a buró de crédito beneficiando tu historial
                      crediticio
                    </li>
                    <li style={{ fontWeight: 'bold' }}>
                      Tarjeta con 100% de aprobación garantizada.{' '}
                      <Image src={iconoTarjetaAprov} maxwidth="17px" />
                    </li>
                  </CardTarjetasOpcionesList>
                  <div style={{ marginTop: '20px' }}>
                    Para conocer más acerca de esta tarjeta y sus beneficios{' '}
                    <a
                      style={{ color: '#F64282', cursor: 'pointer' }}
                      href="https://www.storicard.com/tarjeta-stori-clasica"
                      target="_blank" rel="noreferrer"
                    >
                      haz clic aquí
                    </a>
                  </div>
                </StoriCard>
                <StoriCard mWidth="363px" height="485px">
                  <CardTarjetasOpcionesCardInformation>
                    <CardTarjetasOpcionesImagen
                      src={StoriCardMini}
                      transform="rotate(-30deg)"
                    />
                    <CardTarjetasOpcionesAnualidadContainer>
                      <span style={{ color: 'black', fontSize: '12px' }}>
                        Anualidad
                      </span>
                      <span
                        className="roboto"
                        style={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: 'black',
                        }}
                      >
                        $0 MXN
                      </span>
                    </CardTarjetasOpcionesAnualidadContainer>
                  </CardTarjetasOpcionesCardInformation>
                  <h5
                    className="roboto"
                    style={{ marginTop: '20px', fontWeight: '700' }}
                  >
                    Stori Construye - MasterCard
                  </h5>
                  <h6 style={{ fontWeight: '700' }}>
                    Para crecer tu crédito rápidamente
                  </h6>
                  <CardTarjetasOpcionesList mtop="20px" height="45%">
                    <li>5% de cashback en compras seleccionadas</li>
                    <li>Comisión por contratación hasta $500</li>
                    <li>Tienes el respaldo internacional Mastercard</li>
                    <li>CAT: 99.9%</li>
                    <li style={{ fontWeight: 'bold' }}>
                      Para tu aprobación solo necesitas descargar la app y
                      llenar la información que solicitamos.
                    </li>
                  </CardTarjetasOpcionesList>
                  <div style={{ marginTop: '20px' }}>
                    Para conocer más acerca de esta tarjeta y sus beneficios{' '}
                    <a
                      style={{ color: '#F64282', cursor: 'pointer' }}
                      href="https://www.storicard.com/tarjeta-stori-construye"
                      target="_blank" rel="noreferrer"
                    >
                      haz clic aquí
                    </a>
                  </div>
                </StoriCard>
              </StoriCardContainer>

              <StoriCardContainerCarousel>
                <Carousel transitionMs={300}>
                  <StoriCard mWidth="363px" height="485px">
                    <CardTarjetasOpcionesCardInformation>
                      <CardTarjetasOpcionesImagen
                        src={StoriCardMini}
                        transform="rotate(-30deg)"
                      />
                      <CardTarjetasOpcionesAnualidadContainer>
                        <span style={{ color: 'black', fontSize: '12px' }}>
                          Anualidad
                        </span>
                        <span
                          className="roboto"
                          style={{
                            fontSize: '18px',
                            fontWeight: '700',
                            color: 'black',
                          }}
                        >
                          $0 MXN
                        </span>
                      </CardTarjetasOpcionesAnualidadContainer>
                    </CardTarjetasOpcionesCardInformation>
                    <h5
                      className="roboto"
                      style={{ marginTop: '20px', fontWeight: '700' }}
                    >
                      Stori Clásica - MasterCard
                    </h5>
                    <h6 style={{ fontWeight: '700' }}>
                      Ten el control de nuevo
                    </h6>
                    <CardTarjetasOpcionesList mtop="20px" height="45%">
                      <li>5% de cashback en compras seleccionadas</li>
                      <li>Sin comisión por contratación</li>
                      <li>Tienes el respaldo internacional Mastercard</li>
                      <li>
                        Reporta a buró de crédito beneficiando tu historial
                        crediticio
                      </li>
                      <li style={{ fontWeight: 'bold' }}>
                        Tarjeta con 100% de aprobación garantizada.{' '}
                        <Image src={iconoTarjetaAprov} maxwidth="17px" />
                      </li>
                    </CardTarjetasOpcionesList>
                    <ContainerText>
                      Para conocer más acerca de esta tarjeta y sus beneficios{' '}
                      <a
                        style={{ color: '#F64282', cursor: 'pointer' }}
                        href="https://www.storicard.com/tarjeta-stori-clasica"
                        target="_blank" rel="noreferrer"
                      >
                        haz clic aquí
                      </a>
                    </ContainerText>
                  </StoriCard>

                  <StoriCard mWidth="363px" height="485px">
                    <CardTarjetasOpcionesCardInformation>
                      <CardTarjetasOpcionesImagen
                        src={StoriCardMini}
                        transform="rotate(-30deg)"
                      />
                      <CardTarjetasOpcionesAnualidadContainer>
                        <span style={{ color: 'black', fontSize: '12px' }}>
                          Anualidad
                        </span>
                        <span
                          className="roboto"
                          style={{
                            fontSize: '18px',
                            fontWeight: '700',
                            color: 'black',
                          }}
                        >
                          $0 MXN
                        </span>
                      </CardTarjetasOpcionesAnualidadContainer>
                    </CardTarjetasOpcionesCardInformation>
                    <h5
                      className="roboto"
                      style={{ marginTop: '20px', fontWeight: '700' }}
                    >
                      Stori Construye - MasterCard
                    </h5>
                    <h6 style={{ fontWeight: '700' }}>
                      Para crecer tu crédito rápidamente
                    </h6>
                    <CardTarjetasOpcionesList mtop="20px" height="45%">
                      <li>5% de cashback en compras seleccionadas</li>
                      <li>Comisión por contratación hasta $500</li>
                      <li>Tienes el respaldo internacional Mastercard</li>
                      <li>CAT: 99.9%</li>
                      <li style={{ fontWeight: 'bold' }}>
                        Para tu aprobación solo necesitas descargar la app y
                        llenar la información que solicitamos.
                      </li>
                    </CardTarjetasOpcionesList>
                    <ContainerText2>
                      Para conocer más acerca de esta tarjeta y sus beneficios{' '}
                      <a
                        style={{ color: '#F64282', cursor: 'pointer' }}
                        href="https://www.storicard.com/tarjeta-stori-construye"
                        target="_blank" rel="noreferrer"
                      >
                        haz clic aquí
                      </a>
                    </ContainerText2>
                  </StoriCard>
                </Carousel>
              </StoriCardContainerCarousel>

              <Subtitle
                id="Subtitle"
                className="roboto"
                mtopM="10%"
                mbottomM="5%"
                width=" "
                aling="center"
                mleftMm320="18px"
                widthMm320="250px"
                alingMm320="justify"
                widthMm360="300px"
                alingMm360="justify"
                mleftMm360="28px"
              >
                ¡Aplica a la tarjeta que más se acomode a tus necesidades!
              </Subtitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <ContainerBottons
                  widthD="420px"
                  leftmd="20px"
                  margin=" "
                  position=" "
                  mleftMm320="4px"
                  widthMm320="300px"
                  mleftMm360="4px"
                >
                  <Button
                    className="roboto"
                    onClick={() => history.push(
                      'https://www.storicard.com/?utm_source=Coru&utm_campaign=stapp',
                    )
                    }
                  >
                    Solicita tu tarjeta
                  </Button>
                </ContainerBottons>
              </div>
            </ContentHomeSection>
            {/* Sección 4 */}
            <ContentHomeSection
              leftD=" "
              floatD=" "
              width="100%"
              topD="220px"
              topDW="120px"
              heighMn320="1000px"
              topMm320="40px"
              heighMn425="1000px"
            >
              <div className="row">
                <div className="col-md-6 text-center">
                  <Image src={StoriProduct} maxwidth="571px" />
                </div>
                <div className="col-md-6">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="5%"
                    weight="bold"
                    weightM="bold"
                    width=" "
                    size="26px"
                    mleftMm320="14px"
                  >
                    Beneficios de las tarjetas Stori
                  </Subtitle>
                  <ContainerBeneficios>
                    {text.map((t, i) => (
                        <div
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <CheckBoxIcon style={{ color: '#91D770' }} />
                          <p
                            style={{
                              color: '#000',
                              marginLeft: '10px',
                              textAling: 'justify',
                            }}
                          >
                            {t.text}
                          </p>
                        </div>
                    ))}
                  </ContainerBeneficios>
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    aling="center"
                    size="16px"
                    width=" "
                    marginTop="50px"
                    mtopM=" "
                    weight="bold"
                    weightM="bold"
                    mleftMm320="6px"
                    widthMm320="300px"
                    mleftMm360="4px"
                  >
                    ¡Obtén tu tarjeta hoy mismo!
                  </Subtitle>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <ContainerBottons
                      widthD="420px"
                      leftmd="0px"
                      margin=" "
                      position=" "
                    >
                      <Button
                        className="roboto"
                        onClick={() => history.push(
                          'https://www.storicard.com/descarga?utm_source=Coru&utm_campaign=stapp',
                        )
                        }
                      >
                        Descarga la app
                      </Button>
                    </ContainerBottons>
                  </div>
                </div>
              </div>
            </ContentHomeSection>
          </Container>
        </Shapes>
      </Container1>
    </WOlayout>
  );
};

export default LpStori;
