import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
// Modal
import Modal from '@material-ui/core/Modal';
import { Container, Button, ParagrapTwo } from '../common/common.styled';

import { Title2 } from '../common/Home.styled';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function ModalTestViralidad({ open, setOpen }) {
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();

  const handleClick = () => {
    history.push('/wo/virality-test');
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className="modalTestInversion">
          <ParagrapTwo
            fontWeight="700"
            cursor="pointer"
            texttalign="right"
            textalignMobile="right"
            className="mx-auto"
            onClick={handleClose}
          >
            X
          </ParagrapTwo>
          <Title2
            className="roboto"
            sizeD="30px"
            sizem="25px"
            aling="center"
            topD="0px"
            top="0px"
            mleftm="0px"
          >
            Es viernes, día de <br /> recompensa.
          </Title2>
          <ParagrapTwo
            className="mx-auto"
            mb="0px"
            textalignMobile="left"
            texttalign="left"
          >
            Responde acertadamente nuestro quiz semanal para obtener una tarjeta
            de regalo
          </ParagrapTwo>
          <Container width="220px" mt="0px">
            <Button className="d-block roboto" onClick={handleClick}>
              Responder
            </Button>
          </Container>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalTestViralidad;
