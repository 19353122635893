/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingText,
  CardsBtn,
  TrainingDivSection,
  ErrorDiv,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, getQueryVariableHashtagDeeplink } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ClickButton from '../../Helpers/HookHelpers';

const BirthDat = ({
  date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  mainDataRoutine = 0,
  trainingColor,
  recoveryTimeout = false,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [iduser] = useState(sessionStorage.getItem('idUser') || '');
  const [idcont] = useState(sessionStorage.getItem('idCont') || '');
  const [overwrite] = useState(sessionStorage.getItem('overwrite_WO_29') || 'true');
  const [recoveryDatosPrincipales, setRecoveryDatosPrincipales] = useState('');
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      let stopRecovery = false;
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryDatosPrincipales('inactiva');
        } else {
          const routines = json;
          const contResponse = 0;
          let stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  if (parseInt(idTypeQuestion) === 78) {
                    if (responseUser !== null && responseUser !== 'null') {
                      let date = responseUser.substr(0, 10);

                      date += ''.split('-')[0];
                      sessionStorage.setItem('year', date.split('-')[0]);
                      sessionStorage.setItem('month', date.split('-')[1]);
                      sessionStorage.setItem('day', date.split('-')[2]);
                      form.day = date.split('-')[2];
                      form.month = date.split('-')[1];
                      form.year = date.split('-')[0];

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        date,
                      );
                    } else {
                      stopRoutine = true;
                      // break;
                    }
                  } else {
                    sessionStorage.setItem(
                      arraySessionsRutines[idTypeQuestion],
                      responseUser,
                    );
                  }
                }
              } else if (parseInt(idTypeQuestion) === 78) {
                stopRoutine = true;
                // break;
              }
              if (mainDataRoutine === 1 && i === 6) {
                stopRecovery = true;
                setRecoveryDatosPrincipales('activa');
                setQuestions([]);
                break;
              }
            }
            if (stopRecovery) {
              break;
            }

            if (
              arrayResponseRutine.length
                < routines[idRoutine].questions.length
              && !stopRoutine
            ) {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      iduser !== '' &&
      idcont !== '' &&
      overwrite !== 'true'
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryDatosPrincipales('inactiva');
    }

    const {
      idUser,
      idCont,
    } = getQueryVariableHashtagDeeplink('profile');
    if (idUser && idCont) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(idUser),
          idCont: String(idCont),
        },
      });
    }
  }, []);

  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const [completeBirthDate, setCompleteBirthDate] = useState(
    sessionStorage.getItem('dataPersonBirth') || '',
  );
  const [alertaDatosP, setAlertaDP] = useState(false);
  const [alertaFecha, setAlertaFecha] = useState(false);
  const [clickBtnDate, setClickBtnDate] = useState(false);
  const [isData] = useState(sessionStorage.getItem('DataPerson') === 'true');
  const [form, setForm] = useState({
    day: sessionStorage.getItem('day') || date.day,
    month: sessionStorage.getItem('month') || date.month,
    year: sessionStorage.getItem('year') || date.year,
  });

  const handleChange = (e, question = '') => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'day':
        try {
          document.getElementById('day').className = 'textInput inputs-bottom validSelect';
        } catch (error) {
          document.querySelectorAll('#day')[1].className = 'textInput inputs-bottom validSelect';
        }

        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'month':
        try {
          document.getElementById('month').className = 'textInput inputs-bottom validSelect';
        } catch (error) {
          document.querySelectorAll('#month')[1].className = 'textInput inputs-bottom validSelect';
        }

        handleChangeAge(e.target.name, e.target.value);
        break;
      case 'year':
        try {
          document.getElementById('year').className = 'textInput inputs-bottom validSelect';
        } catch (error) {
          document.querySelectorAll('#year')[1].className = 'textInput inputs-bottom validSelect';
        }

        handleChangeAge(e.target.name, e.target.value);
        break;
      default:
        break;
    }
  };
  const handleChangeAge = (name, value) => {
    let nacimiento = `${form.year}-${form.month}-${form.day}`;
    switch (name) {
      case 'year':
        nacimiento = `${value}-${form.month}-${form.day}`;
        break;
      case 'mont':
        nacimiento = `${form.year}-${value}-${form.day}`;
        break;
      case 'day':
        nacimiento = `${form.year}-${form.month}-${value}`;
        break;
      default:
        nacimiento = `${form.year}-${form.month}-${form.day}`;
        break;
    }
  };
  const [error] = useState({
    day: false,
    month: false,
    year: false,
  });
  const [texterror] = useState({
    day: '',
    month: '',
    year: '',
  });
  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    if (recoveryDatosPrincipales === 'activa') {
      const day = sessionStorage.getItem('day') || '';
      const month = sessionStorage.getItem('month') || '';
      const year = sessionStorage.getItem('year') || '';
      setForm(
        Object.assign(form, {
          day: sessionStorage.getItem('day') || date.day,
          month: sessionStorage.getItem('month') || date.month,
          year: sessionStorage.getItem('year') || date.year,
        }),
      );

      if (day !== '' && month !== '' && year !== '' && !recoveryTimeout) {
        setCompleteBirthDate('Envio Datos');
        if (orderRutine < nextOrderRoutine) {
          setOrderRutine(nextOrderRoutine);
        }
        sessionStorage.setItem('dataPersonBirth', 'Envio Datos');
        setClickBtnDate(true);
      }
    }
  }, [recoveryDatosPrincipales]);

  useEffect(() => {
    if (orderRutine === currentRoutine) {
      if (form.year !== '' && form.month !== '' && form.day !== '-') {
        handleChangeQuestionsSend(
          1,
          'dateBurn',
          `${form.year}-${form.month}-${form.day}`,
        );
        sessionStorage.setItem('year', form.year);
        sessionStorage.setItem('month', form.month);
        sessionStorage.setItem('day', form.day);
        sessionStorage.setItem('Birthdate', `${form.year}-${form.month}-${form.day}`);
      }
      if (form.day !== '' && form.month !== '' && form.year !== '') {
        let age = getAge(`${form.year}-${form.month}-${form.day}`);
        if (age >= 18 && age <= 30) {
          age = 614;
          sessionStorage.setItem('auxIdCatQuestionDetail', 607);
        } else if (age >= 31 && age <= 45) {
          age = 615;
          sessionStorage.setItem('auxIdCatQuestionDetail', 608);
        } else if (age >= 46 && age <= 60) {
          age = 616;
          sessionStorage.setItem('auxIdCatQuestionDetail', 609);
        } else if (age >= 61) {
          age = 617;
          sessionStorage.setItem('auxIdCatQuestionDetail', 610);
        }
      }
    }
  }, [form, completeBirthDate]);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = () => {

  };

  const handleChangeDatosPersonales = (e) => {
    e.preventDefault();
    if (form.day !== '' && form.month !== '' && form.year !== '') {
      let age = getAge(`${form.year}-${form.month}-${form.day}`);
      if (age >= 18 && age <= 30) {
        age = 614;
        sessionStorage.setItem('auxIdCatQuestionDetail', 607);
      } else if (age >= 31 && age <= 45) {
        age = 615;
        sessionStorage.setItem('auxIdCatQuestionDetail', 608);
      } else if (age >= 46 && age <= 60) {
        age = 616;
        sessionStorage.setItem('auxIdCatQuestionDetail', 609);
      } else if (age >= 61) {
        age = 617;
        sessionStorage.setItem('auxIdCatQuestionDetail', 610);
      }
      questions.push({
        id: 'age',
        question: 2,
        value: age,
      });
      if (
        sessionStorage.getItem('idTraining') === '23'
        || sessionStorage.getItem('idTraining') === '34'
        || sessionStorage.getItem('idTraining') === '26'
        || sessionStorage.getItem('idTraining') === '36'
        || sessionStorage.getItem('idTraining') === '35'
        || sessionStorage.getItem('idTraining') === '42'
      ) {
        sessionStorage.setItem('Rutine_BirthDat', JSON.stringify(Rutine));
        sessionStorage.setItem('questions_BirthDat', JSON.stringify(questions));
        setQuestions([]);
      } else {
        createJsonInput(Rutine, questions);
        setQuestions([]);
      }
      setCompleteBirthDate('Envio Datos');
      if (orderRutine < nextOrderRoutine) {
        setOrderRutine(nextOrderRoutine);
      }
      sessionStorage.setItem('dataPersonBirth', 'Envio Datos');
      setClickBtnDate(true);
      if (sessionStorage.getItem('idTraining') === '35') {
        const clickEventCC = `FechaDeNacimiento_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
        actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
      }
      const click = `ContinuarFechaDeNacimiento_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    } else if (form.day === '' || form.month === '' || form.year === '') {
      setAlertaFecha(true);
      setAlertaDP(false);
    } else {
      setAlertaFecha(false);
      setAlertaDP(true);
    }
  };

  const [seccionBirthDate, setSeccionBirthDate] = useState(false);
  const handleChangeButton = () => {
    setOrderRutine(2);
    setSeccionBirthDate(false);
  };

  const scrollToElem = (id) => {
    switch (id) {
      case 'fecha':
        // scroller(950, 1200);
        break;
      default:
      // scroller(950, 1200);
    }
  };

  const anchop = window.screen.width;
  useEffect(() => {
    if (isData) {
      if (orderRutine < nextOrderRoutine) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, []);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (completeBirthDate === ''
          || (orderRutine === currentRoutine && !seccionBirthDate)
          || !clickBtnDate)
      ) {
        gsap.to(window, { duration: 1, scrollTo: '#ActivoBirthDat' });
        setReviewScroll(false);
      }
    }, 1500);
  }, []);

  if (
    recoveryDatosPrincipales === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
        <ImageCoachInicio />
        <Col7 padding=" " className="cardPrincipal1">
          <LoadingWo />
        </Col7>
      </Container>
    );
  }
  return !isData ? (
    <Fragment>
      <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
        <ImageCoachInicio
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          topMobile="35px"
        />
        <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
          <TrainingDivSection
            hew="auto"
            className="topOne1"
            paddw="26px"
            mlw="0%"
            mtw="8%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="100%"
            he="auto"
          >
            <TrainingText
              className={
                completeBirthDate !== ''
                && (seccionBirthDate || orderRutine !== currentRoutine)
                  ? 'cabin'
                  : 'hidden'
              }
              bot="0px"
              align="left"
              weight="600"
              size="20px"
              sizew="20px"
            >
              ¿Cuál es tu fecha de nacimiento?
            </TrainingText>
            <form>
              {completeBirthDate === ''
              || (orderRutine === currentRoutine && !seccionBirthDate)
              || !clickBtnDate ? (
                <Fragment>
                  <InputsOrder
                    handleChangeQuestionsSend={handleChangeQuestionsSend}
                    handleChangeRutine={handleChangeRutine}
                    orderRutine={currentRoutine}
                    currentTraining={currentTraining}
                    step="0-0"
                    handleChangeCatOption={handleChangeCatOption}
                    form={form}
                    handleChange={handleChange}
                    inputError={error}
                    texterror={texterror}
                    date={true}
                  />
                  <ErrorDiv className={alertaFecha === true ? '' : 'hidden'}>
                    Parece que no has llenado tu fecha de nacimiento
                  </ErrorDiv>
                  <ErrorDiv className={alertaDatosP === true ? '' : 'hidden'}>
                    Parece que te falto llenar algún campo
                  </ErrorDiv>
                  <CardsBtn
                    id="btnContinuarDisclaimers"
                    type="button"
                    min_w="100%"
                    min_web="100%"
                    mtopw="10px"
                    onClick={(e) => handleChangeDatosPersonales(e)}
                  >
                    Continuar
                  </CardsBtn>
                </Fragment>
                ) : null}
            </form>
          </TrainingDivSection>
        </Col7>
      </Container>
      <div id="ActiveContactInfo"></div>
      <CardTwo
        classCard={
          completeBirthDate !== ''
          && (seccionBirthDate || orderRutine !== currentRoutine)
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        colorTraining={trainingColor}
        text={`${form.day}/${form.month}/${form.year}`}
      >
        <img
          alt="editIcon"
          className={'iconEditCommon'}
          src={editIcon}
          onClick={() => handleChangeButton()}
        />
      </CardTwo>
      {!seccionBirthDate ? scrollToElem('fecha') : null}
    </Fragment>
  ) : null;
};

export default BirthDat;
