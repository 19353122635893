/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState, useContext } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "../../assets/styles/Home.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutation } from "@apollo/client";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import HeaderSuccess from "../../components/Training/HeaderSucces";
import enproceso from "../../assets/img/anacoach/faces-02.png";
import SectionShare from "../../components/common/ShareSection";
import ModalAmazon from "../../components/common/ModalAmazon";
import ProductsHooks from "../../Helpers/HookHelpersProducts";
import {
  CREATE_OPPORTUNITIES,
  DYNAMIC_GET,
  UPDATE_STATUS_TRAINING_SUCCESS,
  GET_RECOMMENDATION_TRAININGS,
  INSERT_FINISHED_WO_BY_XCOACH,
  COUNT_MODAL_VIEWS,
} from "../../mutations";
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingSectionFlex,
  TrainingQuestion,
  DivLoader,
  TrainigTam,
  DivStyle,
} from "../../assets/styles/Training.styled";
import {
  SeparateSection,
  Feed_Main,
  Feed_Section,
} from "../../assets/styles/Feed.styled";
import { ContentHomeSection } from "../../components/common/Home.styled";
import Recommendations from "../../components/Training/RecommendedTraining";
import Header from "../../components/common/Header";
import CardOne from "../../components/Home/Card1Animate";
import ModalConSinPremio from "../../components/Feed/ModalConSinPremio";
import ClickOutRp from "../../components/rp/ClickOutRp";
import Scrapper from "./scrapper";
// import ClickButton from '../../Helpers/HookHelpers';
import WOlayout from "../../components/layouts/WOlayout";
import Resultados from "../../components/Training/Success_Result";
import ProductsFremium from "../../components/common/ProductsFremium";
import ModalVirality from "../../components/common/ModalVirality";
import ModalVirality2 from "../../components/common/ModalVirality2";
import CardCreditea from "../../components/Training/CardCreditea";
import InfoWoMonetizable from "../../components/common/InfoWoMonetizable";
export const productsContext = React.createContext();

const ScrapperProccess = () => {
  const ctx = useContext(productsContext);
  const selectProduct = ctx.resultadosItems;

  if (selectProduct !== 0 && ctx.intent === 1) {
    console.log("selectProduct", selectProduct);
    const content = [];
    ctx.setIntent(2);
    for (const index in selectProduct) {
      content.push(
        <Scrapper
          key={index}
          lenProduct={selectProduct.length}
          product={selectProduct[index]}
          numcard={index}
          setHiddenLoader={ctx.setHiddenLoader}
        ></Scrapper>
      );
      ctx.setResuestasScrapper(content);
    }

    return null;
  }
  return null;
};

function SuccessTarjeta() {
  const { sendApi } = ProductsHooks();
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  // const { actions } = ClickButton();
  const [respuestasScrapper, setResuestasScrapper] = useState([]);
  const [intent, setIntent] = useState(1);
  const [hiddenLoader, setHiddenLoader] = useState(true);
  const [recommendedTrainingForModal, setRecommendedTrainingForModal] =
    useState({});
  // const [goFeed] = useState(false);
  // const history = useHistory();
  let totalSol = "";
  let selectProduct = [];
  const [resultadosItems, setResultadosItems] = useState([]);
  const [OpenModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
  };
  const oneResult = [];
  const dateNow = new Date();
  let firstname = "";
  let nameClient = "";
  let emailClient = "";
  let telClient = "";
  firstname = sessionStorage.getItem("FirstName");
  nameClient = `${sessionStorage.getItem("FirstName")} ${sessionStorage.getItem(
    "FathersLastName"
  )}`;
  emailClient = sessionStorage.getItem("Email1");
  telClient = sessionStorage.getItem("CellPhone");

  const resultsProducts = [];
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const positionRappi = (arrayIdProducts) => {
    const rappi = arrayIdProducts.find((product) => product === "297");
    if (rappi !== undefined) {
      const sindex = arrayIdProducts.indexOf(rappi);
      arrayIdProducts.splice(sindex, 1);
      arrayIdProducts.unshift(rappi);
    }
    return arrayIdProducts;
  };

  // useEffect(() => {
  //   if (goFeed) {
  //     history.push('/the-training-feed/');
  //   }
  //   const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
  //   actions({ action: 'ClickButtonCollection', variables: click });
  // }, []);

  useEffect(() => {
    sessionStorage.removeItem("numIntentoScrapper");
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setOpenModal(true);
    }, 15000);
  }, []);
  // useEffect(() => {
  //   if (goFeed) {
  //     history.push('/the-training-feed/');
  //   }
  //   const click = `MasEntrenamientos_${sessionStorage.getItem('idTraining')}`;
  //   actions({ action: 'ClickButtonCollection', variables: click });
  // }, [goFeed]);

  sessionStorage.setItem("clickEmpezarAhoraHome", "true");
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "SuccessCC",
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (
      (sessionStorage.getItem("CatProductos") != null ||
        sessionStorage.getItem("CatProductosTemp") != null) &&
      sessionStorage.getItem("datProducts") != null
    ) {
      const jsonProducts = JSON.parse(
        sessionStorage.getItem("CatProductos") ||
          sessionStorage.getItem("CatProductosTemp")
      );
      for (let j = 0; j < jsonProducts.length; j++) {
        jsonProducts[j].productDetail.response = JSON.parse(
          jsonProducts[j].productDetail.response
        );
      }
      console.log("Pruebaaaaa", jsonProducts);
      const idsOpportunities = sessionStorage
        .getItem("datOpportunity")
        .split(",");
      totalSol = idsOpportunities.length;
      let arrayIdProducts;

      const tempSessionData = sessionStorage.getItem("datProducts");

      if (tempSessionData.trim() === "" || tempSessionData.length === 0) {
        const tempArr = [];
        let tempDta =
          sessionStorage.getItem("CatProductos") ||
          sessionStorage.getItem("CatProductosTemp");
        let allGood = true;
        try {
          tempDta = JSON.parse(tempDta);
        } catch (e) {
          allGood = false;
        }
        if (!allGood) return;
        tempDta.forEach((p) => tempArr.push(p.IDProduct.toString()));
        arrayIdProducts = tempArr;
      } else {
        arrayIdProducts = sessionStorage.getItem("datProducts").split(",");
      }

      //! Posicionar Rappi en primer lugar
      for (let i = 0; i < arrayIdProducts.length; i++) {
        for (let j = 0; j < jsonProducts.length; j++) {
          //! Mostrar modal Rappi
          // arrayIdProducts[i] == '297' && setShowModalRappi(true);
          if (
            parseInt(jsonProducts[j].IDProduct) === parseInt(arrayIdProducts[i])
          ) {
            console.log(jsonProducts[j].IDProduct, arrayIdProducts[i]);
            jsonProducts[j].idOpportunity = idsOpportunities[i];
            selectProduct.push(jsonProducts[j]);
          }
          // });
        }
      }

      setHiddenLoader(false);
      console.log(`selectProduct ---->${JSON.stringify(selectProduct)}`);
      setResultadosItems(getResultsItems(selectProduct));

      console.log("resultadosItems", resultadosItems);
    } else {
      selectProduct = [
        {
          IDProduct: 2,
          Name: "The Gold Elite Credit Card American Express",
          ImageURL:
            "https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/GoldEliteAMEX.png",
        },
      ];

      setResultadosItems(getResultsItems(selectProduct));
      console.log("resultadosItems", resultadosItems);
    }
  }, []);

  const getResultsItems = (selectProduct) => {
    console.log(`getResultItems ---->${JSON.stringify(selectProduct)}`);
    const resultItems = [];
    if (selectProduct.length === 3) {
      for (let i = 0; i < selectProduct.length; i++) {
        resultItems.push({
          idOpportunity: selectProduct[i].idOpportunity,
          Name: selectProduct[i].Name,
          BusinessAbr: selectProduct[i].BusinessAbr,
          id: selectProduct[i].IDProduct,
          imgCard: selectProduct[i].ImageURL,
          titleCard: selectProduct[i].Name,
          ClickOut: selectProduct[i].ClickOut,
          hasClickOut: selectProduct[i].hasClickOut,
          DescriptionProduct: selectProduct[i].DescriptionProduct,
          CAT: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === "CAT") {
              return (detalle.Description * 100).toFixed(2);
            }
          }),
          IVA: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === "Interés anual") {
              return (detalle.Description * 100).toFixed(2);
            }
          }),
          imgresult: enproceso,
          resultado: "En proceso",
          msgresultado: "¡Espera! Seguimos trabajando",
          ScrapperHabilitado: selectProduct[i].Enabled,
          info: `${"Fecha de solicitud  " + " "}${dateNow.getDate()}/${
            dateNow.getMonth() + 1
          }/${dateNow.getFullYear()}`,
          txtadicional:
            selectProduct[i].IDProduct === 311
              ? "Estás muy cerca de obtener tu tarjeta BBVA, para terminar tu solicitud ingresa aquí."
              : "Solicitud en proceso. Ya realizamos esta solicitud con el proveedor, espera información en tu correo electrónico.",
          documentos: "",
        });
      }
    } else {
      for (let i = 0; i < selectProduct.length; i++) {
        resultItems.push({
          idOpportunity: selectProduct[i].idOpportunity || "",
          Name: selectProduct[i].Name || "",
          BusinessAbr: selectProduct[i].BusinessAbr || "",
          id: selectProduct[i].IDProduct,
          imgCard: selectProduct[i].ImageURL,
          titleCard: selectProduct[i].Name,
          ClickOut: selectProduct[i].ClickOut,
          hasClickOut: selectProduct[i].hasClickOut,
          DescriptionProduct: selectProduct[i].DescriptionProduct,
          CAT: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === "CAT") {
              return (detalle.Description * 100).toFixed(2);
            }
          }),
          IVA: selectProduct[i].productDetail.response.map((detalle) => {
            if (detalle.Name === "Interés anual") {
              return (detalle.Description * 100).toFixed(2);
            }
          }),
          imgresult: enproceso,
          resultado: "En proceso",
          msgresultado: "¡Espera! Seguimos trabajando",
          ScrapperHabilitado: selectProduct[i].Enabled,
          info: `${"Fecha de solicitud" + " "}${dateNow.getDate()} / ${
            dateNow.getMonth() + 1
          } / ${dateNow.getFullYear()}`,
          txtadicional:
            selectProduct[i].IDProduct === 311
              ? "Estás muy cerca de obtener tu tarjeta BBVA, para terminar tu solicitud ingresa aquí."
              : "Solicitud en proceso. Ya realizamos esta solicitud con el proveedor, espera información en tu correo electrónico.",
          documentos: "",
        });
      }
    }
    return resultItems;
  };

  useEffect(() => {
    if (sessionStorage.getItem("datOpportunity") !== null) {
      const opportunities = sessionStorage.getItem("datOpportunity").split(",");
      for (const i in opportunities) {
        const getProductsInput = {
          input: {
            table: "DatOpportunity, CatProduct",
            columns: ["*"],
            conditions: [
              {
                valone: "IDProduct",
                valtwo: "CatProduct_IDProduct",
                condition: "=",
                validColumn: true,
              },
              {
                valone: "IDDatOpportunity",
                valtwo: opportunities[i],
                condition: "=",
                logic: "AND",
              },
            ],
          },
        };
        getOpportunity({ variables: getProductsInput });
      }
    } else {
      console.log("Sin data opportunity");
    }
  }, []);

  useEffect(() => {
    const idUser = sessionStorage.getItem("idUser");
    const idTraining = sessionStorage.getItem("idTraining");
    const fromXCoach = sessionStorage.getItem("fromXCoach");
    const idAdviser = sessionStorage.getItem("idAdviser");
    if (idUser != null) {
      insertFinishedByXCoach({
        variables: {
          idUser: parseInt(idUser) || 0,
          idTraining: parseInt(idTraining) || 0,
          fromXCoach: fromXCoach === "true" ? 1 : 0,
          idAdviser: parseInt(idAdviser) || null,
        },
      });
    }
  }, []);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log("Insert finished by Xcoach");
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getOpportunity] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        console.log("dynamicGet", dynamicGet.response);
        if (dynamicGet.response !== "") {
          const productsDetails = JSON.parse(dynamicGet.response)[0];
          resultsProducts.push(productsDetails);
          console.log(productsDetails);
        }
      }
    },
  });

  const [updateStatusTrainingSuccess] = useMutation(
    UPDATE_STATUS_TRAINING_SUCCESS,
    {
      onCompleted({ updateStatusTrainingSuccess }) {
        if (updateStatusTrainingSuccess.statusCode === 200) {
          console.log("statusTraining", updateStatusTrainingSuccess.response);
        }
      },
    }
  );

  useEffect(() => {
    if (!hiddenLoader) {
      try {
        const datOpportunity = sessionStorage.getItem("datOpportunity");
        const idDatTraining = sessionStorage.getItem("IdDatTraining");
        const idUser = sessionStorage.getItem("idUser");
        const inputStatuFinal = {
          input: {
            datOpportunity,
            idDatTraining,
            idUser,
          },
        };
        if (datOpportunity && idDatTraining && idUser) {
          console.log("inputStatuFinal", inputStatuFinal);
          setTimeout(() => {
            updateStatusTrainingSuccess({ variables: inputStatuFinal });
          }, 4000);
        }
      } catch (e) {
        console.log("inputStatuFinal", e);
      }
    } else {
      console.log("no se oculta loader");
    }
  }, [hiddenLoader]);

  const [WoTraining, setWoTraining] = useState("");

  useEffect(() => {
    console.log(`share training: ${sessionStorage.getItem("idTraining")}`);
    if (
      sessionStorage.getItem("idTraining") === "1" ||
      sessionStorage.getItem("idTraining") === "35"
    ) {
      setWoTraining("SolicitarTarjeta");
    } else if (sessionStorage.getItem("idTraining") === "3") {
      setWoTraining("TarjetaIdeal");
    }
  }, []);

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);

  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response
          );
          setRecomendaciones(true);
          const filter = getPersonalizeRecommendationTrainings.response.filter(
            (e) =>
              e.TrainingBigTitle === "Protege a tu familia" ||
              e.TrainingBigTitle === "Adelgaza tus deudas"
          );
          setRecommendedTrainingForModal(filter);
        }
      },
      onError(err) {
        console.log(err);
      },
    }
  );
  const email = sessionStorage.getItem("Email1");
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const rpValid =
    sessionStorage.getItem("tipoRP") === "RP_Tipo_1"
      ? 1
      : sessionStorage.getItem("tipoRP") === "RP_Tipo_2"
      ? 2
      : 0;
  console.log("##rpValid>>>", rpValid);
  const [textSaludo] = useState(
    "Hemos procesado tu solicitud y este es el resultado:"
  );

  return (
    <WOlayout
      backgroundColor={"rgb(190, 122, 230)"}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <TrainigTam>
        <Header
          background={"rgb(190, 122, 230)"}
          pathname={window.location.pathname}
        />
      </TrainigTam>
      {recommendedTrainingForModal.length > 0 ? (
        <ModalConSinPremio
          show={sessionStorage.getItem("medium") === "recompensas"}
          recommendedTraining={recommendedTrainingForModal}
          premio={sessionStorage.getItem("campaign") === "con-premio"}
        />
      ) : null}
      {sessionStorage.getItem("previousURL") === "/wo/card-funnel-r" ? (
        <ModalVirality2 setShowModal={handleOpenModal} showModal={OpenModal} />
      ) : (
        <ModalVirality />
      )}
      <HeaderSuccess
        color1="#E2C2EE"
        color2="#6677E3"
        color3="#6677E3"
        color4="#E2C2EE"
        color5="#8358C2"
        color6="#6677E3"
        color7="#A7FOFA"
        color8="#8358C2"
        color9="#CBBFED"
        color10="#6677E3"
      />
      <TrainingMain mt="100px" mtw="0" className="" id="top">
        {/* <ModalRappi show={showModalRappi} ></ModalRappi> */}
        <ModalAmazon show={true} wo={WoTraining}></ModalAmazon>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            paddiw="2% 10% 3% 10%"
            paddi="0% 5% 5% 5%"
            back="#fafafa"
          >
            <TrainingSubtitles
              size="22px"
              fw="600"
              align="center"
              alignweb="center"
              className="cabin"
            >
              ¡Gracias {firstname?.trim()}!
            </TrainingSubtitles>
            <TrainingText
              size="18px"
              weight="600"
              className="roboto"
              align="center"
              alignweb="center"
              ls="-0.2px"
            >
              {textSaludo}
            </TrainingText>
            <InfoWoMonetizable
              nameClient={nameClient}
              telClient={telClient}
              emailClient={emailClient}
            />
          </TrainingDivSection2>
          <SeparateSection />
        </TrainingSectionGray>
        {/*sessionStorage.getItem('showOportunidadCreditea') === 'true' ? (
            <TrainingSectionGray>
              <TrainingDivSection
                mt="20px"
                back="#fafafa"
                backw="#fafafa"
                wi="100%"
                wiw="100%"
                padding="0"
                paddw="0"
              >
                <TrainingSectionFlex
                  display="flex"
                  fdirection='column'
                  aItems='center'
                  className={
                    sessionStorage.getItem('idTraining') === '3'
                      ? 'counstColCC1'
                      : 'counstColCC'
                  }
                >
                  <DivStyle
                    align="center"
                    weight="bold"
                    mt="22px"
                    bott="15px"
                    className="cabin"
                    size="20px"
                    line="120%"
                  >
                    ¡Estos créditos están a un paso de ser tuyos!
                  </DivStyle>
                  <CardCreditea/>
                </TrainingSectionFlex>
              </TrainingDivSection>
            </TrainingSectionGray>
                ) : null */}
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection
            mt="20px"
            back="#fafafa"
            backw="#fafafa"
            wi="90%"
            wiw="90%"
            padding="0"
            paddw="0"
          >
            <TrainingSectionFlex
              display="flex"
              fdirection="column"
              aItems="center"
              className={
                sessionStorage.getItem("idTraining") === "3"
                  ? "counstColCC1"
                  : "counstColCC"
              }
            >
              {resultadosItems.length > 0 ? (
                <productsContext.Provider
                  value={{
                    resultadosItems,
                    setResuestasScrapper,
                    intent,
                    setIntent,
                    setHiddenLoader,
                  }}
                >
                  <ScrapperProccess></ScrapperProccess>
                </productsContext.Provider>
              ) : null}
              {respuestasScrapper}
              {hiddenLoader ? (
                <TrainingDivSection id="loadsuccess">
                  <DivLoader>
                    <CircularProgress color="inherit" />
                    <br />
                    <TrainingText className="dm-sans" align="center">
                      Estamos procesando tu solicitud, tardaremos unos
                      minutos...
                    </TrainingText>
                  </DivLoader>
                </TrainingDivSection>
              ) : null}
              <Resultados
                idTraining={sessionStorage.getItem("idTraining")}
                resultados={totalSol === 3 ? resultadosItems : oneResult}
              ></Resultados>
            </TrainingSectionFlex>
          </TrainingDivSection>
        </TrainingSectionGray>
        <div className="marginComentLgSuccess1">
          <TrainingSectionGray back="#fafafa">
            <ProductsFremium
              idT={parseInt(sessionStorage.getItem("idTraining"))}
            />
          </TrainingSectionGray>
        </div>
        <TrainingSectionGray back="#fafafa">
          <TrainingDivSection2
            wiw="670px"
            paddi="0"
            paddiw="0"
            mw="auto"
            back="#fafafa"
          >
            <SectionShare WO={WoTraining}></SectionShare>
          </TrainingDivSection2>
        </TrainingSectionGray>
        {recomendaciones ? (
          <Fragment>
            <TrainingSectionGray back="#fafafa">
              <TrainingDivSection2
                wiw="670px"
                paddi="0"
                paddiw="0"
                mw="auto"
                back="#fafafa"
              >
                <CardOne
                  classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                  classCard="cardOne"
                  classTop="topOne1"
                  text="¿Aún quieres ir por más?"
                  text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                />
                <CardOne
                  classAna={anchop <= 768 ? "falseAna" : "trueAna"}
                  classCard="cardOne"
                  classTop="topOne1"
                  text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                />
              </TrainingDivSection2>
            </TrainingSectionGray>

            <Fragment>
              <div className="marginComentLgSuccess1">
                <Feed_Main width="100%" background=" ">
                  <Feed_Main
                    mLeft="18%"
                    widthxs="90%"
                    width="80%"
                    display=" "
                    mtop=" "
                    background="transparent"
                  >
                    <Feed_Section padding="0 0 0 0" paddingxs="0 0 0 0">
                      <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                        <Recommendations
                          items={recommendedTraining}
                        ></Recommendations>
                      </ContentHomeSection>
                    </Feed_Section>
                  </Feed_Main>
                </Feed_Main>
              </div>
            </Fragment>
          </Fragment>
        ) : null}
      </TrainingMain>
    </WOlayout>
  );
}
const ingresoFijo = sessionStorage.getItem("Ingreso_Mensual_Fijo");

export default ingresoFijo === "de_4000_a_7000" || ingresoFijo === "menos_4000"
  ? ClickOutRp
  : SuccessTarjeta;
