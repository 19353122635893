import React, { Fragment, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Image } from './common.styled';
import { GENERATE_SHORTENED_LINK, SEND_NOTIFICATION } from '../../mutations';
import { GET_VIDEO_BY_ROUTINE } from '../../queries';
import CardOne from '../Home/Card1Animate';
import ModalPhoneNumber from './ModalNumberPhone';
import ModalWritePhoneNumber from './ModalWritePhoneNumber';
import ClickButton from '../../Helpers/HookHelpers';
import whats from '../../assets/img/anacoach/whats.png';

const SendNotificationVideo = ({ currentRoutine, urlYTDirect }) => {
  const { actions } = ClickButton();
  const [openSent, setOpenSent] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [Phone, setPhone] = useState('');
  const [shortLink, setShortLink] = useState('');
  const anchop = window.screen.width;

  const [getVideosByIdTrainingAndRoutineOrder] = useLazyQuery(
    GET_VIDEO_BY_ROUTINE,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getVideosByIdTrainingAndRoutineOrder }) {
        if (getVideosByIdTrainingAndRoutineOrder.statusCode === 200) {
          const resp = JSON.parse(
            getVideosByIdTrainingAndRoutineOrder.response,
          );
          const urlVideoYt = resp[0].QuestionOption;
          console.log('response == ', urlVideoYt);
          const cellPhone = sessionStorage.getItem('CellPhone');
          const inputSendVideo = {
            input: {
              type: 'wamult',
              properties: {
                trigger: 'Comparte_Videos_Wo',
                telephone: cellPhone,
                subject: '',
                template: '',
                variables: [
                  {
                    setkey: 'ShareVideoWos',
                    setval: `${shortLink}, ${urlVideoYt}`,
                  },
                ],
                source: {},
                destination: {},
              },
            },
          };
          console.log(
            'response == inputSendVideo == ',
            JSON.stringify(inputSendVideo),
          );
          sendNotification({ variables: inputSendVideo });
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted({ generateShortendLink }) {
      if (generateShortendLink.message === 'Exito') {
        const urlShortened = generateShortendLink.response;
        setShortLink(urlShortened);
        if (
          currentRoutine !== ''
          && currentRoutine !== undefined
          && currentRoutine !== null
        ) {
          getVideosByIdTrainingAndRoutineOrder({
            variables: {
              idTraining: sessionStorage.getItem('idTraining'),
              RoutinesOrder: String(currentRoutine),
            },
          });
        } else if (
          urlYTDirect !== ''
          && urlYTDirect !== undefined
          && urlYTDirect !== null
        ) {
          const cellPhone = sessionStorage.getItem('CellPhone');
          const inputSendVideo = {
            input: {
              type: 'wamult',
              properties: {
                trigger: 'Comparte_Videos_Wo',
                telephone: cellPhone,
                subject: '',
                template: '',
                variables: [
                  {
                    setkey: 'ShareVideoWos',
                    setval: `${urlShortened}, ${urlYTDirect}`,
                  },
                ],
                source: {},
                destination: {},
              },
            },
          };
          console.log(
            'response == inputSendVideo == ',
            JSON.stringify(inputSendVideo),
          );
          sendNotification({ variables: inputSendVideo });
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [sendNotification] = useMutation(SEND_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted({ sendNotification }) {
      if (sendNotification.statusCode === 200) {
        const send = sendNotification.response;
        if (send !== '') {
          setOpenSent(true);
        }
        console.log('response == SEND ==== ', send);
        console.log('Se envio correctamente el video!!');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const sendVideo = (e) => {
    const click = `EnviarVideo_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const cellPhone = sessionStorage.getItem('CellPhone');
    const urlTraining = window.location.href;
    if (
      cellPhone !== ''
      && cellPhone !== null
      && cellPhone !== undefined
      && cellPhone !== '0000000000'
    ) {
      const auxPhone = cellPhone.substr(-4);
      setPhone(`******${auxPhone}`);
      setOpenCheck(false);
      const inputShortened = {
        input: {
          url: urlTraining,
          telefono: cellPhone,
          mensaje:
            '¡Comparte este video con tus amigos, esperamos estes de regreso pronto en Coru!',
          idGen: 'ShareVideoWos',
        },
      };
      console.log(inputShortened);
      generateShortendLink({ variables: inputShortened });
    } else {
      setOpenCheck(true);
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
    sessionStorage.setItem('CellPhone', e.target.value);
  };
  return (
    <Fragment>
      <CardOne
        wo="SenNotifyVideo"
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={'cardOne'}
        classTop="topOne1"
        text="¿Quieres ver el video en otro momento?
			            Lo puedes tener en tu WhatsApp y verlo las veces que quieras."
        children={
          <div
            style={{
              background: 'none',
              display: 'flex',
              cursor: 'pointer',
              maxWidth: '325px',
              marginBottom: '0px',
              paddingTop: '20px',
              paddingLeft: '0px',
              paddingBottom: '0px',
            }}
            onClick={(e) => sendVideo(e)}
          >
            <Image
              src={whats}
              marginRight="4%"
              mwidth="15%"
              marginLeft="0px"
              width="10%"
              margin=" "
              top="110px"
              left="10%"
              mleft="0"
              mtop="75px"
            />
            <p style={{ color: '#F64282' }}>Enviarme el video</p>
          </div>
        }
      />
      <ModalPhoneNumber
        setOpen={setOpenSent}
        open={openSent}
        cellPhone={Phone}
      />
      <ModalWritePhoneNumber
        setOpen={setOpenCheck}
        open={openCheck}
        cellPhone={Phone}
        handleChange={handleChange}
        sendVideo={sendVideo}
      />
    </Fragment>
  );
};

export default SendNotificationVideo;
