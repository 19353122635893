/* eslint no-prototype-builtins: */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  Image,
  ContEntrenamiento,
  TitleEntrenamiento,
} from '../../assets/styles/Home.styled';
import GloveBoxDataItem from './GloveBoxDataItem';

function GloveBoxCat({
  color,
  name,
  imagen,
  id,
  items,
  createJsonInput,
  questions,
  setQuestions,
}) {
  const [showInfo, setShowInfo] = useState(false);

  const handleClickShowInfo = (e) => {
    e.stopPropagation();
    // verifica cuando se haga click en la categoria y se
    // actualiza la varaible para mostrar el texto de la categoria
    setShowInfo(!showInfo);
  };

  return (
            <>
                <div>
                    <ContEntrenamiento id={`categorieSelectedOption${id}`} height="auto" widthM="100%" margintop="0" padding="0px" paddingM="0px" widthD="100%" cursor="pointer" marginleft="auto">
                        <div className="row divCategorias" style={{ border: `1px solid ${color}` }}
                            onClick={(e) => handleClickShowInfo(e)}
                            >
                            <div className="col-2 d-flex flex-column justify-content-start align-items-center">
                                <Image
                                    src={imagen}
                                    float=" "
                                    position=" "
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-8 center paddingmobile">

                                <TitleEntrenamiento className="roboto" aling="left" sizem="19px" left="0px" margin="auto" top=" ">
                                    {name}
                                </TitleEntrenamiento>
                            </div>
                            <div className="col-2 center">
                              {!showInfo ? (
                                <TitleEntrenamiento
                                  className="roboto"
                                  left="0px"
                                  margin="auto"
                                  top=" "
                                  onClick={() => { setShowInfo(true); }}
                                >
                                  <AddIcon/>
                                </TitleEntrenamiento>
                              ) : (
                                <TitleEntrenamiento
                                  className="roboto"
                                  left="0px"
                                  margin="auto"
                                  top=" "
                                  onClick={() => { setShowInfo(false); }}
                                >
                                    <RemoveIcon/>
                                </TitleEntrenamiento>
                              )
                              }
                            </div>
                        </div>
                    </ContEntrenamiento>

                {showInfo ? (
                    <>
                    {items.map((item, i) => (
                        <GloveBoxDataItem
                          formIcon={item.formIcon}
                          formTitle={item.formTitle}
                          vigencyText={item.hasOwnProperty('vigencyText') ? item.vigencyText : null}
                          vigency={item.hasOwnProperty('vigency') ? item.vigency : null}
                          payMethod={item.hasOwnProperty('payMethod') ? item.payMethod : null}
                          coverage={item.hasOwnProperty('coverage') ? item.coverage : null}
                          insurance={item.hasOwnProperty('insurance') ? item.insurance : null}
                          lastServiceDate={item.hasOwnProperty('lastServiceDate') ? item.lastServiceDate : null}
                          nextServiceDate={item.hasOwnProperty('nextServiceDate') ? item.nextServiceDate : null}
                          TAGNumber={item.hasOwnProperty('TAGNumber') ? item.TAGNumber : null}
                          balance={item.hasOwnProperty('balance') ? item.balance : null}
                          calco={item.hasOwnProperty('calco') ? item.calco : null}
                          gummed={item.hasOwnProperty('gummed') ? item.gummed : null}
                          nextVerificationDate={item.hasOwnProperty('nextVerificationDate') ? item.nextVerificationDate : null}
                          showFileText={item.hasOwnProperty('showFileText') ? item.showFileText : null}
                          documentFile={item.hasOwnProperty('documentFile') ? item.documentFile : null}
                          emergencyNumbers={item.hasOwnProperty('emergencies') ? item.emergencies : null}
                          insuranceNumbers={item.hasOwnProperty('insurances') ? item.insurances : null}
                          key={i}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                        />))
                    }
                    </>) : null
                }
              </div>
      </>
  );
}

GloveBoxCat.propTypes = {
  formIcon: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  imagen: PropTypes.string,
  id: PropTypes.number,
  items: PropTypes.array,
  createJsonInput: PropTypes.func,
  questions: PropTypes.array,
  setQuestions: PropTypes.func,
};

export default GloveBoxCat;
