import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  // TrainingDivSection,
} from '../../assets/styles/Training.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import SectionVideo from '../../components/common/SectionVideo';
import CardOne from '../../components/Home/Card1Animate';
import ClickButton from '../../Helpers/HookHelpers';

import Features from '../../components/Training/Features';

const TipsInversiones = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  saveAdvance,
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const anchop = window.screen.width;

  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [decalogoInversor, setDecalogoInversor] = useState(
    sessionStorage.getItem('DecalogoInversor') || '',
  );
  const [videoTipsInversionOne, setVideoTipsInversionOne] = useState(false);
  const [primerTextoTipsInversion, setPrimerTextoTipsInversion] = useState(false);
  const [segundoTextoTipsInversion, setSegundoTextoTipsInversion] = useState(false);
  const [videoTipsInversionTwo, setVideoTipsInversionTwo] = useState(false);
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});

  const [Rutine, setRutine] = useState({});

  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    switch (step) {
      case 0:
        setEditDecalogoInv(true);
        setDecalogoInversor(value);
        break;
      default:
        console.log('default break handleChangeCatOption case 4');
        break;
    }
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          let contVideos = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              // console.log(arrayResponseRutine[i])
              const idTypeQuestion = arrayResponseRutine[i].idType;
              // console.log("index: ", i, " ", idTypeQuestion);
              const responseUser = arrayResponseRutine[i].responseValue;
              const { idQuestion } = arrayResponseRutine[i];
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              const { questionName } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      const valueCat = detailQuestion[x].CatFieldDetail_IDCatFieldDetail;

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              } else if (questionType === 'Video') {
                contVideos++;
                console.log('video ', contVideos);
              }
            }

            if (
              arrayResponseRutine.length + contVideos
              < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length + contVideos,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // If idUser and idCont exist, we proceed to recover the existing data for this routine
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setDecalogoInversor(sessionStorage.getItem('DecalogoInversor') || '');
    }
  }, [recoveryRoutine]);
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editDecalogoInv, setEditDecalogoInv] = useState('');

  const handleChangeIcon = (json) => {
    setEditDecalogoInv(false);
    setDecalogoInversor('');
    setLapiz([json]);
  };
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const handleVerTextoTipsInversion = (numTexto) => {
    switch (numTexto) {
      case 1:
        setPrimerTextoTipsInversion(true);
        break;
      case 2:
        setSegundoTextoTipsInversion(true);
        break;
      case 3:
        // setTercerTextoTipsInversion(true);
        saveAdvance(parseInt(currentRoutine));
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log('defaul case');
    }
  };

  const styleBtnDisabled = {
    color: '#fff',
    background: '#aaa',
    border: 'solid 1px #fff',
    display: 'none',
  };
  const [btnContinueMoney, setBtnContinueMoney] = useState({});
  const [btnContinueOne, setBtnContinueOne] = useState({});
  const [btnContinueTips2, setBtnContinueTips2] = useState({});
  const [btnContinueTexto2, setBtnContinueTexto2] = useState({});
  const [btnContinueVideo, setBtnContinueVideo] = useState({});
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const advanceRoutineTips = () => {
    const click = `ContinuarTips1_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueMoney(styleBtnDisabled);
    handleVerTextoTipsInversion(1);
    gsap.to(window, { duration: 1, scrollTo: '#SegundoTip' });
  };
  const advanceRoutineVideo = () => {
    const click = `ContinuarVideoDecalogo_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueOne(styleBtnDisabled);
    setVideoTipsInversionOne(true);
    gsap.to(window, { duration: 1, scrollTo: '#PrimerTipVideo' });
  };
  const advanceRoutineTips2 = () => {
    const click = `ContinuarTips2_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueTips2(styleBtnDisabled);
    handleVerTextoTipsInversion(2);
    gsap.to(window, { duration: 1, scrollTo: '#TercerTip' });
  };
  const advanceRoutineTexto2 = () => {
    const click = `ContinuarTips3_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueTexto2(styleBtnDisabled);
    setVideoTipsInversionTwo(true);
    gsap.to(window, { duration: 1, scrollTo: '#CuartoTip' });
  };
  const advanceRoutineVideo2 = () => {
    const click = `ContinuarVideo2_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setBtnContinueVideo(styleBtnDisabled);
    handleVerTextoTipsInversion(3);
    gsap.to(window, { duration: 1, scrollTo: '#FinalRoutine' });
  };

  useEffect(() => {
    if (decalogoInversor === 'Sí') {
      gsap.to(window, { duration: 1, scrollTo: '#videoDecalogo' });
    } else if (decalogoInversor === 'No') {
      gsap.to(window, { duration: 1, scrollTo: '#PrimerTip' });
    }
  }, [decalogoInversor]);

  const [inicioDec, setInicioDec] = useState(true);
  useEffect(() => {
    if (inicioDec) {
      setTimeout(() => {
        gsap.to(window, { duration: 1, scrollTo: '#inicio' });
        setInicioDec(false);
      }, 1500);
    }
  }, []);

  const feacturesArray = useState([
    {
      text: 'A la vista (Un día)',
      color: '#99F2A8',
    },
    {
      text: '31 días',
      color: '#99F2A8',
    },
    {
      text: '91 días',
      color: '#99F2A8',
    },
    {
      text: '182 días',
      color: '#99F2A8',
    },
    {
      text: '364 días',
      color: '#99F2A8',
    },
    {
      text: '2, 3 y 5 años',
      color: '#99F2A8',
    },
  ]);

  return (
    <>
      {/* -----------
        First question
        -------------*/}
      <div id="inicio"></div>
      <QuestionsOrder
        classCard={'cardOne'}
        orderRutine={currentRoutine}
        step={0}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (decalogoInversor === ''
            || (lapiz[0].routine === 3 && lapiz[0].active === 1))
          && editDecalogoInv !== true
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="PrimerTip"></div>
      <div>
        <img
          alt="editIcon"
          className={
            decalogoInversor !== ''
            && (editDecalogoInv === true
              || !(lapiz[0].routine === 3 && lapiz[0].active === 1))
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 3, active: 1 })}
        />

        <CardTwo
          classCard={
            decalogoInversor !== ''
            && (editDecalogoInv === true
              || !(lapiz[0].routine === 3 && lapiz[0].active === 1))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={decalogoInversor}
          colorTraining={trainingColor}
        ></CardTwo>
      </div>
      <div id="videoDecalogo"></div>
      <TrainingQuestion
        className={decalogoInversor.includes('Sí') ? '' : 'hidden'}
      >
        <SectionVideo title="" benefits={[]}>
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
          <CardsBtn
            style={btnContinueOne}
            type="button"
            min_w="100%"
            min_web="100%"
            mtopw="19px"
            mtop="19px"
            onClick={(e) => advanceRoutineVideo(e)}
          >
            Continuar
          </CardsBtn>
        </SectionVideo>
      </TrainingQuestion>
      <div id="PrimerTipVideo"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          decalogoInversor.includes('No') || videoTipsInversionOne
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          decalogoInversor.includes('No') || videoTipsInversionOne
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        hora={hora}
        children={
          <>
            <p>
              La primera pregunta a la que te enfrentarás al momento de hacer tu
              primera inversión será:
            </p>
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
              ¿Cuánto dinero invertir?
            </p>
            <p>
              En las opciones que te presentaremos el monto mínimo que podrás
              invertir va desde $1 peso hasta $10,000 pesos.
            </p>
            <p>
              Si no estás seguro de cuánto dinero invertir, recuerda que los
              expertos recomiendan comenzar a invertir el 10% de tu ingreso.
            </p>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              ¡Solo si eso no afecta tu gasto diario!
            </p>
          </>
        }
      />
      <div style={{ display: 'flex' }}>
        <CardsBtn1
          className={
            decalogoInversor.includes('No') || videoTipsInversionOne
              ? ''
              : 'hidden'
          }
          style={btnContinueMoney}
          onClick={(e) => {
            advanceRoutineTips(e);
          }}
        >
          Continuar
        </CardsBtn1>
      </div>
      <div id="SegundoTip"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={primerTextoTipsInversion ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={primerTextoTipsInversion ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        hora={hora}
        children={
          <>
            <p>
              La segunda pregunta a la que te enfrentarás al momento de hacer tu
              primera inversión será:
            </p>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              ¿A qué plazo invertir?
            </p>
            <p>
              En general los sitios de inversión se refieren al plazo de tu
              inversión, es decir el tiempo que se quedará tu dinero invertido y
              por lo general no podrás tocar tu dinero durante este tiempo.{' '}
            </p>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              ¡Por eso es vital planear!
            </p>
            <p>
              Separa claramente tu dinero entre tus gastos cotidianos, tu ahorro
              y la inversión.
            </p>
          </>
        }
      />
      {primerTextoTipsInversion ? (
        <Features
          textintro=""
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          title="Los plazos más usuales en los sitios de inversión son:"
          Arraytext={feacturesArray}
        />
      ) : null}
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={primerTextoTipsInversion ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Si no sabes cuánto dinero invertir
				no te preocupes. Lo primero que debes saber es que en general, mientras más tiempo dejes tu dinero en una inversión
				tendrás más oportunidades para recibir rendimientos, beneficios y otras oportunidades."
        hora={hora}
      />
      <div style={{ display: 'flex' }}>
        <CardsBtn1
          className={primerTextoTipsInversion ? '' : 'hidden'}
          style={btnContinueTips2}
          onClick={(e) => {
            advanceRoutineTips2(e);
          }}
        >
          Continuar
        </CardsBtn1>
      </div>
      {/* -----------
        Third question
        -------------*/}
      <div id="TercerTip"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={segundoTextoTipsInversion ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={segundoTextoTipsInversion ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="La última pregunta que tal vez te estarás haciendo probablemente será ¿Cuándo es el momento ideal para invertir?"
        text2="En el siguiente vídeo los expertos te explicarán cuando es el momento idóneo para iniciar a invertir:"
        hora={hora}
      />
      <TrainingQuestion className={segundoTextoTipsInversion ? '' : 'hidden'}>
        <SectionVideo title="" benefits={[]}>
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
          <CardsBtn
            style={btnContinueTexto2}
            mtop="19px"
            type="button"
            min_w="100%"
            min_web="100%"
            max_w="100%"
            onClick={(e) => advanceRoutineTexto2(e)}
          >
            Continuar
          </CardsBtn>
        </SectionVideo>
      </TrainingQuestion>
      <div id="CuartoTip"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={videoTipsInversionTwo ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={videoTipsInversionTwo ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        textbold="¡Cada vez estás más cerca de invertir!"
        hora={hora}
        children={
          <>
            <p>
              Sabemos que decidir el plazo y monto de tu primera inversión no
              siempre es fácil ni claro. Para ayudarte está el{' '}
              <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                Comparador Coru
              </span>{' '}
              de mi{' '}
              <span style={{ fontWeight: 'bold' }}>Primera Inversión</span>,
              usarlo es muy sencillo:
            </p>
            <p style={{ marginLeft: '10px' }}>
              - Ingresa el monto de dinero que te gustaría invertir.
            </p>
            <p style={{ marginLeft: '10px' }}>
              - Indica el plazo de tiempo que podrías dejar invertido tu dinero.
            </p>
            <p>
              ¡Listo! El comparador te mostrará cuánto podrías ganar según el
              plan de inversión que elijas.
            </p>
          </>
        }
      />
      <div id="FinalRoutine"></div>
      <div style={{ display: 'flex' }}>
        <CardsBtn1
          className={videoTipsInversionTwo ? '' : 'hidden'}
          style={btnContinueVideo}
          onClick={(e) => {
            advanceRoutineVideo2(e);
          }}
        >
          Continuar
        </CardsBtn1>
      </div>
    </>
  );
};

export default TipsInversiones;
