import styled from 'styled-components';

export const KandaStyle = styled.div`
    display: flex;
    box-shadow: 0px 0px 30px rgba(41, 41, 41, 0.06);
    border-radius: 16px;
    flex-direction: column;
    width: 386px;
    height: ${props => props.hizoClick? "259px" : "533px"};   
    animation-name: appear;
    animation-duration: 2s;
    z-index: 1;
    @media (max-width: 320px) {
        width: 350px;
    }
}
    @keyframes appear {
    0% {
        transform: translateY(-1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
`;

export const HeaderKanda = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    margin-top: 25px;   
    margin-bottom: 8px;   
`;

export const MessagesContainer = styled.div`
    display: flex;
    flex-direction: row;   
    justify-content: center;
    margin: 0px 15px;
`;

export const PicKandaBot = styled.div`
    width: 54px;
    height: 53px;
    @media (max-width: 320px) {
        width: 50px;
        height: 50px;

    }
`;

export const Messages = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 259px;
    margin-left: 15px;
    @media (max-width: 320px) {
        max-width: 245px;
        margin-left: 10px;

    }
`;

export const Message = styled.div`
    background: rgba(107, 213, 175, 0.24);
    border-radius: 0px 15px 15px 15px;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;   
    padding: 12px 12px 12px 16px;
    margin: 8px 1px;

`;

export const Text = styled.span`
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: ${props => (props.button? 'bold' : "normalnpm stat")};
    font-size: ${props => props.button? "16px" : "14px"};
    line-height: 120%;
    letter-spacing: -0.3px;
    color: ${props => props.button? "#FFFFFF" : "#252525"};
    margin: ${props => props.header? "10px 0px" : props.list? "4px 0px" : "8px 0px"};
`;

export const TextList = styled.span`
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #6BD5AF;
    margin-right: 12px;
`;

export const Button = styled.button`
    width: ${(props) => props.width || ''};
    margin: ${(props) => props.margin || ''};
    background-color: #F64282;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    padding: 13px 20px;    
`;
