import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CALL_SCRAPPER } from '../../mutations';
import { GET_RESPONSE_SCRAPPER_BD } from '../../queries';
import {
  getJsonAmex,
  getJsonBBVA,
  getJsonBanamex,
  responseBank,
  getCreditCardProccess,
} from '../../Helpers/JsonScrappers';
import Resultados from '../../components/Training/Success_Result';
import errorFace from '../../assets/img/anacoach/faces-01.png';
import enproceso from '../../assets/img/anacoach/faces-02.png';
import successFace from '../../assets/img/anacoach/faces-03.png';

const Scrapper = ({
  product, numcard, setHiddenLoader, lenProduct,
}) => {
  const [validate, setValidate] = useState({
    success: false,
  });
  const [jsonResponseBd, setJsonResponseBd] = useState({});
  const [callScrapper, { data, loading, error }] = useMutation(CALL_SCRAPPER);
  const [getResponseScrapperBD] = useLazyQuery(GET_RESPONSE_SCRAPPER_BD, {
    async onCompleted({ getResponseScrapperBD }) {
      console.log('getResponseScrapperBD', getResponseScrapperBD);
      try {
        let encodeString = '';
        switch (product.BusinessAbr) {
          case 'CITIBANAMEX':
            setJsonResponseBd(getResponseScrapperBD);
            if (
              getResponseScrapperBD.folio.includes('SinFolio')
              || getResponseScrapperBD.folio.includes('sinfolio')
              || getResponseScrapperBD.response === ''
              || getResponseScrapperBD.response === null
              || getResponseScrapperBD.response === undefined
            ) {
              if (
                sessionStorage.getItem('statusAutenticacion') === 'Autenticado'
              ) {
                const jsonBanamex = getJsonBanamex(String(product.Name));
                encodeString = btoa(JSON.stringify(jsonBanamex));

                callScrapper({
                  variables: {
                    dataJson: encodeString,
                    bank: 'CITIBANAMEX',
                    idOportunity: product.idOpportunity,
                    idUser: sessionStorage.getItem('idUser'),
                    pixelID: '525808197839029',
                    ipAddressUser: '',
                    url: window.location.href,
                    userAgent: window.navigator.userAgent,
                    em: sessionStorage.getItem('Email1'),
                    ph: sessionStorage.getItem('CellPhone'),
                    eventName: 'Search',
                  },
                });
              } else {
                console.log('response bd');
                setValidate(true);
              }
            } else {
              console.log('response bd');
              setValidate(true);
            }

            break;
          case 'BBVA':
            setJsonResponseBd(getResponseScrapperBD);
            if (
              getResponseScrapperBD.folio.includes('SinFolio')
              || getResponseScrapperBD.folio.includes('sinfolio')
              || getResponseScrapperBD.response === ''
              || getResponseScrapperBD.response === null
              || getResponseScrapperBD.response === undefined
            ) {
              if (
                sessionStorage.getItem('statusAutenticacion') === 'Autenticado'
              ) {
                const jsonBBVA = getJsonBBVA(String(product.Name));
                encodeString = btoa(JSON.stringify(jsonBBVA));
                console.log('scrapper BBVA', encodeString);
                callScrapper({
                  variables: {
                    dataJson: encodeString,
                    bank: 'BBVA',
                    idOportunity: product.idOpportunity,
                    idUser: sessionStorage.getItem('idUser'),
                    pixelID: '525808197839029',
                    ipAddressUser: '',
                    url: window.location.href,
                    userAgent: window.navigator.userAgent,
                    em: sessionStorage.getItem('Email1'),
                    ph: sessionStorage.getItem('CellPhone'),
                    eventName: 'Search',
                  },
                });
              } else {
                console.log('response bd');
                setValidate(true);
              }
            } else {
              console.log('response bd');
              setValidate(true);
            }
            break;
          case 'FALABELLA':
            // setValidate(true);
            break;
          case 'AMEX':
            setJsonResponseBd(getResponseScrapperBD);
            if (
              getResponseScrapperBD.folio.includes('SinFolio')
              || getResponseScrapperBD.folio.includes('sinfolio')
              || getResponseScrapperBD.response === ''
              || getResponseScrapperBD.response === null
              || getResponseScrapperBD.response === undefined
            ) {
              if (
                sessionStorage.getItem('statusAutenticacion') === 'Autenticado'
              ) {
                const jsonAmex = getJsonAmex(String(product.Name));
                encodeString = btoa(JSON.stringify(jsonAmex));
                console.log('scrapper amex', {
                  dataJson: encodeString,
                  bank: 'AMEX',
                  idOportunity: product.idOpportunity,
                  idUser: sessionStorage.getItem('idUser'),
                  pixelID: '525808197839029',
                  ipAddressUser: '',
                  url: window.location.href,
                  userAgent: window.navigator.userAgent,
                  em: sessionStorage.getItem('Email1'),
                  ph: sessionStorage.getItem('CellPhone'),
                  eventName: 'Search',
                });

                callScrapper({
                  variables: {
                    dataJson: encodeString,
                    bank: 'AMEX',
                    idOportunity: product.idOpportunity,
                    idUser: sessionStorage.getItem('idUser'),
                    pixelID: '525808197839029',
                    ipAddressUser: '',
                    url: window.location.href,
                    userAgent: window.navigator.userAgent,
                    em: sessionStorage.getItem('Email1'),
                    ph: sessionStorage.getItem('CellPhone'),
                    eventName: 'Search',
                  },
                });
              } else {
                console.log('response bd');
                setValidate(true);
              }
            } else {
              console.log('response bd');
              setValidate(true);
            }
            break;
          default:
            console.log('default case bancos scrapper: ', product.BusinessAbr);
        }
      } catch (e) {
        console.log(e);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (!validate.success) {
      let apagar = true;
      if (product.ScrapperHabilitado === false) {
        apagar = true;
        console.log(`scrapper Apagado ${product.BusinessAbr}`);
      } else {
        apagar = false;
        console.log(`scrapper Encendido ${product.BusinessAbr}`);
      }
      // apagar=true

      try {
        // const encodeString = '';
        const idOportunity = String(product.idOpportunity);
        switch (product.BusinessAbr) {
          case 'CITIBANAMEX':
            if (apagar) {
              sessionStorage.setItem(
                `status-${parseInt(numcard) + 1}`,
                'EN PROCESO',
              );
              setValidate(true);
            } else if (idOportunity) {
              getResponseScrapperBD({ variables: { idOportunity } });
            }

            break;
          case 'BBVA':
            if (apagar) {
              sessionStorage.setItem(
                `status-${parseInt(numcard) + 1}`,
                'EN PROCESO',
              );
              setValidate(true);
            } else if (idOportunity) {
              getResponseScrapperBD({ variables: { idOportunity } });
            }
            break;
          case 'FALABELLA':
            sessionStorage.setItem(
              `status-${parseInt(numcard) + 1}`,
              'EN PROCESO',
            );
            if (sessionStorage.getItem('runFALABELLA') === null) {
              if (sessionStorage.getItem('numCards') !== null) {
                sessionStorage.setItem(
                  'numCards',
                  parseInt(sessionStorage.getItem('numCards')) + 1,
                );
              } else {
                sessionStorage.setItem('numCards', 1);
              }
              sessionStorage.setItem('runFALABELLA', 'true');
            }
            setValidate(true);
            break;
          case 'AMEX':
            const getsalary = sessionStorage.getItem('Ingreso_Mensual_Fijo');
            const Score = sessionStorage.getItem('BCScore') || '';
            const intScore = parseInt(Score, 10);
            console.log(typeof Score);
            if (
              getsalary !== 'menos_4000'
              && getsalary !== 'de_4000_a_7000'
              && getsalary !== 'de_7000_a_15000'
              && intScore >= 640
            ) {
              console.log('Amex 218 llame a la api');
              if (idOportunity) {
                getResponseScrapperBD({ variables: { idOportunity } });
              }
            }

          default:
            console.log('default case bancos scrapper: ', product.BusinessAbr);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    const resultadosItems = [];
    product.imgresult = errorFace;
    resultadosItems.push(product);
    return <Resultados idTraining={sessionStorage.getItem('idTraining')} resultados={resultadosItems}></Resultados>;
  }
  if (data || validate) {
    const resultadosItems = [];
    if (data !== undefined || jsonResponseBd.response !== undefined) {
      let responseScrapper = {};
      if (data !== undefined) {
        responseScrapper = data.callScrapper;
      } else {
        console.log('bdResponse');
        responseScrapper = jsonResponseBd;
      }

      sessionStorage.setItem(
        `folio_${product.BusinessAbr}`,
        responseScrapper.folio,
      );
      sessionStorage.setItem(
        `response_${product.BusinessAbr}`,
        responseScrapper.response,
      );
      sessionStorage.setItem(
        `comment_${product.BusinessAbr}`,
        responseScrapper.comment,
      );
      sessionStorage.setItem(
        `status_${product.BusinessAbr}`,
        responseScrapper.status,
      );
      sessionStorage.setItem(
        `DateIni_${product.BusinessAbr}`,
        responseScrapper.dateIni,
      );
      sessionStorage.setItem(
        `dateFin_${product.BusinessAbr}`,
        responseScrapper.dateFin,
      );
      sessionStorage.setItem(
        `run_${product.BusinessAbr}`,
        responseScrapper.run,
      );
      const arrayFaces = [errorFace, successFace, enproceso];
      product = responseBank(product, responseScrapper, arrayFaces, numcard);
    }
    getCreditCardProccess(setHiddenLoader, lenProduct);
    resultadosItems.push(product);

    return <Resultados idTraining={sessionStorage.getItem('idTraining')} resultados={resultadosItems}></Resultados>;
  }
  return <div></div>;
};

export default Scrapper;
