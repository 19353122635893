import React, { Fragment } from 'react';

import {
  DivStyle,
  DivGray,
  Resultado,
  RPProductos,
  TrainingSubtitles,
  TrainingDivSection,
  TrainingText,
} from '../../assets/styles/Training.styled';

import { SeparateSection } from '../../assets/styles/Feed.styled';

const SectionVideo = ({
  title = '',
  benefits = [],
  disegn = '1',
  iconExit = true,
  iconArrow = true,
  children,
}) => {
  if (disegn === '1') {
    return (
      <TrainingDivSection
        bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
        mlw="18%"
        paddw="3%"
        ml="0px"
        mr="0px"
        br="4px"
        padding="3%"
        wi="100%"
        wiw="60%"
        he="auto"
        hew="auto"
        mb="0px"
        mtw="22px"
      >
        <div className={iconExit ? 'closeDiv' : 'hidden'}></div>
        <TrainingSubtitles
          size="18px"
          align="center"
          alignweb="center"
          className="cabin"
          style={{ marginTop: '10%' }}
        >
          {title}
        </TrainingSubtitles>
        <TrainingText
          className="roboto"
          align="left"
          alignweb="justify"
          ls="-0.2px"
        >
          {benefits.map((benefit) => (benefit !== '' ? (
              <>
                {benefit}
                <br />
              </>
          ) : null))}
        </TrainingText>
        {children}
      </TrainingDivSection>
    );
  }
  if (disegn === '2') {
    return (
      <Fragment>
        <TrainingSubtitles></TrainingSubtitles>
        <DivStyle size="18px" line="1.2em">
          <Resultado
            minhweb="500px"
            minh="520px"
            marriweb="auto"
            marri="auto"
            border=" "
          >
            <DivGray>
              <div>
                <DivStyle>
                  <DivStyle bott="8px" weight="bold">
                    {title}
                  </DivStyle>
                </DivStyle>
                <br />
                <DivStyle align="justify">
                  {benefits.map((benefit) => (benefit !== '' ? (
                      <Fragment>
                        <div>{benefit}</div>
                        <br />
                      </Fragment>
                  ) : null))}
                </DivStyle>
                <br />
                <br />
                {children}
              </div>
            </DivGray>
          </Resultado>
        </DivStyle>
        <SeparateSection />
        <RPProductos scroll="none"></RPProductos>
      </Fragment>
    );
  }
};
export default SectionVideo;
