/* eslint-disable no-case-declarations */
import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
import "../assets/styles/kanda/KandaDesktop.css";
import { Helmet } from "react-helmet";
import SeguroAuto from "../assets/img/lpCI/IMAGE_AUTO.svg";
import Asesor from "../assets/img/lpCI/ASESOR_CALL.svg";
import Function1 from "../assets/img/lpCI/STEP1.svg";
import Function2 from "../assets/img/lpCI/STEP2.svg";
import Function3 from "../assets/img/lpCI/STEP3.svg";
import Function4 from "../assets/img/lpCI/STEP4.svg";
import Axa from "../assets/img/lpCI/imageAXA.svg";
import Qualitas from "../assets/img/lpCI/imageQUALITAS.svg";
import Gnp from "../assets/img/lpCI/imageGNP.svg";
import Ana from "../assets/img/lpCI/imageANA.svg";
import manchas from "../assets/img/lpCI/manchas.svg";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/anacoach/CoruLogoB.png";
import { CardsBtn } from "../assets/styles/Training.styled";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import ClickButton from "../Helpers/HookHelpers";

const Container1 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  // background: linear-gradient(
  //   360deg,
  //   rgba(239, 209, 247, 0.32) 0.52%,
  //   rgba(209, 209, 247, 0.32) 39.06%,
  //   rgba(255, 255, 255, 0.32) 100%
  // );
  background: #FFFDF2;
  min-height: 100vh;
  padding: 24px 0;
  align-items: center;
  // @media (min-width: 770px) {
  //   display: grid;
  // }
`;

const TitleBlue = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  color: #070B11;
  margin: ${(props) => props.marginD || ""};
  @media (min-width: 770px) {
    text-align: ${(props) => props.textAD || "left"};
    font-size: ${(props) => props.fontSD || "52px"};
  }
`;

const TextGray = styled.div`
  font-weight: ${(props) => props.fontW || "400"};
  font-size: ${(props) => props.fontS || "16px"};
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#070B11"};
  text-align: ${(props) => props.textA || ""};
  margin-top: ${(props) => props.marginT || ""};
  @media (min-width: 770px) {
    display: ${(props) => props.displayMQ || "block"};
    font-size: ${(props) => props.fontSD || "24px"};
    text-align: ${(props) => props.textAD || ""};
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "24px"};
  justify-content: ${(props) => props.justifyC || ""};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.marginT || ""};
  text-align: ${(props) => props.textA || ""};
  height: ${(props) => props.height || ""};
  padding: ${(props) => props.padding || ""};
  @media (max-width: 770px) {
    display: ${(props) => props.display || "block"};
    padding: ${(props) => props.paddingMob || ""};
    text-align: ${(props) => props.textAM || ""};
    height: ${(props) => props.heightMob || ""};
  }
`;

const ImageFunction = styled.img`
  min-width: 48px;
  height: 48px;
  width: 48px;
  margin-top: -24px;
  margin-bottom: 12px;
  @media (max-width: 770px) {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const ImageDownload = styled.img`
  width: 25%;
  padding: 0 10px;
  @media (min-width: 900px) {
    width: 140px;
  }
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 25%;
    margin-top: 15px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 75%;
    display: block;
  }
`;

const PercentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 770px) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 770px) {
    padding: 0 24px;
  }
`;

const ContainerBack = styled.div`
  background-image: ${(props) => props.backgroundImage || ""};
  background-size: ${(props) => props.backgroundSize || ""};
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 770px) {
    background-image: none;
  }
}
`;

const ContainerTextB = styled.div`
  background: #FBF2A7;
  padding: 24px;
  margin: 48px 0;
`;
const ContainerFM = styled.div`
  display: none;
  @media (max-width: 770px) {
    display: block;
    padding: 0 24px;
  }
`;
const ContainerFD = styled.div`
  display: none;
  @media (min-width: 770px) {
    display: flex;
    margin-top: 30px;
    filter: drop-shadow(0px 60px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px 21.901px 29.2013px rgba(0, 0, 0, 0.0482987)) drop-shadow(0px 10.6325px 14.1767px rgba(0, 0, 0, 0.0389404)) drop-shadow(0px 5.21226px 6.94968px rgba(0, 0, 0, 0.0310596)) drop-shadow(0px 2.06094px 2.74791px rgba(0, 0, 0, 0.0217013));
  }
`;
function LPCotizadorCI() {
  const { actions } = ClickButton();
  const URLactual = window.location.hostname;
  let isProd = false;
  if (URLactual === 'coru.com') {
    isProd = true;
  } else {
    isProd = false;
  }

  const handleClickCoru = (name) => {
    actions({
      action: 'ClickButtonCollection',
      variables: name,
    });
    switch (name) {
      case 'CatalogoProductos':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.open('https://dev-rediseno.coru.mx/kanda/catalogo-de-productos', '_blank');
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.open('https://coru.com/kanda/catalogo-de-productos', '_blank');
        }
        break;
      case 'AcercaDeNosotros':
        window.open('/', '_blank');
        break;
      case 'DiagnosticoV3':
        if (process.env.REACT_APP_SERVERTYPE === 'Development') {
          window.open('https://dev-rediseno.coru.mx/kanda/wo/diagnosticov3', '_blank');
        } else if (process.env.REACT_APP_SERVERTYPE === 'Production' || process.env.REACT_APP_SERVERTYPE === 'Stg') {
          window.open('https://coru.com/kanda/wo/diagnosticov3', '_blank');
        }
        break;
      case 'CotizadorSeguroAuto':
        window.open('https://i.coru.mx/seguro-de-auto/datosdevehiculo_Step1/?utm_term=&utm_campaign=aw_coru_lead_do_performancemax_v2&utm_source=adwords&utm_medium=ppc&hsa_acc=6354046841&hsa_cam=18706343019&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gclid=CjwKCAjw2OiaBhBSEiwAh2ZSPwxx4c8syNITf-4EK_FIksUV1eNXkoCr7B5Xr_ZIX1jFYWypCypTSRoCGWMQAvD_BwE', '_blank');
        break;
      default:
        break;
    }
  };

  function callAsesor() {
    actions({
      action: 'ClickButtonCollection',
      variables: 'AsesorWhatsapp',
    });
    const whatsappMessage = `¡Hola! Me gustaría hablar con un asesor experto en seguros de auto.`;
    window.open(
      `https://api.whatsapp.com/send/?phone=5215569784125&text=${encodeURI(
        whatsappMessage,
      )}`,
      '_blank'
    );
  }

  const Header = () => (
  <div style={{ height: "85px", display: "flex", padding: "0 24px" }}>
    <ContLogo>
      <Image
        cursor="pointer"
        src={ImageLogo}
        width="120px"
        mwidth="97.92px"
        heightMobile="20.8px"
        alt="logo-coru"
      />
    </ContLogo>
    <ContLogo2>
      <ContentGeneric width="auto" widthMd="15%">
        <KandaButton
          action={() => handleClickCoru('DiagnosticoV3')}
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
          color="#070B11"
          fontS="14px"
          style={{ opacity: '0.5', textDecoration: 'none', textAlign: 'right' }}
        >
          Diagnóstico Financiero
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="auto" widthMd="15%">
        <KandaButton
          action={() => handleClickCoru('CatalogoProductos')}
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
          color="#070B11"
          fontS="14px"
          style={{ opacity: '0.5', textDecoration: 'none', textAlign: 'right' }}
        >
          Catálogo de productos
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="auto" widthMd="15%">
        <KandaButton
          action={() => handleClickCoru('AcercaDeNosotros')}
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
          color="#070B11"
          fontS="14px"
          style={{ opacity: '0.5', textDecoration: 'none', textAlign: 'right' }}
        >
          Acerca de nosotros
        </KandaButton>
      </ContentGeneric>
    </ContLogo2>
  </div>
  );

  return (
    <Container1>
      <Helmet>
        <title>{isProd ? 'Conoce sobre nosotros | CORU' : 'Conoce sobre nosotros'}</title>
      </Helmet>
      <Header />
      <ContainerBack backgroundImage={`url(${manchas})`} backgroundSize="contain">
        <div
          style={{
            maxWidth: "1056px",
            margin: "auto",
          }}
        >
          <PercentContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <TitleBlue
                className="cabin"
                fontS="32px"
                textAD="center"
                style={{ margin: "15px 0" }}
              >
                Cotiza tu seguro de auto
              </TitleBlue>
              <TextGray
                fontW="400"
                fontSD="26px"
                className="roboto"
                textA="center"
                textAD="center"
                style={{ margin: "15px 0" }}
              >
                Conoce las ventajas de contratar tu seguro con Coru. Cotiza y descubre el seguro que más te conviene.
              </TextGray>
              <CardsBtn
                styledMaxWidth="300px"
                type="button"
                min_w="300px"
                min_web="300px"
                max_w="300px"
                windthmd="60%"
                style={{ borderRadius: "16px" }}
                onClick={() => handleClickCoru('CotizadorSeguroAuto')}
              >
                ¡Cotiza ahora!
              </CardsBtn>
            </div>
            <img
              src={SeguroAuto}
              style={{ width: "100%", maxWidth: "425px" }}
            />
          </PercentContainer>
        </div>
      </ContainerBack>
      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
        }}
      >
        <ContainerTextB>
          <TextGray className="roboto" textAD="center" color="#595959" fontSD="16px">
            En Coru, tu Coach Financiero no compramos autos ni vendemos herramientas para coches.
          </TextGray>
        </ContainerTextB>
        <ContainerFM>
          <ContainerFlex margin="15px 0" display="flex">
            <ImageFunction src={Function1} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Cotiza
              </TextGray>
              <TextGray className="roboto">
                Ingresa tus datos para cotizar con todas las aseguradoras.
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0" display="flex">
            <ImageFunction src={Function2} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Asesoría
              </TextGray>
              <TextGray className="roboto">
                En minutos, uno de nuestros asesores se comunicará contigo.
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0" display="flex">
            <ImageFunction src={Function3} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Elije la aseguradora
              </TextGray>
              <TextGray className="roboto">
                Selecciona el seguro de auto que más te convenga.
              </TextGray>
            </div>
          </ContainerFlex>
          <ContainerFlex margin="15px 0" display="flex">
            <ImageFunction src={Function4} />
            <div>
              <TextGray fontW="600" fontS="18px" className="roboto">
                Paga
              </TextGray>
              <TextGray className="roboto">
                Realiza el pago y listo, nuestro asesor te enviará inmediatamente tu póliza.
              </TextGray>
            </div>
          </ContainerFlex>
        </ContainerFM>
        <div
          style={{
            maxWidth: "1056px",
            margin: "auto",
          }}
        >
          <ContainerFD>
            <ContainerFlex margin="15px 0" textA="center" style={{ width: '25%' }}>
              <div style={{
                padding: '8px 12px', marginLeft: 'auto', marginRight: 'auto', background: '#FFFFFF', borderRadius: '16px'
              }}>
                <ImageFunction src={Function1} />
                <TextGray fontW="600" fontS="18px" className="roboto" fontSD="20px">
                  Cotiza
                </TextGray>
                <TextGray className="roboto" fontSD="14px" marginT="16px">
                  Ingresa tus datos para cotizar con todas las aseguradoras.
                </TextGray>
              </div>
            </ContainerFlex>
            <ContainerFlex margin="15px 15px" textA="center" style={{ width: '25%' }}>
              <div style={{
                padding: '8px 12px', marginLeft: 'auto', marginRight: 'auto', background: '#FFFFFF', borderRadius: '16px'
              }}>
                <ImageFunction src={Function2} />
                <TextGray fontW="600" fontS="18px" className="roboto" fontSD="20px">
                  Asesoría
                </TextGray>
                <TextGray className="roboto" fontSD="14px" marginT="16px">
                  En minutos, uno de nuestros asesores se comunicará contigo.
                </TextGray>
              </div>
            </ContainerFlex>
            <ContainerFlex margin="15px 15px" textA="center" style={{ width: '25%' }}>
              <div style={{
                padding: '8px 12px', marginLeft: 'auto', marginRight: 'auto', background: '#FFFFFF', borderRadius: '16px'
              }}>
                <ImageFunction src={Function3} />
                <TextGray fontW="600" fontS="18px" className="roboto" fontSD="20px">
                  Elije la aseguradora
                </TextGray>
                <TextGray className="roboto" fontSD="14px" marginT="16px">
                  Selecciona el seguro de auto que más te convenga.
                </TextGray>
              </div>
            </ContainerFlex>
            <ContainerFlex margin="15px 0" textA="center" style={{ width: '25%' }}>
              <div style={{
                padding: '8px 12px', marginLeft: 'auto', marginRight: 'auto', background: '#FFFFFF', borderRadius: '16px'
              }}>
                <ImageFunction src={Function4} />
                <TextGray fontW="600" fontS="18px" className="roboto" fontSD="20px">
                  Paga
                </TextGray>
                <TextGray className="roboto" fontSD="14px" marginT="16px">
                  Realiza el pago y listo, nuestro asesor te enviará inmediatamente tu póliza.
                </TextGray>
              </div>
            </ContainerFlex>
          </ContainerFD>
        </div>
        <div
          style={{
            maxWidth: "1056px",
            margin: "auto",
          }}
        >
          <PercentContainer>
            <img
              src={Asesor}
              style={{ width: "100%", maxWidth: "425px", marginTop: "24px" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <TextGray
                fontW="600"
                fontS="18px"
                fontSD="26px"
                className="roboto"
                textA="center"
                textAD="center"
                style={{ margin: "15px 0" }}
              >
                También puedes hablar directo con uno de nuestros asesores.
              </TextGray>
              <CardsBtn
                styledMaxWidth="300px"
                type="button"
                min_w="300px"
                min_web="300px"
                max_w="300px"
                windthmd="60%"
                style={{ borderRadius: "16px" }}
                onClick={() => callAsesor()}
              >
                Hablar con un asesor
              </CardsBtn>
            </div>
          </PercentContainer>
          <ContainerFlex textAM="center" justifyC="center" margin="30px 24px" height="155px" heightMob="auto" padding="40px 80px" paddingMob="0" gap="200px">
            <ImageDownload src={Axa} />
            <ImageDownload src={Qualitas} />
            <ImageDownload src={Gnp} />
            <ImageDownload src={Ana} />
          </ContainerFlex>
        </div>
      </div>
      <Footer isAboutUS="si" />
    </Container1>
  );
}

export default LPCotizadorCI;
