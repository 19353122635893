import React, { Fragment, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  TextPerfil,
  ContainerPerfiles,
} from "../../assets/styles/pantInicio.styled";
import {
  Container,
  Col12,
  Image,
  SubtitleOne,
  ParagrapTwo,
  ContainerMenu,
} from "../../components/common/common.styled";
import {
  CREATE_CLICK,
  DYNAMIC_GET,
  CREATE_SESSION,
  LOGLOGROS_GET_MONGO,
} from "../../mutations";
import { GET_PRODUCTS_BY_ID, CHECK_USER_COLLABORATIVE } from "../../queries";
import "../../assets/styles/perfil/perfiles.css";
import ShareCommonFeed from "../Training/ShareCommonFeed";
import ConcluidosW from "../../assets/img/logros/ConcluidosW.png";
import ConcluidosB from "../../assets/img/logros/ConcluidosB.png";
import ProgresoW from "../../assets/img/logros/ProgresoW.png";
import ProgresoB from "../../assets/img/logros/ProgresoB.png";
import MetasB from "../../assets/img/logros/MetaB.svg";
import MetasW from "../../assets/img/logros/MetaW.svg";
import ScoreB from "../../assets/img/logros/ScoreB.svg";
import ScoreW from "../../assets/img/logros/ScoreW.svg";
import GuardadosB from "../../assets/img/logros/guardadoB.svg";
import GuardadosW from "../../assets/img/logros/guardadoW.svg";
import InvitacionesB from "../../assets/img/logros/InvitacionesB.svg";
import SolicitudesB from "../../assets/img/logros/SolicitudesB.svg";
import Recap from "./Recap";
import Progreso from "./Training_progress";
import Completed from "./Training_completed";
import Goals from "./Goals";
import Invites from "./Invites";
import Request from "./Request";
import Score from "./MyScore";
import SavedProducts from "./SavedProducts";
import GeneralHooks from "../../Helpers/HookHelpersGeneral";
import { getQueryVariableHashtagDeeplink } from "../../Helpers";
import { Top_Text } from "../../assets/styles/Feed.styled";
import CaretUp from "../../assets/img/home/caretup.png";
import Gana from "../../assets/img/logros/Gana.svg";
import { Title_h1 } from "../../assets/styles/Home.styled";
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
  ContainerBottons,
} from "../../components/common/Home.styled";
import Kanda from "../../components/Kanda/Kanda";
import "../../assets/styles/kanda/KandaDesktop.css";
import ModalVirality from "../../components/common/ModalVirality";
import ShareWin from "../../components/common/BannerRewards";

function LogrosWeb() {
  const idTrainingCollaborative = 37;
  const { actions } = GeneralHooks();
  const [cardsByProvider, setCardsByProvider] = useState([]);

  const [idProducts, setIdProducts] = useState([]);
  const [infoByTraining, setInfoByTraining] = useState([]);
  const [productBytraining, setProductBytraining] = useState({});
  const [OpenModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!OpenModal);
  };

  const [createLog] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem("id_logLogros", dynamicMongoSession.response);
      }
    },
  });

  const [GetLog] = useMutation(LOGLOGROS_GET_MONGO, {
    onCompleted({ LogLogrosGet }) {
      if (LogLogrosGet.response !== null) {
        sessionStorage.setItem("id_logLogros", LogLogrosGet.response);
      } else {
        const mongoInputBinacle = {
          idCont: sessionStorage.getItem("idCont"),
          idUser: sessionStorage.getItem("idUser"),
          FirstName: sessionStorage.getItem("FirstName"),
          MiddleName: sessionStorage.getItem("MiddleName"),
          FathersLastName: sessionStorage.getItem("FathersLastName"),
          MothersLastName: sessionStorage.getItem("MothersLastName"),
          email: sessionStorage.getItem("Email1"),
        };
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        mongoInputBinacle.timestampEC = [
          {
            timeStamp: today,
            idCatRelAction: sessionStorage.getItem("idCatRelAction"),
          },
        ];
        const dataStringBinacle = JSON.stringify(mongoInputBinacle);
        const mongoInput = {
          input: {
            collection: "LogLogros",
            data: dataStringBinacle,
          },
        };
        createLog({ variables: mongoInput });
      }
    },
  });

  const [getUser] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const userDetail = JSON.parse(dynamicGet.response)[0];
        sessionStorage.setItem("CellPhone", userDetail.CellPhone || "");
        sessionStorage.setItem("Email1", userDetail.Email1);
        sessionStorage.setItem("HomePhone", userDetail.HomePhone || "");
        sessionStorage.setItem("FirstName", userDetail.FirstName || "");
        sessionStorage.setItem("RFC", userDetail.RFC || "");
        sessionStorage.setItem("MiddleName", userDetail.MiddleName || "");
        sessionStorage.setItem(
          "FathersLastName",
          userDetail.FathersLastName || ""
        );
        sessionStorage.setItem(
          "MothersLastName",
          userDetail.MothersLastName || ""
        );
        sessionStorage.setItem("BirthDate", userDetail.BirthDate || "");
        sessionStorage.setItem("Gender", userDetail.Gender || "");
        sessionStorage.setItem("MaritalStatus", userDetail.MaritalStatus || "");
        sessionStorage.setItem("BirthState", userDetail.BirthState || "");
        sessionStorage.setItem("Nationality", userDetail.BirthState || "");
        sessionStorage.setItem("BirthState", userDetail.BirthState || "");
        sessionStorage.setItem("BirthState", userDetail.BirthState || "");
        sessionStorage.setItem("DataPerson", true);
        if (
          sessionStorage.getItem("BirthDate") !== "" &&
          sessionStorage.getItem("BirthDate") != null
        ) {
          const BirthDate = new Date(sessionStorage.getItem("BirthDate"));
          sessionStorage.setItem(
            "day",
            BirthDate.getDay() ? BirthDate.getDay() : `0${BirthDate.getDay()}`
          );
          sessionStorage.setItem(
            "month",
            BirthDate.getMonth() > 8
              ? BirthDate.getMonth() + 1
              : `0${BirthDate.getMonth() + 1}`
          );
          sessionStorage.setItem("year", BirthDate.getFullYear());
        }
        actions({ action: "getAchievment", variables: "" });
        handleClickSolicitudes();
      }
    },
  });

  const [checkUserCollaborative] = useLazyQuery(CHECK_USER_COLLABORATIVE, {
    onCompleted({ checkUserCollaborative }) {
      if (checkUserCollaborative.message === "success") {
        setIsWOColaborativo(true);
        setShowMetas(true);
        handleClickMetas();
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getInfoByIdTraining] = useMutation(DYNAMIC_GET, {
    fetchPolicy: "no-cache",
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const response = JSON.parse(dynamicGet.response);
        debugger;
        if (response.length > 0) {
          setInfoByTraining(response);
          getProductsByIdProduct({
            variables: {
              idProduct: idProducts,
            },
          });
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getProductsByIdProduct] = useLazyQuery(GET_PRODUCTS_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted({ getProductsByIdProduct }) {
      if (getProductsByIdProduct) {
        const response = JSON.parse(getProductsByIdProduct.response);
        if (response.length !== 0) {
          const productsByWO = [];
          let filterProduct = [];
          let objProductByT = {};
          for (let i = 0; i < infoByTraining.length; i++) {
            const {
              IDCatTraining,
              Name: NameTraining,
              TrainingRedirectUrl,
              TrainingColor,
            } = infoByTraining[i];
            const arraySavedProducts = productBytraining[IDCatTraining];
            for (let s = 0; s < arraySavedProducts.length; s++) {
              const { CatProduct_IdProduct, idTraining } =
                arraySavedProducts[s];
              response.filter((product) => {
                if (CatProduct_IdProduct === product.IDProduct) {
                  const result = {
                    ...product,
                    NameTraining,
                    TrainingRedirectUrl,
                    TrainingColor,
                    idTraining,
                  };
                  filterProduct.push(result);
                }
              });
            }
            objProductByT.training = filterProduct;
            productsByWO.push(objProductByT);
            objProductByT = {};
            filterProduct = [];
          }
          setCardsByProvider(productsByWO);
        } else {
          setProductosGuardados(false);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getidSaveProducts] = useMutation(DYNAMIC_GET, {
    fetchPolicy: "no-cache",
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        const response = JSON.parse(dynamicGet.response);
        if (response.length !== 0) {
          const arrayIds = [];
          // let idTrainings = [];
          const objproductByTraining = {};
          const conditions = [];
          const validIdTraining = [];
          for (let i = 0; i < response.length; i++) {
            arrayIds.push(String(response[i].CatProduct_IdProduct));
            if (!validIdTraining.includes(response[i].idTraining)) {
              validIdTraining.push(response[i].idTraining);

              const arrayProducts = response.filter(
                (product) => response[i].idTraining === product.idTraining
              );

              if (
                !objproductByTraining.hasOwnProperty(response[i].idTraining)
              ) {
                objproductByTraining[response[i].idTraining] = arrayProducts;
              }
              conditions.push({
                logic: i === 0 ? "" : "OR",
                valone: "IDCatTraining",
                condition: "=",
                valtwo: String(response[i].idTraining),
              });
            }
          }
          setProductBytraining(objproductByTraining);
          setIdProducts(arrayIds);
          const input = {
            input: {
              table: "CatTraining",
              columns: ["*"],
              conditions,
            },
          };
          getInfoByIdTraining({
            variables: input,
          });
          setProductosGuardados(true);
        } else {
          setCardsByProvider([]);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const getProductsFunct = async () => {
    actions({ action: "getAchievment", variables: "" });
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    if (!sessionStorage.getItem("id_logLogros")) {
      const mongoInput = {
        input: {
          collection: "LogLogros",
          filter: sessionStorage.getItem("idCont"),
        },
      };
      GetLog({ variables: mongoInput });
    } else {
      const createClickInput = {
        input: {
          id: sessionStorage.getItem("id_logLogros"),
          column: "timestampEC",
          collection: "LogLogros",
          timeStamp: today,
          idCatRelAction: sessionStorage.getItem("idCatRelAction"),
        },
      };
      registerLog({ variables: createClickInput });
    }
    const query = window.location.hash.substring(1);
    const varsSinHash = query.split("#");
    if (varsSinHash.length >= 3) {
      handleClickSolicitudes();
    }
    getidSaveProducts({
      variables: {
        input: {
          table: "DatCardSaving",
          columns: ["*"],
          conditions: [
            {
              valone: "CatUsers_IDUser",
              condition: "=",
              valtwo: String(sessionStorage.getItem("idUser")),
            },
            {
              logic: "AND",
              valone: "`status`",
              condition: "=",
              valtwo: "1",
            },
            {
              logic: "GROUP BY CatProduct_IdProduct",
              validColumn: true,
              valone: " ",
              condition: " ",
              valtwo: " ",
            },
          ],
        },
      },
    });
  };

  const getOtherFunct = async () => {
    const { idUser, idCont } =
      getQueryVariableHashtagDeeplink("funnel-prestamos");
    sessionStorage.setItem("idUser", idUser);
    sessionStorage.setItem("idCont", idCont);
    const GetUserInput = {
      input: {
        table: "CatContactMedium, CatContact",
        columns: ["*"],
        conditions: [
          {
            valone: "IDCont",
            condition: "=",
            valtwo: idCont,
          },
          {
            logic: "AND",
            valone: "IDCont",
            condition: "=",
            valtwo: "CatContact_IDCont",
            validColumn: true,
          },
        ],
      },
    };
    await getUser({ variables: GetUserInput });
  };

  useEffect(() => {
    if (sessionStorage.getItem("idUser")) {
      getProductsFunct();
    } else {
      getOtherFunct();
    }
    if (sessionStorage.getItem('idTraining')) {
      sessionStorage.removeItem('idTraining');
    }
    sessionStorage.setItem('BannerRewards', 'BannerRewards_Logros');
  }, []);

  const [createClickFront] = useMutation(
    CREATE_CLICK,
    {
      onCompleted() {
        localStorage.setItem("activityDate", new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    []
  );

  const [registerLog] = useMutation(
    CREATE_CLICK,
    {
      onCompleted() {
        localStorage.setItem("activityDate", new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    []
  );

  sessionStorage.setItem("PrimerVis", false);
  useEffect(() => {
    document.querySelector("#root").style.backgroundColor = "#fafafa";
  });

  const scrollToTop = () => {
    document.querySelector("#root").scrollIntoView({
      behavior: "smooth",
    });
  };
  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem("id_session"),
        column: action,
        collection: "sessions",
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem("id_session"))
      createClickFront({ variables: createClickInput });
    TagManager.dataLayer(tagManagerArgs);
  };
  const [Open, SetOpen] = useState({
    resumen: false,
    concluidos: true,
    progreso: false,
    metas: false,
    invitaciones: false,
    solicitudes: false,
    guardados: false,
    score: false,
  });
  const handleClickDirecction = () => {
    SetOpen({
      resumen: false,
      concluidos: true,
      progreso: false,
      metas: false,
      invitaciones: false,
      solicitudes: false,
      guardados: false,
      score: false,
    });
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    sendMongoAndGTM("clickDirection");
    const createClickInput = {
      input: {
        id: sessionStorage.getItem("id_logLogros"),
        column: "timestampEC",
        collection: "LogLogros",
        timeStamp: today,
        idCatRelAction: sessionStorage.getItem("idCatRelAction"),
      },
    };
    if (sessionStorage.getItem("id_logLogros"))
      registerLog({ variables: createClickInput });
  };

  const handleClickProgreso = () => {
    SetOpen({
      resumen: false,
      concluidos: false,
      progreso: true,
      metas: false,
      invitaciones: false,
      solicitudes: false,
      guardados: false,
      score: false,
    });
    sendMongoAndGTM("clickProgress");
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    sendMongoAndGTM("clickDirection");
    const createClickInput = {
      input: {
        id: sessionStorage.getItem("id_logLogros"),
        column: "timestampEP",
        collection: "LogLogros",
        timeStamp: today,
        idCatRelAction: sessionStorage.getItem("idCatRelAction"),
      },
    };
    if (sessionStorage.getItem("id_logLogros"))
      registerLog({ variables: createClickInput });
  };

  const handleClickMetas = () => {
    SetOpen({
      resumen: false,
      concluidos: false,
      progreso: false,
      metas: true,
      invitaciones: false,
      solicitudes: false,
      guardados: false,
      score: false,
    });
    sendMongoAndGTM("clickGoals");
  };
  const handleClickInvitaciones = () => {
    SetOpen({
      resumen: false,
      concluidos: false,
      progreso: false,
      metas: false,
      invitaciones: true,
      solicitudes: false,
      guardados: false,
      score: false,
    });
    sendMongoAndGTM("clickInvite");
  };
  const handleClickSolicitudes = () => {
    SetOpen({
      resumen: false,
      concluidos: false,
      progreso: false,
      metas: false,
      invitaciones: false,
      solicitudes: true,
      guardados: false,
      score: false,
    });
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const mongoInputBinacle = {
      idCont: sessionStorage.getItem("idCont"),
      idUser: sessionStorage.getItem("idUser"),
      FirstName: sessionStorage.getItem("FirstName"),
      MiddleName: sessionStorage.getItem("MiddleName"),
      FathersLastName: sessionStorage.getItem("FathersLastName"),
      MothersLastName: sessionStorage.getItem("MothersLastName"),
      email: sessionStorage.getItem("Email1"),
      timestampDA: [today],
    };
    const dataStringBinacle = JSON.stringify(mongoInputBinacle);
    const mongoInput = {
      input: {
        collection: "LogLogros",
        data: dataStringBinacle,
      },
    };
    if (!sessionStorage.getItem("id_logLogros")) {
      createLog({ variables: mongoInput });
    } else {
      const createClickInput = {
        input: {
          id: sessionStorage.getItem("id_logLogros"),
          column: "timestampDA",
          collection: "LogLogros",
          timeStamp: today,
          idCatRelAction: sessionStorage.getItem("idCatRelAction"),
        },
      };
      registerLog({ variables: createClickInput });
    }
    sendMongoAndGTM("clickSolicitudes");
  };
  const handleClickGuardados = () => {
    SetOpen({
      resumen: false,
      concluidos: false,
      progreso: false,
      metas: false,
      invitaciones: false,
      solicitudes: false,
      guardados: true,
      score: false,
    });
    sendMongoAndGTM("clickGuardados");
  };
  const handleClickScore = () => {
    SetOpen({
      resumen: false,
      concluidos: false,
      progreso: false,
      metas: false,
      invitaciones: false,
      solicitudes: false,
      guardados: false,
      score: true,
    });
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    sendMongoAndGTM("clickDirection");
    const createClickInput = {
      input: {
        id: sessionStorage.getItem("id_logLogros"),
        column: "timestampScore",
        collection: "LogLogros",
        timeStamp: today,
        idCatRelAction: sessionStorage.getItem("idCatRelAction"),
      },
    };
    if (sessionStorage.getItem("id_logLogros"))
      registerLog({ variables: createClickInput });
    sendMongoAndGTM("clickScore");
  };

  const [textBienvedio, SetTextBienvenido] = useState("Bienvenido(a)");

  const TextInicial = () => {
    switch (sessionStorage.getItem("Gender")) {
      case "Masculino":
        SetTextBienvenido("Bienvenido");
        break;
      case "Femenino":
        SetTextBienvenido("Bienvenida");
        break;
      default:
        SetTextBienvenido("Bienvenido(a)");
        break;
    }
  };
  const [trainingProcess, settrainingProcess] = useState([]);
  const [validPeticion, setValidPeticion] = useState(true);
  useEffect(() => {
    if (validPeticion && trainingProcess.length !== 0) {
      TextInicial();
      getAllTrainingFunction();
    }
  }, [validPeticion]);

  const { search } = window.location;
  useEffect(() => {
    const idUser = sessionStorage.getItem("idUser") || "";
    if (idUser !== "" && idUser !== "undefined") {
      const input = {
        idUser,
        idTraining: String(idTrainingCollaborative),
      };
      checkUserCollaborative({ variables: input });
    } else {
      sessionStorage.setItem("SearchSource", search);
      const params = new URLSearchParams(search);
      sessionStorage.setItem("CellPhone", params.get("phone") || "");
      sessionStorage.setItem("cellPhone", params.get("phone") || "");
      if (params.get("phone")) {
        setIsWOColaborativo(true);
        setShowMetas(false);
        handleClickInvitaciones();
      }
    }
  }, []);

  let Training;

  const [getAllTraining] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      if (dynamicGet.statusCode === 200) {
        Training = JSON.parse(dynamicGet.response);
        settrainingProcess([Training]);
        setValidPeticion(false);
      }
    },
  });

  const getAllTrainingFunction = () => {
    const getAllTrainingInput = {
      input: {
        table: "CatRelUsersTraining, DatTraining, CatTraining",
        columns: ["*"],
        conditions: [
          {
            valone: "IDUserCollaborative",
            condition: "=",
            valtwo: sessionStorage.getItem("idUser"),
          },
          {
            logic: "AND",
            valone: "CatRelUsersTraining.IDDatTraining",
            condition: "=",
            valtwo: "DatTraining.IDDatTraining",
            validColumn: true,
          },
          {
            logic: "AND",
            valone: "IDCatTraining",
            condition: "=",
            valtwo: "CatTraining_IDTraining",
            validColumn: true,
          },
        ],
      },
    };
    getAllTraining({ variables: getAllTrainingInput });
  };
  const [productosGuardados, setProductosGuardados] = useState(false);
  const [solicitudesActive] = useState(true);
  const [isWOColaborativo, setIsWOColaborativo] = useState(false);
  const [showMetas, setShowMetas] = useState(true);
  const [widthBrowser, setwidthBrowser] = useState(window.outerWidth);
  useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);
  const isViernes = sessionStorage.getItem("dayOfWeek") === "Hola";
  // const trainingProcess = (sessionStorage.getItem("trainingProcess") != null ? sessionStorage.getItem("trainingProcess") : 0);
  return (
    <Fragment>
      <Container
        width="100%"
        mt="1rem"
        widthMobile="94%"
        mtMobile="2rem"
        float="auto!important"
        className="container"
      >
        <ContainerMenu width="50%" mt="0px" mb="0px" paddingMobile="0px">
          <Col12 ta="center">
            <Title_h1
              top="1.2%"
              topM="10%"
              align="center"
              talign="center"
              className="roboto"
            >
              Logros
            </Title_h1>
            <ParagrapTwo
              mb="10%"
              mbweb="3%"
              transform=" "
              className="mx-auto roboto"
              texttalign="center"
              textalignMobile="center"
              fontSize="20px"
              fontWeight="bold"
            >
              {textBienvedio} , {sessionStorage.getItem("FirstName")}
            </ParagrapTwo>
            <TextPerfil
              fw="bold"
              fs="20px"
              mt="2%"
              fsMobile="17px"
              mb="5%"
            ></TextPerfil>
          </Col12>
          <ContainerPerfiles
            paddingM="5%"
            filter={
              Open.concluidos
                ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                : ""
            }
            onClick={handleClickDirecction}
            background={Open.concluidos ? "#fb245c" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.concluidos ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="18px"
              color={Open.concluidos ? "#fff" : "#07210A"}
            >
              {!Open.concluidos ? (
                <Image
                  src={ConcluidosB}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={ConcluidosW}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}
              Entrenamientos concluidos
            </SubtitleOne>
          </ContainerPerfiles>
          <ContainerPerfiles
            paddingM="5%"
            filter={
              Open.progreso
                ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                : ""
            }
            onClick={handleClickProgreso}
            background={Open.progreso ? "#fb245c" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.progreso ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="18px"
              color={Open.progreso ? "#fff" : "#07210A"}
            >
              {!Open.progreso ? (
                <Image
                  src={ProgresoB}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={ProgresoW}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}
              Entrenamientos en progreso
            </SubtitleOne>
          </ContainerPerfiles>
          {productosGuardados ? (
            <ContainerPerfiles
              paddingM="5%"
              filter={
                Open.guardados
                  ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                  : ""
              }
              onClick={handleClickGuardados}
              background={Open.guardados ? "#fb245c" : "#ffff"}
            >
              <SubtitleOne
                className="roboto"
                transform=" "
                textalingnMobile="left"
                fw={Open.guardados ? "bold" : "400!important"}
                textalingn="left"
                mb="0%"
                size="20px"
                sizeMobile="18px"
                color={Open.guardados ? "#fff" : "#07210A"}
              >
                {!Open.guardados ? (
                  <Image
                    src={GuardadosB}
                    width="23px"
                    marginRight="10%"
                    marginRightM="3%"
                  />
                ) : (
                  <Image
                    src={GuardadosW}
                    width="23px"
                    marginRight="10%"
                    marginRightM="3%"
                  />
                )}
                Guardado
              </SubtitleOne>
            </ContainerPerfiles>
          ) : null}
          {isWOColaborativo ? (
            <>
              {showMetas ? (
                <ContainerPerfiles
                  paddingM="5%"
                  filter={
                    Open.metas
                      ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                      : ""
                  }
                  onClick={handleClickMetas}
                  background={Open.metas ? "#fb245c" : "#ffff"}
                >
                  <SubtitleOne
                    className="roboto"
                    transform=" "
                    textalingnMobile="left"
                    fw={Open.metas ? "bold" : "400!important"}
                    textalingn="left"
                    mb="0%"
                    size="20px"
                    sizeMobile="18px"
                    color={Open.metas ? "#fff" : "#07210A"}
                  >
                    {!Open.metas ? (
                      <Image
                        src={MetasB}
                        width="30px"
                        marginRight="10%"
                        marginRightM="3%"
                      />
                    ) : (
                      <Image
                        src={MetasW}
                        width="30px"
                        marginRight="10%"
                        marginRightM="3%"
                      />
                    )}
                    Mis metas
                  </SubtitleOne>
                </ContainerPerfiles>
              ) : null}
              <ContainerPerfiles
                paddingM="5%"
                filter={
                  Open.invitaciones
                    ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                    : ""
                }
                onClick={handleClickInvitaciones}
                background={Open.invitaciones ? "#fb245c" : "#ffff"}
              >
                <SubtitleOne
                  className="roboto"
                  transform=" "
                  textalingnMobile="left"
                  fw={Open.invitaciones ? "bold" : "400!important"}
                  textalingn="left"
                  mb="0%"
                  size="20px"
                  sizeMobile="18px"
                  color={Open.invitaciones ? "#fff" : "#07210A"}
                >
                  {!Open.invitaciones ? (
                    <Image
                      src={InvitacionesB}
                      width="30px"
                      marginRight="10%"
                      marginRightM="3%"
                    />
                  ) : (
                    <Image
                      src={InvitacionesB}
                      width="30px"
                      marginRight="10%"
                      marginRightM="3%"
                    />
                  )}
                  Invitaciones
                </SubtitleOne>
              </ContainerPerfiles>
            </>
          ) : null}
          {solicitudesActive ? (
            <ContainerPerfiles
              paddingM="5%"
              filter={
                Open.solicitudes
                  ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                  : ""
              }
              onClick={handleClickSolicitudes}
              background={Open.solicitudes ? "#fb245c" : "#ffff"}
            >
              <SubtitleOne
                className="roboto"
                transform=" "
                textalingnMobile="left"
                fw={Open.solicitudes ? "bold" : "400!important"}
                textalingn="left"
                mb="0%"
                size="20px"
                sizeMobile="18px"
                color={Open.solicitudes ? "#fff" : "#07210A"}
              >
                {!Open.solicitudes ? (
                  <Image
                    src={SolicitudesB}
                    width="30px"
                    marginRight="10%"
                    marginRightM="3%"
                  />
                ) : (
                  <Image
                    src={SolicitudesB}
                    width="30px"
                    marginRight="10%"
                    marginRightM="3%"
                  />
                )}
                Solicitudes
              </SubtitleOne>
            </ContainerPerfiles>
          ) : null}
          <ContainerPerfiles
            paddingM="5%"
            filter={
              Open.score ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" : ""
            }
            onClick={handleClickScore}
            background={Open.score ? "#fb245c" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.score ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="18px"
              color={Open.score ? "#fff" : "#07210A"}
            >
              {!Open.score ? (
                <Image
                  src={ScoreB}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={ScoreW}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}
              Mi score
            </SubtitleOne>
          </ContainerPerfiles>
          <Fragment>
            <ContentHomeSection
              className="web"
              pos="relative"
              displayD="flex"
              leftD="0%"
              floatD="left"
              width="100%;"
            >
              <ContentHomeSection
                leftD="0%"
                heightD="100%"
                floatD="left"
                width="100%"
                widthM="100%"
              >
                {/* <ShareCommonFeed
                  url="feed"
                  trainingName="Logros"
                  textShare="¡Ayuda a que más personas conozcan Coru! Comparte en:"
                  btn={true}
                  mtop="0px"
                ></ShareCommonFeed> */}
                <ShareWin
                  bannerType="wo"
                  margin="20px auto 80px"
                  width="100%"
                  name="Logros"
                />
              </ContentHomeSection>
            </ContentHomeSection>
            <ContentHomeSection
              className="web"
              topD="20px"
              leftD="35px"
              floatD="left"
              width="80%;"
              widthM="100%"
            >
              <Kanda />
            </ContentHomeSection>
          </Fragment>
        </ContainerMenu>
        <ContainerMenu width="50%" padding="2rem 0px" margin=" " mt=" " mb=" ">
          {Open.resumen ? (
            <Recap Novisible={true} paddingInicio={true} />
          ) : Open.concluidos ? (
            <Completed
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
            />
          ) : Open.progreso ? (
            <Progreso
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
            />
          ) : Open.metas ? (
            <Goals
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
            ></Goals>
          ) : Open.invitaciones ? (
            <Invites
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
            ></Invites>
          ) : Open.guardados ? (
            <SavedProducts
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
              cardsByProvider={cardsByProvider}
            ></SavedProducts>
          ) : Open.score ? (
            <Score
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
            ></Score>
          ) : (
            <Request
              Novisible={true}
              paddingInicio={true}
              isViernes={isViernes}
            ></Request>
          )}
        </ContainerMenu>
        {widthBrowser < 760 ? (
          <Fragment>
            <ContentHomeSection
              topMm320="-70px"
              pos="relative"
              mbottom="100px"
              displayD="flex"
              leftD="0%"
              floatD="left"
              width="100%;"
            >
              <Rectangle24
                positionD="absolute"
                Ptop="125px"
                Pleft="-13%"
                background="#6BD5AF"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="70px"
                height="70px"
                transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="190px"
                Pleft="10%"
                background="#BFDD6A"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="30.94px"
                height="30.94px"
                transform="rotate(46.41deg)"
              />
              <Vector2
                width="50px"
                left="0"
                height="60px"
                background="#91D770"
                radius="40%"
                transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                positionD="absolute"
                Pleft="8%"
                Ptop="45px"
                displayM="none"
              />
              <Vector3
                position="absolute"
                left=" "
                top=" "
                Ptop="268px"
                Pleft="-2%"
                background=" #E3E660;"
                transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                width="65px"
                height="65px"
                displayD=" "
                displayM="none"
              />
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="10%;"
                widthM="0"
              ></ContentHomeSection>
              <ContentHomeSection leftD="0%" width="100%;" widthM="100%">
                <ContentHomeSection
                  className="mob"
                  widthM="100%;"
                  id="KandaFeed"
                >
                  <Kanda />
                </ContentHomeSection>
                <Rectangle24
                  positionD="absolute"
                  Ptop="145px"
                  Pleft="88%"
                  background="#BFDD6A"
                  displayM="none"
                  displayD=" "
                  left=" "
                  top=" "
                  width="30.94px"
                  height="30.94px"
                  transform="rotate(46.41deg)"
                />
                <Vector2
                  width="50px"
                  left="0"
                  height="60px"
                  background="#6BD5AF"
                  radius="40%"
                  transform="rotate(-8.34deg)"
                  positionD="absolute"
                  Pleft="83%"
                  Ptop="285px"
                  displayM="none"
                />
                <Vector3
                  position="absolute"
                  left=" "
                  top=" "
                  Ptop="45px"
                  Pleft="80%"
                  background="#E3E660"
                  transform="rotate(-15.06deg)"
                  width="50px"
                  height="50px"
                  displayD=" "
                  displayM="none"
                />
                <Rectangle24
                  positionD="absolute"
                  Pleft="94%"
                  displayD=""
                  width="60px"
                  height="60px"
                  left=" "
                  top=" "
                  background="#91D770"
                  transform="rotate(-17.24deg)"
                />
                <ShareWin
                  bannerType="mobile"
                  margin="20px auto 80px"
                  width="120%"
                  name="Logros"
                />
              </ContentHomeSection>
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="10%;"
                widthM="0"
              ></ContentHomeSection>
            </ContentHomeSection>
          </Fragment>
        ) : null}
        {Open.resumen ? (
          <Fragment>
            <ContainerBottons
              top="30px"
              widthD="100%"
              position=" "
              onClick={scrollToTop}
            >
              <Image
                src={CaretUp}
                width="20px"
                margin="auto"
                onClick={scrollToTop}
              />
            </ContainerBottons>
            <ContainerBottons
              top="2px"
              position=" "
              bottomD="0"
              bottomM="100px"
            >
              <Top_Text
                onClick={scrollToTop}
                style={{ cursor: "pointer" }}
                className="roboto"
              >
                Volver arriba &nbsp;&nbsp;
              </Top_Text>
            </ContainerBottons>
          </Fragment>
        ) : null}
      </Container>
      {OpenModal ? <ModalVirality openMod={handleOpenModal} /> : null}
    </Fragment>
  );
}
export default LogrosWeb;
