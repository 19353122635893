import React, { useState, Fragment, useEffect } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import CardOne from '../../components/Home/Card1Animate';

import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
  CREATE_SESSION_MYSQL,
  RECOVERY_TRAINING,
} from '../../mutations';
import {
  GETPERCENTAGETRAINING,
  GET_TRAINING_COLOR,
  GET_TRAINING_PREVIEW,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
  TrainigTam,
} from '../../assets/styles/Training.styled';
import Header from '../../components/common/Header';
import { Container, Col7 } from '../../components/common/common.styled';

// components
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import Starttraining from '../../components/Training/Starttraining';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import WOlayout from '../../components/layouts/WOlayout';
import RoutinesOrderWoScore from './RoutinesOrderWoScore';
import RoutinesOrderWoScoreReEntry from './RoutinesOrderWoScoreReEntry';
import '../../assets/styles/kanda/KandaDesktop.css';
import BarraScroll from '../BarraScroll';

const items2 = ['Conoce tu Score', ''];
const idTraining = 41;

const WoScore = () => {
  sessionStorage.setItem('idTraining', idTraining);
  const id = sessionStorage.getItem('idTraining');

  const [arrayOrderRoutines] = useState([]);
  const { actions } = GeneralHooks();
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(idTraining);
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [backWO, setBackWO] = useState(false);
  const [colorTraining, setColorTraining] = useState('');
  const [flagRev, setFlagRev] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();

  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  const anchop = window.screen.width;

  // mutations and queries
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(getTrainingStatus);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        if (percent === 100) setBackWO(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario_existente') {
          // validar que no este logueado

          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else {
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
            const currentOrderRoutine = sessionStorage.getItem(
              'currentOrderRoutine',
            );
            saveAdvance(parseInt(currentOrderRoutine));
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  /** guarda y actualiza el status del entrenamiento */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');
      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // useEffect
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    if (active1) {
      setactive2(true);
    }
  }, [active1]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);

  useEffect(() => {
    const email = sessionStorage.getItem('Email1');
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);

  // useeffect genericos para los entrenamientos
  // para obtener el porcentaje
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser, 10), idtrainig: currentTraining },
      });
    } else {
      setPercentage('0');
    }
  }, []);
  // Recovey de datos básicos
  const [recoveryTrainingF] = useMutation(RECOVERY_TRAINING, {
    onCompleted({ recoveryTraining }) {
      console.log("DATAAAAA ------------->  ", recoveryTraining);
      try {
        const json = JSON.parse(recoveryTraining.response);
        if (json["64"].responses.length !== 0) {
          // Nombre
          console.log(json["64"].responses);
          sessionStorage.setItem('FirstName', json["64"].responses[0].responseValue != "null" ? json["64"].responses[0].responseValue : '');
          sessionStorage.setItem('MiddleName', json["64"].responses[1].responseValue != "null" ? json["64"].responses[1].responseValue : '');
          sessionStorage.setItem('FathersLastName', json["64"].responses[2].responseValue != "null" ? json["64"].responses[2].responseValue : '');
          sessionStorage.setItem('MothersLastName', json["64"].responses[3].responseValue != "null" ? json["64"].responses[3].responseValue : '');
        }
        if (json["65"].responses.length !== 0) {
          // Fecha nacimiento
          const { responseValue } = json["65"].responses[0];
          if (responseValue) {
            let dateBorn = responseValue.substr(0, 10);
            dateBorn += ''.split('-')[0];
            sessionStorage.setItem('year', dateBorn.split('-')[0]);
            sessionStorage.setItem('month', dateBorn.split('-')[1]);
            sessionStorage.setItem('day', dateBorn.split('-')[2]);
          } else {
            sessionStorage.setItem('year', "");
            sessionStorage.setItem('month', "");
            sessionStorage.setItem('day', "");
          }
        }
        if (json["66"].responses.length !== 0) {
          // Contacto
          sessionStorage.setItem('Email1', json["66"].responses[0].responseValue);
          sessionStorage.setItem('CellPhone', json["66"].responses[1].responseValue);
        }
        setFlagRev(true);
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error en recovery', error);
      }
    },
    onError(err) {
      // eslint-disable-next-line
      console.log('Error en la mutacion del recovery: ', err.message);
    },
  });
  // use useEffect para obtener el deeplink o source, medium o capanign
  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('afores');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', pageName);
    sessionStorage.setItem('hostname', hostName);
    console.log(
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      source,
      medium,
      campaign,
      content,
    );
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
      recoveryTrainingF({
        variables: {
          idTraining: '41',
          idUser: String(idUser) || '',
          idCont: String(idCont) || '',
        },
      });
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setFlagRev(true);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
        viralityID,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);
  // FUNCIONES ENTRENAMIENTO
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      if (arrayOrderRoutines.length === 0 && orderRutine === 4) {
        arrayOrderRoutines.push(1, 2, 3, orderRutine);
      } else {
        arrayOrderRoutines.push(orderRoutine);
      }
    }
  };
  const createJsonInput = async (
    currentRoutine,
    questions,
    orderRutineDatBasics = '',
  ) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    sessionStorage.setItem(
      'currentOrderRoutine',
      orderRutineDatBasics !== '' ? orderRutineDatBasics : orderRutine,
    );
    pushArrayOrderRoutine(
      orderRutineDatBasics !== '' ? orderRutineDatBasics : orderRutine,
    );
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  const validAchievment = async () => {
    await actions({ action: 'getAchievment', variables: '' });
    if (sessionStorage.getItem('Achievment-41')) {
      const percent = sessionStorage.getItem('Achievment-41');
      if (percent === 100 || percent === '100') {
        setBackWO(true);
      }
    };
  };
  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    if (!arrayOrderRoutines.includes(1) && arrayOrderRoutines.length === 1) {
      arrayOrderRoutines.unshift(1, 2, 3, 4, 5);
    }
    const stringOrderRoutines = arrayOrderRoutines.join(',');
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: stringOrderRoutines,
      },
    };
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    // const timestamp = today;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };

  const [WOBigImage, setWOBigImage] = useState('');
  useEffect(() => {
    validAchievment();
    getTrainingPreview({
      variables: {
        Id: localStorage.getItem('idTraining') || '41',
      },
    });
  }, []);

  const [getTrainingPreview] = useLazyQuery(GET_TRAINING_PREVIEW, {
    onCompleted: ({ getTrainingPreview }) => {
      console.log(
        `Esta---->${getTrainingPreview.response.training.UrlBigImage}`,
      );
      setWOBigImage(getTrainingPreview.response.training.UrlBigImage);
    },
  });
  const imgWo = WOBigImage;
  // console.log(`IMAGEN WO    ${imgWo}`);

  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: '¿Por qué no te aprueban el crédito?',
    time: '5 minutos',
    imgWo: { imgWo },
  };
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '45px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '45px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);
  return (
    <Fragment>
      <WOlayout
        backgroundColor={colorTraining}
        pathname={window.location.pathname}
        headerType="HeaderTwo"
      >
        <Helmet>
          <title>Cómo Checar mi Score de Crédito | Coru</title>
          <link rel="canonical" href="https://coru.com/wo/know-your-score" />
          <meta name="keywords" content="como checar mi score de credito" />
          <meta
            name="description"
            content="Si quieres saber qué es el Score de Crédito y cuánto tienes que tener para algunos créditos, entonces este es el lugar. Te toma 10 minutos."
          />
        </Helmet>
        <TrainingMain>
          <Fragment>
            <TrainingAnimateFunnel
            bcolor={colorTraining}
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <TrainigTam>
            <Header
              background={'#917AFC'}
              pathname={window.location.pathname}
            />
          </TrainigTam>
          <BarraInfoWO propied={propied} />
          {scroll1 > 0 ? <BarraScroll /> : null}
          <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
          {percentage !== '' ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt=""
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
            </TrainingAnimateFunnel>
            <ModalDataPersonalInitWO isData={isData} setData={setisData} />
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Fragment>
                  <DataPersonalInitWO />
                  <TrainingSectionGray
                    wiw="100%"
                    mw="auto"
                    back="#FAFAFA"
                    mtop="0"
                  >
                    <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                      {orderRutine !== 'rp' ? (
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            classAna="trueAna"
                            title="¡Hola! Soy Ana, tu coach financiero."
                            text="En este entrenamiento podrás conocer la situación  de tu historial en buró de crédito."
                            text2="Te ayudaremos a utilizarlo a tu favor de acuerdo al objetivo que tengas en este momento"
                          />
                        </div>) : null}
                      {backWO ? (
                        <div className="marginComentLgSuccess1">
                        <CardOne
                          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                          classCard="cardOne"
                          classTop="topOne1"
                          text="A continuación, te ayudaré a consultar de nuevo tu Score Crediticio."
                          text2="Pero antes, por favor, confirma que tus datos registrados anteriormente sean correctos."
                        />
                        </div>
                      ) : null}
                      <Fragment>
                        {flagRev ? (
                          <div
                              className={
                              orderRutine === 3
                                ? 'marginComentLgSuccess1'
                                : 'marginComentLgSuccess1'
                            }
                          >
                            <TrainingSectionGray
                              wiw="100%"
                              mw="auto"
                              back="#FAFAFA"
                              mtop="0"
                            >
                              <TrainingDivSection2
                                paddiw="0"
                                paddi="0"
                                back="#FAFAFA"
                              >
                              {backWO ? (
                                <RoutinesOrderWoScoreReEntry
                                  Date={stateDate}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  trainingColor={colorTraining}
                                  backWO={backWO}
                                />) : <RoutinesOrderWoScore
                                  Date={stateDate}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  trainingColor={colorTraining}
                                  backWO={backWO}
                                />
                              }
                              </TrainingDivSection2>
                            </TrainingSectionGray>
                          </div>
                            ) : null}
                      </Fragment>
                    </TrainingDivSection2>
                  </TrainingSectionGray>
                  {!active2 ? (
                    <Fragment>
                      <TrainingSectionGray
                        wiw="670px"
                        mw="auto"
                        back="#FAFAFA"
                        mtop="0"
                      >
                        <Container
                          width="80%"
                          mt="0rem"
                          widthMobile="90%"
                          mtMobile="0rem"
                        >
                          <ImageCoachInicio topMobile="35px" />
                          <Col7 padding=" " className="cardPrincipal1">
                            <LoadingWo />
                          </Col7>
                        </Container>
                      </TrainingSectionGray>
                    </Fragment>
                  ) : null}
                </Fragment>
              </div>
              <div
                style={{ position: 'absolute', top: '1em', right: '3em' }}
                id="kandaDesktop"
              ></div>
            </div>
          </Fragment>
        </TrainingMain>
      </WOlayout>
    </Fragment>
  );
};

export default WoScore;
