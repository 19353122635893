import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { Image } from '../../assets/styles/Home.styled';
import {
  Container1,
  Rombo,
  ContainerBottons,
  Rectangle20,
  SubTexto,
  VectorNum,
  ContentHomeSection,
  Shapes,
  Rectangle23,
  Rectangle25,
  Rectangle24,
  Vector1,
  Subtitle,
  Title2,
  Subtitle2,
  Rectangle13,
  Num,
  TextRectangle13,
} from '../../components/common/Home.styled';
import {
  Button,
  Container,
  Col6,
} from '../../components/common/common.styled';

import Chica from '../../assets/img/anacoach/ChicaEntrena.png';
import GrayShapes from '../../assets/img/anacoach/GrayShapes.png';
import WOlayout from '../../components/layouts/WOlayout';
// Modal
import ClickButton from '../../Helpers/HookHelpers';
import { Helmet } from 'react-helmet';

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function LpXCoach({ location }) {
  const { actions } = ClickButton();
  console.log('fijate aqui');
  console.log(process.env.REACT_APP_SERVERTYPE);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);

  function shareCoru(e) {
    e.preventDefault();
    const coruRedirectURL = 'https://coru.com/';
    const whatsappMessage = `¡Hola! Quiero iniciar a trabajar con un Coach Financiero. ${coruRedirectURL}`;
    window.open(
      `https://api.whatsapp.com/send/?phone=5215541693124&text=${encodeURI(
        whatsappMessage,
      )}`,
      'popup',
      'width=600',
      'height=600',
    );
    const click = 'EntrenarAhora_XCoach';
    actions({ action: 'ClickButtonCollection', variables: click });
  }

  const [heigthBrowser, setheigthBrowser] = useState(window.outerHeight);
  useEffect(() => {
    setheigthBrowser(window.outerHeight);
  }, [window.outerHeight]);

  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Helmet>
        <title>Tu Coach Financiero | CORU</title>
      </Helmet>
      <Container1>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="555px"
          height="373px"
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Rectangle23
                Ptop="100px"
                Pleft="80%"
                position="absolute"
                displayD=" "
                displayM="none"
              />
              <Rectangle25
                positionD="absolute"
                left="63%"
                top="107px"
                displayM="none"
                displayD=" "
              />
              <Rectangle24
                positionD="absolute"
                Ptop="120px"
                Pleft="97%"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
              />
              <Vector1
                positionD="absolute"
                Pleft="57%"
                Ptop="2.9%"
                displayM="none"
                displayD=" "
              />
              <Title2
                sizeD="50px"
                top={heigthBrowser >= 550 ? '100px' : '45px'}
                topD="15%"
                className="roboto"
              >
                Llegó el momento de {window.innerWidth <= 550 ? '' : <br />}{' '}
                entrenar tus finanzas
              </Title2>

              <div className="d-flex flex-column">
                <div className="p-2">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="5%"
                  >
                    ¿Estás listo para alcanzar tus metas financieras? <br />
                    Empieza a entrenar con un coach financiero hoy mismo
                  </Subtitle>
                </div>
              </div>
              <ContainerBottons
                position=" "
                floatD="left"
                displayD="block"
                displayM="none"
                onClick={(e) => shareCoru(e)}
              >
                <Button className="roboto" onClick={(e) => shareCoru(e)}>
                  Entrenar ahora
                </Button>
              </ContainerBottons>
              <Rombo
                displayD=" "
                displayM="none"
                marginleft="50%"
                margintop="0%"
              />
              <Rectangle20 displayD=" " displayM="none" marginleft="103%" />
              <Rectangle23 top="-125%" displayD="none" displayM="block" />
              <Rectangle25 displayD="none" displayM="block" />
              <Rectangle24 displayD="none" displayM="block" />
            </ContentHomeSection>
          </Container>
          <ContentHomeSection
            leftD="0px"
            floatD="right"
            rigthD="0%"
            topD="-485px"
            width="40%"
            displayD=" "
            displayM="none"
          >
            <Shapes
              id="Shapes-medio"
              positionD=" "
              position="absolute"
              zindex="-1"
            >
              <Image
                src={Chica}
                maxwidth="430px"
                MaxWidth="14%"
                MaxWidthX="12%"
                width="20%"
                position="absolute"
                left="0%"
                top="4%"
                displayMobile="none"
              />
            </Shapes>
          </ContentHomeSection>
        </Shapes>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Button className="d-block roboto" onClick={(e) => shareCoru(e)}>
                Entrenar ahora
              </Button>
            </ContainerBottons>
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Image
                src={Chica}
                maxwidth="430px"
                margin="auto"
                width="100%"
                position=""
                left="0%"
                top="0%"
                displayMobile="block"
                display="none"
              />
            </ContainerBottons>
          </div>
        </div>
        <Rombo displayD="none" displayM="block" />
        <Rectangle20 displayD="none" displayM="block" />
        <Shapes
          id="Shapes2"
          key={2}
          top=" "
          left=" "
          heightD=" 350px"
          height={heigthBrowser >= 600 ? '300px' : '480px'}
          width="100%"
          position=" "
          background="#FAFAFA"
        >
          <ContentHomeSection
            leftD=" "
            floatD=" "
            displayD="none"
            displayM="block"
          >
            <Title2
              top={heigthBrowser >= 550 ? '85px' : '45px'}
              topD="15%"
              className="roboto"
            >
              {' '}
              En Coru sabemos que...{' '}
            </Title2>
            <Subtitle2 className="roboto" bottom="17px" top="10px">
              Tomar las riendas de tus finanzas personales no siempre es fácil,
              por lo que contamos con un equipo de expertos en coaching
              financiero.
            </Subtitle2>
            <Subtitle2 className="roboto" bottom="17px" top="10px">
              ¿Sabías que en México <b>9 de cada 10</b> personas declara sufrir
              estrés financiero? Por esta razón, en Coru trabajamos todos los
              días para asegurar que las personas cuentes con las
              herramientasnecesarias para eliminarlo.
            </Subtitle2>
          </ContentHomeSection>
          <div style={{ width: '100%' }}>
            <Container
              width="80%"
              mt="0rem"
              widthMobile="100%"
              mb="0%"
              mtMobile="0rem"
            >
              <Col6 pt="0px" flex="0 0 100%" maxwidth="100%">
                <Image
                  src={GrayShapes}
                  maxwidth="430px"
                  width="30%"
                  position="absolute"
                  left="-12%"
                  top="-2%"
                  displayMobile="none"
                />
              </Col6>
              <Col6 pt="0px" display="flex" displayM="none">
                <div style={{ margin: 'auto' }}>
                  <Title2 top="46px" className="roboto" topD=" ">
                    En Coru sabemos que...{' '}
                  </Title2>
                  <Subtitle2 className="roboto" bottom="17px" top="10px">
                    Tomar las riendas de tus finanzas personales no siempre es
                    fácil, por lo que contamos con un equipo de expertos en
                    coaching financiero.
                  </Subtitle2>
                  <Subtitle2 className="roboto" bottom="17px" top="10px">
                    ¿Sabías que en México <b>9 de cada 10</b> personas declara
                    sufrir estrés financiero? Por esta razón, en Coru trabajamos
                    todos los días para asegurar que las personas cuentes con
                    las herramientasnecesarias para eliminarlo.
                  </Subtitle2>
                </div>
              </Col6>
            </Container>
          </div>
        </Shapes>
        <Shapes
          id="Aliados"
          top=" "
          left=" "
          heightD="auto"
          height=" "
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <Col6
              pt="0px"
              flex="0 0 100%"
              maxwidth="100%"
              display="inline!important"
              displayM="block"
            >
              <div style={{ margin: 'auto' }}>
                <Title2
                  top={heigthBrowser >= 550 ? '85px' : '45px'}
                  topD="15%"
                  className="roboto"
                >
                  Con ayuda de nuestros expertos podrás:
                </Title2>
                <Vector1
                  width="80px"
                  height="80px"
                  positionD="relative"
                  Pleft="5%"
                  Ptop="2.9%"
                  displayM="none"
                  displayD=" "
                />
                <Rectangle25
                  width="160px"
                  height="160px"
                  positionD="absolute"
                  left="-4%"
                  top="initial"
                  displayM="none"
                  displayD=" "
                />
                <Rectangle24
                  width="140px"
                  height="140px"
                  positionD="relative"
                  Ptop="160px"
                  Pleft="-5%"
                  displayM="none"
                  displayD=" "
                  left=" "
                  top=" "
                />
              </div>
            </Col6>
            <Col6
              id="CarruselEmpresasContent"
              pt="0px"
              flex="0 0 100%"
              maxwidth="100%"
              display="flex"
            >
              <div style={{ width: '100%' }}>
                <Container
                  width="100%"
                  mt="0rem"
                  widthMobile="100%"
                  mb="0%"
                  mtMobile="0rem"
                >
                  <Rectangle13 bottomM="10px">
                    <VectorNum>
                      <Num className="roboto">1</Num>
                    </VectorNum>
                    <TextRectangle13 display="block" className="roboto">
                      <b>Conocer las estrategias</b> necesarias para ordenar tus
                      finanzas personales.
                    </TextRectangle13>
                  </Rectangle13>
                  <Rectangle13 top="20px" bottomM="10px">
                    <VectorNum back="#BFDD6A">
                      <Num className="roboto">2</Num>
                    </VectorNum>
                    <TextRectangle13 display="block" className="roboto">
                      <b>Alcanzar tus metas</b> financieras con la planificación
                      adecuada.
                    </TextRectangle13>
                  </Rectangle13>
                  <Rectangle13 top="20px" bottomM="10px">
                    <VectorNum back="#E3E660">
                      <Num className="roboto">3</Num>
                    </VectorNum>
                    <TextRectangle13
                      display="block"
                      top="569px"
                      className="roboto"
                    >
                      <b>Administrar de manera óptima</b> tus ingresos, y no
                      perderle la pista a tus gastos.
                    </TextRectangle13>
                  </Rectangle13>
                  <Rectangle13 top="20px" bottomM="10px">
                    <VectorNum>
                      <Num className="roboto">4</Num>
                    </VectorNum>
                    <TextRectangle13
                      display="block"
                      top="569px"
                      className="roboto"
                    >
                      <b>Aprender sobre herramientas</b> financieras para
                      establecer metas claras.
                    </TextRectangle13>
                  </Rectangle13>
                  <Rectangle13 top="20px" bottomM="10px">
                    <VectorNum back="#BFDD6A">
                      <Num className="roboto">5</Num>
                    </VectorNum>
                    <TextRectangle13
                      display="block"
                      top="569px"
                      className="roboto"
                    >
                      <b>Adquirir mayor seguridad</b> para tomar decisiones
                      financieras importantes.
                    </TextRectangle13>
                  </Rectangle13>
                  <Rectangle13 top="20px" bottomM="10px">
                    <VectorNum back="#E3E660">
                      <Num className="roboto">6</Num>
                    </VectorNum>
                    <TextRectangle13
                      display="block"
                      top="569px"
                      className="roboto"
                    >
                      Y lo más importante <b>¡reducir tu estrés financiero!</b>
                    </TextRectangle13>
                  </Rectangle13>
                </Container>
              </div>
            </Col6>
          </Container>
        </Shapes>
        <Shapes
          top=" "
          left=" "
          heightD="auto"
          height=" "
          width="100%"
          position=" "
        >
          <div className="d-flex flex-column ">
            <div className="p-2">
              <ContentHomeSection
                leftD="10% "
                rigthD="10%"
                floatD=" "
                width="80%;"
                displayD=" "
                displayM=" "
              >
                <Title2
                  top={heigthBrowser >= 550 ? '85px' : '45px'}
                  topD="15%"
                  className="roboto"
                  aling="center"
                >
                  Tu coach te ayudará a establecer una rutina oportuna y
                  realista
                </Title2>
                <SubTexto
                  heightD="30px"
                  alingD="center"
                  height="40px"
                  bottom="0px"
                  className="roboto"
                >
                  La mejor parte de todo, es que será en línea ¡y completamente
                  gratis!
                </SubTexto>
              </ContentHomeSection>
            </div>
          </div>
        </Shapes>
        <ContainerBottons top="20px" position=" " bottomM="80px">
          <Button
            className="d-block mt-5 mt-md-4 roboto"
            onClick={(e) => shareCoru(e)}
          >
            Empezar ahora
          </Button>
        </ContainerBottons>
      </Container1>
      {/* <Whatsapp url={""} id="Whatsapp"/> */}
    </WOlayout>
  );
}

export default LpXCoach;
