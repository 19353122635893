/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// Import visual components
import TagManager from 'react-gtm-module';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TrainingQuestion,
  QuestionsDivs,
  QuestionsText,
  Images,
  TrainingText,
  TrainingSubtitles,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import SectionVideo from '../../components/common/SectionVideo';
import ana from '../../assets/img/anacoach/CoachImage.png';
// Import query components and helpers
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';

// Other components
import InputsOrder from '../../components/common/InputsOrder';
import Recommendations from '../../components/Training/RecommendedTraining';
import ShareDebts from '../Training/SectionShareDebts';
import ModalDiv from '../../components/common/ModalDiv';
import ModalRespConSinPremio from '../../components/Feed/ModalRespConSinPremio';
// Other banks
import RpDebs from './RpDebs';

const SinAtrasoEnPagoDeuda = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [capacidadDePago, setCapacidadDePago] = useState(
    sessionStorage.getItem('CapacidadDePago') || '',
  );
  const [videoOne] = useState(false);
  const [idealSolutions, setIdealSolutions] = useState(
    sessionStorage.getItem('idealSolutions') || '',
  );
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
    switch (step) {
      case 0:
        if (value === 'No') validateOpcSelected();
        setCapacidadDePago(value);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuestios2SinAtraso' });
        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setShow(false);
  };

  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const showModalWhatsapp = () => {
    setShowWhatsapp(true);
  };
  const handleCloseModalWhatsapp = () => {
    setShowWhatsapp(false);
  };

  const [showEmail, setShowEmail] = useState(false);
  const showModalEmail = () => {
    setShowEmail(true);
  };
  const handleCloseModalEmail = () => {
    setShowEmail(false);
  };

  const [showPhone, setShowPhone] = useState(false);
  const showModalPhone = () => {
    setShowPhone(true);
  };
  const handleCloseModalPhone = () => {
    setShowPhone(false);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setCapacidadDePago(sessionStorage.getItem('CapacidadDePago') || '');
      const cpacidadDePagoView = sessionStorage.getItem('CapacidadDePago') || '';
      if (cpacidadDePagoView === 'No') validateOpcSelected();
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    if (videoOne) validateOpcSelected();
  }, [videoOne]);

  const validateOpcSelected = () => {
    if (sessionStorage.getItem('MontoDeudas') === 'Menos de $30,000') {
      // setOpc2(true);
    } else {
      // setOpc1(true);
      // setOpc2(true);
    }
  };
  const handleChangeSolutions = (opc) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ChangeSolution',
      },
    };
    setIdealSolutions(opc);
    sessionStorage.setItem('idealSolutions', opc);
    TagManager.dataLayer(tagManagerArgs);
    gsap.to(window, { duration: 1, scrollTo: '#activeQuestion3SinAtraso' });
  };

  useEffect(() => {
    if (videoOne) validateOpcSelected();
  }, [videoOne]);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll && capacidadDePago === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeMantenerPagos' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeMantenerPagos"></div>
      <CardOne
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
        hora={hora}
      />
      <CardOne
        classCard="cardOne"
        classTop="topOne1"
        text="¿Serías capaz de mantener tus pagos al corriente en el futuro?"
        hora={hora}
      />
      <TrainingQuestion className={capacidadDePago === '' ? '' : 'hidden'}>
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={orderRutine}
          step="0-0"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuestios2SinAtraso"></div>
      <CardTwo
        classCard={capacidadDePago !== '' ? 'cardTwoResp' : 'hidden'}
        classTop="topTwo1"
        imgMan="hidden"
        text={capacidadDePago}
        colorTraining={trainingColor}
      />

      {/** OPCIONES RP* */}
      <CardOne
        classCard={capacidadDePago === 'Sí' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="Encontré dos soluciones ideales para tí. Elige la opción que mejor se acomode a tus necesidades."
        hora={hora}
      />
      <TrainingQuestion
        className={
          capacidadDePago === 'Sí' && idealSolutions === '' ? '' : 'hidden'
        }
      >
        <QuestionsDivs
          onClick={() => handleChangeSolutions('Consolidación de deuda')}
          width="80%"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            Consolidación de deuda
          </QuestionsText>
        </QuestionsDivs>
        <QuestionsDivs
          onClick={() => handleChangeSolutions('Plan rápido para ahorrar dinero')
          }
          width="80%"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            Plan rápido para ahorrar dinero
          </QuestionsText>
        </QuestionsDivs>
      </TrainingQuestion>
      {/* cuando resonde no */}
      <CardOne
        classCard={capacidadDePago === 'No' ? 'cardOne' : 'hidden'}
        classTop="topOne1"
        text="He encontrado tres soluciones ideales para ti. Elige la opción que mejor se acomode a tus necesidades"
        hora={hora}
      />
      <TrainingQuestion
        className={
          capacidadDePago === 'No' && idealSolutions === '' ? '' : 'hidden'
        }
      >
        <QuestionsDivs
          onClick={() => handleChangeSolutions('Quiero una prórroga en mis pagos')
          }
          width="80%"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            Quiero una prórroga en mis pagos
          </QuestionsText>
        </QuestionsDivs>
        <QuestionsDivs
          onClick={() => handleChangeSolutions(
            'Quiero organizar mis gastos para pagar cuanto antes',
          )
          }
          width="80%"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            Quiero organizar mis gastos para pagar cuanto antes
          </QuestionsText>
        </QuestionsDivs>
        <QuestionsDivs
          onClick={() => handleChangeSolutions('Consolidación de deuda')}
          width="80%"
          margin="8px auto auto;"
          hei="auto"
          bs="0px 2px 8px rgba(0,0,0,0.08)"
        >
          <QuestionsText size="14px" weight="400">
            Consolidación de deuda
          </QuestionsText>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeQuestion3SinAtraso"></div>
      <CardTwo
        classCard={
          capacidadDePago !== '' && idealSolutions !== ''
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={idealSolutions}
        colorTraining={trainingColor}
      />

      <TrainingDivSection
        className={
          capacidadDePago !== ''
          && idealSolutions === 'Plan rápido para ahorrar dinero'
            ? ''
            : 'hidden'
        }
        bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
        mt="44px"
        mtw="40px"
        br="4px"
        padding="21px 20px"
        wi="335px"
        wiw="670px"
        he="auto"
        mb="66px"
      >
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          Aquí un plan rápido para comenzar a ahorrar dinero en tu rutina
          diaria.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          1. Planifica tus gastos antes de empezar cada día.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          2. Anota tus movimientos de dinero, en especial todo lo que gastes.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          3. Abre una cuenta de ahorro aparte, o decide dónde vas a poner el
          dinero que es exclusivamente para ahorro.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          4. Paga tus deudas más caras, las que cuestan más intereses.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          5. Consigue rebajas y ofertas para tus consumos habituales.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          6. Recorta gastos innecesarios, ¿qué puedes dejar de comprar sin que
          pase nada malo en tu vida?
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          7. Ahorra en servicios, domicilia todos los servicios.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="center"
          alignweb="center"
          ls="-0.2px"
        >
          <b>Te proponemos una ¡Dieta de ahorro!</b>
        </TrainingText>
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="1-1"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
          opcVideo="?start=35"
        />
      </TrainingDivSection>

      <TrainingDivSection
        className={
          capacidadDePago !== ''
          && idealSolutions
            === 'Quiero organizar mis gastos para pagar cuanto antes'
            ? ''
            : 'hidden'
        }
        bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
        mt="44px"
        mtw="40px"
        br="4px"
        padding="21px 20px"
        wi="335px"
        wiw="670px"
        he="auto"
        mb="66px"
      >
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          Te proponemos una ¡dieta de ahorro!
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          Aquí un plan rápido para comenzar a ahorrar dinero en tu rutina
          diaria.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          1. Planifica tus gastos antes de empezar cada día.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          2. Anota tus movimientos de dinero, en especial todo lo que gastes.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          3. Abre una cuenta de ahorro aparte, o decide dónde vas a poner el
          dinero que es exclusivamente para ahorro.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          4. Paga tus deudas más caras, las que cuestan más intereses.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          5. Consigue rebajas y ofertas para tus consumos habituales.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          6. Recorta gastos innecesarios, ¿qué puedes dejar de comprar sin que
          pase nada malo en tu vida?
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          7. Ahorra en servicios, domicilia todos los servicios básicos, así
          identificarás cuáles son realmente importantes y no olvidarás
          pagarlos.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          8. Lo ideal es tener dinero equivalente a 3 meses de sueldo ahorrando
          para imprevistos.
        </TrainingText>
        <TrainingText
          size="16px"
          className="roboto"
          align="left"
          alignweb="left"
          ls="-0.2px"
        >
          Aquí hay 5 consejos infalibles para ahorrar más y mejor.
        </TrainingText>
        <SectionVideo title="">
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
            opcVideo="?start=35"
          />
          <br />
        </SectionVideo>
      </TrainingDivSection>

      {capacidadDePago !== ''
      && idealSolutions === 'Quiero una prórroga en mis pagos'
      && nextOrderRoutine === 'rp' ? (
        <TrainingQuestion>
          <SectionVideo title="" benefits={['']}>
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              Una <b>reestructuración</b> de deuda o <b>prórroga</b> significa
              negociar las condiciones del crédito a tu favor directamente con
              el banco o financiera, ya sea para pagar en plazos más largos o
              con tasas de interés menores.
            </TrainingText>
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              Con esta opción puedes mantener una buena calificación en tu
              historial crediticio si cumples con las nuevas condiciones de pago
              acordadas.
            </TrainingText>
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              ¡No te comprometas a un plan de pagos que no podrás cumplir!,
              valora tus propiedades y tu situación real antes de decidir qué te
              conviene más: si pagar menos dinero pero por más tiempo, o bien
              pagos más pesados pero por un periodo corto.
            </TrainingText>
            <TrainingText
              size="16px"
              className="roboto"
              align="left"
              alignweb="left"
              ls="-0.2px"
            >
              ¡Puedes ver más tips para reestructurar tu deusa con esta Youtuber
              especialista!:
            </TrainingText>
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="3-3"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
            <br />
            <br />
          </SectionVideo>
        </TrainingQuestion>
        ) : null}
      {capacidadDePago !== ''
      && idealSolutions === 'Quiero una prórroga en mis pagos'
      && nextOrderRoutine === 'rp' ? (
        <RpDebs
          Date={Date}
          hora={hora}
          currentTraining={currentTraining}
          insuranceActive={[
            'RESUELVE TU DEUDA',
            'BANORTE Se pone en tu lugar',
            'BBVA Aplaza tus pagos',
            'BANREGIO Apoyos',
          ]}
        />
        ) : null}
      <CardOne
        classCard={
          capacidadDePago !== ''
          && idealSolutions === 'Consolidación de deuda'
          && nextOrderRoutine === 'rp'
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        textbold="Consolidar deuda"
        text="Significa agrupar diversas deudas en una sola, que te ofrece mejores condiciones y además te olvidas de tener varias fechas de pago en distintos sitios."
        text2="Esta opción también es recomendable cuando quieres pasar las deudas de varias tarjetas de crédito a un solo plástico y conseguir una tasa de interés menor a la que tienes."
        hora={hora}
      />
      {capacidadDePago !== ''
      && idealSolutions === 'Consolidación de deuda'
      && nextOrderRoutine === 'rp' ? (
        <RpDebs
          Date={Date}
          hora={hora}
          currentTraining={currentTraining}
          insuranceActive={[
            'RESUELVE TU DEUDA',
            'YOTEPRESTO',
            'Tarjeta de Crédito HSBC transferible deudas',
            'Tarjeta de Crédito CITIBANAMEX reduce o liquida saldos',
            'PRESTANOMICO',
            'Tarjeta de Crédito BANORTE transfiere deudas',
          ]}
        />
        ) : null}
      {/* modal */}

      {recomendaciones
      && nextOrderRoutine === 'rp'
      && idealSolutions !== ''
      && capacidadDePago !== '' ? (
        <Feed_Main width="100%" background=" ">
          <Feed_Main width="100%" display=" " mtop=" " background="transparent">
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="¿Aún quieres ir por más?"
              text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
            />
            <CardOne
              classCard="cardOne"
              classTop="topOne1"
              text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
            />
            <Feed_Section padding="0 0 5% 0" paddingm="0% 5% 5% 5%">
              <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                <Recommendations items={recommendedTraining}></Recommendations>
              </ContentHomeSection>
            </Feed_Section>
          </Feed_Main>
        </Feed_Main>
        ) : null}
      {recomendaciones
      && nextOrderRoutine === 'rp'
      && idealSolutions !== ''
      && capacidadDePago !== '' ? (
        <ShareDebts></ShareDebts>
        ) : null}
      <ModalDiv show={show} handleClose={handleCloseModal}>
        <TrainingSubtitles>{`¡Hola ${sessionStorage.getItem(
          'FirstName',
        )}!`}</TrainingSubtitles>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¿Nos puedes indicar por qué medio te gustaría que nos pusieramos en
          contacto contigo?
        </TrainingText>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          <span
            onClick={(e) => {
              showModalWhatsapp(e);
            }}
          >
            whatsapp
          </span>
          ----
          <span
            onClick={(e) => {
              showModalEmail(e);
            }}
          >
            E-mail
          </span>
          ----
          <span
            onClick={(e) => {
              showModalPhone(e);
            }}
          >
            Phone
          </span>
        </TrainingText>
      </ModalDiv>

      <ModalDiv show={showWhatsapp} handleClose={handleCloseModalWhatsapp}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showWhatsapp} handleClose={handleCloseModalWhatsapp}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showEmail} handleClose={handleCloseModalEmail}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Dejános tu E-mail, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalDiv show={showPhone} handleClose={handleCloseModalPhone}>
        <Images
          src={ana}
          top="0px"
          topweb="0%"
          left="25%"
          leftw="25%"
          position="initial"
          positionw="initial"
          border="2px solid #68CDC7"
          bs="border-box"
          padd="5px"
          br="45px"
          className="img-fluid"
        ></Images>
        <TrainingText
          size="12px"
          className="cabin"
          align="justify"
          alignweb="justify"
          ls="-0.2px"
        >
          ¡Déjanos tu teléfono, nosotros te contactaremos a la brevedad!
        </TrainingText>
      </ModalDiv>
      <ModalRespConSinPremio
        show={sessionStorage.getItem('medium') === 'recompensas'}
        premio={
          sessionStorage.getItem('campaign') === 'con-premio'
        }
      />
    </div>
  );
};

export default SinAtrasoEnPagoDeuda;
