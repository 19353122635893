import React from 'react';

const TypingResult = ({ typing }) => (
  <div className="row mt-2 container-typing">
    <div className="col-12">
      <h1 className="font-weight-bold vertical-center">{typing}</h1>
    </div>
  </div>
);
export default TypingResult;
