import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  CookiesNoticeFragment,
  CookiesNoticeContainer,
  CookiesNoticeImage,
  CookiesNoticeText,
  CookiesNoticeAcceptButton,
  CookiesNoticeColumn,
} from './Home.styled';
import Cookie from '../../assets/img/anacoach/Cookie.svg';
import { CREATE_CLICK } from '../../mutations';
import ClickButton from '../../Helpers/HookHelpers';

const CookiesNotice = () => {
  const [display, setDisplay] = useState('');
  const history = useHistory();
  const { actions } = ClickButton();

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });

  const togglerDisplay = (_) => {
    actions({ action: 'ClickButtonCoruHome', variables: 'AceptarCookies'});
    if (display === '') {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickAcceptedCookies',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      };
      if (sessionStorage.getItem('id_session')) createClickFront({ variables: createClickInput });
      setDisplay('none');
    } else {
      setDisplay('');
    }
  };

  const handleAqui = () => {
    sessionStorage.setItem('goToCookies', 'true');
    history.push('/privacy-notice');
  };

  return (
    <CookiesNoticeFragment display={display}>
      <CookiesNoticeContainer>
        <CookiesNoticeImage src={Cookie} displayDesk="block" displayM="none" />
        <CookiesNoticeText>
          <span>
            Coru usa cookies para mejorar tu experiencia en nuestro sitio,
            analizar el tráfico, funciones de redes sociales y personalizar el
            contenido y los anuncios de acuerdo a tus intereses. Además,
            compartimos información sobre el uso que hagas del sitio web con
            nuestros socios comerciales, quienes pueden combinarla con otra
            información que hayan recopilado a partir del uso que hayas hecho de
            sus servicios. Más información{' '}
            <span
              onClick={() => handleAqui()}
              style={{ color: '#EC407A', cursor: 'pointer' }}
            >
              aquí
            </span>
            .
          </span>
        </CookiesNoticeText>
        <CookiesNoticeColumn>
          <CookiesNoticeImage
            src={Cookie}
            displayDesk="none"
            displayM="block"
          />
          <CookiesNoticeAcceptButton onClick={() => togglerDisplay()}>
            Aceptar cookies
          </CookiesNoticeAcceptButton>
        </CookiesNoticeColumn>
      </CookiesNoticeContainer>
    </CookiesNoticeFragment>
  );
};

export default CookiesNotice;
