/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { CREATE_USER, DYNAMIC_UPDATE } from "../../mutations";
import WOlayout from '../../components/layouts/WOlayout';
import {
  TrainingMain,
  TrainingSubtitles,
  TrainingText,
  TrainingAnimateFunnel,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  Images,
  TrainingContainAna,
  TrainigTam,
  TrainingLine,
  CoruCoach,
} from '../../assets/styles/Training.styled';
import {
  Button,
  SubtitleOne,
  Main,
  ParagrapTwo,
  Col11,
  Container,
  ContentGeneric,
} from "../../components/common/common.styled";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import "../../assets/styles/TextCustom.css";
import InputAna from "../../components/common/InputAna";
import Aceptterms from "../../components/common/Acceptterms";
import NameComplet from "./NameComplet";
import Contacto from "./Contacto";
import Contrasena from "./Contrasena";
import { Text } from "../../assets/styles/kanda/KandaContent.style";
import Modallogin from "./Login/partials/Modallogin";
import ModalSuccessRegister from "../../components/common/ModalSuccessRegister";

const Registro = ({
  isModal = false, setTypeModal, nextRoutine,
}) => {
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    secondname: "",
    motherslastname: "",
    email: "",
    numPhone: "",
    lada: "México lada +(52)",
    textlada: "México lada +(52)",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
  });
  const [validCampos, setvalidCampos] = useState({
    personales: false,
    contacto: false,
    password: false,
  });
  const [disabledbtn, setdisabledbtn] = useState(true);
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      const { message, response } = dynamicUpdate;
      console.table(message, response);
    },
  });

  const [open, setOpen] = useState(false);
  const [openModalSuccess, setopenModalSuccess] = useState(false);
  const [childrend, setChildrend] = useState("");
  const [textBtnPrincipal, settextBtnPrincipal] = useState("");
  const [isBlockedC, setisBlockedC] = useState(false);
  const [textBtnSeccon, settextBtnSeccon] = useState("");
  const [errorAccountExist, seterrorAccountExist] = useState("");

  const [registerClient] = useMutation(CREATE_USER, {
    onCompleted({ registerClient }) {
      const { message, response } = registerClient;
      const respuesta = JSON.parse(response);
      if (message === "El usuario ya existe") {
        /* SE COLOCAN LOS ERRORES EN CASO DE QUE YA EXISTA EL USUARIO */
        if (isModal) {
          seterrorAccountExist("Este usuario ya se encuentra registrado.");
        } else {
          setChildrend(
            `Usuario ya registrado, por favor inicia sesión, recuerda que si olvidaste tu contraseña puedes solicitar un enlace para ingresar a tu cuenta.`
          );
          settextBtnPrincipal("Iniciar sesión");
          setOpen(true);
          setisBlockedC(true);
        }
      } else {
        sessionStorage.setItem("idUser", respuesta.idUser);
        sessionStorage.setItem("idCont", respuesta.idCont);
        sessionStorage.setItem("idSession", respuesta.idSession);
        sessionStorage.setItem("idCatRelAction", respuesta.idCatRelAction);
        sessionStorage.setItem("idDatSession", respuesta.idSession);
        localStorage.setItem("idUser", respuesta.idUser);
        localStorage.setItem("idCont", respuesta.idCont);
        localStorage.setItem("idSession", respuesta.idSession);
        localStorage.setItem("idCatRelAction", respuesta.idCatRelAction);
        localStorage.setItem("idDatSession", respuesta.idSession);
        /* AQUÍ SE DEBERÍA AGREGAR LA VALIDACIÓN DE LOS INPUT ANTES DE GUARDAR LA DATA */
        dynamicUpdate({
          variables: {
            input: {
              table: "CatContactLogin",
              columns: {
                setkey: "password",
                setval: form.pass1 + form.pass2 + form.pass3 + form.pass4,
              },
              conditions: {
                valone: "CatContact_IDCont",
                valtwo: String(respuesta.idCont),
                condition: "=",
              },
            },
          },
        });
        if (isModal) {
          if (sessionStorage.getItem('Training') !== null && sessionStorage.getItem('Training') === 'Business') {
            setopenModalSuccess(!openModalSuccess);
          }
          nextRoutine();
          /* seterrorAccountExist(""); */
        } else if (sessionStorage.getItem('Training') !== null && sessionStorage.getItem('Training') === 'Business') {
          setopenModalSuccess(!openModalSuccess);
        } else { window.location.href = '/kanda/wo/diagnosticov3'; }
      }
    },
  });
  const handleClickForm = () => {
    const idGa = sessionStorage.getItem("ID_GA");
    let typeUser;
    if (String(form.email).indexOf("@test") !== -1) {
      sessionStorage.setItem("typeUser", "test");
      localStorage.setItem("typeUser", "test");
      typeUser = "test";
    }
    const infoUser = {
      FirstName: form.firstname,
      MiddleName: form.secondname,
      FathersLastName: form.lastname,
      MothersLastName: form.motherslastname,
      PreferredPhone: form.numPhone,
      email: form.email,
      profile: typeUser,
      woProcedure: '',
      idGa,
    };
    sessionStorage.setItem('FirstName', form.firstname);
    if (form.secondname !== '') sessionStorage.setItem('MiddleName', form.secondname);
    sessionStorage.setItem('FathersLastName', form.lastname);
    sessionStorage.setItem('MothersLastName', form.motherslastname);
    if (isModal) {
      const here = window.location.href;
      const withOutDomain = here.replace('http://', '').replace('https://');
      const parts = withOutDomain.split('/');
      const url = `/${parts[1]}/${parts[2]}`;

      infoUser.woProcedure = url;
    }

    const inputUser = {
      input: infoUser,
    };

    try {
      registerClient({ variables: inputUser });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      validCampos.personales
      && validCampos.contacto
      && validCampos.password
    ) {
      setdisabledbtn(false);
    } else {
      setdisabledbtn(true);
    }
  }, [validCampos]);

  return (
    <>
    {isModal
      ? (
        <>
            <TrainingMain>
              <Main
                className="container"
                padding="0 21px"
                mb="134px"
              >
                <Container width="100%" mt="36px" widthMobile="100%" mtMobile="36px">
                  <Col11 padding="0" margin="0">
                    <SubtitleOne size="24px" sizeMobile="24px" className="roboto" mb="24px">Registro</SubtitleOne>
                    <ContentGeneric className="d-flex flex-row" dis="flex" zIndex="4" margin="0 auto 24px auto" >
                      ¿Ya tienes cuenta?{" "}
                      <button
                        style={{
                          color: "#F64282",
                          border: 'none',
                          backgroundColor: 'none',
                          background: 'none',
                          display: 'flex',
                          zIndex: '3',
                        }}
                        onClick={() => {
                          if (isModal) {
                            setTypeModal('login');
                          } else {
                            window.location.href = "/login-v2";
                          }
                        }}
                      >
                        Ingresa aquí
                      </button>
                    </ContentGeneric>
                    <NameComplet form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
                    <Contacto form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
                    <Contrasena form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
                    <Button
                      borderRadius="16px"
                      className={
                        disabledbtn
                          ? "buttonInactive d-block mt-md-4 roboto"
                          : "d-block mt-md-4 roboto"
                      }
                      mb="24px"
                      mt="1.2rem"
                      bg={disabledbtn ? "#d1d1d1" : "#fb245c"}
                      onClick={handleClickForm}
                      disabled={disabledbtn}>
                      Crear mi cuenta
                    </Button>
                    <span style={{ color: "red" }}>{errorAccountExist}</span>
                    <ParagrapTwo
                      color="#000"
                      mt="5%"
                      mb="10%"
                      mbweb="0px"
                      lh="160%"
                      textalignMobile="justify"
                      texttalign="justify"
                      fontSize="11px"
                      fontSizeWeb="11px"
                      className="roboto">
                      Al dar clic en el botón "Continuar", aceptas nuestros Términos,
                      condiciones y Aviso de privacidad. También, autoriza a Coru
                      (Compara Guru México S.A. de C.V.) a transferir mis datos a las
                      instituciones financieras y entidades que se indican en el Aviso
                      de Privacidad, con la finalidad de aplicar a sus propios procesos
                      de solicitud de cotización de productos financieros, de seguros y
                      de inmuebles, para que puedan ofrecerme el producto que consideren
                      adecuado a mis necesidades. También autorizo que me envíen
                      comunicación de Coru y sus filiales por cualquier medio,
                      incluyendo Whatsapp.
                    </ParagrapTwo>
                    <Aceptterms />
                  </Col11>
                </Container>
              </Main>
            </TrainingMain>
        </>)
      : (
        <>
         <WOlayout
      backgroundColor="#FFFFFF"
      pathname={window.location.pathname}
      headerType="HeaderTwo"
      showFooter={!isModal}
    >
      <TrainingMain>
        <TrainigTam>
          <Header
            background="#FFFFFF"
            pathname={window.location.pathname}
          />
        </TrainigTam>
        <Main
          className="container"
          padding="0 21px"
          mb="134px"
        >
          <Container width="100%" mt="36px" widthMobile="100%" mtMobile="36px">
            <Col11 padding="0" margin="0">
              <SubtitleOne mt="15%" size="24px" sizeMobile="24px" className="roboto" mb="24px">Registro</SubtitleOne>
              <ContentGeneric className="d-flex flex-row" dis="flex" zIndex="4" margin="0 auto 24px auto" >
                ¿Ya tienes cuenta?{" "}
                <button
                  style={{
                    color: "#F64282",
                    border: 'none',
                    backgroundColor: 'none',
                    background: 'none',
                    display: 'flex',
                    zIndex: '3',
                  }}
                  onClick={() => {
                    if (isModal) {
                      setTypeModal('login');
                    } else {
                      window.location.href = "/login-v2";
                    }
                  }}
                >
                  Ingresa aquí
                </button>
              </ContentGeneric>
              <NameComplet form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
              <Contacto form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
              <Contrasena form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} />
              <Button
                borderRadius="16px"
                className={
                  disabledbtn
                    ? "buttonInactive d-block mt-md-4 roboto"
                    : "d-block mt-md-4 roboto"
                }
                mb="1.2rem"
                mt="1.2rem"
                bg={disabledbtn ? "#d1d1d1" : "#fb245c"}
                onClick={handleClickForm}
                disabled={disabledbtn}>
                Crear mi cuenta
              </Button>
              <ParagrapTwo
                color="#000"
                mt="5%"
                mb="10%"
                mbweb="0px"
                lh="160%"
                textalignMobile="justify"
                texttalign="justify"
                fontSize="11px"
                fontSizeWeb="11px"
                className="roboto">
                Al dar clic en el botón "Continuar", aceptas nuestros Términos,
                condiciones y Aviso de privacidad. También, autoriza a Coru
                (Compara Guru México S.A. de C.V.) a transferir mis datos a las
                instituciones financieras y entidades que se indican en el Aviso
                de Privacidad, con la finalidad de aplicar a sus propios procesos
                de solicitud de cotización de productos financieros, de seguros y
                de inmuebles, para que puedan ofrecerme el producto que consideren
                adecuado a mis necesidades. También autorizo que me envíen
                comunicación de Coru y sus filiales por cualquier medio,
                incluyendo Whatsapp.
              </ParagrapTwo>
              <Aceptterms />
            </Col11>
          </Container>
        </Main>
      </TrainingMain>
      <Modallogin
        open={open}
        setopen={setOpen}
        childrent={childrend}
        setChildrend={setChildrend}
        textBtnPrincipal={textBtnPrincipal}
        settextBtnPrincipal={settextBtnPrincipal}
        textBtnSeccon={textBtnSeccon}
        settextBtnSeccon={settextBtnSeccon}
        isBlockedC={isBlockedC}
        setisBlockedC={setisBlockedC}
        objectSession={form}
      />
      <ModalSuccessRegister setOpen={setopenModalSuccess} open={openModalSuccess} />
    </WOlayout>
        </>
      )}
   </>
  );
};

export default Registro;
