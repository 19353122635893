import React, { useState, Fragment, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import DatosBasicos from '../Routines/DatosBasicos';
import ExtraIncome from '../Routines/ExtraIncome';
import QuestionsExtraIncome from '../Routines/QuestionsExtraIncome';
import Manejar from '../Routines/Manejar';
import Ejercicio from '../Routines/Ejercicio';
import Convivencia from '../Routines/Convivencia';
import PermanecerEnCasa from '../Routines/PermanecerEnCasa';
import NavegarEnInternet from '../Routines/NavegarEnInternet';
import ConocimientosNuevos from '../Routines/ConocimientosNuevos';
import GustoPorLeer from '../Routines/GustoPorLeer';
import InmuebleSinUsar from '../Routines/InmuebleSinUsar';
import check from '../../assets/img/anacoach/cheque.png';
import CardOne from '../../components/Home/Card1Animate';
import {
  GET_RECOMMENDATION_TRAININGS,
  CREATE_OPPORTUNITIES,
  INSERT_FINISHED_WO_BY_XCOACH,
} from '../../mutations';
import Recommendations from '../../components/Training/RecommendedTraining';
import { Container } from '../../assets/styles/Home.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';

import {
  CardsTxt,
  DivGray,
  CardsBtn,
  DivStyle,
  Images,
  TrainingDivSection,
  TrainingBenef,
  TrainingSectionGray,
  TrainingDivSection2,
  RPFastTrack,
  TrainingSectionFlexCarrusel,
} from '../../assets/styles/Training.styled';
import { Col7 } from '../../components/common/common.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import ClickButton from '../../Helpers/HookHelpers';
import SectionShare from '../../components/common/ShareSection';
import ModalAmazon from '../../components/common/ModalAmazon';
import ProductsFremium from '../../components/common/ProductsFremium';
import ModalVirality from '../../components/common/ModalVirality';
import ModalLogin from '../pantallasInicio/ModalLogin';


const RoutinesOrderExtraIncome = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  saveAdvance,
  trainingColor,
}) => {
  const { actions } = ClickButton();
  const [extraIncomeProducts, setExtraIncomeProducts] = useState(null);
  const [data] = useState([]);
  const [tipo, setTipo] = useState('');
  const [descIntro, setDescIntro] = useState('');
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [insertFinishedByXCoach] = useMutation(INSERT_FINISHED_WO_BY_XCOACH, {
    onCompleted({ insertFinishedByXCoach }) {
      if (insertFinishedByXCoach.statusCode === 200) {
        console.log('Insert finished by Xcoach');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log('response == ', response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const abrirNuevoTab = (url, name, IDProduct) => {
    const click = `ComienzaAGanar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    sessionStorage.setItem('clickOutProduct', name);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 3, // remplazar por el id del entrenamiento
        idProducts: [IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 24,
        idType: '',
        value: name,
      },
    };
    createOpportunitiesUser({ variables: opportunityDetailInput });
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const win = window.open(url, '_blank');
    win.focus();
  };

  useEffect(() => {
    if (extraIncomeProducts) {
      const idUser = sessionStorage.getItem('idUser');
      const idTraining = sessionStorage.getItem('idTraining');
      const fromXCoach = sessionStorage.getItem('fromXCoach');
      const idAdviser = sessionStorage.getItem('idAdviser');
      if (idUser != null) {
        insertFinishedByXCoach({
          variables: {
            idUser: parseInt(idUser) || 0,
            idTraining: parseInt(idTraining) || 0,
            fromXCoach: fromXCoach === 'true' ? 1 : 0,
            idAdviser: parseInt(idAdviser) || null,
          },
        });
      }
    }
  }, [extraIncomeProducts]);

  useEffect(() => {
    console.log(extraIncomeProducts);
  }, [extraIncomeProducts]);

  const irDerecha = () => {
    const buttonR2 = document.getElementById('right-button');
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    if (buttonR2 !== null) {
      buttonR2.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;
        const cw = content.scrollWidth;
        if (sl + scrollStep >= cw) {
          content.scrollTo(cw, 0);
        } else {
          content.scrollTo(sl + scrollStep, 0);
        }
      });
    }
  };

  const irIzquierda = () => {
    const buttonL2 = document.getElementById('left-button');
    const content = document.getElementById('contentRP');
    const scrollStep = 310;
    if (buttonL2 !== null) {
      buttonL2.addEventListener('click', (e) => {
        e.preventDefault();
        const sl = content.scrollLeft;

        if (sl - scrollStep <= 0) {
          content.scrollTo(0, 0);
        } else {
          content.scrollTo(sl - scrollStep, 0);
        }
      });
    }
  };

  return (
    <Fragment>
      {orderRutine >= 1 ? (
        // <DatosBasicos
        //   Date={Date}
        //   hora={hora}
        //   orderRutine={orderRutine}
        //   setOrderRutine={setOrderRutine}
        //   currentTraining={currentTraining}
        //   createJsonInput={createJsonInput}
        //   questions={questions}
        //   setQuestions={setQuestions}
        //   currentRoutine={1}
        //   nextOrderRoutine={2}
        //   trainingColor={trainingColor}
        // />
        <ModalLogin 
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={1}
          nextOrderRoutine={2}
          onLoginRoutine={2}
          onRegisterRoutine={2}
        />
      ) : null}

      {orderRutine >= 2 ? (
        <ExtraIncome
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={2}
          nextOrderRoutine={3}
          trainingColor={trainingColor}
        />
      ) : null}

      {/* ¿Cuál de las siguientes opciones te describe mejor? */}
      {orderRutine >= 3 ? (
        <QuestionsExtraIncome
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={3}
          nextOrderRoutine={4}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setTipo={setTipo}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
      ) : null}

      {/* Qué vehículo manejas */}
      {orderRutine >= 4 && tipo === 'Me gusta manejar' ? (
        <Manejar
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={4}
          nextOrderRoutine={5}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
      ) : null}

      {/* ¿Qué te gusta más?  */}
      {orderRutine >= 5 && tipo === 'Me gusta hacer ejercicio' ? (
        <Ejercicio
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={5}
          nextOrderRoutine={6}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
      ) : null}

      {orderRutine >= 6
      && tipo === 'Me gusta convivir con personas y vender cosas' ? (
        <Convivencia
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={6}
          nextOrderRoutine={7}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
        ) : null}

      {orderRutine >= 7 && tipo === 'Me gusta navegar en internet' ? (
        <NavegarEnInternet
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={7}
          nextOrderRoutine={8}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
      ) : null}

      {orderRutine >= 8 && tipo === 'Me gusta estar en casa' ? (
        <PermanecerEnCasa
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={8}
          nextOrderRoutine={9}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
      ) : null}

      {orderRutine >= 9
      && tipo
        === 'Me gusta sacar provecho de mis conocimientos o adquirir nuevos' ? (
        <ConocimientosNuevos
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={9}
          nextOrderRoutine={10}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
        ) : null}

      {orderRutine >= 10 && tipo === 'Me gusta leer' ? (
        <GustoPorLeer
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={10}
          nextOrderRoutine={11}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
      ) : null}

      {orderRutine >= 11
      && tipo === 'Tengo una habitación o inmueble que no utilizo' ? (
        <InmuebleSinUsar
          Date={Date}
          hora={hora}
          orderRutine={orderRutine}
          setOrderRutine={setOrderRutine}
          currentTraining={currentTraining}
          createJsonInput={createJsonInput}
          questions={questions}
          setQuestions={setQuestions}
          currentRoutine={11}
          nextOrderRoutine={'final'}
          saveAdvance={saveAdvance}
          trainingColor={trainingColor}
          data={data}
          setDescIntro={setDescIntro}
          extraIncomeProducts={extraIncomeProducts}
          setExtraIncomeProducts={setExtraIncomeProducts}
        />
        ) : null}
      {extraIncomeProducts ? (
        <>
          <TrainingSectionGray back="#fafafa">
            <TrainingDivSection2
              wiw="670px"
              paddi="0"
              paddiw="0"
              mw="auto"
              back="#fafafa"
            >
              <CardOne
                classCard="cardOne"
                classTop="topOne1"
                text={descIntro}
              />
            </TrainingDivSection2>
          </TrainingSectionGray>
          <TrainingSectionFlexCarrusel className="d-flex flex-row">
            <button
              className="lefty paddle1FastTrack"
              id="left-button"
              onClick={(e) => irIzquierda(e)}
            ></button>
            <RPFastTrack id="contentRP">
              {extraIncomeProducts?.map((producto) => (
                <>
                  <TrainingDivSection
                    he=""
                    padding="10% 2%"
                    paddw="3% 1%"
                    wiw="400px !important"
                  >
                    <div
                      className="ProductExtraIncome ExtraIncomeMob"
                      style={{
                        border: '1px solid #D6D6D6',
                        'margin-bottom': '15px',
                      }}
                    >
                      <div className="center">
                        <DivStyle size="18px" line="1.2em" align="left">
                          <Images
                            src={producto.ImageURL}
                            top="15px !important"
                            topweb="0"
                            left="5% !important"
                            leftw="0"
                            position="initial"
                            width="60px"
                            widthw="60px"
                            className="img-fluid"
                          ></Images>
                        </DivStyle>
                        <DivStyle
                          className="cabin"
                          mxh="auto"
                          mh="66px"
                          weight="600"
                          align="left"
                          size="18px"
                          line="1.5em"
                          padd="12px 5% 0 5%"
                          color="#07210A"
                        >
                          {producto.Name}
                        </DivStyle>
                        <br />
                        {producto.benefits?.length > 0 ? (
                          <>
                            <DivStyle
                              className="roboto"
                              mxh="30px"
                              align="center"
                              size="16px"
                              line="1.5em"
                              padd="12px 5% 0 5%"
                              color="#07210A"
                            >
                              ¿Cómo ganas dinero?
                            </DivStyle>
                            <DivGray bcolor="#fff" padd="0 5% 0 5%">
                              <hr />
                              <Col7
                                padding=" "
                                className="cardPrincipal1"
                                maxwi="100%"
                                maxwidth="100%"
                              >
                                <TrainingDivSection
                                  hew="auto"
                                  paddw="0px"
                                  mlw="0px"
                                  mtw="8%"
                                  wiw="auto"
                                  back="#FFF"
                                  backw="#FFF"
                                  bshaw="none"
                                  bsha=""
                                  br="4px"
                                  padding="20px"
                                  wi="100%"
                                  he="auto"
                                  mb="0px"
                                >
                                  <Images
                                    src={check}
                                    top="0px !important"
                                    topweb="70px"
                                    left="0px !important"
                                    leftw="2%"
                                    width="18px"
                                    position="initial"
                                    className="img-fluid"
                                  ></Images>
                                  <TrainingBenef
                                    pt="0px"
                                    pb="17px"
                                    he="auto"
                                    pl="35px"
                                    mt="-20px"
                                    className="roboto"
                                    mheight="80px"
                                    alignweb="left"
                                    ls="-0.2px"
                                  >
                                    <CardsTxt
                                      color="#4F4F4F"
                                      className="roboto"
                                      size="16px"
                                    >
                                      {producto.benefits[0]?.Description}
                                    </CardsTxt>
                                    <CardsTxt
                                      color="#4F4F4F"
                                      className="roboto"
                                      size="16px"
                                    >
                                      {producto.benefits[1]?.Description}
                                    </CardsTxt>
                                  </TrainingBenef>
                                </TrainingDivSection>
                              </Col7>
                            </DivGray>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="ProductExtraIncome">
                      <CardsBtn
                        type="button"
                        min_w="100%"
                        min_web="100%"
                        onClick={() => {
                          abrirNuevoTab(
                            producto.ClickOut,
                            producto.Name,
                            producto.IDProduct,
                          );
                        }}
                      >
                        ¡Comienza a ganar!
                      </CardsBtn>
                    </div>
                  </TrainingDivSection>
                </>
              ))}
            </RPFastTrack>
            <button
              className="righty paddle2FastTrack"
              id="right-button"
              onClick={(e) => irDerecha(e)}
            ></button>
          </TrainingSectionFlexCarrusel>
          <div className="marginComentLgSuccess1">
            <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') === '24' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
          </div>
          <ModalAmazon show={true} wo={'IngresosExtra'}></ModalAmazon>

          {recomendaciones ? (
            <Feed_Main width="100%" background=" ">
              <Feed_Main
                width="100%"
                display=" "
                mtop=" "
                background="transparent"
              >
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text="¿Aún quieres ir por más?"
                  text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
                />
                <CardOne
                  classCard="cardOne"
                  classTop="topOne1"
                  text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
                />
              </Feed_Main>
            </Feed_Main>
          ) : null}
          {recomendaciones ? (
            <div className="marginComentLgSuccess1">
              <Container
                mlMobile="auto"
                mRMobile="auto"
                mleftmxs="0%"
                mleft="18%"
                mleftm="7%"
                width="100%"
                widthMobile="100%"
                mtMobile="0px"
              >
                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                  <ContentHomeSection leftD="0%" floatD=" " width="80%;">
                    <Recommendations
                      items={recommendedTraining}
                    ></Recommendations>
                  </ContentHomeSection>
                </Feed_Section>
              </Container>
            </div>
          ) : null}
          <ModalVirality />
          <SectionShare WO="IngresosExtra"></SectionShare>
        </>
      ) : null}
    </Fragment>
  );
};
export default RoutinesOrderExtraIncome;
