/* eslint-disable no-nested-ternary */
import React, { Fragment, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
import ShareIcon from '@material-ui/icons/Share';
import Modal from '@material-ui/core/Modal';
import MaximizeIcon from '@material-ui/icons/Maximize';
import { Button } from '@material-ui/core';
import { Feed_Text, SectionShareFeed } from '../../assets/styles/Feed.styled';
import { DivFlex, CardsBtn } from '../../assets/styles/Training.styled';
import face from '../../assets/img/anacoach/facebook1.png';
import twit from '../../assets/img/anacoach/messenger2.svg';
import whats from '../../assets/img/anacoach/whats.png';
import { viralityUTM } from '../../Helpers/HelperUTM';
import { getDateStandart } from '../../Helpers/general';
import { shareCoru2 } from '../../Helpers/index';
import {
  INSERT_DAT_VIRALITY,
  UPDATE_HISTORY_COLLECTION,
  CREATE_SESSION,
  GENERATE_SHORTENED_LINK,
} from '../../mutations';

import {
  ParagrapTwo,
  ButtonSecon,
} from '../../components/common/common.styled';
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
  Triangu,
} from '../../components/common/Home.styled';
import ClickButton from '../../Helpers/HookHelpers';

function ShareCommonFeed({
  url,
  textShare,
  trainingName = '',
  sumary = false,
  mtop,
  btn = false,
  listXCoach = false,
  onlyButton = false,
}) {
  const { actions } = ClickButton();
  const [generateShortendLink] = useMutation(GENERATE_SHORTENED_LINK, {
    onCompleted({ generateShortendLink }) {
      const link = generateShortendLink.response;
      return link;
    },
    onError(err) {
      console.log(err);
    },
  });

  const [insertDatVirality] = useMutation(INSERT_DAT_VIRALITY, {
    onCompleted({ insertDatVirality }) {
      if (insertDatVirality.message === 'success') {
        console.log('REsponse viralidad', insertDatVirality.response);
        const { response } = insertDatVirality;
        const redirectLink = response.split(',')[0];
        const socialNetwork = response.split(',')[1];
        const announcement = sessionStorage.getItem('auxAnnouncement');

        shareCoru2(
          socialNetwork,
          announcement,
          redirectLink,
          generateShortendLink,
          listXCoach,
        ).then((sharedLink) => {
          if (listXCoach) {
            let copyText = document.createElement('input');
            document.body.appendChild(copyText);
            copyText.setAttribute('id', 'input_xcoach_clipboard');
            document.getElementById('input_xcoach_clipboard').value = sharedLink;
            copyText = document.getElementById('input_xcoach_clipboard');
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');
            alert('La URL se ha copiado al portapapeles');
          }
        });
      }
    },
  });
  const [updateHistoryCollection] = useMutation(UPDATE_HISTORY_COLLECTION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [createClickShareNetworkCollection] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
        localStorage.setItem('idClickSocialSN', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const ClickShareMongo = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'ClickShareSN'.concat(action),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (sessionStorage.getItem('idClickSocialSN')) {
      const dataJson = [
        {
          idSession: sessionStorage.getItem('id_session'),
          nameTraining: sessionStorage.getItem('trainingName'),
          medium: action,
          date: getDateStandart(),
        },
      ];
      const dataString = JSON.stringify(dataJson);
      const filterString = JSON.stringify({
        _id: sessionStorage.getItem('idClickSocialSN'),
      });
      const mongoInput = {
        input: {
          collection: 'LogShared',
          key: 'Shared',
          data: dataString,
          filter: filterString,
        },
      };
      updateHistoryCollection({ variables: mongoInput });
    } else {
      const dataJson = {
        idUser: sessionStorage.getItem('idUser'),
        Shared: [
          {
            idSession: sessionStorage.getItem('id_session'),
            nameTraining: sessionStorage.getItem('trainingName'),
            medium: action,
            date: getDateStandart(),
          },
        ],
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'LogShared',
          data: dataString,
        },
      };
      createClickShareNetworkCollection({ variables: mongoInput });
    }
  };

  let urlVi = '';

  const shareTraining = (socialNetwork) => {
    if (url === 'feed') {
      actions({ action: 'ClickButtonCoruHome', variables: `Compartir${socialNetwork}` });
    }

    ClickShareMongo(socialNetwork);
    let announcement;
    if (listXCoach) {
      sessionStorage.setItem('trainingName', trainingName);
    }

    if (url === 'feed') {
      sessionStorage.setItem('auxAnnouncement', 'ShareFeed');
      urlVi = '/';
      if (trainingName === 'Perfil') {
        sessionStorage.setItem('trainingName', trainingName);
      } else if (trainingName === 'Logros') {
        sessionStorage.setItem('trainingName', trainingName);
      } else {
        sessionStorage.setItem('trainingName', trainingName);
      }
    } else {
      if (url === '/wo/card-funnel') {
        announcement = socialNetwork === 'Whatsapp' ? 'CCSuccessWa' : 'CCSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/profiling') {
        announcement = socialNetwork === 'Whatsapp' ? 'ProfileSuccessWa' : 'ProfileSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/loans-funnel') {
        announcement = socialNetwork === 'Whatsapp' ? 'PLSuccessWa' : 'PLSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/compare-credit-card') {
        announcement = socialNetwork === 'Whatsapp' ? 'CTSuccessWa' : 'CTSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/first-card') {
        announcement = socialNetwork === 'Whatsapp' ? 'FCSuccessWa' : 'FCSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/emergency-saving') {
        announcement = socialNetwork === 'Whatsapp' ? 'EsSuccessWa' : 'EsSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/extra-income') {
        announcement = socialNetwork === 'Whatsapp' ? 'EISuccessWa' : 'EISuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/budget') {
        announcement = socialNetwork === 'Whatsapp' ? 'BudgetSuccessWa' : 'BudgetSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/first-investment') {
        announcement = socialNetwork === 'Whatsapp'
          ? 'FInvertionSuccessWa'
          : 'FInvertionSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/investment-test') {
        announcement = socialNetwork === 'Whatsapp' ? 'ITSuccessWa' : 'ITSuccess'; // hace falta imagen
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/debts') {
        announcement = socialNetwork === 'Whatsapp' ? 'DebtsSuccessWa' : 'DebtsSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/family-protection') {
        announcement = socialNetwork === 'Whatsapp' ? 'PFSuccessWa' : 'PFSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/family-protection') {
        announcement = socialNetwork === 'Whatsapp' ? 'PFSuccessWa' : 'PFSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/wo/virality-test') {
        announcement = socialNetwork === 'Whatsapp'
          ? 'ViralityTestSuccessWa'
          : 'ViralityTestSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else if (url === '/lp/get-card') {
        announcement = socialNetwork === 'Whatsapp'
          ? 'LPgetCardSuccessWa'
          : 'LPgetCardSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      } else {
        announcement = socialNetwork === 'Whatsapp' ? 'CCSuccessWa' : 'CCSuccess';
        sessionStorage.setItem('auxAnnouncement', announcement);
      }
      urlVi = String(url);
      // urlVi = urlVi.substr(1)
    }
    const fromXCoach = listXCoach;
    viralityUTM(
      insertDatVirality,
      urlVi,
      sessionStorage.getItem('trainingName'),
      socialNetwork,
      '',
      '',
      '',
      fromXCoach,
    );
  };

  const [widthBrowser, setwidthBrowser] = useState(window.outerWidth);
  useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);

  const [openModal, setopenModal] = useState(false);
  const ModalCompartir = () => (
    <Modal
      open={openModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClick={() => {
        setopenModal(false);
      }}
    >
      <div className="ModalCompartir">
        <ParagrapTwo
          fontWeight="700"
          mt="0px"
          mb="0px"
          cursor="pointer"
          mtWeb="0px"
          mbweb="0px"
          texttalign="right"
          textalignMobile="center"
          className="mx-auto"
          onClick={() => {
            setopenModal(false);
          }}
        >
          {widthBrowser >= 760 ? (
            'x'
          ) : (
            <MaximizeIcon style={{ fontSize: '2.5rem' }} />
          )}
        </ParagrapTwo>
        <ContentHomeSection
          displayD="flex"
          leftD="0%"
          floatD="left"
          width="100%;"
        >
          <Triangu margintop="-35px" />
          <Vector2
            width="50px"
            left="0"
            height="60px"
            widthM="30px"
            heightM="40px"
            background="#E3E660"
            radius="40%"
            transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
            positionD="absolute"
            Pleft="2%"
            Ptop="243px"
            PleftM="1%"
            PtopM="55%"
            MlefM="2%"
            displayM="block"
            id="ovalo"
          />
          <Vector3
            position="absolute"
            left=" "
            top=" "
            PtopM="35%"
            PleftM="1%"
            Ptop="140px"
            Pleft="1%"
            background=" #6BD5AF;"
            transform="rotate(-90deg)"
            width="55px"
            height="55px"
            widthM="35px"
            heightM="35px"
            displayD=" "
            displayM="block"
          />
          <Rectangle24
            positionD="absolute"
            Ptop="350px"
            mLeftM="0"
            PLeftM="0%"
            mTopM="0%"
            Pleft="2%"
            background="#BFDD6A"
            displayM="block"
            displayD=" "
            left=" "
            top=" "
            width="30.94px"
            height="30.94px"
            widthM="25px"
            heightM="25px"
            transform="rotate(46.41deg)"
          />
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="15%;"
            widthM="0"
          ></ContentHomeSection>
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="50%;"
            widthM="100%"
          >
            <Rectangle24
              positionD="absolute"
              Ptop="24px"
              mLeftM="65%"
              PLeftM="20%"
              mTopM="2%"
              Pleft="85%"
              background="#6BD5AF"
              displayM="block"
              displayD=" "
              left=" "
              top=" "
              width="30.94px"
              height="30.94px"
              widthM="25px"
              heightM="25px"
              transform="rotate(46.41deg)"
            />
            <Vector3
              position="absolute"
              left=" "
              top=" "
              Ptop="112px"
              Pleft="88%"
              PtopM="100px"
              PleftM="83%"
              background="#BFDD6A"
              transform="rotate(-75deg)"
              width="55px"
              height="55px"
              widthM="42px"
              heightM="42px"
              displayD=" "
              displayM="block"
            />
            <Vector3
              position="absolute"
              left=" "
              top=" "
              Ptop="222px"
              Pleft="86%"
              PtopM="190px"
              PleftM="83%"
              background="#E3E660"
              transform="rotate(75deg)"
              width="55px"
              height="55px"
              widthM="42px"
              heightM="42px"
              displayD=" "
              displayM="block"
            />
            <Rectangle24
              positionD="absolute"
              Ptop="310px"
              mLeftM="0"
              PLeftM="90%"
              mTopM="10%"
              Pleft="88%"
              background="#91D770"
              displayM="block"
              displayD=" "
              left=" "
              top=" "
              width="50px"
              height="50px"
              widthM="30px"
              heightM="30px"
              transform="rotate(46.41deg)"
            />
            <ContentHomeSection
              displayD="flex"
              leftD="0%"
              floatD="left"
              width="100%;"
            >
              <Feed_Text
                className="roboto"
                weight="bold"
                width="80%"
                margin="auto"
              >
                Comparte en:
              </Feed_Text>
            </ContentHomeSection>
            <ContentHomeSection
              displayD="flex"
              cursor="pointer"
              topD="10%"
              leftD="0%"
              floatD="left"
              width="100%;"
              borderbottom="2px solid #CDCDCD;"
              onClick={() => shareTraining('Facebook')}
            >
              <img
                alt=""
                position="initial"
                left="0"
                width="50px"
                height="50px"
                style={{
                  maxWidth: '100%',
                  width: '50px',
                  height: '50px',
                  position: 'initial',
                  cursor: 'pointer',
                  margin: '6%',
                }}
                src={face}
              />
              <Feed_Text
                className="roboto"
                margin="auto"
                leftm="0px"
                top="auto"
              >
                Facebook
              </Feed_Text>
            </ContentHomeSection>
            <ContentHomeSection
              displayD="flex"
              cursor="pointer"
              leftD="0%"
              floatD="left"
              width="100%;"
              borderbottom="2px solid #CDCDCD;"
              onClick={() => shareTraining('Whatsapp')}
            >
              <img
                alt=""
                position="initial"
                left="0"
                top="6%"
                width="50px"
                height="50px"
                style={{
                  maxWidth: '100%',
                  width: '50px',
                  height: '50px',
                  position: 'initial',
                  cursor: 'pointer',
                  margin: '6%',
                }}
                src={whats}
              />
              <Feed_Text
                className="roboto"
                margin="auto"
                leftm="0px"
                top="auto"
              >
                Whatsapp
              </Feed_Text>
            </ContentHomeSection>
            <ContentHomeSection
              displayD="flex"
              cursor="pointer"
              leftD="0%"
              floatD="left"
              width="100%;"
              className="display-web"
              onClick={() => shareTraining('Messenger')}
            >
              <img
                className="display-web"
                alt=""
                position="initial"
                left="0"
                width="44px"
                height="50px"
                style={{
                  maxWidth: '100%',
                  width: '50px',
                  height: '54px',
                  position: 'initial',
                  cursor: 'pointer',
                  margin: '6%',
                }}
                src={twit}
              />
              <Feed_Text
                className="roboto display-web"
                margin="auto"
                leftm="0px"
                top="auto"
              >
                Messenger
              </Feed_Text>
            </ContentHomeSection>
            <ContentHomeSection
              displayD="flex"
              cursor="pointer"
              leftD="0%"
              floatD="left"
              width="100%;"
              className="display-mobile-flex"
              onClick={() => shareTraining('MessengerMob')}
            >
              <img
                className="display-mobile"
                alt=""
                position="initial"
                left="0"
                width="44px"
                height="50px"
                style={{
                  maxWidth: '100%',
                  width: '50px',
                  height: '54px',
                  position: 'initial',
                  marginLeft: '0',
                  margin: '6%',
                }}
                src={twit}
              />
              <Feed_Text
                className="roboto display-mobile"
                margin="auto"
                leftm="0px"
                top="auto"
              >
                Messenger
              </Feed_Text>
            </ContentHomeSection>
          </ContentHomeSection>
          <ContentHomeSection
            leftD="0%"
            floatD="left"
            width="20%;"
            widthM="0"
          ></ContentHomeSection>
        </ContentHomeSection>
      </div>
    </Modal>
  );

  return (
    <Fragment>
      {!sumary ? (
        <Fragment>
          {!listXCoach ? (
            <SectionShareFeed
              mt={!btn ? '110px' : '71px'}
              wiw="80%"
              filter="drop-shadow(0px 4px 4px rgba(0,0,0,0.25));"
            >
              <Feed_Text
                className="roboto"
                top={mtop != null ? mtop : '25px'}
                weight={!btn ? 'normal' : 'bold'}
                size={!btn ? '18px' : '21px'}
              >
                {textShare}
              </Feed_Text>
              {!btn ? (
                <DivFlex
                  className="align-items-center mt-4 pt-2"
                  wid="100%"
                  margin="auto"
                  widthweb="85%"
                  marweb="auto"
                >
                  <img
                    onClick={() => shareTraining('Facebook')}
                    alt=""
                    position="initial"
                    left="0"
                    width="50px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                      cursor: 'pointer',
                    }}
                    src={face}
                  />
                  <img
                    className="display-web"
                    onClick={() => shareTraining('Messenger')}
                    alt=""
                    position="initial"
                    left="0"
                    width="44px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '54px',
                      position: 'initial',
                      marginLeft: '0',
                      cursor: 'pointer',
                    }}
                    src={twit}
                  />
                  <img
                    className="display-mobile"
                    onClick={() => shareTraining('MessengerMob')}
                    alt=""
                    position="initial"
                    left="0"
                    width="44px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '54px',
                      position: 'initial',
                      marginLeft: '0',
                      cursor: 'pointer',
                    }}
                    src={twit}
                  />
                  <img
                    onClick={() => shareTraining('Whatsapp')}
                    alt=""
                    position="initial"
                    left="0"
                    top="6%"
                    width="50px"
                    height="50px"
                    style={{
                      maxWidth: '100%',
                      width: '50px',
                      height: '50px',
                      position: 'initial',
                      marginLeft: '0',
                      cursor: 'pointer',
                    }}
                    src={whats}
                  />
                </DivFlex>
              ) : (
                <>
                  <DivFlex
                    className="align-items-center"
                    wid="100%"
                    margin="auto"
                    widthweb="100%"
                    marweb="auto"
                  >
                    <CardsBtn
                      back="#FFFFFF"
                      back=" #F64282"
                      min_web="100%"
                      border="2px solid #F64282"
                      min_w="100%"
                      onClick={() => {
                        setopenModal(true);
                        actions({ action: 'ClickButtonCoruHome', variables: 'CompartirCoru' });
                      }}
                    >
                      <b>Compartir Coru</b>{' '}
                      <ShareIcon style={{ float: 'right' }} />
                    </CardsBtn>
                  </DivFlex>
                  <ModalCompartir />
                </>
              )}
            </SectionShareFeed>
          ) : (
            <Fragment>
              <Button
                name="btnShared"
                type="button"
                variant="outlined"
                className="enlace"
                size="small"
                color="primary"
                title={trainingName}
                startIcon={<ShareIcon />}
                onClick={() => {
                  setopenModal(true);
                }}
              ></Button>
              <ModalCompartir />
            </Fragment>
          )}
        </Fragment>
      ) : onlyButton ? (
        <>
          <ButtonSecon
            className="roboto"
            mleft=" "
            mt=" "
            onClick={() => setopenModal(true)}
          >
            Compartir
          </ButtonSecon>
          <ModalCompartir />
        </>
      ) : (
        <DivFlex
          className="align-items-center mt-4 pt-2"
          wid="100%"
          margin="auto auto 6rem auto"
          widthweb="85%"
          marweb="auto auto 3rem auto"
        >
          <img
            onClick={() => shareTraining('Facebook')}
            alt=""
            position="initial"
            left="0"
            width="50px"
            height="50px"
            style={{
              maxWidth: '100%',
              width: '50px',
              height: '50px',
              position: 'initial',
              marginLeft: '0',
              cursor: 'pointer',
            }}
            src={face}
          />
          <img
            className="display-web"
            onClick={() => shareTraining('Messenger')}
            alt=""
            position="initial"
            left="0"
            width="44px"
            height="50px"
            style={{
              maxWidth: '100%',
              width: '50px',
              height: '54px',
              position: 'initial',
              marginLeft: '0',
              cursor: 'pointer',
            }}
            src={twit}
          />
          <img
            className="display-mobile"
            onClick={() => shareTraining('MessengerMob')}
            alt=""
            position="initial"
            left="0"
            width="44px"
            height="50px"
            style={{
              maxWidth: '100%',
              width: '50px',
              height: '54px',
              position: 'initial',
              marginLeft: '0',
              cursor: 'pointer',
            }}
            src={twit}
          />
          <img
            onClick={() => shareTraining('Whatsapp')}
            alt=""
            position="initial"
            left="0"
            top="6%"
            width="50px"
            height="50px"
            style={{
              maxWidth: '100%',
              width: '50px',
              height: '50px',
              position: 'initial',
              marginLeft: '0',
              cursor: 'pointer',
            }}
            src={whats}
          />
        </DivFlex>
      )}
    </Fragment>
  );
}
export default ShareCommonFeed;
