import React, {
  Fragment, useState, useEffect, useCallback,
} from 'react';
import TextField from '@material-ui/core/TextField';
import '../../assets/styles/WelcomeValidation.css';
import '../../assets/styles/TextCustom.css';
import TagManager from 'react-gtm-module';
import { useMutation } from '@apollo/client';
// Modal
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Container,
  Col3,
  ParagrapTwo,
  ParagraphOne,
  SubtitleOne,
} from '../common/common.styled';
import {
  LOGIN,
  UPDATE_SESSION,
  CREATE_CLICK,
  CHANGE_PASSWORD,
} from '../../mutations';
import '../../assets/styles/perfil/perfiles.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

function ModalPassword(openModal) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted() {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log('Mongo');
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });
  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      if (login.token === 'El usuario no existe') {
        console.log('El usuario no existe');
      } else if (login.token === 'Contraseña incorrecta') {
        console.log('Contraseña incorrecta');
      } else {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const dataJson = {
          email: sessionStorage.getItem('email'),
          timestamp,
          IDUser: login.idUser,
          idSession: login.idSession,
        };
        const filterJson = {
          _id: sessionStorage.getItem('id_session'),
        };
        const dataString = JSON.stringify(dataJson);
        const filterString = JSON.stringify(filterJson);
        const mongoUpdateInput = {
          input: {
            collection: 'sessions',
            data: dataString,
            filter: filterString,
          },
        };
        if (sessionStorage.getItem('id_session')) updateSession({ variables: mongoUpdateInput });
        sessionStorage.setItem('token', login.token);
        sessionStorage.setItem('firstName', login.firstName);
        sessionStorage.setItem('middleName', login.middleName);
        sessionStorage.setItem('fathersLastName', login.fathersLastName);
        sessionStorage.setItem('mothersLastName', login.mothersLastName);
        sessionStorage.setItem('idUser', login.idUser);
        sessionStorage.setItem('idCont', login.idCont);
        sessionStorage.setItem('idDatSession', login.idSession);
        sessionStorage.setItem('showModal', false);
        sessionStorage.setItem('DataPerson', true);
        localStorage.setItem('token', login.token);
        localStorage.setItem('firstName', login.firstName);
        localStorage.setItem('middleName', login.middleName);
        localStorage.setItem('fathersLastName', login.fathersLastName);
        localStorage.setItem('mothersLastName', login.mothersLastName);
        localStorage.setItem('idUser', login.idUser);
        localStorage.setItem('idCont', login.idCont);
        localStorage.setItem('idDatSession', login.idSession);
        window.location.href = '/the-training-feed/';
      }
    },
  });
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted({ changePassword }) {
      const password = sessionStorage.getItem('password_modal');
      let profile = 'test';
      if (sessionStorage.getItem('email').indexOf('@test') !== -1) {
        sessionStorage.setItem('typeUser', 'test');
        localStorage.setItem('typeUser', 'test');
        profile = 'test';
      }
      const user = sessionStorage.getItem('email');

      const inputLogin = {
        input: {
          email: user,
          password,
          typeUser: profile,
          IDGA: sessionStorage.getItem('ID_GA'),
        },
      };
      console.log('PROCESO MODAL update!!');
      console.log(changePassword.message);
      if (changePassword.message === 'success') {
        login({
          variables: inputLogin,
        });
      }
    },
  });
  const [form, setForm] = useState({
    passnew1: '',
    passnew2: '',
    passnew3: '',
    passnew4: '',
  });
  const [error] = useState({
    passnew1: false,
    passnew2: false,
    passnew3: false,
    passnew4: false,
  });

  const [texterror] = useState({
    passnew1: '',
    passnew2: '',
    passnew3: '',
    passnew4: '',
  });
  const [texterrorLogin] = useState();
  const [btn, setBtn] = useState(true);

  const handleChange = (e) => {
    e.persist();
    setForm({
      ...form,
      [e.target.name]: e.target.value === 'N' ? '' : e.target.value,
    });
    console.log(e.target.value);
    ValidaNumero(e.target.name, e.target.value);
    const { name, value } = e.target;
    switch (name) {
      case 'passnew1':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='passnew2']").focus();
        } else {
          return false;
        }
        break;
      case 'passnew2':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='passnew3']").focus();
        } else {
          return false;
        }
        break;
      case 'passnew3':
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='passnew4']").focus();
        } else {
          return false;
        }
        break;
      default:
        break;
    }
  };

  const ValidaNumero = (nombre, valor) => {
    if (nombre && !/^[0-9]+$/i.test(valor) && valor.length !== 1) {
      texterror[nombre] = 'Error';
      error[nombre] = true;
    } else {
      texterror[nombre] = '';
      error[nombre] = false;
    }
  };

  const ebtn = useCallback(() => {
    if (
      form.passnew1.length === 1
      && !error.passnew1
      && form.passnew2.length === 1
      && !error.passnew2
      && form.passnew3.length === 1
      && !error.passnew3
    ) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  });
  useEffect(() => {
    ebtn();
  }, [ebtn]);

  const guardar = (e) => {
    e.preventDefault();
    if (
      form.passnew1.length === 1
      && !error.passnew1
      && form.passnew2.length === 1
      && !error.passnew2
      && form.passnew3.length === 1
      && !error.passnew3
      && form.passnew4.length === 1
      && !error.passnew4
    ) {
      sessionStorage.setItem(
        'password_modal',
        form.passnew1 + form.passnew2 + form.passnew3 + form.passnew4,
      );
      const tagManagerArgs = {
        dataLayer: {
          event: 'clickIniciarsesioresetPass',
        },
      };
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickIniciarsesioresetPass',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      };
      createClickFront({ variables: createClickInput });
      TagManager.dataLayer(tagManagerArgs);

      try {
        changePassword({
          variables: {
            input: {
              email: sessionStorage.getItem('email'),
              password: `${
                form.passnew1 + form.passnew2 + form.passnew3 + form.passnew4
              }`,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openModal.openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ParagrapTwo
            fontWeight="700"
            cursor="pointer"
            texttalign="right"
            textalignMobile="right"
            className="mx-auto"
            onClick={handleClose}
          >
            X
          </ParagrapTwo>

          <SubtitleOne
            mt="5%"
            mb="10%"
            className="font-weight-bold roboto"
            sizeMobile="20px"
            size="20px"
          >
            Ingresa tu nueva contraseña
          </SubtitleOne>
          <ParagrapTwo className="mx-auto roboto" mb="0px">
            Para continuar es necesario escribir tu nueva contraseña de 4
            números que puedas recordar fácilmente para ingresar a tu cuenta
            posteriormente.
          </ParagrapTwo>
          <form autoComplete="off">
            <Container width="80%">
              <Col3>
                <TextField
                  id="passnew1"
                  name="passnew1"
                  label=""
                  type="tel"
                  value={form.passnew1}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 1);
                  }}
                  inputProps={{ className: 'text-center' }}
                />
              </Col3>
              <Col3>
                <TextField
                  id="passnew2"
                  name="passnew2"
                  label=""
                  type="tel"
                  value={form.passnew2}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 1);
                  }}
                  inputProps={{ className: 'text-center' }}
                />
              </Col3>
              <Col3>
                <TextField
                  id="passnew3"
                  name="passnew3"
                  label=""
                  type="tel"
                  value={form.passnew3}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 1);
                  }}
                  inputProps={{ className: 'text-center' }}
                />
              </Col3>
              <Col3>
                <TextField
                  id="passnew4"
                  name="passnew4"
                  label=""
                  type="tel"
                  value={form.passnew4}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 1);
                  }}
                  inputProps={{ className: 'text-center' }}
                />
              </Col3>
            </Container>
            <Container>
              <ParagraphOne fontSize="13px" mt="2%" mb="2%" className="roboto">
                {texterrorLogin}
              </ParagraphOne>
            </Container>
          </form>
          <Button
            type="button"
            onClick={guardar}
            disabled={btn}
            className="d-block  mt-md-4 roboto"
          >
            Guardar contraseña
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalPassword;
