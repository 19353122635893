export const sequence = {
  0: {
    style: 0,
    description:
			'Te damos entrenamientos y ejercicios personalizados para cuidar tu dinero, salir de deudas, aumentar ingresos y más.',
    question: '¿Cuál es tu prioridad financiera?',
    options: [
      {
        text: 'Quiero organizar bien mis gastos, presupuesto y/o ahorro',
        optionCode: 0,
      },
      {
        text: 'Busco mejores tarjetas, créditos y/o seguros',
        optionCode: 1,
      },
      {
        text: 'Quiero invertir mi dinero',
        optionCode: 2,
      },
      {
        text: 'Quiero salir de deudas y/o tener más ingresos',
        optionCode: 3,
      },
      {
        text: 'Otro',
        optionCode: 4,
      },
    ],
  },
  1: {
    style: 1,
    description:
			'Para ayudarte a cumplir tus metas, tenemos diferentes herramientas',
    question: 'Selecciona la que te ayuda más',
    options: [
      {
        answer_title: 'Ruta competitiva',
        text:
					'¡Mide tu avance financiero y supera a los demás rompiendo tus propios retos!',
        optionCode: 0,
        route: '/f1',
      },
      {
        answer_title: 'Ruta saludable',
        text:
					'Cuida al mismo tiempo la salud de tu mente, cuerpo, ¡y billetera!',
        optionCode: 1,
        route: '/f2',
      },
      {
        answer_title: 'Ruta ganar - ganar',
        text: 'Gana premios por avanzar en tus metas financieras.',
        optionCode: 2,
        route: '/f3',
      },
      {
        answer_title: 'Ruta paso a paso',
        text: '¡Ten tu propio entrenador financiero personal!',
        optionCode: 3,
        route: '/f4',
      },
      {
        answer_title: 'Desafío semanal',
        text:
					'Rápidamente notarás tus avances, ¡invita a tus amigos al reto semanal!',
        optionCode: 4,
        route: '/f5',
      },
    ],
  },
};
