import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { GET_AUTHENTICATE_BC } from '../../mutations';

const AuthenticateXCoach = ({
  idUser,
  firstName,
  lastName = '',
  fathersLastName,
  mothersLastName,
  email,
  rfc = '',
  street = '',
  extNum = '',
  intNum = '',
  zipCode = '',
  suburb = '',
  municipality = '',
  state = '',
  responseBuro,
  setResponseBuro,
  ultimosDigitos,
  bancoActual,
}) => {
  const digitoscard = ultimosDigitos || '';
  const activeCard = ultimosDigitos ? 'Si' : 'No';
  const mortgageCredit = 'No';
  const automotiveCredit = 'No';

  const [callAuthenticator] = useMutation(GET_AUTHENTICATE_BC, {
    fetchPolicy: 'no-cache',
    onCompleted({ callAuthenticator }) {
      if (
        callAuthenticator.activeCard === 'Si'
        || callAuthenticator.mortgageCredit === 'Si'
        || callAuthenticator.automotiveCredit === 'Si'
      ) {
        callAuthenticator.tieneHistorial = true;
      } else {
        callAuthenticator.tieneHistorial = false;
      }
      const buro = JSON.stringify(callAuthenticator).replace(/\\/g, '');
      setResponseBuro(buro);
    },
    onError(err) {
      console.log('error autenticion: ', err);
    },
  });

  useEffect(() => {
    const request = `primerNombre=${firstName}&segundoNombre=${lastName}&apPat=${fathersLastName}&apMat=${mothersLastName}&rfc=${rfc}&calle=${street}&noext=${extNum}&noint=${intNum}&cpostal=${zipCode}&colonia=${suburb}&deleg=${municipality}&estado=${state}&email=${email}&tarjeta=${activeCard}&digitos=${digitoscard}&hipotecario=${mortgageCredit}&automotriz=${automotiveCredit}&source=Coru`;
    const { domain } = window.document;
    const inputAuth = {
      input: {
        idUser: String(idUser),
        idOportunity: '',
        user: 'CORU',
        password: 'C0rU*2O19',
        dataPersonaEncode: btoa(request),
        bancoActual,
        host: String(domain),
      },
    };
    callAuthenticator({
      variables: inputAuth,
    });
  }, []);
  return <div></div>;
};

export default AuthenticateXCoach;
