import styled from 'styled-components'; 
import { LinkComponent, imageComponent } from '../../components/common/common'

export const Inicio_Title = styled.h3 `
    color: ${props => props.color ||  '#110e20'};
    font-weight: bold;
    font-size:${props => props.fontSize || '30px'};
    margin-top: ${props => props.mt || '5%'};
    margin-bottom : 5%;
    text-align: center;
`;
export const Inicio_Texts = styled.p `
    text-align: ${props => props.ta ||'center'};
    font-size: ${props => props.fontSize || '20px'};
    margin-top: ${props => props.mt || '3%'};
    color: ${props => props.color || '#110e20'};
    font-weight: ${props => props.fontWeight || '400'};
    cursor: ${props => props.cursor || 'default'};
    @media (min-width: 769px) {
        margin-top: 1rem;
    }
`;
export const Inicio_Container = styled.div `
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
`;
export const Inicio_Options = styled.div `
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
    width: 33.33%;
    text-align: ${props => props.ta ||'center'};
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-color: ${props => props.border_c ||'rgb(206, 206, 206)'};
    color: ${props => props.color || '#110e20'};
`;

export const  ContainerRutinas = styled.div `
    width: 100%;
    padding: ${props => props.padding || ''};
    min-height: ${props => props.minheight || ''}; 
    display: ${props => props.display || ''}; 
    margin: auto;
    margin-bottom: ${props => props.mb || '20px'};
    grid-column: span 12;
    -webkit-box-shadow: 6px 6px 15px -3px rgba(0,0,0,.75);
    -moz-box-shadow: 6px 6px 15px -3px rgba(0,0,0,.75);
    box-shadow: 6px 6px 15px -3px rgba(0,0,0,.75);
    height: ${props => props.height || '236px'}; 
    border-bottom: ${props => props.bbottom || ' '}; 
`;

export const  ContainerPerfiles = styled.div `
    width:  ${props => props.width || '100%'};
    padding: ${props => props.padding || '4%'};
    min-height: ${props => props.minheight || ''}; 
    display: ${props => props.display || ''}; 
    margin: auto;
    margin-bottom: ${props => props.mb || '10px'};
    margin-top: ${props => props.mt || ' '};
    grid-column: span 12;
    height: ${props => props.height || 'auto'}; 
    cursor: pointer;
    background: ${props => props.background || ' '}; /*rgb(255, 255, 255)*/
    border-radius: 10px;
    border-width:  ${props => props.borderW || '1px'};
    border-style: ${props => props.borderS || 'solid'};
    border-color:  ${props => props.borderC || 'rgb(219, 219, 219)'};
    border-image: initial;
    filter: ${props => props.filter || ''};
    @media (max-width: 769px) {
        margin-top: ${props => props.mtMobile || ' '};
        padding: ${props => props.paddingM || props.padding};
    }

`;

export const ImageShare = styled(imageComponent)`
    max-width: 100%; 
    width: ${props => props.width || '100%'};
    border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
    border: 0px solid #000000;
    border-radius: 5px;

`;

export const TextRutinas = styled.p`
    font-family: 'DM Sans', sans-serif !important;
    width: ${props => props.width || '90%'};
    margin: auto;
    margin-top: ${props => props.mt || '-35%'};
    margin-bottom: ${props => props.mb || 'auto'};
    color: ${props => props.color || 'fff'};
    font-size: ${props => props.fs || '20px'};
    text-align: left;
    line-height: 23px;
    font-weight : ${props => props.fw || '400'};
`;

export const TextPerfil = styled.span`
    font-family: 'Roboto', sans-serif !important;
    width: ${props => props.width || '90%'};
    display: ${props => props.display || 'block'}; 
    float : ${props => props.float || ' '}; 
    margin: auto;
    letter-spacing: 0.3px;
    color: ${props => props.color || '#4F4F4F'};
    font-size: ${props => props.fs || '20px'};
    text-align:  ${props => props.textAling || 'left'};
    line-height: ${props => props.lh || '23px'}; 
    font-weight : ${props => props.fw || '400'};
    margin-top: ${props => props.mt || ''};
    margin-bottom: ${props => props.mb || ''};
    border-bottom: ${props => props.bb || ''}; 
    cursor: ${props => props.cursor || ' '};
    @media (max-width: 769px) {
        display: ${props => props.display || 'block'}; 
        margin-top: ${props => props.mtMobile || '0%'};
        width: ${props => props.widthMobile || '100%'};
        font-size: ${props => props.fsMobile || '16px'};
        line-height: ${props => props.lh2 || '23px'};
        margin-left: ${props => props.mlMobile || ''};
    }
`;

export const TextPerfila = styled.a`
    font-family: 'DM Sans', sans-serif !important;
    width: ${props => props.width || '90%'};
    display: block;
    margin: auto;
    color: ${props => props.color || 'rgb(17, 14, 32)'};
    font-size: ${props => props.fs || '20px'};
    text-align:  ${props => props.textAling || 'left'};
    line-height: ${props => props.lh || '23px'}; 
    font-weight : ${props => props.fw || '400'};
    margin-top: ${props => props.mt || ''};
    margin-bottom: ${props => props.mb || ''};
    border-bottom: ${props => props.bb || ''}; 
    @media (max-width: 769px) {
        display: block;
        margin-top: ${props => props.mtMobile || '0%'};
        width: ${props => props.widthMobile || '100%'};
        font-size: ${props => props.fsMobile || '16px'};
        line-height: ${props => props.lh || '23px'};
        margin-left: ${props => props.mlMobile || ''};
    }
`;

    