// Componente "Step Creditea en CC" para Monetizar a usuarios con Creditea
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

// Para animaciones del scroll
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Componentes secundarios
import { useHistory } from 'react-router-dom';
import InputsOrder from '../../components/common/InputsOrder';
import {
  QuestionsDivs,
  CardsBtn,
  TrainingQuestion,
  TrainingDivSection,
} from '../../assets/styles/Training.styled';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7, Image } from '../../components/common/common.styled';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import SendNotificationFTAndCC from '../../components/common/SendNotificationFTAndCC';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import { CREATE_OPPORTUNITIES, SEND_CREDITEA_WL, GET_CURP } from '../../mutations';
import { INFOBIP_ACTIONS } from '../../Helpers/infobip';
import ClickButton from '../../Helpers/HookHelpers';
import { handleIngresoMensual } from '../../Helpers/general';
const StepCreditea = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;

  const [answer, guardaAnswer] = useState(0);
  const [respLit, guardaRespLit] = useState('');
  const [saltarStep, setSaltarStep] = useState(false);
  const [Rutine, setRutine] = useState(currentRoutine);
  const handleChangeRutine = (data) => setRutine(data);
  const { sendMessage } = SendNotificationFTAndCC();
  const { sendEmail } = SendNotificationEmail();
  const { actions } = ClickButton();

  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
      }
    },
    onError(err) {
      // console.log(err);
    },
  });

  const [sendCrediteaApplicationWL] = useMutation(SEND_CREDITEA_WL, {
    onCompleted({ sendCrediteaApplicationWL }) {
      if (sendCrediteaApplicationWL.message === "Exito") {
        // sessionStorage.setItem('showOportunidadCreditea', 'true');
        sessionStorage.setItem("clickoutCreditea", sendCrediteaApplicationWL.url);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createCURP] = useMutation(GET_CURP, {
    onCompleted({ createCURP }) {
      if (createCURP.statusCode === 200) {
        const curp = createCURP.response || '';
        let dataCreditea = {
          idUser: sessionStorage.getItem('idUser') || '',
          withOffer: 'false',
          Personal: {
            nombre: sessionStorage.getItem('FirstName') || '',
            nombre2: sessionStorage.getItem('MiddleName') || '',
            apellidoPaterno: sessionStorage.getItem('FathersLastName') || '',
            apellidoMaterno: sessionStorage.getItem('MothersLastName') || '',
            estadoCivil: sessionStorage.getItem('MaritalStatus') || '',
            Estudios: sessionStorage.getItem('Ulltimo_grado_de_estudios') || '',
            cellPhone: sessionStorage.getItem('CellPhone') || '',
            Email: sessionStorage.getItem('Email1') || '',
            identificacion: curp,
          },
          Credito: {
            trabajo: sessionStorage.getItem('occupation') || '',
            montoCreditoSolicitado: "0",
            mesesCreditoSolicitado: "0",
            salario: handleIngresoMensual(),
          },
          Direccion: {
            calle: sessionStorage.getItem('Street') || '',
            noExt: sessionStorage.getItem('ExtNo') || '',
            cp: sessionStorage.getItem('ZipCode') || '',
            municipio: sessionStorage.getItem('Municipality') || '',
          }
        };
        sendCrediteaApplicationWL({
          variables: {
            input: dataCreditea
          }
        });
      }
    },
  });

  // Animación final al contestar ultima pregunta
  const animaFinale = () => {
    setTimeout(() => {
      gsap.to(window, { duration: 1, scrollTo: '#activeStepCrediteaFinal' });
    }, 200);
  };

  // Si da cick en No busco préstamos por ahora
  const handleSaltarOportunidad = () => {
    triggerEvents();
    guardaAnswer(1);
    setSaltarStep(true);
    const click = `NoSolicitudCyK_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    guardaRespLit('No busco préstamos por ahora');
    questions.push({
      id: 'oportunidadCyK',
      question: 1,
      value: 897,
    });
    createJsonInput(Rutine, questions);
    //setQuestions([]); 
    //animaFinale();
    // setOrderRutine(nextOrderRoutine); //Habilitar si se agrega otra rutina despues
  };
  const triggerEvents = () => {
    const monthlyIncome = sessionStorage.getItem('Ingreso_Mensual_Fijo');
    const score = parseInt(sessionStorage.getItem('BCScore')) || 0

    if (score <= 540) {
      if (monthlyIncome === 'de_15000_a_30000' || monthlyIncome === 'mas_de_30000') {
        INFOBIP_ACTIONS.CREDIT_CARD.INGRESO_MAS15K_SCORE_BAJO();
      }
      else {
        INFOBIP_ACTIONS.CREDIT_CARD.INGRESO_MENOS15K_SCORE_BAJO();
      }
    } else if (score > 540 && score < 640) {
      if (monthlyIncome === 'de_15000_a_30000' || monthlyIncome === 'mas_de_30000') {
        INFOBIP_ACTIONS.CREDIT_CARD.INGRESO_MAS15K_SCORE_MEDIO();
      }
      else {
        INFOBIP_ACTIONS.CREDIT_CARD.INGRESO_MENOS15K_SCORE_MEDIO();
      }
    } else if (score >= 640) {
      if (monthlyIncome === 'de_15000_a_30000' || monthlyIncome === 'mas_de_30000') {
        INFOBIP_ACTIONS.CREDIT_CARD.INGRESO_MAS15K_SCORE_ALTO();
      }
      else {
        INFOBIP_ACTIONS.CREDIT_CARD.INGRESO_MENOS15K_SCORE_ALTO();
      }
    }
  }
  // Si da click en Sí, hacer la solicitud
  const handelChangeOportunidad = () => {
    triggerEvents();
    setSaltarStep(true);
    animaFinale();
    guardaRespLit('Sí, hacer la solicitud');
    const click = `SiSolicitudCyK_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    const opportunityKuesky = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 8,
        idProducts: [358],
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '493',
        value: 'Kuesky',
      },
    };
    const opportunityCreditea = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 8,
        idProducts: [364],
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        idType: '493',
        value: 'Creditea',
      },
    };
    createOpportunitiesUser({ variables: opportunityKuesky });
    createOpportunitiesUser({ variables: opportunityCreditea });

    const birthDatestr = sessionStorage.getItem('Birthdate').split('-');
    // const inputCURP = {
    //   input: {
    //     nombre: `${sessionStorage.getItem(
    //       'FirstName',
    //     )} ${sessionStorage.getItem('MiddleName')}`,
    //     apellido_paterno: sessionStorage.getItem('FathersLastName'),
    //     apellido_materno: sessionStorage.getItem('MothersLastName'),
    //     genero: sessionStorage.getItem('Gender'),
    //     estado: sessionStorage.getItem('BirthState'),
    //     fecha_nacimiento: sessionStorage.getItem('Birthdate')
    //       ? `${birthDatestr[2]}/${birthDatestr[1]}/${birthDatestr[0]}`
    //       : '',
    //   },
    // };
    // createCURP({ variables: inputCURP });
    
    guardaAnswer(1);
  };
  // Cambio de pantalla a resultados finales
  const history = useHistory();
  const hostcheck = window.location.pathname;
  useEffect(() => {
    if (answer !== 0) {
      sendMessage({
        cellPhone: sessionStorage.getItem('CellPhone'),
        trigger: 'Notificación_FT_5_AUX',
      });
      sendEmail({ WO: 'CC', trigger: 'alerta_cc_5_mail' });
      const timer = setTimeout(() => {
        if (hostcheck.includes('loans-funnel')) {
          history.push('/wo/personal-loans/success');
        } else if (hostcheck.includes('first-card')) {
          history.push('/wo/first-card/success');
        } else {
          history.push('/wo/card-funnel/success');
        }
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [answer]);

  // Animacion del scroll al cargar el componente
  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeStepCreditea' });
        setReviewScroll(false);
      }
    }, 2000);
  }, []);

  return (
    <div>
      {orderRutine === currentRoutine ? (
        <div>
          {!reviewScroll ? (
            <>
              <div id="activeStepCreditea"></div>
              <TrainingQuestion width="100%" wiw="100%" mt="0">
                <QuestionsDivs
                  padd="0"
                  txta="left"
                  width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
                  widthweb="100%"
                  hei="auto"
                  margin="0 auto auto"
                  bc="#fafafa"
                >
                  <form className="">
                    <>
                      <Container
                        width="80%"
                        mt="0"
                        widthMobile="90%"
                        mtMobile="0"
                      >
                        <ImageCoachInicio
                          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                          topMobile="35px"
                        />
                        <Col7
                          maxwidth="100%"
                          padding=" "
                          className="cardPrincipal1"
                        >
                          <TrainingDivSection
                            hew="auto"
                            paddw="26px"
                            mlw="0%"
                            mtw="8%"
                            wiw="100%"
                            back="#F3F3F3"
                            backw="#F3F3F3"
                            bshaw="none"
                            bsha=""
                            br="4px"
                            padding="20px"
                            wi="100%"
                            he="auto"
                            className="topOne1 topOne1Init"
                          >
                            <div
                              className="cardOne"
                              style={{ display: 'block' }}
                            >
                              <p className="bold-p">
                                ¡Te tenemos una buena noticia! 🤩
                              </p>
                              <br />
                              <p>
                                Todo indica que puedes aplicar también a algunos
                                préstamos personales acordes a tu perfil.
                              </p>
                              <br />
                              <p>
                                ¿Te gustaría que también hiciéramos una
                                solicitud a estos?
                              </p>
                            </div>
                            <br />
                            <div style={{ display: 'none' }}>
                              <InputsOrder
                                handleChangeRutine={handleChangeRutine}
                                orderRutine={orderRutine}
                                step="0-0"
                              />
                            </div>
                            <CardsBtn
                              type="button"
                              min_w="100%"
                              min_web="100%"
                              mtopw="0"
                              mtop="0"
                              className={
                                saltarStep !== true && saltarStep !== 'true'
                                  ? ''
                                  : 'hidden'
                              }
                              onClick={() => handelChangeOportunidad()}
                            >
                              Sí, hacer la solicitud
                            </CardsBtn>
                            <br />
                            <CardsBtn
                              min_w="100%"
                              min_web="100%"
                              mtop="12px"
                              mtopw="12px"
                              type="button"
                              onClick={(e) => handleSaltarOportunidad(e)}
                              color="#EC407A !important"
                              back="#fff !important"
                              border="solid 1px !important"
                              className={
                                saltarStep !== true && saltarStep !== 'true'
                                  ? ''
                                  : 'hidden'
                              }
                            >
                              No busco préstamos por ahora
                            </CardsBtn>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                marginTop: '20px',
                              }}
                            >
                              <div>
                                <Image
                                  src="https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CCreditea.png"
                                  maxwidth="130px"
                                  height="auto"
                                  heightMobile="auto"
                                  margin="auto"
                                />
                              </div>
                              <div>
                                <Image
                                  src="https://resources-new-coru.s3.us-east-2.amazonaws.com/images/products/C%3A%5Cfakepath%5CKueski.png"
                                  maxwidth="115px"
                                />
                              </div>
                            </div>
                          </TrainingDivSection>
                        </Col7>
                      </Container>
                    </>
                  </form>
                </QuestionsDivs>
              </TrainingQuestion>
            </>
          ) : (
              <>
                {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
                  <>
                    <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
                      {/* <ImageCoachInicio /> */}
                      <Col7 padding=' ' className='cardPrincipal1'>
                        {/* <LoadingWo /> */}
                      </Col7>
                    </Container>
                  </>
                ) : (
                  <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
                    <ImageCoachInicio />
                    <Col7 padding=' ' className='cardPrincipal1'>
                      <LoadingWo />
                    </Col7>
                  </Container>
                )}
              </>
          )}
          <CardTwo
            classCard={answer !== 0 ? 'cardTwoResp' : 'hidden'}
            classTop="topTwo1"
            imgMan="hidden"
            text={respLit}
            colorTraining={trainingColor}
          />
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={answer === 1 ? 'cardOne' : 'hidden'}
            classTop="topOne1"
            textbold="¡Gracias por tus respuestas! 😉"
            text="Espera unos segundos  mientras procesamos tu solicitud."
            hora={hora}
          />
          <Container
              width="80%"
              mt="0"
              widthMobile="90%"
              mtMobile="0"
              displayweb={answer === 1 ? 'flex' : 'none'}
              displaymobile={answer === 1 ? 'flex' : 'none'}
            >
              <ImageCoachInicio
                classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                topMobile="35px"
              />
              <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
                <LoadingWo />
              </Col7>
            </Container>
          {/* {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
            <>
              <Container
                width="80%"
                mt="0"
                widthMobile="90%"
                mtMobile="0"
                displayweb={answer === 1 ? 'flex' : 'none'}
                displaymobile={answer === 1 ? 'flex' : 'none'}
              >
                <ImageCoachInicio
                  classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                  topMobile="35px"
                />
                <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
                  <LoadingWo />
                </Col7>
              </Container>
            </>
          ) : (
            
          )} */}
          
          <div id="activeStepCrediteaFinal"></div>
        </div>
      ) : null}
    </div>
  );
};

export default StepCreditea;
