import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from './common.styled';
import Authenticate from '../../anaCoach/Training/Authenticate';
import {
  CardsBtn,
  ErrorDiv,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/styleComboScore.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '10px 0',
      width: '100%',
      padding: '15px 10px',
    },
    '& .MuiPopover-paper': {
      left: '462px !important',
    },
    '& .MuiMenu-paper': {
      minWidth: '28% !important',
    },
  },
  select: {
    '& .MuiSelect-root': {
      margin: '10px 0',
      width: '100%',
      padding: '15px 10px',
    },
    '& .MuiSelect-select': {
      width: '100%',
    },
  },
}));

const Container = styled.div`
  width: 60%;
  position: relative;
  margin-top: 20px;
  margin-left: 18%;
  padding: 22px 17px;
  background-color: rgba(166, 166, 166, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 768px){
    width: 90%;
    margin: 20px auto 0 auto;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const bancos = [
  'Bancomer',
  'Citibanamex',
  'Santander',
  'Scotiabank',
  'American Express',
];

const ScoreComboBox = ({
  setOrderRutine,
  nextOrderRoutine,
  checkBCReport = false,
  createSheet = false,
  setSkipStep,
  skipStep,
}) => {
  const classes = useStyles();
  const [bank, setBank] = useState(
    sessionStorage.getItem('Banco_tarjeta_actual_del_usuario') || '',
  );
  const [digitosCard, setdigitosCard] = useState(
    sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
  );
  const [rfc] = useState(sessionStorage.getItem('RFC') || '');
  const [triedAut, setTriedAut] = useState(1);
  const [responseBuro, setResponseBuro] = useState('');
  const [edit, setEdit] = useState(true);
  const [errorBC, setErrorBC] = useState(false);
  const [getAuthenticate, setGetAuthenticate] = useState(false);
  const [form, setForm] = useState({
    firstname: sessionStorage.getItem('FirstName') || '',
    lastname: sessionStorage.getItem('FathersLastName') || '',
    secondname: sessionStorage.getItem('MiddleName') || '',
    email: sessionStorage.getItem('Email1') || '',
    secondlastname: sessionStorage.getItem('MothersLastName') || '',
    day: sessionStorage.getItem('day') || Date.day,
    month: sessionStorage.getItem('month') || Date.month,
    year: sessionStorage.getItem('year') || Date.year,
    cel: sessionStorage.getItem('CellPhone') || '',
    directionMaps: '',
    mapsGeo: '',
    calle: sessionStorage.getItem('Street') || '',
    numext: sessionStorage.getItem('ExtNo') || '',
    numint: sessionStorage.getItem('IntNo') || '',
    cp: sessionStorage.getItem('ZipCode') || '',
    colonia: sessionStorage.getItem('Suburb') || '',
    municipio: sessionStorage.getItem('Municipality') || '',
    estado: sessionStorage.getItem('State') || '',
    telcasa: sessionStorage.getItem('HomePhone') || '',
    digitoscard: sessionStorage.getItem('Ultimos_4_digitos_de_tarjeta') || '',
  });

  useEffect(() => {
    console.log(responseBuro);
    setGetAuthenticate(false);
    if ((responseBuro.includes('no_autenticado') || responseBuro.includes('Exclusión')
        || !responseBuro.includes('BC Score') || responseBuro.includes('No Coincide con lo reportado en la Base de Datos'))
        && triedAut < 3 && responseBuro !== '') {
      setErrorBC(true);
      setEdit(false);
    } else if (responseBuro !== '') {
      setErrorBC(false);
      setSkipStep(true);
      setEdit(true);
      setOrderRutine(7);
    }
  }, [triedAut]);
  useEffect(() => {
    if (digitosCard !== '' && bank !== '') {
      sessionStorage.setItem('Ultimos_4_digitos_de_tarjeta', digitosCard);
      sessionStorage.setItem('Banco_tarjeta_actual_del_usuario', bank);
    }
  }, [digitosCard, bank]);
  return (
    <Container>
      <Row>
        <div style={{ width: '60%' }}>
        <p style={{ fontSize: '14px', fontWeight: '400' }}>
          Institución de tu tarjeta de crédito actual
        </p>
        </div>
        {edit && !skipStep ? (
        <div style={{ width: '43%' }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => setdigitosCard(e.target.value)}
            disabled={edit}
            value={ bank}
            padding="20px"
          />
        </div>)
          : (<Select
          style={{ width: '38%', border: 'solid #d3d3d3 1px', alignSelf: 'center' }}
          value={bank}
          disabled={edit}
          onChange={(e) => setBank(e.target.value)}
          autoWidth={false}>
            {bancos.map(
              (banco) => (
                <MenuItem value={banco} key={banco}>
                  {banco}
                </MenuItem>
              ),
            )}
          </Select>)}
      </Row>
      <Row>
        <div style={{ width: '60%' }}>
        <p style={{ fontSize: '14px', fontWeight: '400' }}>
          Últimos 4 dígitos del número de tu tarjeta de crédito
        </p>
        </div>
        <div style={{ width: '43%' }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            onChange={(e) => setdigitosCard(e.target.value)}
            disabled={edit}
            value={digitosCard} className={classes.root}
          />
        </div>
      </Row>
      {!skipStep ? (
      <CardsBtn
        type="button"
        min_w="100%"
        min_web="100%"
        mtopw="19px"
        mtop="19px"
        weight="bold"
        onClick={() => setGetAuthenticate(true)}
      >
        Confirmar
      </CardsBtn>) : null}
      {edit && !skipStep ? (
        <CardsBtn
          color="#EC407A !important"
          back="#fff !important"
          border="solid 1px !important"
          mtopmd="0px"
          mtop="0px"
          mtopw="0px"
          min_w="100%"
          min_web="100%"
          weight="bold"
          onClick={() => setEdit(false)}
        >
          Editar
        </CardsBtn>
      ) : null
      }
      <ErrorDiv className={errorBC ? '' : 'hidden'}>
        Por favor verifica tus datos y vuelve a intentar.
      </ErrorDiv>
      {triedAut < 3 && getAuthenticate ? (
        <Authenticate
          setOrderRutine={setOrderRutine}
          data={form}
          setForm={setForm}
          rfc={rfc}
          triedAut={triedAut}
          setTriedAut={setTriedAut}
          bancoActual={bank}
          responseBuro={responseBuro}
          setResponseBuro={setResponseBuro}
          nextOrdeRoutine={nextOrderRoutine}
          checkBCReport={checkBCReport}
          createSheet={createSheet}
        />) : null
      }
    </Container>
  );
};

export default ScoreComboBox;
