/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 50px;
  font-size: 14px;
  outline: none;
  border: solid 1px #cdcdcd;
  border-radius: ${(p) => p.borderR || '16px'};
  padding: ${(p) => p.padding || ''};
  color: #252525;
  &:focus {
    border: solid 1px #F64282;
  }
`;

function InputAna({
  placeholder,
  initialValue,
  handleChange,
  type,
  tipo,
  change,
  onBlur,
  nextQuestion,
  disabled = false,
  maxlength,
  value,
  handleBlur,
  texterror,
  error,
  errorinicial,
  id,
  className,
  borderR,
  padding,
  ...otherProps
}) {
  console.log();
  // console.log(error, errorinicial, texterror);
  // console.log(id, maxlength);
  return (
    <>
        <Input
        {...otherProps}
        borderR={borderR}
        id={id}
        name={id}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        className={className}
        maxLength={maxlength}
        value={value}
        padding={padding}
        />
        {error && errorinicial === 1
            && <p style={{ margin: "0px", color: "red" }} >{texterror}</p>
        }
    </>
  );
}

export default InputAna;
