import React, { useState, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import {
  Button,
  SubtitleOne,
  ParagraphOne,
  Main,
  ParagrapTwo,
  Carent,
  Col11,
  ContentButton,
  SpanLinkFooter,
  Container,
} from '../common.styled';
import '../../../assets/styles/TextCustom.css';

const CcPhoneValidate = () => {
  const [form, setForm] = useState({
    numPhone: '',
  });
  const [btndisabled, Setbtndisabled] = useState(true);
  const [valueError, SetvalueError] = useState(false);
  const [error, setError] = useState({
    numPhone: '',
  });

  const handleChange = (e) => {
    if (
      (e.target.value.length < 11 && e.target.value.length !== 10)
      || (e.target.name && !/^[0-9]+$/i.test(e.target.value))
    ) {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
      error.numPhone = 'Por favor verifica tu número telefónico';
      SetvalueError(true);
      Setbtndisabled(true);
    } else if (e.target.value.length < 11 && e.target.value.length === 10) {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });

      sessionStorage.setItem('cellPhone', e.target.value);

      error.numPhone = '';
      Setbtndisabled(false);
      SetvalueError(false);
    }
  };
  const guardar = (e) => {
    e.preventDefault();
    window.location.href = '/coach/training/adquieretutarjetadecredito/twinfunnel/validation-otp-cc';
  };
  const initSession = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = './login-cc';
  };

  return (
    <Fragment>
      <Main background="#fff" pb="10%" overflow="" minheigth="700px">
        <Container width="50%" mt="1rem" widthMobile="100%">
          <Col11>
            <SubtitleOne id="idTitle2" mt="0%">
              Verifica tu número
            </SubtitleOne>
            <ParagrapTwo className="mx-auto" mb="5%">
              Ingresa tu número telefónico para recibir un código de validación.
            </ParagrapTwo>
            <form
              autoComplete="off"
              className="width100 displaygrid"
              onSubmit={guardar}
            >
              <ParagrapTwo
                className="mx-auto intermediateWidth"
                mb="3%!important"
                fontWeight="bold"
                mt="15%"
                textalign="left"
              >
                México lada (+52) <Carent> > </Carent>
              </ParagrapTwo>
              <TextField
                id="numPhone"
                name="numPhone"
                autoFocus
                error={valueError}
                required
                label="Tu número a 10 digitos"
                type="tel"
                value={form.numPhone}
                onChange={handleChange}
                helperText={error.numPhone}
                className="intermediateWidth"
              />
              <ContentButton mt="2%">
                <Button
                  disabled={btndisabled}
                  href="/user-validation-otp/"
                  className="d-block mt-5 mt-md-4"
                >
                  Enviar código
                </Button>
                <ParagraphOne fontSize="15px">
                  ¿Ya tienes una cuenta ?{' '}
                  <SpanLinkFooter href="/login" onClick={initSession}>
                    {' '}
                    Iniciar Sesión{' '}
                  </SpanLinkFooter>{' '}
                </ParagraphOne>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
    </Fragment>
  );
};

export default CcPhoneValidate;
