/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import {
  DivFlex,
  TrainingText,
  BorderTask,
  StatusTask,
} from '../../assets/styles/Training.styled';

const TaskAdvance = ({ datos, saveAdvance }) => (
        <Fragment>
            {datos.map((post) => (
                <DivFlex widthweb="100%" marweb="auto" style={{ backgroundColor: '#FFFFFF' }}>
                    <BorderTask bs="none">
                        <TrainingText size="18px" weight="600" className="roboto" align="left" alignweb="left" ls="-0.2px" bot="0">
                            {post.taskName}
                        </TrainingText>
                        <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px" bot="0">
                            {`${post.day} - ${post.taskTime}`}
                        </TrainingText>
                        <TrainingText size="14px" sizew="14px" weight="400" className="roboto" align="left" alignweb="left" ls="-0.2px" >
                            {post.taskInfo}
                        </TrainingText>
                    </BorderTask>

                    {post.taskStatus === 'Incomplete'
                      ? <StatusTask fw="bold" Color="#F64282" bColor=" "
                            onClick={(e) => saveAdvance(e, post.idDatHomework, post.idHomeworkSchedule)}// post.idDatHomework
                            style={{
                              color: '#FFFF',
                              width: '125px',
                              padding: '8px',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }} >
                            Registrar avance
                        </StatusTask>
                      : <StatusTask fw="bold" Color="#FA9D59" bColor=" " style={{
                        color: '#FFFF',
                        width: '125px',
                        padding: '15px',
                        textAlign: 'center',
                      }} >
                            Completada
                        </StatusTask>
                    }

                </DivFlex>
            ))
            }
        </Fragment >
);
export default TaskAdvance;
