/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Nav,
  LogoNav,
  NavInsideContain,
  OptionsContainer,
  NavLinkItem,
  Contact,
  StartedButton,
  BurguerMenu,
  Menu,
  MenuOptions
} from "../Assets/Styles/CaasNew.styles";
import LogoNavBar from "../Assets/Imgs/coruCaas.svg";
import Burguer from "../Assets/Imgs/Menu.svg";
import Globe from "../Assets/Imgs/GlobeTranslate.svg";
import ClickButton from "../../../Helpers/HookHelpers";
import Products from "../Assets/Imgs/AiPersonalizada.svg";
import Solutions from "../Assets/Imgs/solutions.svg";
import Coru from "../Assets/Imgs/ICON CORU.svg";
import Success from "../Assets/Imgs/FEEDBACK_PINK.svg";

const Navbar = ({
  i1, i2, i3, i4, i5, i6, matches1, matches2, languageMenu, setLanguageMenu, url, urlbtnheader
}) => {
  const { actions } = ClickButton();
  const [menu, setMenu] = useState(false);
  const urlh = window.location.href;
  const widthWindow = window.screen.availWidth;
  const demo = urlh.includes("/agendademo");
  const handleClickCoru = (name) => {
    switch (name) {
      case "solutions":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_solutions_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "products":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_products_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "coru":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_coru_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "successstories":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_casosexito_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "traductor":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_traductor_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "getstarted":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_demo_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        // window.location.href = "/caas/agendademo";
        window.open(urlbtnheader, '_blank');
        break;
      case "BottonReadytoStartHero":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonDemo_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonLetsGetToWork":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_btndemoProductos_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonStartnow":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonStartnow`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonSeeallProducts":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonSeeallProducts`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      default:
        break;
    }
  };

  const handleLangMenu = () => {
    setLanguageMenu(!languageMenu);
    setMenu(false);
    handleClickCoru("traductor");
  };

  const handleMenu = () => {
    setMenu(!menu);
    setLanguageMenu(false);
  };
  const RegularNav = () => (
         <Nav>
        <LogoNav alt="" src={LogoNavBar} />
        <NavInsideContain>
        {matches1 ? (
                <>
                  <OptionsContainer>
                    <NavLinkItem
                      onClick={() => handleClickCoru("solutions")}
                    >
                      {i1}
                    </NavLinkItem>
                    <NavLinkItem
                      onClick={() => handleClickCoru("products")}
                    >
                      {i2}
                    </NavLinkItem>
                    <NavLinkItem
                      onClick={() => handleClickCoru("coru")}
                    >
                      {i3}
                    </NavLinkItem>
                    <NavLinkItem
                      onClick={() => handleClickCoru("successstories")}
                    >
                      {i4}
                    </NavLinkItem>
                  </OptionsContainer>
                  <a href={url} target="_blank" rel="noopener noreferrer" >
                    <Contact>
                      {i5}
                      { /* <ContactNumber>+52 55 5011-9400</ContactNumber> */ }
                    </Contact>
                  </a>
                  <img
                    alt=""
                    src={Globe}
                    onClick={() => handleLangMenu()}
                    style={{ cursor: "pointer" }}
                  />
                </>
        ) : null}
              {!matches1 && (
                <>
                  <img
                    alt=""
                    src={Globe}
                    onClick={() => handleLangMenu()}
                    style={{ cursor: "pointer" }}
                  />
                {widthWindow >= 769 ? <BurguerMenu
                  alt={"Burguer"}
                  src={Burguer}
                  onClick={() => handleMenu()}
                  style={{ cursor: "grab" }}
                /> : null}
                </>
              )}
              {menu
              && <Menu>
                  <MenuOptions fontFamily="unset" >
                  <img
                    alt="solutions"
                    src={Solutions}
                  />
                  Soluciones
                </MenuOptions>
                <MenuOptions fontFamily="unset">
                  <img
                    alt="products"
                    src={Products}
                    style={{ width: '50px' }}
                  />
                  Productos
                </MenuOptions>
             <MenuOptions fontFamily="unset">
                  <img
                    alt="coru"
                    src={Coru}
                  />
                  Coru
                </MenuOptions>
                    <MenuOptions fontFamily="unset">
                  <img
                    alt="success"
                    src={Success}
                    />
                  Casos de éxito
                </MenuOptions>
              </Menu>
              }
        </NavInsideContain>
      </Nav>
  );

  const DemoNav = () => (
        <Nav>
        <LogoNav alt="" src={LogoNavBar} />
        <NavInsideContain>
        {/* {matches1 ? ( */}
                <>
                  <a href={""} target="_blank" rel="noopener noreferrer" >
                    <Contact>
                      Contacto
                    </Contact>
                  </a>
                  <img
                    alt=""
                    src={Globe}
                    onClick={() => handleLangMenu()}
                    style={{ cursor: "pointer" }}
                  />
                </>
        {/* ) : null} */}
        </NavInsideContain>
      </Nav>
  );
  return  <RegularNav/>
};

export default Navbar;
