/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ContainerPerfiles } from "../../assets/styles/pantInicio.styled";
import {
  Container,
  Image,
  SubtitleOne,
  ContainerMenu,
} from "../../components/common/common.styled";
import "../../assets/styles/perfil/perfiles.css";
import ShareCommonFeed from "../Training/ShareCommonFeed";
import {
  ContentHomeSection,
  Rectangle24,
  Vector2,
  Vector3,
} from "../../components/common/Home.styled";
import Direction_Perfil from "./Direcction_Perfil";
import Personal from "./Personal_Information_perfil";
import Metodo from "./PaymentMethod";
import Notificacion_WO from "./Notifications";
import Avatarstruct from "../../components/Perfiles/Avatar";
import personalW from "../../assets/img/perfil/informacion.png";
import directionB from "../../assets/img/perfil/direction.png";
import personalB from "../../assets/img/anacoach/Login.png";
import pagoB from "../../assets/img/perfil/pago.png";
import directionW from "../../assets/img/perfil/mdi_pin_drop.svg";
import pagoW from "../../assets/img/perfil/mdi_payment.svg";
import notifiacionB from "../../assets/img/perfil/NotificacionB.png";
import notifiacionW from "../../assets/img/perfil/NotificacionW.png";
import Gana from "../../assets/img/logros/Gana.svg";
import {
  GET_NOTIFICATION_PRIVILEGE,
  GET_USER_CONTACTV2,
  // GET_USER_DETAILS,
  GET_USER_MEDIUM,
} from "../../queries";
import { CREATE_SESSION } from "../../mutations";
import Kanda from "../../components/Kanda/Kanda";
import ClickButton from "../../Helpers/HookHelpers";
import ShareWin from "../../components/common/BannerRewards";
import ModalVirality2 from "../../components/common/ModalVirality";
// import { set } from 'react-ga';
// import { typeFromAST } from 'graphql';

function FinancualProfile({ name }) {
  const [OpenModal, setOpenModal] = useState(false);
  const [userId, setUserid] = useState("");
  const [contId, setContid] = useState("");
  const { actions } = ClickButton();
  const handleOpenModal = () => {
    actions({ action: "ClickButtonCoruHome", variables: "PerfilRewards" });
    setOpenModal(!OpenModal);
  };
  useEffect(() => {
    document.querySelector("#root").style.backgroundColor = "#fafafa";
  });
  const tagManagerArgs = {
    dataLayer: {
      event: window.location.pathname,
      pageName: window.location.pathname,
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  const [Open, SetOpen] = useState({
    datos: true,
    direccion: false,
    metodo: false,
    notificacion: false,
  });
  // const history = useHistory();
  const handleClickPersonal = () => {
    actions({
      action: "ClickButtonCoruHome",
      variables: "PerfilDatosPersonales",
    });
    SetOpen({
      datos: true,
      direccion: false,
      metodo: false,
      notificacion: false,
    });
  };
  const handleClickDirecction = () => {
    actions({ action: "ClickButtonCoruHome", variables: "PerfilDirección" });
    SetOpen({
      datos: false,
      direccion: true,
      metodo: false,
      notificacion: false,
    });
  };

  const handleClickMetodo = () => {
    actions({ action: "ClickButtonCoruHome", variables: "PerfilMétodoDePago" });
    SetOpen({
      datos: false,
      direccion: false,
      metodo: true,
      notificacion: false,
    });
  };

  const handleClickNotificacion = () => {
    actions({
      action: "ClickButtonCoruHome",
      variables: "PerfilNotificaciones",
    });
    SetOpen({
      datos: false,
      direccion: false,
      metodo: false,
      notificacion: true,
    });
  };
  const [widthBrowser, setwidthBrowser] = useState(window.outerWidth);
  useEffect(() => {
    setwidthBrowser(window.outerWidth);
  }, [window.outerWidth]);

  const [getNotificationPrivilege] = useLazyQuery(GET_NOTIFICATION_PRIVILEGE, {
    // eslint-disable-next-line no-shadow
    onCompleted({ getNotificationPrivilege }) {
      if (getNotificationPrivilege.statusCode === 200) {
        sessionStorage.setItem("NotSMS", getNotificationPrivilege.response.sms);
        sessionStorage.setItem(
          "NotEmail",
          getNotificationPrivilege.response.email
        );
        sessionStorage.setItem(
          "NotWhat",
          getNotificationPrivilege.response.whatsapp
        );
      }
    },
    onError(err) {
      console.log(err);
      sessionStorage.setItem("NotSMS", "1");
      sessionStorage.setItem("NotEmail", "1");
      sessionStorage.setItem("NotWhat", "1");
    },
  });
  const [createLog] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        sessionStorage.setItem("id_logLogros", dynamicMongoSession.response);
      }
    },
  });
  // const [getUserDetails, { data: userDetailsData }] = useLazyQuery(GET_USER_DETAILS);
  const [getUserContactV2, { data: userContact }] =
    useLazyQuery(GET_USER_CONTACTV2);
  const [getUserMedium, { data: userMedium }] = useLazyQuery(GET_USER_MEDIUM);
  const id = sessionStorage.getItem("idUser");
  useEffect(() => {
    getNotificationPrivilege({ variables: { idUser: id } });
    const url = window.location.hash.split("#").filter((u) => u !== "");
    const idUser = url[0];
    const idCont = url[1];
    /* */
    setUserid(idUser);
    setContid(idCont);
    // if (idUser) getUserDetails({ variables: { idUser } });
    if (idCont) getUserContactV2({ variables: { idCont } });
    if (idCont) getUserMedium({ variables: { idCont } });
  }, []);
  useEffect(() => {
    if (userContact && userMedium && userId && contId) {
      const { response: resUserMedium } = userMedium.getUserMedium;
      const { response: resUserContact } = userContact.getUserContactV2;
      if (resUserMedium && resUserContact) {
        handleOpenModal();
        const url = new URL(window.location.href);
        const utm_source = url.searchParams.get("utm_source") || "";
        const utm_medium = url.searchParams.get("utm_medium") || "";
        const utm_campaign = url.searchParams.get("utm_campaign") || "";
        const utm_content = url.searchParams.get("utm_content") || "";
        const userContactRes = JSON.parse(resUserContact);
        const userMediumRes = JSON.parse(resUserMedium);
        const mongoInputBinacle = {
          idCont: contId,
          idUser: userId,
          FirstName: userContactRes.FirstName,
          MiddleName: userContactRes.MiddleName,
          FathersLastName: userContactRes.FathersLastName,
          MothersLastName: userContactRes.MothersLastName,
          email: userMediumRes.Email1 || userMediumRes.Email2,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
        };
        /*
        const mongoInputBinacle = {
          idCont: sessionStorage.getItem('idCont'),
          idUser: sessionStorage.getItem('idUser'),
          FirstName: sessionStorage.getItem('FirstName'),
          MiddleName: sessionStorage.getItem('MiddleName'),
          FathersLastName: sessionStorage.getItem('FathersLastName'),
          MothersLastName: sessionStorage.getItem('MothersLastName'),
          email: sessionStorage.getItem('Email1'),
        }; */
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        mongoInputBinacle.timestampEC = [
          {
            timeStamp: today,
            idCatRelAction: sessionStorage.getItem("idCatRelAction") || "",
          },
        ];
        const dataStringBinacle = JSON.stringify(mongoInputBinacle);
        const mongoInput = {
          input: {
            collection: "LogDeeplinkProfile",
            data: dataStringBinacle,
          },
        };
        createLog({ variables: mongoInput });
      }
    }
  }, [userContact, userMedium, userId, contId]);

  return (
    <Fragment>
      <Container
        width="100%"
        mt="1rem"
        widthMobile="94%"
        mtMobile="2rem"
        float="auto!important"
        className="container"
      >
        <ContainerMenu width="50%" mt="0px" mb="0px" paddingMobile="0px">
          <Avatarstruct name={name} />
          <ContainerPerfiles
            paddingM="5%"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            onClick={handleClickPersonal}
            background={Open.datos ? "#F64282" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.datos ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="20px"
              color={Open.datos ? "#fff" : "#07210A"}
            >
              {!Open.datos ? (
                <Image
                  src={personalB}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={personalW}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}{" "}
              Datos personales{" "}
            </SubtitleOne>
          </ContainerPerfiles>
          <ContainerPerfiles
            paddingM="5%"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            onClick={handleClickDirecction}
            background={Open.direccion ? "#F64282" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.direccion ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="20px"
              color={Open.direccion ? "#fff" : "#07210A"}
            >
              {!Open.direccion ? (
                <Image
                  src={directionB}
                  width="20px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={directionW}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}{" "}
              Dirección{" "}
            </SubtitleOne>
          </ContainerPerfiles>
          <ContainerPerfiles
            paddingM="5%"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            onClick={handleClickMetodo}
            background={Open.metodo ? "#F64282" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.metodo ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="20px"
              color={Open.metodo ? "#fff" : "#07210A"}
            >
              {!Open.metodo ? (
                <Image
                  src={pagoB}
                  width="25px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={pagoW}
                  width="30px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}{" "}
              Método de pago{" "}
            </SubtitleOne>
          </ContainerPerfiles>
          <ContainerPerfiles
            paddingM="5%"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            onClick={handleClickNotificacion}
            background={Open.notificacion ? "#F64282" : "#ffff"}
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw={Open.notificacion ? "bold" : "400!important"}
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="20px"
              color={Open.notificacion ? "#fff" : "#07210A"}
            >
              {!Open.notificacion ? (
                <Image
                  src={notifiacionB}
                  width="25px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              ) : (
                <Image
                  src={notifiacionW}
                  width="25px"
                  marginRight="10%"
                  marginRightM="3%"
                />
              )}{" "}
              Notificaciones
            </SubtitleOne>
          </ContainerPerfiles>
          <ContainerPerfiles
            borderS="none"
            paddingM="5%"
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            onClick={() => handleOpenModal()}
            background="#fff"
          >
            <SubtitleOne
              className="roboto"
              transform=" "
              textalingnMobile="left"
              fw="400"
              textalingn="left"
              mb="0%"
              size="20px"
              sizeMobile="18px"
              color="#07210A"
            >
              <Image
                src={Gana}
                width="30px"
                marginRight="10%"
                marginRightM="3%"
              />
              ¡Recomienda y gana!
            </SubtitleOne>
          </ContainerPerfiles>
          <Fragment>
            {widthBrowser >= 760 ? (
              <ContentHomeSection
                pos="relative"
                displayD="flex"
                leftD="0%"
                floatD="left"
                width="100%;"
              >
                <ContentHomeSection
                  leftD="0%"
                  floatD="left"
                  width="10%;"
                  widthM="0"
                ></ContentHomeSection>
                <ContentHomeSection
                  leftD="0%"
                  floatD="left"
                  width="2000%"
                  widthM="100%"
                >
                  {/* <ShareCommonFeed
                    url="feed"
                    trainingName="Logros"
                    textShare="¡Ayuda a que más personas conozcan Coru! Comparte en:"
                    btn={true}
                    mtop="0px"
                  ></ShareCommonFeed> */}
                  <ShareWin
                    bannerType="wo"
                    margin="20px auto 80px"
                    width="100%"
                    name="Perfil"
                  />
                  <div className="KandaProfileWeb">
                    <Kanda goToDiagnostico="DiagnosticoX" />
                  </div>
                </ContentHomeSection>
                <ContentHomeSection
                  leftD="0%"
                  floatD="left"
                  width="10%;"
                  widthM="0"
                ></ContentHomeSection>
              </ContentHomeSection>
            ) : null}
          </Fragment>
        </ContainerMenu>
        <ContainerMenu width="50%" padding="2rem 0px" margin=" " mt=" " mb=" ">
          {Open.datos ? (
            <Personal Novisible={true} paddingInicio={true} />
          ) : Open.direccion ? (
            <Direction_Perfil Novisible={true} paddingInicio={true} />
          ) : Open.metodo ? (
            <Metodo Novisible={true} paddingInicio={true} />
          ) : (
            <Notificacion_WO Novisible={true} paddingInicio={true} />
          )}
        </ContainerMenu>
        {widthBrowser < 760 ? (
          <Fragment>
            <ContentHomeSection
              pos="relative"
              displayD="flex"
              leftD="0%"
              floatD="left"
              width="100%;"
            >
              <Rectangle24
                positionD="absolute"
                Ptop="125px"
                Pleft="-13%"
                background="#6BD5AF"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="70px"
                height="70px"
                transform="matrix(-0.96, -0.3, -0.3, 0.96, 0, 0);"
              />
              <Rectangle24
                positionD="absolute"
                Ptop="190px"
                Pleft="10%"
                background="#BFDD6A"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
                width="30.94px"
                height="30.94px"
                transform="rotate(46.41deg)"
              />
              <Vector2
                width="50px"
                left="0"
                height="60px"
                background="#91D770"
                radius="40%"
                transform="matrix(-0.97, -0.25, -0.25, 0.97, 0, 0)"
                positionD="absolute"
                Pleft="5%"
                Ptop="45px"
                displayM="none"
              />
              <Vector3
                position="absolute"
                left=" "
                top=" "
                Ptop="268px"
                Pleft="-2%"
                background=" #E3E660;"
                transform="matrix(-0.66, 0.75, 0.75, 0.66, 0, 0)"
                width="65px"
                height="65px"
                displayD=" "
                displayM="none"
              />
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="10%;"
                widthM="0"
              ></ContentHomeSection>
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="80%;"
                widthM="100%"
              >
                <Rectangle24
                  positionD="absolute"
                  Ptop="145px"
                  Pleft="88%"
                  background="#BFDD6A"
                  displayM="none"
                  displayD=" "
                  left=" "
                  top=" "
                  width="30.94px"
                  height="30.94px"
                  transform="rotate(46.41deg)"
                />
                <Vector2
                  width="50px"
                  left="0"
                  height="60px"
                  background="#6BD5AF"
                  radius="40%"
                  transform="rotate(-8.34deg)"
                  positionD="absolute"
                  Pleft="83%"
                  Ptop="285px"
                  displayM="none"
                />
                <Vector3
                  position="absolute"
                  left=" "
                  top=" "
                  Ptop="45px"
                  Pleft="80%"
                  background="#E3E660"
                  transform="rotate(-15.06deg)"
                  width="50px"
                  height="50px"
                  displayD=" "
                  displayM="none"
                />
                <Rectangle24
                  positionD="absolute"
                  Pleft="94%"
                  Ptop="220px"
                  displayD=""
                  width="60px"
                  height="60px"
                  left=" "
                  top=" "
                  background="#91D770"
                  transform="rotate(-17.24deg)"
                />
                <div style={{ marginBottom: "120px" }} className="KandaProfile">
                  <Kanda goToDiagnostico="DiagnosticoX" />
                </div>
                <ShareWin
                  bannerType="mobile"
                  margin="20px auto 80px"
                  width="100%"
                  name="Perfil"
                />
              </ContentHomeSection>
              <ContentHomeSection
                leftD="0%"
                floatD="left"
                width="10%;"
                widthM="0"
              ></ContentHomeSection>
            </ContentHomeSection>
          </Fragment>
        ) : null}
      </Container>
      {OpenModal ? (
        <ModalVirality2 setShowModal={handleOpenModal} showModal={OpenModal} />
      ) : null}
    </Fragment>
  );
}
export default FinancualProfile;
