/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_TRAININGS } from '../../queries';
import {
  GET_RECOMMENDATION_TRAININGS,
} from '../../mutations';
import {
  TrainingMain,
  TrainingDivSection2,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import {
  Container,
} from '../../assets/styles/Home.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import { ContentHomeSection } from './Home.styled';
import Recommendations from '../Training/RecommendedTraining';
import image1 from '../../assets/img/anacoach/mejor tarjeta.jpg';
import SectionShare from './ShareSection';
import ModalAmazon from './ModalAmazon';

const ShareTraining = ({
  nameTraining, currentTraining, currentTrainingURL, propertiesStatusGoals,
}) => {
  const [goFeed] = useState(false);
  const history = useHistory();
  const [stateInformativo, setInformativo] = useState(false);
  const [stateOutstanding, setOutstanding] = useState(false);
  const validateInformativo = [];
  const validateOutstanding = [];
  const validateVertical = [];
  const validateTwin = [];
  const validateMedium = [];
  const validateBig = [];
  const trainingBig = [];
  const trainingVertical = []; // vertical cc
  const items4 = []; // entrenamiento destacado
  const itemsMore = []; // informativos
  const trainingTwin = []; // tiwn

  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(GET_RECOMMENDATION_TRAININGS, {
    onCompleted({ getPersonalizeRecommendationTrainings }) {
      if (getPersonalizeRecommendationTrainings.statusCode === 200) {
        if (propertiesStatusGoals.length < 1) {
          propertiesStatusGoals.push({ color: '', idTraining: -1 });
        }
        const filter = getPersonalizeRecommendationTrainings.response.filter((e) => e.IDCatTraining === propertiesStatusGoals[0].idTraining
               || e.IDCatTraining === propertiesStatusGoals[1].idTraining);

        setRecommendedTraining(filter);
        setRecomendaciones(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  useEffect(() => {
    if (goFeed) {
      history.push('/the-training-feed/');
    }
  }, [goFeed]);

  const pushInformativo = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateInformativo.indexOf(idTrainning) !== -1)) {
      const sectionInformativo = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: image1,
        TrainingRedirectUrl: '',
      };
      sectionInformativo.id = arrValue.IDCatTrainingAvailability;
      sectionInformativo.idTrainning = arrValue.IDCatTraining;
      sectionInformativo.name = arrValue.TrainingBigTitle;
      sectionInformativo.parrafo1 = arrValue.TrainingBigDescription;
      sectionInformativo.image = arrValue.UrlBigImage;
      sectionInformativo.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionInformativo.Liked = arrValue.Liked;
      sectionInformativo.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      itemsMore.push(sectionInformativo);
      validateInformativo.push(arrValue.IDCatTraining);
      if (itemsMore.length >= 1 && !stateInformativo) {
        setInformativo(true);
      }
    }
  };

  const pushOutstanding = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateOutstanding.indexOf(idTrainning) !== -1)) {
      const sectionOutstanding = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        Liked: '',
        image: '',
        TrainingRedirectUrl: '',
      };
      sectionOutstanding.id = arrValue.IDCatTrainingAvailability;
      sectionOutstanding.idTrainning = arrValue.IDCatTraining;
      sectionOutstanding.name = arrValue.TrainingBigTitle;
      sectionOutstanding.parrafo1 = arrValue.TrainingBigDescription;
      sectionOutstanding.image = arrValue.UrlBigImage;
      sectionOutstanding.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionOutstanding.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      sectionOutstanding.Liked = arrValue.Liked;
      items4.push(sectionOutstanding);
      validateOutstanding.push(arrValue.IDCatTraining);
      if (items4.length >= 1 && !stateOutstanding) {
        setOutstanding(true);
      }
    }
  };
  const pushCCVertical = (arrValue) => {
    const idTrainning = arrValue.IDCatTraining;
    if (!(validateVertical.indexOf(idTrainning) !== -1)) {
      const sectionVertical = {
        id: '',
        idTrainning: '',
        name: '',
        parrafo1: '',
        color: '#fff',
        image: '',
        Liked: '',
      };
      sectionVertical.id = arrValue.IDCatTrainingAvailability;
      sectionVertical.idTrainning = arrValue.IDCatTraining;
      sectionVertical.name = arrValue.TrainingBigTitle;
      sectionVertical.parrafo1 = arrValue.TrainingBigDescription;
      sectionVertical.image = arrValue.UrlBigImage;
      sectionVertical.time = `Tiempo de entrenamiento: ${arrValue.TrainingTime} minutos`;
      sectionVertical.Liked = arrValue.Liked;
      sectionVertical.TrainingRedirectUrl = arrValue.TrainingRedirectUrl;
      trainingVertical.push(sectionVertical);
      validateVertical.push(idTrainning);
    }
  };
  const idUser = sessionStorage.getItem('idUser') || 0;
  const vars = { variables: { idUser: Number(idUser) }, fetchPolicy: 'no-cache' };
  const { data: dataTraining, loading, error } = useQuery(GET_TRAININGS, vars);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (dataTraining) {
    const trainingList = dataTraining.getTrainings;
    const mapPayload = (item) => ({
      id: item.IDCatTrainingAvailability,
      idTrainning: item.IDCatTraining,
      name: item.TrainingBigTitle,
      parrafo1: item.TrainingBigDescription,
      image: item.UrlBigImage,
      time: `Tiempo de entrenamiento:: ${item.TrainingTime} minutos`,
      Liked: item.Liked,
      TrainingRedirectUrl: item.TrainingRedirectUrl,
      Show: item.Show || true,
      color: item.TrainingColor,
      achievment: item.Achievment,
      status: item.Status,
      category: item.IDCategory,
    });
    trainingList.forEach((training) => {
      switch (training.Section) {
        case 'Big':
          if (validateBig.indexOf(training.IDCatTraining) === -1) {
            trainingBig.push(mapPayload(training));
            validateBig.push(training.IDCatTraining);
          }
          break;
        case 'Medium':
          // items2.push(sectionMedium);
          break;
        case 'Twin':
          if (validateTwin.indexOf(training.IDCatTraining) === -1) {
            trainingTwin.push({
              ...mapPayload(training),
              image: training.UrlTwinImage,
              title: training.TrainingTwinTitle,
            });
            validateTwin.push(training.IDCatTraining);
          }
          break;
        default: break;
      }
      if (training.TrainingType === 'Informativo') {
        pushInformativo(training);
      }
      if (training.Outstanding === 1) {
        pushOutstanding(training);
      }
      if (training.VerticalName === 'Tarjetas de crédito') {
        pushCCVertical(training);
      }
    });
  }

  return (
      <Fragment>
         <TrainingMain mt="0px" mtw="0" className="" id="top">
            <TrainingSectionGray back="#fafafa">
               <Feed_Main width="100%" background=" ">
                  <Feed_Main width="100%" display=" " mtop=" " background="transparent">
                    {/*  <SeparateSection />
 */}
                     <TrainingSectionGray back="#fafafa">
                        <TrainingDivSection2 wiw="100%" paddi="0" paddiw="0" mw="auto" back="#fafafa">
                          <div className="marginComentLgSuccess1">
                              <Container
                              mlMobile='auto'
                              mRMobile='auto'
                              mleftmxs="0%"
                              mleft="18%"
                              mleftm="7%"
                              width="100%"
                              widthMobile="100%"
                              mtMobile="0px"
                              >
                                <Feed_Section padding="3% 0 5% 0" paddingm="0% 5% 5% 5%">
                                  <ContentHomeSection topD='0px' topMm320='0px' leftD="0%" floatD=" " width="80%;">
                                    {
                                    recomendaciones
                                      ? <Fragment>
                                    <Recommendations items={recommendedTraining}></Recommendations>
                                    </Fragment>
                                      : null
                                    }
                                  </ContentHomeSection>
                                </Feed_Section>
                              </Container>
                            </div>
                           <ModalAmazon show={true} wo={'Goals'}></ModalAmazon>

                          <SectionShare WO="Goals"></SectionShare>
                        </TrainingDivSection2>
                     </TrainingSectionGray>
                  </Feed_Main>
               </Feed_Main>
            </TrainingSectionGray>
         </TrainingMain>
      </Fragment>
  );
};
export default ShareTraining;
