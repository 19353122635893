import React from 'react';
import './styles.css';
// import CoruLogo from '../../../../assets/img/home/CoruLogoB.svg';
import El from '../../../../assets/img/anacoach/El.png';

const index = ({ type = 0, content }) => {
  /**
   * Con type se segmentan los mensajes en un valor númerico donde:
   * 0 --> Representa un mensaje del usuario cliente (Este es el default);
   * 1 --> Representa un mensaje respuesta del bot
   * 2 --> Representa un mensaje neutro
   *
   * Con content se pueden recibir dos tipos de datos, un json que contendra titulo, descripción y respuesta en caso de las FAQ comunes; o un simple String que sera el contenido del mensaje
   */
  const def = (
    <>
      <div className="helper-message">
        <div className="helper-message-bubble-right">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsum,
          neque!
        </div>
        <div className="helper-message-icon-right">
          <img src={El} alt="Coru" />
        </div>
      </div>
    </>
  );
  switch (type) {
    case 0:
      return def;
    case 1:
      return 'uno';
    case 2:
      return 'dos';
    default:
      break;
  }
};

export default index;
