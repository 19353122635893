/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery, useMutation } from '@apollo/client';
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { GET_RECOMMENDATION_TRAININGS } from '../../mutations';
import { arraySessionsRutines } from '../../Helpers';
import { TrainingQuestion } from '../../assets/styles/Training.styled';
import { ContentHomeSection } from '../../components/common/Home.styled';
import { Feed_Main, Feed_Section } from '../../assets/styles/Feed.styled';
import SectionVideo from '../../components/common/SectionVideo';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import Recommendations from '../../components/Training/RecommendedTraining';
import ShareDebts from '../Training/SectionShareDebts';

const VideoAutomotriz = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [recomendaciones, setRecomendaciones] = useState(false);
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          setRecomendaciones(true);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);
  // State used for recoveryData process and validations
  // const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({});
  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);
  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);
  };
  const handleChange = (e, question = '') => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          // setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log('video automotriz', routines);
          handleChangeRutine(Object.values(routines)[0]);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {

                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setQuestions([]);
              break;
            } else {
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const anchop = window.screen.width;

  // const title = 'Aquí estan las principales alternativas para liberar esta deuda.';
  const title = 'Estas son las principales alternativas para liberar esta deuda:';
  const benefits = [
    '1. Haz un presupuesto y ajusta tus gastos para que puedas darle prioridad y liquidez la deuda, convierte tus gastos hormiga en abonos a capital.        ',
    '2. Busca un plan de refinanciamiento con la misma institución con la que tienes la deuda, hay ocasiones en las que podrían ampliar el plazo para que las mensualidades disminuyan y no te atrases con los pagos.',
    '3. Traslada la deuda a una segunda institución que pueda otorgar un crédito con mejores condiciones (plazo y tasa), de esta forma ajustarás tus finanzas para que no caigas en incumplimiento.',
    'Una empresa de préstamos nos comparte su punto de vista y los detalles que debemos revisar, no te pierdas este video: ',
  ];
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    }
  }, []);
  useEffect(() => {
    handleChangeQuestionsSend(1, 'VideoAutomotriz', 'true');
    createJsonInput(Rutine, questions);
  }, []);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (reviewScroll) {
        gsap.to(window, { duration: 1, scrollTo: '#activeVideoAuto' });
        setReviewScroll(false);
      }
    }, 1200);
  }, []);

  return (
    <div>
      <div id="activeVideoAuto"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard="cardOne"
        classTop="topOne1"
        text="Continuemos"
      />
      <TrainingQuestion>
        <SectionVideo title={title} benefits={benefits}>
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={currentRoutine}
            step="0-0"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
            opcVideo="?start=35"
          />
        </SectionVideo>
      </TrainingQuestion>
      {recomendaciones ? (
        <Feed_Main width="100%" background=" ">
          <Feed_Main width="100%" display=" " mtop=" " background="transparent">
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="¿Aún quieres ir por más?"
              text2="No olvides que no tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande."
            />
            <CardOne
              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
              classCard="cardOne"
              classTop="topOne1"
              text="Estas son algunas sugerencias de entrenamientos que tenemos para ti."
            />
            <Feed_Section padding="0 0 5% 0" paddingm="0% 5% 5% 5%">
              <ContentHomeSection leftD="0%" floatD=" " width="100%;">
                <Recommendations items={recommendedTraining}></Recommendations>
              </ContentHomeSection>
            </Feed_Section>
          </Feed_Main>
        </Feed_Main>
      ) : null}
      <ShareDebts></ShareDebts>
    </div>
  );
};

export default VideoAutomotriz;
