import React, { useState } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {
  Shapes,
  Rectangle23,
  Rectangle25,
  Rectangle24,
  Vector1,
  Title1,
  Subtitle,
  ContentHomeSection,
  ContainerBottons,
  Rombo,
  Rectangle20,
  BtnPlay,
} from '../common/Home.styled';
import { Button, Image, Container } from '../common/common.styled';
import Pesas from '../../assets/img/home/FIT.svg';
import ClickButton from '../../Helpers/HookHelpers';

function SectionObten({ initializeNow, initializeLogin, heigthBrowser }) {
  const [Play, setPlay] = useState(false);
  const { actions } = ClickButton();
  const [url, setUrl] = useState(
    'https://www.youtube.com/embed/_H8GwfHLolg?rel=0&autoplay=1&loop=1&modestbranding=1&playsinline=1&showinfo=0&controls=1',
  );

  return (
    <Shapes
      id="Shapes1"
      background="#FAFAFA"
      key={1}
      top="100px"
      left="20px"
      heightD="555px"
      height={heigthBrowser >= 600 ? '600px' : '480px'}
      width="100%"
      position=" "
    >
      <Container
        width="80%"
        mt="0rem"
        widthMobile="100%"
        mb="0%"
        mtMobile="0rem"
      >
        <ContentHomeSection leftD=" " floatD=" " width="100%">
          <Rectangle23
            Ptop="100px"
            Pleft="80%"
            position="absolute"
            displayD=" "
            displayM="none"
          />
          <Rectangle25
            positionD="absolute"
            left="63%"
            top="107px"
            displayM="none"
            displayD=" "
          />
          <Rectangle24
            positionD="absolute"
            Ptop="120px"
            Pleft="97%"
            displayM="none"
            displayD=" "
            left=" "
            top=" "
          />
          <Vector1
            positionD="absolute"
            Pleft="52%"
            Ptop="2.9%"
            displayM="none"
            displayD=" "
          />
          <BtnPlay
            className="Play"
            displayD={!Play ? '' : 'none'}
            displayMobile="none"
            onClick={() => {
              setPlay(true);
              actions({ action: 'ClickButtonCoruHome', variables: 'HomeVideoPlay'});
            }}
          >
            <PlayArrowIcon style={{ color: '#fff', fontSize: '60px' }} />
          </BtnPlay>
          <Title1
            id="Title1"
            mtopM={heigthBrowser >= 550 ? '80px' : '45px'}
            className="roboto cabin"
          >
            ¡Dile adiós al
            <br />
            estrés financiero!
          </Title1>
          <div className="d-flex flex-column">
            <div className="p-2">
              <Subtitle
                id="Subtitle"
                className="roboto"
                mtopM="8px"
                mbottomM="5%"
              >
                Entrena en nuestro gimnasio, toma decisiones inteligentes y
                mejora tu calidad de vida.{' '}
              </Subtitle>
            </div>
            <div className="p-2">
              <iframe
                width="90%"
                height="220px"
                className="video"
                src="https://www.youtube.com/embed/_H8GwfHLolg?rel=0&loop=1&showinfo=0&controls=1&modestbranding=1&loop=1"
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; fullscreen; encrypted-media; gyroscope; picture-in-picture; allowpaymentrequest"
                mozallowfullscreen=""
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                oallowfullscreen
                msallowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-5 col-12">
            <ContainerBottons
              widthD="87%"
              position=" "
              left="0.5%"
              floatD="left"
              displayD="block"
              displayM="none"
              onClick={initializeNow}
            >
              <Button className="roboto" onClick={(e)=> initializeNow(e, 'EntrenaAhora')}>
                ¡Entrena ahora!
              </Button>
            </ContainerBottons>
          </div>
          <Image
            src={Pesas}
            height="380px"
            maxwidth="430px"
            displayWeb={!Play ? ' ' : 'none'}
            width="30%"
            position="absolute"
            left="59%"
            top="150px"
            displayMobile="none"
          />
          <Rombo displayD=" " displayM="none" marginleft="50%" margintop="0%" />
          <Rectangle20 displayD=" " displayM="none" marginleft="103%" />
          <Rectangle23 top="-125%" displayD="none" displayM="block" />
          <Rectangle25 displayD="none" displayM="block" />
          <Rectangle24 displayD="none" displayM="block" />
        </ContentHomeSection>
      </Container>
      <ContentHomeSection
        leftD="0px"
        floatD="right"
        rigthD="0%"
        topD="-485px"
        width="40%"
        displayD=" "
        displayM="none"
      >
        <Shapes
          id="Shapes-medio"
          positionD=" "
          position="absolute"
          zindex="-1"
          displayD={Play ? ' ' : 'none'}
        >
          <iframe
            type="text/html"
            width="500"
            height="315"
            className="videoHome"
            src={Play ? url : ''}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Shapes>
      </ContentHomeSection>
    </Shapes>
  );
}

export default SectionObten;
