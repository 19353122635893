import React, { Fragment } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Col7, Container, Col2, Col10,
} from '../common/common.styled';
import ImageCoachInicio from './ImageCoachInicio';
import {
  TrainingSubtitles,
  TrainingText,
  TrainingDivSection,
  TrainingBenef,
} from '../../assets/styles/Training.styled';

function FeaturesList({
  title,
  Arraytext,
  text2,
  text3,
  text4,
  textintro,
  children,
  titleAlign = 'left',
  colorb='#F3F3F3',
  isGloveBox
}) {
  return (
    <Fragment>
     <Col7
          maxwidth="100%"
          padding="0px "
        className="cardPrincipal1"
        >
          <TrainingDivSection
            mt="0px"
            hew="auto"
            paddw="26px"
            mlw="0%"
            wiw="100%"
            back="#F3F3F3"
            backw={colorb}
            bshaw="none"
            bsha=""
            br="0px"
            //padding="20px"
            wi="100%"
            he="auto"
            mb="0px"
            
            className="topOne1Init"
          >
            
            {Arraytext[0].map((feacture, index) => (
              <Container
                key={index}
                width="100%"
              
                widthMobile="100%"
                mt="0px"
                mtMobile="0rem" /* bb= {index < Arraytext.length ? "1px solid #CDCDCD" : " " } */
              >
                <Col2 display="flex" mt="initial">
                  <TrainingBenef
                     ml="-20px"
                    bbs="none"
                    bbsw="none"
                    bbw="1px"
                    bbc="#CDCDCD"
                    pt="17px"
                    pb="17px"
                    he="auto"
                    pl="0px"
                    mt="0px"
                    className="roboto"
                    alignweb="center"
                    ls="-0.2px"
                    width="100%"
                  >
                    <CheckBoxIcon
                      style={{ color: feacture.color, fontSize: 23 }}
                    />
                  </TrainingBenef>
                </Col2>
                <Col10 padding="0px" pMobile="0px 15px">
                  <TrainingBenef
                    borderBottom= {index < Arraytext[0].length - 1 && isGloveBox ? "1px solid #CDCDCD" : " " }
                    pt="17px"
                    pb="17px"
                    he="auto"
                    pl="0px"
                    mt="0px"
                    ml="20px"
                    className="roboto"
                    alignweb="left"
                    ls="-0.2px"
                    size="16px"
                  >
                    {feacture.text}
                  </TrainingBenef>
                </Col10>
              </Container>
            ))}

            {text2 != null ? (
              <TrainingText
                size="16px"
                className="roboto"
                align="left"
                alignweb="left"
                ls="-0.2px"
              >
                {text2}
              </TrainingText>
            ) : null}
            {children}
          </TrainingDivSection>
        </Col7>
    </Fragment>
  );
}

export default FeaturesList;