import React, { Fragment, useEffect } from 'react';
import {
  CardsBtn,
  TrainingSectionGray,
} from '../../assets/styles/Training.styled';
import { Container } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';
import CardOne from '../../components/Home/Card1Animate';

const TaskLog = ({
  infoChildren,
  setShowSchedule,
  showWindow2,
  setShowWindow2,
}) => {
  const { actions } = ClickButton();

  useEffect(() => {
    if (showWindow2) {
      const click = `ContinuarvideoRecompensas_${sessionStorage.getItem('idTraining')}`;
      actions({ action: 'ClickButtonCollection', variables: click });
    }
  }, [showWindow2]);

  return (
    <Fragment>
      {!showWindow2 ? (
        <Fragment>
          <CardOne
            classCard={'cardOne'}
            classTop="topOne1"
            text={`¡Hola, ${
              infoChildren[0].childData.name
                ? infoChildren[0].childData.name
                : 'Gael'
            }! Soy Ana, tu nueva coach online. Tu mamá o tu papá te asignaron unas tareas. Y cada una tiene una recompensa por cumplirlas. 😉`}
          />
          <CardOne
            classCard={'cardOne'}
            classTop="topOne1"
            text="¿Sabes cómo ganarte esa recompensa? Este video te dirá cómo:"
          />
          <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
            <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
              <CardsBtn
                onClick={() => setShowWindow2(true)}
                windthmd="90%"
                minmd="40%"
                mleftmd="5%"
                mrightmd="auto"
                windthG="400px"
                type="button"
                min_w="400px"
                min_web="400px"
                min_mini="80%"
                mleftmini="10%"
                mrightmini="10%"
                mleft="15%"
                mright="15%"
                mleftweb="18%"
                mrightweb="auto"
                mtop="20px"
              >
                Continuar
              </CardsBtn>
            </Container>
          </TrainingSectionGray>
        </Fragment>
      ) : (
        <Fragment>
          <CardOne
            classCard={'cardOne'}
            classTop="topOne1"
            textbold="Bitácora de tareas"
            text={`${infoChildren[0].childData.name}, aquí podrás ver tus tareas asignadas y recompensas que obtendrás el día domingo por cumplirlas; estaremos recordando durante la semana para que puedas cumplirlas al pie de la letra.`}
          />
          <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
            <Container width="100%" mt="0rem" widthMobile="90%" mtMobile="0rem">
              <CardsBtn
                windthmd="90%"
                minmd="40%"
                mleftmd="5%"
                mrightmd="auto"
                windthG="400px"
                type="button"
                min_w="400px"
                min_web="400px"
                min_mini="80%"
                mleftmini="10%"
                mrightmini="10%"
                mleft="15%"
                mright="15%"
                mleftweb="18%"
                mrightweb="auto"
                mtop="20px"
                onClick={() => setShowSchedule(true)}
              >
                <b>Ver mis tareas</b>
              </CardsBtn>
            </Container>
          </TrainingSectionGray>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TaskLog;
