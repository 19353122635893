import React, { useState, useEffect, Fragment } from 'react';
import '../../assets/styles/Home.css';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  DYNAMIC_UPDATE,
  GET_RECOMMENDATION_TRAININGS,
  CREATE_SESSION_MYSQL,
} from '../../mutations';
import {
  GET_TRAINING_PROGRESS,
  GET_TRAINING_COLOR,
  GET_TRAINING_STATUS,
} from '../../queries';
import {
  TrainingMain,
  TrainingAnimateFunnel,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import { Container, Col7 } from '../../components/common/common.styled';
import imgWo from '../../assets/img/anacoach/pymes.svg';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import RoutinesOrderPymes from './RoutinesOrderPymes';
import WaitList from '../../components/Training/WaitList';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import LoadingWo from '../../components/Training/loadingWo';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import WOlayout from '../../components/layouts/WOlayout';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import '../../assets/styles/kanda/KandaDesktop.css';

const items2 = ['Crédito PyME'];
const Pymes = () => {
  const { actions } = GeneralHooks();
  sessionStorage.setItem('idTraining', 42);
  const feacturesArray = useState([
    {
      text: 'Qué es un crédito PyME',
      color: '#33A376',
    },
    {
      text: 'Quién otorga estos créditos y cuáles son sus características',
      color: '#33A376',
    },
    {
      text: 'Cómo se pueden usar los créditos PyMES',
      color: '#33A376',
    },
  ]);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  const id = sessionStorage.getItem('idTraining');
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);

  // Obtener color del entrenamiento
  const [colorTraining, setColorTraining] = useState('');

  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  // mover barra de avance según posición scroll
  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '40px';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '70px';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);

  // End ValidWaitList
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(sessionStorage.getItem('idTraining'));
  const [questions, setQuestions] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;

  const [getTrainingProgress] = useLazyQuery(GET_TRAINING_PROGRESS, {
    onCompleted({ getTrainingProgress }) {
      const percent = getTrainingProgress.achievment;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setactive3(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const [arrayOrderRoutines] = useState([]);
  const [recommendedTraining, setRecommendedTraining] = useState({});
  const [getPersonalizeRecommendationTrainings] = useMutation(
    GET_RECOMMENDATION_TRAININGS,
    {
      onCompleted({ getPersonalizeRecommendationTrainings }) {
        if (getPersonalizeRecommendationTrainings.statusCode === 200) {
          console.log(
            `Recomendaciones:-----${getPersonalizeRecommendationTrainings.response}`,
          );
          setRecommendedTraining(
            getPersonalizeRecommendationTrainings.response,
          );
          console.log(`Array recommended ------------>${recommendedTraining}`);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );
  const email = sessionStorage.getItem('Email1');
  useEffect(() => {
    getPersonalizeRecommendationTrainings({ variables: { email } });
  }, []);

  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insertRoutine');
        console.log(jsonResponse);

        if (insertRoutine.message === 'usuario existente') {
          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
              sessionStorage.setItem(
                'datOpportunity',
                parseInt(jsonResponse.idDatOpportunity),
              );
            }
          } else if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          if (parseInt(jsonResponse.idDatOpportunity) !== 0) {
            sessionStorage.setItem(
              'datOpportunity',
              parseInt(jsonResponse.idDatOpportunity),
            );
          }
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
        const currentOrderRoutine = sessionStorage.getItem(
          'currentOrderRoutine',
        );
        saveAdvance(parseInt(currentOrderRoutine));
        const idUser = sessionStorage.getItem('idUser');
        if (idUser != null) {
          getTrainingProgress({
            variables: {
              idUser: parseInt(idUser),
              idTraining: parseInt(currentTraining),
            },
          });
        }
      }
    },
  });

  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining && saveStatusTraining.message.split('_');
      if (porcentaje && porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };

  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    console.log(`Entra ${orderRutine}`, currentRoutine.questions);
    console.log('questions', questions);
    sessionStorage.setItem('currentOrderRoutine', orderRutine);
    pushArrayOrderRoutine(orderRutine);
    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(JSON.stringify(inputRutine));
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        sessionStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };

  const recoveryAdvance = async (currentOrderRoutine, userRoutines) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
  
    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
        userRoutines: userRoutines || '',
      },
    };
  
    if (idUser != null) {
      await saveStatusTraining({ variables: inputProgress });
    }
  };
  
  const saveRoutines = async () => {
    await recoveryAdvance(1);
    await recoveryAdvance(2);
    await recoveryAdvance(3);
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const source = getQueryVariable('utm_source');
    const medium = getQueryVariable('utm_medium');
    const campaign = getQueryVariable('utm_campaign');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    sessionStorage.setItem('pagename', 'family-protection');
    sessionStorage.setItem('hostname', window.location.hostname);
    const idGa = String(sessionStorage.getItem('ID_GA'));
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const dataJson = {
      idga: idGa,
      campaign,
      medium,
      source,
      timestamp,
      viralityID,
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
    sessionStorage.setItem('Primervisita', false);
  }, []);
  const pushArrayOrderRoutine = (orderRoutine) => {
    if (!arrayOrderRoutines.includes(orderRoutine)) {
      arrayOrderRoutines.push(orderRoutine);
    }
  };

  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getTrainingProgress({
        variables: {
          idUser: parseInt(idUser),
          idTraining: parseInt(currentTraining),
        },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  useEffect(() => {
    if (orderRutine <= 1  && percentage !== '100%') {
      // Esto causar el problema, pero debo dejarlo :/
      saveRoutines(orderRutine);
    }

    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getTrainingProgress({
        variables: {
          idUser: parseInt(idUser),
          idTraining: parseInt(currentTraining),
        },
      });
    }
  }, [orderRutine]);

  const saveAdvance = (currentOrderRoutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    const temp = arrayOrderRoutines.filter((el) => el <= currentOrderRoutine);
    const stringOrderRoutines = temp.join(',');

    const inputProgress = {
      input: {
        idRoutine: '',
        orderRoutine: String(currentOrderRoutine),
        idTraining: String(currentTraining),
        idUser: String(idUser),
        idCont: String(idCont),
        isMaster: '1',
      },
    };
    console.log(JSON.stringify(inputProgress));
    if (idUser != null) {
      saveStatusTraining({ variables: inputProgress });
    }
  };

  useEffect(() => {
    const {
      idUser,
      idCont,
      hostName,
      recovery,
      pageName,
      fromXCoach,
      idAdviser,
    } = getQueryVariableHashtagDeeplink('funnel-prestamos');

    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    sessionStorage.setItem('pagename', 'funnel-prestamos');
    sessionStorage.setItem('hostname', window.location.hostname);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');

    let { cookie } = document;
    let variableEstado = '';
    if (cookie.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (cookie.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = cookie.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    cookie = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = cookie.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }

    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }

    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }

    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }

    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          //   viralityID: viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );

  useEffect(() => {
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      setactive3(true);
    }, 13000);
  }, [active2]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);
  const anchop = window.screen.width;
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    time: '3 minutos',
    imgWo: { imgWo },
    txtsubtitle: '',
  };
  return (
    <WOlayout
      backgroundColor={colorTraining}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Créditos para Empresas en México | Coru</title>
        <link rel="canonical" href="https://coru.com//wo/pymes" />
        <meta name="keywords" content="creditos para empresas en mexico" />
        <meta
          name="description"
          content="Si tienes una empresa y necesitas dinero para llegar al siguiente nivel, entonces tienes que saber qué crédito es mejor para tí."
        />
      </Helmet>
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor={colorTraining}
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <BarraInfoWO propied={propied} />
          <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
          {percentage !== '' ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt=""
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {WOStatus ? (
              <Fragment>
                <ModalDataPersonalInitWO isData={isData} setData={setisData} />
                <DataPersonalInitWO />
                <TrainingSectionGray
                  wiw="100%"
                  mw="auto"
                  back="#FAFAFA"
                  mtop="0"
                >
                  <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                    {orderRutine !== 'rp' ? (
                      <div className="marginComentLgSuccess1">
                        <Starttraining
                          title="¡Hola! Soy Ana, tu coach financiero."
                          text=" En este entrenamiento conocerás cómo funcionan los créditos para PyMEs y te ayudaremos a encontrar el que se adecue mejor a las necesidades de tu empresa."
                        />
                        {active1 ? (
                          <Fragment>
                            <Features
                              classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                              title="¿Qué más puedes saber?"
                              Arraytext={feacturesArray}
                            />
                          </Fragment>
                        ) : null}
                        {active2 ? (
                          <Starttraining
                            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
                            title="Iniciemos esta aventura..."
                            text="A continuación te haré algunas preguntas para conocer un poco más de ti y poder ofrecerte una mejor orientación:"
                          />
                        ) : null}
                      </div>
                    ) : null}
                    {active3 ? (
                      <Fragment>
                        <div className="marginComentLgSuccess1">
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <RoutinesOrderPymes
                                Date={stateDate}
                                hora={hora}
                                orderRutine={orderRutine}
                                setOrderRutine={setOrderRutine}
                                currentTraining={currentTraining}
                                createJsonInput={createJsonInput}
                                questions={questions}
                                setQuestions={setQuestions}
                                saveAdvance={saveAdvance}
                                colorTraining={colorTraining}
                              />
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                        </div>
                      </Fragment>
                    ) : null}
                  </TrainingDivSection2>
                </TrainingSectionGray>
                {!active3 ? (
                  <Fragment>
                    <br></br>
                    <TrainingSectionGray
                      wiw="670px"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <Container
                        width="80%"
                        mt="0rem"
                        widthMobile="90%"
                        mtMobile="0rem"
                      >
                        <ImageCoachInicio topMobile="35px" />
                        <Col7 padding=" " className="cardPrincipal1">
                          <LoadingWo />
                        </Col7>
                      </Container>
                    </TrainingSectionGray>
                  </Fragment>
                ) : null}
                <br />
              </Fragment>
            ) : (
              <Fragment>{executeQuery ? <WaitList /> : null}</Fragment>
            )}
          </div>
          <div
            style={{ position: 'absolute', top: '1em', right: '3em' }}
            id="kandaDesktop"
          ></div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
};

export default Pymes;
