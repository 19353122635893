import React, { useState, useEffect, Fragment } from 'react';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useMutation, useLazyQuery } from '@apollo/client';
import PlayYoutube from '../../components/common/PlayYoutube';
import '../../assets/styles/Home.css';
import imgWo from '../../assets/img/anacoach/compare.svg';
import PerfilamientoCC from '../Routines/PerfilamientoCC';
import DatosPrincipales from '../Routines/DatosPrincipales';
import DetailRFC from '../Routines/DetailRFC';
import PersonalInformation from '../Routines/PersonalInformation';
import Domicile from '../Routines/Domicile';
import UserAuthentication from '../Routines/UserAuthentication';
import DatosLaborales from '../Routines/DatosLaborales';
import Referencias from '../Routines/Referencias';
import RpCompareCard from '../Routines/RpCompareCard';
import GeneralHooks from '../../Helpers/HookHelpersGeneral';
import ModalDataPersonalInitWO from '../../components/Training/ModalDataPersonalInitWO';
import DataPersonalInitWO from '../../components/Training/DataPersonalInitWO';
import SuccessWOValid from '../../components/Training/SuccessWOValid';
import BarraInfoWO from '../../components/Header/BarraInfoWO';
import Kanda from '../../components/Kanda/Kanda';
import BarraScroll from '../BarraScroll';
import '../../assets/styles/kanda/KandaDesktop.css';
import {
 
  TrainingText,
 
} from '../../assets/styles/Training.styled';

import {
  TrainingMain,
  TrainingDivSection,
  TrainingSectionGray,
  TrainingDivSection2,
  TrainingAnimateFunnel,
  TrainigTam,
  TrainingLine,
} from '../../assets/styles/Training.styled';
import CardOne from '../../components/Home/Card1Animate';
import Starttraining from '../../components/Training/Starttraining';
import Features from '../../components/Training/Features';
import FeatureList from '../../components/Training/FeatureList';
// imports funcioanles
import WOlayout from '../../components/layouts/WOlayout';
import {
  arraySessionsRutines,
  updateBinacle,
  getQueryVariableHashtagDeeplink,
  getQueryVariable,
} from '../../Helpers';
import {
  INSERT_ROUTINE,
  SAVE_STATUS_TRAINING,
  UPDATE_SESSION,
  CREATE_SESSION,
  CREATE_ACCOUNT_USER_TEMP,
  CREATE_SESSION_MYSQL,
  RECOVERY_TRAINING,
  BACKUP_WO_DATA,
  GET_CURP,
  DYNAMIC_UPDATE,
} from '../../mutations';
import {
  GETURLVIDEO,
  GETPERCENTAGETRAINING,
  GET_TRAINING_COLOR,
  GET_TRAINING_STATUS,
  CHECK_TRAINING_FINISHED,
} from '../../queries';

import Header from '../../components/common/Header';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import WaitList from '../../components/Training/WaitList';
import SendNotificationVideo from '../../components/common/SendNotificationVideo';

const items2 = ['La tarjeta de crédito ideal para ti', '', ''];
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
function ComparaTarjeta() {
  const [urlVideo, setUrlVideo] = useState('');
  const [idVideo, setIdVideo] = useState('');
  const [percentage, setPercentage] = useState('');
  const [saludo, setSaludo] = useState('Si');
  const [active0, setactive0] = useState(true);
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(false);
  const [active4, setactive4] = useState(false);
  const [firstRoutineCheck, setFirstRoutineCheck] = useState(false);
  const { actions } = GeneralHooks();
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );

  const [colorTraining, setColorTraining] = useState('');
  const [routinesSaves] = useState([
    {
      1: false,
    },
    {
      2: false,
    },
    {
      3: false,
    },
    {
      4: false,
    },
    {
      5: false,
    },
    {
      6: false,
    },
    {
      7: false,
    },
    {
      8: false,
    },
    {
      9: false,
    },
    {
      10: false,
    },
  ]);
  const [getTrainingColor] = useLazyQuery(GET_TRAINING_COLOR, {
    onCompleted({ getTrainingColor }) {
      console.log(getTrainingColor);
      setColorTraining(getTrainingColor);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getTrainingColor({ variables: { idTraining: id } });
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setTimeout(() => {
      setactive2(true);
    }, 10000);
  }, [active1]);

  useEffect(() => {
    setTimeout(() => {
      setactive3(true);
    }, 13000);
  }, [active2]);

  useEffect(() => {
    setTimeout(() => {
      setactive4(true);
    }, 13000);
  }, [active3]);

  setTimeout(() => {
    setactive0(true);
  }, 4000);

  useEffect(() => {
    setTimeout(() => {
      setactive1(true);
    }, 7000);
  }, [active0]);
  sessionStorage.setItem('idTraining', 3);
  // Valid WaitList
  const [WOStatus, setWOStatus] = useState(false);
  const [executeQuery, setExecuteStatus] = useState(false);
  const [getTrainingStatus] = useLazyQuery(GET_TRAINING_STATUS, {
    onCompleted({ getTrainingStatus }) {
      console.log(
        'getTrainingStatus ----------------------- ',
        getTrainingStatus,
      );
      setWOStatus(getTrainingStatus === 'true');
      setExecuteStatus(true);
    },
    onError(err) {
      console.log(err);
      setExecuteStatus(true);
    },
  });

  let id = sessionStorage.getItem('idTraining');
  console.log(id);
  useEffect(() => {
    getTrainingStatus({ variables: { idTraining: id } });
  }, []);
  // End ValidWaitList
  // manejo de orden de la ruitna
  const [orderRutine, setOrderRutine] = useState(1);
  const [currentTraining] = useState(3);
  // ira guardando cada pregunta que el usuario conteste
  const [questions, setQuestions] = useState([]);

  const [mainDataRoutine, setMainDataRoutine] = useState(0);
  //----------------------------------------------------------------
  const [stateDate] = useState({
    year: '1989',
    month: '',
    day: '',
  });
  const [trainingFinishedInfo, setTrainingFinishedInfo] = useState();
  const [getVideoTraining] = useLazyQuery(GETURLVIDEO, {
    onCompleted({ getVideoTraining }) {
      const video = JSON.parse(getVideoTraining.response);
      setUrlVideo(video.UrlVideo);
      setIdVideo(video.IdVideoURL);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    getVideoTraining({ variables: { id: '3' } });
  }, []);

  // Obtener porcentaje de avance
  const [getPercentageTraining] = useLazyQuery(GETPERCENTAGETRAINING, {
    onCompleted({ getPercentageTraining }) {
      const percent = getPercentageTraining.percentage;
      setPercentage(`${percent.toString()}%`);
      if (percent !== 0) {
        setactive0(true);
        setactive1(true);
        setactive2(true);
        setactive3(true);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    const idUser = sessionStorage.getItem('idUser');
    if (idUser != null) {
      getPercentageTraining({
        variables: { iduser: parseInt(idUser), idtrainig: 3 },
      });
    } else {
      console.log('Sin porcentaje de avance');
      setPercentage('0');
    }
  }, []);

  // Valid Finished WO
  const [allowOverwrite, setAllowOverwrite] = useState(false);
  const [backupCC, setBackupCC] = useState();
  const [finished, setFinished] = useState(false);
  const [checkTrainingFinished] = useLazyQuery(CHECK_TRAINING_FINISHED, {
    onCompleted({ checkTrainingFinished }) {
      if (checkTrainingFinished.statusCode === 200) {
        const idTraining = sessionStorage.getItem('idTraining');
        const trainingInfo = JSON.parse(checkTrainingFinished.response);
        const overwrite = sessionStorage.getItem(`overwrite_WO_${idTraining}`) || 'false';
        if (overwrite === 'true' && trainingInfo.avance === 100) {
          setAllowOverwrite(true);
          setOrderRutine(1);
          getTrainingData();
        } else if (trainingInfo.avance === 100) {
          setFinished(true);
          const firstName = sessionStorage.getItem('FirstName') || '';
          const middleName = sessionStorage.getItem('MiddleName')
            ? ` ${sessionStorage.getItem('MiddleName')}`
            : '';
          const fathersLastName = sessionStorage.getItem('FathersLastName')
            ? ` ${sessionStorage.getItem('FathersLastName')}`
            : '';
          const mothersLastName = sessionStorage.getItem('MothersLastName')
            ? ` ${sessionStorage.getItem('MothersLastName')}`
            : '';
          setTrainingFinishedInfo({
            ...setTrainingFinishedInfo,
            fullName: `${firstName}${middleName}${fathersLastName}${mothersLastName}`,
            phone: sessionStorage.getItem('cellPhone'),
            email: sessionStorage.getItem('Email1'),
            meetsCondition: trainingInfo.threeMonthsPassed,
            idTraining: parseInt(sessionStorage.getItem('idTraining')) || 0,
            title: 'Tu solicitud está en proceso',
            txt1: 'Para incrementar las posibilidades de obtener un crédito te recomendamos no solicitar más de uno en tres meses',
            txt2: `Realizaste tu solicitud el día ${moment(
              trainingInfo.fechaTermino,
            ).format('DD/MM/YYYY')} con los siguientes datos:`,
          });
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  const getTrainingData = () => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idUser') !== null
      && sessionStorage.getItem('idCont') !== ''
      && sessionStorage.getItem('idCont') !== null
    ) {
      recoveryTraining({
        variables: {
          idTraining: sessionStorage.getItem('idTraining') || '',
          idUser: sessionStorage.getItem('idUser') || '',
          idCont: sessionStorage.getItem('idCont') || '',
        },
      });
    } else {
      sessionStorage.setItem('Primervisita', true);
    }
  };

  const addDatSession = (idSession) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    // const timestamp = today;
    const dataJson = {
      idSession,
      idga: sessionStorage.getItem('ID_GA'),
      idUser: sessionStorage.getItem('idUser'),
      idCont: sessionStorage.getItem('idCont'),
    };
    const filterJson = {
      _id: sessionStorage.getItem('id_session'),
    };
    const dataString = JSON.stringify(dataJson);
    const filterString = JSON.stringify(filterJson);
    const mongoUpdateInput = {
      input: {
        collection: 'sessions',
        data: dataString,
        filter: filterString,
      },
    };
    updateSession({ variables: mongoUpdateInput });
  };

  const [backupWOData] = useMutation(BACKUP_WO_DATA, {
    onCompleted({ backupWOData }) {
      if (backupWOData.statusCode === 200) {
        console.log(backupWOData.response);
      }
    },
  });
  const [recoveryTraining] = useMutation(RECOVERY_TRAINING, {
    onCompleted({ recoveryTraining }) {
      try {
        const json = JSON.parse(recoveryTraining.response);

        const indices = Object.keys(json);
        const bckData = [];
        for (const indice in indices) {
          const idRoutine = indices[indice];
          const {
            Name: name,
            RoutineType: routineType,
            questions,
            responses,
          } = json[idRoutine];

          const routine = {
            name,
            routineType,
            questionsAndresponses: [],
          };

          questions.map((question, index) => {
            routine.questionsAndresponses.push({
              questionName: question.QuestionName,
              responseValue: responses[index]?.responseValue || '',
            });
          });

          bckData.push(routine);
        }

        const idUser = sessionStorage.getItem('idUser');
        const idTraining = sessionStorage.getItem('idTraining');
        const idCont = sessionStorage.getItem('idCont');

        setBackupCC({
          routines: bckData,
          idUser,
          idTraining,
          idCont,
        });
      } catch (error) {
        console.log('exception recovery', error.message);
      }
    },
    onError(err) {
      console.log('error recoveryTraining: ', err.message);
    },
  });

  const [createSessionMysql] = useMutation(CREATE_SESSION_MYSQL, {
    onCompleted({ createSessionMysql }) {
      console.log('createSessionMysql', createSessionMysql);

      if (createSessionMysql.statusCode === 200) {
        const jsonResponse = JSON.parse(createSessionMysql.response);
        sessionStorage.setItem('idDatSession', jsonResponse.idSession);
        localStorage.setItem('idDatSession', jsonResponse.idSession);
        localStorage.setItem('idCatRelAction', jsonResponse.idCatRelAction);
        addDatSession(jsonResponse.idSession);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_binacle', dynamicMongoSession.response);
      }
    },
  });

  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [newSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
  });

  const [createAccountUserTemp] = useMutation(CREATE_ACCOUNT_USER_TEMP, {
    onCompleted({ createAccountUserTemp }) {
      if (createAccountUserTemp.statusCode === 200) {
        const jsonResponse = JSON.parse(createAccountUserTemp.response);
        sessionStorage.setItem('idCont', jsonResponse.idCont);
        sessionStorage.setItem('idUser', jsonResponse.idUser);
        sessionStorage.setItem('auxTempValidation', 'true');
        localStorage.setItem('idCont', jsonResponse.idCont);
        localStorage.setItem('idUser', jsonResponse.idUser);
      }
    },
  });

  useEffect(() => {
    const {
      idUser,
      idCont,
      recovery,
      hostName,
      pageName,
      fromXCoach,
      idAdviser,
      email,
    } = getQueryVariableHashtagDeeplink('comparar-tarjeta');
    const currentTraining = sessionStorage.getItem('idTraining');
    sessionStorage.setItem('currentTraining',currentTraining);
    if (fromXCoach === 'true') {
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      sessionStorage.setItem('fromXCoach', fromXCoach);
      sessionStorage.setItem('idAdviser', idAdviser);
      sessionStorage.setItem('Email1', email);
    }

    checkTrainingFinished({
      variables: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(currentTraining),
      },
    });

    const emailClient = sessionStorage.getItem('Email1') || '';
    if (
      !idUser
      && !idCont
      && !sessionStorage.getItem('idUser')
      && !sessionStorage.getItem('idCont')
    ) {
      createAccountUserTemp({ variables: { email: emailClient } });
    } else {
      sessionStorage.setItem('auxTempValidation', 'false');
    }

    sessionStorage.setItem('pagename', 'comparar-tarjeta');
    sessionStorage.setItem('hostname', window.location.hostname);
    let source = getQueryVariable('utm_source');
    let medium = getQueryVariable('utm_medium');
    let campaign = getQueryVariable('utm_campaign');
    let content = getQueryVariable('utm_content');
    const viralityID = getQueryVariable('viralityID');
    sessionStorage.setItem('viralityID', viralityID);
    if (recovery !== '' && recovery !== undefined && recovery !== 'false') {
      setMainDataRoutine(1);
      sessionStorage.setItem('recoveryUser', 'true');
    } else {
      sessionStorage.setItem('recoveryUser', 'false');
      setMainDataRoutine(2);
    }
    // cookie
    let galletita = document.cookie;
    let variableEstado = '';
    if (galletita.includes('ReturningSession')) {
      variableEstado = 'ReturningSession';
    } else if (galletita.includes('FirstSession')) {
      variableEstado = 'FirstSession';
    } else {
      variableEstado = 'noencontrada';
    }
    sessionStorage.setItem('visitorType', variableEstado);
    let elementos = galletita.split(';');
    let posicion = 0;
    for (const elem in elementos) {
      if (elementos[elem].includes(variableEstado)) {
        break;
      } else {
        posicion += 1;
      }
    }
    galletita = decodeURIComponent(elementos[posicion]).replace(
      `${variableEstado}=`,
      '',
    );
    elementos = galletita.split('&');
    for (const elem in elementos) {
      if (elementos[elem].includes('source=') && source === '') {
        source = elementos[elem].replace('source=', '');
      } else if (elementos[elem].includes('medium=') && medium === '') {
        medium = elementos[elem].replace('medium=', '');
      } else if (elementos[elem].includes('campaign=') && campaign === '') {
        campaign = elementos[elem].replace('campaign=', '');
      } else if (elementos[elem].includes('content=') && content === '') {
        content = elementos[elem].replace('content=', '');
      }
    }
    source = source != null && source !== '' ? source : 'direct';
    medium = medium != null && medium !== '' ? medium : 'none';
    campaign = campaign != null && campaign !== '' ? campaign : 'direct';

    // cookie
    if (source !== '' || source !== null || source !== undefined) {
      sessionStorage.setItem('source', source);
    }
    if (medium !== '' || medium !== null || medium !== undefined) {
      sessionStorage.setItem('medium', medium);
    }
    if (campaign !== '' || campaign !== null || campaign !== undefined) {
      sessionStorage.setItem('campaign', campaign);
    }
    if (content !== '' || content !== null || content !== undefined) {
      sessionStorage.setItem('content', content);
    }
    if (
      idUser !== ''
      && idUser !== undefined
      && idCont !== ''
      && idCont !== undefined
    ) {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
      localStorage.setItem('idUser', idUser);
      localStorage.setItem('idCont', idCont);
      sessionStorage.setItem('Primervisita', true);
      setTimeout(() => {
        const idGa = String(sessionStorage.getItem('ID_GA'));
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const inputCreateSessionMysql = {
          input: {
            idUser,
            idGa,
            hostName,
            source,
            medium,
            campaign,
            pageName,
          },
        };
        if (Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))) {
          inputCreateSessionMysql.input.viralityID = parseInt(
            sessionStorage.getItem('viralityID'),
          );
        }
        const dataJson = {
          idga: idGa,
          campaign,
          medium,
          source,
          timestamp,
          viralityID,
        };
        const dataString = JSON.stringify(dataJson);
        const mongoInput = {
          input: {
            collection: 'sessions',
            data: dataString,
          },
        };
        if (!sessionStorage.getItem('id_session')) {
          newSession({ variables: mongoInput });
        }
        console.log(inputCreateSessionMysql);
        createSessionMysql({ variables: inputCreateSessionMysql });
      }, 2500);
    } else {
      const idGa = String(sessionStorage.getItem('ID_GA'));
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const dataJson = {
        idga: idGa,
        campaign,
        medium,
        source,
        timestamp,
        viralityID,
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        newSession({ variables: mongoInput });
      }
      sessionStorage.setItem('Primervisita', false);
    }
  }, []);

  useEffect(() => {
    const dataJson = {
      idga: sessionStorage.getItem('ID_GA'),
      campaign: sessionStorage.getItem('campaign'),
      medium: sessionStorage.getItem('medium'),
      source: sessionStorage.getItem('source'),
      viralityID: sessionStorage.getItem('viralityID'),
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: 'sessions',
        data: dataString,
      },
    };
    if (!sessionStorage.getItem('id_session')) {
      newSession({ variables: mongoInput });
    }
  }, []);

  useEffect(() => {
    if (backupCC && backupCC.routines.length > 0 && allowOverwrite) {
      const mongoInput = {
        input: {
          collection: 'backup_wo_routines',
          data: JSON.stringify(backupCC),
        },
      };
      backupWOData({ variables: mongoInput });
    }
  }, [backupCC]);

  const date = new Date();
  let minutos = date.getMinutes();
  if (minutos < 10) {
    minutos = `0${minutos}`;
  }
  const hora = `${date.getHours()}:${minutos}`;
  /**
   *idCatFieldDetail y el value para  una
   *pregunta de tipo cat
   */
  const getFielOption = (items, value) => {
    for (const i in items) {
      if (items[i].CatFieldDetail_IDCatFieldDetail === value) {
        return {
          idCatFieldDetail: items[i].CatFieldDetail_IDCatFieldDetail,
          catDetailTypeUsers_IDType: items[i].IDType,
          fieldOption: items[i].FieldOption,
        };
      }
    }
  };
  /**
   * crea el json para guardar en la base de datos
   * @param  {[type]} currentRoutine [description]
   * @param  {[type]} questions      [description]
   * @return {[type]}                [description]
   */
  const createJsonInput = (currentRoutine, questions) => {
    localStorage.setItem('activityDate', new Date());
    console.log('curretRutine', currentRoutine, 'questions', questions);
    const inputRutine = {
      idUser: '',
      idCont: '',
      email: '',
      idGa: '',
      cellphone: '',
      rutine: [],
    };
    // console.log("Paso 2 >>>> ")

    // console.log("Entra >>>> " + orderRutine, currentRoutine.questions);
    // console.log("questions >>>> ", questions);

    let numQuestion = 0;
    try {
      questions.forEach((item) => {
        numQuestion = parseInt(item.question) - 1;
        const questionRoutine = currentRoutine.questions[numQuestion];

        const rutine = {
          questionId: '',
          datUserDetailStorage: '',
          questionType: '',
          value: '',
        };
        let triggerGTM = false;
        if (
          !sessionStorage.getItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
          )
        ) {
          triggerGTM = true;
        }
        rutine.questionId = questionRoutine.IDCatQuestions;
        rutine.datUserDetailStorage = questionRoutine.DatUserDetailStorage;
        rutine.questionType = questionRoutine.QuestionType;
        if (questionRoutine.QuestionType === 'Cat') {
          if (item.value != null && item.value !== '') {
            rutine.catFieldDetail = getFielOption(
              questionRoutine.detailQuestion,
              item.value,
            );
            sessionStorage.setItem(
              arraySessionsRutines[questionRoutine.DatUserDetailStorage],
              rutine.catFieldDetail.fieldOption,
            );
          }
        } else {
          sessionStorage.setItem(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            item.value,
          );

          rutine.value = item.value;
        }
        if (triggerGTM) {
          const tagManagerArgs = {
            dataLayer: {
              event: arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          updateBinacle(
            arraySessionsRutines[questionRoutine.DatUserDetailStorage],
            updateSession,
            createSession,
          );
        }
        inputRutine.email = sessionStorage.getItem('Email1');
        inputRutine.cellphone = sessionStorage.getItem('CellPhone');
        inputRutine.idCont = parseInt(sessionStorage.getItem('idCont')) || parseInt('0');
        inputRutine.idUser = parseInt(sessionStorage.getItem('idUser')) || parseInt('0');
        inputRutine.idTraining = parseInt(sessionStorage.getItem('idTraining')) || parseInt('0');
        inputRutine.idGa = String(sessionStorage.getItem('ID_GA'));
        inputRutine.rutine[parseInt(numQuestion)] = rutine;
      });
      const newRutine = inputRutine.rutine.filter(() => true);

      inputRutine.rutine = newRutine;

      if (inputRutine.rutine.length > 0) {
        console.log(inputRutine);
        if (sessionStorage.getItem('idDatSession')) {
          inputRutine.idMysql = parseInt(
            sessionStorage.getItem('idDatSession'),
          );
        }
        insertRoutine({ variables: { input: inputRutine } });
      } else {
        console.log('else', inputRutine);
      }
    } catch (e) {
      console.log(e);
    }
    actions({ action: 'getAchievment', variables: '' });
  };
  /**
   * [useMutation mutacion para insertar el json creado para la rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [insertRoutine] = useMutation(INSERT_ROUTINE, {
    onCompleted({ insertRoutine }) {
      if (insertRoutine.statusCode === 200) {
        const jsonResponse = JSON.parse(insertRoutine.response);
        console.log('insert rutine en', orderRutine);
        saveAdvance(parseInt(orderRutine));

        if (insertRoutine.message === 'usuario existente') {
          if (
            sessionStorage.getItem('idUser') == null
            && sessionStorage.getItem('idDatSession') == null
          ) {
            // console.log("existe el usuario pero no esta logueado");
            sessionStorage.setItem('idUser', jsonResponse.idUser);
            sessionStorage.setItem('idCont', jsonResponse.idCont);
            localStorage.setItem('idUser', jsonResponse.idUser);
            localStorage.setItem('idCont', jsonResponse.idCont);
          } else {
            // console.log("existe el usuario pero  esta logueado");
          }
          if (jsonResponse.idSession !== '' || jsonResponse.idSession != null) {
            sessionStorage.setItem('idDatSession', jsonResponse.idSession);
            sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
            localStorage.setItem('idDatSession', jsonResponse.idSession);
            localStorage.setItem('idCatRelAction', jsonResponse.idSession);
            addDatSession(jsonResponse.idSession);
            const inputDynamicUpdate = {
              input: {
                table: 'DatSession',
                columns: [
                  {
                    setkey: 'Source',
                    setval: `${sessionStorage.getItem('source')}`,
                  },
                  {
                    setkey: 'Medium',
                    setval: `${sessionStorage.getItem('medium')}`,
                  },
                  {
                    setkey: 'Campaign',
                    setval: `${sessionStorage.getItem('campaign')}`,
                  },
                  {
                    setkey: 'Content',
                    setval: `${sessionStorage.getItem('content')}`,
                  },
                  {
                    setkey: 'PageName',
                    setval: `${sessionStorage.getItem('pagename')}`,
                  },
                  {
                    setkey: 'Hostname',
                    setval: `${sessionStorage.getItem('hostname')}`,
                  },
                ],
                conditions: {
                  valone: 'IDdatSession',
                  valtwo: `${jsonResponse.idSession}`,
                  condition: '=',
                },
              },
            };
            if (
              Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
            ) {
              inputDynamicUpdate.input.columns.push({
                setkey: 'DatVirality_IdVirality',
                setval: `${sessionStorage.getItem('viralityID')}`,
              });
            }
            // console.log("Paso 4.5.2 A >>>> ")
            dynamicUpdate({ variables: inputDynamicUpdate });
          }
        } else {
          // console.log("Paso 4.5 B >>>> ")
          console.log(
            'provitional ids ==>>>>>>',
            jsonResponse.idUser,
            jsonResponse.idCont,
          );
          sessionStorage.setItem('idUser', jsonResponse.idUser);
          sessionStorage.setItem('idCont', jsonResponse.idCont);
          sessionStorage.setItem('idDatSession', jsonResponse.idSession);
          sessionStorage.setItem('idCatRelAction', jsonResponse.idSession);
          localStorage.setItem('idUser', jsonResponse.idUser);
          localStorage.setItem('idCont', jsonResponse.idCont);
          localStorage.setItem('idDatSession', jsonResponse.idSession);
          localStorage.setItem('idCatRelAction', jsonResponse.idSession);
          addDatSession(jsonResponse.idSession);
          const inputDynamicUpdate = {
            input: {
              table: 'DatSession',
              columns: [
                {
                  setkey: 'Source',
                  setval: `${sessionStorage.getItem('source')}`,
                },
                {
                  setkey: 'Medium',
                  setval: `${sessionStorage.getItem('medium')}`,
                },
                {
                  setkey: 'Campaign',
                  setval: `${sessionStorage.getItem('campaign')}`,
                },
                {
                  setkey: 'Content',
                  setval: `${sessionStorage.getItem('content')}`,
                },
                {
                  setkey: 'PageName',
                  setval: `${sessionStorage.getItem('pagename')}`,
                },
                {
                  setkey: 'Hostname',
                  setval: `${sessionStorage.getItem('hostname')}`,
                },
              ],
              conditions: {
                valone: 'IDdatSession',
                valtwo: `${jsonResponse.idSession}`,
                condition: '=',
              },
            },
          };
          if (
            Number.isInteger(parseInt(sessionStorage.getItem('viralityID')))
          ) {
            inputDynamicUpdate.input.columns.push({
              setkey: 'DatVirality_IdVirality',
              setval: `${sessionStorage.getItem('viralityID')}`,
            });
          }
          dynamicUpdate({ variables: inputDynamicUpdate });
        }
      }
    },
  });
  /**
   * [useMutation Guarda el avanze de cada rutina]
   * @param  {[type]} INSERT_ROUTINE [description]
   * @param  {[callback]} { onCompleted({ insertRoutine }) {if (insertRoutine.statusCode [description]
   * @return {[type]} [description]
   */
  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    onCompleted({ saveStatusTraining }) {
      console.log(saveStatusTraining);
      const porcentaje = saveStatusTraining.message.split('_');

      if (porcentaje.length > 1) {
        console.log(
          'ejecutar porcentaje============',
          `${porcentaje[1].toString()}%`,
        );
        setPercentage(`${porcentaje[1].toString()}%`);
        sessionStorage.setItem(
          'Achievment-3',
          parseFloat(porcentaje[1]).toFixed(2).toString(),
        );
        if (porcentaje !== 0) {
          setactive0(true);
          setactive1(true);
          setactive2(true);
          setactive3(true);
        }
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  /**
   * [function para ir guardando en el progreso]
   * @param  {[type]} idRoutine [description]
   * @return {[type]}           [description]
   */
  const saveAdvance = (orderRutine) => {
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    if (
      orderRutine <= 10
      && routinesSaves[orderRutine - 1][orderRutine] === false
    ) {
      const inputProgress = {
        input: {
          idRoutine: '',
          orderRoutine: String(orderRutine),
          idTraining: String(currentTraining),
          idUser: String(idUser),
          idCont: String(idCont),
          isMaster: '1',
        },
      };
      console.log('input progress');
      console.log(JSON.stringify(inputProgress));
      if (idUser != null) {
        routinesSaves[orderRutine - 1][orderRutine] = true;
        console.log(
          ' routinesSaves[idRoutine-1] final',
          routinesSaves[orderRutine - 1],
        );
        saveStatusTraining({ variables: inputProgress });
      }
    }
    if (orderRutine === 10) {
      const userRoutines = '1,2,3,4,5,7,8,9,10';
      const inputProgress = {
        input: {
          idRoutine: '',
          orderRoutine: String(orderRutine),
          idTraining: String(currentTraining),
          idUser: String(idUser),
          idCont: String(idCont),
          isMaster: '1',
          userRoutines,
        },
      };
      console.log('input progress');
      console.log(JSON.stringify(inputProgress));
      if (idUser != null) {
        console.log(
          ' routinesSaves[idRoutine-1] final',
          routinesSaves[orderRutine - 1],
        );
        saveStatusTraining({ variables: inputProgress });
      }
    }
  };
  //----------------------------------------------------------

  const setCURP = async () => {
    const birthDatestr = sessionStorage.getItem('Birthdate').split('-');
    const inputCURP = {
      input: {
        nombre: `${sessionStorage.getItem(
          'FirstName',
        )} ${sessionStorage.getItem('MiddleName')}`,
        apellido_paterno: sessionStorage.getItem('FathersLastName'),
        apellido_materno: sessionStorage.getItem('MothersLastName'),
        genero: sessionStorage.getItem('Gender'),
        estado: sessionStorage.getItem('BirthState'),
        fecha_nacimiento: sessionStorage.getItem('Birthdate')
          ? `${birthDatestr[2]}/${birthDatestr[1]}/${birthDatestr[0]}`
          : '',
      },
    };
    await getCURP({ variables: inputCURP });
  };

  const [getCURP] = useMutation(GET_CURP, {
    onCompleted({ createCURP }) {
      if (createCURP.statusCode === 200) {
        console.log(createCURP.response);
        const questions_tmp = [
          {
            id: 'Curp',
            question: 1,
            value: createCURP.response,
          },
        ];

        const Rutine_tmp = {
          CatTraining_IDCatTraining: 3,
          ComponentName: null,
          IDCatRoutine: 6,
          Name: 'GeneraciondeCurp',
          RoutineProps: '{}',
          RoutineType: 'Tech',
          RoutinesOrder: 6,
          Status: true,
          idDatRelRoutines: 6,
          questions: [
            {
              CatQuestions_IDCatQuestions: 341,
              CatRoutine_IDCatRoutine: 6,
              DatUserDetailStorage: 45,
              Description: null,
              IDCatQuestions: 341,
              QuestionName: 'Curp',
              QuestionType: 'OpenField',
              QuestionsOrder: 1,
              Show: true,
              Status: true,
            },
          ],
        };
        createJsonInput(
          Rutine_tmp,
          questions_tmp,
          insertRoutine,
          orderRutine,
          updateSession,
          createSession,
          TagManager,
        );

        setOrderRutine(7);
      }
    },
    onError(e) {
      console.log('error mongo: ', e.message);
    },
  });

  useEffect(() => {
    console.log('Scroll Routine >>> ', orderRutine);
    if (!firstRoutineCheck && orderRutine !== 1) {
      saveAdvance(1);
      setFirstRoutineCheck(true);
    } else if (orderRutine > 2) {
      saveAdvance(orderRutine);
    }
    if (orderRutine === 6 || orderRutine === 7) {
      loadCURP();
      saveAdvance(5);
    }
    if (orderRutine === 10) {
      saveAdvance(5);
      saveAdvance(9);
    }
    if (
      orderRutine < 3
      || orderRutine === 7
      || orderRutine === 8
      || orderRutine === 5
      || orderRutine === 9
    ) {
      return;
    }
    let time = 0;
    if (
      orderRutine === 3
      && sessionStorage.getItem('dataPerson') === 'Envio Datos'
    ) {
      return;
    }
    if (orderRutine === 3) time = 1000;
  }, [orderRutine]);

  const loadCURP = async () => {
    await setCURP();
  };

  const [checkScroll, setCheckScroll] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 0 && checkScroll) {
        console.log('Moviste el scroll');
        const grayline = document.getElementById('line-percent-gray');
        if (grayline !== undefined && grayline !== null) {
          grayline.style.marginTop = '0';
        }
        const pinkline = document.getElementById('line-percent-pink');
        if (pinkline !== undefined && pinkline !== null) {
          pinkline.style.marginTop = '0';
          setCheckScroll(false);
        }
      } else {
        setCheckScroll(true);
        const tamScreen = window.screen.width;
        if (tamScreen > 1024) {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '404px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '404px';
          }
        } else {
          const grayline = document.getElementById('line-percent-gray');
          if (grayline !== undefined && grayline !== null) {
            grayline.style.marginTop = '303px';
          }
          const pinkline = document.getElementById('line-percent-pink');
          if (pinkline !== undefined && pinkline !== null) {
            pinkline.style.marginTop = '303px';
          }
        }
      }
    };
  }, []);
  const feacturesArray = useState([
    {
      text: 'Los beneficios entre tu tarjeta con otras opciones',
      color: '#917AFF',
    },
    {
      text: 'Las anualidades',
      color: '#917AFF',
    },
    {
      text: 'CAT y Tasas de interés',
      color: '#917AFF',
    },
  ]);
   const feacturesArrayList = useState([
    {
      text: 'Los beneficios entre tu tarjeta con otras opciones',
      color: '#917AFF',
    },
    {
      text: 'Las anualidades',
      color: '#917AFF',
    },
    {
      text: 'CAT y Tasas de interés',
      color: '#917AFF',
    },
  ]);
  const anchop = window.screen.width;
  const propied = {
    beta: 'false',
    title: { items2 },
    subtitle: 'true',
    txtsubtitle: 'Encuentra una a tu medida.',
    time: '10 minutos',
    imgWo: { imgWo },
  };
  const scroll1 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scroll1 > 0) {
      console.log(`scrollNohis${scroll1}`);
    }
  }, [scroll1]);
  return (
    <WOlayout
      backgroundColor={'#917AFF'}
      pathname={window.location.pathname}
      headerType="HeaderTwo"
    >
      <Helmet>
        <title>Tarjetas de Crédito en México: Compara Hoy | Coru</title>
        <link rel="canonical" href="https://coru.com/wo/compare-credit-card" />
        <meta
          name="keywords"
          content="cual es el la mejor tarjeta de credito"
        />
        <meta
          name="description"
          content="Compara todas las tarjetas de Crédito en México. Nuestro sistema de ayuda a encontrar la mejor de todas. Coru es tu opción 100% confiable."
        />
      </Helmet>
      <TrainingMain>
        <TrainingAnimateFunnel
          bcolor="#917AFF"
          pmob="0 0 0 0"
          margin="0 0 24px 0"
          minh="309px"
          hweb="410px"
        >
          <TrainigTam>
            <Header
              background={'#917AFC'}
              pathname={window.location.pathname}
            />
          </TrainigTam>
          <BarraInfoWO propied={propied} />
          {scroll1 > 0 ? <BarraScroll /> : null}
          <TrainingLine id="line-percent-gray" mt="" mtw="404px"></TrainingLine>
          {percentage !== '' ? (
            <TrainingLine
              id="line-percent-pink"
              bcolor="#F64282"
              mt=""
              mtw="404px"
              wweb={percentage}
              w={percentage}
              mr="auto"
              op="1"
              br="100px"
            ></TrainingLine>
          ) : null}
        </TrainingAnimateFunnel>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {WOStatus ? (
              <Fragment>
                {!finished ? (
                  <Fragment>
                    {!allowOverwrite ? (
                      <Fragment>
                        <ModalDataPersonalInitWO
                          isData={isData}
                          setData={setisData}
                        />
                        <DataPersonalInitWO
                          Date={Date}
                          hora={hora}
                          orderRutine={orderRutine}
                          setOrderRutine={setOrderRutine}
                          currentTraining={currentTraining}
                          createJsonInput={createJsonInput}
                          questions={questions}
                          setQuestions={setQuestions}
                        />
                      </Fragment>
                    ) : null}
                    <TrainingSectionGray
                      wiw="100%"
                      mw="auto"
                      back="#FAFAFA"
                      mtop="0"
                    >
                      <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
                        <div className="marginComentLgSuccess1">
                          <Starttraining
                            title="¡Hola! Soy Ana, tu coach financiero."
                          >
                          <TrainingText  bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                         En Coru tenemos la misión de acercarte los mejores productos financieros del mercado. <b> Cuando buscas una tarjeta de crédito</b>, es importante que tomes en cuenta diferentes factores como <b>tasa de interés, comisión anual y beneficios</b> que cada uno de los bancos te ofrece.
                          </TrainingText>
                         </Starttraining>

                          {active1 ? (
                            <Fragment>
                              <Starttraining
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                              >
                              <TrainingText  bot="20px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                               Realiza el entrenamiento que tenemos para ti y descubre cuál de todas las tarjetas de crédito disponibles es la mejor para ti.<b> ¿Cuál tarjeta de crédito es la mejor? </b>Estás a punto de descubrirlo. 
                              </TrainingText>
                               {/* <TrainingText  bot="0px" size="16px" sizew="16px" className="roboto" align="left" alignweb="left" ls="-0.2px">
                             Algunas de las opciones de <b>tarjetas de crédito en línea</b> son: 
                              </TrainingText> */}
                               {/* <FeatureList
                                 Arraytext={feacturesArrayList}
                              />
                           */}
                              </Starttraining>
                            </Fragment>
                          ) : null}
                          {active2 ? (
                            <Fragment>
                              <Features
                                classAna={
                                  anchop <= 768 ? 'falseAna' : 'trueAna'
                                }
                                title="Qué comparamos"
                                Arraytext={feacturesArrayList}
                              />
                            </Fragment>
                          ) : null}
                        </div>
                      </TrainingDivSection2>
                    </TrainingSectionGray>
                    <div className="shapes">
                      {active3 && false ? (
                        <Fragment>
                          <TrainingSectionGray
                            wiw="100%"
                            mw="auto"
                            back="#FAFAFA"
                            mtop="0"
                          >
                            <TrainingDivSection2
                              paddiw="0"
                              paddi="0"
                              back="#FAFAFA"
                            >
                              <div className="marginComentLgSuccess1">
                                <Starttraining
                                  classAna={
                                    anchop <= 768 ? 'falseAna' : 'trueAna'
                                  }
                                  title="Conoce más de este proceso en nuestro video"
                                  text="Antes de iniciar tu entrenamiento puedes ver un video donde te damos más información del proceso para solicitar tu tarjeta de crédito."
                                />
                              </div>
                            </TrainingDivSection2>
                          </TrainingSectionGray>
                          {saludo === 'Si' ? (
                            <Fragment>
                              <TrainingDivSection
                                bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                                mt="44px"
                                mtw="60px"
                                br="4px"
                                padding="21px 20px"
                                wi="335px"
                                wiw="670px"
                                he="auto"
                                hew="auto"
                                mb="0px"
                              >
                                <div className="triangulo-equilatero-bottom"></div>
                                <div
                                  className="closeDiv"
                                  onClick={() => setSaludo('No')}
                                >
                                  ×
                                </div>
                                {urlVideo !== '' ? (
                                  <PlayYoutube
                                    idVideo={idVideo}
                                    width={'300'}
                                    height={'190'}
                                    close={false}
                                  />
                                ) : null}
                              </TrainingDivSection>
                              <TrainingDivSection
                                ml=" "
                                back=" "
                                backw=" "
                                paddw="4px"
                                //   bsha="0px 2px 48px rgba(0, 0, 0, 0.08)"
                                mt="44px"
                                mtw="85px"
                                br="4px"
                                padding="21px 20px"
                                wi="335px"
                                wiw="670px"
                                he="auto"
                                hew="auto"
                                mb="0"
                              >
                                <SendNotificationVideo urlYTDirect={urlVideo} />
                              </TrainingDivSection>
                            </Fragment>
                          ) : (
                            <PlayYoutube
                              idVideo={idVideo}
                              width={'300'}
                              height={'190'}
                              close={true}
                            />
                          )}
                        </Fragment>
                      ) : null}
                    </div>
                    {active4 ? (
                      <Fragment>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <TrainingDivSection2
                            paddiw="0"
                            paddi="0"
                            back="#FAFAFA"
                          >
                            <div>
                              {mainDataRoutine > 0 && orderRutine >= 1 ? (
                                <Fragment>
                                  <PerfilamientoCC
                                    Date={Date}
                                    hora={hora}
                                    orderRutine={orderRutine}
                                    setOrderRutine={setOrderRutine}
                                    currentTraining={currentTraining}
                                    createJsonInput={createJsonInput}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    currentRoutine={1}
                                    nextOrderRoutine={2}
                                    trainingColor={colorTraining}
                                  />
                                </Fragment>
                              ) : null}
                              <div
                                id="rutina1"
                                style={{
                                  height: '10px',
                                }}
                              ></div>

                              {orderRutine >= 2 ? (
                                <RpCompareCard
                                  Date={Date}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  currentRoutine={2}
                                  nextOrderRoutine={3}
                                  trainingColor={colorTraining}
                                />
                              ) : null}
                              <div id="rutina2"></div>

                              {orderRutine >= 3 ? (
                                <div>
                                  <CardOne
                                    classCard="cardOne"
                                    classTop="topOne1"
                                    text="Te ayudaré a obtener la mejor tarjeta de crédito para ti. 💳"
                                    text2="Lo primero es saber más de ti para buscar las opciones que se adapten a tu perfil."
                                    hora={hora}
                                  />
                                  <DatosPrincipales
                                    Date={stateDate}
                                    hora={hora}
                                    orderRutine={orderRutine}
                                    setOrderRutine={setOrderRutine}
                                    currentTraining={currentTraining}
                                    createJsonInput={createJsonInput}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    currentRoutine={3}
                                    nextOrderRoutine={4}
                                    trainingColor={colorTraining}
                                  />
                                </div>
                              ) : null}
                              <div id="rutina3"></div>

                              <DetailRFC
                                Date={Date}
                                hora={hora}
                                orderRutine={orderRutine}
                                setOrderRutine={setOrderRutine}
                                currentTraining={currentTraining}
                                createJsonInput={createJsonInput}
                                questions={questions}
                                setQuestions={setQuestions}
                                currentRoutine={4}
                                nextOrderRoutine={5}
                              />
                              <div id="rutina4"></div>

                              {orderRutine >= 5 ? (
                                <PersonalInformation
                                  Date={Date}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  currentRoutine={5}
                                  nextOrderRoutine={7}
                                  trainingColor={colorTraining}
                                />
                              ) : null}
                              <div id="rutina5"></div>
                              {orderRutine >= 7 ? (
                                <Domicile
                                  Date={Date}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  currentRoutine={7}
                                  nextOrderRoutine={8}
                                  trainingColor={colorTraining}
                                />
                              ) : null}
                              <div id="rutina7"></div>
                              {orderRutine >= 8 ? (
                                <UserAuthentication
                                  Date={Date}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  currentRoutine={8}
                                  nextOrderRoutine={9}
                                  trainingColor={colorTraining}
                                />
                              ) : null}
                              <div id="rutina8"></div>
                              {orderRutine >= 9 ? (
                                <DatosLaborales
                                  Date={Date}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  currentRoutine={9}
                                  nextOrderRoutine={10}
                                  trainingColor={colorTraining}
                                />
                              ) : null}
                              <div id="rutina9"></div>

                              {orderRutine >= 10 ? (
                                <Referencias
                                  Date={Date}
                                  hora={hora}
                                  orderRutine={orderRutine}
                                  setOrderRutine={setOrderRutine}
                                  currentTraining={currentTraining}
                                  createJsonInput={createJsonInput}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  currentRoutine={10}
                                  nextOrderRoutine={0}
                                  trainingColor={colorTraining}
                                />
                              ) : null}
                            </div>
                            <div id="rutina10"></div>
                          </TrainingDivSection2>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                    {!active4 ? (
                      <Fragment>
                        <br></br>
                        <TrainingSectionGray
                          wiw="670px"
                          mw="auto"
                          back="#FAFAFA"
                          mtop="0"
                        >
                          <Container
                            width="80%"
                            mt="1rem"
                            widthMobile="90%"
                            mtMobile="0rem"
                          >
                            <ImageCoachInicio colorAna="#917AFF" />
                            <Col7 padding=" " className="cardPrincipal1">
                              <LoadingWo />
                            </Col7>
                          </Container>
                        </TrainingSectionGray>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : (
                  <Fragment>
                    <SuccessWOValid {...trainingFinishedInfo} />
                  </Fragment>
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
              </Fragment>
            ) : (
              <Fragment>
                <br />
                <br />
                <br />
                <br />
                {executeQuery ? <WaitList /> : null}
              </Fragment>
            )}
          </div>
          <div
            style={{ position: 'absolute', top: '1em', right: '3em' }}
            id="kandaDesktop"
          ></div>
        </div>
      </TrainingMain>
    </WOlayout>
  );
}

export default ComparaTarjeta;
