import React, { useState, Fragment, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'react-elastic-carousel';
import { GET_PRODUCTS_BY_IDTRAINING } from '../../queries';
import { CREATE_OPPORTUNITIES } from '../../mutations';
import {
  DivStyle,
  DivGray,
  Resultado,
  CardsBtn,
  ImageSections,
  TrainingDivSection,
  DivLoader,
  CardsTxt,
  Images,
} from '../../assets/styles/Training.styled';
import check from '../../assets/img/anacoach/Vector.png';
import ClickButton from '../../Helpers/HookHelpers';
import { Col12 } from '../../components/common/common.styled';
import ProductsHooks from '../../Helpers/HookHelpersProducts';
import ModalVirality from '../../components/common/ModalVirality';

const RpDebs = ({
  Date, hora, currentTraining, insuranceActive,
}) => {
  const { sendApi } = ProductsHooks();
  const { actions } = ClickButton();
  const [products, setProducts] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [getProductsByIdTraining] = useLazyQuery(GET_PRODUCTS_BY_IDTRAINING, {
    onCompleted({ getProductsByIdTraining }) {
      console.log('Productos', getProductsByIdTraining);
      const prdctsArray = [];
      const prdcts = JSON.parse(getProductsByIdTraining.response);
      for (let j = 0; j < prdcts.length; j++) {
        if (prdcts[j].Name === 'RESUELVE TU DEUDA') {
          prdctsArray.push(prdcts[j]);
        }
      }
      for (let j = 0; j < prdcts.length; j++) {
        if (
          prdcts[j].Name !== 'RESUELVE TU DEUDA'
          && prdcts[j].Name !== 'PRESTANOMICO'
        ) {
          prdctsArray.push(prdcts[j]);
        }
      }
      setProducts(prdctsArray);
      setShowProduct(true);
    },
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    getProductsByIdTraining({
      variables: { idTraining: String(currentTraining) },
    });
  }, []);
  const [createOpportunitiesUser] = useMutation(CREATE_OPPORTUNITIES, {
    onCompleted({ createOpportunities }) {
      if (createOpportunities.statusCode === 200) {
        const response = JSON.parse(createOpportunities.response);
        console.log(response);
        sessionStorage.setItem('datOpportunity', response.idOpportunities);
        sessionStorage.setItem('datProducts', response.idProducts);
        const tagManagerArgs = {
          dataLayer: {
            event: 'createOpportunity',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        sessionStorage.setItem('datProducts', response.idProducts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const abrirNuevoTab = (url, name) => {
    sessionStorage.setItem('clickOutProduct', name);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickOutProduct',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    // Abrir nuevo tab
    const win = window.open(url, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();
  };
  const selectedProduct = (product) => {
    const click = `Aplicar_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    console.log(product);
    sessionStorage.setItem('selectProductName', product.Name);
    const opportunityDetailInput = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idVertical: 4, // remplazar por el id del entrenamiento
        idProducts: [product.IDProduct], // arreglo de numero ejemplo [10,1,3]
        idTraining: 12,
        idType: '295',
        value: product.Name,
      },
    };
    console.log(opportunityDetailInput);
    createOpportunitiesUser({ variables: opportunityDetailInput });

    sendApi({ action: product.IDProduct, variables: '' });

    abrirNuevoTab(product.ClickOut, product.Name);
  };
  useEffect(() => {
    setTimeout(() => {
      const content = document.getElementById('contentRP');
      const scrollStep = 310;
      if (
        document.getElementById('right-button') !== null
        && document.getElementById('right-button') !== undefined
      ) {
        document
          .getElementById('right-button')
          .addEventListener('click', (e) => {
            e.preventDefault();
            const sl = content.scrollLeft;
            const cw = content.scrollWidth;

            if (sl + scrollStep >= cw) {
              content.scrollTo(cw, 0);
            } else {
              content.scrollTo(sl + scrollStep, 0);
            }
          });
      }
    }, 1500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const content = document.getElementById('contentRP');
      const scrollStep = 310;
      if (
        document.getElementById('left-button') !== null
        && document.getElementById('left-button') !== undefined
      ) {
        document
          .getElementById('left-button')
          .addEventListener('click', (e) => {
            e.preventDefault();
            const sl = content.scrollLeft;

            if (sl - scrollStep <= 0) {
              content.scrollTo(0, 0);
            } else {
              content.scrollTo(sl - scrollStep, 0);
            }
          });
      }
    }, 1500);
  }, []);

  if (!showProduct) {
    return (
      <DivLoader>
        <CircularProgress color="inherit" />
      </DivLoader>
    );
  }
  const resultProducts = products.map((currentValue, index) => {
    if (insuranceActive.indexOf(currentValue.Name) !== -1) {
      return (
        <Fragment key={index}>
          <ModalVirality />
          <Resultado
            key={currentValue.IDProduct}
            marriweb="8px"
            marri="8px"
            bcolor="#fff"
            ta="left"
            border="solid 1px #D6D6D6"
            hei="335px"
            minh="360px"
            minhweb="335px"
          >
            <ImageSections
              width="auto"
              hmob="40px"
              ml="5%"
              heightweb="40px"
              src={currentValue.ImageURL}
              className="img-fluid"
            />
            <br />
            <DivStyle
              className="cabin"
              mxh="30px"
              weight="600"
              align="left"
              size="19px"
              line="1.2em"
              padd="12px 5% 30px 5%"
              color="#07210A"
            >
              {currentValue.Name}
            </DivStyle>
            <DivGray bcolor="#fff" padd="10px 5% 0 5%">
              <div>
                <DivStyle align="left" mh="150px" size="14px">
                  <CardsTxt
                    color="#07210A"
                    weight="bold"
                    className="cabin"
                    size="16px"
                    ta="left"
                  >
                    Beneficios
                  </CardsTxt>
                  {currentValue.benefits.map((detalle) => {
                    if (detalle.Name === 'Beneficio 1') {
                      return (
                        <li
                          key={detalle.IDProductDetail}
                          className="roboto styleBenef colortxt list-none"
                          style={{ marginBottom: '6px' }}
                        >
                          <Images
                            src={check}
                            top="-2px"
                            topweb="-2px"
                            left="0"
                            leftw="0"
                            width="15px"
                            rigth="10px"
                            rigthm="10px"
                            position="initial"
                            className="img-fluid"
                          ></Images>
                          {detalle.Description}
                        </li>
                      );
                    }
                    if (detalle.Name === 'Beneficio 2') {
                      return (
                        <li
                          key={detalle.IDProductDetail}
                          className="roboto styleBenef colortxt list-none"
                          style={{ marginBottom: '6px' }}
                        >
                          <Images
                            src={check}
                            top="-2px"
                            topweb="-2px"
                            left="0"
                            leftw="0"
                            width="15px"
                            rigth="10px"
                            rigthm="10px"
                            position="initial"
                            className="img-fluid"
                          ></Images>
                          {detalle.Description}
                        </li>
                      );
                    }
                    if (detalle.Name === 'Beneficio 3') {
                      if (detalle.Description !== '-') {
                        return (
                          <li
                            key={detalle.IDProductDetail}
                            className="roboto styleBenef colortxt list-none"
                            style={{ marginBottom: '6px' }}
                          >
                            <Images
                              src={check}
                              top="-2px"
                              topweb="-2px"
                              left="0"
                              leftw="0"
                              width="15px"
                              rigthm="10px"
                              rigth="10px"
                              position="initial"
                              className="img-fluid"
                            ></Images>
                            {detalle.Description}
                          </li>
                        );
                      }
                    }
                  })}
                </DivStyle>
                <div className="center">
                  <CardsBtn
                    mtop="0"
                    mtopw="0"
                    min_w="100%"
                    onClick={() => selectedProduct(currentValue)}
                  >
                    Aplicar
                  </CardsBtn>
                </div>
                <br />
              </div>
            </DivGray>
          </Resultado>
        </Fragment>
      );
    }
  });

  return (
    <>
      <TrainingDivSection
        padding="0 3%"
        paddw="3% 0"
        back="#fafafa"
        backw="#fafafa"
        mt="30px"
      >
        <Col12
          id="CarruselEmpresasContentHome"
          pt="0px"
          flex="0 0 100%"
          maxwidth="100%"
          display="flex"
        >
          <Carousel
            id="CarruselMetasLogros"
            styled={{ background: '#FAFAFA' }}
            transitionMs={1000}
          >
            {resultProducts}
          </Carousel>
        </Col12>
      </TrainingDivSection>
    </>
  );
};
export default RpDebs;
