/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Import visual components
import { useLazyQuery } from '@apollo/client';
import {
  TrainingQuestion,
  CardsBtn,
  QuestionsDivs,
  TrainingDivSection,
  DivRespDP,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
// Import query components and helpers
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines, filterFloat } from '../../Helpers';
import editIcon from '../../assets/img/anacoach/editar1.png';
// Other components
import InputsOrder from '../../components/common/InputsOrder';
import QuestionsOrder from '../../components/common/QuestionsOrder';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import ClickButton from '../../Helpers/HookHelpers';

const Ingresos = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
}) => {
  // Questions state variables
  // This state variables will save the answers of each question of this routine
  // useful for tracking info, validations and "data recovery"
  const [fuentesIngresos, setFuentesIngresos] = useState(
    sessionStorage.getItem('FuentesIngresos') || '',
  );
  const [montoIngresos, setMontoIngresos] = useState(
    sessionStorage.getItem('MontoIngresos') || '',
  );
  const [ingresosVariables, setIngresosVariables] = useState(
    sessionStorage.getItem('IngresosVariables') || '',
  );
  const [montoIngresosVariable, setMontoIngresosVariable] = useState(
    sessionStorage.getItem('MontoIngresosVariable') || '',
  );
  const [editfuentesIngresos, seteditfuentesIngresos] = useState(
    fuentesIngresos !== '',
  );
  const [editmontoIngresos, seteditmontoIngresos] = useState(
    montoIngresos !== '',
  );
  const [editingresosVariables, seteditingresosVariables] = useState(
    ingresosVariables !== '',
  );
  const [editmontoIngresosVariable, seteditmontoIngresosVariable] = useState(
    montoIngresosVariable !== '',
  );
  // State used for recoveryData process and validations
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const anchop = window.screen.width;

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  // Keep data from user's aswers, useful for validations and for saving data
  const [form, setForm] = useState({
    montoIngresos: sessionStorage.getItem('MontoIngresos') || '',
    montoIngresosVariable:
      sessionStorage.getItem('MontoIngresosVariable') || '',
  });

  // Variables and functions useful for inputs-order component, this variables and functions are common on each routine
  const { actions } = ClickButton();
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat' || name === 'empresa') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = ({ value, step }) => {
    console.log('Question answered', value, step);

    switch (step) {
      case 0:
        setFuentesIngresos(value);
        seteditfuentesIngresos(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest2Ingresos' });
        break;
      case 2:
        setIngresosVariables(value);
        seteditingresosVariables(true);
        if (value === 'No') {
          setOrderRutine(nextOrderRoutine);
        }
        gsap.to(window, { duration: 1, scrollTo: '#activeQuest4Ingresos' });
        break;
      default:
        console.log('default break handleChangeCatOption case 1');
        break;
    }
  };
  const formatterMx = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    const arr = number.toString().split('.');
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join('.') : arr[0];
  };
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);
    if (value !== '') {
      setForm({
        ...form,
        [e.target.name]: `$${formatterMx(filterFloat(value))}`,
      });
    } else if (e.target.value === '$') {
      setForm({
        ...form,
        [e.target.name]: '',
      });
    }
  };

  // Recovery variables and functions
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        console.log(json);
        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json;
          console.log(routines);
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }
            if (stopRoutine) {
              console.log('detente');
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              setOrderRutine(nextOrderRoutine);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    if (
      sessionStorage.getItem('idUser') !== ''
      && sessionStorage.getItem('idCont') !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);

  // Veify is the questions are already answered
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      setFuentesIngresos(sessionStorage.getItem('FuentesIngresos') || '');
      setMontoIngresos(sessionStorage.getItem('MontoIngresos') || '');
      setIngresosVariables(sessionStorage.getItem('IngresosVariables') || '');
      setMontoIngresosVariable(
        sessionStorage.getItem('MontoIngresosVariable') || '',
      );
      if (sessionStorage.getItem('IngresosVariables') === 'No') {
        setOrderRutine(nextOrderRoutine);
      } else if (
        sessionStorage.getItem('MontoIngresosVariable') !== ''
        && sessionStorage.getItem('MontoIngresosVariable') !== null
      ) {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);

  const saveMontoIngreso = (e) => {
    const click = `ContinuarIngreso_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    console.log(questions);
    if (form.montoIngreso !== '') {
      setMontoIngresos(form.montoIngresos);
      console.log('totalIngreso1', form.montoIngresos);
      questions.push({
        id: 'sumaIngreso',
        question: 5,
        value: form.montoIngresos,
      });
      seteditmontoIngresos(true);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
    gsap.to(window, { duration: 1, scrollTo: '#activeQuest3Ingresos' });
  };
  const convertirNum = (num) => (num ? parseFloat(num.replace(/,/g, '').replace('$', '').trim()) : 0);
  const saveMontoIngresoVariable = (e) => {
    const click = `ContinuarIngresoVariable_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    console.log(questions);
    if (form.montoIngresosVariable !== '') {
      setMontoIngresosVariable(form.montoIngresosVariable);
      const totalMontoIngresosVariable = convertirNum(form.montoIngresos)
        + convertirNum(form.montoIngresosVariable);
      console.log('totalIngreso', totalMontoIngresosVariable);
      questions.push({
        id: 'sumaIngreso',
        question: 5,
        value: `$${formatterMx(totalMontoIngresosVariable)}`,
      });

      createJsonInput(Rutine, questions);
      setQuestions([]);
      setOrderRutine(nextOrderRoutine);
      seteditmontoIngresosVariable(true);
    }
  };

  const handleChangeIcon = (json) => {
    if (json.active === 1) {
      seteditfuentesIngresos(false);
    } else if (json.active === 2) {
      seteditmontoIngresos(false);
    } else if (json.active === 3) {
      seteditingresosVariables(false);
    } else if (json.active === 4) {
      setMontoIngresosVariable(false);
    }
  };

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === 6) {
        gsap.to(window, { duration: 1, scrollTo: '#activeIngresosMensuales' });
      }
    }, 4000);
  }, []);

  return (
    <>
      {!loaderWait || orderRutine !== 6 ? (
        <>
          <div id="activeIngresosMensuales"></div>
          <QuestionsOrder
            classCard='cardOne'
            orderRutine={currentRoutine}
            step={0}
            handleChangeRutine={handleChangeRutine}
            currentTraining={currentTraining}
            textBold=""
            text2=""
            hora={hora}
          />
          <TrainingQuestion
            className={
              fuentesIngresos === ''
              || (fuentesIngresos !== '' && !editfuentesIngresos)
                ? ''
                : 'hidden'
            }
          >
            <InputsOrder
              handleChangeRutine={handleChangeRutine}
              orderRutine={currentRoutine}
              step="0-0"
              handleChangeQuestionsSend={handleChangeQuestionsSend}
              handleChangeCatOption={handleChangeCatOption}
              form={form}
              handleChange={handleChange}
              currentTraining={currentTraining}
              activeStep={true}
            />
          </TrainingQuestion>
          <div id="activeQuest2Ingresos"></div>
          <CardTwo
            classCard={
              fuentesIngresos !== '' && editfuentesIngresos
                ? 'cardTwoResp'
                : 'hidden'
            }
            classTop="topTwo1"
            imgMan="hidden"
            text={fuentesIngresos}
            colorTraining={trainingColor}
          >
            <img
              alt="editIcon"
              className={!editmontoIngresos ? 'iconEditCommon' : 'hidden'}
              src={editIcon}
              onClick={() => handleChangeIcon({ routine: 5, active: 1 })}
            />
          </CardTwo>
        </>
      ) : (
        <Container width="80%" mt="1rem" widthMobile="90%" mtMobile="1rem">
          <ImageCoachInicio />
          <Col7 padding=" " className="cardPrincipal1">
            <LoadingWo />
          </Col7>
        </Container>
      )}
      {/* pregunta numero dos  */}

      <QuestionsOrder
        classCard={
          fuentesIngresos !== '' && editfuentesIngresos ? 'cardOne' : 'hidden'
        }
        orderRutine={currentRoutine}
        step={1}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          (fuentesIngresos !== ''
            && montoIngresos === ''
            && editfuentesIngresos)
          || (montoIngresos !== '' && editfuentesIngresos && !editmontoIngresos)
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
          bs="transparent"
        >
          <form>
            <TrainingDivSection
              hew="auto"
              paddw="26px"
              mlw="18%"
              mtw="8%"
              wiw="60%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="90%"
              he="auto"
              className="topOne1 topOne1Init"
            >
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="1-1"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                labelInput="Monto"
              />

              <div style={{ display: 'flex' }}>
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtop="0px"
                  mtopw="0px"
                  onClick={(e) => saveMontoIngreso(e)}
                >
                  Continuar
                </CardsBtn>
              </div>
            </TrainingDivSection>
          </form>
        </QuestionsDivs>
      </TrainingQuestion>
      <div id="activeQuest3Ingresos"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            montoIngresos !== '' && editmontoIngresos ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 2 })}
        />
        <CardTwo
          classCard={
            montoIngresos !== '' && editmontoIngresos ? 'cardTwoResp' : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={montoIngresos ? `${montoIngresos}` : ''}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>

      {/* tercer pregunta */}
      <QuestionsOrder
        classCard={
          montoIngresos !== '' && editmontoIngresos ? 'cardOne' : 'hidden'
        }
        orderRutine={currentRoutine}
        step={2}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        className={
          (montoIngresos !== ''
            && ingresosVariables === ''
            && editmontoIngresos)
          || (ingresosVariables !== ''
            && editmontoIngresos
            && !editingresosVariables)
            ? ''
            : 'hidden'
        }
      >
        <InputsOrder
          handleChangeRutine={handleChangeRutine}
          orderRutine={currentRoutine}
          step="2-2"
          handleChangeQuestionsSend={handleChangeQuestionsSend}
          handleChangeCatOption={handleChangeCatOption}
          form={form}
          handleChange={handleChange}
          currentTraining={currentTraining}
          activeStep={true}
        />
      </TrainingQuestion>
      <div id="activeQuest4Ingresos"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            ingresosVariables !== '' && editingresosVariables
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 3 })}
        />
        <CardTwo
          classCard={
            ingresosVariables !== '' && editingresosVariables
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={ingresosVariables}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      {/* cuarta pregunta */}
      <QuestionsOrder
        classCard={
          ingresosVariables === 'Sí' && editingresosVariables
            ? 'cardOne'
            : 'hidden'
        }
        orderRutine={currentRoutine}
        step={3}
        handleChangeRutine={handleChangeRutine}
        currentTraining={currentTraining}
        textBold=""
        text2=""
        hora={hora}
      />
      <TrainingQuestion
        width="100%"
        wiw="100%"
        mt="0"
        className={
          (ingresosVariables === 'Sí'
            && montoIngresosVariable === ''
            && editingresosVariables)
          || (ingresosVariables === 'Sí'
            && montoIngresosVariable !== ''
            && editingresosVariables
            && !editmontoIngresosVariable)
            ? ''
            : 'hidden'
        }
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width={anchop <= 768 ? '0 0 96%' : '0 0 90%'}
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
          bs="transparent"
        >
          <form>
            <TrainingDivSection
              hew="auto"
              paddw="26px"
              mlw="18%"
              mtw="8%"
              wiw="60%"
              back="#F3F3F3"
              backw="#F3F3F3"
              bshaw="none"
              bsha=""
              br="4px"
              padding="20px"
              wi="100%"
              he="auto"
              className="topOne1 topOne1Init"
            >
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={currentRoutine}
                step="3-3"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
                labelInput="Monto"
              />
              <CardsBtn
                type="button"
                min_w="100%"
                min_web="100%"
                mtop="0px"
                mtopw="0px"
                onClick={(e) => saveMontoIngresoVariable(e)}
              >
                Continuar
              </CardsBtn>
            </TrainingDivSection>
          </form>
        </QuestionsDivs>
      </TrainingQuestion>

      <DivRespDP>
        <img
          alt="editIcon"
          className={orderRutine === 7 ? 'iconEdit1' : 'hidden'}
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 4 })}
        />
        <CardTwo
          classCard={
            montoIngresosVariable !== '' && editmontoIngresosVariable
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={montoIngresosVariable ? `${montoIngresosVariable}` : ''}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
    </>
  );
};

export default Ingresos;
