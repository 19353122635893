import React, { useState, useEffect, Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/client';
// Other components
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// modal
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@material-ui/core';

import DialogContentText from '@material-ui/core/DialogContentText';
import CreateStrategyGoal from './CreateStrategyGoal';
import DetailGoal from './DetailGoal';
import Addcircle from '../../assets/img/anacoach/add_circle.png';
import SectionDetailGoal from '../Training/SectionDetailGoal';
import {
  filterFloat,
  formatterMoney,
} from '../../Helpers';
import {
  Container, Col2, Col10, Image,
} from './common.styled';
import Starttraining from '../Training/Starttraining';
import {
  TrainingQuestion,
  CardsBtn,
  CardsBtn1,
  QuestionsDivs,
  TrainingSectionGray,
  TrainingDivSection2,
  QuestionsText,
  Resultado,
  TrainingText,
  DivStyle,
} from '../../assets/styles/Training.styled';
import {
  DYNAMIC_UPDATE,
  INSERT_DAT_GOAL,
  INSERT_WAIT_LIST_PRODUCT_USER,
  SAVE_STATUS_TRAINING,
} from '../../mutations';
import ClickButton from '../../Helpers/HookHelpers';
import { GET_PRODUCTS_BY_IDTRAINING } from '../../queries';
import ModalLearnMore from './ModalLearnMore';
import ProductsFremium from './ProductsFremium';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let recoveryGoalsLocal;
let currentGoalLocal;
const ViewGoals = ({
  historyGoals,
  currentTraining,
  handelChangeRecoveryHistory,
  recoveryGoals,
  dineroDisponible,
  setPropertiesStatusGoals,
  updateProgress = () => true,
}) => {
  const [openGoals, setOpenGoals] = useState(false);
  const [goalsMultiple, setGoalsMultiple] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [montoMeta, setMontoMeta] = useState('');
  const [ahorroMensual, setAhorroMensual] = useState('');
  const [tiempodeAhorro, setTiempodeAhorro] = useState('');
  const [detailsGoals, setDetailGoals] = useState({});
  const [currentGoal, setCurrentGoal] = useState('');
  const [verNewGoal, setVerNewGoal] = useState(false);
  const [disponible, setDisponible] = useState(0);
  const [sumaSaldoUtilizado, setSumaSaldoUtilizado] = useState(0);
  const [newMontoMeta, setNewMontoMeta] = useState('');
  const [inputNewGoal, setInputNewGoal] = useState(false);
  const [newGoal, setNewGoal] = useState('');
  const [updateIdMetaquincenal, setUpdateIdMetaQuincenal] = useState('');
  const [goalUpdateName, setGoalUpdateName] = useState('');
  const [isCurrentGoalUpdate, setIsCurrentGoalUpdate] = useState(false);
  const [currentGoalSeted, setCurrentGoalSeted] = useState(false);
  const [lastRoutineCheck, setLastRoutineCheck] = useState(false);
  const [mustLoadProducts, setMustLoadProducts] = useState(false);
  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [menus, setMenus] = useState([]);
  const anchop = window.screen.width;
  const [showMoreGoalsBtn, setShowMoreGoalsBtn] = useState(true);
  const { actions } = ClickButton();

  const [saveStatusTraining] = useMutation(SAVE_STATUS_TRAINING, {
    fetchPolicy: 'no-cache',
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      recoveryGoals !== recoveryGoalsLocal
      || currentGoal !== currentGoalLocal
    ) {
      recoveryGoalsLocal = recoveryGoals;
      currentGoalLocal = currentGoal;
      const items = [];
      const details = {};
      let nameGoal = '';
      let sumaSaldoUtilizado = 0;
      const metasArr = [];
      console.log('# 1 - historyGoals >>>', historyGoals);
      for (let i = 0; i < historyGoals.length; i++) {
        console.log('# 2 - historyGoals[i] >>>', historyGoals[i]);
        const goal = historyGoals[i];
        metasArr.push(Object.keys(historyGoals[i])[0]);
        for (const [key, value] of Object.entries(goal)) {
          console.log('# 3 - key, value >>>', key, value);
          items.push({
            id: `elemento-${i}`,
            goal: key,
            idgoal: value[0].idGoal,
          });
          menus.push(false);
          if (i == 0 && currentGoal === '') {
            setCurrentGoal(key);
            nameGoal = key;
          } else if (currentGoal !== '') {
            nameGoal = currentGoal;
          }

          if (value[0].details.length > 0) {
            details[key] = value[0].details;
            sumaSaldoUtilizado += parseInt(
              String(value[0].details[1].Value)
                .replace('$', '')
                .replace(/,/g, '')
                .trim(),
            );
            setSumaSaldoUtilizado(sumaSaldoUtilizado);
            setDisponible(dineroDisponible - sumaSaldoUtilizado);
            setDetailGoals(details);
          } else {
            details[key] = [];
            setDetailGoals(details);
          }
        }
      }

      const metasJoin = metasArr.join(',');
      console.log('# 4 - metasJoin >>>', metasJoin);
      sessionStorage.setItem('SeleccionaMeta', metasJoin);
      const isThere = metasJoin.indexOf('Viajar');

      if (isThere !== -1) {
        setMustLoadProducts(true);
      }

      if (details[nameGoal].length > 0) {
        setAhorroMensual(details[nameGoal][2].Value);
        setTiempodeAhorro(details[nameGoal][3].Value);
        if (!currentGoalSeted) {
          setMontoMeta(details[nameGoal][0].Value);
          setCurrentGoalSeted(true);
        }
      } else {
        setAhorroMensual(0);
        setTiempodeAhorro(0);
        setMontoMeta(0);
      }
      setGoalsMultiple([{ columnId: 'menu-1', items }]);
    }
  }, [recoveryGoals, currentGoal, historyGoals]);
  const handleClickOpenGoals = () => {
    const click = `VerMisMetas_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    setOpenGoals(true);
    setShowMoreGoalsBtn(false);
  };
  const [dynamicUpdate] = useMutation(DYNAMIC_UPDATE, {
    fetchPolicy: 'no-cache',
    onCompleted({ dynamicUpdate }) {
      if (dynamicUpdate.message === 'success') {
        console.log('update exitoso');
      } else {
        console.log(dynamicUpdate.message);
      }
    },
  });
  const [insertDatGoal] = useMutation(INSERT_DAT_GOAL, {
    onCompleted({ insertDatGoal }) {
      if (insertDatGoal.message === 'success') {
        console.log('insercion exitossa, ID = ', insertDatGoal);
      }
      handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
    },
    onError(err) {
      handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
      console.log(err);
    },
  });

  useEffect(() => {
    if (!lastRoutineCheck) {
      const idUser = sessionStorage.getItem('idUser');
      const idCont = sessionStorage.getItem('idCont');

      for (let i = 1; i <= 8; i++) {
        const inputProgress = {
          input: {
            idRoutine: '',
            orderRoutine: String(i),
            idTraining: String(currentTraining),
            idUser: String(idUser),
            idCont: String(idCont),
            isMaster: '1',
          },
        };

        if (idUser != null) {
          saveStatusTraining({ variables: inputProgress });
        }
      }
      setLastRoutineCheck(true);
    }

    updateProgress();
  }, []);

  const handleClick = (index) => (event) => {
    menus[index] = true;
    setAnchorEl(event.currentTarget);
    setMenus(menus);
  };

  const handleClose = (index) => () => {
    menus[index] = false;

    setAnchorEl(null);
    setMenus(menus);
  };
  const handleCreateStrategy = (item, j) => () => {
    setCurrentGoal(item.goal);
    setVerNewGoal(true);
    menus[j] = false;
    setAnchorEl(null);
    setMenus(menus);
  };
  const handleViewGoal = (item, j) => () => {
    setCurrentGoal(item.goal);
    menus[j] = false;
    setAnchorEl(null);
    setMenus(menus);
  };

  const handleUpdateGoal = (item, j) => () => {
    setGoalUpdateName(item.goal);
    if (currentGoal === item.goal) {
      setIsCurrentGoalUpdate(true);
    } else {
      setIsCurrentGoalUpdate(false);
    }
    handleClickOpen();
    setUpdateIdMetaQuincenal(item.idgoal);
    menus[j] = false;
    setAnchorEl(null);
    setMenus(menus);
  };
  const handleDeleteGoal = (item, j) => () => {
    const updateDatGoal = {
      input: {
        table: 'DatGoals',
        columns: [
          {
            setkey: 'ShowGoal',
            setval: '0',
          },
        ],
        conditions: [
          {
            valone: 'idDatGoals',
            valtwo: String(item.idgoal),
            condition: '=',
            logic: '',
            validColumn: true,
          },
        ],
      },
    };

    dynamicUpdate({ variables: updateDatGoal });
    handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
    menus[j] = false;
    setAnchorEl(null);
    setMenus(menus);
  };
  const handleChangeQuincena = (e) => {
    let value = e.target.value.replace('$', '').replace(/,/g, '').trim();
    value = filterFloat(value);

    if (value !== '') {
      const newValue = formatterMoney.format(filterFloat(value));

      if (!isCurrentGoalUpdate) {
        setNewMontoMeta(newValue);
      } else {
        setMontoMeta(newValue);
      }
    } else if (e.target.value === '$') {
      setNewMontoMeta('');
    }
  };
  const actualizarDetailsGoal = async (value, idGoal, idType) => {
    const updateDatGoal = {
      input: {
        table: 'DatGoalsDetails',
        columns: [
          {
            setkey: 'Value',
            setval: value,
          },
        ],
        conditions: [
          {
            valone: 'DatGoals_IdDatGoals',
            valtwo: String(idGoal),
            condition: '=',
            logic: '',
            validColumn: true,
          },
          {
            valone: 'IdType',
            valtwo: idType,
            condition: '=',
            logic: 'AND',
            validColumn: true,
          },
        ],
      },
    };

    await dynamicUpdate({ variables: updateDatGoal });
  };
  const handleUpdateQuincena = () => {
    const click = `GuardarActualizacionMeta_${sessionStorage.getItem(
      'idTraining',
    )}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    let montoTemp = '';
    if (isCurrentGoalUpdate) {
      montoTemp = montoMeta;
    } else {
      montoTemp = newMontoMeta;
    }
    actualizarDetailsGoal(montoTemp, updateIdMetaquincenal, '343');

    /* Update time */
    const time = parseFloat(montoTemp.replace('$', '').replace(/,/g, '').trim())
      / (disponible * 2);

    let remainder = (time % 1).toFixed(1);

    remainder = parseFloat(remainder);

    let months = 0;

    if (remainder > 0) {
      if (remainder >= 0.5) {
        months = Math.round(time.toFixed(1));
      } else {
        months = Math.round(time.toFixed(1)) + 1;
      }
    } else {
      months = time;
    }

    actualizarDetailsGoal(months.toString(), updateIdMetaquincenal, '346');

    let updateAhorro = montoTemp.replace('$', '').replace(/,/g, '').trim();

    updateAhorro = filterFloat(updateAhorro);
    actualizarDetailsGoal(
      formatterMoney.format(updateAhorro * 2),
      updateIdMetaquincenal,
      '345',
    );

    handelChangeRecoveryHistory(Math.floor(Math.random() * 100));
    handleCloseModal();
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleAddGoals = (e) => {
    const click = `AgregarNuevaMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    setInputNewGoal(true);
  };
  const handleSaveAddGoals = (e) => {
    const click = `GuardarNuevaMeta_${sessionStorage.getItem('idTraining')}`;
    actions({ action: 'ClickButtonCollection', variables: click });
    e.preventDefault();
    const goalNew = newGoal;
    const inputInsert = {
      input: {
        idUser: parseInt(sessionStorage.getItem('idUser')),
        idTraining: parseInt(sessionStorage.getItem('idTraining')),
        name: goalNew,
        goalsDetails: [
          {
            idType: '343',
            value: '$0.00',
          },
          {
            idType: '344',
            value: '$0.00',
          },
          {
            idType: '345',
            value: '$0.00',
          },
          {
            idType: '346',
            value: '0',
          },
        ],
        saveAmount: '',
        description: '',
      },
    };
    console.log(JSON.stringify(inputInsert));

    insertDatGoal({
      variables: inputInsert,
    });
    setNewGoal('');
    setInputNewGoal(false);
  };
  useEffect(() => {
    console.log(currentGoal);
  }, [currentGoal]);

  return (
    <>
      <TrainingSectionGray wiw="670px" mw="auto" back="#FAFAFA" mtop="0">
        <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
          <Starttraining
            title="¡Hola! Soy Ana, tu coach financiero. Alcanza tus metas"
            text="En este entrenamiento trabajaremos juntos para trazar estrategias y cumplir cada una"
          />
        </TrainingDivSection2>
      </TrainingSectionGray>
      <br />
      <TrainingQuestion>
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          {showMoreGoalsBtn ? (
            <CardsBtn1
              type="button"
              windthmd="100%"
              onClick={(e) => handleClickOpenGoals(e)}
            >
              Ver mis metas
            </CardsBtn1>
          ) : (
            ''
          )}
        </QuestionsDivs>
      </TrainingQuestion>
      <TrainingSectionGray
        className={openGoals ? '' : 'hidden'}
        wiw="670px"
        mw="auto"
        back="#FAFAFA"
        mtop="0"
      >
        <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
          <Starttraining
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            title=""
            text="¡Sigue así! La constancia es tu mejor aliada."
            text2="Selecciona una meta para ver tu progreso"
          />
        </TrainingDivSection2>
      </TrainingSectionGray>
      {/* Diseño de secciones */}
      {openGoals ? (
        <SectionDetailGoal
          goal={currentGoal}
          tiempoAhorro={tiempodeAhorro}
          montoMeta={montoMeta}
        />
      ) : null}
      {/* Diseño de secciones */}
      <TrainingQuestion
        wiw="66%"
        mLg="15%"
        width="100%"
        leftg="auto"
        rigthg="auto"
        className={openGoals ? '' : 'hidden'}
      >
        {goalsMultiple.map((col, i) => (
          <div key={i}>
            {col.items.map((el, j) => (
              <QuestionsDivs
                key={j}
                width="90%"
                margin="8px auto auto;"
                txta="left"
                padd="15px"
                hei="auto"
                bs="0px 2px 8px rgba(0,0,0,0.08)"
              >
                <QuestionsText size="14px" weight="400">
                  <Container>
                    <Col10>{`${el.goal}`}</Col10>
                    <Col2>
                      <IconButton
                        aria-label="Ver más"
                        aria-owns={anchorEl ? `long-menu-${j}` : null}
                        // aria-controls={`long-menu-${j}`}
                        aria-haspopup="true"
                        onClick={handleClick(j)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={`long-menu-${j}`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={menus[j]}
                        onClose={handleClose(j)}
                      >
                        <MenuItem onClick={handleViewGoal(el, j)}>
                          Ver meta
                        </MenuItem>
                        {j !== 0 ? (
                          <MenuItem onClick={handleCreateStrategy(el, j)}>
                            Crear estrategia
                          </MenuItem>
                        ) : null}

                        <MenuItem onClick={handleUpdateGoal(el, j)}>
                          Actualizar
                        </MenuItem>
                        <MenuItem onClick={handleDeleteGoal(el, j)}>
                          Eliminar
                        </MenuItem>
                      </Menu>
                    </Col2>
                  </Container>
                </QuestionsText>
              </QuestionsDivs>
            ))}
          </div>
        ))}
      </TrainingQuestion>

      <TrainingQuestion
        wiw="60%"
        mLg="18%"
        className={goalsMultiple.length > 0 && openGoals ? '' : 'hidden'}
      >
        <QuestionsDivs
          padd="0"
          txta="left"
          width="100%"
          widthweb="100%"
          hei="auto"
          margin="0 auto auto"
          bc="#fafafa"
        >
          <CardsBtn
            type="button"
            min_w="100%"
            min_web="100%"
            color="#EC407A !important"
            back="#fff !important"
            border="solid 1px !important"
            onClick={(e) => {
              handleAddGoals(e);
            }}
          >
            <Image
              src={Addcircle}
              width="30px"
              marginRight="15px"
              alt="logo-coru"
            />
            Agregar una nueva meta
          </CardsBtn>
        </QuestionsDivs>
      </TrainingQuestion>

      <TrainingQuestion
        className={
          goalsMultiple.length > 0 && openGoals && inputNewGoal ? '' : 'hidden'
        }
      >
        <TrainingSectionGray
          padding="3% 5% 3% 5%"
          paddinglG="5%"
          wiw="60%"
          mw="0 0 0 18%"
          mn="auto"
          mtop="0"
        >
          <QuestionsDivs
            padd="0"
            txta="left"
            width="100%"
            widthweb="100%"
            hei="auto"
            margin="0 auto auto"
            bc="transparent"
          >
            <TextField
              variant="outlined"
              label="Nueva meta"
              autoComplete="off"
              required
              fullWidth
              className={
                inputNewGoal ? 'textInput inputs-bottom input-coru' : 'hidden'
              }
              value={newGoal}
              onBlur={(e) => setNewGoal(e.target.value)}
              onChange={(e) => setNewGoal(e.target.value)}
            />
            <CardsBtn
              min_w="100%"
              min_web="100%"
              onClick={(e) => {
                handleSaveAddGoals(e);
              }}
            >
              Guardar
            </CardsBtn>
          </QuestionsDivs>
        </TrainingSectionGray>
      </TrainingQuestion>
      {verNewGoal ? (
        <CreateStrategyGoal
          handelChangeRecoveryHistory={handelChangeRecoveryHistory}
          className={verNewGoal}
          setVerNewGoal={setVerNewGoal}
          currentGoal={currentGoal}
          sumaSaldoUtilizado={sumaSaldoUtilizado}
        ></CreateStrategyGoal>
      ) : null}

      {/* historial de quincenas */}
      {historyGoals.length > 0
      && currentGoal !== ''
      && openGoals
      && !verNewGoal ? (
        <DetailGoal
          historyGoals={historyGoals}
          currentTraining={currentTraining}
          nameGoal={currentGoal}
          handelChangeRecoveryHistory={handelChangeRecoveryHistory}
          setPropertiesStatusGoals={setPropertiesStatusGoals}
        ></DetailGoal>
        ) : null}

      <Dialog open={openModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
          Actualizar meta
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!isCurrentGoalUpdate ? (
              `Tienes disponible para cumplir tus metas ${disponible} ¿Cuánto quieres destinar a esta nueva meta`
            ) : (
              <Fragment>
                <p>
                  {' '}
                  Bienvenido de nuevo{' '}
                  {sessionStorage.getItem('FirstName') || ''}, registra el
                  avance de esta quincena:{' '}
                </p>
                <p> Concepto: {goalUpdateName} </p>
              </Fragment>
            )}
            {/*  */}
          </DialogContentText>

          <TextField
            variant="outlined"
            label="Monto de la meta!"
            autoComplete="off"
            required
            fullWidth
            inputProps={{ maxLength: 10 }}
            className="textInput inputs-bottom input-coru"
            value={!isCurrentGoalUpdate ? newMontoMeta : montoMeta}
            onBlur={(e) => handleChangeQuincena(e)}
            onChange={(e) => handleChangeQuincena(e)}
          />
        </DialogContent>
        <DialogActions>
          <CardsBtn
            type="button"
            min_w="100%"
            min_web="100%"
            onClick={(e) => handleUpdateQuincena(e)}
          >
            Guardar
          </CardsBtn>
        </DialogActions>
      </Dialog>

      {mustLoadProducts ? <LoadProducts /> : null}
    </>
  );
};

const LoadProducts = () => {
  const [productsData, setProductsData] = useState([]);

  const [modalActive, setModalActive] = useState(false);

  const [productSelectedID, setProductSelectedID] = useState(0);

  const { loading, error } = useQuery(GET_PRODUCTS_BY_IDTRAINING, {
    variables: { idTraining: String('28') },
    onCompleted({ getProductsByIdTraining }) {
      console.log('# # Productos', getProductsByIdTraining);
      const products = JSON.parse(getProductsByIdTraining.response);
      console.log('# # products >>>', products);
      setProductsData(products);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [insertWaitList] = useMutation(INSERT_WAIT_LIST_PRODUCT_USER, {
    onCompleted() {
      setModalActive(false);
    },
    onError(err) {
      console.log(err);
    },
  });

  if (loading) return 'Cargando';
  if (error) return 'Error';

  const productSelectHandler = (productId) => {
    setProductSelectedID(productId);
    setModalActive(true);
  };

  const getAdvice = () => {
    if (!sessionStorage.getItem('idUser')) {
      setModalActive(false);
    }

    insertWaitList({
      variables: {
        input: {
          idUser: parseInt(sessionStorage.getItem('idUser')),
          idProduct: productSelectedID,
        },
      },
    });
  };

  return (
    <>
      <ModalLearnMore
        modalActive={modalActive}
        setModalActive={setModalActive}
        handleAdvice={getAdvice}
      />
      {productsData.map((producto) => (
        <>
          <TrainingDivSection2 paddiw="0" paddi="0" back="#FAFAFA">
            <Starttraining
              title=""
              text={
                'Te recomendamos planificar bien tu viaje con un buscador de viajes que te ayude a obtener los mejores precios.'
              }
              text2={
                '¡En Coru tenemos esta opción para ti! Estamos seguros que podrá interesarte.'
              }
            />
          </TrainingDivSection2>

          <Resultado
            bcolor="#fff"
            ta="left"
            marri="10px"
            marriweb="auto"
            marri=" "
            border="none"
            hei="auto"
            minh="453px"
            minhweb="auto"
          >
            <div className="row" style={{ margin: 'auto', width: '100%' }}>
              <div className="col-6" style={{ marginTop: '20px' }}>
                <img
                  src={producto.ImageURL}
                  style={{ width: '100px', margin: 'auto' }}
                />
              </div>

              <DivStyle
                className="cabin"
                mxh="30px"
                weight="600"
                align="left"
                size="20px"
                line="1.5em"
                padd="12px 5% 0 5%"
                color="#07210A"
              >
                {producto.Name}
              </DivStyle>

              <div className="col-12">
                <br />
                <TrainingText
                  size="14px"
                  sizew="14px"
                  weight="600"
                  className="roboto"
                  align="left"
                  alignweb="left"
                  ls="-0.2px"
                >
                  Beneficios
                </TrainingText>
                <ul style={{ paddingLeft: '18px' }}>
                  {producto.benefits.map((item) => (
                    <>
                      {item.IDProductDetailType === 3 ? (
                        <li>{item.Description}</li>
                      ) : null}
                    </>
                  ))}
                </ul>
              </div>

              <div className="col-12">
                <CardsBtn
                  windthmd="100%"
                  minmd="100%"
                  mleftmd="0%"
                  mrightmd="auto"
                  windthG="100%"
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  min_mini="100%"
                  mleftmini="10%"
                  mrightmini="10%"
                  mleft="0%"
                  mright="0%"
                  mleftweb="0%"
                  mrightweb="auto"
                  onClick={() => productSelectHandler(producto.CatProduct_IDProduct)
                  }
                >
                  Conocer más a
                </CardsBtn>
              </div>
            </div>
          </Resultado>
          <div className="marginComentLgSuccess1">
            <TrainingSectionGray back="#fafafa">
              {sessionStorage.getItem('idTraining') == '28' ? (
                <ProductsFremium
                  idT={parseInt(sessionStorage.getItem('idTraining'))}
                />
              ) : null}
            </TrainingSectionGray>
          </div>
        </>
      ))}
    </>
  );
};

export default ViewGoals;
