import React from 'react';
import Dailymotion from 'react-dailymotion';

export default function VideoQuestion({ idVideo }) {
  return (
    <div>
        <Dailymotion
            video={idVideo}
            width="100%"
            height="640px"
            autoplay="1"
            controls="false"
            mute="false"
        />
    </div>
  );
}
